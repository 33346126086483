import { Line } from "../../../trackTypes";

const cranbourneTooradin = [
  [-38.0999, 145.2810],
  [-38.1242, 145.3215],
  [-38.1693, 145.3735],
  [-38.1805, 145.3891]
];

const tooradinNyora = [
  [-38.1805, 145.3891],
  [-38.1840, 145.3939],
  [-38.1861, 145.3978],
  [-38.1879, 145.4041],
  [-38.1983, 145.4834],
  [-38.1998, 145.4894],
  [-38.2015, 145.4935],
  [-38.2036, 145.4968],
  [-38.2059, 145.4998],
  [-38.2408, 145.5377],
  [-38.2447, 145.5421],
  [-38.2600, 145.5632],
  [-38.2615, 145.5648],
  [-38.2631, 145.5661],
  [-38.3137, 145.5999],
  [-38.3151, 145.6023],
  [-38.3155, 145.6050],
  [-38.3153, 145.6071],
  [-38.3133, 145.6144],
  [-38.3123, 145.6226],
  [-38.3124, 145.6246],
  [-38.3131, 145.6263],
  [-38.3186, 145.6395],
  [-38.3212, 145.6444],
  [-38.3235, 145.6469],
  [-38.3263, 145.6484],
  [-38.3284, 145.6500],
  [-38.3293, 145.6523],
  [-38.3330, 145.6704]
];

const nyoraLoch = [
  [-38.3330, 145.6704],
  [-38.3335, 145.6729],
  [-38.3341, 145.6750],
  [-38.3350, 145.6767],
  [-38.3411, 145.6848],
  [-38.3423, 145.6881],
  [-38.3427, 145.6931],
  [-38.3434, 145.6955],
  [-38.3446, 145.6971],
  [-38.3465, 145.6984],
  [-38.3506, 145.6996],
  [-38.3527, 145.7006],
  [-38.3555, 145.7025],
  [-38.3568, 145.7027],
  [-38.3584, 145.7029],
  [-38.3607, 145.7039],
  [-38.3663, 145.7078],
  [-38.3677, 145.7094]
];

const lochKorumburra = [
  [-38.3677, 145.7094],
  [-38.3687, 145.7107],
  [-38.3699, 145.7130],
  [-38.3709, 145.7160],
  [-38.3714, 145.7170],
  [-38.3722, 145.7179],
  [-38.3748, 145.7192],
  [-38.3779, 145.7187],
  [-38.3793, 145.7178],
  [-38.3805, 145.7164],
  [-38.3811, 145.7159],
  [-38.3817, 145.7155],
  [-38.3844, 145.7149],
  [-38.3872, 145.7153],
  [-38.3897, 145.7168],
  [-38.3920, 145.7196],
  [-38.3929, 145.7225],
  [-38.3928, 145.7260],
  [-38.3931, 145.7281],
  [-38.3950, 145.7336],
  [-38.3962, 145.7379],
  [-38.3971, 145.7393],
  [-38.3984, 145.7401],
  [-38.3995, 145.7403],
  [-38.4028, 145.7395],
  [-38.4050, 145.7387],
  [-38.4066, 145.7383],
  [-38.4079, 145.7385],
  [-38.4098, 145.7394],
  [-38.4113, 145.7395],
  [-38.4134, 145.7404],
  [-38.4153, 145.7421],
  [-38.4176, 145.7473],
  [-38.4178, 145.7492],
  [-38.4174, 145.7509],
  [-38.4160, 145.7527],
  [-38.4146, 145.7538],
  [-38.4134, 145.7552],
  [-38.4116, 145.7564],
  [-38.4107, 145.7576],
  [-38.4102, 145.7593],
  [-38.4102, 145.7612],
  [-38.4123, 145.7680],
  [-38.4125, 145.7732],
  [-38.4145, 145.7794],
  [-38.4155, 145.7812],
  [-38.4166, 145.7824],
  [-38.4174, 145.7842],
  [-38.4178, 145.7869],
  [-38.4191, 145.7894],
  [-38.4205, 145.7910],
  [-38.4215, 145.7928],
  [-38.4221, 145.7952],
  [-38.4230, 145.7972],
  [-38.4247, 145.7995],
  [-38.4254, 145.8014],
  [-38.4254, 145.8035],
  [-38.4248, 145.8050],
  [-38.4237, 145.8073],
  [-38.4233, 145.8091],
  [-38.4235, 145.8110],
  [-38.4250, 145.8172],
  [-38.4259, 145.8187],
  [-38.4312, 145.8243]
];

const korumburraLeongatha = [
  [-38.4312, 145.8243],
  [-38.4334, 145.8265],
  [-38.4344, 145.8282],
  [-38.4349, 145.8304],
  [-38.4348, 145.8318],
  [-38.4333, 145.8378],
  [-38.4295, 145.8471],
  [-38.4292, 145.8487],
  [-38.4291, 145.8503],
  [-38.4293, 145.8533],
  [-38.4291, 145.8551],
  [-38.4284, 145.8565],
  [-38.4274, 145.8580],
  [-38.4269, 145.8594],
  [-38.4267, 145.8609],
  [-38.4269, 145.8633],
  [-38.4267, 145.8655],
  [-38.4256, 145.8679],
  [-38.4246, 145.8698],
  [-38.4241, 145.8719],
  [-38.4243, 145.8746],
  [-38.4250, 145.8766],
  [-38.4266, 145.8789],
  [-38.4277, 145.8796],
  [-38.4289, 145.8800],
  [-38.4301, 145.8809],
  [-38.4309, 145.8819],
  [-38.4314, 145.8831],
  [-38.4321, 145.8840],
  [-38.4330, 145.8848],
  [-38.4339, 145.8866],
  [-38.4341, 145.8888],
  [-38.4334, 145.8910],
  [-38.4322, 145.8924],
  [-38.4314, 145.8939],
  [-38.4310, 145.8959],
  [-38.4317, 145.8985],
  [-38.4351, 145.9030],
  [-38.4375, 145.9043],
  [-38.4393, 145.9046],
  [-38.4411, 145.9056],
  [-38.4442, 145.9081],
  [-38.4455, 145.9097],
  [-38.4474, 145.9132],
  [-38.4483, 145.9164],
  [-38.4483, 145.9194],
  [-38.4481, 145.9219],
  [-38.4489, 145.9252],
  [-38.4510, 145.9276],
  [-38.4529, 145.9284],
  [-38.4549, 145.9286],
  [-38.4566, 145.9293],
  [-38.4575, 145.9302],
  [-38.4588, 145.9317],
  [-38.4606, 145.9332],
  [-38.4656, 145.9359],
  [-38.4668, 145.9369],
  [-38.4738, 145.9458],
  [-38.4752, 145.9469],
  [-38.4760, 145.9473],
  [-38.4775, 145.9479]
];

const leongathaBarryBeachJunction = [
  [-38.4775, 145.9479],
  [-38.4805, 145.9488],
  [-38.4824, 145.9489],
  [-38.4838, 145.9483],
  [-38.4850, 145.9471],
  [-38.4893, 145.9419],
  [-38.4911, 145.9406],
  [-38.4928, 145.9402],
  [-38.5113, 145.9410],
  [-38.5126, 145.9414],
  [-38.5150, 145.9426],
  [-38.5168, 145.9429],
  [-38.5190, 145.9426],
  [-38.5207, 145.9429],
  [-38.5246, 145.9447],
  [-38.5262, 145.9450],
  [-38.5329, 145.9448],
  [-38.5342, 145.9449],
  [-38.5384, 145.9462],
  [-38.5397, 145.9462],
  [-38.5414, 145.9458],
  [-38.5437, 145.9460],
  [-38.5477, 145.9494],
  [-38.5499, 145.9531],
  [-38.5519, 145.9547],
  [-38.5539, 145.9554],
  [-38.5589, 145.9541],
  [-38.5609, 145.9546],
  [-38.5623, 145.9567],
  [-38.5629, 145.9602],
  [-38.5640, 145.9626],
  [-38.5645, 145.9670],
  [-38.5665, 145.9716],
  [-38.5701, 145.9756],
  [-38.5749, 145.9793],
  [-38.5758, 145.9807],
  [-38.5777, 145.9863],
  [-38.5798, 145.9892],
  [-38.5813, 145.9932],
  [-38.5815, 145.9956],
  [-38.5825, 146.0021],
  [-38.5820, 146.0055],
  [-38.5809, 146.0078],
  [-38.5760, 146.0142],
  [-38.5748, 146.0172],
  [-38.5747, 146.0198],
  [-38.5755, 146.0261],
  [-38.5763, 146.0286],
  [-38.5775, 146.0302],
  [-38.5990, 146.0471],
  [-38.6017, 146.0475],
  [-38.6633, 146.0243],
  [-38.6652, 146.0242],
  [-38.6671, 146.0250],
  [-38.6748, 146.0315],
  [-38.6758, 146.0328],
  [-38.6778, 146.0377],
  [-38.6790, 146.0397],
  [-38.6802, 146.0424],
  [-38.6808, 146.0484],
  [-38.6815, 146.0502],
  [-38.6862, 146.0591],
  [-38.6868, 146.0608],
  [-38.6883, 146.0686],
  [-38.6935, 146.0765],
  [-38.6940, 146.0789],
  [-38.6930, 146.0847],
  [-38.6930, 146.0873],
  [-38.6935, 146.0890],
  [-38.6949, 146.0920],
  [-38.6953, 146.0941],
  [-38.6948, 146.0966],
  [-38.6947, 146.0982],
  [-38.6951, 146.1002],
  [-38.6955, 146.1023],
  [-38.6945, 146.1058],
  [-38.6918, 146.1255],
  [-38.6895, 146.1344],
  [-38.6894, 146.1369],
  [-38.6900, 146.1426],
  [-38.6897, 146.1456],
  [-38.6880, 146.1479],
  [-38.6864, 146.1487],
  [-38.6841, 146.1518],
  [-38.6833, 146.1544],
  [-38.6818, 146.1567],
  [-38.6743, 146.1641],
  [-38.6705, 146.1696],
  [-38.6698, 146.1731],
  [-38.6696, 146.1879],
  [-38.6690, 146.1907],
  [-38.6605, 146.2141],
  [-38.6601, 146.2182],
  [-38.6671, 146.2689],
  [-38.6656, 146.3109],
  [-38.6739, 146.3953],
];

const barryBeachJunctionWelshpool = [
  [-38.6739, 146.3953],
  [-38.6737, 146.3988],
  [-38.6662, 146.4382]
];

const welshpoolAlberton = [
  [-38.6662, 146.4382],
  [-38.6647, 146.4452],
  [-38.6364, 146.5237],
  [-38.6178, 146.6214],
  [-38.6104, 146.6452],
  [-38.6100, 146.6482],
  [-38.6110, 146.6578],
  [-38.6109, 146.6595],
  [-38.6102, 146.6639],
  [-38.6095, 146.6674]
];

const albertonYarram = [
  [-38.6095, 146.6674],
  [-38.6082, 146.6695],
  [-38.6070, 146.6702],
  [-38.6056, 146.6704],
  [-38.5885, 146.6673],
  [-38.5866, 146.6674],
  [-38.5633, 146.6725]
];

const yarramWonWOn = [
  [-38.5633, 146.6725],
  [-38.5540, 146.6746],
  [-38.5523, 146.6745],
  [-38.5501, 146.6737],
  [-38.5266, 146.6608],
  [-38.5050, 146.6546],
  [-38.5030, 146.6548],
  [-38.4969, 146.6572],
  [-38.4955, 146.6589],
  [-38.4900, 146.6717],
  [-38.4832, 146.6814],
  [-38.4814, 146.6859],
  [-38.4803, 146.6907],
  [-38.4749, 146.7087],
  [-38.4704, 146.7168]
];

const wonWonWoodside = [
  [-38.4704, 146.7168],
  [-38.4677, 146.7224],
  [-38.4673, 146.7252],
  [-38.4675, 146.7279],
  [-38.4690, 146.7384],
  [-38.4737, 146.7568],
  [-38.4752, 146.7597],
  [-38.4766, 146.7615],
  [-38.4811, 146.7640],
  [-38.4873, 146.7738],
  [-38.4889, 146.7806],
  [-38.4907, 146.7845],
  [-38.4941, 146.8088],
  [-38.5053, 146.8414],
  [-38.5125, 146.8529],
  [-38.5150, 146.8602],
  [-38.5179, 146.8691],
  [-38.5188, 146.8773]
];

export const southGippsland: Line = {
  name: 'South Gippsland',
  state: 'VIC',
  segments: [
    {
      segments: [cranbourneTooradin],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-01-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tooradinNyora],
      history: {
        opened: {
          date: '1890-11-11',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-01-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nyoraLoch],
      history: {
        opened: {
          date: '1890-11-11',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2015-11-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lochKorumburra],
      history: {
        opened: {
          date: '1891-06-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2015-11-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [korumburraLeongatha],
      history: {
        opened: {
          date: '1891-12-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2015-11-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [leongathaBarryBeachJunction],
      history: {
        opened: {
          date: '1892-01-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [barryBeachJunctionWelshpool],
      history: {
        opened: {
          date: '1892-01-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-06-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [welshpoolAlberton],
      history: {
        opened: {
          date: '1892-01-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [albertonYarram],
      history: {
        opened: {
          date: '1921-02-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yarramWonWOn],
      history: {
        opened: {
          date: '1921-12-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-05-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wonWonWoodside],
      history: {
        opened: {
          date: '1923-06-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-05-26',
          status: 'closed'
        }]
      }
    }
  ]
};
