import { Line } from '../trackTypes';
import { adelaideDarwin } from './northernTerritory/adelaideDarwin';
import { northAustralia } from './northernTerritory/northAustralia';
import { oldGhan } from './northernTerritory/pichiRichi';
import { francesCreek } from './northernTerritory/francesCreek';

export const ntLines: Line[] = [
  adelaideDarwin,
  northAustralia,
  oldGhan,
  francesCreek
]
