import { Line } from "../../../trackTypes";

const line = [
  [-33.9082, 150.9361],
  [-33.9089, 150.9365],
  [-33.9098, 150.9374],
  [-33.9119, 150.9401],
  [-33.9135, 150.9413],
  [-33.9143, 150.9426],
  [-33.9155, 150.9493]
];

export const warwickFarmRacecourse: Line = {
  name: 'Warwick Farm Racecourse',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1889-06-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-11-19',
          status: 'closed'
        }, {
          date: '1991-12-07',
          status: 'closed'
        }, {
          date: '1979-02-17',
          status: 'open'
        }]
      }
    }
  ]
};
