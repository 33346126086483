import { Line } from "../../trackTypes";

const westTamworthWestdale = [
  [-31.0957, 150.9098],
  [-31.0961, 150.9089],
  [-31.0962, 150.9080],
  [-31.0962, 150.9070],
  [-31.0910, 150.8907],
  [-31.0896, 150.8877],
  [-31.0806, 150.8723]
];

const westdaleManilla = [
  [-31.0806, 150.8723],
  [-31.0792, 150.8702],
  [-31.0771, 150.8680],
  [-31.0661, 150.8596],
  [-31.0638, 150.8570],
  [-31.0616, 150.8552],
  [-31.0508, 150.8502],
  [-31.0490, 150.8499],
  [-31.0439, 150.8498],
  [-31.0416, 150.8490],
  [-31.0344, 150.8448],
  [-31.0327, 150.8442],
  [-31.0229, 150.8441],
  [-31.0094, 150.8472],
  [-31.0073, 150.8472],
  [-30.9995, 150.8454],
  [-30.9975, 150.8455],
  [-30.9917, 150.8472],
  [-30.9899, 150.8474],
  [-30.9834, 150.8477],
  [-30.9816, 150.8482],
  [-30.9753, 150.8513],
  [-30.9735, 150.8517],
  [-30.9715, 150.8514],
  [-30.9607, 150.8474],
  [-30.9473, 150.8409],
  [-30.9461, 150.8399],
  [-30.9444, 150.8379],
  [-30.9433, 150.8374],
  [-30.9424, 150.8375],
  [-30.9340, 150.8437],
  [-30.9328, 150.8439],
  [-30.9313, 150.8434],
  [-30.9304, 150.8422],
  [-30.9266, 150.8306],
  [-30.9264, 150.8286],
  [-30.9265, 150.8274],
  [-30.9260, 150.8258],
  [-30.9219, 150.8203],
  [-30.9192, 150.8161],
  [-30.9175, 150.8145],
  [-30.9133, 150.8129],
  [-30.9105, 150.8127],
  [-30.9093, 150.8131],
  [-30.9081, 150.8136],
  [-30.9068, 150.8135],
  [-30.9059, 150.8127],
  [-30.9037, 150.8080],
  [-30.9024, 150.8068],
  [-30.9017, 150.8065],
  [-30.9006, 150.8066],
  [-30.8968, 150.8073],
  [-30.8945, 150.8082],
  [-30.8931, 150.8081],
  [-30.8920, 150.8071],
  [-30.8910, 150.8044],
  [-30.8900, 150.8028],
  [-30.8885, 150.8022],
  [-30.8843, 150.8024],
  [-30.8822, 150.8030],
  [-30.8808, 150.8043],
  [-30.8789, 150.8070],
  [-30.8775, 150.8077],
  [-30.8764, 150.8077],
  [-30.8747, 150.8070],
  [-30.8736, 150.8068],
  [-30.8720, 150.8068],
  [-30.8708, 150.8063],
  [-30.8618, 150.7990],
  [-30.8561, 150.7928],
  [-30.8542, 150.7913],
  [-30.8532, 150.7902],
  [-30.8521, 150.7881],
  [-30.8509, 150.7869],
  [-30.8376, 150.7786],
  [-30.8357, 150.7783],
  [-30.8287, 150.7799],
  [-30.8268, 150.7798],
  [-30.8247, 150.7787],
  [-30.8216, 150.7762],
  [-30.8194, 150.7738],
  [-30.8165, 150.7692],
  [-30.8149, 150.7675],
  [-30.8132, 150.7663],
  [-30.8124, 150.7651],
  [-30.8115, 150.7615],
  [-30.8109, 150.7601],
  [-30.8102, 150.7596],
  [-30.8095, 150.7593],
  [-30.8089, 150.7585],
  [-30.8085, 150.7566],
  [-30.8067, 150.7540],
  [-30.8048, 150.7530],
  [-30.8035, 150.7513],
  [-30.8028, 150.7495],
  [-30.8015, 150.7484],
  [-30.7965, 150.7468],
  [-30.7938, 150.7465],
  [-30.7918, 150.7450],
  [-30.7901, 150.7425],
  [-30.7885, 150.7410],
  [-30.7838, 150.7380],
  [-30.7827, 150.7377],
  [-30.7796, 150.7373],
  [-30.7751, 150.7360],
  [-30.7695, 150.7350],
  [-30.7661, 150.7353],
  [-30.7643, 150.7349],
  [-30.7626, 150.7338],
  [-30.7571, 150.7278],
  [-30.7527, 150.7258],
  [-30.7511, 150.7252]
];

const manillaBarraba = [
  [-30.7527, 150.7258],
  [-30.7518, 150.7247],
  [-30.7516, 150.7237],
  [-30.7518, 150.7219],
  [-30.7517, 150.7204],
  [-30.7514, 150.7190],
  [-30.7514, 150.7180],
  [-30.7518, 150.7144],
  [-30.7516, 150.7132],
  [-30.7509, 150.7122],
  [-30.7423, 150.7057],
  [-30.7401, 150.7030],
  [-30.7343, 150.6926],
  [-30.7303, 150.6890],
  [-30.7287, 150.6882],
  [-30.7214, 150.6856],
  [-30.7197, 150.6856],
  [-30.7186, 150.6861],
  [-30.7171, 150.6880],
  [-30.7157, 150.6873],
  [-30.7100, 150.6874],
  [-30.7081, 150.6868],
  [-30.7021, 150.6826],
  [-30.7011, 150.6815],
  [-30.6921, 150.6651],
  [-30.6902, 150.6594],
  [-30.6897, 150.6552],
  [-30.6887, 150.6534],
  [-30.6818, 150.6488],
  [-30.6728, 150.6449],
  [-30.6706, 150.6447],
  [-30.6564, 150.6473],
  [-30.6541, 150.6484],
  [-30.6496, 150.6529],
  [-30.6488, 150.6539],
  [-30.6464, 150.6593],
  [-30.6446, 150.6619],
  [-30.6427, 150.6657],
  [-30.6418, 150.6664],
  [-30.6407, 150.6665],
  [-30.6321, 150.6645],
  [-30.6304, 150.6633],
  [-30.6295, 150.6618],
  [-30.6285, 150.6610],
  [-30.6255, 150.6601],
  [-30.6240, 150.6601],
  [-30.6227, 150.6606],
  [-30.6217, 150.6606],
  [-30.6200, 150.6602],
  [-30.6191, 150.6596],
  [-30.6187, 150.6584],
  [-30.6188, 150.6552],
  [-30.6185, 150.6541],
  [-30.6179, 150.6533],
  [-30.6141, 150.6513],
  [-30.6112, 150.6505],
  [-30.6046, 150.6502],
  [-30.6029, 150.6499],
  [-30.5925, 150.6466],
  [-30.5853, 150.6427],
  [-30.5799, 150.6391],
  [-30.5749, 150.6370],
  [-30.5660, 150.6321],
  [-30.5637, 150.6302],
  [-30.5616, 150.6265],
  [-30.5600, 150.6220],
  [-30.5578, 150.6187],
  [-30.5550, 150.6165],
  [-30.5495, 150.6138],
  [-30.5459, 150.6132],
  [-30.5439, 150.6135],
  [-30.5408, 150.6150],
  [-30.5395, 150.6152],
  [-30.5385, 150.6160],
  [-30.5379, 150.6176],
  [-30.5368, 150.6186],
  [-30.5357, 150.6186],
  [-30.5343, 150.6181],
  [-30.5331, 150.6180],
  [-30.5324, 150.6175],
  [-30.5313, 150.6166],
  [-30.5300, 150.6158],
  [-30.5273, 150.6127],
  [-30.5249, 150.6111],
  [-30.5211, 150.6104],
  [-30.5184, 150.6109],
  [-30.5142, 150.6120],
  [-30.5109, 150.6123],
  [-30.5075, 150.6134],
  [-30.5062, 150.6139],
  [-30.5052, 150.6140],
  [-30.5035, 150.6136],
  [-30.4988, 150.6136],
  [-30.4975, 150.6142],
  [-30.4970, 150.6154],
  [-30.4972, 150.6168],
  [-30.4981, 150.6177],
  [-30.4998, 150.6182],
  [-30.5005, 150.6192],
  [-30.5007, 150.6207],
  [-30.5000, 150.6218],
  [-30.4989, 150.6222],
  [-30.4950, 150.6218],
  [-30.4937, 150.6213],
  [-30.4917, 150.6202],
  [-30.4905, 150.6199],
  [-30.4894, 150.6196],
  [-30.4886, 150.6191],
  [-30.4863, 150.6182],
  [-30.4842, 150.6186],
  [-30.4829, 150.6181],
  [-30.4808, 150.6160],
  [-30.4795, 150.6156],
  [-30.4779, 150.6161],
  [-30.4768, 150.6172],
  [-30.4759, 150.6189],
  [-30.4749, 150.6198],
  [-30.4702, 150.6224],
  [-30.4652, 150.6264],
  [-30.4631, 150.6271],
  [-30.4596, 150.6271],
  [-30.4513, 150.6295],
  [-30.4491, 150.6295],
  [-30.4458, 150.6292],
  [-30.4447, 150.6295],
  [-30.4437, 150.6298],
  [-30.4426, 150.6296],
  [-30.4418, 150.6289],
  [-30.4411, 150.6274],
  [-30.4404, 150.6266],
  [-30.4358, 150.6238],
  [-30.4319, 150.6224],
  [-30.4306, 150.6211],
  [-30.4300, 150.6196],
  [-30.4299, 150.6180],
  [-30.4300, 150.6165],
  [-30.4298, 150.6154],
  [-30.4288, 150.6142],
  [-30.4270, 150.6134],
  [-30.4248, 150.6135],
  [-30.4232, 150.6145],
  [-30.4222, 150.6152],
  [-30.4210, 150.6155],
  [-30.4200, 150.6152],
  [-30.4186, 150.6142],
  [-30.4163, 150.6138],
  [-30.4014, 150.6141],
  [-30.4014, 150.6141],
  [-30.3947, 150.6112],
  [-30.3897, 150.6099],
  [-30.3897, 150.6099],
  [-30.3859, 150.6029]
];

export const barraba: Line = {
  name: 'Barraba',
  state: 'NSW',
  segments: [
    {
      segments: [westTamworthWestdale],
      history: {
        opened: {
          date: '1899-09-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [westdaleManilla],
      history: {
        opened: {
          date: '1899-09-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-11-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [manillaBarraba],
      history: {
        opened: {
          date: '1908-09-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-11-25',
          status: 'closed'
        }]
      }
    }
  ]
};
