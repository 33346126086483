import { Line } from "../../trackTypes";

const line = [
  [-42.7596, 146.6002],
  [-42.7602, 146.5997],
  [-42.7610, 146.5992],
  [-42.7622, 146.5995],
  [-42.7631, 146.5996],
  [-42.7654, 146.6000],
  [-42.7698, 146.5991],
  [-42.7704, 146.5993],
  [-42.7718, 146.6015],
  [-42.7723, 146.6020],
  [-42.7731, 146.6021],
  [-42.7735, 146.6025],
  [-42.7740, 146.6048],
  [-42.7744, 146.6052],
  [-42.7748, 146.6055],
  [-42.7751, 146.6058],
  [-42.7760, 146.6075],
  [-42.7765, 146.6077],
  [-42.7772, 146.6077],
  [-42.7776, 146.6081],
  [-42.7776, 146.6085],
  [-42.7774, 146.6088],
  [-42.7773, 146.6092],
  [-42.7775, 146.6096],
  [-42.7779, 146.6101],
  [-42.7781, 146.6108],
  [-42.7778, 146.6120],
  [-42.7778, 146.6125],
  [-42.7780, 146.6131],
  [-42.7780, 146.6136],
  [-42.7781, 146.6140],
  [-42.7786, 146.6147],
  [-42.7788, 146.6154],
  [-42.7788, 146.6158],
  [-42.7786, 146.6163]
];

const westJunction = [
  [-42.7606, 146.5961],
  [-42.7607, 146.5970],
  [-42.7609, 146.5978],
  [-42.7615, 146.5988],
  [-42.7622, 146.5995]
];

export const risbyBasin: Line = {
  name: 'Risby Basin',
  state: 'TAS',
  segments: [
    {
      segments: [line, westJunction],
      history: {
        opened: {
          date: '1943-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
