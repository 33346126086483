import { Line } from "../../../trackTypes";

const tallarookYea = [
  [-37.0902, 145.1044],
  [-37.0896, 145.1053],
  [-37.0891, 145.1064],
  [-37.0876, 145.1115],
  [-37.0872, 145.1135],
  [-37.0872, 145.1162],
  [-37.0873, 145.1189],
  [-37.0878, 145.1212],
  [-37.0889, 145.1239],
  [-37.0925, 145.1294],
  [-37.0934, 145.1313],
  [-37.0938, 145.1337],
  [-37.0939, 145.1357],
  [-37.0932, 145.1410],
  [-37.0927, 145.1427],
  [-37.0868, 145.1540],
  [-37.0860, 145.1550],
  [-37.0840, 145.1573],
  [-37.0830, 145.1588],
  [-37.0800, 145.1657],
  [-37.0797, 145.1673],
  [-37.0797, 145.1689],
  [-37.0804, 145.1715],
  [-37.0814, 145.1797],
  [-37.0817, 145.1807],
  [-37.0826, 145.1828],
  [-37.0836, 145.1841],
  [-37.0856, 145.1861],
  [-37.0878, 145.1888],
  [-37.0883, 145.1891],
  [-37.0888, 145.1892],
  [-37.0898, 145.1898],
  [-37.0905, 145.1904],
  [-37.0916, 145.1921],
  [-37.0934, 145.1969],
  [-37.0938, 145.1992],
  [-37.0936, 145.2023],
  [-37.0938, 145.2038],
  [-37.0943, 145.2053],
  [-37.0960, 145.2075],
  [-37.0973, 145.2082],
  [-37.0986, 145.2086],
  [-37.1053, 145.2087],
  [-37.1069, 145.2082],
  [-37.1082, 145.2075],
  [-37.1092, 145.2070],
  [-37.1100, 145.2068],
  [-37.1111, 145.2069],
  [-37.1124, 145.2074],
  [-37.1136, 145.2085],
  [-37.1142, 145.2097],
  [-37.1151, 145.2114],
  [-37.1161, 145.2127],
  [-37.1183, 145.2149],
  [-37.1197, 145.2167],
  [-37.1203, 145.2188],
  [-37.1208, 145.2202],
  [-37.1216, 145.2211],
  [-37.1229, 145.2215],
  [-37.1239, 145.2215],
  [-37.1255, 145.2211],
  [-37.1266, 145.2215],
  [-37.1273, 145.2222],
  [-37.1286, 145.2244],
  [-37.1304, 145.2255],
  [-37.1316, 145.2267],
  [-37.1320, 145.2277],
  [-37.1326, 145.2295],
  [-37.1333, 145.2304],
  [-37.1342, 145.2310],
  [-37.1358, 145.2312],
  [-37.1366, 145.2315],
  [-37.1382, 145.2327],
  [-37.1400, 145.2349],
  [-37.1425, 145.2399],
  [-37.1449, 145.2457],
  [-37.1450, 145.2475],
  [-37.1447, 145.2486],
  [-37.1440, 145.2499],
  [-37.1437, 145.2510],
  [-37.1437, 145.2551],
  [-37.1431, 145.2569],
  [-37.1417, 145.2595],
  [-37.1411, 145.2613],
  [-37.1409, 145.2632],
  [-37.1410, 145.2641],
  [-37.1414, 145.2667],
  [-37.1421, 145.2675],
  [-37.1435, 145.2684],
  [-37.1465, 145.2703],
  [-37.1476, 145.2718],
  [-37.1478, 145.2737],
  [-37.1475, 145.2755],
  [-37.1476, 145.2768],
  [-37.1495, 145.2822],
  [-37.1501, 145.2832],
  [-37.1515, 145.2842],
  [-37.1522, 145.2852],
  [-37.1526, 145.2868],
  [-37.1541, 145.2896],
  [-37.1551, 145.2906],
  [-37.1624, 145.2924],
  [-37.1636, 145.2931],
  [-37.1643, 145.2942],
  [-37.1646, 145.2957],
  [-37.1653, 145.2970],
  [-37.1682, 145.3001],
  [-37.1697, 145.3027],
  [-37.1723, 145.3106],
  [-37.1731, 145.3117],
  [-37.1770, 145.3152],
  [-37.1780, 145.3163],
  [-37.1856, 145.3284],
  [-37.1878, 145.3310],
  [-37.1907, 145.3326],
  [-37.1965, 145.3381],
  [-37.1977, 145.3401],
  [-37.2024, 145.3552],
  [-37.2031, 145.3566],
  [-37.2094, 145.3665],
  [-37.2099, 145.3686],
  [-37.2099, 145.3771],
  [-37.2095, 145.3788],
  [-37.2067, 145.3862],
  [-37.2059, 145.3894],
  [-37.2058, 145.3925],
  [-37.2063, 145.3957],
  [-37.2098, 145.4061],
  [-37.2128, 145.4117],
  [-37.2133, 145.4130],
  [-37.2138, 145.4160],
  [-37.2136, 145.4187],
  [-37.2134, 145.4201],
  [-37.2128, 145.4222],
  [-37.2127, 145.4237],
  [-37.2129, 145.4246],
  [-37.2137, 145.4279]
];

const yeaCheviotTunnel = [
  [-37.2137, 145.4279],
  [-37.2147, 145.4319],
  [-37.2153, 145.4333],
  [-37.2170, 145.4349],
  [-37.2202, 145.4358],
  [-37.2228, 145.4371],
  [-37.2252, 145.4388],
  [-37.2332, 145.4436],
  [-37.2349, 145.4454],
  [-37.2359, 145.4476],
  [-37.2362, 145.4496],
  [-37.2359, 145.4518],
  [-37.2341, 145.4590],
  [-37.2328, 145.4621],
  [-37.2285, 145.4689],
  [-37.2260, 145.4751],
  [-37.2248, 145.4770],
  [-37.2241, 145.4789],
  [-37.2238, 145.4810],
  [-37.2238, 145.4827],
  [-37.2233, 145.4848],
  [-37.2220, 145.4868],
  [-37.2175, 145.4894],
  [-37.2150, 145.4920],
  [-37.2121, 145.4965],
  [-37.2111, 145.4975],
  [-37.2079, 145.4999]
];

const cheviotTunnel = [
  [-37.2079, 145.4999],
  [-37.2062, 145.5011]
];

const cheviotTunnelMolesworth = [
  [-37.2062, 145.5011],
  [-37.2051, 145.5022],
  [-37.2043, 145.5036],
  [-37.2025, 145.5072],
  [-37.2007, 145.5145],
  [-37.2002, 145.5158],
  [-37.1994, 145.5171],
  [-37.1980, 145.5182],
  [-37.1963, 145.5189],
  [-37.1947, 145.5190],
  [-37.1925, 145.5184],
  [-37.1902, 145.5185],
  [-37.1873, 145.5194],
  [-37.1855, 145.5193],
  [-37.1834, 145.5189],
  [-37.1765, 145.5161],
  [-37.1744, 145.5157],
  [-37.1700, 145.5158],
  [-37.1682, 145.5164],
  [-37.1664, 145.5179],
  [-37.1653, 145.5197],
  [-37.1647, 145.5225],
  [-37.1653, 145.5249],
  [-37.1666, 145.5264],
  [-37.1678, 145.5273],
  [-37.1691, 145.5286],
  [-37.1699, 145.5308],
  [-37.1704, 145.5358],
  [-37.1702, 145.5376],
  [-37.1688, 145.5403],
  [-37.1667, 145.5424]
];

const molesworthCathkin = [
  [-37.1667, 145.5424],
  [-37.1636, 145.5453],
  [-37.1627, 145.5467],
  [-37.1621, 145.5484],
  [-37.1618, 145.5503],
  [-37.1619, 145.5582],
  [-37.1613, 145.5616],
  [-37.1568, 145.5721],
  [-37.1555, 145.5742],
  [-37.1531, 145.5772],
  [-37.1520, 145.5793],
  [-37.1499, 145.5841]
];

const cathkinMerton = [
  [-37.1499, 145.5841],
  [-37.1490, 145.5863],
  [-37.1482, 145.5872],
  [-37.1473, 145.5877],
  [-37.1427, 145.5890],
  [-37.1319, 145.5935],
  [-37.1307, 145.5944],
  [-37.1123, 145.6089],
  [-37.1109, 145.6106],
  [-37.1096, 145.6125],
  [-37.1027, 145.6180],
  [-37.1016, 145.6184],
  [-37.1003, 145.6185],
  [-37.0997, 145.6189],
  [-37.0889, 145.6274],
  [-37.0881, 145.6283],
  [-37.0728, 145.6469],
  [-37.0636, 145.6581],
  [-37.0592, 145.6626],
  [-37.0545, 145.6666],
  [-37.0533, 145.6682],
  [-37.0494, 145.6737],
  [-37.0483, 145.6746],
  [-37.0470, 145.6750],
  [-37.0456, 145.6756],
  [-37.0444, 145.6768],
  [-37.0433, 145.6792],
  [-37.0433, 145.6819],
  [-37.0440, 145.6840],
  [-37.0451, 145.6861],
  [-37.0453, 145.6892],
  [-37.0441, 145.6922],
  [-37.0380, 145.6974],
  [-37.0366, 145.6978],
  [-37.0333, 145.6983],
  [-37.0303, 145.6990],
  [-37.0283, 145.6987],
  [-37.0252, 145.6971],
  [-37.0233, 145.6968],
  [-37.0190, 145.6976],
  [-37.0127, 145.6980],
  [-37.0107, 145.6977],
  [-37.0080, 145.6969],
  [-37.0063, 145.6969],
  [-36.9954, 145.6998],
  [-36.9896, 145.7009],
  [-36.9859, 145.7011],
  [-36.9844, 145.7015],
  [-36.9831, 145.7022],
  [-36.9790, 145.7065],
  [-36.9780, 145.7086],
  [-36.9778, 145.7106]
];

const cathkinKoriella = [
  [-37.1490, 145.5863],
  [-37.1489, 145.5878],
  [-37.1492, 145.5893],
  [-37.1498, 145.5914],
  [-37.1499, 145.5929],
  [-37.1499, 145.6011],
  [-37.1495, 145.6035],
  [-37.1434, 145.6217],
  [-37.1428, 145.6244],
  [-37.1429, 145.6296],
  [-37.1435, 145.6324],
  [-37.1511, 145.6485],
  [-37.1520, 145.6516],
  [-37.1551, 145.6580]
];

const koriellaAlexandra = [
  [-37.1551, 145.6580],
  [-37.1560, 145.6600],
  [-37.1568, 145.6631],
  [-37.1573, 145.6641],
  [-37.1633, 145.6731],
  [-37.1640, 145.6745],
  [-37.1650, 145.6756],
  [-37.1680, 145.6772],
  [-37.1688, 145.6785],
  [-37.1689, 145.6795],
  [-37.1687, 145.6805],
  [-37.1676, 145.6814],
  [-37.1666, 145.6816],
  [-37.1659, 145.6821],
  [-37.1653, 145.6833],
  [-37.1653, 145.6846],
  [-37.1659, 145.6863],
  [-37.1670, 145.6880],
  [-37.1680, 145.6889],
  [-37.1687, 145.6901],
  [-37.1696, 145.6940],
  [-37.1700, 145.6987],
  [-37.1704, 145.7005],
  [-37.1715, 145.7026],
  [-37.1755, 145.7068],
  [-37.1760, 145.7072],
  [-37.1798, 145.7093],
  [-37.1804, 145.7098],
  [-37.1853, 145.7157],
  [-37.1856, 145.7164],
  [-37.1859, 145.7175]
];

const mertonMaindample = [
  [-36.9778, 145.7106],
  [-36.9781, 145.7127],
  [-36.9788, 145.7140],
  [-36.9826, 145.7204],
  [-36.9832, 145.7220],
  [-36.9834, 145.7233],
  [-36.9833, 145.7253],
  [-36.9838, 145.7272],
  [-36.9881, 145.7346],
  [-36.9894, 145.7360],
  [-36.9974, 145.7428],
  [-37.0099, 145.7534],
  [-37.0111, 145.7549],
  [-37.0123, 145.7574],
  [-37.0127, 145.7598],
  [-37.0152, 145.7909],
  [-37.0159, 145.7937],
  [-37.0168, 145.7963],
  [-37.0173, 145.7980],
  [-37.0208, 145.8055],
  [-37.0227, 145.8085],
  [-37.0284, 145.8209],
  [-37.0288, 145.8234],
  [-37.0286, 145.8256],
  [-37.0278, 145.8276],
  [-37.0263, 145.8294],
  [-37.0251, 145.8314],
  [-37.0240, 145.8359],
  [-37.0240, 145.8381],
  [-37.0246, 145.8439],
  [-37.0258, 145.8476],
  [-37.0259, 145.8499],
  [-37.0238, 145.8560],
  [-37.0229, 145.8592],
  [-37.0229, 145.8611],
  [-37.0236, 145.8629],
  [-37.0247, 145.8641],
  [-37.0263, 145.8649],
  [-37.0274, 145.8661],
  [-37.0282, 145.8678],
  [-37.0282, 145.8698],
  [-37.0245, 145.8802],
  [-37.0234, 145.8820],
  [-37.0218, 145.8838],
  [-37.0208, 145.8863],
  [-37.0205, 145.8879],
  [-37.0199, 145.8969],
  [-37.0202, 145.8990],
  [-37.0202, 145.9008],
  [-37.0198, 145.9035],
  [-37.0198, 145.9051],
  [-37.0203, 145.9070],
  [-37.0236, 145.9142],
  [-37.0243, 145.9166],
  [-37.0256, 145.9242],
  [-37.0261, 145.9255],
  [-37.0268, 145.9270],
  [-37.0280, 145.9342],
  [-37.0281, 145.9409]
];

const maindampleMansfield = [
  [-37.0281, 145.9409],
  [-37.0282, 145.9428],
  [-37.0278, 145.9444],
  [-37.0258, 145.9474],
  [-37.0253, 145.9481],
  [-37.0233, 145.9522],
  [-37.0227, 145.9539],
  [-37.0206, 145.9719],
  [-37.0223, 145.9920],
  [-37.0229, 145.9945],
  [-37.0304, 146.0089],
  [-37.0349, 146.0225],
  [-37.0361, 146.0247],
  [-37.0397, 146.0285],
  [-37.0415, 146.0320],
  [-37.0483, 146.0634],
  [-37.0496, 146.0714],
  [-37.0496, 146.0752],
  [-37.0505, 146.0824]
];

export const mansfield: Line = {
  name: 'Mansfield',
  state: 'VIC',
  segments: [
    {
      segments: [tallarookYea],
      history: {
        opened: {
          date: '1883-11-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        yeaCheviotTunnel,
        {
          name: 'Cheviot Tunnel',
          segment: cheviotTunnel,
          type: 'tunnel'
        },
        cheviotTunnelMolesworth
      ],
      history: {
        opened: {
          date: '1889-11-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [molesworthCathkin],
      history: {
        opened: {
          date: '1890-06-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cathkinMerton],
      history: {
        opened: {
          date: '1890-11-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cathkinKoriella],
      history: {
        opened: {
          date: '1890-09-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [koriellaAlexandra],
      history: {
        opened: {
          date: '1909-10-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mertonMaindample],
      history: {
        opened: {
          date: '1891-05-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maindampleMansfield],
      history: {
        opened: {
          date: '1891-10-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-08',
          status: 'closed'
        }]
      }
    }
  ]
}
