import { Line } from "../../trackTypes";

const line = [
  [-23.8564, 151.2698],
  [-23.8533, 151.2694],
  [-23.8523, 151.2695],
  [-23.8498, 151.2705],
  [-23.8489, 151.2707],
  [-23.8474, 151.2707],
  [-23.8422, 151.2709],
  [-23.8374, 151.2710]
];

const balloonLoop = [
  [-23.8422, 151.2709],
  [-23.8411, 151.2705],
  [-23.8403, 151.2698],
  [-23.8398, 151.2691],
  [-23.8393, 151.2669],
  [-23.8394, 151.2658],
  [-23.8401, 151.2648],
  [-23.8413, 151.2644],
  [-23.8420, 151.2645],
  [-23.8429, 151.2651],
  [-23.8434, 151.2661],
  [-23.8439, 151.2682],
  [-23.8446, 151.2693],
  [-23.8464, 151.2704],
  [-23.8474, 151.2707]
];

export const barneyPoint: Line = {
  name: 'Barney Point',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1967-08-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2016-05-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balloonLoop],
      history: {
        opened: {
          date: '2001-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2016-05-20',
          status: 'closed'
        }]
      }
    }
  ]
}
