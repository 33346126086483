import { Line } from "../../trackTypes";

const line = [
  [-32.7252, 151.5114],
  [-32.7246, 151.5105],
  [-32.7238, 151.5102],
  [-32.7232, 151.5102],
  [-32.7215, 151.5107],
  [-32.7211, 151.5112],
  [-32.7198, 151.5131],
  [-32.7174, 151.5148],
  [-32.7117, 151.5201],
  [-32.7114, 151.5206],
  [-32.7112, 151.5213],
  [-32.7113, 151.5219],
  [-32.7114, 151.5231],
  [-32.7111, 151.5238],
  [-32.7100, 151.5245],
  [-32.7093, 151.5246],
  [-32.7086, 151.5245],
  [-32.7080, 151.5248],
  [-32.7075, 151.5254],
  [-32.7073, 151.5264],
  [-32.7075, 151.5276]
];

export const rutherfordRacecourse: Line = {
  name: 'Rutherford Racecourse',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-07-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1965-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
