import { Line } from "../../trackTypes";

const northRockhamptonLakesCreek = [
  [-23.3727, 150.5230],
  [-23.3726, 150.5284],
  [-23.3727, 150.5311],
  [-23.3747, 150.5476],
  [-23.3746, 150.5525],
  [-23.3748, 150.5538],
  [-23.3752, 150.5547],
  [-23.3795, 150.5612],
  [-23.3808, 150.5624],
  [-23.3832, 150.5633],
  [-23.3842, 150.5638],
  [-23.3930, 150.5700],
  [-23.3988, 150.5760],
  [-23.4005, 150.5781],
  [-23.4048, 150.5823],
  [-23.4060, 150.5840],
  [-23.4065, 150.5859],
  [-23.4068, 150.5875],
  [-23.4072, 150.5884],
  [-23.4121, 150.5970],
  [-23.4126, 150.5992],
  [-23.4122, 150.6015],
  [-23.4110, 150.6033],
  [-23.4097, 150.6043],
  [-23.4081, 150.6051]
];

const lakesCreekSleipnerJunction = [
  [-23.4081, 150.6051],
  [-23.4070, 150.6057],
  [-23.4060, 150.6065],
  [-23.4054, 150.6074],
  [-23.4044, 150.6105],
  [-23.4039, 150.6114],
  [-23.4031, 150.6122],
  [-23.4001, 150.6140],
  [-23.3990, 150.6150],
  [-23.3980, 150.6166],
  [-23.3941, 150.6262],
  [-23.3928, 150.6282],
  [-23.3908, 150.6296],
  [-23.3886, 150.6306],
  [-23.3838, 150.6328],
  [-23.3822, 150.6337],
  [-23.3735, 150.6421],
  [-23.3632, 150.6484],
  [-23.3615, 150.6498],
  [-23.3564, 150.6555],
  [-23.3552, 150.6561],
  [-23.3513, 150.6570],
  [-23.3500, 150.6578],
  [-23.3463, 150.6619]
];

const sleipnerJunctionEmuPark = [
  [-23.3463, 150.6619],
  [-23.3408, 150.6676],
  [-23.3280, 150.6776],
  [-23.3264, 150.6782],
  [-23.3216, 150.6812],
  [-23.3210, 150.6819],
  [-23.3202, 150.6834],
  [-23.3193, 150.6845],
  [-23.3121, 150.6882],
  [-23.3110, 150.6883],
  [-23.3086, 150.6882],
  [-23.3071, 150.6887],
  [-23.3052, 150.6902],
  [-23.3042, 150.6920],
  [-23.2973, 150.6993],
  [-23.2879, 150.7150],
  [-23.2865, 150.7164],
  [-23.2841, 150.7176],
  [-23.2830, 150.7184],
  [-23.2820, 150.7202],
  [-23.2810, 150.7231],
  [-23.2792, 150.7253],
  [-23.2765, 150.7262],
  [-23.2658, 150.7260],
  [-23.2633, 150.7266],
  [-23.2587, 150.7286],
  [-23.2555, 150.7291],
  [-23.2547, 150.7296],
  [-23.2538, 150.7305],
  [-23.2524, 150.7313],
  [-23.2480, 150.7321],
  [-23.2423, 150.7355],
  [-23.2413, 150.7364],
  [-23.2354, 150.7440],
  [-23.2330, 150.7459],
  [-23.2318, 150.7461],
  [-23.2301, 150.7460],
  [-23.2289, 150.7463],
  [-23.2276, 150.7474],
  [-23.2236, 150.7539],
  [-23.2232, 150.7584],
  [-23.2235, 150.7603],
  [-23.2258, 150.7670],
  [-23.2261, 150.7701],
  [-23.2252, 150.7730],
  [-23.2227, 150.7775],
  [-23.2224, 150.7789],
  [-23.2226, 150.7821],
  [-23.2230, 150.7834],
  [-23.2300, 150.7936],
  [-23.2335, 150.8034],
  [-23.2349, 150.8088],
  [-23.2392, 150.8163],
  [-23.2416, 150.8176],
  [-23.2435, 150.8178],
  [-23.2465, 150.8189],
  [-23.2475, 150.8195],
  [-23.2505, 150.8224],
  [-23.2516, 150.8231],
  [-23.2542, 150.8242],
  [-23.2568, 150.8265]
];

const nankinJunctionBroadmount = [
  [-23.3886, 150.6306],
  [-23.3880, 150.6312],
  [-23.3877, 150.6319],
  [-23.3877, 150.6351],
  [-23.3881, 150.6364],
  [-23.3890, 150.6378],
  [-23.3892, 150.6389],
  [-23.3889, 150.6400],
  [-23.3882, 150.6412],
  [-23.3877, 150.6424],
  [-23.3874, 150.6455],
  [-23.3877, 150.6501],
  [-23.3881, 150.6511],
  [-23.3890, 150.6517],
  [-23.3996, 150.6543],
  [-23.4133, 150.6629],
  [-23.4142, 150.6638],
  [-23.4172, 150.6685],
  [-23.4185, 150.6698],
  [-23.4206, 150.6714],
  [-23.4213, 150.6730],
  [-23.4249, 150.6798],
  [-23.4271, 150.6854],
  [-23.4282, 150.6879],
  [-23.4334, 150.6945],
  [-23.4368, 150.7023],
  [-23.4376, 150.7038],
  [-23.4389, 150.7049],
  [-23.4436, 150.7074],
  [-23.4461, 150.7099],
  [-23.4531, 150.7172],
  [-23.4671, 150.7334],
  [-23.4691, 150.7348],
  [-23.4787, 150.7382],
  [-23.4803, 150.7388],
  [-23.4819, 150.7387],
  [-23.4827, 150.7387],
  [-23.4838, 150.7391],
  [-23.4853, 150.7409],
  [-23.4857, 150.7418],
  [-23.4858, 150.7430],
  [-23.4855, 150.7443],
  [-23.4857, 150.7452],
  [-23.4863, 150.7463],
  [-23.4915, 150.7521],
  [-23.4930, 150.7545],
  [-23.4952, 150.7592],
  [-23.4957, 150.7599],
  [-23.4995, 150.7623],
  [-23.5000, 150.7630],
  [-23.5014, 150.7685],
  [-23.5016, 150.7703],
  [-23.5013, 150.7839],
  [-23.5015, 150.7845],
  [-23.5021, 150.7862],
  [-23.5021, 150.7873],
  [-23.5006, 150.7931],
  [-23.4998, 150.7940],
  [-23.4983, 150.7949],
  [-23.4977, 150.7956],
  [-23.4976, 150.7968],
  [-23.4995, 150.8030]
];

const sleipnerJunctionYeppoon = [
  [-23.3463, 150.6619],
  [-23.3457, 150.6621],
  [-23.3437, 150.6620],
  [-23.3426, 150.6613],
  [-23.3407, 150.6590],
  [-23.3395, 150.6581],
  [-23.3390, 150.6575],
  [-23.3383, 150.6556],
  [-23.3362, 150.6527],
  [-23.3297, 150.6475],
  [-23.3261, 150.6455],
  [-23.3251, 150.6454],
  [-23.3240, 150.6459],
  [-23.3229, 150.6461],
  [-23.3216, 150.6459],
  [-23.3140, 150.6423],
  [-23.3047, 150.6407],
  [-23.3018, 150.6412],
  [-23.2996, 150.6425],
  [-23.2986, 150.6427],
  [-23.2977, 150.6423],
  [-23.2966, 150.6411],
  [-23.2958, 150.6409],
  [-23.2949, 150.6412],
  [-23.2936, 150.6423],
  [-23.2926, 150.6426],
  [-23.2916, 150.6423],
  [-23.2908, 150.6414],
  [-23.2900, 150.6407],
  [-23.2869, 150.6396],
  [-23.2863, 150.6396],
  [-23.2857, 150.6398],
  [-23.2835, 150.6419],
  [-23.2832, 150.6424],
  [-23.2831, 150.6434],
  [-23.2836, 150.6451],
  [-23.2842, 150.6459],
  [-23.2856, 150.6467],
  [-23.2861, 150.6476],
  [-23.2860, 150.6490],
  [-23.2849, 150.6509],
  [-23.2847, 150.6519],
  [-23.2848, 150.6527],
  [-23.2853, 150.6539],
  [-23.2854, 150.6546],
  [-23.2852, 150.6555],
  [-23.2837, 150.6572],
  [-23.2822, 150.6580],
  [-23.2769, 150.6590],
  [-23.2755, 150.6590],
  [-23.2724, 150.6583],
  [-23.2709, 150.6589],
  [-23.2674, 150.6619],
  [-23.2647, 150.6631],
  [-23.2490, 150.6650],
  [-23.2409, 150.6649],
  [-23.2155, 150.6629],
  [-23.2132, 150.6630],
  [-23.2008, 150.6648],
  [-23.1994, 150.6651],
  [-23.1901, 150.6683],
  [-23.1894, 150.6689],
  [-23.1891, 150.6697],
  [-23.1885, 150.6752],
  [-23.1883, 150.6760],
  [-23.1875, 150.6767],
  [-23.1863, 150.6773],
  [-23.1857, 150.6781],
  [-23.1852, 150.6792],
  [-23.1846, 150.6799],
  [-23.1810, 150.6818],
  [-23.1793, 150.6831],
  [-23.1758, 150.6876],
  [-23.1749, 150.6884],
  [-23.1735, 150.6892],
  [-23.1703, 150.6923],
  [-23.1678, 150.6941],
  [-23.1669, 150.6951],
  [-23.1619, 150.7053],
  [-23.1610, 150.7067],
  [-23.1590, 150.7087],
  [-23.1581, 150.7099],
  [-23.1569, 150.7124],
  [-23.1558, 150.7137],
  [-23.1509, 150.7163],
  [-23.1498, 150.7167],
  [-23.1452, 150.7176],
  [-23.1348, 150.7181],
  [-23.1329, 150.7192],
  [-23.1319, 150.7202],
  [-23.1312, 150.7217],
  [-23.1311, 150.7233],
  [-23.1315, 150.7259],
  [-23.1312, 150.7280],
  [-23.1274, 150.7342],
  [-23.1273, 150.7354],
  [-23.1276, 150.7364],
  [-23.1282, 150.7375],
  [-23.1283, 150.7382],
  [-23.1280, 150.7396],
  [-23.1279, 150.7404],
  [-23.1283, 150.7442]
];

export const emuPark: Line = {
  name: 'Emu Park',
  state: 'QLD',
  segments: [
    {
      segments: [northRockhamptonLakesCreek],
      history: {
        opened: {
          date: '1888-12-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [lakesCreekSleipnerJunction],
      history: {
        opened: {
          date: '1888-12-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sleipnerJunctionEmuPark],
      history: {
        opened: {
          date: '1888-12-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nankinJunctionBroadmount],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sleipnerJunctionYeppoon],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
