import { Line } from "../../../trackTypes";

//NOTE this opened originally as a can tram, the info here is just for the tourist opperations
// The below info is from the old cane network, this will be mapped if it can all be accounted for
/**
 * coolum line o1923 [nambour, petrie creek, bli bli] c2003-12?
 */

const line = [
  [-26.6264, 152.9575],
  [-26.6266, 152.9579],
  [-26.6267, 152.9588],
  [-26.6269, 152.9593],
  [-26.6271, 152.9602],
  [-26.6270, 152.9617],
  [-26.6263, 152.9646],
  [-26.6261, 152.9648],
  [-26.6259, 152.9648]
];

export const nambourTram: Line = {
  name: 'Nambour Tramway',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2021-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        }
      }
    }
  ]
}
