import { Line } from "../../../trackTypes";

const kingstonKingstonEast = [
  [-36.8271, 139.8501],
  [-36.8324, 139.8584]
];

const kingstonEastNaracoorte = [
  [-36.8324, 139.8584],
  [-36.8776, 139.9292],
  [-36.8850, 139.9463],
  [-36.9031, 139.9757],
  [-36.9212, 140.0020],
  [-36.9229, 140.0059],
  [-36.9370, 140.0608],
  [-36.9378, 140.0685],
  [-36.9376, 140.0733],
  [-36.9314, 140.1060],
  [-36.9314, 140.1112],
  [-36.9345, 140.1262],
  [-36.9368, 140.1343],
  [-36.9398, 140.1941],
  [-36.9435, 140.2374],
  [-36.9449, 140.2424],
  [-36.9727, 140.3029],
  [-36.9737, 140.3062],
  [-36.9739, 140.3093],
  [-36.9711, 140.3880],
  [-36.9719, 140.4199],
  [-36.9695, 140.4341],
  [-36.9614, 140.4598],
  [-36.9607, 140.4636],
  [-36.9587, 140.4835],
  [-36.9554, 140.4964],
  [-36.9550, 140.5017],
  [-36.9610, 140.5511],
  [-36.9658, 140.5687],
  [-36.9661, 140.5732],
  [-36.9642, 140.5964],
  [-36.9610, 140.6064],
  [-36.9601, 140.6112],
  [-36.9594, 140.6209],
  [-36.9597, 140.6255],
  [-36.9589, 140.6505],
  [-36.9565, 140.6745],
  [-36.9567, 140.6847],
  [-36.9594, 140.7032],
  [-36.9606, 140.7074],
  [-36.9609, 140.7124],
  [-36.9579, 140.7313]
];

export const kingston: Line = {
  name: 'Kingston',
  state: 'SA',
  segments: [
    {
      segments: [kingstonKingstonEast],
      history: {
        opened: {
          date: '1876-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingstonEastNaracoorte],
      history: {
        opened: {
          date: '1876-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          gauge: 'broad'
        }, {
          date: '1987-11-28',
          status: 'closed'
        }]
      }
    }
  ]
};
