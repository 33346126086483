import { Line } from "../../trackTypes";

const ballaratEastEureka = [
  [-37.5583, 143.8800],
  [-37.5584, 143.8812],
  [-37.5586, 143.8818],
  [-37.5590, 143.8824],
  [-37.5598, 143.8833],
  [-37.5606, 143.8838],
  [-37.5615, 143.8841],
  [-37.5622, 143.8842],
  [-37.5628, 143.8840]
];

const eurekaBuninyong = [
  [-37.5628, 143.8840],
  [-37.5854, 143.8779],
  [-37.5898, 143.8770],
  [-37.5927, 143.8771],
  [-37.5939, 143.8769],
  [-37.5950, 143.8766],
  [-37.6002, 143.8748],
  [-37.6017, 143.8747],
  [-37.6189, 143.8811],
  [-37.6203, 143.8818],
  [-37.6213, 143.8823],
  [-37.6235, 143.8826],
  [-37.6284, 143.8822],
  [-37.6335, 143.8819],
  [-37.6343, 143.8821],
  [-37.6349, 143.8826],
  [-37.6367, 143.8849],
  [-37.6387, 143.8882],
  [-37.6401, 143.8892],
  [-37.6416, 143.8895],
  [-37.6443, 143.8889],
  [-37.6450, 143.8889],
  [-37.6454, 143.8892],
  [-37.6460, 143.8892],
  [-37.6496, 143.8882]
];

export const buninyong: Line = {
  name: 'Buninyong',
  state: 'VIC',
  segments: [
    {
      segments: [ballaratEastEureka],
      history: {
        opened: {
          date: '1889-09-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eurekaBuninyong],
      history: {
        opened: {
          date: '1889-09-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1947-03-01',
          status: 'closed'
        }]
      }
    }
  ]
};
