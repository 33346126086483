import { Line } from "../../../trackTypes";

const line = [
  [-27.5382, 152.9801],
  [-27.5376, 152.9806],
  [-27.5373, 152.9810],
  [-27.5370, 152.9817],
  [-27.5332, 152.9894],
  [-27.5286, 152.9955],
  [-27.5279, 152.9969],
  [-27.5258, 153.0033],
  [-27.5257, 153.0041],
  [-27.5258, 153.0051],
  [-27.5261, 153.0060],
  [-27.5266, 153.0068],
  [-27.5267, 153.0075],
  [-27.5267, 153.0121],
  [-27.5265, 153.0128],
  [-27.5263, 153.0131],
  [-27.5260, 153.0135]
];

const corindaJunction = [
  [-27.5370, 152.9817],
  [-27.5371, 152.9812],
  [-27.5370, 152.9808],
  [-27.5368, 152.9805],
  [-27.5363, 152.9801]
];

const yeerongpillyJunction = [
  [-27.5267, 153.0121],
  [-27.5268, 153.0127],
  [-27.5270, 153.0131],
  [-27.5272, 153.0133]
];

export const tennyson: Line = {
  name: 'Tennyson',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1916-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [corindaJunction],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [yeerongpillyJunction],
      history: {
        opened: {
          date: '1885-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
