import { Line } from "../../trackTypes";

const junctionHuttWorkshops = [
  [-41.2230, 174.9083],
  [-41.2243, 174.9073],
  [-41.2253, 174.9070],
  [-41.2265, 174.9071],
  [-41.2349, 174.9124]
];

const huttParkGracefield = [
  [-41.2349, 174.9124],
  [-41.2369, 174.9137],
  [-41.2379, 174.9139],
  [-41.2389, 174.9136],
  [-41.2457, 174.9086],
  [-41.2474, 174.9068]
];

export const gracefield: Line = {
  name: 'Gracefield',
  state: 'NZ',
  segments: [
    {
      segments: [junctionHuttWorkshops],
      history: {
        opened: {
          date: '1929-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [huttParkGracefield],
      history: {
        opened: {
          date: '1943-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1990-01-01',
          electrified: undefined
        }, {
          date: '2002-04-30',
          status: 'closed'
        }]
      }
    }
  ]
}
