import { Line } from "../../../trackTypes";

const line = [
  [-38.33676, 144.74421],
  [-38.33705, 144.74365],
  [-38.33716, 144.74334],
  [-38.33743, 144.74246],
  [-38.33759, 144.74220],
  [-38.33775, 144.74202],
  [-38.33834, 144.74146],
  [-38.33849, 144.74142],
  [-38.33873, 144.74147],
  [-38.33886, 144.74140],
  [-38.33894, 144.74127],
  [-38.34000, 144.73669],
  [-38.34011, 144.73637],
  [-38.34027, 144.73615],
  [-38.34412, 144.73178],
  [-38.34430, 144.73153],
  [-38.34443, 144.73128],
  [-38.34457, 144.73092],
  [-38.34489, 144.72967],
  [-38.34498, 144.72920],
  [-38.34505, 144.72905],
  [-38.34509, 144.72898],
  [-38.34534, 144.72880],
  [-38.34561, 144.72863],
  [-38.34570, 144.72854],
  [-38.34573, 144.72843],
  [-38.34587, 144.72820],
  [-38.34600, 144.72812],
  [-38.34611, 144.72814]
];

export const sorentoTram: Line = {
  name: 'Sorento Tram',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1889-12-25',
          tracks: 1,
          gauge: 'standard', //horse tram
          trackType: 'tram'
        },
        trackChange: [{
          date: '1920-03-01',
          status: 'closed'
        }]
      }
    }
  ]
}
