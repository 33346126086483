import { Line } from "../../../trackTypes";

const finucaneIsland = [
  [-20.3125, 118.5593],
  [-20.3116, 118.5593],
  [-20.3028, 118.5562],
  [-20.3014, 118.5564],
  [-20.2990, 118.5574],
  [-20.2978, 118.5585],
  [-20.2965, 118.5613],
  [-20.2962, 118.5629],
  [-20.2967, 118.5648],
  [-20.2979, 118.5661],
  [-20.2994, 118.5663],
  [-20.3005, 118.5659],
  [-20.3020, 118.5648],
  [-20.3125, 118.5593],
  [-20.3239, 118.5534],
  [-20.3526, 118.5456],
  [-20.3569, 118.5457],
  [-20.3607, 118.5474],
  [-20.3636, 118.5507],
  [-20.3881, 118.5971],
  [-20.3890, 118.6000],
  [-20.3938, 118.6362]
];

const portHeadlandJunctionSouth = [
  [-20.3938, 118.6362],
  [-20.3940, 118.6396],
  [-20.3938, 118.6413],
  [-20.3921, 118.6437]
];

const portHeadlandJunctionNorth = [
  [-20.3938, 118.6362],
  [-20.3946, 118.6383],
  [-20.3958, 118.6394],
  [-20.3972, 118.6400],
  [-20.3989, 118.6402]
];

const headlandCross = [
  [-20.3938, 118.6362],
  [-20.3936, 118.6395],
  [-20.3906, 118.6562]
];

const portHeadlandWestJunction = [
  [-20.4017, 118.6394],
  [-20.4044, 118.6407],
  [-20.4056, 118.6430],
  [-20.4051, 118.6456],
  [-20.4033, 118.6473],
  [-20.3958, 118.6498],
  [-20.3922, 118.6526],
  [-20.3906, 118.6562]
];

const finucaneIslandMountGoldsworthy = [
  [-20.3906, 118.6562],
  [-20.3895, 118.6621],
  [-20.3886, 118.6727],
  [-20.3890, 118.6882],
  [-20.3881, 118.7168],
  [-20.3879, 118.7183],
  [-20.3671, 118.8160],
  [-20.3645, 118.9223],
  [-20.3634, 118.9435],
  [-20.3591, 118.9870],
  [-20.3592, 119.0237],
  [-20.3407, 119.1778],
  [-20.3390, 119.1826],
  [-20.3293, 119.1941],
  [-20.3274, 119.1975],
  [-20.3267, 119.2005],
  [-20.3248, 119.2411],
  [-20.3225, 119.2468],
  [-20.3105, 119.2595],
  [-20.3087, 119.2629],
  [-20.3082, 119.2669],
  [-20.3237, 119.4490],
  [-20.3257, 119.4539],
  [-20.3296, 119.4579],
  [-20.3320, 119.4628],
  [-20.3358, 119.4996],
  [-20.3400, 119.5193],
  [-20.3396, 119.5305],
  [-20.3398, 119.5325],
  [-20.3411, 119.5344],
  [-20.3464, 119.5379],
  [-20.3470, 119.5395],
  [-20.3466, 119.5411],
  [-20.3456, 119.5425]
];

const mountGoldsworthyShayGap = [
  [-20.3396, 119.5305],
  [-20.3387, 119.5339],
  [-20.3370, 119.5375],
  [-20.3369, 119.5404],
  [-20.3375, 119.5422],
  [-20.3383, 119.5459],
  [-20.3380, 119.5492],
  [-20.3341, 119.5635],
  [-20.3337, 119.5675],
  [-20.3418, 119.6781],
  [-20.3653, 119.8334],
  [-20.3858, 119.9159],
  [-20.3876, 119.9198],
  [-20.3976, 119.9331],
  [-20.4019, 119.9440],
  [-20.4028, 119.9523],
  [-20.4016, 119.9667],
  [-20.4045, 119.9733],
  [-20.4080, 119.9752],
  [-20.4139, 119.9743],
  [-20.4177, 119.9746],
  [-20.4223, 119.9763],
  [-20.4263, 119.9764],
  [-20.4318, 119.9752],
  [-20.4358, 119.9759],
  [-20.4384, 119.9787],
  [-20.4462, 119.9965],
  [-20.4488, 120.0007],
  [-20.4528, 120.0046],
  [-20.4820, 120.0662],
  [-20.4905, 120.0867],
  [-20.4928, 120.0896],
  [-20.4954, 120.0914],
  [-20.4968, 120.0934],
  [-20.4986, 120.0998],
  [-20.5001, 120.1022],
  [-20.5033, 120.1040],

];

const shayGapYarrieMine = [
  [-20.5033, 120.1040],
  [-20.5059, 120.1048],
  [-20.5080, 120.1059],
  [-20.5104, 120.1084],
  [-20.5207, 120.1282],
  [-20.5213, 120.1329],
  [-20.5202, 120.1396],
  [-20.5196, 120.1484],
  [-20.5204, 120.1530],
  [-20.5221, 120.1580],
  [-20.5235, 120.1604],
  [-20.5257, 120.1622],
  [-20.5290, 120.1639],
  [-20.5306, 120.1651],
  [-20.5333, 120.1683],
  [-20.5342, 120.1699],
  [-20.5361, 120.1746],
  [-20.5390, 120.1780],
  [-20.5526, 120.1858],
  [-20.5560, 120.1886],
  [-20.5564, 120.1893],
  [-20.5566, 120.1901],
  [-20.5569, 120.1908],
  [-20.5581, 120.1923],
  [-20.5586, 120.1934],
  [-20.5592, 120.1940],
  [-20.5624, 120.1963],
  [-20.5645, 120.1989],
  [-20.5709, 120.2142],
  [-20.5751, 120.2210],
  [-20.5831, 120.2296],
  [-20.5861, 120.2351],
  [-20.5878, 120.2420],
  [-20.5899, 120.2456],
  [-20.5961, 120.2510],
  [-20.6215, 120.2913],
  [-20.6299, 120.3020],
  [-20.6305, 120.3043],
  [-20.6295, 120.3067],
  [-20.6274, 120.3079],
  [-20.6249, 120.3074],
  [-20.6249, 120.3074],
  [-20.6232, 120.3038],
  [-20.6236, 120.2970],
  [-20.6233, 120.2952],
  [-20.6228, 120.2936],
  [-20.6215, 120.2913]
];

const nimingarra = [
  [-20.4462, 119.9965],
  [-20.4475, 120.0002],
  [-20.4476, 120.0026],
  [-20.4469, 120.0048],
  [-20.4420, 120.0113],
  [-20.4357, 120.0165],
  [-20.4337, 120.0173],
  [-20.4293, 120.0177],
  [-20.4277, 120.0170],
  [-20.4247, 120.0144],
  [-20.4226, 120.0136],
  [-20.4210, 120.0139],
  [-20.4192, 120.0153],
  [-20.4167, 120.0211]
];

export const mountGoldsworthy: Line = {
  name: 'Mount Goldsworthy',
  state: 'WA',
  segments: [
    {
      segments: [finucaneIsland],
      history: {
        opened: {
          date: '1966-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portHeadlandJunctionNorth],
      history: {
        opened: {
          date: '1969-01-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portHeadlandJunctionSouth],
      history: {
        opened: {
          date: '1969-01-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portHeadlandWestJunction],
      history: {
        opened: {
          date: '1969-01-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [headlandCross],
      history: {
        opened: {
          date: '1966-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-01-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [finucaneIslandMountGoldsworthy],
      history: {
        opened: {
          date: '1966-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mountGoldsworthyShayGap],
      history: {
        opened: {
          date: '1972-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [shayGapYarrieMine],
      history: {
        opened: {
          date: '1993-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nimingarra],
      history: {
        opened: {
          date: '1972-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
