import { Line } from "../../../trackTypes";

const line = [
  [-27.4773, 153.0647],
  [-27.4771, 153.0655],
  [-27.4772, 153.0663],
  [-27.4777, 153.0691],
  [-27.4794, 153.0720],
  [-27.4808, 153.0727],
  [-27.4817, 153.0735],
  [-27.4825, 153.0752],
  [-27.4830, 153.0766],
  [-27.4836, 153.0774],
  [-27.4845, 153.0776],
  [-27.4866, 153.0771],
  [-27.4883, 153.0772],
  [-27.4897, 153.0780],
  [-27.4924, 153.0807],
  [-27.4929, 153.0816],
  [-27.4930, 153.0836],
  [-27.4932, 153.0843],
  [-27.4936, 153.0848],
  [-27.4954, 153.0862],
  [-27.4957, 153.0867],
  [-27.4959, 153.0872],
  [-27.4953, 153.0910],
  [-27.4958, 153.0951],
  [-27.4958, 153.0957],
  [-27.4955, 153.0968],
  [-27.4955, 153.0978],
  [-27.4966, 153.0994],
  [-27.4979, 153.0998],
  [-27.4998, 153.1002],
  [-27.5003, 153.1009],
  [-27.5002, 153.1022],
  [-27.5001, 153.1030],
  [-27.5021, 153.1153]
];

export const belmontTram: Line = {
  name: 'Belmont Tramway',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1912-05-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-10-30',
          status: 'closed'
        }]
      }
    }
  ]
}
