import { Line } from "../../trackTypes";

const barnesCaldwell = [
  [-36.0274, 144.7905],
  [-36.0254, 144.7903],
  [-36.0234, 144.7896],
  [-36.0213, 144.7882],
  [-36.0200, 144.7867],
  [-35.9451, 144.6890],
  [-35.9414, 144.6858],
  [-35.6974, 144.5416],
  [-35.6333, 144.5051]
];

const caldwellMoulamein = [
  [-35.6333, 144.5051],
  [-35.5318, 144.4488],
  [-35.5293, 144.4470],
  [-35.4383, 144.3673],
  [-35.3843, 144.3090],
  [-35.3695, 144.2912],
  [-35.3674, 144.2894],
  [-35.3596, 144.2828],
  [-35.3578, 144.2817],
  [-35.3383, 144.2697],
  [-35.2926, 144.2442],
  [-35.2886, 144.2430],
  [-35.2789, 144.2424],
  [-35.2751, 144.2413],
  [-35.2708, 144.2393],
  [-35.2641, 144.2350],
  [-35.2618, 144.2326],
  [-35.2218, 144.1776],
  [-35.1969, 144.1432],
  [-35.1950, 144.1413],
  [-35.1165, 144.0620],
  [-35.1040, 144.0477],
  [-35.1019, 144.0461],
  [-35.1002, 144.0453],
  [-35.0924, 144.0435],
  [-35.0897, 144.0423],
  [-35.0869, 144.0402]
];

const moulameinBalranald = [
  [-35.0869, 144.0402],
  [-35.0814, 144.0359],
  [-35.0801, 144.0342],
  [-35.0629, 143.9960],
  [-35.0614, 143.9935],
  [-35.0494, 143.9771],
  [-34.9898, 143.8949],
  [-34.9881, 143.8933],
  [-34.9128, 143.8321],
  [-34.8930, 143.8141],
  [-34.8720, 143.7948],
  [-34.8509, 143.7755],
  [-34.7844, 143.7164],
  [-34.7267, 143.6463],
  [-34.7073, 143.6239],
  [-34.7057, 143.6213],
  [-34.7050, 143.6192],
  [-34.7026, 143.5972],
  [-34.7015, 143.5937],
  [-34.6995, 143.5907],
  [-34.6968, 143.5876],
  [-34.6950, 143.5862],
  [-34.6560, 143.5603]
];

export const moulamein: Line = {
  name: 'Moulamein',
  state: 'NSW',
  segments: [
    {
      segments: [barnesCaldwell],
      history: {
        opened: {
          date: '1926-03-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-05-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [caldwellMoulamein],
      history: {
        opened: {
          date: '1926-03-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-03-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moulameinBalranald],
      history: {
        opened: {
          date: '1926-03-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    }
  ]
};
