import { Line } from "../../../trackTypes";

const clydeRosehill = [
  [-33.8355, 151.0164],
  [-33.8348, 151.0159],
  [-33.8337, 151.0157],
  [-33.8330, 151.0159],
  [-33.8324, 151.0164],
  [-33.8310, 151.0185],
  [-33.8296, 151.0200],
  [-33.8281, 151.0209],
  [-33.8263, 151.0215],
  [-33.8231, 151.0221],
  [-33.8211, 151.0223],
  [-33.8205, 151.0227],
  [-33.8198, 151.0234]
];

const rosehillCamellia = [
  [-33.8198, 151.0234],
  [-33.8191, 151.0240],
  [-33.81871, 151.02428]//paramatta light rail point
];

const camelliaCarlingford = [
  [-33.81871, 151.02428],
  [-33.81601, 151.02519],
  [-33.81565, 151.02535],
  [-33.81535, 151.02552],
  [-33.81247, 151.02721],
  [-33.81189, 151.02761],
  [-33.81091, 151.02837],
  [-33.80948, 151.02972],
  [-33.80911, 151.03002],
  [-33.80881, 151.03020],
  [-33.80849, 151.03034],
  [-33.80503, 151.03173],
  [-33.80472, 151.03192],
  [-33.80452, 151.03206],
  [-33.80414, 151.03251],
  [-33.80392, 151.03291],
  [-33.80269, 151.03560],
  [-33.80252, 151.03612],
  [-33.80222, 151.03733],
  [-33.80210, 151.03775],
  [-33.80191, 151.03811],
  [-33.80162, 151.03851],
  [-33.80133, 151.03878],
  [-33.80039, 151.03954],
  [-33.80000, 151.03973],
  [-33.79977, 151.03986],
  [-33.79926, 151.03994],
  [-33.79874, 151.03988],
  [-33.79775, 151.03954],
  [-33.79735, 151.03949],
  [-33.79689, 151.03955],
  [-33.79655, 151.03966],
  [-33.79619, 151.03988],
  [-33.79324, 151.04195],
  [-33.79294, 151.04224],
  [-33.79271, 151.04254],
  [-33.79249, 151.04289],
  [-33.79193, 151.04418],
  [-33.79172, 151.04458],
  [-33.79149, 151.04486],
  [-33.79123, 151.04510],
  [-33.79082, 151.04536],
  [-33.79039, 151.04552],
  [-33.79003, 151.04556],
  [-33.78958, 151.04552],
  [-33.78920, 151.04540],
  [-33.78726, 151.04458],
  [-33.78678, 151.04449],
  [-33.78641, 151.04451],
  [-33.78607, 151.04459],
  [-33.78451, 151.04521],
  [-33.78379, 151.04553],
  [-33.78347, 151.04578],
  [-33.78134, 151.04775]
];

const sandownLine = [
  [-33.8198, 151.0234],
  [-33.8194, 151.0243],
  [-33.8193, 151.0252],
  [-33.8203, 151.0355],
  [-33.8203, 151.0362],
  [-33.8200, 151.0369],
  [-33.8197, 151.0374],
  [-33.8187, 151.0386],
  [-33.8184, 151.0392],
  [-33.8183, 151.0396],
  [-33.8186, 151.0421],
];

const sandownBranchSouth = [
  [-33.8203, 151.0362],
  [-33.8204, 151.0370],
  [-33.8207, 151.0379],
  [-33.8208, 151.0384],
  [-33.8208, 151.0412],
  [-33.8209, 151.0422],
  [-33.8212, 151.0433],
  [-33.8218, 151.0448]
];

export const carlingford: Line = {
  name: 'Carlingford',
  state: 'NSW',
  segments: [
    {
      segments: [clydeRosehill],
      history: {
        opened: {
          date: '1888-11-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1936-12-12',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1902-06-19',
          tracks: 2
        }, {
          date: '2020-01-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rosehillCamellia],
      history: {
        opened: {
          date: '1888-11-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2020-01-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [camelliaCarlingford],//reopen with opening of p1
      history: {
        opened: {
          date: '1896-04-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2020-01-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandownLine],
      history: {
        opened: {
          date: '1888-11-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2002-12-01',
          electrified: undefined
        }, {
          date: '2019-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandownBranchSouth],
      history: {
        opened: {
          date: '1888-11-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2002-12-01',
          electrified: undefined
        }, {
          date: '2019-07-01',
          status: 'closed'
        }]
      }
    }
  ]
};
