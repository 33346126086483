import { Line } from "../../../../trackTypes";

const bealeyAvenue = [
  [-43.52883, 172.63661],
  [-43.52100, 172.63659]
];

const cranfordStreet = [
  [-43.52100, 172.63659],
  [-43.51496, 172.63659],
  [-43.51352, 172.63659],
  [-43.51347, 172.63663],
  [-43.51343, 172.63671],
  [-43.51342, 172.63791],
  [-43.51339, 172.63801],
  [-43.51334, 172.63807],
  [-43.51313, 172.63810],
  [-43.51239, 172.63826],
  [-43.51209, 172.63829],
  [-43.50901, 172.63830],
  [-43.50884, 172.63827],
  [-43.50866, 172.63818],
  [-43.50855, 172.63809],
  [-43.50657, 172.63632]
];

const stAlbans = [
  [-43.52100, 172.63659],
  [-43.52094, 172.63665],
  [-43.52092, 172.63671],
  [-43.52088, 172.63686],
  [-43.52084, 172.64580],
  [-43.52081, 172.64592],
  [-43.52075, 172.64597],
  [-43.50827, 172.64591]
];

export const line6: Line = {
  name: 'Line 6',
  state: 'NZ',
  segments: [
    {
      segments: [bealeyAvenue],
      history: {
        opened: {
          date: '1906-12-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-07-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stAlbans],
      history: {
        opened: {
          date: '1906-12-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-06-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cranfordStreet],
      history: {
        opened: {
          date: '1910-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-07-26',
          status: 'closed'
        }]
      }
    }
  ]
}
