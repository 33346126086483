import { Line } from "../../trackTypes";

const maryvaleMill = [
  [-38.2358, 146.4388],
  [-38.2352, 146.4400],
  [-38.2344, 146.4408],
  [-38.2335, 146.4411],
  [-38.2055, 146.4472],
  [-38.2036, 146.4474],
  [-38.2028, 146.4472],
  [-38.2019, 146.4467],
  [-38.2006, 146.4449],
  [-38.1998, 146.4444],
  [-38.1989, 146.4442],
  [-38.1950, 146.4442],
  [-38.1862, 146.4463],
  [-38.1845, 146.4463],
  [-38.1841, 146.4462],
  [-38.1832, 146.4457],
  [-38.1825, 146.4455],
  [-38.1822, 146.4455],
  [-38.1787, 146.4464]
];

const saleWharf = [
  [-38.1070, 147.0607],
  [-38.1071, 147.0600],
  [-38.1075, 147.0592],
  [-38.1083, 147.0587],
  [-38.1092, 147.0588],
  [-38.1105, 147.0593],
  [-38.1111, 147.0597],
  [-38.1118, 147.0605],
  [-38.1119, 147.0609],
  [-38.1119, 147.0623],
  [-38.1121, 147.0632],
  [-38.1135, 147.0646]
];

const bairnsdaleWharf = [
  [-37.8285, 147.6287],
  [-37.8286, 147.6297],
  [-37.8289, 147.6308],
  [-37.8294, 147.6328],
  [-37.8295, 147.6345],
  [-37.8282, 147.6400],
  [-37.8278, 147.6407],
  [-37.8273, 147.6412],
  [-37.8267, 147.6414],
  [-37.8260, 147.6411]
];

const portAlbert = [
  [-38.6095, 146.6674],
  [-38.6095, 146.6695],
  [-38.6102, 146.6715],
  [-38.6119, 146.6730],
  [-38.6352, 146.6798],
  [-38.6608, 146.6871],
  [-38.6625, 146.6879],
  [-38.6655, 146.6894],
  [-38.6716, 146.6936]
];

const barryBeach = [
  [-38.6739, 146.3953],
  [-38.6743, 146.3978],
  [-38.6749, 146.3989],
  [-38.6757, 146.3996],
  [-38.6770, 146.3998],
  [-38.6935, 146.3963],
  [-38.7056, 146.3893],
  [-38.7068, 146.3888],
  [-38.7089, 146.3891]
];

const morwellBriquette = [
  [-38.2419, 146.4179],
  [-38.2423, 146.4192],
  [-38.2431, 146.4201],
  [-38.2445, 146.4205],
  [-38.2508, 146.4192]
];

const briquettePowerHouse = [
  [-38.2508, 146.4192],
  [-38.2518, 146.4189],
  [-38.2604, 146.4144],
  [-38.2615, 146.4143],
  [-38.2623, 146.4148],
  [-38.2629, 146.4156],
  [-38.2644, 146.4210],
  [-38.2655, 146.4227],
  [-38.2665, 146.4231],
  [-38.2747, 146.4195],
  [-38.2772, 146.4153],
  [-38.2777, 146.4107],
  [-38.2782, 146.4063],
  [-38.2782, 146.4015],
  [-38.2772, 146.3990],
  [-38.2744, 146.3957],
  [-38.2739, 146.3955],
  [-38.2716, 146.3961],
  [-38.2708, 146.3957],
  [-38.2698, 146.3946],
  [-38.2697, 146.3937],
  [-38.2726, 146.3893],
  [-38.2729, 146.3892],
  [-38.2735, 146.3899]
];

const portWelshpool = [
  [-38.6653, 146.4421],
  [-38.6655, 146.4432],
  [-38.6657, 146.4442],
  [-38.6664, 146.4455],
  [-38.6717, 146.4506],
  [-38.6800, 146.4584],
  [-38.6915, 146.4658],
  [-38.6923, 146.4662],
  [-38.6936, 146.4662],
  [-38.6948, 146.4660],
  [-38.6996, 146.4649],
  [-38.6998, 146.4649],
  [-38.7000, 146.4650],
  [-38.7000, 146.4653],
  [-38.7000, 146.4657],
  [-38.7000, 146.4659],
  [-38.7001, 146.4661],
  [-38.7003, 146.4662],
  [-38.7027, 146.4668]
];

export const easternFreight: Line = {
  name: 'Eastern Freight',
  state: 'VIC',
  segments: [
    {
      segments: [maryvaleMill],
      history: {
        opened: {
          date: '1938-11-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [saleWharf],
      history: {
        opened: {
          date: '1890-09-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-12-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bairnsdaleWharf],
      history: {
        opened: {
          date: '1888-07-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-07-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portAlbert],
      history: {
        opened: {
          date: '1892-01-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-02-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [barryBeach],
      history: {
        opened: {
          date: '1969-04-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morwellBriquette],
      history: {
        opened: {
          date: '1951-08-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2003-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [briquettePowerHouse],
      history: {
        opened: {
          date: '1961-07-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portWelshpool],
      history: {
        opened: {
          date: '1905-06-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1941-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
