import { Line } from "../../../trackTypes";

const southBrisbaneClevelandJunction = [
  [-27.4941, 153.0313],
  [-27.4955, 153.0331],
  [-27.4960, 153.0340]
];

const duttonParkClevelandJunction = [
  [-27.4963, 153.0314],
  [-27.4959, 153.0324],
  [-27.4958, 153.0330],
  [-27.4960, 153.0340]
];

const parkRoadMurarri = [
  [-27.4960, 153.0340],
  [-27.4965, 153.0362],
  [-27.4967, 153.0385],
  [-27.4967, 153.0397],
  [-27.4928, 153.0513],
  [-27.4918, 153.0531],
  [-27.4905, 153.0545],
  [-27.4888, 153.0556],
  [-27.4868, 153.0563],
  [-27.4857, 153.0568],
  [-27.4851, 153.0574],
  [-27.4832, 153.0591],
  [-27.4823, 153.0603],
  [-27.4819, 153.0613],
  [-27.4815, 153.0624],
  [-27.4811, 153.0631],
  [-27.4805, 153.0636],
  [-27.4799, 153.0637],
  [-27.4788, 153.0636],
  [-27.4780, 153.0638],
  [-27.4775, 153.0643],
  [-27.4770, 153.0651],
  [-27.4763, 153.0657],
  [-27.4751, 153.0661],
  [-27.4743, 153.0667],
  [-27.4738, 153.0675],
  [-27.4732, 153.0680],
  [-27.4705, 153.0696],
  [-27.4701, 153.0702],
  [-27.4698, 153.0709],
  [-27.4698, 153.0716],
  [-27.4700, 153.0726],
  [-27.4700, 153.0732],
  [-27.4698, 153.0739],
  [-27.4670, 153.0797],
  [-27.4666, 153.0811],
  [-27.4652, 153.0887],
  [-27.4653, 153.0913],
  [-27.4666, 153.0965],
  [-27.4667, 153.0977],
  [-27.4665, 153.0987],
  [-27.4651, 153.1013],
  [-27.4649, 153.1023],
  [-27.4647, 153.1054]
];

const murarrieHemmant = [
  [-27.4647, 153.1054],
  [-27.4645, 153.1063],
  [-27.4623, 153.1095],
  [-27.4617, 153.1098],
  [-27.4612, 153.1100],
  [-27.4587, 153.1101],
  [-27.4577, 153.1105],
  [-27.4545, 153.1122],
  [-27.4539, 153.1127],
  [-27.4536, 153.1133],
  [-27.4531, 153.1152],
  [-27.4518, 153.1178],
  [-27.4504, 153.1197],
  [-27.4484, 153.1237],
  [-27.4472, 153.1272]
];

const hemmantLindum = [
  [-27.4472, 153.1272],
  [-27.4433, 153.1418],
  [-27.4423, 153.1440],
  [-27.4405, 153.1478],
  [-27.4395, 153.1515],
  [-27.4393, 153.1522]
];

const lindumManly = [
  [-27.4393, 153.1522],
  [-27.4391, 153.1525],
  [-27.4386, 153.1531],
  [-27.4377, 153.1538],
  [-27.4371, 153.1545],
  [-27.4367, 153.1554],
  [-27.4366, 153.1566],
  [-27.4373, 153.1590],
  [-27.4374, 153.1632],
  [-27.4377, 153.1643],
  [-27.4383, 153.1651],
  [-27.4431, 153.1683],
  [-27.4483, 153.1732],
  [-27.4490, 153.1736],
  [-27.4508, 153.1744],
  [-27.4521, 153.1753],
  [-27.4550, 153.1793],
  [-27.4554, 153.1797],
  [-27.4562, 153.1801],
  [-27.4570, 153.1803]
];

const manlyLota = [
  [-27.4570, 153.1803],
  [-27.4582, 153.1804],
  [-27.4588, 153.1809],
  [-27.4592, 153.1815],
  [-27.4597, 153.1826],
  [-27.4601, 153.1832],
  [-27.4608, 153.1839],
  [-27.4637, 153.1851],
  [-27.4646, 153.1857],
  [-27.4658, 153.1875],
  [-27.4663, 153.1881],
  [-27.4672, 153.1886],
  [-27.4684, 153.1888],
  [-27.4691, 153.1887],
  [-27.4700, 153.1885]
];

const lotaThornsdale = [
  [-27.4700, 153.1885],
  [-27.4752, 153.1878],
  [-27.4774, 153.1878],
  [-27.4789, 153.1884],
  [-27.4853, 153.1921],
  [-27.4863, 153.1929]
];

const thornsdaleWellingtonPoint = [
  [-27.4863, 153.1929],
  [-27.4877, 153.1945],
  [-27.4905, 153.1995],
  [-27.4914, 153.2018],
  [-27.4942, 153.2203],
  [-27.4942, 153.2223],
  [-27.4929, 153.2287],
  [-27.4928, 153.2317],
  [-27.4944, 153.2418]
];

const wellingtonPointCleveland = [
  [-27.4944, 153.2418],
  [-27.4946, 153.2426],
  [-27.4951, 153.2439],
  [-27.4961, 153.2450],
  [-27.4974, 153.2457],
  [-27.4997, 153.2463],
  [-27.5019, 153.2474],
  [-27.5104, 153.2549],
  [-27.5119, 153.2557],
  [-27.5131, 153.2559],
  [-27.5175, 153.2556],
  [-27.5184, 153.2556],
  [-27.5197, 153.2562],
  [-27.5230, 153.2594],
  [-27.5236, 153.2604],
  [-27.5240, 153.2619],
  [-27.5242, 153.2659]
];

const clevelandCleveland1 = [
  [-27.5242, 153.2659],
  [-27.5248, 153.2780]
];

const cleveland1Cleveland2 = [
  [-27.5248, 153.2780],
  [-27.5249, 153.2802],
  [-27.5222, 153.2860],
  [-27.5219, 153.2862],
  [-27.5206, 153.2865]
];

export const cleveland: Line = {
  name: 'Cleveland',
  state: 'QLD',
  segments: [
    {
      segments: [southBrisbaneClevelandJunction],
      history: {
        opened: {
          date: '1891-12-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-06-17',
          tracks: 2
        }, {
          date: '1996-07-15',
          parallelStandard: true
        }, {
          date: '1983-10-15',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
        }]
      }
    },
    {
      segments: [duttonParkClevelandJunction],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-06-17',
          tracks: 2
        }, {
          date: '1983-10-15',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
        }, {
          date: '1996-07-15',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [parkRoadMurarri],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-06-17',
          tracks: 2
        }, {
          date: '1996-07-15',
          parallelStandard: true
        }, {
          date: '1983-10-15',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [murarrieHemmant],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1913-01-01',
          tracks: 2
        }, {
          date: '1996-07-15',
          parallelStandard: true
        }, {
          date: '1983-10-15',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [hemmantLindum],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-12-08',
          tracks: 2
        }, {
          date: '1996-07-15',
          parallelStandard: true
        }, {
          date: '1983-10-15',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [lindumManly],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-12-08',
          tracks: 2
        }, {
          date: '1983-10-15',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [manlyLota],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-10-15',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [lotaThornsdale],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-10-15',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1960-01-01',
          status: 'closed'
        }, {
          date: '1982-09-25',
          status: 'open'
        }]
      }
    },
    {
      segments: [thornsdaleWellingtonPoint],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-07-26',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1960-01-01',
          status: 'closed'
        }, {
          date: '1986-07-26',
          status: 'open'
        }]
      }
    },
    {
      segments: [wellingtonPointCleveland],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-24',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1960-01-01',
          status: 'closed'
        }, {
          date: '1987-10-24',
          status: 'open'
        }]
      }
    },
    {
      segments: [clevelandCleveland1],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cleveland1Cleveland2],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
