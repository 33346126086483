import { Line } from "../../../../trackTypes";

const adelaideStation = [
  [-34.92167, 138.59700],
  [-34.92155, 138.59942]
];

const botanicGardens = [
  [-34.92155, 138.59942],
  [-34.92127, 138.60520],
  [-34.92124, 138.60567],
  [-34.92102, 138.60964]
];

const northTerrace = [
  [-34.92102, 138.60964],
  [-34.92090, 138.61140],
  [-34.92081, 138.61236],
  [-34.92071, 138.61275],
  [-34.92057, 138.61312],
  [-34.91968, 138.61489]
];

const stPeters = [
  [-34.91968, 138.61489],
  [-34.91580, 138.62245],
  [-34.91565, 138.62265],
  [-34.91547, 138.62289]
];

const payneham = [
  [-34.90960, 138.62779],
  [-34.89974, 138.63616]
];

const payneham2 = [
  [-34.89974, 138.63616],
  [-34.89718, 138.63836]
];

const paradise = [
  [-34.89718, 138.63836],
  [-34.89658, 138.63905],
  [-34.89618, 138.63970],
  [-34.89591, 138.64028],
  [-34.88956, 138.65621],
  [-34.88941, 138.65642],
  [-34.88925, 138.65661],
  [-34.87700, 138.66440],
  [-34.87665, 138.66470],
  [-34.87633, 138.66509],
  [-34.87175, 138.67150]
];

const hackneyDepot = [
  [-34.91968, 138.61489],
  [-34.91954, 138.61498],
  [-34.91940, 138.61501],
  [-34.91787, 138.61466]
];

export const east4: Line = {
  name: 'East4',
  state: 'SA',
  segments: [
    {
      segments: [adelaideStation],
      history: {
        opened: {
          date: '1883-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          status: 'closed'
        }, {
          date: '1910-03-09',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }, {
          date: '2007-10-14',
          status: 'open'
        }]
      }
    },
    {
      segments: [botanicGardens],
      history: {
        opened: {
          date: '1883-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }, {
          date: '2018-10-13',
          status: 'open'
        }]
      }
    },
    {
      segments: [northTerrace],
      history: {
        opened: {
          date: '1883-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stPeters],
      history: {
        opened: {
          date: '1883-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [payneham],
      history: {
        opened: {
          date: '1883-12-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [payneham2],
      history: {
        opened: {
          date: '1884-02-18',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paradise],
      history: {
        opened: {
          date: '1884-02-18',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-11-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hackneyDepot],
      history: {
        opened: {
          date: '1909-03-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    }
  ]
};
