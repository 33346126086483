import { Line } from "../../trackTypes";

const line = [
  [-38.2382, 144.1637],
  [-38.2383, 144.1610],
  [-38.2384, 144.1582],
  [-38.2388, 144.1563],
  [-38.2397, 144.1550],
  [-38.2412, 144.1534],
  [-38.2445, 144.1506],
  [-38.2477, 144.1479],
  [-38.2503, 144.1455],
  [-38.2631, 144.1246],
  [-38.2662, 144.1209],
  [-38.2683, 144.1190],
  [-38.2708, 144.1153],
  [-38.2782, 144.1061],
  [-38.2909, 144.0906],
  [-38.2913, 144.0903],
  [-38.2923, 144.0894],
  [-38.2984, 144.0884],
  [-38.2997, 144.0875],
  [-38.3003, 144.0870],
  [-38.3031, 144.0847],
  [-38.3039, 144.0836],
  [-38.3063, 144.0777],
  [-38.3074, 144.0758],
  [-38.3100, 144.0722],
  [-38.3130, 144.0694],
  [-38.3224, 144.0604],
  [-38.3287, 144.0538],
  [-38.3302, 144.0516],
  [-38.3319, 144.0490],
  [-38.3326, 144.0474],
  [-38.3339, 144.0455],
  [-38.3365, 144.0435],
  [-38.3381, 144.0424],
  [-38.3392, 144.0421],
  [-38.3438, 144.0420],
  [-38.3446, 144.0416],
  [-38.3489, 144.0388],
  [-38.3497, 144.0386],
  [-38.3510, 144.0385],
  [-38.3540, 144.0391]
];

export const wensleydale: Line = {
  name: 'Wensleydale',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1898-03-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-10-12',
          status: 'closed'
        }]
      }
    }
  ]
};
