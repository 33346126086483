import { Line } from "../../../trackTypes";

const line = [
  [-45.8848, 170.3570],
  [-45.8843, 170.3574],
  [-45.8836, 170.3575],
  [-45.8760, 170.3530],
  [-45.8504, 170.3382],
  [-45.8498, 170.3370],
  [-45.8496, 170.3357],
  [-45.8760, 170.2420],
  [-45.8760, 170.2411],
  [-45.8756, 170.2402],
  [-45.8647, 170.2338],
  [-45.8628, 170.2321],
  [-45.8610, 170.2303]
];

export const outram: Line = {
  name: 'Outram',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1877-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-12-05',
          status: 'closed'
        }]
      }
    }
  ]
}
