import { Line } from "../../../trackTypes";

const charingCrossJunction = [
  [-36.75948, 144.27838],
  [-36.75952, 144.27851],
  [-36.75953, 144.27864],
  [-36.75961, 144.27862],
  [-36.75975, 144.27865]
];

const violetStreetLakeWeeroona = [
  [-36.76452, 144.26939],
  [-36.76376, 144.27024],
  [-36.76146, 144.27279],
  [-36.76129, 144.27305],
  [-36.76115, 144.27338],
  [-36.76108, 144.27370],
  [-36.76082, 144.27596],
  [-36.76079, 144.27615],
  [-36.76071, 144.27633],
  [-36.76013, 144.27743],
  [-36.75997, 144.27778],
  [-36.75977, 144.27826],
  [-36.75965, 144.27846],
  [-36.75953, 144.27864],
  [-36.75743, 144.28154],
  [-36.75540, 144.28441],
  [-36.75325, 144.28736],
  [-36.75303, 144.28766],
  [-36.75218, 144.28880],
  [-36.75194, 144.28907],
  [-36.74989, 144.29065]
];

const lakeWeeroonaNorthBendigo = [
  [-36.74989, 144.29065],
  [-36.74984, 144.29066],
  [-36.74974, 144.29064],
  [-36.74966, 144.29057],
  [-36.74803, 144.28829],
  [-36.74797, 144.28823],
  [-36.74792, 144.28821],
  [-36.74784, 144.28821],
  [-36.74576, 144.28889],
  [-36.74562, 144.28898],
  [-36.74552, 144.28907],
  [-36.74458, 144.29010],
  [-36.74449, 144.29016],
  [-36.74442, 144.29018],
  [-36.74429, 144.29014],
  [-36.74364, 144.28964],
  [-36.74356, 144.28960],
  [-36.74346, 144.28959],
  [-36.74337, 144.28961],
  [-36.74325, 144.28969],
  [-36.74291, 144.29007],
  [-36.74278, 144.29020],
  [-36.74186, 144.29092],
  [-36.74172, 144.29102],
  [-36.74156, 144.29110],
  [-36.74105, 144.29132],
  [-36.74095, 144.29134],
  [-36.74088, 144.29131],
  [-36.74080, 144.29126],
  [-36.74057, 144.29099]
];

const eaglehawkLine = [
  [-36.76345, 144.28277],
  [-36.75975, 144.27865],
  [-36.75948, 144.27838],
  [-36.75911, 144.27795],
  [-36.75702, 144.27547],
  [-36.75546, 144.27426],
  [-36.75535, 144.27405],
  [-36.75538, 144.27384],
  [-36.75666, 144.27134],
  [-36.75672, 144.27110],
  [-36.75673, 144.27083],
  [-36.75550, 144.26283],
  [-36.75540, 144.26251],
  [-36.75523, 144.26221],
  [-36.75329, 144.26034],
  [-36.75231, 144.25957],
  [-36.75214, 144.25948],
  [-36.75134, 144.25926],
  [-36.74882, 144.25855],
  [-36.74635, 144.25805],
  [-36.74587, 144.25802],
  [-36.74545, 144.25808],
  [-36.74475, 144.25829],
  [-36.74448, 144.25836],
  [-36.74411, 144.25838],
  [-36.74350, 144.25832],
  [-36.74287, 144.25834],
  [-36.74141, 144.25862],
  [-36.73932, 144.25930],
  [-36.73899, 144.25937],
  [-36.73864, 144.25932],
  [-36.73695, 144.25880],
  [-36.73304, 144.25731],
  [-36.73277, 144.25726],
  [-36.73256, 144.25727],
  [-36.73171, 144.25753],
  [-36.73116, 144.25774],
  [-36.73016, 144.25828],
  [-36.72985, 144.25836],
  [-36.72896, 144.25854],
  [-36.72873, 144.25856],
  [-36.72611, 144.25869],
  [-36.72569, 144.25862],
  [-36.72284, 144.25756],
  [-36.72075, 144.25671],
  [-36.72056, 144.25660],
  [-36.72037, 144.25638],
  [-36.71783, 144.25243],
  [-36.71767, 144.25201],
  [-36.71762, 144.25150]
];

const quaryHill = [
  [-36.76345, 144.28277],
  [-36.76431, 144.28375],
  [-36.76449, 144.28391],
  [-36.76469, 144.28404],
  [-36.76879, 144.28603],
  [-36.76911, 144.28613],
  [-36.76948, 144.28613],
  [-36.78177, 144.28435]
];

const violetStreetGoldenSquare = [
  [-36.76452, 144.26939],
  [-36.76793, 144.26552],
  [-36.76820, 144.26526],
  [-36.76967, 144.26399],
  [-36.76986, 144.26380],
  [-36.77004, 144.26356],
  [-36.77118, 144.26142],
  [-36.77154, 144.26049],
  [-36.77181, 144.25992],
  [-36.77214, 144.25935]
];

const depotJunction = [
  [-36.75303, 144.28766],
  [-36.75310, 144.28762],
  [-36.75318, 144.28762],
  [-36.75325, 144.28767]
];

const tramDepot = [
  [-36.75325, 144.28736],
  [-36.75322, 144.28749],
  [-36.75323, 144.28757],
  [-36.75325, 144.28767],
  [-36.75387, 144.28839],
  [-36.75406, 144.28870],
  [-36.75412, 144.28885],
  [-36.75413, 144.28901],
  [-36.75407, 144.28965]
];

const mollisonStreetDepot = [
  [-36.76446, 144.28269],
  [-36.76373, 144.28260],
  [-36.76345, 144.28276],
  [-36.76272, 144.28377]
];

const gasWorksSpur = [
  [-36.74429, 144.29014],
  [-36.74445, 144.29026],
  [-36.74454, 144.29037],
  [-36.74471, 144.29078],
  [-36.74470, 144.29090],
  [-36.74464, 144.29099],
  [-36.74424, 144.29118]
];

const lakeWeeroonaSpur = [
  [-36.74989, 144.29065],
  [-36.74794, 144.29216]
];

const violetStreetSpur = [
  [-36.76452, 144.26939],
  [-36.76459, 144.26936],
  [-36.76464, 144.26936],
  [-36.76470, 144.26939],
  [-36.76524, 144.26990]
];

export const bendigoTrams: Line = {
  name: 'Bandigo Trams',
  state: 'VIC',
  segments: [
    {
      segments: [violetStreetLakeWeeroona],
      history: {
        opened: {
          date: '1903-04-21',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1972-04-01',
          status: 'closed'
        }, {
          date: '1972-09-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [lakeWeeroonaNorthBendigo],
      history: {
        opened: {
          date: '1942-01-01',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1972-04-01',
          status: 'closed'
        }, {
          date: '1972-09-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [eaglehawkLine],
      history: {
        opened: {
          date: '1890-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1903-04-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1972-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [quaryHill],
      history: {
        opened: {
          date: '1903-04-21',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1972-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [violetStreetGoldenSquare],
      history: {
        opened: {
          date: '1903-04-21',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1972-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tramDepot, depotJunction],
      history: {
        opened: {
          date: '1903-04-21',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        }
      }
    },
    {
      segments: [mollisonStreetDepot],
      history: {
        opened: {
          date: '1890-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1903-04-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gasWorksSpur],
      history: {
        opened: {
          date: '1989-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        }
      }
    },
    {
      segments: [lakeWeeroonaSpur],
      history: {
        opened: {
          date: '1903-04-21',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1972-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [violetStreetSpur],
      history: {
        opened: {
          date: '1972-10-01',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
      }
    },
    {
      segments: [charingCrossJunction],
      history: {
        opened: {
          date: '1903-04-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1972-04-01',
          status: 'closed'
        }]
      }
    }
  ]
};
