import { Line } from "../../../../trackTypes";

const almaRdJunction = [
  [-37.86550, 145.02586],
  [-37.86565, 145.02599],
  [-37.86585, 145.02600]
];

const line = [
  [-37.86574, 145.02639],
  [-37.86575, 145.02613],
  [-37.86585, 145.02600],
  [-37.87248, 145.02475],
  [-37.87280, 145.02469],
  [-37.88675, 145.02201],
  [-37.88689, 145.02199]
];

const brightonCemetery = [
  [-37.88689, 145.02199],
  [-37.88704, 145.02195],
  [-37.90114, 145.01927]
];

const eastBrighton = [
  [-37.90114, 145.01927],
  [-37.91395, 145.01696],
  [-37.91439, 145.01679]
];

const glenHuntlyNorthJunction = [
  [-37.88675, 145.02201],
  [-37.88685, 145.02205],
  [-37.88691, 145.02214]
];

const glenHuntlySouthJunction = [
  [-37.88704, 145.02195],
  [-37.88695, 145.02204],
  [-37.88691, 145.02214]
];

export const south6: Line = {
  name: 'South6',
  state: 'VIC',
  segments: [
    {
      segments: [line, glenHuntlyNorthJunction, almaRdJunction],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [brightonCemetery, glenHuntlySouthJunction],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [eastBrighton],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
