import { Line } from "../../trackTypes";

const line = [
  [-42.7019, 147.2668],
  [-42.7015, 147.2673],
  [-42.7010, 147.2675],
  [-42.7005, 147.2675],
  [-42.7002, 147.2674],
  [-42.6988, 147.2657],
  [-42.6985, 147.2655],
  [-42.6975, 147.2652],
  [-42.6969, 147.2651],
  [-42.6963, 147.2651],
  [-42.6924, 147.2662],
  [-42.6918, 147.2667],
  [-42.6915, 147.2673],
  [-42.6913, 147.2681],
  [-42.6915, 147.2689],
  [-42.6918, 147.2700],
  [-42.6917, 147.2710],
  [-42.6915, 147.2718],
  [-42.6916, 147.2725],
  [-42.6917, 147.2739],
  [-42.6914, 147.2748],
  [-42.6907, 147.2752],
  [-42.6900, 147.2752],
  [-42.6882, 147.2734],
  [-42.6875, 147.2725],
  [-42.6868, 147.2720],
  [-42.6861, 147.2719],
  [-42.6856, 147.2721],
  [-42.6848, 147.2727],
  [-42.6837, 147.2730],
  [-42.6830, 147.2727],
  [-42.6815, 147.2708],
  [-42.6739, 147.2603],
  [-42.6627, 147.2490],
  [-42.6546, 147.2421],
  [-42.6473, 147.2358],
  [-42.6461, 147.2344],
  [-42.6440, 147.2318],
  [-42.6424, 147.2304],
  [-42.6295, 147.2227],
  [-42.6284, 147.2225],
  [-42.6260, 147.2224],
  [-42.6252, 147.2226],
  [-42.6243, 147.2223],
  [-42.6233, 147.2216],
  [-42.6225, 147.2211],
  [-42.6219, 147.2208],
  [-42.6205, 147.2207],
  [-42.6193, 147.2200],
  [-42.6189, 147.2193],
  [-42.6187, 147.2184],
  [-42.6186, 147.2166],
  [-42.6190, 147.2150],
  [-42.6189, 147.2142],
  [-42.6186, 147.2135],
  [-42.6182, 147.2132],
  [-42.6177, 147.2132],
  [-42.6164, 147.2135],
  [-42.6160, 147.2140],
  [-42.6158, 147.2146],
  [-42.6156, 147.2157],
  [-42.6153, 147.2161],
  [-42.6137, 147.2166],
  [-42.6129, 147.2165],
  [-42.6124, 147.2160],
  [-42.6120, 147.2155],
  [-42.6116, 147.2153],
  [-42.6110, 147.2155],
  [-42.6103, 147.2162],
  [-42.6094, 147.2164],
  [-42.6084, 147.2160],
  [-42.6073, 147.2152],
  [-42.6030, 147.2122],
  [-42.6021, 147.2119],
  [-42.6015, 147.2120],
  [-42.6011, 147.2125],
  [-42.6006, 147.2127],
  [-42.5965, 147.2119],
  [-42.5953, 147.2112],
  [-42.5940, 147.2102],
  [-42.5935, 147.2091],
  [-42.5932, 147.2083],
  [-42.5923, 147.2077],
  [-42.5909, 147.2059],
  [-42.5907, 147.2051],
  [-42.5908, 147.2043],
  [-42.5907, 147.2036],
  [-42.5903, 147.2029],
  [-42.5901, 147.2021],
  [-42.5906, 147.2011],
  [-42.5914, 147.2009],
  [-42.5919, 147.2005],
  [-42.5930, 147.1991],
  [-42.5931, 147.1984],
  [-42.5928, 147.1976],
  [-42.5923, 147.1974],
  [-42.5901, 147.1976],
  [-42.5894, 147.1974],
  [-42.5889, 147.1970],
  [-42.5881, 147.1969],
  [-42.5867, 147.1959],
  [-42.5864, 147.1953],
  [-42.5862, 147.1943],
  [-42.5858, 147.1939],
  [-42.5854, 147.1939],
  [-42.5842, 147.1943],
  [-42.5836, 147.1942],
  [-42.5832, 147.1938],
  [-42.5824, 147.1928],
  [-42.5820, 147.1925],
  [-42.5802, 147.1916],
  [-42.5787, 147.1902],
  [-42.5774, 147.1897],
  [-42.5769, 147.1899],
  [-42.5764, 147.1905],
  [-42.5760, 147.1906],
  [-42.5755, 147.1904],
  [-42.5752, 147.1900],
  [-42.5749, 147.1894],
  [-42.5745, 147.1890],
  [-42.5739, 147.1892],
  [-42.5735, 147.1897],
  [-42.5734, 147.1907],
  [-42.5742, 147.1921],
  [-42.5744, 147.1929],
  [-42.5745, 147.1937],
  [-42.5748, 147.1941],
  [-42.5762, 147.1948],
  [-42.5768, 147.1956],
  [-42.5777, 147.1985],
  [-42.5790, 147.2008],
  [-42.5791, 147.2017],
  [-42.5787, 147.2024],
  [-42.5781, 147.2027],
  [-42.5775, 147.2025],
  [-42.5770, 147.2020],
  [-42.5766, 147.2017],
  [-42.5761, 147.2017],
  [-42.5732, 147.2024],
  [-42.5717, 147.2020],
  [-42.5707, 147.2009],
  [-42.5695, 147.1966],
  [-42.5687, 147.1954],
  [-42.5663, 147.1941],
  [-42.5655, 147.1935],
  [-42.5648, 147.1926],
  [-42.5645, 147.1919],
  [-42.5639, 147.1878],
  [-42.5634, 147.1862],
  [-42.5621, 147.1844],
  [-42.5616, 147.1841],
  [-42.5611, 147.1841],
  [-42.5603, 147.1845],
  [-42.5594, 147.1844],
  [-42.5581, 147.1852],
  [-42.5573, 147.1852],
  [-42.5568, 147.1846],
  [-42.5561, 147.1837],
  [-42.5554, 147.1832],
  [-42.5542, 147.1832],
  [-42.5534, 147.1829],
  [-42.5527, 147.1830],
  [-42.5517, 147.1835],
  [-42.5509, 147.1835],
  [-42.5501, 147.1834],
  [-42.5494, 147.1838],
  [-42.5489, 147.1846],
  [-42.5483, 147.1849],
  [-42.5467, 147.1851],
  [-42.5460, 147.1854],
  [-42.5428, 147.1879],
  [-42.5420, 147.1880],
  [-42.5409, 147.1874],
  [-42.5401, 147.1874],
  [-42.5390, 147.1881],
  [-42.5382, 147.1894],
  [-42.5379, 147.1906],
  [-42.5379, 147.1929],
  [-42.5385, 147.1953],
  [-42.5390, 147.1964],
  [-42.5392, 147.1973],
  [-42.5393, 147.1999],
  [-42.5389, 147.2011],
  [-42.5383, 147.2015],
  [-42.5368, 147.2022],
  [-42.5360, 147.2023],
  [-42.5345, 147.2019],
  [-42.5329, 147.2017],
  [-42.5304, 147.2022],
  [-42.5286, 147.2021],
  [-42.5274, 147.2016],
  [-42.5255, 147.1997],
  [-42.5213, 147.1925],
  [-42.5207, 147.1919],
  [-42.5200, 147.1914],
  [-42.5032, 147.1874],
  [-42.4962, 147.1853],
  [-42.4883, 147.1834],
  [-42.4865, 147.1824],
  [-42.4832, 147.1795],
  [-42.4820, 147.1791],
  [-42.4760, 147.1804],
  [-42.4742, 147.1804],
  [-42.4727, 147.1800],
  [-42.4696, 147.1778],
  [-42.4656, 147.1725],
  [-42.4651, 147.1707],
  [-42.4649, 147.1657],
  [-42.4644, 147.1636],
  [-42.4624, 147.1597],
  [-42.4618, 147.1581],
  [-42.4588, 147.1480],
  [-42.4581, 147.1470],
  [-42.4570, 147.1466],
  [-42.4559, 147.1465],
  [-42.4551, 147.1461],
  [-42.4535, 147.1449],
  [-42.4528, 147.1447],
  [-42.4522, 147.1452],
  [-42.4514, 147.1463],
  [-42.4509, 147.1468],
  [-42.4496, 147.1474],
  [-42.4491, 147.1481],
  [-42.4490, 147.1491],
  [-42.4492, 147.1499],
  [-42.4510, 147.1513],
  [-42.4512, 147.1522],
  [-42.4509, 147.1531],
  [-42.4502, 147.1536],
  [-42.4463, 147.1538],
  [-42.4455, 147.1532],
  [-42.4433, 147.1500],
  [-42.4423, 147.1493],
  [-42.4370, 147.1473],
  [-42.4360, 147.1463],
  [-42.4354, 147.1451],
  [-42.4348, 147.1443],
  [-42.4322, 147.1430]
];

export const apsley: Line = {
  name: 'Apsley',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1891-04-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1947-09-29',
          status: 'closed'
        }]
      }
    }
  ]
}
