import { Line } from "../../trackTypes";

const northBundaberyGinGin = [
  [-24.8584, 152.3314],
  [-24.8581, 152.3295],
  [-24.8595, 152.2923],
  [-24.8600, 152.2910],
  [-24.8623, 152.2880],
  [-24.8626, 152.2871],
  [-24.8633, 152.2834],
  [-24.8638, 152.2826],
  [-24.8670, 152.2795],
  [-24.8719, 152.2733],
  [-24.8812, 152.2533],
  [-24.8821, 152.2506],
  [-24.8829, 152.2394],
  [-24.8856, 152.2279],
  [-24.8867, 152.2256],
  [-24.9037, 152.2016],
  [-24.9360, 152.1096],
  [-24.9367, 152.1090],
  [-24.9415, 152.1072],
  [-24.9426, 152.1060],
  [-24.9463, 152.0981],
  [-24.9485, 152.0935],
  [-24.9491, 152.0916],
  [-24.9537, 152.0622],
  [-24.9539, 152.0610],
  [-24.9575, 152.0541],
  [-24.9593, 152.0526],
  [-24.9599, 152.0525],
  [-24.9618, 152.0527],
  [-24.9623, 152.0523],
  [-24.9625, 152.0512],
  [-24.9621, 152.0505],
  [-24.9615, 152.0503],
  [-24.9613, 152.0498],
  [-24.9613, 152.0492],
  [-24.9610, 152.0486],
  [-24.9591, 152.0477],
  [-24.9589, 152.0468],
  [-24.9591, 152.0458],
  [-24.9590, 152.0451],
  [-24.9587, 152.0444],
  [-24.9560, 152.0428],
  [-24.9553, 152.0419],
  [-24.9543, 152.0390],
  [-24.9545, 152.0381],
  [-24.9550, 152.0376],
  [-24.9581, 152.0375],
  [-24.9590, 152.0364],
  [-24.9592, 152.0331],
  [-24.9597, 152.0324],
  [-24.9610, 152.0315],
  [-24.9615, 152.0310],
  [-24.9624, 152.0283],
  [-24.9624, 152.0272],
  [-24.9617, 152.0255],
  [-24.9620, 152.0240],
  [-24.9646, 152.0197],
  [-24.9663, 152.0158],
  [-24.9699, 152.0112],
  [-24.9793, 151.9990],
  [-24.9806, 151.9955],
  [-24.9815, 151.9937],
  [-24.9846, 151.9899],
  [-24.9858, 151.9873],
  [-24.9863, 151.9834],
  [-24.9871, 151.9807],
  [-24.9880, 151.9792],
  [-24.9909, 151.9768],
  [-24.9921, 151.9746],
  [-24.9932, 151.9685],
  [-24.9938, 151.9674],
  [-24.9962, 151.9655],
  [-24.9968, 151.9639],
  [-24.9968, 151.9632],
  [-24.9965, 151.9627],
  [-24.9951, 151.9622],
  [-24.9946, 151.9615],
  [-24.9942, 151.9594]
];

const ginGinTirroan = [
  [-24.9942, 151.9594],
  [-24.9934, 151.9559],
  [-24.9935, 151.9554],
  [-24.9939, 151.9549],
  [-24.9951, 151.9543],
  [-24.9953, 151.9540],
  [-24.9954, 151.9536],
  [-24.9948, 151.9510],
  [-24.9949, 151.9504],
  [-24.9953, 151.9500],
  [-24.9974, 151.9493],
  [-24.9979, 151.9490],
  [-24.9986, 151.9480],
  [-24.9991, 151.9477],
  [-25.0009, 151.9469],
  [-25.0016, 151.9460],
  [-25.0017, 151.9448],
  [-24.9989, 151.9376],
  [-24.9988, 151.9361],
  [-25.0030, 151.9227]
];

const tirroanMoolboolaman = [
  [-25.0030, 151.9227],
  [-25.0030, 151.9226],
  [-25.0029, 151.9213],
  [-25.0023, 151.9180],
  [-25.0027, 151.9168],
  [-25.0035, 151.9162],
  [-25.0039, 151.9149],
  [-25.0035, 151.9134],
  [-25.0036, 151.9125],
  [-25.0042, 151.9121],
  [-25.0061, 151.9125],
  [-25.0071, 151.9123],
  [-25.0105, 151.9095],
  [-25.0110, 151.9084],
  [-25.0105, 151.9068],
  [-25.0106, 151.9060],
  [-25.0112, 151.9054],
  [-25.0131, 151.9045],
  [-25.0151, 151.9019],
  [-25.0161, 151.8989],
  [-25.0162, 151.8973],
  [-25.0134, 151.8911],
  [-25.0134, 151.8903],
  [-25.0138, 151.8898],
  [-25.0143, 151.8892],
  [-25.0143, 151.8878],
  [-25.0148, 151.8870],
  [-25.0167, 151.8864],
  [-25.0187, 151.8867],
  [-25.0194, 151.8866],
  [-25.0195, 151.8866],
  [-25.0205, 151.8856],
  [-25.0212, 151.8852],
  [-25.0227, 151.8856],
  [-25.0239, 151.8857],
  [-25.0247, 151.8854],
  [-25.0260, 151.8842],
  [-25.0280, 151.8831],
  [-25.0284, 151.8827],
  [-25.0288, 151.8813],
  [-25.0287, 151.8803],
  [-25.0261, 151.8784],
  [-25.0258, 151.8779],
  [-25.0257, 151.8773],
  [-25.0252, 151.8767],
  [-25.0216, 151.8759],
  [-25.0210, 151.8755],
  [-25.0202, 151.8739],
  [-25.0199, 151.8716],
  [-25.0196, 151.8707],
  [-25.0195, 151.8697],
  [-25.0199, 151.8682],
  [-25.0198, 151.8676],
  [-25.0191, 151.8669],
  [-25.0189, 151.8664],
  [-25.0188, 151.8649],
  [-25.0186, 151.8641],
  [-25.0178, 151.8631],
  [-25.0177, 151.8623],
  [-25.0181, 151.8617],
  [-25.0189, 151.8609],
  [-25.0192, 151.8600],
  [-25.0190, 151.8583],
  [-25.0191, 151.8574],
  [-25.0193, 151.8559],
  [-25.0189, 151.8545],
  [-25.0183, 151.8537],
  [-25.0183, 151.8528],
  [-25.0183, 151.8508]
];

const moolamanGillensSiding = [
  [-25.0183, 151.8508],
  [-25.0177, 151.8492],
  [-25.0169, 151.8485],
  [-25.0167, 151.8478],
  [-25.0166, 151.8465],
  [-25.0161, 151.8449],
  [-25.0162, 151.8442],
  [-25.0164, 151.8434],
  [-25.0164, 151.8425],
  [-25.0157, 151.8396],
  [-25.0157, 151.8387],
  [-25.0163, 151.8370],
  [-25.0166, 151.8353],
  [-25.0172, 151.8345],
  [-25.0183, 151.8335],
  [-25.0185, 151.8329],
  [-25.0181, 151.8317],
  [-25.0182, 151.8310],
  [-25.0191, 151.8301],
  [-25.0194, 151.8283],
  [-25.0184, 151.8264],
  [-25.0148, 151.8238],
  [-25.0135, 151.8236],
  [-25.0129, 151.8228],
  [-25.0125, 151.8226],
  [-25.0117, 151.8226],
  [-25.0111, 151.8223],
  [-25.0096, 151.8206],
  [-25.0078, 151.8194],
  [-25.0076, 151.8186],
  [-25.0080, 151.8178],
  [-25.0098, 151.8169],
  [-25.0101, 151.8164],
  [-25.0100, 151.8157],
  [-25.0079, 151.8121],
  [-25.0076, 151.8107],
  [-25.0078, 151.8096],
  [-25.0100, 151.8015],
  [-25.0096, 151.7994],
  [-25.0085, 151.7979],
  [-25.0051, 151.7963],
  [-25.0038, 151.7946],
  [-25.0020, 151.7918],
  [-25.0015, 151.7901],
  [-25.0019, 151.7868],
  [-25.0025, 151.7858],
  [-25.0032, 151.7854],
  [-25.0039, 151.7855],
  [-25.0045, 151.7852],
  [-25.0047, 151.7849],
  [-25.0065, 151.7812],
  [-25.0078, 151.7806],
  [-25.0086, 151.7796],
  [-25.0089, 151.7768],
  [-25.0086, 151.7754],
  [-25.0090, 151.7742],
  [-25.0097, 151.7742],
  [-25.0103, 151.7737],
  [-25.0107, 151.7727],
  [-25.0106, 151.7719],
  [-25.0110, 151.7714],
  [-25.0118, 151.7707],
  [-25.0131, 151.7685],
  [-25.0138, 151.7679],
  [-25.0154, 151.7675],
  [-25.0160, 151.7672],
  [-25.0167, 151.7674],
  [-25.0173, 151.7675],
  [-25.0179, 151.7670],
  [-25.0185, 151.7670],
  [-25.0195, 151.7671],
  [-25.0219, 151.7650]
];

const gillensSidingBoolboonda = [
  [-25.0219, 151.7650],
  [-25.0222, 151.7648],
  [-25.0230, 151.7648],
  [-25.0244, 151.7652],
  [-25.0253, 151.7652],
  [-25.0263, 151.7646],
  [-25.0273, 151.7645],
  [-25.0280, 151.7639],
  [-25.0286, 151.7638],
  [-25.0297, 151.7640],
  [-25.0325, 151.7637],
  [-25.0361, 151.7650],
  [-25.0366, 151.7653],
  [-25.0373, 151.7661],
  [-25.0379, 151.7663],
  [-25.0385, 151.7661],
  [-25.0396, 151.7646],
  [-25.0419, 151.7633],
  [-25.0434, 151.7634],
  [-25.0465, 151.7632],
  [-25.0470, 151.7629],
  [-25.0474, 151.7624],
  [-25.0480, 151.7622],
  [-25.0491, 151.7624],
  [-25.0510, 151.7617],
  [-25.0518, 151.7617],
  [-25.0529, 151.7623],
  [-25.0532, 151.7629],
  [-25.0534, 151.7639],
  [-25.0537, 151.7643],
  [-25.0546, 151.7647],
  [-25.0557, 151.7660],
  [-25.0561, 151.7669],
  [-25.0574, 151.7677],
  [-25.0581, 151.7673],
  [-25.0623, 151.7642],
  [-25.0652, 151.7626],
  [-25.0658, 151.7621],
  [-25.0673, 151.7587],
  [-25.0686, 151.7570],
  [-25.0692, 151.7562],
  [-25.0695, 151.7539],
  [-25.0692, 151.7531],
  [-25.0693, 151.7522],
  [-25.0701, 151.7509],
  [-25.0703, 151.7496],
  [-25.0700, 151.7480],
  [-25.0702, 151.7464],
  [-25.0710, 151.7451],
  [-25.0729, 151.7438],
  [-25.0748, 151.7401],
  [-25.0749, 151.7394],
  [-25.0746, 151.7385],
  [-25.0747, 151.7379],
  [-25.0752, 151.7375],
  [-25.0805, 151.7368],
  [-25.0810, 151.7365],
  [-25.0812, 151.7359],
  [-25.0807, 151.7337],
  [-25.0803, 151.7333],
  [-25.0799, 151.7325],
  [-25.0798, 151.7317],
  [-25.0789, 151.7297],
  [-25.0772, 151.7273],
  [-25.0765, 151.7267],
  [-25.0763, 151.7263],
  [-25.0763, 151.7257],
  [-25.0771, 151.7230],
  [-25.0777, 151.7225],
  [-25.0783, 151.7224],
  [-25.0789, 151.7218],
  [-25.0789, 151.7206],
  [-25.0786, 151.7200],
  [-25.0786, 151.7194],
  [-25.0788, 151.7187],
  [-25.0787, 151.7182],
  [-25.0772, 151.7176],
  [-25.0767, 151.7171],
  [-25.0755, 151.7138],
  [-25.0757, 151.7132],
  [-25.0766, 151.7122],
  [-25.0780, 151.7093],
  [-25.0782, 151.7076],
  [-25.0778, 151.7070],
  [-25.0769, 151.7065],
  [-25.0766, 151.7059],
  [-25.0767, 151.7036],
  [-25.0765, 151.7029],
  [-25.0760, 151.7024],
  [-25.0758, 151.7018],
  [-25.0758, 151.7008],
  [-25.0762, 151.7002],
  [-25.0790, 151.6976],
  [-25.0802, 151.6972],
  [-25.0808, 151.6967],
  [-25.0810, 151.6954],
  [-25.0805, 151.6944],
  [-25.0798, 151.6942],
  [-25.0789, 151.6946],
  [-25.0782, 151.6943],
  [-25.0772, 151.6927],
  [-25.0771, 151.6920],
  [-25.0765, 151.6915],
  [-25.0748, 151.6913],
  [-25.0744, 151.6909],
  [-25.0741, 151.6904],
  [-25.0742, 151.6899],
  [-25.0746, 151.6894],
  [-25.0757, 151.6888],
  [-25.0768, 151.6876],
  [-25.0776, 151.6854],
  [-25.0781, 151.6850],
  [-25.0789, 151.6850],
  [-25.0793, 151.6848],
  [-25.0814, 151.6819]
];

const boolboondaBoolboondaTunnel = [
  [-25.0814, 151.6819],
  [-25.0816, 151.6811],
  [-25.0810, 151.6784],
  [-25.0812, 151.6768]
];

const boolboonaTunnel = [
  [-25.0812, 151.6768],
  [-25.0818, 151.6747]
];

const boolboondaTunnelMountPerry = [
  [-25.0818, 151.6747],
  [-25.0821, 151.6742],
  [-25.0826, 151.6739],
  [-25.0850, 151.6733],
  [-25.0855, 151.6729],
  [-25.0857, 151.6722],
  [-25.0861, 151.6717],
  [-25.0870, 151.6713],
  [-25.0872, 151.6706],
  [-25.0867, 151.6681],
  [-25.0866, 151.6675],
  [-25.0868, 151.6668],
  [-25.0873, 151.6664],
  [-25.0876, 151.6657],
  [-25.0873, 151.6642],
  [-25.0874, 151.6634],
  [-25.0884, 151.6624],
  [-25.0891, 151.6623],
  [-25.0909, 151.6629],
  [-25.0920, 151.6630],
  [-25.0933, 151.6628],
  [-25.0938, 151.6621],
  [-25.0935, 151.6593],
  [-25.0922, 151.6571],
  [-25.0920, 151.6561],
  [-25.0920, 151.6552],
  [-25.0889, 151.6501],
  [-25.0887, 151.6491],
  [-25.0891, 151.6473],
  [-25.0891, 151.6463],
  [-25.0886, 151.6446],
  [-25.0888, 151.6437],
  [-25.0896, 151.6434],
  [-25.0903, 151.6437],
  [-25.0910, 151.6436],
  [-25.0937, 151.6407],
  [-25.0948, 151.6397],
  [-25.0956, 151.6384],
  [-25.0957, 151.6376],
  [-25.0961, 151.6369],
  [-25.0969, 151.6364],
  [-25.0972, 151.6356],
  [-25.0969, 151.6347],
  [-25.0969, 151.6337],
  [-25.0975, 151.6330],
  [-25.0984, 151.6326],
  [-25.0987, 151.6319],
  [-25.0986, 151.6314],
  [-25.0986, 151.6310],
  [-25.0987, 151.6305],
  [-25.0991, 151.6301],
  [-25.0994, 151.6298],
  [-25.0994, 151.6293],
  [-25.0991, 151.6284],
  [-25.0992, 151.6277],
  [-25.0996, 151.6271],
  [-25.0996, 151.6264],
  [-25.0993, 151.6256],
  [-25.0994, 151.6250],
  [-25.0996, 151.6246],
  [-25.0996, 151.6238],
  [-25.0990, 151.6228],
  [-25.0990, 151.6221],
  [-25.0997, 151.6210],
  [-25.1007, 151.6205],
  [-25.1020, 151.6204],
  [-25.1028, 151.6201],
  [-25.1042, 151.6199],
  [-25.1051, 151.6195],
  [-25.1084, 151.6154],
  [-25.1100, 151.6150],
  [-25.1133, 151.6155],
  [-25.1182, 151.6159],
  [-25.1253, 151.6173],
  [-25.1295, 151.6191],
  [-25.1398, 151.6206],
  [-25.1405, 151.6213],
  [-25.1411, 151.6230],
  [-25.1425, 151.6245],
  [-25.1430, 151.6254],
  [-25.1438, 151.6259],
  [-25.1458, 151.6263],
  [-25.1615, 151.6335],
  [-25.1668, 151.6349],
  [-25.1716, 151.6370],
  [-25.1769, 151.6407]
];

export const mountPerry: Line = {
  name: 'Mount Perry',
  state: 'QLD',
  segments: [
    {
      segments: [northBundaberyGinGin],
      history: {
        opened: {
          date: '1881-07-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ginGinTirroan],
      history: {
        opened: {
          date: '1881-07-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tirroanMoolboolaman],
      history: {
        opened: {
          date: '1881-07-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-10-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moolamanGillensSiding],
      history: {
        opened: {
          date: '1882-08-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-10-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gillensSidingBoolboonda],
      history: {
        opened: {
          date: '1883-11-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-10-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        boolboondaBoolboondaTunnel,
        {
          name: 'Boolboonda Tunnel',
          segment: boolboonaTunnel,
          type: 'tunnel'
        },
        boolboondaTunnelMountPerry
      ],
      history: {
        opened: {
          date: '1884-05-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-10-31',
          status: 'closed'
        }]
      }
    }
  ]
}
