import { Line } from "../../../trackTypes";

const waiarekaCormacks = [
  [-45.0975, 170.9387],
  [-45.0969, 170.9388],
  [-45.0964, 170.9387],
  [-45.0958, 170.9382],
  [-45.0941, 170.9358],
  [-45.0918, 170.9312],
  [-45.0913, 170.9306],
  [-45.0854, 170.9261],
  [-45.0849, 170.9254],
  [-45.0802, 170.9124],
  [-45.0793, 170.9066],
  [-45.0788, 170.9054],
  [-45.0743, 170.8993]
];

const cormacksNgapara = [
  [-45.0743, 170.8993],
  [-45.0732, 170.8982],
  [-45.0709, 170.8976],
  [-45.0647, 170.8936],
  [-45.0590, 170.8874],
  [-45.0552, 170.8818],
  [-45.0548, 170.8809],
  [-45.0546, 170.8798],
  [-45.0543, 170.8793],
  [-45.0538, 170.8789],
  [-45.0519, 170.8783],
  [-45.0464, 170.8744],
  [-45.0458, 170.8737],
  [-45.0443, 170.8710],
  [-45.0420, 170.8663],
  [-45.0414, 170.8644],
  [-45.0411, 170.8638],
  [-45.0393, 170.8621],
  [-45.0387, 170.8612],
  [-45.0321, 170.8504],
  [-45.0316, 170.8500],
  [-45.0293, 170.8489],
  [-45.0284, 170.8482],
  [-45.0261, 170.8451],
  [-45.0241, 170.8415],
  [-45.0236, 170.8400],
  [-45.0230, 170.8390],
  [-45.0179, 170.8333],
  [-45.0165, 170.8310],
  [-45.0058, 170.8097],
  [-45.0054, 170.8083],
  [-45.0054, 170.8068],
  [-45.0057, 170.8042],
  [-45.0055, 170.8027],
  [-45.0017, 170.7940],
  [-45.0005, 170.7930],
  [-44.9967, 170.7918],
  [-44.9945, 170.7920],
  [-44.9935, 170.7927],
  [-44.9910, 170.7954],
  [-44.9905, 170.7957],
  [-44.9896, 170.7954],
  [-44.9874, 170.7941],
  [-44.9863, 170.7939],
  [-44.9848, 170.7941],
  [-44.9831, 170.7940],
  [-44.9790, 170.7933],
  [-44.9782, 170.7930],
  [-44.9728, 170.7896],
  [-44.9721, 170.7888],
  [-44.9663, 170.7798],
  [-44.9578, 170.7658],
  [-44.9573, 170.7645],
  [-44.9558, 170.7583],
  [-44.9551, 170.7568],
  [-44.9535, 170.7544],
  [-44.9492, 170.7506]
];

const windsorRakisTunnel = [
  [-45.0017, 170.7940],
  [-45.0003, 170.7913],
  [-44.9984, 170.7863],
  [-44.9984, 170.7849],
  [-44.9987, 170.7838],
  [-44.9995, 170.7829],
  [-45.0017, 170.7818],
  [-45.0023, 170.7810],
  [-45.0025, 170.7802],
  [-45.0030, 170.7747],
  [-45.0029, 170.7722],
  [-45.0021, 170.7700],
  [-45.0011, 170.7681],
  [-44.9986, 170.7652],
  [-44.9969, 170.7621],
  [-44.9965, 170.7617],
  [-44.9956, 170.7611],
  [-44.9947, 170.7609],
  [-44.9938, 170.7602],
  [-44.9935, 170.7591],
  [-44.9936, 170.7580],
  [-44.9942, 170.7569],
  [-44.9945, 170.7562],
  [-44.9946, 170.7554],
  [-44.9943, 170.7536],
  [-44.9943, 170.7528],
  [-44.9944, 170.7519],
  [-44.9942, 170.7510],
  [-44.9937, 170.7503],
  [-44.9931, 170.7495],
  [-44.9927, 170.7482],
  [-44.9912, 170.7462],
  [-44.9907, 170.7454],
  [-44.9906, 170.7447],
  [-44.9903, 170.7429],
  [-44.9881, 170.7384]
];

const rakisTunnel = [
  [-44.9881, 170.7384],
  [-44.9874, 170.7369],
  [-44.9872, 170.7360]
];

const rakisTunnelTapuiTunnel = [
  [-44.9872, 170.7360],
  [-44.9872, 170.7358],
  [-44.9874, 170.7345],
  [-44.9875, 170.7333],
  [-44.9869, 170.7304],
  [-44.9869, 170.7292],
  [-44.9871, 170.7284],
  [-44.9876, 170.7273],
  [-44.9878, 170.7259],
  [-44.9875, 170.7240],
  [-44.9870, 170.7228],
  [-44.9870, 170.7216],
  [-44.9872, 170.7209],
  [-44.9894, 170.7171],
  [-44.9897, 170.7150],
  [-44.9896, 170.7138],
  [-44.9892, 170.7128],
  [-44.9890, 170.7120],
  [-44.9891, 170.7110],
  [-44.9896, 170.7099],
  [-44.9897, 170.7092],
  [-44.9895, 170.7078],
  [-44.9896, 170.7068],
  [-44.9902, 170.7052],
  [-44.9908, 170.7008],
  [-44.9907, 170.6994]
];

const tapuiTunnel = [
  [-44.9907, 170.6994],
  [-44.9902, 170.6960]
];

const tapuiTunnelTokarahi = [
  [-44.9902, 170.6960],
  [-44.9900, 170.6941],
  [-44.9892, 170.6913],
  [-44.9887, 170.6906],
  [-44.9879, 170.6900],
  [-44.9861, 170.6894],
  [-44.9853, 170.6885],
  [-44.9850, 170.6877],
  [-44.9850, 170.6867],
  [-44.9858, 170.6779],
  [-44.9855, 170.6762],
  [-44.9849, 170.6742],
  [-44.9843, 170.6733],
  [-44.9833, 170.6729],
  [-44.9821, 170.6730],
  [-44.9814, 170.6727],
  [-44.9809, 170.6720],
  [-44.9805, 170.6710],
  [-44.9795, 170.6704],
  [-44.9783, 170.6706],
  [-44.9775, 170.6714],
  [-44.9768, 170.6731],
  [-44.9761, 170.6740],
  [-44.9742, 170.6758],
  [-44.9734, 170.6768],
  [-44.9730, 170.6777],
  [-44.9724, 170.6784],
  [-44.9718, 170.6787],
  [-44.9701, 170.6786],
  [-44.9663, 170.6771],
  [-44.9644, 170.6770],
  [-44.9611, 170.6771],
  [-44.9597, 170.6768],
  [-44.9574, 170.6763],
  [-44.9556, 170.6764],
  [-44.9542, 170.6762],
  [-44.9457, 170.6724],
  [-44.9452, 170.6718],
  [-44.9449, 170.6709],
  [-44.9449, 170.6699],
  [-44.9456, 170.6659],
  [-44.9461, 170.6647],
  [-44.9504, 170.6589],
  [-44.9510, 170.6583],
  [-44.9524, 170.6573],
  [-44.9532, 170.6564],
  [-44.9547, 170.6526],
  [-44.9551, 170.6519],
  [-44.9556, 170.6517],
  [-44.9573, 170.6511],
  [-44.9580, 170.6504],
  [-44.9597, 170.6471]
];

export const ngaparaTokarahi: Line = {
  name: 'Ngapara & Tokarahi',
  state: 'NZ',
  segments: [
    {
      segments: [waiarekaCormacks],
      history: {
        opened: {
          date: '1877-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1997-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cormacksNgapara],
      history: {
        opened: {
          date: '1877-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        windsorRakisTunnel,
        {
          name: 'Rakis Tunnel',
          segment: rakisTunnel,
          type: 'tunnel'
        },
        rakisTunnelTapuiTunnel,
        {
          name: 'Tapui Tunnel',
          segment: tapuiTunnel,
          type: 'tunnel'
        },
        tapuiTunnelTokarahi
      ],
      history: {
        opened: {
          date: '1887-07-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-07-14',
          status: 'closed'
        }]
      }
    }
  ]
}
