import { Line } from "../../trackTypes";

const ellekerTorbay = [
  [-35.0065, 117.7321],
  [-35.0059, 117.7307],
  [-35.0058, 117.7293],
  [-35.0073, 117.7193],
  [-35.0068, 117.7038],
  [-35.0074, 117.7019],
  [-35.0219, 117.6836],
  [-35.0239, 117.6814],
  [-35.0246, 117.6795],
  [-35.0247, 117.6782],
  [-35.0243, 117.6758],
  [-35.0243, 117.6743],
  [-35.0251, 117.6709],
  [-35.0253, 117.6532]
];

const torbayDenmark = [
  [-35.0253, 117.6532],
  [-35.0254, 117.6477],
  [-35.0259, 117.6456],
  [-35.0328, 117.6263],
  [-35.0336, 117.6251],
  [-35.0347, 117.6243],
  [-35.0413, 117.6227],
  [-35.0431, 117.6210],
  [-35.0450, 117.6183],
  [-35.0487, 117.6109],
  [-35.0488, 117.6084],
  [-35.0492, 117.6068],
  [-35.0503, 117.6041],
  [-35.0505, 117.6024],
  [-35.0450, 117.5870],
  [-35.0419, 117.5613],
  [-35.0422, 117.5517],
  [-35.0412, 117.5464],
  [-35.0406, 117.5451],
  [-34.9760, 117.4955],
  [-34.9712, 117.4888],
  [-34.9670, 117.4780],
  [-34.9644, 117.4602],
  [-34.9646, 117.4553],
  [-34.9677, 117.4475],
  [-34.9708, 117.4427],
  [-34.9733, 117.4406],
  [-34.9769, 117.4367],
  [-34.9775, 117.4351],
  [-34.9775, 117.4314],
  [-34.9797, 117.4201],
  [-34.9796, 117.4185],
  [-34.9790, 117.4168],
  [-34.9790, 117.4157],
  [-34.9795, 117.4141],
  [-34.9795, 117.4131],
  [-34.9790, 117.4121],
  [-34.9774, 117.4105],
  [-34.9753, 117.4092],
  [-34.9744, 117.4084],
  [-34.9727, 117.4053],
  [-34.9721, 117.4010],
  [-34.9723, 117.3996],
  [-34.9720, 117.3985],
  [-34.9714, 117.3974],
  [-34.9712, 117.3967],
  [-34.9709, 117.3945],
  [-34.9699, 117.3914],
  [-34.9688, 117.3874],
  [-34.9687, 117.3863],
  [-34.9688, 117.3856],
  [-34.9696, 117.3843],
  [-34.9704, 117.3819],
  [-34.9704, 117.3805],
  [-34.9701, 117.3796],
  [-34.9672, 117.3761],
  [-34.9668, 117.3752],
  [-34.9666, 117.3743],
  [-34.9667, 117.3683],
  [-34.9671, 117.3672],
  [-34.9679, 117.3663],
  [-34.9736, 117.3637],
  [-34.9744, 117.3629],
  [-34.9747, 117.3621],
  [-34.9745, 117.3608],
  [-34.9730, 117.3573],
  [-34.9713, 117.3546],
  [-34.9708, 117.3527],
  [-34.9708, 117.3510],
  [-34.9718, 117.3455]
];

const denmarkNornalup = [
  [-34.9718, 117.3455],
  [-34.9728, 117.3434],
  [-34.9742, 117.3425],
  [-34.9763, 117.3426],
  [-34.9771, 117.3422],
  [-34.9779, 117.3411],
  [-34.9788, 117.3369],
  [-34.9797, 117.3351],
  [-34.9808, 117.3344],
  [-34.9829, 117.3339],
  [-34.9843, 117.3327],
  [-34.9848, 117.3308],
  [-34.9839, 117.3155],
  [-34.9843, 117.3146],
  [-34.9848, 117.3140],
  [-34.9863, 117.3131],
  [-34.9875, 117.3113],
  [-34.9881, 117.3069],
  [-34.9882, 117.3059],
  [-34.9877, 117.3001],
  [-34.9878, 117.2981],
  [-34.9892, 117.2947],
  [-34.9894, 117.2920],
  [-34.9887, 117.2870],
  [-34.9888, 117.2812],
  [-34.9876, 117.2669],
  [-34.9880, 117.2653],
  [-34.9880, 117.2642],
  [-34.9874, 117.2625],
  [-34.9874, 117.2608],
  [-34.9885, 117.2594],
  [-34.9889, 117.2580],
  [-34.9882, 117.2552],
  [-34.9879, 117.2524],
  [-34.9845, 117.2467],
  [-34.9840, 117.2446],
  [-34.9842, 117.2415],
  [-34.9836, 117.2398],
  [-34.9810, 117.2364],
  [-34.9802, 117.2344],
  [-34.9794, 117.2336],
  [-34.9781, 117.2330],
  [-34.9774, 117.2324],
  [-34.9766, 117.2312],
  [-34.9758, 117.2307],
  [-34.9751, 117.2307],
  [-34.9734, 117.2314],
  [-34.9726, 117.2312],
  [-34.9717, 117.2302],
  [-34.9715, 117.2291],
  [-34.9719, 117.2279],
  [-34.9742, 117.2256],
  [-34.9747, 117.2239],
  [-34.9744, 117.2223],
  [-34.9703, 117.2169],
  [-34.9696, 117.2149],
  [-34.9692, 117.2120],
  [-34.9696, 117.2101],
  [-34.9706, 117.2084],
  [-34.9762, 117.2045],
  [-34.9770, 117.2030],
  [-34.9770, 117.2018],
  [-34.9764, 117.2005],
  [-34.9753, 117.1993],
  [-34.9747, 117.1973],
  [-34.9750, 117.1947],
  [-34.9746, 117.1908],
  [-34.9736, 117.1886],
  [-34.9718, 117.1865],
  [-34.9703, 117.1835],
  [-34.9696, 117.1814],
  [-34.9697, 117.1799],
  [-34.9702, 117.1783],
  [-34.9703, 117.1771],
  [-34.9692, 117.1704],
  [-34.9695, 117.1677],
  [-34.9711, 117.1643],
  [-34.9715, 117.1623],
  [-34.9723, 117.1603],
  [-34.9724, 117.1589],
  [-34.9719, 117.1576],
  [-34.9708, 117.1565],
  [-34.9701, 117.1555],
  [-34.9632, 117.1396],
  [-34.9622, 117.1359],
  [-34.9610, 117.1249],
  [-34.9615, 117.1206],
  [-34.9603, 117.0997],
  [-34.9606, 117.0971],
  [-34.9626, 117.0905],
  [-34.9621, 117.0878],
  [-34.9609, 117.0861],
  [-34.9594, 117.0854],
  [-34.9569, 117.0852],
  [-34.9558, 117.0842],
  [-34.9556, 117.0825],
  [-34.9564, 117.0803],
  [-34.9563, 117.0788],
  [-34.9555, 117.0774],
  [-34.9552, 117.0760],
  [-34.9564, 117.0592],
  [-34.9572, 117.0573],
  [-34.9587, 117.0553],
  [-34.9590, 117.0545],
  [-34.9589, 117.0533],
  [-34.9586, 117.0512],
  [-34.9589, 117.0481],
  [-34.9708, 117.0275],
  [-34.9710, 117.0259],
  [-34.9711, 117.0175],
  [-34.9707, 117.0159],
  [-34.9692, 117.0125],
  [-34.9677, 117.0065],
  [-34.9676, 117.0038],
  [-34.9693, 116.9934],
  [-34.9696, 116.9737],
  [-34.9700, 116.9711],
  [-34.9733, 116.9589],
  [-34.9735, 116.9547],
  [-34.9742, 116.9520],
  [-34.9753, 116.9505],
  [-34.9808, 116.9482],
  [-34.9816, 116.9470],
  [-34.9829, 116.9452],
  [-34.9838, 116.9441],
  [-34.9890, 116.9419],
  [-34.9901, 116.9403],
  [-34.9908, 116.9373],
  [-34.9918, 116.9357],
  [-34.9922, 116.9341],
  [-34.9917, 116.9316],
  [-34.9918, 116.9299],
  [-34.9930, 116.9282],
  [-34.9936, 116.9264],
  [-34.9933, 116.9203],
  [-34.9930, 116.9183],
  [-34.9930, 116.9134],
  [-34.9934, 116.9119],
  [-34.9939, 116.9107],
  [-34.9948, 116.9037],
  [-34.9977, 116.8978],
  [-34.9988, 116.8921],
  [-35.0000, 116.8833],
  [-35.0010, 116.8814],
  [-35.0013, 116.8799],
  [-35.0009, 116.8781],
  [-35.0009, 116.8768],
  [-35.0018, 116.8716],
  [-35.0026, 116.8700],
  [-35.0042, 116.8685],
  [-35.0052, 116.8665],
  [-35.0074, 116.8650],
  [-35.0091, 116.8632],
  [-35.0095, 116.8622],
  [-35.0096, 116.8605],
  [-35.0091, 116.8580],
  [-35.0089, 116.8501],
  [-35.0078, 116.8477],
  [-35.0076, 116.8462],
  [-35.0081, 116.8335]
];

export const nornalup: Line = {
  name: 'Nornalup',
  state: 'WA',
  segments: [
    {
      segments: [ellekerTorbay],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1929-06-11',
          trackType: 'heavy'
        }, {
          date: '1957-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [torbayDenmark],
      history: {
        opened: {
          date: '1896-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1929-06-11',
          trackType: 'heavy'
        }, {
          date: '1957-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [denmarkNornalup],
      history: {
        opened: {
          date: '1929-06-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-09-01',
          status: 'closed'
        }]
      }
    }
  ]
};
