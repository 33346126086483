import { Line } from "../../../trackTypes";

const line = [
  [-36.7780, 174.5593],
  [-36.7789, 174.5616],
  [-36.7791, 174.5634],
  [-36.7749, 174.5852],
  [-36.7745, 174.5927],
  [-36.7734, 174.5966]
];

export const kumeuRiverhead: Line = {
  name: 'Kumeu - Riverhead',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1875-10-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1881-07-18',
          status: 'closed'
        }]
      }
    }
  ]
}
