import { Line } from "../../trackTypes";

const donJunctionDonRiver = [
  [-41.1638, 146.3348],
  [-41.1640, 146.3341],
  [-41.1644, 146.3336],
  [-41.1679, 146.3317],
  [-41.1684, 146.3312],
  [-41.1724, 146.3237],
  [-41.1728, 146.3234],
  [-41.1732, 146.3233],
  [-41.1741, 146.3235],
  [-41.1749, 146.3235],
  [-41.1753, 146.3237],
  [-41.1763, 146.3249],
  [-41.1766, 146.3250],
  [-41.1771, 146.3250],
  [-41.1780, 146.3243],
  [-41.1785, 146.3230],
  [-41.1790, 146.3223],
  [-41.1793, 146.3214],
  [-41.1791, 146.3203],
  [-41.1791, 146.3195],
  [-41.1794, 146.3189],
  [-41.1806, 146.3181],
  [-41.1818, 146.3176]
];

const donRiverPaloona = [
  [-41.1818, 146.3176],
  [-41.1830, 146.3174],
  [-41.1838, 146.3177],
  [-41.1851, 146.3187],
  [-41.1858, 146.3188],
  [-41.1893, 146.3184],
  [-41.1907, 146.3175],
  [-41.1913, 146.3166],
  [-41.1916, 146.3156],
  [-41.1914, 146.3146],
  [-41.1915, 146.3140],
  [-41.1919, 146.3132],
  [-41.1930, 146.3126],
  [-41.1937, 146.3120],
  [-41.1964, 146.3062],
  [-41.1970, 146.3055],
  [-41.1987, 146.3044],
  [-41.1999, 146.3033],
  [-41.2015, 146.3014],
  [-41.2021, 146.3010],
  [-41.2080, 146.3004],
  [-41.2091, 146.3010],
  [-41.2100, 146.3025],
  [-41.2099, 146.3031],
  [-41.2096, 146.3039],
  [-41.2097, 146.3047],
  [-41.2100, 146.3053],
  [-41.2106, 146.3054],
  [-41.2120, 146.3050],
  [-41.2127, 146.3046],
  [-41.2153, 146.3026],
  [-41.2163, 146.3021],
  [-41.2189, 146.3025],
  [-41.2195, 146.3022],
  [-41.2199, 146.3013],
  [-41.2203, 146.3011],
  [-41.2211, 146.3012],
  [-41.2227, 146.3018],
  [-41.2230, 146.3023],
  [-41.2231, 146.3028],
  [-41.2231, 146.3047],
  [-41.2234, 146.3054],
  [-41.2248, 146.3061],
  [-41.2253, 146.3069],
  [-41.2258, 146.3083],
  [-41.2264, 146.3086],
  [-41.2271, 146.3087],
  [-41.2280, 146.3082],
  [-41.2288, 146.3067],
  [-41.2291, 146.3053],
  [-41.2291, 146.3044],
  [-41.2286, 146.3030],
  [-41.2284, 146.3025],
  [-41.2284, 146.3018],
  [-41.2286, 146.3010],
  [-41.2308, 146.2986],
  [-41.2314, 146.2974],
  [-41.2322, 146.2932],
  [-41.2322, 146.2881],
  [-41.2325, 146.2872],
  [-41.2331, 146.2865],
  [-41.2359, 146.2848],
  [-41.2384, 146.2842],
  [-41.2400, 146.2825],
  [-41.2414, 146.2810],
  [-41.2416, 146.2807],
  [-41.2425, 146.2804],
  [-41.2431, 146.2803],
  [-41.2450, 146.2805]
];

const paloonaBarrington = [
  [-41.2450, 146.2805],
  [-41.2501, 146.2825],
  [-41.2534, 146.2830],
  [-41.2556, 146.2830],
  [-41.2562, 146.2832],
  [-41.2579, 146.2845],
  [-41.2595, 146.2865],
  [-41.2603, 146.2869],
  [-41.2611, 146.2866],
  [-41.2620, 146.2862],
  [-41.2641, 146.2862],
  [-41.2654, 146.2859],
  [-41.2687, 146.2870],
  [-41.2703, 146.2874],
  [-41.2717, 146.2881],
  [-41.2736, 146.2883],
  [-41.2749, 146.2889],
  [-41.2777, 146.2924],
  [-41.2793, 146.2942],
  [-41.2800, 146.2948],
  [-41.2806, 146.2955],
  [-41.2810, 146.2966],
  [-41.2815, 146.2971],
  [-41.2819, 146.2972],
  [-41.2823, 146.2969],
  [-41.2826, 146.2965],
  [-41.2827, 146.2960],
  [-41.2826, 146.2945],
  [-41.2829, 146.2939],
  [-41.2832, 146.2938],
  [-41.2842, 146.2938],
  [-41.2848, 146.2935],
  [-41.2856, 146.2933],
  [-41.2879, 146.2932],
  [-41.2886, 146.2928],
  [-41.2889, 146.2922],
  [-41.2893, 146.2911],
  [-41.2899, 146.2905],
  [-41.2909, 146.2902],
  [-41.2922, 146.2902],
  [-41.2937, 146.2905],
  [-41.2945, 146.2906],
  [-41.2955, 146.2897],
  [-41.2961, 146.2894],
  [-41.2978, 146.2891],
  [-41.2982, 146.2888],
  [-41.2998, 146.2862],
  [-41.3005, 146.2857],
  [-41.3353, 146.2803]
];

export const donRiver: Line = {
  name: 'Don River',
  state: 'TAS',
  segments: [
    {
      segments: [donJunctionDonRiver],
      history: {
        opened: {
          date: '1916-04-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-10-16',
          status: 'closed'
        }, {
          date: '1976-11-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [donRiverPaloona],
      history: {
        opened: {
          date: '1916-04-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-10-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paloonaBarrington],
      history: {
        opened: {
          date: '1923-09-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1935-07-16',
          status: 'closed'
        }]
      }
    }
  ]
}
