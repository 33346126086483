import { Line } from "../../../trackTypes"

const mangaloreLongwood = [
  [-36.9361, 145.1766],
  [-36.9341, 145.1782],
  [-36.9329, 145.1796],
  [-36.9316, 145.1815],
  [-36.9159, 145.2077],
  [-36.9151, 145.2089],
  [-36.9138, 145.2104],
  [-36.8959, 145.2272],
  [-36.8796, 145.2424],
  [-36.8782, 145.2439],
  [-36.8770, 145.2459],
  [-36.8382, 145.3260],
  [-36.8374, 145.3276],
  [-36.8364, 145.3290],
  [-36.8153, 145.3552],
  [-36.8140, 145.3571],
  [-36.8127, 145.3598],
  [-36.8119, 145.3626],
  [-36.8023, 145.4188]
];

const longwoodVioletTown = [
  [-36.8023, 145.4188],
  [-36.7974, 145.4479],
  [-36.7968, 145.4502],
  [-36.7963, 145.4516],
  [-36.7748, 145.4903],
  [-36.7740, 145.4920],
  [-36.7732, 145.4951],
  [-36.7720, 145.5002],
  [-36.7637, 145.5337],
  [-36.7589, 145.5531],
  [-36.7580, 145.5558],
  [-36.7569, 145.5580],
  [-36.7554, 145.5603],
  [-36.7292, 145.5914],
  [-36.7043, 145.6210],
  [-36.6784, 145.6516],
  [-36.6509, 145.6842],
  [-36.6495, 145.6863],
  [-36.6482, 145.6892],
  [-36.6378, 145.7189]
];

const violetTownBenalla = [
  [-36.6378, 145.7189],
  [-36.6249, 145.7560],
  [-36.6004, 145.8260],
  [-36.5569, 145.9503],
  [-36.5446, 145.9836]
];

const benallaWangaratta = [
  [-36.5446, 145.9836],
  [-36.5432, 145.9874],
  [-36.5427, 145.9887],
  [-36.5410, 145.9933],
  [-36.5375, 146.0028],
  [-36.5289, 146.0232],
  [-36.5074, 146.0742],
  [-36.4836, 146.1305],
  [-36.4830, 146.1323],
  [-36.4819, 146.1364],
  [-36.4778, 146.1517],
  [-36.4640, 146.2046],
  [-36.4635, 146.2072],
  [-36.4634, 146.2096],
  [-36.4637, 146.2156],
  [-36.4635, 146.2190],
  [-36.4625, 146.2266],
  [-36.4618, 146.2293],
  [-36.4606, 146.2317],
  [-36.4573, 146.2349],
  [-36.4552, 146.2363],
  [-36.4533, 146.2370],
  [-36.4344, 146.2421],
  [-36.4317, 146.2432],
  [-36.4299, 146.2443],
  [-36.3730, 146.2842],
  [-36.3713, 146.2858],
  [-36.3697, 146.2879],
  [-36.3682, 146.2907],
  [-36.3601, 146.3099],
  [-36.3588, 146.3123],
  [-36.3573, 146.3147],
  [-36.3552, 146.3170]
];

const wangarattaWodonga = [
  [-36.3552, 146.3170],
  [-36.3304, 146.3419],
  [-36.3290, 146.3438],
  [-36.3277, 146.3461],
  [-36.3199, 146.3622],
  [-36.3180, 146.3651],
  [-36.3163, 146.3670],
  [-36.2342, 146.4384],
  [-36.2320, 146.4398],
  [-36.2301, 146.4407],
  [-36.2222, 146.4434],
  [-36.2186, 146.4455],
  [-36.2013, 146.4588],
  [-36.1782, 146.4763],
  [-36.1752, 146.4797],
  [-36.1724, 146.4848],
  [-36.1712, 146.4890],
  [-36.1593, 146.5517],
  [-36.1591, 146.5563],
  [-36.1598, 146.5604],
  [-36.1626, 146.5684],
  [-36.1634, 146.5741],
  [-36.1631, 146.5786],
  [-36.1532, 146.6213],
  [-36.1467, 146.6363],
  [-36.1435, 146.6411],
  [-36.1406, 146.6437],
  [-36.1351, 146.6466],
  [-36.1289, 146.6482],
  [-36.1268, 146.6493],
  [-36.1247, 146.6510],
  [-36.1228, 146.6535],
  [-36.1176, 146.6625],
  [-36.1146, 146.6665],
  [-36.0986, 146.6841],
  [-36.0963, 146.6870],
  [-36.0950, 146.6894],
  [-36.0799, 146.7297],
  [-36.0787, 146.7350],
  [-36.0785, 146.7388],
  [-36.0807, 146.7831],
  [-36.0812, 146.7883],
  [-36.0813, 146.7920],
  [-36.0824, 146.7969],
  [-36.0838, 146.7995],
  [-36.0903, 146.8073],
  [-36.1035, 146.8277],
  [-36.1049, 146.8317],
  [-36.1055, 146.8365],
  [-36.1082, 146.8581]
];

const wodongaWestWodongaOld = [
  [-36.1082, 146.8581],
  [-36.1087, 146.8607],
  [-36.1088, 146.8612],
  [-36.1092, 146.8629],
  [-36.1101, 146.8657],
  [-36.1107, 146.8671],
  [-36.1177, 146.8824],
  [-36.1195, 146.8863],
];

const wodongaOldCudgewaNorthJunction = [
  [-36.1195, 146.8863],
  [-36.1204, 146.8882],
  [-36.1207, 146.8896],
  [-36.1207, 146.8907],
  [-36.1204, 146.8918],
  [-36.1197, 146.8932],
  [-36.1188, 146.8940]
]

const cudgewaNorthJunctionWodongaEast = [
  [-36.1188, 146.8940],
  [-36.1073, 146.9000],
  [-36.1052, 146.9012],
  [-36.1031, 146.9032],
  [-36.1020, 146.9046],
  [-36.1009, 146.9061]
];

const wodongaBypass = [
  [-36.1082, 146.8581],
  [-36.1081, 146.8597],
  [-36.1078, 146.8618],
  [-36.1067, 146.8652],
  [-36.1063, 146.8673],
  [-36.1059, 146.8700],
  [-36.1058, 146.8722],
  [-36.1059, 146.8750],
  [-36.1063, 146.8842],
  [-36.1063, 146.8870],
  [-36.1058, 146.8905],
  [-36.1034, 146.9005],
  [-36.1026, 146.9026],
  [-36.1009, 146.9061]
];

const wodongaNswBorder = [
  [-36.1009, 146.9061],
  [-36.0999, 146.9088]
];

export const northEast: Line = {
  name: 'North East',
  state: 'VIC',
  segments: [
    {
      segments: [mangaloreLongwood],
      history: {
        opened: {
          date: '1872-11-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-03',
          parallelStandard: true
        }, {
          date: '2011-06-26',
          gauge: 'standard',
          parallelStandard: false,
          tracks: 2
        }]
      }
    },
    {
      segments: [longwoodVioletTown],
      history: {
        opened: {
          date: '1873-03-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-03',
          parallelStandard: true
        }, {
          date: '2011-06-26',
          gauge: 'standard',
          parallelStandard: false,
          tracks: 2
        }]
      }
    },
    {
      segments: [violetTownBenalla],
      history: {
        opened: {
          date: '1873-08-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-03',
          parallelStandard: true
        }, {
          date: '2011-06-26',
          gauge: 'standard',
          parallelStandard: false,
          tracks: 2
        }]
      }
    },
    {
      segments: [benallaWangaratta],
      history: {
        opened: {
          date: '1873-10-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-03',
          parallelStandard: true
        }, {
          date: '2011-06-26',
          gauge: 'standard',
          parallelStandard: false,
          tracks: 2
        }]
      }
    },
    {
      segments: [wangarattaWodonga],
      history: {
        opened: {
          date: '1873-11-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-03',
          parallelStandard: true
        }, {
          date: '2011-06-26',
          gauge: 'standard',
          parallelStandard: false,
          tracks: 2
        }]
      }
    },
    {
      segments: [wodongaWestWodongaOld],
      history: {
        opened: {
          date: '1873-11-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-03',
          parallelStandard: true
        }, {
          date: '2009-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wodongaOldCudgewaNorthJunction],
      history: {
        opened: {
          date: '1883-06-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy',
          parallelStandard: true
        },
        trackChange: [{
          date: '2009-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cudgewaNorthJunctionWodongaEast],
      history: {
        opened: {
          date: '1883-06-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy',
          parallelStandard: true
        },
        trackChange: [{
          date: '2009-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wodongaBypass],
      history: {
        opened: {
          date: '2011-06-25',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wodongaNswBorder],
      history: {
        opened: {
          date: '1883-06-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy',
          parallelStandard: true
        },
        trackChange: [{
          date: '2011-06-26',
          gauge: 'standard',
          parallelStandard: false,
          tracks: 1
        }]
      }
    }
  ]
}
