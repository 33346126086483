import { Line } from "../../../../trackTypes";

const flemingtonBridge = [
  [-37.80644, 144.95958],
  [-37.80633, 144.95947],
  [-37.80628, 144.95938],
  [-37.80624, 144.95924],
  [-37.80515, 144.94950],
  [-37.80511, 144.94940],
  [-37.80503, 144.94932],
  [-37.80492, 144.94930],
  [-37.80325, 144.94959],
  [-37.80315, 144.94957],
  [-37.80309, 144.94952],
  [-37.80302, 144.94941],
  [-37.80262, 144.94577],
  [-37.80255, 144.94562],
  [-37.80241, 144.94557],
  [-37.79404, 144.94706],
  [-37.79365, 144.94725],
  [-37.79354, 144.94727],
  [-37.79340, 144.94721]
];

const flemingtonRoad = [
  [-37.79340, 144.94721],
  [-37.78938, 144.94215],
  [-37.78844, 144.94099],
  [-37.78802, 144.94062]
];

const flemingtonBridgeEssendon = [
  [-37.78802, 144.94062],
  [-37.78464, 144.93800],
  [-37.78426, 144.93747],
  [-37.78387, 144.93666],
  [-37.78274, 144.93428],
  [-37.78209, 144.93335],
  [-37.77972, 144.93134],
  [-37.77867, 144.93051],
  [-37.77491, 144.92804],
  [-37.77301, 144.92704],
  [-37.76721, 144.92488],
  [-37.76711, 144.92485],
  [-37.76659, 144.92486],
  [-37.76643, 144.92493],
  [-37.76631, 144.92499],
  [-37.76615, 144.92504],
  [-37.75831, 144.92643],
  [-37.75802, 144.92644],
  [-37.75608, 144.92612],
  [-37.75595, 144.92604],
  [-37.75590, 144.92591],
  [-37.75502, 144.91794],
  [-37.75499, 144.91770],
  [-37.75484, 144.91743],
  [-37.75431, 144.91694],
  [-37.74643, 144.91155]
];

const essendonExtension = [
  [-37.74643, 144.91155],
  [-37.74464, 144.91033],
  [-37.74446, 144.91009],
  [-37.74240, 144.90488]
];

const essendonExtension2 = [
  [-37.74240, 144.90488],
  [-37.73872, 144.89597]
];

const essendonAerodrome = [
  [-37.73872, 144.89597],
  [-37.73614, 144.88942],
  [-37.73598, 144.88927],
  [-37.73582, 144.88920],
  [-37.72672, 144.89075],
  [-37.72582, 144.89088]
];

const matthewsAveExtension = [
  [-37.72582, 144.89088],
  [-37.72173, 144.89154]
];

const airportWest = [
  [-37.72173, 144.89154],
  [-37.71736, 144.89220],
  [-37.71638, 144.89217],
  [-37.71556, 144.89187],
  [-37.71490, 144.89140],
  [-37.71384, 144.89065]
];

const AerodromeSpur = [
  [-37.72582, 144.89088],
  [-37.72577, 144.89100],
  [-37.72573, 144.89110],
  [-37.72594, 144.89570]
];

const mooneePondsStationSpur = [
  [-37.76721, 144.92488],
  [-37.76715, 144.92479],
  [-37.76711, 144.92464],
  [-37.76660, 144.91987]
];

const napierStreetSiding = [
  [-37.75502, 144.91794],
  [-37.75494, 144.91786],
  [-37.75484, 144.91782],
  [-37.75308, 144.91812]
];

const essendonDepot = [
  [-37.77972, 144.93134],
  [-37.77962, 144.93131],
  [-37.77931, 144.93128],
  [-37.77923, 144.93131],
  [-37.77919, 144.93136],
  [-37.77888, 144.93195]
];

export const west1: Line = {
  name: 'West1',
  state: 'VIC',
  segments: [
    {
      segments: [flemingtonBridge],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [flemingtonRoad],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1925-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [flemingtonBridgeEssendon],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [essendonExtension],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [essendonExtension2],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [essendonAerodrome],
      history: {
        opened: {
          date: '1943-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [matthewsAveExtension],
      history: {
        opened: {
          date: '1976-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [airportWest],
      history: {
        opened: {
          date: '1992-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [AerodromeSpur],
      history: {
        opened: {
          date: '1943-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1976-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mooneePondsStationSpur],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1915-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [napierStreetSiding],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '2004-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [essendonDepot],
      history: {
        opened: {
          date: '1906-10-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
