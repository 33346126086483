import { Line } from "../../trackTypes";

const line = [
  [-34.4950, 149.6042],
  [-34.4939, 149.6046],
  [-34.4931, 149.6060],
  [-34.4929, 149.6074],
  [-34.4927, 149.6144],
  [-34.4920, 149.6164],
  [-34.4907, 149.6176],
  [-34.4888, 149.6184],
  [-34.4877, 149.6191],
  [-34.4798, 149.6282],
  [-34.4782, 149.6325],
  [-34.4761, 149.6561],
  [-34.4763, 149.6598],
  [-34.4767, 149.6611],
  [-34.4801, 149.6674],
  [-34.4813, 149.6687],
  [-34.4822, 149.6702],
  [-34.4823, 149.6714],
  [-34.4819, 149.6726],
  [-34.4743, 149.6795],
  [-34.4733, 149.6809],
  [-34.4728, 149.6832],
  [-34.4728, 149.6853],
  [-34.4726, 149.6871],
  [-34.4696, 149.6954],
  [-34.4695, 149.6967],
  [-34.4693, 149.6976],
  [-34.4686, 149.6983],
  [-34.4676, 149.6986],
  [-34.4623, 149.6985],
  [-34.4612, 149.6988],
  [-34.4603, 149.6998],
  [-34.4594, 149.7014],
  [-34.4549, 149.7042],
  [-34.4510, 149.7085],
  [-34.4506, 149.7094],
  [-34.4505, 149.7118],
  [-34.4500, 149.7129],
  [-34.4484, 149.7146],
  [-34.4479, 149.7159],
  [-34.4477, 149.7174],
  [-34.4474, 149.7185],
  [-34.4448, 149.7225],
  [-34.4441, 149.7231],
  [-34.4434, 149.7234],
  [-34.4421, 149.7234],
  [-34.4413, 149.7237],
  [-34.4406, 149.7243],
  [-34.4388, 149.7270],
  [-34.4383, 149.7292],
  [-34.4376, 149.7302],
  [-34.4366, 149.7307],
  [-34.4332, 149.7308],
  [-34.4320, 149.7315],
  [-34.4311, 149.7325],
  [-34.4296, 149.7332],
  [-34.4284, 149.7331],
  [-34.4272, 149.7337],
  [-34.4265, 149.7348],
  [-34.4238, 149.7460],
  [-34.4218, 149.7591],
  [-34.4212, 149.7610],
  [-34.4211, 149.7618],
  [-34.4213, 149.7628],
  [-34.4219, 149.7641],
  [-34.4220, 149.7654],
  [-34.4215, 149.7669],
  [-34.4173, 149.7711],
  [-34.4167, 149.7721],
  [-34.4164, 149.7733],
  [-34.4165, 149.7746],
  [-34.4163, 149.7757],
  [-34.4157, 149.7767],
  [-34.4139, 149.7782],
  [-34.4133, 149.7794],
  [-34.4133, 149.7810],
  [-34.4137, 149.7819],
  [-34.4147, 149.7831],
  [-34.4151, 149.7842],
  [-34.4151, 149.7853],
  [-34.4147, 149.7868],
  [-34.4142, 149.7883],
  [-34.4139, 149.7905],
  [-34.4131, 149.7933],
  [-34.4108, 149.7965],
  [-34.4100, 149.7979],
  [-34.4096, 149.7995],
  [-34.4093, 149.8062],
  [-34.4078, 149.8131]
];

export const taralga: Line = {
  name: 'Taralga',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1926-02-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-05-01',
          status: 'closed'
        }]
      }
    }
  ]
};
