import { Line } from "../../trackTypes";

const line = [
  [-41.5216, 146.5985],
  [-41.5219, 146.5969],
  [-41.5244, 146.5895],
  [-41.5277, 146.5834],
  [-41.5286, 146.5824],
  [-41.5349, 146.5779],
  [-41.5377, 146.5768],
  [-41.5381, 146.5764],
  [-41.5384, 146.5757],
  [-41.5410, 146.5691],
  [-41.5419, 146.5679],
  [-41.5453, 146.5656],
  [-41.5459, 146.5646],
  [-41.5462, 146.5636],
  [-41.5466, 146.5629],
  [-41.5474, 146.5624],
  [-41.5478, 146.5617],
  [-41.5485, 146.5598],
  [-41.5486, 146.5589],
  [-41.5490, 146.5582],
  [-41.5505, 146.5566],
  [-41.5512, 146.5549],
  [-41.5515, 146.5525],
  [-41.5506, 146.5481],
  [-41.5493, 146.5457],
  [-41.5488, 146.5443],
  [-41.5479, 146.5405],
  [-41.5464, 146.5366],
  [-41.5446, 146.5307],
  [-41.5445, 146.5288],
  [-41.5442, 146.5276],
  [-41.5442, 146.5266],
  [-41.5447, 146.5256],
  [-41.5477, 146.5225],
  [-41.5485, 146.5211],
  [-41.5492, 146.5194],
  [-41.5504, 146.5182],
  [-41.5534, 146.5171],
  [-41.5541, 146.5165],
  [-41.5554, 146.5134],
  [-41.5556, 146.5117],
  [-41.5563, 146.5099],
  [-41.5583, 146.5071],
  [-41.5587, 146.5059],
  [-41.5588, 146.5049],
  [-41.5585, 146.5007],
  [-41.5580, 146.4984],
  [-41.5563, 146.4946],
  [-41.5560, 146.4932],
  [-41.5560, 146.4918],
  [-41.5579, 146.4833],
  [-41.5595, 146.4762],
  [-41.5595, 146.4738],
  [-41.5558, 146.4502],
  [-41.5552, 146.4421],
  [-41.5554, 146.4397],
  [-41.5575, 146.4287],
  [-41.5576, 146.4273],
  [-41.5573, 146.4248],
  [-41.5575, 146.4154],
  [-41.5570, 146.4080],
  [-41.5569, 146.4064],
  [-41.5558, 146.3989]
];

export const moleCreek: Line = {
  name: 'Mole Creek',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1890-04-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-02-01',
          status: 'closed'
        }]
      }
    }
  ]
}
