import { Line } from "../../../trackTypes";

const oldLeppertonConnection = [
  [-39.0384, 174.2015],
  [-39.0386, 174.2010],
  [-39.0388, 174.1998],
  [-39.0390, 174.1965]
];

const line = [
  [-39.0419, 174.1992],
  [-39.0410, 174.1995],
  [-39.0392, 174.2006],
  [-39.0384, 174.2015],
  [-39.0310, 174.2118],
  [-39.0232, 174.2192],
  [-39.0215, 174.2207],
  [-39.0120, 174.2255],
  [-39.0115, 174.2259],
  [-39.0110, 174.2264],
  [-39.0103, 174.2268],
  [-39.0088, 174.2266],
  [-39.0067, 174.2255],
  [-39.0060, 174.2254],
  [-39.0052, 174.2258],
  [-39.0047, 174.2264],
  [-39.0005, 174.2351],
  [-38.9995, 174.2370]
];

const sidings = [
  [-38.9995, 174.2370],
  [-38.9994, 174.2375],
  [-38.9995, 174.2377],
  [-39.0006, 174.2399],
  [-39.0008, 174.2402],
  [-39.0013, 174.2405],
  [-39.0015, 174.2406],
  [-39.0018, 174.2405],
  [-39.0021, 174.2404]
];

export const waitara: Line = {
  name: 'Waitara',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1875-10-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1999-02-02',
          status: 'closed'
        }, {
          date: '2003-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [oldLeppertonConnection],
      history: {
        opened: {
          date: '1875-10-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1908-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sidings],
      history: {
        opened: {
          date: '1875-10-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1999-02-02',
          status: 'closed'
        }]
      }
    }
  ]
}
