import { Line } from "../../../trackTypes";

const plantinaThomson = [
  [-37.9746, 146.4181],
  [-37.9748, 146.4183],
  [-37.9749, 146.4185],
  [-37.9748, 146.4188],
  [-37.9746, 146.4189],
  [-37.9744, 146.4188],
  [-37.9727, 146.4192],
  [-37.9717, 146.4195],
  [-37.9714, 146.4195],
  [-37.9708, 146.4193],
  [-37.9705, 146.4193],
  [-37.9696, 146.4196],
  [-37.9691, 146.4206],
  [-37.9689, 146.4222],
  [-37.9689, 146.4237],
  [-37.9689, 146.4239],
  [-37.9687, 146.4240],
  [-37.9675, 146.4241],
  [-37.9657, 146.4240],
  [-37.9652, 146.4237],
  [-37.9650, 146.4233],
  [-37.9651, 146.4230],
  [-37.9662, 146.4223],
  [-37.9668, 146.4214],
  [-37.9670, 146.4203],
  [-37.9667, 146.4197],
  [-37.9659, 146.4191],
  [-37.9646, 146.4186],
  [-37.9643, 146.4182],
  [-37.9640, 146.4184],
  [-37.9631, 146.4186],
  [-37.9621, 146.4186],
  [-37.9615, 146.4185]
];

const thomsonCascadeHalt = [
  [-37.9615, 146.4185],
  [-37.9611, 146.4187],
  [-37.9599, 146.4194],
  [-37.9598, 146.4195],
  [-37.9596, 146.4196],
  [-37.9596, 146.4197],
  [-37.9595, 146.4198],
  [-37.9590, 146.4211],
  [-37.9590, 146.4213],
  [-37.9590, 146.4214],
  [-37.9590, 146.4221],
  [-37.9590, 146.4224],
  [-37.9588, 146.4232],
  [-37.9587, 146.4234],
  [-37.9582, 146.4236],
  [-37.9575, 146.4235],
  [-37.9572, 146.4235],
  [-37.9571, 146.4236],
  [-37.9570, 146.4237],
  [-37.9567, 146.4242],
  [-37.9565, 146.4244],
  [-37.9562, 146.4244],
  [-37.9558, 146.4244],
  [-37.9557, 146.4245],
  [-37.9554, 146.4249],
  [-37.9547, 146.4256]
];

const cascadeHalyHappyCreekHalt = [
  [-37.9547, 146.4256],
  [-37.9544, 146.4259],
  [-37.9540, 146.4260],
  [-37.9539, 146.4261],
  [-37.9536, 146.4265],
  [-37.9536, 146.4271],
  [-37.9531, 146.4280],
  [-37.9531, 146.4288],
  [-37.9529, 146.4301],
  [-37.9526, 146.4306],
  [-37.9524, 146.4309],
  [-37.9523, 146.4310],
  [-37.9524, 146.4311],
  [-37.9526, 146.4317],
  [-37.9527, 146.4323],
  [-37.9530, 146.4329],
  [-37.9532, 146.4334],
  [-37.9532, 146.4338],
  [-37.9531, 146.4348],
  [-37.9529, 146.4353],
  [-37.9529, 146.4359],
  [-37.9527, 146.4365],
  [-37.9527, 146.4367],
  [-37.9530, 146.4371],
  [-37.9533, 146.4373],
  [-37.9536, 146.4378],
  [-37.9539, 146.4386],
  [-37.9541, 146.4388],
  [-37.9543, 146.4390],
  [-37.9545, 146.4394],

  [-37.9547, 146.4398]
];

const happyCreekHaultWalhalla = [
  [-37.9547, 146.4398],
  [-37.9547, 146.4400],
  [-37.9546, 146.4406],
  [-37.9542, 146.4409],
  [-37.9537, 146.4410],
  [-37.9529, 146.4406],
  [-37.9527, 146.4407],
  [-37.9525, 146.4408],
  [-37.9524, 146.4410],
  [-37.9522, 146.4418],
  [-37.9521, 146.4423],
  [-37.9520, 146.4427],
  [-37.9520, 146.4429],
  [-37.9520, 146.4431],
  [-37.9521, 146.4445],
  [-37.9523, 146.4449],
  [-37.9524, 146.4451],
  [-37.9524, 146.4454],
  [-37.9524, 146.4457],
  [-37.9523, 146.4459],
  [-37.9523, 146.4463],
  [-37.9523, 146.4468],
  [-37.9523, 146.4471],
  [-37.9523, 146.4472],
  [-37.9522, 146.4473],
  [-37.9520, 146.4475],
  [-37.9518, 146.4477],
  [-37.9513, 146.4479],
  [-37.9512, 146.4480],
  [-37.9510, 146.4482],
  [-37.9508, 146.4486],
  [-37.9506, 146.4490],
  [-37.9505, 146.4493],
  [-37.9504, 146.4495],
  [-37.9502, 146.4497]
];

const ericaPlatina = [
  [-37.9792, 146.3729],
  [-37.9779, 146.3740],
  [-37.9776, 146.3743],
  [-37.9767, 146.3766],
  [-37.9767, 146.3774],
  [-37.9767, 146.3783],
  [-37.9763, 146.3791],
  [-37.9752, 146.3800],
  [-37.9751, 146.3806],
  [-37.9751, 146.3808],
  [-37.9753, 146.3816],
  [-37.9759, 146.3839],
  [-37.9758, 146.3852],
  [-37.9750, 146.3866],
  [-37.9722, 146.3889],
  [-37.9716, 146.3890],
  [-37.9714, 146.3891],
  [-37.9713, 146.3894],
  [-37.9715, 146.3909],
  [-37.9712, 146.3917],
  [-37.9704, 146.3925],
  [-37.9702, 146.3932],
  [-37.9702, 146.3942],
  [-37.9706, 146.3951],
  [-37.9706, 146.3952],
  [-37.9706, 146.3953],
  [-37.9691, 146.3980],
  [-37.9691, 146.3985],
  [-37.9691, 146.3988],
  [-37.9694, 146.3991],
  [-37.9702, 146.3996],
  [-37.9706, 146.4002],
  [-37.9707, 146.4011],
  [-37.9708, 146.4015],
  [-37.9711, 146.4017],
  [-37.9722, 146.4023],
  [-37.9728, 146.4025],
  [-37.9733, 146.4029],
  [-37.9742, 146.4050],
  [-37.9741, 146.4056],
  [-37.9736, 146.4065],
  [-37.9735, 146.4067],
  [-37.9736, 146.4070],
  [-37.9744, 146.4085],
  [-37.9744, 146.4090],
  [-37.9744, 146.4094],
  [-37.9742, 146.4098],
  [-37.9732, 146.4106],
  [-37.9728, 146.4106],
  [-37.9725, 146.4109],
  [-37.9721, 146.4123],
  [-37.9718, 146.4129],
  [-37.9716, 146.4151],
  [-37.9717, 146.4155],
  [-37.9716, 146.4158],
  [-37.9713, 146.4159],
  [-37.9696, 146.4156],
  [-37.9693, 146.4157],
  [-37.9692, 146.4159],
  [-37.9691, 146.4163],
  [-37.9694, 146.4166],
  [-37.9719, 146.4170],
  [-37.9728, 146.4168],
  [-37.9733, 146.4170],
  [-37.9741, 146.4179],
  [-37.9746, 146.4181]
];

const moeErica = [
  [-38.1768, 146.2604],
  [-38.1767, 146.2600],
  [-38.1766, 146.2598],
  [-38.1764, 146.2596],
  [-38.1760, 146.2593],
  [-38.1757, 146.2592],
  [-38.1754, 146.2592],
  [-38.1726, 146.2598],
  [-38.1717, 146.2602],
  [-38.1632, 146.2647],
  [-38.1581, 146.2673],
  [-38.1562, 146.2689],
  [-38.1557, 146.2692],
  [-38.1501, 146.2693],
  [-38.1492, 146.2695],
  [-38.1465, 146.2710],
  [-38.1458, 146.2710],
  [-38.1406, 146.2696],
  [-38.1393, 146.2697],
  [-38.1363, 146.2708],
  [-38.1334, 146.2727],
  [-38.1187, 146.2859],
  [-38.1166, 146.2887],
  [-38.1147, 146.2904],
  [-38.1124, 146.2918],
  [-38.1079, 146.2941],
  [-38.1051, 146.2965],
  [-38.1043, 146.2985],
  [-38.1039, 146.3011],
  [-38.1014, 146.3074],
  [-38.0977, 146.3114],
  [-38.0971, 146.3126],
  [-38.0949, 146.3161],
  [-38.0946, 146.3178],
  [-38.0943, 146.3206],
  [-38.0939, 146.3215],
  [-38.0930, 146.3217],
  [-38.0920, 146.3225],
  [-38.0898, 146.3251],
  [-38.0894, 146.3256],
  [-38.0888, 146.3258],
  [-38.0879, 146.3270],
  [-38.0873, 146.3271],
  [-38.0860, 146.3267],
  [-38.0853, 146.3271],
  [-38.0833, 146.3302],
  [-38.0830, 146.3313],
  [-38.0830, 146.3340],
  [-38.0826, 146.3349],
  [-38.0820, 146.3351],
  [-38.0803, 146.3353],
  [-38.0787, 146.3353],
  [-38.0776, 146.3359],
  [-38.0753, 146.3391],
  [-38.0744, 146.3425],
  [-38.0731, 146.3500],
  [-38.0721, 146.3516],
  [-38.0718, 146.3534],
  [-38.0715, 146.3540],
  [-38.0706, 146.3549],
  [-38.0671, 146.3630],
  [-38.0668, 146.3635],
  [-38.0664, 146.3636],
  [-38.0657, 146.3636],
  [-38.0639, 146.3644],
  [-38.0629, 146.3642],
  [-38.0612, 146.3644],
  [-38.0607, 146.3650],
  [-38.0598, 146.3651],
  [-38.0591, 146.3656],
  [-38.0587, 146.3665],
  [-38.0581, 146.3668],
  [-38.0569, 146.3671],
  [-38.0565, 146.3678],
  [-38.0560, 146.3680],
  [-38.0553, 146.3679],
  [-38.0545, 146.3682],
  [-38.0522, 146.3736],
  [-38.0513, 146.3743],
  [-38.0506, 146.3743],
  [-38.0487, 146.3755],
  [-38.0483, 146.3767],
  [-38.0480, 146.3771],
  [-38.0476, 146.3769],
  [-38.0470, 146.3766],
  [-38.0466, 146.3764],
  [-38.0449, 146.3769],
  [-38.0443, 146.3763],
  [-38.0426, 146.3758],
  [-38.0420, 146.3760],
  [-38.0414, 146.3771],
  [-38.0413, 146.3789],
  [-38.0412, 146.3803],
  [-38.0404, 146.3810],
  [-38.0399, 146.3810],
  [-38.0395, 146.3807],
  [-38.0393, 146.3799],
  [-38.0392, 146.3787],
  [-38.0390, 146.3781],
  [-38.0386, 146.3779],
  [-38.0376, 146.3779],
  [-38.0371, 146.3777],
  [-38.0366, 146.3772],
  [-38.0354, 146.3754],
  [-38.0343, 146.3742],
  [-38.0338, 146.3731],
  [-38.0338, 146.3723],
  [-38.0339, 146.3712],
  [-38.0338, 146.3707],
  [-38.0335, 146.3703],
  [-38.0324, 146.3700],
  [-38.0287, 146.3658],
  [-38.0284, 146.3647],
  [-38.0284, 146.3631],
  [-38.0283, 146.3625],
  [-38.0280, 146.3622],
  [-38.0275, 146.3623],
  [-38.0264, 146.3626],
  [-38.0246, 146.3619],
  [-38.0241, 146.3613],
  [-38.0236, 146.3601],
  [-38.0233, 146.3600],
  [-38.0229, 146.3601],
  [-38.0216, 146.3608],
  [-38.0207, 146.3610],
  [-38.0203, 146.3613],
  [-38.0202, 146.3616],
  [-38.0201, 146.3637],
  [-38.0199, 146.3642],
  [-38.0196, 146.3643],
  [-38.0181, 146.3643],
  [-38.0177, 146.3644],
  [-38.0174, 146.3649],
  [-38.0149, 146.3697],
  [-38.0135, 146.3711],
  [-38.0126, 146.3725],
  [-38.0120, 146.3730],
  [-38.0115, 146.3731],
  [-38.0099, 146.3727],
  [-38.0069, 146.3731],
  [-38.0060, 146.3730],
  [-38.0038, 146.3724],
  [-38.0032, 146.3726],
  [-38.0021, 146.3728],
  [-38.0015, 146.3726],
  [-38.0001, 146.3719],
  [-37.9985, 146.3717],
  [-37.9980, 146.3714],
  [-37.9956, 146.3675],
  [-37.9952, 146.3672],
  [-37.9900, 146.3649],
  [-37.9894, 146.3650],
  [-37.9876, 146.3661],
  [-37.9855, 146.3679],
  [-37.9850, 146.3680],
  [-37.9843, 146.3681],
  [-37.9838, 146.3683],
  [-37.9818, 146.3707],
  [-37.9807, 146.3714],
  [-37.9792, 146.3729]
];

export const walhalla: Line = {
  name: 'Walhalla',
  state: 'VIC',
  segments: [
    {
      segments: [plantinaThomson],
      history: {
        opened: {
          date: '1910-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1944-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [thomsonCascadeHalt],
      history: {
        opened: {
          date: '1910-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1944-04-01',
          status: 'closed'
        }, {
          date: '1997-10-13',
          status: 'open'
        }]
      }
    },
    {
      segments: [cascadeHalyHappyCreekHalt],
      history: {
        opened: {
          date: '1910-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1944-04-01',
          status: 'closed'
        }, {
          date: '1998-03-27',
          status: 'open'
        }]
      }
    },
    {
      segments: [happyCreekHaultWalhalla],
      history: {
        opened: {
          date: '1910-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1944-04-01',
          status: 'closed'
        }, {
          date: '2002-03-15',
          status: 'open'
        }]
      }
    },
    {
      segments: [ericaPlatina],
      history: {
        opened: {
          date: '1910-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moeErica],
      history: {
        opened: {
          date: '1910-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-06-25',
          status: 'closed'
        }]
      }
    }
  ]
}
