import { Line } from "../../trackTypes";

const line = [
  [-35.8101, 137.7447],
  [-35.8101, 137.7441],
  [-35.8103, 137.7438],
  [-35.8106, 137.7435],
  [-35.8115, 137.7435],
  [-35.8170, 137.7420],
  [-35.8173, 137.7417],
  [-35.8178, 137.7404],
  [-35.8180, 137.7400],
  [-35.8182, 137.7398],
  [-35.8192, 137.7397],
  [-35.8201, 137.7399],
  [-35.8206, 137.7397],
  [-35.8211, 137.7394],
  [-35.8213, 137.7391],
  [-35.8213, 137.7386],
  [-35.8202, 137.7367],
  [-35.8201, 137.7361],
  [-35.8197, 137.7325],
  [-35.8193, 137.7312],
  [-35.8193, 137.7309],
  [-35.8194, 137.7305],
  [-35.8207, 137.7276],
  [-35.8406, 137.7017],
  [-35.8410, 137.7014],
  [-35.8419, 137.7015],
  [-35.8423, 137.7014],
  [-35.8425, 137.7011],
  [-35.8431, 137.6995],
  [-35.8437, 137.6990],
  [-35.8446, 137.6990],
  [-35.8452, 137.6987],
  [-35.8455, 137.6981],
  [-35.8462, 137.6977],
  [-35.8476, 137.6977],
  [-35.8480, 137.6975],
  [-35.8483, 137.6974],
  [-35.8494, 137.6962],
  [-35.8500, 137.6957],
  [-35.8505, 137.6956],
  [-35.8512, 137.6956],
  [-35.8531, 137.6953],
  [-35.8536, 137.6950],
  [-35.8540, 137.6945],
  [-35.8564, 137.6903],
  [-35.8568, 137.6899],
  [-35.8575, 137.6896],
  [-35.8581, 137.6890],
  [-35.8589, 137.6879],
  [-35.8593, 137.6877],
  [-35.8612, 137.6875],
  [-35.8618, 137.6873],
  [-35.8694, 137.6819]
];

export const kangarooIsland: Line = {
  name: 'Kangaroo Island Salt Tramway',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1910-02-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1930-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
