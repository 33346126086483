import { Line } from "../../trackTypes";

const junctionTunnelStart = [
  [-34.4534, 150.4375],
  [-34.4528, 150.4358],
  [-34.4529, 150.4305],
  [-34.4516, 150.4221],
  [-34.4513, 150.4217],
  [-34.4509, 150.4215],
  [-34.4490, 150.4217],
  [-34.4481, 150.4216],
  [-34.4446, 150.4225],
  [-34.4440, 150.4224],
  [-34.4433, 150.4218],
  [-34.4419, 150.4202],
  [-34.4408, 150.4182],
  [-34.4403, 150.4166],
  [-34.4395, 150.4126],
  [-34.4382, 150.4108],
  [-34.4373, 150.4103],
  [-34.4366, 150.4102],
  [-34.4362, 150.4104],
  [-34.4354, 150.4111],
  [-34.4338, 150.4129],
  [-34.4330, 150.4141],
  [-34.4328, 150.4156],
  [-34.4320, 150.4182],
  [-34.4315, 150.4190],
  [-34.4309, 150.4192],
  [-34.4296, 150.4192],
  [-34.4283, 150.4188],
  [-34.4262, 150.4184],
  [-34.4249, 150.4187],
  [-34.4242, 150.4191],
  [-34.4238, 150.4197],
  [-34.4228, 150.4227],
  [-34.4221, 150.4238],
  [-34.4218, 150.4247],
  [-34.4212, 150.4254],
  [-34.4207, 150.4255],
  [-34.4204, 150.4256],
  [-34.4199, 150.4269],
  [-34.4197, 150.4280],
  [-34.4194, 150.4288],
  [-34.4187, 150.4292]
];

const boxValeTunnel = [
  [-34.4187, 150.4292],
  [-34.4178, 150.4294]
];

const tunnelEndTerminus = [
  [-34.4178, 150.4294],
  [-34.4154, 150.4312]
];

export const boxValeColliery: Line = {
  name: 'Box Vale Colliery',
  state: 'NSW',
  segments: [
    {
      segments: [
        junctionTunnelStart,
        {
          name: 'Box Vale Tunnel',
          segment: boxValeTunnel,
          type: 'tunnel'
        },
        tunnelEndTerminus
      ],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1896-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
