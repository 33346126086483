import { Line } from "../../trackTypes";

const crystalBrookGladstoneOldAllignment = [
  [-33.3553, 138.2079],
  [-33.3656, 138.2197],
  [-33.3664, 138.2214],
  [-33.3683, 138.2283],
  [-33.3682, 138.2296],
  [-33.3675, 138.2310],
  [-33.3668, 138.2316],
  [-33.3650, 138.2324],
  [-33.3643, 138.2331],
  [-33.3638, 138.2342],
  [-33.3637, 138.2353],
  [-33.3637, 138.2366],
  [-33.3631, 138.2379],
  [-33.3623, 138.2386],
  [-33.3606, 138.2391],
  [-33.3592, 138.2403],
  [-33.3586, 138.2423],
  [-33.3589, 138.2440],
  [-33.3599, 138.2451],
  [-33.3624, 138.2461],
  [-33.3632, 138.2469],
  [-33.3636, 138.2480],
  [-33.3638, 138.2526],
  [-33.3634, 138.2537],
  [-33.3626, 138.2545],
  [-33.3618, 138.2548],
  [-33.3568, 138.2552],
  [-33.3523, 138.2552],
  [-33.3460, 138.2571],
  [-33.3447, 138.2571],
  [-33.3426, 138.2569],
  [-33.3415, 138.2570],
  [-33.3396, 138.2574],
  [-33.3381, 138.2573],
  [-33.3368, 138.2577],
  [-33.3359, 138.2588],
  [-33.3332, 138.2694],
  [-33.3334, 138.2726],
  [-33.3348, 138.2766],
  [-33.3351, 138.2786],
  [-33.3346, 138.2819],
  [-33.3332, 138.2835],
  [-33.3274, 138.2857],
  [-33.3230, 138.2887],
  [-33.3210, 138.2892],
  [-33.3185, 138.2894],
  [-33.3176, 138.2898],
  [-33.3161, 138.2907],
  [-33.3150, 138.2910],
  [-33.3119, 138.2914],
  [-33.3106, 138.2924],
  [-33.3099, 138.2938],
  [-33.3099, 138.2952],
  [-33.3106, 138.2968],
  [-33.3117, 138.2981],
  [-33.3124, 138.3002],
  [-33.3123, 138.3024],
  [-33.3117, 138.3039],
  [-33.3110, 138.3050],
  [-33.3091, 138.3067],
  [-33.3079, 138.3075],
  [-33.3040, 138.3084]
];

const crystalBrookGladstoneNewAllignment = [
  [-33.3553, 138.2079],
  [-33.3572, 138.2102],
  [-33.3588, 138.2134],
  [-33.3600, 138.2162],
  [-33.3617, 138.2188],
  [-33.3642, 138.2217],
  [-33.3655, 138.2249],
  [-33.3656, 138.2282],
  [-33.3649, 138.2313],
  [-33.3635, 138.2334],
  [-33.3603, 138.2368],
  [-33.3589, 138.2392],
  [-33.3584, 138.2424],
  [-33.3587, 138.2447],
  [-33.3594, 138.2473],
  [-33.3594, 138.2499],
  [-33.3581, 138.2529],
  [-33.3563, 138.2543],
  [-33.3545, 138.2549],
  [-33.3509, 138.2549],
  [-33.3457, 138.2562],
  [-33.3400, 138.2563],
  [-33.3372, 138.2572],
  [-33.3349, 138.2590],
  [-33.3327, 138.2626],
  [-33.3319, 138.2660],
  [-33.3318, 138.2778],
  [-33.3311, 138.2810],
  [-33.3292, 138.2839],
  [-33.3271, 138.2854],
  [-33.3210, 138.2878],
  [-33.3138, 138.2896],
  [-33.3115, 138.2911],
  [-33.3096, 138.2937],
  [-33.3086, 138.2976],
  [-33.3088, 138.3003],
  [-33.3082, 138.3038],
  [-33.3062, 138.3068],
  [-33.3040, 138.3084]
];

const crystalBrookGladstoneShared = [
  [-33.3040, 138.3084],
  [-33.2951, 138.3109],
  [-33.2912, 138.3133],
  [-33.2884, 138.3164],
  [-33.2853, 138.3225],
  [-33.2845, 138.3259],
  [-33.2834, 138.3354],
  [-33.2774, 138.3551],
  [-33.2763, 138.3567],
  [-33.2750, 138.3574],
  [-33.2697, 138.3575],
  [-33.2647, 138.3575],
  [-33.2619, 138.3583],
  [-33.2506, 138.3655],
  [-33.2479, 138.3683],
  [-33.2430, 138.3760],
];

const gladstoneOld = [
  [-33.2430, 138.3760],
  [-33.2413, 138.3853],
  [-33.2403, 138.3877],
  [-33.2366, 138.3898],
  [-33.2358, 138.3908],
  [-33.2355, 138.3919],
  [-33.2362, 138.3981],
  [-33.2355, 138.4003],
  [-33.2337, 138.4013],
  [-33.2308, 138.4014],
  [-33.2295, 138.4017],
  [-33.2284, 138.4026],
  [-33.2279, 138.4041],
  [-33.2281, 138.4059],
  [-33.2306, 138.4118],
  [-33.2305, 138.4135],
  [-33.2297, 138.4148],
  [-33.2285, 138.4152],
  [-33.2265, 138.4150],
  [-33.2250, 138.4158],
  [-33.2240, 138.4177],
  [-33.2241, 138.4198],
  [-33.2249, 138.4213],
  [-33.2268, 138.4238],
  [-33.2274, 138.4258],
  [-33.2273, 138.4279],
  [-33.2264, 138.4292],
  [-33.2240, 138.4312],
  [-33.2228, 138.4316],
  [-33.2212, 138.4320],
  [-33.2200, 138.4331],
  [-33.2195, 138.4350],
  [-33.2200, 138.4371],
  [-33.2198, 138.4394],
  [-33.2187, 138.4407],
  [-33.2117, 138.4431],
  [-33.2098, 138.4430],
  [-33.2058, 138.4455],
  [-33.2046, 138.4475],
  [-33.2034, 138.4534],
  [-33.2027, 138.4548],
  [-33.2019, 138.4554],
  [-33.1973, 138.4574],
  [-33.1852, 138.4730],
  [-33.1842, 138.4754],
  [-33.1833, 138.4792],
  [-33.1834, 138.4812],
  [-33.1851, 138.4864],
  [-33.1851, 138.4885],
  [-33.1846, 138.4912],
  [-33.1845, 138.4936],
  [-33.1840, 138.4966],
  [-33.1835, 138.5178],
  [-33.1843, 138.5203],
  [-33.1899, 138.5311],
  [-33.1912, 138.5324],
  [-33.1922, 138.5327],
  [-33.1938, 138.5327],
  [-33.1950, 138.5333],
  [-33.1957, 138.5342],
  [-33.1976, 138.5390],
  [-33.1983, 138.5401],
  [-33.2019, 138.5429],
  [-33.2030, 138.5437],
  [-33.2038, 138.5448],
  [-33.2043, 138.5460],
  [-33.2050, 138.5523]
];

const gladstoneNew = [
  [-33.2430, 138.3760],
  [-33.2409, 138.3808],
  [-33.2284, 138.4026],
  [-33.2204, 138.4162],
  [-33.2159, 138.4217],
  [-33.2091, 138.4343],
  [-33.2032, 138.4500],
  [-33.2005, 138.4543],
  [-33.1973, 138.4574],
  [-33.1855, 138.4685],
  [-33.1840, 138.4708],
  [-33.1829, 138.4738],
  [-33.1827, 138.4764],
  [-33.1829, 138.4798],
  [-33.1846, 138.4912],
  [-33.1845, 138.4936],
  [-33.1840, 138.4966],
  [-33.1835, 138.5178],
  [-33.1842, 138.5224],
  [-33.1904, 138.5320],
  [-33.2022, 138.5446],
  [-33.2038, 138.5473],
  [-33.2050, 138.5523]

];

const jamestownShared = [
  [-33.2050, 138.5523],
  [-33.2049, 138.5555],
  [-33.2033, 138.5621],
  [-33.2029, 138.5671],
  [-33.2036, 138.5777],
  [-33.2033, 138.5808],
  [-33.2006, 138.6003],
  [-33.2005, 138.6019],
  [-33.2006, 138.6037],
  [-33.2017, 138.6086]
];

const jamestownNew = [
  [-33.2006, 138.6037],
  [-33.2006, 138.6085],
  [-33.2001, 138.6107],
  [-33.1991, 138.6124],
  [-33.1973, 138.6139],
  [-33.1951, 138.6144],
  [-33.1802, 138.6119],
  [-33.1782, 138.6118],
  [-33.1181, 138.6195],
  [-33.1010, 138.6258],
  [-33.0973, 138.6282],
  [-33.0937, 138.6331],
  [-33.0911, 138.6404],
  [-33.0885, 138.6549],
  [-33.0865, 138.6599],
  [-33.0631, 138.6957],
  [-33.0450, 138.7159],
  [-33.0422, 138.7214],
  [-33.0415, 138.7267],
  [-33.0417, 138.7358],
  [-33.0405, 138.7419],
  [-33.0388, 138.7455],
  [-33.0365, 138.7482],
  [-33.0335, 138.7504],
  [-33.0295, 138.7519],
  [-33.0215, 138.7544],
  [-33.0186, 138.7562],
  [-33.0166, 138.7578]
];

const jamestownOld = [
  [-33.2006, 138.6037],
  [-33.2017, 138.6086],
  [-33.2016, 138.6096],
  [-33.2013, 138.6103],
  [-33.1982, 138.6156],
  [-33.1970, 138.6202],
  [-33.1974, 138.6263],
  [-33.1969, 138.6276],
  [-33.1962, 138.6284],
  [-33.1950, 138.6291],
  [-33.1940, 138.6307],
  [-33.1936, 138.6325],
  [-33.1931, 138.6339],
  [-33.1901, 138.6380],
  [-33.1882, 138.6392],
  [-33.1752, 138.6429],
  [-33.1739, 138.6439],
  [-33.1726, 138.6455],
  [-33.1450, 138.6616],
  [-33.1371, 138.6677],
  [-33.1282, 138.6764],
  [-33.1205, 138.6884],
  [-33.1063, 138.6979],
  [-33.1017, 138.7001],
  [-33.0953, 138.7052],
  [-33.0904, 138.7080],
  [-33.0789, 138.7185],
  [-33.0760, 138.7241],
  [-33.0746, 138.7256],
  [-33.0701, 138.7276],
  [-33.0690, 138.7286],
  [-33.0681, 138.7303],
  [-33.0671, 138.7311],
  [-33.0534, 138.7371],
  [-33.0365, 138.7482],
  [-33.0335, 138.7504],
  [-33.0295, 138.7519],
  [-33.0222, 138.7518],
  [-33.0213, 138.7521],
  [-33.0205, 138.7527],
  [-33.0166, 138.7578]
];

const yongalaPeterborough = [
  [-33.0166, 138.7578],
  [-32.9914, 138.7901],
  [-32.9753, 138.8277],
  [-32.9745, 138.8312],
  [-32.9717, 138.8599],
  [-32.9709, 138.8631],
  [-32.9612, 138.8899],
  [-32.9601, 138.8917],
  [-32.9568, 138.8965],
  [-32.9538, 138.9039]
];

const peterboroughDowdsHillTunnel = [
  [-32.9538, 138.9039],
  [-32.9536, 138.9057],
  [-32.9537, 138.9063]
];

const dowdsHillTunnel = [
  [-32.9537, 138.9063],
  [-32.9538, 138.9070]
];

const dowdsHillTunnelUcolta = [
  [-32.9538, 138.9070],
  [-32.9539, 138.9082],
  [-32.9536, 138.9095],
  [-32.9527, 138.9109],
  [-32.9487, 138.9138],
  [-32.9477, 138.9153],
  [-32.9472, 138.9174],
  [-32.9482, 138.9313]
];

const peterboroughNew = [
  [-32.9538, 138.9039],
  [-32.9531, 138.9073],
  [-32.9532, 138.9098],
  [-32.9530, 138.9122],
  [-32.9489, 138.9244],
  [-32.9483, 138.9274],
  [-32.9482, 138.9313]
];

const ucolta = [
  [-32.9482, 138.9313],
  [-32.9503, 138.9642],
  [-32.9495, 138.9745],
  [-32.9486, 138.9779],
  [-32.9440, 138.9898]
];

const ucoltaOld = [
  [-32.9440, 138.9898],
  [-32.9250, 139.0171],
  [-32.9133, 139.0292],
  [-32.8788, 139.0683],
  [-32.8767, 139.0711],
  [-32.8728, 139.0812],
  [-32.8692, 139.0933],
  [-32.8642, 139.1039],
  [-32.8627, 139.1093],
  [-32.8626, 139.1109],
  [-32.8628, 139.1137],
  [-32.8625, 139.1163],
  [-32.8627, 139.1181],
  [-32.8637, 139.1221],
  [-32.8639, 139.1261],
  [-32.8642, 139.1275],
  [-32.8649, 139.1291],
  [-32.8652, 139.1303],
  [-32.8651, 139.1317],
  [-32.8577, 139.1476],
  [-32.8567, 139.1501],
  [-32.8564, 139.1545],
  [-32.8568, 139.1570],
  [-32.8582, 139.1606],
  [-32.8594, 139.1626],
  [-32.8596, 139.1644],
  [-32.8589, 139.1662],
  [-32.8515, 139.1734],
  [-32.8507, 139.1747],
  [-32.8503, 139.1763],
  [-32.8505, 139.1786],
  [-32.8497, 139.1805],
  [-32.8487, 139.1814],
  [-32.8471, 139.1822],
  [-32.8456, 139.1835],
  [-32.8410, 139.1909],
  [-32.8393, 139.1925],
  [-32.8373, 139.1932],
  [-32.8347, 139.1932],
  [-32.8268, 139.1958],
  [-32.8256, 139.1969],
  [-32.8244, 139.1996],
  [-32.8239, 139.2045],
  [-32.8232, 139.2062],
  [-32.7935, 139.2436],
  [-32.7736, 139.2616],
  [-32.7730, 139.2630],
  [-32.7727, 139.2643],
  [-32.7729, 139.2721],
  [-32.7725, 139.2740],
  [-32.7671, 139.2870],
  [-32.7610, 139.3020],
  [-32.7591, 139.3053]
];

const ucoltaNew = [
  [-32.9440, 138.9898],
  [-32.9435, 138.9916],
  [-32.9290, 139.0541],
  [-32.9288, 139.0604],
  [-32.9340, 139.0852],
  [-32.9353, 139.1050],
  [-32.9347, 139.1107],
  [-32.9251, 139.1333],
  [-32.9239, 139.1379],
  [-32.9205, 139.1595],
  [-32.9197, 139.1686],
  [-32.9182, 139.1732],
  [-32.9153, 139.1776],
  [-32.8988, 139.1923],
  [-32.8869, 139.2085],
  [-32.8700, 139.2226],
  [-32.8643, 139.2285],
  [-32.8580, 139.2327],
  [-32.8364, 139.2513],
  [-32.8028, 139.2726],
  [-32.7610, 139.3037],
  [-32.7591, 139.3053]
];

const nackara = [
  [-32.7591, 139.3053],
  [-32.7502, 139.3147],
  [-32.7202, 139.3456],
  [-32.7109, 139.3579],
  [-32.6614, 139.4446],
  [-32.6510, 139.4578],
  [-32.6162, 139.5138],
  [-32.5981, 139.5427],
  [-32.5897, 139.5510],
  [-32.5873, 139.5542],
  [-32.5792, 139.5683],
  [-32.5783, 139.5708],
  [-32.5769, 139.5769],
  [-32.5666, 139.6007],
  [-32.5599, 139.6107],
  [-32.5422, 139.6640],
  [-32.5406, 139.6738],
  [-32.5388, 139.6790],
  [-32.5239, 139.7000],
  [-32.5092, 139.7297],
  [-32.5035, 139.7494],
  [-32.4984, 139.7786],
  [-32.4911, 139.8009],
  [-32.4871, 139.8209],
  [-32.4824, 139.8376],
  [-32.4770, 139.8666],
  [-32.4758, 139.8700],
  [-32.4556, 139.9122],
  [-32.4363, 139.9613],
  [-32.4325, 139.9801],
  [-32.4273, 140.0204],
  [-32.4265, 140.0233],
  [-32.4029, 140.0873],
  [-32.3695, 140.1375],
  [-32.3143, 140.2081],
  [-32.3126, 140.2114],
  [-32.3011, 140.2506],
  [-32.2809, 140.3327],
  [-32.2789, 140.3374],
  [-32.2761, 140.3408],
  [-32.2717, 140.3446],
  [-32.2696, 140.3470],
  [-32.2677, 140.3505],
  [-32.2554, 140.3834],
  [-32.2535, 140.3954],
  [-32.2503, 140.4022],
  [-32.2386, 140.4158],
  [-32.2360, 140.4205],
  [-32.2322, 140.4310],
  [-32.2294, 140.4356],
  [-32.2278, 140.4399],
  [-32.2271, 140.4436],
  [-32.2253, 140.4479],
  [-32.2116, 140.4683],
  [-32.2095, 140.4730],
  [-32.1965, 140.5298],
  [-32.1853, 140.5911],
  [-32.1852, 140.5963],
  [-32.1835, 140.6051],
  [-32.1826, 140.6075],
  [-32.1764, 140.6226],
  [-32.1293, 140.7376],
  [-32.1286, 140.7401],
  [-32.1250, 140.7531],
  [-32.1233, 140.7573],
  [-32.0870, 140.9413],
  [-32.0819, 140.9669]
];

const cockburnOld = [
  [-32.0819, 140.9669],
  [-32.0776, 140.9884],
  [-32.0774, 140.9905],
  [-32.0774, 141.0014]
];

const cockburnNew = [
  [-32.0819, 140.9669],
  [-32.0816, 140.9706],
  [-32.0803, 141.0014]
];

export const crystalBrookBrokenHill: Line = {
  name: 'Crystal Brook - Broken Hill',
  state: 'SA',
  segments: [
    {
      segments: [{
          date: '1970-01-01',
          original: [crystalBrookGladstoneOldAllignment],
          deviation: [crystalBrookGladstoneNewAllignment]
        },
        crystalBrookGladstoneShared
      ],
      history: {
        opened: {
          date: '1875-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [{
          date: '1970-01-01',
          original: [gladstoneOld],
          deviation: [gladstoneNew]
        },
        jamestownShared,
        {
          date: '1970-01-01',
          original: [jamestownOld],
          deviation: [jamestownNew]
        },
        yongalaPeterborough,
        {
          date: '1970-01-01',
          original: [
            peterboroughDowdsHillTunnel,
            {
              name: 'Dowds Hill Tunnel',
              segment: dowdsHillTunnel,
              type: 'tunnel'
            },
            dowdsHillTunnelUcolta
          ],
          deviation: [peterboroughNew]
        },
        ucolta,
        {
          date: '1970-01-01',
          original: [ucoltaOld],
          deviation: [ucoltaNew]
        },
        nackara,
        {
          date: '1970-01-01',
          original: [cockburnOld],
          deviation: [cockburnNew]
        },
      ],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          gauge: 'standard'
        }]
      }
    }
  ]
};
