import { Line } from "../../trackTypes";

const goodwoodJunctionOaklands = [
  [-34.9543, 138.5864],
  [-34.9552, 138.5866],
  [-34.9562, 138.5864],
  [-34.9570, 138.5858],
  [-34.9678, 138.5721],
  [-34.9688, 138.5713],
  [-34.9698, 138.5710],
  [-34.9746, 138.5714],
  [-34.9759, 138.5711],
  [-34.9859, 138.5655],
  [-34.9872, 138.5646],
  [-34.9901, 138.5619],
  [-34.9960, 138.5565],
  [-34.9965, 138.5560],
  [-35.0031, 138.5474],
  [-35.0040, 138.5464],
  [-35.0093, 138.5408]
];

const oaklandsHalletCove = [
  [-35.0093, 138.5408],
  [-35.0100, 138.5400],
  [-35.0107, 138.5390],
  [-35.0110, 138.5377],
  [-35.0115, 138.5290],
  [-35.0123, 138.5233],
  [-35.0129, 138.5216],
  [-35.0139, 138.5203],
  [-35.0151, 138.5195],
  [-35.0170, 138.5189],
  [-35.0201, 138.5189],
  [-35.0226, 138.5196],
  [-35.0261, 138.5214],
  [-35.0271, 138.5217],
  [-35.0384, 138.5208],
  [-35.0390, 138.5206],
  [-35.0397, 138.5201],
  [-35.0467, 138.5120],
  [-35.0486, 138.5090],
  [-35.0496, 138.5079],
  [-35.0529, 138.5061],
  [-35.0540, 138.5059],
  [-35.0552, 138.5060],
  [-35.0595, 138.5070],
  [-35.0608, 138.5070],
  [-35.0661, 138.5056]
];

const halletCoveChristieDowns = [
  [-35.0661, 138.5056],
  [-35.0679, 138.5052],
  [-35.0690, 138.5052],
  [-35.0703, 138.5054],
  [-35.0716, 138.5055],
  [-35.0730, 138.5051],
  [-35.0794, 138.5020],
  [-35.0807, 138.5013],
  [-35.0817, 138.5005],
  [-35.0831, 138.4991],
  [-35.0844, 138.4983],
  [-35.0873, 138.4977],
  [-35.0885, 138.4972],
  [-35.0898, 138.4963],
  [-35.0960, 138.4900],
  [-35.0973, 138.4891],
  [-35.0985, 138.4888],
  [-35.1019, 138.4884],
  [-35.1034, 138.4885],
  [-35.1058, 138.4894],
  [-35.1106, 138.4925],
  [-35.1135, 138.4939],
  [-35.1229, 138.4962],
  [-35.1265, 138.4964],
  [-35.1281, 138.4964],
  [-35.1290, 138.4962]
];

const christieDownsNoralunga = [
  [-35.1290, 138.4962],
  [-35.1317, 138.4956],
  [-35.1403, 138.4923]
];

const noralungaSeaford = [
  [-35.1403, 138.4923],
  [-35.1445, 138.4907],
  [-35.1492, 138.4898],
  [-35.1629, 138.4888],
  [-35.1736, 138.4894],
  [-35.1765, 138.4887],
  [-35.1839, 138.4847],
  [-35.1865, 138.4838],
  [-35.1884, 138.4835],
  [-35.1903, 138.4834]
];

export const seaford: Line = {
  name: 'Seaford',
  state: 'SA',
  segments: [
    {
      segments: [noralungaSeaford],
      history: {
        opened: {
          date: '2014-02-23',
          tracks: 2,
          gauge: 'broad',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [christieDownsNoralunga],
      history: {
        opened: {
          date: '1978-04-02',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [halletCoveChristieDowns],
      history: {
        opened: {
          date: '1976-01-25',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [oaklandsHalletCove],
      history: {
        opened: {
          date: '1915-01-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1975-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [goodwoodJunctionOaklands],
      history: {
        opened: {
          date: '1915-01-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1953-01-01',
          tracks: 2
        }]
      }
    }
  ]
};
