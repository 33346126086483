import { Line } from "../../trackTypes";

const bunburyBoyanup = [
  [-33.3478, 115.7056],
  [-33.3488, 115.7047],
  [-33.3499, 115.7045],
  [-33.3512, 115.7050],
  [-33.3522, 115.7063],
  [-33.3544, 115.7155],
  [-33.3549, 115.7165],
  [-33.3557, 115.7174],
  [-33.3572, 115.7181],
  [-33.3669, 115.7249],
  [-33.3736, 115.7352],
  [-33.3749, 115.7366],
  [-33.3817, 115.7411],
  [-33.3943, 115.7519],
  [-33.3965, 115.7531],
  [-33.4021, 115.7546],
  [-33.4039, 115.7547],
  [-33.4067, 115.7541],
  [-33.4092, 115.7538],
  [-33.4277, 115.7546],
  [-33.4298, 115.7543],
  [-33.4749, 115.7370],
  [-33.4775, 115.7350],
  [-33.4796, 115.7312]
];

const pictonJunction = [
  [-33.3424, 115.7230],
  [-33.3428, 115.7252],
  [-33.3440, 115.7266],
  [-33.3455, 115.7269],
  [-33.3467, 115.7265],
  [-33.3476, 115.7256],
  [-33.3507, 115.7193],
  [-33.3520, 115.7180],
  [-33.3536, 115.7173],
  [-33.3557, 115.7174],
];

const boyanupDonnybrook = [
  [-33.4796, 115.7312],
  [-33.4818, 115.7277],
  [-33.4834, 115.7264],
  [-33.4849, 115.7261],
  [-33.4942, 115.7267],
  [-33.4965, 115.7275],
  [-33.5088, 115.7367],
  [-33.5108, 115.7393],
  [-33.5126, 115.7437],
  [-33.5134, 115.7451],
  [-33.5148, 115.7463],
  [-33.5220, 115.7494],
  [-33.5235, 115.7507],
  [-33.5260, 115.7539],
  [-33.5278, 115.7554],
  [-33.5335, 115.7586],
  [-33.5348, 115.7590],
  [-33.5364, 115.7586],
  [-33.5377, 115.7591],
  [-33.5387, 115.7602],
  [-33.5392, 115.7617],
  [-33.5393, 115.7636],
  [-33.5399, 115.7651],
  [-33.5409, 115.7659],
  [-33.5424, 115.7659],
  [-33.5439, 115.7661],
  [-33.5484, 115.7679],
  [-33.5494, 115.7682],
  [-33.5509, 115.7679],
  [-33.5525, 115.7685],
  [-33.5574, 115.7746],
  [-33.5619, 115.7844],
  [-33.5624, 115.7865],
  [-33.5629, 115.8075],
  [-33.5633, 115.8099],
  [-33.5642, 115.8122],
  [-33.5690, 115.8211],
  [-33.5697, 115.8219],
  [-33.5705, 115.8225],
  [-33.5770, 115.8253],
  [-33.5780, 115.8260],
  [-33.5791, 115.8274],
  [-33.5800, 115.8294],
  [-33.5803, 115.8305],
  [-33.5803, 115.8317],
  [-33.5799, 115.8340]
];

const donnybrookBridgetown = [
  [-33.5799, 115.8340],
  [-33.5795, 115.8359],
  [-33.5795, 115.8377],
  [-33.5799, 115.8403],
  [-33.5798, 115.8420],
  [-33.5800, 115.8436],
  [-33.5809, 115.8448],
  [-33.5830, 115.8463],
  [-33.5839, 115.8474],
  [-33.5881, 115.8577],
  [-33.5885, 115.8584],
  [-33.5985, 115.8688],
  [-33.6000, 115.8697],
  [-33.6053, 115.8719],
  [-33.6063, 115.8721],
  [-33.6094, 115.8716],
  [-33.6104, 115.8717],
  [-33.6137, 115.8724],
  [-33.6147, 115.8724],
  [-33.6157, 115.8718],
  [-33.6178, 115.8695],
  [-33.6188, 115.8690],
  [-33.6199, 115.8690],
  [-33.6217, 115.8697],
  [-33.6224, 115.8704],
  [-33.6227, 115.8712],
  [-33.6228, 115.8725],
  [-33.6233, 115.8734],
  [-33.6240, 115.8739],
  [-33.6249, 115.8741],
  [-33.6264, 115.8733],
  [-33.6276, 115.8730],
  [-33.6288, 115.8733],
  [-33.6326, 115.8763],
  [-33.6340, 115.8768],
  [-33.6355, 115.8770],
  [-33.6363, 115.8772],
  [-33.6382, 115.8783],
  [-33.6404, 115.8787],
  [-33.6465, 115.8782],
  [-33.6474, 115.8783],
  [-33.6493, 115.8791],
  [-33.6502, 115.8797],
  [-33.6512, 115.8812],
  [-33.6521, 115.8820],
  [-33.6531, 115.8821],
  [-33.6540, 115.8819],
  [-33.6547, 115.8819],
  [-33.6578, 115.8830],
  [-33.6591, 115.8829],
  [-33.6603, 115.8820],
  [-33.6621, 115.8791],
  [-33.6625, 115.8772],
  [-33.6631, 115.8762],
  [-33.6640, 115.8756],
  [-33.6651, 115.8757],
  [-33.6690, 115.8777],
  [-33.6701, 115.8785],
  [-33.6714, 115.8798],
  [-33.6721, 115.8809],
  [-33.6733, 115.8835],
  [-33.6744, 115.8848],
  [-33.6766, 115.8859],
  [-33.6778, 115.8870],
  [-33.6788, 115.8886],
  [-33.6797, 115.8899],
  [-33.6819, 115.8919],
  [-33.6837, 115.8928],
  [-33.6844, 115.8934],
  [-33.6855, 115.8938],
  [-33.6866, 115.8953],
  [-33.6875, 115.8953],
  [-33.6889, 115.8949],
  [-33.6897, 115.8948],
  [-33.6910, 115.8948],
  [-33.6923, 115.8943],
  [-33.6938, 115.8930],
  [-33.6952, 115.8926],
  [-33.6963, 115.8932],
  [-33.6976, 115.8948],
  [-33.6988, 115.8958],
  [-33.7002, 115.8961],
  [-33.7114, 115.8936],
  [-33.7128, 115.8936],
  [-33.7244, 115.8987],
  [-33.7259, 115.9000],
  [-33.7267, 115.9018],
  [-33.7273, 115.9027],
  [-33.7318, 115.9076],
  [-33.7325, 115.9093],
  [-33.7334, 115.9155],
  [-33.7353, 115.9218],
  [-33.7360, 115.9228],
  [-33.7372, 115.9234],
  [-33.7379, 115.9244],
  [-33.7381, 115.9256],
  [-33.7377, 115.9276],
  [-33.7376, 115.9298],
  [-33.7378, 115.9314],
  [-33.7401, 115.9366],
  [-33.7423, 115.9437],
  [-33.7429, 115.9447],
  [-33.7461, 115.9466],
  [-33.7467, 115.9468],
  [-33.7490, 115.9470],
  [-33.7502, 115.9476],
  [-33.7515, 115.9492],
  [-33.7526, 115.9499],
  [-33.7560, 115.9501],
  [-33.7573, 115.9508],
  [-33.7579, 115.9523],
  [-33.7578, 115.9538],
  [-33.7571, 115.9551],
  [-33.7569, 115.9564],
  [-33.7574, 115.9577],
  [-33.7619, 115.9623],
  [-33.7624, 115.9633],
  [-33.7623, 115.9659],
  [-33.7639, 115.9672],
  [-33.7668, 115.9705],
  [-33.7675, 115.9710],
  [-33.7690, 115.9715],
  [-33.7728, 115.9746],
  [-33.7736, 115.9760],
  [-33.7740, 115.9777],
  [-33.7748, 115.9787],
  [-33.7771, 115.9796],
  [-33.7779, 115.9795],
  [-33.7786, 115.9790],
  [-33.7791, 115.9783],
  [-33.7799, 115.9779],
  [-33.7830, 115.9778],
  [-33.7840, 115.9784],
  [-33.7846, 115.9795],
  [-33.7854, 115.9825],
  [-33.7861, 115.9836],
  [-33.7870, 115.9839],
  [-33.7897, 115.9837],
  [-33.7914, 115.9836],
  [-33.7938, 115.9838],
  [-33.7961, 115.9834],
  [-33.7967, 115.9835],
  [-33.7996, 115.9848],
  [-33.8007, 115.9849],
  [-33.8020, 115.9844],
  [-33.8033, 115.9830],
  [-33.8038, 115.9811],
  [-33.8048, 115.9804],
  [-33.8048, 115.9801],
  [-33.8058, 115.9803],
  [-33.8063, 115.9808],
  [-33.8066, 115.9813],
  [-33.8075, 115.9817],
  [-33.8084, 115.9816],
  [-33.8091, 115.9819],
  [-33.8097, 115.9825],
  [-33.8100, 115.9832],
  [-33.8107, 115.9837],
  [-33.8119, 115.9841],
  [-33.8127, 115.9845],
  [-33.8157, 115.9886],
  [-33.8159, 115.9892],
  [-33.8162, 115.9902],
  [-33.8167, 115.9907],
  [-33.8175, 115.9912],
  [-33.8180, 115.9921],
  [-33.8182, 115.9934],
  [-33.8178, 115.9953],
  [-33.8179, 115.9974],
  [-33.8190, 115.9996],
  [-33.8192, 116.0004],
  [-33.8194, 116.0019],
  [-33.8200, 116.0033],
  [-33.8212, 116.0050],
  [-33.8213, 116.0065],
  [-33.8206, 116.0075],
  [-33.8196, 116.0076],
  [-33.8183, 116.0070],
  [-33.8174, 116.0072],
  [-33.8167, 116.0080],
  [-33.8166, 116.0090],
  [-33.8171, 116.0107],
  [-33.8177, 116.0139],
  [-33.8174, 116.0176],
  [-33.8161, 116.0216],
  [-33.8134, 116.0261],
  [-33.8132, 116.0272],
  [-33.8134, 116.0283],
  [-33.8176, 116.0364],
  [-33.8204, 116.0473],
  [-33.8212, 116.0487],
  [-33.8260, 116.0526],
  [-33.8270, 116.0546],
  [-33.8278, 116.0603],
  [-33.8284, 116.0620],
  [-33.8314, 116.0662],
  [-33.8320, 116.0683],
  [-33.8320, 116.0732],
  [-33.8324, 116.0744],
  [-33.8331, 116.0750],
  [-33.8334, 116.0758],
  [-33.8335, 116.0771],
  [-33.8337, 116.0777],
  [-33.8347, 116.0792],
  [-33.8353, 116.0796],
  [-33.8364, 116.0800],
  [-33.8370, 116.0808],
  [-33.8371, 116.0817],
  [-33.8369, 116.0825],
  [-33.8361, 116.0837],
  [-33.8358, 116.0845],
  [-33.8356, 116.0875],
  [-33.8362, 116.0894],
  [-33.8404, 116.0943],
  [-33.8410, 116.0963],
  [-33.8408, 116.0983],
  [-33.8401, 116.0996],
  [-33.8399, 116.1004],
  [-33.8399, 116.1017],
  [-33.8393, 116.1031],
  [-33.8392, 116.1046],
  [-33.8397, 116.1075],
  [-33.8408, 116.1087],
  [-33.8419, 116.1088],
  [-33.8430, 116.1082],
  [-33.8441, 116.1083],
  [-33.8449, 116.1095],
  [-33.8448, 116.1107],
  [-33.8452, 116.1119],
  [-33.8465, 116.1129],
  [-33.8477, 116.1128],
  [-33.8485, 116.1120],
  [-33.8496, 116.1119],
  [-33.8504, 116.1126],
  [-33.8507, 116.1136],
  [-33.8507, 116.1148],
  [-33.8512, 116.1158],
  [-33.8533, 116.1172],
  [-33.8545, 116.1174],
  [-33.8576, 116.1177],
  [-33.8589, 116.1186],
  [-33.8627, 116.1245],
  [-33.8637, 116.1257],
  [-33.8644, 116.1264],
  [-33.8652, 116.1280],
  [-33.8652, 116.1299],
  [-33.8654, 116.1310],
  [-33.8659, 116.1318],
  [-33.8694, 116.1359],
  [-33.8701, 116.1365],
  [-33.8765, 116.1395],
  [-33.8796, 116.1425],
  [-33.8802, 116.1438],
  [-33.8801, 116.1455],
  [-33.8804, 116.1472],
  [-33.8821, 116.1500],
  [-33.8830, 116.1510],
  [-33.8870, 116.1529],
  [-33.8879, 116.1529],
  [-33.8893, 116.1525],
  [-33.8905, 116.1527],
  [-33.8917, 116.1537],
  [-33.8927, 116.1542],
  [-33.8951, 116.1542],
  [-33.8968, 116.1552],
  [-33.8982, 116.1563],
  [-33.9004, 116.1566],
  [-33.9023, 116.1582],
  [-33.9078, 116.1594],
  [-33.9114, 116.1607],
  [-33.9151, 116.1606],
  [-33.9169, 116.1611],
  [-33.9183, 116.1610],
  [-33.9256, 116.1576],
  [-33.9287, 116.1555],
  [-33.9307, 116.1547],
  [-33.9317, 116.1540],
  [-33.9369, 116.1471],
  [-33.9372, 116.1455],
  [-33.9366, 116.1440],
  [-33.9365, 116.1423],
  [-33.9362, 116.1412],
  [-33.9347, 116.1393],
  [-33.9342, 116.1376],
  [-33.9346, 116.1324],
  [-33.9354, 116.1313],
  [-33.9367, 116.1313],
  [-33.9391, 116.1337],
  [-33.9402, 116.1343],
  [-33.9445, 116.1347],
  [-33.9479, 116.1345],
  [-33.9533, 116.1361],
  [-33.9565, 116.1380],
  [-33.9576, 116.1383],
  [-33.9629, 116.1367]
];

const bridgetownJardee = [
  [-33.9629, 116.1367],
  [-33.9636, 116.1365],
  [-33.9645, 116.1360],
  [-33.9668, 116.1341],
  [-33.9673, 116.1336],
  [-33.9681, 116.1320],
  [-33.9686, 116.1296],
  [-33.9688, 116.1269],
  [-33.9692, 116.1258],
  [-33.9699, 116.1251],
  [-33.9709, 116.1248],
  [-33.9725, 116.1253],
  [-33.9731, 116.1253],
  [-33.9741, 116.1251],
  [-33.9752, 116.1253],
  [-33.9768, 116.1271],
  [-33.9788, 116.1309],
  [-33.9790, 116.1321],
  [-33.9784, 116.1337],
  [-33.9783, 116.1350],
  [-33.9785, 116.1356],
  [-33.9790, 116.1365],
  [-33.9802, 116.1371],
  [-33.9818, 116.1371],
  [-33.9850, 116.1356],
  [-33.9860, 116.1348],
  [-33.9869, 116.1346],
  [-33.9899, 116.1355],
  [-33.9916, 116.1366],
  [-33.9928, 116.1383],
  [-33.9930, 116.1399],
  [-33.9929, 116.1422],
  [-33.9932, 116.1440],
  [-33.9938, 116.1456],
  [-33.9940, 116.1465],
  [-33.9941, 116.1476],
  [-33.9947, 116.1488],
  [-33.9958, 116.1498],
  [-33.9964, 116.1505],
  [-33.9969, 116.1515],
  [-33.9978, 116.1523],
  [-33.9987, 116.1524],
  [-34.0002, 116.1519],
  [-34.0015, 116.1518],
  [-34.0039, 116.1524],
  [-34.0060, 116.1526],
  [-34.0102, 116.1518],
  [-34.0128, 116.1514],
  [-34.0147, 116.1507],
  [-34.0159, 116.1507],
  [-34.0171, 116.1512],
  [-34.0188, 116.1526],
  [-34.0197, 116.1530],
  [-34.0207, 116.1532],
  [-34.0216, 116.1538],
  [-34.0240, 116.1569],
  [-34.0257, 116.1583],
  [-34.0270, 116.1588],
  [-34.0277, 116.1594],
  [-34.0286, 116.1604],
  [-34.0295, 116.1608],
  [-34.0307, 116.1608],
  [-34.0314, 116.1609],
  [-34.0321, 116.1612],
  [-34.0329, 116.1612],
  [-34.0345, 116.1607],
  [-34.0365, 116.1606],
  [-34.0381, 116.1609],
  [-34.0389, 116.1608],
  [-34.0397, 116.1605],
  [-34.0408, 116.1604],
  [-34.0437, 116.1618],
  [-34.0446, 116.1625],
  [-34.0453, 116.1628],
  [-34.0479, 116.1633],
  [-34.0485, 116.1633],
  [-34.0499, 116.1630],
  [-34.0508, 116.1631],
  [-34.0518, 116.1640],
  [-34.0523, 116.1655],
  [-34.0530, 116.1664],
  [-34.0543, 116.1673],
  [-34.0564, 116.1681],
  [-34.0609, 116.1682],
  [-34.0622, 116.1687],
  [-34.0640, 116.1706],
  [-34.0653, 116.1711],
  [-34.0666, 116.1709],
  [-34.0684, 116.1696],
  [-34.0699, 116.1690],
  [-34.0756, 116.1690],
  [-34.0783, 116.1683],
  [-34.0896, 116.1683],
  [-34.0914, 116.1676],
  [-34.0984, 116.1624],
  [-34.0996, 116.1621],
  [-34.1259, 116.1666],
  [-34.1285, 116.1663],
  [-34.1309, 116.1655],
  [-34.1326, 116.1660],
  [-34.1350, 116.1685],
  [-34.1384, 116.1699],
  [-34.1399, 116.1699],
  [-34.1442, 116.1675],
  [-34.1452, 116.1672],
  [-34.1473, 116.1672],
  [-34.1488, 116.1664],
  [-34.1505, 116.1646],
  [-34.1529, 116.1638],
  [-34.1558, 116.1645],
  [-34.1579, 116.1664],
  [-34.1598, 116.1672],
  [-34.1612, 116.1666],
  [-34.1628, 116.1646],
  [-34.1641, 116.1637],
  [-34.1652, 116.1638],
  [-34.1660, 116.1643],
  [-34.1692, 116.1682],
  [-34.1706, 116.1693],
  [-34.1724, 116.1696],
  [-34.1760, 116.1687],
  [-34.1772, 116.1681],
  [-34.1869, 116.1595],
  [-34.1888, 116.1587],
  [-34.1936, 116.1590],
  [-34.1955, 116.1588],
  [-34.2101, 116.1536],
  [-34.2113, 116.1533],
  [-34.2157, 116.1532],
  [-34.2326, 116.1491],
  [-34.2376, 116.1487],
  [-34.2397, 116.1480],
  [-34.2438, 116.1455],
  [-34.2455, 116.1441],
  [-34.2493, 116.1428],
  [-34.2506, 116.1416],
  [-34.2523, 116.1373],
  [-34.2535, 116.1359],
  [-34.2600, 116.1317],
  [-34.2617, 116.1313],
  [-34.2651, 116.1312],
  [-34.2685, 116.1303],
  [-34.2729, 116.1285],
  [-34.2753, 116.1270],
  [-34.2771, 116.1263],
  [-34.2792, 116.1242]
];

const jardeePemberton = [
  [-34.2792, 116.1242],
  [-34.2805, 116.1233],
  [-34.2815, 116.1230],
  [-34.2825, 116.1233],
  [-34.2835, 116.1238],
  [-34.2841, 116.1239],
  [-34.2859, 116.1237],
  [-34.2868, 116.1230],
  [-34.2876, 116.1215],
  [-34.2881, 116.1211],
  [-34.2988, 116.1173],
  [-34.3010, 116.1174],
  [-34.3032, 116.1188],
  [-34.3044, 116.1189],
  [-34.3052, 116.1184],
  [-34.3090, 116.1146],
  [-34.3109, 116.1136],
  [-34.3131, 116.1137],
  [-34.3164, 116.1159],
  [-34.3174, 116.1176],
  [-34.3185, 116.1184],
  [-34.3197, 116.1184],
  [-34.3208, 116.1177],
  [-34.3217, 116.1162],
  [-34.3227, 116.1153],
  [-34.3265, 116.1141],
  [-34.3291, 116.1125],
  [-34.3306, 116.1120],
  [-34.3351, 116.1122],
  [-34.3365, 116.1113],
  [-34.3385, 116.1071],
  [-34.3384, 116.1059],
  [-34.3379, 116.1049],
  [-34.3367, 116.1037],
  [-34.3363, 116.1028],
  [-34.3365, 116.1015],
  [-34.3384, 116.0971],
  [-34.3391, 116.0963],
  [-34.3399, 116.0961],
  [-34.3414, 116.0964],
  [-34.3423, 116.0964],
  [-34.3445, 116.0955],
  [-34.3452, 116.0955],
  [-34.3459, 116.0960],
  [-34.3474, 116.0979],
  [-34.3482, 116.0984],
  [-34.3496, 116.0988],
  [-34.3504, 116.0997],
  [-34.3507, 116.1006],
  [-34.3508, 116.1026],
  [-34.3510, 116.1036],
  [-34.3515, 116.1042],
  [-34.3527, 116.1050],
  [-34.3532, 116.1059],
  [-34.3534, 116.1072],
  [-34.3539, 116.1084],
  [-34.3547, 116.1090],
  [-34.3572, 116.1094],
  [-34.3581, 116.1093],
  [-34.3607, 116.1075],
  [-34.3613, 116.1068],
  [-34.3620, 116.1054],
  [-34.3624, 116.1048],
  [-34.3629, 116.1046],
  [-34.3674, 116.1046],
  [-34.3725, 116.1067],
  [-34.3745, 116.1083],
  [-34.3755, 116.1086],
  [-34.3766, 116.1082],
  [-34.3780, 116.1067],
  [-34.3787, 116.1062],
  [-34.3808, 116.1054],
  [-34.3816, 116.1054],
  [-34.3835, 116.1059],
  [-34.3847, 116.1058],
  [-34.3856, 116.1062],
  [-34.3888, 116.1094],
  [-34.3901, 116.1096],
  [-34.3910, 116.1092],
  [-34.3915, 116.1084],
  [-34.3922, 116.1068],
  [-34.3928, 116.1060],
  [-34.3973, 116.1048],
  [-34.3984, 116.1049],
  [-34.4000, 116.1055],
  [-34.4013, 116.1053],
  [-34.4019, 116.1054],
  [-34.4032, 116.1060],
  [-34.4058, 116.1058],
  [-34.4072, 116.1063],
  [-34.4085, 116.1074],
  [-34.4098, 116.1077],
  [-34.4107, 116.1070],
  [-34.4119, 116.1043],
  [-34.4120, 116.1019],
  [-34.4126, 116.0996],
  [-34.4142, 116.0963],
  [-34.4147, 116.0946],
  [-34.4155, 116.0937],
  [-34.4165, 116.0936],
  [-34.4177, 116.0938],
  [-34.4186, 116.0937],
  [-34.4214, 116.0927],
  [-34.4222, 116.0910],
  [-34.4221, 116.0897],
  [-34.4213, 116.0885],
  [-34.4212, 116.0869],
  [-34.4215, 116.0857],
  [-34.4226, 116.0845],
  [-34.4228, 116.0829],
  [-34.4215, 116.0805],
  [-34.4214, 116.0792],
  [-34.4220, 116.0780],
  [-34.4233, 116.0770],
  [-34.4238, 116.0755],
  [-34.4236, 116.0737],
  [-34.4239, 116.0725],
  [-34.4271, 116.0672],
  [-34.4288, 116.0659],
  [-34.4298, 116.0643],
  [-34.4308, 116.0616],
  [-34.4352, 116.0557],
  [-34.4359, 116.0550],
  [-34.4370, 116.0541],
  [-34.4373, 116.0528],
  [-34.4371, 116.0516],
  [-34.4371, 116.0508],
  [-34.4377, 116.0487],
  [-34.4387, 116.0470],
  [-34.4398, 116.0459],
  [-34.4405, 116.0452],
  [-34.4409, 116.0441],
  [-34.4408, 116.0429],
  [-34.4407, 116.0416],
  [-34.4410, 116.0403],
  [-34.4419, 116.0387],
  [-34.4421, 116.0374],
  [-34.4422, 116.0352],
  [-34.4425, 116.0341],
  [-34.4432, 116.0328]
];

const pembertonWarrenBridge = [
  [-34.4432, 116.0328],
  [-34.4443, 116.0311],
  [-34.4449, 116.0304],
  [-34.4456, 116.0303],
  [-34.4464, 116.0307],
  [-34.4475, 116.0315],
  [-34.4483, 116.0318],
  [-34.4490, 116.0317],
  [-34.4497, 116.0313],
  [-34.4502, 116.0304],
  [-34.4505, 116.0288],
  [-34.4507, 116.0282],
  [-34.4516, 116.0269],
  [-34.4523, 116.0263],
  [-34.4532, 116.0261],
  [-34.4539, 116.0265],
  [-34.4544, 116.0272],
  [-34.4550, 116.0286],
  [-34.4560, 116.0297],
  [-34.4581, 116.0307],
  [-34.4590, 116.0314],
  [-34.4594, 116.0324],
  [-34.4598, 116.0340],
  [-34.4607, 116.0350],
  [-34.4619, 116.0353],
  [-34.4630, 116.0349],
  [-34.4643, 116.0336],
  [-34.4649, 116.0318],
  [-34.4655, 116.0309],
  [-34.4676, 116.0290],
  [-34.4691, 116.0289],
  [-34.4703, 116.0295],
  [-34.4711, 116.0301],
  [-34.4723, 116.0301],
  [-34.4734, 116.0298],
  [-34.4743, 116.0299],
  [-34.4754, 116.0304],
  [-34.4774, 116.0306],
  [-34.4779, 116.0308],
  [-34.4790, 116.0319],
  [-34.4793, 116.0329],
  [-34.4796, 116.0344],
  [-34.4806, 116.0358],
  [-34.4809, 116.0370],
  [-34.4807, 116.0378],
  [-34.4792, 116.0405],
  [-34.4786, 116.0417],
  [-34.4771, 116.0477],
  [-34.4771, 116.0490],
  [-34.4773, 116.0500],
  [-34.4793, 116.0528],
  [-34.4802, 116.0534],
  [-34.4818, 116.0537],
  [-34.4828, 116.0545],
  [-34.4841, 116.0564],
  [-34.4846, 116.0569],
  [-34.4854, 116.0571],
  [-34.4887, 116.0572],
  [-34.4897, 116.0567],
  [-34.4913, 116.0552],
  [-34.4927, 116.0544],
  [-34.4945, 116.0540],
  [-34.4962, 116.0542],
  [-34.4970, 116.0542],
  [-34.4977, 116.0538],
  [-34.4983, 116.0533]
];

const warrenBridgeNorthcliffe = [
  [-34.4983, 116.0533],
  [-34.4985, 116.0530],
  [-34.4995, 116.0499],
  [-34.5002, 116.0492],
  [-34.5013, 116.0490],
  [-34.5030, 116.0491],
  [-34.5040, 116.0486],
  [-34.5048, 116.0475],
  [-34.5062, 116.0459],
  [-34.5066, 116.0442],
  [-34.5063, 116.0424],
  [-34.5065, 116.0407],
  [-34.5075, 116.0379],
  [-34.5080, 116.0320],
  [-34.5078, 116.0270],
  [-34.5079, 116.0257],
  [-34.5089, 116.0243],
  [-34.5108, 116.0233],
  [-34.5148, 116.0230],
  [-34.5157, 116.0231],
  [-34.5169, 116.0238],
  [-34.5180, 116.0240],
  [-34.5211, 116.0241],
  [-34.5228, 116.0245],
  [-34.5240, 116.0255],
  [-34.5253, 116.0256],
  [-34.5269, 116.0244],
  [-34.5284, 116.0241],
  [-34.5302, 116.0246],
  [-34.5317, 116.0241],
  [-34.5327, 116.0229],
  [-34.5338, 116.0224],
  [-34.5355, 116.0227],
  [-34.5366, 116.0236],
  [-34.5378, 116.0256],
  [-34.5398, 116.0274],
  [-34.5402, 116.0293],
  [-34.5395, 116.0361],
  [-34.5400, 116.0381],
  [-34.5447, 116.0460],
  [-34.5451, 116.0476],
  [-34.5447, 116.0500],
  [-34.5449, 116.0516],
  [-34.5457, 116.0530],
  [-34.5475, 116.0541],
  [-34.5482, 116.0552],
  [-34.5485, 116.0572],
  [-34.5535, 116.0692],
  [-34.5538, 116.0712],
  [-34.5546, 116.0726],
  [-34.5560, 116.0735],
  [-34.5567, 116.0752],
  [-34.5561, 116.0783],
  [-34.5564, 116.0793],
  [-34.5571, 116.0804],
  [-34.5635, 116.0834],
  [-34.5658, 116.0831],
  [-34.5667, 116.0824],
  [-34.5673, 116.0808],
  [-34.5682, 116.0799],
  [-34.5702, 116.0794],
  [-34.5713, 116.0785],
  [-34.5723, 116.0783],
  [-34.5735, 116.0786],
  [-34.5744, 116.0784],
  [-34.5752, 116.0777],
  [-34.5764, 116.0750],
  [-34.5778, 116.0742],
  [-34.5788, 116.0735],
  [-34.5803, 116.0704],
  [-34.5803, 116.0691],
  [-34.5795, 116.0664],
  [-34.5795, 116.0647],
  [-34.5805, 116.0628],
  [-34.5838, 116.0610],
  [-34.5849, 116.0610],
  [-34.5858, 116.0616],
  [-34.5882, 116.0661],
  [-34.5890, 116.0692],
  [-34.5906, 116.0725],
  [-34.5907, 116.0747],
  [-34.5899, 116.0768],
  [-34.5898, 116.0793],
  [-34.5907, 116.0827],
  [-34.5965, 116.0916],
  [-34.5969, 116.0931],
  [-34.5968, 116.0945],
  [-34.5961, 116.0963],
  [-34.5961, 116.0977],
  [-34.5976, 116.1030],
  [-34.5987, 116.1047],
  [-34.6089, 116.1152],
  [-34.6102, 116.1171],
  [-34.6169, 116.1243],
  [-34.6187, 116.1251],
  [-34.6245, 116.1251],
  [-34.6268, 116.1243],
  [-34.6284, 116.1239],
  [-34.6328, 116.1246]
];

export const northcliffe: Line = {
  name: 'Northcliffe',
  state: 'WA',
  segments: [
    {
      segments: [bunburyBoyanup],
      history: {
        opened: {
          date: '1891-03-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pictonJunction],
      history: {
        opened: {
          date: '1891-03-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [boyanupDonnybrook],
      history: {
        opened: {
          date: '1893-11-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [donnybrookBridgetown],
      history: {
        opened: {
          date: '1898-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bridgetownJardee],
      history: {
        opened: {
          date: '1911-06-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [jardeePemberton],
      history: {
        opened: {
          date: '1914-01-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pembertonWarrenBridge],
      history: {
        opened: {
          date: '1933-11-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2023-02-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [warrenBridgeNorthcliffe],
      history: {
        opened: {
          date: '1933-11-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-01',
          status: 'closed'
        }]
      }
    }
  ]
};
