export const lightTheme = {
  background: '#FFFFFF',
  backgroundSecondary: '#cccaca',
  backgroundHover: 'rgba(156, 152, 152, 0.9)',
  textColour: '#000000',
  highlightText: '#0c035e',

  broadGauge: '#fa1f02',
  standardGauge: '#12018f',
  narrowGauge: '#008026',
  dualGauge: '#781ec7',
  parallelStandard: '#b010a8',
  directCurrent: '#080100',
  alternatingCurrent: '#543d02',
  basicTram: '#545453',
  cableTram: '#a09fa1',
  electrifiedTram: '#826f3d',
  hybridTram: '#6b6b6b'
};

export const darkTheme = {
  background: '#000000',
  backgroundSecondary: '#171717',
  backgroundHover: 'rgba(102, 102, 102, 0.125)',
  textColour: '#FFFFFF',
  highlightText: 'lightblue',

  broadGauge: '#ff1f0a',
  standardGauge: '#3e5bfa',
  narrowGauge: '#3fff0a',
  dualGauge: '#a228fa',
  parallelStandard: '#db58bf',
  directCurrent: '#f4fc03',
  alternatingCurrent: '#f5842f',
  basicTram: '#545453',
  cableTram: '#a09fa1',
  electrifiedTram: '#a5a683',
  hybridTram: '#f5f3f2'
};
