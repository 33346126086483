import { Line } from "../../../trackTypes";

const lappaJunctionOrd = [
  [-17.3594, 144.8858],
  [-17.3607, 144.8854],
  [-17.3614, 144.8854],
  [-17.3641, 144.8864],
  [-17.3647, 144.8868],
  [-17.3651, 144.8875],
  [-17.3653, 144.8888],
  [-17.3658, 144.8896],
  [-17.3674, 144.8906],
  [-17.3682, 144.8906],
  [-17.3692, 144.8903],
  [-17.3696, 144.8903],
  [-17.3705, 144.8906],
  [-17.3740, 144.8927],
  [-17.3747, 144.8927],
  [-17.3754, 144.8924],
  [-17.3758, 144.8923],
  [-17.3763, 144.8924],
  [-17.3767, 144.8928],
  [-17.3769, 144.8936],
  [-17.3772, 144.8941],
  [-17.3775, 144.8943],
  [-17.3789, 144.8944],
  [-17.3794, 144.8944],
  [-17.3800, 144.8941],
  [-17.3805, 144.8939],
  [-17.3815, 144.8941],
  [-17.3826, 144.8940],
  [-17.3833, 144.8942],
  [-17.3837, 144.8942],
  [-17.3845, 144.8940],
  [-17.3851, 144.8940],
  [-17.3859, 144.8944],
  [-17.3865, 144.8944],
  [-17.3872, 144.8941],
  [-17.3878, 144.8939],
  [-17.3883, 144.8940],
  [-17.3889, 144.8944],
  [-17.3893, 144.8951],
  [-17.3900, 144.8955],
  [-17.3905, 144.8954],
  [-17.3912, 144.8952],
  [-17.3919, 144.8952],
  [-17.3938, 144.8960],
  [-17.3944, 144.8967],
  [-17.3945, 144.8972],
  [-17.3953, 144.8979],
  [-17.3981, 144.8985],
  [-17.3991, 144.8991],
  [-17.4015, 144.9015],
  [-17.4020, 144.9024],
  [-17.4032, 144.9050],
  [-17.4039, 144.9057],
  [-17.4231, 144.9150],
  [-17.4239, 144.9153],
  [-17.4266, 144.9154],
  [-17.4290, 144.9162],
  [-17.4371, 144.9175],
  [-17.4414, 144.9190],
  [-17.4428, 144.9202],
  [-17.4437, 144.9216],
  [-17.4445, 144.9222],
  [-17.4456, 144.9226],
  [-17.4466, 144.9226],
  [-17.4620, 144.9187],
  [-17.4630, 144.9188],
  [-17.4659, 144.9200],
  [-17.4664, 144.9200],
  [-17.4684, 144.9199],
  [-17.4702, 144.9206],
  [-17.4722, 144.9220],
  [-17.4758, 144.9238],
  [-17.4771, 144.9247],
  [-17.4779, 144.9249],
  [-17.4788, 144.9250],
  [-17.4797, 144.9251],
  [-17.4820, 144.9263],
  [-17.4830, 144.9267],
  [-17.4847, 144.9271],
  [-17.4879, 144.9273],
  [-17.4925, 144.9290],
  [-17.4934, 144.9291],
  [-17.4942, 144.9288],
  [-17.4967, 144.9266],
  [-17.4973, 144.9262],
  [-17.4981, 144.9262],
  [-17.4997, 144.9267],
  [-17.5004, 144.9272],
  [-17.5007, 144.9273],
  [-17.5014, 144.9273],
  [-17.5023, 144.9277],
  [-17.5030, 144.9284],
  [-17.5033, 144.9285],
  [-17.5045, 144.9287],
  [-17.5051, 144.9287],
  [-17.5058, 144.9285],
  [-17.5064, 144.9284],
  [-17.5080, 144.9287],
  [-17.5090, 144.9290],
  [-17.5094, 144.9296],
  [-17.5102, 144.9311],
  [-17.5106, 144.9315],
  [-17.5112, 144.9315],
  [-17.5122, 144.9311],
  [-17.5130, 144.9311],
  [-17.5140, 144.9314],
  [-17.5147, 144.9319],
  [-17.5153, 144.9322],
  [-17.5173, 144.9325],
  [-17.5178, 144.9327],
  [-17.5194, 144.9340],
  [-17.5200, 144.9348],
  [-17.5203, 144.9352],
  [-17.5216, 144.9360],
  [-17.5235, 144.9365],
  [-17.5242, 144.9372],
  [-17.5261, 144.9409],
  [-17.5273, 144.9425],
  [-17.5309, 144.9453],
  [-17.5358, 144.9513],
  [-17.5367, 144.9518],
  [-17.5381, 144.9519],
  [-17.5387, 144.9523],
  [-17.5395, 144.9534],
  [-17.5396, 144.9539],
  [-17.5398, 144.9543],
  [-17.5405, 144.9552],
  [-17.5406, 144.9557],
  [-17.5405, 144.9563],
  [-17.5404, 144.9568],
  [-17.5405, 144.9572],
  [-17.5408, 144.9576],
  [-17.5417, 144.9580],
  [-17.5423, 144.9585],
  [-17.5427, 144.9594],
  [-17.5431, 144.9598],
  [-17.5443, 144.9604],
  [-17.5450, 144.9604],
  [-17.5472, 144.9598],
  [-17.5479, 144.9598],
  [-17.5524, 144.9610],
  [-17.5530, 144.9609],
  [-17.5557, 144.9603],
  [-17.5562, 144.9600],
  [-17.5566, 144.9596],
  [-17.5569, 144.9593],
  [-17.5581, 144.9590],
  [-17.5593, 144.9593],
  [-17.5628, 144.9595],
  [-17.5642, 144.9597],
  [-17.5657, 144.9606],
  [-17.5680, 144.9615]
];

const ordReturnCreekBridge = [
  [-17.5680, 144.9615],
  [-17.5700, 144.9618],
  [-17.5704, 144.9617],
  [-17.5723, 144.9610],
  [-17.5729, 144.9611],
  [-17.5736, 144.9616],
  [-17.5750, 144.9619],
  [-17.5761, 144.9618],
  [-17.5770, 144.9619],
  [-17.5774, 144.9620],
  [-17.5792, 144.9633],
  [-17.5818, 144.9642],
  [-17.5832, 144.9649],
  [-17.5861, 144.9653],
  [-17.5867, 144.9657],
  [-17.5878, 144.9672],
  [-17.5884, 144.9689],
  [-17.5889, 144.9693],
  [-17.5903, 144.9696],
  [-17.5907, 144.9700],
  [-17.5910, 144.9706],
  [-17.5914, 144.9714],
  [-17.5945, 144.9750],
  [-17.5957, 144.9760],
  [-17.5960, 144.9764],
  [-17.5961, 144.9771],
  [-17.5959, 144.9795],
  [-17.5960, 144.9800],
  [-17.5964, 144.9804],
  [-17.5974, 144.9807],
  [-17.5982, 144.9808],
  [-17.5986, 144.9809],
  [-17.5994, 144.9814],
  [-17.6003, 144.9818],
  [-17.6006, 144.9820],
  [-17.6012, 144.9828],
  [-17.6019, 144.9830],
  [-17.6024, 144.9828],
  [-17.6030, 144.9822],
  [-17.6037, 144.9818],
  [-17.6045, 144.9818],
  [-17.6050, 144.9821],
  [-17.6055, 144.9829],
  [-17.6059, 144.9832],
  [-17.6064, 144.9832],
  [-17.6074, 144.9828],
  [-17.6080, 144.9823],
  [-17.6083, 144.9822],
  [-17.6089, 144.9824],
  [-17.6096, 144.9825],
  [-17.6099, 144.9827],
  [-17.6106, 144.9828],
  [-17.6112, 144.9827],
  [-17.6115, 144.9827],
  [-17.6118, 144.9828],
  [-17.6122, 144.9832],
  [-17.6129, 144.9837],
  [-17.6135, 144.9846],
  [-17.6141, 144.9848],
  [-17.6153, 144.9847],
  [-17.6160, 144.9849],
  [-17.6170, 144.9856],
  [-17.6174, 144.9857],
  [-17.6180, 144.9857],
  [-17.6185, 144.9858],
  [-17.6188, 144.9861],
  [-17.6192, 144.9866],
  [-17.6197, 144.9869],
  [-17.6201, 144.9868],
  [-17.6205, 144.9865],
  [-17.6214, 144.9853],
  [-17.6216, 144.9850],
  [-17.6221, 144.9848],
  [-17.6224, 144.9848],
  [-17.6234, 144.9852],
  [-17.6237, 144.9854],
  [-17.6239, 144.9857],
  [-17.6244, 144.9862],
  [-17.6248, 144.9870],
  [-17.6251, 144.9875],
  [-17.6252, 144.9880],
  [-17.6250, 144.9886],
  [-17.6253, 144.9894],
  [-17.6258, 144.9896],
  [-17.6266, 144.9896],
  [-17.6274, 144.9892],
  [-17.6283, 144.9890],
  [-17.6298, 144.9882],
  [-17.6304, 144.9883],
  [-17.6325, 144.9900],
  [-17.6340, 144.9908],
  [-17.6345, 144.9917],
  [-17.6341, 144.9927],
  [-17.6326, 144.9942],
  [-17.6323, 144.9949],
  [-17.6322, 144.9958],
  [-17.6311, 144.9977],
  [-17.6310, 144.9992],
  [-17.6306, 144.9997],
  [-17.6296, 145.0003],
  [-17.6293, 145.0009],
  [-17.6294, 145.0015],
  [-17.6320, 145.0043],
  [-17.6323, 145.0054],
  [-17.6317, 145.0106],
  [-17.6320, 145.0125],
  [-17.6372, 145.0314],
  [-17.6372, 145.0320],
  [-17.6366, 145.0341],
  [-17.6365, 145.0354],
  [-17.6366, 145.0374],
  [-17.6376, 145.0405],
  [-17.6376, 145.0418],
  [-17.6370, 145.0457],
  [-17.6376, 145.0487],
  [-17.6387, 145.0502],
  [-17.6393, 145.0505],
  [-17.6405, 145.0507],
  [-17.6412, 145.0512],
  [-17.6416, 145.0519],
  [-17.6415, 145.0526],
  [-17.6410, 145.0540],
  [-17.6411, 145.0546],
  [-17.6416, 145.0550],
  [-17.6443, 145.0556],
  [-17.6449, 145.0560],
  [-17.6469, 145.0579],
  [-17.6484, 145.0586],
  [-17.6489, 145.0593],
  [-17.6498, 145.0613],
  [-17.6500, 145.0618],
  [-17.6510, 145.0624],
  [-17.6512, 145.0630],
  [-17.6511, 145.0638],
  [-17.6512, 145.0645],
  [-17.6520, 145.0668],
  [-17.6522, 145.0685],
  [-17.6520, 145.0708],
  [-17.6526, 145.0768],
  [-17.6544, 145.0807],
  [-17.6551, 145.0814],
  [-17.6579, 145.0827],
  [-17.6585, 145.0832],
  [-17.6588, 145.0840],
  [-17.6594, 145.0844],
  [-17.6601, 145.0842],
  [-17.6605, 145.0835],
  [-17.6605, 145.0828],
  [-17.6608, 145.0822],
  [-17.6614, 145.0819],
  [-17.6620, 145.0822],
  [-17.6632, 145.0837],
  [-17.6633, 145.0844],
  [-17.6630, 145.0854],
  [-17.6629, 145.0867],
  [-17.6631, 145.0873],
  [-17.6641, 145.0879],
  [-17.6649, 145.0891],
  [-17.6650, 145.0907],
  [-17.6643, 145.0928],
  [-17.6644, 145.0941],
  [-17.6649, 145.0951],
  [-17.6653, 145.0963],
  [-17.6664, 145.0972],
  [-17.6673, 145.0983],
  [-17.6693, 145.1028],
  [-17.6704, 145.1039],
  [-17.6733, 145.1055],
  [-17.6753, 145.1071],
  [-17.6762, 145.1080],
  [-17.6765, 145.1089]
];

const returnCreekBridgeMountGarnet = [
  [-17.6765, 145.1089],
  [-17.6766, 145.1097],
  [-17.6760, 145.1125]
];

export const mountGarnet: Line = {
  name: 'Mount Garnet',
  state: 'QLD',
  segments: [
    {
      segments: [lappaJunctionOrd],
      history: {
        opened: {
          date: '1901-11-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ordReturnCreekBridge],
      history: {
        opened: {
          date: '1902-04-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [returnCreekBridgeMountGarnet],
      history: {
        opened: {
          date: '1914-12-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-07-01',
          status: 'closed'
        }]
      }
    }
  ]
}
