import { Line } from "../../../../trackTypes";

const bridgeConnection = [
  [-37.81861, 144.96797],
  [-37.81865, 144.96812],
  [-37.81866, 144.96823],
  [-37.81862, 144.96867]
];

const batmanAve = [
  [-37.81862, 144.96867],
  [-37.81860, 144.97134],
  [-37.81871, 144.97222],
  [-37.81887, 144.97290],
  [-37.81912, 144.97361],
  [-37.81933, 144.97412],
  [-37.81980, 144.97487],
  [-37.82317, 144.97832],
  [-37.82326, 144.97848],
  [-37.82334, 144.97872],
  [-37.82438, 144.98694]
];

const jolimont = [
  [-37.81606, 144.97220],
  [-37.81608, 144.97234],
  [-37.81613, 144.97245],
  [-37.81636, 144.97260],
  [-37.81678, 144.97295],
  [-37.81715, 144.97340],
  [-37.81787, 144.97478],
  [-37.81823, 144.97533],
  [-37.81830, 144.97555],
  [-37.81824, 144.97637],
  [-37.81827, 144.97658],
  [-37.81884, 144.97755],
  [-37.81995, 144.97974],
  [-37.82039, 144.98044],
  [-37.82093, 144.98112],
  [-37.82191, 144.98248],
  [-37.82244, 144.98353],
  [-37.82276, 144.98430],
  [-37.82321, 144.98585],
  [-37.82351, 144.98671],
  [-37.82360, 144.98682],
  [-37.82368, 144.98685],
  [-37.82419, 144.98674],
  [-37.82430, 144.98679],
  [-37.82438, 144.98694]
];

const line = [
  [-37.82438, 144.98694],
  [-37.82565, 144.99770],
  [-37.82753, 145.01493],
  [-37.82753, 145.01566],
  [-37.82746, 145.01621],
  [-37.82652, 145.02001],
  [-37.82643, 145.02068],
  [-37.82644, 145.02108],
  [-37.82661, 145.02294],
  [-37.82667, 145.02334],
  [-37.82729, 145.02537]
];

export const east12: Line = {
  name: 'East12',
  state: 'VIC',
  segments: [
    {
      segments: [bridgeConnection],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1999-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        {
          date: '1999-01-01',
          original:[batmanAve],
          deviation: [jolimont]
        },
        line
      ],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
