import { Line } from "../../../trackTypes";

const gisborneKaitaratahi = [
  [-38.6623, 178.0009],
  [-38.6568, 177.9855],
  [-38.6560, 177.9838],
  [-38.6464, 177.9671],
  [-38.6458, 177.9663],
  [-38.6445, 177.9650],
  [-38.6384, 177.9597],
  [-38.6369, 177.9585],
  [-38.5791, 177.9268],
  [-38.5783, 177.9265],
  [-38.5662, 177.9251],
  [-38.5651, 177.9252],
  [-38.5603, 177.9256],
  [-38.5520, 177.9234],
  [-38.5430, 177.9175],
  [-38.5332, 177.9109],
  [-38.5326, 177.9100],
  [-38.5319, 177.9089],
  [-38.5304, 177.9046]
];

const kaitaratahiTunnel1 = [
  [-38.5304, 177.9046],
  [-38.5272, 177.8984],
  [-38.5259, 177.8970],
  [-38.5083, 177.8894],
  [-38.5069, 177.8893],
  [-38.5026, 177.8904],
  [-38.5009, 177.8902],
  [-38.4985, 177.8888],
  [-38.4978, 177.8877],
  [-38.4977, 177.8868],
  [-38.4976, 177.8853],
  [-38.4974, 177.8842],
  [-38.4970, 177.8837],
  [-38.4965, 177.8832]
];

const waipaoaTunnel = [
  [-38.4965, 177.8832],
  [-38.4949, 177.8823]
];

const tunnel1Tunnel2 = [
  [-38.4949, 177.8823],
  [-38.4942, 177.8820],
  [-38.4926, 177.8817],
  [-38.4753, 177.8816],
  [-38.4740, 177.8811],
  [-38.4730, 177.8802],
  [-38.4724, 177.8788],
  [-38.4715, 177.8750],
  [-38.4714, 177.8733],
  [-38.4716, 177.8718],
  [-38.4745, 177.8631],
  [-38.4752, 177.8617],
  [-38.4768, 177.8596],
  [-38.4771, 177.8591]
];

const karakaTunnel = [
  [-38.4771, 177.8591],
  [-38.4773, 177.8581],
  [-38.4772, 177.8569]
];

const tunnel2Tunnel3 = [
  [-38.4772, 177.8569],
  [-38.4788, 177.8481],
  [-38.4788, 177.8458],
  [-38.4754, 177.8294],
  [-38.4747, 177.8283],
  [-38.4733, 177.8277],
  [-38.4710, 177.8261],
  [-38.4695, 177.8246],
  [-38.4687, 177.8240],
  [-38.4681, 177.8232],
  [-38.4667, 177.8176],
  [-38.4661, 177.8162],
  [-38.4637, 177.8109],
  [-38.4634, 177.8099],
  [-38.4630, 177.8091],
  [-38.4617, 177.8076],
  [-38.4611, 177.8060],
  [-38.4607, 177.8042],
  [-38.4596, 177.8016],
  [-38.4592, 177.8002],
  [-38.4592, 177.7986],
  [-38.4589, 177.7974],
  [-38.4581, 177.7962],
  [-38.4576, 177.7947],
  [-38.4573, 177.7928],
  [-38.4550, 177.7843],
  [-38.4547, 177.7827],
  [-38.4550, 177.7812],
  [-38.4560, 177.7769],
  [-38.4560, 177.7756],
  [-38.4559, 177.7747],
  [-38.4551, 177.7731],
  [-38.4549, 177.7719],
  [-38.4550, 177.7677],
  [-38.4549, 177.7665],
  [-38.4540, 177.7630],
  [-38.4540, 177.7621],
  [-38.4542, 177.7609],
  [-38.4542, 177.7602],
  [-38.4540, 177.7594],
  [-38.4534, 177.7584],
  [-38.4532, 177.7576],
  [-38.4532, 177.7550],
  [-38.4530, 177.7523],
  [-38.4533, 177.7513],
  [-38.4559, 177.7476],
  [-38.4579, 177.7463],
  [-38.4594, 177.7461],
  [-38.4603, 177.7456],
  [-38.4609, 177.7449],
  [-38.4626, 177.7380],
  [-38.4628, 177.7355],
  [-38.4623, 177.7322],
  [-38.4623, 177.7317]
];

const waikohuTunnel = [
  [-38.4623, 177.7317],
  [-38.4623, 177.7312]
];

const tunnel3Tunnel4 = [
  [-38.4623, 177.7312],
  [-38.4625, 177.7283],
  [-38.4624, 177.7269],
  [-38.4619, 177.7251],
  [-38.4618, 177.7239],
  [-38.4621, 177.7228],
  [-38.4632, 177.7214],
  [-38.4636, 177.7206],
  [-38.4649, 177.7163],
  [-38.4655, 177.7149],
  [-38.4656, 177.7144],
  [-38.4657, 177.7139],
  [-38.4660, 177.7133],
  [-38.4666, 177.7128],
  [-38.4669, 177.7123],
  [-38.4688, 177.7080],
  [-38.4690, 177.7072],
  [-38.4690, 177.7065],
  [-38.4692, 177.7060],
  [-38.4697, 177.7051],
  [-38.4697, 177.7046],
  [-38.4686, 177.7008],
  [-38.4686, 177.6999],
  [-38.4690, 177.6987],
  [-38.4699, 177.6975],
  [-38.4708, 177.6950],
  [-38.4710, 177.6932],
  [-38.4714, 177.6919],
  [-38.4715, 177.6914],
  [-38.4715, 177.6910],
  [-38.4713, 177.6899],
  [-38.4710, 177.6864],
  [-38.4712, 177.6841],
  [-38.4711, 177.6835],
  [-38.4707, 177.6821],
  [-38.4706, 177.6813],
  [-38.4707, 177.6798],
  [-38.4705, 177.6786],
  [-38.4700, 177.6773],
  [-38.4697, 177.6763],
  [-38.4696, 177.6746],
  [-38.4694, 177.6740],
  [-38.4690, 177.6734],
  [-38.4682, 177.6729],
  [-38.4659, 177.6713],
  [-38.4653, 177.6701],
  [-38.4653, 177.6690],
  [-38.4656, 177.6682],
  [-38.4661, 177.6671],
  [-38.4663, 177.6660],
  [-38.4660, 177.6651],
  [-38.4651, 177.6641],
  [-38.4646, 177.6622],
  [-38.4639, 177.6610],
  [-38.4637, 177.6601],
  [-38.4638, 177.6592],
  [-38.4635, 177.6577],
  [-38.4618, 177.6540],
  [-38.4617, 177.6529],
  [-38.4623, 177.6501],
  [-38.4623, 177.6494],
  [-38.4611, 177.6464],
  [-38.4596, 177.6447],
  [-38.4571, 177.6429],
  [-38.4567, 177.6423],
  [-38.4566, 177.6417],
  [-38.4566, 177.6405],
  [-38.4563, 177.6397],
  [-38.4558, 177.6392],
  [-38.4548, 177.6388],
  [-38.4543, 177.6384],
  [-38.4540, 177.6379],
  [-38.4533, 177.6347],
  [-38.4533, 177.6338],
  [-38.4535, 177.6328],
  [-38.4535, 177.6317],
  [-38.4502, 177.6271],
  [-38.4485, 177.6253],
  [-38.4470, 177.6232],
  [-38.4455, 177.6219],
  [-38.4448, 177.6208],
  [-38.4445, 177.6195],
  [-38.4443, 177.6145],
  [-38.4440, 177.6130],
  [-38.4436, 177.6124],
  [-38.4435, 177.6118],
  [-38.4435, 177.6110],
  [-38.4433, 177.6104],
  [-38.4427, 177.6099],
  [-38.4424, 177.6091],
  [-38.4426, 177.6079],
  [-38.4426, 177.6071],
  [-38.4418, 177.6022],
  [-38.4418, 177.6016],
  [-38.4420, 177.6012],
  [-38.4419, 177.6007],
  [-38.4415, 177.6003],
  [-38.4414, 177.6000],
  [-38.4414, 177.5996],
  [-38.4416, 177.5992],
  [-38.4417, 177.5984],
  [-38.4415, 177.5972],
  [-38.4417, 177.5962],
  [-38.4417, 177.5956],
  [-38.4416, 177.5942],
  [-38.4412, 177.5931],
  [-38.4404, 177.5917],
  [-38.4401, 177.5908],
  [-38.4401, 177.5900],
  [-38.4404, 177.5882],
  [-38.4403, 177.5871],
  [-38.4393, 177.5845],
  [-38.4385, 177.5799],
  [-38.4381, 177.5791],
  [-38.4371, 177.5781],
  [-38.4343, 177.5738],
  [-38.4342, 177.5730],
  [-38.4343, 177.5724],
  [-38.4345, 177.5716],
  [-38.4345, 177.5706],
  [-38.4338, 177.5696],
  [-38.4317, 177.5684],
  [-38.4299, 177.5666],
  [-38.4292, 177.5656],
  [-38.4284, 177.5650],
  [-38.4272, 177.5647],
  [-38.4264, 177.5643],
  [-38.4254, 177.5633],
  [-38.4237, 177.5623],
  [-38.4228, 177.5623],
  [-38.4213, 177.5626],
  [-38.4201, 177.5624],
  [-38.4196, 177.5621],
  [-38.4190, 177.5613],
  [-38.4184, 177.5609],
  [-38.4157, 177.5610],
  [-38.4119, 177.5598],
  [-38.4104, 177.5599],
  [-38.4097, 177.5603],
  [-38.4089, 177.5612],
  [-38.4080, 177.5616],
  [-38.4072, 177.5614],
  [-38.4062, 177.5611],
  [-38.4007, 177.5613],
  [-38.4000, 177.5611],
  [-38.3989, 177.5606],
  [-38.3972, 177.5607],
  [-38.3961, 177.5613],
  [-38.3945, 177.5615],
  [-38.3930, 177.5607],
  [-38.3922, 177.5599],
  [-38.3914, 177.5582],
  [-38.3911, 177.5577],
  [-38.3886, 177.5555],
  [-38.3878, 177.5554],
  [-38.3872, 177.5558],
  [-38.3847, 177.5595],
  [-38.3839, 177.5600],
  [-38.3833, 177.5600],
  [-38.3822, 177.5594],
  [-38.3818, 177.5588],
  [-38.3816, 177.5582],
  [-38.3814, 177.5558],
  [-38.3808, 177.5514],
  [-38.3802, 177.5500],
  [-38.3791, 177.5487],
  [-38.3713, 177.5449],
  [-38.3704, 177.5439],
  [-38.3702, 177.5428],
  [-38.3706, 177.5392],
  [-38.3705, 177.5388]
];

const summitTunnel = [
  [-38.3705, 177.5388],
  [-38.3703, 177.5383],
  [-38.3700, 177.5381]
];

const tunnel4Moutohora = [
  [-38.3700, 177.5381],
  [-38.3681, 177.5377],
  [-38.3658, 177.5369],
  [-38.3600, 177.5335],
  [-38.3592, 177.5333],
  [-38.3542, 177.5332],
  [-38.3529, 177.5328],
  [-38.3502, 177.5312],
  [-38.3489, 177.5308],
  [-38.3480, 177.5310],
  [-38.3472, 177.5315],
  [-38.3467, 177.5319],
  [-38.3437, 177.5326],
  [-38.3423, 177.5325],
  [-38.3418, 177.5326],
  [-38.3409, 177.5329],
  [-38.3394, 177.5326],
  [-38.3384, 177.5330],
  [-38.3357, 177.5326],
  [-38.3332, 177.5328],
  [-38.3310, 177.5323],
  [-38.3304, 177.5320],
  [-38.3278, 177.5299],
  [-38.3271, 177.5296],
  [-38.3229, 177.5305],
  [-38.3217, 177.5310],
  [-38.3212, 177.5315],
  [-38.3205, 177.5317],
  [-38.3186, 177.5316],
  [-38.3180, 177.5314],
  [-38.3158, 177.5300],
  [-38.3145, 177.5285],
  [-38.3133, 177.5248],
  [-38.3126, 177.5237],
  [-38.3120, 177.5233],
  [-38.3107, 177.5232],
  [-38.3100, 177.5227],
  [-38.3084, 177.5205],
  [-38.3077, 177.5199],
  [-38.3048, 177.5197],
  [-38.3015, 177.5205],
  [-38.3003, 177.5210],
  [-38.2979, 177.5237],
  [-38.2952, 177.5245],
  [-38.2901, 177.5255],
  [-38.2721, 177.5350],
  [-38.2717, 177.5351],
  [-38.2715, 177.5351],
  [-38.2713, 177.5352],
  [-38.2711, 177.5357],
  [-38.2707, 177.5360],
  [-38.2608, 177.5416]
];

export const moutohora: Line = {
  name: 'Moutohora',
  state: 'NZ',
  segments: [
    {
      segments: [gisborneKaitaratahi],
      history: {
        opened: {
          date: '1902-11-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-03-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        kaitaratahiTunnel1,
        {
          name: 'Waipaoa Tunnel',
          segment: waipaoaTunnel,
          type: 'tunnel'
        },
        tunnel1Tunnel2,
        {
          name: 'Karaka Tunnel',
          segment: karakaTunnel,
          type: 'tunnel'
        },
        tunnel2Tunnel3,
        {
          name: 'Waikohu Tunnel',
          segment: waikohuTunnel,
          type: 'tunnel'
        },
        tunnel3Tunnel4,
        {
          name: 'Summit Tunnel',
          segment: summitTunnel,
          type: 'tunnel'
        },
        tunnel4Moutohora
      ],
      history: {
        opened: {
          date: '1917-11-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-03-14',
          status: 'closed'
        }]
      }
    }
  ]
}
