import { Line } from "../../../../trackTypes";

const cbd = [
  [-34.92576, 138.59973],
  [-34.92544, 138.60576],
  [-34.92516, 138.61153],
  [-34.92519, 138.61171],
  [-34.92535, 138.61184],
  [-34.92814, 138.61204]
];

const parksideJunction = [
  [-34.92805, 138.61184],
  [-34.92806, 138.61192],
  [-34.92810, 138.61200],
  [-34.92814, 138.61204]
];

const parkside = [
  [-34.92814, 138.61204],
  [-34.93833, 138.61278],
  [-34.93869, 138.61295],
  [-34.93896, 138.61315],
  [-34.94619, 138.62485],
  [-34.94629, 138.62496],
  [-34.94639, 138.62501],
  [-34.94650, 138.62508],
  [-34.94687, 138.62509]
];

const glenOsmond = [
  [-34.94629, 138.62496],
  [-34.95150, 138.63398],
  [-34.95178, 138.63440],
  [-34.95203, 138.63463],
  [-34.95681, 138.63860],
  [-34.95988, 138.64114]
];

const portrushRoad = [
  [-34.95988, 138.64114],
  [-34.96324, 138.64383]
];

const pulteneyStreetNorth = [
  [-34.92406, 138.60566],
  [-34.92544, 138.60576]
];

const pulteneyStreet = [
  [-34.92544, 138.60576],
  [-34.92697, 138.60588]
];

const cbdLoop = [
  [-34.92686, 138.60572],
  [-34.92653, 138.61190]
];

const fullarton = [
  [-34.94687, 138.62509],
  [-34.96435, 138.62643]
];

const springfield = [
  [-34.96435, 138.62643],
  [-34.97512, 138.62724]
];

export const south2: Line = {
  name: 'South2',
  state: 'SA',
  segments: [
    {
      segments: [cbd],
      history: {
        opened: {
          date: '1882-09-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parksideJunction],
      history: {
        opened: {
          date: '1909-11-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parkside],
      history: {
        opened: {
          date: '1882-09-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenOsmond],
      history: {
        opened: {
          date: '1884-01-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-10-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pulteneyStreetNorth],
      history: {
        opened: {
          date: '1909-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pulteneyStreet],
      history: {
        opened: {
          date: '1884-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-10-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portrushRoad],
      history: {
        opened: {
          date: '1884-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-10-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cbdLoop],
      history: {
        opened: {
          date: '1884-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fullarton],
      history: {
        opened: {
          date: '1914-06-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [springfield],
      history: {
        opened: {
          date: '1938-07-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    }
  ]
};
