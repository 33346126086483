import { Line } from "../../trackTypes";

const line = [
  [-37.7804, 144.9834],
  [-37.7808, 144.9842],
  [-37.7812, 144.9848],
  [-37.7818, 144.9854],
  [-37.7824, 144.9856],
  [-37.7828, 144.9856],
  [-37.7844, 144.9853],
  [-37.7851, 144.9850],
  [-37.7864, 144.9835],
  [-37.7869, 144.9831],
  [-37.7874, 144.9829],
  [-37.7900, 144.9825]
];

export const fitzroy: Line = {
  name: 'Fitzroy',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-07-31',
          status: 'closed'
        }]
      }
    }
  ]
};
