import { Line } from "../../trackTypes";

const cabooltureWamuran = [
  [-27.0800, 152.9523],
  [-27.0795, 152.9516],
  [-27.0726, 152.9326],
  [-27.0710, 152.9284],
  [-27.0619, 152.9102],
  [-27.0616, 152.9090],
  [-27.0615, 152.9067],
  [-27.0601, 152.9039],
  [-27.0573, 152.9019],
  [-27.0559, 152.8999],
  [-27.0526, 152.8971],
  [-27.0519, 152.8959],
  [-27.0508, 152.8925],
  [-27.0506, 152.8906],
  [-27.0466, 152.8831],
  [-27.0461, 152.8817],
  [-27.0459, 152.8750],
  [-27.0463, 152.8729],
  [-27.0461, 152.8715],
  [-27.0427, 152.8678],
  [-27.0402, 152.8665],
  [-27.0395, 152.8656],
  [-27.0392, 152.8647],
  [-27.0390, 152.8615]
];

const wamuranWoodford = [
  [-27.0390, 152.8615],
  [-27.0383, 152.8602],
  [-27.0371, 152.8593],
  [-27.0367, 152.8586],
  [-27.0364, 152.8577],
  [-27.0357, 152.8566],
  [-27.0311, 152.8539],
  [-27.0301, 152.8536],
  [-27.0281, 152.8533],
  [-27.0266, 152.8525],
  [-27.0255, 152.8514],
  [-27.0243, 152.8498],
  [-27.0232, 152.8488],
  [-27.0209, 152.8475],
  [-27.0200, 152.8468],
  [-27.0186, 152.8447],
  [-27.0168, 152.8431],
  [-27.0148, 152.8422],
  [-27.0118, 152.8415],
  [-27.0101, 152.8406],
  [-27.0081, 152.8387],
  [-27.0070, 152.8380],
  [-27.0044, 152.8378],
  [-27.0037, 152.8380],
  [-27.0032, 152.8387],
  [-27.0029, 152.8406],
  [-27.0022, 152.8424],
  [-26.9994, 152.8462],
  [-26.9981, 152.8472],
  [-26.9969, 152.8478],
  [-26.9948, 152.8473],
  [-26.9935, 152.8475],
  [-26.9919, 152.8461],
  [-26.9894, 152.8461],
  [-26.9877, 152.8469],
  [-26.9836, 152.8460],
  [-26.9821, 152.8452],
  [-26.9802, 152.8420],
  [-26.9800, 152.8409],
  [-26.9804, 152.8392],
  [-26.9802, 152.8370],
  [-26.9808, 152.8346],
  [-26.9821, 152.8331],
  [-26.9829, 152.8304],
  [-26.9827, 152.8290],
  [-26.9824, 152.8273],
  [-26.9827, 152.8263],
  [-26.9841, 152.8244],
  [-26.9851, 152.8237],
  [-26.9863, 152.8234],
  [-26.9868, 152.8226],
  [-26.9868, 152.8218],
  [-26.9867, 152.8155],
  [-26.9870, 152.8142],
  [-26.9879, 152.8128],
  [-26.9895, 152.8116],
  [-26.9904, 152.8103],
  [-26.9912, 152.8080],
  [-26.9913, 152.8063],
  [-26.9909, 152.8042],
  [-26.9904, 152.8034],
  [-26.9892, 152.8022],
  [-26.9887, 152.8015],
  [-26.9882, 152.7999],
  [-26.9852, 152.7963],
  [-26.9838, 152.7955],
  [-26.9812, 152.7951],
  [-26.9767, 152.7957],
  [-26.9754, 152.7954],
  [-26.9667, 152.7901],
  [-26.9599, 152.7848],
  [-26.9577, 152.7822],
  [-26.9572, 152.7816]
];

const woodfordKilcoy = [
  [-26.9572, 152.7816],
  [-26.9565, 152.7812],
  [-26.9508, 152.7791],
  [-26.9497, 152.7780],
  [-26.9495, 152.7771],
  [-26.9513, 152.7655],
  [-26.9518, 152.7641],
  [-26.9531, 152.7624],
  [-26.9538, 152.7610],
  [-26.9539, 152.7592],
  [-26.9525, 152.7528],
  [-26.9526, 152.7510],
  [-26.9534, 152.7481],
  [-26.9533, 152.7469],
  [-26.9527, 152.7458],
  [-26.9512, 152.7450],
  [-26.9507, 152.7445],
  [-26.9506, 152.7436],
  [-26.9509, 152.7424],
  [-26.9506, 152.7413],
  [-26.9498, 152.7403],
  [-26.9495, 152.7395],
  [-26.9499, 152.7359],
  [-26.9502, 152.7351],
  [-26.9511, 152.7343],
  [-26.9515, 152.7332],
  [-26.9511, 152.7312],
  [-26.9512, 152.7302],
  [-26.9521, 152.7295],
  [-26.9534, 152.7291],
  [-26.9550, 152.7296],
  [-26.9562, 152.7295],
  [-26.9571, 152.7288],
  [-26.9585, 152.7261],
  [-26.9590, 152.7220],
  [-26.9596, 152.7207],
  [-26.9609, 152.7195],
  [-26.9611, 152.7183],
  [-26.9592, 152.7134],
  [-26.9592, 152.7121],
  [-26.9607, 152.7095],
  [-26.9608, 152.7083],
  [-26.9606, 152.7062],
  [-26.9611, 152.7039],
  [-26.9609, 152.6932],
  [-26.9606, 152.6919],
  [-26.9598, 152.6904],
  [-26.9595, 152.6891],
  [-26.9599, 152.6871],
  [-26.9595, 152.6856],
  [-26.9566, 152.6829],
  [-26.9546, 152.6817],
  [-26.9535, 152.6805],
  [-26.9509, 152.6764],
  [-26.9506, 152.6753],
  [-26.9508, 152.6739],
  [-26.9507, 152.6715],
  [-26.9510, 152.6701],
  [-26.9519, 152.6683],
  [-26.9522, 152.6661],
  [-26.9516, 152.6630],
  [-26.9515, 152.6612],
  [-26.9524, 152.6506],
  [-26.9530, 152.6493],
  [-26.9541, 152.6485],
  [-26.9548, 152.6475],
  [-26.9567, 152.6412],
  [-26.9574, 152.6398],
  [-26.9605, 152.6359],
  [-26.9614, 152.6352],
  [-26.9639, 152.6343],
  [-26.9646, 152.6334],
  [-26.9645, 152.6325],
  [-26.9616, 152.6250],
  [-26.9615, 152.6237],
  [-26.9621, 152.6223],
  [-26.9639, 152.6196],
  [-26.9640, 152.6187],
  [-26.9635, 152.6152],
  [-26.9626, 152.6122],
  [-26.9620, 152.6113],
  [-26.9588, 152.6092],
  [-26.9577, 152.6089],
  [-26.9564, 152.6088],
  [-26.9551, 152.6078],
  [-26.9542, 152.6064],
  [-26.9531, 152.6052],
  [-26.9422, 152.6014],
  [-26.9412, 152.6007],
  [-26.9397, 152.5991],
  [-26.9331, 152.5863],
  [-26.9330, 152.5833],
  [-26.9338, 152.5800],
  [-26.9361, 152.5768],
  [-26.9409, 152.5732],
  [-26.9417, 152.5723],
  [-26.9428, 152.5713],
  [-26.9447, 152.5707],
  [-26.9457, 152.5699],
  [-26.9471, 152.5666],
  [-26.9475, 152.5624]
];

export const kilcoy: Line = {
  name: 'Kilcoy',
  state: 'QLD',
  segments: [
    {
      segments: [cabooltureWamuran],
      history: {
        opened: {
          date: '1909-12-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-09-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wamuranWoodford],
      history: {
        opened: {
          date: '1909-12-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [woodfordKilcoy],
      history: {
        opened: {
          date: '1913-12-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
