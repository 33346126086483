import { Line } from "../../trackTypes";

const line = [
  [-41.8892, 145.3439],
  [-41.8932, 145.3462],
  [-41.8947, 145.3463],
  [-41.9022, 145.3445],
  [-41.9078, 145.3448],
  [-41.9089, 145.3451],
  [-41.9107, 145.3460],
  [-41.9119, 145.3462],
  [-41.9157, 145.3455],
  [-41.9174, 145.3458],
  [-41.9237, 145.3509],
  [-41.9249, 145.3525],
  [-41.9284, 145.3609],
  [-41.9293, 145.3623],
  [-41.9372, 145.3688],
  [-41.9385, 145.3695],
  [-41.9425, 145.3705],
  [-41.9433, 145.3711],
  [-41.9440, 145.3719],
  [-41.9452, 145.3722],
  [-41.9461, 145.3714],
  [-41.9465, 145.3700],
  [-41.9464, 145.3672],
  [-41.9466, 145.3659],
  [-41.9474, 145.3646],
  [-41.9484, 145.3645],
  [-41.9499, 145.3659],
  [-41.9511, 145.3662],
  [-41.9538, 145.3655],
  [-41.9567, 145.3626],
  [-41.9571, 145.3620],
  [-41.9581, 145.3614],
  [-41.9586, 145.3603],
  [-41.9623, 145.3568],
  [-41.9626, 145.3560],
  [-41.9624, 145.3519],
  [-41.9630, 145.3470],
  [-41.9640, 145.3457],
  [-41.9653, 145.3456],
  [-41.9708, 145.3493],
  [-41.9714, 145.3505],
  [-41.9752, 145.3611],
  [-41.9759, 145.3621],
  [-41.9807, 145.3635],
  [-41.9874, 145.3638],
  [-41.9918, 145.3620],
  [-41.9927, 145.3610],
  [-41.9960, 145.3487],
  [-41.9964, 145.3466],
  [-41.9963, 145.3446],
  [-41.9956, 145.3427],
  [-41.9954, 145.3417],
  [-41.9947, 145.3234],
  [-41.9956, 145.3132],
  [-41.9954, 145.3118],
  [-41.9936, 145.3081],
  [-41.9926, 145.3046],
  [-41.9926, 145.3040],
  [-41.9927, 145.3035],
  [-41.9935, 145.3024],
  [-41.9936, 145.3017],
  [-41.9937, 145.3000],
  [-41.9948, 145.2956],
  [-41.9952, 145.2948],
  [-41.9970, 145.2927],
  [-41.9972, 145.2920],
  [-41.9970, 145.2912],
  [-41.9966, 145.2905],
  [-41.9964, 145.2899],
  [-41.9967, 145.2890],
  [-41.9977, 145.2872],
  [-41.9981, 145.2850],
  [-41.9973, 145.2821],
  [-41.9972, 145.2812],
  [-41.9979, 145.2787],
  [-41.9985, 145.2780],
  [-41.9998, 145.2778],
  [-42.0002, 145.2767],
  [-42.0005, 145.2728],
  [-42.0010, 145.2701],
  [-42.0014, 145.2695],
  [-42.0019, 145.2693],
  [-42.0023, 145.2694],
  [-42.0040, 145.2708],
  [-42.0047, 145.2711],
  [-42.0052, 145.2710],
  [-42.0056, 145.2710],
  [-42.0061, 145.2714],
  [-42.0065, 145.2715],
  [-42.0073, 145.2714],
  [-42.0079, 145.2716],
  [-42.0082, 145.2722],
  [-42.0083, 145.2741],
  [-42.0086, 145.2749],
  [-42.0091, 145.2752],
  [-42.0112, 145.2749],
  [-42.0117, 145.2752],
  [-42.0122, 145.2758],
  [-42.0125, 145.2760],
  [-42.0130, 145.2762],
  [-42.0133, 145.2760],
  [-42.0140, 145.2753],
  [-42.0155, 145.2743],
  [-42.0161, 145.2743],
  [-42.0166, 145.2748],
  [-42.0173, 145.2761],
  [-42.0180, 145.2764],
  [-42.0187, 145.2761],
  [-42.0191, 145.2754],
  [-42.0191, 145.2745],
  [-42.0184, 145.2727],
  [-42.0184, 145.2719],
  [-42.0187, 145.2710],
  [-42.0194, 145.2703],
  [-42.0261, 145.2651],
  [-42.0270, 145.2648],
  [-42.0462, 145.2716],
  [-42.0468, 145.2722],
  [-42.0474, 145.2735],
  [-42.0477, 145.2739],
  [-42.0485, 145.2746],
  [-42.0498, 145.2769],
  [-42.0503, 145.2774],
  [-42.0532, 145.2789],
  [-42.0540, 145.2796],
  [-42.0553, 145.2800],
  [-42.0572, 145.2816],
  [-42.0577, 145.2819],
  [-42.0630, 145.2829],
  [-42.0643, 145.2837],
  [-42.0650, 145.2839],
  [-42.0676, 145.2833],
  [-42.0682, 145.2832],
  [-42.0712, 145.2846],
  [-42.0723, 145.2846],
  [-42.0769, 145.2814],
  [-42.0776, 145.2802],
  [-42.0780, 145.2791],
  [-42.0784, 145.2752],
  [-42.0780, 145.2716],
  [-42.0782, 145.2696],
  [-42.0788, 145.2683],
  [-42.0802, 145.2675],
  [-42.0826, 145.2677],
  [-42.0839, 145.2687],
  [-42.0855, 145.2724],
  [-42.0865, 145.2739],
  [-42.0877, 145.2747],
  [-42.1016, 145.2804],
  [-42.1042, 145.2825],
  [-42.1070, 145.2841],
  [-42.1079, 145.2852],
  [-42.1100, 145.2895],
  [-42.1102, 145.2910],
  [-42.1099, 145.2958],
  [-42.1104, 145.3007],
  [-42.1118, 145.3036],
  [-42.1263, 145.3160],
  [-42.1288, 145.3174],
  [-42.1299, 145.3186],
  [-42.1308, 145.3206],
  [-42.1318, 145.3217],
  [-42.1327, 145.3219],
  [-42.1525, 145.3176],
  [-42.1531, 145.3178],
  [-42.1535, 145.3184],
  [-42.1544, 145.3210],
  [-42.1547, 145.3213],
  [-42.1551, 145.3215],
  [-42.1556, 145.3216],
  [-42.1563, 145.3222],
  [-42.1565, 145.3230],
  [-42.1563, 145.3240],
  [-42.1554, 145.3251],
  [-42.1549, 145.3263],
  [-42.1534, 145.3284]
];

export const strahnZeehan: Line = {
  name: 'Strahn - Zeehan',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1892-02-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-06-04',
          status: 'closed'
        }]
      }
    }
  ]
}
