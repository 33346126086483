import { Line } from "../../../trackTypes";

const beenleighErnestJunctionTunnel = [
  [-27.7838, 153.2619],
  [-27.7846, 153.2639],
  [-27.7853, 153.2652],
  [-27.7865, 153.2659],
  [-27.7913, 153.2660],
  [-27.7919, 153.2664],
  [-27.7921, 153.2670],
  [-27.7925, 153.2720],
  [-27.7929, 153.2727],
  [-27.7948, 153.2740],
  [-27.7954, 153.2747],
  [-27.7963, 153.2772],
  [-27.7966, 153.2781],
  [-27.7964, 153.2814],
  [-27.7964, 153.2825],
  [-27.7975, 153.2866],
  [-27.7980, 153.2873],
  [-27.7988, 153.2875],
  [-27.7998, 153.2872],
  [-27.8008, 153.2862],
  [-27.8016, 153.2859],
  [-27.8026, 153.2863],
  [-27.8037, 153.2875],
  [-27.8078, 153.2917],
  [-27.8093, 153.2923],
  [-27.8113, 153.2919],
  [-27.8135, 153.2918],
  [-27.8152, 153.2928],
  [-27.8182, 153.2977],
  [-27.8195, 153.2993],
  [-27.8317, 153.3068],
  [-27.8344, 153.3076],
  [-27.8430, 153.3081],
  [-27.8492, 153.3105],
  [-27.8518, 153.3105],
  [-27.8593, 153.3109],
  [-27.8630, 153.3115],
  [-27.8655, 153.3124],
  [-27.8682, 153.3135],
  [-27.8700, 153.3136],
  [-27.8744, 153.3125],
  [-27.8756, 153.3119],
  [-27.8762, 153.3120],
  [-27.8788, 153.3139],
  [-27.8814, 153.3153],
  [-27.8849, 153.3155],
  [-27.8870, 153.3162],
  [-27.8899, 153.3176],
  [-27.8918, 153.3192],
  [-27.8922, 153.3199],
  [-27.8923, 153.3205],
  [-27.8920, 153.3238],
  [-27.8922, 153.3246],
  [-27.8927, 153.3252],
  [-27.8977, 153.3285],
  [-27.8981, 153.3287],
  [-27.8986, 153.3286],
  [-27.9020, 153.3269],
  [-27.9026, 153.3269],
  [-27.9035, 153.3276],
  [-27.9058, 153.3291],
  [-27.9081, 153.3304],
  [-27.9104, 153.3308],
  [-27.9171, 153.3308],
  [-27.9184, 153.3314],
  [-27.9190, 153.3321],
  [-27.9198, 153.3337],
  [-27.9208, 153.3346],
  [-27.9243, 153.3357],
  [-27.9322, 153.3394],
  [-27.9375, 153.3408],
  [-27.9381, 153.3414],
  [-27.9395, 153.3439],
  [-27.9405, 153.3444],
  [-27.9424, 153.3444],
  [-27.9445, 153.3438],
  [-27.9456, 153.3443],
  [-27.9467, 153.3452],
  [-27.9475, 153.3459],
  [-27.9484, 153.3461],
  [-27.9555, 153.3463],
  [-27.9579, 153.3474],
  [-27.9592, 153.3491],
  [-27.9595, 153.3493],
  [-27.9614, 153.3498],
  [-27.9636, 153.3518],
  [-27.9640, 153.3521],
  [-27.9649, 153.3524],
  [-27.9654, 153.3527],
  [-27.9658, 153.3533],
  [-27.9658, 153.3543],
  [-27.9653, 153.3551],
  [-27.9652, 153.3555]
];

const ernestJunctionTunnel = [
  [-27.9652, 153.3555],
  [-27.9652, 153.3563]
];

const ernestJunctionTunnelSouthport = [
  [-27.9652, 153.3563],
  [-27.9653, 153.3571],
  [-27.9656, 153.3577],
  [-27.9660, 153.3580],
  [-27.9666, 153.3581],
  [-27.9682, 153.3578],
  [-27.9690, 153.3584],
  [-27.9698, 153.3594],
  [-27.9703, 153.3609],
  [-27.9706, 153.3620],
  [-27.9704, 153.3633],
  [-27.9676, 153.3691],
  [-27.9669, 153.3714],
  [-27.9651, 153.3792],
  [-27.9650, 153.3808],
  [-27.9653, 153.3851],
  [-27.9657, 153.3871],
  [-27.9656, 153.3885],
  [-27.9650, 153.3896],
  [-27.9642, 153.3911],
  [-27.9639, 153.3922],
  [-27.9640, 153.3934],
  [-27.9648, 153.3946],
  [-27.9667, 153.3967],
  [-27.9673, 153.3983],
  [-27.9680, 153.4051],
  [-27.9675, 153.4071],
  [-27.9666, 153.4080],
  [-27.9646, 153.4096],
  [-27.9639, 153.4104],
  [-27.9624, 153.4131]
];

export const southCoast: Line = {
  name: 'South Coast (QLD)',
  state: 'QLD',
  segments: [
    {
      segments: [
        beenleighErnestJunctionTunnel,
        {
          name: 'Ernest Junction Tunnel',
          segment: ernestJunctionTunnel,
          type: 'tunnel'
        },
        ernestJunctionTunnelSouthport
      ],
      history: {
        opened: {
          date: '1889-01-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
