import { Line } from "../../trackTypes";

const geraldtonMullewa = [
  [-28.8183, 114.6949],
  [-28.8187, 114.6964],
  [-28.8185, 114.6978],
  [-28.7938, 114.7491],
  [-28.7927, 114.7502],
  [-28.7914, 114.7508],
  [-28.7832, 114.7520],
  [-28.7815, 114.7530],
  [-28.7806, 114.7545],
  [-28.7802, 114.7563],
  [-28.7801, 114.7633],
  [-28.7793, 114.7664],
  [-28.7683, 114.7876],
  [-28.7652, 114.7964],
  [-28.7639, 114.7983],
  [-28.7585, 114.8026],
  [-28.7575, 114.8042],
  [-28.7572, 114.8056],
  [-28.7572, 114.8072],
  [-28.7567, 114.8087],
  [-28.7537, 114.8138],
  [-28.7475, 114.8321],
  [-28.7474, 114.8331],
  [-28.7474, 114.8345],
  [-28.7467, 114.8362],
  [-28.7453, 114.8371],
  [-28.7444, 114.8384],
  [-28.7444, 114.8399],
  [-28.7450, 114.8410],
  [-28.7460, 114.8419],
  [-28.7467, 114.8437],
  [-28.7477, 114.8448],
  [-28.7484, 114.8467],
  [-28.7478, 114.8487],
  [-28.7467, 114.8497],
  [-28.7453, 114.8499],
  [-28.7429, 114.8498],
  [-28.7413, 114.8503],
  [-28.7290, 114.8570],
  [-28.7271, 114.8587],
  [-28.7083, 114.8895],
  [-28.7049, 114.8991],
  [-28.7050, 114.9030],
  [-28.7125, 114.9168],
  [-28.7136, 114.9203],
  [-28.7151, 114.9345],
  [-28.7170, 114.9461],
  [-28.7171, 114.9480],
  [-28.7157, 114.9568],
  [-28.7148, 114.9588],
  [-28.7134, 114.9606],
  [-28.7127, 114.9626],
  [-28.7126, 114.9641],
  [-28.7134, 114.9686],
  [-28.7151, 114.9719],
  [-28.7153, 114.9731],
  [-28.7153, 114.9748],
  [-28.7158, 114.9763],
  [-28.7188, 114.9799],
  [-28.7194, 114.9813],
  [-28.7196, 114.9828],
  [-28.7191, 114.9855],
  [-28.7193, 114.9870],
  [-28.7199, 114.9880],
  [-28.7220, 114.9898],
  [-28.7228, 114.9915],
  [-28.7227, 114.9944],
  [-28.7230, 114.9957],
  [-28.7237, 114.9975],
  [-28.7238, 114.9988],
  [-28.7234, 115.0000],
  [-28.7216, 115.0029],
  [-28.7163, 115.0181],
  [-28.7150, 115.0193],
  [-28.7136, 115.0195],
  [-28.7092, 115.0188],
  [-28.7074, 115.0197],
  [-28.7066, 115.0212],
  [-28.7063, 115.0242],
  [-28.7054, 115.0258],
  [-28.7029, 115.0277],
  [-28.6993, 115.0327],
  [-28.6987, 115.0340],
  [-28.6911, 115.0536],
  [-28.6885, 115.0660],
  [-28.6888, 115.0685],
  [-28.6905, 115.0715],
  [-28.6913, 115.0748],
  [-28.6898, 115.1058],
  [-28.6850, 115.1290],
  [-28.6848, 115.1312],
  [-28.6887, 115.1621],
  [-28.6887, 115.1641],
  [-28.6857, 115.1869],
  [-28.6681, 115.2528],
  [-28.6681, 115.2552],
  [-28.6740, 115.2678],
  [-28.6744, 115.2699],
  [-28.6740, 115.2727],
  [-28.6740, 115.2740],
  [-28.6746, 115.2769],
  [-28.6739, 115.2799],
  [-28.6721, 115.2812],
  [-28.6704, 115.2813],
  [-28.6683, 115.2809],
  [-28.6668, 115.2810],
  [-28.6608, 115.2833],
  [-28.6597, 115.2843],
  [-28.6589, 115.2854],
  [-28.6546, 115.2972],
  [-28.5947, 115.4251],
  [-28.5801, 115.4483],
  [-28.5598, 115.4664],
  [-28.5584, 115.4684],
  [-28.5526, 115.4882],
  [-28.5519, 115.4944],
  [-28.5512, 115.4960],
  [-28.5466, 115.5028],
  [-28.5459, 115.5047],
  [-28.5455, 115.5074],
  [-28.5448, 115.5091],
  [-28.5410, 115.5143],
  [-28.5403, 115.5160],
  [-28.5401, 115.5168]
];

const mullewaPindar = [
  [-28.5401, 115.5168],
  [-28.5411, 115.5323],
  [-28.5401, 115.5409],
  [-28.5374, 115.5489],
  [-28.5293, 115.5606],
  [-28.5285, 115.5623],
  [-28.5284, 115.5642],
  [-28.5288, 115.5662],
  [-28.5285, 115.5681],
  [-28.5273, 115.5698],
  [-28.5251, 115.5711],
  [-28.5238, 115.5727],
  [-28.5199, 115.5822],
  [-28.5195, 115.5843],
  [-28.5196, 115.5959],
  [-28.5208, 115.5997],
  [-28.5210, 115.6022],
  [-28.5213, 115.6032],
  [-28.5220, 115.6041],
  [-28.5241, 115.6048],
  [-28.5256, 115.6063],
  [-28.5259, 115.6086],
  [-28.5232, 115.6135],
  [-28.5227, 115.6153],
  [-28.5221, 115.6223],
  [-28.4947, 115.7269],
  [-28.4887, 115.7379],
  [-28.4881, 115.7400],
  [-28.4877, 115.7437],
  [-28.4860, 115.7468],
  [-28.4854, 115.7486],
  [-28.4857, 115.7505],
  [-28.4895, 115.7577],
  [-28.4897, 115.7602],
  [-28.4888, 115.7625],
  [-28.4846, 115.7685],
  [-28.4766, 115.7945]
];

const pindarCue = [
  [-28.4766, 115.7945],
  [-28.4737, 115.8036],
  [-28.4731, 115.8061],
  [-28.4630, 115.9350],
  [-28.4343, 116.0378],
  [-28.4106, 116.2268],
  [-28.4082, 116.2327],
  [-28.4078, 116.2362],
  [-28.4094, 116.2465],
  [-28.4088, 116.2643],
  [-28.4092, 116.2670],
  [-28.4148, 116.2815],
  [-28.4165, 116.2921],
  [-28.4161, 116.3049],
  [-28.4168, 116.3113],
  [-28.4144, 116.3213],
  [-28.4060, 116.3335],
  [-28.4054, 116.3373],
  [-28.4061, 116.3439],
  [-28.4055, 116.3467],
  [-28.4034, 116.3494],
  [-28.3967, 116.3648],
  [-28.3947, 116.3811],
  [-28.3639, 116.4867],
  [-28.3453, 116.6725],
  [-28.3518, 116.7151],
  [-28.3510, 116.8218],
  [-28.3508, 116.8249],
  [-28.3335, 116.9302],
  [-28.2994, 117.1355],
  [-28.2996, 117.1563],
  [-28.2292, 117.6548],
  [-28.2274, 117.6592],
  [-28.1856, 117.7079],
  [-28.1476, 117.7387],
  [-28.1456, 117.7413],
  [-28.1308, 117.7706],
  [-28.1290, 117.7725],
  [-28.1157, 117.7796],
  [-28.1134, 117.7820],
  [-28.0685, 117.8438],
  [-28.0668, 117.8451],
  [-28.0548, 117.8467],
  [-28.0273, 117.8506],
  [-28.0201, 117.8491],
  [-28.0172, 117.8476],
  [-28.0129, 117.8444],
  [-28.0113, 117.8438],
  [-27.9960, 117.8407],
  [-27.9927, 117.8413],
  [-27.9911, 117.8411],
  [-27.9767, 117.8352],
  [-27.9743, 117.8349],
  [-27.9645, 117.8360],
  [-27.9550, 117.8396],
  [-27.9489, 117.8428],
  [-27.9466, 117.8432],
  [-27.9388, 117.8428],
  [-27.9261, 117.8447],
  [-27.9145, 117.8430],
  [-27.9120, 117.8435],
  [-27.8318, 117.8753],
  [-27.8244, 117.8758],
  [-27.8225, 117.8763],
  [-27.7985, 117.8873],
  [-27.7914, 117.8888],
  [-27.7697, 117.8872],
  [-27.7676, 117.8875],
  [-27.7455, 117.8947],
  [-27.7234, 117.8995],
  [-27.7185, 117.8985],
  [-27.6776, 117.8730],
  [-27.6705, 117.8707],
  [-27.6678, 117.8705],
  [-27.6417, 117.8710],
  [-27.6399, 117.8713],
  [-27.5788, 117.8908],
  [-27.5767, 117.8907],
  [-27.5392, 117.8820],
  [-27.4686, 117.8608],
  [-27.4664, 117.8609],
  [-27.4644, 117.8618],
  [-27.4528, 117.8706],
  [-27.4433, 117.8796],
  [-27.4307, 117.8943],
  [-27.4289, 117.8958],
  [-27.4243, 117.9018]
];

const cueNannine = [
  [-27.4243, 117.9018],
  [-27.4235, 117.9029],
  [-27.4211, 117.9055],
  [-27.4168, 117.9080],
  [-27.3757, 117.9230],
  [-27.2476, 117.9955],
  [-27.1943, 118.0254],
  [-27.1571, 118.0462],
  [-27.1293, 118.0738],
  [-27.1225, 118.0798],
  [-27.1214, 118.0812],
  [-27.1171, 118.0926],
  [-27.0990, 118.1269],
  [-27.0386, 118.1842],
  [-27.0314, 118.1902],
  [-27.0174, 118.2052],
  [-26.9650, 118.2416],
  [-26.9394, 118.2557],
  [-26.9163, 118.2643],
  [-26.9032, 118.2712],
  [-26.8885, 118.2843],
  [-26.8878, 118.2856],
  [-26.8874, 118.2874],
  [-26.8922, 118.3418],
  [-26.8919, 118.3443],
  [-26.8901, 118.3495]
];

const nannineMeekatharra = [
  [-26.8901, 118.3495],
  [-26.8868, 118.3525],
  [-26.8854, 118.3531],
  [-26.8747, 118.3539],
  [-26.8663, 118.3573],
  [-26.8219, 118.3708],
  [-26.6977, 118.4090],
  [-26.6798, 118.4207],
  [-26.6712, 118.4328],
  [-26.6677, 118.4361],
  [-26.6464, 118.4484],
  [-26.6266, 118.4656],
  [-26.6026, 118.4868],
  [-26.6011, 118.4893],
  [-26.6004, 118.4954],
  [-26.5997, 118.4971],
  [-26.5927, 118.5008],
  [-26.5914, 118.5019],
  [-26.5907, 118.5034]
];

export const mullewaMeekatherra: Line = {
  name: 'Mullewa - Meekatherra',
  state: 'WA',
  segments: [
    {
      segments: [geraldtonMullewa],
      history: {
        opened: {
          date: '1894-11-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mullewaPindar],
      history: {
        opened: {
          date: '1898-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pindarCue],
      history: {
        opened: {
          date: '1898-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-04-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cueNannine],
      history: {
        opened: {
          date: '1903-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-04-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nannineMeekatharra],
      history: {
        opened: {
          date: '1910-08-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-04-29',
          status: 'closed'
        }]
      }
    }
  ]
};
