import { Line } from "../../../trackTypes";

const coppabellaIngsdon = [
  [-21.9050, 148.3590],
  [-21.9088, 148.3548],
  [-21.9121, 148.3526],
  [-21.9976, 148.3189],
  [-22.0024, 148.3162],
  [-22.0034, 148.3154]
];

const ingsdonPeakDownsMine = [
  [-22.0034, 148.3154],
  [-22.0464, 148.2780],
  [-22.0515, 148.2753],
  [-22.0579, 148.2745],
  [-22.1033, 148.2820],
  [-22.1099, 148.2810],
  [-22.1157, 148.2776],
  [-22.1210, 148.2728],
  [-22.1248, 148.2701],
  [-22.2185, 148.2216],
  [-22.2541, 148.1874],
  [-22.2549, 148.1861],
  [-22.2550, 148.1848],
  [-22.2543, 148.1824],
  [-22.2532, 148.1785],
  [-22.2523, 148.1776],
  [-22.2511, 148.1775],
  [-22.2500, 148.1782],
  [-22.2497, 148.1795],
  [-22.2503, 148.1808],
  [-22.2512, 148.1813],
  [-22.2529, 148.1813],
  [-22.2537, 148.1817],
  [-22.2543, 148.1824]
];

const peakDownsMineSarajiCoalMine = [
  [-22.2541, 148.1874],
  [-22.2584, 148.1834],
  [-22.2595, 148.1814],
  [-22.2606, 148.1802],
  [-22.2622, 148.1796],
  [-22.2637, 148.1797],
  [-22.2657, 148.1808],
  [-22.2761, 148.1860],
  [-22.2783, 148.1880],
  [-22.2802, 148.1909],
  [-22.2817, 148.1925],
  [-22.2839, 148.1938],
  [-22.2864, 148.1942],
  [-22.2902, 148.1937],
  [-22.2915, 148.1937],
  [-22.3124, 148.1972],
  [-22.3155, 148.1982],
  [-22.3217, 148.2019],
  [-22.3250, 148.2053],
  [-22.3359, 148.2250],
  [-22.3382, 148.2279],
  [-22.3788, 148.2631],
  [-22.3936, 148.2716],
  [-22.3974, 148.2728],
  [-22.4039, 148.2733],
  [-22.4091, 148.2725],
  [-22.4160, 148.2703],
  [-22.4179, 148.2706],
  [-22.4191, 148.2722],
  [-22.4192, 148.2742],
  [-22.4182, 148.2755],
  [-22.4166, 148.2761],
  [-22.4086, 148.2759],
  [-22.4056, 148.2754],
  [-22.3974, 148.2728]
];

const sarajiCoalMineNorwichPark = [
  [-22.4160, 148.2703],
  [-22.4184, 148.2698],
  [-22.4397, 148.2719],
  [-22.4451, 148.2739],
  [-22.4580, 148.2847],
  [-22.4652, 148.2871],
  [-22.4716, 148.2867],
  [-22.4748, 148.2870],
  [-22.4920, 148.2925],
  [-22.4974, 148.2978],
  [-22.5022, 148.3088],
  [-22.5107, 148.3219],
  [-22.5139, 148.3247],
  [-22.5744, 148.3616],
  [-22.5771, 148.3625],
  [-22.6170, 148.3682],
  [-22.6199, 148.3696],
  [-22.6766, 148.4154],
  [-22.6820, 148.4218],
  [-22.6850, 148.4237],
  [-22.6886, 148.4245],
  [-22.6911, 148.4259],
  [-22.7069, 148.4419],
  [-22.7197, 148.4492],
  [-22.7229, 148.4505],
  [-22.7365, 148.4527],
  [-22.7522, 148.4553],
  [-22.7536, 148.4560],
  [-22.7543, 148.4573],
  [-22.7543, 148.4586],
  [-22.7525, 148.4623],
  [-22.7507, 148.4633],
  [-22.7487, 148.4625],
  [-22.7411, 148.4551],
  [-22.7365, 148.4527]
];

const norwichParkGregoryJunction = [
  [-22.7522, 148.4553],
  [-22.7550, 148.4550],
  [-22.7623, 148.4526],
  [-22.7662, 148.4524],
  [-22.7704, 148.4537],
  [-22.8006, 148.4737],
  [-22.8047, 148.4780],
  [-22.8107, 148.4911],
  [-22.8138, 148.4950],
  [-22.8250, 148.5010],
  [-22.8771, 148.5221],
  [-22.8850, 148.5263],
  [-22.9045, 148.5460],
  [-22.9100, 148.5489],
  [-22.9343, 148.5493],
  [-22.9392, 148.5480],
  [-22.9455, 148.5435],
  [-22.9491, 148.5421],
  [-22.9535, 148.5414],
  [-22.9786, 148.5298],
  [-23.0237, 148.4933],
  [-23.0278, 148.4885],
  [-23.0309, 148.4860],
  [-23.0375, 148.4825],
  [-23.0493, 148.4725],
  [-23.0587, 148.4578],
  [-23.0652, 148.4530],
  [-23.0709, 148.4524],
  [-23.0766, 148.4500],
  [-23.0812, 148.4456],
  [-23.0857, 148.4430],
  [-23.1028, 148.4363],
  [-23.1309, 148.4319],
  [-23.1345, 148.4302],
  [-23.1371, 148.4275],
  [-23.1396, 148.4236],
  [-23.1437, 148.4201],
  [-23.1607, 148.4132],
  [-23.1713, 148.4060],
  [-23.1745, 148.4048],
  [-23.1778, 148.4046]
];

export const blackwaterGoonyella: Line = {
  name: 'Blackwater - Goonyella',
  state: 'QLD',
  segments: [
    {
      segments: [coppabellaIngsdon],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-29',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2010-07-24',
          tracks: 2
        }]
      }
    },
    {
      segments: [ingsdonPeakDownsMine],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-29',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [peakDownsMineSarajiCoalMine],
      history: {
        opened: {
          date: '1974-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-06-04',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [sarajiCoalMineNorwichPark],
      history: {
        opened: {
          date: '1979-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-06-04',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [norwichParkGregoryJunction],
      history: {
        opened: {
          date: '1980-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-06-04',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
