import { Line } from "../../trackTypes";

const ballaratCattleYards = [
  [-37.5394, 143.8176],
  [-37.5396, 143.8158],
  [-37.5408, 143.8130],
  [-37.5422, 143.8111],
  [-37.5428, 143.8105],
  [-37.5438, 143.8100],
  [-37.5641, 143.8064],
  [-37.5655, 143.8065],
  [-37.5668, 143.8073],
  [-37.5678, 143.8089],
  [-37.5682, 143.8108],
  [-37.5692, 143.8198]
];

const bendigoCattleYards = [
  [-36.7474, 144.2948],
  [-36.7484, 144.2944],
  [-36.7489, 144.2944],
  [-36.7500, 144.2946],
  [-36.7508, 144.2950],
  [-36.7516, 144.2957],
  [-36.7522, 144.2966]
];

const bungareeRacecourse = [
  [-37.5572, 144.0048],
  [-37.5550, 144.0050],
  [-37.5539, 144.0048],
  [-37.5527, 144.0041],
  [-37.5510, 144.0031],
  [-37.5491, 144.0011],
  [-37.5481, 143.9998],
  [-37.5448, 143.9951],
  [-37.5440, 143.9944],
  [-37.5429, 143.9938],
  [-37.5419, 143.9939],
  [-37.5390, 143.9944],
  [-37.5344, 143.9956]
];

const warrnamboolBreakwater = [
  [-38.3855, 142.4760],
  [-38.3854, 142.4750],
  [-38.3856, 142.4744],
  [-38.3860, 142.4737],
  [-38.3867, 142.4733],
  [-38.3916, 142.4727],
  [-38.3926, 142.4728],
  [-38.3960, 142.4720],
  [-38.3998, 142.4724],
  [-38.4004, 142.4726],
  [-38.4024, 142.4751],
  [-38.4026, 142.4758],
  [-38.4025, 142.4763],
  [-38.4014, 142.4804]
];

const phoenixFoundry = [
  [-37.5559, 143.8564],
  [-37.5573, 143.8569],
  [-37.5577, 143.8569],
  [-37.5620, 143.8561]
];

export const westernFreight: Line = {
  name: 'Western Freight',
  state: 'VIC',
  segments: [
    {
      segments: [ballaratCattleYards],
      history: {
        opened: {
          date: '1886-11-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1997-11-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bendigoCattleYards],
      history: {
        opened: {
          date: '1894-05-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bungareeRacecourse],
      history: {
        opened: {
          date: '1900-12-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-09-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [warrnamboolBreakwater],
      history: {
        opened: {
          date: '1890-02-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1944-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [phoenixFoundry],
      history: {
        opened: {
          date: '1883-04-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1906-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
