import { west1 } from "./brisbane/west1";
import { west2 } from "./brisbane/west2";
import { south1 } from "./brisbane/south1";
import { east1 } from "./brisbane/east1";
import { east2 } from "./brisbane/east2";
import { south2 } from "./brisbane/south2";
import { east3 } from "./brisbane/east3";
import { west3 } from "./brisbane/west3";
import { west4 } from "./brisbane/west4";
import { east4 } from "./brisbane/east4";
import { south3 } from "./brisbane/south3";
import { south4 } from "./brisbane/south4";
import { west5 } from "./brisbane/west5";
import { east5 } from "./brisbane/east5";
import { south5 } from "./brisbane/south5";
import { south6 } from "./brisbane/south6";
import { south7 } from "./brisbane/south7";
import { east6 } from "./brisbane/east6";
import { east7 } from "./brisbane/east7";
import { east8 } from "./brisbane/east8";

export const brisbaneTrams = [
  south1,
  west1,
  east1,
  west2,
  east2,
  south2,
  east3,
  west3,
  west4,
  east4,
  south3,
  south4,
  west5,
  east5,
  south5,
  south6,
  south7,
  east6,
  east7,
  east8
];
