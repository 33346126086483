import { Line } from "../../../../trackTypes";

const connector = [
  [-37.86088, 145.01516],
  [-37.86090, 145.01533],
  [-37.86089, 145.01542],
  [-37.86077, 145.01574],
  [-37.86076, 145.01600],
  [-37.86238, 145.02853]
];

const northSouth = [
  [-37.80768, 145.03797],
  [-37.80776, 145.03809],
  [-37.80788, 145.03812],
  [-37.82890, 145.03419],
  [-37.83997, 145.03223],
  [-37.84392, 145.03151],
  [-37.84645, 145.03104],
  [-37.85178, 145.03002],
  [-37.85661, 145.02929]
];

const eastMalvern = [
  [-37.85661, 145.02929],
  [-37.85677, 145.02926],
  [-37.86220, 145.02837],
  [-37.86233, 145.02842],
  [-37.86238, 145.02853],
  [-37.86508, 145.05007]
];

const glenferrieRoadJunction = [
  [-37.82870, 145.03401],
  [-37.82878, 145.03414],
  [-37.82890, 145.03419]
];

const malvernDepot = [
  [-37.85896, 145.02890],
  [-37.85887, 145.02886],
  [-37.85882, 145.02880],
  [-37.85877, 145.02869],
  [-37.85854, 145.02692]
];

const malvernDepotJunction = [
  [-37.85863, 145.02895],
  [-37.85872, 145.02888],
  [-37.85876, 145.02880],
  [-37.85877, 145.02869]
];

export const east5: Line = {
  name: 'East5',
  state: 'VIC',
  segments: [
    {
      segments: [connector],
      history: {
        opened: {
          date: '1928-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [northSouth],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [eastMalvern],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [glenferrieRoadJunction],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [malvernDepot, malvernDepotJunction],
      history: {
        opened: {
          date: '1910-05-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
