import { Line } from "../../../trackTypes";

const line = [
  [-34.04962, 137.56548],
  [-34.05406, 137.57323],
  [-34.05422, 137.57347],
  [-34.05440, 137.57369],
  [-34.05543, 137.57467],
  [-34.05556, 137.57484],
  [-34.05573, 137.57515],
  [-34.06001, 137.58460],
  [-34.06038, 137.58510],
  [-34.06060, 137.58535],
  [-34.06087, 137.58554],
  [-34.06132, 137.58568],
  [-34.06315, 137.58584],
  [-34.06338, 137.58592],
  [-34.06356, 137.58609],
  [-34.06995, 137.59331],
  [-34.07004, 137.59365],
  [-34.07027, 137.59415],
  [-34.07162, 137.59718],
  [-34.07487, 137.60460],
  [-34.07514, 137.60506],
  [-34.07673, 137.60698],
  [-34.07699, 137.60729],
  [-34.07727, 137.60771],
  [-34.07808, 137.60914],
  [-34.07824, 137.60957],
  [-34.07833, 137.60991],
  [-34.07869, 137.61115],
  [-34.07888, 137.61179],
  [-34.07919, 137.61255],
  [-34.07952, 137.61320],
  [-34.08014, 137.61410],
  [-34.08037, 137.61460],
  [-34.08048, 137.61495],
  [-34.08064, 137.61577]
];

const branch = [
  [-34.07027, 137.59415],
  [-34.07050, 137.59440],
  [-34.07182, 137.59529],
  [-34.07249, 137.59572],
  [-34.07291, 137.59595],
  [-34.07375, 137.59629],
  [-34.07523, 137.59688],
  [-34.07544, 137.59693],
  [-34.07569, 137.59692],
  [-34.07595, 137.59684],
  [-34.07667, 137.59647],
  [-34.07677, 137.59645],
  [-34.07687, 137.59648],
  [-34.07695, 137.59655],
  [-34.07728, 137.59713],
  [-34.07733, 137.59721],
  [-34.07741, 137.59728],
  [-34.07816, 137.59764],
  [-34.07837, 137.59780],
  [-34.07983, 137.59950],
  [-34.08003, 137.59977],
  [-34.08019, 137.60005],
  [-34.08078, 137.60134],
  [-34.08093, 137.60171],
  [-34.08107, 137.60223],
  [-34.08118, 137.60288],
  [-34.08124, 137.60309],
  [-34.08132, 137.60330],
  [-34.08219, 137.60489]
];

export const moontaTrams: Line = {
  name: 'Moonta Trams',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1896-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1931-04-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [branch],
      history: {
        opened: {
          date: '1896-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1931-04-31',
          status: 'closed'
        }]
      }
    }
  ]
};
