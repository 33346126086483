import { Line } from "../../../trackTypes";

const yeelannaKapinnie = [
  [-34.1421, 135.7286],
  [-34.1438, 135.7280],
  [-34.1452, 135.7264],
  [-34.1458, 135.7252],
  [-34.1476, 135.7201],
  [-34.1478, 135.7180],
  [-34.1423, 135.6903],
  [-34.1421, 135.6882],
  [-34.1445, 135.6596],
  [-34.1591, 135.5820],
  [-34.1599, 135.5699],
  [-34.1588, 135.5634],
  [-34.1587, 135.5618],
  [-34.1590, 135.5607],
  [-34.1621, 135.5526],
  [-34.1640, 135.5449],
  [-34.1640, 135.5420],
  [-34.1608, 135.5316],
  [-34.1392, 135.4991],
  [-34.1338, 135.4948]
];

const kapinnieMountHope = [
  [-34.1338, 135.4948],
  [-34.1300, 135.4919],
  [-34.1254, 135.4895],
  [-34.1241, 135.4881],
  [-34.1222, 135.4845],
  [-34.1218, 135.4828],
  [-34.1219, 135.4813],
  [-34.1236, 135.4771],
  [-34.1238, 135.4753],
  [-34.1233, 135.4735],
  [-34.1197, 135.4667],
  [-34.1192, 135.4643],
  [-34.1191, 135.4621],
  [-34.1200, 135.4577],
  [-34.1200, 135.4538],
  [-34.1144, 135.4296],
  [-34.1146, 135.3635],
  [-34.1144, 135.3620],
  [-34.1138, 135.3603],
  [-34.1115, 135.3570]
];

export const mountHope: Line = {
  name: 'MountHope',
  state: 'SA',
  segments: [
    {
      segments: [yeelannaKapinnie],
      history: {
        opened: {
          date: '1914-10-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-10-01',
          status: 'closed'

        }]
      }
    },
    {
      segments: [kapinnieMountHope],
      history: {
        opened: {
          date: '1914-10-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1965-07-12',
          status: 'closed'
        }]
      }
    }
  ]
};
