import { Line } from "../../trackTypes";

const tennysonStreetStation = [
  [-21.1445, 149.1919],
  [-21.1510, 149.1867],
  [-21.1514, 149.1855],
  [-21.1505, 149.1785],
  [-21.1506, 149.1777],
  [-21.1512, 149.1768],
  [-21.1543, 149.1737]
];

const boddingtonStreetStation = [
  [-21.1376, 149.1436],
  [-21.1405, 149.1506],
  [-21.1451, 149.1573],
  [-21.1457, 149.1594],
  [-21.1492, 149.1856],
  [-21.1496, 149.1861],
  [-21.1503, 149.1865],
  [-21.1509, 149.1864],
  [-21.1516, 149.1863],
  [-21.1546, 149.1831],
  [-21.1549, 149.1818],
  [-21.1540, 149.1757],
  [-21.1541, 149.1743],
  [-21.1543, 149.1737]
];

const pagetJunctionnewburyJunction = [
  [-21.1669, 149.1567],
  [-21.1646, 149.1389],
  [-21.1647, 149.1372],
  [-21.1661, 149.1285],
  [-21.1661, 149.1271],
  [-21.1537, 149.0239],
  [-21.1517, 149.0195],
  [-21.1514, 149.0177],
  [-21.1522, 149.0145],
  [-21.1524, 149.0130],
  [-21.1484, 148.9745]
];

const pagetJunctionOld = [
  [-21.1543, 149.1737],
  [-21.1670, 149.1616],
  [-21.1673, 149.1605],
  [-21.1669, 149.1567]
];

const pagetJunctionNew = [
  [-21.1648, 149.1586],
  [-21.1652, 149.1586],
  [-21.1657, 149.1584],
  [-21.1667, 149.1572],
  [-21.1669, 149.1567]
];

const pagetJunctionTwoNew = [
  [-21.1670, 149.1594],
  [-21.1665, 149.1591],
  [-21.1663, 149.1587],
  [-21.1662, 149.1582],
  [-21.1664, 149.1576]
];

const newburyJunctionVictoria = [
  [-21.1484, 148.9745],
  [-21.1486, 148.9742],
  [-21.1488, 148.9738],
  [-21.1495, 148.9734],
  [-21.1642, 148.9716],
  [-21.1730, 148.9720],
  [-21.1776, 148.9715],
  [-21.2314, 148.9738],
  [-21.2447, 148.9764]
];

const victoriaEton = [
  [-21.2447, 148.9764],
  [-21.2506, 148.9776],
  [-21.2542, 148.9773],
  [-21.2549, 148.9771],
  [-21.2603, 148.9730],
  [-21.2614, 148.9730],
  [-21.2625, 148.9733],
  [-21.2635, 148.9743]
];

const newburyJunctionMarian = [
  [-21.1484, 148.9745],
  [-21.1467, 148.9584],
  [-21.1468, 148.9574],
  [-21.1482, 148.9547],
  [-21.1483, 148.9535],
  [-21.1468, 148.9395],
  [-21.1469, 148.9381],
  [-21.1476, 148.9360]
];

const marianMirani = [
  [-21.1476, 148.9360],
  [-21.1612, 148.8865],
  [-21.1613, 148.8849],
  [-21.1590, 148.8626]
];

const miraniPinnacle = [
  [-21.1590, 148.8626],
  [-21.1553, 148.8287],
  [-21.1553, 148.8274],
  [-21.1587, 148.8117],
  [-21.1585, 148.8007],
  [-21.1581, 148.7989],
  [-21.1563, 148.7942],
  [-21.1551, 148.7863],
  [-21.1567, 148.7694],
  [-21.1567, 148.7664],
  [-21.1555, 148.7634],
  [-21.1554, 148.7611],
  [-21.1568, 148.7567],
  [-21.1568, 148.7554],
  [-21.1560, 148.7538],
  [-21.1560, 148.7526],
  [-21.1564, 148.7514],
  [-21.1563, 148.7501],
  [-21.1555, 148.7489],
  [-21.1554, 148.7477],
  [-21.1559, 148.7467],
  [-21.1573, 148.7457],
  [-21.1577, 148.7445],
  [-21.1564, 148.7364],
  [-21.1563, 148.7341],
  [-21.1564, 148.7314],
  [-21.1560, 148.7303],
  [-21.1527, 148.7234],
  [-21.1499, 148.7199],
  [-21.1492, 148.7187],
  [-21.1470, 148.7132]
];

const pinnacleFinchHatton = [
  [-21.1470, 148.7132],
  [-21.1467, 148.7124],
  [-21.1467, 148.7119],
  [-21.1460, 148.6981],
  [-21.1454, 148.6958],
  [-21.1362, 148.6796],
  [-21.1358, 148.6784],
  [-21.1348, 148.6687],
  [-21.1357, 148.6533],
  [-21.1353, 148.6512],
  [-21.1355, 148.6501],
  [-21.1368, 148.6479],
  [-21.1375, 148.6464],
  [-21.1400, 148.6345]
];

const finchHattonNetherdale = [
  [-21.1400, 148.6345],
  [-21.1459, 148.6097],
  [-21.1459, 148.6082],
  [-21.1443, 148.5944],
  [-21.1438, 148.5934],
  [-21.1431, 148.5924],
  [-21.1429, 148.5911],
  [-21.1430, 148.5901],
  [-21.1429, 148.5888],
  [-21.1424, 148.5878],
  [-21.1423, 148.5869],
  [-21.1425, 148.5858],
  [-21.1424, 148.5851],
  [-21.1416, 148.5830],
  [-21.1407, 148.5794],
  [-21.1401, 148.5739],
  [-21.1402, 148.5705],
  [-21.1402, 148.5671],
  [-21.1392, 148.5650],
  [-21.1373, 148.5617],
  [-21.1370, 148.5606],
  [-21.1374, 148.5512],
  [-21.1380, 148.5495],
  [-21.1416, 148.5463],
  [-21.1429, 148.5440],
  [-21.1426, 148.5419],
  [-21.1419, 148.5405],
  [-21.1415, 148.5395],
  [-21.1412, 148.5356],
  [-21.1403, 148.5325]
];

const benholmeKirkup = [
  [-21.1586, 148.8122],
  [-21.1583, 148.8114],
  [-21.1575, 148.8106],
  [-21.1566, 148.8102],
  [-21.1382, 148.8104],
  [-21.1218, 148.8113],
  [-21.1166, 148.8085],
  [-21.1160, 148.8079],
  [-21.1142, 148.8043],
  [-21.1138, 148.8030],
  [-21.1138, 148.7995],
  [-21.1129, 148.7965],
  [-21.1126, 148.7918],
  [-21.1101, 148.7830],
  [-21.1019, 148.7715]
];

const kirkupKungurri = [
  [-21.1019, 148.7715],
  [-21.1017, 148.7709],
  [-21.1017, 148.7702],
  [-21.1007, 148.7681],
  [-21.0969, 148.7624],
  [-21.0956, 148.7610],
  [-21.0951, 148.7599]
];

const gargettOwensCreek = [
  [-21.1559, 148.7467],
  [-21.1541, 148.7477],
  [-21.1531, 148.7477],
  [-21.1435, 148.7446],
  [-21.1420, 148.7436],
  [-21.1410, 148.7422],
  [-21.1405, 148.7412],
  [-21.1400, 148.7406],
  [-21.1375, 148.7398],
  [-21.1367, 148.7390],
  [-21.1355, 148.7373],
  [-21.1344, 148.7365],
  [-21.1254, 148.7330],
  [-21.1186, 148.7321],
  [-21.1147, 148.7325],
  [-21.1118, 148.7322],
  [-21.1105, 148.7314],
  [-21.1091, 148.7297],
  [-21.1078, 148.7290],
  [-21.1042, 148.7283],
  [-21.0974, 148.7276],
  [-21.0953, 148.7272],
  [-21.0915, 148.7257],
  [-21.0870, 148.7206],
  [-21.0841, 148.7149],
  [-21.0836, 148.7112]
];

export const mackay: Line = {
  name: 'Mackay',
  state: 'QLD',
  segments: [
    {
      segments: [tennysonStreetStation],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1924-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boddingtonStreetStation],
      history: {
        opened: {
          date: '1918-04-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pagetJunctionOld],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pagetJunctionNew],
      history: {
        opened: {
          date: '1994-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pagetJunctionTwoNew],
      history: {
        opened: {
          date: '1994-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pagetJunctionnewburyJunction],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newburyJunctionVictoria],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaEton],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newburyJunctionMarian],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [marianMirani],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [miraniPinnacle],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pinnacleFinchHatton],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [finchHattonNetherdale],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [benholmeKirkup],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kirkupKungurri],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gargettOwensCreek],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
