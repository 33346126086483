import { Line } from "../../trackTypes";

const murtoaWarracknabeal = [
  [-36.6105, 142.4693],
  [-36.6091, 142.4688],
  [-36.6079, 142.4686],
  [-36.6066, 142.4687],
  [-36.6053, 142.4689],
  [-36.6038, 142.4695],
  [-36.6028, 142.4701],
  [-36.6018, 142.4709],
  [-36.5611, 142.5066],
  [-36.4970, 142.5709],
  [-36.4958, 142.5724],
  [-36.4884, 142.5823],
  [-36.4875, 142.5831],
  [-36.4863, 142.5839],
  [-36.4847, 142.5845],
  [-36.4674, 142.5868],
  [-36.4659, 142.5867],
  [-36.4646, 142.5863],
  [-36.4638, 142.5860],
  [-36.4629, 142.5853],
  [-36.4605, 142.5831],
  [-36.4541, 142.5771],
  [-36.4530, 142.5763],
  [-36.4514, 142.5754],
  [-36.4030, 142.5501],
  [-36.4021, 142.5497],
  [-36.4003, 142.5493],
  [-36.3993, 142.5492],
  [-36.3924, 142.5492],
  [-36.3903, 142.5488],
  [-36.3891, 142.5484],
  [-36.3443, 142.5274],
  [-36.3431, 142.5267],
  [-36.3421, 142.5258],
  [-36.3414, 142.5248],
  [-36.2825, 142.4324],
  [-36.2814, 142.4311],
  [-36.2581, 142.4067],
  [-36.2568, 142.4055],
  [-36.2498, 142.4005]
];

const warracknabealBeulah = [
  [-36.2498, 142.4005],
  [-36.2462, 142.3979],
  [-36.2455, 142.3976],
  [-36.2448, 142.3974],
  [-36.2440, 142.3974],
  [-36.2303, 142.3993],
  [-36.2292, 142.3993],
  [-36.2162, 142.3993],
  [-36.1893, 142.3994],
  [-36.1888, 142.3995],
  [-36.1876, 142.3999],
  [-36.1863, 142.3999],
  [-36.1822, 142.3999],
  [-36.1815, 142.3997],
  [-36.1804, 142.3994],
  [-36.1740, 142.3994],
  [-36.1730, 142.3995],
  [-36.1722, 142.3998],
  [-36.1353, 142.4173],
  [-36.1342, 142.4181],
  [-36.1331, 142.4195],
  [-36.1321, 142.4208],
  [-36.1310, 142.4216],
  [-36.1267, 142.4237],
  [-36.1256, 142.4239],
  [-36.1250, 142.4239],
  [-36.1219, 142.4238],
  [-36.1205, 142.4234],
  [-36.1188, 142.4224],
  [-36.1171, 142.4218],
  [-36.0693, 142.4218],
  [-36.0680, 142.4220],
  [-36.0537, 142.4261],
  [-36.0523, 142.4263],
  [-36.0498, 142.4261],
  [-36.0487, 142.4257],
  [-36.0302, 142.4186],
  [-36.0286, 142.4178],
  [-36.0114, 142.4065],
  [-36.0085, 142.4060],
  [-36.0008, 142.4071],
  [-35.9985, 142.4080],
  [-35.9976, 142.4082],
  [-35.9928, 142.4089],
  [-35.9913, 142.4087],
  [-35.9905, 142.4087],
  [-35.9530, 142.4144],
  [-35.9520, 142.4148],
  [-35.9512, 142.4152],
  [-35.9463, 142.4186],
  [-35.9450, 142.4201],
  [-35.9419, 142.4224]
];

const beulahHopetoun = [
  [-35.9419, 142.4224],
  [-35.9407, 142.4233],
  [-35.9398, 142.4238],
  [-35.9388, 142.4242],
  [-35.9379, 142.4244],
  [-35.9305, 142.4244],
  [-35.9295, 142.4242],
  [-35.9287, 142.4240],
  [-35.9280, 142.4236],
  [-35.9273, 142.4231],
  [-35.9264, 142.4228],
  [-35.9254, 142.4227],
  [-35.9212, 142.4227],
  [-35.8578, 142.4228],
  [-35.8412, 142.4229],
  [-35.8400, 142.4232],
  [-35.8381, 142.4236],
  [-35.8336, 142.4237],
  [-35.8326, 142.4235],
  [-35.8309, 142.4230],
  [-35.8297, 142.4229],
  [-35.8013, 142.4230],
  [-35.8005, 142.4229],
  [-35.7995, 142.4226],
  [-35.7979, 142.4216],
  [-35.7967, 142.4210],
  [-35.7952, 142.4208],
  [-35.7874, 142.4205],
  [-35.7844, 142.4193],
  [-35.7822, 142.4178],
  [-35.7808, 142.4164],
  [-35.7344, 142.3640],
  [-35.7338, 142.3635],
  [-35.7305, 142.3613]
];

const hopetounPatchewollock = [
  [-35.7305, 142.3613],
  [-35.7158, 142.3498],
  [-35.7127, 142.3482],
  [-35.6782, 142.3371],
  [-35.6689, 142.3361],
  [-35.6630, 142.3357],
  [-35.6579, 142.3353],
  [-35.6559, 142.3348],
  [-35.6477, 142.3303],
  [-35.6173, 142.3179],
  [-35.6103, 142.3152],
  [-35.6082, 142.3149],
  [-35.5982, 142.3144],
  [-35.5910, 142.3139],
  [-35.5893, 142.3126],
  [-35.5839, 142.3073],
  [-35.5822, 142.3060],
  [-35.5814, 142.3056],
  [-35.5728, 142.3015],
  [-35.5713, 142.3010],
  [-35.5699, 142.3009],
  [-35.5679, 142.3008],
  [-35.5660, 142.3005],
  [-35.5546, 142.2978],
  [-35.5455, 142.2945],
  [-35.5434, 142.2932],
  [-35.5414, 142.2910],
  [-35.5378, 142.2843],
  [-35.5364, 142.2824],
  [-35.5347, 142.2807],
  [-35.5222, 142.2729],
  [-35.5205, 142.2722],
  [-35.5185, 142.2718],
  [-35.5068, 142.2718],
  [-35.4935, 142.2711],
  [-35.4855, 142.2710],
  [-35.4844, 142.2708],
  [-35.4779, 142.2685],
  [-35.4764, 142.2675],
  [-35.4636, 142.2549],
  [-35.4600, 142.2515],
  [-35.4591, 142.2502],
  [-35.4499, 142.2322],
  [-35.4484, 142.2305],
  [-35.4462, 142.2289],
  [-35.4404, 142.2259],
  [-35.4212, 142.2156],
  [-35.4089, 142.2093],
  [-35.4073, 142.2087],
  [-35.3938, 142.2041],
  [-35.3860, 142.2008],
  [-35.3842, 142.1996],
  [-35.3830, 142.1976],
  [-35.3826, 142.1951],
  [-35.3826, 142.1937],
  [-35.3846, 142.1858]
];

export const hopetoun: Line = {
  name: 'Hopetoun',
  state: 'VIC',
  segments: [
    {
      segments: [murtoaWarracknabeal],
      history: {
        opened: {
          date: '1886-05-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-05-23',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [warracknabealBeulah],
      history: {
        opened: {
          date: '1893-01-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-05-23',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [beulahHopetoun],
      history: {
        opened: {
          date: '1894-03-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-05-23',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [hopetounPatchewollock],
      history: {
        opened: {
          date: '1925-05-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    }
  ]
}
