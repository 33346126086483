import { Line } from "../../trackTypes";

const newnesJunctionNewnesNo1Tunnel = [
  [-33.4688, 150.2449],
  [-33.4674, 150.2444],
  [-33.4667, 150.2432],
  [-33.4665, 150.2418],
  [-33.4661, 150.2411],
  [-33.4653, 150.2405],
  [-33.4629, 150.2401],
  [-33.4603, 150.2409],
  [-33.4590, 150.2403],
  [-33.4583, 150.2393],
  [-33.4586, 150.2378],
  [-33.4608, 150.2364],
  [-33.4611, 150.2358],
  [-33.4608, 150.2350],
  [-33.4601, 150.2345],
  [-33.4595, 150.2335],
  [-33.4588, 150.2332],
  [-33.4581, 150.2335],
  [-33.4574, 150.2334],
  [-33.4567, 150.2328],
  [-33.4559, 150.2329],
  [-33.4548, 150.2346],
  [-33.4538, 150.2353],
  [-33.4527, 150.2352],
  [-33.4519, 150.2351],
  [-33.4507, 150.2361],
  [-33.4500, 150.2364],
  [-33.4484, 150.2361],
  [-33.4479, 150.2365],
  [-33.4480, 150.2373],
  [-33.4492, 150.2384],
  [-33.4495, 150.2391],
  [-33.4492, 150.2402],
  [-33.4489, 150.2406],
  [-33.4485, 150.2408],
  [-33.4474, 150.2409],
  [-33.4467, 150.2412],
  [-33.4458, 150.2418],
  [-33.4455, 150.2419],
  [-33.4450, 150.2417],
  [-33.4444, 150.2411],
  [-33.4442, 150.2405],
  [-33.4442, 150.2397],
  [-33.4441, 150.2392],
  [-33.4436, 150.2387],
  [-33.4428, 150.2385],
  [-33.4425, 150.2383],
  [-33.4423, 150.2380],
  [-33.4423, 150.2372],
  [-33.4422, 150.2370],
  [-33.4417, 150.2364],
  [-33.4407, 150.2360],
  [-33.4387, 150.2354],
  [-33.4382, 150.2351],
  [-33.4380, 150.2344],
  [-33.4381, 150.2337],
  [-33.4381, 150.2324],
  [-33.4379, 150.2318],
  [-33.4374, 150.2311],
  [-33.4364, 150.2308],
  [-33.4362, 150.2305],
  [-33.4361, 150.2301],
  [-33.4362, 150.2289],
  [-33.4359, 150.2281],
  [-33.4341, 150.2260],
  [-33.4337, 150.2258],
  [-33.4334, 150.2255],
  [-33.4328, 150.2240],
  [-33.4326, 150.2225],
  [-33.4324, 150.2219],
  [-33.4319, 150.2215],
  [-33.4309, 150.2214],
  [-33.4302, 150.2208],
  [-33.4294, 150.2187],
  [-33.4292, 150.2167],
  [-33.4294, 150.2157],
  [-33.4302, 150.2145],
  [-33.4310, 150.2138],
  [-33.4313, 150.2134],
  [-33.4313, 150.2124],
  [-33.4294, 150.2091],
  [-33.4285, 150.2086],
  [-33.4279, 150.2086],
  [-33.4273, 150.2083],
  [-33.4267, 150.2062],
  [-33.4263, 150.2058],
  [-33.4258, 150.2057],
  [-33.4247, 150.2061],
  [-33.4242, 150.2059],
  [-33.4238, 150.2054],
  [-33.4237, 150.2030],
  [-33.4234, 150.2024],
  [-33.4229, 150.2021],
  [-33.4223, 150.2020],
  [-33.4180, 150.2040],
  [-33.4172, 150.2042],
  [-33.4161, 150.2039],
  [-33.4108, 150.1999],
  [-33.4073, 150.1986],
  [-33.4019, 150.1988],
  [-33.4006, 150.1993],
  [-33.3996, 150.2011],
  [-33.3997, 150.2021],
  [-33.4001, 150.2033],
  [-33.3998, 150.2045],
  [-33.3991, 150.2050],
  [-33.3975, 150.2057],
  [-33.3965, 150.2070],
  [-33.3958, 150.2096],
  [-33.3949, 150.2102],
  [-33.3932, 150.2105],
  [-33.3923, 150.2116],
  [-33.3920, 150.2134],
  [-33.3918, 150.2159],
  [-33.3920, 150.2169],
  [-33.3925, 150.2175],
  [-33.3926, 150.2185],
  [-33.3921, 150.2192],
  [-33.3915, 150.2193],
  [-33.3902, 150.2190],
  [-33.3896, 150.2194],
  [-33.3893, 150.2202],
  [-33.3895, 150.2208],
  [-33.3902, 150.2217],
  [-33.3904, 150.2225],
  [-33.3902, 150.2241],
  [-33.3903, 150.2258],
  [-33.3907, 150.2271],
  [-33.3905, 150.2286],
  [-33.3896, 150.2295],
  [-33.3868, 150.2310],
  [-33.3844, 150.2333],
  [-33.3834, 150.2337],
  [-33.3818, 150.2335],
  [-33.3810, 150.2337],
  [-33.3803, 150.2343],
  [-33.3774, 150.2384],
  [-33.3769, 150.2388],
  [-33.3762, 150.2386],
  [-33.3745, 150.2377],
  [-33.3736, 150.2380],
  [-33.3727, 150.2390],
  [-33.3634, 150.2419],
  [-33.3622, 150.2429],
  [-33.3612, 150.2439],
  [-33.3596, 150.2446],
  [-33.3590, 150.2453],
  [-33.3582, 150.2465],
  [-33.3575, 150.2468],
  [-33.3564, 150.2469],
  [-33.3557, 150.2473],
  [-33.3555, 150.2482],
  [-33.3558, 150.2490],
  [-33.3569, 150.2509],
  [-33.3571, 150.2518],
  [-33.3568, 150.2526],
  [-33.3560, 150.2529],
  [-33.3550, 150.2527],
  [-33.3541, 150.2526],
  [-33.3523, 150.2529],
  [-33.3514, 150.2536],
  [-33.3507, 150.2547],
  [-33.3499, 150.2554],
  [-33.3451, 150.2551],
  [-33.3443, 150.2549],
  [-33.3415, 150.2526],
  [-33.3398, 150.2509],
  [-33.3393, 150.2500],
  [-33.3388, 150.2496],
  [-33.3381, 150.2497],
  [-33.3374, 150.2502],
  [-33.3370, 150.2503],
  [-33.3364, 150.2502],
  [-33.3357, 150.2496],
  [-33.3347, 150.2493],
  [-33.3336, 150.2495],
  [-33.3305, 150.2496],
  [-33.3292, 150.2492],
  [-33.3272, 150.2480],
  [-33.3264, 150.2480],
  [-33.3252, 150.2483],
  [-33.3246, 150.2483],
  [-33.3239, 150.2477],
  [-33.3233, 150.2463],
  [-33.3227, 150.2455],
  [-33.3217, 150.2452],
  [-33.3208, 150.2454],
  [-33.3199, 150.2462],
  [-33.3193, 150.2466],
  [-33.3187, 150.2467],
  [-33.3163, 150.2456],
  [-33.3159, 150.2449],
  [-33.3162, 150.2426],
  [-33.3162, 150.2426],
  [-33.3160, 150.2415],
  [-33.3151, 150.2401],
  [-33.3138, 150.2395],
  [-33.3085, 150.2398],
  [-33.3067, 150.2397],
  [-33.3050, 150.2401],
  [-33.3041, 150.2400],
  [-33.3032, 150.2395],
  [-33.3026, 150.2394],
  [-33.3015, 150.2397],
  [-33.3007, 150.2392],
  [-33.2986, 150.2358],
  [-33.2980, 150.2353],
  [-33.2974, 150.2352],
  [-33.2959, 150.2354],
  [-33.2951, 150.2353],
  [-33.2902, 150.2331],
  [-33.2892, 150.2332],
  [-33.2857, 150.2356],
  [-33.2852, 150.2357],
  [-33.2847, 150.2356],
  [-33.2807, 150.2325],
  [-33.2799, 150.2314],
  [-33.2794, 150.2312],
  [-33.2789, 150.2314],
  [-33.2777, 150.2329],
  [-33.2770, 150.2331],
  [-33.2764, 150.2328],
  [-33.2760, 150.2321],
  [-33.2757, 150.2303],
  [-33.2755, 150.2296],
  [-33.2735, 150.2256],
  [-33.2730, 150.2251],
  [-33.2725, 150.2252],
  [-33.2721, 150.2256],
  [-33.2717, 150.2265],
  [-33.2710, 150.2268],
  [-33.2700, 150.2267],
  [-33.2674, 150.2255],
  [-33.2670, 150.2246],
  [-33.2662, 150.2242],
  [-33.2640, 150.2246],
  [-33.2634, 150.2245],
  [-33.2628, 150.2242],
  [-33.2612, 150.2242],
  [-33.2607, 150.2237],
  [-33.2605, 150.2229],
  [-33.2608, 150.2220],
  [-33.2607, 150.2214],
  [-33.2603, 150.2209],
  [-33.2593, 150.2205],
  [-33.2589, 150.2200],
  [-33.2587, 150.2194],
  [-33.2584, 150.2190],
  [-33.2570, 150.2188],
  [-33.2570, 150.2188],
  [-33.2558, 150.2184],
  [-33.2545, 150.2177],
  [-33.2539, 150.2176],
  [-33.2522, 150.2180],
  [-33.2518, 150.2183],
  [-33.2515, 150.2190],
  [-33.2512, 150.2193],
  [-33.2508, 150.2195],
  [-33.2501, 150.2195]
];

const newnesNo1Tunnel = [
  [-33.2501, 150.2195],
  [-33.2497, 150.2197],
  [-33.2494, 150.2202],
  [-33.2494, 150.2206]
];

const newnesTunnel1NewnesTunnel2 = [
  [-33.2494, 150.2206],
  [-33.2495, 150.2211],
  [-33.2500, 150.2215],
  [-33.2505, 150.2215],
  [-33.2509, 150.2212],
  [-33.2512, 150.2207],
  [-33.2520, 150.2200],
  [-33.2524, 150.2197],
  [-33.2539, 150.2196],
  [-33.2545, 150.2197],
  [-33.2550, 150.2203],
  [-33.2550, 150.2211],
  [-33.2546, 150.2217],
  [-33.2541, 150.2219],
  [-33.2527, 150.2218],
  [-33.2518, 150.2220],
  [-33.2488, 150.2221],
  [-33.2483, 150.2223],
  [-33.2480, 150.2226],
  [-33.2479, 150.2230],
  [-33.2479, 150.2235],
  [-33.2481, 150.2240],
  [-33.2482, 150.2245],
  [-33.2480, 150.2251],
  [-33.2472, 150.2261],
  [-33.2470, 150.2263],
  [-33.2467, 150.2264],
  [-33.2452, 150.2262],
  [-33.2448, 150.2263],
  [-33.2445, 150.2265],
  [-33.2441, 150.2271],
  [-33.2436, 150.2274],
  [-33.2433, 150.2275],
  [-33.2428, 150.2274],
  [-33.2426, 150.2272],
  [-33.2424, 150.2269],
  [-33.2421, 150.2266],
  [-33.2414, 150.2266],
  [-33.2409, 150.2269],
  [-33.2404, 150.2274],
  [-33.2399, 150.2277],
  [-33.2396, 150.2277],
  [-33.2392, 150.2275]
];

const newnesNo2Tunnel = [
  [-33.2392, 150.2275],
  [-33.2389, 150.2271],
  [-33.2388, 150.2263],
  [-33.2388, 150.2256]
];

const newnesNo2TunnelNewnes = [
  [-33.2388, 150.2256],
  [-33.2389, 150.2250],
  [-33.2395, 150.2238],
  [-33.2397, 150.2229],
  [-33.2396, 150.2221],
  [-33.2390, 150.2212],
  [-33.2354, 150.2197],
  [-33.2354, 150.2197],
  [-33.2343, 150.2195],
  [-33.2335, 150.2194],
  [-33.2327, 150.2191],
  [-33.2311, 150.2175],
  [-33.2307, 150.2174],
  [-33.2301, 150.2174],
  [-33.2298, 150.2177],
  [-33.2296, 150.2181],
  [-33.2296, 150.2192],
  [-33.2295, 150.2196],
  [-33.2293, 150.2203],
  [-33.2289, 150.2208],
  [-33.2279, 150.2232],
  [-33.2276, 150.2236],
  [-33.2273, 150.2239],
  [-33.2266, 150.2263],
  [-33.2259, 150.2270],
  [-33.2255, 150.2272],
  [-33.2246, 150.2275],
  [-33.2227, 150.2289],
  [-33.2210, 150.2299],
  [-33.2208, 150.2302],
  [-33.2202, 150.2319],
  [-33.2200, 150.2324],
  [-33.2201, 150.2327],
  [-33.2208, 150.2345],
  [-33.2209, 150.2350],
  [-33.2208, 150.2357],
  [-33.2208, 150.2365],
  [-33.2209, 150.2368],
  [-33.2211, 150.2373],
  [-33.2211, 150.2376],
  [-33.2209, 150.2383],
  [-33.2208, 150.2389],
  [-33.2207, 150.2389],
  [-33.2206, 150.2389],
  [-33.2203, 150.2384],
  [-33.2198, 150.2381],
  [-33.2191, 150.2378],
  [-33.2187, 150.2374],
  [-33.2172, 150.2368],
  [-33.2162, 150.2367],
  [-33.2146, 150.2373],
  [-33.2136, 150.2383],
  [-33.2129, 150.2396],
  [-33.2118, 150.2433],
  [-33.2116, 150.2435],
  [-33.2106, 150.2440],
  [-33.2092, 150.2449],
  [-33.2088, 150.2449],
  [-33.2062, 150.2436],
  [-33.2056, 150.2435],
  [-33.2047, 150.2435],
  [-33.2034, 150.2440],
  [-33.2015, 150.2454],
  [-33.2008, 150.2456],
  [-33.2001, 150.2461],
  [-33.1994, 150.2464],
  [-33.1981, 150.2466],
  [-33.1973, 150.2471],
  [-33.1968, 150.2476],
  [-33.1962, 150.2477],
  [-33.1956, 150.2474],
  [-33.1951, 150.2472],
  [-33.1920, 150.2472],
  [-33.1911, 150.2469],
  [-33.1891, 150.2457],
  [-33.1880, 150.2454],
  [-33.1877, 150.2456],
  [-33.1874, 150.2458],
  [-33.1870, 150.2458],
  [-33.1866, 150.2456],
  [-33.1854, 150.2450],
  [-33.1849, 150.2446],
  [-33.1846, 150.2438],
  [-33.1838, 150.2427],
  [-33.1834, 150.2416],
  [-33.1835, 150.2401],
  [-33.1834, 150.2395],
  [-33.1828, 150.2388],
  [-33.1820, 150.2384],
  [-33.1808, 150.2375],
  [-33.1792, 150.2367],
  [-33.1785, 150.2365],
  [-33.1775, 150.2368],
  [-33.1752, 150.2380],
  [-33.1748, 150.2384],
  [-33.1746, 150.2391],
  [-33.1746, 150.2399],
  [-33.1755, 150.2435],
  [-33.1755, 150.2464],
  [-33.1758, 150.2476],
  [-33.1757, 150.2485],
  [-33.1751, 150.2493],
  [-33.1732, 150.2510],
  [-33.1726, 150.2519],
  [-33.1714, 150.2542]
];

export const newnes: Line = {
  name: 'Newnes',
  state: 'NSW',
  segments: [
    {
      segments: [
        newnesJunctionNewnesNo1Tunnel,
        {
          name: 'Newnes No 1 tunnel',
          segment: newnesNo1Tunnel,
          type: 'tunnel'
        },
        newnesTunnel1NewnesTunnel2,
        {
          name: 'Newnes No 2 tunnel',
          segment: newnesNo2Tunnel,
          type: 'tunnel'
        },
        newnesNo2TunnelNewnes
      ],
      history: {
        opened: {
          date: '1907-12-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1932-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
