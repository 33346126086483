import { Line } from "../../trackTypes";

const tailemBendPinnaroo = [
  [-35.2691, 139.4695],
  [-35.2745, 139.4746],
  [-35.2803, 139.4832],
  [-35.2813, 139.4858],
  [-35.2856, 139.5090],
  [-35.2864, 139.5923],
  [-35.2867, 139.5944],
  [-35.2880, 139.5992],
  [-35.2882, 139.6010],
  [-35.2882, 139.6284],
  [-35.2926, 139.6727],
  [-35.3172, 139.7801],
  [-35.3174, 139.7825],
  [-35.3181, 139.8066],
  [-35.3265, 139.8664],
  [-35.3272, 139.8681],
  [-35.3451, 139.8942],
  [-35.3624, 139.9461],
  [-35.3712, 139.9626],
  [-35.3740, 139.9702],
  [-35.3758, 139.9729],
  [-35.3766, 139.9746],
  [-35.3771, 139.9773],
  [-35.3792, 139.9818],
  [-35.3801, 139.9861],
  [-35.3827, 139.9948],
  [-35.3843, 139.9995],
  [-35.3846, 140.0029],
  [-35.3839, 140.0061],
  [-35.3813, 140.0100],
  [-35.3799, 140.0109],
  [-35.3789, 140.0122],
  [-35.3784, 140.0138],
  [-35.3775, 140.0249],
  [-35.3779, 140.0268],
  [-35.3806, 140.0312],
  [-35.3841, 140.0438],
  [-35.3849, 140.0635],
  [-35.3858, 140.0669],
  [-35.3854, 140.0753],
  [-35.3848, 140.0779],
  [-35.3843, 140.0965],
  [-35.3839, 140.0993],
  [-35.3838, 140.1041],
  [-35.3844, 140.1094],
  [-35.3839, 140.1144],
  [-35.3821, 140.1192],
  [-35.3820, 140.1209],
  [-35.3822, 140.1222],
  [-35.3846, 140.1279],
  [-35.3850, 140.1305],
  [-35.3834, 140.1393],
  [-35.3830, 140.1445],
  [-35.3836, 140.1524],
  [-35.3834, 140.1551],
  [-35.3794, 140.1788],
  [-35.3793, 140.1807],
  [-35.3801, 140.1874],
  [-35.3800, 140.1923],
  [-35.3806, 140.1994],
  [-35.3796, 140.2094],
  [-35.3803, 140.2244],
  [-35.3830, 140.2391],
  [-35.3831, 140.2420],
  [-35.3828, 140.2481],
  [-35.3805, 140.2580],
  [-35.3803, 140.2603],
  [-35.3821, 140.2879],
  [-35.3817, 140.2904],
  [-35.3758, 140.3041],
  [-35.3752, 140.3075],
  [-35.3756, 140.3337],
  [-35.3746, 140.3494],
  [-35.3663, 140.3741],
  [-35.3650, 140.3792],
  [-35.3629, 140.3907],
  [-35.3552, 140.4176],
  [-35.3523, 140.4223],
  [-35.3406, 140.4607],
  [-35.3371, 140.4852],
  [-35.3174, 140.5741],
  [-35.3024, 140.6030],
  [-35.3016, 140.6057],
  [-35.3012, 140.6167],
  [-35.2998, 140.6283],
  [-35.2998, 140.6299],
  [-35.3010, 140.6382],
  [-35.3009, 140.6398],
  [-35.3003, 140.6411],
  [-35.2972, 140.6453],
  [-35.2958, 140.6485],
  [-35.2953, 140.6519],
  [-35.2978, 140.7126],
  [-35.2938, 140.7419],
  [-35.2929, 140.7447],
  [-35.2836, 140.7607],
  [-35.2783, 140.7661],
  [-35.2772, 140.7678],
  [-35.2766, 140.7694],
  [-35.2743, 140.7863],
  [-35.2743, 140.7888],
  [-35.2769, 140.8308],
  [-35.2756, 140.8561],
  [-35.2750, 140.8585],
  [-35.2667, 140.8759],
  [-35.2661, 140.8783],
  [-35.2647, 140.8893],
  [-35.2600, 140.9071]
];

const pinnarooVicBorder = [
  [-35.2600, 140.9071],
  [-35.2500, 140.9439],
  [-35.2483, 140.9479],
  [-35.2384, 140.9632],
  [-35.2375, 140.9650],
  [-35.2372, 140.9659]
];

export const pinnaroo: Line = {
  name: 'Pinnaroo',
  state: 'SA',
  segments: [
    {
      segments: [tailemBendPinnaroo],
      history: {
        opened: {
          date: '1909-09-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-06-27',
          gauge: 'standard'
        }, {
          date: '2015-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pinnarooVicBorder],
      history: {
        opened: {
          date: '1915-07-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-06-27',
          status: 'closed'
        }]
      }
    }
  ]
};
