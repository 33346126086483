import { Line } from "../../../trackTypes";

const perthEsplanade = [
  [-31.9492, 115.8560],
  [-31.9497, 115.8578],
  [-31.9501, 115.8583],
  [-31.9508, 115.8584],
  [-31.9525, 115.8577],
  [-31.9536, 115.8569],
  [-31.9560, 115.8558],
  [-31.9565, 115.8555]
];

const esplanadeTrench = [
  [-31.9565, 115.8555],
  [-31.9575, 115.8549],
  [-31.9579, 115.8544],
  [-31.9581, 115.8537]
];

const trench = [
  [-31.9581, 115.8537],
  [-31.9581, 115.8522]
];

const trenchTunnelEnd = [
  [-31.9581, 115.8522],
  [-31.9581, 115.8511]
]

const esplanadeMandurah = [
  [-31.9581, 115.8511],
  [-31.9581, 115.8507],
  [-31.9583, 115.8494],
  [-31.9588, 115.8486],
  [-31.9593, 115.8481],
  [-31.9608, 115.8474],
  [-31.9671, 115.8462],
  [-31.9686, 115.8463],
  [-31.9702, 115.8471],
  [-31.9718, 115.8475],
  [-31.9792, 115.8485],
  [-31.9807, 115.8491],
  [-31.9830, 115.8506],
  [-31.9851, 115.8519],
  [-31.9911, 115.8546],
  [-31.9937, 115.8552],
  [-31.9978, 115.8551],
  [-32.0018, 115.8540],
  [-32.0035, 115.8537],
  [-32.0048, 115.8540],
  [-32.0095, 115.8561],
  [-32.0125, 115.8573],
  [-32.0153, 115.8577],
  [-32.0191, 115.8573],
  [-32.0205, 115.8575],
  [-32.0221, 115.8580],
  [-32.0251, 115.8600],
  [-32.0266, 115.8607],
  [-32.0291, 115.8607],
  [-32.0307, 115.8600],
  [-32.0378, 115.8559],
  [-32.0398, 115.8554],
  [-32.0452, 115.8546],
  [-32.0545, 115.8518],
  [-32.0572, 115.8513],
  [-32.0827, 115.8490],
  [-32.0977, 115.8495],
  [-32.1028, 115.8502],
  [-32.1087, 115.8516],
  [-32.1205, 115.8560],
  [-32.1262, 115.8588],
  [-32.1301, 115.8597],
  [-32.1459, 115.8599],
  [-32.1494, 115.8595],
  [-32.1599, 115.8565],
  [-32.1623, 115.8563],
  [-32.1875, 115.8573],
  [-32.1934, 115.8568],
  [-32.1987, 115.8552],
  [-32.2110, 115.8498],
  [-32.2154, 115.8486],
  [-32.2206, 115.8484],
  [-32.2221, 115.8485],
  [-32.2251, 115.8482],
  [-32.2312, 115.8455],
  [-32.2384, 115.8401],
  [-32.2434, 115.8380],
  [-32.2567, 115.8294],
  [-32.2592, 115.8270],
  [-32.2607, 115.8241],
  [-32.2617, 115.8209],
  [-32.2630, 115.8185],
  [-32.2664, 115.8137],
  [-32.2678, 115.8109],
  [-32.2695, 115.8057],
  [-32.2717, 115.8010],
  [-32.2730, 115.7951],
  [-32.2776, 115.7875],
  [-32.2796, 115.7815],
  [-32.2883, 115.7624],
  [-32.2889, 115.7617],
  [-32.2910, 115.7611],
  [-32.2920, 115.7612],
  [-32.2998, 115.7679],
  [-32.3022, 115.7692],
  [-32.3049, 115.7695],
  [-32.3071, 115.7689],
  [-32.3111, 115.7665],
  [-32.3147, 115.7660],
  [-32.3224, 115.7680],
  [-32.3244, 115.7681],
  [-32.3354, 115.7674],
  [-32.3411, 115.7681],
  [-32.3601, 115.7737],
  [-32.3685, 115.7767],
  [-32.3853, 115.7824],
  [-32.3881, 115.7827],
  [-32.3905, 115.7823],
  [-32.3932, 115.7807],
  [-32.3974, 115.7767],
  [-32.4017, 115.7748],
  [-32.4088, 115.7751],
  [-32.4125, 115.7741],
  [-32.4154, 115.7742],
  [-32.4170, 115.7744],
  [-32.4226, 115.7741],
  [-32.4261, 115.7752],
  [-32.4294, 115.7752],
  [-32.4359, 115.7727],
  [-32.4386, 115.7723],
  [-32.4494, 115.7723],
  [-32.4536, 115.7722],
  [-32.4576, 115.7728],
  [-32.4622, 115.7727],
  [-32.4673, 115.7714],
  [-32.4782, 115.7701],
  [-32.4809, 115.7691],
  [-32.4846, 115.7667],
  [-32.4879, 115.7657],
  [-32.4962, 115.7645],
  [-32.5001, 115.7626],
  [-32.5037, 115.7585],
  [-32.5069, 115.7565],
  [-32.5143, 115.7549],
  [-32.5174, 115.7527],
  [-32.5208, 115.7488],
  [-32.5222, 115.7480],
  [-32.5277, 115.7466]
];

export const mandurah: Line = {
  name: 'Mandurah',
  state: 'WA',
  segments: [
    {
      segments: [{
        name: 'William Street Tunnel',
        segment: perthEsplanade,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '2007-10-15',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        {
          name: 'William Street Tunnel',
          segment: esplanadeTrench,
          type: 'tunnel'
        },
        trench,
        {
          name: 'William Street Tunnel',
          segment: trenchTunnelEnd,
          type: 'tunnel'
        },
        esplanadeMandurah
      ],
      history: {
        opened: {
          date: '2007-12-23',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
