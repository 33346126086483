import { Line } from "../../../trackTypes";

const ipswichChurchill = [
  [-27.6155, 152.7497],
  [-27.6160, 152.7476],
  [-27.6166, 152.7469],
  [-27.6173, 152.7466],
  [-27.6194, 152.7471],
  [-27.6203, 152.7477],
  [-27.6210, 152.7495],
  [-27.6217, 152.7504],
  [-27.6232, 152.7511],
  [-27.6238, 152.7512],
  [-27.6261, 152.7510],
  [-27.6268, 152.7506],
  [-27.6286, 152.7485],
  [-27.6294, 152.7479],
  [-27.6302, 152.7478],
  [-27.6330, 152.7484],
  [-27.6356, 152.7486]
];

const churchillHarrisville = [
  [-27.6356, 152.7486],
  [-27.6370, 152.7485],
  [-27.6391, 152.7478],
  [-27.6398, 152.7480],
  [-27.6410, 152.7495],
  [-27.6436, 152.7506],
  [-27.6474, 152.7508],
  [-27.6480, 152.7506],
  [-27.6492, 152.7500],
  [-27.6500, 152.7499],
  [-27.6532, 152.7504],
  [-27.6539, 152.7502],
  [-27.6545, 152.7498],
  [-27.6576, 152.7445],
  [-27.6582, 152.7442],
  [-27.6588, 152.7440],
  [-27.6594, 152.7436],
  [-27.6606, 152.7420],
  [-27.6646, 152.7395],
  [-27.6651, 152.7385],
  [-27.6660, 152.7378],
  [-27.6683, 152.7368],
  [-27.6694, 152.7364],
  [-27.6744, 152.7361],
  [-27.6890, 152.7384],
  [-27.6908, 152.7386],
  [-27.7055, 152.7377],
  [-27.7179, 152.7409],
  [-27.7225, 152.7421],
  [-27.7235, 152.7422],
  [-27.7321, 152.7409],
  [-27.7385, 152.7416],
  [-27.7394, 152.7415],
  [-27.7443, 152.7397],
  [-27.7475, 152.7368],
  [-27.7486, 152.7364],
  [-27.7573, 152.7357],
  [-27.7581, 152.7358],
  [-27.7637, 152.7376],
  [-27.7650, 152.7376],
  [-27.7774, 152.7332],
  [-27.7783, 152.7325],
  [-27.7806, 152.7304],
  [-27.7836, 152.7285],
  [-27.7839, 152.7282],
  [-27.7847, 152.7266],
  [-27.7900, 152.7193],
  [-27.7902, 152.7185],
  [-27.7897, 152.7151],
  [-27.7894, 152.7145],
  [-27.7872, 152.7099],
  [-27.7863, 152.7072],
  [-27.7862, 152.7061],
  [-27.7867, 152.7032],
  [-27.7894, 152.6980],
  [-27.7897, 152.6967],
  [-27.7876, 152.6827],
  [-27.7879, 152.6817],
  [-27.7886, 152.6806],
  [-27.8002, 152.6708],
  [-27.8009, 152.6700],
  [-27.8018, 152.6661],
  [-27.8024, 152.6652],
  [-27.8033, 152.6648],
  [-27.8044, 152.6650],
  [-27.8068, 152.6670],
  [-27.8083, 152.6672],
  [-27.8110, 152.6666]
];

const harrisvilleDugandan = [
  [-27.8110, 152.6666],
  [-27.8118, 152.6663],
  [-27.8130, 152.6664],
  [-27.8171, 152.6690],
  [-27.8180, 152.6693],
  [-27.8189, 152.6690],
  [-27.8261, 152.6634],
  [-27.8520, 152.6458],
  [-27.8540, 152.6435],
  [-27.8548, 152.6432],
  [-27.8559, 152.6434],
  [-27.8620, 152.6483],
  [-27.8625, 152.6493],
  [-27.8628, 152.6511],
  [-27.8630, 152.6518],
  [-27.8635, 152.6524],
  [-27.8746, 152.6579],
  [-27.8785, 152.6599],
  [-27.8795, 152.6610],
  [-27.8801, 152.6625],
  [-27.8803, 152.6648],
  [-27.8806, 152.6661],
  [-27.8815, 152.6673],
  [-27.8825, 152.6681],
  [-27.8830, 152.6689],
  [-27.8830, 152.6699],
  [-27.8825, 152.6710],
  [-27.8825, 152.6718],
  [-27.8828, 152.6725],
  [-27.8836, 152.6730],
  [-27.8861, 152.6732],
  [-27.8873, 152.6738],
  [-27.8882, 152.6751],
  [-27.8895, 152.6762],
  [-27.8919, 152.6767],
  [-27.8949, 152.6767],
  [-27.8959, 152.6775],
  [-27.8968, 152.6792],
  [-27.8968, 152.6806],
  [-27.8963, 152.6817],
  [-27.8961, 152.6825],
  [-27.8964, 152.6832],
  [-27.8971, 152.6838],
  [-27.8979, 152.6839],
  [-27.8989, 152.6833],
  [-27.8996, 152.6830],
  [-27.9003, 152.6832],
  [-27.9020, 152.6842],
  [-27.9037, 152.6843],
  [-27.9049, 152.6838],
  [-27.9088, 152.6802],
  [-27.9098, 152.6798],
  [-27.9107, 152.6800],
  [-27.9113, 152.6804],
  [-27.9124, 152.6821],
  [-27.9146, 152.6837],
  [-27.9155, 152.6839],
  [-27.9166, 152.6837],
  [-27.9177, 152.6838],
  [-27.9194, 152.6844],
  [-27.9207, 152.6846],
  [-27.9292, 152.6838],
  [-27.9307, 152.6829],
  [-27.9352, 152.6824],
  [-27.9368, 152.6816],
  [-27.9395, 152.6815],
  [-27.9402, 152.6818],
  [-27.9412, 152.6825],
  [-27.9425, 152.6828],
  [-27.9469, 152.6816],
  [-27.9480, 152.6821],
  [-27.9487, 152.6835],
  [-27.9487, 152.6847],
  [-27.9475, 152.6877],
  [-27.9473, 152.6877],
  [-27.9479, 152.6887],
  [-27.9489, 152.6892],
  [-27.9496, 152.6900],
  [-27.9498, 152.6911],
  [-27.9505, 152.6918],
  [-27.9530, 152.6923],
  [-27.9540, 152.6921],
  [-27.9547, 152.6913],
  [-27.9553, 152.6904],
  [-27.9564, 152.6899],
  [-27.9661, 152.6904],
  [-27.9695, 152.6897],
  [-27.9702, 152.6893],
  [-27.9717, 152.6881],
  [-27.9723, 152.6877],
  [-27.9733, 152.6873],
  [-27.9739, 152.6870],
  [-27.9755, 152.6847],
  [-27.9762, 152.6842],
  [-27.9801, 152.6837],
  [-27.9810, 152.6834],
  [-27.9840, 152.6810],
  [-27.9845, 152.6799],
  [-27.9847, 152.6784],
  [-27.9855, 152.6775],
  [-27.9867, 152.6775],
  [-27.9878, 152.6785],
  [-27.9886, 152.6789],
  [-27.9896, 152.6785],
  [-27.9904, 152.6777],
  [-27.9910, 152.6772],
  [-27.9936, 152.6772],
  [-27.9945, 152.6777],
  [-27.9949, 152.6788],
  [-27.9948, 152.6802],
  [-27.9949, 152.6813],
  [-27.9960, 152.6816],
  [-27.9971, 152.6815],
  [-27.9999, 152.6815],
  [-28.0005, 152.6813],
  [-28.0017, 152.6802],
  [-28.0025, 152.6796],
  [-28.0029, 152.6790],
  [-28.0035, 152.6777],
  [-28.0049, 152.6756]
];

export const dugandan: Line = {
  name: 'Dugandan',
  state: 'QLD',
  segments: [
    {
      segments: [ipswichChurchill],
      history: {
        opened: {
          date: '1882-07-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [churchillHarrisville],
      history: {
        opened: {
          date: '1882-07-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [harrisvilleDugandan],
      history: {
        opened: {
          date: '1887-09-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
