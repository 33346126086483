import { Line } from "../../trackTypes";

const culcairnBrocklesby = [
  [-35.6720, 147.0369],
  [-35.6729, 147.0366],
  [-35.6737, 147.0359],
  [-35.6743, 147.0351],
  [-35.7287, 146.9558],
  [-35.7317, 146.9498],
  [-35.7328, 146.9480],
  [-35.7338, 146.9476],
  [-35.7365, 146.9466],
  [-35.7378, 146.9453],
  [-35.7383, 146.9440],
  [-35.7388, 146.9421],
  [-35.7397, 146.9397],
  [-35.7564, 146.9140],
  [-35.7835, 146.8546],
  [-35.7855, 146.8517],
  [-35.7993, 146.8385],
  [-35.8002, 146.8370],
  [-35.8007, 146.8353],
  [-35.8014, 146.8190],
  [-35.8010, 146.8156],
  [-35.7960, 146.7996],
  [-35.7958, 146.7968],
  [-35.7966, 146.7879],
  [-35.7963, 146.7855],
  [-35.7942, 146.7780],
  [-35.7940, 146.7755],
  [-35.7944, 146.7730],
  [-35.7954, 146.7709],
  [-35.8008, 146.7637],
  [-35.8023, 146.7587],
  [-35.8024, 146.7571],
  [-35.8020, 146.7558],
  [-35.8013, 146.7543],
  [-35.8009, 146.7515],
  [-35.8017, 146.7494],
  [-35.8032, 146.7477],
  [-35.8042, 146.7458],
  [-35.8063, 146.7298],
  [-35.8067, 146.7284],
  [-35.8076, 146.7269],
  [-35.8080, 146.7255],
  [-35.8082, 146.7236],
  [-35.8084, 146.7221],
  [-35.8090, 146.7210],
  [-35.8110, 146.7190],
  [-35.8129, 146.7156],
  [-35.8204, 146.6858],
  [-35.8214, 146.6757]
];

const brocklesbyCorowa = [
  [-35.8214, 146.6757],
  [-35.8323, 146.5704],
  [-35.8339, 146.5662],
  [-35.8361, 146.5637],
  [-35.8437, 146.5592],
  [-35.8456, 146.5574],
  [-35.8462, 146.5543],
  [-35.8440, 146.5386],
  [-35.8441, 146.5337],
  [-35.8430, 146.5253],
  [-35.8431, 146.5231],
  [-35.8436, 146.5214],
  [-35.8548, 146.4994],
  [-35.8552, 146.4970],
  [-35.8547, 146.4938],
  [-35.8550, 146.4907],
  [-35.8662, 146.4637],
  [-35.8692, 146.4588],
  [-35.8852, 146.4416],
  [-35.8910, 146.4381],
  [-35.9430, 146.4276],
  [-35.9442, 146.4260],
  [-35.9507, 146.4155],
  [-35.9539, 146.4121],
  [-35.9583, 146.4096],
  [-35.9825, 146.3999],
  [-35.9849, 146.3985],
  [-35.9883, 146.3951],
  [-35.9895, 146.3932],
  [-35.9973, 146.3857]
];

export const corowa: Line = {
  name: 'Corowa',
  state: 'NSW',
  segments: [
    {
      segments: [culcairnBrocklesby],
      history: {
        opened: {
          date: '1892-10-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brocklesbyCorowa],
      history: {
        opened: {
          date: '1892-10-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-09',
          status: 'closed'
        }]
      }
    }
  ]
};
