import { Line } from "../../../trackTypes";

const sutherlandGymea = [
  [-34.0336, 151.0558],
  [-34.0346, 151.0554],
  [-34.0357, 151.0558],
  [-34.0363, 151.0564],
  [-34.0368, 151.0575],
  [-34.0368, 151.0585],
  [-34.0365, 151.0596],
  [-34.0360, 151.0609],
  [-34.0358, 151.0622],
  [-34.0357, 151.0675],
  [-34.0356, 151.0684],
  [-34.0351, 151.0702],
  [-34.0349, 151.0717],
  [-34.0349, 151.0736],
  [-34.0347, 151.0803],
  [-34.0348, 151.0854]
];

const gymeaCaringbah = [
  [-34.0348, 151.0854],
  [-34.0351, 151.0929],
  [-34.0369, 151.1069],
  [-34.0393, 151.1158],
  [-34.0396, 151.1166],
  [-34.0409, 151.1186],
  [-34.0414, 151.1200],
  [-34.0416, 151.1227]
];

const caringbahCronulla = [
  [-34.0416, 151.1227],
  [-34.0417, 151.1244],
  [-34.0418, 151.1253],
  [-34.0468, 151.1396],
  [-34.0472, 151.1425],
  [-34.0476, 151.1440],
  [-34.0482, 151.1451],
  [-34.0496, 151.1464],
  [-34.0509, 151.1474],
  [-34.0529, 151.1502],
  [-34.0535, 151.1507],
  [-34.0544, 151.1511],
  [-34.0576, 151.1516]
];

const woronoraCemetery = [
  [-34.0341, 151.0554],
  [-34.0344, 151.0547],
  [-34.0346, 151.0541],
  [-34.0346, 151.0536],
  [-34.0345, 151.0528],
  [-34.0343, 151.0522],
  [-34.0339, 151.0516],
  [-34.0311, 151.0491]
];

export const cronulla: Line = {
  name: 'Cronulla',
  state: 'NSW',
  segments: [
    {
      segments: [sutherlandGymea],
      history: {
        opened: {
          date: '1939-12-16',
          tracks: 1,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-04-19',
          tracks: 2
        }]
      }
    },
    {
      segments: [gymeaCaringbah],
      history: {
        opened: {
          date: '1939-12-16',
          tracks: 1,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-07-15',
          tracks: 2
        }]
      }
    },
    {
      segments: [caringbahCronulla],
      history: {
        opened: {
          date: '1939-12-16',
          tracks: 1,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-04-19',
          tracks: 2
        }]
      }
    },
    {
      segments: [woronoraCemetery],
      history: {
        opened: {
          date: '1900-06-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1947-05-24',
          status: 'closed'
        }]
      }
    }
  ]
};
