import { Line } from "../../trackTypes";

const swanViewTunnelWestApproach = [
  [-31.8857, 116.0659],
  [-31.8855, 116.0664],
  [-31.8853, 116.0667],
  [-31.8850, 116.0672],
  [-31.8844, 116.0677],
  [-31.8840, 116.0681]
];

const swanViewTunnel = [
  [-31.8840, 116.0681],
  [-31.8822, 116.0709]
];

const swanViewTunnelEastApproach = [
  [-31.8822, 116.0709],
  [-31.8816, 116.0717]
];

const tunnelDeviation = [
  [-31.8857, 116.0659],
  [-31.8853, 116.0663],
  [-31.8849, 116.0667],
  [-31.8840, 116.0668],
  [-31.8829, 116.0664],
  [-31.8824, 116.0664],
  [-31.8819, 116.0666],
  [-31.8814, 116.0672],
  [-31.8812, 116.0678],
  [-31.8812, 116.0687],
  [-31.8817, 116.0705],
  [-31.8818, 116.0710],
  [-31.8817, 116.0714],
  [-31.8816, 116.0717]
];

const parkervillDeviationSwanViewTunnel = [
  [-31.8965, 116.0359],
  [-31.8939, 116.0408],
  [-31.8935, 116.0420],
  [-31.8931, 116.0465],
  [-31.8933, 116.0476],
  [-31.8936, 116.0483],
  [-31.8943, 116.0491],
  [-31.8946, 116.0500],
  [-31.8946, 116.0506],
  [-31.8943, 116.0521],
  [-31.8943, 116.0531],
  [-31.8945, 116.0539],
  [-31.8945, 116.0550],
  [-31.8939, 116.0562],
  [-31.8930, 116.0569],
  [-31.8917, 116.0570],
  [-31.8907, 116.0576],
  [-31.8902, 116.0583],
  [-31.8898, 116.0593],
  [-31.8891, 116.0599],
  [-31.8882, 116.0603],
  [-31.8871, 116.0605],
  [-31.8861, 116.0608],
  [-31.8855, 116.0618],
  [-31.8853, 116.0629],
  [-31.8857, 116.0647],
  [-31.8857, 116.0659]
];

const swanViewTunnelParkervillDeviation = [
  [-31.8816, 116.0717],
  [-31.8813, 116.0722],
  [-31.8801, 116.0732],
  [-31.8796, 116.0743],
  [-31.8794, 116.0755],
  [-31.8799, 116.0769],
  [-31.8808, 116.0779],
  [-31.8820, 116.0806],
  [-31.8822, 116.0833],
  [-31.8819, 116.0844],
  [-31.8813, 116.0853],
  [-31.8807, 116.0881],
  [-31.8810, 116.0899],
  [-31.8813, 116.0910],
  [-31.8829, 116.0936],
  [-31.8832, 116.0948],
  [-31.8826, 116.0982],
  [-31.8811, 116.0999],
  [-31.8800, 116.1004],
  [-31.8778, 116.1026],
  [-31.8771, 116.1039],
  [-31.8768, 116.1052],
  [-31.8766, 116.1075],
  [-31.8759, 116.1100],
  [-31.8756, 116.1131],
  [-31.8758, 116.1143],
  [-31.8766, 116.1168],
  [-31.8785, 116.1207],
  [-31.8789, 116.1214],
  [-31.8801, 116.1223],
  [-31.8808, 116.1235],
  [-31.8809, 116.1245],
  [-31.8807, 116.1254],
  [-31.8795, 116.1279],
  [-31.8784, 116.1312],
  [-31.8785, 116.1321],
  [-31.8808, 116.1398],
  [-31.8809, 116.1408],
  [-31.8805, 116.1429],
  [-31.8805, 116.1437],
  [-31.8811, 116.1490],
  [-31.8811, 116.1503],
  [-31.8801, 116.1536],
  [-31.8799, 116.1549],
  [-31.8805, 116.1583],
  [-31.8804, 116.1601],
  [-31.8799, 116.1639],
  [-31.8796, 116.1645],
  [-31.8788, 116.1659],
  [-31.8786, 116.1665],
  [-31.8782, 116.1709],
  [-31.8784, 116.1730],
  [-31.8795, 116.1764],
  [-31.8796, 116.1773],
  [-31.8794, 116.1846],
  [-31.8791, 116.1875],
  [-31.8795, 116.1917],
  [-31.8798, 116.1931],
  [-31.8798, 116.1943],
  [-31.8808, 116.1977],
  [-31.8804, 116.2064],
  [-31.8803, 116.2072]
];

const mountHelenaChidlow = [
  [-31.8803, 116.2072],
  [-31.8800, 116.2087],
  [-31.8800, 116.2094],
  [-31.8804, 116.2104],
  [-31.8804, 116.2120],
  [-31.8758, 116.2210],
  [-31.8754, 116.2220],
  [-31.8721, 116.2353],
  [-31.8629, 116.2666]
];

const chidlowSpencersBrook = [
  [-31.8629, 116.2666],
  [-31.8625, 116.2689],
  [-31.8627, 116.2716],
  [-31.8638, 116.2750],
  [-31.8644, 116.2764],
  [-31.8657, 116.2785],
  [-31.8677, 116.2836],
  [-31.8678, 116.2853],
  [-31.8674, 116.2870],
  [-31.8664, 116.2883],
  [-31.8658, 116.2888],
  [-31.8653, 116.2897],
  [-31.8642, 116.2927],
  [-31.8640, 116.2938],
  [-31.8637, 116.2985],
  [-31.8639, 116.3022],
  [-31.8634, 116.3047],
  [-31.8623, 116.3068],
  [-31.8619, 116.3082],
  [-31.8621, 116.3101],
  [-31.8632, 116.3115],
  [-31.8645, 116.3120],
  [-31.8665, 116.3123],
  [-31.8675, 116.3125],
  [-31.8701, 116.3141],
  [-31.8710, 116.3151],
  [-31.8713, 116.3169],
  [-31.8707, 116.3187],
  [-31.8695, 116.3198],
  [-31.8681, 116.3199],
  [-31.8663, 116.3193],
  [-31.8641, 116.3193],
  [-31.8622, 116.3203],
  [-31.8581, 116.3236],
  [-31.8558, 116.3245],
  [-31.8505, 116.3247],
  [-31.8438, 116.3254],
  [-31.8388, 116.3262],
  [-31.8330, 116.3262],
  [-31.8315, 116.3264],
  [-31.8281, 116.3270],
  [-31.8261, 116.3265],
  [-31.8247, 116.3254],
  [-31.8234, 116.3233],
  [-31.8220, 116.3208],
  [-31.8198, 116.3190],
  [-31.8086, 116.3138],
  [-31.8065, 116.3135],
  [-31.8043, 116.3139],
  [-31.8029, 116.3149],
  [-31.8005, 116.3179],
  [-31.7988, 116.3189],
  [-31.7962, 116.3194],
  [-31.7948, 116.3199],
  [-31.7936, 116.3210],
  [-31.7889, 116.3270],
  [-31.7884, 116.3279],
  [-31.7882, 116.3289],
  [-31.7882, 116.3323],
  [-31.7870, 116.3398],
  [-31.7869, 116.3413],
  [-31.7881, 116.3477],
  [-31.7879, 116.3501],
  [-31.7873, 116.3519],
  [-31.7820, 116.3588],
  [-31.7807, 116.3595],
  [-31.7779, 116.3608],
  [-31.7762, 116.3620],
  [-31.7746, 116.3643],
  [-31.7689, 116.3786],
  [-31.7666, 116.3858],
  [-31.7644, 116.3911],
  [-31.7637, 116.3920],
  [-31.7629, 116.3945],
  [-31.7621, 116.3955],
  [-31.7613, 116.3963],
  [-31.7597, 116.3970],
  [-31.7587, 116.3980],
  [-31.7562, 116.4040],
  [-31.7559, 116.4054],
  [-31.7559, 116.4067],
  [-31.7576, 116.4139],
  [-31.7580, 116.4148],
  [-31.7595, 116.4164],
  [-31.7601, 116.4172],
  [-31.7607, 116.4189],
  [-31.7608, 116.4210],
  [-31.7614, 116.4229],
  [-31.7616, 116.4239],
  [-31.7612, 116.4306],
  [-31.7607, 116.4320],
  [-31.7587, 116.4358],
  [-31.7567, 116.4382],
  [-31.7541, 116.4401],
  [-31.7522, 116.4410],
  [-31.7508, 116.4422],
  [-31.7498, 116.4444],
  [-31.7496, 116.4462],
  [-31.7499, 116.4500],
  [-31.7498, 116.4527],
  [-31.7492, 116.4549],
  [-31.7409, 116.4669],
  [-31.7398, 116.4694],
  [-31.7379, 116.4778],
  [-31.7368, 116.4802],
  [-31.7288, 116.4903],
  [-31.7282, 116.4916],
  [-31.7266, 116.4955],
  [-31.7260, 116.4965],
  [-31.7207, 116.5032],
  [-31.7197, 116.5053],
  [-31.7194, 116.5073],
  [-31.7194, 116.5143],
  [-31.7206, 116.5222],
  [-31.7213, 116.5243],
  [-31.7214, 116.5258],
  [-31.7214, 116.5279],
  [-31.7217, 116.5292],
  [-31.7221, 116.5300],
  [-31.7235, 116.5317],
  [-31.7248, 116.5354],
  [-31.7253, 116.5362],
  [-31.7264, 116.5369],
  [-31.7270, 116.5372],
  [-31.7283, 116.5373],
  [-31.7292, 116.5380],
  [-31.7296, 116.5388],
  [-31.7297, 116.5422],
  [-31.7301, 116.5449],
  [-31.7305, 116.5456],
  [-31.7310, 116.5460],
  [-31.7329, 116.5469],
  [-31.7345, 116.5485],
  [-31.7367, 116.5549],
  [-31.7385, 116.5580],
  [-31.7390, 116.5586],
  [-31.7402, 116.5593],
  [-31.7427, 116.5614],
  [-31.7435, 116.5623],
  [-31.7443, 116.5656],
  [-31.7445, 116.5695],
  [-31.7423, 116.5829],
  [-31.7400, 116.5880],
  [-31.7392, 116.5888],
  [-31.7383, 116.5889],
  [-31.7372, 116.5886],
  [-31.7365, 116.5887],
  [-31.7254, 116.5926],
  [-31.7249, 116.5929],
  [-31.7228, 116.5938],
  [-31.7211, 116.5936],
  [-31.7193, 116.5938],
  [-31.7178, 116.5946],
  [-31.7154, 116.5981],
  [-31.7147, 116.6000],
  [-31.7148, 116.6015],
  [-31.7151, 116.6030],
  [-31.7157, 116.6046],
  [-31.7157, 116.6068],
  [-31.7149, 116.6088],
  [-31.7139, 116.6098],
  [-31.7130, 116.6110],
  [-31.7125, 116.6135],
  [-31.7127, 116.6151],
  [-31.7133, 116.6161],
  [-31.7164, 116.6200],
  [-31.7179, 116.6230],
  [-31.7246, 116.6453]
];

const spencersBrookChidlowJunction = [
  [-31.7246, 116.6453],
  [-31.7249, 116.6491],
  [-31.7231, 116.6549]
];

const spencersBrookJunction = [
  [-31.7246, 116.6453],
  [-31.7255, 116.6476],
  [-31.7293, 116.6538]
];

const spencersBrookNortham = [
  [-31.7231, 116.6549],
  [-31.7219, 116.6592],
  [-31.7210, 116.6605],
  [-31.7198, 116.6610],
  [-31.7186, 116.6609],
  [-31.7149, 116.6590],
  [-31.7128, 116.6584],
  [-31.7006, 116.6566],
  [-31.6985, 116.6557],
  [-31.6836, 116.6439],
  [-31.6820, 116.6431],
  [-31.6789, 116.6422],
  [-31.6772, 116.6424],
  [-31.6758, 116.6433],
  [-31.6666, 116.6530]
];

const northamOld = [
  [-31.6666, 116.6530],
  [-31.6645, 116.6558],
  [-31.6605, 116.6646],
  [-31.6594, 116.6662],
  [-31.6530, 116.6730],
  [-31.6519, 116.6740],
  [-31.6504, 116.6743],
  [-31.6496, 116.6750],
  [-31.6492, 116.6759],
  [-31.6492, 116.6770]
];

const nothamDeviation = [
  [-31.6666, 116.6530],
  [-31.6635, 116.6549],
  [-31.6551, 116.6567],
  [-31.6529, 116.6566],
  [-31.6510, 116.6558],
  [-31.6405, 116.6494],
  [-31.6392, 116.6484]
];

const initialRoute = [
  [-31.8988, 116.0285],
  [-31.9008, 116.0359],
  [-31.9047, 116.0468],
  [-31.9052, 116.0480],
  [-31.9060, 116.0491],
  [-31.9071, 116.0501],
  [-31.9135, 116.0532],
  [-31.9141, 116.0537],
  [-31.9146, 116.0543],
  [-31.9153, 116.0562],
  [-31.9153, 116.0574],
  [-31.9155, 116.0583],
  [-31.9161, 116.0590],
  [-31.9178, 116.0601],
  [-31.9187, 116.0608],
  [-31.9189, 116.0618],
  [-31.9189, 116.0654],
  [-31.9186, 116.0666],
  [-31.9183, 116.0672],
  [-31.9175, 116.0681],
  [-31.9166, 116.0686],
  [-31.9159, 116.0692],
  [-31.9153, 116.0709],
  [-31.9153, 116.0721],
  [-31.9156, 116.0732],
  [-31.9181, 116.0773],
  [-31.9186, 116.0789],
  [-31.9188, 116.0812],
  [-31.9181, 116.0843],
  [-31.9172, 116.0858],
  [-31.9151, 116.0876],
  [-31.9146, 116.0885],
  [-31.9144, 116.0895],
  [-31.9146, 116.0926],
  [-31.9145, 116.0943],
  [-31.9138, 116.0958],
  [-31.9127, 116.0969],
  [-31.9086, 116.1059],
  [-31.9084, 116.1073],
  [-31.9086, 116.1086],
  [-31.9109, 116.1131],
  [-31.9111, 116.1145],
  [-31.9106, 116.1192],
  [-31.9106, 116.1212],
  [-31.9115, 116.1260],
  [-31.9106, 116.1333],
  [-31.9098, 116.1350],
  [-31.9084, 116.1365],
  [-31.9057, 116.1384],
  [-31.9050, 116.1394],
  [-31.9046, 116.1406],
  [-31.9047, 116.1420],
  [-31.9057, 116.1451],
  [-31.9058, 116.1478],
  [-31.9034, 116.1607],
  [-31.9033, 116.1618],
  [-31.9034, 116.1629],
  [-31.9070, 116.1736],
  [-31.9071, 116.1748],
  [-31.9067, 116.1763],
  [-31.9049, 116.1796],
  [-31.9027, 116.1827],
  [-31.9022, 116.1842],
  [-31.9014, 116.1916],
  [-31.9014, 116.1925],
  [-31.9031, 116.2030],
  [-31.9028, 116.2047],
  [-31.9019, 116.2062],
  [-31.8971, 116.2101],
  [-31.8955, 116.2106],
  [-31.8941, 116.2105],
  [-31.8930, 116.2098],
  [-31.8899, 116.2072],
  [-31.8889, 116.2065],
  [-31.8874, 116.2063],
  [-31.8852, 116.2071],
  [-31.8841, 116.2069],
  [-31.8829, 116.2062],
  [-31.8819, 116.2060],
  [-31.8812, 116.2063],
  [-31.8803, 116.2072]
];

const northamNorthamMerredinJunction = [
  [-31.6492, 116.6770],
  [-31.6507, 116.6866],
  [-31.6506, 116.6895],
  [-31.6496, 116.6928]
];

const northamMerredinJunctionSharedEnd = [
  [-31.6496, 116.6928],
  [-31.6493, 116.6959],
  [-31.6501, 116.6993],
  [-31.6518, 116.7016],
  [-31.6553, 116.7050],
  [-31.6571, 116.7077],
  [-31.6598, 116.7135]
];

const northamMeenaarOld = [
  [-31.6598, 116.7135],
  [-31.6606, 116.7187],
  [-31.6613, 116.7213],
  [-31.6626, 116.7233],
  [-31.6638, 116.7264],
  [-31.6640, 116.7299],
  [-31.6599, 116.7410],
  [-31.6579, 116.7429],
  [-31.6449, 116.7503],
  [-31.6434, 116.7518],
  [-31.6358, 116.7645],
  [-31.6348, 116.7673],
  [-31.6349, 116.7832],
  [-31.6344, 116.7867],
  [-31.6333, 116.7916],
  [-31.6336, 116.7943],
  [-31.6349, 116.7973],
  [-31.6378, 116.8000],
  [-31.6392, 116.8024],
  [-31.6396, 116.8050],
  [-31.6390, 116.8071],
  [-31.6335, 116.8182],
  [-31.6329, 116.8206],
  [-31.6332, 116.8223],
  [-31.6344, 116.8255],
  [-31.6353, 116.8278],
  [-31.6357, 116.8298],
  [-31.6367, 116.8316],
  [-31.6385, 116.8335],
  [-31.6394, 116.8354],
  [-31.6407, 116.8406],
  [-31.6410, 116.8433],
  [-31.6403, 116.8463],
  [-31.6395, 116.8507],
  [-31.6396, 116.8558],
  [-31.6392, 116.8585],
  [-31.6379, 116.8615],
  [-31.6352, 116.8647],
  [-31.6340, 116.8674],
  [-31.6334, 116.8741],
  [-31.6325, 116.8768],
  [-31.6322, 116.8799],
  [-31.6345, 116.8862],
  [-31.6395, 116.8923],
  [-31.6404, 116.8942],
  [-31.6407, 116.8961],
  [-31.6409, 116.9103],
  [-31.6411, 116.9121],
  [-31.6457, 116.9254],
  [-31.6458, 116.9280],
  [-31.6446, 116.9312],
  [-31.6427, 116.9331],
  [-31.6418, 116.9344],
  [-31.6410, 116.9373]
];

const northamMeenaarNew = [
  [-31.6598, 116.7135],
  [-31.6607, 116.7156],
  [-31.6646, 116.7271],
  [-31.6647, 116.7344],
  [-31.6627, 116.7397],
  [-31.6606, 116.7424],
  [-31.6460, 116.7555],
  [-31.6442, 116.7577],
  [-31.6408, 116.7641],
  [-31.6400, 116.7668],
  [-31.6397, 116.7701],
  [-31.6400, 116.7740],
  [-31.6399, 116.7764],
  [-31.6378, 116.7904],
  [-31.6384, 116.7944],
  [-31.6400, 116.7971],
  [-31.6430, 116.8000],
  [-31.6445, 116.8024],
  [-31.6453, 116.8062],
  [-31.6443, 116.8109],
  [-31.6423, 116.8140],
  [-31.6397, 116.8162],
  [-31.6377, 116.8200],
  [-31.6375, 116.8237],
  [-31.6415, 116.8382],
  [-31.6418, 116.8411],
  [-31.6399, 116.8577],
  [-31.6386, 116.8614],
  [-31.6356, 116.8667],
  [-31.6345, 116.8702],
  [-31.6329, 116.8835],
  [-31.6337, 116.8888],
  [-31.6372, 116.8970],
  [-31.6379, 116.8995],
  [-31.6412, 116.9308],
  [-31.6410, 116.9373]
];

const meenaarCunderdin = [
  [-31.6410, 116.9373],
  [-31.6393, 116.9757],
  [-31.6389, 116.9782],
  [-31.6368, 116.9838],
  [-31.6311, 116.9989],
  [-31.6233, 117.0144],
  [-31.6219, 117.0164],
  [-31.6158, 117.0229],
  [-31.6135, 117.0267],
  [-31.6126, 117.0305],
  [-31.6126, 117.0514],
  [-31.6169, 117.0802],
  [-31.6414, 117.1885]
];

const cunderdinOld = [
  [-31.6414, 117.1885],
  [-31.6426, 117.1911],
  [-31.6494, 117.2007],
  [-31.6508, 117.2044],
  [-31.6514, 117.2081],
  [-31.6510, 117.2438],
  [-31.6492, 117.2634]
];

const cunderdinNew = [
  [-31.6414, 117.1885],
  [-31.6442, 117.2010],
  [-31.6446, 117.2043],
  [-31.6447, 117.2305],
  [-31.6470, 117.2532],
  [-31.6476, 117.2556],
  [-31.6486, 117.2584],
  [-31.6492, 117.2611],
  [-31.6492, 117.2634]
];

const cunderdinTammin = [
  [-31.6492, 117.2634],
  [-31.6426, 117.3340],
  [-31.6408, 117.3785],
  [-31.6409, 117.4078],
  [-31.6416, 117.4115],
  [-31.6466, 117.4255],
  [-31.6472, 117.4289],
  [-31.6471, 117.4320],
  [-31.6439, 117.4498],
  [-31.6402, 117.5228],
  [-31.6395, 117.5265],
  [-31.6325, 117.5475]
];

const tamminKellerberrinOld = [
  [-31.6325, 117.5475],
  [-31.6271, 117.5647],
  [-31.6263, 117.5689],
  [-31.6206, 117.6247],
  [-31.6208, 117.6287],
  [-31.6215, 117.6327],
  [-31.6217, 117.6386],
  [-31.6255, 117.6522],
  [-31.6316, 117.6636],
  [-31.6364, 117.6813],
  [-31.6368, 117.6851],
  [-31.6351, 117.7028]
];

const tamminKellerberrinNew = [
  [-31.6325, 117.5475],
  [-31.6319, 117.5539],
  [-31.6329, 117.5592],
  [-31.6395, 117.5742],
  [-31.6421, 117.5778],
  [-31.6565, 117.5900],
  [-31.6590, 117.5958],
  [-31.6588, 117.6012],
  [-31.6513, 117.6185],
  [-31.6486, 117.6213],
  [-31.6418, 117.6263],
  [-31.6393, 117.6296],
  [-31.6369, 117.6364],
  [-31.6372, 117.6420],
  [-31.6395, 117.6463],
  [-31.6435, 117.6498],
  [-31.6458, 117.6543],
  [-31.6460, 117.6596],
  [-31.6447, 117.6636],
  [-31.6418, 117.6674],
  [-31.6404, 117.6708],
  [-31.6398, 117.6749],
  [-31.6406, 117.6872],
  [-31.6394, 117.6925],
  [-31.6367, 117.6974],
  [-31.6351, 117.7028]
];

const kellerberrinHinesHill = [
  [-31.6351, 117.7028],
  [-31.6207, 117.8355],
  [-31.6207, 117.8379],
  [-31.6209, 117.8404],
  [-31.6205, 117.8455],
  [-31.6056, 117.8893]
];

const hinesHillMerredinOld = [
  [-31.6056, 117.8893],
  [-31.5910, 117.9332],
  [-31.5822, 117.9523],
  [-31.5809, 117.9584],
  [-31.5823, 117.9996],
  [-31.5821, 118.0022],
  [-31.5797, 118.0114],
  [-31.5773, 118.0158],
  [-31.5756, 118.0175],
  [-31.5725, 118.0205],
  [-31.5616, 118.0412],
  [-31.5595, 118.0442],
  [-31.5445, 118.0579],
  [-31.5425, 118.0606],
  [-31.5243, 118.1146],
  [-31.5235, 118.1215],
  [-31.5256, 118.1380],
  [-31.5250, 118.1434],
  [-31.4937, 118.2371],
  [-31.4885, 118.2476],
  [-31.4817, 118.2612],
  [-31.4813, 118.2625],
  [-31.4813, 118.2632]
];

const hinesHillMerredinNew = [
  [-31.6056, 117.8893],
  [-31.5903, 117.9329],
  [-31.5773, 117.9609],
  [-31.5394, 118.0289],
  [-31.5343, 118.0491],
  [-31.5326, 118.0715],
  [-31.5254, 118.0927],
  [-31.5234, 118.0960],
  [-31.5118, 118.1094],
  [-31.5095, 118.1149],
  [-31.5095, 118.1209],
  [-31.5120, 118.1304],
  [-31.5121, 118.1340],
  [-31.5049, 118.1739],
  [-31.5049, 118.1767],
  [-31.5065, 118.1878],
  [-31.5059, 118.1929],
  [-31.5038, 118.1972],
  [-31.5013, 118.2000],
  [-31.4997, 118.2029],
  [-31.4921, 118.2254],
  [-31.4917, 118.2291],
  [-31.4918, 118.2336],
  [-31.4908, 118.2376],
  [-31.4816, 118.2574],
  [-31.4810, 118.2606],
  [-31.4813, 118.2632]
];

const merredinShared = [
  [-31.4813, 118.2632],
  [-31.4818, 118.2662],
  [-31.4824, 118.2735],
  [-31.4834, 118.2860],
  [-31.4836, 118.2887]
];

const merredinSouthernCrossOld = [
  [-31.4836, 118.2887],
  [-31.4882, 118.3152],
  [-31.4874, 118.3194],
  [-31.4577, 118.3648],
  [-31.4472, 118.3910],
  [-31.4434, 118.3966],
  [-31.4426, 118.3999],
  [-31.4430, 118.4027],
  [-31.4426, 118.4049],
  [-31.4411, 118.4065],
  [-31.4392, 118.4075],
  [-31.4377, 118.4087],
  [-31.4288, 118.4208],
  [-31.4265, 118.4224],
  [-31.4179, 118.4261],
  [-31.4154, 118.4283],
  [-31.4107, 118.4360],
  [-31.4096, 118.4393],
  [-31.4071, 118.4545],
  [-31.3884, 118.5060],
  [-31.3758, 118.5917],
  [-31.3735, 118.5975],
  [-31.3713, 118.6007],
  [-31.3707, 118.6028],
  [-31.3712, 118.6051],
  [-31.3724, 118.6069],
  [-31.3732, 118.6092],
  [-31.3715, 118.6223],
  [-31.3716, 118.6253],
  [-31.3838, 118.7026],
  [-31.3839, 118.7058],
  [-31.3819, 118.7191],
  [-31.3828, 118.7229],
  [-31.3852, 118.7267],
  [-31.3894, 118.7399],
  [-31.3898, 118.7460],
  [-31.3797, 118.8180],
  [-31.3555, 118.9259],
  [-31.3542, 118.9294],
  [-31.3345, 118.9615],
  [-31.3327, 118.9672],
  [-31.3323, 118.9880],
  [-31.3317, 118.9932],
  [-31.3268, 119.0050],
  [-31.3256, 119.0118],
  [-31.3268, 119.0174],
  [-31.3262, 119.0217],
  [-31.3234, 119.0264],
  [-31.3214, 119.0316],
  [-31.3134, 119.0759],
  [-31.3113, 119.0801],
  [-31.3104, 119.0829],
  [-31.3099, 119.0907],
  [-31.3102, 119.0941],
  [-31.3160, 119.1158],
  [-31.3161, 119.1186],
  [-31.3151, 119.1215],
  [-31.3050, 119.1370],
  [-31.2729, 119.2205],
  [-31.2683, 119.2521],
  [-31.2670, 119.2558],
  [-31.2527, 119.2826],
  [-31.2483, 119.2878],
  [-31.2451, 119.2894],
  [-31.2298, 119.2977]
];

const merredinSouthernCrossDeviation = [
  [-31.4836, 118.2887],
  [-31.4873, 118.3124],
  [-31.4871, 118.3173],
  [-31.4856, 118.3212],
  [-31.4830, 118.3244],
  [-31.4740, 118.3304],
  [-31.4711, 118.3314],
  [-31.4495, 118.3344],
  [-31.4449, 118.3368],
  [-31.4418, 118.3409],
  [-31.4344, 118.3617],
  [-31.4338, 118.3673],
  [-31.4350, 118.3719],
  [-31.4378, 118.3773],
  [-31.4389, 118.3827],
  [-31.4374, 118.4054],
  [-31.4355, 118.4111],
  [-31.4307, 118.4178],
  [-31.4270, 118.4203],
  [-31.4229, 118.4210],
  [-31.4196, 118.4201],
  [-31.4139, 118.4165],
  [-31.4090, 118.4154],
  [-31.4052, 118.4165],
  [-31.4007, 118.4199],
  [-31.3982, 118.4261],
  [-31.3981, 118.4305],
  [-31.3989, 118.4341],
  [-31.4022, 118.4422],
  [-31.4028, 118.4477],
  [-31.4018, 118.4521],
  [-31.3995, 118.4559],
  [-31.3953, 118.4601],
  [-31.3926, 118.4650],
  [-31.3726, 118.5289],
  [-31.3685, 118.5571],
  [-31.3684, 118.5617],
  [-31.3711, 118.5812],
  [-31.3735, 118.5892],
  [-31.3748, 118.5919],
  [-31.3772, 118.5947],
  [-31.3789, 118.5974],
  [-31.3890, 118.6187],
  [-31.3902, 118.6226],
  [-31.3902, 118.6268],
  [-31.3862, 118.6531],
  [-31.3868, 118.6591],
  [-31.3966, 118.6821],
  [-31.4047, 118.6943],
  [-31.4064, 118.6989],
  [-31.4066, 118.7033],
  [-31.4058, 118.7068],
  [-31.3984, 118.7238],
  [-31.3962, 118.7265],
  [-31.3927, 118.7279],
  [-31.3894, 118.7274],
  [-31.3844, 118.7248],
  [-31.3804, 118.7246],
  [-31.3774, 118.7266],
  [-31.3754, 118.7298],
  [-31.3751, 118.7344],
  [-31.3770, 118.7454],
  [-31.3768, 118.7499],
  [-31.3754, 118.7539],
  [-31.3683, 118.7659],
  [-31.3669, 118.7697],
  [-31.3608, 118.8011],
  [-31.3565, 118.8304],
  [-31.3566, 118.8366],
  [-31.3587, 118.8423],
  [-31.3709, 118.8618],
  [-31.3723, 118.8652],
  [-31.3748, 118.8811],
  [-31.3746, 118.8860],
  [-31.3735, 118.8892],
  [-31.3651, 118.9047],
  [-31.3636, 118.9094],
  [-31.3610, 118.9534],
  [-31.3537, 118.9850],
  [-31.3536, 118.9904],
  [-31.3581, 119.0053],
  [-31.3583, 119.0107],
  [-31.3564, 119.0160],
  [-31.3530, 119.0195],
  [-31.3492, 119.0212],
  [-31.3328, 119.0233],
  [-31.3282, 119.0254],
  [-31.3251, 119.0291],
  [-31.3236, 119.0331],
  [-31.3234, 119.0369],
  [-31.3250, 119.0567],
  [-31.3246, 119.0620],
  [-31.3230, 119.0663],
  [-31.3134, 119.0831],
  [-31.3096, 119.0871],
  [-31.2963, 119.0953],
  [-31.2904, 119.1016],
  [-31.2790, 119.1246],
  [-31.2769, 119.1306],
  [-31.2660, 119.1817],
  [-31.2636, 119.2332],
  [-31.2570, 119.2708],
  [-31.2551, 119.2768],
  [-31.2522, 119.2822],
  [-31.2471, 119.2879],
  [-31.2315, 119.2963],
  [-31.2298, 119.2977]
];

const southernCrossBoorabbinOld = [
  [-31.2243, 119.3264],
  [-31.2268, 119.3313],
  [-31.2274, 119.3332],
  [-31.2275, 119.3406],
  [-31.2278, 119.3431],
  [-31.2533, 119.4554],
  [-31.2599, 119.4846],
  [-31.2613, 119.4867],
  [-31.2650, 119.4889],
  [-31.2668, 119.4908],
  [-31.2682, 119.4959],
  [-31.2678, 119.4991],
  [-31.2685, 119.5134],
  [-31.2703, 119.5181],
  [-31.2706, 119.5212],
  [-31.2727, 119.5270],
  [-31.2729, 119.5301],
  [-31.2719, 119.5338],
  [-31.2717, 119.5371],
  [-31.2928, 119.6291],
  [-31.2967, 119.6436],
  [-31.2967, 119.6448],
  [-31.2880, 119.6842],
  [-31.2865, 119.6879],
  [-31.2739, 119.8158],
  [-31.2743, 119.8200],
  [-31.2752, 119.8226],
  [-31.2773, 119.8265],
  [-31.2778, 119.8312],
  [-31.2645, 119.9723],
  [-31.2646, 119.9758],
  [-31.2674, 119.9845],
  [-31.2700, 119.9887],
  [-31.2711, 119.9934],
  [-31.2709, 119.9968],
  [-31.2681, 120.0073],
  [-31.2615, 120.0198],
  [-31.2611, 120.0212],
  [-31.2489, 120.0875],
  [-31.2460, 120.0950],
  [-31.2116, 120.2510],
  [-31.2114, 120.2527],
  [-31.2105, 120.2927],
  [-31.2038, 120.3207]
];

const boorabbinKalgoorlieOld = [


];

const boorabbinEsperanceJunction = [
  [-31.2038, 120.3207],
  [-31.1935, 120.3620],
  [-31.1885, 120.3981],
  [-31.1887, 120.4024],
  [-31.1680, 120.5422],
  [-31.1663, 120.5478],
  [-31.1478, 120.5855],
  [-31.1463, 120.5875],
  [-31.1405, 120.5927],
  [-31.1395, 120.5945],
  [-31.0905, 120.6944],
  [-31.0166, 120.8456],
  [-31.0142, 120.8639],
  [-31.0136, 120.8658],
  [-31.0087, 120.8725],
  [-31.0062, 120.8845],
  [-31.0051, 120.8872],
  [-31.0006, 120.8929],
  [-30.9996, 120.8955],
  [-30.9987, 120.8986],
  [-30.9978, 120.9001],
  [-30.9953, 120.9026],
  [-30.9943, 120.9043],
  [-30.9940, 120.9056],
  [-30.9920, 120.9197],
  [-30.9881, 120.9301],
  [-30.9803, 120.9774],
  [-30.9803, 120.9792],
  [-30.9817, 120.9867],
  [-30.9817, 120.9882],
  [-30.9709, 121.0476],
  [-30.9709, 121.0487],
  [-30.9713, 121.0533],
  [-30.9713, 121.0546],
  [-30.9565, 121.1484],
  [-30.9562, 121.1521],
  [-30.9564, 121.1654],
  [-30.9561, 121.1668],
  [-30.9561, 121.1688],
  [-30.9567, 121.1708],
  [-30.9567, 121.1721],
  [-30.9558, 121.1763]
];

const esperanceJunctionKalgoorlieOld = [
  [-30.9558, 121.1763],
  [-30.9555, 121.1775],
  [-30.9544, 121.1798],
  [-30.9534, 121.1807],
  [-30.9490, 121.1834],
  [-30.9394, 121.1887],
  [-30.9379, 121.1892],
  [-30.9339, 121.1901],
  [-30.9309, 121.1918],
  [-30.9105, 121.2078],
  [-30.9092, 121.2092],
  [-30.8795, 121.2501],
  [-30.8467, 121.3064],
  [-30.8428, 121.3104],
  [-30.8194, 121.3516],
  [-30.8083, 121.3683],
  [-30.8052, 121.3751],
  [-30.7850, 121.4088]
];

const oldNewSouthernCrossJunction = [
  [-31.2298, 119.2977],
  [-31.2203, 119.3083],
  [-31.2190, 119.3113],
  [-31.2189, 119.3143],
  [-31.2243, 119.3264]
];

const southernCrossKoolyanobbingJunction = [
  [-31.2298, 119.2977],
  [-31.2117, 119.3131],
  [-31.2103, 119.3139],
  [-31.1710, 119.3331],
  [-31.1671, 119.3335],
  [-31.1460, 119.3296],
  [-31.1430, 119.3299],
  [-31.1377, 119.3318],
  [-31.1333, 119.3320],
  [-31.1289, 119.3311],
  [-31.0842, 119.3388],
  [-31.0011, 119.3387],
  [-30.9828, 119.3428],
  [-30.9775, 119.3452],
  [-30.8655, 119.4303],
  [-30.8590, 119.4390],
  [-30.8567, 119.4472],
  [-30.8559, 119.4679],
  [-30.8530, 119.4778],
  [-30.8326, 119.5072]
];

const koolyanobbingJunctionKalgoorlie = [
  [-30.8326, 119.5072],
  [-30.8230, 119.5212],
  [-30.8210, 119.5253],
  [-30.8207, 119.5295],
  [-30.8235, 119.5820],
  [-30.8264, 119.5912],
  [-30.8323, 119.6005],
  [-30.8349, 119.6118],
  [-30.8327, 119.6426],
  [-30.8408, 119.6877],
  [-30.8429, 119.6927],
  [-30.8462, 119.7015],
  [-30.8449, 119.7107],
  [-30.8406, 119.7190],
  [-30.8394, 119.7263],
  [-30.8396, 119.7962],
  [-30.8418, 119.8238],
  [-30.8526, 119.8749],
  [-30.8490, 119.9496],
  [-30.8530, 119.9784],
  [-30.8555, 119.9839],
  [-30.8665, 119.9999],
  [-30.8684, 120.0054],
  [-30.8768, 120.0754],
  [-30.8762, 120.0799],
  [-30.8744, 120.0837],
  [-30.8473, 120.1267],
  [-30.8336, 120.1529],
  [-30.8273, 120.1700],
  [-30.8265, 120.1768],
  [-30.8275, 120.1809],
  [-30.8345, 120.1971],
  [-30.8365, 120.1996],
  [-30.8408, 120.2040],
  [-30.8425, 120.2097],
  [-30.8409, 120.2158],
  [-30.8302, 120.2287],
  [-30.8283, 120.2327],
  [-30.8279, 120.2362],
  [-30.8297, 120.2726],
  [-30.8295, 120.2766],
  [-30.8183, 120.3460],
  [-30.8190, 120.3530],
  [-30.8647, 120.4510],
  [-30.8658, 120.4580],
  [-30.8637, 120.4989],
  [-30.8643, 120.5054],
  [-30.8747, 120.5599],
  [-30.8747, 120.5665],
  [-30.8727, 120.5889],
  [-30.8621, 120.6250],
  [-30.8616, 120.6307],
  [-30.8632, 120.6399],
  [-30.8624, 120.6450],
  [-30.8534, 120.6720],
  [-30.8533, 120.6782],
  [-30.8555, 120.6839],
  [-30.8560, 120.6883],
  [-30.8540, 120.7285],
  [-30.8560, 120.7357],
  [-30.8594, 120.7405],
  [-30.8782, 120.7571],
  [-30.8809, 120.7615],
  [-30.8826, 120.7654],
  [-30.8853, 120.7693],
  [-30.9031, 120.7840],
  [-30.9057, 120.7879],
  [-30.9131, 120.8063],
  [-30.9134, 120.8109],
  [-30.9127, 120.8145],
  [-30.8879, 120.8805],
  [-30.8885, 120.8860],
  [-30.8840, 120.9770],
  [-30.8806, 121.0428],
  [-30.8792, 121.0487],
  [-30.8429, 121.1382],
  [-30.8415, 121.1558],
  [-30.8404, 121.2829],
  [-30.8397, 121.2887],
  [-30.8367, 121.2993],
  [-30.8191, 121.3355],
  [-30.8156, 121.3463],
  [-30.8019, 121.3693],
  [-30.7850, 121.4088]
];

const westKalgoorlieKalgoorlie = [
  [-30.7850, 121.4088],
  [-30.7704, 121.4343],
  [-30.7663, 121.4431],
  [-30.7645, 121.4457],
  [-30.7456, 121.4670]
];

export const easternGoldfields: Line = {
  name: 'Eastern Goldfields',
  state: 'WA',
  segments: [
    {
      segments: [
        swanViewTunnelWestApproach,
        {
          name: 'Swan View Tunnel',
          segment: swanViewTunnel,
          type: 'tunnel'
        },
        swanViewTunnelEastApproach
      ],
      history: {
        opened: {
          date: '1896-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tunnelDeviation],
      history: {
        opened: {
          date: '1945-11-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parkervillDeviationSwanViewTunnel],
      history: {
        opened: {
          date: '1896-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          tracks: 2
        }, {
          date: '1966-02-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [swanViewTunnelParkervillDeviation],
      history: {
        opened: {
          date: '1896-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          tracks: 2
        }, {
          date: '1966-02-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mountHelenaChidlow],
      history: {
        opened: {
          date: '1884-03-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chidlowSpencersBrook],
      history: {
        opened: {
          date: '1885-06-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [spencersBrookChidlowJunction],
      history: {
        opened: {
          date: '1886-10-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [spencersBrookJunction],
      history: {
        opened: {
          date: '1885-06-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [spencersBrookNortham],
      history: {
        opened: {
          date: '1886-10-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        date: '1966-02-13',
        original: [northamOld],
        deviation: [nothamDeviation]
      }],
      history: {
        opened: {
          date: '1886-10-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [initialRoute],
      history: {
        opened: {
          date: '1884-03-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1965-03-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northamNorthamMerredinJunction],
      history: {
        opened: {
          date: '1894-07-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-14',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [
        northamMerredinJunctionSharedEnd,
        {
          date: '1967-08-10',
          original: [northamMeenaarOld],
          deviation: [northamMeenaarNew]
        },
        meenaarCunderdin,
        {
          date: '1967-08-10',
          original: [cunderdinOld],
          deviation: [cunderdinNew]
        },
        cunderdinTammin,
        {
          date: '1967-08-10',
          original: [tamminKellerberrinOld],
          deviation: [tamminKellerberrinNew]
        },
        kellerberrinHinesHill,
        {
          date: '1967-08-10',
          original: [hinesHillMerredinOld],
          deviation: [hinesHillMerredinNew]
        },
        merredinShared,
        {
          date: '1967-08-10',
          original: [merredinSouthernCrossOld],
          deviation: [merredinSouthernCrossDeviation]
        }
      ],
      history: {
        opened: {
          date: '1894-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-08-10',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [southernCrossBoorabbinOld],
      history: {
        opened: {
          date: '1896-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boorabbinEsperanceJunction],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [esperanceJunctionKalgoorlieOld],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oldNewSouthernCrossJunction],
      history: {
        opened: {
          date: '1894-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southernCrossBoorabbinOld],
      history: {
        opened: {
          date: '1896-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southernCrossKoolyanobbingJunction],
      history: {
        opened: {
          date: '1967-07-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [koolyanobbingJunctionKalgoorlie],
      history: {
        opened: {
          date: '1968-08-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [westKalgoorlieKalgoorlie],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }, {
          date: '1974-01-01',
          gauge: 'standard'
        }]
      }
    }
  ]
};
