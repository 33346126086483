import { Line } from "../../trackTypes";

const koorawathaJunctionGreenethorpe = [
  [-34.0404, 148.5526],
  [-34.0410, 148.5519],
  [-34.0413, 148.5510],
  [-34.0413, 148.5502],
  [-34.0410, 148.5494],
  [-34.0402, 148.5486],
  [-34.0395, 148.5484],
  [-34.0388, 148.5486],
  [-34.0356, 148.5506],
  [-34.0345, 148.5510],
  [-34.0332, 148.5510],
  [-34.0319, 148.5504],
  [-34.0312, 148.5498],
  [-34.0304, 148.5487],
  [-34.0286, 148.5451],
  [-34.0275, 148.5414],
  [-34.0262, 148.5304],
  [-34.0263, 148.5281],
  [-34.0261, 148.5267],
  [-34.0253, 148.5255],
  [-34.0236, 148.5246],
  [-34.0222, 148.5234],
  [-34.0215, 148.5217],
  [-34.0201, 148.5109],
  [-34.0204, 148.5069],
  [-34.0209, 148.5053],
  [-34.0219, 148.5040],
  [-34.0286, 148.4990],
  [-34.0293, 148.4980],
  [-34.0311, 148.4916],
  [-34.0310, 148.4904],
  [-34.0307, 148.4894],
  [-34.0268, 148.4846],
  [-34.0264, 148.4833],
  [-34.0266, 148.4818],
  [-34.0275, 148.4806],
  [-34.0318, 148.4790],
  [-34.0333, 148.4779],
  [-34.0353, 148.4753],
  [-34.0358, 148.4735],
  [-34.0357, 148.4723],
  [-34.0352, 148.4713],
  [-34.0344, 148.4701],
  [-34.0340, 148.4688],
  [-34.0337, 148.4649],
  [-34.0341, 148.4608],
  [-34.0355, 148.4561],
  [-34.0374, 148.4536],
  [-34.0378, 148.4527],
  [-34.0379, 148.4518],
  [-34.0367, 148.4383],
  [-34.0358, 148.4362],
  [-34.0346, 148.4350],
  [-34.0281, 148.4319],
  [-34.0269, 148.4318],
  [-34.0226, 148.4325],
  [-34.0208, 148.4332],
  [-34.0128, 148.4377],
  [-34.0080, 148.4386],
  [-34.0040, 148.4380],
  [-34.0022, 148.4370],
  [-34.0013, 148.4359],
  [-33.9993, 148.4306],
  [-33.9991, 148.4295],
  [-33.9977, 148.4100],
  [-33.9971, 148.4019]
];

const greenthorpeGrenfell = [
  [-33.9971, 148.4019],
  [-33.9966, 148.3935],
  [-33.9961, 148.3914],
  [-33.9954, 148.3890],
  [-33.9952, 148.3879],
  [-33.9953, 148.3869],
  [-33.9960, 148.3831],
  [-33.9960, 148.3801],
  [-33.9941, 148.3488],
  [-33.9945, 148.3463],
  [-33.9945, 148.3446],
  [-33.9940, 148.3430],
  [-33.9695, 148.3164],
  [-33.9617, 148.3053],
  [-33.9608, 148.3044],
  [-33.9542, 148.3021],
  [-33.9532, 148.3015],
  [-33.9377, 148.2866],
  [-33.9280, 148.2709],
  [-33.9199, 148.2621],
  [-33.9187, 148.2597],
  [-33.9152, 148.2455],
  [-33.9153, 148.2215],
  [-33.9161, 148.2182],
  [-33.9192, 148.2122],
  [-33.9203, 148.2086],
  [-33.9205, 148.1980],
  [-33.9212, 148.1949],
  [-33.9226, 148.1922],
  [-33.9231, 148.1901],
  [-33.9239, 148.1802],
  [-33.9235, 148.1783],
  [-33.9202, 148.1730],
  [-33.9198, 148.1714],
  [-33.9201, 148.1700],
  [-33.9208, 148.1684],
  [-33.9208, 148.1669],
  [-33.9203, 148.1657],
  [-33.9192, 148.1647],
  [-33.9140, 148.1635],
  [-33.9124, 148.1638],
  [-33.9098, 148.1654],
  [-33.9065, 148.1659],
  [-33.9052, 148.1654],
  [-33.9042, 148.1641],
  [-33.9039, 148.1623],
  [-33.9046, 148.1599],
  [-33.9047, 148.1578],
  [-33.9035, 148.1551],
  [-33.9024, 148.1542],
  [-33.9013, 148.1540],
  [-33.8947, 148.1552]
];

export const grenfell: Line = {
  name: 'Grenfell',
  state: 'NSW',
  segments: [
    {
      segments: [koorawathaJunctionGreenethorpe],
      history: {
        opened: {
          date: '1901-05-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-10-22',
          status: 'closed'
        }, {
          date: '1992-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [greenthorpeGrenfell],
      history: {
        opened: {
          date: '1901-05-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-10-22',
          status: 'closed'
        }]
      }
    }
  ]
};
