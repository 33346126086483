import { Line } from "../../../../trackTypes";

const footscrayRdSiding = [
  [-37.81118, 144.94027],
  [-37.81152, 144.94092],
  [-37.81195, 144.94167],
  [-37.81289, 144.94331]
];

const waterfrontCity = [
  [-37.81519, 144.93669],
  [-37.81289, 144.94272],
  [-37.81285, 144.94304],
  [-37.81289, 144.94331],
  [-37.81314, 144.94377],
  [-37.81356, 144.94424],
  [-37.81402, 144.94454],
  [-37.81482, 144.94489],
  [-37.81517, 144.94503]
];

const harbourEsplanade = [
  [-37.81517, 144.94503],
  [-37.82016, 144.94720],
  [-37.82101, 144.94744],
  [-37.82236, 144.94779],
  [-37.82250, 144.94789],
  [-37.82255, 144.94809],
  [-37.82243, 144.94903],
  [-37.82184, 144.95132],
  [-37.82182, 144.95162],
  [-37.82185, 144.95228],
  [-37.82183, 144.95250],
  [-37.82102, 144.95528]
];

const spencerStreetExtension = [
  [-37.81495, 144.95227],
  [-37.81694, 144.95318],
  [-37.81724, 144.95333]
];

const spencerStreetHawthornBridge = [
  [-37.81724, 144.95333],
  [-37.81922, 144.95424],
  [-37.82090, 144.95497],
  [-37.82101, 144.95512],
  [-37.82102, 144.95528],
  [-37.81746, 144.96744],
  [-37.81606, 144.97220],
  [-37.81533, 144.97466],
  [-37.81526, 144.97509],
  [-37.81660, 144.98736],
  [-37.81735, 144.98945],
  [-37.81763, 144.99088],
  [-37.81995, 145.01258],
  [-37.82021, 145.01439]
];

const hawthornBridgeHawthorn = [
  [-37.82021, 145.01439],
  [-37.82020, 145.01471],
  [-37.81997, 145.01605],
  [-37.81997, 145.01649],
  [-37.82007, 145.01673],
  [-37.82033, 145.01714],
  [-37.82049, 145.01766],
  [-37.82085, 145.02080],
  [-37.82104, 145.02196],
  [-37.82152, 145.02618],
  [-37.82160, 145.02631],
  [-37.82171, 145.02635],
  [-37.82703, 145.02529],
  [-37.82729, 145.02537],
  [-37.82768, 145.02578],
  [-37.82780, 145.02608],
  [-37.82870, 145.03401],
  [-37.82984, 145.04352]
];

const hawthornBurwood = [
  [-37.82984, 145.04352],
  [-37.83108, 145.05430],
  [-37.83113, 145.05472],
  [-37.83135, 145.05653],
  [-37.83141, 145.05677],
  [-37.83143, 145.05683],
  [-37.83148, 145.05692],
  [-37.84804, 145.07572],
  [-37.84810, 145.07582],
  [-37.84814, 145.07595],
  [-37.85039, 145.09548]
];

const eastBurwood = [
  [-37.85039, 145.09548],
  [-37.85069, 145.09851],
  [-37.85072, 145.09934],
  [-37.85052, 145.10147],
  [-37.85032, 145.10365],
  [-37.84970, 145.10707],
  [-37.84952, 145.10885],
  [-37.84953, 145.11100],
  [-37.85206, 145.13318]
];

const blackburnRoad = [
  [-37.85206, 145.13318],
  [-37.85218, 145.13495],
  [-37.85204, 145.13599],
  [-37.85136, 145.13858],
  [-37.85122, 145.13949],
  [-37.85119, 145.14048],
  [-37.85258, 145.15166]
];

const vermontSouth = [
  [-37.85258, 145.15166],
  [-37.85312, 145.15568],
  [-37.85349, 145.15791],
  [-37.85647, 145.18231]
];

const camberwellDepot = [
  [-37.83108, 145.05430],
  [-37.83105, 145.05445],
  [-37.83094, 145.05456],
  [-37.83015, 145.05484],
  [-37.83003, 145.05484],
  [-37.82992, 145.05479],
  [-37.82982, 145.05473],
  [-37.82916, 145.05394]
];

const camberwellDepotEastJunction = [
  [-37.83113, 145.05472],
  [-37.83106, 145.05459],
  [-37.83094, 145.05456]
];

const hawthornDepot = [
  [-37.82685, 145.02391],
  [-37.82692, 145.02401],
  [-37.82697, 145.02404],
  [-37.82711, 145.02408],
  [-37.82717, 145.02413],
  [-37.82720, 145.02421],
  [-37.82745, 145.02499]
];

export const east1: Line = {
  name: 'East1',
  state: 'VIC',
  segments: [
    {
      segments: [waterfrontCity, footscrayRdSiding],
      history: {
        opened: {
          date: '2005-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [harbourEsplanade],
      history: {
        opened: {
          date: '2003-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [spencerStreetExtension],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [spencerStreetHawthornBridge],
      history: {
        opened: {
          date: '1885-11-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1927-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [hawthornBridgeHawthorn],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1916-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [hawthornBurwood],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [eastBurwood],
      history: {
        opened: {
          date: '1978-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [blackburnRoad],
      history: {
        opened: {
          date: '1993-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [vermontSouth],
      history: {
        opened: {
          date: '2005-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [camberwellDepot, camberwellDepotEastJunction],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [hawthornDepot],
      history: {
        opened: {
          date: '1916-04-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
