import { Line } from "../../../trackTypes";

const portLincolnCummins = [
  [-34.7236, 135.8683],
  [-34.7321, 135.8565],
  [-34.7328, 135.8557],
  [-34.7336, 135.8551],
  [-34.7387, 135.8528],
  [-34.7399, 135.8520],
  [-34.7455, 135.8463],
  [-34.7536, 135.8367],
  [-34.7545, 135.8347],
  [-34.7552, 135.8338],
  [-34.7562, 135.8330],
  [-34.7593, 135.8297],
  [-34.7598, 135.8284],
  [-34.7600, 135.8270],
  [-34.7604, 135.8259],
  [-34.7630, 135.8218],
  [-34.7633, 135.8199],
  [-34.7629, 135.8164],
  [-34.7629, 135.8138],
  [-34.7625, 135.8118],
  [-34.7617, 135.8106],
  [-34.7605, 135.8100],
  [-34.7587, 135.8100],
  [-34.7575, 135.8097],
  [-34.7556, 135.8085],
  [-34.7422, 135.8061],
  [-34.7407, 135.8052],
  [-34.7370, 135.8011],
  [-34.7340, 135.7986],
  [-34.7318, 135.7952],
  [-34.7125, 135.7750],
  [-34.7053, 135.7703],
  [-34.7042, 135.7698],
  [-34.6982, 135.7688],
  [-34.6932, 135.7662],
  [-34.6806, 135.7553],
  [-34.6699, 135.7411],
  [-34.6308, 135.7096],
  [-34.6244, 135.7060],
  [-34.6226, 135.7058],
  [-34.6186, 135.7066],
  [-34.6141, 135.7058],
  [-34.6125, 135.7044],
  [-34.6115, 135.7018],
  [-34.6104, 135.6940],
  [-34.6083, 135.6895],
  [-34.6000, 135.6807],
  [-34.5981, 135.6792],
  [-34.5894, 135.6759],
  [-34.5849, 135.6760],
  [-34.5835, 135.6753],
  [-34.5818, 135.6736],
  [-34.5804, 135.6730],
  [-34.5791, 135.6729],
  [-34.5743, 135.6739],
  [-34.5731, 135.6743],
  [-34.5711, 135.6757],
  [-34.5693, 135.6762],
  [-34.5655, 135.6759],
  [-34.5470, 135.6770],
  [-34.5457, 135.6768],
  [-34.5415, 135.6750],
  [-34.5400, 135.6750],
  [-34.5355, 135.6762],
  [-34.5341, 135.6770],
  [-34.5313, 135.6795],
  [-34.5165, 135.6848],
  [-34.5152, 135.6851],
  [-34.5093, 135.6847],
  [-34.4916, 135.6807],
  [-34.4898, 135.6809],
  [-34.4441, 135.6964],
  [-34.4409, 135.6963],
  [-34.4395, 135.6969],
  [-34.4351, 135.6999],
  [-34.4099, 135.7092],
  [-34.3933, 135.7095],
  [-34.3915, 135.7091],
  [-34.3898, 135.7083],
  [-34.3886, 135.7080],
  [-34.3873, 135.7082],
  [-34.3802, 135.7113],
  [-34.3786, 135.7116],
  [-34.3654, 135.7120],
  [-34.3325, 135.7154],
  [-34.3033, 135.7154],
  [-34.2802, 135.7203],
  [-34.2696, 135.7249]
];

const cumminsYeelanna = [
  [-34.2696, 135.7249],
  [-34.2616, 135.7283],
  [-34.2614, 135.7284],
  [-34.2599, 135.7287],
  [-34.1421, 135.7286],
  [-34.1390, 135.7286]
];

const yeelannaMinnipa = [
  [-34.1390, 135.7286],
  [-34.1232, 135.7287],
  [-34.1183, 135.7302],
  [-34.1160, 135.7302],
  [-34.1095, 135.7285],
  [-34.0505, 135.7286],
  [-34.0418, 135.7310],
  [-34.0393, 135.7311],
  [-34.0268, 135.7297],
  [-34.0203, 135.7285],
  [-34.0070, 135.7285],
  [-33.9891, 135.7259],
  [-33.9858, 135.7259],
  [-33.9738, 135.7239],
  [-33.9693, 135.7248],
  [-33.9678, 135.7247],
  [-33.9667, 135.7242],
  [-33.9641, 135.7226],
  [-33.9565, 135.7209],
  [-33.9534, 135.7192],
  [-33.9517, 135.7187],
  [-33.9414, 135.7196],
  [-33.9395, 135.7192],
  [-33.9386, 135.7193],
  [-33.9368, 135.7197],
  [-33.9338, 135.7192],
  [-33.9285, 135.7164],
  [-33.9238, 135.7116],
  [-33.9220, 135.7106],
  [-33.8916, 135.7066],
  [-33.8724, 135.7064],
  [-33.8675, 135.7049],
  [-33.8573, 135.7034],
  [-33.8252, 135.7061],
  [-33.8209, 135.7058],
  [-33.8121, 135.7068],
  [-33.7997, 135.7119],
  [-33.7920, 135.7131],
  [-33.7608, 135.7143],
  [-33.7547, 135.7153],
  [-33.7527, 135.7149],
  [-33.7512, 135.7138],
  [-33.7476, 135.7093],
  [-33.7460, 135.7083],
  [-33.7410, 135.7070],
  [-33.7393, 135.7072],
  [-33.7287, 135.7101],
  [-33.7274, 135.7101],
  [-33.7261, 135.7096],
  [-33.7119, 135.7004],
  [-33.7100, 135.6997],
  [-33.7025, 135.6989],
  [-33.7006, 135.6982],
  [-33.6965, 135.6944],
  [-33.6951, 135.6939],
  [-33.6932, 135.6944],
  [-33.6919, 135.6956],
  [-33.6856, 135.7092],
  [-33.6930, 135.7112],
  [-33.6673, 135.7146],
  [-33.6546, 135.7129],
  [-33.6529, 135.7132],
  [-33.6473, 135.7162],
  [-33.6455, 135.7166],
  [-33.6437, 135.7166],
  [-33.6417, 135.7172],
  [-33.6365, 135.7200],
  [-33.6349, 135.7214],
  [-33.6266, 135.7311],
  [-33.5893, 135.7555],
  [-33.5876, 135.7560],
  [-33.5625, 135.7587],
  [-33.5605, 135.7582],
  [-33.5508, 135.7515],
  [-33.5258, 135.7264],
  [-33.4988, 135.6757],
  [-33.4975, 135.6742],
  [-33.4949, 135.6728],
  [-33.4801, 135.6672],
  [-33.4753, 135.6631],
  [-33.4592, 135.6570],
  [-33.4414, 135.6487],
  [-33.4307, 135.6469],
  [-33.4232, 135.6444],
  [-33.4216, 135.6444],
  [-33.3969, 135.6493],
  [-33.3696, 135.6508],
  [-33.3660, 135.6496],
  [-33.3593, 135.6492],
  [-33.3569, 135.6482],
  [-33.3360, 135.6313],
  [-33.3334, 135.6302],
  [-33.3137, 135.6260],
  [-33.2971, 135.6264],
  [-33.2954, 135.6258],
  [-33.2940, 135.6247],
  [-33.2843, 135.6136],
  [-33.2789, 135.6067],
  [-33.2771, 135.6052],
  [-33.2637, 135.5982],
  [-33.2614, 135.5978],
  [-33.2382, 135.5986],
  [-33.2334, 135.5970],
  [-33.2282, 135.5940],
  [-33.2164, 135.5819],
  [-33.2144, 135.5809],
  [-33.1399, 135.5591],
  [-33.1384, 135.5582],
  [-33.1311, 135.5521],
  [-33.1056, 135.5266],
  [-33.0919, 135.5142],
  [-33.0904, 135.5122],
  [-33.0789, 135.4932],
  [-33.0408, 135.4527],
  [-33.0214, 135.4285],
  [-33.0207, 135.4262],
  [-33.0193, 135.3929],
  [-33.0187, 135.3910],
  [-32.9895, 135.3379],
  [-32.9499, 135.2770],
  [-32.9189, 135.2158],
  [-32.9146, 135.2041],
  [-32.9129, 135.2015],
  [-32.9102, 135.1992],
  [-32.8928, 135.1908],
  [-32.8912, 135.1903],
  [-32.8846, 135.1900],
  [-32.8794, 135.1877],
  [-32.8770, 135.1853],
  [-32.8656, 135.1655],
  [-32.8541, 135.1523]
];

const minnipaNunjikompita = [
  [-32.8541, 135.1523],
  [-32.8393, 135.1363],
  [-32.8229, 135.1117],
  [-32.7748, 134.9971],
  [-32.7745, 134.9950],
  [-32.7736, 134.9885],
  [-32.7730, 134.9860],
  [-32.7600, 134.9466],
  [-32.7578, 134.9312],
  [-32.7570, 134.9291],
  [-32.7424, 134.9061],
  [-32.7417, 134.9043],
  [-32.7388, 134.8907],
  [-32.7377, 134.8886],
  [-32.7324, 134.8813],
  [-32.7255, 134.8645],
  [-32.7247, 134.8610],
  [-32.7225, 134.8413],
  [-32.7196, 134.8323],
  [-32.7182, 134.8303],
  [-32.6526, 134.7732],
  [-32.6341, 134.7487],
  [-32.6139, 134.7288],
  [-32.5893, 134.7167],
  [-32.5817, 134.7151],
  [-32.5744, 134.7001],
  [-32.5677, 134.6886],
  [-32.5520, 134.6722],
  [-32.5297, 134.6558],
  [-32.5272, 134.6551],
  [-32.5260, 134.6544],
  [-32.5161, 134.6458],
  [-32.5145, 134.6450],
  [-32.4924, 134.6395],
  [-32.4909, 134.6387],
  [-32.4789, 134.6270],
  [-32.4521, 134.5958],
  [-32.4373, 134.5686],
  [-32.4142, 134.5437],
  [-32.4039, 134.5278],
  [-32.3845, 134.5075],
  [-32.3470, 134.4752],
  [-32.3329, 134.4644],
  [-32.3314, 134.4625],
  [-32.3134, 134.4282],
  [-32.2947, 134.3956],
  [-32.2736, 134.3567],
  [-32.2729, 134.3546],
  [-32.2683, 134.3353]
];

const nunjikompitaThevenardJunction = [
  [-32.2683, 134.3353],
  [-32.2660, 134.3259],
  [-32.2561, 134.2888],
  [-32.2233, 134.2018],
  [-32.2230, 134.2001],
  [-32.2192, 134.1644],
  [-32.2188, 134.1630],
  [-32.2034, 134.1306],
  [-32.2001, 134.1166],
  [-32.1993, 134.1145],
  [-32.1660, 134.0525],
  [-32.1219, 133.9949],
  [-32.0897, 133.9329],
  [-32.0574, 133.9003],
  [-32.0500, 133.8863],
  [-32.0492, 133.8838],
  [-32.0480, 133.8769],
  [-32.0469, 133.8739],
  [-32.0413, 133.8635],
  [-32.0404, 133.8609],
  [-32.0381, 133.8485],
  [-32.0383, 133.8461],
  [-32.0389, 133.8443],
  [-32.0492, 133.8300],
  [-32.0586, 133.8240],
  [-32.0603, 133.8224],
  [-32.0656, 133.8137],
  [-32.0909, 133.7580],
  [-32.1197, 133.7080],
  [-32.1201, 133.7067],
  [-32.1202, 133.7055],
  [-32.1185, 133.6911],
  [-32.1189, 133.6899],
  [-32.1197, 133.6889],
  [-32.1210, 133.6883]
];

const theverand = [
  [-32.1210, 133.6883],
  [-32.1246, 133.6882],
  [-32.1259, 133.6876],
  [-32.1270, 133.6861],
  [-32.1275, 133.6849],
  [-32.1275, 133.6805],
  [-32.1280, 133.6787],
  [-32.1292, 133.6767],
  [-32.1390, 133.6670],
  [-32.1462, 133.6575],
  [-32.1468, 133.6560],
  [-32.1470, 133.6550],
  [-32.1478, 133.6495]
];

const wandanaPenongJunction = [
  [-32.0574, 133.9003],
  [-32.0533, 133.8954],
  [-32.0463, 133.8817],
  [-32.0455, 133.8810],
  [-32.0317, 133.8772],
  [-32.0287, 133.8743],
  [-32.0213, 133.8624],
  [-32.0205, 133.8617],
  [-32.0065, 133.8544],
  [-32.0049, 133.8527],
  [-31.9969, 133.8381],
  [-31.9870, 133.8281],
  [-31.9860, 133.8265],
  [-31.9827, 133.8196],
  [-31.9819, 133.8174],
  [-31.9760, 133.7573],
  [-31.9498, 133.6756],
  [-31.9496, 133.6737],
  [-31.9500, 133.5776],
  [-31.9505, 133.5757],
  [-31.9589, 133.5587],
  [-31.9647, 133.5313],
  [-31.9731, 133.4615],
  [-31.9690, 133.4053],
  [-31.9648, 133.3503],
  [-31.9686, 133.2462],
  [-31.9726, 133.2215],
  [-31.9727, 133.2198],
  [-31.9501, 133.0809]
];

const kevinKowulka = [
  [-31.9521, 133.0823],
  [-31.9514, 133.0827],
  [-31.9510, 133.0832],
  [-31.9508, 133.0838],
  [-31.9507, 133.0846],

];

const kevinPeongJunction = [
  [-32.1210, 133.6883],
  [-32.1197, 133.6881],
  [-32.1059, 133.6803],
  [-32.1030, 133.6778],
  [-32.1010, 133.6747],
  [-32.0873, 133.6465],
  [-32.0853, 133.6435],
  [-32.0739, 133.6306],
  [-32.0716, 133.6262],
  [-32.0649, 133.6058],
  [-32.0640, 133.6015],
  [-32.0584, 133.4632],
  [-32.0463, 133.1953],
  [-32.0253, 133.0893],
  [-32.0240, 133.0835],
  [-32.0233, 133.0821],
  [-32.0212, 133.0808],
  [-32.0203, 133.0805],
  [-32.0105, 133.0787],
  [-32.0092, 133.0781],
  [-32.0002, 133.0713],
  [-31.9984, 133.0706],
  [-31.9879, 133.0707],
  [-31.9861, 133.0715],
  [-31.9803, 133.0763],
  [-31.9765, 133.0777],
  [-31.9565, 133.0807],
  [-31.9521, 133.0823],
  [-31.9513, 133.0822],
  [-31.9508, 133.0820],
  [-31.9504, 133.0816],
  [-31.9501, 133.0809]
];

const peongJunctionPeong = [
  [-31.9501, 133.0809],
  [-31.9431, 133.0379],
  [-31.9426, 133.0364],
  [-31.9309, 133.0093]
];

const thevenardBalloonLoop = [
  [-32.1478, 133.6495],
  [-32.1480, 133.6488],
  [-32.1486, 133.6483],
  [-32.1493, 133.6480],
  [-32.1502, 133.6482],
  [-32.1508, 133.6488],
  [-32.1511, 133.6497],
  [-32.1508, 133.6507],
  [-32.1502, 133.6513],
  [-32.1487, 133.6517],
  [-32.1482, 133.6521],
  [-32.1479, 133.6525],
  [-32.1477, 133.6530],
  [-32.1476, 133.6538],
  [-32.1473, 133.6545],
  [-32.1470, 133.6550]
];

export const eyrePeninsula: Line = {
  name: 'Eyre Peninsula',
  state: 'SA',
  segments: [
    {
      segments: [portLincolnCummins],
      history: {
        opened: {
          date: '1907-11-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-07-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cumminsYeelanna],
      history: {
        opened: {
          date: '1909-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-07-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [minnipaNunjikompita],
      history: {
        opened: {
          date: '1914-08-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-07-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nunjikompitaThevenardJunction],
      history: {
        opened: {
          date: '1915-02-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-07-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [theverand],
      history: {
        opened: {
          date: '1915-02-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [thevenardBalloonLoop],
      history: {
        opened: {
          date: '1984-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wandanaPenongJunction],
      history: {
        opened: {
          date: '1924-02-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kevinPeongJunction],
      history: {
        opened: {
          date: '1966-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [peongJunctionPeong],
      history: {
        opened: {
          date: '1924-02-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-07-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kevinKowulka],
      history: {
        opened: {
          date: '1950-04-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yeelannaMinnipa],
      history: {
        opened: {
          date: '1913-05-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-07-29',
          status: 'closed'
        }]
      }
    }
  ]
};
