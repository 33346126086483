import { Line } from "../../../../trackTypes";

const stafford = [
  [-27.42062, 153.03412],
  [-27.42053, 153.03405],
  [-27.42046, 153.03397],
  [-27.42034, 153.03332],
  [-27.42013, 153.03245],
  [-27.41945, 153.02737],
  [-27.41941, 153.02723],
  [-27.41931, 153.02707],
  [-27.41923, 153.02685],
  [-27.41915, 153.02633],
  [-27.41910, 153.02626],
  [-27.41904, 153.02623],
  [-27.41201, 153.02739],
  [-27.41192, 153.02734],
  [-27.41187, 153.02724],
  [-27.41097, 153.02061]
];

export const east8: Line = {
  name: 'East 8',
  state: 'QLD',
  segments: [
    {
      segments: [stafford],
      history: {
        opened: {
          date: '1940-01-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
