import { Line } from "../../../trackTypes";

const townsvilleKurukan = [
  [-19.2691, 146.8067],
  [-19.2653, 146.7719],
  [-19.2634, 146.7118],
  [-19.2614, 146.7040],
  [-19.2600, 146.7014],
  [-19.2479, 146.6829],
  [-19.2195, 146.6253],
  [-19.2098, 146.5961],
  [-19.2075, 146.5928],
  [-19.1961, 146.5824]
];

const kurukanRollingstone = [
  [-19.1961, 146.5824],
  [-19.1919, 146.5786],
  [-19.1581, 146.5361],
  [-19.1388, 146.5091],
  [-19.1110, 146.4784],
  [-19.1065, 146.4723],
  [-19.0912, 146.4558],
  [-19.0696, 146.4258],
  [-19.0529, 146.3984],
  [-19.0516, 146.3968],
  [-19.0484, 146.3940],
  [-19.0453, 146.3913]
];

const rollingstoneMoongobulla = [
  [-19.0453, 146.3913],
  [-19.0404, 146.3869],
  [-19.0203, 146.3693],
  [-19.0182, 146.3680],
  [-19.0107, 146.3642],
  [-19.0080, 146.3623],
  [-18.9968, 146.3515],
  [-18.9782, 146.3306]
];

const moongobullaCoolbie = [
  [-18.9782, 146.3306],
  [-18.9718, 146.3234],
  [-18.9700, 146.3214],
  [-18.9602, 146.3076],
  [-18.9565, 146.3002],
  [-18.9504, 146.2912],
  [-18.9494, 146.2891],
  [-18.9479, 146.2846],
  [-18.9467, 146.2823],
  [-18.9419, 146.2761],
  [-18.9166, 146.2557]
];

const coolbieBambaroo = [
  [-18.9166, 146.2557],
  [-18.9123, 146.2522],
  [-18.9097, 146.2491],
  [-18.8985, 146.2398],
  [-18.8968, 146.2383],
  [-18.8797, 146.2322],
  [-18.8769, 146.2301],
  [-18.8752, 146.2276],
  [-18.8621, 146.1971],
  [-18.8613, 146.1955]
];

const bambarooToobanna = [
  [-18.8613, 146.1955],
  [-18.8601, 146.1939],
  [-18.8584, 146.1922],
  [-18.8515, 146.1874],
  [-18.8500, 146.1861],
  [-18.8451, 146.1809],
  [-18.8436, 146.1797],
  [-18.8388, 146.1768],
  [-18.8050, 146.1625],
  [-18.8027, 146.1620],
  [-18.8006, 146.1620],
  [-18.7896, 146.1638],
  [-18.7862, 146.1638],
  [-18.7701, 146.1616],
  [-18.7679, 146.1608],
  [-18.7530, 146.1505],
  [-18.7510, 146.1496],
  [-18.7318, 146.1461],
  [-18.7288, 146.1463],
  [-18.7156, 146.1500]
];

const toobannaIngham = [
  [-18.7156, 146.1500],
  [-18.7132, 146.1507],
  [-18.7108, 146.1511],
  [-18.6777, 146.1541],
  [-18.6754, 146.1545],
  [-18.6609, 146.1592],
  [-18.6590, 146.1595],
  [-18.6525, 146.1602],
  [-18.6510, 146.1607],
  [-18.6499, 146.1614],
  [-18.6467, 146.1648]
];

const inghamLilyPond = [
  [-18.6467, 146.1648],
  [-18.6415, 146.1701],
  [-18.6386, 146.1721],
  [-18.6331, 146.1749],
  [-18.6320, 146.1758],
  [-18.6155, 146.1980],
  [-18.6144, 146.1990],
  [-18.6054, 146.2039],
  [-18.5911, 146.2156]
];

const lilyPondFeluga = [
  [-18.5911, 146.2156],
  [-18.5883, 146.2181],
  [-18.5869, 146.2188],
  [-18.5761, 146.2211],
  [-18.5682, 146.2216],
  [-18.5668, 146.2219],
  [-18.5645, 146.2239],
  [-18.5626, 146.2265],
  [-18.5613, 146.2273],
  [-18.5605, 146.2273],
  [-18.5598, 146.2271],
  [-18.5553, 146.2237],
  [-18.5540, 146.2234],
  [-18.5476, 146.2236],
  [-18.5454, 146.2231],
  [-18.5434, 146.2215],
  [-18.5400, 146.2167],
  [-18.5379, 146.2117],
  [-18.5358, 146.2046],
  [-18.5345, 146.2026],
  [-18.5222, 146.1924],
  [-18.5204, 146.1914],
  [-18.5118, 146.1887],
  [-18.5095, 146.1874],
  [-18.4985, 146.1774],
  [-18.4949, 146.1757],
  [-18.4902, 146.1750],
  [-18.4832, 146.1716],
  [-18.4821, 146.1708],
  [-18.4669, 146.1536],
  [-18.4566, 146.1469],
  [-18.4476, 146.1431],
  [-18.4368, 146.1404],
  [-18.4129, 146.1410],
  [-18.4098, 146.1402],
  [-18.4037, 146.1367],
  [-18.4019, 146.1350],
  [-18.3829, 146.1062],
  [-18.3707, 146.0922],
  [-18.3678, 146.0899],
  [-18.3538, 146.0741],
  [-18.3524, 146.0732],
  [-18.3481, 146.0710],
  [-18.3305, 146.0570],
  [-18.3258, 146.0544],
  [-18.3244, 146.0542],
  [-18.3232, 146.0543],
  [-18.3121, 146.0576],
  [-18.3105, 146.0576],
  [-18.3092, 146.0571],
  [-18.2872, 146.0443],
  [-18.2852, 146.0435],
  [-18.2792, 146.0420],
  [-18.2769, 146.0405],
  [-18.2746, 146.0376],
  [-18.2705, 146.0311],
  [-18.2577, 146.0164],
  [-18.2560, 146.0132],
  [-18.2532, 146.0048],
  [-18.2518, 146.0027],
  [-18.2451, 145.9966],
  [-18.2412, 145.9904],
  [-18.2405, 145.9880],
  [-18.2394, 145.9868],
  [-18.2380, 145.9863],
  [-18.2349, 145.9861],
  [-18.2334, 145.9855],
  [-18.2323, 145.9844],
  [-18.2238, 145.9734],
  [-18.2005, 145.9502],
  [-18.1954, 145.9462],
  [-18.1943, 145.9448],
  [-18.1935, 145.9431],
  [-18.1922, 145.9419],
  [-18.1885, 145.9399],
  [-18.1280, 145.9146],
  [-18.1055, 145.9070],
  [-18.1029, 145.9066],
  [-18.0607, 145.9072],
  [-18.0587, 145.9075],
  [-18.0471, 145.9115],
  [-18.0342, 145.9203],
  [-18.0250, 145.9274],
  [-18.0127, 145.9325],
  [-17.9938, 145.9364],
  [-17.9924, 145.9371],
  [-17.9882, 145.9392],
  [-17.9839, 145.9397],
  [-17.9820, 145.9394],
  [-17.9786, 145.9379],
  [-17.9626, 145.9355],
  [-17.9535, 145.9329],
  [-17.9378, 145.9308],
  [-17.9367, 145.9310],
  [-17.9265, 145.9350],
  [-17.9252, 145.9361],
  [-17.9188, 145.9439],
  [-17.9114, 145.9504],
  [-17.8807, 145.9709]
];

const felugaElArish = [
  [-17.8807, 145.9709],
  [-17.8642, 145.9819],
  [-17.8634, 145.9827],
  [-17.8620, 145.9844],
  [-17.8612, 145.9849],
  [-17.8605, 145.9851],
  [-17.8580, 145.9847],
  [-17.8527, 145.9850],
  [-17.8520, 145.9852],
  [-17.8504, 145.9859],
  [-17.8499, 145.9863],
  [-17.8488, 145.9871],
  [-17.8482, 145.9875],
  [-17.8465, 145.9881],
  [-17.8458, 145.9885],
  [-17.8444, 145.9896],
  [-17.8435, 145.9900],
  [-17.8410, 145.9907],
  [-17.8393, 145.9909],
  [-17.8384, 145.9914],
  [-17.8378, 145.9922],
  [-17.8374, 145.9932],
  [-17.8368, 145.9938],
  [-17.8359, 145.9943],
  [-17.8353, 145.9943],
  [-17.8342, 145.9941],
  [-17.8331, 145.9942],
  [-17.8311, 145.9950],
  [-17.8258, 145.9978],
  [-17.8250, 145.9981],
  [-17.8197, 145.9990],
  [-17.8102, 146.0013]
];

const elArishInnisfail = [
  [-17.8102, 146.0013],
  [-17.7995, 146.0042],
  [-17.7717, 146.0115],
  [-17.7438, 146.0206],
  [-17.7310, 146.0247],
  [-17.7262, 146.0247],
  [-17.7183, 146.0210],
  [-17.7099, 146.0168],
  [-17.7074, 146.0162],
  [-17.6880, 146.0194],
  [-17.6873, 146.0197],
  [-17.6861, 146.0206],
  [-17.6855, 146.0208],
  [-17.6830, 146.0208],
  [-17.6823, 146.0206],
  [-17.6814, 146.0204],
  [-17.6805, 146.0206],
  [-17.6765, 146.0228],
  [-17.6746, 146.0233],
  [-17.6716, 146.0238],
  [-17.6697, 146.0244],
  [-17.6687, 146.0244],
  [-17.6674, 146.0239],
  [-17.6640, 146.0239],
  [-17.6617, 146.0242],
  [-17.6603, 146.0238],
  [-17.6585, 146.0227],
  [-17.6525, 146.0202],
  [-17.6477, 146.0194],
  [-17.6323, 146.0208],
  [-17.6310, 146.0206],
  [-17.5912, 146.0075],
  [-17.5901, 146.0074],
  [-17.5892, 146.0076],
  [-17.5754, 146.0138],
  [-17.5738, 146.0142],
  [-17.5495, 146.0190],
  [-17.5361, 146.0216],
  [-17.5347, 146.0215],
  [-17.5260, 146.0189]
];

const innisfailDaradgee = [
  [-17.5260, 146.0189],
  [-17.5245, 146.0185],
  [-17.5233, 146.0180],
  [-17.5166, 146.0149],
  [-17.5085, 146.0111],
  [-17.5078, 146.0109],
  [-17.5067, 146.0108],
  [-17.5015, 146.0114],
  [-17.5002, 146.0113],
  [-17.4975, 146.0109],
  [-17.4960, 146.0105],
  [-17.4893, 146.0085],
  [-17.4881, 146.0080],
  [-17.4816, 146.0045],
  [-17.4809, 146.0042]
];

const darafgeePawngilly = [
  [-17.4809, 146.0042],
  [-17.4754, 146.0021],
  [-17.4744, 146.0013],
  [-17.4722, 145.9988],
  [-17.4707, 145.9980],
  [-17.4639, 145.9966],
  [-17.4626, 145.9957],
  [-17.4605, 145.9921],
  [-17.4601, 145.9906],
  [-17.4602, 145.9893],
  [-17.4606, 145.9877],
  [-17.4604, 145.9862],
  [-17.4599, 145.9853],
  [-17.4540, 145.9795],
  [-17.4535, 145.9787],
  [-17.4531, 145.9775],
  [-17.4527, 145.9718],
  [-17.4529, 145.9708],
  [-17.4537, 145.9691],
  [-17.4539, 145.9675],
  [-17.4522, 145.9619],
  [-17.4516, 145.9609],
  [-17.4507, 145.9601],
  [-17.4495, 145.9596],
  [-17.4475, 145.9591],
  [-17.4467, 145.9585],
  [-17.4461, 145.9574],
  [-17.4452, 145.9567],
  [-17.4429, 145.9559],
  [-17.4424, 145.9555],
  [-17.4418, 145.9545],
  [-17.4415, 145.9528],
  [-17.4384, 145.9464],
  [-17.4312, 145.9347],
  [-17.4301, 145.9336],
  [-17.4294, 145.9328],
  [-17.4290, 145.9318],
  [-17.4284, 145.9229],
  [-17.4280, 145.9215],
  [-17.4270, 145.9196],
  [-17.4260, 145.9166],
  [-17.4231, 145.9106],
  [-17.4222, 145.9097],
  [-17.4208, 145.9090],
  [-17.4195, 145.9088],
  [-17.4135, 145.9094]
];

const pawngillyBabinda = [
  [-17.4135, 145.9094],
  [-17.3908, 145.9119],
  [-17.3880, 145.9116],
  [-17.3863, 145.9111],
  [-17.3848, 145.9110],
  [-17.3734, 145.9129],
  [-17.3688, 145.9144],
  [-17.3679, 145.9149],
  [-17.3675, 145.9155],
  [-17.3652, 145.9199],
  [-17.3644, 145.9229],
  [-17.3631, 145.9253],
  [-17.3622, 145.9260],
  [-17.3614, 145.9263],
  [-17.3572, 145.9263],
  [-17.3565, 145.9264],
  [-17.3520, 145.9273],
  [-17.3513, 145.9272],
  [-17.3502, 145.9266],
  [-17.3488, 145.9253],
  [-17.3478, 145.9247],
  [-17.3467, 145.9247],
  [-17.3431, 145.9253]
];

const babindaHarveyCreek = [
  [-17.3431, 145.9253],
  [-17.3412, 145.9256],
  [-17.3401, 145.9260],
  [-17.3371, 145.9276],
  [-17.3362, 145.9278],
  [-17.3269, 145.9288],
  [-17.3257, 145.9288],
  [-17.3173, 145.9281],
  [-17.3162, 145.9278],
  [-17.3128, 145.9267],
  [-17.3108, 145.9258],
  [-17.3101, 145.9257],
  [-17.3095, 145.9258],
  [-17.3090, 145.9261],
  [-17.3082, 145.9269],
  [-17.3072, 145.9274],
  [-17.3031, 145.9284],
  [-17.3017, 145.9285],
  [-17.2993, 145.9282],
  [-17.2990, 145.9282],
  [-17.2960, 145.9287],
  [-17.2952, 145.9286],
  [-17.2946, 145.9284],
  [-17.2866, 145.9226],
  [-17.2858, 145.9222],
  [-17.2851, 145.9221],
  [-17.2821, 145.9219],
  [-17.2769, 145.9216],
  [-17.2758, 145.9215],
  [-17.2726, 145.9204],
  [-17.2720, 145.9204],
  [-17.2716, 145.9205],
  [-17.2711, 145.9208],
  [-17.2697, 145.9223],
  [-17.2689, 145.9229],
  [-17.2648, 145.9248],
  [-17.2636, 145.9252],
  [-17.2629, 145.9253]
];

const harveyCreekAloomba = [
  [-17.2629, 145.9253],
  [-17.2615, 145.9252],
  [-17.2605, 145.9248],
  [-17.2597, 145.9244],
  [-17.2577, 145.9229],
  [-17.2571, 145.9226],
  [-17.2565, 145.9226],
  [-17.2560, 145.9227],
  [-17.2545, 145.9234],
  [-17.2534, 145.9237],
  [-17.2459, 145.9243],
  [-17.2454, 145.9243],
  [-17.2449, 145.9241],
  [-17.2432, 145.9233],
  [-17.2426, 145.9231],
  [-17.2419, 145.9231],
  [-17.2409, 145.9233],
  [-17.2401, 145.9233],
  [-17.2332, 145.9218],
  [-17.2322, 145.9215],
  [-17.2237, 145.9170],
  [-17.2143, 145.9104],
  [-17.2135, 145.9100],
  [-17.2082, 145.9079],
  [-17.2069, 145.9071],
  [-17.1975, 145.8987],
  [-17.1967, 145.8978],
  [-17.1907, 145.8910],
  [-17.1897, 145.8903],
  [-17.1833, 145.8874],
  [-17.1761, 145.8830],
  [-17.1753, 145.8822],
  [-17.1745, 145.8810],
  [-17.1740, 145.8806],
  [-17.1711, 145.8788],
  [-17.1697, 145.8785],
  [-17.1668, 145.8786],
  [-17.1654, 145.8784],
  [-17.1641, 145.8778],
  [-17.1631, 145.8770],
  [-17.1582, 145.8710],
  [-17.1563, 145.8679],
  [-17.1561, 145.8670],
  [-17.1559, 145.8661],
  [-17.1556, 145.8653],
  [-17.1551, 145.8646],
  [-17.1543, 145.8640],
  [-17.1538, 145.8634],
  [-17.1527, 145.8613],
  [-17.1478, 145.8540],
  [-17.1472, 145.8534],
  [-17.1332, 145.8411],
  [-17.1313, 145.8399],
  [-17.1305, 145.8396],
  [-17.1240, 145.8371],
  [-17.1103, 145.8336]
];

const aloombaGordonvale = [
  [-17.1103, 145.8336],
  [-17.1092, 145.8333],
  [-17.1086, 145.8329],
  [-17.1082, 145.8324],
  [-17.1081, 145.8317],
  [-17.1095, 145.8231],
  [-17.1094, 145.8222],
  [-17.1044, 145.8105],
  [-17.1039, 145.8098],
  [-17.1037, 145.8090],
  [-17.1037, 145.8082],
  [-17.1045, 145.8054],
  [-17.1046, 145.8043],
  [-17.1039, 145.8014],
  [-17.1030, 145.7989],
  [-17.1022, 145.7975],
  [-17.1019, 145.7968],
  [-17.0998, 145.7902],
  [-17.0993, 145.7892],
  [-17.0986, 145.7884],
  [-17.0975, 145.7878],
  [-17.0963, 145.7876],
  [-17.0931, 145.7879]
];

const gordonvaleCairnsDeviationStart = [
  [-17.0931, 145.7879],
  [-17.0901, 145.7882],
  [-17.0895, 145.7881],
  [-17.0889, 145.7877],
  [-17.0884, 145.7871],
  [-17.0853, 145.7781],
  [-17.0849, 145.7774],
  [-17.0843, 145.7770],
  [-17.0816, 145.7758],
  [-17.0808, 145.7757],
  [-17.0756, 145.7758],
  [-17.0743, 145.7754],
  [-17.0694, 145.7727],
  [-17.0633, 145.7711],
  [-17.0602, 145.7698],
  [-17.0592, 145.7695],
  [-17.0473, 145.7682],
  [-17.0463, 145.7679],
  [-17.0416, 145.7660],
  [-17.0409, 145.7655],
  [-17.0404, 145.7647],
  [-17.0394, 145.7607],
  [-17.0386, 145.7591],
  [-17.0326, 145.7519],
  [-17.0305, 145.7505],
  [-17.0283, 145.7497],
  [-17.0270, 145.7489],
  [-17.0254, 145.7478],
  [-17.0243, 145.7472],
  [-17.0177, 145.7448],
  [-17.0163, 145.7446],
  [-17.0123, 145.7445],
  [-17.0051, 145.7447],
  [-17.0039, 145.7446],
  [-17.0003, 145.7438],
  [-16.9989, 145.7440],
  [-16.9979, 145.7439],
  [-16.9952, 145.7429],
  [-16.9905, 145.7419],
  [-16.9896, 145.7419],
  [-16.9776, 145.7440],
  [-16.9770, 145.7442],
  [-16.9758, 145.7448],
  [-16.9750, 145.7449],
  [-16.9743, 145.7447],
  [-16.9737, 145.7444],
  [-16.9730, 145.7443],
  [-16.9723, 145.7444],
  [-16.9677, 145.7471],
  [-16.9668, 145.7473],
  [-16.9623, 145.7473],
  [-16.9611, 145.7475]
];

const cairnsOld = [
  [-16.9611, 145.7475],
  [-16.9558, 145.7476],
  [-16.9545, 145.7472],
  [-16.9531, 145.7464],
  [-16.9521, 145.7461],
  [-16.9512, 145.7465],
  [-16.9470, 145.7502],
  [-16.9461, 145.7508],
  [-16.9440, 145.7520],
  [-16.9431, 145.7527],
  [-16.9269, 145.7732],
  [-16.9264, 145.7734],
  [-16.9259, 145.7734],
  [-16.9256, 145.7732]

];

const cairnsDeviation = [
  [-16.9611, 145.7475],
  [-16.9606, 145.7476],
  [-16.9587, 145.7483],
  [-16.9575, 145.7490],
  [-16.9550, 145.7508],
  [-16.9538, 145.7519],
  [-16.9528, 145.7534],
  [-16.9521, 145.7558],
  [-16.9511, 145.7577],
  [-16.9440, 145.7667],
  [-16.9404, 145.7695],
  [-16.9362, 145.7749],
  [-16.9359, 145.7755],
  [-16.9357, 145.7761],
  [-16.9354, 145.7766],
  [-16.9350, 145.7768],
  [-16.9346, 145.7769],
  [-16.9307, 145.7776],
  [-16.9302, 145.7774],
  [-16.9299, 145.7772],
  [-16.9281, 145.7748],
  [-16.9278, 145.7743],
  [-16.9275, 145.7731],
  [-16.9271, 145.7725],
  [-16.9256, 145.7712]
];

export const northCoastTownsvilleCairns: Line = {
  name: 'North Coast (Townsville - Cairns)',
  state: 'QLD',
  segments: [
    {
      segments: [townsvilleKurukan],
      history: {
        opened: {
          date: '1914-04-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kurukanRollingstone],
      history: {
        opened: {
          date: '1915-04-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [rollingstoneMoongobulla],
      history: {
        opened: {
          date: '1917-05-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [moongobullaCoolbie],
      history: {
        opened: {
          date: '1918-07-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [coolbieBambaroo],
      history: {
        opened: {
          date: '1918-12-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bambarooToobanna],
      history: {
        opened: {
          date: '1919-07-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [toobannaIngham],
      history: {
        opened: {
          date: '1919-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [inghamLilyPond],
      history: {
        opened: {
          date: '1921-01-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [lilyPondFeluga],
      history: {
        opened: {
          date: '1924-12-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [felugaElArish],
      history: {
        opened: {
          date: '1923-12-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [elArishInnisfail],
      history: {
        opened: {
          date: '1922-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [innisfailDaradgee],
      history: {
        opened: {
          date: '1924-12-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [darafgeePawngilly],
      history: {
        opened: {
          date: '1919-09-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pawngillyBabinda],
      history: {
        opened: {
          date: '1912-12-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [babindaHarveyCreek],
      history: {
        opened: {
          date: '1910-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-07-01',
          trackType: 'heavy'
        }]
      }
    },
    {
      segments: [harveyCreekAloomba],
      history: {
        opened: {
          date: '1903-06-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-07-01',
          trackType: 'heavy'
        }]
      }
    },
    {
      segments: [aloombaGordonvale],
      history: {
        opened: {
          date: '1898-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-07-01',
          trackType: 'heavy'
        }]
      }
    },
    {
      segments: [
        gordonvaleCairnsDeviationStart,
        {
          date: '1990-01-01',
          original: [cairnsOld],
          deviation: [cairnsDeviation]
        }
      ],
      history: {
        opened: {
          date: '1897-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-07-01',
          trackType: 'heavy'
        }]
      }
    }
  ]
}
