import { Line } from "../../trackTypes";

const line = [
  [-33.1087, 115.8825],
  [-33.1076, 115.8837],
  [-33.1029, 115.8957],
  [-33.1028, 115.8991],
  [-33.1024, 115.9023],
  [-33.1026, 115.9038],
  [-33.1032, 115.9048],
  [-33.1044, 115.9052],
  [-33.1050, 115.9051],
  [-33.1067, 115.9043],
  [-33.1076, 115.9044],
  [-33.1084, 115.9053],
  [-33.1088, 115.9071],
  [-33.1095, 115.9081],
  [-33.1128, 115.9097],
  [-33.1136, 115.9099],
  [-33.1151, 115.9096],
  [-33.1179, 115.9080],
  [-33.1187, 115.9079],
  [-33.1203, 115.9082],
  [-33.1254, 115.9076],
  [-33.1279, 115.9067],
  [-33.1297, 115.9049],
  [-33.1306, 115.9046],
  [-33.1321, 115.9050],
  [-33.1349, 115.9044],
  [-33.1376, 115.9053],
  [-33.1387, 115.9065],
  [-33.1406, 115.9075],
  [-33.1413, 115.9088],
  [-33.1411, 115.9102],
  [-33.1403, 115.9115],
  [-33.1401, 115.9123],
  [-33.1404, 115.9148],
  [-33.1409, 115.9157],
  [-33.1418, 115.9162],
  [-33.1455, 115.9157],
  [-33.1467, 115.9165],
  [-33.1473, 115.9182],
  [-33.1467, 115.9223],
  [-33.1455, 115.9239],
  [-33.1454, 115.9252],
  [-33.1458, 115.9261],
  [-33.1474, 115.9271],
  [-33.1495, 115.9267],
  [-33.1514, 115.9274],
  [-33.1531, 115.9290],
  [-33.1536, 115.9311],
  [-33.1536, 115.9341],
  [-33.1539, 115.9361],
  [-33.1554, 115.9375],
  [-33.1572, 115.9385],
  [-33.1574, 115.9398],
  [-33.1571, 115.9413],
  [-33.1570, 115.9426],
  [-33.1558, 115.9518],
  [-33.1550, 115.9540],
  [-33.1538, 115.9560],
  [-33.1527, 115.9593],
  [-33.1527, 115.9619],
  [-33.1514, 115.9647],
  [-33.1513, 115.9676],
  [-33.1507, 115.9693],
  [-33.1506, 115.9705],
  [-33.1514, 115.9717],
  [-33.1530, 115.9723],
  [-33.1542, 115.9736],
  [-33.1566, 115.9819],
  [-33.1572, 115.9830],
  [-33.1582, 115.9836],
  [-33.1590, 115.9837],
  [-33.1598, 115.9844],
  [-33.1600, 115.9857],
  [-33.1590, 115.9887],
  [-33.1588, 115.9938],
  [-33.1572, 115.9981],
  [-33.1570, 115.9992],
  [-33.1575, 116.0002],
  [-33.1605, 116.0021],
  [-33.1612, 116.0032],
  [-33.1621, 116.0068],
  [-33.1622, 116.0080],
  [-33.1611, 116.0121],
  [-33.1607, 116.0171],
  [-33.1618, 116.0257],
  [-33.1641, 116.0299],
  [-33.1666, 116.0371],
  [-33.1667, 116.0390],
  [-33.1654, 116.0496],
  [-33.1655, 116.0511],
  [-33.1668, 116.0567],
  [-33.1669, 116.0583],
  [-33.1656, 116.0671],
  [-33.1657, 116.0690],
  [-33.1668, 116.0724],
  [-33.1690, 116.0759],
  [-33.1702, 116.0771],
  [-33.1724, 116.0802],
  [-33.1725, 116.0818],
  [-33.1721, 116.0834],
  [-33.1657, 116.0910],
  [-33.1478, 116.1216],
  [-33.1467, 116.1229],
  [-33.1439, 116.1237],
  [-33.1418, 116.1250],
  [-33.1381, 116.1302],
  [-33.1368, 116.1311],
  [-33.1349, 116.1319],
  [-33.1335, 116.1340],
  [-33.1336, 116.1365],
  [-33.1352, 116.1407],
  [-33.1344, 116.1429],
  [-33.1344, 116.1450],
  [-33.1358, 116.1473],
  [-33.1364, 116.1503],
  [-33.1374, 116.1519],
  [-33.1410, 116.1547]
];

export const wokalupMornington: Line = {
  name: 'Wokalup - Mornington',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-09-29',
          status: 'closed'
        }]
      }
    }
  ]
};
