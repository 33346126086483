import { Line } from "../../../trackTypes";

const line = [
  [-37.9552, 176.9708],
  [-37.9542, 176.9648],
  [-37.9486, 176.9373],
  [-37.9486, 176.9350],
  [-37.9493, 176.9335],
  [-37.9505, 176.9323],
  [-37.9703, 176.9233],
  [-37.9717, 176.9222],
  [-37.9723, 176.9211],
  [-37.9729, 176.9189],
  [-37.9735, 176.9177],
  [-37.9886, 176.9029],
  [-37.9940, 176.8990],
  [-37.9958, 176.8979],
  [-37.9970, 176.8967],
  [-37.9977, 176.8951],
  [-37.9985, 176.8916],
  [-37.9986, 176.8902],
  [-37.9985, 176.8888]
];

export const whakataneBoardMills: Line = {
  name: 'Whakatane Board Mills',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1939-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2001-12-03',
          status: 'closed'
        }]
      }
    }
  ]
}
