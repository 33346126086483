import { Line } from "../../trackTypes";

//check this one, it potentially should not be in this file
const launcestonLauncestonJunction = [
  [-41.4292, 147.1413],
  [-41.4278, 147.1438],
  [-41.4271, 147.1461],
  [-41.4269, 147.1487],
  [-41.4272, 147.1533],
  [-41.4280, 147.1561],
  [-41.4292, 147.1584],
  [-41.4307, 147.1602],
  [-41.4447, 147.1777],
  [-41.4450, 147.1783],
  [-41.4456, 147.1796],
  [-41.4471, 147.1814],
  [-41.4496, 147.1831],
  [-41.4576, 147.1854],
  [-41.4630, 147.1865],
  [-41.4639, 147.1865],
  [-41.4661, 147.1857],
  [-41.4670, 147.1857],
  [-41.4681, 147.1863],
  [-41.4690, 147.1874],
  [-41.4698, 147.1880],
  [-41.4710, 147.1882],
  [-41.4720, 147.1879],
  [-41.4733, 147.1874],
  [-41.4747, 147.1874],
  [-41.4773, 147.1885],
  [-41.4788, 147.1887],
  [-41.4848, 147.1880],
  [-41.4857, 147.1881],
  [-41.4869, 147.1884],
  [-41.4884, 147.1883],
  [-41.4910, 147.1887],
  [-41.4923, 147.1894],
  [-41.4942, 147.1917],
  [-41.4955, 147.1935],
  [-41.4986, 147.1964],
  [-41.4997, 147.1981],
  [-41.5016, 147.1999],
  [-41.5063, 147.2023],
  [-41.5108, 147.2054],
  [-41.5135, 147.2065],
  [-41.5146, 147.2075],
  [-41.5154, 147.2089],
  [-41.5165, 147.2099],
  [-41.5200, 147.2115],
  [-41.5209, 147.2122],
  [-41.5218, 147.2138],
  [-41.5224, 147.2146],
  [-41.5247, 147.2163],
  [-41.5262, 147.2167],
  [-41.5274, 147.2161],
  [-41.5283, 147.2152],
  [-41.5295, 147.2148],
  [-41.5307, 147.2149],
  [-41.5317, 147.2157],
  [-41.5329, 147.2175],
  [-41.5340, 147.2183],
  [-41.5351, 147.2183],
  [-41.5361, 147.2181],
  [-41.5370, 147.2181],
  [-41.5381, 147.2187],
  [-41.5401, 147.2212],
  [-41.5412, 147.2221],
  [-41.5424, 147.2223],
  [-41.5436, 147.2230],
  [-41.5447, 147.2243],
  [-41.5457, 147.2248],
  [-41.5469, 147.2250],
  [-41.5482, 147.2247],
  [-41.5493, 147.2239],
  [-41.5498, 147.2228],
  [-41.5503, 147.2212],
  [-41.5512, 147.2194],
  [-41.5524, 147.2182],
  [-41.5534, 147.2175],
  [-41.5544, 147.2172]
];

const launcestonJunctionColdWaterCreekJunctionOld = [
  [-41.4286, 147.1421],
  [-41.4282, 147.1423],
  [-41.4276, 147.1422],
  [-41.4260, 147.1407],
  [-41.4257, 147.1407],
  [-41.4255, 147.1407],
  [-41.4222, 147.1421],
  [-41.4211, 147.1422],
  [-41.4203, 147.1425],
  [-41.4195, 147.1431],
  [-41.4103, 147.1480],
  [-41.4081, 147.1504],
  [-41.4075, 147.1506],
  [-41.4069, 147.1505],
  [-41.4062, 147.1496],
  [-41.4042, 147.1477],
  [-41.4030, 147.1465],
  [-41.4024, 147.1454],
  [-41.4020, 147.1444],
  [-41.4017, 147.1442],
  [-41.4012, 147.1435],
  [-41.3985, 147.1423],
  [-41.3966, 147.1420],
  [-41.3957, 147.1420],
  [-41.3951, 147.1418],
  [-41.3942, 147.1409],
  [-41.3936, 147.1405],
  [-41.3911, 147.1399],
  [-41.3906, 147.1396],
  [-41.3897, 147.1385],
  [-41.3891, 147.1383],
  [-41.3886, 147.1384],
  [-41.3882, 147.1390],
  [-41.3878, 147.1403],
  [-41.3873, 147.1409],
  [-41.3867, 147.1409],
  [-41.3863, 147.1404],
  [-41.3859, 147.1383],
  [-41.3859, 147.1368],
  [-41.3857, 147.1360],
  [-41.3853, 147.1355],
  [-41.3845, 147.1352],
  [-41.3842, 147.1348],
  [-41.3840, 147.1344],
  [-41.3837, 147.1331],
  [-41.3834, 147.1327],
  [-41.3831, 147.1324],
  [-41.3778, 147.1308],
  [-41.3764, 147.1302],
  [-41.3758, 147.1302],
  [-41.3751, 147.1306],
  [-41.3746, 147.1316]
];

const launcestonJunctionColdWaterCreekJunctionOldTwo = [
  [-41.3677, 147.1400],
  [-41.3663, 147.1410],
  [-41.3656, 147.1412],
  [-41.3648, 147.1408],
  [-41.3639, 147.1396],
  [-41.3624, 147.1377],
  [-41.3610, 147.1366],
  [-41.3588, 147.1354],
  [-41.3579, 147.1349],
  [-41.3570, 147.1340],
  [-41.3544, 147.1300]
];

const launcestonJunctionColdWaterCreekJunctionOldSharedOne = [
  [-41.3746, 147.1316],
  [-41.3712, 147.1369],
  [-41.3697, 147.1385],
  [-41.3677, 147.1400]
];

const launcestonJunctionColdWaterCreekJunctionOldSharedTwo = [
  [-41.3544, 147.1300],
  [-41.3512, 147.1258],
  [-41.3496, 147.1235],
  [-41.3476, 147.1215],
  [-41.3469, 147.1205],
  [-41.3456, 147.1181],
  [-41.3440, 147.1165],
  [-41.3414, 147.1131]
];

const scottsdaleJunctionTunnel = [
  [-41.3414, 147.1131],
  [-41.3381, 147.1091],
  [-41.3365, 147.1080],
  [-41.3348, 147.1075],
  [-41.3340, 147.1069],
  [-41.3331, 147.1057],
  [-41.3319, 147.1050],
  [-41.3306, 147.1048],
  [-41.3284, 147.1055],
  [-41.3278, 147.1052],
  [-41.3275, 147.1048],
  [-41.3272, 147.1041],
  [-41.3265, 147.1035],
  [-41.3257, 147.1036],
  [-41.3248, 147.1046],
  [-41.3239, 147.1048],
  [-41.3222, 147.1041],
  [-41.3212, 147.1043],
  [-41.3187, 147.1064],
  [-41.3178, 147.1067],
  [-41.3168, 147.1063],
  [-41.3136, 147.1035],
  [-41.3118, 147.1026],
  [-41.3109, 147.1026],
  [-41.3095, 147.1032],
  [-41.3042, 147.1043],
  [-41.3032, 147.1051],
  [-41.3027, 147.1066],
  [-41.3007, 147.1106],
  [-41.3001, 147.1110],
  [-41.2995, 147.1109],
  [-41.2989, 147.1104],
  [-41.2984, 147.1104],
  [-41.2979, 147.1107],
  [-41.2968, 147.1133],
  [-41.2961, 147.1143],
  [-41.2923, 147.1169],
  [-41.2915, 147.1168],
  [-41.2905, 147.1159],
  [-41.2894, 147.1155],
  [-41.2880, 147.1158],
  [-41.2875, 147.1166],
  [-41.2874, 147.1177],
  [-41.2871, 147.1183],
  [-41.2865, 147.1186],
  [-41.2860, 147.1184],
  [-41.2856, 147.1179],
  [-41.2851, 147.1177],
  [-41.2822, 147.1182],
  [-41.2818, 147.1187],
  [-41.2816, 147.1195],
  [-41.2821, 147.1253],
  [-41.2818, 147.1263],
  [-41.2812, 147.1270],
  [-41.2770, 147.1280],
  [-41.2764, 147.1285],
  [-41.2755, 147.1296],
  [-41.2753, 147.1304],
  [-41.2753, 147.1345],
  [-41.2755, 147.1356],
  [-41.2750, 147.1367],
  [-41.2739, 147.1371],
  [-41.2734, 147.1380],
  [-41.2734, 147.1392],
  [-41.2730, 147.1402],
  [-41.2729, 147.1409],
  [-41.2729, 147.1417],
  [-41.2724, 147.1448],
  [-41.2725, 147.1461],
  [-41.2722, 147.1469],
  [-41.2716, 147.1471],
  [-41.2682, 147.1464],
  [-41.2674, 147.1459],
  [-41.2668, 147.1459],
  [-41.2663, 147.1464],
  [-41.2662, 147.1473],
  [-41.2666, 147.1480],
  [-41.2674, 147.1486],
  [-41.2676, 147.1490],
  [-41.2680, 147.1501],
  [-41.2684, 147.1506],
  [-41.2692, 147.1509],
  [-41.2697, 147.1516],
  [-41.2699, 147.1523],
  [-41.2694, 147.1551],
  [-41.2695, 147.1559],
  [-41.2698, 147.1566],
  [-41.2726, 147.1601],
  [-41.2734, 147.1618],
  [-41.2735, 147.1628],
  [-41.2735, 147.1634],
  [-41.2730, 147.1651],
  [-41.2726, 147.1658],
  [-41.2721, 147.1661],
  [-41.2687, 147.1666],
  [-41.2681, 147.1669],
  [-41.2658, 147.1693],
  [-41.2652, 147.1695],
  [-41.2646, 147.1693],
  [-41.2638, 147.1684],
  [-41.2632, 147.1683],
  [-41.2626, 147.1687],
  [-41.2624, 147.1695],
  [-41.2626, 147.1703],
  [-41.2632, 147.1711],
  [-41.2635, 147.1721],
  [-41.2634, 147.1731],
  [-41.2615, 147.1769],
  [-41.2608, 147.1778],
  [-41.2581, 147.1798],
  [-41.2573, 147.1811],
  [-41.2563, 147.1853],
  [-41.2559, 147.1858],
  [-41.2553, 147.1863],
  [-41.2550, 147.1871],
  [-41.2550, 147.1879],
  [-41.2559, 147.1900],
  [-41.2561, 147.1910],
  [-41.2562, 147.1929],
  [-41.2566, 147.1940],
  [-41.2582, 147.1956],
  [-41.2586, 147.1963],
  [-41.2586, 147.1973],
  [-41.2583, 147.1979],
  [-41.2579, 147.1985],
  [-41.2577, 147.1993],
  [-41.2591, 147.2042],
  [-41.2617, 147.2096],
  [-41.2619, 147.2103],
  [-41.2618, 147.2111],
  [-41.2605, 147.2145],
  [-41.2597, 147.2154],
  [-41.2580, 147.2158],
  [-41.2567, 147.2157],
  [-41.2564, 147.2154],
  [-41.2562, 147.2149],
  [-41.2561, 147.2144],
  [-41.2562, 147.2125],
  [-41.2559, 147.2117],
  [-41.2555, 147.2113],
  [-41.2549, 147.2114],
  [-41.2544, 147.2118],
  [-41.2539, 147.2129],
  [-41.2532, 147.2133],
  [-41.2517, 147.2133],
  [-41.2511, 147.2132],
  [-41.2490, 147.2119],
  [-41.2485, 147.2117],
  [-41.2422, 147.2123],
  [-41.2412, 147.2126],
  [-41.2406, 147.2127],
  [-41.2386, 147.2120],
  [-41.2380, 147.2119],
  [-41.2339, 147.2124],
  [-41.2332, 147.2122],
  [-41.2313, 147.2108],
  [-41.2295, 147.2102],
  [-41.2286, 147.2095],
  [-41.2276, 147.2091],
  [-41.2271, 147.2087],
  [-41.2257, 147.2070],
  [-41.2252, 147.2066],
  [-41.2241, 147.2063],
  [-41.2238, 147.2061],
  [-41.2236, 147.2057],
  [-41.2230, 147.2039],
  [-41.2206, 147.2002],
  [-41.2199, 147.1988],
  [-41.2191, 147.1981],
  [-41.2182, 147.1980],
  [-41.2146, 147.1995],
  [-41.2136, 147.1994],
  [-41.2083, 147.1971],
  [-41.2049, 147.1962],
  [-41.2043, 147.1957],
  [-41.2040, 147.1948],
  [-41.2035, 147.1943],
  [-41.2024, 147.1939],
  [-41.2016, 147.1940],
  [-41.2011, 147.1937],
  [-41.2003, 147.1925],
  [-41.1994, 147.1902],
  [-41.1988, 147.1892],
  [-41.1968, 147.1872],
  [-41.1962, 147.1862],
  [-41.1957, 147.1859],
  [-41.1951, 147.1862],
  [-41.1947, 147.1869],
  [-41.1950, 147.1898],
  [-41.1954, 147.1909],
  [-41.1966, 147.1928],
  [-41.1969, 147.1937],
  [-41.1967, 147.1945],
  [-41.1964, 147.1951],
  [-41.1959, 147.1956],
  [-41.1955, 147.1961],
  [-41.1948, 147.1983]
];

const tunnel = [
  [-41.1948, 147.1983],
  [-41.1924, 147.2059]
];

const tunnelScottsdale = [
  [-41.1924, 147.2059],
  [-41.1921, 147.2068],
  [-41.1922, 147.2078],
  [-41.1919, 147.2086],
  [-41.1915, 147.2092],
  [-41.1908, 147.2111],
  [-41.1898, 147.2119],
  [-41.1891, 147.2129],
  [-41.1878, 147.2174],
  [-41.1860, 147.2207],
  [-41.1853, 147.2213],
  [-41.1825, 147.2227],
  [-41.1817, 147.2234],
  [-41.1781, 147.2275],
  [-41.1777, 147.2287],
  [-41.1780, 147.2315],
  [-41.1784, 147.2322],
  [-41.1792, 147.2325],
  [-41.1796, 147.2333],
  [-41.1796, 147.2343],
  [-41.1801, 147.2351],
  [-41.1808, 147.2351],
  [-41.1820, 147.2340],
  [-41.1827, 147.2340],
  [-41.1831, 147.2344],
  [-41.1835, 147.2355],
  [-41.1840, 147.2359],
  [-41.1849, 147.2356],
  [-41.1854, 147.2347],
  [-41.1854, 147.2314],
  [-41.1857, 147.2306],
  [-41.1864, 147.2304],
  [-41.1870, 147.2308],
  [-41.1877, 147.2328],
  [-41.1877, 147.2336],
  [-41.1870, 147.2359],
  [-41.1869, 147.2374],
  [-41.1865, 147.2386],
  [-41.1861, 147.2392],
  [-41.1859, 147.2399],
  [-41.1856, 147.2405],
  [-41.1851, 147.2406],
  [-41.1846, 147.2406],
  [-41.1842, 147.2408],
  [-41.1831, 147.2418],
  [-41.1822, 147.2433],
  [-41.1816, 147.2438],
  [-41.1815, 147.2445],
  [-41.1817, 147.2456],
  [-41.1821, 147.2460],
  [-41.1823, 147.2465],
  [-41.1823, 147.2472],
  [-41.1820, 147.2481],
  [-41.1819, 147.2491],
  [-41.1822, 147.2501],
  [-41.1822, 147.2514],
  [-41.1804, 147.2544],
  [-41.1799, 147.2562],
  [-41.1795, 147.2598],
  [-41.1789, 147.2612],
  [-41.1773, 147.2627],
  [-41.1762, 147.2650],
  [-41.1760, 147.2656],
  [-41.1762, 147.2664],
  [-41.1765, 147.2669],
  [-41.1767, 147.2675],
  [-41.1767, 147.2693],
  [-41.1765, 147.2702],
  [-41.1757, 147.2710],
  [-41.1752, 147.2712],
  [-41.1741, 147.2712],
  [-41.1736, 147.2716],
  [-41.1733, 147.2721],
  [-41.1731, 147.2731],
  [-41.1724, 147.2740],
  [-41.1712, 147.2743],
  [-41.1704, 147.2750],
  [-41.1702, 147.2759],
  [-41.1704, 147.2771],
  [-41.1702, 147.2780],
  [-41.1698, 147.2786],
  [-41.1685, 147.2796],
  [-41.1663, 147.2828],
  [-41.1662, 147.2837],
  [-41.1663, 147.2844],
  [-41.1661, 147.2854],
  [-41.1656, 147.2862],
  [-41.1651, 147.2872],
  [-41.1650, 147.2885],
  [-41.1652, 147.2893],
  [-41.1659, 147.2903],
  [-41.1662, 147.2913],
  [-41.1660, 147.2923],
  [-41.1662, 147.2932],
  [-41.1669, 147.2943],
  [-41.1672, 147.2957],
  [-41.1670, 147.2969],
  [-41.1660, 147.2994],
  [-41.1659, 147.3003],
  [-41.1662, 147.3024],
  [-41.1659, 147.3038],
  [-41.1653, 147.3047],
  [-41.1645, 147.3066],
  [-41.1641, 147.3082],
  [-41.1638, 147.3131],
  [-41.1640, 147.3140],
  [-41.1644, 147.3146],
  [-41.1671, 147.3162],
  [-41.1677, 147.3172],
  [-41.1680, 147.3183],
  [-41.1678, 147.3194],
  [-41.1673, 147.3201],
  [-41.1664, 147.3209],
  [-41.1660, 147.3216],
  [-41.1654, 147.3233],
  [-41.1654, 147.3244],
  [-41.1658, 147.3251],
  [-41.1670, 147.3262],
  [-41.1675, 147.3269],
  [-41.1684, 147.3295],
  [-41.1689, 147.3302],
  [-41.1695, 147.3306],
  [-41.1700, 147.3314],
  [-41.1703, 147.3323],
  [-41.1704, 147.3342],
  [-41.1710, 147.3363],
  [-41.1733, 147.3399],
  [-41.1733, 147.3409],
  [-41.1731, 147.3416],
  [-41.1723, 147.3425],
  [-41.1719, 147.3436],
  [-41.1720, 147.3449],
  [-41.1718, 147.3459],
  [-41.1712, 147.3465],
  [-41.1698, 147.3471],
  [-41.1693, 147.3477],
  [-41.1686, 147.3498],
  [-41.1686, 147.3504],
  [-41.1687, 147.3512],
  [-41.1687, 147.3519],
  [-41.1682, 147.3539],
  [-41.1682, 147.3549],
  [-41.1693, 147.3594],
  [-41.1691, 147.3603],
  [-41.1687, 147.3612],
  [-41.1686, 147.3622],
  [-41.1689, 147.3629],
  [-41.1699, 147.3636],
  [-41.1703, 147.3646],
  [-41.1702, 147.3657],
  [-41.1692, 147.3675],
  [-41.1665, 147.3759],
  [-41.1637, 147.3891],
  [-41.1631, 147.3908],
  [-41.1615, 147.3942],
  [-41.1611, 147.3953],
  [-41.1604, 147.4005],
  [-41.1597, 147.4020],
  [-41.1585, 147.4032],
  [-41.1576, 147.4049],
  [-41.1574, 147.4061],
  [-41.1574, 147.4074],
  [-41.1572, 147.4083],
  [-41.1567, 147.4088],
  [-41.1557, 147.4092],
  [-41.1548, 147.4099],
  [-41.1515, 147.4164],
  [-41.1491, 147.4243],
  [-41.1490, 147.4256],
  [-41.1496, 147.4306],
  [-41.1549, 147.4459],
  [-41.1556, 147.4471],
  [-41.1573, 147.4493],
  [-41.1586, 147.4530],
  [-41.1587, 147.4563],
  [-41.1579, 147.4591],
  [-41.1546, 147.4660],
  [-41.1545, 147.4669],
  [-41.1548, 147.4677],
  [-41.1555, 147.4684],
  [-41.1559, 147.4693],
  [-41.1558, 147.4705],
  [-41.1553, 147.4712],
  [-41.1547, 147.4715],
  [-41.1528, 147.4713],
  [-41.1499, 147.4716],
  [-41.1492, 147.4721],
  [-41.1489, 147.4732],
  [-41.1492, 147.4743],
  [-41.1500, 147.4749],
  [-41.1517, 147.4748],
  [-41.1524, 147.4752],
  [-41.1528, 147.4760],
  [-41.1525, 147.4770],
  [-41.1516, 147.4776],
  [-41.1511, 147.4784],
  [-41.1511, 147.4797],
  [-41.1523, 147.4834],
  [-41.1535, 147.4854],
  [-41.1553, 147.4877],
  [-41.1564, 147.4901],
  [-41.1569, 147.4918],
  [-41.1577, 147.4935],
  [-41.1580, 147.4954],
  [-41.1577, 147.4990],
  [-41.1564, 147.5039],
  [-41.1564, 147.5063],
  [-41.1570, 147.5085],
  [-41.1602, 147.5128]
];

const scottsdaleTonganah = [
  [-41.1602, 147.5128],
  [-41.1606, 147.5133],
  [-41.1621, 147.5139],
  [-41.1658, 147.5135],
  [-41.1670, 147.5130],
  [-41.1675, 147.5131],
  [-41.1689, 147.5140],
  [-41.1713, 147.5142],
  [-41.1719, 147.5139],
  [-41.1726, 147.5130],
  [-41.1735, 147.5129],
  [-41.1748, 147.5139],
  [-41.1760, 147.5144],
  [-41.1765, 147.5153],
  [-41.1766, 147.5161],
  [-41.1762, 147.5170],
  [-41.1748, 147.5176],
  [-41.1723, 147.5194],
  [-41.1719, 147.5202],
  [-41.1716, 147.5215],
  [-41.1716, 147.5258],
  [-41.1731, 147.5290],
  [-41.1742, 147.5299],
  [-41.1747, 147.5307],
  [-41.1749, 147.5318],
  [-41.1741, 147.5345],
  [-41.1743, 147.5354],
  [-41.1748, 147.5360],
  [-41.1759, 147.5363],
  [-41.1765, 147.5371],
  [-41.1767, 147.5383],
  [-41.1769, 147.5392],
  [-41.1815, 147.5457],
  [-41.1818, 147.5467],
  [-41.1818, 147.5487],
  [-41.1822, 147.5498],
  [-41.1852, 147.5539],
  [-41.1876, 147.5555],
  [-41.1901, 147.5590],
  [-41.1904, 147.5601],
  [-41.1905, 147.5621],
  [-41.1903, 147.5634],
  [-41.1898, 147.5643],
  [-41.1895, 147.5653],
  [-41.1891, 147.5676],
  [-41.1884, 147.5687],
  [-41.1875, 147.5694],
  [-41.1872, 147.5703],
  [-41.1873, 147.5711],
  [-41.1872, 147.5717],
  [-41.1869, 147.5722],
  [-41.1866, 147.5727],
  [-41.1864, 147.5739],
  [-41.1851, 147.5763],
  [-41.1851, 147.5772],
  [-41.1857, 147.5778],
  [-41.1862, 147.5780],
  [-41.1867, 147.5783],
  [-41.1873, 147.5791],
  [-41.1883, 147.5836],
  [-41.1885, 147.5842],
  [-41.1897, 147.5861]
];

const tonganahSidings = [
  [-41.1897, 147.5861],
  [-41.1899, 147.5870],
  [-41.1896, 147.5877],
  [-41.1892, 147.5881],
  [-41.1858, 147.5888]
];

const tonganahBraxholm = [
  [-41.1897, 147.5861],
  [-41.1907, 147.5880],
  [-41.1909, 147.5894],
  [-41.1907, 147.5914],
  [-41.1910, 147.5924],
  [-41.1921, 147.5934],
  [-41.1924, 147.5953],
  [-41.1923, 147.5962],
  [-41.1917, 147.5977],
  [-41.1915, 147.5987],
  [-41.1915, 147.6005],
  [-41.1914, 147.6015],
  [-41.1906, 147.6027],
  [-41.1904, 147.6036],
  [-41.1907, 147.6047],
  [-41.1907, 147.6055],
  [-41.1903, 147.6063],
  [-41.1901, 147.6071],
  [-41.1900, 147.6109],
  [-41.1893, 147.6129],
  [-41.1892, 147.6138],
  [-41.1895, 147.6172],
  [-41.1899, 147.6179],
  [-41.1906, 147.6183],
  [-41.1912, 147.6194],
  [-41.1924, 147.6198],
  [-41.1942, 147.6189],
  [-41.1955, 147.6187],
  [-41.1963, 147.6196],
  [-41.1964, 147.6209],
  [-41.1992, 147.6236],
  [-41.2007, 147.6236],
  [-41.2007, 147.6236],
  [-41.2022, 147.6227],
  [-41.2033, 147.6231],
  [-41.2039, 147.6239],
  [-41.2041, 147.6251],
  [-41.2046, 147.6261],
  [-41.2057, 147.6262],
  [-41.2066, 147.6266],
  [-41.2074, 147.6264],
  [-41.2081, 147.6257],
  [-41.2087, 147.6256],
  [-41.2090, 147.6259],
  [-41.2093, 147.6266],
  [-41.2091, 147.6279],
  [-41.2090, 147.6289],
  [-41.2096, 147.6299],
  [-41.2114, 147.6303],
  [-41.2119, 147.6317],
  [-41.2113, 147.6327],
  [-41.2100, 147.6326],
  [-41.2089, 147.6323],
  [-41.2059, 147.6326],
  [-41.2049, 147.6317],
  [-41.2037, 147.6311],
  [-41.2029, 147.6315],
  [-41.2021, 147.6318],
  [-41.2010, 147.6313],
  [-41.1992, 147.6308],
  [-41.1984, 147.6313],
  [-41.1972, 147.6313],
  [-41.1959, 147.6311],
  [-41.1953, 147.6313],
  [-41.1948, 147.6314],
  [-41.1940, 147.6309],
  [-41.1930, 147.6311],
  [-41.1925, 147.6316],
  [-41.1923, 147.6326],
  [-41.1919, 147.6330],
  [-41.1912, 147.6331],
  [-41.1907, 147.6327],
  [-41.1902, 147.6326],
  [-41.1886, 147.6332],
  [-41.1881, 147.6339],
  [-41.1879, 147.6347],
  [-41.1873, 147.6352],
  [-41.1850, 147.6364],
  [-41.1844, 147.6375],
  [-41.1842, 147.6387],
  [-41.1823, 147.6420],
  [-41.1821, 147.6434],
  [-41.1817, 147.6442],
  [-41.1816, 147.6449],
  [-41.1819, 147.6457],
  [-41.1828, 147.6462],
  [-41.1838, 147.6471],
  [-41.1846, 147.6468],
  [-41.1848, 147.6459],
  [-41.1849, 147.6440],
  [-41.1858, 147.6423],
  [-41.1866, 147.6420],
  [-41.1874, 147.6424],
  [-41.1883, 147.6420],
  [-41.1890, 147.6404],
  [-41.1895, 147.6396],
  [-41.1903, 147.6397],
  [-41.1913, 147.6407],
  [-41.1930, 147.6414],
  [-41.1935, 147.6422],
  [-41.1935, 147.6434],
  [-41.1938, 147.6458],
  [-41.1943, 147.6467],
  [-41.1954, 147.6470],
  [-41.1964, 147.6467],
  [-41.1972, 147.6458],
  [-41.1980, 147.6460],
  [-41.1983, 147.6468],
  [-41.1982, 147.6479],
  [-41.1972, 147.6488],
  [-41.1968, 147.6495],
  [-41.1966, 147.6518],
  [-41.1963, 147.6525],
  [-41.1959, 147.6536],
  [-41.1959, 147.6549],
  [-41.1963, 147.6557],
  [-41.1973, 147.6564],
  [-41.1978, 147.6572],
  [-41.1979, 147.6580],
  [-41.1959, 147.6605],
  [-41.1953, 147.6621],
  [-41.1952, 147.6634],
  [-41.1958, 147.6643],
  [-41.1979, 147.6655],
  [-41.1988, 147.6664],
  [-41.2000, 147.6688],
  [-41.2014, 147.6700],
  [-41.2022, 147.6714],
  [-41.2027, 147.6731],
  [-41.2027, 147.6748],
  [-41.2032, 147.6754],
  [-41.2038, 147.6760],
  [-41.2041, 147.6767],
  [-41.2040, 147.6776],
  [-41.2037, 147.6780],
  [-41.2032, 147.6782],
  [-41.2028, 147.6787],
  [-41.2027, 147.6795],
  [-41.2030, 147.6802],
  [-41.2036, 147.6806],
  [-41.2044, 147.6805],
  [-41.2050, 147.6807],
  [-41.2070, 147.6825],
  [-41.2085, 147.6830],
  [-41.2091, 147.6827],
  [-41.2095, 147.6819],
  [-41.2096, 147.6798],
  [-41.2091, 147.6788],
  [-41.2080, 147.6776],
  [-41.2070, 147.6740],
  [-41.2073, 147.6725],
  [-41.2083, 147.6720],
  [-41.2091, 147.6726],
  [-41.2115, 147.6785],
  [-41.2120, 147.6792],
  [-41.2130, 147.6797],
  [-41.2136, 147.6805],
  [-41.2139, 147.6821],
  [-41.2143, 147.6832],
  [-41.2151, 147.6839],
  [-41.2155, 147.6846],
  [-41.2153, 147.6855],
  [-41.2142, 147.6868],
  [-41.2131, 147.6876],
  [-41.2127, 147.6886],
  [-41.2127, 147.6896],
  [-41.2132, 147.6906],
  [-41.2173, 147.6945],
  [-41.2178, 147.6958],
  [-41.2176, 147.6977],
  [-41.2173, 147.6984],
  [-41.2167, 147.6990],
  [-41.2128, 147.7003],
  [-41.2060, 147.7020],
  [-41.2054, 147.7027],
  [-41.2045, 147.7056],
  [-41.2040, 147.7061],
  [-41.2008, 147.7081],
  [-41.2003, 147.7086],
  [-41.1998, 147.7096],
  [-41.1994, 147.7099],
  [-41.1981, 147.7103],
  [-41.1976, 147.7107],
  [-41.1968, 147.7117],
  [-41.1964, 147.7119],
  [-41.1959, 147.7118],
  [-41.1943, 147.7106],
  [-41.1937, 147.7105],
  [-41.1912, 147.7105],
  [-41.1907, 147.7108],
  [-41.1903, 147.7111],
  [-41.1898, 147.7118],
  [-41.1892, 147.7125],
  [-41.1888, 147.7128],
  [-41.1875, 147.7131],
  [-41.1862, 147.7142],
  [-41.1857, 147.7147],
  [-41.1850, 147.7164],
  [-41.1848, 147.7177],
  [-41.1846, 147.7184],
  [-41.1841, 147.7188],
  [-41.1831, 147.7188],
  [-41.1825, 147.7192],
  [-41.1821, 147.7200],
  [-41.1820, 147.7209],
  [-41.1818, 147.7216],
  [-41.1814, 147.7226],
  [-41.1810, 147.7231],
  [-41.1799, 147.7233],
  [-41.1780, 147.7229],
  [-41.1770, 147.7230],
  [-41.1760, 147.7237],
  [-41.1748, 147.7239],
  [-41.1726, 147.7240],
  [-41.1720, 147.7244],
  [-41.1716, 147.7250],
  [-41.1712, 147.7271],
  [-41.1705, 147.7280],
  [-41.1693, 147.7284],
  [-41.1652, 147.7308],
  [-41.1647, 147.7314],
  [-41.1646, 147.7322],
  [-41.1649, 147.7348]
];

const branxholmWinnaleah = [
  [-41.1649, 147.7348],
  [-41.1651, 147.7370],
  [-41.1648, 147.7379],
  [-41.1641, 147.7384],
  [-41.1636, 147.7385],
  [-41.1631, 147.7382],
  [-41.1615, 147.7366],
  [-41.1610, 147.7362],
  [-41.1583, 147.7357],
  [-41.1575, 147.7357],
  [-41.1557, 147.7361],
  [-41.1550, 147.7359],
  [-41.1535, 147.7346],
  [-41.1522, 147.7323],
  [-41.1517, 147.7318],
  [-41.1503, 147.7314],
  [-41.1495, 147.7306],
  [-41.1491, 147.7299],
  [-41.1486, 147.7287],
  [-41.1479, 147.7279],
  [-41.1470, 147.7275],
  [-41.1453, 147.7275],
  [-41.1446, 147.7277],
  [-41.1425, 147.7288],
  [-41.1416, 147.7296],
  [-41.1412, 147.7306],
  [-41.1401, 147.7338],
  [-41.1394, 147.7347],
  [-41.1359, 147.7366],
  [-41.1346, 147.7384],
  [-41.1344, 147.7398],
  [-41.1346, 147.7408],
  [-41.1351, 147.7417],
  [-41.1352, 147.7425],
  [-41.1348, 147.7438],
  [-41.1348, 147.7445],
  [-41.1352, 147.7453],
  [-41.1366, 147.7467],
  [-41.1370, 147.7475],
  [-41.1370, 147.7487],
  [-41.1366, 147.7519],
  [-41.1363, 147.7530],
  [-41.1353, 147.7543],
  [-41.1351, 147.7553],
  [-41.1354, 147.7564],
  [-41.1361, 147.7574],
  [-41.1368, 147.7575],
  [-41.1376, 147.7573],
  [-41.1381, 147.7573],
  [-41.1395, 147.7578],
  [-41.1399, 147.7583],
  [-41.1400, 147.7591],
  [-41.1393, 147.7607],
  [-41.1386, 147.7614],
  [-41.1378, 147.7615],
  [-41.1369, 147.7609],
  [-41.1361, 147.7608],
  [-41.1346, 147.7613],
  [-41.1328, 147.7622],
  [-41.1321, 147.7622],
  [-41.1314, 147.7620],
  [-41.1307, 147.7621],
  [-41.1299, 147.7627],
  [-41.1292, 147.7641],
  [-41.1288, 147.7657],
  [-41.1286, 147.7668],
  [-41.1282, 147.7677],
  [-41.1275, 147.7685],
  [-41.1267, 147.7703],
  [-41.1264, 147.7716],
  [-41.1260, 147.7745],
  [-41.1256, 147.7754],
  [-41.1251, 147.7760],
  [-41.1243, 147.7764],
  [-41.1236, 147.7773],
  [-41.1234, 147.7783],
  [-41.1235, 147.7790],
  [-41.1238, 147.7797],
  [-41.1246, 147.7804],
  [-41.1277, 147.7846],
  [-41.1283, 147.7860],
  [-41.1284, 147.7877],
  [-41.1280, 147.7890],
  [-41.1270, 147.7912],
  [-41.1268, 147.7927],
  [-41.1269, 147.7938],
  [-41.1267, 147.7948],
  [-41.1263, 147.7956],
  [-41.1256, 147.7962],
  [-41.1252, 147.7967],
  [-41.1243, 147.7987],
  [-41.1238, 147.7993],
  [-41.1227, 147.8003],
  [-41.1219, 147.8008],
  [-41.1214, 147.8008],
  [-41.1196, 147.8006],
  [-41.1193, 147.8007],
  [-41.1171, 147.8012],
  [-41.1161, 147.8019],
  [-41.1150, 147.8021],
  [-41.1142, 147.8027],
  [-41.1136, 147.8037],
  [-41.1129, 147.8043],
  [-41.1118, 147.8049],
  [-41.1110, 147.8058],
  [-41.1107, 147.8069],
  [-41.1107, 147.8077],
  [-41.1109, 147.8090],
  [-41.1107, 147.8102],
  [-41.1100, 147.8109],
  [-41.1094, 147.8110],
  [-41.1089, 147.8108],
  [-41.1080, 147.8101],
  [-41.1073, 147.8092],
  [-41.1068, 147.8088],
  [-41.1062, 147.8088],
  [-41.1057, 147.8092],
  [-41.1043, 147.8114],
  [-41.1036, 147.8122],
  [-41.1028, 147.8124],
  [-41.1021, 147.8122],
  [-41.1013, 147.8115],
  [-41.1008, 147.8114],
  [-41.1006, 147.8115],
  [-41.1003, 147.8117],
  [-41.1000, 147.8123],
  [-41.0985, 147.8171],
  [-41.0984, 147.8180],
  [-41.0986, 147.8188],
  [-41.0987, 147.8194],
  [-41.0986, 147.8212],
  [-41.0983, 147.8221],
  [-41.0973, 147.8233]
];

const winnaleahHerrick = [
  [-41.0973, 147.8233],
  [-41.0961, 147.8250],
  [-41.0960, 147.8257],
  [-41.0963, 147.8266],
  [-41.0969, 147.8275],
  [-41.0976, 147.8308],
  [-41.0976, 147.8327],
  [-41.0971, 147.8343],
  [-41.0971, 147.8356],
  [-41.0972, 147.8368],
  [-41.0970, 147.8379],
  [-41.0965, 147.8387],
  [-41.0964, 147.8393],
  [-41.0964, 147.8400],
  [-41.0964, 147.8406],
  [-41.0961, 147.8413],
  [-41.0950, 147.8421],
  [-41.0940, 147.8423],
  [-41.0935, 147.8426],
  [-41.0932, 147.8434],
  [-41.0933, 147.8443],
  [-41.0944, 147.8477],
  [-41.0945, 147.8496],
  [-41.0949, 147.8516],
  [-41.0947, 147.8535],
  [-41.0943, 147.8544],
  [-41.0941, 147.8551],
  [-41.0943, 147.8559],
  [-41.0957, 147.8584],
  [-41.0965, 147.8592],
  [-41.0968, 147.8600],
  [-41.0968, 147.8617],
  [-41.0951, 147.8669],
  [-41.0942, 147.8680],
  [-41.0940, 147.8688],
  [-41.0941, 147.8697],
  [-41.0961, 147.8745],
  [-41.0966, 147.8750],
  [-41.0973, 147.8752],
  [-41.0982, 147.8751],
  [-41.0988, 147.8752],
  [-41.0994, 147.8757],
  [-41.1000, 147.8763],
  [-41.1013, 147.8793]
];

export const northEast: Line = {
  name: 'North East (TAS)',
  state: 'TAS',
  segments: [
    {
      segments: [launcestonJunctionColdWaterCreekJunctionOld, launcestonJunctionColdWaterCreekJunctionOldTwo],
      history: {
        opened: {
          date: '1889-08-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [launcestonJunctionColdWaterCreekJunctionOldSharedOne, launcestonJunctionColdWaterCreekJunctionOldSharedTwo],
      history: {
        opened: {
          date: '1889-08-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [launcestonLauncestonJunction],
      history: {
        opened: {
          date: '1871-02-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'dual'
        }, {
          date: '1890-01-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [
        scottsdaleJunctionTunnel,
        {
          name: 'Tunnel',
          segment: tunnel,
          type: 'tunnel'
        },
        tunnelScottsdale
      ],
      history: {
        opened: {
          date: '1889-08-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [scottsdaleTonganah],
      history: {
        opened: {
          date: '1911-07-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tonganahSidings],
      history: {
        opened: {
          date: '1975-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tonganahBraxholm],
      history: {
        opened: {
          date: '1911-07-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [branxholmWinnaleah],
      history: {
        opened: {
          date: '1919-03-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [winnaleahHerrick],
      history: {
        opened: {
          date: '1919-03-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-10-02',
          status: 'closed'
        }, {
          date: '1994-01-01',
          status: 'closed'
        }, {
          date: '1982-01-25',
          status: 'open'
        }]
      }
    }
  ]
}
