import { Line } from "../../../trackTypes";

const granvilleParramatta = [
  [-33.8314, 151.0099],
  [-33.8305, 151.0089],
  [-33.8294, 151.0082],
  [-33.8270, 151.0072],
  [-33.8264, 151.0072],
  [-33.8254, 151.0072],
  [-33.8242, 151.0073],
  [-33.8228, 151.0077],
  [-33.8216, 151.0078],
  [-33.8206, 151.0077],
  [-33.8192, 151.0071],
  [-33.8184, 151.0064],
  [-33.8177, 151.0056],
  [-33.8171, 151.0048]
];

const granvilleJunction = [
  [-33.8270, 151.0072],
  [-33.8278, 151.0071],
  [-33.8285, 151.0068],
  [-33.8289, 151.0062],
  [-33.8293, 151.0049]
];

const parramattaWestmead = [
  [-33.8171, 151.0048],
  [-33.8167, 151.0035],
  [-33.8156, 150.9984],
  [-33.8152, 150.9974],
  [-33.8144, 150.9961],
  [-33.8134, 150.9952],
  [-33.8117, 150.9940],
  [-33.8109, 150.9931],
  [-33.8099, 150.9916],
  [-33.8084, 150.9879]
];

const westmeadBlacktown = [
  [-33.8084, 150.9879],
  [-33.8079, 150.9861],
  [-33.8077, 150.9836],
  [-33.8078, 150.9820],
  [-33.8082, 150.9786],
  [-33.8082, 150.9768],
  [-33.8079, 150.9749],
  [-33.8063, 150.9706],
  [-33.8047, 150.9668],
  [-33.8040, 150.9647],
  [-33.8034, 150.9608],
  [-33.8029, 150.9591],
  [-33.8020, 150.9571],
  [-33.8006, 150.9555],
  [-33.7982, 150.9536],
  [-33.7955, 150.9525],
  [-33.7877, 150.9515],
  [-33.7845, 150.9504],
  [-33.7822, 150.9491],
  [-33.7801, 150.9473],
  [-33.7779, 150.9447],
  [-33.7760, 150.9414],
  [-33.7747, 150.9379],
  [-33.7742, 150.9351],
  [-33.7740, 150.9307],
  [-33.7744, 150.9240],
  [-33.7742, 150.9215],
  [-33.7731, 150.9176],
  [-33.7717, 150.9151],
  [-33.7702, 150.9128],
  [-33.7692, 150.9103],
  [-33.7691, 150.9098]
];

const blacktownRootyHill = [
  [-33.7691, 150.9098],
  [-33.7686, 150.9074],
  [-33.7676, 150.9035],
  [-33.7636, 150.8791],
  [-33.7633, 150.8740],
  [-33.7638, 150.8686],
  [-33.7644, 150.8661],
  [-33.7714, 150.8451]
];

const rootyHillStMarys = [
  [-33.7714, 150.8451],
  [-33.7724, 150.8406],
  [-33.7723, 150.8368],
  [-33.7689, 150.8163],
  [-33.7620, 150.7751]
];

const stMarysKingswood = [
  [-33.7620, 150.7751],
  [-33.7577, 150.7488],
  [-33.7575, 150.7462],
  [-33.7591, 150.7257],
  [-33.7589, 150.7230],
  [-33.7582, 150.7196]
];

const kingswoodPenrith = [
  [-33.7582, 150.7196],
  [-33.7563, 150.7120],
  [-33.7556, 150.7099],
  [-33.7530, 150.7052],
  [-33.7508, 150.6997],
  [-33.7500, 150.6957]
];

const penrithEmuPlains = [
  [-33.7500, 150.6957],
  [-33.7480, 150.6864],
  [-33.7473, 150.6846],
  [-33.7449, 150.6799],
  [-33.7445, 150.6785],
  [-33.7445, 150.6773],
  [-33.7456, 150.6717]
];

export const emuPlains: Line = {
  name: 'Emu Plains Line',
  state: 'NSW',
  segments: [
    {
      segments: [granvilleParramatta],
      history: {
        opened: {
          date: '1860-07-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1885-01-01',
          tracks: 2
        }, {
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1965-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [granvilleJunction],
      history: {
        opened: {
          date: '1996-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [parramattaWestmead],
      history: {
        opened: {
          date: '1860-07-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-01-01',
          tracks: 2
        }, {
          date: '1955-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1965-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [westmeadBlacktown],
      history: {
        opened: {
          date: '1860-07-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-01-01',
          tracks: 2
        }, {
          date: '1955-01-01',
          electrified: {
             current: 'DC',
             isOverhead: true
          }
        }, {
          date: '1940-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [blacktownRootyHill],
      history: {
        opened: {
          date: '1861-12-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-01-01',
          tracks: 2
        }, {
          date: '1955-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1940-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [rootyHillStMarys],
      history: {
        opened: {
          date: '1862-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-01-01',
          tracks: 2
        }, {
          date: '1955-01-01',
          electrified: {
             current: 'DC',
             isOverhead: true
          }
        }, {
          date: '1980-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [stMarysKingswood],
      history: {
        opened: {
          date: '1862-07-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-01-01',
          tracks: 2
        }, {
          date: '1955-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [kingswoodPenrith],
      history: {
        opened: {
          date: '1863-01-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-01-01',
          tracks: 2
        }, {
          date: '1955-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [penrithEmuPlains],
      history: {
        opened: {
          date: '1867-01-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1902-01-01',
          tracks: 2
        }, {
          date: '1956-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
