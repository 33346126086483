import { Line } from "../../../trackTypes";

const balcluthaFinegand = [
  [-46.2563, 169.7376],
  [-46.2598, 169.7388],
  [-46.2615, 169.7387],
  [-46.2625, 169.7390],
  [-46.2634, 169.7395],
  [-46.2647, 169.7397],
  [-46.2670, 169.7387],
  [-46.2683, 169.7377],
  [-46.2693, 169.7373],
  [-46.2722, 169.7375]
];

const finegandRomahapa = [
  [-46.2722, 169.7375],
  [-46.2871, 169.7386],
  [-46.2901, 169.7391],
  [-46.3105, 169.7481],
  [-46.3115, 169.7480],
  [-46.3120, 169.7473],
  [-46.3137, 169.7452],
  [-46.3149, 169.7443],
  [-46.3196, 169.7431],
  [-46.3271, 169.7428],
  [-46.3280, 169.7425],
  [-46.3333, 169.7377],
  [-46.3345, 169.7373],
  [-46.3398, 169.7383],
  [-46.3407, 169.7382],
  [-46.3415, 169.7377],
  [-46.3435, 169.7349],
  [-46.3439, 169.7339],
  [-46.3441, 169.7330],
  [-46.3449, 169.7316],
  [-46.3485, 169.7283]
];

const romahapaGlenomaru = [
  [-46.3485, 169.7283],
  [-46.3492, 169.7269],
  [-46.3495, 169.7248],
  [-46.3494, 169.7226],
  [-46.3495, 169.7217],
  [-46.3500, 169.7209],
  [-46.3563, 169.7150],
  [-46.3568, 169.7143],
  [-46.3599, 169.7064],
  [-46.3603, 169.7056],
  [-46.3609, 169.7050],
  [-46.3624, 169.7042],
  [-46.3630, 169.7043],
  [-46.3640, 169.7049],
  [-46.3648, 169.7050],
  [-46.3656, 169.7044],
  [-46.3678, 169.7007],
  [-46.3682, 169.6995],
  [-46.3684, 169.6978],
  [-46.3686, 169.6970],
  [-46.3696, 169.6941],
  [-46.3706, 169.6923],
  [-46.3712, 169.6919],
  [-46.3738, 169.6914],
  [-46.3768, 169.6892],
  [-46.3773, 169.6882],
  [-46.3780, 169.6860],
  [-46.3784, 169.6829],
  [-46.3788, 169.6819],
  [-46.3802, 169.6802],
  [-46.3839, 169.6751]
];

const glenomaruHuntsRoadTunnel = [
  [-46.3839, 169.6751],
  [-46.3846, 169.6745],
  [-46.3851, 169.6745],
  [-46.3900, 169.6765],
  [-46.3910, 169.6769],
  [-46.3922, 169.6765],
  [-46.3937, 169.6755],
  [-46.3941, 169.6754],
  [-46.3951, 169.6755],
  [-46.3957, 169.6765],
  [-46.3962, 169.6782],
  [-46.3969, 169.6790],
  [-46.3981, 169.6795],
  [-46.3990, 169.6799],
  [-46.3995, 169.6805],
  [-46.4003, 169.6809],
  [-46.4026, 169.6811],
  [-46.4035, 169.6821],
  [-46.4043, 169.6844],
  [-46.4047, 169.6852],
  [-46.4052, 169.6857],
  [-46.4065, 169.6863],
  [-46.4073, 169.6874],
  [-46.4079, 169.6894],
  [-46.4087, 169.6908],
  [-46.4103, 169.6922],
  [-46.4113, 169.6933],
  [-46.4119, 169.6950],
  [-46.4124, 169.6957],
  [-46.4130, 169.6960],
  [-46.4136, 169.6960],
  [-46.4141, 169.6954],
  [-46.4151, 169.6947]
];

const huntsRoadTunnel = [
  [-46.4151, 169.6947],
  [-46.4171, 169.6939]
];

const huntsRoadTunnelTahora = [
  [-46.4171, 169.6939],
  [-46.4173, 169.6937],
  [-46.4204, 169.6890],
  [-46.4207, 169.6883],
  [-46.4207, 169.6874],
  [-46.4211, 169.6864],
  [-46.4218, 169.6855],
  [-46.4221, 169.6840],
  [-46.4228, 169.6829],
  [-46.4230, 169.6815],
  [-46.4224, 169.6786],
  [-46.4219, 169.6773],
  [-46.4202, 169.6748],
  [-46.4198, 169.6739],
  [-46.4200, 169.6724],
  [-46.4206, 169.6716],
  [-46.4214, 169.6714],
  [-46.4247, 169.6726],
  [-46.4257, 169.6728],
  [-46.4264, 169.6735],
  [-46.4269, 169.6752],
  [-46.4276, 169.6761],
  [-46.4294, 169.6774],
  [-46.4304, 169.6777],
  [-46.4313, 169.6776],
  [-46.4331, 169.6761],
  [-46.4335, 169.6745],
  [-46.4335, 169.6731],
  [-46.4337, 169.6722],
  [-46.4339, 169.6713],
  [-46.4336, 169.6700],
  [-46.4336, 169.6688],
  [-46.4340, 169.6675],
  [-46.4352, 169.6668],
  [-46.4362, 169.6673],
  [-46.4367, 169.6684],
  [-46.4372, 169.6721],
  [-46.4383, 169.6749],
  [-46.4393, 169.6758],
  [-46.4404, 169.6759],
  [-46.4417, 169.6751]
];

const tahoraOwaka = [
  [-46.4417, 169.6751],
  [-46.4504, 169.6599]
];

const owakaRatanui = [
  [-46.4504, 169.6599],
  [-46.4580, 169.6476],
  [-46.4596, 169.6464],
  [-46.4609, 169.6462],
  [-46.4618, 169.6454],
  [-46.4631, 169.6435],
  [-46.4642, 169.6426],
  [-46.4651, 169.6423],
  [-46.4659, 169.6414],
  [-46.4683, 169.6355],
  [-46.4685, 169.6342],
  [-46.4693, 169.6329],
  [-46.4735, 169.6291],
  [-46.4742, 169.6281],
  [-46.4745, 169.6268],
  [-46.4745, 169.6243],
  [-46.4752, 169.6220],
  [-46.4763, 169.6201],
  [-46.4769, 169.6185],
  [-46.4777, 169.6120],
  [-46.4803, 169.6010]
];

const ratanuiHouipapa = [
  [-46.4803, 169.6010],
  [-46.4815, 169.5961],
  [-46.4820, 169.5925],
  [-46.4828, 169.5900],
  [-46.4828, 169.5887],
  [-46.4824, 169.5874],
  [-46.4810, 169.5854],
  [-46.4804, 169.5841],
  [-46.4803, 169.5827],
  [-46.4798, 169.5815],
  [-46.4775, 169.5788],
  [-46.4761, 169.5757],
  [-46.4760, 169.5737],
  [-46.4781, 169.5680],
  [-46.4782, 169.5658],
  [-46.4775, 169.5638],
  [-46.4768, 169.5631],
  [-46.4760, 169.5631],
  [-46.4751, 169.5636]
];

const houipapaTahakopa = [
  [-46.4751, 169.5636],
  [-46.4737, 169.5652],
  [-46.4725, 169.5671],
  [-46.4719, 169.5677],
  [-46.4710, 169.5677],
  [-46.4702, 169.5671],
  [-46.4696, 169.5672],
  [-46.4691, 169.5675],
  [-46.4673, 169.5699],
  [-46.4664, 169.5704],
  [-46.4658, 169.5702],
  [-46.4652, 169.5693],
  [-46.4651, 169.5682],
  [-46.4660, 169.5629],
  [-46.4664, 169.5618],
  [-46.4670, 169.5612],
  [-46.4675, 169.5605],
  [-46.4684, 169.5575],
  [-46.4684, 169.5559],
  [-46.4680, 169.5544],
  [-46.4650, 169.5489],
  [-46.4642, 169.5484],
  [-46.4633, 169.5484],
  [-46.4615, 169.5494],
  [-46.4610, 169.5493],
  [-46.4604, 169.5488],
  [-46.4589, 169.5460],
  [-46.4587, 169.5452],
  [-46.4585, 169.5442],
  [-46.4585, 169.5432],
  [-46.4584, 169.5425],
  [-46.4573, 169.5402],
  [-46.4571, 169.5391],
  [-46.4573, 169.5379],
  [-46.4580, 169.5362],
  [-46.4587, 169.5355],
  [-46.4599, 169.5349],
  [-46.4606, 169.5342],
  [-46.4612, 169.5329],
  [-46.4612, 169.5314],
  [-46.4607, 169.5288],
  [-46.4607, 169.5278],
  [-46.4610, 169.5268],
  [-46.4616, 169.5259],
  [-46.4632, 169.5230],
  [-46.4635, 169.5222],
  [-46.4638, 169.5189],
  [-46.4641, 169.5175],
  [-46.4647, 169.5163],
  [-46.4688, 169.5111],
  [-46.4690, 169.5099],
  [-46.4687, 169.5087],
  [-46.4680, 169.5081],
  [-46.4672, 169.5082],
  [-46.4664, 169.5086],
  [-46.4656, 169.5084],
  [-46.4650, 169.5076],
  [-46.4649, 169.5065],
  [-46.4653, 169.5048],
  [-46.4659, 169.5020],
  [-46.4662, 169.5011],
  [-46.4667, 169.5002],
  [-46.4668, 169.4993],
  [-46.4665, 169.4985],
  [-46.4666, 169.4974],
  [-46.4671, 169.4956],
  [-46.4676, 169.4949],
  [-46.4684, 169.4946],
  [-46.4697, 169.4953],
  [-46.4712, 169.4960],
  [-46.4719, 169.4960],
  [-46.4725, 169.4955],
  [-46.4731, 169.4948],
  [-46.4737, 169.4945],
  [-46.4747, 169.4946],
  [-46.4765, 169.4952],
  [-46.4772, 169.4951],
  [-46.4779, 169.4945],
  [-46.4782, 169.4937],
  [-46.4781, 169.4909],
  [-46.4781, 169.4896],
  [-46.4785, 169.4889],
  [-46.4800, 169.4867],
  [-46.4826, 169.4819],
  [-46.4869, 169.4720],
  [-46.4883, 169.4708],
  [-46.4896, 169.4706],
  [-46.4928, 169.4729],
  [-46.4934, 169.4733],
  [-46.4940, 169.4732],
  [-46.4947, 169.4731],
  [-46.4955, 169.4734],
  [-46.4967, 169.4755],
  [-46.4969, 169.4769],
  [-46.4960, 169.4807],
  [-46.4963, 169.4823],
  [-46.4972, 169.4829],
  [-46.4987, 169.4822],
  [-46.4998, 169.4822],
  [-46.5010, 169.4816],
  [-46.5019, 169.4817],
  [-46.5025, 169.4825],
  [-46.5030, 169.4839],
  [-46.5036, 169.4845],
  [-46.5046, 169.4843],
  [-46.5062, 169.4821],
  [-46.5066, 169.4809],
  [-46.5067, 169.4794],
  [-46.5071, 169.4780],
  [-46.5100, 169.4759],
  [-46.5108, 169.4744],
  [-46.5123, 169.4732],
  [-46.5131, 169.4730],
  [-46.5136, 169.4732],
  [-46.5143, 169.4730],
  [-46.5152, 169.4722],
  [-46.5160, 169.4720],
  [-46.5168, 169.4725],
  [-46.5174, 169.4734],
  [-46.5179, 169.4739],
  [-46.5186, 169.4740],
  [-46.5194, 169.4745],
  [-46.5199, 169.4757],
  [-46.5206, 169.4763],
  [-46.5215, 169.4767],
  [-46.5223, 169.4778],
  [-46.5232, 169.4781],
  [-46.5238, 169.4779],
  [-46.5244, 169.4781],
  [-46.5251, 169.4788],
  [-46.5262, 169.4790],
  [-46.5271, 169.4784],
  [-46.5277, 169.4783],
  [-46.5283, 169.4787],
  [-46.5299, 169.4810],
  [-46.5303, 169.4823],
  [-46.5310, 169.4831],
  [-46.5316, 169.4829],
  [-46.5328, 169.4826],
  [-46.5340, 169.4827],
  [-46.5363, 169.4841],
  [-46.5372, 169.4851],
  [-46.5380, 169.4853],
  [-46.5386, 169.4848],
  [-46.5398, 169.4823],
  [-46.5399, 169.4807],
  [-46.5398, 169.4781],
  [-46.5401, 169.4762],
  [-46.5413, 169.4743],
  [-46.5428, 169.4722],
  [-46.5432, 169.4709],
  [-46.5430, 169.4697],
  [-46.5426, 169.4687],
  [-46.5425, 169.4678],
  [-46.5420, 169.4662],
  [-46.5416, 169.4630],
  [-46.5418, 169.4612],
  [-46.5411, 169.4522],
  [-46.5407, 169.4510],
  [-46.5344, 169.4440],
  [-46.5333, 169.4437],
  [-46.5316, 169.4437],
  [-46.5303, 169.4431],
  [-46.5294, 169.4422],
  [-46.5291, 169.4410],
  [-46.5290, 169.4363],
  [-46.5248, 169.4179],
  [-46.5121, 169.3870]
];

export const catlinsRiver: Line = {
  name: 'Catlins River',
  state: 'NZ',
  segments: [
    {
      segments: [balcluthaFinegand],
      history: {
        opened: {
          date: '1885-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [finegandRomahapa],
      history: {
        opened: {
          date: '1885-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-02-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [romahapaGlenomaru],
      history: {
        opened: {
          date: '1891-07-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-02-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        glenomaruHuntsRoadTunnel,
        {
          name: 'Hunts Road Tunnel',
          segment: huntsRoadTunnel,
          type: 'tunnel'
        },
        huntsRoadTunnelTahora
      ],
      history: {
        opened: {
          date: '1895-12-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-02-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tahoraOwaka],
      history: {
        opened: {
          date: '1896-06-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-02-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [owakaRatanui],
      history: {
        opened: {
          date: '1904-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-02-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ratanuiHouipapa],
      history: {
        opened: {
          date: '1909-12-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-02-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [houipapaTahakopa],
      history: {
        opened: {
          date: '1915-02-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-02-27',
          status: 'closed'
        }]
      }
    }
  ]
}
