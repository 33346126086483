import { Line } from "../../trackTypes";

const kailiAbbotPoint = [
  [-19.9963, 148.0872],
  [-19.9950, 148.0867],
  [-19.9938, 148.0865],
  [-19.9930, 148.0867],
  [-19.9854, 148.0883],
  [-19.9842, 148.0887],
  [-19.9663, 148.0955],
  [-19.9476, 148.1054],
  [-19.9464, 148.1058],
  [-19.9452, 148.1058],
  [-19.9441, 148.1055],
  [-19.9136, 148.0897],
  [-19.9111, 148.0880],
  [-19.9022, 148.0817],
  [-19.9015, 148.0806],
  [-19.9013, 148.0796],
  [-19.9014, 148.0786],
  [-19.9020, 148.0773],
  [-19.9032, 148.0765],
  [-19.9043, 148.0764],
  [-19.9052, 148.0766],
  [-19.9061, 148.0771],
  [-19.9067, 148.0779],
  [-19.9087, 148.0826],
  [-19.9102, 148.0865],
  [-19.9108, 148.0874],
  [-19.9111, 148.0880]
];

const merindaByPass = [
  [-20.0133, 148.1495],
  [-20.0158, 148.1570],
  [-20.0172, 148.1592],
  [-20.0190, 148.1606],
  [-20.0250, 148.1630],
  [-20.0266, 148.1633],
  [-20.0293, 148.1629]
];

const merindaCollinsville = [
  [-20.0135, 148.1675],
  [-20.0139, 148.1668],
  [-20.0144, 148.1665],
  [-20.0149, 148.1664],
  [-20.0158, 148.1663],
  [-20.0221, 148.1657],
  [-20.0244, 148.1652],
  [-20.0293, 148.1629],
  [-20.0677, 148.1452],
  [-20.0695, 148.1439],
  [-20.1017, 148.1170],
  [-20.1128, 148.1067],
  [-20.1191, 148.0991],
  [-20.1214, 148.0968],
  [-20.1255, 148.0939],
  [-20.1266, 148.0928],
  [-20.1279, 148.0906],
  [-20.1345, 148.0701],
  [-20.1364, 148.0620],
  [-20.1364, 148.0605],
  [-20.1360, 148.0583],
  [-20.1360, 148.0569],
  [-20.1377, 148.0483],
  [-20.1392, 148.0457],
  [-20.1411, 148.0441],
  [-20.1480, 148.0413],
  [-20.1505, 148.0393],
  [-20.1556, 148.0323],
  [-20.1569, 148.0291],
  [-20.1577, 148.0241],
  [-20.1586, 148.0219],
  [-20.1644, 148.0139],
  [-20.1676, 148.0069],
  [-20.1680, 148.0050],
  [-20.1735, 147.9961],
  [-20.1750, 147.9946],
  [-20.1770, 147.9933],
  [-20.1793, 147.9912],
  [-20.1807, 147.9889],
  [-20.1818, 147.9878],
  [-20.1836, 147.9865],
  [-20.1851, 147.9848],
  [-20.1857, 147.9830],
  [-20.1864, 147.9754],
  [-20.1881, 147.9700],
  [-20.1899, 147.9676],
  [-20.1914, 147.9637],
  [-20.1923, 147.9622],
  [-20.1966, 147.9577],
  [-20.2009, 147.9549],
  [-20.2032, 147.9542],
  [-20.2120, 147.9552],
  [-20.2143, 147.9551],
  [-20.2220, 147.9538],
  [-20.2233, 147.9533],
  [-20.2452, 147.9419],
  [-20.2484, 147.9412],
  [-20.2571, 147.9366],
  [-20.2609, 147.9354],
  [-20.2682, 147.9353],
  [-20.2706, 147.9356],
  [-20.2735, 147.9368],
  [-20.2764, 147.9374],
  [-20.2829, 147.9374],
  [-20.2860, 147.9382],
  [-20.2876, 147.9382],
  [-20.2893, 147.9378],
  [-20.2993, 147.9331],
  [-20.3140, 147.9233],
  [-20.3161, 147.9226],
  [-20.3192, 147.9222],
  [-20.3205, 147.9217],
  [-20.3331, 147.9147],
  [-20.3405, 147.9071],
  [-20.3422, 147.9058],
  [-20.3443, 147.9047],
  [-20.3452, 147.9038],
  [-20.3462, 147.9019],
  [-20.3469, 147.9010],
  [-20.3481, 147.9002],
  [-20.3500, 147.8996],
  [-20.3528, 147.8976],
  [-20.3542, 147.8969],
  [-20.3563, 147.8966],
  [-20.3572, 147.8962],
  [-20.3614, 147.8934],
  [-20.3715, 147.8894],
  [-20.3788, 147.8878],
  [-20.3815, 147.8878],
  [-20.3828, 147.8876],
  [-20.3891, 147.8852],
  [-20.3907, 147.8841],
  [-20.3928, 147.8820],
  [-20.3950, 147.8805],
  [-20.3984, 147.8788],
  [-20.3995, 147.8780],
  [-20.4044, 147.8724],
  [-20.4066, 147.8683],
  [-20.4076, 147.8668],
  [-20.4119, 147.8623],
  [-20.4142, 147.8608],
  [-20.4277, 147.8572],
  [-20.4303, 147.8575],
  [-20.4335, 147.8590],
  [-20.4356, 147.8595],
  [-20.4377, 147.8594],
  [-20.4433, 147.8579],
  [-20.4517, 147.8576],
  [-20.4566, 147.8585],
  [-20.4597, 147.8583],
  [-20.4622, 147.8572],
  [-20.4641, 147.8572],
  [-20.4655, 147.8579],
  [-20.4676, 147.8600],
  [-20.4701, 147.8616],
  [-20.4721, 147.8617],
  [-20.4738, 147.8609],
  [-20.4752, 147.8589],
  [-20.4759, 147.8582],
  [-20.4771, 147.8577],
  [-20.4803, 147.8569],
  [-20.4822, 147.8568],
  [-20.4847, 147.8559],
  [-20.4882, 147.8535],
  [-20.4983, 147.8478],
  [-20.5084, 147.8447],
  [-20.5166, 147.8445],
  [-20.5192, 147.8451],
  [-20.5218, 147.8463],
  [-20.5232, 147.8474],
  [-20.5241, 147.8479],
  [-20.5281, 147.8481],
  [-20.5293, 147.8478],
  [-20.5320, 147.8461],
  [-20.5335, 147.8456],
  [-20.5351, 147.8456],
  [-20.5387, 147.8470],
  [-20.5413, 147.8488],
  [-20.5495, 147.8516],
  [-20.5520, 147.8528],
  [-20.5529, 147.8530],
  [-20.5536, 147.8530],
  [-20.5566, 147.8518]
];

const collinsvilleScotsvilleMine = [
  [-20.5566, 147.8518],
  [-20.5602, 147.8501],
  [-20.5689, 147.8450],
  [-20.5704, 147.8445],
  [-20.5717, 147.8446],
  [-20.5836, 147.8481],
  [-20.5853, 147.8492],
  [-20.5931, 147.8603],
  [-20.5953, 147.8622],
  [-20.5979, 147.8633],
  [-20.6134, 147.8651],
  [-20.6210, 147.8644],
  [-20.6427, 147.8622]
];

const sonoma = [
  [-20.6210, 147.8644],
  [-20.6230, 147.8637],
  [-20.6299, 147.8630],
  [-20.6352, 147.8625],
  [-20.6363, 147.8619],
  [-20.6371, 147.8608],
  [-20.6372, 147.8592],
  [-20.6366, 147.8578],
  [-20.6353, 147.8569],
  [-20.6339, 147.8569],
  [-20.6328, 147.8575],
  [-20.6321, 147.8584],
  [-20.6319, 147.8595],
  [-20.6318, 147.8606],
  [-20.6315, 147.8615],
  [-20.6308, 147.8625],
  [-20.6299, 147.8630]
];

const daconMine = [
  [-20.5689, 147.8450],
  [-20.5706, 147.8432],
  [-20.5711, 147.8421],
  [-20.5714, 147.8405],
  [-20.5709, 147.8361],
  [-20.5702, 147.8343],
  [-20.5637, 147.8265],
  [-20.5630, 147.8253],
  [-20.5614, 147.8205],
  [-20.5611, 147.8193],
  [-20.5609, 147.8173],
  [-20.5601, 147.8029],
  [-20.5605, 147.8013],
  [-20.5617, 147.8001],
  [-20.5635, 147.8001],
  [-20.5647, 147.8008],
  [-20.5654, 147.8022],
  [-20.5652, 147.8108],
  [-20.5648, 147.8120],
  [-20.5616, 147.8158],
  [-20.5612, 147.8165],
  [-20.5609, 147.8173]
];

const scottsvilleNewlands = [
  [-20.6427, 147.8622],
  [-20.7343, 147.8529],
  [-20.7940, 147.8384],
  [-20.7982, 147.8383],
  [-20.8404, 147.8458],
  [-20.9233, 147.8760],
  [-21.0401, 147.9152],
  [-21.0798, 147.9228],
  [-21.1073, 147.9237],
  [-21.1128, 147.9218],
  [-21.1160, 147.9178],
  [-21.1211, 147.9081],
  [-21.1302, 147.8997],
  [-21.1469, 147.8949],
  [-21.1508, 147.8941],
  [-21.1770, 147.8891],
  [-21.1918, 147.8864],
  [-21.1943, 147.8874],
  [-21.1952, 147.8897],
  [-21.1942, 147.8922],
  [-21.1925, 147.8931],
  [-21.1878, 147.8929],
  [-21.1784, 147.8894],
  [-21.1770, 147.8891]
];

const newlandsNorthGoonyellaMine = [
  [-21.1508, 147.8941],
  [-21.1523, 147.8936],
  [-21.1538, 147.8929],
  [-21.1556, 147.8917],
  [-21.1567, 147.8906],
  [-21.1581, 147.8888],
  [-21.1592, 147.8864],
  [-21.1639, 147.8730],
  [-21.1649, 147.8708],
  [-21.1667, 147.8685],
  [-21.1696, 147.8655],
  [-21.1710, 147.8638],
  [-21.1719, 147.8624],
  [-21.1730, 147.8599],
  [-21.1748, 147.8531],
  [-21.1759, 147.8504],
  [-21.1774, 147.8481],
  [-21.1791, 147.8463],
  [-21.1818, 147.8445],
  [-21.1910, 147.8395],
  [-21.1940, 147.8376],
  [-21.2112, 147.8253],
  [-21.2133, 147.8243],
  [-21.2160, 147.8237],
  [-21.2199, 147.8235],
  [-21.2252, 147.8218],
  [-21.2286, 147.8211],
  [-21.2536, 147.8199],
  [-21.2579, 147.8203],
  [-21.2600, 147.8209],
  [-21.3012, 147.8395],
  [-21.3094, 147.8415],
  [-21.3476, 147.8421],
  [-21.3541, 147.8454],
  [-21.3678, 147.8601],
  [-21.3719, 147.8623],
  [-21.3769, 147.8623],
  [-21.3811, 147.8606],
  [-21.3851, 147.8602],
  [-21.4086, 147.8621],
  [-21.4868, 147.8543],
  [-21.4929, 147.8555],
  [-21.4981, 147.8588],
  [-21.5199, 147.8807],
  [-21.5308, 147.8917],
  [-21.5412, 147.8992],
  [-21.5509, 147.9028],
  [-21.6021, 147.9134],
  [-21.6089, 147.9138],
  [-21.6477, 147.9081],
  [-21.6524, 147.9088],
  [-21.6563, 147.9108],
  [-21.6850, 147.9355],
  [-21.6871, 147.9365],
  [-21.6894, 147.9366]
];

export const GAP: Line = {
  name: 'GAP',
  state: 'QLD',
  segments: [
    {
      segments: [kailiAbbotPoint],
      history: {
        opened: {
          date: '1984-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [merindaByPass],
      history: {
        opened: {
          date: '1984-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [merindaCollinsville],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [collinsvilleScotsvilleMine],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [sonoma],
      history: {
        opened: {
          date: '2008-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [daconMine],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [scottsvilleNewlands],
      history: {
        opened: {
          date: '1984-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [newlandsNorthGoonyellaMine],
      history: {
        opened: {
          date: '2011-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
