import { Line } from "../../trackTypes";

const line = [
  [-36.4267, 143.6865],
  [-36.4277, 143.6857],
  [-36.4285, 143.6841],
  [-36.4300, 143.6735],
  [-36.4307, 143.6507],
  [-36.4306, 143.6483],
  [-36.4285, 143.6314],
  [-36.4283, 143.6267],
  [-36.4269, 143.6217],
  [-36.4264, 143.6204],
  [-36.4251, 143.6164],
  [-36.4239, 143.6141],
  [-36.4223, 143.6124],
  [-36.4187, 143.6058],
  [-36.4182, 143.6042]
];

export const wedderburn: Line = {
  name: 'Wedderburn',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1887-04-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-06',
          status: 'closed'
        }]
      }
    }
  ]
}
