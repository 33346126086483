import { Line } from "../../../../trackTypes";

const flindersStreetStKilda = [
  [-37.81746, 144.96744],
  [-37.81861, 144.96797],
  [-37.82041, 144.96878],
  [-37.82312, 144.97002],
  [-37.82605, 144.97127],
  [-37.82683, 144.97139],
  [-37.82734, 144.97142],
  [-37.82886, 144.97128],
  [-37.83138, 144.97143],
  [-37.83206, 144.97169],
  [-37.83243, 144.97192],
  [-37.83274, 144.97221],
  [-37.83283, 144.97231],
  [-37.83301, 144.97250],
  [-37.83390, 144.97383],
  [-37.83427, 144.97427],
  [-37.83470, 144.97465],
  [-37.83618, 144.97552],
  [-37.84485, 144.97869],
  [-37.84471, 144.97864],
  [-37.85006, 144.98059],
  [-37.85406, 144.98206],
  [-37.85544, 144.98252],
  [-37.85646, 144.98288],
  [-37.85666, 144.98297],
  [-37.85686, 144.98302],
  [-37.85725, 144.98302],
  [-37.85759, 144.98313],
  [-37.85791, 144.98335],
  [-37.85827, 144.98354],
  [-37.86731, 144.98665],
  [-37.86774, 144.98690],
  [-37.87109, 144.98900]
];

const stKildaExtension = [
  [-37.87109, 144.98900],
  [-37.87221, 144.98970],
  [-37.88359, 144.99687],
  [-37.88372, 144.99701],
  [-37.88377, 144.99715]
];

export const south1: Line = {
  name: 'South1',
  state: 'VIC',
  segments: [
    {
      segments: [flindersStreetStKilda],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1926-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [stKildaExtension],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
