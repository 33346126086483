import { Line } from "../../trackTypes";

const berowraCownan = [
  [-33.6233, 151.1532],
  [-33.6216, 151.1558],
  [-33.6184, 151.1586],
  [-33.6150, 151.1598],
  [-33.6095, 151.1606],
  [-33.6076, 151.1615],
  [-33.6036, 151.1647],
  [-33.6022, 151.1653],
  [-33.6011, 151.1652],
  [-33.6001, 151.1651],
  [-33.5990, 151.1655],
  [-33.5981, 151.1667],
  [-33.5980, 151.1680],
  [-33.5982, 151.1693],
  [-33.5977, 151.1707],
  [-33.5967, 151.1717],
  [-33.5958, 151.1718],
  [-33.5931, 151.1717]
];

const cowenBoroniaTunnel1 = [
  [-33.5931, 151.1717],
  [-33.5915, 151.1717],
  [-33.5902, 151.1721],
  [-33.5887, 151.1732],
  [-33.5870, 151.1741],
  [-33.5857, 151.1756],
  [-33.5847, 151.1765],
  [-33.5836, 151.1771],
  [-33.5829, 151.1782],
  [-33.5827, 151.1795],
  [-33.5829, 151.1807],
  [-33.5828, 151.1820],
  [-33.5823, 151.1830],
  [-33.5813, 151.1843]
];

const boroniaNo1Tunnel = [
  [-33.5813, 151.1843],
  [-33.5788, 151.1875]
];

const boroniaTunnel1BoroniaTunnel2 = [
  [-33.5788, 151.1875],
  [-33.5783, 151.1884],
  [-33.5769, 151.1912],
  [-33.5761, 151.1920],
  [-33.5756, 151.1922]
];

const boroniaNo2Tunnel = [
  [-33.5756, 151.1922],
  [-33.5701, 151.1934]
];

const boroniaTunnel2BoroniaTunnel3 = [
  [-33.5701, 151.1934],
  [-33.5688, 151.1939],
  [-33.5674, 151.1946],
  [-33.5666, 151.1947],
  [-33.5651, 151.1945],
  [-33.5639, 151.1948],
  [-33.5631, 151.1954],
  [-33.5621, 151.1964],
  [-33.5610, 151.1968],
  [-33.5599, 151.1967],
  [-33.5591, 151.1970],
  [-33.5580, 151.1977],
  [-33.5569, 151.1977],
  [-33.5561, 151.1973],
  [-33.5552, 151.1965],
  [-33.5543, 151.1963],
  [-33.5539, 151.1964]
];

const boroniaNo3Tunnel = [
  [-33.5539, 151.1964],
  [-33.5532, 151.1967],
  [-33.5528, 151.1972]
];

const boroniaTunnel3BoroniaTunnel4 = [
  [-33.5528, 151.1972],
  [-33.5522, 151.1983],
  [-33.5519, 151.1993]
];

const boroniaNo4Tunnel = [
  [-33.5519, 151.1993],
  [-33.5519, 151.2002]
];

const boroniaTunnel4BoroniaDeviationStart = [
  [-33.5519, 151.2002],
  [-33.5519, 151.2004]
];

const boroniaDeviationStartBoroniaTunnel5 = [
  [-33.5519, 151.2004],
  [-33.5521, 151.2011]
];

const boroniaNo5Tunnel = [
  [-33.5521, 151.2011],
  [-33.5521, 151.2018],
  [-33.5520, 151.2021]
];

const boroniaTunnel5BoroniaDeviationEnd = [
  [-33.5520, 151.2021],
  [-33.5518, 151.2026]
];

const boroniaNo5Deviation = [
  [-33.5519, 151.2004],
  [-33.5519, 151.2017],
  [-33.5518, 151.2026]
];

const boroniaDeviationEndHawkesburyRiver = [
  [-33.5518, 151.2026],
  [-33.5511, 151.2037],
  [-33.5505, 151.2043],
  [-33.5501, 151.2054],
  [-33.5501, 151.2064],
  [-33.5505, 151.2076],
  [-33.5507, 151.2084],
  [-33.5505, 151.2093],
  [-33.5493, 151.2123],
  [-33.5492, 151.2131],
  [-33.5492, 151.2137],
  [-33.5493, 151.2147],
  [-33.5491, 151.2157],
  [-33.5486, 151.2169],
  [-33.5482, 151.2182],
  [-33.5476, 151.2244],
  [-33.5474, 151.2256],
  [-33.5472, 151.2261]
];

const hawkesburyRiverHawkesburyBridgeStart = [
  [-33.5472, 151.2261],
  [-33.5461, 151.2273],
  [-33.5427, 151.2291]
];

const hawkesburyBridgeOldLongIslandTunnelOld = [
  [-33.5427, 151.2291],
  [-33.5405, 151.2298],
  [-33.5402, 151.2298]
];

const longIslandTunnelOld = [
  [-33.5402, 151.2298],
  [-33.5387, 151.2296]
];

const longIslandTunnelOldHawkesburyBridgeOld = [
  [-33.5387, 151.2296],
  [-33.5295, 151.2288],
  [-33.5274, 151.2296],
  [-33.5248, 151.2306]
];

const hawkesburyRiverNewLongIslandTunnel = [
  [-33.5427, 151.2291],
  [-33.5414, 151.2293],
  [-33.5404, 151.2292]
];

const longIslandTunnel = [
  [-33.5404, 151.2292],
  [-33.5380, 151.2290]
];

const longIslandTunnelMulletCreekTunnel = [
  [-33.5380, 151.2290],
  [-33.5298, 151.2282]
];

const mulletCreekTunnel = [
  [-33.5298, 151.2282],
  [-33.5284, 151.2284],
  [-33.5260, 151.2298]
];

const mulletCreekTunnelHawkesburyRiverNew = [
  [-33.5260, 151.2298],
  [-33.5248, 151.2306],
];

const hawkesburyBridgeDeviationEndWondabyne = [
  [-33.5248, 151.2306],
  [-33.5156, 151.2325],
  [-33.5138, 151.2324],
  [-33.5087, 151.2307],
  [-33.5070, 151.2314],
  [-33.5061, 151.2326],
  [-33.5058, 151.2339],
  [-33.5058, 151.2352],
  [-33.5056, 151.2363],
  [-33.5042, 151.2391],
  [-33.5035, 151.2399],
  [-33.5027, 151.2404],
  [-33.4989, 151.2414],
  [-33.4979, 151.2421],
  [-33.4974, 151.2429],
  [-33.4971, 151.2439],
  [-33.4968, 151.2461],
  [-33.4964, 151.2470],
  [-33.4958, 151.2476],
  [-33.4950, 151.2481],
  [-33.4943, 151.2490],
  [-33.4939, 151.2502],
  [-33.4938, 151.2544],
  [-33.4935, 151.2557],
  [-33.4928, 151.2565],
  [-33.4920, 151.2570]
];

const wondabyneWoyWoyTunnel = [
  [-33.4920, 151.2570],
  [-33.4914, 151.2575],
  [-33.4908, 151.2582],
  [-33.4904, 151.2590],
  [-33.4898, 151.2596],
  [-33.4881, 151.2608],
  [-33.4857, 151.2627],
  [-33.4850, 151.2637],
  [-33.4846, 151.2647],
  [-33.4845, 151.2659],
  [-33.4849, 151.2697],
  [-33.4852, 151.2706],
  [-33.4863, 151.2728]
];

const woyWoyTunnel = [
  [-33.4863, 151.2728],
  [-33.4944, 151.2896]
];

const woyWoyTunnelGosford = [
  [-33.4944, 151.2896],
  [-33.4965, 151.2940],
  [-33.4971, 151.2961],
  [-33.4975, 151.3022],
  [-33.4970, 151.3066],
  [-33.4959, 151.3098],
  [-33.4943, 151.3126],
  [-33.4863, 151.3227],
  [-33.4847, 151.3239],
  [-33.4831, 151.3241],
  [-33.4753, 151.3235],
  [-33.4736, 151.3226],
  [-33.4721, 151.3210],
  [-33.4705, 151.3194],
  [-33.4692, 151.3189],
  [-33.4667, 151.3185],
  [-33.4648, 151.3176],
  [-33.4636, 151.3163],
  [-33.4628, 151.3148],
  [-33.4622, 151.3137],
  [-33.4612, 151.3129],
  [-33.4600, 151.3128],
  [-33.4590, 151.3132],
  [-33.4520, 151.3173],
  [-33.4510, 151.3183],
  [-33.4503, 151.3196],
  [-33.4500, 151.3209],
  [-33.4494, 151.3248],
  [-33.4487, 151.3265],
  [-33.4476, 151.3277],
  [-33.4465, 151.3283],
  [-33.4381, 151.3308],
  [-33.4299, 151.3351],
  [-33.4281, 151.3364],
  [-33.4270, 151.3380],
  [-33.4260, 151.3400],
  [-33.4251, 151.3411],
  [-33.4244, 151.3414],
  [-33.4233, 151.3416]
];

const gosfordWyong = [
  [-33.4233, 151.3416],
  [-33.4184, 151.3428],
  [-33.4172, 151.3427],
  [-33.4162, 151.3425],
  [-33.4149, 151.3426],
  [-33.4134, 151.3433],
  [-33.4123, 151.3443],
  [-33.4087, 151.3481],
  [-33.4074, 151.3490],
  [-33.4061, 151.3491],
  [-33.4047, 151.3487],
  [-33.3985, 151.3429],
  [-33.3975, 151.3425],
  [-33.3963, 151.3428],
  [-33.3954, 151.3434],
  [-33.3922, 151.3478],
  [-33.3891, 151.3509],
  [-33.3854, 151.3529],
  [-33.3835, 151.3539],
  [-33.3822, 151.3563],
  [-33.3819, 151.3585],
  [-33.3831, 151.3657],
  [-33.3829, 151.3680],
  [-33.3829, 151.3680],
  [-33.3809, 151.3716],
  [-33.3777, 151.3746],
  [-33.3751, 151.3760],
  [-33.3724, 151.3766],
  [-33.3695, 151.3763],
  [-33.3672, 151.3754],
  [-33.3647, 151.3735],
  [-33.3632, 151.3719],
  [-33.3612, 151.3706],
  [-33.3594, 151.3698],
  [-33.3572, 151.3696],
  [-33.3542, 151.3702],
  [-33.3520, 151.3715],
  [-33.3502, 151.3732],
  [-33.3489, 151.3752],
  [-33.3476, 151.3789],
  [-33.3463, 151.3820],
  [-33.3444, 151.3849],
  [-33.3416, 151.3874],
  [-33.3387, 151.3896],
  [-33.3368, 151.3917],
  [-33.3297, 151.4038],
  [-33.3284, 151.4055],
  [-33.3176, 151.4171],
  [-33.3153, 151.4184],
  [-33.2944, 151.4231],
  [-33.2918, 151.4239],
  [-33.2868, 151.4248],
  [-33.2855, 151.4251]
];

const wyongTickholeTunnel = [
  [-33.2855, 151.4251],
  [-33.2817, 151.4265],
  [-33.2609, 151.4363],
  [-33.2531, 151.4422],
  [-33.2484, 151.4473],
  [-33.2425, 151.4564],
  [-33.2405, 151.4585],
  [-33.2340, 151.4625],
  [-33.2323, 151.4645],
  [-33.2313, 151.4663],
  [-33.2293, 151.4712],
  [-33.2278, 151.4732],
  [-33.2258, 151.4746],
  [-33.2205, 151.4767],
  [-33.2186, 151.4781],
  [-33.2111, 151.4861],
  [-33.2082, 151.4877],
  [-33.1937, 151.4903],
  [-33.1908, 151.4898],
  [-33.1869, 151.4878],
  [-33.1852, 151.4873],
  [-33.1620, 151.4856],
  [-33.1590, 151.4861],
  [-33.1559, 151.4875],
  [-33.1528, 151.4876],
  [-33.1501, 151.4867],
  [-33.1384, 151.4791],
  [-33.1367, 151.4788],
  [-33.1351, 151.4794],
  [-33.1328, 151.4813],
  [-33.1289, 151.4829],
  [-33.1257, 151.4826],
  [-33.1223, 151.4812],
  [-33.1202, 151.4808],
  [-33.1170, 151.4811],
  [-33.1134, 151.4827],
  [-33.1108, 151.4851],
  [-33.1088, 151.4883],
  [-33.1067, 151.4949],
  [-33.1045, 151.4999],
  [-33.1030, 151.5013],
  [-33.1013, 151.5013],
  [-33.0998, 151.5000],
  [-33.0987, 151.4984],
  [-33.0968, 151.4971],
  [-33.0947, 151.4969],
  [-33.0928, 151.4973],
  [-33.0759, 151.5042],
  [-33.0743, 151.5045],
  [-33.0723, 151.5044],
  [-33.0696, 151.5052],
  [-33.0673, 151.5069],
  [-33.0657, 151.5075],
  [-33.0641, 151.5077],
  [-33.0624, 151.5079],
  [-33.0603, 151.5091],
  [-33.0593, 151.5105],
  [-33.0572, 151.5144],
  [-33.0553, 151.5159],
  [-33.0536, 151.5163],
  [-33.0522, 151.5159],
  [-33.0507, 151.5152],
  [-33.0494, 151.5152],
  [-33.0482, 151.5158],
  [-33.0442, 151.5208],
  [-33.0427, 151.5215],
  [-33.0413, 151.5216],
  [-33.0389, 151.5212],
  [-33.0378, 151.5207],
  [-33.0367, 151.5200],
  [-33.0354, 151.5197],
  [-33.0345, 151.5200],
  [-33.0323, 151.5211],
  [-33.0310, 151.5211],
  [-33.0299, 151.5205],
  [-33.0286, 151.5186],
  [-33.0275, 151.5177],
  [-33.0261, 151.5176],
  [-33.0244, 151.5189],
  [-33.0231, 151.5217],
  [-33.0226, 151.5238],
  [-33.0217, 151.5254],
  [-33.0206, 151.5263],
  [-33.0180, 151.5281],
  [-33.0171, 151.5292],
  [-33.0168, 151.5304],
  [-33.0167, 151.5321],
  [-33.0161, 151.5338],
  [-33.0152, 151.5347],
  [-33.0143, 151.5356],
  [-33.0131, 151.5377],
  [-33.0127, 151.5399],
  [-33.0129, 151.5426],
  [-33.0126, 151.5445],
  [-33.0118, 151.5459],
  [-33.0040, 151.5560],
  [-32.9978, 151.5666],
  [-32.9961, 151.5683],
  [-32.9935, 151.5696],
  [-32.9912, 151.5714],
  [-32.9901, 151.5731],
  [-32.9898, 151.5745],
  [-32.9898, 151.5775],
  [-32.9890, 151.5795],
  [-32.9879, 151.5804],
  [-32.9860, 151.5808],
  [-32.9848, 151.5812],
  [-32.9750, 151.5861],
  [-32.9739, 151.5873],
  [-32.9735, 151.5883],
  [-32.9725, 151.5936],
  [-32.9730, 151.5958],
  [-32.9740, 151.5970],
  [-32.9758, 151.5978],
  [-32.9772, 151.5992],
  [-32.9779, 151.6019],
  [-32.9770, 151.6045],
  [-32.9752, 151.6059],
  [-32.9736, 151.6060],
  [-32.9724, 151.6054],
  [-32.9667, 151.6011],
  [-32.9652, 151.6008],
  [-32.9640, 151.6013],
  [-32.9631, 151.6024],
  [-32.9612, 151.6054],
  [-32.9591, 151.6077],
  [-32.9564, 151.6095],
  [-32.9467, 151.6146],
  [-32.9445, 151.6168],
  [-32.9434, 151.6191],
  [-32.9426, 151.6240],
  [-32.9415, 151.6265],
  [-32.9405, 151.6281],
  [-32.9379, 151.6316],
  [-32.9368, 151.6343],
  [-32.9367, 151.6370],
  [-32.9380, 151.6522],
  [-32.9374, 151.6546],
  [-32.9364, 151.6564],
  [-32.9361, 151.6580],
  [-32.9364, 151.6599],
  [-32.9372, 151.6610],
  [-32.9383, 151.6618],
  [-32.9405, 151.6621],
  [-32.9421, 151.6631],
  [-32.9432, 151.6649],
  [-32.9434, 151.6663],
  [-32.9431, 151.6694],
  [-32.9424, 151.6708],
  [-32.9416, 151.6715],
  [-32.9407, 151.6723],
  [-32.9401, 151.6732],
  [-32.9398, 151.6743],
  [-32.9394, 151.6779],
  [-32.9396, 151.6806],
  [-32.9404, 151.6825],
  [-32.9413, 151.6837],
  [-32.9435, 151.6860]
];

const tickholeTunnel = [
  [-32.9435, 151.6860],
  [-32.9439, 151.6865],
  [-32.9445, 151.6876]
];

const tickholeTunnelIslingtonJunction = [
  [-32.9445, 151.6876],
  [-32.9447, 151.6884],
  [-32.9447, 151.6896],
  [-32.9445, 151.6909],
  [-32.9437, 151.6926],
  [-32.9427, 151.6943],
  [-32.9425, 151.6948],
  [-32.9401, 151.7031],
  [-32.9367, 151.7100],
  [-32.9359, 151.7126],
  [-32.9356, 151.7161],
  [-32.9346, 151.7188],
  [-32.9168, 151.7428]
];

const islingtonJunction = [
  [-32.9168, 151.7428],
  [-32.9156, 151.7436],
  [-32.9145, 151.7436],
  [-32.9130, 151.7427]
];

const islingtonJunctionPortWaratahJunction = [
  [-32.9130, 151.7427],
  [-32.9081, 151.7374],
];

const islingtonJunctionTarro = [
  [-32.9081, 151.7374],
  [-32.8968, 151.7254],
  [-32.8946, 151.7222],
  [-32.8924, 151.7164],
  [-32.8904, 151.7131],
  [-32.8863, 151.7092],
  [-32.8824, 151.7071],
  [-32.8213, 151.6809],
  [-32.8140, 151.6756],
  [-32.8091, 151.6697]
];

const tarroVictoriaStreet = [
  [-32.8091, 151.6697],
  [-32.7984, 151.6573],
  [-32.7562, 151.6077],
  [-32.7534, 151.6032],
  [-32.7524, 151.5998],
  [-32.7516, 151.5956],
  [-32.7508, 151.5934]
];

const victoriaStreetMaitland = [
  [-32.7508, 151.5934],
  [-32.7498, 151.5915],
  [-32.7484, 151.5897],
  [-32.7461, 151.5879],
  [-32.7441, 151.5869],
  [-32.7415, 151.5845],
  [-32.7396, 151.5809],
  [-32.7388, 151.5770],
  [-32.7392, 151.5734],
  [-32.7409, 151.5681],
  [-32.7415, 151.5648],
  [-32.7412, 151.5608],
  [-32.7379, 151.5520]
];

const maitlandCessnockJunction = [
  [-32.7379, 151.5520],
  [-32.7362, 151.5480],
  [-32.7355, 151.5459],
  [-32.7348, 151.5433]
];

const cessnockJunctionLochinvar = [
  [-32.7348, 151.5433],
  [-32.7338, 151.5395],
  [-32.7329, 151.5371],
  [-32.7296, 151.5299],
  [-32.7254, 151.5148],
  [-32.7244, 151.5010],
  [-32.7236, 151.4970],
  [-32.7220, 151.4923],
  [-32.7189, 151.4854],
  [-32.7181, 151.4825],
  [-32.7179, 151.4800],
  [-32.7179, 151.4726],
  [-32.7187, 151.4627],
  [-32.7199, 151.4523],
  [-32.7205, 151.4499]
];

const lochinvarBranxton = [
  [-32.7205, 151.4499],
  [-32.7219, 151.4441],
  [-32.7233, 151.4406],
  [-32.7239, 151.4373],
  [-32.7233, 151.4215],
  [-32.7225, 151.4182],
  [-32.7203, 151.4146],
  [-32.7143, 151.4098],
  [-32.7088, 151.4067],
  [-32.7043, 151.4025],
  [-32.6967, 151.3928],
  [-32.6906, 151.3866],
  [-32.6878, 151.3846],
  [-32.6833, 151.3830],
  [-32.6792, 151.3808],
  [-32.6761, 151.3782],
  [-32.6690, 151.3665],
  [-32.6670, 151.3618],
  [-32.6622, 151.3457]
];

const branxtonWhittingham = [
  [-32.6622, 151.3457],
  [-32.6598, 151.3375],
  [-32.6593, 151.3347],
  [-32.6591, 151.3252],
  [-32.6600, 151.3205],
  [-32.6613, 151.3171],
  [-32.6639, 151.3133],
  [-32.6653, 151.3098],
  [-32.6655, 151.3060],
  [-32.6643, 151.2978],
  [-32.6635, 151.2952],
  [-32.6622, 151.2932],
  [-32.6604, 151.2911],
  [-32.6587, 151.2878],
  [-32.6560, 151.2777],
  [-32.6556, 151.2739],
  [-32.6561, 151.2686],
  [-32.6589, 151.2625],
  [-32.6625, 151.2581],
  [-32.6639, 151.2549],
  [-32.6643, 151.2513],
  [-32.6638, 151.2479],
  [-32.6616, 151.2416],
  [-32.6586, 151.2369],
  [-32.6552, 151.2343],
  [-32.6451, 151.2281],
  [-32.6416, 151.2271],
  [-32.6382, 151.2270],
  [-32.6336, 151.2278],
  [-32.6280, 151.2271],
  [-32.6242, 151.2251],
  [-32.6215, 151.2222],
  [-32.6115, 151.2075],
  [-32.6093, 151.2050],
  [-32.5989, 151.1951]
];

const whittinghameSingleton = [
  [-32.5989, 151.1951],
  [-32.5841, 151.1813],
  [-32.5719, 151.1654]
];

const singletonHunterRiver = [
  [-32.5719, 151.1654],
  [-32.5689, 151.1616],
  [-32.5669, 151.1598],
  [-32.5652, 151.1589],
  [-32.5623, 151.1579]
];

const hunterRiverNundahJunction = [
  [-32.5623, 151.1579],
  [-32.5582, 151.1568],
  [-32.5568, 151.1559],
  [-32.5551, 151.1546],
  [-32.5527, 151.1538],
  [-32.5510, 151.1538],
  [-32.5493, 151.1544],
  [-32.5468, 151.1545],
  [-32.5448, 151.1542],
  [-32.5437, 151.1542],
  [-32.5418, 151.1546],
  [-32.5407, 151.1547],
  [-32.5339, 151.1533],
  [-32.5313, 151.1528],
  [-32.5289, 151.1530],
  [-32.5262, 151.1538],
  [-32.5249, 151.1542]
];

const hunterRiverDeviation = [
  [-32.5623, 151.1579],
  [-32.5606, 151.1578],
  [-32.5589, 151.1578],
  [-32.5576, 151.1573],
  [-32.5566, 151.1566],
  [-32.5555, 151.1555],
  [-32.5543, 151.1548],
  [-32.5528, 151.1544],
  [-32.5512, 151.1546],
  [-32.5497, 151.1552],
  [-32.5481, 151.1555],
  [-32.5397, 151.1556],
  [-32.5383, 151.1554],
  [-32.5324, 151.1539],
  [-32.5307, 151.1538],
  [-32.5273, 151.1544],
  [-32.5262, 151.1544],
  [-32.5249, 151.1542]
];

const nundahJunctionNewdellJunction = [
  [-32.5249, 151.1542],
  [-32.5225, 151.1535],
  [-32.5197, 151.1534],
  [-32.5171, 151.1540],
  [-32.5153, 151.1541],
  [-32.5090, 151.1525],
  [-32.5076, 151.1517],
  [-32.5066, 151.1504],
  [-32.5035, 151.1459],
  [-32.5029, 151.1441],
  [-32.5023, 151.1416],
  [-32.5007, 151.1387],
  [-32.4983, 151.1372],
  [-32.4959, 151.1369],
  [-32.4939, 151.1374],
  [-32.4926, 151.1373],
  [-32.4846, 151.1356],
  [-32.4830, 151.1347],
  [-32.4814, 151.1330],
  [-32.4792, 151.1317],
  [-32.4779, 151.1315],
  [-32.4761, 151.1315],
  [-32.4743, 151.1309],
  [-32.4725, 151.1293],
  [-32.4666, 151.1198],
  [-32.4659, 151.1178],
  [-32.4650, 151.1146],
  [-32.4635, 151.1116],
  [-32.4599, 151.1040],
  [-32.4578, 151.0971],
  [-32.4574, 151.0916],
  [-32.4578, 151.0869],
  [-32.4592, 151.0799],
  [-32.4593, 151.0751],
  [-32.4579, 151.0700],
  [-32.4544, 151.0651],
  [-32.4444, 151.0585],
  [-32.4413, 151.0573],
  [-32.4298, 151.0550],
  [-32.4263, 151.0536],
  [-32.4216, 151.0502],
  [-32.4178, 151.0450]
];

const newdellJunctionOldMainNorthDeadEnd = [
  [-32.4178, 151.0450],
  [-32.4168, 151.0421],
  [-32.4141, 151.0316],
  [-32.4127, 151.0283],
  [-32.4103, 151.0246],
  [-32.4029, 151.0177],
  [-32.3886, 150.9983]
];

const oldMainNorthDeadEndOldMainNorthDeadEndTwo = [
  [-32.3886, 150.9983],
  [-32.3837, 150.9928]
];

const oldMainNorthDeadEndTwoAntieneJunction = [
  [-32.3837, 150.9928],
  [-32.3825, 150.9922],
  [-32.3551, 150.9878],
  [-32.3430, 150.9860]
];

const liddellDeviation = [
  [-32.4178, 151.0450],
  [-32.4159, 151.0425],
  [-32.4102, 151.0384],
  [-32.3747, 151.0243],
  [-32.3712, 151.0222],
  [-32.3577, 151.0109],
  [-32.3548, 151.0075],
  [-32.3519, 151.0018],
  [-32.3485, 150.9904],
  [-32.3461, 150.9875],
  [-32.3430, 150.9860]
];

const antieneJunctionMuswellbrook = [
  [-32.3430, 150.9860],
  [-32.3393, 150.9851],
  [-32.3372, 150.9839],
  [-32.3348, 150.9833],
  [-32.3320, 150.9839],
  [-32.3299, 150.9848],
  [-32.3276, 150.9845],
  [-32.3260, 150.9826],
  [-32.3253, 150.9803],
  [-32.3234, 150.9780],
  [-32.3204, 150.9774],
  [-32.3178, 150.9787],
  [-32.3145, 150.9823],
  [-32.3111, 150.9846],
  [-32.3068, 150.9853],
  [-32.3021, 150.9836],
  [-32.2943, 150.9766],
  [-32.2904, 150.9706],
  [-32.2822, 150.9529],
  [-32.2813, 150.9458],
  [-32.2826, 150.9258],
  [-32.2824, 150.9235],
  [-32.2814, 150.9209],
  [-32.2787, 150.9168],
  [-32.2776, 150.9138],
  [-32.2774, 150.9114],
  [-32.2778, 150.9085],
  [-32.2777, 150.9059],
  [-32.2765, 150.9028],
  [-32.2743, 150.9005],
  [-32.2722, 150.8992],
  [-32.2708, 150.8977],
  [-32.2675, 150.8904]
];

const muswellbrookAberdeen = [
  [-32.2675, 150.8904],
  [-32.2669, 150.8891],
  [-32.2665, 150.8885],
  [-32.2652, 150.8876],
  [-32.2642, 150.8874],
  [-32.2632, 150.8875],
  [-32.2601, 150.8881],
  [-32.2583, 150.8886],
  [-32.2535, 150.8908],
  [-32.2512, 150.8927],
  [-32.2427, 150.9024],
  [-32.2408, 150.9036],
  [-32.2386, 150.9038],
  [-32.2165, 150.9002],
  [-32.2144, 150.8992],
  [-32.2085, 150.8947],
  [-32.2066, 150.8938],
  [-32.2051, 150.8936],
  [-32.1736, 150.8935],
  [-32.1705, 150.8930],
  [-32.1676, 150.8922]
];

const aberdeenScone = [
  [-32.1676, 150.8922],
  [-32.1532, 150.8880],
  [-32.1511, 150.8870],
  [-32.1354, 150.8766],
  [-32.1318, 150.8747],
  [-32.1179, 150.8705],
  [-32.0923, 150.8670],
  [-32.0883, 150.8672],
  [-32.0683, 150.8709],
  [-32.0651, 150.8708],
  [-32.0610, 150.8694],
  [-32.0567, 150.8663],
  [-32.0553, 150.8657],
  [-32.0537, 150.8656],
  [-32.0468, 150.8669]
];

const sconeWingen = [
  [-32.0468, 150.8669],
  [-32.0445, 150.8673],
  [-32.0144, 150.8677],
  [-31.9915, 150.8678],
  [-31.9626, 150.8680],
  [-31.9600, 150.8683],
  [-31.9279, 150.8789],
  [-31.9246, 150.8797],
  [-31.9058, 150.8793],
  [-31.9040, 150.8796],
  [-31.9029, 150.8801],
  [-31.9011, 150.8810],
  [-31.8996, 150.8813],
  [-31.8986, 150.8813],
  [-31.8958, 150.8807]
];

const wingenMurrurundi = [
  [-31.8958, 150.8807],
  [-31.8932, 150.8802],
  [-31.8918, 150.8801],
  [-31.8798, 150.8817],
  [-31.8766, 150.8821],
  [-31.8746, 150.8828],
  [-31.8624, 150.8891],
  [-31.8580, 150.8929],
  [-31.8518, 150.9023],
  [-31.8401, 150.9146],
  [-31.8389, 150.9166],
  [-31.8381, 150.9183],
  [-31.8369, 150.9199],
  [-31.8320, 150.9241],
  [-31.8298, 150.9251],
  [-31.8282, 150.9253],
  [-31.8259, 150.9249],
  [-31.8244, 150.9250],
  [-31.8232, 150.9255],
  [-31.8217, 150.9265],
  [-31.8198, 150.9272],
  [-31.8178, 150.9270],
  [-31.8160, 150.9260],
  [-31.8138, 150.9235],
  [-31.8110, 150.9216],
  [-31.8040, 150.9187],
  [-31.7977, 150.9144],
  [-31.7965, 150.9129],
  [-31.7957, 150.9114],
  [-31.7940, 150.9048],
  [-31.7931, 150.9028],
  [-31.7855, 150.8919],
  [-31.7844, 150.8895],
  [-31.7804, 150.8729],
  [-31.7738, 150.8594],
  [-31.7689, 150.8425],
  [-31.7684, 150.8394]
];

const murrurundiArdglenTunnel = [
  [-31.7684, 150.8394],
  [-31.7678, 150.8360],
  [-31.7673, 150.8341],
  [-31.7658, 150.8315],
  [-31.7652, 150.8301],
  [-31.7626, 150.8232],
  [-31.7623, 150.8216],
  [-31.7624, 150.8202],
  [-31.7629, 150.8190],
  [-31.7642, 150.8170],
  [-31.7645, 150.8161],
  [-31.7647, 150.8147],
  [-31.7650, 150.8135],
  [-31.7658, 150.8123],
  [-31.7786, 150.7997],
  [-31.7791, 150.7987],
  [-31.7791, 150.7973],
  [-31.7784, 150.7960],
  [-31.7774, 150.7955],
  [-31.7764, 150.7955],
  [-31.7750, 150.7961],
  [-31.7736, 150.7963],
  [-31.7722, 150.7960],
  [-31.7714, 150.7961],
  [-31.7707, 150.7966],
  [-31.7699, 150.7972],
  [-31.7691, 150.7976],
  [-31.7680, 150.7974],
  [-31.7672, 150.7967],
  [-31.7666, 150.7954],
  [-31.7660, 150.7946],
  [-31.7652, 150.7941],
  [-31.7642, 150.7941],
  [-31.7632, 150.7945],
  [-31.7621, 150.7945],
  [-31.7613, 150.7939],
  [-31.7604, 150.7932],
  [-31.7595, 150.7932],
  [-31.7575, 150.7936],
  [-31.7563, 150.7936],
  [-31.7550, 150.7936],
  [-31.7541, 150.7939],
  [-31.7533, 150.7951],
  [-31.7530, 150.7961],
  [-31.7533, 150.7979],
  [-31.7531, 150.7991],
  [-31.7522, 150.8002],
  [-31.7512, 150.8005],
  [-31.7503, 150.8004],
  [-31.7495, 150.7998],
  [-31.7487, 150.7996],
  [-31.7479, 150.7996],
  [-31.7470, 150.7993],
  [-31.7460, 150.7985],
  [-31.7445, 150.7982],
  [-31.7433, 150.7982],
  [-31.7424, 150.7979],
  [-31.7419, 150.7974],
  [-31.7414, 150.7966]
];

const ardglenTunnel = [
  [-31.7414, 150.7966],
  [-31.7392, 150.7921]
];

const ardglenTunnelQuirindi = [
  [-31.7392, 150.7921],
  [-31.7386, 150.7905],
  [-31.7379, 150.7838],
  [-31.7372, 150.7824],
  [-31.7363, 150.7818],
  [-31.7354, 150.7818],
  [-31.7307, 150.7825],
  [-31.7276, 150.7838],
  [-31.7267, 150.7839],
  [-31.7256, 150.7846],
  [-31.7246, 150.7851],
  [-31.7239, 150.7851],
  [-31.7211, 150.7838],
  [-31.7205, 150.7836],
  [-31.7182, 150.7837],
  [-31.7168, 150.7834],
  [-31.7159, 150.7826],
  [-31.7151, 150.7813],
  [-31.7143, 150.7806],
  [-31.7134, 150.7803],
  [-31.7128, 150.7799],
  [-31.7123, 150.7791],
  [-31.7113, 150.7784],
  [-31.7093, 150.7784],
  [-31.7084, 150.7781],
  [-31.7072, 150.7774],
  [-31.7030, 150.7763],
  [-31.7018, 150.7756],
  [-31.7007, 150.7743],
  [-31.6996, 150.7736],
  [-31.6973, 150.7730],
  [-31.6959, 150.7721],
  [-31.6896, 150.7668],
  [-31.6875, 150.7658],
  [-31.6850, 150.7657],
  [-31.6838, 150.7652],
  [-31.6819, 150.7640],
  [-31.6807, 150.7623],
  [-31.6789, 150.7549],
  [-31.6755, 150.7489],
  [-31.6708, 150.7431],
  [-31.6692, 150.7417],
  [-31.6642, 150.7383],
  [-31.6434, 150.7206],
  [-31.6381, 150.7181],
  [-31.6205, 150.7151],
  [-31.6169, 150.7140],
  [-31.5941, 150.7042],
  [-31.5454, 150.6945],
  [-31.5328, 150.6882],
  [-31.5313, 150.6879],
  [-31.5256, 150.6877],
  [-31.5230, 150.6868],
  [-31.5214, 150.6850],
  [-31.5201, 150.6826],
  [-31.5184, 150.6809],
  [-31.5157, 150.6796],
  [-31.5133, 150.6793],
  [-31.5052, 150.6811]

];

const quirindiWestTamworth = [
  [-31.5052, 150.6811],
  [-31.4954, 150.6832],
  [-31.4922, 150.6832],
  [-31.4731, 150.6803],
  [-31.4683, 150.6782],
  [-31.4629, 150.6742],
  [-31.4617, 150.6729],
  [-31.4597, 150.6703],
  [-31.4499, 150.6614],
  [-31.4471, 150.6602],
  [-31.4418, 150.6598],
  [-31.3823, 150.6432],
  [-31.3773, 150.6429],
  [-31.3635, 150.6446],
  [-31.3504, 150.6463],
  [-31.3152, 150.6509],
  [-31.3097, 150.6531],
  [-31.2971, 150.6637],
  [-31.2946, 150.6669],
  [-31.2651, 150.7307],
  [-31.2642, 150.7336],
  [-31.2640, 150.7366],
  [-31.2658, 150.7428],
  [-31.2659, 150.7455],
  [-31.2648, 150.7522],
  [-31.2652, 150.7557],
  [-31.2665, 150.7586],
  [-31.2721, 150.7654],
  [-31.2730, 150.7681],
  [-31.2728, 150.7714],
  [-31.2716, 150.7737],
  [-31.2619, 150.7861],
  [-31.2597, 150.7882],
  [-31.1655, 150.8579],
  [-31.1490, 150.8741],
  [-31.1444, 150.8767],
  [-31.1350, 150.8794],
  [-31.1311, 150.8814],
  [-31.1012, 150.9039],
  [-31.0968, 150.9085],
  [-31.0957, 150.9098],
  [-31.0934, 150.9125]
];

const westTamworthKootingal = [
  [-31.0934, 150.9125],
  [-31.0845, 150.9236],
  [-31.0839, 150.9249],
  [-31.0839, 150.9265],
  [-31.0842, 150.9279],
  [-31.0853, 150.9291],
  [-31.0991, 150.9419],
  [-31.0998, 150.9430],
  [-31.1010, 150.9458],
  [-31.1018, 150.9471],
  [-31.1155, 150.9639],
  [-31.1212, 150.9709],
  [-31.1217, 150.9719],
  [-31.1220, 150.9732],
  [-31.1212, 150.9852],
  [-31.1202, 150.9891],
  [-31.1184, 150.9923],
  [-31.1151, 150.9950],
  [-31.1113, 150.9966],
  [-31.1096, 150.9978],
  [-31.1087, 150.9988],
  [-31.1078, 151.0000],
  [-31.1065, 151.0008],
  [-31.1032, 151.0018],
  [-31.1015, 151.0028],
  [-31.0921, 151.0125],
  [-31.0914, 151.0141],
  [-31.0908, 151.0186],
  [-31.0903, 151.0200],
  [-31.0890, 151.0214],
  [-31.0764, 151.0301],
  [-31.0752, 151.0316],
  [-31.0746, 151.0332],
  [-31.0739, 151.0394],
  [-31.0728, 151.0417],
  [-31.0715, 151.0430],
  [-31.0635, 151.0485],
  [-31.0608, 151.0516],
  [-31.0587, 151.0561]
];

const kootingalLimbriTunnel = [
  [-31.0587, 151.0561],
  [-31.0491, 151.0762],
  [-31.0481, 151.0803],
  [-31.0475, 151.0949],
  [-31.0471, 151.0977],
  [-31.0452, 151.1060],
  [-31.0454, 151.1085],
  [-31.0467, 151.1101],
  [-31.0481, 151.1109],
  [-31.0496, 151.1109],
  [-31.0516, 151.1102],
  [-31.0529, 151.1101],
  [-31.0547, 151.1104],
  [-31.0567, 151.1116],
  [-31.0580, 151.1138],
  [-31.0606, 151.1331],
  [-31.0604, 151.1348],
  [-31.0596, 151.1363],
  [-31.0564, 151.1396],
  [-31.0543, 151.1406],
  [-31.0516, 151.1412],
  [-31.0506, 151.1418],
  [-31.0500, 151.1435],
  [-31.0504, 151.1449],
  [-31.0513, 151.1462],
  [-31.0517, 151.1474],
  [-31.0511, 151.1491],
  [-31.0499, 151.1500],
  [-31.0440, 151.1499],
  [-31.0416, 151.1505],
  [-31.0393, 151.1520],
  [-31.0358, 151.1562]
];

const limbriTunnel = [
  [-31.0358, 151.1562],
  [-31.0347, 151.1574]
];

const limbriTunnelUralla = [
  [-31.0347, 151.1574],
  [-31.0333, 151.1590],
  [-31.0325, 151.1596],
  [-31.0266, 151.1621],
  [-31.0236, 151.1637],
  [-31.0217, 151.1656],
  [-31.0195, 151.1687],
  [-31.0184, 151.1695],
  [-31.0172, 151.1693],
  [-31.0164, 151.1687],
  [-31.0153, 151.1669],
  [-31.0141, 151.1662],
  [-31.0125, 151.1662],
  [-31.0085, 151.1695],
  [-31.0075, 151.1710],
  [-31.0069, 151.1730],
  [-31.0071, 151.1773],
  [-31.0076, 151.1788],
  [-31.0084, 151.1804],
  [-31.0083, 151.1822],
  [-31.0076, 151.1836],
  [-31.0063, 151.1847],
  [-31.0059, 151.1856],
  [-31.0058, 151.1867],
  [-31.0065, 151.1917],
  [-31.0063, 151.1937],
  [-31.0049, 151.1986],
  [-31.0048, 151.1996],
  [-31.0050, 151.2004],
  [-31.0052, 151.2013],
  [-31.0054, 151.2022],
  [-31.0051, 151.2042],
  [-31.0054, 151.2057],
  [-31.0060, 151.2068],
  [-31.0073, 151.2079],
  [-31.0079, 151.2088],
  [-31.0093, 151.2124],
  [-31.0093, 151.2136],
  [-31.0087, 151.2147],
  [-31.0079, 151.2153],
  [-31.0062, 151.2156],
  [-31.0051, 151.2162],
  [-31.0043, 151.2176],
  [-31.0033, 151.2185],
  [-31.0022, 151.2194],
  [-31.0015, 151.2204],
  [-31.0011, 151.2219],
  [-31.0010, 151.2273],
  [-31.0004, 151.2289],
  [-30.9996, 151.2299],
  [-30.9982, 151.2308],
  [-30.9975, 151.2320],
  [-30.9972, 151.2338],
  [-30.9978, 151.2356],
  [-30.9992, 151.2372],
  [-30.9999, 151.2385],
  [-30.9999, 151.2398],
  [-30.9998, 151.2410],
  [-31.0000, 151.2422],
  [-31.0007, 151.2438],
  [-31.0008, 151.2448],
  [-31.0006, 151.2457],
  [-31.0002, 151.2466],
  [-31.0001, 151.2477],
  [-31.0003, 151.2487],
  [-31.0016, 151.2512],
  [-31.0021, 151.2524],
  [-31.0024, 151.2540],
  [-31.0033, 151.2554],
  [-31.0049, 151.2564],
  [-31.0063, 151.2583],
  [-31.0078, 151.2597],
  [-31.0100, 151.2610],
  [-31.0110, 151.2619],
  [-31.0116, 151.2633],
  [-31.0119, 151.2646],
  [-31.0127, 151.2658],
  [-31.0140, 151.2666],
  [-31.0148, 151.2676],
  [-31.0151, 151.2686],
  [-31.0150, 151.2697],
  [-31.0142, 151.2723],
  [-31.0134, 151.2737],
  [-31.0063, 151.2805],
  [-31.0057, 151.2815],
  [-31.0056, 151.2822],
  [-31.0056, 151.2832],
  [-31.0050, 151.2847],
  [-31.0003, 151.2893],
  [-30.9995, 151.2908],
  [-30.9995, 151.2922],
  [-30.9997, 151.2933],
  [-30.9995, 151.2949],
  [-30.9987, 151.2961],
  [-30.9974, 151.2968],
  [-30.9956, 151.2970],
  [-30.9940, 151.2982],
  [-30.9935, 151.2998],
  [-30.9937, 151.3049],
  [-30.9932, 151.3067],
  [-30.9924, 151.3076],
  [-30.9842, 151.3120],
  [-30.9815, 151.3148],
  [-30.9802, 151.3170],
  [-30.9790, 151.3193],
  [-30.9776, 151.3212],
  [-30.9686, 151.3305],
  [-30.9677, 151.3322],
  [-30.9676, 151.3340],
  [-30.9688, 151.3430],
  [-30.9687, 151.3454],
  [-30.9671, 151.3499],
  [-30.9656, 151.3518],
  [-30.9619, 151.3531],
  [-30.9611, 151.3549],
  [-30.9607, 151.3560],
  [-30.9605, 151.3575],
  [-30.9599, 151.3586],
  [-30.9589, 151.3592],
  [-30.9570, 151.3593],
  [-30.9559, 151.3598],
  [-30.9553, 151.3607],
  [-30.9550, 151.3620],
  [-30.9554, 151.3634],
  [-30.9567, 151.3649],
  [-30.9574, 151.3667],
  [-30.9573, 151.3684],
  [-30.9543, 151.3745],
  [-30.9540, 151.3762],
  [-30.9546, 151.3779],
  [-30.9557, 151.3789],
  [-30.9577, 151.3797],
  [-30.9592, 151.3811],
  [-30.9597, 151.3833],
  [-30.9594, 151.3849],
  [-30.9584, 151.3862],
  [-30.9558, 151.3886],
  [-30.9549, 151.3903],
  [-30.9545, 151.3931],
  [-30.9538, 151.3943],
  [-30.9528, 151.3952],
  [-30.9508, 151.3962],
  [-30.9490, 151.3978],
  [-30.9477, 151.3999],
  [-30.9468, 151.4013],
  [-30.9458, 151.4017],
  [-30.9446, 151.4017],
  [-30.9438, 151.4019],
  [-30.9426, 151.4025],
  [-30.9415, 151.4025],
  [-30.9403, 151.4017],
  [-30.9394, 151.4008],
  [-30.9379, 151.4001],
  [-30.9313, 151.4006],
  [-30.9302, 151.4011],
  [-30.9297, 151.4019],
  [-30.9291, 151.4034],
  [-30.9280, 151.4051],
  [-30.9265, 151.4062],
  [-30.9218, 151.4079],
  [-30.9202, 151.4089],
  [-30.9191, 151.4101],
  [-30.9175, 151.4123],
  [-30.9162, 151.4130],
  [-30.9148, 151.4128],
  [-30.9125, 151.4117],
  [-30.9108, 151.4114],
  [-30.9083, 151.4116],
  [-30.9059, 151.4128],
  [-30.9033, 151.4132],
  [-30.9014, 151.4128],
  [-30.8986, 151.4117],
  [-30.8968, 151.4115],
  [-30.8953, 151.4118],
  [-30.8939, 151.4117],
  [-30.8927, 151.4110],
  [-30.8874, 151.4067],
  [-30.8859, 151.4063],
  [-30.8844, 151.4069],
  [-30.8828, 151.4088],
  [-30.8817, 151.4096],
  [-30.8773, 151.4119],
  [-30.8758, 151.4134],
  [-30.8752, 151.4149],
  [-30.8746, 151.4185],
  [-30.8733, 151.4214],
  [-30.8690, 151.4267],
  [-30.8669, 151.4282],
  [-30.8646, 151.4287],
  [-30.8252, 151.4296],
  [-30.8235, 151.4303],
  [-30.8221, 151.4318],
  [-30.8197, 151.4366],
  [-30.8166, 151.4395],
  [-30.8125, 151.4415],
  [-30.8083, 151.4420],
  [-30.7994, 151.4404],
  [-30.7933, 151.4401],
  [-30.7860, 151.4411],
  [-30.7815, 151.4425],
  [-30.7573, 151.4518],
  [-30.7536, 151.4522],
  [-30.7377, 151.4511],
  [-30.7321, 151.4522],
  [-30.7271, 151.4542],
  [-30.7214, 151.4583],
  [-30.7189, 151.4611],
  [-30.7025, 151.4834],
  [-30.6983, 151.4874],
  [-30.6825, 151.4967],
  [-30.6634, 151.5035],
  [-30.6588, 151.5038],
  [-30.6497, 151.5026],
  [-30.6479, 151.5028],
  [-30.6440, 151.5050]
];

const urallaArmidale = [
  [-30.6440, 151.5050],
  [-30.6362, 151.5092],
  [-30.6344, 151.5097],
  [-30.6325, 151.5097],
  [-30.6311, 151.5098],
  [-30.6301, 151.5104],
  [-30.6270, 151.5133],
  [-30.6257, 151.5156],
  [-30.6216, 151.5296],
  [-30.6194, 151.5366],
  [-30.5778, 151.6094],
  [-30.5762, 151.6133],
  [-30.5647, 151.6565],
  [-30.5626, 151.6604],
  [-30.5596, 151.6631],
  [-30.5555, 151.6646],
  [-30.5310, 151.6643],
  [-30.5279, 151.6634],
  [-30.5258, 151.6620],
  [-30.5152, 151.6516]
];

const armidaleDumaresq = [
  [-30.5152, 151.6516],
  [-30.4918, 151.6284],
  [-30.4886, 151.6266],
  [-30.4798, 151.6250],
  [-30.4777, 151.6238],
  [-30.4759, 151.6217],
  [-30.4663, 151.6022],
  [-30.4651, 151.6008],
  [-30.4636, 151.5994],
  [-30.4627, 151.5977],
  [-30.4624, 151.5954],
  [-30.4638, 151.5894],
  [-30.4639, 151.5867],
  [-30.4630, 151.5834],
  [-30.4616, 151.5812]
];

const durmaresqGlenInnes = [
  [-30.4616, 151.5812],
  [-30.4581, 151.5750],
  [-30.4532, 151.5691],
  [-30.4486, 151.5654],
  [-30.4417, 151.5619],
  [-30.4392, 151.5617],
  [-30.4365, 151.5627],
  [-30.4340, 151.5651],
  [-30.4307, 151.5675],
  [-30.4273, 151.5684],
  [-30.4235, 151.5684],
  [-30.4198, 151.5669],
  [-30.4182, 151.5660],
  [-30.4167, 151.5660],
  [-30.4155, 151.5666],
  [-30.4126, 151.5694],
  [-30.4117, 151.5709],
  [-30.4103, 151.5747],
  [-30.4089, 151.5762],
  [-30.4071, 151.5767],
  [-30.4055, 151.5763],
  [-30.4035, 151.5745],
  [-30.4017, 151.5743],
  [-30.4005, 151.5752],
  [-30.3997, 151.5769],
  [-30.3987, 151.5776],
  [-30.3977, 151.5776],
  [-30.3948, 151.5770],
  [-30.3927, 151.5772],
  [-30.3901, 151.5790],
  [-30.3888, 151.5818],
  [-30.3880, 151.5861],
  [-30.3868, 151.5881],
  [-30.3844, 151.5897],
  [-30.3822, 151.5897],
  [-30.3807, 151.5890],
  [-30.3788, 151.5878],
  [-30.3766, 151.5874],
  [-30.3752, 151.5879],
  [-30.3735, 151.5896],
  [-30.3606, 151.6101],
  [-30.3583, 151.6119],
  [-30.3558, 151.6126],
  [-30.3528, 151.6118],
  [-30.3507, 151.6103],
  [-30.3498, 151.6090],
  [-30.3479, 151.6084],
  [-30.3466, 151.6090],
  [-30.3456, 151.6104],
  [-30.3437, 151.6202],
  [-30.3424, 151.6222],
  [-30.3405, 151.6233],
  [-30.3380, 151.6233],
  [-30.3363, 151.6239],
  [-30.3350, 151.6254],
  [-30.3340, 151.6280],
  [-30.3327, 151.6303],
  [-30.3304, 151.6327],
  [-30.3295, 151.6341],
  [-30.3291, 151.6358],
  [-30.3283, 151.6428],
  [-30.3269, 151.6455],
  [-30.3139, 151.6568],
  [-30.3123, 151.6573],
  [-30.3103, 151.6569],
  [-30.3081, 151.6558],
  [-30.3064, 151.6556],
  [-30.3044, 151.6563],
  [-30.3033, 151.6578],
  [-30.3015, 151.6615],
  [-30.2998, 151.6631],
  [-30.2980, 151.6638],
  [-30.2968, 151.6638],
  [-30.2946, 151.6638],
  [-30.2927, 151.6643],
  [-30.2800, 151.6725],
  [-30.2766, 151.6737],
  [-30.2745, 151.6733],
  [-30.2723, 151.6717],
  [-30.2677, 151.6667],
  [-30.2654, 151.6654],
  [-30.2631, 151.6652],
  [-30.2467, 151.6679],
  [-30.1886, 151.6774],
  [-30.1848, 151.6787],
  [-30.1758, 151.6841],
  [-30.1703, 151.6854],
  [-30.1658, 151.6851],
  [-30.1618, 151.6836],
  [-30.1586, 151.6818],
  [-30.1563, 151.6812],
  [-30.1541, 151.6814],
  [-30.1487, 151.6833],
  [-30.1434, 151.6837],
  [-30.1345, 151.6830],
  [-30.1311, 151.6834],
  [-30.1179, 151.6872],
  [-30.1164, 151.6872],
  [-30.1148, 151.6860],
  [-30.1120, 151.6822],
  [-30.1111, 151.6814],
  [-30.1096, 151.6810],
  [-30.1051, 151.6816],
  [-30.1039, 151.6813],
  [-30.1030, 151.6808],
  [-30.1012, 151.6801],
  [-30.0807, 151.6776],
  [-30.0771, 151.6764],
  [-30.0751, 151.6750],
  [-30.0656, 151.6673],
  [-30.0636, 151.6661],
  [-30.0601, 151.6651],
  [-30.0572, 151.6650],
  [-30.0405, 151.6684],
  [-30.0387, 151.6683],
  [-30.0369, 151.6678],
  [-30.0286, 151.6627],
  [-30.0146, 151.6579],
  [-30.0130, 151.6581],
  [-30.0117, 151.6592],
  [-30.0023, 151.6736],
  [-30.0016, 151.6757],
  [-30.0011, 151.6768],
  [-29.9998, 151.6782],
  [-29.9990, 151.6807],
  [-29.9980, 151.6827],
  [-29.9978, 151.6844],
  [-29.9976, 151.6856],
  [-29.9967, 151.6868],
  [-29.9958, 151.6871],
  [-29.9948, 151.6869],
  [-29.9940, 151.6870],
  [-29.9933, 151.6874],
  [-29.9888, 151.6925],
  [-29.9883, 151.6942],
  [-29.9885, 151.6954],
  [-29.9892, 151.6971],
  [-29.9892, 151.6984],
  [-29.9885, 151.7001],
  [-29.9882, 151.7016],
  [-29.9882, 151.7034],
  [-29.9875, 151.7047],
  [-29.9864, 151.7053],
  [-29.9852, 151.7051],
  [-29.9796, 151.7019],
  [-29.9775, 151.7016],
  [-29.9724, 151.7016],
  [-29.9651, 151.7038],
  [-29.9393, 151.7195],
  [-29.9365, 151.7208],
  [-29.9315, 151.7218],
  [-29.9039, 151.7284],
  [-29.9013, 151.7293],
  [-29.8986, 151.7310],
  [-29.8962, 151.7320],
  [-29.8942, 151.7324],
  [-29.8920, 151.7331],
  [-29.8766, 151.7389],
  [-29.8652, 151.7432],
  [-29.8451, 151.7464],
  [-29.8423, 151.7465],
  [-29.8398, 151.7460],
  [-29.7995, 151.7320],
  [-29.7954, 151.7305],
  [-29.7934, 151.7306],
  [-29.7910, 151.7317],
  [-29.7896, 151.7318],
  [-29.7767, 151.7308],
  [-29.7679, 151.7307],
  [-29.7530, 151.7309],
  [-29.7512, 151.7304],
  [-29.7465, 151.7279],
  [-29.7434, 151.7264],
  [-29.7425, 151.7261],
  [-29.7415, 151.7261],
  [-29.7366, 151.7270]
];

const glenInnesTenterfield = [
  [-29.7366, 151.7270],
  [-29.7281, 151.7287],
  [-29.7004, 151.7386],
  [-29.6956, 151.7397],
  [-29.6863, 151.7408],
  [-29.6846, 151.7417],
  [-29.6837, 151.7426],
  [-29.6825, 151.7446],
  [-29.6809, 151.7461],
  [-29.6770, 151.7478],
  [-29.6750, 151.7494],
  [-29.6739, 151.7515],
  [-29.6718, 151.7537],
  [-29.6660, 151.7563],
  [-29.6641, 151.7582],
  [-29.6592, 151.7682],
  [-29.6576, 151.7701],
  [-29.6442, 151.7816],
  [-29.6421, 151.7824],
  [-29.6399, 151.7821],
  [-29.6383, 151.7810],
  [-29.6368, 151.7797],
  [-29.6345, 151.7790],
  [-29.6141, 151.7805],
  [-29.6113, 151.7799],
  [-29.6077, 151.7783],
  [-29.6045, 151.7782],
  [-29.6013, 151.7800],
  [-29.5996, 151.7824],
  [-29.5984, 151.7862],
  [-29.5975, 151.7879],
  [-29.5965, 151.7896],
  [-29.5961, 151.7913],
  [-29.5962, 151.7938],
  [-29.5948, 151.7966],
  [-29.5925, 151.7980],
  [-29.5886, 151.7980],
  [-29.5855, 151.7990],
  [-29.5832, 151.8010],
  [-29.5802, 151.8046],
  [-29.5771, 151.8065],
  [-29.5479, 151.8176],
  [-29.5436, 151.8206],
  [-29.5328, 151.8297],
  [-29.5287, 151.8314],
  [-29.5057, 151.8363],
  [-29.4648, 151.8457],
  [-29.4466, 151.8503],
  [-29.4365, 151.8522],
  [-29.4317, 151.8545],
  [-29.3999, 151.8750],
  [-29.3921, 151.8829],
  [-29.3887, 151.8889],
  [-29.3864, 151.8907],
  [-29.3799, 151.8934],
  [-29.3776, 151.8938],
  [-29.3748, 151.8932],
  [-29.3733, 151.8932],
  [-29.3719, 151.8941],
  [-29.3683, 151.8979],
  [-29.3642, 151.9001],
  [-29.3614, 151.9003],
  [-29.3523, 151.8982],
  [-29.3501, 151.8969],
  [-29.3489, 151.8954],
  [-29.3472, 151.8947],
  [-29.3453, 151.8951],
  [-29.3382, 151.9008],
  [-29.3349, 151.9026],
  [-29.3328, 151.9035],
  [-29.3311, 151.9051],
  [-29.3290, 151.9094],
  [-29.3288, 151.9105],
  [-29.3288, 151.9122],
  [-29.3284, 151.9135],
  [-29.3273, 151.9143],
  [-29.3196, 151.9229],
  [-29.3189, 151.9243],
  [-29.3187, 151.9259],
  [-29.3181, 151.9270],
  [-29.3172, 151.9278],
  [-29.3168, 151.9290],
  [-29.3166, 151.9304],
  [-29.3160, 151.9323],
  [-29.3162, 151.9335],
  [-29.3173, 151.9344],
  [-29.3184, 151.9344],
  [-29.3199, 151.9331],
  [-29.3210, 151.9326],
  [-29.3222, 151.9326],
  [-29.3231, 151.9323],
  [-29.3245, 151.9317],
  [-29.3260, 151.9328],
  [-29.3263, 151.9347],
  [-29.3254, 151.9360],
  [-29.3246, 151.9367],
  [-29.3240, 151.9377],
  [-29.3238, 151.9390],
  [-29.3243, 151.9408],
  [-29.3237, 151.9432],
  [-29.3180, 151.9520],
  [-29.3159, 151.9533],
  [-29.3135, 151.9536],
  [-29.3086, 151.9524],
  [-29.3059, 151.9529],
  [-29.2827, 151.9693],
  [-29.2810, 151.9716],
  [-29.2789, 151.9764],
  [-29.2774, 151.9781],
  [-29.2692, 151.9833],
  [-29.2679, 151.9850],
  [-29.2670, 151.9871],
  [-29.2650, 151.9890],
  [-29.2624, 151.9896],
  [-29.2602, 151.9893],
  [-29.2586, 151.9896],
  [-29.2535, 151.9943],
  [-29.2513, 151.9953],
  [-29.2453, 151.9972],
  [-29.2404, 151.9998],
  [-29.2369, 152.0026],
  [-29.2335, 152.0061],
  [-29.2281, 152.0135],
  [-29.2275, 152.0151],
  [-29.2272, 152.0174],
  [-29.2260, 152.0195],
  [-29.2241, 152.0203],
  [-29.2187, 152.0206],
  [-29.2173, 152.0213],
  [-29.2164, 152.0223],
  [-29.2149, 152.0236],
  [-29.2128, 152.0236],
  [-29.2117, 152.0224],
  [-29.2102, 152.0203],
  [-29.2090, 152.0196],
  [-29.2079, 152.0196],
  [-29.2063, 152.0197],
  [-29.2051, 152.0194],
  [-29.1946, 152.0135],
  [-29.1925, 152.0132],
  [-29.1912, 152.0125],
  [-29.1905, 152.0114],
  [-29.1899, 152.0101],
  [-29.1886, 152.0091],
  [-29.1874, 152.0090],
  [-29.1862, 152.0096],
  [-29.1845, 152.0101],
  [-29.1831, 152.0096],
  [-29.1821, 152.0086],
  [-29.1810, 152.0075],
  [-29.1795, 152.0071],
  [-29.1781, 152.0079],
  [-29.1771, 152.0088],
  [-29.1750, 152.0097],
  [-29.1729, 152.0092],
  [-29.1715, 152.0080],
  [-29.1677, 152.0034],
  [-29.1667, 152.0025],
  [-29.1641, 152.0005],
  [-29.1625, 151.9984],
  [-29.1617, 151.9967],
  [-29.1611, 151.9948],
  [-29.1598, 151.9928],
  [-29.1578, 151.9920],
  [-29.1559, 151.9921],
  [-29.1507, 151.9941],
  [-29.1493, 151.9939],
  [-29.1482, 151.9930],
  [-29.1465, 151.9903],
  [-29.1448, 151.9892],
  [-29.1388, 151.9881],
  [-29.1369, 151.9880],
  [-29.1339, 151.9883],
  [-29.1311, 151.9895],
  [-29.1286, 151.9915],
  [-29.1242, 151.9962],
  [-29.1227, 151.9970],
  [-29.1206, 151.9969],
  [-29.1192, 151.9959],
  [-29.1177, 151.9943],
  [-29.1157, 151.9930],
  [-29.1059, 151.9907],
  [-29.0989, 151.9900],
  [-29.0953, 151.9902],
  [-29.0878, 151.9921],
  [-29.0749, 151.9976],
  [-29.0725, 151.9998],
  [-29.0705, 152.0026],
  [-29.0684, 152.0040],
  [-29.0585, 152.0047],
  [-29.0520, 152.0059]
];

const tenterfieldWallangarra = [
  [-29.0520, 152.0059],
  [-29.0474, 152.0067],
  [-29.0460, 152.0067],
  [-29.0335, 152.0056],
  [-29.0301, 152.0047],
  [-29.0261, 152.0022],
  [-29.0239, 152.0000],
  [-29.0221, 151.9978],
  [-29.0196, 151.9965],
  [-29.0169, 151.9960],
  [-29.0152, 151.9951],
  [-29.0138, 151.9935],
  [-29.0119, 151.9894],
  [-29.0102, 151.9866],
  [-29.0070, 151.9828],
  [-28.9997, 151.9743],
  [-28.9975, 151.9708],
  [-28.9913, 151.9562],
  [-28.9894, 151.9534],
  [-28.9830, 151.9473],
  [-28.9805, 151.9445],
  [-28.9717, 151.9326],
  [-28.9705, 151.9315],
  [-28.9689, 151.9306],
  [-28.9666, 151.9308],
  [-28.9500, 151.9340],
  [-28.9477, 151.9340],
  [-28.9459, 151.9334],
  [-28.9343, 151.9271],
  [-28.9324, 151.9268],
  [-28.9297, 151.9274],
  [-28.9240, 151.9313]
];

export const northernLine: Line = {
  name: 'Northern Line',
  state: 'NSW',
  segments: [
    {
      segments: [berowraCownan],
      history: {
        opened: {
          date: '1887-04-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1909-06-27',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        cowenBoroniaTunnel1,
        {
          name: 'Boronia No 1 Tunnel',
          segment: boroniaNo1Tunnel,
          type: 'tunnel'
        },
        boroniaTunnel1BoroniaTunnel2,
        {
          name: 'Boronia No 2 Tunnel',
          segment: boroniaNo2Tunnel,
          type: 'tunnel'
        },
        boroniaTunnel2BoroniaTunnel3,
        {
          name: 'Boronia No 3 Tunnel',
          segment: boroniaNo3Tunnel,
          type: 'tunnel'
        },
        boroniaTunnel3BoroniaTunnel4,
        {
          name: 'Boronia No 4 Tunnel',
          segment: boroniaNo4Tunnel,
          type: 'tunnel'
        },
        boroniaTunnel4BoroniaDeviationStart,
        {
          date: '1909-06-27',
          original: [
            boroniaDeviationStartBoroniaTunnel5,
            {
              name: 'Boronia No 5 Tunnel',
              segment: boroniaNo5Tunnel,
              type: 'tunnel'
            },
            boroniaTunnel5BoroniaDeviationEnd
          ],
          deviation: [boroniaNo5Deviation]
        },
        boroniaDeviationEndHawkesburyRiver
      ],
      history: {
        opened: {
          date: '1887-04-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1909-06-27',
          tracks: 2
        }, {
          date: '1960-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [hawkesburyRiverHawkesburyBridgeStart],
      history: {
        opened: {
          date: '1889-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1906-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [{
        date: '1946-07-01',
        original: [
          hawkesburyBridgeOldLongIslandTunnelOld,
          {
            name: 'Long Island Tunnel (Old)',
            segment: longIslandTunnelOld,
            type: 'tunnel'
          },
          longIslandTunnelOldHawkesburyBridgeOld
        ],
        deviation: [
          hawkesburyRiverNewLongIslandTunnel,
          {
            name: 'Long Island Tunnel',
            segment: longIslandTunnel,
            type: 'tunnel'
          },
          longIslandTunnelMulletCreekTunnel,
          {
            name: 'Mullet Creek Tunnel',
            segment: mulletCreekTunnel,
            type: 'tunnel'
          },
          mulletCreekTunnelHawkesburyRiverNew
        ]
      }],
      history: {
        opened: {
          date: '1889-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1946-07-01',
          tracks: 2
        }, {
          date: '1960-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        hawkesburyBridgeDeviationEndWondabyne,
        wondabyneWoyWoyTunnel,
        {
          name: 'Woy Woy Tunnel',
          segment: woyWoyTunnel,
          type: 'tunnel'
        },
        woyWoyTunnelGosford
      ],
      history: {
        opened: {
          date: '1889-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1906-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [gosfordWyong],
      history: {
        opened: {
          date: '1887-08-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-01-01',
          tracks: 2
        }, {
          date: '1982-04-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        wyongTickholeTunnel,
        {
          name: 'Tickhole Tunnel',
          segment: tickholeTunnel,
          type: 'tunnel'
        },
        tickholeTunnelIslingtonJunction
      ],
      history: {
        opened: {
          date: '1887-08-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-01-01',
          tracks: 2
        }, {
          date: '1984-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [islingtonJunction],
      history: {
        opened: {
          date: '1887-08-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [islingtonJunctionPortWaratahJunction],
      history: {
        opened: {
          date: '1857-03-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1864-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [islingtonJunctionTarro],
      history: {
        opened: {
          date: '1857-03-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1864-01-01',
          tracks: 2
        }, {
          date: '1913-02-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [tarroVictoriaStreet],
      history: {
        opened: {
          date: '1857-03-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1880-01-01',
          tracks: 2
        }, {
          date: '1913-02-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [victoriaStreetMaitland],
      history: {
        opened: {
          date: '1858-07-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1880-01-01',
          tracks: 2
        }, {
          date: '1913-02-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [maitlandCessnockJunction],
      history: {
        opened: {
          date: '1860-07-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1880-01-01',
          tracks: 2
        }, {
          date: '2012-01-01',
          tracks: 3
        }, {
          date: '1913-02-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [cessnockJunctionLochinvar],
      history: {
        opened: {
          date: '1860-07-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1914-01-01',
          tracks: 2
        }, {
          date: '2012-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [lochinvarBranxton],
      history: {
        opened: {
          date: '1862-03-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [branxtonWhittingham],
      history: {
        opened: {
          date: '1863-05-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-01-01',
          tracks: 2
        }, {
          date: '2012-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [whittinghameSingleton],
      history: {
        opened: {
          date: '1863-05-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [singletonHunterRiver],
      history: {
        opened: {
          date: '1869-05-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [{
        date: '1952-12-09',
        original: [hunterRiverNundahJunction],
        deviation: [hunterRiverDeviation]
      }],
      history: {
        opened: {
          date: '1869-05-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-12-09',
          tracks: 2
        }]
      }
    },
    {
      segments: [nundahJunctionNewdellJunction],
      history: {
        opened: {
          date: '1869-05-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-12-09',
          tracks: 2
        }]
      }
    },
    {
      segments: [newdellJunctionOldMainNorthDeadEnd],
      history: {
        opened: {
          date: '1869-05-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-12-09',
          tracks: 2
        }]
      }
    },
    {
      segments: [oldMainNorthDeadEndOldMainNorthDeadEndTwo],
      history: {
        opened: {
          date: '1869-05-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-12-09',
          tracks: 2
        }, {
          date: '1979-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [{
        date: '1966-08-28',
        original: [oldMainNorthDeadEndTwoAntieneJunction],
        deviation: [liddellDeviation]
      }],
      history: {
        opened: {
          date: '1869-05-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-12-09',
          tracks: 2
        }]
      }
    },
    {
      segments: [antieneJunctionMuswellbrook],
      history: {
        opened: {
          date: '1869-05-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-12-09',
          tracks: 2
        }]
      }
    },
    {
      segments: [muswellbrookAberdeen],
      history: {
        opened: {
          date: '1870-10-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [aberdeenScone],
      history: {
        opened: {
          date: '1871-04-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [sconeWingen],
      history: {
        opened: {
          date: '1871-08-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wingenMurrurundi],
      history: {
        opened: {
          date: '1872-05-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        murrurundiArdglenTunnel,
        {
          name: 'Ardglen Tunnel',
          segment: ardglenTunnel,
          type: 'tunnel'
        },
        ardglenTunnelQuirindi
      ],
      history: {
        opened: {
          date: '1877-08-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [quirindiWestTamworth],
      history: {
        opened: {
          date: '1878-10-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [westTamworthKootingal],
      history: {
        opened: {
          date: '1882-01-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        kootingalLimbriTunnel,
        {
          name: 'Limbri Tunnel',
          segment: limbriTunnel,
          type: 'tunnel'
        },
        limbriTunnelUralla
      ],
      history: {
        opened: {
          date: '1882-08-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [urallaArmidale],
      history: {
        opened: {
          date: '1883-02-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [armidaleDumaresq],
      history: {
        opened: {
          date: '1884-08-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [durmaresqGlenInnes],
      history: {
        opened: {
          date: '1884-08-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-10-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenInnesTenterfield],
      history: {
        opened: {
          date: '1886-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-10-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tenterfieldWallangarra],
      history: {
        opened: {
          date: '1888-01-16',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-10-26',
          status: 'closed'
        }]
      }
    }
  ]
};
