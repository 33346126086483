import { Line } from "../../../trackTypes";

const line = [
  [-45.4546, 170.8081],
  [-45.4557, 170.8078],
  [-45.4562, 170.8079],
  [-45.4575, 170.8095],
  [-45.4592, 170.8111],
  [-45.4604, 170.8134],
  [-45.4608, 170.8155],
  [-45.4619, 170.8186],
  [-45.4659, 170.8261],
  [-45.4670, 170.8271],
  [-45.4700, 170.8287],
  [-45.4713, 170.8296],
  [-45.4732, 170.8303],
  [-45.4733, 170.8304],
  [-45.4734, 170.8305]
];

export const shagPoint: Line = {
  name: 'Shag Point',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1879-08-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
