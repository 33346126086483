import { Line } from "../../../trackTypes";

const portDampierRobeRiverJunction = [
  [-20.6622, 116.7328],
  [-20.6592, 116.7328],
  [-20.6556, 116.7342],
  [-20.6529, 116.7366],
  [-20.6505, 116.7377],
  [-20.6483, 116.7372],
  [-20.6468, 116.7359],
  [-20.6461, 116.7344],
  [-20.6451, 116.7288],
  [-20.6455, 116.7273],
  [-20.6468, 116.7255],
  [-20.6487, 116.7245],
  [-20.6677, 116.7186],
  [-20.6695, 116.7177],
  [-20.6753, 116.7131],
  [-20.6770, 116.7123],
  [-20.6791, 116.7122],
  [-20.6816, 116.7133],
  [-20.6834, 116.7158],
  [-20.6857, 116.7268],
  [-20.6876, 116.7302],
  [-20.7123, 116.7547],
  [-20.7151, 116.7563],
  [-20.7808, 116.7632],
  [-20.7837, 116.7639],
  [-20.7901, 116.7684],
  [-20.7930, 116.7696],
  [-20.8147, 116.7724],
  [-20.8184, 116.7741],
  [-20.8253, 116.7810],
  [-20.8283, 116.7826],
  [-20.8459, 116.7847],
  [-20.8507, 116.7865],
  [-20.8534, 116.7893],
  [-20.8644, 116.8071],
  [-20.8666, 116.8165],
  [-20.8682, 116.8192],
  [-20.8706, 116.8207],
  [-20.9063, 116.8301],
  [-20.9092, 116.8316],
  [-20.9116, 116.8349],
  [-20.9139, 116.8369],
  [-20.9279, 116.8432],
  [-20.9318, 116.8442],
  [-20.9339, 116.8449],
  [-20.9446, 116.8509],
  [-20.9696, 116.8583],
  [-20.9728, 116.8605],
  [-20.9803, 116.8716],
  [-20.9861, 116.8840],
  [-20.9895, 116.8880],
  [-21.0008, 116.8941],
  [-21.0034, 116.8960],
  [-21.0127, 116.9059],
  [-21.0156, 116.9082],
  [-21.0302, 116.9152],
  [-21.0320, 116.9166],
  [-21.0375, 116.9244],
  [-21.0431, 116.9311],
  [-21.0528, 116.9489],
  [-21.0547, 116.9512],
  [-21.0628, 116.9587],
  [-21.0692, 116.9697],
  [-21.0707, 116.9716],
  [-21.0781, 116.9780],
  [-21.0847, 116.9818],
  [-21.0867, 116.9842],
  [-21.0877, 116.9870],
  [-21.0888, 116.9887],
  [-21.0909, 116.9899],
  [-21.1108, 116.9938],
  [-21.1367, 116.9964],
  [-21.1389, 116.9969],
  [-21.1660, 117.0079],
  [-21.1693, 117.0113],
  [-21.1726, 117.0216],
  [-21.1750, 117.0263],
  [-21.1763, 117.0334],
  [-21.1784, 117.0408],
  [-21.1788, 117.0450],
  [-21.1803, 117.0480],
  [-21.1835, 117.0496],
  [-21.1864, 117.0491],
  [-21.1966, 117.0436],
  [-21.2004, 117.0431],
  [-21.2073, 117.0443],
  [-21.2103, 117.0457],
  [-21.2132, 117.0484],
  [-21.2159, 117.0500]
];

const portSpur = [
  [-20.6851, 116.7237],
  [-20.6848, 116.7167],
  [-20.6812, 116.7042],
  [-20.6808, 116.7016],
  [-20.6809, 116.6954],
  [-20.6814, 116.6933],
  [-20.6826, 116.6914],
  [-20.6838, 116.6899],
  [-20.6843, 116.6878],
  [-20.6843, 116.6786],
  [-20.6847, 116.6766],
  [-20.6863, 116.6752]
];

const wombatJunctionParaburdooMine = [
  [-22.6878, 117.7841],
  [-22.6925, 117.7832],
  [-22.6964, 117.7842],
  [-22.7007, 117.7871],
  [-22.7038, 117.7930],
  [-22.7049, 117.8023],
  [-22.7079, 117.8078],
  [-22.7120, 117.8108],
  [-22.7162, 117.8165],
  [-22.7297, 117.8292],
  [-22.7389, 117.8428],
  [-22.7413, 117.8508],
  [-22.7447, 117.8558],
  [-22.7501, 117.8582],
  [-22.7559, 117.8573],
  [-22.7752, 117.8386],
  [-22.7788, 117.8356],
  [-22.7843, 117.8325],
  [-22.7880, 117.8287],
  [-22.8005, 117.7994],
  [-22.8050, 117.7948],
  [-22.8119, 117.7942],
  [-22.8512, 117.8039],
  [-22.8558, 117.8065],
  [-22.8588, 117.8109],
  [-22.8596, 117.8170],
  [-22.8579, 117.8268],
  [-22.8586, 117.8313],
  [-22.8609, 117.8354],
  [-22.8651, 117.8386],
  [-22.8848, 117.8448],
  [-22.8887, 117.8454],
  [-22.8984, 117.8447],
  [-22.9019, 117.8441],
  [-22.9079, 117.8418],
  [-22.9125, 117.8409],
  [-22.9177, 117.8419],
  [-22.9233, 117.8448],
  [-22.9315, 117.8468],
  [-22.9351, 117.8484],
  [-22.9404, 117.8522],
  [-22.9430, 117.8553],
  [-22.9459, 117.8611],
  [-22.9509, 117.8657],
  [-22.9576, 117.8682],
  [-22.9637, 117.8727],
  [-22.9782, 117.8800],
  [-22.9917, 117.8832],
  [-22.9981, 117.8871],
  [-23.0028, 117.8889],
  [-23.0066, 117.8915],
  [-23.0111, 117.8966],
  [-23.0199, 117.9031],
  [-23.0255, 117.9100],
  [-23.0269, 117.9127],
  [-23.0328, 117.9280],
  [-23.0353, 117.9317],
  [-23.0400, 117.9364],
  [-23.0423, 117.9395],
  [-23.0476, 117.9514],
  [-23.0521, 117.9569],
  [-23.0615, 117.9635],
  [-23.0664, 117.9645],
  [-23.0881, 117.9621],
  [-23.0950, 117.9580],
  [-23.1179, 117.9245],
  [-23.1197, 117.9202],
  [-23.1252, 117.8902],
  [-23.1263, 117.8609],
  [-23.1301, 117.8405],
  [-23.1312, 117.8370],
  [-23.1391, 117.8211],
  [-23.1428, 117.8168],
  [-23.1525, 117.8100],
  [-23.1571, 117.8079],
  [-23.1811, 117.8044],
  [-23.1871, 117.8010],
  [-23.1913, 117.7929],
  [-23.1927, 117.7772],
  [-23.1910, 117.7604],
  [-23.1917, 117.7544],
  [-23.1973, 117.7413],
  [-23.1985, 117.7366],
  [-23.1984, 117.7326],
  [-23.1940, 117.7163],
  [-23.1936, 117.7117],
  [-23.1949, 117.7064],
  [-23.2076, 117.6784],
  [-23.2162, 117.6419],
  [-23.2171, 117.6390],
  [-23.2239, 117.6266],
  [-23.2259, 117.6243],
  [-23.2277, 117.6232],
  [-23.2359, 117.6207],
  [-23.2388, 117.6211],
  [-23.2415, 117.6236],
  [-23.2422, 117.6267],
  [-23.2416, 117.6312],
  [-23.2397, 117.6340],
  [-23.2368, 117.6352],
  [-23.2339, 117.6344],
  [-23.2318, 117.6322],
  [-23.2305, 117.6284],
  [-23.2292, 117.6262],
  [-23.2270, 117.6253],
  [-23.2254, 117.6256],
  [-23.2239, 117.6266]
];

const rosellaJunctionMarandoo = [
  [-22.4226, 117.6939],
  [-22.4314, 117.7001],
  [-22.4350, 117.7072],
  [-22.4360, 117.7118],
  [-22.4373, 117.7680],
  [-22.4379, 117.7714],
  [-22.4446, 117.7937],
  [-22.4451, 117.7988],
  [-22.4450, 117.8093],
  [-22.4461, 117.8146],
  [-22.4496, 117.8214],
  [-22.4509, 117.8253],
  [-22.4527, 117.8363],
  [-22.4554, 117.8428],
  [-22.4593, 117.8472],
  [-22.4699, 117.8552],
  [-22.4741, 117.8617],
  [-22.4787, 117.8917],
  [-22.4817, 117.8990],
  [-22.4854, 117.9029],
  [-22.4992, 117.9108],
  [-22.5085, 117.9208],
  [-22.5130, 117.9234],
  [-22.5189, 117.9242],
  [-22.5402, 117.9186],
  [-22.5454, 117.9189],
  [-22.5577, 117.9235],
  [-22.5646, 117.9292],
  [-22.5751, 117.9475],
  [-22.5874, 117.9630],
  [-22.5898, 117.9697],
  [-22.5904, 117.9957],
  [-22.5916, 118.0003],
  [-22.5937, 118.0037],
  [-22.5963, 118.0084],
  [-22.6010, 118.0275],
  [-22.6020, 118.0471],
  [-22.6072, 118.0753],
  [-22.6085, 118.0787],
  [-22.6209, 118.1013],
  [-22.6274, 118.1135],
  [-22.6293, 118.1153],
  [-22.6316, 118.1158],
  [-22.6340, 118.1149],
  [-22.6356, 118.1131],
  [-22.6358, 118.1106],
  [-22.6343, 118.1082],
  [-22.6223, 118.1026],
  [-22.6217, 118.1021],
  [-22.6209, 118.1013]
];

const rosellaJunctionBrockmanTwo = [
  [-22.4226, 117.6939],
  [-22.4295, 117.6956],
  [-22.4344, 117.6946],
  [-22.4390, 117.6910],
  [-22.4416, 117.6866],
  [-22.4424, 117.6825],
  [-22.4422, 117.6232],
  [-22.4406, 117.6117],
  [-22.4359, 117.5950],
  [-22.4329, 117.5888],
  [-22.4164, 117.5672],
  [-22.4137, 117.5627],
  [-22.4027, 117.5361],
  [-22.3997, 117.5229],
  [-22.3997, 117.5134],
  [-22.4005, 117.5057],
  [-22.4004, 117.5018],
  [-22.3978, 117.4761],
  [-22.3982, 117.4730],
  [-22.3993, 117.4707],
  [-22.4047, 117.4640],
  [-22.4063, 117.4595],
  [-22.4064, 117.4359],
  [-22.4030, 117.4231],
  [-22.4025, 117.4203],
  [-22.4017, 117.4028],
  [-22.4019, 117.3995],
  [-22.4042, 117.3868],
  [-22.4049, 117.3794],
  [-22.4071, 117.3758],
  [-22.4100, 117.3736],
  [-22.4178, 117.3706],
  [-22.4204, 117.3679],
  [-22.4213, 117.3647],
  [-22.4204, 117.3559],
  [-22.4205, 117.3534],
  [-22.4216, 117.3486],
  [-22.4224, 117.3465],
  [-22.4259, 117.3398],
  [-22.4261, 117.3377],
  [-22.4251, 117.3354],
  [-22.4231, 117.3342],
  [-22.4209, 117.3344],
  [-22.4190, 117.3358],
  [-22.4184, 117.3380],
  [-22.4187, 117.3398],
  [-22.4215, 117.3448],
  [-22.4218, 117.3462],
  [-22.4216, 117.3486]
];

const rosellaJunctionTomPriceMine = [
  [-22.4226, 117.6939],
  [-22.4998, 117.7276],
  [-22.5201, 117.7324],
  [-22.5238, 117.7340],
  [-22.5363, 117.7437],
  [-22.5415, 117.7456],
  [-22.5570, 117.7450],
  [-22.5728, 117.7480],
  [-22.6299, 117.7445],
  [-22.6331, 117.7453],
  [-22.6360, 117.7473],
  [-22.6384, 117.7476],
  [-22.6436, 117.7473],
  [-22.6458, 117.7480],
  [-22.6498, 117.7519],
  [-22.6508, 117.7552],
  [-22.6505, 117.7588],
  [-22.6528, 117.7675],
  [-22.6604, 117.7778],
  [-22.6768, 117.7883],
  [-22.6807, 117.7890],
  [-22.6838, 117.7876],
  [-22.6878, 117.7841],
  [-22.6942, 117.7790],
  [-22.7087, 117.7747],
  [-22.7124, 117.7744],
  [-22.7244, 117.7754],
  [-22.7274, 117.7741],
  [-22.7310, 117.7701],
  [-22.7328, 117.7690],
  [-22.7346, 117.7693],
  [-22.7383, 117.7714],
  [-22.7439, 117.7755],
  [-22.7454, 117.7758],
  [-22.7470, 117.7746],
  [-22.7484, 117.7697],
  [-22.7479, 117.7684],
  [-22.7469, 117.7677],
  [-22.7460, 117.7676],
  [-22.7447, 117.7681],
  [-22.7410, 117.7713],
  [-22.7399, 117.7716],
  [-22.7383, 117.7714]
];

const brockmanTwoNammuldi = [
  [-22.4049, 117.3794],
  [-22.4058, 117.3744],
  [-22.4056, 117.3715],
  [-22.4032, 117.3634],
  [-22.4004, 117.3538],
  [-22.3989, 117.3517],
  [-22.3964, 117.3505],
  [-22.3936, 117.3507],
  [-22.3918, 117.3520],
  [-22.3898, 117.3546],
  [-22.3892, 117.3566],
  [-22.3895, 117.3590],
  [-22.3907, 117.3609],
  [-22.3926, 117.3620],
  [-22.3944, 117.3620],
  [-22.3981, 117.3607],
  [-22.3997, 117.3607],
  [-22.4016, 117.3614],
  [-22.4025, 117.3622],
  [-22.4032, 117.3634]
];

const brockmanTwoBrockmanFour = [
  [-22.4178, 117.3706],
  [-22.4212, 117.3679],
  [-22.4224, 117.3652],
  [-22.4228, 117.3623],
  [-22.4226, 117.3566],
  [-22.4231, 117.3536],
  [-22.4241, 117.3516],
  [-22.4267, 117.3482],
  [-22.4279, 117.3457],
  [-22.4288, 117.3411],
  [-22.4297, 117.3389],
  [-22.4321, 117.3353],
  [-22.4332, 117.3311],
  [-22.4327, 117.3268],
  [-22.4331, 117.3233],
  [-22.4349, 117.3187],
  [-22.4354, 117.3155],
  [-22.4350, 117.3125],
  [-22.4296, 117.2985],
  [-22.4291, 117.2956],
  [-22.4294, 117.2925],
  [-22.4318, 117.2866],
  [-22.4350, 117.2830],
  [-22.4677, 117.2689],
  [-22.4779, 117.2663],
  [-22.4833, 117.2625],
  [-22.4966, 117.2430],
  [-22.5088, 117.2333],
  [-22.5121, 117.2324],
  [-22.5155, 117.2332],
  [-22.5192, 117.2366],
  [-22.5238, 117.2482],
  [-22.5243, 117.2522],
  [-22.5229, 117.2629],
  [-22.5234, 117.2670],
  [-22.5265, 117.2736],
  [-22.5282, 117.2760],
  [-22.5442, 117.2901],
  [-22.5478, 117.2920],
  [-22.5536, 117.2925],
  [-22.5568, 117.2917],
  [-22.5599, 117.2888],
  [-22.5632, 117.2837],
  [-22.5674, 117.2802],
  [-22.5754, 117.2773],
  [-22.5777, 117.2756],
  [-22.5793, 117.2732],
  [-22.5820, 117.2658],
  [-22.5821, 117.2620],
  [-22.5808, 117.2533],
  [-22.5800, 117.2424],
  [-22.5803, 117.2404],
  [-22.5825, 117.2320],
  [-22.5826, 117.2302],
  [-22.5820, 117.2288],
  [-22.5802, 117.2261],
  [-22.5783, 117.2253],
  [-22.5764, 117.2256],
  [-22.5747, 117.2274],
  [-22.5743, 117.2292],
  [-22.5747, 117.2310],
  [-22.5793, 117.2393],
  [-22.5798, 117.2406],
  [-22.5800, 117.2424]
];

const marandooMineYandicoogina = [
  [-22.6274, 118.1135],
  [-22.6291, 118.1174],
  [-22.6321, 118.1316],
  [-22.6388, 118.1577],
  [-22.6407, 118.1622],
  [-22.6442, 118.1675],
  [-22.6458, 118.1713],
  [-22.6473, 118.1791],
  [-22.6501, 118.1848],
  [-22.6609, 118.1982],
  [-22.6655, 118.2060],
  [-22.6683, 118.2093],
  [-22.6780, 118.2173],
  [-22.7081, 118.2490],
  [-22.7156, 118.2595],
  [-22.7270, 118.2695],
  [-22.7301, 118.2714],
  [-22.7524, 118.2801],
  [-22.7615, 118.2851],
  [-22.7705, 118.2950],
  [-22.7759, 118.3059],
  [-22.7765, 118.3103],
  [-22.7760, 118.3153],
  [-22.7768, 118.3191],
  [-22.7818, 118.3275],
  [-22.7834, 118.3344],
  [-22.7825, 118.3477],
  [-22.7836, 118.3548],
  [-22.7862, 118.3597],
  [-22.7924, 118.3672],
  [-22.7950, 118.3725],
  [-22.7994, 118.3898],
  [-22.8004, 118.3978],
  [-22.8026, 118.4028],
  [-22.8124, 118.4150],
  [-22.8190, 118.4305],
  [-22.8221, 118.4348],
  [-22.8282, 118.4404],
  [-22.8391, 118.4477],
  [-22.8576, 118.4668],
  [-22.8709, 118.4841],
  [-22.8731, 118.4899],
  [-22.8742, 118.5006],
  [-22.8762, 118.5098],
  [-22.8772, 118.5255],
  [-22.8750, 118.5447],
  [-22.8762, 118.5569],
  [-22.8750, 118.5694],
  [-22.8735, 118.5861],
  [-22.8701, 118.5933],
  [-22.8592, 118.6044],
  [-22.8567, 118.6119],
  [-22.8571, 118.6328],
  [-22.8556, 118.6522],
  [-22.8575, 118.6649],
  [-22.8576, 118.6851],
  [-22.8582, 118.6886],
  [-22.8640, 118.7058],
  [-22.8658, 118.7087],
  [-22.8789, 118.7198],
  [-22.8860, 118.7274],
  [-22.8880, 118.7311],
  [-22.8917, 118.7423],
  [-22.8922, 118.7485],
  [-22.8871, 118.7841],
  [-22.8847, 118.7884],
  [-22.8784, 118.7913],
  [-22.8754, 118.7910],
  [-22.8728, 118.7895],
  [-22.8676, 118.7838],
  [-22.8601, 118.7787],
  [-22.8541, 118.7766],
  [-22.8341, 118.7745],
  [-22.8306, 118.7759],
  [-22.8219, 118.7891],
  [-22.8193, 118.7913],
  [-22.7984, 118.8030],
  [-22.7954, 118.8058],
  [-22.7932, 118.8097],
  [-22.7914, 118.8155],
  [-22.7884, 118.8202],
  [-22.7542, 118.8558],
  [-22.7488, 118.8606],
  [-22.7453, 118.8664],
  [-22.7430, 118.8785],
  [-22.7448, 118.9027],
  [-22.7469, 118.9145],
  [-22.7463, 118.9206],
  [-22.7403, 118.9356],
  [-22.7399, 118.9394],
  [-22.7414, 118.9588],
  [-22.7435, 118.9650],
  [-22.7533, 118.9773],
  [-22.7579, 118.9817],
  [-22.7597, 118.9827],
  [-22.7623, 118.9834],
  [-22.7646, 118.9856],
  [-22.7655, 118.9894],
  [-22.7642, 118.9984],
  [-22.7639, 119.0130],
  [-22.7627, 119.0170],
  [-22.7586, 119.0228],
  [-22.7554, 119.0256],
  [-22.7544, 119.0276],
  [-22.7537, 119.0310],
  [-22.7539, 119.0346],
  [-22.7577, 119.0450],
  [-22.7578, 119.0489],
  [-22.7558, 119.0560],
  [-22.7556, 119.0594],
  [-22.7548, 119.0617],
  [-22.7528, 119.0646],
  [-22.7512, 119.0690],
  [-22.7511, 119.0712],
  [-22.7515, 119.0729],
  [-22.7540, 119.0770],
  [-22.7581, 119.0805],
  [-22.7598, 119.0836],
  [-22.7600, 119.0882],
  [-22.7608, 119.0907],
  [-22.7639, 119.0951],
  [-22.7682, 119.0975],
  [-22.7751, 119.1029],
  [-22.7778, 119.1059],
  [-22.7825, 119.1091],
  [-22.7860, 119.1141],
  [-22.7875, 119.1181],
  [-22.7893, 119.1203],
  [-22.7921, 119.1220],
  [-22.7944, 119.1252],
  [-22.7957, 119.1289],
  [-22.7957, 119.1346],
  [-22.7952, 119.1378],
  [-22.7956, 119.1409],
  [-22.7974, 119.1447],
  [-22.7976, 119.1468],
  [-22.7970, 119.1517],
  [-22.7957, 119.1543],
  [-22.7919, 119.1583],
  [-22.7908, 119.1608],
  [-22.7909, 119.1634],
  [-22.7921, 119.1671],
  [-22.7927, 119.1723],
  [-22.7921, 119.1754],
  [-22.7875, 119.1840],
  [-22.7869, 119.1862],
  [-22.7857, 119.1956],
  [-22.7844, 119.1981],
  [-22.7822, 119.1990],
  [-22.7801, 119.1986],
  [-22.7775, 119.1965],
  [-22.7768, 119.1945],
  [-22.7771, 119.1925],
  [-22.7779, 119.1911],
  [-22.7791, 119.1904],
  [-22.7829, 119.1892],
  [-22.7853, 119.1875],
  [-22.7875, 119.1840]
];

const westAngelas = [
  [-22.8750, 118.5694],
  [-22.8747, 118.5792],
  [-22.8756, 118.5837],
  [-22.8877, 118.6153],
  [-22.8902, 118.6196],
  [-22.9136, 118.6494],
  [-22.9163, 118.6560],
  [-22.9163, 118.6641],
  [-22.9121, 118.6754],
  [-22.9115, 118.6818],
  [-22.9156, 118.6984],
  [-22.9165, 118.7082],
  [-22.9184, 118.7126],
  [-22.9302, 118.7226],
  [-22.9330, 118.7256],
  [-22.9395, 118.7352],
  [-22.9505, 118.7443],
  [-22.9842, 118.7580],
  [-22.9907, 118.7584],
  [-22.9973, 118.7551],
  [-23.0013, 118.7502],
  [-23.0056, 118.7392],
  [-23.0076, 118.7299],
  [-23.0088, 118.7268],
  [-23.0165, 118.7131],
  [-23.0216, 118.7074],
  [-23.0236, 118.7037],
  [-23.0249, 118.6983],
  [-23.0269, 118.6950],
  [-23.0295, 118.6929],
  [-23.0601, 118.6762],
  [-23.0667, 118.6748],
  [-23.0749, 118.6768],
  [-23.0823, 118.6842],
  [-23.0854, 118.6924],
  [-23.0861, 118.7116],
  [-23.0881, 118.7178],
  [-23.0949, 118.7262],
  [-23.1003, 118.7302],
  [-23.1058, 118.7322],
  [-23.1137, 118.7328],
  [-23.1204, 118.7306],
  [-23.1246, 118.7274],
  [-23.1297, 118.7254],
  [-23.1437, 118.7256],
  [-23.1497, 118.7278],
  [-23.1536, 118.7325],
  [-23.1584, 118.7444],
  [-23.1584, 118.7444],
  [-23.1609, 118.7488],
  [-23.1651, 118.7527],
  [-23.1676, 118.7574],
  [-23.1679, 118.7634],
  [-23.1658, 118.7679],
  [-23.1629, 118.7692],
  [-23.1599, 118.7685],
  [-23.1580, 118.7667],
  [-23.1566, 118.7640],
  [-23.1563, 118.7606],
  [-23.1589, 118.7508],
  [-23.1590, 118.7481],
  [-23.1584, 118.7444]
];

const hopeDownsOne = [
  [-23.0861, 118.7116],
  [-23.0858, 118.7144],
  [-23.0851, 118.7165],
  [-23.0785, 118.7286],
  [-23.0774, 118.7338],
  [-23.0784, 118.7385],
  [-23.0836, 118.7483],
  [-23.0851, 118.7527],
  [-23.0894, 118.7775],
  [-23.0895, 118.7813],
  [-23.0878, 118.7973],
  [-23.0879, 118.8011],
  [-23.0896, 118.8106],
  [-23.0895, 118.8149],
  [-23.0867, 118.8243],
  [-23.0759, 118.8477],
  [-23.0709, 118.8537],
  [-23.0690, 118.8583],
  [-23.0692, 118.8636],
  [-23.0685, 118.8674],
  [-23.0666, 118.8717],
  [-23.0665, 118.8768],
  [-23.0698, 118.8854],
  [-23.0702, 118.8881],
  [-23.0712, 118.9056],
  [-23.0694, 118.9116],
  [-23.0627, 118.9197],
  [-23.0574, 118.9288],
  [-23.0566, 118.9327],
  [-23.0566, 118.9396],
  [-23.0540, 118.9484],
  [-23.0521, 118.9623],
  [-23.0497, 118.9669],
  [-23.0346, 118.9798],
  [-23.0315, 118.9846],
  [-23.0300, 118.9914],
  [-23.0301, 118.9955],
  [-23.0331, 119.0066],
  [-23.0338, 119.0184],
  [-23.0326, 119.0242],
  [-23.0263, 119.0389],
  [-23.0258, 119.0425],
  [-23.0264, 119.0455],
  [-23.0284, 119.0499],
  [-23.0290, 119.0545],
  [-23.0279, 119.0594],
  [-23.0271, 119.0649],
  [-23.0271, 119.0677],
  [-23.0284, 119.0755],
  [-23.0281, 119.0845],
  [-23.0283, 119.0871],
  [-23.0302, 119.0922],
  [-23.0303, 119.0961],
  [-23.0271, 119.1062],
  [-23.0218, 119.1160],
  [-23.0207, 119.1189],
  [-23.0188, 119.1277],
  [-23.0182, 119.1312],
  [-23.0153, 119.1376],
  [-23.0123, 119.1478],
  [-23.0122, 119.1517],
  [-23.0131, 119.1545],
  [-23.0213, 119.1670],
  [-23.0362, 119.1846],
  [-23.0377, 119.1881],
  [-23.0389, 119.1973],
  [-23.0357, 119.2145],
  [-23.0360, 119.2189],
  [-23.0375, 119.2220],
  [-23.0429, 119.2283],
  [-23.0443, 119.2326],
  [-23.0438, 119.2389],
  [-23.0442, 119.2417],
  [-23.0494, 119.2591],
  [-23.0691, 119.2996],
  [-23.0730, 119.3035],
  [-23.0814, 119.3071],
  [-23.0848, 119.3103],
  [-23.0999, 119.3350],
  [-23.1071, 119.3535],
  [-23.1161, 119.3905],
  [-23.1114, 119.4449],
  [-23.1114, 119.4918],
  [-23.1119, 119.4953],
  [-23.1174, 119.5126],
  [-23.1225, 119.5433],
  [-23.1248, 119.5576],
  [-23.1265, 119.5605],
  [-23.1297, 119.5621],
  [-23.1329, 119.5616],
  [-23.1353, 119.5593],
  [-23.1362, 119.5556],
  [-23.1350, 119.5511],
  [-23.1331, 119.5491],
  [-23.1254, 119.5467],
  [-23.1237, 119.5445],
  [-23.1225, 119.5433]
];

const hopeDownsFour = [
  [-23.0188, 119.1277],
  [-23.0160, 119.1326],
  [-23.0126, 119.1346],
  [-23.0083, 119.1348],
  [-23.0046, 119.1330],
  [-22.9915, 119.1188],
  [-22.9898, 119.1177],
  [-22.9860, 119.1160],
  [-22.9749, 119.1110],
  [-22.9718, 119.1114],
  [-22.9698, 119.1135],
  [-22.9692, 119.1160],
  [-22.9703, 119.1204],
  [-22.9723, 119.1222],
  [-22.9737, 119.1226],
  [-22.9767, 119.1217],
  [-22.9822, 119.1171],
  [-22.9840, 119.1164],
  [-22.9860, 119.1160]
];

const gudaiDarri = [
  [-21.9573, 117.6350],
  [-21.9610, 117.6376],
  [-21.9635, 117.6411],
  [-21.9659, 117.6465],
  [-21.9662, 117.6510],
  [-21.9577, 117.6878],
  [-21.9562, 117.6915],
  [-21.9295, 117.7309],
  [-21.9273, 117.7366],
  [-21.9268, 117.7511],
  [-21.9251, 117.7564],
  [-21.9221, 117.7595],
  [-21.9152, 117.7640],
  [-21.9124, 117.7683],
  [-21.9098, 117.7763],
  [-21.9099, 117.7824],
  [-21.9200, 117.8035],
  [-21.9211, 117.8080],
  [-21.9212, 117.8207],
  [-21.9217, 117.8248],
  [-21.9248, 117.8339],
  [-21.9255, 117.8382],
  [-21.9252, 117.8427],
  [-21.9224, 117.8542],
  [-21.9223, 117.8598],
  [-21.9286, 117.8862],
  [-21.9318, 117.8910],
  [-21.9458, 117.8997],
  [-21.9484, 117.9019],
  [-21.9721, 117.9300],
  [-21.9753, 117.9328],
  [-21.9908, 117.9489],
  [-22.0248, 117.9632],
  [-22.0445, 117.9755],
  [-22.0482, 117.9767],
  [-22.0774, 117.9797],
  [-22.0832, 117.9829],
  [-22.1151, 118.0330],
  [-22.1519, 118.1426],
  [-22.1540, 118.1459],
  [-22.1610, 118.1521],
  [-22.1633, 118.1562],
  [-22.1638, 118.1603],
  [-22.1563, 118.1985],
  [-22.1564, 118.2028],
  [-22.1616, 118.2205],
  [-22.1645, 118.2256],
  [-22.1745, 118.2339],
  [-22.1772, 118.2381],
  [-22.1777, 118.2420],
  [-22.1754, 118.2670],
  [-22.1759, 118.2718],
  [-22.1776, 118.2751],
  [-22.1865, 118.2866],
  [-22.1998, 118.3001],
  [-22.2017, 118.3037],
  [-22.2023, 118.3076],
  [-22.2004, 118.3298],
  [-22.2017, 118.3377],
  [-22.2147, 118.3610],
  [-22.2321, 118.4021],
  [-22.2436, 118.4175],
  [-22.2461, 118.4229],
  [-22.2551, 118.4719],
  [-22.2659, 118.4984],
  [-22.2763, 118.5415],
  [-22.3277, 118.6831],
  [-22.3485, 118.7679],
  [-22.3509, 118.7734],
  [-22.3837, 118.8274],
  [-22.4245, 118.8708],
  [-22.4264, 118.8740],
  [-22.4403, 118.9075],
  [-22.4427, 118.9110],
  [-22.4689, 118.9380],
  [-22.4720, 118.9449],
  [-22.4774, 118.9779],
  [-22.4820, 118.9909],
  [-22.4933, 119.0049],
  [-22.4995, 119.0124],
  [-22.5002, 119.0158],
  [-22.4995, 119.0182],
  [-22.4962, 119.0207],
  [-22.4939, 119.0208],
  [-22.4923, 119.0198],
  [-22.4915, 119.0185],
  [-22.4914, 119.0164],
  [-22.4941, 119.0087],
  [-22.4940, 119.0072],
  [-22.4933, 119.0049]
];

const westCreekJunctionMillstream = [
  [-21.2159, 117.0500],
  [-21.2243, 117.0522],
  [-21.2270, 117.0534],
  [-21.2353, 117.0603],
  [-21.2380, 117.0620],
  [-21.2453, 117.0644],
  [-21.2608, 117.0711],
  [-21.2634, 117.0718],
  [-21.2818, 117.0716],
  [-21.2864, 117.0708],
  [-21.2881, 117.0709],
  [-21.2939, 117.0736],
  [-21.2979, 117.0772],
  [-21.2997, 117.0804],
  [-21.3064, 117.0863],
  [-21.3091, 117.0874],
  [-21.3134, 117.0878],
  [-21.3170, 117.0860],
  [-21.3197, 117.0825],
  [-21.3202, 117.0799],
  [-21.3214, 117.0778],
  [-21.3252, 117.0748],
  [-21.3275, 117.0746],
  [-21.3292, 117.0756],
  [-21.3325, 117.0819],
  [-21.3405, 117.0958],
  [-21.3515, 117.1067],
  [-21.3659, 117.1253],
  [-21.3673, 117.1282],
  [-21.3684, 117.1346],
  [-21.3694, 117.1371],
  [-21.3739, 117.1441],
  [-21.3777, 117.1470],
  [-21.4221, 117.1613]
];

const millstreamOne = [
  [-21.4221, 117.1613],
  [-21.4287, 117.1635],
  [-21.4332, 117.1644],
  [-21.4762, 117.1626],
  [-21.4799, 117.1637],
  [-21.4997, 117.1813]
];

const millstreamTwo = [
  [-21.4221, 117.1613],
  [-21.4360, 117.1699],
  [-21.4411, 117.1717],
  [-21.4479, 117.1716],
  [-21.4638, 117.1661],
  [-21.4746, 117.1649],
  [-21.4828, 117.1678],
  [-21.4997, 117.1813]
];

const millstreamTrackSplit = [
  [-21.4997, 117.1813],
  [-21.5016, 117.1825],
  [-21.5095, 117.1851],
  [-21.5151, 117.1883],
  [-21.5565, 117.2086],
  [-21.5700, 117.2178],
  [-21.5852, 117.2367],
  [-21.6029, 117.2551],
  [-21.6134, 117.2726],
  [-21.6251, 117.2875],
  [-21.6417, 117.3030],
  [-21.6529, 117.3234],
  [-21.6593, 117.3461],
  [-21.6671, 117.4378],
  [-21.6693, 117.4451],
  [-21.6982, 117.4896],
  [-21.7042, 117.4950],
  [-21.7338, 117.5078],
  [-21.7407, 117.5136],
  [-21.7800, 117.5719],
  [-21.7876, 117.5790],
  [-21.8223, 117.5933],
  [-21.8498, 117.6154],
  [-21.8649, 117.6222],
  [-21.9177, 117.6236],
  [-21.9289, 117.6284],
  [-21.9464, 117.6301],
  [-21.9573, 117.6350],
  [-21.9630, 117.6357],
  [-22.0095, 117.6361],
  [-22.0146, 117.6381],
  [-22.0232, 117.6478],
  [-22.0288, 117.6611],
  [-22.0721, 117.7028],
  [-22.0861, 117.7089],
  [-22.0902, 117.7090],
  [-22.0972, 117.7066],
  [-22.1004, 117.7063],
  [-22.1066, 117.7084],
  [-22.1100, 117.7116],
  [-22.1185, 117.7285]
];

const trackSplitOne = [
  [-22.1185, 117.7285],
  [-22.1214, 117.7312],
  [-22.1249, 117.7320],
  [-22.1373, 117.7281],
  [-22.1418, 117.7243],
  [-22.1449, 117.7226],
  [-22.1483, 117.7222],
  [-22.1513, 117.7206],
  [-22.1553, 117.7180],
  [-22.1609, 117.7172],
  [-22.1652, 117.7180],
  [-22.1683, 117.7173],
  [-22.1714, 117.7148],
  [-22.1824, 117.6956]
];

const trackSplitTwo = [
  [-22.1185, 117.7285],
  [-22.1205, 117.7314],
  [-22.1228, 117.7326],
  [-22.1274, 117.7328],
  [-22.1346, 117.7299],
  [-22.1551, 117.7197],
  [-22.1592, 117.7191],
  [-22.1629, 117.7198],
  [-22.1687, 117.7229],
  [-22.1724, 117.7243],
  [-22.1741, 117.7245],
  [-22.1765, 117.7242],
  [-22.1800, 117.7225],
  [-22.1821, 117.7204],
  [-22.1834, 117.7177],
  [-22.1842, 117.7133],
  [-22.1839, 117.7106],
  [-22.1814, 117.7022],
  [-22.1813, 117.6991],
  [-22.1824, 117.6956]
];

const trackSplitRosellaJunction = [
  [-22.1824, 117.6956],
  [-22.1841, 117.6931],
  [-22.1862, 117.6918],
  [-22.1890, 117.6913],
  [-22.2088, 117.6916],
  [-22.2164, 117.6887],
  [-22.2197, 117.6884],
  [-22.2298, 117.6898],
  [-22.2512, 117.6876],
  [-22.2551, 117.6878],
  [-22.2652, 117.6903],
  [-22.2685, 117.6903],
  [-22.3329, 117.6691],
  [-22.3371, 117.6688],
  [-22.3925, 117.6807],
  [-22.4226, 117.6939]
];

export const hamersley: Line = {
  name: 'Hamersley',
  state: 'WA',
  segments: [
    {
      segments: [portDampierRobeRiverJunction, portSpur, rosellaJunctionTomPriceMine],
      history: {
        opened: {
          date: '1966-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [rosellaJunctionBrockmanTwo],
      history: {
        opened: {
          date: '1992-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [brockmanTwoNammuldi],
      history: {
        opened: {
          date: '2006-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [brockmanTwoBrockmanFour],
      history: {
        opened: {
          date: '2010-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wombatJunctionParaburdooMine],
      history: {
        opened: {
          date: '1972-05-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [rosellaJunctionMarandoo],
      history: {
        opened: {
          date: '1994-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [westAngelas],
      history: {
        opened: {
          date: '2002-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hopeDownsOne],
      history: {
        opened: {
          date: '2007-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hopeDownsFour],
      history: {
        opened: {
          date: '2013-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [marandooMineYandicoogina],
      history: {
        opened: {
          date: '1998-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [gudaiDarri],
      history: {
        opened: {
          date: '2022-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [westCreekJunctionMillstream],
      history: {
        opened: {
          date: '1966-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [millstreamOne],
      history: {
        opened: {
          date: '1966-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [millstreamTwo],
      history: {
        opened: {
          date: '2006-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [millstreamTrackSplit],
      history: {
        opened: {
          date: '1966-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [trackSplitOne],
      history: {
        opened: {
          date: '1966-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [trackSplitTwo],
      history: {
        opened: {
          date: '2006-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [trackSplitRosellaJunction],
      history: {
        opened: {
          date: '1966-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          tracks: 2
        }]
      }
    }
  ]
};
