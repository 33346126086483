import { Line } from "../../../trackTypes";

const boonmooJunction = [
  [-17.2455, 145.0736],
  [-17.2489, 145.0755],
  [-17.2491, 145.0758],
  [-17.2491, 145.0761],
  [-17.2489, 145.0767],
  [-17.2485, 145.0774],
  [-17.2481, 145.0777],
  [-17.2478, 145.0779],
  [-17.2476, 145.0781],
  [-17.2474, 145.0807],
  [-17.2478, 145.0815],
  [-17.2500, 145.0849],
  [-17.2514, 145.0861],
  [-17.2564, 145.0880],
  [-17.2574, 145.0893],
  [-17.2577, 145.0906],
  [-17.2575, 145.0916],
  [-17.2570, 145.0923],
  [-17.2569, 145.0930],
  [-17.2580, 145.0967],
  [-17.2586, 145.0976],
  [-17.2587, 145.0986],
  [-17.2592, 145.0996],
  [-17.2600, 145.1001],
  [-17.2608, 145.1002],
  [-17.2614, 145.1008],
  [-17.2618, 145.1017],
  [-17.2617, 145.1035],
  [-17.2613, 145.1040],
  [-17.2610, 145.1045],
  [-17.2610, 145.1071],
  [-17.2614, 145.1079],
  [-17.2628, 145.1085],
  [-17.2633, 145.1090],
  [-17.2633, 145.1097],
  [-17.2630, 145.1102],
  [-17.2631, 145.1107],
  [-17.2635, 145.1110],
  [-17.2639, 145.1109],
  [-17.2642, 145.1107],
  [-17.2646, 145.1108],
  [-17.2655, 145.1117],
  [-17.2658, 145.1124],
  [-17.2664, 145.1130],
  [-17.2665, 145.1138],
  [-17.2665, 145.1148],
  [-17.2662, 145.1154],
  [-17.2661, 145.1158],
  [-17.2661, 145.1165],
  [-17.2664, 145.1173],
  [-17.2673, 145.1179],
  [-17.2679, 145.1179],
  [-17.2687, 145.1181],
  [-17.2689, 145.1183],
  [-17.2690, 145.1186],
  [-17.2688, 145.1193],
  [-17.2686, 145.1204],
  [-17.2690, 145.1212],
  [-17.2694, 145.1230],
  [-17.2693, 145.1238],
  [-17.2690, 145.1242],
  [-17.2681, 145.1245],
  [-17.2674, 145.1251],
  [-17.2667, 145.1263],
  [-17.2665, 145.1276],
  [-17.2668, 145.1286],
  [-17.2674, 145.1293],
  [-17.2680, 145.1291],
  [-17.2692, 145.1287],
  [-17.2699, 145.1288],
  [-17.2703, 145.1294],
  [-17.2706, 145.1316],
  [-17.2706, 145.1320],
  [-17.2704, 145.1324],
  [-17.2704, 145.1327],
  [-17.2706, 145.1330],
  [-17.2713, 145.1333],
  [-17.2717, 145.1332],
  [-17.2720, 145.1329],
  [-17.2725, 145.1309],
  [-17.2730, 145.1302],
  [-17.2734, 145.1301],
  [-17.2736, 145.1301],
  [-17.2738, 145.1303],
  [-17.2739, 145.1308],
  [-17.2742, 145.1314],
  [-17.2745, 145.1324],
  [-17.2746, 145.1341],
  [-17.2748, 145.1349],
  [-17.2755, 145.1356],
  [-17.2764, 145.1361],
  [-17.2771, 145.1362],
  [-17.2782, 145.1368],
  [-17.2788, 145.1373],
  [-17.2791, 145.1379],
  [-17.2796, 145.1383],
  [-17.2803, 145.1396],
  [-17.2808, 145.1401],
  [-17.2809, 145.1404],
  [-17.2813, 145.1421],
  [-17.2815, 145.1426],
  [-17.2819, 145.1429],
  [-17.2831, 145.1440],
  [-17.2832, 145.1447],
  [-17.2827, 145.1458],
  [-17.2827, 145.1463],
  [-17.2830, 145.1467],
  [-17.2841, 145.1471],
  [-17.2863, 145.1472],
  [-17.2867, 145.1474],
  [-17.2868, 145.1478],
  [-17.2870, 145.1491],
  [-17.2875, 145.1502],
  [-17.2881, 145.1506],
  [-17.2908, 145.1526],
  [-17.2915, 145.1541],
  [-17.2933, 145.1568],
  [-17.2935, 145.1574],
  [-17.2933, 145.1583],
  [-17.2928, 145.1605],
  [-17.2929, 145.1617],
  [-17.2937, 145.1627],
  [-17.2947, 145.1629],
  [-17.2955, 145.1634],
  [-17.3000, 145.1703],
  [-17.3009, 145.1713],
  [-17.3019, 145.1717],
  [-17.3067, 145.1771],
  [-17.3091, 145.1803],
  [-17.3109, 145.1841],
  [-17.3120, 145.1854],
  [-17.3121, 145.1862],
  [-17.3119, 145.1871],
  [-17.3121, 145.1877],
  [-17.3124, 145.1883],
  [-17.3128, 145.1924],
  [-17.3126, 145.1929],
  [-17.3127, 145.1933],
  [-17.3135, 145.1937],
  [-17.3137, 145.1941],
  [-17.3134, 145.1944],
  [-17.3130, 145.1944],
  [-17.3125, 145.1939],
  [-17.3122, 145.1931],
  [-17.3122, 145.1927],
  [-17.3118, 145.1917],
  [-17.3113, 145.1911],
  [-17.3104, 145.1905],
  [-17.3100, 145.1906],
  [-17.3098, 145.1908],
  [-17.3093, 145.1921],
  [-17.3093, 145.1932],
  [-17.3090, 145.1944],
  [-17.3089, 145.1947],
  [-17.3086, 145.1950],
  [-17.3064, 145.1960],
  [-17.3047, 145.1976],
  [-17.3047, 145.1979],
  [-17.3045, 145.1981],
  [-17.3045, 145.1986],
  [-17.3069, 145.1998],
  [-17.3072, 145.2001],
  [-17.3072, 145.2005],
  [-17.3069, 145.2008],
  [-17.3068, 145.2011],
  [-17.3070, 145.2013],
  [-17.3075, 145.2014],
  [-17.3077, 145.2017],
  [-17.3079, 145.2023],
  [-17.3081, 145.2025],
  [-17.3083, 145.2025],
  [-17.3089, 145.2022],
  [-17.3092, 145.2021],
  [-17.3094, 145.2023],
  [-17.3094, 145.2028],
  [-17.3079, 145.2050],
  [-17.3076, 145.2052],
  [-17.3073, 145.2053],
  [-17.3071, 145.2055],
  [-17.3072, 145.2059],
  [-17.3072, 145.2062],
  [-17.3070, 145.2069],
  [-17.3071, 145.2079],
  [-17.3074, 145.2083],
  [-17.3080, 145.2083],
  [-17.3084, 145.2080],
  [-17.3084, 145.2074],
  [-17.3079, 145.2070],
  [-17.3076, 145.2067],
  [-17.3076, 145.2062],
  [-17.3079, 145.2058],
  [-17.3084, 145.2056],
  [-17.3089, 145.2058],
  [-17.3094, 145.2060],
  [-17.3096, 145.2065],
  [-17.3098, 145.2079],
  [-17.3101, 145.2085],
  [-17.3103, 145.2093],
  [-17.3100, 145.2103],
  [-17.3092, 145.2112],
  [-17.3091, 145.2115],
  [-17.3091, 145.2117],
  [-17.3096, 145.2121],
  [-17.3097, 145.2125],
  [-17.3098, 145.2133],
  [-17.3103, 145.2142],
  [-17.3128, 145.2151],
  [-17.3130, 145.2156],
  [-17.3129, 145.2161],
  [-17.3121, 145.2167],
  [-17.3118, 145.2171],
  [-17.3118, 145.2180],
  [-17.3147, 145.2199],
  [-17.3149, 145.2219],
  [-17.3147, 145.2225],
  [-17.3149, 145.2233],
  [-17.3152, 145.2252],
  [-17.3155, 145.2256],
  [-17.3154, 145.2260],
  [-17.3152, 145.2263],
  [-17.3144, 145.2265],
  [-17.3140, 145.2262],
  [-17.3138, 145.2263],
  [-17.3137, 145.2266],
  [-17.3140, 145.2280],
  [-17.3133, 145.2304]
];

const junctionStannyHills = [
  [-17.3133, 145.2304],
  [-17.3128, 145.2308],
  [-17.3116, 145.2314],
  [-17.3100, 145.2317],
  [-17.3081, 145.2318],
  [-17.3076, 145.2323],
  [-17.3076, 145.2333],
  [-17.3075, 145.2342]
];

const stannaryHillsRockyBluff = [
  [-17.3075, 145.2342],
  [-17.3072, 145.2346],
  [-17.3067, 145.2347],
  [-17.3065, 145.2350],
  [-17.3065, 145.2354],
  [-17.3070, 145.2360],
  [-17.3073, 145.2370],
  [-17.3072, 145.2375],
  [-17.3066, 145.2383],
  [-17.3067, 145.2387],
  [-17.3067, 145.2394],
  [-17.3062, 145.2405],
  [-17.3063, 145.2412],
  [-17.3066, 145.2420],
  [-17.3065, 145.2430],
  [-17.3068, 145.2438],
  [-17.3068, 145.2443],
  [-17.3054, 145.2462],
  [-17.3053, 145.2468],
  [-17.3053, 145.2475],
  [-17.3066, 145.2499],
  [-17.3076, 145.2507],
  [-17.3111, 145.2514],
  [-17.3118, 145.2515],
  [-17.3122, 145.2520],
  [-17.3127, 145.2523],
  [-17.3139, 145.2527],
  [-17.3157, 145.2548],
  [-17.3172, 145.2563],
  [-17.3174, 145.2571],
  [-17.3170, 145.2576],
  [-17.3160, 145.2581],
  [-17.3160, 145.2588],
  [-17.3157, 145.2593],
  [-17.3154, 145.2594],
  [-17.3144, 145.2589],
  [-17.3141, 145.2591],
  [-17.3142, 145.2596],
  [-17.3147, 145.2599],
  [-17.3148, 145.2603],
  [-17.3146, 145.2607],
  [-17.3146, 145.2613],
  [-17.3143, 145.2616],
  [-17.3136, 145.2614],
  [-17.3134, 145.2612],
  [-17.3131, 145.2609],
  [-17.3116, 145.2612],
  [-17.3105, 145.2611],
  [-17.3100, 145.2608],
  [-17.3097, 145.2604],
  [-17.3099, 145.2597],
  [-17.3098, 145.2592],
  [-17.3094, 145.2592],
  [-17.3087, 145.2599],
  [-17.3083, 145.2599],
  [-17.3071, 145.2588],
  [-17.3065, 145.2590],
  [-17.3063, 145.2598],
  [-17.3065, 145.2603],
  [-17.3069, 145.2607],
  [-17.3068, 145.2613],
  [-17.3067, 145.2619],
  [-17.3075, 145.2642],
  [-17.3075, 145.2653],
  [-17.3070, 145.2657],
  [-17.3064, 145.2655],
  [-17.3060, 145.2657],
  [-17.3061, 145.2668],
  [-17.3064, 145.2671],
  [-17.3074, 145.2671],
  [-17.3079, 145.2674],
  [-17.3082, 145.2679],
  [-17.3083, 145.2684],
  [-17.3087, 145.2689],
  [-17.3087, 145.2691],
  [-17.3085, 145.2694],
  [-17.3084, 145.2704],
  [-17.3073, 145.2731],
  [-17.3065, 145.2735],
  [-17.3059, 145.2740],
  [-17.3051, 145.2735],
  [-17.3048, 145.2736],
  [-17.3045, 145.2743],
  [-17.3048, 145.2748],
  [-17.3047, 145.2753],
  [-17.3041, 145.2756],
  [-17.3037, 145.2759],
  [-17.3033, 145.2763],
  [-17.3027, 145.2763]
];

const junctionIrvinbank = [
  [-17.3133, 145.2304],
  [-17.3133, 145.2312],
  [-17.3136, 145.2316],
  [-17.3141, 145.2317],
  [-17.3148, 145.2313],
  [-17.3175, 145.2309],
  [-17.3178, 145.2310],
  [-17.3181, 145.2312],
  [-17.3190, 145.2311],
  [-17.3220, 145.2314],
  [-17.3228, 145.2319],
  [-17.3232, 145.2319],
  [-17.3249, 145.2310],
  [-17.3251, 145.2305],
  [-17.3273, 145.2296],
  [-17.3277, 145.2297],
  [-17.3279, 145.2302],
  [-17.3285, 145.2311],
  [-17.3288, 145.2313],
  [-17.3293, 145.2310],
  [-17.3296, 145.2299],
  [-17.3299, 145.2296],
  [-17.3304, 145.2296],
  [-17.3319, 145.2305],
  [-17.3323, 145.2306],
  [-17.3326, 145.2303],
  [-17.3328, 145.2299],
  [-17.3326, 145.2295],
  [-17.3321, 145.2291],
  [-17.3320, 145.2288],
  [-17.3321, 145.2285],
  [-17.3339, 145.2271],
  [-17.3342, 145.2267],
  [-17.3343, 145.2259],
  [-17.3339, 145.2252],
  [-17.3341, 145.2247],
  [-17.3344, 145.2244],
  [-17.3363, 145.2246],
  [-17.3367, 145.2243],
  [-17.3371, 145.2237],
  [-17.3373, 145.2235],
  [-17.3378, 145.2235],
  [-17.3386, 145.2238],
  [-17.3393, 145.2237],
  [-17.3403, 145.2232],
  [-17.3411, 145.2223],
  [-17.3415, 145.2200],
  [-17.3421, 145.2193],
  [-17.3433, 145.2190],
  [-17.3439, 145.2190],
  [-17.3451, 145.2192],
  [-17.3457, 145.2193],
  [-17.3466, 145.2192],
  [-17.3470, 145.2197],
  [-17.3472, 145.2203],
  [-17.3476, 145.2207],
  [-17.3505, 145.2206],
  [-17.3516, 145.2200],
  [-17.3523, 145.2190],
  [-17.3518, 145.2139],
  [-17.3522, 145.2134],
  [-17.3524, 145.2133],
  [-17.3616, 145.2172],
  [-17.3633, 145.2172],
  [-17.3642, 145.2164],
  [-17.3642, 145.2152],
  [-17.3628, 145.2123],
  [-17.3617, 145.2110],
  [-17.3615, 145.2098],
  [-17.3620, 145.2089],
  [-17.3631, 145.2083],
  [-17.3640, 145.2074],
  [-17.3656, 145.2040],
  [-17.3666, 145.2008],
  [-17.3678, 145.1992],
  [-17.3696, 145.1982],
  [-17.3704, 145.1970],
  [-17.3710, 145.1932],
  [-17.3720, 145.1909],
  [-17.3727, 145.1902],
  [-17.3758, 145.1880],
  [-17.3772, 145.1876],
  [-17.3810, 145.1883],
  [-17.3817, 145.1881],
  [-17.3824, 145.1869],
  [-17.3829, 145.1865],
  [-17.3834, 145.1866],
  [-17.3838, 145.1870],
  [-17.3844, 145.1871],
  [-17.3866, 145.1864],
  [-17.3905, 145.1862],
  [-17.3915, 145.1858],
  [-17.3952, 145.1839],
  [-17.3960, 145.1839],
  [-17.3966, 145.1843],
  [-17.3970, 145.1851],
  [-17.3975, 145.1853],
  [-17.3979, 145.1854],
  [-17.3987, 145.1850],
  [-17.3994, 145.1850],
  [-17.4003, 145.1851],
  [-17.4009, 145.1849],
  [-17.4014, 145.1850],
  [-17.4020, 145.1845],
  [-17.4028, 145.1827],
  [-17.4034, 145.1822],
  [-17.4062, 145.1818],
  [-17.4072, 145.1820],
  [-17.4118, 145.1849],
  [-17.4124, 145.1860],
  [-17.4136, 145.1868],
  [-17.4148, 145.1869],
  [-17.4169, 145.1872],
  [-17.4210, 145.1887],
  [-17.4217, 145.1886],
  [-17.4222, 145.1883],
  [-17.4229, 145.1878],
  [-17.4237, 145.1878],
  [-17.4250, 145.1887],
  [-17.4254, 145.1896],
  [-17.4259, 145.1902],
  [-17.4292, 145.1914],
  [-17.4303, 145.1921],
  [-17.4316, 145.1923],
  [-17.4321, 145.1932],
  [-17.4321, 145.1939],
  [-17.4316, 145.1954],
  [-17.4315, 145.1966],
  [-17.4296, 145.1994],
  [-17.4294, 145.2000],
  [-17.4297, 145.2005],
  [-17.4307, 145.2018],
  [-17.4315, 145.2042],
  [-17.4328, 145.2059],
  [-17.4328, 145.2063],
  [-17.4324, 145.2064],
  [-17.4310, 145.2051],
  [-17.4305, 145.2044],
  [-17.4299, 145.2039]
];

export const stannaryHills: Line = {
  name: 'Stannaryy Hills',
  state: 'QLD',
  segments: [
    {
      segments: [boonmooJunction],
      history: {
        opened: {
          date: '1902-05-01',
          tracks: 1,
          gauge: 'narrow', //2ft gauge
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [junctionStannyHills],
      history: {
        opened: {
          date: '1902-05-01',
          tracks: 1,
          gauge: 'narrow', //2ft gauge
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stannaryHillsRockyBluff],
      history: {
        opened: {
          date: '1903-05-01',
          tracks: 1,
          gauge: 'narrow', //2ft gauge
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [junctionIrvinbank],
      history: {
        opened: {
          date: '1907-06-29',
          tracks: 1,
          gauge: 'narrow', //2ft gauge
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
