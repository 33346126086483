import { Line } from "../../trackTypes";

const karoondaGalga = [
  [-35.0936, 139.9008],
  [-35.0921, 139.9027],
  [-35.0903, 139.9034],
  [-35.0885, 139.9033],
  [-35.0867, 139.9020],
  [-35.0858, 139.9005],
  [-35.0686, 139.8556],
  [-35.0667, 139.8520],
  [-35.0425, 139.8175],
  [-35.0392, 139.8154],
  [-35.0226, 139.8126],
  [-35.0205, 139.8128],
  [-35.0024, 139.8179],
  [-34.9990, 139.8177],
  [-34.9935, 139.8146],
  [-34.9912, 139.8142],
  [-34.9894, 139.8145],
  [-34.9866, 139.8159],
  [-34.9847, 139.8163],
  [-34.9775, 139.8152],
  [-34.9758, 139.8156],
  [-34.9369, 139.8312],
  [-34.9229, 139.8334],
  [-34.9208, 139.8332],
  [-34.9058, 139.8271],
  [-34.9031, 139.8269],
  [-34.8842, 139.8310],
  [-34.8825, 139.8309],
  [-34.8687, 139.8291],
  [-34.8662, 139.8293],
  [-34.8324, 139.8399],
  [-34.8196, 139.8411],
  [-34.7990, 139.8501],
  [-34.7973, 139.8502],
  [-34.7866, 139.8464],
  [-34.7843, 139.8467],
  [-34.7825, 139.8482],
  [-34.7712, 139.8648],
  [-34.7697, 139.8661],
  [-34.7632, 139.8697],
  [-34.7613, 139.8716],
  [-34.7540, 139.8824],
  [-34.7530, 139.8853],
  [-34.7495, 139.9065],
  [-34.7480, 139.9096],
  [-34.7457, 139.9112],
  [-34.7323, 139.9149],
  [-34.7299, 139.9169],
  [-34.7245, 139.9278],
  [-34.6976, 139.9612]
];

const galgaWaikerie = [
  [-34.6976, 139.9612],
  [-34.6667, 139.9996],
  [-34.6589, 140.0055],
  [-34.6140, 140.0705],
  [-34.6123, 140.0715],
  [-34.6014, 140.0758],
  [-34.5994, 140.0761],
  [-34.5626, 140.0738],
  [-34.5486, 140.0746],
  [-34.5378, 140.0739],
  [-34.5237, 140.0695],
  [-34.5060, 140.0701],
  [-34.4734, 140.0680],
  [-34.4713, 140.0672],
  [-34.4348, 140.0424],
  [-34.3977, 140.0284],
  [-34.3691, 140.0298],
  [-34.3670, 140.0294],
  [-34.3538, 140.0238],
  [-34.3186, 140.0146],
  [-34.3155, 140.0146],
  [-34.3024, 140.0178],
  [-34.3002, 140.0176],
  [-34.2943, 140.0149],
  [-34.2912, 140.0145],
  [-34.2646, 140.0181],
  [-34.2104, 140.0029],
  [-34.1900, 140.0030],
  [-34.1887, 140.0022],
  [-34.1879, 140.0008],
  [-34.1830, 139.9931],
  [-34.1798, 139.9876]
];

export const waikerie: Line = {
  name: 'Waikerie',
  state: 'SA',
  segments: [
    {
      segments: [karoondaGalga],
      history: {
        opened: {
          date: '1914-09-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-03-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [galgaWaikerie],
      history: {
        opened: {
          date: '1914-09-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-03-14',
          status: 'closed'
        }]
      }
    }
  ]
};
