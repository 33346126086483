import { Line } from "../../../trackTypes";

const warwickJunctionThane = [
  [-28.2215, 152.0395],
  [-28.2223, 152.0389],
  [-28.2229, 152.0383],
  [-28.2266, 152.0292],
  [-28.2268, 152.0276],
  [-28.2266, 152.0264],
  [-28.2258, 152.0247],
  [-28.2254, 152.0234],
  [-28.2249, 152.0197],
  [-28.2250, 152.0182],
  [-28.2253, 152.0159],
  [-28.2253, 152.0143],
  [-28.2247, 152.0109],
  [-28.2244, 152.0099],
  [-28.2234, 152.0079],
  [-28.2230, 152.0066],
  [-28.2228, 152.0053],
  [-28.2222, 152.0043],
  [-28.2215, 152.0037],
  [-28.2187, 152.0028],
  [-28.2181, 152.0022],
  [-28.2177, 152.0012],
  [-28.2177, 151.9959],
  [-28.2173, 151.9944],
  [-28.2164, 151.9929],
  [-28.2154, 151.9919],
  [-28.2147, 151.9908],
  [-28.2141, 151.9890],
  [-28.2140, 151.9879],
  [-28.2140, 151.9857],
  [-28.2138, 151.9838],
  [-28.2126, 151.9781],
  [-28.2125, 151.9774],
  [-28.2126, 151.9765],
  [-28.2145, 151.9686],
  [-28.2146, 151.9676],
  [-28.2144, 151.9666],
  [-28.2120, 151.9610],
  [-28.2113, 151.9599],
  [-28.1998, 151.9508],
  [-28.1983, 151.9493],
  [-28.1931, 151.9403],
  [-28.1907, 151.9313],
  [-28.1896, 151.9289],
  [-28.1877, 151.9261],
  [-28.1855, 151.9246],
  [-28.1769, 151.9227],
  [-28.1746, 151.9216],
  [-28.1728, 151.9196],
  [-28.1692, 151.9121],
  [-28.1686, 151.9111],
  [-28.1646, 151.9066],
  [-28.1638, 151.9052],
  [-28.1634, 151.9037],
  [-28.1633, 151.9000],
  [-28.1634, 151.8990],
  [-28.1691, 151.8797],
  [-28.1697, 151.8752],
  [-28.1696, 151.8744],
  [-28.1693, 151.8735],
  [-28.1648, 151.8680],
  [-28.1641, 151.8661],
  [-28.1642, 151.8642],
  [-28.1673, 151.8573],
  [-28.1677, 151.8552],
  [-28.1670, 151.8532],
  [-28.1635, 151.8489],
  [-28.1602, 151.8459],
  [-28.1592, 151.8443],
  [-28.1590, 151.8428],
  [-28.1606, 151.8301],
  [-28.1615, 151.8280],
  [-28.1662, 151.8234],
  [-28.1675, 151.8217],
  [-28.1698, 151.8168],
  [-28.1700, 151.8160],
  [-28.1700, 151.8152],
  [-28.1689, 151.8107],
  [-28.1688, 151.8096],
  [-28.1693, 151.8083],
  [-28.1700, 151.8074],
  [-28.1732, 151.8052],
  [-28.1776, 151.7997],
  [-28.1805, 151.7945],
  [-28.1809, 151.7934],
  [-28.1809, 151.7923],
  [-28.1804, 151.7885],
  [-28.1791, 151.7852],
  [-28.1779, 151.7794],
  [-28.1773, 151.7777],
  [-28.1686, 151.7613],
  [-28.1658, 151.7519],
  [-28.1658, 151.7504],
  [-28.1663, 151.7489],
  [-28.1674, 151.7477],
  [-28.1713, 151.7451],
  [-28.1718, 151.7445],
  [-28.1721, 151.7437],
  [-28.1724, 151.7379],
  [-28.1721, 151.7364],
  [-28.1674, 151.7298],
  [-28.1667, 151.7287],
  [-28.1639, 151.7204],
  [-28.1637, 151.7189],
  [-28.1635, 151.7155],
  [-28.1630, 151.7128],
  [-28.1615, 151.7071],
  [-28.1613, 151.7056],
  [-28.1617, 151.7011]
];

const thaneThallon = [
  [-28.1617, 151.7011],
  [-28.1618, 151.7004],
  [-28.1617, 151.6996],
  [-28.1613, 151.6986],
  [-28.1580, 151.6938],
  [-28.1557, 151.6919],
  [-28.1551, 151.6910],
  [-28.1548, 151.6898],
  [-28.1549, 151.6886],
  [-28.1558, 151.6859],
  [-28.1558, 151.6847],
  [-28.1554, 151.6839],
  [-28.1517, 151.6806],
  [-28.1513, 151.6798],
  [-28.1512, 151.6786],
  [-28.1516, 151.6775],
  [-28.1548, 151.6742],
  [-28.1558, 151.6725],
  [-28.1608, 151.6619],
  [-28.1617, 151.6607],
  [-28.1629, 151.6601],
  [-28.1663, 151.6597],
  [-28.1678, 151.6599],
  [-28.1688, 151.6597],
  [-28.1697, 151.6590],
  [-28.1713, 151.6564],
  [-28.1716, 151.6551],
  [-28.1715, 151.6529],
  [-28.1706, 151.6487],
  [-28.1706, 151.6474],
  [-28.1711, 151.6461],
  [-28.1740, 151.6425],
  [-28.1763, 151.6384],
  [-28.1770, 151.6365],
  [-28.1772, 151.6341],
  [-28.1767, 151.6296],
  [-28.1769, 151.6283],
  [-28.1776, 151.6272],
  [-28.1828, 151.6223],
  [-28.1837, 151.6218],
  [-28.1849, 151.6216],
  [-28.1856, 151.6212],
  [-28.1862, 151.6203],
  [-28.1866, 151.6177],
  [-28.1870, 151.6168],
  [-28.1876, 151.6163],
  [-28.1922, 151.6137],
  [-28.1934, 151.6124],
  [-28.1953, 151.6074],
  [-28.1957, 151.6055],
  [-28.1958, 151.6035],
  [-28.1960, 151.6026],
  [-28.1966, 151.6018],
  [-28.1975, 151.6014],
  [-28.1997, 151.6011],
  [-28.2007, 151.6005],
  [-28.2012, 151.5993],
  [-28.2013, 151.5981],
  [-28.2001, 151.5932],
  [-28.1997, 151.5881],
  [-28.1999, 151.5864],
  [-28.2010, 151.5843],
  [-28.2024, 151.5832],
  [-28.2037, 151.5830],
  [-28.2048, 151.5824],
  [-28.2055, 151.5808],
  [-28.2052, 151.5796],
  [-28.2045, 151.5786],
  [-28.2027, 151.5774],
  [-28.2017, 151.5763],
  [-28.2001, 151.5743],
  [-28.1998, 151.5729],
  [-28.2004, 151.5717],
  [-28.2021, 151.5707],
  [-28.2039, 151.5690],
  [-28.2048, 151.5675],
  [-28.2056, 151.5653],
  [-28.2065, 151.5641],
  [-28.2076, 151.5636],
  [-28.2116, 151.5636],
  [-28.2136, 151.5632],
  [-28.2162, 151.5614],
  [-28.2168, 151.5604],
  [-28.2168, 151.5592],
  [-28.2165, 151.5582],
  [-28.2164, 151.5573],
  [-28.2167, 151.5562],
  [-28.2175, 151.5547],
  [-28.2181, 151.5541],
  [-28.2192, 151.5533],
  [-28.2199, 151.5530],
  [-28.2222, 151.5527],
  [-28.2251, 151.5520],
  [-28.2272, 151.5507],
  [-28.2287, 151.5492],
  [-28.2291, 151.5489],
  [-28.2304, 151.5482],
  [-28.2333, 151.5453],
  [-28.2346, 151.5434],
  [-28.2354, 151.5417],
  [-28.2355, 151.5410],
  [-28.2355, 151.5391],
  [-28.2359, 151.5377],
  [-28.2368, 151.5370],
  [-28.2428, 151.5350],
  [-28.2469, 151.5321],
  [-28.2478, 151.5319],
  [-28.2487, 151.5332],
  [-28.2496, 151.5331],
  [-28.2505, 151.5335],
  [-28.2514, 151.5335],
  [-28.2528, 151.5326],
  [-28.2538, 151.5323],
  [-28.2561, 151.5322],
  [-28.2569, 151.5324],
  [-28.2585, 151.5331],
  [-28.2595, 151.5333],
  [-28.2604, 151.5329],
  [-28.2624, 151.5311],
  [-28.2629, 151.5299],
  [-28.2631, 151.5283],
  [-28.2634, 151.5274],
  [-28.2641, 151.5269],
  [-28.2650, 151.5267],
  [-28.2660, 151.5269],
  [-28.2670, 151.5267],
  [-28.2676, 151.5261],
  [-28.2706, 151.5197],
  [-28.2718, 151.5182],
  [-28.2739, 151.5165],
  [-28.2744, 151.5156],
  [-28.2744, 151.5147],
  [-28.2743, 151.5137],
  [-28.2744, 151.5129],
  [-28.2749, 151.5121],
  [-28.2756, 151.5116],
  [-28.2773, 151.5114],
  [-28.2780, 151.5110],
  [-28.2785, 151.5103],
  [-28.2796, 151.5080],
  [-28.2834, 151.5035],
  [-28.2860, 151.5012],
  [-28.2892, 151.4969],
  [-28.2901, 151.4948],
  [-28.2909, 151.4914],
  [-28.2914, 151.4905],
  [-28.2939, 151.4886],
  [-28.2948, 151.4871],
  [-28.2954, 151.4845],
  [-28.2958, 151.4835],
  [-28.2967, 151.4820],
  [-28.2979, 151.4808],
  [-28.2997, 151.4802],
  [-28.3051, 151.4805],
  [-28.3065, 151.4798],
  [-28.3087, 151.4775],
  [-28.3091, 151.4765],
  [-28.3090, 151.4757],
  [-28.3086, 151.4745],
  [-28.3085, 151.4737],
  [-28.3092, 151.4717],
  [-28.3100, 151.4707],
  [-28.3104, 151.4695],
  [-28.3104, 151.4673],
  [-28.3159, 151.4518],
  [-28.3161, 151.4485],
  [-28.3157, 151.4449],
  [-28.3158, 151.4438],
  [-28.3166, 151.4426],
  [-28.3198, 151.4402],
  [-28.3226, 151.4392],
  [-28.3245, 151.4381],
  [-28.3254, 151.4370],
  [-28.3277, 151.4322],
  [-28.3287, 151.4310],
  [-28.3299, 151.4299],
  [-28.3314, 151.4269],
  [-28.3318, 151.4253],
  [-28.3318, 151.4133],
  [-28.3321, 151.4120],
  [-28.3330, 151.4105],
  [-28.3336, 151.4086],
  [-28.3336, 151.4071],
  [-28.3334, 151.4052],
  [-28.3335, 151.4039],
  [-28.3345, 151.4004],
  [-28.3351, 151.3993],
  [-28.3391, 151.3966],
  [-28.3410, 151.3959],
  [-28.3457, 151.3947],
  [-28.3479, 151.3930],
  [-28.3509, 151.3874],
  [-28.3515, 151.3842],
  [-28.3528, 151.3813],
  [-28.3581, 151.3756],
  [-28.3586, 151.3746],
  [-28.3598, 151.3673],
  [-28.3621, 151.3630],
  [-28.3629, 151.3609],
  [-28.3690, 151.3521],
  [-28.3706, 151.3482],
  [-28.3718, 151.3465],
  [-28.3749, 151.3436],
  [-28.3764, 151.3428],
  [-28.3790, 151.3418],
  [-28.3816, 151.3396],
  [-28.3919, 151.3197],
  [-28.3940, 151.3145],
  [-28.3945, 151.3121],
  [-28.3954, 151.3028],
  [-28.3995, 151.2791],
  [-28.3996, 151.2768],
  [-28.3987, 151.2705],
  [-28.3988, 151.2682],
  [-28.4002, 151.2585],
  [-28.4013, 151.2562],
  [-28.4047, 151.2524],
  [-28.4058, 151.2503],
  [-28.4071, 151.2430],
  [-28.4079, 151.2410],
  [-28.4098, 151.2381],
  [-28.4107, 151.2353],
  [-28.4110, 151.2315],
  [-28.4105, 151.2287],
  [-28.4058, 151.2203],
  [-28.4054, 151.2175],
  [-28.4065, 151.2151],
  [-28.4097, 151.2130],
  [-28.4112, 151.2114],
  [-28.4121, 151.2088],
  [-28.4145, 151.1617],
  [-28.4158, 151.0925],
  [-28.4169, 151.0882],
  [-28.4277, 151.0668],
  [-28.4785, 150.9892],
  [-28.4809, 150.9840],
  [-28.4976, 150.9291],
  [-28.4989, 150.9263],
  [-28.5150, 150.8995],
  [-28.5270, 150.8668],
  [-28.5517, 150.8233],
  [-28.6152, 150.5988],
  [-28.6159, 150.5937],
  [-28.6140, 150.4688],
  [-28.6127, 150.4622],
  [-28.5832, 150.3962],
  [-28.5796, 150.3907],
  [-28.5371, 150.3386],
  [-28.5355, 150.3355],
  [-28.5345, 150.3276],
  [-28.5331, 150.3198],
  [-28.5333, 150.3181],
  [-28.5346, 150.3162],
  [-28.5378, 150.3132],
  [-28.5387, 150.3118],
  [-28.5390, 150.3100],
  [-28.5383, 150.3058],
  [-28.5379, 150.3045],
  [-28.5355, 150.2992],
  [-28.5344, 150.2972],
  [-28.5192, 150.2739],
  [-28.5176, 150.2708],
  [-28.5105, 150.2509],
  [-28.5090, 150.2483],
  [-28.5051, 150.2431],
  [-28.5040, 150.2406],
  [-28.4967, 150.2080],
  [-28.4927, 150.1852],
  [-28.4918, 150.1816],
  [-28.4747, 150.1392],
  [-28.4725, 150.1313],
  [-28.4694, 150.1117],
  [-28.4453, 150.0354],
  [-28.4227, 149.9462],
  [-28.4221, 149.9414],
  [-28.4194, 149.8960],
  [-28.4166, 149.8757],
  [-28.4167, 149.8732],
  [-28.4175, 149.8708],
  [-28.4209, 149.8645],
  [-28.4217, 149.8606],
  [-28.4204, 149.8497],
  [-28.4206, 149.8472],
  [-28.4431, 149.7466],
  [-28.4443, 149.7372],
  [-28.4451, 149.6970],
  [-28.4446, 149.6945],
  [-28.4435, 149.6930],
  [-28.4368, 149.6857],
  [-28.4352, 149.6833],
  [-28.4261, 149.6641],
  [-28.4256, 149.6608],
  [-28.4262, 149.6578],
  [-28.4440, 149.6185],
  [-28.4456, 149.6115],
  [-28.4472, 149.5869],
  [-28.4486, 149.5813],
  [-28.4756, 149.5162],
  [-28.4765, 149.5118],
  [-28.4769, 149.4977],
  [-28.4787, 149.4877],
  [-28.4844, 149.4701],
  [-28.4922, 149.3859],
  [-28.4936, 149.3788],
  [-28.5484, 149.1825],
  [-28.6369, 148.8647]
];

const thallonDirranbandi = [
  [-28.6369, 148.8647],
  [-28.6376, 148.8621],
  [-28.6379, 148.8597],
  [-28.6397, 148.8185],
  [-28.6279, 148.6247],
  [-28.6232, 148.5488],
  [-28.5977, 148.3255],
  [-28.5873, 148.2347],
  [-28.5862, 148.2244]
];

export const southWestern: Line = {
  name: 'South Western',
  state: 'QLD',
  segments: [
    {
      segments: [warwickJunctionThane],
      history: {
        opened: {
          date: '1904-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [thaneThallon],
      history: {
        opened: {
          date: '1913-05-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [thallonDirranbandi],
      history: {
        opened: {
          date: '1913-05-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-09-02',
          status: 'closed'
        }]
      }
    }
  ]
}
