import { Line } from "../../trackTypes";

const line = [
  [-38.3415, 143.5591],
  [-38.3409, 143.5578],
  [-38.3400, 143.5566],
  [-38.3394, 143.5560],
  [-38.3348, 143.5530],
  [-38.3289, 143.5474],
  [-38.3232, 143.5424],
  [-38.3212, 143.5401],
  [-38.3194, 143.5374],
  [-38.3184, 143.5363],
  [-38.3175, 143.5359],
  [-38.3165, 143.5358],
  [-38.3047, 143.5380],
  [-38.3038, 143.5379],
  [-38.3030, 143.5375],
  [-38.2898, 143.5284],
  [-38.2851, 143.5250],
  [-38.2848, 143.5248],
  [-38.2844, 143.5249],
  [-38.2804, 143.5280],
  [-38.2796, 143.5283],
  [-38.2747, 143.5299],
  [-38.2735, 143.5298],
  [-38.2721, 143.5295],
  [-38.2680, 143.5274],
  [-38.2648, 143.5261],
  [-38.2495, 143.5237],
  [-38.2485, 143.5233],
  [-38.2454, 143.5204],
  [-38.2447, 143.5192],
  [-38.2442, 143.5177],
  [-38.2433, 143.5138],
  [-38.2428, 143.5131],
  [-38.2383, 143.5108]
];

export const alvie: Line = {
  name: 'Alvie',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1923-06-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-12-18',
          status: 'closed'
        }]
      }
    }
  ]
};
