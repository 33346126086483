import { Line } from "../../trackTypes";

const uranquintyGalore = [
  [-35.1954, 147.2412],
  [-35.1958, 147.2399],
  [-35.1958, 147.2388],
  [-35.1955, 147.2378],
  [-35.1722, 147.1899],
  [-35.1714, 147.1873],
  [-35.1705, 147.1805],
  [-35.1705, 147.1782],
  [-35.1718, 147.1627],
  [-35.1717, 147.1607],
  [-35.1692, 147.1477],
  [-35.1687, 147.1459],
  [-35.1618, 147.1256],
  [-35.1602, 147.1225],
  [-35.1516, 147.1120],
  [-35.1498, 147.1095],
  [-35.0849, 146.9983],
  [-35.0833, 146.9949],
  [-35.0739, 146.9668],
  [-35.0624, 146.9386],
  [-35.0343, 146.8818],
  [-34.9999, 146.8117]
];

const galoreKywong = [
  [-34.9999, 146.8117],
  [-34.9987, 146.8093],
  [-34.9977, 146.8063],
  [-34.9976, 146.8037],
  [-35.0010, 146.7770],
  [-35.0012, 146.7734],
  [-34.9970, 146.7473],
  [-34.9961, 146.7453],
  [-34.9946, 146.7437],
  [-34.9869, 146.7389],
  [-34.9809, 146.7342]
];

export const kywong: Line = {
  name: 'Kywong',
  state: 'NSW',
  segments: [
    {
      segments: [uranquintyGalore],
      history: {
        opened: {
          date: '1928-10-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [galoreKywong],
      history: {
        opened: {
          date: '1929-04-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-06',
          status: 'closed'
        }]
      }
    }
  ]
};
