import { Line } from "../../trackTypes";

const boyanupLockville = [
  [-33.4818, 115.7277],
  [-33.4831, 115.7252],
  [-33.5238, 115.5952],
  [-33.5299, 115.5855],
  [-33.5548, 115.5648],
  [-33.5561, 115.5632],
  [-33.5747, 115.5254],
  [-33.5832, 115.5132],
  [-33.5923, 115.5068],
  [-33.6026, 115.4997],
  [-33.6040, 115.4979],
  [-33.6058, 115.4932],
  [-33.6301, 115.4424]
];

const busseltonWitchcliffe = [
  [-33.6458, 115.3457],
  [-33.6453, 115.3449],
  [-33.6452, 115.3440],
  [-33.6453, 115.3430],
  [-33.6530, 115.3234],
  [-33.6542, 115.3217],
  [-33.6591, 115.3173],
  [-33.6605, 115.3167],
  [-33.6647, 115.3162],
  [-33.6660, 115.3155],
  [-33.6714, 115.3087],
  [-33.6749, 115.3047],
  [-33.6757, 115.3018],
  [-33.6755, 115.1957],
  [-33.6747, 115.1930],
  [-33.6655, 115.1795],
  [-33.6649, 115.1767],
  [-33.6648, 115.1455],
  [-33.6656, 115.1435],
  [-33.6723, 115.1316],
  [-33.6770, 115.1167],
  [-33.6779, 115.1151],
  [-33.6807, 115.1126],
  [-33.6836, 115.1092],
  [-33.6850, 115.1084],
  [-33.6894, 115.1076],
  [-33.6921, 115.1060],
  [-33.6934, 115.1056],
  [-33.7011, 115.1060],
  [-33.7022, 115.1056],
  [-33.7068, 115.1032],
  [-33.7092, 115.1026],
  [-33.7112, 115.1030],
  [-33.7130, 115.1046],
  [-33.7153, 115.1049],
  [-33.7164, 115.1045],
  [-33.7176, 115.1029],
  [-33.7185, 115.1026],
  [-33.7195, 115.1029],
  [-33.7206, 115.1038],
  [-33.7214, 115.1040],
  [-33.7235, 115.1036],
  [-33.7247, 115.1041],
  [-33.7269, 115.1061],
  [-33.7279, 115.1064],
  [-33.7290, 115.1061],
  [-33.7305, 115.1048],
  [-33.7349, 115.1034],
  [-33.7438, 115.1042],
  [-33.7469, 115.1053],
  [-33.7479, 115.1066],
  [-33.7496, 115.1117],
  [-33.7502, 115.1128],
  [-33.7522, 115.1146],
  [-33.7556, 115.1163],
  [-33.7565, 115.1172],
  [-33.7570, 115.1183],
  [-33.7577, 115.1190],
  [-33.7587, 115.1194],
  [-33.7654, 115.1190],
  [-33.7682, 115.1184],
  [-33.7777, 115.1199],
  [-33.7853, 115.1202],
  [-33.7871, 115.1199],
  [-33.7921, 115.1157],
  [-33.7932, 115.1155],
  [-33.7943, 115.1157],
  [-33.7954, 115.1168],
  [-33.7968, 115.1176],
  [-33.7980, 115.1171],
  [-33.7997, 115.1148],
  [-33.8000, 115.1136],
  [-33.8001, 115.1115],
  [-33.8008, 115.1084],
  [-33.8015, 115.1071],
  [-33.8028, 115.1067],
  [-33.8089, 115.1090],
  [-33.8106, 115.1105],
  [-33.8135, 115.1115],
  [-33.8158, 115.1105],
  [-33.8179, 115.1078],
  [-33.8194, 115.1065],
  [-33.8255, 115.1046],
  [-33.8292, 115.1016],
  [-33.8312, 115.1008],
  [-33.8332, 115.1012],
  [-33.8344, 115.1011],
  [-33.8358, 115.1003],
  [-33.8390, 115.0993],
  [-33.8401, 115.0994],
  [-33.8419, 115.1003],
  [-33.8448, 115.1008],
  [-33.8466, 115.1003],
  [-33.8779, 115.0804],
  [-33.8849, 115.0744],
  [-33.8867, 115.0732],
  [-33.8906, 115.0722],
  [-33.8922, 115.0723],
  [-33.8975, 115.0734],
  [-33.8985, 115.0740],
  [-33.9032, 115.0788],
  [-33.9068, 115.0839],
  [-33.9080, 115.0846],
  [-33.9090, 115.0844],
  [-33.9162, 115.0794],
  [-33.9199, 115.0793],
  [-33.9232, 115.0783],
  [-33.9257, 115.0791],
  [-33.9286, 115.0790],
  [-33.9298, 115.0785],
  [-33.9310, 115.0768],
  [-33.9334, 115.0697],
  [-33.9346, 115.0681],
  [-33.9361, 115.0679],
  [-33.9394, 115.0697],
  [-33.9407, 115.0700],
  [-33.9443, 115.0694],
  [-33.9453, 115.0688],
  [-33.9464, 115.0676],
  [-33.9474, 115.0669],
  [-33.9489, 115.0665],
  [-33.9500, 115.0658],
  [-33.9516, 115.0636],
  [-33.9534, 115.0631],
  [-33.9549, 115.0631],
  [-33.9573, 115.0641],
  [-33.9718, 115.0797],
  [-33.9743, 115.0812],
  [-33.9866, 115.0849],
  [-33.9884, 115.0847],
  [-33.9918, 115.0831],
  [-33.9971, 115.0828],
  [-34.0003, 115.0816],
  [-34.0036, 115.0821],
  [-34.0048, 115.0834],
  [-34.0061, 115.0865],
  [-34.0093, 115.0895],
  [-34.0137, 115.0922],
  [-34.0148, 115.0940],
  [-34.0155, 115.0943],
  [-34.0195, 115.0949],
  [-34.0260, 115.0981]
];

const witchcliffeFlindersBay = [
  [-34.0260, 115.0981],
  [-34.0288, 115.0991],
  [-34.0300, 115.0993],
  [-34.0496, 115.0974],
  [-34.0539, 115.0946],
  [-34.0546, 115.0944],
  [-34.0647, 115.0940],
  [-34.0655, 115.0941],
  [-34.0725, 115.0978],
  [-34.0775, 115.0990],
  [-34.0863, 115.0985],
  [-34.0869, 115.0980],
  [-34.0871, 115.0983],
  [-34.0873, 115.0952],
  [-34.0880, 115.0931],
  [-34.0893, 115.0912],
  [-34.0917, 115.0902],
  [-34.0970, 115.0905],
  [-34.0979, 115.0901],
  [-34.0992, 115.0891],
  [-34.1002, 115.0888],
  [-34.1025, 115.0893],
  [-34.1080, 115.0886],
  [-34.1094, 115.0890],
  [-34.1108, 115.0890],
  [-34.1121, 115.0881],
  [-34.1129, 115.0879],
  [-34.1138, 115.0881],
  [-34.1152, 115.0890],
  [-34.1181, 115.0891],
  [-34.1203, 115.0898],
  [-34.1214, 115.0899],
  [-34.1240, 115.0884],
  [-34.1253, 115.0881],
  [-34.1303, 115.0897],
  [-34.1313, 115.0896],
  [-34.1341, 115.0872],
  [-34.1451, 115.0819],
  [-34.1462, 115.0817],
  [-34.1511, 115.0826],
  [-34.1644, 115.0830],
  [-34.1682, 115.0812],
  [-34.1700, 115.0808],
  [-34.1708, 115.0812],
  [-34.1724, 115.0829],
  [-34.1735, 115.0831],
  [-34.1767, 115.0822],
  [-34.1809, 115.0805],
  [-34.1824, 115.0803],
  [-34.1859, 115.0808],
  [-34.2027, 115.0780],
  [-34.2092, 115.0781],
  [-34.2110, 115.0776],
  [-34.2123, 115.0770],
  [-34.2136, 115.0769],
  [-34.2338, 115.0872],
  [-34.2524, 115.1027],
  [-34.2532, 115.1032],
  [-34.2545, 115.1036],
  [-34.2756, 115.1203],
  [-34.2767, 115.1215],
  [-34.2833, 115.1365],
  [-34.2841, 115.1374],
  [-34.2989, 115.1431],
  [-34.3078, 115.1462],
  [-34.3382, 115.1638],
  [-34.3394, 115.1652],
  [-34.3399, 115.1655],
  [-34.3415, 115.1660],
  [-34.3420, 115.1662],
  [-34.3445, 115.1689],
  [-34.3448, 115.1704]
];

export const flindersBay: Line = {
  name: 'Flinders Bay',
  state: 'WA',
  segments: [
    {
      segments: [boyanupLockville],
      history: {
        opened: {
          date: '1894-11-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [busseltonWitchcliffe],
      history: {
        opened: {
          date: '1924-10-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [witchcliffeFlindersBay],
      history: {
        opened: {
          date: '1925-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
