import { Line } from "../../../trackTypes";

const line = [
  [-45.8955, 170.4193],
  [-45.8947, 170.4189],
  [-45.8940, 170.4189],
  [-45.8907, 170.4212],
  [-45.8901, 170.4219],
  [-45.8895, 170.4228],
  [-45.8888, 170.4232],
  [-45.8882, 170.4230],
  [-45.8878, 170.4224],
  [-45.8877, 170.4216],
  [-45.8876, 170.4200],
  [-45.8871, 170.4190],
  [-45.8863, 170.4185],
  [-45.8848, 170.4179],
  [-45.8842, 170.4174],
  [-45.8837, 170.4166],
  [-45.8832, 170.4164],
  [-45.8826, 170.4165],
  [-45.8819, 170.4171],
  [-45.8813, 170.4170]
];

export const fernhill: Line = {
  name: 'Fernhill',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1883-07-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
