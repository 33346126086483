import { Line } from "../../../../trackTypes";

const linePart1 = [
  [-34.92437, 138.59981],
  [-34.92439, 138.59944],
  [-34.92488, 138.58930],
  [-34.92489, 138.58785],
  [-34.92494, 138.58770],
  [-34.92503, 138.58761],
  [-34.92510, 138.58752],
  [-34.93084, 138.58798]
]

const grenfelStJunctions = [
  [-34.92425, 138.59963],
  [-34.92432, 138.59957],
  [-34.92435, 138.59952],
  [-34.92439, 138.59944],
  [-34.92443, 138.59956],
  [-34.92447, 138.59962],
  [-34.92449, 138.59965]
];

const sturtJunction = [
  [-34.93337, 138.58817],
  [-34.93345, 138.58820],
  [-34.93349, 138.58827],
  [-34.93352, 138.58836]
];

const linePart2 = [
  [-34.93366, 138.58819],
  [-34.93359, 138.58820],
  [-34.93355, 138.58825],
  [-34.93352, 138.58836],
  [-34.93326, 138.59333],
  [-34.93321, 138.59343],
  [-34.93315, 138.59348],
  [-34.93308, 138.59352],
  [-34.93225, 138.59347],
  [-34.93181, 138.59412],
  [-34.93035, 138.59400]
];

const linePart3 = [
  [-34.92906, 138.59393],
  [-34.92902, 138.59394],
  [-34.92897, 138.59399],
  [-34.92894, 138.59406],
  [-34.92867, 138.59922]
];

const morphettStNorthJunction = [
  [-34.92894, 138.59406],
  [-34.92891, 138.59400],
  [-34.92887, 138.59395],
  [-34.92881, 138.59391]
];

const victoriaSquare = [
  [-34.92867, 138.59922],
  [-34.92864, 138.60002]
];

const franklinStLoopSouth = [
  [-34.93010, 138.60012],
  [-34.93014, 138.59945],
  [-34.93013, 138.59937],
  [-34.93010, 138.59930],
  [-34.93005, 138.59926],
  [-34.92867, 138.59922]
];

const franklinStLoop = [
  [-34.92867, 138.59922],
  [-34.92727, 138.59907],
  [-34.92722, 138.59902],
  [-34.92720, 138.59894],
  [-34.92748, 138.59400],
  [-34.92751, 138.59391],
  [-34.92754, 138.59387],
  [-34.92760, 138.59383]
];

export const west3: Line = {
  name: 'West3',
  state: 'SA',
  segments: [
    {
      segments: [linePart1, linePart2, linePart3, sturtJunction, grenfelStJunctions],
      history: {
        opened: {
          date: '1911-10-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaSquare],
      history: {
        opened: {
          date: '1911-10-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [franklinStLoopSouth],
      history: {
        opened: {
          date: '1952-02-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [franklinStLoop, morphettStNorthJunction],
      history: {
        opened: {
          date: '1952-02-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-04-25',
          status: 'closed'
        }]
      }
    }
  ]
};
