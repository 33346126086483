import { Line } from "../../../trackTypes";

const line = [
  [-36.7074, 142.2019],
  [-36.7079, 142.2033],
  [-36.7086, 142.2044],
  [-36.7093, 142.2048],
  [-36.7233, 142.2051],
  [-36.7276, 142.2055],
  [-36.7400, 142.2048],
  [-36.7766, 142.1799],
  [-36.7776, 142.1787],
  [-36.7793, 142.1760],
  [-36.7807, 142.1748],
  [-36.7833, 142.1745],
  [-36.7967, 142.1744]
];

export const mcKenzieCreek: Line = {
  name: 'McKenzie Creek',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1932-07-26',
          status: 'closed'
        }]
      }
    }
  ]
}
