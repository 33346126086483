import { Line } from "../../../trackTypes";

const rockdaleBrightonLeSands = [
  [-33.95261, 151.13701],
  [-33.95306, 151.13810],
  [-33.96080, 151.15605],
  [-33.96090, 151.15616],
  [-33.96100, 151.15622],
  [-33.96111, 151.15624],
  [-33.96438, 151.15480]
];

const kogarahSansSouci = [
  [-33.97082, 151.13631],
  [-33.97072, 151.13625],
  [-33.97063, 151.13616],
  [-33.97029, 151.13544],
  [-33.97017, 151.13527],
  [-33.96977, 151.13482],
  [-33.96599, 151.13133],
  [-33.96590, 151.13132],
  [-33.96580, 151.13134],
  [-33.96548, 151.13160],
  [-33.96509, 151.13188],
  [-33.96457, 151.13218],
  [-33.96397, 151.13245],
  [-33.96335, 151.13264],
  [-33.96307, 151.13273],
  [-33.96300, 151.13276],
  [-33.96294, 151.13283],
  [-33.96280, 151.13306],
  [-33.96280, 151.13314],
  [-33.96282, 151.13323],
  [-33.96579, 151.13573],
  [-33.96587, 151.13577],
  [-33.96617, 151.13580],
  [-33.96622, 151.13584],
  [-33.96624, 151.13590],
  [-33.96619, 151.13659],
  [-33.96621, 151.13668],
  [-33.96628, 151.13673],
  [-33.96639, 151.13676],
  [-33.96878, 151.13626],
  [-33.96908, 151.13623],
  [-33.97082, 151.13631],
  [-33.98164, 151.13676],
  [-33.98188, 151.13675],
  [-33.98212, 151.13671],
  [-33.98564, 151.13599],
  [-33.98585, 151.13591],
  [-33.99256, 151.13188],
  [-34.00146, 151.12641],
  [-34.00183, 151.12626],
  [-34.00216, 151.12624],
  [-34.00249, 151.12633],
  [-34.00268, 151.12646],
  [-34.00292, 151.12672],
  [-34.00302, 151.12692],
  [-34.00424, 151.13010],
  [-34.00434, 151.13045],
  [-34.00448, 151.13136],
  [-34.00448, 151.13167],
  [-34.00420, 151.13368],
  [-34.00411, 151.13410],
  [-34.00396, 151.13450],
  [-34.00353, 151.13556],
  [-34.00343, 151.13587],
  [-34.00312, 151.13777],
  [-34.00305, 151.13797],
  [-34.00298, 151.13808],
  [-34.00283, 151.13821],
  [-34.00259, 151.13832],
  [-33.99807, 151.13944],
  [-33.99441, 151.14006],
  [-33.99422, 151.13997],
  [-33.99407, 151.13986],
  [-33.99397, 151.13974],
  [-33.99388, 151.13956],
  [-33.99381, 151.13940],
  [-33.99276, 151.13205],
  [-33.99267, 151.13192],
  [-33.99256, 151.13188]
];

const arncliffeBexley = [
  [-33.93641, 151.14691],
  [-33.93571, 151.14723],
  [-33.93548, 151.14739],
  [-33.93539, 151.14742],
  [-33.93533, 151.14741],
  [-33.93442, 151.14647],
  [-33.93440, 151.14641],
  [-33.93440, 151.14633],
  [-33.93548, 151.14413],
  [-33.94155, 151.13228],
  [-33.94169, 151.13205],
  [-33.94325, 151.12998],
  [-33.94348, 151.12970],
  [-33.94367, 151.12952],
  [-33.94449, 151.12881],
  [-33.94505, 151.12840],
  [-33.94531, 151.12825],
  [-33.94576, 151.12809],
  [-33.94625, 151.12798],
  [-33.94645, 151.12796],
  [-33.94681, 151.12800],
  [-33.94705, 151.12800],
  [-33.94812, 151.12789],
  [-33.94833, 151.12786],
  [-33.94851, 151.12776],
  [-33.95061, 151.12591],
  [-33.95081, 151.12569],
  [-33.95267, 151.12236],
  [-33.95278, 151.12207],
  [-33.95282, 151.12182],
  [-33.95284, 151.12159],
  [-33.95278, 151.12101],
  [-33.95280, 151.12067],
  [-33.95285, 151.12043],
  [-33.95294, 151.12018],
  [-33.95312, 151.11982],
  [-33.95327, 151.11960],
  [-33.95347, 151.11942],
  [-33.95371, 151.11925],
  [-33.95400, 151.11914],
  [-33.95419, 151.11910]
];

const sutherlandCronulla = [
  [-34.03257, 151.05713],
  [-34.03016, 151.05880],
  [-34.02991, 151.05909],
  [-34.02974, 151.05939],
  [-34.02965, 151.05972],
  [-34.02971, 151.06161],
  [-34.02975, 151.06204],
  [-34.03149, 151.06664],
  [-34.03162, 151.06709],
  [-34.03172, 151.06763],
  [-34.03180, 151.06829],
  [-34.03232, 151.07434],
  [-34.03230, 151.07488],
  [-34.03225, 151.07521],
  [-34.03161, 151.07734],
  [-34.03154, 151.07763],
  [-34.03152, 151.07789],
  [-34.03153, 151.07824],
  [-34.03159, 151.07853],
  [-34.03169, 151.07891],
  [-34.03216, 151.08217],
  [-34.03221, 151.08265],
  [-34.03224, 151.08319],
  [-34.03230, 151.08375],
  [-34.03290, 151.08788],
  [-34.03294, 151.08841],
  [-34.03293, 151.08888],
  [-34.03288, 151.08983],
  [-34.03291, 151.09023],
  [-34.03294, 151.09070],
  [-34.03441, 151.10459],
  [-34.03483, 151.10772],
  [-34.03523, 151.11311],
  [-34.03529, 151.11342],
  [-34.03539, 151.11371],
  [-34.03712, 151.11776],
  [-34.03726, 151.11800],
  [-34.03746, 151.11820],
  [-34.03762, 151.11834],
  [-34.04291, 151.12279],
  [-34.04306, 151.12297],
  [-34.04315, 151.12315],
  [-34.04321, 151.12337],
  [-34.04387, 151.12872],
  [-34.04395, 151.12908],
  [-34.04407, 151.12944],
  [-34.04422, 151.12977],
  [-34.04626, 151.13422],
  [-34.04641, 151.13461],
  [-34.05134, 151.15306],
  [-34.05142, 151.15314],
  [-34.05156, 151.15316],
  [-34.05525, 151.15174],
  [-34.05541, 151.15172],
  [-34.05560, 151.15170],
  [-34.05798, 151.15219],
  [-34.05815, 151.15227],
  [-34.05828, 151.15235],
  [-34.05842, 151.15251],
  [-34.05949, 151.15464],
  [-34.05960, 151.15472],
  [-34.05970, 151.15475],
  [-34.05977, 151.15476],
  [-34.05992, 151.15473],
  [-34.06130, 151.15377],
  [-34.06142, 151.15373],
  [-34.06294, 151.15344],
  [-34.06311, 151.15345],
  [-34.06382, 151.15367]
];

export const sydneyTramsSouth: Line = {
  name: 'Sydney Trams (South)',
  state: 'NSW',
  segments: [
    {
      segments: [rockdaleBrightonLeSands],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sutherlandCronulla],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1932-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [arncliffeBexley],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kogarahSansSouci],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
