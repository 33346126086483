import { Line } from "../../trackTypes";

const lintonJunctionScarsdale = [
  [-37.5324, 143.8000],
  [-37.5313, 143.7955],
  [-37.5310, 143.7915],
  [-37.5316, 143.7870],
  [-37.5341, 143.7810],
  [-37.5541, 143.7509],
  [-37.5591, 143.7424],
  [-37.5633, 143.7341],
  [-37.5641, 143.7330],
  [-37.5650, 143.7325],
  [-37.6134, 143.7125],
  [-37.6141, 143.7121],
  [-37.6151, 143.7113],
  [-37.6359, 143.6917],
  [-37.6368, 143.6908],
  [-37.6383, 143.6887],
  [-37.6400, 143.6856],
  [-37.6404, 143.6849],
  [-37.6411, 143.6843],
  [-37.6458, 143.6810],
  [-37.6466, 143.6805],
  [-37.6473, 143.6803],
  [-37.6491, 143.6797],
  [-37.6499, 143.6793],
  [-37.6510, 143.6785],
  [-37.6521, 143.6774],
  [-37.6621, 143.6630],
  [-37.6641, 143.6605],
  [-37.6664, 143.6586],
  [-37.6691, 143.6569]
];

const scarsdaleLinton = [
  [-37.6691, 143.6569],
  [-37.6735, 143.6541],
  [-37.6745, 143.6537],
  [-37.6758, 143.6535],
  [-37.6980, 143.6548],
  [-37.6988, 143.6548],
  [-37.7000, 143.6543],
  [-37.7009, 143.6534],
  [-37.7017, 143.6517],
  [-37.7019, 143.6505],
  [-37.7024, 143.6429],
  [-37.7026, 143.6419],
  [-37.7049, 143.6313],
  [-37.7053, 143.6298],
  [-37.7059, 143.6286],
  [-37.7071, 143.6269],
  [-37.7078, 143.6254],
  [-37.7098, 143.6191],
  [-37.7101, 143.6177],
  [-37.7101, 143.6157],
  [-37.7091, 143.6115],
  [-37.7089, 143.6093],
  [-37.7088, 143.6072],
  [-37.7083, 143.6054],
  [-37.7071, 143.6033],
  [-37.7056, 143.6020],
  [-37.7048, 143.6010],
  [-37.7041, 143.5993],
  [-37.7005, 143.5873],
  [-37.6985, 143.5825],
  [-37.6978, 143.5802],
  [-37.6968, 143.5743],
  [-37.6961, 143.5716],
  [-37.6952, 143.5692],
  [-37.6937, 143.5674],
  [-37.6920, 143.5663],
  [-37.6911, 143.5660],
  [-37.6900, 143.5660],
  [-37.6869, 143.5661]
];

const lintonSkipton = [
  [-37.6869, 143.5661],
  [-37.6819, 143.5661],
  [-37.6811, 143.5658],
  [-37.6804, 143.5652],
  [-37.6800, 143.5644],
  [-37.6787, 143.5587],
  [-37.6787, 143.5570],
  [-37.6790, 143.5556],
  [-37.6799, 143.5541],
  [-37.6811, 143.5528],
  [-37.6821, 143.5513],
  [-37.6825, 143.5487],
  [-37.6818, 143.5470],
  [-37.6809, 143.5464],
  [-37.6786, 143.5457],
  [-37.6777, 143.5452],
  [-37.6764, 143.5440],
  [-37.6756, 143.5423],
  [-37.6745, 143.5396],
  [-37.6741, 143.5372],
  [-37.6736, 143.5359],
  [-37.6736, 143.5351],
  [-37.6742, 143.5313],
  [-37.6737, 143.5276],
  [-37.6729, 143.5261],
  [-37.6719, 143.5254],
  [-37.6705, 143.5250],
  [-37.6695, 143.5240],
  [-37.6691, 143.5228],
  [-37.6693, 143.5212],
  [-37.6696, 143.5201],
  [-37.6695, 143.5186],
  [-37.6666, 143.5125],
  [-37.6663, 143.5110],
  [-37.6663, 143.5095],
  [-37.6660, 143.5081],
  [-37.6648, 143.5058],
  [-37.6644, 143.5050],
  [-37.6643, 143.5043],
  [-37.6644, 143.4979],
  [-37.6643, 143.4969],
  [-37.6636, 143.4945],
  [-37.6636, 143.4929],
  [-37.6641, 143.4910],
  [-37.6674, 143.4856],
  [-37.6679, 143.4841],
  [-37.6685, 143.4732],
  [-37.6675, 143.4659],
  [-37.6674, 143.4638],
  [-37.6677, 143.4625],
  [-37.6707, 143.4538],
  [-37.6748, 143.4453],
  [-37.6810, 143.4339],
  [-37.6817, 143.4315],
  [-37.6871, 143.3919],
  [-37.6895, 143.3830],
  [-37.6937, 143.3707],
  [-37.6940, 143.3693],
  [-37.6937, 143.3679],
  [-37.6897, 143.3630]
]

export const skipton: Line = {
  name: 'Skipton',
  state: 'VIC',
  segments: [
    {
      segments: [lintonJunctionScarsdale],
      history: {
        opened: {
          date: '1883-08-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-12-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [scarsdaleLinton],
      history: {
        opened: {
          date: '1890-10-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lintonSkipton],
      history: {
        opened: {
          date: '1916-01-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-04',
          status: 'closed'
        }]
      }
    }
  ]
};
