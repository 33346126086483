import { Line } from "../../../trackTypes";

const clarkefieldLancefield = [
  [-37.4818, 144.7421],
  [-37.4801, 144.7405],
  [-37.4775, 144.7391],
  [-37.4756, 144.7386],
  [-37.4732, 144.7388],
  [-37.4450, 144.7440],
  [-37.4294, 144.7467],
  [-37.4137, 144.7488],
  [-37.4127, 144.7486],
  [-37.4026, 144.7472],
  [-37.3958, 144.7481],
  [-37.3775, 144.7459],
  [-37.3629, 144.7445],
  [-37.3577, 144.7436],
  [-37.3517, 144.7445],
  [-37.3509, 144.7448],
  [-37.3499, 144.7457],
  [-37.3481, 144.7481],
  [-37.3471, 144.7490],
  [-37.3462, 144.7494],
  [-37.3422, 144.7517],
  [-37.3376, 144.7541],
  [-37.3367, 144.7545],
  [-37.3351, 144.7547],
  [-37.3020, 144.7545],
  [-37.2997, 144.7540],
  [-37.2978, 144.7527],
  [-37.2852, 144.7392],
  [-37.2837, 144.7381],
  [-37.2821, 144.7376],
  [-37.2792, 144.7376]
];

const lancefieldKilmore = [
  [-37.2792, 144.7376],
  [-37.2752, 144.7379],
  [-37.2724, 144.7396],
  [-37.2705, 144.7423],
  [-37.2622, 144.7686],
  [-37.2616, 144.7729],
  [-37.2620, 144.7779],
  [-37.2635, 144.7820],
  [-37.2661, 144.7858],
  [-37.2721, 144.7912],
  [-37.2763, 144.7928],
  [-37.2825, 144.7936],
  [-37.2861, 144.7939],
  [-37.2910, 144.7947],
  [-37.2931, 144.7958],
  [-37.2947, 144.7981],
  [-37.2948, 144.8014],
  [-37.2936, 144.8043],
  [-37.2909, 144.8070],
  [-37.2884, 144.8082],
  [-37.2855, 144.8082],
  [-37.2810, 144.8082],
  [-37.2759, 144.8086],
  [-37.2736, 144.8090],
  [-37.2710, 144.8109],
  [-37.2693, 144.8144],
  [-37.2689, 144.8180],
  [-37.2699, 144.8200],
  [-37.2724, 144.8218],
  [-37.2740, 144.8217],
  [-37.2758, 144.8212],
  [-37.2788, 144.8229],
  [-37.2819, 144.8250],
  [-37.2834, 144.8255],
  [-37.2952, 144.8262],
  [-37.2985, 144.8275],
  [-37.3146, 144.8413],
  [-37.3181, 144.8465],
  [-37.3188, 144.8505],
  [-37.3179, 144.8552],
  [-37.3149, 144.8587],
  [-37.3111, 144.8614],
  [-37.3063, 144.8660],
  [-37.3027, 144.8724],
  [-37.3011, 144.8783],
  [-37.2908, 144.9371],
  [-37.2902, 144.9402],
  [-37.2906, 144.9422],
  [-37.2928, 144.9446]
];

export const lancefield: Line = {
  name: 'Lancefield',
  state: 'VIC',
  segments: [
    {
      segments: [clarkefieldLancefield],
      history: {
        opened: {
          date: '1881-06-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-08-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lancefieldKilmore],
      history: {
        opened: {
          date: '1892-04-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1900-02-06',
          status: 'open'
        }, {
          date: '1897-06-01',
          status: 'closed'
        }, {
          date: '1903-07-15',
          status: 'closed'
        }]
      }
    }
  ]
}
