import { Line } from "../../trackTypes";

const cunninghamPier = [
  [-38.1422, 144.3547],
  [-38.1433, 144.3554],
  [-38.1442, 144.3562],
  [-38.1448, 144.3571],
  [-38.1451, 144.3580],
  [-38.1451, 144.3591],
  [-38.1448, 144.3603],
  [-38.1442, 144.3609],
  [-38.1436, 144.3613],
  [-38.1409, 144.3626]
];

const grain = [
  [-38.1117, 144.3540],
  [-38.1112, 144.3548],
  [-38.1106, 144.3558],
  [-38.1101, 144.3572],
  [-38.1099, 144.3590],
  [-38.1105, 144.3642]
];

const grainLoop = [
  [-38.1105, 144.3642],
  [-38.1103, 144.3650],
  [-38.1099, 144.3654],
  [-38.1096, 144.3655],
  [-38.1091, 144.3655],
  [-38.1087, 144.3652],
  [-38.1084, 144.3649],
  [-38.1082, 144.3645],
  [-38.1078, 144.3632],
  [-38.1074, 144.3605],
  [-38.1074, 144.3598],
  [-38.1075, 144.3594],
  [-38.1077, 144.3590],
  [-38.1079, 144.3587],
  [-38.1081, 144.3582],
  [-38.1083, 144.3573],
  [-38.1086, 144.3565],
  [-38.1089, 144.3561],
  [-38.1098, 144.3555],
  [-38.1104, 144.3554],
  [-38.1107, 144.3552],
  [-38.1112, 144.3548]
];

const geelongRacecourse = [
  [-38.1991, 144.3546],
  [-38.2003, 144.3545],
  [-38.2017, 144.3550],
  [-38.2033, 144.3562],
  [-38.2046, 144.3582],
  [-38.2137, 144.3698],
  [-38.2144, 144.3713],
  [-38.2161, 144.3761],
  [-38.2177, 144.3806],
  [-38.2196, 144.3970]
];

const eldersIxlSiding = [
  [-38.0496, 144.3935],
  [-38.0490, 144.3933],
  [-38.0485, 144.3930],
  [-38.0483, 144.3925],
  [-38.0481, 144.3921],
  [-38.0473, 144.3848]
];

const midwaySiding = [
  [-38.0976, 144.3656],
  [-38.0985, 144.3656],
  [-38.0992, 144.3657],
  [-38.1003, 144.3662],
  [-38.1008, 144.3662],
  [-38.1024, 144.3659]
];

const phosphateSiding = [
  [-38.0976, 144.3656],
  [-38.0968, 144.3664],
  [-38.0963, 144.3671],
  [-38.0961, 144.3676],
  [-38.0960, 144.3682],
  [-38.0974, 144.3793]
];

const fordSiding = [
  [-38.1006, 144.3641],
  [-38.1015, 144.3624],
  [-38.1016, 144.3618],
  [-38.1010, 144.3584]
];

export const geelongFreight: Line = {
  name: 'geelong Freight',
  state: 'VIC',
  segments: [
    {
      segments: [cunninghamPier],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [grain],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-08-13',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [grainLoop],
      history: {
        opened: {
          date: '1981-08-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-08-13',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [geelongRacecourse],
      history: {
        opened: {
          date: '1878-01-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1906-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eldersIxlSiding],
      history: {
        opened: {
          date: '1986-03-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-10-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [midwaySiding],
      history: {
        opened: {
          date: '2003-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-01-01',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [phosphateSiding],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2003-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fordSiding],
      history: {
        opened: {
          date: '1925-07-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
