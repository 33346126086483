import { Line } from "../../../trackTypes";

const line = [
  [-33.8730, 150.9561],
  [-33.8726, 150.9563],
  [-33.8723, 150.9566],
  [-33.8717, 150.9575],
  [-33.8713, 150.9578],
  [-33.8708, 150.9580],
  [-33.8705, 150.9579],
  [-33.8677, 150.9568],
  [-33.8649, 150.9550],
  [-33.8620, 150.9529],
  [-33.8573, 150.9507],
  [-33.8568, 150.9502],
  [-33.8535, 150.9404],
  [-33.8514, 150.9268],
  [-33.8507, 150.9260],
  [-33.8501, 150.9256],
  [-33.8474, 150.9262],
  [-33.8468, 150.9261],
  [-33.8462, 150.9255],
  [-33.8448, 150.9238],
  [-33.8443, 150.9236],
  [-33.8433, 150.9239],
  [-33.8424, 150.9237],
  [-33.8415, 150.9230],
  [-33.8411, 150.9220],
  [-33.8400, 150.9141],
  [-33.8395, 150.9134],
  [-33.8390, 150.9131],
  [-33.8369, 150.9127],
  [-33.8358, 150.9128],
  [-33.8351, 150.9129],
  [-33.8346, 150.9128],
  [-33.8338, 150.9124],
  [-33.8331, 150.9123],
  [-33.8324, 150.9118],
  [-33.8316, 150.9110]
];

export const widemereQuarry: Line = {
  name: 'Widemere Quarry',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1925-10-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1945-06-02',
          status: 'closed'
        }]
      }
    }
  ]
};
