import { Line } from "../../trackTypes";

const mungarJunctionBrooweena = [
  [-25.6036, 152.5938],
  [-25.6046, 152.5934],
  [-25.6052, 152.5926],
  [-25.6078, 152.5863],
  [-25.6078, 152.5852],
  [-25.6046, 152.5778],
  [-25.6045, 152.5767],
  [-25.6055, 152.5733],
  [-25.6054, 152.5721],
  [-25.6042, 152.5692],
  [-25.6040, 152.5677],
  [-25.6051, 152.5638],
  [-25.6066, 152.5612],
  [-25.6066, 152.5597],
  [-25.6058, 152.5572],
  [-25.6060, 152.5527],
  [-25.6064, 152.5518],
  [-25.6070, 152.5513],
  [-25.6085, 152.5508],
  [-25.6094, 152.5497],
  [-25.6092, 152.5482],
  [-25.6079, 152.5470],
  [-25.6076, 152.5462],
  [-25.6072, 152.5421],
  [-25.6078, 152.5394],
  [-25.6078, 152.5386],
  [-25.6069, 152.5337],
  [-25.6069, 152.5316],
  [-25.6079, 152.5274],
  [-25.6086, 152.5200],
  [-25.6092, 152.5162],
  [-25.6092, 152.5152],
  [-25.6087, 152.5142],
  [-25.6086, 152.5125],
  [-25.6072, 152.5105],
  [-25.6070, 152.5096],
  [-25.6075, 152.5080],
  [-25.6074, 152.5071],
  [-25.6040, 152.5024],
  [-25.6034, 152.5008],
  [-25.6020, 152.4925],
  [-25.6007, 152.4903],
  [-25.6004, 152.4892],
  [-25.6000, 152.4834],
  [-25.5990, 152.4809],
  [-25.5989, 152.4793],
  [-25.5997, 152.4763],
  [-25.5998, 152.4749],
  [-25.5993, 152.4725],
  [-25.5997, 152.4714],
  [-25.6036, 152.4694],
  [-25.6040, 152.4685],
  [-25.6037, 152.4677],
  [-25.6020, 152.4667],
  [-25.6003, 152.4651],
  [-25.6000, 152.4642],
  [-25.5998, 152.4628],
  [-25.5994, 152.4620],
  [-25.5983, 152.4613],
  [-25.5980, 152.4606],
  [-25.5981, 152.4595],
  [-25.6001, 152.4548],
  [-25.6003, 152.4532],
  [-25.5998, 152.4515],
  [-25.5998, 152.4502],
  [-25.6017, 152.4469],
  [-25.6018, 152.4461],
  [-25.6014, 152.4449],
  [-25.6015, 152.4442],
  [-25.6020, 152.4433],
  [-25.6020, 152.4424],
  [-25.6009, 152.4410],
  [-25.6007, 152.4402],
  [-25.6009, 152.4390],
  [-25.6001, 152.4367],
  [-25.6002, 152.4357],
  [-25.6007, 152.4344],
  [-25.6003, 152.4289],
  [-25.6008, 152.4276],
  [-25.6020, 152.4268],
  [-25.6036, 152.4246],
  [-25.6054, 152.4236],
  [-25.6064, 152.4221],
  [-25.6062, 152.4175],
  [-25.6069, 152.4164],
  [-25.6083, 152.4160],
  [-25.6090, 152.4151],
  [-25.6092, 152.4137],
  [-25.6095, 152.4129],
  [-25.6104, 152.4124],
  [-25.6120, 152.4126],
  [-25.6129, 152.4119],
  [-25.6133, 152.4104],
  [-25.6139, 152.4098],
  [-25.6150, 152.4096],
  [-25.6158, 152.4091],
  [-25.6165, 152.4074],
  [-25.6170, 152.3979],
  [-25.6168, 152.3969],
  [-25.6154, 152.3947],
  [-25.6141, 152.3913],
  [-25.6133, 152.3904],
  [-25.6110, 152.3890],
  [-25.6102, 152.3879],
  [-25.6073, 152.3777],
  [-25.6062, 152.3756],
  [-25.6041, 152.3735],
  [-25.6037, 152.3720],
  [-25.6031, 152.3713],
  [-25.6011, 152.3705],
  [-25.6005, 152.3697],
  [-25.5998, 152.3663],
  [-25.5999, 152.3651],
  [-25.6022, 152.3545],
  [-25.6041, 152.3518],
  [-25.6068, 152.3501],
  [-25.6079, 152.3487],
  [-25.6081, 152.3471],
  [-25.6055, 152.3381],
  [-25.6055, 152.3371],
  [-25.6059, 152.3362],
  [-25.6069, 152.3355],
  [-25.6073, 152.3347],
  [-25.6075, 152.3334],
  [-25.6082, 152.3324],
  [-25.6095, 152.3319],
  [-25.6101, 152.3311],
  [-25.6101, 152.3300],
  [-25.6095, 152.3289],
  [-25.6093, 152.3282],
  [-25.6096, 152.3273],
  [-25.6105, 152.3258],
  [-25.6109, 152.3244],
  [-25.6108, 152.3216],
  [-25.6103, 152.3203],
  [-25.6095, 152.3193],
  [-25.6062, 152.3185],
  [-25.6055, 152.3179],
  [-25.6049, 152.3166],
  [-25.6044, 152.3161],
  [-25.6030, 152.3154],
  [-25.6024, 152.3145],
  [-25.6015, 152.3100],
  [-25.6015, 152.3088],
  [-25.6013, 152.3081],
  [-25.6007, 152.3072],
  [-25.6004, 152.3063],
  [-25.6004, 152.3029],
  [-25.5998, 152.3017],
  [-25.5987, 152.3009],
  [-25.5984, 152.2998],
  [-25.5985, 152.2977],
  [-25.5983, 152.2931],
  [-25.5991, 152.2904],
  [-25.6026, 152.2859],
  [-25.6051, 152.2819],
  [-25.6054, 152.2810],
  [-25.6050, 152.2798],
  [-25.6038, 152.2788],
  [-25.6033, 152.2781],
  [-25.6021, 152.2738],
  [-25.6022, 152.2727],
  [-25.6029, 152.2708],
  [-25.6029, 152.2697],
  [-25.6022, 152.2685],
  [-25.6002, 152.2668],
  [-25.5999, 152.2660],
  [-25.6001, 152.2648],
  [-25.6011, 152.2629]
];

const brooweenaBoompa = [
  [-25.6011, 152.2629],
  [-25.6015, 152.2618],
  [-25.6018, 152.2607],
  [-25.6023, 152.2562],
  [-25.6027, 152.2549],
  [-25.6049, 152.2513],
  [-25.6052, 152.2501],
  [-25.6047, 152.2484],
  [-25.5997, 152.2415],
  [-25.5993, 152.2407],
  [-25.5983, 152.2350],
  [-25.5977, 152.2338],
  [-25.5976, 152.2328],
  [-25.5980, 152.2309],
  [-25.5978, 152.2301],
  [-25.5970, 152.2293],
  [-25.5951, 152.2287],
  [-25.5946, 152.2279],
  [-25.5946, 152.2268],
  [-25.5951, 152.2257],
  [-25.5953, 152.2248],
  [-25.5950, 152.2222],
  [-25.5951, 152.2214],
  [-25.5975, 152.2149],
  [-25.5976, 152.2141],
  [-25.5968, 152.2108],
  [-25.5964, 152.2101],
  [-25.5950, 152.2086],
  [-25.5948, 152.2077],
  [-25.5951, 152.2067],
  [-25.5973, 152.2035],
  [-25.5976, 152.2027],
  [-25.5982, 152.1981],
  [-25.5980, 152.1970],
  [-25.5972, 152.1965],
  [-25.5955, 152.1961],
  [-25.5945, 152.1949],
  [-25.5941, 152.1921],
  [-25.5929, 152.1904],
  [-25.5908, 152.1892],
  [-25.5882, 152.1862]
];

const boompaBiggenden = [
  [-25.5882, 152.1862],
  [-25.5877, 152.1851],
  [-25.5877, 152.1842],
  [-25.5883, 152.1813],
  [-25.5880, 152.1799],
  [-25.5868, 152.1783],
  [-25.5864, 152.1773],
  [-25.5867, 152.1758],
  [-25.5863, 152.1745],
  [-25.5854, 152.1738],
  [-25.5841, 152.1736],
  [-25.5833, 152.1733],
  [-25.5828, 152.1724],
  [-25.5818, 152.1689],
  [-25.5815, 152.1651],
  [-25.5817, 152.1641],
  [-25.5824, 152.1628],
  [-25.5834, 152.1596],
  [-25.5832, 152.1584],
  [-25.5825, 152.1576],
  [-25.5814, 152.1570],
  [-25.5802, 152.1560],
  [-25.5756, 152.1516],
  [-25.5741, 152.1488],
  [-25.5738, 152.1431],
  [-25.5746, 152.1401],
  [-25.5765, 152.1366],
  [-25.5770, 152.1344],
  [-25.5763, 152.1320],
  [-25.5765, 152.1310],
  [-25.5770, 152.1298],
  [-25.5771, 152.1289],
  [-25.5764, 152.1276],
  [-25.5762, 152.1261],
  [-25.5764, 152.1232],
  [-25.5764, 152.1213],
  [-25.5759, 152.1181],
  [-25.5752, 152.1170],
  [-25.5742, 152.1166],
  [-25.5736, 152.1162],
  [-25.5717, 152.1131],
  [-25.5714, 152.1123],
  [-25.5714, 152.1110],
  [-25.5708, 152.1097],
  [-25.5694, 152.1089],
  [-25.5687, 152.1079],
  [-25.5685, 152.1069],
  [-25.5690, 152.1052],
  [-25.5695, 152.1045],
  [-25.5714, 152.1028],
  [-25.5719, 152.1020],
  [-25.5725, 152.0996],
  [-25.5724, 152.0990],
  [-25.5722, 152.0982],
  [-25.5721, 152.0973],
  [-25.5724, 152.0965],
  [-25.5723, 152.0957],
  [-25.5714, 152.0928],
  [-25.5692, 152.0879],
  [-25.5690, 152.0869],
  [-25.5695, 152.0849],
  [-25.5693, 152.0836],
  [-25.5684, 152.0825],
  [-25.5623, 152.0804],
  [-25.5599, 152.0791],
  [-25.5532, 152.0744],
  [-25.5514, 152.0737],
  [-25.5428, 152.0715],
  [-25.5414, 152.0706],
  [-25.5383, 152.0670],
  [-25.5371, 152.0661],
  [-25.5340, 152.0645],
  [-25.5328, 152.0633],
  [-25.5310, 152.0599],
  [-25.5297, 152.0590],
  [-25.5264, 152.0586],
  [-25.5251, 152.0579],
  [-25.5108, 152.0427]
];

const biggendenDegilbo = [
  [-25.5108, 152.0427],
  [-25.5041, 152.0357],
  [-25.5026, 152.0332],
  [-25.4989, 152.0220],
  [-25.4985, 152.0214],
  [-25.4979, 152.0206],
  [-25.4976, 152.0194],
  [-25.4983, 152.0167],
  [-25.4979, 152.0146],
  [-25.4967, 152.0134],
  [-25.4945, 152.0127],
  [-25.4934, 152.0116],
  [-25.4916, 152.0078],
  [-25.4899, 152.0055],
  [-25.4860, 152.0011]
];

const degilboWetheron = [
  [-25.4860, 152.0011],
  [-25.4851, 151.9998],
  [-25.4842, 151.9976],
  [-25.4838, 151.9954],
  [-25.4839, 151.9936],
  [-25.4845, 151.9879],
  [-25.4840, 151.9854],
  [-25.4784, 151.9784],
  [-25.4779, 151.9770],
  [-25.4780, 151.9732],
  [-25.4768, 151.9697],
  [-25.4669, 151.9573],
  [-25.4651, 151.9529],
  [-25.4649, 151.9488],
  [-25.4658, 151.9437],
  [-25.4669, 151.9412],
  [-25.4670, 151.9402],
  [-25.4664, 151.9390],
  [-25.4630, 151.9353],
  [-25.4577, 151.9309],
  [-25.4570, 151.9299],
  [-25.4570, 151.9286],
  [-25.4574, 151.9276],
  [-25.4590, 151.9263],
  [-25.4599, 151.9250],
  [-25.4600, 151.9231],
  [-25.4604, 151.9220],
  [-25.4626, 151.9202],
  [-25.4633, 151.9189],
  [-25.4645, 151.9177],
  [-25.4675, 151.9164],
  [-25.4686, 151.9152],
  [-25.4690, 151.9139],
  [-25.4681, 151.9091],
  [-25.4672, 151.9074],
  [-25.4653, 151.9003],
  [-25.4654, 151.8987],
  [-25.4691, 151.8909],
  [-25.4693, 151.8894],
  [-25.4681, 151.8842],
  [-25.4674, 151.8823],
  [-25.4673, 151.8811],
  [-25.4674, 151.8801],
  [-25.4701, 151.8725],
  [-25.4724, 151.8694],
  [-25.4758, 151.8672],
  [-25.4784, 151.8647],
  [-25.4790, 151.8634],
  [-25.4793, 151.8623],
  [-25.4799, 151.8614],
  [-25.4822, 151.8596],
  [-25.4830, 151.8584],
  [-25.4832, 151.8556],
  [-25.4836, 151.8543],
  [-25.4844, 151.8529],
  [-25.4853, 151.8495],
  [-25.4853, 151.8480],
  [-25.4847, 151.8420],
  [-25.4835, 151.8397],
  [-25.4833, 151.8386],
  [-25.4847, 151.8356],
  [-25.4866, 151.8338],
  [-25.4909, 151.8312],
  [-25.4962, 151.8287],
  [-25.4987, 151.8255],
  [-25.4997, 151.8247],
  [-25.5028, 151.8231],
  [-25.5034, 151.8223],
  [-25.5037, 151.8213],
  [-25.5046, 151.8202],
  [-25.5062, 151.8197],
  [-25.5072, 151.8184],
  [-25.5080, 151.8179],
  [-25.5097, 151.8172],
  [-25.5105, 151.8164],
  [-25.5108, 151.8151],
  [-25.5107, 151.8138],
  [-25.5109, 151.8124],
  [-25.5115, 151.8115],
  [-25.5124, 151.8106],
  [-25.5134, 151.8093],
  [-25.5142, 151.8073],
  [-25.5146, 151.8069],
  [-25.5154, 151.8062],
  [-25.5159, 151.8050],
  [-25.5158, 151.8031],
  [-25.5160, 151.7994],
  [-25.5167, 151.7968],
  [-25.5182, 151.7943],
  [-25.5188, 151.7919],
  [-25.5196, 151.7906],
  [-25.5199, 151.7895],
  [-25.5197, 151.7881],
  [-25.5185, 151.7861],
  [-25.5181, 151.7844],
  [-25.5185, 151.7826],
  [-25.5226, 151.7751],
  [-25.5241, 151.7703],
  [-25.5243, 151.7663],
  [-25.5236, 151.7622],
  [-25.5241, 151.7585],
  [-25.5366, 151.7336],
  [-25.5428, 151.7229],
  [-25.5449, 151.7153]
];

const wetheronGayndah = [
  [-25.5449, 151.7153],
  [-25.5467, 151.7078],
  [-25.5473, 151.6977],
  [-25.5468, 151.6952],
  [-25.5437, 151.6898],
  [-25.5423, 151.6880],
  [-25.5401, 151.6860],
  [-25.5390, 151.6841],
  [-25.5386, 151.6821],
  [-25.5386, 151.6798],
  [-25.5374, 151.6738],
  [-25.5373, 151.6724],
  [-25.5383, 151.6603],
  [-25.5380, 151.6589],
  [-25.5364, 151.6553],
  [-25.5365, 151.6541],
  [-25.5370, 151.6532],
  [-25.5462, 151.6493],
  [-25.5474, 151.6481],
  [-25.5536, 151.6270],
  [-25.5549, 151.6251],
  [-25.5566, 151.6239],
  [-25.5650, 151.6217],
  [-25.5663, 151.6211],
  [-25.5686, 151.6196],
  [-25.5704, 151.6188],
  [-25.5805, 151.6176],
  [-25.5820, 151.6169],
  [-25.5857, 151.6127],
  [-25.5871, 151.6122],
  [-25.5885, 151.6123],
  [-25.5893, 151.6122],
  [-25.5906, 151.6118],
  [-25.5937, 151.6118],
  [-25.5947, 151.6122],
  [-25.5977, 151.6156],
  [-25.5988, 151.6164],
  [-25.6000, 151.6166],
  [-25.6013, 151.6166],
  [-25.6023, 151.6169],
  [-25.6032, 151.6175],
  [-25.6044, 151.6178],
  [-25.6059, 151.6178],
  [-25.6071, 151.6178],
  [-25.6084, 151.6174],
  [-25.6138, 151.6134],
  [-25.6145, 151.6125],
  [-25.6163, 151.6095]
];

const gayndahBoomerang = [
  [-25.6163, 151.6095],
  [-25.6165, 151.6088],
  [-25.6158, 151.6035],
  [-25.6160, 151.5981],
  [-25.6159, 151.5974],
  [-25.6144, 151.5948],
  [-25.6134, 151.5941],
  [-25.6115, 151.5936],
  [-25.6107, 151.5932],
  [-25.6096, 151.5920],
  [-25.6088, 151.5908],
  [-25.6034, 151.5778],
  [-25.6032, 151.5759],
  [-25.6036, 151.5745],
  [-25.6054, 151.5721],
  [-25.6056, 151.5712],
  [-25.6056, 151.5703],
  [-25.6044, 151.5669],
  [-25.6041, 151.5644],
  [-25.6051, 151.5607],
  [-25.6076, 151.5568],
  [-25.6084, 151.5562],
  [-25.6097, 151.5554],
  [-25.6101, 151.5548],
  [-25.6109, 151.5519],
  [-25.6119, 151.5497],
  [-25.6120, 151.5489],
  [-25.6120, 151.5478],
  [-25.6121, 151.5472],
  [-25.6131, 151.5439],
  [-25.6138, 151.5430],
  [-25.6147, 151.5423],
  [-25.6198, 151.5409],
  [-25.6210, 151.5404],
  [-25.6238, 151.5386],
  [-25.6265, 151.5366],
  [-25.6273, 151.5352],
  [-25.6275, 151.5337],
  [-25.6269, 151.5323],
  [-25.6255, 151.5310],
  [-25.6236, 151.5299],
  [-25.6218, 151.5284],
  [-25.6209, 151.5280],
  [-25.6205, 151.5275],
  [-25.6121, 151.5078],
  [-25.6118, 151.5064],
  [-25.6120, 151.5054],
  [-25.6124, 151.5042],
  [-25.6125, 151.5029],
  [-25.6118, 151.5017],
  [-25.6094, 151.5001],
  [-25.6073, 151.4984],
  [-25.6065, 151.4974],
  [-25.6044, 151.4958],
  [-25.6034, 151.4952],
  [-25.6030, 151.4947],
  [-25.6027, 151.4935],
  [-25.6017, 151.4921],
  [-25.6014, 151.4913],
  [-25.6004, 151.4864],
  [-25.5992, 151.4830],
  [-25.5992, 151.4818],
  [-25.5995, 151.4808],
  [-25.6067, 151.4722],
  [-25.6114, 151.4650],
  [-25.6138, 151.4605],
  [-25.6144, 151.4587],
  [-25.6149, 151.4543],
  [-25.6150, 151.4500],
  [-25.6153, 151.4485],
  [-25.6158, 151.4446],
  [-25.6194, 151.4328]
];

const boomerangMundubbera = [
  [-25.6194, 151.4328],
  [-25.6197, 151.4305],
  [-25.6193, 151.4272],
  [-25.6179, 151.4246],
  [-25.6159, 151.4230],
  [-25.6098, 151.4207],
  [-25.6080, 151.4197],
  [-25.6067, 151.4193],
  [-25.6053, 151.4182],
  [-25.6043, 151.4161],
  [-25.6032, 151.4150],
  [-25.6022, 151.4141],
  [-25.6011, 151.4123],
  [-25.6009, 151.4105],
  [-25.6006, 151.4093],
  [-25.5955, 151.3998],
  [-25.5954, 151.3986],
  [-25.5969, 151.3950],
  [-25.5971, 151.3933],
  [-25.5967, 151.3911],
  [-25.5969, 151.3895],
  [-25.5986, 151.3865],
  [-25.5989, 151.3849],
  [-25.5987, 151.3804],
  [-25.5985, 151.3788],
  [-25.5974, 151.3752],
  [-25.5966, 151.3745],
  [-25.5951, 151.3741],
  [-25.5942, 151.3737],
  [-25.5927, 151.3726],
  [-25.5920, 151.3715],
  [-25.5917, 151.3706],
  [-25.5931, 151.3456],
  [-25.5926, 151.3422],
  [-25.5903, 151.3353],
  [-25.5902, 151.3324],
  [-25.5907, 151.3296],
  [-25.5909, 151.3140],
  [-25.5913, 151.3127],
  [-25.5923, 151.3108],
  [-25.5925, 151.3090],
  [-25.5919, 151.3071],
  [-25.5919, 151.3055],
  [-25.5924, 151.3044],
  [-25.5937, 151.3031],
  [-25.5941, 151.3022],
  [-25.5940, 151.3008],
  [-25.5925, 151.2968]
];

const mundubberaCeratodus = [
  [-25.5925, 151.2968],
  [-25.5906, 151.2900],
  [-25.5907, 151.2880],
  [-25.5931, 151.2827],
  [-25.5944, 151.2810],
  [-25.6047, 151.2735],
  [-25.6057, 151.2721],
  [-25.6060, 151.2706],
  [-25.6033, 151.2454],
  [-25.6026, 151.2437],
  [-25.6010, 151.2418],
  [-25.6002, 151.2404],
  [-25.5997, 151.2385],
  [-25.5975, 151.2344],
  [-25.5929, 151.2284],
  [-25.5918, 151.2265],
  [-25.5911, 151.2237],
  [-25.5913, 151.2223],
  [-25.5922, 151.2214],
  [-25.5937, 151.2205],
  [-25.5951, 151.2186],
  [-25.5953, 151.2169],
  [-25.5945, 151.2153],
  [-25.5938, 151.2145],
  [-25.5893, 151.2124],
  [-25.5881, 151.2115],
  [-25.5869, 151.2096],
  [-25.5858, 151.2087],
  [-25.5838, 151.2078],
  [-25.5732, 151.1984],
  [-25.5708, 151.1972],
  [-25.5570, 151.1954],
  [-25.5556, 151.1954],
  [-25.5508, 151.1969],
  [-25.5490, 151.1988],
  [-25.5458, 151.2051],
  [-25.5409, 151.2137],
  [-25.5389, 151.2153],
  [-25.5269, 151.2191],
  [-25.5072, 151.2212],
  [-25.5035, 151.2207],
  [-25.5005, 151.2188],
  [-25.4937, 151.2133],
  [-25.4886, 151.2110],
  [-25.4876, 151.2103],
  [-25.4834, 151.2064],
  [-25.4779, 151.2028],
  [-25.4656, 151.1973],
  [-25.4633, 151.1955],
  [-25.4606, 151.1924],
  [-25.4588, 151.1915],
  [-25.4534, 151.1905],
  [-25.4499, 151.1890],
  [-25.4463, 151.1865],
  [-25.4403, 151.1804],
  [-25.4381, 151.1788],
  [-25.4270, 151.1741],
  [-25.4245, 151.1724],
  [-25.4190, 151.1670],
  [-25.4168, 151.1632],
  [-25.4152, 151.1616],
  [-25.4084, 151.1591],
  [-25.4075, 151.1590],
  [-25.4050, 151.1594],
  [-25.4029, 151.1587],
  [-25.4014, 151.1572],
  [-25.3968, 151.1541],
  [-25.3959, 151.1525],
  [-25.3960, 151.1509],
  [-25.3958, 151.1498],
  [-25.3936, 151.1462],
  [-25.3927, 151.1434],
  [-25.3925, 151.1390],
  [-25.3912, 151.1346],
  [-25.3905, 151.1337],
  [-25.3894, 151.1332],
  [-25.3863, 151.1336],
  [-25.3837, 151.1332],
  [-25.3681, 151.1261],
  [-25.3641, 151.1235],
  [-25.3626, 151.1231],
  [-25.3609, 151.1235],
  [-25.3599, 151.1233],
  [-25.3587, 151.1228],
  [-25.3580, 151.1222],
  [-25.3572, 151.1211],
  [-25.3564, 151.1206],
  [-25.3543, 151.1198],
  [-25.3532, 151.1197],
  [-25.3475, 151.1217],
  [-25.3454, 151.1218],
  [-25.3438, 151.1223],
  [-25.3408, 151.1244],
  [-25.3354, 151.1266],
  [-25.3343, 151.1267],
  [-25.3327, 151.1262],
  [-25.3314, 151.1265],
  [-25.3303, 151.1272],
  [-25.3288, 151.1296],
  [-25.3265, 151.1313],
  [-25.3218, 151.1331],
  [-25.3204, 151.1334],
  [-25.3195, 151.1338],
  [-25.3177, 151.1355],
  [-25.3156, 151.1386],
  [-25.3122, 151.1420],
  [-25.3107, 151.1426],
  [-25.3093, 151.1426],
  [-25.3064, 151.1418],
  [-25.3049, 151.1419],
  [-25.3029, 151.1428],
  [-25.3003, 151.1432],
  [-25.2886, 151.1419]
];

const ceratodusMulgildie = [
  [-25.2886, 151.1419],
  [-25.2525, 151.1329],
  [-25.2485, 151.1335],
  [-25.2375, 151.1411],
  [-25.2335, 151.1418],
  [-25.2319, 151.1414],
  [-25.2050, 151.1240],
  [-25.2030, 151.1234],
  [-25.2005, 151.1233],
  [-25.1984, 151.1225],
  [-25.1959, 151.1206],
  [-25.1942, 151.1201],
  [-25.1929, 151.1200],
  [-25.1920, 151.1196],
  [-25.1912, 151.1190],
  [-25.1901, 151.1190],
  [-25.1894, 151.1196],
  [-25.1884, 151.1216],
  [-25.1865, 151.1225],
  [-25.1847, 151.1218],
  [-25.1828, 151.1196],
  [-25.1800, 151.1187],
  [-25.1762, 151.1205],
  [-25.1606, 151.1211],
  [-25.1519, 151.1231],
  [-25.1476, 151.1230],
  [-25.1454, 151.1236],
  [-25.1393, 151.1274],
  [-25.1359, 151.1285],
  [-25.1290, 151.1338],
  [-25.1275, 151.1343],
  [-25.1207, 151.1349],
  [-25.1185, 151.1359],
  [-25.1152, 151.1365],
  [-25.1135, 151.1360],
  [-25.1108, 151.1336],
  [-25.1095, 151.1333],
  [-25.1073, 151.1342],
  [-25.1061, 151.1340],
  [-25.1050, 151.1329],
  [-25.1040, 151.1325],
  [-25.1020, 151.1325],
  [-25.0972, 151.1338],
  [-25.0955, 151.1333],
  [-25.0942, 151.1321],
  [-25.0931, 151.1319],
  [-25.0917, 151.1323],
  [-25.0906, 151.1320],
  [-25.0890, 151.1311],
  [-25.0880, 151.1309],
  [-25.0866, 151.1309],
  [-25.0851, 151.1298],
  [-25.0841, 151.1263],
  [-25.0829, 151.1253],
  [-25.0772, 151.1241],
  [-25.0735, 151.1241],
  [-25.0725, 151.1243],
  [-25.0704, 151.1252],
  [-25.0670, 151.1257],
  [-25.0647, 151.1272],
  [-25.0590, 151.1292],
  [-25.0574, 151.1303],
  [-25.0549, 151.1328],
  [-25.0526, 151.1338],
  [-25.0436, 151.1339],
  [-25.0416, 151.1345],
  [-25.0403, 151.1359],
  [-25.0384, 151.1396],
  [-25.0374, 151.1408],
  [-25.0355, 151.1423],
  [-25.0341, 151.1448],
  [-25.0334, 151.1466],
  [-25.0311, 151.1498],
  [-25.0282, 151.1516],
  [-25.0267, 151.1520],
  [-25.0217, 151.1522],
  [-25.0208, 151.1524],
  [-25.0172, 151.1545],
  [-25.0161, 151.1546],
  [-25.0152, 151.1540],
  [-25.0148, 151.1531],
  [-25.0143, 151.1480],
  [-25.0132, 151.1462],
  [-25.0115, 151.1457],
  [-25.0073, 151.1465],
  [-25.0062, 151.1474],
  [-25.0047, 151.1494],
  [-25.0037, 151.1498],
  [-25.0028, 151.1495],
  [-25.0013, 151.1482],
  [-24.9998, 151.1479],
  [-24.9984, 151.1485],
  [-24.9974, 151.1496],
  [-24.9965, 151.1498],
  [-24.9956, 151.1492],
  [-24.9949, 151.1484],
  [-24.9936, 151.1479],
  [-24.9899, 151.1497],
  [-24.9889, 151.1496],
  [-24.9882, 151.1489],
  [-24.9877, 151.1476],
  [-24.9862, 151.1467],
  [-24.9853, 151.1469],
  [-24.9847, 151.1474],
  [-24.9839, 151.1486],
  [-24.9830, 151.1489],
  [-24.9820, 151.1485],
  [-24.9816, 151.1476],
  [-24.9816, 151.1430],
  [-24.9811, 151.1419],
  [-24.9801, 151.1416],
  [-24.9782, 151.1421],
  [-24.9763, 151.1420],
  [-24.9730, 151.1408],
  [-24.9721, 151.1401],
  [-24.9715, 151.1392],
  [-24.9711, 151.1374],
  [-24.9702, 151.1357],
  [-24.9666, 151.1312],
  [-24.9655, 151.1307],
  [-24.9646, 151.1309],
  [-24.9615, 151.1337]
];

const mulgildieMonto = [
  [-24.9615, 151.1337],
  [-24.9599, 151.1343],
  [-24.9555, 151.1342],
  [-24.9530, 151.1334],
  [-24.9450, 151.1261],
  [-24.9437, 151.1256],
  [-24.9424, 151.1258],
  [-24.9413, 151.1267],
  [-24.9390, 151.1306],
  [-24.9375, 151.1319],
  [-24.9328, 151.1340],
  [-24.9314, 151.1341],
  [-24.9304, 151.1337],
  [-24.9270, 151.1317],
  [-24.9254, 151.1313],
  [-24.9185, 151.1317],
  [-24.9174, 151.1314],
  [-24.9159, 151.1303],
  [-24.9129, 151.1263],
  [-24.9110, 151.1252],
  [-24.9085, 151.1251],
  [-24.9053, 151.1268],
  [-24.9041, 151.1277],
  [-24.9030, 151.1291],
  [-24.9018, 151.1299],
  [-24.8977, 151.1305],
  [-24.8954, 151.1296],
  [-24.8934, 151.1274],
  [-24.8912, 151.1267],
  [-24.8884, 151.1267],
  [-24.8861, 151.1261],
  [-24.8785, 151.1218],
  [-24.8775, 151.1215],
  [-24.8768, 151.1216],
  [-24.8723, 151.1230],
  [-24.8714, 151.1228],
  [-24.8705, 151.1220],
  [-24.8699, 151.1203],
  [-24.8694, 151.1196],
  [-24.8684, 151.1193],
  [-24.8652, 151.1195],
  [-24.8640, 151.1199],
  [-24.8619, 151.1216]
];

export const mungarJunctionMonto: Line = {
  name: 'Mungar Junction  - Monto',
  state: 'QLD',
  segments: [
    {
      segments: [mungarJunctionBrooweena],
      history: {
        opened: {
          date: '1889-07-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brooweenaBoompa],
      history: {
        opened: {
          date: '1891-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boompaBiggenden],
      history: {
        opened: {
          date: '1891-05-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [biggendenDegilbo],
      history: {
        opened: {
          date: '1893-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [degilboWetheron],
      history: {
        opened: {
          date: '1905-12-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wetheronGayndah],
      history: {
        opened: {
          date: '1907-12-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gayndahBoomerang],
      history: {
        opened: {
          date: '1913-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boomerangMundubbera],
      history: {
        opened: {
          date: '1914-02-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mundubberaCeratodus],
      history: {
        opened: {
          date: '1924-04-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ceratodusMulgildie],
      history: {
        opened: {
          date: '1927-06-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mulgildieMonto],
      history: {
        opened: {
          date: '1928-09-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
