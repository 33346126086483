import { Line } from "../../trackTypes";

const maryboroughDunolly = [
  [-37.0511, 143.7426],
  [-37.0479, 143.7452],
  [-37.0468, 143.7457],
  [-37.0461, 143.7459],
  [-37.0454, 143.7459],
  [-37.0448, 143.7459],
  [-37.0376, 143.7454],
  [-37.0360, 143.7456],
  [-37.0350, 143.7460],
  [-36.9828, 143.7695],
  [-36.9813, 143.7699],
  [-36.9647, 143.7725],
  [-36.9633, 143.7725],
  [-36.9626, 143.7723],
  [-36.9394, 143.7660],
  [-36.9382, 143.7654],
  [-36.9097, 143.7466],
  [-36.9087, 143.7458],
  [-36.9041, 143.7412],
  [-36.9035, 143.7408],
  [-36.9024, 143.7402],
  [-36.8987, 143.7382],
  [-36.8979, 143.7379],
  [-36.8967, 143.7377],
  [-36.8873, 143.7377],
  [-36.8862, 143.7377],
  [-36.8784, 143.7364],
  [-36.8777, 143.7362],
  [-36.8769, 143.7359],
  [-36.8670, 143.7312]
];

const dunollyRobinvaleJunction = [
  [-36.8670, 143.7312],
  [-36.8627, 143.7291],
  [-36.8617, 143.7286],
  [-36.8608, 143.7279],
  [-36.8592, 143.7261]
];

const robinvaleJunctionBealiba = [
  [-36.8592, 143.7261],
  [-36.8309, 143.6923],
  [-36.8302, 143.6914],
  [-36.8294, 143.6901],
  [-36.8126, 143.6613],
  [-36.8112, 143.6595],
  [-36.8103, 143.6584],
  [-36.8089, 143.6572],
  [-36.8031, 143.6532],
  [-36.8017, 143.6519],
  [-36.8004, 143.6505],
  [-36.7991, 143.6485],
  [-36.7983, 143.6470],
  [-36.7870, 143.6230],
  [-36.7860, 143.6208],
  [-36.7829, 143.6122],
  [-36.7823, 143.6109],
  [-36.7811, 143.6086],
  [-36.7806, 143.6070],
  [-36.7804, 143.6052],
  [-36.7806, 143.6035],
  [-36.7829, 143.5962],
  [-36.7832, 143.5946],
  [-36.7832, 143.5935],
  [-36.7832, 143.5912],
  [-36.7825, 143.5829],
  [-36.7826, 143.5810],
  [-36.7828, 143.5796],
  [-36.7900, 143.5535]
];

const bealibaStAnaud = [
  [-36.7900, 143.5535],
  [-36.7910, 143.5493],
  [-36.7913, 143.5467],
  [-36.7912, 143.5439],
  [-36.7886, 143.5287],
  [-36.7876, 143.5253],
  [-36.7865, 143.5229],
  [-36.7693, 143.4946],
  [-36.7665, 143.4914],
  [-36.7581, 143.4842],
  [-36.7562, 143.4822],
  [-36.7553, 143.4810],
  [-36.7388, 143.4568],
  [-36.7378, 143.4549],
  [-36.7368, 143.4518],
  [-36.7323, 143.4282],
  [-36.7310, 143.4245],
  [-36.7283, 143.4204],
  [-36.7148, 143.4077],
  [-36.7122, 143.4048],
  [-36.7023, 143.3936],
  [-36.7012, 143.3920],
  [-36.7003, 143.3902],
  [-36.6942, 143.3744],
  [-36.6925, 143.3715],
  [-36.6905, 143.3694],
  [-36.6762, 143.3591],
  [-36.6739, 143.3569],
  [-36.6723, 143.3543],
  [-36.6699, 143.3495],
  [-36.6668, 143.3410],
  [-36.6655, 143.3384],
  [-36.6639, 143.3363],
  [-36.6554, 143.3290],
  [-36.6540, 143.3274],
  [-36.6526, 143.3249],
  [-36.6500, 143.3186],
  [-36.6463, 143.3130],
  [-36.6379, 143.3003],
  [-36.6307, 143.2873],
  [-36.6295, 143.2838],
  [-36.6268, 143.2694],
  [-36.6260, 143.2667],
  [-36.6253, 143.2653],
  [-36.6214, 143.2585],
];

const stAnaudCopeCope = [
  [-36.6214, 143.2585],
  [-36.6189, 143.2545],
  [-36.6176, 143.2530],
  [-36.6143, 143.2503],
  [-36.6127, 143.2494],
  [-36.6111, 143.2491],
  [-36.6097, 143.2491],
  [-36.6078, 143.2496],
  [-36.6063, 143.2495],
  [-36.6048, 143.2491],
  [-36.6034, 143.2483],
  [-36.5986, 143.2442],
  [-36.5976, 143.2432],
  [-36.5958, 143.2413],
  [-36.5934, 143.2399],
  [-36.5904, 143.2385],
  [-36.5887, 143.2382],
  [-36.5868, 143.2373],
  [-36.5834, 143.2346],
  [-36.5784, 143.2316],
  [-36.5714, 143.2272],
  [-36.5701, 143.2262],
  [-36.5509, 143.2069],
  [-36.5481, 143.2033],
  [-36.4782, 143.0689],
  [-36.4762, 143.0657],
  [-36.4687, 143.0578],
  [-36.4676, 143.0570],
  [-36.4623, 143.0551]
];

const hattahNowingi = [
  [-34.7633, 142.2785],
  [-34.7461, 142.2730],
  [-34.7450, 142.2725],
  [-34.7385, 142.2691],
  [-34.7371, 142.2685],
  [-34.7334, 142.2674],
  [-34.7319, 142.2672],
  [-34.7093, 142.2642],
  [-34.7079, 142.2639],
  [-34.6887, 142.2581],
  [-34.6868, 142.2573],
  [-34.6447, 142.2388],
  [-34.6232, 142.2279],
  [-34.6214, 142.2274],
  [-34.6087, 142.2253],
  [-34.6032, 142.2248],
  [-34.5982, 142.2240]
];

const nowingiYatpool = [
  [-34.5982, 142.2240],
  [-34.5969, 142.2238],
  [-34.5960, 142.2236],
  [-34.5880, 142.2212],
  [-34.5864, 142.2210],
  [-34.5851, 142.2210],
  [-34.5774, 142.2221],
  [-34.5757, 142.2221],
  [-34.5732, 142.2216],
  [-34.5489, 142.2121],
  [-34.5440, 142.2108],
  [-34.5197, 142.2067],
  [-34.5177, 142.2067],
  [-34.5052, 142.2081],
  [-34.5032, 142.2080],
  [-34.4671, 142.2020],
  [-34.4172, 142.1865],
  [-34.4158, 142.1862],
  [-34.4147, 142.1861],
  [-34.3668, 142.1860]
];

const yatpoolMildura = [
  [-34.3668, 142.1860],
  [-34.3346, 142.1860],
  [-34.2784, 142.1859],
  [-34.2768, 142.1857],
  [-34.2747, 142.1852],
  [-34.2729, 142.1846],
  [-34.2374, 142.1707],
  [-34.2365, 142.1705],
  [-34.2353, 142.1705],
  [-34.2034, 142.1721],
  [-34.2022, 142.1720],
  [-34.2007, 142.1717],
  [-34.1999, 142.1714],
  [-34.1955, 142.1699],
  [-34.1891, 142.1676],
  [-34.1879, 142.1674],
  [-34.1864, 142.1670],
  [-34.1855, 142.1667],
  [-34.1847, 142.1662],
  [-34.1837, 142.1653],
  [-34.1831, 142.1646],
  [-34.1813, 142.1624]
];

const milduraMerbein = [
  [-34.1813, 142.1624],
  [-34.1798, 142.1607],
  [-34.1789, 142.1596],
  [-34.1783, 142.1586],
  [-34.1780, 142.1577],
  [-34.1780, 142.1565],
  [-34.1781, 142.1553],
  [-34.1784, 142.1545],
  [-34.1789, 142.1537],
  [-34.1888, 142.1417],
  [-34.1897, 142.1400],
  [-34.1901, 142.1382],
  [-34.1904, 142.1303],
  [-34.1907, 142.1280],
  [-34.1912, 142.1262],
  [-34.1924, 142.1230],
  [-34.1932, 142.1205],
  [-34.1938, 142.1176],
  [-34.1944, 142.1113],
  [-34.1943, 142.1094],
  [-34.1941, 142.1083],
  [-34.1937, 142.1068],
  [-34.1926, 142.1041],
  [-34.1920, 142.1025],
  [-34.1868, 142.0839],
  [-34.1862, 142.0825],
  [-34.1855, 142.0814],
  [-34.1847, 142.0805],
  [-34.1725, 142.0711],
  [-34.1714, 142.0700],
  [-34.1707, 142.0686],
  [-34.1703, 142.0674],
  [-34.1701, 142.0658],
  [-34.1701, 142.0619]
];

const merbeinYelta = [
  [-34.1701, 142.0619],
  [-34.1701, 142.0265],
  [-34.1700, 142.0250],
  [-34.1698, 142.0239],
  [-34.1676, 142.0163],
  [-34.1672, 142.0153],
  [-34.1665, 142.0141],
  [-34.1655, 142.0128],
  [-34.1648, 142.0122],
  [-34.1450, 141.9982],
  [-34.1440, 141.9976],
  [-34.1429, 141.9973],
  [-34.1419, 141.9971],
  [-34.1375, 141.9970],
  [-34.1359, 141.9969],
  [-34.1325, 141.9962],
  [-34.1314, 141.9959],
  [-34.1303, 141.9953],
  [-34.1294, 141.9946],
  [-34.1266, 141.9916],
  [-34.1259, 141.9910],
  [-34.1248, 141.9899]
];

const copeCopeDonald = [
  [-36.4623, 143.0551],
  [-36.4533, 143.0521],
  [-36.4525, 143.0517],
  [-36.4514, 143.0510],
  [-36.3806, 142.9940],
  [-36.3794, 142.9934],
  [-36.3783, 142.9932],
  [-36.3747, 142.9930],
  [-36.3736, 142.9926],
  [-36.3722, 142.9917],
  [-36.3675, 142.9868]
];

const donaldBirchip = [
  [-36.3675, 142.9868],
  [-36.3640, 142.9832],
  [-36.3624, 142.9810],
  [-36.3561, 142.9680],
  [-36.3552, 142.9667],
  [-36.3431, 142.9551],
  [-36.3252, 142.9377],
  [-36.3240, 142.9361],
  [-36.3227, 142.9333],
  [-36.3222, 142.9319],
  [-36.3215, 142.9273],
  [-36.3207, 142.9249],
  [-36.3184, 142.9200],
  [-36.3138, 142.9046],
  [-36.3084, 142.8782],
  [-36.2975, 142.8472],
  [-36.2963, 142.8460],
  [-36.2947, 142.8454],
  [-36.2896, 142.8462],
  [-36.2884, 142.8466],
  [-36.2848, 142.8489],
  [-36.2837, 142.8494],
  [-36.2157, 142.8607],
  [-36.2141, 142.8607],
  [-36.2130, 142.8605],
  [-36.2078, 142.8585],
  [-36.2062, 142.8583],
  [-36.1646, 142.8655],
  [-36.1633, 142.8654],
  [-36.1618, 142.8648],
  [-36.1556, 142.8615],
  [-36.1541, 142.8613],
  [-36.1251, 142.8663],
  [-36.1211, 142.8683],
  [-36.0992, 142.8879],
  [-36.0976, 142.8885],
  [-36.0964, 142.8884],
  [-36.0916, 142.8864],
  [-36.0889, 142.8861],
  [-36.0792, 142.8878],
  [-36.0758, 142.8890],
  [-36.0652, 142.8909],
  [-36.0637, 142.8914],
  [-36.0491, 142.8989],
  [-36.0473, 142.8992],
  [-36.0374, 142.8992],
  [-36.0363, 142.8995],
  [-36.0164, 142.9067],
  [-36.0153, 142.9069],
  [-36.0089, 142.9071],
  [-35.9984, 142.9114],
  [-35.9928, 142.9142],
  [-35.9857, 142.9200],
  [-35.9846, 142.9204],
  [-35.9815, 142.9210]
];

const birchipWoomelang = [
  [-35.9815, 142.9210],
  [-35.9759, 142.9219],
  [-35.9705, 142.9218],
  [-35.9683, 142.9210],
  [-35.9665, 142.9191],
  [-35.9024, 142.7990],
  [-35.9006, 142.7971],
  [-35.8997, 142.7966],
  [-35.8280, 142.7823],
  [-35.8257, 142.7814],
  [-35.8244, 142.7806],
  [-35.7573, 142.7259],
  [-35.6842, 142.6647]
];

const woomelangHattah = [
  [-35.6842, 142.6647],
  [-35.6507, 142.6374],
  [-35.6477, 142.6338],
  [-35.6286, 142.6021],
  [-35.6102, 142.5792],
  [-35.6073, 142.5762],
  [-35.6055, 142.5749],
  [-35.5792, 142.5595],
  [-35.5716, 142.5544],
  [-35.5121, 142.5124],
  [-35.5100, 142.5101],
  [-35.4933, 142.4881],
  [-35.4915, 142.4863],
  [-35.4889, 142.4846],
  [-35.4647, 142.4734],
  [-35.4605, 142.4714],
  [-35.3917, 142.4321],
  [-35.3890, 142.4310],
  [-35.3861, 142.4303],
  [-35.3666, 142.4277],
  [-35.3424, 142.4234],
  [-35.3404, 142.4227],
  [-35.3319, 142.4185],
  [-35.3300, 142.4176],
  [-35.3126, 142.4141],
  [-35.2560, 142.4026],
  [-35.2456, 142.4011],
  [-35.2296, 142.3983],
  [-35.2243, 142.3963],
  [-35.2150, 142.3944],
  [-35.2122, 142.3934],
  [-35.2036, 142.3886],
  [-35.1870, 142.3788],
  [-35.1844, 142.3778],
  [-35.1825, 142.3776],
  [-35.1789, 142.3777],
  [-35.1758, 142.3771],
  [-35.1225, 142.3508],
  [-35.1197, 142.3489],
  [-35.1093, 142.3401],
  [-35.0942, 142.3286],
  [-35.0929, 142.3277],
  [-35.0762, 142.3190],
  [-35.0733, 142.3181],
  [-35.0635, 142.3176],
  [-35.0612, 142.3172],
  [-35.0470, 142.3137],
  [-35.0461, 142.3136],
  [-34.9551, 142.3117],
  [-34.9534, 142.3119],
  [-34.9504, 142.3126],
  [-34.9479, 142.3127],
  [-34.9380, 142.3114],
  [-34.9366, 142.3111],
  [-34.9235, 142.3066],
  [-34.9219, 142.3062],
  [-34.8996, 142.3021],
  [-34.8978, 142.3019],
  [-34.8921, 142.3017],
  [-34.8903, 142.3012],
  [-34.8887, 142.3004],
  [-34.8868, 142.3000],
  [-34.8833, 142.2999],
  [-34.8820, 142.2997],
  [-34.8739, 142.2982],
  [-34.8725, 142.2982],
  [-34.8619, 142.2986],
  [-34.8606, 142.2985],
  [-34.8347, 142.2934],
  [-34.8201, 142.2919],
  [-34.8187, 142.2916],
  [-34.8026, 142.2861],
  [-34.7975, 142.2851],
  [-34.7938, 142.2849],
  [-34.7886, 142.2842],
  [-34.7828, 142.2846],
  [-34.7810, 142.2842],
  [-34.7633, 142.2785]
];

export const mildura: Line = {
  name: 'Yelta',
  state: 'VIC',
  segments: [
    {
      segments: [maryboroughDunolly],
      history: {
        opened: {
          date: '1874-10-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-04-28',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [dunollyRobinvaleJunction],
      history: {
        opened: {
          date: '1878-09-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [robinvaleJunctionBealiba],
      history: {
        opened: {
          date: '1878-09-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [bealibaStAnaud],
      history: {
        opened: {
          date: '1878-12-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [hattahNowingi],
      history: {
        opened: {
          date: '1903-05-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [nowingiYatpool],
      history: {
        opened: {
          date: '1903-09-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [yatpoolMildura],
      history: {
        opened: {
          date: '1903-10-27',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [milduraMerbein],
      history: {
        opened: {
          date: '1910-07-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [merbeinYelta],
      history: {
        opened: {
          date: '1925-06-27',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [copeCopeDonald],
      history: {
        opened: {
          date: '1882-04-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [donaldBirchip],
      history: {
        opened: {
          date: '1893-03-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [birchipWoomelang],
      history: {
        opened: {
          date: '1899-09-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [stAnaudCopeCope],
      history: {
        opened: {
          date: '1882-01-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [woomelangHattah],
      history: {
        opened: {
          date: '1903-01-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-07',
          gauge: 'standard'
        }]
      }
    }
  ]
}
