import { Line } from "../../trackTypes";

const stratfordOld = [
  [-39.3430, 174.2869],
  [-39.3438, 174.2881],
  [-39.3441, 174.2889],
  [-39.3442, 174.2899],
  [-39.3433, 174.2939],
  [-39.3432, 174.2952],
  [-39.3435, 174.2992],
  [-39.3437, 174.3006],
  [-39.3438, 174.3114],
  [-39.3436, 174.3127],
  [-39.3428, 174.3150]
];

const stratfordOldSouthJunction = [
  [-39.3465, 174.2884],
  [-39.3454, 174.2886],
  [-39.3448, 174.2889],
  [-39.3442, 174.2899]
];

const stratfordDeviation = [
  [-39.3516, 174.2912],
  [-39.3522, 174.2924],
  [-39.3522, 174.2934],
  [-39.3506, 174.3002],
  [-39.3499, 174.3015],
  [-39.3492, 174.3024],
  [-39.3479, 174.3032],
  [-39.3469, 174.3044],
  [-39.3463, 174.3060],
  [-39.3462, 174.3070],
  [-39.3462, 174.3090],
  [-39.3459, 174.3107],
  [-39.3451, 174.3122],
  [-39.3435, 174.3138],
  [-39.3429, 174.3148],
  [-39.3428, 174.3150]
];

const stratfordToko = [
  [-39.3428, 174.3150],
  [-39.3376, 174.3280],
  [-39.3373, 174.3294],
  [-39.3375, 174.3311],
  [-39.3451, 174.3558],
  [-39.3454, 174.3577],
  [-39.3453, 174.3652],
  [-39.3448, 174.3668],
  [-39.3406, 174.3744],
  [-39.3402, 174.3755],
  [-39.3401, 174.3764],
  [-39.3406, 174.3824],
  [-39.3404, 174.3836],
  [-39.3398, 174.3846],
  [-39.3359, 174.3883],
  [-39.3352, 174.3893],
  [-39.3349, 174.3905],
  [-39.3346, 174.3936]
];

const tokoKioreTunnel = [
  [-39.3346, 174.3936],
  [-39.3345, 174.3953],
  [-39.3338, 174.3967],
  [-39.3285, 174.4015],
  [-39.3235, 174.4073],
  [-39.3230, 174.4082],
  [-39.3209, 174.4138],
  [-39.3205, 174.4166],
  [-39.3220, 174.4264],
  [-39.3220, 174.4279],
  [-39.3181, 174.4526],
  [-39.3174, 174.4546],
  [-39.3164, 174.4561],
  [-39.3071, 174.4637],
  [-39.3016, 174.4675],
  [-39.3005, 174.4679],
  [-39.2918, 174.4686],
  [-39.2756, 174.4647],
  [-39.2673, 174.4654],
  [-39.2660, 174.4660],
  [-39.2654, 174.4667],
  [-39.2647, 174.4679],
  [-39.2635, 174.4690],
  [-39.2626, 174.4693],
  [-39.2620, 174.4697],
  [-39.2612, 174.4706],
  [-39.2604, 174.4711],
  [-39.2590, 174.4715],
  [-39.2576, 174.4725],
  [-39.2567, 174.4727],
  [-39.2537, 174.4723],
  [-39.2526, 174.4724],
  [-39.2517, 174.4730],
  [-39.2484, 174.4762],
  [-39.2464, 174.4797],
  [-39.2461, 174.4809],
  [-39.2465, 174.4821],
  [-39.2505, 174.4870],
  [-39.2518, 174.4896],
  [-39.2532, 174.4934],
  [-39.2532, 174.4951],
  [-39.2529, 174.4962],
  [-39.2442, 174.5120],
  [-39.2431, 174.5132],
  [-39.2352, 174.5193],
  [-39.2296, 174.5259],
  [-39.2286, 174.5267],
  [-39.2262, 174.5275],
  [-39.2246, 174.5291],
  [-39.2240, 174.5308],
  [-39.2240, 174.5326],
  [-39.2272, 174.5392],
  [-39.2276, 174.5414],
  [-39.2280, 174.5425],
  [-39.2297, 174.5451],
  [-39.2305, 174.5461]
];

const kioreTunnel = [
  [-39.2305, 174.5461],
  [-39.2324, 174.5472]
];

const kioreTunnelPohukuraTunnel = [
  [-39.2324, 174.5472],
  [-39.2347, 174.5485],
  [-39.2353, 174.5494],
  [-39.2356, 174.5505],
  [-39.2360, 174.5512],
  [-39.2366, 174.5518],
  [-39.2370, 174.5532],
  [-39.2367, 174.5546],
  [-39.2358, 174.5558],
  [-39.2352, 174.5574],
  [-39.2350, 174.5591],
  [-39.2352, 174.5607],
  [-39.2351, 174.5620],
  [-39.2345, 174.5665],
  [-39.2347, 174.5680],
  [-39.2352, 174.5688],
  [-39.2390, 174.5729],
  [-39.2396, 174.5739],
  [-39.2398, 174.5749],
  [-39.2400, 174.5799],
  [-39.2399, 174.5809],
  [-39.2362, 174.5895],
  [-39.2341, 174.5920],
  [-39.2331, 174.5926],
  [-39.2304, 174.5934],
  [-39.2294, 174.5941],
  [-39.2247, 174.5994],
  [-39.2233, 174.6002],
  [-39.2211, 174.6006],
  [-39.2199, 174.6012],
  [-39.2192, 174.6024],
  [-39.2181, 174.6061],
  [-39.2169, 174.6077],
  [-39.2112, 174.6115],
  [-39.2081, 174.6155],
  [-39.2070, 174.6165],
  [-39.2066, 174.6171],
  [-39.2063, 174.6187],
  [-39.2056, 174.6209],
  [-39.2044, 174.6220],
  [-39.1955, 174.6242],
  [-39.1945, 174.6242],
  [-39.1928, 174.6239],
  [-39.1914, 174.6241],
  [-39.1905, 174.6247],
  [-39.1891, 174.6249],
  [-39.1867, 174.6238],
  [-39.1856, 174.6231],
  [-39.1838, 174.6209],
  [-39.1831, 174.6205],
  [-39.1823, 174.6207],
  [-39.1818, 174.6213],
  [-39.1816, 174.6223],
  [-39.1816, 174.6237],
  [-39.1812, 174.6256],
  [-39.1804, 174.6269],
  [-39.1773, 174.6294]
];

const pohukuraTunnel = [
  [-39.1773, 174.6294],
  [-39.1752, 174.6311]
];

const pohukuraTunnelWhangamomonaTunnel = [
  [-39.1752, 174.6311],
  [-39.1739, 174.6316],
  [-39.1731, 174.6325],
  [-39.1713, 174.6357],
  [-39.1706, 174.6364],
  [-39.1697, 174.6366],
  [-39.1686, 174.6365],
  [-39.1670, 174.6370],
  [-39.1644, 174.6372],
  [-39.1637, 174.6375],
  [-39.1625, 174.6385],
  [-39.1584, 174.6400],
  [-39.1578, 174.6407],
  [-39.1574, 174.6417],
  [-39.1572, 174.6459],
  [-39.1576, 174.6477],
  [-39.1584, 174.6491],
  [-39.1609, 174.6510],
  [-39.1618, 174.6526],
  [-39.1621, 174.6544],
  [-39.1619, 174.6579],
  [-39.1622, 174.6596],
  [-39.1630, 174.6608],
  [-39.1644, 174.6616],
  [-39.1649, 174.6622],
  [-39.1700, 174.6690],
  [-39.1704, 174.6703],
  [-39.1701, 174.6714],
  [-39.1687, 174.6737],
  [-39.1678, 174.6744],
  [-39.1665, 174.6746],
  [-39.1645, 174.6742],
  [-39.1634, 174.6744],
  [-39.1583, 174.6776],
  [-39.1574, 174.6786],
  [-39.1567, 174.6796],
  [-39.1557, 174.6802],
  [-39.1535, 174.6804],
  [-39.1526, 174.6801],
  [-39.1502, 174.6788],
  [-39.1492, 174.6788],
  [-39.1485, 174.6792],
  [-39.1451, 174.6828],
  [-39.1446, 174.6838],
  [-39.1444, 174.6850],
  [-39.1444, 174.6861],
  [-39.1441, 174.6881],
  [-39.1437, 174.6891]
];

const whangamomonaTunnel = [
  [-39.1437, 174.6891],
  [-39.1399, 174.6927],
  [-39.1394, 174.6936]
];

const whangamomonaTunnelTunnelNo4 = [
  [-39.1394, 174.6936],
  [-39.1392, 174.6951],
  [-39.1390, 174.6961],
  [-39.1383, 174.6967],
  [-39.1375, 174.6971],
  [-39.1361, 174.6984],
  [-39.1353, 174.6998],
  [-39.1342, 174.7010],
  [-39.1337, 174.7014],
  [-39.1333, 174.7024],
  [-39.1334, 174.7033],
  [-39.1350, 174.7069],
  [-39.1352, 174.7078],
  [-39.1352, 174.7090],
  [-39.1357, 174.7108],
  [-39.1362, 174.7118],
  [-39.1373, 174.7132],
  [-39.1376, 174.7138],
  [-39.1381, 174.7157],
  [-39.1387, 174.7167],
  [-39.1411, 174.7195],
  [-39.1420, 174.7201],
  [-39.1426, 174.7210],
  [-39.1428, 174.7219],
  [-39.1427, 174.7234],
  [-39.1431, 174.7251],
  [-39.1438, 174.7260],
  [-39.1442, 174.7272],
  [-39.1442, 174.7294],
  [-39.1444, 174.7301],
  [-39.1449, 174.7317],
  [-39.1446, 174.7327],
  [-39.1441, 174.7335],
  [-39.1436, 174.7347],
  [-39.1436, 174.7358],
  [-39.1435, 174.7392],
  [-39.1431, 174.7414],
  [-39.1416, 174.7436],
  [-39.1403, 174.7445],
  [-39.1394, 174.7445],
  [-39.1380, 174.7444],
  [-39.1368, 174.7447],
  [-39.1352, 174.7456],
  [-39.1344, 174.7459],
  [-39.1318, 174.7464],
  [-39.1307, 174.7460],
  [-39.1298, 174.7454],
  [-39.1291, 174.7445],
  [-39.1286, 174.7442],
  [-39.1270, 174.7439],
  [-39.1264, 174.7437],
  [-39.1239, 174.7419],
  [-39.1231, 174.7416],
  [-39.1215, 174.7418],
  [-39.1154, 174.7431],
  [-39.1140, 174.7436],
  [-39.1072, 174.7476],
  [-39.1058, 174.7490],
  [-39.1028, 174.7542],
  [-39.1024, 174.7557],
  [-39.1024, 174.7570],
  [-39.1022, 174.7587],
  [-39.1011, 174.7605],
  [-39.0920, 174.7681],
  [-39.0907, 174.7698],
  [-39.0874, 174.7774],
  [-39.0857, 174.7800],
  [-39.0851, 174.7807],
  [-39.0787, 174.7854],
  [-39.0773, 174.7860],
  [-39.0765, 174.7866],
  [-39.0761, 174.7875],
  [-39.0756, 174.7905],
  [-39.0752, 174.7914],
  [-39.0725, 174.7944],
  [-39.0694, 174.7967],
  [-39.0681, 174.7971],
  [-39.0669, 174.7972]
];

const tunnelNo4 = [
  [-39.0669, 174.7972],
  [-39.0625, 174.7957]
];

const tunnelNo4TunnelNo5 = [
  [-39.0625, 174.7957],
  [-39.0612, 174.7952],
  [-39.0603, 174.7952],
  [-39.0587, 174.7957]
];

const tunnelNo5 = [
  [-39.0587, 174.7957],
  [-39.0563, 174.7966]
];

const tunnelNo5TunnelNo6 = [
  [-39.0563, 174.7966],
  [-39.0555, 174.7971],
  [-39.0544, 174.7977],
  [-39.0537, 174.7983],
  [-39.0526, 174.7998],
  [-39.0517, 174.8019],
  [-39.0511, 174.8028],
  [-39.0503, 174.8033],
  [-39.0496, 174.8042],
  [-39.0489, 174.8055],
  [-39.0484, 174.8061],
  [-39.0468, 174.8072],
  [-39.0461, 174.8074],
  [-39.0400, 174.8067],
  [-39.0392, 174.8069],
  [-39.0385, 174.8071],
  [-39.0376, 174.8072],
  [-39.0334, 174.8046],
  [-39.0322, 174.8041],
  [-39.0287, 174.8040],
  [-39.0274, 174.8050],
  [-39.0266, 174.8062],
  [-39.0256, 174.8101],
  [-39.0256, 174.8113],
  [-39.0256, 174.8127],
  [-39.0239, 174.8166],
  [-39.0238, 174.8178],
  [-39.0249, 174.8230],
  [-39.0249, 174.8236],
  [-39.0240, 174.8270],
  [-39.0236, 174.8277],
  [-39.0222, 174.8288],
  [-39.0218, 174.8295],
  [-39.0216, 174.8302],
  [-39.0212, 174.8329]
];

const tunnelNo6 = [
  [-39.0212, 174.8329],
  [-39.0211, 174.8343]
];

const tunnelNo6TangarakauTunnel = [
  [-39.0211, 174.8343],
  [-39.0209, 174.8360],
  [-39.0201, 174.8378],
  [-39.0198, 174.8390],
  [-39.0200, 174.8402],
  [-39.0197, 174.8413],
  [-39.0185, 174.8429],
  [-39.0182, 174.8441],
  [-39.0183, 174.8469],
  [-39.0185, 174.8474]
];

const tangarakauTunnel = [
  [-39.0185, 174.8474],
  [-39.0197, 174.8488]
];

const tangarakauTunnelTunnelNo8 = [
  [-39.0197, 174.8488],
  [-39.0204, 174.8496],
  [-39.0208, 174.8506],
  [-39.0206, 174.8515],
  [-39.0198, 174.8529],
  [-39.0188, 174.8557],
  [-39.0187, 174.8568],
  [-39.0189, 174.8605],
  [-39.0198, 174.8631],
  [-39.0197, 174.8647],
  [-39.0190, 174.8656],
  [-39.0177, 174.8664],
  [-39.0172, 174.8669]
];

const tunnelNo8 = [
  [-39.0172, 174.8669],
  [-39.0144, 174.8729]
];

const tunnelNo8TunnelNo9 = [
  [-39.0144, 174.8729],
  [-39.0136, 174.8744],
  [-39.0133, 174.8765],
  [-39.0140, 174.8800],
  [-39.0147, 174.8818],
  [-39.0147, 174.8824]
];

const tunnelNo9 = [
  [-39.0147, 174.8824],
  [-39.0146, 174.8953]
];

const tunnelNo9TunnelNo10 = [
  [-39.0146, 174.8953],
  [-39.0140, 174.8987],
  [-39.0140, 174.9006],
  [-39.0137, 174.9017],
  [-39.0113, 174.9044],
  [-39.0108, 174.9059],
  [-39.0108, 174.9078]
];

const tunnelNo10 = [
  [-39.0108, 174.9078],
  [-39.0109, 174.9227]
];

const tunnelNo10MangateteTunnel = [
  [-39.0109, 174.9227],
  [-39.0106, 174.9239],
  [-39.0062, 174.9271],
  [-39.0039, 174.9278],
  [-39.0025, 174.9296],
  [-39.0016, 174.9311]
];

const mangateteTunnel = [
  [-39.0016, 174.9311],
  [-38.9956, 174.9407]
];

const mangateteTunnelTunnelNo12 = [
  [-38.9956, 174.9407],
  [-38.9954, 174.9417],
  [-38.9957, 174.9428],
  [-38.9979, 174.9469],
  [-38.9990, 174.9516],
  [-38.9987, 174.9532],
  [-38.9967, 174.9576]
];

const tunnelNo12 = [
  [-38.9967, 174.9576],
  [-38.9959, 174.9592]
];

const tunnelNo12TunnelNo13 = [
  [-38.9959, 174.9592],
  [-38.9945, 174.9610],
  [-38.9925, 174.9628]
];

const tunnelNo13 = [
  [-38.9925, 174.9628],
  [-38.9914, 174.9635]
];

const tunnelNo13KokopuTunnel = [
  [-38.9914, 174.9635],
  [-38.9913, 174.9635],
  [-38.9871, 174.9654],
  [-38.9859, 174.9667],
  [-38.9850, 174.9687],
  [-38.9834, 174.9704],
  [-38.9824, 174.9709],
  [-38.9806, 174.9711],
  [-38.9796, 174.9717],
  [-38.9785, 174.9732],
  [-38.9775, 174.9739],
  [-38.9749, 174.9743],
  [-38.9736, 174.9748],
  [-38.9689, 174.9777],
  [-38.9668, 174.9799],
  [-38.9606, 174.9893]
];

const kokopuTunnel = [
  [-38.9606, 174.9893],
  [-38.9573, 174.9941]
];

const kokopuTunnelTokirimaTunnel = [
  [-38.9573, 174.9941],
  [-38.9566, 174.9953],
  [-38.9559, 174.9972],
  [-38.9556, 174.9991],
  [-38.9549, 175.0006],
  [-38.9518, 175.0038],
  [-38.9464, 175.0060],
  [-38.9456, 175.0065],
  [-38.9431, 175.0087],
  [-38.9408, 175.0096],
  [-38.9332, 175.0088],
  [-38.9324, 175.0085],
  [-38.9318, 175.0077],
  [-38.9297, 175.0035],
  [-38.9295, 175.0026],
  [-38.9297, 175.0018],
  [-38.9300, 175.0008],
  [-38.9299, 174.9980],
  [-38.9297, 174.9971],
  [-38.9292, 174.9962],
  [-38.9290, 174.9956],
  [-38.9290, 174.9947],
  [-38.9294, 174.9933],
  [-38.9295, 174.9925],
  [-38.9292, 174.9905],
  [-38.9287, 174.9895],
  [-38.9277, 174.9888],
  [-38.9263, 174.9885]
];

const tokirimaTunnel = [
  [-38.9263, 174.9885],
  [-38.9203, 174.9876]
];

const tokirimaTunnelTunnelNo16 = [
  [-38.9203, 174.9876],
  [-38.9201, 174.9876],
  [-38.9176, 174.9882],
  [-38.9165, 174.9883],
  [-38.9161, 174.9882]
];

const tunnelNo16 = [
  [-38.9161, 174.9882],
  [-38.9156, 174.9879]
];

const tunnelNo16TunnelNo17 = [
  [-38.9156, 174.9879],
  [-38.9153, 174.9876],
  [-38.9150, 174.9869],
  [-38.9142, 174.9835],
  [-38.9134, 174.9821],
  [-38.9112, 174.9798],
  [-38.9110, 174.9797]
];

const tunnelNo17 = [
  [-38.9110, 174.9797],
  [-38.9099, 174.9791],
  [-38.9072, 174.9787]
];

const tunnelNo17OhuraTunnel = [
  [-38.9072, 174.9787],
  [-38.9032, 174.9786],
  [-38.9023, 174.9783],
  [-38.9014, 174.9778],
  [-38.9007, 174.9777],
  [-38.8998, 174.9777],
  [-38.8987, 174.9774],
  [-38.8976, 174.9774],
  [-38.8967, 174.9778],
  [-38.8962, 174.9779],
  [-38.8950, 174.9779],
  [-38.8938, 174.9783],
  [-38.8915, 174.9784],
  [-38.8876, 174.9791],
  [-38.8868, 174.9790],
  [-38.8859, 174.9785],
  [-38.8848, 174.9785],
  [-38.8840, 174.9790],
  [-38.8836, 174.9798],
  [-38.8825, 174.9833],
  [-38.8819, 174.9844],
  [-38.8809, 174.9849],
  [-38.8795, 174.9845],
  [-38.8789, 174.9836],
  [-38.8786, 174.9826],
  [-38.8784, 174.9804],
  [-38.8778, 174.9789],
  [-38.8768, 174.9781],
  [-38.8756, 174.9780],
  [-38.8744, 174.9782],
  [-38.8736, 174.9781],
  [-38.8727, 174.9778],
  [-38.8717, 174.9778],
  [-38.8714, 174.9777]
];

const ohuraTunnel = [
  [-38.8714, 174.9777],
  [-38.8700, 174.9771]
];

const ohuraTunnelMangaparoTunnel = [
  [-38.8700, 174.9771],
  [-38.8673, 174.9755],
  [-38.8666, 174.9754],
  [-38.8658, 174.9758],
  [-38.8635, 174.9785],
  [-38.8628, 174.9790],
  [-38.8621, 174.9791],
  [-38.8604, 174.9792],
  [-38.8593, 174.9789],
  [-38.8582, 174.9782],
  [-38.8576, 174.9781]
];

const mangaparoTunnel = [
  [-38.8576, 174.9781],
  [-38.8562, 174.9778]
];

const mangaparoTunnelMatiere = [
  [-38.8562, 174.9778],
  [-38.8545, 174.9774],
  [-38.8534, 174.9772],
  [-38.8524, 174.9773],
  [-38.8508, 174.9779],
  [-38.8502, 174.9780],
  [-38.8439, 174.9782],
  [-38.8335, 174.9804],
  [-38.8322, 174.9813],
  [-38.8272, 174.9910],
  [-38.8257, 174.9960],
  [-38.8241, 174.9995],
  [-38.8222, 175.0081],
  [-38.8215, 175.0097],
  [-38.8204, 175.0108],
  [-38.8154, 175.0128],
  [-38.8114, 175.0154],
  [-38.8103, 175.0170],
  [-38.8092, 175.0197],
  [-38.7994, 175.0354],
  [-38.7980, 175.0365],
  [-38.7965, 175.0367],
  [-38.7954, 175.0374],
  [-38.7949, 175.0384],
  [-38.7947, 175.0399],
  [-38.7950, 175.0415],
  [-38.7948, 175.0430],
  [-38.7939, 175.0440],
  [-38.7881, 175.0458],
  [-38.7867, 175.0469],
  [-38.7860, 175.0486],
  [-38.7812, 175.0696],
  [-38.7803, 175.0709],
  [-38.7775, 175.0725],
  [-38.7765, 175.0737],
  [-38.7738, 175.0792],
  [-38.7725, 175.0826],
  [-38.7655, 175.0946],
  [-38.7651, 175.0959],
  [-38.7650, 175.0969],
  [-38.7650, 175.0982]
];

const matiereMatiereTunnel = [
  [-38.7650, 175.0982],
  [-38.7651, 175.1010],
  [-38.7659, 175.1041],
  [-38.7665, 175.1050],
  [-38.7685, 175.1068],
  [-38.7699, 175.1075],
  [-38.7716, 175.1077],
  [-38.7725, 175.1081],
  [-38.7735, 175.1090],
  [-38.7745, 175.1104]
];

const matiereTunnel = [
  [-38.7745, 175.1104],
  [-38.7755, 175.1118]
];

const matiereTunnelTunnelNo21 = [
  [-38.7755, 175.1118],
  [-38.7772, 175.1144],
  [-38.7784, 175.1158],
  [-38.7809, 175.1179],
  [-38.7815, 175.1189],
  [-38.7818, 175.1206]
];

const tunnelNo21 = [
  [-38.7818, 175.1206],
  [-38.7819, 175.1225]
];

const tunnelNo21TunnelNo22 = [
  [-38.7819, 175.1225],
  [-38.7820, 175.1237],
  [-38.7829, 175.1271],
  [-38.7828, 175.1283],
  [-38.7821, 175.1293],
  [-38.7812, 175.1298],
  [-38.7807, 175.1307],
  [-38.7806, 175.1317],
  [-38.7811, 175.1326],
  [-38.7818, 175.1331],
  [-38.7824, 175.1340],
  [-38.7827, 175.1350],
  [-38.7825, 175.1364],
  [-38.7809, 175.1402],
  [-38.7806, 175.1413],
  [-38.7805, 175.1429],
  [-38.7801, 175.1439],
  [-38.7794, 175.1445],
  [-38.7787, 175.1447]
];

const tunnelNo22 = [
  [-38.7787, 175.1447],
  [-38.7784, 175.1449],
  [-38.7781, 175.1452]
];

const tunnelNo22TunnelNo23 = [
  [-38.7781, 175.1452],
  [-38.7773, 175.1461],
  [-38.7764, 175.1462],
  [-38.7750, 175.1460],
  [-38.7737, 175.1466],
  [-38.7729, 175.1477],
  [-38.7721, 175.1499],
  [-38.7715, 175.1506],
  [-38.7708, 175.1511],
  [-38.7704, 175.1518],
  [-38.7702, 175.1529],
  [-38.7705, 175.1545],
  [-38.7703, 175.1556],
  [-38.7696, 175.1567],
  [-38.7685, 175.1576],
  [-38.7670, 175.1595],
  [-38.7667, 175.1600]
];

const tunnelNo23 = [
  [-38.7667, 175.1600],
  [-38.7665, 175.1605],
  [-38.7664, 175.1611]
];

const tunnelNo23OkahukuraTunnel = [
  [-38.7664, 175.1611],
  [-38.7665, 175.1615],
  [-38.7670, 175.1623],
  [-38.7699, 175.1652],
  [-38.7704, 175.1663],
  [-38.7712, 175.1670],
  [-38.7723, 175.1674],
  [-38.7729, 175.1680],
  [-38.7734, 175.1694],
  [-38.7740, 175.1702],
  [-38.7748, 175.1710],
  [-38.7751, 175.1717],
  [-38.7751, 175.1724],
  [-38.7748, 175.1734],
  [-38.7749, 175.1746],
  [-38.7754, 175.1755],
  [-38.7801, 175.1780],
  [-38.7817, 175.1788],
  [-38.7819, 175.1789]
];

const okahukuraTunnel = [
  [-38.7819, 175.1789],
  [-38.7822, 175.1793],
  [-38.7826, 175.1803],
  [-38.7841, 175.1954],
  [-38.7844, 175.1961]
];

const okahukuraTunnelStateHighway4 = [
  [-38.7844, 175.1961],
  [-38.7851, 175.1972],
  [-38.7856, 175.1985],
  [-38.7857, 175.2012],
  [-38.7861, 175.2034],
  [-38.7859, 175.2046],
  [-38.7836, 175.2094],
  [-38.7835, 175.2105],
  [-38.7838, 175.2113],
  [-38.7843, 175.2119],
  [-38.7854, 175.2124],
  [-38.7874, 175.2138],
  [-38.7878, 175.2145],
  [-38.7880, 175.2154],
  [-38.7885, 175.2162],
  [-38.7903, 175.2184],
  [-38.7907, 175.2191],
  [-38.7910, 175.2200],
  [-38.7915, 175.2207],
  [-38.7948, 175.2232],
  [-38.7955, 175.2233],
  [-38.7968, 175.2229],
  [-38.7971, 175.2229]
];

const stateHighway4OkahukuraJunction = [
  [-38.7971, 175.2229],
  [-38.7976, 175.2230],
  [-38.7989, 175.2236],
  [-38.8003, 175.2238]
];

export const stratfordOkahukura: Line = {
  name: 'Stratford - Okahukura',
  state: 'NZ',
  segments: [
    {
      segments: [
        {
          date: '1960-01-01',
          original: [stratfordOld, stratfordOldSouthJunction],
          deviation: [stratfordDeviation]
        },
        stratfordToko
      ],
      history: {
        opened: {
          date: '1902-08-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-11-01',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [
        tokoKioreTunnel,
        {
          name: 'Kiore Tunnel',
          segment: kioreTunnel,
          type: 'tunnel'
        },
        kioreTunnelPohukuraTunnel,
        {
          name: 'Pokukura Tunnel',
          segment: pohukuraTunnel,
          type: 'tunnel'
        },
        pohukuraTunnelWhangamomonaTunnel,
        {
          name: 'Whangamomona Tunnel',
          segment: whangamomonaTunnel,
          type: 'tunnel'
        },
        whangamomonaTunnelTunnelNo4,
        {
          name: 'Tunnel No 4',
          segment: tunnelNo4,
          type: 'tunnel'
        },
        tunnelNo4TunnelNo5,
        {
          name: 'Tunnel No 5',
          segment: tunnelNo5,
          type: 'tunnel'
        },
        tunnelNo5TunnelNo6,
        {
          name: 'Tunnel No 6',
          segment: tunnelNo6,
          type: 'tunnel'
        },
        tunnelNo6TangarakauTunnel,
        {
          name: 'Tangarakau Tunnel',
          segment: tangarakauTunnel,
          type: 'tunnel'
        },
        tangarakauTunnelTunnelNo8,
        {
          name: 'Tunnel No 8',
          segment: tunnelNo8,
          type: 'tunnel'
        },
        tunnelNo8TunnelNo9,
        {
          name: 'Tunnel No 9',
          segment: tunnelNo9,
          type: 'tunnel'
        },
        tunnelNo9TunnelNo10,
        {
          name: 'Tunnel No 10',
          segment: tunnelNo10,
          type: 'tunnel'
        },
        tunnelNo10MangateteTunnel,
        {
          name: 'Mangatete Tunnel',
          segment: mangateteTunnel,
          type: 'tunnel'
        },
        mangateteTunnelTunnelNo12,
        {
          name: 'Tunnel No 12',
          segment: tunnelNo12,
          type: 'tunnel'
        },
        tunnelNo12TunnelNo13,
        {
          name: 'Tunnel No 13',
          segment: tunnelNo13,
          type: 'tunnel'
        },
        tunnelNo13KokopuTunnel,
        {
          name: 'Kokopu Tunnel',
          segment: kokopuTunnel,
          type: 'tunnel'
        },
        kokopuTunnelTokirimaTunnel,
        {
          name: 'Tokirima Tunnel',
          segment: tokirimaTunnel,
          type: 'tunnel'
        },
        tokirimaTunnelTunnelNo16,
        {
          name: 'Tunnel No 16',
          segment: tunnelNo16,
          type: 'tunnel'
        },
        tunnelNo16TunnelNo17,
        {
          name: 'Tunnel No 17',
          segment: tunnelNo17,
          type: 'tunnel'
        },
        tunnelNo17OhuraTunnel,
        {
          name: 'Ohura Tunnel',
          segment: ohuraTunnel,
          type: 'tunnel'
        },
        ohuraTunnelMangaparoTunnel,
        {
          name: 'Mangaparo Tunnel',
          segment: mangaparoTunnel,
          type: 'tunnel'
        },
        mangaparoTunnelMatiere
      ],
      history: {
        opened: {
          date: '1932-12-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-11-01',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [
        matiereMatiereTunnel,
        {
          name: 'Matiere Tunnel',
          segment: matiereTunnel,
          type: 'tunnel'
        },
        matiereTunnelTunnelNo21,
        {
          name: 'Tunnel No 21',
          segment: tunnelNo21,
          type: 'tunnel'
        },
        tunnelNo21TunnelNo22,
        {
          name: 'Tunnel No 22',
          segment: tunnelNo22,
          type: 'tunnel'
        },
        tunnelNo22TunnelNo23,
        {
          name: 'Tunnel No 23',
          segment: tunnelNo23,
          type: 'tunnel'
        },
        tunnelNo23OkahukuraTunnel,
        {
          name: 'Okahukura Tunnel',
          segment: okahukuraTunnel,
          type: 'tunnel'
        },
        okahukuraTunnelStateHighway4
      ],
      history: {
        opened: {
          date: '1922-05-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-11-01',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [stateHighway4OkahukuraJunction],
      history: {
        opened: {
          date: '1922-05-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-11-01',
          status: 'closed'
        }]
      }
    }
  ]
}
