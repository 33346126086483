import { Line } from "../../../../trackTypes";

const lodgeRoad = [
  [-27.41592, 153.03656],
  [-27.41570, 153.03663],
  [-27.41557, 153.03683],
  [-27.41551, 153.03701],
  [-27.41562, 153.03778],
  [-27.41570, 153.03802],
  [-27.41615, 153.03868],
  [-27.41617, 153.03880],
  [-27.41613, 153.03895],
  [-27.41518, 153.04031],
  [-27.41509, 153.04054],
  [-27.41483, 153.04134],
  [-27.41475, 153.04149],
  [-27.41462, 153.04163],
  [-27.41451, 153.04170],
  [-27.41383, 153.04199],
  [-27.41377, 153.04208],
  [-27.41373, 153.04220],
  [-27.41371, 153.04233],
  [-27.41381, 153.04292],
  [-27.41379, 153.04303],
  [-27.41373, 153.04309],
  [-27.40986, 153.04383]
];

const shawRoad = [
  [-27.40986, 153.04383],
  [-27.40979, 153.04389],
  [-27.40977, 153.04397],
  [-27.40975, 153.04406],
  [-27.41012, 153.04659],
  [-27.41007, 153.04671],
  [-27.41001, 153.04677],
  [-27.40846, 153.04706]
];

export const east7: Line = {
  name: 'East 7',
  state: 'QLD',
  segments: [
    {
      segments: [lodgeRoad],
      history: {
        opened: {
          date: '1928-11-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-07-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [shawRoad],
      history: {
        opened: {
          date: '1929-02-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-07-02',
          status: 'closed'
        }]
      }
    }
  ]
}
