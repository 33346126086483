import { Line } from "../../../trackTypes";

const waterfallWaterfallTunnel = [
  [-34.1345, 150.9945],
  [-34.1369, 150.9948],
  [-34.1377, 150.9948],
  [-34.1392, 150.9944],
  [-34.1407, 150.9946],
  [-34.1424, 150.9956],
  [-34.1436, 150.9958],
  [-34.1445, 150.9957]
];

const waterfallTunnel = [
  [-34.1445, 150.9957],
  [-34.1485, 150.9952]
];

const waterfallTunnelWaterfallDeviationStart = [
  [-34.1485, 150.9952],
  [-34.1515, 150.9947]
];

const waterfallDeviation = [
  [-34.1515, 150.9947],
  [-34.1527, 150.9951],
  [-34.1534, 150.9958],
  [-34.1540, 150.9968],
  [-34.1547, 150.9973],
  [-34.1556, 150.9973],
  [-34.1564, 150.9970],
  [-34.1573, 150.9955],
  [-34.1580, 150.9949],
  [-34.1590, 150.9945],
  [-34.1600, 150.9936],
  [-34.1619, 150.9892],
  [-34.1627, 150.9882],
  [-34.1638, 150.9879],
  [-34.1649, 150.9883],
  [-34.1659, 150.9898],
  [-34.1659, 150.9913],
  [-34.1653, 150.9924],
  [-34.1643, 150.9934],
  [-34.1639, 150.9941],
  [-34.1632, 150.9955],
  [-34.1621, 150.9967],
  [-34.1616, 150.9978],
  [-34.1616, 150.9989],
  [-34.1619, 150.9999],
  [-34.1624, 151.0006],
  [-34.1644, 151.0024],
  [-34.1655, 151.0029],
  [-34.1665, 151.0028],
  [-34.1673, 151.0021],
  [-34.1677, 151.0012],
  [-34.1681, 151.0000],
  [-34.1687, 150.9989],
  [-34.1693, 150.9985],
  [-34.1706, 150.9980],
  [-34.1714, 150.9974],
  [-34.1720, 150.9966],
  [-34.1735, 150.9930],
  [-34.1742, 150.9923],
  [-34.1746, 150.9921]
];

const waterfallOldStartCawleyTunnel = [
  [-34.1515, 150.9947],
  [-34.1529, 150.9958],
  [-34.1541, 150.9976],
  [-34.1550, 150.9980],
  [-34.1564, 150.9977],
  [-34.1574, 150.9969],
  [-34.1581, 150.9956],
  [-34.1591, 150.9951],
  [-34.1601, 150.9945],
  [-34.1622, 150.9905],
  [-34.1630, 150.9897],
  [-34.1642, 150.9892],
  [-34.1656, 150.9896],
  [-34.1686, 150.9917]
];

const cawleyTunnel = [
  [-34.1686, 150.9917],
  [-34.1712, 150.9942]
];

const cawlerTunnelWaterfallOldEnd = [
  [-34.1712, 150.9942],
  [-34.1719, 150.9943],
  [-34.1731, 150.9938],
  [-34.1740, 150.9929],
  [-34.1746, 150.9921]
];

const helensburghDeviationStartHelensburghTunnel = [
  [-34.1746, 150.9921],
  [-34.1758, 150.9922],
  [-34.1766, 150.9929],
  [-34.1769, 150.9938],
  [-34.1768, 150.9951],
  [-34.1758, 150.9964],
  [-34.1752, 150.9972],
  [-34.1748, 150.9985],
  [-34.1743, 150.9992],
  [-34.1733, 151.0001],
  [-34.1727, 151.0010],
  [-34.1726, 151.0023],
  [-34.1729, 151.0033],
  [-34.1744, 151.0056]
];

const helensburghTunnel = [
  [-34.1744, 151.0056],
  [-34.1763, 151.0084],
  [-34.1768, 151.0088],
  [-34.1773, 151.0089]
];

const helensburghTunnelMetropolitanTunnel = [
  [-34.1773, 151.0089],
  [-34.1777, 151.0089],
  [-34.1784, 151.0087],
  [-34.1795, 151.0080],
  [-34.1801, 151.0078],
  [-34.1804, 151.0078]
];

const metropolitanTunnel = [
  [-34.1804, 151.0078],
  [-34.1816, 151.0077]
];

const metropolitanTunnelLilyvaleTunnel = [
  [-34.1816, 151.0077],
  [-34.1823, 151.0073],
  [-34.1827, 151.0068],
  [-34.1839, 151.0044],
  [-34.1842, 151.0030],
  [-34.1847, 151.0018],
  [-34.1855, 151.0012],
  [-34.1863, 151.0011],
  [-34.1874, 151.0014],
  [-34.1883, 151.0024]
];

const lilyvaleTunnel = [
  [-34.1883, 151.0024],
  [-34.1901, 151.0043]
];

const lilyvaleTunnelHelensburghDeviationEnd = [
  [-34.1901, 151.0043],
  [-34.1903, 151.0046]
];

const helensburghOldStartHelensburghTunnelOld = [
  [-34.1746, 150.9921],
  [-34.1755, 150.9918],
  [-34.1765, 150.9919],
  [-34.1772, 150.9921]
];

const helensburghTunnelOld = [
  [-34.1772, 150.9921],
  [-34.1778, 150.9923]
];

const helensburghTunnelOldMetropolitanTunnelOld = [
  [-34.1778, 150.9923],
  [-34.1794, 150.9929]
];

const metropolitanTunnelOld = [
  [-34.1794, 150.9929],
  [-34.1852, 150.9955]
];

const metropolitanTunnelOldLilyvaleNo1Tunnel = [
  [-34.1852, 150.9955],
  [-34.1878, 150.9971],
  [-34.1882, 150.9975],
  [-34.1884, 150.9983],
  [-34.1884, 150.9984]
];

const lilyvaleNo1Tunnel = [
  [-34.1884, 150.9984],
  [-34.1882, 151.0006]
];

const lilyvaleNo1TunnelLilyvaleNo2Tunnel = [
  [-34.1882, 151.0006],
  [-34.1882, 151.0015],
  [-34.1884, 151.0020],
  [-34.1887, 151.0024]
];

const lilyvaleNo2Tunnel = [
  [-34.1887, 151.0024],
  [-34.1901, 151.0042]
];

const lilyvaleNo2TunnelHelensburghOldEnd = [
  [-34.1901, 151.0042],
  [-34.1903, 151.0046]
];

const helensburghDeviationEndOrtforDeviationStart = [
  [-34.1903, 151.0046],
  [-34.1907, 151.0053],
  [-34.1908, 151.0060],
  [-34.1909, 151.0069],
  [-34.1911, 151.0078],
  [-34.1917, 151.0084],
  [-34.1925, 151.0088],
  [-34.1952, 151.0086],
  [-34.1963, 151.0083],
  [-34.1981, 151.0071],
  [-34.1994, 151.0066],
  [-34.2009, 151.0068],
  [-34.2042, 151.0089],
  [-34.2049, 151.0091],
  [-34.2057, 151.0089],
  [-34.2063, 151.0084],
  [-34.2069, 151.0074],
  [-34.2074, 151.0068],
  [-34.2080, 151.0065],
  [-34.2086, 151.0064],
  [-34.2093, 151.0065],
  [-34.2099, 151.0064],
  [-34.2104, 151.0061],
  [-34.2107, 151.0056]
];

const otfordOtfordDevationStart = [
  [-34.2107, 151.0056],
  [-34.2110, 151.0049],
  [-34.2111, 151.0041],
  [-34.2110, 151.0029],
  [-34.2110, 151.0021],
  [-34.2113, 151.0014],
];

const otfordOldStartOtfordTunnel = [
  [-34.2113, 151.0014],
  [-34.2120, 151.0009],
  [-34.2125, 151.0007]
];

const otfordTunnel = [
  [-34.2125, 151.0007],
  [-34.2246, 150.9963],
  [-34.2255, 150.9954],
  [-34.2258, 150.9946]
];

const otfordTunnelOtfordOldEnd = [
  [-34.2258, 150.9946],
  [-34.2255, 150.9931],
  [-34.2246, 150.9916],
  [-34.2245, 150.9911],
  [-34.2245, 150.9902],
  [-34.2247, 150.9895],
  [-34.2253, 150.9888],
  [-34.2256, 150.9881],
  [-34.2259, 150.9861],
  [-34.2263, 150.9850],
  [-34.2268, 150.9843],
  [-34.2290, 150.9824],
  [-34.2299, 150.9818],
  [-34.2309, 150.9818],
  [-34.2319, 150.9824],
  [-34.2335, 150.9848],
  [-34.2342, 150.9851],
  [-34.2353, 150.9853],
  [-34.2365, 150.9846],
  [-34.2372, 150.9839],
  [-34.2388, 150.9813]
];

const otfordDeviationStartBaldHillTunnel = [
  [-34.2113, 151.0014],
  [-34.2139, 150.9968],
  [-34.2145, 150.9962],
  [-34.2151, 150.9960],
  [-34.2163, 150.9958],
  [-34.2171, 150.9956],
  [-34.2178, 150.9950],
  [-34.2186, 150.9933],
  [-34.2196, 150.9920]
];

const baldHillTunnel = [
  [-34.2196, 150.9920],
  [-34.2210, 150.9911],
  [-34.2220, 150.9905],
  [-34.2225, 150.9900]
];

const baldHillTunnelTinTunnel = [
  [-34.2225, 150.9900],
  [-34.2230, 150.9887],
  [-34.2231, 150.9872],
  [-34.2229, 150.9852],
  [-34.2230, 150.9831],
  [-34.2234, 150.9821],
  [-34.2241, 150.9814],
  [-34.2247, 150.9813]
];

const tinTunnel = [
  [-34.2247, 150.9813],
  [-34.2256, 150.9812]
];

const tinTunnelStanwellParkTunnel = [
  [-34.2256, 150.9812],
  [-34.2266, 150.9810],
  [-34.2275, 150.9804],
  [-34.2280, 150.9795],
  [-34.2286, 150.9777],
  [-34.2288, 150.9757]
];

const stanwellParkTunnel = [
  [-34.2288, 150.9757],
  [-34.2291, 150.9746]
];

const stanwellParkTunnelStanwellCreekTunnel = [
  [-34.2291, 150.9746],
  [-34.2293, 150.9742],
  [-34.2306, 150.9735],
  [-34.2316, 150.9736],
  [-34.2323, 150.9742],
  [-34.2324, 150.9744]
];

const stanwellCreekTunnel = [
  [-34.2324, 150.9744],
  [-34.2327, 150.9754],
  [-34.2329, 150.9777]
];

const stanwellCreekTunnelOtfordDeviationEnd = [
  [-34.2329, 150.9777],
  [-34.2330, 150.9795],
  [-34.2331, 150.9822],
  [-34.2333, 150.9833],
  [-34.2340, 150.9843],
  [-34.2351, 150.9848],
  [-34.2362, 150.9846],
  [-34.2369, 150.9838],
  [-34.2379, 150.9824],
  [-34.2388, 150.9813]
];

const otfordDeviationEndCoalcliff = [
  [-34.2388, 150.9813],
  [-34.2417, 150.9782],
  [-34.2422, 150.9774],
];

const coalCliffCliftonTunnel = [
  [-34.2422, 150.9774],
  [-34.2425, 150.9768],
  [-34.2443, 150.9701],
  [-34.2449, 150.9690],
  [-34.2457, 150.9684],
];

const cliftonTunnelSingleTrack = [
  [-34.2457, 150.9684],
  [-34.2466, 150.9684],
  [-34.2470, 150.9685]
];

const cliftonTunnel = [
  [-34.2470, 150.9685],
  [-34.2556, 150.9713]
];

const cliftonTunnelClifton = [
  [-34.2556, 150.9713],
  [-34.2560, 150.9714],
  [-34.2566, 150.9714],
  [-34.2574, 150.9709],
  [-34.2586, 150.9692],
  [-34.2593, 150.9685]
];

const cliftonWoolongong = [
  [-34.2593, 150.9685],
  [-34.2615, 150.9668],
  [-34.2625, 150.9663],
  [-34.2640, 150.9660],
  [-34.2650, 150.9657],
  [-34.2658, 150.9651],
  [-34.2665, 150.9644],
  [-34.2678, 150.9625],
  [-34.2698, 150.9597],
  [-34.2703, 150.9586],
  [-34.2705, 150.9579],
  [-34.2706, 150.9570],
  [-34.2710, 150.9559],
  [-34.2717, 150.9548],
  [-34.2724, 150.9542],
  [-34.2734, 150.9539],
  [-34.2744, 150.9539],
  [-34.2754, 150.9535],
  [-34.2760, 150.9529],
  [-34.2768, 150.9519],
  [-34.2775, 150.9513],
  [-34.2782, 150.9510],
  [-34.2789, 150.9511],
  [-34.2805, 150.9513],
  [-34.2817, 150.9512],
  [-34.2826, 150.9505],
  [-34.2832, 150.9497],
  [-34.2834, 150.9486],
  [-34.2836, 150.9475],
  [-34.2841, 150.9466],
  [-34.2846, 150.9460],
  [-34.2902, 150.9428],
  [-34.2922, 150.9411],
  [-34.2939, 150.9403],
  [-34.2978, 150.9393],
  [-34.2983, 150.9390],
  [-34.2991, 150.9384],
  [-34.3079, 150.9273],
  [-34.3096, 150.9255],
  [-34.3174, 150.9194],
  [-34.3217, 150.9160],
  [-34.3225, 150.9156],
  [-34.3235, 150.9155],
  [-34.3272, 150.9167],
  [-34.3283, 150.9168],
  [-34.3293, 150.9166],
  [-34.3371, 150.9136],
  [-34.3386, 150.9134],
  [-34.3406, 150.9143],
  [-34.3427, 150.9160],
  [-34.3438, 150.9166],
  [-34.3447, 150.9168],
  [-34.3458, 150.9166],
  [-34.3651, 150.9091],
  [-34.3839, 150.9015],
  [-34.4002, 150.8941],
  [-34.4021, 150.8935],
  [-34.4269, 150.8882]
];

const conistonJunctionWoolongong = [
  [-34.4269, 150.8882],
  [-34.4357, 150.8862],
  [-34.4372, 150.8855],
  [-34.4385, 150.8846],
  [-34.4397, 150.8835]
];

const woolongongUnanderraJunction = [
  [-34.4397, 150.8835],
  [-34.4419, 150.8800],
  [-34.4429, 150.8769],
  [-34.4436, 150.8699],
  [-34.4441, 150.8672],
  [-34.4486, 150.8553],
  [-34.4503, 150.8515],
  [-34.4533, 150.8472],
  [-34.4595, 150.8414]
];

const unanderraJunctionConistonJunction = [
  [-34.4595, 150.8414],
  [-34.4625, 150.8386],
  [-34.4649, 150.8350],
  [-34.4664, 150.8317],
  [-34.4702, 150.8170],
  [-34.4715, 150.8141],
  [-34.4735, 150.8112],
  [-34.4796, 150.8042],
  [-34.4861, 150.7971],
  [-34.4893, 150.7942],
  [-34.4929, 150.7917]
];

const daptoCroomTunnel = [
  [-34.4929, 150.7917],
  [-34.4939, 150.7910],
  [-34.4980, 150.7886],
  [-34.5071, 150.7830],
  [-34.5106, 150.7818],
  [-34.5142, 150.7816],
  [-34.5325, 150.7838],
  [-34.5372, 150.7852],
  [-34.5609, 150.7974],
  [-34.5626, 150.7984],
  [-34.5637, 150.7996],
  [-34.5649, 150.8015],
  [-34.5681, 150.8103],
  [-34.5761, 150.8312],
  [-34.5770, 150.8329]
];

const croomTunnel = [
  [-34.5770, 150.8329],
  [-34.5772, 150.8332]
];

const croomTunnelBombo = [
  [-34.5772, 150.8332],
  [-34.5780, 150.8343],
  [-34.5799, 150.8361],
  [-34.5815, 150.8385],
  [-34.5831, 150.8421],
  [-34.5840, 150.8438],
  [-34.5855, 150.8449],
  [-34.5872, 150.8455],
  [-34.5887, 150.8454],
  [-34.6002, 150.8418],
  [-34.6090, 150.8391],
  [-34.6111, 150.8388],
  [-34.6134, 150.8392],
  [-34.6153, 150.8403],
  [-34.6185, 150.8427],
  [-34.6195, 150.8440],
  [-34.6199, 150.8448],
  [-34.6210, 150.8474],
  [-34.6214, 150.8481],
  [-34.6221, 150.8489],
  [-34.6274, 150.8537],
  [-34.6285, 150.8545],
  [-34.6302, 150.8550],
  [-34.6317, 150.8549],
  [-34.6331, 150.8543],
  [-34.6375, 150.8511],
  [-34.6386, 150.8506],
  [-34.6397, 150.8506],
  [-34.6408, 150.8510],
  [-34.6461, 150.8549],
  [-34.6473, 150.8562],
  [-34.6481, 150.8575],
  [-34.6488, 150.8584],
  [-34.6497, 150.8588],
  [-34.6506, 150.8588],
  [-34.6517, 150.8581],
  [-34.6531, 150.8563],
  [-34.6542, 150.8554],
  [-34.6557, 150.8545],
  [-34.6586, 150.8538]
];

const bomboKiamaTunnel = [
  [-34.6586, 150.8538],
  [-34.6631, 150.8528],
  [-34.6638, 150.8528]
];

const kiamaTunnel = [
  [-34.6638, 150.8528],
  [-34.6651, 150.8531],
  [-34.6679, 150.8543]
];

const kiamaTunnelKiama = [
  [-34.6679, 150.8543],
  [-34.6698, 150.8553],
  [-34.6703, 150.8554],
  [-34.6710, 150.8554],
  [-34.6716, 150.8553],
  [-34.6721, 150.8549],
  [-34.6725, 150.8546]
];

const kiamaOmegaNo1Tunnel = [
  [-34.6725, 150.8546],
  [-34.6779, 150.8498],
  [-34.6789, 150.8492],
  [-34.6810, 150.8482],
  [-34.6835, 150.8478],
  [-34.6857, 150.8479],
  [-34.6932, 150.8491]
];

const omegaNo1Tunnel = [
  [-34.6932, 150.8491],
  [-34.6965, 150.8496]
];

const omegaNo1TunnelOmegaNo2Tunnel = [
  [-34.6965, 150.8496],
  [-34.6979, 150.8498],
  [-34.6994, 150.8498],
  [-34.7003, 150.8497]
];

const omegaNo2Tunnel = [
  [-34.7003, 150.8497],
  [-34.7025, 150.8494]
];

const omegaNo2TunnelOmegaNo3Tunnel = [
  [-34.7025, 150.8494],
  [-34.7057, 150.8490],
  [-34.7065, 150.8488],
  [-34.7075, 150.8485],
  [-34.7083, 150.8485],
  [-34.7091, 150.8488],
  [-34.7101, 150.8493],
  [-34.7109, 150.8494],
  [-34.7117, 150.8492],
  [-34.7122, 150.8488]
];

const omegaNo3Tunnel = [
  [-34.7122, 150.8488],
  [-34.7147, 150.8469]
];

const omegaNo3TunnelOmegaNo4Tunnel = [
  [-34.7147, 150.8469],
  [-34.7150, 150.8467],
  [-34.7157, 150.8458],
  [-34.7170, 150.8436]
];

const omegaNo4Tunnel = [
  [-34.7170, 150.8436],
  [-34.7186, 150.8406]
];

const omegaNo4TunnelBomaderry = [
  [-34.7186, 150.8406],
  [-34.7219, 150.8348],
  [-34.7241, 150.8319],
  [-34.7269, 150.8296],
  [-34.7424, 150.8194],
  [-34.7453, 150.8169],
  [-34.7481, 150.8131],
  [-34.7767, 150.7591],
  [-34.7776, 150.7554],
  [-34.7777, 150.7424],
  [-34.7774, 150.7406],
  [-34.7762, 150.7370],
  [-34.7741, 150.7142],
  [-34.7743, 150.7122],
  [-34.7800, 150.6979],
  [-34.7823, 150.6875],
  [-34.7831, 150.6856],
  [-34.7848, 150.6835],
  [-34.8055, 150.6675],
  [-34.8070, 150.6651],
  [-34.8078, 150.6622],
  [-34.8074, 150.6593],
  [-34.8066, 150.6571],
  [-34.8063, 150.6552],
  [-34.8065, 150.6530],
  [-34.8113, 150.6392],
  [-34.8147, 150.6324],
  [-34.8191, 150.6264],
  [-34.8247, 150.6216],
  [-34.8311, 150.6182],
  [-34.8357, 150.6169],
  [-34.8446, 150.6146],
  [-34.8463, 150.6139],
  [-34.8555, 150.6089]
];

export const southCoast: Line = {
  name: 'South Coast',
  state: 'NSW',
  segments: [
    {
      segments: [
        waterfallWaterfallTunnel,
        {
          date: '1914-01-26',
          original: [{
            name: 'Waterfall Tunnel',
            segment: waterfallTunnel,
            type: 'tunnel'
          }],
          deviation: [waterfallTunnel]
        },
        waterfallTunnelWaterfallDeviationStart,
        {
          date: '1914-01-26',
          original: [
            waterfallOldStartCawleyTunnel,
            {
              name: 'Cawley Tunnel',
              segment: cawleyTunnel,
              type: 'tunnel'
            },
            cawlerTunnelWaterfallOldEnd
          ],
          deviation: [waterfallDeviation]
        }
      ],
      history: {
        opened: {
          date: '1888-10-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1914-01-26',
          tracks: 2
        }]
      }
    },
    {
      segments: [{
          date: '1915-05-30',
          original: [
            helensburghOldStartHelensburghTunnelOld,
            {
              name: 'Helensburgh Tunnel (Old)',
              segment: helensburghTunnelOld,
              type: 'tunnel'
            },
            helensburghTunnelOldMetropolitanTunnelOld,
            {
              name: 'Metropolitan Tunnel (Old)',
              segment: metropolitanTunnelOld,
              type: 'tunnel'
            },
            metropolitanTunnelOldLilyvaleNo1Tunnel,
            {
              name: 'Lilyvale No 1 Tunnel',
              segment: lilyvaleNo1Tunnel,
              type: 'tunnel'
            },
            lilyvaleNo1TunnelLilyvaleNo2Tunnel,
            {
              name: 'Lilyvale No 2 Tunnel',
              segment: lilyvaleNo2Tunnel,
              type: 'tunnel'
            },
            lilyvaleNo2TunnelHelensburghOldEnd
          ],
          deviation: [
            helensburghDeviationStartHelensburghTunnel,
            {
              name: 'Helensburgh Tunnel',
              segment: helensburghTunnel,
              type: 'tunnel'
            },
            helensburghTunnelMetropolitanTunnel,
            {
              name: 'Metropolitan Tunnel',
              segment: metropolitanTunnel,
              type: 'tunnel'
            },
            metropolitanTunnelLilyvaleTunnel,
            {
              name: 'Lilyvale Tunnel',
              segment: lilyvaleTunnel,
              type: 'tunnel'
            },
            lilyvaleTunnelHelensburghDeviationEnd
          ]
        },
        helensburghDeviationEndOrtforDeviationStart
      ],
      history: {
        opened: {
          date: '1888-10-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-05-30',
          tracks: 2,
        }, {
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        otfordOtfordDevationStart,
        {
          date: '1920-10-10',
          original: [
            otfordOldStartOtfordTunnel,
            {
              name: 'Otford Tunnel',
              segment: otfordTunnel,
              type: 'tunnel'
            },
            otfordTunnelOtfordOldEnd
          ],
          deviation: [
            otfordDeviationStartBaldHillTunnel,
            {
              name: 'Bald Hill Tunnel',
              segment: baldHillTunnel,
              type: 'tunnel'
            },
            baldHillTunnelTinTunnel,
            tinTunnel, //rockfall shelter
            tinTunnelStanwellParkTunnel,
            {
              name: 'StanwellPark Tunnel',
              segment: stanwellParkTunnel,
              type: 'tunnel'
            },
            stanwellParkTunnelStanwellCreekTunnel,
            {
              name: 'Stanwell Creek Tunnel',
              segment: stanwellCreekTunnel,
              type: 'tunnel'
            },
            stanwellCreekTunnelOtfordDeviationEnd
          ]
        },
        otfordDeviationEndCoalcliff
      ],
      history: {
        opened: {
          date: '1888-10-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1920-10-10',
          tracks: 2
        }]
      }
    },
    {
      segments: [coalCliffCliftonTunnel],
      history: {
        opened: {
          date: '1888-07-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1915-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        cliftonTunnelSingleTrack,
        {
          name: 'Clifton Tunnel',
          segment: cliftonTunnel,
          type: 'tunnel'
        },
        cliftonTunnelClifton
      ],
      history: {
        opened: {
          date: '1888-07-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [cliftonWoolongong],
      history: {
        opened: {
          date: '1887-06-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1915-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [conistonJunctionWoolongong],
      history: {
        opened: {
          date: '1887-11-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1940-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [woolongongUnanderraJunction],
      history: {
        opened: {
          date: '1887-11-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1988-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [unanderraJunctionConistonJunction],
      history: {
        opened: {
          date: '1887-11-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        daptoCroomTunnel,
        {
          name: 'Croom Tunnel',
          segment: croomTunnel,
          type: 'tunnel'
        },
        croomTunnelBombo
      ],
      history: {
        opened: {
          date: '1887-11-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2001-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        bomboKiamaTunnel,
        {
          name: 'Kiama Tunnel',
          segment: kiamaTunnel,
          type: 'tunnel'
        },
        kiamaTunnelKiama
      ],
      history: {
        opened: {
          date: '1893-06-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2001-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        kiamaOmegaNo1Tunnel,
        {
          name: 'Omega No 1 Tunnel',
          segment: omegaNo1Tunnel,
          type: 'tunnel'
        },
        omegaNo1TunnelOmegaNo2Tunnel,
        {
          name: 'Omega No 2 Tunnel',
          segment: omegaNo2Tunnel,
          type: 'tunnel'
        },
        omegaNo2TunnelOmegaNo3Tunnel,
        {
          name: 'Omega No 3 Tunnel',
          segment: omegaNo3Tunnel,
          type: 'tunnel'
        },
        omegaNo3TunnelOmegaNo4Tunnel,
        {
          name: 'Omega No 4 Tunnel',
          segment: omegaNo4Tunnel,
          type: 'tunnel'
        },
        omegaNo4TunnelBomaderry
      ],
      history: {
        opened: {
          date: '1893-06-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
