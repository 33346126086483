import { Line } from "../../trackTypes";

const stirlingBungalaSolar = [
  [-32.5003, 137.8313],
  [-32.5000, 137.8322],
  [-32.4995, 137.8328],
  [-32.4989, 137.8332],
  [-32.4980, 137.8333],
  [-32.4728, 137.8267],
  [-32.4696, 137.8267],
  [-32.4664, 137.8278],
  [-32.4260, 137.8494],
  [-32.4234, 137.8502],
  [-32.3941, 137.8540]
];

const bungalaSolarBrachina = [
  [-32.3941, 137.8540],
  [-32.2757, 137.8695],
  [-32.1633, 137.8840],
  [-32.0909, 137.8933],
  [-32.0871, 137.8951],
  [-32.0154, 137.9625],
  [-31.9123, 138.0590],
  [-31.8078, 138.1305],
  [-31.7852, 138.1531],
  [-31.7263, 138.1954],
  [-31.6822, 138.2300],
  [-31.6083, 138.2597],
  [-31.6032, 138.2597],
  [-31.5889, 138.2569],
  [-31.5684, 138.2466],
  [-31.5636, 138.2462],
  [-31.5584, 138.2478],
  [-31.4867, 138.3086],
  [-31.3654, 138.4117],
  [-31.3626, 138.4135],
  [-31.3598, 138.4146],
  [-31.3556, 138.4149],
  [-31.3333, 138.4129]
];

const brachinaNorthTelfordCut = [
  [-31.3333, 138.4129],
  [-31.3264, 138.4122],
  [-31.2249, 138.4029],
  [-31.1244, 138.3935],
  [-31.0786, 138.3893],
  [-31.0767, 138.3887],
  [-31.0037, 138.3615],
  [-30.9179, 138.3294],
  [-30.8742, 138.3256],
  [-30.8719, 138.3260],
  [-30.8515, 138.3325],
  [-30.8476, 138.3324],
  [-30.8448, 138.3312],
  [-30.8231, 138.3149],
  [-30.8208, 138.3140],
  [-30.8176, 138.3136],
  [-30.8002, 138.3167],
  [-30.7974, 138.3178],
  [-30.7895, 138.3234],
  [-30.7868, 138.3265],
  [-30.7754, 138.3445],
  [-30.7733, 138.3467],
  [-30.7570, 138.3577],
  [-30.7409, 138.3637],
  [-30.7373, 138.3661],
  [-30.7323, 138.3709],
  [-30.7276, 138.3739],
  [-30.7140, 138.3791],
  [-30.7020, 138.3797],
  [-30.6983, 138.3811],
  [-30.6959, 138.3833],
  [-30.6940, 138.3861],
  [-30.6897, 138.3959],
  [-30.6878, 138.3988],
  [-30.6762, 138.4099],
  [-30.6736, 138.4112],
  [-30.6700, 138.4117],
  [-30.6675, 138.4112],
  [-30.6411, 138.3972],
  [-30.6273, 138.3890],
  [-30.6246, 138.3884],
  [-30.6210, 138.3888],
  [-30.6124, 138.3915],
  [-30.6100, 138.3929],
  [-30.6006, 138.4012],
  [-30.5756, 138.4230],
  [-30.5731, 138.4242],
  [-30.5704, 138.4246],
  [-30.5524, 138.4246],
  [-30.5496, 138.4254],
  [-30.5478, 138.4264],
  [-30.5396, 138.4326],
  [-30.5193, 138.4421],
  [-30.5110, 138.4438],
  [-30.4784, 138.4423],
  [-30.4727, 138.4419],
  [-30.4690, 138.4409],
  [-30.4652, 138.4387],
  [-30.4618, 138.4356],
  [-30.4611, 138.4340],
  [-30.4611, 138.4321],
  [-30.4620, 138.4300],
  [-30.4637, 138.4287],
  [-30.4652, 138.4287],
  [-30.4664, 138.4292],
  [-30.4672, 138.4299],
  [-30.4756, 138.4407],
  [-30.4768, 138.4418],
  [-30.4784, 138.4423]
];

const telfordCutMarree = [
  [-30.4618, 138.4356],
  [-30.4433, 138.4179],
  [-30.4394, 138.4163],
  [-30.4362, 138.4162],
  [-30.4303, 138.4171],
  [-30.4136, 138.4152],
  [-30.4098, 138.4139],
  [-30.4006, 138.4082],
  [-30.3829, 138.3907],
  [-30.3789, 138.3884],
  [-30.3197, 138.3740],
  [-30.2732, 138.3576],
  [-30.2312, 138.3525],
  [-30.1710, 138.3248],
  [-30.1334, 138.3035],
  [-30.0552, 138.2802],
  [-30.0359, 138.2767],
  [-29.9783, 138.2412],
  [-29.8988, 138.2050],
  [-29.8960, 138.2027],
  [-29.8937, 138.1998],
  [-29.8921, 138.1965],
  [-29.8890, 138.1883],
  [-29.8874, 138.1853],
  [-29.8627, 138.1537],
  [-29.8598, 138.1512],
  [-29.8294, 138.1320],
  [-29.8071, 138.1182],
  [-29.7901, 138.1135],
  [-29.7870, 138.1120],
  [-29.7563, 138.0908],
  [-29.7238, 138.0830],
  [-29.6565, 138.0730],
  [-29.6538, 138.0716],
  [-29.6511, 138.0694],
  [-29.6469, 138.0641]
];

export const marree: Line = {
  name: 'Marree',
  state: 'SA',
  segments: [
    {
      segments: [stirlingBungalaSolar],
      history: {
        opened: {
          date: '1955-05-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-07-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bungalaSolarBrachina],
      history: {
        opened: {
          date: '1955-05-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2016-04-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brachinaNorthTelfordCut],
      history: {
        opened: {
          date: '1956-05-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2016-04-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [telfordCutMarree],
      history: {
        opened: {
          date: '1957-07-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-06-10',
          status: 'closed'
        }]
      }
    }
  ]
};
