import { Line } from "../../trackTypes";

const binnawayJunctionGapJunction = [
  [-31.5638, 149.3829],
  [-31.5652, 149.3848],
  [-31.5667, 149.3893],
  [-31.5669, 149.3908],
  [-31.5667, 149.3920],
  [-31.5616, 149.4058],
  [-31.5610, 149.4084],
  [-31.5576, 149.4280],
  [-31.5569, 149.4303],
  [-31.5531, 149.4393],
  [-31.5523, 149.4446],
  [-31.5534, 149.4534],
  [-31.5540, 149.4553],
  [-31.5558, 149.4589],
  [-31.5573, 149.4605],
  [-31.5589, 149.4609],
  [-31.5676, 149.4606],
  [-31.5690, 149.4610],
  [-31.5701, 149.4619],
  [-31.5839, 149.4844],
  [-31.6024, 149.5276],
  [-31.6035, 149.5297],
  [-31.6133, 149.5442],
  [-31.6254, 149.5552],
  [-31.6271, 149.5579],
  [-31.6310, 149.5677],
  [-31.6440, 149.5927],
  [-31.6449, 149.5964],
  [-31.6446, 149.6097],
  [-31.6428, 149.6323],
  [-31.6402, 149.6489],
  [-31.6361, 149.6671],
  [-31.6267, 149.6944],
  [-31.6260, 149.6978],
  [-31.6240, 149.7025],
  [-31.6220, 149.7049],
  [-31.6128, 149.7114],
  [-31.6110, 149.7138],
  [-31.6105, 149.7159],
  [-31.6105, 149.7235],
  [-31.6097, 149.7266],
  [-31.6036, 149.7396],
  [-31.6006, 149.7435],
  [-31.5986, 149.7452],
  [-31.5972, 149.7473],
  [-31.5966, 149.7493],
  [-31.5956, 149.7509],
  [-31.5944, 149.7518],
  [-31.5932, 149.7524],
  [-31.5919, 149.7538],
  [-31.5914, 149.7556],
  [-31.5915, 149.7575],
  [-31.5905, 149.7605],
  [-31.5888, 149.7618],
  [-31.5868, 149.7624],
  [-31.5854, 149.7634],
  [-31.5783, 149.7735],
  [-31.5760, 149.7789],
  [-31.5754, 149.7813],
  [-31.5741, 149.7832],
  [-31.5726, 149.7840],
  [-31.5706, 149.7842],
  [-31.5654, 149.7859],
  [-31.5639, 149.7872],
  [-31.5634, 149.7884],
  [-31.5629, 149.7901],
  [-31.5617, 149.7919],
  [-31.5605, 149.7926],
  [-31.5588, 149.7934],
  [-31.5576, 149.7942],
  [-31.5553, 149.7966],
  [-31.5514, 149.7987],
  [-31.5467, 149.7996],
  [-31.5455, 149.8002],
  [-31.5446, 149.8011],
  [-31.5394, 149.8099],
  [-31.5383, 149.8109],
  [-31.5368, 149.8119],
  [-31.5353, 149.8139],
  [-31.5350, 149.8162],
  [-31.5362, 149.8218],
  [-31.5363, 149.8239],
  [-31.5359, 149.8290],
  [-31.5355, 149.8309],
  [-31.5321, 149.8406],
  [-31.5303, 149.8424],
  [-31.5288, 149.8427],
  [-31.5267, 149.8425],
  [-31.5253, 149.8427],
  [-31.5242, 149.8433],
  [-31.5189, 149.8480],
  [-31.5178, 149.8495],
  [-31.5167, 149.8513],
  [-31.5149, 149.8528],
  [-31.5136, 149.8529],
  [-31.5121, 149.8526],
  [-31.5104, 149.8532],
  [-31.5096, 149.8547],
  [-31.5094, 149.8563],
  [-31.5088, 149.8579],
  [-31.5080, 149.8587],
  [-31.5022, 149.8615],
  [-31.5008, 149.8616],
  [-31.4993, 149.8606],
  [-31.4986, 149.8589],
  [-31.4980, 149.8533],
  [-31.4981, 149.8520],
  [-31.4990, 149.8499],
  [-31.4991, 149.8482],
  [-31.4981, 149.8465],
  [-31.4966, 149.8459],
  [-31.4953, 149.8462],
  [-31.4936, 149.8477],
  [-31.4923, 149.8483],
  [-31.4895, 149.8492],
  [-31.4888, 149.8491],
  [-31.4880, 149.8488],
  [-31.4872, 149.8484],
  [-31.4859, 149.8484],
  [-31.4847, 149.8493],
  [-31.4841, 149.8508],
  [-31.4837, 149.8529],
  [-31.4829, 149.8546],
  [-31.4813, 149.8564],
  [-31.4806, 149.8581],
  [-31.4806, 149.8594],
  [-31.4810, 149.8612],
  [-31.4811, 149.8630],
  [-31.4806, 149.8658],
  [-31.4793, 149.8684],
  [-31.4753, 149.8742],
  [-31.4746, 149.8763],
  [-31.4749, 149.8785],
  [-31.4762, 149.8810],
  [-31.4773, 149.8844],
  [-31.4774, 149.8872],
  [-31.4760, 149.8970],
  [-31.4746, 149.8997],
  [-31.4723, 149.9014],
  [-31.4701, 149.9016],
  [-31.4636, 149.8998],
  [-31.4615, 149.8998],
  [-31.4548, 149.9019],
  [-31.4533, 149.9038],
  [-31.4528, 149.9058],
  [-31.4589, 149.9492],
  [-31.4586, 149.9699],
  [-31.4549, 149.9856],
  [-31.4540, 149.9878],
  [-31.4482, 149.9999],
  [-31.4475, 150.0024],
  [-31.4432, 150.0253],
  [-31.4432, 150.0283],
  [-31.4450, 150.0365],
  [-31.4642, 150.1073],
  [-31.4636, 150.1127],
  [-31.4371, 150.1625],
  [-31.4189, 150.1869],
  [-31.4120, 150.1995],
  [-31.4111, 150.2024],
  [-31.4089, 150.2262],
  [-31.4080, 150.2294],
  [-31.3883, 150.2770],
  [-31.3878, 150.2808],
  [-31.3883, 150.2835],
  [-31.3897, 150.2869],
  [-31.3906, 150.2902],
  [-31.3961, 150.3288],
  [-31.4073, 150.3780],
  [-31.4082, 150.3895],
  [-31.4079, 150.3921],
  [-31.4069, 150.3946],
  [-31.4062, 150.3975],
  [-31.4063, 150.4000],
  [-31.4078, 150.4099],
  [-31.4072, 150.4136],
  [-31.4033, 150.4212],
  [-31.3958, 150.4286],
  [-31.3939, 150.4315],
  [-31.3686, 150.4922],
  [-31.3678, 150.4956],
  [-31.3669, 150.5148],
  [-31.3670, 150.5179],
  [-31.3686, 150.5293],
  [-31.3685, 150.5320],
  [-31.3605, 150.5658],
  [-31.3604, 150.5679],
  [-31.3605, 150.5727],
  [-31.3596, 150.5768],
  [-31.3579, 150.5790],
  [-31.3562, 150.5806],
  [-31.3553, 150.5823],
  [-31.3552, 150.5841],
  [-31.3556, 150.5873],
  [-31.3561, 150.5888],
  [-31.3570, 150.5906],
  [-31.3572, 150.5922],
  [-31.3569, 150.5935],
  [-31.3562, 150.5948],
  [-31.3559, 150.5957]
];

const gapJunctionWerrisCreekSouthJunction = [
  [-31.3543, 150.6035],
  [-31.3543, 150.6054],
  [-31.3560, 150.6103],
  [-31.3576, 150.6128],
  [-31.3600, 150.6145],
  [-31.3698, 150.6177],
  [-31.3719, 150.6191],
  [-31.3730, 150.6207],
  [-31.3741, 150.6242],
  [-31.3741, 150.6272],
  [-31.3734, 150.6291],
  [-31.3652, 150.6433],
  [-31.3645, 150.6440],
  [-31.3635, 150.6446]
];

export const binnawayWerrisCreek: Line = {
  name: 'Binnaway - Werris Creek',
  state: 'NSW',
  segments: [
    {
      segments: [binnawayJunctionGapJunction],
      history: {
        opened: {
          date: '1923-10-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [gapJunctionWerrisCreekSouthJunction],
      history: {
        opened: {
          date: '1923-10-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-30',
          status: 'closed'
        }]
      }
    }
  ]
};
