import { Line } from "../../../trackTypes";

const kooWeeRupBayles = [
  [-38.2015, 145.4935],
  [-38.2017, 145.4951],
  [-38.2015, 145.4963],
  [-38.1978, 145.5049],
  [-38.1878, 145.5289],
  [-38.1872, 145.5313],
  [-38.1853, 145.5459],
  [-38.1786, 145.5661],
  [-38.1786, 145.5705]
];

const baylesYannathan = [
  [-38.1786, 145.5705],
  [-38.1787, 145.5721],
  [-38.1796, 145.5782],
  [-38.1867, 145.6347],
  [-38.1873, 145.6365],
  [-38.1883, 145.6375],
  [-38.1956, 145.6449],
  [-38.1978, 145.6467],
  [-38.2069, 145.6528],
  [-38.2109, 145.6567],
  [-38.2210, 145.6619]
];

const yannathanTriholm = [
  [-38.2210, 145.6619],
  [-38.2234, 145.6632],
  [-38.2254, 145.6647],
  [-38.2395, 145.6835],
  [-38.2425, 145.6876],
  [-38.2431, 145.6891],
  [-38.2436, 145.6957],
  [-38.2443, 145.6974],
  [-38.2449, 145.6981],
  [-38.2462, 145.6983],
  [-38.2473, 145.6988],
  [-38.2511, 145.7016],
  [-38.2520, 145.7024],
  [-38.2535, 145.7048],
  [-38.2563, 145.7152],
  [-38.2562, 145.7174],
  [-38.2558, 145.7192],
  [-38.2537, 145.7236],
  [-38.2532, 145.7250],
  [-38.2508, 145.7390],
  [-38.2505, 145.7402],
  [-38.2495, 145.7426],
  [-38.2492, 145.7445],
  [-38.2491, 145.7468],
  [-38.2487, 145.7484],
  [-38.2468, 145.7526],
  [-38.2458, 145.7537],
  [-38.2450, 145.7542],
  [-38.2443, 145.7552],
  [-38.2386, 145.7655],
  [-38.2380, 145.7678],
  [-38.2380, 145.7700],
  [-38.2391, 145.7799],
  [-38.2398, 145.7829],
  [-38.2415, 145.7855],
  [-38.2436, 145.7876],
  [-38.2470, 145.7883],
  [-38.2488, 145.7881],
  [-38.2500, 145.7884],
  [-38.2577, 145.7916],
  [-38.2691, 145.8005],
  [-38.2699, 145.8012],
  [-38.2704, 145.8020],
  [-38.2719, 145.8058],
  [-38.2726, 145.8067],
  [-38.2786, 145.8134],
  [-38.2798, 145.8143],
  [-38.2812, 145.8147],
  [-38.2824, 145.8147],
  [-38.2830, 145.8147],
  [-38.2840, 145.8152],
  [-38.2871, 145.8175],
  [-38.2883, 145.8181],
  [-38.2940, 145.8198],
  [-38.2953, 145.8205],
  [-38.2962, 145.8213],
  [-38.2983, 145.8237],
  [-38.3008, 145.8262],
  [-38.3013, 145.8266],
  [-38.3092, 145.8314],
  [-38.3107, 145.8316],
  [-38.3151, 145.8304]
];

const triholmStrzelecki = [
  [-38.3151, 145.8304],
  [-38.3157, 145.8304],
  [-38.3163, 145.8307],
  [-38.3186, 145.8323],
  [-38.3192, 145.8326],
  [-38.3203, 145.8328],
  [-38.3217, 145.8343],
  [-38.3223, 145.8344],
  [-38.3230, 145.8345],
  [-38.3238, 145.8349],
  [-38.3247, 145.8349],
  [-38.3254, 145.8350],
  [-38.3260, 145.8354],
  [-38.3265, 145.8362],
  [-38.3272, 145.8379],
  [-38.3273, 145.8386],
  [-38.3270, 145.8396],
  [-38.3244, 145.8471],
  [-38.3241, 145.8488],
  [-38.3242, 145.8504],
  [-38.3242, 145.8520],
  [-38.3235, 145.8564],
  [-38.3236, 145.8576],
  [-38.3239, 145.8584],
  [-38.3244, 145.8591],
  [-38.3246, 145.8600],
  [-38.3246, 145.8609],
  [-38.3246, 145.8615],
  [-38.3248, 145.8624],
  [-38.3248, 145.8630],
  [-38.3243, 145.8646],
  [-38.3240, 145.8652],
  [-38.3231, 145.8659],
  [-38.3227, 145.8664],
  [-38.3227, 145.8673],
  [-38.3229, 145.8681],
  [-38.3236, 145.8691],
  [-38.3240, 145.8700],
  [-38.3240, 145.8712],
  [-38.3242, 145.8720],
  [-38.3247, 145.8728],
  [-38.3249, 145.8738],
  [-38.3251, 145.8751],
  [-38.3254, 145.8759],
  [-38.3255, 145.8767],
  [-38.3250, 145.8802],
  [-38.3251, 145.8815],
  [-38.3254, 145.8827],
  [-38.3254, 145.8837],
  [-38.3250, 145.8846],
  [-38.3239, 145.8855],
  [-38.3233, 145.8864],
  [-38.3231, 145.8875],
  [-38.3233, 145.8883],
  [-38.3239, 145.8896],
  [-38.3241, 145.8906],
  [-38.3233, 145.8945],
  [-38.3233, 145.8958],
  [-38.3232, 145.8985],
  [-38.3227, 145.8998],
  [-38.3228, 145.9014],
  [-38.3234, 145.9021],
  [-38.3246, 145.9025],
  [-38.3275, 145.9022],
  [-38.3283, 145.9024],
  [-38.3296, 145.9029]
];

export const strzelecki: Line = {
  name: 'Strzelecki',
  state: 'VIC',
  segments: [
    {
      segments: [kooWeeRupBayles],
      history: {
        opened: {
          date: '1922-06-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-02-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [baylesYannathan],
      history: {
        opened: {
          date: '1922-06-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1950-04-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yannathanTriholm],
      history: {
        opened: {
          date: '1922-06-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1941-08-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [triholmStrzelecki],
      history: {
        opened: {
          date: '1922-06-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-11-22',
          status: 'closed'
        }]
      }
    }
  ]
}
