import { Line } from "../../../trackTypes";

const romaStreetChelmer = [
  [-27.4650, 153.0190],
  [-27.4649, 153.0180],
  [-27.4649, 153.0169],
  [-27.4660, 153.0136],
  [-27.4694, 153.0036],
  [-27.4701, 153.0023],
  [-27.4707, 153.0018],
  [-27.4721, 153.0006],
  [-27.4745, 152.9980],
  [-27.4754, 152.9972],
  [-27.4762, 152.9968],
  [-27.4784, 152.9963],
  [-27.4796, 152.9958],
  [-27.4829, 152.9940],
  [-27.4845, 152.9933],
  [-27.4862, 152.9928],
  [-27.4871, 152.9923],
  [-27.4877, 152.9916],
  [-27.4887, 152.9894],
  [-27.4927, 152.9826],
  [-27.4934, 152.9806],
  [-27.4939, 152.9783],
  [-27.4945, 152.9773],
  [-27.4954, 152.9766],
  [-27.4962, 152.9764],
  [-27.5010, 152.9765],
  [-27.5026, 152.9760],
  [-27.5040, 152.9749],
  [-27.5049, 152.9743],
  [-27.5081, 152.9730],
  [-27.5120, 152.9727]
];

const chelmerSherwood = [
  [-27.5120, 152.9727],
  [-27.5144, 152.9730],
  [-27.5264, 152.9786],
  [-27.5281, 152.9791],
  [-27.5310, 152.9794]
];

const sherwoodCorinda = [
  [-27.5310, 152.9794],
  [-27.5363, 152.9801],
  [-27.5374, 152.9802],
  [-27.5382, 152.9801],
  [-27.5390, 152.9800]
];

const corindaDarra = [
  [-27.5390, 152.9800],
  [-27.5479, 152.9788],
  [-27.5491, 152.9782],
  [-27.5502, 152.9769],
  [-27.5508, 152.9753],
  [-27.5515, 152.9743],
  [-27.5530, 152.9732],
  [-27.5544, 152.9718],
  [-27.5559, 152.9698],
  [-27.5564, 152.9685],
  [-27.5567, 152.9666],
  [-27.5572, 152.9656],
  [-27.5577, 152.9648],
  [-27.5635, 152.9600],
  [-27.5646, 152.9585],
  [-27.5652, 152.9567],
  [-27.5659, 152.9522],
  [-27.5663, 152.9511],
  [-27.5683, 152.9474]
];

const darraIpswich = [
  [-27.5683, 152.9474],
  [-27.5697, 152.9447],
  [-27.5715, 152.9372],
  [-27.5724, 152.9355],
  [-27.5738, 152.9340],
  [-27.5906, 152.9234],
  [-27.5928, 152.9225],
  [-27.6019, 152.9193],
  [-27.6032, 152.9184],
  [-27.6044, 152.9169],
  [-27.6081, 152.9101],
  [-27.6086, 152.9082],
  [-27.6088, 152.9023],
  [-27.6085, 152.9003],
  [-27.6071, 152.8969],
  [-27.6057, 152.8953],
  [-27.6048, 152.8940],
  [-27.6025, 152.8888],
  [-27.6017, 152.8876],
  [-27.6004, 152.8865],
  [-27.5994, 152.8850],
  [-27.5982, 152.8799],
  [-27.5982, 152.8785],
  [-27.6008, 152.8649],
  [-27.6007, 152.8626],
  [-27.5968, 152.8537],
  [-27.5961, 152.8527],
  [-27.5954, 152.8518],
  [-27.5950, 152.8506],
  [-27.5948, 152.8495],
  [-27.5947, 152.8480],
  [-27.5951, 152.8401],
  [-27.5959, 152.8346],
  [-27.5969, 152.8314],
  [-27.5982, 152.8287],
  [-27.6026, 152.8231],
  [-27.6037, 152.8213],
  [-27.6072, 152.8132],
  [-27.6077, 152.8108],
  [-27.6074, 152.8040],
  [-27.6076, 152.8023],
  [-27.6085, 152.7992],
  [-27.6088, 152.7948],
  [-27.6102, 152.7782],
  [-27.6099, 152.7744],
  [-27.6100, 152.7731],
  [-27.6105, 152.7719],
  [-27.6118, 152.7703],
  [-27.6127, 152.7687],
  [-27.6131, 152.7669],
  [-27.6129, 152.7604]
];

const ipswichWulkuraka = [
  [-27.6129, 152.7604],
  [-27.6129, 152.7584],
  [-27.6133, 152.7568],
  [-27.6140, 152.7544],
  [-27.6148, 152.7526],
  [-27.6154, 152.7503],
  [-27.6155, 152.7493],
  [-27.6153, 152.7485],
  [-27.6149, 152.7472],
  [-27.6148, 152.7464],
  [-27.6144, 152.7393],
  [-27.6144, 152.7361],
  [-27.6136, 152.7328]
];

const wulkurakaRosewood = [
  [-27.6136, 152.7328],
  [-27.6116, 152.7246],
  [-27.6108, 152.7213],
  [-27.6034, 152.7072],
  [-27.6031, 152.7060],
  [-27.6029, 152.7036],
  [-27.6026, 152.7024],
  [-27.6018, 152.7013],
  [-27.5996, 152.6992],
  [-27.5988, 152.6978],
  [-27.5981, 152.6946],
  [-27.5982, 152.6932],
  [-27.5986, 152.6921],
  [-27.6046, 152.6826],
  [-27.6058, 152.6790],
  [-27.6075, 152.6665],
  [-27.6083, 152.6634],
  [-27.6098, 152.6603],
  [-27.6194, 152.6449],
  [-27.6388, 152.6049],
  [-27.6431, 152.5925]
];

export const rosewood: Line = {
  name: 'Rosewood',
  state: 'QLD',
  segments: [
    {
      segments: [romaStreetChelmer],
      history: {
        opened: {
          date: '1876-07-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1887-01-01',
          tracks: 2
        }, {
          date: '1963-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [chelmerSherwood],
      history: {
        opened: {
          date: '1875-02-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1887-01-01',
          tracks: 2
        }, {
          date: '1963-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [sherwoodCorinda],
      history: {
        opened: {
          date: '1874-10-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1887-01-01',
          tracks: 2
        }, {
          date: '1963-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [corindaDarra],
      history: {
        opened: {
          date: '1874-10-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1887-01-01',
          tracks: 2
        }, {
          date: '2011-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [darraIpswich],
      history: {
        opened: {
          date: '1874-10-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-09-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1887-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [ipswichWulkuraka],
      history: {
        opened: {
          date: '1865-07-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1902-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [wulkurakaRosewood],
      history: {
        opened: {
          date: '1865-07-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1913-01-01',
          tracks: 2
        }]
      }
    }
  ]
}
