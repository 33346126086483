import { Line } from "../../../trackTypes";

const benallaOilSiding = [
  [-36.5427, 145.9887],
  [-36.5426, 145.9896],
  [-36.5424, 145.9902],
  [-36.5422, 145.9908],
  [-36.5421, 145.9920],
  [-36.5423, 145.9930],
  [-36.5430, 145.9942],
  [-36.5438, 145.9949],
  [-36.5454, 145.9953],
  [-36.5483, 145.9947]
];

const oilSidingTatong = [
  [-36.5483, 145.9947],
  [-36.5540, 145.9935],
  [-36.5552, 145.9936],
  [-36.5561, 145.9930],
  [-36.5642, 145.9910],
  [-36.5699, 145.9901],
  [-36.5717, 145.9900],
  [-36.5746, 145.9908],
  [-36.5789, 145.9902],
  [-36.6042, 145.9847],
  [-36.6522, 146.0018],
  [-36.6543, 146.0020],
  [-36.6621, 146.0019],
  [-36.6635, 146.0015],
  [-36.6680, 145.9995],
  [-36.6776, 145.9984],
  [-36.6803, 145.9991],
  [-36.6828, 146.0006],
  [-36.6869, 146.0020],
  [-36.7084, 146.0065],
  [-36.7116, 146.0093],
  [-36.7215, 146.0167],
  [-36.7229, 146.0193],
  [-36.7242, 146.0269],
  [-36.7252, 146.0428],
  [-36.7251, 146.0641],
  [-36.7246, 146.0739],
  [-36.7247, 146.0873],
  [-36.7251, 146.0892],
  [-36.7261, 146.0907],
  [-36.7341, 146.0981],
  [-36.7348, 146.0997],
  [-36.7347, 146.1011]
];

export const tatong: Line = {
  name: 'Tatong',
  state: 'VIC',
  segments: [
    {
      segments: [benallaOilSiding],
      history: {
        opened: {
          date: '1914-06-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-04-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oilSidingTatong],
      history: {
        opened: {
          date: '1914-06-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1947-07-01',
          status: 'closed'
        }]
      }
    }
  ]
};
