import { Line } from "../../trackTypes";

const waurnPondsTorquayDeviationStart = [
  [-38.2159, 144.3061],
  [-38.2139, 144.3190],
  [-38.2138, 144.3206],
  [-38.2137, 144.3219],
  [-38.2140, 144.3270],
  [-38.2138, 144.3292],
  [-38.2133, 144.3315],
];

const torquayRoadOld = [
  [-38.2133, 144.3315],
  [-38.2120, 144.3346],
  [-38.2117, 144.3356],
  [-38.2113, 144.3372],
  [-38.2102, 144.3444]
];

const torquayRoadDeviation = [
  [-38.2133, 144.3315],
  [-38.2102, 144.3444]
];

const torquayRoadDeviationEndMarshall = [
  [-38.2102, 144.3444],
  [-38.2096, 144.3463],
  [-38.2087, 144.3480],
  [-38.2077, 144.3493],
  [-38.2066, 144.3502],
  [-38.1986, 144.3549],
  [-38.1971, 144.3558]
];

const marshallBreakwaterRoad = [
  [-38.1971, 144.3558],
  [-38.1793, 144.3663]
];

const breakwaterRoadSouthGeelong = [
  [-38.1793, 144.3663],
  [-38.1724, 144.3704],
  [-38.1706, 144.3712],
  [-38.1688, 144.3713],
  [-38.1675, 144.3708],
  [-38.1664, 144.3701],
  [-38.1653, 144.3688],
  [-38.1584, 144.3590],
  [-38.1579, 144.3583]
]

const southGeelongTunnelPortal = [
  [-38.1579, 144.3583],
  [-38.1548, 144.3539],
  [-38.1537, 144.3530],
  [-38.1528, 144.3527],
  [-38.1519, 144.3527],
  [-38.1511, 144.3530]
];

const tunnel = [
  [-38.1511, 144.3530],
  [-38.1474, 144.3541]
];

const tunnelPortalGeelong = [
  [-38.1474, 144.3541],
  [-38.1465, 144.3544],
  [-38.1443, 144.3548]
];

const geelongNorthGeelongA = [
  [-38.1443, 144.3548],
  [-38.1431, 144.3548],
  [-38.1422, 144.3547],
  [-38.1384, 144.3534],
  [-38.1378, 144.3530],
  [-38.1367, 144.3520],
  [-38.1361, 144.3516],
  [-38.1336, 144.3507],
  [-38.1325, 144.3505],
  [-38.1317, 144.3505],
  [-38.1224, 144.3523],
  [-38.1212, 144.3525]
];

const northGeelonANorthGeelongB = [
  [-38.1212, 144.3525],
  [-38.1113, 144.3545],
  [-38.1107, 144.3547]
];

const northGeelongBCorio = [
  [-38.1107, 144.3547],
  [-38.1099, 144.3551],
  [-38.1092, 144.3556],
  [-38.1045, 144.3593],
  [-38.1035, 144.3603],
  [-38.1006, 144.3641],
  [-38.0998, 144.3647],
  [-38.0988, 144.3652],
  [-38.0948, 144.3665],
  [-38.0723, 144.3798]
];

const corioLara = [
  [-38.0723, 144.3798],
  [-38.0325, 144.4039],
  [-38.0219, 144.4145]
];

const laraLittleRiver = [
  [-38.0219, 144.4145],
  [-38.0192, 144.4173],
  [-38.0163, 144.4208],
  [-37.9810, 144.4692],
  [-37.9740, 144.4788],
  [-37.9688, 144.4868],
  [-37.9656, 144.4929],
  [-37.9628, 144.4988]
];

const littleRiverWerribee = [
  [-37.9628, 144.4988],
  [-37.9589, 144.5073],
  [-37.9277, 144.5883],
  [-37.8995, 144.6608]
];

export const geelong: Line = {
  name: 'Geelong',
  state: 'VIC',
  segments: [
    {
      segments: [
        waurnPondsTorquayDeviationStart,
        {
          date: '2024-08-25',
          original: [torquayRoadOld],
          deviation: [torquayRoadDeviation]
        },
        torquayRoadDeviationEndMarshall,
        breakwaterRoadSouthGeelong
      ],
      history: {
        opened: {
          date: '1876-11-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2024-08-25',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        marshallBreakwaterRoad,
        southGeelongTunnelPortal,
        {
          name: 'Geelong Tunnel',
          segment: tunnel,
          type: 'tunnel'
        },
        tunnelPortalGeelong
      ],
      history: {
        opened: {
          date: '1876-11-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [geelongNorthGeelongA],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1863-04-11',
          tracks: 2
        }]
      }
    },
    {
      segments: [northGeelonANorthGeelongB],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1922-04-30',
          tracks: 2
        }]
      }
    },
    {
      segments: [northGeelongBCorio],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-02-08',
          tracks: 2
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [corioLara],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-09-19',
          tracks: 2
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [laraLittleRiver],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-07-25',
          tracks: 2
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [littleRiverWerribee],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-10-25',
          tracks: 2
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    }
  ]
}
