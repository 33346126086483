import { Line } from "../../../../trackTypes";

const prospect = [
  [-34.90218, 138.59454],
  [-34.90118, 138.59432],
  [-34.90060, 138.59436],
  [-34.89950, 138.59471],
  [-34.89903, 138.59481],
  [-34.88393, 138.59381]
];

const connector = [
  [-34.88393, 138.59381],
  [-34.88385, 138.59386],
  [-34.88383, 138.59393],
  [-34.88341, 138.60266]
];

const farrantStreet = [
  [-34.88393, 138.59381],
  [-34.87918, 138.59348]
];

const regencyRd = [
  [-34.87918, 138.59348],
  [-34.87423, 138.59313]
];

export const north4: Line = {
  name: 'North4',
  state: 'SA',
  segments: [
    {
      segments: [prospect],
      history: {
        opened: {
          date: '1883-09-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [connector],
      history: {
        opened: {
          date: '1883-09-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [farrantStreet],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [regencyRd],
      history: {
        opened: {
          date: '1924-07-20',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    }
  ]
};
