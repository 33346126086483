import { Line } from "../../trackTypes";

const chidlowsWellJunction = [
  [-31.7231, 116.6549],
  [-31.7236, 116.6538],
  [-31.7249, 116.6527],
  [-31.7264, 116.6522],
  [-31.7278, 116.6526],
  [-31.7293, 116.6538]
];

const chidlowsWellYork = [
  [-31.7293, 116.6538],
  [-31.7310, 116.6567],
  [-31.7328, 116.6587],
  [-31.7490, 116.6682],
  [-31.7504, 116.6693],
  [-31.7615, 116.6822],
  [-31.7626, 116.6841],
  [-31.7723, 116.7070],
  [-31.7730, 116.7098],
  [-31.7749, 116.7235],
  [-31.7785, 116.7345],
  [-31.7989, 116.7742],
  [-31.8014, 116.7773],
  [-31.8120, 116.7866],
  [-31.8298, 116.7993],
  [-31.8327, 116.8003],
  [-31.8473, 116.7999],
  [-31.8497, 116.7993],
  [-31.8548, 116.7963],
  [-31.8562, 116.7946],
  [-31.8571, 116.7921],
  [-31.8583, 116.7811],
  [-31.8589, 116.7784],
  [-31.8600, 116.7763],
  [-31.8626, 116.7734],
  [-31.8654, 116.7710],
  [-31.8681, 116.7696],
  [-31.8728, 116.7682],
  [-31.8746, 116.7679],
  [-31.8865, 116.7672],
  [-31.8892, 116.7660],
  [-31.8905, 116.7657],
  [-31.8919, 116.7657]
];

const yorkBeverley = [
  [-31.8919, 116.7657],
  [-31.8944, 116.7658],
  [-31.8954, 116.7661],
  [-31.8972, 116.7672],
  [-31.8988, 116.7689],
  [-31.9023, 116.7739],
  [-31.9075, 116.7817],
  [-31.9097, 116.7840],
  [-31.9411, 116.8051],
  [-31.9444, 116.8060],
  [-31.9600, 116.8053],
  [-31.9617, 116.8055],
  [-31.9721, 116.8087],
  [-31.9749, 116.8086],
  [-31.9771, 116.8071],
  [-31.9797, 116.8030],
  [-31.9821, 116.8006],
  [-31.9852, 116.7998],
  [-31.9933, 116.8016],
  [-31.9974, 116.8037],
  [-32.0001, 116.8038],
  [-32.0106, 116.8002],
  [-32.0131, 116.8000],
  [-32.0393, 116.8061],
  [-32.0507, 116.8125],
  [-32.0683, 116.8184],
  [-32.0705, 116.8201],
  [-32.0858, 116.8477],
  [-32.0864, 116.8503],
  [-32.0863, 116.8814],
  [-32.0876, 116.8866],
  [-32.0977, 116.9045],
  [-32.1028, 116.9212],
  [-32.1044, 116.9234],
  [-32.1072, 116.9253]
];

const beverleyAlbany = [
  [-32.1072, 116.9253],
  [-32.1127, 116.9285],
  [-32.1178, 116.9299],
  [-32.1250, 116.9297],
  [-32.1329, 116.9305],
  [-32.1371, 116.9318],
  [-32.1439, 116.9357],
  [-32.1458, 116.9375],
  [-32.1598, 116.9542],
  [-32.1629, 116.9559],
  [-32.1714, 116.9569],
  [-32.1747, 116.9582],
  [-32.1779, 116.9588],
  [-32.1826, 116.9580],
  [-32.1852, 116.9583],
  [-32.1918, 116.9622],
  [-32.1942, 116.9632],
  [-32.2014, 116.9642],
  [-32.2043, 116.9657],
  [-32.2122, 116.9747],
  [-32.2153, 116.9768],
  [-32.2745, 116.9950],
  [-32.2789, 116.9954],
  [-32.2893, 116.9933],
  [-32.2914, 116.9926],
  [-32.3021, 116.9859],
  [-32.3057, 116.9849],
  [-32.3333, 116.9867],
  [-32.3357, 116.9878],
  [-32.3373, 116.9898],
  [-32.3394, 116.9919],
  [-32.3514, 116.9989],
  [-32.3773, 117.0158],
  [-32.3801, 117.0173],
  [-32.3905, 117.0214],
  [-32.3994, 117.0258],
  [-32.4077, 117.0317],
  [-32.4116, 117.0335],
  [-32.4274, 117.0362],
  [-32.4427, 117.0436],
  [-32.4483, 117.0450],
  [-32.4534, 117.0471],
  [-32.4558, 117.0475],
  [-32.4626, 117.0466],
  [-32.4760, 117.0474],
  [-32.4778, 117.0480],
  [-32.4982, 117.0625],
  [-32.5069, 117.0730],
  [-32.5097, 117.0751],
  [-32.5270, 117.0819],
  [-32.5337, 117.0852],
  [-32.5353, 117.0859],
  [-32.5441, 117.0886],
  [-32.5478, 117.0915],
  [-32.5500, 117.0921],
  [-32.5525, 117.0914],
  [-32.5622, 117.0832],
  [-32.5655, 117.0820],
  [-32.5692, 117.0822],
  [-32.5748, 117.0816],
  [-32.5838, 117.0815],
  [-32.5904, 117.0833],
  [-32.5929, 117.0849],
  [-32.6059, 117.1005],
  [-32.6077, 117.1020],
  [-32.6114, 117.1038],
  [-32.6139, 117.1063],
  [-32.6211, 117.1250],
  [-32.6227, 117.1272],
  [-32.6247, 117.1283],
  [-32.6290, 117.1285],
  [-32.6320, 117.1282],
  [-32.6411, 117.1242],
  [-32.6447, 117.1216],
  [-32.6469, 117.1208],
  [-32.6494, 117.1209],
  [-32.6556, 117.1238],
  [-32.6580, 117.1243],
  [-32.6640, 117.1238],
  [-32.6669, 117.1247],
  [-32.6810, 117.1346],
  [-32.6863, 117.1398],
  [-32.6973, 117.1467],
  [-32.7006, 117.1477],
  [-32.7138, 117.1478],
  [-32.7160, 117.1484],
  [-32.7183, 117.1501],
  [-32.7269, 117.1612],
  [-32.7289, 117.1628],
  [-32.7315, 117.1635],
  [-32.7485, 117.1630],
  [-32.7588, 117.1658],
  [-32.7612, 117.1672],
  [-32.7645, 117.1706],
  [-32.7667, 117.1723],
  [-32.7856, 117.1822],
  [-32.7877, 117.1830],
  [-32.7930, 117.1835],
  [-32.7954, 117.1830],
  [-32.8024, 117.1794],
  [-32.8122, 117.1771],
  [-32.8154, 117.1775],
  [-32.8251, 117.1821],
  [-32.8282, 117.1823],
  [-32.8318, 117.1808],
  [-32.8335, 117.1809],
  [-32.8351, 117.1817],
  [-32.8365, 117.1819],
  [-32.8385, 117.1810],
  [-32.8413, 117.1772],
  [-32.8426, 117.1760],
  [-32.8442, 117.1753],
  [-32.8470, 117.1752],
  [-32.8481, 117.1747],
  [-32.8494, 117.1734],
  [-32.8509, 117.1726],
  [-32.8525, 117.1725],
  [-32.8536, 117.1722],
  [-32.8548, 117.1710],
  [-32.8583, 117.1655],
  [-32.8605, 117.1652],
  [-32.8626, 117.1645],
  [-32.8666, 117.1667],
  [-32.8717, 117.1680],
  [-32.8737, 117.1671],
  [-32.8758, 117.1672],
  [-32.8823, 117.1612],
  [-32.8842, 117.1603],
  [-32.8860, 117.1607],
  [-32.8872, 117.1624],
  [-32.8877, 117.1634],
  [-32.8889, 117.1643],
  [-32.8902, 117.1645],
  [-32.8915, 117.1654],
  [-32.8923, 117.1673],
  [-32.8933, 117.1685],
  [-32.8949, 117.1691],
  [-32.9001, 117.1677],
  [-32.9019, 117.1682],
  [-32.9031, 117.1702],
  [-32.9036, 117.1726],
  [-32.9046, 117.1739],
  [-32.9060, 117.1745],
  [-32.9078, 117.1744],
  [-32.9091, 117.1747],
  [-32.9116, 117.1766],
  [-32.9131, 117.1771],
  [-32.9154, 117.1770],
  [-32.9193, 117.1783],
  [-32.9297, 117.1786],
  [-32.9320, 117.1789],
  [-32.9364, 117.1803],
  [-32.9413, 117.1816],
  [-32.9430, 117.1824],
  [-32.9467, 117.1862],
  [-32.9533, 117.1908],
  [-32.9566, 117.1918],
  [-32.9606, 117.1917],
  [-32.9702, 117.1931],
  [-32.9717, 117.1943],
  [-32.9726, 117.1962],
  [-32.9738, 117.1973],
  [-32.9754, 117.1976],
  [-32.9774, 117.1965],
  [-32.9785, 117.1962],
  [-32.9798, 117.1965],
  [-32.9882, 117.2025],
  [-33.0057, 117.2178],
  [-33.0078, 117.2193],
  [-33.0235, 117.2272],
  [-33.0256, 117.2288],
  [-33.0392, 117.2426],
  [-33.0416, 117.2441],
  [-33.0443, 117.2445],
  [-33.0583, 117.2411],
  [-33.0599, 117.2410],
  [-33.0627, 117.2416],
  [-33.0649, 117.2425],
  [-33.0986, 117.2646],
  [-33.1026, 117.2658],
  [-33.1382, 117.2632],
  [-33.1411, 117.2637],
  [-33.1569, 117.2729],
  [-33.1606, 117.2770],
  [-33.1657, 117.2875],
  [-33.1692, 117.2918],
  [-33.1711, 117.2932],
  [-33.1739, 117.2941],
  [-33.1775, 117.2939],
  [-33.1903, 117.2879],
  [-33.2013, 117.2863],
  [-33.2034, 117.2864],
  [-33.2060, 117.2874],
  [-33.2082, 117.2889],
  [-33.2101, 117.2917],
  [-33.2127, 117.3009],
  [-33.2129, 117.3036],
  [-33.2127, 117.3093],
  [-33.2131, 117.3128],
  [-33.2144, 117.3160],
  [-33.2248, 117.3311],
  [-33.2269, 117.3334],
  [-33.2297, 117.3353],
  [-33.2396, 117.3381],
  [-33.2417, 117.3379],
  [-33.2435, 117.3367],
  [-33.2449, 117.3363],
  [-33.2471, 117.3362],
  [-33.2498, 117.3352],
  [-33.2527, 117.3328],
  [-33.2542, 117.3323],
  [-33.2563, 117.3330],
  [-33.2590, 117.3355],
  [-33.2658, 117.3411],
  [-33.2668, 117.3428],
  [-33.2680, 117.3466],
  [-33.2689, 117.3482],
  [-33.2709, 117.3494],
  [-33.2733, 117.3489],
  [-33.2815, 117.3418],
  [-33.2842, 117.3409],
  [-33.2934, 117.3409],
  [-33.2974, 117.3422],
  [-33.3016, 117.3424],
  [-33.3046, 117.3431],
  [-33.3227, 117.3526],
  [-33.3258, 117.3548],
  [-33.3276, 117.3556],
  [-33.3294, 117.3556],
  [-33.3358, 117.3528],
  [-33.3371, 117.3526],
  [-33.3387, 117.3528],
  [-33.3403, 117.3536],
  [-33.3424, 117.3539],
  [-33.3462, 117.3529],
  [-33.3480, 117.3528],
  [-33.3502, 117.3532],
  [-33.3530, 117.3535],
  [-33.3550, 117.3531],
  [-33.3570, 117.3530],
  [-33.3590, 117.3538],
  [-33.3607, 117.3554],
  [-33.3627, 117.3566],
  [-33.3664, 117.3579],
  [-33.3739, 117.3615],
  [-33.3770, 117.3622],
  [-33.3844, 117.3612],
  [-33.3908, 117.3586],
  [-33.3933, 117.3583],
  [-33.3970, 117.3595],
  [-33.3996, 117.3619],
  [-33.4040, 117.3683],
  [-33.4049, 117.3695],
  [-33.4065, 117.3703],
  [-33.4134, 117.3718],
  [-33.4161, 117.3728],
  [-33.4330, 117.3852],
  [-33.4349, 117.3859],
  [-33.4373, 117.3861],
  [-33.4399, 117.3867],
  [-33.5005, 117.4123],
  [-33.5026, 117.4145],
  [-33.5078, 117.4253],
  [-33.5101, 117.4278],
  [-33.5161, 117.4310],
  [-33.5191, 117.4319],
  [-33.5304, 117.4329],
  [-33.5319, 117.4327],
  [-33.5337, 117.4318],
  [-33.5385, 117.4269],
  [-33.5415, 117.4253],
  [-33.5457, 117.4248],
  [-33.5483, 117.4253],
  [-33.5573, 117.4303],
  [-33.5591, 117.4308],
  [-33.5704, 117.4317],
  [-33.5739, 117.4331],
  [-33.5753, 117.4339],
  [-33.5808, 117.4388],
  [-33.5833, 117.4421],
  [-33.5975, 117.4734],
  [-33.6021, 117.4805],
  [-33.6031, 117.4841],
  [-33.6049, 117.5077],
  [-33.6059, 117.5108],
  [-33.6075, 117.5130],
  [-33.6106, 117.5153],
  [-33.6124, 117.5159],
  [-33.6145, 117.5160],
  [-33.6224, 117.5142],
  [-33.6298, 117.5138],
  [-33.6318, 117.5142],
  [-33.6342, 117.5155],
  [-33.6356, 117.5170],
  [-33.6373, 117.5178],
  [-33.6511, 117.5179],
  [-33.6533, 117.5189],
  [-33.6551, 117.5206],
  [-33.6563, 117.5222],
  [-33.6581, 117.5258],
  [-33.6592, 117.5273],
  [-33.6609, 117.5284],
  [-33.6708, 117.5318],
  [-33.6741, 117.5342],
  [-33.6757, 117.5369],
  [-33.6767, 117.5403],
  [-33.6777, 117.5423],
  [-33.6792, 117.5437],
  [-33.6808, 117.5444],
  [-33.6819, 117.5453],
  [-33.6932, 117.5586],
  [-33.6946, 117.5598],
  [-33.6967, 117.5608],
  [-33.6999, 117.5612],
  [-33.7028, 117.5606],
  [-33.7048, 117.5605],
  [-33.7067, 117.5611],
  [-33.7108, 117.5640],
  [-33.7125, 117.5658],
  [-33.7201, 117.5763],
  [-33.7218, 117.5782],
  [-33.7451, 117.5957],
  [-33.7700, 117.6072],
  [-33.7821, 117.6180],
  [-33.7834, 117.6189],
  [-33.7847, 117.6190],
  [-33.7911, 117.6171],
  [-33.7926, 117.6172],
  [-33.7944, 117.6186],
  [-33.8008, 117.6290],
  [-33.8018, 117.6299],
  [-33.8031, 117.6302],
  [-33.8078, 117.6289],
  [-33.8091, 117.6289],
  [-33.8103, 117.6295],
  [-33.8165, 117.6350],
  [-33.8176, 117.6356],
  [-33.8189, 117.6358],
  [-33.8346, 117.6332],
  [-33.8359, 117.6332],
  [-33.8387, 117.6340],
  [-33.8400, 117.6349],
  [-33.8433, 117.6383],
  [-33.8454, 117.6429],
  [-33.8470, 117.6449],
  [-33.8527, 117.6485],
  [-33.8540, 117.6491],
  [-33.8612, 117.6505],
  [-33.8628, 117.6506],
  [-33.8685, 117.6499],
  [-33.8708, 117.6492],
  [-33.8719, 117.6491],
  [-33.8734, 117.6494],
  [-33.8783, 117.6532],
  [-33.8790, 117.6544],
  [-33.8796, 117.6566],
  [-33.8804, 117.6580],
  [-33.8861, 117.6624],
  [-33.8878, 117.6629],
  [-33.8983, 117.6628],
  [-33.9015, 117.6618],
  [-33.9161, 117.6522],
  [-33.9175, 117.6518],
  [-33.9235, 117.6521],
  [-33.9249, 117.6519],
  [-33.9265, 117.6509],
  [-33.9308, 117.6467],
  [-33.9320, 117.6462],
  [-33.9444, 117.6450],
  [-33.9478, 117.6436],
  [-33.9501, 117.6432],
  [-33.9744, 117.6458],
  [-33.9784, 117.6449],
  [-34.0050, 117.6275],
  [-34.0076, 117.6266],
  [-34.0110, 117.6268],
  [-34.0468, 117.6418],
  [-34.0622, 117.6539],
  [-34.0781, 117.6761],
  [-34.0802, 117.6778],
  [-34.0930, 117.6813],
  [-34.0947, 117.6814],
  [-34.1746, 117.6696],
  [-34.1759, 117.6696],
  [-34.1973, 117.6747],
  [-34.1996, 117.6747],
  [-34.2026, 117.6734],
  [-34.2136, 117.6637],
  [-34.2448, 117.6445],
  [-34.2469, 117.6438],
  [-34.2504, 117.6436],
  [-34.2523, 117.6431],
  [-34.2549, 117.6410],
  [-34.2562, 117.6393],
  [-34.2571, 117.6366],
  [-34.2577, 117.6244],
  [-34.2588, 117.6211],
  [-34.2847, 117.5750],
  [-34.2892, 117.5630],
  [-34.2906, 117.5608],
  [-34.3130, 117.5362],
  [-34.3147, 117.5350],
  [-34.3169, 117.5347],
  [-34.3239, 117.5370],
  [-34.3297, 117.5418],
  [-34.3317, 117.5427],
  [-34.3461, 117.5443],
  [-34.3479, 117.5449],
  [-34.3584, 117.5518],
  [-34.3627, 117.5561],
  [-34.3637, 117.5580],
  [-34.3655, 117.5633],
  [-34.3666, 117.5652],
  [-34.3727, 117.5706],
  [-34.3749, 117.5717],
  [-34.3957, 117.5754],
  [-34.4038, 117.5786],
  [-34.4074, 117.5821],
  [-34.4095, 117.5853],
  [-34.4111, 117.5869],
  [-34.4214, 117.5917],
  [-34.4232, 117.5934],
  [-34.4321, 117.6082],
  [-34.4344, 117.6101],
  [-34.4771, 117.6288],
  [-34.4787, 117.6291],
  [-34.4893, 117.6290],
  [-34.4909, 117.6295],
  [-34.4922, 117.6311],
  [-34.4970, 117.6437],
  [-34.4979, 117.6452],
  [-34.4994, 117.6465],
  [-34.5012, 117.6472],
  [-34.5035, 117.6472],
  [-34.5061, 117.6479],
  [-34.5078, 117.6492],
  [-34.5084, 117.6500],
  [-34.5089, 117.6512],
  [-34.5097, 117.6554],
  [-34.5096, 117.6573],
  [-34.5088, 117.6615],
  [-34.5088, 117.6628],
  [-34.5092, 117.6641],
  [-34.5102, 117.6659],
  [-34.5105, 117.6668],
  [-34.5107, 117.6679],
  [-34.5107, 117.6700],
  [-34.5109, 117.6715],
  [-34.5125, 117.6749],
  [-34.5132, 117.6758],
  [-34.5140, 117.6765],
  [-34.5150, 117.6775],
  [-34.5162, 117.6796],
  [-34.5173, 117.6809],
  [-34.5193, 117.6821],
  [-34.5205, 117.6825],
  [-34.5236, 117.6827],
  [-34.5246, 117.6831],
  [-34.5282, 117.6855],
  [-34.5297, 117.6863],
  [-34.5689, 117.6983],
  [-34.5713, 117.6983],
  [-34.5735, 117.6975],
  [-34.5751, 117.6974],
  [-34.5768, 117.6980],
  [-34.5779, 117.6982],
  [-34.5794, 117.6978],
  [-34.5836, 117.6943],
  [-34.5910, 117.6841],
  [-34.5931, 117.6823],
  [-34.6116, 117.6737],
  [-34.6133, 117.6724],
  [-34.6202, 117.6628],
  [-34.6213, 117.6619],
  [-34.6229, 117.6614],
  [-34.6237, 117.6614],
  [-34.6244, 117.6616],
  [-34.6323, 117.6659],
  [-34.6338, 117.6666],
  [-34.6378, 117.6677],
  [-34.6385, 117.6680],
  [-34.6397, 117.6690],
  [-34.6445, 117.6725],
  [-34.6467, 117.6736],
  [-34.6499, 117.6740],
  [-34.6507, 117.6739],
  [-34.6517, 117.6735],
  [-34.6528, 117.6726],
  [-34.6540, 117.6719],
  [-34.6556, 117.6714],
  [-34.6572, 117.6706],
  [-34.6587, 117.6691],
  [-34.6633, 117.6664],
  [-34.6648, 117.6657],
  [-34.6670, 117.6654],
  [-34.6693, 117.6660],
  [-34.6713, 117.6674],
  [-34.6740, 117.6680],
  [-34.6875, 117.6673],
  [-34.6891, 117.6675],
  [-34.6909, 117.6683],
  [-34.6923, 117.6684],
  [-34.6939, 117.6676],
  [-34.6953, 117.6661],
  [-34.6963, 117.6655],
  [-34.6977, 117.6652],
  [-34.7069, 117.6670],
  [-34.7091, 117.6681],
  [-34.7107, 117.6697],
  [-34.7197, 117.6852],
  [-34.7211, 117.6870],
  [-34.7229, 117.6881],
  [-34.7537, 117.6970],
  [-34.7555, 117.6980],
  [-34.7583, 117.7008],
  [-34.7610, 117.7025],
  [-34.7682, 117.7040],
  [-34.7703, 117.7041],
  [-34.7794, 117.7015],
  [-34.7821, 117.6999],
  [-34.7938, 117.6890],
  [-34.7961, 117.6880],
  [-34.8150, 117.6873],
  [-34.8294, 117.6893],
  [-34.8847, 117.6922],
  [-34.8969, 117.6973],
  [-34.8993, 117.6994],
  [-34.9085, 117.7127],
  [-34.9104, 117.7144],
  [-34.9128, 117.7148],
  [-34.9176, 117.7136],
  [-34.9186, 117.7135],
  [-34.9195, 117.7139],
  [-34.9255, 117.7189],
  [-34.9309, 117.7216],
  [-34.9403, 117.7277],
  [-34.9450, 117.7294],
  [-34.9542, 117.7304],
  [-34.9563, 117.7309],
  [-34.9621, 117.7335],
  [-34.9646, 117.7342],
  [-34.9712, 117.7335],
  [-34.9728, 117.7330],
  [-34.9878, 117.7221],
  [-34.9897, 117.7216],
  [-34.9925, 117.7223],
  [-35.0006, 117.7283],
  [-35.0083, 117.7332],
  [-35.0111, 117.7356],
  [-35.0135, 117.7392],
  [-35.0154, 117.7411],
  [-35.0161, 117.7420],
  [-35.0167, 117.7440],
  [-35.0171, 117.7518],
  [-35.0159, 117.7677],
  [-35.0161, 117.7717],
  [-35.0161, 117.7909],
  [-35.0159, 117.7926],
  [-35.0151, 117.7949],
  [-35.0146, 117.7971],
  [-35.0123, 117.8143],
  [-35.0123, 117.8165],
  [-35.0127, 117.8187],
  [-35.0150, 117.8261],
  [-35.0170, 117.8380],
  [-35.0180, 117.8409],
  [-35.0184, 117.8422],
  [-35.0190, 117.8457],
  [-35.0192, 117.8526],
  [-35.0194, 117.8545],
  [-35.0198, 117.8559],
  [-35.0210, 117.8581],
  [-35.0253, 117.8639],
  [-35.0259, 117.8649],
  [-35.0269, 117.8677],
  [-35.0271, 117.8687],
  [-35.0271, 117.8695],
  [-35.0268, 117.8706],
  [-35.0268, 117.8716],
  [-35.0270, 117.8726],
  [-35.0277, 117.8743],
  [-35.0287, 117.8776],
  [-35.0288, 117.8784],
  [-35.0288, 117.8790],
  [-35.0282, 117.8814],
  [-35.0278, 117.8844],
  [-35.0279, 117.8851],
  [-35.0280, 117.8857]
];

const albanyPort = [
  [-35.0280, 117.8857],
  [-35.0282, 117.8862],
  [-35.0283, 117.8872],
  [-35.0285, 117.8881],
  [-35.0289, 117.8888],
  [-35.0305, 117.8910],
  [-35.0312, 117.8925],
  [-35.0314, 117.8929],
  [-35.0328, 117.8949],
  [-35.0354, 117.9011],
  [-35.0357, 117.9018],
  [-35.0366, 117.9066],
  [-35.0366, 117.9073],
  [-35.0367, 117.9077],
  [-35.0369, 117.9082],
  [-35.0373, 117.9086],
  [-35.0380, 117.9088],
  [-35.0385, 117.9086],
  [-35.0390, 117.9080],
  [-35.0392, 117.9074],
  [-35.0392, 117.9052],
  [-35.0391, 117.9045],
  [-35.0389, 117.9038]
];

export const albany: Line = {
  name: 'Albany',
  state: 'WA',
  segments: [
    {
      segments: [chidlowsWellJunction],
      history: {
        opened: {
          date: '1886-10-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [chidlowsWellYork],
      history: {
        opened: {
          date: '1885-06-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [yorkBeverley],
      history: {
        opened: {
          date: '1889-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [beverleyAlbany],
      history: {
        opened: {
          date: '1889-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [albanyPort],
      history: {
        opened: {
          date: '1889-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
};
