import { Line } from "../../../trackTypes";

const belgraveMenziesCreek = [
  [-37.9088, 145.3554],
  [-37.9067, 145.3570],
  [-37.9065, 145.3574],
  [-37.9065, 145.3577],
  [-37.9069, 145.3595],
  [-37.9073, 145.3603],
  [-37.9075, 145.3611],
  [-37.9077, 145.3618],
  [-37.9078, 145.3624],
  [-37.9079, 145.3631],
  [-37.9079, 145.3634],
  [-37.9077, 145.3643],
  [-37.9077, 145.3646],
  [-37.9079, 145.3649],
  [-37.9081, 145.3651],
  [-37.9084, 145.3651],
  [-37.9086, 145.3650],
  [-37.9089, 145.3645],
  [-37.9092, 145.3642],
  [-37.9095, 145.3642],
  [-37.9099, 145.3642],
  [-37.9110, 145.3641],
  [-37.9116, 145.3645],
  [-37.9120, 145.3651],
  [-37.9122, 145.3659],
  [-37.9123, 145.3670],
  [-37.9125, 145.3675],
  [-37.9129, 145.3676],
  [-37.9133, 145.3676],
  [-37.9138, 145.3678],
  [-37.9142, 145.3683],
  [-37.9145, 145.3689],
  [-37.9147, 145.3701],
  [-37.9151, 145.3708],
  [-37.9156, 145.3715],
  [-37.9157, 145.3718],
  [-37.9157, 145.3724],
  [-37.9155, 145.3730],
  [-37.9155, 145.3735],
  [-37.9159, 145.3763],
  [-37.9161, 145.3768],
  [-37.9164, 145.3770],
  [-37.9167, 145.3772],
  [-37.9169, 145.3775],
  [-37.9170, 145.3777],
  [-37.9170, 145.3795],
  [-37.9172, 145.3800],
  [-37.9176, 145.3804],
  [-37.9178, 145.3806],
  [-37.9182, 145.3809],
  [-37.9184, 145.3811],
  [-37.9185, 145.3814],
  [-37.9185, 145.3822],
  [-37.9185, 145.3825],
  [-37.9189, 145.3830],
  [-37.9192, 145.3836],
  [-37.9189, 145.3848],
  [-37.9189, 145.3854],
  [-37.9190, 145.3862],
  [-37.9187, 145.3873],
  [-37.9178, 145.3881],
  [-37.9168, 145.3882],
  [-37.9166, 145.3883],
  [-37.9165, 145.3885],
  [-37.9165, 145.3888],
  [-37.9166, 145.3890],
  [-37.9169, 145.3895],
  [-37.9172, 145.3898],
  [-37.9176, 145.3897],
  [-37.9180, 145.3898],
  [-37.9187, 145.3902],
  [-37.9189, 145.3905],
  [-37.9190, 145.3908],
  [-37.9190, 145.3911],
  [-37.9188, 145.3914],
  [-37.9188, 145.3917],
  [-37.9188, 145.3921],
  [-37.9196, 145.3931],
  [-37.9198, 145.3933],
  [-37.9205, 145.3936],
  [-37.9207, 145.3938],
  [-37.9212, 145.3947],
  [-37.9213, 145.3950],
  [-37.9213, 145.3952],
  [-37.9212, 145.3956],
  [-37.9211, 145.3958],
  [-37.9207, 145.3963],
  [-37.9199, 145.3980],
  [-37.9196, 145.3987],
  [-37.9196, 145.3997],
  [-37.9198, 145.4014],
  [-37.9199, 145.4017],
  [-37.9202, 145.4025],
  [-37.9207, 145.4045]
];

const menziesCreekEmerald = [
  [-37.9207, 145.4045],
  [-37.9210, 145.4057],
  [-37.9213, 145.4062],
  [-37.9222, 145.4072],
  [-37.9227, 145.4081],
  [-37.9230, 145.4095],
  [-37.9231, 145.4101],
  [-37.9230, 145.4120],
  [-37.9231, 145.4127],
  [-37.9233, 145.4130],
  [-37.9237, 145.4131],
  [-37.9240, 145.4134],
  [-37.9242, 145.4139],
  [-37.9242, 145.4143],
  [-37.9238, 145.4153],
  [-37.9238, 145.4159],
  [-37.9238, 145.4168],
  [-37.9247, 145.4183],
  [-37.9251, 145.4191],
  [-37.9258, 145.4209],
  [-37.9262, 145.4214],
  [-37.9265, 145.4217],
  [-37.9277, 145.4222],
  [-37.9280, 145.4225],
  [-37.9282, 145.4229],
  [-37.9291, 145.4259],
  [-37.9291, 145.4271],
  [-37.9289, 145.4278],
  [-37.9284, 145.4290],
  [-37.9283, 145.4297],
  [-37.9284, 145.4306],
  [-37.9291, 145.4316],
  [-37.9298, 145.4320],
  [-37.9300, 145.4324],
  [-37.9300, 145.4328],
  [-37.9300, 145.4340],
  [-37.9303, 145.4350],
  [-37.9309, 145.4359],
  [-37.9311, 145.4362],
  [-37.9315, 145.4372],
  [-37.9318, 145.4377],
  [-37.9338, 145.4398]
];

const emeraldLakeside = [
  [-37.9338, 145.4398],
  [-37.9345, 145.4405],
  [-37.9348, 145.4410],
  [-37.9348, 145.4413],
  [-37.9344, 145.4431],
  [-37.9339, 145.4442],
  [-37.9323, 145.4460],
  [-37.9319, 145.4467],
  [-37.9317, 145.4477],
  [-37.9318, 145.4498],
  [-37.9322, 145.4510],
  [-37.9332, 145.4521],
  [-37.9344, 145.4525],
  [-37.9355, 145.4524],
  [-37.9359, 145.4524],
  [-37.9361, 145.4527],
  [-37.9362, 145.4531],
  [-37.9358, 145.4540],
  [-37.9352, 145.4547],
  [-37.9326, 145.4560],
  [-37.9321, 145.4565],
  [-37.9318, 145.4571],
  [-37.9320, 145.4578],
  [-37.9326, 145.4584],
  [-37.9330, 145.4587],
  [-37.9340, 145.4591],
  [-37.9347, 145.4591],
  [-37.9351, 145.4594],
  [-37.9352, 145.4598],
  [-37.9351, 145.4601],
  [-37.9350, 145.4604],
  [-37.9341, 145.4612],
  [-37.9338, 145.4616],
  [-37.9334, 145.4617],
  [-37.9332, 145.4616],
  [-37.9329, 145.4614],
  [-37.9326, 145.4607],
  [-37.9322, 145.4603],
  [-37.9320, 145.4601],
  [-37.9307, 145.4597]
];

const lakesideGembrook = [
  [-37.9307, 145.4597],
  [-37.9300, 145.4595],
  [-37.9295, 145.4596],
  [-37.9290, 145.4600],
  [-37.9280, 145.4617],
  [-37.9279, 145.4621],
  [-37.9279, 145.4624],
  [-37.9281, 145.4633],
  [-37.9281, 145.4639],
  [-37.9278, 145.4643],
  [-37.9268, 145.4653],
  [-37.9265, 145.4659],
  [-37.9265, 145.4662],
  [-37.9267, 145.4666],
  [-37.9282, 145.4680],
  [-37.9290, 145.4694],
  [-37.9293, 145.4725],
  [-37.9291, 145.4743],
  [-37.9292, 145.4746],
  [-37.9294, 145.4756],
  [-37.9294, 145.4758],
  [-37.9293, 145.4760],
  [-37.9290, 145.4763],
  [-37.9289, 145.4764],
  [-37.9283, 145.4764],
  [-37.9279, 145.4766],
  [-37.9275, 145.4770],
  [-37.9274, 145.4774],
  [-37.9273, 145.4779],
  [-37.9275, 145.4791],
  [-37.9275, 145.4794],
  [-37.9270, 145.4803],
  [-37.9267, 145.4810],
  [-37.9256, 145.4849],
  [-37.9255, 145.4863],
  [-37.9257, 145.4871],
  [-37.9274, 145.4906],
  [-37.9280, 145.4910],
  [-37.9283, 145.4912],
  [-37.9305, 145.4911],
  [-37.9332, 145.4914],
  [-37.9352, 145.4914],
  [-37.9365, 145.4916],
  [-37.9378, 145.4921],
  [-37.9405, 145.4938],
  [-37.9409, 145.4943],
  [-37.9410, 145.4948],
  [-37.9410, 145.4954],
  [-37.9410, 145.4959],
  [-37.9413, 145.4963],
  [-37.9434, 145.4992],
  [-37.9438, 145.4994],
  [-37.9444, 145.4995],
  [-37.9447, 145.4998],
  [-37.9460, 145.5018],
  [-37.9461, 145.5024],
  [-37.9461, 145.5028],
  [-37.9461, 145.5032],
  [-37.9461, 145.5037],
  [-37.9468, 145.5060],
  [-37.9471, 145.5064],
  [-37.9477, 145.5068],
  [-37.9480, 145.5073],
  [-37.9480, 145.5080],
  [-37.9473, 145.5098],
  [-37.9470, 145.5109],
  [-37.9465, 145.5115],
  [-37.9454, 145.5120],
  [-37.9449, 145.5124],
  [-37.9447, 145.5129],
  [-37.9447, 145.5125],
  [-37.9448, 145.5140],
  [-37.9461, 145.5157],
  [-37.9464, 145.5163],
  [-37.9466, 145.5169],
  [-37.9468, 145.5211],
  [-37.9471, 145.5224],
  [-37.9493, 145.5265],
  [-37.9495, 145.5276],
  [-37.9490, 145.5290],
  [-37.9489, 145.5297],
  [-37.9488, 145.5317],
  [-37.9486, 145.5323],
  [-37.9476, 145.5342],
  [-37.9474, 145.5352],
  [-37.9475, 145.5364],
  [-37.9478, 145.5374],
  [-37.9488, 145.5391],
  [-37.9491, 145.5403],
  [-37.9490, 145.5413],
  [-37.9488, 145.5422],
  [-37.9488, 145.5430],
  [-37.9492, 145.5450],
  [-37.9492, 145.5465],
  [-37.9493, 145.5473],
  [-37.9496, 145.5477],
  [-37.9512, 145.5494],
  [-37.9516, 145.5497],
  [-37.9519, 145.5497],
  [-37.9522, 145.5497]
];

export const gembrook: Line = {
  name: 'Gembrook',
  state: 'VIC',
  segments: [
    {
      segments: [belgraveMenziesCreek],
      history: {
        opened: {
          date: '1900-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-04-30',
          status: 'closed'
        }, {
          date: '1962-07-28',
          status: 'open'
        }]
      }
    },
    {
      segments: [menziesCreekEmerald],
      history: {
        opened: {
          date: '1900-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-04-30',
          status: 'closed'
        }, {
          date: '1965-07-31',
          status: 'open'
        }]
      }
    },
    {
      segments: [emeraldLakeside],
      history: {
        opened: {
          date: '1900-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-04-30',
          status: 'closed'
        }, {
          date: '1975-10-18',
          status: 'open'
        }]
      }
    },
    {
      segments: [lakesideGembrook],
      history: {
        opened: {
          date: '1900-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-04-30',
          status: 'closed'
        }, {
          date: '1998-10-18',
          status: 'open'
        }]
      }
    }
  ]
};
