import { Line } from "../../../../trackTypes";

const rosalie = [
  [-27.46903, 153.00406],
  [-27.46904, 153.00371],
  [-27.46899, 153.00349],
  [-27.46889, 153.00330],
  [-27.46689, 153.00137],
  [-27.46678, 153.00122],
  [-27.46665, 153.00095],
  [-27.46604, 152.99961],
  [-27.46601, 152.99946],
  [-27.46600, 152.99928],
  [-27.46625, 152.99732],
  [-27.46625, 152.99714]
];

const rainworth = [
  [-27.46625, 152.99714],
  [-27.46621, 152.99706],
  [-27.46617, 152.99702],
  [-27.46611, 152.99699],
  [-27.46540, 152.99692],
  [-27.46530, 152.99689],
  [-27.46518, 152.99683],
  [-27.46427, 152.99622],
  [-27.46424, 152.99618],
  [-27.46416, 152.99608],
  [-27.46386, 152.99395],
  [-27.46389, 152.99376],
  [-27.46398, 152.99360],
  [-27.46552, 152.99189],
  [-27.46563, 152.99176],
  [-27.46571, 152.99155],
  [-27.46624, 152.99007],
  [-27.46636, 152.98978],
  [-27.46682, 152.98903],
  [-27.46736, 152.98833],
  [-27.46743, 152.98815],
  [-27.46745, 152.98803],
  [-27.46744, 152.98798],
  [-27.46719, 152.98772],
  [-27.46716, 152.98758],
  [-27.46715, 152.98740],
  [-27.46720, 152.98715],
  [-27.46731, 152.98686],
  [-27.46733, 152.98678],
  [-27.46732, 152.98664],
  [-27.46705, 152.98570]
];

export const west5: Line = {
  name: 'West 5',
  state: 'QLD',
  segments: [
    {
      segments: [rosalie],
      history: {
        opened: {
          date: '1904-10-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-07-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rainworth],
      history: {
        opened: {
          date: '1930-10-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-07-02',
          status: 'closed'
        }]
      }
    }
  ]
}
