import { Line } from "../../trackTypes";

const craigieburnDonnybrook = [
  [-37.6023, 144.9431],
  [-37.5528, 144.9645],
  [-37.5486, 144.9665],
  [-37.5420, 144.9701]
];

const donnybrookBeveridge = [
  [-37.5420, 144.9701],
  [-37.5281, 144.9779],
  [-37.5263, 144.9786],
  [-37.5250, 144.9789],
  [-37.5171, 144.9800],
  [-37.5158, 144.9804],
  [-37.5149, 144.9807],
  [-37.4822, 144.9937],
  [-37.4804, 144.9941],
  [-37.4693, 144.9961]
];

const beveridgeBroadford = [
  [-37.4693, 144.9961],
  [-37.4174, 145.0051],
  [-37.4163, 145.0055],
  [-37.4148, 145.0063],
  [-37.4136, 145.0074],
  [-37.4002, 145.0231],
  [-37.3985, 145.0247],
  [-37.3885, 145.0316],
  [-37.3871, 145.0322],
  [-37.3855, 145.0325],
  [-37.3848, 145.0325],
  [-37.3805, 145.0322],
  [-37.3793, 145.0319],
  [-37.3777, 145.0312],
  [-37.3688, 145.0265],
  [-37.3673, 145.0261],
  [-37.3658, 145.0261],
  [-37.3584, 145.0269],
  [-37.3564, 145.0269],
  [-37.3557, 145.0267],
  [-37.3375, 145.0199],
  [-37.3363, 145.0192],
  [-37.3352, 145.0184],
  [-37.3342, 145.0175],
  [-37.3284, 145.0117],
  [-37.3265, 145.0105],
  [-37.3244, 145.0099],
  [-37.3208, 145.0101],
  [-37.3193, 145.0100],
  [-37.3176, 145.0093],
  [-37.3054, 145.0009],
  [-37.3037, 144.9991],
  [-37.3027, 144.9974],
  [-37.3022, 144.9957],
  [-37.3012, 144.9910],
  [-37.3003, 144.9890],
  [-37.2987, 144.9868],
  [-37.2963, 144.9849],
  [-37.2921, 144.9829],
  [-37.2901, 144.9824],
  [-37.2883, 144.9825],
  [-37.2855, 144.9830],
  [-37.2839, 144.9836],
  [-37.2827, 144.9842],
  [-37.2665, 144.9940],
  [-37.2641, 144.9951],
  [-37.2578, 144.9972],
  [-37.2555, 144.9984],
  [-37.2527, 145.0007],
  [-37.2510, 145.0028],
  [-37.2374, 145.0252],
  [-37.2357, 145.0273],
  [-37.2339, 145.0288],
  [-37.2311, 145.0308],
  [-37.2285, 145.0314],
  [-37.2258, 145.0311],
  [-37.2230, 145.0299],
  [-37.2206, 145.0295],
  [-37.2189, 145.0298],
  [-37.2157, 145.0310],
  [-37.2138, 145.0326],
  [-37.2118, 145.0350],
  [-37.2073, 145.0429]
];

const broadfordTallarook = [
  [-37.2073, 145.0429],
  [-37.2064, 145.0447],
  [-37.2059, 145.0461],
  [-37.2055, 145.0482],
  [-37.2055, 145.0500],
  [-37.2057, 145.0533],
  [-37.2055, 145.0559],
  [-37.2048, 145.0582],
  [-37.2035, 145.0605],
  [-37.1985, 145.0665],
  [-37.1974, 145.0674],
  [-37.1961, 145.0683],
  [-37.1942, 145.0689],
  [-37.1925, 145.0690],
  [-37.1909, 145.0688],
  [-37.1853, 145.0677],
  [-37.1834, 145.0676],
  [-37.1826, 145.0676],
  [-37.1676, 145.0693],
  [-37.1655, 145.0697],
  [-37.1621, 145.0711],
  [-37.1596, 145.0717],
  [-37.1379, 145.0741],
  [-37.1360, 145.0745],
  [-37.1340, 145.0752],
  [-37.1324, 145.0760],
  [-37.1037, 145.0918],
  [-37.1017, 145.0933],
  [-37.1005, 145.0945],
  [-37.0972, 145.0986],
  [-37.0952, 145.1006],
  [-37.0923, 145.1028]
];

const tallarookDysart = [
  [-37.0923, 145.1028],
  [-37.0825, 145.1100],
  [-37.0810, 145.1109],
  [-37.0791, 145.1116],
  [-37.0769, 145.1120],
  [-37.0541, 145.1147],
  [-37.0521, 145.1150],
  [-37.0499, 145.1158],
  [-37.0481, 145.1167],
  [-37.0419, 145.1210]
];

const dysartSeymour = [
  [-37.0419, 145.1210],
  [-37.0323, 145.1278],
  [-37.0312, 145.1283],
  [-37.0293, 145.1293],
  [-37.0285, 145.1299],
  [-37.0275, 145.1309],
  [-37.0267, 145.1322],
  [-37.0262, 145.1333],
  [-37.0243, 145.1384]
];

export const seymour: Line = {
  name: 'Seymour',
  state: 'VIC',
  segments: [
    {
      segments: [craigieburnDonnybrook],
      history: {
        opened: {
          date: '1872-04-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-03-22',
          tracks: 2
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [donnybrookBeveridge],
      history: {
        opened: {
          date: '1872-04-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1883-10-15',
          tracks: 2
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [beveridgeBroadford],
      history: {
        opened: {
          date: '1872-04-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-07-12',
          tracks: 2
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [broadfordTallarook],
      history: {
        opened: {
          date: '1872-04-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1883-12-12',
          tracks: 2
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [tallarookDysart],
      history: {
        opened: {
          date: '1872-04-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-10-18',
          tracks: 2
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [dysartSeymour],
      history: {
        opened: {
          date: '1872-08-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1942-04-17',
          tracks: 2
        }, {
          date: '1961-11-26',
          tracks: 1
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    }
  ]
};
