import { Line } from "../../trackTypes";

const gladstoneJunction = [
  [-24.2268, 150.4171],
  [-24.2274, 150.4166],
  [-24.2299, 150.4155],
  [-24.2306, 150.4154],
  [-24.2315, 150.4158]
];

const argoonConnector = [
  [-24.2272, 150.4120],
  [-24.2315, 150.4158]
];

const rannesArgoon = [
  [-24.1014, 150.1180],
  [-24.1027, 150.1182],
  [-24.1033, 150.1188],
  [-24.1036, 150.1197],
  [-24.1026, 150.1220],
  [-24.1024, 150.1234],
  [-24.1039, 150.1352],
  [-24.1044, 150.1367],
  [-24.1043, 150.1385],
  [-24.1027, 150.1410],
  [-24.1026, 150.1423],
  [-24.1033, 150.1434],
  [-24.1049, 150.1438],
  [-24.1058, 150.1447],
  [-24.1059, 150.1459],
  [-24.1047, 150.1492],
  [-24.1049, 150.1532],
  [-24.1063, 150.1560],
  [-24.1084, 150.1577],
  [-24.1114, 150.1630],
  [-24.1161, 150.1799],
  [-24.1162, 150.1824],
  [-24.1141, 150.1902],
  [-24.1138, 150.1919],
  [-24.1148, 150.2177],
  [-24.1250, 150.2474],
  [-24.1266, 150.2607],
  [-24.1281, 150.2634],
  [-24.1305, 150.2647],
  [-24.1323, 150.2664],
  [-24.1344, 150.2708],
  [-24.1458, 150.2831],
  [-24.1501, 150.2943],
  [-24.1818, 150.3386],
  [-24.1850, 150.3486],
  [-24.2103, 150.3979],
  [-24.2172, 150.4028],
  [-24.2272, 150.4120]
];

const argoonCallide = [
  [-24.2315, 150.4158],
  [-24.2439, 150.4273],
  [-24.2456, 150.4283],
  [-24.2487, 150.4294],
  [-24.2537, 150.4332],
  [-24.2543, 150.4340],
  [-24.2582, 150.4399],
  [-24.2594, 150.4411],
  [-24.2684, 150.4475],
  [-24.2696, 150.4483],
  [-24.2832, 150.4534],
  [-24.2983, 150.4616],
  [-24.2994, 150.4620],
  [-24.3060, 150.4657]
];

const callideDakenJunction = [
  [-24.3060, 150.4657],
  [-24.3112, 150.4686],
  [-24.3248, 150.4744],
  [-24.3300, 150.4778],
  [-24.3312, 150.4789],
  [-24.3404, 150.4902],
  [-24.3420, 150.4914],
  [-24.3500, 150.4950]
];

const dakenJunctionBiloela = [
  [-24.3500, 150.4950],
  [-24.3563, 150.4978],
  [-24.3923, 150.5031],
  [-24.3949, 150.5043],
  [-24.4012, 150.5103]
];

const biloelaThangool = [
  [-24.4012, 150.5103],
  [-24.4313, 150.5397],
  [-24.4332, 150.5411],
  [-24.4411, 150.5444],
  [-24.4673, 150.5592],
  [-24.4684, 150.5595],
  [-24.4703, 150.5600],
  [-24.4718, 150.5607],
  [-24.4842, 150.5710],
  [-24.4851, 150.5722],
  [-24.4866, 150.5776]
];

const thangoolLawgi = [
  [-24.4866, 150.5776],
  [-24.4875, 150.5802],
  [-24.4898, 150.5861],
  [-24.4955, 150.5934],
  [-24.4986, 150.5958],
  [-24.5044, 150.5970],
  [-24.5066, 150.5980],
  [-24.5196, 150.6110],
  [-24.5222, 150.6121],
  [-24.5274, 150.6125],
  [-24.5292, 150.6131],
  [-24.5345, 150.6161],
  [-24.5365, 150.6178],
  [-24.5552, 150.6276],
  [-24.5578, 150.6281],
  [-24.5593, 150.6290],
  [-24.5624, 150.6341],
  [-24.5624, 150.6351],
  [-24.5624, 150.6366],
  [-24.5630, 150.6380],
  [-24.5651, 150.6384],
  [-24.5683, 150.6406],
  [-24.5688, 150.6423],
  [-24.5684, 150.6438],
  [-24.5678, 150.6446],
  [-24.5665, 150.6450],
  [-24.5659, 150.6459],
  [-24.5659, 150.6472],
  [-24.5676, 150.6501],
  [-24.5687, 150.6504],
  [-24.5697, 150.6497],
  [-24.5711, 150.6486],
  [-24.5725, 150.6486],
  [-24.5735, 150.6502],
  [-24.5731, 150.6515],
  [-24.5732, 150.6524],
  [-24.5761, 150.6551],
  [-24.5765, 150.6560],
  [-24.5767, 150.6570],
  [-24.5776, 150.6577],
  [-24.5793, 150.6574],
  [-24.5799, 150.6574],
  [-24.5804, 150.6579],
  [-24.5814, 150.6605],
  [-24.5824, 150.6614],
  [-24.5828, 150.6626],
  [-24.5830, 150.6636],
  [-24.5858, 150.6674],
  [-24.5863, 150.6688],
  [-24.5870, 150.6694],
  [-24.5880, 150.6691],
  [-24.5884, 150.6683],
  [-24.5884, 150.6671],
  [-24.5887, 150.6663],
  [-24.5893, 150.6655],
  [-24.5895, 150.6648],
  [-24.5895, 150.6634],
  [-24.5901, 150.6624],
  [-24.5909, 150.6624],
  [-24.5918, 150.6631],
  [-24.5922, 150.6641],
  [-24.5923, 150.6656],
  [-24.5922, 150.6666],
  [-24.5926, 150.6699],
  [-24.5932, 150.6711],
  [-24.5948, 150.6727],
  [-24.5982, 150.6792],
  [-24.5990, 150.6796],
  [-24.5997, 150.6796],
  [-24.6013, 150.6789],
  [-24.6025, 150.6788]
];

const dakenbaCoalMine = [
  [-24.3500, 150.4950],
  [-24.3509, 150.4959],
  [-24.3514, 150.4968],
  [-24.3515, 150.4976],
  [-24.3509, 150.5198],
  [-24.3436, 150.5754],
  [-24.3406, 150.5894],
  [-24.3336, 150.6084],
  [-24.3334, 150.6098],
  [-24.3338, 150.6117],
  [-24.3333, 150.6141],
  [-24.3308, 150.6175],
  [-24.3304, 150.6185],
  [-24.3303, 150.6192],
  [-24.3306, 150.6206],
  [-24.3303, 150.6218],
  [-24.3294, 150.6225],
  [-24.3286, 150.6225],
  [-24.3273, 150.6218],
  [-24.3267, 150.6218],
  [-24.3262, 150.6219],
  [-24.3257, 150.6224],
  [-24.3253, 150.6236],
  [-24.3254, 150.6244],
  [-24.3260, 150.6251],
  [-24.3268, 150.6252],
  [-24.3275, 150.6248],
  [-24.3284, 150.6233],
  [-24.3288, 150.6229],
  [-24.3294, 150.6225]
];

export const callideValley: Line = {
  name: 'Callide Valley',
  state: 'QLD',
  segments: [
    {
      segments: [gladstoneJunction],
      history: {
        opened: {
          date: '1968-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [argoonConnector],
      history: {
        opened: {
          date: '1924-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1999-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rannesArgoon],
      history: {
        opened: {
          date: '1924-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1999-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [argoonCallide],
      history: {
        opened: {
          date: '1924-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [callideDakenJunction],
      history: {
        opened: {
          date: '1925-08-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [dakenJunctionBiloela],
      history: {
        opened: {
          date: '1925-08-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [biloelaThangool],
      history: {
        opened: {
          date: '1925-08-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [thangoolLawgi],
      history: {
        opened: {
          date: '1932-09-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dakenbaCoalMine],
      history: {
        opened: {
          date: '1953-11-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
