import { Line } from "../../../trackTypes";

const line = [
  [-36.3186, 146.3642],
  [-36.3174, 146.3649],
  [-36.3167, 146.3649],
  [-36.3161, 146.3647],
  [-36.3109, 146.3611],
  [-36.2773, 146.3364],
  [-36.2757, 146.3355],
  [-36.2739, 146.3346],
  [-36.2565, 146.3209],
  [-36.2529, 146.3180],
  [-36.2256, 146.2922],
  [-36.2243, 146.2911],
  [-36.2172, 146.2873],
  [-36.2127, 146.2865],
  [-36.2118, 146.2860],
  [-36.2104, 146.2849],
  [-36.1865, 146.2638],
  [-36.1850, 146.2629],
  [-36.1793, 146.2607],
  [-36.1735, 146.2583],
  [-36.1726, 146.2581],
  [-36.1656, 146.2573]
];

export const peechelbaEast: Line = {
  name: 'Peechelba East',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1927-10-31',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    }
  ]
};
