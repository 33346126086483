import { Line } from "../../../trackTypes";

const westMeadJunctionTramSetcion = [
  [-33.8077, 150.9831],
  [-33.8074, 150.9822],
  [-33.8071, 150.9816],
  [-33.8065, 150.9808],
  [-33.8057, 150.9807],
  [-33.8048, 150.9810],
  [-33.8040, 150.9816],
  [-33.8035, 150.9836],
  [-33.8028, 150.9845],
  [-33.8021, 150.9875],
  [-33.8015, 150.9885],
  [-33.7989, 150.9913],
  [-33.7933, 150.9970],
  [-33.7925, 150.9979],
  [-33.7914, 150.9977],
];

const tramSectionBaulkhamHills = [
  [-33.7914, 150.9977],
  [-33.7887, 150.9951],
  [-33.7879, 150.9950],
  [-33.7799, 150.9977],
  [-33.7755, 151.0011],
  [-33.7748, 151.0012],
  [-33.7717, 151.0011],
  [-33.7704, 151.0007],
  [-33.7695, 151.0002],
  [-33.7686, 150.9991],
  [-33.7644, 150.9957],
  [-33.7622, 150.9932],
  [-33.7620, 150.9930]
];

const baulkhamHillsCastleHill = [
  [-33.7620, 150.9930],
  [-33.7614, 150.9930],
  [-33.7586, 150.9928],
  [-33.7572, 150.9924],
  [-33.7561, 150.9924],
  [-33.7554, 150.9928],
  [-33.7546, 150.9937],
  [-33.7535, 150.9941],
  [-33.7503, 150.9942],
  [-33.7487, 150.9936],
  [-33.7475, 150.9936],
  [-33.7465, 150.9942],
  [-33.7446, 150.9967],
  [-33.7431, 150.9990],
  [-33.7419, 150.9996],
  [-33.7400, 150.9999],
  [-33.7360, 151.0020],
  [-33.7322, 151.0058],
  [-33.7314, 151.0083]
];

const castleHillRogansHill = [
  [-33.7314, 151.0083],
  [-33.7307, 151.0103],
  [-33.7307, 151.0120],
  [-33.7314, 151.0131],
  [-33.7317, 151.0142],
  [-33.7314, 151.0154],
  [-33.7308, 151.0159],
  [-33.7297, 151.0161],
  [-33.7283, 151.0164],
  [-33.7274, 151.0177],
  [-33.7268, 151.0181]
];

export const rogansHill: Line = {
  name: 'Rogans Hill',
  state: 'NSW',
  segments: [
    {
      segments: [westMeadJunctionTramSetcion],
      history: {
        opened: {
          date: '1923-01-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1932-01-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tramSectionBaulkhamHills],
      history: {
        opened: {
          date: '1902-08-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1923-01-28',
          trackType: 'heavy'
        }, {
          date: '1932-01-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [baulkhamHillsCastleHill],
      history: {
        opened: {
          date: '1910-07-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1923-01-28',
          trackType: 'heavy'
        }, {
          date: '1932-01-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [castleHillRogansHill],
      history: {
        opened: {
          date: '1924-11-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1923-01-28',
          trackType: 'heavy'
        }, {
          date: '1932-01-31',
          status: 'closed'
        }]
      }
    }
  ]
};
