import { Line } from "../../../../trackTypes";

const swanstonStreet = [
  [-37.81746, 144.96744],
  [-37.81170, 144.96480]
];

const collingwood = [
  [-37.81170, 144.96480],
  [-37.81156, 144.96485],
  [-37.81149, 144.96493],
  [-37.81091, 144.96694],
  [-37.81081, 144.96708],
  [-37.81069, 144.96712],
  [-37.80784, 144.96584],
  [-37.80750, 144.96577],
  [-37.79731, 144.96753],
  [-37.79722, 144.96762],
  [-37.79717, 144.96779],
  [-37.79745, 144.97030],
  [-37.80074, 145.00219]
];

export const east3: Line = {
  name: 'East3',
  state: 'VIC',
  segments: [
    {
      segments: [swanstonStreet],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1926-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [collingwood],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
