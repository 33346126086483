import { Line } from "../../../trackTypes";

const croydonParkAshfieldStation = [
  [-33.8879, 151.1256],
  [-33.8888, 151.1249],
  [-33.8888, 151.1242],
  [-33.8876, 151.1226],
  [-33.8875, 151.1219],
  [-33.8875, 151.1215],
  [-33.8876, 151.1211],
  [-33.8884, 151.1190],
  [-33.8885, 151.1185],
  [-33.8885, 151.1174],
  [-33.8890, 151.1173],
  [-33.8933, 151.1170],
  [-33.8971, 151.1008],
  [-33.8964, 151.0998],
  [-33.8953, 151.0916]
];

const mortlakeExtension = [
  [-33.8953, 151.0916],
  [-33.8950, 151.0900],
  [-33.8876, 151.0914],
  [-33.8868, 151.0925],
  [-33.8857, 151.0935],
  [-33.8855, 151.0940],
  [-33.8855, 151.0946],
  [-33.8871, 151.0982],
  [-33.8874, 151.0996],
  [-33.8876, 151.1016],
  [-33.8783, 151.1035],
  [-33.8658, 151.1060],
  [-33.8626, 151.1081],
  [-33.8606, 151.1039],
  [-33.8544, 151.1030],
  [-33.8532, 151.1079],
  [-33.8522, 151.1076],
  [-33.8517, 151.1076],
  [-33.8486, 151.1066],
  [-33.8484, 151.1076],
  [-33.8482, 151.1078],
  [-33.8479, 151.1078],
  [-33.8452, 151.1069],
  [-33.8444, 151.1080],
  [-33.8442, 151.1082],
  [-33.8440, 151.1082],
  [-33.8406, 151.1072]
];

const cabaritaParkBranch = [
  [-33.8522, 151.1076],
  [-33.8519, 151.1077],
  [-33.8517, 151.1079],
  [-33.8506, 151.1129],
  [-33.8426, 151.1171]
];

export const enfieldTrams: Line = {
  name: 'Enfield (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [croydonParkAshfieldStation],
      history: {
        opened: {
          date: '1891-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1912-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mortlakeExtension],
      history: {
        opened: {
          date: '1901',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1912-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cabaritaParkBranch],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1912-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
