import { Line } from "../../trackTypes";

const kerangMurrabit = [
  [-35.7248, 143.9201],
  [-35.7238, 143.9196],
  [-35.7224, 143.9193],
  [-35.7192, 143.9192],
  [-35.7161, 143.9200],
  [-35.7130, 143.9220],
  [-35.7004, 143.9334],
  [-35.6749, 143.9569],
  [-35.6724, 143.9583],
  [-35.6700, 143.9586],
  [-35.6413, 143.9588],
  [-35.6378, 143.9595],
  [-35.6308, 143.9615],
  [-35.6286, 143.9617],
  [-35.5514, 143.9615],
  [-35.5493, 143.9613],
  [-35.5319, 143.9585]
];

const murrabitNswBorder = [
  [-35.5319, 143.9585],
  [-35.5257, 143.9576],
  [-35.5245, 143.9575],
  [-35.5221, 143.9568],
  [-35.5162, 143.9558],
  [-35.5078, 143.9562],
  [-35.5056, 143.9566],
  [-35.5040, 143.9566]
];

export const stonyCrossing: Line = {
  name: 'Stony Crossing',
  state: 'VIC',
  segments: [
    {
      segments: [kerangMurrabit],
      history: {
        opened: {
          date: '1928-03-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-12-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [murrabitNswBorder],
      history: {
        opened: {
          date: '1928-03-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-12-20',
          status: 'closed'
        }]
      }
    }
  ]
};
