import { Line } from "../../../trackTypes";

const line = [
  [-36.3543, 146.3181],
  [-36.3571, 146.3150],
  [-36.3577, 146.3145],
  [-36.3581, 146.3144],
  [-36.3585, 146.3145],
  [-36.3588, 146.3148],
  [-36.3627, 146.3210],
  [-36.3646, 146.3231],
  [-36.3651, 146.3235],
  [-36.3658, 146.3236],
  [-36.3706, 146.3225],
  [-36.3710, 146.3225],
  [-36.3753, 146.3240],
  [-36.3876, 146.3310],
  [-36.3884, 146.3317],
  [-36.3910, 146.3351],
  [-36.3941, 146.3379],
  [-36.3948, 146.3389],
  [-36.3958, 146.3425],
  [-36.3968, 146.3441],
  [-36.3995, 146.3462],
  [-36.4064, 146.3490],
  [-36.4119, 146.3529],
  [-36.4125, 146.3531],
  [-36.4173, 146.3550],
  [-36.4226, 146.3578],
  [-36.4236, 146.3580],
  [-36.4349, 146.3577],
  [-36.4360, 146.3579],
  [-36.4393, 146.3595],
  [-36.4403, 146.3605],
  [-36.4453, 146.3634],
  [-36.4524, 146.3682],
  [-36.4532, 146.3685],
  [-36.4540, 146.3683],
  [-36.4560, 146.3665],
  [-36.4652, 146.3637],
  [-36.4658, 146.3637],
  [-36.5011, 146.3711],
  [-36.5048, 146.3728],
  [-36.5150, 146.3788],
  [-36.5189, 146.3799],
  [-36.5361, 146.3792],
  [-36.5367, 146.3792],
  [-36.5489, 146.3830],
  [-36.5496, 146.3830],
  [-36.5531, 146.3825],
  [-36.5716, 146.3794],
  [-36.5728, 146.3788],
  [-36.5757, 146.3782],
  [-36.5784, 146.3783],
  [-36.5801, 146.3783],
  [-36.6012, 146.3853],
  [-36.6023, 146.3861],
  [-36.6033, 146.3872],
  [-36.6093, 146.3950],
  [-36.6098, 146.3956],
  [-36.6106, 146.3962],
  [-36.6185, 146.4020],
  [-36.6341, 146.4133],
  [-36.6366, 146.4161],
  [-36.6397, 146.4192],
  [-36.6436, 146.4215],
  [-36.6470, 146.4220],
  [-36.6495, 146.4219],
  [-36.6540, 146.4216],
  [-36.6561, 146.4212],
  [-36.6591, 146.4199],
  [-36.6630, 146.4198],
  [-36.6658, 146.4197],
  [-36.6711, 146.4197],
  [-36.6765, 146.4187],
  [-36.6776, 146.4186],
  [-36.6909, 146.4201],
  [-36.6916, 146.4200],
  [-36.7075, 146.4139],
  [-36.7113, 146.4103],
  [-36.7120, 146.4100],
  [-36.7241, 146.4086],
  [-36.7248, 146.4084],
  [-36.7297, 146.4062],
  [-36.7308, 146.4061],
  [-36.7402, 146.4089],
  [-36.7424, 146.4101],
  [-36.7550, 146.4152],
  [-36.7563, 146.4152],
  [-36.7628, 146.4136],
  [-36.7642, 146.4139],
  [-36.7651, 146.4138]
];

export const whitfield: Line = {
  name: 'Whitfield',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1899-03-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-10-12',
          status: 'closed'
        }]
      }
    }
  ]
}
