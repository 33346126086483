import { Line } from "../../../trackTypes";

const ravenswoodJunctionRavenswood = [
  [-19.8776, 146.6358],
  [-19.8777, 146.6366],
  [-19.8783, 146.6371],
  [-19.8831, 146.6383],
  [-19.8840, 146.6389],
  [-19.8852, 146.6407],
  [-19.8870, 146.6415],
  [-19.8956, 146.6425],
  [-19.8971, 146.6436],
  [-19.8998, 146.6483],
  [-19.9013, 146.6497],
  [-19.9035, 146.6506],
  [-19.9043, 146.6515],
  [-19.9044, 146.6522],
  [-19.9044, 146.6531],
  [-19.9047, 146.6538],
  [-19.9060, 146.6545],
  [-19.9067, 146.6553],
  [-19.9068, 146.6562],
  [-19.9070, 146.6577],
  [-19.9072, 146.6589],
  [-19.9077, 146.6608],
  [-19.9076, 146.6638],
  [-19.9077, 146.6652],
  [-19.9206, 146.6831],
  [-19.9325, 146.6962],
  [-19.9407, 146.7031],
  [-19.9423, 146.7039],
  [-19.9438, 146.7051],
  [-19.9446, 146.7069],
  [-19.9449, 146.7081],
  [-19.9457, 146.7096],
  [-19.9572, 146.7221],
  [-19.9672, 146.7313],
  [-19.9712, 146.7350],
  [-19.9831, 146.7503],
  [-19.9857, 146.7519],
  [-19.9886, 146.7525],
  [-19.9932, 146.7525],
  [-19.9974, 146.7533],
  [-19.9985, 146.7542],
  [-20.0011, 146.7596],
  [-20.0018, 146.7620],
  [-20.0028, 146.7639],
  [-20.0125, 146.7719],
  [-20.0142, 146.7743],
  [-20.0156, 146.7787],
  [-20.0166, 146.7797],
  [-20.0203, 146.7825],
  [-20.0260, 146.7879],
  [-20.0290, 146.7901],
  [-20.0314, 146.7933],
  [-20.0327, 146.7945],
  [-20.0417, 146.7998],
  [-20.0461, 146.8043],
  [-20.0494, 146.8068],
  [-20.0511, 146.8094],
  [-20.0529, 146.8110],
  [-20.0536, 146.8123],
  [-20.0571, 146.8236],
  [-20.0586, 146.8257],
  [-20.0606, 146.8274],
  [-20.0622, 146.8294],
  [-20.0629, 146.8300],
  [-20.0636, 146.8302],
  [-20.0642, 146.8306],
  [-20.0645, 146.8313],
  [-20.0653, 146.8344],
  [-20.0665, 146.8360],
  [-20.0694, 146.8385],
  [-20.0700, 146.8397],
  [-20.0703, 146.8413],
  [-20.0706, 146.8427],
  [-20.0715, 146.8435],
  [-20.0739, 146.8449],
  [-20.0795, 146.8504],
  [-20.0800, 146.8517],
  [-20.0809, 146.8527],
  [-20.0829, 146.8539],
  [-20.0835, 146.8551],
  [-20.0836, 146.8570],
  [-20.0828, 146.8605],
  [-20.0830, 146.8617],
  [-20.0842, 146.8629],
  [-20.0871, 146.8640],
  [-20.0880, 146.8645],
  [-20.0964, 146.8733],
  [-20.0972, 146.8748],
  [-20.0990, 146.8765],
  [-20.0995, 146.8773],
  [-20.1005, 146.8784],
  [-20.1009, 146.8793],
  [-20.1011, 146.8806],
  [-20.1008, 146.8820],
  [-20.0995, 146.8840],
  [-20.0990, 146.8853],
  [-20.0990, 146.8866]
];

export const ravenswood: Line = {
  name: 'Ravenswood',
  state: 'QLD',
  segments: [
    {
      segments: [ravenswoodJunctionRavenswood],
      history: {
        opened: {
          date: '1884-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-11-01',
          status: 'closed'
        }]
      }
    }
  ]
}
