import { Line } from "../../../trackTypes";

const liverpoolJunctionAnzacRifleRange = [
  [-33.9238, 150.9284],
  [-33.9253, 150.9279],
  [-33.9265, 150.9289],
  [-33.9281, 150.9299],
  [-33.9291, 150.9314],
  [-33.9311, 150.9329],
  [-33.9317, 150.9331],
  [-33.9352, 150.9325],
  [-33.9395, 150.9319],
  [-33.9406, 150.9322],
  [-33.9455, 150.9369],
  [-33.9460, 150.9378],
  [-33.9463, 150.9401]
];

const anzacRifleRangeHolsworthy = [
  [-33.9463, 150.9401],
  [-33.9471, 150.9427],
  [-33.9509, 150.9512],
  [-33.9516, 150.9522],
  [-33.9530, 150.9530],
  [-33.9576, 150.9575],
  [-33.9617, 150.9594],
  [-33.9627, 150.9594],
  [-33.9692, 150.9585],
  [-33.9708, 150.9590],
  [-33.9731, 150.9616],
  [-33.9743, 150.9625],
  [-33.9755, 150.9625],
  [-33.9769, 150.9614],
  [-33.9781, 150.9609],
  [-33.9793, 150.9610],
  [-33.9806, 150.9616]
];

export const holsworthy: Line = {
  name: 'Holsworthy',
  state: 'NSW',
  segments: [
    {
      segments: [liverpoolJunctionAnzacRifleRange],
      history: {
        opened: {
          date: '1918-01-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-06-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [anzacRifleRangeHolsworthy],
      history: {
        opened: {
          date: '1918-01-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1923-10-01',
          status: 'closed'
        }]
      }
    }
  ]
};
