import { Line } from "../../../trackTypes";

const junctionShared = [
  [-34.4397, 150.8835],
  [-34.4423, 150.8807],
  [-34.4433, 150.8799],
  [-34.4533, 150.8760],
  [-34.4546, 150.8758],
  [-34.4652, 150.8775],
  [-34.4668, 150.8782],
  [-34.4678, 150.8791],
  [-34.4687, 150.8803]
];

const shared = [
  [-34.4687, 150.8803],
  [-34.4716, 150.8842],
  [-34.4725, 150.8863],
  [-34.4728, 150.8895]
];

const sharedEnd = [
  [-34.4728, 150.8895],
  [-34.4732, 150.8918],
  [-34.4743, 150.8933],
  [-34.4762, 150.8951],
  [-34.4770, 150.8962],
  [-34.4774, 150.8977],
  [-34.4774, 150.8999],
  [-34.4770, 150.9021],
  [-34.4771, 150.9036]
];

const triangle = [
  [-34.4484, 150.8779],
  [-34.4456, 150.8781],
  [-34.4445, 150.8778],
  [-34.4438, 150.8771],
  [-34.4434, 150.8763],
  [-34.4431, 150.8751]
];

export const portKembla: Line = {
  name: 'PortKembla',
  state: 'NSW',
  segments: [
    {
      segments: [junctionShared, sharedEnd],
      history: {
        opened: {
          date: '1916-07-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1941-07-15',
          tracks: 2
        }, {
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [shared],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1941-07-15',
          tracks: 2
        }, {
          date: '1986-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [triangle],
      history: {
        opened: {
          date: '1961-11-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
