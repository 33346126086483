import { Line } from "../../../trackTypes";

const line = [
  [-28.4227, 151.0768],
  [-28.4234, 151.0761],
  [-28.4241, 151.0759],
  [-28.4307, 151.0756],
  [-28.4691, 151.0734],
  [-28.4719, 151.0739],
  [-28.4753, 151.0754],
  [-28.4777, 151.0759],
  [-28.5132, 151.0755],
  [-28.5145, 151.0751],
  [-28.5158, 151.0738],
  [-28.5171, 151.0723],
  [-28.5184, 151.0717],
  [-28.5198, 151.0716],
  [-28.5206, 151.0713],
  [-28.5221, 151.0702],
  [-28.5230, 151.0699],
  [-28.5239, 151.0702],
  [-28.5292, 151.0739],
  [-28.5327, 151.0748],
  [-28.5901, 151.0681],
  [-28.6064, 151.0676],
  [-28.6426, 151.0594],
  [-28.6666, 151.0513],
  [-28.6903, 151.0496],
  [-28.7153, 151.0509],
  [-28.7552, 151.0609],
  [-28.7568, 151.0610],
  [-28.7674, 151.0588],
  [-28.7695, 151.0591],
  [-28.7773, 151.0637],
  [-28.7784, 151.0647],
  [-28.7850, 151.0741],
  [-28.8180, 151.1104],
  [-28.8260, 151.1297],
  [-28.8268, 151.1345],
  [-28.8278, 151.1363],
  [-28.8319, 151.1395],
  [-28.8330, 151.1409],
  [-28.8367, 151.1486],
  [-28.8450, 151.1599],
  [-28.8467, 151.1609],
  [-28.8499, 151.1612],
  [-28.8507, 151.1616],
  [-28.8553, 151.1659],
  [-28.8557, 151.1669],
  [-28.8559, 151.1691],
  [-28.8572, 151.1738],
  [-28.8581, 151.1782]
];

export const texas: Line = {
  name: 'Texas',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1930-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
