import { Line } from "../../trackTypes";

const wigginsIslandOne = [
  [-23.8653, 151.2248],
  [-23.8645, 151.2275],
  [-23.8636, 151.2296],
  [-23.8625, 151.2306],
  [-23.8612, 151.2308],
  [-23.8589, 151.2304],
  [-23.8553, 151.2298],
  [-23.8542, 151.2293],
  [-23.8515, 151.2273],
  [-23.8490, 151.2263],
  [-23.8483, 151.2263],
  [-23.8452, 151.2272],
  [-23.8343, 151.2298],
  [-23.8311, 151.2306],
  [-23.8278, 151.2318],
  [-23.8267, 151.2317],
  [-23.8256, 151.2312],
  [-23.8224, 151.2282],
  [-23.8218, 151.2263],
  [-23.8223, 151.2246],
  [-23.8235, 151.2235],
  [-23.8246, 151.2233],
  [-23.8261, 151.2237],
  [-23.8320, 151.2292],
  [-23.8330, 151.2297],
  [-23.8343, 151.2298]
];

const wigginsIslandTwo = [
  [-23.8672, 151.2062],
  [-23.8657, 151.2075],
  [-23.8651, 151.2085],
  [-23.8637, 151.2195],
  [-23.8633, 151.2204],
  [-23.8624, 151.2211],
  [-23.8612, 151.2211],
  [-23.8588, 151.2199],
  [-23.8580, 151.2198],
  [-23.8528, 151.2213],
  [-23.8493, 151.2214],
  [-23.8486, 151.2216],
  [-23.8471, 151.2228],
  [-23.8459, 151.2232],
  [-23.8443, 151.2232],
  [-23.8434, 151.2236],
  [-23.8428, 151.2246],
  [-23.8427, 151.2257],
  [-23.8433, 151.2267],
  [-23.8441, 151.2271],
  [-23.8452, 151.2272]
];

export const wigginsIsland: Line = {
  name: 'Wiggins Island',
  state: 'QLD',
  segments: [
    {
      segments: [wigginsIslandOne, wigginsIslandTwo],
      history: {
        opened: {
          date: '2015-04-01',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
