import { Line } from "../../../trackTypes";

const line = [
  [-27.1645, 151.2472],
  [-27.1632, 151.2459],
  [-27.1618, 151.2452],
  [-27.1545, 151.2447],
  [-27.0062, 151.2335],
  [-27.0045, 151.2331],
  [-26.8510, 151.1679],
  [-26.8494, 151.1668],
  [-26.8076, 151.1289],
  [-26.7809, 151.1071]
];

export const jandowae: Line = {
  name: 'Janowae',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-08-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    }
  ]
}
