import { Line } from "../../trackTypes";

//maquarie group
const maquarieStreetLine = [
  [-42.87849, 147.33054],
  [-42.87838, 147.33083],
  [-42.87835, 147.33120],
  [-42.87839, 147.33162],
  [-42.87885, 147.33412],
  [-42.87894, 147.33432],
  [-42.87904, 147.33439],
  [-42.87918, 147.33437],
  [-42.87966, 147.33406],
  [-42.87995, 147.33378],
  [-42.88262, 147.33034],
  [-42.88384, 147.32877],
  [-42.88848, 147.32280],
  [-42.89072, 147.31995],
  [-42.89175, 147.31860],
  [-42.89186, 147.31841],
  [-42.89193, 147.31823],
  [-42.89276, 147.31549],
  [-42.89358, 147.31185],
  [-42.89367, 147.31153],
  [-42.89459, 147.30908],
  [-42.89473, 147.30860],
  [-42.89543, 147.30473],
  [-42.89550, 147.30395],
  [-42.89549, 147.30358],
  [-42.89545, 147.30322],
  [-42.89504, 147.30154],
  [-42.89500, 147.30128],
  [-42.89500, 147.30099],
  [-42.89511, 147.30007],
  [-42.89585, 147.29544]
];

const proctorsRoadBranch = [
  [-42.89072, 147.31995],
  [-42.89078, 147.31993],
  [-42.89084, 147.31993],
  [-42.89090, 147.31995],
  [-42.89213, 147.32176],
  [-42.89224, 147.32200],
  [-42.89233, 147.32223],
  [-42.89243, 147.32237],
  [-42.89255, 147.32246],
  [-42.89273, 147.32251],
  [-42.89332, 147.32258],
  [-42.89347, 147.32261],
  [-42.89530, 147.32315],
  [-42.89536, 147.32312],
  [-42.89541, 147.32301],
  [-42.89583, 147.32092],
  [-42.89595, 147.32066],
  [-42.89780, 147.31808],
  [-42.89803, 147.31780],
  [-42.89842, 147.31738],
  [-42.89870, 147.31716],
  [-42.89924, 147.31696],
  [-42.89956, 147.31689],
  [-42.90006, 147.31685],
  [-42.90050, 147.31690]
];

const proctorsExtension = [
  [-42.90050, 147.31690],
  [-42.90081, 147.31703],
  [-42.90107, 147.31725],
  [-42.90145, 147.31773],
  [-42.90214, 147.31856]
];

//elizabeth group
const elizabethStreetLine = [
  [-42.88262, 147.33034],
  [-42.88266, 147.33024],
  [-42.88267, 147.33014],
  [-42.88265, 147.33001],
  [-42.88206, 147.32886],
  [-42.88118, 147.32690],
  [-42.87835, 147.32067],
  [-42.87807, 147.32000],
  [-42.87785, 147.31937],
  [-42.87774, 147.31919],
  [-42.87764, 147.31910],
  [-42.87750, 147.31900],
  [-42.87700, 147.31877],
  [-42.87580, 147.31807],
  [-42.87555, 147.31790],
  [-42.87523, 147.31765],
  [-42.87445, 147.31690],
  [-42.87411, 147.31655],
  [-42.87265, 147.31489],
  [-42.87213, 147.31441],
  [-42.87175, 147.31409],
  [-42.87160, 147.31394],
  [-42.87145, 147.31372],
  [-42.87119, 147.31329],
  [-42.87049, 147.31203],
  [-42.87033, 147.31177],
  [-42.87015, 147.31156],
  [-42.87000, 147.31149],
  [-42.86986, 147.31146],
  [-42.86973, 147.31149],
  [-42.86947, 147.31159],
  [-42.86912, 147.31181],
  [-42.86893, 147.31190],
  [-42.86869, 147.31195],
  [-42.86845, 147.31195],
  [-42.86817, 147.31188],
  [-42.86788, 147.31175],
  [-42.86623, 147.31114],
  [-42.86504, 147.31082],
  [-42.86429, 147.31071],
  [-42.86406, 147.31070],
  [-42.86379, 147.31073],
  [-42.86296, 147.31085],
  [-42.86277, 147.31082],
  [-42.86258, 147.31077],
  [-42.86225, 147.31058],
  [-42.86195, 147.31036],
  [-42.86177, 147.31019],
  [-42.86166, 147.31003],
  [-42.86106, 147.30894],
  [-42.85984, 147.30645],
  [-42.85930, 147.30543],
  [-42.85910, 147.30512],
  [-42.85892, 147.30488],
  [-42.85869, 147.30468],
  [-42.85841, 147.30450],
  [-42.85786, 147.30430],
  [-42.85684, 147.30386],
  [-42.85619, 147.30368],
  [-42.85600, 147.30361],
  [-42.85584, 147.30352],
  [-42.85568, 147.30338],
  [-42.85199, 147.29907],
  [-42.85179, 147.29885],
  [-42.85144, 147.29853],
  [-42.85001, 147.29748],
  [-42.84822, 147.29609]
];

const glenorchyBranch = [
  [-42.84017, 147.29025],
  [-42.83746, 147.28822],
  [-42.83662, 147.28743],
  [-42.83649, 147.28729],
  [-42.83634, 147.28711],
  [-42.83613, 147.28678],
  [-42.83469, 147.28393],
  [-42.83443, 147.28329]
];

const glenorchyExtension = [
  [-42.83443, 147.28329],
  [-42.83432, 147.28297],
  [-42.83423, 147.28264],
  [-42.83331, 147.27749],
  [-42.83294, 147.27478]
];

const glenorchyExtension2 = [
  [-42.83294, 147.27478],
  [-42.83295, 147.27467],
  [-42.83298, 147.27458],
  [-42.83306, 147.27449],
  [-42.83373, 147.27415]
];

const lenahValleyBranch = [
  [-42.86788, 147.31175],
  [-42.86782, 147.31167],
  [-42.86774, 147.31148],
  [-42.86714, 147.30645],
  [-42.86707, 147.30603],
  [-42.86658, 147.30418],
  [-42.86650, 147.30379],
  [-42.86646, 147.30342],
  [-42.86646, 147.30300],
  [-42.86655, 147.30211],
  [-42.86654, 147.30174],
  [-42.86646, 147.30088],
  [-42.86617, 147.29838],
  [-42.86609, 147.29799],
  [-42.86595, 147.29745],
  [-42.86554, 147.29565],
  [-42.86540, 147.29526],
  [-42.86333, 147.29132],
  [-42.86322, 147.29108],
  [-42.86314, 147.29075],
  [-42.86286, 147.28904]
];

const springfieldbranch = [
  [-42.84822, 147.29609],
  [-42.84514, 147.29376],
  [-42.84017, 147.29025],
  [-42.84014, 147.29016],
  [-42.84014, 147.29009],
  [-42.84015, 147.29002],
  [-42.84204, 147.28691],
  [-42.84361, 147.28434],
  [-42.84374, 147.28415]
];

//sandy group
const sandyBayLine = [
  [-42.88384, 147.32877],
  [-42.88392, 147.32874],
  [-42.88401, 147.32875],
  [-42.88409, 147.32879],
  [-42.88443, 147.32943],
  [-42.88448, 147.32946],
  [-42.88453, 147.32948],
  [-42.88460, 147.32946],
  [-42.88600, 147.32772],
  [-42.88613, 147.32766],
  [-42.88621, 147.32763],
  [-42.88629, 147.32764],
  [-42.88643, 147.32771],
  [-42.88730, 147.32896],
  [-42.88743, 147.32915],
  [-42.88761, 147.32936],
  [-42.88823, 147.32997],
  [-42.88838, 147.33007],
  [-42.88856, 147.33017],
  [-42.88902, 147.33032],
  [-42.88915, 147.33034],
  [-42.88937, 147.33033],
  [-42.88965, 147.33023],
  [-42.88994, 147.33006],
  [-42.89018, 147.32987],
  [-42.89037, 147.32966],
  [-42.89055, 147.32938],
  [-42.89072, 147.32909],
  [-42.89088, 147.32874],
  [-42.89132, 147.32774],
  [-42.89142, 147.32744],
  [-42.89157, 147.32699],
  [-42.89168, 147.32675],
  [-42.89180, 147.32657],
  [-42.89197, 147.32642],
  [-42.89234, 147.32614],
  [-42.89262, 147.32595],
  [-42.89286, 147.32582],
  [-42.89314, 147.32575],
  [-42.89339, 147.32574],
  [-42.89363, 147.32578],
  [-42.89396, 147.32592],
  [-42.89418, 147.32606],
  [-42.89443, 147.32628],
  [-42.89464, 147.32653],
  [-42.89524, 147.32755],
  [-42.89542, 147.32781],
  [-42.89559, 147.32799],
  [-42.89580, 147.32813],
  [-42.89658, 147.32850],
  [-42.89805, 147.32918],
  [-42.89823, 147.32929],
  [-42.89896, 147.32995],
  [-42.89969, 147.33060],
  [-42.89986, 147.33078],
  [-42.90100, 147.33247],
  [-42.90216, 147.33417],
  [-42.90231, 147.33443],
  [-42.90248, 147.33473],
  [-42.90282, 147.33551],
  [-42.90298, 147.33584],
  [-42.90319, 147.33619],
  [-42.90361, 147.33676],
  [-42.90382, 147.33699],
  [-42.90435, 147.33749],
  [-42.90453, 147.33770],
  [-42.90466, 147.33792],
  [-42.90481, 147.33828],
  [-42.90522, 147.33905],
  [-42.90569, 147.33984],
  [-42.90585, 147.34025],
  [-42.90596, 147.34072],
  [-42.90604, 147.34099],
  [-42.90620, 147.34128],
  [-42.90669, 147.34214],
  [-42.90706, 147.34274],
  [-42.90721, 147.34297],
  [-42.90788, 147.34385],
  [-42.90800, 147.34409],
  [-42.90817, 147.34450],
  [-42.90845, 147.34535],
  [-42.90897, 147.34682],
  [-42.90923, 147.34753],
  [-42.91012, 147.34970],
  [-42.91054, 147.35055],
  [-42.91068, 147.35077],
  [-42.91079, 147.35093],
  [-42.91108, 147.35123],
  [-42.91120, 147.35144],
  [-42.91160, 147.35242]
];

const sandyBayExtension = [
  [-42.91160, 147.35242],
  [-42.91168, 147.35258],
  [-42.91179, 147.35275],
  [-42.91197, 147.35303],
  [-42.91238, 147.35360],
  [-42.91254, 147.35386],
  [-42.91270, 147.35415],
  [-42.91411, 147.35739],
  [-42.91421, 147.35778],
  [-42.91423, 147.35799],
  [-42.91420, 147.35890]
];

//liverpool group
const liverpoolStreetLineStart = [
  [-42.87722, 147.33205],
  [-42.87849, 147.33054],
  [-42.88021, 147.32834],
  [-42.88495, 147.32314],
  [-42.88600, 147.32202],
  [-42.88708, 147.32084],
  [-42.88713, 147.32072],
  [-42.88713, 147.32060],
  [-42.88659, 147.31979],
  [-42.88657, 147.31969],
  [-42.88657, 147.31962],
  [-42.88660, 147.31950],
  [-42.88687, 147.31911],
  [-42.88695, 147.31899],
  [-42.88699, 147.31884],
  [-42.88719, 147.31781],
  [-42.88724, 147.31731],
  [-42.88726, 147.31629],
  [-42.88722, 147.31562],
  [-42.88712, 147.31460],
  [-42.88699, 147.31365]
];

const theY = [
  [-42.88699, 147.31365],
  [-42.88689, 147.31311],
  [-42.88683, 147.31270]
];

const theYend = [
  [-42.88689, 147.31311],
  [-42.88687, 147.31324],
  [-42.88682, 147.31336],
  [-42.88668, 147.31366]
];

const goulburnCavellJunction = [
  [-42.88699, 147.31365],
  [-42.88694, 147.31360],
  [-42.88686, 147.31357],
  [-42.88677, 147.31360],
  [-42.88668, 147.31366]
];

const liverpoolStreetLineEnd = [
  [-42.88668, 147.31366],
  [-42.88544, 147.31614],
  [-42.88536, 147.31620],
  [-42.88529, 147.31622],
  [-42.88522, 147.31621],
  [-42.88383, 147.31501],
  [-42.88380, 147.31493],
  [-42.88379, 147.31485],
  [-42.88378, 147.31476],
  [-42.88396, 147.31425],
  [-42.88404, 147.31390],
  [-42.88410, 147.31354],
  [-42.88414, 147.31300],
  [-42.88409, 147.31241],
  [-42.88403, 147.31197],
  [-42.88389, 147.31151],
  [-42.88368, 147.31106],
  [-42.88350, 147.31080],
  [-42.88324, 147.31043],
  [-42.88284, 147.31007],
  [-42.88244, 147.30983],
  [-42.88202, 147.30967],
  [-42.88167, 147.30967],
  [-42.88132, 147.30972],
  [-42.88090, 147.30984],
  [-42.88062, 147.31001],
  [-42.88023, 147.31032],
  [-42.88001, 147.31059],
  [-42.87980, 147.31090],
  [-42.87967, 147.31112],
  [-42.87960, 147.31118],
  [-42.87954, 147.31120],
  [-42.87947, 147.31121],
  [-42.87758, 147.30958]
];

const liverpoolExtension = [
  [-42.87758, 147.30958],
  [-42.87756, 147.30948],
  [-42.87757, 147.30938],
  [-42.87777, 147.30894],
  [-42.87779, 147.30880],
  [-42.87775, 147.30872],
  [-42.87656, 147.30755]
];

const liverpoolConnector = [
  [-42.88848, 147.32280],
  [-42.88851, 147.32270],
  [-42.88850, 147.32260],
  [-42.88713, 147.32060]
];

//north hobart group
const northHobartLine = [
  [-42.87849, 147.33054],
  [-42.87828, 147.33052],
  [-42.87804, 147.33045],
  [-42.87776, 147.33022],
  [-42.87753, 147.32985],
  [-42.87687, 147.32842],
  [-42.87657, 147.32798],
  [-42.87655, 147.32776],
  [-42.87661, 147.32757],
  [-42.87691, 147.32715],
  [-42.87695, 147.32699],
  [-42.87693, 147.32689],
  [-42.87365, 147.32228],
  [-42.87345, 147.32207],
  [-42.87325, 147.32193],
  [-42.87286, 147.32179],
  [-42.87266, 147.32177],
  [-42.87246, 147.32180],
  [-42.87228, 147.32186],
  [-42.87203, 147.32203],
  [-42.87185, 147.32218],
  [-42.87177, 147.32226],
  [-42.87168, 147.32232],
  [-42.87160, 147.32232],
  [-42.87152, 147.32229],
  [-42.87085, 147.32161],
  [-42.86938, 147.32008],
  [-42.86771, 147.31833]
];

export const hobartTrams: Line = {
  name: 'Hobart Trams',
  state: 'TAS',
  segments: [
    {
      segments: [maquarieStreetLine],
      history: {
        opened: {
          date: '1893-09-23',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-04-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [proctorsRoadBranch],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-04-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [proctorsExtension],
      history: {
        opened: {
          date: '1934-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-04-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elizabethStreetLine],
      history: {
        opened: {
          date: '1893-09-23',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenorchyBranch],
      history: {
        opened: {
          date: '1923-03-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenorchyExtension],
      history: {
        opened: {
          date: '1931-04-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenorchyExtension2],
      history: {
        opened: {
          date: '1937-12-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [springfieldbranch],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lenahValleyBranch],
      history: {
        opened: {
          date: '1922-09-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandyBayLine],
      history: {
        opened: {
          date: '1893-09-23',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandyBayExtension],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        liverpoolStreetLineStart,
        {
          date: '1932-01-01',
          original: [theY, theYend],
          deviation: [goulburnCavellJunction]
        },
        liverpoolStreetLineEnd
      ],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [liverpoolExtension],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [liverpoolConnector],
      history: {
        opened: {
          date: '1951-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northHobartLine],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
