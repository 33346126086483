import { Line } from "../../../trackTypes";

const korumburraJumbunna = [
  [-38.4368, 145.8271],
  [-38.4378, 145.8260],
  [-38.4385, 145.8242],
  [-38.4386, 145.8221],
  [-38.4386, 145.8212],
  [-38.4384, 145.8199],
  [-38.4387, 145.8185],
  [-38.4422, 145.8154],
  [-38.4431, 145.8141],
  [-38.4434, 145.8116],
  [-38.4437, 145.8106],
  [-38.4445, 145.8095],
  [-38.4449, 145.8080],
  [-38.4449, 145.8062],
  [-38.4454, 145.8048],
  [-38.4482, 145.8018],
  [-38.4519, 145.7974],
  [-38.4545, 145.7956],
  [-38.4563, 145.7929],
  [-38.4573, 145.7919],
  [-38.4581, 145.7900],
  [-38.4585, 145.7894],
  [-38.4594, 145.7888],
  [-38.4602, 145.7878],
  [-38.4632, 145.7827],
  [-38.4641, 145.7816],
  [-38.4676, 145.7782],
  [-38.4683, 145.7768],
  [-38.4684, 145.7763]
];

const jumbunnaOuttrim = [
  [-38.4684, 145.7763],
  [-38.4689, 145.7745],
  [-38.4694, 145.7737],
  [-38.4702, 145.7733],
  [-38.4710, 145.7731],
  [-38.4719, 145.7726],
  [-38.4733, 145.7711],
  [-38.4740, 145.7707],
  [-38.4749, 145.7707],
  [-38.4777, 145.7717],
  [-38.4785, 145.7726],
  [-38.4790, 145.7742],
  [-38.4797, 145.7751],
  [-38.4807, 145.7753],
  [-38.4817, 145.7748],
  [-38.4827, 145.7744],
  [-38.4838, 145.7740],
  [-38.4846, 145.7733],
  [-38.4851, 145.7720],
  [-38.4859, 145.7712],
  [-38.4899, 145.7700],
  [-38.4907, 145.7693],
  [-38.4909, 145.7682],
  [-38.4908, 145.7675],
  [-38.4901, 145.7660],
  [-38.4901, 145.7651],
  [-38.4905, 145.7642],
  [-38.4917, 145.7632],
  [-38.4923, 145.7631],
  [-38.4931, 145.7629]
];

export const outtrim: Line = {
  name: 'Outtrim',
  state: 'VIC',
  segments: [
    {
      segments: [korumburraJumbunna],
      history: {
        opened: {
          date: '1894-05-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-09-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [jumbunnaOuttrim],
      history: {
        opened: {
          date: '1896-02-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-09-04',
          status: 'closed'
        }]
      }
    }
  ]
};
