import { Line } from "../../trackTypes";

const perthClaisebrook = [
  [-31.9511, 115.8599],
  [-31.9522, 115.8624],
  [-31.9525, 115.8635],
  [-31.9523, 115.8646],
  [-31.9500, 115.8712],
  [-31.9487, 115.8738]
];

const claisebrookEastPerth = [
  [-31.9487, 115.8738],
  [-31.9478, 115.8753],
  [-31.9468, 115.8762]
];

const eastPerthGuildford = [
  [-31.9468, 115.8762],
  [-31.9412, 115.8779],
  [-31.9395, 115.8780],
  [-31.9387, 115.8782],
  [-31.9369, 115.8790],
  [-31.9352, 115.8802],
  [-31.9342, 115.8816],
  [-31.9331, 115.8848],
  [-31.9230, 115.8993],
  [-31.9217, 115.9017],
  [-31.9195, 115.9074],
  [-31.9180, 115.9129],
  [-31.9179, 115.9146],
  [-31.9186, 115.9195],
  [-31.9198, 115.9233],
  [-31.9199, 115.9253],
  [-31.9196, 115.9271],
  [-31.9189, 115.9286],
  [-31.9040, 115.9464],
  [-31.9030, 115.9479],
  [-31.9007, 115.9532],
  [-31.9002, 115.9551],
  [-31.8989, 115.9637],
  [-31.8989, 115.9658]
];

const guildfordMidlandJunction = [
  [-31.8989, 115.9658],
  [-31.8989, 115.9746],
  [-31.8984, 115.9766],
  [-31.8929, 115.9860],
  [-31.8924, 115.9875],
  [-31.8915, 115.9932],
  [-31.8913, 115.9947],
  [-31.8913, 115.9986],
  [-31.8920, 116.0011],
];

const midlandDeviationPoint = [
  [-31.8920, 116.0011],
  [-31.8988, 116.0285],
  [-31.8988, 116.0285]
];

const midlandDeviationPoint2 = [
  [-31.8988, 116.0285],
  [-31.8989, 116.0309],
  [-31.8980, 116.0334],
  [-31.8965, 116.0359]
];

const midlandJunctionDeviation = [
  [-31.8965, 116.0359],
  [-31.8953, 116.0373],
  [-31.8939, 116.0381],
  [-31.8923, 116.0384],
  [-31.8907, 116.0381],
  [-31.8851, 116.0358],
  [-31.8827, 116.0354],
  [-31.8787, 116.0353],
  [-31.8751, 116.0347],
  [-31.8717, 116.0330],
  [-31.8682, 116.0298],
  [-31.8615, 116.0216],
  [-31.8579, 116.0194],
  [-31.8551, 116.0188]
];

const midlandJunctionOld = [
  [-31.8913, 115.9947],
  [-31.8899, 116.0013],
  [-31.8884, 116.0103],
  [-31.8873, 116.0131],
  [-31.8852, 116.0153],
  [-31.8828, 116.0163],
  [-31.8551, 116.0188]
];

const eastLineJunctionDevationPoint = [
  [-31.8551, 116.0188],
  [-31.8372, 116.0203],
  [-31.8352, 116.0207],
  [-31.8101, 116.0284],
  [-31.8078, 116.0285],
  [-31.8041, 116.0283]
];

const eastLineJunctionGingin = [
  [-31.8041, 116.0283],
  [-31.7885, 116.0273],
  [-31.7863, 116.0267],
  [-31.7795, 116.0238],
  [-31.7782, 116.0236],
  [-31.7768, 116.0239],
  [-31.7742, 116.0253],
  [-31.7716, 116.0258],
  [-31.7491, 116.0245],
  [-31.7468, 116.0235],
  [-31.7095, 116.0014],
  [-31.7061, 116.0004],
  [-31.6351, 115.9970],
  [-31.6331, 115.9966],
  [-31.6087, 115.9872],
  [-31.5809, 115.9765],
  [-31.5670, 115.9712],
  [-31.5399, 115.9608],
  [-31.5265, 115.9531],
  [-31.5248, 115.9523],
  [-31.3982, 115.9095],
  [-31.3963, 115.9086],
  [-31.3856, 115.9010],
  [-31.3824, 115.9000],
  [-31.3605, 115.8985],
  [-31.3584, 115.8988],
  [-31.3555, 115.9001],
  [-31.3536, 115.9016],
  [-31.3510, 115.9047]
];

const ginGinMogumbur = [
  [-31.3510, 115.9047],
  [-31.3499, 115.9062],
  [-31.3493, 115.9074],
  [-31.3490, 115.9085],
  [-31.3478, 115.9237],
  [-31.3471, 115.9263],
  [-31.3372, 115.9444],
  [-31.3365, 115.9465],
  [-31.3336, 115.9623],
  [-31.3326, 115.9648],
  [-31.3302, 115.9682],
  [-31.3296, 115.9697],
  [-31.3296, 115.9711],
  [-31.3300, 115.9724],
  [-31.3326, 115.9785],
  [-31.3340, 115.9809],
  [-31.3352, 115.9859],
  [-31.3358, 115.9872],
  [-31.3367, 115.9885],
  [-31.3373, 115.9897],
  [-31.3376, 115.9911],
  [-31.3374, 115.9938],
  [-31.3378, 115.9977],
  [-31.3380, 115.9990],
  [-31.3388, 116.0017],
  [-31.3391, 116.0040],
  [-31.3394, 116.0054],
  [-31.3400, 116.0072],
  [-31.3403, 116.0087],
  [-31.3400, 116.0103],
  [-31.3393, 116.0115],
  [-31.3244, 116.0274],
  [-31.3223, 116.0286],
  [-31.3194, 116.0293],
  [-31.3179, 116.0291],
  [-31.3166, 116.0285],
  [-31.3083, 116.0232],
  [-31.3068, 116.0230],
  [-31.3053, 116.0235],
  [-31.3041, 116.0246],
  [-31.3028, 116.0270],
  [-31.3014, 116.0308],
  [-31.3007, 116.0318],
  [-31.2999, 116.0325],
  [-31.2969, 116.0338],
  [-31.2954, 116.0339],
  [-31.2937, 116.0338],
  [-31.2923, 116.0341],
  [-31.2778, 116.0420],
  [-31.2752, 116.0427],
  [-31.2724, 116.0427],
  [-31.2486, 116.0386],
  [-31.2454, 116.0387],
  [-31.2433, 116.0393],
  [-31.2401, 116.0408],
  [-31.2392, 116.0409],
  [-31.2382, 116.0407],
  [-31.2299, 116.0374],
  [-31.2282, 116.0372],
  [-31.2152, 116.0387],
  [-31.2141, 116.0391],
  [-31.2133, 116.0397],
  [-31.2112, 116.0422],
  [-31.2100, 116.0429],
  [-31.1948, 116.0452],
  [-31.1929, 116.0458],
  [-31.1713, 116.0557],
  [-31.1697, 116.0561],
  [-31.1679, 116.0560],
  [-31.1592, 116.0545],
  [-31.1496, 116.0518],
  [-31.1474, 116.0516],
  [-31.1233, 116.0532],
  [-31.1213, 116.0530],
  [-31.1166, 116.0515],
  [-31.1149, 116.0514],
  [-31.1056, 116.0521],
  [-31.1031, 116.0517],
  [-31.0935, 116.0486],
  [-31.0914, 116.0484],
  [-31.0760, 116.0476],
  [-31.0702, 116.0485],
  [-31.0677, 116.0483],
  [-31.0518, 116.0425],
  [-31.0499, 116.0423],
  [-31.0385, 116.0439]
];

const mogumburMora = [
  [-31.0385, 116.0439],
  [-31.0375, 116.0440],
  [-31.0359, 116.0436],
  [-31.0347, 116.0426],
  [-31.0330, 116.0408],
  [-31.0321, 116.0402],
  [-31.0310, 116.0400],
  [-30.9810, 116.0398],
  [-30.9668, 116.0414],
  [-30.9647, 116.0425],
  [-30.9632, 116.0440],
  [-30.9587, 116.0507],
  [-30.9562, 116.0525],
  [-30.9540, 116.0532],
  [-30.9512, 116.0530],
  [-30.9446, 116.0511],
  [-30.9428, 116.0512],
  [-30.9398, 116.0524],
  [-30.9377, 116.0524],
  [-30.9340, 116.0510],
  [-30.9323, 116.0496],
  [-30.9257, 116.0393],
  [-30.9228, 116.0359],
  [-30.9215, 116.0352],
  [-30.9201, 116.0351],
  [-30.9081, 116.0364],
  [-30.9062, 116.0361],
  [-30.9048, 116.0354],
  [-30.8894, 116.0250],
  [-30.8881, 116.0248],
  [-30.8869, 116.0250],
  [-30.8845, 116.0262],
  [-30.8754, 116.0299],
  [-30.8739, 116.0301],
  [-30.8654, 116.0290],
  [-30.8279, 116.0282],
  [-30.7743, 116.0231],
  [-30.7728, 116.0233],
  [-30.7441, 116.0295],
  [-30.7421, 116.0295],
  [-30.6358, 116.0073]
];

const moraArrino = [
  [-30.6358, 116.0073],
  [-30.6255, 116.0052],
  [-30.6215, 116.0055],
  [-30.5710, 116.0193],
  [-30.5446, 116.0288],
  [-30.5428, 116.0289],
  [-30.5298, 116.0265],
  [-30.4577, 116.0389],
  [-30.4300, 116.0446],
  [-30.4231, 116.0446],
  [-30.4219, 116.0442],
  [-30.4201, 116.0434],
  [-30.4180, 116.0432],
  [-30.4068, 116.0409],
  [-30.4047, 116.0410],
  [-30.3930, 116.0445],
  [-30.3615, 116.0555],
  [-30.3550, 116.0593],
  [-30.3514, 116.0600],
  [-30.3387, 116.0645],
  [-30.3322, 116.0683],
  [-30.3306, 116.0688],
  [-30.3236, 116.0691],
  [-30.3222, 116.0688],
  [-30.3179, 116.0667],
  [-30.3160, 116.0649],
  [-30.3147, 116.0630],
  [-30.3128, 116.0613],
  [-30.3030, 116.0574],
  [-30.3013, 116.0573],
  [-30.2903, 116.0594],
  [-30.2881, 116.0593],
  [-30.2827, 116.0580],
  [-30.2798, 116.0588],
  [-30.2701, 116.0665],
  [-30.2678, 116.0674],
  [-30.2443, 116.0717],
  [-30.2430, 116.0723],
  [-30.2423, 116.0731],
  [-30.2411, 116.0750],
  [-30.2397, 116.0761],
  [-30.2321, 116.0788],
  [-30.2151, 116.0789],
  [-30.2113, 116.0778],
  [-30.2094, 116.0778],
  [-30.1977, 116.0792],
  [-30.1839, 116.0826],
  [-30.1818, 116.0826],
  [-30.1680, 116.0814],
  [-30.1654, 116.0821],
  [-30.1589, 116.0853],
  [-30.1574, 116.0857],
  [-30.1537, 116.0856],
  [-30.1513, 116.0846],
  [-30.1370, 116.0835],
  [-30.1312, 116.0840],
  [-30.1212, 116.0831],
  [-30.1126, 116.0814],
  [-30.1081, 116.0820],
  [-30.1063, 116.0828],
  [-30.1022, 116.0866],
  [-30.1003, 116.0874],
  [-30.0981, 116.0873],
  [-30.0767, 116.0808],
  [-30.0697, 116.0804],
  [-30.0688, 116.0800],
  [-30.0680, 116.0792],
  [-30.0671, 116.0777],
  [-30.0622, 116.0748],
  [-30.0606, 116.0743],
  [-30.0135, 116.0750],
  [-30.0207, 116.0747],
  [-30.0193, 116.0743],
  [-30.0102, 116.0697],
  [-30.0090, 116.0688],
  [-30.0066, 116.0658],
  [-30.0034, 116.0641],
  [-30.0021, 116.0638],
  [-29.9953, 116.0647],
  [-29.9922, 116.0661],
  [-29.9895, 116.0678],
  [-29.9882, 116.0682],
  [-29.9705, 116.0658],
  [-29.9692, 116.0660],
  [-29.9653, 116.0676],
  [-29.9640, 116.0676],
  [-29.9519, 116.0630],
  [-29.9440, 116.0582],
  [-29.9430, 116.0569],
  [-29.9421, 116.0563],
  [-29.9405, 116.0559],
  [-29.9340, 116.0505],
  [-29.9313, 116.0490],
  [-29.9182, 116.0363],
  [-29.9170, 116.0356],
  [-29.8956, 116.0293],
  [-29.8942, 116.0282],
  [-29.8924, 116.0259],
  [-29.8910, 116.0249],
  [-29.8845, 116.0218],
  [-29.8802, 116.0187],
  [-29.8795, 116.0176],
  [-29.8791, 116.0162],
  [-29.8788, 116.0131],
  [-29.8780, 116.0115],
  [-29.8768, 116.0040],
  [-29.8761, 116.0024],
  [-29.8734, 115.9994],
  [-29.8720, 115.9989],
  [-29.8711, 115.9980],
  [-29.8670, 115.9928],
  [-29.8660, 115.9922],
  [-29.8650, 115.9918],
  [-29.8641, 115.9910],
  [-29.8634, 115.9897],
  [-29.8623, 115.9887],
  [-29.8606, 115.9879],
  [-29.8358, 115.9648],
  [-29.8339, 115.9639],
  [-29.8312, 115.9637],
  [-29.8288, 115.9646],
  [-29.8209, 115.9709],
  [-29.8170, 115.9717],
  [-29.8131, 115.9701],
  [-29.7817, 115.9351],
  [-29.7766, 115.9324],
  [-29.7440, 115.9017],
  [-29.7375, 115.8962],
  [-29.7342, 115.8935],
  [-29.7230, 115.8913],
  [-29.7210, 115.8914],
  [-29.7159, 115.8921],
  [-29.7132, 115.8919],
  [-29.6953, 115.8883],
  [-29.6927, 115.8868],
  [-29.6614, 115.8581],
  [-29.6598, 115.8559],
  [-29.6564, 115.8487],
  [-29.6523, 115.8443],
  [-29.6418, 115.8386],
  [-29.6217, 115.8268],
  [-29.6192, 115.8260],
  [-29.6000, 115.8240],
  [-29.5900, 115.8254],
  [-29.5846, 115.8239],
  [-29.5797, 115.8199],
  [-29.5785, 115.8194],
  [-29.5773, 115.8196],
  [-29.5760, 115.8203],
  [-29.5745, 115.8202],
  [-29.5732, 115.8192],
  [-29.5705, 115.8137],
  [-29.5695, 115.8089],
  [-29.5694, 115.8076],
  [-29.5700, 115.8041],
  [-29.5713, 115.8000],
  [-29.5712, 115.7980],
  [-29.5701, 115.7965],
  [-29.5587, 115.7891],
  [-29.5555, 115.7853],
  [-29.5549, 115.7837],
  [-29.5547, 115.7820],
  [-29.5536, 115.7800],
  [-29.5518, 115.7789],
  [-29.5480, 115.7787],
  [-29.5465, 115.7779],
  [-29.5237, 115.7510],
  [-29.5103, 115.7273],
  [-29.5026, 115.7212],
  [-29.5012, 115.7206],
  [-29.4994, 115.7203],
  [-29.4978, 115.7193],
  [-29.4949, 115.7156],
  [-29.4929, 115.7142],
  [-29.4911, 115.7139],
  [-29.4882, 115.7142],
  [-29.4867, 115.7139],
  [-29.4855, 115.7129],
  [-29.4745, 115.6982],
  [-29.4706, 115.6906],
  [-29.4700, 115.6899],
  [-29.4690, 115.6889],
  [-29.4682, 115.6875],
  [-29.4678, 115.6857],
  [-29.4669, 115.6840],
  [-29.4597, 115.6770],
  [-29.4582, 115.6761],
  [-29.4574, 115.6751],
  [-29.4549, 115.6702],
  [-29.4509, 115.6548],
  [-29.4508, 115.6529],
  [-29.4510, 115.6510],
  [-29.4525, 115.6453],
  [-29.4527, 115.6419],
  [-29.4517, 115.6353],
  [-29.4510, 115.6337],
  [-29.4499, 115.6325],
  [-29.4465, 115.6313],
  [-29.4443, 115.6301],
  [-29.4408, 115.6290]
];

const arrinoMingenew = [
  [-29.4408, 115.6290],
  [-29.4216, 115.6230],
  [-29.4193, 115.6219],
  [-29.4184, 115.6218],
  [-29.4175, 115.6219],
  [-29.4163, 115.6221],
  [-29.4147, 115.6218],
  [-29.4111, 115.6201],
  [-29.4068, 115.6191],
  [-29.4044, 115.6181],
  [-29.4022, 115.6166],
  [-29.3952, 115.6108],
  [-29.3932, 115.6096],
  [-29.3904, 115.6083],
  [-29.3885, 115.6080],
  [-29.3867, 115.6082],
  [-29.3785, 115.6103],
  [-29.3745, 115.6105],
  [-29.3719, 115.6099],
  [-29.3570, 115.6048],
  [-29.3560, 115.6041],
  [-29.3553, 115.6032],
  [-29.3546, 115.6021],
  [-29.3540, 115.6016],
  [-29.3530, 115.6009],
  [-29.3523, 115.6001],
  [-29.3518, 115.5990],
  [-29.3511, 115.5982],
  [-29.3487, 115.5970],
  [-29.3476, 115.5969],
  [-29.3464, 115.5972],
  [-29.3456, 115.5972],
  [-29.3441, 115.5971],
  [-29.3428, 115.5964],
  [-29.3414, 115.5944],
  [-29.3402, 115.5934],
  [-29.3388, 115.5929],
  [-29.3379, 115.5922],
  [-29.3371, 115.5912],
  [-29.3362, 115.5905],
  [-29.3349, 115.5899],
  [-29.3326, 115.5882],
  [-29.3320, 115.5874],
  [-29.3317, 115.5864],
  [-29.3315, 115.5841],
  [-29.3310, 115.5829],
  [-29.3303, 115.5819],
  [-29.3299, 115.5812],
  [-29.3295, 115.5799],
  [-29.3289, 115.5789],
  [-29.3252, 115.5752],
  [-29.3210, 115.5719],
  [-29.3196, 115.5711],
  [-29.3150, 115.5690],
  [-29.3133, 115.5674],
  [-29.3111, 115.5633],
  [-29.3108, 115.5621],
  [-29.3109, 115.5609],
  [-29.3115, 115.5591],
  [-29.3116, 115.5577],
  [-29.3111, 115.5560],
  [-29.3071, 115.5509],
  [-29.3055, 115.5494],
  [-29.3035, 115.5483],
  [-29.2954, 115.5453],
  [-29.2935, 115.5442],
  [-29.2922, 115.5426],
  [-29.2873, 115.5348],
  [-29.2852, 115.5326],
  [-29.2759, 115.5249],
  [-29.2655, 115.5126],
  [-29.2647, 115.5112],
  [-29.2628, 115.5071],
  [-29.2567, 115.4973],
  [-29.2562, 115.4962],
  [-29.2559, 115.4941],
  [-29.2553, 115.4928],
  [-29.2544, 115.4919],
  [-29.2517, 115.4899],
  [-29.2505, 115.4895],
  [-29.2473, 115.4895],
  [-29.2455, 115.4891],
  [-29.2388, 115.4870],
  [-29.2257, 115.4850],
  [-29.2200, 115.4824],
  [-29.2189, 115.4812],
  [-29.2183, 115.4797],
  [-29.2180, 115.4737],
  [-29.2174, 115.4722],
  [-29.2155, 115.4696],
  [-29.2144, 115.4688],
  [-29.2120, 115.4679],
  [-29.2111, 115.4672],
  [-29.2100, 115.4660],
  [-29.2087, 115.4654],
  [-29.2069, 115.4651],
  [-29.2050, 115.4641],
  [-29.2012, 115.4611],
  [-29.2004, 115.4600],
  [-29.1974, 115.4535],
  [-29.1959, 115.4521],
  [-29.1918, 115.4503],
  [-29.1904, 115.4488],
  [-29.1899, 115.4468],
  [-29.1897, 115.4431]
];

const mingenewWalkaway = [
  [-29.1897, 115.4431],
  [-29.1896, 115.4365],
  [-29.1898, 115.4344],
  [-29.1922, 115.4248],
  [-29.1923, 115.4235],
  [-29.1919, 115.4221],
  [-29.1897, 115.4183],
  [-29.1890, 115.4176],
  [-29.1855, 115.4149],
  [-29.1848, 115.4141],
  [-29.1844, 115.4131],
  [-29.1799, 115.3951],
  [-29.1797, 115.3922],
  [-29.1800, 115.3895],
  [-29.1850, 115.3729],
  [-29.2032, 115.3342],
  [-29.2047, 115.3320],
  [-29.2071, 115.3292],
  [-29.2083, 115.3267],
  [-29.2108, 115.3169],
  [-29.2167, 115.2997],
  [-29.2179, 115.2943],
  [-29.2180, 115.2926],
  [-29.2166, 115.2853],
  [-29.2160, 115.2840],
  [-29.2123, 115.2781],
  [-29.2120, 115.2763],
  [-29.2126, 115.2747],
  [-29.2140, 115.2731],
  [-29.2143, 115.2720],
  [-29.2143, 115.2709],
  [-29.2117, 115.2595],
  [-29.2111, 115.2582],
  [-29.2100, 115.2567],
  [-29.2094, 115.2552],
  [-29.2082, 115.2391],
  [-29.2076, 115.2371],
  [-29.2053, 115.2333],
  [-29.2047, 115.2316],
  [-29.2041, 115.2265],
  [-29.2043, 115.2243],
  [-29.2051, 115.2226],
  [-29.2092, 115.2173],
  [-29.2101, 115.2155],
  [-29.2110, 115.2105],
  [-29.2107, 115.2060],
  [-29.2057, 115.1937],
  [-29.2052, 115.1911],
  [-29.2054, 115.1824],
  [-29.2057, 115.1810],
  [-29.2115, 115.1640],
  [-29.2117, 115.1626],
  [-29.2111, 115.1547],
  [-29.2112, 115.1506],
  [-29.2145, 115.1344],
  [-29.2152, 115.1331],
  [-29.2164, 115.1318],
  [-29.2172, 115.1300],
  [-29.2173, 115.1275],
  [-29.2178, 115.1258],
  [-29.2194, 115.1234],
  [-29.2201, 115.1212],
  [-29.2196, 115.1038],
  [-29.2183, 115.0979],
  [-29.2181, 115.0784],
  [-29.2185, 115.0768],
  [-29.2196, 115.0745],
  [-29.2202, 115.0722],
  [-29.2202, 115.0700],
  [-29.2200, 115.0685],
  [-29.2212, 115.0603],
  [-29.2208, 115.0585],
  [-29.2197, 115.0569],
  [-29.2176, 115.0555],
  [-29.2064, 115.0428],
  [-29.2049, 115.0404],
  [-29.2045, 115.0387],
  [-29.2044, 115.0073],
  [-29.2040, 115.0061],
  [-29.2032, 115.0049],
  [-29.2028, 115.0041],
  [-29.2027, 115.0031],
  [-29.2032, 114.9993],
  [-29.2037, 114.9981],
  [-29.2074, 114.9946],
  [-29.2127, 114.9908],
  [-29.2153, 114.9893],
  [-29.2165, 114.9878],
  [-29.2169, 114.9864],
  [-29.2169, 114.9826],
  [-29.2174, 114.9809],
  [-29.2187, 114.9799],
  [-29.2283, 114.9780],
  [-29.2332, 114.9786],
  [-29.2345, 114.9782],
  [-29.2355, 114.9770],
  [-29.2359, 114.9758],
  [-29.2359, 114.9737],
  [-29.2363, 114.9722],
  [-29.2374, 114.9712],
  [-29.2386, 114.9709],
  [-29.2400, 114.9711],
  [-29.2411, 114.9710],
  [-29.2420, 114.9705],
  [-29.2449, 114.9676],
  [-29.2462, 114.9668],
  [-29.2502, 114.9651],
  [-29.2513, 114.9639],
  [-29.2516, 114.9623],
  [-29.2515, 114.9610],
  [-29.2438, 114.9404],
  [-29.2417, 114.9372],
  [-29.2389, 114.9350],
  [-29.2365, 114.9340],
  [-29.2232, 114.9314],
  [-29.1971, 114.9216],
  [-29.1948, 114.9211],
  [-29.1817, 114.9199],
  [-29.1800, 114.9193],
  [-29.1779, 114.9180],
  [-29.1676, 114.9137],
  [-29.1653, 114.9132],
  [-29.1504, 114.9144],
  [-29.1473, 114.9139],
  [-29.1448, 114.9126],
  [-29.1420, 114.9106],
  [-29.1399, 114.9096],
  [-29.1364, 114.9088],
  [-29.1216, 114.9014],
  [-29.1172, 114.9002],
  [-29.1142, 114.8992],
  [-29.1116, 114.8992],
  [-29.1080, 114.9002],
  [-29.1021, 114.9005],
  [-29.0999, 114.9001],
  [-29.0956, 114.8984],
  [-29.0933, 114.8967],
  [-29.0874, 114.8901],
  [-29.0851, 114.8884],
  [-29.0816, 114.8868],
  [-29.0793, 114.8851],
  [-29.0690, 114.8742],
  [-29.0673, 114.8730],
  [-29.0615, 114.8695],
  [-29.0548, 114.8664],
  [-29.0531, 114.8652],
  [-29.0436, 114.8574],
  [-29.0420, 114.8552],
  [-29.0391, 114.8441],
  [-29.0364, 114.8395],
  [-29.0325, 114.8364],
  [-29.0306, 114.8344],
  [-29.0220, 114.8232],
  [-29.0046, 114.8109],
  [-29.0029, 114.8105],
  [-29.0010, 114.8107],
  [-28.9996, 114.8102],
  [-28.9896, 114.8004],
  [-28.9891, 114.7995],
  [-28.9887, 114.7983],
  [-28.9878, 114.7971],
  [-28.9813, 114.7926],
  [-28.9697, 114.7846],
  [-28.9658, 114.7836],
  [-28.9630, 114.7840],
  [-28.9545, 114.7872],
  [-28.9521, 114.7893],
  [-28.9437, 114.8005],
  [-28.9413, 114.8014],
  [-28.9379, 114.8016]
];

const walkawayDeviationJunction = [
  [-28.9379, 114.8016],
  [-28.9337, 114.8016],
  [-28.9316, 114.8009],
  [-28.8786, 114.7549],
  [-28.8376, 114.7084],
  [-28.8364, 114.7075],
  [-28.8283, 114.7038],
  [-28.8193, 114.6962],
  [-28.8183, 114.6949],
  [-28.8104, 114.6833],
  [-28.8052, 114.6790]
];

const originalRoute = [
  [-28.8052, 114.6790],
  [-28.8016, 114.6759],
  [-28.7883, 114.6651],
  [-28.7869, 114.6635],
  [-28.7793, 114.6509],
  [-28.7731, 114.6407],
  [-28.7718, 114.6390],
  [-28.7696, 114.6372],
  [-28.7489, 114.6258],
  [-28.7480, 114.6249],
  [-28.7475, 114.6236],
  [-28.7473, 114.6225],
  [-28.7476, 114.6211],
  [-28.7482, 114.6202]
];

const southernTransportCorridorDeviation = [
  [-28.8052, 114.6790],
  [-28.8034, 114.6769],
  [-28.8019, 114.6740],
  [-28.8008, 114.6694],
  [-28.8001, 114.6584],
  [-28.8001, 114.6363],
  [-28.7995, 114.6338],
  [-28.7976, 114.6314],
  [-28.7936, 114.6291],
  [-28.7916, 114.6272],
  [-28.7903, 114.6247],
  [-28.7885, 114.6177],
  [-28.7881, 114.6133],
  [-28.7884, 114.6097],
  [-28.7899, 114.6022],
  [-28.7895, 114.5993],
  [-28.7880, 114.5973],
  [-28.7852, 114.5954],
  [-28.7838, 114.5934],
  [-28.7833, 114.5915],
  [-28.7832, 114.5879],
  [-28.7825, 114.5861],
  [-28.7808, 114.5849],
  [-28.7794, 114.5850],
  [-28.7780, 114.5859],
  [-28.7772, 114.5879],
  [-28.7771, 114.5965],
  [-28.7767, 114.5994],
  [-28.7766, 114.6000],
  [-28.7768, 114.6007],
  [-28.7765, 114.6026],
  [-28.7763, 114.6035],
  [-28.7749, 114.6063],
  [-28.7734, 114.6083],
  [-28.7703, 114.6112],
  [-28.7687, 114.6135]
];

const junction = [
  [-28.7480, 114.6249],
  [-28.7470, 114.6240],
  [-28.7441, 114.6232],
  [-28.7429, 114.6235]
];

export const midland: Line = {
  name: 'Midland',
  state: 'WA',
  segments: [
    {
      segments: [perthClaisebrook],
      history: {
        opened: {
          date: '1881-03-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-09-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1893-05-22',
          tracks: 3
        }, {
          date: '1968-03-31',
          tracks: 4,
        }]
      }
    },
    {
      segments: [claisebrookEastPerth],
      history: {
        opened: {
          date: '1881-03-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-09-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [eastPerthGuildford],
      history: {
        opened: {
          date: '1881-03-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-09-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1966-02-14',
          gauge: 'dual',
        }]
      }
    },
    {
      segments: [guildfordMidlandJunction],
      history: {
        opened: {
          date: '1884-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-09-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1930-01-01',
          tracks: 2
        },{
          date: '1966-02-14',
          gauge: 'dual',
        }]
      }
    },
    {
      segments: [midlandDeviationPoint],
      history: {
        opened: {
          date: '1884-03-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          tracks: 2
        },{
          date: '1966-02-14',
          gauge: 'dual',
        }]
      }
    },
    {
      segments: [midlandDeviationPoint2],
      history: {
        opened: {
          date: '1896-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          tracks: 2
        },{
          date: '1966-02-14',
          gauge: 'dual',
        }]
      }
    },
    {
      segments: [{
        date: '1966-02-14',
        original: [midlandJunctionOld],
        deviation: [midlandJunctionDeviation]
      }],
      history: {
        opened: {
          date: '1891-04-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-14',
          tracks: 2,
          gauge: 'dual',
        }]
      }
    },
    {
      segments: [eastLineJunctionDevationPoint],
      history: {
        opened: {
          date: '1891-04-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-14',
          gauge: 'dual',
          tracks: 2
        }]
      }
    },
    {
      segments: [eastLineJunctionGingin],
      history: {
        opened: {
          date: '1891-04-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ginGinMogumbur],
      history: {
        opened: {
          date: '1892-02-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mogumburMora],
      history: {
        opened: {
          date: '1894-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [moraArrino],
      history: {
        opened: {
          date: '1894-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [arrinoMingenew],
      history: {
        opened: {
          date: '1892-01-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mingenewWalkaway],
      history: {
        opened: {
          date: '1891-08-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [walkawayDeviationJunction],
      history: {
        opened: {
          date: '1887-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        date: '2005-09-10',
        original: [originalRoute],
        deviation: [southernTransportCorridorDeviation]
      }],
      history: {
        opened: {
          date: '1887-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
      }
    },
    {
      segments: [junction],
      history: {
        opened: {
          date: '1887-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-04-29',
          status: 'closed'
        }]
      }
    }
  ]
};
