import { Line } from "../../../trackTypes";

const invercargillWaimatua = [
  [-46.4282, 168.3504],
  [-46.4293, 168.3514],
  [-46.4300, 168.3526],
  [-46.4505, 168.4196],
  [-46.4514, 168.4225],
  [-46.4515, 168.4248],
  [-46.4512, 168.4576]
];

const waimatuaMokotua = [
  [-46.4512, 168.4576],
  [-46.4503, 168.5499],
  [-46.4506, 168.5518],
  [-46.4512, 168.5534],
  [-46.4516, 168.5554],
  [-46.4514, 168.5771]
];

const mokotuaGorgeRoad = [
  [-46.4514, 168.5771],
  [-46.4516, 168.5783],
  [-46.4515, 168.5964],
  [-46.4518, 168.5980],
  [-46.4526, 168.5992],
  [-46.4564, 168.6032],
  [-46.4580, 168.6069],
  [-46.4587, 168.6100],
  [-46.4587, 168.6235],
  [-46.4598, 168.6315],
  [-46.4601, 168.6361],
  [-46.4603, 168.6375],
  [-46.4775, 168.7027],
  [-46.4790, 168.7069]
];

const gorgeRoadWaimahaka = [
  [-46.4790, 168.7069],
  [-46.4812, 168.7130],
  [-46.4813, 168.7162],
  [-46.4801, 168.7194],
  [-46.4798, 168.7216],
  [-46.4796, 168.7494],
  [-46.4807, 168.7561],
  [-46.4815, 168.7579],
  [-46.4870, 168.7663],
  [-46.4884, 168.7669],
  [-46.4898, 168.7667],
  [-46.4915, 168.7672],
  [-46.4951, 168.7691],
  [-46.4959, 168.7703],
  [-46.4961, 168.7715],
  [-46.4961, 168.7733],
  [-46.4964, 168.7751],
  [-46.5020, 168.7888],
  [-46.5036, 168.7950],
  [-46.5053, 168.7974],
  [-46.5073, 168.7985],
  [-46.5113, 168.8012],
  [-46.5125, 168.8033],
  [-46.5134, 168.8095],
  [-46.5142, 168.8133],
  [-46.5153, 168.8170]
];

const waimahakaTokanui = [
  [-46.5153, 168.8170],
  [-46.5166, 168.8213],
  [-46.5169, 168.8238],
  [-46.5159, 168.8302],
  [-46.5146, 168.8348],
  [-46.5144, 168.8362],
  [-46.5143, 168.8378],
  [-46.5141, 168.8390],
  [-46.5132, 168.8415],
  [-46.5130, 168.8428],
  [-46.5132, 168.8439],
  [-46.5139, 168.8452],
  [-46.5151, 168.8459],
  [-46.5175, 168.8478],
  [-46.5184, 168.8483],
  [-46.5197, 168.8486],
  [-46.5204, 168.8491],
  [-46.5212, 168.8501],
  [-46.5218, 168.8504],
  [-46.5224, 168.8505],
  [-46.5233, 168.8504],
  [-46.5238, 168.8504],
  [-46.5258, 168.8517],
  [-46.5275, 168.8521],
  [-46.5281, 168.8525],
  [-46.5287, 168.8537],
  [-46.5292, 168.8542],
  [-46.5299, 168.8544],
  [-46.5305, 168.8548],
  [-46.5332, 168.8587],
  [-46.5336, 168.8600],
  [-46.5344, 168.8641],
  [-46.5371, 168.8687],
  [-46.5384, 168.8696],
  [-46.5410, 168.8702],
  [-46.5419, 168.8711],
  [-46.5426, 168.8732],
  [-46.5431, 168.8745],
  [-46.5438, 168.8751],
  [-46.5453, 168.8761],
  [-46.5461, 168.8782],
  [-46.5464, 168.8800],
  [-46.5457, 168.8842],
  [-46.5459, 168.8860],
  [-46.5466, 168.8873],
  [-46.5473, 168.8878],
  [-46.5483, 168.8878],
  [-46.5496, 168.8871],
  [-46.5506, 168.8871],
  [-46.5516, 168.8880],
  [-46.5524, 168.8914],
  [-46.5524, 168.8934],
  [-46.5520, 168.8946],
  [-46.5513, 168.8956],
  [-46.5508, 168.8967],
  [-46.5508, 168.8977],
  [-46.5511, 168.8987],
  [-46.5521, 168.9006],
  [-46.5525, 168.9018],
  [-46.5534, 168.9049],
  [-46.5545, 168.9067],
  [-46.5547, 168.9079],
  [-46.5556, 168.9096],
  [-46.5558, 168.9114],
  [-46.5555, 168.9135],
  [-46.5556, 168.9147],
  [-46.5561, 168.9155],
  [-46.5567, 168.9162],
  [-46.5571, 168.9172],
  [-46.5576, 168.9197],
  [-46.5590, 168.9231],
  [-46.5595, 168.9264],
  [-46.5594, 168.9277],
  [-46.5588, 168.9292],
  [-46.5587, 168.9305],
  [-46.5595, 168.9341],
  [-46.5601, 168.9355],
  [-46.5616, 168.9370],
  [-46.5622, 168.9379],
  [-46.5631, 168.9411],
  [-46.5631, 168.9422],
  [-46.5620, 168.9455]
];

export const tokanui: Line = {
  name: 'Tokanui',
  state: 'NZ',
  segments: [
    {
      segments: [invercargillWaimatua],
      history: {
        opened: {
          date: '1886-07-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waimatuaMokotua],
      history: {
        opened: {
          date: '1888-01-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mokotuaGorgeRoad],
      history: {
        opened: {
          date: '1895-03-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gorgeRoadWaimahaka],
      history: {
        opened: {
          date: '1899-06-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waimahakaTokanui],
      history: {
        opened: {
          date: '1911-09-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-03-31',
          status: 'closed'
        }]
      }
    }
  ]
}
