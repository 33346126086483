import { Line } from "../../../../trackTypes";

const breakfastCreek = [
  [-27.45755, 153.03359],
  [-27.45432, 153.03680],
  [-27.45415, 153.03703],
  [-27.45398, 153.03732],
  [-27.45376, 153.03758],
  [-27.45175, 153.03951],
  [-27.45108, 153.04017],
  [-27.45081, 153.04050],
  [-27.45020, 153.04139],
  [-27.44929, 153.04244],
  [-27.44910, 153.04257],
  [-27.44893, 153.04263],
  [-27.44875, 153.04262],
  [-27.44754, 153.04246],
  [-27.44712, 153.04245],
  [-27.44679, 153.04247],
  [-27.44545, 153.04270],
  [-27.44519, 153.04281],
  [-27.44464, 153.04319],
  [-27.44272, 153.04435],
  [-27.44244, 153.04457],
  [-27.44229, 153.04476]
];

const ascot = [
  [-27.44229, 153.04476],
  [-27.44182, 153.04534],
  [-27.44118, 153.04598],
  [-27.44114, 153.04611],
  [-27.44112, 153.04626],
  [-27.44135, 153.04740],
  [-27.44132, 153.04760],
  [-27.44120, 153.04789],
  [-27.44011, 153.04911],
  [-27.43999, 153.04930],
  [-27.43989, 153.04965],
  [-27.43987, 153.05000],
  [-27.43989, 153.05079],
  [-27.43985, 153.05118],
  [-27.43975, 153.05158],
  [-27.43957, 153.05210],
  [-27.43946, 153.05252],
  [-27.43941, 153.05294],
  [-27.43939, 153.05360],
  [-27.43937, 153.05390],
  [-27.43917, 153.05577],
  [-27.43916, 153.05621],
  [-27.43918, 153.05662],
  [-27.43931, 153.05733],
  [-27.43963, 153.05828],
  [-27.43975, 153.05880],
  [-27.43982, 153.05943],
  [-27.43982, 153.06060],
  [-27.43987, 153.06157],
  [-27.43985, 153.06208],
  [-27.43977, 153.06250],
  [-27.43936, 153.06415],
  [-27.43928, 153.06426],
  [-27.43917, 153.06435],
  [-27.43133, 153.06558]
];

const doomben = [
  [-27.43133, 153.06558],
  [-27.43121, 153.06564],
  [-27.43118, 153.06574],
  [-27.43153, 153.06853]
];

const nudgeeRoad = [
  [-27.43153, 153.06853],
  [-27.43188, 153.07151]
];

const warnerStreet = [
  [-27.45689, 153.03426],
  [-27.45686, 153.03437],
  [-27.45688, 153.03446],
  [-27.45776, 153.03557],
  [-27.45783, 153.03560],
  [-27.45791, 153.03558]
];

const orielPark = [
  [-27.43133, 153.06558],
  [-27.43122, 153.06552],
  [-27.43115, 153.06543],
  [-27.43019, 153.05824],
  [-27.43015, 153.05816],
  [-27.43009, 153.05811],
  [-27.42999, 153.05809],
  [-27.42959, 153.05817]
];

const agnesStreetSiding = [
  [-27.44121, 153.04671],
  [-27.44119, 153.04682],
  [-27.44114, 153.04687],
  [-27.43994, 153.04707]
];

const sidingLoop = [
  [-27.43994, 153.04707],
  [-27.43984, 153.04700],
  [-27.43978, 153.04691],
  [-27.43975, 153.04659],
  [-27.43976, 153.04647],
  [-27.43980, 153.04635],
  [-27.43989, 153.04627]
];

export const east2: Line = {
  name: 'East 2',
  state: 'QLD',
  segments: [
    {
      segments: [breakfastCreek],
      history: {
        opened: {
          date: '1885-08-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1898-01-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ascot],
      history: {
        opened: {
          date: '1899-12-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [doomben],
      history: {
        opened: {
          date: '1916-05-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nudgeeRoad],
      history: {
        opened: {
          date: '1937-09-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [warnerStreet],
      history: {
        opened: {
          date: '1925-08-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [orielPark],
      history: {
        opened: {
          date: '1925-12-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [agnesStreetSiding],
      history: {
        opened: {
          date: '1927-06-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sidingLoop],
      history: {
        opened: {
          date: '1927-09-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    }
  ]
}
