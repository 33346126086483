import { Line } from "../../trackTypes";

const saBorerFinkeDeviationStart = [
  [-25.9986, 134.7741],
  [-25.9032, 134.7170],
  [-25.8771, 134.7018],
  [-25.8732, 134.7009],
  [-25.8375, 134.6899],
  [-25.8269, 134.6905],
  [-25.8230, 134.6897],
  [-25.8069, 134.6814],
  [-25.7744, 134.6427],
  [-25.7369, 134.6220],
  [-25.7316, 134.6207],
  [-25.6429, 134.6121],
  [-25.6404, 134.6112],
  [-25.6064, 134.5876],
  [-25.5807, 134.5752],
  [-25.5790, 134.5747],
  [-25.5598, 134.5747]
];

const finkeOld = [
  [-25.5598, 134.5747],
  [-25.5566, 134.5748],
  [-25.5542, 134.5742],
  [-25.5462, 134.5706],
  [-25.5429, 134.5688]
];

const finkeDeviation = [
  [-25.5598, 134.5747],
  [-25.5579, 134.5751],
  [-25.5564, 134.5759],
  [-25.5545, 134.5777],
  [-25.5537, 134.5789],
  [-25.5517, 134.5829],
  [-25.5505, 134.5845],
  [-25.5494, 134.5851],
  [-25.5483, 134.5852],
  [-25.5472, 134.5850],
  [-25.5460, 134.5841],
  [-25.5452, 134.5828],
  [-25.5449, 134.5813],
  [-25.5450, 134.5799],
  [-25.5461, 134.5753],
  [-25.5462, 134.5742],
  [-25.5461, 134.5733],
  [-25.5456, 134.5723],
  [-25.5435, 134.5695],
  [-25.5429, 134.5688]
];

const finkeDeviationEndRumbalara = [
  [-25.5429, 134.5688],
  [-25.5391, 134.5668],
  [-25.5324, 134.5663],
  [-25.5307, 134.5656],
  [-25.5277, 134.5636],
  [-25.5192, 134.5605],
  [-25.5155, 134.5577],
  [-25.4873, 134.5450],
  [-25.4524, 134.5439],
  [-25.4019, 134.5356],
  [-25.3675, 134.5195],
  [-25.3318, 134.4841]
];

const rumbalaraAliceSprings = [
  [-25.3318, 134.4841],
  [-25.2946, 134.4502],
  [-25.2899, 134.4488],
  [-25.2709, 134.4501],
  [-25.2567, 134.4483],
  [-25.2448, 134.4453],
  [-25.2304, 134.4458],
  [-25.2269, 134.4450],
  [-25.1976, 134.4291],
  [-25.1878, 134.4261],
  [-25.1848, 134.4243],
  [-25.1789, 134.4188],
  [-25.1627, 134.4092],
  [-25.1571, 134.4073],
  [-25.1084, 134.3865],
  [-25.0970, 134.3775],
  [-25.0899, 134.3741],
  [-25.0856, 134.3715],
  [-25.0679, 134.3637],
  [-25.0592, 134.3621],
  [-25.0170, 134.3376],
  [-25.0004, 134.3313],
  [-24.9983, 134.3301],
  [-24.9947, 134.3266],
  [-24.9667, 134.3106],
  [-24.9286, 134.2865],
  [-24.9198, 134.2791],
  [-24.9170, 134.2780],
  [-24.9134, 134.2768],
  [-24.9107, 134.2751],
  [-24.8821, 134.2498],
  [-24.8779, 134.2479],
  [-24.8691, 134.2464],
  [-24.8054, 134.2128],
  [-24.7978, 134.2104],
  [-24.7889, 134.2025],
  [-24.7740, 134.1944],
  [-24.7408, 134.1817],
  [-24.6847, 134.1508],
  [-24.6717, 134.1493],
  [-24.6685, 134.1478],
  [-24.6648, 134.1436],
  [-24.6625, 134.1422],
  [-24.5882, 134.1084],
  [-24.5785, 134.1003],
  [-24.5418, 134.0794],
  [-24.5367, 134.0776],
  [-24.5150, 134.0661],
  [-24.4845, 134.0585],
  [-24.4585, 134.0550],
  [-24.4557, 134.0550],
  [-24.4462, 134.0519],
  [-24.4344, 134.0513],
  [-24.3925, 134.0541],
  [-24.3630, 134.0525],
  [-24.3494, 134.0534],
  [-24.3365, 134.0580],
  [-24.3103, 134.0555],
  [-24.3089, 134.0553],
  [-24.3055, 134.0537],
  [-24.3006, 134.0534],
  [-24.2966, 134.0521],
  [-24.2913, 134.0506],
  [-24.2862, 134.0476],
  [-24.2748, 134.0425],
  [-24.2638, 134.0361],
  [-24.2608, 134.0352],
  [-24.2083, 134.0227],
  [-24.1896, 134.0156],
  [-24.1512, 134.0068],
  [-24.0872, 133.9788],
  [-24.0839, 133.9778],
  [-24.0377, 133.9729],
  [-24.0323, 133.9708],
  [-24.0212, 133.9622],
  [-24.0097, 133.9480],
  [-24.0068, 133.9458],
  [-23.9459, 133.9190],
  [-23.9048, 133.9008],
  [-23.8809, 133.8969],
  [-23.7829, 133.8686],
  [-23.7676, 133.8666],
  [-23.7646, 133.8662]
];

export const oldGhan: Line = {
  name: 'Old Ghan',
  state: 'NT',
  segments: [
    {
      segments: [
        saBorerFinkeDeviationStart,
        {
          date: '1930-05-01',
          original: [finkeOld],
          deviation: [finkeDeviation]
        },
        finkeDeviationEndRumbalara
      ],
      history: {
        opened: {
          date: '1928-12-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rumbalaraAliceSprings],
      history: {
        opened: {
          date: '1929-08-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
