import { Line } from "../../../../trackTypes";

const victoriaParkRacecourse = [
  [-34.92784, 138.62316],
  [-34.92804, 138.62341],
  [-34.92826, 138.62356],
  [-34.92840, 138.62363],
  [-34.93223, 138.62393]
];

const dulwich = [
  [-34.93223, 138.62393],
  [-34.93523, 138.62414],
  [-34.93532, 138.62423],
  [-34.93535, 138.62431],
  [-34.93500, 138.63140],
  [-34.93502, 138.63150],
  [-34.93507, 138.63157],
  [-34.93514, 138.63162],
  [-34.93955, 138.63195]
];

const lindenPark = [
  [-34.93955, 138.63195],
  [-34.93967, 138.63204],
  [-34.93972, 138.63225],
  [-34.93923, 138.64219],
  [-34.93925, 138.64231],
  [-34.93929, 138.64238],
  [-34.93933, 138.64242],
  [-34.94524, 138.64288]
];

export const east5: Line = {
  name: 'East5',
  state: 'SA',
  segments: [
    {
      segments: [victoriaParkRacecourse],
      history: {
        opened: {
          date: '1910-01-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-05-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dulwich],
      history: {
        opened: {
          date: '1914-03-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lindenPark],
      history: {
        opened: {
          date: '1924-10-05',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-05-01',
          status: 'closed'
        }]
      }
    }
  ]
};
