import { Line } from "../../../trackTypes";

const napierAhuriri = [
  [-39.4957, 176.8924],
  [-39.4954, 176.8917],
  [-39.4951, 176.8913],
  [-39.4946, 176.8909],
  [-39.4938, 176.8908],
  [-39.4902, 176.8919],
  [-39.4888, 176.8928],
  [-39.4846, 176.8942],
  [-39.4830, 176.8952],
  [-39.4822, 176.8962],
  [-39.4813, 176.8988],
  [-39.4799, 176.9042]
];

const napierJunction = [
  [-39.4938, 176.8908],
  [-39.4945, 176.8903],
  [-39.4949, 176.8895],
  [-39.4949, 176.8882]
];

const ahuririBreakwater = [
  [-39.4799, 176.9042],
  [-39.4794, 176.9072],
  [-39.4791, 176.9079],
  [-39.4783, 176.9087],
  [-39.4779, 176.9092],
  [-39.4778, 176.9097],
  [-39.4774, 176.9123],
  [-39.4774, 176.9159],
  [-39.4775, 176.9170],
  [-39.4782, 176.9193],
  [-39.4787, 176.9205],
  [-39.4797, 176.9213],
  [-39.4807, 176.9215],
  [-39.4812, 176.9215],
  [-39.4824, 176.9210]
];

export const ahuriri: Line = {
  name: 'Ahuriri',
  state: 'NZ',
  segments: [
    {
      segments: [napierAhuriri],
      history: {
        opened: {
          date: '1874-11-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [napierJunction],
      history: {
        opened: {
          date: '1922-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-02-01',
          status: 'closed'
        },{
          date: '2012-12-01',
          status: 'closed'
        }, {
          date: '1936-10-17',
          status: 'open'
        },{
          date: '2018-06-06',
          status: 'open'
        }]
      }
    },
    {
      segments: [ahuririBreakwater],
      history: {
        opened: {
          date: '1931-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
