import { Line } from "../../../../trackTypes";

const heritage = [
  [-43.53414, 172.63955],
  [-43.53513, 172.64089]
];

const woolston = [
  [-43.53513, 172.64089],
  [-43.53632, 172.64261],
  [-43.53642, 172.64281],
  [-43.53824, 172.65039],
  [-43.54053, 172.65974],
  [-43.54064, 172.66008],
  [-43.54567, 172.67335],
  [-43.54578, 172.67365],
  [-43.54582, 172.67385],
  [-43.54610, 172.67539],
  [-43.54619, 172.67581],
  [-43.54678, 172.67808],
  [-43.54690, 172.67843],
  [-43.54704, 172.67870],
  [-43.54863, 172.68166]
];

const heathcote = [
  [-43.54863, 172.68166],
  [-43.55466, 172.69315],
  [-43.55575, 172.69503],
  [-43.55608, 172.69575],
  [-43.55633, 172.69643],
  [-43.55657, 172.69738],
  [-43.55837, 172.70742]
];

const redcliffs = [
  [-43.55837, 172.70742],
  [-43.55843, 172.70790],
  [-43.55843, 172.70822],
  [-43.55831, 172.70952],
  [-43.55822, 172.70987],
  [-43.55811, 172.71013],
  [-43.55670, 172.71220],
  [-43.55654, 172.71248],
  [-43.55637, 172.71287],
  [-43.55559, 172.71539],
  [-43.55552, 172.71578],
  [-43.55549, 172.71616],
  [-43.55558, 172.71748]
];

const redcliffsOriginal = [
  [-43.55558, 172.71748],
  [-43.55567, 172.71780],
  [-43.55578, 172.71830],
  [-43.55615, 172.72028],
  [-43.55621, 172.72050],
  [-43.55630, 172.72069],
  [-43.55858, 172.72433],
  [-43.55872, 172.72452],
  [-43.55895, 172.72474],
  [-43.55911, 172.72491],
  [-43.55968, 172.72561],
  [-43.56000, 172.72610],
  [-43.56008, 172.72625],
  [-43.56024, 172.72664],
  [-43.56039, 172.72714],
  [-43.56045, 172.72755],
  [-43.56044, 172.72798],
  [-43.56038, 172.72826],
  [-43.56027, 172.72843],
  [-43.56012, 172.72858],
  [-43.55993, 172.72867],
  [-43.55818, 172.72924],
  [-43.55807, 172.72929],
  [-43.55797, 172.72938],
  [-43.55713, 172.73022],
  [-43.55705, 172.73034],
  [-43.55698, 172.73049],
  [-43.55663, 172.73172],
  [-43.55662, 172.73188],
  [-43.55664, 172.73202]
];

const redcliffsDeviation = [
  [-43.55558, 172.71748],
  [-43.55647, 172.73143],
  [-43.55652, 172.73171],
  [-43.55657, 172.73187],
  [-43.55664, 172.73202]
];

const sumner = [
  [-43.55664, 172.73202],
  [-43.55675, 172.73222],
  [-43.55761, 172.73335],
  [-43.55836, 172.73428],
  [-43.56270, 172.73986],
  [-43.56282, 172.74000],
  [-43.56295, 172.74010],
  [-43.56470, 172.74120],
  [-43.56483, 172.74134],
  [-43.56493, 172.74148],
  [-43.56620, 172.74403],
  [-43.56628, 172.74427],
  [-43.56632, 172.74451],
  [-43.56633, 172.74476],
  [-43.56632, 172.74680],
  [-43.56628, 172.74706],
  [-43.56619, 172.74729],
  [-43.56608, 172.74748],
  [-43.56585, 172.74767],
  [-43.56557, 172.74785],
  [-43.56536, 172.74800],
  [-43.56386, 172.74922],
  [-43.56378, 172.74933],
  [-43.56372, 172.74947],
  [-43.56370, 172.74963],
  [-43.56371, 172.74982],
  [-43.56392, 172.75058],
  [-43.56473, 172.75416],
  [-43.56481, 172.75437],
  [-43.56492, 172.75453],
  [-43.56618, 172.75597],
  [-43.56646, 172.75634],
  [-43.56674, 172.75671],
  [-43.56835, 172.75899],
  [-43.56844, 172.75908],
  [-43.56853, 172.75913],
  [-43.56863, 172.75912],
  [-43.56937, 172.75902],
  [-43.56946, 172.75905],
  [-43.56955, 172.75912],
  [-43.57494, 172.76823]
];

const naylandStreet = [
  [-43.56653, 172.75642],
  [-43.56661, 172.75647],
  [-43.56670, 172.75648],
  [-43.56775, 172.75633],
  [-43.56783, 172.75634],
  [-43.56789, 172.75637],
  [-43.56797, 172.75645],
  [-43.56846, 172.75736],
  [-43.56955, 172.75912]
];

const moorhouseAvenue = [
  [-43.53993, 172.64253],
  [-43.53991, 172.65036],
  [-43.53997, 172.65049],
  [-43.54007, 172.65057]
];

const lancasterPark = [
  [-43.53824, 172.65039],
  [-43.53833, 172.65050],
  [-43.53842, 172.65056],
  [-43.54007, 172.65057],
  [-43.54087, 172.65057],
  [-43.54094, 172.65064],
  [-43.54096, 172.65074],
  [-43.54093, 172.65413],
  [-43.54089, 172.65424],
  [-43.54081, 172.65430],
  [-43.53934, 172.65430],
  [-43.53928, 172.65435],
  [-43.53925, 172.65440],
  [-43.53924, 172.65445]
];

export const line3: Line = {
  name: 'Line 3',
  state: 'NZ',
  segments: [
    {
      segments: [heritage],
      history: {
        opened: {
          date: '1882-07-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1905-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-12-06',
          status: 'closed'
        }, {
          date: '2015-02-12',
          status: 'open'
        }]
      }
    },
    {
      segments: [woolston],
      history: {
        opened: {
          date: '1882-07-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1905-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-12-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heathcote],
      history: {
        opened: {
          date: '1882-12-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1905-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-12-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        redcliffs,
        {
          date: '1905-06-06',
          original: [redcliffsOriginal],
          deviation: [redcliffsDeviation]
        },
        sumner
      ],
      history: {
        opened: {
          date: '1888-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1905-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-12-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [naylandStreet],
      history: {
        opened: {
          date: '1905-06-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-12-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moorhouseAvenue, lancasterPark],
      history: {
        opened: {
          date: '1905-06-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-12-06',
          status: 'closed'
        }]
      }
    }
  ]
}
