import { Line } from "../../../trackTypes";

const line = [
  [-36.3584, 145.4312],
  [-36.3576, 145.4317],
  [-36.3564, 145.4333],
  [-36.3560, 145.4347],
  [-36.3559, 145.4359],
  [-36.3560, 145.5279],
  [-36.3555, 145.5311],
  [-36.3555, 145.5382],
  [-36.3559, 145.5420],
  [-36.3559, 145.6039],
  [-36.3549, 145.6082],
  [-36.3442, 145.6300],
  [-36.3322, 145.6630],
  [-36.3303, 145.6704],
  [-36.3282, 145.6848]
];

const katamatite = [
  [-36.3282, 145.6848],
  [-36.3272, 145.6912],
  [-36.3265, 145.6930],
  [-36.3253, 145.6944],
  [-36.3237, 145.6952],
  [-36.3176, 145.6964],
  [-36.3167, 145.6964],
  [-36.3082, 145.6954],
  [-36.3068, 145.6950],
  [-36.3059, 145.6945],
  [-36.2985, 145.6901],
  [-36.2974, 145.6898],
  [-36.2963, 145.6897],
  [-36.2130, 145.6895],
  [-36.2071, 145.6896],
  [-36.2053, 145.6900],
  [-36.2011, 145.6913],
  [-36.1989, 145.6915],
  [-36.1445, 145.6915],
  [-36.1399, 145.6927],
  [-36.0969, 145.6926],
  [-36.0864, 145.6906]
];

export const dookie: Line = {
  name: 'Dookie',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-09-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [katamatite],
      history: {
        opened: {
          date: '1892-11-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-13',
          status: 'closed'
        }]
      }
    }
  ]
}
