import { Line } from "../../../trackTypes";

const warragulRokeby = [
  [-38.1657, 145.9354],
  [-38.1657, 145.9367],
  [-38.1656, 145.9377],
  [-38.1650, 145.9387],
  [-38.1644, 145.9392],
  [-38.1604, 145.9402],
  [-38.1577, 145.9411],
  [-38.1548, 145.9426],
  [-38.1540, 145.9432],
  [-38.1535, 145.9439],
  [-38.1521, 145.9464],
  [-38.1513, 145.9471],
  [-38.1502, 145.9475],
  [-38.1490, 145.9473],
  [-38.1463, 145.9455],
  [-38.1448, 145.9454],
  [-38.1436, 145.9457],
  [-38.1390, 145.9480],
  [-38.1379, 145.9485],
  [-38.1353, 145.9499],
  [-38.1339, 145.9504],
  [-38.1272, 145.9527],
  [-38.1264, 145.9527],
  [-38.1158, 145.9510],
  [-38.1129, 145.9513],
  [-38.1117, 145.9509],
  [-38.1108, 145.9500],
  [-38.1081, 145.9467],
  [-38.1073, 145.9460],
  [-38.1056, 145.9453],
  [-38.1034, 145.9445],
  [-38.1016, 145.9425],
  [-38.0981, 145.9372],
  [-38.0961, 145.9354],
  [-38.0931, 145.9331],
  [-38.0919, 145.9318],
  [-38.0844, 145.9228],
  [-38.0814, 145.9193],
  [-38.0796, 145.9183],
  [-38.0778, 145.9186],
  [-38.0745, 145.9212],
  [-38.0734, 145.9215],
  [-38.0708, 145.9220],
  [-38.0695, 145.9230],
  [-38.0689, 145.9244],
  [-38.0685, 145.9270]
];

const rokebyNerrimSouth = [
  [-38.0685, 145.9270],
  [-38.0688, 145.9302],
  [-38.0676, 145.9322],
  [-38.0663, 145.9334],
  [-38.0656, 145.9353],
  [-38.0656, 145.9376],
  [-38.0629, 145.9448],
  [-38.0607, 145.9476],
  [-38.0582, 145.9489],
  [-38.0569, 145.9507],
  [-38.0555, 145.9562],
  [-38.0536, 145.9613],
  [-38.0528, 145.9665],
  [-38.0518, 145.9677],
  [-38.0486, 145.9726],
  [-38.0478, 145.9735],
  [-38.0470, 145.9736],
  [-38.0456, 145.9734],
  [-38.0442, 145.9724],
  [-38.0433, 145.9722],
  [-38.0409, 145.9721],
  [-38.0400, 145.9717],
  [-38.0388, 145.9710],
  [-38.0379, 145.9701],
  [-38.0360, 145.9674],
  [-38.0352, 145.9669],
  [-38.0342, 145.9667],
  [-38.0325, 145.9662],
  [-38.0314, 145.9652],
  [-38.0303, 145.9628],
  [-38.0285, 145.9593],
  [-38.0273, 145.9583],
  [-38.0263, 145.9580],
  [-38.0253, 145.9582],
  [-38.0225, 145.9588]
];

const nerrimSouthNayook = [
  [-38.0225, 145.9588],
  [-38.0171, 145.9615],
  [-38.0138, 145.9625],
  [-38.0117, 145.9640],
  [-38.0110, 145.9642],
  [-38.0094, 145.9645],
  [-38.0085, 145.9641],
  [-38.0060, 145.9622],
  [-38.0005, 145.9566],
  [-37.9994, 145.9559],
  [-37.9977, 145.9553],
  [-37.9960, 145.9542],
  [-37.9949, 145.9540],
  [-37.9936, 145.9544],
  [-37.9926, 145.9545],
  [-37.9898, 145.9546],
  [-37.9888, 145.9540],
  [-37.9859, 145.9489],
  [-37.9849, 145.9481],
  [-37.9841, 145.9480],
  [-37.9827, 145.9479],
  [-37.9810, 145.9474],
  [-37.9749, 145.9469],
  [-37.9732, 145.9475],
  [-37.9726, 145.9483],
  [-37.9723, 145.9495],
  [-37.9708, 145.9506],
  [-37.9658, 145.9512],
  [-37.9644, 145.9525],
  [-37.9623, 145.9529],
  [-37.9602, 145.9527],
  [-37.9589, 145.9529],
  [-37.9581, 145.9532],
  [-37.9540, 145.9562],
  [-37.9531, 145.9565],
  [-37.9520, 145.9563],
  [-37.9509, 145.9556],
  [-37.9501, 145.9554],
  [-37.9495, 145.9554],
  [-37.9454, 145.9563],
  [-37.9443, 145.9563],
  [-37.9424, 145.9559],
  [-37.9420, 145.9555],
  [-37.9414, 145.9547],
  [-37.9404, 145.9521],
  [-37.9394, 145.9510],
  [-37.9377, 145.9506],
  [-37.9364, 145.9510],
  [-37.9352, 145.9535],
  [-37.9343, 145.9543],
  [-37.9333, 145.9541],
  [-37.9325, 145.9534],
  [-37.9310, 145.9506],
  [-37.9302, 145.9501],
  [-37.9290, 145.9501],
  [-37.9260, 145.9511],
  [-37.9230, 145.9512],
  [-37.9222, 145.9508],
  [-37.9212, 145.9493],
  [-37.9188, 145.9447]
];

const nayookNoojee = [
  [-37.9188, 145.9447],
  [-37.9177, 145.9429],
  [-37.9140, 145.9395],
  [-37.9108, 145.9380],
  [-37.9095, 145.9381],
  [-37.9084, 145.9386],
  [-37.9069, 145.9387],
  [-37.9062, 145.9393],
  [-37.9052, 145.9422],
  [-37.9051, 145.9431],
  [-37.9055, 145.9447],
  [-37.9051, 145.9481],
  [-37.9053, 145.9492],
  [-37.9057, 145.9498],
  [-37.9067, 145.9507],
  [-37.9072, 145.9520],
  [-37.9069, 145.9533],
  [-37.9061, 145.9538],
  [-37.9047, 145.9538],
  [-37.9039, 145.9544],
  [-37.9036, 145.9557],
  [-37.9036, 145.9576],
  [-37.9030, 145.9580],
  [-37.9013, 145.9580],
  [-37.9004, 145.9587],
  [-37.9002, 145.9599],
  [-37.9011, 145.9627],
  [-37.9018, 145.9636],
  [-37.9031, 145.9644],
  [-37.9036, 145.9657],
  [-37.9044, 145.9666],
  [-37.9046, 145.9680],
  [-37.9040, 145.9696],
  [-37.9034, 145.9739],
  [-37.9012, 145.9807],
  [-37.9004, 145.9847],
  [-37.8986, 145.9868],
  [-37.8948, 145.9935],
  [-37.8938, 145.9946],
  [-37.8935, 145.9958],
  [-37.8956, 146.0027],
  [-37.8961, 146.0075]
];

export const noojee: Line = {
  name: 'Noojee',
  state: 'VIC',
  segments: [
    {
      segments: [warragulRokeby],
      history: {
        opened: {
          date: '1890-05-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rokebyNerrimSouth],
      history: {
        opened: {
          date: '1892-03-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nerrimSouthNayook],
      history: {
        opened: {
          date: '1917-03-27',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nayookNoojee],
      history: {
        opened: {
          date: '1919-04-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-03-27',
          status: 'closed'
        }]
      }
    }
  ]
}
