import { Line } from "../../trackTypes";

const narranderaJerilderie = [
  [-34.7395, 146.5485],
  [-34.7397, 146.5468],
  [-34.7403, 146.5455],
  [-34.7417, 146.5444],
  [-34.7551, 146.5371],
  [-34.7701, 146.5297],
  [-34.7755, 146.5255],
  [-34.9002, 146.3732],
  [-34.9047, 146.3663],
  [-34.9111, 146.3550],
  [-34.9332, 146.3085],
  [-34.9372, 146.3014],
  [-34.9406, 146.2982],
  [-34.9742, 146.2741],
  [-35.0148, 146.2456],
  [-35.0249, 146.2365],
  [-35.0522, 146.2076],
  [-35.0889, 146.1689],
  [-35.1249, 146.1308],
  [-35.1273, 146.1280],
  [-35.1303, 146.1232],
  [-35.1327, 146.1179],
  [-35.1639, 146.0183],
  [-35.1678, 146.0093],
  [-35.1783, 145.9952],
  [-35.2438, 145.9048],
  [-35.2880, 145.8433],
  [-35.3604, 145.7427],
  [-35.3617, 145.7401],
  [-35.3623, 145.7371],
  [-35.3617, 145.7330]
];

const jerilderieBerrigan = [
  [-35.3617, 145.7330],
  [-35.3597, 145.7241],
  [-35.3598, 145.7222],
  [-35.3606, 145.7208],
  [-35.3616, 145.7202],
  [-35.3653, 145.7192],
  [-35.4135, 145.7073],
  [-35.4156, 145.7070],
  [-35.4311, 145.7084],
  [-35.4332, 145.7092],
  [-35.4349, 145.7106],
  [-35.4391, 145.7155],
  [-35.4410, 145.7174],
  [-35.4436, 145.7193],
  [-35.4715, 145.7338],
  [-35.4963, 145.7451],
  [-35.5224, 145.7509],
  [-35.5545, 145.7556],
  [-35.5719, 145.7568],
  [-35.5734, 145.7571],
  [-35.5747, 145.7581],
  [-35.5921, 145.7773],
  [-35.5934, 145.7784],
  [-35.6182, 145.7925],
  [-35.6462, 145.8060],
  [-35.6493, 145.8082]
];

const berriganFinley = [
  [-35.6493, 145.8082],
  [-35.6516, 145.8092],
  [-35.6526, 145.8094],
  [-35.6533, 145.8090],
  [-35.6538, 145.8082],
  [-35.6540, 145.8070],
  [-35.6530, 145.7993],
  [-35.6534, 145.7978],
  [-35.6546, 145.7950],
  [-35.6550, 145.7930],
  [-35.6535, 145.7807],
  [-35.6536, 145.7791],
  [-35.6543, 145.7775],
  [-35.6593, 145.7713],
  [-35.6599, 145.7702],
  [-35.6604, 145.7687],
  [-35.6605, 145.7670],
  [-35.6542, 145.7147],
  [-35.6539, 145.7133],
  [-35.6523, 145.7093],
  [-35.6519, 145.7080],
  [-35.6468, 145.6644],
  [-35.6466, 145.6362],
  [-35.6402, 145.5837],
  [-35.6403, 145.5802],
  [-35.6407, 145.5806],
  [-35.6419, 145.5791],
  [-35.6477, 145.5756]
];

const finleyTocumwal = [
  [-35.6477, 145.5756],
  [-35.6524, 145.5726],
  [-35.6543, 145.5719],
  [-35.6557, 145.5719],
  [-35.6984, 145.5785],
  [-35.7007, 145.5785],
  [-35.7862, 145.5634],
  [-35.7887, 145.5616],
  [-35.7906, 145.5604],
  [-35.8075, 145.5574]
];

const tocumwalVicBorder = [
  [-35.8075, 145.5574],
  [-35.8127, 145.5565],
  [-35.8143, 145.5567]
];

export const tocumwal: Line = {
  name: 'Tocumwal _NSW)',
  state: 'NSW',
  segments: [
    {
      segments: [narranderaJerilderie],
      history: {
        opened: {
          date: '1884-09-16',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-01-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [jerilderieBerrigan],
      history: {
        opened: {
          date: '1896-10-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [berriganFinley],
      history: {
        opened: {
          date: '1898-09-16',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [finleyTocumwal],
      history: {
        opened: {
          date: '1914-07-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tocumwalVicBorder],
      history: {
        opened: {
          date: '1908-07-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-01-06',
          status: 'closed'
        }, {
          date: '1995-02-06',
          status: 'open'
        }]
      }
    }
  ]
};
