import { Line } from "../../trackTypes";

const camberwellRiversdale = [
  [-37.8263, 145.0651],
  [-37.8261, 145.0672],
  [-37.8262, 145.0681],
  [-37.8264, 145.0689],
  [-37.8268, 145.0694],
  [-37.8273, 145.0698],
  [-37.8279, 145.0700],
  [-37.8285, 145.0700],
  [-37.8291, 145.0699],
  [-37.8311, 145.0697],
  [-37.8315, 145.0696]
];

const riversdaleHartwell = [
  [-37.8315, 145.0696],
  [-37.8332, 145.0693],
  [-37.8337, 145.0693],
  [-37.8344, 145.0695],
  [-37.8349, 145.0698],
  [-37.8440, 145.0756]
];

const hartwellAshburton = [
  [-37.8440, 145.0756],
  [-37.8457, 145.0767],
  [-37.8546, 145.0824],
  [-37.8551, 145.0827],
  [-37.8559, 145.0829],
  [-37.8568, 145.0831],
  [-37.8575, 145.0830],
  [-37.8584, 145.0828],
  [-37.8620, 145.0813]
];

const ashburtonAlamein = [
  [-37.8620, 145.0813],
  [-37.8635, 145.0806],
  [-37.8639, 145.0804],
  [-37.8691, 145.0795]
];

export const alamein: Line = {
  name: 'Alamein',
  state: 'VIC',
  segments: [
    {
      segments: [camberwellRiversdale],
      history: {
        opened: {
          date: '1890-05-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1897-05-01',
          status: 'closed'
        }, {
          date: '1898-07-04',
          status: 'open'
        }, {
          date: '1895-12-09',
          tracks: 1
        }, {
          date: '1898-07-04',
          tracks: 2
        }, {
          date: '1924-10-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [riversdaleHartwell],
      history: {
        opened: {
          date: '1890-05-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1897-05-01',
          status: 'closed'
        }, {
          date: '1898-07-04',
          status: 'open'
        }, {
          date: '1955-07-31',
          tracks: 2
        }, {
          date: '1924-10-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [hartwellAshburton],
      history: {
        opened: {
          date: '1890-05-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1924-10-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-11-07',
          tracks: 2
        }, {
          date: '1897-05-01',
          status: 'closed'
        }, {
          date: '1898-07-04',
          status: 'open'
        }]
      }
    },
    {
      segments: [ashburtonAlamein],
      history: {
        opened: {
          date: '1890-05-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1895-12-09',
          status: 'closed'
        }, {
          date: '1948-06-28',
          status: 'open'
        }, {
          date: '1948-06-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
