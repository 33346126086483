import { Line } from "../../../trackTypes";

const nyoraWonthaggi = [
  [-38.3425, 145.6906],
  [-38.3429, 145.6930],
  [-38.3435, 145.6941],
  [-38.3443, 145.6948],
  [-38.3455, 145.6951],
  [-38.3464, 145.6948],
  [-38.3514, 145.6918],
  [-38.3582, 145.6858],
  [-38.3642, 145.6827],
  [-38.3659, 145.6817],
  [-38.3724, 145.6749],
  [-38.3742, 145.6721],
  [-38.3812, 145.6593],
  [-38.3858, 145.6491],
  [-38.4152, 145.6142],
  [-38.4261, 145.5980],
  [-38.4427, 145.5608],
  [-38.4447, 145.5576],
  [-38.4678, 145.5334],
  [-38.4977, 145.5068],
  [-38.5027, 145.4990],
  [-38.5149, 145.4678],
  [-38.5164, 145.4644],
  [-38.5171, 145.4612],
  [-38.5198, 145.4526],
  [-38.5220, 145.4489],
  [-38.5234, 145.4478],
  [-38.5254, 145.4476],
  [-38.5303, 145.4497],
  [-38.5314, 145.4508],
  [-38.5322, 145.4522],
  [-38.5336, 145.4602],
  [-38.5344, 145.4620],
  [-38.5362, 145.4633],
  [-38.5378, 145.4634],
  [-38.5391, 145.4630],
  [-38.5405, 145.4633],
  [-38.5422, 145.4643],
  [-38.5443, 145.4653],
  [-38.5453, 145.4664],
  [-38.5461, 145.4684],
  [-38.5465, 145.4711],
  [-38.5474, 145.4728],
  [-38.5537, 145.4809],
  [-38.5555, 145.4841],
  [-38.5582, 145.4876],
  [-38.5590, 145.4890],
  [-38.5606, 145.4936],
  [-38.5626, 145.4986],
  [-38.5665, 145.5151],
  [-38.5689, 145.5346],
  [-38.5693, 145.5359],
  [-38.5727, 145.5446],
  [-38.5738, 145.5465],
  [-38.5778, 145.5508],
  [-38.5886, 145.5579],
  [-38.5892, 145.5583],
  [-38.5904, 145.5594],
  [-38.5983, 145.5691],
  [-38.5996, 145.5710],
  [-38.6040, 145.5784],
  [-38.6047, 145.5802],
  [-38.6049, 145.5819],
  [-38.6039, 145.5908]
];

const wonthaggiKirrakJunction = [
  [-38.6039, 145.5908],
  [-38.6035, 145.5951],
  [-38.6039, 145.5980],
  [-38.6055, 145.6017],
  [-38.6069, 145.6029],
  [-38.6081, 145.6029]
];

const kirrakJunctionEasternArea = [
  [-38.6081, 145.6029],
  [-38.6158, 145.5999],
  [-38.6201, 145.5982],
  [-38.6215, 145.5969],
  [-38.6260, 145.5886]
];

const kirrakJunctionKirrak = [
  [-38.6081, 145.6029],
  [-38.6089, 145.6032],
  [-38.6097, 145.6041],
  [-38.6103, 145.6057],
  [-38.6112, 145.6144],
  [-38.6103, 145.6205],
  [-38.6095, 145.6223],
  [-38.6069, 145.6260],
  [-38.6062, 145.6278],
  [-38.6062, 145.6303],
  [-38.6055, 145.6326],
  [-38.6043, 145.6341],
  [-38.6025, 145.6346],
  [-38.5974, 145.6353]
];

const powlettNothWoolamaiCollieryCompanySiding = [
  [-38.5983, 145.5691],
  [-38.5977, 145.5687],
  [-38.5971, 145.5685],
  [-38.5964, 145.5684],
  [-38.5865, 145.5692],
  [-38.5800, 145.5706],
  [-38.5793, 145.5714],
  [-38.5787, 145.5723],
  [-38.5784, 145.5733],
  [-38.5784, 145.5742],
  [-38.5801, 145.5791]
];

const powlettNothWoolamaiCollieryCompanySidingWestJunction = [
  [-38.5865, 145.5692],
  [-38.5874, 145.5688],
  [-38.5882, 145.5680],
  [-38.5887, 145.5673],
  [-38.5899, 145.5627],
  [-38.5899, 145.5622],
  [-38.5899, 145.5616],
  [-38.5897, 145.5595],
  [-38.5896, 145.5590],
  [-38.5892, 145.5583]
];

const stateMine = [
  [-38.6005, 145.5726],
  [-38.6011, 145.5731],
  [-38.6018, 145.5734],
  [-38.6029, 145.5738],
  [-38.6034, 145.5739],
  [-38.6074, 145.5736],
  [-38.6079, 145.5732],
  [-38.6084, 145.5726],
  [-38.6087, 145.5720],
  [-38.6088, 145.5714],
  [-38.6087, 145.5708],
  [-38.6086, 145.5703],
  [-38.6084, 145.5699]
];

export const wonthaggi: Line = {
  name: 'Wonthaggi',
  state: 'VIC',
  segments: [
    {
      segments: [nyoraWonthaggi],
      history: {
        opened: {
          date: '1910-05-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-11-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wonthaggiKirrakJunction],
      history: {
        opened: {
          date: '1919-08-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-12-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kirrakJunctionEasternArea],
      history: {
        opened: {
          date: '1919-08-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kirrakJunctionKirrak],
      history: {
        opened: {
          date: '1939-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-12-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [powlettNothWoolamaiCollieryCompanySiding, powlettNothWoolamaiCollieryCompanySidingWestJunction],
      history: {
        opened: {
          date: '1910-12-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1928-10-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stateMine],
      history: {
        opened: {
          date: '1910-03-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-07-13',
          status: 'closed'
        }]
      }
    }
  ]
}
