import { Line } from "../../../trackTypes";

const dalbyKumbarilla = [
  [-27.1760, 151.2640],
  [-27.1755, 151.2624],
  [-27.1755, 151.2613],
  [-27.1756, 151.2604],
  [-27.1808, 151.2472],
  [-27.1813, 151.2453],
  [-27.1859, 151.2218],
  [-27.1862, 151.2208],
  [-27.1868, 151.2199],
  [-27.1975, 151.2095],
  [-27.2079, 151.1935],
  [-27.2435, 151.0403],
  [-27.2573, 150.9992],
  [-27.2585, 150.9967],
  [-27.2925, 150.9455],
  [-27.3129, 150.8943],
  [-27.3142, 150.8882],
  [-27.3154, 150.8761]
];

const kumbarillaTara = [
  [-27.3154, 150.8761],
  [-27.3201, 150.8254],
  [-27.3031, 150.7197],
  [-27.3026, 150.7005],
  [-27.3021, 150.6975],
  [-27.2964, 150.6800],
  [-27.2953, 150.6778],
  [-27.2879, 150.6679],
  [-27.2859, 150.6637],
  [-27.2851, 150.6599],
  [-27.2885, 150.5611],
  [-27.2775, 150.4579]
];

const taraTheGums = [
  [-27.2775, 150.4579],
  [-27.2745, 150.4298],
  [-27.2724, 150.4095],
  [-27.2727, 150.4068],
  [-27.3299, 150.2487],
  [-27.3303, 150.2467],
  [-27.3288, 150.1853]
];

const theGumsHannaford = [
  [-27.3288, 150.1853],
  [-27.3266, 150.0967],
  [-27.3264, 150.0638]
];

const hannafordMeandarra = [
  [-27.3264, 150.0638],
  [-27.3263, 150.0281],
  [-27.3220, 149.8852],
  [-27.3216, 149.8828],
  [-27.3213, 149.8818]
];

const meandarraGlenmorgan = [
  [-27.3213, 149.8818],
  [-27.3030, 149.8295],
  [-27.2491, 149.6761]
];

export const glenmorgan: Line = {
  name: 'Glenmorgan',
  state: 'QLD',
  segments: [
    {
      segments: [dalbyKumbarilla],
      history: {
        opened: {
          date: '1911-09-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kumbarillaTara],
      history: {
        opened: {
          date: '1911-10-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [taraTheGums],
      history: {
        opened: {
          date: '1925-08-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [theGumsHannaford],
      history: {
        opened: {
          date: '1926-06-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hannafordMeandarra],
      history: {
        opened: {
          date: '1927-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [meandarraGlenmorgan],
      history: {
        opened: {
          date: '1931-12-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    }
  ]
}
