import { Line } from "../../trackTypes";

const flindersStreetJolimontTunnel = [
  [-37.8168, 144.9718],
  [-37.8162, 144.9734],
  [-37.8157, 144.9746],
  [-37.8156, 144.9750],
  [-37.8156, 144.9757],
  [-37.8157, 144.9768],
  [-37.8168, 144.9869],
  [-37.8168, 144.9875]
];

const jolimontTunnel = [
  [-37.8168, 144.9875],
  [-37.8167, 144.9883],
  [-37.8165, 144.9892]
];

const jolimontTunnelWestRichmondTunnel = [
  [-37.8165, 144.9892],
  [-37.8162, 144.9899]
];

const westRichmondTunnel = [
  [-37.8162, 144.9899],
  [-37.8159, 144.9904],
  [-37.8155, 144.9908]
];

const westRichmondTunnelVictoriaPark = [
  [-37.8155, 144.9908],
  [-37.8152, 144.9911],
  [-37.8145, 144.9916],
  [-37.8140, 144.9918],
  [-37.8088, 144.9927],
  [-37.8076, 144.9931],
  [-37.8044, 144.9937],
  [-37.8044, 144.9937],
  [-37.8018, 144.9941],
  [-37.8013, 144.9941],
  [-37.7991, 144.9944]
];

const victoriaParkCliftonHill = [
  [-37.7991, 144.9944],
  [-37.7938, 144.9953],
  [-37.7931, 144.9953],
  [-37.7924, 144.9952],
  [-37.7918, 144.9950],
  [-37.7910, 144.9950],
  [-37.7904, 144.9950],
  [-37.7886, 144.9954]
];

const cliftonHillNorthcoteJunction = [
  [-37.7886, 144.9954],
  [-37.7867, 144.9957]
];

const northcoteJunctionWestgrath = [
  [-37.7867, 144.9957],
  [-37.7867, 144.9957],
  [-37.7860, 144.9959],
  [-37.7857, 144.9961],
  [-37.7824, 144.9981],
  [-37.7816, 144.9985],
  [-37.7810, 144.9988],
];

const westgrathAlphington = [
  [-37.7810, 144.9988],
  [-37.7806, 144.9992],
  [-37.7801, 144.9997],
  [-37.7796, 145.0004],
  [-37.7793, 145.0012],
  [-37.7792, 145.0022],
  [-37.7791, 145.0280],
  [-37.7789, 145.0294],
  [-37.7783, 145.0313]
];

const alphingtonIvanhoe = [
  [-37.7783, 145.0313],
  [-37.7768, 145.0359],
  [-37.7763, 145.0370],
  [-37.7756, 145.0379],
  [-37.7703, 145.0424],
  [-37.7699, 145.0428],
  [-37.7695, 145.0436],
  [-37.7688, 145.0454]
];

const ivanhoeHeidelberg = [
  [-37.7688, 145.0454],
  [-37.7682, 145.0472],
  [-37.7679, 145.0478],
  [-37.7674, 145.0486],
  [-37.7635, 145.0541],
  [-37.7631, 145.0546],
  [-37.7626, 145.0551],
  [-37.7621, 145.0556],
  [-37.7603, 145.0570],
  [-37.7598, 145.0574],
  [-37.7593, 145.0580],
  [-37.7588, 145.0586],
  [-37.7571, 145.0607]
];

const heidelbergHeidelbergTunnel = [
  [-37.7571, 145.0607],
  [-37.7542, 145.0648]
];

const heidelbergTunnel = [
  [-37.7542, 145.0648],
  [-37.7537, 145.0653],
];

const heidelbergTunnelRosanaJunction = [
  [-37.7537, 145.0653],
  [-37.7530, 145.0659],
  [-37.7524, 145.0662],
  [-37.7517, 145.0664],
  [-37.7511, 145.0665],
  [-37.7484, 145.0667]
];

const rosanaJunctionMacleod = [
  [-37.7484, 145.0667],
  [-37.7469, 145.0668],
  [-37.7462, 145.0668],
  [-37.7454, 145.0667],
  [-37.7415, 145.0658],
  [-37.7410, 145.0657],
  [-37.7384, 145.0653],
  [-37.7375, 145.0653],
  [-37.7367, 145.0653],
  [-37.7359, 145.0655],
  [-37.7353, 145.0657],
  [-37.7291, 145.0685],
  [-37.7283, 145.0687],
  [-37.7276, 145.0689],
  [-37.7259, 145.0691]
];

const macleodGreensborough = [
  [-37.7259, 145.0691],
  [-37.7241, 145.0693],
  [-37.7228, 145.0697],
  [-37.7222, 145.0701],
  [-37.7214, 145.0707],
  [-37.7208, 145.0713],
  [-37.7203, 145.0721],
  [-37.7186, 145.0754],
  [-37.7181, 145.0764],
  [-37.7175, 145.0770],
  [-37.7170, 145.0774],
  [-37.7160, 145.0780],
  [-37.7154, 145.0786],
  [-37.7149, 145.0792],
  [-37.7141, 145.0804],
  [-37.7135, 145.0813],
  [-37.7101, 145.0844],
  [-37.7096, 145.0850],
  [-37.7074, 145.0878],
  [-37.7066, 145.0886],
  [-37.7060, 145.0890],
  [-37.7036, 145.0905],
  [-37.7023, 145.0915],
  [-37.7018, 145.0920],
  [-37.6997, 145.0943],
  [-37.6994, 145.0949],
  [-37.6991, 145.0956],
  [-37.6989, 145.0967],
  [-37.6989, 145.0977],
  [-37.6990, 145.0983],
  [-37.6992, 145.0988],
  [-37.6996, 145.0998],
  [-37.7000, 145.1013],
  [-37.7002, 145.1020],
  [-37.7006, 145.1027],
  [-37.7014, 145.1038],
  [-37.7019, 145.1047],
  [-37.7024, 145.1061],
  [-37.7028, 145.1069],
  [-37.7035, 145.1077],
  [-37.7040, 145.1082]
];

const greensboroughMontmorency = [
  [-37.7040, 145.1082],
  [-37.7053, 145.1092],
  [-37.7062, 145.1098],
  [-37.7067, 145.1103],
  [-37.7086, 145.1124],
  [-37.7093, 145.1130],
  [-37.7133, 145.1161],
  [-37.7141, 145.1169],
  [-37.7145, 145.1177],
  [-37.7148, 145.1185],
  [-37.7150, 145.1195],
  [-37.7150, 145.1204],
  [-37.7151, 145.1210],
  [-37.7153, 145.1219],
  [-37.7155, 145.1224]
];

const montmorencyEltham = [
  [-37.7155, 145.1224],
  [-37.7161, 145.1241],
  [-37.7164, 145.1249],
  [-37.7166, 145.1264],
  [-37.7166, 145.1285],
  [-37.7165, 145.1293],
  [-37.7163, 145.1303],
  [-37.7158, 145.1316],
  [-37.7149, 145.1336],
  [-37.7146, 145.1343],
  [-37.7145, 145.1349],
  [-37.7145, 145.1356],
  [-37.7146, 145.1364],
  [-37.7148, 145.1371],
  [-37.7151, 145.1376],
  [-37.7161, 145.1391],
  [-37.7164, 145.1396],
  [-37.7166, 145.1401],
  [-37.7167, 145.1409],
  [-37.7167, 145.1416],
  [-37.7167, 145.1421],
  [-37.7160, 145.1449],
  [-37.7158, 145.1456],
  [-37.7155, 145.1462],
  [-37.7149, 145.1467],
  [-37.7143, 145.1473],
  [-37.7135, 145.1477]
];

const elthamDiamondCreek = [
  [-37.7135, 145.1477],
  [-37.7120, 145.1485],
  [-37.7113, 145.1487],
  [-37.7102, 145.1488],
  [-37.7082, 145.1487],
  [-37.7073, 145.1488],
  [-37.7064, 145.1495],
  [-37.7034, 145.1537],
  [-37.7021, 145.1549],
  [-37.7011, 145.1552],
  [-37.7001, 145.1551],
  [-37.6990, 145.1546],
  [-37.6982, 145.1538],
  [-37.6973, 145.1528],
  [-37.6963, 145.1523],
  [-37.6954, 145.1523],
  [-37.6951, 145.1524],
  [-37.6890, 145.1559],
  [-37.6874, 145.1563],
  [-37.6859, 145.1561],
  [-37.6846, 145.1553],
  [-37.6821, 145.1525],
  [-37.6808, 145.1517],
  [-37.6797, 145.1514],
  [-37.6783, 145.1518],
  [-37.6774, 145.1525],
  [-37.6749, 145.1553],
  [-37.6740, 145.1568],
  [-37.6734, 145.1583],
  [-37.6733, 145.1586]
];

const diamondCreekWattleGlen = [
  [-37.6733, 145.1586],
  [-37.6730, 145.1599],
  [-37.6729, 145.1615],
  [-37.6727, 145.1636],
  [-37.6725, 145.1643],
  [-37.6722, 145.1651],
  [-37.6710, 145.1671],
  [-37.6705, 145.1681],
  [-37.6674, 145.1784],
  [-37.6666, 145.1799]
];

const wattleGlenHurstbridge = [
  [-37.6666, 145.1799],
  [-37.6663, 145.1805],
  [-37.6657, 145.1809],
  [-37.6651, 145.1813],
  [-37.6609, 145.1827],
  [-37.6602, 145.1831],
  [-37.6597, 145.1837],
  [-37.6590, 145.1849],
  [-37.6576, 145.1863],
  [-37.6540, 145.1882],
  [-37.6508, 145.1892],
  [-37.6502, 145.1893],
  [-37.6493, 145.1891],
  [-37.6485, 145.1893],
  [-37.6478, 145.1895],
  [-37.6465, 145.1894],
  [-37.6457, 145.1894],
  [-37.6441, 145.1904],
  [-37.6432, 145.1905],
  [-37.6419, 145.1903],
  [-37.6412, 145.1905],
  [-37.6404, 145.1910],
  [-37.6376, 145.1938]
];

export const hurstbridge: Line = {
  name: 'Hurstbridge',
  state: 'VIC',
  segments: [
    {
      segments: [
        flindersStreetJolimontTunnel,
        {
          name: 'Jolimont Tunnel',
          segment: jolimontTunnel,
          type: 'tunnel'
        },
        jolimontTunnelWestRichmondTunnel,
        {
          name: 'West Richmond Tunnel',
          segment: westRichmondTunnel,
          type: 'tunnel'
        },
        westRichmondTunnelVictoriaPark
      ],
      history: {
        opened: {
          date: '1901-10-21',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [ivanhoeHeidelberg],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-06-19',
          tracks: 2
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [victoriaParkCliftonHill],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1901-10-21',
          tracks: 2
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [cliftonHillNorthcoteJunction],
      history: {
        opened: {
          date: '1886-02-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'tram' //gas
        },
        trackChange: [{
          date: '1888-05-08',
          trackType: 'heavy'
        }, {
          date: '1901-10-21',
          tracks: 2
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [northcoteJunctionWestgrath],
      history: {
        opened: {
          date: '1886-02-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'tram' //gas
        },
        trackChange: [{
          date: '1888-05-08',
          trackType: 'heavy'
        }, {
          date: '2009-01-27',
          tracks: 2
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [westgrathAlphington],
      history: {
        opened: {
          date: '1886-02-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'tram' //gas
        },
        trackChange: [{
          date: '1888-05-08',
          trackType: 'heavy'
        }, {
          date: '1912-12-08',
          tracks: 2
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [alphingtonIvanhoe],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-12-16',
          tracks: 2,
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        heidelbergHeidelbergTunnel,
        {
          name: 'Heidelberg Tunnel',
          segment: heidelbergTunnel,
          type: 'tunnel'
        },
        heidelbergTunnelRosanaJunction
      ],
      history: {
        opened: {
          date: '1902-06-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-05-04',
          tracks: 2
        }, {
          date: '1923-04-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [rosanaJunctionMacleod],
      history: {
        opened: {
          date: '1902-06-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-12-14',
          tracks: 2
        }, {
          date: '1923-05-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [macleodGreensborough],
      history: {
        opened: {
          date: '1902-06-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-08-12',
          tracks: 2,
        }, {
          date: '1923-04-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [greensboroughMontmorency],
      history: {
        opened: {
          date: '1902-06-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1923-04-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2023-04-30',
          tracks: 2
        }]
      }
    },
    {
      segments: [montmorencyEltham],
      history: {
        opened: {
          date: '1902-06-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1923-04-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [elthamDiamondCreek],
      history: {
        opened: {
          date: '1912-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [diamondCreekWattleGlen],
      history: {
        opened: {
          date: '1912-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2023-04-30',
          tracks: 2
        }]
      }
    },
    {
      segments: [wattleGlenHurstbridge],
      history: {
        opened: {
          date: '1912-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
