import { Line } from "../../../trackTypes";

// * australian smelting company line
// * - branch off dapto
// * - opened 1895-12-12
// * - closed 1907
// *
// * TALLAWARRA POWER STATION SIDING
// * - opened 1961-12-27
// * - branch point close to yallah need closed date

const shellharbourQuary = [
  [-34.6074, 150.8396],
  [-34.6079, 150.8391],
  [-34.6083, 150.8387],
  [-34.6118, 150.8284],
  [-34.6118, 150.8273],
  [-34.6115, 150.8260]
];

const bomboStateQuary = [
  [-34.6552, 150.8548],
  [-34.6547, 150.8548],
  [-34.6542, 150.8545],
  [-34.6537, 150.8540],
  [-34.6535, 150.8534],
  [-34.6532, 150.8517],
  [-34.6528, 150.8501],
  [-34.6526, 150.8486]
];

const bomaderrySiding = [
  [-34.8529, 150.6103],
  [-34.8538, 150.6103],
  [-34.8544, 150.6107],
  [-34.8559, 150.6132],
  [-34.8560, 150.6143],
  [-34.8555, 150.6157],
  [-34.8535, 150.6211],
  [-34.8530, 150.6234],
  [-34.8527, 150.6266],
  [-34.8527, 150.6276],
  [-34.8529, 150.6298]
];

export const illawarraSidings: Line = {
  name: 'Illawarra Sidings',
  state: 'NSW',
  segments: [
    {
      segments: [shellharbourQuary],
      history: {
        opened: {
          date: '1923-08-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }, {
          date: '1937-10-26',
          status: 'open'
        }]
      }
    },
    {
      segments: [bomboStateQuary],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bomaderrySiding],
      history: {
        opened: {
          date: '1938-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
