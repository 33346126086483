import { Line } from "../../trackTypes";

const line = [
  [-32.2096, 148.6197],
  [-32.2080, 148.6215],
  [-32.2047, 148.6280],
  [-32.2038, 148.6313],
  [-32.1961, 148.7009],
  [-32.1968, 148.7366],
  [-32.1966, 148.7397],
  [-32.1933, 148.7579],
  [-32.1917, 148.7640],
  [-32.1914, 148.7671],
  [-32.1916, 148.7698],
  [-32.1961, 148.7884],
  [-32.1984, 148.8011],
  [-32.1991, 148.8031],
  [-32.2026, 148.8094],
  [-32.2031, 148.8112],
  [-32.2030, 148.8127],
  [-32.2025, 148.8140],
  [-32.2017, 148.8153],
  [-32.2012, 148.8167],
  [-32.2011, 148.8181],
  [-32.2014, 148.8195],
  [-32.2034, 148.8238],
  [-32.2042, 148.8250],
  [-32.2050, 148.8256],
  [-32.2190, 148.8332],
  [-32.2203, 148.8343],
  [-32.2218, 148.8365],
  [-32.2230, 148.8392],
  [-32.2235, 148.8420],
  [-32.2233, 148.8444],
  [-32.2229, 148.8462],
  [-32.2180, 148.8587],
  [-32.2177, 148.8602],
  [-32.2165, 148.8682],
  [-32.2158, 148.8703],
  [-32.2150, 148.8718],
  [-32.1985, 148.8925],
  [-32.1978, 148.8939],
  [-32.1952, 148.9017],
  [-32.1823, 148.9296],
  [-32.1820, 148.9313],
  [-32.1823, 148.9327],
  [-32.1840, 148.9371],
  [-32.1845, 148.9392],
  [-32.1847, 148.9455],
  [-32.1846, 148.9470],
  [-32.1842, 148.9487],
  [-32.1832, 148.9508],
  [-32.1720, 148.9653],
  [-32.1711, 148.9671],
  [-32.1706, 148.9686],
  [-32.1687, 148.9770],
  [-32.1679, 148.9784],
  [-32.1668, 148.9794],
  [-32.1652, 148.9796],
  [-32.1640, 148.9792],
  [-32.1633, 148.9786],
  [-32.1617, 148.9770],
  [-32.1606, 148.9764],
  [-32.1593, 148.9764],
  [-32.1462, 148.9828],
  [-32.1451, 148.9838],
  [-32.1446, 148.9851],
  [-32.1443, 148.9882],
  [-32.1438, 148.9895],
  [-32.1429, 148.9904],
  [-32.1417, 148.9908],
  [-32.1404, 148.9904],
  [-32.1390, 148.9896],
  [-32.1381, 148.9893],
  [-32.1371, 148.9894],
  [-32.1364, 148.9898],
  [-32.1328, 148.9931],
  [-32.1313, 148.9950],
  [-32.1205, 149.0119],
  [-32.1195, 149.0141],
  [-32.1146, 149.0288],
  [-32.1138, 149.0305],
  [-32.1047, 149.0496],
  [-32.0985, 149.0610],
  [-32.0960, 149.0639],
  [-32.0843, 149.0712],
  [-32.0812, 149.0718],
  [-32.0763, 149.0715],
  [-32.0738, 149.0722],
  [-32.0712, 149.0741],
  [-32.0540, 149.0956],
  [-32.0506, 149.0982],
  [-32.0300, 149.1055],
  [-32.0286, 149.1054],
  [-32.0271, 149.1045],
  [-32.0263, 149.1035],
  [-32.0254, 149.1023],
  [-32.0242, 149.1017],
  [-32.0226, 149.1017],
  [-32.0214, 149.1026],
  [-32.0204, 149.1049],
  [-32.0190, 149.1065],
  [-32.0176, 149.1068],
  [-32.0124, 149.1056],
  [-32.0105, 149.1056],
  [-32.0044, 149.1066],
  [-32.0030, 149.1074],
  [-31.9989, 149.1114],
  [-31.9940, 149.1144],
  [-31.9904, 149.1153],
  [-31.9883, 149.1149],
  [-31.9866, 149.1138],
  [-31.9797, 149.1066],
  [-31.9769, 149.1051],
  [-31.9736, 149.1047],
  [-31.9714, 149.1037],
  [-31.9701, 149.1027],
  [-31.9685, 149.1024],
  [-31.9672, 149.1029],
  [-31.9664, 149.1039],
  [-31.9655, 149.1056],
  [-31.9644, 149.1065],
  [-31.9631, 149.1067],
  [-31.9614, 149.1059],
  [-31.9603, 149.1043],
  [-31.9588, 149.1032],
  [-31.9568, 149.1027],
  [-31.9553, 149.1030],
  [-31.9543, 149.1037],
  [-31.9529, 149.1053],
  [-31.9524, 149.1065],
  [-31.9522, 149.1076],
  [-31.9525, 149.1089],
  [-31.9532, 149.1104],
  [-31.9536, 149.1117],
  [-31.9534, 149.1132],
  [-31.9526, 149.1145],
  [-31.9516, 149.1152],
  [-31.9502, 149.1157],
  [-31.9492, 149.1165],
  [-31.9451, 149.1224],
  [-31.9440, 149.1232],
  [-31.9427, 149.1233],
  [-31.9411, 149.1226],
  [-31.9400, 149.1213],
  [-31.9387, 149.1206],
  [-31.9371, 149.1205],
  [-31.9358, 149.1211],
  [-31.9341, 149.1226],
  [-31.9326, 149.1232],
  [-31.9301, 149.1235],
  [-31.9286, 149.1246],
  [-31.9275, 149.1258],
  [-31.9265, 149.1265],
  [-31.9250, 149.1267],
  [-31.9237, 149.1262],
  [-31.9224, 149.1253],
  [-31.9208, 149.1250],
  [-31.9191, 149.1251],
  [-31.9179, 149.1248],
  [-31.9171, 149.1241],
  [-31.9159, 149.1224],
  [-31.9149, 149.1217],
  [-31.9138, 149.1216],
  [-31.9128, 149.1218],
  [-31.9117, 149.1225],
  [-31.9106, 149.1228],
  [-31.9094, 149.1225],
  [-31.9086, 149.1220],
  [-31.9058, 149.1191],
  [-31.9052, 149.1181],
  [-31.9049, 149.1172],
  [-31.9047, 149.1155],
  [-31.9042, 149.1145],
  [-31.9034, 149.1136],
  [-31.9026, 149.1132],
  [-31.9010, 149.1128],
  [-31.9000, 149.1123],
  [-31.8987, 149.1113],
  [-31.8973, 149.1108],
  [-31.8785, 149.1107],
  [-31.8766, 149.1109],
  [-31.8531, 149.1164],
  [-31.8381, 149.1243],
  [-31.8369, 149.1254],
  [-31.8342, 149.1338],
  [-31.8308, 149.1719],
  [-31.8305, 149.1736],
  [-31.8237, 149.1913],
  [-31.8235, 149.1930],
  [-31.8238, 149.1945],
  [-31.8250, 149.1967],
  [-31.8256, 149.1981],
  [-31.8273, 149.2057],
  [-31.8274, 149.2077],
  [-31.8272, 149.2096],
  [-31.8260, 149.2142],
  [-31.8248, 149.2168],
  [-31.8237, 149.2184],
  [-31.8233, 149.2194],
  [-31.8234, 149.2207],
  [-31.8240, 149.2217],
  [-31.8250, 149.2224],
  [-31.8260, 149.2224],
  [-31.8272, 149.2219],
  [-31.8281, 149.2219],
  [-31.8292, 149.2225],
  [-31.8298, 149.2236],
  [-31.8297, 149.2252],
  [-31.8294, 149.2260],
  [-31.8287, 149.2268]
];

export const troyJunctionMerrygoen: Line = {
  name: 'Troy Junction Merrygoen',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1918-04-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
