import { Line } from "../../../trackTypes";

const line = [
  [-46.0851, 168.9892],
  [-46.0855, 168.9909],
  [-46.0855, 168.9922],
  [-46.0849, 168.9936],
  [-46.0841, 168.9944],
  [-46.0818, 168.9956],
  [-46.0808, 168.9964],
  [-46.0768, 169.0024],
  [-46.0760, 169.0029],
  [-46.0691, 169.0054],
  [-46.0685, 169.0060],
  [-46.0673, 169.0076],
  [-46.0666, 169.0080],
  [-46.0619, 169.0083],
  [-46.0603, 169.0081],
  [-46.0594, 169.0084],
  [-46.0563, 169.0111],
  [-46.0520, 169.0141],
  [-46.0512, 169.0154],
  [-46.0508, 169.0168],
  [-46.0502, 169.0181],
  [-46.0495, 169.0188],
  [-46.0477, 169.0196],
  [-46.0466, 169.0209],
  [-46.0443, 169.0222],
  [-46.0439, 169.0228],
  [-46.0406, 169.0277],
  [-46.0397, 169.0283],
  [-46.0368, 169.0289],
  [-46.0314, 169.0320],
  [-46.0305, 169.0320],
  [-46.0226, 169.0307],
  [-46.0117, 169.0279],
  [-46.0097, 169.0280],
  [-45.9968, 169.0288],
  [-45.9895, 169.0281],
  [-45.9889, 169.0278],
  [-45.9863, 169.0259],
  [-45.9791, 169.0240],
  [-45.9711, 169.0213],
  [-45.9704, 169.0208],
  [-45.9674, 169.0158],
  [-45.9585, 169.0083],
  [-45.9534, 169.0037],
  [-45.9530, 169.0035],
  [-45.9525, 169.0037],
  [-45.9446, 169.0089],
  [-45.9440, 169.0089],
  [-45.9435, 169.0086],
  [-45.9406, 169.0057],
  [-45.9396, 169.0052],
  [-45.9378, 169.0051],
  [-45.9361, 169.0058],
  [-45.9323, 169.0096],
  [-45.9248, 169.0143],
  [-45.9235, 169.0146],
  [-45.9227, 169.0144],
  [-45.9222, 169.0141],
  [-45.9205, 169.0124]
];

export const waikaka: Line = {
  name: 'Waikaka',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1908-11-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-09-09',
          status: 'closed'
        }]
      }
    }
  ]
}
