import { Line } from "../../trackTypes";

const junction = [
  [-23.6522, 150.6451],
  [-23.6516, 150.6450],
  [-23.6512, 150.6452],
  [-23.6510, 150.6454],
  [-23.6508, 150.6457]
];

const bajoolSaltWorks = [
  [-23.6504, 150.6437],
  [-23.6509, 150.6448],
  [-23.6508, 150.6457],
  [-23.6503, 150.6466],
  [-23.6388, 150.6543],
  [-23.6367, 150.6562],
  [-23.6331, 150.6600],
  [-23.6307, 150.6643],
  [-23.6262, 150.6902],
  [-23.6220, 150.7280]
];

const saltWorksPortAlma = [
  [-23.6220, 150.7280],
  [-23.6199, 150.7480],
  [-23.6218, 150.7678],
  [-23.6255, 150.7827],
  [-23.6251, 150.7854],
  [-23.6239, 150.7870],
  [-23.6116, 150.7947],
  [-23.6095, 150.7966],
  [-23.5900, 150.8175],
  [-23.5887, 150.8197],
  [-23.5880, 150.8224],
  [-23.5857, 150.8612]
];

export const portAlma: Line = {
  name: 'Port Alma',
  state: 'QLD',
  segments: [
    {
      segments: [junction, bajoolSaltWorks],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [saltWorksPortAlma],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
