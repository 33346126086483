import { Line } from "../../trackTypes";

const belairNationalParkTunnel = [
  [-34.9980, 138.6329],
  [-34.9976, 138.6344],
  [-34.9971, 138.6384],
  [-34.9971, 138.6400],
  [-34.9992, 138.6498],
  [-34.9999, 138.6509],
  [-35.0009, 138.6514],
  [-35.0022, 138.6513],
  [-35.0029, 138.6516],
  [-35.0038, 138.6524],
  [-35.0047, 138.6526],
  [-35.0055, 138.6523],
  [-35.0065, 138.6517],
  [-35.0074, 138.6515],
  [-35.0082, 138.6520],
  [-35.0087, 138.6526],
  [-35.0093, 138.6538],
  [-35.0099, 138.6545],
  [-35.0105, 138.6548],
  [-35.0107, 138.6548]
];

const nationalParkTunnel = [
  [-35.0107, 138.6548],
  [-35.0126, 138.6545]
];

const nationalParkTunnelLongGullyTunnel = [
  [-35.0126, 138.6545],
  [-35.0138, 138.6543],
  [-35.0144, 138.6545],
  [-35.0148, 138.6548],
  [-35.0152, 138.6553],
  [-35.0155, 138.6561],
  [-35.0154, 138.6575],
  [-35.0146, 138.6590],
  [-35.0141, 138.6598],
  [-35.0137, 138.6614],
  [-35.0131, 138.6624],
  [-35.0127, 138.6631],
  [-35.0125, 138.6639],
  [-35.0125, 138.6648],
  [-35.0128, 138.6659],
  [-35.0128, 138.6666],
  [-35.0126, 138.6675],
  [-35.0117, 138.6692],
  [-35.0113, 138.6703],
  [-35.0112, 138.6725],
  [-35.0114, 138.6734],
  [-35.0118, 138.6740]
];

const longGullyTunnel = [
  [-35.0118, 138.6740],
  [-35.0121, 138.6744],
  [-35.0138, 138.6754]
];

const longGullyTunnelUpperSturtTunnel = [
  [-35.0138, 138.6754],
  [-35.0144, 138.6759],
  [-35.0148, 138.6765],
  [-35.0150, 138.6773],
  [-35.0150, 138.6779],
  [-35.0147, 138.6788],
  [-35.0141, 138.6795],
  [-35.0133, 138.6802],
  [-35.0129, 138.6810],
  [-35.0129, 138.6819],
  [-35.0130, 138.6830],
  [-35.0129, 138.6838],
  [-35.0127, 138.6846],
  [-35.0127, 138.6855],
  [-35.0133, 138.6869],
  [-35.0134, 138.6876],
  [-35.0134, 138.6881],
  [-35.0131, 138.6900],
  [-35.0132, 138.6925],
  [-35.0135, 138.6965]
];

const upperSturtTunnel = [
  [-35.0135, 138.6965],
  [-35.0134, 138.6980],
  [-35.0132, 138.6989]
];

const upperSturtTunnelAldgate = [
  [-35.0132, 138.6989],
  [-35.0127, 138.6996],
  [-35.0121, 138.7000],
  [-35.0106, 138.7004],
  [-35.0101, 138.7008],
  [-35.0095, 138.7015],
  [-35.0090, 138.7019],
  [-35.0084, 138.7021],
  [-35.0075, 138.7026],
  [-35.0070, 138.7037],
  [-35.0069, 138.7057],
  [-35.0075, 138.7079],
  [-35.0077, 138.7098],
  [-35.0081, 138.7108],
  [-35.0087, 138.7113],
  [-35.0093, 138.7115],
  [-35.0104, 138.7117],
  [-35.0109, 138.7119],
  [-35.0117, 138.7125],
  [-35.0125, 138.7127],
  [-35.0136, 138.7125],
  [-35.0145, 138.7124],
  [-35.0150, 138.7125],
  [-35.0156, 138.7129],
  [-35.0167, 138.7143],
  [-35.0174, 138.7147],
  [-35.0179, 138.7148],
  [-35.0191, 138.7146],
  [-35.0196, 138.7147],
  [-35.0203, 138.7150],
  [-35.0210, 138.7160],
  [-35.0212, 138.7170],
  [-35.0211, 138.7179],
  [-35.0193, 138.7217],
  [-35.0188, 138.7225],
  [-35.0176, 138.7240],
  [-35.0171, 138.7249],
  [-35.0143, 138.7320]
];

const aldgateAmblesideTunnel = [
  [-35.0143, 138.7320],
  [-35.0141, 138.7329],
  [-35.0140, 138.7339],
  [-35.0140, 138.7346],
  [-35.0142, 138.7358],
  [-35.0143, 138.7367],
  [-35.0141, 138.7376],
  [-35.0124, 138.7417],
  [-35.0122, 138.7424],
  [-35.0118, 138.7444],
  [-35.0114, 138.7456],
  [-35.0109, 138.7465],
  [-35.0100, 138.7477],
  [-35.0095, 138.7486],
  [-35.0089, 138.7507],
  [-35.0079, 138.7527],
  [-35.0077, 138.7538],
  [-35.0080, 138.7550],
  [-35.0082, 138.7562],
  [-35.0083, 138.7645],
  [-35.0084, 138.7653],
  [-35.0090, 138.7667],
  [-35.0092, 138.7675],
  [-35.0091, 138.7685],
  [-35.0087, 138.7693],
  [-35.0075, 138.7706],
  [-35.0057, 138.7734],
  [-35.0054, 138.7742],
  [-35.0053, 138.7750],
  [-35.0053, 138.7760],
  [-35.0051, 138.7769],
  [-35.0026, 138.7812],
  [-35.0019, 138.7820],
  [-34.9998, 138.7836],
  [-34.9992, 138.7838],
  [-34.9986, 138.7837]
];

const amblesideTunnel = [
  [-34.9986, 138.7837],
  [-34.9971, 138.7832]
];

const amblesideTunnelNairne = [
  [-34.9971, 138.7832],
  [-34.9955, 138.7826],
  [-34.9946, 138.7827],
  [-34.9937, 138.7833],
  [-34.9932, 138.7844],
  [-34.9931, 138.7853],
  [-34.9933, 138.7871],
  [-34.9937, 138.7882],
  [-34.9946, 138.7891],
  [-34.9971, 138.7935],
  [-34.9977, 138.7942],
  [-34.9985, 138.7946],
  [-34.9993, 138.7948],
  [-35.0002, 138.7955],
  [-35.0008, 138.7965],
  [-35.0016, 138.7973],
  [-35.0034, 138.7976],
  [-35.0043, 138.7981],
  [-35.0047, 138.7990],
  [-35.0048, 138.8000],
  [-35.0040, 138.8054],
  [-35.0032, 138.8079],
  [-35.0023, 138.8095],
  [-34.9989, 138.8134],
  [-34.9981, 138.8149],
  [-34.9978, 138.8167],
  [-34.9980, 138.8185],
  [-34.9978, 138.8197],
  [-34.9972, 138.8208],
  [-34.9957, 138.8219],
  [-34.9951, 138.8228],
  [-34.9942, 138.8253],
  [-34.9941, 138.8262],
  [-34.9944, 138.8272],
  [-34.9948, 138.8280],
  [-34.9964, 138.8300],
  [-34.9968, 138.8305],
  [-35.0042, 138.8417],
  [-35.0048, 138.8424],
  [-35.0076, 138.8443],
  [-35.0095, 138.8459],
  [-35.0137, 138.8484],
  [-35.0149, 138.8493],
  [-35.0170, 138.8519],
  [-35.0186, 138.8534],
  [-35.0201, 138.8545],
  [-35.0209, 138.8555],
  [-35.0220, 138.8571],
  [-35.0260, 138.8610],
  [-35.0266, 138.8620],
  [-35.0274, 138.8627],
  [-35.0285, 138.8629],
  [-35.0303, 138.8628],
  [-35.0311, 138.8631],
  [-35.0318, 138.8639],
  [-35.0321, 138.8649],
  [-35.0320, 138.8659],
  [-35.0318, 138.8665],
  [-35.0304, 138.8697],
  [-35.0303, 138.8705],
  [-35.0303, 138.8711],
  [-35.0318, 138.8791],
  [-35.0323, 138.8802],
  [-35.0362, 138.8842],
  [-35.0367, 138.8845],
  [-35.0375, 138.8849],
  [-35.0382, 138.8855],
  [-35.0387, 138.8864],
  [-35.0389, 138.8876],
  [-35.0383, 138.8906],
  [-35.0380, 138.8913],
  [-35.0375, 138.8919],
  [-35.0368, 138.8922],
  [-35.0356, 138.8923],
  [-35.0348, 138.8927],
  [-35.0342, 138.8934],
  [-35.0339, 138.8939],
  [-35.0335, 138.8956],
  [-35.0336, 138.8969],
  [-35.0339, 138.8978],
  [-35.0348, 138.8992],
  [-35.0351, 138.9003],
  [-35.0352, 138.9013],
  [-35.0348, 138.9036],
  [-35.0347, 138.9044],
  [-35.0347, 138.9053]
];

const nairneMurrayBridge = [
  [-35.0347, 138.9053],
  [-35.0347, 138.9069],
  [-35.0351, 138.9092],
  [-35.0354, 138.9101],
  [-35.0358, 138.9108],
  [-35.0367, 138.9119],
  [-35.0371, 138.9126],
  [-35.0380, 138.9155],
  [-35.0389, 138.9174],
  [-35.0392, 138.9186],
  [-35.0393, 138.9201],
  [-35.0396, 138.9213],
  [-35.0401, 138.9221],
  [-35.0407, 138.9227],
  [-35.0421, 138.9236],
  [-35.0428, 138.9244],
  [-35.0433, 138.9255],
  [-35.0435, 138.9265],
  [-35.0434, 138.9276],
  [-35.0436, 138.9286],
  [-35.0441, 138.9296],
  [-35.0449, 138.9303],
  [-35.0496, 138.9320],
  [-35.0510, 138.9330],
  [-35.0519, 138.9346],
  [-35.0525, 138.9369],
  [-35.0530, 138.9377],
  [-35.0537, 138.9383],
  [-35.0549, 138.9384],
  [-35.0558, 138.9383],
  [-35.0577, 138.9385],
  [-35.0585, 138.9388],
  [-35.0594, 138.9395],
  [-35.0617, 138.9434],
  [-35.0625, 138.9442],
  [-35.0634, 138.9446],
  [-35.0642, 138.9447],
  [-35.0649, 138.9450],
  [-35.0656, 138.9457],
  [-35.0663, 138.9466],
  [-35.0671, 138.9472],
  [-35.0727, 138.9501],
  [-35.0741, 138.9512],
  [-35.0761, 138.9518],
  [-35.0785, 138.9534],
  [-35.0792, 138.9544],
  [-35.0795, 138.9557],
  [-35.0791, 138.9582],
  [-35.0790, 138.9593],
  [-35.0792, 138.9602],
  [-35.0798, 138.9611],
  [-35.0804, 138.9617],
  [-35.0826, 138.9628],
  [-35.0866, 138.9674],
  [-35.0871, 138.9688],
  [-35.0872, 138.9704],
  [-35.0871, 138.9714],
  [-35.0866, 138.9727],
  [-35.0865, 138.9735],
  [-35.0867, 138.9746],
  [-35.0891, 138.9785],
  [-35.0896, 138.9790],
  [-35.0904, 138.9795],
  [-35.0912, 138.9806],
  [-35.0915, 138.9818],
  [-35.0919, 138.9827],
  [-35.0927, 138.9838],
  [-35.0932, 138.9849],
  [-35.0947, 138.9917],
  [-35.0952, 138.9930],
  [-35.0974, 138.9967],
  [-35.0983, 138.9976],
  [-35.1051, 139.0007],
  [-35.1061, 139.0017],
  [-35.1070, 139.0036],
  [-35.1071, 139.0054],
  [-35.1067, 139.0067],
  [-35.1061, 139.0083],
  [-35.1027, 139.0227],
  [-35.1026, 139.0241],
  [-35.1030, 139.0256],
  [-35.1038, 139.0268],
  [-35.1083, 139.0313],
  [-35.1090, 139.0325],
  [-35.1111, 139.0390],
  [-35.1114, 139.0411],
  [-35.1113, 139.0524],
  [-35.1118, 139.0548],
  [-35.1133, 139.0576],
  [-35.1155, 139.0595],
  [-35.1234, 139.0628],
  [-35.1267, 139.0631],
  [-35.1279, 139.0634],
  [-35.1314, 139.0659],
  [-35.1334, 139.0687],
  [-35.1337, 139.0699],
  [-35.1337, 139.0711],
  [-35.1231, 139.1295],
  [-35.1177, 139.1587],
  [-35.1161, 139.1620],
  [-35.1142, 139.1644],
  [-35.1132, 139.1667],
  [-35.1122, 139.1724],
  [-35.1123, 139.1760],
  [-35.1130, 139.1786],
  [-35.1131, 139.1813],
  [-35.1106, 139.1995],
  [-35.1099, 139.2009],
  [-35.1092, 139.2016],
  [-35.1079, 139.2021],
  [-35.1073, 139.2026],
  [-35.1047, 139.2061],
  [-35.1043, 139.2071],
  [-35.1043, 139.2080],
  [-35.1048, 139.2133],
  [-35.1052, 139.2143],
  [-35.1059, 139.2154],
  [-35.1061, 139.2161],
  [-35.1063, 139.2176],
  [-35.1066, 139.2184],
  [-35.1072, 139.2194],
  [-35.1075, 139.2202],
  [-35.1075, 139.2211],
  [-35.1072, 139.2232],
  [-35.1071, 139.2300],
  [-35.1126, 139.2560],
  [-35.1151, 139.2722],
  [-35.1160, 139.2746],
  [-35.1170, 139.2761]
];

const murrayBridgeOld = [
  [-35.1170, 139.2761],
  [-35.1173, 139.2776],
  [-35.1172, 139.2782],
  [-35.1164, 139.2791],
  [-35.1127, 139.2827],
  [-35.1121, 139.2841],
  [-35.1121, 139.2855],
  [-35.1127, 139.2871],
  [-35.1128, 139.2884],
  [-35.1123, 139.2964],
  [-35.1127, 139.3015],
  [-35.1134, 139.3034],
  [-35.1274, 139.3225],
  [-35.1291, 139.3237],
  [-35.1405, 139.3286],
];

const murrayBridgeNewStart = [
  [-35.1170, 139.2761],
  [-35.1173, 139.2768],
  [-35.1175, 139.2776]
];

const murrayBridgeTunnel = [
  [-35.1175, 139.2776],
  [-35.1175, 139.2788]
];

const murrayBridgeNew = [
  [-35.1175, 139.2788],
  [-35.1171, 139.2797],
  [-35.1152, 139.2823],
  [-35.1147, 139.2837],
  [-35.1146, 139.2849],
  [-35.1148, 139.2862],
  [-35.1152, 139.2872],
  [-35.1163, 139.2884],
  [-35.1166, 139.2892],
  [-35.1172, 139.2904],
  [-35.1182, 139.2915],
  [-35.1193, 139.2922],
  [-35.1203, 139.2933],
  [-35.1210, 139.2946],
  [-35.1212, 139.2964],
  [-35.1209, 139.2979],
  [-35.1199, 139.2997],
  [-35.1194, 139.3013],
  [-35.1194, 139.3030],
  [-35.1198, 139.3044],
  [-35.1233, 139.3109],
  [-35.1246, 139.3126],
  [-35.1405, 139.3286],
];

const murrayBridgeBordertown = [
  [-35.1405, 139.3286],
  [-35.1515, 139.3395],
  [-35.1590, 139.3503],
  [-35.1690, 139.3614],
  [-35.1707, 139.3648],
  [-35.1714, 139.3689],
  [-35.1733, 139.3730],
  [-35.2053, 139.4078],
  [-35.2195, 139.4233],
  [-35.2568, 139.4581],
  [-35.2691, 139.4695],
  [-35.3105, 139.5042],
  [-35.3496, 139.5371],
  [-35.3669, 139.5495],
  [-35.3763, 139.5572],
  [-35.4272, 139.6151],
  [-35.4450, 139.6393],
  [-35.4639, 139.6689],
  [-35.4653, 139.6732],
  [-35.4657, 139.6838],
  [-35.4667, 139.6883],
  [-35.4997, 139.7476],
  [-35.5027, 139.7511],
  [-35.5217, 139.7684],
  [-35.5242, 139.7695],
  [-35.5368, 139.7714],
  [-35.5413, 139.7730],
  [-35.5845, 139.8046],
  [-35.5875, 139.8056],
  [-35.6005, 139.8056],
  [-35.6028, 139.8062],
  [-35.6466, 139.8264],
  [-35.6725, 139.8366],
  [-35.7297, 139.8840],
  [-35.7476, 139.8991],
  [-35.7623, 139.9086],
  [-35.7812, 139.9225],
  [-35.7832, 139.9246],
  [-35.9368, 140.1245],
  [-35.9388, 140.1283],
  [-35.9420, 140.1401],
  [-35.9427, 140.1470],
  [-35.9438, 140.1678],
  [-35.9447, 140.1723],
  [-35.9631, 140.2328],
  [-35.9658, 140.2373],
  [-36.0668, 140.3241],
  [-36.1349, 140.3940],
  [-36.1523, 140.4277],
  [-36.1539, 140.4325],
  [-36.1551, 140.4404],
  [-36.1563, 140.4438],
  [-36.1698, 140.4748],
  [-36.1709, 140.4790],
  [-36.1721, 140.4928],
  [-36.1733, 140.4981],
  [-36.2023, 140.5775],
  [-36.2918, 140.7490],
  [-36.3105, 140.7776]
];

const bordertownWolsely = [
  [-36.3105, 140.7776],
  [-36.3125, 140.7790],
  [-36.3136, 140.7803],
  [-36.3143, 140.7816],
  [-36.3166, 140.7893],
  [-36.3172, 140.7908],
  [-36.3182, 140.7923],
  [-36.3534, 140.8328],
  [-36.3546, 140.8349],
  [-36.3553, 140.8373],
  [-36.3639, 140.8960],
  [-36.3662, 140.9084]
];

const wolselyVicBorder = [
  [-36.3662, 140.9084],
  [-36.3668, 140.9116],
  [-36.3673, 140.9186],
  [-36.3743, 140.9674]
];

export const wolsely: Line = {
  name: 'Wolsely',
  state: 'SA',
  segments: [
    {
      segments: [
        belairNationalParkTunnel,
        {
          name: 'National Park Tunnel',
          segment: nationalParkTunnel,
          type: 'tunnel'
        },
        nationalParkTunnelLongGullyTunnel,
        {
          name: 'Long Gully Tunnel',
          segment: longGullyTunnel,
          type: 'tunnel'
        },
        longGullyTunnelUpperSturtTunnel,
        {
          name: 'Upper Sturt Tunnel',
          segment: upperSturtTunnel,
          type: 'tunnel'
        },
        upperSturtTunnelAldgate
      ],
      history: {
        opened: {
          date: '1883-03-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [
        aldgateAmblesideTunnel,
        {
          name: 'Yantaringa Tunnel',
          segment: amblesideTunnel,
          type: 'tunnel'
        },
        amblesideTunnelNairne

      ],
      history: {
        opened: {
          date: '1883-11-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [
        nairneMurrayBridge,
        {
          date: '1925-01-01',
          original: [murrayBridgeOld],
          deviation: [
            murrayBridgeNewStart,
            {name: 'Murray Bridge Tunnel', segment: murrayBridgeTunnel, type: 'tunnel'},
            murrayBridgeNew
          ]
        },
        murrayBridgeBordertown
      ],
      history: {
        opened: {
          date: '1886-05-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [bordertownWolsely],
      history: {
        opened: {
          date: '1887-01-19',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1924-01-01',
          gauge: 'broad'
        }, {
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [wolselyVicBorder],
      history: {
        opened: {
          date: '1887-01-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    }
  ]
};
