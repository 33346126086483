import { Line } from "../../trackTypes";

const line = [
  [-28.9312, 121.5165],
  [-28.9302, 121.5169],
  [-28.9293, 121.5178],
  [-28.9288, 121.5192],
  [-28.9286, 121.5324],
  [-28.9339, 121.6114],
  [-28.9345, 121.6145],
  [-28.9657, 121.7018],
  [-28.9746, 121.7350],
  [-28.9746, 121.7381],
  [-28.9663, 121.7666],
  [-28.9646, 121.7690],
  [-28.9530, 121.7745],
  [-28.9229, 121.7950],
  [-28.9213, 121.7966],
  [-28.9091, 121.8201],
  [-28.8880, 121.8411],
  [-28.8382, 121.8947],
  [-28.8247, 121.9390],
  [-28.8219, 121.9481],
  [-28.7681, 122.0493],
  [-28.7678, 122.0514],
  [-28.7681, 122.0523],
  [-28.7784, 122.0627],
  [-28.7781, 122.0642],
  [-28.7790, 122.0732],
  [-28.7786, 122.0754],
  [-28.7778, 122.0769],
  [-28.7744, 122.0805],
  [-28.7735, 122.0823],
  [-28.7215, 122.2575],
  [-28.7159, 122.2675],
  [-28.6980, 122.2887],
  [-28.6964, 122.2916],
  [-28.6845, 122.3465],
  [-28.6650, 122.3876],
  [-28.6530, 122.3994],
  [-28.6508, 122.4006],
  [-28.6351, 122.4072],
  [-28.6332, 122.4076],
  [-28.6260, 122.4079]
];

export const malcomLaverton: Line = {
  name: 'Malcom - Laverton',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
