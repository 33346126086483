import { Line } from "../../../../trackTypes";

const valleyJunctionNorthWestCurve = [
  [-27.45755, 153.03359],
  [-27.45749, 153.03362],
  [-27.45742, 153.03361],
  [-27.45736, 153.03359]
];

const exhibition = [
  [-27.45755, 153.03384],
  [-27.45736, 153.03359],
  [-27.45554, 153.03127],
  [-27.45439, 153.02980],
  [-27.45423, 153.02964],
  [-27.45409, 153.02954],
  [-27.45388, 153.02944],
  [-27.45278, 153.02913],
  [-27.45266, 153.02917],
  [-27.45258, 153.02919],
  [-27.45252, 153.02925],
  [-27.45195, 153.03034],
  [-27.45172, 153.03078],
  [-27.45085, 153.03296]
];

const exhibitionSiding = [
  [-27.45085, 153.03296],
  [-27.44997, 153.03508]
];

const bowenBrige = [
  [-27.45278, 153.02913],
  [-27.45181, 153.02882],
  [-27.45131, 153.02875],
  [-27.45102, 153.02875],
  [-27.45082, 153.02877],
  [-27.44494, 153.02982]
];

const stPaulsTerrace = [
  [-27.46187, 153.02656],
  [-27.46171, 153.02644],
  [-27.46157, 153.02643],
  [-27.46124, 153.02654],
  [-27.46113, 153.02660],
  [-27.46104, 153.02670],
  [-27.45622, 153.03142],
  [-27.45318, 153.03446]
];

const stPaulsTerraceCurve = [
  [-27.46192, 153.02631],
  [-27.46157, 153.02643]
];

const albionRoad = [
  [-27.44494, 153.02982],
  [-27.43275, 153.03197],
  [-27.43220, 153.03197],
  [-27.43154, 153.03189],
  [-27.43116, 153.03184],
  [-27.43022, 153.03190],
  [-27.42997, 153.03197],
  [-27.42979, 153.03209],
  [-27.42947, 153.03242],
  [-27.42927, 153.03256],
  [-27.42887, 153.03266]
];

const wooloowinStateSchool = [
  [-27.42887, 153.03266],
  [-27.42847, 153.03277],
  [-27.42809, 153.03272],
  [-27.42789, 153.03264],
  [-27.42716, 153.03229],
  [-27.42692, 153.03225],
  [-27.42668, 153.03225],
  [-27.42615, 153.03243],
  [-27.42591, 153.03255],
  [-27.42492, 153.03332],
  [-27.42477, 153.03343],
  [-27.42460, 153.03347],
  [-27.42072, 153.03411],
  [-27.42034, 153.03421],
  [-27.42008, 153.03436],
  [-27.41972, 153.03464],
  [-27.41770, 153.03603],
  [-27.41735, 153.03622],
  [-27.41689, 153.03638],
  [-27.41600, 153.03655]
];

const kedronBridge = [
  [-27.41600, 153.03655],
  [-27.41538, 153.03650],
  [-27.41493, 153.03626],
  [-27.41437, 153.03569]
];

const exhibitionLoop = [
  [-27.45085, 153.03296],
  [-27.45086, 153.03310],
  [-27.45092, 153.03322],
  [-27.45242, 153.03503],
  [-27.45252, 153.03507],
  [-27.45262, 153.03503],
  [-27.45318, 153.03446]
];

const lutwyche = [
  [-27.41437, 153.03569],
  [-27.41296, 153.03428],
  [-27.41256, 153.03397],
  [-27.41206, 153.03376],
  [-27.41124, 153.03358],
  [-27.41102, 153.03350],
  [-27.41071, 153.03332],
  [-27.40989, 153.03272],
  [-27.40936, 153.03248],
  [-27.40891, 153.03238],
  [-27.40835, 153.03238],
  [-27.40765, 153.03251],
  [-27.40725, 153.03252],
  [-27.40682, 153.03246],
  [-27.40413, 153.03183]
];

const chermside = [
  [-27.40413, 153.03183],
  [-27.40295, 153.03160],
  [-27.40253, 153.03157],
  [-27.40127, 153.03149],
  [-27.40084, 153.03150],
  [-27.40010, 153.03159],
  [-27.39972, 153.03159],
  [-27.39935, 153.03156],
  [-27.39475, 153.03086],
  [-27.39424, 153.03087],
  [-27.39245, 153.03119],
  [-27.39050, 153.03145],
  [-27.39002, 153.03144],
  [-27.38958, 153.03137],
  [-27.38883, 153.03122],
  [-27.38837, 153.03117],
  [-27.38748, 153.03115],
  [-27.38703, 153.03110],
  [-27.38613, 153.03095]
];

export const east4: Line = {
  name: 'East 4',
  state: 'QLD',
  segments: [
    {
      segments: [valleyJunctionNorthWestCurve],
      history: {
        opened: {
          date: '1927-06-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [exhibition],
      history: {
        opened: {
          date: '1885-08-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1899-02-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bowenBrige],
      history: {
        opened: {
          date: '1899-04-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stPaulsTerrace],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stPaulsTerraceCurve],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1947-07-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [albionRoad],
      history: {
        opened: {
          date: '1914-09-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wooloowinStateSchool],
      history: {
        opened: {
          date: '1914-11-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kedronBridge],
      history: {
        opened: {
          date: '1914-12-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [exhibitionLoop],
      history: {
        opened: {
          date: '1916-05-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lutwyche],
      history: {
        opened: {
          date: '1925-05-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [exhibitionSiding],
      history: {
        opened: {
          date: '1935-07-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chermside],
      history: {
        opened: {
          date: '1947-03-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
