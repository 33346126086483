import { line1 } from "./christchurchTrams/line1";
import { line10 } from "./christchurchTrams/line10";
import { line11 } from "./christchurchTrams/line11";
import { line2 } from "./christchurchTrams/line2";
import { line3 } from "./christchurchTrams/line3";
import { line4 } from "./christchurchTrams/line4";
import { line5 } from "./christchurchTrams/line5";
import { line6 } from "./christchurchTrams/line6";
import { line7 } from "./christchurchTrams/line7";
import { line8 } from "./christchurchTrams/line8";
import { line9 } from "./christchurchTrams/line9";

export const christchurchTrams = [
  line1,
  line2,
  line3,
  line4,
  line5,
  line6,
  line7,
  line8,
  line9,
  line10,
  line11
];
