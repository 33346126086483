import { Line } from "../../../trackTypes";

const strathfieldConcordWest = [
  [-33.8699, 151.0920],
  [-33.8690, 151.0915],
  [-33.8657, 151.0904],
  [-33.8541, 151.0866],
  [-33.8484, 151.0856]
];

const concordWestRhodes = [
  [-33.8484, 151.0856],
  [-33.8430, 151.0852],
  [-33.8360, 151.0858],
  [-33.8305, 151.0870]
];

const rhodesWestRyde = [
  [-33.8305, 151.0870],
  [-33.8166, 151.0899],
  [-33.8149, 151.0901],
  [-33.8127, 151.0900],
  [-33.8093, 151.0897],
  [-33.8078, 151.0899],
  [-33.8073, 151.0901]
];

const westRydeEpping = [
  [-33.8073, 151.0901],
  [-33.8052, 151.0909],
  [-33.8039, 151.0911],
  [-33.8022, 151.0905],
  [-33.8009, 151.0894],
  [-33.8004, 151.0886],
  [-33.7992, 151.0862],
  [-33.7982, 151.0850],
  [-33.7970, 151.0842],
  [-33.7918, 151.0823],
  [-33.7906, 151.0822],
  [-33.7889, 151.0823],
  [-33.7790, 151.0840],
  [-33.7773, 151.0842],
  [-33.7751, 151.0835],
  [-33.7734, 151.0822]
];

const eppingThornleigh = [
  [-33.7734, 151.0822],
  [-33.7716, 151.0816],
  [-33.7695, 151.0814],
  [-33.7679, 151.0818],
  [-33.7656, 151.0828],
  [-33.7644, 151.0829],
  [-33.7636, 151.0827],
  [-33.7583, 151.0809],
  [-33.7567, 151.0799],
  [-33.7558, 151.0789],
  [-33.7551, 151.0774],
  [-33.7547, 151.0757],
  [-33.7537, 151.0688],
  [-33.7533, 151.0674],
  [-33.7526, 151.0665],
  [-33.7517, 151.0660],
  [-33.7506, 151.0659],
  [-33.7497, 151.0663],
  [-33.7488, 151.0667],
  [-33.7478, 151.0667],
  [-33.7469, 151.0664],
  [-33.7457, 151.0655],
  [-33.7447, 151.0652],
  [-33.7437, 151.0653],
  [-33.7426, 151.0661],
  [-33.7420, 151.0670],
  [-33.7415, 151.0685],
  [-33.7405, 151.0700],
  [-33.7288, 151.0810]
];

const thornleighHornsby = [
  [-33.7288, 151.0810],
  [-33.7241, 151.0853],
  [-33.7228, 151.0870],
  [-33.7221, 151.0887],
  [-33.7219, 151.0905],
  [-33.7219, 151.0928],
  [-33.7217, 151.0945],
  [-33.7211, 151.0963],
  [-33.7203, 151.0975],
  [-33.7192, 151.0986],
  [-33.7177, 151.0995],
  [-33.7163, 151.0998],
  [-33.7148, 151.0996],
  [-33.7135, 151.0991],
  [-33.7111, 151.0975],
  [-33.7095, 151.0971],
  [-33.7075, 151.0971],
  [-33.7040, 151.0982],
  [-33.7034, 151.0984]
];

const strathfieldJunction = [
  [-33.8673, 151.0875],
  [-33.8675, 151.0888],
  [-33.8672, 151.0897],
  [-33.8666, 151.0902],
  [-33.8657, 151.0904]
];

const hornsbyBerowra = [
  [-33.7034, 151.0984],
  [-33.6970, 151.1000],
  [-33.6952, 151.1010],
  [-33.6942, 151.1019],
  [-33.6891, 151.1076],
  [-33.6853, 151.1103],
  [-33.6832, 151.1111],
  [-33.6734, 151.1137],
  [-33.6734, 151.1137],
  [-33.6705, 151.1159],
  [-33.6698, 151.1169],
  [-33.6681, 151.1196],
  [-33.6658, 151.1221],
  [-33.6634, 151.1239],
  [-33.6623, 151.1251],
  [-33.6564, 151.1349],
  [-33.6552, 151.1362],
  [-33.6536, 151.1368],
  [-33.6525, 151.1368],
  [-33.6514, 151.1366],
  [-33.6505, 151.1367],
  [-33.6492, 151.1373],
  [-33.6483, 151.1374],
  [-33.6475, 151.1372],
  [-33.6461, 151.1364],
  [-33.6448, 151.1364],
  [-33.6437, 151.1371],
  [-33.6429, 151.1386],
  [-33.6425, 151.1406],
  [-33.6414, 151.1428],
  [-33.6402, 151.1438],
  [-33.6327, 151.1484],
  [-33.6313, 151.1488],
  [-33.6301, 151.1487],
  [-33.6287, 151.1485],
  [-33.6272, 151.1487],
  [-33.6255, 151.1501],
  [-33.6233, 151.1532]
];

export const berowra: Line = {
  name: 'Berowra',
  state: 'NSW',
  segments: [
    {
      segments: [strathfieldJunction],
      history: {
        opened: {
          date: '1886-09-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [strathfieldConcordWest],
      history: {
        opened: {
          date: '1886-09-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1892-01-01',
          tracks: 2
        }, {
          date: '1911-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [concordWestRhodes],
      history: {
        opened: {
          date: '1886-09-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1892-01-01',
          tracks: 2
        }, {
          date: '1912-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [rhodesWestRyde],
      history: {
        opened: {
          date: '1886-09-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1892-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [westRydeEpping],
      history: {
        opened: {
          date: '1886-09-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1892-01-01',
          tracks: 2
        }, {
          date: '1978-10-24',
          tracks: 3
        }, {
          date: '1989-11-27',
          tracks: 4
        }]
      }
    },
    {
      segments: [eppingThornleigh],
      history: {
        opened: {
          date: '1886-09-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1892-01-01',
          tracks: 2
        }, {
          date: '2016-06-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [thornleighHornsby],
      history: {
        opened: {
          date: '1886-09-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1892-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [hornsbyBerowra],
      history: {
        opened: {
          date: '1887-04-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1909-06-27',
          tracks: 2
        }]
      }
    }
  ]
};
