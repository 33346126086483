import { Line } from "../../trackTypes";

const swansonSwansonDeviation = [
  [-36.8661, 174.5761],
  [-36.8659, 174.5748]
];

const swansonOld = [
  [-36.8659, 174.5748],
  [-36.8658, 174.5729],
  [-36.8651, 174.5690],
  [-36.8652, 174.5681],
  [-36.8652, 174.5665],
  [-36.8650, 174.5650],
  [-36.8645, 174.5644],
  [-36.8634, 174.5636],
  [-36.8629, 174.5629],
  [-36.8622, 174.5610],
  [-36.8621, 174.5601],
  [-36.8620, 174.5571],
  [-36.8619, 174.5564],
  [-36.8618, 174.5561]
];

const swansonDeviation = [
  [-36.8659, 174.5748],
  [-36.8660, 174.5741],
  [-36.8662, 174.5733],
  [-36.8667, 174.5725],
  [-36.8679, 174.5712],
  [-36.8684, 174.5701],
  [-36.8686, 174.5693],
  [-36.8685, 174.5681],
  [-36.8681, 174.5670],
  [-36.8675, 174.5662],
  [-36.8649, 174.5642],
  [-36.8642, 174.5634],
  [-36.8637, 174.5625],
  [-36.8622, 174.5569],
  [-36.8618, 174.5561]
];

const waitakereTunnel = [
  [-36.8618, 174.5561],
  [-36.8604, 174.5539]
];

const waitakereTunnelHelensville = [
  [-36.8604, 174.5539],
  [-36.8599, 174.5531],
  [-36.8590, 174.5507],
  [-36.8585, 174.5497],
  [-36.8577, 174.5487],
  [-36.8574, 174.5480],
  [-36.8570, 174.5471],
  [-36.8567, 174.5464],
  [-36.8558, 174.5451],
  [-36.8551, 174.5442],
  [-36.8541, 174.5435],
  [-36.8531, 174.5433],
  [-36.8515, 174.5432],
  [-36.8507, 174.5433],
  [-36.8477, 174.5441],
  [-36.8469, 174.5444],
  [-36.8393, 174.5482],
  [-36.8368, 174.5490],
  [-36.8359, 174.5491],
  [-36.8351, 174.5490],
  [-36.8344, 174.5490],
  [-36.8320, 174.5493],
  [-36.8303, 174.5497],
  [-36.8291, 174.5502],
  [-36.8249, 174.5524],
  [-36.8197, 174.5551],
  [-36.8077, 174.5582],
  [-36.8064, 174.5584],
  [-36.8042, 174.5583],
  [-36.7805, 174.5608],
  [-36.7798, 174.5608],
  [-36.7789, 174.5603],
  [-36.7780, 174.5593],
  [-36.7750, 174.5532],
  [-36.7739, 174.5500],
  [-36.7732, 174.5475],
  [-36.7725, 174.5427],
  [-36.7725, 174.5416],
  [-36.7725, 174.5406],
  [-36.7724, 174.5397],
  [-36.7721, 174.5387],
  [-36.7713, 174.5374],
  [-36.7709, 174.5363],
  [-36.7708, 174.5355],
  [-36.7705, 174.5347],
  [-36.7699, 174.5332],
  [-36.7690, 174.5306],
  [-36.7678, 174.5245],
  [-36.7675, 174.5237],
  [-36.7670, 174.5227],
  [-36.7667, 174.5217],
  [-36.7662, 174.5174],
  [-36.7660, 174.5064],
  [-36.7663, 174.5012],
  [-36.7662, 174.4970],
  [-36.7663, 174.4963],
  [-36.7664, 174.4955],
  [-36.7664, 174.4946],
  [-36.7661, 174.4939],
  [-36.7655, 174.4933],
  [-36.7610, 174.4912],
  [-36.7583, 174.4896],
  [-36.7575, 174.4888],
  [-36.7571, 174.4876],
  [-36.7570, 174.4829],
  [-36.7571, 174.4820],
  [-36.7583, 174.4798],
  [-36.7585, 174.4792],
  [-36.7586, 174.4783],
  [-36.7581, 174.4758],
  [-36.7580, 174.4744],
  [-36.7578, 174.4738],
  [-36.7565, 174.4713],
  [-36.7546, 174.4696],
  [-36.7527, 174.4674],
  [-36.7523, 174.4665],
  [-36.7523, 174.4657],
  [-36.7525, 174.4658],
  [-36.7547, 174.4604],
  [-36.7553, 174.4587],
  [-36.7566, 174.4512],
  [-36.7566, 174.4503],
  [-36.7563, 174.4496],
  [-36.7558, 174.4490],
  [-36.7548, 174.4483],
  [-36.7538, 174.4479],
  [-36.7532, 174.4475],
  [-36.7521, 174.4459],
  [-36.7518, 174.4449],
  [-36.7516, 174.4438],
  [-36.7512, 174.4430],
  [-36.7484, 174.4396],
  [-36.7473, 174.4389],
  [-36.7461, 174.4387],
  [-36.7448, 174.4391],
  [-36.7394, 174.4432],
  [-36.7387, 174.4434],
  [-36.7374, 174.4432],
  [-36.7361, 174.4424],
  [-36.7237, 174.4371],
  [-36.7142, 174.4360],
  [-36.7129, 174.4361],
  [-36.7048, 174.4377],
  [-36.7004, 174.4389],
  [-36.6989, 174.4399],
  [-36.6904, 174.4490],
  [-36.6889, 174.4499],
  [-36.6876, 174.4501],
  [-36.6840, 174.4499],
  [-36.6833, 174.4497],
  [-36.6813, 174.4485],
  [-36.6807, 174.4483],
  [-36.6798, 174.4484],
  [-36.6792, 174.4487],
  [-36.6784, 174.4493],
  [-36.6771, 174.4498],
  [-36.6740, 174.4500],
  [-36.6726, 174.4502],
  [-36.6717, 174.4504]
];

const helensvilleKanohi = [
  [-36.6717, 174.4504],
  [-36.6692, 174.4509],
  [-36.6639, 174.4501],
  [-36.6619, 174.4494],
  [-36.6604, 174.4492],
  [-36.6564, 174.4492],
  [-36.6554, 174.4490],
  [-36.6527, 174.4466],
  [-36.6514, 174.4460],
  [-36.6482, 174.4456],
  [-36.6467, 174.4460],
  [-36.6455, 174.4472],
  [-36.6448, 174.4489],
  [-36.6374, 174.4786],
  [-36.6354, 174.4826],
  [-36.6345, 174.4835],
  [-36.6254, 174.4880],
  [-36.6237, 174.4883],
  [-36.6161, 174.4931],
  [-36.6145, 174.4934],
  [-36.6038, 174.4901],
  [-36.6021, 174.4901],
  [-36.6004, 174.4908],
  [-36.5959, 174.4937],
  [-36.5949, 174.4950],
  [-36.5943, 174.4968],
  [-36.5937, 174.5036]
];

const kanohiMakarauTunnel = [
  [-36.5937, 174.5036],
  [-36.5932, 174.5096],
  [-36.5929, 174.5104],
  [-36.5926, 174.5110],
  [-36.5919, 174.5119],
  [-36.5914, 174.5128],
  [-36.5902, 174.5164],
  [-36.5896, 174.5172],
  [-36.5889, 174.5176],
  [-36.5852, 174.5179]
];

const makarauTunnel = [
  [-36.5852, 174.5179],
  [-36.5800, 174.5187]
];

const makarauTunnelMakarau = [
  [-36.5800, 174.5187],
  [-36.5773, 174.5192],
  [-36.5765, 174.5191],
  [-36.5757, 174.5189],
  [-36.5738, 174.5186],
  [-36.5713, 174.5183],
  [-36.5707, 174.5184],
  [-36.5697, 174.5189],
  [-36.5691, 174.5190],
  [-36.5684, 174.5188],
  [-36.5680, 174.5185],
  [-36.5672, 174.5184],
  [-36.5656, 174.5186],
  [-36.5614, 174.5198],
  [-36.5591, 174.5206],
  [-36.5585, 174.5211],
  [-36.5584, 174.5212]
];

const makarauTahekeroa = [
  [-36.5584, 174.5212],
  [-36.5577, 174.5222],
  [-36.5575, 174.5230],
  [-36.5569, 174.5239],
  [-36.5563, 174.5244],
  [-36.5558, 174.5252],
  [-36.5547, 174.5289],
  [-36.5541, 174.5298],
  [-36.5530, 174.5305],
  [-36.5524, 174.5313],
  [-36.5521, 174.5324],
  [-36.5515, 174.5332],
  [-36.5480, 174.5355],
  [-36.5469, 174.5368],
  [-36.5464, 174.5371],
  [-36.5457, 174.5373],
  [-36.5452, 174.5377],
  [-36.5446, 174.5384],
  [-36.5439, 174.5388],
  [-36.5432, 174.5389],
  [-36.5428, 174.5391],
  [-36.5421, 174.5397],
  [-36.5415, 174.5399],
  [-36.5405, 174.5400],
  [-36.5398, 174.5403],
  [-36.5393, 174.5411],
  [-36.5387, 174.5426],
  [-36.5382, 174.5433],
  [-36.5374, 174.5439],
  [-36.5368, 174.5445],
  [-36.5360, 174.5458],
  [-36.5354, 174.5463],
  [-36.5345, 174.5468],
  [-36.5340, 174.5475],
  [-36.5336, 174.5487],
  [-36.5337, 174.5503],
  [-36.5337, 174.5511],
  [-36.5334, 174.5519],
  [-36.5329, 174.5526],
  [-36.5300, 174.5604]
];

const tahekeroaTahekeroaTunnel = [
  [-36.5300, 174.5604],
  [-36.5296, 174.5616],
  [-36.5290, 174.5625],
  [-36.5284, 174.5630],
  [-36.5258, 174.5642],
  [-36.5238, 174.5659],
  [-36.5229, 174.5665],
  [-36.5223, 174.5666],
  [-36.5216, 174.5666],
  [-36.5209, 174.5670],
  [-36.5204, 174.5675],
  [-36.5200, 174.5685],
  [-36.5199, 174.5694],
  [-36.5201, 174.5704],
  [-36.5207, 174.5712],
  [-36.5210, 174.5721],
  [-36.5210, 174.5729],
  [-36.5206, 174.5763],
  [-36.5203, 174.5772],
  [-36.5196, 174.5780],
  [-36.5189, 174.5782],
  [-36.5183, 174.5781],
  [-36.5175, 174.5778],
  [-36.5171, 174.5778],
  [-36.5161, 174.5780],
  [-36.5156, 174.5780],
  [-36.5152, 174.5778],
  [-36.5143, 174.5772],
  [-36.5138, 174.5770],
  [-36.5132, 174.5769]
];

const tahekeroaTunnel = [
  [-36.5132, 174.5769],
  [-36.5091, 174.5763]
];

const tahekeroaTunnelAhuroaTunnel = [
  [-36.5091, 174.5763],
  [-36.5079, 174.5761],
  [-36.5070, 174.5764],
  [-36.5060, 174.5762],
  [-36.5054, 174.5759],
  [-36.5045, 174.5751],
  [-36.5032, 174.5747],
  [-36.5025, 174.5743],
  [-36.5014, 174.5729],
  [-36.5009, 174.5712],
  [-36.5009, 174.5694],
  [-36.5006, 174.5678],
  [-36.4999, 174.5666],
  [-36.4963, 174.5632],
  [-36.4941, 174.5621],
  [-36.4932, 174.5620],
  [-36.4908, 174.5624],
  [-36.4898, 174.5623],
  [-36.4867, 174.5605],
  [-36.4825, 174.5588],
  [-36.4819, 174.5584],
  [-36.4803, 174.5568],
  [-36.4772, 174.5551],
  [-36.4764, 174.5551],
  [-36.4754, 174.5554],
  [-36.4747, 174.5553],
  [-36.4739, 174.5548],
  [-36.4729, 174.5546],
  [-36.4719, 174.5550],
  [-36.4707, 174.5564],
  [-36.4702, 174.5575],
  [-36.4691, 174.5623],
  [-36.4685, 174.5633],
  [-36.4678, 174.5637],
  [-36.4670, 174.5637]
];

const ahuroaTunnel = [
  [-36.4670, 174.5637],
  [-36.4634, 174.5635]
];

const ahuroaTunnelHoteoTunnel = [
  [-36.4634, 174.5635],
  [-36.4599, 174.5623],
  [-36.4586, 174.5627],
  [-36.4577, 174.5636],
  [-36.4571, 174.5646],
  [-36.4564, 174.5652],
  [-36.4505, 174.5677],
  [-36.4486, 174.5678],
  [-36.4469, 174.5674],
  [-36.4458, 174.5669],
  [-36.4287, 174.5520],
  [-36.4274, 174.5513],
  [-36.4193, 174.5499],
  [-36.4091, 174.5472],
  [-36.4077, 174.5465],
  [-36.4028, 174.5417],
  [-36.4020, 174.5402],
  [-36.4006, 174.5363],
  [-36.3996, 174.5346],
  [-36.3952, 174.5314],
  [-36.3943, 174.5306],
  [-36.3934, 174.5286],
  [-36.3927, 174.5279],
  [-36.3919, 174.5278],
  [-36.3901, 174.5280],
  [-36.3894, 174.5279],
  [-36.3889, 174.5274],
  [-36.3878, 174.5259],
  [-36.3875, 174.5251],
  [-36.3871, 174.5230],
  [-36.3867, 174.5220],
  [-36.3857, 174.5201],
  [-36.3851, 174.5194],
  [-36.3844, 174.5192],
  [-36.3835, 174.5194],
  [-36.3819, 174.5204],
  [-36.3814, 174.5209],
  [-36.3801, 174.5231],
  [-36.3795, 174.5237],
  [-36.3785, 174.5244]
];

const hoteoTunnel = [
  [-36.3785, 174.5244],
  [-36.3744, 174.5272]
];

const hoteoTunnelWellsford = [
  [-36.3744, 174.5272],
  [-36.3738, 174.5277],
  [-36.3728, 174.5287],
  [-36.3722, 174.5289],
  [-36.3716, 174.5287],
  [-36.3708, 174.5283],
  [-36.3688, 174.5280],
  [-36.3681, 174.5285],
  [-36.3677, 174.5293],
  [-36.3673, 174.5313],
  [-36.3663, 174.5325],
  [-36.3622, 174.5335],
  [-36.3615, 174.5333],
  [-36.3607, 174.5327],
  [-36.3586, 174.5296],
  [-36.3577, 174.5292],
  [-36.3566, 174.5294],
  [-36.3558, 174.5301],
  [-36.3549, 174.5303],
  [-36.3540, 174.5299],
  [-36.3531, 174.5290],
  [-36.3524, 174.5285],
  [-36.3500, 174.5279],
  [-36.3494, 174.5279],
  [-36.3451, 174.5285],
  [-36.3441, 174.5290],
  [-36.3433, 174.5291],
  [-36.3411, 174.5286],
  [-36.3405, 174.5285],
  [-36.3324, 174.5297],
  [-36.3281, 174.5309],
  [-36.3189, 174.5311],
  [-36.3180, 174.5308],
  [-36.3175, 174.5304],
  [-36.3167, 174.5293],
  [-36.3159, 174.5288],
  [-36.3150, 174.5288],
  [-36.3140, 174.5295],
  [-36.3129, 174.5299],
  [-36.3119, 174.5296],
  [-36.3112, 174.5288],
  [-36.3101, 174.5270],
  [-36.3093, 174.5265],
  [-36.3085, 174.5265],
  [-36.3073, 174.5270],
  [-36.3065, 174.5271],
  [-36.3052, 174.5269],
  [-36.3015, 174.5261],
  [-36.2965, 174.5242],
  [-36.2957, 174.5243],
  [-36.2950, 174.5247],
  [-36.2939, 174.5261]
];

const wellsfordTeHana = [
  [-36.2939, 174.5261],
  [-36.2928, 174.5273],
  [-36.2919, 174.5277],
  [-36.2908, 174.5276],
  [-36.2900, 174.5280],
  [-36.2895, 174.5286],
  [-36.2890, 174.5296],
  [-36.2881, 174.5305],
  [-36.2867, 174.5308],
  [-36.2854, 174.5300],
  [-36.2841, 174.5283],
  [-36.2829, 174.5277],
  [-36.2817, 174.5279],
  [-36.2804, 174.5287],
  [-36.2790, 174.5289],
  [-36.2768, 174.5280],
  [-36.2738, 174.5266],
  [-36.2733, 174.5245],
  [-36.2722, 174.5218],
  [-36.2714, 174.5183],
  [-36.2706, 174.5166],
  [-36.2692, 174.5151],
  [-36.2666, 174.5134],
  [-36.2657, 174.5125],
  [-36.2647, 174.5112],
  [-36.2632, 174.5102],
  [-36.2620, 174.5098],
  [-36.2570, 174.5101]
];

const teHanaTopuniTunnel = [
  [-36.2570, 174.5101],
  [-36.2541, 174.5102],
  [-36.2491, 174.5072],
  [-36.2479, 174.5069],
  [-36.2460, 174.5071],
  [-36.2452, 174.5068],
  [-36.2447, 174.5061],
  [-36.2444, 174.5052],
  [-36.2441, 174.5012],
  [-36.2436, 174.5000],
  [-36.2429, 174.4992],
  [-36.2394, 174.4977],
  [-36.2384, 174.4968],
  [-36.2379, 174.4959],
  [-36.2370, 174.4919],
  [-36.2370, 174.4909],
  [-36.2373, 174.4891],
  [-36.2372, 174.4880],
  [-36.2368, 174.4873],
  [-36.2361, 174.4869]
];

const topuniTunnel = [
  [-36.2361, 174.4869],
  [-36.2333, 174.4853]
];

const topuniTunnelRosHillTunnel = [
  [-36.2333, 174.4853],
  [-36.2324, 174.4848],
  [-36.2312, 174.4836],
  [-36.2235, 174.4806],
  [-36.2225, 174.4796],
  [-36.2217, 174.4778],
  [-36.2210, 174.4770],
  [-36.2195, 174.4760],
  [-36.2178, 174.4736],
  [-36.2139, 174.4704],
  [-36.2134, 174.4694],
  [-36.2132, 174.4685],
  [-36.2131, 174.4644],
  [-36.2126, 174.4633],
  [-36.2117, 174.4627],
  [-36.2086, 174.4631],
  [-36.2063, 174.4636],
  [-36.2044, 174.4634],
  [-36.2029, 174.4628],
  [-36.2016, 174.4628],
  [-36.2004, 174.4633],
  [-36.1993, 174.4631],
  [-36.1955, 174.4598],
  [-36.1945, 174.4582],
  [-36.1933, 174.4547],
  [-36.1932, 174.4535],
  [-36.1928, 174.4526],
  [-36.1923, 174.4521],
  [-36.1918, 174.4517],
  [-36.1913, 174.4511],
  [-36.1902, 174.4491],
  [-36.1897, 174.4486],
  [-36.1888, 174.4478]
];

const rossHillTunnel = [
  [-36.1888, 174.4478],
  [-36.1861, 174.4460]
];

const rossHillTunnelKaiwaka = [
  [-36.1861, 174.4460],
  [-36.1859, 174.4458],
  [-36.1855, 174.4452],
  [-36.1849, 174.4438],
  [-36.1842, 174.4430],
  [-36.1828, 174.4421],
  [-36.1807, 174.4397],
  [-36.1801, 174.4387],
  [-36.1767, 174.4364],
  [-36.1763, 174.4356],
  [-36.1760, 174.4345],
  [-36.1754, 174.4336],
  [-36.1746, 174.4334],
  [-36.1739, 174.4337],
  [-36.1732, 174.4342],
  [-36.1722, 174.4344],
  [-36.1711, 174.4340],
  [-36.1691, 174.4317],
  [-36.1641, 174.4279]
];

const kaiwakaRaganuiTunnel = [
  [-36.1641, 174.4279],
  [-36.1536, 174.4200],
  [-36.1526, 174.4187],
  [-36.1497, 174.4117],
  [-36.1496, 174.4103],
  [-36.1499, 174.4093],
  [-36.1505, 174.4083],
  [-36.1509, 174.4070],
  [-36.1507, 174.4060],
  [-36.1503, 174.4048],
  [-36.1502, 174.4038],
  [-36.1504, 174.4029],
  [-36.1509, 174.4019],
  [-36.1509, 174.4008],
  [-36.1505, 174.3999],
  [-36.1493, 174.3989],
  [-36.1484, 174.3976],
  [-36.1479, 174.3959],
  [-36.1478, 174.3944],
  [-36.1480, 174.3915],
  [-36.1476, 174.3877],
  [-36.1471, 174.3863],
  [-36.1462, 174.3851],
  [-36.1448, 174.3841],
  [-36.1435, 174.3837],
  [-36.1419, 174.3825],
  [-36.1411, 174.3823],
  [-36.1399, 174.3826],
  [-36.1390, 174.3824],
  [-36.1384, 174.3817],
  [-36.1381, 174.3807],
  [-36.1378, 174.3787],
  [-36.1375, 174.3779],
  [-36.1368, 174.3771],
  [-36.1365, 174.3762],
  [-36.1365, 174.3745],
  [-36.1367, 174.3737],
  [-36.1379, 174.3702],
  [-36.1380, 174.3681],
  [-36.1375, 174.3619],
  [-36.1369, 174.3602]
];

const raganuiTunnel = [
  [-36.1369, 174.3602],
  [-36.1358, 174.3586]
];

const raganuiTunnelBickerstaffeTunnel = [
  [-36.1358, 174.3586],
  [-36.1322, 174.3549],
  [-36.1301, 174.3540],
  [-36.1293, 174.3532],
  [-36.1287, 174.3517],
  [-36.1283, 174.3485]
];

const bickerstaffeTunnel = [
  [-36.1283, 174.3485],
  [-36.1276, 174.3432]
];

const bickerstaffeTunnelHuarau = [
  [-36.1276, 174.3432],
  [-36.1272, 174.3408],
  [-36.1263, 174.3379],
  [-36.1256, 174.3369],
  [-36.1244, 174.3359],
  [-36.1237, 174.3350],
  [-36.1226, 174.3310],
  [-36.1209, 174.3220],
  [-36.1209, 174.3169],
  [-36.1217, 174.3132],
  [-36.1216, 174.3112],
  [-36.1212, 174.3099],
  [-36.1193, 174.3066],
  [-36.1188, 174.3053],
  [-36.1182, 174.3030],
  [-36.1173, 174.3017],
  [-36.1158, 174.3011],
  [-36.1149, 174.3004],
  [-36.1125, 174.2976],
  [-36.1107, 174.2965]
];

const huarauHuarauTunnel = [
  [-36.1107, 174.2965],
  [-36.1075, 174.2947],
  [-36.1051, 174.2947],
  [-36.1029, 174.2967],
  [-36.1008, 174.3026],
  [-36.0997, 174.3038],
  [-36.0989, 174.3040]
];

const huarauTunnel = [
  [-36.0989, 174.3040],
  [-36.0957, 174.3033]
];

const huarauTunnelGoldenStairsTunnel = [
  [-36.0957, 174.3033],
  [-36.0951, 174.3027],
  [-36.0922, 174.2992],
  [-36.0913, 174.2989],
  [-36.0903, 174.2994],
  [-36.0887, 174.3010],
  [-36.0873, 174.3015],
  [-36.0842, 174.3041],
  [-36.0807, 174.3060],
  [-36.0801, 174.3060],
  [-36.0795, 174.3056],
  [-36.0791, 174.3048],
  [-36.0785, 174.3018],
  [-36.0781, 174.3008],
  [-36.0760, 174.2988],
  [-36.0732, 174.2970],
  [-36.0727, 174.2969]
];

const goldenStairsTunnel = [
  [-36.0727, 174.2969],
  [-36.0720, 174.2968],
  [-36.0672, 174.2977]
];

const goldenStairsTunnelMareretuTunnel = [
  [-36.0672, 174.2977],
  [-36.0633, 174.2975],
  [-36.0626, 174.2973],
  [-36.0594, 174.2956],
  [-36.0580, 174.2945],
  [-36.0566, 174.2939],
  [-36.0555, 174.2941],
  [-36.0525, 174.2945],
  [-36.0516, 174.2944],
  [-36.0504, 174.2938],
  [-36.0480, 174.2916],
  [-36.0454, 174.2885],
  [-36.0392, 174.2827],
  [-36.0361, 174.2791],
  [-36.0280, 174.2749],
  [-36.0198, 174.2738],
  [-36.0184, 174.2733],
  [-36.0167, 174.2720],
  [-36.0154, 174.2717],
  [-36.0134, 174.2723],
  [-36.0121, 174.2721],
  [-36.0112, 174.2710],
  [-36.0105, 174.2694],
  [-36.0096, 174.2685],
  [-36.0087, 174.2682],
  [-35.9970, 174.2692],
  [-35.9958, 174.2685],
  [-35.9952, 174.2671],
  [-35.9946, 174.2591],
  [-35.9937, 174.2568],
  [-35.9918, 174.2552],
  [-35.9889, 174.2543],
  [-35.9874, 174.2534],
  [-35.9864, 174.2524],
  [-35.9855, 174.2520],
  [-35.9846, 174.2520],
  [-35.9825, 174.2528]
];

const mareretuTunnel = [
  [-35.9825, 174.2528],
  [-35.9795, 174.2528]
];

const mareretuTunnelWaikiekieTunnel = [
  [-35.9795, 174.2528],
  [-35.9794, 174.2528],
  [-35.9784, 174.2530],
  [-35.9775, 174.2535],
  [-35.9753, 174.2535],
  [-35.9729, 174.2523],
  [-35.9711, 174.2502],
  [-35.9649, 174.2446],
  [-35.9643, 174.2435],
  [-35.9640, 174.2421],
  [-35.9642, 174.2392],
  [-35.9639, 174.2380],
  [-35.9631, 174.2372],
  [-35.9557, 174.2338],
  [-35.9547, 174.2327],
  [-35.9537, 174.2308],
  [-35.9525, 174.2298],
  [-35.9506, 174.2295],
  [-35.9496, 174.2288],
  [-35.9494, 174.2285]
];

const waikiekieTunnel = [
  [-35.9494, 174.2285],
  [-35.9479, 174.2264]
];

const waikiekieTunnelPortland = [
  [-35.9479, 174.2264],
  [-35.9473, 174.2260],
  [-35.9452, 174.2247],
  [-35.9444, 174.2236],
  [-35.9430, 174.2185],
  [-35.9422, 174.2169],
  [-35.9406, 174.2149],
  [-35.9376, 174.2082],
  [-35.9373, 174.2069],
  [-35.9374, 174.2054],
  [-35.9369, 174.2034],
  [-35.9336, 174.1985],
  [-35.9323, 174.1977],
  [-35.9313, 174.1977],
  [-35.9294, 174.1990],
  [-35.9280, 174.1996],
  [-35.9264, 174.1998],
  [-35.9249, 174.2004],
  [-35.9218, 174.2025],
  [-35.9203, 174.2033],
  [-35.9184, 174.2035],
  [-35.9171, 174.2031],
  [-35.9155, 174.2024],
  [-35.9138, 174.2022],
  [-35.9123, 174.2030],
  [-35.9105, 174.2041],
  [-35.9094, 174.2055],
  [-35.9088, 174.2069],
  [-35.9078, 174.2081],
  [-35.9043, 174.2106],
  [-35.9031, 174.2111],
  [-35.9023, 174.2119],
  [-35.9013, 174.2134],
  [-35.8991, 174.2153],
  [-35.8984, 174.2162],
  [-35.8975, 174.2180],
  [-35.8970, 174.2185],
  [-35.8956, 174.2193],
  [-35.8951, 174.2199],
  [-35.8947, 174.2208],
  [-35.8943, 174.2239],
  [-35.8936, 174.2253],
  [-35.8927, 174.2258],
  [-35.8906, 174.2262],
  [-35.8889, 174.2268],
  [-35.8867, 174.2268],
  [-35.8850, 174.2263],
  [-35.8839, 174.2254],
  [-35.8825, 174.2237],
  [-35.8812, 174.2233],
  [-35.8802, 174.2239],
  [-35.8794, 174.2247],
  [-35.8786, 174.2252],
  [-35.8777, 174.2251],
  [-35.8770, 174.2246],
  [-35.8757, 174.2246],
  [-35.8699, 174.2285],
  [-35.8684, 174.2288],
  [-35.8665, 174.2283],
  [-35.8654, 174.2286],
  [-35.8647, 174.2293],
  [-35.8641, 174.2306],
  [-35.8640, 174.2319],
  [-35.8648, 174.2377],
  [-35.8655, 174.2401],
  [-35.8655, 174.2423],
  [-35.8649, 174.2436],
  [-35.8626, 174.2462],
  [-35.8612, 174.2468],
  [-35.8600, 174.2467],
  [-35.8587, 174.2458],
  [-35.8576, 174.2457],
  [-35.8568, 174.2462],
  [-35.8561, 174.2474],
  [-35.8552, 174.2509],
  [-35.8550, 174.2555],
  [-35.8544, 174.2578],
  [-35.8534, 174.2601],
  [-35.8527, 174.2611],
  [-35.8500, 174.2637],
  [-35.8489, 174.2661],
  [-35.8483, 174.2704],
  [-35.8451, 174.2784],
  [-35.8450, 174.2800],
  [-35.8457, 174.2813],
  [-35.8488, 174.2838],
  [-35.8494, 174.2850],
  [-35.8494, 174.2864],
  [-35.8472, 174.2914],
  [-35.8463, 174.2921],
  [-35.8454, 174.2922],
  [-35.8444, 174.2916],
  [-35.8437, 174.2905],
  [-35.8423, 174.2895],
  [-35.8410, 174.2895],
  [-35.8398, 174.2903],
  [-35.8349, 174.2966],
  [-35.8344, 174.2978],
  [-35.8344, 174.2988],
  [-35.8350, 174.3040],
  [-35.8347, 174.3094],
  [-35.8344, 174.3108],
  [-35.8333, 174.3137],
  [-35.8319, 174.3153],
  [-35.8278, 174.3169],
  [-35.8249, 174.3170],
  [-35.8233, 174.3175],
  [-35.8223, 174.3175],
  [-35.8217, 174.3170],
  [-35.8210, 174.3163],
  [-35.8199, 174.3160],
  [-35.8187, 174.3165],
  [-35.8175, 174.3178],
  [-35.8163, 174.3184],
  [-35.8150, 174.3183],
  [-35.8140, 174.3178],
  [-35.8131, 174.3178],
  [-35.8120, 174.3182],
  [-35.8109, 174.3179],
  [-35.8099, 174.3170],
  [-35.8088, 174.3168],
  [-35.8079, 174.3172],
  [-35.8073, 174.3181],
  [-35.8069, 174.3202],
  [-35.8062, 174.3212],
  [-35.8054, 174.3216],
  [-35.8034, 174.3219],
  [-35.8023, 174.3226],
  [-35.8015, 174.3243],
  [-35.8013, 174.3258],
  [-35.8015, 174.3267],
  [-35.8018, 174.3274]
];

const portlandWhangarei = [
  [-35.8018, 174.3274],
  [-35.8026, 174.3284],
  [-35.8032, 174.3298],
  [-35.8031, 174.3309],
  [-35.8025, 174.3318],
  [-35.8014, 174.3325],
  [-35.8006, 174.3327],
  [-35.7973, 174.3320],
  [-35.7962, 174.3321],
  [-35.7952, 174.3327],
  [-35.7919, 174.3353],
  [-35.7910, 174.3355],
  [-35.7901, 174.3351],
  [-35.7895, 174.3343],
  [-35.7892, 174.3331],
  [-35.7886, 174.3321],
  [-35.7816, 174.3268],
  [-35.7804, 174.3263],
  [-35.7793, 174.3262],
  [-35.7604, 174.3279],
  [-35.7591, 174.3285],
  [-35.7543, 174.3349],
  [-35.7534, 174.3357],
  [-35.7462, 174.3390],
  [-35.7444, 174.3393],
  [-35.7422, 174.3386],
  [-35.7409, 174.3376],
  [-35.7400, 174.3363],
  [-35.7317, 174.3238]
];

const whangareiKamo = [
  [-35.7317, 174.3238],
  [-35.7290, 174.3204],
  [-35.7285, 174.3199],
  [-35.7266, 174.3188],
  [-35.7256, 174.3187],
  [-35.7236, 174.3188],
  [-35.7226, 174.3184],
  [-35.7219, 174.3185],
  [-35.7213, 174.3188],
  [-35.7208, 174.3192],
  [-35.7204, 174.3194],
  [-35.7198, 174.3195],
  [-35.7190, 174.3193],
  [-35.7181, 174.3195],
  [-35.7169, 174.3203],
  [-35.7160, 174.3206],
  [-35.7153, 174.3206],
  [-35.7041, 174.3173],
  [-35.7029, 174.3172],
  [-35.7019, 174.3176],
  [-35.7007, 174.3186],
  [-35.6998, 174.3190],
  [-35.6991, 174.3189],
  [-35.6981, 174.3184],
  [-35.6976, 174.3177],
  [-35.6974, 174.3170],
  [-35.6971, 174.3157],
  [-35.6966, 174.3144],
  [-35.6956, 174.3133],
  [-35.6933, 174.3123],
  [-35.6921, 174.3116],
  [-35.6908, 174.3108],
  [-35.6903, 174.3107],
  [-35.6896, 174.3108],
  [-35.6884, 174.3110],
  [-35.6877, 174.3107],
  [-35.6873, 174.3101],
  [-35.6869, 174.3094],
  [-35.6863, 174.3087],
  [-35.6854, 174.3080],
  [-35.6848, 174.3074],
  [-35.6844, 174.3068],
  [-35.6835, 174.3060],
  [-35.6816, 174.3055],
  [-35.6808, 174.3051]
];

const kamoWaro = [
  [-35.6808, 174.3051],
  [-35.6791, 174.3043],
  [-35.6783, 174.3041],
  [-35.6776, 174.3042],
  [-35.6767, 174.3045],
  [-35.6758, 174.3045],
  [-35.6751, 174.3039],
  [-35.6746, 174.3028],
  [-35.6745, 174.3016],
  [-35.6749, 174.3005],
  [-35.6768, 174.2965],
  [-35.6780, 174.2949],
  [-35.6783, 174.2935],
  [-35.6781, 174.2927],
  [-35.6775, 174.2919],
  [-35.6755, 174.2905],
  [-35.6747, 174.2904],
  [-35.6738, 174.2906],
  [-35.6731, 174.2905],
  [-35.6718, 174.2895],
  [-35.6711, 174.2885],
  [-35.6705, 174.2872],
  [-35.6699, 174.2865],
  [-35.6692, 174.2862],
  [-35.6683, 174.2864],
  [-35.6671, 174.2873],
  [-35.6666, 174.2874],
  [-35.6656, 174.2873],
  [-35.6649, 174.2874],
  [-35.6624, 174.2889],
  [-35.6619, 174.2894],
  [-35.6613, 174.2902],
  [-35.6606, 174.2906],
  [-35.6598, 174.2906],
  [-35.6588, 174.2900],
  [-35.6579, 174.2899],
  [-35.6572, 174.2904],
  [-35.6564, 174.2919],
  [-35.6558, 174.2926],
  [-35.6531, 174.2942],
  [-35.6521, 174.2946],
  [-35.6511, 174.2945],
  [-35.6498, 174.2943],
  [-35.6487, 174.2946],
  [-35.6481, 174.2952],
  [-35.6473, 174.2972],
  [-35.6465, 174.2981],
  [-35.6456, 174.2984],
  [-35.6398, 174.2987],
  [-35.6389, 174.2990],
  [-35.6384, 174.2997],
  [-35.6380, 174.3004],
  [-35.6373, 174.3011],
  [-35.6365, 174.3013],
  [-35.6355, 174.3014],
  [-35.6350, 174.3016],
  [-35.6344, 174.3022],
  [-35.6332, 174.3037],
  [-35.6324, 174.3043],
  [-35.6315, 174.3044],
  [-35.6305, 174.3036],
  [-35.6297, 174.3020],
  [-35.6287, 174.3009],
  [-35.6278, 174.3004],
  [-35.6273, 174.3000],
  [-35.6222, 174.2914],
  [-35.6212, 174.2905],
  [-35.6192, 174.2898],
  [-35.6148, 174.2879],
  [-35.6140, 174.2878],
  [-35.6066, 174.2875],
  [-35.6037, 174.2878],
  [-35.6003, 174.2887],
  [-35.5976, 174.2889],
  [-35.5966, 174.2887],
  [-35.5915, 174.2862],
  [-35.5905, 174.2860],
  [-35.5895, 174.2863],
  [-35.5887, 174.2872]
];

const waroWaiotu = [
  [-35.5887, 174.2872],
  [-35.5847, 174.2915],
  [-35.5808, 174.2934],
  [-35.5798, 174.2933],
  [-35.5673, 174.2885],
  [-35.5557, 174.2849],
  [-35.5545, 174.2843],
  [-35.5417, 174.2763],
  [-35.5404, 174.2751],
  [-35.5392, 174.2728],
  [-35.5386, 174.2689],
  [-35.5387, 174.2669],
  [-35.5392, 174.2650],
  [-35.5425, 174.2585],
  [-35.5427, 174.2573],
  [-35.5428, 174.2546],
  [-35.5428, 174.2536],
  [-35.5425, 174.2521],
  [-35.5414, 174.2493],
  [-35.5410, 174.2481],
  [-35.5405, 174.2457],
  [-35.5392, 174.2380],
  [-35.5381, 174.2341],
  [-35.5373, 174.2330],
  [-35.5348, 174.2315]
];

const waiotuHukerenui = [
  [-35.5348, 174.2315],
  [-35.5343, 174.2312],
  [-35.5330, 174.2304],
  [-35.5323, 174.2296],
  [-35.5314, 174.2281],
  [-35.5294, 174.2255],
  [-35.5287, 174.2239],
  [-35.5285, 174.2229],
  [-35.5283, 174.2222],
  [-35.5262, 174.2182],
  [-35.5256, 174.2174],
  [-35.5247, 174.2164],
  [-35.5236, 174.2138],
  [-35.5192, 174.2012]
];

const hukerenuiTowai = [
  [-35.5192, 174.2012],
  [-35.5183, 174.1986],
  [-35.5115, 174.1852],
  [-35.5107, 174.1843],
  [-35.5031, 174.1797],
  [-35.5016, 174.1780],
  [-35.4990, 174.1730],
  [-35.4979, 174.1697],
  [-35.4976, 174.1674],
  [-35.4976, 174.1658],
  [-35.4972, 174.1639],
  [-35.4965, 174.1622],
  [-35.4953, 174.1603],
  [-35.4949, 174.1592],
  [-35.4948, 174.1582],
  [-35.4949, 174.1537],
  [-35.4951, 174.1522],
  [-35.4960, 174.1494],
  [-35.4966, 174.1480],
  [-35.4968, 174.1470],
  [-35.4966, 174.1457],
  [-35.4952, 174.1431],
  [-35.4949, 174.1421],
  [-35.4949, 174.1412],
  [-35.4957, 174.1350],
  [-35.4960, 174.1338],
  [-35.4992, 174.1282]
];

const towaiOtiria = [
  [-35.4992, 174.1282],
  [-35.4993, 174.1281],
  [-35.4995, 174.1274],
  [-35.4995, 174.1265],
  [-35.4991, 174.1250],
  [-35.4986, 174.1242],
  [-35.4983, 174.1231],
  [-35.4984, 174.1218],
  [-35.4982, 174.1205],
  [-35.4964, 174.1170],
  [-35.4960, 174.1165],
  [-35.4954, 174.1161],
  [-35.4950, 174.1159],
  [-35.4945, 174.1159],
  [-35.4937, 174.1162],
  [-35.4927, 174.1162],
  [-35.4922, 174.1160],
  [-35.4900, 174.1141],
  [-35.4887, 174.1124],
  [-35.4881, 174.1119],
  [-35.4873, 174.1116],
  [-35.4866, 174.1110],
  [-35.4862, 174.1102],
  [-35.4767, 174.0819],
  [-35.4765, 174.0809],
  [-35.4767, 174.0789],
  [-35.4783, 174.0730],
  [-35.4793, 174.0676],
  [-35.4801, 174.0660],
  [-35.4814, 174.0646],
  [-35.4867, 174.0622],
  [-35.4880, 174.0620],
  [-35.4889, 174.0616],
  [-35.4896, 174.0610],
  [-35.4903, 174.0607],
  [-35.4914, 174.0606],
  [-35.4924, 174.0599],
  [-35.4928, 174.0592],
  [-35.4930, 174.0584],
  [-35.4934, 174.0578],
  [-35.4940, 174.0574],
  [-35.4951, 174.0571],
  [-35.4960, 174.0563],
  [-35.4966, 174.0548],
  [-35.4975, 174.0537],
  [-35.4979, 174.0527],
  [-35.4987, 174.0518],
  [-35.4994, 174.0513],
  [-35.5016, 174.0491],
  [-35.5022, 174.0480],
  [-35.5027, 174.0451],
  [-35.5025, 174.0438],
  [-35.5017, 174.0427],
  [-35.5007, 174.0422],
  [-35.4979, 174.0425],
  [-35.4972, 174.0423],
  [-35.4940, 174.0408],
  [-35.4931, 174.0399],
  [-35.4893, 174.0342],
  [-35.4868, 174.0292],
  [-35.4851, 174.0269],
  [-35.4847, 174.0262],
  [-35.4846, 174.0250],
  [-35.4847, 174.0202],
  [-35.4846, 174.0189],
  [-35.4840, 174.0163],
  [-35.4840, 174.0154],
  [-35.4852, 174.0063],
  [-35.4851, 174.0053],
  [-35.4847, 174.0043],
  [-35.4815, 174.0011],
  [-35.4806, 174.0007],
  [-35.4797, 174.0009],
  [-35.4780, 174.0022],
  [-35.4771, 174.0026],
  [-35.4759, 174.0027],
  [-35.4589, 174.0024],
  [-35.4577, 174.0022],
  [-35.4514, 173.9989],
  [-35.4507, 173.9981],
  [-35.4488, 173.9944],
  [-35.4468, 173.9917],
  [-35.4462, 173.9903],
  [-35.4454, 173.9895],
  [-35.4447, 173.9894],
  [-35.4441, 173.9895],
  [-35.4429, 173.9900],
  [-35.4421, 173.9900],
  [-35.4416, 173.9898],
  [-35.4379, 173.9863],
  [-35.4351, 173.9842],
  [-35.4343, 173.9833],
  [-35.4330, 173.9803],
  [-35.4322, 173.9795],
  [-35.4314, 173.9792],
  [-35.4234, 173.9797],
  [-35.4216, 173.9803],
  [-35.4186, 173.9824],
  [-35.4172, 173.9829],
  [-35.4147, 173.9829],
  [-35.4137, 173.9833],
  [-35.4128, 173.9841],
  [-35.4121, 173.9845],
  [-35.4107, 173.9845],
  [-35.4098, 173.9850],
  [-35.4036, 173.9909],
  [-35.4019, 173.9936],
  [-35.3986, 174.0038],
  [-35.3983, 174.0043]
];

export const northAuckland: Line = {
  name: 'North Auckland',
  state: 'NZ',
  segments: [
    {
      segments: [
        swansonSwansonDeviation,
        {
          date: '1981-10-03',
          original: [swansonOld],
          deviation: [swansonDeviation]
        },
        {
          name: 'Waitakere Tunnel',
          segment: waitakereTunnel,
          type: 'tunnel'
        },
        waitakereTunnelHelensville
      ],
      history: {
        opened: {
          date: '1881-07-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [helensvilleKanohi],
      history: {
        opened: {
          date: '1889-03-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        kanohiMakarauTunnel,
        {
          name: 'Makarau Tunnel',
          segment: makarauTunnel,
          type: 'tunnel'
        },
        makarauTunnelMakarau
      ],
      history: {
        opened: {
          date: '1897-06-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [makarauTahekeroa],
      history: {
        opened: {
          date: '1900-12-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        tahekeroaTahekeroaTunnel,
        {
          name: 'Tahekeroa Tunnel',
          segment: tahekeroaTunnel,
          type: 'tunnel'
        },
        tahekeroaTunnelAhuroaTunnel,
        {
          name: 'Ahuroa Tunnel',
          segment: ahuroaTunnel,
          type: 'tunnel'
        },
        ahuroaTunnelHoteoTunnel,
        {
          name: 'Hoteo Tunnel',
          segment: hoteoTunnel,
          type: 'tunnel'
        },
        hoteoTunnelWellsford
      ],
      history: {
        opened: {
          date: '1909-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wellsfordTeHana],
      history: {
        opened: {
          date: '1910-05-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        teHanaTopuniTunnel,
        {
          name: 'Topuni Tunnel',
          segment: topuniTunnel,
          type: 'tunnel'
        },
        topuniTunnelRosHillTunnel,
        {
          name: 'Ross Hill Tunnel',
          segment: rossHillTunnel,
          type: 'tunnel'
        },
        rossHillTunnelKaiwaka
      ],
      history: {
        opened: {
          date: '1913-03-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        kaiwakaRaganuiTunnel,
        {
          name: 'Raganui Tunnel',
          segment: raganuiTunnel,
          type: 'tunnel'
        },
        raganuiTunnelBickerstaffeTunnel,
        {
          name: 'Bickerstaffe Tunnel',
          segment: bickerstaffeTunnel,
          type: 'tunnel'
        },
        bickerstaffeTunnelHuarau
      ],
      history: {
        opened: {
          date: '1920-04-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        huarauHuarauTunnel,
        {
          name: 'Huarau Tunnel',
          segment: huarauTunnel,
          type: 'tunnel'
        },
        huarauTunnelGoldenStairsTunnel,
        {
          name: 'Golden Stairs Tunnel',
          segment: goldenStairsTunnel,
          type: 'tunnel'
        },
        goldenStairsTunnelMareretuTunnel,
        {
          name: 'Mareretu Tunnel',
          segment: mareretuTunnel,
          type: 'tunnel'
        },
        mareretuTunnelWaikiekieTunnel,
        {
          name: 'Waikiekie Tunnel',
          segment: waikiekieTunnel,
          type: 'tunnel'
        },
        waikiekieTunnelPortland
      ],
      history: {
        opened: {
          date: '1925-11-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portlandWhangarei],
      history: {
        opened: {
          date: '1920-04-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [whangareiKamo],
      history: {
        opened: {
          date: '1882-11-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kamoWaro],
      history: {
        opened: {
          date: '1894-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waroWaiotu],
      history: {
        opened: {
          date: '1898-12-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waiotuHukerenui],
      history: {
        opened: {
          date: '1901-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2016-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hukerenuiTowai],
      history: {
        opened: {
          date: '1910-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2016-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [towaiOtiria],
      history: {
        opened: {
          date: '1911-04-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2016-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
