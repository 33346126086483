import { Line } from "../../../../trackTypes";

const kew = [
  [-37.80688, 145.03056],
  [-37.80686, 145.03071],
  [-37.80686, 145.03086],
  [-37.80768, 145.03797],
  [-37.81033, 145.06052]
];

const montAlbert = [
  [-37.81033, 145.06052],
  [-37.81498, 145.09923]
];

const boxHill = [
  [-37.81498, 145.09923],
  [-37.81722, 145.11789],
  [-37.81793, 145.12279]
];

export const east9: Line = {
  name: 'East9',
  state: 'VIC',
  segments: [
    {
      segments: [kew],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [montAlbert],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [boxHill],
      history: {
        opened: {
          date: '2003-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
