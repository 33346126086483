import { Line } from "../../../trackTypes";

const line = [
  [-43.7586, 172.0168],
  [-43.7592, 172.0149],
  [-43.7595, 172.0133],
  [-43.7584, 171.9536],
  [-43.7550, 171.9171],
  [-43.7527, 171.8613],
  [-43.7487, 171.8045],
  [-43.7482, 171.8026],
  [-43.7477, 171.8009],
  [-43.7470, 171.7993],
  [-43.6951, 171.7180],
  [-43.6371, 171.6517]
];

export const methven: Line = {
  name: 'Methven',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1880-02-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-07-31',
          status: 'closed'
        }]
      }
    }
  ]
}
