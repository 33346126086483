import { Line } from "../../trackTypes";

const islingtonJunctionNorthIslingtonJunctionEast = [
  [-32.9130, 151.7427],
  [-32.9169, 151.7468]
]

const islingtonJunctionHamilton = [
  [-32.9169, 151.7468],
  [-32.9184, 151.7485]
];

const hamiltonNewCastleInterchange = [
  [-32.9184, 151.7485],
  [-32.9191, 151.7493],
  [-32.9205, 151.7515],
  [-32.9220, 151.7544],
  [-32.9242, 151.7595]
];

const newCastleInterchangeCivic = [
  [-32.9242, 151.7595],
  [-32.9257, 151.7629],
  [-32.9264, 151.7658],
  [-32.9268, 151.7687],
  [-32.9267, 151.7700],
  [-32.9266, 151.7720]
];

const civicNewcastle = [
  [-32.9266, 151.7720],
  [-32.9263, 151.7747],
  [-32.9261, 151.7789],
  [-32.9261, 151.7816],
  [-32.9263, 151.7831],
  [-32.9266, 151.7845]
];

const hamiltonJunction = [
  [-32.9168, 151.7428],
  [-32.9164, 151.7440],
  [-32.9163, 151.7451],
  [-32.9166, 151.7461],
  [-32.9169, 151.7468]
];

export const newcastle: Line = {
  name: 'Newcastle',
  state: 'NSW',
  segments: [
    {
      segments: [hamiltonJunction],
      history: {
        opened: {
          date: '1887-08-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1864-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [islingtonJunctionHamilton],
      history: {
        opened: {
          date: '1857-03-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1864-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [islingtonJunctionNorthIslingtonJunctionEast],
      history: {
        opened: {
          date: '1857-03-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1864-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [hamiltonNewCastleInterchange],
      history: {
        opened: {
          date: '1857-03-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1864-01-01',
          tracks: 2
        }, {
          date: '2014-12-25',
          status: 'closed'
        }, {
          date: '2017-10-15',
          status: 'open'
        }]
      }
    },
    {
      segments: [newCastleInterchangeCivic],
      history: {
        opened: {
          date: '1857-03-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1864-01-01',
          tracks: 2
        }, {
          date: '2012-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [civicNewcastle],
      history: {
        opened: {
          date: '1857-03-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1864-01-01',
          tracks: 2
        }, {
          date: '2012-12-01',
          status: 'closed'
        }]
      }
    }
  ]
};
