import { Line } from "../../../trackTypes";

const manlyLoop = [
  [-33.79827, 151.28464],
  [-33.79837, 151.28452],
  [-33.79840, 151.28442],
  [-33.79829, 151.28402],
  [-33.79833, 151.28386],
  [-33.79841, 151.28375],
  [-33.79854, 151.28368],
  [-33.79867, 151.28364],
  [-33.79877, 151.28367],
  [-33.79885, 151.28373]
];

const manlyLoopContinued = [
  [-33.79906, 151.28417],
  [-33.79908, 151.28432],
  [-33.79905, 151.28440],
  [-33.79896, 151.28449],
  [-33.79882, 151.28454]
];

const eastEsplanade = [
  [-33.79882, 151.28454],
  [-33.79891, 151.28459],
  [-33.79894, 151.28467],
  [-33.79894, 151.28478],
  [-33.79890, 151.28496]
];

const belgraveStreet = [
  [-33.79547, 151.28491],
  [-33.79555, 151.28503],
  [-33.79561, 151.28509],
  [-33.79570, 151.28515],
  [-33.79583, 151.28518],
  [-33.79827, 151.28464],
  [-33.79882, 151.28454],
  [-33.79912, 151.28454],
  [-33.79924, 151.28457],
  [-33.79935, 151.28464],
  [-33.79950, 151.28489]
];

const belgraveStreetNorth = [
  [-33.79583, 151.28518],
  [-33.79565, 151.28518],
  [-33.79539, 151.28511],
  [-33.79530, 151.28510],
  [-33.79516, 151.28511],
  [-33.79301, 151.28562],
  [-33.79287, 151.28564],
  [-33.79278, 151.28564],
  [-33.79251, 151.28558]
];

const terminus = [
  [-33.79885, 151.28373],
  [-33.79906, 151.28417]
];

const manlyCoast = [
  [-33.79906, 151.28417],
  [-33.79912, 151.28430],
  [-33.79913, 151.28450],
  [-33.79906, 151.28470],
  [-33.79890, 151.28496],
  [-33.79721, 151.28789],
  [-33.79710, 151.28798],
  [-33.79692, 151.28795],
  [-33.79598, 151.28759],
  [-33.79470, 151.28720],
  [-33.79400, 151.28700],
  [-33.79383, 151.28696],
  [-33.79293, 151.28699],
  [-33.79284, 151.28695],
  [-33.79280, 151.28689],
  [-33.79264, 151.28576],
  [-33.79258, 151.28565],
  [-33.79251, 151.28558]
];

const westEsplanadePittwater = [
  [-33.79251, 151.28558],
  [-33.78804, 151.28459],
  [-33.78774, 151.28449],
  [-33.78742, 151.28429],
  [-33.78726, 151.28413],
  [-33.78715, 151.28396],
  [-33.78710, 151.28378],
  [-33.78678, 151.28154]
];

const pittwaterBrookvale = [
  [-33.78678, 151.28154],
  [-33.78668, 151.28132],
  [-33.78653, 151.28115],
  [-33.78638, 151.28105],
  [-33.78620, 151.28099],
  [-33.78567, 151.28105],
  [-33.78542, 151.28098],
  [-33.78297, 151.28023],
  [-33.78287, 151.28018],
  [-33.78277, 151.28010],
  [-33.78171, 151.27868],
  [-33.77934, 151.27520],
  [-33.77910, 151.27495],
  [-33.77877, 151.27468],
  [-33.77834, 151.27442],
  [-33.77748, 151.27403],
  [-33.77722, 151.27381],
  [-33.77699, 151.27353],
  [-33.77682, 151.27324],
  [-33.77583, 151.27112],
  [-33.77571, 151.27091],
  [-33.77549, 151.27069],
  [-33.77526, 151.27054],
  [-33.77470, 151.27026],
  [-33.77441, 151.27004],
  [-33.77422, 151.26978],
  [-33.77375, 151.26889],
  [-33.77355, 151.26863],
  [-33.77329, 151.26839],
  [-33.77306, 151.26828],
  [-33.77274, 151.26823],
  [-33.77225, 151.26833],
  [-33.77208, 151.26846],
  [-33.77180, 151.26870],
  [-33.77147, 151.26879],
  [-33.77117, 151.26878],
  [-33.76856, 151.26813],
  [-33.76833, 151.26812],
  [-33.76815, 151.26817],
  [-33.76791, 151.26827],
  [-33.76597, 151.26972],
  [-33.76516, 151.27034],
  [-33.76501, 151.27049],
  [-33.76433, 151.27119],
  [-33.76416, 151.27133],
  [-33.76381, 151.27145],
  [-33.76200, 151.27174],
  [-33.75903, 151.27237],
  [-33.75899, 151.27235],
  [-33.75894, 151.27229],
  [-33.75859, 151.27014]
];

const brookvaleCollaroy = [
  [-33.76200, 151.27174],
  [-33.76170, 151.27190],
  [-33.76153, 151.27205],
  [-33.76135, 151.27229],
  [-33.76121, 151.27259],
  [-33.76113, 151.27294],
  [-33.76113, 151.27334],
  [-33.76125, 151.27608],
  [-33.76120, 151.27652],
  [-33.76108, 151.27696],
  [-33.76096, 151.27728],
  [-33.76078, 151.27756],
  [-33.75958, 151.27902],
  [-33.75936, 151.27935],
  [-33.75862, 151.28058],
  [-33.75837, 151.28086],
  [-33.75813, 151.28112],
  [-33.75433, 151.28433],
  [-33.75409, 151.28461],
  [-33.75132, 151.28859],
  [-33.75125, 151.28867],
  [-33.75104, 151.28884],
  [-33.74711, 151.29096],
  [-33.74699, 151.29107],
  [-33.74683, 151.29126],
  [-33.74591, 151.29245],
  [-33.74576, 151.29265],
  [-33.74561, 151.29291],
  [-33.74552, 151.29313],
  [-33.74517, 151.29428],
  [-33.74480, 151.29594],
  [-33.74476, 151.29626],
  [-33.74470, 151.30032],
  [-33.74478, 151.30173],
  [-33.74476, 151.30193],
  [-33.74473, 151.30210],
  [-33.74462, 151.30232],
  [-33.74450, 151.30252],
  [-33.74310, 151.30356],
  [-33.74292, 151.30364],
  [-33.74273, 151.30366],
  [-33.74256, 151.30366],
  [-33.74162, 151.30342],
  [-33.74137, 151.30332],
  [-33.74030, 151.30288],
  [-33.73996, 151.30280],
  [-33.73528, 151.30227],
  [-33.73398, 151.30204],
  [-33.73353, 151.30189],
  [-33.73224, 151.30131]
];

const collaroyNarrabeen = [
  [-33.73224, 151.30131],
  [-33.72975, 151.30020],
  [-33.72781, 151.29950],
  [-33.72753, 151.29942],
  [-33.72471, 151.29892],
  [-33.72042, 151.29816],
  [-33.71692, 151.29752],
  [-33.71651, 151.29747],
  [-33.71287, 151.29732]
];

const harbordBeachBranch = [
  [-33.78171, 151.27868],
  [-33.78159, 151.27864],
  [-33.78147, 151.27861],
  [-33.78135, 151.27863],
  [-33.78128, 151.27868],
  [-33.78124, 151.27876],
  [-33.78120, 151.27890],
  [-33.78106, 151.27991],
  [-33.78101, 151.28011],
  [-33.78095, 151.28022],
  [-33.78080, 151.28031],
  [-33.78073, 151.28038],
  [-33.78070, 151.28046],
  [-33.78052, 151.28097],
  [-33.78044, 151.28114],
  [-33.78034, 151.28124],
  [-33.78004, 151.28134],
  [-33.77994, 151.28146],
  [-33.77985, 151.28171],
  [-33.77981, 151.28206],
  [-33.77976, 151.28216],
  [-33.77969, 151.28223],
  [-33.77881, 151.28243],
  [-33.77872, 151.28253],
  [-33.77868, 151.28263],
  [-33.77868, 151.28276],
  [-33.77898, 151.28508],
  [-33.77898, 151.28519],
  [-33.77895, 151.28528],
  [-33.77876, 151.28567],
  [-33.77873, 151.28579],
  [-33.77873, 151.28586],
  [-33.77876, 151.28593],
  [-33.78134, 151.28914]
];

const raglanStreet = [
  [-33.79598, 151.28759],
  [-33.79589, 151.28752],
  [-33.79585, 151.28742],
  [-33.79547, 151.28491],
];

const theSpitBranch = [
  [-33.79547, 151.28491],
  [-33.79529, 151.28363],
  [-33.79531, 151.28352],
  [-33.79535, 151.28343],
  [-33.79541, 151.28325],
  [-33.79539, 151.28313],
  [-33.79525, 151.28213],
  [-33.79515, 151.28177],
  [-33.79516, 151.28157],
  [-33.79520, 151.28141],
  [-33.79530, 151.28123],
  [-33.79542, 151.28113],
  [-33.79562, 151.28107],
  [-33.79568, 151.28098],
  [-33.79571, 151.28087],
  [-33.79567, 151.28055],
  [-33.79569, 151.28045],
  [-33.79575, 151.28038],
  [-33.79598, 151.28033],
  [-33.79604, 151.28025],
  [-33.79605, 151.28016],
  [-33.79597, 151.27971],
  [-33.79601, 151.27962],
  [-33.79607, 151.27955],
  [-33.79642, 151.27950],
  [-33.79653, 151.27942],
  [-33.79656, 151.27933],
  [-33.79653, 151.27919],
  [-33.79645, 151.27910],
  [-33.79638, 151.27909],
  [-33.79609, 151.27910],
  [-33.79603, 151.27905],
  [-33.79597, 151.27899],
  [-33.79593, 151.27891],
  [-33.79590, 151.27877],
  [-33.79597, 151.27817],
  [-33.79598, 151.27790],
  [-33.79593, 151.27654],
  [-33.79588, 151.27623],
  [-33.79545, 151.27450],
  [-33.79537, 151.27428],
  [-33.79521, 151.27402],
  [-33.79515, 151.27389],
  [-33.79510, 151.27371],
  [-33.79505, 151.27340],
  [-33.79502, 151.27323],
  [-33.79489, 151.27276],
  [-33.79486, 151.27260],
  [-33.79486, 151.27246],
  [-33.79498, 151.27160],
  [-33.79503, 151.27060],
  [-33.79502, 151.27022],
  [-33.79501, 151.26897],
  [-33.79470, 151.26677],
  [-33.79384, 151.26088],
  [-33.79384, 151.26068],
  [-33.79388, 151.26046],
  [-33.79599, 151.25312],
  [-33.79608, 151.25294],
  [-33.79618, 151.25285],
  [-33.79635, 151.25277],
  [-33.79814, 151.25245],
  [-33.79834, 151.25246],
  [-33.79850, 151.25251],
  [-33.79929, 151.25306],
  [-33.79945, 151.25314],
  [-33.79974, 151.25319],
  [-33.79990, 151.25316],
  [-33.80003, 151.25310],
  [-33.80062, 151.25260],
  [-33.80067, 151.25244],
  [-33.80070, 151.25227],
  [-33.80068, 151.25201],
  [-33.80053, 151.25184],
  [-33.80035, 151.25182],
  [-33.80020, 151.25187],
  [-33.80002, 151.25195],
  [-33.79986, 151.25221],
  [-33.79979, 151.25230],
  [-33.79971, 151.25230],
  [-33.79963, 151.25225],
  [-33.79933, 151.25136],
  [-33.79930, 151.25104],
  [-33.79934, 151.25080],
  [-33.79952, 151.25061],
  [-33.79971, 151.25030],
  [-33.79976, 151.25014],
  [-33.79979, 151.24996],
  [-33.79991, 151.24933],
  [-33.80000, 151.24920],
  [-33.80010, 151.24911],
  [-33.80020, 151.24906],
  [-33.80065, 151.24919],
  [-33.80094, 151.24914],
  [-33.80117, 151.24904],
  [-33.80129, 151.24890],
  [-33.80138, 151.24874],
  [-33.80143, 151.24860],
  [-33.80150, 151.24830],
  [-33.80149, 151.24751],
  [-33.80138, 151.24665]
];

export const manlyTrams: Line = {
  name: 'Manly (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [manlyCoast],
      history: {
        opened: {
          date: '1903-02-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-01-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1925-12-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westEsplanadePittwater, terminus],
      history: {
        opened: {
          date: '1903-02-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-01-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pittwaterBrookvale],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-01-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brookvaleCollaroy],
      history: {
        opened: {
          date: '1912-08-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [collaroyNarrabeen],
      history: {
        opened: {
          date: '1913-12-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [harbordBeachBranch, belgraveStreetNorth, manlyLoop, manlyLoopContinued],
      history: {
        opened: {
          date: '1925-12-19',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eastEsplanade],
      history: {
        opened: {
          date: '1911-01-09',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1925-12-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [raglanStreet],
      history: {
        opened: {
          date: '1911-01-09',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1925-12-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [theSpitBranch, belgraveStreet],
      history: {
        opened: {
          date: '1911-01-09',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-10-01',
          status: 'closed'
        }]
      }
    }
  ]
};
