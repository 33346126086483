import { vicLines } from "./australia/vicLines";
import { nswLines } from "./australia/nswLines";
import { qldLines } from "./australia/qldLines";
import { actLines } from "./australia/actLines";
import { saLines } from "./australia/saLines";
import { waLines} from "./australia/waLines";
import { tasLines } from "./australia/tasLines";
import { ntLines } from "./australia/ntLines";
import { newZealandLines } from './newZealand/newZealandLines';

// import { isleOfManLines } from './uk/isleOfMan';
// import { isleOfWightLines } from './uk/isleOfWight';
// import { irelandLines } from './ireland/irelandLines';
// import { scotlandLines } from './uk/scotland/scotlandLines';
// import { walesLines } from './uk/wales/walesLines';
// import { englandLines } from "./uk/england/englandLines";

export const lines = [
  ...vicLines,
  ...nswLines,
  ...qldLines,
  ...actLines,
  ...saLines,
  ...waLines,
  ...tasLines,
  ...ntLines,
  ...newZealandLines,
  // ...isleOfManLines,
  // ...isleOfWightLines,
  // ...irelandLines,
  // ...scotlandLines,
  // ...walesLines,
  // ...englandLines
];
