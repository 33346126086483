import { Line } from "../../trackTypes";

const katanningNyabing = [
  [-33.7011, 117.5610],
  [-33.6998, 117.5619],
  [-33.6993, 117.5633],
  [-33.6994, 117.5647],
  [-33.7014, 117.5712],
  [-33.7023, 117.5729],
  [-33.7054, 117.5777],
  [-33.7061, 117.5806],
  [-33.7062, 117.6753],
  [-33.7059, 117.6773],
  [-33.7059, 117.6842],
  [-33.7062, 117.6858],
  [-33.7061, 117.7333],
  [-33.7057, 117.7349],
  [-33.7026, 117.7391],
  [-33.6957, 117.7552],
  [-33.6928, 117.7591],
  [-33.6918, 117.7613],
  [-33.6888, 117.7769],
  [-33.6882, 117.7787],
  [-33.6837, 117.7874],
  [-33.6675, 117.8100],
  [-33.6668, 117.8113],
  [-33.6661, 117.8132],
  [-33.6658, 117.8150],
  [-33.6659, 117.8197],
  [-33.6657, 117.8217],
  [-33.6597, 117.8477],
  [-33.6591, 117.8532],
  [-33.6593, 117.8551],
  [-33.6600, 117.8569],
  [-33.6601, 117.8581],
  [-33.6597, 117.8596],
  [-33.6588, 117.8607],
  [-33.6562, 117.8622],
  [-33.6549, 117.8637],
  [-33.6522, 117.8685],
  [-33.6516, 117.8701],
  [-33.6511, 117.8727],
  [-33.6505, 117.8744],
  [-33.6403, 117.8894],
  [-33.6279, 117.9026],
  [-33.6271, 117.9040],
  [-33.6268, 117.9057],
  [-33.6268, 117.9442],
  [-33.6267, 117.9458],
  [-33.6254, 117.9506],
  [-33.6254, 117.9506],
  [-33.5915, 118.0410],
  [-33.5906, 118.0428],
  [-33.5857, 118.0491],
  [-33.5737, 118.0746],
  [-33.5728, 118.0759],
  [-33.5662, 118.0821],
  [-33.5651, 118.0838],
  [-33.5524, 118.1183],
  [-33.5414, 118.1387],
  [-33.5404, 118.1425],
  [-33.5404, 118.1536],
];

const nyabingPingrup = [
  [-33.5404, 118.1536],
  [-33.5405, 118.1564],
  [-33.5360, 118.1771],
  [-33.5203, 118.2219],
  [-33.5204, 118.2451],
  [-33.5196, 118.2528],
  [-33.5203, 118.2572],
  [-33.5202, 118.2758],
  [-33.5211, 118.2809],
  [-33.5211, 118.3185],
  [-33.5258, 118.3376],
  [-33.5261, 118.3449],
  [-33.5274, 118.3491],
  [-33.5286, 118.3511],
  [-33.5291, 118.3535],
  [-33.5285, 118.3602],
  [-33.5294, 118.3720],
  [-33.5336, 118.4053],
  [-33.5342, 118.4071],
  [-33.5362, 118.4101],
  [-33.5371, 118.4136],
  [-33.5388, 118.4167],
  [-33.5407, 118.4225],
  [-33.5420, 118.4662],
  [-33.5418, 118.4677],
  [-33.5362, 118.4825],
  [-33.5350, 118.4926],
  [-33.5347, 118.5132]
];

export const pingrup: Line = {
  name: 'Pingrup',
  state: 'WA',
  segments: [
    {
      segments: [katanningNyabing],
      history: {
        opened: {
          date: '1912-04-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nyabingPingrup],
      history: {
        opened: {
          date: '1923-10-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
