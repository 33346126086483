import { Line } from "../../trackTypes";

const kabraDeviationStart = [
  [-23.4680, 150.3954],
  [-23.4687, 150.3949],
  [-23.4696, 150.3942],
  [-23.4709, 150.3939],
  [-23.4718, 150.3941],
  [-23.4727, 150.3949],
  [-23.4750, 150.3979],
  [-23.4790, 150.4027],
  [-23.4799, 150.4036],
  [-23.4873, 150.4087],
  [-23.4890, 150.4093],
  [-23.4952, 150.4086],
  [-23.5078, 150.4078],
  [-23.5142, 150.4082],
  [-23.5154, 150.4081],
  [-23.5236, 150.4056],
  [-23.5272, 150.4033],
  [-23.5283, 150.4030],
  [-23.5328, 150.4033],
  [-23.5347, 150.4029],
  [-23.5366, 150.4018],
  [-23.5434, 150.3998],
  [-23.5448, 150.3987]
];

const deviationStartMoonmera = [
  [-23.5448, 150.3987],
  [-23.5456, 150.3982],
  [-23.5466, 150.3980],
  [-23.5477, 150.3983],
  [-23.5507, 150.4002],
  [-23.5520, 150.4005],
  [-23.5536, 150.4001],
  [-23.5557, 150.3985],
  [-23.5570, 150.3980],
  [-23.5610, 150.3973],
  [-23.5646, 150.3975],
  [-23.5679, 150.3983],
  [-23.5702, 150.4000],
  [-23.5737, 150.4015],
  [-23.5760, 150.4018],
  [-23.5791, 150.4012],
  [-23.5819, 150.3990],
  [-23.5902, 150.3958]
];

const moonmeraDeviationEnd = [
  [-23.5902, 150.3958],
  [-23.5926, 150.3947],
  [-23.5941, 150.3944],
  [-23.5972, 150.3947],
  [-23.5978, 150.3955],
  [-23.5985, 150.3961],
  [-23.5997, 150.3964],
  [-23.6004, 150.3969],
  [-23.6010, 150.3977],
  [-23.6027, 150.3990],
];

const mountMorgonDeviation = [
  [-23.5448, 150.3987],
  [-23.5450, 150.3977],
  [-23.5448, 150.3966],
  [-23.5446, 150.3954],
  [-23.5446, 150.3935],
  [-23.5462, 150.3892],
  [-23.5478, 150.3872],
  [-23.5492, 150.3865],
  [-23.5509, 150.3846],
  [-23.5542, 150.3742],
  [-23.5548, 150.3734],
  [-23.5558, 150.3732],
  [-23.5566, 150.3736],
  [-23.5571, 150.3745],
  [-23.5580, 150.3803],
  [-23.5590, 150.3831],
  [-23.5626, 150.3881],
  [-23.5640, 150.3890],
  [-23.5757, 150.3927],
  [-23.5767, 150.3931],
  [-23.5776, 150.3932],
  [-23.5783, 150.3927],
  [-23.5787, 150.3917],
  [-23.5795, 150.3911],
  [-23.5816, 150.3912],
  [-23.5822, 150.3910],
  [-23.5827, 150.3905],
  [-23.5832, 150.3895],
  [-23.5838, 150.3890],
  [-23.5847, 150.3888],
  [-23.5888, 150.3898],
  [-23.5911, 150.3911],
  [-23.5919, 150.3914],
  [-23.5934, 150.3918],
  [-23.5941, 150.3922],
  [-23.5947, 150.3923],
  [-23.5958, 150.3922],
  [-23.5973, 150.3927],
  [-23.5979, 150.3931],
  [-23.5982, 150.3938],
  [-23.5982, 150.3946],
  [-23.5985, 150.3952],
  [-23.5989, 150.3956],
  [-23.6005, 150.3963],
  [-23.6011, 150.3968],
  [-23.6014, 150.3975],
  [-23.6018, 150.3981],
  [-23.6027, 150.3990]
];

const moonmeraMountMorgan = [
  [-23.6027, 150.3990],
  [-23.6030, 150.3992],
  [-23.6037, 150.3993],
  [-23.6045, 150.3993],
  [-23.6052, 150.3987],
  [-23.6067, 150.3962],
  [-23.6078, 150.3948],
  [-23.6081, 150.3946],
  [-23.6094, 150.3943],
  [-23.6123, 150.3940],
  [-23.6127, 150.3941],
  [-23.6132, 150.3941],
  [-23.6140, 150.3938],
  [-23.6146, 150.3929],
  [-23.6153, 150.3923],
  [-23.6185, 150.3914],
  [-23.6198, 150.3915],
  [-23.6211, 150.3911],
  [-23.6240, 150.3891],
  [-23.6250, 150.3886],
  [-23.6270, 150.3881],
  [-23.6276, 150.3881],
  [-23.6281, 150.3882],
  [-23.6291, 150.3881],
  [-23.6305, 150.3873],
  [-23.6309, 150.3871],
  [-23.6317, 150.3869],
  [-23.6399, 150.3866]
];

const mountMorganMountMorganTunnel = [
  [-23.6399, 150.3866],
  [-23.6405, 150.3864],
  [-23.6412, 150.3858],
  [-23.6426, 150.3836],
  [-23.6441, 150.3801]
];

const mountMorganTunnel = [
  [-23.6441, 150.3801],
  [-23.6444, 150.3795]
];

const mountMorganTunnelWowan = [
  [-23.6444, 150.3795],
  [-23.6447, 150.3788],
  [-23.6454, 150.3778],
  [-23.6467, 150.3773],
  [-23.6488, 150.3772],
  [-23.6496, 150.3769],
  [-23.6508, 150.3755],
  [-23.6565, 150.3712],
  [-23.6598, 150.3702],
  [-23.6606, 150.3704],
  [-23.6618, 150.3713],
  [-23.6624, 150.3730],
  [-23.6629, 150.3735],
  [-23.6637, 150.3738],
  [-23.6649, 150.3733],
  [-23.6663, 150.3708],
  [-23.6668, 150.3706],
  [-23.6677, 150.3705],
  [-23.6708, 150.3715],
  [-23.6720, 150.3711],
  [-23.6763, 150.3655],
  [-23.6767, 150.3637],
  [-23.6784, 150.3603],
  [-23.6795, 150.3577],
  [-23.6805, 150.3561],
  [-23.6827, 150.3546],
  [-23.6879, 150.3540],
  [-23.6890, 150.3535],
  [-23.6905, 150.3509],
  [-23.6920, 150.3496],
  [-23.6950, 150.3486],
  [-23.6958, 150.3485],
  [-23.6970, 150.3491],
  [-23.7006, 150.3520],
  [-23.7012, 150.3531],
  [-23.7014, 150.3563],
  [-23.7021, 150.3573],
  [-23.7035, 150.3577],
  [-23.7066, 150.3568],
  [-23.7077, 150.3561],
  [-23.7086, 150.3555],
  [-23.7119, 150.3550],
  [-23.7130, 150.3553],
  [-23.7142, 150.3570],
  [-23.7142, 150.3584],
  [-23.7118, 150.3621],
  [-23.7105, 150.3632],
  [-23.7074, 150.3650],
  [-23.7066, 150.3659],
  [-23.7062, 150.3675],
  [-23.7064, 150.3689],
  [-23.7074, 150.3702],
  [-23.7114, 150.3718],
  [-23.7180, 150.3717],
  [-23.7295, 150.3752],
  [-23.7320, 150.3753],
  [-23.7380, 150.3730],
  [-23.7399, 150.3717],
  [-23.7449, 150.3652],
  [-23.7460, 150.3647],
  [-23.7486, 150.3646],
  [-23.7545, 150.3631],
  [-23.7621, 150.3626],
  [-23.7635, 150.3613],
  [-23.7643, 150.3595],
  [-23.7714, 150.3509],
  [-23.7728, 150.3502],
  [-23.7749, 150.3504],
  [-23.7762, 150.3513],
  [-23.7780, 150.3516],
  [-23.7848, 150.3496],
  [-23.7879, 150.3470],
  [-23.7897, 150.3474],
  [-23.7902, 150.3488],
  [-23.7910, 150.3501],
  [-23.7960, 150.3527],
  [-23.7975, 150.3526],
  [-23.8000, 150.3510],
  [-23.8009, 150.3492],
  [-23.8012, 150.3422],
  [-23.8017, 150.3410],
  [-23.8029, 150.3401],
  [-23.8045, 150.3397],
  [-23.8057, 150.3393],
  [-23.8073, 150.3375],
  [-23.8085, 150.3339],
  [-23.8098, 150.3323],
  [-23.8111, 150.3315],
  [-23.8122, 150.3309],
  [-23.8140, 150.3284],
  [-23.8146, 150.3267],
  [-23.8145, 150.3250],
  [-23.8117, 150.3180],
  [-23.8116, 150.3165],
  [-23.8124, 150.3073],
  [-23.8133, 150.3040],
  [-23.8180, 150.2954],
  [-23.8192, 150.2907],
  [-23.8225, 150.2863],
  [-23.8242, 150.2811],
  [-23.8264, 150.2781],
  [-23.8399, 150.2695],
  [-23.8413, 150.2680],
  [-23.8624, 150.2313],
  [-23.8641, 150.2292],
  [-23.8956, 150.2022],
  [-23.8996, 150.1999],
  [-23.9080, 150.1965]
];

const wowanRannes = [
  [-23.9080, 150.1965],
  [-23.9350, 150.1863],
  [-23.9520, 150.1762],
  [-23.9700, 150.1638],
  [-23.9751, 150.1621],
  [-23.9932, 150.1511],
  [-24.0018, 150.1437],
  [-24.0051, 150.1426],
  [-24.0079, 150.1430],
  [-24.0116, 150.1444],
  [-24.0173, 150.1453],
  [-24.0249, 150.1442],
  [-24.0283, 150.1431],
  [-24.0380, 150.1379],
  [-24.0400, 150.1374],
  [-24.0469, 150.1373],
  [-24.0593, 150.1361],
  [-24.0616, 150.1365],
  [-24.0627, 150.1364],
  [-24.0639, 150.1356],
  [-24.0652, 150.1354],
  [-24.0673, 150.1356],
  [-24.0710, 150.1356],
  [-24.0737, 150.1352],
  [-24.0778, 150.1352],
  [-24.0795, 150.1353],
  [-24.0813, 150.1364],
  [-24.0825, 150.1370],
  [-24.0838, 150.1365],
  [-24.0852, 150.1350],
  [-24.0855, 150.1334],
  [-24.0861, 150.1322],
  [-24.0873, 150.1311],
  [-24.0927, 150.1283],
  [-24.0932, 150.1271],
  [-24.0932, 150.1253],
  [-24.0933, 150.1244],
  [-24.0946, 150.1206],
  [-24.0955, 150.1195],
  [-24.0967, 150.1188],
  [-24.1004, 150.1183]
];

const rannesBaralaba = [
  [-24.1004, 150.1183],
  [-24.1014, 150.1180],
  [-24.1034, 150.1180],
  [-24.1095, 150.1190],
  [-24.1109, 150.1189],
  [-24.1125, 150.1179],
  [-24.1134, 150.1165],
  [-24.1148, 150.1138],
  [-24.1157, 150.1110],
  [-24.1157, 150.1097],
  [-24.1135, 150.1039],
  [-24.1133, 150.1016],
  [-24.1139, 150.0954],
  [-24.1143, 150.0941],
  [-24.1209, 150.0797],
  [-24.1211, 150.0774],
  [-24.1203, 150.0719],
  [-24.1190, 150.0707],
  [-24.1170, 150.0702],
  [-24.1155, 150.0695],
  [-24.1108, 150.0654],
  [-24.1084, 150.0619],
  [-24.1072, 150.0572],
  [-24.1072, 150.0546],
  [-24.1090, 150.0464],
  [-24.1098, 150.0455],
  [-24.1107, 150.0452],
  [-24.1131, 150.0457],
  [-24.1144, 150.0455],
  [-24.1206, 150.0386],
  [-24.1216, 150.0358],
  [-24.1216, 150.0298],
  [-24.1224, 150.0269],
  [-24.1439, 149.9838],
  [-24.1445, 149.9809],
  [-24.1442, 149.9780],
  [-24.1425, 149.9733],
  [-24.1424, 149.9718],
  [-24.1457, 149.9619],
  [-24.1469, 149.9606],
  [-24.1508, 149.9595],
  [-24.1526, 149.9579],
  [-24.1607, 149.9383],
  [-24.1615, 149.9356],
  [-24.1633, 149.9248],
  [-24.1671, 149.9141],
  [-24.1676, 149.9114],
  [-24.1659, 149.8919],
  [-24.1654, 149.8893],
  [-24.1616, 149.8800],
  [-24.1611, 149.8777],
  [-24.1613, 149.8743],
  [-24.1616, 149.8727],
  [-24.1630, 149.8698],
  [-24.1633, 149.8666],
  [-24.1628, 149.8631],
  [-24.1632, 149.8599],
  [-24.1670, 149.8514],
  [-24.1676, 149.8495],
  [-24.1685, 149.8420],
  [-24.1721, 149.8302],
  [-24.1740, 149.8183],
  [-24.1752, 149.8155],
  [-24.1768, 149.8141],
  [-24.1813, 149.8120]
];

const baralabaMoura = [
  [-24.1813, 149.8120],
  [-24.1824, 149.8117],
  [-24.1844, 149.8117],
  [-24.1892, 149.8137],
  [-24.1963, 149.8190],
  [-24.2045, 149.8279],
  [-24.2071, 149.8296],
  [-24.2123, 149.8308],
  [-24.2138, 149.8316],
  [-24.2172, 149.8350],
  [-24.2183, 149.8378],
  [-24.2199, 149.8395],
  [-24.2259, 149.8418],
  [-24.2277, 149.8431],
  [-24.2293, 149.8447],
  [-24.2309, 149.8456],
  [-24.2564, 149.8499],
  [-24.2596, 149.8499],
  [-24.2726, 149.8470],
  [-24.2883, 149.8471],
  [-24.2988, 149.8456],
  [-24.3362, 149.8509],
  [-24.3383, 149.8519],
  [-24.3420, 149.8562],
  [-24.3446, 149.8578],
  [-24.3682, 149.8619],
  [-24.3812, 149.8652],
  [-24.3896, 149.8655],
  [-24.3924, 149.8669],
  [-24.3967, 149.8714],
  [-24.3988, 149.8728],
  [-24.4033, 149.8738],
  [-24.4306, 149.8727],
  [-24.4340, 149.8731],
  [-24.4971, 149.9038],
  [-24.5105, 149.9129],
  [-24.5422, 149.9460],
  [-24.5556, 149.9652],
  [-24.5690, 149.9773]
];

const mouraNipan = [
  [-24.5690, 149.9773],
  [-24.5775, 149.9851],
  [-24.5791, 149.9862],
  [-24.5948, 149.9932],
  [-24.5965, 149.9938],
  [-24.6314, 150.0010],
  [-24.6747, 150.0085],
  [-24.6764, 150.0090],
  [-24.6811, 150.0112],
  [-24.6869, 150.0122],
  [-24.6882, 150.0122],
  [-24.6900, 150.0119],
  [-24.6992, 150.0129],
  [-24.7057, 150.0158],
  [-24.7075, 150.0160],
  [-24.7090, 150.0156],
  [-24.7125, 150.0132],
  [-24.7145, 150.0129],
  [-24.7169, 150.0138],
  [-24.7207, 150.0148],
  [-24.7263, 150.0179],
  [-24.7294, 150.0189],
  [-24.7526, 150.0226],
  [-24.7683, 150.0230],
  [-24.7746, 150.0216]
];

const nipanGoolara = [
  [-24.7746, 150.0216],
  [-24.7873, 150.0189],
  [-24.7895, 150.0190],
  [-24.8167, 150.0306],
  [-24.8202, 150.0333],
  [-24.8215, 150.0341],
  [-24.8228, 150.0342],
  [-24.8291, 150.0328],
  [-24.8308, 150.0330],
  [-24.8326, 150.0341],
  [-24.8336, 150.0354],
  [-24.8407, 150.0471],
  [-24.8414, 150.0493],
  [-24.8415, 150.0530],
  [-24.8435, 150.0592],
  [-24.8443, 150.0604],
  [-24.8455, 150.0612],
  [-24.8507, 150.0631],
  [-24.8517, 150.0631],
  [-24.8533, 150.0626],
  [-24.8545, 150.0627],
  [-24.8556, 150.0636],
  [-24.8561, 150.0646],
  [-24.8571, 150.0653],
  [-24.8585, 150.0655],
  [-24.8598, 150.0664],
  [-24.8611, 150.0688],
  [-24.8628, 150.0710],
  [-24.8646, 150.0719],
  [-24.9083, 150.0807]
];

const goolaraTheodore = [
  [-24.9083, 150.0807],
  [-24.9121, 150.0814],
  [-24.9133, 150.0812],
  [-24.9153, 150.0798],
  [-24.9162, 150.0797],
  [-24.9171, 150.0799],
  [-24.9240, 150.0859],
  [-24.9252, 150.0863],
  [-24.9337, 150.0856],
  [-24.9348, 150.0853],
  [-24.9401, 150.0819],
  [-24.9414, 150.0814],
  [-24.9498, 150.0821]
];

export const dawsonValley: Line = {
  name: 'Dawson Valley',
  state: 'QLD',
  segments: [
    {
      segments: [
        kabraDeviationStart,
        {
          date: '1952-01-01',
          original: [deviationStartMoonmera],
          deviation: [mountMorgonDeviation]
        }
      ],
      history: {
        opened: {
          date: '1898-06-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [deviationStartMoonmera],
      history: {
        opened: {
          date: '1898-06-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moonmeraDeviationEnd],
      history: {
        opened: {
          date: '1898-11-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moonmeraMountMorgan],
      history: {
        opened: {
          date: '1898-11-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        mountMorganMountMorganTunnel,
        {
          name: 'Mount Morgan Tunnel',
          segment: mountMorganTunnel,
          type: 'tunnel'
        },
        mountMorganTunnelWowan
      ],
      history: {
        opened: {
          date: '1912-10-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wowanRannes],
      history: {
        opened: {
          date: '1915-02-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rannesBaralaba],
      history: {
        opened: {
          date: '1917-07-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [baralabaMoura],
      history: {
        opened: {
          date: '1926-06-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mouraNipan],
      history: {
        opened: {
          date: '1926-06-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nipanGoolara],
      history: {
        opened: {
          date: '1927-05-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goolaraTheodore],
      history: {
        opened: {
          date: '1927-05-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
