import { Line } from "../../trackTypes";

const line = [
  [-38.3283, 143.7863],
  [-38.3284, 143.7890],
  [-38.3290, 143.7910],
  [-38.3435, 143.8105],
  [-38.3449, 143.8115],
  [-38.3479, 143.8122],
  [-38.3490, 143.8128],
  [-38.3651, 143.8248],
  [-38.3739, 143.8315],
  [-38.3752, 143.8330],
  [-38.3837, 143.8479],
  [-38.3847, 143.8511],
  [-38.3866, 143.8636],
  [-38.3874, 143.8658],
  [-38.3890, 143.8678],
  [-38.3950, 143.8728],
  [-38.3970, 143.8735],
  [-38.3987, 143.8728],
  [-38.4007, 143.8714],
  [-38.4064, 143.8634],
  [-38.4331, 143.8032],
  [-38.4336, 143.8023],
  [-38.4543, 143.7734],
  [-38.4601, 143.7645],
  [-38.4702, 143.7426],
  [-38.4716, 143.7406],
  [-38.4732, 143.7400],
  [-38.4763, 143.7400],
  [-38.4783, 143.7404],
  [-38.4818, 143.7431],
  [-38.4833, 143.7437],
  [-38.4863, 143.7432],
  [-38.4882, 143.7418],
  [-38.4959, 143.7338],
  [-38.4995, 143.7287],
  [-38.5021, 143.7265],
  [-38.5051, 143.7233],
  [-38.5096, 143.7183],
  [-38.5116, 143.7170],
  [-38.5129, 143.7168],
  [-38.5147, 143.7171],
  [-38.5167, 143.7175]
];

export const forrest: Line = {
  name: 'Forrest',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1891-06-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-03-04',
          status: 'closed'
        }]
      }
    }
  ]
}
