import { Line } from "../../../trackTypes";

const morwellBoolarra = [
  [-38.2359, 146.3939],
  [-38.2361, 146.3923],
  [-38.2368, 146.3905],
  [-38.2389, 146.3878],
  [-38.2407, 146.3854],
  [-38.2452, 146.3811],
  [-38.2550, 146.3762],
  [-38.2642, 146.3751],
  [-38.2717, 146.3708],
  [-38.2747, 146.3677],
  [-38.2889, 146.3491],
  [-38.3039, 146.3402],
  [-38.3051, 146.3399],
  [-38.3078, 146.3399],
  [-38.3099, 146.3390],
  [-38.3135, 146.3348],
  [-38.3157, 146.3311],
  [-38.3172, 146.3296],
  [-38.3205, 146.3284],
  [-38.3607, 146.2953],
  [-38.3654, 146.2908],
  [-38.3667, 146.2882],
  [-38.3679, 146.2855],
  [-38.3759, 146.2763]
];

const boolarraDarlimurla = [
  [-38.3759, 146.2763],
  [-38.3806, 146.2711],
  [-38.3819, 146.2678],
  [-38.3820, 146.2641],
  [-38.3851, 146.2475],
  [-38.3849, 146.2454],
  [-38.3838, 146.2440],
  [-38.3771, 146.2400],
  [-38.3754, 146.2372],
  [-38.3746, 146.2329],
  [-38.3746, 146.2310],
  [-38.3751, 146.2266],
  [-38.3739, 146.2194],
  [-38.3728, 146.2170],
  [-38.3710, 146.2145],
  [-38.3704, 146.2127],
  [-38.3698, 146.2082],
  [-38.3701, 146.2062]
];

const darlimurlaMirbooNorth = [
  [-38.3701, 146.2062],
  [-38.3705, 146.2050],
  [-38.3716, 146.2030],
  [-38.3762, 146.1968],
  [-38.3775, 146.1954],
  [-38.3872, 146.1888],
  [-38.3885, 146.1872],
  [-38.3904, 146.1827],
  [-38.3917, 146.1808],
  [-38.3928, 146.1796],
  [-38.3953, 146.1772],
  [-38.3983, 146.1744],
  [-38.3994, 146.1727],
  [-38.3999, 146.1702],
  [-38.4007, 146.1596],
  [-38.4002, 146.1559]
];

export const mirbooNorth: Line = {
  name: 'Mirboo North',
  state: 'VIC',
  segments: [
    {
      segments: [morwellBoolarra],
      history: {
        opened: {
          date: '1885-04-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-06-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boolarraDarlimurla],
      history: {
        opened: {
          date: '1885-09-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-06-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [darlimurlaMirbooNorth],
      history: {
        opened: {
          date: '1886-01-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-06-24',
          status: 'closed'
        }]
      }
    }
  ]
}
