import { Line } from "../../trackTypes";

const dandenongDandenongThroughSiding = [
  [-37.9999, 145.2218],
  [-38.0005, 145.2226],
  [-38.0010, 145.2232],
  [-38.0016, 145.2236],
  [-38.0211, 145.2322]
];

const dandenongThroughSidingCranbourne = [
  [-38.0211, 145.2322],
  [-38.0393, 145.2402],
  [-38.0597, 145.2505],
  [-38.0653, 145.2533],
  [-38.0666, 145.2541],
  [-38.0968, 145.2770],
  [-38.0975, 145.2776],
  [-38.0984, 145.2787],
  [-38.0999, 145.2810]
];

export const cranbourne: Line = {
  name: 'Cranbourne',
  state: 'VIC',
  segments: [
    {
      segments: [dandenongThroughSidingCranbourne],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2022-02-03',
          tracks: 2
        }, {
          date: '1995-03-25',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [dandenongDandenongThroughSiding],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-12-24',
          tracks: 2,
        }, {
          date: '1995-03-25',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
