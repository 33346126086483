import { Line } from "../../trackTypes";

const maitlandJunctionEastGreta = [
  [-32.7348, 151.5433],
  [-32.7347, 151.5415],
  [-32.7349, 151.5407],
  [-32.7354, 151.5399],
  [-32.7361, 151.5393],
  [-32.7378, 151.5385],
  [-32.7385, 151.5377],
  [-32.7390, 151.5367],
  [-32.7396, 151.5360],
  [-32.7416, 151.5347],
  [-32.7423, 151.5339],
  [-32.7459, 151.5293],
  [-32.7485, 151.5268],
  [-32.7521, 151.5247],
  [-32.7578, 151.5230]
];

const eastGretaAberdareJunction = [
  [-32.7578, 151.5230],
  [-32.7603, 151.5223],
  [-32.7612, 151.5218],
  [-32.7619, 151.5212],
  [-32.7628, 151.5207],
  [-32.7658, 151.5207],
  [-32.7691, 151.5201],
  [-32.7701, 151.5195],
  [-32.7724, 151.5170],
  [-32.7729, 151.5162],
  [-32.7741, 151.5140]
];

const aberdareJunctionWeston = [
  [-32.7741, 151.5140],
  [-32.7782, 151.5049],
  [-32.7791, 151.5038],
  [-32.7811, 151.5022],
  [-32.7822, 151.5016],
  [-32.7837, 151.5014],
  [-32.7851, 151.5007],
  [-32.7862, 151.4986],
  [-32.7906, 151.4945],
  [-32.7915, 151.4941],
  [-32.7927, 151.4939],
  [-32.7941, 151.4931],
  [-32.7960, 151.4904],
  [-32.7966, 151.4891],
  [-32.7971, 151.4866],
  [-32.7973, 151.4857],
  [-32.7978, 151.4850],
  [-32.7992, 151.4836],
  [-32.8007, 151.4832],
  [-32.8024, 151.4833],
  [-32.8036, 151.4831],
  [-32.8046, 151.4822],
  [-32.8154, 151.4643],
  [-32.8158, 151.4631],
  [-32.8160, 151.4598]
];

const westonAbermain = [
  [-32.8160, 151.4598],
  [-32.8163, 151.4529],
  [-32.8170, 151.4503],
  [-32.8170, 151.4485],
  [-32.8167, 151.4474],
  [-32.8119, 151.4365],
  [-32.8115, 151.4351],
  [-32.8113, 151.4295],
  [-32.8117, 151.4275]
];

const abermainBellbirdJunction = [
  [-32.8117, 151.4275],
  [-32.8121, 151.4266],
  [-32.8131, 151.4254],
  [-32.8184, 151.4229],
  [-32.8373, 151.4060],
  [-32.8388, 151.4051],
  [-32.8399, 151.4048],
  [-32.8413, 151.4036],
  [-32.8423, 151.4017],
  [-32.8458, 151.3982],
  [-32.8467, 151.3968],
  [-32.8488, 151.3889],
  [-32.8486, 151.3872],
  [-32.8480, 151.3856],
  [-32.8477, 151.3842],
  [-32.8474, 151.3776],
  [-32.8472, 151.3762],
  [-32.8449, 151.3708],
  [-32.8437, 151.3652]
];

const bellbirdJunctionCessnock = [
  [-32.8437, 151.3652],
  [-32.8416, 151.3559]
];

const cessnockAberdareExtendedColliery = [
  [-32.8416, 151.3559],
  [-32.8404, 151.3512]
];

export const cessnock: Line = {
  name: 'Cessnock',
  state: 'NSW',
  segments: [
    {
      segments: [maitlandJunctionEastGreta],
      history: {
        opened: {
          date: '1893-07-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1903-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [eastGretaAberdareJunction],
      history: {
        opened: {
          date: '1901-09-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1906-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [aberdareJunctionWeston],
      history: {
        opened: {
          date: '1902-12-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-02-11',
          tracks: 2
        }]
      }
    },
    {
      segments: [westonAbermain],
      history: {
        opened: {
          date: '1903-01-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-02-11',
          tracks: 2
        }]
      }
    },
    {
      segments: [abermainBellbirdJunction],
      history: {
        opened: {
          date: '1904-02-16',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bellbirdJunctionCessnock],
      history: {
        opened: {
          date: '1912-02-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-12-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cessnockAberdareExtendedColliery],
      history: {
        opened: {
          date: '1912-02-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-12-23',
          status: 'closed'
        }]
      }
    }
  ]
};
