import { Line } from "../../../../trackTypes";

const portMelbourne = [
  [-37.82764, 144.95809],
  [-37.83262, 144.95222],
  [-37.83291, 144.95182],
  [-37.83390, 144.94984],
  [-37.83408, 144.94936],
  [-37.83491, 144.94605],
  [-37.83504, 144.94573],
  [-37.83523, 144.94544],
  [-37.84301, 144.93827],
  [-37.84307, 144.93813],
  [-37.84305, 144.93799],
  [-37.84246, 144.93675],
  [-37.84184, 144.93568],
  [-37.84151, 144.93473]
];

export const south4: Line = {
  name: 'South4',
  state: 'VIC',
  segments: [
    {
      segments: [portMelbourne],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
