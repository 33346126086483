import { Line } from "../../../trackTypes";

const brayshawParkRiversidePark = [
  [-41.5311, 173.9398],
  [-41.5342, 173.9379],
  [-41.5343, 173.9378],
  [-41.5344, 173.9376],
  [-41.5344, 173.9375],
  [-41.5344, 173.9372],
  [-41.5343, 173.9370],
  [-41.5341, 173.9370],
  [-41.5336, 173.9369],
  [-41.5333, 173.9370],
  [-41.5323, 173.9376],
  [-41.5316, 173.9379],
  [-41.5311, 173.9382],
  [-41.5308, 173.9386],
  [-41.5303, 173.9389],
  [-41.5295, 173.9402],
  [-41.5288, 173.9409],
  [-41.5282, 173.9412],
  [-41.5272, 173.9413],
  [-41.5253, 173.9411],
  [-41.5246, 173.9411],
  [-41.5243, 173.9410],
  [-41.5238, 173.9408],
  [-41.5230, 173.9407],
  [-41.5226, 173.9407],
  [-41.5185, 173.9405],
  [-41.5180, 173.9407],
  [-41.5177, 173.9409],
  [-41.5163, 173.9430],
  [-41.5160, 173.9432],
  [-41.5154, 173.9432],
  [-41.5144, 173.9434],
  [-41.5140, 173.9434],
  [-41.5137, 173.9433],
  [-41.5133, 173.9427],
  [-41.5130, 173.9425],
  [-41.5116, 173.9424],
  [-41.5112, 173.9426],
  [-41.5109, 173.9431],
  [-41.5108, 173.9435],
  [-41.5108, 173.9438],
  [-41.5107, 173.9441],
  [-41.5101, 173.9456],
  [-41.5099, 173.9459],
  [-41.5096, 173.9466],
  [-41.5095, 173.9473],
  [-41.5094, 173.9484],
  [-41.5092, 173.9497],
  [-41.5085, 173.9518]
];

const beaverExtension = [
  [-41.5085, 173.9518],
  [-41.5084, 173.9529],
  [-41.5084, 173.9533],
  [-41.5088, 173.9546],
  [-41.5090, 173.9550],
  [-41.5092, 173.9553],
  [-41.5093, 173.9557],
  [-41.5093, 173.9561],
  [-41.5093, 173.9565],
  [-41.5094, 173.9580],
  [-41.5096, 173.9582],
  [-41.5101, 173.9582],
  [-41.5104, 173.9583],
  [-41.5107, 173.9585],
  [-41.5116, 173.9591],
  [-41.5119, 173.9593],
  [-41.5120, 173.9596],
  [-41.5118, 173.9606]
];

const omakaBranch = [
  [-41.5341, 173.9370],
  [-41.5343, 173.9369],
  [-41.5344, 173.9369],
  [-41.5349, 173.9367],
  [-41.5352, 173.9365],
  [-41.5354, 173.9365],
  [-41.5356, 173.9365],
  [-41.5357, 173.9364],
  [-41.5359, 173.9363],
  [-41.5360, 173.9362],
  [-41.5363, 173.9361],
  [-41.5366, 173.9359],
  [-41.5367, 173.9358],
  [-41.5371, 173.9341],
  [-41.5372, 173.9337],
  [-41.5374, 173.9295],
  [-41.5374, 173.9291],
  [-41.5374, 173.9281]
];

const omakaJunction = [
  [-41.5344, 173.9375],
  [-41.5346, 173.9371],
  [-41.5349, 173.9367]
];

export const blenheimRiverside: Line = {
  name: 'Blenheim Riverside',
  state: 'NZ',
  segments: [
    {
      segments: [brayshawParkRiversidePark],
      history: {
        opened: {
          date: '1990-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [beaverExtension],
      history: {
        opened: {
          date: '2005-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [omakaBranch, omakaJunction],
      history: {
        opened: {
          date: '2015-03-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
