import { Line } from "../../../trackTypes";

const northJunction = [
  [-27.4473, 153.0377],
  [-27.4489, 153.0385],
  [-27.4494, 153.0390],
  [-27.4500, 153.0401]
];

const brunswickStreetCommercialRoad = [
  [-27.4510, 153.0373],
  [-27.4503, 153.0386],
  [-27.4500, 153.0401],
  [-27.4510, 153.0463],
  [-27.4517, 153.0476],
  [-27.4525, 153.0482]
];

const commercialRoadNewFarm = [
  [-27.4525, 153.0482],
  [-27.4543, 153.0494],
  [-27.4557, 153.0499],
  [-27.4630, 153.0503],
  [-27.4641, 153.0508],
  [-27.4654, 153.0515],
  [-27.4673, 153.0541]
];

export const bulimba: Line = {
  name: 'Bulimba',
  state: 'QLD',
  segments: [
    {
      segments: [northJunction, brunswickStreetCommercialRoad],
      history: {
        opened: {
          date: '1897-12-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [commercialRoadNewFarm],
      history: {
        opened: {
          date: '1897-12-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-11-01',
          status: 'closed'
        }]
      }
    }
  ]
}
