import { Line } from "../../trackTypes";

const joppaJunctionTarago = [
  [-34.7897, 149.6780],
  [-34.7900, 149.6768],
  [-34.7904, 149.6759],
  [-34.7911, 149.6751],
  [-34.7922, 149.6747],
  [-34.8022, 149.6743],
  [-34.8071, 149.6727],
  [-34.8336, 149.6567],
  [-34.8358, 149.6556],
  [-34.8530, 149.6505],
  [-34.8742, 149.6442],
  [-34.8782, 149.6438],
  [-34.8922, 149.6444],
  [-34.9999, 149.6488],
  [-35.0053, 149.6481],
  [-35.0085, 149.6471],
  [-35.0109, 149.6469],
  [-35.0138, 149.6476],
  [-35.0170, 149.6491],
  [-35.0202, 149.6499],
  [-35.0283, 149.6502],
  [-35.0298, 149.6502],
  [-35.0324, 149.6499],
  [-35.0351, 149.6500],
  [-35.0371, 149.6505],
  [-35.0398, 149.6515],
  [-35.0429, 149.6519],
  [-35.0485, 149.6507],
  [-35.0509, 149.6509],
  [-35.0610, 149.6546],
  [-35.0630, 149.6549],
  [-35.0652, 149.6544],
  [-35.0700, 149.6512]
];

const taragoBungendore = [
  [-35.0700, 149.6512],
  [-35.0821, 149.6427],
  [-35.0833, 149.6412],
  [-35.0862, 149.6350],
  [-35.0872, 149.6340],
  [-35.0881, 149.6334],
  [-35.0951, 149.6299],
  [-35.0961, 149.6291],
  [-35.0967, 149.6277],
  [-35.0969, 149.6263],
  [-35.0972, 149.6255],
  [-35.0977, 149.6247],
  [-35.1010, 149.6213],
  [-35.1021, 149.6201],
  [-35.1027, 149.6189],
  [-35.1032, 149.6183],
  [-35.1038, 149.6179],
  [-35.1079, 149.6169],
  [-35.1092, 149.6163],
  [-35.1132, 149.6140],
  [-35.1141, 149.6139],
  [-35.1151, 149.6144],
  [-35.1160, 149.6145],
  [-35.1168, 149.6142],
  [-35.1176, 149.6135],
  [-35.1184, 149.6123],
  [-35.1194, 149.6116],
  [-35.1209, 149.6111],
  [-35.1232, 149.6111],
  [-35.1243, 149.6109],
  [-35.1257, 149.6104],
  [-35.1271, 149.6103],
  [-35.1525, 149.6118],
  [-35.1546, 149.6117],
  [-35.1569, 149.6112],
  [-35.1590, 149.6103],
  [-35.1623, 149.6078],
  [-35.1673, 149.6018],
  [-35.1776, 149.5897],
  [-35.1900, 149.5751],
  [-35.1908, 149.5733],
  [-35.1909, 149.5712],
  [-35.1900, 149.5688],
  [-35.1892, 149.5659],
  [-35.1888, 149.5605],
  [-35.1895, 149.5563],
  [-35.1907, 149.5522],
  [-35.1911, 149.5497],
  [-35.1913, 149.5449],
  [-35.1919, 149.5420],
  [-35.1932, 149.5392],
  [-35.1947, 149.5374],
  [-35.1966, 149.5360],
  [-35.2038, 149.5329],
  [-35.2054, 149.5317],
  [-35.2068, 149.5293],
  [-35.2074, 149.5263],
  [-35.2071, 149.5234],
  [-35.2072, 149.5213],
  [-35.2081, 149.5187],
  [-35.2159, 149.5077],
  [-35.2177, 149.5035],
  [-35.2195, 149.4954],
  [-35.2215, 149.4904],
  [-35.2329, 149.4744],
  [-35.2355, 149.4723],
  [-35.2380, 149.4714],
  [-35.2422, 149.4674],
  [-35.2521, 149.4483],
  [-35.2530, 149.4473],
  [-35.2538, 149.4469],
  [-35.2559, 149.4464]
];

const bungendoreBrooksBankTunnel = [
  [-35.2559, 149.4464],
  [-35.2610, 149.4454],
  [-35.2636, 149.4447],
  [-35.2949, 149.4312],
  [-35.2975, 149.4297],
  [-35.3002, 149.4277],
  [-35.3010, 149.4272],
  [-35.3134, 149.4164],
  [-35.3146, 149.4145],
  [-35.3152, 149.4115],
  [-35.3165, 149.4080],
  [-35.3199, 149.4014],
  [-35.3206, 149.4007],
  [-35.3216, 149.4004],
  [-35.3229, 149.4007],
  [-35.3239, 149.4004],
  [-35.3245, 149.3998]
];

const brooksBankTunnel = [
  [-35.3245, 149.3998],
  [-35.3260, 149.3972]
];

const brooksBankTunnelPineRange = [
  [-35.3260, 149.3972],
  [-35.3273, 149.3947],
  [-35.3289, 149.3929],
  [-35.3301, 149.3918],
  [-35.3311, 149.3905],
  [-35.3317, 149.3888],
  [-35.3320, 149.3871],
  [-35.3326, 149.3855],
  [-35.3375, 149.3762],
  [-35.3384, 149.3736],
  [-35.3396, 149.3680],
  [-35.3403, 149.3667],
  [-35.3414, 149.3656],
  [-35.3428, 149.3651],
  [-35.3465, 149.3654],
  [-35.3477, 149.3649],
  [-35.3484, 149.3639],
  [-35.3486, 149.3627],
  [-35.3484, 149.3611],
  [-35.3486, 149.3600],
  [-35.3491, 149.3591],
  [-35.3500, 149.3581],
  [-35.3507, 149.3568],
  [-35.3515, 149.3511],
  [-35.3513, 149.3494],
  [-35.3506, 149.3476],
  [-35.3447, 149.3405],
  [-35.3435, 149.3398],
  [-35.3421, 149.3394],
  [-35.3408, 149.3382],
  [-35.3400, 149.3367],
  [-35.3363, 149.3131],
  [-35.3361, 149.3102],
  [-35.3360, 149.3049],
  [-35.3354, 149.3035],
  [-35.3341, 149.3023],
  [-35.3335, 149.3015],
  [-35.3332, 149.3002],
  [-35.3333, 149.2970]
];

const pineRangeNo1Tunnel = [
  [-35.3333, 149.2970],
  [-35.3334, 149.2951]
];

const pineRange = [
  [-35.3334, 149.2951],
  [-35.3335, 149.2942],
  [-35.3337, 149.2930],
  [-35.3341, 149.2920],
  [-35.3380, 149.2847],
  [-35.3383, 149.2832],
  [-35.3382, 149.2811],
  [-35.3376, 149.2794],
  [-35.3369, 149.2781]
];

const pinrRangeNo2Tunnel = [
  [-35.3369, 149.2781],
  [-35.3362, 149.2766],
  [-35.3361, 149.2756]
];

const pineRangeQueanbeyan = [
  [-35.3361, 149.2756],
  [-35.3362, 149.2744],
  [-35.3361, 149.2735],
  [-35.3358, 149.2726],
  [-35.3356, 149.2719],
  [-35.3353, 149.2684],
  [-35.3349, 149.2674],
  [-35.3330, 149.2651],
  [-35.3326, 149.2644],
  [-35.3323, 149.2637],
  [-35.3317, 149.2629],
  [-35.3314, 149.2620],
  [-35.3314, 149.2609],
  [-35.3316, 149.2601],
  [-35.3321, 149.2591],
  [-35.3322, 149.2579],
  [-35.3318, 149.2568],
  [-35.3308, 149.2553],
  [-35.3305, 149.2545],
  [-35.3304, 149.2538],
  [-35.3304, 149.2529],
  [-35.3303, 149.2499],
  [-35.3305, 149.2485],
  [-35.3310, 149.2473],
  [-35.3317, 149.2465],
  [-35.3346, 149.2446],
  [-35.3362, 149.2427],
  [-35.3428, 149.2308],
  [-35.3431, 149.2298],
  [-35.3431, 149.2289],
  [-35.3415, 149.2207],
  [-35.3414, 149.2202]
];

const queanbeyanTuggeranong = [
  [-35.3415, 149.2207],
  [-35.3414, 149.2191],
  [-35.3417, 149.2178],
  [-35.3455, 149.2075],
  [-35.3462, 149.2061],
  [-35.3475, 149.2048],
  [-35.3783, 149.1847],
  [-35.4078, 149.1571],
  [-35.4102, 149.1535],
  [-35.4130, 149.1487],
  [-35.4165, 149.1459],
  [-35.4232, 149.1446],
  [-35.4254, 149.1432],
  [-35.4278, 149.1394],
  [-35.4288, 149.1385],
  [-35.4298, 149.1384],
  [-35.4325, 149.1393],
  [-35.4335, 149.1404],
  [-35.4338, 149.1418],
  [-35.4336, 149.1429],
  [-35.4333, 149.1435]
];

const tuggeranongMichelago = [
  [-35.4333, 149.1435],
  [-35.4329, 149.1442],
  [-35.4327, 149.1451],
  [-35.4329, 149.1463],
  [-35.4333, 149.1485],
  [-35.4331, 149.1515],
  [-35.4335, 149.1535],
  [-35.4346, 149.1549],
  [-35.4360, 149.1553],
  [-35.4374, 149.1548],
  [-35.4388, 149.1536],
  [-35.4405, 149.1530],
  [-35.4418, 149.1533],
  [-35.4429, 149.1531],
  [-35.4440, 149.1518],
  [-35.4441, 149.1505],
  [-35.4440, 149.1493],
  [-35.4442, 149.1481],
  [-35.4467, 149.1440],
  [-35.4480, 149.1433],
  [-35.4496, 149.1435],
  [-35.4509, 149.1430],
  [-35.4516, 149.1417],
  [-35.4518, 149.1381],
  [-35.4523, 149.1367],
  [-35.4536, 149.1356],
  [-35.4550, 149.1355],
  [-35.4563, 149.1361],
  [-35.4578, 149.1364],
  [-35.4590, 149.1362],
  [-35.4605, 149.1363],
  [-35.4623, 149.1376],
  [-35.4636, 149.1382],
  [-35.4664, 149.1383],
  [-35.4683, 149.1390],
  [-35.4701, 149.1402],
  [-35.4724, 149.1425],
  [-35.4762, 149.1451],
  [-35.4911, 149.1511],
  [-35.4936, 149.1517],
  [-35.5076, 149.1513],
  [-35.5103, 149.1505],
  [-35.5505, 149.1321],
  [-35.5553, 149.1316],
  [-35.5900, 149.1412],
  [-35.6084, 149.1516],
  [-35.6112, 149.1525],
  [-35.6140, 149.1529],
  [-35.6162, 149.1542],
  [-35.6208, 149.1591],
  [-35.6255, 149.1623],
  [-35.6310, 149.1637],
  [-35.6565, 149.1645],
  [-35.6695, 149.1640],
  [-35.6714, 149.1648],
  [-35.6729, 149.1666],
  [-35.6744, 149.1687],
  [-35.6767, 149.1697],
  [-35.6827, 149.1688],
  [-35.6864, 149.1700],
  [-35.6882, 149.1714],
  [-35.6897, 149.1720],
  [-35.6913, 149.1717],
  [-35.7029, 149.1669],
  [-35.7060, 149.1662],
  [-35.7112, 149.1660]
];

const michealagoColintonTunnel = [
  [-35.7112, 149.1660],
  [-35.7267, 149.1656],
  [-35.7321, 149.1663],
  [-35.7578, 149.1724],
  [-35.7603, 149.1724],
  [-35.7624, 149.1715],
  [-35.7732, 149.1627],
  [-35.7760, 149.1611],
  [-35.7996, 149.1547],
  [-35.8020, 149.1551],
  [-35.8034, 149.1562],
  [-35.8047, 149.1589],
  [-35.8064, 149.1614],
  [-35.8106, 149.1660],
  [-35.8114, 149.1676],
  [-35.8117, 149.1688],
  [-35.8119, 149.1708],
  [-35.8130, 149.1738],
  [-35.8154, 149.1761],
  [-35.8175, 149.1769],
  [-35.8193, 149.1769],
  [-35.8216, 149.1761],
  [-35.8237, 149.1740],
  [-35.8252, 149.1714],
  [-35.8272, 149.1693],
  [-35.8340, 149.1636],
  [-35.8387, 149.1617],
  [-35.8534, 149.1589],
  [-35.8557, 149.1581],
  [-35.8729, 149.1487],
  [-35.8749, 149.1467],
  [-35.8764, 149.1446],
  [-35.8778, 149.1434],
  [-35.8800, 149.1427],
  [-35.8931, 149.1431],
  [-35.8990, 149.1449],
  [-35.9024, 149.1475]
];

const colintonTunnel = [
  [-35.9024, 149.1475],
  [-35.9035, 149.1487]
];

const colintonTunnelBredbo = [
  [-35.9035, 149.1487],
  [-35.9038, 149.1490],
  [-35.9056, 149.1514],
  [-35.9076, 149.1526],
  [-35.9098, 149.1527],
  [-35.9121, 149.1509],
  [-35.9135, 149.1480],
  [-35.9158, 149.1447],
  [-35.9191, 149.1429],
  [-35.9221, 149.1426],
  [-35.9458, 149.1471],
  [-35.9514, 149.1486]
];

const bredboCooma = [
  [-35.9514, 149.1486],
  [-35.9554, 149.1508],
  [-35.9782, 149.1646],
  [-35.9829, 149.1691],
  [-35.9844, 149.1707],
  [-35.9857, 149.1713],
  [-35.9877, 149.1718],
  [-35.9903, 149.1736],
  [-35.9943, 149.1762],
  [-35.9998, 149.1779],
  [-36.0044, 149.1780],
  [-36.0218, 149.1735],
  [-36.0246, 149.1733],
  [-36.0376, 149.1741],
  [-36.0394, 149.1746],
  [-36.0426, 149.1762],
  [-36.0459, 149.1767],
  [-36.0504, 149.1756],
  [-36.0540, 149.1755],
  [-36.0637, 149.1777],
  [-36.0924, 149.1825],
  [-36.0954, 149.1836],
  [-36.0991, 149.1855],
  [-36.1028, 149.1865],
  [-36.1159, 149.1866],
  [-36.1201, 149.1860],
  [-36.1271, 149.1831],
  [-36.1314, 149.1803],
  [-36.1354, 149.1788],
  [-36.1458, 149.1766],
  [-36.1501, 149.1747],
  [-36.1556, 149.1713],
  [-36.1584, 149.1682],
  [-36.1605, 149.1651],
  [-36.1636, 149.1629],
  [-36.1716, 149.1611],
  [-36.1736, 149.1610],
  [-36.1888, 149.1627],
  [-36.1950, 149.1617],
  [-36.1977, 149.1623],
  [-36.2000, 149.1621],
  [-36.2040, 149.1603],
  [-36.2065, 149.1602],
  [-36.2118, 149.1581],
  [-36.2144, 149.1557],
  [-36.2192, 149.1487],
  [-36.2198, 149.1464],
  [-36.2192, 149.1400],
  [-36.2184, 149.1369],
  [-36.2186, 149.1356],
  [-36.2193, 149.1344],
  [-36.2202, 149.1338],
  [-36.2209, 149.1338],
  [-36.2266, 149.1362],
  [-36.2289, 149.1365],
  [-36.2364, 149.1354]
];

const coomaNimmitabel = [
  [-36.2364, 149.1354],
  [-36.2376, 149.1352],
  [-36.2387, 149.1354],
  [-36.2409, 149.1369],
  [-36.2422, 149.1371],
  [-36.2434, 149.1365],
  [-36.2447, 149.1345],
  [-36.2457, 149.1336],
  [-36.2474, 149.1336],
  [-36.2488, 149.1338],
  [-36.2505, 149.1331],
  [-36.2514, 149.1331],
  [-36.2533, 149.1337],
  [-36.2569, 149.1345],
  [-36.2583, 149.1352],
  [-36.2594, 149.1364],
  [-36.2598, 149.1380],
  [-36.2594, 149.1421],
  [-36.2596, 149.1438],
  [-36.2605, 149.1459],
  [-36.2610, 149.1486],
  [-36.2623, 149.1515],
  [-36.2635, 149.1531],
  [-36.2650, 149.1543],
  [-36.2719, 149.1600],
  [-36.2727, 149.1613],
  [-36.2742, 149.1639],
  [-36.2753, 149.1653],
  [-36.2825, 149.1719],
  [-36.2841, 149.1727],
  [-36.2859, 149.1733],
  [-36.2880, 149.1748],
  [-36.2901, 149.1777],
  [-36.2927, 149.1795],
  [-36.3253, 149.1884],
  [-36.3381, 149.1934],
  [-36.3405, 149.1961],
  [-36.3428, 149.2018],
  [-36.3437, 149.2027],
  [-36.3451, 149.2030],
  [-36.3462, 149.2026],
  [-36.3475, 149.2010],
  [-36.3486, 149.2000],
  [-36.3651, 149.1948],
  [-36.3673, 149.1945],
  [-36.3796, 149.1958],
  [-36.3810, 149.1955],
  [-36.3822, 149.1946],
  [-36.3852, 149.1916],
  [-36.3865, 149.1911],
  [-36.3880, 149.1913],
  [-36.3903, 149.1922],
  [-36.3962, 149.1930],
  [-36.3978, 149.1929],
  [-36.4033, 149.1915],
  [-36.4045, 149.1906],
  [-36.4108, 149.1844],
  [-36.4121, 149.1839],
  [-36.4135, 149.1843],
  [-36.4152, 149.1859],
  [-36.4162, 149.1863],
  [-36.4174, 149.1863],
  [-36.4254, 149.1845],
  [-36.4267, 149.1846],
  [-36.4293, 149.1856],
  [-36.4305, 149.1867],
  [-36.4317, 149.1884],
  [-36.4328, 149.1892],
  [-36.4363, 149.1905],
  [-36.4384, 149.1920],
  [-36.4422, 149.1965],
  [-36.4430, 149.1986],
  [-36.4431, 149.2007],
  [-36.4435, 149.2023],
  [-36.4446, 149.2040],
  [-36.4451, 149.2056],
  [-36.4450, 149.2067],
  [-36.4442, 149.2086],
  [-36.4439, 149.2102],
  [-36.4440, 149.2121],
  [-36.4438, 149.2136],
  [-36.4432, 149.2149],
  [-36.4431, 149.2164],
  [-36.4436, 149.2179],
  [-36.4446, 149.2189],
  [-36.4468, 149.2193],
  [-36.4479, 149.2202],
  [-36.4486, 149.2213],
  [-36.4491, 149.2239],
  [-36.4501, 149.2256],
  [-36.4557, 149.2306],
  [-36.4572, 149.2339],
  [-36.4575, 149.2351],
  [-36.4573, 149.2369],
  [-36.4575, 149.2384],
  [-36.4605, 149.2436],
  [-36.4617, 149.2446],
  [-36.4634, 149.2452],
  [-36.4650, 149.2469],
  [-36.4687, 149.2541],
  [-36.4691, 149.2563],
  [-36.4695, 149.2575],
  [-36.4706, 149.2588],
  [-36.4711, 149.2602],
  [-36.4717, 149.2622],
  [-36.4721, 149.2644],
  [-36.4730, 149.2653],
  [-36.4745, 149.2664],
  [-36.4751, 149.2677],
  [-36.4754, 149.2695],
  [-36.4763, 149.2709],
  [-36.4772, 149.2712],
  [-36.4786, 149.2712],
  [-36.4796, 149.2716],
  [-36.4806, 149.2724],
  [-36.4814, 149.2727],
  [-36.4824, 149.2726],
  [-36.4854, 149.2713],
  [-36.4870, 149.2712],
  [-36.4890, 149.2720],
  [-36.4973, 149.2812],
  [-36.4996, 149.2818],
  [-36.5067, 149.2796]
];

const nimmitabelBombala = [
  [-36.5067, 149.2796],
  [-36.5114, 149.2783],
  [-36.5127, 149.2785],
  [-36.5155, 149.2804],
  [-36.5166, 149.2809],
  [-36.5227, 149.2816],
  [-36.5284, 149.2811],
  [-36.5296, 149.2802],
  [-36.5304, 149.2788],
  [-36.5317, 149.2779],
  [-36.5332, 149.2783],
  [-36.5341, 149.2797],
  [-36.5341, 149.2816],
  [-36.5331, 149.2849],
  [-36.5332, 149.2870],
  [-36.5338, 149.2880],
  [-36.5351, 149.2883],
  [-36.5372, 149.2878],
  [-36.5381, 149.2866],
  [-36.5393, 149.2844],
  [-36.5402, 149.2835],
  [-36.5444, 149.2819],
  [-36.5455, 149.2819],
  [-36.5514, 149.2832],
  [-36.5527, 149.2828],
  [-36.5538, 149.2819],
  [-36.5547, 149.2818],
  [-36.5577, 149.2825],
  [-36.5589, 149.2837],
  [-36.5594, 149.2857],
  [-36.5587, 149.2876],
  [-36.5563, 149.2894],
  [-36.5555, 149.2916],
  [-36.5563, 149.2935],
  [-36.5601, 149.2960],
  [-36.5618, 149.2961],
  [-36.5627, 149.2949],
  [-36.5634, 149.2925],
  [-36.5631, 149.2894],
  [-36.5634, 149.2876],
  [-36.5653, 149.2860],
  [-36.5660, 149.2848],
  [-36.5659, 149.2833],
  [-36.5656, 149.2814],
  [-36.5662, 149.2796],
  [-36.5674, 149.2791],
  [-36.5688, 149.2795],
  [-36.5697, 149.2816],
  [-36.5715, 149.2826],
  [-36.5729, 149.2819],
  [-36.5740, 149.2804],
  [-36.5749, 149.2800],
  [-36.5759, 149.2802],
  [-36.5774, 149.2816],
  [-36.5786, 149.2822],
  [-36.5829, 149.2829],
  [-36.5865, 149.2820],
  [-36.5925, 149.2818],
  [-36.5940, 149.2820],
  [-36.5978, 149.2835],
  [-36.5990, 149.2837],
  [-36.6036, 149.2826],
  [-36.6047, 149.2817],
  [-36.6056, 149.2802],
  [-36.6073, 149.2793],
  [-36.6086, 149.2798],
  [-36.6099, 149.2819],
  [-36.6111, 149.2829],
  [-36.6216, 149.2862],
  [-36.6233, 149.2872],
  [-36.6270, 149.2873],
  [-36.6323, 149.2894],
  [-36.6345, 149.2895],
  [-36.6386, 149.2864],
  [-36.6398, 149.2835],
  [-36.6397, 149.2795],
  [-36.6410, 149.2727],
  [-36.6419, 149.2712],
  [-36.6532, 149.2638],
  [-36.6540, 149.2636],
  [-36.6549, 149.2638],
  [-36.6574, 149.2661],
  [-36.6584, 149.2682],
  [-36.6591, 149.2689],
  [-36.6607, 149.2695],
  [-36.6615, 149.2703],
  [-36.6622, 149.2720],
  [-36.6621, 149.2736],
  [-36.6617, 149.2751],
  [-36.6618, 149.2767],
  [-36.6632, 149.2799],
  [-36.6646, 149.2808],
  [-36.6657, 149.2807],
  [-36.6672, 149.2798],
  [-36.6684, 149.2799],
  [-36.6701, 149.2810],
  [-36.6728, 149.2816],
  [-36.6739, 149.2811],
  [-36.6748, 149.2799],
  [-36.6749, 149.2784],
  [-36.6742, 149.2763],
  [-36.6742, 149.2750],
  [-36.6746, 149.2730],
  [-36.6747, 149.2717],
  [-36.6734, 149.2640],
  [-36.6725, 149.2615],
  [-36.6724, 149.2594],
  [-36.6733, 149.2527],
  [-36.6732, 149.2497],
  [-36.6739, 149.2480],
  [-36.6773, 149.2443],
  [-36.6841, 149.2335],
  [-36.6846, 149.2322],
  [-36.6849, 149.2303],
  [-36.6857, 149.2287],
  [-36.6868, 149.2279],
  [-36.6896, 149.2224],
  [-36.6908, 149.2212],
  [-36.6933, 149.2202],
  [-36.6957, 149.2201],
  [-36.7100, 149.2147],
  [-36.7118, 149.2146],
  [-36.7284, 149.2176],
  [-36.7294, 149.2183],
  [-36.7334, 149.2229],
  [-36.7348, 149.2234],
  [-36.7367, 149.2234],
  [-36.7380, 149.2239],
  [-36.7392, 149.2240],
  [-36.7410, 149.2230],
  [-36.7415, 149.2218],
  [-36.7418, 149.2196],
  [-36.7431, 149.2178],
  [-36.7449, 149.2180],
  [-36.7472, 149.2208],
  [-36.7486, 149.2221],
  [-36.7517, 149.2265],
  [-36.7566, 149.2292],
  [-36.7596, 149.2294],
  [-36.7607, 149.2296],
  [-36.7616, 149.2303],
  [-36.7640, 149.2349],
  [-36.7655, 149.2363],
  [-36.7679, 149.2370],
  [-36.7691, 149.2369],
  [-36.7707, 149.2360],
  [-36.7715, 149.2359],
  [-36.7728, 149.2361],
  [-36.7745, 149.2357],
  [-36.7757, 149.2361],
  [-36.7764, 149.2368],
  [-36.7772, 149.2382],
  [-36.7782, 149.2390],
  [-36.7792, 149.2391],
  [-36.7808, 149.2387],
  [-36.7820, 149.2387],
  [-36.7837, 149.2394],
  [-36.7852, 149.2394],
  [-36.7868, 149.2381],
  [-36.7879, 149.2378],
  [-36.7954, 149.2378],
  [-36.7969, 149.2373],
  [-36.7982, 149.2362],
  [-36.7993, 149.2357],
  [-36.8069, 149.2342],
  [-36.8103, 149.2332],
  [-36.8112, 149.2321],
  [-36.8118, 149.2305],
  [-36.8127, 149.2294],
  [-36.8153, 149.2278],
  [-36.8161, 149.2277],
  [-36.8177, 149.2278],
  [-36.8187, 149.2275],
  [-36.8194, 149.2268],
  [-36.8213, 149.2229],
  [-36.8225, 149.2215],
  [-36.8252, 149.2200],
  [-36.8261, 149.2190],
  [-36.8269, 149.2173],
  [-36.8278, 149.2165],
  [-36.8286, 149.2163],
  [-36.8304, 149.2168],
  [-36.8316, 149.2164],
  [-36.8329, 149.2152],
  [-36.8336, 149.2148],
  [-36.8343, 149.2148],
  [-36.8359, 149.2153],
  [-36.8368, 149.2153],
  [-36.8376, 149.2147],
  [-36.8404, 149.2108],
  [-36.8414, 149.2100],
  [-36.8431, 149.2092],
  [-36.8440, 149.2091],
  [-36.8448, 149.2094],
  [-36.8462, 149.2107],
  [-36.8475, 149.2112],
  [-36.8515, 149.2103],
  [-36.8523, 149.2102],
  [-36.8537, 149.2105],
  [-36.8544, 149.2105],
  [-36.8559, 149.2102],
  [-36.8569, 149.2103],
  [-36.8580, 149.2100],
  [-36.8586, 149.2092],
  [-36.8599, 149.2087],
  [-36.8610, 149.2089],
  [-36.8621, 149.2099],
  [-36.8629, 149.2103],
  [-36.8637, 149.2103],
  [-36.8671, 149.2090],
  [-36.8681, 149.2089],
  [-36.8689, 149.2093],
  [-36.8713, 149.2107],
  [-36.8722, 149.2103],
  [-36.8737, 149.2086],
  [-36.8748, 149.2083],
  [-36.8760, 149.2087],
  [-36.8777, 149.2104],
  [-36.8800, 149.2117],
  [-36.8811, 149.2117],
  [-36.8828, 149.2110],
  [-36.8840, 149.2112],
  [-36.8849, 149.2119],
  [-36.8858, 149.2135],
  [-36.8871, 149.2147],
  [-36.8883, 149.2168],
  [-36.8895, 149.2175],
  [-36.8902, 149.2185],
  [-36.8916, 149.2235],
  [-36.8915, 149.2248],
  [-36.8910, 149.2258],
  [-36.8887, 149.2286],
  [-36.8881, 149.2303],
  [-36.8883, 149.2321],
  [-36.8891, 149.2332],
  [-36.8905, 149.2337],
  [-36.8919, 149.2335],
  [-36.8927, 149.2337],
  [-36.8967, 149.2359],
  [-36.8982, 149.2360],
  [-36.8991, 149.2365],
  [-36.9003, 149.2382],
  [-36.9029, 149.2411],
  [-36.9041, 149.2416],
  [-36.9053, 149.2412],
  [-36.9098, 149.2348]
];

export const bombala: Line = {
  name: 'Bombala',
  state: 'NSW',
  segments: [
    {
      segments: [joppaJunctionTarago],
      history: {
        opened: {
          date: '1884-01-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [taragoBungendore],
      history: {
        opened: {
          date: '1885-03-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        bungendoreBrooksBankTunnel,
        {
          name: 'Brooks Bank Tunnel',
          segment: brooksBankTunnel,
          type: 'tunnel'
        },
        brooksBankTunnelPineRange,
        {
          name: 'Pine Range No 2 Tunnel',
          segment: pineRangeNo1Tunnel,
          type: 'tunnel'
        },
        pineRange,
        {
          name: 'Pine Range No 2 Tunnel',
          segment: pinrRangeNo2Tunnel,
          type: 'tunnel'
        },
        pineRangeQueanbeyan
      ],
      history: {
        opened: {
          date: '1887-09-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [queanbeyanTuggeranong],
      history: {
        opened: {
          date: '1887-12-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-03-03',
          status: 'closed'
        }, {
          date: '2007-01-01',
          status: 'closed'
        }, {
          date: '1993-04-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [tuggeranongMichelago],
      history: {
        opened: {
          date: '1887-12-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-03-03',
          status: 'closed'
        }, {
          date: '2007-01-01',
          status: 'closed'
        }, {
          date: '1993-04-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [
        michealagoColintonTunnel,
        {
          name: 'Colinton Tunnel',
          segment: colintonTunnel,
          type: 'tunnel'
        },
        colintonTunnelBredbo
      ],
      history: {
        opened: {
          date: '1889-05-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-03-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bredboCooma],
      history: {
        opened: {
          date: '1889-05-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-05-17',
          status: 'closed'
        }, {
          date: '2014-01-01',
          status: 'closed'
        }, {
          date: '1998-12-05',
          status: 'open'
        }]
      }
    },
    {
      segments: [coomaNimmitabel],
      history: {
        opened: {
          date: '1912-04-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-03-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nimmitabelBombala],
      history: {
        opened: {
          date: '1921-11-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-03-26',
          status: 'closed'
        }]
      }
    }
  ]
};
