import { Line } from "../../../trackTypes";

const dargaville = [
  [-35.9290, 173.8450],
  [-35.9323, 173.8474],
  [-35.9333, 173.8484],
  [-35.9387, 173.8554],
  [-35.9394, 173.8572],
  [-35.9408, 173.8663],
  [-35.9411, 173.8674]
];

const dargavilleOpanake = [
  [-35.9290, 173.8450],
  [-35.9265, 173.8433],
  [-35.9194, 173.8354],
  [-35.9147, 173.8313],
  [-35.9131, 173.8284],
  [-35.9122, 173.8250],
  [-35.9093, 173.8171],
  [-35.9073, 173.8140],
  [-35.9050, 173.8119],
  [-35.9006, 173.8104],
  [-35.8977, 173.8100],
  [-35.8968, 173.8094],
  [-35.8963, 173.8084],
  [-35.8961, 173.8074],
  [-35.8958, 173.8066],
  [-35.8949, 173.8053],
  [-35.8939, 173.8042],
  [-35.8937, 173.8032],
  [-35.8931, 173.8021],
  [-35.8921, 173.8012],
  [-35.8860, 173.7981],
  [-35.8810, 173.7971],
  [-35.8802, 173.7965],
  [-35.8798, 173.7955],
  [-35.8792, 173.7931],
  [-35.8786, 173.7920],
  [-35.8774, 173.7902],
  [-35.8768, 173.7891],
  [-35.8766, 173.7879],
  [-35.8735, 173.7810],
  [-35.8623, 173.7688],
  [-35.8558, 173.7615],
  [-35.8548, 173.7606],
  [-35.8469, 173.7579],
  [-35.8431, 173.7559],
  [-35.8420, 173.7555],
  [-35.8373, 173.7551],
  [-35.8359, 173.7546],
  [-35.8277, 173.7460],
  [-35.8263, 173.7453],
  [-35.8249, 173.7438],
  [-35.8166, 173.7395],
  [-35.8160, 173.7388],
  [-35.8148, 173.7355],
  [-35.8144, 173.7347],
  [-35.8118, 173.7321],
  [-35.8109, 173.7316],
  [-35.8080, 173.7307],
  [-35.7934, 173.7317],
  [-35.7922, 173.7314],
  [-35.7897, 173.7307],
  [-35.7890, 173.7302],
  [-35.7880, 173.7287]
];

const opanakeKaihu = [
  [-35.7880, 173.7287],
  [-35.7742, 173.7080],
  [-35.7711, 173.7034],
  [-35.7670, 173.6995]
];

const kaihuWhatoro = [
  [-35.7670, 173.6995],
  [-35.7661, 173.6987],
  [-35.7656, 173.6975],
  [-35.7648, 173.6927],
  [-35.7644, 173.6921],
  [-35.7639, 173.6918],
  [-35.7627, 173.6918],
  [-35.7622, 173.6915],
  [-35.7617, 173.6908],
  [-35.7610, 173.6878],
  [-35.7611, 173.6868],
  [-35.7616, 173.6855],
  [-35.7617, 173.6844],
  [-35.7614, 173.6836],
  [-35.7607, 173.6831],
  [-35.7603, 173.6824],
  [-35.7599, 173.6813],
  [-35.7592, 173.6800],
  [-35.7586, 173.6790],
  [-35.7572, 173.6781],
  [-35.7563, 173.6779],
  [-35.7545, 173.6781],
  [-35.7531, 173.6787],
  [-35.7525, 173.6786],
  [-35.7521, 173.6782],
  [-35.7515, 173.6762],
  [-35.7515, 173.6749]
];

const whatoroDonnellysCrossing = [
  [-35.7515, 173.6749],
  [-35.7512, 173.6723],
  [-35.7517, 173.6712],
  [-35.7524, 173.6705],
  [-35.7528, 173.6698],
  [-35.7530, 173.6680],
  [-35.7534, 173.6673],
  [-35.7540, 173.6665],
  [-35.7544, 173.6626],
  [-35.7542, 173.6616],
  [-35.7536, 173.6609],
  [-35.7503, 173.6593],
  [-35.7498, 173.6587],
  [-35.7496, 173.6580],
  [-35.7496, 173.6569],
  [-35.7494, 173.6561],
  [-35.7488, 173.6552],
  [-35.7479, 173.6550],
  [-35.7475, 173.6549],
  [-35.7472, 173.6550],
  [-35.7469, 173.6550],
  [-35.7466, 173.6546],
  [-35.7454, 173.6527],
  [-35.7449, 173.6514],
  [-35.7446, 173.6509],
  [-35.7437, 173.6502],
  [-35.7434, 173.6498],
  [-35.7433, 173.6492],
  [-35.7433, 173.6485],
  [-35.7430, 173.6479],
  [-35.7425, 173.6474],
  [-35.7423, 173.6470],
  [-35.7423, 173.6467],
  [-35.7422, 173.6456],
  [-35.7421, 173.6450],
  [-35.7416, 173.6443],
  [-35.7414, 173.6438],
  [-35.7414, 173.6426],
  [-35.7413, 173.6416],
  [-35.7410, 173.6408],
  [-35.7398, 173.6397],
  [-35.7392, 173.6389],
  [-35.7388, 173.6354],
  [-35.7385, 173.6338],
  [-35.7381, 173.6328],
  [-35.7364, 173.6312],
  [-35.7340, 173.6300],
  [-35.7332, 173.6300],
  [-35.7324, 173.6306],
  [-35.7314, 173.6307],
  [-35.7303, 173.6300],
  [-35.7295, 173.6291],
  [-35.7291, 173.6281],
  [-35.7290, 173.6269],
  [-35.7267, 173.6241],
  [-35.7264, 173.6233],
  [-35.7261, 173.6215],
  [-35.7250, 173.6197],
  [-35.7162, 173.6156],
  [-35.7152, 173.6159],
  [-35.7147, 173.6167],
  [-35.7141, 173.6180],
  [-35.7137, 173.6185],
  [-35.7131, 173.6185],
  [-35.7106, 173.6183]
];

export const donnellysCrossing: Line = {
  name: 'Donnellys Crossing',
  state: 'NZ',
  segments: [
    {
      segments: [dargaville],
      history: {
        opened: {
          date: '1889-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dargavilleOpanake],
      history: {
        opened: {
          date: '1889-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [opanakeKaihu],
      history: {
        opened: {
          date: '1896-10-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kaihuWhatoro],
      history: {
        opened: {
          date: '1914-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [whatoroDonnellysCrossing],
      history: {
        opened: {
          date: '1923-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-19',
          status: 'closed'
        }]
      }
    }
  ]
}
