import { Line } from "../../trackTypes";

const camurraNorthStar = [
  [-29.3992, 149.9161],
  [-29.3984, 149.9152],
  [-29.3978, 149.9149],
  [-29.3965, 149.9151],
  [-29.3955, 149.9160],
  [-29.3952, 149.9176],
  [-29.4040, 149.9895],
  [-29.4035, 149.9972],
  [-29.3522, 150.1995],
  [-29.3504, 150.2031],
  [-29.3480, 150.2056],
  [-29.2718, 150.2510],
  [-29.2572, 150.2595],
  [-29.2031, 150.2732],
  [-29.1233, 150.3078],
  [-29.1130, 150.3111],
  [-29.1107, 150.3112],
  [-29.0739, 150.3069],
  [-29.0706, 150.3073],
  [-29.0316, 150.3263],
  [-29.0240, 150.3341],
  [-29.0221, 150.3349],
  [-29.0181, 150.3353],
  [-29.0167, 150.3347],
  [-29.0147, 150.3330],
  [-29.0137, 150.3324],
  [-29.0124, 150.3321],
  [-29.0015, 150.3333],
  [-28.9959, 150.3331],
  [-28.9916, 150.3341],
  [-28.9885, 150.3356],
  [-28.9777, 150.3430],
  [-28.9747, 150.3455],
  [-28.9416, 150.3827],
  [-28.9362, 150.3886],
  [-28.9326, 150.3905],
  [-28.9124, 150.3940]
];

const northStarBoggabilla = [
  [-28.9124, 150.3940],
  [-28.8787, 150.3998],
  [-28.8670, 150.4029],
  [-28.8352, 150.4058],
  [-28.8155, 150.4072],
  [-28.7992, 150.4101],
  [-28.7773, 150.4094],
  [-28.7327, 150.4194],
  [-28.7301, 150.4192],
  [-28.7127, 150.4153],
  [-28.6768, 150.4141],
  [-28.6740, 150.4130],
  [-28.6716, 150.4106],
  [-28.6520, 150.3828],
  [-28.6207, 150.3556],
  [-28.6173, 150.3542],
  [-28.6084, 150.3530]
];

export const boggabilla: Line = {
  name: 'Boggabilla',
  state: 'NSW',
  segments: [
    {
      segments: [camurraNorthStar],
      history: {
        opened: {
          date: '1932-06-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [northStarBoggabilla],
      history: {
        opened: {
          date: '1932-06-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-12-02',
          status: 'closed'
        }]
      }
    }
  ]
};
