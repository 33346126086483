import { Line } from "../../../trackTypes";

const bowenHillsNewmarket = [
  [-27.4402, 153.0389],
  [-27.4394, 153.0387],
  [-27.4388, 153.0383],
  [-27.4371, 153.0359],
  [-27.4351, 153.0338],
  [-27.4346, 153.0330],
  [-27.4344, 153.0319],
  [-27.4345, 153.0308],
  [-27.4360, 153.0270],
  [-27.4362, 153.0256],
  [-27.4359, 153.0227],
  [-27.4359, 153.0217],
  [-27.4362, 153.0195],
  [-27.4353, 153.0132],
  [-27.4350, 153.0124],
  [-27.4346, 153.0117],
  [-27.4339, 153.0112],
  [-27.4329, 153.0106]
];

const newmarketEnoggera = [
  [-27.4329, 153.0106],
  [-27.4308, 153.0094],
  [-27.4299, 153.0086],
  [-27.4256, 153.0029],
  [-27.4247, 153.0019],
  [-27.4229, 153.0006],
  [-27.4223, 153.0000],
  [-27.4220, 152.9994],
  [-27.4218, 152.9988],
  [-27.4213, 152.9951],
  [-27.4209, 152.9939],
  [-27.4203, 152.9924]
];

const enoggeraGaythorne = [
  [-27.4203, 152.9924],
  [-27.4198, 152.9912],
  [-27.4195, 152.9904],
  [-27.4192, 152.9882],
  [-27.4192, 152.9874],
  [-27.4194, 152.9864],
  [-27.4194, 152.9857],
  [-27.4192, 152.9850]
];

const gaythorneMitchelton = [
  [-27.4192, 152.9850],
  [-27.4189, 152.9845],
  [-27.4183, 152.9838],
  [-27.4159, 152.9828],
  [-27.4149, 152.9821],
  [-27.4143, 152.9813],
  [-27.4115, 152.9757]
];

const mitcheltonFernyGrove = [
  [-27.4115, 152.9757],
  [-27.4110, 152.9749],
  [-27.4102, 152.9741],
  [-27.4054, 152.9705],
  [-27.4049, 152.9700],
  [-27.4046, 152.9694],
  [-27.4042, 152.9683],
  [-27.4035, 152.9644],
  [-27.4036, 152.9637],
  [-27.4038, 152.9632],
  [-27.4041, 152.9628],
  [-27.4061, 152.9614],
  [-27.4073, 152.9600],
  [-27.4117, 152.9523],
  [-27.4119, 152.9517],
  [-27.4119, 152.9507],
  [-27.4111, 152.9489],
  [-27.4106, 152.9483],
  [-27.4097, 152.9477],
  [-27.4091, 152.9470],
  [-27.4080, 152.9442],
  [-27.4073, 152.9429],
  [-27.4009, 152.9345]
];

const fernyGroveSamford = [
  [-27.4009, 152.9345],
  [-27.3986, 152.9316],
  [-27.3941, 152.9267],
  [-27.3939, 152.9261],
  [-27.3939, 152.9257],
  [-27.3946, 152.9233],
  [-27.3946, 152.9225],
  [-27.3943, 152.9205],
  [-27.3942, 152.9194],
  [-27.3946, 152.9178],
  [-27.3945, 152.9170],
  [-27.3942, 152.9158],
  [-27.3942, 152.9148],
  [-27.3945, 152.9137],
  [-27.3949, 152.9131],
  [-27.3958, 152.9120],
  [-27.3974, 152.9083],
  [-27.3978, 152.9078],
  [-27.3993, 152.9070],
  [-27.3997, 152.9070],
  [-27.4004, 152.9072],
  [-27.4011, 152.9070],
  [-27.4016, 152.9064],
  [-27.4015, 152.9056],
  [-27.4009, 152.9047],
  [-27.4007, 152.9039],
  [-27.4008, 152.9027],
  [-27.4012, 152.9023],
  [-27.4022, 152.9020],
  [-27.4026, 152.9015],
  [-27.4027, 152.9009],
  [-27.4024, 152.8990],
  [-27.4020, 152.8983],
  [-27.4013, 152.8979],
  [-27.3978, 152.8972],
  [-27.3972, 152.8967],
  [-27.3971, 152.8960],
  [-27.3974, 152.8948],
  [-27.3974, 152.8941],
  [-27.3969, 152.8919],
  [-27.3964, 152.8914],
  [-27.3959, 152.8911],
  [-27.3953, 152.8911],
  [-27.3939, 152.8917],
  [-27.3933, 152.8918],
  [-27.3909, 152.8914],
  [-27.3901, 152.8915],
  [-27.3862, 152.8926],
  [-27.3855, 152.8926],
  [-27.3824, 152.8913],
  [-27.3780, 152.8904],
  [-27.3772, 152.8904],
  [-27.3755, 152.8904],
  [-27.3744, 152.8901],
  [-27.3727, 152.8891],
  [-27.3698, 152.8863]
];

const samfordYugarTunnel = [
  [-27.3698, 152.8863],
  [-27.3675, 152.8848],
  [-27.3662, 152.8843],
  [-27.3658, 152.8843],
  [-27.3653, 152.8846],
  [-27.3649, 152.8846],
  [-27.3634, 152.8841],
  [-27.3631, 152.8838],
  [-27.3630, 152.8834],
  [-27.3631, 152.8829],
  [-27.3630, 152.8825],
  [-27.3627, 152.8821],
  [-27.3611, 152.8807],
  [-27.3603, 152.8805],
  [-27.3588, 152.8804],
  [-27.3582, 152.8802],
  [-27.3573, 152.8796],
  [-27.3543, 152.8782],
  [-27.3511, 152.8765],
  [-27.3486, 152.8757],
  [-27.3455, 152.8742]
];

const yugarTunnel = [
  [-27.3455, 152.8742],
  [-27.3438, 152.8732]
];

const yugarTunnelSamsonvale = [
  [-27.3438, 152.8732],
  [-27.3435, 152.8731],
  [-27.3429, 152.8729],
  [-27.3395, 152.8727],
  [-27.3387, 152.8728],
  [-27.3364, 152.8740],
  [-27.3319, 152.8746],
  [-27.3303, 152.8743],
  [-27.3284, 152.8728],
  [-27.3272, 152.8714],
  [-27.3253, 152.8684],
  [-27.3251, 152.8673],
  [-27.3245, 152.8624],
  [-27.3242, 152.8619],
  [-27.3237, 152.8615],
  [-27.3230, 152.8614],
  [-27.3196, 152.8606],
  [-27.3174, 152.8600],
  [-27.3167, 152.8596],
  [-27.3158, 152.8587],
  [-27.3138, 152.8570],
  [-27.3124, 152.8564],
  [-27.3110, 152.8550],
  [-27.3100, 152.8544],
  [-27.3097, 152.8540],
  [-27.3093, 152.8531],
  [-27.3090, 152.8524],
  [-27.3073, 152.8514],
  [-27.3066, 152.8515],
  [-27.3055, 152.8526],
  [-27.3045, 152.8530],
  [-27.3036, 152.8530],
  [-27.2994, 152.8525],
  [-27.2985, 152.8528],
  [-27.2975, 152.8530],
  [-27.2960, 152.8530],
  [-27.2942, 152.8526],
  [-27.2936, 152.8527],
  [-27.2919, 152.8533],
  [-27.2912, 152.8534],
  [-27.2904, 152.8531],
  [-27.2887, 152.8521],
  [-27.2879, 152.8518],
  [-27.2863, 152.8521],
  [-27.2814, 152.8546],
  [-27.2791, 152.8550],
  [-27.2778, 152.8547],
  [-27.2767, 152.8537],
  [-27.2763, 152.8529]
];

const samsonvaleKobble = [
  [-27.2763, 152.8529],
  [-27.2747, 152.8504],
  [-27.2737, 152.8501],
  [-27.2694, 152.8512],
  [-27.2652, 152.8537],
  [-27.2645, 152.8540],
  [-27.2639, 152.8538],
  [-27.2625, 152.8530],
  [-27.2607, 152.8522],
  [-27.2600, 152.8521],
  [-27.2586, 152.8521],
  [-27.2576, 152.8524],
  [-27.2558, 152.8531],
  [-27.2551, 152.8528],
  [-27.2547, 152.8523],
  [-27.2543, 152.8509],
  [-27.2542, 152.8491],
  [-27.2538, 152.8483],
  [-27.2515, 152.8448],
  [-27.2490, 152.8433],
  [-27.2486, 152.8427],
  [-27.2485, 152.8418],
  [-27.2493, 152.8397],
  [-27.2494, 152.8387],
  [-27.2483, 152.8367],
  [-27.2463, 152.8346]
];

const kobbleDayboro = [
  [-27.2463, 152.8346],
  [-27.2447, 152.8337],
  [-27.2428, 152.8334],
  [-27.2407, 152.8325],
  [-27.2396, 152.8325],
  [-27.2371, 152.8324],
  [-27.2363, 152.8322],
  [-27.2359, 152.8318],
  [-27.2338, 152.8301],
  [-27.2331, 152.8300],
  [-27.2319, 152.8305],
  [-27.2312, 152.8313],
  [-27.2307, 152.8317],
  [-27.2297, 152.8321],
  [-27.2288, 152.8325],
  [-27.2280, 152.8324],
  [-27.2267, 152.8324],
  [-27.2222, 152.8321],
  [-27.2213, 152.8324],
  [-27.2203, 152.8331],
  [-27.2194, 152.8343],
  [-27.2186, 152.8347],
  [-27.2172, 152.8347],
  [-27.2148, 152.8335],
  [-27.2136, 152.8334],
  [-27.2093, 152.8339],
  [-27.2085, 152.8336],
  [-27.2079, 152.8329],
  [-27.2078, 152.8318],
  [-27.2074, 152.8308],
  [-27.2009, 152.8267],
  [-27.1976, 152.8251]
];

export const fernyGrove: Line = {
  name: 'Ferny Grove',
  state: 'QLD',
  segments: [
    {
      segments: [bowenHillsNewmarket],
      history: {
        opened: {
          date: '1899-02-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1921-01-01',
          tracks: 2
        }, {
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [newmarketEnoggera],
      history: {
        opened: {
          date: '1899-02-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-01-01',
          tracks: 2
        }, {
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [enoggeraGaythorne],
      history: {
        opened: {
          date: '1916-02-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-01-01',
          tracks: 2
        }, {
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gaythorneMitchelton],
      history: {
        opened: {
          date: '1918-03-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-01-01',
          tracks: 2
        }, {
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [mitcheltonFernyGrove],
      history: {
        opened: {
          date: '1918-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-04-01',
          tracks: 2
        }, {
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [fernyGroveSamford],
      history: {
        opened: {
          date: '1918-03-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        samfordYugarTunnel,
        {
          name: 'Yugar Tunnel',
          segment: yugarTunnel,
          type: 'tunnel'
        },
        yugarTunnelSamsonvale
      ],
      history: {
        opened: {
          date: '1919-03-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [samsonvaleKobble],
      history: {
        opened: {
          date: '1919-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kobbleDayboro],
      history: {
        opened: {
          date: '1920-09-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
