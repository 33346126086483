import { Line } from "../../trackTypes";

const line = [
  [-36.1224, 144.7534],
  [-36.1182, 144.7548],
  [-36.1167, 144.7555],
  [-36.1149, 144.7569],
  [-36.1136, 144.7585],
  [-36.1124, 144.7605],
  [-36.1082, 144.7698],
  [-36.1059, 144.7732],
  [-36.1027, 144.7761],
  [-36.0987, 144.7777],
  [-36.0274, 144.7905],
  [-36.0253, 144.7909],
  [-36.0219, 144.7923],
  [-36.0024, 144.8039],
  [-35.8339, 144.9038],
  [-35.8305, 144.9052],
  [-35.8267, 144.9055],
  [-35.8241, 144.9049],
  [-35.8212, 144.9036],
  [-35.7782, 144.8761],
  [-35.7744, 144.8749],
  [-35.7710, 144.8748],
  [-35.5634, 144.9120],
  [-35.5600, 144.9133],
  [-35.5571, 144.9156],
  [-35.5546, 144.9187],
  [-35.5296, 144.9596]
];

export const deniliquin: Line = {
  name: 'Deniliquin',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1876-07-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-08-06',
          status: 'closed'
        }, {
          date: '2013-01-01',
          status: 'open'
        }]
      }
    }
  ]
};
