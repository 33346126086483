import { Line } from "../../../trackTypes";

const blackwaterKoorilgahJunction = [
  [-23.5860, 148.8877],
  [-23.5863, 148.8867],
  [-23.5868, 148.8859],
  [-23.5877, 148.8852],
  [-23.5887, 148.8849],
  [-23.5913, 148.8850],
  [-23.5928, 148.8847],
  [-23.5942, 148.8841],
  [-23.5972, 148.8814],
  [-23.5995, 148.8800],
  [-23.6019, 148.8792],
  [-23.6190, 148.8773],
  [-23.6222, 148.8768],
  [-23.6438, 148.8742],
  [-23.6461, 148.8742],
  [-23.6670, 148.8771],
  [-23.6688, 148.8773],
  [-23.6857, 148.8764],
  [-23.6878, 148.8765],
  [-23.7034, 148.8794]
];

const koorilgahJunctionLeichardtColliery = [
  [-23.7034, 148.8794],
  [-23.7069, 148.8812],
  [-23.7128, 148.8825],
  [-23.7142, 148.8835],
  [-23.7147, 148.8850],
  [-23.7140, 148.8925],
  [-23.7132, 148.8997],
  [-23.7135, 148.9012],
  [-23.7145, 148.9023],
  [-23.7161, 148.9030],
  [-23.7178, 148.9029],
  [-23.7190, 148.9019],
  [-23.7196, 148.9003],
  [-23.7196, 148.8985],
  [-23.7191, 148.8972],
  [-23.7178, 148.8961],
  [-23.7159, 148.8957],
  [-23.7151, 148.8953],
  [-23.7147, 148.8948],
  [-23.7142, 148.8940],
  [-23.7140, 148.8925]
];

const koorilgahJunctionStewartonJunction = [
  [-23.7034, 148.8794],
  [-23.7426, 148.8876],
  [-23.7456, 148.8877],
  [-23.7705, 148.8813]
];

const stewartonJunctionLaleham = [
  [-23.7705, 148.8813],
  [-23.7930, 148.8753],
  [-23.8287, 148.8725],
  [-23.8823, 148.8593],
  [-23.8893, 148.8549],
  [-23.9036, 148.8399],
  [-23.9070, 148.8378],
  [-23.9159, 148.8348],
  [-23.9198, 148.8317],
  [-23.9243, 148.8257],
  [-23.9303, 148.8132],
  [-23.9310, 148.8111],
  [-23.9311, 148.8094],
  [-23.9306, 148.8078],
  [-23.9306, 148.8062],
  [-23.9316, 148.8045],
  [-23.9333, 148.8040],
  [-23.9349, 148.8047],
  [-23.9359, 148.8066],
  [-23.9359, 148.8082],
  [-23.9351, 148.8093],
  [-23.9310, 148.8124],
  [-23.9303, 148.8132]
];

const stewartonJunctionCookColliery = [
  [-23.7705, 148.8813],
  [-23.7739, 148.8815],
  [-23.7758, 148.8824],
  [-23.7782, 148.8842],
  [-23.7816, 148.8856],
  [-23.8042, 148.8855],
  [-23.8073, 148.8849],
  [-23.8251, 148.8847],
  [-23.8258, 148.8848],
  [-23.8262, 148.8853],
  [-23.8266, 148.8863],
  [-23.8267, 148.8871],
  [-23.8265, 148.8886],
  [-23.8256, 148.8900]
];

const westJunction = [
  [-23.5860, 148.8825],
  [-23.5864, 148.8838],
  [-23.5869, 148.8844],
  [-23.5877, 148.8848],
  [-23.5887, 148.8849]
];

export const laleham: Line = {
  name: 'Laleham',
  state: 'QLD',
  segments: [
    {
      segments: [blackwaterKoorilgahJunction, koorilgahJunctionLeichardtColliery, westJunction],
      history: {
        opened: {
          date: '1970-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [koorilgahJunctionStewartonJunction],
      history: {
        opened: {
          date: '1970-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2009-01-01',
          status: 'closed'
        }, {
          date: '2022-04-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [stewartonJunctionLaleham],
      history: {
        opened: {
          date: '1970-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2009-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stewartonJunctionCookColliery],
      history: {
        opened: {
          date: '1970-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2009-01-01',
          status: 'closed'
        }, {
          date: '2022-04-01',
          status: 'open'
        }]
      }
    }
  ]
}
