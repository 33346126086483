import { Line } from "../../trackTypes";

const loop = [
  [-19.2113, 146.6007],
  [-19.2103, 146.5995],
  [-19.2093, 146.5992],
  [-19.2084, 146.5994],
  [-19.2076, 146.6005],
  [-19.2071, 146.6019],
  [-19.2061, 146.6033],
  [-19.1973, 146.6100],
  [-19.1956, 146.6103],
  [-19.1939, 146.6092],
  [-19.1933, 146.6073],
  [-19.1938, 146.6056],
  [-19.1949, 146.6047],
  [-19.1963, 146.6044],
  [-19.2013, 146.6057],
  [-19.2023, 146.6053],
  [-19.2031, 146.6042],
  [-19.2039, 146.6003],
  [-19.2046, 146.5969],
  [-19.2060, 146.5954],
  [-19.2077, 146.5951],
  [-19.2088, 146.5955],
  [-19.2098, 146.5961]
];

const townsvilleHervyRangeNo1Tunnel = [
  [-19.2039, 146.6003],
  [-19.2043, 146.6016],
  [-19.2053, 146.6022],
  [-19.2063, 146.6022],
  [-19.2076, 146.6005],
  [-19.2086, 146.5984],
  [-19.2103, 146.5969],
  [-19.2223, 146.5898],
  [-19.2259, 146.5882],
  [-19.2343, 146.5854],
  [-19.2375, 146.5832],
  [-19.2672, 146.5546],
  [-19.2866, 146.5403],
  [-19.2929, 146.5339],
  [-19.3048, 146.5182],
  [-19.3066, 146.5147],
  [-19.3097, 146.5013],
  [-19.3118, 146.4962],
  [-19.3120, 146.4879],
  [-19.3112, 146.4844],
  [-19.3096, 146.4826],
  [-19.3047, 146.4813],
  [-19.3030, 146.4799],
  [-19.2995, 146.4708],
  [-19.2995, 146.4688],
  [-19.3007, 146.4673],
  [-19.3030, 146.4668],
  [-19.3045, 146.4672],
  [-19.3055, 146.4682],
  [-19.3074, 146.4732],
  [-19.3087, 146.4743],
  [-19.3103, 146.4746],
  [-19.3128, 146.4738],
  [-19.3161, 146.4746],
  [-19.3200, 146.4778],
  [-19.3210, 146.4781],
  [-19.3219, 146.4781],
  [-19.3228, 146.4778],
  [-19.3235, 146.4773],
  [-19.3240, 146.4763]
];

const hervyRangeNo1Tunnel = [
  [-19.3240, 146.4763],
  [-19.3247, 146.4751]
];

const hervyRangeNo1TunnelHervyRangeNo2Tunnel = [
  [-19.3247, 146.4751],
  [-19.3251, 146.4745],
  [-19.3259, 146.4737],
  [-19.3267, 146.4734],
  [-19.3306, 146.4738],
  [-19.3315, 146.4736],
  [-19.3335, 146.4726],
  [-19.3338, 146.4724]
];

const hervyRangeNo2Tunnel = [
  [-19.3338, 146.4724],
  [-19.3360, 146.4703]
];

const hervyRangeNo2TunnelHervyRangeNo3Tunnel = [
  [-19.3360, 146.4703],
  [-19.3366, 146.4695],
  [-19.3371, 146.4686]
];

const hervyRangeNo3Tunnel = [
  [-19.3371, 146.4686],
  [-19.3376, 146.4675]
];

const hervyRangeNo3TunnelHervyRangeNo4Tunnel = [
  [-19.3376, 146.4675],
  [-19.3395, 146.4635],
  [-19.3403, 146.4625],
  [-19.3411, 146.4621]
];

const hervyRangeNo4Tunnel = [
  [-19.3411, 146.4621],
  [-19.3452, 146.4622]
];

const hervyRangeNo4TunnelHervyRangeNo5Tunnel = [
  [-19.3452, 146.4622],
  [-19.3496, 146.4621],
  [-19.3515, 146.4618],
  [-19.3531, 146.4604],
  [-19.3549, 146.4569]
];

const hervyRangeNo5Tunnel = [
  [-19.3549, 146.4569],
  [-19.3569, 146.4528]
];

const hervyRangeNo5TunnelGreenvale = [
  [-19.3569, 146.4528],
  [-19.3596, 146.4476],
  [-19.3601, 146.4464],
  [-19.3612, 146.4402],
  [-19.3627, 146.4374],
  [-19.3674, 146.4326],
  [-19.3696, 146.4313],
  [-19.3823, 146.4266],
  [-19.3882, 146.4234],
  [-19.3908, 146.4222],
  [-19.3924, 146.4204],
  [-19.3956, 146.4117],
  [-19.3992, 146.4070],
  [-19.4056, 146.4034],
  [-19.4074, 146.4016],
  [-19.4095, 146.3983],
  [-19.4118, 146.3962],
  [-19.4156, 146.3945],
  [-19.4183, 146.3920],
  [-19.4208, 146.3882],
  [-19.4245, 146.3843],
  [-19.4340, 146.3706],
  [-19.4353, 146.3675],
  [-19.4380, 146.3568],
  [-19.4415, 146.3471],
  [-19.4425, 146.3452],
  [-19.4472, 146.3396],
  [-19.4483, 146.3370],
  [-19.4483, 146.3342],
  [-19.4430, 146.3176],
  [-19.4424, 146.3138],
  [-19.4419, 146.2870],
  [-19.4400, 146.2738],
  [-19.4387, 146.2534],
  [-19.4384, 146.2339],
  [-19.4354, 146.2065],
  [-19.4302, 146.1933],
  [-19.4266, 146.1792],
  [-19.4093, 146.1468],
  [-19.4062, 146.1382],
  [-19.4004, 146.1123],
  [-19.3894, 146.0921],
  [-19.3872, 146.0902],
  [-19.3838, 146.0890],
  [-19.3818, 146.0877],
  [-19.3783, 146.0829],
  [-19.3771, 146.0778],
  [-19.3774, 146.0727],
  [-19.3756, 146.0669],
  [-19.3717, 146.0624],
  [-19.3702, 146.0603],
  [-19.3562, 146.0248],
  [-19.3383, 145.9898],
  [-19.3298, 145.9765],
  [-19.3269, 145.9735],
  [-19.3233, 145.9717],
  [-19.3209, 145.9694],
  [-19.3074, 145.9430],
  [-19.2948, 145.9254],
  [-19.2909, 145.9218],
  [-19.2891, 145.9192],
  [-19.2856, 145.9099],
  [-19.2847, 145.9021],
  [-19.2855, 145.8884],
  [-19.2840, 145.8790],
  [-19.2752, 145.8483],
  [-19.2718, 145.8432],
  [-19.2557, 145.8313],
  [-19.2531, 145.8287],
  [-19.2421, 145.8132],
  [-19.2353, 145.7999],
  [-19.2350, 145.7949],
  [-19.2402, 145.7822],
  [-19.2400, 145.7771],
  [-19.2304, 145.7590],
  [-19.2297, 145.7548],
  [-19.2305, 145.7512],
  [-19.2352, 145.7429],
  [-19.2360, 145.7383],
  [-19.2315, 145.7053],
  [-19.2327, 145.6917],
  [-19.2312, 145.6691],
  [-19.2327, 145.6609],
  [-19.2549, 145.6234],
  [-19.2615, 145.6149],
  [-19.2630, 145.6108],
  [-19.2629, 145.6075],
  [-19.2610, 145.6019],
  [-19.2601, 145.6000],
  [-19.2494, 145.5907],
  [-19.2460, 145.5859],
  [-19.2319, 145.5515],
  [-19.2311, 145.5466],
  [-19.2312, 145.5392],
  [-19.2317, 145.5358],
  [-19.2359, 145.5249],
  [-19.2362, 145.5200],
  [-19.2348, 145.5150],
  [-19.2350, 145.5090],
  [-19.2342, 145.5059],
  [-19.2318, 145.5032],
  [-19.2307, 145.5010],
  [-19.2307, 145.4982],
  [-19.2295, 145.4949],
  [-19.2272, 145.4931],
  [-19.2255, 145.4910],
  [-19.2136, 145.4617],
  [-19.2109, 145.4580],
  [-19.2065, 145.4548],
  [-19.2032, 145.4508],
  [-19.1914, 145.4129],
  [-19.1724, 145.3693],
  [-19.1702, 145.3659],
  [-19.1669, 145.3639],
  [-19.1621, 145.3624],
  [-19.1374, 145.3468],
  [-19.1344, 145.3438],
  [-19.1300, 145.3372],
  [-19.1148, 145.3244],
  [-19.1126, 145.3213],
  [-19.1074, 145.3074],
  [-19.1053, 145.3046],
  [-19.1018, 145.3019],
  [-19.0994, 145.2981],
  [-19.0991, 145.2936],
  [-19.1008, 145.2892],
  [-19.1007, 145.2847],
  [-19.0932, 145.2659],
  [-19.0921, 145.2594],
  [-19.0923, 145.2563],
  [-19.0953, 145.2471],
  [-19.0973, 145.2445],
  [-19.0996, 145.2428],
  [-19.1011, 145.2402],
  [-19.1020, 145.2374],
  [-19.1017, 145.2341],
  [-19.1027, 145.2298],
  [-19.1059, 145.2252],
  [-19.1072, 145.2215],
  [-19.1075, 145.2136],
  [-19.1065, 145.2105],
  [-19.1046, 145.2082],
  [-19.1001, 145.2054],
  [-19.0976, 145.2026],
  [-19.0943, 145.1968],
  [-19.0922, 145.1949],
  [-19.0838, 145.1904],
  [-19.0741, 145.1803],
  [-19.0718, 145.1753],
  [-19.0714, 145.1726],
  [-19.0718, 145.1703],
  [-19.0735, 145.1666],
  [-19.0738, 145.1632],
  [-19.0697, 145.1534],
  [-19.0693, 145.1508],
  [-19.0711, 145.1459],
  [-19.0730, 145.1440],
  [-19.0736, 145.1419],
  [-19.0735, 145.1408],
  [-19.0722, 145.1381],
  [-19.0633, 145.1292],
  [-19.0605, 145.1249],
  [-19.0555, 145.1149],
  [-19.0461, 145.1049],
  [-19.0392, 145.0939],
  [-19.0353, 145.0894],
  [-19.0341, 145.0872],
  [-19.0322, 145.0815],
  [-19.0248, 145.0645],
  [-19.0240, 145.0601],
  [-19.0244, 145.0569],
  [-19.0230, 145.0519],
  [-19.0196, 145.0486],
  [-19.0165, 145.0474],
  [-19.0142, 145.0459],
  [-19.0071, 145.0390],
  [-19.0040, 145.0340],
  [-19.0024, 145.0325],
  [-18.9927, 145.0253],
  [-18.9882, 145.0193],
  [-18.9765, 144.9922],
  [-18.9755, 144.9876],
  [-18.9747, 144.9780],
  [-18.9729, 144.9738],
  [-18.9655, 144.9659],
  [-18.9643, 144.9636],
  [-18.9640, 144.9608],
  [-18.9649, 144.9574],
  [-18.9680, 144.9463],
  [-18.9692, 144.9444],
  [-18.9711, 144.9437],
  [-18.9734, 144.9444],
  [-18.9748, 144.9463],
  [-18.9749, 144.9486],
  [-18.9735, 144.9507],
  [-18.9679, 144.9537],
  [-18.9662, 144.9551],
  [-18.9649, 144.9574]
];

export const greenvale: Line = {
  name: 'Greenvale',
  state: 'QLD',
  segments: [
    {
      segments: [
        loop,
        townsvilleHervyRangeNo1Tunnel,
        {
          name: 'Hervy Range No 1 Tunnel',
          segment: hervyRangeNo1Tunnel,
          type: 'tunnel'
        },
        hervyRangeNo1TunnelHervyRangeNo2Tunnel,
        {
          name: 'Hervy Range No 2 Tunnel',
          segment: hervyRangeNo2Tunnel,
          type: 'tunnel'
        },
        hervyRangeNo2TunnelHervyRangeNo3Tunnel,
        {
          name: 'Hervy Range No 3 Tunnel',
          segment: hervyRangeNo3Tunnel,
          type: 'tunnel'
        },
        hervyRangeNo3TunnelHervyRangeNo4Tunnel,
        {
          name: 'Hervy Range No 4 Tunnel',
          segment: hervyRangeNo4Tunnel,
          type: 'tunnel'
        },
        hervyRangeNo4TunnelHervyRangeNo5Tunnel,
        {
          name: 'Hervy Range No 5 Tunnel',
          segment: hervyRangeNo5Tunnel,
          type: 'tunnel'
        },
        hervyRangeNo5TunnelGreenvale
      ],
      history: {
        opened: {
          date: '1974-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
