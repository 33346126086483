import { Line } from '../trackTypes';
import { airport } from './queensland/brisbane/airport';
import { fernyGrove } from './queensland/brisbane/fernyGrove';
import { shorncliffe } from './queensland/brisbane/shorncliffe';
import { springfield } from './queensland/brisbane/springfield';
import { goldcoast } from './queensland/southCoast/goldcoast';
import { western } from './queensland/western/western';
import { wandoan } from './queensland/western/wandoan';
import { cleveland } from './queensland/brisbane/cleveland';
import { fishermanIslands } from './queensland/brisbane/fishermanIslands';
import { normanton } from './queensland/normanton';
import { doomben } from './queensland/brisbane/doomben';
import { mainLine } from './queensland/mainLine';
import { jandowae } from './queensland/western/jandowae';
import { glenmorgan } from './queensland/western/glenmorgan';
import { tennyson } from './queensland/brisbane/tennyson';
import { millmerran } from './queensland/southern/millmerran';
import { greatNorthern } from './queensland/greatNorthern/greatNorthern';
import { springsure } from './queensland/centralWestern/springsure';
import { redcliffePeninsula } from './queensland/brisbane/redcliffePeninsula';
import { GAP } from './queensland/GAP';
import { chillagoe } from './queensland/tablelands/chillagoe';
import { tablelands } from './queensland/tablelands/tablelands';
import { southWestern } from './queensland/southern/southWestern';
import { southern } from './queensland/southern/southern';
import { southCoast } from './queensland/southCoast/southCoast';
import { emuPark } from './queensland/emuPark';
import { weipa } from './queensland/weipa';
import { goomburra } from './queensland/southern/goomburra';
import { warwickMaryvale } from './queensland/southern/warwickmaryvale';
import { killarney } from './queensland/southern/killarney';
import { amiens } from './queensland/southern/amiens';
import { centralWestern } from './queensland/centralWestern/centralWestern';
import { rockhamptonTrams } from './queensland/trams/rockhamptonTrams';
import { goldCoastLightRail } from './queensland/trams/goldCoastTrams';
import { marburg } from './queensland/western/marburg';
import { laidleyValley } from './queensland/western/laidleyValley';
import { mtEdwards } from './queensland/western/mtEdwards';
import { dugandan } from './queensland/western/dugandan';
import { brisbaneValley } from './queensland/western/brisbaneValley';
import { trivoliMtCrosby } from './queensland/western/mtCrosby';
import { redbankBudamba } from './queensland/western/redbankBundambaLoop';
import { yarrowleaEbenezer } from './queensland/western/yarrowleaEbenezer';
import { bell } from './queensland/western/bell';
import { haden } from './queensland/western/haden';
import { injune } from './queensland/western/injune';
import { barakula } from './queensland/western/barakula';
import { cecilPlains } from './queensland/western/cecilPlains';
import { cooyar } from './queensland/western/cooyar';
import { crowsNest } from './queensland/western/crowsNest';
import { texas } from './queensland/southern/texas';
import { beaudesert } from './queensland/southCoast/beaudesert';
import { canungra } from './queensland/southCoast/canungra';
import { belmontTram } from './queensland/trams/belmontTram';
import { kilcoy } from './queensland/kilcoy';
import { maryValley } from './queensland/maryValley';
import { blackall } from './queensland/centralWestern/blackall';
import { winton } from './queensland/greatNorthern/winton';
import { ravenswood } from './queensland/greatNorthern/ravenswood';
import { dajarra } from './queensland/greatNorthern/dajarra';
import { blairAthol } from './queensland/centralWestern/blairAthol';
import { aramac } from './queensland/centralWestern/aramac';
import { trekalano } from './queensland/greatNorthern/trekalano';
import { curragh } from './queensland/centralWestern/curragh';
import { mackay } from './queensland/mackay';
import { greenvale } from './queensland/greenvale';
import { nanango } from './queensland/nanango';
import { selwyn } from './queensland/greatNorthern/selwyn';
import { kajabbi } from './queensland/greatNorthern/kajabbi';
import { phosphateHill } from './queensland/greatNorthern/phosphateHill';
import { ballara } from './queensland/greatNorthern/ballara';
import { laleham } from './queensland/centralWestern/laleham';
import { goonyella } from './queensland/goonyella/goonyella';
import { callideValley } from './queensland/callideValley';
import { byelleMonto } from './queensland/byelleMonto';
import { mungarJunctionMonto } from './queensland/mungarJunctionMonto';
import { dawsonValley } from './queensland/dawsonValley';
import { millaMilla } from './queensland/tablelands/millaMilla';
import { bauhinia } from './queensland/centralWestern/bauhinia';
import { herveyBay } from './queensland/herveyBay';
import { portAlma } from './queensland/portAlma';
import { mackayHarbour } from './queensland/mackayHarbour';
import { townsvilleWharf } from './queensland/greatNorthern/townsvilleWharf';
import { mountGarnet } from './queensland/tablelands/mountGarnet';
import { mountPerry } from './queensland/mountPerry';
import { beaudesertTramways } from './queensland/southCoast/beaudesertTramways';
import { mouraShortLine } from './queensland/mouraShortLine';
import { isis } from './queensland/isis';
import { ridgelands } from './queensland/ridgelands';
import { maryboroughWharf } from './queensland/maryboroughWharf';
import { wigginsIsland } from './queensland/wigginsIsland';
import { northCoastTownsvilleCairns } from './queensland/northCoast/northCoastTownsvilleCairns';
import { northCoastMackayTownsville } from './queensland/northCoast/northCoastMackayTownsville';
import { northCoastStandardGauge } from './queensland/northCoast/northCoastStandardGauge';
import { northCoastRockhamptonMackay } from './queensland/northCoast/northCoastRockhamptonMackay';
import { northCoastCabooltureGympie } from './queensland/northCoast/northCoastCabooltureGympie';
import { northCoastGympieBundaberg } from './queensland/northCoast/northCoastGympieBundaberg';
import { northCoastBundabergRockhampton } from './queensland/northCoast/northCoastBundabergRockhampton';
import { gregory } from './queensland/centralWestern/gregory';
import { wotongaBlairAthol } from './queensland/goonyella/wotongaBlairAthol';
import { blackwaterGoonyella } from './queensland/goonyella/blackwaterGoonyella';
import { carmichael } from './queensland/goonyella/carmichael';
import { hailCreek } from './queensland/goonyella/hailCreek';
import { hayPoint } from './queensland/goonyella/hayPoint';
import { forsayth } from './queensland/tablelands/forsayth';
import { mountMulligan } from './queensland/tablelands/mountMulligan';
import { pemberton } from './queensland/pemberton';
import { cooktown } from './queensland/cooktown';
import { julatten } from './queensland/tablelands/julatten';
import { barneyPoint } from './queensland/barneyPoint';
import { buderim } from './queensland/buderim';
import { woolloongabba } from './queensland/brisbane/woolloongabba';
import { bulimba } from './queensland/brisbane/bulimba';
import { laheysTramway } from './queensland/southCoast/laheys';
import { cooloola } from './queensland/cooloola';
import { seaWorldMonorail } from './queensland/seaWorldMonorail';
import { oasisMonorail } from './queensland/oasisMonorail';
import { crossRiverRail } from './queensland/brisbane/crossRiverRail';
import { nambourTram } from './queensland/trams/nambourTram';
import { brisbaneMonorail } from './queensland/brisbaneMonorail';
import { tweedHeads } from './queensland/southCoast/tweedHeads';
import { beenleigh } from './queensland/brisbane/beenleigh';
import { rosewood } from './queensland/brisbane/rosewood';
import { northCoastBrisbaneCaboolture } from './queensland/northCoast/northCoastBrisbanecaboolture';
import { stannaryHills } from './queensland/tablelands/stannaryHills';
import { neranwoodTram } from './queensland/trams/neranwoodTram';
import { mapleton } from './queensland/mapleton';
import { gibsonIsland } from './queensland/brisbane/gibsonIsland';
import { cannonHill } from './queensland/brisbane/cannonHill';
import { brisbaneTrams } from './queensland/trams/brisbaneTrams';

export const qldLines: Line[] = [
  airport,
  fernyGrove,
  shorncliffe,
  springfield,
  goldcoast,
  western,
  wandoan,
  cleveland,
  fishermanIslands,
  normanton,
  doomben,
  mainLine,
  jandowae,
  glenmorgan,
  tennyson,
  millmerran,
  greatNorthern,
  springsure,
  redcliffePeninsula,
  GAP,
  chillagoe,
  tablelands,
  southWestern,
  southern,
  southCoast,
  emuPark,
  weipa,
  goomburra,
  warwickMaryvale,
  killarney,
  amiens,
  centralWestern,
  rockhamptonTrams,
  goldCoastLightRail,
  marburg,
  laidleyValley,
  mtEdwards,
  dugandan,
  brisbaneValley,
  trivoliMtCrosby,
  redbankBudamba,
  yarrowleaEbenezer,
  bell,
  haden,
  injune,
  barakula,
  cecilPlains,
  cooyar,
  crowsNest,
  texas,
  beaudesert,
  canungra,
  belmontTram,
  kilcoy,
  maryValley,
  blackall,
  winton,
  ravenswood,
  dajarra,
  blairAthol,
  aramac,
  trekalano,
  curragh,
  mackay,
  greenvale,
  nanango,
  selwyn,
  kajabbi,
  phosphateHill,
  ballara,
  laleham,
  goonyella,
  callideValley,
  byelleMonto,
  mungarJunctionMonto,
  dawsonValley,
  millaMilla,
  bauhinia,
  herveyBay,
  portAlma,
  mackayHarbour,
  townsvilleWharf,
  mountGarnet,
  mountPerry,
  beaudesertTramways,
  mouraShortLine,
  isis,
  ridgelands,
  maryboroughWharf,
  wigginsIsland,
  northCoastStandardGauge,
  northCoastBrisbaneCaboolture,
  northCoastCabooltureGympie,
  northCoastGympieBundaberg,
  northCoastBundabergRockhampton,
  northCoastRockhamptonMackay,
  northCoastMackayTownsville,
  northCoastTownsvilleCairns,
  gregory,
  wotongaBlairAthol,
  blackwaterGoonyella,
  carmichael,
  hailCreek,
  hayPoint,
  forsayth,
  mountMulligan,
  pemberton,
  cooktown,
  julatten,
  barneyPoint,
  buderim,
  woolloongabba,
  bulimba,
  laheysTramway,
  cooloola,
  seaWorldMonorail,
  oasisMonorail,
  crossRiverRail,
  nambourTram,
  brisbaneMonorail,
  tweedHeads,
  beenleigh,
  rosewood,
  stannaryHills,
  neranwoodTram,
  mapleton,
  gibsonIsland,
  cannonHill,
  ...brisbaneTrams
];
