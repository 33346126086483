import { Line } from "../../../trackTypes";

const beaudesertChristmasCreek = [
  [-27.9853, 152.9949],
  [-27.9846, 152.9943],
  [-27.9843, 152.9934],
  [-27.9844, 152.9926],
  [-27.9879, 152.9859],
  [-27.9891, 152.9846],
  [-27.9927, 152.9827],
  [-27.9949, 152.9810],
  [-28.0217, 152.9498],
  [-28.0246, 152.9430],
  [-28.0257, 152.9417],
  [-28.0295, 152.9414],
  [-28.0309, 152.9406],
  [-28.0323, 152.9393],
  [-28.0334, 152.9391],
  [-28.0352, 152.9379],
  [-28.0364, 152.9362],
  [-28.0374, 152.9336],
  [-28.0383, 152.9330],
  [-28.0401, 152.9337],
  [-28.0412, 152.9339],
  [-28.0425, 152.9336],
  [-28.0440, 152.9327],
  [-28.0477, 152.9327],
  [-28.0566, 152.9340],
  [-28.0578, 152.9335],
  [-28.0589, 152.9328],
  [-28.0624, 152.9327],
  [-28.0706, 152.9324],
  [-28.0768, 152.9333],
  [-28.0812, 152.9347],
  [-28.0830, 152.9349],
  [-28.0938, 152.9359],
  [-28.0945, 152.9362],
  [-28.0962, 152.9374],
  [-28.0978, 152.9380],
  [-28.0983, 152.9384],
  [-28.0994, 152.9397],
  [-28.1005, 152.9406],
  [-28.1146, 152.9479],
  [-28.1165, 152.9484],
  [-28.1230, 152.9468],
  [-28.1275, 152.9462],
  [-28.1281, 152.9464],
  [-28.1283, 152.9469],
  [-28.1286, 152.9480],
  [-28.1289, 152.9489],
  [-28.1301, 152.9496],
  [-28.1306, 152.9501],
  [-28.1316, 152.9528],
  [-28.1353, 152.9580],
  [-28.1363, 152.9593],
  [-28.1412, 152.9616],
  [-28.1492, 152.9673],
  [-28.1521, 152.9685],
  [-28.1571, 152.9697],
  [-28.1584, 152.9705],
  [-28.1675, 152.9813],
  [-28.1685, 152.9821],
  [-28.1694, 152.9822],
  [-28.1698, 152.9824]
];

const christmasCreekLamington = [
  [-28.1698, 152.9824],
  [-28.1703, 152.9830],
  [-28.1710, 152.9844],
  [-28.1721, 152.9857],
  [-28.1797, 152.9910],
  [-28.1856, 152.9925],
  [-28.1866, 152.9934],
  [-28.1898, 152.9985],
  [-28.1936, 153.0033],
  [-28.1959, 153.0046],
  [-28.1998, 153.0056],
  [-28.2006, 153.0057],
  [-28.2073, 153.0037],
  [-28.2081, 153.0036],
  [-28.2125, 153.0041],
  [-28.2130, 153.0043],
  [-28.2150, 153.0069],
  [-28.2152, 153.0073],
  [-28.2157, 153.0076],
  [-28.2168, 153.0075],
  [-28.2231, 153.0045],
  [-28.2265, 152.9987],
  [-28.2271, 152.9981],
  [-28.2288, 152.9967],
  [-28.2295, 152.9966],
  [-28.2318, 152.9977],
  [-28.2325, 152.9978],
  [-28.2370, 152.9962]
];

const innisplainRathdowney = [
  [-28.1230, 152.9468],
  [-28.1233, 152.9463],
  [-28.1236, 152.9456],
  [-28.1239, 152.9435],
  [-28.1244, 152.9420],
  [-28.1259, 152.9412],
  [-28.1313, 152.9401],
  [-28.1321, 152.9392],
  [-28.1333, 152.9375],
  [-28.1337, 152.9356],
  [-28.1408, 152.9262],
  [-28.1413, 152.9258],
  [-28.1468, 152.9256],
  [-28.1473, 152.9252],
  [-28.1475, 152.9244],
  [-28.1479, 152.9238],
  [-28.1563, 152.9192],
  [-28.1583, 152.9175],
  [-28.1648, 152.9111],
  [-28.1662, 152.9100],
  [-28.1714, 152.9077],
  [-28.1727, 152.9069],
  [-28.1807, 152.8967],
  [-28.1821, 152.8958],
  [-28.1833, 152.8959],
  [-28.1859, 152.8967],
  [-28.1881, 152.8984],
  [-28.1889, 152.8985],
  [-28.1965, 152.8979],
  [-28.1973, 152.8969],
  [-28.1976, 152.8957],
  [-28.1968, 152.8935],
  [-28.1959, 152.8922],
  [-28.1939, 152.8908],
  [-28.1933, 152.8899],
  [-28.1912, 152.8877],
  [-28.1909, 152.8866],
  [-28.1916, 152.8838],
  [-28.1922, 152.8832],
  [-28.1932, 152.8829],
  [-28.1942, 152.8832],
  [-28.1950, 152.8833],
  [-28.1962, 152.8831],
  [-28.1991, 152.8836],
  [-28.2019, 152.8833],
  [-28.2039, 152.8829],
  [-28.2065, 152.8830],
  [-28.2096, 152.8820],
  [-28.2134, 152.8801],
  [-28.2138, 152.8792],
  [-28.2138, 152.8784],
  [-28.2136, 152.8778],
  [-28.2102, 152.8759],
  [-28.2095, 152.8749],
  [-28.2093, 152.8736],
  [-28.2110, 152.8655],
  [-28.2119, 152.8624]
];

export const beaudesertTramways: Line = {
  name: 'Beaudesert Tramways',
  state: 'QLD',
  segments: [
    {
      segments: [beaudesertChristmasCreek],
      history: {
        opened: {
          date: '1903-10-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1944-09-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [christmasCreekLamington],
      history: {
        opened: {
          date: '1910-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1944-09-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [innisplainRathdowney],
      history: {
        opened: {
          date: '1911-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1944-09-30',
          status: 'closed'
        }]
      }
    }
  ]
}
