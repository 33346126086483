import { Line } from "../../trackTypes";

const northIsisJunction = [
  [-25.2138, 152.4475],
  [-25.2146, 152.4474],
  [-25.2150, 152.4471],
  [-25.2154, 152.4465]
];

const isisJunctionChilders = [
  [-25.2166, 152.4481],
  [-25.2159, 152.4474],
  [-25.2154, 152.4465],
  [-25.2149, 152.4349],
  [-25.2155, 152.4304],
  [-25.2305, 152.3766],
  [-25.2310, 152.3715],
  [-25.2301, 152.3474],
  [-25.2297, 152.3457],
  [-25.2282, 152.3437],
  [-25.2274, 152.3424],
  [-25.2259, 152.3382],
  [-25.2256, 152.3358],
  [-25.2259, 152.3255],
  [-25.2249, 152.3169],
  [-25.2250, 152.3122],
  [-25.2284, 152.3010],
  [-25.2292, 152.3000],
  [-25.2330, 152.2985],
  [-25.2336, 152.2984],
  [-25.2346, 152.2985],
  [-25.2355, 152.2984],
  [-25.2363, 152.2977],
  [-25.2389, 152.2924],
  [-25.2390, 152.2911],
  [-25.2379, 152.2879],
  [-25.2344, 152.2821],
  [-25.2341, 152.2812],
  [-25.2342, 152.2802],
  [-25.2355, 152.2767],
  [-25.2355, 152.2758],
  [-25.2350, 152.2748],
  [-25.2328, 152.2731]
];

const childersCordalba = [
  [-25.2328, 152.2731],
  [-25.2321, 152.2723],
  [-25.2316, 152.2713],
  [-25.2317, 152.2699],
  [-25.2327, 152.2678],
  [-25.2327, 152.2673],
  [-25.2326, 152.2669],
  [-25.2320, 152.2662],
  [-25.2315, 152.2651],
  [-25.2314, 152.2642],
  [-25.2313, 152.2636],
  [-25.2309, 152.2631],
  [-25.2291, 152.2620],
  [-25.2284, 152.2610],
  [-25.2266, 152.2557],
  [-25.2260, 152.2551],
  [-25.2252, 152.2550],
  [-25.2222, 152.2565],
  [-25.2210, 152.2565],
  [-25.2197, 152.2561],
  [-25.2187, 152.2562],
  [-25.2138, 152.2594],
  [-25.2129, 152.2595],
  [-25.2033, 152.2575],
  [-25.2027, 152.2573],
  [-25.2016, 152.2562],
  [-25.2010, 152.2560],
  [-25.1996, 152.2557],
  [-25.1978, 152.2550],
  [-25.1972, 152.2542],
  [-25.1967, 152.2523],
  [-25.1950, 152.2491],
  [-25.1942, 152.2481],
  [-25.1911, 152.2461],
  [-25.1902, 152.2453],
  [-25.1691, 152.2167],
  [-25.1676, 152.2156],
  [-25.1634, 152.2148],
  [-25.1621, 152.2140],
  [-25.1606, 152.2126]
];

const cordalbaDallarnil = [
  [-25.1606, 152.2126],
  [-25.1570, 152.2060],
  [-25.1566, 152.2044],
  [-25.1550, 152.2030],
  [-25.1544, 152.2027],
  [-25.1512, 152.2024],
  [-25.1499, 152.2021],
  [-25.1485, 152.2009],
  [-25.1479, 152.2001],
  [-25.1478, 152.1993],
  [-25.1487, 152.1943],
  [-25.1495, 152.1930],
  [-25.1525, 152.1905],
  [-25.1530, 152.1896],
  [-25.1531, 152.1888],
  [-25.1525, 152.1838],
  [-25.1515, 152.1807],
  [-25.1513, 152.1772],
  [-25.1516, 152.1760],
  [-25.1527, 152.1748],
  [-25.1560, 152.1725],
  [-25.1567, 152.1709],
  [-25.1571, 152.1702],
  [-25.1595, 152.1688],
  [-25.1627, 152.1676],
  [-25.1637, 152.1675],
  [-25.1654, 152.1675],
  [-25.1663, 152.1671],
  [-25.1675, 152.1658],
  [-25.1685, 152.1650],
  [-25.1754, 152.1625],
  [-25.1765, 152.1617],
  [-25.1779, 152.1590],
  [-25.1780, 152.1584],
  [-25.1779, 152.1571],
  [-25.1782, 152.1561],
  [-25.1796, 152.1544],
  [-25.1806, 152.1538],
  [-25.1822, 152.1534],
  [-25.1833, 152.1524],
  [-25.1846, 152.1494],
  [-25.1848, 152.1485],
  [-25.1849, 152.1470],
  [-25.1851, 152.1460],
  [-25.1858, 152.1444],
  [-25.1860, 152.1434],
  [-25.1859, 152.1426],
  [-25.1853, 152.1410],
  [-25.1852, 152.1403],
  [-25.1853, 152.1396],
  [-25.1868, 152.1369],
  [-25.1873, 152.1365],
  [-25.1882, 152.1358],
  [-25.1888, 152.1349],
  [-25.1894, 152.1314],
  [-25.1897, 152.1307],
  [-25.1903, 152.1300],
  [-25.1907, 152.1290],
  [-25.1906, 152.1262],
  [-25.1907, 152.1252],
  [-25.1912, 152.1235],
  [-25.1913, 152.1225],
  [-25.1910, 152.1215],
  [-25.1879, 152.1180],
  [-25.1873, 152.1166],
  [-25.1866, 152.1130],
  [-25.1867, 152.1119],
  [-25.1873, 152.1108],
  [-25.1924, 152.1050],
  [-25.1976, 152.0963],
  [-25.1989, 152.0947],
  [-25.2072, 152.0876],
  [-25.2093, 152.0848],
  [-25.2155, 152.0700],
  [-25.2157, 152.0687],
  [-25.2164, 152.0674],
  [-25.2165, 152.0665],
  [-25.2166, 152.0639],
  [-25.2168, 152.0630],
  [-25.2176, 152.0622],
  [-25.2179, 152.0616],
  [-25.2179, 152.0607],
  [-25.2174, 152.0592],
  [-25.2168, 152.0557],
  [-25.2169, 152.0528],
  [-25.2163, 152.0512],
  [-25.2144, 152.0496],
  [-25.2139, 152.0489],
  [-25.2131, 152.0473],
  [-25.2115, 152.0456],
  [-25.2088, 152.0442],
  [-25.2083, 152.0435],
  [-25.2081, 152.0426],
  [-25.2082, 152.0418],
  [-25.2086, 152.0411],
  [-25.2098, 152.0405],
  [-25.2124, 152.0400],
  [-25.2130, 152.0401],
  [-25.2150, 152.0408],
  [-25.2155, 152.0408],
  [-25.2176, 152.0398],
  [-25.2181, 152.0398],
  [-25.2241, 152.0433],
  [-25.2250, 152.0442],
  [-25.2252, 152.0452],
  [-25.2254, 152.0476],
  [-25.2259, 152.0489],
  [-25.2266, 152.0499],
  [-25.2296, 152.0525],
  [-25.2302, 152.0528],
  [-25.2311, 152.0527],
  [-25.2323, 152.0522],
  [-25.2330, 152.0522],
  [-25.2376, 152.0532],
  [-25.2390, 152.0539],
  [-25.2421, 152.0570],
  [-25.2426, 152.0573],
  [-25.2434, 152.0573],
  [-25.2444, 152.0571],
  [-25.2452, 152.0572],
  [-25.2465, 152.0582],
  [-25.2472, 152.0585],
  [-25.2485, 152.0588],
  [-25.2491, 152.0591],
  [-25.2500, 152.0596],
  [-25.2507, 152.0597],
  [-25.2518, 152.0594],
  [-25.2523, 152.0595],
  [-25.2530, 152.0598],
  [-25.2535, 152.0604],
  [-25.2542, 152.0607],
  [-25.2554, 152.0610],
  [-25.2562, 152.0614],
  [-25.2569, 152.0624],
  [-25.2574, 152.0627],
  [-25.2585, 152.0626],
  [-25.2611, 152.0613],
  [-25.2626, 152.0612],
  [-25.2640, 152.0619],
  [-25.2658, 152.0623],
  [-25.2744, 152.0615],
  [-25.2783, 152.0603],
  [-25.2796, 152.0604],
  [-25.2853, 152.0629],
  [-25.2865, 152.0629],
  [-25.2875, 152.0624],
  [-25.2883, 152.0616],
  [-25.2894, 152.0600],
  [-25.2905, 152.0592],
  [-25.2926, 152.0588],
  [-25.2949, 152.0575],
  [-25.2956, 152.0567],
  [-25.2965, 152.0544],
  [-25.2964, 152.0537],
  [-25.2961, 152.0524],
  [-25.2960, 152.0520],
  [-25.2971, 152.0479],
  [-25.2970, 152.0470],
  [-25.2967, 152.0459],
  [-25.2962, 152.0452],
  [-25.2935, 152.0428],
  [-25.2925, 152.0422],
  [-25.2922, 152.0419],
  [-25.2921, 152.0413],
  [-25.2924, 152.0407],
  [-25.2931, 152.0401],
  [-25.2933, 152.0395],
  [-25.2932, 152.0389],
  [-25.2927, 152.0381],
  [-25.2927, 152.0374],
  [-25.2931, 152.0368],
  [-25.2946, 152.0365],
  [-25.2952, 152.0368],
  [-25.2955, 152.0374],
  [-25.2955, 152.0390],
  [-25.2957, 152.0395],
  [-25.2963, 152.0399],
  [-25.2969, 152.0398],
  [-25.2973, 152.0392],
  [-25.2977, 152.0364],
  [-25.2980, 152.0359],
  [-25.2985, 152.0356],
  [-25.2995, 152.0355],
  [-25.3000, 152.0351],
  [-25.3001, 152.0345],
  [-25.2998, 152.0337],
  [-25.2998, 152.0328],
  [-25.3001, 152.0320],
  [-25.3001, 152.0316],
  [-25.2998, 152.0306],
  [-25.2999, 152.0301],
  [-25.3001, 152.0296],
  [-25.3008, 152.0292],
  [-25.3015, 152.0294],
  [-25.3019, 152.0298],
  [-25.3023, 152.0308],
  [-25.3028, 152.0313],
  [-25.3033, 152.0313],
  [-25.3038, 152.0311],
  [-25.3045, 152.0307],
  [-25.3052, 152.0307],
  [-25.3056, 152.0311],
  [-25.3057, 152.0318],
  [-25.3052, 152.0328],
  [-25.3052, 152.0335],
  [-25.3054, 152.0340],
  [-25.3065, 152.0348],
  [-25.3104, 152.0369],
  [-25.3148, 152.0399],
  [-25.3204, 152.0422],
  [-25.3227, 152.0421],
  [-25.3247, 152.0427],
  [-25.3264, 152.0442],
  [-25.3276, 152.0447],
  [-25.3323, 152.0454],
  [-25.3336, 152.0452],
  [-25.3350, 152.0446],
  [-25.3360, 152.0446],
  [-25.3367, 152.0449],
  [-25.3391, 152.0479],
  [-25.3399, 152.0485],
  [-25.3408, 152.0485],
  [-25.3429, 152.0472],
  [-25.3439, 152.0471],
  [-25.3446, 152.0473],
  [-25.3458, 152.0473],
  [-25.3470, 152.0469],
  [-25.3479, 152.0470],
  [-25.3492, 152.0475],
  [-25.3501, 152.0475],
  [-25.3518, 152.0465],
  [-25.3528, 152.0465],
  [-25.3558, 152.0481],
  [-25.3572, 152.0497],
  [-25.3589, 152.0503],
  [-25.3608, 152.0499],
  [-25.3617, 152.0501],
  [-25.3631, 152.0510],
  [-25.3642, 152.0512],
  [-25.3729, 152.0496],
  [-25.3784, 152.0477],
  [-25.3809, 152.0459],
  [-25.3828, 152.0456],
  [-25.3845, 152.0461],
  [-25.3889, 152.0484]
];

export const isis: Line = {
  name: 'Isis',
  state: 'QLD',
  segments: [
    {
      segments: [northIsisJunction, isisJunctionChilders],
      history: {
        opened: {
          date: '1887-10-31',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [childersCordalba],
      history: {
        opened: {
          date: '1896-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cordalbaDallarnil],
      history: {
        opened: {
          date: '1913-05-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-07-01',
          status: 'closed'
        }]
      }
    }
  ]
}
