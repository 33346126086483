import { Line } from "../../../trackTypes";

const murchisonEastWarangaBasin = [
  [-36.6191, 145.24067],
  [-36.6205, 145.2400],
  [-36.6216, 145.2385],
  [-36.6222, 145.2368],
  [-36.6237, 145.2235],
  [-36.6233, 145.2210],
  [-36.6077, 145.1724],
  [-36.5970, 145.1264],
  [-36.5964, 145.1229],
  [-36.5963, 145.1080]
];

const warangaBasinOld = [
  [-36.5963, 145.1080],
  [-36.5962, 145.0770],
  [-36.5932, 145.0565]
];

const warangaBasinRushworthJunction = [
  [-36.5963, 145.1080],
  [-36.5971, 145.1046],
  [-36.6043, 145.0853],
  [-36.6046, 145.0820],
  [-36.6040, 145.0782],
  [-36.5932, 145.0565]
];

const rushworthJunctionRushworth = [
  [-36.5932, 145.0565],
  [-36.5873, 145.0206]
];

const rushworthColbinabbin = [
  [-36.5873, 145.0206],
  [-36.5855, 145.0094],
  [-36.5832, 145.0006],
  [-36.5827, 144.9981],
  [-36.5812, 144.9719],
  [-36.5815, 144.9681],
  [-36.5849, 144.9583],
  [-36.5868, 144.9553],
  [-36.5884, 144.9527],
  [-36.5890, 144.9508],
  [-36.5892, 144.9480],
  [-36.5887, 144.9427],
  [-36.5853, 144.9221],
  [-36.5851, 144.9181],
  [-36.5868, 144.8925],
  [-36.5888, 144.8740],
  [-36.5888, 144.8738],
  [-36.5895, 144.8162],
  [-36.5891, 144.8092],
  [-36.5890, 144.7917]
];

export const colbinabbin: Line = {
  name: 'Colbinabbin',
  state: 'VIC',
  segments: [
    {
      segments: [
        murchisonEastWarangaBasin,
        {
          date: '1912-01-01',
          original: [warangaBasinOld],
          deviation: [warangaBasinRushworthJunction]
        },
        rushworthJunctionRushworth
      ],
      history: {
        opened: {
          date: '1890-09-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rushworthColbinabbin],
      history: {
        opened: {
          date: '1914-08-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-12',
          status: 'closed'
        }]
      }
    }
  ]
}
