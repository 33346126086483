import { Line } from "../../../trackTypes";

const riversdaleMatauraRiver = [
  [-45.9000, 168.7407],
  [-45.8988, 168.7405],
  [-45.8973, 168.7411],
  [-45.8874, 168.7496],
  [-45.8659, 168.7614],
  [-45.8644, 168.7630],
  [-45.8542, 168.7779],
  [-45.8528, 168.7785],
  [-45.8509, 168.7782],
  [-45.8473, 168.7762],
  [-45.8448, 168.7760],
  [-45.8395, 168.7776],
  [-45.8342, 168.7779],
  [-45.8047, 168.7757],
  [-45.7863, 168.7815],
  [-45.7844, 168.7831],
  [-45.7552, 168.8052],
  [-45.7491, 168.8126],
  [-45.7435, 168.8216],
  [-45.7422, 168.8224],
  [-45.7371, 168.8227]
];

const matauraRoverWaikaia = [
  [-45.7371, 168.8227],
  [-45.7358, 168.8231],
  [-45.7343, 168.8254],
  [-45.7333, 168.8277],
  [-45.7315, 168.8297],
  [-45.7303, 168.8320],
  [-45.7284, 168.8347],
  [-45.7273, 168.8355],
  [-45.7264, 168.8357],
  [-45.7255, 168.8360],
  [-45.7248, 168.8367],
  [-45.7234, 168.8392]
];

export const waikaia: Line = {
  name: 'Waikaia',
  state: 'NZ',
  segments: [
    {
      segments: [riversdaleMatauraRiver],
      history: {
        opened: {
          date: '1904-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-05-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [matauraRoverWaikaia],
      history: {
        opened: {
          date: '1909-10-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-05-16',
          status: 'closed'
        }]
      }
    }
  ]
}
