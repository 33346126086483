import { Line } from "../../../trackTypes";

const line = [
  [-33.8674, 151.2154],
  [-33.8665, 151.2143],
  [-33.8659, 151.2121],
  [-33.8655, 151.2066],
  [-33.8657, 151.2048],
  [-33.8663, 151.2033],
  [-33.8672, 151.2019],
  [-33.8685, 151.2005],
  [-33.8695, 151.1988],
  [-33.8699, 151.1974],
  [-33.8703, 151.1938],
  [-33.8701, 151.1911],
  [-33.8695, 151.1890],
  [-33.8661, 151.1753],
  [-33.8657, 151.1709],
  [-33.8681, 151.1440],
  [-33.8680, 151.1333],
  [-33.8671, 151.1275],
  [-33.8674, 151.1230],
  [-33.8696, 151.1161],
  [-33.8698, 151.1117],
  [-33.8674, 151.1019],
  [-33.8664, 151.0997],
  [-33.8646, 151.0972],
  [-33.8636, 151.0949],
  [-33.8628, 151.0921],
  [-33.8618, 151.0904],
  [-33.8599, 151.0889],
  [-33.8558, 151.0875],
  [-33.8539, 151.0859],
  [-33.8528, 151.0836],
  [-33.8525, 151.0818],
  [-33.8529, 151.0759],
  [-33.8525, 151.0731],
  [-33.8511, 151.0711],
  [-33.8468, 151.0679],
  [-33.8448, 151.0651],
  [-33.8438, 151.0636],
  [-33.8399, 151.0506],
  [-33.8385, 151.0476],
  [-33.8346, 151.0402],
  [-33.8331, 151.0354],
  [-33.8324, 151.0314],
  [-33.8310, 151.0284],
  [-33.8263, 151.0242],
  [-33.8230, 151.0225],
  [-33.8208, 151.0206],
  [-33.8191, 151.0175],
  [-33.8163, 151.0113],
  [-33.8137, 150.9998],
  [-33.8125, 150.9969],
  [-33.8112, 150.9946],
  [-33.8107, 150.9933],
  [-33.8084, 150.9859]
];

export const metroWest: Line = {
  name: 'Metro West',
  state: 'NSW',
  segments: [
    {
      segments: [{
        segment: line,
        type: 'tunnel'
      }],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
