import { Line } from "../../../trackTypes";

const waiotiraJunctionKirikopuni = [
  [-35.9336, 174.1985],
  [-35.9333, 174.1978],
  [-35.9332, 174.1972],
  [-35.9331, 174.1923],
  [-35.9326, 174.1900],
  [-35.9314, 174.1820],
  [-35.9311, 174.1804],
  [-35.9288, 174.1757],
  [-35.9284, 174.1746],
  [-35.9282, 174.1730],
  [-35.9279, 174.1722],
  [-35.9257, 174.1700],
  [-35.9241, 174.1676],
  [-35.9233, 174.1658],
  [-35.9223, 174.1648],
  [-35.9204, 174.1641],
  [-35.9195, 174.1633],
  [-35.9181, 174.1608],
  [-35.9178, 174.1600],
  [-35.9175, 174.1583],
  [-35.9172, 174.1525],
  [-35.9169, 174.1512],
  [-35.9154, 174.1487],
  [-35.9150, 174.1476],
  [-35.9144, 174.1422],
  [-35.9136, 174.1389],
  [-35.9136, 174.1373],
  [-35.9146, 174.1329],
  [-35.9149, 174.1299],
  [-35.9154, 174.1281],
  [-35.9186, 174.1229],
  [-35.9189, 174.1213],
  [-35.9186, 174.1176],
  [-35.9182, 174.1161],
  [-35.9170, 174.1139],
  [-35.9165, 174.1113],
  [-35.9163, 174.1080],
  [-35.9147, 174.1005],
  [-35.9141, 174.0993],
  [-35.9128, 174.0984],
  [-35.9046, 174.0969],
  [-35.9034, 174.0963],
  [-35.9025, 174.0952],
  [-35.8984, 174.0872],
  [-35.8961, 174.0836],
  [-35.8957, 174.0822],
  [-35.8945, 174.0802],
  [-35.8939, 174.0790],
  [-35.8916, 174.0693],
  [-35.8911, 174.0679],
  [-35.8893, 174.0654],
  [-35.8888, 174.0643],
  [-35.8888, 174.0627],
  [-35.8891, 174.0612],
  [-35.8889, 174.0596],
  [-35.8873, 174.0557],
  [-35.8865, 174.0544],
  [-35.8823, 174.0506],
  [-35.8816, 174.0490],
  [-35.8813, 174.0472],
  [-35.8809, 174.0462],
  [-35.8785, 174.0428],
  [-35.8688, 174.0355],
  [-35.8675, 174.0336],
  [-35.8637, 174.0253],
  [-35.8630, 174.0234],
  [-35.8630, 174.0220],
  [-35.8637, 174.0185]
];

const kirikopuniTangowahine = [
  [-35.8648, 174.0157],
  [-35.8667, 174.0153],
  [-35.8677, 174.0150],
  [-35.8683, 174.0149],
  [-35.8692, 174.0152],
  [-35.8698, 174.0153],
  [-35.8703, 174.0152],
  [-35.8737, 174.0136],
  [-35.8754, 174.0129],
  [-35.8767, 174.0117],
  [-35.8795, 174.0063],
  [-35.8809, 174.0014],
  [-35.8815, 174.0006],
  [-35.8822, 174.0003],
  [-35.8828, 173.9996],
  [-35.8831, 173.9985],
  [-35.8845, 173.9959],
  [-35.8851, 173.9954],
  [-35.8862, 173.9948],
  [-35.8868, 173.9943],
  [-35.8872, 173.9936],
  [-35.8877, 173.9917],
  [-35.8878, 173.9908],
  [-35.8878, 173.9873],
  [-35.8883, 173.9845],
  [-35.8887, 173.9835],
  [-35.8892, 173.9824],
  [-35.8893, 173.9816],
  [-35.8891, 173.9810],
  [-35.8887, 173.9802],
  [-35.8885, 173.9793],
  [-35.8882, 173.9757],
  [-35.8885, 173.9743],
  [-35.8892, 173.9735],
  [-35.8899, 173.9731],
  [-35.8908, 173.9731],
  [-35.8916, 173.9734],
  [-35.8927, 173.9735],
  [-35.8942, 173.9745],
  [-35.8949, 173.9748],
  [-35.8959, 173.9747],
  [-35.9024, 173.9721],
  [-35.9039, 173.9711],
  [-35.9051, 173.9689],
  [-35.9054, 173.9677],
  [-35.9055, 173.9653],
  [-35.9047, 173.9633],
  [-35.9035, 173.9622],
  [-35.8978, 173.9614],
  [-35.8906, 173.9592],
  [-35.8774, 173.9516],
  [-35.8757, 173.9496],
  [-35.8723, 173.9418],
  [-35.8707, 173.9372],
  [-35.8701, 173.9360],
  [-35.8689, 173.9344],
  [-35.8687, 173.9338],
  [-35.8685, 173.9329],
  [-35.8685, 173.9307]
];

const kirikopuniLoop = [
  [-35.8637, 174.0185],
  [-35.8635, 174.0178],
  [-35.8630, 174.0172],
  [-35.8618, 174.0167],
  [-35.8628, 174.0164],
  [-35.8642, 174.0157],
  [-35.8648, 174.0157]
];

const kirikopuniLoopTwo = [
  [-35.8618, 174.0167],
  [-35.8586, 174.0159],
  [-35.8573, 174.0162],
  [-35.8560, 174.0170],
  [-35.8554, 174.0172],
  [-35.8524, 174.0179],
  [-35.8511, 174.0183],
  [-35.8504, 174.0183],
  [-35.8500, 174.0182],
  [-35.8486, 174.0166],
  [-35.8479, 174.0154],
  [-35.8472, 174.0135],
  [-35.8467, 174.0129],
  [-35.8461, 174.0128],
  [-35.8449, 174.0133],
  [-35.8446, 174.0137],
  [-35.8445, 174.0142],
  [-35.8448, 174.0152],
  [-35.8455, 174.0158],
  [-35.8475, 174.0173],
  [-35.8500, 174.0182]
];

const kirikopuniBypass = [
  [-35.8637, 174.0185],
  [-35.8640, 174.0170],
  [-35.8643, 174.0162],
  [-35.8648, 174.0157]
];

const tangowahineDargaville = [
  [-35.8685, 173.9307],
  [-35.8688, 173.9259],
  [-35.8701, 173.9186],
  [-35.8702, 173.9162],
  [-35.8698, 173.9131],
  [-35.8694, 173.9118],
  [-35.8693, 173.9107],
  [-35.8694, 173.9091],
  [-35.8701, 173.9077],
  [-35.8707, 173.9068],
  [-35.8711, 173.9059],
  [-35.8715, 173.9039],
  [-35.8724, 173.9019],
  [-35.8727, 173.9000],
  [-35.8726, 173.8980],
  [-35.8729, 173.8966],
  [-35.8745, 173.8940],
  [-35.8752, 173.8931],
  [-35.8800, 173.8889],
  [-35.8813, 173.8880],
  [-35.8911, 173.8855],
  [-35.8943, 173.8859],
  [-35.9042, 173.8909],
  [-35.9086, 173.8931],
  [-35.9100, 173.8933],
  [-35.9121, 173.8925],
  [-35.9135, 173.8908],
  [-35.9141, 173.8884],
  [-35.9148, 173.8689],
  [-35.9156, 173.8569],
  [-35.9162, 173.8548],
  [-35.9190, 173.8506],
  [-35.9195, 173.8488],
  [-35.9206, 173.8473],
  [-35.9219, 173.8466],
  [-35.9242, 173.8461],
  [-35.9259, 173.8452],
  [-35.9275, 173.8447],
  [-35.9290, 173.8450]
];

export const dargaville: Line = {
  name: 'Dargaville',
  state: 'NZ',
  segments: [
    {
      segments: [waiotiraJunctionKirikopuni],
      history: {
        opened: {
          date: '1928-05-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kirikopuniTangowahine],
      history: {
        opened: {
          date: '1931-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kirikopuniLoop, kirikopuniLoopTwo],
      history: {
        opened: {
          date: '1931-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1943-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kirikopuniBypass],
      history: {
        opened: {
          date: '1943-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tangowahineDargaville],
      history: {
        opened: {
          date: '1943-03-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-10-01',
          status: 'closed'
        }]
      }
    }
  ]
}
