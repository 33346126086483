import { Line } from "../../../trackTypes";

const tallawongBellaVista = [
  [-33.6927, 150.9019],
  [-33.6904, 150.9095],
  [-33.6901, 150.9112],
  [-33.6902, 150.9127],
  [-33.6900, 150.9137],
  [-33.6890, 150.9184],
  [-33.6889, 150.9195],
  [-33.6891, 150.9208],
  [-33.6897, 150.9222],
  [-33.6904, 150.9229],
  [-33.6953, 150.9270],
  [-33.6968, 150.9280],
  [-33.7044, 150.9308],
  [-33.7070, 150.9317],
  [-33.7083, 150.9323],
  [-33.7115, 150.9343],
  [-33.7184, 150.9377],
  [-33.7260, 150.9411],
  [-33.7270, 150.9417],
  [-33.7283, 150.9429],
  [-33.7292, 150.9434],
  [-33.7311, 150.9442]
];

const bellaVistaEpping = [
  [-33.7311, 150.9442],
  [-33.7321, 150.9447],
  [-33.7342, 150.9465],
  [-33.7356, 150.9488],
  [-33.7369, 150.9527],
  [-33.7375, 150.9576],
  [-33.7361, 150.9617],
  [-33.7317, 150.9669],
  [-33.7308, 150.9692],
  [-33.7287, 150.9842],
  [-33.7272, 150.9893],
  [-33.7270, 150.9909],
  [-33.7274, 150.9925],
  [-33.7314, 150.9999],
  [-33.7322, 151.0024],
  [-33.7321, 151.0054],
  [-33.7303, 151.0122],
  [-33.7302, 151.0136],
  [-33.7320, 151.0247],
  [-33.7326, 151.0266],
  [-33.7331, 151.0273],
  [-33.7355, 151.0301],
  [-33.7380, 151.0341],
  [-33.7419, 151.0439],
  [-33.7425, 151.0450],
  [-33.7563, 151.0645],
  [-33.7598, 151.0688],
  [-33.7619, 151.0728],
  [-33.7644, 151.0767],
  [-33.7679, 151.0802],
  [-33.7693, 151.0812],
  [-33.7698, 151.0814]
];

const eppingChatswood = [
  [-33.7773, 151.0842],
  [-33.7776, 151.0844],
  [-33.7788, 151.0857],
  [-33.7794, 151.0867],
  [-33.7796, 151.0872],
  [-33.7798, 151.0880],
  [-33.7801, 151.0895],
  [-33.7800, 151.0908],
  [-33.7747, 151.1080],
  [-33.7744, 151.1100],
  [-33.7744, 151.1119],
  [-33.7749, 151.1137],
  [-33.7757, 151.1157],
  [-33.7765, 151.1169],
  [-33.7865, 151.1304],
  [-33.7873, 151.1312],
  [-33.7882, 151.1319],
  [-33.7910, 151.1332],
  [-33.7925, 151.1344],
  [-33.7937, 151.1364],
  [-33.7958, 151.1451],
  [-33.7961, 151.1472],
  [-33.7960, 151.1490],
  [-33.7955, 151.1512],
  [-33.7940, 151.1539],
  [-33.7926, 151.1561],
  [-33.7887, 151.1606],
  [-33.7874, 151.1615],
  [-33.7855, 151.1624],
  [-33.7841, 151.1628],
  [-33.7831, 151.1633],
  [-33.7818, 151.1641],
  [-33.7805, 151.1656],
  [-33.7793, 151.1678],
  [-33.7786, 151.1709],
  [-33.7790, 151.1741],
  [-33.7808, 151.1772],
  [-33.7825, 151.1789],
  [-33.7839, 151.1797],
  [-33.7882, 151.1805]
];

const chatswoodSydenham = [
  [-33.8023, 151.1810],
  [-33.8069, 151.1803],
  [-33.8090, 151.1806],
  [-33.8118, 151.1823],
  [-33.8228, 151.1961],
  [-33.8247, 151.1983],
  [-33.8269, 151.2002],
  [-33.8284, 151.2027],
  [-33.8294, 151.2053],
  [-33.8310, 151.2072],
  [-33.8331, 151.2079],
  [-33.8390, 151.2069],
  [-33.8413, 151.2052],
  [-33.8432, 151.2034],
  [-33.8442, 151.2029],
  [-33.8457, 151.2029],
  [-33.8474, 151.2036],
  [-33.8506, 151.2039],
  [-33.8549, 151.2025],
  [-33.8561, 151.2024],
  [-33.8612, 151.2028],
  [-33.8624, 151.2035],
  [-33.8630, 151.2046],
  [-33.8631, 151.2061],
  [-33.8629, 151.2077],
  [-33.8632, 151.2090],
  [-33.8642, 151.2101],
  [-33.8654, 151.2104],
  [-33.8691, 151.2100],
  [-33.8713, 151.2094],
  [-33.8770, 151.2087],
  [-33.8804, 151.2089],
  [-33.8814, 151.2086],
  [-33.8827, 151.2075],
  [-33.8874, 151.2038],
  [-33.8918, 151.2004],
  [-33.8938, 151.1996],
  [-33.8954, 151.1996],
  [-33.8995, 151.2009],
  [-33.9017, 151.2009],
  [-33.9039, 151.1998],
  [-33.9055, 151.1973],
  [-33.9073, 151.1932],
  [-33.9076, 151.1906],
  [-33.9070, 151.1829],
  [-33.9073, 151.1788],
  [-33.9079, 151.1765],
  [-33.9093, 151.1736],
  [-33.9147, 151.1661]
];

export const metroNorthWest: Line = {
  name: 'Metro North West',
  state: 'NSW',
  segments: [
    {
      segments: [
        tallawongBellaVista,
        {
          segment: bellaVistaEpping,
          type: 'tunnel'
        }
      ],
      history: {
        opened: {
          date: '2019-05-26',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: eppingChatswood,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '2009-02-23',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-09-28',
          status: 'closed'
        }, {
          date: '2019-05-26',
          status: 'open'
        }]
      }
    },
    {
      segments: [{
        segment: chatswoodSydenham,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '2024-08-19',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
