import { Line } from "../../trackTypes";

const line = [
  [-32.4597, 151.1034],
  [-32.4577, 151.1014],
  [-32.4555, 151.1007],
  [-32.4533, 151.1011],
  [-32.4504, 151.1032],
  [-32.4473, 151.1039],
  [-32.4352, 151.0993],
  [-32.4208, 151.0969],
  [-32.4185, 151.0961],
  [-32.4062, 151.0908],
  [-32.4051, 151.0910],
  [-32.4043, 151.0918],
  [-32.4041, 151.0934],
  [-32.4045, 151.0948],
  [-32.4055, 151.0955],
  [-32.4068, 151.0956],
  [-32.4090, 151.0943],
  [-32.4106, 151.0941],
  [-32.4156, 151.0962],
  [-32.4171, 151.0964],
  [-32.4180, 151.0963],
  [-32.4185, 151.0961]
];

export const mountOwen: Line = {
  name: 'Mount Owen',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1996-10-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
