import { Line } from "../../../trackTypes";

const junction = [
  [-31.9198, 115.9233],
  [-31.9210, 115.9252]
];

const tunnel = [
  [-31.9210, 115.9252],
  [-31.9299, 115.9351],
  [-31.9307, 115.9362],
  [-31.9312, 115.9376],
  [-31.9346, 115.9549],
  [-31.9353, 115.9573],
  [-31.9363, 115.9593],
  [-31.9422, 115.9683],
  [-31.9433, 115.9710],
  [-31.9447, 115.9772],
  [-31.9462, 115.9886],
  [-31.9468, 115.9903],
  [-31.9477, 115.9913],
  [-31.9488, 115.9919],
  [-31.9505, 115.9923]
];

const highWycombe = [
  [-31.9505, 115.9923],
  [-31.9511, 115.9923],
  [-31.9544, 115.9921]
];

export const airport: Line = {
  name: 'Airport Line',
  state: 'WA',
  segments: [
    {
      segments: [
        junction,
        {
          segment: tunnel,
          type: 'tunnel'
        },
        highWycombe
      ],
      history: {
        opened: {
          date: '2022-10-09',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
  ]
};
