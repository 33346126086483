import { Line } from "../../../trackTypes";

const maryboroughAvoca = [
  [-37.0545, 143.7402],
  [-37.0555, 143.7390],
  [-37.0560, 143.7380],
  [-37.0564, 143.7365],
  [-37.0581, 143.7188],
  [-37.0582, 143.7174],
  [-37.0582, 143.7158],
  [-37.0568, 143.6975],
  [-37.0548, 143.6702],
  [-37.0547, 143.6681],
  [-37.0566, 143.6317],
  [-37.0566, 143.6296],
  [-37.0547, 143.6078],
  [-37.0530, 143.5874],
  [-37.0529, 143.5851],
  [-37.0532, 143.5820],
  [-37.0568, 143.5538],
  [-37.0573, 143.5515],
  [-37.0579, 143.5493],
  [-37.0701, 143.5156],
  [-37.0811, 143.4790]
];

const avocaArarat = [
  [-37.0811, 143.4790],
  [-37.0820, 143.4759],
  [-37.0823, 143.4744],
  [-37.0825, 143.4715],
  [-37.0830, 143.4699],
  [-37.0839, 143.4678],
  [-37.0852, 143.4661],
  [-37.0940, 143.4588],
  [-37.1193, 143.4420],
  [-37.1235, 143.4398],
  [-37.1274, 143.4385],
  [-37.1357, 143.4366],
  [-37.1465, 143.4325],
  [-37.1473, 143.4321],
  [-37.1710, 143.4111],
  [-37.1756, 143.4063],
  [-37.1778, 143.4049],
  [-37.1820, 143.4036],
  [-37.1834, 143.4027],
  [-37.1841, 143.4016],
  [-37.1879, 143.3946],
  [-37.1934, 143.3861],
  [-37.1977, 143.3779],
  [-37.1981, 143.3762],
  [-37.1981, 143.3751],
  [-37.1979, 143.3736],
  [-37.1961, 143.3678],
  [-37.1957, 143.3660],
  [-37.1955, 143.3643],
  [-37.1950, 143.3581],
  [-37.1944, 143.3540],
  [-37.1944, 143.3516],
  [-37.1947, 143.3491],
  [-37.1951, 143.3473],
  [-37.1986, 143.3372],
  [-37.1989, 143.3356],
  [-37.1990, 143.3338],
  [-37.1988, 143.3319],
  [-37.1978, 143.3276],
  [-37.1974, 143.3255],
  [-37.1964, 143.3165],
  [-37.1961, 143.3149],
  [-37.1953, 143.3124],
  [-37.1906, 143.3019],
  [-37.1902, 143.3006],
  [-37.1889, 143.2921],
  [-37.1882, 143.2897],
  [-37.1770, 143.2581],
  [-37.1766, 143.2563],
  [-37.1737, 143.2377],
  [-37.1732, 143.2364],
  [-37.1724, 143.2350],
  [-37.1707, 143.2332],
  [-37.1699, 143.2320],
  [-37.1666, 143.2243],
  [-37.1662, 143.2227],
  [-37.1662, 143.2208],
  [-37.1670, 143.2126],
  [-37.1674, 143.2105],
  [-37.1682, 143.2087],
  [-37.1694, 143.2071],
  [-37.1709, 143.2059],
  [-37.1777, 143.2023],
  [-37.1790, 143.2014],
  [-37.1854, 143.1967],
  [-37.1866, 143.1954],
  [-37.1874, 143.1942],
  [-37.1936, 143.1803],
  [-37.1941, 143.1782],
  [-37.1942, 143.1763],
  [-37.1941, 143.1745],
  [-37.1940, 143.1735],
  [-37.1933, 143.1707],
  [-37.1926, 143.1692],
  [-37.1914, 143.1674],
  [-37.1862, 143.1624],
  [-37.1852, 143.1609],
  [-37.1842, 143.1586],
  [-37.1838, 143.1563],
  [-37.1837, 143.1544],
  [-37.1840, 143.1526],
  [-37.1919, 143.1203],
  [-37.1942, 143.1109],
  [-37.1947, 143.1096],
  [-37.1982, 143.1015],
  [-37.1986, 143.1000],
  [-37.1986, 143.0988],
  [-37.1971, 143.0759],
  [-37.1973, 143.0733],
  [-37.1973, 143.0716],
  [-37.1970, 143.0671],
  [-37.1968, 143.0658],
  [-37.1963, 143.0634],
  [-37.1954, 143.0495],
  [-37.1955, 143.0474],
  [-37.1997, 143.0310],
  [-37.2003, 143.0297],
  [-37.2012, 143.0285],
  [-37.2070, 143.0220],
  [-37.2080, 143.0210],
  [-37.2093, 143.0203],
  [-37.2116, 143.0195],
  [-37.2127, 143.0190],
  [-37.2134, 143.0185],
  [-37.2184, 143.0140],
  [-37.2192, 143.0130],
  [-37.2202, 143.0113],
  [-37.2213, 143.0082],
  [-37.2219, 143.0073],
  [-37.2223, 143.0068],
  [-37.2531, 142.9745],
  [-37.2570, 142.9713],
  [-37.2582, 142.9701],
  [-37.2672, 142.9579],
  [-37.2680, 142.9566],
  [-37.2690, 142.9549],
  [-37.2704, 142.9518],
  [-37.2712, 142.9506],
  [-37.2725, 142.9494],
  [-37.2745, 142.9485],
  [-37.2796, 142.9480],
  [-37.2805, 142.9477],
  [-37.2811, 142.9473]
];

const araratExit1 = [
  [-37.2811, 142.9473],
  [-37.2821, 142.9462],
  [-37.2825, 142.9452],
  [-37.2829, 142.9433],
  [-37.2829, 142.9422]
];

const araratExit2 = [
  [-37.2811, 142.9473],
  [-37.2817, 142.9469],
  [-37.2824, 142.9469],
  [-37.2830, 142.9470],
  [-37.2834, 142.9474],
  [-37.2840, 142.9485]
];

export const avoca: Line = {
  name: 'Avoca',
  state: 'VIC',
  segments: [
    {
      segments: [maryboroughAvoca],
      history: {
        opened: {
          date: '1876-10-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-08',
          status: 'closed'
        }, {
          date: '1966-10-31',
          status: 'open'
        }, {
          date: '1996-04-28',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [avocaArarat, araratExit1, araratExit2],
      history: {
        opened: {
          date: '1890-11-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-08',
          status: 'closed'
        }, {
          date: '1966-10-31',
          status: 'open'
        }, {
          date: '1996-04-28',
          gauge: 'standard'
        }]
      }
    }
  ]
};
