import { Line } from "../../trackTypes";

const christchurchEastJunction = [
  [-43.5409, 172.6129],
  [-43.5404, 172.6118],
  [-43.5388, 172.6101],
  [-43.5380, 172.6098]
];

const christchurchWestJunction = [
  [-43.5410, 172.6051],
  [-43.5408, 172.6064],
  [-43.5405, 172.6071],
  [-43.5392, 172.6090],
  [-43.5388, 172.6096],
  [-43.5380, 172.6098]
];

const christchurchJunctionWaimakaririRiverDeviationStart = [
  [-43.5380, 172.6098],
  [-43.5248, 172.6080],
  [-43.5233, 172.6074],
  [-43.5220, 172.6067],
  [-43.5208, 172.6064],
  [-43.4881, 172.6059],
  [-43.4863, 172.6062],
  [-43.4842, 172.6069],
  [-43.4737, 172.6118],
  [-43.4713, 172.6143],
  [-43.4692, 172.6182],
  [-43.4679, 172.6198],
  [-43.4306, 172.6528],
  [-43.4236, 172.6572]
];

const waimakaririRiverOld = [
  [-43.4236, 172.6572],
  [-43.4118, 172.6648],
  [-43.4076, 172.6663],
  [-43.4051, 172.6666],
  [-43.4014, 172.6656],
  [-43.3996, 172.6647],
  [-43.3964, 172.6618],
  [-43.3942, 172.6590],
  [-43.3930, 172.6581],
  [-43.3920, 172.6578],
  [-43.3911, 172.6578]
];

const eyretonStart = [
  [-43.4067, 172.6490],
  [-43.3911, 172.6578]
];

const waimakaririRiverDeviation = [
  [-43.4236, 172.6572],
  [-43.4216, 172.6578],
  [-43.4192, 172.6570],
  [-43.4107, 172.6497],
  [-43.4087, 172.6488],
  [-43.4067, 172.6490]
];

const waimakaririRiverDeviationEndKaipapoi = [
  [-43.3911, 172.6578],
  [-43.3901, 172.6579],
  [-43.3861, 172.6568],
  [-43.3851, 172.6562],
  [-43.3828, 172.6540]
];

const kaiapoiSouthbrook = [
  [-43.3828, 172.6540],
  [-43.3811, 172.6522],
  [-43.3795, 172.6495],
  [-43.3781, 172.6450],
  [-43.3765, 172.6428],
  [-43.3744, 172.6412],
  [-43.3707, 172.6403],
  [-43.3694, 172.6396],
  [-43.3307, 172.6036],
  [-43.3290, 172.6025],
  [-43.3253, 172.6016]
];

const southbrookRangiora = [
  [-43.3253, 172.6016],
  [-43.3231, 172.6011],
  [-43.3222, 172.6012],
  [-43.3206, 172.6020],
  [-43.3194, 172.6022],
  [-43.3004, 172.5976]
];

const rangioraAshleyRiver = [
  [-43.3004, 172.5976],
  [-43.2994, 172.5974],
  [-43.2973, 172.5968],
  [-43.2963, 172.5969],
  [-43.2932, 172.5978],
  [-43.2923, 172.5978],
  [-43.2879, 172.5967]
];

const ashleyRiverOld = [
  [-43.2879, 172.5967],
  [-43.2780, 172.5948],
  [-43.2770, 172.5949],
  [-43.2761, 172.5955],
  [-43.2755, 172.5961],
  [-43.2746, 172.5984]
];

const ashleyRiverDeviation = [
  [-43.2879, 172.5967],
  [-43.2785, 172.5956],
  [-43.2768, 172.5960],
  [-43.2756, 172.5969],
  [-43.2746, 172.5984]
];

const ashleyRiverBalcairn = [
  [-43.2746, 172.5984],
  [-43.2737, 172.6007],
  [-43.2731, 172.6029],
  [-43.2727, 172.6056],
  [-43.2724, 172.6087],
  [-43.2701, 172.6176],
  [-43.2681, 172.6298],
  [-43.2661, 172.6353],
  [-43.2624, 172.6399],
  [-43.2599, 172.6416],
  [-43.2584, 172.6437],
  [-43.2420, 172.6861],
  [-43.2409, 172.6875],
  [-43.2397, 172.6883],
  [-43.2080, 172.6967]
];

const balcairnAmberley = [
  [-43.2080, 172.6967],
  [-43.1931, 172.7006],
  [-43.1916, 172.7013],
  [-43.1697, 172.7164],
  [-43.1679, 172.7170],
  [-43.1596, 172.7183],
  [-43.1581, 172.7192],
  [-43.1553, 172.7224],
  [-43.1533, 172.7255]
];

const amberleyWaipara = [
  [-43.1533, 172.7255],
  [-43.1520, 172.7277],
  [-43.1506, 172.7290],
  [-43.1493, 172.7294],
  [-43.1421, 172.7289],
  [-43.1371, 172.7295],
  [-43.1350, 172.7295],
  [-43.1319, 172.7299],
  [-43.1250, 172.7330],
  [-43.1187, 172.7339],
  [-43.0870, 172.7416],
  [-43.0842, 172.7428],
  [-43.0660, 172.7557],
  [-43.0653, 172.7560],
  [-43.0569, 172.7592]
];

const waiparaScargill = [
  [-43.0569, 172.7592],
  [-43.0537, 172.7604],
  [-43.0513, 172.7623],
  [-43.0499, 172.7640],
  [-43.0300, 172.7958],
  [-43.0281, 172.8015],
  [-43.0269, 172.8147],
  [-43.0265, 172.8171],
  [-43.0193, 172.8457],
  [-43.0117, 172.8686],
  [-43.0103, 172.8713],
  [-43.0037, 172.8786],
  [-43.0015, 172.8819],
  [-42.9990, 172.8870],
  [-42.9869, 172.9054],
  [-42.9851, 172.9068],
  [-42.9829, 172.9075],
  [-42.9811, 172.9088],
  [-42.9797, 172.9111],
  [-42.9779, 172.9162],
  [-42.9758, 172.9189],
  [-42.9734, 172.9199],
  [-42.9680, 172.9194],
  [-42.9660, 172.9199],
  [-42.9613, 172.9224],
  [-42.9515, 172.9244],
  [-42.9502, 172.9242],
  [-42.9495, 172.9235],
  [-42.9477, 172.9211],
  [-42.9461, 172.9199],
  [-42.9450, 172.9180],
  [-42.9432, 172.9160],
  [-42.9422, 172.9157],
  [-42.9414, 172.9160],
  [-42.9407, 172.9169],
  [-42.9393, 172.9218],
  [-42.9366, 172.9466]
];

const scargillEthelton = [
  [-42.9366, 172.9466],
  [-42.9365, 172.9471],
  [-42.9345, 172.9536],
  [-42.9331, 172.9560],
  [-42.9310, 172.9581],
  [-42.9295, 172.9602],
  [-42.9280, 172.9637],
  [-42.9251, 172.9675],
  [-42.9190, 172.9708],
  [-42.9173, 172.9723],
  [-42.9162, 172.9740],
  [-42.9144, 172.9775],
  [-42.9118, 172.9810],
  [-42.9040, 172.9939],
  [-42.8961, 173.0124],
  [-42.8931, 173.0169],
  [-42.8902, 173.0231],
  [-42.8898, 173.0249],
  [-42.8902, 173.0266],
  [-42.8911, 173.0285],
  [-42.8913, 173.0303],
  [-42.8910, 173.0320],
  [-42.8901, 173.0332],
  [-42.8881, 173.0347],
  [-42.8861, 173.0350],
  [-42.8850, 173.0348],
  [-42.8841, 173.0344],
  [-42.8830, 173.0345],
  [-42.8822, 173.0353],
  [-42.8819, 173.0369],
  [-42.8822, 173.0382],
  [-42.8846, 173.0424],
  [-42.8853, 173.0444],
  [-42.8859, 173.0482],
  [-42.8866, 173.0508],
  [-42.8877, 173.0538],
  [-42.8879, 173.0563],
  [-42.8854, 173.0664],
  [-42.8850, 173.0677],
  [-42.8838, 173.0697],
  [-42.8834, 173.0712],
  [-42.8832, 173.0730]
];

const etheltonDomett = [
  [-42.8832, 173.0730],
  [-42.8827, 173.0772],
  [-42.8828, 173.0806],
  [-42.8837, 173.0840],
  [-42.8854, 173.0871],
  [-42.8861, 173.0880],
  [-42.8874, 173.0890],
  [-42.8881, 173.0897],
  [-42.8888, 173.0900],
  [-42.8893, 173.0904],
  [-42.8898, 173.0910],
  [-42.8929, 173.0938],
  [-42.8957, 173.0967],
  [-42.8965, 173.0985],
  [-42.8967, 173.1007],
  [-42.8960, 173.1054],
  [-42.8955, 173.1076],
  [-42.8953, 173.1108],
  [-42.8960, 173.1157],
  [-42.8960, 173.1174],
  [-42.8931, 173.1354],
  [-42.8908, 173.1450],
  [-42.8904, 173.1462],
  [-42.8814, 173.1663],
  [-42.8809, 173.1678],
  [-42.8799, 173.1722],
  [-42.8795, 173.1736],
  [-42.8777, 173.1773],
  [-42.8705, 173.1937],
  [-42.8700, 173.1953],
  [-42.8699, 173.1973],
  [-42.8701, 173.2004],
  [-42.8700, 173.2039],
  [-42.8699, 173.2059],
  [-42.8695, 173.2076],
  [-42.8682, 173.2103],
  [-42.8598, 173.2216]
];

const domettCheviot = [
  [-42.8598, 173.2216],
  [-42.8588, 173.2228],
  [-42.8578, 173.2234],
  [-42.8563, 173.2238],
  [-42.8537, 173.2237],
  [-42.8461, 173.2210],
  [-42.8455, 173.2210],
  [-42.8448, 173.2212],
  [-42.8432, 173.2220],
  [-42.8425, 173.2221],
  [-42.8415, 173.2220],
  [-42.8400, 173.2214],
  [-42.8389, 173.2214],
  [-42.8379, 173.2219],
  [-42.8359, 173.2239],
  [-42.8351, 173.2244],
  [-42.8340, 173.2245],
  [-42.8246, 173.2236],
  [-42.8214, 173.2245],
  [-42.8151, 173.2290],
  [-42.8128, 173.2298],
  [-42.8091, 173.2296]
];

const cheviotParnassus = [
  [-42.8091, 173.2296],
  [-42.7969, 173.2290],
  [-42.7925, 173.2296],
  [-42.7912, 173.2300],
  [-42.7891, 173.2300],
  [-42.7858, 173.2285],
  [-42.7814, 173.2251],
  [-42.7738, 173.2226],
  [-42.7723, 173.2226],
  [-42.7671, 173.2240],
  [-42.7658, 173.2250],
  [-42.7354, 173.2711],
  [-42.7323, 173.2741],
  [-42.7260, 173.2781],
  [-42.7173, 173.2849],
  [-42.7102, 173.2922]
];

const parnassusHundalee = [
  [-42.7102, 173.2922],
  [-42.7067, 173.2955],
  [-42.7054, 173.2972],
  [-42.6982, 173.3100],
  [-42.6958, 173.3122],
  [-42.6935, 173.3129],
  [-42.6911, 173.3128],
  [-42.6892, 173.3131],
  [-42.6857, 173.3144],
  [-42.6840, 173.3144],
  [-42.6798, 173.3123],
  [-42.6781, 173.3120],
  [-42.6768, 173.3125],
  [-42.6749, 173.3137],
  [-42.6735, 173.3140],
  [-42.6673, 173.3130],
  [-42.6652, 173.3133],
  [-42.6633, 173.3143],
  [-42.6618, 173.3155],
  [-42.6598, 173.3162],
  [-42.6572, 173.3158],
  [-42.6493, 173.3132],
  [-42.6444, 173.3132],
  [-42.6433, 173.3137],
  [-42.6370, 173.3198],
  [-42.6283, 173.3317],
  [-42.6275, 173.3336],
  [-42.6234, 173.3463],
  [-42.6162, 173.3583],
  [-42.6129, 173.3655],
  [-42.6120, 173.3680],
  [-42.6088, 173.3733],
  [-42.6081, 173.3755],
  [-42.6067, 173.3778],
  [-42.6059, 173.3797],
  [-42.6041, 173.3820],
  [-42.6035, 173.3835],
  [-42.6013, 173.3910],
  [-42.6012, 173.3928],
  [-42.6017, 173.3948],
  [-42.6019, 173.4036],
  [-42.6017, 173.4056],
  [-42.6007, 173.4080],
  [-42.6004, 173.4098],
  [-42.6005, 173.4123],
  [-42.6001, 173.4136],
  [-42.5994, 173.4146],
  [-42.5987, 173.4155],
  [-42.5983, 173.4164],
  [-42.5981, 173.4178],
  [-42.5988, 173.4259],
  [-42.5993, 173.4277],
  [-42.6001, 173.4291],
  [-42.6049, 173.4344],
  [-42.6059, 173.4360],
  [-42.6083, 173.4426],
  [-42.6085, 173.4451],
  [-42.6074, 173.4495],
  [-42.6072, 173.4516],
  [-42.6076, 173.4533],
  [-42.6081, 173.4544],
  [-42.6089, 173.4554],
  [-42.6094, 173.4572],
  [-42.6095, 173.4594],
  [-42.6091, 173.4609],
  [-42.6084, 173.4621],
  [-42.5928, 173.4772]
];

const hundaleeTunnelNo1 = [
  [-42.5928, 173.4772],
  [-42.5910, 173.4790],
  [-42.5902, 173.4803],
  [-42.5882, 173.4818],
  [-42.5845, 173.4833],
  [-42.5825, 173.4848],
  [-42.5810, 173.4871],
  [-42.5804, 173.4887],
  [-42.5798, 173.4896],
  [-42.5776, 173.4909],
  [-42.5770, 173.4911],
  [-42.5758, 173.4913],
  [-42.5752, 173.4919],
  [-42.5748, 173.4926],
  [-42.5747, 173.4927]
];

const tunnelNo1 = [
  [-42.5747, 173.4927],
  [-42.5742, 173.4932]
];

const tunnelNo1TunnelNo2 = [
  [-42.5742, 173.4932],
  [-42.5741, 173.4933],
  [-42.5726, 173.4940],
  [-42.5716, 173.4945],
  [-42.5704, 173.4948],
  [-42.5696, 173.4953]
];

const tunnelNo2 = [
  [-42.5696, 173.4953],
  [-42.5692, 173.4957]
];

const tunnelNo2AmuraBluffTunnel = [
  [-42.5692, 173.4957],
  [-42.5689, 173.4961],
  [-42.5668, 173.4994],
  [-42.5660, 173.5000],
  [-42.5653, 173.5002],
  [-42.5636, 173.5002],
  [-42.5605, 173.5001],
  [-42.5575, 173.5001]
];

const amuriBluffTunnel = [
  [-42.5575, 173.5001],
  [-42.5488, 173.5013]
];

const amuriBluffTunnelTunnelNo4 = [
  [-42.5488, 173.5013],
  [-42.5485, 173.5012],
  [-42.5455, 173.5003],
  [-42.5448, 173.5003],
  [-42.5438, 173.5007],
  [-42.5429, 173.5008],
  [-42.5420, 173.5007],
  [-42.5410, 173.5009],
  [-42.5388, 173.5025],
  [-42.5379, 173.5028],
  [-42.5370, 173.5027],
  [-42.5356, 173.5029]
];

const oldStartTunnelNo4 = [
  [-42.5356, 173.5029],
  [-42.5351, 173.5028]
];

const tunnelNo4 = [
  [-42.5351, 173.5028],
  [-42.5328, 173.5025]
];

const tunnelNo4OldEnd = [
  [-42.5328, 173.5025],
  [-42.5323, 173.5026]
];

const tunnelNo4Deviation = [
  [-42.5356, 173.5029],
  [-42.5338, 173.5032],
  [-42.5333, 173.5031],
  [-42.5323, 173.5026]
];

const tunnelNo4TunnelNo5 = [
  [-42.5323, 173.5026],
  [-42.5312, 173.5020],
  [-42.5304, 173.5018],
  [-42.5292, 173.5016],
  [-42.5269, 173.5019],
  [-42.5259, 173.5022],
  [-42.5254, 173.5028],
  [-42.5247, 173.5038],
  [-42.5238, 173.5045],
  [-42.5215, 173.5049],
  [-42.5209, 173.5049],
  [-42.5198, 173.5048],
  [-42.5186, 173.5052],
  [-42.5179, 173.5059],
  [-42.5171, 173.5069],
  [-42.5164, 173.5073],
  [-42.5156, 173.5073],
  [-42.5127, 173.5063],
  [-42.5117, 173.5064],
  [-42.5067, 173.5079],
  [-42.5059, 173.5084],
  [-42.5050, 173.5096],
  [-42.5045, 173.5107],
  [-42.5036, 173.5117],
  [-42.5026, 173.5121],
  [-42.5019, 173.5127],
  [-42.5000, 173.5160],
  [-42.4975, 173.5187],
  [-42.4967, 173.5193],
  [-42.4951, 173.5197],
  [-42.4945, 173.5200],
  [-42.4937, 173.5206],
  [-42.4874, 173.5241],
  [-42.4866, 173.5247],
  [-42.4845, 173.5273]
];

const tunnelNo5 = [
  [-42.4845, 173.5273],
  [-42.4837, 173.5279],
  [-42.4827, 173.5282]
];

const tunnelNo5TunnelNo6 = [
  [-42.4827, 173.5282],
  [-42.4823, 173.5282],
  [-42.4798, 173.5281],
  [-42.4785, 173.5286],
  [-42.4776, 173.5299],
  [-42.4769, 173.5320],
  [-42.4765, 173.5327],
  [-42.4758, 173.5332],
  [-42.4747, 173.5335],
  [-42.4740, 173.5340],
  [-42.4721, 173.5355],
  [-42.4712, 173.5360],
  [-42.4701, 173.5364],
  [-42.4669, 173.5381],
  [-42.4663, 173.5388],
  [-42.4657, 173.5402],
  [-42.4653, 173.5409],
  [-42.4631, 173.5430],
  [-42.4625, 173.5439],
  [-42.4619, 173.5444],
  [-42.4612, 173.5449],
  [-42.4607, 173.5454],
  [-42.4604, 173.5459]
];

const tunnelNo6 = [
  [-42.4604, 173.5459],
  [-42.4599, 173.5465],
  [-42.4595, 173.5469]
];

const tunnelNo6RaramaiTunnel = [
  [-42.4595, 173.5469],
  [-42.4590, 173.5474],
  [-42.4585, 173.5484],
  [-42.4584, 173.5489]
];

const raramaiTunnel = [
  [-42.4584, 173.5489],
  [-42.4584, 173.5502]
];

const raramaiTunnelTunnelNo8 = [
  [-42.4584, 173.5502],
  [-42.4583, 173.5509],
  [-42.4581, 173.5518],
  [-42.4578, 173.5527],
  [-42.4577, 173.5536],
  [-42.4579, 173.5558],
  [-42.4579, 173.5559]
];

const tunnelNo8 = [
  [-42.4579, 173.5559],
  [-42.4579, 173.5565],
  [-42.4578, 173.5571],
  [-42.4574, 173.5577]
];

const tunnelNo8TunnelNo9 = [
  [-42.4574, 173.5577],
  [-42.4572, 173.5580],
  [-42.4568, 173.5588],
  [-42.4563, 173.5600],
  [-42.4555, 173.5613],
  [-42.4552, 173.5619],
  [-42.4548, 173.5628],
  [-42.4544, 173.5635],
  [-42.4538, 173.5641],
  [-42.4535, 173.5645]
];

const tunnelNo9 = [
  [-42.4535, 173.5645],
  [-42.4522, 173.5670]
];

const tunnelNo9TunnelNo10 = [
  [-42.4522, 173.5670],
  [-42.4517, 173.5678]
];

const tunnelNo10 = [
  [-42.4517, 173.5678],
  [-42.4511, 173.5687]
];

const tunnelNo10KowhaiTunnel = [
  [-42.4511, 173.5687],
  [-42.4508, 173.5692],
  [-42.4506, 173.5696],
  [-42.4504, 173.5704],
  [-42.4502, 173.5725],
  [-42.4497, 173.5738],
  [-42.4496, 173.5740]
];

const kowhaiTunnel = [
  [-42.4496, 173.5740],
  [-42.4482, 173.5772]
];

const kowhaiTunnelParatitahiTunnel = [
  [-42.4482, 173.5772],
  [-42.4479, 173.5780],
  [-42.4478, 173.5792]
];

const paratitahiTunnel = [
  [-42.4478, 173.5792],
  [-42.4475, 173.5808],
  [-42.4466, 173.5822],
  [-42.4452, 173.5828],
  [-42.4441, 173.5836],
  [-42.4433, 173.5850],
  [-42.4427, 173.5857],
  [-42.4421, 173.5862]
];

const paratitahiTunnelTunnelNo13 = [
  [-42.4421, 173.5862],
  [-42.4417, 173.5863],
  [-42.4408, 173.5863]
];

const tunnelNo13 = [
  [-42.4408, 173.5863],
  [-42.4392, 173.5865]
];

const tunnelNo13kahutaraTunnel = [
  [-42.4392, 173.5865],
  [-42.4382, 173.5866]
];

const kahutaraTunnel = [
  [-42.4382, 173.5866],
  [-42.4352, 173.5851],
  [-42.4342, 173.5852]
];

const kahutaraTunnelTunnelNo15 = [
  [-42.4342, 173.5852],
  [-42.4316, 173.5868],
  [-42.4256, 173.5910],
  [-42.4243, 173.5929],
  [-42.4166, 173.6116],
  [-42.4074, 173.6258],
  [-42.4069, 173.6273],
  [-42.4068, 173.6288],
  [-42.4073, 173.6372],
  [-42.4069, 173.6388],
  [-42.4059, 173.6403],
  [-42.4020, 173.6427],
  [-42.3979, 173.6461],
  [-42.3963, 173.6487],
  [-42.3955, 173.6524],
  [-42.3954, 173.6540],
  [-42.3956, 173.6565],
  [-42.3966, 173.6588],
  [-42.3978, 173.6606],
  [-42.3984, 173.6621],
  [-42.3998, 173.6691],
  [-42.4018, 173.6752],
  [-42.4019, 173.6767],
  [-42.4016, 173.6778],
  [-42.3998, 173.6804],
  [-42.3991, 173.6810],
  [-42.3981, 173.6811],
  [-42.3954, 173.6805],
  [-42.3871, 173.6808],
  [-42.3822, 173.6815],
  [-42.3709, 173.6843],
  [-42.3653, 173.6870],
  [-42.3594, 173.6910],
  [-42.3560, 173.6931],
  [-42.3523, 173.6962],
  [-42.3437, 173.7074],
  [-42.3378, 173.7164],
  [-42.3279, 173.7284],
  [-42.3163, 173.7486],
  [-42.3149, 173.7502],
  [-42.3119, 173.7517],
  [-42.3084, 173.7519],
  [-42.3063, 173.7515],
  [-42.3049, 173.7501],
  [-42.3041, 173.7482],
  [-42.3029, 173.7470],
  [-42.3018, 173.7466],
  [-42.3004, 173.7470],
  [-42.2948, 173.7502],
  [-42.2933, 173.7514],
  [-42.2873, 173.7573],
  [-42.2865, 173.7583],
  [-42.2858, 173.7601],
  [-42.2810, 173.7665],
  [-42.2800, 173.7675],
  [-42.2794, 173.7684],
  [-42.2790, 173.7694]
];

const tunnelNo15 = [
  [-42.2790, 173.7694],
  [-42.2783, 173.7720]
];

const tunnelNo15TunnelNo16 = [
  [-42.2783, 173.7720],
  [-42.2778, 173.7741]
];

const tunnelNo16 = [
  [-42.2778, 173.7741],
  [-42.2775, 173.7756]
];

const tunnelNo16TunnelNo17 = [
  [-42.2775, 173.7756],
  [-42.2774, 173.7767],
  [-42.2776, 173.7781]
];

const tunnelNo17 = [
  [-42.2776, 173.7781],
  [-42.2775, 173.7794],
  [-42.2772, 173.7804]
];

const tunnelNo17ShandsTunnel = [
  [-42.2772, 173.7804],
  [-42.2767, 173.7814],
  [-42.2765, 173.7822],
  [-42.2765, 173.7834],
  [-42.2763, 173.7842],
  [-42.2754, 173.7867],
  [-42.2751, 173.7881],
  [-42.2746, 173.7892],
  [-42.2728, 173.7911],
  [-42.2723, 173.7918],
  [-42.2714, 173.7933],
  [-42.2706, 173.7941],
  [-42.2701, 173.7948],
  [-42.2697, 173.7966],
  [-42.2693, 173.7976],
  [-42.2686, 173.7990],
  [-42.2653, 173.8060],
  [-42.2645, 173.8070],
  [-42.2614, 173.8100],
  [-42.2606, 173.8104],
  [-42.2597, 173.8104],
  [-42.2592, 173.8102]
];

const shandsTunnel = [
  [-42.2592, 173.8102],
  [-42.2570, 173.8090]
];

const shandsTunnelTunnelNo19 = [
  [-42.2570, 173.8090],
  [-42.2568, 173.8089],
  [-42.2558, 173.8090],
  [-42.2549, 173.8097],
  [-42.2540, 173.8123],
  [-42.2533, 173.8136],
  [-42.2531, 173.8144],
  [-42.2527, 173.8159],
  [-42.2521, 173.8175],
  [-42.2518, 173.8190],
  [-42.2510, 173.8208],
  [-42.2506, 173.8226],
  [-42.2492, 173.8260]
];

const tunnelNo19 = [
  [-42.2492, 173.8260],
  [-42.2484, 173.8271],
  [-42.2450, 173.8303]
];

const tunnelNo19TunnelNo20 = [
  [-42.2450, 173.8303],
  [-42.2446, 173.8309],
  [-42.2443, 173.8318],
  [-42.2442, 173.8331],
  [-42.2438, 173.8342],
  [-42.2431, 173.8353],
  [-42.2419, 173.8365],
  [-42.2400, 173.8390],
  [-42.2365, 173.8416],
  [-42.2358, 173.8425],
  [-42.2351, 173.8448],
  [-42.2345, 173.8458],
  [-42.2335, 173.8465],
  [-42.2319, 173.8472],
  [-42.2278, 173.8500],
  [-42.2264, 173.8517],
  [-42.2252, 173.8546],
  [-42.2242, 173.8559],
  [-42.2229, 173.8564],
  [-42.2217, 173.8563],
  [-42.2209, 173.8565]
];

const tunnelNo20 = [
  [-42.2209, 173.8565],
  [-42.2197, 173.8570]
];

const tunnelNo20Wharanui = [
  [-42.2197, 173.8570],
  [-42.2191, 173.8573],
  [-42.2185, 173.8577],
  [-42.2177, 173.8586],
  [-42.2166, 173.8606],
  [-42.2162, 173.8625],
  [-42.2164, 173.8647],
  [-42.2167, 173.8661],
  [-42.2167, 173.8682],
  [-42.2160, 173.8698],
  [-42.2143, 173.8712],
  [-42.2131, 173.8716],
  [-42.2116, 173.8715],
  [-42.2102, 173.8720],
  [-42.2088, 173.8732],
  [-42.2075, 173.8748],
  [-42.2063, 173.8757],
  [-42.2050, 173.8759],
  [-42.2036, 173.8756],
  [-42.2017, 173.8758],
  [-42.1995, 173.8767],
  [-42.1942, 173.8808],
  [-42.1868, 173.8884],
  [-42.1828, 173.8935],
  [-42.1782, 173.9010],
  [-42.1705, 173.9119],
  [-42.1693, 173.9131],
  [-42.1602, 173.9180],
  [-42.1590, 173.9193],
  [-42.1583, 173.9208],
  [-42.1557, 173.9302],
  [-42.1542, 173.9323],
  [-42.1523, 173.9329],
  [-42.1506, 173.9323],
  [-42.1411, 173.9248],
  [-42.1385, 173.9235],
  [-42.1360, 173.9232],
  [-42.1214, 173.9244],
  [-42.1188, 173.9250],
  [-42.1052, 173.9294],
  [-42.1033, 173.9298],
  [-42.0982, 173.9306],
  [-42.0839, 173.9371],
  [-42.0755, 173.9432],
  [-42.0653, 173.9492],
  [-42.0559, 173.9555],
  [-42.0510, 173.9601],
  [-42.0387, 173.9712],
  [-42.0330, 173.9776],
  [-42.0205, 173.9906],
  [-42.0155, 173.9963],
  [-42.0140, 173.9972],
  [-42.0131, 173.9982],
  [-42.0107, 174.0025],
  [-42.0071, 174.0073],
  [-42.0056, 174.0086],
  [-41.9988, 174.0114],
  [-41.9974, 174.0126],
  [-41.9964, 174.0142],
  [-41.9958, 174.0149],
  [-41.9934, 174.0166],
  [-41.9925, 174.0178],
  [-41.9888, 174.0208],
  [-41.9864, 174.0243],
  [-41.9818, 174.0291],
  [-41.9773, 174.0358],
  [-41.9745, 174.0389],
  [-41.9737, 174.0400],
  [-41.9721, 174.0438],
  [-41.9710, 174.0455],
  [-41.9569, 174.0585],
  [-41.9540, 174.0623],
  [-41.9528, 174.0649],
  [-41.9512, 174.0678],
  [-41.9490, 174.0699],
  [-41.9445, 174.0760],
  [-41.9433, 174.0774],
  [-41.9400, 174.0802],
  [-41.9371, 174.0819],
  [-41.9343, 174.0828],
  [-41.9314, 174.0846],
  [-41.9293, 174.0868],
  [-41.9249, 174.0930],
  [-41.9243, 174.0936]
];

const wharanuiTunnelTarBarrelTunnel = [
  [-41.9243, 174.0936],
  [-41.9147, 174.1024],
  [-41.9086, 174.1072],
  [-41.9034, 174.1109],
  [-41.8940, 174.1160],
  [-41.8930, 174.1162],
  [-41.8916, 174.1162],
  [-41.8905, 174.1166]
];

const oldStartTarBarrelTunnel = [
  [-41.8905, 174.1165],
  [-41.8898, 174.1165],
  [-41.8881, 174.1163],
  [-41.8876, 174.1164],
  [-41.8872, 174.1166]
];

const tarBarrelTunnel = [
  [-41.8872, 174.1166],
  [-41.8868, 174.1170],
  [-41.8860, 174.1181]
];

const tarBarrelTunnelOldEnd = [
  [-41.8860, 174.1181],
  [-41.8856, 174.1188],
  [-41.8852, 174.1193],
  [-41.8848, 174.1196]
];

const tarBarrelDeviation = [
  [-41.8905, 174.1166],
  [-41.8877, 174.1182],
  [-41.8860, 174.1191],
  [-41.8848, 174.1196]
];

const tarBarrelTunnelWard = [
  [-41.8848, 174.1196],
  [-41.8843, 174.1197],
  [-41.8809, 174.1201],
  [-41.8791, 174.1207],
  [-41.8777, 174.1209],
  [-41.8751, 174.1208],
  [-41.8691, 174.1217],
  [-41.8671, 174.1216],
  [-41.8597, 174.1199],
  [-41.8587, 174.1199],
  [-41.8577, 174.1204],
  [-41.8557, 174.1222],
  [-41.8542, 174.1230],
  [-41.8501, 174.1235],
  [-41.8487, 174.1232],
  [-41.8470, 174.1223],
  [-41.8431, 174.1220],
  [-41.8414, 174.1226],
  [-41.8388, 174.1248],
  [-41.8349, 174.1273],
  [-41.8338, 174.1283],
  [-41.8322, 174.1305],
  [-41.8289, 174.1383],
  [-41.8284, 174.1406],
  [-41.8281, 174.1459],
  [-41.8279, 174.1474],
  [-41.8271, 174.1508]
];

const wardSeddon = [
  [-41.8271, 174.1508],
  [-41.8263, 174.1540],
  [-41.8258, 174.1553],
  [-41.8228, 174.1592],
  [-41.8207, 174.1609],
  [-41.8182, 174.1615],
  [-41.8096, 174.1605],
  [-41.7876, 174.1522],
  [-41.7857, 174.1523],
  [-41.7659, 174.1560],
  [-41.7643, 174.1568],
  [-41.7618, 174.1590],
  [-41.7603, 174.1599],
  [-41.7586, 174.1601],
  [-41.7514, 174.1589],
  [-41.7438, 174.1563],
  [-41.7424, 174.1555],
  [-41.7215, 174.1373],
  [-41.7206, 174.1369],
  [-41.7197, 174.1370],
  [-41.7167, 174.1383],
  [-41.7155, 174.1392],
  [-41.7147, 174.1401],
  [-41.7136, 174.1409],
  [-41.7111, 174.1420],
  [-41.7103, 174.1426],
  [-41.7088, 174.1443],
  [-41.7053, 174.1467],
  [-41.7044, 174.1471],
  [-41.7030, 174.1473],
  [-41.6998, 174.1475],
  [-41.6984, 174.1470],
  [-41.6959, 174.1453],
  [-41.6931, 174.1438],
  [-41.6924, 174.1432],
  [-41.6917, 174.1421],
  [-41.6909, 174.1413],
  [-41.6901, 174.1407],
  [-41.6892, 174.1393],
  [-41.6863, 174.1293],
  [-41.6860, 174.1277],
  [-41.6852, 174.1168],
  [-41.6845, 174.1146],
  [-41.6825, 174.1116],
  [-41.6805, 174.1098],
  [-41.6788, 174.1088],
  [-41.6773, 174.1075],
  [-41.6762, 174.1049],
  [-41.6759, 174.1023],
  [-41.6761, 174.1002],
  [-41.6799, 174.0871],
  [-41.6805, 174.0861],
  [-41.6814, 174.0852],
  [-41.6819, 174.0837],
  [-41.6817, 174.0823],
  [-41.6809, 174.0813],
  [-41.6786, 174.0803],
  [-41.6768, 174.0787],
  [-41.6742, 174.0757]
];

const seddonTunnelNo22 = [
  [-41.6742, 174.0757],
  [-41.6714, 174.0725],
  [-41.6704, 174.0719],
  [-41.6693, 174.0717],
  [-41.6685, 174.0720],
  [-41.6616, 174.0768],
  [-41.6607, 174.0772],
  [-41.6544, 174.0777],
  [-41.6522, 174.0773],
  [-41.6293, 174.0660],
  [-41.6259, 174.0649],
  [-41.6247, 174.0640],
  [-41.6212, 174.0605],
  [-41.6205, 174.0600],
  [-41.6194, 174.0596],
  [-41.6182, 174.0596],
  [-41.6167, 174.0599],
  [-41.6156, 174.0598],
  [-41.6144, 174.0593],
  [-41.6131, 174.0581],
  [-41.6118, 174.0562],
  [-41.6110, 174.0555],
  [-41.6092, 174.0546],
  [-41.6088, 174.0543],
  [-41.6084, 174.0537],
  [-41.6078, 174.0521],
  [-41.6073, 174.0514],
  [-41.6050, 174.0493],
  [-41.6043, 174.0489],
  [-41.6037, 174.0489],
  [-41.6030, 174.0491],
  [-41.6020, 174.0498],
  [-41.6011, 174.0500],
  [-41.5999, 174.0499],
  [-41.5988, 174.0501],
  [-41.5971, 174.0512],
  [-41.5955, 174.0517],
  [-41.5948, 174.0517],
  [-41.5942, 174.0515],
  [-41.5937, 174.0512],
  [-41.5933, 174.0510],
  [-41.5919, 174.0508],
  [-41.5912, 174.0509],
  [-41.5905, 174.0513],
  [-41.5898, 174.0520],
  [-41.5890, 174.0524],
  [-41.5881, 174.0523],
  [-41.5871, 174.0519],
  [-41.5861, 174.0517],
  [-41.5849, 174.0520],
  [-41.5815, 174.0540],
  [-41.5801, 174.0544],
  [-41.5788, 174.0543],
  [-41.5774, 174.0537],
  [-41.5762, 174.0537],
  [-41.5738, 174.0546],
  [-41.5731, 174.0545],
  [-41.5727, 174.0542],
  [-41.5722, 174.0537],
  [-41.5717, 174.0535],
  [-41.5711, 174.0535],
  [-41.5678, 174.0551],
  [-41.5669, 174.0550],
  [-41.5661, 174.0540],
  [-41.5660, 174.0530],
  [-41.5661, 174.0519],
  [-41.5657, 174.0507],
  [-41.5650, 174.0501],
  [-41.5643, 174.0501],
  [-41.5635, 174.0507],
  [-41.5624, 174.0528],
  [-41.5617, 174.0533]
];

const tunnelNo22 = [
  [-41.5617, 174.0533],
  [-41.5610, 174.0533]
];

const tunnelNo22TunnelNo23 = [
  [-41.5610, 174.0533],
  [-41.5608, 174.0532],
  [-41.5603, 174.0529],
  [-41.5595, 174.0527],
  [-41.5589, 174.0527],
  [-41.5580, 174.0523],
  [-41.5562, 174.0498],
  [-41.5561, 174.0496]
];

const tunnelNo23 = [
  [-41.5561, 174.0496],
  [-41.5560, 174.0487]
];

const tunnelNo23Blenheim = [
  [-41.5560, 174.0487],
  [-41.5562, 174.0476],
  [-41.5568, 174.0469],
  [-41.5577, 174.0465],
  [-41.5586, 174.0457],
  [-41.5595, 174.0436],
  [-41.5596, 174.0423],
  [-41.5593, 174.0412],
  [-41.5584, 174.0403],
  [-41.5574, 174.0402],
  [-41.5553, 174.0410],
  [-41.5535, 174.0413],
  [-41.5501, 174.0414],
  [-41.5489, 174.0410],
  [-41.5479, 174.0398],
  [-41.5436, 174.0315],
  [-41.5395, 174.0217],
  [-41.5387, 174.0193],
  [-41.5372, 174.0139],
  [-41.5350, 174.0086],
  [-41.5344, 174.0073],
  [-41.5259, 173.9953],
  [-41.5249, 173.9930],
  [-41.5232, 173.9846],
  [-41.5229, 173.9836],
  [-41.5150, 173.9615],
  [-41.5140, 173.9603],
  [-41.5130, 173.9598],
  [-41.5112, 173.9597],
  [-41.5099, 173.9599],
  [-41.5092, 173.9599]
];

const blenheimOpawaRiver = [
  [-41.5092, 173.9599],
  [-41.5075, 173.9597],
  [-41.5067, 173.9599],
  [-41.5057, 173.9605],
  [-41.5050, 173.9608],
  [-41.5032, 173.9613],
  [-41.4992, 173.9631],
  [-41.4955, 173.9645],
  [-41.4943, 173.9648],
  [-41.4883, 173.9651],
  [-41.4822, 173.9657],
  [-41.4813, 173.9656],
  [-41.4786, 173.9649],
  [-41.4777, 173.9645],
  [-41.4764, 173.9631],
  [-41.4756, 173.9626],
  [-41.4737, 173.9622],
  [-41.4612, 173.9623],
  [-41.4442, 173.9649],
  [-41.4411, 173.9657],
  [-41.4381, 173.9658],
  [-41.4368, 173.9656],
  [-41.4321, 173.9639]
];

const opawaRiverTunnelNo24 = [
  [-41.4321, 173.9639],
  [-41.4307, 173.9630],
  [-41.4292, 173.9615],
  [-41.4284, 173.9606],
  [-41.4273, 173.9601],
  [-41.4261, 173.9602],
  [-41.4252, 173.9601],
  [-41.4236, 173.9593],
  [-41.4230, 173.9591],
  [-41.4209, 173.9591]
];

const tunnelNo24 = [
  [-41.4209, 173.9591],
  [-41.4204, 173.9591]
];

const tunnelNo24Picton = [
  [-41.4204, 173.9591],
  [-41.4193, 173.9595],
  [-41.4157, 173.9626],
  [-41.4151, 173.9634],
  [-41.4140, 173.9641],
  [-41.4128, 173.9639],
  [-41.4119, 173.9632],
  [-41.4114, 173.9619],
  [-41.4105, 173.9586],
  [-41.4100, 173.9576],
  [-41.4089, 173.9567],
  [-41.4082, 173.9556],
  [-41.4076, 173.9545],
  [-41.4072, 173.9533],
  [-41.4062, 173.9524],
  [-41.4044, 173.9517],
  [-41.4011, 173.9495],
  [-41.4003, 173.9493],
  [-41.3997, 173.9493],
  [-41.3989, 173.9491],
  [-41.3960, 173.9472],
  [-41.3951, 173.9470],
  [-41.3942, 173.9473],
  [-41.3934, 173.9479],
  [-41.3924, 173.9481],
  [-41.3916, 173.9479],
  [-41.3907, 173.9473],
  [-41.3896, 173.9473],
  [-41.3888, 173.9475],
  [-41.3878, 173.9475],
  [-41.3869, 173.9471],
  [-41.3838, 173.9443],
  [-41.3832, 173.9435],
  [-41.3826, 173.9427],
  [-41.3819, 173.9421],
  [-41.3810, 173.9418],
  [-41.3791, 173.9417],
  [-41.3776, 173.9412],
  [-41.3732, 173.9407],
  [-41.3717, 173.9410],
  [-41.3629, 173.9467],
  [-41.3615, 173.9474],
  [-41.3606, 173.9480],
  [-41.3569, 173.9491],
  [-41.3554, 173.9499],
  [-41.3343, 173.9666],
  [-41.3330, 173.9671],
  [-41.3324, 173.9673],
  [-41.3317, 173.9676],
  [-41.3260, 173.9710],
  [-41.3237, 173.9722],
  [-41.3221, 173.9726],
  [-41.3211, 173.9725],
  [-41.3181, 173.9713],
  [-41.3146, 173.9703],
  [-41.3141, 173.9702],
  [-41.3133, 173.9705],
  [-41.3117, 173.9716],
  [-41.3108, 173.9727],
  [-41.3100, 173.9743],
  [-41.3077, 173.9847],
  [-41.3070, 173.9865],
  [-41.3065, 173.9873],
  [-41.3062, 173.9877],
  [-41.3059, 173.9887],
  [-41.3056, 173.9907],
  [-41.3053, 173.9917],
  [-41.3042, 173.9936],
  [-41.3036, 173.9943],
  [-41.3030, 173.9950],
  [-41.3012, 173.9993],
  [-41.2998, 174.0017],
  [-41.2996, 174.0027],
  [-41.2997, 174.0040],
  [-41.2995, 174.0050],
  [-41.2991, 174.0057],
  [-41.2986, 174.0061],
  [-41.2973, 174.0064],
  [-41.2958, 174.0061],
  [-41.2942, 174.0060],
  [-41.2928, 174.0055],
  [-41.2882, 174.0046]
];

const pictonPictonWharf = [
  [-41.2882, 174.0046],
  [-41.2851, 174.0041],
  [-41.2845, 174.0042],
  [-41.2840, 174.0045],
  [-41.2824, 174.0064]
];

export const mainNorthLine: Line = {
  name: 'Main North Line',
  state: 'NZ',
  segments: [
    {
      segments: [christchurchEastJunction],
      history: {
        opened: {
          date: '1872-04-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }, {
          date: '1993-04-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [christchurchWestJunction],
      history: {
        opened: {
          date: '1993-04-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [christchurchJunctionWaimakaririRiverDeviationStart],
      history: {
        opened: {
          date: '1872-04-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [waimakaririRiverOld],
      history: {
        opened: {
          date: '1872-04-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }, {
          date: '1958-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eyretonStart],
      history: {
        opened: {
          date: '1875-12-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waimakaririRiverDeviation],
      history: {
        opened: {
          date: '1958-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waimakaririRiverDeviationEndKaipapoi],
      history: {
        opened: {
          date: '1872-04-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [kaiapoiSouthbrook],
      history: {
        opened: {
          date: '1872-07-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [southbrookRangiora],
      history: {
        opened: {
          date: '1872-11-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [rangioraAshleyRiver],
      history: {
        opened: {
          date: '1875-11-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [{
        date: '1961-12-18',
        original: [ashleyRiverOld],
        deviation: [ashleyRiverDeviation]
      }],
      history: {
        opened: {
          date: '1875-11-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [ashleyRiverBalcairn],
      history: {
        opened: {
          date: '1875-11-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [balcairnAmberley],
      history: {
        opened: {
          date: '1876-02-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [amberleyWaipara],
      history: {
        opened: {
          date: '1880-09-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waiparaScargill],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [scargillEthelton],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [etheltonDomett],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [domettCheviot],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cheviotParnassus],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [parnassusHundalee],
      history: {
        opened: {
          date: '1939-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        hundaleeTunnelNo1,
        {
          name: 'Tunnel No 1',
          segment: tunnelNo1,
          type: 'tunnel'
        },
        tunnelNo1TunnelNo2,
        {
          name: 'Tunnel No 2',
          segment: tunnelNo2,
          type: 'tunnel'
        },
        tunnelNo2AmuraBluffTunnel,
        {
          name: 'Amuri Bluff Tunnel',
          segment: amuriBluffTunnel,
          type: 'tunnel'
        },
        amuriBluffTunnelTunnelNo4,
        {
          date: '1956-01-01',
          original: [
            oldStartTunnelNo4,
            {
              name: 'Tunnel No 4',
              segment: tunnelNo4,
              type: 'tunnel'
            },
            tunnelNo4OldEnd
          ],
          deviation: [tunnelNo4Deviation]
        },
        tunnelNo4TunnelNo5,
        {
          name: 'Tunnel No 5',
          segment: tunnelNo5,
          type: 'tunnel'
        },
        tunnelNo5TunnelNo6,
        {
          name: 'Tunnel No 6',
          segment: tunnelNo6,
          type: 'tunnel'
        },
        tunnelNo6RaramaiTunnel,
        {
          name: 'Raramai Tunnel',
          segment: raramaiTunnel,
          type: 'tunnel'
        },
        raramaiTunnelTunnelNo8,
        {
          name: 'Tunnel No 8',
          segment: tunnelNo8,
          type: 'tunnel'
        },
        tunnelNo8TunnelNo9,
        {
          name: 'Tunnel No 9',
          segment: tunnelNo9,
          type: 'tunnel'
        },
        tunnelNo9TunnelNo10,
        {
          name: 'Tunnel No 10',
          segment: tunnelNo10,
          type: 'tunnel'
        },
        tunnelNo10KowhaiTunnel,
        {
          name: 'Kowhai Tunnel',
          segment: kowhaiTunnel,
          type: 'tunnel'
        },
        kowhaiTunnelParatitahiTunnel,
        {
          name: 'Paratitahi Tunnel',
          segment: paratitahiTunnel,
          type: 'tunnel'
        },
        paratitahiTunnelTunnelNo13,
        {
          name: 'Tunnel No 13',
          segment: tunnelNo13,
          type: 'tunnel'
        },
        tunnelNo13kahutaraTunnel,
        {
          name: 'Rileys Hill (Kahutara) Tunnel',
          segment: kahutaraTunnel,
          type: 'tunnel'
        },
        kahutaraTunnelTunnelNo15,
        {
          name: 'Tunnel No 15',
          segment: tunnelNo15,
          type: 'tunnel'
        },
        tunnelNo15TunnelNo16,
        {
          name: 'Tunnel No 16',
          segment: tunnelNo16,
          type: 'tunnel'
        },
        tunnelNo16TunnelNo17,
        {
          name: 'Tunnel No 17',
          segment: tunnelNo17,
          type: 'tunnel'
        },
        tunnelNo17ShandsTunnel,
        {
          name: 'Shands Tunnel',
          segment: shandsTunnel,
          type: 'tunnel'
        },
        shandsTunnelTunnelNo19,
        {
          name: 'Tunnel No 19',
          segment: tunnelNo19,
          type: 'tunnel'
        },
        tunnelNo19TunnelNo20,
        {
          name: 'Tunnel No 20',
          segment: tunnelNo20,
          type: 'tunnel'
        },
        tunnelNo20Wharanui
      ],
      history: {
        opened: {
          date: '1945-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        wharanuiTunnelTarBarrelTunnel,
        {
          date: '2021-01-01',
          original: [
            oldStartTarBarrelTunnel,
            {
              name: 'Tar Barrel Tunnel',
              segment: tarBarrelTunnel,
              type: 'tunnel'
            },
            tarBarrelTunnelOldEnd
          ],
          deviation: [tarBarrelDeviation]
        },
        tarBarrelTunnelWard
      ],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wardSeddon],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        seddonTunnelNo22,
        {
          date: '1981-01-01',
          original: [{
            name: 'Tunnel No 22',
            segment: tunnelNo22,
            type: 'tunnel'
          }],
          deviation: [tunnelNo22],
        },
        tunnelNo22TunnelNo23,
        {
          date: '1979-01-01',
          original: [{
            name: 'Tunnel No 23',
            segment: tunnelNo23,
            type: 'tunnel'
          }],
          deviation: [tunnelNo23],
        },
        tunnelNo23Blenheim
      ],
      history: {
        opened: {
          date: '1902-10-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [blenheimOpawaRiver],
      history: {
        opened: {
          date: '1880-05-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        opawaRiverTunnelNo24,
        {
          date: '1950-01-01',
          original: [tunnelNo24],
          deviation: [{
            name: 'Tunnel No 24',
            segment: tunnelNo24,
            type: 'tunnel'
          }]
        },
        tunnelNo24Picton
      ],
      history: {
        opened: {
          date: '1875-11-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pictonPictonWharf],
      history: {
        opened: {
          date: '1875-11-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1914-03-11',
          status: 'closed'
        }]
      }
    }
  ]
}
