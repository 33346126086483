import { Line } from "../../../trackTypes";

const townsvilleRavenswoodJunction = [
  [-19.3468, 146.8356],
  [-19.3486, 146.8360],
  [-19.3508, 146.8365],
  [-19.3559, 146.8379],
  [-19.3575, 146.8379],
  [-19.3590, 146.8376],
  [-19.3645, 146.8374],
  [-19.3673, 146.8376],
  [-19.3700, 146.8373],
  [-19.3738, 146.8361],
  [-19.3810, 146.8325],
  [-19.3822, 146.8324],
  [-19.3838, 146.8325],
  [-19.3850, 146.8321],
  [-19.3869, 146.8310],
  [-19.3883, 146.8305],
  [-19.3911, 146.8307],
  [-19.3932, 146.8318],
  [-19.3962, 146.8324],
  [-19.4021, 146.8324],
  [-19.4045, 146.8331],
  [-19.4093, 146.8336],
  [-19.4120, 146.8342],
  [-19.4144, 146.8359],
  [-19.4234, 146.8404],
  [-19.4276, 146.8445],
  [-19.4305, 146.8497],
  [-19.4332, 146.8534],
  [-19.4372, 146.8559],
  [-19.4422, 146.8569],
  [-19.4790, 146.8549],
  [-19.4859, 146.8549],
  [-19.5038, 146.8578],
  [-19.5304, 146.8662],
  [-19.5357, 146.8664],
  [-19.5402, 146.8647],
  [-19.5756, 146.8370],
  [-19.5786, 146.8360],
  [-19.6394, 146.8360],
  [-19.6613, 146.8335],
  [-19.6780, 146.8349],
  [-19.6901, 146.8387],
  [-19.6921, 146.8387],
  [-19.6987, 146.8361],
  [-19.7027, 146.8354],
  [-19.7051, 146.8356],
  [-19.7121, 146.8385],
  [-19.7182, 146.8415],
  [-19.7202, 146.8421],
  [-19.7222, 146.8418],
  [-19.7296, 146.8377],
  [-19.7378, 146.8346],
  [-19.7392, 146.8336],
  [-19.7410, 146.8314],
  [-19.7429, 146.8303],
  [-19.7447, 146.8299],
  [-19.7501, 146.8308],
  [-19.7531, 146.8320],
  [-19.7554, 146.8327],
  [-19.7600, 146.8324],
  [-19.7676, 146.8307],
  [-19.7759, 146.8275],
  [-19.8010, 146.8211],
  [-19.8020, 146.8210],
  [-19.8037, 146.8212],
  [-19.8058, 146.8212],
  [-19.8076, 146.8202],
  [-19.8086, 146.8182],
  [-19.8114, 146.8063],
  [-19.8114, 146.8033],
  [-19.8115, 146.8015],
  [-19.8126, 146.7992],
  [-19.8161, 146.7960],
  [-19.8181, 146.7926],
  [-19.8199, 146.7904],
  [-19.8222, 146.7893],
  [-19.8241, 146.7879],
  [-19.8253, 146.7860],
  [-19.8328, 146.7568],
  [-19.8329, 146.7539],
  [-19.8244, 146.7187],
  [-19.8244, 146.7170],
  [-19.8250, 146.7148],
  [-19.8272, 146.7101],
  [-19.8300, 146.6983],
  [-19.8325, 146.6931],
  [-19.8339, 146.6916],
  [-19.8365, 146.6900],
  [-19.8382, 146.6890],
  [-19.8476, 146.6797],
  [-19.8485, 146.6784],
  [-19.8498, 146.6735],
  [-19.8503, 146.6684],
  [-19.8501, 146.6671],
  [-19.8494, 146.6661],
  [-19.8456, 146.6629],
  [-19.8449, 146.6614],
  [-19.8451, 146.6598],
  [-19.8459, 146.6585],
  [-19.8495, 146.6564],
  [-19.8508, 146.6561],
  [-19.8523, 146.6548],
  [-19.8552, 146.6523],
  [-19.8556, 146.6507],
  [-19.8552, 146.6459],
  [-19.8555, 146.6442],
  [-19.8576, 146.6404],
  [-19.8579, 146.6389],
  [-19.8573, 146.6371],
  [-19.8553, 146.6358],
  [-19.8535, 146.6361],
  [-19.8522, 146.6368],
  [-19.8503, 146.6366],
  [-19.8493, 146.6360],
  [-19.8477, 146.6358],
  [-19.8437, 146.6361],
  [-19.8419, 146.6356],
  [-19.8407, 146.6333],
  [-19.8407, 146.6317],
  [-19.8418, 146.6302],
  [-19.8435, 146.6297],
  [-19.8500, 146.6303],
  [-19.8510, 146.6304],
  [-19.8573, 146.6292],
  [-19.8594, 146.6296],
  [-19.8718, 146.6386],
  [-19.8734, 146.6393],
  [-19.8751, 146.6391],
  [-19.8769, 146.6376],
  [-19.8776, 146.6358],
  [-19.8780, 146.6337]
];

const stuartJunction = [
  [-19.3486, 146.8360],
  [-19.3478, 146.8362],
  [-19.3473, 146.8368],
  [-19.3470, 146.8377]
];

const ravenswoodJunctionBurdekinRiver = [
[-19.8780, 146.6337],
[-19.8785, 146.6314],
[-19.8792, 146.6297],
[-19.8877, 146.6157],
[-19.8883, 146.6141],
[-19.8890, 146.6100],
[-19.8895, 146.6088],
[-19.8968, 146.5979],
[-19.8979, 146.5968],
[-19.9001, 146.5953],
[-19.9015, 146.5937],
[-19.9059, 146.5834],
[-19.9067, 146.5806],
[-19.9111, 146.5717],
[-19.9114, 146.5696],
[-19.9115, 146.5670],
[-19.9122, 146.5657],
[-19.9133, 146.5646],
[-19.9164, 146.5629],
[-19.9231, 146.5553],
[-19.9306, 146.5451],
[-19.9329, 146.5438],
[-19.9352, 146.5436],
[-19.9369, 146.5428],
[-19.9384, 146.5410],
[-19.9420, 146.5305],
[-19.9432, 146.5289],
[-19.9446, 146.5280],
[-19.9460, 146.5275],
[-19.9478, 146.5263],
[-19.9500, 146.5235],
[-19.9526, 146.5215],
[-19.9535, 146.5205],
[-19.9562, 146.5149],
[-19.9572, 146.5136],
[-19.9584, 146.5129],
[-19.9620, 146.5115],
[-19.9632, 146.5107],
[-19.9644, 146.5094],
[-19.9665, 146.5056],
[-19.9674, 146.5018],
[-19.9680, 146.5003],
[-19.9695, 146.4979],
[-19.9790, 146.4868],
[-19.9823, 146.4796],
[-19.9830, 146.4751],
[-19.9836, 146.4736],
[-19.9864, 146.4696],
[-19.9881, 146.4678],
[-19.9894, 146.4656],
[-19.9911, 146.4638],
[-19.9995, 146.4567],
[-20.0004, 146.4557],
[-20.0028, 146.4514],
[-20.0053, 146.4448]
];

const burdekinRiverChartersTowers = [
  [-20.0053, 146.4448],
  [-20.0062, 146.4403],
  [-20.0069, 146.4321],
  [-20.0074, 146.4302],
  [-20.0093, 146.4273],
  [-20.0098, 146.4256],
  [-20.0094, 146.4236],
  [-20.0083, 146.4217],
  [-20.0080, 146.4194],
  [-20.0082, 146.4173],
  [-20.0130, 146.4068],
  [-20.0145, 146.4051],
  [-20.0218, 146.3890],
  [-20.0226, 146.3878],
  [-20.0288, 146.3809],
  [-20.0329, 146.3745],
  [-20.0335, 146.3729],
  [-20.0342, 146.3691],
  [-20.0362, 146.3645],
  [-20.0384, 146.3615],
  [-20.0418, 146.3589],
  [-20.0431, 146.3575],
  [-20.0454, 146.3527],
  [-20.0464, 146.3515],
  [-20.0502, 146.3488],
  [-20.0511, 146.3476],
  [-20.0514, 146.3465],
  [-20.0517, 146.3411],
  [-20.0523, 146.3388],
  [-20.0537, 146.3367],
  [-20.0594, 146.3309],
  [-20.0602, 146.3293],
  [-20.0602, 146.3275],
  [-20.0579, 146.3221],
  [-20.0575, 146.3202],
  [-20.0577, 146.3125],
  [-20.0582, 146.3097],
  [-20.0593, 146.3070],
  [-20.0624, 146.3035],
  [-20.0651, 146.3018],
  [-20.0664, 146.3004],
  [-20.0707, 146.2919],
  [-20.0710, 146.2901],
  [-20.0708, 146.2886],
  [-20.0694, 146.2851],
  [-20.0693, 146.2831],
  [-20.0699, 146.2813],
  [-20.0711, 146.2799],
  [-20.0725, 146.2791],
  [-20.0734, 146.2779],
  [-20.0744, 146.2760],
  [-20.0748, 146.2741],
  [-20.0756, 146.2725],
  [-20.0801, 146.2677]
];

const chartersTowersHomestead = [
  [-20.0801, 146.2677],
  [-20.0830, 146.2644],
  [-20.0840, 146.2637],
  [-20.0916, 146.2595],
  [-20.0927, 146.2587],
  [-20.0954, 146.2548],
  [-20.0964, 146.2520],
  [-20.0967, 146.2511],
  [-20.0977, 146.2496],
  [-20.0996, 146.2477],
  [-20.1004, 146.2460],
  [-20.1005, 146.2438],
  [-20.0997, 146.2412],
  [-20.0995, 146.2391],
  [-20.0998, 146.2368],
  [-20.1030, 146.2257],
  [-20.1035, 146.2209],
  [-20.1040, 146.2189],
  [-20.1098, 146.2036],
  [-20.1127, 146.1939],
  [-20.1133, 146.1925],
  [-20.1144, 146.1910],
  [-20.1169, 146.1888],
  [-20.1180, 146.1875],
  [-20.1208, 146.1821],
  [-20.1217, 146.1792],
  [-20.1245, 146.1653],
  [-20.1254, 146.1623],
  [-20.1259, 146.1577],
  [-20.1261, 146.1480],
  [-20.1257, 146.1445],
  [-20.1258, 146.1425],
  [-20.1317, 146.1240],
  [-20.1328, 146.1186],
  [-20.1329, 146.1156],
  [-20.1331, 146.1141],
  [-20.1383, 146.1014],
  [-20.1386, 146.1000],
  [-20.1383, 146.0984],
  [-20.1370, 146.0950],
  [-20.1352, 146.0868],
  [-20.1352, 146.0837],
  [-20.1363, 146.0804],
  [-20.1379, 146.0785],
  [-20.1446, 146.0736],
  [-20.1457, 146.0719],
  [-20.1735, 145.9962],
  [-20.1822, 145.9685],
  [-20.1830, 145.9667],
  [-20.2108, 145.9195],
  [-20.2142, 145.9145],
  [-20.2161, 145.9105],
  [-20.2174, 145.9084],
  [-20.2490, 145.8462],
  [-20.2514, 145.8435],
  [-20.2542, 145.8416],
  [-20.2580, 145.8403],
  [-20.2597, 145.8386],
  [-20.2603, 145.8357],
  [-20.2598, 145.8328],
  [-20.2603, 145.8276],
  [-20.2634, 145.8206],
  [-20.2734, 145.8042],
  [-20.3069, 145.7413],
  [-20.3131, 145.7299],
  [-20.3182, 145.7217],
  [-20.3465, 145.6747],
  [-20.3475, 145.6733],
  [-20.3491, 145.6717],
  [-20.3592, 145.6603],
  [-20.3609, 145.6579]
];

const homesteadPentland = [
  [-20.3609, 145.6579],
  [-20.3713, 145.6413],
  [-20.3753, 145.6356],
  [-20.3779, 145.6305],
  [-20.3918, 145.6084],
  [-20.3968, 145.6010],
  [-20.4006, 145.5964],
  [-20.4115, 145.5757],
  [-20.4122, 145.5747],
  [-20.4153, 145.5718],
  [-20.4167, 145.5701],
  [-20.4171, 145.5689],
  [-20.4175, 145.5681],
  [-20.4186, 145.5670],
  [-20.4217, 145.5653],
  [-20.4233, 145.5636],
  [-20.4246, 145.5609],
  [-20.4254, 145.5599],
  [-20.4275, 145.5581],
  [-20.4288, 145.5563],
  [-20.4314, 145.5495],
  [-20.4326, 145.5475],
  [-20.4352, 145.5444],
  [-20.4402, 145.5354],
  [-20.4414, 145.5339],
  [-20.4439, 145.5318],
  [-20.4456, 145.5295],
  [-20.4523, 145.5148],
  [-20.4547, 145.5118],
  [-20.4566, 145.5101],
  [-20.4583, 145.5080],
  [-20.4707, 145.4827],
  [-20.4746, 145.4782],
  [-20.4772, 145.4766],
  [-20.477, 145.4750],
  [-20.4970, 145.4465],
  [-20.5023, 145.4375],
  [-20.5058, 145.4288],
  [-20.5067, 145.4273],
  [-20.5097, 145.4235],
  [-20.5107, 145.4216],
  [-20.5163, 145.4063],
  [-20.5170, 145.4052],
  [-20.5179, 145.4046],
  [-20.5197, 145.4038],
  [-20.5206, 145.4031],
  [-20.5254, 145.3984]
];

const pentlandTorrensCreek = [
  [-20.5254, 145.3984],
  [-20.5274, 145.3962],
  [-20.5317, 145.3921],
  [-20.5337, 145.3894],
  [-20.5480, 145.3742],
  [-20.5530, 145.3679],
  [-20.5567, 145.3649],
  [-20.5627, 145.3584],
  [-20.5701, 145.3521],
  [-20.5714, 145.3514],
  [-20.5729, 145.3514],
  [-20.5796, 145.3538],
  [-20.5818, 145.3533],
  [-20.5833, 145.3520],
  [-20.5840, 145.3507],
  [-20.5856, 145.3428],
  [-20.5859, 145.3416],
  [-20.5868, 145.3404],
  [-20.5985, 145.3296],
  [-20.6008, 145.3258],
  [-20.6111, 145.3146],
  [-20.6126, 145.3137],
  [-20.6146, 145.3133],
  [-20.6246, 145.3147],
  [-20.6265, 145.3147],
  [-20.6288, 145.3140],
  [-20.6379, 145.3076],
  [-20.6405, 145.3050],
  [-20.6428, 145.3009],
  [-20.6465, 145.2872],
  [-20.6475, 145.2850],
  [-20.6491, 145.2826],
  [-20.6497, 145.2815],
  [-20.6505, 145.2773],
  [-20.6530, 145.2692],
  [-20.6649, 145.2477],
  [-20.6668, 145.2456],
  [-20.6767, 145.2385],
  [-20.6797, 145.2353],
  [-20.6810, 145.2345],
  [-20.6823, 145.2343],
  [-20.6844, 145.2346],
  [-20.6867, 145.2354],
  [-20.6890, 145.2358],
  [-20.6925, 145.2356],
  [-20.6940, 145.2348],
  [-20.6949, 145.2331],
  [-20.6950, 145.2316],
  [-20.6946, 145.2304],
  [-20.6950, 145.2282],
  [-20.6971, 145.2264],
  [-20.6989, 145.2263],
  [-20.7007, 145.2273],
  [-20.7015, 145.2277],
  [-20.7059, 145.2286],
  [-20.7084, 145.2283],
  [-20.7109, 145.2268],
  [-20.7128, 145.2244],
  [-20.7141, 145.2218],
  [-20.7153, 145.2205],
  [-20.7217, 145.2152],
  [-20.7224, 145.2139],
  [-20.7267, 145.1972],
  [-20.7303, 145.1909],
  [-20.7379, 145.1796],
  [-20.7385, 145.1772],
  [-20.7383, 145.1583],
  [-20.7400, 145.1446],
  [-20.7636, 145.0521],
  [-20.7664, 145.0441],
  [-20.7688, 145.0311],
  [-20.7691, 145.0252],
  [-20.7695, 145.0227],
  [-20.7704, 145.0194]
];

const torrensCreekPraire = [
  [-20.7704, 145.0194],
  [-20.8159, 144.8490],
  [-20.8225, 144.8243],
  [-20.8231, 144.8217],
  [-20.8239, 144.8191],
  [-20.8282, 144.8008],
  [-20.8487, 144.7099],
  [-20.8703, 144.6128],
  [-20.8710, 144.6094],
  [-20.8714, 144.6038]
];

const praireHughenden = [
  [-20.8714, 144.6038],
  [-20.8781, 144.5130],
  [-20.8787, 144.5110],
  [-20.8802, 144.5094],
  [-20.8810, 144.5074],
  [-20.8808, 144.5056],
  [-20.8798, 144.5033],
  [-20.8795, 144.5017],
  [-20.8798, 144.5004],
  [-20.8818, 144.4959],
  [-20.8821, 144.4942],
  [-20.8819, 144.4924],
  [-20.8791, 144.4845],
  [-20.8777, 144.4743],
  [-20.8677, 144.3731],
  [-20.8636, 144.3323],
  [-20.8637, 144.3303],
  [-20.8769, 144.2621],
  [-20.8768, 144.2606],
  [-20.8656, 144.2144],
  [-20.8641, 144.2054],
  [-20.8631, 144.2042],
  [-20.8616, 144.2037],
  [-20.8498, 144.2037],
  [-20.8485, 144.2033],
  [-20.8474, 144.2024],
  [-20.8468, 144.2013],
  [-20.8464, 144.1991],
  [-20.8466, 144.1983],
  [-20.8471, 144.1975],
  [-20.8478, 144.1971]
];

const hughendenBypass = [
  [-20.8641, 144.2054],
  [-20.8635, 144.2040],
  [-20.8577, 144.1958],
  [-20.8571, 144.1943],
  [-20.8569, 144.1928]
];

const hughendenMarathon = [
  [-20.8478, 144.1971],
  [-20.8529, 144.1961],
  [-20.8560, 144.1943],
  [-20.8566, 144.1936],
  [-20.8569, 144.1928],
  [-20.8606, 144.1373],
  [-20.8684, 144.0172],
  [-20.8627, 143.9435],
  [-20.8626, 143.9383],
  [-20.8807, 143.7658],
  [-20.8837, 143.7587],
  [-20.8840, 143.7563],
  [-20.8628, 143.5730]
];

const marathonRichmond = [
  [-20.8628, 143.5730],
  [-20.8587, 143.5372],
  [-20.8565, 143.5188],
  [-20.8459, 143.4309],
  [-20.8453, 143.4282],
  [-20.8127, 143.3133],
  [-20.7834, 143.2107],
  [-20.7625, 143.1659],
  [-20.7597, 143.1623],
  [-20.7347, 143.1407]
];

const richmondJuliaCreek = [
  [-20.7347, 143.1407],
  [-20.7323, 143.1386],
  [-20.7313, 143.1368],
  [-20.7310, 143.1341],
  [-20.7348, 143.1077],
  [-20.7345, 143.0981],
  [-20.7331, 143.0931],
  [-20.7295, 143.0534],
  [-20.7341, 142.8574],
  [-20.7277, 142.7738],
  [-20.7116, 142.5667],
  [-20.7098, 142.5616],
  [-20.7037, 142.5530],
  [-20.7016, 142.5487],
  [-20.6854, 142.4903],
  [-20.6798, 142.4802],
  [-20.6779, 142.4734],
  [-20.6515, 142.1920],
  [-20.6511, 142.1736],
  [-20.6558, 141.7799],
  [-20.6590, 141.7466]
];

const juliaCreekCloncurry = [
  [-20.6590, 141.7466],
  [-20.6774, 141.5577],
  [-20.6782, 141.5526],
  [-20.6784, 141.5484],
  [-20.6792, 141.5435],
  [-20.7010, 141.4641],
  [-20.7019, 141.4583],
  [-20.7043, 141.4175],
  [-20.7049, 141.3350],
  [-20.6946, 141.1368],
  [-20.6941, 141.1336],
  [-20.6924, 141.1295],
  [-20.6910, 141.1241],
  [-20.6906, 141.1197],
  [-20.6934, 141.0746],
  [-20.6961, 140.9942],
  [-20.7142, 140.8638],
  [-20.7293, 140.7857],
  [-20.7298, 140.7761],
  [-20.7275, 140.7587],
  [-20.7275, 140.7563],
  [-20.7309, 140.7358],
  [-20.7310, 140.7334],
  [-20.7303, 140.7251],
  [-20.7303, 140.7224],
  [-20.7309, 140.7178],
  [-20.7320, 140.7148],
  [-20.7364, 140.7075],
  [-20.7406, 140.6968],
  [-20.7410, 140.6947],
  [-20.7410, 140.6912],
  [-20.7403, 140.6885],
  [-20.7389, 140.6864],
  [-20.7376, 140.6841],
  [-20.7373, 140.6816],
  [-20.7382, 140.6754],
  [-20.7382, 140.6735],
  [-20.7380, 140.6712],
  [-20.7381, 140.6698],
  [-20.7391, 140.6649],
  [-20.7391, 140.6627],
  [-20.7387, 140.6611],
  [-20.7365, 140.6571],
  [-20.7356, 140.6529],
  [-20.7352, 140.6514],
  [-20.7337, 140.6492],
  [-20.7329, 140.6472],
  [-20.7319, 140.6391],
  [-20.7321, 140.6363],
  [-20.7333, 140.6324],
  [-20.7334, 140.6303],
  [-20.7311, 140.6135],
  [-20.7309, 140.6066],
  [-20.7295, 140.5984],
  [-20.7293, 140.5957],
  [-20.7296, 140.5911],
  [-20.7292, 140.5882],
  [-20.7294, 140.5865],
  [-20.7299, 140.5855],
  [-20.7319, 140.5834],
  [-20.7342, 140.5791],
  [-20.7356, 140.5771],
  [-20.7381, 140.5749],
  [-20.7388, 140.5729],
  [-20.7384, 140.5709],
  [-20.7358, 140.5664],
  [-20.7344, 140.5608],
  [-20.7350, 140.5570],
  [-20.7375, 140.5520],
  [-20.7379, 140.5504],
  [-20.7377, 140.5484],
  [-20.7354, 140.5422],
  [-20.7344, 140.5408],
  [-20.7267, 140.5342],
  [-20.7229, 140.5321],
  [-20.7216, 140.5306],
  [-20.7205, 140.5284],
  [-20.7192, 140.5269],
  [-20.7168, 140.5253],
  [-20.7149, 140.5226],
  [-20.7143, 140.5203],
  [-20.7143, 140.5173]
];

const cloncurrySelwynJunction = [
  [-20.7143, 140.5173],
  [-20.7143, 140.5154],
  [-20.7147, 140.5119],
  [-20.7155, 140.5083],
  [-20.7161, 140.5055],
  [-20.7248, 140.4808],
  [-20.7266, 140.4778],
  [-20.7466, 140.4572],
  [-20.7735, 140.4182],
  [-20.7755, 140.4165],
  [-20.7845, 140.4123],
  [-20.7885, 140.4121],
  [-20.7944, 140.4138],
  [-20.7958, 140.4138],
  [-20.7978, 140.4130],
  [-20.7992, 140.4132],
  [-20.8006, 140.4139],
  [-20.8019, 140.4142],
  [-20.8054, 140.4143],
  [-20.8067, 140.4141],
  [-20.8130, 140.4116],
  [-20.8152, 140.4103],
  [-20.8167, 140.4087],
  [-20.8195, 140.4029],
  [-20.8208, 140.4015],
  [-20.8449, 140.3858],
  [-20.8468, 140.3838],
  [-20.8498, 140.3782],
  [-20.8512, 140.3760],
  [-20.8635, 140.3628],
  [-20.8676, 140.3557],
  [-20.8680, 140.3541],
  [-20.8682, 140.3519],
  [-20.8688, 140.3499],
  [-20.8697, 140.3487],
  [-20.8717, 140.3474],
  [-20.8739, 140.3468],
  [-20.8764, 140.3454],
  [-20.8800, 140.3419],
  [-20.8858, 140.3388],
  [-20.8878, 140.3371],
  [-20.8892, 140.3351],
  [-20.8895, 140.3335],
  [-20.8892, 140.3320],
  [-20.8884, 140.3304],
  [-20.8882, 140.3286],
  [-20.8887, 140.3269],
  [-20.8910, 140.3248],
  [-20.8920, 140.3242],
  [-20.8944, 140.3235],
  [-20.9010, 140.3207],
  [-20.9093, 140.3187],
  [-20.9175, 140.3180],
  [-20.9192, 140.3174],
  [-20.9274, 140.3102],
  [-20.9307, 140.3088],
  [-20.9326, 140.3085],
  [-20.9390, 140.3088],
  [-20.9452, 140.3078],
  [-20.9614, 140.3040],
  [-20.9634, 140.3038],
  [-20.9801, 140.3040],
  [-20.9814, 140.3038],
  [-21.0012, 140.2977],
  [-21.0041, 140.2973],
  [-21.0102, 140.2976],
  [-21.0118, 140.2974],
  [-21.0267, 140.2937],
  [-21.0287, 140.2934],
  [-21.0371, 140.2939],
  [-21.0398, 140.2948],
  [-21.0450, 140.2976],
  [-21.0477, 140.2981],
  [-21.0502, 140.2975],
  [-21.0518, 140.2967],
  [-21.0535, 140.2964],
  [-21.0700, 140.2998],
  [-21.0744, 140.3006],
  [-21.0773, 140.3019]
];

const selwynJunctionDuchess = [
  [-21.0773, 140.3019],
  [-21.0788, 140.3019],
  [-21.0834, 140.2998],
  [-21.0847, 140.2987],
  [-21.0857, 140.2969],
  [-21.0903, 140.2836],
  [-21.0918, 140.2814],
  [-21.0979, 140.2755],
  [-21.1018, 140.2727],
  [-21.1029, 140.2715],
  [-21.1033, 140.2703],
  [-21.1037, 140.2686],
  [-21.1042, 140.2673],
  [-21.1175, 140.2514],
  [-21.1476, 140.1910],
  [-21.1706, 140.1592],
  [-21.2004, 140.0836],
  [-21.2095, 140.0673],
  [-21.2107, 140.0659],
  [-21.2127, 140.0647],
  [-21.2137, 140.0635],
  [-21.2196, 140.0508],
  [-21.2258, 140.0416],
  [-21.2273, 140.0406],
  [-21.2337, 140.0395],
  [-21.2358, 140.0387],
  [-21.2410, 140.0348],
  [-21.2421, 140.0323],
  [-21.2417, 140.0300],
  [-21.2397, 140.0270],
  [-21.2394, 140.0250],
  [-21.2399, 140.0234],
  [-21.2410, 140.0221],
  [-21.2428, 140.0212],
  [-21.2451, 140.0210],
  [-21.2461, 140.0211],
  [-21.2528, 140.0232],
  [-21.2540, 140.0233],
  [-21.2557, 140.0230],
  [-21.2569, 140.0231],
  [-21.2584, 140.0235],
  [-21.2604, 140.0233],
  [-21.2619, 140.0223],
  [-21.2639, 140.0186],
  [-21.2655, 140.0149],
  [-21.2674, 140.0124],
  [-21.2725, 140.0090],
  [-21.2740, 140.0085],
  [-21.2763, 140.0085],
  [-21.2789, 140.0077],
  [-21.2809, 140.0061],
  [-21.2868, 139.9983],
  [-21.2932, 139.9926],
  [-21.2950, 139.9891],
  [-21.3032, 139.9782],
  [-21.3161, 139.9583],
  [-21.3217, 139.9531],
  [-21.3238, 139.9501],
  [-21.3261, 139.9452],
  [-21.3297, 139.9146],
  [-21.3369, 139.8909],
  [-21.3382, 139.8881],
  [-21.3446, 139.8791],
  [-21.3461, 139.8760],
  [-21.3569, 139.8645]
];

const duchessMtIsa = [
  [-21.3569, 139.8645],
  [-21.3574, 139.8632],
  [-21.3573, 139.8616],
  [-21.3564, 139.8604],
  [-21.3553, 139.8598],
  [-21.3482, 139.8590],
  [-21.3370, 139.8590],
  [-21.3354, 139.8587],
  [-21.3341, 139.8572],
  [-21.3297, 139.8460],
  [-21.3265, 139.8401],
  [-21.3075, 139.8122],
  [-21.2877, 139.7975],
  [-21.2854, 139.7967],
  [-21.2830, 139.7966],
  [-21.2809, 139.7945],
  [-21.2791, 139.7840],
  [-21.2780, 139.7822],
  [-21.2762, 139.7813],
  [-21.2749, 139.7800],
  [-21.2733, 139.7767],
  [-21.2728, 139.7746],
  [-21.2729, 139.7732],
  [-21.2750, 139.7686],
  [-21.2754, 139.7668],
  [-21.2747, 139.7608],
  [-21.2674, 139.7432],
  [-21.2658, 139.7416],
  [-21.2381, 139.7310],
  [-21.2255, 139.7244],
  [-21.2199, 139.7166],
  [-21.1881, 139.7010],
  [-21.1854, 139.7001],
  [-21.1830, 139.6987],
  [-21.1315, 139.6757],
  [-21.1012, 139.6590],
  [-21.0882, 139.6534],
  [-21.0624, 139.6353],
  [-21.0589, 139.6296],
  [-21.0583, 139.6268],
  [-21.0589, 139.6210],
  [-21.0582, 139.6182],
  [-21.0560, 139.6160],
  [-21.0527, 139.6107],
  [-21.0303, 139.5928],
  [-21.0236, 139.5894],
  [-21.0214, 139.5876],
  [-21.0200, 139.5851],
  [-21.0179, 139.5832],
  [-21.0097, 139.5794],
  [-21.0073, 139.5791],
  [-21.0035, 139.5798],
  [-21.0035, 139.5798],
  [-20.9993, 139.5809],
  [-20.9916, 139.5771],
  [-20.9896, 139.5749],
  [-20.9864, 139.5734],
  [-20.9706, 139.5739],
  [-20.9653, 139.5754],
  [-20.9622, 139.5751],
  [-20.9490, 139.5683],
  [-20.9447, 139.5672],
  [-20.9342, 139.5611],
  [-20.9325, 139.5595],
  [-20.9270, 139.5525],
  [-20.9187, 139.5494],
  [-20.9098, 139.5472],
  [-20.9058, 139.5446],
  [-20.9033, 139.5408],
  [-20.9020, 139.5396],
  [-20.9005, 139.5350],
  [-20.8912, 139.5238],
  [-20.8878, 139.5221],
  [-20.8665, 139.5227],
  [-20.8606, 139.5218],
  [-20.8585, 139.5206],
  [-20.8498, 139.5103],
  [-20.8444, 139.5074],
  [-20.8418, 139.5063],
  [-20.8378, 139.5021],
  [-20.8360, 139.5012],
  [-20.8245, 139.4993],
  [-20.8194, 139.4992],
  [-20.8180, 139.4988],
  [-20.8124, 139.4954],
  [-20.8110, 139.4950],
  [-20.7859, 139.4959],
  [-20.7837, 139.4951],
  [-20.7817, 139.4925],
  [-20.7803, 139.4916],
  [-20.7737, 139.4909],
  [-20.7587, 139.4911],
  [-20.7567, 139.4905],
  [-20.7511, 139.4872],
  [-20.7434, 139.4844],
  [-20.7415, 139.4842],
  [-20.7014, 139.4850],
  [-20.6999, 139.4847],
  [-20.6937, 139.4849],
  [-20.6905, 139.4857],
  [-20.6900, 139.4860],
  [-20.6891, 139.4868],
  [-20.6846, 139.4870]
];

export const greatNorthern: Line = {
  name: 'Great Northern',
  state: 'QLD',
  segments: [
    {
      segments: [townsvilleRavenswoodJunction],
      history: {
        opened: {
          date: '1881-11-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [stuartJunction],
      history: {
        opened: {
          date: '1901-03-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ravenswoodJunctionBurdekinRiver],
      history: {
        opened: {
          date: '1882-07-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [burdekinRiverChartersTowers],
      history: {
        opened: {
          date: '1882-12-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [chartersTowersHomestead],
      history: {
        opened: {
          date: '1884-02-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [homesteadPentland],
      history: {
        opened: {
          date: '1884-10-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pentlandTorrensCreek],
      history: {
        opened: {
          date: '1885-10-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [torrensCreekPraire],
      history: {
        opened: {
          date: '1887-01-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [praireHughenden],
      history: {
        opened: {
          date: '1887-10-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hughendenBypass],
      history: {
        opened: {
          date: '1897-12-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hughendenMarathon],
      history: {
        opened: {
          date: '1903-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [marathonRichmond],
      history: {
        opened: {
          date: '1904-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [richmondJuliaCreek],
      history: {
        opened: {
          date: '1908-02-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [juliaCreekCloncurry],
      history: {
        opened: {
          date: '1908-12-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cloncurrySelwynJunction],
      history: {
        opened: {
          date: '1910-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [selwynJunctionDuchess],
      history: {
        opened: {
          date: '1912-10-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [duchessMtIsa],
      history: {
        opened: {
          date: '1929-05-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
