import { Line } from "../../trackTypes";

const dawrinDarwinRiverDam = [
  [-12.4678, 130.8507],
  [-12.4673, 130.8504],
  [-12.4669, 130.8500],
  [-12.4663, 130.8484],
  [-12.4655, 130.8475],
  [-12.4650, 130.8473],
  [-12.4618, 130.8469],
  [-12.4609, 130.8469],
  [-12.4591, 130.8467],
  [-12.4572, 130.8459],
  [-12.4560, 130.8443],
  [-12.4548, 130.8412],
  [-12.4541, 130.8401],
  [-12.4533, 130.8396],
  [-12.4525, 130.8394],
  [-12.4387, 130.8415],
  [-12.4379, 130.8417],
  [-12.4374, 130.8421],
  [-12.4362, 130.8439],
  [-12.4338, 130.8480],
  [-12.4254, 130.8662],
  [-12.4250, 130.8682],
  [-12.4248, 130.8826],
  [-12.4266, 130.9049],
  [-12.4274, 130.9079],
  [-12.4458, 130.9569],
  [-12.4470, 130.9591],
  [-12.4740, 130.9984],
  [-12.4811, 131.0049],
  [-12.4866, 131.0120],
  [-12.5141, 131.0402],
  [-12.5584, 131.0714],
  [-12.5639, 131.0733],
  [-12.6704, 131.0735],
  [-12.6730, 131.0729],
  [-12.7284, 131.0511],
  [-12.7320, 131.0489],
  [-12.7992, 130.9806],
  [-12.8199, 130.9634]
];

const darwinRiverDamOld = [
  [-12.8199, 130.9634],
  [-12.8217, 130.9626],
  [-12.8230, 130.9631],
  [-12.8260, 130.9671],
  [-12.8468, 130.9790],
  [-12.8664, 130.9789],
  [-12.9447, 130.9789],
  [-12.9474, 130.9791]
];

const darwinRiverDamDeviation = [
  [-12.8199, 130.9634],
  [-12.8216, 130.9629],
  [-12.8363, 130.9610],
  [-12.8562, 130.9513],
  [-12.9022, 130.9416],
  [-12.9052, 130.9419],
  [-12.9177, 130.9484],
  [-12.9281, 130.9565],
  [-12.9366, 130.9616],
  [-12.9382, 130.9632],
  [-12.9438, 130.9755],
  [-12.9452, 130.9775],
  [-12.9474, 130.9791]
];

const darwinRiverDamAdelaideRiver = [
  [-12.9474, 130.9791],
  [-12.9718, 130.9861],
  [-12.9740, 130.9860],
  [-12.9835, 130.9835],
  [-12.9908, 130.9829],
  [-13.0038, 130.9843],
  [-13.0062, 130.9853],
  [-13.0149, 130.9910],
  [-13.0186, 130.9961],
  [-13.0219, 130.9984],
  [-13.0262, 130.9996],
  [-13.0280, 131.0009],
  [-13.0313, 131.0068],
  [-13.0409, 131.0172],
  [-13.0436, 131.0193],
  [-13.0690, 131.0290],
  [-13.1150, 131.0293],
  [-13.1472, 131.0326],
  [-13.1647, 131.0319],
  [-13.1664, 131.0312],
  [-13.1673, 131.0299],
  [-13.1687, 131.0292],
  [-13.1701, 131.0295],
  [-13.1757, 131.0349],
  [-13.1767, 131.0360],
  [-13.1811, 131.0468],
  [-13.1811, 131.0502],
  [-13.1802, 131.0519],
  [-13.1799, 131.0530],
  [-13.1811, 131.0580],
  [-13.1838, 131.0608],
  [-13.1916, 131.0610],
  [-13.1959, 131.0597],
  [-13.1989, 131.0605],
  [-13.2029, 131.0643],
  [-13.2050, 131.0664],
  [-13.2173, 131.0706],
  [-13.2200, 131.0727],
  [-13.2258, 131.0743],
  [-13.2277, 131.0754],
  [-13.2294, 131.0777],
  [-13.2303, 131.0809],
  [-13.2314, 131.0825],
  [-13.2331, 131.0837],
  [-13.2337, 131.0852],
  [-13.2334, 131.0865],
  [-13.2320, 131.0881],
  [-13.2310, 131.0896],
  [-13.2309, 131.0915],
  [-13.2321, 131.0929],
  [-13.2327, 131.0940],
  [-13.2332, 131.0969],
  [-13.2378, 131.1032]
];

const bridgeCreek = [
  [-13.3980, 131.3026],
  [-13.4106, 131.3233],
  [-13.4221, 131.3339],
  [-13.4352, 131.3406],
  [-13.4365, 131.3420],
  [-13.4376, 131.3441],
  [-13.4430, 131.3667]
];

const sharedPineCreek = [
  [-13.6660, 131.7682],
  [-13.6822, 131.7718],
  [-13.6843, 131.7730],
  [-13.6859, 131.7737],
  [-13.6943, 131.7743],
  [-13.6998, 131.7772],
  [-13.7034, 131.7811],
  [-13.7059, 131.7826],
  [-13.7092, 131.7833],
  [-13.7112, 131.7845],
  [-13.7410, 131.7942],
  [-13.7434, 131.7958],
  [-13.7483, 131.8009],
  [-13.7559, 131.8066],
  [-13.7755, 131.8174],
  [-13.8083, 131.8243],
  [-13.8109, 131.8256],
  [-13.8198, 131.8328]
];

const pineCreekPineCreekSharedOne = [
  [-13.8198, 131.8328],
  [-13.8293, 131.8408],
  [-13.8315, 131.8422],
  [-13.8392, 131.8455],
  [-13.8536, 131.8487]
];

const sharedTwo = [
  [-13.9508, 131.9043],
  [-13.9664, 131.9097],
  [-13.9850, 131.9237]
];

const sharedThree = [
  [-14.0505, 131.9631],
  [-14.0529, 131.9648],
  [-14.0657, 131.9744],
  [-14.0690, 131.9762]
];

const sharedKatherineRiver = [
  [-14.4223, 132.2035],
  [-14.4246, 132.2062],
  [-14.4415, 132.2351],
  [-14.4467, 132.2418],
  [-14.4543, 132.2480],
  [-14.4605, 132.2557]
];

const katherineRiverBirdum = [
  [-14.4605, 132.2557],
  [-14.4686, 132.2663],
  [-14.4694, 132.2690],
  [-14.4705, 132.2746],
  [-14.4824, 132.3283],
  [-14.4852, 132.3328],
  [-14.5287, 132.4074],
  [-14.5774, 132.4911],
  [-14.5788, 132.4942],
  [-14.5879, 132.5361],
  [-14.5896, 132.5400],
  [-14.6801, 132.6904],
  [-14.6821, 132.6947],
  [-14.6889, 132.7197],
  [-14.6901, 132.7219],
  [-14.6910, 132.7256],
  [-14.6917, 132.7323],
  [-14.7077, 132.7771],
  [-14.7139, 132.8044],
  [-14.7245, 132.8267],
  [-14.7278, 132.8310],
  [-14.7420, 132.8410],
  [-14.7477, 132.8480],
  [-14.7500, 132.8505],
  [-14.7595, 132.8568],
  [-14.7617, 132.8585],
  [-14.7738, 132.8802],
  [-14.7750, 132.8848],
  [-14.7763, 132.9025],
  [-14.7784, 132.9089],
  [-14.7815, 132.9134],
  [-14.8086, 132.9350],
  [-14.8264, 132.9589],
  [-14.8340, 132.9695],
  [-14.8362, 132.9717],
  [-14.8590, 132.9903],
  [-14.8618, 132.9934],
  [-14.8723, 133.0126],
  [-14.8747, 133.0159],
  [-14.9235, 133.0697],
  [-14.9276, 133.0726],
  [-14.9317, 133.0736],
  [-14.9434, 133.0738],
  [-15.0507, 133.0926],
  [-15.0548, 133.0932],
  [-15.0592, 133.0925],
  [-15.0949, 133.0811],
  [-15.0983, 133.0807],
  [-15.1016, 133.0813],
  [-15.1307, 133.0904],
  [-15.1728, 133.0951],
  [-15.2025, 133.1053],
  [-15.2062, 133.1054],
  [-15.2343, 133.1150],
  [-15.2584, 133.1191],
  [-15.3013, 133.1426],
  [-15.4193, 133.1816],
  [-15.5632, 133.2119],
  [-15.5957, 133.2183],
  [-15.5985, 133.2185],
  [-15.6018, 133.2177],
  [-15.6113, 133.2114],
  [-15.6148, 133.2101],
  [-15.6181, 133.2105],
  [-15.6440, 133.2195]
];

export const northAustralia: Line = {
  name: 'North Australia',
  state: 'NT',
  segments: [
    {
      segments: [dawrinDarwinRiverDam],
      history: {
        opened: {
          date: '1889-09-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [darwinRiverDamOld],
      history: {
        opened: {
          date: '1889-09-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1972-06-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [darwinRiverDamDeviation],
      history: {
        opened: {
          date: '1972-06-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [darwinRiverDamAdelaideRiver],
      history: {
        opened: {
          date: '1889-09-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bridgeCreek],
      history: {
        opened: {
          date: '1889-09-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sharedPineCreek],
      history: {
        opened: {
          date: '1889-09-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pineCreekPineCreekSharedOne],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sharedTwo],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sharedThree],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sharedKatherineRiver],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [katherineRiverBirdum],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }]
      }
    }
  ]
};
