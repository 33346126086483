import { Line } from "../../trackTypes";

const geraldtonMidlandJunction = [
  [-28.7702, 114.6128],
  [-28.7687, 114.6135],
  [-28.7678, 114.6145],
  [-28.7667, 114.6153],
  [-28.7652, 114.6155],
  [-28.7638, 114.6153],
  [-28.7578, 114.6159],
  [-28.7504, 114.6181],
  [-28.7492, 114.6188],
  [-28.7482, 114.6202]
];

const midlandJunctionNorthampton = [
  [-28.7482, 114.6202],
  [-28.7429, 114.6235],
  [-28.7290, 114.6300],
  [-28.7262, 114.6309],
  [-28.6766, 114.6297],
  [-28.6604, 114.6295],
  [-28.6562, 114.6293],
  [-28.6546, 114.6299],
  [-28.6521, 114.6320],
  [-28.6509, 114.6323],
  [-28.6472, 114.6321],
  [-28.6409, 114.6330],
  [-28.6391, 114.6322],
  [-28.6384, 114.6314],
  [-28.6374, 114.6308],
  [-28.6346, 114.6297],
  [-28.6334, 114.6298],
  [-28.6321, 114.6306],
  [-28.6295, 114.6312],
  [-28.6279, 114.6322],
  [-28.6261, 114.6332],
  [-28.6243, 114.6337],
  [-28.6187, 114.6339],
  [-28.6165, 114.6336],
  [-28.6145, 114.6336],
  [-28.6051, 114.6358],
  [-28.6007, 114.6364],
  [-28.5993, 114.6371],
  [-28.5970, 114.6398],
  [-28.5960, 114.6406],
  [-28.5943, 114.6411],
  [-28.5935, 114.6417],
  [-28.5900, 114.6463],
  [-28.5867, 114.6496],
  [-28.5826, 114.6534],
  [-28.5818, 114.6537],
  [-28.5809, 114.6535],
  [-28.5768, 114.6506],
  [-28.5759, 114.6496],
  [-28.5751, 114.6478],
  [-28.5743, 114.6468],
  [-28.5712, 114.6443],
  [-28.5696, 114.6421],
  [-28.5666, 114.6371],
  [-28.5659, 114.6366],
  [-28.5653, 114.6364],
  [-28.5646, 114.6359],
  [-28.5634, 114.6336],
  [-28.5622, 114.6326],
  [-28.5611, 114.6323],
  [-28.5551, 114.6285],
  [-28.5537, 114.6287],
  [-28.5460, 114.6322],
  [-28.5408, 114.6332],
  [-28.5391, 114.6332],
  [-28.5126, 114.6302],
  [-28.5114, 114.6301],
  [-28.5095, 114.6305],
  [-28.5080, 114.6300],
  [-28.5046, 114.6265],
  [-28.5038, 114.6261],
  [-28.5028, 114.6262],
  [-28.5014, 114.6268],
  [-28.5006, 114.6269],
  [-28.4966, 114.6266],
  [-28.4957, 114.6268],
  [-28.4919, 114.6295],
  [-28.4915, 114.6300],
  [-28.4913, 114.6306],
  [-28.4906, 114.6349],
  [-28.4892, 114.6378],
  [-28.4886, 114.6384],
  [-28.4875, 114.6386],
  [-28.4868, 114.6390],
  [-28.4865, 114.6397],
  [-28.4859, 114.6426],
  [-28.4854, 114.6436],
  [-28.4816, 114.6469],
  [-28.4801, 114.6479],
  [-28.4776, 114.6488],
  [-28.4722, 114.6519],
  [-28.4715, 114.6525],
  [-28.4698, 114.6545],
  [-28.4690, 114.6549],
  [-28.4672, 114.6554],
  [-28.4657, 114.6561],
  [-28.4627, 114.6581],
  [-28.4618, 114.6583],
  [-28.4609, 114.6581],
  [-28.4599, 114.6579],
  [-28.4592, 114.6573],
  [-28.4584, 114.6558],
  [-28.4570, 114.6543],
  [-28.4565, 114.6533],
  [-28.4556, 114.6525],
  [-28.4546, 114.6522],
  [-28.4535, 114.6516],
  [-28.4529, 114.6503],
  [-28.4523, 114.6497],
  [-28.4510, 114.6494],
  [-28.4503, 114.6494],
  [-28.4496, 114.6490],
  [-28.4487, 114.6487],
  [-28.4473, 114.6489],
  [-28.4463, 114.6488],
  [-28.4453, 114.6484],
  [-28.4445, 114.6485],
  [-28.4439, 114.6489],
  [-28.4427, 114.6503],
  [-28.4421, 114.6507],
  [-28.4378, 114.6518],
  [-28.4339, 114.6517],
  [-28.4333, 114.6519],
  [-28.4321, 114.6528],
  [-28.4298, 114.6532],
  [-28.4257, 114.6527],
  [-28.4250, 114.6525],
  [-28.4231, 114.6509],
  [-28.4221, 114.6508],
  [-28.4212, 114.6513],
  [-28.4188, 114.6547],
  [-28.4184, 114.6555],
  [-28.4183, 114.6572],
  [-28.4187, 114.6587],
  [-28.4186, 114.6598],
  [-28.4180, 114.6607],
  [-28.4168, 114.6615],
  [-28.4137, 114.6614],
  [-28.4124, 114.6618],
  [-28.4108, 114.6625],
  [-28.4100, 114.6625],
  [-28.4087, 114.6621],
  [-28.4076, 114.6622],
  [-28.4024, 114.6638],
  [-28.4015, 114.6643],
  [-28.3984, 114.6670],
  [-28.3977, 114.6672],
  [-28.3959, 114.6674],
  [-28.3952, 114.6671],
  [-28.3924, 114.6651],
  [-28.3917, 114.6649],
  [-28.3910, 114.6651],
  [-28.3900, 114.6658],
  [-28.3883, 114.6661],
  [-28.3876, 114.6663],
  [-28.3821, 114.6698],
  [-28.3813, 114.6701],
  [-28.3804, 114.6699],
  [-28.3784, 114.6687],
  [-28.3777, 114.6679],
  [-28.3759, 114.6638],
  [-28.3712, 114.6594],
  [-28.3704, 114.6582],
  [-28.3695, 114.6561],
  [-28.3676, 114.6533],
  [-28.3667, 114.6527],
  [-28.3660, 114.6522],
  [-28.3618, 114.6463],
  [-28.3616, 114.6456],
  [-28.3616, 114.6447],
  [-28.3640, 114.6385],
  [-28.3639, 114.6372],
  [-28.3630, 114.6355],
  [-28.3622, 114.6348],
  [-28.3581, 114.6327],
  [-28.3565, 114.6326],
  [-28.3554, 114.6333],
  [-28.3544, 114.6342],
  [-28.3535, 114.6347],
  [-28.3494, 114.6357]
];

const northamptonAjana = [
  [-28.3494, 114.6357],
  [-28.3476, 114.6360],
  [-28.3463, 114.6359],
  [-28.3442, 114.6354],
  [-28.3431, 114.6356],
  [-28.3422, 114.6363],
  [-28.3414, 114.6381],
  [-28.3403, 114.6387],
  [-28.3395, 114.6390],
  [-28.3389, 114.6395],
  [-28.3381, 114.6411],
  [-28.3372, 114.6433],
  [-28.3365, 114.6440],
  [-28.3361, 114.6448],
  [-28.3356, 114.6465],
  [-28.3344, 114.6481],
  [-28.3314, 114.6493],
  [-28.3270, 114.6521],
  [-28.3249, 114.6528],
  [-28.3180, 114.6529],
  [-28.3158, 114.6528],
  [-28.3120, 114.6516],
  [-28.3107, 114.6506],
  [-28.3089, 114.6492],
  [-28.3082, 114.6490],
  [-28.3071, 114.6486],
  [-28.3063, 114.6481],
  [-28.2960, 114.6408],
  [-28.2951, 114.6405],
  [-28.2916, 114.6404],
  [-28.2904, 114.6407],
  [-28.2888, 114.6424],
  [-28.2879, 114.6428],
  [-28.2868, 114.6427],
  [-28.2857, 114.6419],
  [-28.2846, 114.6415],
  [-28.2808, 114.6413],
  [-28.2773, 114.6428],
  [-28.2762, 114.6430],
  [-28.2711, 114.6421],
  [-28.2684, 114.6418],
  [-28.2672, 114.6415],
  [-28.2665, 114.6408],
  [-28.2655, 114.6393],
  [-28.2643, 114.6383],
  [-28.2636, 114.6374],
  [-28.2626, 114.6353],
  [-28.2614, 114.6343],
  [-28.2594, 114.6338],
  [-28.2583, 114.6340],
  [-28.2576, 114.6345],
  [-28.2559, 114.6373],
  [-28.2524, 114.6403],
  [-28.2492, 114.6416],
  [-28.2473, 114.6416],
  [-28.2461, 114.6423],
  [-28.2454, 114.6437],
  [-28.2445, 114.6447],
  [-28.2424, 114.6460],
  [-28.2399, 114.6466],
  [-28.2376, 114.6464],
  [-28.2055, 114.6368],
  [-28.2000, 114.6340],
  [-28.1972, 114.6339],
  [-28.1845, 114.6434],
  [-28.1801, 114.6451],
  [-28.1752, 114.6480],
  [-28.1732, 114.6480],
  [-28.1705, 114.6469],
  [-28.1686, 114.6469],
  [-28.1607, 114.6509],
  [-28.1591, 114.6517],
  [-28.1562, 114.6516],
  [-28.1550, 114.6513],
  [-28.1489, 114.6491],
  [-28.1403, 114.6472],
  [-28.1387, 114.6471],
  [-28.1361, 114.6462],
  [-28.1310, 114.6436],
  [-28.1286, 114.6437],
  [-28.1259, 114.6460],
  [-28.1232, 114.6517],
  [-28.1220, 114.6558],
  [-28.1193, 114.6606],
  [-28.1178, 114.6653],
  [-28.1164, 114.6669],
  [-28.1145, 114.6672],
  [-28.1098, 114.6659],
  [-28.1087, 114.6657],
  [-28.1071, 114.6657],
  [-28.1055, 114.6660],
  [-28.1044, 114.6658],
  [-28.1033, 114.6651],
  [-28.0996, 114.6621],
  [-28.0986, 114.6617],
  [-28.0977, 114.6620],
  [-28.0966, 114.6627],
  [-28.0927, 114.6627],
  [-28.0914, 114.6631],
  [-28.0904, 114.6648],
  [-28.0895, 114.6655],
  [-28.0865, 114.6662],
  [-28.0844, 114.6660],
  [-28.0701, 114.6634],
  [-28.0685, 114.6637],
  [-28.0669, 114.6649],
  [-28.0574, 114.6705],
  [-28.0529, 114.6709],
  [-28.0504, 114.6718],
  [-28.0483, 114.6729],
  [-28.0460, 114.6730],
  [-28.0427, 114.6726],
  [-28.0396, 114.6732],
  [-28.0371, 114.6754],
  [-28.0355, 114.6761],
  [-28.0313, 114.6774],
  [-28.0297, 114.6772],
  [-28.0270, 114.6762],
  [-28.0228, 114.6722],
  [-28.0207, 114.6711],
  [-28.0195, 114.6694],
  [-28.0191, 114.6664],
  [-28.0183, 114.6641],
  [-28.0082, 114.6505],
  [-27.9950, 114.6287],
  [-27.9937, 114.6273],
  [-27.9921, 114.6265],
  [-27.9902, 114.6262],
  [-27.9831, 114.6278],
  [-27.9818, 114.6285],
  [-27.9798, 114.6305],
  [-27.9779, 114.6315],
  [-27.9637, 114.6352],
  [-27.9596, 114.6350],
  [-27.9586, 114.6353],
  [-27.9571, 114.6363],
  [-27.9564, 114.6376],
  [-27.9556, 114.6383],
  [-27.9535, 114.6387],
  [-27.9505, 114.6393],
  [-27.9448, 114.6426]
];

export const northampton: Line = {
  name: 'Northampton',
  state: 'WA',
  segments: [
    {
      segments: [geraldtonMidlandJunction],
      history: {
        opened: {
          date: '1879-07-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [midlandJunctionNorthampton],
      history: {
        opened: {
          date: '1879-07-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-04-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northamptonAjana],
      history: {
        opened: {
          date: '1913-03-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-04-29',
          status: 'closed'
        }]
      }
    }
  ]
};
