import { Line } from "../../trackTypes";

const line = [
  [-35.3002, 149.4277],
  [-35.3017, 149.4271],
  [-35.3064, 149.4261],
  [-35.3075, 149.4261],
  [-35.3142, 149.4276],
  [-35.3215, 149.4274],
  [-35.3228, 149.4278],
  [-35.3236, 149.4286],
  [-35.3246, 149.4303],
  [-35.3253, 149.4310],
  [-35.3260, 149.4313],
  [-35.3272, 149.4314],
  [-35.3297, 149.4313],
  [-35.3322, 149.4315],
  [-35.3365, 149.4326],
  [-35.3387, 149.4331],
  [-35.3485, 149.4333],
  [-35.3504, 149.4336],
  [-35.3744, 149.4391],
  [-35.4006, 149.4453],
  [-35.4088, 149.4485],
  [-35.4103, 149.4486],
  [-35.4120, 149.4482],
  [-35.4133, 149.4482],
  [-35.4170, 149.4493],
  [-35.4189, 149.4505],
  [-35.4222, 149.4515],
  [-35.4233, 149.4521],
  [-35.4244, 149.4532],
  [-35.4257, 149.4541],
  [-35.4374, 149.4563],
  [-35.4394, 149.4561],
  [-35.4409, 149.4553],
  [-35.4422, 149.4550],
  [-35.4482, 149.4560],
  [-35.4508, 149.4557],
  [-35.4538, 149.4544],
  [-35.4550, 149.4540],
  [-35.4565, 149.4539],
  [-35.4572, 149.4537],
  [-35.4590, 149.4524],
  [-35.4596, 149.4513],
  [-35.4601, 149.4498],
  [-35.4610, 149.4487],
  [-35.4626, 149.4477],
  [-35.4636, 149.4474],
  [-35.4706, 149.4477],
  [-35.4722, 149.4482],
  [-35.4741, 149.4495],
  [-35.4752, 149.4500],
  [-35.4766, 149.4501],
  [-35.4781, 149.4499],
  [-35.4790, 149.4500],
  [-35.4844, 149.4512],
  [-35.4863, 149.4512],
  [-35.4916, 149.4491],
  [-35.4946, 149.4468],
  [-35.4999, 149.4445],
  [-35.5041, 149.4433],
  [-35.5059, 149.4431],
  [-35.5103, 149.4435],
  [-35.5118, 149.4439],
  [-35.5129, 149.4438],
  [-35.5162, 149.4427],
  [-35.5174, 149.4427],
  [-35.5183, 149.4431],
  [-35.5209, 149.4458],
  [-35.5220, 149.4466],
  [-35.5232, 149.4468],
  [-35.5254, 149.4465],
  [-35.5279, 149.4467],
  [-35.5298, 149.4464],
  [-35.5375, 149.4467],
  [-35.5388, 149.4463],
  [-35.5400, 149.4455],
  [-35.5407, 149.4452],
  [-35.5444, 149.4460],
  [-35.5451, 149.4461],
  [-35.5480, 149.4455],
  [-35.5493, 149.4444],
  [-35.5510, 149.4436],
  [-35.5522, 149.4436],
  [-35.5535, 149.4444],
  [-35.5543, 149.4446],
  [-35.5563, 149.4444],
  [-35.5608, 149.4423],
  [-35.5623, 149.4421],
  [-35.5641, 149.4414],
  [-35.5653, 149.4405],
  [-35.5660, 149.4402],
  [-35.5697, 149.4399],
  [-35.5708, 149.4404],
  [-35.5720, 149.4417],
  [-35.5731, 149.4420],
  [-35.5741, 149.4417],
  [-35.5755, 149.4403],
  [-35.5777, 149.4391],
  [-35.5791, 149.4392],
  [-35.5803, 149.4398],
  [-35.5812, 149.4399],
  [-35.5820, 149.4396],
  [-35.5834, 149.4389],
  [-35.5849, 149.4386],
  [-35.5864, 149.4379],
  [-35.5876, 149.4368],
  [-35.5886, 149.4366],
  [-35.5896, 149.4371],
  [-35.5914, 149.4398]
];

export const captainsFlat: Line = {
  name: 'Captains Flat',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1940-06-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-31',
          status: 'closed'
        }]
      }
    }
  ]
};
