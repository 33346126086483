import { Line } from "../../trackTypes";

const waginDumbleyung = [
  [-33.3150, 117.3486],
  [-33.3159, 117.3496],
  [-33.3164, 117.3507],
  [-33.3164, 117.3519],
  [-33.3160, 117.3532],
  [-33.3154, 117.3540],
  [-33.3105, 117.3599],
  [-33.3096, 117.3613],
  [-33.3081, 117.3655],
  [-33.3065, 117.3753],
  [-33.3064, 117.3838],
  [-33.3062, 117.3852],
  [-33.3023, 117.4002],
  [-33.2976, 117.4101],
  [-33.2971, 117.4122],
  [-33.2970, 117.4208],
  [-33.2963, 117.4234],
  [-33.2936, 117.4276],
  [-33.2925, 117.4311],
  [-33.2910, 117.4475],
  [-33.2866, 117.4663],
  [-33.2862, 117.4692],
  [-33.2864, 117.4747],
  [-33.2895, 117.4902],
  [-33.2893, 117.5031],
  [-33.2898, 117.5059],
  [-33.2963, 117.5190],
  [-33.3017, 117.5244],
  [-33.3030, 117.5252],
  [-33.3077, 117.5265],
  [-33.3097, 117.5282],
  [-33.3107, 117.5308],
  [-33.3107, 117.5446],
  [-33.3102, 117.5473],
  [-33.3063, 117.5581],
  [-33.3061, 117.5597],
  [-33.3061, 117.5932],
  [-33.3058, 117.5951],
  [-33.3013, 117.6105],
  [-33.2992, 117.6186],
  [-33.2994, 117.6209],
  [-33.3006, 117.6223],
  [-33.3020, 117.6229],
  [-33.3032, 117.6238],
  [-33.3037, 117.6252],
  [-33.3040, 117.6284],
  [-33.3035, 117.6311],
  [-33.3024, 117.6325],
  [-33.2965, 117.6352],
  [-33.2954, 117.6367],
  [-33.2952, 117.6385],
  [-33.2956, 117.6399],
  [-33.3014, 117.6483],
  [-33.3021, 117.6506],
  [-33.3050, 117.7118],
  [-33.3055, 117.7148],
  [-33.3079, 117.7221],
  [-33.3145, 117.7337],
  [-33.3150, 117.7352],
  [-33.3150, 117.7398]
];

const waginSouthJunction = [
  [-33.3200, 117.3512],
  [-33.3191, 117.3511],
  [-33.3181, 117.3513],
  [-33.3173, 117.3518],
  [-33.3159, 117.3535],
  [-33.3154, 117.3540]
];

const dumbleyungKukerin = [
  [-33.3150, 117.7398],
  [-33.3150, 117.7484],
  [-33.3143, 117.7509],
  [-33.3002, 117.7707],
  [-33.2970, 117.7735],
  [-33.2936, 117.7787],
  [-33.2924, 117.7798],
  [-33.2901, 117.7809],
  [-33.2878, 117.7835],
  [-33.2871, 117.7862],
  [-33.2864, 117.7873],
  [-33.2854, 117.7882],
  [-33.2845, 117.7897],
  [-33.2840, 117.7925],
  [-33.2828, 117.7947],
  [-33.2825, 117.7971],
  [-33.2834, 117.8019],
  [-33.2833, 117.8041],
  [-33.2826, 117.8058],
  [-33.2801, 117.8094],
  [-33.2793, 117.8111],
  [-33.2758, 117.8165],
  [-33.2745, 117.8180],
  [-33.2734, 117.8205],
  [-33.2724, 117.8346],
  [-33.2725, 117.8361],
  [-33.2749, 117.8476],
  [-33.2749, 117.8539],
  [-33.2740, 117.8564],
  [-33.2347, 117.9092],
  [-33.2282, 117.9260],
  [-33.2268, 117.9331],
  [-33.2268, 117.9346],
  [-33.2327, 117.9559],
  [-33.2346, 117.9602],
  [-33.2350, 117.9619],
  [-33.2350, 117.9782],
  [-33.2322, 117.9873],
  [-33.2313, 117.9970],
  [-33.2289, 118.0058],
  [-33.2276, 118.0078],
  [-33.2199, 118.0141],
  [-33.2186, 118.0159],
  [-33.2177, 118.0182],
  [-33.2168, 118.0195],
  [-33.2149, 118.0211],
  [-33.2139, 118.0232],
  [-33.2139, 118.0259],
  [-33.2132, 118.0292],
  [-33.2132, 118.0304],
  [-33.2134, 118.0319],
  [-33.2130, 118.0339],
  [-33.2116, 118.0351],
  [-33.2101, 118.0355],
  [-33.2087, 118.0367],
  [-33.2082, 118.0386],
  [-33.2084, 118.0408],
  [-33.2090, 118.0421],
  [-33.2103, 118.0439],
  [-33.2111, 118.0466],
  [-33.2108, 118.0490],
  [-33.2040, 118.0624],
  [-33.2028, 118.0638],
  [-33.1977, 118.0673],
  [-33.1965, 118.0686],
  [-33.1945, 118.0718],
  [-33.1940, 118.0735],
  [-33.1936, 118.0768],
  [-33.1930, 118.0784],
  [-33.1873, 118.0873]
];

const kukerinLakeGrace = [
  [-33.1873, 118.0873],
  [-33.1865, 118.0881],
  [-33.1854, 118.0886],
  [-33.1788, 118.0895],
  [-33.1770, 118.0903],
  [-33.1730, 118.0946],
  [-33.1721, 118.0968],
  [-33.1721, 118.0995],
  [-33.1726, 118.1013],
  [-33.1726, 118.1034],
  [-33.1718, 118.1053],
  [-33.1643, 118.1171],
  [-33.1637, 118.1188],
  [-33.1629, 118.1232],
  [-33.1623, 118.1246],
  [-33.1469, 118.1513],
  [-33.1464, 118.1524],
  [-33.1448, 118.1582],
  [-33.1439, 118.1645],
  [-33.1426, 118.1666],
  [-33.1409, 118.1675],
  [-33.1367, 118.1680],
  [-33.1348, 118.1693],
  [-33.1340, 118.1710],
  [-33.1335, 118.1744],
  [-33.1330, 118.1758],
  [-33.1273, 118.1865],
  [-33.1258, 118.1878],
  [-33.1244, 118.1882],
  [-33.1222, 118.1880],
  [-33.1203, 118.1886],
  [-33.1186, 118.1902],
  [-33.1177, 118.1925],
  [-33.1175, 118.1991],
  [-33.1174, 118.2003],
  [-33.1120, 118.2201],
  [-33.1115, 118.2214],
  [-33.1107, 118.2225],
  [-33.1101, 118.2240],
  [-33.1084, 118.2439],
  [-33.1106, 118.2600],
  [-33.1105, 118.3064],
  [-33.1126, 118.3138],
  [-33.1135, 118.3156],
  [-33.1170, 118.3206],
  [-33.1177, 118.3227],
  [-33.1192, 118.3400],
  [-33.1187, 118.3423],
  [-33.1178, 118.3438],
  [-33.1107, 118.3505],
  [-33.1097, 118.3522],
  [-33.1065, 118.3645],
  [-33.1064, 118.3671],
  [-33.1091, 118.3809],
  [-33.1092, 118.4077],
  [-33.1089, 118.4100],
  [-33.1005, 118.4376],
  [-33.1003, 118.4393],
  [-33.1003, 118.4698]
];

const lakeGraceNewdegate = [
  [-33.1003, 118.4698],
  [-33.0996, 118.4718],
  [-33.0986, 118.4726],
  [-33.0889, 118.4751],
  [-33.0875, 118.4761],
  [-33.0869, 118.4772],
  [-33.0868, 118.4785],
  [-33.0870, 118.4818],
  [-33.0889, 118.4884],
  [-33.0889, 118.4899],
  [-33.0868, 118.4954],
  [-33.0861, 118.4963],
  [-33.0851, 118.4969],
  [-33.0836, 118.4972],
  [-33.0823, 118.4981],
  [-33.0817, 118.4998],
  [-33.0816, 118.5029],
  [-33.0814, 118.5039],
  [-33.0758, 118.5193],
  [-33.0749, 118.5205],
  [-33.0736, 118.5216],
  [-33.0728, 118.5225],
  [-33.0700, 118.5303],
  [-33.0689, 118.5356],
  [-33.0659, 118.5417],
  [-33.0635, 118.5447],
  [-33.0629, 118.5459],
  [-33.0608, 118.5522],
  [-33.0500, 118.5680],
  [-33.0157, 118.6625],
  [-33.0149, 118.6675],
  [-33.0150, 118.6710],
  [-33.0185, 118.6978],
  [-33.0185, 118.7012],
  [-33.0158, 118.7142],
  [-33.0095, 118.7274],
  [-33.0089, 118.7295],
  [-33.0091, 118.7311],
  [-33.0098, 118.7342],
  [-33.0099, 118.7360],
  [-33.0090, 118.7402],
  [-33.0067, 118.7446],
  [-33.0060, 118.7471],
  [-33.0061, 118.7560],
  [-33.0058, 118.7576],
  [-33.0050, 118.7590],
  [-33.0048, 118.7601],
  [-33.0048, 118.7618],
  [-33.0041, 118.7637],
  [-33.0024, 118.7652],
  [-33.0017, 118.7667],
  [-33.0012, 118.7714],
  [-33.0015, 118.7751],
  [-33.0032, 118.7805],
  [-33.0056, 118.7849],
  [-33.0060, 118.7866],
  [-33.0046, 118.8001],
  [-33.0050, 118.8024],
  [-33.0061, 118.8048],
  [-33.0063, 118.8070],
  [-33.0042, 118.8144],
  [-33.0035, 118.8156],
  [-33.0022, 118.8168],
  [-33.0015, 118.8185],
  [-33.0014, 118.8660],
  [-33.0017, 118.8682],
  [-33.0057, 118.8862],
  [-33.0057, 118.8878],
  [-33.0016, 118.8963],
  [-33.0014, 118.8973],
  [-33.0018, 118.8999],
  [-33.0019, 118.9011],
  [-33.0013, 118.9061],
  [-33.0013, 118.9364],
  [-33.0011, 118.9375],
  [-33.0004, 118.9391],
  [-33.0004, 118.9411],
  [-33.0013, 118.9426],
  [-33.0162, 118.9528],
  [-33.0187, 118.9532],
  [-33.0221, 118.9517],
  [-33.0237, 118.9519],
  [-33.0252, 118.9535],
  [-33.0261, 118.9566],
  [-33.0272, 118.9579],
  [-33.0285, 118.9584],
  [-33.0318, 118.9586],
  [-33.0333, 118.9591],
  [-33.0419, 118.9650],
  [-33.0446, 118.9663],
  [-33.0457, 118.9671],
  [-33.0492, 118.9711],
  [-33.0624, 118.9803],
  [-33.0636, 118.9816],
  [-33.0685, 118.9890],
  [-33.0696, 118.9899],
  [-33.0724, 118.9907],
  [-33.0738, 118.9918],
  [-33.0810, 119.0069],
  [-33.0815, 119.0093],
  [-33.0820, 119.0105],
  [-33.0942, 119.0266]
];

export const newdegate: Line = {
  name: 'Newdegate',
  state: 'WA',
  segments: [
    {
      segments: [waginDumbleyung],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waginSouthJunction],
      history: {
        opened: {
          date: '1981-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [dumbleyungKukerin],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kukerinLakeGrace],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [lakeGraceNewdegate],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
};
