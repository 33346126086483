import { Line } from "../../../trackTypes";

const branxholmeHenty = [
  [-37.8553, 141.7955],
  [-37.8539, 141.7953],
  [-37.8523, 141.7946],
  [-37.8510, 141.7930],
  [-37.8443, 141.7800],
  [-37.8433, 141.7769],
  [-37.8429, 141.7718],
  [-37.8421, 141.7695],
  [-37.8409, 141.7660],
  [-37.8380, 141.7589],
  [-37.8318, 141.7461],
  [-37.8294, 141.7392],
  [-37.8202, 141.7242],
  [-37.8185, 141.7207],
  [-37.8054, 141.6695],
  [-37.7949, 141.6388],
  [-37.7906, 141.6179],
  [-37.7815, 141.6008],
  [-37.7638, 141.5838],
  [-37.7620, 141.5813],
  [-37.7608, 141.5767],
  [-37.7605, 141.5733],
  [-37.7608, 141.5701],
  [-37.7596, 141.5675],
  [-37.7571, 141.5657],
  [-37.7536, 141.5610],
  [-37.7527, 141.5585],
  [-37.7525, 141.5536],
  [-37.7507, 141.5501],
  [-37.7479, 141.5484],
  [-37.7448, 141.5491],
  [-37.7410, 141.5527],
  [-37.7372, 141.5542],
  [-37.7352, 141.5539],
  [-37.7333, 141.5523],
  [-37.7321, 141.5510],
  [-37.7309, 141.5504],
  [-37.7272, 141.5502],
  [-37.7243, 141.5496],
  [-37.7193, 141.5467],
  [-37.7179, 141.5465],
  [-37.7145, 141.5470],
  [-37.7067, 141.5459],
  [-37.7015, 141.5467],
  [-37.6950, 141.5505],
  [-37.6934, 141.5509],
  [-37.6921, 141.5506],
  [-37.6865, 141.5464],
  [-37.6824, 141.5442],
  [-37.6811, 141.5426],
  [-37.6797, 141.5401],
  [-37.6784, 141.5390],
  [-37.6771, 141.5386],
  [-37.6761, 141.5377],
  [-37.6754, 141.5366],
  [-37.6741, 141.5355],
  [-37.6669, 141.5296],
  [-37.6646, 141.5285],
  [-37.6632, 141.5264],
  [-37.6616, 141.5168]
];

const hentyCasterton = [
  [-37.6616, 141.5168],
  [-37.6601, 141.5075],
  [-37.6602, 141.5027],
  [-37.6614, 141.4940],
  [-37.6612, 141.4917],
  [-37.6603, 141.4892],
  [-37.6512, 141.4730],
  [-37.6411, 141.4598],
  [-37.6395, 141.4585],
  [-37.6253, 141.4489],
  [-37.6199, 141.4462],
  [-37.6141, 141.4418],
  [-37.6120, 141.4413],
  [-37.6059, 141.4423],
  [-37.6040, 141.4419],
  [-37.6024, 141.4409],
  [-37.6012, 141.4392],
  [-37.6002, 141.4352],
  [-37.5993, 141.4239],
  [-37.5987, 141.4219],
  [-37.5955, 141.4152],
  [-37.5935, 141.4127],
  [-37.5910, 141.4103],
  [-37.5870, 141.4027]
];

export const casterton: Line = {
  name: 'Casterton',
  state: 'VIC',
  segments: [
    {
      segments: [branxholmeHenty],
      history: {
        opened: {
          date: '1884-02-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-09-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hentyCasterton],
      history: {
        opened: {
          date: '1884-09-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-09-12',
          status: 'closed'
        }]
      }
    }
  ]
}
