import { Line } from "../../../trackTypes";

const line = [
  [-33.8641, 151.0520],
  [-33.8634, 151.0537],
  [-33.8632, 151.0547],
  [-33.8634, 151.0556],
  [-33.8641, 151.0567],
  [-33.8654, 151.0574],
  [-33.8659, 151.0579],
  [-33.8662, 151.0585],
  [-33.8666, 151.0600],
  [-33.8670, 151.0611],
  [-33.8675, 151.0616],
  [-33.8721, 151.0652],
  [-33.8743, 151.0664],
  [-33.8812, 151.0686],
  [-33.8830, 151.0691],
  [-33.8841, 151.0692],
  [-33.8849, 151.0690],
  [-33.8862, 151.0686],
  [-33.8921, 151.0674],
  [-33.8933, 151.0675],
  [-33.9002, 151.0700],
  [-33.9030, 151.0716],
  [-33.9036, 151.0722],
  [-33.9077, 151.0780],
  [-33.9079, 151.0793],
  [-33.9079, 151.0819],
  [-33.9081, 151.0828],
  [-33.9134, 151.0949],
  [-33.9135, 151.0959],
  [-33.9131, 151.0971]
];

export const flemingtonGoods: Line = {
  name: 'Flemington Goods',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1916-04-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
