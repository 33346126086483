import { Line } from "../../../trackTypes";

const emeraldJunction = [
  [-23.5362, 148.1742],
  [-23.5367, 148.1746],
  [-23.5372, 148.1747],
  [-23.5385, 148.1743]
];

const emeraldWarbaJunction = [
  [-23.5374, 148.1763],
  [-23.5374, 148.1755],
  [-23.5377, 148.1749],
  [-23.5385, 148.1743],
  [-23.5742, 148.1682],
  [-23.7129, 148.1445],
  [-23.7751, 148.1337],
  [-23.7879, 148.1296],
  [-23.7917, 148.1289],
  [-23.8011, 148.1292],
  [-23.8147, 148.1268],
  [-23.8210, 148.1250],
  [-23.8224, 148.1249],
  [-23.8264, 148.1258],
  [-23.8285, 148.1255],
  [-23.8309, 148.1241],
  [-23.8366, 148.1230],
  [-23.8394, 148.1230],
  [-23.8482, 148.1241],
  [-23.8505, 148.1243],
  [-23.8530, 148.1242],
  [-23.8573, 148.1234],
  [-23.8581, 148.1234],
  [-23.8591, 148.1237],
  [-23.8605, 148.1235],
  [-23.8615, 148.1228],
  [-23.8624, 148.1222],
  [-23.8634, 148.1220],
  [-23.8708, 148.1235],
  [-23.8715, 148.1236],
  [-23.9149, 148.1180],
  [-23.9169, 148.1180]
];

const wurbaJunctionSpringsure = [
  [-23.9169, 148.1180],
  [-23.9688, 148.1196],
  [-23.9867, 148.1183],
  [-23.9919, 148.1172],
  [-23.9974, 148.1167],
  [-23.9998, 148.1171],
  [-24.0024, 148.1180],
  [-24.0042, 148.1184],
  [-24.0056, 148.1184],
  [-24.0066, 148.1187],
  [-24.0078, 148.1196],
  [-24.0086, 148.1203],
  [-24.0102, 148.1209],
  [-24.0163, 148.1208],
  [-24.0173, 148.1206],
  [-24.0182, 148.1201],
  [-24.0193, 148.1189],
  [-24.0200, 148.1183],
  [-24.0211, 148.1181],
  [-24.0250, 148.1184],
  [-24.0310, 148.1180],
  [-24.0360, 148.1166],
  [-24.0752, 148.0972],
  [-24.0771, 148.0963],
  [-24.0946, 148.0913],
  [-24.0971, 148.0903],
  [-24.1000, 148.0888],
  [-24.1015, 148.0879],
  [-24.1045, 148.0876],
  [-24.1055, 148.0879],
  [-24.1069, 148.0888],
  [-24.1082, 148.0891],
  [-24.1094, 148.0888],
  [-24.1105, 148.0878],
  [-24.1111, 148.0871],
  [-24.1119, 148.0866],
  [-24.1135, 148.0864],
  [-24.1143, 148.0861],
  [-24.1175, 148.0840]
];

const balloonLoop = [
  [-23.9169, 148.1180],
  [-23.9190, 148.1175],
  [-23.9206, 148.1163],
  [-23.9215, 148.1148],
  [-23.9225, 148.1112],
  [-23.9243, 148.1053],
  [-23.9243, 148.1039],
  [-23.9236, 148.1025],
  [-23.9224, 148.1017],
  [-23.9212, 148.1016],
  [-23.9201, 148.1022],
  [-23.9192, 148.1034],
  [-23.9190, 148.1049],
  [-23.9194, 148.1060],
  [-23.9203, 148.1071],
  [-23.9215, 148.1078],
  [-23.9222, 148.1086],
  [-23.9226, 148.1097],
  [-23.9225, 148.1112]
];

export const springsure: Line = {
  name: 'Springsure',
  state: 'QLD',
  segments: [
    {
      segments: [emeraldWarbaJunction, emeraldJunction],
      history: {
        opened: {
          date: '1887-08-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wurbaJunctionSpringsure],
      history: {
        opened: {
          date: '1887-08-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balloonLoop],
      history: {
        opened: {
          date: '2006-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
