import { Line } from "../../trackTypes";

const mountArthurCoalLoader = [
  [-32.3485, 150.9904],
  [-32.3440, 150.9825],
  [-32.3405, 150.9734],
  [-32.3399, 150.9694],
  [-32.3402, 150.9649],
  [-32.3399, 150.9629],
  [-32.3391, 150.9612],
  [-32.3372, 150.9589],
  [-32.3363, 150.9570],
  [-32.3362, 150.9551],
  [-32.3377, 150.9476],
  [-32.3376, 150.9456],
  [-32.3370, 150.9439],
  [-32.3352, 150.9414],
  [-32.3332, 150.9382],
  [-32.3324, 150.9360],
  [-32.3314, 150.9302],
  [-32.3313, 150.9277],
  [-32.3320, 150.9250],
  [-32.3319, 150.9228],
  [-32.3311, 150.9203],
  [-32.3311, 150.9191],
  [-32.3343, 150.9100],
  [-32.3344, 150.9091],
  [-32.3343, 150.9047],
  [-32.3344, 150.9039],
  [-32.3350, 150.9025],
  [-32.3352, 150.9015],
  [-32.3348, 150.9003],
  [-32.3338, 150.8993],
  [-32.3298, 150.8952]
];

const peltonCollieryBranch = [
  [-32.8437, 151.3652],
  [-32.8438, 151.3639],
  [-32.8445, 151.3624],
  [-32.8456, 151.3615],
  [-32.8479, 151.3610],
  [-32.8489, 151.3603],
  [-32.8495, 151.3593],
  [-32.8498, 151.3581],
  [-32.8550, 151.3429],
  [-32.8576, 151.3377],
  [-32.8594, 151.3346],
  [-32.8596, 151.3331],
  [-32.8582, 151.3239],
  [-32.8583, 151.3228],
  [-32.8588, 151.3219],
  [-32.8608, 151.3200],
  [-32.8629, 151.3184],
  [-32.8638, 151.3180],
  [-32.8659, 151.3179],
  [-32.8670, 151.3174],
  [-32.8678, 151.3164],
  [-32.8686, 151.3143],
  [-32.8692, 151.3133],
  [-32.8718, 151.3110],
  [-32.8725, 151.3097],
  [-32.8727, 151.3074],
  [-32.8723, 151.3047],
  [-32.8723, 151.3035],
  [-32.8737, 151.2988],
  [-32.8751, 151.2961]
];

export const hunterValleyColleries: Line = {
  name: 'Hunter Valley Colleries',
  state: 'NSW',
  segments: [
    {
      segments: [mountArthurCoalLoader],
      history: {
        opened: {
          date: '1983-06-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [peltonCollieryBranch],
      history: {
        opened: {
          date: '1918-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
