import { Line } from "../../../trackTypes";

const line = [
  [-37.6711, 175.1505],
  [-37.6718, 175.1507],
  [-37.6721, 175.1506],
  [-37.6724, 175.1503],
  [-37.6726, 175.1500],
  [-37.6741, 175.1461],
  [-37.6744, 175.1457],
  [-37.6752, 175.1433],
  [-37.6754, 175.1430],
  [-37.6767, 175.1423],
  [-37.6774, 175.1417],
  [-37.6782, 175.1403],
  [-37.6787, 175.1388],
  [-37.6788, 175.1381],
  [-37.6787, 175.1371],
  [-37.6790, 175.1356],
  [-37.6797, 175.1344],
  [-37.6802, 175.1341],
  [-37.6807, 175.1335],
  [-37.6808, 175.1328],
  [-37.6807, 175.1318],
  [-37.6810, 175.1310],
  [-37.6814, 175.1305],
  [-37.6821, 175.1296],
  [-37.6823, 175.1291],
  [-37.6824, 175.1286],
  [-37.6823, 175.1272],
  [-37.6823, 175.1267],
  [-37.6825, 175.1263],
  [-37.6847, 175.1236],
  [-37.6853, 175.1216],
  [-37.6852, 175.1208],
  [-37.6844, 175.1174],
  [-37.6844, 175.1165],
  [-37.6843, 175.1155],
  [-37.6857, 175.1127],
  [-37.6857, 175.1122],
  [-37.6852, 175.1113],
  [-37.6840, 175.1100],
  [-37.6838, 175.1096],
  [-37.6838, 175.1091],
  [-37.6840, 175.1076],
  [-37.6838, 175.1070],
  [-37.6833, 175.1064],
  [-37.6831, 175.1061],
  [-37.6830, 175.1050],
  [-37.6827, 175.1043],
  [-37.6814, 175.1028],
  [-37.6810, 175.1026],
  [-37.6806, 175.1025],
  [-37.6803, 175.1024],
  [-37.6786, 175.1014],
  [-37.6773, 175.1005],
  [-37.6770, 175.0998],
  [-37.6771, 175.0988],
  [-37.6770, 175.0982],
  [-37.6767, 175.0976],
  [-37.6762, 175.0969],
  [-37.6761, 175.0963],
  [-37.6762, 175.0958],
  [-37.6761, 175.0951],
  [-37.6759, 175.0944],
  [-37.6758, 175.0930],
  [-37.6765, 175.0903],
  [-37.6763, 175.0891],
  [-37.6759, 175.0886],
  [-37.6753, 175.0882],
  [-37.6749, 175.0872],
  [-37.6749, 175.0861],
  [-37.6746, 175.0851],
  [-37.6737, 175.0838],
  [-37.6728, 175.0822],
  [-37.6725, 175.0807],
  [-37.6720, 175.0796],
  [-37.6714, 175.0791],
  [-37.6709, 175.0782],
  [-37.6709, 175.0768],
  [-37.6735, 175.0696],
  [-37.6736, 175.0687],
  [-37.6736, 175.0682],
  [-37.6740, 175.0678],
  [-37.6752, 175.0674],
  [-37.6775, 175.0671],
  [-37.6796, 175.0674],
  [-37.6807, 175.0672],
  [-37.6818, 175.0668],
  [-37.6827, 175.0667],
  [-37.6839, 175.0661],
  [-37.6852, 175.0643],
  [-37.6858, 175.0627],
  [-37.6859, 175.0616],
  [-37.6862, 175.0608],
  [-37.6868, 175.0601],
  [-37.6869, 175.0595],
  [-37.6868, 175.0584],
  [-37.6859, 175.0575]
];

export const glenMassey: Line = {
  name: 'Glen Massey',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-05-19',
          status: 'closed'
        }]
      }
    }
  ]
}
