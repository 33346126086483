import { Line } from "../../../trackTypes";

const lambtonQuayBasinReserve = [
  [-41.2778, 174.7804],
  [-41.2787, 174.7783],
  [-41.2795, 174.7770],
  [-41.2800, 174.7760],
  [-41.2805, 174.7758],
  [-41.2819, 174.7760],
  [-41.2830, 174.7755],
  [-41.2837, 174.7754],
  [-41.2844, 174.7775],
  [-41.2884, 174.7783],
  [-41.2896, 174.7786],
  [-41.2910, 174.7811],
  [-41.2922, 174.7847],
  [-41.2934, 174.7843],
  [-41.2940, 174.7838],
  [-41.2956, 174.7829],
  [-41.2995, 174.7808]
];

const karoriNorthlandTunnel = [
  [-41.2860, 174.7547],
  [-41.2851, 174.7550],
  [-41.2848, 174.7552]
];

const northlandTunnel = [
  [-41.2848, 174.7552],
  [-41.2842, 174.7560]
];

const northlandTunnelNorthland = [
  [-41.2842, 174.7560],
  [-41.2834, 174.7570],
  [-41.2833, 174.7573],
  [-41.2833, 174.7579],
  [-41.2831, 174.7583],
  [-41.2829, 174.7584],
  [-41.2826, 174.7583],
  [-41.2822, 174.7583]
];

const aroValley = [
  [-41.2837, 174.7754],
  [-41.2844, 174.7754],
  [-41.2851, 174.7756],
  [-41.2863, 174.7762],
  [-41.2891, 174.7746],
  [-41.2962, 174.7706],
  [-41.2954, 174.7679],
  [-41.2951, 174.7639],
  [-41.2950, 174.7637],
  [-41.2946, 174.7632],
  [-41.2936, 174.7611]
];

const orientalBay = [
  [-41.2934, 174.7843],
  [-41.2918, 174.7863],
  [-41.2917, 174.7868],
  [-41.2915, 174.7889],
  [-41.2907, 174.7909],
  [-41.2907, 174.7913],
  [-41.2911, 174.7923],
  [-41.2913, 174.7932],
  [-41.2913, 174.7943],
  [-41.2909, 174.7961],
  [-41.2906, 174.7966],
  [-41.2900, 174.7970],
  [-41.2893, 174.7974]
];

const constableStreet = [
  [-41.3137, 174.7798],
  [-41.3126, 174.7864],
  [-41.3126, 174.7883],
  [-41.3128, 174.7889],
  [-41.3131, 174.7893],
  [-41.3136, 174.7895],
  [-41.3139, 174.7899],
  [-41.3140, 174.7904],
  [-41.3143, 174.7908],
  [-41.3146, 174.7908],
  [-41.3152, 174.7904],
  [-41.3156, 174.7905],
  [-41.3158, 174.7907],
  [-41.3159, 174.7910],
  [-41.3158, 174.7917],
  [-41.3158, 174.7921],
  [-41.3163, 174.7925],
  [-41.3166, 174.7932],
  [-41.3169, 174.7933],
  [-41.3176, 174.7935],
  [-41.3179, 174.7949]
];

const lyallBay = [
  [-41.3179, 174.7949],
  [-41.3181, 174.7962],
  [-41.3206, 174.7954],
  [-41.3291, 174.7953],
  [-41.3299, 174.7935]
];

const islandBay = [
  [-41.3118, 174.7792],
  [-41.3151, 174.7781],
  [-41.3198, 174.7778],
  [-41.3198, 174.7753],
  [-41.3231, 174.7751],
  [-41.3251, 174.7749],
  [-41.3288, 174.7739],
  [-41.3355, 174.7732],
  [-41.3429, 174.7703]
];

const miramar = [
  [-41.3253, 174.8119],
  [-41.3199, 174.8138],
  [-41.3192, 174.8146],
  [-41.3167, 174.8164],
  [-41.3157, 174.8166],
  [-41.3114, 174.8199],
  [-41.3128, 174.8230],
  [-41.3126, 174.8234],
  [-41.3069, 174.8258]
];

const seatoun = [
  [-41.3179, 174.7949],
  [-41.3198, 174.7943],
  [-41.3213, 174.8015],
  [-41.3230, 174.8031],
  [-41.3242, 174.8054],
  [-41.3247, 174.8098],
  [-41.3253, 174.8119],
  [-41.3251, 174.8184],
  [-41.3247, 174.8197],
  [-41.3239, 174.8232],
  [-41.3239, 174.8237],
  [-41.3242, 174.8248],
  [-41.3245, 174.8267],
  [-41.3244, 174.8271],
  [-41.3231, 174.8286],
  [-41.3223, 174.8289],
  [-41.3228, 174.8319]
];

const brooklyn = [
  [-41.2962, 174.7706],
  [-41.2971, 174.7701],
  [-41.2972, 174.7699],
  [-41.2973, 174.7695],
  [-41.2978, 174.7689],
  [-41.2981, 174.7687],
  [-41.2998, 174.7684],
  [-41.3003, 174.7687],
  [-41.3006, 174.7691],
  [-41.3009, 174.7693],
  [-41.3022, 174.7696],
  [-41.3032, 174.7692],
  [-41.3034, 174.7688],
  [-41.3033, 174.7685],
  [-41.3031, 174.7681],
  [-41.3027, 174.7679],
  [-41.3025, 174.7676],
  [-41.3024, 174.7672],
  [-41.3024, 174.7668],
  [-41.3027, 174.7660],
  [-41.3027, 174.7644],
  [-41.3028, 174.7640],
  [-41.3038, 174.7636],
  [-41.3051, 174.7632]
];

const wallaceStreet = [
  [-41.2884, 174.7783],
  [-41.2977, 174.7732],
  [-41.2983, 174.7754],
  [-41.3014, 174.7739],
  [-41.3031, 174.7747],
  [-41.3045, 174.7750],
  [-41.3045, 174.7750],
  [-41.3072, 174.7751],
  [-41.3073, 174.7754],
  [-41.3070, 174.7768],
  [-41.3072, 174.7778]
];

const newtown = [
  [-41.2995, 174.7808],
  [-41.2999, 174.7816],
  [-41.3002, 174.7817],
  [-41.3012, 174.7811],
  [-41.3014, 174.7806],
  [-41.3011, 174.7797],
  [-41.3072, 174.7778],
  [-41.3118, 174.7792],
  [-41.3137, 174.7798],
  [-41.3197, 174.7816],
  [-41.3193, 174.7840]
];

const hataitaiStartHataitaiTunnel = [
  [-41.2956, 174.7829],
  [-41.2964, 174.7854],
  [-41.2979, 174.7846],
  [-41.2991, 174.7884],
  [-41.2992, 174.7886]
];

const hataitaiTunnel = [
  [-41.2992, 174.7886],
  [-41.3019, 174.7917]
];

const hataitaiTunnelHataitaiEnd = [
  [-41.3019, 174.7917],
  [-41.3028, 174.7928],
  [-41.3037, 174.7934],
  [-41.3040, 174.7942],
  [-41.3044, 174.7939],
  [-41.3098, 174.7921],
  [-41.3109, 174.7935],
  [-41.3116, 174.7936],
  [-41.3139, 174.7927],
  [-41.3148, 174.7929],
  [-41.3159, 174.7937],
  [-41.3169, 174.7953],
  [-41.3179, 174.7949]
];

const bowenStreet = [
  [-41.2788, 174.7692],
  [-41.2792, 174.7709],
  [-41.2792, 174.7713],
  [-41.2783, 174.7721],
  [-41.2779, 174.7727],
  [-41.2778, 174.7732],
  [-41.2780, 174.7738],
  [-41.2786, 174.7747],
  [-41.2789, 174.7768],
  [-41.2795, 174.7770]
];

const thorndon = [
  [-41.2778, 174.7804],
  [-41.2766, 174.7810],
  [-41.2761, 174.7817],
  [-41.2754, 174.7832],
  [-41.2748, 174.7835],
  [-41.2744, 174.7835],
  [-41.2698, 174.7810],
  [-41.2689, 174.7809],
];

const lambtonTinakoriRoad = [
  [-41.2717, 174.7775],
  [-41.2718, 174.7778],
  [-41.2726, 174.7779],
  [-41.2777, 174.7777],
  [-41.2787, 174.7783]
];

const wadestown = [
  [-41.2717, 174.7775],
  [-41.2709, 174.7764],
  [-41.2687, 174.7788],
  [-41.2679, 174.7792],
  [-41.2674, 174.7790],
  [-41.2673, 174.7791],
  [-41.2672, 174.7796],
  [-41.2669, 174.7800],
  [-41.2668, 174.7803],
  [-41.2668, 174.7804],
  [-41.2666, 174.7805],
  [-41.2662, 174.7800],
  [-41.2657, 174.7798],
  [-41.2656, 174.7799],
  [-41.2655, 174.7801],
  [-41.2655, 174.7807],
  [-41.2654, 174.7811],
  [-41.2650, 174.7813],
  [-41.2647, 174.7812],
  [-41.2645, 174.7808],
  [-41.2640, 174.7782],
  [-41.2637, 174.7779],
  [-41.2628, 174.7775],
  [-41.2626, 174.7772],
  [-41.2625, 174.7768],
  [-41.2623, 174.7765],
  [-41.2617, 174.7759],
  [-41.2616, 174.7754],
  [-41.2619, 174.7744]
];

const karoriCemetaryExtension = [
  [-41.2804, 174.7673],
  [-41.2822, 174.7635],
  [-41.2830, 174.7629],
  [-41.2852, 174.7596],
  [-41.2858, 174.7584],
  [-41.2862, 174.7577],
  [-41.2874, 174.7564],
  [-41.2875, 174.7561],
  [-41.2872, 174.7549],
  [-41.2870, 174.7547],
  [-41.2860, 174.7547],
  [-41.2852, 174.7546],
  [-41.2847, 174.7541],
  [-41.2844, 174.7529],
  [-41.2838, 174.7522],
  [-41.2827, 174.7521],
  [-41.2825, 174.7523],
  [-41.2817, 174.7525],
  [-41.2815, 174.7523],
  [-41.2814, 174.7518],
  [-41.2809, 174.7511],
  [-41.2810, 174.7508]
];

const karoriParkExtension = [
  [-41.2810, 174.7508],
  [-41.2814, 174.7501],
  [-41.2817, 174.7493],
  [-41.2817, 174.7479],
  [-41.2863, 174.7317],
  [-41.2869, 174.7275],
  [-41.2868, 174.7258],
  [-41.2876, 174.7242],
  [-41.2885, 174.7236]
];

const tinakoriRoad = [
  [-41.2717, 174.7775],
  [-41.2788, 174.7692],
  [-41.2804, 174.7673]
];

const courtenayPlace = [
  [-41.2891, 174.7746],
  [-41.2904, 174.7757],
  [-41.2930, 174.7800],
  [-41.2936, 174.7817],
  [-41.2940, 174.7838]
];

const vivianStreet = [
  [-41.2947, 174.7748],
  [-41.2970, 174.7821]
];

export const wellingtonTrams: Line = {
  name: 'Wellington Trams',
  state: 'NZ',
  segments: [
    {
      segments: [lambtonQuayBasinReserve],
      history: {
        opened: {
          date: '1878-08-24',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1904-06-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        karoriNorthlandTunnel,
        {
          name: 'Northland Tunnel',
          segment: northlandTunnel,
          type: 'tunnel'
        },
        northlandTunnelNorthland
      ],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [aroValley],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [orientalBay],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [constableStreet],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lyallBay],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [islandBay],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1963-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [miramar],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [seatoun],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brooklyn],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wallaceStreet],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1964-05-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newtown],
      history: {
        opened: {
          date: '1904-06-30',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1964-05-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        hataitaiStartHataitaiTunnel,
        {
          name: 'Hataitai Tunnel',
          segment: hataitaiTunnel,
          type: 'tunnel'
        },
        hataitaiTunnelHataitaiEnd
      ],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bowenStreet],
      history: {
        opened: {
          date: '1940-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [thorndon],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lambtonTinakoriRoad],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wadestown],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [karoriCemetaryExtension],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [karoriParkExtension],
      history: {
        opened: {
          date: '1911-02-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tinakoriRoad],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [courtenayPlace],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [vivianStreet],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
