import { Line } from "../../trackTypes";

const holdfastBayDuplicated = [
  [-34.9235, 138.5800],
  [-34.9252, 138.5797],
  [-34.9262, 138.5794],
  [-34.9277, 138.5785],
  [-34.9286, 138.5771],
  [-34.9295, 138.5761],
  [-34.9336, 138.5735],
  [-34.9354, 138.5720],
  [-34.9414, 138.5681],
  [-34.9489, 138.5630],
  [-34.9600, 138.5544],
  [-34.9628, 138.5521],
  [-34.9682, 138.5468],
  [-34.9717, 138.5412],
  [-34.9727, 138.5387],
  [-34.9733, 138.5357],
  [-34.9736, 138.5307],
  [-34.9739, 138.5293],
  [-34.9746, 138.5275],
  [-34.9747, 138.5246],
  [-34.9751, 138.5236],
  [-34.9754, 138.5228],
  [-34.9755, 138.5215],
  [-34.9758, 138.5207],
  [-34.9762, 138.5194],
  [-34.9763, 138.5185]
];

const holdfastBaySingle = [
  [-34.9763, 138.5185],
  [-34.9765, 138.5139],
  [-34.9763, 138.5134],
  [-34.9762, 138.5130],
  [-34.9764, 138.5125],
  [-34.9768, 138.5121],
  [-34.9794, 138.5123]
];

const morphettvilleRacecourse = [
  [-34.9700, 138.5440],
  [-34.9731, 138.5424],
  [-34.9735, 138.5420],
  [-34.9748, 138.5396]
];

export const holdfastBay: Line = {
  name: 'Holdfast Bay',
  state: 'SA',
  segments: [
    {
      segments: [holdfastBayDuplicated],
      history: {
        opened: {
          date: '1880-05-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1914-01-01',
          tracks: 2
        }, {
          date: '1929-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [holdfastBaySingle],
      history: {
        opened: {
          date: '1880-05-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morphettvilleRacecourse],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-12-01',
          status: 'closed'
        }]
      }
    }
  ]
};
