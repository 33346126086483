import { Line } from "../../../trackTypes";

const line = [
  [-27.7705, 153.1068],
  [-27.7715, 153.1069],
  [-27.7721, 153.1071],
  [-27.7751, 153.1090],
  [-27.7786, 153.1106],
  [-27.7803, 153.1108],
  [-27.7820, 153.1105],
  [-27.7867, 153.1084],
  [-27.7888, 153.1083],
  [-27.7904, 153.1087],
  [-27.7925, 153.1100],
  [-27.7936, 153.1102],
  [-27.7960, 153.1103],
  [-27.7967, 153.1107],
  [-27.8013, 153.1169],
  [-27.8025, 153.1179],
  [-27.8054, 153.1183],
  [-27.8074, 153.1182],
  [-27.8088, 153.1186],
  [-27.8159, 153.1229],
  [-27.8322, 153.1302],
  [-27.8380, 153.1312],
  [-27.8394, 153.1321],
  [-27.8405, 153.1348],
  [-27.8420, 153.1357],
  [-27.8456, 153.1367],
  [-27.8473, 153.1377],
  [-27.8490, 153.1391],
  [-27.8500, 153.1395],
  [-27.8524, 153.1394],
  [-27.8579, 153.1376],
  [-27.8595, 153.1379],
  [-27.8614, 153.1390],
  [-27.8640, 153.1395],
  [-27.8660, 153.1389],
  [-27.8678, 153.1373],
  [-27.8701, 153.1349],
  [-27.8720, 153.1340],
  [-27.8736, 153.1336],
  [-27.8750, 153.1330],
  [-27.8778, 153.1306],
  [-27.8789, 153.1301],
  [-27.8833, 153.1302],
  [-27.8868, 153.1316],
  [-27.8916, 153.1335],
  [-27.8930, 153.1340],
  [-27.8939, 153.1336],
  [-27.8946, 153.1330],
  [-27.8951, 153.1321],
  [-27.8955, 153.1305],
  [-27.8961, 153.1291],
  [-27.8983, 153.1265],
  [-27.8993, 153.1258],
  [-27.9056, 153.1220],
  [-27.9062, 153.1209],
  [-27.9063, 153.1195],
  [-27.9064, 153.1177],
  [-27.9077, 153.1154],
  [-27.9092, 153.1143],
  [-27.9119, 153.1145],
  [-27.9128, 153.1149],
  [-27.9145, 153.1162],
  [-27.9166, 153.1169],
  [-27.9173, 153.1170],
  [-27.9185, 153.1167],
  [-27.9195, 153.1169],
  [-27.9224, 153.1196],
  [-27.9238, 153.1217],
  [-27.9251, 153.1226],
  [-27.9263, 153.1232],
  [-27.9330, 153.1241],
  [-27.9341, 153.1247],
  [-27.9350, 153.1257],
  [-27.9357, 153.1262],
  [-27.9384, 153.1276],
  [-27.9426, 153.1318],
  [-27.9439, 153.1352],
  [-27.9439, 153.1415],
  [-27.9443, 153.1427],
  [-27.9472, 153.1470],
  [-27.9493, 153.1493],
  [-27.9733, 153.1644],
  [-27.9755, 153.1646],
  [-27.9764, 153.1641],
  [-27.9788, 153.1617],
  [-27.9805, 153.1610],
  [-27.9928, 153.1623],
  [-27.9937, 153.1623],
  [-27.9972, 153.1599],
  [-27.9978, 153.1599],
  [-27.9998, 153.1610],
  [-28.0008, 153.1611],
  [-28.0015, 153.1608],
  [-28.0019, 153.1603],
  [-28.0023, 153.1594],
  [-28.0027, 153.1590],
  [-28.0034, 153.1590],
  [-28.0038, 153.1595],
  [-28.0045, 153.1612],
  [-28.0050, 153.1617],
  [-28.0058, 153.1616],
  [-28.0078, 153.1597],
  [-28.0083, 153.1596],
  [-28.0087, 153.1597],
  [-28.0101, 153.1610],
  [-28.0129, 153.1655],
  [-28.0135, 153.1658],
  [-28.0142, 153.1656],
  [-28.0148, 153.1649],
  [-28.0155, 153.1630],
  [-28.0163, 153.1621],
  [-28.0173, 153.1620],
  [-28.0179, 153.1625]
];

export const canungra: Line = {
  name: 'Canungra',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1915-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-06-01',
          status: 'closed'
        }]
      }
    }
  ]
}
