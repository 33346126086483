import { Line } from "../../trackTypes";

const line = [
  [-34.5942, 142.2231],
  [-34.5932, 142.2220],
  [-34.5917, 142.2196],
  [-34.5908, 142.2171],
  [-34.5904, 142.2146],
  [-34.5907, 142.2111],
  [-34.5944, 142.1923],
  [-34.5971, 142.1829],
  [-34.5973, 142.1791],
  [-34.5959, 142.1670],
  [-34.5933, 142.1552],
  [-34.5923, 142.1428],
  [-34.5932, 142.1285],
  [-34.5965, 142.1143],
  [-34.5984, 142.0983],
  [-34.6006, 142.0873],
  [-34.6038, 142.0775],
  [-34.6071, 142.0634],
  [-34.6116, 142.0536],
  [-34.6163, 142.0291],
  [-34.6292, 141.9969],
  [-34.6299, 141.9942],
  [-34.6302, 141.9897],
  [-34.6321, 141.9850],
  [-34.6369, 141.9788],
  [-34.6406, 141.9705],
  [-34.6412, 141.9683]
];

export const millewaSouth: Line = {
  name: 'Millewa South',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1930-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
