import { Line } from "../../trackTypes";

const merriNorthcote = [
  [-37.7778, 144.9929],
  [-37.7725, 144.9940],
  [-37.7717, 144.9942],
  [-37.7710, 144.9946],
  [-37.7698, 144.9952]
];

const northcoteReservoir = [
  [-37.7698, 144.9952],
  [-37.7690, 144.9957],
  [-37.7675, 144.9962],
  [-37.7670, 144.9964],
  [-37.7649, 144.9969],
  [-37.7447, 145.0003],
  [-37.7442, 145.0003],
  [-37.7435, 145.0002],
  [-37.7425, 144.9999],
  [-37.7419, 144.9999],
  [-37.7413, 144.9999],
  [-37.7229, 145.0039],
  [-37.7216, 145.0043],
  [-37.7209, 145.0046],
  [-37.7202, 145.0049],
  [-37.7168, 145.0070]
];

const reservoirKeonPark = [
  [-37.7168, 145.0070],
  [-37.7147, 145.0081],
  [-37.6990, 145.0109],
  [-37.6976, 145.0113],
  [-37.6946, 145.0118]
];

const keonParkThomastown = [
  [-37.6946, 145.0118],
  [-37.6840, 145.0134],
  [-37.6824, 145.0139],
  [-37.6804, 145.0142]
];

const thomastownLalor = [
  [-37.6804, 145.0142],
  [-37.6698, 145.0160],
  [-37.6672, 145.0167],
  [-37.6659, 145.0172]
];

const lalorEpping = [
  [-37.6659, 145.0172],
  [-37.6633, 145.0179],
  [-37.6619, 145.0187],
  [-37.6609, 145.0193],
  [-37.6601, 145.0199],
  [-37.6580, 145.0220],
  [-37.6541, 145.0262],
  [-37.6524, 145.0298],
  [-37.6522, 145.0304],
  [-37.6521, 145.0309],
];

const eppingSouthMorang = [
  [-37.6521, 145.0309],
  [-37.6520, 145.0337],
  [-37.6520, 145.0362],
  [-37.6515, 145.0474],
  [-37.6489, 145.0679]
];

const southMorangMernda = [
  [-37.6489, 145.0679],
  [-37.6465, 145.0880],
  [-37.6460, 145.0895],
  [-37.6455, 145.0905],
  [-37.6428, 145.0934],
  [-37.6420, 145.0939],
  [-37.6414, 145.0942],
  [-37.6302, 145.0968],
  [-37.6170, 145.0977],
  [-37.6026, 145.1007]
];

const northcoteLoopNorthMerri = [
  [-37.7807, 144.9910],
  [-37.7797, 144.9922],
  [-37.7793, 144.9925],
  [-37.7788, 144.9927],
  [-37.7778, 144.9929]
]

const rushallNorthcoteLoopSouth = [
  [-37.7828, 144.9918],
  [-37.7826, 144.9915],
  [-37.7820, 144.9909],
];

const northcoteLoop = [
  [-37.7828, 144.9918],
  [-37.7826, 144.9915],
  [-37.7820, 144.9909],
  [-37.7816, 144.9907],
  [-37.7813, 144.9907],
  [-37.7809, 144.9908],
  [-37.7806, 144.9911],
  [-37.7797, 144.9922],
  [-37.7793, 144.9925],
  [-37.7788, 144.9927],
  [-37.7778, 144.9929]
];

const cliftonHillRushall = [
  [-37.7867, 144.9957],
  [-37.7861, 144.9956],
  [-37.7855, 144.9954],
  [-37.7849, 144.9949],
  [-37.7846, 144.9945],
  [-37.7828, 144.9918]
];

const merndaWhittlesea = [
  [-37.6026, 145.1007],
  [-37.5684, 145.1073],
  [-37.5679, 145.1075],
  [-37.5674, 145.1078],
  [-37.5656, 145.1090],
  [-37.5650, 145.1093],
  [-37.5645, 145.1094],
  [-37.5639, 145.1095],
  [-37.5528, 145.1104],
  [-37.5516, 145.1105],
  [-37.5172, 145.1164]
];

export const whittlesea: Line =  {
  name: 'Whittlesea',
  state: 'VIC',
  segments: [
    {
      segments: [merriNorthcote],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1889-10-08',
          tracks: 2
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [eppingSouthMorang],
      history: {
        opened: {
          date: '1889-12-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-11-29',
          status: 'closed'
        }, {
          date: '2011-11-27',
          status: 'open'
        }, {
          date: '2011-11-27',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [southMorangMernda],
      history: {
        opened: {
          date: '1889-12-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-11-29',
          status: 'closed'
        }, {
          date: '2018-07-02',
          status: 'open'
        }, {
          date: '2018-07-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          tracks: 2
        }]
      }
    },
    {
      segments: [lalorEpping],
      history: {
        opened: {
          date: '1889-12-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-11-29',
          status: 'closed'
        }, {
          date: '1964-11-29',
          status: 'open'
        }, {
          date: '1964-11-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
        }, {
          date: '2011-11-27',
          tracks: 2
        }]
      }
    },
    {
      segments: [thomastownLalor],
      history: {
        opened: {
          date: '1889-12-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-11-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2011-11-27',
          tracks: 2
        }]
      }
    },
    {
      segments: [keonParkThomastown],
      history: {
        opened: {
          date: '1889-12-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-11-27',
          tracks: 2
        }, {
          date: '1929-12-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [reservoirKeonPark],
      history: {
        opened: {
          date: '1889-12-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-11-29',
          tracks: 2
        }, {
          date: '1929-12-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [northcoteReservoir],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-12-01',
          tracks: 2
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [northcoteLoopNorthMerri],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [rushallNorthcoteLoopSouth],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1904-12-05',
          tracks: 2
        },{
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [northcoteLoop],
      history: {
        opened: {
          date: '1904-12-05',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [cliftonHillRushall],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1904-12-05',
          tracks: 2
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [merndaWhittlesea],
      history: {
        opened: {
          date: '1889-12-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-11-29',
          status: 'closed'
        }]
      }
    }
  ]
};
