import { Line } from "../../trackTypes";

const kalgoorlieLeonoraJunction = [
  [-30.7456, 121.4670],
  [-30.7412, 121.4719]
];

const kalgoorlieMenzies = [
  [-30.7412, 121.4719],
  [-30.7401, 121.4723],
  [-30.7392, 121.4722],
  [-30.7385, 121.4718],
  [-30.7379, 121.4712],
  [-30.7374, 121.4704],
  [-30.7366, 121.4696],
  [-30.7358, 121.4692],
  [-30.7337, 121.4687],
  [-30.7247, 121.4650],
  [-30.6433, 121.4393],
  [-30.6212, 121.4323],
  [-30.6194, 121.4320],
  [-30.5944, 121.4320],
  [-30.5925, 121.4317],
  [-30.5734, 121.4254],
  [-30.5606, 121.4211],
  [-30.5593, 121.4201],
  [-30.5500, 121.4089],
  [-30.5471, 121.4029],
  [-30.5461, 121.4016],
  [-30.5437, 121.3998],
  [-30.5427, 121.3985],
  [-30.5423, 121.3973],
  [-30.5416, 121.3928],
  [-30.5410, 121.3916],
  [-30.5399, 121.3908],
  [-30.5388, 121.3906],
  [-30.5348, 121.3914],
  [-30.5334, 121.3913],
  [-30.5323, 121.3908],
  [-30.5059, 121.3691],
  [-30.4881, 121.3524],
  [-30.4756, 121.3387],
  [-30.4740, 121.3375],
  [-30.4632, 121.3331],
  [-30.4609, 121.3328],
  [-30.4543, 121.3330],
  [-30.4491, 121.3324],
  [-30.4421, 121.3303],
  [-30.4377, 121.3292],
  [-30.4198, 121.3237],
  [-30.4178, 121.3235],
  [-30.4062, 121.3232],
  [-30.4042, 121.3228],
  [-30.3650, 121.3124],
  [-30.3558, 121.3073],
  [-30.3537, 121.3054],
  [-30.3517, 121.3020],
  [-30.3501, 121.3003],
  [-30.3483, 121.2992],
  [-30.3434, 121.2947],
  [-30.3405, 121.2937],
  [-30.3335, 121.2933],
  [-30.3314, 121.2924],
  [-30.3265, 121.2879],
  [-30.3221, 121.2852],
  [-30.2738, 121.2423],
  [-30.2509, 121.2267],
  [-30.2019, 121.1768],
  [-30.1988, 121.1754],
  [-30.1975, 121.1744],
  [-30.1871, 121.1638],
  [-30.1763, 121.1473],
  [-30.1749, 121.1463],
  [-30.1734, 121.1462],
  [-30.1493, 121.1486],
  [-30.0772, 121.1556],
  [-30.0567, 121.1613],
  [-30.0549, 121.1615],
  [-30.0360, 121.1590],
  [-30.0204, 121.1613],
  [-30.0185, 121.1611],
  [-30.0054, 121.1568],
  [-29.9967, 121.1525],
  [-29.9916, 121.1475],
  [-29.9833, 121.1370],
  [-29.9815, 121.1357],
  [-29.9580, 121.1279],
  [-29.9399, 121.1220],
  [-29.9346, 121.1214],
  [-29.9323, 121.1206],
  [-29.9265, 121.1179],
  [-29.9144, 121.1155],
  [-29.9128, 121.1148],
  [-29.8953, 121.1022],
  [-29.8932, 121.1015],
  [-29.8776, 121.1018],
  [-29.8758, 121.1015],
  [-29.8430, 121.0890],
  [-29.8374, 121.0879],
  [-29.7547, 121.0562],
  [-29.7499, 121.0549],
  [-29.7357, 121.0489],
  [-29.7321, 121.0479],
  [-29.7254, 121.0452],
  [-29.7187, 121.0434],
  [-29.7101, 121.0390],
  [-29.7012, 121.0317],
  [-29.6996, 121.0311],
  [-29.6958, 121.0311]
];

const leonoraGwalia = [
  [-28.8671, 121.3210],
  [-28.8763, 121.3255],
  [-28.8781, 121.3258],
  [-28.8918, 121.3257],
  [-28.8938, 121.3261],
  [-28.9034, 121.3280],
  [-28.9055, 121.3282],
  [-28.9124, 121.3278]
];

const gwaliaMenzies = [
  [-28.9124, 121.3278],
  [-28.9168, 121.3275],
  [-28.9184, 121.3271],
  [-28.9200, 121.3263],
  [-28.9216, 121.3260],
  [-28.9232, 121.3266],
  [-28.9246, 121.3284],
  [-28.9249, 121.3305],
  [-28.9243, 121.3323],
  [-28.9222, 121.3364],
  [-28.9216, 121.3384],
  [-28.9216, 121.3399],
  [-28.9219, 121.3426],
  [-28.9211, 121.4147],
  [-28.9294, 121.4881],
  [-28.9289, 121.5137],
  [-28.9294, 121.5152],
  [-28.9302, 121.5161],
  [-28.9314, 121.5165],
  [-28.9575, 121.5172],
  [-28.9601, 121.5166],
  [-28.9743, 121.5095],
  [-28.9938, 121.5039],
  [-29.0575, 121.4849],
  [-29.1073, 121.4787],
  [-29.1254, 121.4744],
  [-29.1626, 121.4735],
  [-29.1866, 121.4691],
  [-29.1883, 121.4692],
  [-29.1897, 121.4702],
  [-29.1912, 121.4717],
  [-29.1944, 121.4728],
  [-29.2606, 121.4713],
  [-29.2635, 121.4723],
  [-29.2645, 121.4728],
  [-29.2652, 121.4736],
  [-29.2664, 121.4742],
  [-29.2690, 121.4745],
  [-29.2712, 121.4749],
  [-29.3186, 121.4868],
  [-29.3208, 121.4878],
  [-29.3279, 121.4932],
  [-29.3295, 121.4938],
  [-29.3323, 121.4937],
  [-29.3338, 121.4930],
  [-29.3371, 121.4879],
  [-29.3388, 121.4868],
  [-29.3434, 121.4864],
  [-29.3454, 121.4858],
  [-29.3488, 121.4829],
  [-29.3502, 121.4813],
  [-29.3691, 121.4491],
  [-29.3704, 121.4450],
  [-29.3704, 121.4342],
  [-29.3710, 121.4313],
  [-29.3774, 121.4188],
  [-29.3790, 121.4168],
  [-29.3825, 121.4139],
  [-29.3839, 121.4121],
  [-29.3906, 121.3987],
  [-29.4098, 121.3760],
  [-29.4112, 121.3729],
  [-29.4115, 121.3703],
  [-29.4128, 121.3672],
  [-29.4320, 121.3441],
  [-29.4448, 121.3328],
  [-29.4866, 121.2831],
  [-29.5828, 121.1690],
  [-29.6320, 121.1103],
  [-29.6376, 121.0976],
  [-29.6670, 121.0626],
  [-29.6824, 121.0359],
  [-29.6844, 121.0338],
  [-29.6908, 121.0313],
  [-29.6921, 121.0310],
  [-29.6958, 121.0311]
];

export const leonora: Line = {
  name: 'Leonora',
  state: 'WA',
  segments: [
    {
      segments: [kalgoorlieLeonoraJunction],
      history: {
        opened: {
          date: '1897-11-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1900-01-01',
          tracks: 2
        }, {
          date: '1917-09-16',
          gauge: 'dual'
        }, {
          date: '1974-09-16',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [leonoraGwalia],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-09-16',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [gwaliaMenzies],
      history: {
        opened: {
          date: '1902-06-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-09-16',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [kalgoorlieMenzies],
      history: {
        opened: {
          date: '1902-06-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-09-16',
          gauge: 'standard'
        }]
      }
    }
  ]
};
