import { Line } from '../trackTypes';
import { flinders } from './southAustralia/flinders';
import { seaford } from './southAustralia/seaford';
import { grange } from './southAustralia/grange';
import { mtGambier } from './southAustralia/southEastern/mtGambier';
import { belair } from './southAustralia/belair';
import { wolsely } from './southAustralia/wolsely';
import { gawler } from './southAustralia/gawler';
import { portPirie } from './southAustralia/portPirie';
import { outerHarbor } from './southAustralia/outerHarbor';
import { pinnaroo } from './southAustralia/pinnaroo';
import { whyalla } from './southAustralia/eyrePeninsula/whyalla';
import { rivoliBay } from './southAustralia/southEastern/rivoliBay';
import { kingston } from './southAustralia/southEastern/kingston';
import { glencoe } from './southAustralia/southEastern/glencoe';
import { barossaValley } from './southAustralia/barossaValley';
import { penriceQuary } from './southAustralia/penriceQuarry';
import { transAustralian } from './southAustralia/transAustralian';
import { pichiRichi } from './southAustralia/pichiRichi';
import { willunga } from './southAustralia/willunga';
import { morgan } from './southAustralia/morgan';
import { robertstown } from './southAustralia/robertstown';
import { peterborough } from './southAustralia/peterborough';
import { spalding } from './southAustralia/spalding';
import { barmera } from './southAustralia/barmera';
import { peebinga } from './southAustralia/peebinga';
import { waikerie } from './southAustralia/waikerie';
import { peterboroughQuorn } from './southAustralia/peterboroughQuorn';
import { yinkanie } from './southAustralia/yinkanie';
import { loxton } from './southAustralia/loxton';
import { victorHarbor } from './southAustralia/victorHarbor';
import { millang } from './southAustralia/milang';
import { moonta } from './southAustralia/moonta';
import { sedan } from './southAustralia/sedan';
import { mtPleasant } from './southAustralia/mtPleasant';
import { crystalBrookBrokenHill } from './southAustralia/crystalBrookBrokenHill';
import { jubilee } from './southAustralia/jubilee';
import { finsbury } from './southAustralia/finsbury';
import { northfield } from './southAustralia/northfield';
import { penfield } from './southAustralia/penfield';
import { hamleyBridge } from './southAustralia/hamleyBridge';
import { wilmington } from './southAustralia/wilmington';
import { kadinaBrinkworth } from './southAustralia/kadinaBrinkworth';
import { glenelg } from './southAustralia/glenelg';
import { dryCreek } from './southAustralia/dryCreek';
import { marree } from './southAustralia/marree';
import { coffinBay } from './southAustralia/eyrePeninsula/coffinBay';
import { eyrePeninsula } from './southAustralia/eyrePeninsula/eyrePeninsula';
import { adelaideDarwin } from './southAustralia/adelaideDarwin';
import { mountHope } from './southAustralia/eyrePeninsula/mountHope';
import { buckleboo } from './southAustralia/eyrePeninsula/buckleboo';
import { semaphore } from './southAustralia/semaphore';
import { holdfastBay } from './southAustralia/holdfastBay';
import { hendon } from './southAustralia/hendon';
import { radiumHill } from './southAustralia/radiumHill';
import { gawlerTram } from './southAustralia/trams/gawlerTram';
import { clapham } from './southAustralia/clapham';
import { gmhElizabeth } from './southAustralia/gmhElizabeth';
import { moontaTrams } from './southAustralia/trams/moontaTrams';
import { marionBay } from './southAustralia/marionBay';
import { kangarooIsland } from './southAustralia/kangarooIsland';
import { adelaideTrams } from './southAustralia/trams/adelaideTrams';
import { largsBay } from './southAustralia/largsBay';

export const saLines: Line[] = [
  flinders,
  seaford,
  grange,
  mtGambier,
  belair,
  wolsely,
  gawler,
  portPirie,
  outerHarbor,
  pinnaroo,
  whyalla,
  rivoliBay,
  kingston,
  glencoe,
  barossaValley,
  penriceQuary,
  transAustralian,
  pichiRichi,
  willunga,
  morgan,
  robertstown,
  peterborough,
  spalding,
  barmera,
  peebinga,
  waikerie,
  peterboroughQuorn,
  yinkanie,
  loxton,
  victorHarbor,
  millang,
  moonta,
  sedan,
  mtPleasant,
  crystalBrookBrokenHill,
  jubilee,
  finsbury,
  northfield,
  penfield,
  hamleyBridge,
  wilmington,
  kadinaBrinkworth,
  glenelg,
  dryCreek,
  marree,
  coffinBay,
  eyrePeninsula,
  adelaideDarwin,
  mountHope,
  buckleboo,
  semaphore,
  holdfastBay,
  hendon,
  radiumHill,
  gawlerTram,
  clapham,
  gmhElizabeth,
  moontaTrams,
  marionBay,
  kangarooIsland,
  largsBay,
  ...adelaideTrams
];
