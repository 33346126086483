import { Line } from "../../trackTypes";

const line = [
  [-23.3721, 150.5041],
  [-23.3688, 150.5017],
  [-23.3667, 150.5009],
  [-23.3655, 150.4997],
  [-23.3650, 150.4982],
  [-23.3638, 150.4903],
  [-23.3640, 150.4895],
  [-23.3658, 150.4871],
  [-23.3665, 150.4852],
  [-23.3665, 150.4839],
  [-23.3659, 150.4824],
  [-23.3625, 150.4757],
  [-23.3452, 150.4548],
  [-23.3440, 150.4526],
  [-23.3405, 150.4448],
  [-23.3279, 150.4281],
  [-23.3270, 150.4260],
  [-23.3209, 150.3824],
  [-23.3073, 150.3551],
  [-23.3012, 150.3427],
  [-23.3011, 150.3370],
  [-23.3006, 150.3350],
  [-23.2986, 150.3316],
  [-23.2951, 150.3284],
  [-23.2935, 150.3264],
  [-23.2895, 150.3233],
  [-23.2620, 150.2949]
];

export const ridgelands: Line = {
  name: 'Ridgelands',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
