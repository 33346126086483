import { Line } from "../../../../trackTypes";

const glenelgSomerton = [
  [-34.98026, 138.51775],
  [-34.98807, 138.51832],
  [-34.99080, 138.51853],
  [-34.99100, 138.51859],
  [-34.99127, 138.51876],
  [-34.99145, 138.51883],
  [-34.99663, 138.51918],
  [-34.99671, 138.51915],
  [-34.99676, 138.51908],
  [-34.99679, 138.51898],
  [-34.99705, 138.51383],
  [-34.99701, 138.51376],
  [-34.99691, 138.51369],
  [-34.99166, 138.51331],
  [-34.99157, 138.51333],
  [-34.99149, 138.51342],
  [-34.99124, 138.51385],
  [-34.99118, 138.51393],
  [-34.99107, 138.51396],
  [-34.98843, 138.51378],
  [-34.98831, 138.51373],
  [-34.98814, 138.51367],
  [-34.98050, 138.51312]
];

const broadway = [
  [-34.98807, 138.51832],
  [-34.98831, 138.51373]
];

const glenelgSomertonPark = [
  [-34.98017, 138.52019],
  [-34.99689, 138.52143]
];

const whyteStreet = [
  [-34.99663, 138.51918],
  [-34.99668, 138.51921],
  [-34.99672, 138.51925],
  [-34.99677, 138.51937],
  [-34.99668, 138.52110],
  [-34.99672, 138.52124],
  [-34.99680, 138.52137],
  [-34.99689, 138.52143]
];

const somertonParkSouthBrighton = [
  [-34.99689, 138.52143],
  [-35.00508, 138.52205],
  [-35.02602, 138.52364]
];

export const glenelgTrams: Line = {
  name: 'Glenelg Tram Network',
  state: 'SA',
  segments: [
    {
      segments: [glenelgSomerton, broadway],
      history: {
        opened: {
          date: '1883-09-17',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1885-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenelgSomertonPark],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1914-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [whyteStreet],
      history: {
        opened: {
          date: '1884-09-27',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1885-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [somertonParkSouthBrighton],
      history: {
        opened: {
          date: '1884-09-27',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1914-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
