import { Line } from "../../trackTypes";

const semaphoreJunctionSemaphore = [
  [-34.8452, 138.4939],
  [-34.8418, 138.4931],
  [-34.8409, 138.4927],
  [-34.8404, 138.4921],
  [-34.8382, 138.4804]
];

export const semaphore: Line = {
  name: 'Semaphore',
  state: 'SA',
  segments: [
    {
      segments: [semaphoreJunctionSemaphore],
      history: {
        opened: {
          date: '1878-01-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-10-29',
          status: 'closed'
        }]
      }
    }
  ]
};
