import { Line } from "../../trackTypes";

const wokarinaNaraling = [
  [-28.6554, 114.6296],
  [-28.6551, 114.6300],
  [-28.6548, 114.6306],
  [-28.6545, 114.6317],
  [-28.6548, 114.6330],
  [-28.6560, 114.6347],
  [-28.6563, 114.6361],
  [-28.6561, 114.6377],
  [-28.6549, 114.6402],
  [-28.6533, 114.6419],
  [-28.6526, 114.6431],
  [-28.6523, 114.6444],
  [-28.6523, 114.6475],
  [-28.6517, 114.6485],
  [-28.6508, 114.6490],
  [-28.6498, 114.6496],
  [-28.6466, 114.6536],
  [-28.6455, 114.6542],
  [-28.6449, 114.6543],
  [-28.6441, 114.6542],
  [-28.6428, 114.6546],
  [-28.6408, 114.6548],
  [-28.6401, 114.6551],
  [-28.6386, 114.6562],
  [-28.6375, 114.6564],
  [-28.6366, 114.6573],
  [-28.6362, 114.6589],
  [-28.6363, 114.6606],
  [-28.6361, 114.6632],
  [-28.6354, 114.6641],
  [-28.6344, 114.6644],
  [-28.6337, 114.6651],
  [-28.6322, 114.6677],
  [-28.6320, 114.6691],
  [-28.6325, 114.6714],
  [-28.6333, 114.6738],
  [-28.6335, 114.6756],
  [-28.6333, 114.6785],
  [-28.6333, 114.6802],
  [-28.6343, 114.6853],
  [-28.6345, 114.6867],
  [-28.6341, 114.6904],
  [-28.6341, 114.6927],
  [-28.6351, 114.6959],
  [-28.6360, 114.6981],
  [-28.6369, 114.7042],
  [-28.6361, 114.7061],
  [-28.6349, 114.7072],
  [-28.6341, 114.7088],
  [-28.6339, 114.7105],
  [-28.6332, 114.7120],
  [-28.6287, 114.7159],
  [-28.6263, 114.7172],
  [-28.6145, 114.7215],
  [-28.6088, 114.7237],
  [-28.6075, 114.7236],
  [-28.6010, 114.7204],
  [-28.6003, 114.7204],
  [-28.5995, 114.7208],
  [-28.5989, 114.7207],
  [-28.5985, 114.7203],
  [-28.5979, 114.7204],
  [-28.5968, 114.7214],
  [-28.5883, 114.7249],
  [-28.5874, 114.7255],
  [-28.5868, 114.7268],
  [-28.5851, 114.7339],
  [-28.5834, 114.7380],
  [-28.5792, 114.7433],
  [-28.5658, 114.7522],
  [-28.5652, 114.7536],
  [-28.5648, 114.7562],
  [-28.5644, 114.7573],
  [-28.5609, 114.7601],
  [-28.5532, 114.7650],
  [-28.5427, 114.7690],
  [-28.5413, 114.7691],
  [-28.5377, 114.7676],
  [-28.5351, 114.7676],
  [-28.5331, 114.7681],
  [-28.5318, 114.7678],
  [-28.5306, 114.7672],
  [-28.5279, 114.7674],
  [-28.5234, 114.7700],
  [-28.5225, 114.7713],
  [-28.5213, 114.7746],
  [-28.5155, 114.7814],
  [-28.5146, 114.7821],
  [-28.5117, 114.7833],
  [-28.5089, 114.7860],
  [-28.5071, 114.7868],
  [-28.5013, 114.7878],
  [-28.5000, 114.7880],
  [-28.4950, 114.7914],
  [-28.4925, 114.7918],
  [-28.4882, 114.7912],
  [-28.4859, 114.7905],
  [-28.4841, 114.7908],
  [-28.4391, 114.8166],
  [-28.4360, 114.8171],
  [-28.4345, 114.8168],
  [-28.4328, 114.8159],
  [-28.4315, 114.8159],
  [-28.4265, 114.8202],
  [-28.4240, 114.8217],
  [-28.4221, 114.8220],
  [-28.4204, 114.8237],
  [-28.4150, 114.8335],
  [-28.4149, 114.8349],
  [-28.4154, 114.8365],
  [-28.4154, 114.8380],
  [-28.4139, 114.8465],
  [-28.4128, 114.8492],
  [-28.4111, 114.8511],
  [-28.4010, 114.8539]
];

const naralingYuna = [
  [-28.4010, 114.8539],
  [-28.3971, 114.8551],
  [-28.3956, 114.8567],
  [-28.3935, 114.8622],
  [-28.3873, 114.8681],
  [-28.3863, 114.8700],
  [-28.3852, 114.8709],
  [-28.3818, 114.8720],
  [-28.3798, 114.8718],
  [-28.3764, 114.8706],
  [-28.3747, 114.8708],
  [-28.3718, 114.8726],
  [-28.3656, 114.8747],
  [-28.3643, 114.8757],
  [-28.3616, 114.8789],
  [-28.3571, 114.8803],
  [-28.3553, 114.8815],
  [-28.3504, 114.8875],
  [-28.3490, 114.8895],
  [-28.3443, 114.9035],
  [-28.3439, 114.9084],
  [-28.3453, 114.9140],
  [-28.3448, 114.9171],
  [-28.3348, 114.9400],
  [-28.3289, 114.9598],
  [-28.3286, 114.9617],
  [-28.3285, 115.0026]
];

export const upperChapman: Line = {
  name: 'Upper Chapman',
  state: 'WA',
  segments: [
    {
      segments: [wokarinaNaraling],
      history: {
        opened: {
          date: '1910-10-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-04-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [naralingYuna],
      history: {
        opened: {
          date: '1912-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-04-29',
          status: 'closed'
        }]
      }
    }
  ]
};
