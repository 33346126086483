import { Line } from "../../../../trackTypes";

const docklandsStadiumNorthJunction = [
  [-37.81482, 144.94489],
  [-37.81493, 144.94500],
  [-37.81495, 144.94523]
];

const docklandsStadiumSouthJunction = [
  [-37.81517, 144.94503],
  [-37.81505, 144.94508],
  [-37.81495, 144.94523]
];

const docklandsStadium = [
  [-37.81495, 144.94523],
  [-37.81323, 144.95117],
  [-37.81310, 144.95168]
];

const docklandsJunction = [
  [-37.81323, 144.95117],
  [-37.81323, 144.95132],
  [-37.81327, 144.95144],
  [-37.81332, 144.95151]
];

const lonsdaleStreet = [
  [-37.81242, 144.96232],
  [-37.81234, 144.96219],
  [-37.81234, 144.96202],
  [-37.81506, 144.95257],
  [-37.81505, 144.95241],
  [-37.81495, 144.95227]
];

const upperSpencerStreet = [
  [-37.81495, 144.95227],
  [-37.81332, 144.95151]
];

const spencerStreetSiding = [
  [-37.81332, 144.95151],
  [-37.81218, 144.95098]
];

const westMelbourne = [
  [-37.81218, 144.95098],
  [-37.81048, 144.95019],
  [-37.80928, 144.94940],
  [-37.80841, 144.94865],
  [-37.80759, 144.94770],
  [-37.80705, 144.94692],
  [-37.80626, 144.94510],
  [-37.80614, 144.94498],
  [-37.80602, 144.94493],
  [-37.80241, 144.94557]
];

const laTrobeStreet = [
  [-37.81332, 144.95151],
  [-37.81325, 144.95152],
  [-37.81316, 144.95158],
  [-37.81310, 144.95168],
  [-37.81181, 144.95620],
  [-37.81168, 144.95664],
  [-37.81038, 144.96113],
  [-37.80965, 144.96363],
  [-37.80759, 144.97062],
  [-37.80758, 144.97102],
  [-37.80778, 144.97198],
  [-37.80787, 144.97286],
  [-37.80795, 144.97351],
  [-37.80820, 144.97609]
];

const nicholsonStreetJunction = [
  [-37.80787, 144.97286],
  [-37.80785, 144.97305],
  [-37.80779, 144.97315],
  [-37.80764, 144.97324]
];

const nicholsonStreetJunction2 = [
  [-37.80817, 144.97315],
  [-37.80805, 144.97322],
  [-37.80799, 144.97332],
  [-37.80795, 144.97351]
];

export const west2: Line = {
  name: 'West2',
  state: 'VIC',
  segments: [
    {
      segments: [docklandsStadiumNorthJunction],
      history: {
        opened: {
          date: '2005-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [docklandsStadiumSouthJunction],
      history: {
        opened: {
          date: '2003-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [docklandsStadium, docklandsJunction],
      history: {
        opened: {
          date: '2000-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [lonsdaleStreet],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lonsdaleStreet],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [upperSpencerStreet],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }, {
          date: '1951-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [spencerStreetSiding],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }, {
          date: '1993-12-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [westMelbourne],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [laTrobeStreet, nicholsonStreetJunction, nicholsonStreetJunction2],
      history: {
        opened: {
          date: '1951-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
