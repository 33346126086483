import React, { useState } from "react";
import styled, { css } from "styled-components";

const Slider = styled.div<{$isOn: boolean}>`
  width: 75px;
  height: 40px;
  position: relative;
  border: 2px solid black;
  border-radius: 20px;
  transition: border-color 0.6s ease-out;
  box-sizing: border-box;
  display: flex;

  ${({ $isOn }) => $isOn && css`
    border-color: lightgray;
    -webkit-transition: all .5s .15s ease-out;
    transition: all .5s .15s ease-out;
  `}

  @media (max-width: 600px) {
    position: fixed;
    top: 3.5rem;
    right: 1rem;
  }
`;

const ToggleButton = styled.div<{$isOn: boolean}>`
  position: absolute;
  top: 4px;
  width: 28px;
  bottom: 4px;
  right: 39px;
  background-color: black;
  border-radius: 19px;
  cursor: pointer;
  -webkit-transition: all .3s .1s, width .1s, top .1s, bottom .1s;
  transition: all .3s .1s, width .1s, top .1s, bottom .1s;

  ${({ $isOn }) => $isOn && css`
      top: 3px;
      width: 65px;
      bottom: 3px;
      right: 3px;
      border-radius: 23px;
      background-color: white;
      box-shadow: 0 0 16px lightgray;
      -webkit-transition: all .2s .1s, right .1s;
      transition: all .2s .1s, right .1s;
  `}
`;

const Text = styled.p<{$isOn: boolean}>`
  color: black;
  font-size: 0.75rem;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  padding-right: 7px;

  ${({ $isOn }) => $isOn && css`
    justify-content: center;
    padding-right: 0;
  `}
`;

type ToggleSliderProps = {
  checked: boolean;
  onToggle: Function;
};

const ToggleSlider = ({ checked, onToggle }: ToggleSliderProps) => {
  const [isOn, setIsOn] = useState(checked ? true: false);
  const toggleText = isOn ? 'Light' : 'Dark';
  return (
    <Slider $isOn={isOn} onClick={() => {setIsOn(!isOn); onToggle()}}>
      <ToggleButton $isOn={isOn}></ToggleButton>
      <Text $isOn={isOn}>{toggleText}</Text>
    </Slider>
  );
};

export default ToggleSlider;
