import { Line } from "../../trackTypes";

const coltonAalberg = [
  [-25.4267, 152.6518],
  [-25.4259, 152.6519],
  [-25.4254, 152.6520],
  [-25.4247, 152.6523],
  [-25.4064, 152.6723],
  [-25.3856, 152.7017],
  [-25.3677, 152.7346],
  [-25.3650, 152.7409],
  [-25.3623, 152.7435],
  [-25.3602, 152.7445],
  [-25.3589, 152.7457],
  [-25.3424, 152.7704],
  [-25.3414, 152.7731],
  [-25.3398, 152.7799],
  [-25.3376, 152.7842],
  [-25.3314, 152.7913],
  [-25.3291, 152.7934],
  [-25.3249, 152.7959],
  [-25.3205, 152.8003],
  [-25.3195, 152.8024],
  [-25.3190, 152.8054]
];

const aalbergPialba = [
  [-25.3190, 152.8054],
  [-25.3183, 152.8070],
  [-25.3159, 152.8098],
  [-25.3140, 152.8109],
  [-25.3116, 152.8114],
  [-25.3102, 152.8114],
  [-25.3076, 152.8118],
  [-25.3069, 152.8122],
  [-25.3051, 152.8147],
  [-25.3046, 152.8162],
  [-25.3041, 152.8167],
  [-25.3035, 152.8169],
  [-25.3023, 152.8171],
  [-25.3014, 152.8177],
  [-25.3008, 152.8199],
  [-25.3011, 152.8255],
  [-25.3020, 152.8329],
  [-25.3017, 152.8339],
  [-25.3011, 152.8346],
  [-25.2992, 152.8358],
  [-25.2979, 152.8361],
  [-25.2962, 152.8354],
  [-25.2953, 152.8354],
  [-25.2842, 152.8383],
  [-25.2799, 152.8389]
];

const pialbaUrangan = [
  [-25.2842, 152.8383],
  [-25.2835, 152.8390],
  [-25.2835, 152.8400],
  [-25.2865, 152.8479],
  [-25.2878, 152.8576],
  [-25.2887, 152.8617],
  [-25.2887, 152.8659],
  [-25.2887, 152.8669],
  [-25.2903, 152.8726],
  [-25.2926, 152.8897],
  [-25.2924, 152.8915],
  [-25.2908, 152.8957],
  [-25.2896, 152.8976],
  [-25.2880, 152.8991],
  [-25.2817, 152.9044],
  [-25.2763, 152.9081]
];

export const herveyBay: Line = {
  name: 'Hervey Bay',
  state: 'QLD',
  segments: [
    {
      segments: [coltonAalberg],
      history: {
        opened: {
          date: '1896-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [aalbergPialba],
      history: {
        opened: {
          date: '1896-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pialbaUrangan],
      history: {
        opened: {
          date: '1913-12-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
