import { Line } from "../../trackTypes";

const southYarraCaulfield = [
  [-37.8381, 144.9923],
  [-37.8391, 144.9922],
  [-37.8400, 144.9924],
  [-37.8405, 144.9927],
  [-37.8409, 144.9932],
  [-37.8413, 144.9939],
  [-37.8422, 144.9964],
  [-37.8475, 145.0097],
  [-37.8480, 145.0107],
  [-37.8485, 145.0115],
  [-37.8491, 145.0121],
  [-37.8754, 145.0396],
  [-37.8761, 145.0405],
  [-37.8794, 145.0453]
];

const caulfieldOakleigh = [
  [-37.8794, 145.0453],
  [-37.8809, 145.0478],
  [-37.8831, 145.0517],
  [-37.8959, 145.0800],
  [-37.8983, 145.0855],
  [-37.8988, 145.0863],
  [-37.9004, 145.0884]
];

const oakleighDandenong = [
  [-37.9004, 145.0884],
  [-37.9125, 145.1044],
  [-37.9487, 145.1523],
  [-37.9885, 145.2066],
  [-37.9891, 145.2077],
  [-37.9900, 145.2096]
];

const dandenongNarreWarren = [
  [-37.9900, 145.2096],
  [-37.9914, 145.2129],
  [-37.9919, 145.2135],
  [-37.9923, 145.2139],
  [-37.9963, 145.2165],
  [-37.9974, 145.2174],
  [-37.9983, 145.2185],
  [-37.9989, 145.2197],
  [-37.9999, 145.2218],
  [-38.0012, 145.2248],
  [-38.0157, 145.2570],
  [-38.0164, 145.2592],
  [-38.0168, 145.2615],
  [-38.0195, 145.2863],
  [-38.0199, 145.2883],
  [-38.0206, 145.2903],
  [-38.0276, 145.3035]
];

const narreWarrenBerwick = [
  [-38.0276, 145.3035],
  [-38.0296, 145.3076],
  [-38.0302, 145.3089],
  [-38.0307, 145.3103],
  [-38.0309, 145.3112],
  [-38.0342, 145.3254],
  [-38.0344, 145.3268],
  [-38.0346, 145.3282],
  [-38.0354, 145.3369],
  [-38.0355, 145.3381],
  [-38.0358, 145.3394],
  [-38.0362, 145.3406],
  [-38.0367, 145.3416],
  [-38.0373, 145.3425],
  [-38.0381, 145.3436],
  [-38.0388, 145.3442],
  [-38.0396, 145.3449]
];

const berwickOfficer = [
  [-38.0396, 145.3449],
  [-38.0420, 145.3467],
  [-38.0437, 145.3484],
  [-38.0446, 145.3496],
  [-38.0452, 145.3507],
  [-38.0469, 145.3548],
  [-38.0518, 145.3688],
  [-38.0524, 145.3702],
  [-38.0530, 145.3713],
  [-38.0537, 145.3723],
  [-38.0587, 145.3780],
  [-38.0596, 145.3794],
  [-38.0602, 145.3809],
  [-38.0606, 145.3824],
  [-38.0662, 145.4116]
];

const officerPakenham = [
  [-38.0662, 145.4116],
  [-38.0803, 145.4857]
];

const pakenhamPakenhamEast = [
  [-38.0803, 145.4857],
  [-38.0850, 145.5101],
  [-38.0852, 145.5118],
  [-38.0852, 145.5140],
  [-38.0850, 145.5157],
  [-38.0832, 145.5271]
];

export const pakenham: Line = {
  name: 'Pakenham',
  state: 'VIC',
  segments: [
    {
      segments: [southYarraCaulfield],
      history: {
        opened: {
          date: '1879-04-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
            date: '1922-03-05',
            electrified: {
            current: 'DC',
            isOverhead: true
          }
          }, {
            date: '1881-12-12',
            tracks: 2
          }, {
            date: '1915-10-03',
            tracks: 4
        }]
      }
    },
    {
      segments: [caulfieldOakleigh],
      history: {
        opened: {
          date: '1879-04-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1883-02-07',
          tracks: 2
        }, {
          date: '1922-03-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [dandenongNarreWarren],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-11-18',
          tracks: 2
        }, {
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [officerPakenham],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-02-25',
          tracks: 2,
        }, {
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [berwickOfficer],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-03-13',
          tracks: 2
        }, {
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [narreWarrenBerwick],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-02-25',
          tracks: 2
        }, {
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [oakleighDandenong],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1891-12-14',
          tracks: 2
        }, {
          date: '1922-12-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [pakenhamPakenhamEast],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-10-10',
          tracks: 2
        }, {
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2001-08-22',
          electrified: undefined
        }, {
          date: '2018-12-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
