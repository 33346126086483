import { Line } from "../../trackTypes";

const line = [
  [-42.6833, 146.8410],
  [-42.6831, 146.8398],
  [-42.6832, 146.8388],
  [-42.6838, 146.8379],
  [-42.6875, 146.8368],
  [-42.6884, 146.8360],
  [-42.6890, 146.8352],
  [-42.6899, 146.8346],
  [-42.6913, 146.8346],
  [-42.6921, 146.8333],
  [-42.6923, 146.8325],
  [-42.6918, 146.8311],
  [-42.6917, 146.8298],
  [-42.6924, 146.8231],
  [-42.6922, 146.8221],
  [-42.6919, 146.8210],
  [-42.6920, 146.8183],
  [-42.6918, 146.8163],
  [-42.6920, 146.8147],
  [-42.6934, 146.8126],
  [-42.6941, 146.8122],
  [-42.6955, 146.8120],
  [-42.6964, 146.8115],
  [-42.6969, 146.8102],
  [-42.6975, 146.8095],
  [-42.6994, 146.8089],
  [-42.7001, 146.8092],
  [-42.7015, 146.8106],
  [-42.7030, 146.8110],
  [-42.7042, 146.8119],
  [-42.7047, 146.8131],
  [-42.7053, 146.8137],
  [-42.7061, 146.8138],
  [-42.7067, 146.8143],
  [-42.7087, 146.8146],
  [-42.7106, 146.8140],
  [-42.7116, 146.8139],
  [-42.7130, 146.8129],
  [-42.7143, 146.8127],
  [-42.7148, 146.8135],
  [-42.7147, 146.8146],
  [-42.7137, 146.8157],
  [-42.7133, 146.8167],
  [-42.7134, 146.8175],
  [-42.7141, 146.8185],
  [-42.7147, 146.8187],
  [-42.7156, 146.8183],
  [-42.7165, 146.8184],
  [-42.7173, 146.8193],
  [-42.7181, 146.8193],
  [-42.7189, 146.8187],
  [-42.7193, 146.8186],
  [-42.7197, 146.8189],
  [-42.7201, 146.8198],
  [-42.7205, 146.8202],
  [-42.7213, 146.8206],
  [-42.7217, 146.8210],
  [-42.7226, 146.8231],
  [-42.7232, 146.8234],
  [-42.7235, 146.8231],
  [-42.7239, 146.8223],
  [-42.7256, 146.8197],
  [-42.7281, 146.8187],
  [-42.7286, 146.8189],
  [-42.7291, 146.8194],
  [-42.7296, 146.8196],
  [-42.7305, 146.8193],
  [-42.7309, 146.8187],
  [-42.7310, 146.8169],
  [-42.7304, 146.8154],
  [-42.7290, 146.8142],
  [-42.7280, 146.8127],
  [-42.7278, 146.8118],
  [-42.7280, 146.8108],
  [-42.7289, 146.8099],
  [-42.7296, 146.8087],
  [-42.7310, 146.8075],
  [-42.7335, 146.8070],
  [-42.7341, 146.8065],
  [-42.7344, 146.8055],
  [-42.7343, 146.8030],
  [-42.7347, 146.8006],
  [-42.7358, 146.7986],
  [-42.7361, 146.7980],
  [-42.7366, 146.7976],
  [-42.7369, 146.7967],
  [-42.7373, 146.7963],
  [-42.7377, 146.7964],
  [-42.7380, 146.7970],
  [-42.7386, 146.7973],
  [-42.7403, 146.7977],
  [-42.7457, 146.8001],
  [-42.7469, 146.8000],
  [-42.7486, 146.7986],
  [-42.7494, 146.7982],
  [-42.7510, 146.7983],
  [-42.7517, 146.7985],
  [-42.7525, 146.7984],
  [-42.7533, 146.7976],
  [-42.7542, 146.7971],
  [-42.7551, 146.7959],
  [-42.7553, 146.7953],
  [-42.7554, 146.7938],
  [-42.7556, 146.7932],
  [-42.7570, 146.7910],
  [-42.7584, 146.7902],
  [-42.7591, 146.7902],
  [-42.7598, 146.7899],
  [-42.7612, 146.7883],
  [-42.7616, 146.7876],
  [-42.7622, 146.7872],
  [-42.7625, 146.7866],
  [-42.7627, 146.7855],
  [-42.7631, 146.7847],
  [-42.7647, 146.7836],
  [-42.7656, 146.7836],
  [-42.7672, 146.7823],
  [-42.7676, 146.7815],
  [-42.7676, 146.7797],
  [-42.7682, 146.7776],
  [-42.7682, 146.7761],
  [-42.7684, 146.7755],
  [-42.7693, 146.7749],
  [-42.7699, 146.7750],
  [-42.7704, 146.7751],
  [-42.7724, 146.7730],
  [-42.7726, 146.7719],
  [-42.7738, 146.7699]
];

export const styx: Line = {
  name: 'Styx',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1943-01-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
