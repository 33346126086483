import { Line } from "../../../trackTypes";

const palmerstonShingles = [
  [-45.4820, 170.7201],
  [-45.4816, 170.7204],
  [-45.4811, 170.7206],
  [-45.4748, 170.7207],
  [-45.4740, 170.7201],
  [-45.4732, 170.7185],
  [-45.4703, 170.7061],
  [-45.4696, 170.7036],
  [-45.4645, 170.6933],
  [-45.4631, 170.6912],
  [-45.4582, 170.6872]
];

const shinglesInchValley = [
  [-45.4582, 170.6872],
  [-45.4421, 170.6747],
  [-45.4346, 170.6681],
  [-45.4316, 170.6644],
  [-45.4307, 170.6639],
  [-45.4264, 170.6629],
  [-45.4255, 170.6623],
  [-45.4249, 170.6616],
  [-45.4245, 170.6608],
  [-45.4238, 170.6602],
  [-45.4175, 170.6587],
  [-45.4166, 170.6581],
  [-45.4157, 170.6568],
  [-45.4102, 170.6537]
];

const inchValleyDunback = [
  [-45.4102, 170.6537],
  [-45.4094, 170.6530],
  [-45.4083, 170.6513],
  [-45.4078, 170.6499],
  [-45.4079, 170.6485],
  [-45.4082, 170.6470],
  [-45.4081, 170.6458],
  [-45.4065, 170.6430],
  [-45.4059, 170.6425],
  [-45.4033, 170.6419],
  [-45.4012, 170.6403],
  [-45.3997, 170.6387],
  [-45.3992, 170.6382],
  [-45.3969, 170.6372],
  [-45.3945, 170.6352],
  [-45.3931, 170.6345],
  [-45.3906, 170.6350],
  [-45.3896, 170.6347],
  [-45.3879, 170.6332],
  [-45.3865, 170.6325],
  [-45.3847, 170.6307]
];

const inchValleyMakareao = [
  [-45.4102, 170.6537],
  [-45.4089, 170.6537],
  [-45.4080, 170.6543],
  [-45.4057, 170.6585],
  [-45.4051, 170.6617],
  [-45.4043, 170.6632],
  [-45.3979, 170.6693],
  [-45.3973, 170.6706],
  [-45.3970, 170.6726],
  [-45.3965, 170.6736],
  [-45.3952, 170.6750],
  [-45.3923, 170.6771],
  [-45.3905, 170.6796],
  [-45.3899, 170.6804],
  [-45.3878, 170.6810]
];

export const dunbackMakareao: Line = {
  name: 'Dunback & Makareao',
  state: 'NZ',
  segments: [
    {
      segments: [palmerstonShingles],
      history: {
        opened: {
          date: '1880-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [shinglesInchValley],
      history: {
        opened: {
          date: '1885-08-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [inchValleyDunback],
      history: {
        opened: {
          date: '1885-08-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [inchValleyMakareao],
      history: {
        opened: {
          date: '1900-03-31',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-06-01',
          status: 'closed'
        }]
      }
    }
  ]
}
