import { Line } from "../../trackTypes";

const line = [
  [-34.7337, 148.7321],
  [-34.7332, 148.7314],
  [-34.7330, 148.7308],
  [-34.7331, 148.7305],
  [-34.7334, 148.7304],
  [-34.7342, 148.7304],
  [-34.7346, 148.7301],
  [-34.7348, 148.7296],
  [-34.7352, 148.7292],
  [-34.7366, 148.7284],
  [-34.7375, 148.7271],
  [-34.7381, 148.7266],
  [-34.7384, 148.7262],
  [-34.7388, 148.7248],
  [-34.7390, 148.7240],
  [-34.7390, 148.7234],
  [-34.7389, 148.7230],
  [-34.7388, 148.7226],
  [-34.7386, 148.7220],
  [-34.7384, 148.7215],
  [-34.7372, 148.7199],
  [-34.7359, 148.7191],
  [-34.7354, 148.7183],
  [-34.7353, 148.7176],
  [-34.7354, 148.7147],
  [-34.7353, 148.7140],
  [-34.7349, 148.7134],
  [-34.7346, 148.7121],
  [-34.7347, 148.7115],
  [-34.7355, 148.7097],
  [-34.7359, 148.7085],
  [-34.7359, 148.7078],
  [-34.7358, 148.7070],
  [-34.7359, 148.7066],
  [-34.7361, 148.7064],
  [-34.7370, 148.7061],
  [-34.7374, 148.7061],
  [-34.7378, 148.7063],
  [-34.7382, 148.7063],
  [-34.7391, 148.7060],
  [-34.7397, 148.7060],
  [-34.7402, 148.7061],
  [-34.7402, 148.7061],
  [-34.7415, 148.7058],
  [-34.7420, 148.7055],
  [-34.7430, 148.7044],
  [-34.7434, 148.7041],
  [-34.7436, 148.7037],
  [-34.7437, 148.7032],
  [-34.7437, 148.7027],
  [-34.7439, 148.7021],
  [-34.7468, 148.6971],
  [-34.7471, 148.6969],
  [-34.7476, 148.6967],
  [-34.7480, 148.6968],
  [-34.7485, 148.6968],
  [-34.7519, 148.6954],
  [-34.7526, 148.6952],
  [-34.7594, 148.6951],
  [-34.7687, 148.6930],
  [-34.7705, 148.6931],
  [-34.7712, 148.6935],
  [-34.7716, 148.6936],
  [-34.7740, 148.6936],
  [-34.7774, 148.6947],
  [-34.7811, 148.6949],
  [-34.7839, 148.6946],
  [-34.7842, 148.6946],
  [-34.7848, 148.6949],
  [-34.7851, 148.6952],
  [-34.7853, 148.6960],
  [-34.7854, 148.6962],
  [-34.7857, 148.6964],
  [-34.7885, 148.6967],
  [-34.7920, 148.6957],
  [-34.7954, 148.6944],
  [-34.8008, 148.6919],
  [-34.8015, 148.6908],
  [-34.8025, 148.6902],
  [-34.8090, 148.6893],
  [-34.8104, 148.6894],
  [-34.8142, 148.6903],
  [-34.8170, 148.6914],
  [-34.8176, 148.6915],
  [-34.8186, 148.6916],
  [-34.8209, 148.6920],
  [-34.8227, 148.6927],
  [-34.8243, 148.6930],
  [-34.8257, 148.6940],
  [-34.8273, 148.6951],
  [-34.8287, 148.6971],
  [-34.8297, 148.6977],
  [-34.8305, 148.6988],
  [-34.8309, 148.6992],
  [-34.8316, 148.6994],
  [-34.8332, 148.6984],
  [-34.8336, 148.6982],
  [-34.8409, 148.6982],
  [-34.8457, 148.6960],
  [-34.8484, 148.6954],
  [-34.8529, 148.6958],
  [-34.8537, 148.6958],
  [-34.8668, 148.6919],
  [-34.8674, 148.6918],
  [-34.8694, 148.6922],
  [-34.8837, 148.6883],
  [-34.8872, 148.6866],
  [-34.8895, 148.6857],
  [-34.8902, 148.6852],
  [-34.8908, 148.6848],
  [-34.8912, 148.6842],
  [-34.8916, 148.6839],
  [-34.8924, 148.6838],
  [-34.8928, 148.6836],
  [-34.8934, 148.6827],
  [-34.8943, 148.6824],
  [-34.8968, 148.6799],
  [-34.8978, 148.6795],
  [-34.8981, 148.6793],
  [-34.8983, 148.6788],
  [-34.8982, 148.6783],
  [-34.8983, 148.6780],
  [-34.8985, 148.6779],
  [-34.8988, 148.6779],
  [-34.8990, 148.6777],
  [-34.8998, 148.6765],
  [-34.9011, 148.6755],
  [-34.9049, 148.6729],
  [-34.9083, 148.6713],
  [-34.9093, 148.6701],
  [-34.9105, 148.6694],
  [-34.9121, 148.6670],
  [-34.9161, 148.6634],
  [-34.9178, 148.6613],
  [-34.9221, 148.6580],
  [-34.9239, 148.6575],
  [-34.9252, 148.6579],
  [-34.9264, 148.6578],
  [-34.9271, 148.6577],
  [-34.9300, 148.6548],
  [-34.9304, 148.6547],
  [-34.9312, 148.6548],
  [-34.9316, 148.6547],
  [-34.9337, 148.6538],
  [-34.9340, 148.6535],
  [-34.9354, 148.6518],
  [-34.9358, 148.6515],
  [-34.9363, 148.6512],
  [-34.9367, 148.6509],
  [-34.9371, 148.6502],
  [-34.9373, 148.6494],
  [-34.9375, 148.6490],
  [-34.9387, 148.6476],
  [-34.9391, 148.6474],
  [-34.9406, 148.6466],
  [-34.9409, 148.6463],
  [-34.9410, 148.6458],
  [-34.9409, 148.6449],
  [-34.9410, 148.6443],
  [-34.9414, 148.6439],
  [-34.9426, 148.6438],
  [-34.9449, 148.6443],
  [-34.9465, 148.6439],
  [-34.9469, 148.6437],
  [-34.9474, 148.6430],
  [-34.9493, 148.6427],
  [-34.9500, 148.6428],
  [-34.9504, 148.6428],
  [-34.9519, 148.6421],
  [-34.9525, 148.6414],
  [-34.9530, 148.6412],
  [-34.9535, 148.6414],
  [-34.9538, 148.6413],
  [-34.9542, 148.6407],
  [-34.9552, 148.6399],
  [-34.9557, 148.6394],
  [-34.9558, 148.6389],
  [-34.9556, 148.6386],
  [-34.9539, 148.6388],
  [-34.9537, 148.6386],
  [-34.9535, 148.6382],
  [-34.9535, 148.6379],
  [-34.9541, 148.6376],
  [-34.9544, 148.6372],
  [-34.9546, 148.6360],
  [-34.9561, 148.6341],
  [-34.9571, 148.6326],
  [-34.9579, 148.6318],
  [-34.9582, 148.6317],
  [-34.9582, 148.6320],
  [-34.9583, 148.6323],
  [-34.9578, 148.6331],
  [-34.9578, 148.6334],
  [-34.9580, 148.6336],
  [-34.9587, 148.6332],
  [-34.9589, 148.6332],
  [-34.9594, 148.6334],
  [-34.9597, 148.6334],
  [-34.9601, 148.6332],
  [-34.9603, 148.6331],
  [-34.9605, 148.6333],
  [-34.9608, 148.6334],
  [-34.9618, 148.6329],
  [-34.9625, 148.6327],
  [-34.9630, 148.6324],
  [-34.9635, 148.6324],
  [-34.9637, 148.6324],
  [-34.9641, 148.6322],
  [-34.9646, 148.6322],
  [-34.9659, 148.6316],
  [-34.9669, 148.6313],
  [-34.9671, 148.6310],
  [-34.9670, 148.6308],
  [-34.9654, 148.6307],
  [-34.9652, 148.6305],
  [-34.9652, 148.6302],
  [-34.9654, 148.6299],
  [-34.9657, 148.6298],
  [-34.9663, 148.6300],
  [-34.9667, 148.6299],
  [-34.9672, 148.6301],
  [-34.9675, 148.6304],
  [-34.9677, 148.6304],
  [-34.9682, 148.6303],
  [-34.9687, 148.6302],
  [-34.9694, 148.6297],
  [-34.9705, 148.6294],
  [-34.9706, 148.6293],
  [-34.9706, 148.6287],
  [-34.9706, 148.6285],
  [-34.9708, 148.6283],
  [-34.9716, 148.6283],
  [-34.9719, 148.6284],
  [-34.9721, 148.6284],
  [-34.9722, 148.6283],
  [-34.9722, 148.6278],
  [-34.9720, 148.6273],
  [-34.9719, 148.6268],
  [-34.9720, 148.6261],
  [-34.9722, 148.6259],
  [-34.9724, 148.6259],
  [-34.9733, 148.6265],
  [-34.9736, 148.6266],
  [-34.9740, 148.6264],
  [-34.9744, 148.6264],
  [-34.9750, 148.6260],
  [-34.9753, 148.6255],
  [-34.9757, 148.6252],
  [-34.9763, 148.6251],
  [-34.9764, 148.6249],
  [-34.9764, 148.6244],
  [-34.9765, 148.6239],
  [-34.9766, 148.6234],
  [-34.9768, 148.6231],
  [-34.9772, 148.6231],
  [-34.9777, 148.6229],
  [-34.9780, 148.6222],
  [-34.9781, 148.6209],
  [-34.9778, 148.6197],
  [-34.9779, 148.6193],
  [-34.9781, 148.6192],
  [-34.9784, 148.6194],
  [-34.9789, 148.6194],
  [-34.9795, 148.6192],
  [-34.9799, 148.6187],
  [-34.9801, 148.6186],
  [-34.9805, 148.6186],
  [-34.9810, 148.6183],
  [-34.9814, 148.6177],
  [-34.9829, 148.6163],
  [-34.9840, 148.6155],
  [-34.9861, 148.6146],
  [-34.9868, 148.6145],
  [-34.9878, 148.6137],
  [-34.9888, 148.6128],
  [-34.9891, 148.6123],
  [-34.9902, 148.6110],
  [-34.9902, 148.6106],
  [-34.9901, 148.6105],
  [-34.9901, 148.6103],
  [-34.9904, 148.6097],
  [-34.9908, 148.6093],
  [-34.9909, 148.6090],
  [-34.9909, 148.6088],
  [-34.9909, 148.6084],
  [-34.9910, 148.6082],
  [-34.9911, 148.6081],
  [-34.9916, 148.6084],
  [-34.9918, 148.6085],
  [-34.9925, 148.6086],
  [-34.9929, 148.6084],
  [-34.9933, 148.6078],
  [-34.9935, 148.6074],
  [-34.9942, 148.6065],
  [-34.9944, 148.6061],
  [-34.9947, 148.6058],
  [-34.9950, 148.6050],
  [-34.9953, 148.6033],
  [-34.9955, 148.6026],
  [-34.9956, 148.6020],
  [-34.9955, 148.6017],
  [-34.9953, 148.6011],
  [-34.9949, 148.6007],
  [-34.9946, 148.6005],
  [-34.9941, 148.6005],
  [-34.9940, 148.6004],
  [-34.9930, 148.5995],
  [-34.9921, 148.5977],
  [-34.9919, 148.5975],
  [-34.9914, 148.5973],
  [-34.9909, 148.5973],
  [-34.9907, 148.5972],
  [-34.9905, 148.5970],
  [-34.9903, 148.5967],
  [-34.9899, 148.5965],
  [-34.9894, 148.5960],
  [-34.9894, 148.5956],
  [-34.9894, 148.5954],
  [-34.9896, 148.5953],
  [-34.9900, 148.5954],
  [-34.9901, 148.5957],
  [-34.9904, 148.5960],
  [-34.9909, 148.5963],
  [-34.9912, 148.5964],
  [-34.9919, 148.5964],
  [-34.9943, 148.5973],
  [-34.9944, 148.5974],
  [-34.9944, 148.5983],
  [-34.9945, 148.5985],
  [-34.9950, 148.5986],
  [-34.9956, 148.5986],
  [-34.9963, 148.5990],
  [-34.9967, 148.5990],
  [-34.9970, 148.5988],
  [-34.9972, 148.5986],
  [-34.9974, 148.5986],
  [-34.9980, 148.5988],
  [-34.9982, 148.5988],
  [-34.9985, 148.5987],
  [-34.9987, 148.5987],
  [-34.9990, 148.5988],
  [-34.9992, 148.5988],
  [-34.9997, 148.5987],
  [-35.0003, 148.5983],
  [-35.0006, 148.5979],
  [-35.0007, 148.5975],
  [-35.0007, 148.5969],
  [-35.0007, 148.5967],
  [-35.0008, 148.5966],
  [-35.0017, 148.5967],
  [-35.0021, 148.5966],
  [-35.0030, 148.5958],
  [-35.0032, 148.5955],
  [-35.0032, 148.5952],
  [-35.0031, 148.5950],
  [-35.0031, 148.5948],
  [-35.0032, 148.5943],
  [-35.0032, 148.5939],
  [-35.0028, 148.5930],
  [-35.0028, 148.5927],
  [-35.0030, 148.5924],
  [-35.0030, 148.5922],
  [-35.0026, 148.5909],
  [-35.0020, 148.5902],
  [-35.0019, 148.5900],
  [-35.0020, 148.5890],
  [-35.0020, 148.5887],
  [-35.0018, 148.5882],
  [-35.0018, 148.5879],
  [-35.0019, 148.5875],
  [-35.0016, 148.5858],
  [-35.0016, 148.5854],
  [-35.0016, 148.5849],
  [-35.0013, 148.5842]
];

export const goondahBurrinjuck: Line = {
  name: 'Barraba',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1908-06-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1929-05-01',
          status: 'closed'
        }]
      }
    }
  ]
};
