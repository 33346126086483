import { Line } from "../../trackTypes";

const line = [
  [-26.6878, 152.9603],
  [-26.6871, 152.9603],
  [-26.6868, 152.9606],
  [-26.6865, 152.9617],
  [-26.6865, 152.9628],
  [-26.6869, 152.9651],
  [-26.6874, 152.9661],
  [-26.6888, 152.9673],
  [-26.6895, 152.9676],
  [-26.6910, 152.9702],
  [-26.6925, 152.9732],
  [-26.6930, 152.9767],
  [-26.6931, 152.9781],
  [-26.6923, 152.9829],
  [-26.6912, 152.9872],
  [-26.6910, 152.9877],
  [-26.6909, 152.9907],
  [-26.6916, 152.9951],
  [-26.6936, 153.0017],
  [-26.6937, 153.0022],
  [-26.6934, 153.0029],
  [-26.6918, 153.0057],
  [-26.6914, 153.0061],
  [-26.6907, 153.0067],
  [-26.6902, 153.0071],
  [-26.6895, 153.0096],
  [-26.6892, 153.0105],
  [-26.6892, 153.0112],
  [-26.6894, 153.0115],
  [-26.6899, 153.0118],
  [-26.6902, 153.0121],
  [-26.6902, 153.0124],
  [-26.6901, 153.0127],
  [-26.6902, 153.0131],
  [-26.6905, 153.0133],
  [-26.6908, 153.0138],
  [-26.6908, 153.0142],
  [-26.6906, 153.0146],
  [-26.6906, 153.0149],
  [-26.6907, 153.0156],
  [-26.6910, 153.0158],
  [-26.6912, 153.0158],
  [-26.6915, 153.0154],
  [-26.6916, 153.0151],
  [-26.6918, 153.0150],
  [-26.6921, 153.0152],
  [-26.6920, 153.0161],
  [-26.6921, 153.0164],
  [-26.6923, 153.0167],
  [-26.6923, 153.0172],
  [-26.6921, 153.0176],
  [-26.6920, 153.0180],
  [-26.6918, 153.0183],
  [-26.6914, 153.0183],
  [-26.6906, 153.0186],
  [-26.6901, 153.0186],
  [-26.6896, 153.0184],
  [-26.6893, 153.0185],
  [-26.6892, 153.0186],
  [-26.6893, 153.0194],
  [-26.6897, 153.0201],
  [-26.6898, 153.0204],
  [-26.6895, 153.0211],
  [-26.6894, 153.0216],
  [-26.6894, 153.0217],
  [-26.6896, 153.0221],
  [-26.6899, 153.0222],
  [-26.6902, 153.0221],
  [-26.6906, 153.0221],
  [-26.6909, 153.0223],
  [-26.6912, 153.0224],
  [-26.6914, 153.0226],
  [-26.6914, 153.0231],
  [-26.6914, 153.0237],
  [-26.6914, 153.0239],
  [-26.6914, 153.0243],
  [-26.6914, 153.0246],
  [-26.6920, 153.0248],
  [-26.6922, 153.0252],
  [-26.6922, 153.0255],
  [-26.6923, 153.0257],
  [-26.6927, 153.0259],
  [-26.6928, 153.0262],
  [-26.6926, 153.0267],
  [-26.6932, 153.0291],
  [-26.6936, 153.0320],
  [-26.6929, 153.0353],
  [-26.6924, 153.0360],
  [-26.6920, 153.0366],
  [-26.6917, 153.0369],
  [-26.6915, 153.0373],
  [-26.6915, 153.0376],
  [-26.6914, 153.0380],
  [-26.6909, 153.0383],
  [-26.6904, 153.0388],
  [-26.6897, 153.0392],
  [-26.6896, 153.0396],
  [-26.6895, 153.0408],
  [-26.6849, 153.0528]
];

export const buderim: Line = {
  name: 'Buderim Tramway',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
