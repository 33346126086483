import { Line } from "../../../trackTypes";

const darraRichlands = [
  [-27.5683, 152.9474],
  [-27.5695, 152.9461],
  [-27.5707, 152.9454],
  [-27.5721, 152.9450],
  [-27.5732, 152.9449],
  [-27.5777, 152.9457],
  [-27.5793, 152.9463],
  [-27.5807, 152.9471],
  [-27.5821, 152.9478],
  [-27.5837, 152.9480],
  [-27.5870, 152.9482],
  [-27.5891, 152.9487],
  [-27.5905, 152.9487],
  [-27.5917, 152.9484],
  [-27.5945, 152.9474],
  [-27.5963, 152.9467]
];

const richlandsSpringfield = [
  [-27.5963, 152.9467],
  [-27.5987, 152.9458],
  [-27.6021, 152.9448],
  [-27.6066, 152.9440],
  [-27.6137, 152.9435],
  [-27.6235, 152.9424],
  [-27.6256, 152.9418],
  [-27.6272, 152.9411],
  [-27.6296, 152.9397],
  [-27.6327, 152.9388],
  [-27.6387, 152.9382],
  [-27.6414, 152.9377],
  [-27.6439, 152.9364],
  [-27.6455, 152.9350],
  [-27.6635, 152.9187],
  [-27.6705, 152.9117],
  [-27.6720, 152.9096],
  [-27.6739, 152.9054],
  [-27.6744, 152.9013]
];

export const springfield: Line = {
  name: 'Springfield',
  state: 'QLD',
  segments: [
    {
      segments: [darraRichlands],
      history: {
        opened: {
          date: '2011-01-17',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [richlandsSpringfield],
      history: {
        opened: {
          date: '2013-12-02',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
