import { Line } from "../../../trackTypes";

const line = [
  [-28.7483, 153.4434],
  [-28.7488, 153.4440],
  [-28.7497, 153.4448],
  [-28.7506, 153.4450],
  [-28.7515, 153.4449],
  [-28.7533, 153.4442],
  [-28.7553, 153.4444],
  [-28.7574, 153.4455],
  [-28.7672, 153.4550],
  [-28.7684, 153.4556],
  [-28.7694, 153.4555],
  [-28.7708, 153.4550],
  [-28.7720, 153.4550],
  [-28.7729, 153.4555],
  [-28.7763, 153.4584],
  [-28.7768, 153.4596],
  [-28.7767, 153.4608],
  [-28.7761, 153.4616],
  [-28.7756, 153.4626],
  [-28.7754, 153.4636],
  [-28.7756, 153.4649],
  [-28.7761, 153.4658],
  [-28.7765, 153.4666],
  [-28.7766, 153.4675],
  [-28.7771, 153.4683],
  [-28.7784, 153.4693],
  [-28.7791, 153.4708],
  [-28.7791, 153.4723],
  [-28.7786, 153.4736],
  [-28.7774, 153.4750],
  [-28.7771, 153.4757],
  [-28.7771, 153.4782],
  [-28.7775, 153.4795],
  [-28.7783, 153.4804],
  [-28.7795, 153.4815],
  [-28.7810, 153.4846],
  [-28.7817, 153.4852],
  [-28.7824, 153.4855],
  [-28.7833, 153.4862],
  [-28.7875, 153.4911],
  [-28.7887, 153.4918],
  [-28.7911, 153.4919],
  [-28.7921, 153.4923],
  [-28.7927, 153.4928],
  [-28.7938, 153.4930],
  [-28.7948, 153.4927],
  [-28.7966, 153.4921],
  [-28.7992, 153.4923],
  [-28.8020, 153.4933],
  [-28.8033, 153.4935],
  [-28.8043, 153.4931],
  [-28.8054, 153.4914],
  [-28.8060, 153.4909],
  [-28.8069, 153.4907],
  [-28.8091, 153.4908],
  [-28.8108, 153.4902],
  [-28.8119, 153.4903],
  [-28.8126, 153.4909],
  [-28.8147, 153.4935],
  [-28.8160, 153.4942],
  [-28.8382, 153.4988],
  [-28.8393, 153.4993],
  [-28.8430, 153.5023],
  [-28.8442, 153.5038],
  [-28.8605, 153.5301]
];

export const ballina: Line = {
  name: 'Ballina',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1930-08-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-06-11',
          status: 'closed'
        }]
      }
    }
  ]
};
