import { Line } from "../../../trackTypes";

const fitzroyWeymouthStreet = [
  [-39.05051, 174.10460],
  [-39.04934, 174.10235],
  [-39.04932, 174.10222],
  [-39.04933, 174.10208],
  [-39.05476, 174.08409],
  [-39.05712, 174.07622],
  [-39.05722, 174.07586],
  [-39.05841, 174.07195],
  [-39.05841, 174.07183],
  [-39.05837, 174.07175],
  [-39.05670, 174.07092],
  [-39.05661, 174.07080],
  [-39.05658, 174.07063],
  [-39.05657, 174.06907],
  [-39.05658, 174.06881],
  [-39.05663, 174.06857],
  [-39.05766, 174.06520]
];

const weymouthStreetBreakwater = [
  [-39.05766, 174.06520],
  [-39.05932, 174.05969],
  [-39.06033, 174.05568],
  [-39.06083, 174.05350],
  [-39.06084, 174.05317],
  [-39.06076, 174.05246],
  [-39.06076, 174.05204],
  [-39.06084, 174.05157],
  [-39.06102, 174.05108],
  [-39.06119, 174.05078],
  [-39.06145, 174.05041],
  [-39.06217, 174.04892],
  [-39.06228, 174.04857],
  [-39.06292, 174.04605],
  [-39.06301, 174.04546],
  [-39.06303, 174.04486],
  [-39.06282, 174.04064],
  [-39.06284, 174.04024],
  [-39.06300, 174.03919],
  [-39.06300, 174.03884],
  [-39.06293, 174.03847],
  [-39.06279, 174.03798],
  [-39.06267, 174.03767],
  [-39.06006, 174.03271],
  [-39.05987, 174.03246],
  [-39.05955, 174.03219],
  [-39.05830, 174.03127]
];

const egmontStreetMorleyStreet = [
  [-39.05841, 174.07195],
  [-39.06107, 174.06328]
];

const morleyStreetDavidStreet = [
  [-39.06107, 174.06328],
  [-39.06115, 174.06317],
  [-39.06125, 174.06315],
  [-39.06673, 174.06588],
  [-39.06687, 174.06590],
  [-39.06698, 174.06585],
  [-39.06708, 174.06571],
  [-39.06728, 174.06506],
  [-39.06738, 174.06486],
  [-39.06751, 174.06472],
  [-39.06864, 174.06393],
  [-39.07090, 174.06253],
  [-39.07111, 174.06238],
  [-39.07125, 174.06223],
  [-39.07390, 174.05885]
];

const pukekuraParkJunction = [
  [-39.05712, 174.07622],
  [-39.05720, 174.07614],
  [-39.05728, 174.07611]
];

const pukekuraPark = [
  [-39.05722, 174.07586],
  [-39.05723, 174.07602],
  [-39.05728, 174.07611],
  [-39.06223, 174.07856],
  [-39.06242, 174.07859],
  [-39.06248, 174.07855]
];

export const newPlymouthTrams: Line = {
  name: 'New Plymouth Trams',
  state: 'NZ',
  segments: [
    {
      segments: [pukekuraPark, pukekuraParkJunction],
      history: {
        opened: {
          date: '1924-07-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fitzroyWeymouthStreet],
      history: {
        opened: {
          date: '1916-03-10',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [weymouthStreetBreakwater],
      history: {
        opened: {
          date: '1916-04-21',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [egmontStreetMorleyStreet],
      history: {
        opened: {
          date: '1916-05-20',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morleyStreetDavidStreet],
      history: {
        opened: {
          date: '1923-11-20',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
