import { Line } from "../../trackTypes";

const line = [
  [-32.0040, 115.5501],
  [-32.0042, 115.5503],
  [-32.0045, 115.5506],
  [-32.0052, 115.5508],
  [-32.0059, 115.5506],
  [-32.0067, 115.5499],
  [-32.0071, 115.5493],
  [-32.0073, 115.5484],
  [-32.0068, 115.5447],
  [-32.0068, 115.5441],
  [-32.0069, 115.5421],
  [-32.0071, 115.5408],
  [-32.0079, 115.5390],
  [-32.0080, 115.5382],
  [-32.0078, 115.5371],
  [-32.0074, 115.5363],
  [-32.0069, 115.5358],
  [-32.0067, 115.5356],
  [-32.0066, 115.5352],
  [-32.0066, 115.5326],
  [-32.0067, 115.5319],
  [-32.0072, 115.5309],
  [-32.0076, 115.5291],
  [-32.0074, 115.5283],
  [-32.0067, 115.5269],
  [-32.0065, 115.5260],
  [-32.0067, 115.5251],
  [-32.0072, 115.5243],
  [-32.0077, 115.5238],
  [-32.0080, 115.5234],
  [-32.0082, 115.5229],
  [-32.0087, 115.5199],
  [-32.0091, 115.5187],
  [-32.0096, 115.5179],
  [-32.0099, 115.5176],
  [-32.0102, 115.5174],
  [-32.0109, 115.5172],
  [-32.0112, 115.5170],
  [-32.0114, 115.5167],
  [-32.0115, 115.5163],
  [-32.0115, 115.5159],
  [-32.0112, 115.5154],
  [-32.0109, 115.5150],
  [-32.0105, 115.5149],
  [-32.0101, 115.5149],
  [-32.0098, 115.5151],
  [-32.0089, 115.5159],
  [-32.0086, 115.5161],
  [-32.0081, 115.5166],
  [-32.0078, 115.5168],
  [-32.0073, 115.5169]
];

const wharf = [
  [-32.0040, 115.5501],
  [-32.0034, 115.5495],
  [-32.0031, 115.5493],
  [-32.0027, 115.5494],
  [-32.0022, 115.5495],
  [-32.0012, 115.5499]
];

const townDeviation = [
  [-32.0040, 115.5501],
  [-32.0039, 115.5498],
  [-32.0038, 115.5494],
  [-32.0039, 115.5480],
  [-32.0041, 115.5472],
  [-32.0041, 115.5467],
  [-32.0040, 115.5462],
  [-32.0020, 115.5435],
  [-32.0011, 115.5426],
  [-31.9995, 115.5414]
];

export const rottnestIsland: Line = {
  name: 'Rottnest Island',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1939-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }, {
          date: '2000-02-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [wharf],
      history: {
        opened: {
          date: '1939-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [townDeviation],
      history: {
        opened: {
          date: '2000-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        }
      }
    }
  ]
};
