import { Line } from "../../../trackTypes";

const milesGiligulgul = [
  [-26.6565, 150.1782],
  [-26.6532, 150.1745],
  [-26.6519, 150.1737],
  [-26.6398, 150.1715],
  [-26.6267, 150.1722],
  [-26.6248, 150.1720],
  [-26.6131, 150.1693],
  [-26.6015, 150.1643],
  [-26.6000, 150.1633],
  [-26.5988, 150.1620],
  [-26.5966, 150.1589],
  [-26.5949, 150.1575],
  [-26.5765, 150.1504],
  [-26.5727, 150.1488],
  [-26.5711, 150.1476],
  [-26.5700, 150.1463],
  [-26.5686, 150.1437],
  [-26.5674, 150.1426],
  [-26.5662, 150.1421],
  [-26.5642, 150.1419],
  [-26.5630, 150.1414],
  [-26.5579, 150.1386],
  [-26.5420, 150.1275],
  [-26.5410, 150.1272],
  [-26.5395, 150.1273],
  [-26.5387, 150.1269],
  [-26.5382, 150.1263],
  [-26.5372, 150.1239],
  [-26.5359, 150.1228],
  [-26.5319, 150.1219],
  [-26.5298, 150.1206],
  [-26.5245, 150.1162],
  [-26.5215, 150.1153],
  [-26.5187, 150.1155],
  [-26.5133, 150.1134],
  [-26.4568, 150.0774],
  [-26.4298, 150.0600],
  [-26.4235, 150.0560],
  [-26.4079, 150.0488],
  [-26.3966, 150.0455],
  [-26.3914, 150.0422],
  [-26.3904, 150.0408],
  [-26.3893, 150.0376],
  [-26.3885, 150.0366],
  [-26.3876, 150.0358],
  [-26.3864, 150.0354],
  [-26.3776, 150.0352],
  [-26.3761, 150.0355],
  [-26.3720, 150.0370],
  [-26.3692, 150.0374],
  [-26.3673, 150.0381],
  [-26.3661, 150.0390],
  [-26.3642, 150.0407],
  [-26.3621, 150.0422],
  [-26.3563, 150.0455],
  [-26.3552, 150.0465],
  [-26.3531, 150.0497]
];

const giligulgulWandoan = [
  [-26.3531, 150.0497],
  [-26.3507, 150.0535],
  [-26.3483, 150.0557],
  [-26.3460, 150.0567],
  [-26.3436, 150.0569],
  [-26.3425, 150.0575],
  [-26.3421, 150.0584],
  [-26.3419, 150.0597],
  [-26.3414, 150.0606],
  [-26.3409, 150.0610],
  [-26.3387, 150.0622],
  [-26.3371, 150.0624],
  [-26.3076, 150.0573],
  [-26.3062, 150.0574],
  [-26.2968, 150.0598],
  [-26.2948, 150.0598],
  [-26.2885, 150.0593],
  [-26.2746, 150.0546],
  [-26.2696, 150.0517],
  [-26.2659, 150.0480],
  [-26.2645, 150.0475],
  [-26.2619, 150.0477],
  [-26.2608, 150.0475],
  [-26.2580, 150.0462],
  [-26.2566, 150.0450],
  [-26.2552, 150.0425],
  [-26.2519, 150.0381],
  [-26.2501, 150.0365],
  [-26.2470, 150.0350],
  [-26.2457, 150.0339],
  [-26.2430, 150.0309],
  [-26.2422, 150.0295],
  [-26.2416, 150.0277],
  [-26.2402, 150.0258],
  [-26.2382, 150.0246],
  [-26.2364, 150.0245],
  [-26.2350, 150.0250],
  [-26.2338, 150.0257],
  [-26.2320, 150.0260],
  [-26.2303, 150.0252],
  [-26.2292, 150.0240],
  [-26.2268, 150.0189],
  [-26.2260, 150.0180],
  [-26.2250, 150.0176],
  [-26.2172, 150.0167],
  [-26.2122, 150.0153],
  [-26.2107, 150.0145],
  [-26.2097, 150.0137],
  [-26.2050, 150.0100],
  [-26.2038, 150.0096],
  [-26.2026, 150.0100],
  [-26.2016, 150.0109],
  [-26.2000, 150.0141],
  [-26.1987, 150.0148],
  [-26.1934, 150.0153],
  [-26.1921, 150.0151],
  [-26.1910, 150.0144],
  [-26.1866, 150.0110],
  [-26.1859, 150.0107],
  [-26.1853, 150.0107],
  [-26.1846, 150.0108],
  [-26.1835, 150.0106],
  [-26.1823, 150.0097],
  [-26.1815, 150.0094],
  [-26.1805, 150.0094],
  [-26.1797, 150.0090],
  [-26.1752, 150.0036],
  [-26.1737, 150.0025],
  [-26.1723, 150.0022],
  [-26.1691, 150.0022],
  [-26.1682, 150.0021],
  [-26.1673, 150.0016],
  [-26.1666, 150.0010],
  [-26.1643, 149.9983],
  [-26.1584, 149.9891],
  [-26.1571, 149.9878],
  [-26.1551, 149.9866],
  [-26.1542, 149.9865],
  [-26.1530, 149.9869],
  [-26.1522, 149.9869],
  [-26.1514, 149.9865],
  [-26.1510, 149.9861],
  [-26.1478, 149.9821],
  [-26.1360, 149.9724],
  [-26.1342, 149.9701],
  [-26.1297, 149.9639],
  [-26.1287, 149.9631],
  [-26.1275, 149.9627],
  [-26.1257, 149.9627],
  [-26.1244, 149.9626],
  [-26.1231, 149.9619],
  [-26.1214, 149.9597]
];

export const wandoan: Line = {
  name: 'Wandoan',
  state: 'QLD',
  segments: [
    {
      segments: [milesGiligulgul],
      history: {
        opened: {
          date: '1913-12-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [giligulgulWandoan],
      history: {
        opened: {
          date: '1914-12-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
