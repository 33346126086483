import { Line } from "../../trackTypes";

const lavertonWerribee = [
  [-37.8638, 144.7719],
  [-37.8789, 144.7118],
  [-37.8995, 144.6608],
];

const lavertonAltonaJunctionWest = [
  [-37.8638, 144.7719],
  [-37.8615, 144.7804]
];

const westonaLaverton = [
  [-37.8651, 144.8135],
  [-37.8649, 144.8115],
  [-37.8648, 144.8104],
  [-37.8614, 144.7851],
  [-37.8613, 144.7839],
  [-37.8613, 144.7829],
  [-37.8614, 144.7820],
  [-37.8617, 144.7799]
];

const newportGreenwichJunction = [
  [-37.8427, 144.8835],
  [-37.8442, 144.8835],
  [-37.8456, 144.8829]
];

const greenwichJunctionNewportSouth = [
  [-37.8456, 144.8829],
  [-37.8461, 144.8823],
  [-37.8467, 144.8814],
  [-37.8470, 144.8806],
  [-37.8487, 144.8755]
];

const newportSouthAltonaJunctionEast = [
  [-37.8487, 144.8755],
  [-37.8493, 144.8734],
  [-37.8495, 144.8718],
  [-37.8478, 144.8573]
];

const newportWilliamstown = [
  [-37.8487, 144.8755],
  [-37.8492, 144.8792],
  [-37.8500, 144.8815],
  [-37.8513, 144.8838],
  [-37.8531, 144.8857],
  [-37.8551, 144.8880]
];

const altonaJunctionEastAltonaJunctionWest = [
  [-37.8478, 144.8573],
  [-37.8473, 144.8528],
  [-37.8464, 144.8458],
  [-37.8463, 144.8445],
  [-37.8465, 144.8416],
  [-37.8467, 144.8399],
  [-37.8470, 144.8383],
  [-37.8615, 144.7804]
];

const altonaWestona = [
  [-37.8671, 144.8296],
  [-37.8660, 144.8191],
  [-37.8659, 144.8184],
  [-37.8657, 144.8177],
  [-37.8655, 144.8170],
  [-37.8651, 144.8135]
];

const altonaJunctionEastKororoitCreekRoad = [
  [-37.8478, 144.8573],
  [-37.8479, 144.8561],
  [-37.8482, 144.8551],
  [-37.8487, 144.8544],
  [-37.8492, 144.8539],
  [-37.8500, 144.8535],
  [-37.8511, 144.8533],
  [-37.8539, 144.8524]
];

const kororoitCreekRoadWilliamstownRacecourse = [
  [-37.8539, 144.8524],
  [-37.8557, 144.8518],
  [-37.8575, 144.8502],
  [-37.8580, 144.8499],
  [-37.8583, 144.8498],
  [-37.8591, 144.8498]
];

const williamstownRacecourseAltona = [
  [-37.8591, 144.8498],
  [-37.8606, 144.8496],
  [-37.8614, 144.8494],
  [-37.8622, 144.8490],
  [-37.8630, 144.8484],
  [-37.8667, 144.8433],
  [-37.8673, 144.8422],
  [-37.8677, 144.8412],
  [-37.8679, 144.8405],
  [-37.8680, 144.8397],
  [-37.8680, 144.8382],
  [-37.8671, 144.8296]
];

const greenwich = [
  [-37.8456, 144.8829],
  [-37.8447, 144.8838],
  [-37.8444, 144.8855],
  [-37.8456, 144.8978]
];

export const werribee: Line = {
  name: 'werribee',
  state: 'VIC',
  segments: [
    {
      segments: [westonaLaverton],
      history: {
        opened: {
          date: '1985-04-11',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [lavertonAltonaJunctionWest],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-10-22',
          tracks: 2
        }, {
          date: '1983-11-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [lavertonWerribee],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-09-01',
          tracks: 2
        }, {
          date: '1983-11-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [altonaJunctionEastAltonaJunctionWest],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-10-22',
          tracks: 2
        }, {
          date: '1983-11-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [newportGreenwichJunction],
      history: {
        opened: {
          date: '1859-01-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1885-03-18',
          tracks: 2
        }, {
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [greenwichJunctionNewportSouth],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1885-03-18',
          tracks: 2
        }, {
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [newportWilliamstown],
      history: {
        opened: {
          date: '1857-10-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1859-01-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newportSouthAltonaJunctionEast],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-10-22',
          tracks: 2
        }, {
          date: '1920-08-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1995-05-23',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [altonaWestona],
      history: {
        opened: {
          date: '1985-01-20',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [altonaJunctionEastKororoitCreekRoad],
      history: {
        opened: {
          date: '1885-04-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1920-08-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2018-07-24',
          tracks: 2
        }]
      }
    },
    {
      segments: [kororoitCreekRoadWilliamstownRacecourse],
      history: {
        opened: {
          date: '1885-04-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1920-08-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [williamstownRacecourseAltona],
      history: {
        opened: {
          date: '1888-11-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-10-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [greenwich],
      history: {
        opened: {
          date: '1857-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1859-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
