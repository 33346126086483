import { Line } from "../../trackTypes";

const terangMortlake = [
  [-38.2370, 142.8929],
  [-38.2375, 142.8908],
  [-38.2373, 142.8887],
  [-38.2368, 142.8874],
  [-38.2360, 142.8862],
  [-38.2153, 142.8651],
  [-38.2140, 142.8641],
  [-38.2121, 142.8630],
  [-38.1975, 142.8571],
  [-38.1756, 142.8479],
  [-38.1107, 142.8215],
  [-38.0965, 142.8156],
  [-38.0934, 142.8136],
  [-38.0905, 142.8113],
  [-38.0880, 142.8082],
  [-38.0860, 142.8052],
  [-38.0824, 142.7988]
];

export const mortlake: Line = {
  name: 'Mortlake',
  state: 'VIC',
  segments: [
    {
      segments: [terangMortlake],
      history: {
        opened: {
          date: '1890-12-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-08-01',
          status: 'closed'
        }]
      }
    }
  ]
}
