import { Line } from "../../trackTypes";

const line = [
  [-42.7564, 146.6058],
  [-42.7541, 146.6026],
  [-42.7535, 146.6013],
  [-42.7526, 146.6004],
  [-42.7523, 146.5996],
  [-42.7514, 146.5956],
  [-42.7498, 146.5930],
  [-42.7492, 146.5917],
  [-42.7469, 146.5855],
  [-42.7457, 146.5821],
  [-42.7451, 146.5815],
  [-42.7446, 146.5813],
  [-42.7435, 146.5815],
  [-42.7420, 146.5822],
  [-42.7412, 146.5824],
  [-42.7408, 146.5821],
  [-42.7406, 146.5815],
  [-42.7409, 146.5807],
  [-42.7416, 146.5805],
  [-42.7421, 146.5800],
  [-42.7422, 146.5793],
  [-42.7422, 146.5777],
  [-42.7421, 146.5772],
  [-42.7418, 146.5769],
  [-42.7417, 146.5763],
  [-42.7418, 146.5757],
  [-42.7424, 146.5749],
  [-42.7428, 146.5747],
  [-42.7438, 146.5756],
  [-42.7443, 146.5757],
  [-42.7446, 146.5755],
  [-42.7449, 146.5748]
];

export const nicolsSpur: Line = {
  name: 'Nicols Spur',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1941-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
