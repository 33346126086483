import { Line } from "../../trackTypes";

const sunshineAlbionJunction = [
  [-37.7870, 144.8318],
  [-37.7753, 144.8230]
];

const albionJunctionStAlbans = [
  [-37.7753, 144.8230],
  [-37.7445, 144.7998]
];

const stAlbansSydenham = [
  [-37.7445, 144.7998],
  [-37.7425, 144.7983],
  [-37.7411, 144.7976],
  [-37.7400, 144.7971],
  [-37.7272, 144.7930],
  [-37.7258, 144.7925],
  [-37.7236, 144.7914],
  [-37.7213, 144.7899],
  [-37.6954, 144.7696]
];

const sydenhamSunbury = [
  [-37.6954, 144.7696],
  [-37.6930, 144.7678],
  [-37.6359, 144.7231],
  [-37.6341, 144.7219],
  [-37.6315, 144.7209],
  [-37.6188, 144.7181],
  [-37.6176, 144.7179],
  [-37.6165, 144.7178],
  [-37.5959, 144.7182],
  [-37.5936, 144.7186],
  [-37.5914, 144.7194],
  [-37.5893, 144.7205],
  [-37.5791, 144.7279]
];

const footscrayWestFootscrayJunction = [
  [-37.8012, 144.9029],
  [-37.8022, 144.9006],
  [-37.8025, 144.8988]
];

const westFootscrayJunctionSunshine = [
  [-37.8025, 144.8988],
  [-37.8028, 144.8954],
  [-37.8027, 144.8943],
  [-37.7975, 144.8480],
  [-37.7974, 144.8472],
  [-37.7969, 144.8449],
  [-37.7966, 144.8435],
  [-37.7953, 144.8404],
  [-37.7936, 144.8376],
  [-37.7913, 144.8350],
  [-37.7870, 144.8318]
];

export const sunbury: Line = {
  name: 'Subury',
  state: 'VIC',
  segments: [
    {
      segments: [stAlbansSydenham],
      history: {
        opened: {
          date: '1859-02-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-01-25',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [sydenhamSunbury],
      history: {
        opened: {
          date: '1859-02-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-11-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [sunshineAlbionJunction],
      history: {
        opened: {
          date: '1859-02-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1859-07-11',
          tracks: 2
        }, {
          date: '1921-10-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [albionJunctionStAlbans],
      history: {
        opened: {
          date: '1859-02-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1859-07-11',
          tracks: 2
        }, {
          date: '1921-10-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [footscrayWestFootscrayJunction],
      history: {
        opened: {
          date: '1859-02-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1859-07-11',
          tracks: 2
        }, {
          date: '2014-04-28',
          tracks: 4
        }, {
          date: '1921-10-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [westFootscrayJunctionSunshine],
      history: {
        opened: {
          date: '1859-02-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1859-07-11',
          tracks: 2
        }, {
          date: '2014-04-28',
          tracks: 4
        }, {
          date: '1921-10-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    }
  ]
};
