import { Line } from "../../trackTypes";

const peterboroughOrroroo = [
  [-32.9740, 138.8363],
  [-32.9745, 138.8312],
  [-32.9745, 138.8298],
  [-32.9742, 138.8289],
  [-32.9713, 138.8247],
  [-32.9705, 138.8231],
  [-32.9681, 138.8155],
  [-32.9681, 138.8130],
  [-32.9695, 138.8093],
  [-32.9696, 138.8071],
  [-32.9682, 138.8046],
  [-32.9508, 138.7901],
  [-32.9493, 138.7887],
  [-32.9376, 138.7717],
  [-32.9353, 138.7701],
  [-32.9331, 138.7695],
  [-32.9216, 138.7699],
  [-32.9179, 138.7690],
  [-32.9150, 138.7671],
  [-32.9122, 138.7633],
  [-32.9096, 138.7611],
  [-32.8991, 138.7561],
  [-32.8966, 138.7544],
  [-32.8494, 138.7074],
  [-32.8444, 138.6995],
  [-32.8430, 138.6983],
  [-32.7974, 138.6744],
  [-32.7697, 138.6549],
  [-32.7658, 138.6528],
  [-32.7583, 138.6439],
  [-32.7333, 138.6226],
  [-32.7315, 138.6194]
];

const orrorooEurelia = [
  [-32.7315, 138.6194],
  [-32.7302, 138.6167],
  [-32.7297, 138.6160],
  [-32.7222, 138.6106],
  [-32.7214, 138.6103],
  [-32.7180, 138.6102],
  [-32.6928, 138.6091],
  [-32.6885, 138.6082],
  [-32.6620, 138.5960],
  [-32.6404, 138.5859],
  [-32.6355, 138.5847],
  [-32.6345, 138.5841],
  [-32.6292, 138.5775],
  [-32.6288, 138.5766],
  [-32.6286, 138.5737],
  [-32.6283, 138.5728],
  [-32.6274, 138.5718],
  [-32.6271, 138.5712],
  [-32.6267, 138.5702],
  [-32.6262, 138.5695],
  [-32.6249, 138.5689],
  [-32.6239, 138.5679],
  [-32.6235, 138.5671],
  [-32.6230, 138.5667],
  [-32.6134, 138.5624],
  [-32.6124, 138.5615],
  [-32.6114, 138.5604],
  [-32.6105, 138.5599],
  [-32.6095, 138.5599],
  [-32.6076, 138.5602],
  [-32.6068, 138.5601],
  [-32.6044, 138.5591],
  [-32.6032, 138.5590],
  [-32.6020, 138.5593],
  [-32.6012, 138.5598],
  [-32.6001, 138.5608],
  [-32.5993, 138.5612],
  [-32.5975, 138.5615],
  [-32.5970, 138.5614],
  [-32.5963, 138.5610],
  [-32.5942, 138.5583],
  [-32.5925, 138.5574],
  [-32.5896, 138.5570],
  [-32.5887, 138.5566],
  [-32.5875, 138.5558],
  [-32.5865, 138.5555],
  [-32.5858, 138.5556],
  [-32.5830, 138.5561],
  [-32.5819, 138.5567],
  [-32.5806, 138.5580],
  [-32.5798, 138.5583],
  [-32.5790, 138.5582],
  [-32.5785, 138.5579],
  [-32.5778, 138.5572],
  [-32.5770, 138.5570],
  [-32.5713, 138.5575],
  [-32.5592, 138.5605],
  [-32.5583, 138.5605],
  [-32.5575, 138.5604],
  [-32.5545, 138.5591]
];

const eureliaoQuorn = [
  [-32.5545, 138.5591],
  [-32.5487, 138.5567],
  [-32.5379, 138.5520],
  [-32.5200, 138.5495],
  [-32.5105, 138.5459],
  [-32.5083, 138.5458],
  [-32.5016, 138.5475],
  [-32.4993, 138.5475],
  [-32.4971, 138.5469],
  [-32.4755, 138.5344],
  [-32.4620, 138.5212],
  [-32.4606, 138.5205],
  [-32.4577, 138.5201],
  [-32.4560, 138.5193],
  [-32.4525, 138.5164],
  [-32.4468, 138.5138],
  [-32.4444, 138.5135],
  [-32.4434, 138.5130],
  [-32.4397, 138.5097],
  [-32.4389, 138.5092],
  [-32.4354, 138.5081],
  [-32.4335, 138.5071],
  [-32.4299, 138.5041],
  [-32.4191, 138.4987],
  [-32.4184, 138.4980],
  [-32.4181, 138.4973],
  [-32.4174, 138.4928],
  [-32.4175, 138.4916],
  [-32.4209, 138.4847],
  [-32.4219, 138.4833],
  [-32.4294, 138.4762],
  [-32.4307, 138.4743],
  [-32.4321, 138.4715],
  [-32.4330, 138.4701],
  [-32.4354, 138.4674],
  [-32.4365, 138.4654],
  [-32.4388, 138.4585],
  [-32.4389, 138.4575],
  [-32.4388, 138.4564],
  [-32.4388, 138.4564],
  [-32.4370, 138.4516],
  [-32.4369, 138.4504],
  [-32.4371, 138.4493],
  [-32.4408, 138.4423],
  [-32.4419, 138.4412],
  [-32.4438, 138.4402],
  [-32.4449, 138.4391],
  [-32.4472, 138.4344],
  [-32.4481, 138.4329],
  [-32.4517, 138.4285],
  [-32.4523, 138.4271],
  [-32.4525, 138.4256],
  [-32.4531, 138.4243],
  [-32.4576, 138.4182],
  [-32.4594, 138.4166],
  [-32.4718, 138.4100],
  [-32.4843, 138.4032],
  [-32.4860, 138.4015],
  [-32.4908, 138.3929],
  [-32.4938, 138.3827],
  [-32.4950, 138.3806],
  [-32.4971, 138.3781],
  [-32.4980, 138.3763],
  [-32.4985, 138.3710],
  [-32.4985, 138.3693],
  [-32.4982, 138.3674],
  [-32.4982, 138.3659],
  [-32.4985, 138.3640],
  [-32.5156, 138.3090],
  [-32.5159, 138.3066],
  [-32.5156, 138.3047],
  [-32.4456, 138.1690],
  [-32.4432, 138.1662],
  [-32.3760, 138.1230],
  [-32.3745, 138.1225],
  [-32.3703, 138.1229],
  [-32.3690, 138.1225],
  [-32.3679, 138.1216],
  [-32.3671, 138.1203],
  [-32.3645, 138.1086],
  [-32.3641, 138.0952],
  [-32.3632, 138.0922],
  [-32.3477, 138.0697],
  [-32.3461, 138.0662],
  [-32.3427, 138.0510],
  [-32.3425, 138.0476],
  [-32.3428, 138.0454],
  [-32.3438, 138.0428]
];

export const peterboroughQuorn: Line = {
  name: 'PeterboroughQuorn',
  state: 'SA',
  segments: [
    {
      segments: [peterboroughOrroroo],
      history: {
        opened: {
          date: '1881-11-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [orrorooEurelia],
      history: {
        opened: {
          date: '1882-05-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eureliaoQuorn],
      history: {
        opened: {
          date: '1882-05-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-03-03',
          status: 'closed'
        }]
      }
    }
  ]
};
