import { Line } from "../../trackTypes";

const bendigoNorthBendigoJunction = [
  [-36.7655, 144.2830],
  [-36.7628, 144.2866],
  [-36.7622, 144.2872],
  [-36.7614, 144.2880],
  [-36.7598, 144.2890],
  [-36.7591, 144.2893],
  [-36.7531, 144.2912],
  [-36.7520, 144.2916],
  [-36.7511, 144.2921],
  [-36.7502, 144.2926]
];

const northBendigoJunctionEchuca = [
  [-36.7502, 144.2926],
  [-36.7487, 144.2938],
  [-36.7474, 144.2948],
  [-36.7438, 144.2975],
  [-36.7169, 144.3181],
  [-36.7151, 144.3190],
  [-36.7136, 144.3195],
  [-36.7036, 144.3215],
  [-36.7018, 144.3222],
  [-36.6859, 144.3292],
  [-36.6841, 144.3301],
  [-36.6824, 144.3315],
  [-36.6812, 144.3329],
  [-36.6802, 144.3345],
  [-36.6795, 144.3363],
  [-36.6782, 144.3396],
  [-36.6775, 144.3409],
  [-36.6737, 144.3468],
  [-36.6728, 144.3484],
  [-36.6716, 144.3513],
  [-36.6578, 144.3957],
  [-36.6568, 144.3982],
  [-36.6562, 144.3996],
  [-36.6460, 144.4218],
  [-36.6303, 144.4557],
  [-36.6294, 144.4578],
  [-36.6290, 144.4592],
  [-36.6059, 144.5320],
  [-36.6041, 144.5361],
  [-36.6013, 144.5402],
  [-36.5979, 144.5436],
  [-36.5958, 144.5451],
  [-36.5272, 144.5852],
  [-36.4918, 144.6097],
  [-36.4700, 144.6245],
  [-36.3725, 144.6913],
  [-36.3606, 144.6994],
  [-36.3591, 144.7002],
  [-36.3580, 144.7008],
  [-36.3415, 144.7087],
  [-36.2323, 144.7527],
  [-36.2206, 144.7574],
  [-36.2176, 144.7580],
  [-36.2152, 144.7580],
  [-36.1541, 144.7560],
  [-36.1499, 144.7555],
  [-36.1410, 144.7544],
  [-36.1310, 144.7531],
  [-36.1260, 144.7526]
];

const echucaNswBorder = [
  [-36.1260, 144.7526],
  [-36.1247, 144.7527],
  [-36.1233, 144.7532],
  [-36.1224, 144.7534]
]

export const echuca: Line = {
  name: 'Echuca',
  state: 'VIC',
  segments: [
    {
      segments: [bendigoNorthBendigoJunction],
      history: {
        opened: {
          date: '1864-09-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-09-19',
          tracks: 2
        }, {
          date: '1989-11-28',
          tracks: 1
        }]
      }
    },
    {
      segments: [northBendigoJunctionEchuca],
      history: {
        opened: {
          date: '1864-09-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [echucaNswBorder],
      history: {
        opened: {
          date: '1876-07-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-08-06',
          status: 'closed'
        }, {
          date: '2013-01-01',
          status: 'open'
        }]
      }
    }
  ]
};
