import { Line } from "../../trackTypes";

const cootamundraTemora = [
  [-34.6323, 148.0409],
  [-34.6326, 148.0389],
  [-34.6325, 148.0376],
  [-34.6319, 148.0360],
  [-34.6260, 148.0271],
  [-34.6214, 148.0203],
  [-34.6202, 148.0178],
  [-34.6184, 148.0133],
  [-34.6166, 148.0109],
  [-34.6145, 148.0093],
  [-34.6120, 148.0085],
  [-34.6004, 148.0078],
  [-34.5987, 148.0079],
  [-34.5917, 148.0091],
  [-34.5903, 148.0090],
  [-34.5890, 148.0086],
  [-34.5772, 148.0034],
  [-34.5755, 148.0031],
  [-34.5742, 148.0033],
  [-34.5728, 148.0031],
  [-34.5716, 148.0024],
  [-34.5704, 148.0010],
  [-34.5685, 147.9997],
  [-34.5630, 147.9983],
  [-34.5611, 147.9970],
  [-34.5594, 147.9951],
  [-34.5572, 147.9937],
  [-34.5389, 147.9866],
  [-34.5365, 147.9848],
  [-34.5337, 147.9833],
  [-34.5308, 147.9825],
  [-34.5288, 147.9822],
  [-34.5267, 147.9814],
  [-34.5134, 147.9753],
  [-34.5120, 147.9745],
  [-34.5062, 147.9693],
  [-34.5046, 147.9674],
  [-34.5035, 147.9657],
  [-34.5016, 147.9641],
  [-34.4949, 147.9611],
  [-34.4937, 147.9598],
  [-34.4931, 147.9579],
  [-34.4931, 147.9561],
  [-34.4937, 147.9545],
  [-34.4979, 147.9481],
  [-34.4986, 147.9463],
  [-34.5029, 147.9032],
  [-34.5028, 147.8955],
  [-34.5002, 147.8759],
  [-34.4986, 147.8685],
  [-34.4983, 147.8671],
  [-34.4816, 147.8224],
  [-34.4806, 147.8182],
  [-34.4734, 147.7613],
  [-34.4735, 147.7588],
  [-34.4741, 147.7561],
  [-34.4742, 147.7544],
  [-34.4735, 147.7523],
  [-34.4724, 147.7509],
  [-34.4714, 147.7494],
  [-34.4703, 147.7419],
  [-34.4703, 147.7367],
  [-34.4715, 147.7305],
  [-34.4716, 147.7294],
  [-34.4713, 147.7274],
  [-34.4704, 147.7253],
  [-34.4671, 147.7211],
  [-34.4663, 147.7195],
  [-34.4659, 147.7171],
  [-34.4663, 147.7092],
  [-34.4658, 147.7072],
  [-34.4647, 147.7055],
  [-34.4621, 147.7034],
  [-34.4607, 147.7028],
  [-34.4590, 147.7027],
  [-34.4578, 147.7021],
  [-34.4566, 147.7008],
  [-34.4553, 147.6973],
  [-34.4552, 147.6961],
  [-34.4554, 147.6947],
  [-34.4561, 147.6931],
  [-34.4576, 147.6911],
  [-34.4580, 147.6892],
  [-34.4584, 147.6877],
  [-34.4593, 147.6861],
  [-34.4602, 147.6847],
  [-34.4606, 147.6828],
  [-34.4604, 147.6811],
  [-34.4582, 147.6758],
  [-34.4578, 147.6742],
  [-34.4555, 147.6428],
  [-34.4557, 147.6408],
  [-34.4646, 147.5886],
  [-34.4646, 147.5862],
  [-34.4638, 147.5807],
  [-34.4637, 147.5792],
  [-34.4641, 147.5773],
  [-34.4664, 147.5713],
  [-34.4669, 147.5688],
  [-34.4666, 147.5657],
  [-34.4650, 147.5610],
  [-34.4636, 147.5589],
  [-34.4589, 147.5543],
  [-34.4578, 147.5526],
  [-34.4567, 147.5500],
  [-34.4563, 147.5472],
  [-34.4564, 147.5429],
  [-34.4560, 147.5404],
  [-34.4537, 147.5340],
  [-34.4527, 147.5320],
  [-34.4503, 147.5301],
  [-34.4458, 147.5282]
];

const temoraWyalong = [
  [-34.4458, 147.5282],
  [-34.4441, 147.5274],
  [-34.4400, 147.5263],
  [-34.4369, 147.5257],
  [-34.4324, 147.5253],
  [-34.4298, 147.5249],
  [-34.4271, 147.5237],
  [-34.4191, 147.5188],
  [-34.4179, 147.5178],
  [-34.4153, 147.5148],
  [-34.4134, 147.5134],
  [-34.3663, 147.4917],
  [-34.3135, 147.4674],
  [-34.2504, 147.4381],
  [-34.2060, 147.4176],
  [-34.1746, 147.4105],
  [-34.1725, 147.4097],
  [-34.1613, 147.4032],
  [-34.1596, 147.4017],
  [-34.1584, 147.3997],
  [-34.1536, 147.3878],
  [-34.1521, 147.3856],
  [-34.1503, 147.3844],
  [-34.1449, 147.3816],
  [-34.1418, 147.3800],
  [-34.1341, 147.3779],
  [-34.1317, 147.3768],
  [-34.1137, 147.3639],
  [-34.1125, 147.3627],
  [-34.1106, 147.3606],
  [-34.1075, 147.3592],
  [-34.1057, 147.3583],
  [-34.0838, 147.3428],
  [-34.0212, 147.3124],
  [-34.0181, 147.3106],
  [-33.9506, 147.2664],
  [-33.9460, 147.2639],
  [-33.9386, 147.2590],
  [-33.9363, 147.2563],
  [-33.9350, 147.2531],
  [-33.9330, 147.2333],
  [-33.9330, 147.2291],
  [-33.9342, 147.2196]
];

const wyalongLakeCargellio = [
  [-33.9342, 147.2196],
  [-33.9347, 147.2159],
  [-33.9347, 147.2121],
  [-33.9341, 147.2085],
  [-33.9333, 147.2070],
  [-33.9253, 147.1984],
  [-33.9241, 147.1977],
  [-33.9212, 147.1965],
  [-33.9150, 147.1940],
  [-33.9128, 147.1929],
  [-33.8653, 147.1601],
  [-33.8075, 147.1196],
  [-33.7989, 147.1131],
  [-33.7966, 147.1120],
  [-33.7922, 147.1110],
  [-33.7904, 147.1102],
  [-33.7774, 147.1030],
  [-33.7688, 147.0997],
  [-33.7478, 147.0893],
  [-33.7432, 147.0882],
  [-33.7385, 147.0883],
  [-33.7358, 147.0879],
  [-33.7333, 147.0866],
  [-33.7300, 147.0840],
  [-33.7277, 147.0830],
  [-33.7227, 147.0818],
  [-33.7105, 147.0772],
  [-33.7084, 147.0755],
  [-33.6779, 147.0379],
  [-33.6383, 146.9691],
  [-33.6337, 146.9557],
  [-33.6337, 146.9557],
  [-33.6309, 146.9479],
  [-33.6304, 146.9455],
  [-33.6294, 146.9369],
  [-33.6289, 146.9350],
  [-33.6123, 146.8838],
  [-33.6104, 146.8804],
  [-33.5966, 146.8661],
  [-33.5836, 146.8526],
  [-33.5615, 146.8361],
  [-33.5465, 146.8263],
  [-33.5434, 146.8254],
  [-33.5391, 146.8258],
  [-33.5362, 146.8255],
  [-33.5336, 146.8245],
  [-33.5230, 146.8181],
  [-33.5213, 146.8175],
  [-33.5063, 146.8145],
  [-33.5040, 146.8131],
  [-33.5026, 146.8110],
  [-33.5020, 146.8089],
  [-33.5012, 146.8072],
  [-33.4995, 146.8053],
  [-33.4902, 146.7989],
  [-33.4874, 146.7979],
  [-33.4824, 146.7973],
  [-33.4806, 146.7966],
  [-33.4792, 146.7956],
  [-33.4671, 146.7812],
  [-33.4494, 146.7652],
  [-33.4338, 146.7454],
  [-33.4211, 146.7289],
  [-33.4189, 146.7245],
  [-33.3968, 146.6429],
  [-33.3963, 146.6399],
  [-33.3949, 146.6152],
  [-33.3754, 146.5289],
  [-33.3406, 146.4419],
  [-33.3259, 146.4193],
  [-33.3246, 146.4161],
  [-33.3239, 146.4132],
  [-33.3217, 146.3933],
  [-33.3206, 146.3885],
  [-33.3182, 146.3828],
  [-33.3144, 146.3778],
  [-33.3137, 146.3765],
  [-33.3126, 146.3738],
  [-33.3104, 146.3714],
  [-33.3076, 146.3701],
  [-33.2994, 146.3685]
];

const cootamundraNorthJunctionCootamunduraWest = [
  [-34.6319, 148.0360],
  [-34.6327, 148.0366],
  [-34.6337, 148.0369],
  [-34.6344, 148.0367],
  [-34.6356, 148.0361]
];

export const lakeCargellio: Line = {
  name: 'Lake Cargellio',
  state: 'NSW',
  segments: [
    {
      segments: [cootamundraTemora],
      history: {
        opened: {
          date: '1893-09-01',
          tracks:1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [temoraWyalong],
      history: {
        opened: {
          date: '1903-12-02',
          tracks:1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wyalongLakeCargellio],
      history: {
        opened: {
          date: '1917-11-13',
          tracks:1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cootamundraNorthJunctionCootamunduraWest],
      history: {
        opened: {
          date: '1911-03-22',
          tracks:1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
