import { Line } from "../../trackTypes";

const portAugustaDeviation = [
  [-32.5004, 137.8299],
  [-32.5001, 137.8309],
  [-32.4994, 137.8318],
  [-32.4988, 137.8322],
  [-32.4983, 137.8329],
  [-32.4972, 137.8348],
  [-32.4971, 137.8355],
  [-32.4971, 137.8363],
  [-32.4975, 137.8371],
  [-32.4980, 137.8377],
  [-32.4988, 137.8380],
  [-32.4993, 137.8384],
  [-32.4996, 137.8388],
  [-32.4998, 137.8396]
];

const portAugustaClosed = [
  [-32.5004, 137.8299],
  [-32.5003, 137.8313],
  [-32.4998, 137.8396]
];

const stirlingNorthWoolshedFlat = [
  [-32.4998, 137.8396],
  [-32.4975, 137.8765],
  [-32.4977, 137.8788],
  [-32.4988, 137.8825],
  [-32.4990, 137.8843],
  [-32.4986, 137.8857],
  [-32.4855, 137.9068],
  [-32.4836, 137.9086],
  [-32.4817, 137.9096],
  [-32.4792, 137.9118],
  [-32.4788, 137.9128],
  [-32.4782, 137.9133],
  [-32.4775, 137.9135],
  [-32.4752, 137.9146],
  [-32.4748, 137.9151],
  [-32.4744, 137.9160],
  [-32.4739, 137.9164],
  [-32.4723, 137.9171],
  [-32.4718, 137.9178],
  [-32.4716, 137.9185],
  [-32.4718, 137.9192],
  [-32.4724, 137.9203],
  [-32.4725, 137.9217],
  [-32.4728, 137.9226],
  [-32.4733, 137.9234],
  [-32.4734, 137.9242],
  [-32.4732, 137.9259],
  [-32.4733, 137.9275],
  [-32.4730, 137.9284],
  [-32.4724, 137.9291],
  [-32.4710, 137.9318],
  [-32.4711, 137.9332],
  [-32.4710, 137.9337],
  [-32.4711, 137.9342],
  [-32.4716, 137.9350],
  [-32.4718, 137.9359],
  [-32.4717, 137.9377],
  [-32.4720, 137.9386],
  [-32.4724, 137.9392],
  [-32.4726, 137.9401],
  [-32.4724, 137.9411],
  [-32.4719, 137.9420],
  [-32.4714, 137.9433],
  [-32.4714, 137.9446],
  [-32.4718, 137.9464],
  [-32.4717, 137.9475],
  [-32.4708, 137.9509],
  [-32.4707, 137.9521],
  [-32.4709, 137.9534],
  [-32.4707, 137.9547],
  [-32.4693, 137.9574],
  [-32.4691, 137.9581],
  [-32.4689, 137.9597],
  [-32.4689, 137.9604],
  [-32.4693, 137.9615],
  [-32.4693, 137.9623],
  [-32.4690, 137.9635],
  [-32.4691, 137.9645],
  [-32.4694, 137.9654],
  [-32.4695, 137.9662],
  [-32.4693, 137.9669],
  [-32.4682, 137.9686],
  [-32.4675, 137.9690],
  [-32.4670, 137.9690],
  [-32.4664, 137.9687],
  [-32.4656, 137.9687],
  [-32.4649, 137.9694],
  [-32.4648, 137.9703],
  [-32.4649, 137.9711],
  [-32.4648, 137.9721],
  [-32.4641, 137.9729],
  [-32.4633, 137.9730],
  [-32.4625, 137.9725],
  [-32.4618, 137.9714],
  [-32.4613, 137.9711],
  [-32.4606, 137.9710],
  [-32.4577, 137.9718],
  [-32.4568, 137.9726],
  [-32.4564, 137.9734],
  [-32.4560, 137.9739],
  [-32.4537, 137.9753]
];

const woolshedFlatQuorn = [
  [-32.4537, 137.9753],
  [-32.4530, 137.9757],
  [-32.4522, 137.9760],
  [-32.4518, 137.9762],
  [-32.4511, 137.9769],
  [-32.4507, 137.9771],
  [-32.4502, 137.9772],
  [-32.4470, 137.9784],
  [-32.4466, 137.9784],
  [-32.4461, 137.9783],
  [-32.4456, 137.9784],
  [-32.4447, 137.9790],
  [-32.4442, 137.9791],
  [-32.4434, 137.9790],
  [-32.4428, 137.9791],
  [-32.4424, 137.9790],
  [-32.4418, 137.9787],
  [-32.4414, 137.9788],
  [-32.4406, 137.9791],
  [-32.4400, 137.9791],
  [-32.4394, 137.9787],
  [-32.4389, 137.9780],
  [-32.4384, 137.9777],
  [-32.4379, 137.9778],
  [-32.4374, 137.9779],
  [-32.4368, 137.9778],
  [-32.4365, 137.9775],
  [-32.4360, 137.9769],
  [-32.4354, 137.9768],
  [-32.4347, 137.9769],
  [-32.4341, 137.9767],
  [-32.4338, 137.9763],
  [-32.4334, 137.9754],
  [-32.4320, 137.9736],
  [-32.4313, 137.9733],
  [-32.4307, 137.9733],
  [-32.4301, 137.9734],
  [-32.4293, 137.9733],
  [-32.4288, 137.9729],
  [-32.4283, 137.9724],
  [-32.4275, 137.9720],
  [-32.4254, 137.9714],
  [-32.4248, 137.9715],
  [-32.4243, 137.9718],
  [-32.4235, 137.9718],
  [-32.4231, 137.9714],
  [-32.4222, 137.9699],
  [-32.4216, 137.9694],
  [-32.4211, 137.9693],
  [-32.4206, 137.9695],
  [-32.4197, 137.9702],
  [-32.4192, 137.9704],
  [-32.4174, 137.9707],
  [-32.4168, 137.9706],
  [-32.4107, 137.9696],
  [-32.4089, 137.9697],
  [-32.4076, 137.9700],
  [-32.4072, 137.9700],
  [-32.4065, 137.9699],
  [-32.4056, 137.9707],
  [-32.4054, 137.9717],
  [-32.4050, 137.9725],
  [-32.4045, 137.9729],
  [-32.4041, 137.9729],
  [-32.4033, 137.9729],
  [-32.4030, 137.9730],
  [-32.4027, 137.9731],
  [-32.4016, 137.9740],
  [-32.4012, 137.9747],
  [-32.4012, 137.9754],
  [-32.4012, 137.9759],
  [-32.4011, 137.9766],
  [-32.4008, 137.9772],
  [-32.4004, 137.9775],
  [-32.3998, 137.9778],
  [-32.3985, 137.9794],
  [-32.3976, 137.9799],
  [-32.3976, 137.9799],
  [-32.3960, 137.9802],
  [-32.3924, 137.9827],
  [-32.3921, 137.9833],
  [-32.3920, 137.9842],
  [-32.3924, 137.9849],
  [-32.3931, 137.9856],
  [-32.3937, 137.9870],
  [-32.3935, 137.9883],
  [-32.3927, 137.9894],
  [-32.3900, 137.9907],
  [-32.3890, 137.9909],
  [-32.3874, 137.9909],
  [-32.3855, 137.9914],
  [-32.3813, 137.9932],
  [-32.3796, 137.9942],
  [-32.3779, 137.9945],
  [-32.3735, 137.9972],
  [-32.3716, 137.9987],
  [-32.3698, 138.0010],
  [-32.3667, 138.0035],
  [-32.3639, 138.0071],
  [-32.3632, 138.0086],
  [-32.3621, 138.0101],
  [-32.3615, 138.0113],
  [-32.3609, 138.0127],
  [-32.3601, 138.0138],
  [-32.3584, 138.0152],
  [-32.3565, 138.0175],
  [-32.3447, 138.0410]
];

const quornHawker = [
  [-32.3447, 138.0410],
  [-32.3399, 138.0506],
  [-32.3382, 138.0527],
  [-32.3228, 138.0697],
  [-32.2651, 138.1148],
  [-32.2569, 138.1189],
  [-32.2270, 138.1299],
  [-32.2239, 138.1321],
  [-32.1440, 138.2291],
  [-32.1135, 138.2659],
  [-32.1092, 138.2745],
  [-32.1057, 138.2791],
  [-32.1036, 138.2809],
  [-32.1027, 138.2827],
  [-32.1017, 138.2870],
  [-32.0996, 138.2897],
  [-32.0963, 138.2925],
  [-32.0953, 138.2939],
  [-32.0944, 138.2951],
  [-32.0931, 138.2957],
  [-32.0904, 138.2960],
  [-32.0893, 138.2965],
  [-32.0887, 138.2971],
  [-32.0877, 138.2983],
  [-32.0864, 138.2988],
  [-32.0818, 138.2986],
  [-32.0801, 138.2992],
  [-32.0635, 138.3131],
  [-32.0619, 138.3137],
  [-32.0571, 138.3137],
  [-32.0518, 138.3132],
  [-32.0497, 138.3138],
  [-32.0445, 138.3175],
  [-32.0399, 138.3225],
  [-32.0371, 138.3243],
  [-32.0357, 138.3258],
  [-32.0335, 138.3289],
  [-32.0303, 138.3311],
  [-32.0266, 138.3355],
  [-32.0184, 138.3434],
  [-32.0170, 138.3439],
  [-32.0145, 138.3441],
  [-32.0131, 138.3447],
  [-32.0116, 138.3462],
  [-32.0100, 138.3471],
  [-32.0069, 138.3482],
  [-32.0020, 138.3511],
  [-32.0001, 138.3513],
  [-31.9927, 138.3503],
  [-31.9913, 138.3506],
  [-31.9874, 138.3531],
  [-31.9854, 138.3551],
  [-31.9558, 138.3895],
  [-31.9231, 138.4196],
  [-31.9091, 138.4300],
  [-31.9034, 138.4331],
  [-31.9022, 138.4330],
  [-31.9013, 138.4326],
  [-31.8907, 138.4232],
  [-31.8852, 138.4184]
];

const hawkerbrachina = [
  [-31.8852, 138.4184],
  [-31.8815, 138.4150],
  [-31.8299, 138.3849],
  [-31.8257, 138.3836],
  [-31.8173, 138.3822],
  [-31.8005, 138.3797],
  [-31.7990, 138.3789],
  [-31.7984, 138.3782],
  [-31.7966, 138.3750],
  [-31.7964, 138.3737],
  [-31.7959, 138.3695],
  [-31.7953, 138.3686],
  [-31.7943, 138.3684],
  [-31.7930, 138.3691],
  [-31.7923, 138.3690],
  [-31.7917, 138.3685],
  [-31.7910, 138.3677],
  [-31.7901, 138.3676],
  [-31.7892, 138.3684],
  [-31.7882, 138.3683],
  [-31.7874, 138.3676],
  [-31.7872, 138.3667],
  [-31.7875, 138.3657],
  [-31.7901, 138.3627],
  [-31.7906, 138.3615],
  [-31.7903, 138.3602],
  [-31.7883, 138.3572],
  [-31.7872, 138.3545],
  [-31.7842, 138.3499],
  [-31.7834, 138.3492],
  [-31.7820, 138.3485],
  [-31.7815, 138.3477],
  [-31.7805, 138.3451],
  [-31.7798, 138.3443],
  [-31.7791, 138.3439],
  [-31.7780, 138.3433],
  [-31.7755, 138.3405],
  [-31.7747, 138.3400],
  [-31.7738, 138.3399],
  [-31.7726, 138.3402],
  [-31.7714, 138.3400],
  [-31.7697, 138.3393],
  [-31.7672, 138.3392],
  [-31.7632, 138.3403],
  [-31.7620, 138.3401],
  [-31.7608, 138.3390],
  [-31.7586, 138.3358],
  [-31.7580, 138.3354],
  [-31.7569, 138.3352],
  [-31.7399, 138.3394],
  [-31.6626, 138.3746],
  [-31.6295, 138.3896],
  [-31.6267, 138.3903],
  [-31.6120, 138.3931],
  [-31.6027, 138.3963],
  [-31.5919, 138.3978],
  [-31.5906, 138.3984],
  [-31.5829, 138.4056],
  [-31.5819, 138.4071],
  [-31.5784, 138.4093],
  [-31.5774, 138.4104],
  [-31.5766, 138.4109],
  [-31.5714, 138.4130],
  [-31.5705, 138.4142],
  [-31.5689, 138.4153],
  [-31.5683, 138.4160],
  [-31.5676, 138.4175],
  [-31.5666, 138.4185],
  [-31.5644, 138.4193],
  [-31.5635, 138.4199],
  [-31.5628, 138.4201],
  [-31.5616, 138.4196],
  [-31.5609, 138.4198],
  [-31.5591, 138.4215],
  [-31.5582, 138.4218],
  [-31.5559, 138.4222],
  [-31.5520, 138.4238],
  [-31.4988, 138.4301],
  [-31.4804, 138.4321],
  [-31.4789, 138.4320],
  [-31.4520, 138.4274],
  [-31.4498, 138.4265],
  [-31.4467, 138.4240],
  [-31.4448, 138.4232],
  [-31.3573, 138.4147],
  [-31.3502, 138.4142],
  [-31.3491, 138.4140],
  [-31.3357, 138.4103]
];

const brachinaBeltana = [
  [-31.3357, 138.4103],
  [-31.3339, 138.4098],
  [-31.3331, 138.4098],
  [-31.3276, 138.4118],
  [-31.3261, 138.4120],
  [-31.3095, 138.4103],
  [-31.2604, 138.4056],
  [-31.1962, 138.3998],
  [-31.1331, 138.3942],
  [-31.0755, 138.3887],
  [-31.0672, 138.3883],
  [-31.0652, 138.3872],
  [-31.0633, 138.3870],
  [-31.0617, 138.3874],
  [-31.0552, 138.3872],
  [-30.9478, 138.3774],
  [-30.9078, 138.3851],
  [-30.8958, 138.3899],
  [-30.8941, 138.3903],
  [-30.8919, 138.3902],
  [-30.8864, 138.3886],
  [-30.8836, 138.3884],
  [-30.8650, 138.3912],
  [-30.8629, 138.3922],
  [-30.8466, 138.4033],
  [-30.8450, 138.4038],
  [-30.8437, 138.4038],
  [-30.8329, 138.4011],
  [-30.8268, 138.4015],
  [-30.8255, 138.4019],
  [-30.8188, 138.4052],
  [-30.8169, 138.4056],
  [-30.8092, 138.4055]
];

const beltanaCopley = [
  [-30.8092, 138.4055],
  [-30.7999, 138.4055],
  [-30.7984, 138.4059],
  [-30.7886, 138.4103],
  [-30.7841, 138.4129],
  [-30.7834, 138.4131],
  [-30.7789, 138.4130],
  [-30.7778, 138.4128],
  [-30.7765, 138.4124],
  [-30.7757, 138.4123],
  [-30.7739, 138.4129],
  [-30.7732, 138.4129],
  [-30.7700, 138.4125],
  [-30.7690, 138.4122],
  [-30.7681, 138.4117],
  [-30.7669, 138.4116],
  [-30.7660, 138.4121],
  [-30.7648, 138.4136],
  [-30.7498, 138.4208],
  [-30.7491, 138.4209],
  [-30.7485, 138.4207],
  [-30.7467, 138.4194],
  [-30.7458, 138.4191],
  [-30.7427, 138.4189],
  [-30.7360, 138.4170],
  [-30.7330, 138.4158],
  [-30.7321, 138.4157],
  [-30.7305, 138.4147],
  [-30.7291, 138.4144],
  [-30.7271, 138.4147],
  [-30.7257, 138.4145],
  [-30.7167, 138.4086],
  [-30.7147, 138.4081],
  [-30.7124, 138.4086],
  [-30.7104, 138.4085],
  [-30.7089, 138.4078],
  [-30.7083, 138.4078],
  [-30.7076, 138.4077],
  [-30.7071, 138.4072],
  [-30.7068, 138.4065],
  [-30.7066, 138.4032],
  [-30.7067, 138.4024],
  [-30.7071, 138.4013],
  [-30.7070, 138.4003],
  [-30.7060, 138.3994],
  [-30.6971, 138.3989],
  [-30.6964, 138.3991],
  [-30.6695, 138.4092],
  [-30.6678, 138.4094],
  [-30.6666, 138.4092],
  [-30.6656, 138.4088],
  [-30.6647, 138.4090],
  [-30.6640, 138.4096],
  [-30.6633, 138.4107],
  [-30.6626, 138.4110],
  [-30.6620, 138.4110],
  [-30.6614, 138.4106],
  [-30.6596, 138.4078],
  [-30.6587, 138.4071],
  [-30.6577, 138.4068],
  [-30.6544, 138.4067],
  [-30.6525, 138.4070],
  [-30.6422, 138.4096],
  [-30.6291, 138.4152],
  [-30.6276, 138.4155],
  [-30.6253, 138.4147],
  [-30.6246, 138.4141],
  [-30.6236, 138.4120],
  [-30.6233, 138.4118],
  [-30.6227, 138.4110],
  [-30.6224, 138.4103],
  [-30.6215, 138.4096],
  [-30.6204, 138.4098],
  [-30.6196, 138.4107],
  [-30.6195, 138.4117],
  [-30.6200, 138.4136],
  [-30.6197, 138.4148],
  [-30.6188, 138.4160],
  [-30.6185, 138.4169],
  [-30.6185, 138.4186],
  [-30.6174, 138.4219],
  [-30.6152, 138.4241],
  [-30.6131, 138.4248],
  [-30.5545, 138.4247],
  [-30.5528, 138.4247]
];

const copleyMaree = [
  [-30.5528, 138.4247],
  [-30.5442, 138.4246],
  [-30.5415, 138.4240],
  [-30.5224, 138.4165],
  [-30.5205, 138.4165],
  [-30.5189, 138.4172],
  [-30.5164, 138.4200],
  [-30.5100, 138.4234],
  [-30.5079, 138.4239],
  [-30.5057, 138.4237],
  [-30.4982, 138.4189],
  [-30.4913, 138.4176],
  [-30.4800, 138.4136],
  [-30.4666, 138.4097],
  [-30.4590, 138.4052],
  [-30.4528, 138.4043],
  [-30.4412, 138.4059],
  [-30.4398, 138.4055],
  [-30.4390, 138.4050],
  [-30.4147, 138.3841],
  [-30.4129, 138.3832],
  [-30.3902, 138.3760],
  [-30.3061, 138.3482],
  [-30.3045, 138.3481],
  [-30.2835, 138.3505],
  [-30.2816, 138.3503],
  [-30.2383, 138.3301],
  [-30.2323, 138.3290],
  [-30.2021, 138.3174],
  [-30.1991, 138.3153],
  [-30.1974, 138.3145],
  [-30.1290, 138.2925],
  [-30.1004, 138.2913],
  [-30.0967, 138.2904],
  [-30.0829, 138.2846],
  [-30.0789, 138.2838],
  [-30.0586, 138.2836],
  [-30.0404, 138.2784],
  [-30.0385, 138.2785],
  [-30.0372, 138.2789],
  [-30.0316, 138.2815],
  [-30.0290, 138.2818],
  [-30.0102, 138.2779],
  [-29.9719, 138.2700],
  [-29.8517, 138.2012],
  [-29.8473, 138.1971],
  [-29.8309, 138.1702],
  [-29.8287, 138.1678],
  [-29.8257, 138.1657],
  [-29.6657, 138.0868],
  [-29.6617, 138.0832],
  [-29.6469, 138.0641]
];

const marreeCowardSprings = [
  [-29.6469, 138.0641],
  [-29.6426, 138.0592],
  [-29.6415, 138.0571],
  [-29.6412, 138.0546],
  [-29.6414, 138.0526],
  [-29.6394, 138.0308],
  [-29.6394, 138.0280],
  [-29.6432, 137.9677],
  [-29.6379, 137.8052],
  [-29.6380, 137.8030],
  [-29.6393, 137.7959],
  [-29.6400, 137.7941],
  [-29.6425, 137.7887],
  [-29.6432, 137.7862],
  [-29.6455, 137.7620],
  [-29.6497, 137.7215],
  [-29.6602, 137.6768],
  [-29.6603, 137.6742],
  [-29.6594, 137.6656],
  [-29.6586, 137.6629],
  [-29.6583, 137.6606],
  [-29.6583, 137.6586],
  [-29.6572, 137.6546],
  [-29.6548, 137.6506],
  [-29.6494, 137.6452],
  [-29.6484, 137.6434],
  [-29.6477, 137.6409],
  [-29.6475, 137.6316],
  [-29.6481, 137.6252],
  [-29.6478, 137.6224],
  [-29.6469, 137.6200],
  [-29.6433, 137.6140],
  [-29.6284, 137.5641],
  [-29.6259, 137.5487],
  [-29.6242, 137.5392],
  [-29.6142, 137.4825],
  [-29.6117, 137.4627],
  [-29.6095, 137.4552],
  [-29.6079, 137.4508],
  [-29.6078, 137.4491],
  [-29.6089, 137.4466],
  [-29.6092, 137.4455],
  [-29.6090, 137.4433],
  [-29.6039, 137.4145],
  [-29.5995, 137.4009],
  [-29.5991, 137.3988],
  [-29.5991, 137.3970],
  [-29.5997, 137.3917],
  [-29.5996, 137.3909],
  [-29.5982, 137.3878],
  [-29.5921, 137.3785],
  [-29.5813, 137.3649],
  [-29.5694, 137.3480],
  [-29.5633, 137.3394],
  [-29.5206, 137.2603],
  [-29.5040, 137.2152],
  [-29.4958, 137.2053],
  [-29.4930, 137.1991],
  [-29.4819, 137.1768],
  [-29.4809, 137.1741],
  [-29.4795, 137.1673],
  [-29.4768, 137.0806],
  [-29.4758, 137.0761],
  [-29.4704, 137.0646],
  [-29.4699, 137.0619],
  [-29.4694, 137.0332],
  [-29.4528, 136.9549],
  [-29.4341, 136.8682],
  [-29.4010, 136.8118]
];

const cowardsSpringsWilliamCreek = [
  [-29.4010, 136.8118],
  [-29.3821, 136.7859],
  [-29.3784, 136.7824],
  [-29.3655, 136.7705],
  [-29.3100, 136.7201],
  [-29.2488, 136.6642],
  [-29.2370, 136.6568],
  [-29.1782, 136.5953],
  [-29.1590, 136.5758],
  [-29.1516, 136.5706],
  [-29.1399, 136.5665],
  [-29.1358, 136.5657],
  [-29.1281, 136.5613],
  [-29.1068, 136.5479],
  [-29.0958, 136.5390],
  [-29.0804, 136.5306],
  [-29.0778, 136.5296],
  [-29.0696, 136.5249],
  [-29.0624, 136.5182],
  [-29.0603, 136.5167],
  [-29.0533, 136.5136],
  [-29.0484, 136.5107],
  [-29.0415, 136.5062],
  [-29.0276, 136.4937],
  [-29.0139, 136.4770],
  [-29.0072, 136.4687],
  [-29.0054, 136.4651],
  [-28.9985, 136.4424],
  [-28.9970, 136.4401],
  [-28.9476, 136.3786],
  [-28.9456, 136.3768],
  [-28.9111, 136.3492],
  [-28.9092, 136.3459],
  [-28.9065, 136.3357]
];

const williamCreekWarrina = [
  [-28.9065, 136.3357],
  [-28.9025, 136.3204],
  [-28.8735, 136.2678],
  [-28.8688, 136.2592],
  [-28.8663, 136.2522],
  [-28.8661, 136.2500],
  [-28.8700, 136.1927],
  [-28.8697, 136.1897],
  [-28.8545, 136.1531],
  [-28.8539, 136.1509],
  [-28.8537, 136.1361],
  [-28.8526, 136.1327],
  [-28.8509, 136.1306],
  [-28.8285, 136.1144],
  [-28.8265, 136.1119],
  [-28.8163, 136.0821],
  [-28.8042, 136.0571],
  [-28.7938, 136.0227],
  [-28.7917, 136.0186],
  [-28.7834, 136.0088],
  [-28.7663, 135.9803],
  [-28.7613, 135.9764],
  [-28.7310, 135.9664],
  [-28.7289, 135.9651],
  [-28.7209, 135.9576],
  [-28.6952, 135.9177],
  [-28.6916, 135.9132],
  [-28.6900, 135.9118],
  [-28.6806, 135.9075],
  [-28.6660, 135.9066],
  [-28.6636, 135.9055],
  [-28.6603, 135.9028],
  [-28.6584, 135.9018],
  [-28.6517, 135.9005],
  [-28.6220, 135.8874],
  [-28.6192, 135.8869],
  [-28.5945, 135.8857],
  [-28.5773, 135.8835],
  [-28.5641, 135.8809],
  [-28.5231, 135.8708],
  [-28.4901, 135.8608],
  [-28.4755, 135.8566],
  [-28.4679, 135.8544],
  [-28.4493, 135.8535],
  [-28.4223, 135.8473],
  [-28.3959, 135.8444],
  [-28.3838, 135.8419],
  [-28.3698, 135.8415],
  [-28.3514, 135.8428],
  [-28.3488, 135.8441],
  [-28.3453, 135.8472],
  [-28.3406, 135.8516],
  [-28.3389, 135.8523],
  [-28.3360, 135.8522],
  [-28.3026, 135.8388],
  [-28.2894, 135.8367],
  [-28.2858, 135.8374],
  [-28.2772, 135.8435],
  [-28.2733, 135.8442],
  [-28.2620, 135.8414],
  [-28.2176, 135.8324],
];

const warrinaOodnadatta = [
  [-28.2176, 135.8324],
  [-28.1980, 135.8303],
  [-28.1576, 135.8257],
  [-28.1428, 135.8229],
  [-28.0990, 135.8141],
  [-28.0925, 135.8112],
  [-28.0884, 135.8107],
  [-28.0866, 135.8100],
  [-28.0793, 135.8066],
  [-28.0619, 135.8060],
  [-28.0557, 135.8046],
  [-28.0532, 135.8049],
  [-28.0483, 135.8062],
  [-28.0355, 135.8076],
  [-27.9996, 135.8112],
  [-27.9935, 135.8133],
  [-27.9190, 135.8156],
  [-27.9157, 135.8147],
  [-27.9098, 135.8102],
  [-27.9032, 135.8079],
  [-27.8947, 135.8068],
  [-27.8891, 135.8043],
  [-27.8868, 135.8038],
  [-27.8841, 135.8038],
  [-27.8813, 135.8029],
  [-27.8779, 135.8004],
  [-27.8721, 135.7946],
  [-27.8708, 135.7921],
  [-27.8674, 135.7810],
  [-27.8584, 135.7631],
  [-27.8573, 135.7615],
  [-27.8434, 135.7498],
  [-27.8385, 135.7448],
  [-27.8328, 135.7368],
  [-27.7883, 135.6915],
  [-27.7854, 135.6873],
  [-27.7847, 135.6853],
  [-27.7826, 135.6670],
  [-27.7775, 135.6559],
  [-27.7601, 135.6368],
  [-27.7492, 135.6170],
  [-27.7477, 135.6154],
  [-27.7419, 135.6120],
  [-27.7400, 135.6099],
  [-27.7313, 135.5934],
  [-27.7273, 135.5792],
  [-27.7250, 135.5760],
  [-27.6912, 135.5527],
  [-27.6872, 135.5484],
  [-27.6569, 135.5303],
  [-27.5981, 135.4782],
  [-27.5733, 135.4640],
  [-27.5492, 135.4495],
  [-27.5460, 135.4475]
];

const oodnadattaWireCreek = [
  [-27.5460, 135.4475],
  [-27.5355, 135.4413],
  [-27.4938, 135.4168],
  [-27.4902, 135.4161],
  [-27.4833, 135.4157],
  [-27.4789, 135.4142],
  [-27.4764, 135.4131],
  [-27.4712, 135.4128],
  [-27.4256, 135.4099],
  [-27.4224, 135.4103],
  [-27.3743, 135.4243],
  [-27.3654, 135.4259],
  [-27.3576, 135.4295],
  [-27.3555, 135.4300],
  [-27.3060, 135.4346],
  [-27.2874, 135.4377]
];

const wireCreekNtBorder = [
  [-27.2874, 135.4377],
  [-27.2446, 135.4446],
  [-27.2116, 135.4499],
  [-27.2087, 135.4496],
  [-27.1873, 135.4428],
  [-27.1251, 135.4232],
  [-27.1206, 135.4214],
  [-27.1158, 135.4203],
  [-27.1094, 135.4182],
  [-27.0219, 135.3781],
  [-26.9774, 135.3681],
  [-26.9437, 135.3542],
  [-26.9262, 135.3471],
  [-26.9234, 135.3466],
  [-26.8971, 135.3464],
  [-26.8936, 135.3475],
  [-26.8902, 135.3492],
  [-26.8864, 135.3496],
  [-26.8396, 135.3436],
  [-26.8373, 135.3426],
  [-26.8290, 135.3354],
  [-26.8263, 135.3344],
  [-26.8243, 135.3330],
  [-26.8220, 135.3302],
  [-26.7826, 135.2974],
  [-26.7515, 135.2717],
  [-26.6814, 135.2271],
  [-26.6778, 135.2263],
  [-26.6681, 135.2266],
  [-26.6657, 135.2257],
  [-26.6636, 135.2233],
  [-26.6418, 135.1795],
  [-26.6397, 135.1774],
  [-26.4137, 135.0331],
  [-26.3404, 134.9862],
  [-26.2781, 134.9305],
  [-26.2758, 134.9294],
  [-26.2658, 134.9273],
  [-26.2628, 134.9259],
  [-26.2326, 134.9065],
  [-26.1812, 134.8738],
  [-26.1455, 134.8536],
  [-26.1418, 134.8524],
  [-26.1262, 134.8498],
  [-26.1215, 134.8479],
  [-26.1079, 134.8395],
  [-26.0190, 134.7862],
  [-25.9986, 134.7741]
];

export const pichiRichi: Line = {
  name: 'Pichi Richi',
  state: 'SA',
  segments: [
    {
      segments: [
        {
          date: '2001-09-15',
          original: [portAugustaClosed],
          deviation: [portAugustaDeviation]
        }],
      history: {
        opened: {
          date: '1879-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [stirlingNorthWoolshedFlat],
      history: {
        opened: {
          date: '1879-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1972-09-17',
          status: 'closed'
        }, {
          date: '1999-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [woolshedFlatQuorn],
      history: {
        opened: {
          date: '1879-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1972-09-17',
          status: 'closed'
        }, {
          date: '1979-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [quornHawker],
      history: {
        opened: {
          date: '1880-06-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1972-09-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hawkerbrachina],
      history: {
        opened: {
          date: '1881-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-10-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brachinaBeltana],
      history: {
        opened: {
          date: '1881-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-10-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [beltanaCopley],
      history: {
        opened: {
          date: '1884-01-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-10-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [copleyMaree],
      history: {
        opened: {
          date: '1884-01-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-03-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [marreeCowardSprings],
      history: {
        opened: {
          date: '1888-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cowardsSpringsWilliamCreek],
      history: {
        opened: {
          date: '1889-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [williamCreekWarrina],
      history: {
        opened: {
          date: '1889-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [warrinaOodnadatta],
      history: {
        opened: {
          date: '1891-01-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oodnadattaWireCreek],
      history: {
        opened: {
          date: '1927-01-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wireCreekNtBorder],
      history: {
        opened: {
          date: '1928-12-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-25',
          status: 'closed'
        }]
      }
    }
  ]
};
