import { Line } from "../../trackTypes";

const werrisCreekBreeza = [
  [-31.3504, 150.6463],
  [-31.3490, 150.6460],
  [-31.3477, 150.6451],
  [-31.3465, 150.6438],
  [-31.3459, 150.6422],
  [-31.3457, 150.6407],
  [-31.3487, 150.6173],
  [-31.3493, 150.6148],
  [-31.3543, 150.6035],
  [-31.3553, 150.6011],
  [-31.3558, 150.5992],
  [-31.3559, 150.5957],
  [-31.3560, 150.5930],
  [-31.3557, 150.5911],
  [-31.3542, 150.5874],
  [-31.3525, 150.5845],
  [-31.2606, 150.4737],
  [-31.2581, 150.4719],
  [-31.2505, 150.4698],
  [-31.2483, 150.4684],
  [-31.2469, 150.4664],
  [-31.2451, 150.4621]
];

const breezaGunnedah = [
  [-31.2451, 150.4621],
  [-31.2441, 150.4597],
  [-31.2436, 150.4569],
  [-31.2435, 150.4543],
  [-31.2463, 150.4374],
  [-31.2462, 150.4352],
  [-31.2455, 150.4335],
  [-31.2360, 150.4172],
  [-31.2349, 150.4159],
  [-31.2332, 150.4147],
  [-31.2248, 150.4115],
  [-31.2212, 150.4087],
  [-31.2191, 150.4053],
  [-31.2082, 150.3761],
  [-31.2068, 150.3735],
  [-31.1330, 150.2793],
  [-31.1301, 150.2769],
  [-31.1113, 150.2674],
  [-31.1073, 150.2671],
  [-31.0676, 150.2762],
  [-31.0538, 150.2772],
  [-31.0417, 150.2760],
  [-31.0176, 150.2730],
  [-31.0143, 150.2732],
  [-30.9974, 150.2789],
  [-30.9943, 150.2791],
  [-30.9910, 150.2778],
  [-30.9881, 150.2747],
  [-30.9867, 150.2708],
  [-30.9862, 150.2651],
  [-30.9853, 150.2613],
  [-30.9789, 150.2470]
];

const gunnedahBoggabri = [
  [-30.9789, 150.2470],
  [-30.9776, 150.2442],
  [-30.9770, 150.2433],
  [-30.9761, 150.2425],
  [-30.9735, 150.2415],
  [-30.9720, 150.2405],
  [-30.9710, 150.2393],
  [-30.9228, 150.1382],
  [-30.9187, 150.1321],
  [-30.8282, 150.0634],
  [-30.8235, 150.0611],
  [-30.7465, 150.0341],
  [-30.7386, 150.0334],
  [-30.7036, 150.0399]
];

const boggabriNarrabriSouthJunction = [
  [-30.7036, 150.0399],
  [-30.6994, 150.0407],
  [-30.6951, 150.0405],
  [-30.6905, 150.0388],
  [-30.6613, 150.0211],
  [-30.6588, 150.0183],
  [-30.6571, 150.0145],
  [-30.6544, 150.0012],
  [-30.6542, 149.9998],
  [-30.6544, 149.9955],
  [-30.6542, 149.9939],
  [-30.6531, 149.9921],
  [-30.6515, 149.9910],
  [-30.6493, 149.9902],
  [-30.6481, 149.9891],
  [-30.6473, 149.9877],
  [-30.6468, 149.9859],
  [-30.6459, 149.9841],
  [-30.6444, 149.9827],
  [-30.6438, 149.9820],
  [-30.6377, 149.9716],
  [-30.6356, 149.9695],
  [-30.5805, 149.9435],
  [-30.4488, 149.8813],
  [-30.4410, 149.8753],
  [-30.3654, 149.7716],
  [-30.3615, 149.7683],
  [-30.3482, 149.7634]
];

const narrabriSouthJunctionMoree = [
  [-30.3482, 149.7634],
  [-30.3472, 149.7635],
  [-30.3464, 149.7640],
  [-30.3457, 149.7651],
  [-30.3450, 149.7697],
  [-30.3449, 149.7748],
  [-30.3446, 149.7767],
  [-30.3436, 149.7781],
  [-30.3378, 149.7825],
  [-30.3366, 149.7841],
  [-30.3330, 149.7923],
  [-30.3322, 149.7935],
  [-30.3309, 149.7943],
  [-30.3295, 149.7945],
  [-30.3282, 149.7941],
  [-30.3269, 149.7933],
  [-30.3218, 149.7899],
  [-30.3207, 149.7895],
  [-30.3185, 149.7896],
  [-30.3041, 149.7922],
  [-30.3020, 149.7930],
  [-30.2995, 149.7945],
  [-30.2977, 149.7951],
  [-30.2739, 149.7992],
  [-30.2713, 149.8004],
  [-30.2697, 149.8019],
  [-30.2679, 149.8040],
  [-30.2660, 149.8052],
  [-30.2640, 149.8055],
  [-30.2523, 149.8038],
  [-30.2502, 149.8041],
  [-30.2487, 149.8049],
  [-30.2462, 149.8071],
  [-30.2438, 149.8081],
  [-30.2384, 149.8089],
  [-30.2362, 149.8085],
  [-30.2345, 149.8075],
  [-30.2326, 149.8067],
  [-30.2311, 149.8068],
  [-30.2267, 149.8090],
  [-30.2234, 149.8100],
  [-30.1965, 149.8150],
  [-30.1861, 149.8198],
  [-30.1831, 149.8203],
  [-30.1800, 149.8200],
  [-30.1179, 149.7987],
  [-30.0465, 149.7880],
  [-30.0018, 149.7923],
  [-29.8155, 149.7885],
  [-29.7414, 149.8002],
  [-29.7145, 149.8010],
  [-29.6916, 149.8055],
  [-29.6897, 149.8055],
  [-29.6874, 149.8048],
  [-29.6855, 149.8050],
  [-29.6754, 149.8085],
  [-29.6472, 149.8137],
  [-29.6222, 149.8264],
  [-29.6196, 149.8272],
  [-29.5562, 149.8389],
  [-29.5171, 149.8526],
  [-29.5151, 149.8529],
  [-29.5004, 149.8532],
  [-29.4856, 149.8516],
  [-29.4842, 149.8512],
  [-29.4799, 149.8492],
  [-29.4783, 149.8484],
  [-29.4768, 149.8480],
  [-29.4733, 149.8480]
];

const moreeGarah = [
  [-29.4733, 149.8480],
  [-29.4706, 149.8481],
  [-29.4695, 149.8485],
  [-29.4686, 149.8493],
  [-29.4680, 149.8504],
  [-29.4669, 149.8533],
  [-29.4661, 149.8545],
  [-29.4654, 149.8553],
  [-29.4368, 149.8755],
  [-29.4358, 149.8764],
  [-29.4302, 149.8832],
  [-29.4259, 149.8909],
  [-29.4213, 149.9024],
  [-29.4202, 149.9081],
  [-29.4196, 149.9096],
  [-29.4120, 149.9209],
  [-29.4112, 149.9215],
  [-29.4103, 149.9217],
  [-29.4033, 149.9210],
  [-29.4017, 149.9203],
  [-29.4003, 149.9188],
  [-29.3947, 149.9049],
  [-29.3775, 149.8748],
  [-29.3761, 149.8731],
  [-29.3700, 149.8678],
  [-29.3534, 149.8466],
  [-29.3285, 149.8171],
  [-29.3249, 149.8156],
  [-29.3037, 149.8070],
  [-29.2766, 149.7888],
  [-29.2121, 149.7599],
  [-29.2102, 149.7585],
  [-29.2087, 149.7565],
  [-29.1990, 149.7391],
  [-29.1963, 149.7363],
  [-29.1673, 149.7184],
  [-29.1637, 149.7147],
  [-29.1473, 149.6891],
  [-29.1409, 149.6826],
  [-29.1116, 149.6675],
  [-29.0978, 149.6564],
  [-29.0827, 149.6406],
  [-29.0804, 149.6392],
  [-29.0738, 149.6361]
];

const garahWeemelah = [
  [-29.0738, 149.6361],
  [-29.0710, 149.6348],
  [-29.0686, 149.6326],
  [-29.0672, 149.6291],
  [-29.0654, 149.5891],
  [-29.0717, 149.5198],
  [-29.0707, 149.5142],
  [-29.0464, 149.4503],
  [-29.0140, 149.2955],
  [-29.0138, 149.2902],
  [-29.0175, 149.2539]
];

const weemelahMungindi = [
  [-29.0175, 149.2539],
  [-29.0182, 149.2465],
  [-29.0160, 149.2109],
  [-29.0163, 149.2075],
  [-29.0242, 149.1659],
  [-29.0290, 149.1050],
  [-29.0266, 149.0787],
  [-29.0260, 149.0768],
  [-29.0250, 149.0752],
  [-29.0164, 149.0677],
  [-29.0152, 149.0657],
  [-29.0060, 149.0469],
  [-28.9750, 149.0008],
  [-28.9741, 148.9984]
];

export const mungindi: Line = {
  name: 'Mungindi',
  state: 'NSW',
  segments: [
    {
      segments: [werrisCreekBreeza],
      history: {
        opened: {
          date: '1879-03-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [breezaGunnedah],
      history: {
        opened: {
          date: '1879-09-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [gunnedahBoggabri],
      history: {
        opened: {
          date: '1882-07-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [boggabriNarrabriSouthJunction],
      history: {
        opened: {
          date: '1882-10-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [narrabriSouthJunctionMoree],
      history: {
        opened: {
          date: '1897-04-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [moreeGarah],
      history: {
        opened: {
          date: '1913-09-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [garahWeemelah],
      history: {
        opened: {
          date: '1914-12-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [weemelahMungindi],
      history: {
        opened: {
          date: '1914-12-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-11',
          status: 'closed'
        }]
      }
    }
  ]
};
