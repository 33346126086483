import { Line } from "../../trackTypes";

const line = [
  [-30.7412, 121.4719],
  [-30.7401, 121.4724],
  [-30.7377, 121.4732],
  [-30.7363, 121.4740],
  [-30.7356, 121.4751],
  [-30.7350, 121.4772],
  [-30.7343, 121.4783],
  [-30.7329, 121.4795],
  [-30.7306, 121.4800],
  [-30.7289, 121.4807],
  [-30.7272, 121.4819],
  [-30.7190, 121.4870],
  [-30.7176, 121.4881],
  [-30.7158, 121.4902],
  [-30.6504, 121.5648],
  [-30.6146, 121.5999],
  [-30.6133, 121.6007],
  [-30.6119, 121.6010],
  [-30.6049, 121.6010]
];

export const kanowna: Line = {
  name: 'Kanowna',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1898-06-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1928-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
