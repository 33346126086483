import { Line } from "../../../trackTypes";

const stageOne = [
  [-27.96072, 153.38062],
  [-27.96131, 153.38188],
  [-27.96146, 153.38213],
  [-27.96161, 153.38233],
  [-27.96223, 153.38303],
  [-27.96249, 153.38339],
  [-27.96286, 153.38402],
  [-27.96301, 153.38425],
  [-27.96358, 153.38508],
  [-27.96370, 153.38520],
  [-27.96382, 153.38528],
  [-27.96398, 153.38533],
  [-27.96475, 153.38544],
  [-27.96518, 153.38554],
  [-27.96556, 153.38568],
  [-27.96590, 153.38584],
  [-27.96635, 153.38613],
  [-27.96673, 153.38646],
  [-27.96704, 153.38680],
  [-27.96728, 153.38714],
  [-27.96756, 153.38766],
  [-27.96774, 153.38815],
  [-27.96784, 153.38857],
  [-27.96822, 153.39056],
  [-27.96831, 153.39107],
  [-27.96856, 153.39242],
  [-27.96869, 153.39288],
  [-27.96879, 153.39310],
  [-27.96905, 153.39357],
  [-27.96914, 153.39367],
  [-27.96926, 153.39372],
  [-27.96938, 153.39373],
  [-27.96970, 153.39367],
  [-27.96987, 153.39369],
  [-27.97001, 153.39379],
  [-27.97010, 153.39396],
  [-27.97050, 153.39678],
  [-27.97065, 153.39812],
  [-27.97134, 153.40308],
  [-27.97184, 153.40601],
  [-27.97185, 153.40622],
  [-27.97181, 153.40641],
  [-27.97173, 153.40658],
  [-27.96763, 153.41393],
  [-27.96760, 153.41405],
  [-27.96762, 153.41417],
  [-27.96768, 153.41426],
  [-27.96778, 153.41431],
  [-27.97296, 153.41566],
  [-27.97306, 153.41571],
  [-27.97315, 153.41581],
  [-27.97319, 153.41591],
  [-27.97336, 153.41707],
  [-27.97340, 153.41744],
  [-27.97340, 153.41799],
  [-27.97341, 153.41825],
  [-27.97360, 153.41950],
  [-27.97366, 153.41970],
  [-27.97378, 153.41992],
  [-27.97391, 153.42007],
  [-27.97415, 153.42021],
  [-27.97846, 153.42150],
  [-27.97939, 153.42177],
  [-27.97982, 153.42193],
  [-27.98020, 153.42211],
  [-27.98066, 153.42239],
  [-27.98091, 153.42257],
  [-27.98575, 153.42656],
  [-27.98607, 153.42680],
  [-27.98648, 153.42707],
  [-27.98714, 153.42743],
  [-27.98773, 153.42768],
  [-27.98828, 153.42788],
  [-27.98889, 153.42802],
  [-27.98933, 153.42810],
  [-27.98964, 153.42811],
  [-27.99024, 153.42812],
  [-27.99051, 153.42817],
  [-27.99064, 153.42824],
  [-27.99080, 153.42839],
  [-27.99099, 153.42866],
  [-27.99110, 153.42878],
  [-27.99122, 153.42887],
  [-27.99175, 153.42914],
  [-27.99196, 153.42921],
  [-27.99223, 153.42925],
  [-27.99306, 153.42927],
  [-27.99333, 153.42930],
  [-27.99441, 153.42949],
  [-27.99469, 153.42951],
  [-27.99501, 153.42953],
  [-27.99531, 153.42951],
  [-27.99563, 153.42945],
  [-27.99592, 153.42937],
  [-27.99720, 153.42891],
  [-27.99772, 153.42871],
  [-27.99794, 153.42862],
  [-27.99813, 153.42855],
  [-27.99832, 153.42853],
  [-27.99936, 153.42856],
  [-27.99962, 153.42855],
  [-27.99984, 153.42852],
  [-28.00009, 153.42846],
  [-28.00054, 153.42829],
  [-28.00072, 153.42825],
  [-28.00089, 153.42825],
  [-28.00105, 153.42826],
  [-28.00248, 153.42860],
  [-28.00279, 153.42866],
  [-28.00302, 153.42867],
  [-28.00338, 153.42868],
  [-28.00361, 153.42871],
  [-28.00400, 153.42881],
  [-28.00422, 153.42885],
  [-28.00447, 153.42887],
  [-28.00503, 153.42882],
  [-28.00521, 153.42883],
  [-28.00538, 153.42885],
  [-28.00640, 153.42915],
  [-28.00655, 153.42919],
  [-28.00669, 153.42920],
  [-28.00708, 153.42922],
  [-28.01044, 153.42953],
  [-28.01100, 153.42954],
  [-28.01118, 153.42956],
  [-28.01154, 153.42964],
  [-28.01178, 153.42967],
  [-28.01263, 153.42974],
  [-28.01293, 153.42976],
  [-28.01328, 153.42972],
  [-28.01471, 153.42946],
  [-28.01534, 153.42930],
  [-28.01570, 153.42924],
  [-28.01598, 153.42921],
  [-28.01644, 153.42920],
  [-28.01691, 153.42923],
  [-28.01803, 153.42935],
  [-28.01835, 153.42936],
  [-28.01843, 153.42936],
  [-28.01871, 153.42933],
  [-28.02213, 153.42864],
  [-28.02247, 153.42860],
  [-28.02272, 153.42859],
  [-28.02306, 153.42861],
  [-28.02337, 153.42866],
  [-28.02551, 153.42917],
  [-28.02622, 153.42927],
  [-28.03082, 153.43036],
  [-28.03097, 153.43038],
  [-28.03116, 153.43036],
  [-28.03144, 153.43032],
  [-28.03158, 153.43031],
  [-28.03172, 153.43032],
  [-28.03380, 153.43079],
  [-28.03401, 153.43082],
  [-28.03433, 153.43083],
  [-28.03453, 153.43086],
  [-28.03615, 153.43123]
];

const southportDepot = [
  [-27.96822, 153.39056],
  [-27.96820, 153.39066],
  [-27.96814, 153.39079],
  [-27.96802, 153.39088],
  [-27.96690, 153.39112]
];

const southportJunction = [
  [-27.96831, 153.39107],
  [-27.96821, 153.39095],
  [-27.96814, 153.39091],
  [-27.96802, 153.39088]
];

const stageTwo = [
  [-27.92566, 153.33928],
  [-27.92736, 153.33901],
  [-27.92753, 153.33900],
  [-27.92773, 153.33903],
  [-27.92822, 153.33914],
  [-27.92863, 153.33914],
  [-27.93012, 153.33900],
  [-27.93104, 153.33903],
  [-27.93208, 153.33914],
  [-27.93431, 153.33950],
  [-27.93589, 153.33990],
  [-27.93704, 153.34033],
  [-27.93793, 153.34077],
  [-27.94115, 153.34236],
  [-27.94245, 153.34291],
  [-27.94764, 153.34485],
  [-27.94860, 153.34516],
  [-27.94941, 153.34540],
  [-27.95042, 153.34564],
  [-27.95070, 153.34573],
  [-27.95094, 153.34590],
  [-27.95120, 153.34624],
  [-27.95133, 153.34653],
  [-27.95146, 153.34676],
  [-27.95167, 153.34695],
  [-27.95234, 153.34751],
  [-27.95270, 153.34774],
  [-27.95320, 153.34799],
  [-27.95365, 153.34827],
  [-27.95514, 153.34926],
  [-27.95570, 153.34962],
  [-27.95676, 153.35017],
  [-27.95757, 153.35055],
  [-27.95890, 153.35114],
  [-27.95945, 153.35145],
  [-27.96006, 153.35192],
  [-27.96060, 153.35248],
  [-27.96098, 153.35305],
  [-27.96130, 153.35376],
  [-27.96153, 153.35460],
  [-27.96383, 153.36977],
  [-27.96392, 153.37050],
  [-27.96394, 153.37135],
  [-27.96390, 153.37380],
  [-27.96385, 153.37417],
  [-27.96375, 153.37447],
  [-27.96356, 153.37479],
  [-27.96335, 153.37504],
  [-27.96213, 153.37617],
  [-27.96162, 153.37660],
  [-27.96085, 153.37721],
  [-27.95981, 153.37796],
  [-27.95961, 153.37818],
  [-27.95953, 153.37841],
  [-27.95951, 153.37863],
  [-27.95957, 153.37891],
  [-27.95973, 153.37922],
  [-27.96002, 153.37980],
  [-27.96013, 153.37996],
  [-27.96035, 153.38013],
  [-27.96053, 153.38029],
  [-27.96065, 153.38044],
  [-27.96072, 153.38062]
];

const olsenAvenueSiding = [
  [-27.95973, 153.37922],
  [-27.95961, 153.37914],
  [-27.95950, 153.37914],
  [-27.95940, 153.37917],
  [-27.95825, 153.38019]
];

const stageThree = [
  [-28.03615, 153.43123],
  [-28.03619, 153.43127],
  [-28.03642, 153.43150],
  [-28.03650, 153.43167],
  [-28.03663, 153.43183],
  [-28.03805, 153.43277],
  [-28.03907, 153.43339],
  [-28.03938, 153.43356],
  [-28.05788, 153.43813],
  [-28.05824, 153.43819],
  [-28.05854, 153.43816],
  [-28.05885, 153.43808],
  [-28.06080, 153.43726],
  [-28.06114, 153.43717],
  [-28.06140, 153.43714],
  [-28.06168, 153.43716],
  [-28.06202, 153.43724],
  [-28.06226, 153.43732],
  [-28.06426, 153.43818],
  [-28.06450, 153.43830],
  [-28.06462, 153.43839],
  [-28.06957, 153.44246],
  [-28.06986, 153.44267],
  [-28.07015, 153.44283],
  [-28.07218, 153.44387],
  [-28.07266, 153.44408],
  [-28.07992, 153.44682],
  [-28.08437, 153.44845],
  [-28.08495, 153.44871],
  [-28.08675, 153.44964],
  [-28.08794, 153.45020],
  [-28.08817, 153.45037],
  [-28.08832, 153.45056],
  [-28.08839, 153.45073],
  [-28.08865, 153.45226],
  [-28.08878, 153.45257],
  [-28.08894, 153.45276],
  [-28.08912, 153.45287],
  [-28.08933, 153.45294],
  [-28.08991, 153.45289],
  [-28.09013, 153.45291],
  [-28.09033, 153.45300],
  [-28.09047, 153.45312],
  [-28.09101, 153.45376]
];

const broadbeachSouthSiding = [
  [-28.03615, 153.43123],
  [-28.03639, 153.43126],
  [-28.03652, 153.43123],
  [-28.03727, 153.43136]
];

export const goldCoastLightRail: Line = {
  name: 'Gold Coast Light Rail',
  state: 'QLD',
  segments: [
    {
      segments: [stageOne, southportDepot, southportJunction],
      history: {
        opened: {
          date: '2014-07-20',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [stageTwo, olsenAvenueSiding],
      history: {
        opened: {
          date: '2017-12-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [stageThree, broadbeachSouthSiding],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
}
