import { Line } from "../../../trackTypes";

const northGeelongCMoorabool = [
  [-38.1115, 144.3502],
  [-38.1098, 144.3485],
  [-38.1085, 144.3475],
  [-38.1071, 144.3465],
  [-38.0994, 144.3419],
  [-38.0983, 144.3411],
  [-38.0966, 144.3396],
  [-38.0953, 144.3379],
  [-38.0945, 144.3367],
  [-38.0904, 144.3291],
  [-38.0887, 144.3269],
  [-38.0876, 144.3258],
  [-38.0864, 144.3242],
  [-38.0834, 144.3195],
  [-38.0817, 144.3172],
  [-38.0800, 144.3155],
  [-38.0790, 144.3141],
  [-38.0779, 144.3121],
  [-38.0773, 144.3101],
  [-38.0768, 144.3084],
  [-38.0752, 144.2987]
];

const northGeelongCNorthGeelongA = [
  [-38.1115, 144.3502],
  [-38.1120, 144.3505],
  [-38.1138, 144.3512],
  [-38.1168, 144.3522],
  [-38.1190, 144.3527],
  [-38.1212, 144.3525]
];

const northGeelongANorthGeelongB = [
  [-38.1115, 144.3502],
  [-38.1120, 144.3510],
  [-38.1122, 144.3516],
  [-38.1123, 144.3523],
  [-38.1121, 144.3533],
  [-38.1117, 144.3540],
  [-38.1107, 144.3547]
];

const mooraboolGheringhap = [
  [-38.0752, 144.2987],
  [-38.0737, 144.2889],
  [-38.0736, 144.2874],
  [-38.0736, 144.2852],
  [-38.0740, 144.2786],
  [-38.0746, 144.2750],
  [-38.0753, 144.2725],
  [-38.0762, 144.2702],
  [-38.0770, 144.2679],
  [-38.0774, 144.2657],
  [-38.0775, 144.2633],
  [-38.0749, 144.2340],
  [-38.0742, 144.2266]
];

const gherimghapBannockburn = [
  [-38.0742, 144.2266],
  [-38.0735, 144.2238],
  [-38.0724, 144.2202],
  [-38.0709, 144.2169],
  [-38.0455, 144.1744],
  [-38.0438, 144.1720],
  [-38.0434, 144.1717]
];

const bannockburnLethbridge = [
  [-38.0434, 144.1717],
  [-38.0421, 144.1703],
  [-38.0275, 144.1585],
  [-38.0254, 144.1570],
  [-38.0240, 144.1565],
  [-37.9954, 144.1481],
  [-37.9927, 144.1469],
  [-37.9904, 144.1453],
  [-37.9792, 144.1362],
  [-37.9775, 144.1351],
  [-37.9753, 144.1339],
  [-37.9663, 144.1310]
];

const lethbridgeMeredith = [
  [-37.9663, 144.1310],
  [-37.9645, 144.1304],
  [-37.9624, 144.1293],
  [-37.9614, 144.1286],
  [-37.9598, 144.1271],
  [-37.9540, 144.1206],
  [-37.9532, 144.1198],
  [-37.8922, 144.0763],
  [-37.8909, 144.0756],
  [-37.8894, 144.0749],
  [-37.8874, 144.0744],
  [-37.8866, 144.0742],
  [-37.8733, 144.0731],
  [-37.8609, 144.0721],
  [-37.8488, 144.0710],
  [-37.8473, 144.0707],
  [-37.8460, 144.0703],
  [-37.8447, 144.0697],
  [-37.8443, 144.0695]
];

const meredithElaine = [
  [-37.8443, 144.0695],
  [-37.8429, 144.0687],
  [-37.8417, 144.0680],
  [-37.8401, 144.0666],
  [-37.8390, 144.0655],
  [-37.8377, 144.0638],
  [-37.8364, 144.0619],
  [-37.8315, 144.0542],
  [-37.8301, 144.0524],
  [-37.8287, 144.0511],
  [-37.8270, 144.0499],
  [-37.8213, 144.0468],
  [-37.8197, 144.0458],
  [-37.7991, 144.0305],
  [-37.7958, 144.0289],
  [-37.7936, 144.0284],
  [-37.7757, 144.0271]
];

const elaineLalLal = [
  [-37.7757, 144.0271],
  [-37.7744, 144.0271],
  [-37.7333, 144.0239],
  [-37.7259, 144.0234],
  [-37.7238, 144.0229],
  [-37.7215, 144.0219],
  [-37.7198, 144.0208],
  [-37.7182, 144.0195],
  [-37.7159, 144.0184],
  [-37.7135, 144.0180],
  [-37.7115, 144.0182],
  [-37.7084, 144.0189],
  [-37.7061, 144.0199],
  [-37.7041, 144.0215],
  [-37.7022, 144.0238],
  [-37.7008, 144.0251],
  [-37.6986, 144.0265],
  [-37.6965, 144.0272],
  [-37.6942, 144.0273],
  [-37.6909, 144.0264],
  [-37.6870, 144.0243],
  [-37.6843, 144.0224],
  [-37.6832, 144.0213],
  [-37.6758, 144.0137]
];

const lalLalWarrenheip = [
  [-37.6758, 144.0137],
  [-37.6409, 143.9776],
  [-37.6346, 143.9711],
  [-37.6329, 143.9691],
  [-37.6316, 143.9673],
  [-37.6298, 143.9642],
  [-37.6282, 143.9606],
  [-37.6266, 143.9552],
  [-37.6245, 143.9509],
  [-37.6223, 143.9477],
  [-37.6170, 143.9417],
  [-37.6153, 143.9402],
  [-37.6120, 143.9384],
  [-37.6083, 143.9373],
  [-37.6066, 143.9371],
  [-37.6039, 143.9373],
  [-37.6022, 143.9372],
  [-37.5996, 143.9364],
  [-37.5976, 143.9352],
  [-37.5950, 143.9325],
  [-37.5916, 143.9304],
  [-37.5894, 143.9298],
  [-37.5869, 143.9298],
  [-37.5841, 143.9293],
  [-37.5818, 143.9281],
  [-37.5803, 143.9268],
  [-37.5786, 143.9248]
];

export const geelongBallarat: Line = {
  name: 'Geelong - Ballarat',
  state: 'VIC',
  segments: [
    {
      segments: [northGeelongCMoorabool],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-14',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [mooraboolGheringhap],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1892-11-18',
          tracks: 1
        }, {
          date: '1995-04-14',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [gherimghapBannockburn],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1907-10-20',
          tracks: 1
        }]
      }
    },
    {
      segments: [bannockburnLethbridge],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-07-07',
          tracks: 1
        }]
      }
    },
    {
      segments: [lethbridgeMeredith],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-06-10',
          tracks: 1
        }]
      }
    },
    {
      segments: [northGeelongCNorthGeelongA],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
      }
    },
    {
      segments: [northGeelongANorthGeelongB],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-14',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [meredithElaine],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-08-23',
          tracks: 1
        }]
      }
    },
    {
      segments: [elaineLalLal],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-09-27',
          tracks: 1
        }]
      }
    },
    {
      segments: [lalLalWarrenheip],
      history: {
        opened: {
          date: '1862-04-10',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-12-06',
          tracks: 1
        }]
      }
    }
  ]
};
