import { Line } from "../../trackTypes";

const line = [
  [-26.5907, 118.5034],
  [-26.5869, 118.5114],
  [-26.5829, 118.5165],
  [-26.5787, 118.5203],
  [-26.5746, 118.5225],
  [-26.5661, 118.5250],
  [-26.5623, 118.5265],
  [-26.5475, 118.5353],
  [-26.5457, 118.5368],
  [-26.5402, 118.5422],
  [-26.5308, 118.5461],
  [-26.5265, 118.5476],
  [-26.5193, 118.5479],
  [-26.5142, 118.5473],
  [-26.4139, 118.5496],
  [-26.4008, 118.5475],
  [-26.3499, 118.5325],
  [-26.3483, 118.5325],
  [-26.3390, 118.5347],
  [-26.3376, 118.5347],
  [-26.2772, 118.5276],
  [-26.2300, 118.5353],
  [-26.0987, 118.5565],
  [-26.0956, 118.5563],
  [-26.0278, 118.5480],
  [-26.0264, 118.5482],
  [-25.9958, 118.5555],
  [-25.9939, 118.5555],
  [-25.9861, 118.5531],
  [-25.9754, 118.5515],
  [-25.9731, 118.5518],
  [-25.9628, 118.5550],
  [-25.9600, 118.5564],
  [-25.9542, 118.5608],
  [-25.9474, 118.5632],
  [-25.9366, 118.5690],
  [-25.9264, 118.5774],
  [-25.9249, 118.5781],
  [-25.8770, 118.5895],
  [-25.8452, 118.5970],
  [-25.7933, 118.6094],
  [-25.7919, 118.6101],
  [-25.7850, 118.6155],
  [-25.7830, 118.6165],
  [-25.7773, 118.6183],
  [-25.7697, 118.6186],
  [-25.7482, 118.6238],
  [-25.7397, 118.6275],
  [-25.7266, 118.6303],
  [-25.7251, 118.6303],
  [-25.6906, 118.6236],
  [-25.6777, 118.6185],
  [-25.6732, 118.6155],
  [-25.6719, 118.6149],
  [-25.6591, 118.6119],
  [-25.5867, 118.5979],
  [-25.5479, 118.5873],
  [-25.5008, 118.5683],
  [-25.4947, 118.5645],
  [-25.4772, 118.5585],
  [-25.4676, 118.5545],
  [-25.4664, 118.5544],
  [-25.4642, 118.5550],
  [-25.4632, 118.5549],
  [-25.4561, 118.5501],
  [-25.4539, 118.5493],
  [-25.4512, 118.5487],
  [-25.4466, 118.5500],
  [-25.4437, 118.5537],
  [-25.4428, 118.5544],
  [-25.4424, 118.5550],
  [-25.4418, 118.5565]
];

export const meekatharraHorseshoe: Line = {
  name: 'Meekatharra - Horseshoe',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1927-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
