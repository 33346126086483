import { Line } from "../../../trackTypes";

const wilsonsMill = [
  [-43.3708, 172.6403],
  [-43.3702, 172.6395],
  [-43.3700, 172.6386],
  [-43.3700, 172.6375],
  [-43.3735, 172.6301],
  [-43.3739, 172.6285],
  [-43.3757, 172.6129],
  [-43.3755, 172.6114],
  [-43.3751, 172.6101]
];

const millRoad = [
  [-43.3751, 172.6101],
  [-43.3490, 172.5314]
];

const junctionWetheral = [
  [-43.4067, 172.6490],
  [-43.4071, 172.6483],
  [-43.4074, 172.6474],
  [-43.4085, 172.6410],
  [-43.4084, 172.6395],
  [-43.4075, 172.6362],
  [-43.4060, 172.6277],
  [-43.4031, 172.6157],
  [-43.4024, 172.6139],
  [-43.3973, 172.6051],
  [-43.3927, 172.5910],
  [-43.3917, 172.5817],
  [-43.3794, 172.5350]
];

const wetheralWestEyreton = [
  [-43.3794, 172.5350],
  [-43.3386, 172.3818]
];

const westEyretonHorrelville = [
  [-43.3386, 172.3818],
  [-43.3192, 172.3094],
  [-43.3186, 172.3081],
  [-43.3166, 172.3063]
];

const horrelvilleBennettsJunction = [
  [-43.3166, 172.3063],
  [-43.3061, 172.2968],
  [-43.3059, 172.2964],
  [-43.3058, 172.2959]
];

export const eyreton: Line = {
  name: 'Eyreton',
  state: 'NZ',
  segments: [
    {
      segments: [wilsonsMill],
      history: {
        opened: {
          date: '1875-12-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [millRoad, wetheralWestEyreton],
      history: {
        opened: {
          date: '1875-12-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-05-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [junctionWetheral],
      history: {
        opened: {
          date: '1875-12-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1965-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westEyretonHorrelville],
      history: {
        opened: {
          date: '1878-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-05-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [horrelvilleBennettsJunction],
      history: {
        opened: {
          date: '1878-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-02-03',
          status: 'closed'
        }]
      }
    }
  ]
}
