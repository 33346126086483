import { Line } from "../../trackTypes";

const line = [
  [-37.8409, 144.9932],
  [-37.8398, 144.9918],
  [-37.8385, 144.9879],
  [-37.8382, 144.9856],
  [-37.8383, 144.9832],
  [-37.8379, 144.9795],
  [-37.8370, 144.9770],
  [-37.8360, 144.9755],
  [-37.8348, 144.9747],
  [-37.8338, 144.9738],
  [-37.8331, 144.9726],
  [-37.8313, 144.9714],
  [-37.8288, 144.9714],
  [-37.8270, 144.9714],
  [-37.8243, 144.9713],
  [-37.8217, 144.9705],
  [-37.8190, 144.9682],
  [-37.8068, 144.9625],
  [-37.8055, 144.9625],
  [-37.8046, 144.9631],
  [-37.8038, 144.9638],
  [-37.8023, 144.9639],
  [-37.8008, 144.9630],
  [-37.8000, 144.9611],
  [-37.7995, 144.9553],
  [-37.7995, 144.9538],
  [-37.8017, 144.9375],
  [-37.8014, 144.9352],
  [-37.8003, 144.9307]
];

export const metroTunnel: Line = {
  name: 'Metro Tunnel',
  state: 'VIC',
  segments: [
    {
      segments: [{
        segment: line,
        type: 'tunnel'
      }],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    }
  ]
};
