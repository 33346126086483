import { Line } from "../../../trackTypes";

const line = [
  [-42.4050, 171.2492],
  [-42.3991, 171.2474],
  [-42.3980, 171.2473],
  [-42.3902, 171.2493],
  [-42.3886, 171.2491],
  [-42.3836, 171.2467],
  [-42.3821, 171.2466],
  [-42.3777, 171.2475],
  [-42.3742, 171.2474]
];

export const rapahoe: Line = {
  name: 'Rapahoe',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1923-09-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
