import { Line } from "../../../trackTypes";

const sawyersBayOtagoPortTunnel = [
  [-45.8211, 170.6052],
  [-45.8205, 170.6064],
  [-45.8203, 170.6073],
  [-45.8203, 170.6080],
  [-45.8202, 170.6089],
  [-45.8200, 170.6094],
  [-45.8195, 170.6102],
  [-45.8193, 170.6111],
  [-45.8192, 170.6119],
  [-45.8193, 170.6130],
  [-45.8193, 170.6141],
  [-45.8182, 170.6185],
  [-45.8169, 170.6214]
];

const otagoPortTunnel = [
  [-45.8169, 170.6214],
  [-45.8163, 170.6225],
  [-45.8157, 170.6231]
];

const otagoPortTunnelPortChalmers = [
  [-45.8157, 170.6231],
  [-45.8156, 170.6232],
  [-45.8155, 170.6237],
  [-45.8155, 170.6243],
  [-45.8155, 170.6247],
  [-45.8151, 170.6267],
  [-45.8150, 170.6272],
  [-45.8151, 170.6277],
  [-45.8155, 170.6281],
  [-45.8158, 170.6283],
  [-45.8161, 170.6283],
  [-45.8171, 170.6280],
  [-45.8178, 170.6275],
  [-45.8196, 170.6270]
];

const harbourTwo = [
  [-45.8156, 170.6232],
  [-45.8153, 170.6233],
  [-45.8147, 170.6233],
  [-45.8144, 170.6231],
  [-45.8138, 170.6221],
  [-45.8131, 170.6219],
  [-45.8125, 170.6221],
  [-45.8110, 170.6242],
  [-45.8098, 170.6261]
];

const harbourThree = [
  [-45.8153, 170.6233],
  [-45.8144, 170.6235],
  [-45.8141, 170.6237],
  [-45.8122, 170.6263],
  [-45.8120, 170.6265],
  [-45.8098, 170.6276]
];

export const portChalmers: Line = {
  name: 'Port Chalmers',
  state: 'NZ',
  segments: [
    {
      segments: [
        sawyersBayOtagoPortTunnel,
        {
          name: 'Otago Port Tunnel',
          segment: otagoPortTunnel,
          type: 'tunnel'
        },
        otagoPortTunnelPortChalmers,
        harbourTwo,
        harbourThree
      ],
      history: {
        opened: {
          date: '1873-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
