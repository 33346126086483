import { Line } from "../../trackTypes";

const line = [
  [-25.9619, 153.0251],
  [-25.9631, 153.0268],
  [-25.9676, 153.0300],
  [-25.9687, 153.0313],
  [-25.9711, 153.0331],
  [-25.9718, 153.0338],
  [-25.9720, 153.0344],
  [-25.9723, 153.0361],
  [-25.9722, 153.0367],
  [-25.9717, 153.0378],
  [-25.9713, 153.0392],
  [-25.9714, 153.0405],
  [-25.9710, 153.0426],
  [-25.9706, 153.0433],
  [-25.9696, 153.0442],
  [-25.9693, 153.0450],
  [-25.9693, 153.0467],
  [-25.9692, 153.0476],
  [-25.9695, 153.0514],
  [-25.9693, 153.0541],
  [-25.9689, 153.0553],
  [-25.9684, 153.0561],
  [-25.9679, 153.0581],
  [-25.9680, 153.0593],
  [-25.9681, 153.0604],
  [-25.9688, 153.0622],
  [-25.9696, 153.0629],
  [-25.9708, 153.0635],
  [-25.9717, 153.0637],
  [-25.9722, 153.0640],
  [-25.9740, 153.0657],
  [-25.9752, 153.0664],
  [-25.9766, 153.0668],
  [-25.9781, 153.0679],
  [-25.9797, 153.0689],
  [-25.9804, 153.0696],
  [-25.9810, 153.0698],
  [-25.9822, 153.0704],
  [-25.9847, 153.0715],
  [-25.9882, 153.0729],
  [-25.9916, 153.0741],
  [-25.9926, 153.0751],
  [-25.9943, 153.0771],
  [-25.9944, 153.0779],
  [-25.9941, 153.0788],
  [-25.9930, 153.0801],
  [-25.9926, 153.0812],
  [-25.9917, 153.0819],
  [-25.9903, 153.0819],
  [-25.9891, 153.0823],
  [-25.9882, 153.0823],
  [-25.9877, 153.0827],
  [-25.9875, 153.0833],
  [-25.9878, 153.0840],
  [-25.9899, 153.0860],
  [-25.9909, 153.0867],
  [-25.9929, 153.0907],
  [-25.9931, 153.0927],
  [-25.9937, 153.0958],
  [-25.9964, 153.0998],
  [-25.9976, 153.1001],
  [-26.0028, 153.1037],
  [-26.0052, 153.1052],
  [-26.0084, 153.1081]
];

export const cooloola: Line = {
  name: 'Cooloola',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1873-10-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1884-03-01',
          status: 'closed'
        }]
      }
    }
  ]
}
