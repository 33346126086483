import { Line } from "../../../../trackTypes";

const gladstoneRoad = [
  [-27.47714, 153.01278],
  [-27.47732, 153.01282],
  [-27.47741, 153.01287],
  [-27.48140, 153.01559],
  [-27.48146, 153.01564],
  [-27.48152, 153.01575],
  [-27.48196, 153.01865],
  [-27.48204, 153.01876],
  [-27.48219, 153.01893],
  [-27.48304, 153.01960],
  [-27.48316, 153.01966],
  [-27.48328, 153.01968],
  [-27.48487, 153.01972],
  [-27.48513, 153.01970],
  [-27.48682, 153.01940],
  [-27.48702, 153.01934],
  [-27.48731, 153.01920],
  [-27.48799, 153.01880],
  [-27.48820, 153.01871],
  [-27.48846, 153.01865],
  [-27.48918, 153.01860],
  [-27.48936, 153.01860],
  [-27.49029, 153.01870],
  [-27.49048, 153.01878],
  [-27.49063, 153.01888],
  [-27.49150, 153.01997],
  [-27.49172, 153.02022]
];

const duttonPark = [
  [-27.49172, 153.02022],
  [-27.49385, 153.02247],
  [-27.49404, 153.02270],
  [-27.49417, 153.02295],
  [-27.49489, 153.02448],
  [-27.49503, 153.02470],
  [-27.49518, 153.02487],
  [-27.49648, 153.02609],
  [-27.49661, 153.02624],
  [-27.49666, 153.02634],
  [-27.49681, 153.02672],
  [-27.49689, 153.02687],
  [-27.49704, 153.02701],
  [-27.49796, 153.02754],
  [-27.49906, 153.02796],
  [-27.49945, 153.02818],
  [-27.49986, 153.02846]
];

const oKeefStreet = [
  [-27.49986, 153.02846],
  [-27.50040, 153.02888],
  [-27.50041, 153.02897],
  [-27.50036, 153.02908],
  [-27.50026, 153.02923],
  [-27.50023, 153.02935],
  [-27.50059, 153.03209],
  [-27.50098, 153.03488],
  [-27.50092, 153.03503],
  [-27.50077, 153.03516]
];

export const south3: Line = {
  name: 'South 3',
  state: 'QLD',
  segments: [
    {
      segments: [gladstoneRoad],
      history: {
        opened: {
          date: '1901-12-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [duttonPark],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oKeefStreet],
      history: {
        opened: {
          date: '1961-03-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    }
  ]
}
