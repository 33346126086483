import styled from 'styled-components';

const Button = styled.button`
  border-radius: 1rem;
  font-size: 1rem;
  border: 1px solid grey;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.textColour};

  &:hover {
    background-color: ${({ theme }) => theme.backgroundHover};
  }
`;

export default Button;
