import { Line } from "../../trackTypes";

const junctionGlenbrook = [
  [-37.1780, 174.9017],
  [-37.1776, 174.9012],
  [-37.1774, 174.9007],
  [-37.1773, 174.9001],
  [-37.1773, 174.8996],
  [-37.1775, 174.8988],
  [-37.1781, 174.8978],
  [-37.1790, 174.8956],
  [-37.1797, 174.8948],
  [-37.1805, 174.8941],
  [-37.1815, 174.8935],
  [-37.1822, 174.8930],
  [-37.1827, 174.8922],
  [-37.1831, 174.8915],
  [-37.1834, 174.8904],
  [-37.1846, 174.8801],
  [-37.1846, 174.8784],
  [-37.1840, 174.8765],
  [-37.1839, 174.8756],
  [-37.1839, 174.8742],
  [-37.1852, 174.8677],
  [-37.1860, 174.8660],
  [-37.1875, 174.8646],
  [-37.1882, 174.8635],
  [-37.1899, 174.8600],
  [-37.1903, 174.8588],
  [-37.1911, 174.8551],
  [-37.1911, 174.8539],
  [-37.1901, 174.8421],
  [-37.1903, 174.8408],
  [-37.1912, 174.8379],
  [-37.1914, 174.8368],
  [-37.1920, 174.8280],
  [-37.1925, 174.8256],
  [-37.1949, 174.8213],
  [-37.1998, 174.8167],
  [-37.2006, 174.8157],
  [-37.2028, 174.8110],
  [-37.2033, 174.8088],
  [-37.2039, 174.8074],
  [-37.2065, 174.8031],
  [-37.2070, 174.8017],
  [-37.2092, 174.7914],
  [-37.2092, 174.7893],
  [-37.2069, 174.7821]
];

const junction = [
  [-37.1775, 174.8988],
  [-37.1768, 174.8997],
  [-37.1760, 174.9003],
  [-37.1749, 174.9008],
  [-37.1740, 174.9008]
];

const glenbrookPukeoware = [
  [-37.2069, 174.7821],
  [-37.2070, 174.7804],
  [-37.2074, 174.7793],
  [-37.2080, 174.7785],
  [-37.2128, 174.7744],
  [-37.2147, 174.7729],
  [-37.2156, 174.7720],
  [-37.2185, 174.7684],
  [-37.2273, 174.7596],
  [-37.2283, 174.7583],
  [-37.2291, 174.7576],
  [-37.2311, 174.7566]
];

const pukeowareFernleigh = [
  [-37.2311, 174.7566],
  [-37.2397, 174.7521],
  [-37.2408, 174.7513],
  [-37.2459, 174.7464],
  [-37.2464, 174.7456],
  [-37.2482, 174.7414]
];

const fernleighVictoriaAvenueJunction = [
  [-37.2482, 174.7414],
  [-37.2503, 174.7366],
  [-37.2508, 174.7358]
];

const victoriaAvenueJunctionVictoriaAvenue = [
  [-37.2508, 174.7358],
  [-37.2509, 174.7351],
  [-37.2509, 174.7343],
  [-37.2502, 174.7329],
  [-37.2500, 174.7327]
]

const victoriaAvenueJunctionWaiuku = [
  [-37.2508, 174.7358],
  [-37.2517, 174.7341],
  [-37.2531, 174.7323],
  [-37.2544, 174.7311],
  [-37.2585, 174.7274]
];

export const waiuku: Line = {
  name: 'Waiuku',
  state: 'NZ',
  segments: [
    {
      segments: [junctionGlenbrook, junction],
      history: {
        opened: {
          date: '1922-05-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [glenbrookPukeoware],
      history: {
        opened: {
          date: '1922-05-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-12-31',
          status: 'closed'
        }, {
          date: '1977-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [pukeowareFernleigh],
      history: {
        opened: {
          date: '1922-05-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-12-31',
          status: 'closed'
        }, {
          date: '1986-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [fernleighVictoriaAvenueJunction],
      history: {
        opened: {
          date: '1922-05-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-12-31',
          status: 'closed'
        }, {
          date: '2010-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [victoriaAvenueJunctionVictoriaAvenue],
      history: {
        opened: {
          date: '2010-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [victoriaAvenueJunctionWaiuku],
      history: {
        opened: {
          date: '1922-05-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-12-31',
          status: 'closed'
        }]
      }
    }
  ]
}
