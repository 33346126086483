import { Line } from "../../../trackTypes";

const almadenMountSurprise = [
  [-17.3378, 144.6776],
  [-17.3375, 144.6771],
  [-17.3373, 144.6764],
  [-17.3373, 144.6742],
  [-17.3375, 144.6735],
  [-17.3380, 144.6730],
  [-17.3400, 144.6722],
  [-17.3412, 144.6710],
  [-17.3417, 144.6703],
  [-17.3425, 144.6698],
  [-17.3459, 144.6697],
  [-17.3465, 144.6694],
  [-17.3477, 144.6683],
  [-17.3482, 144.6675],
  [-17.3491, 144.6651],
  [-17.3495, 144.6645],
  [-17.3501, 144.6642],
  [-17.3521, 144.6637],
  [-17.3530, 144.6629],
  [-17.3539, 144.6627],
  [-17.3665, 144.6663],
  [-17.3688, 144.6665],
  [-17.3727, 144.6676],
  [-17.3743, 144.6674],
  [-17.3772, 144.6659],
  [-17.3796, 144.6633],
  [-17.3805, 144.6627],
  [-17.3857, 144.6612],
  [-17.3907, 144.6608],
  [-17.3916, 144.6605],
  [-17.4039, 144.6530],
  [-17.4067, 144.6527],
  [-17.4083, 144.6531],
  [-17.4093, 144.6531],
  [-17.4129, 144.6530],
  [-17.4155, 144.6539],
  [-17.4179, 144.6543],
  [-17.4187, 144.6540],
  [-17.4208, 144.6522],
  [-17.4227, 144.6499],
  [-17.4234, 144.6486],
  [-17.4244, 144.6477],
  [-17.4251, 144.6475],
  [-17.4264, 144.6475],
  [-17.4282, 144.6469],
  [-17.4287, 144.6463],
  [-17.4289, 144.6457],
  [-17.4286, 144.6440],
  [-17.4288, 144.6431],
  [-17.4294, 144.6421],
  [-17.4334, 144.6380],
  [-17.4364, 144.6340],
  [-17.4395, 144.6313],
  [-17.4408, 144.6281],
  [-17.4415, 144.6273],
  [-17.4468, 144.6254],
  [-17.4499, 144.6247],
  [-17.4519, 144.6239],
  [-17.4538, 144.6240],
  [-17.4559, 144.6230],
  [-17.4585, 144.6232],
  [-17.4602, 144.6236],
  [-17.4612, 144.6236],
  [-17.4630, 144.6226],
  [-17.4665, 144.6222],
  [-17.4731, 144.6236],
  [-17.4741, 144.6239],
  [-17.4752, 144.6241],
  [-17.4772, 144.6235],
  [-17.4780, 144.6235],
  [-17.4800, 144.6247],
  [-17.4814, 144.6250],
  [-17.4829, 144.6246],
  [-17.4834, 144.6246],
  [-17.4855, 144.6255],
  [-17.4877, 144.6260],
  [-17.4889, 144.6257],
  [-17.4895, 144.6257],
  [-17.4918, 144.6264],
  [-17.4930, 144.6264],
  [-17.4952, 144.6273],
  [-17.4960, 144.6274],
  [-17.4968, 144.6272],
  [-17.5063, 144.6271],
  [-17.5072, 144.6267],
  [-17.5100, 144.6237],
  [-17.5124, 144.6222],
  [-17.5135, 144.6221],
  [-17.5164, 144.6233],
  [-17.5212, 144.6236],
  [-17.5220, 144.6239],
  [-17.5253, 144.6268],
  [-17.5264, 144.6271],
  [-17.5279, 144.6265],
  [-17.5352, 144.6268],
  [-17.5375, 144.6261],
  [-17.5384, 144.6261],
  [-17.5398, 144.6266],
  [-17.5442, 144.6274],
  [-17.5452, 144.6273],
  [-17.5467, 144.6267],
  [-17.5527, 144.6277],
  [-17.5536, 144.6274],
  [-17.5543, 144.6268],
  [-17.5549, 144.6255],
  [-17.5555, 144.6247],
  [-17.5613, 144.6214],
  [-17.5716, 144.6193],
  [-17.5840, 144.6153],
  [-17.5846, 144.6149],
  [-17.5880, 144.6116],
  [-17.5889, 144.6086],
  [-17.5888, 144.6077],
  [-17.5878, 144.6040],
  [-17.5878, 144.6028],
  [-17.5890, 144.5974],
  [-17.5907, 144.5949],
  [-17.5914, 144.5944],
  [-17.5925, 144.5942],
  [-17.5935, 144.5932],
  [-17.5939, 144.5922],
  [-17.5945, 144.5912],
  [-17.5980, 144.5881],
  [-17.5985, 144.5873],
  [-17.5987, 144.5866],
  [-17.6001, 144.5840],
  [-17.6008, 144.5830],
  [-17.6010, 144.5822],
  [-17.6008, 144.5797],
  [-17.6011, 144.5788],
  [-17.6021, 144.5782],
  [-17.6066, 144.5781],
  [-17.6080, 144.5783],
  [-17.6087, 144.5781],
  [-17.6116, 144.5758],
  [-17.6142, 144.5743],
  [-17.6148, 144.5742],
  [-17.6154, 144.5744],
  [-17.6163, 144.5751],
  [-17.6168, 144.5752],
  [-17.6190, 144.5751],
  [-17.6200, 144.5743],
  [-17.6210, 144.5742],
  [-17.6219, 144.5746],
  [-17.6227, 144.5747],
  [-17.6245, 144.5746],
  [-17.6253, 144.5748],
  [-17.6262, 144.5757],
  [-17.6271, 144.5760],
  [-17.6279, 144.5758],
  [-17.6286, 144.5752],
  [-17.6290, 144.5745],
  [-17.6292, 144.5725],
  [-17.6295, 144.5720],
  [-17.6305, 144.5700],
  [-17.6305, 144.5690],
  [-17.6301, 144.5679],
  [-17.6303, 144.5668],
  [-17.6310, 144.5661],
  [-17.6330, 144.5658],
  [-17.6339, 144.5651],
  [-17.6348, 144.5637],
  [-17.6356, 144.5632],
  [-17.6367, 144.5634],
  [-17.6373, 144.5639],
  [-17.6378, 144.5647],
  [-17.6387, 144.5653],
  [-17.6410, 144.5652],
  [-17.6419, 144.5647],
  [-17.6435, 144.5626],
  [-17.6441, 144.5621],
  [-17.6463, 144.5615],
  [-17.6494, 144.5585],
  [-17.6496, 144.5575],
  [-17.6494, 144.5551],
  [-17.6496, 144.5539],
  [-17.6510, 144.5518],
  [-17.6522, 144.5510],
  [-17.6526, 144.5505],
  [-17.6534, 144.5488],
  [-17.6541, 144.5481],
  [-17.6553, 144.5475],
  [-17.6564, 144.5462],
  [-17.6565, 144.5453],
  [-17.6565, 144.5446],
  [-17.6566, 144.5438],
  [-17.6577, 144.5427],
  [-17.6594, 144.5423],
  [-17.6602, 144.5416],
  [-17.6609, 144.5403],
  [-17.6617, 144.5397],
  [-17.6628, 144.5395],
  [-17.6635, 144.5391],
  [-17.6676, 144.5333],
  [-17.6698, 144.5312],
  [-17.6737, 144.5289],
  [-17.6752, 144.5286],
  [-17.6758, 144.5283],
  [-17.6776, 144.5265],
  [-17.6804, 144.5248],
  [-17.6823, 144.5242],
  [-17.6830, 144.5241],
  [-17.6850, 144.5246],
  [-17.6858, 144.5246],
  [-17.6874, 144.5241],
  [-17.6881, 144.5241],
  [-17.6894, 144.5245],
  [-17.6910, 144.5245],
  [-17.6935, 144.5236],
  [-17.6963, 144.5231],
  [-17.7034, 144.5205],
  [-17.7041, 144.5201],
  [-17.7051, 144.5183],
  [-17.7058, 144.5177],
  [-17.7082, 144.5164],
  [-17.7103, 144.5143],
  [-17.7132, 144.5128],
  [-17.7170, 144.5112],
  [-17.7175, 144.5108],
  [-17.7178, 144.5101],
  [-17.7179, 144.5058],
  [-17.7182, 144.5051],
  [-17.7197, 144.5025],
  [-17.7198, 144.5018],
  [-17.7197, 144.5004],
  [-17.7199, 144.4997],
  [-17.7203, 144.4991],
  [-17.7233, 144.4968],
  [-17.7316, 144.4934],
  [-17.7357, 144.4932],
  [-17.7365, 144.4928],
  [-17.7375, 144.4918],
  [-17.7381, 144.4897],
  [-17.7387, 144.4890],
  [-17.7407, 144.4878],
  [-17.7429, 144.4849],
  [-17.7464, 144.4815],
  [-17.7467, 144.4809],
  [-17.7469, 144.4800],
  [-17.7473, 144.4793],
  [-17.7496, 144.4778],
  [-17.7514, 144.4758],
  [-17.7548, 144.4731],
  [-17.7566, 144.4700],
  [-17.7581, 144.4688],
  [-17.7652, 144.4602],
  [-17.7716, 144.4553],
  [-17.7739, 144.4542],
  [-17.7758, 144.4530],
  [-17.7806, 144.4516],
  [-17.7871, 144.4486],
  [-17.7881, 144.4486],
  [-17.7908, 144.4501],
  [-17.7945, 144.4506],
  [-17.8031, 144.4492],
  [-17.8090, 144.4499],
  [-17.8134, 144.4486],
  [-17.8179, 144.4483],
  [-17.8211, 144.4492],
  [-17.8220, 144.4490],
  [-17.8242, 144.4467],
  [-17.8260, 144.4456],
  [-17.8271, 144.4456],
  [-17.8281, 144.4461],
  [-17.8288, 144.4462],
  [-17.8293, 144.4458],
  [-17.8302, 144.4439],
  [-17.8304, 144.4427],
  [-17.8310, 144.4418],
  [-17.8321, 144.4413],
  [-17.8336, 144.4416],
  [-17.8396, 144.4452],
  [-17.8450, 144.4504],
  [-17.8485, 144.4519],
  [-17.8491, 144.4520],
  [-17.8728, 144.4495],
  [-17.8789, 144.4472],
  [-17.8794, 144.4469],
  [-17.8803, 144.4458],
  [-17.8934, 144.4403],
  [-17.8942, 144.4394],
  [-17.8945, 144.4377],
  [-17.8948, 144.4370],
  [-17.8953, 144.4366],
  [-17.9080, 144.4308],
  [-17.9308, 144.4127],
  [-17.9509, 144.4072],
  [-18.0549, 144.3692],
  [-18.0724, 144.3674],
  [-18.0760, 144.3683],
  [-18.0768, 144.3683],
  [-18.0779, 144.3677],
  [-18.0806, 144.3642],
  [-18.0857, 144.3614],
  [-18.0906, 144.3567],
  [-18.0991, 144.3521],
  [-18.1166, 144.3408],
  [-18.1192, 144.3399],
  [-18.1292, 144.3337],
  [-18.1366, 144.3277],
  [-18.1400, 144.3255],
  [-18.1454, 144.3202],
  [-18.1465, 144.3185],
  [-18.1473, 144.3166]
];

const mountSurpriseEinasleigh = [
  [-18.1473, 144.3166],
  [-18.1478, 144.3154],
  [-18.1490, 144.3136],
  [-18.1594, 144.3037],
  [-18.1726, 144.2889],
  [-18.1734, 144.2870],
  [-18.1745, 144.2859],
  [-18.1769, 144.2844],
  [-18.1814, 144.2793],
  [-18.1824, 144.2763],
  [-18.1821, 144.2698],
  [-18.1823, 144.2682],
  [-18.1839, 144.2649],
  [-18.1850, 144.2637],
  [-18.1866, 144.2626],
  [-18.1871, 144.2617],
  [-18.1871, 144.2597],
  [-18.1874, 144.2588],
  [-18.1885, 144.2574],
  [-18.1896, 144.2568],
  [-18.1916, 144.2565],
  [-18.1928, 144.2556],
  [-18.1935, 144.2535],
  [-18.1946, 144.2523],
  [-18.1972, 144.2514],
  [-18.1989, 144.2503],
  [-18.2011, 144.2458],
  [-18.2042, 144.2426],
  [-18.2078, 144.2345],
  [-18.2095, 144.2294],
  [-18.2101, 144.2283],
  [-18.2115, 144.2269],
  [-18.2122, 144.2250],
  [-18.2120, 144.2226],
  [-18.2126, 144.2196],
  [-18.2133, 144.2182],
  [-18.2150, 144.2171],
  [-18.2168, 144.2151],
  [-18.2194, 144.2137],
  [-18.2226, 144.2089],
  [-18.2231, 144.2071],
  [-18.2230, 144.2050],
  [-18.2240, 144.2013],
  [-18.2280, 144.1968],
  [-18.2384, 144.1913],
  [-18.2428, 144.1899],
  [-18.2439, 144.1899],
  [-18.2459, 144.1902],
  [-18.2469, 144.1900],
  [-18.2477, 144.1893],
  [-18.2502, 144.1848],
  [-18.2519, 144.1833],
  [-18.2557, 144.1817],
  [-18.2571, 144.1806],
  [-18.2615, 144.1732],
  [-18.2627, 144.1722],
  [-18.2674, 144.1710],
  [-18.2690, 144.1698],
  [-18.2707, 144.1674],
  [-18.2712, 144.1659],
  [-18.2714, 144.1638],
  [-18.2721, 144.1621],
  [-18.2734, 144.1610],
  [-18.2742, 144.1607],
  [-18.2750, 144.1607],
  [-18.2767, 144.1617],
  [-18.2773, 144.1618],
  [-18.2781, 144.1615],
  [-18.2792, 144.1609],
  [-18.2804, 144.1606],
  [-18.2824, 144.1606],
  [-18.2833, 144.1611],
  [-18.2841, 144.1620],
  [-18.2850, 144.1625],
  [-18.2875, 144.1630],
  [-18.2900, 144.1624],
  [-18.2909, 144.1617],
  [-18.2927, 144.1600],
  [-18.2946, 144.1588],
  [-18.2958, 144.1584],
  [-18.3004, 144.1573],
  [-18.3026, 144.1577],
  [-18.3042, 144.1591],
  [-18.3052, 144.1594],
  [-18.3060, 144.1592],
  [-18.3072, 144.1578],
  [-18.3085, 144.1569],
  [-18.3159, 144.1560],
  [-18.3171, 144.1555],
  [-18.3187, 144.1545],
  [-18.3200, 144.1542],
  [-18.3231, 144.1542],
  [-18.3249, 144.1534],
  [-18.3269, 144.1511],
  [-18.3285, 144.1499],
  [-18.3306, 144.1490],
  [-18.3333, 144.1488],
  [-18.3368, 144.1471],
  [-18.3380, 144.1468],
  [-18.3393, 144.1467],
  [-18.3402, 144.1463],
  [-18.3410, 144.1454],
  [-18.3416, 144.1442],
  [-18.3422, 144.1437],
  [-18.3463, 144.1431],
  [-18.3473, 144.1424],
  [-18.3483, 144.1403],
  [-18.3492, 144.1397],
  [-18.3596, 144.1374],
  [-18.3621, 144.1356],
  [-18.3689, 144.1341],
  [-18.3748, 144.1342],
  [-18.3762, 144.1346],
  [-18.3773, 144.1345],
  [-18.3837, 144.1324],
  [-18.3857, 144.1307],
  [-18.3871, 144.1303],
  [-18.3889, 144.1315],
  [-18.3902, 144.1317],
  [-18.3934, 144.1313],
  [-18.3947, 144.1316],
  [-18.3963, 144.1323],
  [-18.3973, 144.1322],
  [-18.3984, 144.1311],
  [-18.3997, 144.1306],
  [-18.4043, 144.1272],
  [-18.4060, 144.1256],
  [-18.4090, 144.1249],
  [-18.4098, 144.1244],
  [-18.4101, 144.1237],
  [-18.4108, 144.1217],
  [-18.4129, 144.1195],
  [-18.4150, 144.1186],
  [-18.4159, 144.1186],
  [-18.4174, 144.1195],
  [-18.4257, 144.1229],
  [-18.4269, 144.1228],
  [-18.4293, 144.1213],
  [-18.4321, 144.1208],
  [-18.4334, 144.1212],
  [-18.4376, 144.1240],
  [-18.4387, 144.1244],
  [-18.4424, 144.1246],
  [-18.4433, 144.1244],
  [-18.4478, 144.1223],
  [-18.4493, 144.1221],
  [-18.4526, 144.1228],
  [-18.4539, 144.1227],
  [-18.4572, 144.1205],
  [-18.4583, 144.1202],
  [-18.4604, 144.1205],
  [-18.4651, 144.1192],
  [-18.4666, 144.1179],
  [-18.4677, 144.1176],
  [-18.4691, 144.1181],
  [-18.4699, 144.1180],
  [-18.4707, 144.1176],
  [-18.4723, 144.1177],
  [-18.4736, 144.1189],
  [-18.4745, 144.1192],
  [-18.4774, 144.1192],
  [-18.4788, 144.1184],
  [-18.4802, 144.1159],
  [-18.4842, 144.1124],
  [-18.4847, 144.1106],
  [-18.4854, 144.1098],
  [-18.4871, 144.1091],
  [-18.4886, 144.1081],
  [-18.4900, 144.1078],
  [-18.4915, 144.1070],
  [-18.4936, 144.1064],
  [-18.4955, 144.1056],
  [-18.4973, 144.1054],
  [-18.4987, 144.1057],
  [-18.5072, 144.1098],
  [-18.5078, 144.1104],
  [-18.5087, 144.1107],
  [-18.5114, 144.1105],
  [-18.5125, 144.1098],
  [-18.5131, 144.1089],
  [-18.5142, 144.1012],
  [-18.5135, 144.0936]
];

const einasleighForsayth = [
  [-18.5135, 144.0936],
  [-18.5137, 144.0922],
  [-18.5145, 144.0908],
  [-18.5159, 144.0898],
  [-18.5322, 144.0815],
  [-18.5331, 144.0812],
  [-18.5357, 144.0810],
  [-18.5365, 144.0806],
  [-18.5372, 144.0799],
  [-18.5380, 144.0783],
  [-18.5387, 144.0776],
  [-18.5442, 144.0747],
  [-18.5511, 144.0685],
  [-18.5752, 144.0550],
  [-18.5762, 144.0540],
  [-18.5765, 144.0517],
  [-18.5769, 144.0509],
  [-18.5794, 144.0482],
  [-18.5802, 144.0464],
  [-18.5858, 144.0400],
  [-18.5938, 144.0250],
  [-18.6015, 144.0167],
  [-18.6026, 144.0148],
  [-18.6033, 144.0144],
  [-18.6042, 144.0145],
  [-18.6058, 144.0161],
  [-18.6067, 144.0163],
  [-18.6076, 144.0160],
  [-18.6087, 144.0138],
  [-18.6095, 144.0130],
  [-18.6115, 144.0129],
  [-18.6133, 144.0136],
  [-18.6139, 144.0136],
  [-18.6143, 144.0133],
  [-18.6146, 144.0122],
  [-18.6145, 144.0113],
  [-18.6133, 144.0098],
  [-18.6130, 144.0092],
  [-18.6131, 144.0087],
  [-18.6138, 144.0079],
  [-18.6144, 144.0077],
  [-18.6151, 144.0079],
  [-18.6161, 144.0091],
  [-18.6167, 144.0092],
  [-18.6172, 144.0090],
  [-18.6186, 144.0071],
  [-18.6187, 144.0064],
  [-18.6184, 144.0060],
  [-18.6181, 144.0058],
  [-18.6153, 144.0056],
  [-18.6148, 144.0053],
  [-18.6144, 144.0043],
  [-18.6140, 144.0038],
  [-18.6134, 144.0035],
  [-18.6130, 144.0028],
  [-18.6128, 144.0020],
  [-18.6132, 144.0011],
  [-18.6144, 144.0001],
  [-18.6147, 143.9994],
  [-18.6145, 143.9989],
  [-18.6141, 143.9985],
  [-18.6132, 143.9982],
  [-18.6128, 143.9977],
  [-18.6128, 143.9970],
  [-18.6134, 143.9945],
  [-18.6132, 143.9937],
  [-18.6120, 143.9927],
  [-18.6118, 143.9922],
  [-18.6116, 143.9914],
  [-18.6112, 143.9909],
  [-18.6106, 143.9908],
  [-18.6095, 143.9914],
  [-18.6091, 143.9913],
  [-18.6087, 143.9909],
  [-18.6083, 143.9885],
  [-18.6079, 143.9879],
  [-18.6071, 143.9877],
  [-18.6053, 143.9881],
  [-18.6047, 143.9879],
  [-18.6044, 143.9873],
  [-18.6048, 143.9841],
  [-18.6058, 143.9826],
  [-18.6060, 143.9821],
  [-18.6058, 143.9815],
  [-18.6052, 143.9810],
  [-18.6049, 143.9802],
  [-18.6051, 143.9792],
  [-18.6062, 143.9781],
  [-18.6069, 143.9779],
  [-18.6083, 143.9778],
  [-18.6097, 143.9770],
  [-18.6111, 143.9751],
  [-18.6114, 143.9742],
  [-18.6113, 143.9713],
  [-18.6108, 143.9676],
  [-18.6109, 143.9669],
  [-18.6112, 143.9663],
  [-18.6123, 143.9651],
  [-18.6131, 143.9633],
  [-18.6132, 143.9616],
  [-18.6154, 143.9570],
  [-18.6157, 143.9555],
  [-18.6161, 143.9548],
  [-18.6166, 143.9544],
  [-18.6174, 143.9540],
  [-18.6189, 143.9536],
  [-18.6194, 143.9531],
  [-18.6195, 143.9524],
  [-18.6192, 143.9518],
  [-18.6186, 143.9513],
  [-18.6183, 143.9509],
  [-18.6178, 143.9489],
  [-18.6179, 143.9482],
  [-18.6180, 143.9475],
  [-18.6181, 143.9468],
  [-18.6177, 143.9448],
  [-18.6179, 143.9438],
  [-18.6185, 143.9431],
  [-18.6192, 143.9429],
  [-18.6200, 143.9432],
  [-18.6208, 143.9440],
  [-18.6217, 143.9443],
  [-18.6227, 143.9440],
  [-18.6235, 143.9432],
  [-18.6238, 143.9425],
  [-18.6247, 143.9223],
  [-18.6249, 143.9214],
  [-18.6254, 143.9204],
  [-18.6256, 143.9197],
  [-18.6253, 143.9183],
  [-18.6254, 143.9172],
  [-18.6279, 143.9123],
  [-18.6281, 143.9111],
  [-18.6277, 143.9088],
  [-18.6273, 143.9080],
  [-18.6258, 143.9053],
  [-18.6254, 143.9048],
  [-18.6248, 143.9044],
  [-18.6243, 143.9039],
  [-18.6242, 143.9033],
  [-18.6244, 143.9025],
  [-18.6255, 143.9016],
  [-18.6266, 143.9002],
  [-18.6268, 143.8993],
  [-18.6265, 143.8985],
  [-18.6259, 143.8979],
  [-18.6257, 143.8975],
  [-18.6251, 143.8950],
  [-18.6246, 143.8943],
  [-18.6234, 143.8936],
  [-18.6227, 143.8931],
  [-18.6197, 143.8899],
  [-18.6194, 143.8893],
  [-18.6195, 143.8886],
  [-18.6200, 143.8876],
  [-18.6202, 143.8868],
  [-18.6193, 143.8793],
  [-18.6194, 143.8284],
  [-18.6222, 143.8073],
  [-18.6222, 143.8044],
  [-18.6198, 143.7856],
  [-18.6194, 143.7844],
  [-18.6175, 143.7817],
  [-18.6171, 143.7806],
  [-18.6172, 143.7793],
  [-18.6180, 143.7775],
  [-18.6182, 143.7762],
  [-18.6179, 143.7695],
  [-18.6175, 143.7683],
  [-18.6162, 143.7666],
  [-18.6156, 143.7651],
  [-18.6158, 143.7631],
  [-18.6177, 143.7594],
  [-18.6180, 143.7581],
  [-18.6175, 143.7566],
  [-18.6140, 143.7523],
  [-18.6119, 143.7503],
  [-18.6110, 143.7497],
  [-18.6099, 143.7493],
  [-18.6093, 143.7487],
  [-18.6083, 143.7460],
  [-18.6071, 143.7441],
  [-18.6053, 143.7384],
  [-18.6053, 143.7377],
  [-18.6057, 143.7362],
  [-18.6058, 143.7353],
  [-18.6053, 143.7327],
  [-18.6050, 143.7319],
  [-18.6036, 143.7292],
  [-18.6034, 143.7285],
  [-18.6036, 143.7272],
  [-18.6063, 143.7240],
  [-18.6066, 143.7229],
  [-18.6061, 143.7204],
  [-18.6058, 143.7199],
  [-18.6034, 143.7174],
  [-18.6028, 143.7164],
  [-18.6009, 143.7148],
  [-18.6006, 143.7142],
  [-18.6004, 143.7132],
  [-18.6000, 143.7127],
  [-18.5987, 143.7123],
  [-18.5975, 143.7112],
  [-18.5972, 143.7107],
  [-18.5969, 143.7096],
  [-18.5966, 143.7092],
  [-18.5960, 143.7087],
  [-18.5957, 143.7082],
  [-18.5957, 143.7077],
  [-18.5960, 143.7073],
  [-18.5967, 143.7068],
  [-18.5970, 143.7064],
  [-18.5971, 143.7060],
  [-18.5970, 143.7054],
  [-18.5970, 143.7050],
  [-18.5971, 143.7046],
  [-18.5971, 143.7042],
  [-18.5970, 143.7037],
  [-18.5969, 143.7031],
  [-18.5971, 143.7025],
  [-18.5975, 143.7021],
  [-18.5976, 143.7016],
  [-18.5975, 143.7008],
  [-18.5975, 143.7002],
  [-18.5974, 143.6991],
  [-18.5975, 143.6987],
  [-18.5979, 143.6977],
  [-18.5983, 143.6973],
  [-18.5987, 143.6972],
  [-18.5992, 143.6973],
  [-18.5999, 143.6970],
  [-18.6003, 143.6965],
  [-18.6003, 143.6960],
  [-18.6005, 143.6952],
  [-18.6009, 143.6948],
  [-18.6016, 143.6946],
  [-18.6019, 143.6945],
  [-18.6027, 143.6934],
  [-18.6029, 143.6929],
  [-18.6028, 143.6922],
  [-18.6031, 143.6909],
  [-18.6034, 143.6903],
  [-18.6033, 143.6892],
  [-18.6029, 143.6885],
  [-18.6027, 143.6879],
  [-18.6023, 143.6874],
  [-18.6018, 143.6873],
  [-18.6014, 143.6870],
  [-18.6012, 143.6866],
  [-18.6012, 143.6860],
  [-18.6010, 143.6854],
  [-18.6010, 143.6850],
  [-18.6012, 143.6845],
  [-18.6012, 143.6835],
  [-18.6017, 143.6822],
  [-18.6019, 143.6807],
  [-18.6018, 143.6802],
  [-18.6013, 143.6798],
  [-18.6005, 143.6797],
  [-18.6001, 143.6794],
  [-18.5998, 143.6787],
  [-18.6000, 143.6780],
  [-18.6001, 143.6774],
  [-18.6000, 143.6770],
  [-18.6000, 143.6767],
  [-18.6002, 143.6762],
  [-18.6001, 143.6754],
  [-18.5995, 143.6747],
  [-18.5984, 143.6743],
  [-18.5978, 143.6737],
  [-18.5974, 143.6725],
  [-18.5974, 143.6719],
  [-18.5977, 143.6703],
  [-18.5985, 143.6682],
  [-18.5986, 143.6676],
  [-18.5982, 143.6668],
  [-18.5978, 143.6663],
  [-18.5975, 143.6657],
  [-18.5975, 143.6651],
  [-18.5978, 143.6643],
  [-18.5983, 143.6637],
  [-18.5983, 143.6631],
  [-18.5981, 143.6625],
  [-18.5982, 143.6617],
  [-18.5987, 143.6612],
  [-18.5989, 143.6607],
  [-18.5993, 143.6592],
  [-18.5993, 143.6580],
  [-18.5996, 143.6573],
  [-18.5996, 143.6568],
  [-18.5994, 143.6556],
  [-18.5995, 143.6549],
  [-18.5996, 143.6545],
  [-18.5995, 143.6539],
  [-18.5998, 143.6530],
  [-18.5998, 143.6522],
  [-18.5999, 143.6519],
  [-18.6002, 143.6511],
  [-18.6002, 143.6498],
  [-18.6000, 143.6492],
  [-18.5987, 143.6474],
  [-18.5979, 143.6454],
  [-18.5978, 143.6448],
  [-18.5978, 143.6442],
  [-18.5993, 143.6413],
  [-18.5994, 143.6394],
  [-18.5993, 143.6386],
  [-18.5981, 143.6352],
  [-18.5979, 143.6342],
  [-18.5991, 143.6274],
  [-18.5987, 143.6263],
  [-18.5968, 143.6243],
  [-18.5959, 143.6220],
  [-18.5957, 143.6216],
  [-18.5949, 143.6208],
  [-18.5944, 143.6197],
  [-18.5934, 143.6187],
  [-18.5919, 143.6169],
  [-18.5914, 143.6165],
  [-18.5887, 143.6155],
  [-18.5880, 143.6149],
  [-18.5866, 143.6123],
  [-18.5858, 143.6116],
  [-18.5853, 143.6110],
  [-18.5845, 143.6093],
  [-18.5844, 143.6086],
  [-18.5846, 143.6078],
  [-18.5865, 143.6048],
  [-18.5868, 143.6039],
  [-18.5869, 143.6001]
];

export const forsayth: Line = {
  name: 'Forsayth',
  state: 'QLD',
  segments: [
    {
      segments: [almadenMountSurprise],
      history: {
        opened: {
          date: '1908-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mountSurpriseEinasleigh],
      history: {
        opened: {
          date: '1909-02-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [einasleighForsayth],
      history: {
        opened: {
          date: '1911-02-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
