import { Line } from "../../trackTypes";

const line = [
  [-34.9212, 138.5919],
  [-34.9204, 138.5937],
  [-34.9200, 138.5959],
  [-34.9194, 138.5976],
  [-34.9186, 138.6002],
  [-34.9185, 138.6016],
  [-34.9185, 138.6029],
  [-34.9193, 138.6053],
  [-34.9194, 138.6060]
];

export const jubilee: Line = {
  name: 'Jubilee',
  state: 'SA',
  segments: [
    {
      segments: [{
        segment: line,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
