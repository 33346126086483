import { Line } from "../../../trackTypes";

const maoriHill = [
  [-45.85646, 170.50591],
  [-45.85704, 170.50317],
  [-45.85710, 170.50299],
  [-45.85719, 170.50281],
  [-45.85802, 170.50149],
  [-45.85816, 170.50120],
  [-45.85838, 170.50050],
  [-45.85845, 170.50020],
  [-45.85847, 170.49891],
  [-45.85850, 170.49873],
  [-45.85861, 170.49849],
  [-45.85938, 170.49743],
  [-45.85957, 170.49720],
  [-45.85994, 170.49680],
  [-45.86020, 170.49657],
  [-45.86278, 170.49496],
  [-45.86361, 170.49444],
  [-45.86379, 170.49429],
  [-45.86655, 170.49130],
  [-45.86669, 170.49111],
  [-45.86682, 170.49089],
  [-45.86721, 170.49016],
  [-45.86735, 170.48999],
  [-45.86757, 170.48980],
  [-45.86772, 170.48976],
  [-45.86787, 170.48978],
  [-45.86918, 170.49061],
  [-45.86955, 170.49081],
  [-45.87020, 170.49109],
  [-45.87044, 170.49112],
  [-45.87056, 170.49110],
  [-45.87069, 170.49103],
  [-45.87177, 170.49018],
  [-45.87213, 170.48977],
  [-45.87226, 170.48953]
];

const georgeStreet = [
  [-45.87798, 170.50147],
  [-45.85876, 170.51226],
  [-45.85855, 170.51235],
  [-45.85487, 170.51443],
  [-45.85470, 170.51458],
  [-45.85458, 170.51477],
  [-45.85447, 170.51512],
  [-45.85443, 170.51562],
  [-45.85445, 170.51623],
  [-45.85449, 170.51662],
  [-45.85455, 170.51690],
  [-45.85503, 170.51865]
];

const normanby = [
  [-45.85503, 170.51865],
  [-45.85502, 170.51884],
  [-45.85498, 170.51900],
  [-45.85443, 170.51983],
  [-45.85412, 170.52029],
  [-45.85281, 170.52213],
  [-45.85065, 170.52544],
  [-45.85042, 170.52582],
  [-45.85010, 170.52643],
  [-45.84996, 170.52676],
  [-45.84749, 170.53406],
  [-45.84741, 170.53426],
  [-45.84732, 170.53443],
  [-45.84352, 170.54088]
];

const howeStreetJunction = [
  [-45.85876, 170.51226],
  [-45.85870, 170.51235],
  [-45.85867, 170.51246]
];

const howeStreet = [
  [-45.85855, 170.51235],
  [-45.85862, 170.51240],
  [-45.85867, 170.51246],
  [-45.85977, 170.51649],
  [-45.85983, 170.51657],
  [-45.85991, 170.51659],
  [-45.87557, 170.50783],
  [-45.87578, 170.50768],
  [-45.87595, 170.50751],
  [-45.87613, 170.50724],
  [-45.87626, 170.50700],
  [-45.87639, 170.50661],
  [-45.87766, 170.50188],
  [-45.87773, 170.50171],
  [-45.87798, 170.50147]
];

const opoho = [
  [-45.85503, 170.51865],
  [-45.85725, 170.52683],
  [-45.85726, 170.52695],
  [-45.85723, 170.52704],
  [-45.85677, 170.52774],
  [-45.85661, 170.52794],
  [-45.85492, 170.52988],
  [-45.85481, 170.53002],
  [-45.85465, 170.53026],
  [-45.85387, 170.53159]
];

const andersonsBay = [
  [-45.88409, 170.49806],
  [-45.88423, 170.49808],
  [-45.88437, 170.49808],
  [-45.88835, 170.50024],
  [-45.88896, 170.50072],
  [-45.89157, 170.50307],
  [-45.89183, 170.50328],
  [-45.89206, 170.50342],
  [-45.89353, 170.50399],
  [-45.89382, 170.50415],
  [-45.89405, 170.50433],
  [-45.89422, 170.50453],
  [-45.89701, 170.50896],
  [-45.89823, 170.51121],
  [-45.89872, 170.51220],
  [-45.89876, 170.51241],
  [-45.89871, 170.51265],
  [-45.89862, 170.51283],
  [-45.89825, 170.51341],
  [-45.89796, 170.51398],
  [-45.89787, 170.51422],
  [-45.89783, 170.51443],
  [-45.89777, 170.51507],
  [-45.89763, 170.52131],
  [-45.89761, 170.52153],
  [-45.89750, 170.52175],
  [-45.89652, 170.52294],
  [-45.89641, 170.52314],
  [-45.89635, 170.52337],
  [-45.89616, 170.52504],
  [-45.89609, 170.52533],
  [-45.89598, 170.52558],
  [-45.89486, 170.52694],
  [-45.89472, 170.52719],
  [-45.89466, 170.52739],
  [-45.89466, 170.52761],
  [-45.89469, 170.52783],
  [-45.89477, 170.52809],
  [-45.89587, 170.52999],
  [-45.89602, 170.53020],
  [-45.89695, 170.53104]
];

const caversham = [
  [-45.88715, 170.49627],
  [-45.88763, 170.49591],
  [-45.88782, 170.49586],
  [-45.88797, 170.49583],
  [-45.88826, 170.49585],
  [-45.88885, 170.49575],
  [-45.88900, 170.49570],
  [-45.88911, 170.49564],
  [-45.89048, 170.49445],
  [-45.89067, 170.49425],
  [-45.89077, 170.49420],
  [-45.89086, 170.49419],
  [-45.89120, 170.49431],
  [-45.89138, 170.49434],
  [-45.89156, 170.49429],
  [-45.89171, 170.49418],
  [-45.89180, 170.49404],
  [-45.89187, 170.49389],
  [-45.89206, 170.49302],
  [-45.89208, 170.49275],
  [-45.89205, 170.49250],
  [-45.89191, 170.49193],
  [-45.89187, 170.49165],
  [-45.89180, 170.48977],
  [-45.89183, 170.48941],
  [-45.89189, 170.48911],
  [-45.89199, 170.48888],
  [-45.89218, 170.48864],
  [-45.89236, 170.48853],
  [-45.89257, 170.48847],
  [-45.89285, 170.48845],
  [-45.89303, 170.48839],
  [-45.89317, 170.48828],
  [-45.89334, 170.48807],
  [-45.89605, 170.48155],
  [-45.89615, 170.48130],
  [-45.89619, 170.48108],
  [-45.89620, 170.48082],
  [-45.89592, 170.47684]
];

const hillsideRoad = [
  [-45.89315, 170.49821],
  [-45.89323, 170.49820],
  [-45.89331, 170.49812],
  [-45.89882, 170.48483]
];

const cavershamSecondary = [
  [-45.89882, 170.48483],
  [-45.89890, 170.48467],
  [-45.89890, 170.48449],
  [-45.89881, 170.48420],
  [-45.89871, 170.48399],
  [-45.89854, 170.48379],
  [-45.89608, 170.48179],
  [-45.89604, 170.48168],
  [-45.89605, 170.48155]
];

const stClair = [
  [-45.89882, 170.48483],
  [-45.89896, 170.48473],
  [-45.89913, 170.48474],
  [-45.90374, 170.48753],
  [-45.90428, 170.48773],
  [-45.90444, 170.48775],
  [-45.90460, 170.48772],
  [-45.90607, 170.48728],
  [-45.90745, 170.48716],
  [-45.90754, 170.48717],
  [-45.90766, 170.48722],
  [-45.91090, 170.48995],
  [-45.91098, 170.48996],
  [-45.91107, 170.48989],
  [-45.91235, 170.48683]
];

const kingEdwardStreet = [
  [-45.87798, 170.50147],
  [-45.88409, 170.49806],
  [-45.88715, 170.49627],
  [-45.88740, 170.49621],
  [-45.88898, 170.49605],
  [-45.88922, 170.49597],
  [-45.88996, 170.49571],
  [-45.89011, 170.49571],
  [-45.89024, 170.49577],
  [-45.89315, 170.49821],
  [-45.90182, 170.50556]
];

const stKilda = [
  [-45.90182, 170.50556],
  [-45.90592, 170.50906],
  [-45.90597, 170.50916],
  [-45.90600, 170.50933],
  [-45.90566, 170.51130]
];

const forburyPark = [
  [-45.90182, 170.50556],
  [-45.90191, 170.50558],
  [-45.90200, 170.50552],
  [-45.90456, 170.49934],
  [-45.90490, 170.49854],
  [-45.90499, 170.49849],
  [-45.90508, 170.49849],
  [-45.90770, 170.50071],
  [-45.90774, 170.50083],
  [-45.90775, 170.50096],
  [-45.90760, 170.50155],
  [-45.90754, 170.50162],
  [-45.90746, 170.50163],
  [-45.90468, 170.49929],
  [-45.90463, 170.49929],
  [-45.90456, 170.49934]
];

const tahunaPark = [
  [-45.89823, 170.51121],
  [-45.89835, 170.51134],
  [-45.89845, 170.51143],
  [-45.90276, 170.51335],
  [-45.90293, 170.51339],
  [-45.90329, 170.51340],
  [-45.90381, 170.51333],
  [-45.90412, 170.51321],
  [-45.90447, 170.51298],
  [-45.90484, 170.51262],
  [-45.90516, 170.51219],
  [-45.90555, 170.51157],
  [-45.90566, 170.51130]
];

const loganPark = [
  [-45.86615, 170.50808],
  [-45.86611, 170.50817],
  [-45.86609, 170.50831],
  [-45.86840, 170.51668],
  [-45.86840, 170.51679],
  [-45.86836, 170.51687],
  [-45.86668, 170.51781],
  [-45.86663, 170.51789],
  [-45.86662, 170.51798],
  [-45.86782, 170.52235]
];

export const dunedinTrams: Line = {
  name: 'Dunedin Trams',
  state: 'NZ',
  segments: [
    {
      segments: [maoriHill],
      history: {
        opened: {
          date: '1900-10-23',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1936-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [georgeStreet],
      history: {
        opened: {
          date: '1903-12-24',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [normanby],
      history: {
        opened: {
          date: '1903-12-24',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [howeStreet, howeStreetJunction],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [opoho],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [andersonsBay],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [caversham],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hillsideRoad],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cavershamSecondary],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stClair],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingEdwardStreet],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stKilda],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [forburyPark],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tahunaPark],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [loganPark],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1943-05-01',
          status: 'closed'
        }]
      }
    }
  ]
}
