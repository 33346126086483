import { Line } from "../../trackTypes";

const mundaringJunctionBottomPoints = [
  [-31.9053, 116.1685],
  [-31.9065, 116.1699],
  [-31.9075, 116.1704],
  [-31.9109, 116.1714],
  [-31.9126, 116.1716],
  [-31.9134, 116.1721],
  [-31.9146, 116.1737],
  [-31.9167, 116.1756],
  [-31.9183, 116.1774],
  [-31.9191, 116.1779],
  [-31.9201, 116.1778],
  [-31.9209, 116.1782],
  [-31.9218, 116.1790],
  [-31.9225, 116.1794],
  [-31.9234, 116.1793],
  [-31.9249, 116.1782],
  [-31.9259, 116.1782],
  [-31.9268, 116.1789],
  [-31.9281, 116.1818],
  [-31.9291, 116.1826],
  [-31.9305, 116.1828],
  [-31.9322, 116.1824],
  [-31.9332, 116.1825],
  [-31.9342, 116.1831],
  [-31.9353, 116.1834],
  [-31.9398, 116.1817],
  [-31.9416, 116.1801],
  [-31.9437, 116.1795],
  [-31.9444, 116.1789],
  [-31.9451, 116.1786],
  [-31.9470, 116.1789],
  [-31.9478, 116.1787],
  [-31.9483, 116.1780],
  [-31.9490, 116.1757],
  [-31.9505, 116.1741],
  [-31.9507, 116.1729],
  [-31.9505, 116.1709],
  [-31.9505, 116.1699],
  [-31.9509, 116.1690],
  [-31.9535, 116.1660],
  [-31.9542, 116.1657],
  [-31.9548, 116.1660],
  [-31.9551, 116.1668],
  [-31.9550, 116.1684],
  [-31.9550, 116.1693],
  [-31.9553, 116.1706]
];

const bottomPointsZigZagOne = [
  [-31.9550, 116.1684],
  [-31.9554, 116.1674],
  [-31.9553, 116.1661],
  [-31.9550, 116.1655],
  [-31.9546, 116.1651],
  [-31.9543, 116.1650],
  [-31.9541, 116.1650],
  [-31.9538, 116.1653]
];

const zigZagTwo = [
  [-31.9543, 116.1650],
  [-31.9548, 116.1649],
  [-31.9551, 116.1651],
  [-31.9554, 116.1654],
  [-31.9555, 116.1659],
  [-31.9555, 116.1665]
];

const zigZagThree = [
  [-31.9555, 116.1659],
  [-31.9554, 116.1649],
  [-31.9552, 116.1645],
  [-31.9548, 116.1643],
  [-31.9545, 116.1643],
  [-31.9541, 116.1644]
];

const zigZagFour = [
  [-31.9545, 116.1643],
  [-31.9550, 116.1642],
  [-31.9554, 116.1643],
  [-31.9556, 116.1646],
  [-31.9558, 116.1648],
  [-31.9559, 116.1650]
];

const zigZagFive = [
  [-31.9558, 116.1648],
  [-31.9557, 116.1643],
  [-31.9553, 116.1639],
  [-31.9547, 116.1637],
  [-31.9543, 116.1638],
  [-31.9539, 116.1640]
];

const topPointsWeir = [
  [-31.9543, 116.1638],
  [-31.9547, 116.1635],
  [-31.9551, 116.1632],
  [-31.9555, 116.1634],
  [-31.9564, 116.1633],
  [-31.9567, 116.1630],
  [-31.9573, 116.1609],
  [-31.9575, 116.1602],
  [-31.9572, 116.1592],
  [-31.9554, 116.1562],
  [-31.9553, 116.1554],
  [-31.9556, 116.1534],
  [-31.9558, 116.1528],
  [-31.9563, 116.1522],
  [-31.9565, 116.1517],
  [-31.9567, 116.1504],
  [-31.9569, 116.1497],
  [-31.9573, 116.1493],
  [-31.9580, 116.1490],
  [-31.9585, 116.1491],
  [-31.9596, 116.1496],
  [-31.9600, 116.1497],
  [-31.9604, 116.1496],
  [-31.9615, 116.1487],
  [-31.9619, 116.1476],
  [-31.9627, 116.1412],
  [-31.9633, 116.1388],
  [-31.9637, 116.1383],
  [-31.9661, 116.1382],
  [-31.9673, 116.1379],
  [-31.9692, 116.1368],
  [-31.9705, 116.1352],
  [-31.9718, 116.1303]
];

export const mundarighWeir: Line = {
  name: 'Mundaring Weir',
  state: 'WA',
  segments: [
    {
      segments: [mundaringJunctionBottomPoints, bottomPointsZigZagOne, zigZagTwo, zigZagThree, zigZagFour, zigZagFive, topPointsWeir],
      history: {
        opened: {
          date: '1898-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-11-14',
          status: 'closed'
        }]
      }
    }
  ]
};
