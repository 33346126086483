import { Line } from "../../../trackTypes";

const line = [
  [-21.8563, 148.5213],
  [-21.8559, 148.5189],
  [-21.8550, 148.5172],
  [-21.8389, 148.5000],
  [-21.8345, 148.4982],
  [-21.8257, 148.4986],
  [-21.8061, 148.4965],
  [-21.8015, 148.4975],
  [-21.7987, 148.4995],
  [-21.7958, 148.5027],
  [-21.7916, 148.5052],
  [-21.7865, 148.5052],
  [-21.7822, 148.5033],
  [-21.7463, 148.4726],
  [-21.7430, 148.4681],
  [-21.7220, 148.4216],
  [-21.7120, 148.4105],
  [-21.6976, 148.3866],
  [-21.6817, 148.3697],
  [-21.6747, 148.3644],
  [-21.6624, 148.3585],
  [-21.6554, 148.3576],
  [-21.6133, 148.3636],
  [-21.6071, 148.3629],
  [-21.6009, 148.3610],
  [-21.5949, 148.3609],
  [-21.5703, 148.3684],
  [-21.5617, 148.3687],
  [-21.5537, 148.3658],
  [-21.5374, 148.3555],
  [-21.5321, 148.3541],
  [-21.5265, 148.3551],
  [-21.5232, 148.3543],
  [-21.5196, 148.3523],
  [-21.5131, 148.3522],
  [-21.5092, 148.3535],
  [-21.5072, 148.3560],
  [-21.5032, 148.3702],
  [-21.5016, 148.3720],
  [-21.4994, 148.3721],
  [-21.4980, 148.3703],
  [-21.4981, 148.3683],
  [-21.5041, 148.3570],
  [-21.5066, 148.3546],
  [-21.5092, 148.3535]
];

export const hailCreek: Line = {
  name: 'Hail Creek',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2003-01-01',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
