import { Line } from "../../../trackTypes";

const southPortJunction = [
  [-27.9698, 153.3594],
  [-27.9699, 153.3587],
  [-27.9703, 153.3580],
  [-27.9709, 153.3575]
];

const southportJunctionWestBurleighTunnel = [
  [-27.9682, 153.3578],
  [-27.9709, 153.3575],
  [-27.9727, 153.3573],
  [-27.9742, 153.3563],
  [-27.9757, 153.3544],
  [-27.9770, 153.3536],
  [-27.9782, 153.3536],
  [-27.9797, 153.3547],
  [-27.9810, 153.3562],
  [-27.9816, 153.3567],
  [-27.9824, 153.3568],
  [-27.9865, 153.3556],
  [-27.9871, 153.3551],
  [-27.9876, 153.3546],
  [-27.9930, 153.3413],
  [-27.9936, 153.3403],
  [-27.9945, 153.3399],
  [-28.0002, 153.3396],
  [-28.0019, 153.3405],
  [-28.0031, 153.3413],
  [-28.0069, 153.3455],
  [-28.0079, 153.3461],
  [-28.0090, 153.3464],
  [-28.0110, 153.3466],
  [-28.0119, 153.3469],
  [-28.0143, 153.3483],
  [-28.0191, 153.3537],
  [-28.0242, 153.3566],
  [-28.0278, 153.3575],
  [-28.0295, 153.3573],
  [-28.0370, 153.3542],
  [-28.0384, 153.3542],
  [-28.0395, 153.3549],
  [-28.0408, 153.3553],
  [-28.0427, 153.3554],
  [-28.0489, 153.3541],
  [-28.0510, 153.3549],
  [-28.0536, 153.3570],
  [-28.0765, 153.3655],
  [-28.0780, 153.3666],
  [-28.0796, 153.3687],
  [-28.0828, 153.3761],
  [-28.0844, 153.3824],
  [-28.0849, 153.3858],
  [-28.0851, 153.3866],
  [-28.0856, 153.3868],
  [-28.0876, 153.3875],
  [-28.0922, 153.3910],
  [-28.0940, 153.3933],
  [-28.0979, 153.3999],
  [-28.1016, 153.4034],
  [-28.1055, 153.4059],
  [-28.1079, 153.4074],
  [-28.1109, 153.4078],
  [-28.1118, 153.4087],
  [-28.1125, 153.4103],
  [-28.1127, 153.4129],
  [-28.1129, 153.4141],
  [-28.1142, 153.4166],
  [-28.1154, 153.4197],
  [-28.1161, 153.4227]
];

const westBurleighTunnel = [
  [-28.1161, 153.4227],
  [-28.1162, 153.4247]
];

const westBurleighTunnelTweedHeads = [
  [-28.1162, 153.4247],
  [-28.1161, 153.4287],
  [-28.1156, 153.4310],
  [-28.1138, 153.4342],
  [-28.1133, 153.4361],
  [-28.1135, 153.4393],
  [-28.1183, 153.4573],
  [-28.1203, 153.4613],
  [-28.1229, 153.4638],
  [-28.1342, 153.4707],
  [-28.1385, 153.4736],
  [-28.1422, 153.4777],
  [-28.1432, 153.4787],
  [-28.1443, 153.4795],
  [-28.1452, 153.4805],
  [-28.1456, 153.4818],
  [-28.1454, 153.4835],
  [-28.1446, 153.4850],
  [-28.1418, 153.4879],
  [-28.1415, 153.4887],
  [-28.1415, 153.4896],
  [-28.1422, 153.4917],
  [-28.1441, 153.4951],
  [-28.1456, 153.4968],
  [-28.1538, 153.5039],
  [-28.1569, 153.5069],
  [-28.1613, 153.5121],
  [-28.1629, 153.5144],
  [-28.1667, 153.5210],
  [-28.1678, 153.5223],
  [-28.1695, 153.5255],
  [-28.1698, 153.5267],
  [-28.1697, 153.5310],
  [-28.1693, 153.5335],
  [-28.1686, 153.5353],
  [-28.1686, 153.5366],
  [-28.1692, 153.5398],
  [-28.1694, 153.5404],
  [-28.1699, 153.5408]
];

export const tweedHeads: Line = {
  name: 'Tweed Heads (QLD)',
  state: 'QLD',
  segments: [
    {
      segments: [
        southPortJunction,
        southportJunctionWestBurleighTunnel,
        {
          name: 'West Burleigh Tunnel',
          segment: westBurleighTunnel,
          type: 'tunnel'
        },
        westBurleighTunnelTweedHeads
      ],
      history: {
        opened: {
          date: '1903-08-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-07-01',
          status: 'closed'
        }]
      }
    }
  ]
}
