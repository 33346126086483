import { Line } from "../../trackTypes";

const line = [
  [-36.1260, 144.7526],
  [-36.1249, 144.7523],
  [-36.1243, 144.7517],
  [-36.1239, 144.7508],
  [-36.1235, 144.7496],
  [-36.1230, 144.7485],
  [-36.1225, 144.7479],
  [-36.1218, 144.7474],
  [-36.1213, 144.7471],
  [-36.1201, 144.7470]
];

export const echucaWharf: Line = {
  name: 'Echuca Wharf',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1864-09-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-05-01',
          status: 'closed'
        }]
      }
    }
  ]
}
