import { Line } from "../../trackTypes";

const gladstoneLaura = [
  [-33.2697, 138.3575],
  [-33.2668, 138.3575],
  [-33.2654, 138.3570],
  [-33.2650, 138.3564],
  [-33.2647, 138.3553],
  [-33.2646, 138.3533],
  [-33.2640, 138.3507],
  [-33.2575, 138.3385],
  [-33.2519, 138.3329],
  [-33.2450, 138.3254],
  [-33.2371, 138.3187],
  [-33.2193, 138.3070],
  [-33.2184, 138.3067],
  [-33.1928, 138.3008],
  [-33.1918, 138.3009],
  [-33.1910, 138.3014],
  [-33.1872, 138.3063],
  [-33.1860, 138.3069],
  [-33.1850, 138.3070]
];

const lauraBoolerooCentre = [
  [-33.1850, 138.3070],
  [-33.1808, 138.3069],
  [-33.1797, 138.3061],
  [-33.1783, 138.3048],
  [-33.1755, 138.3008],
  [-33.1728, 138.2988],
  [-33.1617, 138.2971],
  [-33.1546, 138.2981],
  [-33.1407, 138.2959],
  [-33.1358, 138.2966],
  [-33.1285, 138.3006],
  [-33.1262, 138.3009],
  [-33.1198, 138.2991],
  [-33.1090, 138.2976],
  [-33.1058, 138.2966],
  [-33.1018, 138.2963],
  [-33.0969, 138.2942],
  [-33.0815, 138.2853],
  [-33.0625, 138.2786],
  [-33.0608, 138.2776],
  [-33.0566, 138.2745],
  [-33.0533, 138.2715],
  [-33.0528, 138.2706],
  [-33.0526, 138.2694],
  [-33.0516, 138.2680],
  [-33.0509, 138.2675],
  [-33.0502, 138.2674],
  [-33.0489, 138.2675],
  [-33.0479, 138.2672],
  [-33.0470, 138.2664],
  [-33.0461, 138.2660],
  [-33.0453, 138.2659],
  [-33.0415, 138.2665],
  [-33.0398, 138.2664],
  [-33.0377, 138.2656],
  [-33.0367, 138.2657],
  [-33.0359, 138.2660],
  [-33.0323, 138.2675],
  [-33.0314, 138.2675],
  [-33.0298, 138.2672],
  [-33.0287, 138.2676],
  [-33.0243, 138.2710],
  [-33.0224, 138.2736],
  [-33.0215, 138.2757],
  [-33.0212, 138.2772],
  [-33.0213, 138.2797],
  [-33.0206, 138.2823],
  [-33.0201, 138.2830],
  [-33.0144, 138.2866],
  [-33.0117, 138.2873],
  [-33.0106, 138.2883],
  [-33.0100, 138.2898],
  [-33.0095, 138.2928],
  [-33.0086, 138.2946],
  [-33.0046, 138.2985],
  [-33.0037, 138.2990],
  [-33.0022, 138.2993],
  [-33.0013, 138.2999],
  [-32.9993, 138.3014],
  [-32.9944, 138.3042],
  [-32.9935, 138.3050],
  [-32.9927, 138.3059],
  [-32.9916, 138.3063],
  [-32.9902, 138.3063],
  [-32.9887, 138.3068],
  [-32.9855, 138.3110],
  [-32.9850, 138.3126],
  [-32.9852, 138.3146],
  [-32.9851, 138.3166],
  [-32.9839, 138.3186],
  [-32.9738, 138.3273],
  [-32.9708, 138.3321],
  [-32.9692, 138.3337],
  [-32.9672, 138.3344],
  [-32.9622, 138.3342],
  [-32.9441, 138.3374],
  [-32.9419, 138.3385],
  [-32.9359, 138.3452],
  [-32.9335, 138.3467],
  [-32.9310, 138.3477],
  [-32.9208, 138.3562],
  [-32.9186, 138.3570],
  [-32.9168, 138.3570],
  [-32.8887, 138.3514],
  [-32.8866, 138.3506],
  [-32.8834, 138.3476],
  [-32.8826, 138.3471],
  [-32.8795, 138.3471]
];

const boolerooCentreWilmington = [
  [-32.8795, 138.3471],
  [-32.8779, 138.3471],
  [-32.8755, 138.3464],
  [-32.8735, 138.3451],
  [-32.8700, 138.3416],
  [-32.8691, 138.3401],
  [-32.8685, 138.3383],
  [-32.8673, 138.3360],
  [-32.8662, 138.3312],
  [-32.8661, 138.3285],
  [-32.8671, 138.3218],
  [-32.8671, 138.3204],
  [-32.8610, 138.2831],
  [-32.8601, 138.2811],
  [-32.8397, 138.2558],
  [-32.8384, 138.2531],
  [-32.8348, 138.2410],
  [-32.8336, 138.2385],
  [-32.8293, 138.2328],
  [-32.8121, 138.2005],
  [-32.8098, 138.1971],
  [-32.8077, 138.1956],
  [-32.7989, 138.1931],
  [-32.7928, 138.1914],
  [-32.7805, 138.1865],
  [-32.7785, 138.1852],
  [-32.7695, 138.1782],
  [-32.7597, 138.1706],
  [-32.7465, 138.1637],
  [-32.7249, 138.1541],
  [-32.7130, 138.1517],
  [-32.7114, 138.1508],
  [-32.7065, 138.1461],
  [-32.6678, 138.1173],
  [-32.6658, 138.1166],
  [-32.6608, 138.1166],
  [-32.6590, 138.1161],
  [-32.6542, 138.1126],
  [-32.6527, 138.1107],
  [-32.6493, 138.1046]
];

export const wilmington: Line = {
  name: 'Wilmington',
  state: 'SA',
  segments: [
    {
      segments: [gladstoneLaura],
      history: {
        opened: {
          date: '1884-06-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-03-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lauraBoolerooCentre],
      history: {
        opened: {
          date: '1910-04-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-03-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boolerooCentreWilmington],
      history: {
        opened: {
          date: '1915-07-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-03-13',
          status: 'closed'
        }]
      }
    }
  ]
};
