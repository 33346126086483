import { Line } from "../../trackTypes";

const blayneyCarcoarTunnel = [
  [-33.5215, 149.2431],
  [-33.5216, 149.2413],
  [-33.5223, 149.2401],
  [-33.5232, 149.2392],
  [-33.5281, 149.2384],
  [-33.5319, 149.2397],
  [-33.5343, 149.2421],
  [-33.5360, 149.2428],
  [-33.5384, 149.2421],
  [-33.5448, 149.2330],
  [-33.5475, 149.2249],
  [-33.5479, 149.2201],
  [-33.5490, 149.2156],
  [-33.5516, 149.2116],
  [-33.5641, 149.2024],
  [-33.5656, 149.2003],
  [-33.5663, 149.1978],
  [-33.5659, 149.1948],
  [-33.5628, 149.1865],
  [-33.5629, 149.1834],
  [-33.5648, 149.1806],
  [-33.5677, 149.1789],
  [-33.5688, 149.1772],
  [-33.5691, 149.1746],
  [-33.5680, 149.1724],
  [-33.5657, 149.1700],
  [-33.5646, 149.1676],
  [-33.5646, 149.1647],
  [-33.5656, 149.1624],
  [-33.5687, 149.1585],
  [-33.5695, 149.1558],
  [-33.5699, 149.1508],
  [-33.5703, 149.1497],
  [-33.5715, 149.1488],
  [-33.5744, 149.1486],
  [-33.5758, 149.1481],
  [-33.5770, 149.1470],
  [-33.5784, 149.1448],
  [-33.5819, 149.1410],
  [-33.5836, 149.1405],
  [-33.5854, 149.1408],
  [-33.5866, 149.1408],
  [-33.5881, 149.1402],
  [-33.5956, 149.1332],
  [-33.5966, 149.1328],
  [-33.5980, 149.1329],
  [-33.6012, 149.1348],
  [-33.6027, 149.1351],
  [-33.6047, 149.1349],
  [-33.6061, 149.1352],
  [-33.6076, 149.1363],
  [-33.6091, 149.1369],
  [-33.6134, 149.1365],
  [-33.6143, 149.1365],
  [-33.6156, 149.1369],
  [-33.6171, 149.1371],
  [-33.6180, 149.1368]
];

const carcoarTunnel = [
  [-33.6180, 149.1368],
  [-33.6190, 149.1361],
  [-33.6200, 149.1351]
];

const carcoarTunnelCowra = [
  [-33.6200, 149.1351],
  [-33.6211, 149.1331],
  [-33.6224, 149.1316],
  [-33.6241, 149.1312],
  [-33.6253, 149.1308],
  [-33.6265, 149.1294],
  [-33.6267, 149.1283],
  [-33.6266, 149.1231],
  [-33.6272, 149.1198],
  [-33.6310, 149.1119],
  [-33.6332, 149.1090],
  [-33.6379, 149.1061],
  [-33.6394, 149.1044],
  [-33.6405, 149.1017],
  [-33.6410, 149.0955],
  [-33.6418, 149.0915],
  [-33.6437, 149.0870],
  [-33.6549, 149.0683],
  [-33.6570, 149.0661],
  [-33.6637, 149.0614],
  [-33.6663, 149.0587],
  [-33.6795, 149.0429],
  [-33.6795, 149.0429],
  [-33.7033, 149.0322],
  [-33.7090, 149.0273],
  [-33.7187, 149.0139],
  [-33.7199, 149.0111],
  [-33.7208, 149.0078],
  [-33.7210, 148.9940],
  [-33.7201, 148.9914],
  [-33.7149, 148.9833],
  [-33.7139, 148.9807],
  [-33.7130, 148.9754],
  [-33.7116, 148.9718],
  [-33.7111, 148.9683],
  [-33.7119, 148.9636],
  [-33.7139, 148.9603],
  [-33.7269, 148.9485],
  [-33.7288, 148.9469],
  [-33.7299, 148.9444],
  [-33.7298, 148.9424],
  [-33.7289, 148.9395],
  [-33.7289, 148.9355],
  [-33.7310, 148.9270],
  [-33.7312, 148.9249],
  [-33.7303, 148.9227],
  [-33.7288, 148.9216],
  [-33.7252, 148.9204],
  [-33.7239, 148.9191],
  [-33.7232, 148.9170],
  [-33.7232, 148.9148],
  [-33.7225, 148.9127],
  [-33.7209, 148.9111],
  [-33.7190, 148.9105],
  [-33.7179, 148.9096],
  [-33.7170, 148.9080],
  [-33.7154, 148.9003],
  [-33.7158, 148.8977],
  [-33.7169, 148.8962],
  [-33.7188, 148.8955],
  [-33.7202, 148.8946],
  [-33.7236, 148.8912],
  [-33.7251, 148.8907],
  [-33.7274, 148.8907],
  [-33.7289, 148.8902],
  [-33.7299, 148.8891],
  [-33.7400, 148.8662],
  [-33.7409, 148.8627],
  [-33.7417, 148.8559],
  [-33.7423, 148.8530],
  [-33.7431, 148.8494],
  [-33.7432, 148.8447],
  [-33.7426, 148.8409],
  [-33.7420, 148.8378],
  [-33.7399, 148.8029],
  [-33.7407, 148.8003],
  [-33.7431, 148.7983],
  [-33.7460, 148.7978],
  [-33.7501, 148.7966],
  [-33.7553, 148.7934],
  [-33.7622, 148.7868],
  [-33.7661, 148.7841],
  [-33.7816, 148.7764],
  [-33.7846, 148.7744],
  [-33.7878, 148.7710],
  [-33.7904, 148.7696],
  [-33.7933, 148.7692],
  [-33.7948, 148.7690],
  [-33.8023, 148.7647],
  [-33.8069, 148.7596],
  [-33.8264, 148.7186],
  [-33.8274, 148.7147],
  [-33.8275, 148.7117],
  [-33.8285, 148.7070],
  [-33.8300, 148.7046],
  [-33.8345, 148.7002]
];

const cowraYoung = [
  [-33.8345, 148.7002],
  [-33.8363, 148.6984],
  [-33.8376, 148.6974],
  [-33.8392, 148.6967],
  [-33.8403, 148.6964],
  [-33.8482, 148.6946],
  [-33.8504, 148.6934],
  [-33.8520, 148.6910],
  [-33.8525, 148.6893],
  [-33.8519, 148.6854],
  [-33.8487, 148.6791],
  [-33.8478, 148.6763],
  [-33.8480, 148.6730],
  [-33.8490, 148.6706],
  [-33.8507, 148.6686],
  [-33.9075, 148.6288],
  [-33.9632, 148.5890],
  [-33.9682, 148.5867],
  [-34.0074, 148.5749],
  [-34.0120, 148.5725],
  [-34.0404, 148.5526],
  [-34.0706, 148.5310],
  [-34.0989, 148.5021],
  [-34.1013, 148.5004],
  [-34.1042, 148.4993],
  [-34.1081, 148.4989],
  [-34.1115, 148.4971],
  [-34.1227, 148.4837],
  [-34.1257, 148.4808],
  [-34.1311, 148.4770],
  [-34.1383, 148.4684],
  [-34.1430, 148.4602],
  [-34.1465, 148.4562],
  [-34.1483, 148.4546],
  [-34.1539, 148.4508],
  [-34.1578, 148.4472],
  [-34.1599, 148.4435],
  [-34.1619, 148.4385],
  [-34.1643, 148.4346],
  [-34.1973, 148.3943],
  [-34.2047, 148.3880],
  [-34.2067, 148.3851],
  [-34.2090, 148.3758],
  [-34.2097, 148.3745],
  [-34.2108, 148.3736],
  [-34.2126, 148.3730],
  [-34.2144, 148.3714],
  [-34.2201, 148.3622],
  [-34.2216, 148.3606],
  [-34.2230, 148.3596],
  [-34.2242, 148.3577],
  [-34.2268, 148.3472],
  [-34.2277, 148.3458],
  [-34.2288, 148.3452],
  [-34.2300, 148.3455],
  [-34.2325, 148.3460],
  [-34.2536, 148.3418],
  [-34.2555, 148.3405],
  [-34.2564, 148.3381],
  [-34.2562, 148.3361],
  [-34.2553, 148.3338],
  [-34.2549, 148.3311],
  [-34.2566, 148.3093],
  [-34.2609, 148.2904],
  [-34.2613, 148.2859],
  [-34.2608, 148.2822],
  [-34.2593, 148.2766],
  [-34.2593, 148.2716],
  [-34.2613, 148.2674],
  [-34.2638, 148.2653],
  [-34.2671, 148.2644],
  [-34.2862, 148.2673],
  [-34.3018, 148.2741],
  [-34.3029, 148.2757],
  [-34.3030, 148.2774],
  [-34.3027, 148.2793],
  [-34.3030, 148.2813],
  [-34.3041, 148.2829],
  [-34.3067, 148.2846],
  [-34.3086, 148.2866],
  [-34.3095, 148.2890],
  [-34.3114, 148.2981]
];

const youngDemondrille = [
  [-34.3114, 148.2981],
  [-34.3120, 148.3008],
  [-34.3129, 148.3025],
  [-34.3140, 148.3032],
  [-34.3304, 148.3063],
  [-34.3322, 148.3072],
  [-34.3334, 148.3086],
  [-34.3348, 148.3113],
  [-34.3365, 148.3127],
  [-34.3383, 148.3136],
  [-34.3401, 148.3155],
  [-34.3408, 148.3177],
  [-34.3415, 148.3230],
  [-34.3424, 148.3248],
  [-34.3440, 148.3259],
  [-34.3459, 148.3260],
  [-34.3606, 148.3172],
  [-34.3621, 148.3170],
  [-34.3637, 148.3177],
  [-34.3657, 148.3194],
  [-34.3686, 148.3202],
  [-34.3712, 148.3192],
  [-34.3726, 148.3178],
  [-34.3737, 148.3161],
  [-34.3755, 148.3149],
  [-34.3776, 148.3153],
  [-34.3952, 148.3289],
  [-34.3989, 148.3300],
  [-34.4185, 148.3295],
  [-34.4212, 148.3283],
  [-34.4275, 148.3237],
  [-34.4301, 148.3227],
  [-34.4389, 148.3221],
  [-34.4662, 148.3196],
  [-34.4688, 148.3199],
  [-34.4719, 148.3216],
  [-34.4763, 148.3223],
  [-34.4952, 148.3176],
  [-34.4998, 148.3176],
  [-34.5073, 148.3197],
  [-34.5099, 148.3197],
  [-34.5194, 148.3167],
  [-34.5220, 148.3171],
  [-34.5235, 148.3188],
  [-34.5242, 148.3210],
  [-34.5261, 148.3226],
  [-34.5282, 148.3227],
  [-34.5321, 148.3216],
  [-34.5334, 148.3215],
  [-34.5343, 148.3220],
  [-34.5343, 148.3220],
  [-34.5360, 148.3245],
  [-34.5369, 148.3252]
];

const demondrilleJunction = [
  [-34.5321, 148.3216],
  [-34.5328, 148.3207],
  [-34.5329, 148.3198],
  [-34.5328, 148.3189],
  [-34.5320, 148.3172]
];

export const blayneyDemondrille: Line = {
  name: 'Blayney - Demondrille',
  state: 'NSW',
  segments: [
    {
      segments: [
        blayneyCarcoarTunnel,
        {
          name: 'Carcoar Tunnel',
          segment: carcoarTunnel,
          type: 'tunnel'
        },
        carcoarTunnelCowra
      ],
      history: {
        opened: {
          date: '1888-02-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cowraYoung],
      history: {
        opened: {
          date: '1886-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [youngDemondrille, demondrilleJunction],
      history: {
        opened: {
          date: '1885-03-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
