import { Line } from "../../../trackTypes";

const edendaleWyndham = [
  [-46.3116, 168.7848],
  [-46.3112, 168.7856],
  [-46.3112, 168.7868],
  [-46.3114, 168.7880],
  [-46.3120, 168.7892],
  [-46.3122, 168.7898],
  [-46.3161, 168.8183],
  [-46.3168, 168.8208],
  [-46.3212, 168.8268],
  [-46.3216, 168.8280],
  [-46.3224, 168.8315],
  [-46.3229, 168.8469],
  [-46.3234, 168.8487],
  [-46.3241, 168.8500],
  [-46.3248, 168.8506]
];

const wyndhamGlenhamTunnel = [
  [-46.3248, 168.8506],
  [-46.3261, 168.8511],
  [-46.3279, 168.8508],
  [-46.3334, 168.8499],
  [-46.3386, 168.8514],
  [-46.3425, 168.8516],
  [-46.3509, 168.8503],
  [-46.3532, 168.8492],
  [-46.3556, 168.8464],
  [-46.3562, 168.8459],
  [-46.3573, 168.8457],
  [-46.3578, 168.8458],
  [-46.3602, 168.8473],
  [-46.3630, 168.8500],
  [-46.3648, 168.8534],
  [-46.3657, 168.8556],
  [-46.3668, 168.8565],
  [-46.3681, 168.8566],
  [-46.3714, 168.8562],
  [-46.3725, 168.8555],
  [-46.3732, 168.8552],
  [-46.3757, 168.8553],
  [-46.3775, 168.8549],
  [-46.3793, 168.8549]
];

const glenhamTunnel = [
  [-46.3793, 168.8549],
  [-46.3808, 168.8546],
  [-46.3815, 168.8540]
];

const glenhamTunnelGlenham = [
  [-46.3815, 168.8540],
  [-46.3819, 168.8535],
  [-46.3822, 168.8518],
  [-46.3829, 168.8510],
  [-46.3838, 168.8512],
  [-46.3852, 168.8529],
  [-46.3861, 168.8531],
  [-46.3869, 168.8523],
  [-46.3875, 168.8507],
  [-46.3885, 168.8496],
  [-46.3901, 168.8493],
  [-46.3916, 168.8487],
  [-46.3926, 168.8488],
  [-46.3938, 168.8496],
  [-46.3946, 168.8497],
  [-46.3968, 168.8489],
  [-46.3975, 168.8488],
  [-46.3980, 168.8493],
  [-46.3994, 168.8511]
];

export const wyndham: Line = {
  name: 'Wyndham',
  state: 'NZ',
  segments: [
    {
      segments: [edendaleWyndham],
      history: {
        opened: {
          date: '1882-10-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        wyndhamGlenhamTunnel,
        {
          name: 'Glenham Tunnel',
          segment: glenhamTunnel,
          type: 'tunnel'
        },
        glenhamTunnelGlenham
      ],
      history: {
        opened: {
          date: '1890-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-07-14',
          status: 'closed'
        }]
      }
    }
  ]
}
