import { Line } from "../../trackTypes";

const flowerdalePreolenna = [
  [-40.9685, 145.6571],
  [-40.9681, 145.6564],
  [-40.9680, 145.6556],
  [-40.9682, 145.6549],
  [-40.9684, 145.6543],
  [-40.9688, 145.6539],
  [-40.9697, 145.6534],
  [-40.9704, 145.6537],
  [-40.9714, 145.6555],
  [-40.9719, 145.6557],
  [-40.9721, 145.6555],
  [-40.9725, 145.6551],
  [-40.9726, 145.6547],
  [-40.9730, 145.6545],
  [-40.9737, 145.6548],
  [-40.9740, 145.6548],
  [-40.9745, 145.6543],
  [-40.9748, 145.6540],
  [-40.9753, 145.6537],
  [-40.9768, 145.6537],
  [-40.9782, 145.6528],
  [-40.9797, 145.6511],
  [-40.9799, 145.6507],
  [-40.9803, 145.6501],
  [-40.9808, 145.6497],
  [-40.9816, 145.6495],
  [-40.9836, 145.6502],
  [-40.9841, 145.6500],
  [-40.9843, 145.6491],
  [-40.9843, 145.6476],
  [-40.9840, 145.6471],
  [-40.9837, 145.6470],
  [-40.9829, 145.6472],
  [-40.9825, 145.6470],
  [-40.9823, 145.6465],
  [-40.9820, 145.6452],
  [-40.9817, 145.6448],
  [-40.9814, 145.6447],
  [-40.9808, 145.6446],
  [-40.9800, 145.6439],
  [-40.9797, 145.6432],
  [-40.9796, 145.6426],
  [-40.9798, 145.6418],
  [-40.9797, 145.6409],
  [-40.9794, 145.6402],
  [-40.9794, 145.6395],
  [-40.9800, 145.6379],
  [-40.9803, 145.6376],
  [-40.9807, 145.6372],
  [-40.9809, 145.6361],
  [-40.9811, 145.6356],
  [-40.9814, 145.6349],
  [-40.9814, 145.6342],
  [-40.9808, 145.6320],
  [-40.9805, 145.6314],
  [-40.9800, 145.6310],
  [-40.9796, 145.6310],
  [-40.9792, 145.6311],
  [-40.9788, 145.6308],
  [-40.9786, 145.6292],
  [-40.9787, 145.6285],
  [-40.9790, 145.6275],
  [-40.9795, 145.6267],
  [-40.9798, 145.6264],
  [-40.9801, 145.6264],
  [-40.9807, 145.6267],
  [-40.9812, 145.6269],
  [-40.9827, 145.6258],
  [-40.9828, 145.6255],
  [-40.9827, 145.6247],
  [-40.9819, 145.6233],
  [-40.9819, 145.6228],
  [-40.9819, 145.6223],
  [-40.9821, 145.6216],
  [-40.9821, 145.6212],
  [-40.9821, 145.6208],
  [-40.9821, 145.6204],
  [-40.9824, 145.6200],
  [-40.9825, 145.6195],
  [-40.9824, 145.6183],
  [-40.9825, 145.6179],
  [-40.9828, 145.6172],
  [-40.9828, 145.6167],
  [-40.9827, 145.6149],
  [-40.9830, 145.6134],
  [-40.9836, 145.6119],
  [-40.9841, 145.6111],
  [-40.9844, 145.6103],
  [-40.9848, 145.6086],
  [-40.9856, 145.6076],
  [-40.9867, 145.6072],
  [-40.9872, 145.6065],
  [-40.9877, 145.6056],
  [-40.9886, 145.6050],
  [-40.9903, 145.6053],
  [-40.9909, 145.6058],
  [-40.9916, 145.6071],
  [-40.9916, 145.6078],
  [-40.9915, 145.6084],
  [-40.9915, 145.6090],
  [-40.9919, 145.6092],
  [-40.9933, 145.6091],
  [-40.9940, 145.6093],
  [-40.9944, 145.6094],
  [-40.9948, 145.6092],
  [-40.9953, 145.6092],
  [-40.9956, 145.6094],
  [-40.9967, 145.6116],
  [-40.9970, 145.6117],
  [-40.9975, 145.6111],
  [-40.9978, 145.6103],
  [-40.9981, 145.6100],
  [-40.9985, 145.6101],
  [-40.9989, 145.6106],
  [-40.9991, 145.6128],
  [-40.9993, 145.6131],
  [-40.9995, 145.6132],
  [-41.0000, 145.6130],
  [-41.0006, 145.6126],
  [-41.0011, 145.6120],
  [-41.0044, 145.6105],
  [-41.0059, 145.6104],
  [-41.0081, 145.6106],
  [-41.0086, 145.6108],
  [-41.0104, 145.6124],
  [-41.0113, 145.6127],
  [-41.0136, 145.6128],
  [-41.0144, 145.6124],
  [-41.0161, 145.6107],
  [-41.0165, 145.6105],
  [-41.0176, 145.6107],
  [-41.0180, 145.6105],
  [-41.0192, 145.6099],
  [-41.0198, 145.6096],
  [-41.0213, 145.6101],
  [-41.0219, 145.6102],
  [-41.0230, 145.6096],
  [-41.0233, 145.6091],
  [-41.0233, 145.6086],
  [-41.0230, 145.6081],
  [-41.0230, 145.6075],
  [-41.0233, 145.6071],
  [-41.0238, 145.6066],
  [-41.0247, 145.6063],
  [-41.0253, 145.6065],
  [-41.0266, 145.6080],
  [-41.0273, 145.6084],
  [-41.0281, 145.6084],
  [-41.0313, 145.6064],
  [-41.0317, 145.6058],
  [-41.0316, 145.6049],
  [-41.0318, 145.6043],
  [-41.0327, 145.6034],
  [-41.0340, 145.6031],
  [-41.0346, 145.6026],
  [-41.0353, 145.5999],
  [-41.0352, 145.5990],
  [-41.0354, 145.5984],
  [-41.0359, 145.5981],
  [-41.0368, 145.5978],
  [-41.0374, 145.5974],
  [-41.0378, 145.5961],
  [-41.0383, 145.5955],
  [-41.0410, 145.5953],
  [-41.0443, 145.5937],
  [-41.0445, 145.5933],
  [-41.0449, 145.5923],
  [-41.0452, 145.5921],
  [-41.0462, 145.5922],
  [-41.0470, 145.5928],
  [-41.0475, 145.5929],
  [-41.0485, 145.5918],
  [-41.0491, 145.5905],
  [-41.0494, 145.5899],
  [-41.0501, 145.5898],
  [-41.0519, 145.5913],
  [-41.0534, 145.5923],
  [-41.0557, 145.5930],
  [-41.0586, 145.5965],
  [-41.0593, 145.5967],
  [-41.0622, 145.5951],
  [-41.0638, 145.5946],
  [-41.0643, 145.5943],
  [-41.0670, 145.5904],
  [-41.0681, 145.5895],
  [-41.0697, 145.5893],
  [-41.0704, 145.5888],
  [-41.0711, 145.5889],
  [-41.0732, 145.5906],
  [-41.0738, 145.5909],
  [-41.0765, 145.5907],
  [-41.0770, 145.5903],
  [-41.0787, 145.5880],
  [-41.0795, 145.5877],
  [-41.0801, 145.5881],
  [-41.0806, 145.5881],
  [-41.0811, 145.5877],
  [-41.0816, 145.5875],
  [-41.0831, 145.5880],
  [-41.0835, 145.5879],
  [-41.0839, 145.5876],
  [-41.0850, 145.5862],
  [-41.0853, 145.5851],
  [-41.0856, 145.5847],
  [-41.0861, 145.5847],
  [-41.0867, 145.5854],
  [-41.0895, 145.5864],
  [-41.0901, 145.5864],
  [-41.0906, 145.5858],
  [-41.0907, 145.5852],
  [-41.0905, 145.5845],
  [-41.0894, 145.5834],
  [-41.0892, 145.5829],
  [-41.0885, 145.5766],
  [-41.0884, 145.5736],
  [-41.0882, 145.5729],
  [-41.0876, 145.5719],
  [-41.0874, 145.5711],
  [-41.0875, 145.5685],
  [-41.0873, 145.5677],
  [-41.0864, 145.5655],
  [-41.0864, 145.5648],
  [-41.0866, 145.5631],
  [-41.0873, 145.5612],
  [-41.0873, 145.5605],
  [-41.0870, 145.5599],
  [-41.0855, 145.5596],
  [-41.0847, 145.5592],
  [-41.0843, 145.5583],
  [-41.0841, 145.5572],
  [-41.0839, 145.5534],
  [-41.0840, 145.5530]
];

const preolennaExtension = [
  [-41.0840, 145.5530],
  [-41.0852, 145.5504],
  [-41.0865, 145.5489],
  [-41.0870, 145.5487],
  [-41.0876, 145.5492],
  [-41.0881, 145.5501],
  [-41.0885, 145.5504],
  [-41.0904, 145.5502],
  [-41.0920, 145.5496],
  [-41.0942, 145.5499],
  [-41.0972, 145.5494],
  [-41.0983, 145.5493],
  [-41.0990, 145.5501],
  [-41.1000, 145.5519],
  [-41.1006, 145.5521],
  [-41.1021, 145.5514],
  [-41.1029, 145.5512],
  [-41.1034, 145.5508],
  [-41.1039, 145.5500],
  [-41.1043, 145.5497],
  [-41.1047, 145.5495],
  [-41.1058, 145.5481],
  [-41.1065, 145.5476],
  [-41.1072, 145.5467],
  [-41.1079, 145.5465],
  [-41.1082, 145.5462],
  [-41.1086, 145.5455],
  [-41.1090, 145.5452],
  [-41.1101, 145.5442],
  [-41.1105, 145.5432],
  [-41.1108, 145.5428],
  [-41.1122, 145.5421],
  [-41.1127, 145.5418],
  [-41.1135, 145.5419],
  [-41.1142, 145.5414],
  [-41.1147, 145.5413],
  [-41.1163, 145.5420],
  [-41.1171, 145.5429],
  [-41.1178, 145.5430],
  [-41.1184, 145.5435],
  [-41.1189, 145.5453],
  [-41.1193, 145.5459],
  [-41.1199, 145.5460],
  [-41.1213, 145.5457],
  [-41.1217, 145.5455],
  [-41.1220, 145.5450],
  [-41.1224, 145.5447],
  [-41.1239, 145.5446],
  [-41.1243, 145.5447],
  [-41.1249, 145.5452],
  [-41.1254, 145.5452],
  [-41.1259, 145.5449],
  [-41.1263, 145.5448],
  [-41.1272, 145.5450],
  [-41.1278, 145.5448],
  [-41.1282, 145.5443],
  [-41.1288, 145.5431],
  [-41.1287, 145.5419],
  [-41.1290, 145.5406],
  [-41.1328, 145.5390],
  [-41.1334, 145.5390],
  [-41.1338, 145.5394],
  [-41.1339, 145.5402],
  [-41.1341, 145.5407],
  [-41.1347, 145.5409],
  [-41.1364, 145.5406]
];

export const preolenna: Line = {
  name: 'Preolenna',
  state: 'TAS',
  segments: [
    {
      segments: [flowerdalePreolenna],
      history: {
        opened: {
          date: '1917-03-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-04-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [preolennaExtension],
      history: {
        opened: {
          date: '1924-03-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-04-06',
          status: 'closed'
        }]
      }
    }
  ]
}
