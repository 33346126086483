import { Line } from "../../trackTypes";

const theRockMangoplah = [
  [-35.2708, 147.1222],
  [-35.2711, 147.1234],
  [-35.2714, 147.1241],
  [-35.2761, 147.1297],
  [-35.2770, 147.1305],
  [-35.2839, 147.1362],
  [-35.2850, 147.1371],
  [-35.2858, 147.1381],
  [-35.2940, 147.1528],
  [-35.3086, 147.1837],
  [-35.3091, 147.1845],
  [-35.3100, 147.1851],
  [-35.3204, 147.1899],
  [-35.3216, 147.1910],
  [-35.3222, 147.1922],
  [-35.3374, 147.2109],
  [-35.3384, 147.2130],
  [-35.3400, 147.2148],
  [-35.3418, 147.2158],
  [-35.3434, 147.2174],
  [-35.3447, 147.2201],
  [-35.3450, 147.2228],
  [-35.3443, 147.2283],
  [-35.3450, 147.2305],
  [-35.3535, 147.2418],
  [-35.3550, 147.2439],
  [-35.3571, 147.2457],
  [-35.3636, 147.2487],
  [-35.3650, 147.2489],
  [-35.3669, 147.2482],
  [-35.3681, 147.2481],
  [-35.3728, 147.2497]
];

const mangoplahWestby = [
  [-35.3728, 147.2497],
  [-35.3741, 147.2507],
  [-35.3750, 147.2524],
  [-35.3751, 147.2543],
  [-35.3749, 147.2557],
  [-35.3752, 147.2571],
  [-35.3766, 147.2586],
  [-35.3780, 147.2588],
  [-35.3796, 147.2587],
  [-35.3807, 147.2592],
  [-35.3816, 147.2604],
  [-35.3868, 147.2812],
  [-35.3879, 147.2899],
  [-35.3876, 147.2916],
  [-35.3879, 147.2937],
  [-35.3887, 147.2952],
  [-35.3912, 147.2981],
  [-35.3922, 147.2985],
  [-35.3957, 147.2991],
  [-35.3968, 147.2996],
  [-35.3976, 147.3005],
  [-35.4007, 147.3051],
  [-35.4017, 147.3061],
  [-35.4144, 147.3157],
  [-35.4165, 147.3175],
  [-35.4179, 147.3197],
  [-35.4231, 147.3345],
  [-35.4232, 147.3383],
  [-35.4220, 147.3555],
  [-35.4222, 147.3579],
  [-35.4229, 147.3600],
  [-35.4251, 147.3633],
  [-35.4256, 147.3649],
  [-35.4256, 147.3668],
  [-35.4253, 147.3692],
  [-35.4255, 147.3707],
  [-35.4261, 147.3716],
  [-35.4314, 147.3781],
  [-35.4328, 147.3789],
  [-35.4373, 147.3806],
  [-35.4389, 147.3823],
  [-35.4391, 147.3841],
  [-35.4371, 147.3919],
  [-35.4372, 147.3929],
  [-35.4376, 147.3938],
  [-35.4408, 147.3986],
  [-35.4451, 147.4030],
  [-35.4463, 147.4035],
  [-35.4480, 147.4036],
  [-35.4494, 147.4043],
  [-35.4503, 147.4063],
  [-35.4505, 147.4133],
  [-35.4514, 147.4154],
  [-35.4528, 147.4162],
  [-35.4546, 147.4163],
  [-35.4565, 147.4171],
  [-35.4630, 147.4164],
  [-35.4648, 147.4154],
  [-35.4716, 147.4130],
  [-35.4730, 147.4129],
  [-35.4769, 147.4144],
  [-35.4782, 147.4144],
  [-35.4801, 147.4140],
  [-35.4834, 147.4141],
  [-35.4917, 147.4189]
];

export const westby: Line = {
  name: 'Westby',
  state: 'NSW',
  segments: [
    {
      segments: [theRockMangoplah],
      history: {
        opened: {
          date: '1925-08-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-07-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mangoplahWestby],
      history: {
        opened: {
          date: '1925-08-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-01-24',
          status: 'closed'
        }]
      }
    }
  ]
};
