import { Line } from '../trackTypes';
import { airport } from './westernAustralia/perth/airport';
import { yanchep } from './westernAustralia/perth/yanchep';
import { mandurah } from './westernAustralia/perth/mandurah';
import { thornlie } from './westernAustralia/perth/thornlie';
import { southWest } from './westernAustralia/southWest';
import { transAustrailan } from './westernAustralia/transAustralian';
import { leonora } from './westernAustralia/leonora';
import { esperance } from './westernAustralia/esperance';
import { easternGoldfields } from './westernAustralia/easternGoldfiels';
import { fremantle } from './westernAustralia/perth/fremantle';
import { fortescue } from './westernAustralia/pilbara/fortescue';
import { midland } from './westernAustralia/midland';
import { northampton } from './westernAustralia/northampton';
import { albany } from './westernAustralia/albany';
import { kwinanaFreight } from './westernAustralia/perth/kwinanaFreight';
import { northcliffe } from './westernAustralia/northcliffe';
import { royHill } from './westernAustralia/pilbara/royHill';
import { kalgoorlieTrams } from './westernAustralia/trams/kalgoorlieTrams';
import { boulderLoopline } from './westernAustralia/boulderLoopline';
import { perthTrams } from './westernAustralia/trams/perthTrams';
import { wiluna } from './westernAustralia/wiluna';
import { sandstone } from './westernAustralia/sandstone';
import { bigBell } from './westernAustralia/bigBell';
import { mullewaMeekatherra } from './westernAustralia/mullewaMeekatharra';
import { upperChapman } from './westernAustralia/upperChapman';
import { miling } from './westernAustralia/miling';
import { avonValleyDeviation } from './westernAustralia/avonValleyDeviation';
import { eneabba } from './westernAustralia/eneabba';
import { marbleBar } from './westernAustralia/pilbara/marbleBar';
import { pingrup } from './westernAustralia/pingrup';
import { yorkBruceRock } from './westernAustralia/yorkBruceRock';
import { brooktonCorrigin } from './westernAustralia/brooktonCorrigin';
import { ongerup } from './westernAustralia/ongerup';
import { belmont } from './westernAustralia/perth/belmont';
import { flindersBay } from './westernAustralia/flindersBay';
import { narroginMerredin } from './westernAustralia/narroginMerredin';
import { bonnieRock } from './westernAustralia/bonnieRock';
import { newdegate } from './westernAustralia/newdegate';
import { goomallingMullewa } from './westernAustralia/goomallingMullewa';
import { wyalkatchemSouthernCross } from './westernAustralia/wyalkatchemSouthernCross';
import { northamMerredin } from './westernAustralia/northamMerredin';
import { kalannie } from './westernAustralia/kalannie';
import { yilliminningMerredin } from './westernAustralia/yilliminningMerredin';
import { hyden } from './westernAustralia/hyden';
import { donnybrookKatanning } from './westernAustralia/donnybrookKatanning';
import { robeRiver } from './westernAustralia/pilbara/robeRiver';
import { hamersley } from './westernAustralia/pilbara/hamersley';
import { broomeTrams } from './westernAustralia/trams/broomeTrams';
import { bowellingWagin } from './westernAustralia/bowellingWagin';
import { pinjarraNarrogin } from './westernAustralia/pinjarraNarrogin';
import { nannup } from './westernAustralia/nannup';
import { jarrahdale } from './westernAustralia/jarrahdale';
import { brunswickNarrogin } from './westernAustralia/brunswickNarrogin';
import { lakeClifton } from './westernAustralia/lakeClifton';
import { karara } from './westernAustralia/karara';
import { nornalup } from './westernAustralia/nornalup';
import { mountGoldsworthy } from './westernAustralia/pilbara/mountGoldsworthy';
import { mountNewman } from './westernAustralia/pilbara/mountNewman';
import { upperDarlingRange } from './westernAustralia/upperDarlingRange';
import { malcomLaverton } from './westernAustralia/malcomLaverton';
import { ravensthorpe } from './westernAustralia/ravensthorpe';
import { koolyanobbing } from './westernAustralia/koolyanobbing';
import { wokalupMornington } from './westernAustralia/wokalupMornington';
import { cardiff } from './westernAustralia/cardiff';
import { mundarighWeir } from './westernAustralia/mundaringWeir';
import { rottnestIsland } from './westernAustralia/rottnestIsland';
import { bennettBrook } from './westernAustralia/bennettBrook';
import { ellenbrook } from './westernAustralia/perth/ellenbrook';
import { fremantleTrams } from './westernAustralia/trams/fremantleTrams';
import { leonoraTrams } from './westernAustralia/trams/leonoraTrams';
import { armidale } from './westernAustralia/perth/armidale';
import { meekatharraHorseshoe } from './westernAustralia/meekatharraHorseshoe';
import { carnarvonTrams } from './westernAustralia/trams/carnarvonTrams';
import { kanowna } from './westernAustralia/kanowna';
import { gnumballaLake } from './westernAustralia/gnumballaLake';
import { derbyTram } from './westernAustralia/derbyTram';

export const waLines: Line[] = [
  airport,
  yanchep,
  mandurah,
  thornlie,
  southWest,
  transAustrailan,
  leonora,
  esperance,
  easternGoldfields,
  fremantle,
  fortescue,
  midland,
  northampton,
  albany,
  kwinanaFreight,
  northcliffe,
  royHill,
  kalgoorlieTrams,
  boulderLoopline,
  perthTrams,
  wiluna,
  sandstone,
  bigBell,
  mullewaMeekatherra,
  upperChapman,
  miling,
  avonValleyDeviation,
  eneabba,
  marbleBar,
  pingrup,
  yorkBruceRock,
  brooktonCorrigin,
  ongerup,
  belmont,
  flindersBay,
  narroginMerredin,
  bonnieRock,
  newdegate,
  goomallingMullewa,
  wyalkatchemSouthernCross,
  northamMerredin,
  kalannie,
  yilliminningMerredin,
  hyden,
  donnybrookKatanning,
  robeRiver,
  hamersley,
  broomeTrams,
  bowellingWagin,
  pinjarraNarrogin,
  nannup,
  jarrahdale,
  brunswickNarrogin,
  lakeClifton,
  karara,
  nornalup,
  mountGoldsworthy,
  mountNewman,
  upperDarlingRange,
  malcomLaverton,
  ravensthorpe,
  koolyanobbing,
  wokalupMornington,
  cardiff,
  mundarighWeir,
  rottnestIsland,
  bennettBrook,
  ellenbrook,
  fremantleTrams,
  leonoraTrams,
  armidale,
  meekatharraHorseshoe,
  carnarvonTrams,
  kanowna,
  gnumballaLake,
  derbyTram
];
