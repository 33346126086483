import { Line } from "../../trackTypes";

const line = [
  [-33.9212, 147.1965],
  [-33.9198, 147.1967],
  [-33.9182, 147.1974],
  [-33.8943, 147.2144],
  [-33.8680, 147.2308],
  [-33.8468, 147.2501],
  [-33.7953, 147.2917],
  [-33.7915, 147.2931],
  [-33.7716, 147.2946],
  [-33.7688, 147.2958],
  [-33.7454, 147.3169],
  [-33.7411, 147.3233],
  [-33.7363, 147.3347],
  [-33.7249, 147.3499],
  [-33.7209, 147.3526],
  [-33.6817, 147.3593],
  [-33.6780, 147.3595],
  [-33.6397, 147.3530],
  [-33.6216, 147.3411],
  [-33.6202, 147.3398],
  [-33.6191, 147.3378],
  [-33.6184, 147.3341],
  [-33.6174, 147.3325],
  [-33.6156, 147.3313],
  [-33.6125, 147.3311],
  [-33.6111, 147.3303],
  [-33.6096, 147.3285],
  [-33.6078, 147.3275],
  [-33.6055, 147.3277],
  [-33.6040, 147.3277],
  [-33.6023, 147.3266],
  [-33.6012, 147.3246],
  [-33.6012, 147.3229],
  [-33.6020, 147.3194],
  [-33.6017, 147.3175],
  [-33.5953, 147.3041],
  [-33.5951, 147.3021],
  [-33.5952, 147.2996],
  [-33.5942, 147.2973],
  [-33.5923, 147.2961],
  [-33.5905, 147.2959],
  [-33.5888, 147.2950],
  [-33.5833, 147.2888],
  [-33.5811, 147.2872],
  [-33.5161, 147.2555]
];

export const burcher: Line = {
  name: 'Burcher',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1929-12-16',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-07-04',
          status: 'closed'
        }]
      }
    }
  ]
};
