import { Line } from "../../trackTypes";

const clacklineToodyay = [
  [-31.7204, 116.5211],
  [-31.7197, 116.5228],
  [-31.7188, 116.5233],
  [-31.7171, 116.5228],
  [-31.7162, 116.5229],
  [-31.7120, 116.5240],
  [-31.7102, 116.5240],
  [-31.7079, 116.5227],
  [-31.7066, 116.5220],
  [-31.7026, 116.5209],
  [-31.7010, 116.5216],
  [-31.6955, 116.5221],
  [-31.6917, 116.5228],
  [-31.6906, 116.5223],
  [-31.6891, 116.5211],
  [-31.6788, 116.5140],
  [-31.6765, 116.5110],
  [-31.6734, 116.5069],
  [-31.6725, 116.5048],
  [-31.6717, 116.5039],
  [-31.6705, 116.5036],
  [-31.6651, 116.5041],
  [-31.6635, 116.5039],
  [-31.6613, 116.5024],
  [-31.6584, 116.4990],
  [-31.6569, 116.4984],
  [-31.6555, 116.4968],
  [-31.6523, 116.4903],
  [-31.6504, 116.4889],
  [-31.6436, 116.4863],
  [-31.6401, 116.4828],
  [-31.6351, 116.4735],
  [-31.6342, 116.4728],
  [-31.6332, 116.4723],
  [-31.6324, 116.4712],
  [-31.6317, 116.4687],
  [-31.6300, 116.4671],
  [-31.6291, 116.4655],
  [-31.6280, 116.4648],
  [-31.6269, 116.4649],
  [-31.6221, 116.4681],
  [-31.6210, 116.4684],
  [-31.6187, 116.4684],
  [-31.6141, 116.4688],
  [-31.6131, 116.4682],
  [-31.6125, 116.4673],
  [-31.6116, 116.4667],
  [-31.6103, 116.4669],
  [-31.6088, 116.4680],
  [-31.6079, 116.4684],
  [-31.6062, 116.4684],
  [-31.6054, 116.4689],
  [-31.6038, 116.4708],
  [-31.6033, 116.4720],
  [-31.6028, 116.4753],
  [-31.6020, 116.4761],
  [-31.5985, 116.4775],
  [-31.5972, 116.4775],
  [-31.5960, 116.4783],
  [-31.5955, 116.4795],
  [-31.5950, 116.4801],
  [-31.5940, 116.4805],
  [-31.5928, 116.4802],
  [-31.5922, 116.4790],
  [-31.5920, 116.4776],
  [-31.5914, 116.4768],
  [-31.5904, 116.4762],
  [-31.5874, 116.4760],
  [-31.5854, 116.4769],
  [-31.5839, 116.4781],
  [-31.5828, 116.4795],
  [-31.5816, 116.4800],
  [-31.5800, 116.4800],
  [-31.5784, 116.4806],
  [-31.5768, 116.4820],
  [-31.5756, 116.4823],
  [-31.5711, 116.4814],
  [-31.5699, 116.4806],
  [-31.5691, 116.4791],
  [-31.5668, 116.4762],
  [-31.5654, 116.4755],
  [-31.5638, 116.4752]
];

const toodayAllignmentReuse = [
  [-31.5638, 116.4752],
  [-31.5567, 116.4747],
  [-31.5539, 116.4727],
  [-31.5525, 116.4704],
  [-31.5517, 116.4680]
];

const toodyayToodyayJunction = [
  [-31.5517, 116.4680],
  [-31.5514, 116.4664],
  [-31.5509, 116.4650],
  [-31.5498, 116.4634],
  [-31.5456, 116.4572],
  [-31.5443, 116.4548],
  [-31.5423, 116.4535],
  [-31.5368, 116.4519],
  [-31.5357, 116.4522],
  [-31.5346, 116.4531]
];

const toodyayConnector = [
  [-31.5346, 116.4531],
  [-31.5348, 116.4516],
  [-31.5347, 116.4468],
  [-31.5344, 116.4456],
  [-31.5334, 116.4437]
];

const toodyayBolgart = [
  [-31.5346, 116.4531],
  [-31.5342, 116.4541],
  [-31.5341, 116.4552],
  [-31.5342, 116.4566],
  [-31.5338, 116.4581],
  [-31.5326, 116.4590],
  [-31.5315, 116.4590],
  [-31.5305, 116.4585],
  [-31.5297, 116.4575],
  [-31.5286, 116.4569],
  [-31.5270, 116.4567],
  [-31.5258, 116.4561],
  [-31.5234, 116.4542],
  [-31.5219, 116.4534],
  [-31.5149, 116.4520],
  [-31.5130, 116.4509],
  [-31.5114, 116.4488],
  [-31.5098, 116.4479],
  [-31.5079, 116.4476],
  [-31.5066, 116.4471],
  [-31.5011, 116.4437],
  [-31.5001, 116.4425],
  [-31.4995, 116.4411],
  [-31.4988, 116.4382],
  [-31.4972, 116.4362],
  [-31.4958, 116.4358],
  [-31.4944, 116.4363],
  [-31.4922, 116.4381],
  [-31.4902, 116.4388],
  [-31.4880, 116.4386],
  [-31.4870, 116.4387],
  [-31.4805, 116.4408],
  [-31.4792, 116.4408],
  [-31.4753, 116.4398],
  [-31.4739, 116.4390],
  [-31.4720, 116.4372],
  [-31.4702, 116.4361],
  [-31.4624, 116.4340],
  [-31.4612, 116.4341],
  [-31.4556, 116.4364],
  [-31.4547, 116.4369],
  [-31.4500, 116.4411],
  [-31.4456, 116.4432],
  [-31.4449, 116.4437],
  [-31.4439, 116.4448],
  [-31.4412, 116.4471],
  [-31.4330, 116.4583],
  [-31.4266, 116.4634],
  [-31.4251, 116.4638],
  [-31.4240, 116.4648],
  [-31.4235, 116.4658],
  [-31.4218, 116.4706],
  [-31.4197, 116.4733],
  [-31.4178, 116.4746],
  [-31.4156, 116.4756],
  [-31.4146, 116.4763],
  [-31.4138, 116.4772],
  [-31.4096, 116.4787],
  [-31.4086, 116.4799],
  [-31.4032, 116.4993],
  [-31.4015, 116.5016],
  [-31.3966, 116.5046],
  [-31.3939, 116.5052],
  [-31.3901, 116.5053],
  [-31.3883, 116.5060],
  [-31.3802, 116.5128],
  [-31.3786, 116.5133],
  [-31.3771, 116.5131],
  [-31.3758, 116.5120],
  [-31.3681, 116.5025],
  [-31.3668, 116.5018],
  [-31.3655, 116.5019],
  [-31.3638, 116.5025],
  [-31.3609, 116.5030],
  [-31.3593, 116.5037],
  [-31.3541, 116.5089],
  [-31.3523, 116.5098],
  [-31.3457, 116.5115],
  [-31.3436, 116.5126],
  [-31.3368, 116.5187],
  [-31.3352, 116.5190],
  [-31.3340, 116.5186],
  [-31.3323, 116.5172],
  [-31.3309, 116.5167],
  [-31.3281, 116.5163],
  [-31.3153, 116.5089],
  [-31.3143, 116.5079],
  [-31.3119, 116.5044],
  [-31.3112, 116.5029],
  [-31.3092, 116.4967],
  [-31.3085, 116.4956],
  [-31.3074, 116.4950],
  [-31.3015, 116.4938],
  [-31.2993, 116.4940],
  [-31.2957, 116.4957],
  [-31.2941, 116.4972],
  [-31.2926, 116.5000],
  [-31.2913, 116.5014],
  [-31.2842, 116.5075],
  [-31.2833, 116.5078],
  [-31.2825, 116.5077],
  [-31.2783, 116.5063],
  [-31.2772, 116.5062],
  [-31.2714, 116.5069]
];

const bolgartCalingiri = [
  [-31.2714, 116.5069],
  [-31.2697, 116.5070],
  [-31.2683, 116.5067],
  [-31.2667, 116.5059],
  [-31.2579, 116.5052],
  [-31.2557, 116.5046],
  [-31.2496, 116.5012],
  [-31.2474, 116.5008],
  [-31.2453, 116.5007],
  [-31.2422, 116.4990],
  [-31.2375, 116.4923],
  [-31.2356, 116.4903],
  [-31.2310, 116.4883],
  [-31.2266, 116.4859],
  [-31.2251, 116.4857],
  [-31.2128, 116.4878],
  [-31.2050, 116.4918],
  [-31.2038, 116.4921],
  [-31.1981, 116.4926],
  [-31.1962, 116.4921],
  [-31.1845, 116.4851],
  [-31.1822, 116.4846],
  [-31.1790, 116.4854],
  [-31.1765, 116.4872],
  [-31.1716, 116.4887],
  [-31.1702, 116.4897],
  [-31.1687, 116.4910],
  [-31.1672, 116.4915],
  [-31.1636, 116.4912],
  [-31.1619, 116.4900],
  [-31.1603, 116.4881],
  [-31.1590, 116.4873],
  [-31.1575, 116.4869],
  [-31.1562, 116.4871],
  [-31.1543, 116.4878],
  [-31.1528, 116.4875],
  [-31.1517, 116.4864],
  [-31.1513, 116.4851],
  [-31.1512, 116.4820],
  [-31.1497, 116.4783],
  [-31.1473, 116.4760],
  [-31.1432, 116.4746],
  [-31.1419, 116.4745],
  [-31.1373, 116.4749],
  [-31.1348, 116.4741],
  [-31.1254, 116.4686],
  [-31.1242, 116.4675],
  [-31.1156, 116.4573],
  [-31.1113, 116.4538],
  [-31.1091, 116.4531],
  [-31.0976, 116.4531],
  [-31.0953, 116.4521],
  [-31.0871, 116.4431]
];

const calingiriPiawaning = [
  [-31.0871, 116.4431],
  [-31.0848, 116.4406],
  [-31.0775, 116.4365],
  [-31.0761, 116.4362],
  [-31.0628, 116.4362],
  [-31.0619, 116.4363],
  [-31.0567, 116.4382],
  [-31.0437, 116.4395],
  [-31.0398, 116.4389],
  [-31.0384, 116.4383],
  [-31.0351, 116.4359],
  [-31.0286, 116.4330],
  [-31.0266, 116.4326],
  [-31.0246, 116.4312],
  [-31.0215, 116.4265],
  [-31.0189, 116.4236],
  [-31.0180, 116.4219],
  [-31.0168, 116.4166],
  [-31.0153, 116.4130],
  [-31.0130, 116.4103],
  [-31.0107, 116.4094],
  [-31.0082, 116.4097],
  [-31.0059, 116.4095],
  [-31.0022, 116.4099],
  [-30.9997, 116.4094],
  [-30.9982, 116.4096],
  [-30.9957, 116.4106],
  [-30.9939, 116.4107],
  [-30.9875, 116.4097],
  [-30.9837, 116.4100],
  [-30.9808, 116.4098],
  [-30.9796, 116.4100],
  [-30.9781, 116.4105],
  [-30.9769, 116.4103],
  [-30.9757, 116.4093],
  [-30.9725, 116.4039],
  [-30.9716, 116.4029],
  [-30.9701, 116.4021],
  [-30.9648, 116.4009],
  [-30.9631, 116.4001],
  [-30.9594, 116.3971],
  [-30.9537, 116.3951],
  [-30.9479, 116.3949],
  [-30.9395, 116.3918],
  [-30.9380, 116.3918],
  [-30.9337, 116.3934],
  [-30.9299, 116.3937],
  [-30.9287, 116.3933],
  [-30.9208, 116.3889],
  [-30.9137, 116.3861],
  [-30.9122, 116.3859],
  [-30.8952, 116.3862],
  [-30.8936, 116.3859],
  [-30.8922, 116.3853],
  [-30.8909, 116.3850],
  [-30.8857, 116.3850],
  [-30.8757, 116.3838],
  [-30.8741, 116.3838],
  [-30.8705, 116.3850],
  [-30.8689, 116.3864],
  [-30.8681, 116.3879],
  [-30.8669, 116.3890],
  [-30.8655, 116.3892],
  [-30.8569, 116.3873],
  [-30.8380, 116.3872]
];

const piawanningMiling = [
  [-30.8380, 116.3872],
  [-30.8320, 116.3871],
  [-30.8294, 116.3865],
  [-30.8275, 116.3856],
  [-30.8252, 116.3851],
  [-30.8186, 116.3843],
  [-30.7917, 116.3842],
  [-30.7888, 116.3849],
  [-30.7771, 116.3910],
  [-30.7756, 116.3915],
  [-30.7663, 116.3924],
  [-30.7621, 116.3940],
  [-30.7601, 116.3941],
  [-30.7432, 116.3906],
  [-30.7405, 116.3891],
  [-30.7351, 116.3841],
  [-30.7334, 116.3836],
  [-30.7321, 116.3838],
  [-30.7192, 116.3895],
  [-30.7167, 116.3898],
  [-30.7142, 116.3890],
  [-30.7098, 116.3854],
  [-30.7069, 116.3846],
  [-30.6987, 116.3855],
  [-30.6972, 116.3852],
  [-30.6948, 116.3840],
  [-30.6933, 116.3836],
  [-30.6885, 116.3842],
  [-30.6868, 116.3838],
  [-30.6668, 116.3741],
  [-30.6643, 116.3738],
  [-30.6589, 116.3762],
  [-30.6576, 116.3762],
  [-30.6566, 116.3757],
  [-30.6548, 116.3738],
  [-30.6535, 116.3730],
  [-30.6518, 116.3727],
  [-30.6505, 116.3717],
  [-30.6495, 116.3692],
  [-30.6485, 116.3680],
  [-30.6475, 116.3676],
  [-30.6456, 116.3676],
  [-30.6442, 116.3665],
  [-30.6428, 116.3641],
  [-30.6410, 116.3630],
  [-30.6388, 116.3633],
  [-30.6367, 116.3652],
  [-30.6357, 116.3656],
  [-30.6343, 116.3656],
  [-30.6292, 116.3637],
  [-30.6277, 116.3637],
  [-30.6264, 116.3643],
  [-30.6211, 116.3695],
  [-30.6198, 116.3699],
  [-30.6188, 116.3697],
  [-30.6157, 116.3677],
  [-30.6118, 116.3668],
  [-30.6100, 116.3670],
  [-30.6081, 116.3681],
  [-30.6067, 116.3683],
  [-30.6052, 116.3676],
  [-30.6012, 116.3645],
  [-30.5987, 116.3631],
  [-30.5970, 116.3611],
  [-30.5958, 116.3603],
  [-30.5927, 116.3596],
  [-30.5910, 116.3597],
  [-30.5898, 116.3593],
  [-30.5885, 116.3583],
  [-30.5869, 116.3580],
  [-30.5854, 116.3584],
  [-30.5835, 116.3598],
  [-30.5818, 116.3600],
  [-30.5805, 116.3592],
  [-30.5779, 116.3548],
  [-30.5752, 116.3523],
  [-30.5737, 116.3519],
  [-30.5721, 116.3516],
  [-30.5703, 116.3502],
  [-30.5694, 116.3479],
  [-30.5681, 116.3465],
  [-30.5665, 116.3464],
  [-30.5640, 116.3478],
  [-30.5623, 116.3479],
  [-30.5609, 116.3471],
  [-30.5596, 116.3447],
  [-30.5582, 116.3432],
  [-30.5541, 116.3406],
  [-30.5524, 116.3399],
  [-30.5505, 116.3385],
  [-30.5487, 116.3359],
  [-30.5472, 116.3346],
  [-30.5457, 116.3338],
  [-30.5446, 116.3328],
  [-30.5413, 116.3278],
  [-30.5398, 116.3268],
  [-30.5385, 116.3267],
  [-30.5324, 116.3281],
  [-30.5307, 116.3290],
  [-30.5216, 116.3395],
  [-30.5205, 116.3405],
  [-30.5155, 116.3438],
  [-30.5143, 116.3441],
  [-30.5130, 116.3440],
  [-30.5081, 116.3425],
  [-30.5062, 116.3426],
  [-30.5045, 116.3432],
  [-30.5032, 116.3445],
  [-30.4941, 116.3580],
  [-30.4932, 116.3589],
  [-30.4884, 116.3619]
];

export const miling: Line = {
  name: 'Miling',
  state: 'WA',
  segments: [
    {
      segments: [clacklineToodyay],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [toodayAllignmentReuse],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-14',
          gauge: 'dual',
          tracks: 2
        }]
      }
    },
    {
      segments: [toodyayToodyayJunction],
      history: {
        opened: {
          date: '1909-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [toodyayConnector],
      history: {
        opened: {
          date: '1966-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [toodyayBolgart],
      history: {
        opened: {
          date: '1909-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bolgartCalingiri],
      history: {
        opened: {
          date: '1917-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [calingiriPiawaning],
      history: {
        opened: {
          date: '1919-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [piawanningMiling],
      history: {
        opened: {
          date: '1925-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
};
