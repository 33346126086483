import { Line } from "../../trackTypes";

const hexhamWharfHexhamExchangeSidings = [
  [-32.8266, 151.6894],
  [-32.8275, 151.6784]
];

const hexhamExchangeSidingsMinmiunction = [
  [-32.8284, 151.6839],
  [-32.8277, 151.6814],
  [-32.8275, 151.6800],
  [-32.8275, 151.6784],
  [-32.8279, 151.6759],
  [-32.8287, 151.6741],
  [-32.8597, 151.6360]
];

const minmiJunctionStockringtonNo2Colliery = [
  [-32.8597, 151.6360],
  [-32.8605, 151.6345],
  [-32.8604, 151.6333],
  [-32.8597, 151.6320],
  [-32.8590, 151.6310],
  [-32.8585, 151.6294],
  [-32.8575, 151.6242],
  [-32.8570, 151.6202],
  [-32.8568, 151.6132],
  [-32.8574, 151.6108],
  [-32.8574, 151.6085],
  [-32.8576, 151.6073],
  [-32.8581, 151.6063],
  [-32.8597, 151.6040],
  [-32.8633, 151.5987]
];

const stockringtonNo2CollieryTunnel1 = [
  [-32.8633, 151.5987],
  [-32.8648, 151.5972],
  [-32.8669, 151.5955],
  [-32.8707, 151.5903],
  [-32.8718, 151.5895],
  [-32.8758, 151.5881],
  [-32.8767, 151.5873],
  [-32.8773, 151.5856],
  [-32.8771, 151.5833],
  [-32.8772, 151.5824],
  [-32.8776, 151.5813],
  [-32.8794, 151.5784]
];

const richmondValeNo1Tunnel = [
  [-32.8794, 151.5784],
  [-32.8801, 151.5766]
];

const tunnel1Tunnel2 = [
  [-32.8801, 151.5766],
  [-32.8803, 151.5758],
  [-32.8802, 151.5708],
  [-32.8784, 151.5661],
  [-32.8770, 151.5649]
];

const sugarloafTunnel = [
  [-32.8770, 151.5649],
  [-32.8732, 151.5637]
];

const tunnel2Tunnel3 = [
  [-32.8732, 151.5637],
  [-32.8718, 151.5627],
  [-32.8706, 151.5616],
  [-32.8695, 151.5612],
  [-32.8675, 151.5616],
  [-32.8663, 151.5613],
  [-32.8653, 151.5601],
  [-32.8643, 151.5556],
  [-32.8646, 151.5508],
  [-32.8642, 151.5494],
  [-32.8631, 151.5484],
  [-32.8584, 151.5464],
  [-32.8576, 151.5456],
  [-32.8570, 151.5445],
  [-32.8557, 151.5379],
  [-32.8543, 151.5277],
  [-32.8543, 151.5255],
  [-32.8545, 151.5235]
];

const richmondValeNo3Tunnel = [
  [-32.8545, 151.5235],
  [-32.8546, 151.5223]
];

const tunnel3RichmondValeJunction = [
  [-32.8546, 151.5223],
  [-32.8547, 151.5210],
  [-32.8544, 151.5199],
  [-32.8538, 151.5189],
  [-32.8520, 151.5172],
  [-32.8513, 151.5162],
  [-32.8511, 151.5149],
  [-32.8517, 151.5122],
  [-32.8517, 151.5106],
  [-32.8501, 151.5029]
];

const richmondValeJunctionRichmondMainColliery = [
  [-32.8501, 151.5029],
  [-32.8501, 151.5022],
  [-32.8502, 151.4982],
  [-32.8552, 151.4825],
  [-32.8551, 151.4805],
  [-32.8546, 151.4787],
  [-32.8548, 151.4770],
  [-32.8551, 151.4762],
  [-32.8556, 151.4758],
  [-32.8564, 151.4756],
  [-32.8590, 151.4746],
  [-32.8598, 151.4746],
  [-32.8602, 151.4748],
  [-32.8608, 151.4750],
  [-32.8612, 151.4749]
];

const minmiJunctionMinmiOpenCut = [
  [-32.8597, 151.6360],
  [-32.8671, 151.6269],
  [-32.8711, 151.6228],
  [-32.8715, 151.6223],
  [-32.8742, 151.6177],
  [-32.8755, 151.6162],
  [-32.8754, 151.6162],
  [-32.8765, 151.6155]
];

const minmiOpenCutMinmiPlatform = [
  [-32.8765, 151.6155],
  [-32.8786, 151.6149],
  [-32.8809, 151.6151],
  [-32.8818, 151.6144],
  [-32.8820, 151.6137]
];

const duckenfieldJunctionDuckenfieldColliery = [
  [-32.8755, 151.6162],
  [-32.8763, 151.6145],
  [-32.8771, 151.6138],
  [-32.8779, 151.6137],
  [-32.8788, 151.6130],
  [-32.8791, 151.6125],
  [-32.8796, 151.6110],
  [-32.8796, 151.6103],
  [-32.8784, 151.6071]
];

const backCreekJunctionBrownsColliery = [
  [-32.8715, 151.6223],
  [-32.8735, 151.6208],
  [-32.8792, 151.6192],
  [-32.8834, 151.6188],
  [-32.8841, 151.6190],
  [-32.8849, 151.6195],
  [-32.8859, 151.6214],
  [-32.8872, 151.6228]
];

const richmondValeJunctionPelawMain = [
  [-32.8501, 151.5029],
  [-32.8494, 151.5007],
  [-32.8485, 151.5000],
  [-32.8463, 151.4997],
  [-32.8450, 151.4993],
  [-32.8404, 151.4970],
  [-32.8395, 151.4963],
  [-32.8346, 151.4902],
  [-32.8338, 151.4896],
  [-32.8330, 151.4895],
  [-32.8304, 151.4900],
  [-32.8293, 151.4896],
  [-32.8285, 151.4886],
  [-32.8259, 151.4796],
  [-32.8258, 151.4781],
  [-32.8263, 151.4744],
  [-32.8275, 151.4711],
  [-32.8281, 151.4683]
];

const pelawMainWesternJunction = [
  [-32.8263, 151.4744],
  [-32.8262, 151.4730],
  [-32.8257, 151.4698],
  [-32.8250, 151.4684],
  [-32.8237, 151.4670],
  [-32.8226, 151.4669],
  [-32.8182, 151.4682],
  [-32.8170, 151.4681],
  [-32.8161, 151.4675],
  [-32.8156, 151.4662],
  [-32.8154, 151.4653],
  [-32.8154, 151.4643]
];

const pelawMainRichmondMainColliery = [
  [-32.8275, 151.4711],
  [-32.8279, 151.4726],
  [-32.8287, 151.4741],
  [-32.8298, 151.4751],
  [-32.8340, 151.4749],
  [-32.8354, 151.4756],
  [-32.8373, 151.4778],
  [-32.8383, 151.4784],
  [-32.8397, 151.4786],
  [-32.8523, 151.4764],
  [-32.8558, 151.4758]
];

const richmondMainCollieryJunction = [
  [-32.8523, 151.4764],
  [-32.8535, 151.4768],
  [-32.8542, 151.4775],
  [-32.8546, 151.4787]
];

export const richmondVale: Line = {
  name: 'Richmond Vale',
  state: 'NSW',
  segments: [
    {
      segments: [hexhamWharfHexhamExchangeSidings],
      history: {
        opened: {
          date: '1857-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hexhamExchangeSidingsMinmiunction],
      history: {
        opened: {
          date: '1905-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [minmiJunctionStockringtonNo2Colliery],
      history: {
        opened: {
          date: '1905-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-06-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        stockringtonNo2CollieryTunnel1,
        {
          name: 'Richmond Vale No 1 Tunnel',
          segment: richmondValeNo1Tunnel,
          type: 'tunnel'
        },
        tunnel1Tunnel2,
        {
          name: 'Sugarloaf Tunnel',
          segment: sugarloafTunnel,
          type: 'tunnel'
        },
        tunnel2Tunnel3,
        {
          name: 'Richmond Vale No 3 Tunnel',
          segment: richmondValeNo3Tunnel,
          type: 'tunnel'
        },
        tunnel3RichmondValeJunction
      ],
      history: {
        opened: {
          date: '1857-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [richmondValeJunctionRichmondMainColliery],
      history: {
        opened: {
          date: '1905-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-07-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [minmiJunctionMinmiOpenCut],
      history: {
        opened: {
          date: '1905-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-01-01',
          status: 'closed'
        }, {
          date: '1967-07-28',
          status: 'closed'
        }, {
          date: '1949-04-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [minmiOpenCutMinmiPlatform],
      history: {
        opened: {
          date: '1905-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [duckenfieldJunctionDuckenfieldColliery],
      history: {
        opened: {
          date: '1874-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [backCreekJunctionBrownsColliery],
      history: {
        opened: {
          date: '1876-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [richmondValeJunctionPelawMain],
      history: {
        opened: {
          date: '1906-03-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-07-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pelawMainWesternJunction],
      history: {
        opened: {
          date: '1902-01-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pelawMainRichmondMainColliery],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [richmondMainCollieryJunction],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
