import { Line } from "../../../../trackTypes";

const newThebarton = [
  [-34.92320, 138.57931],
  [-34.92345, 138.57574],
  [-34.92371, 138.56994],
  [-34.92417, 138.56099]
];

const henleyBeachSouthOld = [
  [-34.92687, 138.50770],
  [-34.92681, 138.50730],
  [-34.92666, 138.50698],
  [-34.92641, 138.50673],
  [-34.92571, 138.50625],
  [-34.92549, 138.50599],
  [-34.92533, 138.50570],
  [-34.92521, 138.50506],
  [-34.92557, 138.49701]
];

const henleyBeachSouthNew = [
  [-34.92687, 138.50770],
  [-34.92706, 138.50240],
  [-34.92700, 138.50203],
  [-34.92688, 138.50147],
  [-34.92574, 138.49766],
  [-34.92565, 138.49738],
  [-34.92557, 138.49706],
  [-34.92557, 138.49701]
];

const seaviewRoad = [
  [-34.92557, 138.49701],
  [-34.92558, 138.49654],
  [-34.92553, 138.49625],
  [-34.92540, 138.49606],
  [-34.92522, 138.49589],
  [-34.92522, 138.49589],
  [-34.91984, 138.49439]
];

const henleyBeach = [
  [-34.92417, 138.56099],
  [-34.92503, 138.54358],
  [-34.92507, 138.54324],
  [-34.92517, 138.54293],
  [-34.92729, 138.53801],
  [-34.92751, 138.53737],
  [-34.92762, 138.53691],
  [-34.92764, 138.53655],
  [-34.92761, 138.53620],
  [-34.92746, 138.53564],
  [-34.92736, 138.53538],
  [-34.92719, 138.53508],
  [-34.92690, 138.53466],
  [-34.92676, 138.53437],
  [-34.92666, 138.53394],
  [-34.92604, 138.52922],
  [-34.92603, 138.52863],
  [-34.92642, 138.51621],
  [-34.92687, 138.50770]
];

const northStreet = [
  [-34.91984, 138.49439],
  [-34.91678, 138.49354]
];

const marlboroughStreet = [
  [-34.91678, 138.49354],
  [-34.91406, 138.49280]
];

const grange = [
  [-34.91406, 138.49280],
  [-34.90775, 138.49113],
  [-34.90246, 138.49005]
];

export const west2: Line = {
  name: 'West2',
  state: 'SA',
  segments: [
    {
      segments: [newThebarton],
      history: {
        opened: {
          date: '1882-10-07',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1910-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [henleyBeach],
      history: {
        opened: {
          date: '1883-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-12-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [henleyBeachSouthOld],
      history: {
        opened: {
          date: '1883-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-12-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [henleyBeachSouthNew],
      history: {
        opened: {
          date: '1909-12-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [seaviewRoad],
      history: {
        opened: {
          date: '1883-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-12-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northStreet],
      history: {
        opened: {
          date: '1883-05-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1894-01-01',
          status: 'closed'
        }, {
          date: '1912-01-29',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [marlboroughStreet],
      history: {
        opened: {
          date: '1883-05-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1894-01-01',
          status: 'closed'
        }, {
          date: '1919-11-08',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [grange],
      history: {
        opened: {
          date: '1883-05-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1894-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
