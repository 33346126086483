import { Line } from "../../trackTypes";

const orangeWestForkOrangeNorthFork = [
  [-33.2997, 149.0989],
  [-33.2994, 149.1005],
  [-33.2988, 149.1014],
  [-33.2979, 149.1020],
  [-33.2967, 149.10223]
];

const orangeEastForkMolong = [
  [-33.3027, 149.10238],
  [-33.3014, 149.1018],
  [-33.3006, 149.1010],
  [-33.3000, 149.1000],
  [-33.2997, 149.0989],
  [-33.2930, 149.0555],
  [-33.2923, 149.0534],
  [-33.2890, 149.0476],
  [-33.2885, 149.0454],
  [-33.2885, 149.0430],
  [-33.2913, 149.0347],
  [-33.2916, 149.0327],
  [-33.2914, 149.0312],
  [-33.2847, 149.0077],
  [-33.2836, 149.0059],
  [-33.2816, 149.0043],
  [-33.2807, 149.0031],
  [-33.2803, 149.0016],
  [-33.2803, 149.0000],
  [-33.2813, 148.9943],
  [-33.2812, 148.9922],
  [-33.2805, 148.9906],
  [-33.2791, 148.9892],
  [-33.2776, 148.9885],
  [-33.2673, 148.9869],
  [-33.2655, 148.9860],
  [-33.2644, 148.9851],
  [-33.2594, 148.9790],
  [-33.2576, 148.9778],
  [-33.2394, 148.9730],
  [-33.2321, 148.9704],
  [-33.2240, 148.9661],
  [-33.2176, 148.9615],
  [-33.2027, 148.9492],
  [-33.2006, 148.9482],
  [-33.1986, 148.9479],
  [-33.1959, 148.9478],
  [-33.1946, 148.9474],
  [-33.1933, 148.9464],
  [-33.1926, 148.9453],
  [-33.1921, 148.9441],
  [-33.1871, 148.9193],
  [-33.1852, 148.9158],
  [-33.1815, 148.9129],
  [-33.1751, 148.9109],
  [-33.1693, 148.9077],
  [-33.1505, 148.8905],
  [-33.1460, 148.8877],
  [-33.1202, 148.8796],
  [-33.1065, 148.8777],
  [-33.1049, 148.8772],
  [-33.1033, 148.8758],
  [-33.0997, 148.8714],
  [-33.0987, 148.8708],
  [-33.0976, 148.8705],
  [-33.0927, 148.8717]
];

const molongParkes = [
  [-33.0927, 148.8717],
  [-33.0905, 148.8723],
  [-33.0889, 148.8720],
  [-33.0878, 148.8712],
  [-33.0842, 148.8665],
  [-33.0822, 148.8625],
  [-33.0819, 148.8614],
  [-33.0819, 148.8606],
  [-33.0824, 148.8589],
  [-33.0829, 148.8580],
  [-33.0838, 148.8572],
  [-33.0853, 148.8566],
  [-33.0865, 148.8556],
  [-33.0882, 148.8532],
  [-33.0926, 148.8490],
  [-33.0952, 148.8470],
  [-33.0966, 148.8463],
  [-33.0988, 148.8459],
  [-33.0997, 148.8454],
  [-33.1005, 148.8446],
  [-33.1011, 148.8434],
  [-33.1018, 148.8427],
  [-33.1032, 148.8417],
  [-33.1043, 148.8407],
  [-33.1050, 148.8402],
  [-33.1072, 148.8392],
  [-33.1083, 148.8381],
  [-33.1090, 148.8365],
  [-33.1092, 148.8344],
  [-33.1098, 148.8329],
  [-33.1111, 148.8320],
  [-33.1131, 148.8317],
  [-33.1142, 148.8312],
  [-33.1165, 148.8298],
  [-33.1207, 148.8287],
  [-33.1275, 148.8292],
  [-33.1372, 148.8281],
  [-33.1499, 148.8231],
  [-33.1513, 148.8217],
  [-33.1520, 148.8195],
  [-33.1520, 148.8180],
  [-33.1522, 148.8167],
  [-33.1531, 148.8147],
  [-33.1536, 148.8115],
  [-33.1528, 148.8083],
  [-33.1508, 148.8050],
  [-33.1501, 148.8023],
  [-33.1504, 148.7999],
  [-33.1516, 148.7976],
  [-33.1535, 148.7962],
  [-33.1557, 148.7954],
  [-33.1621, 148.7923],
  [-33.1631, 148.7920],
  [-33.1652, 148.7917],
  [-33.1683, 148.7901],
  [-33.1702, 148.7874],
  [-33.1714, 148.7848],
  [-33.1733, 148.7821],
  [-33.1758, 148.7803],
  [-33.1775, 148.7783],
  [-33.1786, 148.7757],
  [-33.1845, 148.7441],
  [-33.1848, 148.7389],
  [-33.1836, 148.7243],
  [-33.1841, 148.7179],
  [-33.1846, 148.7159],
  [-33.1856, 148.7145],
  [-33.1875, 148.7124],
  [-33.1882, 148.7100],
  [-33.1886, 148.7061],
  [-33.1883, 148.7046],
  [-33.1873, 148.7023],
  [-33.1868, 148.7005],
  [-33.1865, 148.6893],
  [-33.1854, 148.6858],
  [-33.1837, 148.6833],
  [-33.1828, 148.6815],
  [-33.1826, 148.6795],
  [-33.1826, 148.6779],
  [-33.1823, 148.6762],
  [-33.1815, 148.6748],
  [-33.1812, 148.6737],
  [-33.1812, 148.6728],
  [-33.1816, 148.6711],
  [-33.1815, 148.6696],
  [-33.1810, 148.6681],
  [-33.1810, 148.6667],
  [-33.1817, 148.6654],
  [-33.1832, 148.6645],
  [-33.1838, 148.6640],
  [-33.1894, 148.6567],
  [-33.1901, 148.6561],
  [-33.1914, 148.6552],
  [-33.1925, 148.6538],
  [-33.1939, 148.6497],
  [-33.1940, 148.6483],
  [-33.1935, 148.6471],
  [-33.1923, 148.6455],
  [-33.1920, 148.6443],
  [-33.1922, 148.6431],
  [-33.1930, 148.6419],
  [-33.1944, 148.6410],
  [-33.1951, 148.6397],
  [-33.1953, 148.6383],
  [-33.1938, 148.6306],
  [-33.1939, 148.6292],
  [-33.1945, 148.6281],
  [-33.1959, 148.6266],
  [-33.1964, 148.6256],
  [-33.1967, 148.6235],
  [-33.1964, 148.6206],
  [-33.1955, 148.6180],
  [-33.1866, 148.6017],
  [-33.1863, 148.6003],
  [-33.1867, 148.5987],
  [-33.1890, 148.5946],
  [-33.1896, 148.5924],
  [-33.1894, 148.5896],
  [-33.1884, 148.5874],
  [-33.1868, 148.5862],
  [-33.1850, 148.5856],
  [-33.1838, 148.5847],
  [-33.1830, 148.5833],
  [-33.1826, 148.5815],
  [-33.1828, 148.5780],
  [-33.1825, 148.5760],
  [-33.1815, 148.5742],
  [-33.1801, 148.5731],
  [-33.1787, 148.5725],
  [-33.1776, 148.5715],
  [-33.1767, 148.5700],
  [-33.1763, 148.5678],
  [-33.1749, 148.5650],
  [-33.1745, 148.5632],
  [-33.1747, 148.5615],
  [-33.1756, 148.5591],
  [-33.1757, 148.5573],
  [-33.1753, 148.5558],
  [-33.1744, 148.5540],
  [-33.1740, 148.5529],
  [-33.1736, 148.5506],
  [-33.1730, 148.5493],
  [-33.1719, 148.5483],
  [-33.1700, 148.5473],
  [-33.1661, 148.5433],
  [-33.1653, 148.5418],
  [-33.1639, 148.5371],
  [-33.1638, 148.5345],
  [-33.1647, 148.5325],
  [-33.1687, 148.5280],
  [-33.1708, 148.5250],
  [-33.1733, 148.5204],
  [-33.1741, 148.5180],
  [-33.1745, 148.5149],
  [-33.1752, 148.5127],
  [-33.1802, 148.5038],
  [-33.1817, 148.5020],
  [-33.1842, 148.5003],
  [-33.1856, 148.4993],
  [-33.1865, 148.4979],
  [-33.1870, 148.4968],
  [-33.1879, 148.4955],
  [-33.1894, 148.4942],
  [-33.1901, 148.4927],
  [-33.1900, 148.4911],
  [-33.1892, 148.4896],
  [-33.1890, 148.4885],
  [-33.1894, 148.4869],
  [-33.1905, 148.4861],
  [-33.1922, 148.4858],
  [-33.1933, 148.4849],
  [-33.1938, 148.4837],
  [-33.1937, 148.4828],
  [-33.1934, 148.4814],
  [-33.1935, 148.4802],
  [-33.1941, 148.4790],
  [-33.1953, 148.4776],
  [-33.1958, 148.4764],
  [-33.1970, 148.4752],
  [-33.1984, 148.4752],
  [-33.1995, 148.4759],
  [-33.2006, 148.4761],
  [-33.2015, 148.4760],
  [-33.2028, 148.4762],
  [-33.2044, 148.4777],
  [-33.2059, 148.4783],
  [-33.2070, 148.4778],
  [-33.2084, 148.4758],
  [-33.2096, 148.4751],
  [-33.2111, 148.4747],
  [-33.2118, 148.4742],
  [-33.2124, 148.4732],
  [-33.2136, 148.4666],
  [-33.2145, 148.4645],
  [-33.2147, 148.4632],
  [-33.2148, 148.4588],
  [-33.2150, 148.4577],
  [-33.2171, 148.4513],
  [-33.2174, 148.4491],
  [-33.2171, 148.4473],
  [-33.2148, 148.4411],
  [-33.2146, 148.4395],
  [-33.2150, 148.4377],
  [-33.2161, 148.4357],
  [-33.2166, 148.4338],
  [-33.2165, 148.4320],
  [-33.2158, 148.4295],
  [-33.2156, 148.4278],
  [-33.2159, 148.4263],
  [-33.2166, 148.4245],
  [-33.2178, 148.4165],
  [-33.2187, 148.4147],
  [-33.2202, 148.4135],
  [-33.2215, 148.4127],
  [-33.2223, 148.4116],
  [-33.2251, 148.4053],
  [-33.2256, 148.4029],
  [-33.2253, 148.3993],
  [-33.2215, 148.3904],
  [-33.2211, 148.3892],
  [-33.2207, 148.3854],
  [-33.2203, 148.3837],
  [-33.2195, 148.3810],
  [-33.2192, 148.3792],
  [-33.2194, 148.3759],
  [-33.2191, 148.3739],
  [-33.2182, 148.3718],
  [-33.2178, 148.3698],
  [-33.2180, 148.3684],
  [-33.2183, 148.3668],
  [-33.2182, 148.3656],
  [-33.2175, 148.3644],
  [-33.2164, 148.3635],
  [-33.2157, 148.3625],
  [-33.2155, 148.3611],
  [-33.2159, 148.3598],
  [-33.2188, 148.3570],
  [-33.2194, 148.3557],
  [-33.2194, 148.3545],
  [-33.2190, 148.3535],
  [-33.2175, 148.3516],
  [-33.2171, 148.3506],
  [-33.2170, 148.3493],
  [-33.2176, 148.3482],
  [-33.2184, 148.3475],
  [-33.2202, 148.3471],
  [-33.2216, 148.3462],
  [-33.2233, 148.3443],
  [-33.2238, 148.3429],
  [-33.2238, 148.3418],
  [-33.2235, 148.3406],
  [-33.2235, 148.3395],
  [-33.2239, 148.3380],
  [-33.2239, 148.3370],
  [-33.2236, 148.3358],
  [-33.2223, 148.3345],
  [-33.2215, 148.3335],
  [-33.2205, 148.3312],
  [-33.2196, 148.3300],
  [-33.2179, 148.3282],
  [-33.2167, 148.3266],
  [-33.2128, 148.3189],
  [-33.1985, 148.2967],
  [-33.1948, 148.2910],
  [-33.1934, 148.2873],
  [-33.1932, 148.2838],
  [-33.1936, 148.2812],
  [-33.1958, 148.2752],
  [-33.1965, 148.2709],
  [-33.1953, 148.2568],
  [-33.1945, 148.2377],
  [-33.1941, 148.2347],
  [-33.1921, 148.2269],
  [-33.1905, 148.2240],
  [-33.1547, 148.1978],
  [-33.1530, 148.1961],
  [-33.1460, 148.1882],
  [-33.1443, 148.1854],
  [-33.1435, 148.1826],
  [-33.1422, 148.1730]
];

const parkesBoganGate = [
  [-33.1422, 148.1730],
  [-33.1419, 148.1705],
  [-33.1419, 148.1683],
  [-33.1427, 148.1652],
  [-33.1446, 148.1604],
  [-33.1450, 148.1585],
  [-33.1446, 148.1564],
  [-33.1419, 148.1529],
  [-33.1410, 148.1506],
  [-33.1396, 148.1436],
  [-33.1380, 148.1403],
  [-33.1354, 148.1349],
  [-33.1346, 148.1320],
  [-33.1344, 148.1298],
  [-33.1349, 148.1267],
  [-33.1364, 148.1236],
  [-33.1402, 148.1210],
  [-33.1433, 148.1187],
  [-33.1458, 148.1140],
  [-33.1463, 148.1116],
  [-33.1460, 148.1083],
  [-33.1440, 148.1030],
  [-33.1435, 148.1006],
  [-33.1436, 148.0910],
  [-33.1429, 148.0286],
  [-33.1447, 147.9804],
  [-33.1413, 147.9534],
  [-33.1413, 147.9515],
  [-33.1418, 147.9486],
  [-33.1419, 147.9463],
  [-33.1408, 147.9376],
  [-33.1411, 147.9342],
  [-33.1407, 147.9310],
  [-33.1369, 147.9208],
  [-33.1309, 147.9101],
  [-33.1282, 147.9048],
  [-33.1259, 147.9025],
  [-33.1214, 147.9007],
  [-33.1189, 147.8987],
  [-33.1151, 147.8939],
  [-33.1138, 147.8901],
  [-33.1140, 147.8867],
  [-33.1151, 147.8837],
  [-33.1182, 147.8795],
  [-33.1195, 147.8768],
  [-33.1198, 147.8738],
  [-33.1194, 147.8712],
  [-33.1127, 147.8554],
  [-33.1095, 147.8380],
  [-33.1077, 147.8331],
  [-33.1073, 147.8306],
  [-33.1086, 147.8025],
  [-33.1087, 147.7987]
];

const boganGateCondobolin = [
  [-33.1087, 147.7987],
  [-33.1097, 147.7788],
  [-33.1347, 147.6233],
  [-33.1349, 147.6197],
  [-33.1175, 147.4753],
  [-33.1177, 147.4721],
  [-33.1188, 147.4697],
  [-33.1210, 147.4660],
  [-33.1214, 147.4636],
  [-33.1216, 147.4606],
  [-33.1230, 147.4556],
  [-33.1232, 147.4529],
  [-33.1225, 147.4497],
  [-33.1199, 147.4446],
  [-33.1193, 147.4428],
  [-33.1191, 147.4410],
  [-33.1190, 147.4321],
  [-33.1177, 147.4218],
  [-33.1177, 147.4195],
  [-33.1198, 147.4106],
  [-33.1207, 147.4086],
  [-33.1238, 147.4045],
  [-33.1250, 147.4011],
  [-33.1251, 147.3981],
  [-33.1206, 147.3753],
  [-33.1191, 147.3729],
  [-33.1127, 147.3670],
  [-33.1108, 147.3642],
  [-33.1001, 147.3441],
  [-33.0837, 147.2997],
  [-33.0755, 147.2723],
  [-33.0746, 147.2643],
  [-33.0771, 147.2478],
  [-33.0765, 147.2151],
  [-33.0775, 147.2052],
  [-33.0759, 147.1910],
  [-33.0760, 147.1880],
  [-33.0779, 147.1804],
  [-33.0786, 147.1719],
  [-33.0812, 147.1622],
  [-33.0840, 147.1530]
];

const condobolinTrida = [
  [-33.0840, 147.1530],
  [-33.0840, 147.1515],
  [-33.0834, 147.1502],
  [-33.0824, 147.1492],
  [-33.0788, 147.1479],
  [-33.0763, 147.1461],
  [-33.0741, 147.1435],
  [-33.0726, 147.1396],
  [-33.0721, 147.1362],
  [-33.0728, 147.1097],
  [-33.0652, 147.0430],
  [-33.0603, 146.9994],
  [-33.0453, 146.8969],
  [-33.0332, 146.8152],
  [-33.0097, 146.6576],
  [-33.0096, 146.6513],
  [-33.0248, 146.5778],
  [-33.0453, 146.4496],
  [-33.0565, 146.3785],
  [-33.0533, 146.2420],
  [-33.0536, 146.2388],
  [-33.0541, 146.2361],
  [-33.0540, 146.2333],
  [-33.0014, 145.9864],
  [-32.9900, 145.8996],
  [-32.9874, 145.8797],
  [-32.9873, 145.8749],
  [-32.9995, 145.7689],
  [-33.0500, 145.4632],
  [-33.0506, 145.4598],
  [-33.0588, 145.4094],
  [-33.0576, 145.3088],
  [-33.0380, 145.1333],
  [-33.0195, 145.0099]
];

const tridaIvanhoe = [
  [-33.0195, 145.0099],
  [-33.0187, 145.0044],
  [-32.9442, 144.5153],
  [-32.9167, 144.3393],
  [-32.9142, 144.3117]
];

const ivanhoeMenindee = [
  [-32.9142, 144.3117],
  [-32.8821, 143.9572],
  [-32.8676, 143.8025],
  [-32.8446, 143.5607],
  [-32.8430, 143.5544],
  [-32.7902, 143.4386],
  [-32.7888, 143.4340],
  [-32.7850, 143.4152],
  [-32.7593, 143.3463],
  [-32.7235, 143.2628],
  [-32.6864, 143.1769],
  [-32.6505, 143.1156],
  [-32.4733, 142.6063],
  [-32.4336, 142.5596],
  [-32.4307, 142.5546],
  [-32.4211, 142.5472],
  [-32.4121, 142.5375],
  [-32.4101, 142.5346],
  [-32.3877, 142.4742],
  [-32.3866, 142.4656],
  [-32.3891, 142.4396],
  [-32.3892, 142.4246]
];

const menindeeBrokenHill = [
  [-32.3892, 142.4246],
  [-32.3893, 142.4207],
  [-32.3888, 142.4189],
  [-32.3876, 142.4172],
  [-32.3862, 142.4164],
  [-32.3530, 142.4070],
  [-32.3511, 142.4061],
  [-32.3485, 142.4040],
  [-32.3469, 142.4033],
  [-32.3443, 142.4029],
  [-32.3384, 142.4014],
  [-32.3333, 142.4004],
  [-32.3295, 142.3994],
  [-32.3263, 142.3979],
  [-32.3174, 142.3932],
  [-32.3139, 142.3908],
  [-32.3045, 142.3832],
  [-32.2980, 142.3760],
  [-32.2886, 142.3637],
  [-32.2863, 142.3599],
  [-32.2818, 142.3505],
  [-32.2808, 142.3476],
  [-32.2775, 142.3371],
  [-32.2755, 142.3330],
  [-32.2713, 142.3273],
  [-32.2404, 142.2991],
  [-32.2357, 142.2935],
  [-32.1450, 142.1217],
  [-32.0070, 141.8620],
  [-31.9145, 141.6892],
  [-31.9133, 141.6851],
  [-31.9125, 141.6716],
  [-31.9127, 141.6696],
  [-31.9152, 141.6635],
  [-31.9156, 141.6619],
  [-31.9155, 141.6603],
  [-31.9099, 141.6485],
  [-31.9090, 141.6454],
  [-31.9086, 141.6384],
  [-31.9088, 141.6368],
  [-31.9096, 141.6353],
  [-31.9111, 141.6340],
  [-31.9122, 141.6323],
  [-31.9124, 141.6301],
  [-31.9113, 141.6275],
  [-31.9099, 141.6266],
  [-31.9082, 141.6261],
  [-31.9070, 141.6250],
  [-31.9062, 141.6234],
  [-31.9061, 141.6219],
  [-31.9063, 141.6205],
  [-31.9070, 141.6189],
  [-31.9071, 141.6175],
  [-31.9069, 141.6160],
  [-31.9052, 141.6113],
  [-31.9043, 141.6100],
  [-31.9026, 141.6087],
  [-31.9016, 141.6073],
  [-31.9009, 141.6053],
  [-31.9001, 141.6040],
  [-31.8992, 141.6023],
  [-31.8988, 141.6003],
  [-31.8989, 141.5971],
  [-31.8992, 141.5958],
  [-31.8999, 141.5946],
  [-31.9009, 141.5938],
  [-31.9026, 141.5928],
  [-31.9037, 141.5915],
  [-31.9042, 141.5899],
  [-31.9044, 141.5881],
  [-31.9051, 141.5866],
  [-31.9058, 141.5858],
  [-31.9086, 141.5835],
  [-31.9112, 141.5808],
  [-31.9120, 141.5793],
  [-31.9122, 141.5778],
  [-31.9119, 141.5762],
  [-31.9108, 141.5738],
  [-31.9106, 141.5723],
  [-31.9108, 141.5709],
  [-31.9114, 141.5698],
  [-31.9125, 141.5681],
  [-31.9149, 141.5628],
  [-31.9176, 141.5594],
  [-31.9214, 141.5567],
  [-31.9236, 141.5537],
  [-31.9245, 141.5504],
  [-31.9244, 141.5471],
  [-31.9235, 141.5438],
  [-31.9233, 141.5411],
  [-31.9239, 141.5382],
  [-31.9268, 141.5324],
  [-31.9282, 141.5308],
  [-31.9299, 141.5295],
  [-31.9559, 141.5201],
  [-31.9577, 141.5192],
  [-31.9630, 141.5156],
  [-31.9652, 141.5134],
  [-31.9667, 141.5110],
  [-31.9721, 141.4955],
  [-31.9752, 141.4891],
  [-31.9755, 141.4872],
  [-31.9751, 141.4854],
  [-31.9736, 141.4836],
  [-31.9720, 141.4831],
  [-31.9703, 141.4835],
  [-31.9679, 141.4863],
  [-31.9668, 141.4872],
  [-31.9656, 141.4874],
  [-31.9643, 141.4869],
  [-31.9634, 141.4857],
  [-31.9627, 141.4836],
  [-31.9620, 141.4824],
  [-31.9609, 141.4817],
  [-31.9592, 141.4812],
  [-31.9582, 141.4806],
  [-31.9575, 141.4798],
  [-31.9558, 141.4771],
  [-31.9553, 141.4759],
  [-31.9554, 141.4748],
  [-31.9556, 141.4740],
  [-31.9598, 141.4661]
];

const brokenHillSaBorder = [
  [-31.9598, 141.4661],
  [-31.9611, 141.4640],
  [-31.9618, 141.4632],
  [-31.9678, 141.4579],
  [-31.9726, 141.4521],
  [-31.9737, 141.4510],
  [-31.9758, 141.4495],
  [-31.9767, 141.4486],
  [-31.9776, 141.4472],
  [-31.9803, 141.4399],
  [-31.9866, 141.4096],
  [-31.9877, 141.4063],
  [-32.0191, 141.3389],
  [-32.0198, 141.3366],
  [-32.0268, 141.3104],
  [-32.0279, 141.3075],
  [-32.0430, 141.2781],
  [-32.0604, 141.2544],
  [-32.0616, 141.2522],
  [-32.0776, 141.2176],
  [-32.0787, 141.2143],
  [-32.0821, 141.1950],
  [-32.0840, 141.1634],
  [-32.0820, 141.1419],
  [-32.0824, 141.1320],
  [-32.0801, 141.1189],
  [-32.0798, 141.1166],
  [-32.0800, 141.0070],
  [-32.0803, 141.0014]
];

const silvertonTramway = [
  [-31.9587, 141.4615],
  [-31.9623, 141.4554],
  [-31.9629, 141.4532],
  [-31.9621, 141.4497],
  [-31.9590, 141.4430],
  [-31.9586, 141.4405],
  [-31.9587, 141.4378],
  [-31.9599, 141.4319],
  [-31.9598, 141.4236],
  [-31.9516, 141.3942],
  [-31.9482, 141.3862],
  [-31.9467, 141.3832],
  [-31.9398, 141.3765],
  [-31.9193, 141.3513],
  [-31.9176, 141.3467],
  [-31.9173, 141.3232],
  [-31.9184, 141.3199],
  [-31.9219, 141.3152],
  [-31.9228, 141.3130],
  [-31.9230, 141.3094],
  [-31.9215, 141.3052],
  [-31.8938, 141.2604],
  [-31.8894, 141.2466],
  [-31.8868, 141.2326],
  [-31.8845, 141.2104],
  [-31.8847, 141.2073],
  [-31.8842, 141.2052],
  [-31.8842, 141.2042],
  [-31.8846, 141.2029],
  [-31.8893, 141.1955],
  [-31.8899, 141.1938],
  [-31.8899, 141.1915],
  [-31.8892, 141.1887],
  [-31.8892, 141.1872],
  [-31.8899, 141.1854],
  [-31.8949, 141.1784],
  [-31.8981, 141.1752],
  [-31.9185, 141.1610],
  [-32.0169, 141.0689],
  [-32.0757, 141.0141],
  [-32.0768, 141.0123],
  [-32.0774, 141.0100],
  [-32.0774, 141.0014]
];

export const brokenHill: Line = {
  name: 'Broken Hill',
  state: 'NSW',
  segments: [
    {
      segments: [orangeEastForkMolong],
      history: {
        opened: {
          date: '1893-12-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [orangeWestForkOrangeNorthFork],
      history: {
        opened: {
          date: '1885-12-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [molongParkes],
      history: {
        opened: {
          date: '1893-12-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [parkesBoganGate],
      history: {
        opened: {
          date: '1896-12-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [boganGateCondobolin],
      history: {
        opened: {
          date: '1898-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [condobolinTrida],
      history: {
        opened: {
          date: '1919-02-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [tridaIvanhoe],
      history: {
        opened: {
          date: '1919-02-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ivanhoeMenindee],
      history: {
        opened: {
          date: '1927-11-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [menindeeBrokenHill],
      history: {
        opened: {
          date: '1919-07-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [brokenHillSaBorder],
      history: {
        opened: {
          date: '1970-01-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [silvertonTramway],
      history: {
        opened: {
          date: '1888-12-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-09',
          status: 'closed'
        }]
      }
    }
  ]
};
