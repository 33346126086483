import { Line } from "../../trackTypes";

const pinjarraDwellingup = [
  [-32.6330, 115.8805],
  [-32.6337, 115.8808],
  [-32.6341, 115.8812],
  [-32.6347, 115.8820],
  [-32.6461, 115.8929],
  [-32.6865, 115.9311],
  [-32.6879, 115.9331],
  [-32.6893, 115.9408],
  [-32.6899, 115.9422],
  [-32.6967, 115.9498],
  [-32.6975, 115.9505],
  [-32.6997, 115.9517],
  [-32.7008, 115.9528],
  [-32.7013, 115.9540],
  [-32.7020, 115.9572],
  [-32.7025, 115.9580],
  [-32.7030, 115.9586],
  [-32.7033, 115.9590],
  [-32.7044, 115.9613],
  [-32.7047, 115.9625],
  [-32.7045, 115.9656],
  [-32.7041, 115.9663],
  [-32.7036, 115.9667],
  [-32.7034, 115.9673],
  [-32.7034, 115.9681],
  [-32.7036, 115.9687],
  [-32.7037, 115.9692],
  [-32.7035, 115.9710],
  [-32.7033, 115.9714],
  [-32.7027, 115.9720],
  [-32.7024, 115.9726],
  [-32.7022, 115.9736],
  [-32.7026, 115.9746],
  [-32.7032, 115.9750],
  [-32.7043, 115.9749],
  [-32.7049, 115.9751],
  [-32.7054, 115.9757],
  [-32.7054, 115.9763],
  [-32.7053, 115.9771],
  [-32.7053, 115.9777],
  [-32.7057, 115.9791],
  [-32.7056, 115.9797],
  [-32.7055, 115.9803],
  [-32.7056, 115.9808],
  [-32.7057, 115.9815],
  [-32.7056, 115.9823],
  [-32.7052, 115.9828],
  [-32.7047, 115.9830],
  [-32.7043, 115.9835],
  [-32.7040, 115.9844],
  [-32.7039, 115.9850],
  [-32.7031, 115.9868],
  [-32.7031, 115.9874],
  [-32.7031, 115.9883],
  [-32.7029, 115.9888],
  [-32.7026, 115.9893],
  [-32.7021, 115.9896],
  [-32.7019, 115.9902],
  [-32.7018, 115.9906],
  [-32.7019, 115.9914],
  [-32.7018, 115.9919],
  [-32.7013, 115.9934],
  [-32.7012, 115.9939],
  [-32.7014, 115.9944],
  [-32.7016, 115.9947],
  [-32.7029, 115.9957],
  [-32.7033, 115.9963],
  [-32.7034, 115.9968],
  [-32.7029, 115.9982],
  [-32.7024, 115.9986],
  [-32.7004, 115.9989],
  [-32.6999, 115.9989],
  [-32.6990, 115.9986],
  [-32.6985, 115.9986],
  [-32.6967, 115.9995],
  [-32.6962, 115.9999],
  [-32.6948, 116.0018],
  [-32.6945, 116.0026],
  [-32.6947, 116.0034],
  [-32.6951, 116.0039],
  [-32.6954, 116.0045],
  [-32.6954, 116.0053],
  [-32.6958, 116.0063],
  [-32.6977, 116.0085],
  [-32.6985, 116.0102],
  [-32.6986, 116.0108],
  [-32.6987, 116.0119],
  [-32.6992, 116.0131],
  [-32.7001, 116.0139],
  [-32.7005, 116.0145],
  [-32.7006, 116.0152],
  [-32.7005, 116.0163],
  [-32.7007, 116.0172],
  [-32.7025, 116.0195],
  [-32.7029, 116.0206],
  [-32.7028, 116.0231],
  [-32.7031, 116.0250],
  [-32.7046, 116.0286],
  [-32.7090, 116.0391],
  [-32.7098, 116.0398],
  [-32.7131, 116.0410],
  [-32.7137, 116.0416],
  [-32.7155, 116.0452],
  [-32.7156, 116.0465],
  [-32.7140, 116.0511],
  [-32.7140, 116.0521],
  [-32.7144, 116.0529],
  [-32.7155, 116.0544],
  [-32.7158, 116.0558],
  [-32.7155, 116.0569],
  [-32.7111, 116.0634]
];

const dwellingupHolyoake = [
  [-32.7111, 116.0634],
  [-32.7095, 116.0659],
  [-32.7090, 116.0669],
  [-32.7089, 116.0682],
  [-32.7091, 116.0694],
  [-32.7098, 116.0705],
  [-32.7101, 116.0713],
  [-32.7102, 116.0725],
  [-32.7106, 116.0733],
  [-32.7115, 116.0745],
  [-32.7117, 116.0756],
  [-32.7114, 116.0767],
  [-32.7103, 116.0784],
  [-32.7101, 116.0792],
  [-32.7100, 116.0811],
  [-32.7105, 116.0837],
  [-32.7108, 116.0844],
  [-32.7115, 116.0853],
  [-32.7117, 116.0860],
  [-32.7118, 116.0876],
  [-32.7122, 116.0895],
  [-32.7132, 116.0917],
  [-32.7150, 116.0943]
];

const holyoadkeEtmilyn = [
  [-32.7150, 116.0943],
  [-32.7156, 116.0958],
  [-32.7164, 116.0968],
  [-32.7176, 116.0976],
  [-32.7182, 116.0986],
  [-32.7186, 116.0998],
  [-32.7193, 116.1006],
  [-32.7201, 116.1012],
  [-32.7207, 116.1022],
  [-32.7210, 116.1045],
  [-32.7222, 116.1071],
  [-32.7238, 116.1087],
  [-32.7253, 116.1094],
  [-32.7261, 116.1101],
  [-32.7268, 116.1110],
  [-32.7278, 116.1117],
  [-32.7288, 116.1116],
  [-32.7299, 116.1123],
  [-32.7304, 116.1131],
  [-32.7313, 116.1137],
  [-32.7324, 116.1137],
  [-32.7348, 116.1146],
  [-32.7357, 116.1161],
  [-32.7361, 116.1176],
  [-32.7370, 116.1190],
  [-32.7386, 116.1283],
  [-32.7385, 116.1296],
  [-32.7381, 116.1305],
  [-32.7374, 116.1311],
  [-32.7368, 116.1321],
  [-32.7369, 116.1352],
  [-32.7374, 116.1368],
  [-32.7383, 116.1392]
];

const etmilynBoddington = [
  [-32.7383, 116.1392],
  [-32.7397, 116.1419],
  [-32.7402, 116.1446],
  [-32.7403, 116.1464],
  [-32.7456, 116.1524],
  [-32.7466, 116.1537],
  [-32.7477, 116.1578],
  [-32.7490, 116.1601],
  [-32.7496, 116.1627],
  [-32.7504, 116.1650],
  [-32.7516, 116.1661],
  [-32.7543, 116.1669],
  [-32.7551, 116.1675],
  [-32.7566, 116.1700],
  [-32.7598, 116.1736],
  [-32.7602, 116.1746],
  [-32.7602, 116.1757],
  [-32.7586, 116.1808],
  [-32.7585, 116.1829],
  [-32.7575, 116.1881],
  [-32.7577, 116.1902],
  [-32.7598, 116.1976],
  [-32.7620, 116.2012],
  [-32.7621, 116.2027],
  [-32.7608, 116.2072],
  [-32.7609, 116.2119],
  [-32.7613, 116.2134],
  [-32.7624, 116.2156],
  [-32.7629, 116.2173],
  [-32.7632, 116.2213],
  [-32.7629, 116.2227],
  [-32.7611, 116.2259],
  [-32.7609, 116.2278],
  [-32.7615, 116.2296],
  [-32.7674, 116.2348],
  [-32.7754, 116.2403],
  [-32.7763, 116.2403],
  [-32.7778, 116.2397],
  [-32.7788, 116.2399],
  [-32.7797, 116.2412],
  [-32.7801, 116.2469],
  [-32.7821, 116.2541],
  [-32.7816, 116.2566],
  [-32.7799, 116.2583],
  [-32.7791, 116.2596],
  [-32.7775, 116.2666],
  [-32.7775, 116.2679],
  [-32.7781, 116.2689],
  [-32.7857, 116.2760],
  [-32.7961, 116.2801],
  [-32.7975, 116.2811],
  [-32.7997, 116.2851],
  [-32.7997, 116.2871],
  [-32.7992, 116.2894],
  [-32.7996, 116.2938],
  [-32.7994, 116.2968],
  [-32.7990, 116.2989],
  [-32.7954, 116.3041],
  [-32.7950, 116.3062],
  [-32.7954, 116.3102],
  [-32.7948, 116.3134],
  [-32.7956, 116.3149],
  [-32.7974, 116.3159],
  [-32.7985, 116.3170],
  [-32.8037, 116.3293],
  [-32.8052, 116.3313],
  [-32.8069, 116.3313],
  [-32.8104, 116.3312],
  [-32.8114, 116.3320],
  [-32.8122, 116.3349],
  [-32.8117, 116.3379],
  [-32.8106, 116.3399],
  [-32.8089, 116.3414],
  [-32.8083, 116.3425],
  [-32.8083, 116.3441],
  [-32.8100, 116.3511],
  [-32.8094, 116.3530],
  [-32.8083, 116.3544],
  [-32.8058, 116.3610],
  [-32.8056, 116.3624],
  [-32.8057, 116.3678],
  [-32.8079, 116.3797],
  [-32.8079, 116.3820],
  [-32.8070, 116.3876],
  [-32.8071, 116.3887],
  [-32.8076, 116.3900],
  [-32.8075, 116.3913],
  [-32.8071, 116.3921],
  [-32.8053, 116.3933],
  [-32.8038, 116.3950],
  [-32.8035, 116.3960],
  [-32.8034, 116.4021],
  [-32.8031, 116.4037],
  [-32.8016, 116.4065],
  [-32.8014, 116.4075],
  [-32.8017, 116.4084],
  [-32.8056, 116.4148],
  [-32.8062, 116.4170],
  [-32.8057, 116.4261],
  [-32.8061, 116.4275],
  [-32.8084, 116.4311],
  [-32.8088, 116.4325],
  [-32.8090, 116.4498],
  [-32.8089, 116.4513],
  [-32.8084, 116.4534],
  [-32.8082, 116.4548],
  [-32.8084, 116.4577],
  [-32.8077, 116.4628],
  [-32.8066, 116.4664],
  [-32.8040, 116.4705]
];

const boddingtonDwarda = [
  [-32.8040, 116.4705],
  [-32.8014, 116.4728],
  [-32.7998, 116.4737],
  [-32.7989, 116.4749],
  [-32.7980, 116.4771],
  [-32.7977, 116.4785],
  [-32.7977, 116.4809],
  [-32.7972, 116.4823],
  [-32.7965, 116.4830],
  [-32.7951, 116.4840],
  [-32.7940, 116.4851],
  [-32.7922, 116.4877],
  [-32.7920, 116.4892],
  [-32.7924, 116.4946],
  [-32.7920, 116.5021],
  [-32.7917, 116.5035],
  [-32.7905, 116.5066],
  [-32.7876, 116.5105],
  [-32.7871, 116.5122],
  [-32.7871, 116.5149],
  [-32.7873, 116.5160],
  [-32.7885, 116.5185],
  [-32.7939, 116.5260],
  [-32.7941, 116.5275],
  [-32.7902, 116.5428],
  [-32.7876, 116.5478],
  [-32.7873, 116.5493],
  [-32.7858, 116.5569],
  [-32.7860, 116.5724],
  [-32.7855, 116.5743],
  [-32.7752, 116.5953],
  [-32.7747, 116.5978],
  [-32.7746, 116.6004],
  [-32.7738, 116.6023],
  [-32.7736, 116.6041],
  [-32.7740, 116.6091],
  [-32.7744, 116.6109],
  [-32.7758, 116.6142],
  [-32.7758, 116.6166],
  [-32.7763, 116.6183],
  [-32.7781, 116.6223],
  [-32.7800, 116.6314],
  [-32.7811, 116.6349],
  [-32.7837, 116.6394],
  [-32.7850, 116.6444],
  [-32.7857, 116.6530],
  [-32.7850, 116.6562],
  [-32.7778, 116.6694],
  [-32.7746, 116.6731],
  [-32.7708, 116.6763],
  [-32.7698, 116.6779],
  [-32.7653, 116.6869]
];

const dwardaNarrogin = [
  [-32.7653, 116.6869],
  [-32.7641, 116.6898],
  [-32.7638, 116.6916],
  [-32.7638, 116.6940],
  [-32.7635, 116.6966],
  [-32.7631, 116.6981],
  [-32.7631, 116.7003],
  [-32.7645, 116.7036],
  [-32.7650, 116.7055],
  [-32.7650, 116.7160],
  [-32.7644, 116.7179],
  [-32.7635, 116.7190],
  [-32.7581, 116.7219],
  [-32.7569, 116.7229],
  [-32.7563, 116.7241],
  [-32.7555, 116.7269],
  [-32.7555, 116.7289],
  [-32.7565, 116.7313],
  [-32.7568, 116.7330],
  [-32.7565, 116.7471],
  [-32.7561, 116.7486],
  [-32.7496, 116.7682],
  [-32.7494, 116.7696],
  [-32.7494, 116.7864],
  [-32.7472, 116.7937],
  [-32.7469, 116.7958],
  [-32.7472, 116.7976],
  [-32.7483, 116.7994],
  [-32.7486, 116.8010],
  [-32.7489, 116.8159],
  [-32.7485, 116.8177],
  [-32.7473, 116.8201],
  [-32.7470, 116.8226],
  [-32.7479, 116.8256],
  [-32.7492, 116.8278],
  [-32.7508, 116.8289],
  [-32.7629, 116.8309],
  [-32.7640, 116.8314],
  [-32.7700, 116.8363],
  [-32.7712, 116.8387],
  [-32.7717, 116.8444],
  [-32.7718, 116.8456],
  [-32.7728, 116.8475],
  [-32.7740, 116.8487],
  [-32.7748, 116.8509],
  [-32.7754, 116.8535],
  [-32.7767, 116.8546],
  [-32.7815, 116.8554],
  [-32.7831, 116.8568],
  [-32.7835, 116.8588],
  [-32.7841, 116.8621],
  [-32.7856, 116.8648],
  [-32.7863, 116.8667],
  [-32.7866, 116.8693],
  [-32.7876, 116.8713],
  [-32.7888, 116.8724],
  [-32.7933, 116.8745],
  [-32.7949, 116.8759],
  [-32.7958, 116.8781],
  [-32.7963, 116.8802],
  [-32.7975, 116.8820],
  [-32.8022, 116.8847],
  [-32.8063, 116.8866],
  [-32.8170, 116.8871],
  [-32.8379, 116.8947],
  [-32.8397, 116.8968],
  [-32.8460, 116.9242],
  [-32.8488, 116.9328],
  [-32.8607, 116.9510],
  [-32.8611, 116.9527],
  [-32.8613, 116.9723],
  [-32.8616, 116.9737],
  [-32.8667, 116.9858],
  [-32.8670, 116.9877],
  [-32.8668, 116.9962],
  [-32.8681, 117.0054],
  [-32.8689, 117.0070],
  [-32.8711, 117.0088],
  [-32.8719, 117.0106],
  [-32.8720, 117.0125],
  [-32.8711, 117.0166],
  [-32.8696, 117.0193],
  [-32.8693, 117.0209],
  [-32.8695, 117.0221],
  [-32.8717, 117.0263],
  [-32.8720, 117.0288],
  [-32.8708, 117.0341],
  [-32.8707, 117.0388],
  [-32.8713, 117.0410],
  [-32.8734, 117.0436],
  [-32.8737, 117.0445],
  [-32.8731, 117.0511],
  [-32.8731, 117.0532],
  [-32.8737, 117.0552],
  [-32.8740, 117.0592],
  [-32.8748, 117.0627],
  [-32.8758, 117.0649],
  [-32.8788, 117.0678],
  [-32.8802, 117.0686],
  [-32.8831, 117.0689],
  [-32.8841, 117.0695],
  [-32.8850, 117.0708],
  [-32.8856, 117.0729],
  [-32.8861, 117.0739],
  [-32.8935, 117.0827],
  [-32.8940, 117.0837],
  [-32.8943, 117.0862],
  [-32.8935, 117.0900],
  [-32.8934, 117.0911],
  [-32.8933, 117.0952],
  [-32.8935, 117.0959],
  [-32.8936, 117.0969],
  [-32.8934, 117.0985],
  [-32.8935, 117.1001],
  [-32.8940, 117.1013],
  [-32.8964, 117.1049],
  [-32.8970, 117.1071],
  [-32.8969, 117.1092],
  [-32.8971, 117.1112],
  [-32.9016, 117.1290],
  [-32.9044, 117.1361],
  [-32.9053, 117.1372],
  [-32.9064, 117.1382],
  [-32.9073, 117.1394],
  [-32.9081, 117.1420],
  [-32.9078, 117.1443],
  [-32.9070, 117.1458],
  [-32.9051, 117.1475],
  [-32.9041, 117.1492],
  [-32.9040, 117.1512],
  [-32.9045, 117.1528],
  [-32.9056, 117.1542],
  [-32.9101, 117.1567],
  [-32.9114, 117.1584],
  [-32.9117, 117.1608],
  [-32.9112, 117.1626],
  [-32.9106, 117.1639],
  [-32.9104, 117.1650],
  [-32.9108, 117.1675],
  [-32.9118, 117.1688],
  [-32.9133, 117.1695],
  [-32.9166, 117.1699],
  [-32.9211, 117.1723],
  [-32.9221, 117.1734],
  [-32.9227, 117.1753],
  [-32.9234, 117.1768],
  [-32.9245, 117.1779],
  [-32.9259, 117.1785]
];

export const pinjarraNarrogin: Line = {
  name: 'Pinjarra - Narrogin',
  state: 'WA',
  segments: [
    {
      segments: [pinjarraDwellingup],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [dwellingupHolyoake],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }, {
          date: '1986-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [holyoadkeEtmilyn],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }, {
          date: '1986-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [etmilynBoddington],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boddingtonDwarda],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dwardaNarrogin],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
