import { Line } from "../../../trackTypes";

const westportFairdown = [
  [-41.7530, 171.5972],
  [-41.7543, 171.5976],
  [-41.7574, 171.5977],
  [-41.7625, 171.5988],
  [-41.7641, 171.6000],
  [-41.7651, 171.6021],
  [-41.7652, 171.6041],
  [-41.7651, 171.6051],
  [-41.7646, 171.6089],
  [-41.7593, 171.6509],
  [-41.7579, 171.6559],
  [-41.7393, 171.7010],
];

const fairdownWaimangaroa = [
  [-41.7393, 171.7010],
  [-41.7124, 171.7628]
];

const waimangaroaNgakawau = [
  [-41.7124, 171.7628],
  [-41.7118, 171.7640],
  [-41.7106, 171.7654],
  [-41.7081, 171.7671],
  [-41.6988, 171.7719],
  [-41.6957, 171.7745],
  [-41.6661, 171.8113],
  [-41.6377, 171.8466],
  [-41.6171, 171.8658],
  [-41.6151, 171.8682],
  [-41.6105, 171.8747],
  [-41.6101, 171.8755],
  [-41.6092, 171.8784],
  [-41.6092, 171.8794],
  [-41.6094, 171.8803]
];

const ngakawauMokihinui = [
  [-41.6101, 171.8755],
  [-41.6085, 171.8769],
  [-41.5969, 171.8858],
  [-41.5933, 171.8888],
  [-41.5919, 171.8896],
  [-41.5905, 171.8903],
  [-41.5900, 171.8908],
  [-41.5888, 171.8924],
  [-41.5841, 171.8958],
  [-41.5825, 171.8964],
  [-41.5793, 171.8990],
  [-41.5766, 171.9003],
  [-41.5737, 171.9025],
  [-41.5711, 171.9056],
  [-41.5697, 171.9071],
  [-41.5673, 171.9092],
  [-41.5626, 171.9145],
  [-41.5388, 171.9294],
  [-41.5375, 171.9306],
  [-41.5318, 171.9399]
];

const mokihinuiSeddonville = [
  [-41.5318, 171.9399],
  [-41.5298, 171.9436],
  [-41.5296, 171.9453],
  [-41.5308, 171.9498],
  [-41.5327, 171.9546],
  [-41.5340, 171.9585],
  [-41.5343, 171.9648],
  [-41.5346, 171.9666],
  [-41.5354, 171.9679],
  [-41.5373, 171.9700],
  [-41.5384, 171.9716],
  [-41.5406, 171.9736],
  [-41.5430, 171.9750],
  [-41.5447, 171.9770],
  [-41.5460, 171.9803],
  [-41.5558, 171.9926]
];

const seddonVilleMines = [
  [-41.5558, 171.9926],
  [-41.5609, 171.9982],
  [-41.5613, 171.9985],
  [-41.5622, 171.9986],
  [-41.5629, 171.9994],
  [-41.5635, 171.9999],
  [-41.5651, 172.0002],
  [-41.5656, 172.0006],
  [-41.5662, 172.0014],
  [-41.5665, 172.0017],
  [-41.5668, 172.0018],
  [-41.5672, 172.0021],
  [-41.5676, 172.0036],
  [-41.5677, 172.0042],
  [-41.5683, 172.0052],
  [-41.5687, 172.0054],
  [-41.5691, 172.0051],
  [-41.5698, 172.0051],
  [-41.5700, 172.0050],
  [-41.5700, 172.0047],
  [-41.5697, 172.0039],
  [-41.5694, 172.0036],
  [-41.5690, 172.0035],
  [-41.5686, 172.0032],
  [-41.5685, 172.0029],
  [-41.5686, 172.0027],
  [-41.5689, 172.0026],
  [-41.5691, 172.0023],
  [-41.5693, 172.0023],
  [-41.5704, 172.0026],
  [-41.5709, 172.0030],
  [-41.5713, 172.0030],
  [-41.5723, 172.0027],
  [-41.5726, 172.0027],
  [-41.5728, 172.0026],
  [-41.5730, 172.0024],
  [-41.5735, 172.0021],
  [-41.5740, 172.0021],
  [-41.5745, 172.0019],
  [-41.5753, 172.0021],
  [-41.5759, 172.0029],
  [-41.5764, 172.0031],
  [-41.5778, 172.0033],
  [-41.5781, 172.0031],
  [-41.5783, 172.0026],
  [-41.5785, 172.0009],
  [-41.5789, 171.9992],
  [-41.5792, 171.9986],
  [-41.5793, 171.9977],
  [-41.5794, 171.9972],
  [-41.5801, 171.9959],
  [-41.5804, 171.9956],
  [-41.5814, 171.9953],
  [-41.5826, 171.9940],
  [-41.5827, 171.9936],
  [-41.5826, 171.9930],
  [-41.5823, 171.9925],
  [-41.5809, 171.9916],
  [-41.5806, 171.9915],
  [-41.5803, 171.9913],
  [-41.5795, 171.9899],
  [-41.5790, 171.9895],
  [-41.5786, 171.9894],
  [-41.5782, 171.9890],
  [-41.5783, 171.9883],
  [-41.5786, 171.9876],
  [-41.5793, 171.9867],
  [-41.5799, 171.9845],
  [-41.5803, 171.9835],
  [-41.5803, 171.9829],
  [-41.5801, 171.9822],
  [-41.5802, 171.9817],
  [-41.5805, 171.9810],
  [-41.5810, 171.9807],
  [-41.5815, 171.9798],
  [-41.5816, 171.9794],
  [-41.5816, 171.9790],
  [-41.5816, 171.9786],
  [-41.5819, 171.9778],
  [-41.5818, 171.9771],
  [-41.5816, 171.9765],
  [-41.5811, 171.9761],
  [-41.5810, 171.9757],
  [-41.5809, 171.9752],
  [-41.5811, 171.9739],
  [-41.5814, 171.9731],
  [-41.5814, 171.9726],
  [-41.5810, 171.9708],
  [-41.5810, 171.9700],
  [-41.5813, 171.9693],
  [-41.5816, 171.9676],
  [-41.5816, 171.9668],
  [-41.5810, 171.9650],
  [-41.5810, 171.9645],
  [-41.5813, 171.9641],
  [-41.5821, 171.9635],
  [-41.5824, 171.9635],
  [-41.5830, 171.9636],
  [-41.5833, 171.9634],
  [-41.5834, 171.9630],
  [-41.5835, 171.9620],
  [-41.5833, 171.9608],
  [-41.5835, 171.9600],
  [-41.5848, 171.9584],
  [-41.5852, 171.9575],
  [-41.5853, 171.9569],
  [-41.5855, 171.9565],
  [-41.5859, 171.9562],
  [-41.5862, 171.9558],
  [-41.5863, 171.9551]
];

export const seddonville: Line = {
  name: 'Seddonville',
  state: 'NZ',
  segments: [
    {
      segments: [westportFairdown],
      history: {
        opened: {
          date: '1875-12-31',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [fairdownWaimangaroa],
      history: {
        opened: {
          date: '1876-08-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waimangaroaNgakawau],
      history: {
        opened: {
          date: '1877-09-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ngakawauMokihinui],
      history: {
        opened: {
          date: '1893-08-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mokihinuiSeddonville],
      history: {
        opened: {
          date: '1895-02-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [seddonVilleMines],
      history: {
        opened: {
          date: '1895-02-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1975-02-10',
          status: 'closed'
        }]
      }
    }
  ]
}
