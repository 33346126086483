import { Line } from "../../trackTypes";

const tailemBendWanbi = [
  [-35.2568, 139.4581],
  [-35.2575, 139.4594],
  [-35.2598, 139.4675],
  [-35.2632, 139.4879],
  [-35.2630, 139.4895],
  [-35.2626, 139.4912],
  [-35.2573, 139.5052],
  [-35.2567, 139.5083],
  [-35.2568, 139.5125],
  [-35.2564, 139.5145],
  [-35.2437, 139.5336],
  [-35.2426, 139.5346],
  [-35.2258, 139.5452],
  [-35.2248, 139.5464],
  [-35.2243, 139.5475],
  [-35.2226, 139.5540],
  [-35.2169, 139.5685],
  [-35.2152, 139.5706],
  [-35.2136, 139.5714],
  [-35.2044, 139.5731],
  [-35.2022, 139.5746],
  [-35.2008, 139.5766],
  [-35.1898, 139.6009],
  [-35.1882, 139.6028],
  [-35.1869, 139.6032],
  [-35.1848, 139.6034],
  [-35.1833, 139.6042],
  [-35.1822, 139.6059],
  [-35.1623, 139.6603],
  [-35.1610, 139.6625],
  [-35.1397, 139.6859],
  [-35.1371, 139.6872],
  [-35.1323, 139.6879],
  [-35.1308, 139.6885],
  [-35.1294, 139.6898],
  [-35.1260, 139.6955],
  [-35.1255, 139.6978],
  [-35.1256, 139.6992],
  [-35.1285, 139.7138],
  [-35.1308, 139.7230],
  [-35.1328, 139.7648],
  [-35.1325, 139.7672],
  [-35.1262, 139.7919],
  [-35.1243, 139.7950],
  [-35.1227, 139.7963],
  [-35.1099, 139.8020],
  [-35.1086, 139.8036],
  [-35.1081, 139.8053],
  [-35.1068, 139.8263],
  [-35.1072, 139.8340],
  [-35.0996, 139.8825],
  [-35.0934, 139.9014],
  [-35.0926, 139.9028],
  [-35.0731, 139.9238],
  [-35.0709, 139.9279],
  [-35.0629, 139.9571],
  [-35.0577, 139.9674],
  [-35.0508, 139.9894],
  [-35.0156, 140.0602],
  [-35.0137, 140.0622],
  [-35.0016, 140.0683],
  [-34.9994, 140.0702],
  [-34.9859, 140.0852],
  [-34.9844, 140.0862],
  [-34.9716, 140.0926],
  [-34.9703, 140.0938],
  [-34.9638, 140.1055],
  [-34.9566, 140.1140],
  [-34.9555, 140.1159],
  [-34.9532, 140.1210],
  [-34.9512, 140.1235],
  [-34.9410, 140.1312],
  [-34.9394, 140.1317],
  [-34.9285, 140.1321],
  [-34.9266, 140.1329],
  [-34.9259, 140.1339],
  [-34.9246, 140.1358],
  [-34.9232, 140.1367],
  [-34.9131, 140.1417],
  [-34.8804, 140.1660],
  [-34.8557, 140.1936],
  [-34.8538, 140.1948],
  [-34.8317, 140.2017],
  [-34.8017, 140.2246],
  [-34.7993, 140.2256],
  [-34.7917, 140.2268],
  [-34.7895, 140.2282],
  [-34.7833, 140.2379],
  [-34.7796, 140.2465],
  [-34.7765, 140.2598],
  [-34.7688, 140.2790],
  [-34.7681, 140.2820],
  [-34.7653, 140.3080]
];

const wanbiAlawoona = [
  [-34.7653, 140.3080],
  [-34.7620, 140.3403],
  [-34.7601, 140.3473],
  [-34.7599, 140.3499],
  [-34.7599, 140.3575],
  [-34.7510, 140.3954],
  [-34.7501, 140.4060],
  [-34.7462, 140.4170],
  [-34.7427, 140.4228],
  [-34.7420, 140.4256],
  [-34.7427, 140.4498],
  [-34.7388, 140.4707],
  [-34.7385, 140.4735],
  [-34.7388, 140.4854],
  [-34.7384, 140.4879],
  [-34.7354, 140.4960],
  [-34.7350, 140.5133]
];

const alawoonaMeribah = [
  [-34.7350, 140.5133],
  [-34.7351, 140.5207],
  [-34.7345, 140.5237],
  [-34.7331, 140.5274],
  [-34.7327, 140.5293],
  [-34.7328, 140.5344],
  [-34.7333, 140.5402],
  [-34.7320, 140.5577],
  [-34.7262, 140.5852],
  [-34.7253, 140.5876],
  [-34.7236, 140.5907],
  [-34.7231, 140.5929],
  [-34.7198, 140.6343],
  [-34.7196, 140.6765],
  [-34.7232, 140.6968],
  [-34.7233, 140.7052],
  [-34.7213, 140.7114],
  [-34.7210, 140.7148],
  [-34.7217, 140.7230],
  [-34.7212, 140.7268],
  [-34.7171, 140.7432],
  [-34.7171, 140.7462],
  [-34.7184, 140.7533],
  [-34.7183, 140.7553],
  [-34.7154, 140.7694],
  [-34.7152, 140.7719],
  [-34.7136, 140.8199],
  [-34.7083, 140.8480],
  [-34.7075, 140.8501],
  [-34.7053, 140.8538]
];

const meribahParinga = [
  [-34.7053, 140.8538],
  [-34.7023, 140.8581],
  [-34.7003, 140.8590],
  [-34.6859, 140.8600],
  [-34.6839, 140.8607],
  [-34.6643, 140.8715],
  [-34.6573, 140.8778],
  [-34.6542, 140.8821],
  [-34.6522, 140.8836],
  [-34.6398, 140.8877],
  [-34.6083, 140.8896],
  [-34.6064, 140.8894],
  [-34.5906, 140.8853],
  [-34.5691, 140.8817],
  [-34.5671, 140.8824],
  [-34.5610, 140.8873],
  [-34.5589, 140.8881],
  [-34.5506, 140.8861],
  [-34.5400, 140.8807],
  [-34.5370, 140.8802],
  [-34.5216, 140.8837],
  [-34.5196, 140.8835],
  [-34.5000, 140.8753],
  [-34.4940, 140.8714],
  [-34.4928, 140.8710],
  [-34.4840, 140.8709],
  [-34.4805, 140.8719],
  [-34.4779, 140.8716],
  [-34.4656, 140.8646],
  [-34.4500, 140.8599],
  [-34.4481, 140.8599],
  [-34.4365, 140.8628],
  [-34.4272, 140.8625],
  [-34.4196, 140.8590],
  [-34.4157, 140.8558],
  [-34.4033, 140.8416],
  [-34.3996, 140.8386],
  [-34.3938, 140.8365],
  [-34.3885, 140.8366],
  [-34.3467, 140.8433],
  [-34.3345, 140.8454],
  [-34.3322, 140.8462],
  [-34.3122, 140.8582],
  [-34.3101, 140.8587],
  [-34.3031, 140.8587],
  [-34.3011, 140.8596],
  [-34.2980, 140.8618],
  [-34.2963, 140.8620],
  [-34.2917, 140.8608],
  [-34.2900, 140.8608],
  [-34.2767, 140.8623],
  [-34.2748, 140.8619],
  [-34.2292, 140.8475],
  [-34.2011, 140.8386],
  [-34.1997, 140.8377],
  [-34.1964, 140.8343],
  [-34.1952, 140.8322],
  [-34.1882, 140.8088],
  [-34.1870, 140.8044],
  [-34.1860, 140.7995],
  [-34.1846, 140.7969],
  [-34.1833, 140.7957],
  [-34.1824, 140.7945],
  [-34.1797, 140.7884],
  [-34.1792, 140.7866],
  [-34.1791, 140.7846]
];

const peringaRenmark = [
  [-34.1791, 140.7846],
  [-34.1794, 140.7825],
  [-34.1798, 140.7809],
  [-34.1808, 140.7789],
  [-34.1810, 140.7782],
  [-34.1810, 140.7775],
  [-34.1803, 140.7737],
  [-34.1793, 140.7683],
  [-34.1792, 140.7674],
  [-34.1789, 140.7599],
  [-34.1791, 140.7588],
  [-34.1806, 140.7541],
  [-34.1807, 140.7526],
  [-34.1805, 140.7509],
  [-34.1801, 140.7499],
  [-34.1786, 140.7482]
];

const renmarkBarmera = [
  [-34.1786, 140.7482],
  [-34.1772, 140.7464],
  [-34.1769, 140.7455],
  [-34.1769, 140.7442],
  [-34.1772, 140.7430],
  [-34.2078, 140.7018],
  [-34.2080, 140.7007],
  [-34.2083, 140.6924],
  [-34.2080, 140.6907],
  [-34.2065, 140.6844],
  [-34.2064, 140.6821],
  [-34.2067, 140.6809],
  [-34.2073, 140.6793],
  [-34.2321, 140.6467],
  [-34.2390, 140.6376],
  [-34.2397, 140.6357],
  [-34.2415, 140.6275],
  [-34.2422, 140.6259],
  [-34.2508, 140.6175],
  [-34.2527, 140.6164],
  [-34.2588, 140.6136],
  [-34.2699, 140.6035],
  [-34.2707, 140.6015],
  [-34.2708, 140.5921],
  [-34.2711, 140.5796],
  [-34.2721, 140.5648],
  [-34.2721, 140.5614],
  [-34.2723, 140.5601],
  [-34.2786, 140.5488],
  [-34.2790, 140.5472],
  [-34.2784, 140.5441],
  [-34.2783, 140.5421],
  [-34.2780, 140.5412],
  [-34.2728, 140.5340],
  [-34.2724, 140.5325],
  [-34.2728, 140.5188],
  [-34.2739, 140.5139],
  [-34.2741, 140.5110],
  [-34.2737, 140.5079],
  [-34.2719, 140.5034],
  [-34.2714, 140.5018],
  [-34.2690, 140.4687],
  [-34.2673, 140.4641],
  [-34.2660, 140.4628],
  [-34.2647, 140.4626],
  [-34.2636, 140.4629],
  [-34.2586, 140.4664],
  [-34.2564, 140.4684],
  [-34.2536, 140.4726]
];

export const barmera: Line = {
  name: 'Barmera',
  state: 'SA',
  segments: [
    {
      segments: [tailemBendWanbi],
      history: {
        opened: {
          date: '1913-01-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-06-27',
          gauge: 'standard'
        }, {
          date: '2015-01-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wanbiAlawoona],
      history: {
        opened: {
          date: '1913-05-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-06-27',
          gauge: 'standard'
        }, {
          date: '2015-06-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [alawoonaMeribah],
      history: {
        opened: {
          date: '1913-05-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [meribahParinga],
      history: {
        opened: {
          date: '1913-10-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [peringaRenmark],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [renmarkBarmera],
      history: {
        opened: {
          date: '1928-08-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
