import { Line } from "../../trackTypes";

const line = [
  [-37.6778, 144.0158],
  [-37.6782, 144.0172],
  [-37.6783, 144.0188],
  [-37.6777, 144.0206],
  [-37.6699, 144.0297],
  [-37.6590, 144.0383]
];

export const lalLalRacecourse: Line = {
  name: 'Lal Lal Racecourse',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
