import { Line } from "../../../trackTypes";

const oakeyBeefExportSiding = [
  [-27.4263, 151.7097],
  [-27.4263, 151.7086],
  [-27.4265, 151.7079],
  [-27.4325, 151.6992]
];

const beefExportSidingEvanslea = [
  [-27.4325, 151.6992],
  [-27.4395, 151.6890],
  [-27.4406, 151.6878],
  [-27.4569, 151.6756],
  [-27.4584, 151.6749],
  [-27.4598, 151.6748],
  [-27.4639, 151.6762],
  [-27.4650, 151.6763],
  [-27.4673, 151.6756],
  [-27.4680, 151.6752],
  [-27.4692, 151.6738],
  [-27.4701, 151.6732],
  [-27.4852, 151.6707],
  [-27.4860, 151.6702],
  [-27.4863, 151.6694],
  [-27.4863, 151.6657],
  [-27.4864, 151.6643],
  [-27.4905, 151.6527],
  [-27.4920, 151.6507],
  [-27.5042, 151.6449],
  [-27.5173, 151.6429],
  [-27.5238, 151.6406],
  [-27.5348, 151.6346],
  [-27.5369, 151.6325],
  [-27.5423, 151.6201],
  [-27.5451, 151.6156],
  [-27.5464, 151.6145],
  [-27.5476, 151.6142],
  [-27.5506, 151.6143],
  [-27.5516, 151.6138],
  [-27.5711, 151.5906],
  [-27.5718, 151.5892],
  [-27.5728, 151.5822],
  [-27.5720, 151.5658],
  [-27.5721, 151.5643],
  [-27.5728, 151.5631],
  [-27.5778, 151.5566],
  [-27.5786, 151.5547],
  [-27.5794, 151.5460],
  [-27.5780, 151.5344],
  [-27.5766, 151.5282],
  [-27.5759, 151.5270],
  [-27.5677, 151.5095]
];

const evansleaCecilPlains = [
  [-27.5677, 151.5095],
  [-27.5671, 151.5072],
  [-27.5669, 151.5045],
  [-27.5703, 151.4812],
  [-27.5702, 151.4794],
  [-27.5531, 151.3433],
  [-27.5381, 151.2326],
  [-27.5360, 151.2099],
  [-27.5322, 151.1874]
];

export const cecilPlains: Line = {
  name: 'Cecil Plains',
  state: 'QLD',
  segments: [
    {
      segments: [oakeyBeefExportSiding],
      history: {
        opened: {
          date: '1915-09-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }, {
          date: '2016-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [beefExportSidingEvanslea],
      history: {
        opened: {
          date: '1915-09-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [evansleaCecilPlains],
      history: {
        opened: {
          date: '1919-04-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
