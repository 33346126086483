import { Line } from "../../trackTypes";

const northMelbourneNewmarket = [
  [-37.8012, 144.9337],
  [-37.7999, 144.9327],
  [-37.7988, 144.9321],
  [-37.7937, 144.9305],
  [-37.7865, 144.9288]
];

const newmarketEssendon = [
  [-37.7865, 144.9288],
  [-37.7840, 144.9281],
  [-37.7833, 144.9279],
  [-37.7824, 144.9275],
  [-37.7811, 144.9268],
  [-37.7800, 144.9259],
  [-37.7770, 144.9230],
  [-37.7763, 144.9225],
  [-37.7759, 144.9222],
  [-37.7750, 144.9216],
  [-37.7742, 144.9212],
  [-37.7733, 144.9209],
  [-37.7726, 144.9207],
  [-37.7668, 144.9195],
  [-37.7662, 144.9193],
  [-37.7651, 144.9189],
  [-37.7643, 144.9185],
  [-37.7627, 144.9175],
  [-37.7619, 144.9171],
  [-37.7610, 144.9167],
  [-37.7599, 144.9165],
  [-37.7584, 144.9162],
  [-37.7560, 144.9160]
];

const essensonJacana = [
  [-37.7560, 144.9160],
  [-37.7547, 144.9162],
  [-37.7540, 144.9164],
  [-37.7512, 144.9174],
  [-37.7505, 144.9177],
  [-37.7496, 144.9183],
  [-37.7483, 144.9195],
  [-37.7476, 144.9203],
  [-37.7473, 144.9208],
  [-37.7470, 144.9211],
  [-37.7465, 144.9221],
  [-37.7451, 144.9251],
  [-37.7443, 144.9264],
  [-37.7436, 144.9272],
  [-37.7432, 144.9276],
  [-37.7423, 144.9283],
  [-37.7413, 144.9289],
  [-37.7404, 144.9292],
  [-37.7396, 144.9294],
  [-37.7387, 144.9294],
  [-37.7379, 144.9293],
  [-37.7352, 144.9289],
  [-37.7303, 144.9280],
  [-37.7300, 144.9280],
  [-37.7290, 144.9276],
  [-37.7281, 144.9273],
  [-37.7270, 144.9267],
  [-37.7260, 144.9261],
  [-37.7219, 144.9231],
  [-37.7202, 144.9222],
  [-37.7188, 144.9217],
  [-37.7007, 144.9160],
  [-37.6998, 144.9158],
  [-37.6993, 144.9157],
  [-37.6982, 144.9156],
  [-37.6965, 144.9157],
  [-37.6934, 144.9159]
];

const jacanaBroardmeadows = [
  [-37.6934, 144.9159],
  [-37.6892, 144.9164],
  [-37.6885, 144.9165],
  [-37.6875, 144.9167],
  [-37.6863, 144.9172],
  [-37.6852, 144.9179],
  [-37.6843, 144.9185],
  [-37.6827, 144.9198]
];

const broardmeadowsCraigieburn = [
  [-37.6827, 144.9198],
  [-37.6809, 144.9212],
  [-37.6793, 144.9221],
  [-37.6779, 144.9226],
  [-37.6688, 144.9245],
  [-37.6595, 144.9264],
  [-37.6584, 144.9268],
  [-37.6571, 144.9274],
  [-37.6490, 144.9326],
  [-37.6479, 144.9332],
  [-37.6469, 144.9336],
  [-37.6455, 144.9340],
  [-37.6416, 144.9348],
  [-37.6412, 144.9349],
  [-37.6064, 144.9418],
  [-37.6046, 144.9422],
  [-37.6039, 144.9424],
  [-37.6023, 144.9431]
];

export const craigieburn: Line = {
  name: 'Craigieburn',
  state: 'VIC',
  segments: [
    {
      segments: [broardmeadowsCraigieburn],
      history: {
        opened: {
          date: '1872-04-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-03-22',
          tracks: 2
        }, {
          date: '2007-07-22',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [newmarketEssendon],
      history: {
        opened: {
          date: '1860-10-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1864-07-01',
          status: 'closed'
        }, {
          date: '1871-01-09',
          status: 'open'
        }, {
          date: '1882-04-24',
          tracks: 2
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [essensonJacana],
      history: {
        opened: {
          date: '1872-04-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1885-11-16',
          tracks: 2
        },{
          date: '1921-09-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [jacanaBroardmeadows],
      history: {
        opened: {
          date: '1872-04-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1885-11-16',
          tracks: 2
        },{
          date: '1921-09-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [northMelbourneNewmarket],
      history: {
        opened: {
          date: '1860-10-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1864-08-01',
          status: 'closed'
        }, {
          date: '1867-10-31',
          status: 'open'
        }, {
          date: '1870-11-05',
          tracks: 2
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
