import { Line } from "../../../trackTypes";

const line = [
  [-43.1310, 171.6915],
  [-43.1297, 171.6888]
];

export const tyndallTramway: Line = {
  name: 'Tyndall Tramway',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2009-07-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'funicular'
        }
      }
    }
  ]
}
