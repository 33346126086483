import { Line } from "../../../trackTypes";

const line = [
  [-28.5203, 151.9489],
  [-28.5209, 151.9486],
  [-28.5213, 151.9483],
  [-28.5241, 151.9445],
  [-28.5246, 151.9432],
  [-28.5263, 151.9357],
  [-28.5276, 151.9296],
  [-28.5277, 151.9284],
  [-28.5243, 151.9035],
  [-28.5243, 151.9014],
  [-28.5248, 151.8994],
  [-28.5259, 151.8970],
  [-28.5292, 151.8826],
  [-28.5291, 151.8812],
  [-28.5281, 151.8739],
  [-28.5277, 151.8729],
  [-28.5262, 151.8716],
  [-28.5234, 151.8708],
  [-28.5229, 151.8697],
  [-28.5229, 151.8684],
  [-28.5237, 151.8673],
  [-28.5254, 151.8667],
  [-28.5262, 151.8661],
  [-28.5278, 151.8639],
  [-28.5284, 151.8634],
  [-28.5293, 151.8631],
  [-28.5308, 151.8620],
  [-28.5340, 151.8595],
  [-28.5344, 151.8584],
  [-28.5349, 151.8576],
  [-28.5366, 151.8567],
  [-28.5378, 151.8551],
  [-28.5387, 151.8511],
  [-28.5394, 151.8497],
  [-28.5402, 151.8489],
  [-28.5450, 151.8465],
  [-28.5484, 151.8429],
  [-28.5528, 151.8369],
  [-28.5543, 151.8356],
  [-28.5630, 151.8322],
  [-28.5649, 151.8308],
  [-28.5664, 151.8294],
  [-28.5674, 151.8271],
  [-28.5677, 151.8239],
  [-28.5664, 151.8197],
  [-28.5646, 151.8160],
  [-28.5647, 151.8147],
  [-28.5656, 151.8139],
  [-28.5669, 151.8135],
  [-28.5677, 151.8129],
  [-28.5688, 151.8119],
  [-28.5709, 151.8093],
  [-28.5721, 151.8084],
  [-28.5726, 151.8072],
  [-28.5725, 151.8054],
  [-28.5731, 151.8040],
  [-28.5746, 151.8036],
  [-28.5759, 151.8042],
  [-28.5771, 151.8042],
  [-28.5796, 151.8030],
  [-28.5834, 151.8029],
  [-28.5844, 151.8030],
  [-28.5849, 151.8032],
  [-28.5853, 151.8036],
  [-28.5864, 151.8063]
];

export const amiens: Line = {
  name: 'Amiens',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1920-06-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-02-28',
          status: 'closed'
        }]
      }
    }
  ]
}
