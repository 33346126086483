import { Line } from "../../../trackTypes";

const maroonaDunkeld = [
  [-37.4424, 142.8595],
  [-37.4478, 142.8565],
  [-37.4492, 142.8555],
  [-37.4504, 142.8544],
  [-37.4784, 142.8259],
  [-37.4796, 142.8244],
  [-37.4804, 142.8226],
  [-37.4879, 142.7986],
  [-37.4892, 142.7951],
  [-37.4965, 142.7791],
  [-37.4979, 142.7770],
  [-37.4993, 142.7755],
  [-37.5010, 142.7742],
  [-37.5270, 142.7581],
  [-37.5328, 142.7534],
  [-37.5376, 142.7479],
  [-37.5389, 142.7467],
  [-37.5407, 142.7453],
  [-37.5701, 142.7274],
  [-37.5714, 142.7261],
  [-37.5725, 142.7246],
  [-37.5734, 142.7221],
  [-37.5890, 142.6684],
  [-37.6135, 142.6021],
  [-37.6141, 142.6011],
  [-37.6379, 142.5562],
  [-37.6391, 142.5533],
  [-37.6396, 142.5508],
  [-37.6397, 142.5492],
  [-37.6402, 142.4952],
  [-37.6405, 142.4921],
  [-37.6413, 142.4890],
  [-37.6507, 142.4637],
  [-37.6511, 142.4618],
  [-37.6515, 142.4596],
  [-37.6532, 142.4199],
  [-37.6557, 142.3828],
  [-37.6556, 142.3795],
  [-37.6541, 142.3676],
  [-37.6538, 142.3636],
  [-37.6538, 142.3589],
  [-37.6541, 142.3543],
  [-37.6540, 142.3499],
  [-37.6533, 142.3427]
];

const dunkeldHamilton = [
  [-37.6533, 142.3427],
  [-37.6526, 142.3357],
  [-37.6526, 142.3326],
  [-37.6527, 142.3314],
  [-37.6529, 142.3303],
  [-37.6575, 142.3059],
  [-37.6583, 142.3025],
  [-37.6591, 142.3001],
  [-37.6628, 142.2904],
  [-37.6636, 142.2876],
  [-37.6641, 142.2852],
  [-37.6644, 142.2824],
  [-37.6653, 142.2509],
  [-37.6652, 142.2489],
  [-37.6649, 142.2470],
  [-37.6640, 142.2423],
  [-37.6638, 142.2399],
  [-37.6639, 142.2360],
  [-37.6647, 142.2316],
  [-37.6658, 142.2280],
  [-37.6669, 142.2255],
  [-37.6673, 142.2248],
  [-37.6841, 142.1900],
  [-37.6892, 142.1794],
  [-37.6897, 142.1779],
  [-37.6902, 142.1760],
  [-37.7071, 142.1003],
  [-37.7078, 142.0983],
  [-37.7087, 142.0958],
  [-37.7280, 142.0461],
  [-37.7286, 142.0446],
  [-37.7297, 142.0424],
  [-37.7316, 142.0399],
  [-37.7399, 142.0318],
  [-37.7432, 142.0299],
  [-37.7464, 142.0286]
];

const hamiltonPortland = [
  [-37.7464, 142.0286],
  [-37.7520, 142.0262],
  [-37.7546, 142.0243],
  [-37.7563, 142.0223],
  [-37.7572, 142.0207],
  [-37.8029, 141.9273],
  [-37.8045, 141.9231],
  [-37.8055, 141.9195],
  [-37.8290, 141.8178],
  [-37.8300, 141.8150],
  [-37.8314, 141.8127],
  [-37.8326, 141.8114],
  [-37.8442, 141.8011],
  [-37.8466, 141.7994],
  [-37.8553, 141.7955],
  [-37.8604, 141.7931],
  [-37.8632, 141.7911],
  [-37.8654, 141.7888],
  [-37.8967, 141.7390],
  [-37.8992, 141.7363],
  [-37.9022, 141.7353],
  [-37.9054, 141.7354],
  [-37.9080, 141.7359],
  [-37.9397, 141.7442],
  [-37.9425, 141.7444],
  [-37.9443, 141.7440],
  [-38.0324, 141.7079],
  [-38.0339, 141.7077],
  [-38.0380, 141.7077],
  [-38.0410, 141.7072],
  [-38.0439, 141.7057],
  [-38.0505, 141.6990],
  [-38.0533, 141.6970],
  [-38.0557, 141.6960],
  [-38.0587, 141.6952],
  [-38.0624, 141.6935],
  [-38.0660, 141.6912],
  [-38.0670, 141.6899],
  [-38.0684, 141.6880],
  [-38.0699, 141.6866],
  [-38.0715, 141.6857],
  [-38.0751, 141.6851],
  [-38.0777, 141.6839],
  [-38.0799, 141.6821],
  [-38.0815, 141.6800],
  [-38.0827, 141.6781],
  [-38.0842, 141.6762],
  [-38.0847, 141.6757],
  [-38.0977, 141.6627],
  [-38.0988, 141.6613],
  [-38.1003, 141.6585],
  [-38.1014, 141.6568],
  [-38.1038, 141.6546],
  [-38.1216, 141.6435],
  [-38.1232, 141.6423],
  [-38.1345, 141.6307],
  [-38.1359, 141.6296],
  [-38.1374, 141.6288],
  [-38.1382, 141.6285],
  [-38.1438, 141.6270],
  [-38.1472, 141.6269],
  [-38.1497, 141.6270],
  [-38.1554, 141.6277],
  [-38.1658, 141.6288],
  [-38.1679, 141.6287],
  [-38.2398, 141.6180],
  [-38.2437, 141.6166],
  [-38.2467, 141.6148],
  [-38.2492, 141.6140],
  [-38.2505, 141.6132],
  [-38.2518, 141.6118],
  [-38.2528, 141.6104],
  [-38.2534, 141.6092],
  [-38.2541, 141.6080],
  [-38.2550, 141.6071],
  [-38.2564, 141.6063],
  [-38.2577, 141.6061],
  [-38.2591, 141.6061],
  [-38.2598, 141.6060],
  [-38.2644, 141.6048],
  [-38.2660, 141.6041],
  [-38.2674, 141.6030],
  [-38.2687, 141.6014],
  [-38.2695, 141.5996],
  [-38.2701, 141.5974],
  [-38.2705, 141.5962],
  [-38.2709, 141.5953],
  [-38.2759, 141.5878],
  [-38.2770, 141.5866],
  [-38.2783, 141.5858],
  [-38.2799, 141.5855],
  [-38.2814, 141.5856],
  [-38.2842, 141.5854],
  [-38.2899, 141.5825],
  [-38.2922, 141.5818],
  [-38.2964, 141.5806],
  [-38.2979, 141.5804],
  [-38.2992, 141.5805],
  [-38.3104, 141.5836],
  [-38.3111, 141.5835],
  [-38.3193, 141.5822],
  [-38.3205, 141.5823],
  [-38.3226, 141.5829],
  [-38.3244, 141.5843],
  [-38.3302, 141.5906],
  [-38.3386, 141.5998],
  [-38.3396, 141.6011]
];

const portlandPier = [
  [-38.3302, 141.5906],
  [-38.3307, 141.5909],
  [-38.3313, 141.5912],
  [-38.3409, 141.5910],
  [-38.3502, 141.5911],
  [-38.3509, 141.5914],
  [-38.3515, 141.5919],
  [-38.3554, 141.5973],
  [-38.3561, 141.5986],
  [-38.3563, 141.5999],
  [-38.3569, 141.6059],
  [-38.3569, 141.6076],
  [-38.3558, 141.6124],
  [-38.3552, 141.6135],
  [-38.3548, 141.6151],
  [-38.3545, 141.6167],
  [-38.3543, 141.6187],
  [-38.3541, 141.6192],
  [-38.3534, 141.6203],
  [-38.3531, 141.6205],
  [-38.3503, 141.6214]
];

export const portland: Line = {
  name: 'Portland',
  state: 'VIC',
  segments: [
    {
      segments: [maroonaDunkeld],
      history: {
        opened: {
          date: '1877-04-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-11',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [dunkeldHamilton],
      history: {
        opened: {
          date: '1877-10-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-11',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [hamiltonPortland],
      history: {
        opened: {
          date: '1877-12-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-11',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [portlandPier],
      history: {
        opened: {
          date: '1877-12-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-11',
          gauge: 'standard'
        }]
      }
    }
  ]
};
