import { Line } from "../../trackTypes";

const line = [
  [-41.4304, 147.1390],
  [-41.4301, 147.1391],
  [-41.4291, 147.1394],
  [-41.4278, 147.1397],
  [-41.4254, 147.1403],
  [-41.4232, 147.1409],
  [-41.4226, 147.1409]
];

const lineTwo = [
  [-41.4254, 147.1403],
  [-41.4259, 147.1404],
  [-41.4261, 147.1406],
  [-41.4265, 147.1411],
  [-41.4275, 147.1421],
  [-41.4280, 147.1423],
  [-41.4284, 147.1423],
  [-41.4287, 147.1419],
  [-41.4290, 147.1413],
  [-41.4291, 147.1408],
  [-41.4290, 147.1397],
  [-41.4288, 147.1384]
];

const junction = [
  [-41.4291, 147.1394],
  [-41.4290, 147.1395],
  [-41.4290, 147.1397]
];

const depot = [
  [-41.4278, 147.1397],
  [-41.4280, 147.1395],
  [-41.4281, 147.1390]
];

export const invereskTram: Line = {
  name: 'Inveresk Tram',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        }
      }
    },
    {
      segments: [lineTwo],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        }
      }
    },
    {
      segments: [junction],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        }
      }
    },
    {
      segments: [depot],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        }
      }
    }
  ]
}
