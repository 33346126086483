import { Line } from "../../../trackTypes";

const line = [
  [-32.4656, 137.7659],
  [-32.4588, 137.7618],
  [-32.4571, 137.7601],
  [-32.4560, 137.7578],
  [-32.4549, 137.7539],
  [-32.4547, 137.7509],
  [-32.4553, 137.7479],
  [-32.4571, 137.7446],
  [-32.5206, 137.6609],
  [-32.5372, 137.6429],
  [-32.5395, 137.6411],
  [-32.5423, 137.6399],
  [-32.5472, 137.6390],
  [-32.5502, 137.6379],
  [-32.5612, 137.6299],
  [-32.5646, 137.6283],
  [-32.5768, 137.6261],
  [-32.5792, 137.6254],
  [-32.5825, 137.6228],
  [-32.6407, 137.5430],
  [-32.6444, 137.5397],
  [-32.6929, 137.5169],
  [-32.6953, 137.5161],
  [-32.7211, 137.5111],
  [-32.7241, 137.5111],
  [-32.7274, 137.5124],
  [-32.7348, 137.5185],
  [-32.7371, 137.5200],
  [-32.7869, 137.5370],
  [-32.8734, 137.5573],
  [-32.9823, 137.5828],
  [-32.9846, 137.5828],
  [-32.9868, 137.5824],
  [-32.9909, 137.5810],
  [-33.0084, 137.5764]
];

const whyallaPort1 = [
  [-33.0331, 137.5887],
  [-33.0353, 137.5917],
  [-33.0358, 137.5922],
  [-33.0364, 137.5924],
  [-33.0375, 137.5924],
  [-33.0380, 137.5921],
  [-33.0383, 137.5918],
  [-33.0389, 137.5904]
];

const whyallaPort2 = [
  [-33.0247, 137.5738],
  [-33.0251, 137.5753],
  [-33.0250, 137.5765],
  [-33.0246, 137.5778],
  [-33.0237, 137.5790],
  [-33.0227, 137.5795],
  [-33.0215, 137.5796],
  [-33.0162, 137.5788],
  [-33.0138, 137.5784],
  [-33.0128, 137.5786],
  [-33.0120, 137.5790],
  [-33.0113, 137.5798],
  [-33.0107, 137.5807],
  [-33.0105, 137.5820],
  [-33.0105, 137.5847],
  [-33.0112, 137.5889],
  [-33.0118, 137.5905],
  [-33.0140, 137.5939]
];

const whyallaPort3 = [
  [-33.0284, 137.5804],
  [-33.0287, 137.5814],
  [-33.0286, 137.5822],
  [-33.0283, 137.5829],
  [-33.0272, 137.5838],
  [-33.0262, 137.5840],
  [-33.0252, 137.5838],
  [-33.0162, 137.5788]
];

const whyallaIronKnob = [
  [-33.0348, 137.5917],
  [-33.0340, 137.5906],
  [-33.0331, 137.5887],
  [-33.0317, 137.5859],
  [-33.0284, 137.5804],
  [-33.0254, 137.5754],
  [-33.0247, 137.5738],
  [-33.0107, 137.5388],
  [-32.9572, 137.4225],
  [-32.9395, 137.4004],
  [-32.8339, 137.2678],
  [-32.7559, 137.1579],
  [-32.7551, 137.1573],
  [-32.7534, 137.1557],
  [-32.7523, 137.1541],
  [-32.7517, 137.1522],
  [-32.7517, 137.1505],
  [-32.7524, 137.1476],
  [-32.7533, 137.1454],
  [-32.7537, 137.1430],
  [-32.7535, 137.1409],
  [-32.7509, 137.1304],
  [-32.7497, 137.1287],
  [-32.7479, 137.1276],
  [-32.7440, 137.1274]
];

const middletonJunctionIronBaron = [
  [-32.9395, 137.4004],
  [-32.9382, 137.3971],
  [-32.9379, 137.3943],
  [-32.9671, 137.2333],
  [-32.9826, 137.1949],
  [-33.0025, 137.1731],
  [-33.0038, 137.1708],
  [-33.0041, 137.1689],
  [-33.0038, 137.1674],
  [-33.0028, 137.1657],
  [-33.0006, 137.1640],
  [-32.9985, 137.1634],
  [-32.9934, 137.1631],
  [-32.9920, 137.1633],
  [-32.9904, 137.1640]
];

const ironBaronJunction = [
  [-33.0025, 137.1731],
  [-33.0039, 137.1720],
  [-33.0056, 137.1712],
  [-33.0071, 137.1710],
  [-33.0088, 137.1712]
];

const ironBaronIronDuke = [
  [-33.0028, 137.1657],
  [-33.0051, 137.1681],
  [-33.0071, 137.1700],
  [-33.0088, 137.1712],
  [-33.0115, 137.1722],
  [-33.0132, 137.1734],
  [-33.0177, 137.1787],
  [-33.0211, 137.1814],
  [-33.0258, 137.1832],
  [-33.0350, 137.1845],
  [-33.0411, 137.1843],
  [-33.0497, 137.1820],
  [-33.0612, 137.1756],
  [-33.0645, 137.1724],
  [-33.0666, 137.1687],
  [-33.0694, 137.1652],
  [-33.0738, 137.1622],
  [-33.1050, 137.1512],
  [-33.1106, 137.1481],
  [-33.1275, 137.1352],
  [-33.1361, 137.1255],
  [-33.1410, 137.1170],
  [-33.1443, 137.1132],
  [-33.1482, 137.1105],
  [-33.1853, 137.0955],
  [-33.1914, 137.0943],
  [-33.2291, 137.0963],
  [-33.2322, 137.0969],
  [-33.2411, 137.1010],
  [-33.2457, 137.1021],
  [-33.2628, 137.1020],
  [-33.2635, 137.1022],
  [-33.2754, 137.1070]
];

export const whyalla: Line = {
  name: 'Whyalla',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1972-10-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [whyallaIronKnob],
      history: {
        opened: {
          date: '1901-08-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [whyallaPort1, whyallaPort2, whyallaPort3],
      history: {
        opened: {
          date: '1901-08-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [middletonJunctionIronBaron],
      history: {
        opened: {
          date: '1930-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }, {
          date: '1998-01-01',
          status: 'closed'
        }, {
          date: '1958-01-01',
          status: 'open'
        }, {
          date: '2012-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [ironBaronIronDuke, ironBaronJunction],
      history: {
        opened: {
          date: '1990-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-01-01',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'open'
        }]
      }
    }
  ]
};
