import { Line } from "../../../trackTypes";

const line = [
  [-26.7293, 150.6120],
  [-26.7258, 150.6120],
  [-26.7220, 150.6109],
  [-26.7203, 150.6110],
  [-26.7192, 150.6118],
  [-26.7158, 150.6156],
  [-26.7148, 150.6162],
  [-26.7110, 150.6168],
  [-26.7011, 150.6221],
  [-26.6965, 150.6239],
  [-26.6955, 150.6239],
  [-26.6934, 150.6229],
  [-26.6913, 150.6210],
  [-26.6891, 150.6174],
  [-26.6870, 150.6151],
  [-26.6824, 150.6119],
  [-26.6817, 150.6117],
  [-26.6802, 150.6116],
  [-26.6797, 150.6114],
  [-26.6793, 150.6110],
  [-26.6786, 150.6094],
  [-26.6773, 150.6044],
  [-26.6715, 150.5929],
  [-26.6705, 150.5918],
  [-26.6674, 150.5899],
  [-26.6549, 150.5846],
  [-26.6536, 150.5839],
  [-26.6527, 150.5826],
  [-26.6517, 150.5801],
  [-26.6506, 150.5792],
  [-26.6491, 150.5789],
  [-26.6444, 150.5789],
  [-26.6429, 150.5784],
  [-26.6410, 150.5784],
  [-26.6342, 150.5803],
  [-26.6260, 150.5815],
  [-26.6238, 150.5814],
  [-26.6203, 150.5793],
  [-26.5832, 150.5677],
  [-26.5811, 150.5664],
  [-26.5487, 150.5351],
  [-26.5474, 150.5336],
  [-26.5469, 150.5333],
  [-26.5463, 150.5332],
  [-26.5351, 150.5375],
  [-26.5341, 150.5376],
  [-26.5220, 150.5357],
  [-26.5211, 150.5352],
  [-26.5176, 150.5318],
  [-26.5164, 150.5312],
  [-26.5097, 150.5298],
  [-26.4977, 150.5247],
  [-26.4946, 150.5243],
  [-26.4672, 150.5142],
  [-26.4575, 150.5123],
  [-26.4492, 150.5121],
  [-26.4298, 150.5042],
  [-26.4290, 150.5043],
  [-26.4283, 150.5046],
  [-26.4258, 150.5076]
];

export const barakula: Line = {
  name: 'Barakula',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1970-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
