import { Line } from "../../../../trackTypes";

const westEnd = [
  [-27.47324, 153.01968],
  [-27.47688, 153.01300],
  [-27.47700, 153.01287],
  [-27.47714, 153.01278],
  [-27.48083, 153.01208],
  [-27.48092, 153.01200],
  [-27.48095, 153.01190],
  [-27.48051, 153.00893]
];

const dornochTerrace = [
  [-27.48051, 153.00893],
  [-27.48050, 153.00876],
  [-27.48052, 153.00860],
  [-27.48058, 153.00831],
  [-27.48060, 153.00817],
  [-27.48060, 153.00805],
  [-27.48052, 153.00748],
  [-27.48056, 153.00739],
  [-27.48063, 153.00730],
  [-27.48663, 153.00617]
];

const stLuciaFerry = [
  [-27.48663, 153.00617],
  [-27.48678, 153.00609],
  [-27.48682, 153.00601],
  [-27.48683, 153.00591],
  [-27.48660, 153.00431],
  [-27.48662, 153.00421],
  [-27.48666, 153.00413],
  [-27.48674, 153.00407],
  [-27.48685, 153.00403],
  [-27.48942, 153.00353],
  [-27.48957, 153.00346],
  [-27.48973, 153.00330],
  [-27.48978, 153.00318],
  [-27.48976, 153.00267]
];

const daviesParkLoop = [
  [-27.48052, 153.00748],
  [-27.48020, 153.00549],
  [-27.48015, 153.00543],
  [-27.48009, 153.00540],
  [-27.48001, 153.00540],
  [-27.47845, 153.00636],
  [-27.47840, 153.00644],
  [-27.47837, 153.00652],
  [-27.47836, 153.00664],
  [-27.47852, 153.00758],
  [-27.47855, 153.00764],
  [-27.47864, 153.00768],
  [-27.48063, 153.00730]
];

const loopJunction = [
  [-27.48037, 153.00735],
  [-27.48045, 153.00739],
  [-27.48052, 153.00748]
];

export const south2: Line = {
  name: 'South 2',
  state: 'QLD',
  segments: [
    {
      segments: [westEnd],
      history: {
        opened: {
          date: '1885-08-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1898-01-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dornochTerrace],
      history: {
        opened: {
          date: '1898-01-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stLuciaFerry],
      history: {
        opened: {
          date: '1925-09-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [daviesParkLoop, loopJunction],
      history: {
        opened: {
          date: '1927-12-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    }
  ]
}
