import { Line } from "../../trackTypes";

const tarcoolaNtBorder = [
  [-30.7129, 134.5438],
  [-30.7144, 134.5276],
  [-30.7140, 134.5244],
  [-30.7126, 134.5210],
  [-30.7102, 134.5184],
  [-30.7061, 134.5167],
  [-30.7031, 134.5167],
  [-30.3001, 134.5327],
  [-29.7161, 134.5398],
  [-29.5598, 134.5421],
  [-29.4476, 134.5518],
  [-29.3605, 134.5642],
  [-29.3564, 134.5638],
  [-29.3537, 134.5628],
  [-29.3289, 134.5494],
  [-29.2559, 134.4797],
  [-29.2501, 134.4710],
  [-29.2468, 134.4681],
  [-29.2322, 134.4607],
  [-29.1938, 134.4415],
  [-28.9549, 134.3695],
  [-28.9040, 134.3541],
  [-28.8578, 134.3242],
  [-28.8540, 134.3230],
  [-28.7027, 134.3211],
  [-28.6055, 134.2941],
  [-28.6014, 134.2917],
  [-28.5692, 134.2649],
  [-28.5226, 134.2177],
  [-28.5192, 134.2150],
  [-28.4424, 134.1838],
  [-28.0947, 134.1130],
  [-28.0335, 134.0921],
  [-27.9646, 134.0823],
  [-27.9594, 134.0803],
  [-27.9015, 134.0515],
  [-27.8779, 134.0392],
  [-27.8098, 134.0164],
  [-27.7692, 134.0028],
  [-27.7666, 134.0016],
  [-27.7368, 133.9806],
  [-27.7343, 133.9782],
  [-27.7067, 133.9374],
  [-27.7031, 133.9350],
  [-27.7000, 133.9343],
  [-27.6929, 133.9347],
  [-27.6893, 133.9338],
  [-27.6865, 133.9322],
  [-27.6069, 133.8564],
  [-27.5485, 133.8124],
  [-27.4048, 133.6891],
  [-27.1965, 133.5276],
  [-27.0720, 133.4427],
  [-26.9997, 133.3736],
  [-26.9943, 133.3712],
  [-26.9102, 133.3672],
  [-26.8616, 133.3524],
  [-26.8478, 133.3446],
  [-26.8098, 133.3334],
  [-26.8055, 133.3335],
  [-26.7114, 133.3684],
  [-26.7079, 133.3710],
  [-26.6767, 133.4090],
  [-26.6738, 133.4114],
  [-26.6697, 133.4120],
  [-26.6587, 133.4121],
  [-26.6418, 133.4148],
  [-26.6381, 133.4146],
  [-26.5849, 133.4050],
  [-26.5475, 133.4028],
  [-26.5052, 133.4066],
  [-26.4137, 133.4073],
  [-26.1505, 133.3973],
  [-26.0034, 133.4087],
  [-25.9985, 133.4082]
];

const tarcoolaJunction = [
  [-30.7203, 134.5004],
  [-30.7200, 134.5026],
  [-30.7191, 134.5042],
  [-30.7051, 134.5159],
  [-30.7031, 134.5167]
];

export const adelaideDarwin: Line = {
  name: 'Adelaide - Darwin',
  state: 'SA',
  segments: [
    {
      segments: [tarcoolaNtBorder, tarcoolaJunction],
      history: {
        opened: {
          date: '1980-10-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
