import { Line } from "../../trackTypes";

const line = [
  [-40.2541, 175.5974],
  [-40.2544, 175.5982],
  [-40.2543, 175.5990],
  [-40.2539, 175.5996],
  [-40.2533, 175.6000],
  [-40.2528, 175.6004],
  [-40.2274, 175.6448],
  [-40.2269, 175.6460],
  [-40.2263, 175.6469]
];

export const taonui: Line = {
  name: 'Taonui',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1879-11-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1895-08-14',
          status: 'closed'
        }]
      }
    }
  ]
}
