import { Line } from "../../../trackTypes";

// - Cannon hill branch (off cleaveland)
// - - o1913
// - - o1915 to wharf

const line = [
  [-27.4653, 153.0906],
  [-27.4650, 153.0911],
  [-27.4648, 153.0914],
  [-27.4646, 153.0916],
  [-27.4643, 153.0919],
  [-27.4632, 153.0921],
  [-27.4629, 153.0920],
  [-27.4624, 153.0917],
  [-27.4618, 153.0911],
  [-27.4614, 153.0908],
  [-27.4610, 153.0907],
  [-27.4581, 153.0911],
  [-27.4578, 153.0909],
  [-27.4576, 153.0907],
  [-27.4574, 153.0904],
  [-27.4572, 153.0893],
  [-27.4569, 153.0889],
  [-27.4565, 153.0887],
  [-27.4562, 153.0886],
  [-27.4513, 153.0896],
  [-27.4507, 153.0900],
  [-27.4502, 153.0907],
  [-27.4500, 153.0913],
  [-27.4491, 153.0945]
];

const junction = [
  [-27.4656, 153.0925],
  [-27.4652, 153.0921],
  [-27.4649, 153.0919],
  [-27.4643, 153.0919]
];

export const cannonHill: Line = {
  name: 'Cannon Hill',
  state: 'QLD',
  segments: [
    {
      segments: [line, junction],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
