import { Line } from "../../../trackTypes";

const lincolnBirdlingsFlat = [
  [-43.6424, 172.4854],
  [-43.6430, 172.4851],
  [-43.6437, 172.4850],
  [-43.6445, 172.4851],
  [-43.6918, 172.5111],
  [-43.6927, 172.5122],
  [-43.6935, 172.5133],
  [-43.7252, 172.5811],
  [-43.7353, 172.6029],
  [-43.7366, 172.6046],
  [-43.7515, 172.6143],
  [-43.7534, 172.6160],
  [-43.7762, 172.6440],
  [-43.7811, 172.6538],
  [-43.7823, 172.6550],
  [-43.7946, 172.6637],
  [-43.8143, 172.6965]
];

const birdlingsFlatLittleRiver = [
  [-43.8143, 172.6965],
  [-43.8153, 172.6983],
  [-43.8155, 172.6994],
  [-43.8154, 172.7010],
  [-43.8081, 172.7224],
  [-43.8072, 172.7238],
  [-43.8050, 172.7257],
  [-43.8044, 172.7264],
  [-43.8007, 172.7335],
  [-43.7998, 172.7364],
  [-43.7995, 172.7370],
  [-43.7989, 172.7375],
  [-43.7981, 172.7378],
  [-43.7972, 172.7387],
  [-43.7969, 172.7397],
  [-43.7969, 172.7438],
  [-43.7964, 172.7461],
  [-43.7926, 172.7537],
  [-43.7903, 172.7571],
  [-43.7897, 172.7586],
  [-43.7892, 172.7631],
  [-43.7878, 172.7679],
  [-43.7874, 172.7698],
  [-43.7869, 172.7710],
  [-43.7835, 172.7759],
  [-43.7778, 172.7812],
  [-43.7767, 172.7823],
  [-43.7736, 172.7881],
  [-43.7729, 172.7887],
  [-43.7701, 172.7903]
];

export const littleRiver: Line = {
  name: 'Little River',
  state: 'NZ',
  segments: [
    {
      segments: [lincolnBirdlingsFlat],
      history: {
        opened: {
          date: '1882-05-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [birdlingsFlatLittleRiver],
      history: {
        opened: {
          date: '1886-03-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
