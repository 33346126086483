import { Line } from "../../trackTypes";

const line = [
  [-32.9846, 151.5813],
  [-32.9854, 151.5813],
  [-32.9861, 151.5816],
  [-32.9866, 151.5822],
  [-32.9876, 151.5855],
  [-32.9882, 151.5863],
  [-32.9889, 151.5867],
  [-32.9896, 151.5867],
  [-32.9926, 151.5861],
  [-32.9938, 151.5854],
  [-32.9944, 151.5847],
  [-32.9951, 151.5840],
  [-32.9954, 151.5838],
  [-32.9980, 151.5835],
  [-32.9987, 151.5837],
  [-33.0007, 151.5854],
  [-33.0015, 151.5857],
  [-33.0023, 151.5858],
  [-33.0029, 151.5860],
  [-33.0060, 151.5880],
  [-33.0066, 151.5888],
  [-33.0073, 151.5901],
  [-33.0078, 151.5911],
  [-33.0099, 151.5936],
  [-33.0102, 151.5943],
  [-33.0105, 151.5950],
  [-33.0108, 151.5954],
  [-33.0125, 151.5968]
];

export const toronto: Line = {
  name: 'Toronto',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1891-03-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1909-03-15',
          status: 'closed'
        }, {
          date: '1995-03-26',
          status: 'closed'
        }, {
          date: '1910-08-28',
          status: 'open'
        }]
      }
    }
  ]
};
