import { Line } from "../../trackTypes";

const coolgardieWidgiemooltha = [
  [-30.9558, 121.1763],
  [-30.9565, 121.1757],
  [-30.9574, 121.1753],
  [-30.9585, 121.1754],
  [-30.9622, 121.1774],
  [-30.9652, 121.1781],
  [-30.9704, 121.1782],
  [-30.9754, 121.1771],
  [-30.9800, 121.1742],
  [-30.9889, 121.1693],
  [-30.9949, 121.1680],
  [-31.0048, 121.1632],
  [-31.0106, 121.1612],
  [-31.0147, 121.1575],
  [-31.0166, 121.1546],
  [-31.0231, 121.1504],
  [-31.0276, 121.1448],
  [-31.0387, 121.1385],
  [-31.0669, 121.1276],
  [-31.0745, 121.1234],
  [-31.0766, 121.1226],
  [-31.1114, 121.1260],
  [-31.1143, 121.1274],
  [-31.1764, 121.1950],
  [-31.1790, 121.1972],
  [-31.2087, 121.2172],
  [-31.2837, 121.3079],
  [-31.3620, 121.3878],
  [-31.3650, 121.3931],
  [-31.3670, 121.3939],
  [-31.3693, 121.3943],
  [-31.3715, 121.3952],
  [-31.3753, 121.3994],
  [-31.3764, 121.4019],
  [-31.3775, 121.4031],
  [-31.3807, 121.4056],
  [-31.3980, 121.4239],
  [-31.4608, 121.4601],
  [-31.4628, 121.4618],
  [-31.4702, 121.4742],
  [-31.4799, 121.4984],
  [-31.4805, 121.5004],
  [-31.4828, 121.5196],
  [-31.4838, 121.5235],
  [-31.4831, 121.5310],
  [-31.4834, 121.5325],
  [-31.4845, 121.5342],
  [-31.4923, 121.5402],
  [-31.4944, 121.5446],
  [-31.4946, 121.5472],
  [-31.4950, 121.5507],
  [-31.4948, 121.5527],
  [-31.4922, 121.5601],
  [-31.4899, 121.5641],
  [-31.4894, 121.5665],
  [-31.4898, 121.5686],
  [-31.4908, 121.5703],
  [-31.4951, 121.5741],
  [-31.4978, 121.5759],
  [-31.4992, 121.5773],
  [-31.5000, 121.5789],
  [-31.5013, 121.5859],
  [-31.5023, 121.5882],
  [-31.5039, 121.5900]
];

const coolgardieJunction = [
  [-30.9567, 121.1721],
  [-30.9566, 121.1730],
  [-30.9566, 121.1740],
  [-30.9568, 121.1745],
  [-30.9571, 121.1750],
  [-30.9585, 121.1754]
];

const kalgoorlieWidgiemooltha = [
  [-30.7934, 121.3892],
  [-30.7930, 121.3914],
  [-30.7932, 121.3929],
  [-30.7937, 121.3945],
  [-30.7947, 121.3958],
  [-30.7962, 121.3970],
  [-30.8144, 121.4111],
  [-30.8174, 121.4157],
  [-30.8216, 121.4353],
  [-30.8244, 121.4423],
  [-30.8420, 121.4689],
  [-30.8461, 121.4719],
  [-30.9387, 121.5061],
  [-30.9431, 121.5086],
  [-30.9851, 121.5359],
  [-30.9921, 121.5414],
  [-30.9998, 121.5439],
  [-31.0324, 121.5420],
  [-31.0370, 121.5427],
  [-31.0795, 121.5584],
  [-31.1468, 121.5970],
  [-31.1495, 121.5996],
  [-31.1523, 121.6038],
  [-31.1558, 121.6063],
  [-31.1696, 121.6102],
  [-31.1736, 121.6125],
  [-31.1884, 121.6272],
  [-31.1911, 121.6278],
  [-31.1973, 121.6294],
  [-31.2010, 121.6314],
  [-31.2068, 121.6377],
  [-31.2109, 121.6402],
  [-31.2168, 121.6402],
  [-31.2271, 121.6342],
  [-31.2512, 121.6243],
  [-31.3373, 121.5801],
  [-31.3420, 121.5785],
  [-31.3507, 121.5782],
  [-31.3565, 121.5800],
  [-31.3735, 121.5907],
  [-31.3767, 121.5946],
  [-31.3797, 121.6031],
  [-31.3832, 121.6074],
  [-31.3941, 121.6142],
  [-31.4154, 121.6221],
  [-31.4807, 121.6222],
  [-31.4839, 121.6205],
  [-31.4850, 121.6189],
  [-31.4899, 121.6035],
  [-31.4900, 121.6016],
  [-31.4899, 121.5982],
  [-31.4904, 121.5958],
  [-31.4928, 121.5920],
  [-31.4984, 121.5886],
  [-31.4997, 121.5882],
  [-31.5010, 121.5883],
  [-31.5039, 121.5900]

];

const kalgoorlieJunction = [
  [-30.7890, 121.3996],
  [-30.7901, 121.3979],
  [-30.7920, 121.3967],
  [-30.7934, 121.3964],
  [-30.7948, 121.3964],
  [-30.7962, 121.3970]
];

const widgiemoolthaNorseman = [
  [-31.5039, 121.5900],
  [-31.5138, 121.5964],
  [-31.5157, 121.5969],
  [-31.5177, 121.5968],
  [-31.5200, 121.5975],
  [-31.5248, 121.6031],
  [-31.5263, 121.6041],
  [-31.5299, 121.6048],
  [-31.5319, 121.6047],
  [-31.5489, 121.5993],
  [-31.5511, 121.5991],
  [-31.5536, 121.5993],
  [-31.6096, 121.6179],
  [-31.6114, 121.6192],
  [-31.6164, 121.6254],
  [-31.6232, 121.6297],
  [-31.6254, 121.6307],
  [-31.6387, 121.6334],
  [-31.6427, 121.6348],
  [-31.6778, 121.6563],
  [-31.7046, 121.6685],
  [-31.7138, 121.6746],
  [-31.7170, 121.6778],
  [-31.7258, 121.6893],
  [-31.7320, 121.7024],
  [-31.7335, 121.7043],
  [-31.7370, 121.7072],
  [-31.7402, 121.7084],
  [-31.7457, 121.7115],
  [-31.7474, 121.7119],
  [-31.7686, 121.7101],
  [-31.7717, 121.7092],
  [-31.8753, 121.6573],
  [-31.8777, 121.6565],
  [-31.8916, 121.6554],
  [-31.8950, 121.6540],
  [-31.9003, 121.6498],
  [-31.9027, 121.6487],
  [-31.9050, 121.6485],
  [-31.9463, 121.6579],
  [-31.9820, 121.6770],
  [-31.9897, 121.6832],
  [-31.9915, 121.6843],
  [-32.0185, 121.6952],
  [-32.0207, 121.6954],
  [-32.0250, 121.6940],
  [-32.0264, 121.6931],
  [-32.0298, 121.6902],
  [-32.0340, 121.6871],
  [-32.0401, 121.6810],
  [-32.0414, 121.6804],
  [-32.0435, 121.6802],
  [-32.0511, 121.6823],
  [-32.0534, 121.6825],
  [-32.0606, 121.6816],
  [-32.0636, 121.6818],
  [-32.0674, 121.6829],
  [-32.0707, 121.6830],
  [-32.0754, 121.6817],
  [-32.0766, 121.6816],
  [-32.0778, 121.6820],
  [-32.1004, 121.6950],
  [-32.1124, 121.6989],
  [-32.1147, 121.6992],
  [-32.1170, 121.6990],
  [-32.1189, 121.6990],
  [-32.1315, 121.7032],
  [-32.1392, 121.7078],
  [-32.1429, 121.7121],
  [-32.1442, 121.7132],
  [-32.1500, 121.7166],
  [-32.1523, 121.7198],
  [-32.1529, 121.7249],
  [-32.1525, 121.7267],
  [-32.1514, 121.7291],
  [-32.1509, 121.7318],
  [-32.1505, 121.7401],
  [-32.1496, 121.7420],
  [-32.1484, 121.7433],
  [-32.1478, 121.7455],
  [-32.1479, 121.7467],
  [-32.1517, 121.7595],
  [-32.1519, 121.7606],
  [-32.1521, 121.7625],
  [-32.1527, 121.7641],
  [-32.1598, 121.7723],
  [-32.1611, 121.7732],
  [-32.1845, 121.7816],
  [-32.1858, 121.7818],
  [-32.1922, 121.7819]
];

const norsemanSalmonGums = [
  [-32.1922, 121.7819],
  [-32.2048, 121.7820],
  [-32.2062, 121.7812],
  [-32.2068, 121.7797],
  [-32.2068, 121.7763],
  [-32.2073, 121.7741],
  [-32.2089, 121.7723],
  [-32.2105, 121.7717],
  [-32.2518, 121.7666],
  [-32.2547, 121.7669],
  [-32.2575, 121.7686],
  [-32.2601, 121.7700],
  [-32.2628, 121.7702],
  [-32.2976, 121.7600],
  [-32.3009, 121.7573],
  [-32.3045, 121.7566],
  [-32.3076, 121.7550],
  [-32.3296, 121.7302],
  [-32.3850, 121.6782],
  [-32.3943, 121.6742],
  [-32.3987, 121.6705],
  [-32.5190, 121.6142],
  [-32.5896, 121.5810],
  [-32.5925, 121.5792],
  [-32.6027, 121.5677],
  [-32.6080, 121.5604],
  [-32.6125, 121.5560],
  [-32.6308, 121.5455],
  [-32.6342, 121.5442],
  [-32.7432, 121.5359],
  [-32.7471, 121.5366],
  [-32.9774, 121.6433],
  [-32.9784, 121.6439],
  [-32.9809, 121.6460]
];

const salmonGumsEsperance = [
  [-32.9809, 121.6460],
  [-32.9829, 121.6477],
  [-32.9848, 121.6484],
  [-32.9867, 121.6481],
  [-32.9886, 121.6470],
  [-32.9899, 121.6466],
  [-32.9914, 121.6468],
  [-33.0050, 121.6525],
  [-33.0460, 121.6734],
  [-33.0480, 121.6751],
  [-33.0517, 121.6797],
  [-33.0533, 121.6809],
  [-33.0548, 121.6809],
  [-33.0599, 121.6793],
  [-33.0621, 121.6792],
  [-33.1183, 121.6988],
  [-33.1206, 121.6990],
  [-33.1235, 121.6992],
  [-33.1255, 121.6994],
  [-33.1818, 121.7193],
  [-33.1840, 121.7194],
  [-33.2009, 121.7164],
  [-33.3489, 121.7164],
  [-33.3508, 121.7160],
  [-33.3523, 121.7153],
  [-33.3541, 121.7150],
  [-33.4057, 121.7235],
  [-33.4078, 121.7236],
  [-33.4096, 121.7232],
  [-33.4110, 121.7231],
  [-33.4215, 121.7249],
  [-33.4236, 121.7247],
  [-33.4281, 121.7230],
  [-33.4303, 121.7228],
  [-33.4494, 121.7273],
  [-33.4602, 121.7340],
  [-33.4679, 121.7387],
  [-33.5235, 121.7716],
  [-33.5415, 121.7761],
  [-33.5484, 121.7802],
  [-33.5579, 121.7839],
  [-33.5610, 121.7859],
  [-33.5622, 121.7864],
  [-33.5658, 121.7868],
  [-33.5959, 121.7972],
  [-33.6219, 121.8032],
  [-33.6486, 121.8135],
  [-33.6505, 121.8144],
  [-33.6746, 121.8361],
  [-33.6825, 121.8458],
  [-33.6835, 121.8468],
  [-33.6928, 121.8532],
  [-33.6958, 121.8541],
  [-33.7033, 121.8535],
  [-33.7201, 121.8571],
  [-33.7219, 121.8577],
  [-33.7278, 121.8619],
  [-33.7296, 121.8626],
  [-33.7465, 121.8653],
  [-33.7476, 121.8657],
  [-33.7494, 121.8667],
  [-33.7509, 121.8672],
  [-33.7554, 121.8674],
  [-33.7569, 121.8669],
  [-33.7580, 121.8661],
  [-33.7593, 121.8645],
  [-33.7608, 121.8633],
  [-33.7624, 121.8628],
  [-33.7667, 121.8630],
  [-33.7709, 121.8626],
  [-33.7722, 121.8628],
  [-33.7762, 121.8636],
  [-33.7778, 121.8636],
  [-33.7788, 121.8632],
  [-33.7805, 121.8620],
  [-33.7820, 121.8615],
  [-33.7834, 121.8617],
  [-33.7906, 121.8643],
  [-33.7922, 121.8645],
  [-33.7970, 121.8640],
  [-33.7985, 121.8640],
  [-33.8022, 121.8651],
  [-33.8043, 121.8646],
  [-33.8060, 121.8632],
  [-33.8078, 121.8599],
  [-33.8095, 121.8582],
  [-33.8117, 121.8566],
  [-33.8129, 121.8555],
  [-33.8138, 121.8538],
  [-33.8149, 121.8525],
  [-33.8195, 121.8492],
  [-33.8221, 121.8482],
  [-33.8263, 121.8484],
  [-33.8287, 121.8505],
  [-33.8407, 121.8681],
  [-33.8418, 121.8703],
  [-33.8422, 121.8726],
  [-33.8423, 121.8756],
  [-33.8428, 121.8774],
  [-33.8439, 121.8793],
  [-33.8447, 121.8802],
  [-33.8464, 121.8813],
  [-33.8478, 121.8817],
  [-33.8673, 121.8817],
  [-33.8688, 121.8821],
  [-33.8697, 121.8836],
  [-33.8700, 121.8901],
  [-33.8701, 121.8913],
  [-33.8702, 121.8928],
  [-33.8707, 121.8948],
  [-33.8731, 121.8999],
  [-33.8732, 121.9016],
  [-33.8725, 121.9026],
  [-33.8686, 121.9053]
];

export const esperance: Line = {
  name: 'Esperance',
  state: 'WA',
  segments: [
    {
      segments: [salmonGumsEsperance],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [norsemanSalmonGums],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [widgiemoolthaNorseman],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [coolgardieWidgiemooltha],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [coolgardieJunction],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kalgoorlieWidgiemooltha],
      history: {
        opened: {
          date: '1974-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kalgoorlieJunction],
      history: {
        opened: {
          date: '1974-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
