import { Line } from "../../trackTypes";

const bennettBrookRailway = [
  [-31.84538, 115.94672],
  [-31.84533, 115.94643],
  [-31.84528, 115.94630],
  [-31.84522, 115.94620],
  [-31.84511, 115.94607],
  [-31.84487, 115.94591],
  [-31.84440, 115.94547],
  [-31.84410, 115.94521],
  [-31.84361, 115.94472],
  [-31.84317, 115.94426],
  [-31.84294, 115.94410],
  [-31.84270, 115.94399],
  [-31.84245, 115.94396],
  [-31.84134, 115.94400],
  [-31.84091, 115.94412],
  [-31.84062, 115.94425],
  [-31.84039, 115.94440],
  [-31.84019, 115.94457],
  [-31.84001, 115.94474],
  [-31.83969, 115.94493],
  [-31.83934, 115.94504],
  [-31.83854, 115.94518],
  [-31.83825, 115.94525],
  [-31.83792, 115.94534],
  [-31.83770, 115.94537],
  [-31.83745, 115.94535],
  [-31.83711, 115.94528],
  [-31.83652, 115.94506],
  [-31.83496, 115.94432],
  [-31.83412, 115.94387],
  [-31.83354, 115.94358],
  [-31.83232, 115.94291],
  [-31.83204, 115.94278],
  [-31.83174, 115.94269],
  [-31.83069, 115.94245],
  [-31.83041, 115.94245],
  [-31.83020, 115.94249],
  [-31.82996, 115.94258],
  [-31.82946, 115.94282],
  [-31.82901, 115.94291],
  [-31.82864, 115.94294],
  [-31.82823, 115.94282],
  [-31.82796, 115.94266],
  [-31.82778, 115.94248],
  [-31.82759, 115.94220],
  [-31.82747, 115.94190],
  [-31.82740, 115.94157],
  [-31.82739, 115.94126],
  [-31.82744, 115.94093],
  [-31.82752, 115.94065],
  [-31.82769, 115.94035],
  [-31.82791, 115.94011],
  [-31.82823, 115.93983],
  [-31.82849, 115.93955],
  [-31.82956, 115.93824],
  [-31.83052, 115.93685],
  [-31.83071, 115.93652],
  [-31.83077, 115.93629],
  [-31.83106, 115.93491],
  [-31.83109, 115.93451],
  [-31.83105, 115.93417],
  [-31.83095, 115.93386],
  [-31.83080, 115.93360],
  [-31.82916, 115.93138],
  [-31.82876, 115.93078],
  [-31.82862, 115.93048],
  [-31.82856, 115.93023],
  [-31.82853, 115.92997],
  [-31.82854, 115.92965],
  [-31.82868, 115.92907],
  [-31.82884, 115.92876],
  [-31.82911, 115.92845],
  [-31.82947, 115.92825],
  [-31.82975, 115.92818],
  [-31.83000, 115.92818],
  [-31.83032, 115.92825],
  [-31.83066, 115.92844],
  [-31.83093, 115.92866],
  [-31.83156, 115.92954],
  [-31.83194, 115.93001],
  [-31.83300, 115.93128],
  [-31.83330, 115.93175],
  [-31.83344, 115.93204],
  [-31.83354, 115.93242],
  [-31.83372, 115.93442],
  [-31.83381, 115.93482],
  [-31.83392, 115.93511],
  [-31.83411, 115.93538],
  [-31.83433, 115.93561],
  [-31.83468, 115.93586],
  [-31.83485, 115.93603],
  [-31.83503, 115.93629],
  [-31.83515, 115.93660],
  [-31.83581, 115.93937],
  [-31.83597, 115.93992],
  [-31.83609, 115.94022],
  [-31.83684, 115.94218],
  [-31.83693, 115.94252],
  [-31.83696, 115.94289],
  [-31.83691, 115.94326],
  [-31.83678, 115.94364],
  [-31.83649, 115.94404],
  [-31.83616, 115.94429],
  [-31.83597, 115.94439],
  [-31.83572, 115.94444],
  [-31.83539, 115.94443],
  [-31.83496, 115.94432]
];

const railWorkshops = [
  [-31.84440, 115.94547],
  [-31.84433, 115.94532],
  [-31.84427, 115.94520],
  [-31.84422, 115.94505],
  [-31.84419, 115.94486],
  [-31.84420, 115.94461],
  [-31.84425, 115.94448],
  [-31.84432, 115.94435],
  [-31.84440, 115.94426],
  [-31.84453, 115.94416],
  [-31.84498, 115.94396]
];

const perthElectricTramwayLine = [
  [-31.83455, 115.94426],
  [-31.83565, 115.94487],
  [-31.83579, 115.94497],
  [-31.83593, 115.94514],
  [-31.83600, 115.94528],
  [-31.83606, 115.94548],
  [-31.83606, 115.94568],
  [-31.83604, 115.94586],
  [-31.83591, 115.94661],
  [-31.83587, 115.94673],
  [-31.83582, 115.94684],
  [-31.83578, 115.94688],
  [-31.83543, 115.94728],
  [-31.83535, 115.94740],
  [-31.83528, 115.94759],
  [-31.83528, 115.94779],
  [-31.83524, 115.94804],
  [-31.83527, 115.94821],
  [-31.83534, 115.94841],
  [-31.83544, 115.94857],
  [-31.83556, 115.94873],
  [-31.83822, 115.95153],
  [-31.84026, 115.95368],
  [-31.84126, 115.95473],
  [-31.84190, 115.95540],
  [-31.84207, 115.95553],
  [-31.84221, 115.95557],
  [-31.84231, 115.95556],
  [-31.84240, 115.95551],
  [-31.84250, 115.95538],
  [-31.84355, 115.95268],
  [-31.84363, 115.95248],
  [-31.84367, 115.95222],
  [-31.84379, 115.95096],
  [-31.84385, 115.95079],
  [-31.84389, 115.95069],
  [-31.84399, 115.95059],
  [-31.84449, 115.95034],
  [-31.84459, 115.95032],
  [-31.84467, 115.95032],
  [-31.84509, 115.95040],
  [-31.84518, 115.95041],
  [-31.84531, 115.95035],
  [-31.84569, 115.95003],
  [-31.84582, 115.94989],
  [-31.84589, 115.94974],
  [-31.84591, 115.94961],
  [-31.84591, 115.94945],
  [-31.84538, 115.94621],
  [-31.84536, 115.94597],
  [-31.84537, 115.94573],
  [-31.84550, 115.94463],
  [-31.84550, 115.94454],
  [-31.84548, 115.94443],
  [-31.84520, 115.94354]
];

const tramJunction = [
  [-31.84190, 115.95540],
  [-31.84210, 115.95565],
  [-31.84216, 115.95578],
  [-31.84218, 115.95595],
  [-31.84216, 115.95610],
  [-31.84209, 115.95634]
];

const whitemanParkStationExtension = [
  [-31.84250, 115.95538],
  [-31.84228, 115.95596],
  [-31.84209, 115.95634],
  [-31.84186, 115.95683],
  [-31.83990, 115.96090],
  [-31.83981, 115.96119],
  [-31.83982, 115.96148],
  [-31.83975, 115.96189],
  [-31.83971, 115.96202],
  [-31.83956, 115.96225],
  [-31.83866, 115.96311],
  [-31.83803, 115.96348],
  [-31.83659, 115.96467]
];

export const bennettBrook: Line = {
  name: 'Bennett Brook',
  state: 'WA',
  segments: [
    {
      segments: [bennettBrookRailway, railWorkshops],
      history: {
        opened: {
          date: '1984-12-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [perthElectricTramwayLine],
      history: {
        opened: {
          date: '1986-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [whitemanParkStationExtension, tramJunction],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
