import { Line } from "../../../trackTypes";

const rockhamptonJunctionMilman = [
  [-23.3675, 150.5099],
  [-23.3667, 150.5109],
  [-23.3659, 150.5115],
  [-23.3644, 150.5121],
  [-23.3625, 150.5123],
  [-23.3403, 150.5080],
  [-23.3204, 150.5064],
  [-23.3171, 150.5067],
  [-23.2992, 150.5115],
  [-23.2969, 150.5117],
  [-23.2927, 150.5107],
  [-23.2902, 150.5093],
  [-23.2835, 150.5041],
  [-23.2728, 150.4972],
  [-23.2709, 150.4963],
  [-23.2646, 150.4943],
  [-23.2547, 150.4930],
  [-23.2494, 150.4917],
  [-23.2320, 150.4851],
  [-23.2298, 150.4836],
  [-23.2273, 150.4808],
  [-23.2261, 150.4802],
  [-23.2250, 150.4802],
  [-23.2217, 150.4818],
  [-23.2194, 150.4823],
  [-23.2170, 150.4819],
  [-23.2146, 150.4806],
  [-23.2121, 150.4782],
  [-23.2105, 150.4771],
  [-23.2085, 150.4765],
  [-23.2023, 150.4759],
  [-23.2000, 150.4751],
  [-23.1961, 150.4731],
  [-23.1941, 150.4716],
  [-23.1913, 150.4690],
  [-23.1891, 150.4679],
  [-23.1869, 150.4676],
  [-23.1843, 150.4665],
  [-23.1832, 150.4656],
  [-23.1827, 150.4646],
  [-23.1817, 150.4627],
  [-23.1781, 150.4591],
  [-23.1683, 150.4531],
  [-23.1656, 150.4504],
  [-23.1611, 150.4432],
  [-23.1581, 150.4380],
  [-23.1525, 150.4327],
  [-23.1498, 150.4294],
  [-23.1474, 150.4269],
  [-23.1374, 150.4213],
  [-23.1343, 150.4188],
  [-23.1320, 150.4143],
  [-23.1297, 150.4049]
];

const milmanYaamba = [
  [-23.1297, 150.4049],
  [-23.1284, 150.3995],
  [-23.1283, 150.3979],
  [-23.1285, 150.3968],
  [-23.1299, 150.3925],
  [-23.1302, 150.3910],
  [-23.1318, 150.3675]
];

const yaambaKunwarara = [
  [-23.1318, 150.3675],
  [-23.1320, 150.3657],
  [-23.1316, 150.3640],
  [-23.1306, 150.3622],
  [-23.1173, 150.3465],
  [-23.1159, 150.3443],
  [-23.1037, 150.3179],
  [-23.0784, 150.2794],
  [-23.0766, 150.2777],
  [-23.0496, 150.2594],
  [-23.0484, 150.2590],
  [-23.0473, 150.2590],
  [-23.0448, 150.2595],
  [-23.0433, 150.2602],
  [-23.0395, 150.2625],
  [-23.0375, 150.2632],
  [-23.0354, 150.2632],
  [-23.0293, 150.2621],
  [-23.0275, 150.2612],
  [-23.0215, 150.2538],
  [-23.0191, 150.2522],
  [-23.0109, 150.2494],
  [-22.9999, 150.2434],
  [-22.9950, 150.2393],
  [-22.9800, 150.2310],
  [-22.9751, 150.2269],
  [-22.9723, 150.2234],
  [-22.9712, 150.2226],
  [-22.9672, 150.2210],
  [-22.9647, 150.2188],
  [-22.9503, 150.1940],
  [-22.9445, 150.1864],
  [-22.9182, 150.1402],
  [-22.9145, 150.1351]
];

const kunwararaMalborough = [
  [-22.9145, 150.1351],
  [-22.9032, 150.1192],
  [-22.9012, 150.1143],
  [-22.9008, 150.1119],
  [-22.9000, 150.1096],
  [-22.8985, 150.1071],
  [-22.8976, 150.1049],
  [-22.8952, 150.0957],
  [-22.8945, 150.0944],
  [-22.8934, 150.0929],
  [-22.8930, 150.0915],
  [-22.8930, 150.0891],
  [-22.8927, 150.0871],
  [-22.8914, 150.0846],
  [-22.8901, 150.0834],
  [-22.8891, 150.0826],
  [-22.8885, 150.0814],
  [-22.8873, 150.0763],
  [-22.8867, 150.0753],
  [-22.8859, 150.0747],
  [-22.8850, 150.0742],
  [-22.8844, 150.0736],
  [-22.8836, 150.0724],
  [-22.8834, 150.0714],
  [-22.8836, 150.0703],
  [-22.8842, 150.0695],
  [-22.8851, 150.0689],
  [-22.8859, 150.0679],
  [-22.8869, 150.0624],
  [-22.8869, 150.0604],
  [-22.8852, 150.0540],
  [-22.8846, 150.0530],
  [-22.8816, 150.0494],
  [-22.8812, 150.0487],
  [-22.8811, 150.0480],
  [-22.8810, 150.0456],
  [-22.8801, 150.0395],
  [-22.8796, 150.0383],
  [-22.8788, 150.0373],
  [-22.8716, 150.0315],
  [-22.8698, 150.0303],
  [-22.8668, 150.0293],
  [-22.8643, 150.0290],
  [-22.8630, 150.0285],
  [-22.8616, 150.0272],
  [-22.8593, 150.0231],
  [-22.8589, 150.0203],
  [-22.8597, 150.0165],
  [-22.8591, 150.0069],
  [-22.8578, 150.0032],
  [-22.8467, 149.9915],
  [-22.8423, 149.9886],
  [-22.8410, 149.9873],
  [-22.8318, 149.9747],
  [-22.8293, 149.9679],
  [-22.8271, 149.9587],
  [-22.8221, 149.9489],
  [-22.8189, 149.9436],
  [-22.8180, 149.9410],
  [-22.8179, 149.9390],
  [-22.8206, 149.9245],
  [-22.8207, 149.9217],
  [-22.8184, 149.9031],
  [-22.8137, 149.8885]
];

const malboroughStyx = [
  [-22.8137, 149.8885],
  [-22.8106, 149.8788],
  [-22.8098, 149.8757],
  [-22.8075, 149.8603],
  [-22.8069, 149.8585],
  [-22.8045, 149.8538],
  [-22.8037, 149.8528],
  [-22.8019, 149.8510],
  [-22.8012, 149.8502],
  [-22.8007, 149.8492],
  [-22.7999, 149.8466],
  [-22.7993, 149.8457],
  [-22.7983, 149.8449],
  [-22.7972, 149.8435],
  [-22.7926, 149.8332],
  [-22.7917, 149.8314],
  [-22.7836, 149.8207],
  [-22.7824, 149.8195],
  [-22.7774, 149.8163],
  [-22.7767, 149.8156],
  [-22.7760, 149.8148],
  [-22.7727, 149.8091],
  [-22.7670, 149.8004],
  [-22.7536, 149.7832],
  [-22.7523, 149.7823],
  [-22.7510, 149.7817],
  [-22.7450, 149.7810],
  [-22.7431, 149.7803],
  [-22.7415, 149.7791],
  [-22.7368, 149.7741],
  [-22.7359, 149.7725],
  [-22.7331, 149.7653],
  [-22.7259, 149.7567],
  [-22.7249, 149.7551],
  [-22.7232, 149.7511],
  [-22.7219, 149.7493],
  [-22.7117, 149.7387],
  [-22.7068, 149.7322],
  [-22.6822, 149.7067],
  [-22.6698, 149.6949],
  [-22.6144, 149.6544],
  [-22.5943, 149.6402],
  [-22.5930, 149.6385],
  [-22.5924, 149.6367],
  [-22.5923, 149.6352]
];

const styxWumalgi = [
  [-22.5923, 149.6352],
  [-22.5929, 149.6322],
  [-22.5928, 149.6291],
  [-22.5904, 149.6221],
  [-22.5889, 149.6200],
  [-22.5682, 149.6021],
  [-22.5660, 149.6009],
  [-22.5626, 149.5999],
  [-22.5612, 149.5992],
  [-22.5516, 149.5925],
  [-22.5304, 149.5715],
  [-22.5253, 149.5675],
  [-22.5244, 149.5671],
  [-22.5231, 149.5670],
  [-22.5219, 149.5673],
  [-22.5181, 149.5694],
  [-22.5173, 149.5696],
  [-22.5162, 149.5694],
  [-22.5153, 149.5689],
  [-22.5126, 149.5659],
  [-22.5118, 149.5653],
  [-22.5109, 149.5651],
  [-22.4993, 149.5656],
  [-22.4982, 149.5653],
  [-22.4959, 149.5639]
];

const wumalgiStLawrence = [
  [-22.4959, 149.5639],
  [-22.4939, 149.5631],
  [-22.4893, 149.5625],
  [-22.4874, 149.5618],
  [-22.4828, 149.5578],
  [-22.4814, 149.5572],
  [-22.4798, 149.5573],
  [-22.4783, 149.5579],
  [-22.4767, 149.5579],
  [-22.4751, 149.5571],
  [-22.4733, 149.5548],
  [-22.4721, 149.5539],
  [-22.4701, 149.5532],
  [-22.4693, 149.5532],
  [-22.4677, 149.5535],
  [-22.4665, 149.5534],
  [-22.4654, 149.5527],
  [-22.4632, 149.5510],
  [-22.4611, 149.5502],
  [-22.4592, 149.5505],
  [-22.4479, 149.5563],
  [-22.4455, 149.5565],
  [-22.4433, 149.5554],
  [-22.4304, 149.5427],
  [-22.3871, 149.5199],
  [-22.3837, 149.5191],
  [-22.3585, 149.5224],
  [-22.3535, 149.5222],
  [-22.3491, 149.5231]
];

const stLawrenceCarmila = [
  [-22.3491, 149.5231],
  [-22.3365, 149.5259],
  [-22.3338, 149.5261],
  [-22.2882, 149.5237],
  [-22.2507, 149.5217],
  [-22.2486, 149.5212],
  [-22.2430, 149.5192],
  [-22.2416, 149.5183],
  [-22.2360, 149.5132],
  [-22.2341, 149.5122],
  [-22.2208, 149.5087],
  [-22.2191, 149.5086],
  [-22.1725, 149.5170],
  [-22.1575, 149.5255],
  [-22.1488, 149.5326],
  [-22.1471, 149.5332],
  [-22.1457, 149.5330],
  [-22.1438, 149.5321],
  [-22.1417, 149.5318],
  [-22.1392, 149.5324],
  [-22.1380, 149.5323],
  [-22.1362, 149.5315],
  [-22.1350, 149.5313],
  [-22.1284, 149.5324],
  [-22.1273, 149.5329],
  [-22.1240, 149.5348],
  [-22.1225, 149.5352],
  [-22.1201, 149.5351],
  [-22.1170, 149.5344],
  [-22.1158, 149.5337],
  [-22.1109, 149.5297],
  [-22.1033, 149.5254],
  [-22.1017, 149.5250],
  [-22.1009, 149.5244],
  [-22.0986, 149.5220],
  [-22.0972, 149.5211],
  [-22.0942, 149.5206],
  [-22.0933, 149.5203],
  [-22.0821, 149.5121],
  [-22.0806, 149.5114],
  [-22.0530, 149.5004],
  [-22.0171, 149.4809],
  [-21.9807, 149.4571],
  [-21.9669, 149.4446],
  [-21.9614, 149.4391],
  [-21.9594, 149.4381],
  [-21.9116, 149.4162],
  [-21.9101, 149.4157],
  [-21.9051, 149.4148],
  [-21.9023, 149.4137],
  [-21.8848, 149.4036],
  [-21.8589, 149.3931],
  [-21.8113, 149.3697],
  [-21.8101, 149.3694],
  [-21.8090, 149.3697],
  [-21.8072, 149.3705]
];

const carmilaKoumala = [
  [-21.8072, 149.3705],
  [-21.8062, 149.3709],
  [-21.8050, 149.3710],
  [-21.8039, 149.3707],
  [-21.8031, 149.3701],
  [-21.8011, 149.3684],
  [-21.8000, 149.3672],
  [-21.7973, 149.3637],
  [-21.7958, 149.3621],
  [-21.7943, 149.3611],
  [-21.7922, 149.3602],
  [-21.7874, 149.3594],
  [-21.7775, 149.3583],
  [-21.7757, 149.3585],
  [-21.7712, 149.3603],
  [-21.7696, 149.3606],
  [-21.7619, 149.3616],
  [-21.7532, 149.3602],
  [-21.7455, 149.3600],
  [-21.7445, 149.3597],
  [-21.7412, 149.3579],
  [-21.7403, 149.3571],
  [-21.7394, 149.3566],
  [-21.7382, 149.3566],
  [-21.7358, 149.3573],
  [-21.7348, 149.3578],
  [-21.7323, 149.3603],
  [-21.7313, 149.3610],
  [-21.7303, 149.3613],
  [-21.7293, 149.3612],
  [-21.7185, 149.3590],
  [-21.7157, 149.3589],
  [-21.7128, 149.3593],
  [-21.7107, 149.3593],
  [-21.7080, 149.3586],
  [-21.7060, 149.3576],
  [-21.6935, 149.3480],
  [-21.6877, 149.3430],
  [-21.6869, 149.3426],
  [-21.6862, 149.3425],
  [-21.6856, 149.3426],
  [-21.6838, 149.3432],
  [-21.6829, 149.3432],
  [-21.6821, 149.3428],
  [-21.6783, 149.3391],
  [-21.6775, 149.3379],
  [-21.6769, 149.3363],
  [-21.6759, 149.3296],
  [-21.6750, 149.3270],
  [-21.6606, 149.3081],
  [-21.6590, 149.3052],
  [-21.6568, 149.3023],
  [-21.6549, 149.3002],
  [-21.6533, 149.2989],
  [-21.6429, 149.2925],
  [-21.6416, 149.2912],
  [-21.6332, 149.2807],
  [-21.6171, 149.2565],
  [-21.6081, 149.2472]
];

const koumalaKoumalaJunction = [
  [-21.6081, 149.2472],
  [-21.6060, 149.2452],
  [-21.6047, 149.2445],
  [-21.5925, 149.2408],
  [-21.5868, 149.2382],
  [-21.5853, 149.2380],
  [-21.5744, 149.2382],
  [-21.5538, 149.2368],
  [-21.5309, 149.2341]
];

const koumalaJunctionSarinaJunction = [
  [-21.5309, 149.2341],
  [-21.5042, 149.2310],
  [-21.5009, 149.2310],
  [-21.4706, 149.2341]
];

const sarinaJunctionSarina = [
  [-21.4706, 149.2341],
  [-21.4670, 149.2353],
  [-21.4651, 149.2354],
  [-21.4572, 149.2332],
  [-21.4466, 149.2318],
  [-21.4450, 149.2313],
  [-21.4397, 149.2281],
  [-21.4368, 149.2253],
  [-21.4344, 149.2219],
  [-21.4333, 149.2207],
  [-21.4301, 149.2187],
  [-21.4267, 149.2170],
  [-21.4259, 149.2169]
];

const sarinaMackay = [
  [-21.4259, 149.2169],
  [-21.4233, 149.2164],
  [-21.4224, 149.2161],
  [-21.4182, 149.2138],
  [-21.4173, 149.2129],
  [-21.4129, 149.2077],
  [-21.4077, 149.2035],
  [-21.4005, 149.1955],
  [-21.3957, 149.1910],
  [-21.3580, 149.1504],
  [-21.3569, 149.1496],
  [-21.3367, 149.1415],
  [-21.3074, 149.1234],
  [-21.2959, 149.1196],
  [-21.2936, 149.1196],
  [-21.2744, 149.1254],
  [-21.2269, 149.1440],
  [-21.2203, 149.1448],
  [-21.2190, 149.1453],
  [-21.2142, 149.1479],
  [-21.2003, 149.1562],
  [-21.1986, 149.1568],
  [-21.1819, 149.1592]
];

const mackayOldAllignment = [
  [-21.1819, 149.1592],
  [-21.1681, 149.1610],
  [-21.1670, 149.1616]
];

const mackayNewAllignment = [
  [-21.1819, 149.1592],
  [-21.1806, 149.1591],
  [-21.1790, 149.1585],
  [-21.1781, 149.1585],
  [-21.1758, 149.1587],
  [-21.1731, 149.1595],
  [-21.1720, 149.1596],
  [-21.1691, 149.1600],
  [-21.1670, 149.1594],
  [-21.1660, 149.1592],
  [-21.1648, 149.1586],
  [-21.1628, 149.1563],
  [-21.1562, 149.1513],
  [-21.1532, 149.1499],
  [-21.1483, 149.1490],
  [-21.1408, 149.1459],
  [-21.1376, 149.1436],
];

export const northCoastRockhamptonMackay: Line = {
  name: 'North Coast (Rockhampton - Mackay)',
  state: 'QLD',
  segments: [
    {
      segments: [rockhamptonJunctionMilman],
      history: {
        opened: {
          date: '1911-10-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [milmanYaamba],
      history: {
        opened: {
          date: '1913-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [yaambaKunwarara],
      history: {
        opened: {
          date: '1915-08-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kunwararaMalborough],
      history: {
        opened: {
          date: '1917-03-31',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [malboroughStyx],
      history: {
        opened: {
          date: '1919-06-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [styxWumalgi],
      history: {
        opened: {
          date: '1920-08-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wumalgiStLawrence],
      history: {
        opened: {
          date: '1921-06-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [stLawrenceCarmila],
      history: {
        opened: {
          date: '1921-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [carmilaKoumala],
      history: {
        opened: {
          date: '1920-08-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [koumalaKoumalaJunction],
      history: {
        opened: {
          date: '1915-07-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [koumalaJunctionSarinaJunction],
      history: {
        opened: {
          date: '1915-07-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-01',
          tracks: 2,
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2009-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [sarinaJunctionSarina],
      history: {
        opened: {
          date: '1915-07-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        sarinaMackay, {
        date: '1994-01-01',
        original: [mackayOldAllignment],
        deviation: [mackayNewAllignment]
      }],
      history: {
        opened: {
          date: '1913-06-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
