import { Line } from "../../../trackTypes";

//location of original christchurch station building
const christchurchFerrymeadJunction = [
  [-43.5410, 172.6428],
  [-43.5410, 172.6448],
  [-43.5412, 172.6462],
  [-43.5423, 172.6487],
  [-43.5691, 172.6965]
];

const ferrymeadJunctionHeathcote = [
  [-43.5691, 172.6965],
  [-43.5723, 172.7022],
  [-43.5747, 172.7052],
  [-43.5792, 172.7083]
];

const heatchoteLytteltonTunnel = [
  [-43.5792, 172.7083],
  [-43.5804, 172.7088]
];

const lytteltonTunnel = [
  [-43.5804, 172.7088],
  [-43.6030, 172.7176]
];

const lytteltonTunnelLyttelton = [
  [-43.6030, 172.7176],
  [-43.6035, 172.7180],
  [-43.6038, 172.7186],
  [-43.6049, 172.7233]
];

const lytteltonCashinQuay = [
  [-43.6049, 172.7233],
  [-43.6053, 172.7239],
  [-43.6057, 172.7242],
  [-43.6064, 172.7244],
  [-43.6071, 172.7250],
  [-43.6075, 172.7258],
  [-43.6075, 172.7270],
  [-43.6068, 172.7325],
  [-43.6065, 172.7333],
  [-43.6055, 172.7349],
  [-43.6051, 172.7356],
  [-43.6044, 172.7380],
  [-43.6041, 172.7394],
  [-43.6041, 172.7405]
];

const ferrymeadJunctionFerrymeadWharf = [
  [-43.5691, 172.6965],
  [-43.5695, 172.6978],
  [-43.5696, 172.6989],
  [-43.5692, 172.7001],
  [-43.5683, 172.7011],
  [-43.5663, 172.7021],
  [-43.5657, 172.7026],
  [-43.5653, 172.7031],
  [-43.5641, 172.7059],
  [-43.5630, 172.7073]
];

export const lyttelton: Line = {
  name: 'Lyttelton',
  state: 'NZ',
  segments: [
    {
      segments: [christchurchFerrymeadJunction],
      history: {
        opened: {
          date: '1863-12-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-04-01',
          gauge: 'narrow'
        }, {
          date: '1929-02-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1970-09-19',
          electrified: undefined
        }, {
          date: '1878-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [ferrymeadJunctionHeathcote],
      history: {
        opened: {
          date: '1867-12-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-04-01',
          gauge: 'narrow'
        }, {
          date: '1929-02-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1970-09-19',
          electrified: undefined
        }, {
          date: '1878-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        heatchoteLytteltonTunnel,
        {
          name: 'Lyttelton Rail Tunnel',
          segment: lytteltonTunnel,
          type: 'tunnel'
        },
        lytteltonTunnelLyttelton
      ],
      history: {
        opened: {
          date: '1867-12-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-04-01',
          gauge: 'narrow'
        }, {
          date: '1929-02-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1970-09-19',
          electrified: undefined
        }]
      }
    },
    {
      segments: [lytteltonCashinQuay],
      history: {
        opened: {
          date: '1965-01-01',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-09-19',
          electrified: undefined
        }]
      }
    },
    {
      segments: [ferrymeadJunctionFerrymeadWharf],
      history: {
        opened: {
          date: '1863-12-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-01-01',
          gauge: 'narrow'
        }, {
          date: '1868-07-01',
          status: 'closed'
        }, {
          date: '1977-01-01',
          status: 'open'
        }]
      }
    }
  ]
}
