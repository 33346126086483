import { Line } from "../../../../trackTypes";

const nicholsonStreetCarltonNorth = [
  [-37.80555, 144.97359],
  [-37.79281, 144.97577],
  [-37.78044, 144.97806]
];

const eastBrunswick = [
  [-37.78044, 144.97806],
  [-37.77890, 144.97829],
  [-37.76604, 144.98046]
];

const northFitzroyDepot = [
  [-37.78170, 144.97783],
  [-37.78164, 144.97790],
  [-37.78161, 144.97798],
  [-37.78160, 144.97807],
  [-37.78161, 144.97826],
  [-37.78158, 144.97840],
  [-37.78157, 144.97851],
  [-37.78165, 144.97909]
];

export const north3: Line = {
  name: 'North3',
  state: 'VIC',
  segments: [
    {
      segments: [nicholsonStreetCarltonNorth],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1940-01-01',
          status: 'closed'
        }, {
          date: '1956-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [eastBrunswick],
      history: {
        opened: {
          date: '1956-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [northFitzroyDepot],
      history: {
        opened: {
          date: '1956-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1993-12-18',
          status: 'closed'
        }]
      }
    }
  ]
};
