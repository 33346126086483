import { Line } from "../../../trackTypes";

const portHeadlandJunction = [
  [-20.3968, 118.5643],
  [-20.3937, 118.5647],
  [-20.3907, 118.5640],
  [-20.3882, 118.5624],
  [-20.3812, 118.5547],
  [-20.3791, 118.5537],
  [-20.3768, 118.5535],
  [-20.3743, 118.5546],
  [-20.3724, 118.5570],
  [-20.3721, 118.5599],
  [-20.3735, 118.5662],
  [-20.3775, 118.5704],
  [-20.3810, 118.5714],
  [-20.3846, 118.5710],
  [-20.3968, 118.5643],
  [-20.4028, 118.5611],
  [-20.4067, 118.5602],
  [-20.4094, 118.5607],
  [-20.4358, 118.5746],
  [-20.4712, 118.5865],
  [-20.4801, 118.5876],
  [-20.6002, 118.5827],
  [-20.6363, 118.5849],
  [-20.6548, 118.5871],
  [-20.7515, 118.6107],
  [-20.7662, 118.6182],
  [-20.7745, 118.6258],
  [-20.7962, 118.6647],
  [-20.8024, 118.6704],
  [-20.8121, 118.6725],
  [-20.8511, 118.6638],
  [-20.8623, 118.6639],
  [-20.9235, 118.6763],
  [-21.0022, 118.7025],
  [-21.0226, 118.7048],
  [-21.0450, 118.7083],
  [-21.0518, 118.7087],
  [-21.0702, 118.7070],
  [-21.0758, 118.7076],
  [-21.0829, 118.7107],
  [-21.0945, 118.7205],
  [-21.1376, 118.7480],
  [-21.1453, 118.7520],
  [-21.1819, 118.7638],
  [-21.1858, 118.7657],
  [-21.2074, 118.7800],
  [-21.2190, 118.7840],
  [-21.2270, 118.7851],
  [-21.2306, 118.7861],
  [-21.2464, 118.7952],
  [-21.3429, 118.8475],
  [-21.3590, 118.8545],
  [-21.4724, 118.8899],
  [-21.5171, 118.9004],
  [-21.5321, 118.9096],
  [-21.5405, 118.9192],
  [-21.5499, 118.9246],
  [-21.5615, 118.9259],
  [-21.5722, 118.9217],
  [-21.5891, 118.9084],
  [-21.5983, 118.9043],
  [-21.6122, 118.9004],
  [-21.6200, 118.8996],
  [-21.6332, 118.9008],
  [-21.6552, 118.8987],
  [-21.6650, 118.8958],
  [-21.6995, 118.8778],
  [-21.7094, 118.8745],
  [-21.7169, 118.8729],
  [-21.7277, 118.8730],
  [-21.7366, 118.8755],
  [-21.7926, 118.9079],
  [-21.8279, 118.9356],
  [-21.8358, 118.9410]
];

const junctionCloudBreakMine = [
  [-21.8358, 118.9410],
  [-21.8443, 118.9449],
  [-21.8508, 118.9462],
  [-21.8587, 118.9467],
  [-21.8657, 118.9483],
  [-21.8862, 118.9573],
  [-21.8889, 118.9591],
  [-21.8919, 118.9621],
  [-21.8984, 118.9709],
  [-21.9136, 118.9856],
  [-21.9185, 118.9905],
  [-21.9277, 118.9989],
  [-21.9299, 119.0015],
  [-21.9376, 119.0155],
  [-21.9406, 119.0320],
  [-21.9408, 119.0439],
  [-21.9419, 119.0510],
  [-21.9448, 119.0577],
  [-21.9676, 119.0950],
  [-21.9711, 119.1036],
  [-21.9747, 119.1098],
  [-21.9791, 119.1143],
  [-21.9852, 119.1250],
  [-21.9869, 119.1387],
  [-21.9950, 119.1589],
  [-22.0001, 119.1642],
  [-22.0204, 119.1760],
  [-22.0254, 119.1827],
  [-22.0304, 119.1847],
  [-22.0450, 119.1840],
  [-22.0517, 119.1857],
  [-22.0656, 119.1941],
  [-22.0767, 119.2029],
  [-22.1004, 119.2248],
  [-22.1255, 119.2422],
  [-22.1289, 119.2456],
  [-22.1391, 119.2597],
  [-22.1437, 119.2634],
  [-22.1579, 119.2692],
  [-22.1658, 119.2738],
  [-22.1711, 119.2762],
  [-22.1793, 119.2768],
  [-22.2044, 119.2810],
  [-22.2103, 119.2834],
  [-22.2320, 119.2964],
  [-22.2450, 119.3013],
  [-22.2580, 119.3072],
  [-22.2656, 119.3095],
  [-22.2694, 119.3117],
  [-22.2847, 119.3263],
  [-22.2880, 119.3305],
  [-22.2986, 119.3519],
  [-22.3028, 119.3580],
  [-22.3143, 119.3686],
  [-22.3184, 119.3739],
  [-22.3204, 119.3807],
  [-22.3196, 119.4178]
];

const cloudBreakMineChristmasCreek = [
  [-22.3196, 119.4178],
  [-22.3202, 119.4204],
  [-22.3251, 119.4339],
  [-22.3299, 119.4544],
  [-22.3338, 119.4654],
  [-22.3343, 119.4719],
  [-22.3318, 119.4866],
  [-22.3319, 119.4921],
  [-22.3334, 119.4975],
  [-22.3441, 119.5213],
  [-22.3445, 119.5244],
  [-22.3442, 119.5375],
  [-22.3456, 119.5430],
  [-22.3536, 119.5560],
  [-22.3549, 119.5588],
  [-22.3593, 119.5755],
  [-22.3617, 119.5988],
  [-22.3615, 119.6174],
  [-22.3577, 119.6427],
  [-22.3573, 119.6589],
  [-22.3578, 119.6636],
  [-22.3600, 119.6691],
  [-22.3633, 119.6737],
  [-22.3669, 119.6770],
  [-22.4021, 119.6963],
  [-22.4042, 119.6984],
  [-22.4055, 119.7016],
  [-22.4059, 119.7055],
  [-22.4076, 119.7185],
  [-22.4077, 119.7215],
  [-22.4085, 119.7280],
  [-22.4090, 119.7295],
  [-22.4098, 119.7308],
  [-22.4116, 119.7323],
  [-22.4140, 119.7328],
  [-22.4165, 119.7322],
  [-22.4184, 119.7306],
  [-22.4195, 119.7283],
  [-22.4195, 119.7258],
  [-22.4186, 119.7235],
  [-22.4066, 119.7074],
  [-22.4059, 119.7055]
];

const junctionSolomonMine = [
  [-21.8358, 118.9410],
  [-21.8401, 118.9422],
  [-21.8439, 118.9420],
  [-21.8485, 118.9402],
  [-21.8520, 118.9368],
  [-21.8602, 118.9220],
  [-21.8920, 118.8906],
  [-21.9046, 118.8805],
  [-21.9155, 118.8733],
  [-21.9289, 118.8625],
  [-21.9322, 118.8605],
  [-21.9367, 118.8595],
  [-21.9513, 118.8593],
  [-21.9638, 118.8556],
  [-21.9680, 118.8535],
  [-21.9883, 118.8358],
  [-21.9907, 118.8322],
  [-21.9946, 118.8201],
  [-21.9952, 118.8119],
  [-21.9995, 118.7991],
  [-22.0036, 118.7924],
  [-22.0152, 118.7822],
  [-22.0197, 118.7768],
  [-22.0226, 118.7742],
  [-22.0257, 118.7722],
  [-22.0293, 118.7681],
  [-22.0322, 118.7628],
  [-22.0341, 118.7598],
  [-22.0529, 118.7404],
  [-22.0555, 118.7336],
  [-22.0552, 118.7105],
  [-22.0560, 118.7018],
  [-22.0545, 118.6894],
  [-22.0500, 118.6740],
  [-22.0473, 118.6555],
  [-22.0406, 118.6369],
  [-22.0403, 118.6319],
  [-22.0382, 118.6219],
  [-22.0369, 118.6190],
  [-22.0259, 118.6036],
  [-22.0203, 118.5904],
  [-22.0150, 118.5700],
  [-22.0148, 118.5656],
  [-22.0164, 118.5531],
  [-22.0171, 118.5426],
  [-22.0184, 118.5319],
  [-22.0212, 118.5193],
  [-22.0220, 118.5086],
  [-22.0255, 118.4919],
  [-22.0246, 118.4873],
  [-22.0221, 118.4823],
  [-22.0214, 118.4771],
  [-22.0238, 118.4715],
  [-22.0253, 118.4659],
  [-22.0234, 118.4432],
  [-22.0243, 118.4398],
  [-22.0426, 118.3908],
  [-22.0528, 118.3724],
  [-22.0624, 118.3633],
  [-22.0975, 118.3151],
  [-22.1164, 118.2702],
  [-22.1590, 118.1409],
  [-22.1786, 118.0618],
  [-22.1848, 118.0422],
  [-22.1847, 118.0295],
  [-22.1836, 118.0255],
  [-22.1759, 118.0112],
  [-22.1493, 117.9771],
  [-22.1480, 117.9763],
  [-22.1465, 117.9761],
  [-22.1450, 117.9764],
  [-22.1391, 117.9799],
  [-22.1373, 117.9800],
  [-22.1351, 117.9790],
  [-22.1340, 117.9770],
  [-22.1340, 117.9745],
  [-22.1351, 117.9726],
  [-22.1367, 117.9716],
  [-22.1404, 117.9710],
  [-22.1435, 117.9716],
  [-22.1462, 117.9734],
  [-22.1493, 117.9771]
];

const solomonMineEliwana = [
  [-22.1847, 118.0295],
  [-22.1836, 118.0146],
  [-22.1852, 118.0086],
  [-22.1896, 118.0043],
  [-22.1970, 118.0013],
  [-22.2005, 117.9979],
  [-22.2021, 117.9950],
  [-22.2032, 117.9880],
  [-22.2058, 117.9847],
  [-22.2095, 117.9818],
  [-22.2127, 117.9768],
  [-22.2122, 117.9660],
  [-22.2108, 117.9636],
  [-22.2057, 117.9587],
  [-22.2041, 117.9545],
  [-22.2032, 117.9435],
  [-22.2017, 117.9402],
  [-22.1992, 117.9378],
  [-22.1898, 117.9311],
  [-22.1886, 117.9272],
  [-22.1916, 117.9160],
  [-22.1955, 117.9083],
  [-22.1974, 117.9061],
  [-22.2047, 117.9013],
  [-22.2103, 117.9006],
  [-22.2238, 117.9037],
  [-22.2270, 117.9033],
  [-22.2334, 117.8990],
  [-22.2441, 117.8819],
  [-22.2450, 117.8780],
  [-22.2498, 117.8254],
  [-22.2519, 117.8163],
  [-22.2556, 117.8115],
  [-22.2575, 117.8081],
  [-22.2580, 117.8047],
  [-22.2563, 117.7817],
  [-22.2566, 117.7765],
  [-22.2650, 117.7414],
  [-22.2784, 117.7163],
  [-22.2814, 117.7129],
  [-22.2929, 117.7041],
  [-22.2973, 117.6977],
  [-22.2985, 117.6913],
  [-22.2958, 117.6683],
  [-22.2966, 117.6620],
  [-22.3106, 117.6269],
  [-22.3126, 117.6240],
  [-22.3163, 117.6214],
  [-22.3325, 117.6176],
  [-22.3505, 117.6097],
  [-22.3538, 117.6070],
  [-22.3545, 117.6060],
  [-22.3609, 117.5949],
  [-22.3682, 117.5748],
  [-22.3790, 117.5521],
  [-22.3801, 117.5458],
  [-22.3787, 117.5217],
  [-22.3840, 117.4335],
  [-22.3839, 117.4307],
  [-22.3818, 117.4226],
  [-22.3737, 117.4025],
  [-22.3684, 117.3805],
  [-22.3669, 117.3768],
  [-22.3627, 117.3713],
  [-22.3604, 117.3663],
  [-22.3599, 117.3629],
  [-22.3600, 117.3280],
  [-22.3607, 117.3246],
  [-22.3714, 117.3040],
  [-22.3721, 117.3011],
  [-22.3719, 117.2981],
  [-22.3694, 117.2893],
  [-22.3687, 117.2845],
  [-22.3703, 117.2634],
  [-22.3706, 117.2482],
  [-22.3779, 117.2116],
  [-22.3787, 117.1906],
  [-22.3803, 117.1839],
  [-22.3963, 117.1515],
  [-22.3994, 117.1481],
  [-22.4082, 117.1344],
  [-22.4166, 117.1188],
  [-22.4201, 117.1075],
  [-22.4212, 117.0935],
  [-22.4272, 117.0774],
  [-22.4327, 117.0689],
  [-22.4375, 117.0566],
  [-22.4388, 117.0485],
  [-22.4432, 117.0365],
  [-22.4457, 117.0317],
  [-22.4470, 117.0277],
  [-22.4476, 117.0209],
  [-22.4462, 117.0090],
  [-22.4440, 116.9994],
  [-22.4356, 116.9832],
  [-22.4305, 116.9677],
  [-22.4312, 116.9607],
  [-22.4335, 116.9568],
  [-22.4362, 116.9405],
  [-22.4393, 116.9313],
  [-22.4419, 116.9283],
  [-22.4482, 116.9223],
  [-22.4499, 116.9143],
  [-22.4477, 116.9059],
  [-22.4459, 116.8951],
  [-22.4470, 116.8892],
  [-22.4466, 116.8820],
  [-22.4482, 116.8730],
  [-22.4505, 116.8604],
  [-22.4497, 116.8552],
  [-22.4466, 116.8513],
  [-22.4420, 116.8490],
  [-22.4399, 116.8491],
  [-22.4368, 116.8513],
  [-22.4356, 116.8555],
  [-22.4368, 116.8589],
  [-22.4469, 116.8687],
  [-22.4478, 116.8705],
  [-22.4482, 116.8730]
];

export const fortescue: Line = {
  name: 'Fortescue',
  state: 'WA',
  segments: [
    {
      segments: [portHeadlandJunction, junctionCloudBreakMine],
      history: {
        opened: {
          date: '2008-04-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cloudBreakMineChristmasCreek],
      history: {
        opened: {
          date: '2009-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [junctionSolomonMine],
      history: {
        opened: {
          date: '2012-12-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [solomonMineEliwana],
      history: {
        opened: {
          date: '2021-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
