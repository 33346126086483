import { Line } from "../../trackTypes";

const burnieGuildfordJunction = [
  [-41.0534, 145.9079],
  [-41.0565, 145.9071],
  [-41.0585, 145.9065],
  [-41.0592, 145.9067],
  [-41.0606, 145.9078],
  [-41.0616, 145.9081],
  [-41.0623, 145.9084],
  [-41.0629, 145.9084],
  [-41.0641, 145.9081],
  [-41.0645, 145.9082],
  [-41.0651, 145.9090],
  [-41.0652, 145.9096],
  [-41.0652, 145.9104],
  [-41.0653, 145.9110],
  [-41.0665, 145.9131],
  [-41.0670, 145.9138],
  [-41.0673, 145.9146],
  [-41.0677, 145.9152],
  [-41.0687, 145.9159],
  [-41.0689, 145.9164],
  [-41.0691, 145.9172],
  [-41.0696, 145.9178],
  [-41.0704, 145.9179],
  [-41.0710, 145.9174],
  [-41.0715, 145.9172],
  [-41.0721, 145.9172],
  [-41.0730, 145.9170],
  [-41.0734, 145.9167],
  [-41.0744, 145.9166],
  [-41.0764, 145.9170],
  [-41.0774, 145.9179],
  [-41.0779, 145.9186],
  [-41.0783, 145.9189],
  [-41.0788, 145.9189],
  [-41.0799, 145.9180],
  [-41.0803, 145.9176],
  [-41.0808, 145.9165],
  [-41.0813, 145.9160],
  [-41.0823, 145.9157],
  [-41.0830, 145.9151],
  [-41.0833, 145.9149],
  [-41.0840, 145.9149],
  [-41.0846, 145.9147],
  [-41.0850, 145.9147],
  [-41.0856, 145.9151],
  [-41.0862, 145.9151],
  [-41.0867, 145.9145],
  [-41.0868, 145.9139],
  [-41.0869, 145.9132],
  [-41.0876, 145.9123],
  [-41.0882, 145.9120],
  [-41.0887, 145.9119],
  [-41.0891, 145.9115],
  [-41.0899, 145.9098],
  [-41.0899, 145.9094],
  [-41.0898, 145.9087],
  [-41.0898, 145.9082],
  [-41.0902, 145.9074],
  [-41.0908, 145.9069],
  [-41.0913, 145.9056],
  [-41.0915, 145.9045],
  [-41.0920, 145.9033],
  [-41.0920, 145.9026],
  [-41.0917, 145.9018],
  [-41.0914, 145.9007],
  [-41.0909, 145.9001],
  [-41.0908, 145.8996],
  [-41.0909, 145.8990],
  [-41.0918, 145.8974],
  [-41.0924, 145.8971],
  [-41.0929, 145.8971],
  [-41.0943, 145.8966],
  [-41.0948, 145.8958],
  [-41.0954, 145.8955],
  [-41.0961, 145.8946],
  [-41.0964, 145.8935],
  [-41.0966, 145.8931],
  [-41.0979, 145.8927],
  [-41.0978, 145.8926],
  [-41.0990, 145.8917],
  [-41.0994, 145.8917],
  [-41.0999, 145.8918],
  [-41.1002, 145.8918],
  [-41.1010, 145.8912],
  [-41.1015, 145.8912],
  [-41.1020, 145.8915],
  [-41.1025, 145.8915],
  [-41.1039, 145.8908],
  [-41.1053, 145.8909],
  [-41.1057, 145.8911],
  [-41.1063, 145.8919],
  [-41.1074, 145.8922],
  [-41.1081, 145.8923],
  [-41.1085, 145.8926],
  [-41.1089, 145.8932],
  [-41.1096, 145.8935],
  [-41.1102, 145.8931],
  [-41.1107, 145.8919],
  [-41.1108, 145.8901],
  [-41.1111, 145.8903],
  [-41.1114, 145.8895],
  [-41.1115, 145.8883],
  [-41.1109, 145.8870],
  [-41.1105, 145.8850],
  [-41.1105, 145.8843],
  [-41.1108, 145.8813],
  [-41.1112, 145.8802],
  [-41.1119, 145.8794],
  [-41.1126, 145.8792],
  [-41.1131, 145.8787],
  [-41.1142, 145.8764],
  [-41.1145, 145.8746],
  [-41.1155, 145.8726],
  [-41.1165, 145.8716],
  [-41.1172, 145.8712],
  [-41.1188, 145.8709],
  [-41.1196, 145.8701],
  [-41.1211, 145.8667],
  [-41.1221, 145.8649],
  [-41.1229, 145.8637],
  [-41.1233, 145.8625],
  [-41.1248, 145.8606],
  [-41.1254, 145.8601],
  [-41.1266, 145.8600],
  [-41.1272, 145.8598],
  [-41.1279, 145.8592],
  [-41.1289, 145.8586],
  [-41.1296, 145.8578],
  [-41.1301, 145.8575],
  [-41.1308, 145.8576],
  [-41.1314, 145.8581],
  [-41.1319, 145.8583],
  [-41.1324, 145.8581],
  [-41.1335, 145.8582],
  [-41.1342, 145.8577],
  [-41.1348, 145.8566],
  [-41.1354, 145.8560],
  [-41.1361, 145.8562],
  [-41.1375, 145.8574],
  [-41.1381, 145.8575],
  [-41.1396, 145.8563],
  [-41.1401, 145.8558],
  [-41.1411, 145.8530],
  [-41.1410, 145.8522],
  [-41.1410, 145.8514],
  [-41.1414, 145.8497],
  [-41.1414, 145.8487],
  [-41.1412, 145.8480],
  [-41.1412, 145.8474],
  [-41.1414, 145.8468],
  [-41.1426, 145.8460],
  [-41.1433, 145.8460],
  [-41.1437, 145.8458],
  [-41.1441, 145.8452],
  [-41.1441, 145.8445],
  [-41.1439, 145.8436],
  [-41.1439, 145.8430],
  [-41.1442, 145.8424],
  [-41.1467, 145.8404],
  [-41.1471, 145.8395],
  [-41.1473, 145.8361],
  [-41.1476, 145.8354],
  [-41.1481, 145.8351],
  [-41.1492, 145.8350],
  [-41.1498, 145.8348],
  [-41.1509, 145.8337],
  [-41.1512, 145.8327],
  [-41.1511, 145.8317],
  [-41.1513, 145.8307],
  [-41.1532, 145.8281],
  [-41.1554, 145.8269],
  [-41.1578, 145.8260],
  [-41.1586, 145.8261],
  [-41.1594, 145.8266],
  [-41.1598, 145.8268],
  [-41.1608, 145.8268],
  [-41.1615, 145.8265],
  [-41.1624, 145.8256],
  [-41.1628, 145.8244],
  [-41.1629, 145.8235],
  [-41.1632, 145.8231],
  [-41.1649, 145.8220],
  [-41.1655, 145.8220],
  [-41.1663, 145.8224],
  [-41.1670, 145.8223],
  [-41.1679, 145.8216],
  [-41.1695, 145.8188],
  [-41.1703, 145.8183],
  [-41.1737, 145.8171],
  [-41.1743, 145.8168],
  [-41.1771, 145.8137],
  [-41.1777, 145.8135],
  [-41.1788, 145.8134],
  [-41.1796, 145.8129],
  [-41.1802, 145.8116],
  [-41.1807, 145.8109],
  [-41.1818, 145.8103],
  [-41.1835, 145.8080],
  [-41.1837, 145.8075],
  [-41.1838, 145.8067],
  [-41.1844, 145.8047],
  [-41.1849, 145.8039],
  [-41.1854, 145.8035],
  [-41.1874, 145.8030],
  [-41.1878, 145.8031],
  [-41.1886, 145.8033],
  [-41.1894, 145.8033],
  [-41.1900, 145.8030],
  [-41.1931, 145.7998],
  [-41.1934, 145.7992],
  [-41.1936, 145.7982],
  [-41.1939, 145.7972],
  [-41.1943, 145.7967],
  [-41.1948, 145.7966],
  [-41.1955, 145.7968],
  [-41.1966, 145.7967],
  [-41.1979, 145.7957],
  [-41.1986, 145.7954],
  [-41.1992, 145.7948],
  [-41.1997, 145.7938],
  [-41.2002, 145.7930],
  [-41.2013, 145.7926],
  [-41.2033, 145.7924],
  [-41.2041, 145.7927],
  [-41.2050, 145.7927],
  [-41.2067, 145.7931],
  [-41.2078, 145.7927],
  [-41.2084, 145.7921],
  [-41.2086, 145.7915],
  [-41.2088, 145.7910],
  [-41.2093, 145.7907],
  [-41.2097, 145.7907],
  [-41.2107, 145.7912],
  [-41.2111, 145.7915],
  [-41.2112, 145.7920],
  [-41.2116, 145.7924],
  [-41.2128, 145.7932],
  [-41.2134, 145.7933],
  [-41.2154, 145.7928],
  [-41.2166, 145.7929],
  [-41.2172, 145.7931],
  [-41.2178, 145.7933],
  [-41.2186, 145.7930],
  [-41.2194, 145.7922],
  [-41.2206, 145.7915],
  [-41.2211, 145.7913],
  [-41.2230, 145.7912],
  [-41.2237, 145.7908],
  [-41.2248, 145.7894],
  [-41.2254, 145.7890],
  [-41.2262, 145.7888],
  [-41.2268, 145.7884],
  [-41.2285, 145.7859],
  [-41.2292, 145.7854],
  [-41.2318, 145.7846],
  [-41.2326, 145.7846],
  [-41.2351, 145.7852],
  [-41.2365, 145.7858],
  [-41.2384, 145.7858],
  [-41.2389, 145.7857],
  [-41.2401, 145.7851],
  [-41.2410, 145.7848],
  [-41.2451, 145.7844],
  [-41.2463, 145.7850],
  [-41.2473, 145.7865],
  [-41.2481, 145.7870],
  [-41.2499, 145.7875],
  [-41.2514, 145.7885],
  [-41.2522, 145.7886],
  [-41.2531, 145.7881],
  [-41.2544, 145.7863],
  [-41.2551, 145.7858],
  [-41.2630, 145.7847],
  [-41.2640, 145.7846],
  [-41.2645, 145.7844],
  [-41.2650, 145.7838],
  [-41.2655, 145.7823],
  [-41.2673, 145.7798],
  [-41.2680, 145.7793],
  [-41.2701, 145.7786],
  [-41.2720, 145.7773],
  [-41.2739, 145.7764],
  [-41.2747, 145.7758],
  [-41.2754, 145.7743],
  [-41.2762, 145.7706],
  [-41.2773, 145.7669],
  [-41.2784, 145.7647],
  [-41.2791, 145.7643],
  [-41.2797, 145.7642],
  [-41.2804, 145.7638],
  [-41.2810, 145.7628],
  [-41.2812, 145.7619],
  [-41.2812, 145.7578],
  [-41.2810, 145.7570],
  [-41.2802, 145.7553],
  [-41.2794, 145.7491],
  [-41.2792, 145.7480],
  [-41.2792, 145.7467],
  [-41.2797, 145.7451],
  [-41.2811, 145.7434],
  [-41.2826, 145.7425],
  [-41.2833, 145.7413],
  [-41.2845, 145.7383],
  [-41.2847, 145.7376],
  [-41.2849, 145.7359],
  [-41.2852, 145.7351],
  [-41.2857, 145.7345],
  [-41.2861, 145.7339],
  [-41.2861, 145.7331],
  [-41.2859, 145.7318],
  [-41.2858, 145.7312],
  [-41.2861, 145.7283],
  [-41.2865, 145.7274],
  [-41.2872, 145.7269],
  [-41.2879, 145.7268],
  [-41.2884, 145.7267],
  [-41.2903, 145.7255],
  [-41.2909, 145.7253],
  [-41.2937, 145.7235],
  [-41.2941, 145.7231],
  [-41.2944, 145.7222],
  [-41.2946, 145.7189],
  [-41.2943, 145.7171],
  [-41.2933, 145.7145],
  [-41.2931, 145.7138],
  [-41.2927, 145.7084],
  [-41.2925, 145.7075],
  [-41.2925, 145.7068],
  [-41.2929, 145.7061],
  [-41.2949, 145.7043],
  [-41.2989, 145.7031],
  [-41.2996, 145.7024],
  [-41.3010, 145.7007],
  [-41.3031, 145.6990],
  [-41.3037, 145.6988],
  [-41.3041, 145.6986],
  [-41.3063, 145.6958],
  [-41.3074, 145.6950],
  [-41.3124, 145.6919],
  [-41.3161, 145.6885],
  [-41.3169, 145.6880],
  [-41.3206, 145.6866],
  [-41.3213, 145.6865],
  [-41.3226, 145.6869],
  [-41.3232, 145.6870],
  [-41.3240, 145.6865],
  [-41.3246, 145.6862],
  [-41.3254, 145.6862],
  [-41.3278, 145.6875],
  [-41.3284, 145.6882],
  [-41.3288, 145.6890],
  [-41.3290, 145.6910],
  [-41.3294, 145.6917],
  [-41.3303, 145.6924],
  [-41.3313, 145.6927],
  [-41.3338, 145.6943],
  [-41.3350, 145.6947],
  [-41.3361, 145.6947],
  [-41.3370, 145.6950],
  [-41.3376, 145.6951],
  [-41.3384, 145.6947],
  [-41.3391, 145.6938],
  [-41.3398, 145.6934],
  [-41.3408, 145.6926],
  [-41.3415, 145.6914],
  [-41.3424, 145.6883],
  [-41.3427, 145.6877],
  [-41.3434, 145.6870],
  [-41.3516, 145.6835],
  [-41.3535, 145.6823],
  [-41.3550, 145.6820],
  [-41.3554, 145.6820],
  [-41.3597, 145.6841],
  [-41.3604, 145.6850],
  [-41.3615, 145.6860],
  [-41.3619, 145.6870],
  [-41.3619, 145.6879],
  [-41.3613, 145.6900],
  [-41.3614, 145.6909],
  [-41.3620, 145.6915],
  [-41.3642, 145.6916],
  [-41.3650, 145.6921],
  [-41.3653, 145.6933],
  [-41.3650, 145.6954],
  [-41.3635, 145.6993],
  [-41.3635, 145.7002],
  [-41.3638, 145.7009],
  [-41.3644, 145.7012],
  [-41.3651, 145.7011],
  [-41.3666, 145.6996],
  [-41.3670, 145.6995],
  [-41.3675, 145.6997],
  [-41.3685, 145.7006],
  [-41.3690, 145.7008],
  [-41.3695, 145.7007],
  [-41.3706, 145.6995],
  [-41.3716, 145.6973],
  [-41.3720, 145.6970],
  [-41.3734, 145.6964],
  [-41.3755, 145.6962],
  [-41.3774, 145.6965],
  [-41.3783, 145.6964],
  [-41.3821, 145.6939],
  [-41.3829, 145.6935],
  [-41.3837, 145.6936],
  [-41.3845, 145.6942],
  [-41.3852, 145.6960],
  [-41.3857, 145.6965],
  [-41.3863, 145.6966],
  [-41.3869, 145.6963],
  [-41.3878, 145.6963],
  [-41.3895, 145.6968],
  [-41.3902, 145.6967],
  [-41.3910, 145.6969],
  [-41.3917, 145.6976],
  [-41.3924, 145.6988],
  [-41.3931, 145.6995],
  [-41.3938, 145.7006],
  [-41.3943, 145.7010],
  [-41.3950, 145.7008],
  [-41.3956, 145.7000],
  [-41.3959, 145.6996],
  [-41.3979, 145.6982],
  [-41.3985, 145.6981],
  [-41.3993, 145.6984],
  [-41.3999, 145.6984],
  [-41.4011, 145.6978],
  [-41.4016, 145.6974],
  [-41.4025, 145.6957],
  [-41.4031, 145.6952],
  [-41.4038, 145.6953],
  [-41.4078, 145.6981],
  [-41.4084, 145.6983],
  [-41.4091, 145.6982],
  [-41.4100, 145.6974],
  [-41.4103, 145.6971],
  [-41.4127, 145.6964],
  [-41.4154, 145.6961],
  [-41.4162, 145.6962],
  [-41.4176, 145.6972],
  [-41.4182, 145.6980],
  [-41.4193, 145.6989],
  [-41.4202, 145.6991],
  [-41.4217, 145.6988],
  [-41.4226, 145.6990],
  [-41.4231, 145.6990],
  [-41.4243, 145.6984],
  [-41.4247, 145.6979],
  [-41.4250, 145.6969]
];

const guildfordRosebery = [
  [-41.4250, 145.6969],
  [-41.4252, 145.6963],
  [-41.4258, 145.6956],
  [-41.4270, 145.6951],
  [-41.4323, 145.6933],
  [-41.4333, 145.6922],
  [-41.4335, 145.6907],
  [-41.4338, 145.6890],
  [-41.4342, 145.6882],
  [-41.4354, 145.6872],
  [-41.4360, 145.6870],
  [-41.4367, 145.6871],
  [-41.4373, 145.6873],
  [-41.4378, 145.6874],
  [-41.4382, 145.6872],
  [-41.4392, 145.6864],
  [-41.4412, 145.6860],
  [-41.4620, 145.6873],
  [-41.4629, 145.6871],
  [-41.4691, 145.6847],
  [-41.4705, 145.6835],
  [-41.4772, 145.6751],
  [-41.4792, 145.6700],
  [-41.4800, 145.6686],
  [-41.4837, 145.6645],
  [-41.4862, 145.6629],
  [-41.4933, 145.6605],
  [-41.4945, 145.6591],
  [-41.4962, 145.6578],
  [-41.4974, 145.6563],
  [-41.4984, 145.6557],
  [-41.5114, 145.6561],
  [-41.5123, 145.6558],
  [-41.5167, 145.6525],
  [-41.5171, 145.6520],
  [-41.5239, 145.6513],
  [-41.5255, 145.6505],
  [-41.5267, 145.6503],
  [-41.5273, 145.6496],
  [-41.5276, 145.6488],
  [-41.5273, 145.6462],
  [-41.5276, 145.6453],
  [-41.5281, 145.6448],
  [-41.5303, 145.6443],
  [-41.5355, 145.6404],
  [-41.5364, 145.6401],
  [-41.5414, 145.6421],
  [-41.5424, 145.6420],
  [-41.5430, 145.6413],
  [-41.5442, 145.6389],
  [-41.5455, 145.6377],
  [-41.5469, 145.6371],
  [-41.5505, 145.6337],
  [-41.5522, 145.6324],
  [-41.5542, 145.6311],
  [-41.5563, 145.6294],
  [-41.5569, 145.6283],
  [-41.5571, 145.6272],
  [-41.5564, 145.6198],
  [-41.5566, 145.6182],
  [-41.5563, 145.6162],
  [-41.5564, 145.6152],
  [-41.5589, 145.6114],
  [-41.5598, 145.6108],
  [-41.5597, 145.6101],
  [-41.5595, 145.6090],
  [-41.5597, 145.6081],
  [-41.5603, 145.6077],
  [-41.5618, 145.6077],
  [-41.5641, 145.6066],
  [-41.5645, 145.6060],
  [-41.5646, 145.6053],
  [-41.5649, 145.6046],
  [-41.5655, 145.6039],
  [-41.5657, 145.6027],
  [-41.5662, 145.6019],
  [-41.5672, 145.6017],
  [-41.5684, 145.6004],
  [-41.5688, 145.6001],
  [-41.5695, 145.6000],
  [-41.5700, 145.5995],
  [-41.5702, 145.5984],
  [-41.5707, 145.5980],
  [-41.5713, 145.5977],
  [-41.5719, 145.5967],
  [-41.5731, 145.5962],
  [-41.5738, 145.5966],
  [-41.5745, 145.5975],
  [-41.5750, 145.5976],
  [-41.5758, 145.5975],
  [-41.5763, 145.5977],
  [-41.5769, 145.5983],
  [-41.5774, 145.5984],
  [-41.5791, 145.5983],
  [-41.5797, 145.5978],
  [-41.5801, 145.5967],
  [-41.5804, 145.5961],
  [-41.5811, 145.5961],
  [-41.5820, 145.5961],
  [-41.5826, 145.5965],
  [-41.5831, 145.5976],
  [-41.5839, 145.5979],
  [-41.5845, 145.5974],
  [-41.5852, 145.5965],
  [-41.5866, 145.5955],
  [-41.5873, 145.5946],
  [-41.5880, 145.5941],
  [-41.5882, 145.5936],
  [-41.5891, 145.5932],
  [-41.5895, 145.5926],
  [-41.5900, 145.5923],
  [-41.5903, 145.5923],
  [-41.5914, 145.5916],
  [-41.5917, 145.5911],
  [-41.5919, 145.5903],
  [-41.5923, 145.5899],
  [-41.5927, 145.5898],
  [-41.5933, 145.5902],
  [-41.5938, 145.5916],
  [-41.5941, 145.5926],
  [-41.5947, 145.5930],
  [-41.5953, 145.5927],
  [-41.5960, 145.5925],
  [-41.5975, 145.5930],
  [-41.5981, 145.5928],
  [-41.5988, 145.5916],
  [-41.5992, 145.5906],
  [-41.5996, 145.5902],
  [-41.6002, 145.5901],
  [-41.6006, 145.5897],
  [-41.6011, 145.5890],
  [-41.6021, 145.5879],
  [-41.6030, 145.5875],
  [-41.6039, 145.5873],
  [-41.6044, 145.5869],
  [-41.6047, 145.5862],
  [-41.6052, 145.5859],
  [-41.6058, 145.5860],
  [-41.6064, 145.5858],
  [-41.6067, 145.5855],
  [-41.6070, 145.5849],
  [-41.6074, 145.5845],
  [-41.6079, 145.5845],
  [-41.6086, 145.5844],
  [-41.6091, 145.5840],
  [-41.6092, 145.5831],
  [-41.6089, 145.5822],
  [-41.6092, 145.5812],
  [-41.6097, 145.5808],
  [-41.6103, 145.5810],
  [-41.6113, 145.5822],
  [-41.6117, 145.5832],
  [-41.6123, 145.5835],
  [-41.6127, 145.5841],
  [-41.6130, 145.5849],
  [-41.6138, 145.5853],
  [-41.6150, 145.5848],
  [-41.6162, 145.5850],
  [-41.6170, 145.5859],
  [-41.6175, 145.5863],
  [-41.6185, 145.5865],
  [-41.6190, 145.5870],
  [-41.6194, 145.5882],
  [-41.6196, 145.5896],
  [-41.6203, 145.5902],
  [-41.6209, 145.5903],
  [-41.6221, 145.5915],
  [-41.6223, 145.5921],
  [-41.6225, 145.5930],
  [-41.6231, 145.5936],
  [-41.6248, 145.5940],
  [-41.6253, 145.5945],
  [-41.6264, 145.5969],
  [-41.6269, 145.5973],
  [-41.6275, 145.5973],
  [-41.6286, 145.5966],
  [-41.6298, 145.5964],
  [-41.6305, 145.5965],
  [-41.6310, 145.5966],
  [-41.6316, 145.5966],
  [-41.6334, 145.5970],
  [-41.6451, 145.5987],
  [-41.6529, 145.5997],
  [-41.6536, 145.6000],
  [-41.6608, 145.6061],
  [-41.6617, 145.6062],
  [-41.6625, 145.6057],
  [-41.6630, 145.6048],
  [-41.6634, 145.6032],
  [-41.6639, 145.6021],
  [-41.6643, 145.6003],
  [-41.6643, 145.5991],
  [-41.6651, 145.5963],
  [-41.6663, 145.5938],
  [-41.6667, 145.5933],
  [-41.6701, 145.5914],
  [-41.6708, 145.5913],
  [-41.6722, 145.5918],
  [-41.6736, 145.5916],
  [-41.6768, 145.5879],
  [-41.6801, 145.5845],
  [-41.6809, 145.5839],
  [-41.6815, 145.5831],
  [-41.6827, 145.5821],
  [-41.6840, 145.5803],
  [-41.6849, 145.5796],
  [-41.6863, 145.5782],
  [-41.6874, 145.5766],
  [-41.6903, 145.5745],
  [-41.6911, 145.5744],
  [-41.6916, 145.5738],
  [-41.6921, 145.5724],
  [-41.6929, 145.5717],
  [-41.6931, 145.5705],
  [-41.6927, 145.5695],
  [-41.6925, 145.5688],
  [-41.6927, 145.5670],
  [-41.6934, 145.5656],
  [-41.6938, 145.5641],
  [-41.6947, 145.5629],
  [-41.6967, 145.5611],
  [-41.6974, 145.5606],
  [-41.6998, 145.5605],
  [-41.7013, 145.5598],
  [-41.7028, 145.5588],
  [-41.7036, 145.5588],
  [-41.7042, 145.5592],
  [-41.7047, 145.5593],
  [-41.7052, 145.5590],
  [-41.7061, 145.5577],
  [-41.7070, 145.5572],
  [-41.7076, 145.5571],
  [-41.7082, 145.5566],
  [-41.7092, 145.5566],
  [-41.7102, 145.5560],
  [-41.7129, 145.5556],
  [-41.7137, 145.5558],
  [-41.7151, 145.5551],
  [-41.7168, 145.5530],
  [-41.7176, 145.5527],
  [-41.7185, 145.5511],
  [-41.7189, 145.5507],
  [-41.7210, 145.5501],
  [-41.7226, 145.5492],
  [-41.7251, 145.5478],
  [-41.7258, 145.5467],
  [-41.7259, 145.5452],
  [-41.7256, 145.5440],
  [-41.7256, 145.5429],
  [-41.7261, 145.5417],
  [-41.7261, 145.5407],
  [-41.7259, 145.5384],
  [-41.7261, 145.5372],
  [-41.7266, 145.5362],
  [-41.7289, 145.5340],
  [-41.7304, 145.5335],
  [-41.7327, 145.5310],
  [-41.7334, 145.5304],
  [-41.7341, 145.5303],
  [-41.7346, 145.5299],
  [-41.7352, 145.5288],
  [-41.7360, 145.5282],
  [-41.7398, 145.5213],
  [-41.7404, 145.5208],
  [-41.7412, 145.5206],
  [-41.7426, 145.5209],
  [-41.7434, 145.5207],
  [-41.7440, 145.5201],
  [-41.7470, 145.5133],
  [-41.7478, 145.5123],
  [-41.7488, 145.5120],
  [-41.7519, 145.5121],
  [-41.7529, 145.5126],
  [-41.7550, 145.5150],
  [-41.7555, 145.5160],
  [-41.7564, 145.5166],
  [-41.7575, 145.5166],
  [-41.7587, 145.5172],
  [-41.7590, 145.5181],
  [-41.7587, 145.5193],
  [-41.7588, 145.5203],
  [-41.7594, 145.5208],
  [-41.7600, 145.5206],
  [-41.7607, 145.5199],
  [-41.7612, 145.5196],
  [-41.7617, 145.5198],
  [-41.7626, 145.5206],
  [-41.7629, 145.5213],
  [-41.7635, 145.5217],
  [-41.7644, 145.5217],
  [-41.7651, 145.5219],
  [-41.7660, 145.5221],
  [-41.7670, 145.5228],
  [-41.7675, 145.5230],
  [-41.7684, 145.5229],
  [-41.7691, 145.5231],
  [-41.7702, 145.5248],
  [-41.7704, 145.5253],
  [-41.7704, 145.5266],
  [-41.7708, 145.5273],
  [-41.7721, 145.5282],
  [-41.7747, 145.5289],
  [-41.7752, 145.5294],
  [-41.7755, 145.5306],
  [-41.7753, 145.5315],
  [-41.7754, 145.5324],
  [-41.7757, 145.5330],
  [-41.7766, 145.5335],
  [-41.7771, 145.5335],
  [-41.7779, 145.5332],
  [-41.7784, 145.5324],
  [-41.7793, 145.5316],
  [-41.7798, 145.5316],
  [-41.7817, 145.5325],
  [-41.7822, 145.5326],
  [-41.7825, 145.5324],
  [-41.7869, 145.5285],
  [-41.7875, 145.5277],
  [-41.7885, 145.5251]
];

const roseberyArgentTunnel = [
  [-41.7885, 145.5251],
  [-41.7893, 145.5227],
  [-41.7893, 145.5217],
  [-41.7888, 145.5205],
  [-41.7888, 145.5192],
  [-41.7895, 145.5178],
  [-41.7896, 145.5172],
  [-41.7889, 145.5160],
  [-41.7889, 145.5149],
  [-41.7895, 145.5138],
  [-41.7892, 145.5122],
  [-41.7868, 145.5099],
  [-41.7846, 145.5087],
  [-41.7841, 145.5077],
  [-41.7842, 145.5068],
  [-41.7847, 145.5061],
  [-41.7848, 145.5056],
  [-41.7853, 145.4975],
  [-41.7851, 145.4965],
  [-41.7830, 145.4906],
  [-41.7830, 145.4862],
  [-41.7832, 145.4856],
  [-41.7833, 145.4849],
  [-41.7837, 145.4837],
  [-41.7835, 145.4826],
  [-41.7836, 145.4816],
  [-41.7833, 145.4807],
  [-41.7833, 145.4800],
  [-41.7835, 145.4786],
  [-41.7833, 145.4777],
  [-41.7834, 145.4768],
  [-41.7837, 145.4762],
  [-41.7839, 145.4754],
  [-41.7838, 145.4745],
  [-41.7841, 145.4739],
  [-41.7840, 145.4731],
  [-41.7835, 145.4724],
  [-41.7816, 145.4714],
  [-41.7810, 145.4713],
  [-41.7804, 145.4710],
  [-41.7798, 145.4701],
  [-41.7784, 145.4662],
  [-41.7783, 145.4630],
  [-41.7770, 145.4595],
  [-41.7761, 145.4542],
  [-41.7755, 145.4530],
  [-41.7752, 145.4521],
  [-41.7741, 145.4505],
  [-41.7731, 145.4488],
  [-41.7724, 145.4483],
  [-41.7714, 145.4474],
  [-41.7710, 145.4467],
  [-41.7709, 145.4460],
  [-41.7714, 145.4452],
  [-41.7722, 145.4448],
  [-41.7725, 145.4444],
  [-41.7731, 145.4441],
  [-41.7737, 145.4444],
  [-41.7745, 145.4442],
  [-41.7750, 145.4443],
  [-41.7767, 145.4457],
  [-41.7775, 145.4456],
  [-41.7780, 145.4458],
  [-41.7789, 145.4457],
  [-41.7795, 145.4462],
  [-41.7798, 145.4473],
  [-41.7801, 145.4478],
  [-41.7808, 145.4482],
  [-41.7816, 145.4498],
  [-41.7824, 145.4506],
  [-41.7841, 145.4510],
  [-41.7848, 145.4508],
  [-41.7856, 145.4509],
  [-41.7863, 145.4505],
  [-41.7869, 145.4495],
  [-41.7871, 145.4488],
  [-41.7869, 145.4477],
  [-41.7871, 145.4470],
  [-41.7876, 145.4465],
  [-41.7881, 145.4466],
  [-41.7894, 145.4459],
  [-41.7896, 145.4451],
  [-41.7893, 145.4443],
  [-41.7894, 145.4432],
  [-41.7900, 145.4426],
  [-41.7906, 145.4425],
  [-41.7910, 145.4420],
  [-41.7912, 145.4413],
  [-41.7917, 145.4401],
  [-41.7915, 145.4391],
  [-41.7910, 145.4383],
  [-41.7904, 145.4378],
  [-41.7902, 145.4370],
  [-41.7904, 145.4363],
  [-41.7915, 145.4348],
  [-41.7919, 145.4346],
  [-41.7933, 145.4345],
  [-41.7938, 145.4342],
  [-41.7941, 145.4336],
  [-41.7943, 145.4311],
  [-41.7946, 145.4304],
  [-41.7950, 145.4301],
  [-41.7973, 145.4302],
  [-41.7976, 145.4299],
  [-41.7979, 145.4289],
  [-41.7983, 145.4284],
  [-41.7990, 145.4280],
  [-41.7993, 145.4270],
  [-41.7996, 145.4262],
  [-41.8000, 145.4254],
  [-41.7996, 145.4235],
  [-41.7999, 145.4223],
  [-41.8005, 145.4220],
  [-41.8017, 145.4223],
  [-41.8028, 145.4214],
  [-41.8036, 145.4199],
  [-41.8039, 145.4174],
  [-41.8042, 145.4165],
  [-41.8049, 145.4156],
  [-41.8066, 145.4154],
  [-41.8141, 145.4181],
  [-41.8147, 145.4181],
  [-41.8158, 145.4178],
  [-41.8173, 145.4184],
  [-41.8186, 145.4184],
  [-41.8199, 145.4176],
  [-41.8227, 145.4170],
  [-41.8236, 145.4165],
  [-41.8244, 145.4157]
];

const argentTunnel = [
  [-41.8244, 145.4157],
  [-41.8276, 145.4124]
];

const argentTunnelMelbaFlats = [
  [-41.8276, 145.4124],
  [-41.8282, 145.4118],
  [-41.8309, 145.4101],
  [-41.8329, 145.4081],
  [-41.8366, 145.4060],
  [-41.8412, 145.4013]
];

const melbaFlatsZeehan = [
  [-41.8412, 145.4013],
  [-41.8443, 145.3980],
  [-41.8449, 145.3971],
  [-41.8461, 145.3938],
  [-41.8465, 145.3918],
  [-41.8474, 145.3905],
  [-41.8478, 145.3890],
  [-41.8485, 145.3819],
  [-41.8504, 145.3764],
  [-41.8518, 145.3744],
  [-41.8529, 145.3721],
  [-41.8534, 145.3716],
  [-41.8542, 145.3713],
  [-41.8633, 145.3718],
  [-41.8642, 145.3715],
  [-41.8802, 145.3536],
  [-41.8816, 145.3527],
  [-41.8839, 145.3498],
  [-41.8843, 145.3486],
  [-41.8847, 145.3435],
  [-41.8851, 145.3426],
  [-41.8856, 145.3421],
  [-41.8866, 145.3424],
  [-41.8892, 145.3439]
];

export const melba: Line = {
  name: 'Melba',
  state: 'TAS',
  segments: [
    {
      segments: [burnieGuildfordJunction],
      history: {
        opened: {
          date: '1878-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1884-07-15',
          trackType: 'heavy'
        }]
      }
    },
    {
      segments: [guildfordRosebery],
      history: {
        opened: {
          date: '1899-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        roseberyArgentTunnel,
        {
          name: 'Argent Tunnel',
          segment: argentTunnel,
          type: 'tunnel'
        },
        argentTunnelMelbaFlats
      ],
      history: {
        opened: {
          date: '1900-12-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1965-08-13',
          status: 'closed'
        }, {
          date: '1994-12-14',
          status: 'closed'
        }, {
          date: '1970-01-20',
          status: 'open'
        }, {
          date: '1996-12-16',
          status: 'open'
        }]
      }
    },
    {
      segments: [melbaFlatsZeehan],
      history: {
        opened: {
          date: '1900-12-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1965-08-13',
          status: 'closed'
        }]
      }
    }
  ]
}
