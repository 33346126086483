import { Line } from "../../../trackTypes";

const studholmeWaimate = [
  [-44.7268, 171.1279],
  [-44.7283, 171.1270],
  [-44.7289, 171.1260],
  [-44.7302, 171.1207],
  [-44.7390, 171.0716],
  [-44.7399, 171.0559]
];

const waimateWaimateForks = [
  [-44.7399, 171.0559],
  [-44.7409, 171.0409],
  [-44.7411, 171.0398],
  [-44.7417, 171.0385],
  [-44.7442, 171.0358],
  [-44.7498, 171.0266],
  [-44.7510, 171.0251],
  [-44.7562, 171.0225],
  [-44.7572, 171.0216],
  [-44.7577, 171.0204],
  [-44.7581, 171.0178],
  [-44.7591, 171.0153],
  [-44.7608, 171.0134],
  [-44.7617, 171.0130],
  [-44.7626, 171.0133],
  [-44.7639, 171.0142],
  [-44.7650, 171.0144],
  [-44.7657, 171.0140],
  [-44.7662, 171.0131],
  [-44.7675, 171.0059],
  [-44.7679, 171.0045],
  [-44.7704, 170.9997],
  [-44.7711, 170.9985],
  [-44.7717, 170.9971],
  [-44.7726, 170.9965],
  [-44.7739, 170.9964],
  [-44.7744, 170.9962],
  [-44.7747, 170.9956],
  [-44.7752, 170.9943],
  [-44.7755, 170.9938],
  [-44.7765, 170.9933],
  [-44.7770, 170.9927],
  [-44.7773, 170.9916],
  [-44.7773, 170.9905],
  [-44.7776, 170.9899],
  [-44.7792, 170.9887],
  [-44.7892, 170.9753],
  [-44.7897, 170.9740],
  [-44.7915, 170.9636],
  [-44.7915, 170.9621],
  [-44.7912, 170.9607],
  [-44.7911, 170.9591],
  [-44.7912, 170.9575],
  [-44.7910, 170.9564],
  [-44.7905, 170.9555],
  [-44.7905, 170.9545],
  [-44.7906, 170.9528],
  [-44.7904, 170.9515],
  [-44.7896, 170.9493],
  [-44.7889, 170.9488],
  [-44.7885, 170.9479],
  [-44.7882, 170.9467],
  [-44.7873, 170.9451],
  [-44.7866, 170.9442],
  [-44.7865, 170.9436],
  [-44.7868, 170.9430],
  [-44.7870, 170.9421],
  [-44.7868, 170.9412],
  [-44.7870, 170.9404],
  [-44.7873, 170.9398],
  [-44.7878, 170.9392]
];

const waimateForksWaimateDowns = [
  [-44.7878, 170.9392],
  [-44.7888, 170.9381],
  [-44.7890, 170.9377],
  [-44.7891, 170.9374],
  [-44.7890, 170.9371],
  [-44.7890, 170.9368],
  [-44.7900, 170.9336],
  [-44.7904, 170.9330],
  [-44.7911, 170.9323],
  [-44.7916, 170.9315],
  [-44.7918, 170.9304],
  [-44.7922, 170.9278],
  [-44.7923, 170.9255],
  [-44.7926, 170.9243],
  [-44.7933, 170.9225],
  [-44.7935, 170.9215],
  [-44.7932, 170.9179],
  [-44.7929, 170.9171],
  [-44.7921, 170.9164],
  [-44.7905, 170.9141],
  [-44.7896, 170.9117],
  [-44.7887, 170.9100],
  [-44.7885, 170.9095],
  [-44.7884, 170.9088],
  [-44.7882, 170.9080],
  [-44.7874, 170.9068],
  [-44.7871, 170.9066],
  [-44.7858, 170.9059],
  [-44.7853, 170.9053],
  [-44.7849, 170.9047],
  [-44.7846, 170.9044],
  [-44.7833, 170.9040],
  [-44.7829, 170.9035],
  [-44.7827, 170.9027],
  [-44.7822, 170.9021],
  [-44.7812, 170.9013],
  [-44.7802, 170.9009],
  [-44.7797, 170.9003],
  [-44.7793, 170.8991],
  [-44.7791, 170.8930],
  [-44.7790, 170.8922],
  [-44.7785, 170.8903],
  [-44.7784, 170.8897],
  [-44.7784, 170.8888],
  [-44.7785, 170.8881],
  [-44.7786, 170.8874],
  [-44.7783, 170.8866],
  [-44.7778, 170.8859],
  [-44.7688, 170.8801],
  [-44.7682, 170.8797],
  [-44.7665, 170.8774],
  [-44.7662, 170.8764],
  [-44.7661, 170.8755],
  [-44.7675, 170.8687],
  [-44.7681, 170.8669],
  [-44.7681, 170.8655],
  [-44.7676, 170.8640],
  [-44.7674, 170.8631],
  [-44.7675, 170.8602],
  [-44.7677, 170.8591],
  [-44.7681, 170.8579],
  [-44.7682, 170.8569],
  [-44.7684, 170.8526],
  [-44.7686, 170.8512],
  [-44.7689, 170.8498],
  [-44.7712, 170.8451]
];

export const waimate: Line = {
  name: 'Waimate',
  state: 'NZ',
  segments: [
    {
      segments: [studholmeWaimate],
      history: {
        opened: {
          date: '1877-03-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waimateWaimateForks],
      history: {
        opened: {
          date: '1883-02-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-11-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waimateForksWaimateDowns],
      history: {
        opened: {
          date: '1883-04-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-11-12',
          status: 'closed'
        }]
      }
    }
  ]
}
