import { Line } from "../../trackTypes";

const line = [
  [-38.54784, 143.98682],
  [-38.54803, 143.98650],
  [-38.54815, 143.98634],
  [-38.54857, 143.98600],
  [-38.54878, 143.98591],
  [-38.54932, 143.98562],
  [-38.54986, 143.98540],
  [-38.55072, 143.98521],
  [-38.55105, 143.98493],
  [-38.55123, 143.98465],
  [-38.55149, 143.98429],
  [-38.55234, 143.98353],
  [-38.55265, 143.98335],
  [-38.55290, 143.98328],
  [-38.55307, 143.98321],
  [-38.55333, 143.98301],
  [-38.55344, 143.98282],
  [-38.55346, 143.98270],
  [-38.55355, 143.98254],
  [-38.55374, 143.98227],
  [-38.55394, 143.98208],
  [-38.55437, 143.98150],
  [-38.55448, 143.98130],
  [-38.55469, 143.98051],
  [-38.55477, 143.98000],
  [-38.55507, 143.97916],
  [-38.55522, 143.97881],
  [-38.55520, 143.97867],
  [-38.55516, 143.97860],
  [-38.55511, 143.97824],
  [-38.55512, 143.97809],
  [-38.55522, 143.97797],
  [-38.55524, 143.97782],
  [-38.55519, 143.97770],
  [-38.55471, 143.97757],
  [-38.55440, 143.97755],
  [-38.55427, 143.97755],
  [-38.55420, 143.97745],
  [-38.55411, 143.97735],
  [-38.55388, 143.97725],
  [-38.55377, 143.97712],
  [-38.55368, 143.97689],
  [-38.55360, 143.97628]
];

export const lorneTram: Line = {
  name: 'Lorne Tramway',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1934-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
