import { Line } from "../../trackTypes";

const taneatuaJunctionKawerau = [
  [-37.9423, 176.7702],
  [-37.9443, 176.7711],
  [-37.9462, 176.7711],
  [-37.9568, 176.7674],
  [-37.9760, 176.7552],
  [-37.9778, 176.7547],
  [-37.9855, 176.7545],
  [-38.0020, 176.7568],
  [-38.0033, 176.7567],
  [-38.0133, 176.7532],
  [-38.0503, 176.7406],
  [-38.0510, 176.7403],
  [-38.0554, 176.7371],
  [-38.0767, 176.7213],
  [-38.0770, 176.7206],
  [-38.0770, 176.7200],
  [-38.0769, 176.7194],
  [-38.0761, 176.7174],
  [-38.0757, 176.7170],
  [-38.0752, 176.7168],
  [-38.0747, 176.7169],
  [-38.0732, 176.7180]
];

const kawerauMurupara = [
  [-38.0554, 176.7371],
  [-38.0548, 176.7379],
  [-38.0408, 176.7630],
  [-38.0403, 176.7653],
  [-38.0405, 176.7676],
  [-38.0442, 176.7810],
  [-38.0446, 176.7846],
  [-38.0452, 176.7863],
  [-38.0494, 176.7913],
  [-38.0509, 176.7920],
  [-38.0548, 176.7918],
  [-38.0603, 176.7906],
  [-38.0637, 176.7907],
  [-38.0652, 176.7911],
  [-38.0673, 176.7924],
  [-38.0696, 176.7947],
  [-38.0708, 176.7953],
  [-38.0721, 176.7952],
  [-38.0747, 176.7941],
  [-38.0759, 176.7928],
  [-38.0771, 176.7900],
  [-38.0779, 176.7880],
  [-38.0823, 176.7851],
  [-38.0898, 176.7822],
  [-38.0913, 176.7823],
  [-38.0926, 176.7835],
  [-38.0948, 176.7873],
  [-38.0959, 176.7882],
  [-38.0972, 176.7884],
  [-38.0999, 176.7881],
  [-38.1007, 176.7878],
  [-38.1046, 176.7845],
  [-38.1081, 176.7828],
  [-38.1094, 176.7827],
  [-38.1123, 176.7833],
  [-38.1134, 176.7834],
  [-38.1182, 176.7833],
  [-38.1192, 176.7835],
  [-38.1197, 176.7841],
  [-38.1206, 176.7852],
  [-38.1216, 176.7857],
  [-38.1229, 176.7855],
  [-38.1245, 176.7847],
  [-38.1256, 176.7847],
  [-38.1268, 176.7856],
  [-38.1281, 176.7892],
  [-38.1281, 176.7908],
  [-38.1274, 176.7965],
  [-38.1274, 176.7984],
  [-38.1284, 176.8023],
  [-38.1306, 176.8058],
  [-38.1317, 176.8069],
  [-38.1329, 176.8072],
  [-38.1389, 176.8062],
  [-38.1399, 176.8058],
  [-38.1411, 176.8048],
  [-38.1420, 176.8045],
  [-38.1433, 176.8046],
  [-38.1453, 176.8053],
  [-38.1463, 176.8053],
  [-38.1528, 176.8040],
  [-38.1539, 176.8040],
  [-38.1547, 176.8041],
  [-38.1555, 176.8040],
  [-38.1578, 176.8034],
  [-38.1591, 176.8034],
  [-38.1607, 176.8042],
  [-38.1616, 176.8044],
  [-38.1644, 176.8045],
  [-38.1688, 176.8037],
  [-38.1701, 176.8029],
  [-38.1723, 176.8000],
  [-38.1734, 176.7992],
  [-38.1749, 176.7992],
  [-38.1833, 176.8028],
  [-38.1843, 176.8028],
  [-38.1853, 176.8023],
  [-38.1908, 176.7972],
  [-38.1939, 176.7926],
  [-38.1952, 176.7915],
  [-38.2019, 176.7881],
  [-38.2031, 176.7866],
  [-38.2035, 176.7853],
  [-38.2037, 176.7821],
  [-38.2046, 176.7800],
  [-38.2075, 176.7777],
  [-38.2085, 176.7774],
  [-38.2095, 176.7775],
  [-38.2177, 176.7822],
  [-38.2208, 176.7831],
  [-38.2219, 176.7831],
  [-38.2247, 176.7818],
  [-38.2322, 176.7804],
  [-38.2376, 176.7773],
  [-38.2423, 176.7726],
  [-38.2442, 176.7713],
  [-38.2490, 176.7695],
  [-38.2503, 176.7683],
  [-38.2517, 176.7659],
  [-38.2532, 176.7646],
  [-38.2573, 176.7632],
  [-38.2592, 176.7619],
  [-38.2624, 176.7576],
  [-38.2671, 176.7541],
  [-38.2684, 176.7537],
  [-38.2698, 176.7541],
  [-38.2731, 176.7575],
  [-38.2747, 176.7583],
  [-38.2761, 176.7582],
  [-38.2790, 176.7570],
  [-38.2807, 176.7567],
  [-38.2863, 176.7568],
  [-38.2890, 176.7577],
  [-38.2901, 176.7576],
  [-38.2912, 176.7570],
  [-38.2954, 176.7514],
  [-38.2968, 176.7486],
  [-38.2981, 176.7474],
  [-38.3037, 176.7456],
  [-38.3059, 176.7443],
  [-38.3109, 176.7442],
  [-38.3122, 176.7445],
  [-38.3199, 176.7480],
  [-38.3220, 176.7485],
  [-38.3294, 176.7477],
  [-38.3351, 176.7448],
  [-38.3365, 176.7446],
  [-38.3389, 176.7450],
  [-38.3429, 176.7445],
  [-38.3469, 176.7454],
  [-38.3479, 176.7452],
  [-38.3496, 176.7446],
  [-38.3511, 176.7444],
  [-38.3592, 176.7471],
  [-38.3603, 176.7472],
  [-38.3616, 176.7464],
  [-38.3855, 176.7213],
  [-38.3877, 176.7202],
  [-38.3922, 176.7200],
  [-38.3940, 176.7194],
  [-38.3956, 176.7179],
  [-38.4010, 176.7105],
  [-38.4023, 176.7093],
  [-38.4115, 176.7028],
  [-38.4135, 176.7020],
  [-38.4166, 176.7020],
  [-38.4182, 176.7018],
  [-38.4325, 176.6963],
  [-38.4354, 176.6964],
  [-38.4395, 176.6984],
  [-38.4410, 176.6987],
  [-38.4489, 176.6969],
  [-38.4534, 176.6945]
];

export const murupara: Line = {
  name: 'Murupara',
  state: 'NZ',
  segments: [
    {
      segments: [taneatuaJunctionKawerau],
      history: {
        opened: {
          date: '1953-10-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kawerauMurupara],
      history: {
        opened: {
          date: '1955-04-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
