import { Line } from "../../../../trackTypes";

const leedsStreet = [
  [-37.80145, 144.90090],
  [-37.80037, 144.90108],
  [-37.80011, 144.90115],
  [-37.80000, 144.90112],
  [-37.79992, 144.90099]
];

const line = [
  [-37.79992, 144.90099],
  [-37.79978, 144.89973],
  [-37.79972, 144.89963],
  [-37.79964, 144.89956],
  [-37.79952, 144.89949],
  [-37.79246, 144.89358],
  [-37.79237, 144.89344],
  [-37.79122, 144.88965]
];

const ballaratRoad = [
  [-37.79122, 144.88965],
  [-37.78864, 144.88324]
];

export const footscrayNorth: Line = {
  name: 'Footscray North',
  state: 'VIC',
  segments: [
    {
      segments: [leedsStreet],
      history: {
        opened: {
          date: '1962-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [line],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [ballaratRoad],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
