import { Line } from "../../../trackTypes";

const lidcombeJunction = [
  [-33.8606, 151.0411],
  [-33.8614, 151.0414],
  [-33.8622, 151.0412],
  [-33.8626, 151.0409],
  [-33.8631, 151.0401]
];

const libcombeRegentsPark = [
  [-33.8631, 151.0436],
  [-33.8628, 151.0426],
  [-33.8627, 151.0417],
  [-33.8628, 151.0410],
  [-33.8631, 151.0401],
  [-33.8638, 151.0389],
  [-33.8645, 151.0382],
  [-33.8700, 151.0345],
  [-33.8708, 151.0338],
  [-33.8714, 151.0331],
  [-33.8720, 151.0320],
  [-33.8727, 151.0307],
  [-33.8735, 151.0296],
  [-33.8758, 151.0271],
  [-33.8767, 151.0263],
  [-33.8781, 151.0256],
  [-33.8807, 151.0251],
  [-33.8819, 151.0247],
  [-33.8830, 151.0241]
];

const regentsParkSeftonParkSouthJunction = [
  [-33.8830, 151.0241],
  [-33.8840, 151.0232],
  [-33.8848, 151.0223],
  [-33.8850, 151.0220],
  [-33.8857, 151.0204],
  [-33.8860, 151.0190],
  [-33.8860, 151.0172]
];

const seftonParkSouthJunctionJunction = [
  [-33.8860, 151.0172],
  [-33.8809, 150.9805],
  [-33.8807, 150.9771],
  [-33.8811, 150.9739],
  [-33.8815, 150.9719],
  [-33.8888, 150.9444],
  [-33.8894, 150.9426],
  [-33.8901, 150.9415],
  [-33.8912, 150.9403]
];

export const lidcombeCabramatta: Line = {
  name: 'Lidcombe - Cabramatta',
  state: 'NSW',
  segments: [
    {
      segments: [lidcombeJunction],
      history: {
        opened: {
          date: '1912-11-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [libcombeRegentsPark],
      history: {
        opened: {
          date: '1912-11-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [regentsParkSeftonParkSouthJunction],
      history: {
        opened: {
          date: '1924-10-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [seftonParkSouthJunctionJunction],
      history: {
        opened: {
          date: '1924-05-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2013-01-21',
          tracks: 3
        }]
      }
    }
  ]
};
