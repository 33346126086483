import { Line } from "../../../trackTypes";

const line = [
  [-28.87864, 121.33084],
  [-28.88476, 121.33086],
  [-28.88545, 121.33083],
  [-28.89250, 121.32998],
  [-28.89296, 121.32996],
  [-28.89335, 121.33002],
  [-28.89370, 121.33010],
  [-28.89573, 121.33082],
  [-28.89592, 121.33086],
  [-28.89620, 121.33086],
  [-28.90789, 121.33091],
  [-28.90835, 121.33085],
  [-28.90863, 121.33082],
  [-28.91396, 121.33081]
];

export const leonoraTrams: Line = {
  name: 'Leonora Trams',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1907-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1915-01-01',
          electrified: undefined
        }, {
          date: '1921-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
