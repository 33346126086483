import { Line } from "../../../trackTypes";

const tinwaldFrasersRoad = [
  [-43.9215, 171.7167],
  [-43.9219, 171.7153],
  [-43.9223, 171.7126],
  [-43.9222, 171.7097],
  [-43.9173, 171.6872],
  [-43.9165, 171.6849],
  [-43.9157, 171.6835],
  [-43.9150, 171.6827]
];

const frasersRoadWesterfield = [
  [-43.9150, 171.6827],
  [-43.8776, 171.6432],
  [-43.8480, 171.6257],
  [-43.8366, 171.6144],
  [-43.8359, 171.6133]
];

const westerfieldAnama = [
  [-43.8359, 171.6133],
  [-43.7867, 171.5351],
  [-43.7782, 171.5125],
  [-43.7645, 171.4750],
  [-43.7494, 171.4339]
];

const anamaCavendish = [
  [-43.7494, 171.4339],
  [-43.7455, 171.4239],
  [-43.7255, 171.3941],
  [-43.7243, 171.3934],
  [-43.7234, 171.3934],
  [-43.7188, 171.3951],
  [-43.7182, 171.3956],
  [-43.7172, 171.3972]
];

const cavendishMountSomers = [
  [-43.7172, 171.3972],
  [-43.7087, 171.4121]
];

const mountSomersSpringburn = [
  [-43.7087, 171.4121],
  [-43.7074, 171.4143],
  [-43.7058, 171.4197],
  [-43.7054, 171.4204],
  [-43.6873, 171.4481],
  [-43.6841, 171.4509],
  [-43.6770, 171.4628]
];

export const mountSomers: Line = {
  name: 'Mount Somers',
  state: 'NZ',
  segments: [
    {
      segments: [tinwaldFrasersRoad],
      history: {
        opened: {
          date: '1880-04-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }, {
          date: '1973-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [frasersRoadWesterfield],
      history: {
        opened: {
          date: '1880-04-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westerfieldAnama],
      history: {
        opened: {
          date: '1882-10-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [anamaCavendish],
      history: {
        opened: {
          date: '1883-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cavendishMountSomers],
      history: {
        opened: {
          date: '1885-10-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mountSomersSpringburn],
      history: {
        opened: {
          date: '1889-09-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-03-29',
          status: 'closed'
        }]
      }
    }
  ]
}
