import { Line } from "../../trackTypes";

const line = [
  [-37.0324, 144.7962],
  [-37.0302, 144.7963],
  [-37.0284, 144.7969],
  [-37.0276, 144.7978],
  [-37.0234, 144.7991],
  [-37.0210, 144.7995],
  [-37.0206, 144.8001],
  [-37.0204, 144.8012],
  [-37.0205, 144.8017],
  [-37.0207, 144.8024],
  [-37.0202, 144.8055],
  [-37.0198, 144.8062],
  [-37.0188, 144.8072],
  [-37.0147, 144.8079],
  [-37.0105, 144.8093],
  [-36.9953, 144.8128],
  [-36.9934, 144.8139],
  [-36.9854, 144.8237],
  [-36.9846, 144.8264],
  [-36.9843, 144.8302],
  [-36.9837, 144.8322],
  [-36.9826, 144.8338],
  [-36.9605, 144.8442],
  [-36.9524, 144.8458],
  [-36.9475, 144.8489],
  [-36.9307, 144.8525],
  [-36.9294, 144.8538],
  [-36.9260, 144.8590],
  [-36.9241, 144.8601],
  [-36.9213, 144.8603],
  [-36.9145, 144.8583],
  [-36.9111, 144.8577],
  [-36.9027, 144.8576],
  [-36.8967, 144.8573],
  [-36.8961, 144.8575],
  [-36.8957, 144.8578],
  [-36.8951, 144.8581],
  [-36.8849, 144.8554],
  [-36.8843, 144.8555],
  [-36.8832, 144.8558],
  [-36.8824, 144.8567],
  [-36.8820, 144.8578],
  [-36.8809, 144.8615],
  [-36.8795, 144.8635],
  [-36.8787, 144.8642],
  [-36.8785, 144.8650],
  [-36.8792, 144.8685],
  [-36.8790, 144.8709],
  [-36.8765, 144.8798],
  [-36.8757, 144.8836],
  [-36.8747, 144.8967],
  [-36.8752, 144.9003],
  [-36.8766, 144.9074],
  [-36.8769, 144.9090],
  [-36.8769, 144.9202],
  [-36.8776, 144.9226],
  [-36.8827, 144.9317],
  [-36.8848, 144.9382],
  [-36.8868, 144.9426],
  [-36.8918, 144.9497],
  [-36.8934, 144.9533],
  [-36.8946, 144.9553],
  [-36.8965, 144.9564],
  [-36.8982, 144.9567],
  [-36.9043, 144.9545],
  [-36.9055, 144.9544],
  [-36.9114, 144.9564],
  [-36.9162, 144.9605],
  [-36.9174, 144.9621],
  [-36.9206, 144.9647],
  [-36.9247, 144.9665],
  [-36.9255, 144.9673],
  [-36.9300, 144.9681],
  [-36.9320, 144.9688],
  [-36.9372, 144.9714],
  [-36.9428, 144.9722],
  [-36.9442, 144.9729],
  [-36.9491, 144.9762],
  [-36.9498, 144.9770],
  [-36.9502, 144.9777],
  [-36.9518, 144.9807],
  [-36.9523, 144.9813],
  [-36.9538, 144.9818],
  [-36.9555, 144.9827],
  [-36.9568, 144.9839],
  [-36.9590, 144.9876]
];

const cherringtonBranch = [
  [-36.8790, 144.8709],
  [-36.8785, 144.8720],
  [-36.8769, 144.8734],
  [-36.8696, 144.8791],
  [-36.8684, 144.8793],
  [-36.8674, 144.8792],
  [-36.8616, 144.8774],
  [-36.8594, 144.8757],
  [-36.8484, 144.8716],
  [-36.8464, 144.8714],
  [-36.8292, 144.8749],
  [-36.8239, 144.8769],
  [-36.7966, 144.8955],
  [-36.7957, 144.8961],
  [-36.7582, 144.8961]
];

const graytownBranch = [
  [-36.8868, 144.9426]

];

// McIvor timber & firewood company tramway (broad gauge)
//  - https://www.lrrsa.org.au/McIvor_1972.pdf
//  - greytown branch closed in 1912-03
//  - Puckapunyal branch (off greytown)

export const mcIvor: Line = {
  name: 'McIvor',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1934-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cherringtonBranch],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
