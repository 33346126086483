import { Line } from "../../../../trackTypes";

const fendalton = [
  [-43.52099, 172.62814],
  [-43.52091, 172.62798],
  [-43.52087, 172.62776],
  [-43.52086, 172.62612],
  [-43.52079, 172.62567],
  [-43.52070, 172.62528],
  [-43.52064, 172.62491],
  [-43.52065, 172.62460],
  [-43.52069, 172.62431],
  [-43.52222, 172.61949],
  [-43.52222, 172.61925],
  [-43.52215, 172.61908],
  [-43.52098, 172.61823],
  [-43.52092, 172.61815],
  [-43.52089, 172.61802],
  [-43.52091, 172.61788],
  [-43.52119, 172.61707],
  [-43.52120, 172.61699],
  [-43.52119, 172.61691],
  [-43.52117, 172.61684],
  [-43.51973, 172.61578],
  [-43.51970, 172.61570],
  [-43.51970, 172.61564],
  [-43.51969, 172.61554],
  [-43.52050, 172.61307],
  [-43.52058, 172.61283],
  [-43.52169, 172.61014],
  [-43.52171, 172.61000],
  [-43.52173, 172.60985],
  [-43.52155, 172.60919],
  [-43.52146, 172.60899],
  [-43.52138, 172.60886],
  [-43.52127, 172.60872],
  [-43.52076, 172.60822],
  [-43.52061, 172.60806],
  [-43.52053, 172.60791],
  [-43.52044, 172.60773],
  [-43.52038, 172.60754],
  [-43.52032, 172.60732],
  [-43.51887, 172.59427],
  [-43.51865, 172.59134]
];

export const line7: Line = {
  name: 'Line 7',
  state: 'NZ',
  segments: [
    {
      segments: [fendalton],
      history: {
        opened: {
          date: '1907-05-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram', //steam
        },
        trackChange: [{
          date: '1909-11-20',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-06',
          status: 'closed'
        }]
      }
    }
  ]
}
