import { Line } from "../../trackTypes";

const rosewoodGrandchester = [
  [-27.6431, 152.5925],
  [-27.6465, 152.5827],
  [-27.6472, 152.5788],
  [-27.6649, 152.5344],
  [-27.6654, 152.5300],
  [-27.6637, 152.5218],
  [-27.6635, 152.5190],
  [-27.6639, 152.4772],
  [-27.6625, 152.4660]
];

const grandchesterSixChainsTunnel = [
  [-27.6625, 152.4660],
  [-27.6619, 152.4616],
  [-27.6620, 152.4608],
  [-27.6628, 152.4579],
  [-27.6628, 152.4572],
  [-27.6625, 152.4565],
  [-27.6612, 152.4548],
  [-27.6602, 152.4544],
  [-27.6586, 152.4544],
  [-27.6577, 152.4542],
  [-27.6568, 152.4538],
  [-27.6545, 152.4524],
  [-27.6533, 152.4512],
  [-27.6531, 152.4506],
  [-27.6531, 152.4502],
  [-27.6534, 152.4496],
  [-27.6538, 152.4494],
  [-27.6542, 152.4494],
  [-27.6547, 152.4495],
  [-27.6552, 152.4495]
];

const sixChainsTunnel = [
  [-27.6552, 152.4495],
  [-27.6555, 152.4493],
  [-27.6558, 152.4490],
  [-27.6559, 152.4488]
];

const sixChainsTunnelVictoriaTunnel = [
  [-27.6559, 152.4488],
  [-27.6560, 152.4482],
  [-27.6559, 152.4477],
  [-27.6545, 152.4459],
  [-27.6542, 152.4452],
  [-27.6540, 152.4435],
  [-27.6540, 152.4427],
  [-27.6538, 152.4420],
  [-27.6534, 152.4414],
  [-27.6523, 152.4407],
  [-27.6518, 152.4406],
  [-27.6509, 152.4407],
  [-27.6504, 152.4405],
  [-27.6501, 152.4402],
  [-27.6499, 152.4395],
  [-27.6501, 152.4389],
  [-27.6505, 152.4384],
  [-27.6511, 152.4382],
  [-27.6515, 152.4377],
  [-27.6518, 152.4369],
  [-27.6517, 152.4362],
  [-27.6514, 152.4356],
  [-27.6509, 152.4352],
  [-27.6507, 152.4346],
  [-27.6507, 152.4340],
  [-27.6509, 152.4335],
  [-27.6514, 152.4329],
  [-27.6516, 152.4323],
  [-27.6516, 152.4313],
  [-27.6517, 152.4306],
  [-27.6523, 152.4302],
  [-27.6529, 152.4303],
  [-27.6534, 152.4307],
  [-27.6536, 152.4316],
  [-27.6539, 152.4321],
  [-27.6550, 152.4331],
  [-27.6554, 152.4332],
  [-27.6558, 152.4331],
  [-27.6563, 152.4328],
  [-27.6565, 152.4324],
  [-27.6568, 152.4311],
  [-27.6568, 152.4304],
  [-27.6562, 152.4284]
];

const victoriaTunnel = [
  [-27.6562, 152.4284],
  [-27.6546, 152.4229]
];

const victoriaTunnelYarongmulu = [
  [-27.6546, 152.4229],
  [-27.6541, 152.4221],
  [-27.6532, 152.4212],
  [-27.6523, 152.4207]
];

const yarongmuluGatton = [
  [-27.6523, 152.4207],
  [-27.6511, 152.4201],
  [-27.6506, 152.4200],
  [-27.6498, 152.4200],
  [-27.6493, 152.4198],
  [-27.6490, 152.4196],
  [-27.6468, 152.4177],
  [-27.6463, 152.4170],
  [-27.6443, 152.4134],
  [-27.6440, 152.4131],
  [-27.6436, 152.4129],
  [-27.6431, 152.4129],
  [-27.6427, 152.4132],
  [-27.6424, 152.4136],
  [-27.6422, 152.4141],
  [-27.6423, 152.4146],
  [-27.6432, 152.4164],
  [-27.6434, 152.4175],
  [-27.6432, 152.4189],
  [-27.6424, 152.4200],
  [-27.6416, 152.4204],
  [-27.6399, 152.4205],
  [-27.6391, 152.4202],
  [-27.6386, 152.4197],
  [-27.6368, 152.4162],
  [-27.6358, 152.4154],
  [-27.6351, 152.4146],
  [-27.6347, 152.4138],
  [-27.6326, 152.4037],
  [-27.6319, 152.4024],
  [-27.6307, 152.4010],
  [-27.6302, 152.4003],
  [-27.6295, 152.3982],
  [-27.6294, 152.3971],
  [-27.6296, 152.3961],
  [-27.6295, 152.3950],
  [-27.6291, 152.3941],
  [-27.6286, 152.3935],
  [-27.6269, 152.3923],
  [-27.6262, 152.3921],
  [-27.6182, 152.3904],
  [-27.6156, 152.3895],
  [-27.6120, 152.3875],
  [-27.6094, 152.3855],
  [-27.6064, 152.3821],
  [-27.5825, 152.3483],
  [-27.5748, 152.3375],
  [-27.5729, 152.3342],
  [-27.5612, 152.3070],
  [-27.5605, 152.3047],
  [-27.5558, 152.2807],
  [-27.5552, 152.2789],
  [-27.5547, 152.2781],
  [-27.5543, 152.2775]
];

const gattonHelidon = [
  [-27.5543, 152.2775],
  [-27.5528, 152.2755],
  [-27.5490, 152.2713],
  [-27.5477, 152.2692],
  [-27.5473, 152.2668],
  [-27.5503, 152.2205],
  [-27.5506, 152.2192],
  [-27.5510, 152.2184],
  [-27.5550, 152.2129],
  [-27.5564, 152.2121],
  [-27.5627, 152.2115],
  [-27.5634, 152.2113],
  [-27.5651, 152.2106],
  [-27.5663, 152.2097],
  [-27.5674, 152.2084],
  [-27.5683, 152.2066],
  [-27.5694, 152.2053],
  [-27.5708, 152.2043],
  [-27.5740, 152.2030],
  [-27.5750, 152.2022],
  [-27.5756, 152.2012],
  [-27.5763, 152.1991],
  [-27.5765, 152.1968],
  [-27.5744, 152.1800],
  [-27.5736, 152.1772],
  [-27.5727, 152.1756],
  [-27.5668, 152.1661],
  [-27.5661, 152.1645],
  [-27.5616, 152.1470],
  [-27.5604, 152.1441],
  [-27.5499, 152.1247],
  [-27.5492, 152.1239],
  [-27.5486, 152.1235],
  [-27.5486, 152.1235]
];

const helidonTunnel1 = [
  [-27.5486, 152.1235],
  [-27.5460, 152.1229],
  [-27.5425, 152.1231],
  [-27.5417, 152.1230],
  [-27.5411, 152.1226],
  [-27.5388, 152.1202],
  [-27.5380, 152.1184],
  [-27.5351, 152.1007],
  [-27.5348, 152.0979],
  [-27.5348, 152.0972],
  [-27.5351, 152.0965],
  [-27.5352, 152.0958],
  [-27.5351, 152.0949],
  [-27.5345, 152.0941],
  [-27.5338, 152.0937],
  [-27.5331, 152.0937],
  [-27.5315, 152.0944],
  [-27.5294, 152.0958],
  [-27.5287, 152.0960],
  [-27.5280, 152.0961],
  [-27.5274, 152.0960],
  [-27.5240, 152.0945],
  [-27.5227, 152.0944],
  [-27.5218, 152.0945],
  [-27.5212, 152.0944],
  [-27.5208, 152.0941],
  [-27.5204, 152.0935],
  [-27.5203, 152.0929],
  [-27.5205, 152.0917],
  [-27.5204, 152.0907],
  [-27.5199, 152.0898],
  [-27.5192, 152.0892],
  [-27.5183, 152.0889],
  [-27.5178, 152.0886],
  [-27.5174, 152.0880],
  [-27.5168, 152.0871],
  [-27.5162, 152.0865],
  [-27.5097, 152.0835],
  [-27.5088, 152.0833],
  [-27.5080, 152.0835],
  [-27.5030, 152.0870],
  [-27.5023, 152.0877],
  [-27.5017, 152.0881],
  [-27.5009, 152.0882],
  [-27.5003, 152.0879],
  [-27.4986, 152.0870],
  [-27.4980, 152.0865],
  [-27.4974, 152.0856],
  [-27.4962, 152.0827],
  [-27.4954, 152.0820],
  [-27.4947, 152.0818],
  [-27.4916, 152.0820],
  [-27.4908, 152.0818],
  [-27.4901, 152.0812],
  [-27.4897, 152.0807],
  [-27.4886, 152.0786],
  [-27.4879, 152.0780],
  [-27.4872, 152.0778],
  [-27.4865, 152.0779],
  [-27.4860, 152.0783],
  [-27.4842, 152.0803],
  [-27.4834, 152.0808],
  [-27.4827, 152.0808],
  [-27.4819, 152.0805],
  [-27.4806, 152.0791],
  [-27.4798, 152.0787],
  [-27.4788, 152.0788],
  [-27.4766, 152.0802],
  [-27.4762, 152.0807],
  [-27.4760, 152.0812],
  [-27.4756, 152.0832],
  [-27.4759, 152.0843],
  [-27.4764, 152.0850],
  [-27.4777, 152.0859],
  [-27.4781, 152.0866],
  [-27.4783, 152.0873],
  [-27.4782, 152.0879],
  [-27.4778, 152.0895],
  [-27.4772, 152.0903],
  [-27.4767, 152.0907],
  [-27.4762, 152.0908],
  [-27.4755, 152.0908],
  [-27.4736, 152.0898],
  [-27.4718, 152.0894],
  [-27.4712, 152.0890],
  [-27.4706, 152.0886],
  [-27.4670, 152.0848],
  [-27.4661, 152.0833],
  [-27.4658, 152.0820],
  [-27.4660, 152.0808],
  [-27.4665, 152.0789],
  [-27.4667, 152.0762],
  [-27.4669, 152.0750],
  [-27.4681, 152.0714],
  [-27.4681, 152.0709],
  [-27.4680, 152.0702],
  [-27.4662, 152.0658],
  [-27.4661, 152.0651],
  [-27.4661, 152.0643],
  [-27.4659, 152.0635],
  [-27.4596, 152.0538],
  [-27.4590, 152.0532],
  [-27.4553, 152.0513],
  [-27.4545, 152.0505],
  [-27.4544, 152.0493],
  [-27.4551, 152.0476],
  [-27.4552, 152.0466],
  [-27.4550, 152.0456],
  [-27.4551, 152.0450],
  [-27.4554, 152.0444],
  [-27.4561, 152.0435],
  [-27.4563, 152.0425],
  [-27.4561, 152.0416],
  [-27.4550, 152.0403],
  [-27.4529, 152.0390],
  [-27.4522, 152.0388],
  [-27.4519, 152.0389],
  [-27.4513, 152.0392],
  [-27.4508, 152.0392],
  [-27.4501, 152.0390],
  [-27.4496, 152.0384],
  [-27.4495, 152.0378],
  [-27.4496, 152.0373],
  [-27.4500, 152.0366],
  [-27.4501, 152.0360],
  [-27.4500, 152.0307],
  [-27.4503, 152.0299],
  [-27.4511, 152.0292],
  [-27.4521, 152.0291],
  [-27.4528, 152.0296],
  [-27.4532, 152.0305],
  [-27.4534, 152.0313],
  [-27.4537, 152.0318],
  [-27.4543, 152.0321],
  [-27.4548, 152.0319],
  [-27.4552, 152.0315],
  [-27.4553, 152.0308],
  [-27.4551, 152.0301],
  [-27.4550, 152.0289],
  [-27.4548, 152.0285],
  [-27.4544, 152.0280],
  [-27.4542, 152.0276],
  [-27.4541, 152.0267],
  [-27.4540, 152.0263],
  [-27.4537, 152.0259],
  [-27.4533, 152.0257],
  [-27.4531, 152.0254],
  [-27.4530, 152.0249],
  [-27.4529, 152.0244],
  [-27.4528, 152.0240],
  [-27.4525, 152.0235],
  [-27.4520, 152.0215],
  [-27.4521, 152.0196],
  [-27.4523, 152.0190],
  [-27.4529, 152.0183],
  [-27.4531, 152.0177],
  [-27.4532, 152.0169],
  [-27.4537, 152.0162],
  [-27.4544, 152.0160],
  [-27.4551, 152.0164],
  [-27.4555, 152.0171],
  [-27.4559, 152.0177],
  [-27.4561, 152.0184],
  [-27.4560, 152.0189],
  [-27.4557, 152.0196],
  [-27.4556, 152.0203],
  [-27.4559, 152.0210],
  [-27.4569, 152.0219],
  [-27.4576, 152.0220],
  [-27.4582, 152.0217],
  [-27.4586, 152.0214],
  [-27.4591, 152.0204],
  [-27.4594, 152.0194],
  [-27.4597, 152.0190],
  [-27.4604, 152.0188],
  [-27.4617, 152.0191],
  [-27.4622, 152.0195],
  [-27.4625, 152.0201],
  [-27.4629, 152.0205],
  [-27.4635, 152.0205],
  [-27.4640, 152.0202],
  [-27.4642, 152.0197],
  [-27.4641, 152.0190],
  [-27.4637, 152.0183],
  [-27.4634, 152.0181],
  [-27.4624, 152.0175],
  [-27.4617, 152.0168],
  [-27.4609, 152.0154],
  [-27.4608, 152.0150],
  [-27.4609, 152.0145],
  [-27.4612, 152.0142],
  [-27.4624, 152.0134],
  [-27.4628, 152.0133],
  [-27.4633, 152.0134],
  [-27.4638, 152.0132],
  [-27.4641, 152.0127],
  [-27.4644, 152.0111],
  [-27.4644, 152.0099],
  [-27.4643, 152.0094],
  [-27.4640, 152.0089],
  [-27.4638, 152.0085],
  [-27.4638, 152.0078],
  [-27.4636, 152.0073],
  [-27.4632, 152.0068],
  [-27.4627, 152.0067],
  [-27.4622, 152.0067],
  [-27.4618, 152.0066],
  [-27.4614, 152.0062],
  [-27.4611, 152.0057],
  [-27.4612, 152.0050],
  [-27.4616, 152.0043]
];

const mainRangeNo1Tunnel = [
  [-27.4616, 152.0043],
  [-27.4621, 152.0037]
];

const tunnel1Tunnel2 = [
  [-27.4621, 152.0037],
  [-27.4625, 152.0033],
  [-27.4627, 152.0027],
  [-27.4626, 152.0020],
  [-27.4624, 152.0016],
  [-27.4623, 152.0015]
];

const mainRangeNo2Tunnel = [
  [-27.4623, 152.0015],
  [-27.4618, 152.0010]
];

const tunnel2Tunnel3 = [
  [-27.4618, 152.0010],
  [-27.4617, 152.0009],
  [-27.4615, 152.0003],
  [-27.4615, 151.9997],
  [-27.4621, 151.9979],
  [-27.4621, 151.9973],
  [-27.4618, 151.9968],
  [-27.4612, 151.9966],
  [-27.4608, 151.9966],
  [-27.4603, 151.9969],
  [-27.4598, 151.9970],
  [-27.4593, 151.9970],
  [-27.4588, 151.9971],
  [-27.4582, 151.9974],
  [-27.4578, 151.9975],
  [-27.4573, 151.9973],
  [-27.4570, 151.9968],
  [-27.4570, 151.9961],
  [-27.4575, 151.9950],
  [-27.4579, 151.9946],
  [-27.4588, 151.9940],
  [-27.4594, 151.9933],
  [-27.4603, 151.9917],
  [-27.4606, 151.9914],
  [-27.4611, 151.9912],
  [-27.4615, 151.9913],
  [-27.4620, 151.9917],
  [-27.4625, 151.9918],
  [-27.4630, 151.9916]
];

const mainRangeNo3Tunnel = [
  [-27.4630, 151.9916],
  [-27.4633, 151.9911]
];

const tunnel3Tunnel4 = [
  [-27.4633, 151.9911],
  [-27.4636, 151.9895],
  [-27.4636, 151.9884],
  [-27.4632, 151.9875],
  [-27.4631, 151.9870],
  [-27.4632, 151.9866],
  [-27.4643, 151.9840],
  [-27.4646, 151.9827],
  [-27.4650, 151.9816],
  [-27.4654, 151.9812],
  [-27.4658, 151.9809],
  [-27.4660, 151.9802],
  [-27.4659, 151.9796],
  [-27.4651, 151.9788],
  [-27.4650, 151.9784],
  [-27.4647, 151.9774],
  [-27.4647, 151.9770],
  [-27.4651, 151.9763],
  [-27.4656, 151.9759],
  [-27.4663, 151.9757],
  [-27.4677, 151.9757],
  [-27.4683, 151.9755],
  [-27.4689, 151.9748],
  [-27.4694, 151.9736],
  [-27.4700, 151.9728],
  [-27.4703, 151.9721],
  [-27.4703, 151.9712],
  [-27.4702, 151.9705],
  [-27.4702, 151.9700],
  [-27.4703, 151.9698]
];

const mainRangeNo4Tunnel = [
  [-27.4703, 151.9698],
  [-27.4710, 151.9684]
];

const tunnel4Tunnel5 = [
  [-27.4710, 151.9684],
  [-27.4711, 151.9672],
  [-27.4709, 151.9664],
  [-27.4703, 151.9654],
  [-27.4702, 151.9650],
  [-27.4702, 151.9645],
  [-27.4706, 151.9640],
  [-27.4711, 151.9639],
  [-27.4718, 151.9643],
  [-27.4721, 151.9651],
  [-27.4724, 151.9656],
  [-27.4734, 151.9668],
  [-27.4739, 151.9670],
  [-27.4744, 151.9669],
  [-27.4747, 151.9666],
  [-27.4751, 151.9659],
  [-27.4755, 151.9656],
  [-27.4759, 151.9655],
  [-27.4765, 151.9655],
  [-27.4775, 151.9653],
  [-27.4779, 151.9651],
  [-27.4785, 151.9644],
  [-27.4799, 151.9637],
  [-27.4807, 151.9638],
  [-27.4813, 151.9642],
  [-27.4819, 151.9648],
  [-27.4825, 151.9650],
  [-27.4831, 151.9648],
  [-27.4854, 151.9619],
  [-27.4860, 151.9613],
  [-27.4865, 151.9613],
  [-27.4870, 151.9615],
  [-27.4873, 151.9620],
  [-27.4873, 151.9626],
  [-27.4870, 151.9635],
  [-27.4869, 151.9639],
  [-27.4870, 151.9651],
  [-27.4873, 151.9655],
  [-27.4877, 151.9658],
  [-27.4899, 151.9662],
  [-27.4902, 151.9662],
  [-27.4908, 151.9660],
  [-27.4912, 151.9657],
  [-27.4914, 151.9653]
];

const mainRangeNo5Tunnel = [
  [-27.4914, 151.9653],
  [-27.4916, 151.9648],
  [-27.4920, 151.9646]
];

const tunnel5Tunnel6 = [
  [-27.4920, 151.9646],
  [-27.4926, 151.9647],
  [-27.4931, 151.9653],
  [-27.4931, 151.9660],
  [-27.4927, 151.9667],
  [-27.4923, 151.9677],
  [-27.4923, 151.9682],
  [-27.4925, 151.9688],
  [-27.4930, 151.9691],
  [-27.4936, 151.9693],
  [-27.4943, 151.9697]
];

const mainRangeNo6Tunnel = [
  [-27.4943, 151.9697],
  [-27.4947, 151.9697],
  [-27.4951, 151.9695]
];

const tunnel6Tunnel7 = [
  [-27.4951, 151.9695],
  [-27.4956, 151.9691],
  [-27.4958, 151.9685],
  [-27.4958, 151.9680],
  [-27.4959, 151.9676],
  [-27.4966, 151.9665],
  [-27.4970, 151.9661],
  [-27.4974, 151.9660],
  [-27.4979, 151.9660],
  [-27.4983, 151.9659],
  [-27.4987, 151.9656],
  [-27.4991, 151.9651]
];

const mainRangeNo7Tunnel = [
  [-27.4991, 151.9651],
  [-27.4993, 151.9648],
  [-27.4995, 151.9646]
];

const tunnel7Tunnel8 = [
  [-27.4995, 151.9646],
  [-27.4997, 151.9645],
  [-27.4999, 151.9644]
];

const mainRangeNo8Tunnel = [
  [-27.4999, 151.9644],
  [-27.5010, 151.9641],
  [-27.5013, 151.9641]
];

const tunnel8Tunnel9 = [
  [-27.5013, 151.9641],
  [-27.5016, 151.9643],
  [-27.5020, 151.9650],
  [-27.5021, 151.9660],
  [-27.5023, 151.9666],
  [-27.5029, 151.9678],
  [-27.5033, 151.9681],
  [-27.5038, 151.9681],
  [-27.5042, 151.9679],
  [-27.5052, 151.9666],
  [-27.5056, 151.9663],
  [-27.5060, 151.9663],
  [-27.5064, 151.9664],
  [-27.5066, 151.9664]
];

const mainRangeNo9Tunnel = [
  [-27.5066, 151.9664],
  [-27.5078, 151.9663]
];

const tunnel9Toowoomba = [
  [-27.5078, 151.9663],
  [-27.5082, 151.9663],
  [-27.5086, 151.9662],
  [-27.5092, 151.9657],
  [-27.5097, 151.9651],
  [-27.5101, 151.9649],
  [-27.5106, 151.9648],
  [-27.5110, 151.9645],
  [-27.5112, 151.9639],
  [-27.5110, 151.9632],
  [-27.5100, 151.9625],
  [-27.5098, 151.9621],
  [-27.5097, 151.9615],
  [-27.5099, 151.9610],
  [-27.5108, 151.9602],
  [-27.5111, 151.9596],
  [-27.5113, 151.9588],
  [-27.5116, 151.9583],
  [-27.5120, 151.9581],
  [-27.5125, 151.9580],
  [-27.5129, 151.9577],
  [-27.5132, 151.9572],
  [-27.5133, 151.9564],
  [-27.5137, 151.9554],
  [-27.5141, 151.9551],
  [-27.5145, 151.9551],
  [-27.5157, 151.9556],
  [-27.5163, 151.9561],
  [-27.5168, 151.9563],
  [-27.5188, 151.9564],
  [-27.5191, 151.9566],
  [-27.5195, 151.9569],
  [-27.5198, 151.9576],
  [-27.5202, 151.9578],
  [-27.5203, 151.9579],
  [-27.5216, 151.9582],
  [-27.5221, 151.9586],
  [-27.5224, 151.9589],
  [-27.5228, 151.9599],
  [-27.5230, 151.9603],
  [-27.5233, 151.9605],
  [-27.5239, 151.9616],
  [-27.5243, 151.9620],
  [-27.5247, 151.9620],
  [-27.5251, 151.9619],
  [-27.5254, 151.9617],
  [-27.5258, 151.9611],
  [-27.5262, 151.9608],
  [-27.5265, 151.9607],
  [-27.5274, 151.9607],
  [-27.5280, 151.9604],
  [-27.5285, 151.9597],
  [-27.5293, 151.9565],
  [-27.5298, 151.9552],
  [-27.5307, 151.9542],
  [-27.5335, 151.9522],
  [-27.5342, 151.9519],
  [-27.5360, 151.9516],
  [-27.5405, 151.9516],
  [-27.5413, 151.9518],
  [-27.5432, 151.9528],
  [-27.5442, 151.9531],
  [-27.5457, 151.9532],
  [-27.5476, 151.9536],
  [-27.5489, 151.9541],
  [-27.5497, 151.9540],
  [-27.5514, 151.9534],
  [-27.5518, 151.9531],
  [-27.5529, 151.9519],
  [-27.5533, 151.9516],
  [-27.5537, 151.9515],
  [-27.5556, 151.9519],
  [-27.5562, 151.9519],
  [-27.5581, 151.9517]
];

export const mainLine: Line = {
  name: 'Main Line (QLD)',
  state: 'QLD',
  segments: [
    {
      segments: [rosewoodGrandchester],
      history: {
        opened: {
          date: '1865-07-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1913-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        grandchesterSixChainsTunnel,
        {
          name: 'Six Chains Tunnel',
          segment: sixChainsTunnel,
          type: 'tunnel'
        },
        sixChainsTunnelVictoriaTunnel,
        {
          name: 'Victoria Tunnel',
          segment: victoriaTunnel,
          type: 'tunnel'
        },
        victoriaTunnelYarongmulu
      ],
      history: {
        opened: {
          date: '1866-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [yarongmuluGatton],
      history: {
        opened: {
          date: '1866-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1918-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [gattonHelidon],
      history: {
        opened: {
          date: '1866-07-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1914-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        helidonTunnel1,
        {
          name: 'Main Range No 1 Tunnel',
          segment: mainRangeNo1Tunnel,
          type: 'tunnel'
        },
        tunnel1Tunnel2,
        {
          name: 'Main Range No 2 Tunnel',
          segment: mainRangeNo2Tunnel,
          type: 'tunnel'
        },
        tunnel2Tunnel3,
        {
          name: 'Main Range No 3 Tunnel',
          segment: mainRangeNo3Tunnel,
          type: 'tunnel'
        },
        tunnel3Tunnel4,
        {
          name: 'Main Range No 4 Tunnel',
          segment: mainRangeNo4Tunnel,
          type: 'tunnel'
        },
        tunnel4Tunnel5,
        {
          name: 'Main Range No 5 Tunnel',
          segment: mainRangeNo5Tunnel,
          type: 'tunnel'
        },
        tunnel5Tunnel6,
        {
          name: 'Main Range No 6 Tunnel',
          segment: mainRangeNo6Tunnel,
          type: 'tunnel'
        },
        tunnel6Tunnel7,
        {
          name: 'Main Range No 7 Tunnel',
          segment: mainRangeNo7Tunnel,
          type: 'tunnel'
        },
        tunnel7Tunnel8,
        {
          name: 'Main Range No 8 Tunnel',
          segment: mainRangeNo8Tunnel,
          type: 'tunnel'
        },
        tunnel8Tunnel9,
        {
          name: 'Main Range No 9 Tunnel',
          segment: mainRangeNo9Tunnel,
          type: 'tunnel'
        },
        tunnel9Toowoomba
      ],
      history: {
        opened: {
          date: '1867-04-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
