import { Line } from "../../trackTypes";

const tawaFlatJunctionTawaNo1Tunnel = [
  [-41.2554, 174.7987],
  [-41.2547, 174.8015],
  [-41.2543, 174.8025],
  [-41.2539, 174.8032],
  [-41.2530, 174.8041],
  [-41.2524, 174.8046]
];

const tawaNo1Tunnel = [
  [-41.2524, 174.8046],
  [-41.2441, 174.8107],
  [-41.2427, 174.8117]
];

const tawaNo1TunnelTawaNo2Tunnel = [
  [-41.2427, 174.8117],
  [-41.2419, 174.8122],
  [-41.2405, 174.8126]
];

const tawaNo2Tunnel = [
  [-41.2405, 174.8126],
  [-41.2019, 174.8200]
];

const tawaNo2TunnelTawa = [
  [-41.2019, 174.8200],
  [-41.2003, 174.8204],
  [-41.1987, 174.8214],
  [-41.1946, 174.8260],
  [-41.1905, 174.8293],
  [-41.1894, 174.8296],
  [-41.1880, 174.8295],
  [-41.1847, 174.8280],
  [-41.1839, 174.8274],
  [-41.1830, 174.8265],
  [-41.1818, 174.8259],
  [-41.1804, 174.8259],
  [-41.1771, 174.8268],
  [-41.1720, 174.8275],
  [-41.1693, 174.8283]
];

const tawaDeviationEnd = [
  [-41.1693, 174.8283],
  [-41.1654, 174.8293]
];

const tawaFlatDeviationEndPorirua = [
  [-41.1654, 174.8293],
  [-41.1581, 174.8323],
  [-41.1531, 174.8338],
  [-41.1512, 174.8348],
  [-41.1497, 174.8374],
  [-41.1491, 174.8398],
  [-41.1482, 174.8418],
  [-41.1468, 174.8430],
  [-41.1454, 174.8434],
  [-41.1375, 174.8434]
];

const poriruaMana = [
  [-41.1375, 174.8434],
  [-41.1312, 174.8435],
  [-41.1294, 174.8438],
  [-41.1275, 174.8452],
  [-41.1163, 174.8581],
  [-41.1145, 174.8595],
  [-41.1109, 174.8610],
  [-41.1094, 174.8619],
  [-41.1082, 174.8633],
  [-41.1063, 174.8662],
  [-41.1063, 174.8662],
  [-41.1052, 174.8676],
  [-41.1030, 174.8688],
  [-41.1015, 174.8689],
  [-41.0952, 174.8682]
];

const manaPlimmerton = [
  [-41.0952, 174.8682],
  [-41.0938, 174.8680],
  [-41.0926, 174.8676],
  [-41.0912, 174.8667],
  [-41.0898, 174.8664],
  [-41.0889, 174.8666],
  [-41.0873, 174.8676],
  [-41.0855, 174.8677],
  [-41.0838, 174.8671]
];

const plimmertonPukeruaBay = [
  [-41.0838, 174.8671],
  [-41.0820, 174.8664],
  [-41.0805, 174.8662],
  [-41.0792, 174.8665],
  [-41.0770, 174.8678],
  [-41.0760, 174.8686],
  [-41.0754, 174.8696],
  [-41.0746, 174.8715],
  [-41.0739, 174.8724],
  [-41.0730, 174.8730],
  [-41.0688, 174.8744],
  [-41.0678, 174.8745],
  [-41.0669, 174.8743],
  [-41.0662, 174.8744],
  [-41.0652, 174.8749],
  [-41.0640, 174.8749],
  [-41.0587, 174.8721],
  [-41.0576, 174.8720],
  [-41.0564, 174.8724],
  [-41.0552, 174.8736],
  [-41.0537, 174.8749],
  [-41.0526, 174.8754],
  [-41.0519, 174.8753],
  [-41.0508, 174.8751],
  [-41.0495, 174.8753],
  [-41.0486, 174.8760],
  [-41.0440, 174.8826],
  [-41.0431, 174.8833],
  [-41.0423, 174.8835],
  [-41.0412, 174.8836],
  [-41.0401, 174.8840],
  [-41.0394, 174.8847],
  [-41.0386, 174.8860],
  [-41.0378, 174.8869]
];

const pukeruaBaySouthJunction = [
  [-41.0378, 174.8869],
  [-41.0368, 174.8876],
  [-41.0342, 174.8890],
  [-41.0333, 174.8900],
  [-41.0330, 174.8911],
  [-41.0331, 174.8922],
  [-41.0339, 174.8936],
  [-41.0343, 174.8948],
  [-41.0342, 174.8960],
  [-41.0336, 174.8969],
  [-41.0330, 174.8974],
  [-41.0323, 174.8975],
  [-41.0316, 174.8974],
  [-41.0309, 174.8976],
  [-41.0303, 174.8982],
  [-41.0300, 174.8988]
];

const southJunctionPukeruaTunnel = [
  [-41.0300, 174.8988],
  [-41.0297, 174.8995],
  [-41.0293, 174.9001],
  [-41.0288, 174.9005],
  [-41.0283, 174.9007],
  [-41.0278, 174.9011],
  [-41.0269, 174.9021],
  [-41.0264, 174.9028],
  [-41.0251, 174.9050]
];

const pukeruaTunnel = [
  [-41.0251, 174.9050],
  [-41.0242, 174.9065]
];

const pukeruaTunnelStKildaTunnel = [
  [-41.0242, 174.9065],
  [-41.0237, 174.9074]
];

const stKildaTunnel = [
  [-41.0237, 174.9074],
  [-41.0220, 174.9100]
];

const stKildaTunnelSeaviewTunnel = [
  [-41.0220, 174.9100],
  [-41.0216, 174.9106]
];

const seaviewTunnel = [
  [-41.0216, 174.9106],
  [-41.0203, 174.9122]
];

const seaviewTunnelBrightonTunnel = [
  [-41.0203, 174.9122],
  [-41.0197, 174.9129],
  [-41.0195, 174.9131]
];

const brightonTunnel = [
  [-41.0195, 174.9131],
  [-41.0180, 174.9152]
];

const brightonTunnelNeptuneTunnel = [
  [-41.0180, 174.9152],
  [-41.0173, 174.9159],
  [-41.0164, 174.9164],
  [-41.0159, 174.9170],
  [-41.0147, 174.9189],
  [-41.0137, 174.9200],
  [-41.0122, 174.9212],
  [-41.0109, 174.9227],
  [-41.0100, 174.9234]
];

const neptuneTunnel = [
  [-41.0100, 174.9234],
  [-41.0096, 174.9237]
];

const neptuneTunnelNorthJunction = [
  [-41.0096, 174.9237],
  [-41.0091, 174.9241],
  [-41.0086, 174.9247],
  [-41.0082, 174.9252]
];

const northJunctionPaekakariki = [
  [-41.0082, 174.9252],
  [-41.0071, 174.9264],
  [-41.0062, 174.9270],
  [-41.0056, 174.9276],
  [-41.0051, 174.9286],
  [-41.0043, 174.9309],
  [-41.0039, 174.9325],
  [-40.9983, 174.9429],
  [-40.9968, 174.9444],
  [-40.9920, 174.9479],
  [-40.9899, 174.9501],
  [-40.9882, 174.9526],
  [-40.9868, 174.9548]
];

const paekakarikiMackaysCrossing = [
  [-40.9868, 174.9548],
  [-40.9848, 174.9582],
  [-40.9831, 174.9602],
  [-40.9692, 174.9834],
  [-40.9681, 174.9848],
  [-40.9662, 174.9861]
];

const mackaysCrossingParaparaumu = [
  [-40.9662, 174.9861],
  [-40.9641, 174.9867],
  [-40.9526, 174.9891],
  [-40.9439, 174.9917],
  [-40.9272, 174.9988],
  [-40.9247, 175.0003],
  [-40.9160, 175.0078]
];

const paraparaumuWaikanaeRiverBridge = [
  [-40.9160, 175.0078],
  [-40.9075, 175.0153],
  [-40.8956, 175.0256],
  [-40.8938, 175.0275],
  [-40.8903, 175.0316],
  [-40.8886, 175.0344],
  [-40.8877, 175.0374],
  [-40.8873, 175.0409],
  [-40.8875, 175.0440],
  [-40.8869, 175.0483],
  [-40.8843, 175.0571],
  [-40.8836, 175.0598]
];

const waikanaeRiverBridgeWaikanae = [
  [-40.8836, 175.0598],
  [-40.8831, 175.0616],
  [-40.8826, 175.0627],
  [-40.8821, 175.0634],
  [-40.8814, 175.0640],
  [-40.8805, 175.0644],
  [-40.8791, 175.0646],
  [-40.8782, 175.0649],
  [-40.8765, 175.0662]
];

export const kapiti: Line = {
  name: 'Kapiti',
  state: 'NZ',
  segments: [
    {
      segments: [
        tawaFlatJunctionTawaNo1Tunnel,
        {
          name: 'Tawa No 1 Tunnel',
          segment: tawaNo1Tunnel,
          type: 'tunnel'
        },
        tawaNo1TunnelTawaNo2Tunnel,
        {
          name: 'Tawa No 2 Tunnel',
          segment: tawaNo2Tunnel,
          type: 'tunnel'
        },
        tawaNo2TunnelTawa
      ],
      history: {
        opened: {
          date: '1935-07-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-06-19',
          tracks: 2
        }, {
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [tawaDeviationEnd],
      history: {
        opened: {
          date: '1935-07-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-12-15',
          tracks: 2
        }, {
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [tawaFlatDeviationEndPorirua],
      history: {
        opened: {
          date: '1885-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-12-15',
          tracks: 2
        }, {
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [poriruaMana],
      history: {
        opened: {
          date: '1885-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-11-07',
          tracks: 2
        }]
      }
    },
    {
      segments: [manaPlimmerton],
      history: {
        opened: {
          date: '1885-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-10-16',
          tracks: 2
        }]
      }
    },
    {
      segments: [plimmertonPukeruaBay],
      history: {
        opened: {
          date: '1885-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1940-02-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [pukeruaBaySouthJunction],
      history: {
        opened: {
          date: '1886-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1940-02-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        southJunctionPukeruaTunnel,
        {
          name: 'Pukerua Tunnel',
          segment: pukeruaTunnel,
          type: 'tunnel'
        },
        pukeruaTunnelStKildaTunnel,
        {
          name: 'St Kilda Tunnel',
          segment: stKildaTunnel,
          type: 'tunnel'
        },
        stKildaTunnelSeaviewTunnel,
        {
          name: 'Seaview Tunnel',
          segment: seaviewTunnel,
          type: 'tunnel'
        },
        seaviewTunnelBrightonTunnel,
        {
          name: 'Brighton Tunnel',
          segment: brightonTunnel,
          type: 'tunnel'
        },
        brightonTunnelNeptuneTunnel,
        {
          name: 'Neptune Tunnel',
          segment: neptuneTunnel,
          type: 'tunnel'
        },
        neptuneTunnelNorthJunction
      ],
      history: {
        opened: {
          date: '1886-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [northJunctionPaekakariki],
      history: {
        opened: {
          date: '1886-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1983-12-05',
          tracks: 2
        }]
      }
    },
    {
      segments: [paekakarikiMackaysCrossing],
      history: {
        opened: {
          date: '1886-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-05-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1983-12-05',
          tracks: 2
        }]
      }
    },
    {
      segments: [mackaysCrossingParaparaumu],
      history: {
        opened: {
          date: '1886-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-05-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2011-02-20',
          tracks: 2
        }]
      }
    },
    {
      segments: [paraparaumuWaikanaeRiverBridge],
      history: {
        opened: {
          date: '1886-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-02-20',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2011-02-20',
          tracks: 2
        }]
      }
    },
    {
      segments: [waikanaeRiverBridgeWaikanae],
      history: {
        opened: {
          date: '1886-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-02-20',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
