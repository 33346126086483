import { Line } from "../../trackTypes";

const ulverstoneWharf = [
  [-41.1586, 146.1736],
  [-41.1586, 146.1728],
  [-41.1582, 146.1696],
  [-41.1578, 146.1691],
  [-41.1572, 146.1690],
  [-41.1557, 146.1695]
];

const launcestonWharf = [
  //original wharf opened 1872 near george town road
  //o1895-06-25 //tamar street bridge side
  //extended in 1916 to kings wharf, on 1917-12-08
  //opened with new connection to the ne line
  //c1987-06-15
];

const latrobeWharf = [
  //o 1893-05 as horse tramway
  //c1900

];

export const tasWharfLines: Line = {
  name: 'Tas Wharf Lines',
  state: 'TAS',
  segments: [
    {
      segments: [ulverstoneWharf],
      history: {
        opened: {
          date: '1921-05-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-12-06',
          status: 'closed'
        }]
      }
    }
  ]
}
