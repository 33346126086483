import { Line } from "../../trackTypes";

const flagstaffParliment = [
  [-37.8131, 144.9516],
  [-37.8118, 144.9561],
  [-37.8080, 144.9693],
  [-37.8080, 144.9707],
  [-37.8085, 144.9717],
  [-37.8113, 144.9730],
  [-37.8144, 144.9744]
];

const parlimentExit1 = [
  [-37.8144, 144.9744],
  [-37.8148, 144.9748],
  [-37.8153, 144.9755],
  [-37.8157, 144.9768]
];

const parlimentExit2 = [
  [-37.8144, 144.9744],
  [-37.8151, 144.9743],
  [-37.8156, 144.9740],
  [-37.8162, 144.9734]
]

const parlimentExit3 = [
  [-37.8144, 144.9744],
  [-37.8160, 144.9753],
  [-37.8179, 144.9768]
];

const flagstaffExit1 = [
  [-37.8131, 144.9516],
  [-37.8135, 144.9497],
  [-37.8134, 144.9487],
  [-37.8130, 144.9474]
];

const flagstaffExit2 = [
  [-37.8131, 144.9516],
  [-37.8137, 144.9506],
  [-37.8147, 144.9500],
  [-37.8159, 144.9499]
];

const flindersStreetSouthernCross = [
  [-37.8189, 144.9642],
  [-37.8196, 144.9613],
  [-37.8210, 144.9573],
  [-37.8213, 144.9564],
  [-37.8213, 144.9555],
  [-37.8211, 144.9546],
  [-37.8207, 144.9539],
  [-37.8201, 144.9532],
  [-37.8182, 144.9518]
];

const flindersStreetPortMelbounreJunction = [
  [-37.8182, 144.9671],
  [-37.8189, 144.9642]
];

export const cityLoop: Line = {
  name: 'City Loop',
  state: 'VIC',
  segments: [
    {
      segments: [{
        segment: flagstaffExit1,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1981-01-24',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: flagstaffExit2,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1981-01-24',
          tracks: 3,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: flagstaffParliment,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1981-01-24',
          tracks: 4,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: parlimentExit1,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1981-01-24',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: parlimentExit2,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1981-01-24',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: parlimentExit3,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1981-01-24',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [flindersStreetSouthernCross],
      history: {
        opened: {
          date: '1879-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1891-12-20',
          tracks: 2
        }, {
          date: '1917-12-02',
          tracks: 4
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1978-12-11',
          tracks: 6
        }]
      }
    },
    {
      segments: [flindersStreetPortMelbounreJunction],
      history: {
        opened: {
          date: '1854-09-13',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1857-05-13',
          tracks: 4
        }, {
          date: '1919-10-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1978-12-11',
          tracks: 6
        }]
      }
    }
  ]
};
