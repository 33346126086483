import { Line } from "../../../trackTypes";

/**
 * - mullumbimby
 * fords gap tunnel
 * morrisons hill tunnel
 * haynes hill tunnel
 * marshals hill tunnel
 * bamberry hill tunnel
 * hull hill tunnel
 * burringbar range tunnel
 */

const casinoNaughtonsGapTunnel = [
  [-28.8544, 153.0447],
  [-28.8562, 153.0562],
  [-28.8561, 153.0585],
  [-28.8554, 153.0603],
  [-28.8540, 153.0621],
  [-28.8477, 153.0677],
  [-28.8471, 153.0684],
  [-28.8461, 153.0699],
  [-28.8444, 153.0716],
  [-28.8407, 153.0734],
  [-28.8396, 153.0734],
  [-28.8380, 153.0734],
  [-28.8325, 153.0751],
  [-28.8300, 153.0763],
  [-28.8291, 153.0774],
  [-28.8227, 153.0875],
  [-28.8214, 153.0885],
  [-28.8093, 153.0932],
  [-28.8084, 153.0937],
  [-28.8055, 153.0960],
  [-28.8042, 153.0964],
  [-28.8029, 153.0963],
  [-28.8015, 153.0957],
  [-28.7987, 153.0936],
  [-28.7973, 153.0931],
  [-28.7962, 153.0931]
];

const naughtonsGapTunnel = [
  [-28.7962, 153.0931],
  [-28.7952, 153.0935],
  [-28.7942, 153.0944]
];

const naughtonsGapTunnelLismore = [
  [-28.7942, 153.0944],
  [-28.7853, 153.1023],
  [-28.7844, 153.1036],
  [-28.7827, 153.1068],
  [-28.7822, 153.1081],
  [-28.7819, 153.1142],
  [-28.7814, 153.1166],
  [-28.7776, 153.1255],
  [-28.7766, 153.1287],
  [-28.7764, 153.1305],
  [-28.7766, 153.1322],
  [-28.7798, 153.1452],
  [-28.7838, 153.1555],
  [-28.7843, 153.1578],
  [-28.7848, 153.1619],
  [-28.7853, 153.1632],
  [-28.7860, 153.1643],
  [-28.7882, 153.1667],
  [-28.7888, 153.1678],
  [-28.7891, 153.1691],
  [-28.7894, 153.1780],
  [-28.7890, 153.1805],
  [-28.7871, 153.1849],
  [-28.7860, 153.1864],
  [-28.7836, 153.1885],
  [-28.7826, 153.1900],
  [-28.7807, 153.1960],
  [-28.7806, 153.1977],
  [-28.7810, 153.1993],
  [-28.7819, 153.2008],
  [-28.7883, 153.2062],
  [-28.7892, 153.2066],
  [-28.7899, 153.2067],
  [-28.7952, 153.2063],
  [-28.7967, 153.2067],
  [-28.7982, 153.2082],
  [-28.7989, 153.2098],
  [-28.7990, 153.2112],
  [-28.7978, 153.2197],
  [-28.7978, 153.2208],
  [-28.7996, 153.2296],
  [-28.8047, 153.2394],
  [-28.8050, 153.2407],
  [-28.8053, 153.2471],
  [-28.8055, 153.2481],
  [-28.8096, 153.2582],
  [-28.8099, 153.2595],
  [-28.8109, 153.2666],
  [-28.8109, 153.2672],
  [-28.8106, 153.2681],
  [-28.8101, 153.2690]
];

const lismoreBooyongTunnel = [
  [-28.8101, 153.2690],
  [-28.8079, 153.2726],
  [-28.8071, 153.2732],
  [-28.8061, 153.2732],
  [-28.8053, 153.2729],
  [-28.8044, 153.2724],
  [-28.8015, 153.2709],
  [-28.8002, 153.2707],
  [-28.7988, 153.2712],
  [-28.7979, 153.2723],
  [-28.7975, 153.2734],
  [-28.7961, 153.2797],
  [-28.7950, 153.2819],
  [-28.7851, 153.2949],
  [-28.7845, 153.2953],
  [-28.7838, 153.2953],
  [-28.7827, 153.2952],
  [-28.7817, 153.2956],
  [-28.7809, 153.2965],
  [-28.7807, 153.2980],
  [-28.7813, 153.3000],
  [-28.7825, 153.3016],
  [-28.7829, 153.3030],
  [-28.7828, 153.3044],
  [-28.7821, 153.3057],
  [-28.7810, 153.3072],
  [-28.7799, 153.3098],
  [-28.7747, 153.3260],
  [-28.7703, 153.3362],
  [-28.7689, 153.3410],
  [-28.7679, 153.3424],
  [-28.7654, 153.3448],
  [-28.7619, 153.3464],
  [-28.7609, 153.3477],
  [-28.7606, 153.3492],
  [-28.7607, 153.3512],
  [-28.7602, 153.3532],
  [-28.7591, 153.3545],
  [-28.7581, 153.3555],
  [-28.7572, 153.3573],
  [-28.7559, 153.3778],
  [-28.7553, 153.3801],
  [-28.7547, 153.3812],
  [-28.7545, 153.3824],
  [-28.7552, 153.3858],
  [-28.7557, 153.3923],
  [-28.7555, 153.3942],
  [-28.7539, 153.4004],
  [-28.7537, 153.4024],
  [-28.7542, 153.4062],
  [-28.7541, 153.4088],
  [-28.7513, 153.4183],
  [-28.7510, 153.4203],
  [-28.7510, 153.4226],
  [-28.7507, 153.4258],
  [-28.7490, 153.4324]
];

const booyongTunnel = [
  [-28.7490, 153.4324],
  [-28.7487, 153.4335]
];

const booyongTunnelByronBayStart = [
  [-28.7487, 153.4335],
  [-28.7476, 153.4377],
  [-28.7476, 153.4395],
  [-28.7483, 153.4434],
  [-28.7484, 153.4464],
  [-28.7479, 153.4479],
  [-28.7472, 153.4490],
  [-28.7455, 153.4502],
  [-28.7443, 153.4505],
  [-28.7430, 153.4500],
  [-28.7401, 153.4480],
  [-28.7378, 153.4461],
  [-28.7367, 153.4458],
  [-28.7353, 153.4463],
  [-28.7344, 153.4476],
  [-28.7343, 153.4496],
  [-28.7375, 153.4552],
  [-28.7381, 153.4579],
  [-28.7376, 153.4604],
  [-28.7364, 153.4626],
  [-28.7346, 153.4638],
  [-28.7270, 153.4667],
  [-28.7253, 153.4681],
  [-28.7219, 153.4732],
  [-28.7211, 153.4740],
  [-28.7194, 153.4748],
  [-28.7186, 153.4760],
  [-28.7186, 153.4774],
  [-28.7193, 153.4785],
  [-28.7217, 153.4814],
  [-28.7222, 153.4829],
  [-28.7217, 153.4845],
  [-28.7191, 153.4872],
  [-28.7181, 153.4876],
  [-28.7172, 153.4874],
  [-28.7144, 153.4862],
  [-28.7130, 153.4862],
  [-28.7120, 153.4869],
  [-28.7058, 153.4968],
  [-28.7050, 153.4977],
  [-28.7037, 153.4984],
  [-28.7029, 153.4996],
  [-28.7027, 153.5011],
  [-28.7025, 153.5032],
  [-28.7018, 153.5043],
  [-28.6986, 153.5069],
  [-28.6936, 153.5145],
  [-28.6922, 153.5160],
  [-28.6888, 153.5189],
  [-28.6880, 153.5200],
  [-28.6868, 153.5223],
  [-28.6859, 153.5233],
  [-28.6836, 153.5249],
  [-28.6829, 153.5256],
  [-28.6800, 153.5303],
  [-28.6783, 153.5341],
  [-28.6781, 153.5363],
  [-28.6787, 153.5379],
  [-28.6803, 153.5401],
  [-28.6808, 153.5425],
  [-28.6804, 153.5459],
  [-28.6800, 153.5471],
  [-28.6793, 153.5476],
  [-28.6781, 153.5483],
  [-28.6774, 153.5494],
  [-28.6773, 153.5507],
  [-28.6774, 153.5544],
  [-28.6770, 153.5561],
  [-28.6758, 153.5576],
  [-28.6751, 153.5596],
  [-28.6734, 153.5615],
  [-28.6711, 153.5622],
  [-28.6700, 153.5634],
  [-28.6695, 153.5649],
  [-28.6701, 153.5684],
  [-28.6698, 153.5696],
  [-28.6692, 153.5703],
  [-28.6678, 153.5713],
  [-28.6665, 153.5732],
  [-28.6661, 153.5746],
  [-28.6662, 153.5769],
  [-28.6677, 153.5818],
  [-28.6678, 153.5837],
  [-28.6683, 153.5850],
  [-28.6696, 153.5861],
  [-28.6705, 153.5874],
  [-28.6715, 153.5883],
  [-28.6721, 153.5892],
  [-28.6726, 153.5904],
  [-28.6741, 153.5925],
  [-28.6753, 153.5957],
  [-28.6754, 153.5980],
  [-28.6747, 153.5989],
  [-28.6732, 153.5998],
  [-28.6726, 153.6007],
  [-28.6724, 153.6020],
  [-28.6717, 153.6031],
  [-28.6708, 153.6039],
  [-28.6703, 153.6046],
  [-28.6698, 153.6058],
  [-28.6683, 153.6073],
  [-28.6672, 153.6080],
  [-28.6663, 153.6094],
  [-28.6653, 153.6115],
  [-28.6639, 153.6129],
  [-28.6623, 153.6133],
  [-28.6495, 153.6121],
  [-28.6425, 153.6113],
  [-28.6417, 153.6111]
];

const byronBayLine = [
  [-28.6417, 153.6111],
  [-28.6409, 153.6105],
  [-28.6405, 153.6098],
  [-28.6378, 153.6037],
  [-28.6293, 153.5845]
];

const ByronBayEndMullumbimby = [
  [-28.6293, 153.5845],
  [-28.6177, 153.5587],
  [-28.6166, 153.5572],
  [-28.5900, 153.5327],
  [-28.5882, 153.5318],
  [-28.5867, 153.5307],
  [-28.5859, 153.5293],
  [-28.5805, 153.5119],
  [-28.5796, 153.5105],
  [-28.5783, 153.5095],
  [-28.5579, 153.5026],
  [-28.5561, 153.5025],
  [-28.5541, 153.5029]
];

const mullumbimbyFordsGapTunnel = [
  [-28.5541, 153.5029],
  [-28.5435, 153.5047],
  [-28.5420, 153.5045],
  [-28.5404, 153.5040],
  [-28.5389, 153.5044],
  [-28.5377, 153.5060],
  [-28.5376, 153.5063]
];

const fordsGapTunnel = [
  [-28.5376, 153.5063],
  [-28.5372, 153.5071]
];

const fordsGapTunnelMorrisonsHillTunnel = [
  [-28.5372, 153.5071],
  [-28.5370, 153.5078],
  [-28.5362, 153.5086],
  [-28.5326, 153.5108],
  [-28.5316, 153.5119],
  [-28.5311, 153.5130],
  [-28.5305, 153.5153],
  [-28.5293, 153.5166],
  [-28.5278, 153.5170],
  [-28.5240, 153.5170]
];

const morrisonsHillTunnel = [
  [-28.5240, 153.5170],
  [-28.5227, 153.5170],
  [-28.5208, 153.5174]
];

const morrisonsHillTunnelHaynesHillTunnel = [
  [-28.5208, 153.5174],
  [-28.5184, 153.5183],
  [-28.5174, 153.5196],
  [-28.5166, 153.5218],
  [-28.5162, 153.5243],
  [-28.5154, 153.5256],
  [-28.5130, 153.5282],
  [-28.5117, 153.5287],
  [-28.5113, 153.5288]
];

const haynesHillTunnel = [
  [-28.5113, 153.5288],
  [-28.5103, 153.5288]
];

const haynesHillTunnelMarshalsHillTunnel = [
  [-28.5103, 153.5288],
  [-28.5020, 153.5270],
  [-28.4992, 153.5265],
  [-28.4971, 153.5253],
  [-28.4941, 153.5205],
  [-28.4933, 153.5191]
];

const marshalsHillTunnel = [
  [-28.4933, 153.5191],
  [-28.4926, 153.5182]
];

const marshalsHillTunnelBamberryHillTunnel = [
  [-28.4926, 153.5182],
  [-28.4921, 153.5179],
  [-28.4901, 153.5175],
  [-28.4892, 153.5167],
  [-28.4869, 153.5138],
  [-28.4853, 153.5129],
  [-28.4834, 153.5128],
  [-28.4806, 153.5128],
  [-28.4796, 153.5123],
  [-28.4790, 153.5113],
  [-28.4784, 153.5098],
  [-28.4775, 153.5087],
  [-28.4747, 153.5074],
  [-28.4741, 153.5068]
];

const bamberryHillTunnel = [
  [-28.4741, 153.5068],
  [-28.4726, 153.5059]
];

const bamberryHillTunnelHullHillTunnel = [
  [-28.4726, 153.5059],
  [-28.4720, 153.5057],
  [-28.4704, 153.5051],
  [-28.4668, 153.5057],
  [-28.4653, 153.5055],
  [-28.4641, 153.5048],
  [-28.4632, 153.5045],
  [-28.4618, 153.5043],
  [-28.4606, 153.5036],
  [-28.4591, 153.5019],
  [-28.4561, 153.5002],
  [-28.4543, 153.5000],
  [-28.4526, 153.5004],
  [-28.4505, 153.5015]
];

const hullHillTunnel = [
  [-28.4505, 153.5015],
  [-28.4501, 153.5017]
];

const hullHillTunnelBurringbarRangeTunnel = [
  [-28.4501, 153.5017],
  [-28.4497, 153.5019],
  [-28.4481, 153.5022],
  [-28.4468, 153.5015],
  [-28.4421, 153.4966],
  [-28.4414, 153.4951],
  [-28.4412, 153.4935],
  [-28.4417, 153.4869],
  [-28.4415, 153.4848],
  [-28.4400, 153.4802],
  [-28.4390, 153.4790],
  [-28.4371, 153.4781],
  [-28.4361, 153.4772],
  [-28.4340, 153.4739],
  [-28.4336, 153.4727],
  [-28.4336, 153.4717],
  [-28.4348, 153.4668],
  [-28.4354, 153.4657],
  [-28.4373, 153.4630],
  [-28.4379, 153.4616],
  [-28.4411, 153.4502],
  [-28.4417, 153.4490],
  [-28.4422, 153.4483],
  [-28.4433, 153.4473],
  [-28.4439, 153.4462],
  [-28.4444, 153.4449],
  [-28.4446, 153.4398],
  [-28.4444, 153.4382],
  [-28.4434, 153.4364],
  [-28.4429, 153.4359],
  [-28.4393, 153.4344],
  [-28.4381, 153.4338],
  [-28.4361, 153.4338],
  [-28.4354, 153.4336],
  [-28.4334, 153.4325]
];

const burringbarRangeTunnel = [
  [-28.4334, 153.4325],
  [-28.4295, 153.4297]
];

const burringbarRangeTunnelMurwillumbah = [
  [-28.4295, 153.4297],
  [-28.4287, 153.4293],
  [-28.4250, 153.4281],
  [-28.4241, 153.4281],
  [-28.4221, 153.4285],
  [-28.4210, 153.4285],
  [-28.4200, 153.4280],
  [-28.4183, 153.4267],
  [-28.4173, 153.4263],
  [-28.4147, 153.4262],
  [-28.4133, 153.4257],
  [-28.4081, 153.4219],
  [-28.4074, 153.4209],
  [-28.4069, 153.4195],
  [-28.4069, 153.4184],
  [-28.4073, 153.4163],
  [-28.4073, 153.4151],
  [-28.4069, 153.4135],
  [-28.4059, 153.4122],
  [-28.4050, 153.4118],
  [-28.4034, 153.4117],
  [-28.4025, 153.4114],
  [-28.4019, 153.4108],
  [-28.4015, 153.4099],
  [-28.4012, 153.4088],
  [-28.4010, 153.4081],
  [-28.4006, 153.4075],
  [-28.3964, 153.4041],
  [-28.3960, 153.4039],
  [-28.3928, 153.4032],
  [-28.3921, 153.4028],
  [-28.3900, 153.4008],
  [-28.3892, 153.4003],
  [-28.3883, 153.4000],
  [-28.3876, 153.4000],
  [-28.3824, 153.4013],
  [-28.3818, 153.4013],
  [-28.3751, 153.4009],
  [-28.3717, 153.4000],
  [-28.3707, 153.3994],
  [-28.3703, 153.3988],
  [-28.3701, 153.3983],
  [-28.3699, 153.3971],
  [-28.3683, 153.3945],
  [-28.3676, 153.3939],
  [-28.3664, 153.3933],
  [-28.3657, 153.3928],
  [-28.3646, 153.3918],
  [-28.3637, 153.3917],
  [-28.3628, 153.3920],
  [-28.3620, 153.3927],
  [-28.3610, 153.3951],
  [-28.3602, 153.3960],
  [-28.3574, 153.3980],
  [-28.3549, 153.3992],
  [-28.3540, 153.4000],
  [-28.3530, 153.4012],
  [-28.3522, 153.4016],
  [-28.3513, 153.4017],
  [-28.3497, 153.4012],
  [-28.3489, 153.4012],
  [-28.3456, 153.4027],
  [-28.3448, 153.4028],
  [-28.3406, 153.4025],
  [-28.3363, 153.4013],
  [-28.3348, 153.4012],
  [-28.3307, 153.4022],
  [-28.3299, 153.4026]
];

const murwillumbahCondong = [
  [-28.3299, 153.4026],
  [-28.3292, 153.4035],
  [-28.3289, 153.4043],
  [-28.3282, 153.4074],
  [-28.3216, 153.4220],
  [-28.3209, 153.4268],
  [-28.3204, 153.4279],
  [-28.3174, 153.4332],
  [-28.3166, 153.4339],
  [-28.3157, 153.4344],
  [-28.3146, 153.4349],
  [-28.3119, 153.4357]
];

export const murwillumbah: Line = {
  name: 'Murwillumbah',
  state: 'NSW',
  segments: [
    {
      segments: [
        casinoNaughtonsGapTunnel,
        {
          name: 'Naughtons Gap Tunnel',
          segment: naughtonsGapTunnel,
          type: 'tunnel'
        },
        naughtonsGapTunnelLismore
      ],
      history: {
        opened: {
          date: '1903-10-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-05-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        lismoreBooyongTunnel,
        {
          name: 'Booyong Tunnel',
          segment: booyongTunnel,
          type: 'tunnel'
        },
        booyongTunnelByronBayStart
      ],
      history: {
        opened: {
          date: '1894-05-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-05-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [byronBayLine],
      history: {
        opened: {
          date: '1894-05-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-05-16',
          status: 'closed'
        }, {
          date: '2017-12-16',
          status: 'open'
        }]
      }
    },
    {
      segments: [ByronBayEndMullumbimby],
      history: {
        opened: {
          date: '1894-05-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-05-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        mullumbimbyFordsGapTunnel,
        {
          name: 'Fords Gap Tunnel',
          segment: fordsGapTunnel,
          type: 'tunnel'
        },
        fordsGapTunnelMorrisonsHillTunnel,
        {
          name: 'Morrisons Hill Tunnel',
          segment: morrisonsHillTunnel,
          type: 'tunnel'
        },
        morrisonsHillTunnelHaynesHillTunnel,
        {
          name: 'Haynes Hill Tunnel',
          segment: haynesHillTunnel,
          type: 'tunnel'
        },
        haynesHillTunnelMarshalsHillTunnel,
        {
          name: 'Marshals Hill Tunnel',
          segment: marshalsHillTunnel,
          type: 'tunnel'
        },
        marshalsHillTunnelBamberryHillTunnel,
        {
          name: 'Bamberry Hill Tunnel',
          segment: bamberryHillTunnel,
          type: 'tunnel'
        },
        bamberryHillTunnelHullHillTunnel,
        {
          name: 'Hull Hill Tunnel',
          segment: hullHillTunnel,
          type: 'tunnel'
        },
        hullHillTunnelBurringbarRangeTunnel,
        {
          name: 'Burringbar Range Tunnel',
          segment: burringbarRangeTunnel,
          type: 'tunnel'
        },
        burringbarRangeTunnelMurwillumbah
      ],
      history: {
        opened: {
          date: '1894-12-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-05-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [murwillumbahCondong],
      history: {
        opened: {
          date: '1894-12-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1975-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
