import { Line } from "../../trackTypes";

const fairfieldApmSiding = [
  [-37.7791, 145.0206],
  [-37.7793, 145.0217],
  [-37.7795, 145.0224],
  [-37.7798, 145.0228],
  [-37.7813, 145.0245],
  [-37.7821, 145.0252],
  [-37.7828, 145.0254],
  [-37.7832, 145.0255],
  [-37.7850, 145.0254]
];

const apmSidingEastKew = [
  [-37.7850, 145.0254],
  [-37.7850, 145.0252],
  [-37.7859, 145.0254],
  [-37.7877, 145.0254],
  [-37.7887, 145.0257],
  [-37.7897, 145.0263],
  [-37.7903, 145.0270],
  [-37.7921, 145.0301],
  [-37.7945, 145.0347],
  [-37.7948, 145.0356],
  [-37.8006, 145.0540]
];

const deepdeneEastKew = [
  [-37.8098, 145.0629],
  [-37.8079, 145.0622],
  [-37.8069, 145.0616],
  [-37.8049, 145.0602],
  [-37.8029, 145.0588],
  [-37.8021, 145.0579],
  [-37.8016, 145.0569],
  [-37.8006, 145.0540]
];

const riversdalseDeepdene = [
  [-37.8279, 145.0700],
  [-37.8272, 145.0698],
  [-37.8218, 145.0674],
  [-37.8165, 145.0654],
  [-37.8098, 145.0629]
];

const alameinWaverleyRoad = [
  [-37.8691, 145.0795],
  [-37.8698, 145.0793],
  [-37.8707, 145.0789],
  [-37.8713, 145.0784],
  [-37.8749, 145.0742],
  [-37.8759, 145.0727],
  [-37.8765, 145.0719],
  [-37.8774, 145.0715],
  [-37.8782, 145.0714],
  [-37.8813, 145.0719]
];

const eastMalvernWaverleyRoad = [
  [-37.8768, 145.0692],
  [-37.8771, 145.0699],
  [-37.8775, 145.0704],
  [-37.8784, 145.0712],
  [-37.8793, 145.07157]
];

const waverleyRoadOakley = [
  [-37.8813, 145.0719],
  [-37.8925, 145.0750],
  [-37.8933, 145.0753],
  [-37.8940, 145.0759]
];

export const outerCircle: Line = {
  name: 'Outer Circle',
  state: 'VIC',
  segments: [
    {
      segments: [fairfieldApmSiding],
      history: {
        opened: {
          date: '1891-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1919-07-27',
          status: 'open'
        }, {
          date: '1893-03-12',
          status: 'closed'
        }, {
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [apmSidingEastKew],
      history: {
        opened: {
          date: '1891-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1893-03-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [deepdeneEastKew],
      history: {
        opened: {
          date: '1891-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1893-04-12',
          status: 'closed'
        }, {
          date: '1943-09-07',
          status: 'closed'
        }, {
          date: '1925-02-11',
          status: 'open'
        }]
      }
    },
    {
      segments: [riversdalseDeepdene],
      history: {
        opened: {
          date: '1891-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1943-09-07',
          status: 'closed'
        }, {
          date: '1893-04-12',
          status: 'closed'
        }, {
          date: '1900-05-14',
          status: 'open'
        }]
      }
    },
    {
      segments: [alameinWaverleyRoad],
      history: {
        opened: {
          date: '1890-05-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1895-12-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eastMalvernWaverleyRoad],
      history: {
        opened: {
          date: '1890-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1895-12-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waverleyRoadOakley],
      history: {
        opened: {
          date: '1890-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1895-12-09',
          status: 'closed'
        }]
      }
    }
  ]
};
