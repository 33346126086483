import { Line } from "../../trackTypes";

const line = [
  [-42.3455, 147.4083],
  [-42.3450, 147.4084],
  [-42.3445, 147.4084],
  [-42.3419, 147.4081],
  [-42.3412, 147.4078],
  [-42.3407, 147.4071],
  [-42.3389, 147.4034],
  [-42.3383, 147.4029],
  [-42.3339, 147.4010],
  [-42.3332, 147.4001],
  [-42.3321, 147.3975],
  [-42.3312, 147.3964],
  [-42.3297, 147.3957],
  [-42.3274, 147.3929],
  [-42.3223, 147.3860],
  [-42.3217, 147.3855],
  [-42.3210, 147.3854],
  [-42.3206, 147.3851],
  [-42.3200, 147.3845],
  [-42.3191, 147.3844],
  [-42.3185, 147.3837],
  [-42.3183, 147.3828],
  [-42.3179, 147.3822],
  [-42.3175, 147.3817],
  [-42.3174, 147.3808],
  [-42.3178, 147.3795],
  [-42.3178, 147.3781],
  [-42.3172, 147.3769],
  [-42.3166, 147.3766],
  [-42.3132, 147.3762],
  [-42.3127, 147.3761],
  [-42.3121, 147.3757],
  [-42.3113, 147.3747],
  [-42.3109, 147.3746],
  [-42.3104, 147.3748],
  [-42.3099, 147.3752],
  [-42.3093, 147.3755],
  [-42.3083, 147.3756],
  [-42.3081, 147.3756],
  [-42.3078, 147.3753],
  [-42.3067, 147.3735],
  [-42.3064, 147.3729],
  [-42.3061, 147.3715],
  [-42.3056, 147.3709],
  [-42.3050, 147.3707],
  [-42.3041, 147.3710],
  [-42.3035, 147.3709],
  [-42.3015, 147.3694]
];

export const oatlands: Line = {
  name: 'Oatlands',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1885-05-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-06-10',
          status: 'closed'
        }]
      }
    }
  ]
}
