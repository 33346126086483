import { Line } from "../../trackTypes";

const islingtonJunctionCarringtonYard = [
  [-32.9106, 151.7401],
  [-32.9100, 151.7398],
  [-32.9095, 151.7398],
  [-32.9091, 151.7399],
  [-32.9087, 151.7402],
  [-32.9083, 151.7408],
  [-32.9060, 151.7470],
  [-32.9036, 151.7521],
  [-32.9033, 151.7530],
  [-32.9024, 151.7539],
  [-32.9018, 151.7542],
  [-32.9010, 151.7544],
  [-32.9003, 151.7548],
  [-32.8998, 151.7557],
  [-32.8990, 151.7592],
  [-32.8987, 151.7607],
  [-32.8989, 151.7662],
  [-32.8990, 151.7671],
  [-32.8991, 151.7678],
  [-32.8995, 151.7686],
  [-32.9003, 151.7692],
  [-32.9010, 151.7694],
  [-32.9123, 151.7710],
  [-32.9143, 151.7717],
  [-32.9175, 151.7729]
];

const loop = [
  [-32.9033, 151.7530],
  [-32.9030, 151.7547],
  [-32.9024, 151.7631],
  [-32.9026, 151.7645],
  [-32.9025, 151.7660],
  [-32.9020, 151.7670],
  [-32.9013, 151.7676],
  [-32.9005, 151.7678],
  [-32.8998, 151.7675],
  [-32.8993, 151.7670],
  [-32.8989, 151.7662]
];

const islingtonWestJunctionScholeyStreetJunction = [
  [-32.9081, 151.7374],
  [-32.9086, 151.7382],
  [-32.9087, 151.7388],
  [-32.9087, 151.7397],
  [-32.9083, 151.7408]
];

const morandooBhpSteelworks = [
  [-32.8990, 151.7592],
  [-32.8986, 151.7597],
  [-32.8976, 151.7607],
  [-32.8968, 151.7610],
  [-32.8960, 151.7608],
  [-32.8942, 151.7589],
  [-32.8938, 151.7582],
  [-32.8923, 151.7488],
  [-32.8922, 151.7484],
  [-32.8919, 151.7478],
  [-32.8918, 151.7473],
  [-32.8914, 151.7461],
  [-32.8911, 151.7456],
  [-32.8907, 151.7453],
  [-32.8905, 151.7448]
];

export const portWaratah: Line = {
  name: 'Port Waratah',
  state: 'NSW',
  segments: [
    {
      segments: [islingtonJunctionCarringtonYard, loop, islingtonWestJunctionScholeyStreetJunction],
      history: {
        opened: {
          date: '1908-04-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [morandooBhpSteelworks],
      history: {
        opened: {
          date: '1968-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1999-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
