import { Line } from "../../../trackTypes";

const hornbySpringsRoad = [
  [-43.5410, 172.5283],
  [-43.5412, 172.5275],
  [-43.5415, 172.5269],
  [-43.5421, 172.5264],
  [-43.5467, 172.5244],
  [-43.5649, 172.5187]
];

const springsRoadPrebbleton = [
  [-43.5649, 172.5187],
  [-43.5684, 172.5176],
  [-43.5711, 172.5174],
  [-43.5729, 172.5171],
  [-43.5795, 172.5148],
  [-43.5801, 172.5145]
];

const prebbletonLincoln = [
  [-43.5801, 172.5145],
  [-43.5913, 172.5108],
  [-43.5919, 172.5107],
  [-43.6004, 172.5121],
  [-43.6043, 172.5136],
  [-43.6081, 172.5136],
  [-43.6086, 172.5135],
  [-43.6230, 172.5008],
  [-43.6260, 172.4970],
  [-43.6340, 172.4909],
  [-43.6400, 172.4891],
  [-43.6406, 172.4885]
];

const lincolnSpringston = [
  [-43.6406, 172.4885],
  [-43.6424, 172.4854],
  [-43.6489, 172.4705],
  [-43.6494, 172.4687],
  [-43.6545, 172.4277],
  [-43.6557, 172.4222]
];

const springstonSouthbridge = [
  [-43.6557, 172.4222],
  [-43.6570, 172.4190],
  [-43.6834, 172.3625],
  [-43.6845, 172.3616],
  [-43.6855, 172.3616],
  [-43.6896, 172.3648],
  [-43.6906, 172.3649],
  [-43.6912, 172.3643],
  [-43.6938, 172.3582],
  [-43.6947, 172.3570],
  [-43.7025, 172.3505],
  [-43.7528, 172.3109],
  [-43.7597, 172.3058],
  [-43.7601, 172.3052],
  [-43.7644, 172.2965],
  [-43.7652, 172.2957],
  [-43.7742, 172.2887],
  [-43.7791, 172.2861],
  [-43.7799, 172.2854],
  [-43.7876, 172.2752],
  [-43.8090, 172.2605],
  [-43.8100, 172.2591],
  [-43.8106, 172.2574]
];

export const southbridge: Line = {
  name: 'Southbridge',
  state: 'NZ',
  segments: [
    {
      segments: [hornbySpringsRoad],
      history: {
        opened: {
          date: '1875-04-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [springsRoadPrebbleton],
      history: {
        opened: {
          date: '1875-04-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [prebbletonLincoln],
      history: {
        opened: {
          date: '1875-04-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lincolnSpringston],
      history: {
        opened: {
          date: '1875-04-26',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [springstonSouthbridge],
      history: {
        opened: {
          date: '1875-07-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
