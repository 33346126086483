import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import History from './History';
import { lightTheme, darkTheme } from "./mapUtils/colours";
import { detectSystemTheme } from "./mapUtils/systemUtils";

function App() {
  const [theme, setTheme] = useState('dark');

  const toggleTheme = () => {
    theme === 'light' ? setTheme('dark') : setTheme('light');
  };

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <History theme={theme} toggleTheme={toggleTheme}/>
    </ThemeProvider>
  );
}

export default App;
