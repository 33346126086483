import { Line } from "../../trackTypes";

//Kaimai Tunnel deviation
const hamiltonMorrinsville = [
  [-37.7898, 175.2639],
  [-37.7905, 175.2658],
  [-37.7905, 175.2668],
  [-37.7903, 175.2676],
  [-37.7894, 175.2688],
  [-37.7891, 175.2697],
  [-37.7880, 175.2758],
  [-37.7875, 175.2773],
  [-37.7832, 175.2849],
  [-37.7813, 175.2884],
  [-37.7792, 175.2937],
  [-37.7782, 175.2975],
  [-37.7759, 175.3184],
  [-37.7749, 175.3227],
  [-37.7733, 175.3266],
  [-37.7714, 175.3297],
  [-37.7697, 175.3318],
  [-37.7685, 175.3333],
  [-37.7597, 175.3434],
  [-37.7171, 175.4123],
  [-37.6949, 175.4397],
  [-37.6729, 175.4667],
  [-37.6714, 175.4691],
  [-37.6705, 175.4720],
  [-37.6657, 175.4995],
  [-37.6655, 175.5023],
  [-37.6659, 175.5042],
  [-37.6659, 175.5052],
  [-37.6658, 175.5061],
  [-37.6626, 175.5200]
];

const morrinsvilleWaitoa = [
  [-37.6626, 175.5200],
  [-37.6600, 175.5310],
  [-37.6594, 175.5330],
  [-37.6585, 175.5353],
  [-37.6520, 175.5466],
  [-37.6517, 175.5473],
  [-37.6514, 175.5484],
  [-37.6508, 175.5493],
  [-37.6485, 175.5515],
  [-37.6478, 175.5524],
  [-37.6197, 175.5975],
  [-37.5989, 175.6311]
];

const waitoaTeAroha = [
  [-37.5989, 175.6311],
  [-37.5951, 175.6373],
  [-37.5898, 175.6489],
  [-37.5886, 175.6505],
  [-37.5766, 175.6620],
  [-37.5747, 175.6643],
  [-37.5730, 175.6667],
  [-37.5501, 175.7007],
  [-37.5445, 175.7075],
  [-37.5441, 175.7078],
  [-37.5422, 175.7085]
];

const teArohaPaeroa = [
  [-37.5422, 175.7085],
  [-37.5404, 175.7092],
  [-37.5397, 175.7092],
  [-37.5390, 175.7086],
  [-37.5347, 175.7010],
  [-37.5338, 175.6999],
  [-37.5311, 175.6976],
  [-37.5302, 175.6972],
  [-37.5064, 175.6886],
  [-37.5050, 175.6878],
  [-37.4984, 175.6822],
  [-37.4650, 175.6537],
  [-37.4467, 175.6427],
  [-37.4450, 175.6421],
  [-37.4365, 175.6405],
  [-37.4355, 175.6404],
  [-37.4281, 175.6413],
  [-37.4263, 175.6420],
  [-37.3911, 175.6664],
  [-37.3895, 175.6671],
  [-37.3874, 175.6671], //POINT
  [-37.3839, 175.6665],
  [-37.3792, 175.6670],
  [-37.3772, 175.6672]
];

const paeroaJunction = [
  [-37.3874, 175.6671],
  [-37.3908, 175.6685],
  [-37.3924, 175.6697],
  [-37.3936, 175.6711]
];

const paeroaKarangahakeTunnel = [
  [-37.3935, 175.6647],
  [-37.3927, 175.6661],
  [-37.3924, 175.6676],
  [-37.3926, 175.6692],
  [-37.3936, 175.6711],
  [-37.3978, 175.6782],
  [-37.4021, 175.6920],
  [-37.4026, 175.6930],
  [-37.4042, 175.6961],
  [-37.4046, 175.6967],
  [-37.4054, 175.6974],
  [-37.4058, 175.6978],
  [-37.4061, 175.6988],
  [-37.4074, 175.7008],
  [-37.4075, 175.7016],
  [-37.4074, 175.7024],
  [-37.4075, 175.7031],
  [-37.4079, 175.7036],
  [-37.4085, 175.7038],
  [-37.4089, 175.7036],
  [-37.4094, 175.7033],
  [-37.4099, 175.7031],
  [-37.4112, 175.7033],
  [-37.4125, 175.7033],
  [-37.4133, 175.7031],
  [-37.4138, 175.7031],
  [-37.4142, 175.7035],
  [-37.4149, 175.7047],
  [-37.4149, 175.7052],
  [-37.4147, 175.7067],
  [-37.4147, 175.7084],
  [-37.4146, 175.7093],
  [-37.4139, 175.7108],
  [-37.4138, 175.7114],
  [-37.4141, 175.7125],
  [-37.4144, 175.7132],
  [-37.4177, 175.7164],
  [-37.4180, 175.7171],
  [-37.4184, 175.7192]
];

const karangahakeTunnel = [
  [-37.4184, 175.7192],
  [-37.4210, 175.7307]
];

const karangahakeTunnelWaikino = [
  [-37.4210, 175.7307],
  [-37.4212, 175.7317],
  [-37.4214, 175.7323],
  [-37.4213, 175.7328],
  [-37.4209, 175.7339],
  [-37.4208, 175.7342],
  [-37.4208, 175.7354],
  [-37.4207, 175.7358],
  [-37.4198, 175.7372],
  [-37.4196, 175.7378],
  [-37.4196, 175.7384],
  [-37.4199, 175.7389],
  [-37.4204, 175.7400],
  [-37.4202, 175.7414],
  [-37.4198, 175.7422],
  [-37.4189, 175.7429],
  [-37.4188, 175.7432],
  [-37.4184, 175.7445],
  [-37.4184, 175.7449],
  [-37.4185, 175.7454],
  [-37.4186, 175.7457],
  [-37.4196, 175.7468],
  [-37.4199, 175.7473],
  [-37.4200, 175.7476],
  [-37.4199, 175.7483],
  [-37.4199, 175.7487],
  [-37.4204, 175.7501],
  [-37.4206, 175.7510],
  [-37.4204, 175.7523],
  [-37.4200, 175.7530],
  [-37.4196, 175.7534],
  [-37.4189, 175.7537],
  [-37.4183, 175.7542],
  [-37.4174, 175.7558],
  [-37.4171, 175.7566],
  [-37.4172, 175.7574],
  [-37.4174, 175.7580],
  [-37.4181, 175.7588],
  [-37.4184, 175.7596],
  [-37.4184, 175.7602],
  [-37.4182, 175.7625],
  [-37.4173, 175.7669],
  [-37.4173, 175.7682],
  [-37.4171, 175.7689],
  [-37.4167, 175.7696],
  [-37.4133, 175.7724],
  [-37.4120, 175.7730],
  [-37.4112, 175.7737],
  [-37.4108, 175.7747],
  [-37.4109, 175.7762],
  [-37.4115, 175.7776],
  [-37.4119, 175.7781],
  [-37.4139, 175.7790]
];

const waikinoWaihi = [
  [-37.4139, 175.7790],
  [-37.4146, 175.7793],
  [-37.4153, 175.7802],
  [-37.4168, 175.7835],
  [-37.4172, 175.7845],
  [-37.4175, 175.7852],
  [-37.4175, 175.7862],
  [-37.4172, 175.7873],
  [-37.4158, 175.7893],
  [-37.4143, 175.7915],
  [-37.4137, 175.7920],
  [-37.4132, 175.7927],
  [-37.4120, 175.7951],
  [-37.4118, 175.7963],
  [-37.4121, 175.7974],
  [-37.4128, 175.7981],
  [-37.4131, 175.7990],
  [-37.4131, 175.7998],
  [-37.4128, 175.8006],
  [-37.4105, 175.8039],
  [-37.4102, 175.8045],
  [-37.4100, 175.8052],
  [-37.4100, 175.8066],
  [-37.4098, 175.8077],
  [-37.4094, 175.8090],
  [-37.4085, 175.8097],
  [-37.4077, 175.8098],
  [-37.4067, 175.8094],
  [-37.4062, 175.8093],
  [-37.4056, 175.8095],
  [-37.4049, 175.8102],
  [-37.4043, 175.8114],
  [-37.4038, 175.8120],
  [-37.4030, 175.8126],
  [-37.4026, 175.8132],
  [-37.4018, 175.8151],
  [-37.4017, 175.8160],
  [-37.4018, 175.8170],
  [-37.4022, 175.8178],
  [-37.4031, 175.8188],
  [-37.4035, 175.8195],
  [-37.4036, 175.8203],
  [-37.4034, 175.8223],
  [-37.4031, 175.8230],
  [-37.4026, 175.8237],
  [-37.4019, 175.8244],
  [-37.4015, 175.8249],
  [-37.4012, 175.8256],
  [-37.4001, 175.8297],
  [-37.3997, 175.8309],
  [-37.3978, 175.8342]
];

const waihiTahawai = [
  [-37.3978, 175.8342],
  [-37.3968, 175.8360],
  [-37.3963, 175.8380],
  [-37.3961, 175.8410],
  [-37.3965, 175.8429],
  [-37.3975, 175.8449],
  [-37.3996, 175.8470],
  [-37.4062, 175.8502],
  [-37.4085, 175.8516],
  [-37.4101, 175.8521],
  [-37.4127, 175.8522],
  [-37.4142, 175.8530],
  [-37.4162, 175.8559],
  [-37.4186, 175.8575],
  [-37.4206, 175.8582],
  [-37.4261, 175.8582],
  [-37.4276, 175.8586],
  [-37.4298, 175.8599],
  [-37.4312, 175.8611],
  [-37.4336, 175.8643],
  [-37.4391, 175.8696],
  [-37.4499, 175.8759],
  [-37.4505, 175.8767],
  [-37.4508, 175.8777],
  [-37.4507, 175.8787],
  [-37.4492, 175.8830],
  [-37.4490, 175.8850],
  [-37.4485, 175.8863],
  [-37.4463, 175.8882],
  [-37.4458, 175.8888],
  [-37.4456, 175.8897],
  [-37.4456, 175.8908],
  [-37.4451, 175.8918],
  [-37.4441, 175.8927],
  [-37.4437, 175.8936],
  [-37.4438, 175.8946],
  [-37.4443, 175.8954],
  [-37.4450, 175.8957],
  [-37.4456, 175.8952],
  [-37.4464, 175.8947],
  [-37.4470, 175.8948],
  [-37.4485, 175.8950],
  [-37.4495, 175.8960],
  [-37.4503, 175.8963],
  [-37.4510, 175.8962],
  [-37.4524, 175.8947],
  [-37.4534, 175.8941],
  [-37.4544, 175.8941],
  [-37.4554, 175.8943],
  [-37.4558, 175.8949],
  [-37.4558, 175.8956],
  [-37.4554, 175.8962],
  [-37.4551, 175.8976],
  [-37.4556, 175.9007],
  [-37.4585, 175.9079],
  [-37.4621, 175.9125],
  [-37.4626, 175.9137],
  [-37.4627, 175.9155],
  [-37.4618, 175.9178],
  [-37.4592, 175.9222],
  [-37.4587, 175.9244],
  [-37.4579, 175.9282],
  [-37.4570, 175.9296],
  [-37.4561, 175.9303],
  [-37.4556, 175.9315],
  [-37.4558, 175.9330],
  [-37.4566, 175.9340],
  [-37.4593, 175.9349],
  [-37.4604, 175.9359],
  [-37.4615, 175.9376],
  [-37.4626, 175.9383],
  [-37.4697, 175.9375],
  [-37.4918, 175.9288],
  [-37.5039, 175.9267],
  [-37.5066, 175.9267]
];

const tahawaiKaimaiTunnelDeviationEnd = [
  [-37.5066, 175.9267],
  [-37.5096, 175.9268],
  [-37.5146, 175.9281],
  [-37.5164, 175.9282],
  [-37.5176, 175.9278],
  [-37.5202, 175.9266],
  [-37.5231, 175.9259],
  [-37.5240, 175.9254],
  [-37.5279, 175.9197],
  [-37.5286, 175.9186],
  [-37.5312, 175.9172],
  [-37.5385, 175.9151],
  [-37.5491, 175.9151],
  [-37.5547, 175.9141],
  [-37.5564, 175.9140],
  [-37.5595, 175.9136],
  [-37.5794, 175.9143],
  [-37.5799, 175.9144],
  [-37.5807, 175.9152],
  [-37.5873, 175.9253],
  [-37.5890, 175.9282],
  [-37.5908, 175.9302],
  [-37.5942, 175.9331],
  [-37.6037, 175.9388],
  [-37.6122, 175.9483],
  [-37.6139, 175.9491],
  [-37.6277, 175.9525],
  [-37.6306, 175.9526],
  [-37.6324, 175.9534],
  [-37.6365, 175.9580],
  [-37.6370, 175.9592],
  [-37.6373, 175.9607],
  [-37.6378, 175.9618],
  [-37.6389, 175.9625],
  [-37.6458, 175.9648],
  [-37.6492, 175.9673],
  [-37.6497, 175.9680],
  [-37.6507, 175.9698]
];

const kaimaiTunnelDeviationEndTauranga = [
  [-37.6507, 175.9698],
  [-37.6510, 175.9713],
  [-37.6510, 175.9730],
  [-37.6499, 175.9811],
  [-37.6496, 175.9823],
  [-37.6469, 175.9872],
  [-37.6465, 175.9884],
  [-37.6449, 175.9938],
  [-37.6448, 175.9952],
  [-37.6452, 175.9977],
  [-37.6453, 175.9987],
  [-37.6450, 175.9996],
  [-37.6445, 176.0010],
  [-37.6443, 176.0024],
  [-37.6443, 176.0035],
  [-37.6462, 176.0147],
  [-37.6467, 176.0163],
  [-37.6600, 176.0404],
  [-37.6608, 176.0413],
  [-37.6617, 176.0417],
  [-37.6658, 176.0419],
  [-37.6671, 176.0424],
  [-37.6681, 176.0435],
  [-37.6759, 176.0562],
  [-37.6765, 176.0578],
  [-37.6767, 176.0592],
  [-37.6768, 176.0617],
  [-37.6766, 176.0627],
  [-37.6762, 176.0636],
  [-37.6744, 176.0662],
  [-37.6738, 176.0675],
  [-37.6736, 176.0693],
  [-37.6741, 176.0714],
  [-37.6752, 176.0730],
  [-37.6780, 176.0751],
  [-37.6789, 176.0761],
  [-37.6793, 176.0771],
  [-37.6798, 176.0788],
  [-37.6810, 176.0805],
  [-37.6825, 176.0814],
  [-37.6836, 176.0832],
  [-37.6860, 176.0921],
  [-37.6874, 176.0967],
  [-37.6875, 176.0981],
  [-37.6871, 176.0996],
  [-37.6862, 176.1006],
  [-37.6825, 176.1026],
  [-37.6816, 176.1035],
  [-37.6810, 176.1048],
  [-37.6808, 176.1064],
  [-37.6804, 176.1076],
  [-37.6792, 176.1100],
  [-37.6788, 176.1111],
  [-37.6785, 176.1125],
  [-37.6786, 176.1137],
  [-37.6797, 176.1182],
  [-37.6797, 176.1196],
  [-37.6794, 176.1207],
  [-37.6787, 176.1217],
  [-37.6775, 176.1229],
  [-37.6769, 176.1238],
  [-37.6749, 176.1274],
  [-37.6743, 176.1287],
  [-37.6739, 176.1297],
  [-37.6731, 176.1310],
  [-37.6720, 176.1321],
  [-37.6687, 176.1340],
  [-37.6677, 176.1349],
  [-37.6670, 176.1359],
  [-37.6656, 176.1393],
  [-37.6653, 176.1407],
  [-37.6651, 176.1438],
  [-37.6654, 176.1474],
  [-37.6692, 176.1610],
  [-37.6698, 176.1624],
  [-37.6719, 176.1650],
  [-37.6736, 176.1670],
  [-37.6758, 176.1696],
  [-37.6770, 176.1703],
  [-37.6852, 176.1703]
];

const taurangaTaneatuaJunction = [
  [-37.6852, 176.1703],
  [-37.6862, 176.1703],
  [-37.6869, 176.1706],
  [-37.6876, 176.1715],
  [-37.6879, 176.1723],
  [-37.6902, 176.1845],
  [-37.6909, 176.1863],
  [-37.6963, 176.1950],
  [-37.6968, 176.1965],
  [-37.6968, 176.1987],
  [-37.6959, 176.2015],
  [-37.6950, 176.2031],
  [-37.6903, 176.2077],
  [-37.6851, 176.2148],
  [-37.6841, 176.2172],
  [-37.6838, 176.2182],
  [-37.6829, 176.2223],
  [-37.6828, 176.2242],
  [-37.6834, 176.2266],
  [-37.6954, 176.2507],
  [-37.7234, 176.2942],
  [-37.7250, 176.2960],
  [-37.7267, 176.2969],
  [-37.7689, 176.3081],
  [-37.7756, 176.3118],
  [-37.7772, 176.3130],
  [-37.7809, 176.3163],
  [-37.7815, 176.3173],
  [-37.7819, 176.3188],
  [-37.7825, 176.3330],
  [-37.7831, 176.3353],
  [-37.7896, 176.3478],
  [-37.7902, 176.3497],
  [-37.7907, 176.3541],
  [-37.7906, 176.3559],
  [-37.7887, 176.3672],
  [-37.7879, 176.3760],
  [-37.7883, 176.3788],
  [-37.7955, 176.3966],
  [-37.7973, 176.3989],
  [-37.8098, 176.4075],
  [-37.8109, 176.4088],
  [-37.8134, 176.4161],
  [-37.8179, 176.4392],
  [-37.8180, 176.4416],
  [-37.8174, 176.4494],
  [-37.8181, 176.4535],
  [-37.8226, 176.4661],
  [-37.8240, 176.4729],
  [-37.8242, 176.4752],
  [-37.8237, 176.4855],
  [-37.8214, 176.4981],
  [-37.8165, 176.5128],
  [-37.8161, 176.5149],
  [-37.8160, 176.5171],
  [-37.8176, 176.5338],
  [-37.8215, 176.5469],
  [-37.8219, 176.5498],
  [-37.8218, 176.5825],
  [-37.8223, 176.5857],
  [-37.8231, 176.5881],
  [-37.8275, 176.5962],
  [-37.8296, 176.5991],
  [-37.8547, 176.6598],
  [-37.8561, 176.6623],
  [-37.8568, 176.6640],
  [-37.8580, 176.6689],
  [-37.8622, 176.6806],
  [-37.8635, 176.6831],
  [-37.8638, 176.6840],
  [-37.8663, 176.6931],
  [-37.8716, 176.7090],
  [-37.8842, 176.7447],
  [-37.8852, 176.7464],
  [-37.8865, 176.7479],
  [-37.8871, 176.7489],
  [-37.8879, 176.7507],
  [-37.8884, 176.7514],
  [-37.8896, 176.7526],
  [-37.8911, 176.7536],
  [-37.8919, 176.7545],
  [-37.8935, 176.7572],
  [-37.8944, 176.7583],
  [-37.8954, 176.7589],
  [-37.9029, 176.7609],
  [-37.9058, 176.7610],
  [-37.9080, 176.7603],
  [-37.9137, 176.7574],
  [-37.9163, 176.7568],
  [-37.9247, 176.7552],
  [-37.9261, 176.7553],
  [-37.9273, 176.7559],
  [-37.9423, 176.7702]
];

const kaimaiTunnelDeviationSouthJunction = [
  [-37.7548, 175.7490],
  [-37.7541, 175.7488],
  [-37.7531, 175.7490],
  [-37.7519, 175.7498],
  [-37.7513, 175.7509],
  [-37.7506, 175.7532]
];

const kaimaiTunnelDeviationStartKaimaiTunnel = [
  [-37.7478, 175.7443],
  [-37.7492, 175.7458],
  [-37.7502, 175.7477],
  [-37.7508, 175.7502],
  [-37.7506, 175.7532],
  [-37.7467, 175.7717],
  [-37.7460, 175.7736],
  [-37.7346, 175.7950],
  [-37.7329, 175.7970],
  [-37.7162, 175.8105],
  [-37.7147, 175.8124],
  [-37.7141, 175.8137],
  [-37.7065, 175.8348],
  [-37.7057, 175.8363],
  [-37.6997, 175.8451],
  [-37.6991, 175.8462]
];

const kaimaiTunnel = [
  [-37.6991, 175.8462],
  [-37.6596, 175.9335]
];

const kaimaiTunnelKaimaiTunnelDeviationEnd = [
  [-37.6596, 175.9335],
  [-37.6593, 175.9342],
  [-37.6585, 175.9369],
  [-37.6582, 175.9395],
  [-37.6575, 175.9421],
  [-37.6560, 175.9446],
  [-37.6525, 175.9480],
  [-37.6506, 175.9513],
  [-37.6483, 175.9591],
  [-37.6481, 175.9624],
  [-37.6488, 175.9651],
  [-37.6507, 175.9698]
];

export const eastCoastMainTrunk: Line = {
  name: 'East Coast Main Trunk',
  state: 'NZ',
  segments: [
    {
      segments: [hamiltonMorrinsville],
      history: {
        opened: {
          date: '1884-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [morrinsvilleWaitoa],
      history: {
        opened: {
          date: '1886-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waitoaTeAroha],
      history: {
        opened: {
          date: '1886-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [teArohaPaeroa],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paeroaJunction],
      history: {
        opened: {
          date: '1905-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-09-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        paeroaKarangahakeTunnel,
        {
          name: 'Karangahake Tunnel',
          segment: karangahakeTunnel,
          type: 'tunnel'
        },
        karangahakeTunnelWaikino
      ],
      history: {
        opened: {
          date: '1905-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-09-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waikinoWaihi],
      history: {
        opened: {
          date: '1905-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-09-12',
          status: 'closed'
        }, {
          date: '1980-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [waihiTahawai],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-09-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tahawaiKaimaiTunnelDeviationEnd],
      history: {
        opened: {
          date: '1927-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-09-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kaimaiTunnelDeviationEndTauranga],
      history: {
        opened: {
          date: '1927-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [taurangaTaneatuaJunction],
      history: {
        opened: {
          date: '1928-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kaimaiTunnelDeviationSouthJunction],
      history: {
        opened: {
          date: '1978-09-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        kaimaiTunnelDeviationStartKaimaiTunnel,
        {
          name: 'Kaimai Tunnel',
          segment: kaimaiTunnel,
          type: 'tunnel'
        },
        kaimaiTunnelKaimaiTunnelDeviationEnd
      ],
      history: {
        opened: {
          date: '1978-09-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
