import { Line } from "../../trackTypes";

const tembellupGnowangerup = [
  [-34.0470, 117.6419],
  [-34.0479, 117.6432],
  [-34.0480, 117.6442],
  [-34.0476, 117.6460],
  [-34.0475, 117.6474],
  [-34.0470, 117.6606],
  [-34.0470, 117.6846],
  [-34.0463, 117.6886],
  [-34.0407, 117.6986],
  [-34.0397, 117.7009],
  [-34.0355, 117.7058],
  [-34.0348, 117.7074],
  [-34.0350, 117.7094],
  [-34.0356, 117.7110],
  [-34.0358, 117.7133],
  [-34.0214, 117.7703],
  [-34.0212, 117.7721],
  [-34.0220, 117.7993],
  [-34.0214, 117.8027],
  [-34.0198, 117.8057],
  [-34.0194, 117.8072],
  [-34.0191, 117.8107],
  [-34.0195, 117.8136],
  [-34.0187, 117.8247],
  [-34.0146, 117.8354],
  [-34.0087, 117.8453],
  [-34.0066, 117.8473],
  [-34.0049, 117.8503],
  [-34.0044, 117.8524],
  [-34.0041, 117.8553],
  [-34.0008, 117.8730],
  [-33.9993, 117.8764],
  [-33.9989, 117.8784],
  [-33.9989, 117.8836],
  [-33.9987, 117.8853],
  [-33.9932, 117.9022],
  [-33.9925, 117.9033],
  [-33.9909, 117.9045],
  [-33.9899, 117.9060],
  [-33.9891, 117.9081],
  [-33.9890, 117.9097],
  [-33.9914, 117.9163],
  [-33.9915, 117.9180],
  [-33.9897, 117.9240],
  [-33.9833, 117.9364],
  [-33.9828, 117.9384],
  [-33.9828, 117.9515],
  [-33.9820, 117.9545],
  [-33.9787, 117.9590],
  [-33.9783, 117.9604],
  [-33.9783, 117.9619],
  [-33.9778, 117.9634],
  [-33.9749, 117.9677],
  [-33.9738, 117.9686],
  [-33.9653, 117.9740],
  [-33.9641, 117.9752],
  [-33.9598, 117.9828],
  [-33.9587, 117.9841],
  [-33.9521, 117.9888],
  [-33.9385, 117.9930],
  [-33.9374, 117.9941],
  [-33.9369, 117.9955],
  [-33.9371, 118.0026]
];

const gnowangerupOngerup = [
  [-33.9371, 118.0026],
  [-33.9371, 118.0043],
  [-33.9361, 118.0087],
  [-33.9364, 118.0100],
  [-33.9373, 118.0118],
  [-33.9388, 118.0134],
  [-33.9428, 118.0145],
  [-33.9477, 118.0176],
  [-33.9526, 118.0224],
  [-33.9533, 118.0239],
  [-33.9537, 118.0255],
  [-33.9542, 118.0265],
  [-33.9552, 118.0276],
  [-33.9576, 118.0316],
  [-33.9584, 118.0326],
  [-33.9601, 118.0338],
  [-33.9617, 118.0369],
  [-33.9656, 118.0432],
  [-33.9723, 118.0586],
  [-33.9727, 118.0605],
  [-33.9728, 118.0639],
  [-33.9741, 118.0664],
  [-33.9785, 118.0681],
  [-33.9803, 118.0696],
  [-33.9812, 118.0715],
  [-33.9826, 118.0788],
  [-33.9837, 118.0804],
  [-33.9851, 118.0809],
  [-33.9885, 118.0801],
  [-33.9902, 118.0806],
  [-33.9914, 118.0818],
  [-33.9938, 118.0872],
  [-33.9953, 118.0889],
  [-33.9976, 118.0905],
  [-33.9988, 118.0921],
  [-34.0035, 118.1030],
  [-34.0106, 118.1180],
  [-34.0177, 118.1274],
  [-34.0193, 118.1312],
  [-34.0206, 118.1367],
  [-34.0223, 118.1410],
  [-34.0240, 118.1449],
  [-34.0251, 118.1534],
  [-34.0252, 118.1631],
  [-34.0275, 118.1757],
  [-34.0297, 118.1814],
  [-34.0297, 118.1839],
  [-34.0291, 118.1864],
  [-34.0294, 118.1891],
  [-34.0469, 118.2029],
  [-34.0573, 118.2211],
  [-34.0620, 118.2247],
  [-34.0631, 118.2267],
  [-34.0637, 118.2292],
  [-34.0648, 118.2308],
  [-34.0677, 118.2328],
  [-34.0703, 118.2358],
  [-34.0715, 118.2384],
  [-34.0724, 118.2456],
  [-34.0721, 118.2478],
  [-34.0708, 118.2524],
  [-34.0705, 118.2670],
  [-34.0700, 118.2688],
  [-34.0682, 118.2720],
  [-34.0653, 118.2834],
  [-34.0628, 118.2871],
  [-34.0621, 118.2894],
  [-34.0621, 118.2962],
  [-34.0629, 118.3009],
  [-34.0621, 118.3050],
  [-34.0625, 118.3121],
  [-34.0619, 118.3143],
  [-34.0607, 118.3159],
  [-34.0579, 118.3175],
  [-34.0570, 118.3189],
  [-34.0563, 118.3208],
  [-34.0543, 118.3235],
  [-34.0443, 118.3442],
  [-34.0434, 118.3467],
  [-34.0400, 118.3501],
  [-34.0392, 118.3514],
  [-34.0355, 118.3668],
  [-34.0249, 118.3978],
  [-34.0248, 118.4014],
  [-34.0225, 118.4078],
  [-34.0221, 118.4104],
  [-34.0221, 118.4180],
  [-34.0213, 118.4208],
  [-34.0187, 118.4243],
  [-34.0162, 118.4260],
  [-34.0125, 118.4311],
  [-34.0118, 118.4331],
  [-34.0109, 118.4369],
  [-34.0098, 118.4386],
  [-34.0033, 118.4454],
  [-34.0020, 118.4472],
  [-33.9982, 118.4557],
  [-33.9753, 118.4863],
  [-33.9717, 118.4883],
  [-33.9629, 118.4891]
];

export const ongerup: Line = {
  name: 'Ongerup',
  state: 'WA',
  segments: [
    {
      segments: [tembellupGnowangerup],
      history: {
        opened: {
          date: '1912-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gnowangerupOngerup],
      history: {
        opened: {
          date: '1913-01-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-10-13',
          status: 'closed'
        }]
      }
    }
  ]
};
