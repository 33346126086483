import { Line } from "../../trackTypes";

const narrabriSouthJunctionNarrabriWest = [
  [-30.3482, 149.7634],
  [-30.3470, 149.7630],
  [-30.3459, 149.7624],
  [-30.3452, 149.7618],
  [-30.3441, 149.7603],
  [-30.3415, 149.7552]
];

const narrabriWestJunctionNarrabriNorthJunction = [
  [-30.3452, 149.7618],
  [-30.3455, 149.7625],
  [-30.3458, 149.7641],
  [-30.3457, 149.7651]
];

const narrabriWestBurren = [
  [-30.3415, 149.7552],
  [-30.3381, 149.7486],
  [-30.3376, 149.7474],
  [-30.3331, 149.7346],
  [-30.2813, 149.5890],
  [-30.2673, 149.5476],
  [-30.2423, 149.4704],
  [-30.2368, 149.4513],
  [-30.2363, 149.4503],
  [-30.2306, 149.4413],
  [-30.2300, 149.4400],
  [-30.2259, 149.4214],
  [-30.2153, 149.3782],
  [-30.2052, 149.3448],
  [-30.2042, 149.3405],
  [-30.2037, 149.3394],
  [-30.2029, 149.3384],
  [-30.1941, 149.3310],
  [-30.1926, 149.3291],
  [-30.1914, 149.3265],
  [-30.1859, 149.3051],
  [-30.1855, 149.3032],
  [-30.1843, 149.2928],
  [-30.1834, 149.2660],
  [-30.1841, 149.2623],
  [-30.1886, 149.2499],
  [-30.1893, 149.2440],
  [-30.1876, 149.2305],
  [-30.1876, 149.2251],
  [-30.1891, 149.2165],
  [-30.1894, 149.1673],
  [-30.1890, 149.1642],
  [-30.1880, 149.1612],
  [-30.1864, 149.1584],
  [-30.1847, 149.1538],
  [-30.1832, 149.1418],
  [-30.1815, 149.1371],
  [-30.1714, 149.1247],
  [-30.1640, 149.1121],
  [-30.1558, 149.1024],
  [-30.1541, 149.0997],
  [-30.1295, 149.0492],
  [-30.1286, 149.0465],
  [-30.1262, 149.0290],
  [-30.1256, 149.0268],
  [-30.1093, 148.9878],
  [-30.1082, 148.9832],
  [-30.1063, 148.9676]

];

const burrenCryon = [
  [-30.1063, 148.9676],
  [-30.1045, 148.9549],
  [-30.1032, 148.9513],
  [-30.0792, 148.9163],
  [-30.0763, 148.9099],
  [-30.0730, 148.8936],
  [-30.0673, 148.8775],
  [-30.0666, 148.8732],
  [-30.0671, 148.8687],
  [-30.0692, 148.8612],
  [-30.0695, 148.8579],
  [-30.0671, 148.8402],
  [-30.0661, 148.8380],
  [-30.0645, 148.8363],
  [-30.0601, 148.8337],
  [-30.0575, 148.8308],
  [-30.0558, 148.8273],
  [-30.0533, 148.8136],
  [-30.0519, 148.8103],
  [-30.0503, 148.8084],
  [-30.0482, 148.8065],
  [-30.0466, 148.8044],
  [-30.0457, 148.8018],
  [-30.0349, 148.7156],
  [-30.0262, 148.6833],
  [-30.0243, 148.6796],
  [-30.0209, 148.6756],
  [-30.0189, 148.6717],
  [-30.0103, 148.6310],
  [-30.0058, 148.6154],
  [-30.0037, 148.6085]
];

const cryonWalgettWheatTerminal = [
  [-30.0037, 148.6085],
  [-29.9840, 148.5403],
  [-29.9567, 148.4464],
  [-29.9559, 148.4425],
  [-29.9486, 148.3533],
  [-29.9476, 148.3502],
  [-29.9457, 148.3480],
  [-29.9434, 148.3466],
  [-29.9416, 148.3458],
  [-29.9391, 148.3435],
  [-29.9379, 148.3411],
  [-29.9375, 148.3383],
  [-29.9416, 148.2995],
  [-29.9438, 148.2926],
  [-29.9716, 148.2407],
  [-29.9731, 148.2360],
  [-29.9864, 148.1720],
  [-29.9901, 148.1631],
  [-29.9984, 148.1509]
];

const walgettWheatTerminalWalgett = [
  [-29.9984, 148.1509],
  [-29.9996, 148.1484],
  [-29.9998, 148.1464],
  [-29.9986, 148.1318],
  [-29.9989, 148.1299],
  [-29.9998, 148.1285],
  [-30.0011, 148.1276],
  [-30.0125, 148.1241]
];

export const walgett: Line = {
  name: 'Walgett',
  state: 'NSW',
  segments: [
    {
      segments: [narrabriSouthJunctionNarrabriWest],
      history: {
        opened: {
          date: '1882-10-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [narrabriWestJunctionNarrabriNorthJunction],
      history: {
        opened: {
          date: '1887-04-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [narrabriWestBurren],
      history: {
        opened: {
          date: '1903-12-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [burrenCryon],
      history: {
        opened: {
          date: '1905-07-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cryonWalgettWheatTerminal],
      history: {
        opened: {
          date: '1908-11-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [walgettWheatTerminalWalgett],
      history: {
        opened: {
          date: '1908-11-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-11-12',
          status: 'closed'
        }]
      }
    }
  ]
};
