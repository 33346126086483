import { Line } from "../../../trackTypes";

const wodongaJunction = [
  [-36.1204, 146.8882],
  [-36.1211, 146.8899],
  [-36.1221, 146.8914],
  [-36.1233, 146.8925],
  [-36.1247, 146.8934]
];

const alburyJunction = [
  [-36.1188, 146.8940],
  [-36.1196, 146.8937],
  [-36.1229, 146.8930],
  [-36.1236, 146.8930],
  [-36.1247, 146.8934],
];

const alburyJunctionBandiana = [
  [-36.1247, 146.8934],
  [-36.1308, 146.8974],
  [-36.1335, 146.8999],
  [-36.1357, 146.9028],
  [-36.1470, 146.9223],
  [-36.1485, 146.9268],
  [-36.1490, 146.9328]
];

const bandianaBandolier = [
  [-36.1490, 146.9328],
  [-36.1502, 146.9445],
  [-36.1502, 146.9457]
];

const bandolierHuon = [
  [-36.1502, 146.9457],
  [-36.1497, 146.9494],
  [-36.1397, 146.9847],
  [-36.1395, 146.9886],
  [-36.1405, 146.9926],
  [-36.1423, 146.9957],
  [-36.1431, 146.9974],
  [-36.1434, 146.9991],
  [-36.1434, 147.0084],
  [-36.1438, 147.0113],
  [-36.1447, 147.0128],
  [-36.1463, 147.0142],
  [-36.1470, 147.0156],
  [-36.1471, 147.0171],
  [-36.1469, 147.0193],
  [-36.1470, 147.0206],
  [-36.1477, 147.0218],
  [-36.1546, 147.0275],
  [-36.1563, 147.0283],
  [-36.1646, 147.0313],
  [-36.1723, 147.0353],
  [-36.1735, 147.0356],
  [-36.1801, 147.0353],
  [-36.1826, 147.0353],
  [-36.1870, 147.0351],
  [-36.1884, 147.0354],
  [-36.1937, 147.0388],
  [-36.1959, 147.0398],
  [-36.1997, 147.0438],
  [-36.2007, 147.0453],
  [-36.2023, 147.0487],
  [-36.2034, 147.0503],
  [-36.2152, 147.0573],
  [-36.2164, 147.0588],
  [-36.2185, 147.0642]
];

const huonBolga = [
  [-36.2185, 147.0642],
  [-36.2201, 147.0691],
  [-36.2222, 147.0725],
  [-36.2285, 147.0786],
  [-36.2344, 147.0832],
  [-36.2356, 147.0851],
  [-36.2362, 147.0904],
  [-36.2358, 147.0925],
  [-36.2299, 147.1011],
  [-36.2284, 147.1022],
  [-36.2268, 147.1034],
  [-36.2246, 147.1070]
];

const bolgaTallangatta = [
  [-36.2246, 147.1070],
  [-36.2237, 147.1084],
  [-36.2233, 147.1103],
  [-36.2233, 147.1160],
  [-36.2238, 147.1189],
  [-36.2255, 147.1217],
  [-36.2260, 147.1235],
  [-36.2257, 147.1255],
  [-36.2204, 147.1369],
  [-36.2206, 147.1395],
  [-36.2230, 147.1462],
  [-36.2229, 147.1486],
  [-36.2221, 147.1499],
  [-36.2208, 147.1518],
  [-36.2206, 147.1531],
  [-36.2204, 147.1553],
  [-36.2197, 147.1578],
  [-36.2195, 147.1609],
  [-36.2191, 147.1624],
  [-36.2192, 147.1643],
  [-36.2207, 147.1688],
  [-36.2209, 147.1707],
  [-36.2206, 147.1728],
  [-36.2186, 147.1775],
  [-36.2179, 147.1837]
];

const tallangattaShelley = [
  [-36.2179, 147.1837],
  [-36.2172, 147.1888],
  [-36.2153, 147.1998],
  [-36.2152, 147.2017],
  [-36.2153, 147.2082],
  [-36.2159, 147.2108],
  [-36.2159, 147.2119],
  [-36.2155, 147.2138],
  [-36.2155, 147.2152],
  [-36.2157, 147.2174],
  [-36.2155, 147.2194],
  [-36.2156, 147.2208],
  [-36.2161, 147.2231],
  [-36.2161, 147.2258],
  [-36.2169, 147.2291],
  [-36.2170, 147.2322],
  [-36.2162, 147.2357],
  [-36.2147, 147.2397],
  [-36.2144, 147.2412],
  [-36.2144, 147.2427],
  [-36.2142, 147.2433],
  [-36.2137, 147.2442],
  [-36.2070, 147.2513],
  [-36.2063, 147.2518],
  [-36.2052, 147.2521],
  [-36.2045, 147.2526],
  [-36.2040, 147.2532],
  [-36.2035, 147.2547],
  [-36.2023, 147.2566],
  [-36.2011, 147.2581],
  [-36.1999, 147.2594],
  [-36.1991, 147.2609],
  [-36.1980, 147.2623],
  [-36.1976, 147.2632],
  [-36.1975, 147.2643],
  [-36.1976, 147.2656],
  [-36.1974, 147.2668],
  [-36.1970, 147.2682],
  [-36.1968, 147.2697],
  [-36.1969, 147.2714],
  [-36.1971, 147.2728],
  [-36.1968, 147.2741],
  [-36.1959, 147.2766],
  [-36.1915, 147.2851],
  [-36.1913, 147.2863],
  [-36.1915, 147.2874],
  [-36.1933, 147.2911],
  [-36.1935, 147.2922],
  [-36.1933, 147.2933],
  [-36.1926, 147.2949],
  [-36.1917, 147.2996],
  [-36.1918, 147.3002],
  [-36.1921, 147.3009],
  [-36.1934, 147.3024],
  [-36.1938, 147.3031],
  [-36.1947, 147.3060],
  [-36.1946, 147.3105],
  [-36.1942, 147.3121],
  [-36.1936, 147.3135],
  [-36.1933, 147.3156],
  [-36.1947, 147.3266],
  [-36.1947, 147.3284],
  [-36.1943, 147.3299],
  [-36.1922, 147.3354],
  [-36.1909, 147.3371],
  [-36.1861, 147.3400],
  [-36.1849, 147.3411],
  [-36.1822, 147.3439],
  [-36.1817, 147.3453],
  [-36.1816, 147.3467],
  [-36.1819, 147.3479],
  [-36.1853, 147.3553],
  [-36.1856, 147.3570],
  [-36.1852, 147.3583],
  [-36.1845, 147.3593],
  [-36.1844, 147.3604],
  [-36.1848, 147.3613],
  [-36.1865, 147.3629],
  [-36.1869, 147.3639],
  [-36.1868, 147.3648],
  [-36.1864, 147.3656],
  [-36.1839, 147.3677],
  [-36.1833, 147.3689],
  [-36.1833, 147.3707],
  [-36.1831, 147.3723],
  [-36.1815, 147.3748],
  [-36.1809, 147.3752],
  [-36.1801, 147.3756],
  [-36.1795, 147.3764],
  [-36.1795, 147.3777],
  [-36.1797, 147.3788],
  [-36.1795, 147.3799],
  [-36.1788, 147.3809],
  [-36.1756, 147.3833],
  [-36.1752, 147.3841],
  [-36.1747, 147.3851],
  [-36.1741, 147.3855],
  [-36.1734, 147.3855],
  [-36.1726, 147.3852],
  [-36.1716, 147.3855],
  [-36.1710, 147.3864],
  [-36.1710, 147.3876],
  [-36.1714, 147.3890],
  [-36.1713, 147.3900],
  [-36.1708, 147.3908],
  [-36.1702, 147.3914],
  [-36.1699, 147.3922],
  [-36.1697, 147.3947],
  [-36.1700, 147.3961],
  [-36.1707, 147.3969],
  [-36.1718, 147.3973],
  [-36.1727, 147.3971],
  [-36.1760, 147.3945],
  [-36.1768, 147.3943],
  [-36.1778, 147.3951],
  [-36.1778, 147.3965],
  [-36.1773, 147.3974],
  [-36.1762, 147.3984],
  [-36.1753, 147.4007],
  [-36.1745, 147.4019],
  [-36.1739, 147.4024],
  [-36.1727, 147.4026],
  [-36.1720, 147.4031],
  [-36.1706, 147.4048],
  [-36.1702, 147.4057],
  [-36.1696, 147.4063],
  [-36.1690, 147.4064],
  [-36.1680, 147.4058],
  [-36.1670, 147.4053],
  [-36.1656, 147.4053],
  [-36.1647, 147.4055],
  [-36.1640, 147.4060],
  [-36.1600, 147.4096],
  [-36.1587, 147.4102],
  [-36.1574, 147.4104],
  [-36.1565, 147.4108],
  [-36.1552, 147.4122],
  [-36.1538, 147.4136],
  [-36.1523, 147.4164],
  [-36.1513, 147.4182],
  [-36.1508, 147.4197],
  [-36.1503, 147.4203],
  [-36.1493, 147.4215],
  [-36.1489, 147.4223],
  [-36.1484, 147.4242],
  [-36.1479, 147.4250],
  [-36.1466, 147.4267],
  [-36.1456, 147.4284],
  [-36.1449, 147.4325],
  [-36.1451, 147.4343],
  [-36.1450, 147.4352],
  [-36.1448, 147.4367],
  [-36.1449, 147.4377],
  [-36.1454, 147.4399],
  [-36.1454, 147.4412],
  [-36.1448, 147.4426],
  [-36.1435, 147.4439],
  [-36.1427, 147.4451],
  [-36.1427, 147.4462],
  [-36.1431, 147.4471],
  [-36.1440, 147.4478],
  [-36.1445, 147.4485],
  [-36.1452, 147.4541],
  [-36.1454, 147.4547],
  [-36.1462, 147.4564],
  [-36.1464, 147.4579],
  [-36.1465, 147.4598],
  [-36.1466, 147.4614],
  [-36.1461, 147.4645],
  [-36.1463, 147.4667],
  [-36.1479, 147.4702],
  [-36.1491, 147.4716],
  [-36.1504, 147.4732],
  [-36.1510, 147.4752],
  [-36.1511, 147.4786],
  [-36.1514, 147.4807],
  [-36.1511, 147.4833],
  [-36.1514, 147.4851],
  [-36.1524, 147.4865],
  [-36.1538, 147.4870],
  [-36.1550, 147.4864],
  [-36.1560, 147.4855],
  [-36.1571, 147.4853],
  [-36.1604, 147.4866],
  [-36.1617, 147.4865],
  [-36.1636, 147.4852],
  [-36.1644, 147.4849],
  [-36.1661, 147.4849],
  [-36.1672, 147.4856],
  [-36.1684, 147.4913],
  [-36.1683, 147.4935],
  [-36.1688, 147.4949],
  [-36.1713, 147.4959],
  [-36.1725, 147.4972],
  [-36.1739, 147.4996],
  [-36.1748, 147.5002],
  [-36.1815, 147.5014],
  [-36.1845, 147.5016],
  [-36.1889, 147.5041],
  [-36.1897, 147.5053],
  [-36.1902, 147.5072],
  [-36.1921, 147.5090],
  [-36.1937, 147.5116],
  [-36.1946, 147.5147],
  [-36.1944, 147.5164],
  [-36.1940, 147.5173],
  [-36.1941, 147.5187],
  [-36.1953, 147.5212],
  [-36.1960, 147.5217],
  [-36.1978, 147.5218],
  [-36.1990, 147.5229],
  [-36.1995, 147.5249],
  [-36.1986, 147.5270],
  [-36.1978, 147.5294],
  [-36.1958, 147.5317],
  [-36.1944, 147.5353],
  [-36.1937, 147.5378],
  [-36.1900, 147.5426]
];

const shelleyBeetomba = [
  [-36.1900, 147.5426],
  [-36.1883, 147.5449],
  [-36.1880, 147.5459],
  [-36.1880, 147.5468],
  [-36.1881, 147.5480],
  [-36.1881, 147.5489],
  [-36.1877, 147.5501],
  [-36.1876, 147.5511],
  [-36.1879, 147.5535],
  [-36.1878, 147.5544],
  [-36.1875, 147.5556],
  [-36.1876, 147.5566],
  [-36.1879, 147.5574],
  [-36.1888, 147.5580],
  [-36.1905, 147.5578],
  [-36.1911, 147.5575],
  [-36.1915, 147.5569],
  [-36.1924, 147.5536],
  [-36.1930, 147.5527],
  [-36.1941, 147.5525],
  [-36.1956, 147.5535],
  [-36.1964, 147.5536],
  [-36.1974, 147.5533],
  [-36.1982, 147.5536],
  [-36.1987, 147.5543],
  [-36.1990, 147.5563],
  [-36.1999, 147.5583],
  [-36.2009, 147.5596],
  [-36.2012, 147.5607],
  [-36.2014, 147.5621],
  [-36.2022, 147.5640],
  [-36.2035, 147.5653],
  [-36.2043, 147.5657],
  [-36.2102, 147.5667],
  [-36.2126, 147.5665],
  [-36.2149, 147.5656],
  [-36.2160, 147.5657],
  [-36.2172, 147.5663],
  [-36.2188, 147.5658],
  [-36.2197, 147.5646],
  [-36.2198, 147.5628],
  [-36.2202, 147.5616],
  [-36.2210, 147.5612],
  [-36.2223, 147.5616],
  [-36.2227, 147.5629],
  [-36.2222, 147.5645],
  [-36.2221, 147.5654],
  [-36.2225, 147.5665],
  [-36.2235, 147.5673],
  [-36.2241, 147.5683],
  [-36.2243, 147.5700],
  [-36.2253, 147.5712],
  [-36.2255, 147.5722],
  [-36.2251, 147.5762],
  [-36.2245, 147.5776],
  [-36.2246, 147.5791],
  [-36.2241, 147.5808],
  [-36.2242, 147.5818],
  [-36.2250, 147.5828],
  [-36.2290, 147.5833],
  [-36.2296, 147.5841],
  [-36.2311, 147.5878],
  [-36.2313, 147.5900],
  [-36.2309, 147.5911],
  [-36.2301, 147.5920],
  [-36.2298, 147.5934],
  [-36.2305, 147.5955],
  [-36.2313, 147.5966],
  [-36.2325, 147.5975],
  [-36.2328, 147.5990],
  [-36.2327, 147.5997],
  [-36.2314, 147.6025],
  [-36.2313, 147.6035],
  [-36.2319, 147.6047],
  [-36.2335, 147.6066],
  [-36.2346, 147.6102],
  [-36.2356, 147.6115],
  [-36.2370, 147.6125],
  [-36.2374, 147.6137],
  [-36.2371, 147.6147],
  [-36.2365, 147.6155],
  [-36.2363, 147.6163],
  [-36.2360, 147.6193],
  [-36.2363, 147.6213],
  [-36.2358, 147.6263],
  [-36.2360, 147.6294],
  [-36.2366, 147.6309],
  [-36.2391, 147.6348],
  [-36.2396, 147.6368],
  [-36.2395, 147.6384],
  [-36.2389, 147.6401],
  [-36.2387, 147.6420],
  [-36.2385, 147.6428],
  [-36.2366, 147.6467]
];

const beetombaCudgewa = [
  [-36.2366, 147.6467],
  [-36.2355, 147.6486],
  [-36.2344, 147.6493],
  [-36.2333, 147.6491],
  [-36.2322, 147.6497],
  [-36.2314, 147.6513],
  [-36.2308, 147.6538],
  [-36.2297, 147.6554],
  [-36.2287, 147.6560],
  [-36.2265, 147.6566],
  [-36.2259, 147.6572],
  [-36.2251, 147.6588],
  [-36.2234, 147.6604],
  [-36.2220, 147.6625],
  [-36.2219, 147.6634],
  [-36.2222, 147.6642],
  [-36.2232, 147.6656],
  [-36.2237, 147.6668],
  [-36.2237, 147.6678],
  [-36.2224, 147.6715],
  [-36.2212, 147.6724],
  [-36.2193, 147.6723],
  [-36.2175, 147.6730],
  [-36.2159, 147.6730],
  [-36.2149, 147.6735],
  [-36.2116, 147.6768],
  [-36.2111, 147.6778],
  [-36.2112, 147.6786],
  [-36.2118, 147.6797],
  [-36.2117, 147.6887],
  [-36.2115, 147.6899],
  [-36.2115, 147.6910],
  [-36.2108, 147.6923],
  [-36.2102, 147.6932],
  [-36.2100, 147.6946],
  [-36.2096, 147.6954],
  [-36.2087, 147.6959],
  [-36.2080, 147.6968],
  [-36.2072, 147.6994],
  [-36.2062, 147.7006],
  [-36.1994, 147.7044],
  [-36.1914, 147.7109],
  [-36.1894, 147.7135],
  [-36.1872, 147.7175],
  [-36.1859, 147.7220],
  [-36.1860, 147.7232],
  [-36.1865, 147.7239],
  [-36.1876, 147.7242],
  [-36.1885, 147.7249],
  [-36.1893, 147.7269],
  [-36.1899, 147.7279],
  [-36.1910, 147.7288],
  [-36.1973, 147.7302],
  [-36.1993, 147.7320],
  [-36.2029, 147.7412],
  [-36.2030, 147.7436],
  [-36.1994, 147.7558],
  [-36.1922, 147.7727]
];

export const cudgewa: Line = {
  name: 'Cudgewa',
  state: 'VIC',
  segments: [
    {
      segments: [wodongaJunction],
      history: {
        opened: {
          date: '1889-09-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [alburyJunction],
      history: {
        opened: {
          date: '1942-04-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-22',
          gauge: 'standard'
        }, {
          date: '2009-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [alburyJunctionBandiana],
      history: {
        opened: {
          date: '1889-09-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1942-04-22',
          gauge: 'dual'
        }, {
          date: '1995-01-22',
          gauge: 'standard'
        }, {
          date: '2009-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bandianaBandolier],
      history: {
        opened: {
          date: '1889-09-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1944-07-10',
          gauge: 'dual'
        }, {
          date: '1968-07-29',
          gauge: 'broad'
        }, {
          date: '1995-01-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bandolierHuon],
      history: {
        opened: {
          date: '1889-09-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [huonBolga],
      history: {
        opened: {
          date: '1890-07-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bolgaTallangatta],
      history: {
        opened: {
          date: '1891-07-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tallangattaShelley],
      history: {
        opened: {
          date: '1916-06-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [shelleyBeetomba],
      history: {
        opened: {
          date: '1919-05-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [beetombaCudgewa],
      history: {
        opened: {
          date: '1921-05-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-01',
          status: 'closed'
        }]
      }
    }
  ]
}
