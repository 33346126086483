import { Line } from "../../../../trackTypes";

const heritage = [
  [-43.53095, 172.63413],
  [-43.53099, 172.63399],
  [-43.53106, 172.63389],
  [-43.53114, 172.63384],
  [-43.53273, 172.63384],
];

const riccarton = [
  [-43.53273, 172.63384],
  [-43.53276, 172.63383],
  [-43.53302, 172.63375],
  [-43.53326, 172.63359],
  [-43.53341, 172.63351],
  [-43.53429, 172.63237],
  [-43.53487, 172.63143],
  [-43.53492, 172.63132],
  [-43.53496, 172.63110],
  [-43.53494, 172.63050],
  [-43.53467, 172.62880],
  [-43.53444, 172.62761],
  [-43.53446, 172.62727],
  [-43.53453, 172.62705],
  [-43.53489, 172.62641],
  [-43.53494, 172.62624],
  [-43.53495, 172.62608],
  [-43.53274, 172.61854],
  [-43.53263, 172.61821],
  [-43.53248, 172.61792],
  [-43.53224, 172.61750],
  [-43.53136, 172.61633],
  [-43.53120, 172.61610],
  [-43.53109, 172.61584],
  [-43.53097, 172.61533],
  [-43.53091, 172.61509],
  [-43.53080, 172.61486],
  [-43.53064, 172.61459],
  [-43.53051, 172.61442],
  [-43.53034, 172.61422],
  [-43.52999, 172.61394],
  [-43.52970, 172.61375],
  [-43.52950, 172.61359],
  [-43.52927, 172.61332],
  [-43.52912, 172.61307],
  [-43.52897, 172.61270],
  [-43.52891, 172.61241],
  [-43.52887, 172.61206],
  [-43.52885, 172.61168],
  [-43.52886, 172.61137],
  [-43.53193, 172.57226],
  [-43.53192, 172.57199],
  [-43.53187, 172.57182],
  [-43.52630, 172.55355],
  [-43.52631, 172.55345],
  [-43.52634, 172.55333],
  [-43.53280, 172.54960],
  [-43.53284, 172.54946],
  [-43.53287, 172.54934],
  [-43.53228, 172.54735]
];

const plimptonPark = [
  [-43.53193, 172.57226],
  [-43.53230, 172.56750],
  [-43.53239, 172.56711],
  [-43.53248, 172.56681],
  [-43.53260, 172.56653],
  [-43.54071, 172.55060],
  [-43.54125, 172.54961],
  [-43.54142, 172.54956],
  [-43.54164, 172.54954],
  [-43.54327, 172.54976]
];

const racecourseLink = [
  [-43.53250, 172.54809],
  [-43.53251, 172.54809],
  [-43.53257, 172.54811],
  [-43.53263, 172.54806],
  [-43.53368, 172.54605],
  [-43.53375, 172.54600],
  [-43.53382, 172.54601],
  [-43.53564, 172.54780],
  [-43.53571, 172.54786],
  [-43.53583, 172.54789],
  [-43.53753, 172.54796],
  [-43.53762, 172.54797],
  [-43.53771, 172.54801],
  [-43.54056, 172.55061],
  [-43.54064, 172.55063],
  [-43.54071, 172.55060]
];

const lichfieldStreetLink = [
  [-43.53408, 172.63662],
  [-43.53416, 172.63654],
  [-43.53420, 172.63642],
  [-43.53423, 172.63258],
  [-43.53426, 172.63247],
  [-43.53429, 172.63237]
];

export const line9: Line = {
  name: 'Line 9',
  state: 'NZ',
  segments: [
    {
      segments: [heritage],
      history: {
        opened: {
          date: '1905-11-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram', //steam
        },
        trackChange: [{
          date: '1906-03-12',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-06-14',
          status: 'closed'
        }, {
          date: '2015-02-12',
          status: 'open'
        }]
      }
    },
    {
      segments: [riccarton],
      history: {
        opened: {
          date: '1905-11-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram', //steam
        },
        trackChange: [{
          date: '1906-03-12',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-06-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [plimptonPark, racecourseLink],
      history: {
        opened: {
          date: '1915-12-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-06-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lichfieldStreetLink],
      history: {
        opened: {
          date: '1922-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-06-14',
          status: 'closed'
        }]
      }
    }
  ]
}
