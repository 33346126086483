import { Line } from "../../../trackTypes";

const brightEverton = [
  [-36.3180, 146.3651],
  [-36.3168, 146.3684],
  [-36.3163, 146.3704],
  [-36.3164, 146.3732],
  [-36.3170, 146.3750],
  [-36.3434, 146.4325],
  [-36.3756, 146.5032],
  [-36.3833, 146.5200],
  [-36.3855, 146.5234],
  [-36.4050, 146.5444],
  [-36.4077, 146.5471]
];

const evertonMyrtleford = [
  [-36.4077, 146.5471],
  [-36.4123, 146.5496],
  [-36.4146, 146.5500],
  [-36.4300, 146.5506],
  [-36.4474, 146.5513],
  [-36.4487, 146.5516],
  [-36.4527, 146.5538],
  [-36.4537, 146.5547],
  [-36.4542, 146.5557],
  [-36.4660, 146.5834],
  [-36.4719, 146.5937],
  [-36.4728, 146.5957],
  [-36.4788, 146.6153],
  [-36.4802, 146.6179],
  [-36.4827, 146.6207],
  [-36.4835, 146.6221],
  [-36.4850, 146.6289],
  [-36.4866, 146.6331],
  [-36.4880, 146.6398],
  [-36.4887, 146.6415],
  [-36.4894, 146.6424],
  [-36.4905, 146.6435],
  [-36.4912, 146.6448],
  [-36.4914, 146.6463],
  [-36.4912, 146.6478],
  [-36.4900, 146.6503],
  [-36.4893, 146.6532],
  [-36.4895, 146.6550],
  [-36.4904, 146.6563],
  [-36.4915, 146.6575],
  [-36.4926, 146.6585],
  [-36.4938, 146.6604],
  [-36.4951, 146.6634],
  [-36.4962, 146.6645],
  [-36.4974, 146.6650],
  [-36.4993, 146.6652],
  [-36.5006, 146.6658],
  [-36.5019, 146.6674],
  [-36.5040, 146.6730],
  [-36.5054, 146.6753],
  [-36.5072, 146.6767],
  [-36.5094, 146.6776],
  [-36.5169, 146.6783],
  [-36.5185, 146.6790],
  [-36.5229, 146.6823],
  [-36.5312, 146.6906],
  [-36.5327, 146.6917],
  [-36.5346, 146.6922],
  [-36.5380, 146.6919],
  [-36.5400, 146.6922],
  [-36.5508, 146.6966],
  [-36.5522, 146.6980],
  [-36.5529, 146.6999],
  [-36.5529, 146.7016],
  [-36.5514, 146.7099],
  [-36.5515, 146.7114],
  [-36.5520, 146.7130],
  [-36.5588, 146.7243]
];

const myrtlefordBright = [
  [-36.5588, 146.7243],
  [-36.5599, 146.7260],
  [-36.5618, 146.7278],
  [-36.5661, 146.7299],
  [-36.5691, 146.7311],
  [-36.5705, 146.7321],
  [-36.5716, 146.7336],
  [-36.5722, 146.7349],
  [-36.5752, 146.7461],
  [-36.5759, 146.7475],
  [-36.5774, 146.7489],
  [-36.5811, 146.7506],
  [-36.5821, 146.7512],
  [-36.5830, 146.7522],
  [-36.5883, 146.7603],
  [-36.5917, 146.7643],
  [-36.5969, 146.7695],
  [-36.5993, 146.7730],
  [-36.6077, 146.7884],
  [-36.6107, 146.7969],
  [-36.6121, 146.7999],
  [-36.6163, 146.8060],
  [-36.6190, 146.8095],
  [-36.6237, 146.8213],
  [-36.6244, 146.8242],
  [-36.6249, 146.8340],
  [-36.6252, 146.8360],
  [-36.6258, 146.8377],
  [-36.6301, 146.8476],
  [-36.6308, 146.8498],
  [-36.6332, 146.8587],
  [-36.6342, 146.8604],
  [-36.6359, 146.8619],
  [-36.6428, 146.8649],
  [-36.6453, 146.8654],
  [-36.6573, 146.8706],
  [-36.6597, 146.8719],
  [-36.6625, 146.8737],
  [-36.6727, 146.8833],
  [-36.6770, 146.8896],
  [-36.6782, 146.8907],
  [-36.6807, 146.8921],
  [-36.6819, 146.8932],
  [-36.6852, 146.8973],
  [-36.6879, 146.9028],
  [-36.6908, 146.9075],
  [-36.7028, 146.9250],
  [-36.7148, 146.9416],
  [-36.7172, 146.9433],
  [-36.7277, 146.9486],
  [-36.7288, 146.9497],
  [-36.7296, 146.9512],
  [-36.7300, 146.9531],
  [-36.7303, 146.9596]
];

export const bright: Line = {
  name: 'Bright',
  state: 'VIC',
  segments: [
    {
      segments: [brightEverton],
      history: {
        opened: {
          date: '1875-07-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-11-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [evertonMyrtleford],
      history: {
        opened: {
          date: '1883-12-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [myrtlefordBright],
      history: {
        opened: {
          date: '1890-10-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-11-30',
          status: 'closed'
        }]
      }
    }
  ]
};
