import { Line } from "../../trackTypes";

const line = [
  [-35.0938, 139.9002],
  [-35.0939, 139.9027],
  [-35.0941, 139.9036],
  [-35.1044, 139.9282],
  [-35.1059, 139.9305],
  [-35.1121, 139.9375],
  [-35.1138, 139.9389],
  [-35.1188, 139.9418],
  [-35.1214, 139.9441],
  [-35.1392, 139.9744],
  [-35.1454, 139.9831],
  [-35.1632, 140.0230],
  [-35.1702, 140.0486],
  [-35.1712, 140.0600],
  [-35.1709, 140.0637],
  [-35.1674, 140.0801],
  [-35.1670, 140.0857],
  [-35.1685, 140.1259],
  [-35.1721, 140.1660],
  [-35.1733, 140.1727],
  [-35.1733, 140.1757],
  [-35.1696, 140.1937],
  [-35.1672, 140.1993],
  [-35.1590, 140.2100],
  [-35.1572, 140.2144],
  [-35.1572, 140.2186],
  [-35.1584, 140.2244],
  [-35.1589, 140.2589],
  [-35.1535, 140.2988],
  [-35.1506, 140.3078],
  [-35.1502, 140.3105],
  [-35.1497, 140.3364],
  [-35.1557, 140.3741],
  [-35.1561, 140.3864],
  [-35.1518, 140.4125],
  [-35.1511, 140.4252],
  [-35.1519, 140.4377],
  [-35.1496, 140.4635],
  [-35.1513, 140.4730],
  [-35.1511, 140.4752],
  [-35.1494, 140.4796],
  [-35.1482, 140.4810],
  [-35.1466, 140.4828],
  [-35.1458, 140.4846],
  [-35.1405, 140.5146],
  [-35.1397, 140.5246],
  [-35.1330, 140.5462],
  [-35.1326, 140.5486],
  [-35.1325, 140.5591],
  [-35.1323, 140.5613],
  [-35.1236, 140.5980],
  [-35.1194, 140.6107],
  [-35.1022, 140.6436],
  [-35.0986, 140.6525],
  [-35.0783, 140.6828],
  [-35.0763, 140.6840],
  [-35.0741, 140.6840],
  [-35.0727, 140.6831],
  [-35.0708, 140.6812],
  [-35.0685, 140.6801],
  [-35.0658, 140.6810],
  [-35.0644, 140.6826],
  [-35.0555, 140.7065],
  [-35.0541, 140.7088],
  [-35.0443, 140.7173],
  [-35.0358, 140.7262],
  [-35.0304, 140.7294],
  [-35.0075, 140.7507],
  [-35.0061, 140.7529],
  [-34.9998, 140.7734],
  [-34.9979, 140.7760],
  [-34.9897, 140.7819],
  [-34.9785, 140.7924],
  [-34.9759, 140.7963],
  [-34.9730, 140.7990],
  [-34.9714, 140.8012],
  [-34.9698, 140.8069],
  [-34.9656, 140.8139],
  [-34.9620, 140.8159],
  [-34.9604, 140.8176],
  [-34.9510, 140.8467],
  [-34.9503, 140.8522],
  [-34.9499, 140.8662],
  [-34.9458, 140.8821],
  [-34.9391, 140.8992],
  [-34.9324, 140.9080],
  [-34.9295, 140.9147]
];

export const peebinga: Line = {
  name: 'Peebinga',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-12-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-12-07',
          status: 'closed'
        }]
      }
    }
  ]
};
