import { Line } from "../../trackTypes";

const brownHillLoop = [
  [-30.7466, 121.4794],
  [-30.7480, 121.4811],
  [-30.7504, 121.4862],
  [-30.7517, 121.4877],
  [-30.7546, 121.4903],
  [-30.7551, 121.4914],
  [-30.7554, 121.4929],
  [-30.7554, 121.4937],
  [-30.7558, 121.4952],
  [-30.7574, 121.4981],
  [-30.7583, 121.4990],
  [-30.7602, 121.5020],
  [-30.7629, 121.5049],
  [-30.7658, 121.5063],
  [-30.7686, 121.5070],
  [-30.7697, 121.5074],
  [-30.7715, 121.5098],
  [-30.7730, 121.5108],
  [-30.7754, 121.5117],
  [-30.7768, 121.5128],
  [-30.7788, 121.5160],
  [-30.7834, 121.5178],
  [-30.7845, 121.5178],
  [-30.7855, 121.5174],
  [-30.7876, 121.5160],
  [-30.7897, 121.5152],
  [-30.7903, 121.5139],
  [-30.7903, 121.5123],
];

const boulderCityLoop = [
  [-30.7719, 121.4963],
  [-30.7733, 121.4962],
  [-30.7771, 121.4927],
  [-30.7779, 121.4922],
  [-30.7788, 121.4919],
  [-30.7851, 121.4929],
  [-30.7859, 121.4934],
  [-30.7864, 121.4939],
  [-30.7867, 121.4948],
  [-30.7877, 121.5027],
  [-30.7876, 121.5049],
  [-30.7872, 121.5060],
  [-30.7870, 121.5073],
  [-30.7874, 121.5086]
];

export const boulderLoopline: Line = {
  name: 'Boulder Loopline',
  state: 'WA',
  segments: [
    {
      segments: [brownHillLoop],
      history: {
        opened: {
          date: '1902-03-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boulderCityLoop],
      history: {
        opened: {
          date: '1897-11-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1900-01-01',
          tracks: 2
        }, {
          date: '1948-11-11',
          status: 'closed'
        }]
      }
    }
  ]
};
