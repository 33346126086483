import { Line } from "../../trackTypes";

const launcestonJunctionDeloraine = [
  [-41.5544, 147.2172],
  [-41.5560, 147.2166],
  [-41.5567, 147.2163],
  [-41.5579, 147.2158],
  [-41.5616, 147.2154],
  [-41.5633, 147.2156],
  [-41.5646, 147.2161],
  [-41.5658, 147.2159],
  [-41.5673, 147.2149],
  [-41.5683, 147.2131],
  [-41.5687, 147.2107],
  [-41.5685, 147.2090],
  [-41.5675, 147.2056],
  [-41.5667, 147.2041],
  [-41.5662, 147.2028],
  [-41.5659, 147.2015],
  [-41.5657, 147.1996],
  [-41.5653, 147.1982],
  [-41.5631, 147.1869],
  [-41.5630, 147.1853],
  [-41.5631, 147.1843],
  [-41.5644, 147.1815],
  [-41.5653, 147.1803],
  [-41.5658, 147.1796],
  [-41.5662, 147.1787],
  [-41.5668, 147.1758],
  [-41.5674, 147.1744],
  [-41.5715, 147.1671],
  [-41.5743, 147.1635],
  [-41.5779, 147.1601],
  [-41.5789, 147.1580],
  [-41.5816, 147.1390],
  [-41.5873, 147.1185],
  [-41.5979, 147.0984],
  [-41.5996, 147.0936],
  [-41.6010, 147.0867],
  [-41.6018, 147.0849],
  [-41.6027, 147.0837],
  [-41.6045, 147.0823],
  [-41.6055, 147.0808],
  [-41.6061, 147.0784],
  [-41.6058, 147.0764],
  [-41.6043, 147.0738],
  [-41.6036, 147.0721],
  [-41.6035, 147.0700],
  [-41.6052, 147.0620],
  [-41.6097, 147.0276],
  [-41.6094, 147.0236],
  [-41.6059, 147.0149],
  [-41.6055, 147.0128],
  [-41.6052, 147.0106],
  [-41.6041, 147.0075],
  [-41.5866, 146.9867],
  [-41.5858, 146.9850],
  [-41.5857, 146.9830],
  [-41.5861, 146.9810],
  [-41.5894, 146.9763],
  [-41.5903, 146.9741],
  [-41.5906, 146.9713],
  [-41.5901, 146.9689],
  [-41.5890, 146.9666],
  [-41.5634, 146.9389],
  [-41.5424, 146.9162],
  [-41.5397, 146.9128],
  [-41.5375, 146.9086],
  [-41.5358, 146.9038],
  [-41.5233, 146.8581],
  [-41.5229, 146.8545],
  [-41.5231, 146.8518],
  [-41.5247, 146.8445],
  [-41.5248, 146.8434],
  [-41.5247, 146.8408],
  [-41.5243, 146.8395],
  [-41.5232, 146.8376],
  [-41.5226, 146.8357],
  [-41.5217, 146.8294],
  [-41.5220, 146.8271],
  [-41.5260, 146.8166],
  [-41.5274, 146.8103],
  [-41.5280, 146.7955],
  [-41.5276, 146.7931],
  [-41.5244, 146.7862],
  [-41.5209, 146.7748],
  [-41.5202, 146.7689],
  [-41.5202, 146.7437],
  [-41.5196, 146.7392],
  [-41.5159, 146.7278],
  [-41.5154, 146.7243],
  [-41.5163, 146.7201],
  [-41.5200, 146.7125],
  [-41.5211, 146.7081],
  [-41.5211, 146.7044],
  [-41.5204, 146.7013],
  [-41.5196, 146.6993],
  [-41.5192, 146.6970],
  [-41.5216, 146.6679],
  [-41.5235, 146.6602]
];

const bridgewaterClaremontJunction = [
  [-42.7372, 147.2259],
  [-42.7381, 147.2256],
  [-42.7385, 147.2258],
  [-42.7389, 147.2260],
  [-42.7395, 147.2260],
  [-42.7430, 147.2251],
  [-42.7435, 147.2251],
  [-42.7483, 147.2244],
  [-42.7492, 147.2246],
  [-42.7497, 147.2250],
  [-42.7502, 147.2258],
  [-42.7504, 147.2262],
  [-42.7504, 147.2265],
  [-42.7504, 147.2277],
  [-42.7504, 147.2281],
  [-42.7506, 147.2284],
  [-42.7510, 147.2294],
  [-42.7517, 147.2316],
  [-42.7520, 147.2323],
  [-42.7525, 147.2330],
  [-42.7564, 147.2358],
  [-42.7572, 147.2362],
  [-42.7586, 147.2366],
  [-42.7592, 147.2370],
  [-42.7598, 147.2376],
  [-42.7614, 147.2408],
  [-42.7620, 147.2417],
  [-42.7626, 147.2421],
  [-42.7633, 147.2424],
  [-42.7643, 147.2434],
  [-42.7649, 147.2447],
  [-42.7650, 147.2456],
  [-42.7653, 147.2467],
  [-42.7658, 147.2475],
  [-42.7671, 147.2490],
  [-42.7682, 147.2512],
  [-42.7692, 147.2526],
  [-42.7705, 147.2540],
  [-42.7715, 147.2547],
  [-42.7724, 147.2551],
  [-42.7728, 147.2554],
  [-42.7734, 147.2562],
  [-42.7742, 147.2568],
  [-42.7749, 147.2573],
  [-42.7761, 147.2577],
  [-42.7769, 147.2577],
  [-42.7779, 147.2576],
  [-42.7785, 147.2571],
  [-42.7789, 147.2564],
  [-42.7791, 147.2553],
  [-42.7794, 147.2545],
  [-42.7799, 147.2539],
  [-42.7805, 147.2536],
  [-42.7825, 147.2535],
  [-42.7839, 147.2530],
  [-42.7846, 147.2524],
  [-42.7853, 147.2520],
  [-42.7862, 147.2522],
  [-42.7869, 147.2528],
  [-42.7874, 147.2540],
  [-42.7881, 147.2550],
  [-42.7891, 147.2557],
  [-42.7935, 147.2565]
];

const claremontJunctionTransportMuseum = [
  [-42.7935, 147.2565],
  [-42.7945, 147.2564],
  [-42.7962, 147.2553],
  [-42.7970, 147.2544],
  [-42.7979, 147.2537],
  [-42.7993, 147.2534],
  [-42.8007, 147.2537]
];

const transportMuseum = [
  [-42.8007, 147.2537],
  [-42.8034, 147.2544],
  [-42.8041, 147.2543],
  [-42.8046, 147.2540],
  [-42.8054, 147.2531],
  [-42.8062, 147.2527],
  [-42.8068, 147.2528],
  [-42.8094, 147.2542],
  [-42.8103, 147.2544],
  [-42.8111, 147.2543],
  [-42.8121, 147.2539],
  [-42.8131, 147.2537],
  [-42.8141, 147.2539],
  [-42.8151, 147.2545],
  [-42.8162, 147.2556],
  [-42.8168, 147.2564],
  [-42.8201, 147.2618],
  [-42.8213, 147.2634],
  [-42.8297, 147.2718],
  [-42.8307, 147.2733],
  [-42.8314, 147.2746]
];

const bridgeWaterMidlandHighwayDeviationStart = [
  [-42.7372, 147.2259],
  [-42.7369, 147.2260],
  [-42.7365, 147.2264],
  [-42.7363, 147.2268],
  [-42.7360, 147.2276],
  [-42.7355, 147.2284],
  [-42.7349, 147.2291],
  [-42.7348, 147.2294],
  [-42.7347, 147.2297],
  [-42.7340, 147.2346],
  [-42.7335, 147.2357],
  [-42.7327, 147.2366],
  [-42.7316, 147.2368],
  [-42.7307, 147.2364],
  [-42.7301, 147.2357],
  [-42.7295, 147.2346],
  [-42.7288, 147.2340],
  [-42.7283, 147.2339],
  [-42.7277, 147.2341],
  [-42.7245, 147.2358],
  [-42.7237, 147.2358],
  [-42.7222, 147.2351],
  [-42.7218, 147.2351],
  [-42.7193, 147.2361],
  [-42.7171, 147.2362],
  [-42.7166, 147.2365],
  [-42.7163, 147.2369],
  [-42.7146, 147.2400],
  [-42.7141, 147.2423],
  [-42.7131, 147.2447],
];

const midlandHighwayOld = [
  [-42.7131, 147.2447],
  [-42.7130, 147.2453],
  [-42.7131, 147.2472],
  [-42.7130, 147.2477],
  [-42.7126, 147.2482],
  [-42.7117, 147.2487],
  [-42.7114, 147.2490]
];

const midlandHighwayDeviation = [
  [-42.7131, 147.2447],
  [-42.7120, 147.2480],
  [-42.7114, 147.2490]
];

const midlandHighwayDeviationEndRhynastonTunnel = [
  [-42.7114, 147.2490],
  [-42.7105, 147.2499],
  [-42.7084, 147.2539],
  [-42.7081, 147.2551],
  [-42.7080, 147.2570],
  [-42.7078, 147.2585],
  [-42.7076, 147.2590],
  [-42.7069, 147.2600],
  [-42.7068, 147.2605],
  [-42.7068, 147.2611],
  [-42.7071, 147.2621],
  [-42.7069, 147.2633],
  [-42.7062, 147.2642],
  [-42.7052, 147.2645],
  [-42.7039, 147.2641],
  [-42.7039, 147.2641],
  [-42.7026, 147.2649],
  [-42.7022, 147.2656],
  [-42.6980, 147.2830],
  [-42.6980, 147.2841],
  [-42.6987, 147.2878],
  [-42.6988, 147.2890],
  [-42.6983, 147.2934],
  [-42.6979, 147.2945],
  [-42.6972, 147.2953],
  [-42.6964, 147.2956],
  [-42.6955, 147.2957],
  [-42.6950, 147.2960],
  [-42.6948, 147.2963],
  [-42.6943, 147.2976],
  [-42.6940, 147.2979],
  [-42.6936, 147.2981],
  [-42.6933, 147.2981],
  [-42.6926, 147.2979],
  [-42.6918, 147.2980],
  [-42.6913, 147.2986],
  [-42.6910, 147.2993],
  [-42.6899, 147.3207],
  [-42.6896, 147.3239],
  [-42.6901, 147.3303],
  [-42.6895, 147.3340],
  [-42.6884, 147.3365],
  [-42.6879, 147.3378],
  [-42.6873, 147.3411],
  [-42.6863, 147.3449],
  [-42.6853, 147.3506],
  [-42.6849, 147.3514],
  [-42.6845, 147.3518],
  [-42.6831, 147.3527],
  [-42.6823, 147.3527],
  [-42.6812, 147.3523],
  [-42.6793, 147.3523],
  [-42.6773, 147.3538],
  [-42.6724, 147.3609],
  [-42.6698, 147.3637],
  [-42.6688, 147.3651],
  [-42.6683, 147.3665],
  [-42.6672, 147.3719],
  [-42.6668, 147.3777],
  [-42.6670, 147.3798],
  [-42.6675, 147.3819],
  [-42.6705, 147.3901],
  [-42.6710, 147.3924],
  [-42.6717, 147.3939],
  [-42.6719, 147.3953],
  [-42.6729, 147.3989],
  [-42.6726, 147.3994],
  [-42.6719, 147.4003],
  [-42.6717, 147.4011],
  [-42.6719, 147.4017],
  [-42.6725, 147.4023],
  [-42.6728, 147.4032],
  [-42.6726, 147.4039],
  [-42.6722, 147.4047],
  [-42.6718, 147.4082],
  [-42.6714, 147.4086],
  [-42.6708, 147.4088],
  [-42.6704, 147.4086],
  [-42.6699, 147.4079],
  [-42.6695, 147.4076],
  [-42.6688, 147.4072],
  [-42.6682, 147.4072],
  [-42.6678, 147.4075],
  [-42.6676, 147.4081],
  [-42.6677, 147.4088],
  [-42.6679, 147.4096],
  [-42.6683, 147.4109],
  [-42.6683, 147.4116],
  [-42.6681, 147.4129],
  [-42.6682, 147.4139],
  [-42.6687, 147.4153],
  [-42.6688, 147.4162],
  [-42.6687, 147.4169],
  [-42.6689, 147.4175],
  [-42.6697, 147.4181],
  [-42.6702, 147.4188],
  [-42.6705, 147.4197],
  [-42.6703, 147.4205],
  [-42.6698, 147.4210],
  [-42.6692, 147.4210],
  [-42.6672, 147.4196],
  [-42.6667, 147.4194],
  [-42.6662, 147.4195],
  [-42.6609, 147.4222],
  [-42.6604, 147.4223],
  [-42.6597, 147.4221],
  [-42.6593, 147.4221],
  [-42.6579, 147.4229],
  [-42.6576, 147.4234],
  [-42.6575, 147.4239],
  [-42.6576, 147.4249],
  [-42.6575, 147.4256],
  [-42.6560, 147.4279],
  [-42.6556, 147.4282],
  [-42.6552, 147.4283],
  [-42.6524, 147.4275],
  [-42.6520, 147.4271],
  [-42.6511, 147.4262],
  [-42.6508, 147.4258],
  [-42.6496, 147.4251],
  [-42.6492, 147.4250],
  [-42.6454, 147.4261],
  [-42.6449, 147.4265],
  [-42.6442, 147.4273],
  [-42.6434, 147.4276],
  [-42.6430, 147.4275],
  [-42.6427, 147.4272],
  [-42.6414, 147.4257],
  [-42.6409, 147.4253],
  [-42.6390, 147.4248],
  [-42.6382, 147.4249],
  [-42.6360, 147.4255],
  [-42.6354, 147.4255],
  [-42.6311, 147.4238],
  [-42.6305, 147.4238],
  [-42.6294, 147.4241],
  [-42.6290, 147.4241],
  [-42.6286, 147.4238],
  [-42.6280, 147.4231],
  [-42.6276, 147.4228],
  [-42.6263, 147.4223],
  [-42.6259, 147.4223],
  [-42.6253, 147.4224],
  [-42.6248, 147.4222],
  [-42.6244, 147.4219],
  [-42.6242, 147.4214],
  [-42.6237, 147.4189],
  [-42.6234, 147.4184],
  [-42.6230, 147.4181],
  [-42.6218, 147.4177],
  [-42.6214, 147.4173],
  [-42.6194, 147.4139],
  [-42.6190, 147.4135],
  [-42.6186, 147.4134],
  [-42.6163, 147.4133],
  [-42.6159, 147.4131],
  [-42.6154, 147.4129],
  [-42.6147, 147.4130],
  [-42.6138, 147.4134],
  [-42.6133, 147.4136],
  [-42.6120, 147.4134],
  [-42.6115, 147.4131],
  [-42.6110, 147.4125],
  [-42.6107, 147.4117],
  [-42.6100, 147.4076],
  [-42.6095, 147.4066],
  [-42.6087, 147.4059],
  [-42.6072, 147.4053],
  [-42.6060, 147.4044],
  [-42.6044, 147.4036],
  [-42.6039, 147.4037],
  [-42.6033, 147.4042],
  [-42.6027, 147.4042],
  [-42.6022, 147.4038],
  [-42.6015, 147.4011],
  [-42.6011, 147.4004],
  [-42.6004, 147.4000],
  [-42.6000, 147.3992],
  [-42.5996, 147.3978],
  [-42.5988, 147.3966],
  [-42.5983, 147.3963],
  [-42.5977, 147.3964],
  [-42.5972, 147.3961],
  [-42.5939, 147.3901],
  [-42.5937, 147.3892],
  [-42.5934, 147.3883],
  [-42.5928, 147.3876],
  [-42.5902, 147.3872],
  [-42.5865, 147.3874],
  [-42.5856, 147.3872],
  [-42.5836, 147.3860],
  [-42.5828, 147.3844],
  [-42.5824, 147.3841],
  [-42.5819, 147.3841],
  [-42.5811, 147.3846],
  [-42.5806, 147.3851],
  [-42.5802, 147.3852],
  [-42.5798, 147.3851],
  [-42.5794, 147.3848],
  [-42.5786, 147.3834],
  [-42.5782, 147.3831],
  [-42.5778, 147.3831],
  [-42.5773, 147.3835],
  [-42.5771, 147.3843],
  [-42.5773, 147.3854],
  [-42.5773, 147.3862],
  [-42.5770, 147.3867],
  [-42.5763, 147.3871],
  [-42.5754, 147.3879],
  [-42.5727, 147.3888],
  [-42.5722, 147.3887],
  [-42.5718, 147.3883],
  [-42.5717, 147.3876],
  [-42.5718, 147.3867],
  [-42.5725, 147.3849],
  [-42.5725, 147.3843],
  [-42.5723, 147.3838],
  [-42.5713, 147.3826],
  [-42.5707, 147.3823],
  [-42.5704, 147.3821],
  [-42.5693, 147.3809],
  [-42.5688, 147.3805],
  [-42.5673, 147.3800],
  [-42.5669, 147.3795],
  [-42.5660, 147.3775],
  [-42.5647, 147.3755],
  [-42.5627, 147.3733],
  [-42.5617, 147.3728],
  [-42.5602, 147.3717],
  [-42.5588, 147.3715],
  [-42.5573, 147.3723],
  [-42.5567, 147.3732],
  [-42.5554, 147.3739],
  [-42.5543, 147.3741],
  [-42.5538, 147.3743],
  [-42.5531, 147.3742],
  [-42.5487, 147.3711],
  [-42.5474, 147.3698],
  [-42.5468, 147.3697],
  [-42.5463, 147.3701],
  [-42.5459, 147.3709],
  [-42.5453, 147.3713],
  [-42.5448, 147.3714],
  [-42.5441, 147.3711],
  [-42.5412, 147.3685],
  [-42.5403, 147.3674],
  [-42.5388, 147.3664],
  [-42.5291, 147.3578],
  [-42.5282, 147.3575],
  [-42.5274, 147.3574],
  [-42.5269, 147.3572],
  [-42.5226, 147.3534],
  [-42.5194, 147.3524],
  [-42.5185, 147.3524],
  [-42.5178, 147.3523],
  [-42.5173, 147.3518],
  [-42.5162, 147.3497],
  [-42.5153, 147.3494],
  [-42.5147, 147.3495],
  [-42.5141, 147.3499],
  [-42.5136, 147.3500],
  [-42.5121, 147.3496],
  [-42.5110, 147.3489],
  [-42.5088, 147.3481],
  [-42.5070, 147.3483],
  [-42.5062, 147.3492],
  [-42.5058, 147.3505],
  [-42.5057, 147.3511],
  [-42.5044, 147.3533],
  [-42.5019, 147.3556],
  [-42.4987, 147.3573],
  [-42.4983, 147.3573],
  [-42.4975, 147.3571],
  [-42.4969, 147.3575],
  [-42.4967, 147.3583],
  [-42.4970, 147.3591],
  [-42.4975, 147.3595],
  [-42.4986, 147.3595],
  [-42.4996, 147.3597],
  [-42.4999, 147.3599],
  [-42.5003, 147.3604],
  [-42.5011, 147.3625],
  [-42.5023, 147.3648],
  [-42.5027, 147.3664],
  [-42.5027, 147.3675],
  [-42.5028, 147.3683],
  [-42.5034, 147.3705],
  [-42.5040, 147.3714],
  [-42.5045, 147.3727],
  [-42.5044, 147.3736],
  [-42.5042, 147.3745],
  [-42.5042, 147.3753],
  [-42.5038, 147.3760],
  [-42.5037, 147.3769],
  [-42.5038, 147.3775],
  [-42.5045, 147.3782],
  [-42.5047, 147.3789],
  [-42.5047, 147.3795],
  [-42.5041, 147.3802],
  [-42.5032, 147.3804],
  [-42.5028, 147.3807],
  [-42.5025, 147.3812],
  [-42.5023, 147.3821],
  [-42.5019, 147.3827],
  [-42.5010, 147.3830],
  [-42.5006, 147.3835],
  [-42.5000, 147.3846],
  [-42.4986, 147.3856],
  [-42.4981, 147.3864],
  [-42.4976, 147.3867],
  [-42.4967, 147.3870],
  [-42.4960, 147.3875],
  [-42.4952, 147.3875],
  [-42.4944, 147.3871],
  [-42.4939, 147.3866],
  [-42.4935, 147.3866],
  [-42.4932, 147.3867],
  [-42.4927, 147.3871],
  [-42.4923, 147.3872],
  [-42.4914, 147.3870],
  [-42.4901, 147.3864],
  [-42.4896, 147.3864],
  [-42.4893, 147.3865]
];

const rhynastonTunnel = [
  [-42.4893, 147.3865],
  [-42.4809, 147.3873]
];

const rhynastonTunnelTinDish = [
  [-42.4809, 147.3873],
  [-42.4803, 147.3875],
  [-42.4796, 147.3881],
  [-42.4791, 147.3882],
  [-42.4785, 147.3880],
  [-42.4783, 147.3876],
  [-42.4781, 147.3871],
  [-42.4781, 147.3848],
  [-42.4778, 147.3839],
  [-42.4773, 147.3832],
  [-42.4758, 147.3823],
  [-42.4743, 147.3821],
  [-42.4734, 147.3817],
  [-42.4725, 147.3815],
  [-42.4722, 147.3814],
  [-42.4710, 147.3806],
  [-42.4707, 147.3800],
  [-42.4700, 147.3796],
  [-42.4697, 147.3792],
  [-42.4694, 147.3785],
  [-42.4689, 147.3781],
  [-42.4683, 147.3779],
  [-42.4679, 147.3775],
  [-42.4675, 147.3769],
  [-42.4672, 147.3767],
  [-42.4669, 147.3766],
  [-42.4651, 147.3767],
  [-42.4643, 147.3764],
  [-42.4638, 147.3758],
  [-42.4634, 147.3755],
  [-42.4613, 147.3753],
  [-42.4606, 147.3750],
  [-42.4576, 147.3727],
  [-42.4567, 147.3723],
  [-42.4497, 147.3720],
  [-42.4386, 147.3744],
  [-42.4379, 147.3748],
  [-42.4370, 147.3759],
  [-42.4353, 147.3768],
  [-42.4339, 147.3771],
  [-42.4334, 147.3774],
  [-42.4323, 147.3784],
  [-42.4263, 147.3813],
  [-42.4219, 147.3824],
  [-42.4196, 147.3818],
  [-42.4181, 147.3819],
  [-42.4170, 147.3825],
  [-42.4132, 147.3862],
  [-42.4083, 147.3891],
  [-42.4055, 147.3900],
  [-42.4015, 147.3906],
  [-42.3958, 147.3908],
  [-42.3918, 147.3921],
  [-42.3823, 147.3936],
  [-42.3810, 147.3943],
  [-42.3744, 147.4014],
  [-42.3735, 147.4018],
  [-42.3722, 147.4021],
  [-42.3707, 147.4028],
  [-42.3698, 147.4029],
  [-42.3634, 147.4019],
  [-42.3521, 147.4036],
  [-42.3513, 147.4039],
  [-42.3495, 147.4050],
  [-42.3449, 147.4088],
  [-42.3436, 147.4106],
  [-42.3362, 147.4250],
  [-42.3354, 147.4283],
  [-42.3346, 147.4380],
  [-42.3344, 147.4389],
  [-42.3325, 147.4432],
  [-42.3320, 147.4453],
  [-42.3315, 147.4467],
  [-42.3310, 147.4474],
  [-42.3304, 147.4481],
  [-42.3304, 147.4481]
];

const nalaOld = [
  [-42.3304, 147.4481],
  [-42.3299, 147.4486],
  [-42.3293, 147.4490],
  [-42.3276, 147.4499],
  [-42.3270, 147.4506],
  [-42.3250, 147.4537],
  [-42.3247, 147.4546],
  [-42.3245, 147.4566],
  [-42.3242, 147.4577],
  [-42.3238, 147.4586],
  [-42.3234, 147.4589],
  [-42.3230, 147.4590],
  [-42.3211, 147.4586],
  [-42.3205, 147.4583],
  [-42.3202, 147.4581],
  [-42.3197, 147.4579],
  [-42.3191, 147.4579],
  [-42.3185, 147.4576],
  [-42.3176, 147.4569],
  [-42.3169, 147.4566],
  [-42.3148, 147.4564],
  [-42.3137, 147.4566]
];

const nalaDeviation = [
  [-42.3304, 147.4481],
  [-42.3294, 147.4502],
  [-42.3295, 147.4516],
  [-42.3298, 147.4525],
  [-42.3298, 147.4536],
  [-42.3294, 147.4546],
  [-42.3288, 147.4550],
  [-42.3282, 147.4550],
  [-42.3276, 147.4550],
  [-42.3270, 147.4552],
  [-42.3266, 147.4557],
  [-42.3260, 147.4575],
  [-42.3260, 147.4584],
  [-42.3266, 147.4594],
  [-42.3273, 147.4598],
  [-42.3283, 147.4599],
  [-42.3299, 147.4604],
  [-42.3310, 147.4604],
  [-42.3317, 147.4609],
  [-42.3323, 147.4620],
  [-42.3322, 147.4632],
  [-42.3318, 147.4638],
  [-42.3297, 147.4659],
  [-42.3290, 147.4662],
  [-42.3281, 147.4662],
  [-42.3274, 147.4665],
  [-42.3264, 147.4676],
  [-42.3257, 147.4680],
  [-42.3251, 147.4681],
  [-42.3225, 147.4676],
  [-42.3217, 147.4671],
  [-42.3211, 147.4664],
  [-42.3200, 147.4640],
  [-42.3198, 147.4628],
  [-42.3197, 147.4612],
  [-42.3194, 147.4601],
  [-42.3188, 147.4593],
  [-42.3181, 147.4590],
  [-42.3171, 147.4588],
  [-42.3162, 147.4582],
  [-42.3151, 147.4569],
  [-42.3137, 147.4566]
];

const nalaTinDish = [
  [-42.3137, 147.4566],
  [-42.3068, 147.4581],
  [-42.3050, 147.4581],
  [-42.3038, 147.4578],
  [-42.3008, 147.4565],
  [-42.2997, 147.4563],
  [-42.2982, 147.4564],
  [-42.2975, 147.4562],
  [-42.2953, 147.4552],
  [-42.2946, 147.4546],
  [-42.2940, 147.4539],
  [-42.2929, 147.4517],
  [-42.2910, 147.4487],
  [-42.2872, 147.4461],
  [-42.2599, 147.4387]
];

const tinDishOld = [
  [-42.2599, 147.4387],
  [-42.2586, 147.4383],
  [-42.2580, 147.4384],
  [-42.2556, 147.4390],
  [-42.2550, 147.4395],
  [-42.2545, 147.4401],
  [-42.2540, 147.4406],
  [-42.2534, 147.4408],
  [-42.2529, 147.4407],
  [-42.2494, 147.4386],
  [-42.2480, 147.4381],
  [-42.2476, 147.4377],
  [-42.2471, 147.4371],
  [-42.2461, 147.4350],
  [-42.2456, 147.4345],
  [-42.2444, 147.4338],
  [-42.2439, 147.4334],
  [-42.2432, 147.4325],
  [-42.2425, 147.4322],
  [-42.2413, 147.4321],
  [-42.2408, 147.4323],
  [-42.2403, 147.4327],
  [-42.2394, 147.4341],
  [-42.2389, 147.4344],
  [-42.2381, 147.4344],
  [-42.2375, 147.4343],
  [-42.2370, 147.4342],
  [-42.2364, 147.4343],
  [-42.2359, 147.4342],
  [-42.2353, 147.4337],
  [-42.2345, 147.4333],
  [-42.2342, 147.4329],
  [-42.2338, 147.4323],
  [-42.2333, 147.4320],
  [-42.2329, 147.4319],
  [-42.2326, 147.4317],
  [-42.2323, 147.4312],
  [-42.2321, 147.4306],
  [-42.2318, 147.4296],
  [-42.2315, 147.4291],
  [-42.2312, 147.4289],
  [-42.2305, 147.4287],
  [-42.2301, 147.4284],
  [-42.2295, 147.4277],
  [-42.2288, 147.4273],
  [-42.2284, 147.4267],
  [-42.2281, 147.4259],
  [-42.2278, 147.4242],
  [-42.2275, 147.4236],
  [-42.2269, 147.4233],
  [-42.2264, 147.4234],
  [-42.2259, 147.4231],
  [-42.2255, 147.4225],
  [-42.2251, 147.4222],
  [-42.2242, 147.4217],
  [-42.2238, 147.4212],
  [-42.2237, 147.4207],
  [-42.2237, 147.4195],
  [-42.2236, 147.4188],
  [-42.2233, 147.4181],
  [-42.2230, 147.4160],
  [-42.2224, 147.4147],
  [-42.2218, 147.4141],
  [-42.2181, 147.4121]
];

const tinDishDeviation = [
  [-42.2599, 147.4387],
  [-42.2597, 147.4387],
  [-42.2583, 147.4388],
  [-42.2550, 147.4406],
  [-42.2543, 147.4413],
  [-42.2535, 147.4426],
  [-42.2525, 147.4435],
  [-42.2503, 147.4445],
  [-42.2496, 147.4444],
  [-42.2490, 147.4440],
  [-42.2484, 147.4439],
  [-42.2478, 147.4440],
  [-42.2469, 147.4434],
  [-42.2465, 147.4425],
  [-42.2464, 147.4412],
  [-42.2461, 147.4404],
  [-42.2456, 147.4399],
  [-42.2446, 147.4396],
  [-42.2441, 147.4390],
  [-42.2438, 147.4381],
  [-42.2437, 147.4370],
  [-42.2434, 147.4359],
  [-42.2429, 147.4354],
  [-42.2421, 147.4353],
  [-42.2409, 147.4357],
  [-42.2400, 147.4357],
  [-42.2386, 147.4352],
  [-42.2378, 147.4352],
  [-42.2370, 147.4348],
  [-42.2359, 147.4347],
  [-42.2348, 147.4347],
  [-42.2337, 147.4345],
  [-42.2332, 147.4341],
  [-42.2312, 147.4310],
  [-42.2305, 147.4306],
  [-42.2296, 147.4308],
  [-42.2291, 147.4314],
  [-42.2286, 147.4325],
  [-42.2281, 147.4330],
  [-42.2277, 147.4332],
  [-42.2260, 147.4333],
  [-42.2255, 147.4331],
  [-42.2250, 147.4325],
  [-42.2245, 147.4311],
  [-42.2243, 147.4302],
  [-42.2244, 147.4290],
  [-42.2243, 147.4282],
  [-42.2241, 147.4274],
  [-42.2241, 147.4262],
  [-42.2246, 147.4250],
  [-42.2246, 147.4240],
  [-42.2242, 147.4229],
  [-42.2237, 147.4225],
  [-42.2228, 147.4222],
  [-42.2221, 147.4216],
  [-42.2218, 147.4206],
  [-42.2216, 147.4166],
  [-42.2209, 147.4147],
  [-42.2185, 147.4123],
  [-42.2181, 147.4121]
];

const nalaWesternJunction = [
  [-42.2181, 147.4121],
  [-42.2145, 147.4100],
  [-42.2139, 147.4095],
  [-42.2122, 147.4078],
  [-42.2089, 147.4058],
  [-42.2058, 147.4030],
  [-42.2043, 147.4023],
  [-42.2038, 147.4018],
  [-42.2005, 147.3980],
  [-42.1985, 147.3938],
  [-42.1979, 147.3931],
  [-42.1869, 147.3883],
  [-42.1854, 147.3879],
  [-42.1826, 147.3864],
  [-42.1809, 147.3862],
  [-42.1795, 147.3868],
  [-42.1581, 147.4044],
  [-42.1574, 147.4052],
  [-42.1553, 147.4090],
  [-42.1494, 147.4145],
  [-42.1475, 147.4169],
  [-42.1438, 147.4195],
  [-42.1338, 147.4258],
  [-42.1330, 147.4266],
  [-42.1301, 147.4304],
  [-42.1243, 147.4363],
  [-42.1026, 147.4650],
  [-42.1022, 147.4658],
  [-42.0988, 147.4761],
  [-42.0984, 147.4767],
  [-42.0977, 147.4773],
  [-42.0974, 147.4778],
  [-42.0972, 147.4784],
  [-42.0952, 147.4936],
  [-42.0945, 147.4956],
  [-42.0927, 147.4981],
  [-42.0912, 147.4993],
  [-42.0907, 147.4994],
  [-42.0902, 147.4994],
  [-42.0852, 147.4963],
  [-42.0699, 147.4919],
  [-42.0689, 147.4921],
  [-42.0663, 147.4939],
  [-42.0625, 147.4989],
  [-42.0618, 147.4993],
  [-42.0512, 147.4995],
  [-42.0364, 147.4945],
  [-42.0333, 147.4945],
  [-42.0326, 147.4947],
  [-42.0128, 147.5076],
  [-42.0095, 147.5105],
  [-42.0087, 147.5109],
  [-41.9987, 147.5141],
  [-41.9971, 147.5140],
  [-41.9653, 147.5034],
  [-41.9473, 147.5003],
  [-41.9378, 147.4966],
  [-41.9365, 147.4964],
  [-41.9297, 147.4963],
  [-41.9223, 147.4981],
  [-41.9199, 147.4977],
  [-41.9179, 147.4966],
  [-41.9135, 147.4919],
  [-41.9127, 147.4914],
  [-41.9106, 147.4907],
  [-41.8937, 147.4777],
  [-41.8668, 147.4634],
  [-41.8588, 147.4614],
  [-41.8575, 147.4606],
  [-41.8564, 147.4592],
  [-41.8552, 147.4584],
  [-41.8541, 147.4586],
  [-41.8530, 147.4591],
  [-41.8517, 147.4589],
  [-41.8390, 147.4479],
  [-41.8361, 147.4469],
  [-41.8352, 147.4461],
  [-41.8347, 147.4452],
  [-41.8341, 147.4407],
  [-41.8337, 147.4396],
  [-41.8205, 147.4229],
  [-41.8099, 147.4044],
  [-41.8056, 147.4001],
  [-41.8050, 147.3997],
  [-41.8019, 147.3984],
  [-41.8008, 147.3975],
  [-41.7812, 147.3731],
  [-41.7804, 147.3724],
  [-41.7761, 147.3703],
  [-41.7745, 147.3689],
  [-41.7721, 147.3652],
  [-41.7710, 147.3642],
  [-41.7699, 147.3638],
  [-41.7690, 147.3630],
  [-41.7683, 147.3617],
  [-41.7662, 147.3561],
  [-41.7646, 147.3535],
  [-41.7552, 147.3434],
  [-41.7520, 147.3383],
  [-41.7442, 147.3307],
  [-41.7426, 147.3284],
  [-41.7101, 147.2937],
  [-41.7006, 147.2858],
  [-41.6871, 147.2765],
  [-41.6848, 147.2745],
  [-41.6829, 147.2736],
  [-41.6815, 147.2736],
  [-41.6781, 147.2747],
  [-41.6764, 147.2746],
  [-41.6742, 147.2738],
  [-41.6728, 147.2736],
  [-41.6436, 147.2805],
  [-41.6292, 147.2856],
  [-41.6282, 147.2857],
  [-41.6273, 147.2854],
  [-41.6021, 147.2715],
  [-41.6010, 147.2706],
  [-41.5849, 147.2502],
  [-41.5837, 147.2492],
  [-41.5711, 147.2436],
  [-41.5692, 147.2419],
  [-41.5661, 147.2372],
  [-41.5650, 147.2364],
  [-41.5633, 147.2357],
  [-41.5625, 147.2349],
  [-41.5573, 147.2271],
  [-41.5567, 147.2255],
  [-41.5558, 147.2184],
  [-41.5555, 147.2177],
  [-41.5550, 147.2173],
  [-41.5544, 147.2172]
];

const westernJunction = [
  [-41.5558, 147.2184],
  [-41.5559, 147.2176],
  [-41.5561, 147.2170],
  [-41.5566, 147.21635]
];

const hobartNewTown = [
  [-42.8779, 147.3322],
  [-42.8788, 147.3360],
  [-42.8786, 147.3379],
  [-42.8782, 147.3387],
  [-42.8778, 147.3389],
  [-42.8775, 147.3389],
  [-42.8772, 147.3387],
  [-42.8755, 147.3356],
  [-42.8746, 147.3350],
  [-42.8708, 147.3342],
  [-42.8699, 147.3346],
  [-42.8692, 147.3355],
  [-42.8684, 147.3359],
  [-42.8673, 147.3359],
  [-42.8667, 147.3361],
  [-42.8658, 147.3365],
  [-42.8650, 147.3365],
  [-42.8646, 147.3362],
  [-42.8641, 147.3356],
  [-42.8639, 147.3346],
  [-42.8640, 147.3323],
  [-42.8639, 147.3313],
  [-42.8633, 147.3304],
  [-42.8607, 147.3286],
  [-42.8601, 147.3281],
  [-42.8594, 147.3268],
  [-42.8571, 147.3236],
  [-42.8567, 147.3226],
  [-42.8551, 147.3166],
  [-42.8548, 147.3152],
  [-42.8542, 147.3113]
];

const newtownGlenorchy = [
  [-42.8542, 147.3113],
  [-42.8540, 147.3103],
  [-42.8519, 147.3023],
  [-42.8512, 147.3007],
  [-42.8501, 147.2994],
  [-42.8495, 147.2989],
  [-42.8464, 147.2981],
  [-42.8458, 147.2978],
  [-42.8452, 147.2973],
  [-42.8397, 147.2916],
  [-42.8375, 147.2894],
  [-42.8365, 147.2880],
  [-42.8343, 147.2849],
  [-42.8333, 147.2830],
  [-42.8329, 147.2812],
  [-42.8326, 147.2786],
  [-42.8323, 147.2772],
  [-42.8316, 147.2751],
  [-42.8314, 147.2746]
];

const deloraineLatrobeDeviationStart = [
  [-41.5235, 146.6602],
  [-41.5240, 146.6581],
  [-41.5246, 146.6574],
  [-41.5261, 146.6560],
  [-41.5266, 146.6553],
  [-41.5275, 146.6519],
  [-41.5276, 146.6498],
  [-41.5271, 146.6483],
  [-41.5250, 146.6459],
  [-41.5243, 146.6445],
  [-41.5242, 146.6436],
  [-41.5242, 146.6429],
  [-41.5275, 146.6307],
  [-41.5279, 146.6269],
  [-41.5301, 146.6260],
  [-41.5306, 146.6248],
  [-41.5312, 146.6219],
  [-41.5312, 146.6208],
  [-41.5307, 146.6168],
  [-41.5298, 146.6150],
  [-41.5245, 146.6087],
  [-41.5240, 146.6078],
  [-41.5216, 146.5990],
  [-41.5213, 146.5951],
  [-41.5208, 146.5933],
  [-41.5194, 146.5912],
  [-41.5166, 146.5893],
  [-41.5123, 146.5881],
  [-41.5111, 146.5874],
  [-41.5102, 146.5862],
  [-41.4942, 146.5498],
  [-41.4880, 146.5358],
  [-41.4875, 146.5351],
  [-41.4868, 146.5348],
  [-41.4848, 146.5343],
  [-41.4834, 146.5335],
  [-41.4827, 146.5334],
  [-41.4794, 146.5338],
  [-41.4784, 146.5334],
  [-41.4765, 146.5314],
  [-41.4750, 146.5294],
  [-41.4730, 146.5278],
  [-41.4707, 146.5272],
  [-41.4680, 146.5271],
  [-41.4655, 146.5274],
  [-41.4648, 146.5278],
  [-41.4611, 146.5305],
  [-41.4603, 146.5307],
  [-41.4588, 146.5302],
  [-41.4583, 146.5298],
  [-41.4573, 146.5288],
  [-41.4559, 146.5283],
  [-41.4554, 146.5278],
  [-41.4542, 146.5266],
  [-41.4539, 146.5257],
  [-41.4537, 146.5243],
  [-41.4532, 146.5232],
  [-41.4508, 146.5212],
  [-41.4501, 146.5203],
  [-41.4473, 146.5151],
  [-41.4466, 146.5143],
  [-41.4440, 146.5120],
  [-41.4422, 146.5096],
  [-41.4414, 146.5090],
  [-41.4401, 146.5085],
  [-41.4395, 146.5086],
  [-41.4374, 146.5094],
  [-41.4366, 146.5095],
  [-41.4357, 146.5100],
  [-41.4335, 146.5139],
  [-41.4327, 146.5145],
  [-41.4307, 146.5153],
  [-41.4287, 146.5162],
  [-41.4257, 146.5186],
  [-41.4242, 146.5209],
  [-41.4234, 146.5230],
  [-41.4223, 146.5247],
  [-41.4204, 146.5262],
  [-41.4194, 146.5267],
  [-41.4185, 146.5266],
  [-41.4170, 146.5253],
  [-41.4154, 146.5225],
  [-41.4148, 146.5207],
  [-41.4137, 146.5189],
  [-41.4125, 146.5178],
  [-41.4028, 146.5111],
  [-41.4006, 146.5084],
  [-41.3990, 146.5049],
  [-41.3979, 146.4991],
  [-41.3966, 146.4965],
  [-41.3936, 146.4940],
  [-41.3837, 146.4912],
  [-41.3808, 146.4895],
  [-41.3783, 146.4868],
  [-41.3734, 146.4798],
  [-41.3709, 146.4781],
  [-41.3680, 146.4771],
  [-41.3664, 146.4759],
  [-41.3604, 146.4700],
  [-41.3599, 146.4690],
  [-41.3598, 146.4678],
  [-41.3598, 146.4659],
  [-41.3592, 146.4644],
  [-41.3583, 146.4635],
  [-41.3523, 146.4617],
  [-41.3509, 146.4606],
  [-41.3500, 146.4585],
  [-41.3496, 146.4562],
  [-41.3489, 146.4540],
  [-41.3489, 146.4514],
  [-41.3494, 146.4486],
  [-41.3488, 146.4370],
  [-41.3479, 146.4305],
  [-41.3465, 146.4265],
  [-41.3441, 146.4241],
  [-41.3429, 146.4233],
  [-41.3342, 146.4226],
  [-41.3335, 146.4224],
  [-41.3325, 146.4214],
  [-41.3290, 146.4161],
  [-41.3279, 146.4152],
  [-41.3128, 146.4082],
  [-41.3075, 146.4063],
  [-41.3062, 146.4055],
  [-41.3043, 146.4039],
  [-41.2985, 146.4016],
  [-41.2966, 146.4012],
  [-41.2934, 146.3994],
  [-41.2919, 146.3990],
  [-41.2874, 146.3985],
  [-41.2865, 146.3988],
  [-41.2860, 146.3994],
  [-41.2853, 146.4013],
  [-41.2842, 146.4022],
  [-41.2829, 146.4023],
  [-41.2818, 146.4017],
  [-41.2800, 146.4020],
  [-41.2785, 146.4030],
  [-41.2727, 146.4038],
  [-41.2717, 146.4045],
  [-41.2707, 146.4053],
  [-41.2624, 146.4101],
  [-41.2595, 146.4104],
  [-41.2574, 146.4094],
  [-41.2540, 146.4065]
];

const latrobeOld = [
  [-41.2540, 146.4065],
  [-41.2524, 146.4060],
  [-41.2508, 146.4064],
  [-41.2499, 146.4071],
  [-41.2475, 146.4103],
  [-41.2459, 146.4111],
  [-41.2443, 146.4115],
  [-41.2429, 146.4116],
  [-41.2351, 146.4100],
  [-41.2344, 146.4096],
  [-41.2340, 146.4090],
  [-41.2331, 146.4025],
  [-41.2332, 146.4010],
  [-41.2366, 146.3914],
  [-41.2376, 146.3897],
  [-41.2382, 146.3873],
  [-41.2383, 146.3866],
  [-41.2382, 146.3856]
];

const latrobeDeviation = [
  [-41.2540, 146.4065],
  [-41.2532, 146.4056],
  [-41.2473, 146.3964],
  [-41.2460, 146.3955],
  [-41.2441, 146.3949],
  [-41.2423, 146.3941],
  [-41.2409, 146.3929],
  [-41.2398, 146.3908],
  [-41.2382, 146.3856]
];

const latrobeDeviationEndDevonport = [
  [-41.2382, 146.3856],
  [-41.2315, 146.3639],
  [-41.2308, 146.3624],
  [-41.2255, 146.3547],
  [-41.2210, 146.3458],
  [-41.2202, 146.3449],
  [-41.2131, 146.3430],
  [-41.2117, 146.3431],
  [-41.2110, 146.3433],
  [-41.2044, 146.3472],
  [-41.2038, 146.3480],
  [-41.2027, 146.3513],
  [-41.2020, 146.3518],
  [-41.2001, 146.3526],
  [-41.1995, 146.3533],
  [-41.1992, 146.3543],
  [-41.1995, 146.3570],
  [-41.1994, 146.3581],
  [-41.1981, 146.3608],
  [-41.1967, 146.3623],
  [-41.1948, 146.3636],
  [-41.1938, 146.3638],
  [-41.1906, 146.3630],
  [-41.1892, 146.3628],
  [-41.1879, 146.3624],
  [-41.1847, 146.3620],
  [-41.1832, 146.3621],
  [-41.1826, 146.3623],
  [-41.1816, 146.3629],
  [-41.1812, 146.3631],
  [-41.1799, 146.3634],
  [-41.1791, 146.3633],
  [-41.1766, 146.3623]
];

const devonportUlverstone = [
  [-41.1766, 146.3623],
  [-41.1748, 146.3616],
  [-41.1739, 146.3609],
  [-41.1631, 146.3483],
  [-41.1626, 146.3468],
  [-41.1626, 146.3444],
  [-41.1633, 146.3416],
  [-41.1638, 146.3348],//don river junction
  [-41.1639, 146.3330],
  [-41.1633, 146.3272],
  [-41.1634, 146.3262],
  [-41.1641, 146.3250],
  [-41.1644, 146.3241],
  [-41.1647, 146.3218],
  [-41.1670, 146.3171],
  [-41.1672, 146.3144],
  [-41.1662, 146.3116],
  [-41.1647, 146.3097],
  [-41.1636, 146.3074],
  [-41.1634, 146.3063],
  [-41.1633, 146.3039],
  [-41.1629, 146.3024],
  [-41.1628, 146.3006],
  [-41.1630, 146.2989],
  [-41.1642, 146.2948],
  [-41.1643, 146.2922],
  [-41.1598, 146.2768],
  [-41.1559, 146.2660],
  [-41.1554, 146.2633],
  [-41.1555, 146.2603],
  [-41.1561, 146.2569],
  [-41.1566, 146.2559],
  [-41.1576, 146.2551],
  [-41.1617, 146.2541],
  [-41.1624, 146.2537],
  [-41.1629, 146.2529],
  [-41.1634, 146.2500],
  [-41.1634, 146.2481],
  [-41.1628, 146.2466],
  [-41.1618, 146.2455],
  [-41.1611, 146.2440],
  [-41.1609, 146.2425],
  [-41.1617, 146.2341],
  [-41.1617, 146.2329],
  [-41.1611, 146.2295],
  [-41.1607, 146.2282],
  [-41.1592, 146.2257],
  [-41.1557, 146.2148],
  [-41.1555, 146.2125],
  [-41.1557, 146.2088],
  [-41.1555, 146.2068],
  [-41.1549, 146.2047],
  [-41.1530, 146.2016],
  [-41.1525, 146.1999],
  [-41.1524, 146.1977],
  [-41.1543, 146.1827],
  [-41.1547, 146.1810],
  [-41.1552, 146.1798],
  [-41.1570, 146.1768],
  [-41.1585, 146.1738]
];

const ulverstoneBurnie = [
  [-41.1585, 146.1738],
  [-41.1597, 146.1713],
  [-41.1599, 146.1705],
  [-41.1597, 146.1657],
  [-41.1596, 146.1650],
  [-41.1589, 146.1634],
  [-41.1581, 146.1626],
  [-41.1572, 146.1624],
  [-41.1566, 146.1628],
  [-41.1560, 146.1633],
  [-41.1556, 146.1635],
  [-41.1538, 146.1642],
  [-41.1533, 146.1643],
  [-41.1503, 146.1643],
  [-41.1473, 146.1636],
  [-41.1466, 146.1632],
  [-41.1448, 146.1612],
  [-41.1442, 146.1608],
  [-41.1426, 146.1604],
  [-41.1420, 146.1599],
  [-41.1418, 146.1591],
  [-41.1412, 146.1476],
  [-41.1400, 146.1428],
  [-41.1387, 146.1404],
  [-41.1383, 146.1400],
  [-41.1379, 146.1396],
  [-41.1376, 146.1391],
  [-41.1373, 146.1377],
  [-41.1373, 146.1354],
  [-41.1371, 146.1342],
  [-41.1364, 146.1326],
  [-41.1361, 146.1310],
  [-41.1360, 146.1303],
  [-41.1358, 146.1296],
  [-41.1355, 146.1289],
  [-41.1347, 146.1279],
  [-41.1341, 146.1269],
  [-41.1332, 146.1256],
  [-41.1326, 146.1252],
  [-41.1322, 146.1251],
  [-41.1310, 146.1254],
  [-41.1304, 146.1257],
  [-41.1300, 146.1258],
  [-41.1293, 146.1257],
  [-41.1288, 146.1258],
  [-41.1282, 146.1260],
  [-41.1278, 146.1260],
  [-41.1274, 146.1260],
  [-41.1260, 146.1261],
  [-41.1254, 146.1260],
  [-41.1249, 146.1257],
  [-41.1243, 146.1248],
  [-41.1242, 146.1242],
  [-41.1241, 146.1235],
  [-41.1239, 146.1229],
  [-41.1234, 146.1221],
  [-41.1231, 146.1216],
  [-41.1224, 146.1209],
  [-41.1221, 146.1205],
  [-41.1220, 146.1200],
  [-41.1216, 146.1193],
  [-41.1209, 146.1187],
  [-41.1206, 146.1182],
  [-41.1206, 146.1176],
  [-41.1211, 146.1156],
  [-41.1211, 146.1149],
  [-41.1210, 146.1140],
  [-41.1208, 146.1133],
  [-41.1209, 146.1126],
  [-41.1212, 146.1117],
  [-41.1213, 146.1110],
  [-41.1212, 146.1086],
  [-41.1211, 146.1081],
  [-41.1209, 146.1075],
  [-41.1208, 146.1070],
  [-41.1212, 146.1050],
  [-41.1211, 146.1036],
  [-41.1200, 146.1007],
  [-41.1186, 146.0980],
  [-41.1184, 146.0973],
  [-41.1180, 146.0958],
  [-41.1179, 146.0944],
  [-41.1170, 146.0905],
  [-41.1166, 146.0880],
  [-41.1164, 146.0864],
  [-41.1162, 146.0858],
  [-41.1160, 146.0853],
  [-41.1161, 146.0849],
  [-41.1162, 146.0843],
  [-41.1161, 146.0836],
  [-41.1156, 146.0823],
  [-41.1155, 146.0818],
  [-41.1156, 146.0813],
  [-41.1159, 146.0807],
  [-41.1159, 146.0802],
  [-41.1159, 146.0794],
  [-41.1160, 146.0785],
  [-41.1159, 146.0780],
  [-41.1156, 146.0770],
  [-41.1154, 146.0766],
  [-41.1145, 146.0755],
  [-41.1142, 146.0749],
  [-41.1140, 146.0737],
  [-41.1139, 146.0724],
  [-41.1137, 146.0718],
  [-41.1124, 146.0704],
  [-41.1099, 146.0690],
  [-41.1090, 146.0687],
  [-41.1085, 146.0687],
  [-41.1081, 146.0683],
  [-41.1072, 146.0669],
  [-41.1066, 146.0659],
  [-41.1057, 146.0652],
  [-41.1052, 146.0646],
  [-41.1050, 146.0640],
  [-41.1046, 146.0636],
  [-41.1042, 146.0635],
  [-41.1038, 146.0635],
  [-41.1033, 146.0632],
  [-41.1030, 146.0627],
  [-41.1030, 146.0616],
  [-41.1027, 146.0596],
  [-41.1014, 146.0553],
  [-41.1013, 146.0545],
  [-41.1015, 146.0530],
  [-41.1019, 146.0522],
  [-41.1027, 146.0512],
  [-41.1030, 146.0507],
  [-41.1030, 146.0501],
  [-41.1022, 146.0470],
  [-41.1013, 146.0448],
  [-41.1005, 146.0441],
  [-41.0989, 146.0437],
  [-41.0979, 146.0433],
  [-41.0975, 146.0429],
  [-41.0972, 146.0423],
  [-41.0965, 146.0401],
  [-41.0965, 146.0391],
  [-41.0967, 146.0384],
  [-41.0972, 146.0375],
  [-41.0974, 146.0368],
  [-41.0967, 146.0328],
  [-41.0963, 146.0317],
  [-41.0941, 146.0279],
  [-41.0928, 146.0242],
  [-41.0924, 146.0231],
  [-41.0923, 146.0214],
  [-41.0919, 146.0200],
  [-41.0904, 146.0173],
  [-41.0898, 146.0159],
  [-41.0895, 146.0145],
  [-41.0894, 146.0133],
  [-41.0890, 146.0122],
  [-41.0885, 146.0114],
  [-41.0865, 146.0089],
  [-41.0844, 146.0073],
  [-41.0832, 146.0062],
  [-41.0822, 146.0052],
  [-41.0806, 146.0042],
  [-41.0799, 146.0036],
  [-41.0794, 146.0027],
  [-41.0789, 146.0014],
  [-41.0779, 145.9995],
  [-41.0776, 145.9986],
  [-41.0774, 145.9964],
  [-41.0772, 145.9950],
  [-41.0767, 145.9932],
  [-41.0765, 145.9922],
  [-41.0759, 145.9842],
  [-41.0755, 145.9827],
  [-41.0719, 145.9762],
  [-41.0708, 145.9749],
  [-41.0699, 145.9743],
  [-41.0689, 145.9733],
  [-41.0674, 145.9707],
  [-41.0672, 145.9699],
  [-41.0673, 145.9693],
  [-41.0675, 145.9686],
  [-41.0676, 145.9681],
  [-41.0676, 145.9669],
  [-41.0671, 145.9653],
  [-41.0667, 145.9648],
  [-41.0656, 145.9638],
  [-41.0646, 145.9622],
  [-41.0644, 145.9615],
  [-41.0644, 145.9605],
  [-41.0642, 145.9598],
  [-41.0637, 145.9586],
  [-41.0635, 145.9577],
  [-41.0636, 145.9572],
  [-41.0639, 145.9558],
  [-41.0642, 145.9554],
  [-41.0648, 145.9544],
  [-41.0652, 145.9531],
  [-41.0653, 145.9520],
  [-41.0656, 145.9509],
  [-41.0659, 145.9499],
  [-41.0661, 145.9492],
  [-41.0661, 145.9466],
  [-41.0660, 145.9440],
  [-41.0660, 145.9434],
  [-41.0662, 145.9426],
  [-41.0674, 145.9404],
  [-41.0677, 145.9394],
  [-41.0678, 145.9375],
  [-41.0681, 145.9365],
  [-41.0686, 145.9353],
  [-41.0693, 145.9344],
  [-41.0697, 145.9335],
  [-41.0701, 145.9302],
  [-41.0701, 145.9292],
  [-41.0679, 145.9221],
  [-41.0677, 145.9211],
  [-41.0671, 145.9195],
  [-41.0644, 145.9116],
  [-41.0641, 145.9110],
  [-41.0621, 145.9087],
  [-41.0618, 145.9085],
  [-41.0606, 145.9078]
];

const burnieWynard = [
  [-41.0534, 145.9079],
  [-41.0512, 145.9092],
  [-41.0506, 145.9092],
  [-41.0501, 145.9090],
  [-41.0498, 145.9082],
  [-41.0496, 145.9059],
  [-41.0494, 145.9053],
  [-41.0483, 145.9028],
  [-41.0474, 145.9016],
  [-41.0470, 145.9012],
  [-41.0461, 145.9011],
  [-41.0456, 145.9008],
  [-41.0453, 145.9001],
  [-41.0451, 145.8980],
  [-41.0452, 145.8973],
  [-41.0454, 145.8970],
  [-41.0457, 145.8966],
  [-41.0460, 145.8960],
  [-41.0461, 145.8952],
  [-41.0459, 145.8939],
  [-41.0454, 145.8928],
  [-41.0449, 145.8919],
  [-41.0447, 145.8913],
  [-41.0445, 145.8883],
  [-41.0445, 145.8874],
  [-41.0447, 145.8865],
  [-41.0448, 145.8854],
  [-41.0445, 145.8845],
  [-41.0435, 145.8832],
  [-41.0429, 145.8821],
  [-41.0428, 145.8815],
  [-41.0426, 145.8799],
  [-41.0424, 145.8795],
  [-41.0422, 145.8792],
  [-41.0392, 145.8771],
  [-41.0387, 145.8765],
  [-41.0385, 145.8757],
  [-41.0385, 145.8750],
  [-41.0390, 145.8737],
  [-41.0392, 145.8734],
  [-41.0397, 145.8729],
  [-41.0400, 145.8723],
  [-41.0402, 145.8712],
  [-41.0401, 145.8684],
  [-41.0400, 145.8678],
  [-41.0396, 145.8664],
  [-41.0395, 145.8655],
  [-41.0400, 145.8624],
  [-41.0399, 145.8615],
  [-41.0397, 145.8605],
  [-41.0397, 145.8596],
  [-41.0398, 145.8591],
  [-41.0398, 145.8585],
  [-41.0396, 145.8570],
  [-41.0396, 145.8561],
  [-41.0400, 145.8553],
  [-41.0405, 145.8545],
  [-41.0407, 145.8538],
  [-41.0408, 145.8531],
  [-41.0406, 145.8506],
  [-41.0393, 145.8462],
  [-41.0391, 145.8453],
  [-41.0394, 145.8369],
  [-41.0384, 145.8295],
  [-41.0381, 145.8287],
  [-41.0348, 145.8244],
  [-41.0344, 145.8234],
  [-41.0340, 145.8191],
  [-41.0336, 145.8179],
  [-41.0320, 145.8153],
  [-41.0317, 145.8143],
  [-41.0307, 145.8100],
  [-41.0299, 145.8075],
  [-41.0293, 145.8067],
  [-41.0280, 145.8061],
  [-41.0276, 145.8058],
  [-41.0273, 145.8051],
  [-41.0264, 145.8012],
  [-41.0259, 145.8005],
  [-41.0251, 145.7998],
  [-41.0225, 145.7968],
  [-41.0207, 145.7940],
  [-41.0200, 145.7924],
  [-41.0194, 145.7914],
  [-41.0184, 145.7906],
  [-41.0179, 145.7900],
  [-41.0176, 145.7891],
  [-41.0165, 145.7858],
  [-41.0160, 145.7851],
  [-41.0155, 145.7849],
  [-41.0148, 145.7848],
  [-41.0136, 145.7842],
  [-41.0131, 145.7837],
  [-41.0120, 145.7821],
  [-41.0106, 145.7811],
  [-41.0091, 145.7811],
  [-41.0083, 145.7808],
  [-41.0070, 145.7802],
  [-41.0070, 145.7802],
  [-41.0056, 145.7774],
  [-41.0052, 145.7768],
  [-41.0045, 145.7760],
  [-41.0041, 145.7754],
  [-41.0032, 145.7737],
  [-41.0012, 145.7716],
  [-40.9992, 145.7700],
  [-40.9985, 145.7689],
  [-40.9981, 145.7681],
  [-40.9974, 145.7620],
  [-40.9969, 145.7599],
  [-40.9948, 145.7381],
  [-40.9941, 145.7295],
  [-40.9938, 145.7284],
  [-40.9927, 145.7255],
  [-40.9917, 145.7203]
];

const wynardMyalla = [
  [-40.9917, 145.7203],
  [-40.9908, 145.7128],
  [-40.9906, 145.7119],
  [-40.9892, 145.7084],
  [-40.9852, 145.6932],
  [-40.9847, 145.6857],
  [-40.9841, 145.6836],
  [-40.9842, 145.6800],
  [-40.9840, 145.6790],
  [-40.9834, 145.6770],
  [-40.9834, 145.6759],
  [-40.9790, 145.6640],
  [-40.9786, 145.6634],
  [-40.9779, 145.6626],
  [-40.9775, 145.6615],
  [-40.9773, 145.6596],
  [-40.9772, 145.6589],
  [-40.9768, 145.6586],
  [-40.9763, 145.6583],
  [-40.9759, 145.6579],
  [-40.9754, 145.6566],
  [-40.9747, 145.6560],
  [-40.9743, 145.6558],
  [-40.9738, 145.6560],
  [-40.9727, 145.6573],
  [-40.9718, 145.6574],
  [-40.9691, 145.6573],
  [-40.9685, 145.6571],
  [-40.9671, 145.6562],
  [-40.9666, 145.6555],
  [-40.9647, 145.6522],
  [-40.9641, 145.6510],
  [-40.9637, 145.6505],
  [-40.9620, 145.6494],
  [-40.9609, 145.6484],
  [-40.9605, 145.6477],
  [-40.9594, 145.6454],
  [-40.9588, 145.6432],
  [-40.9569, 145.6385],
  [-40.9568, 145.6372],
  [-40.9578, 145.6342],
  [-40.9583, 145.6335],
  [-40.9586, 145.6329],
  [-40.9587, 145.6317],
  [-40.9590, 145.6309],
  [-40.9607, 145.6292],
  [-40.9612, 145.6281],
  [-40.9612, 145.6271],
  [-40.9608, 145.6260],
  [-40.9606, 145.6248],
  [-40.9609, 145.6239],
  [-40.9620, 145.6221],
  [-40.9624, 145.6211],
  [-40.9623, 145.6205],
  [-40.9621, 145.6198],
  [-40.9621, 145.6192],
  [-40.9623, 145.6186],
  [-40.9627, 145.6181],
  [-40.9628, 145.6175],
  [-40.9627, 145.6169],
  [-40.9618, 145.6155],
  [-40.9616, 145.6147],
  [-40.9615, 145.6110],
  [-40.9617, 145.6103],
  [-40.9621, 145.6101],
  [-40.9631, 145.6099],
  [-40.9635, 145.6097],
  [-40.9637, 145.6090],
  [-40.9636, 145.6082],
  [-40.9631, 145.6070],
  [-40.9630, 145.6062],
  [-40.9632, 145.6055],
  [-40.9635, 145.6048],
  [-40.9637, 145.6038],
  [-40.9635, 145.6030],
  [-40.9630, 145.6021],
  [-40.9625, 145.6006],
  [-40.9626, 145.5998],
  [-40.9629, 145.5993],
  [-40.9635, 145.5992],
  [-40.9656, 145.6000],
  [-40.9661, 145.6004],
  [-40.9666, 145.6010],
  [-40.9675, 145.6012],
  [-40.9687, 145.6010],
  [-40.9696, 145.6011],
  [-40.9711, 145.6014],
  [-40.9717, 145.6013],
  [-40.9722, 145.6006],
  [-40.9724, 145.5997],
  [-40.9723, 145.5981],
  [-40.9725, 145.5971],
  [-40.9730, 145.5966],
  [-40.9765, 145.5946],
  [-40.9766, 145.5942],
  [-40.9768, 145.5933],
  [-40.9767, 145.5923],
  [-40.9771, 145.5913],
  [-40.9777, 145.5907],
  [-40.9788, 145.5904],
  [-40.9797, 145.5896],
  [-40.9801, 145.5884],
  [-40.9801, 145.5831],
  [-40.9797, 145.5807],
  [-40.9789, 145.5785],
  [-40.9788, 145.5772],
  [-40.9798, 145.5731],
  [-40.9802, 145.5724],
  [-40.9833, 145.5688]
];

const myallaWiltshireJunction = [
  [-40.9833, 145.5688],
  [-40.9846, 145.5665],
  [-40.9878, 145.5606],
  [-40.9880, 145.5591],
  [-40.9877, 145.5570],
  [-40.9878, 145.5559],
  [-40.9882, 145.5545],
  [-40.9881, 145.5535],
  [-40.9869, 145.5518],
  [-40.9857, 145.5478],
  [-40.9848, 145.5467],
  [-40.9836, 145.5467],
  [-40.9827, 145.5474],
  [-40.9813, 145.5495],
  [-40.9804, 145.5499],
  [-40.9792, 145.5498],
  [-40.9783, 145.5503],
  [-40.9771, 145.5520],
  [-40.9765, 145.5532],
  [-40.9759, 145.5535],
  [-40.9753, 145.5531],
  [-40.9747, 145.5518],
  [-40.9732, 145.5499],
  [-40.9729, 145.5488],
  [-40.9731, 145.5474],
  [-40.9728, 145.5463],
  [-40.9707, 145.5447],
  [-40.9694, 145.5446],
  [-40.9682, 145.5439],
  [-40.9678, 145.5429],
  [-40.9679, 145.5415],
  [-40.9698, 145.5386],
  [-40.9699, 145.5376],
  [-40.9696, 145.5366],
  [-40.9696, 145.5357],
  [-40.9697, 145.5349],
  [-40.9695, 145.5339],
  [-40.9691, 145.5332],
  [-40.9687, 145.5320],
  [-40.9683, 145.5314],
  [-40.9665, 145.5303],
  [-40.9659, 145.5302],
  [-40.9651, 145.5305],
  [-40.9646, 145.5309],
  [-40.9640, 145.5318],
  [-40.9622, 145.5329],
  [-40.9607, 145.5329],
  [-40.9597, 145.5324],
  [-40.9594, 145.5316],
  [-40.9596, 145.5309],
  [-40.9601, 145.5304],
  [-40.9607, 145.5301],
  [-40.9612, 145.5296],
  [-40.9618, 145.5283],
  [-40.9618, 145.5273],
  [-40.9607, 145.5252],
  [-40.9588, 145.5241],
  [-40.9584, 145.5235],
  [-40.9580, 145.5223],
  [-40.9574, 145.5218],
  [-40.9562, 145.5217],
  [-40.9555, 145.5213],
  [-40.9537, 145.5195],
  [-40.9532, 145.5187],
  [-40.9527, 145.5163],
  [-40.9527, 145.5151],
  [-40.9529, 145.5133],
  [-40.9523, 145.5110],
  [-40.9517, 145.5102],
  [-40.9508, 145.5097],
  [-40.9501, 145.5086],
  [-40.9500, 145.5070],
  [-40.9495, 145.5060],
  [-40.9488, 145.5054],
  [-40.9483, 145.5048],
  [-40.9466, 145.5014],
  [-40.9463, 145.5000],
  [-40.9456, 145.4992],
  [-40.9449, 145.4991],
  [-40.9441, 145.4995],
  [-40.9433, 145.4994],
  [-40.9428, 145.4987],
  [-40.9424, 145.4972],
  [-40.9424, 145.4965],
  [-40.9432, 145.4934],
  [-40.9433, 145.4925],
  [-40.9429, 145.4906],
  [-40.9426, 145.4899],
  [-40.9421, 145.4891],
  [-40.9419, 145.4886],
  [-40.9417, 145.4878],
  [-40.9411, 145.4869],
  [-40.9395, 145.4861],
  [-40.9389, 145.4862],
  [-40.9381, 145.4875],
  [-40.9375, 145.4878],
  [-40.9368, 145.4876],
  [-40.9360, 145.4862],
  [-40.9358, 145.4851],
  [-40.9359, 145.4840],
  [-40.9356, 145.4831],
  [-40.9347, 145.4825],
  [-40.9339, 145.4823],
  [-40.9318, 145.4829],
  [-40.9303, 145.4839],
  [-40.9296, 145.4838],
  [-40.9291, 145.4831],
  [-40.9284, 145.4818],
  [-40.9271, 145.4803],
  [-40.9263, 145.4798],
  [-40.9259, 145.4792],
  [-40.9254, 145.4782],
  [-40.9248, 145.4775],
  [-40.9242, 145.4775],
  [-40.9231, 145.4780],
  [-40.9223, 145.4778],
  [-40.9217, 145.4775],
  [-40.9210, 145.4778],
  [-40.9189, 145.4799],
  [-40.9179, 145.4800],
  [-40.9171, 145.4795],
  [-40.9164, 145.4782],
  [-40.9164, 145.4770],
  [-40.9161, 145.4761],
  [-40.9155, 145.4757],
  [-40.9151, 145.4750],
  [-40.9150, 145.4740],
  [-40.9144, 145.4732],
  [-40.9130, 145.4723],
  [-40.9124, 145.4723],
  [-40.9111, 145.4729],
  [-40.9103, 145.4729],
  [-40.9078, 145.4713],
  [-40.9068, 145.4712],
  [-40.9056, 145.4719],
  [-40.9049, 145.4720],
  [-40.9040, 145.4720],
  [-40.9024, 145.4728],
  [-40.9017, 145.4728],
  [-40.9004, 145.4719],
  [-40.8994, 145.4706],
  [-40.8983, 145.4702],
  [-40.8975, 145.4705],
  [-40.8967, 145.4712],
  [-40.8954, 145.4714],
  [-40.8947, 145.4708],
  [-40.8940, 145.4695],
  [-40.8928, 145.4678],
  [-40.8924, 145.4660],
  [-40.8935, 145.4607],
  [-40.8923, 145.4499],
  [-40.8923, 145.4482],
  [-40.8929, 145.4449],
  [-40.8928, 145.4412],
  [-40.8931, 145.4394],
  [-40.8935, 145.4385],
  [-40.8940, 145.4377],
  [-40.8942, 145.4356],
  [-40.8935, 145.4308],
  [-40.8925, 145.4289],
  [-40.8912, 145.4281],
  [-40.8905, 145.4266],
  [-40.8901, 145.4224],
  [-40.8903, 145.4208],
  [-40.8920, 145.4168],
  [-40.8931, 145.4157],
  [-40.8968, 145.4148],
  [-40.8976, 145.4142],
  [-40.8986, 145.4124],
  [-40.8995, 145.4115],
  [-40.9009, 145.4109],
  [-40.9021, 145.4088],
  [-40.9030, 145.4035],
  [-40.9029, 145.3929],
  [-40.9033, 145.3914],
  [-40.9044, 145.3901],
  [-40.9049, 145.3891],
  [-40.9058, 145.3867],
  [-40.9070, 145.3855],
  [-40.9075, 145.3847],
  [-40.9076, 145.3835],
  [-40.9072, 145.3810],
  [-40.9067, 145.3800],
  [-40.9060, 145.3790],
  [-40.9056, 145.3775],
  [-40.9063, 145.3745],
  [-40.9061, 145.3737],
  [-40.9052, 145.3732],
  [-40.9047, 145.3733],
  [-40.9021, 145.3752],
  [-40.9013, 145.3761],
  [-40.9006, 145.3763],
  [-40.8999, 145.3758],
  [-40.8997, 145.3750],
  [-40.8998, 145.3742],
  [-40.9014, 145.3719],
  [-40.9037, 145.3666],
  [-40.9067, 145.3621],
  [-40.9071, 145.3604],
  [-40.9065, 145.3547],
  [-40.9043, 145.3489],
  [-40.9041, 145.3479],
  [-40.9040, 145.3463],
  [-40.9039, 145.3456],
  [-40.9034, 145.3447],
  [-40.8858, 145.3331],
  [-40.8849, 145.3320],
  [-40.8840, 145.3301],
  [-40.8794, 145.3258],
  [-40.8782, 145.3253],
  [-40.8760, 145.3255],
  [-40.8752, 145.3261],
  [-40.8738, 145.3283],
  [-40.8726, 145.3288],
  [-40.8719, 145.3295],
  [-40.8715, 145.3297],
  [-40.8707, 145.3298],
  [-40.8702, 145.3303],
  [-40.8695, 145.3313],
  [-40.8687, 145.3317],
  [-40.8670, 145.3318],
  [-40.8653, 145.3312],
  [-40.8649, 145.3306],
  [-40.8648, 145.3299],
  [-40.8651, 145.3291],
  [-40.8659, 145.3284],
  [-40.8665, 145.3274],
  [-40.8664, 145.3259],
  [-40.8659, 145.3248],
  [-40.8651, 145.3243],
  [-40.8644, 145.3242],
  [-40.8637, 145.3235],
  [-40.8634, 145.3225],
  [-40.8634, 145.3215],
  [-40.8630, 145.3206],
  [-40.8623, 145.3202],
  [-40.8615, 145.3201],
  [-40.8609, 145.3196],
  [-40.8606, 145.3189],
  [-40.8602, 145.3169],
  [-40.8598, 145.3160],
  [-40.8553, 145.3111],
  [-40.8501, 145.3072],
  [-40.8493, 145.3069],
  [-40.8473, 145.3073],
  [-40.8457, 145.3068],
  [-40.8451, 145.3060],
  [-40.8448, 145.3049],
  [-40.8449, 145.2952],
  [-40.8444, 145.2931],
  [-40.8432, 145.2914],
  [-40.8353, 145.2757],
  [-40.8346, 145.2751]
];

const wiltshireJunctionBalloonLoop = [
  [-40.8346, 145.2751],
  [-40.8341, 145.2746],
  [-40.8328, 145.2721],
  [-40.8322, 145.2718],
  [-40.8317, 145.2719],
  [-40.8313, 145.2724],
  [-40.8312, 145.2732],
  [-40.8314, 145.2738],
  [-40.8317, 145.2741]
];

export const mainLine: Line = {
  name: 'Main Line (TAS)',
  state: 'TAS',
  segments: [
    {
      segments: [launcestonJunctionDeloraine],
      history: {
        opened: {
          date: '1871-02-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-11-01',
          gauge: 'dual'
        }, {
          date: '1888-01-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [
        bridgeWaterMidlandHighwayDeviationStart,
        {
          date: '2010-08-01',
          original: [midlandHighwayOld],
          deviation: [midlandHighwayDeviation]
        },
        midlandHighwayDeviationEndRhynastonTunnel,
        {
          name: 'Rhynaston Tunnel',
          segment: rhynastonTunnel,
          type: 'tunnel'
        },
        rhynastonTunnelTinDish,
        {
          date: '1936-01-01',
          original: [nalaOld],
          deviation: [nalaDeviation]
        },
        nalaTinDish,
        {
          date: '1932-04-25',
          original: [tinDishOld],
          deviation: [tinDishDeviation]
        },
        nalaWesternJunction,
        westernJunction
      ],
      history: {
        opened: {
          date: '1876-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bridgewaterClaremontJunction],
      history: {
        opened: {
          date: '1876-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [claremontJunctionTransportMuseum],
      history: {
        opened: {
          date: '1876-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1936-12-16',
          tracks: 2
        }, {
          date: '2014-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [transportMuseum],
      history: {
        opened: {
          date: '1876-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1936-12-16',
          tracks: 2
        }, {
          date: '2023-05-21',
          tracks: 1
        }, {
          date: '2023-05-21',
          status: 'open'
        }, {
          date: '2014-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        deloraineLatrobeDeviationStart,
        {
          date: '1982-06-15',
          original: [latrobeOld],
          deviation: [latrobeDeviation]
        },
        latrobeDeviationEndDevonport
      ],
      history: {
        opened: {
          date: '1885-05-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [devonportUlverstone],
      history: {
        opened: {
          date: '1890-07-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hobartNewTown],
      history: {
        opened: {
          date: '1876-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-10-13',
          tracks: 2
        }, {
          date: '2014-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newtownGlenorchy],
      history: {
        opened: {
          date: '1876-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1916-05-28',
          tracks: 2
        }, {
          date: '2014-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ulverstoneBurnie],
      history: {
        opened: {
          date: '1901-04-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [burnieWynard],
      history: {
        opened: {
          date: '1913-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-01',
          status: 'closed'
        }, {
          date: '2003-01-01',
          status: 'closed'
        }, {
          date: '1999-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [wynardMyalla],
      history: {
        opened: {
          date: '1913-08-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-01',
          status: 'closed'
        }, {
          date: '2003-01-01',
          status: 'closed'
        }, {
          date: '1999-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [myallaWiltshireJunction],
      history: {
        opened: {
          date: '1922-07-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-01',
          status: 'closed'
        }, {
          date: '2003-01-01',
          status: 'closed'
        }, {
          date: '1999-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [wiltshireJunctionBalloonLoop],
      history: {
        opened: {
          date: '1945-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-01',
          status: 'closed'
        }, {
          date: '2003-01-01',
          status: 'closed'
        }, {
          date: '1999-01-01',
          status: 'open'
        }]
      }
    }
  ]
}
