import { Line } from "../../../trackTypes";

const line = [
  [-23.5855, 148.8550],
  [-23.5847, 148.8510],
  [-23.5834, 148.8485],
  [-23.5808, 148.8457],
  [-23.5778, 148.8440],
  [-23.5754, 148.8436],
  [-23.5052, 148.8414],
  [-23.4917, 148.8411],
  [-23.4904, 148.8402],
  [-23.4898, 148.8382],
  [-23.4903, 148.8363],
  [-23.4917, 148.8353],
  [-23.4934, 148.8353],
  [-23.5025, 148.8406],
  [-23.5052, 148.8414]
];

export const curragh: Line = {
  name: 'Curragh',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1983-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-29',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
