import { Line } from "../../trackTypes";

const line = [
  [-34.7130, 150.0321],
  [-34.7131, 150.0306],
  [-34.7136, 150.0297],
  [-34.7143, 150.0292],
  [-34.7198, 150.0275],
  [-34.7297, 150.0225],
  [-34.7307, 150.0224],
  [-34.7316, 150.0227],
  [-34.7329, 150.0233],
  [-34.7343, 150.0236],
  [-34.7376, 150.0234],
  [-34.7387, 150.0226],
  [-34.7390, 150.0216],
  [-34.7392, 150.0206],
  [-34.7397, 150.0196],
  [-34.7413, 150.0180],
  [-34.7425, 150.0177],
  [-34.7456, 150.0183],
  [-34.7465, 150.0191],
  [-34.7477, 150.0213],
  [-34.7485, 150.0220],
  [-34.7548, 150.0238],
  [-34.7561, 150.0248],
  [-34.7578, 150.0279],
  [-34.7612, 150.0320],
  [-34.7619, 150.0324],
  [-34.7627, 150.0325],
  [-34.7685, 150.0297]
];

export const medwayQuarry: Line = {
  name: 'Medway Quarry',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1928-09-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
