import { Line } from "../../trackTypes";

const busseltonJetty = [
  [-33.6458, 115.3457],
  [-33.6444, 115.3449],
  [-33.6405, 115.3419],
  [-33.6395, 115.3414],
  [-33.6301, 115.3386]
];

const busseltonJettyLockville = [
  [-33.6458, 115.3457],
  [-33.6544, 115.3485],
  [-33.6556, 115.3493],
  [-33.6624, 115.3595],
  [-33.6626, 115.3601],
  [-33.6627, 115.3614],
  [-33.6535, 115.4090],
  [-33.6526, 115.4113],
  [-33.6398, 115.4317],
  [-33.6312, 115.4402],
  [-33.6305, 115.4415],
  [-33.6301, 115.4424]
];

const lockvilleYoganup = [
  [-33.6301, 115.4424],
  [-33.6301, 115.4439],
  [-33.6361, 115.4675],
  [-33.6455, 115.5054],
  [-33.6616, 115.5567],
  [-33.6667, 115.5741],
  [-33.6669, 115.5771],
  [-33.6660, 115.5867]
];

const yoganupJarrahville = [
  [-33.6660, 115.5867],
  [-33.6655, 115.5904],
  [-33.6658, 115.5950],
  [-33.6662, 115.5966],
  [-33.6686, 115.6037],
  [-33.6699, 115.6056],
  [-33.6709, 115.6065],
  [-33.6721, 115.6073],
  [-33.6727, 115.6081],
  [-33.6748, 115.6144],
  [-33.6757, 115.6158],
  [-33.6767, 115.6166],
  [-33.6807, 115.6186],
  [-33.6820, 115.6200],
  [-33.6830, 115.6229],
  [-33.6840, 115.6243],
  [-33.6931, 115.6315],
  [-33.6991, 115.6375],
  [-33.7002, 115.6406],
  [-33.7002, 115.6453],
  [-33.7004, 115.6469],
  [-33.7018, 115.6511],
  [-33.7055, 115.6567],
  [-33.7072, 115.6616],
  [-33.7082, 115.6631],
  [-33.7125, 115.6660],
  [-33.7155, 115.6666],
  [-33.7170, 115.6673],
  [-33.7211, 115.6706],
  [-33.7222, 115.6723],
  [-33.7242, 115.6778],
  [-33.7249, 115.6787],
  [-33.7261, 115.6791],
  [-33.7295, 115.6792],
  [-33.7374, 115.6756],
  [-33.7404, 115.6747],
  [-33.7503, 115.6704],
  [-33.7523, 115.6684],
  [-33.7553, 115.6621],
  [-33.7561, 115.6612],
  [-33.7569, 115.6611],
  [-33.7577, 115.6614],
  [-33.7585, 115.6614],
  [-33.7611, 115.6606],
  [-33.7618, 115.6600],
  [-33.7626, 115.6590],
  [-33.7634, 115.6585],
  [-33.7680, 115.6579],
  [-33.7693, 115.6581],
  [-33.7815, 115.6641],
  [-33.7837, 115.6665],
  [-33.7843, 115.6671],
  [-33.7854, 115.6672],
  [-33.7886, 115.6668],
  [-33.7905, 115.6663],
  [-33.7919, 115.6657],
  [-33.7929, 115.6656],
  [-33.7938, 115.6659],
  [-33.7949, 115.6657],
  [-33.7959, 115.6651]
];

const jarrahwoodNannup = [
  [-33.7959, 115.6651],
  [-33.7980, 115.6645],
  [-33.8007, 115.6645],
  [-33.8049, 115.6663],
  [-33.8061, 115.6664],
  [-33.8073, 115.6659],
  [-33.8092, 115.6641],
  [-33.8141, 115.6584],
  [-33.8198, 115.6551],
  [-33.8240, 115.6542],
  [-33.8282, 115.6551],
  [-33.8293, 115.6558],
  [-33.8306, 115.6580],
  [-33.8346, 115.6607],
  [-33.8361, 115.6626],
  [-33.8391, 115.6683],
  [-33.8409, 115.6700],
  [-33.8438, 115.6708],
  [-33.8460, 115.6706],
  [-33.8480, 115.6699],
  [-33.8493, 115.6702],
  [-33.8509, 115.6716],
  [-33.8527, 115.6724],
  [-33.8550, 115.6718],
  [-33.8567, 115.6718],
  [-33.8585, 115.6730],
  [-33.8596, 115.6734],
  [-33.8614, 115.6732],
  [-33.8634, 115.6739],
  [-33.8648, 115.6739],
  [-33.8680, 115.6730],
  [-33.8695, 115.6732],
  [-33.8707, 115.6749],
  [-33.8716, 115.6757],
  [-33.8743, 115.6773],
  [-33.8809, 115.6791],
  [-33.8831, 115.6803],
  [-33.8860, 115.6811],
  [-33.8874, 115.6823],
  [-33.8883, 115.6845],
  [-33.8900, 115.6868],
  [-33.8911, 115.6894],
  [-33.8912, 115.6920],
  [-33.8917, 115.6939],
  [-33.8916, 115.6960],
  [-33.8910, 115.6983],
  [-33.8908, 115.7000],
  [-33.8916, 115.7028],
  [-33.8934, 115.7055],
  [-33.8946, 115.7090],
  [-33.8955, 115.7103],
  [-33.8966, 115.7108],
  [-33.8991, 115.7105],
  [-33.9010, 115.7109],
  [-33.9069, 115.7162],
  [-33.9087, 115.7172],
  [-33.9157, 115.7193],
  [-33.9172, 115.7204],
  [-33.9180, 115.7221],
  [-33.9186, 115.7256],
  [-33.9184, 115.7283],
  [-33.9187, 115.7299],
  [-33.9195, 115.7311],
  [-33.9205, 115.7325],
  [-33.9208, 115.7339],
  [-33.9206, 115.7352],
  [-33.9202, 115.7368],
  [-33.9204, 115.7387],
  [-33.9213, 115.7400],
  [-33.9229, 115.7407],
  [-33.9272, 115.7409],
  [-33.9291, 115.7415],
  [-33.9308, 115.7431],
  [-33.9329, 115.7463],
  [-33.9338, 115.7470],
  [-33.9350, 115.7470],
  [-33.9367, 115.7465],
  [-33.9383, 115.7470],
  [-33.9400, 115.7495],
  [-33.9415, 115.7508],
  [-33.9432, 115.7513],
  [-33.9442, 115.7521],
  [-33.9466, 115.7559],
  [-33.9477, 115.7567],
  [-33.9492, 115.7570],
  [-33.9503, 115.7576],
  [-33.9518, 115.7591],
  [-33.9534, 115.7600],
  [-33.9555, 115.7600],
  [-33.9575, 115.7603],
  [-33.9599, 115.7594],
  [-33.9614, 115.7591],
  [-33.9629, 115.7582],
  [-33.9647, 115.7575],
  [-33.9664, 115.7574],
  [-33.9681, 115.7569],
  [-33.9693, 115.7571],
  [-33.9708, 115.7593],
  [-33.9714, 115.7603],
  [-33.9724, 115.7610],
  [-33.9787, 115.7622],
  [-33.9800, 115.7614],
  [-33.9820, 115.7591],
  [-33.9837, 115.7582],
  [-33.9900, 115.7571],
  [-33.9953, 115.7570]
];

export const nannup: Line = {
  name: 'Nannup',
  state: 'WA',
  segments: [
    {
      segments: [busseltonJetty],
      history: {
        opened: {
          date: '1871-12-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1899-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [busseltonJettyLockville],
      history: {
        opened: {
          date: '1871-12-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1899-01-01',
          trackType: 'heavy'
        }, {
          date: '1984-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lockvilleYoganup],
      history: {
        opened: {
          date: '1871-12-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1899-01-01',
          trackType: 'heavy'
        }, {
          date: '1984-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yoganupJarrahville],
      history: {
        opened: {
          date: '1899-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [jarrahwoodNannup],
      history: {
        opened: {
          date: '1909-03-31',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
