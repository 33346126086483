import { Line } from "../../trackTypes";

const line = [
  [-37.7509, 145.3523],
  [-37.7499, 145.3543],
  [-37.7487, 145.3559],
  [-37.7483, 145.3567],
  [-37.7482, 145.3578],
  [-37.7485, 145.3589],
  [-37.7488, 145.3593],
  [-37.7498, 145.3597],
  [-37.7524, 145.3598],
  [-37.7563, 145.3596],
  [-37.7573, 145.3598],
  [-37.7593, 145.3608],
  [-37.7604, 145.3616],
  [-37.7625, 145.3639],
  [-37.7634, 145.3652],
  [-37.7644, 145.3660],
  [-37.7650, 145.3667],
  [-37.7652, 145.3676],
  [-37.7653, 145.3689],
  [-37.7654, 145.3694],
  [-37.7669, 145.3728],
  [-37.7673, 145.3734],
  [-37.7679, 145.3737],
  [-37.7687, 145.3740],
  [-37.7693, 145.3744],
  [-37.7697, 145.3750],
  [-37.7700, 145.3762],
  [-37.7705, 145.3771],
  [-37.7710, 145.3775],
  [-37.7723, 145.3782],
  [-37.7726, 145.3783],
  [-37.7732, 145.3782],
  [-37.7742, 145.3775],
  [-37.7749, 145.3773],
  [-37.7757, 145.3773],
  [-37.7771, 145.3778],
  [-37.7782, 145.3784],
  [-37.7811, 145.3794],
  [-37.7819, 145.3795],
  [-37.7826, 145.3792],
  [-37.7832, 145.3787],
  [-37.7838, 145.3786],
  [-37.7845, 145.3787],
  [-37.7859, 145.3794],
  [-37.7867, 145.3803],
  [-37.7872, 145.3818],
  [-37.7877, 145.3846],
  [-37.7888, 145.3877],
  [-37.7890, 145.3900],
  [-37.7876, 145.3932],
  [-37.7865, 145.3941],
  [-37.7844, 145.3945],
  [-37.7836, 145.3950],
  [-37.7830, 145.3959],
  [-37.7818, 145.4001],
  [-37.7817, 145.4013],
  [-37.7818, 145.4085],
  [-37.7821, 145.4124],
  [-37.7820, 145.4144],
  [-37.7809, 145.4195],
  [-37.7802, 145.4207],
  [-37.7789, 145.4225],
  [-37.7785, 145.4233],
  [-37.7780, 145.4251],
  [-37.7772, 145.4268],
  [-37.7769, 145.4283],
  [-37.7770, 145.4296],
  [-37.7775, 145.4314],
  [-37.7775, 145.4327],
  [-37.7765, 145.4399],
  [-37.7759, 145.4415],
  [-37.7751, 145.4424],
  [-37.7734, 145.4439],
  [-37.7728, 145.4447],
  [-37.7712, 145.4479],
  [-37.7700, 145.4554],
  [-37.7692, 145.4574],
  [-37.7654, 145.4611],
  [-37.7645, 145.4622],
  [-37.7636, 145.4638],
  [-37.7626, 145.4647],
  [-37.7601, 145.4660],
  [-37.7593, 145.4667],
  [-37.7589, 145.4677],
  [-37.7587, 145.4716],
  [-37.7575, 145.4791],
  [-37.7580, 145.4943],
  [-37.7576, 145.5013],
  [-37.7579, 145.5028],
  [-37.7587, 145.5040],
  [-37.7603, 145.5050],
  [-37.7624, 145.5055],
  [-37.7633, 145.5062],
  [-37.7641, 145.5072],
  [-37.7645, 145.5084],
  [-37.7650, 145.5151],
  [-37.7655, 145.5169],
  [-37.7665, 145.5183],
  [-37.7721, 145.5224],
  [-37.7734, 145.5239],
  [-37.7750, 145.5269],
  [-37.7753, 145.5285],
  [-37.7754, 145.5321],
  [-37.7747, 145.5349],
  [-37.7736, 145.5365],
  [-37.7705, 145.5395],
  [-37.7698, 145.5404],
  [-37.7658, 145.5465],
  [-37.7656, 145.5474],
  [-37.7657, 145.5488],
  [-37.7656, 145.5500],
  [-37.7660, 145.5511],
  [-37.7680, 145.5537],
  [-37.7688, 145.5550],
  [-37.7697, 145.5555],
  [-37.7706, 145.5565],
  [-37.7742, 145.5664],
  [-37.7745, 145.5681],
  [-37.7745, 145.5706],
  [-37.7750, 145.5727],
  [-37.7752, 145.5747],
  [-37.7750, 145.5785],
  [-37.7747, 145.5795],
  [-37.7741, 145.5811],
  [-37.7745, 145.5829],
  [-37.7757, 145.5842],
  [-37.7762, 145.5851],
  [-37.7760, 145.5883],
  [-37.7758, 145.5896],
  [-37.7764, 145.5927],
  [-37.7763, 145.5942],
  [-37.7759, 145.5964],
  [-37.7763, 145.6003],
  [-37.7776, 145.6055],
  [-37.7781, 145.6063],
  [-37.7792, 145.6072],
  [-37.7803, 145.6082],
  [-37.7812, 145.6105],
  [-37.7812, 145.6117],
  [-37.7810, 145.6127],
  [-37.7810, 145.6143],
  [-37.7813, 145.6165],
  [-37.7812, 145.6183],
  [-37.7812, 145.6204],
  [-37.7805, 145.6216],
  [-37.7794, 145.6223],
  [-37.7775, 145.6226],
  [-37.7757, 145.6236],
  [-37.7747, 145.6249],
  [-37.7562, 145.6518],
  [-37.7539, 145.6560],
  [-37.7537, 145.6570],
  [-37.7544, 145.6625],
  [-37.7550, 145.6664],
  [-37.7547, 145.6706],
  [-37.7550, 145.6727],
  [-37.7553, 145.6733],
  [-37.7561, 145.6745],
  [-37.7565, 145.6761],
  [-37.7564, 145.6777],
  [-37.7556, 145.6795],
  [-37.7552, 145.6823],
  [-37.7554, 145.6836],
  [-37.7553, 145.6844],
  [-37.7546, 145.6885],
  [-37.7526, 145.6954]
];

export const warburton: Line = {
  name: 'Warburton',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1901-11-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1965-07-29',
          status: 'closed'
        }]
      }
    }
  ]
}
