import { Line } from "../../trackTypes";

const junctionGundagai = [
  [-34.6490, 148.0228],
  [-34.6498, 148.0224],
  [-34.6504, 148.0222],
  [-34.6515, 148.0224],
  [-34.6838, 148.0355],
  [-34.6900, 148.0374],
  [-34.6965, 148.0382],
  [-34.7173, 148.0384],
  [-34.7230, 148.0393],
  [-34.7291, 148.0412],
  [-34.7331, 148.0432],
  [-34.7463, 148.0517],
  [-34.7578, 148.0641],
  [-34.7598, 148.0678],
  [-34.7618, 148.0747],
  [-34.7626, 148.0764],
  [-34.7638, 148.0779],
  [-34.7735, 148.0840],
  [-34.7784, 148.0892],
  [-34.7852, 148.1027],
  [-34.7869, 148.1045],
  [-34.7897, 148.1056],
  [-34.7975, 148.1062],
  [-34.8007, 148.1070],
  [-34.8114, 148.1132],
  [-34.8134, 148.1139],
  [-34.8157, 148.1137],
  [-34.8199, 148.1122],
  [-34.8221, 148.1122],
  [-34.8484, 148.1190],
  [-34.8523, 148.1198],
  [-34.8812, 148.1241],
  [-34.8833, 148.1252],
  [-34.8920, 148.1327],
  [-34.8996, 148.1377],
  [-34.9006, 148.1387],
  [-34.9016, 148.1410],
  [-34.9031, 148.1514],
  [-34.9036, 148.1529],
  [-34.9049, 148.1544],
  [-34.9066, 148.1549],
  [-34.9109, 148.1543],
  [-34.9125, 148.1544],
  [-34.9144, 148.1553],
  [-34.9233, 148.1642],
  [-34.9265, 148.1662],
  [-34.9290, 148.1671],
  [-34.9456, 148.1694],
  [-34.9469, 148.1693],
  [-34.9487, 148.1686],
  [-34.9503, 148.1676],
  [-34.9548, 148.1598],
  [-34.9561, 148.1586],
  [-34.9576, 148.1578],
  [-34.9596, 148.1573],
  [-34.9610, 148.1569],
  [-34.9777, 148.1471],
  [-34.9918, 148.1388],
  [-34.9933, 148.1383],
  [-35.0034, 148.1356],
  [-35.0046, 148.1351],
  [-35.0067, 148.1329],
  [-35.0078, 148.1322],
  [-35.0088, 148.1318],
  [-35.0096, 148.1313],
  [-35.0107, 148.1300],
  [-35.0126, 148.1260],
  [-35.0134, 148.1251],
  [-35.0146, 148.1244],
  [-35.0160, 148.1243],
  [-35.0212, 148.1260],
  [-35.0220, 148.1261],
  [-35.0232, 148.1257],
  [-35.0263, 148.1237],
  [-35.0289, 148.1221],
  [-35.0314, 148.1213],
  [-35.0342, 148.1210],
  [-35.0369, 148.1214],
  [-35.0446, 148.1239],
  [-35.0458, 148.1240],
  [-35.0470, 148.1234],
  [-35.0516, 148.1191],
  [-35.0529, 148.1184],
  [-35.0550, 148.1181],
  [-35.0629, 148.1185],
  [-35.0639, 148.1181],
  [-35.0649, 148.1169],
  [-35.0655, 148.1128]
];

const gundagaiMountHoreb = [
  [-35.0655, 148.1128],
  [-35.0657, 148.1119],
  [-35.0660, 148.1110],
  [-35.0664, 148.1104],
  [-35.0675, 148.1087],
  [-35.0681, 148.1082],
  [-35.0736, 148.1042],
  [-35.0745, 148.1034],
  [-35.0779, 148.0984],
  [-35.0785, 148.0979],
  [-35.0800, 148.0972],
  [-35.0818, 148.0967],
  [-35.0830, 148.0967],
  [-35.0872, 148.0978],
  [-35.0881, 148.0977],
  [-35.0890, 148.0974],
  [-35.0898, 148.0969],
  [-35.0939, 148.0924],
  [-35.0960, 148.0895],
  [-35.0980, 148.0877],
  [-35.0983, 148.0863],
  [-35.0979, 148.0834],
  [-35.0969, 148.0818],
  [-35.0963, 148.0814],
  [-35.0936, 148.0804],
  [-35.0926, 148.0797],
  [-35.0921, 148.0787],
  [-35.0921, 148.0776],
  [-35.0925, 148.0766],
  [-35.0930, 148.0761],
  [-35.0937, 148.0758],
  [-35.0966, 148.0754],
  [-35.0971, 148.0752],
  [-35.0975, 148.0749],
  [-35.1003, 148.0706],
  [-35.1008, 148.0699],
  [-35.1013, 148.0696],
  [-35.1025, 148.0690],
  [-35.1052, 148.0667],
  [-35.1086, 148.0640],
  [-35.1096, 148.0630],
  [-35.1104, 148.0615],
  [-35.1179, 148.0449],
  [-35.1182, 148.0434],
  [-35.1180, 148.0419],
  [-35.1176, 148.0402],
  [-35.1176, 148.0384],
  [-35.1179, 148.0364],
  [-35.1179, 148.0347],
  [-35.1173, 148.0322],
  [-35.1172, 148.0307],
  [-35.1180, 148.0271],
  [-35.1190, 148.0259],
  [-35.1352, 148.0118],
  [-35.1369, 148.0107],
  [-35.1426, 148.0077],
  [-35.1467, 148.0064],
  [-35.1478, 148.0063],
  [-35.1527, 148.0066],
  [-35.1566, 148.0058],
  [-35.1579, 148.0060],
  [-35.1587, 148.0069],
  [-35.1603, 148.0083],
  [-35.1676, 148.0104],
  [-35.1688, 148.0110],
  [-35.1698, 148.0120],
  [-35.1726, 148.0164],
  [-35.1737, 148.0195],
  [-35.1745, 148.0210],
  [-35.1878, 148.0349],
  [-35.1889, 148.0356],
  [-35.1906, 148.0357],
  [-35.1917, 148.0353],
  [-35.1936, 148.0338],
  [-35.1948, 148.0333],
  [-35.1981, 148.0330],
  [-35.2001, 148.0337],
  [-35.2031, 148.0361],
  [-35.2042, 148.0366],
  [-35.2066, 148.0374]
];

const mountHorebGilmore = [
  [-35.2066, 148.0374],
  [-35.2105, 148.0384],
  [-35.2115, 148.0393],
  [-35.2120, 148.0403],
  [-35.2133, 148.0466],
  [-35.2132, 148.0562],
  [-35.2134, 148.0577],
  [-35.2147, 148.0621],
  [-35.2177, 148.0669],
  [-35.2184, 148.0673],
  [-35.2200, 148.0677],
  [-35.2210, 148.0684],
  [-35.2260, 148.0743],
  [-35.2268, 148.0747],
  [-35.2277, 148.0748],
  [-35.2296, 148.0742],
  [-35.2303, 148.0742],
  [-35.2312, 148.0747],
  [-35.2321, 148.0759],
  [-35.2322, 148.0773],
  [-35.2317, 148.0793],
  [-35.2316, 148.0803],
  [-35.2319, 148.0815],
  [-35.2324, 148.0822],
  [-35.2346, 148.0847],
  [-35.2358, 148.0853],
  [-35.2399, 148.0865],
  [-35.2418, 148.0875],
  [-35.2461, 148.0920],
  [-35.2482, 148.0933],
  [-35.2494, 148.0944],
  [-35.2505, 148.0954],
  [-35.2524, 148.0961],
  [-35.2535, 148.0970],
  [-35.2541, 148.0981],
  [-35.2544, 148.1005],
  [-35.2551, 148.1025],
  [-35.2560, 148.1038],
  [-35.2574, 148.1050],
  [-35.2588, 148.1055],
  [-35.2621, 148.1058],
  [-35.2649, 148.1054],
  [-35.2658, 148.1051],
  [-35.2667, 148.1049],
  [-35.2677, 148.1052],
  [-35.2753, 148.1092],
  [-35.2801, 148.1143],
  [-35.2812, 148.1150],
  [-35.2840, 148.1159],
  [-35.2847, 148.1164],
  [-35.2853, 148.1171],
  [-35.2856, 148.1179],
  [-35.2863, 148.1207],
  [-35.2876, 148.1235],
  [-35.2884, 148.1245],
  [-35.2918, 148.1272],
  [-35.2932, 148.1278],
  [-35.2963, 148.1277],
  [-35.2976, 148.1281],
  [-35.2989, 148.1292],
  [-35.2996, 148.1303],
  [-35.3031, 148.1377],
  [-35.3044, 148.1390],
  [-35.3066, 148.1405],
  [-35.3074, 148.1414],
  [-35.3080, 148.1425],
  [-35.3096, 148.1479],
  [-35.3106, 148.1498],
  [-35.3117, 148.1513],
  [-35.3128, 148.1525],
  [-35.3169, 148.1557],
  [-35.3179, 148.1568],
  [-35.3189, 148.1581],
  [-35.3244, 148.1627],
  [-35.3271, 148.1636],
  [-35.3331, 148.1665],
  [-35.3340, 148.1673],
  [-35.3345, 148.1684],
  [-35.3346, 148.1697],
  [-35.3338, 148.1739],
  [-35.3333, 148.1749],
  [-35.3325, 148.1756],
  [-35.3318, 148.1759],
  [-35.3291, 148.1763]
];

const gilmoreTumut = [
  [-35.3291, 148.1763],
  [-35.3265, 148.1775],
  [-35.3215, 148.1797],
  [-35.3172, 148.1830],
  [-35.3158, 148.1836],
  [-35.3117, 148.1847],
  [-35.3106, 148.1854],
  [-35.3097, 148.1865],
  [-35.3064, 148.1916],
  [-35.3045, 148.1934],
  [-35.3037, 148.1943],
  [-35.2970, 148.2046],
  [-35.2961, 148.2067],
  [-35.2947, 148.2133]
];

export const tumut: Line = {
  name: 'Tumut',
  state: 'NSW',
  segments: [
    {
      segments: [junctionGundagai],
      history: {
        opened: {
          date: '1886-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gundagaiMountHoreb],
      history: {
        opened: {
          date: '1903-10-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mountHorebGilmore],
      history: {
        opened: {
          date: '1903-12-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gilmoreTumut],
      history: {
        opened: {
          date: '1903-12-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-19',
          status: 'closed'
        }, {
          date: '1990-05-01',
          status: 'closed'
        }, {
          date: '1988-12-01',
          status: 'open'
        }]
      }
    }
  ]
};
