import { Line } from "../../trackTypes";

const byelleeCalliope = [
  [-23.8661, 151.2107],
  [-23.8667, 151.2085],
  [-23.8674, 151.2073],
  [-23.8715, 151.2033],
  [-23.8766, 151.2009],
  [-23.8801, 151.2007],
  [-23.8834, 151.2004],
  [-23.8910, 151.2015],
  [-23.8941, 151.2029],
  [-23.9002, 151.2043],
  [-23.9020, 151.2051],
  [-23.9079, 151.2082],
  [-23.9103, 151.2086],
  [-23.9137, 151.2080],
  [-23.9150, 151.2081],
  [-23.9246, 151.2119],
  [-23.9272, 151.2149],
  [-23.9280, 151.2157],
  [-23.9363, 151.2187],
  [-23.9539, 151.2199],
  [-23.9551, 151.2197],
  [-23.9580, 151.2181],
  [-23.9586, 151.2176],
  [-23.9596, 151.2165],
  [-23.9622, 151.2147],
  [-23.9630, 151.2145],
  [-23.9650, 151.2144],
  [-23.9668, 151.2140],
  [-23.9675, 151.2137],
  [-23.9688, 151.2127],
  [-23.9691, 151.2119],
  [-23.9692, 151.2113],
  [-23.9687, 151.2100],
  [-23.9687, 151.2087],
  [-23.9679, 151.2065],
  [-23.9678, 151.2052],
  [-23.9692, 151.2009],
  [-23.9702, 151.2002],
  [-23.9712, 151.2000],
  [-23.9719, 151.1998],
  [-23.9730, 151.1991],
  [-23.9743, 151.1982],
  [-23.9772, 151.1970],
  [-23.9786, 151.1971],
  [-23.9802, 151.1981],
  [-23.9815, 151.1997],
  [-23.9829, 151.2024],
  [-23.9840, 151.2035],
  [-23.9859, 151.2038],
  [-23.9879, 151.2031],
  [-23.9891, 151.2015],
  [-23.9898, 151.1993]
];

const calliopeManyPeaks = [
  [-23.9898, 151.1993],
  [-23.9905, 151.1983],
  [-23.9924, 151.1969],
  [-23.9934, 151.1966],
  [-23.9947, 151.1968],
  [-24.0097, 151.2050],
  [-24.0113, 151.2052],
  [-24.0153, 151.2046],
  [-24.0164, 151.2046],
  [-24.0175, 151.2053],
  [-24.0190, 151.2072],
  [-24.0205, 151.2081],
  [-24.0224, 151.2086],
  [-24.0241, 151.2093],
  [-24.0282, 151.2121],
  [-24.0294, 151.2124],
  [-24.0344, 151.2122],
  [-24.0355, 151.2126],
  [-24.0365, 151.2132],
  [-24.0376, 151.2134],
  [-24.0387, 151.2132],
  [-24.0398, 151.2122],
  [-24.0410, 151.2119],
  [-24.0450, 151.2134],
  [-24.0461, 151.2143],
  [-24.0484, 151.2175],
  [-24.0495, 151.2183],
  [-24.0542, 151.2199],
  [-24.0553, 151.2208],
  [-24.0561, 151.2222],
  [-24.0569, 151.2230],
  [-24.0582, 151.2234],
  [-24.0598, 151.2231],
  [-24.0609, 151.2232],
  [-24.0627, 151.2239],
  [-24.0638, 151.2240],
  [-24.0652, 151.2235],
  [-24.0668, 151.2233],
  [-24.0681, 151.2230],
  [-24.0701, 151.2229],
  [-24.0712, 151.2224],
  [-24.0749, 151.2189],
  [-24.0759, 151.2185],
  [-24.0804, 151.2186],
  [-24.0835, 151.2179],
  [-24.0911, 151.2176],
  [-24.0922, 151.2177],
  [-24.0935, 151.2181],
  [-24.0944, 151.2182],
  [-24.0968, 151.2175],
  [-24.0979, 151.2174],
  [-24.0988, 151.2177],
  [-24.1012, 151.2197],
  [-24.1020, 151.2201],
  [-24.1041, 151.2206],
  [-24.1064, 151.2205],
  [-24.1071, 151.2203],
  [-24.1085, 151.2192],
  [-24.1094, 151.2188],
  [-24.1122, 151.2184],
  [-24.1158, 151.2167],
  [-24.1226, 151.2107],
  [-24.1247, 151.2094],
  [-24.1263, 151.2073],
  [-24.1264, 151.2061],
  [-24.1255, 151.2018],
  [-24.1256, 151.2008],
  [-24.1260, 151.2000],
  [-24.1271, 151.1994],
  [-24.1287, 151.1995],
  [-24.1353, 151.1986],
  [-24.1368, 151.1987],
  [-24.1384, 151.1994],
  [-24.1400, 151.2009],
  [-24.1414, 151.2014],
  [-24.1434, 151.2015],
  [-24.1448, 151.2022],
  [-24.1455, 151.2032],
  [-24.1461, 151.2037],
  [-24.1470, 151.2038],
  [-24.1479, 151.2034],
  [-24.1491, 151.2036],
  [-24.1531, 151.2064],
  [-24.1548, 151.2066],
  [-24.1563, 151.2079],
  [-24.1604, 151.2099],
  [-24.1620, 151.2117],
  [-24.1644, 151.2135],
  [-24.1715, 151.2163],
  [-24.1733, 151.2173],
  [-24.1765, 151.2199],
  [-24.1797, 151.2213],
  [-24.1836, 151.2244],
  [-24.1861, 151.2254],
  [-24.1879, 151.2267],
  [-24.1967, 151.2373],
  [-24.1983, 151.2383],
  [-24.2004, 151.2387],
  [-24.2034, 151.2386],
  [-24.2082, 151.2400],
  [-24.2104, 151.2412],
  [-24.2222, 151.2519],
  [-24.2232, 151.2527],
  [-24.2244, 151.2531],
  [-24.2298, 151.2529],
  [-24.2407, 151.2538],
  [-24.2518, 151.2574],
  [-24.2617, 151.2648],
  [-24.2857, 151.2740],
  [-24.2991, 151.2761],
  [-24.3152, 151.2818],
  [-24.3188, 151.2844],
  [-24.3666, 151.3029],
  [-24.4591, 151.3490],
  [-24.4735, 151.3571],
  [-24.4738, 151.3574],
  [-24.4760, 151.3573],
  [-24.4782, 151.3571],
  [-24.4802, 151.3578],
  [-24.4834, 151.3600],
  [-24.4895, 151.3626],
  [-24.4916, 151.3630],
  [-24.4937, 151.3626],
  [-24.4950, 151.3630],
  [-24.4975, 151.3651],
  [-24.5019, 151.3667],
  [-24.5060, 151.3679],
  [-24.5130, 151.3734],
  [-24.5160, 151.3772],
  [-24.5187, 151.3792],
  [-24.5300, 151.3848],
  [-24.5333, 151.3854],
  [-24.5352, 151.3854],
  [-24.5368, 151.3864],
  [-24.5374, 151.3866],
  [-24.5387, 151.3867],
  [-24.5392, 151.3863],
  [-24.5395, 151.3852],
  [-24.5389, 151.3834],
  [-24.5387, 151.3829],
  [-24.5380, 151.3823],
  [-24.5378, 151.3817],
  [-24.5380, 151.3808],
  [-24.5391, 151.3785]
];

const manyPeaksTunnelNo1 = [
  [-24.5391, 151.3785],
  [-24.5396, 151.3776],
  [-24.5402, 151.3749],
  [-24.5406, 151.3745],
  [-24.5414, 151.3741],
  [-24.5427, 151.3727],
  [-24.5447, 151.3683],
  [-24.5453, 151.3676],
  [-24.5471, 151.3660],
  [-24.5474, 151.3653],
  [-24.5474, 151.3646],
  [-24.5469, 151.3637],
  [-24.5461, 151.3631],
  [-24.5457, 151.3626],
  [-24.5455, 151.3619],
  [-24.5461, 151.3596],
  [-24.5462, 151.3584],
  [-24.5457, 151.3564],
  [-24.5443, 151.3542],
  [-24.5439, 151.3527],
  [-24.5441, 151.3507],
  [-24.5448, 151.3493],
  [-24.5494, 151.3450],
  [-24.5524, 151.3401],
  [-24.5550, 151.3376],
  [-24.5554, 151.3369],
  [-24.5558, 151.3360],
  [-24.5563, 151.3352],
  [-24.5577, 151.3344],
  [-24.5602, 151.3309],
  [-24.5612, 151.3305],
  [-24.5641, 151.3312],
  [-24.5648, 151.3311],
  [-24.5663, 151.3299],
  [-24.5668, 151.3297],
  [-24.5692, 151.3300],
  [-24.5709, 151.3307],
  [-24.5716, 151.3306],
  [-24.5722, 151.3302],
  [-24.5729, 151.3300],
  [-24.5734, 151.3298],
  [-24.5736, 151.3292],
  [-24.5736, 151.3287],
  [-24.5737, 151.3282],
  [-24.5741, 151.3278],
  [-24.5765, 151.3271],
  [-24.5770, 151.3268],
  [-24.5776, 151.3262],
  [-24.5784, 151.3259],
  [-24.5789, 151.3256],
  [-24.5802, 151.3239],
  [-24.5808, 151.3224],
  [-24.5810, 151.3210],
  [-24.5808, 151.3202],
  [-24.5812, 151.3192],
  [-24.5850, 151.3146],
  [-24.5862, 151.3138],
  [-24.5896, 151.3128],
  [-24.5933, 151.3129],
  [-24.5943, 151.3128],
  [-24.5964, 151.3119],
  [-24.5978, 151.3117],
  [-24.5994, 151.3122],
  [-24.6002, 151.3128],
  [-24.6013, 151.3145],
  [-24.6039, 151.3169],
  [-24.6048, 151.3171],
  [-24.6056, 151.3167],
  [-24.6072, 151.3143],
  [-24.6078, 151.3138],
  [-24.6092, 151.3135],
  [-24.6103, 151.3138],
  [-24.6117, 151.3149],
  [-24.6128, 151.3155],
  [-24.6155, 151.3164],
  [-24.6174, 151.3166],
  [-24.6208, 151.3190],
  [-24.6211, 151.3196],
  [-24.6208, 151.3219],
  [-24.6210, 151.3227],
  [-24.6217, 151.3231],
  [-24.6240, 151.3232],
  [-24.6245, 151.3234],
  [-24.6248, 151.3238],
  [-24.6251, 151.3251],
  [-24.6255, 151.3255],
  [-24.6261, 151.3257],
  [-24.6266, 151.3254],
  [-24.6271, 151.3246],
  [-24.6277, 151.3243],
  [-24.6290, 151.3244],
  [-24.6298, 151.3238],
  [-24.6306, 151.3221],
  [-24.6310, 151.3217],
  [-24.6321, 151.3212],
  [-24.6324, 151.3207],
  [-24.6324, 151.3201],
  [-24.6318, 151.3189],
  [-24.6314, 151.3184],
  [-24.6305, 151.3178],
  [-24.6302, 151.3173],
  [-24.6299, 151.3160],
  [-24.6301, 151.3153],
  [-24.6305, 151.3150],
  [-24.6312, 151.3151],
  [-24.6320, 151.3157],
  [-24.6328, 151.3159],
  [-24.6341, 151.3158],
  [-24.6349, 151.3152],
  [-24.6358, 151.3137],
  [-24.6363, 151.3134],
  [-24.6368, 151.3134],
  [-24.6373, 151.3138],
  [-24.6376, 151.3147],
  [-24.6381, 151.3151],
  [-24.6388, 151.3150],
  [-24.6403, 151.3130],
  [-24.6416, 151.3118],
  [-24.6422, 151.3117],
  [-24.6446, 151.3126],
  [-24.6454, 151.3123],
  [-24.6457, 151.3116],
  [-24.6454, 151.3109],
  [-24.6444, 151.3103],
  [-24.6442, 151.3098],
  [-24.6442, 151.3093],
  [-24.6444, 151.3089]
];

const manyPeaksNo1Tunnel = [
  [-24.6444, 151.3089],
  [-24.6447, 151.3086],
  [-24.6451, 151.3085]
];

const manyPeaksNo1TunnelManyPeaksNo2Tunnel = [
  [-24.6451, 151.3085],
  [-24.6461, 151.3083],
  [-24.6467, 151.3079],
  [-24.6470, 151.3072],
  [-24.6471, 151.3063],
  [-24.6461, 151.3039],
  [-24.6455, 151.3029],
  [-24.6455, 151.3023],
  [-24.6458, 151.3018],
  [-24.6463, 151.3015],
  [-24.6469, 151.3015],
  [-24.6475, 151.3019],
  [-24.6480, 151.3018],
  [-24.6485, 151.3014],
  [-24.6486, 151.3007],
  [-24.6482, 151.2988],
  [-24.6482, 151.2983],
  [-24.6485, 151.2976],
  [-24.6490, 151.2972]
];

const manyPeaksNo2Tunnel = [
  [-24.6490, 151.2972],
  [-24.6494, 151.2970],
  [-24.6502, 151.2969]
];

const manyPeaksNo2TunnelManyPeaksNo3Tunnel = [
  [-24.6502, 151.2969],
  [-24.6508, 151.2970],
  [-24.6510, 151.2971]
];

const manyPeaksNo3Tunnel = [
  [-24.6510, 151.2971],
  [-24.6519, 151.2978]
];

const manyPeaksNo3TunnelManyPeaksNo4Tunnel = [
  [-24.6519, 151.2978],
  [-24.6522, 151.2981],
  [-24.6524, 151.2982],
  [-24.6532, 151.2983],
  [-24.6536, 151.2985],
  [-24.6539, 151.2987]
];

const manyPeaksNo4Tunnel = [
  [-24.6539, 151.2987],
  [-24.6544, 151.2994]
];

const manyPeaksNo4TunnelManyPeaksNo5Tunnel = [
  [-24.6544, 151.2994],
  [-24.6550, 151.3002]
];

const manyPeaksNo5Tunnel = [
  [-24.6550, 151.3002],
  [-24.6558, 151.3012]
];

const manyPeaksNo5TunnelManyPeaksNo6Tunnel = [
  [-24.6558, 151.3012],
  [-24.6564, 151.3021]
];

const manyPeaksNo6Tunnel = [
  [-24.6564, 151.3021],
  [-24.6572, 151.3031]
];

const manyPeaksNo6TunnelBarrimoon = [
  [-24.6572, 151.3031],
  [-24.6582, 151.3046],
  [-24.6586, 151.3049],
  [-24.6595, 151.3051],
  [-24.6603, 151.3058],
  [-24.6620, 151.3083]
];

const barrimoonMungungo = [
  [-24.6620, 151.3083],
  [-24.6658, 151.3123],
  [-24.6662, 151.3133],
  [-24.6662, 151.3142],
  [-24.6664, 151.3149],
  [-24.6676, 151.3173],
  [-24.6694, 151.3189],
  [-24.6701, 151.3190],
  [-24.6708, 151.3187],
  [-24.6714, 151.3179],
  [-24.6721, 151.3176],
  [-24.6730, 151.3178],
  [-24.6737, 151.3178],
  [-24.6770, 151.3155],
  [-24.6774, 151.3143],
  [-24.6769, 151.3134],
  [-24.6759, 151.3129],
  [-24.6752, 151.3123],
  [-24.6741, 151.3103],
  [-24.6741, 151.3096],
  [-24.6745, 151.3090],
  [-24.6753, 151.3088],
  [-24.6761, 151.3090],
  [-24.6767, 151.3091],
  [-24.6783, 151.3086],
  [-24.6786, 151.3083],
  [-24.6793, 151.3076],
  [-24.6802, 151.3076],
  [-24.6815, 151.3089],
  [-24.6835, 151.3100],
  [-24.6852, 151.3097],
  [-24.6864, 151.3085],
  [-24.6867, 151.3077],
  [-24.6868, 151.3067],
  [-24.6873, 151.3058],
  [-24.6882, 151.3055],
  [-24.6960, 151.3069],
  [-24.6976, 151.3067],
  [-24.7017, 151.3048],
  [-24.7024, 151.3047],
  [-24.7037, 151.3047],
  [-24.7050, 151.3039],
  [-24.7051, 151.3023],
  [-24.7015, 151.2938],
  [-24.7014, 151.2917],
  [-24.7019, 151.2904],
  [-24.7064, 151.2852],
  [-24.7071, 151.2849],
  [-24.7083, 151.2846],
  [-24.7092, 151.2835],
  [-24.7095, 151.2817],
  [-24.7100, 151.2808],
  [-24.7108, 151.2805],
  [-24.7120, 151.2806],
  [-24.7133, 151.2800],
  [-24.7139, 151.2792],
  [-24.7144, 151.2779],
  [-24.7152, 151.2773],
  [-24.7162, 151.2773],
  [-24.7180, 151.2786],
  [-24.7189, 151.2787],
  [-24.7198, 151.2782],
  [-24.7216, 151.2759],
  [-24.7242, 151.2711],
  [-24.7252, 151.2705],
  [-24.7286, 151.2702],
  [-24.7293, 151.2699],
  [-24.7296, 151.2692],
  [-24.7298, 151.2662],
  [-24.7301, 151.2657],
  [-24.7307, 151.2654],
  [-24.7322, 151.2650],
  [-24.7363, 151.2628],
  [-24.7378, 151.2614],
  [-24.7385, 151.2609],
  [-24.7408, 151.2602],
  [-24.7430, 151.2603],
  [-24.7451, 151.2616],
  [-24.7459, 151.2639],
  [-24.7475, 151.2658],
  [-24.7491, 151.2658],
  [-24.7505, 151.2648],
  [-24.7532, 151.2639],
  [-24.7546, 151.2631],
  [-24.7555, 151.2630],
  [-24.7571, 151.2635],
  [-24.7581, 151.2632],
  [-24.7585, 151.2624],
  [-24.7588, 151.2583],
  [-24.7591, 151.2575],
  [-24.7600, 151.2563],
  [-24.7613, 151.2559],
  [-24.7624, 151.2565],
  [-24.7634, 151.2581],
  [-24.7646, 151.2589],
  [-24.7655, 151.2590],
  [-24.7675, 151.2588],
  [-24.7689, 151.2594],
  [-24.7694, 151.2602],
  [-24.7700, 151.2607],
  [-24.7710, 151.2615],
  [-24.7714, 151.2628],
  [-24.7721, 151.2635],
  [-24.7758, 151.2645],
  [-24.7772, 151.2640],
  [-24.7779, 151.2626],
  [-24.7776, 151.2605],
  [-24.7780, 151.2593],
  [-24.7788, 151.2589],
  [-24.7800, 151.2588],
  [-24.7808, 151.2584],
  [-24.7850, 151.2505],
  [-24.7849, 151.2497],
  [-24.7845, 151.2490],
  [-24.7832, 151.2485],
  [-24.7809, 151.2463],
  [-24.7806, 151.2452],
  [-24.7810, 151.2441],
  [-24.7821, 151.2434],
  [-24.7829, 151.2421],
  [-24.7831, 151.2339],
  [-24.7836, 151.2329],
  [-24.7843, 151.2324],
  [-24.7885, 151.2312],
  [-24.7890, 151.2297],
  [-24.7889, 151.2285],
  [-24.7875, 151.2253],
  [-24.7872, 151.2239],
  [-24.7867, 151.2201],
  [-24.7863, 151.2193],
  [-24.7844, 151.2169],
  [-24.7796, 151.2136],
  [-24.7787, 151.2124],
  [-24.7781, 151.2115],
  [-24.7767, 151.2060],
  [-24.7766, 151.2047],
  [-24.7772, 151.2035],
  [-24.7770, 151.2023],
  [-24.7764, 151.2017],
  [-24.7739, 151.2006],
  [-24.7730, 151.1998],
  [-24.7720, 151.1984],
  [-24.7715, 151.1979],
  [-24.7699, 151.1972],
  [-24.7694, 151.1968],
  [-24.7674, 151.1936],
  [-24.7668, 151.1912],
  [-24.7664, 151.1905],
  [-24.7647, 151.1888],
  [-24.7640, 151.1885],
  [-24.7628, 151.1885],
  [-24.7621, 151.1883],
  [-24.7589, 151.1857],
  [-24.7580, 151.1854],
  [-24.7561, 151.1858],
  [-24.7552, 151.1857],
  [-24.7543, 151.1849],
  [-24.7540, 151.1839],
  [-24.7545, 151.1820],
  [-24.7550, 151.1813],
  [-24.7558, 151.1805],
  [-24.7562, 151.1797],
  [-24.7565, 151.1770],
  [-24.7563, 151.1762],
  [-24.7558, 151.1752],
  [-24.7557, 151.1743],
  [-24.7560, 151.1729],
  [-24.7557, 151.1720],
  [-24.7544, 151.1710],
  [-24.7541, 151.1703],
  [-24.7542, 151.1695],
  [-24.7547, 151.1690],
  [-24.7570, 151.1685],
  [-24.7577, 151.1681],
  [-24.7585, 151.1667],
  [-24.7594, 151.1661],
  [-24.7603, 151.1661],
  [-24.7611, 151.1659],
  [-24.7621, 151.1653],
  [-24.7627, 151.1652],
  [-24.7639, 151.1654],
  [-24.7646, 151.1653],
  [-24.7652, 151.1650],
  [-24.7679, 151.1612]
];

const mungungoMonto = [
  [-24.7679, 151.1612],
  [-24.7707, 151.1571],
  [-24.7712, 151.1557],
  [-24.7749, 151.1509],
  [-24.7762, 151.1498],
  [-24.7793, 151.1483],
  [-24.7804, 151.1482],
  [-24.7838, 151.1497],
  [-24.7852, 151.1500],
  [-24.7873, 151.1497],
  [-24.7882, 151.1498],
  [-24.7975, 151.1533],
  [-24.8034, 151.1564],
  [-24.8048, 151.1568],
  [-24.8060, 151.1565],
  [-24.8090, 151.1546],
  [-24.8103, 151.1532],
  [-24.8111, 151.1520],
  [-24.8122, 151.1510],
  [-24.8211, 151.1474],
  [-24.8272, 151.1454],
  [-24.8281, 151.1449],
  [-24.8286, 151.1440],
  [-24.8292, 151.1407],
  [-24.8290, 151.1391],
  [-24.8287, 151.1368],
  [-24.8283, 151.1361],
  [-24.8280, 151.1353],
  [-24.8282, 151.1343],
  [-24.8287, 151.1335],
  [-24.8288, 151.1326],
  [-24.8285, 151.1306],
  [-24.8291, 151.1267],
  [-24.8302, 151.1248],
  [-24.8319, 151.1237],
  [-24.8332, 151.1236],
  [-24.8350, 151.1242],
  [-24.8360, 151.1243],
  [-24.8409, 151.1234],
  [-24.8420, 151.1236],
  [-24.8437, 151.1250],
  [-24.8448, 151.1252],
  [-24.8474, 151.1249],
  [-24.8497, 151.1250],
  [-24.8564, 151.1282],
  [-24.8574, 151.1283],
  [-24.8585, 151.1279],
  [-24.8595, 151.1268],
  [-24.8608, 151.1232],
  [-24.8611, 151.1226],
  [-24.8619, 151.1216]
];

export const byelleMonto: Line = {
  name: 'Byelle - Monto',
  state: 'QLD',
  segments: [
    {
      segments: [byelleeCalliope],
      history: {
        opened: {
          date: '1910-07-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [calliopeManyPeaks],
      history: {
        opened: {
          date: '1910-07-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        manyPeaksTunnelNo1,
        {
          name: 'Many Peaks No 1 Tunnel',
          segment: manyPeaksNo1Tunnel,
          type: 'tunnel'
        },
        manyPeaksNo1TunnelManyPeaksNo2Tunnel,
        {
          name: 'Many Peaks No 2 Tunnel',
          segment: manyPeaksNo2Tunnel,
          type: 'tunnel'
        },
        manyPeaksNo2TunnelManyPeaksNo3Tunnel,
        {
          name: 'Many Peaks No 3 Tunnel',
          segment: manyPeaksNo3Tunnel,
          type: 'tunnel'
        },
        manyPeaksNo3TunnelManyPeaksNo4Tunnel,
        {
          name: 'Many Peaks No 4 Tunnel',
          segment: manyPeaksNo4Tunnel,
          type: 'tunnel'
        },
        manyPeaksNo4TunnelManyPeaksNo5Tunnel,
        {
          name: 'Many Peaks No 5 Tunnel',
          segment: manyPeaksNo5Tunnel,
          type: 'tunnel'
        },
        manyPeaksNo5TunnelManyPeaksNo6Tunnel,
        {
          name: 'Many Peaks No 6 Tunnel',
          segment: manyPeaksNo6Tunnel,
          type: 'tunnel'
        },
        manyPeaksNo6TunnelBarrimoon
      ],
      history: {
        opened: {
          date: '1926-08-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [barrimoonMungungo],
      history: {
        opened: {
          date: '1930-07-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mungungoMonto],
      history: {
        opened: {
          date: '1931-07-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2002-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
