import { Line } from "../../trackTypes";

const line = [
  [-41.4250, 145.6969],
  [-41.4249, 145.6962],
  [-41.4245, 145.6950],
  [-41.4242, 145.6934],
  [-41.4243, 145.6926],
  [-41.4246, 145.6917],
  [-41.4265, 145.6892],
  [-41.4292, 145.6873],
  [-41.4299, 145.6862],
  [-41.4302, 145.6855],
  [-41.4303, 145.6838],
  [-41.4306, 145.6827],
  [-41.4305, 145.6819],
  [-41.4299, 145.6808],
  [-41.4297, 145.6799],
  [-41.4298, 145.6791],
  [-41.4306, 145.6772],
  [-41.4311, 145.6764],
  [-41.4313, 145.6754],
  [-41.4299, 145.6702],
  [-41.4298, 145.6692],
  [-41.4302, 145.6671],
  [-41.4303, 145.6666],
  [-41.4314, 145.6648],
  [-41.4333, 145.6630],
  [-41.4341, 145.6628],
  [-41.4348, 145.6631],
  [-41.4352, 145.6636],
  [-41.4355, 145.6639],
  [-41.4359, 145.6638],
  [-41.4371, 145.6623],
  [-41.4373, 145.6617],
  [-41.4373, 145.6612],
  [-41.4359, 145.6585],
  [-41.4350, 145.6577],
  [-41.4348, 145.6572],
  [-41.4347, 145.6566],
  [-41.4356, 145.6522],
  [-41.4354, 145.6508],
  [-41.4337, 145.6475],
  [-41.4331, 145.6456],
  [-41.4329, 145.6424],
  [-41.4330, 145.6407],
  [-41.4329, 145.6355],
  [-41.4334, 145.6307],
  [-41.4350, 145.6240],
  [-41.4349, 145.6228],
  [-41.4340, 145.6204],
  [-41.4330, 145.6158],
  [-41.4325, 145.6145],
  [-41.4324, 145.6138],
  [-41.4325, 145.6130],
  [-41.4382, 145.6036],
  [-41.4405, 145.6007],
  [-41.4416, 145.5999],
  [-41.4421, 145.5992],
  [-41.4445, 145.5930],
  [-41.4449, 145.5924],
  [-41.4453, 145.5919],
  [-41.4457, 145.5912],
  [-41.4459, 145.5899],
  [-41.4464, 145.5879],
  [-41.4463, 145.5868],
  [-41.4460, 145.5858],
  [-41.4459, 145.5853],
  [-41.4467, 145.5711],
  [-41.4471, 145.5591],
  [-41.4468, 145.5580],
  [-41.4461, 145.5559],
  [-41.4461, 145.5549],
  [-41.4464, 145.5540],
  [-41.4484, 145.5522],
  [-41.4487, 145.5513],
  [-41.4496, 145.5442],
  [-41.4494, 145.5429],
  [-41.4480, 145.5405],
  [-41.4469, 145.5390],
  [-41.4461, 145.5386],
  [-41.4459, 145.5382],
  [-41.4458, 145.5367],
  [-41.4456, 145.5361],
  [-41.4450, 145.5356],
  [-41.4448, 145.5351],
  [-41.4447, 145.5344],
  [-41.4448, 145.5339],
  [-41.4454, 145.5332],
  [-41.4455, 145.5326],
  [-41.4455, 145.5314],
  [-41.4452, 145.5309],
  [-41.4450, 145.5306],
  [-41.4448, 145.5300],
  [-41.4446, 145.5279]
];

export const waratah: Line = {
  name: 'Waratah',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1881-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1884-07-15',
          trackType: 'heavy'
        }, {
          date: '1940-06-29',
          status: 'closed'
        }]
      }
    }
  ]
}
