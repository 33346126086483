import { Line } from "../../../trackTypes";

const cloncurryKajabbi = [
  [-20.7143, 140.5157],
  [-20.7138, 140.5140],
  [-20.7121, 140.5115],
  [-20.7108, 140.5108],
  [-20.7002, 140.5108],
  [-20.6991, 140.5103],
  [-20.6977, 140.5088],
  [-20.6960, 140.5081],
  [-20.6912, 140.5079],
  [-20.6855, 140.5052],
  [-20.6845, 140.5044],
  [-20.6818, 140.5005],
  [-20.6812, 140.4981],
  [-20.6803, 140.4890],
  [-20.6803, 140.4890],
  [-20.6652, 140.4722],
  [-20.6593, 140.4678],
  [-20.6525, 140.4660],
  [-20.6507, 140.4658],
  [-20.6463, 140.4666],
  [-20.6441, 140.4662],
  [-20.5770, 140.4407],
  [-20.5402, 140.4128],
  [-20.4741, 140.3451],
  [-20.4597, 140.3235],
  [-20.4485, 140.3161],
  [-20.4465, 140.3133],
  [-20.4422, 140.3096],
  [-20.4240, 140.3017],
  [-20.4025, 140.2955],
  [-20.3950, 140.2925],
  [-20.3831, 140.2901],
  [-20.3749, 140.2862],
  [-20.3656, 140.2768],
  [-20.3634, 140.2753],
  [-20.3622, 140.2740],
  [-20.3591, 140.2667],
  [-20.3576, 140.2657],
  [-20.3554, 140.2653],
  [-20.3536, 140.2641],
  [-20.3513, 140.2618],
  [-20.3227, 140.2465],
  [-20.3193, 140.2427],
  [-20.3170, 140.2414],
  [-20.2676, 140.2355],
  [-20.2581, 140.2329],
  [-20.2556, 140.2329],
  [-20.2424, 140.2366],
  [-20.2349, 140.2362],
  [-20.2331, 140.2366],
  [-20.2263, 140.2397],
  [-20.2242, 140.2395],
  [-20.2144, 140.2351],
  [-20.2040, 140.2264],
  [-20.2012, 140.2260],
  [-20.1925, 140.2284],
  [-20.1896, 140.2280],
  [-20.1589, 140.2096],
  [-20.1540, 140.2085],
  [-20.1417, 140.1970],
  [-20.1360, 140.1932],
  [-20.1299, 140.1848],
  [-20.1286, 140.1789],
  [-20.1248, 140.1730],
  [-20.1102, 140.1596],
  [-20.1061, 140.1517],
  [-20.1039, 140.1433],
  [-20.1040, 140.1414],
  [-20.1050, 140.1378],
  [-20.1040, 140.1307],
  [-20.1028, 140.1283],
  [-20.0845, 140.1088],
  [-20.0775, 140.0960],
  [-20.0416, 140.0491],
  [-20.0299, 140.0386],
  [-20.0290, 140.0370],
  [-20.0285, 140.0358]
];

const kajabbiDobbyn = [
  [-20.0285, 140.0358],
  [-20.0219, 140.0118],
  [-20.0141, 140.0012],
  [-20.0140, 140.0012],
  [-19.9959, 139.9845],
  [-19.9941, 139.9835],
  [-19.9832, 139.9845],
  [-19.9746, 139.9855],
  [-19.9666, 139.9874],
  [-19.9393, 139.9919],
  [-19.9377, 139.9918],
  [-19.9326, 139.9935],
  [-19.9317, 139.9942],
  [-19.9306, 139.9957],
  [-19.9251, 139.9990],
  [-19.9167, 140.0009],
  [-19.9082, 140.0019],
  [-19.8925, 139.9984],
  [-19.8890, 139.9984],
  [-19.8813, 140.0010],
  [-19.8751, 140.0015],
  [-19.8741, 140.0019],
  [-19.8616, 140.0157],
  [-19.8602, 140.0166],
  [-19.8464, 140.0242],
  [-19.8432, 140.0250],
  [-19.8332, 140.0263],
  [-19.8285, 140.0271],
  [-19.8273, 140.0269],
  [-19.8266, 140.0259],
  [-19.8225, 140.0194],
  [-19.8206, 140.0172],
  [-19.8146, 140.0105],
  [-19.8132, 140.0098],
  [-19.8122, 140.0097],
  [-19.8092, 140.0086],
  [-19.8037, 140.0043],
  [-19.8022, 140.0026],
  [-19.8012, 140.0006],
  [-19.8002, 139.9995],
  [-19.7961, 139.9984]
];

const oonaMtCuthbert = [
  [-19.9959, 139.9845],
  [-19.9897, 139.9745],
  [-19.9895, 139.9728],
  [-19.9903, 139.9671],
  [-19.9914, 139.9643],
  [-19.9920, 139.9611],
  [-19.9919, 139.9591],
  [-19.9904, 139.9545],
  [-19.9875, 139.9491],
  [-19.9875, 139.9479],
  [-19.9881, 139.9464],
  [-19.9884, 139.9448],
  [-19.9880, 139.9376],
  [-19.9886, 139.9358],
  [-19.9902, 139.9334],
  [-19.9914, 139.9297],
  [-19.9919, 139.9292],
  [-19.9918, 139.9287],
  [-19.9912, 139.9279],
  [-19.9899, 139.9272],
  [-19.9895, 139.9268],
  [-19.9887, 139.9257],
  [-19.9887, 139.9252],
  [-19.9890, 139.9248],
  [-19.9901, 139.9243],
  [-19.9908, 139.9242],
  [-19.9921, 139.9244],
  [-19.9928, 139.9243],
  [-19.9933, 139.9238],
  [-19.9932, 139.9231],
  [-19.9926, 139.9223],
  [-19.9893, 139.9202],
  [-19.9882, 139.9193],
  [-19.9872, 139.9174],
  [-19.9853, 139.9158],
  [-19.9793, 139.9154]
];

export const kajabbi: Line = {
  name: 'Kajabbi',
  state: 'QLD',
  segments: [
    {
      segments: [cloncurryKajabbi],
      history: {
        opened: {
          date: '1917-04-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kajabbiDobbyn],
      history: {
        opened: {
          date: '1917-04-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oonaMtCuthbert],
      history: {
        opened: {
          date: '1917-04-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
