import { Line } from "../../trackTypes";

const whittinghamJunctionMountThorley = [
  [-32.5989, 151.1951],
  [-32.5978, 151.1931],
  [-32.5976, 151.1914],
  [-32.6004, 151.1710],
  [-32.6039, 151.1589],
  [-32.6055, 151.1559],
  [-32.6081, 151.1540],
  [-32.6172, 151.1509],
  [-32.6195, 151.1493],
  [-32.6211, 151.1468],
  [-32.6259, 151.1348],
  [-32.6266, 151.1322],
  [-32.6269, 151.1306],
  [-32.6285, 151.1202],
  [-32.6287, 151.1190],
  [-32.6294, 151.1168],
  [-32.6329, 151.1097],
  [-32.6354, 151.1046],
  [-32.6355, 151.1035],
  [-32.6351, 151.1023],
  [-32.6340, 151.1015],
  [-32.6329, 151.1017],
  [-32.6321, 151.1029],
  [-32.6320, 151.1042],
  [-32.6328, 151.1061],
  [-32.6331, 151.1078],
  [-32.6329, 151.1097]
];

const saxonvale = [
  [-32.6269, 151.1306],
  [-32.6276, 151.1286],
  [-32.6289, 151.1276],
  [-32.6355, 151.1253],
  [-32.6543, 151.1148],
  [-32.6579, 151.1142],
  [-32.6624, 151.1158],
  [-32.6718, 151.1240],
  [-32.6759, 151.1278],
  [-32.6771, 151.1280],
  [-32.6782, 151.1272],
  [-32.6786, 151.1257],
  [-32.6781, 151.1244],
  [-32.6774, 151.1238],
  [-32.6764, 151.1238],
  [-32.6749, 151.1245],
  [-32.6737, 151.1248],
  [-32.6729, 151.1246],
  [-32.6718, 151.1240]
];

const wambo = [
  [-32.6287, 151.1190],
  [-32.6289, 151.1158],
  [-32.6282, 151.1129],
  [-32.6267, 151.1111],
  [-32.6250, 151.1103],
  [-32.6230, 151.1101],
  [-32.6215, 151.1102],
  [-32.6200, 151.1098],
  [-32.6152, 151.1066],
  [-32.6119, 151.1053],
  [-32.6102, 151.1042],
  [-32.6066, 151.1007],
  [-32.6026, 151.0979],
  [-32.5902, 151.0902],
  [-32.5876, 151.0890],
  [-32.5862, 151.0879],
  [-32.5851, 151.0860],
  [-32.5830, 151.0784],
  [-32.5814, 151.0683],
  [-32.5813, 151.0664],
  [-32.5815, 151.0589],
  [-32.5820, 151.0561],
  [-32.5848, 151.0489],
  [-32.5852, 151.0461],
  [-32.5850, 151.0434],
  [-32.5826, 151.0361],
  [-32.5808, 151.0334],
  [-32.5799, 151.0311],
  [-32.5795, 151.0282],
  [-32.5789, 151.0231],
  [-32.5783, 151.0219],
  [-32.5772, 151.0213],
  [-32.5761, 151.0215],
  [-32.5752, 151.0226],
  [-32.5750, 151.0238],
  [-32.5754, 151.0249],
  [-32.5763, 151.0256],
  [-32.5779, 151.0261],
  [-32.5789, 151.0269],
  [-32.5795, 151.0282]
];

export const mountThorley: Line = {
  name: 'Mount Thorley',
  state: 'NSW',
  segments: [
    {
      segments: [whittinghamJunctionMountThorley],
      history: {
        opened: {
          date: '1978-03-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [saxonvale],
      history: {
        opened: {
          date: '1992-12-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wambo],
      history: {
        opened: {
          date: '1990-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
