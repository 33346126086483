import { Line } from "../../../../trackTypes";

const cityWest = [
  [-34.92167, 138.59700],
  [-34.92187, 138.59254]
];

const cbdNew = [
  [-34.92187, 138.59254],
  [-34.92202, 138.58677],
  [-34.92194, 138.58652],
  [-34.92116, 138.58484],
  [-34.92063, 138.58332],
  [-34.92000, 138.58171]
];

const deviationRd = [
  [-34.92000, 138.58171],
  [-34.92009, 138.58135],
  [-34.92028, 138.58105],
  [-34.92055, 138.58081],
  [-34.92283, 138.57976],
  [-34.92315, 138.57941],
  [-34.92320, 138.57931]
];

const hindleyStreet = [
  [-34.92298, 138.59952],
  [-34.92359, 138.58744],
  [-34.92320, 138.57931]
];

const portRoadSouth = [
  [-34.92320, 138.57931],
  [-34.91827, 138.57864]
];

const portRoad = [
  [-34.91827, 138.57864],
  [-34.91732, 138.57847]
];

const entertainmentCentre = [
  [-34.91732, 138.57847],
  [-34.90906, 138.57711],
  [-34.90869, 138.57694],
  [-34.90845, 138.57676],
  [-34.90827, 138.57657],
  [-34.90714, 138.57484]
];

const hindmarshOld = [
  [-34.90714, 138.57484],
  [-34.90179, 138.56695],
  [-34.90178, 138.56670],
  [-34.90189, 138.56653],
  [-34.90208, 138.56632],
  [-34.90222, 138.56615],
  [-34.90299, 138.56590],
  [-34.90400, 138.56573]
];

const hindmarshNewSouth = [
  [-34.92345, 138.57574],
  [-34.92342, 138.57564],
  [-34.92334, 138.57558],
  [-34.92023, 138.57539],
  [-34.92011, 138.57534],
  [-34.92002, 138.57527],
  [-34.91995, 138.57525],
  [-34.91934, 138.57520],
  [-34.91917, 138.57514],
  [-34.91865, 138.57509]
];

const hindmarshNew = [
  [-34.91865, 138.57509],
  [-34.91750, 138.57501],
  [-34.91744, 138.57495],
  [-34.91740, 138.57487],
  [-34.91738, 138.57480],
  [-34.91735, 138.57476],
  [-34.91183, 138.57381],
  [-34.90934, 138.57339],
  [-34.90924, 138.57331],
  [-34.90463, 138.56634]
];

const cawthornStreet = [
  [-34.91732, 138.57847],
  [-34.91725, 138.57837],
  [-34.91723, 138.57828],
  [-34.91734, 138.57607],
  [-34.91732, 138.57600],
  [-34.91728, 138.57595],
  [-34.91209, 138.57506],
  [-34.91200, 138.57498],
  [-34.91188, 138.57481],
  [-34.91183, 138.57472],
  [-34.91183, 138.57458],
  [-34.91189, 138.57391],
  [-34.91188, 138.57385],
  [-34.91183, 138.57381]
];

const portRoadEast = [
  [-34.92000, 138.58171],
  [-34.91900, 138.57935],
  [-34.91887, 138.57908],
  [-34.91872, 138.57889],
  [-34.91851, 138.57873],
  [-34.91827, 138.57864]
];

const georgeStreet = [
  [-34.91900, 138.57935],
  [-34.91866, 138.57859],
  [-34.91863, 138.57850],
  [-34.91862, 138.57837],
  [-34.91875, 138.57525],
  [-34.91874, 138.57519],
  [-34.91870, 138.57513],
  [-34.91865, 138.57509]
];

const findon = [
  [-34.90463, 138.56634],
  [-34.90449, 138.56600],
  [-34.90438, 138.56551],
  [-34.90405, 138.56110],
  [-34.90406, 138.56047],
  [-34.90482, 138.54513]
];

const bakewellBridge = [
  [-34.92489, 138.58785],
  [-34.92468, 138.58607],
  [-34.92456, 138.58541],
  [-34.92439, 138.58477],
  [-34.92367, 138.58175],
  [-34.92356, 138.58117],
  [-34.92345, 138.58061],
  [-34.92320, 138.57931]
];

export const west1: Line = {
  name: 'West1',
  state: 'SA',
  segments: [
    {
      segments: [cityWest],
      history: {
        opened: {
          date: '1910-03-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-08-01',
          status: 'closed'
        }, {
          date: '2007-10-14',
          status: 'open'
        }]
      }
    },
    {
      segments: [cbdNew],
      history: {
        opened: {
          date: '1910-03-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-08-01',
          status: 'closed'
        }, {
          date: '2010-03-22',
          status: 'open'
        }]
      }
    },
    {
      segments: [deviationRd],
      history: {
        opened: {
          date: '1910-03-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hindleyStreet],
      history: {
        opened: {
          date: '1880-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1910-03-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portRoadSouth],
      history: {
        opened: {
          date: '1880-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-06-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portRoad],
      history: {
        opened: {
          date: '1880-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-06-25',
          status: 'closed'
        }, {
          date: '1923-01-14',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-10-17',
          status: 'closed'
        }, {
          date: '2010-03-22',
          status: 'open'
        }]
      }
    },
    {
      segments: [entertainmentCentre],
      history: {
        opened: {
          date: '1880-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-06-25',
          status: 'closed'
        }, {
          date: '2010-03-22',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [hindmarshOld],
      history: {
        opened: {
          date: '1880-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-06-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hindmarshNewSouth],
      history: {
        opened: {
          date: '1909-06-25',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1910-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1923-01-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hindmarshNew],
      history: {
        opened: {
          date: '1909-06-25',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1910-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-10-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cawthornStreet, georgeStreet],
      history: {
        opened: {
          date: '1923-01-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-10-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portRoadEast],
      history: {
        opened: {
          date: '1923-01-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-10-17',
          status: 'closed'
        }, {
          date: '2010-03-22',
          status: 'open'
        }]
      }
    },
    {
      segments: [findon],
      history: {
        opened: {
          date: '1923-04-15',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-10-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bakewellBridge],
      history: {
        opened: {
          date: '1925-12-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    }
  ]
};
