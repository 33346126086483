import { Line } from "../../../../trackTypes";

const semaphoreJervoisBridge = [
  [-34.83818, 138.48051],
  [-34.83878, 138.48372],
  [-34.83888, 138.48387],
  [-34.83897, 138.48391],
  [-34.83906, 138.48391],
  [-34.84401, 138.48196],
  [-34.84420, 138.48194],
  [-34.84430, 138.48201],
  [-34.84436, 138.48214],
  [-34.84530, 138.48715],
  [-34.84610, 138.49134],
  [-34.84622, 138.49198],
  [-34.84672, 138.49387],
  [-34.84678, 138.49428],
  [-34.84678, 138.49490],
  [-34.84671, 138.49542],
  [-34.84661, 138.49581],
  [-34.84570, 138.49843],
  [-34.84556, 138.49897]
];

const jervoisBridgePortAdelaide = [
  [-34.84556, 138.49897],
  [-34.84438, 138.50510],
  [-34.84439, 138.50528],
  [-34.84443, 138.50540],
  [-34.84451, 138.50549]
];

const portAdelaide = [
  [-34.84451, 138.50549],
  [-34.84971, 138.50879],
  [-34.85021, 138.50904],
  [-34.85092, 138.50927],
  [-34.85168, 138.50932],
  [-34.85213, 138.50934]
];

const albertPark = [
  [-34.85213, 138.50934],
  [-34.85350, 138.50944],
  [-34.85409, 138.50957],
  [-34.85463, 138.50984],
  [-34.86927, 138.51857],
  [-34.86976, 138.51890],
  [-34.87030, 138.51954],
  [-34.87254, 138.52285]
];

const rosewater = [
  [-34.85213, 138.50934],
  [-34.85221, 138.50941],
  [-34.85226, 138.50950],
  [-34.85229, 138.50964],
  [-34.85223, 138.51067],
  [-34.85224, 138.51155],
  [-34.85234, 138.51266],
  [-34.85236, 138.51368],
  [-34.85234, 138.51448],
  [-34.85223, 138.51557],
  [-34.85201, 138.51687],
  [-34.85191, 138.51772],
  [-34.85187, 138.51841],
  [-34.85153, 138.52595]
];

const rosewaterJunction = [
  [-34.85229, 138.50964],
  [-34.85234, 138.50956],
  [-34.85240, 138.50948],
  [-34.85252, 138.50937]
];

const largsBay = [
  [-34.84530, 138.48715],
  [-34.84524, 138.48707],
  [-34.84515, 138.48699],
  [-34.84508, 138.48696],
  [-34.84497, 138.48694],
  [-34.84198, 138.48815],
  [-34.84166, 138.48823],
  [-34.83988, 138.48868],
  [-34.83981, 138.48874],
  [-34.83979, 138.48883],
  [-34.83979, 138.48893],
  [-34.83977, 138.48903],
  [-34.83972, 138.48909],
  [-34.83965, 138.48912],
  [-34.83955, 138.48912],
  [-34.83123, 138.48734],
  [-34.83104, 138.48730],
  [-34.83083, 138.48728],
  [-34.82662, 138.48948],
  [-34.82651, 138.48948],
  [-34.82642, 138.48940],
  [-34.82534, 138.48658]
];

const stKildaTramMuseum = [
  [-34.74151, 138.53357],
  [-34.74097, 138.53461],
  [-34.74076, 138.53497],
  [-34.74003, 138.53617],
  [-34.73992, 138.53629],
  [-34.73981, 138.53635],
  [-34.73970, 138.53639],
  [-34.73958, 138.53647],
  [-34.73946, 138.53664],
  [-34.73941, 138.53681],
  [-34.73934, 138.53817],
  [-34.73929, 138.53862],
  [-34.73838, 138.54432],
  [-34.73831, 138.54463],
  [-34.73815, 138.54498],
  [-34.73693, 138.54690],
  [-34.73657, 138.54740],
  [-34.73612, 138.54786],
  [-34.73577, 138.54810],
  [-34.73557, 138.54833],
  [-34.73515, 138.54904]
];

export const portAdelaideTrams: Line = {
  name: 'Port Adelaide Tram Network',
  state: 'SA',
  segments: [
    {
      segments: [semaphoreJervoisBridge],
      history: {
        opened: {
          date: '1917-04-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1935-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [jervoisBridgePortAdelaide],
      history: {
        opened: {
          date: '1915-05-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1917-04-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1935-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portAdelaide],
      history: {
        opened: {
          date: '1879-05-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1917-04-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1935-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [albertPark],
      history: {
        opened: {
          date: '1879-05-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1917-04-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1934-11-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rosewater],
      history: {
        opened: {
          date: '1917-04-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1935-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rosewaterJunction],
      history: {
        opened: {
          date: '1917-04-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1934-11-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [largsBay],
      history: {
        opened: {
          date: '1917-05-15',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1935-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stKildaTramMuseum],
      history: {
        opened: {
          date: '1974-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
