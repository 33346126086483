import { Line } from "../../trackTypes";

const irishtownJunctionTrowutta = [
  [-40.9079, 145.1452],
  [-40.9080, 145.1446],
  [-40.9082, 145.1441],
  [-40.9085, 145.1437],
  [-40.9144, 145.1424],
  [-40.9151, 145.1420],
  [-40.9221, 145.1346],
  [-40.9238, 145.1309],
  [-40.9241, 145.1297],
  [-40.9241, 145.1282],
  [-40.9244, 145.1263],
  [-40.9252, 145.1247],
  [-40.9257, 145.1234],
  [-40.9249, 145.1224],
  [-40.9244, 145.1222],
  [-40.9239, 145.1217],
  [-40.9230, 145.1189],
  [-40.9233, 145.1181],
  [-40.9238, 145.1176],
  [-40.9247, 145.1173],
  [-40.9257, 145.1159],
  [-40.9261, 145.1147],
  [-40.9266, 145.1143],
  [-40.9271, 145.1135],
  [-40.9274, 145.1120],
  [-40.9271, 145.1111],
  [-40.9261, 145.1104],
  [-40.9253, 145.1100],
  [-40.9251, 145.1094],
  [-40.9253, 145.1078],
  [-40.9260, 145.1071],
  [-40.9268, 145.1070],
  [-40.9277, 145.1073],
  [-40.9287, 145.1071],
  [-40.9318, 145.1033],
  [-40.9332, 145.1030],
  [-40.9363, 145.1020],
  [-40.9396, 145.1024],
  [-40.9406, 145.1029],
  [-40.9433, 145.1029],
  [-40.9440, 145.1027],
  [-40.9446, 145.1019],
  [-40.9452, 145.1016],
  [-40.9475, 145.1014],
  [-40.9484, 145.1006],
  [-40.9527, 145.1004],
  [-40.9542, 145.1007],
  [-40.9580, 145.0995],
  [-40.9585, 145.0989],
  [-40.9587, 145.0977],
  [-40.9595, 145.0962],
  [-40.9599, 145.0947],
  [-40.9603, 145.0941],
  [-40.9612, 145.0943],
  [-40.9616, 145.0948],
  [-40.9622, 145.0952],
  [-40.9631, 145.0953],
  [-40.9645, 145.0960],
  [-40.9654, 145.0961],
  [-40.9675, 145.0950],
  [-40.9684, 145.0950],
  [-40.9734, 145.0914],
  [-40.9742, 145.0912],
  [-40.9758, 145.0909],
  [-40.9765, 145.0906],
  [-40.9777, 145.0896],
  [-40.9787, 145.0891],
  [-40.9794, 145.0885],
  [-40.9813, 145.0865],
  [-40.9818, 145.0859],
  [-40.9829, 145.0853],
  [-40.9836, 145.0846],
  [-40.9859, 145.0804],
  [-40.9869, 145.0796],
  [-40.9888, 145.0786],
  [-40.9955, 145.0719],
  [-41.0050, 145.0617]
];

const wiltshireJunctionIrishtown = [
  [-40.8317, 145.2741],
  [-40.8418, 145.2775],
  [-40.8430, 145.2777],
  [-40.8565, 145.2746],
  [-40.8574, 145.2741],
  [-40.8582, 145.2727],
  [-40.8594, 145.2719],
  [-40.8644, 145.2707],
  [-40.8655, 145.2705],
  [-40.8661, 145.2707],
  [-40.8690, 145.2721],
  [-40.8699, 145.2719],
  [-40.8706, 145.2712],
  [-40.8714, 145.2686],
  [-40.8718, 145.2680],
  [-40.8734, 145.2661],
  [-40.8744, 145.2635],
  [-40.8746, 145.2621],
  [-40.8750, 145.2613],
  [-40.8755, 145.2608],
  [-40.8769, 145.2602],
  [-40.8782, 145.2587],
  [-40.8800, 145.2547],
  [-40.8806, 145.2535],
  [-40.8825, 145.2514],
  [-40.8827, 145.2512],
  [-40.8838, 145.2505],
  [-40.8843, 145.2496],
  [-40.8844, 145.2486],
  [-40.8840, 145.2469],
  [-40.8839, 145.2402],
  [-40.8842, 145.2369],
  [-40.8853, 145.2324],
  [-40.8856, 145.2261],
  [-40.8859, 145.2251],
  [-40.8868, 145.2234],
  [-40.8873, 145.2209],
  [-40.8876, 145.2199],
  [-40.8906, 145.2140],
  [-40.8911, 145.2134],
  [-40.8926, 145.2124],
  [-40.8933, 145.2109],
  [-40.8933, 145.2095],
  [-40.8927, 145.2081],
  [-40.8927, 145.2070],
  [-40.8919, 145.2058],
  [-40.8913, 145.2050],
  [-40.8906, 145.2022],
  [-40.8908, 145.2012],
  [-40.8912, 145.2010],
  [-40.8921, 145.2009],
  [-40.8925, 145.2005],
  [-40.8928, 145.1994],
  [-40.8921, 145.1978],
  [-40.8921, 145.1960],
  [-40.8921, 145.1949],
  [-40.8927, 145.1939],
  [-40.8938, 145.1932],
  [-40.8945, 145.1930],
  [-40.8950, 145.1928],
  [-40.8958, 145.1921],
  [-40.8971, 145.1915],
  [-40.8977, 145.1915],
  [-40.8992, 145.1917],
  [-40.9005, 145.1906],
  [-40.9008, 145.1899],
  [-40.9008, 145.1882],
  [-40.9009, 145.1877],
  [-40.9022, 145.1851],
  [-40.9025, 145.1839],
  [-40.9024, 145.1778],
  [-40.9025, 145.1762],
  [-40.9023, 145.1666],
  [-40.9025, 145.1655],
  [-40.9037, 145.1625],
  [-40.9042, 145.1619],
  [-40.9068, 145.1599],
  [-40.9073, 145.1593],
  [-40.9079, 145.1580],
  [-40.9081, 145.1571],
  [-40.9079, 145.1452]
];

const wiltshireJunctionStanleyWharf = [
  [-40.8317, 145.2741],
  [-40.8249, 145.2722],
  [-40.8197, 145.2706],
  [-40.8180, 145.2697],
  [-40.8157, 145.2683],
  [-40.8115, 145.2669],
  [-40.8105, 145.2670],
  [-40.8077, 145.2680],
  [-40.8068, 145.2679],
  [-40.8046, 145.2666],
  [-40.8040, 145.2664],
  [-40.8022, 145.2664],
  [-40.8007, 145.2659],
  [-40.7974, 145.2657],
  [-40.7962, 145.2661],
  [-40.7876, 145.2734],
  [-40.7850, 145.2745],
  [-40.7831, 145.2763],
  [-40.7789, 145.2784],
  [-40.7734, 145.2804],
  [-40.7675, 145.2851],
  [-40.7635, 145.2911],
  [-40.7630, 145.2927],
  [-40.7630, 145.2952],
  [-40.7632, 145.2958],
  [-40.7638, 145.2964],
  [-40.7655, 145.2977],
  [-40.7658, 145.2979],
  [-40.7664, 145.2980],
  [-40.7667, 145.2982],
  [-40.7674, 145.3029],
  [-40.7671, 145.3044],
  [-40.7671, 145.3052],
  [-40.7672, 145.3057],
  [-40.7674, 145.3059],
  [-40.7690, 145.3058]
];

export const stanleyTrowutta: Line = {
  name: 'Stanley - Trowutta',
  state: 'TAS',
  segments: [
    {
      segments: [irishtownJunctionTrowutta],
      history: {
        opened: {
          date: '1919-06-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-09-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wiltshireJunctionIrishtown],
      history: {
        opened: {
          date: '1919-06-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-04-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wiltshireJunctionStanleyWharf],
      history: {
        opened: {
          date: '1919-06-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-15',
          status: 'closed'
        }]
      }
    }
  ]
}
