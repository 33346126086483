import { Line } from "../../../trackTypes";

const wynyardLoop = [
  [-36.84432, 174.75462],
  [-36.84469, 174.75602],
  [-36.84474, 174.75616],
  [-36.84480, 174.75629],
  [-36.84488, 174.75660],
  [-36.84487, 174.75676],
  [-36.84478, 174.75687],
  [-36.84140, 174.75828],
  [-36.84128, 174.75828],
  [-36.84120, 174.75815],
  [-36.84066, 174.75598],
  [-36.84074, 174.75588],
  [-36.84229, 174.75529],
  [-36.84374, 174.75470],
  [-36.84397, 174.75457],
  [-36.84414, 174.75450],
  [-36.84424, 174.75453],
  [-36.84432, 174.75462]
];

const wynyardDepot = [
  [-36.84229, 174.75529],
  [-36.84235, 174.75523],
  [-36.84255, 174.75496],
  [-36.84263, 174.75489],
  [-36.84288, 174.75477]
];

const motat = [
  [-36.86647, 174.72709],
  [-36.86667, 174.72725],
  [-36.86672, 174.72736],
  [-36.86674, 174.72767],
  [-36.86677, 174.72780],
  [-36.86684, 174.72793],
  [-36.86699, 174.72804],
  [-36.86708, 174.72805],
  [-36.86720, 174.72804],
  [-36.86743, 174.72795],
  [-36.86756, 174.72793],
  [-36.86770, 174.72793],
  [-36.86779, 174.72789],
  [-36.86784, 174.72782],
  [-36.86787, 174.72772],
  [-36.86789, 174.72761],
  [-36.86796, 174.72663],
  [-36.86802, 174.72620],
  [-36.86803, 174.72602],
  [-36.86802, 174.72570]
];

const greatNorthRoadExtension = [
  [-36.86802, 174.72570],
  [-36.86784, 174.71994],
  [-36.86781, 174.71978],
  [-36.86775, 174.71964],
  [-36.86749, 174.71935]
];

const zooExtension = [
  [-36.86749, 174.71935],
  [-36.86740, 174.71925],
  [-36.86733, 174.71921],
  [-36.86726, 174.71918],
  [-36.86693, 174.71916],
  [-36.86462, 174.71922]
];

const aviationHangerExtention = [
  [-36.86462, 174.71922],
  [-36.86430, 174.71922],
  [-36.86419, 174.71919],
  [-36.86410, 174.71915],
  [-36.86401, 174.71906],
  [-36.86393, 174.71895],
  [-36.86387, 174.71889],
  [-36.86378, 174.71884],
  [-36.86225, 174.71815],
  [-36.86213, 174.71812],
  [-36.86192, 174.71810],
  [-36.86183, 174.71808],
  [-36.86177, 174.71805],
  [-36.86167, 174.71798],
  [-36.86091, 174.71728],
  [-36.86083, 174.71719],
  [-36.86064, 174.71689],
  [-36.86056, 174.71679],
  [-36.85994, 174.71625]
];

export const aucklandHeritageTrams: Line = {
  name: 'Auckland Heritage Trams',
  state: 'NZ',
  segments: [
    {
      segments: [wynyardLoop, wynyardDepot],
      history: {
        opened: {
          date: '2011-08-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [motat],
      history: {
        opened: {
          date: '1967-12-16',
          tracks: 2,
          gauge: 'dual',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [greatNorthRoadExtension],
      history: {
        opened: {
          date: '1980-12-10',
          tracks: 2,
          gauge: 'dual',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [zooExtension],
      history: {
        opened: {
          date: '1981-12-05',
          tracks: 2,
          gauge: 'dual',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [aviationHangerExtention],
      history: {
        opened: {
          date: '2008-04-27',
          tracks: 2,
          gauge: 'dual',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
}
