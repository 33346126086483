import { Line } from "../../trackTypes";

const line = [
  [-33.0128, 151.5394],
  [-33.0136, 151.5377],
  [-33.0148, 151.5368],
  [-33.0161, 151.5355],
  [-33.0175, 151.5371],
  [-33.0187, 151.5372],
  [-33.0233, 151.5350],
  [-33.0240, 151.5339],
  [-33.0243, 151.5321],
  [-33.0247, 151.5309],
  [-33.0257, 151.5298],
  [-33.0269, 151.5296],
  [-33.0303, 151.5301],
  [-33.0317, 151.5297],
  [-33.0326, 151.5287],
  [-33.0333, 151.5269],
  [-33.0340, 151.5260],
  [-33.0349, 151.5255],
  [-33.0397, 151.5247],
  [-33.0410, 151.5254],
  [-33.0419, 151.5273],
  [-33.0415, 151.5293],
  [-33.0407, 151.5308],
  [-33.0406, 151.5321],
  [-33.0410, 151.5337],
  [-33.0410, 151.5353],
  [-33.0396, 151.5378],
  [-33.0391, 151.5394],
  [-33.0392, 151.5408],
  [-33.0409, 151.5473],
  [-33.0414, 151.5481],
  [-33.0435, 151.5503],
  [-33.0442, 151.5524],
  [-33.0435, 151.5567],
  [-33.0437, 151.5586],
  [-33.0446, 151.5598],
  [-33.0465, 151.5602],
  [-33.0523, 151.5607],
  [-33.0537, 151.5616],
  [-33.0546, 151.5633],
  [-33.0559, 151.5645],
  [-33.0582, 151.5660],
  [-33.0590, 151.5679],
  [-33.0603, 151.5711],
  [-33.0634, 151.5752],
  [-33.0639, 151.5764],
  [-33.0644, 151.5804]
];

export const wangiPowerStation: Line = {
  name: 'Wangi Power Station',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1954-05-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-12-19',
          status: 'closed'
        }]
      }
    }
  ]
};
