import { Line } from "../../trackTypes";

const parkesNorthWestConnection = [
  [-33.1139, 148.1208],
  [-33.1163, 148.1208],
  [-33.1178, 148.1202],
  [-33.1191, 148.1190],
  [-33.1322, 148.1004],
  [-33.1333, 148.0993],
  [-33.1350, 148.0984],
  [-33.1376, 148.0979],
  [-33.1394, 148.0974],
  [-33.1410, 148.0966],
  [-33.1424, 148.0951],
  [-33.1432, 148.0934],
  [-33.1436, 148.0910]
];

const parkesJunction = [
  [-33.1440, 148.1030],
  [-33.1430, 148.1008],
  [-33.1418, 148.0993],
  [-33.1403, 148.0983],
  [-33.1392, 148.0980],
  [-33.1376, 148.0979]
];

const illaboStockingbingal = [
  [-34.7894, 147.7995],
  [-34.7855, 147.8029],
  [-34.7835, 147.8046],
  [-34.7799, 147.8062],
  [-34.7653, 147.8091],
  [-34.7430, 147.8131],
  [-34.7354, 147.8144],
  [-34.7210, 147.8159],
  [-34.7187, 147.8162],
  [-34.7166, 147.8168],
  [-34.7066, 147.8226],
  [-34.7050, 147.8235],
  [-34.7038, 147.8250],
  [-34.6971, 147.8324],
  [-34.6952, 147.8340],
  [-34.6927, 147.8354],
  [-34.6797, 147.8385],
  [-34.6781, 147.8386],
  [-34.6607, 147.8393],
  [-34.6585, 147.8390],
  [-34.6569, 147.8382],
  [-34.6538, 147.8372],
  [-34.6522, 147.8369],
  [-34.6507, 147.8370],
  [-34.6440, 147.8381],
  [-34.6412, 147.8380],
  [-34.6176, 147.8320],
  [-34.6159, 147.8319],
  [-34.6140, 147.8322],
  [-34.6120, 147.8326],
  [-34.6092, 147.8339],
  [-34.6058, 147.8370],
  [-34.6037, 147.8402],
  [-34.6027, 147.8428],
  [-34.5993, 147.8524],
  [-34.5971, 147.8571],
  [-34.5955, 147.8587],
  [-34.5932, 147.8604],
  [-34.5908, 147.8612],
  [-34.5582, 147.8668],
  [-34.5560, 147.8667],
  [-34.5526, 147.8666],
  [-34.5501, 147.8668],
  [-34.5388, 147.8692],
  [-34.5375, 147.8698],
  [-34.5336, 147.8718],
  [-34.5322, 147.8724],
  [-34.5193, 147.8747],
  [-34.5158, 147.8748],
  [-34.5126, 147.8731],
  [-34.5070, 147.8677],
  [-34.5030, 147.8660],
  [-34.5004, 147.8659]
];

export const inlandRailNsw: Line = {
  name: 'Inland Rail NSW',
  state: 'NSW',
  segments: [
    {
      segments: [parkesNorthWestConnection, parkesJunction],
      history: {
        opened: {
          date: '2020-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [illaboStockingbingal],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
