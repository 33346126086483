import { Line } from "../../trackTypes";

const goomallingWonganHills = [
  [-31.2957, 116.8347],
  [-31.2951, 116.8371],
  [-31.2928, 116.8396],
  [-31.2914, 116.8400],
  [-31.2902, 116.8396],
  [-31.2881, 116.8384],
  [-31.2861, 116.8383],
  [-31.2846, 116.8390],
  [-31.2832, 116.8390],
  [-31.2822, 116.8385],
  [-31.2810, 116.8371],
  [-31.2798, 116.8363],
  [-31.2757, 116.8352],
  [-31.2749, 116.8352],
  [-31.2731, 116.8359],
  [-31.2716, 116.8362],
  [-31.2686, 116.8360],
  [-31.2674, 116.8354],
  [-31.2639, 116.8320],
  [-31.2626, 116.8312],
  [-31.2576, 116.8293],
  [-31.2467, 116.8222],
  [-31.2458, 116.8220],
  [-31.2443, 116.8222],
  [-31.2433, 116.8219],
  [-31.2425, 116.8212],
  [-31.2409, 116.8180],
  [-31.2407, 116.8166],
  [-31.2409, 116.8152],
  [-31.2407, 116.8139],
  [-31.2398, 116.8125],
  [-31.2379, 116.8116],
  [-31.2359, 116.8116],
  [-31.2335, 116.8124],
  [-31.2317, 116.8126],
  [-31.2292, 116.8139],
  [-31.2285, 116.8148],
  [-31.2269, 116.8182],
  [-31.2257, 116.8193],
  [-31.2244, 116.8195],
  [-31.2213, 116.8186],
  [-31.2163, 116.8159],
  [-31.2155, 116.8157],
  [-31.2145, 116.8159],
  [-31.2094, 116.8184],
  [-31.2086, 116.8186],
  [-31.2077, 116.8184],
  [-31.2016, 116.8154],
  [-31.2002, 116.8150],
  [-31.1935, 116.8139],
  [-31.1925, 116.8140],
  [-31.1914, 116.8143],
  [-31.1904, 116.8142],
  [-31.1893, 116.8134],
  [-31.1870, 116.8101],
  [-31.1866, 116.8091],
  [-31.1863, 116.8071],
  [-31.1858, 116.8060],
  [-31.1819, 116.8017],
  [-31.1808, 116.8012],
  [-31.1789, 116.8011],
  [-31.1780, 116.8008],
  [-31.1750, 116.7990],
  [-31.1734, 116.7983],
  [-31.1719, 116.7970],
  [-31.1706, 116.7965],
  [-31.1692, 116.7968],
  [-31.1670, 116.7987],
  [-31.1654, 116.8010],
  [-31.1639, 116.8054],
  [-31.1625, 116.8070],
  [-31.1610, 116.8073],
  [-31.1576, 116.8063],
  [-31.1566, 116.8058],
  [-31.1551, 116.8045],
  [-31.1541, 116.8039],
  [-31.1481, 116.8020],
  [-31.1433, 116.8011],
  [-31.1423, 116.8011],
  [-31.1357, 116.8030],
  [-31.1323, 116.8048],
  [-31.1312, 116.8049],
  [-31.1304, 116.8047],
  [-31.1268, 116.8021],
  [-31.1251, 116.8015],
  [-31.1241, 116.8007],
  [-31.1232, 116.7989],
  [-31.1226, 116.7981],
  [-31.1173, 116.7939],
  [-31.1086, 116.7910],
  [-31.1073, 116.7909],
  [-31.1035, 116.7917],
  [-31.1027, 116.7916],
  [-31.1015, 116.7911],
  [-31.1008, 116.7909],
  [-31.0997, 116.7908],
  [-31.0984, 116.7903],
  [-31.0969, 116.7883],
  [-31.0958, 116.7876],
  [-31.0947, 116.7875],
  [-31.0891, 116.7879],
  [-31.0874, 116.7899],
  [-31.0801, 116.7879],
  [-31.0788, 116.7872],
  [-31.0754, 116.7829],
  [-31.0748, 116.7816],
  [-31.0742, 116.7791],
  [-31.0736, 116.7780],
  [-31.0723, 116.7772],
  [-31.0690, 116.7765],
  [-31.0679, 116.7758],
  [-31.0663, 116.7735],
  [-31.0652, 116.7728],
  [-31.0643, 116.7727],
  [-31.0599, 116.7736],
  [-31.0586, 116.7742],
  [-31.0568, 116.7757],
  [-31.0556, 116.7760],
  [-31.0546, 116.7759],
  [-31.0506, 116.7737],
  [-31.0486, 116.7721],
  [-31.0475, 116.7717],
  [-31.0464, 116.7718],
  [-31.0444, 116.7728],
  [-31.0434, 116.7730],
  [-31.0419, 116.7727],
  [-31.0403, 116.7730],
  [-31.0390, 116.7727],
  [-31.0383, 116.7721],
  [-31.0371, 116.7702],
  [-31.0362, 116.7680],
  [-31.0356, 116.7672],
  [-31.0348, 116.7666],
  [-31.0338, 116.7663],
  [-31.0329, 116.7656],
  [-31.0324, 116.7648],
  [-31.0317, 116.7626],
  [-31.0308, 116.7613],
  [-31.0296, 116.7609],
  [-31.0291, 116.7608],
  [-31.0246, 116.7614],
  [-31.0226, 116.7614],
  [-31.0209, 116.7608],
  [-31.0187, 116.7590],
  [-31.0180, 116.7579],
  [-31.0177, 116.7569],
  [-31.0177, 116.7560],
  [-31.0180, 116.7542],
  [-31.0179, 116.7529],
  [-31.0174, 116.7518],
  [-31.0161, 116.7509],
  [-31.0150, 116.7507],
  [-31.0138, 116.7512],
  [-31.0127, 116.7521],
  [-31.0111, 116.7527],
  [-31.0099, 116.7526],
  [-31.0087, 116.7519],
  [-31.0024, 116.7450],
  [-30.9916, 116.7381],
  [-30.9905, 116.7379],
  [-30.9893, 116.7384],
  [-30.9878, 116.7398],
  [-30.9853, 116.7416],
  [-30.9837, 116.7422],
  [-30.9828, 116.7429],
  [-30.9821, 116.7439],
  [-30.9814, 116.7444],
  [-30.9805, 116.7448],
  [-30.9794, 116.7458],
  [-30.9790, 116.7471],
  [-30.9789, 116.7484],
  [-30.9780, 116.7500],
  [-30.9767, 116.7509],
  [-30.9757, 116.7524],
  [-30.9752, 116.7541],
  [-30.9739, 116.7554],
  [-30.9727, 116.7557],
  [-30.9714, 116.7555],
  [-30.9701, 116.7558],
  [-30.9694, 116.7565],
  [-30.9687, 116.7574],
  [-30.9674, 116.7581],
  [-30.9660, 116.7583],
  [-30.9604, 116.7566],
  [-30.9589, 116.7557],
  [-30.9582, 116.7555],
  [-30.9570, 116.7554],
  [-30.9556, 116.7546],
  [-30.9519, 116.7495],
  [-30.9515, 116.7484],
  [-30.9512, 116.7462],
  [-30.9503, 116.7447],
  [-30.9474, 116.7426],
  [-30.9461, 116.7415],
  [-30.9414, 116.7397],
  [-30.9405, 116.7396],
  [-30.9391, 116.7398],
  [-30.9379, 116.7396],
  [-30.9366, 116.7387],
  [-30.9355, 116.7384],
  [-30.9319, 116.7390],
  [-30.9302, 116.7388],
  [-30.9185, 116.7327],
  [-30.9110, 116.7269],
  [-30.9090, 116.7249],
  [-30.9078, 116.7243],
  [-30.9069, 116.7240],
  [-30.9039, 116.7235],
  [-30.9027, 116.7230],
  [-30.9012, 116.7216],
  [-30.8946, 116.7171],
  [-30.8925, 116.7156]
];

const wonganHillsMcLevie = [
  [-30.8925, 116.7156],
  [-30.8896, 116.7138],
  [-30.8881, 116.7134],
  [-30.8857, 116.7137],
  [-30.8809, 116.7155],
  [-30.8765, 116.7193],
  [-30.8745, 116.7218],
  [-30.8730, 116.7226],
  [-30.8713, 116.7222],
  [-30.8703, 116.7209],
  [-30.8695, 116.7183],
  [-30.8636, 116.7089],
  [-30.8632, 116.7077],
  [-30.8634, 116.7062],
  [-30.8644, 116.7041],
  [-30.8645, 116.7026],
  [-30.8641, 116.7011],
  [-30.8573, 116.6925],
  [-30.8537, 116.6842],
  [-30.8532, 116.6821],
  [-30.8534, 116.6777],
  [-30.8527, 116.6755],
  [-30.8506, 116.6736],
  [-30.8487, 116.6731],
  [-30.8432, 116.6739],
  [-30.8311, 116.6748],
  [-30.8287, 116.6761],
  [-30.8018, 116.7025],
  [-30.7992, 116.7037],
  [-30.7946, 116.7044],
  [-30.7930, 116.7054],
  [-30.7501, 116.7578],
  [-30.7478, 116.7592],
  [-30.7390, 116.7608],
  [-30.7373, 116.7616],
  [-30.7360, 116.7630],
  [-30.7322, 116.7703],
  [-30.7304, 116.7720],
  [-30.7286, 116.7727],
  [-30.7003, 116.7739],
  [-30.6983, 116.7731],
  [-30.6961, 116.7717],
  [-30.6948, 116.7712],
  [-30.6933, 116.7714],
  [-30.6882, 116.7741],
  [-30.6857, 116.7742],
  [-30.6840, 116.7735],
  [-30.6820, 116.7732],
  [-30.6581, 116.7746],
  [-30.6428, 116.7705],
  [-30.6405, 116.7705],
  [-30.6046, 116.7767],
  [-30.6031, 116.7765],
  [-30.6019, 116.7757],
  [-30.5951, 116.7667],
  [-30.5924, 116.7638],
  [-30.5908, 116.7629],
  [-30.5843, 116.7614],
  [-30.5831, 116.7604],
  [-30.5825, 116.7587],
  [-30.5825, 116.7558],
  [-30.5820, 116.7545],
  [-30.5811, 116.7536],
  [-30.5761, 116.7509],
  [-30.5742, 116.7502],
  [-30.5716, 116.7497],
  [-30.5701, 116.7490],
  [-30.5647, 116.7447],
  [-30.5409, 116.7319],
  [-30.5395, 116.7307],
  [-30.5330, 116.7222],
  [-30.5313, 116.7213],
  [-30.5295, 116.7214],
  [-30.5262, 116.7232],
  [-30.5230, 116.7231],
  [-30.5202, 116.7217],
  [-30.5190, 116.7206],
  [-30.5150, 116.7154],
  [-30.5081, 116.7102],
  [-30.5058, 116.7095],
  [-30.5044, 116.7088],
  [-30.4551, 116.6711],
  [-30.4532, 116.6702],
  [-30.4194, 116.6605],
  [-30.4171, 116.6607],
  [-30.4074, 116.6647],
  [-30.4059, 116.6657],
  [-30.4031, 116.6683],
  [-30.4019, 116.6688],
  [-30.4009, 116.6687],
  [-30.3995, 116.6681],
  [-30.3980, 116.6678],
  [-30.3961, 116.6680],
  [-30.3932, 116.6676],
  [-30.3921, 116.6678],
  [-30.3879, 116.6698],
  [-30.3864, 116.6701],
  [-30.3146, 116.6701],
  [-30.3130, 116.6705],
  [-30.3104, 116.6717],
  [-30.3077, 116.6714],
  [-30.3051, 116.6700],
  [-30.3035, 116.6696],
  [-30.2952, 116.6696],
  [-30.2929, 116.6688],
  [-30.2873, 116.6642],
  [-30.2854, 116.6635],
  [-30.2760, 116.6633],
  [-30.2745, 116.6637],
  [-30.2730, 116.6646],
  [-30.2691, 116.6687],
  [-30.2665, 116.6697],
  [-30.2511, 116.6696]
];

const mcLeviePerenjori = [
  [-30.2511, 116.6696],
  [-30.2194, 116.6695],
  [-30.2178, 116.6688],
  [-30.2149, 116.6657],
  [-30.2144, 116.6645],
  [-30.2141, 116.6629],
  [-30.2132, 116.6616],
  [-30.2117, 116.6609],
  [-30.2050, 116.6605],
  [-30.1574, 116.6511],
  [-30.1562, 116.6512],
  [-30.1536, 116.6518],
  [-30.1276, 116.6494],
  [-30.1247, 116.6478],
  [-30.1020, 116.6255],
  [-30.1011, 116.6239],
  [-30.1005, 116.6220],
  [-30.0990, 116.6204],
  [-30.0771, 116.6108],
  [-30.0755, 116.6104],
  [-30.0693, 116.6103],
  [-30.0674, 116.6089],
  [-30.0663, 116.6067],
  [-30.0647, 116.6053],
  [-30.0347, 116.5922],
  [-30.0329, 116.5918],
  [-30.0183, 116.5914],
  [-30.0162, 116.5908],
  [-29.9107, 116.5213],
  [-29.9088, 116.5210],
  [-29.9061, 116.5219],
  [-29.9045, 116.5218],
  [-29.9032, 116.5211],
  [-29.8915, 116.5090],
  [-29.8269, 116.4680],
  [-29.8254, 116.4675],
  [-29.8208, 116.4664],
  [-29.8184, 116.4647],
  [-29.8137, 116.4597],
  [-29.7939, 116.4472],
  [-29.7921, 116.4467],
  [-29.7602, 116.4466],
  [-29.7584, 116.4460],
  [-29.7540, 116.4429],
  [-29.7521, 116.4423],
  [-29.7439, 116.4421],
  [-29.7415, 116.4412],
  [-29.6961, 116.4016],
  [-29.6941, 116.4007],
  [-29.6861, 116.3992],
  [-29.6844, 116.3984],
  [-29.6760, 116.3926],
  [-29.6744, 116.3904],
  [-29.6732, 116.3878],
  [-29.6724, 116.3867],
  [-29.6578, 116.3739],
  [-29.6494, 116.3616],
  [-29.6097, 116.3270],
  [-29.5982, 116.3218],
  [-29.5897, 116.3210],
  [-29.5883, 116.3204],
  [-29.5860, 116.3188],
  [-29.5844, 116.3182],
  [-29.5506, 116.3159],
  [-29.5485, 116.3152],
  [-29.5403, 116.3091],
  [-29.5377, 116.3083],
  [-29.4633, 116.3082],
  [-29.4609, 116.3070],
  [-29.4557, 116.3014]
];

const perenjoriMullewa = [
  [-29.4557, 116.3014],
  [-29.4361, 116.2798],
  [-29.4204, 116.2515],
  [-29.4080, 116.2385],
  [-29.4067, 116.2377],
  [-29.3931, 116.2334],
  [-29.3919, 116.2328],
  [-29.3517, 116.2049],
  [-29.3417, 116.1934],
  [-29.3408, 116.1916],
  [-29.3403, 116.1892],
  [-29.3402, 116.1848],
  [-29.3397, 116.1823],
  [-29.3280, 116.1596],
  [-29.3259, 116.1508],
  [-29.3245, 116.1484],
  [-29.3125, 116.1387],
  [-29.2813, 116.1021],
  [-29.2803, 116.1001],
  [-29.2780, 116.0927],
  [-29.2724, 116.0851],
  [-29.2484, 116.0369],
  [-29.2465, 116.0316],
  [-29.2423, 116.0233],
  [-29.2388, 116.0177],
  [-29.2358, 116.0121],
  [-29.2345, 116.0106],
  [-29.2327, 116.0100],
  [-29.1525, 116.0097],
  [-29.1503, 116.0091],
  [-29.0859, 115.9773],
  [-29.0604, 115.9727],
  [-29.0581, 115.9727],
  [-29.0366, 115.9777],
  [-29.0347, 115.9786],
  [-29.0321, 115.9804],
  [-29.0305, 115.9809],
  [-29.0283, 115.9811],
  [-29.0266, 115.9807],
  [-29.0230, 115.9787],
  [-28.9632, 115.9172],
  [-28.9613, 115.9159],
  [-28.9489, 115.9110],
  [-28.9259, 115.8954],
  [-28.9166, 115.8855],
  [-28.9153, 115.8832],
  [-28.9139, 115.8778],
  [-28.9127, 115.8758],
  [-28.9015, 115.8660],
  [-28.8929, 115.8555],
  [-28.8909, 115.8538],
  [-28.8842, 115.8499],
  [-28.8831, 115.8487],
  [-28.8821, 115.8468],
  [-28.8806, 115.8452],
  [-28.8770, 115.8435],
  [-28.8757, 115.8424],
  [-28.8715, 115.8381],
  [-28.8662, 115.8292],
  [-28.8650, 115.8281],
  [-28.8631, 115.8270],
  [-28.8621, 115.8258],
  [-28.8619, 115.8245],
  [-28.8620, 115.8232],
  [-28.8617, 115.8221],
  [-28.8609, 115.8199],
  [-28.8566, 115.8138],
  [-28.8560, 115.8126],
  [-28.8558, 115.8115],
  [-28.8548, 115.8101],
  [-28.8516, 115.8082],
  [-28.8506, 115.8070],
  [-28.8504, 115.8061],
  [-28.8503, 115.8036],
  [-28.8496, 115.8020],
  [-28.8445, 115.7973],
  [-28.8439, 115.7958],
  [-28.8438, 115.7941],
  [-28.8432, 115.7927],
  [-28.8416, 115.7912],
  [-28.8388, 115.7905],
  [-28.8367, 115.7904],
  [-28.8342, 115.7890],
  [-28.8327, 115.7869],
  [-28.8294, 115.7774],
  [-28.8283, 115.7761],
  [-28.8120, 115.7704],
  [-28.8103, 115.7705],
  [-28.8077, 115.7708],
  [-28.8049, 115.7693],
  [-28.8033, 115.7667],
  [-28.8029, 115.7647],
  [-28.8030, 115.7621],
  [-28.8018, 115.7585],
  [-28.8007, 115.7572],
  [-28.7991, 115.7566],
  [-28.7980, 115.7556],
  [-28.7945, 115.7507],
  [-28.7898, 115.7482],
  [-28.7881, 115.7481],
  [-28.7810, 115.7502],
  [-28.7787, 115.7500],
  [-28.7693, 115.7457],
  [-28.7673, 115.7455],
  [-28.7616, 115.7459],
  [-28.7557, 115.7438],
  [-28.7369, 115.7282],
  [-28.7356, 115.7265],
  [-28.7326, 115.7210],
  [-28.7314, 115.7194],
  [-28.7224, 115.7125],
  [-28.6491, 115.6557],
  [-28.6371, 115.6479],
  [-28.6355, 115.6463],
  [-28.6309, 115.6378],
  [-28.6134, 115.6210],
  [-28.6108, 115.6162],
  [-28.6092, 115.6142],
  [-28.6037, 115.6112],
  [-28.5944, 115.6101],
  [-28.5926, 115.6093],
  [-28.5869, 115.6046],
  [-28.5691, 115.5804],
  [-28.5643, 115.5772],
  [-28.5619, 115.5731],
  [-28.5611, 115.5685],
  [-28.5603, 115.5668],
  [-28.5570, 115.5632],
  [-28.5547, 115.5592],
  [-28.5470, 115.5530],
  [-28.5460, 115.5515],
  [-28.5449, 115.5479],
  [-28.5447, 115.5390],
  [-28.5402, 115.5176],
  [-28.5401, 115.5168]
];

export const goomallingMullewa: Line = {
  name: 'Goomalling - Mullewa',
  state: 'WA',
  segments: [
    {
      segments: [goomallingWonganHills],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wonganHillsMcLevie],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mcLeviePerenjori],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [perenjoriMullewa],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
};
