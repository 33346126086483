import { Line } from '../../trackTypes';

const line = [
  [-34.30086, 142.18619],
  [-34.30124, 142.18620],
  [-34.30137, 142.18630],
  [-34.30145, 142.18646],
  [-34.30150, 142.18667],
  [-34.30164, 142.18775],
  [-34.30163, 142.18811],
  [-34.30144, 142.18954],
  [-34.30141, 142.18993],
  [-34.30149, 142.19386],
  [-34.30145, 142.19402],
  [-34.30137, 142.19425],
  [-34.30072, 142.19510],
  [-34.30060, 142.19531],
  [-34.30050, 142.19560],
  [-34.30009, 142.19736],
  [-34.29865, 142.20184],
  [-34.29864, 142.20211],
  [-34.29869, 142.20238],
  [-34.29873, 142.20258],
  [-34.29915, 142.20314],
  [-34.29919, 142.20331],
  [-34.29922, 142.20353],
  [-34.29864, 142.20761],
  [-34.29861, 142.20809],
  [-34.29861, 142.20853],
  [-34.29924, 142.21505],
  [-34.29925, 142.21573],
  [-34.29896, 142.22170],
  [-34.29890, 142.22200],
  [-34.29876, 142.22224],
  [-34.29860, 142.22238],
  [-34.29844, 142.22243],
  [-34.29679, 142.22274],
  [-34.29663, 142.22286],
  [-34.29653, 142.22308],
  [-34.29643, 142.22596],
  [-34.29647, 142.22702],
  [-34.29654, 142.22782],
  [-34.29692, 142.23035],
  [-34.29688, 142.23056],
  [-34.29682, 142.23070],
  [-34.29667, 142.23086],
  [-34.29471, 142.23247],
  [-34.29459, 142.23264],
  [-34.29452, 142.23282],
  [-34.29447, 142.23306],
  [-34.29454, 142.23677],
  [-34.29449, 142.23692],
  [-34.29441, 142.23705],
  [-34.29381, 142.23772]
];

export const redcliffsTramway: Line = {
  name: 'Redcliffs Tramway',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1924-07-15',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1954-01-01'
        }]
      }
    }
  ]
};
