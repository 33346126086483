import { Line } from "../../../trackTypes";

const line = [
  [-23.5513, 145.2793],
  [-23.5506, 145.2781],
  [-23.5497, 145.2775],
  [-23.5366, 145.2749],
  [-23.4607, 145.2504],
  [-23.3942, 145.2287],
  [-23.3919, 145.2286],
  [-23.1407, 145.2534],
  [-23.1232, 145.2589],
  [-23.1175, 145.2588],
  [-23.1019, 145.2540],
  [-23.0973, 145.2539],
  [-23.0811, 145.2575],
  [-23.0527, 145.2569],
  [-23.0458, 145.2552],
  [-23.0271, 145.2477],
  [-23.0215, 145.2463],
  [-22.9848, 145.2406],
  [-22.9823, 145.2413],
  [-22.9766, 145.2449],
  [-22.9728, 145.2487]
];

export const aramac: Line = {
  name: 'Aramac',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1913-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1975-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
