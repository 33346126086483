import { Line } from "../../trackTypes";

const caulfieldGlenhuntly = [
  [-37.8794, 145.0453],
  [-37.8808, 145.0461],
  [-37.8820, 145.0463],
  [-37.8830, 145.0461],
  [-37.8840, 145.0456],
  [-37.8863, 145.0433],
  [-37.8868, 145.0429],
  [-37.8873, 145.0427],
  [-37.8901, 145.0421]
];

const glenHuntleyMoorabin = [
  [-37.8901, 145.0421],
  [-37.9261, 145.0353],
  [-37.9268, 145.0353],
  [-37.9276, 145.0355],
  [-37.9304, 145.0368],
  [-37.9313, 145.0370],
  [-37.9323, 145.0371],
  [-37.9348, 145.0366]
];

const moorabinMordialloc = [
  [-37.9348, 145.0366],
  [-37.9357, 145.0364],
  [-37.9378, 145.0364],
  [-37.9394, 145.0366],
  [-37.9410, 145.0371],
  [-37.9419, 145.0375],
  [-37.9427, 145.0379],
  [-37.9434, 145.0383],
  [-37.9524, 145.0446],
  [-37.9688, 145.0561],
  [-37.9841, 145.0667],
  [-37.9859, 145.0682],
  [-37.9878, 145.0702],
  [-37.9965, 145.0803],
  [-37.9978, 145.0815],
  [-37.9987, 145.0822],
  [-38.0065, 145.0876]
];

const mordiallocFrankston = [
  [-38.0065, 145.0876],
  [-38.0150, 145.0932],
  [-38.0154, 145.0935],
  [-38.0163, 145.0943],
  [-38.0172, 145.0951],
  [-38.0178, 145.0956],
  [-38.0236, 145.0999],
  [-38.0240, 145.1002],
  [-38.0463, 145.1133],
  [-38.0551, 145.1175],
  [-38.0559, 145.1178],
  [-38.0600, 145.1192],
  [-38.0611, 145.1194],
  [-38.0676, 145.1204],
  [-38.0685, 145.1206],
  [-38.0702, 145.1211],
  [-38.0746, 145.1223],
  [-38.0803, 145.1236],
  [-38.0818, 145.1241],
  [-38.0889, 145.1276],
  [-38.0901, 145.1279],
  [-38.0916, 145.1281],
  [-38.1060, 145.1282],
  [-38.1072, 145.1284],
  [-38.1081, 145.1287],
  [-38.1093, 145.1294],
  [-38.1160, 145.1351],
  [-38.1169, 145.1356],
  [-38.1181, 145.1360],
  [-38.1194, 145.1361],
  [-38.1207, 145.1358],
  [-38.1430, 145.1260]
];

export const frankston: Line = {
  name: 'Frankston',
  state: 'VIC',
  segments: [
    {
      segments: [caulfieldGlenhuntly],
      history: {
        opened: {
          date: '1881-12-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-12-09',
          tracks: 2
        }, {
          date: '1987-07-03',
          tracks: 3
        }, {
          date: '1922-03-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [glenHuntleyMoorabin],
      history: {
        opened: {
          date: '1881-12-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-12-09',
          tracks: 2
        }, {
          date: '1987-07-03',
          tracks: 3
        }, {
          date: '1922-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [moorabinMordialloc],
      history: {
        opened: {
          date: '1881-12-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-12-09',
          tracks: 2
        }, {
          date: '1922-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [mordiallocFrankston],
      history: {
        opened: {
          date: '1882-08-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-12-01',
          tracks: 2
        }, {
          date: '1922-08-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
