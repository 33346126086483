import { Line } from "../../../../trackTypes";

const elizabethStreetBrunswick = [
  [-37.81786, 144.96480],
  [-37.81242, 144.96232],
  [-37.81050, 144.96144],
  [-37.80644, 144.95958],
  [-37.80610, 144.95942],
  [-37.80165, 144.95738],
  [-37.80140, 144.95735],
  [-37.78456, 144.95941]
];

const brunswickExtension = [
  [-37.78456, 144.95941],
  [-37.77814, 144.96022],
  [-37.77722, 144.96041],
  [-37.77703, 144.96046],
  [-37.75568, 144.96408]
];

const brunswickCoburg = [
  [-37.75568, 144.96408],
  [-37.73838, 144.96712],
  [-37.73809, 144.96710],
  [-37.73407, 144.96539]
];

const coburgNorthCoburg = [
  [-37.73407, 144.96539],
  [-37.73044, 144.96383],
  [-37.73016, 144.96379],
  [-37.72757, 144.96367]
];

const holmesStNorthJunction = [
  [-37.75653, 144.97491],
  [-37.75661, 144.97481],
  [-37.75663, 144.97462]
];

const connector = [
  [-37.75545, 144.96412],
  [-37.75663, 144.97462],
  [-37.75671, 144.97480],
  [-37.75682, 144.97484]
];

const morelandRoad = [
  [-37.75545, 144.96412],
  [-37.75524, 144.96246]
];

const brunswickDepot = [
  [-37.75524, 144.96246],
  [-37.75524, 144.96233],
  [-37.75530, 144.96220],
  [-37.75540, 144.96212],
  [-37.75704, 144.96182],
  [-37.75719, 144.96187],
  [-37.75727, 144.96201],
  [-37.75741, 144.96325]
];

const elizabethStJunction = [
  [-37.81050, 144.96144],
  [-37.81044, 144.96135],
  [-37.81040, 144.96126],
  [-37.81038, 144.96113]
];

export const north4: Line = {
  name: 'North4',
  state: 'VIC',
  segments: [
    {
      segments: [elizabethStreetBrunswick],
      history: {
        opened: {
          date: '1887-10-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1935-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [brunswickExtension],
      history: {
        opened: {
          date: '1887-10-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1936-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [brunswickCoburg],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1916-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [coburgNorthCoburg],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [connector, holmesStNorthJunction],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [morelandRoad],
      history: {
        opened: {
          date: '1936-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [brunswickDepot],
      history: {
        opened: {
          date: '1957-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [elizabethStJunction],
      history: {
        opened: {
          date: '1951-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '2012-01-01',
          status: 'closed'
        }]
      }
    },
  ]
};
