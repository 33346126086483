import { Line } from "../../trackTypes";

const drumsiteSouthPointSettlement = [
  [-10.4314, 105.6724],
  [-10.4320, 105.6711],
  [-10.4331, 105.6703],
  [-10.4411, 105.6680],
  [-10.4432, 105.6669],
  [-10.4467, 105.6643],
  [-10.4541, 105.6566],
  [-10.4566, 105.6528],
  [-10.4590, 105.6502],
  [-10.4619, 105.6446],
  [-10.4625, 105.6438],
  [-10.4653, 105.6417],
  [-10.4660, 105.6408],
  [-10.4686, 105.6366],
  [-10.4709, 105.6350],
  [-10.4834, 105.6336],
  [-10.4848, 105.6344],
  [-10.4856, 105.6364],
  [-10.4852, 105.6379],
  [-10.4852, 105.6391],
  [-10.4862, 105.6417],
  [-10.4871, 105.6427],
  [-10.4922, 105.6462],
  [-10.4955, 105.6489],
  [-10.5019, 105.6520],
  [-10.5041, 105.6526],
  [-10.5052, 105.6523],
  [-10.5066, 105.6502],
  [-10.5074, 105.6496],
  [-10.5083, 105.6495],
  [-10.5102, 105.6497],
  [-10.5129, 105.6493],
  [-10.5157, 105.6477],
  [-10.5191, 105.6434],
  [-10.5199, 105.6429],
  [-10.5206, 105.6428],
  [-10.5213, 105.6430],
  [-10.5227, 105.6430],
  [-10.5231, 105.6428],
  [-10.5243, 105.6420],
  [-10.5262, 105.6415],
  [-10.5398, 105.6427],
  [-10.5465, 105.6436],
  [-10.5515, 105.6435],
  [-10.5607, 105.6464],
  [-10.5617, 105.6473],
  [-10.5626, 105.6491],
  [-10.5625, 105.6584]
];

const drumsitePhosphateHill = [
  [-10.4314, 105.6724],
  [-10.4306, 105.6738],
  [-10.4300, 105.6744],
  [-10.4297, 105.6747],
  [-10.4287, 105.6760],
  [-10.4282, 105.6769],
  [-10.4277, 105.6774],
  [-10.4272, 105.6777],
  [-10.4269, 105.6782],
  [-10.4269, 105.6782],
  [-10.4263, 105.6804],
  [-10.4253, 105.6821],
  [-10.4246, 105.6840]
];

export const christmasIsland: Line = {
  name: 'Christmas Island Phosphate Co. Railway',
  state: 'ACT',
  segments: [
    {
      segments: [drumsiteSouthPointSettlement],
      history: {
        opened: {
          date: '1920-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [drumsitePhosphateHill],
      history: {
        opened: {
          date: '1961-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
