import { Line } from "../../../../trackTypes";

const pointOrmond = [
  [-37.88177, 144.97855],
  [-37.88377, 144.99715]
];

const brightonRoad = [
  [-37.88377, 144.99715],
  [-37.88433, 145.00127]
];

const elsternwickGlenhuntly = [
  [-37.88433, 145.00127],
  [-37.88600, 145.01480],
  [-37.88691, 145.02214],
  [-37.88971, 145.04460]
];

const elsternwickCaulfield = [
  [-37.88600, 145.01480],
  [-37.88597, 145.01494],
  [-37.88593, 145.01498],
  [-37.87924, 145.01630],
  [-37.87919, 145.01637],
  [-37.87917, 145.01648],
  [-37.88137, 145.03449],
  [-37.88133, 145.03458],
  [-37.88127, 145.03465],
  [-37.87700, 145.03544],
  [-37.87691, 145.03552],
  [-37.87687, 145.03566],
  [-37.87700, 145.03674],
  [-37.87691, 145.03789],
  [-37.87690, 145.03922],
  [-37.87714, 145.04060]
];

const caufieldCarnegie = [
  [-37.88971, 145.04460],
  [-37.89084, 145.05400],
  [-37.89090, 145.05411],
  [-37.89103, 145.05416],
  [-37.89130, 145.05412],
  [-37.89193, 145.05414],
  [-37.89255, 145.05436],
  [-37.89328, 145.05491],
  [-37.89373, 145.05555],
  [-37.89395, 145.05600]
];

const glenhuntlyDepot = [
  [-37.88750, 145.02683],
  [-37.88749, 145.02696],
  [-37.88744, 145.02704],
  [-37.88735, 145.02709],
  [-37.88634, 145.02732],
  [-37.88626, 145.02731],
  [-37.88607, 145.02721],
  [-37.88602, 145.02716],
  [-37.88600, 145.02710],
  [-37.88599, 145.02704],
  [-37.88584, 145.02595]
];

export const east10: Line = {
  name: 'East10',
  state: 'VIC',
  segments: [
    {
      segments: [elsternwickGlenhuntly],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-01-01',
          status: 'closed'
        }, {
          date: '1913-01-01',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [pointOrmond],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brightonRoad],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [elsternwickCaulfield],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [caufieldCarnegie],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [glenhuntlyDepot],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
