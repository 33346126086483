import { Line } from "../../../trackTypes";

const coffinBayPortLincoln = [
  [-34.7490, 135.8873],
  [-34.7531, 135.8769],
  [-34.7541, 135.8724],
  [-34.7545, 135.8644],
  [-34.7543, 135.8626],
  [-34.7536, 135.8606],
  [-34.7518, 135.8582],
  [-34.7510, 135.8566],
  [-34.7505, 135.8546],
  [-34.7506, 135.8528],
  [-34.7517, 135.8483],
  [-34.7530, 135.8452],
  [-34.7548, 135.8425],
  [-34.7573, 135.8399],
  [-34.7586, 135.8384],
  [-34.7630, 135.8323],
  [-34.7651, 135.8299],
  [-34.7663, 135.8281],
  [-34.7681, 135.8252],
  [-34.7757, 135.8151],
  [-34.7772, 135.8125],
  [-34.7785, 135.8085],
  [-34.7790, 135.8049],
  [-34.7788, 135.7519],
  [-34.7792, 135.7478],
  [-34.7805, 135.7434],
  [-34.7820, 135.7405],
  [-34.7862, 135.7343],
  [-34.7881, 135.7297],
  [-34.7889, 135.7246],
  [-34.7875, 135.6973],
  [-34.7867, 135.6923],
  [-34.7849, 135.6881],
  [-34.7825, 135.6846],
  [-34.7793, 135.6819],
  [-34.7769, 135.6806],
  [-34.7727, 135.6791],
  [-34.7697, 135.6773],
  [-34.7678, 135.6755],
  [-34.7660, 135.6732],
  [-34.7259, 135.6113],
  [-34.7133, 135.5994],
  [-34.6946, 135.5752],
  [-34.6794, 135.5470],
  [-34.6781, 135.5438],
  [-34.6753, 135.5332],
  [-34.6744, 135.5316],
  [-34.6700, 135.5255]
];

export const coffinBay: Line = {
  name: 'Coffin Bay',
  state: 'SA',
  segments: [
    {
      segments: [coffinBayPortLincoln],
      history: {
        opened: {
          date: '1966-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
