import { Line } from "../../../../trackTypes";

const kingswood = [
  [-34.93833, 138.61278],
  [-34.93902, 138.61281],
  [-34.94034, 138.61274],
  [-34.94091, 138.61276],
  [-34.94733, 138.61326],
  [-34.94740, 138.61331],
  [-34.94744, 138.61344],
  [-34.94742, 138.61383],
  [-34.94744, 138.61394],
  [-34.94750, 138.61402],
  [-34.94763, 138.61408],
  [-34.96889, 138.61567]
];

export const south5: Line = {
  name: 'South5',
  state: 'SA',
  segments: [
    {
      segments: [kingswood],
      history: {
        opened: {
          date: '1917-03-21',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-07-09',
          status: 'closed'
        }]
      }
    }
  ]
};
