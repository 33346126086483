import { Line } from "../../../trackTypes";

const toowoombaDalby = [
  [-27.5537, 151.9515],
  [-27.5532, 151.9514],
  [-27.5513, 151.9513],
  [-27.5504, 151.9512],
  [-27.5497, 151.9509],
  [-27.5480, 151.9497],
  [-27.5471, 151.9490],
  [-27.5453, 151.9468],
  [-27.5444, 151.9460],
  [-27.5438, 151.9454],
  [-27.5421, 151.9443],
  [-27.5412, 151.9440],
  [-27.5402, 151.9439],
  [-27.5394, 151.9441],
  [-27.5373, 151.9448],
  [-27.5361, 151.9451],
  [-27.5331, 151.9453],
  [-27.5316, 151.9451],
  [-27.5282, 151.9441],
  [-27.5273, 151.9440],
  [-27.5247, 151.9436],
  [-27.5237, 151.9437],
  [-27.5230, 151.9441],
  [-27.5225, 151.9441],
  [-27.5220, 151.9441],
  [-27.5215, 151.9439],
  [-27.5186, 151.9422],
  [-27.5181, 151.9417],
  [-27.5177, 151.9411],
  [-27.5176, 151.9403],
  [-27.5176, 151.9395],
  [-27.5178, 151.9390],
  [-27.5178, 151.9381],
  [-27.5176, 151.9371],
  [-27.5170, 151.9361],
  [-27.5163, 151.9356],
  [-27.5156, 151.9353],
  [-27.5137, 151.9349],
  [-27.5130, 151.9346],
  [-27.5125, 151.9342],
  [-27.5122, 151.9337],
  [-27.5117, 151.9327],
  [-27.5112, 151.9322],
  [-27.5107, 151.9320],
  [-27.5100, 151.9318],
  [-27.5094, 151.9316],
  [-27.5089, 151.9311],
  [-27.5085, 151.9306],
  [-27.5081, 151.9302],
  [-27.5075, 151.9299],
  [-27.5069, 151.9294],
  [-27.5065, 151.9287],
  [-27.5059, 151.9268],
  [-27.5054, 151.9259],
  [-27.5046, 151.9253],
  [-27.5039, 151.9251],
  [-27.4977, 151.9239],
  [-27.4970, 151.9236],
  [-27.4963, 151.9230],
  [-27.4959, 151.9223],
  [-27.4949, 151.9196],
  [-27.4944, 151.9184],
  [-27.4936, 151.9172],
  [-27.4932, 151.9161],
  [-27.4930, 151.9150],
  [-27.4931, 151.9142],
  [-27.4935, 151.9134],
  [-27.4976, 151.9071],
  [-27.4985, 151.9054],
  [-27.5002, 151.9000],
  [-27.5004, 151.8991],
  [-27.5003, 151.8981],
  [-27.4984, 151.8904],
  [-27.4983, 151.8890],
  [-27.4983, 151.8888],
  [-27.4992, 151.8722],
  [-27.4992, 151.8697],
  [-27.4973, 151.8582],
  [-27.4969, 151.8566],
  [-27.4946, 151.8513],
  [-27.4918, 151.8463],
  [-27.4910, 151.8447],
  [-27.4905, 151.8431],
  [-27.4899, 151.8407],
  [-27.4886, 151.8302],
  [-27.4883, 151.8293],
  [-27.4878, 151.8283],
  [-27.4872, 151.8277],
  [-27.4844, 151.8256],
  [-27.4836, 151.8248],
  [-27.4828, 151.8236],
  [-27.4727, 151.8043],
  [-27.4676, 151.7919],
  [-27.4619, 151.7810],
  [-27.4572, 151.7740],
  [-27.4557, 151.7712],
  [-27.4461, 151.7513],
  [-27.4292, 151.7165],
  [-27.3943, 151.6346],
  [-27.3929, 151.6319],
  [-27.2634, 151.4263],
  [-27.2174, 151.3531],
  [-27.1952, 151.3177],
  [-27.1925, 151.3118],
  [-27.1825, 151.2883],
  [-27.1819, 151.2861],
  [-27.1799, 151.2714]
];

const toowoombaJunction = [
  [-27.5513, 151.9513],
  [-27.5516, 151.9515],
  [-27.5519, 151.9519],
  [-27.5520, 151.9525],
  [-27.5518, 151.9531]
];

const dalbyRoma = [
  [-27.1799, 151.2714],
  [-27.1796, 151.2696],
  [-27.1793, 151.2688],
  [-27.0926, 151.1423],
  [-27.0649, 151.1020],
  [-27.0281, 151.0610],
  [-26.9986, 151.0281],
  [-26.9976, 151.0268],
  [-26.9505, 150.9546],
  [-26.8775, 150.8427],
  [-26.7804, 150.6941],
  [-26.7502, 150.6480],
  [-26.7373, 150.6251],
  [-26.7370, 150.6241],
  [-26.7369, 150.6235],
  [-26.7369, 150.6220],
  [-26.7366, 150.6211],
  [-26.7277, 150.6088],
  [-26.7273, 150.6079],
  [-26.6865, 150.4769],
  [-26.6774, 150.4476],
  [-26.6769, 150.4456],
  [-26.6767, 150.4432],
  [-26.6769, 150.3624],
  [-26.6766, 150.3594],
  [-26.6667, 150.3031],
  [-26.6647, 150.2696],
  [-26.6604, 150.2367],
  [-26.6606, 150.1869],
  [-26.6603, 150.1845],
  [-26.6590, 150.1813],
  [-26.6565, 150.1782],
  [-26.6555, 150.1760],
  [-26.6551, 150.1736],
  [-26.6442, 150.0311],
  [-26.6359, 149.8805],
  [-26.6363, 149.8784],
  [-26.6369, 149.8759],
  [-26.6426, 149.8620],
  [-26.6432, 149.8589],
  [-26.6449, 149.8170],
  [-26.6447, 149.8153],
  [-26.6423, 149.8051],
  [-26.6422, 149.8036],
  [-26.6426, 149.8022],
  [-26.6455, 149.7975],
  [-26.6460, 149.7962],
  [-26.6461, 149.7947],
  [-26.6432, 149.7356],
  [-26.6442, 149.6545],
  [-26.6441, 149.6530],
  [-26.6421, 149.6404],
  [-26.6420, 149.6388],
  [-26.6447, 149.6022],
  [-26.6444, 149.5978],
  [-26.6411, 149.5781],
  [-26.6396, 149.5722],
  [-26.6234, 149.5343],
  [-26.6228, 149.5316],
  [-26.6219, 149.4749],
  [-26.6213, 149.4394],
  [-26.6210, 149.4370],
  [-26.6202, 149.4335],
  [-26.6200, 149.4322],
  [-26.6199, 149.4303],
  [-26.6201, 149.4287],
  [-26.6206, 149.4273],
  [-26.6210, 149.4265],
  [-26.6241, 149.4221],
  [-26.6245, 149.4210],
  [-26.6247, 149.4200],
  [-26.6246, 149.4191],
  [-26.6241, 149.4165],
  [-26.6238, 149.4153],
  [-26.6231, 149.4143],
  [-26.6219, 149.4135],
  [-26.6207, 149.4132],
  [-26.6186, 149.4131],
  [-26.6176, 149.4129],
  [-26.6167, 149.4124],
  [-26.6160, 149.4116],
  [-26.6155, 149.4108],
  [-26.6153, 149.4100],
  [-26.6152, 149.4093],
  [-26.6145, 149.3898],
  [-26.6143, 149.3885],
  [-26.6036, 149.3461],
  [-26.5963, 149.2820],
  [-26.5969, 149.2777],
  [-26.5968, 149.2757],
  [-26.5957, 149.2709],
  [-26.5872, 149.1876],
  [-26.5872, 149.1855],
  [-26.5885, 149.1794],
  [-26.5886, 149.1768],
  [-26.5818, 149.1038],
  [-26.5818, 149.1003],
  [-26.5884, 149.0558],
  [-26.5848, 148.9675],
  [-26.5842, 148.9647],
  [-26.5821, 148.9602],
  [-26.5815, 148.9574],
  [-26.5789, 148.9160],
  [-26.5799, 148.8858],
  [-26.5785, 148.8638],
  [-26.5809, 148.8547],
  [-26.5812, 148.8518],
  [-26.5777, 148.8289],
  [-26.5780, 148.8182],
  [-26.5768, 148.7973],
  [-26.5762, 148.7922]
];

const romaCharleville = [
  [-26.5762, 148.7922],
  [-26.5759, 148.7902],
  [-26.5759, 148.7888],
  [-26.5763, 148.7875],
  [-26.5780, 148.7818],
  [-26.5782, 148.7803],
  [-26.5784, 148.7745],
  [-26.5753, 148.7520],
  [-26.5755, 148.7465],
  [-26.5785, 148.7331],
  [-26.5785, 148.7273],
  [-26.5826, 148.7094],
  [-26.5834, 148.7074],
  [-26.5900, 148.6968],
  [-26.5905, 148.6952],
  [-26.5907, 148.6937],
  [-26.5892, 148.6823],
  [-26.5771, 148.6316],
  [-26.5768, 148.6286],
  [-26.5765, 148.5841],
  [-26.5830, 148.5401],
  [-26.5838, 148.5256],
  [-26.5909, 148.4538],
  [-26.5909, 148.4511],
  [-26.5846, 148.3880],
  [-26.5839, 148.3807],
  [-26.5690, 148.2779],
  [-26.5684, 148.2547],
  [-26.5682, 148.2533],
  [-26.5564, 148.2165],
  [-26.5537, 148.2117],
  [-26.5522, 148.2064],
  [-26.5511, 148.1990],
  [-26.5511, 148.1969],
  [-26.5515, 148.1912],
  [-26.5513, 148.1895],
  [-26.5507, 148.1881],
  [-26.5397, 148.1718],
  [-26.5389, 148.1701],
  [-26.5385, 148.1684],
  [-26.5386, 148.1573],
  [-26.5381, 148.1552],
  [-26.5312, 148.1405],
  [-26.5253, 148.1329],
  [-26.5100, 148.1185],
  [-26.5086, 148.1164],
  [-26.5014, 148.1044],
  [-26.5002, 148.1014],
  [-26.4867, 148.0548],
  [-26.4863, 148.0516],
  [-26.4880, 148.0069],
  [-26.4866, 147.9958],
  [-26.4860, 147.9942],
  [-26.4859, 147.9929],
  [-26.4861, 147.9905],
  [-26.4858, 147.9880],
  [-26.4852, 147.9867],
  [-26.4827, 147.9830],
  [-26.4823, 147.9819],
  [-26.4824, 147.9808],
  [-26.4881, 147.9660],
  [-26.4882, 147.9643],
  [-26.4819, 147.9441],
  [-26.4799, 147.9237],
  [-26.4802, 147.9213],
  [-26.4887, 147.8879],
  [-26.4892, 147.8865],
  [-26.4915, 147.8840],
  [-26.4918, 147.8833],
  [-26.4920, 147.8826],
  [-26.4920, 147.8814],
  [-26.4921, 147.8807],
  [-26.4930, 147.8782],
  [-26.4933, 147.8767],
  [-26.4934, 147.8736],
  [-26.4930, 147.8723],
  [-26.4913, 147.8696],
  [-26.4910, 147.8689],
  [-26.4910, 147.8682],
  [-26.4911, 147.8677],
  [-26.4936, 147.8598],
  [-26.4974, 147.8355],
  [-26.4992, 147.8283],
  [-26.4993, 147.8261],
  [-26.4991, 147.8242],
  [-26.4992, 147.8225],
  [-26.4996, 147.8196],
  [-26.5006, 147.8151],
  [-26.5028, 147.7985],
  [-26.5038, 147.7798],
  [-26.5004, 147.7433],
  [-26.5006, 147.7420],
  [-26.5011, 147.7411],
  [-26.5037, 147.7388],
  [-26.5050, 147.7368],
  [-26.5056, 147.7345],
  [-26.5054, 147.7322],
  [-26.5049, 147.7310],
  [-26.4951, 147.7130],
  [-26.4945, 147.7110],
  [-26.4887, 147.6639],
  [-26.4881, 147.6629],
  [-26.4875, 147.6625],
  [-26.4864, 147.6623],
  [-26.4857, 147.6619],
  [-26.4853, 147.6613],
  [-26.4847, 147.6599],
  [-26.4847, 147.6590],
  [-26.4848, 147.6581],
  [-26.4855, 147.6570],
  [-26.4872, 147.6555],
  [-26.4878, 147.6545],
  [-26.4881, 147.6530],
  [-26.4873, 147.6453],
  [-26.4869, 147.6436],
  [-26.4825, 147.6365],
  [-26.4752, 147.6263],
  [-26.4746, 147.6251],
  [-26.4745, 147.6240],
  [-26.4745, 147.6230],
  [-26.4754, 147.6201],
  [-26.4754, 147.6189],
  [-26.4750, 147.6177],
  [-26.4742, 147.6170],
  [-26.4727, 147.6160],
  [-26.4720, 147.6152],
  [-26.4709, 147.6132],
  [-26.4707, 147.6121],
  [-26.4709, 147.6026],
  [-26.4701, 147.5995],
  [-26.4700, 147.5985],
  [-26.4702, 147.5973],
  [-26.4708, 147.5952],
  [-26.4710, 147.5931],
  [-26.4705, 147.5891],
  [-26.4699, 147.5875],
  [-26.4692, 147.5863],
  [-26.4682, 147.5853],
  [-26.4663, 147.5841],
  [-26.4650, 147.5826],
  [-26.4575, 147.5720],
  [-26.4572, 147.5713],
  [-26.4572, 147.5705],
  [-26.4574, 147.5698],
  [-26.4608, 147.5650],
  [-26.4614, 147.5637],
  [-26.4615, 147.5623],
  [-26.4607, 147.5570],
  [-26.4601, 147.5536],
  [-26.4595, 147.5514],
  [-26.4560, 147.5438],
  [-26.4551, 147.5409],
  [-26.4536, 147.5295],
  [-26.4498, 147.4989],
  [-26.4459, 147.4838],
  [-26.4416, 147.4671],
  [-26.4415, 147.4657],
  [-26.4419, 147.4645],
  [-26.4420, 147.4635],
  [-26.4418, 147.4629],
  [-26.4408, 147.4611],
  [-26.4406, 147.4601],
  [-26.4407, 147.4588],
  [-26.4415, 147.4571],
  [-26.4422, 147.4552],
  [-26.4458, 147.4323],
  [-26.4463, 147.4251],
  [-26.4459, 147.4219],
  [-26.4402, 147.4095],
  [-26.4391, 147.4057],
  [-26.4344, 147.3676],
  [-26.4346, 147.3647],
  [-26.4364, 147.3563],
  [-26.4367, 147.3531],
  [-26.4366, 147.3502],
  [-26.4370, 147.3488],
  [-26.4376, 147.3479],
  [-26.4385, 147.3468],
  [-26.4388, 147.3458],
  [-26.4417, 147.3319],
  [-26.4415, 147.3306],
  [-26.4366, 147.3227],
  [-26.4357, 147.3203],
  [-26.4318, 147.3034],
  [-26.4289, 147.2680],
  [-26.4202, 147.1741],
  [-26.4197, 147.1719],
  [-26.4188, 147.1700],
  [-26.4185, 147.1690],
  [-26.4186, 147.1681],
  [-26.4192, 147.1635],
  [-26.4166, 147.1333],
  [-26.4136, 147.1212],
  [-26.4133, 147.1186],
  [-26.4137, 147.1164],
  [-26.4148, 147.1121],
  [-26.4150, 147.1107],
  [-26.4146, 147.1095],
  [-26.4116, 147.1049],
  [-26.4107, 147.1030],
  [-26.4104, 147.1011],
  [-26.4091, 147.0335],
  [-26.4094, 147.0312],
  [-26.4118, 147.0238],
  [-26.4119, 147.0223],
  [-26.4113, 147.0207],
  [-26.4113, 147.0199],
  [-26.4114, 147.0191],
  [-26.4134, 147.0140],
  [-26.4149, 147.0116],
  [-26.4251, 147.0025],
  [-26.4261, 147.0010],
  [-26.4264, 146.9995],
  [-26.4244, 146.9602],
  [-26.4128, 146.8932],
  [-26.4128, 146.8905],
  [-26.4142, 146.8835],
  [-26.4143, 146.8815],
  [-26.4115, 146.8648],
  [-26.4106, 146.8625],
  [-26.4095, 146.8610],
  [-26.4084, 146.8598],
  [-26.4073, 146.8581],
  [-26.4067, 146.8566],
  [-26.4059, 146.8533],
  [-26.4056, 146.8434],
  [-26.4063, 146.8402],
  [-26.4075, 146.8380],
  [-26.4081, 146.8361],
  [-26.4087, 146.6572],
  [-26.4091, 146.6547],
  [-26.4150, 146.6402],
  [-26.4156, 146.6376],
  [-26.4156, 146.6296],
  [-26.4154, 146.6280],
  [-26.4141, 146.6223],
  [-26.4138, 146.6201],
  [-26.4142, 146.6051],
  [-26.4148, 146.6023],
  [-26.4160, 146.6001],
  [-26.4242, 146.5897],
  [-26.4251, 146.5879],
  [-26.4343, 146.5641],
  [-26.4349, 146.5618],
  [-26.4362, 146.5503],
  [-26.4360, 146.5472],
  [-26.4313, 146.5328],
  [-26.4310, 146.5303],
  [-26.4314, 146.5241],
  [-26.4313, 146.5215],
  [-26.4308, 146.5197],
  [-26.4299, 146.5181],
  [-26.4182, 146.5035],
  [-26.4170, 146.5015],
  [-26.4165, 146.4997],
  [-26.4165, 146.4977],
  [-26.4168, 146.4958],
  [-26.4207, 146.4783],
  [-26.4209, 146.4761],
  [-26.4203, 146.4735],
  [-26.4174, 146.4679],
  [-26.4168, 146.4660],
  [-26.4166, 146.4636],
  [-26.4170, 146.4615],
  [-26.4198, 146.4522],
  [-26.4239, 146.4242],
  [-26.4241, 146.4213],
  [-26.4224, 146.3848],
  [-26.4225, 146.3821],
  [-26.4231, 146.3794],
  [-26.4242, 146.3755],
  [-26.4245, 146.3738],
  [-26.4245, 146.3720],
  [-26.4046, 146.2506],
  [-26.4045, 146.2487],
  [-26.4047, 146.2476],
  [-26.4056, 146.2454],
  [-26.4063, 146.2442]
];

const charlevilleWestgate = [
  [-26.4063, 146.2442],
  [-26.4078, 146.2419],
  [-26.4085, 146.2412],
  [-26.4095, 146.2406],
  [-26.4586, 146.2276],
  [-26.4863, 146.2202],
  [-26.5861, 146.1937]
];

const westgateCunnamulla = [
  [-26.5861, 146.1937],
  [-26.5880, 146.1932],
  [-26.6446, 146.1782],
  [-26.6639, 146.1730],
  [-26.6680, 146.1714],
  [-26.6706, 146.1699],
  [-26.7858, 146.0961],
  [-26.7870, 146.0955],
  [-26.7891, 146.0947],
  [-26.8093, 146.0874],
  [-26.8998, 146.0548],
  [-26.9462, 146.0382],
  [-26.9532, 146.0367],
  [-26.9981, 146.0306],
  [-27.0258, 146.0269],
  [-27.0961, 146.0176],
  [-27.1035, 146.0160],
  [-27.2158, 145.9860],
  [-27.2204, 145.9851],
  [-27.2670, 145.9791],
  [-27.2910, 145.9786],
  [-27.2994, 145.9774],
  [-27.3907, 145.9544],
  [-27.5264, 145.9200],
  [-27.5427, 145.9144],
  [-27.5481, 145.9133],
  [-27.6537, 145.9025],
  [-27.6993, 145.8979],
  [-27.7742, 145.8813],
  [-27.7787, 145.8795],
  [-27.8500, 145.8436],
  [-27.8676, 145.8345],
  [-27.9372, 145.7915],
  [-27.9579, 145.7788],
  [-28.0409, 145.7364],
  [-28.0455, 145.7312],
  [-28.0625, 145.7006],
  [-28.0634, 145.6977],
  [-28.0634, 145.6946],
  [-28.0623, 145.6891]
];

const westgateQuilpie = [
  [-26.5861, 146.1937],
  [-26.5861, 146.1937],
  [-26.5865, 146.1934],
  [-26.5868, 146.1929],
  [-26.5870, 146.1923],
  [-26.5870, 146.1916],
  [-26.5901, 146.1282],
  [-26.5871, 146.0610],
  [-26.5872, 146.0589],
  [-26.5875, 146.0576],
  [-26.5911, 146.0488],
  [-26.5941, 146.0437],
  [-26.5947, 146.0421],
  [-26.5947, 146.0403],
  [-26.5942, 146.0387],
  [-26.5870, 146.0237],
  [-26.5862, 146.0205],
  [-26.5859, 146.0169],
  [-26.5878, 145.9910],
  [-26.5838, 145.9507],
  [-26.5839, 145.9474],
  [-26.5888, 145.9104],
  [-26.5882, 145.9077],
  [-26.5870, 145.9060],
  [-26.5826, 145.9026],
  [-26.5807, 145.9003],
  [-26.5800, 145.8982],
  [-26.5663, 145.8368],
  [-26.5653, 145.8108],
  [-26.5656, 145.8084],
  [-26.5880, 145.7215],
  [-26.5889, 145.7198],
  [-26.5902, 145.7186],
  [-26.5915, 145.7182],
  [-26.5958, 145.7173],
  [-26.5972, 145.7164],
  [-26.5980, 145.7149],
  [-26.5983, 145.7134],
  [-26.5970, 145.7030],
  [-26.5973, 145.6945],
  [-26.5967, 145.6912],
  [-26.5952, 145.6880],
  [-26.5946, 145.6857],
  [-26.5932, 145.6394],
  [-26.5938, 145.6370],
  [-26.5954, 145.6353],
  [-26.5971, 145.6348],
  [-26.5998, 145.6347],
  [-26.6012, 145.6340],
  [-26.6024, 145.6326],
  [-26.6066, 145.6226],
  [-26.6086, 145.6195],
  [-26.6331, 145.5924],
  [-26.6402, 145.5879],
  [-26.6412, 145.5870],
  [-26.6421, 145.5854],
  [-26.6447, 145.5784],
  [-26.6450, 145.5766],
  [-26.6447, 145.5747],
  [-26.6423, 145.5688],
  [-26.6421, 145.5665],
  [-26.6429, 145.5643],
  [-26.6441, 145.5630],
  [-26.6481, 145.5606],
  [-26.6496, 145.5591],
  [-26.6514, 145.5557],
  [-26.6518, 145.5535],
  [-26.6513, 145.5515],
  [-26.6453, 145.5373],
  [-26.6444, 145.5339],
  [-26.6443, 145.5305],
  [-26.6436, 145.5284],
  [-26.6424, 145.5266],
  [-26.6418, 145.5248],
  [-26.6419, 145.5228],
  [-26.6447, 145.5166],
  [-26.6460, 145.5123],
  [-26.6472, 145.5106],
  [-26.6476, 145.5086],
  [-26.6471, 145.5067],
  [-26.6438, 145.5022],
  [-26.6424, 145.4991],
  [-26.6398, 145.4883],
  [-26.6396, 145.4846],
  [-26.6404, 145.4796],
  [-26.6424, 145.4744],
  [-26.6427, 145.4727],
  [-26.6393, 145.4421],
  [-26.6393, 145.4393],
  [-26.6439, 145.3682],
  [-26.6437, 145.3629],
  [-26.6455, 145.3557],
  [-26.6457, 145.3506],
  [-26.6445, 145.3424],
  [-26.6453, 145.2958],
  [-26.6460, 145.2929],
  [-26.6497, 145.2851],
  [-26.6504, 145.2817],
  [-26.6504, 145.2786],
  [-26.6399, 145.2084],
  [-26.6401, 145.2065],
  [-26.6408, 145.2042],
  [-26.6410, 145.2022],
  [-26.6337, 145.1650],
  [-26.6335, 145.1629],
  [-26.6337, 145.1582],
  [-26.6331, 145.1551],
  [-26.6265, 145.1402],
  [-26.6265, 145.1402],
  [-26.6268, 145.1368],
  [-26.6298, 145.1326],
  [-26.6328, 145.1308],
  [-26.6340, 145.1294],
  [-26.6356, 145.1261],
  [-26.6357, 145.1243],
  [-26.6343, 145.1191],
  [-26.6342, 145.1174],
  [-26.6358, 145.1051],
  [-26.6291, 144.9790],
  [-26.6264, 144.9654],
  [-26.6262, 144.9628],
  [-26.6423, 144.8430],
  [-26.6417, 144.8346],
  [-26.6406, 144.8323],
  [-26.6390, 144.8307],
  [-26.6382, 144.8294],
  [-26.6379, 144.8278],
  [-26.6384, 144.8205],
  [-26.6382, 144.8185],
  [-26.6329, 144.7998],
  [-26.6277, 144.7913],
  [-26.6270, 144.7892],
  [-26.6268, 144.7866],
  [-26.6291, 144.7766],
  [-26.6298, 144.7749],
  [-26.6315, 144.7725],
  [-26.6323, 144.7703],
  [-26.6323, 144.7574],
  [-26.6313, 144.7454],
  [-26.6309, 144.7436],
  [-26.6235, 144.7265],
  [-26.6230, 144.7241],
  [-26.6228, 144.7149],
  [-26.6184, 144.6845],
  [-26.6145, 144.6003],
  [-26.6161, 144.5400],
  [-26.6191, 144.5195],
  [-26.6192, 144.5154],
  [-26.6176, 144.5045],
  [-26.6005, 144.4686],
  [-26.5991, 144.4618],
  [-26.5969, 144.4289],
  [-26.5962, 144.4259],
  [-26.5905, 144.4151],
  [-26.5899, 144.4133],
  [-26.5898, 144.4110],
  [-26.5967, 144.3414],
  [-26.6102, 144.2891],
  [-26.6136, 144.2629]
];

const westgateJunction = [
  [-26.5870, 146.1923],
  [-26.5871, 146.1927],
  [-26.5874, 146.1930],
  [-26.5876, 146.1931],
  [-26.5880, 146.1932]
];

export const western: Line = {
  name: 'Western',
  state: 'QLD',
  segments: [
    {
      segments: [toowoombaDalby, toowoombaJunction],
      history: {
        opened: {
          date: '1868-04-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [dalbyRoma],
      history: {
        opened: {
          date: '1877-09-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [romaCharleville],
      history: {
        opened: {
          date: '1883-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [charlevilleWestgate],
      history: {
        opened: {
          date: '1898-10-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [westgateCunnamulla],
      history: {
        opened: {
          date: '1898-10-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westgateQuilpie, westgateJunction],
      history: {
        opened: {
          date: '1917-06-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-09-01',
          status: 'closed'
        }]
      }
    }
  ]
}
