import { Line } from "../../trackTypes";

const hernsOakBrownCoalMineJunction = [
  [-38.2124, 146.3385],
  [-38.2123, 146.3412],
  [-38.2122, 146.3433],
  [-38.2113, 146.3456],
  [-38.2016, 146.3525],
  [-38.1985, 146.3523],
  [-38.1947, 146.3511]
];

const brownCoalMineJunctionYallourn = [
  [-38.1947, 146.3511],
  [-38.1877, 146.3465],
  [-38.1857, 146.3469],
  [-38.1849, 146.3470],
  [-38.1841, 146.3465],
  [-38.1827, 146.3442],
  [-38.1823, 146.3436],
  [-38.1798, 146.3415]
];

const yallournYallournNorth = [
  [-38.1797, 146.3445],
  [-38.1784, 146.3450],
  [-38.1779, 146.3454],
  [-38.1768, 146.3476],
  [-38.1763, 146.3490],
  [-38.1759, 146.3499],
  [-38.1758, 146.3509],
  [-38.1758, 146.3523],
  [-38.1754, 146.3540],
  [-38.1748, 146.3558],
  [-38.1747, 146.3572],
  [-38.1748, 146.3588],
  [-38.1750, 146.3598],
  [-38.1752, 146.3620],
  [-38.1748, 146.3629],
  [-38.1721, 146.3657],
  [-38.1705, 146.3656],
  [-38.1678, 146.3641]
];

const yallournNorthMoeJunction = [
  [-38.1798, 146.3415],
  [-38.1802, 146.3428],
  [-38.1801, 146.3439],
  [-38.1797, 146.3445]
];

const moeYallourn = [
  [-38.1772, 146.2658],
  [-38.1771, 146.2677],
  [-38.1769, 146.2686],
  [-38.1730, 146.2825],
  [-38.1683, 146.3246],
  [-38.1687, 146.3279],
  [-38.1697, 146.3298],
  [-38.1704, 146.3306],
  [-38.1753, 146.3332],
  [-38.1761, 146.3340],
  [-38.1768, 146.3352],
  [-38.1773, 146.3375],
  [-38.1778, 146.3392],
  [-38.1787, 146.3406],
  [-38.1798, 146.3415]
];

const hernsOakYallournNorthJunction = [
  [-38.1797, 146.3445],
  [-38.1806, 146.3441],
  [-38.1823, 146.3436]
];

export const yallourn: Line = {
  name: 'Yallourn',
  state: 'VIC',
  segments: [
    {
      segments: [hernsOakBrownCoalMineJunction],
      history: {
        opened: {
          date: '1890-09-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brownCoalMineJunctionYallourn],
      history: {
        opened: {
          date: '1921-11-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yallournYallournNorth],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yallournNorthMoeJunction],
      history: {
        opened: {
          date: '1953-09-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moeYallourn],
      history: {
        opened: {
          date: '1953-09-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-09-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1979-01-01',
          electrified: undefined
        }, {
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hernsOakYallournNorthJunction],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-03-31',
          status: 'closed'
        }]
      }
    }
  ]
};
