import { Line } from '../trackTypes';
import { skiTube } from './newSouthWales/skiTube';
import { metroNorthWest } from './newSouthWales/sydney/metroNorthWest';
import { airportLink } from './newSouthWales/sydney/airportLink';
import { northernLine } from './newSouthWales/northernLine';
import { leppington } from './newSouthWales/sydney/leppington';
import { mainWest } from './newSouthWales/mainWest';
import { oaklands } from './newSouthWales/oaklands';
import { brokenHill } from './newSouthWales/brokenHill';
import { northShore } from './newSouthWales/sydney/northShore';
import { deniliquin } from './newSouthWales/deniliquin';
import { moulamein } from './newSouthWales/moulamein';
import { stonyCrossing } from './newSouthWales/stonyCrossing';
import { corowa } from './newSouthWales/corowa';
import { tocumwal } from './newSouthWales/tocumwal';
import { hay } from './newSouthWales/hay';
import { mainSouthLine } from './newSouthWales/mainSouthLine';
import { macarthur } from './newSouthWales/sydney/macarthur';
import { holbrook } from './newSouthWales/holbrook';
import { rand } from './newSouthWales/rand';
import { westby } from './newSouthWales/westby';
import { sydneyLightRail } from './newSouthWales/trams/sydneyLightRail';
import { kywong } from './newSouthWales/kywong';
import { tumbarumba } from './newSouthWales/tumbarumba';
import { yanco } from './newSouthWales/yanco';
import { inlandRailNsw } from './newSouthWales/inlandRailNsw';
import { cityCircle } from './newSouthWales/sydney/cityCircle';
import { easternSuburbs } from './newSouthWales/sydney/easternSuburbs';
import { cronulla } from './newSouthWales/sydney/cronulla';
import { richmond } from './newSouthWales/sydney/richmond';
import { newcastle } from './newSouthWales/newcastle';
import { temoraRoto } from './newSouthWales/temoraRoto';
import { parkesNarromine } from './newSouthWales/parkesNarromine';
import { southCoast } from './newSouthWales/illawarra/southCoast';
import { royalPark } from './newSouthWales/sydney/royalPark';
import { naradhan } from './newSouthWales/naradhan';
import { burcher } from './newSouthWales/burcher';
import { rankinsSprings } from './newSouthWales/rankinsSprings';
import { easternHills } from './newSouthWales/sydney/easternHills';
import { bombala } from './newSouthWales/bombala';
import { cobar } from './newSouthWales/cobar';
import { bankstown } from './newSouthWales/sydney/bankstown';
import { carlingford } from './newSouthWales/sydney/carlingford';
import { pokataroo } from './newSouthWales/pokataroo';
import { tottenham } from './newSouthWales/tottenham';
import { lakeCargellio } from './newSouthWales/lakeCargelligo';
import { northCoast } from './newSouthWales/northCoast/northCoast';
import { unanderraMossVale } from './newSouthWales/illawarra/unanderraMossVale';
import { walgett } from './newSouthWales/walgett';
import { stockinbingalParkes } from './newSouthWales/stockinbingalParkes';
import { tumut } from './newSouthWales/tumut';
import { boorowa } from './newSouthWales/boorowa';
import { portWaratah } from './newSouthWales/portWaratah';
import { coonamble } from './newSouthWales/coonamble';
import { warren } from './newSouthWales/warren';
import { brewarrina } from './newSouthWales/brewarrina';
import { troyJunctionMerrygoen } from './newSouthWales/troyJunctionMerrygoen';
import { mountHope } from './newSouthWales/mountHope';
import { inverell } from './newSouthWales/inverell';
import { lidcombeCabramatta } from './newSouthWales/sydney/lidcombeCabramatta';
import { grenfell } from './newSouthWales/grenfell';
import { eugowra } from './newSouthWales/eugowra';
import { mungindi } from './newSouthWales/mungindi';
import { boggabilla } from './newSouthWales/boggabilla';
import { coolah } from './newSouthWales/coolah';
import { murwillumbah } from './newSouthWales/northCoast/murwillumbah';
import { dorrigo } from './newSouthWales/northCoast/dorrigo';
import { southCoastQld } from './newSouthWales/southCoastQld';
import { gwabegar } from './newSouthWales/gwabegar';
import { merriwa } from './newSouthWales/merriwa';
import { binnawayWerrisCreek } from './newSouthWales/binnawayWerrisCreek';
import { botanyGoods } from './newSouthWales/sydney/botanyGoods';
import { olympicPark } from './newSouthWales/sydney/olympicPark';
import { barraba } from './newSouthWales/barraba';
import { flemingtonGoods } from './newSouthWales/sydney/flemingtonGoods';
import { chulloraSeftonGoods } from './newSouthWales/sydney/chulloraSeftonGoods';
import { cessnock } from './newSouthWales/cessnock';
import { hunterValleyColleries } from './newSouthWales/hunterValleyColleries';
import { molongDubbo } from './newSouthWales/molongDubbo';
import { blayneyDemondrille } from './newSouthWales/blayneyDemondrille';
import { sandyHollowGulgong } from './newSouthWales/sandyHollowGulgong';
import { captainsFlat } from './newSouthWales/captainsFlat';
import { yass } from './newSouthWales/yass';
import { toronto } from './newSouthWales/toronto';
import { oberon } from './newSouthWales/oberon';
import { taralga } from './newSouthWales/taralga';
import { ballina } from './newSouthWales/northCoast/ballina';
import { newnes } from './newSouthWales/newnes';
import { crookwell } from './newSouthWales/crookwell';
import { richmondVale } from './newSouthWales/richmondVale';
import { darlingHarbourGoodsLine } from './newSouthWales/sydney/darlingHarbourGoodsLine';
import { camden } from './newSouthWales/camden';
import { berrima } from './newSouthWales/berrima';
import { medwayQuarry } from './newSouthWales/medwayQuarry';
import { tarrawingee } from './newSouthWales/tarrawingee';
import { stanfordMerthyr } from './newSouthWales/stanfordMerthyr';
import { morpeth } from './newSouthWales/morpeth';
import { rutherfordRacecourse } from './newSouthWales/rutherfordRacecourse';
import { newcastleTrams } from './newSouthWales/trams/newcastleTrams';
import { wangiPowerStation } from './newSouthWales/wangiPowerStation';
import { belmont } from './newSouthWales/belmont';
import { wallsend } from './newSouthWales/wallsend';
import { kooragangIsland } from './newSouthWales/kooragangIsland';
import { hebburn } from './newSouthWales/hebburn';
import { prospectQuarry } from './newSouthWales/sydney/prospectQuarry';
import { widemereQuarry } from './newSouthWales/sydney/widemereQuarry';
import { rogansHill } from './newSouthWales/sydney/rogansHill';
import { rookwoodCemetery } from './newSouthWales/sydney/rookwoodCemetery';
import { warwickFarmRacecourse } from './newSouthWales/sydney/warwickFarmRacecourse';
import { ropesCreek } from './newSouthWales/ropesCreek';
import { glenleeColliery } from './newSouthWales/glenleeColliery';
import { sydneyTramsNorth } from './newSouthWales/trams/sydneyTramsNorth';
import { manlyTrams } from './newSouthWales/trams/manlyTrams';
import { enfieldTrams } from './newSouthWales/trams/enfieldTrams';
import { kunama } from './newSouthWales/kunama';
import { sydneyTramsWest } from './newSouthWales/trams/sydneyTramsWest';
import { sydneyTramsSouthWest } from './newSouthWales/trams/sydneyTramsSouthWest';
import { sydneyTramsSouth } from './newSouthWales/trams/sydneyTramsSouth';
import { sydneyTramsCbd } from './newSouthWales/trams/sydneyTramsCbd';
import { sydneyTramsEast } from './newSouthWales/trams/sydneyTramsEast';
import { holsworthy } from './newSouthWales/sydney/holsworthy';
import { mountOwen } from './newSouthWales/mountOwenMine';
import { muswellbrook } from './newSouthWales/muswellbrookMine';
import { lette } from './newSouthWales/lette';
import { mountThorley } from './newSouthWales/mountThorley';
import { goondahBurrinjuck } from './newSouthWales/goondahBurrinjuck';
import { mountKembla } from './newSouthWales/illawarra/mountKembla';
import { portKembla } from './newSouthWales/illawarra/portKembla';
import { wongawill } from './newSouthWales/illawarra/wongawilli';
import { helensburughColliery } from './newSouthWales/illawarra/helensburghColliery';
import { illawarraSidings } from './newSouthWales/illawarra/illawarraSidings';
import { katoombaScenicRailway } from './newSouthWales/katoombaScenicRailway';
import { sydneyMonorail } from './newSouthWales/sydneyMonorail';
import { metroWest } from './newSouthWales/sydney/metroWest';
import { paramattaTrams } from './newSouthWales/trams/paramattaTrams';
import { brokenHillTrams } from './newSouthWales/trams/brokenHillTrams';
import { metroAirportWest } from './newSouthWales/sydney/metroAirportWest';
import { maitlandTrams } from './newSouthWales/trams/maitlandTrams';
import { emuPlains } from './newSouthWales/sydney/emuPlains';
import { berowra } from './newSouthWales/sydney/berowra';
import { waterfall } from './newSouthWales/sydney/waterfall';
import { boxValeColliery } from './newSouthWales/boxValeColliery';

export const nswLines: Line[] = [
  skiTube,
  metroNorthWest,
  airportLink,
  northernLine,
  leppington,
  mainWest,
  oaklands,
  brokenHill,
  northShore,
  deniliquin,
  moulamein,
  stonyCrossing,
  corowa,
  tocumwal,
  hay,
  mainSouthLine,
  holbrook,
  rand,
  westby,
  sydneyLightRail,
  kywong,
  tumbarumba,
  yanco,
  inlandRailNsw,
  cityCircle,
  easternSuburbs,
  cronulla,
  richmond,
  newcastle,
  temoraRoto,
  parkesNarromine,
  southCoast,
  royalPark,
  naradhan,
  burcher,
  rankinsSprings,
  easternHills,
  bombala,
  cobar,
  bankstown,
  carlingford,
  pokataroo,
  tottenham,
  lakeCargellio,
  northCoast,
  unanderraMossVale,
  walgett,
  stockinbingalParkes,
  tumut,
  boorowa,
  portWaratah,
  coonamble,
  warren,
  brewarrina,
  troyJunctionMerrygoen,
  mountHope,
  inverell,
  lidcombeCabramatta,
  grenfell,
  eugowra,
  mungindi,
  boggabilla,
  coolah,
  murwillumbah,
  dorrigo,
  southCoastQld,
  gwabegar,
  merriwa,
  binnawayWerrisCreek,
  botanyGoods,
  olympicPark,
  barraba,
  flemingtonGoods,
  chulloraSeftonGoods,
  cessnock,
  hunterValleyColleries,
  molongDubbo,
  blayneyDemondrille,
  sandyHollowGulgong,
  captainsFlat,
  yass,
  toronto,
  oberon,
  taralga,
  ballina,
  newnes,
  crookwell,
  richmondVale,
  darlingHarbourGoodsLine,
  camden,
  berrima,
  medwayQuarry,
  tarrawingee,
  stanfordMerthyr,
  morpeth,
  rutherfordRacecourse,
  newcastleTrams,
  wangiPowerStation,
  belmont,
  wallsend,
  kooragangIsland,
  hebburn,
  prospectQuarry,
  widemereQuarry,
  rogansHill,
  rookwoodCemetery,
  warwickFarmRacecourse,
  ropesCreek,
  glenleeColliery,
  sydneyTramsNorth,
  manlyTrams,
  enfieldTrams,
  kunama,
  sydneyTramsWest,
  sydneyTramsSouthWest,
  sydneyTramsSouth,
  sydneyTramsCbd,
  sydneyTramsEast,
  holsworthy,
  mountOwen,
  muswellbrook,
  lette,
  mountThorley,
  goondahBurrinjuck,
  mountKembla,
  portKembla,
  wongawill,
  helensburughColliery,
  illawarraSidings,
  katoombaScenicRailway,
  sydneyMonorail,
  metroWest,
  paramattaTrams,
  brokenHillTrams,
  metroAirportWest,
  maitlandTrams,
  emuPlains,
  berowra,
  macarthur,
  waterfall,
  boxValeColliery
];
