import { Line } from "../../trackTypes";

const line = [
  [-29.2504, 114.9578],
  [-29.2518, 114.9598],
  [-29.2533, 114.9609],
  [-29.2550, 114.9612],
  [-29.2562, 114.9609],
  [-29.2603, 114.9591],
  [-29.2629, 114.9584],
  [-29.2705, 114.9584],
  [-29.2834, 114.9621],
  [-29.2854, 114.9633],
  [-29.2952, 114.9716],
  [-29.2982, 114.9732],
  [-29.3017, 114.9736],
  [-29.3058, 114.9730],
  [-29.3081, 114.9730],
  [-29.3286, 114.9769],
  [-29.3339, 114.9786],
  [-29.3355, 114.9788],
  [-29.3443, 114.9786],
  [-29.3500, 114.9768],
  [-29.3517, 114.9765],
  [-29.3621, 114.9765],
  [-29.3736, 114.9785],
  [-29.3768, 114.9802],
  [-29.3923, 114.9939],
  [-29.3959, 114.9963],
  [-29.4038, 115.0000],
  [-29.4069, 115.0006],
  [-29.4257, 115.0005],
  [-29.4286, 114.9998],
  [-29.4398, 114.9925],
  [-29.4445, 114.9916],
  [-29.4648, 114.9961],
  [-29.4677, 114.9973],
  [-29.4819, 115.0074],
  [-29.4855, 115.0086],
  [-29.4893, 115.0083],
  [-29.5002, 115.0040],
  [-29.5033, 115.0036],
  [-29.5122, 115.0054],
  [-29.5159, 115.0076],
  [-29.5190, 115.0119],
  [-29.5201, 115.0178],
  [-29.5182, 115.0294],
  [-29.5186, 115.0348],
  [-29.5210, 115.0396],
  [-29.5243, 115.0424],
  [-29.5409, 115.0496],
  [-29.5435, 115.0516],
  [-29.5461, 115.0545],
  [-29.5482, 115.0561],
  [-29.5592, 115.0608],
  [-29.5641, 115.0647],
  [-29.5824, 115.0725],
  [-29.5855, 115.0750],
  [-29.5880, 115.0786],
  [-29.5899, 115.0804],
  [-29.6081, 115.0930],
  [-29.6110, 115.0941],
  [-29.6157, 115.0949],
  [-29.6188, 115.0965],
  [-29.6214, 115.0986],
  [-29.6344, 115.1071],
  [-29.6370, 115.1101],
  [-29.6473, 115.1314],
  [-29.6529, 115.1506],
  [-29.6551, 115.1542],
  [-29.7759, 115.2621],
  [-29.7785, 115.2636],
  [-29.7995, 115.2701],
  [-29.8094, 115.2687],
  [-29.8131, 115.2668],
  [-29.8206, 115.2590],
  [-29.8249, 115.2564],
  [-29.8490, 115.2531],
  [-29.8506, 115.2535],
  [-29.8516, 115.2548],
  [-29.8525, 115.2576],
  [-29.8523, 115.2601],
  [-29.8454, 115.2721],
  [-29.8449, 115.2739],
  [-29.8447, 115.2778],
  [-29.8454, 115.2801],
  [-29.8470, 115.2815],
  [-29.8560, 115.2834],
  [-29.8909, 115.2845],
  [-29.8921, 115.2847],
  [-29.8940, 115.2857],
  [-29.8952, 115.2860],
  [-29.9272, 115.2875],
  [-29.9287, 115.2871],
  [-29.9316, 115.2848]
];

export const eneabba: Line = {
  name: 'Eneabba',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1974-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
};
