import { Line } from "../../../trackTypes";

const portAhuruiLine = [
  [-39.49446, 176.90597],
  [-39.49172, 176.91645],
  [-39.49124, 176.91818],
  [-39.49118, 176.91830],
  [-39.49112, 176.91839],
  [-39.49033, 176.91846],
  [-39.49021, 176.91844],
  [-39.48840, 176.91762],
  [-39.48830, 176.91752],
  [-39.48823, 176.91737],
  [-39.48789, 176.91535],
  [-39.48782, 176.91512],
  [-39.48683, 176.91278],
  [-39.48666, 176.91257],
  [-39.48651, 176.91249],
  [-39.48639, 176.91246],
  [-39.48625, 176.91248],
  [-39.48583, 176.91271],
  [-39.48572, 176.91276],
  [-39.48563, 176.91276],
  [-39.48487, 176.91262],
  [-39.48479, 176.91255],
  [-39.48475, 176.91246],
  [-39.48446, 176.91088],
  [-39.48435, 176.91056],
  [-39.48424, 176.91038],
  [-39.48408, 176.91021],
  [-39.48248, 176.90961],
  [-39.48222, 176.90959],
  [-39.48206, 176.90958],
  [-39.48123, 176.90973],
  [-39.48096, 176.90973],
  [-39.48042, 176.90963],
  [-39.48022, 176.90956],
  [-39.48000, 176.90941],
  [-39.47949, 176.90894],
  [-39.47937, 176.90877],
  [-39.47930, 176.90859],
  [-39.47886, 176.90745],
  [-39.47882, 176.90720],
  [-39.47957, 176.90217],
  [-39.47997, 176.89989],
  [-39.48004, 176.89965],
  [-39.48039, 176.89902],
  [-39.48046, 176.89882],
  [-39.48058, 176.89826],
  [-39.48064, 176.89816],
  [-39.48072, 176.89806],
  [-39.48112, 176.89788]
];

const thackerayStreetLine = [
  [-39.49172, 176.91645],
  [-39.49179, 176.91638],
  [-39.49187, 176.91636],
  [-39.49357, 176.91711],
  [-39.49367, 176.91712],
  [-39.49376, 176.91710],
  [-39.49402, 176.91694],
  [-39.49411, 176.91685],
  [-39.49418, 176.91671],
  [-39.49477, 176.91430]
];

const hastingsStreetExtension = [
  [-39.49112, 176.91839],
  [-39.49391, 176.91817],
  [-39.49399, 176.91823],
  [-39.49404, 176.91831],
  [-39.49407, 176.91882],
  [-39.49415, 176.91894],
  [-39.49425, 176.91901],
  [-39.49961, 176.91856]
];

export const napierTrams: Line = {
  name: 'Napier Trams',
  state: 'NZ',
  segments: [
    {
      segments: [portAhuruiLine],
      history: {
        opened: {
          date: '1913-09-08',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1931-02-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [thackerayStreetLine],
      history: {
        opened: {
          date: '1913-09-08',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1931-02-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hastingsStreetExtension],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1931-02-03',
          status: 'closed'
        }]
      }
    }
  ]
}
