import { Line } from "../../trackTypes";

const line = [
  [-34.0690, 150.8071],
  [-34.0693, 150.8052],
  [-34.0692, 150.8043],
  [-34.0682, 150.8030],
  [-34.0669, 150.8018],
  [-34.0661, 150.8006],
  [-34.0657, 150.7992],
  [-34.0647, 150.7933],
  [-34.0643, 150.7918],
  [-34.0643, 150.7903],
  [-34.0636, 150.7880],
  [-34.0616, 150.7851],
  [-34.0603, 150.7827],
  [-34.0590, 150.7807],
  [-34.0551, 150.7763],
  [-34.0543, 150.7752],
  [-34.0533, 150.7719],
  [-34.0523, 150.7682],
  [-34.0509, 150.7657],
  [-34.0446, 150.7575],
  [-34.0430, 150.7541],
  [-34.0397, 150.7418],
  [-34.0392, 150.7385],
  [-34.0394, 150.7366],
  [-34.0408, 150.7311],
  [-34.0435, 150.7211],
  [-34.0444, 150.7192],
  [-34.0466, 150.7166],
  [-34.0504, 150.7117],
  [-34.0543, 150.7068],
  [-34.0544, 150.7058],
  [-34.0532, 150.6996],
  [-34.0528, 150.6988],
  [-34.0522, 150.6984]
];

export const camden: Line = {
  name: 'Camden',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1882-03-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
