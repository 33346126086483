import { Line } from "../../../trackTypes";

const chulloraSouthJunctionSeftonParkSouthJunction = [
  [-33.8849, 151.0690],
  [-33.8839, 151.0686],
  [-33.8834, 151.0680],
  [-33.8831, 151.0669],
  [-33.8831, 151.0661],
  [-33.8829, 151.0641],
  [-33.8829, 151.0626],
  [-33.8862, 151.0480],
  [-33.8863, 151.0460],
  [-33.8858, 151.0441],
  [-33.8853, 151.0426],
  [-33.8850, 151.0408],
  [-33.8851, 151.0391],
  [-33.8856, 151.0375],
  [-33.8867, 151.0349],
  [-33.8872, 151.0333],
  [-33.8874, 151.0319],
  [-33.8878, 151.0278],
  [-33.8880, 151.0265],
  [-33.8887, 151.0232],
  [-33.8886, 151.0223],
  [-33.8877, 151.0203],
  [-33.8865, 151.0187],
  [-33.8860, 151.0172]
];

const chulloraNorthChulloraWest = [
  [-33.8812, 151.0686],
  [-33.8820, 151.0684],
  [-33.8827, 151.0677],
  [-33.8831, 151.0669]
];

const chulloraWestSigway = [
  [-33.8831, 151.0661],
  [-33.8832, 151.0649],
  [-33.8832, 151.0631],
  [-33.8845, 151.0577],
  [-33.8857, 151.0555],
  [-33.8863, 151.0551],
  [-33.8866, 151.0547],
  [-33.8870, 151.0540],
  [-33.8871, 151.0533],
  [-33.8875, 151.0524],
  [-33.8883, 151.0514],
  [-33.8886, 151.0510],
  [-33.8889, 151.0500],
  [-33.8889, 151.0491],
  [-33.8904, 151.0421],
  [-33.8910, 151.0412],
  [-33.8917, 151.0405],
  [-33.8933, 151.0400],
  [-33.8954, 151.0396],
  [-33.8963, 151.0397],
  [-33.8970, 151.0402],
  [-33.8975, 151.0409],
  [-33.8977, 151.0417],
  [-33.8976, 151.0426],
  [-33.8954, 151.0474]
];

export const chulloraSeftonGoods: Line = {
  name: 'Chullora - Sefton Goods',
  state: 'NSW',
  segments: [
    {
      segments: [chulloraSouthJunctionSeftonParkSouthJunction, chulloraNorthChulloraWest],
      history: {
        opened: {
          date: '1924-05-15',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [chulloraWestSigway],
      history: {
        opened: {
          date: '1924-05-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-03-11',
          status: 'closed'
        }]
      }
    }
  ]
};
