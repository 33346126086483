import { Line } from "../../../../trackTypes";

const kelvinGrove = [
  [-27.46596, 153.01618],
  [-27.46591, 153.01602],
  [-27.46582, 153.01594],
  [-27.46402, 153.01554],
  [-27.46204, 153.01512],
  [-27.46165, 153.01502],
  [-27.46104, 153.01483],
  [-27.46062, 153.01478],
  [-27.45947, 153.01479],
  [-27.45924, 153.01471],
  [-27.45902, 153.01464],
  [-27.45513, 153.01239],
  [-27.45458, 153.01213],
  [-27.45270, 153.01134],
  [-27.45179, 153.01101],
  [-27.45146, 153.01095],
  [-27.45117, 153.01095],
  [-27.45097, 153.01098],
  [-27.45015, 153.01109],
  [-27.44976, 153.01110],
  [-27.44924, 153.01104],
  [-27.44898, 153.01097],
  [-27.44879, 153.01086],
  [-27.44766, 153.01002],
  [-27.44631, 153.00906]
];

const bowenBridgeExtension = [
  [-27.44631, 153.00906],
  [-27.44539, 153.00814],
  [-27.44458, 153.00761],
  [-27.44335, 153.00709]
];

const newmarket = [
  [-27.44335, 153.00709],
  [-27.44299, 153.00698],
  [-27.44255, 153.00690],
  [-27.44015, 153.00696],
  [-27.43973, 153.00703],
  [-27.43884, 153.00722],
  [-27.43840, 153.00725],
  [-27.43812, 153.00722],
  [-27.43714, 153.00693]
];

const banksStreet = [
  [-27.43714, 153.00693],
  [-27.43678, 153.00679],
  [-27.43612, 153.00645],
  [-27.43583, 153.00634],
  [-27.43483, 153.00607],
  [-27.43372, 153.00566],
  [-27.43337, 153.00551],
  [-27.43285, 153.00523],
  [-27.43256, 153.00512],
  [-27.43230, 153.00508]
];

const countessStreetNorthEastJunction = [
  [-27.45995, 153.01501],
  [-27.45977, 153.01486],
  [-27.45960, 153.01479]
];

const countessStreetSouthEastJunction = [
  [-27.45995, 153.01501],
  [-27.46002, 153.01488],
  [-27.46021, 153.01478]
];

const enoggera = [
  [-27.43230, 153.00508],
  [-27.43152, 153.00507],
  [-27.43099, 153.00517],
  [-27.43052, 153.00531],
  [-27.43024, 153.00532],
  [-27.42996, 153.00522],
  [-27.42972, 153.00500],
  [-27.42862, 153.00356],
  [-27.42823, 153.00318],
  [-27.42750, 153.00256],
  [-27.42571, 153.00139],
  [-27.42555, 153.00121],
  [-27.42540, 153.00094],
  [-27.42402, 152.99763],
  [-27.42391, 152.99734],
  [-27.42385, 152.99703],
  [-27.42374, 152.99591],
  [-27.42297, 152.99237],
  [-27.42277, 152.99173],
  [-27.42251, 152.99099]
];

export const west3: Line = {
  name: 'West 3 ',
  state: 'QLD',
  segments: [
    {
      segments: [kelvinGrove],
      history: {
        opened: {
          date: '1901-08-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bowenBridgeExtension],
      history: {
        opened: {
          date: '1901-05-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newmarket],
      history: {
        opened: {
          date: '1903-07-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [banksStreet],
      history: {
        opened: {
          date: '1926-09-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [countessStreetNorthEastJunction],
      history: {
        opened: {
          date: '1903-02-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1939-08-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [countessStreetSouthEastJunction],
      history: {
        opened: {
          date: '1939-08-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1947-07-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [enoggera],
      history: {
        opened: {
          date: '1949-08-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
