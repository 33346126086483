import { Line } from "../../../trackTypes";

const colacBeechForest = [
  [-38.3440, 143.5877],
  [-38.3441, 143.5885],
  [-38.3441, 143.5885],
  [-38.3445, 143.5891],
  [-38.3450, 143.5892],
  [-38.3485, 143.5886],
  [-38.3496, 143.5886],
  [-38.3507, 143.5889],
  [-38.3582, 143.5927],
  [-38.3591, 143.5932],
  [-38.3665, 143.5964],
  [-38.3669, 143.5964],
  [-38.3675, 143.5963],
  [-38.3680, 143.5964],
  [-38.3684, 143.5967],
  [-38.3688, 143.5969],
  [-38.3707, 143.5975],
  [-38.3718, 143.5975],
  [-38.3733, 143.5966],
  [-38.3745, 143.5952],
  [-38.3752, 143.5925],
  [-38.3757, 143.5906],
  [-38.3763, 143.5898],
  [-38.3771, 143.5891],
  [-38.3791, 143.5859],
  [-38.3795, 143.5857],
  [-38.3799, 143.5858],
  [-38.3801, 143.5862],
  [-38.3802, 143.5871],
  [-38.3806, 143.5881],
  [-38.3814, 143.5886],
  [-38.3833, 143.5887],
  [-38.3842, 143.5885],
  [-38.3862, 143.5872],
  [-38.3927, 143.5856],
  [-38.3932, 143.5856],
  [-38.3935, 143.5858],
  [-38.3937, 143.5862],
  [-38.3942, 143.5887],
  [-38.3950, 143.5898],
  [-38.3984, 143.5909],
  [-38.3994, 143.5920],
  [-38.4000, 143.5934],
  [-38.4008, 143.5939],
  [-38.4019, 143.5936],
  [-38.4045, 143.5928],
  [-38.4110, 143.5920],
  [-38.4127, 143.5924],
  [-38.4164, 143.5954],
  [-38.4173, 143.5972],
  [-38.4194, 143.6046],
  [-38.4205, 143.6063],
  [-38.4261, 143.6091],
  [-38.4277, 143.6105],
  [-38.4284, 143.6117],
  [-38.4289, 143.6128],
  [-38.4298, 143.6132],
  [-38.4305, 143.6131],
  [-38.4349, 143.6111],
  [-38.4360, 143.6096],
  [-38.4364, 143.6077],
  [-38.4367, 143.6041],
  [-38.4370, 143.6031],
  [-38.4392, 143.5998],
  [-38.4395, 143.5988],
  [-38.4396, 143.5971],
  [-38.4393, 143.5964],
  [-38.4388, 143.5950],
  [-38.4386, 143.5935],
  [-38.4391, 143.5914],
  [-38.4401, 143.5889],
  [-38.4403, 143.5887],
  [-38.4405, 143.5888],
  [-38.4411, 143.5908],
  [-38.4415, 143.5913],
  [-38.4422, 143.5914],
  [-38.4432, 143.5914],
  [-38.4443, 143.5917],
  [-38.4460, 143.5918],
  [-38.4471, 143.5916],
  [-38.4479, 143.5914],
  [-38.4484, 143.5914],
  [-38.4578, 143.5952],
  [-38.4590, 143.5955],
  [-38.4594, 143.5957],
  [-38.4602, 143.5968],
  [-38.4627, 143.6007],
  [-38.4649, 143.6046],
  [-38.4656, 143.6051],
  [-38.4661, 143.6052],
  [-38.4668, 143.6049],
  [-38.4673, 143.6049],
  [-38.4704, 143.6064],
  [-38.4715, 143.6065],
  [-38.4754, 143.6058],
  [-38.4783, 143.6046],
  [-38.4798, 143.6031],
  [-38.4804, 143.6015],
  [-38.4816, 143.5905],
  [-38.4812, 143.5862],
  [-38.4814, 143.5846],
  [-38.4861, 143.5733],
  [-38.4866, 143.5725],
  [-38.4901, 143.5694],
  [-38.4921, 143.5680],
  [-38.4932, 143.5677],
  [-38.4947, 143.5673],
  [-38.4960, 143.5666],
  [-38.4975, 143.5652],
  [-38.4988, 143.5628],
  [-38.5001, 143.5588],
  [-38.5025, 143.5537],
  [-38.5054, 143.5515],
  [-38.5112, 143.5411],
  [-38.5125, 143.5403],
  [-38.5182, 143.5384],
  [-38.5193, 143.5383],
  [-38.5252, 143.5410],
  [-38.5261, 143.5410],
  [-38.5335, 143.5394],
  [-38.5365, 143.5384],
  [-38.5368, 143.5379],
  [-38.5374, 143.5359],
  [-38.5377, 143.5351],
  [-38.5384, 143.5344],
  [-38.5389, 143.5330],
  [-38.5400, 143.5314],
  [-38.5403, 143.5311],
  [-38.5405, 143.5311],
  [-38.5411, 143.5313],
  [-38.5422, 143.5328],
  [-38.5431, 143.5329],
  [-38.5431, 143.5331],
  [-38.5432, 143.5336],
  [-38.5434, 143.5338],
  [-38.5436, 143.5339],
  [-38.5441, 143.5340],
  [-38.5444, 143.5345],
  [-38.5447, 143.5346],
  [-38.5454, 143.5347],
  [-38.5465, 143.5362],
  [-38.5474, 143.5366],
  [-38.5478, 143.5379],
  [-38.5481, 143.5382],
  [-38.5486, 143.5387],
  [-38.5487, 143.5395],
  [-38.5484, 143.5401],
  [-38.5486, 143.5410],
  [-38.5499, 143.5413],
  [-38.5505, 143.5417],
  [-38.5513, 143.5436],
  [-38.5514, 143.5445],
  [-38.5520, 143.5449],
  [-38.5522, 143.5454],
  [-38.5527, 143.5457],
  [-38.5530, 143.5455],
  [-38.5535, 143.5444],
  [-38.5544, 143.5432],
  [-38.5547, 143.5431],
  [-38.5550, 143.5433],
  [-38.5558, 143.5452],
  [-38.5562, 143.5454],
  [-38.5581, 143.5453],
  [-38.5586, 143.5454],
  [-38.5591, 143.5466],
  [-38.5601, 143.5474],
  [-38.5605, 143.5486],
  [-38.5612, 143.5493],
  [-38.5644, 143.5509],
  [-38.5651, 143.5512],
  [-38.5659, 143.5512],
  [-38.5663, 143.5508],
  [-38.5663, 143.5497],
  [-38.5666, 143.5494],
  [-38.5671, 143.5499],
  [-38.5677, 143.5497],
  [-38.5679, 143.5498],
  [-38.5689, 143.5514],
  [-38.5688, 143.5535],
  [-38.5693, 143.5539],
  [-38.5698, 143.5535],
  [-38.5700, 143.5533],
  [-38.5703, 143.5534],
  [-38.5711, 143.5554],
  [-38.5714, 143.5558],
  [-38.5718, 143.5556],
  [-38.5725, 143.5551],
  [-38.5729, 143.5556],
  [-38.5735, 143.5571],
  [-38.5732, 143.5579],
  [-38.5728, 143.5583],
  [-38.5728, 143.5589],
  [-38.5731, 143.5592],
  [-38.5754, 143.5600],
  [-38.5761, 143.5603],
  [-38.5769, 143.5612],
  [-38.5775, 143.5616],
  [-38.5788, 143.5616],
  [-38.5799, 143.5612],
  [-38.5822, 143.5619],
  [-38.5826, 143.5620],
  [-38.5833, 143.5618],
  [-38.5840, 143.5618],
  [-38.5847, 143.5622],
  [-38.5871, 143.5669],
  [-38.5875, 143.5675],
  [-38.5878, 143.5676],
  [-38.5881, 143.5672],
  [-38.5890, 143.5651],
  [-38.5891, 143.5633],
  [-38.5896, 143.5630],
  [-38.5897, 143.5609],
  [-38.5900, 143.5605],
  [-38.5904, 143.5607],
  [-38.5923, 143.5630],
  [-38.5926, 143.5630],
  [-38.5930, 143.5627],
  [-38.5936, 143.5615],
  [-38.5939, 143.5613],
  [-38.5943, 143.5614],
  [-38.5947, 143.5611],
  [-38.5950, 143.5611],
  [-38.5952, 143.5619],
  [-38.5951, 143.5629],
  [-38.5953, 143.5647],
  [-38.5967, 143.5665],
  [-38.5968, 143.5675],
  [-38.5984, 143.5685],
  [-38.5996, 143.5686],
  [-38.5999, 143.5683],
  [-38.5999, 143.5671],
  [-38.6000, 143.5666],
  [-38.6008, 143.5658],
  [-38.6011, 143.5653],
  [-38.6014, 143.5632],
  [-38.6020, 143.5594],
  [-38.6023, 143.5590],
  [-38.6027, 143.5593],
  [-38.6030, 143.5592],
  [-38.6042, 143.5577],
  [-38.6051, 143.5578],
  [-38.6059, 143.5588],
  [-38.6064, 143.5620],
  [-38.6064, 143.5621],
  [-38.6067, 143.5625],
  [-38.6074, 143.5628],
  [-38.6092, 143.5639],
  [-38.6113, 143.5642],
  [-38.6119, 143.5645],
  [-38.6132, 143.5658],
  [-38.6141, 143.5664],
  [-38.6157, 143.5666],
  [-38.6173, 143.5674],
  [-38.6175, 143.5677],
  [-38.6175, 143.5685],
  [-38.6178, 143.5694],
  [-38.6187, 143.5706],
  [-38.6187, 143.5712],
  [-38.6172, 143.5733],
  [-38.6158, 143.5741],
  [-38.6155, 143.5745],
  [-38.6156, 143.5752],
  [-38.6166, 143.5762],
  [-38.6173, 143.5763],
  [-38.6184, 143.5747],
  [-38.6191, 143.5747],
  [-38.6202, 143.5753],
  [-38.6223, 143.5767],
  [-38.6237, 143.5772],
  [-38.6256, 143.5781],
  [-38.6270, 143.5783],
  [-38.6280, 143.5777],
  [-38.6286, 143.5766],
  [-38.6291, 143.5764],
  [-38.6296, 143.5751],
  [-38.6296, 143.5742],
  [-38.6299, 143.5736],
  [-38.6310, 143.5702],
  [-38.6317, 143.5694],
  [-38.6321, 143.5693],
  [-38.6339, 143.5693],
  [-38.6343, 143.5692],
  [-38.6348, 143.5689],
  [-38.6358, 143.5685],
  [-38.6365, 143.5691],
  [-38.6367, 143.5696],
  [-38.6368, 143.5722],
  [-38.6361, 143.5750],
  [-38.6358, 143.5753],
  [-38.6357, 143.5757],
  [-38.6358, 143.5761],
  [-38.6361, 143.5762],
  [-38.6363, 143.5760],
  [-38.6363, 143.5756],
  [-38.6361, 143.5750]
];

const beechForestFerguson = [
  [-38.6358, 143.5685],
  [-38.6354, 143.5678],
  [-38.6352, 143.5668],
  [-38.6342, 143.5652],
  [-38.6337, 143.5642],
  [-38.6336, 143.5625],
  [-38.6338, 143.5604],
  [-38.6335, 143.5588],
  [-38.6326, 143.5574],
  [-38.6319, 143.5569],
  [-38.6316, 143.5559],
  [-38.6317, 143.5548],
  [-38.6329, 143.5482],
  [-38.6327, 143.5467],
  [-38.6322, 143.5457],
  [-38.6320, 143.5439],
  [-38.6323, 143.5424],
  [-38.6322, 143.5413],
  [-38.6322, 143.5403],
  [-38.6325, 143.5394],
  [-38.6325, 143.5387],
  [-38.6323, 143.5379],
  [-38.6323, 143.5369],
  [-38.6322, 143.5366],
  [-38.6316, 143.5361],
  [-38.6316, 143.5354],
  [-38.6326, 143.5303],
  [-38.6324, 143.5299],
  [-38.6321, 143.5298],
  [-38.6319, 143.5298],
  [-38.6312, 143.5303],
  [-38.6308, 143.5301],
  [-38.6307, 143.5296],
  [-38.6308, 143.5281]
];

const fergusonWeeaproninah = [
  [-38.6308, 143.5281],
  [-38.6308, 143.5267],
  [-38.6307, 143.5261],
  [-38.6307, 143.5240],
  [-38.6308, 143.5223],
  [-38.6316, 143.5209],
  [-38.6320, 143.5205],
  [-38.6321, 143.5200],
  [-38.6319, 143.5193],
  [-38.6320, 143.5187],
  [-38.6325, 143.5181],
  [-38.6326, 143.5173],
  [-38.6327, 143.5141],
  [-38.6329, 143.5131],
  [-38.6334, 143.5115],
  [-38.6333, 143.5106],
  [-38.6326, 143.5083],
  [-38.6322, 143.5076],
  [-38.6322, 143.5070],
  [-38.6324, 143.5066],
  [-38.6335, 143.5044],
  [-38.6349, 143.5029],
  [-38.6354, 143.5010],
  [-38.6366, 143.4998],
  [-38.6369, 143.4994],
  [-38.6375, 143.4976]
];

const weeaproninahCrowes = [
  [-38.6375, 143.4976],
  [-38.6374, 143.4946],
  [-38.6373, 143.4939],
  [-38.6369, 143.4930],
  [-38.6368, 143.4923],
  [-38.6375, 143.4884],
  [-38.6375, 143.4877],
  [-38.6373, 143.4847],
  [-38.6373, 143.4836],
  [-38.6377, 143.4815],
  [-38.6377, 143.4809],
  [-38.6375, 143.4804],
  [-38.6374, 143.4800],
  [-38.6376, 143.4794],
  [-38.6382, 143.4789],
  [-38.6390, 143.4788],
  [-38.6394, 143.4785],
  [-38.6405, 143.4772],
  [-38.6414, 143.4769],
  [-38.6417, 143.4769],
  [-38.6430, 143.4763],
  [-38.6470, 143.4729],
  [-38.6493, 143.4678],
  [-38.6500, 143.4674],
  [-38.6504, 143.4670],
  [-38.6502, 143.4659],
  [-38.6505, 143.4643],
  [-38.6505, 143.4614],
  [-38.6507, 143.4605],
  [-38.6544, 143.4601],
  [-38.6551, 143.4594],
  [-38.6566, 143.4598],
  [-38.6594, 143.4584],
  [-38.6596, 143.4576],
  [-38.6597, 143.4564],
  [-38.6609, 143.4526],
  [-38.6607, 143.4518],
  [-38.6608, 143.4512],
  [-38.6618, 143.4502],
  [-38.6620, 143.4482],
  [-38.6635, 143.4448],
  [-38.6639, 143.4427],
  [-38.6639, 143.4417],
  [-38.6638, 143.4368],
  [-38.6639, 143.4357],
  [-38.6666, 143.4282],
  [-38.6666, 143.4268],
  [-38.6660, 143.4247],
  [-38.6656, 143.4216],
  [-38.6658, 143.4209],
  [-38.6656, 143.4193],
  [-38.6640, 143.4186],
  [-38.6638, 143.4181],
  [-38.6649, 143.4155],
  [-38.6665, 143.4136],
  [-38.6675, 143.4119],
  [-38.6693, 143.4098],
  [-38.6715, 143.4084],
  [-38.6727, 143.4075],
  [-38.6735, 143.4066],
  [-38.6744, 143.4043],
  [-38.6750, 143.4037],
  [-38.6774, 143.4040],
  [-38.6779, 143.4033],
  [-38.6775, 143.4028],
  [-38.6767, 143.4028],
  [-38.6767, 143.4025],
  [-38.6776, 143.3996],
  [-38.6782, 143.3990],
  [-38.6793, 143.3967],
  [-38.6793, 143.3961],
  [-38.6788, 143.3953],
  [-38.6786, 143.3940],
  [-38.6787, 143.3937],
  [-38.6800, 143.3914],
  [-38.6812, 143.3913],
  [-38.6815, 143.3911],
  [-38.6822, 143.3904],
  [-38.6825, 143.3902],
  [-38.6827, 143.3905],
  [-38.6828, 143.3915],
  [-38.6830, 143.3918],
  [-38.6834, 143.3917],
  [-38.6835, 143.3899],
  [-38.6834, 143.3895],
  [-38.6836, 143.3890],
  [-38.6836, 143.3886],
  [-38.6833, 143.3882],
  [-38.6833, 143.3878],
  [-38.6836, 143.3871],
  [-38.6837, 143.3869],
  [-38.6833, 143.3858],
  [-38.6835, 143.3853],
  [-38.6834, 143.3844],
  [-38.6832, 143.3841],
  [-38.6824, 143.3837],
  [-38.6823, 143.3833],
  [-38.6825, 143.3828],
  [-38.6832, 143.3824],
  [-38.6836, 143.3821],
  [-38.6839, 143.3814],
  [-38.6843, 143.3794],
  [-38.6846, 143.3787],
  [-38.6866, 143.3776],
  [-38.6868, 143.3773],
  [-38.6872, 143.3747],
  [-38.6868, 143.3740],
  [-38.6869, 143.3737],
  [-38.6877, 143.3716],
  [-38.6878, 143.3711],
  [-38.6876, 143.3696],
  [-38.6878, 143.3690],
  [-38.6887, 143.3678],
  [-38.6901, 143.3676],
  [-38.6905, 143.3673],
  [-38.6908, 143.3668]
];

export const crowes: Line = {
  name: 'Crowes',
  state: 'VIC',
  segments: [
    {
      segments: [colacBeechForest],
      history: {
        opened: {
          date: '1902-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [beechForestFerguson],
      history: {
        opened: {
          date: '1911-06-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fergusonWeeaproninah],
      history: {
        opened: {
          date: '1911-06-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-01-19',
          status: 'open'
        }, {
          date: '1954-12-10',
          status: 'closed'
        }, {
          date: '1962-06-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [weeaproninahCrowes],
      history: {
        opened: {
          date: '1911-06-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-12-10',
          status: 'closed'
        }]
      }
    }
  ]
};
