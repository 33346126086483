import { Line } from "../../trackTypes";

const line = [
  [-38.2094, 143.0882],
  [-38.2098, 143.0870],
  [-38.2104, 143.0862],
  [-38.2114, 143.0853],
  [-38.2125, 143.0846],
  [-38.2137, 143.0841],
  [-38.2406, 143.0791],
  [-38.2436, 143.0789],
  [-38.2461, 143.0797],
  [-38.2475, 143.0805],
  [-38.2490, 143.0819],
  [-38.2506, 143.0828],
  [-38.2526, 143.0833],
  [-38.2542, 143.0832],
  [-38.2935, 143.0727],
  [-38.2968, 143.0727],
  [-38.2986, 143.0731],
  [-38.3025, 143.0743],
  [-38.3046, 143.0746],
  [-38.3072, 143.0743],
  [-38.3085, 143.0745],
  [-38.3104, 143.0754],
  [-38.3118, 143.0756],
  [-38.3131, 143.0754],
  [-38.3211, 143.0728],
  [-38.3231, 143.0715],
  [-38.3245, 143.0691],
  [-38.3264, 143.0620],
  [-38.3277, 143.0589],
  [-38.3293, 143.0564],
  [-38.3733, 143.0162],
  [-38.3747, 143.0154],
  [-38.3797, 143.0111],
  [-38.4097, 142.9800],
  [-38.4111, 142.9792],
  [-38.4127, 142.9791],
  [-38.4144, 142.9795],
  [-38.4154, 142.9793],
  [-38.4166, 142.9783],
  [-38.4179, 142.9749],
  [-38.4195, 142.9726],
  [-38.4207, 142.9688],
  [-38.4214, 142.9674],
  [-38.4226, 142.9665],
  [-38.4263, 142.9651],
  [-38.4282, 142.9636],
  [-38.4292, 142.9621],
  [-38.4294, 142.9571],
  [-38.4300, 142.9554],
  [-38.4315, 142.9550],
  [-38.4331, 142.9557],
  [-38.4356, 142.9558],
  [-38.4371, 142.9546],
  [-38.4389, 142.9549],
  [-38.4447, 142.9593],
  [-38.4529, 142.9648],
  [-38.4556, 142.9661],
  [-38.4634, 142.9687],
  [-38.4683, 142.9715],
  [-38.4696, 142.9719],
  [-38.4785, 142.9712],
  [-38.4800, 142.9725],
  [-38.4834, 142.9791]
];

export const timboon: Line = {
  name: 'Timboon',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1892-04-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    }
  ]
};
