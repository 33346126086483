import { Line } from "../../../trackTypes";

const warwickEmuVale = [
  [-28.2067, 152.0416],
  [-28.2070, 152.0421],
  [-28.2070, 152.0427],
  [-28.2070, 152.0434],
  [-28.2074, 152.0466],
  [-28.2077, 152.0479],
  [-28.2081, 152.0484],
  [-28.2085, 152.0486],
  [-28.2107, 152.0483],
  [-28.2121, 152.0488],
  [-28.2126, 152.0493],
  [-28.2131, 152.0504],
  [-28.2135, 152.0508],
  [-28.2142, 152.0515],
  [-28.2146, 152.0520],
  [-28.2149, 152.0542],
  [-28.2149, 152.0557],
  [-28.2139, 152.0614],
  [-28.2126, 152.0655],
  [-28.2124, 152.0668],
  [-28.2120, 152.0741],
  [-28.2140, 152.0864],
  [-28.2140, 152.0874],
  [-28.2137, 152.0882],
  [-28.1934, 152.1191],
  [-28.1931, 152.1201],
  [-28.1929, 152.1213],
  [-28.1936, 152.1302],
  [-28.1934, 152.1334],
  [-28.1934, 152.1344],
  [-28.1954, 152.1499],
  [-28.1960, 152.1520],
  [-28.1961, 152.1538],
  [-28.1964, 152.1546],
  [-28.1972, 152.1555],
  [-28.2008, 152.1635],
  [-28.2035, 152.1811],
  [-28.2030, 152.1828],
  [-28.2012, 152.1844],
  [-28.2003, 152.1856],
  [-28.2002, 152.1862],
  [-28.2005, 152.1866],
  [-28.2011, 152.1869],
  [-28.2019, 152.1870],
  [-28.2025, 152.1872],
  [-28.2032, 152.1878],
  [-28.2046, 152.1911],
  [-28.2048, 152.1926],
  [-28.2040, 152.1957],
  [-28.2040, 152.1967],
  [-28.2043, 152.1996],
  [-28.2042, 152.2012],
  [-28.2034, 152.2033],
  [-28.2027, 152.2041],
  [-28.2018, 152.2045],
  [-28.2008, 152.2044],
  [-28.1985, 152.2032],
  [-28.1977, 152.2032],
  [-28.1967, 152.2034],
  [-28.1949, 152.2048],
  [-28.1945, 152.2056],
  [-28.1945, 152.2062],
  [-28.1947, 152.2068],
  [-28.1961, 152.2083],
  [-28.1985, 152.2105],
  [-28.1990, 152.2117],
  [-28.1995, 152.2136],
  [-28.1994, 152.2148],
  [-28.1996, 152.2166],
  [-28.1997, 152.2173],
  [-28.2000, 152.2182],
  [-28.2005, 152.2189],
  [-28.2019, 152.2204],
  [-28.2026, 152.2216],
  [-28.2031, 152.2252],
  [-28.2035, 152.2258],
  [-28.2041, 152.2262],
  [-28.2054, 152.2264],
  [-28.2064, 152.2270],
  [-28.2114, 152.2309],
  [-28.2137, 152.2323],
  [-28.2142, 152.2335],
  [-28.2137, 152.2348],
  [-28.2129, 152.2355],
  [-28.2124, 152.2365],
  [-28.2120, 152.2388],
  [-28.2123, 152.2400],
  [-28.2133, 152.2421],
  [-28.2137, 152.2426],
  [-28.2152, 152.2436],
  [-28.2156, 152.2441],
  [-28.2161, 152.2451],
  [-28.2168, 152.2460],
  [-28.2196, 152.2489],
  [-28.2203, 152.2491],
  [-28.2223, 152.2491],
  [-28.2239, 152.2488]
];

const emuValeKillarney = [
  [-28.2239, 152.2488],
  [-28.2258, 152.2483],
  [-28.2272, 152.2479],
  [-28.2283, 152.2477],
  [-28.2294, 152.2469],
  [-28.2436, 152.2264],
  [-28.2445, 152.2259],
  [-28.2469, 152.2259],
  [-28.2482, 152.2265],
  [-28.2491, 152.2274],
  [-28.2499, 152.2279],
  [-28.2513, 152.2284],
  [-28.2523, 152.2292],
  [-28.2530, 152.2304],
  [-28.2537, 152.2322],
  [-28.2543, 152.2328],
  [-28.2552, 152.2331],
  [-28.2565, 152.2331],
  [-28.2573, 152.2328],
  [-28.2594, 152.2313],
  [-28.2603, 152.2298],
  [-28.2605, 152.2282],
  [-28.2609, 152.2274],
  [-28.2615, 152.2269],
  [-28.2625, 152.2263],
  [-28.2630, 152.2255],
  [-28.2636, 152.2236],
  [-28.2643, 152.2227],
  [-28.2656, 152.2221],
  [-28.2675, 152.2221],
  [-28.2683, 152.2225],
  [-28.2687, 152.2231],
  [-28.2692, 152.2238],
  [-28.2697, 152.2242],
  [-28.2710, 152.2249],
  [-28.2719, 152.2257],
  [-28.2740, 152.2290],
  [-28.2744, 152.2293],
  [-28.2749, 152.2294],
  [-28.2763, 152.2292],
  [-28.2772, 152.2294],
  [-28.2778, 152.2298],
  [-28.2784, 152.2306],
  [-28.2791, 152.2310],
  [-28.2799, 152.2310],
  [-28.2809, 152.2308],
  [-28.2815, 152.2308],
  [-28.2823, 152.2312],
  [-28.2846, 152.2340],
  [-28.2854, 152.2344],
  [-28.2864, 152.2344],
  [-28.2876, 152.2342],
  [-28.2882, 152.2343],
  [-28.2889, 152.2348],
  [-28.2892, 152.2356],
  [-28.2893, 152.2386],
  [-28.2891, 152.2394],
  [-28.2884, 152.2408],
  [-28.2881, 152.2417],
  [-28.2882, 152.2427],
  [-28.2892, 152.2451],
  [-28.2896, 152.2464],
  [-28.2907, 152.2478],
  [-28.2967, 152.2510],
  [-28.2982, 152.2513],
  [-28.2993, 152.2518],
  [-28.3012, 152.2537],
  [-28.3025, 152.2537],
  [-28.3052, 152.2586],
  [-28.3133, 152.2700],
  [-28.3135, 152.2708],
  [-28.3133, 152.2716],
  [-28.3129, 152.2726],
  [-28.3129, 152.2736],
  [-28.3134, 152.2744],
  [-28.3144, 152.2747],
  [-28.3180, 152.2736],
  [-28.3191, 152.2739],
  [-28.3222, 152.2771],
  [-28.3233, 152.2776],
  [-28.3245, 152.2774],
  [-28.3253, 152.2778],
  [-28.3258, 152.2785],
  [-28.3264, 152.2816],
  [-28.3271, 152.2835],
  [-28.3300, 152.2882],
  [-28.3306, 152.2897],
  [-28.3318, 152.2940]
];

export const killarney: Line = {
  name: 'Killarney',
  state: 'QLD',
  segments: [
    {
      segments: [warwickEmuVale],
      history: {
        opened: {
          date: '1884-06-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [emuValeKillarney],
      history: {
        opened: {
          date: '1885-08-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-05-01',
          status: 'closed'
        }]
      }
    }
  ]
}
