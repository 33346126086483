import { Line } from "../../../trackTypes";

const walkurakaLowood = [
  [-27.6118, 152.7254],
  [-27.6113, 152.7247],
  [-27.6106, 152.7242],
  [-27.6101, 152.7241],
  [-27.6095, 152.7242],
  [-27.6051, 152.7260],
  [-27.6035, 152.7271],
  [-27.6022, 152.7283],
  [-27.6015, 152.7286],
  [-27.5996, 152.7286],
  [-27.5991, 152.7285],
  [-27.5932, 152.7256],
  [-27.5926, 152.7250],
  [-27.5906, 152.7229],
  [-27.5864, 152.7204],
  [-27.5845, 152.7201],
  [-27.5837, 152.7203],
  [-27.5829, 152.7208],
  [-27.5824, 152.7209],
  [-27.5807, 152.7204],
  [-27.5774, 152.7203],
  [-27.5769, 152.7201],
  [-27.5765, 152.7197],
  [-27.5758, 152.7188],
  [-27.5752, 152.7185],
  [-27.5745, 152.7184],
  [-27.5737, 152.7186],
  [-27.5731, 152.7189],
  [-27.5727, 152.7189],
  [-27.5723, 152.7185],
  [-27.5720, 152.7178],
  [-27.5717, 152.7174],
  [-27.5676, 152.7152],
  [-27.5668, 152.7151],
  [-27.5661, 152.7147],
  [-27.5658, 152.7140],
  [-27.5659, 152.7134],
  [-27.5663, 152.7128],
  [-27.5664, 152.7122],
  [-27.5660, 152.7116],
  [-27.5643, 152.7111],
  [-27.5636, 152.7112],
  [-27.5631, 152.7114],
  [-27.5625, 152.7113],
  [-27.5621, 152.7106],
  [-27.5619, 152.7098],
  [-27.5620, 152.7090],
  [-27.5618, 152.7084],
  [-27.5615, 152.7081],
  [-27.5606, 152.7077],
  [-27.5595, 152.7067],
  [-27.5593, 152.7063],
  [-27.5591, 152.7056],
  [-27.5588, 152.7051],
  [-27.5584, 152.7050],
  [-27.5579, 152.7050],
  [-27.5535, 152.7029],
  [-27.5528, 152.7027],
  [-27.5520, 152.7028],
  [-27.5514, 152.7025],
  [-27.5511, 152.7019],
  [-27.5511, 152.7011],
  [-27.5507, 152.7005],
  [-27.5501, 152.7004],
  [-27.5492, 152.7006],
  [-27.5487, 152.7005],
  [-27.5484, 152.7000],
  [-27.5483, 152.6989],
  [-27.5479, 152.6984],
  [-27.5463, 152.6972],
  [-27.5449, 152.6967],
  [-27.5443, 152.6961],
  [-27.5439, 152.6954],
  [-27.5434, 152.6950],
  [-27.5416, 152.6942],
  [-27.5399, 152.6938],
  [-27.5342, 152.6913],
  [-27.5312, 152.6907],
  [-27.5304, 152.6903],
  [-27.5294, 152.6894],
  [-27.5285, 152.6891],
  [-27.5272, 152.6892],
  [-27.5266, 152.6891],
  [-27.5250, 152.6885],
  [-27.5242, 152.6879],
  [-27.5238, 152.6871],
  [-27.5235, 152.6863],
  [-27.5233, 152.6828],
  [-27.5237, 152.6803],
  [-27.5236, 152.6795],
  [-27.5227, 152.6782],
  [-27.5215, 152.6772],
  [-27.5196, 152.6762],
  [-27.5189, 152.6756],
  [-27.5165, 152.6718],
  [-27.5135, 152.6684],
  [-27.5130, 152.6675],
  [-27.5126, 152.6671],
  [-27.5100, 152.6665],
  [-27.5091, 152.6664],
  [-27.5087, 152.6662],
  [-27.5084, 152.6658],
  [-27.5083, 152.6653],
  [-27.5080, 152.6649],
  [-27.5075, 152.6647],
  [-27.5070, 152.6649],
  [-27.5066, 152.6653],
  [-27.5063, 152.6659],
  [-27.5058, 152.6664],
  [-27.5053, 152.6666],
  [-27.5048, 152.6664],
  [-27.5030, 152.6655],
  [-27.5026, 152.6650],
  [-27.5021, 152.6642],
  [-27.5015, 152.6638],
  [-27.5007, 152.6638],
  [-27.5002, 152.6636],
  [-27.4991, 152.6629],
  [-27.4979, 152.6625],
  [-27.4969, 152.6624],
  [-27.4961, 152.6621],
  [-27.4952, 152.6613],
  [-27.4941, 152.6610],
  [-27.4932, 152.6611],
  [-27.4923, 152.6618],
  [-27.4916, 152.6620],
  [-27.4895, 152.6623],
  [-27.4883, 152.6622],
  [-27.4873, 152.6618],
  [-27.4840, 152.6616],
  [-27.4830, 152.6619],
  [-27.4811, 152.6628],
  [-27.4806, 152.6635],
  [-27.4803, 152.6646],
  [-27.4798, 152.6653],
  [-27.4793, 152.6656],
  [-27.4771, 152.6660],
  [-27.4762, 152.6666],
  [-27.4757, 152.6666],
  [-27.4751, 152.6664],
  [-27.4720, 152.6635],
  [-27.4703, 152.6624],
  [-27.4696, 152.6622],
  [-27.4689, 152.6622],
  [-27.4681, 152.6619],
  [-27.4672, 152.6610],
  [-27.4668, 152.6609],
  [-27.4664, 152.6609],
  [-27.4658, 152.6613],
  [-27.4653, 152.6613],
  [-27.4603, 152.6580],
  [-27.4597, 152.6571],
  [-27.4595, 152.6562],
  [-27.4597, 152.6552],
  [-27.4596, 152.6545],
  [-27.4592, 152.6541],
  [-27.4564, 152.6529],
  [-27.4560, 152.6526],
  [-27.4553, 152.6517],
  [-27.4540, 152.6488],
  [-27.4536, 152.6473],
  [-27.4539, 152.6411],
  [-27.4536, 152.6398],
  [-27.4528, 152.6385],
  [-27.4503, 152.6362],
  [-27.4497, 152.6353],
  [-27.4497, 152.6344],
  [-27.4502, 152.6325],
  [-27.4509, 152.6311],
  [-27.4596, 152.6222],
  [-27.4621, 152.6180],
  [-27.4645, 152.6108],
  [-27.4652, 152.6093],
  [-27.4671, 152.6062],
  [-27.4673, 152.6054],
  [-27.4671, 152.6048],
  [-27.4667, 152.6044],
  [-27.4665, 152.6038],
  [-27.4667, 152.6031],
  [-27.4677, 152.6020],
  [-27.4682, 152.6012],
  [-27.4688, 152.5998],
  [-27.4700, 152.5981],
  [-27.4703, 152.5968],
  [-27.4707, 152.5954],
  [-27.4710, 152.5934],
  [-27.4708, 152.5916],
  [-27.4699, 152.5902],
  [-27.4698, 152.5897],
  [-27.4698, 152.5893],
  [-27.4701, 152.5888],
  [-27.4700, 152.5879],
  [-27.4698, 152.5875],
  [-27.4687, 152.5864],
  [-27.4633, 152.5816]
];

const lowoodEsk = [
  [-27.4633, 152.5816],
  [-27.4591, 152.5780],
  [-27.4584, 152.5768],
  [-27.4577, 152.5751],
  [-27.4572, 152.5744],
  [-27.4532, 152.5701],
  [-27.4522, 152.5696],
  [-27.4515, 152.5695],
  [-27.4503, 152.5697],
  [-27.4493, 152.5694],
  [-27.4488, 152.5687],
  [-27.4486, 152.5679],
  [-27.4486, 152.5663],
  [-27.4482, 152.5651],
  [-27.4473, 152.5645],
  [-27.4467, 152.5635],
  [-27.4462, 152.5616],
  [-27.4442, 152.5588],
  [-27.4387, 152.5552],
  [-27.4379, 152.5545],
  [-27.4363, 152.5513],
  [-27.4361, 152.5502],
  [-27.4361, 152.5471],
  [-27.4349, 152.5445],
  [-27.4318, 152.5421],
  [-27.4284, 152.5384],
  [-27.4277, 152.5371],
  [-27.4270, 152.5367],
  [-27.4254, 152.5363],
  [-27.4233, 152.5338],
  [-27.4220, 152.5329],
  [-27.4180, 152.5283],
  [-27.4080, 152.5143],
  [-27.4069, 152.5133],
  [-27.3911, 152.5028],
  [-27.3903, 152.5021],
  [-27.3719, 152.4745],
  [-27.3699, 152.4726],
  [-27.3674, 152.4712],
  [-27.3633, 152.4703],
  [-27.3613, 152.4692],
  [-27.3588, 152.4671],
  [-27.3522, 152.4581],
  [-27.3502, 152.4564],
  [-27.3469, 152.4549],
  [-27.3439, 152.4523],
  [-27.3432, 152.4508],
  [-27.3425, 152.4482],
  [-27.3418, 152.4469],
  [-27.3399, 152.4462],
  [-27.3390, 152.4463],
  [-27.3366, 152.4478],
  [-27.3346, 152.4476],
  [-27.3336, 152.4464],
  [-27.3330, 152.4438],
  [-27.3323, 152.4425],
  [-27.3292, 152.4384],
  [-27.3265, 152.4371],
  [-27.3258, 152.4364],
  [-27.3253, 152.4354],
  [-27.3249, 152.4351],
  [-27.3240, 152.4349],
  [-27.3234, 152.4343],
  [-27.3233, 152.4335],
  [-27.3237, 152.4325],
  [-27.3235, 152.4314],
  [-27.3199, 152.4286],
  [-27.3185, 152.4280],
  [-27.3177, 152.4270],
  [-27.3175, 152.4261],
  [-27.3174, 152.4250],
  [-27.3168, 152.4243],
  [-27.3157, 152.4241],
  [-27.3149, 152.4235],
  [-27.3146, 152.4223],
  [-27.3149, 152.4212],
  [-27.3163, 152.4199],
  [-27.3166, 152.4192],
  [-27.3164, 152.4187],
  [-27.3154, 152.4175],
  [-27.3138, 152.4149],
  [-27.3138, 152.4139],
  [-27.3143, 152.4133],
  [-27.3154, 152.4131],
  [-27.3161, 152.4126],
  [-27.3167, 152.4103],
  [-27.3166, 152.4094],
  [-27.3162, 152.4087],
  [-27.3139, 152.4064],
  [-27.3132, 152.4061],
  [-27.3085, 152.4060],
  [-27.3066, 152.4063],
  [-27.3060, 152.4067],
  [-27.3058, 152.4074],
  [-27.3058, 152.4083],
  [-27.3053, 152.4092],
  [-27.3045, 152.4094],
  [-27.3032, 152.4086],
  [-27.3016, 152.4083],
  [-27.2997, 152.4090],
  [-27.2984, 152.4104],
  [-27.2952, 152.4120],
  [-27.2935, 152.4121],
  [-27.2926, 152.4128],
  [-27.2902, 152.4180],
  [-27.2884, 152.4200],
  [-27.2872, 152.4203],
  [-27.2811, 152.4170],
  [-27.2794, 152.4165],
  [-27.2737, 152.4169],
  [-27.2727, 152.4165],
  [-27.2714, 152.4150],
  [-27.2701, 152.4145],
  [-27.2666, 152.4147],
  [-27.2654, 152.4153],
  [-27.2636, 152.4175],
  [-27.2626, 152.4177],
  [-27.2563, 152.4163],
  [-27.2546, 152.4163],
  [-27.2428, 152.4194]
];

const eskToogoolawah = [
  [-27.2428, 152.4194],
  [-27.2389, 152.4204],
  [-27.2371, 152.4203],
  [-27.2222, 152.4135],
  [-27.2161, 152.4103],
  [-27.2053, 152.4072],
  [-27.2032, 152.4072],
  [-27.2004, 152.4077],
  [-27.1910, 152.4059],
  [-27.1886, 152.4064],
  [-27.1873, 152.4058],
  [-27.1864, 152.4044],
  [-27.1852, 152.4037],
  [-27.1841, 152.4041],
  [-27.1798, 152.4074],
  [-27.1780, 152.4077],
  [-27.1731, 152.4067],
  [-27.1704, 152.4047],
  [-27.1700, 152.4039],
  [-27.1698, 152.4030],
  [-27.1692, 152.4021],
  [-27.1678, 152.4013],
  [-27.1668, 152.4015],
  [-27.1656, 152.4022],
  [-27.1642, 152.4021],
  [-27.1635, 152.4011],
  [-27.1636, 152.3984],
  [-27.1630, 152.3966],
  [-27.1627, 152.3960],
  [-27.1609, 152.3941],
  [-27.1592, 152.3935],
  [-27.1572, 152.3936],
  [-27.1559, 152.3934],
  [-27.1532, 152.3923],
  [-27.1511, 152.3909],
  [-27.1488, 152.3898],
  [-27.1434, 152.3880],
  [-27.1419, 152.3872],
  [-27.1392, 152.3856],
  [-27.1350, 152.3819],
  [-27.1331, 152.3808],
  [-27.1285, 152.3798],
  [-27.1191, 152.3765],
  [-27.1158, 152.3758],
  [-27.1118, 152.3758],
  [-27.1052, 152.3767],
  [-27.1042, 152.3773],
  [-27.1037, 152.3781],
  [-27.1031, 152.3795],
  [-27.1022, 152.3803],
  [-27.1011, 152.3806],
  [-27.0909, 152.3783],
  [-27.0877, 152.3781]
];

const toogoolawahYimbun = [
  [-27.0877, 152.3781],
  [-27.0804, 152.3778],
  [-27.0689, 152.3772],
  [-27.0671, 152.3767],
  [-27.0620, 152.3736],
  [-27.0608, 152.3733],
  [-27.0564, 152.3737],
  [-27.0514, 152.3750],
  [-27.0504, 152.3750],
  [-27.0446, 152.3735],
  [-27.0433, 152.3726],
  [-27.0410, 152.3704],
  [-27.0387, 152.3688],
  [-27.0328, 152.3667],
  [-27.0301, 152.3664],
  [-27.0271, 152.3667]
];

const yimbunYimbunTunnel = [
  [-27.0271, 152.3667],
  [-27.0195, 152.3674],
  [-27.0141, 152.3673],
  [-27.0130, 152.3676],
  [-27.0122, 152.3681],
  [-27.0110, 152.3694],
  [-27.0105, 152.3697],
  [-27.0094, 152.3699],
  [-27.0088, 152.3704],
  [-27.0084, 152.3710],
  [-27.0081, 152.3724],
  [-27.0075, 152.3731],
  [-27.0068, 152.3733],
  [-27.0057, 152.3732],
  [-27.0050, 152.3735],
  [-27.0041, 152.3745],
  [-27.0035, 152.3748],
  [-27.0030, 152.3747],
  [-27.0018, 152.3744],
  [-27.0013, 152.3745],
  [-27.0004, 152.3749],
  [-26.9997, 152.3749],
  [-26.9970, 152.3739],
  [-26.9965, 152.3736]
];

const yimbunTunnel = [
  [-26.9965, 152.3736],
  [-26.9957, 152.3731]
];

const yimbunTunnelNurinda = [
  [-26.9957, 152.3731],
  [-26.9943, 152.3724],
  [-26.9934, 152.3722],
  [-26.9925, 152.3725],
  [-26.9920, 152.3729],
  [-26.9908, 152.3747],
  [-26.9901, 152.3751],
  [-26.9893, 152.3753],
  [-26.9884, 152.3759],
  [-26.9877, 152.3767],
  [-26.9868, 152.3771],
  [-26.9860, 152.3770],
  [-26.9792, 152.3714],
  [-26.9755, 152.3656],
  [-26.9738, 152.3606],
  [-26.9697, 152.3545],
  [-26.9687, 152.3537],
  [-26.9669, 152.3534],
  [-26.9640, 152.3534],
  [-26.9613, 152.3540],
  [-26.9596, 152.3540],
  [-26.9535, 152.3553],
  [-26.9519, 152.3563],
  [-26.9501, 152.3564],
  [-26.9463, 152.3541],
  [-26.9452, 152.3526],
  [-26.9442, 152.3518],
  [-26.9427, 152.3512],
  [-26.9416, 152.3503],
  [-26.9399, 152.3475],
  [-26.9394, 152.3454],
  [-26.9379, 152.3425],
  [-26.9366, 152.3413],
  [-26.9361, 152.3403],
  [-26.9360, 152.3390],
  [-26.9356, 152.3381],
  [-26.9349, 152.3376],
  [-26.9335, 152.3370],
  [-26.9326, 152.3363]
];

const nurindaLinville = [
  [-26.9326, 152.3363],
  [-26.9287, 152.3339],
  [-26.9277, 152.3324],
  [-26.9264, 152.3297],
  [-26.9256, 152.3287],
  [-26.9246, 152.3280],
  [-26.9231, 152.3277],
  [-26.9175, 152.3279],
  [-26.9158, 152.3274],
  [-26.9131, 152.3256],
  [-26.9117, 152.3252],
  [-26.9098, 152.3250],
  [-26.9082, 152.3244],
  [-26.9046, 152.3222],
  [-26.8993, 152.3182],
  [-26.8986, 152.3168],
  [-26.8985, 152.3154],
  [-26.8992, 152.3121],
  [-26.8992, 152.3108],
  [-26.8979, 152.3056],
  [-26.8977, 152.3038],
  [-26.8977, 152.3016],
  [-26.8981, 152.2988],
  [-26.8979, 152.2974],
  [-26.8973, 152.2964],
  [-26.8960, 152.2953],
  [-26.8947, 152.2936],
  [-26.8939, 152.2932],
  [-26.8931, 152.2931],
  [-26.8918, 152.2930],
  [-26.8913, 152.2932],
  [-26.8908, 152.2935],
  [-26.8878, 152.2964],
  [-26.8864, 152.2974],
  [-26.8830, 152.2987],
  [-26.8825, 152.2986],
  [-26.8820, 152.2984],
  [-26.8772, 152.2949],
  [-26.8760, 152.2943],
  [-26.8718, 152.2927],
  [-26.8690, 152.2910],
  [-26.8684, 152.2904],
  [-26.8677, 152.2893],
  [-26.8670, 152.2888],
  [-26.8630, 152.2871],
  [-26.8620, 152.2866],
  [-26.8612, 152.2865],
  [-26.8569, 152.2880],
  [-26.8558, 152.2880],
  [-26.8542, 152.2872],
  [-26.8531, 152.2861],
  [-26.8498, 152.2821],
  [-26.8488, 152.2814],
  [-26.8482, 152.2802],
  [-26.8483, 152.2786],
  [-26.8480, 152.2779],
  [-26.8475, 152.2773],
  [-26.8402, 152.2741]
];

const linvilleBenarkin = [
  [-26.8402, 152.2741],
  [-26.8397, 152.2736],
  [-26.8394, 152.2728],
  [-26.8395, 152.2721],
  [-26.8401, 152.2711],
  [-26.8402, 152.2704],
  [-26.8403, 152.2692],
  [-26.8406, 152.2684],
  [-26.8410, 152.2678],
  [-26.8424, 152.2670],
  [-26.8431, 152.2663],
  [-26.8438, 152.2650],
  [-26.8440, 152.2641],
  [-26.8447, 152.2590],
  [-26.8451, 152.2583],
  [-26.8461, 152.2575],
  [-26.8468, 152.2563],
  [-26.8483, 152.2549],
  [-26.8485, 152.2545],
  [-26.8486, 152.2539],
  [-26.8485, 152.2530],
  [-26.8490, 152.2521],
  [-26.8496, 152.2517],
  [-26.8507, 152.2518],
  [-26.8512, 152.2517],
  [-26.8515, 152.2512],
  [-26.8516, 152.2498],
  [-26.8519, 152.2490],
  [-26.8519, 152.2482],
  [-26.8510, 152.2468],
  [-26.8505, 152.2449],
  [-26.8506, 152.2443],
  [-26.8511, 152.2435],
  [-26.8512, 152.2429],
  [-26.8512, 152.2419],
  [-26.8521, 152.2399],
  [-26.8521, 152.2394],
  [-26.8520, 152.2389],
  [-26.8511, 152.2372],
  [-26.8510, 152.2367],
  [-26.8512, 152.2361],
  [-26.8519, 152.2352],
  [-26.8520, 152.2345],
  [-26.8517, 152.2339],
  [-26.8509, 152.2332],
  [-26.8507, 152.2327],
  [-26.8507, 152.2322],
  [-26.8510, 152.2313],
  [-26.8511, 152.2307],
  [-26.8508, 152.2299],
  [-26.8508, 152.2295],
  [-26.8510, 152.2291],
  [-26.8518, 152.2281],
  [-26.8536, 152.2268],
  [-26.8541, 152.2261],
  [-26.8543, 152.2255],
  [-26.8543, 152.2246],
  [-26.8545, 152.2241],
  [-26.8552, 152.2231],
  [-26.8554, 152.2216],
  [-26.8557, 152.2208],
  [-26.8557, 152.2198],
  [-26.8567, 152.2169],
  [-26.8572, 152.2163],
  [-26.8601, 152.2152],
  [-26.8607, 152.2146],
  [-26.8612, 152.2138],
  [-26.8616, 152.2123],
  [-26.8620, 152.2118],
  [-26.8628, 152.2118],
  [-26.8632, 152.2121],
  [-26.8637, 152.2123],
  [-26.8645, 152.2121],
  [-26.8655, 152.2112],
  [-26.8663, 152.2094],
  [-26.8667, 152.2089],
  [-26.8677, 152.2087],
  [-26.8680, 152.2085],
  [-26.8687, 152.2067],
  [-26.8695, 152.2056],
  [-26.8690, 152.2048],
  [-26.8693, 152.2042],
  [-26.8680, 152.2022],
  [-26.8679, 152.2018],
  [-26.8680, 152.2013],
  [-26.8684, 152.2007],
  [-26.8694, 152.1979],
  [-26.8693, 152.1967],
  [-26.8694, 152.1961],
  [-26.8698, 152.1954],
  [-26.8701, 152.1944],
  [-26.8708, 152.1938],
  [-26.8713, 152.1928],
  [-26.8723, 152.1919],
  [-26.8727, 152.1912],
  [-26.8730, 152.1903],
  [-26.8733, 152.1898],
  [-26.8740, 152.1894],
  [-26.8742, 152.1890],
  [-26.8743, 152.1885],
  [-26.8740, 152.1875],
  [-26.8742, 152.1870],
  [-26.8750, 152.1861],
  [-26.8753, 152.1851],
  [-26.8757, 152.1847],
  [-26.8768, 152.1842],
  [-26.8771, 152.1840],
  [-26.8778, 152.1827],
  [-26.8778, 152.1822],
  [-26.8777, 152.1811],
  [-26.8778, 152.1805],
  [-26.8802, 152.1777],
  [-26.8808, 152.1773],
  [-26.8834, 152.1764],
  [-26.8849, 152.1752],
  [-26.8862, 152.1736],
  [-26.8864, 152.1730],
  [-26.8862, 152.1724],
  [-26.8835, 152.1704],
  [-26.8833, 152.1699],
  [-26.8833, 152.1692],
  [-26.8843, 152.1676],
  [-26.8844, 152.1671],
  [-26.8840, 152.1659],
  [-26.8831, 152.1651],
  [-26.8816, 152.1648],
  [-26.8811, 152.1645],
  [-26.8788, 152.1625],
  [-26.8785, 152.1617],
  [-26.8787, 152.1609],
  [-26.8798, 152.1599],
  [-26.8813, 152.1576],
  [-26.8821, 152.1568],
  [-26.8829, 152.1551],
  [-26.8836, 152.1526],
  [-26.8852, 152.1493],
  [-26.8863, 152.1441],
  [-26.8863, 152.1432],
  [-26.8861, 152.1415],
  [-26.8863, 152.1403],
  [-26.8866, 152.1394],
  [-26.8886, 152.1361]
];

const benarkinGilla = [
  [-26.8886, 152.1361],
  [-26.8897, 152.1341],
  [-26.8898, 152.1320],
  [-26.8889, 152.1301],
  [-26.8861, 152.1269],
  [-26.8856, 152.1254],
  [-26.8858, 152.1241],
  [-26.8871, 152.1213],
  [-26.8871, 152.1201],
  [-26.8868, 152.1189],
  [-26.8858, 152.1181],
  [-26.8800, 152.1166],
  [-26.8790, 152.1157],
  [-26.8785, 152.1138],
  [-26.8788, 152.1123],
  [-26.8794, 152.1105],
  [-26.8796, 152.1089],
  [-26.8794, 152.1069],
  [-26.8796, 152.1052],
  [-26.8805, 152.1038],
  [-26.8837, 152.1011],
  [-26.8842, 152.0994],
  [-26.8847, 152.0945],
  [-26.8845, 152.0872],
  [-26.8838, 152.0853],
  [-26.8809, 152.0820],
  [-26.8805, 152.0809],
  [-26.8807, 152.0796],
  [-26.8815, 152.0783],
  [-26.8816, 152.0770],
  [-26.8810, 152.0759],
  [-26.8795, 152.0751],
  [-26.8787, 152.0742],
  [-26.8786, 152.0729],
  [-26.8811, 152.0667],
  [-26.8830, 152.0639],
  [-26.8837, 152.0620],
  [-26.8841, 152.0613],
  [-26.8853, 152.0601],
  [-26.8862, 152.0586],
  [-26.8887, 152.0571],
  [-26.8892, 152.0563],
  [-26.8892, 152.0555],
  [-26.8894, 152.0550],
  [-26.8897, 152.0546],
  [-26.8908, 152.0543],
  [-26.8912, 152.0541],
  [-26.8915, 152.0537],
  [-26.8920, 152.0508],
  [-26.8918, 152.0502],
  [-26.8910, 152.0498],
  [-26.8905, 152.0498],
  [-26.8894, 152.0503],
  [-26.8871, 152.0507],
  [-26.8865, 152.0505],
  [-26.8861, 152.0499],
  [-26.8860, 152.0493],
  [-26.8868, 152.0466],
  [-26.8872, 152.0460],
  [-26.8879, 152.0451],
  [-26.8880, 152.0442],
  [-26.8877, 152.0434],
  [-26.8868, 152.0427],
  [-26.8844, 152.0429],
  [-26.8836, 152.0426],
  [-26.8801, 152.0397],
  [-26.8796, 152.0395],
  [-26.8777, 152.0393]
];

const gillaYarraman = [
  [-26.8777, 152.0393],
  [-26.8773, 152.0390],
  [-26.8767, 152.0381],
  [-26.8760, 152.0377],
  [-26.8740, 152.0375],
  [-26.8734, 152.0372],
  [-26.8729, 152.0366],
  [-26.8727, 152.0358],
  [-26.8730, 152.0329],
  [-26.8733, 152.0324],
  [-26.8737, 152.0317],
  [-26.8738, 152.0309],
  [-26.8733, 152.0301],
  [-26.8720, 152.0292],
  [-26.8701, 152.0261],
  [-26.8691, 152.0238],
  [-26.8668, 152.0211],
  [-26.8668, 152.0211],
  [-26.8657, 152.0205],
  [-26.8630, 152.0198],
  [-26.8623, 152.0191],
  [-26.8614, 152.0168],
  [-26.8605, 152.0161],
  [-26.8596, 152.0160],
  [-26.8587, 152.0164],
  [-26.8570, 152.0186],
  [-26.8563, 152.0188],
  [-26.8556, 152.0185],
  [-26.8550, 152.0168],
  [-26.8547, 152.0165],
  [-26.8541, 152.0163],
  [-26.8530, 152.0161],
  [-26.8521, 152.0157],
  [-26.8503, 152.0143],
  [-26.8496, 152.0142],
  [-26.8490, 152.0147],
  [-26.8484, 152.0155],
  [-26.8477, 152.0159],
  [-26.8470, 152.0159],
  [-26.8463, 152.0154],
  [-26.8459, 152.0142],
  [-26.8466, 152.0108],
  [-26.8464, 152.0102],
  [-26.8456, 152.0097],
  [-26.8442, 152.0098],
  [-26.8434, 152.0101],
  [-26.8409, 152.0120],
  [-26.8389, 152.0127],
  [-26.8363, 152.0124],
  [-26.8355, 152.0117],
  [-26.8346, 152.0098],
  [-26.8328, 152.0074],
  [-26.8326, 152.0064],
  [-26.8329, 152.0053],
  [-26.8336, 152.0043],
  [-26.8341, 152.0029],
  [-26.8342, 152.0017],
  [-26.8345, 152.0008],
  [-26.8359, 151.9969],
  [-26.8363, 151.9964],
  [-26.8368, 151.9960],
  [-26.8380, 151.9955],
  [-26.8392, 151.9941],
  [-26.8397, 151.9929],
  [-26.8395, 151.9919],
  [-26.8391, 151.9911],
  [-26.8380, 151.9908],
  [-26.8352, 151.9915]
];

export const brisbaneValley: Line = {
  name: 'Brisbane Valley',
  state: 'QLD',
  segments: [
    {
      segments: [walkurakaLowood],
      history: {
        opened: {
          date: '1884-06-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lowoodEsk],
      history: {
        opened: {
          date: '1886-08-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eskToogoolawah],
      history: {
        opened: {
          date: '1904-02-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [toogoolawahYimbun],
      history: {
        opened: {
          date: '1904-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        yimbunYimbunTunnel,
        {
          name: 'Yimbun Tunnel',
          segment: yimbunTunnel,
          type: 'tunnel'
        },
        yimbunTunnelNurinda
      ],
      history: {
        opened: {
          date: '1910-11-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nurindaLinville],
      history: {
        opened: {
          date: '1910-11-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [linvilleBenarkin],
      history: {
        opened: {
          date: '1911-05-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [benarkinGilla],
      history: {
        opened: {
          date: '1912-12-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gillaYarraman],
      history: {
        opened: {
          date: '1913-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
