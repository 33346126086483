import { Line } from "../../../trackTypes";

const line = [
  [-37.7211, 140.7256],
  [-37.7144, 140.7201],
  [-37.7137, 140.7193],
  [-37.7136, 140.7187],
  [-37.7135, 140.7181],
  [-37.7127, 140.7163],
  [-37.7123, 140.7149],
  [-37.7096, 140.6991],
  [-37.7085, 140.6956],
  [-37.7075, 140.6936],
  [-37.7047, 140.6901],
  [-37.7038, 140.6887],
  [-37.7017, 140.6841],
  [-37.7007, 140.6830],
  [-37.6987, 140.6816],
  [-37.6971, 140.6799],
  [-37.6957, 140.6766],
  [-37.6927, 140.6696],
  [-37.6912, 140.6669],
  [-37.6895, 140.6645],
  [-37.6829, 140.6504],
  [-37.6803, 140.6476],
  [-37.6789, 140.6442],
  [-37.6712, 140.6301],
  [-37.6706, 140.6279],
  [-37.6707, 140.5802]
];

export const glencoe: Line = {
  name: 'Glencoe',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1904-08-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-06-01',
          status: 'closed'
        }]
      }
    }
  ]
};
