import { Line } from "../../../trackTypes";

const glenreaghTunnel1 = [
  [-30.0548, 152.9826],
  [-30.0557, 152.9818],
  [-30.0563, 152.9808],
  [-30.0565, 152.9782],
  [-30.0562, 152.9767],
  [-30.0526, 152.9719],
  [-30.0481, 152.9658],
  [-30.0467, 152.9630],
  [-30.0462, 152.9600],
  [-30.0467, 152.9521],
  [-30.0465, 152.9512],
  [-30.0461, 152.9500],
  [-30.0460, 152.9493],
  [-30.0461, 152.9487],
  [-30.0465, 152.9471],
  [-30.0470, 152.9437],
  [-30.0468, 152.9409],
  [-30.0469, 152.9398],
  [-30.0472, 152.9388],
  [-30.0470, 152.9372],
  [-30.0472, 152.9363],
  [-30.0482, 152.9356],
  [-30.0494, 152.9359],
  [-30.0500, 152.9368],
  [-30.0502, 152.9388],
  [-30.0506, 152.9396],
  [-30.0515, 152.9400],
  [-30.0528, 152.9393],
  [-30.0533, 152.9383],
  [-30.0539, 152.9379],
  [-30.0553, 152.9377],
  [-30.0562, 152.9368],
  [-30.0562, 152.9356],
  [-30.0556, 152.9343],
  [-30.0552, 152.9330],
  [-30.0553, 152.9323],
  [-30.0557, 152.9313],
  [-30.0557, 152.9305],
  [-30.0553, 152.9286],
  [-30.0554, 152.9276],
  [-30.0557, 152.9269],
  [-30.0566, 152.9264],
  [-30.0570, 152.9259],
  [-30.0583, 152.9228]
];

const tunnelNo1 = [
  [-30.0583, 152.9228],
  [-30.0584, 152.9214]
];

const tunnel1Tunnel2 = [
  [-30.0584, 152.9214],
  [-30.0587, 152.9208],
  [-30.0593, 152.9205],
  [-30.0602, 152.9206],
  [-30.0605, 152.9207]
];

const tunnelNo2 = [
  [-30.0605, 152.9207],
  [-30.0613, 152.9209],
  [-30.0617, 152.9208],
  [-30.0619, 152.9205],
  [-30.0620, 152.9199]
];

const tunnel2Dorrigo = [
  [-30.0620, 152.9199],
  [-30.0622, 152.9190],
  [-30.0620, 152.9175],
  [-30.0624, 152.9164],
  [-30.0627, 152.9146],
  [-30.0633, 152.9136],
  [-30.0638, 152.9134],
  [-30.0642, 152.9131],
  [-30.0648, 152.9117],
  [-30.0652, 152.9114],
  [-30.0659, 152.9111],
  [-30.0665, 152.9107],
  [-30.0673, 152.9095],
  [-30.0680, 152.9092],
  [-30.0705, 152.9098],
  [-30.0725, 152.9111],
  [-30.0730, 152.9111],
  [-30.0739, 152.9105],
  [-30.0747, 152.9096],
  [-30.0758, 152.9094],
  [-30.0761, 152.9095],
  [-30.0776, 152.9103],
  [-30.0792, 152.9108],
  [-30.0806, 152.9120],
  [-30.0821, 152.9125],
  [-30.0830, 152.9123],
  [-30.0835, 152.9120],
  [-30.0848, 152.9118],
  [-30.0892, 152.9140],
  [-30.0905, 152.9153],
  [-30.0908, 152.9163],
  [-30.0908, 152.9173],
  [-30.0905, 152.9181],
  [-30.0908, 152.9193],
  [-30.0918, 152.9199],
  [-30.0932, 152.9195],
  [-30.0950, 152.9179],
  [-30.0968, 152.9153],
  [-30.0974, 152.9139],
  [-30.0979, 152.9131],
  [-30.1015, 152.9106],
  [-30.1025, 152.9104],
  [-30.1031, 152.9109],
  [-30.1047, 152.9135],
  [-30.1089, 152.9165],
  [-30.1111, 152.9174],
  [-30.1127, 152.9194],
  [-30.1130, 152.9212],
  [-30.1126, 152.9224],
  [-30.1109, 152.9258],
  [-30.1104, 152.9283],
  [-30.1106, 152.9295],
  [-30.1110, 152.9300],
  [-30.1125, 152.9307],
  [-30.1131, 152.9305],
  [-30.1135, 152.9300],
  [-30.1142, 152.9275],
  [-30.1151, 152.9256],
  [-30.1153, 152.9250],
  [-30.1156, 152.9227],
  [-30.1162, 152.9217],
  [-30.1164, 152.9208],
  [-30.1162, 152.9194],
  [-30.1171, 152.9152],
  [-30.1176, 152.9138],
  [-30.1177, 152.9124],
  [-30.1187, 152.9092],
  [-30.1200, 152.9073],
  [-30.1216, 152.9065],
  [-30.1228, 152.9050],
  [-30.1240, 152.9042],
  [-30.1252, 152.9042],
  [-30.1261, 152.9048],
  [-30.1269, 152.9050],
  [-30.1278, 152.9049],
  [-30.1290, 152.9052],
  [-30.1300, 152.9058],
  [-30.1310, 152.9058],
  [-30.1317, 152.9058],
  [-30.1326, 152.9059],
  [-30.1334, 152.9056],
  [-30.1340, 152.9050],
  [-30.1357, 152.9023],
  [-30.1369, 152.9016],
  [-30.1391, 152.9016],
  [-30.1405, 152.9024],
  [-30.1414, 152.9036],
  [-30.1426, 152.9046],
  [-30.1445, 152.9056],
  [-30.1458, 152.9058],
  [-30.1474, 152.9053],
  [-30.1480, 152.9047],
  [-30.1484, 152.9038],
  [-30.1485, 152.9027],
  [-30.1490, 152.9016],
  [-30.1498, 152.9010],
  [-30.1510, 152.9011],
  [-30.1535, 152.9026],
  [-30.1552, 152.9030],
  [-30.1566, 152.9030],
  [-30.1576, 152.9033],
  [-30.1593, 152.9042],
  [-30.1604, 152.9043],
  [-30.1619, 152.9034],
  [-30.1625, 152.9032],
  [-30.1644, 152.9032],
  [-30.1653, 152.9029],
  [-30.1658, 152.9028],
  [-30.1675, 152.9030],
  [-30.1686, 152.9028],
  [-30.1698, 152.9029],
  [-30.1710, 152.9036],
  [-30.1723, 152.9052],
  [-30.1731, 152.9055],
  [-30.1740, 152.9052],
  [-30.1755, 152.9024],
  [-30.1764, 152.9015],
  [-30.1772, 152.9016],
  [-30.1778, 152.9022],
  [-30.1785, 152.9033],
  [-30.1793, 152.9039],
  [-30.1804, 152.9043],
  [-30.1815, 152.9055],
  [-30.1821, 152.9069],
  [-30.1834, 152.9081],
  [-30.1845, 152.9081],
  [-30.1861, 152.9076],
  [-30.1872, 152.9077],
  [-30.1884, 152.9081],
  [-30.1898, 152.9082],
  [-30.1920, 152.9070],
  [-30.1932, 152.9067],
  [-30.1951, 152.9067],
  [-30.1958, 152.9065],
  [-30.1969, 152.9056],
  [-30.1980, 152.9053],
  [-30.1991, 152.9059],
  [-30.2004, 152.9077],
  [-30.2014, 152.9081],
  [-30.2022, 152.9080],
  [-30.2027, 152.9076],
  [-30.2049, 152.9013],
  [-30.2064, 152.8991],
  [-30.2083, 152.8980],
  [-30.2105, 152.8976],
  [-30.2113, 152.8972],
  [-30.2132, 152.8963],
  [-30.2150, 152.8961],
  [-30.2186, 152.8950],
  [-30.2208, 152.8954],
  [-30.2218, 152.8952],
  [-30.2235, 152.8940],
  [-30.2258, 152.8931],
  [-30.2280, 152.8913],
  [-30.2309, 152.8877],
  [-30.2318, 152.8869],
  [-30.2328, 152.8861],
  [-30.2334, 152.8852],
  [-30.2339, 152.8837],
  [-30.2350, 152.8817],
  [-30.2367, 152.8802],
  [-30.2370, 152.8797],
  [-30.2376, 152.8782],
  [-30.2400, 152.8758],
  [-30.2423, 152.8749],
  [-30.2430, 152.8744],
  [-30.2455, 152.8714],
  [-30.2461, 152.8700],
  [-30.2483, 152.8675],
  [-30.2486, 152.8663],
  [-30.2486, 152.8642],
  [-30.2484, 152.8633],
  [-30.2471, 152.8608],
  [-30.2470, 152.8600],
  [-30.2471, 152.8550],
  [-30.2473, 152.8542],
  [-30.2487, 152.8518],
  [-30.2489, 152.8512],
  [-30.2489, 152.8477],
  [-30.2487, 152.8469],
  [-30.2473, 152.8440],
  [-30.2455, 152.8421],
  [-30.2442, 152.8413],
  [-30.2439, 152.8409],
  [-30.2437, 152.8398],
  [-30.2435, 152.8390],
  [-30.2429, 152.8379],
  [-30.2428, 152.8370],
  [-30.2433, 152.8351],
  [-30.2432, 152.8344],
  [-30.2398, 152.8304],
  [-30.2394, 152.8294],
  [-30.2398, 152.8266],
  [-30.2390, 152.8241],
  [-30.2384, 152.8236],
  [-30.2378, 152.8234],
  [-30.2364, 152.8235],
  [-30.2357, 152.8231],
  [-30.2346, 152.8212],
  [-30.2342, 152.8188],
  [-30.2357, 152.8138],
  [-30.2367, 152.8128],
  [-30.2379, 152.8124],
  [-30.2384, 152.8120],
  [-30.2387, 152.8112],
  [-30.2388, 152.8103],
  [-30.2393, 152.8090],
  [-30.2403, 152.8082],
  [-30.2406, 152.8070],
  [-30.2400, 152.8058],
  [-30.2387, 152.8052],
  [-30.2365, 152.8026],
  [-30.2357, 152.8008],
  [-30.2356, 152.7992],
  [-30.2361, 152.7974],
  [-30.2361, 152.7965],
  [-30.2355, 152.7921],
  [-30.2352, 152.7915],
  [-30.2345, 152.7912],
  [-30.2332, 152.7910],
  [-30.2324, 152.7906],
  [-30.2320, 152.7895],
  [-30.2325, 152.7880],
  [-30.2339, 152.7871],
  [-30.2349, 152.7870],
  [-30.2360, 152.7869],
  [-30.2375, 152.7863],
  [-30.2385, 152.7853],
  [-30.2393, 152.7849],
  [-30.2400, 152.7846],
  [-30.2406, 152.7841],
  [-30.2409, 152.7832],
  [-30.2410, 152.7819],
  [-30.2416, 152.7807],
  [-30.2425, 152.7802],
  [-30.2433, 152.7803],
  [-30.2453, 152.7814],
  [-30.2464, 152.7813],
  [-30.2500, 152.7789],
  [-30.2508, 152.7779],
  [-30.2515, 152.7775],
  [-30.2522, 152.7777],
  [-30.2530, 152.7776],
  [-30.2539, 152.7768],
  [-30.2549, 152.7749],
  [-30.2553, 152.7734],
  [-30.2560, 152.7723],
  [-30.2576, 152.7717],
  [-30.2586, 152.7720],
  [-30.2591, 152.7728],
  [-30.2598, 152.7754],
  [-30.2602, 152.7760],
  [-30.2609, 152.7764],
  [-30.2616, 152.7777],
  [-30.2625, 152.7785],
  [-30.2633, 152.7783],
  [-30.2638, 152.7775],
  [-30.2638, 152.7765],
  [-30.2635, 152.7757],
  [-30.2635, 152.7750],
  [-30.2636, 152.7744],
  [-30.2640, 152.7737],
  [-30.2641, 152.7733],
  [-30.2639, 152.7727],
  [-30.2637, 152.7722],
  [-30.2635, 152.7715],
  [-30.2637, 152.7702],
  [-30.2643, 152.7696],
  [-30.2650, 152.7695],
  [-30.2657, 152.7696],
  [-30.2662, 152.7695],
  [-30.2671, 152.7690],
  [-30.2678, 152.7690],
  [-30.2690, 152.7698],
  [-30.2696, 152.7700],
  [-30.2701, 152.7698],
  [-30.2710, 152.7685],
  [-30.2718, 152.7680],
  [-30.2729, 152.7678],
  [-30.2736, 152.7679],
  [-30.2746, 152.7686],
  [-30.2754, 152.7697],
  [-30.2760, 152.7702],
  [-30.2783, 152.7709],
  [-30.2791, 152.7710],
  [-30.2808, 152.7720],
  [-30.2812, 152.7720],
  [-30.2818, 152.7718],
  [-30.2823, 152.7713],
  [-30.2829, 152.7696],
  [-30.2829, 152.7689],
  [-30.2815, 152.7660],
  [-30.2812, 152.7648],
  [-30.2812, 152.7638],
  [-30.2822, 152.7599],
  [-30.2829, 152.7592],
  [-30.2837, 152.7590],
  [-30.2844, 152.7594],
  [-30.2849, 152.7602],
  [-30.2856, 152.7610],
  [-30.2863, 152.7611],
  [-30.2894, 152.7604],
  [-30.2904, 152.7606],
  [-30.2912, 152.7609],
  [-30.2928, 152.7608],
  [-30.2937, 152.7603],
  [-30.2941, 152.7596],
  [-30.2943, 152.7577],
  [-30.2960, 152.7546],
  [-30.2969, 152.7541],
  [-30.2980, 152.7543],
  [-30.2998, 152.7560],
  [-30.3007, 152.7562],
  [-30.3015, 152.7558],
  [-30.3027, 152.7543],
  [-30.3033, 152.7541],
  [-30.3040, 152.7541],
  [-30.3055, 152.7550],
  [-30.3064, 152.7551],
  [-30.3072, 152.7546],
  [-30.3091, 152.7519],
  [-30.3094, 152.7502],
  [-30.3097, 152.7495],
  [-30.3099, 152.7482],
  [-30.3096, 152.7470],
  [-30.3097, 152.7462],
  [-30.3106, 152.7444],
  [-30.3108, 152.7432],
  [-30.3104, 152.7414],
  [-30.3096, 152.7402],
  [-30.3096, 152.7395],
  [-30.3096, 152.7388],
  [-30.3101, 152.7374],
  [-30.3101, 152.7371],
  [-30.3096, 152.7361],
  [-30.3090, 152.7355],
  [-30.3083, 152.7344],
  [-30.3081, 152.7334],
  [-30.3083, 152.7324],
  [-30.3089, 152.7318],
  [-30.3094, 152.7311],
  [-30.3096, 152.7304],
  [-30.3094, 152.7296],
  [-30.3096, 152.7287],
  [-30.3099, 152.7280],
  [-30.3099, 152.7272],
  [-30.3097, 152.7261],
  [-30.3097, 152.7239],
  [-30.3103, 152.7228],
  [-30.3112, 152.7225],
  [-30.3132, 152.7223],
  [-30.3138, 152.7210],
  [-30.3134, 152.7191],
  [-30.3126, 152.7184],
  [-30.3116, 152.7184],
  [-30.3103, 152.7189],
  [-30.3098, 152.7189],
  [-30.3087, 152.7183],
  [-30.3081, 152.7183],
  [-30.3070, 152.7187],
  [-30.3063, 152.7187],
  [-30.3053, 152.7184],
  [-30.3048, 152.7179],
  [-30.3042, 152.7166],
  [-30.3041, 152.7159],
  [-30.3043, 152.7150],
  [-30.3053, 152.7138],
  [-30.3062, 152.7135],
  [-30.3071, 152.7137],
  [-30.3076, 152.7138],
  [-30.3119, 152.7127],
  [-30.3126, 152.7129],
  [-30.3135, 152.7134],
  [-30.3145, 152.7134],
  [-30.3153, 152.7124],
  [-30.3157, 152.7107],
  [-30.3159, 152.7102],
  [-30.3165, 152.7098],
  [-30.3178, 152.7095],
  [-30.3188, 152.7098],
  [-30.3202, 152.7114],
  [-30.3208, 152.7118],
  [-30.3218, 152.7118],
  [-30.3223, 152.7116],
  [-30.3233, 152.7107],
  [-30.3238, 152.7104],
  [-30.3243, 152.7105],
  [-30.3251, 152.7108],
  [-30.3255, 152.7108],
  [-30.3260, 152.7107],
  [-30.3287, 152.7088],
  [-30.3297, 152.7083],
  [-30.3355, 152.7073]
];

export const dorrigo: Line = {
  name: 'Dorrigo',
  state: 'NSW',
  segments: [
    {
      segments: [
        glenreaghTunnel1,
        {
          name: 'Tunnel No 1',
          segment: tunnelNo1,
          type: 'tunnel'
        },
        tunnel1Tunnel2,
        {
          name: 'Tunnel No 2',
          segment: tunnelNo2,
          type: 'tunnel'
        },
        tunnel2Dorrigo
      ],
      history: {
        opened: {
          date: '1924-12-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1972-10-28',
          status: 'closed'
        }]
      }
    }
  ]
};
