import { Line } from "../../trackTypes";

const britomart = [
  [-36.8442, 174.7679],
  [-36.8451, 174.7713]
];

const strandLinkTunnel = [
  [-36.8451, 174.7713],
  [-36.8461, 174.7751]
];

const strandLink = [
  [-36.8461, 174.7751],
  [-36.8466, 174.7772],
  [-36.8472, 174.7782],
  [-36.8475, 174.7784],
  [-36.8481, 174.7783],
  [-36.8485, 174.7777]
];

const beachRoad = [
  [-36.8451, 174.7713],
  [-36.8463, 174.7733],
  [-36.8479, 174.7746],
  [-36.8501, 174.7754]
];

const aucklandStrand = [
  [-36.8484, 174.7802],
  [-36.8485, 174.7777],
  [-36.8486, 174.7767],
  [-36.8488, 174.7761],
  [-36.8493, 174.7756],
  [-36.8498, 174.7754],
  [-36.8501, 174.7754],
];

const aucklandStationParnellTunnel = [
  [-36.8501, 174.7754],
  [-36.8518, 174.7759],
  [-36.8530, 174.7764],
  [-36.8601, 174.7804]
];

const parnellTunnel = [
  [-36.8601, 174.7804],
  [-36.8630, 174.7820]
];

const parnellTunnelNewmarket = [
  [-36.8630, 174.7820],
  [-36.8637, 174.7822],
  [-36.8644, 174.7820],
  [-36.8650, 174.7815],
  [-36.8664, 174.7801],
  [-36.8671, 174.7796],
  [-36.8676, 174.7793],
  [-36.8696, 174.7788]
];

const newmarketPenroseJunction = [
  [-36.8696, 174.7788],
  [-36.8705, 174.7786],
  [-36.8725, 174.7788],
  [-36.8759, 174.7803],
  [-36.8840, 174.7883],
  [-36.8864, 174.7916],
  [-36.8907, 174.7994],
  [-36.8938, 174.8044],
  [-36.8955, 174.8063],
  [-36.8973, 174.8076],
  [-36.9023, 174.8100],
  [-36.9045, 174.8106],
  [-36.9064, 174.8119],
  [-36.9080, 174.8140],
  [-36.9095, 174.8153]
];

const penrosePapatoetoe = [
  [-36.9095, 174.8153],
  [-36.9115, 174.8165],
  [-36.9276, 174.8289],
  [-36.9303, 174.8302],
  [-36.9326, 174.8309],
  [-36.9559, 174.8347],
  [-36.9579, 174.8356],
  [-36.9705, 174.8440],
  [-36.9778, 174.8496]
];

const papatoetoePapakura = [
  [-36.9778, 174.8496],
  [-36.9791, 174.8504],
  [-36.9920, 174.8573],
  [-36.9942, 174.8590],
  [-36.9962, 174.8601],
  [-37.0062, 174.8642],
  [-37.0076, 174.8652],
  [-37.0087, 174.8665],
  [-37.0133, 174.8743],
  [-37.0163, 174.8804],
  [-37.0185, 174.8858],
  [-37.0225, 174.8949],
  [-37.0235, 174.8966],
  [-37.0284, 174.9029],
  [-37.0383, 174.9152],
  [-37.0478, 174.9299],
  [-37.0498, 174.9328],
  [-37.0528, 174.9362],
  [-37.0624, 174.9447],
  [-37.0646, 174.9464]
];

const papakuraDury = [
  [-37.0646, 174.9464],
  [-37.0704, 174.9506],
  [-37.0717, 174.9519],
  [-37.0732, 174.9540],
  [-37.0745, 174.9555],
  [-37.0760, 174.9565],
  [-37.0783, 174.9574],
  [-37.0799, 174.9577],
  [-37.0809, 174.9577],
  [-37.0900, 174.9550],
  [-37.0918, 174.9547],
  [-37.0997, 174.9543],
  [-37.1026, 174.9533],
  [-37.1047, 174.9518]
];

const druryPaerata = [
  [-37.1047, 174.9518],
  [-37.1088, 174.9470],
  [-37.1102, 174.9458],
  [-37.1144, 174.9433],
  [-37.1202, 174.9389],
  [-37.1216, 174.9375],
  [-37.1360, 174.9168],
  [-37.1376, 174.9147],
  [-37.1389, 174.9136],
  [-37.1415, 174.9118],
  [-37.1428, 174.9104],
  [-37.1447, 174.9066],
  [-37.1466, 174.9044],
  [-37.1494, 174.9029],
  [-37.1536, 174.8993],
  [-37.1576, 174.8970],
  [-37.1606, 174.8947],
  [-37.1627, 174.8939],
  [-37.1643, 174.8940],
  [-37.1649, 174.8943]
];

const paerataPukekohe = [
  [-37.1649, 174.8943],
  [-37.1670, 174.8955],
  [-37.1730, 174.9004],
  [-37.1740, 174.9008],
  [-37.1747, 174.9010],
  [-37.1760, 174.9009],
  [-37.1770, 174.9011],
  [-37.1780, 174.9017],
  [-37.1791, 174.9028],
  [-37.1815, 174.9048],
  [-37.1826, 174.9055],
  [-37.1841, 174.9058],
  [-37.1854, 174.9058],
  [-37.1880, 174.9054],
  [-37.1893, 174.9055],
  [-37.1920, 174.9062],
  [-37.1940, 174.9063],
  [-37.1968, 174.9058],
  [-37.1986, 174.9061],
  [-37.2004, 174.9073],
  [-37.2035, 174.9105]
];

export const south: Line = {
  name: 'South',
  state: 'NZ',
  segments: [
    {
      segments: [
        {
          date: '2003-07-07',
          original: [britomart],
          deviation: [{segment: britomart, type: 'tunnel'}]
        }
      ],
      history: {
        opened: {
          date: '1873-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-11-16',
          status: 'closed'
        }, {
          date: '2003-07-07',
          status: 'open'
        }, {
          date: '2003-07-07',
          tracks: 2
        }, {
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        {
          segment: strandLinkTunnel,
          type: 'tunnel'
        },
        strandLink
      ],
      history: {
        opened: {
          date: '2003-07-07',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [beachRoad],
      history: {
        opened: {
          date: '1873-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-11-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [aucklandStrand],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1908-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [aucklandStationParnellTunnel],
      history: {
        opened: {
          date: '1873-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1908-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [{
        name: 'Parnell Tunnel',
        segment: parnellTunnel,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1873-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1915-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [parnellTunnelNewmarket],
      history: {
        opened: {
          date: '1873-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1908-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [newmarketPenroseJunction],
      history: {
        opened: {
          date: '1873-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1909-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [penrosePapatoetoe],
      history: {
        opened: {
          date: '1873-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1947-07-20',
          tracks: 2
        }, {
          date: '2015-07-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [papatoetoePapakura],
      history: {
        opened: {
          date: '1873-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-03-29',
          tracks: 2
        }, {
          date: '2015-07-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [papakuraDury],
      history: {
        opened: {
          date: '1873-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1939-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [druryPaerata],
      history: {
        opened: {
          date: '1874-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1939-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [paerataPukekohe],
      history: {
        opened: {
          date: '1874-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-11-21',
          tracks: 2
        }]
      }
    }
  ]
}
