import { Line } from "../../../trackTypes";

const emeraldClermont = [
  [-23.5256, 148.1558],
  [-23.5252, 148.1551],
  [-23.5248, 148.1547],
  [-23.5240, 148.1543],
  [-23.5113, 148.1492],
  [-23.5055, 148.1476],
  [-23.4359, 148.1278],
  [-23.4304, 148.1270],
  [-23.4175, 148.1221],
  [-23.3050, 148.0855],
  [-23.2724, 148.0812],
  [-23.2690, 148.0808],
  [-23.2667, 148.0800],
  [-23.2357, 148.0626],
  [-23.2316, 148.0611],
  [-23.1578, 148.0437],
  [-23.1179, 148.0341],
  [-23.0745, 148.0184],
  [-23.0737, 148.0180],
  [-23.0709, 148.0154],
  [-23.0696, 148.0137],
  [-23.0652, 148.0036],
  [-23.0546, 147.9831],
  [-23.0459, 147.9707],
  [-23.0369, 147.9455],
  [-23.0227, 147.9220],
  [-22.9766, 147.8666],
  [-22.9599, 147.8477],
  [-22.9398, 147.8201],
  [-22.8941, 147.7578],
  [-22.8602, 147.7114],
  [-22.8279, 147.6523],
  [-22.8273, 147.6503],
  [-22.8261, 147.6398]
];

const clermontBlairAthol = [
  [-22.8261, 147.6398],
  [-22.8256, 147.6354],
  [-22.8252, 147.6342],
  [-22.8102, 147.6194],
  [-22.8061, 147.6164],
  [-22.7981, 147.6128],
  [-22.7854, 147.6070],
  [-22.7837, 147.6056],
  [-22.7807, 147.6006],
  [-22.7795, 147.5996],
  [-22.7746, 147.5972],
  [-22.7733, 147.5960],
  [-22.7717, 147.5937],
  [-22.7682, 147.5913],
  [-22.7664, 147.5891],
  [-22.7640, 147.5844],
  [-22.7626, 147.5826],
  [-22.7611, 147.5816],
  [-22.7558, 147.5792],
  [-22.7427, 147.5713],
  [-22.7337, 147.5647],
  [-22.7150, 147.5593],
  [-22.6992, 147.5612],
  [-22.6956, 147.5604],
  [-22.6799, 147.5520]
];

const blairAtholBirimgan = [
  [-22.7337, 147.5647],
  [-22.7240, 147.5625],
  [-22.7215, 147.5629],
  [-22.7195, 147.5619],
  [-22.7181, 147.5600],
  [-22.7171, 147.5596],
  [-22.7119, 147.5583],
  [-22.7094, 147.5571],
  [-22.7056, 147.5538],
  [-22.7002, 147.5467],
  [-22.6985, 147.5458],
  [-22.6940, 147.5463],
  [-22.6920, 147.5460],
  [-22.6877, 147.5436],
  [-22.6829, 147.5418],
  [-22.6804, 147.5420],
  [-22.6795, 147.5432]
];

const birimganTwo = [
  [-22.6985, 147.5458],
  [-22.7017, 147.5442],
  [-22.7029, 147.5432],
  [-22.7061, 147.5348],
  [-22.7057, 147.5287],
  [-22.7066, 147.5266],
  [-22.7111, 147.5214],
  [-22.7120, 147.5196],
  [-22.7112, 147.5093],
  [-22.7126, 147.5039],
  [-22.7126, 147.5015],
  [-22.7139, 147.4963],
  [-22.7149, 147.4943],
  [-22.7165, 147.4926],
  [-22.7211, 147.4793],
  [-22.7206, 147.4773],
  [-22.7085, 147.4692],
  [-22.7049, 147.4682],
  [-22.7004, 147.4613]
];

const blairAtholMackayConnection = [
  [-22.6799, 147.5520],
  [-22.6756, 147.5493],
  [-22.6723, 147.5454],
  [-22.6691, 147.5396],
  [-22.6679, 147.5385],
  [-22.6650, 147.5372],
  [-22.6632, 147.5372],
  [-22.6611, 147.5383]
];

export const blairAthol: Line = {
  name: 'Blair Athol',
  state: 'QLD',
  segments: [
    {
      segments: [emeraldClermont],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [clermontBlairAthol],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [blairAtholBirimgan],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [birimganTwo],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [blairAtholMackayConnection],
      history: {
        opened: {
          date: '1986-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-26',
          status: 'closed'
        }]
      }
    }
  ]
}
