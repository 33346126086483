import { Line } from "../../../trackTypes";

const heywoodDartmore = [
  [-38.1306, 141.6347],
  [-38.1299, 141.6352],
  [-38.1292, 141.6354],
  [-38.1284, 141.6354],
  [-38.1277, 141.6352],
  [-38.1269, 141.6344],
  [-38.1263, 141.6334],
  [-38.1186, 141.6165],
  [-38.1174, 141.6131],
  [-38.1155, 141.6046],
  [-38.1145, 141.6022],
  [-38.0948, 141.5711],
  [-38.0679, 141.5339],
  [-38.0671, 141.5323],
  [-38.0626, 141.5227],
  [-38.0609, 141.5203],
  [-38.0537, 141.5130],
  [-38.0529, 141.5119],
  [-38.0308, 141.4794],
  [-38.0295, 141.4780],
  [-38.0279, 141.4767],
  [-38.0248, 141.4745],
  [-38.0228, 141.4725],
  [-38.0145, 141.4612],
  [-38.0116, 141.4589],
  [-38.0103, 141.4570],
  [-38.0070, 141.4488],
  [-38.0067, 141.4468],
  [-38.0069, 141.4448],
  [-38.0080, 141.4405],
  [-38.0080, 141.4382],
  [-38.0073, 141.4357],
  [-37.9850, 141.4016],
  [-37.9752, 141.3869],
  [-37.9744, 141.3848],
  [-37.9714, 141.3728],
  [-37.9704, 141.3711],
  [-37.9691, 141.3703],
  [-37.9666, 141.3694],
  [-37.9651, 141.3681],
  [-37.9643, 141.3664],
  [-37.9644, 141.3640],
  [-37.9662, 141.3595],
  [-37.9665, 141.3571],
  [-37.9659, 141.3544],
  [-37.9645, 141.3524],
  [-37.9629, 141.3516],
  [-37.9593, 141.3504],
  [-37.9572, 141.3490],
  [-37.9558, 141.3473],
  [-37.9459, 141.3279],
  [-37.9337, 141.3018],
  [-37.9308, 141.2912],
  [-37.9296, 141.2896],
  [-37.9291, 141.2883],
  [-37.9283, 141.2849],
  [-37.9269, 141.2828],
  [-37.9232, 141.2795],
  [-37.9226, 141.2786],
  [-37.9180, 141.2717]
];

const dartwoodSaBorder = [
  [-37.9180, 141.2717],
  [-37.9115, 141.2617],
  [-37.9020, 141.2503],
  [-37.8675, 141.1959],
  [-37.8353, 141.1264],
  [-37.8222, 141.0863],
  [-37.8216, 141.0838],
  [-37.8199, 141.0693],
  [-37.8173, 140.9702]
];

export const mtGambier: Line = {
  name: 'mt Gambier',
  state: 'VIC',
  segments: [
    {
      segments: [heywoodDartmore],
      history: {
        opened: {
          date: '1916-06-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dartwoodSaBorder],
      history: {
        opened: {
          date: '1917-11-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-11',
          status: 'closed'
        }]
      }
    }
  ]
};
