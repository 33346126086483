import { Line } from "../../../trackTypes";

const shunt = [
  [-45.9001, 170.4373],
  [-45.9004, 170.4361],
  [-45.9009, 170.4349],
  [-45.9017, 170.4330],
  [-45.9019, 170.4314]
];

const shuntTwo = [
  [-45.9017, 170.4330],
  [-45.9018, 170.4335],
  [-45.9017, 170.4363],
  [-45.9016, 170.4368],
  [-45.9008, 170.4379]
];

const junctionWaltonPark = [
  [-45.9019, 170.4314],
  [-45.9022, 170.4306],
  [-45.9037, 170.4270],
  [-45.9042, 170.4247],
  [-45.9042, 170.4237],
  [-45.9037, 170.4225],
  [-45.9032, 170.4212],
  [-45.9022, 170.4131],
  [-45.9009, 170.4050],
  [-45.9003, 170.4013],
  [-45.9004, 170.4008],
  [-45.9006, 170.3995],
  [-45.9006, 170.3971],
  [-45.9008, 170.3963],
  [-45.9018, 170.3941],
  [-45.9035, 170.3911],
  [-45.9041, 170.3907],
  [-45.9068, 170.3892],
  [-45.9086, 170.3875]
];

const waltonParkSaddleHill = [
  [-45.9022, 170.4131],
  [-45.9020, 170.4129],
  [-45.8987, 170.4109],
  [-45.8981, 170.4101],
  [-45.8973, 170.4078],
  [-45.8960, 170.4056],
];

export const waltonPark: Line = {
  name: 'Walton Park',
  state: 'NZ',
  segments: [
    {
      segments: [shunt, shuntTwo],
      history: {
        opened: {
          date: '1874-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [junctionWaltonPark],
      history: {
        opened: {
          date: '1874-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waltonParkSaddleHill],
      history: {
        opened: {
          date: '1879-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-05-01',
          status: 'closed'
        }]
      }
    }
  ]
}
