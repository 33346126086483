import { Line } from "../../trackTypes";

const yorkGreenhills = [
  [-31.9023, 116.7739],
  [-31.9029, 116.7757],
  [-31.9029, 116.7769],
  [-31.9011, 116.7852],
  [-31.9010, 116.7924],
  [-31.9016, 116.7950],
  [-31.9012, 116.7972],
  [-31.8955, 116.8055],
  [-31.8943, 116.8061],
  [-31.8922, 116.8061],
  [-31.8900, 116.8055],
  [-31.8879, 116.8061],
  [-31.8869, 116.8079],
  [-31.8870, 116.8101],
  [-31.8879, 116.8116],
  [-31.8927, 116.8145],
  [-31.8937, 116.8165],
  [-31.8940, 116.8273],
  [-31.8935, 116.8297],
  [-31.8904, 116.8359],
  [-31.8900, 116.8376],
  [-31.8903, 116.8392],
  [-31.8993, 116.8563],
  [-31.8999, 116.8589],
  [-31.8995, 116.8640],
  [-31.8975, 116.8706],
  [-31.8967, 116.8757],
  [-31.8969, 116.8785],
  [-31.8995, 116.8858],
  [-31.9004, 116.8873],
  [-31.9061, 116.8928],
  [-31.9154, 116.8993],
  [-31.9168, 116.9007],
  [-31.9212, 116.9074],
  [-31.9267, 116.9190],
  [-31.9272, 116.9210],
  [-31.9292, 116.9490],
  [-31.9286, 116.9512],
  [-31.9194, 116.9644]
];

const greenhillsQuairading = [
  [-31.9194, 116.9644],
  [-31.9184, 116.9666],
  [-31.9184, 116.9680],
  [-31.9203, 116.9739],
  [-31.9205, 116.9755],
  [-31.9208, 116.9765],
  [-31.9215, 116.9777],
  [-31.9216, 116.9788],
  [-31.9215, 116.9800],
  [-31.9220, 116.9814],
  [-31.9229, 116.9821],
  [-31.9245, 116.9821],
  [-31.9256, 116.9827],
  [-31.9262, 116.9840],
  [-31.9270, 116.9848],
  [-31.9282, 116.9851],
  [-31.9288, 116.9854],
  [-31.9294, 116.9859],
  [-31.9302, 116.9862],
  [-31.9311, 116.9864],
  [-31.9316, 116.9866],
  [-31.9341, 116.9892],
  [-31.9352, 116.9896],
  [-31.9382, 116.9889],
  [-31.9400, 116.9893],
  [-31.9414, 116.9907],
  [-31.9425, 116.9937],
  [-31.9425, 116.9963],
  [-31.9431, 116.9983],
  [-31.9441, 116.9993],
  [-31.9472, 117.0013],
  [-31.9482, 117.0027],
  [-31.9483, 117.0040],
  [-31.9480, 117.0064],
  [-31.9481, 117.0075],
  [-31.9488, 117.0090],
  [-31.9497, 117.0098],
  [-31.9531, 117.0107],
  [-31.9541, 117.0115],
  [-31.9547, 117.0122],
  [-31.9565, 117.0177],
  [-31.9575, 117.0186],
  [-31.9585, 117.0188],
  [-31.9598, 117.0182],
  [-31.9611, 117.0183],
  [-31.9622, 117.0193],
  [-31.9629, 117.0210],
  [-31.9643, 117.0226],
  [-31.9672, 117.0243],
  [-31.9681, 117.0252],
  [-31.9698, 117.0280],
  [-31.9702, 117.0297],
  [-31.9702, 117.0314],
  [-31.9706, 117.0326],
  [-31.9714, 117.0336],
  [-31.9718, 117.0344],
  [-31.9729, 117.0385],
  [-31.9762, 117.0437],
  [-31.9765, 117.0454],
  [-31.9761, 117.0483],
  [-31.9762, 117.0501],
  [-31.9786, 117.0574],
  [-31.9787, 117.0592],
  [-31.9782, 117.0798],
  [-31.9788, 117.0820],
  [-31.9799, 117.0841],
  [-31.9809, 117.0928],
  [-31.9808, 117.0942],
  [-31.9798, 117.0969],
  [-31.9797, 117.0988],
  [-31.9792, 117.1004],
  [-31.9786, 117.1015],
  [-31.9784, 117.1026],
  [-31.9785, 117.1038],
  [-31.9806, 117.1096],
  [-31.9807, 117.1111],
  [-31.9803, 117.1127],
  [-31.9803, 117.1140],
  [-31.9809, 117.1153],
  [-31.9841, 117.1195],
  [-31.9850, 117.1217],
  [-31.9860, 117.1390],
  [-31.9866, 117.1414],
  [-31.9894, 117.1489],
  [-31.9902, 117.1503],
  [-31.9910, 117.1514],
  [-31.9917, 117.1529],
  [-31.9935, 117.1598],
  [-31.9941, 117.1614],
  [-31.9973, 117.1673],
  [-31.9977, 117.1690],
  [-31.9980, 117.1732],
  [-31.9984, 117.1757],
  [-31.9989, 117.1769],
  [-31.9997, 117.1781],
  [-32.0000, 117.1791],
  [-32.0002, 117.1799],
  [-32.0007, 117.1807],
  [-32.0021, 117.1819],
  [-32.0030, 117.1836],
  [-32.0042, 117.1880],
  [-32.0040, 117.1911],
  [-32.0045, 117.1931],
  [-32.0058, 117.1947],
  [-32.0111, 117.1967],
  [-32.0162, 117.1997],
  [-32.0304, 117.2119],
  [-32.0482, 117.2382],
  [-32.0489, 117.2411],
  [-32.0484, 117.2449],
  [-32.0485, 117.2469],
  [-32.0488, 117.2491],
  [-32.0480, 117.2662],
  [-32.0477, 117.2686],
  [-32.0407, 117.2869],
  [-32.0404, 117.2886],
  [-32.0405, 117.2939],
  [-32.0408, 117.2955],
  [-32.0422, 117.2995],
  [-32.0436, 117.3022],
  [-32.0441, 117.3052],
  [-32.0448, 117.3075],
  [-32.0456, 117.3090],
  [-32.0491, 117.3128],
  [-32.0510, 117.3162],
  [-32.0514, 117.3185],
  [-32.0506, 117.3208],
  [-32.0485, 117.3228],
  [-32.0468, 117.3232],
  [-32.0447, 117.3230],
  [-32.0435, 117.3232],
  [-32.0423, 117.3246],
  [-32.0419, 117.3260],
  [-32.0420, 117.3318],
  [-32.0417, 117.3332],
  [-32.0389, 117.3374],
  [-32.0376, 117.3385],
  [-32.0317, 117.3420],
  [-32.0309, 117.3429],
  [-32.0305, 117.3437],
  [-32.0290, 117.3504],
  [-32.0289, 117.3518],
  [-32.0290, 117.3537],
  [-32.0284, 117.3559],
  [-32.0275, 117.3578],
  [-32.0193, 117.3838],
  [-32.0145, 117.3921],
  [-32.0121, 117.3979]
];

const quairadingYoting = [
  [-32.0121, 117.3979],
  [-32.0097, 117.4036],
  [-32.0093, 117.4050],
  [-32.0093, 117.4113],
  [-32.0088, 117.4133],
  [-32.0060, 117.4191],
  [-32.0056, 117.4212],
  [-32.0055, 117.4374],
  [-32.0024, 117.4513],
  [-32.0016, 117.4597],
  [-32.0017, 117.4616],
  [-32.0029, 117.4658],
  [-32.0031, 117.4681],
  [-31.9987, 117.4970],
  [-31.9999, 117.5059],
  [-31.9982, 117.5249],
  [-31.9913, 117.5412],
  [-31.9903, 117.5472],
  [-31.9811, 117.5704],
  [-31.9693, 117.5883],
  [-31.9633, 117.5944]
];

const yotingShackleton = [
  [-31.9633, 117.5944],
  [-31.9621, 117.5956],
  [-31.9614, 117.5970],
  [-31.9533, 117.6160],
  [-31.9530, 117.6177],
  [-31.9533, 117.6293],
  [-31.9535, 117.6307],
  [-31.9544, 117.6329],
  [-31.9556, 117.6343],
  [-31.9572, 117.6352],
  [-31.9581, 117.6360],
  [-31.9585, 117.6370],
  [-31.9586, 117.6388],
  [-31.9581, 117.6400],
  [-31.9556, 117.6432],
  [-31.9548, 117.6452],
  [-31.9547, 117.6467],
  [-31.9551, 117.6496],
  [-31.9548, 117.6519],
  [-31.9531, 117.6557],
  [-31.9494, 117.6618],
  [-31.9489, 117.6629],
  [-31.9489, 117.6639],
  [-31.9490, 117.6655],
  [-31.9486, 117.6669],
  [-31.9385, 117.6792],
  [-31.9379, 117.6808],
  [-31.9362, 117.6862],
  [-31.9363, 117.6880],
  [-31.9384, 117.6937],
  [-31.9395, 117.6950],
  [-31.9414, 117.6955],
  [-31.9425, 117.6963],
  [-31.9432, 117.6977],
  [-31.9433, 117.7011],
  [-31.9438, 117.7032],
  [-31.9457, 117.7073],
  [-31.9462, 117.7097],
  [-31.9460, 117.7159],
  [-31.9453, 117.7172],
  [-31.9439, 117.7188],
  [-31.9433, 117.7202],
  [-31.9431, 117.7223],
  [-31.9428, 117.7237],
  [-31.9413, 117.7266],
  [-31.9409, 117.7289],
  [-31.9411, 117.7309],
  [-31.9425, 117.7335],
  [-31.9428, 117.7350],
  [-31.9421, 117.7395],
  [-31.9411, 117.7410],
  [-31.9392, 117.7423],
  [-31.9373, 117.7452],
  [-31.9340, 117.7483],
  [-31.9330, 117.7498],
  [-31.9286, 117.7581],
  [-31.9281, 117.7600],
  [-31.9284, 117.7696],
  [-31.9282, 117.7708],
  [-31.9275, 117.7734],
  [-31.9274, 117.7748],
  [-31.9326, 117.8069],
  [-31.9350, 117.8149],
  [-31.9351, 117.8168],
  [-31.9330, 117.8356]
];

const shackletonBruceRock = [
  [-31.9330, 117.8356],
  [-31.9320, 117.8440],
  [-31.9292, 117.8567],
  [-31.9292, 117.8590],
  [-31.9303, 117.8623],
  [-31.9314, 117.8748],
  [-31.9314, 117.9157],
  [-31.9316, 117.9172],
  [-31.9323, 117.9187],
  [-31.9350, 117.9222],
  [-31.9356, 117.9237],
  [-31.9355, 117.9250],
  [-31.9342, 117.9284],
  [-31.9328, 117.9303],
  [-31.9322, 117.9321],
  [-31.9323, 117.9491],
  [-31.9320, 117.9508],
  [-31.9252, 117.9693],
  [-31.9216, 117.9763],
  [-31.9203, 117.9780],
  [-31.9099, 117.9853],
  [-31.9050, 117.9921],
  [-31.9043, 117.9940],
  [-31.9011, 118.0193],
  [-31.9009, 118.0495],
  [-31.9008, 118.0886],
  [-31.9005, 118.0913],
  [-31.8913, 118.1110],
  [-31.8909, 118.1132],
  [-31.8890, 118.1471],
  [-31.8886, 118.1484],
  [-31.8874, 118.1497]
];

export const yorkBruceRock: Line = {
  name: 'york - Bruce Rock',
  state: 'WA',
  segments: [
    {
      segments: [yorkGreenhills],
      history: {
        opened: {
          date: '1898-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greenhillsQuairading],
      history: {
        opened: {
          date: '1908-04-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [quairadingYoting],
      history: {
        opened: {
          date: '1913-07-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yotingShackleton],
      history: {
        opened: {
          date: '1913-07-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [shackletonBruceRock],
      history: {
        opened: {
          date: '1913-07-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-10-01',
          status: 'closed'
        }]
      }
    }
  ]
};
