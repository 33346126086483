import { Line } from "../../trackTypes";

const kewLine = [
  [-37.8209, 145.0296],
  [-37.8208, 145.0302],
  [-37.8203, 145.0312],
  [-37.8198, 145.0318],
  [-37.8192, 145.0320],
  [-37.8184, 145.0321],
  [-37.8178, 145.0320],
  [-37.8143, 145.0296],
  [-37.8128, 145.0292],
  [-37.8120, 145.0291],
  [-37.8113, 145.0291],
  [-37.8095, 145.0294]
];

export const kew: Line = {
  name: 'Kew',
  state: 'VIC',
  segments: [
    {
      segments: [kewLine],
      history: {
        opened: {
          date: '1887-12-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
        }, {
          date: '1957-05-13',
          status: 'closed'
        }]
      }
    }
  ]
}
