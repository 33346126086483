import { Line } from "../../../trackTypes";

const junction = [
  [-32.0310, 115.9636],
  [-32.0324, 115.9642],
  [-32.0334, 115.9642],
  [-32.0345, 115.9638]
];

const freightAllignment = [
  [-32.0345, 115.9638],
  [-32.0447, 115.9551],
  [-32.0454, 115.9544],
  [-32.0473, 115.9521]
];

export const thornlie: Line = {
  name: 'Thornlie',
  state: 'WA',
  segments: [
    {
      segments: [{
        name: 'Beckenham Tunnel',
        segment: junction,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '2005-08-07',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [freightAllignment],
      history: {
        opened: {
          date: '1968-08-03',
          tracks: 2,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-08-07',
          tracks: 3,
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
