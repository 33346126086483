import { Line } from "../../trackTypes";

const lubeckRupanyup = [
  [-36.7459, 142.5513],
  [-36.7451, 142.5511],
  [-36.7444, 142.5511],
  [-36.7437, 142.5513],
  [-36.7292, 142.5582],
  [-36.7152, 142.5649],
  [-36.7094, 142.5680],
  [-36.6771, 142.5916],
  [-36.6706, 142.5941],
  [-36.6602, 142.5978],
  [-36.6550, 142.6007],
  [-36.6490, 142.6062],
  [-36.6301, 142.6283],
  [-36.6285, 142.6304],
  [-36.6277, 142.6324]
];

const rupanyupMarnoo = [
  [-36.6277, 142.6324],
  [-36.6265, 142.6349],
  [-36.6256, 142.6363],
  [-36.6252, 142.6378],
  [-36.6251, 142.6392],
  [-36.6254, 142.6441],
  [-36.6253, 142.6709],
  [-36.6250, 142.6733],
  [-36.6243, 142.6759],
  [-36.6243, 142.7039],
  [-36.6238, 142.7064],
  [-36.6234, 142.7077],
  [-36.6173, 142.7250],
  [-36.6165, 142.7286],
  [-36.6161, 142.7314],
  [-36.6156, 142.7409],
  [-36.6156, 142.8192],
  [-36.6161, 142.8226],
  [-36.6170, 142.8244],
  [-36.6181, 142.8259],
  [-36.6285, 142.8379],
  [-36.6406, 142.8484],
  [-36.6422, 142.8494],
  [-36.6492, 142.8526],
  [-36.6507, 142.8534],
  [-36.6676, 142.8679],
  [-36.6683, 142.8689],
  [-36.6691, 142.8709],
  [-36.6695, 142.8752]
];

const marnooBolangum = [
  [-36.6695, 142.8752],
  [-36.6701, 142.8774],
  [-36.6709, 142.8787],
  [-36.6720, 142.8796],
  [-36.6824, 142.8847],
  [-36.6850, 142.8853],
  [-36.7575, 142.8852]
];

export const bolangum: Line = {
  name: 'Bolangum',
  state: 'VIC',
  segments: [
    {
      segments: [lubeckRupanyup],
      history: {
        opened: {
          date: '1887-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-11-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rupanyupMarnoo],
      history: {
        opened: {
          date: '1909-06-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-11-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [marnooBolangum],
      history: {
        opened: {
          date: '1927-07-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-11-28',
          status: 'closed'
        }]
      }
    }
  ]
};
