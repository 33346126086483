import { Line } from "../../trackTypes";

const moontaWallaroo = [
  [-34.0496, 137.5654],
  [-34.0542, 137.5734],
  [-34.0554, 137.5747],
  [-34.0559, 137.5754],
  [-34.0613, 137.5875],
  [-34.0666, 137.5933],
  [-34.0694, 137.5986],
  [-34.0701, 137.6008],
  [-34.0706, 137.6041],
  [-34.0699, 137.6065],
  [-34.0663, 137.6108],
  [-34.0635, 137.6121],
  [-33.9715, 137.6187],
  [-33.9574, 137.6238],
  [-33.9556, 137.6239],
  [-33.9541, 137.6236],
  [-33.9362, 137.6134],
  [-33.9342, 137.6125],
  [-33.9329, 137.6127],
  [-33.9311, 137.6138],
  [-33.9303, 137.6152],
  [-33.9292, 137.6190],
  [-33.9284, 137.6210],
  [-33.9283, 137.6215],
  [-33.9283, 137.6221],
  [-33.9290, 137.6241],
  [-33.9293, 137.6247],
  [-33.9297, 137.6250],
  [-33.9307, 137.6255],
  [-33.9316, 137.6263]
];

const kadinaWallaroo = [
  [-33.9648, 137.7128],
  [-33.9630, 137.7052],
  [-33.9629, 137.7036],
  [-33.9629, 137.7024],
  [-33.9645, 137.6968],
  [-33.9647, 137.6954],
  [-33.9647, 137.6942],
  [-33.9644, 137.6929],
  [-33.9425, 137.6404],
  [-33.9416, 137.6393],
  [-33.9380, 137.6371],
  [-33.9372, 137.6364],
  [-33.9367, 137.6357],
  [-33.9346, 137.6307],
  [-33.9325, 137.6279],
  [-33.9320, 137.6268],
  [-33.9316, 137.6263]
];

const kadinaPaskeville = [
  [-33.9648, 137.7128],
  [-33.9676, 137.7245],
  [-33.9720, 137.7422],
  [-33.9728, 137.7446],
  [-34.0004, 137.8019],
  [-34.0015, 137.8054],
  [-34.0314, 137.8766],
  [-34.0322, 137.8805],
  [-34.0325, 137.8867],
  [-34.0329, 137.8881],
  [-34.0375, 137.8988],
  [-34.0382, 137.9016]
];

const paskevillePortWakefield = [
  [-34.0382, 137.9016],
  [-34.0425, 137.9231],
  [-34.0443, 137.9292],
  [-34.0455, 137.9314],
  [-34.0866, 137.9853],
  [-34.0951, 137.9963],
  [-34.0959, 137.9981],
  [-34.0960, 138.0002],
  [-34.0955, 138.0029],
  [-34.0930, 138.0083],
  [-34.0926, 138.0112],
  [-34.0933, 138.0137],
  [-34.0964, 138.0196],
  [-34.0975, 138.0205],
  [-34.1000, 138.0215],
  [-34.1013, 138.0217],
  [-34.1028, 138.0213],
  [-34.1043, 138.0213],
  [-34.1058, 138.0219],
  [-34.1069, 138.0231],
  [-34.1080, 138.0248],
  [-34.1086, 138.0250],
  [-34.1122, 138.0252],
  [-34.1141, 138.0258],
  [-34.1172, 138.0280],
  [-34.1178, 138.0289],
  [-34.1184, 138.0303],
  [-34.1194, 138.0313],
  [-34.1206, 138.0319],
  [-34.1213, 138.0329],
  [-34.1215, 138.0342],
  [-34.1210, 138.0355],
  [-34.1199, 138.0361],
  [-34.1149, 138.0351],
  [-34.1131, 138.0341],
  [-34.1119, 138.0342],
  [-34.1110, 138.0351],
  [-34.1108, 138.0365],
  [-34.1111, 138.0374],
  [-34.1116, 138.0386],
  [-34.1114, 138.0402],
  [-34.1106, 138.0410],
  [-34.1082, 138.0418],
  [-34.1073, 138.0424],
  [-34.1061, 138.0437],
  [-34.1020, 138.0461],
  [-34.0957, 138.0516],
  [-34.0949, 138.0532],
  [-34.0947, 138.0545],
  [-34.0948, 138.0567],
  [-34.0941, 138.0604],
  [-34.0889, 138.0712],
  [-34.0872, 138.0732],
  [-34.0861, 138.0750],
  [-34.0850, 138.0786],
  [-34.0849, 138.0802],
  [-34.0852, 138.0825],
  [-34.0863, 138.0850],
  [-34.0882, 138.0871],
  [-34.1148, 138.1044],
  [-34.1365, 138.1186],
  [-34.1788, 138.1464],
  [-34.1810, 138.1475],
  [-34.1817, 138.1479],
  [-34.1825, 138.1487],
  [-34.1828, 138.1498],
  [-34.1830, 138.1512]
];

const balaklavaPortWakefield = [
  [-34.1439, 138.4149],
  [-34.1445, 138.4124],
  [-34.1453, 138.4101],
  [-34.1614, 138.3000],
  [-34.1616, 138.2979],
  [-34.1601, 138.2689],
  [-34.1595, 138.2586],
  [-34.1573, 138.2176],
  [-34.1575, 138.2153],
  [-34.1615, 138.1968],
  [-34.1658, 138.1803],
  [-34.1693, 138.1735],
  [-34.1820, 138.1560],
  [-34.1827, 138.1546],
  [-34.1829, 138.1529],
  [-34.1830, 138.1512]
];

const bowmansJunction = [
  [-34.1595, 138.2586],
  [-34.1590, 138.2570],
  [-34.1579, 138.2551]
];

const bowmansJunction2 = [
  [-34.1601, 138.2689],
  [-34.1609, 138.2706],
  [-34.1619, 138.2718],
  [-34.1632, 138.2726],
  [-34.1649, 138.2730],
  [-34.1677, 138.2731],
  [-34.1702, 138.2736],
  [-34.1726, 138.2745],
  [-34.1741, 138.2748]
];

export const moonta: Line = {
  name: 'Moonta',
  state: 'SA',
  segments: [
    {
      segments: [moontaWallaroo],
      history: {
        opened: {
          date: '1880-01-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-12-02',
          gauge: 'dual'
        }, {
          date: '1993-03-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kadinaWallaroo],
      history: {
        opened: {
          date: '1880-01-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-12-02',
          gauge: 'dual'
        }, {
          date: '1993-03-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kadinaPaskeville],
      history: {
        opened: {
          date: '1878-10-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '1990-03-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paskevillePortWakefield],
      history: {
        opened: {
          date: '1878-10-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '1984-04-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balaklavaPortWakefield],
      history: {
        opened: {
          date: '1876-03-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '1984-04-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bowmansJunction, bowmansJunction2],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
