import { Line } from "../../../trackTypes";

const gympieOld = [
  [-26.1596, 152.6738],
  [-26.1546, 152.6723],
  [-26.1528, 152.6713],
  [-26.1516, 152.6711],
  [-26.1430, 152.6747],
  [-26.1415, 152.6759],
  [-26.1404, 152.6761],
  [-26.1395, 152.6756],
  [-26.1381, 152.6733],
  [-26.1377, 152.6726]
];

const gympieDeviation = [
  [-26.1535, 152.6772],
  [-26.1519, 152.6761],
  [-26.1490, 152.6751],
  [-26.1411, 152.6742],
  [-26.1377, 152.6726],
];

const gympieTamareeDeviationStart = [
  [-26.1377, 152.6726],
  [-26.1358, 152.6704]
];

const tamareeOld = [
  [-26.1358, 152.6704],
  [-26.1353, 152.6691],
  [-26.1349, 152.6677],
  [-26.1340, 152.6670],
  [-26.1322, 152.6668],
  [-26.1317, 152.6666],
  [-26.1311, 152.6660]
];

const tamareeDeviation = [
  [-26.1358, 152.6704],
  [-26.1347, 152.6694],
  [-26.1326, 152.6680],
  [-26.1313, 152.6665],
  [-26.1311, 152.6660]
];

const tamareeDeviationEndCorellaDeviationStart = [
  [-26.1311, 152.6660],
  [-26.1302, 152.6638],
  [-26.1294, 152.6628],
  [-26.1275, 152.6610],
  [-26.1266, 152.6597],
  [-26.1254, 152.6566],
  [-26.1231, 152.6540],
  [-26.1217, 152.6516],
  [-26.1195, 152.6491],
  [-26.1181, 152.6482],
  [-26.1133, 152.6466],
  [-26.1120, 152.6458],
  [-26.1058, 152.6399]
];

const corellaOld = [
  [-26.1058, 152.6399],
  [-26.1038, 152.6390],
  [-26.1027, 152.6378],
  [-26.1013, 152.6359],
  [-26.1009, 152.6350],
  [-26.1008, 152.6337],
  [-26.1012, 152.6313],
  [-26.1010, 152.6299],
  [-26.0992, 152.6269],
  [-26.0981, 152.6259],
  [-26.0970, 152.6255],
  [-26.0956, 152.6255],
  [-26.0948, 152.6252],
  [-26.0942, 152.6247],
  [-26.0933, 152.6229]
];

const corellaDeviation = [
  [-26.1058, 152.6399],
  [-26.1041, 152.6382],
  [-26.1032, 152.6368],
  [-26.1024, 152.6350],
  [-26.1012, 152.6303],
  [-26.1010, 152.6299],
  [-26.0999, 152.6279],
  [-26.0983, 152.6263],
  [-26.0956, 152.6250],
  [-26.0940, 152.6239],
  [-26.0933, 152.6229]
];

const corellaDeviationEndCurraDeviationStart = [
  [-26.0933, 152.6229],
  [-26.0930, 152.6223],
  [-26.0914, 152.6173],
  [-26.0900, 152.6151],
  [-26.0869, 152.6122],
  [-26.0834, 152.6083],
  [-26.0829, 152.6075],
  [-26.0816, 152.6050],
  [-26.0806, 152.6039],
  [-26.0762, 152.6005],
  [-26.0759, 152.6001]
];

const curraOld = [
  [-26.0759, 152.6001],
  [-26.0757, 152.5996],
  [-26.0757, 152.5990],
  [-26.0759, 152.5962],
  [-26.0758, 152.5948],
  [-26.0752, 152.5932],
  [-26.0742, 152.5922],
  [-26.0721, 152.5915],
  [-26.0713, 152.5912],
  [-26.0708, 152.5906],
  [-26.0697, 152.5881],
  [-26.0687, 152.5872],
  [-26.0681, 152.5872],
  [-26.0670, 152.5872],
  [-26.0659, 152.5870]
];

const curraDeviation = [
  [-26.0759, 152.6001],
  [-26.0752, 152.5991],
  [-26.0746, 152.5977],
  [-26.0741, 152.5952],
  [-26.0735, 152.5938],
  [-26.0725, 152.5926],
  [-26.0666, 152.5876],
  [-26.0659, 152.5870]
];

const curraDeviationEndGunaldaDeviationStart = [
  [-26.0659, 152.5870],
  [-26.0644, 152.5863],
  [-26.0624, 152.5855],
  [-26.0607, 152.5843],
  [-26.0594, 152.5829],
  [-26.0571, 152.5813],
  [-26.0536, 152.5800],
  [-26.0513, 152.5786]
];

const gunaldaOld = [
  [-26.0513, 152.5786],
  [-26.0506, 152.5772],
  [-26.0493, 152.5762],
  [-26.0481, 152.5750],
  [-26.0460, 152.5712],
  [-26.0428, 152.5670],
  [-26.0407, 152.5664],
  [-26.0362, 152.5668],
  [-26.0345, 152.5665],
  [-26.0319, 152.5655],
  [-26.0309, 152.5646],
  [-26.0303, 152.5633],
  [-26.0290, 152.5618],
  [-26.0251, 152.5599],
  [-26.0230, 152.5600],
  [-26.0212, 152.5610],
  [-26.0193, 152.5619],
  [-26.0171, 152.5621],
  [-26.0158, 152.5620],
  [-26.0146, 152.5624],
  [-26.0129, 152.5634],
  [-26.0115, 152.5638],
  [-26.0093, 152.5640],
  [-26.0081, 152.5645],
  [-26.0060, 152.5658],
  [-26.0046, 152.5657],
  [-26.0038, 152.5651],
  [-26.0031, 152.5640],
  [-26.0028, 152.5627],
  [-26.0021, 152.5614],
  [-25.9999, 152.5594],
  [-25.9988, 152.5594],
  [-25.9980, 152.5599],
  [-25.9968, 152.5612],
  [-25.9897, 152.5629],
  [-25.9883, 152.5625],
  [-25.9876, 152.5614],
  [-25.9876, 152.5599],
  [-25.9888, 152.5573],
  [-25.9888, 152.5558],
  [-25.9876, 152.5532],
  [-25.9866, 152.5524],
  [-25.9850, 152.5520],
  [-25.9838, 152.5518],
  [-25.9824, 152.5511],
  [-25.9809, 152.5495],
  [-25.9791, 152.5483],
  [-25.9759, 152.5478],
  [-25.9748, 152.5470],
  [-25.9737, 152.5446],
  [-25.9729, 152.5436],
  [-25.9690, 152.5415],
  [-25.9668, 152.5404],
  [-25.9656, 152.5392],
  [-25.9651, 152.5377],
  [-25.9644, 152.5369],
  [-25.9634, 152.5366],
  [-25.9604, 152.5369],
  [-25.9585, 152.5381],
  [-25.9570, 152.5399],
  [-25.9552, 152.5411]
];

const gunaldaDeviation = [
  [-26.0513, 152.5786],
  [-26.0439, 152.5711],
  [-26.0408, 152.5690],
  [-26.0227, 152.5620],
  [-26.0206, 152.5617],
  [-26.0191, 152.5617],
  [-26.0139, 152.5626],
  [-26.0096, 152.5623],
  [-26.0062, 152.5616],
  [-26.0029, 152.5599],
  [-25.9979, 152.5560],
  [-25.9933, 152.5537],
  [-25.9873, 152.5524],
  [-25.9832, 152.5506],
  [-25.9760, 152.5453],
  [-25.9735, 152.5437],
  [-25.9673, 152.5411],
  [-25.9610, 152.5402],
  [-25.9552, 152.5411]
];

const gunaldaDeviationEndPatersonDeviationStart = [
  [-25.9552, 152.5411],
  [-25.9475, 152.5443],
  [-25.9446, 152.5452],
  [-25.9272, 152.5493]
];

const patersonOld = [
  [-25.8931, 152.5468],
  [-25.8939, 152.5468],
  [-25.8960, 152.5458],
  [-25.8971, 152.5457],
  [-25.8983, 152.5465],
  [-25.9000, 152.5469],
  [-25.9098, 152.5458],
  [-25.9122, 152.5459],
  [-25.9142, 152.5453],
  [-25.9157, 152.5454],
  [-25.9168, 152.5466],
  [-25.9172, 152.5483],
  [-25.9189, 152.5501],
  [-25.9209, 152.5502],
  [-25.9230, 152.5495],
  [-25.9240, 152.5492],
  [-25.9272, 152.5493]
];

const patersonDeviation = [
  [-25.8931, 152.5468],
  [-25.9046, 152.5528],
  [-25.9142, 152.5545],
  [-25.9159, 152.5543],
  [-25.9260, 152.5496],
  [-25.9272, 152.5493]
];

const patersonDeviationEndGundiahDeviationStart = [
  [-25.8931, 152.5468],
  [-25.8920, 152.5462],
  [-25.8909, 152.5460],
  [-25.8897, 152.5463],
  [-25.8743, 152.5526],
  [-25.8716, 152.5549],
  [-25.8705, 152.5555],
  [-25.8565, 152.5571],
  [-25.8554, 152.5568],
  [-25.8537, 152.5554],
  [-25.8518, 152.5543]
];

const gundiahOld = [
  [-25.8518, 152.5543],
  [-25.8508, 152.5533],
  [-25.8488, 152.5503],
  [-25.8462, 152.5484],
  [-25.8428, 152.5475],
  [-25.8387, 152.5475],
  [-25.8375, 152.5466],
  [-25.8370, 152.5455],
  [-25.8361, 152.5445]
];

const gundiahDeviation = [
  [-25.8361, 152.5445],
  [-25.8378, 152.5451],
  [-25.8403, 152.5465],
  [-25.8496, 152.5533],
  [-25.8518, 152.5543]
];

const gundiahDeviationEndNetherbyDeviationStart = [
  [-25.8361, 152.5445],
  [-25.8303, 152.5422],
  [-25.8287, 152.5421],
  [-25.8277, 152.5422],
  [-25.8202, 152.5446],
  [-25.8068, 152.5474],
  [-25.8003, 152.5474],
  [-25.7972, 152.5469],
  [-25.7954, 152.5462],
  [-25.7941, 152.5460],
  [-25.7922, 152.5462],
  [-25.7871, 152.5458],
  [-25.7743, 152.5460],
  [-25.7726, 152.5456]
];

const netherbyOld = [
  [-25.7726, 152.5456],
  [-25.7713, 152.5461],
  [-25.7706, 152.5468],
  [-25.7704, 152.5481],
  [-25.7707, 152.5521],
  [-25.7699, 152.5536],
  [-25.7685, 152.5543],
  [-25.7656, 152.5537],
  [-25.7648, 152.5530],
  [-25.7642, 152.5521],
  [-25.7635, 152.5514],
  [-25.7624, 152.5513],
  [-25.7606, 152.5520],
  [-25.7600, 152.5521],
  [-25.7589, 152.5518],
  [-25.7579, 152.5509],
  [-25.7571, 152.5505],
  [-25.7549, 152.5503],
  [-25.7539, 152.5506]
];

const netherbyDeviation = [
  [-25.7726, 152.5456],
  [-25.7694, 152.5452],
  [-25.7643, 152.5458],
  [-25.7558, 152.5492],
  [-25.7539, 152.5506]
];

const netherbyDeviationEndMaryboroughJunction = [
  [-25.7539, 152.5506],
  [-25.7461, 152.5566],
  [-25.7451, 152.5569],
  [-25.7404, 152.5576],
  [-25.7382, 152.5586],
  [-25.7366, 152.5601],
  [-25.7334, 152.5670],
  [-25.7324, 152.5709],
  [-25.7312, 152.5727],
  [-25.7277, 152.5772],
  [-25.7272, 152.5781],
  [-25.7269, 152.5793],
  [-25.7263, 152.5804],
  [-25.7250, 152.5812],
  [-25.7173, 152.5826],
  [-25.7095, 152.5858],
  [-25.7076, 152.5861],
  [-25.7060, 152.5859],
  [-25.7016, 152.5846],
  [-25.7000, 152.5846],
  [-25.6913, 152.5884],
  [-25.6901, 152.5894],
  [-25.6878, 152.5930],
  [-25.6850, 152.5960],
  [-25.6836, 152.5967],
  [-25.6779, 152.6014],
  [-25.6757, 152.6038],
  [-25.6745, 152.6045],
  [-25.6732, 152.6046],
  [-25.6703, 152.6043],
  [-25.6691, 152.6045],
  [-25.6673, 152.6055],
  [-25.6662, 152.6058],
  [-25.6568, 152.6068],
  [-25.6556, 152.6067],
  [-25.6538, 152.6058],
  [-25.6467, 152.6042],
  [-25.6417, 152.6042],
  [-25.6407, 152.6039],
  [-25.6370, 152.6017],
  [-25.6359, 152.6013],
  [-25.6336, 152.6010],
  [-25.6326, 152.6004],
  [-25.6322, 152.5993],
  [-25.6322, 152.5979],
  [-25.6316, 152.5966],
  [-25.6303, 152.5960],
  [-25.6296, 152.5962],
  [-25.6285, 152.5968],
  [-25.6276, 152.5970],
  [-25.6232, 152.5962],
  [-25.6224, 152.5956],
  [-25.6218, 152.5948],
  [-25.6210, 152.5943],
  [-25.6196, 152.5942],
  [-25.6151, 152.5933],
  [-25.6142, 152.5933],
  [-25.6099, 152.5942],
  [-25.6089, 152.5941],
  [-25.6076, 152.5935],
  [-25.6067, 152.5934],
  [-25.5966, 152.5949],
  [-25.5957, 152.5953],
  [-25.5911, 152.5991],
  [-25.5897, 152.5998],
  [-25.5763, 152.6022],
  [-25.5751, 152.6021],
  [-25.5726, 152.6013],
  [-25.5707, 152.6013],
  [-25.5689, 152.6025],
  [-25.5674, 152.6042],
  [-25.5666, 152.6048],
  [-25.5639, 152.6062],
  [-25.5624, 152.6077],
  [-25.5611, 152.6099],
  [-25.5601, 152.6108],
  [-25.5587, 152.6115],
  [-25.5575, 152.6117],
  [-25.5565, 152.6113],
  [-25.5541, 152.6092],
  [-25.5529, 152.6087],
  [-25.5519, 152.6086],
  [-25.5342, 152.6133],
  [-25.5303, 152.6135],
  [-25.5283, 152.6141],
  [-25.5269, 152.6154],
  [-25.5262, 152.6168],
  [-25.5255, 152.6187],
  [-25.5245, 152.6202],
  [-25.5227, 152.6215],
  [-25.5209, 152.6220],
  [-25.5198, 152.6229],
  [-25.5193, 152.6240],
  [-25.5179, 152.6296],
  [-25.5174, 152.6306],
  [-25.5165, 152.6314],
  [-25.5124, 152.6343]
];

const maryboroughBranch = [
  [-25.5124, 152.6343],
  [-25.5115, 152.6354],
  [-25.5096, 152.6388],
  [-25.5089, 152.6401],
  [-25.5083, 152.6419],
  [-25.5080, 152.6439],
  [-25.5081, 152.6482],
  [-25.5084, 152.6501],
  [-25.5090, 152.6517],
  [-25.5140, 152.6599],
  [-25.5148, 152.6622],
  [-25.5156, 152.6656],
  [-25.5190, 152.6753],
  [-25.5195, 152.6764],
  [-25.5205, 152.6780],
  [-25.5326, 152.6941],
  [-25.5341, 152.6954],
  [-25.5353, 152.6963],
  [-25.5359, 152.6968],
  [-25.5378, 152.6995]
];

const maryboroughDeviation = [
  [-25.5124, 152.6343],
  [-25.5103, 152.6357],
  [-25.5079, 152.6366],
  [-25.5031, 152.6384],
  [-25.5011, 152.6394],
  [-25.4867, 152.6495],
  [-25.4840, 152.6524],
  [-25.4826, 152.6554],
  [-25.4821, 152.6574],
  [-25.4816, 152.6604],
  [-25.4802, 152.6642],
  [-25.4776, 152.6671],
  [-25.4743, 152.6688],
  [-25.4712, 152.6691],
  [-25.4674, 152.6679]
];

const maryboroughDeviationJunction = [
  [-25.5079, 152.6366],
  [-25.5089, 152.6366],
  [-25.5095, 152.6370],
  [-25.5098, 152.6376],
  [-25.5099, 152.6380],
  [-25.5096, 152.6388]
];

const baddowDeviationEnd = [
  [-25.5205, 152.6780],
  [-25.5198, 152.6775],
  [-25.5190, 152.6773],
  [-25.5181, 152.6775],
  [-25.5171, 152.6778],
  [-25.5162, 152.6778],
  [-25.5153, 152.6775],
  [-25.5102, 152.6754],
  [-25.5087, 152.6750],
  [-25.4816, 152.6742],
  [-25.4803, 152.6739],
  [-25.4796, 152.6734],
  [-25.4782, 152.6720],
  [-25.4775, 152.6716],
  [-25.4769, 152.6715],
  [-25.4752, 152.6717],
  [-25.4736, 152.6716],
  [-25.4720, 152.6710],
  [-25.4674, 152.6679]
];

const baddowJunction = [
  [-25.5190, 152.6753],
  [-25.5191, 152.6762],
  [-25.5189, 152.6768],
  [-25.5186, 152.6771],
  [-25.5181, 152.6775]
];

const deviationEndHoward = [
  [-25.4674, 152.6679],
  [-25.4577, 152.6619],
  [-25.4530, 152.6604],
  [-25.4466, 152.6602],
  [-25.4426, 152.6591],
  [-25.4053, 152.6420],
  [-25.3538, 152.6056],
  [-25.3517, 152.6036],
  [-25.3349, 152.5836],
  [-25.3321, 152.5801],
  [-25.3280, 152.5723],
  [-25.3265, 152.5706],
  [-25.3242, 152.5690],
  [-25.3218, 152.5679],
  [-25.3203, 152.5668],
  [-25.3185, 152.5646]
];

const howardGoodwood = [
  [-25.3185, 152.5646],
  [-25.3147, 152.5601],
  [-25.3138, 152.5583],
  [-25.3134, 152.5565],
  [-25.3129, 152.5502],
  [-25.3122, 152.5484],
  [-25.3099, 152.5450],
  [-25.3090, 152.5427],
  [-25.3081, 152.5363],
  [-25.3071, 152.5331],
  [-25.3000, 152.5219],
  [-25.2983, 152.5203],
  [-25.2926, 152.5170],
  [-25.2872, 152.5121],
  [-25.2855, 152.5111],
  [-25.2766, 152.5090],
  [-25.2668, 152.5012],
  [-25.2375, 152.4573],
  [-25.2337, 152.4536],
  [-25.2285, 152.4508],
  [-25.1768, 152.4392],
  [-25.1753, 152.4387],
  [-25.1709, 152.4361],
  [-25.1681, 152.4337],
  [-25.1672, 152.4332],
  [-25.1656, 152.4327],
  [-25.1638, 152.4316],
  [-25.1625, 152.4298],
  [-25.1571, 152.4179],
  [-25.1549, 152.4156],
  [-25.1480, 152.4116],
  [-25.1453, 152.4109]
];

const goodwoodBundaberg = [
  [-25.1453, 152.4109],
  [-25.1236, 152.4119],
  [-25.1211, 152.4116],
  [-25.0929, 152.4047],
  [-25.0753, 152.3997],
  [-25.0026, 152.3872],
  [-25.0005, 152.3866],
  [-24.9896, 152.3817],
  [-24.9845, 152.3777],
  [-24.9832, 152.3761],
  [-24.9822, 152.3754],
  [-24.9134, 152.3616],
  [-24.9122, 152.3611],
  [-24.8960, 152.3533],
  [-24.8931, 152.3525],
  [-24.8881, 152.3518],
  [-24.8681, 152.3451]
];

const bundabergNorthBundaberg = [
  [-24.8681, 152.3451],
  [-24.8608, 152.3427],
  [-24.8603, 152.3424],
  [-24.8598, 152.3417],
  [-24.8597, 152.3412],
  [-24.8592, 152.3373],
  [-24.8592, 152.3364],
  [-24.8592, 152.3358]
];

export const northCoastGympieBundaberg: Line = {
  name: 'North Coast (Gympie - Bundaberg)',
  state: 'QLD',
  segments: [
    {
      segments: [{
          date: '1989-02-04',
          original: [gympieOld],
          deviation: [gympieDeviation]
        },
        gympieTamareeDeviationStart,
        {
          date: '1989-02-04',
          original: [tamareeOld],
          deviation: [tamareeDeviation]
        },
        tamareeDeviationEndCorellaDeviationStart,
        {
          date: '1989-02-04',
          original: [corellaOld],
          deviation: [corellaDeviation]
        },
        corellaDeviationEndCurraDeviationStart,
        {
          date: '1989-02-04',
          original: [curraOld],
          deviation: [curraDeviation]
        },
        curraDeviationEndGunaldaDeviationStart,
        {
          date: '1989-02-04',
          original: [gunaldaOld],
          deviation: [gunaldaDeviation]
        },
        gunaldaDeviationEndPatersonDeviationStart,
        {
          date: '1989-02-04',
          original: [patersonOld],
          deviation: [patersonDeviation]
        },
        patersonDeviationEndGundiahDeviationStart,
        {
          date:'1989-02-04',
          original: [gundiahOld],
          deviation: [gundiahDeviation]
        },
        gundiahDeviationEndNetherbyDeviationStart,
        {
          date: '1989-02-04',
          original: [netherbyOld],
          deviation: [netherbyDeviation]
        },
        netherbyDeviationEndMaryboroughJunction
      ],
      history: {
        opened: {
          date: '1881-08-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-02-04',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [maryboroughBranch],
      history: {
        opened: {
          date: '1881-08-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [baddowDeviationEnd],
      history: {
        opened: {
          date: '1883-03-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-05-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [baddowJunction],
      history: {
        opened: {
          date: '1883-03-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-05-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maryboroughDeviation],
      history: {
        opened: {
          date: '1988-05-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [maryboroughDeviationJunction],
      history: {
        opened: {
          date: '1989-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [deviationEndHoward],
      history: {
        opened: {
          date: '1883-03-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [howardGoodwood],
      history: {
        opened: {
          date: '1887-08-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [goodwoodBundaberg],
      history: {
        opened: {
          date: '1888-02-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [bundabergNorthBundaberg],
      history: {
        opened: {
          date: '1891-06-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
