import { Line } from "../../trackTypes";

const halletCoveWillunga = [
  [-35.0661, 138.5056],
  [-35.0681, 138.5059],
  [-35.0696, 138.5066],
  [-35.0703, 138.5073],
  [-35.0705, 138.5081],
  [-35.0706, 138.5104],
  [-35.0712, 138.5122],
  [-35.0718, 138.5129],
  [-35.0729, 138.5135],
  [-35.0774, 138.5144],
  [-35.0788, 138.5153],
  [-35.0797, 138.5169],
  [-35.0798, 138.5195],
  [-35.0793, 138.5212],
  [-35.0774, 138.5249],
  [-35.0772, 138.5258],
  [-35.0767, 138.5326],
  [-35.0767, 138.5335],
  [-35.0783, 138.5392],
  [-35.0784, 138.5406],
  [-35.0782, 138.5421],
  [-35.0781, 138.5428],
  [-35.0785, 138.5441],
  [-35.0800, 138.5451],
  [-35.0815, 138.5456],
  [-35.0835, 138.5457],
  [-35.0880, 138.5458],
  [-35.0892, 138.5455],
  [-35.0921, 138.5430],
  [-35.1017, 138.5310],
  [-35.1035, 138.5295],
  [-35.1063, 138.5287],
  [-35.1365, 138.5309],
  [-35.1377, 138.5306],
  [-35.1424, 138.5283],
  [-35.1433, 138.5276],
  [-35.1467, 138.5239],
  [-35.1476, 138.5234],
  [-35.1516, 138.5219],
  [-35.1574, 138.5197],
  [-35.1601, 138.5178],
  [-35.1617, 138.5158],
  [-35.1628, 138.5135],
  [-35.1647, 138.5078],
  [-35.1680, 138.5006],
  [-35.1690, 138.4993],
  [-35.1700, 138.4989],
  [-35.1720, 138.4990],
  [-35.1731, 138.4986],
  [-35.1738, 138.4977],
  [-35.1802, 138.4873],
  [-35.1806, 138.4865],
  //same as currect seaford line
  [-35.1839, 138.4847],
  [-35.1865, 138.4838],
  [-35.1884, 138.4835],
  [-35.1903, 138.4834],
  //same as current seaford line
  //future aldinga extension
  [-35.1918, 138.4836],
  [-35.1944, 138.4850],
  [-35.1973, 138.4879],
  [-35.1989, 138.4893],
  [-35.2008, 138.4902],
  [-35.2038, 138.4907],
  [-35.2056, 138.4907],
  //future aldinga extension
  [-35.2065, 138.4907],
  [-35.2070, 138.4909],
  [-35.2073, 138.4914],
  [-35.2074, 138.4935],
  [-35.2077, 138.4948],
  [-35.2091, 138.4979],
  [-35.2099, 138.5006],
  [-35.2126, 138.5049],
  [-35.2129, 138.5063],
  [-35.2128, 138.5074],
  [-35.2112, 138.5119],
  [-35.2106, 138.5128],
  [-35.2091, 138.5137],
  [-35.2083, 138.5148],
  [-35.2080, 138.5162],
  [-35.2081, 138.5174],
  [-35.2142, 138.5347],
  [-35.2143, 138.5364],
  [-35.2143, 138.5364],
  [-35.2146, 138.5386],
  [-35.2181, 138.5489],
  [-35.2194, 138.5510],
  [-35.2215, 138.5526],
  [-35.2317, 138.5573],
  [-35.2332, 138.5576],
  [-35.2597, 138.5596],
  [-35.2608, 138.5593],
  [-35.2621, 138.5586],
  [-35.2669, 138.5526],
  [-35.2685, 138.5514],
  [-35.2708, 138.5502]
];

export const willunga: Line = {
  name: 'Willunga',
  state: 'SA',
  segments: [
    {
      segments: [halletCoveWillunga],
      history: {
        opened: {
          date: '1915-01-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
