import { Line } from "../../../../trackTypes";

const paddington = [
  [-27.47092, 153.02370],
  [-27.47093, 153.02358],
  [-27.47088, 153.02347],
  [-27.46692, 153.01962],
  [-27.46680, 153.01949],
  [-27.46669, 153.01930],
  [-27.46600, 153.01666],
  [-27.46596, 153.01642],
  [-27.46595, 153.01619],
  [-27.46615, 153.01489],
  [-27.46638, 153.01344],
  [-27.46640, 153.01327],
  [-27.46638, 153.01314],
  [-27.46632, 153.01297],
  [-27.46618, 153.01277],
  [-27.46608, 153.01267],
  [-27.46595, 153.01260],
  [-27.46578, 153.01259],
  [-27.46559, 153.01265],
  [-27.46540, 153.01276],
  [-27.46527, 153.01289],
  [-27.46507, 153.01313],
  [-27.46498, 153.01318],
  [-27.46489, 153.01317],
  [-27.46460, 153.01285],
  [-27.46387, 153.01208],
  [-27.46372, 153.01185],
  [-27.46314, 153.01064],
  [-27.46251, 153.00931],
  [-27.46243, 153.00909],
  [-27.46237, 153.00883],
  [-27.46226, 153.00795],
  [-27.46189, 153.00651],
  [-27.46118, 153.00444],
  [-27.46112, 153.00432],
  [-27.46059, 153.00340],
  [-27.46055, 153.00330],
  [-27.46052, 153.00316],
  [-27.46050, 153.00267],
  [-27.46048, 153.00255],
  [-27.46043, 153.00244],
  [-27.46007, 153.00211],
  [-27.45896, 153.00106],
  [-27.45884, 153.00091],
  [-27.45875, 153.00075],
  [-27.45874, 153.00059],
  [-27.45877, 152.99998],
  [-27.45873, 152.99954],
  [-27.45873, 152.99935],
  [-27.45879, 152.99919],
  [-27.45884, 152.99910],
  [-27.45916, 152.99872],
  [-27.45922, 152.99861],
  [-27.45926, 152.99850],
  [-27.45925, 152.99834],
  [-27.45898, 152.99753]
];

const paddingtonExtension = [
  [-27.45898, 152.99753],
  [-27.45859, 152.99652],
  [-27.45854, 152.99629],
  [-27.45837, 152.99470],
  [-27.45839, 152.99456]
];

const macGregorterrace = [
  [-27.45839, 152.99456],
  [-27.45844, 152.99445],
  [-27.45853, 152.99434],
  [-27.45886, 152.99403],
  [-27.45893, 152.99396],
  [-27.45896, 152.99390],
  [-27.45974, 152.99132],
  [-27.45977, 152.99121],
  [-27.45976, 152.99110],
  [-27.45955, 152.99038],
  [-27.45953, 152.99019],
  [-27.45955, 152.98982],
  [-27.45960, 152.98945],
  [-27.45964, 152.98896],
  [-27.45961, 152.98875]
];

const jubileeTerrace = [
  [-27.45961, 152.98875],
  [-27.45956, 152.98861],
  [-27.45943, 152.98854],
  [-27.45882, 152.98828],
  [-27.45870, 152.98819],
  [-27.45832, 152.98778],
  [-27.45821, 152.98759],
  [-27.45813, 152.98743],
  [-27.45811, 152.98728],
  [-27.45804, 152.98624],
  [-27.45802, 152.98604]
];

const queenStreetSouthWestJunction = [
  [-27.47088, 153.02347],
  [-27.47100, 153.02346],
  [-27.47112, 153.02340]
];

const bardon = [
  [-27.45802, 152.98604],
  [-27.45802, 152.98572],
  [-27.45819, 152.98540],
  [-27.45829, 152.98529],
  [-27.45889, 152.98484],
  [-27.45900, 152.98472],
  [-27.45938, 152.98391],
  [-27.45971, 152.98335],
  [-27.45977, 152.98318],
  [-27.45994, 152.98269],
  [-27.46000, 152.98261],
  [-27.46010, 152.98256],
  [-27.46023, 152.98253],
  [-27.46111, 152.98250],
  [-27.46119, 152.98246],
  [-27.46124, 152.98239],
  [-27.46126, 152.98229],
  [-27.46118, 152.98171],
  [-27.46111, 152.98150],
  [-27.46016, 152.97994],
  [-27.46008, 152.97976],
  [-27.45978, 152.97899],
  [-27.45956, 152.97798],
  [-27.45929, 152.97617]
];

const romaStreetJunction = [
  [-27.46602, 153.01571],
  [-27.46593, 153.01585],
  [-27.46582, 153.01593]
];

export const west2: Line = {
  name: 'West 2',
  state: 'QLD',
  segments: [
    {
      segments: [paddington],
      history: {
        opened: {
          date: '1897-12-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paddingtonExtension],
      history: {
        opened: {
          date: '1899-09-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [macGregorterrace],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [jubileeTerrace],
      history: {
        opened: {
          date: '1916-05-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [queenStreetSouthWestJunction],
      history: {
        opened: {
          date: '1932-09-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bardon],
      history: {
        opened: {
          date: '1937-01-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [romaStreetJunction],
      history: {
        opened: {
          date: '1952-04-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
