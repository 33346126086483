import { Line } from "../../../trackTypes";

const yarraJunctionPowelltown = [
  [-37.7812, 145.6133],
  [-37.7813, 145.6139],
  [-37.7816, 145.6141],
  [-37.7831, 145.6145],
  [-37.7840, 145.6150],
  [-37.7852, 145.6159],
  [-37.7862, 145.6160],
  [-37.7872, 145.6158],
  [-37.7876, 145.6158],
  [-37.7894, 145.6173],
  [-37.7926, 145.6191],
  [-37.7933, 145.6196],
  [-37.7941, 145.6204],
  [-37.7971, 145.6219],
  [-37.7998, 145.6229],
  [-37.8013, 145.6240],
  [-37.8025, 145.6248],
  [-37.8101, 145.6282],
  [-37.8112, 145.6290],
  [-37.8129, 145.6326],
  [-37.8131, 145.6337],
  [-37.8128, 145.6351],
  [-37.8120, 145.6360],
  [-37.8116, 145.6369],
  [-37.8116, 145.6376],
  [-37.8120, 145.6385],
  [-37.8128, 145.6390],
  [-37.8134, 145.6396],
  [-37.8136, 145.6405],
  [-37.8135, 145.6412],
  [-37.8132, 145.6429],
  [-37.8134, 145.6437],
  [-37.8136, 145.6440],
  [-37.8164, 145.6466],
  [-37.8188, 145.6482],
  [-37.8211, 145.6497],
  [-37.8235, 145.6506],
  [-37.8242, 145.6508],
  [-37.8250, 145.6514],
  [-37.8257, 145.6531],
  [-37.8260, 145.6543],
  [-37.8264, 145.6558],
  [-37.8266, 145.6570],
  [-37.8264, 145.6602],
  [-37.8266, 145.6618],
  [-37.8268, 145.6634],
  [-37.8271, 145.6641],
  [-37.8277, 145.6645],
  [-37.8283, 145.6647],
  [-37.8295, 145.6655],
  [-37.8298, 145.6661],
  [-37.8300, 145.6669],
  [-37.8300, 145.6683],
  [-37.8300, 145.6691],
  [-37.8299, 145.6722],
  [-37.8301, 145.6732],
  [-37.8307, 145.6739],
  [-37.8340, 145.6750],
  [-37.8349, 145.6755],
  [-37.8394, 145.6790],
  [-37.8406, 145.6797],
  [-37.8435, 145.6826],
  [-37.8450, 145.6853],
  [-37.8478, 145.6881],
  [-37.8488, 145.6896],
  [-37.8497, 145.6907],
  [-37.8501, 145.6913],
  [-37.8524, 145.6953],
  [-37.8533, 145.6986],
  [-37.8552, 145.7113],
  [-37.8560, 145.7170],
  [-37.8560, 145.7179],
  [-37.8559, 145.7191],
  [-37.8560, 145.7200],
  [-37.8571, 145.7236],
  [-37.8573, 145.7248],
  [-37.8574, 145.7267],
  [-37.8586, 145.7298],
  [-37.8599, 145.7319],
  [-37.8602, 145.7329],
  [-37.8605, 145.7375],
  [-37.8607, 145.7384],
  [-37.8628, 145.7421],
  [-37.8635, 145.7441],
  [-37.8637, 145.7455],
  [-37.8635, 145.7468],
  [-37.8631, 145.7477],
  [-37.8629, 145.7489],
  [-37.8633, 145.7523]
];

const powelltownTheBump = [
  [-37.8633, 145.7523],
  [-37.8637, 145.7533],
  [-37.8652, 145.7563],
  [-37.8666, 145.7582],
  [-37.8672, 145.7587],
  [-37.8673, 145.7591],
  [-37.8673, 145.7594],
  [-37.8671, 145.7600],
  [-37.8670, 145.7605],
  [-37.8673, 145.7617],
  [-37.8677, 145.7629],
  [-37.8677, 145.7636],
  [-37.8673, 145.7641],
  [-37.8661, 145.7649],
  [-37.8660, 145.7656],
  [-37.8662, 145.7662],
  [-37.8668, 145.7669],
  [-37.8668, 145.7681],
  [-37.8665, 145.7686],
  [-37.8661, 145.7688],
  [-37.8658, 145.7693],
  [-37.8660, 145.7703],
  [-37.8667, 145.7714],
  [-37.8670, 145.7729],
  [-37.8668, 145.7738],
  [-37.8667, 145.7740],
  [-37.8663, 145.7763],
  [-37.8671, 145.7813],
  [-37.8675, 145.7835],
  [-37.8677, 145.7879],
  [-37.8676, 145.7901],
  [-37.8678, 145.7913],
  [-37.8684, 145.7923],
  [-37.8685, 145.7930],
  [-37.8684, 145.7946],
  [-37.8678, 145.7958],
  [-37.8677, 145.7967],
  [-37.8681, 145.7979],
  [-37.8702, 145.8047]
];

const theBumpDeviation = [
  [-37.8675, 145.7835],
  [-37.8684, 145.7857],
  [-37.8685, 145.7871],
  [-37.8683, 145.7879],
  [-37.8684, 145.7886],
  [-37.8684, 145.7887],
  [-37.8690, 145.7909],
  [-37.8693, 145.7935],
  [-37.8692, 145.7952],
  [-37.8694, 145.7955],
  [-37.8697, 145.7957],
  [-37.8702, 145.7956],
  [-37.8705, 145.7959],
  [-37.8704, 145.7966],
  [-37.8699, 145.7975],
  [-37.8699, 145.7982],
  [-37.8703, 145.8000],
  [-37.8703, 145.8005],
  [-37.8701, 145.8011],
  [-37.8700, 145.8027],
  [-37.8702, 145.8047]
];

const theBumpTunnel = [
  [-37.8702, 145.8047],
  [-37.8707, 145.8057]
];

const theBumpAdaRiverJunction = [
  [-37.8707, 145.8057],
  [-37.8710, 145.8081],
  [-37.8709, 145.8124],
  [-37.8710, 145.8147],
  [-37.8723, 145.8194],
  [-37.8724, 145.8203],
  [-37.8721, 145.8212],
  [-37.8721, 145.8225],
  [-37.8729, 145.8248],
  [-37.8735, 145.8254],
  [-37.8741, 145.8265],
  [-37.8743, 145.8284],
  [-37.8747, 145.8309],
  [-37.8747, 145.8322],
  [-37.8749, 145.8334],
  [-37.8752, 145.8344],
  [-37.8750, 145.8351],
  [-37.8744, 145.8366],
  [-37.8744, 145.8374],
  [-37.8753, 145.8392],
  [-37.8761, 145.8418],
  [-37.8763, 145.8444],
  [-37.8766, 145.8453],
  [-37.8775, 145.8468],
  [-37.8785, 145.8497],
  [-37.8784, 145.8507],
  [-37.8779, 145.8513],
  [-37.8769, 145.8514],
  [-37.8755, 145.8512],
  [-37.8744, 145.8517],
  [-37.8734, 145.8529],
  [-37.8725, 145.8532],
  [-37.8719, 145.8529],
  [-37.8685, 145.8499],
  [-37.8616, 145.8395],
  [-37.8611, 145.8392],
  [-37.8468, 145.8416],
  [-37.8460, 145.8416],
  [-37.8363, 145.8407]
];

const adaRiverJunctionAdaRiverJunctionTwo = [
  [-37.8363, 145.8407],
  [-37.8361, 145.8402],
  [-37.8353, 145.8387],
  [-37.8349, 145.8376],
  [-37.8340, 145.8363],
  [-37.8334, 145.8355],
  [-37.8330, 145.8353],
  [-37.8326, 145.8354],
  [-37.8322, 145.8352],
  [-37.8318, 145.8342],
  [-37.8311, 145.8324],
  [-37.8303, 145.8316],
  [-37.8296, 145.8310],
  [-37.8290, 145.8296],
  [-37.8282, 145.8286]
];

const adaRiver = [
  [-37.8282, 145.8286],
  [-37.8274, 145.8267],
  [-37.8272, 145.8263],
  [-37.8268, 145.8261],
  [-37.8254, 145.8238],
  [-37.8246, 145.8222],
  [-37.8245, 145.8216],
  [-37.8246, 145.8211],
  [-37.8247, 145.8203],
  [-37.8246, 145.8197],
  [-37.8233, 145.8180],
  [-37.8210, 145.8147],
  [-37.8193, 145.8115],
  [-37.8182, 145.8090],
  [-37.8171, 145.8072],
  [-37.8166, 145.8068],
  [-37.8163, 145.8068],
  [-37.8161, 145.8066],
  [-37.8162, 145.8062],
  [-37.8166, 145.8056],
  [-37.8166, 145.8051],
  [-37.8162, 145.8034],
  [-37.8152, 145.8022],
  [-37.8136, 145.8010]
];

const adaRiverNo1 = [
  [-37.8282, 145.8286],
  [-37.8275, 145.8287],
  [-37.8267, 145.8295],
  [-37.8249, 145.8303]
];

const adaRiverNo2 = [
  [-37.8363, 145.8407],
  [-37.8345, 145.8405],
  [-37.8293, 145.8409],
  [-37.8263, 145.8422],
  [-37.8255, 145.8423],
  [-37.8231, 145.8413],
  [-37.8225, 145.8412],
  [-37.8207, 145.8423]
];

export const powelltown: Line = {
  name: 'Powelltown Tramway',
  state: 'VIC',
  segments: [
    {
      segments: [yarraJunctionPowelltown],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1945-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [powelltownTheBump],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1945-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [theBumpDeviation],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1945-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        {
          name: 'The Bump Tunnel',
          segment: theBumpTunnel,
          type: 'tunnel'
        },
        theBumpAdaRiverJunction
      ],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1945-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adaRiverNo2],
      history: {
        opened: {
          date: '1931-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1942-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adaRiverJunctionAdaRiverJunctionTwo],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adaRiverNo1],
      history: {
        opened: {
          date: '1931-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adaRiver],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1931-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
