import { Line } from "../../trackTypes";

const johnsonvilleJunctionOutletTunnel = [
  [-41.2655, 174.7841],
  [-41.2647, 174.7845],
  [-41.2638, 174.7847],
  [-41.2633, 174.7851],
  [-41.2617, 174.7874],
  [-41.2613, 174.7878]
];

const outletTunnel = [
  [-41.2613, 174.7878],
  [-41.2611, 174.7879],
  [-41.2605, 174.7879],
  [-41.2601, 174.7877]
];

const outletTunnelKaiwarraTunnel = [
  [-41.2601, 174.7877],
  [-41.2598, 174.7874],
  [-41.2595, 174.7869],
  [-41.2592, 174.7862],
  [-41.2590, 174.7858]
];

const kaiwarraTunnel = [
  [-41.2590, 174.7858],
  [-41.2585, 174.7851],
  [-41.2584, 174.7848]
];

const kaiwarraTunnelGorgeTunnel = [
  [-41.2584, 174.7848],
  [-41.2583, 174.7845],
  [-41.2583, 174.7841]
];

const gorgeTunnel = [
  [-41.2583, 174.7841],
  [-41.2588, 174.7823]
];

const gorgeTunnelLizardTunnel = [
  [-41.2588, 174.7823],
  [-41.2589, 174.7815],
  [-41.2588, 174.7806],
  [-41.2585, 174.7795],
  [-41.2585, 174.7789]
];

const lizardTunnel = [
  [-41.2585, 174.7789],
  [-41.2587, 174.7765]
];

const lizardTunnelNgaioTunnel = [
  [-41.2587, 174.7765],
  [-41.2589, 174.7755],
  [-41.2590, 174.7745],
  [-41.2592, 174.7738]
];

const ngaioTunnel = [
  [-41.2592, 174.7738],
  [-41.2593, 174.7736],
  [-41.2600, 174.7727]
];

const ngaioTunnelKakaTunnel = [
  [-41.2600, 174.7727],
  [-41.2603, 174.7723],
  [-41.2607, 174.7713],
  [-41.2607, 174.7701],
  [-41.2603, 174.7691],
  [-41.2595, 174.7681],
  [-41.2589, 174.7677],
  [-41.2580, 174.7674],
  [-41.2574, 174.7669],
  [-41.2570, 174.7665],
  [-41.2562, 174.7661],
  [-41.2553, 174.7662],
  [-41.2548, 174.7666],
  [-41.2543, 174.7674],
  [-41.2540, 174.7686],
  [-41.2537, 174.7693],
  [-41.2532, 174.7700],
  [-41.2528, 174.7704],
  [-41.2521, 174.7707],
  [-41.2506, 174.7720],
  [-41.2496, 174.7729],
  [-41.2490, 174.7731],
  [-41.2484, 174.7729],
  [-41.2476, 174.7729],
  [-41.2471, 174.7733],
  [-41.2465, 174.7740],
  [-41.2463, 174.7751],
  [-41.2463, 174.7770],
  [-41.2464, 174.7779],
  [-41.2466, 174.7791],
  [-41.2465, 174.7802],
  [-41.2462, 174.7809],
  [-41.2461, 174.7816],
  [-41.2461, 174.7824],
  [-41.2465, 174.7837],
  [-41.2471, 174.7850],
  [-41.2472, 174.7860],
  [-41.2471, 174.7869],
  [-41.2467, 174.7877],
  [-41.2462, 174.7883],
  [-41.2459, 174.7890],
  [-41.2457, 174.7896],
  [-41.2456, 174.7908],
  [-41.2452, 174.7916],
  [-41.2448, 174.7921],
  [-41.2439, 174.7926],
  [-41.2405, 174.7948],
  [-41.2399, 174.7955],
  [-41.2391, 174.7968]
];

const kakaTunnel = [
  [-41.2391, 174.7968],
  [-41.2387, 174.7975],
  [-41.2384, 174.7979]
];

const kakaTunnelTuiTunnel = [
  [-41.2384, 174.7979],
  [-41.2381, 174.7982],
  [-41.2370, 174.7986],
  [-41.2343, 174.7989],
  [-41.2336, 174.7993],
  [-41.2331, 174.8001],
  [-41.2328, 174.8010],
  [-41.2322, 174.8018],
  [-41.2312, 174.8024]
];

const tuiTunnel = [
  [-41.2312, 174.8024],
  [-41.2301, 174.8027]
];

const tuiTunnelJohnsonville = [
  [-41.2301, 174.8027],
  [-41.2286, 174.8029],
  [-41.2271, 174.8029],
  [-41.2264, 174.8030],
  [-41.2244, 174.8039],
  [-41.2231, 174.8050]
];

const johnsonvilleTawaFlatDeviationEnd = [
  [-41.2231, 174.8050],
  [-41.2217, 174.8072],
  [-41.2209, 174.8085],
  [-41.2202, 174.8094],
  [-41.2194, 174.8103],
  [-41.2179, 174.8112],
  [-41.2169, 174.8119],
  [-41.2163, 174.8126],
  [-41.2159, 174.8131],
  [-41.2153, 174.8135],
  [-41.2139, 174.8139],
  [-41.2136, 174.8143],
  [-41.2131, 174.8145],
  [-41.2127, 174.8145],
  [-41.2124, 174.8148],
  [-41.2123, 174.8154],
  [-41.2120, 174.8167],
  [-41.2107, 174.8179],
  [-41.2103, 174.8181],
  [-41.2097, 174.8180],
  [-41.2071, 174.8171],
  [-41.2067, 174.8170],
  [-41.2052, 174.8176],
  [-41.2039, 174.8204],
  [-41.2020, 174.8220],
  [-41.1964, 174.8251],
  [-41.1950, 174.8266],
  [-41.1937, 174.8289],
  [-41.1925, 174.8297],
  [-41.1903, 174.8302],
  [-41.1891, 174.8309],
  [-41.1854, 174.8334],
  [-41.1849, 174.8335],
  [-41.1844, 174.8332],
  [-41.1839, 174.8322],
  [-41.1837, 174.8316],
  [-41.1838, 174.8306],
  [-41.1839, 174.8292],
  [-41.1836, 174.8279],
  [-41.1830, 174.8270],
  [-41.1819, 174.8264],
  [-41.1813, 174.8261],
  [-41.1808, 174.8263],
  [-41.1805, 174.8268],
  [-41.1801, 174.8270],
  [-41.1786, 174.8273],
  [-41.1776, 174.8275],
  [-41.1722, 174.8279],
  [-41.1714, 174.8282],
  [-41.1709, 174.8286],
  [-41.1704, 174.8288],
  [-41.1654, 174.8293]
];

export const johnsonville: Line = {
  name: 'Johnsonville',
  state: 'NZ',
  segments: [
    {
      segments: [
        johnsonvilleJunctionOutletTunnel,
        {
          name: 'Outlet Tunnel',
          segment: outletTunnel,
          type: 'tunnel'
        },
        outletTunnelKaiwarraTunnel,
        {
          name: 'Kaiwarra Tunnel',
          segment: kaiwarraTunnel,
          type: 'tunnel'
        },
        kaiwarraTunnelGorgeTunnel,
        {
          name: 'Gorge Tunnel',
          segment: gorgeTunnel,
          type: 'tunnel'
        },
        gorgeTunnelLizardTunnel,
        {
          name: 'Lizard Tunnel',
          segment: lizardTunnel,
          type: 'tunnel'
        },
        lizardTunnelNgaioTunnel,
        {
          name: 'Ngaio Tunnel',
          segment: ngaioTunnel,
          type: 'tunnel'
        },
        ngaioTunnelKakaTunnel,
        {
          name: 'Kaka Tunnel',
          segment: kakaTunnel,
          type: 'tunnel'
        },
        kakaTunnelTuiTunnel,
        {
          name: 'Tui Tunnel',
          segment: tuiTunnel,
          type: 'tunnel'
        },
        tuiTunnelJohnsonville
      ],
      history: {
        opened: {
          date: '1885-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1938-07-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [johnsonvilleTawaFlatDeviationEnd],
      history: {
        opened: {
          date: '1885-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-06-19',
          status: 'closed'
        }]
      }
    }
  ]
}
