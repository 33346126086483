import { Line } from "../../../trackTypes";

const line = [
  [-37.0731, 144.2083],
  [-37.0750, 144.2055],
  [-37.0762, 144.2047],
  [-37.0782, 144.2045],
  [-37.0790, 144.2047],
  [-37.0803, 144.2056],
  [-37.0810, 144.2059],
  [-37.0822, 144.2060],
  [-37.0860, 144.2054],
  [-37.0917, 144.2065],
  [-37.0930, 144.2063],
  [-37.1004, 144.2027],
  [-37.1015, 144.2019],
  [-37.1026, 144.2004],
  [-37.1086, 144.1882],
  [-37.1096, 144.1868],
  [-37.1123, 144.1835],
  [-37.1136, 144.1826],
  [-37.1200, 144.1800],
  [-37.1211, 144.1799],
  [-37.1222, 144.1801],
  [-37.1232, 144.1801],
  [-37.1241, 144.1799],
  [-37.1266, 144.1785],
  [-37.1281, 144.1780],
  [-37.1296, 144.1769],
  [-37.1312, 144.1744],
  [-37.1318, 144.1737],
  [-37.1327, 144.1731],
  [-37.1358, 144.1723],
  [-37.1367, 144.1719],
  [-37.1376, 144.1710],
  [-37.1381, 144.1700],
  [-37.1388, 144.1691],
  [-37.1398, 144.1686],
  [-37.1408, 144.1684],
  [-37.1416, 144.1677],
  [-37.1423, 144.1666],
  [-37.1440, 144.1605],
  [-37.1441, 144.1594],
  [-37.1438, 144.1536],
  [-37.1429, 144.1482],
  [-37.1428, 144.1462],
  [-37.1434, 144.1447],
  [-37.1440, 144.1437],
  [-37.1470, 144.1417],
  [-37.1485, 144.1396],
  [-37.1491, 144.1365],
  [-37.1485, 144.1316],
  [-37.1480, 144.1298],
  [-37.1461, 144.1242],
  [-37.1428, 144.1127],
  [-37.1426, 144.1106],
  [-37.1428, 144.1092],
  [-37.1443, 144.1043],
  [-37.1448, 144.1013],
  [-37.1444, 144.0982],
  [-37.1416, 144.0889],
  [-37.1352, 144.0773],
  [-37.1338, 144.0758],
  [-37.1291, 144.0721],
  [-37.1218, 144.0652],
  [-37.1154, 144.0600],
  [-37.1146, 144.0591],
  [-37.1141, 144.0582],
  [-37.1129, 144.0547],
  [-37.1119, 144.0527],
  [-37.1024, 144.0416],
  [-37.0980, 144.0353],
  [-37.0968, 144.0322],
  [-37.0944, 144.0119],
  [-37.0936, 144.0093],
  [-37.0922, 144.0068],
  [-37.0804, 143.9938],
  [-37.0781, 143.9903],
  [-37.0708, 143.9729],
  [-37.0632, 143.9539],
  [-37.0377, 143.8751],
  [-37.0369, 143.8721],
  [-37.0363, 143.8683],
  [-37.0351, 143.8649],
  [-37.0334, 143.8615],
  [-37.0277, 143.8439],
  [-37.0275, 143.8404],
  [-37.0282, 143.8373],
  [-37.0464, 143.8029],
  [-37.0471, 143.8007],
  [-37.0470, 143.7983],
  [-37.0402, 143.7690],
  [-37.0400, 143.7666],
  [-37.0404, 143.7642],
  [-37.0424, 143.7571],
  [-37.0426, 143.7557],
  [-37.0424, 143.7545],
  [-37.0418, 143.7527],
  [-37.0416, 143.7514],
  [-37.0416, 143.7502],
  [-37.0423, 143.7480],
  [-37.0428, 143.7470],
  [-37.0437, 143.7462],
  [-37.0448, 143.7459]
]

export const castlemaineMaryborough: Line = {
  name: 'Castmaine Maryborough',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1874-07-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-12-17',
          status: 'closed'
        }]
      }
    }
  ]
};
