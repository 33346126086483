import { Line } from "../../trackTypes";

const balaklavaBlyth = [
  [-34.1439, 138.4149],
  [-34.1417, 138.4228],
  [-34.1411, 138.4250],
  [-34.1318, 138.4491],
  [-34.1097, 138.4877],
  [-34.1080, 138.4897],
  [-34.1061, 138.4912],
  [-34.0957, 138.4967],
  [-34.0935, 138.4986],
  [-34.0920, 138.5007],
  [-34.0723, 138.5417],
  [-34.0700, 138.5438],
  [-34.0454, 138.5582],
  [-34.0442, 138.5587],
  [-34.0327, 138.5604],
  [-34.0143, 138.5602],
  [-33.9882, 138.5524],
  [-33.9860, 138.5509],
  [-33.9824, 138.5467],
  [-33.9792, 138.5453],
  [-33.9704, 138.5449],
  [-33.9691, 138.5444],
  [-33.9677, 138.5435],
  [-33.9646, 138.5394],
  [-33.9640, 138.5377],
  [-33.9626, 138.5360],
  [-33.9384, 138.5229],
  [-33.9242, 138.5088],
  [-33.9221, 138.5075],
  [-33.8507, 138.4897],
  [-33.8484, 138.4882],
  [-33.8441, 138.4872]
];

const balaklavaJunction = [
  [-34.1425, 138.4271],
  [-34.1411, 138.4264],
  [-34.1408, 138.4265],
  [-34.1404, 138.4268]
];

const hamleyBridgeBalaklava = [
  [-34.3535, 138.6802],
  [-34.3502, 138.6782],
  [-34.3458, 138.6737],
  [-34.3120, 138.6242],
  [-34.3103, 138.6201],
  [-34.3021, 138.5876],
  [-34.3008, 138.5850],
  [-34.2707, 138.5494],
  [-34.2600, 138.5301],
  [-34.2593, 138.5284],
  [-34.2587, 138.5263],
  [-34.2577, 138.5243],
  [-34.2559, 138.5228],
  [-34.2448, 138.5174],
  [-34.2422, 138.5157],
  [-34.2030, 138.4830],
  [-34.1894, 138.4716],
  [-34.1594, 138.4419],
  [-34.1425, 138.4271],
  [-34.1419, 138.4260],
  [-34.1415, 138.4249],
  [-34.1417, 138.4228]
];

const blythGulnare = [
  [-33.8441, 138.4872],
  [-33.8408, 138.4863],
  [-33.8229, 138.4819],
  [-33.8210, 138.4810],
  [-33.7564, 138.4387],
  [-33.7171, 138.4129],
  [-33.7046, 138.4048],
  [-33.7019, 138.4042],
  [-33.6921, 138.4041],
  [-33.6902, 138.4045],
  [-33.6289, 138.4272],
  [-33.6269, 138.4285],
  [-33.6251, 138.4315],
  [-33.6241, 138.4351],
  [-33.6228, 138.4376],
  [-33.6209, 138.4394],
  [-33.6190, 138.4403],
  [-33.6124, 138.4421],
  [-33.6109, 138.4422],
  [-33.5946, 138.4401],
  [-33.5930, 138.4403],
  [-33.5804, 138.4425],
  [-33.5660, 138.4428],
  [-33.5640, 138.4433],
  [-33.5627, 138.4439],
  [-33.5558, 138.4495],
  [-33.5541, 138.4502],
  [-33.5525, 138.4504],
  [-33.5480, 138.4501],
  [-33.5454, 138.4505],
  [-33.5436, 138.4518],
  [-33.5343, 138.4603],
  [-33.5322, 138.4611],
  [-33.5298, 138.4611],
  [-33.5279, 138.4604],
  [-33.5234, 138.4576],
  [-33.5106, 138.4535],
  [-33.4992, 138.4511],
  [-33.4944, 138.4511],
  [-33.4669, 138.4442]
];

const gulnareGladstone = [
  [-33.4669, 138.4442],
  [-33.4548, 138.4412],
  [-33.4130, 138.4246],
  [-33.3930, 138.4167],
  [-33.3862, 138.4120],
  [-33.3800, 138.4066],
  [-33.3776, 138.4033],
  [-33.3763, 138.4021],
  [-33.3693, 138.3978],
  [-33.3675, 138.3971],
  [-33.3640, 138.3965],
  [-33.3606, 138.3949],
  [-33.3548, 138.3921],
  [-33.3279, 138.3844],
  [-33.3257, 138.3842],
  [-33.3240, 138.3844],
  [-33.3140, 138.3869],
  [-33.3125, 138.3869],
  [-33.3113, 138.3865],
  [-33.3047, 138.3833],
  [-33.2950, 138.3816],
  [-33.2847, 138.3786],
  [-33.2830, 138.3774],
  [-33.2818, 138.3753],
  [-33.2815, 138.3728],
  [-33.2824, 138.3675],
  [-33.2822, 138.3656],
  [-33.2814, 138.3637],
  [-33.2779, 138.3589],
  [-33.2768, 138.3581],
  [-33.2760, 138.3576],
  [-33.2750, 138.3574]
];

export const hamleyBridge: Line = {
  name: 'Hamley Bridge',
  state: 'SA',
  segments: [
    {
      segments: [balaklavaBlyth],
      history: {
        opened: {
          date: '1876-03-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '1989-03-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hamleyBridgeBalaklava, balaklavaJunction],
      history: {
        opened: {
          date: '1880-01-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '2006-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [blythGulnare],
      history: {
        opened: {
          date: '1894-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '1989-03-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gulnareGladstone],
      history: {
        opened: {
          date: '1894-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '1988-05-11',
          status: 'closed'
        }]
      }
    }
  ]
};
