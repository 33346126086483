import { Line } from "../../trackTypes";

const line = [
  [-34.9881, 138.6097],
  [-34.9887, 138.6090],
  [-34.9899, 138.6070]
];

export const clapham: Line = {
  name: 'Clapham',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
