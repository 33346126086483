import { Line } from "../../../trackTypes";

const line = [
  [-17.1487, 145.1128],
  [-17.1477, 145.1137],
  [-17.1464, 145.1143],
  [-17.1451, 145.1145],
  [-17.1426, 145.1152],
  [-17.1418, 145.1152],
  [-17.1401, 145.1146],
  [-17.1379, 145.1139],
  [-17.1367, 145.1140],
  [-17.1351, 145.1144],
  [-17.1344, 145.1143],
  [-17.1337, 145.1139],
  [-17.1187, 145.1025],
  [-17.1101, 145.0978],
  [-17.1086, 145.0967],
  [-17.1048, 145.0930],
  [-17.1028, 145.0919],
  [-17.1004, 145.0913],
  [-17.0989, 145.0906],
  [-17.0980, 145.0899],
  [-17.0954, 145.0871],
  [-17.0951, 145.0864],
  [-17.0947, 145.0858],
  [-17.0942, 145.0853],
  [-17.0924, 145.0845],
  [-17.0915, 145.0844],
  [-17.0887, 145.0847],
  [-17.0870, 145.0844],
  [-17.0853, 145.0838],
  [-17.0791, 145.0802],
  [-17.0777, 145.0787],
  [-17.0727, 145.0711],
  [-17.0709, 145.0695],
  [-17.0633, 145.0649],
  [-17.0603, 145.0626],
  [-17.0562, 145.0589],
  [-17.0550, 145.0575],
  [-17.0518, 145.0519],
  [-17.0504, 145.0507],
  [-17.0492, 145.0503],
  [-17.0481, 145.0504],
  [-17.0456, 145.0509],
  [-17.0444, 145.0508],
  [-17.0407, 145.0480],
  [-17.0395, 145.0479],
  [-17.0386, 145.0484],
  [-17.0378, 145.0492],
  [-17.0367, 145.0497],
  [-17.0346, 145.0503],
  [-17.0336, 145.0503],
  [-17.0326, 145.0502],
  [-17.0320, 145.0504],
  [-17.0315, 145.0508],
  [-17.0310, 145.0519],
  [-17.0303, 145.0526],
  [-17.0261, 145.0547],
  [-17.0253, 145.0547],
  [-17.0244, 145.0541],
  [-17.0237, 145.0541],
  [-17.0228, 145.0546],
  [-17.0221, 145.0547],
  [-17.0213, 145.0543],
  [-17.0200, 145.0528],
  [-17.0189, 145.0520],
  [-17.0138, 145.0489],
  [-17.0130, 145.0482],
  [-17.0116, 145.0475],
  [-17.0108, 145.0474],
  [-17.0101, 145.0474],
  [-17.0095, 145.0472],
  [-17.0088, 145.0465],
  [-17.0080, 145.0461],
  [-17.0068, 145.0459],
  [-17.0061, 145.0459],
  [-17.0042, 145.0464],
  [-17.0036, 145.0463],
  [-17.0033, 145.0460],
  [-17.0027, 145.0448],
  [-17.0021, 145.0442],
  [-17.0004, 145.0426],
  [-16.9994, 145.0414],
  [-16.9987, 145.0410],
  [-16.9983, 145.0410],
  [-16.9972, 145.0412],
  [-16.9968, 145.0411],
  [-16.9962, 145.0406],
  [-16.9956, 145.0405],
  [-16.9952, 145.0408],
  [-16.9945, 145.0414],
  [-16.9939, 145.0416],
  [-16.9934, 145.0416],
  [-16.9928, 145.0412],
  [-16.9913, 145.0392],
  [-16.9883, 145.0346],
  [-16.9875, 145.0339],
  [-16.9859, 145.0335],
  [-16.9853, 145.0336],
  [-16.9846, 145.0338],
  [-16.9838, 145.0335],
  [-16.9834, 145.0330],
  [-16.9827, 145.0303],
  [-16.9824, 145.0295],
  [-16.9814, 145.0283],
  [-16.9807, 145.0267],
  [-16.9805, 145.0260],
  [-16.9802, 145.0229],
  [-16.9795, 145.0218],
  [-16.9785, 145.0212],
  [-16.9771, 145.0212],
  [-16.9763, 145.0207],
  [-16.9759, 145.0202],
  [-16.9751, 145.0198],
  [-16.9735, 145.0196],
  [-16.9720, 145.0188],
  [-16.9691, 145.0151],
  [-16.9681, 145.0144],
  [-16.9630, 145.0128],
  [-16.9615, 145.0119],
  [-16.9591, 145.0095],
  [-16.9573, 145.0084],
  [-16.9554, 145.0079],
  [-16.9547, 145.0079],
  [-16.9528, 145.0081],
  [-16.9515, 145.0080],
  [-16.9499, 145.0070],
  [-16.9462, 145.0028],
  [-16.9444, 145.0015],
  [-16.9438, 145.0005],
  [-16.9434, 144.9992],
  [-16.9429, 144.9986],
  [-16.9421, 144.9982],
  [-16.9417, 144.9978],
  [-16.9416, 144.9971],
  [-16.9416, 144.9958],
  [-16.9413, 144.9947],
  [-16.9403, 144.9937],
  [-16.9390, 144.9928],
  [-16.9381, 144.9918],
  [-16.9360, 144.9880],
  [-16.9353, 144.9871],
  [-16.9343, 144.9863],
  [-16.9337, 144.9852],
  [-16.9330, 144.9816],
  [-16.9323, 144.9804],
  [-16.9299, 144.9784],
  [-16.9289, 144.9780],
  [-16.9255, 144.9778],
  [-16.9243, 144.9771],
  [-16.9236, 144.9762],
  [-16.9234, 144.9749],
  [-16.9239, 144.9717],
  [-16.9235, 144.9694],
  [-16.9209, 144.9653],
  [-16.9194, 144.9641],
  [-16.9185, 144.9638],
  [-16.9176, 144.9629],
  [-16.9127, 144.9558],
  [-16.9095, 144.9477],
  [-16.9087, 144.9467],
  [-16.9042, 144.9448],
  [-16.9031, 144.9436],
  [-16.9028, 144.9424],
  [-16.9030, 144.9412],
  [-16.9043, 144.9388],
  [-16.9044, 144.9378],
  [-16.9039, 144.9368],
  [-16.9032, 144.9362],
  [-16.9028, 144.9355],
  [-16.9024, 144.9329],
  [-16.9019, 144.9313],
  [-16.9003, 144.9287],
  [-16.8974, 144.9221],
  [-16.8971, 144.9207],
  [-16.8972, 144.9190],
  [-16.8968, 144.9176],
  [-16.8939, 144.9145],
  [-16.8884, 144.9097],
  [-16.8876, 144.9080],
  [-16.8867, 144.9057],
  [-16.8859, 144.9045],
  [-16.8819, 144.9024],
  [-16.8807, 144.9010],
  [-16.8775, 144.8941],
  [-16.8763, 144.8931],
  [-16.8737, 144.8920],
  [-16.8730, 144.8911],
  [-16.8729, 144.8903],
  [-16.8738, 144.8871],
  [-16.8752, 144.8845],
  [-16.8754, 144.8836],
  [-16.8752, 144.8827],
  [-16.8745, 144.8816],
  [-16.8742, 144.8808],
  [-16.8734, 144.8780],
  [-16.8728, 144.8770],
  [-16.8708, 144.8750],
  [-16.8702, 144.8740],
  [-16.8697, 144.8712],
  [-16.8691, 144.8707],
  [-16.8685, 144.8707],
  [-16.8680, 144.8712],
  [-16.8677, 144.8721],
  [-16.8671, 144.8724],
  [-16.8666, 144.8724],
  [-16.8651, 144.8716],
  [-16.8646, 144.8715],
  [-16.8640, 144.8716],
  [-16.8635, 144.8715],
  [-16.8623, 144.8707],
  [-16.8619, 144.8707],
  [-16.8615, 144.8709],
  [-16.8609, 144.8715],
  [-16.8603, 144.8716],
  [-16.8579, 144.8713],
  [-16.8555, 144.8704],
  [-16.8548, 144.8697],
  [-16.8541, 144.8685],
  [-16.8540, 144.8678],
  [-16.8543, 144.8666],
  [-16.8548, 144.8659]
];

export const mountMulligan: Line = {
  name: 'Mount Mulligan',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
