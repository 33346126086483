import { Line } from "../../trackTypes";

const line = [
  [-26.5907, 118.5034],
  [-26.5902, 118.5056],
  [-26.5902, 118.5231],
  [-26.5817, 118.6532],
  [-26.5834, 118.7186],
  [-26.5581, 118.9147],
  [-26.5565, 118.9817],
  [-26.5621, 119.0126],
  [-26.5632, 119.0144],
  [-26.5680, 119.0183],
  [-26.5694, 119.0198],
  [-26.5776, 119.0393],
  [-26.5779, 119.0409],
  [-26.5807, 119.1195],
  [-26.5858, 119.1609],
  [-26.5879, 119.3205],
  [-26.5882, 119.3227],
  [-26.6023, 119.3591],
  [-26.6027, 119.3609],
  [-26.6023, 119.4499],
  [-26.5958, 119.4740],
  [-26.5957, 119.4762],
  [-26.5964, 119.4791],
  [-26.5964, 119.4812],
  [-26.5955, 119.4839],
  [-26.5953, 119.4861],
  [-26.5971, 119.4920],
  [-26.5992, 119.4946],
  [-26.5999, 119.4972],
  [-26.5994, 119.5003],
  [-26.5998, 119.5027],
  [-26.6029, 119.5056],
  [-26.6044, 119.5074],
  [-26.6100, 119.5189],
  [-26.6103, 119.5209],
  [-26.6094, 119.5299],
  [-26.6182, 119.5644],
  [-26.6099, 119.6398],
  [-26.6101, 119.6433],
  [-26.6335, 119.7311],
  [-26.6363, 119.8084],
  [-26.6215, 119.9047],
  [-26.6216, 120.1057],
  [-26.6197, 120.1089],
  [-26.6168, 120.1104],
  [-26.6154, 120.1116],
  [-26.6145, 120.1133],
  [-26.5978, 120.2105],
  [-26.5974, 120.2115],
  [-26.5955, 120.2140],
  [-26.5946, 120.2167],
  [-26.5948, 120.2185],
  [-26.5959, 120.2216],
  [-26.5974, 120.2234],
  [-26.5991, 120.2246],
  [-26.6070, 120.2302]
];

export const wiluna: Line = {
  name: 'Wiluna',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1932-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-08-05',
          status: 'closed'
        }]
      }
    }
  ]
};
