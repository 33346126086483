import { Line } from "../../trackTypes";

const line = [
  [-27.4756, 153.0198],
  [-27.4759, 153.0199],
  [-27.4761, 153.0205],
  [-27.4776, 153.0214],
  [-27.4788, 153.0230],
  [-27.4805, 153.0238],
  [-27.4818, 153.0249],
  [-27.4820, 153.0254],
  [-27.4818, 153.0258],
  [-27.4813, 153.0259],
  [-27.4805, 153.0247],
  [-27.4802, 153.0244],
  [-27.4796, 153.0241],
  [-27.4768, 153.0224],
  [-27.4763, 153.0217],
  [-27.4761, 153.0216],
  [-27.4757, 153.0216],
  [-27.4751, 153.0211],
  [-27.4751, 153.0209],
  [-27.4756, 153.0198]
];

export const brisbaneMonorail: Line = {
  name: 'Brisbane Monorail',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1988-04-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'monorail'
        },
        trackChange: [{
          date: '1989-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
