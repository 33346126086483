import { Line } from "../../../trackTypes";

const line = [
  [-35.9263, 145.4833],
  [-35.9263, 145.4847],
  [-35.9259, 145.4876],
  [-35.9258, 145.4893],
  [-35.9258, 145.5552],
  [-35.9263, 145.5582],
  [-35.9263, 145.5662],
  [-35.9258, 145.5694],
  [-35.9258, 145.6304],
  [-35.9254, 145.6328],
  [-35.9191, 145.6475],
  [-35.9188, 145.6487]
];

export const cobram: Line = {
  name: 'Cobram',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1999-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
