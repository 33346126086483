import { Line } from "../../../trackTypes";

const blacktownQuakersHill = [
  [-33.7691, 150.9098],
  [-33.7684, 150.9084],
  [-33.7676, 150.9075],
  [-33.7668, 150.9068],
  [-33.7659, 150.9064],
  [-33.7575, 150.9042],
  [-33.7533, 150.9027],
  [-33.7486, 150.9005],
  [-33.7471, 150.9001],
  [-33.7415, 150.9000],
  [-33.7408, 150.8999],
  [-33.7403, 150.8997],
  [-33.7380, 150.8976],
  [-33.7334, 150.8915],
  [-33.7326, 150.8906],
  [-33.7272, 150.8861]
];

const quakersHillSchofields = [
  [-33.7272, 150.8861],
  [-33.7215, 150.8815],
  [-33.7113, 150.8757],
  [-33.7066, 150.8746],
  [-33.7039, 150.8735]
];

const schofieldsRiverstone = [
  [-33.7039, 150.8735],
  [-33.7022, 150.8728],
  [-33.6790, 150.8603]
];

const riverstoneRichmond = [
  [-33.6790, 150.8603],
  [-33.6782, 150.8598],
  [-33.6763, 150.8590],
  [-33.6701, 150.8570],
  [-33.6688, 150.8569],
  [-33.6678, 150.8566],
  [-33.6665, 150.8558],
  [-33.6645, 150.8540],
  [-33.6624, 150.8529],
  [-33.6595, 150.8522],
  [-33.6529, 150.8519],
  [-33.6510, 150.8514],
  [-33.6510, 150.8511],
  [-33.6490, 150.8504],
  [-33.6294, 150.8350],
  [-33.6280, 150.8336],
  [-33.6272, 150.8323],
  [-33.6266, 150.8307],
  [-33.6263, 150.8291],
  [-33.6262, 150.8283],
  [-33.6260, 150.8261],
  [-33.6257, 150.8247],
  [-33.6241, 150.8209],
  [-33.6235, 150.8200],
  [-33.6229, 150.8194],
  [-33.6220, 150.8188],
  [-33.6214, 150.8182],
  [-33.6197, 150.8159],
  [-33.6186, 150.8152],
  [-33.6155, 150.8131],
  [-33.6146, 150.8122],
  [-33.6138, 150.8112],
  [-33.6133, 150.8101],
  [-33.6130, 150.8090],
  [-33.6127, 150.8061],
  [-33.6101, 150.7919],
  [-33.6094, 150.7896],
  [-33.6084, 150.7876],
  [-33.6076, 150.7854],
  [-33.6015, 150.7585],
  [-33.6012, 150.7574],
  [-33.5988, 150.7524]
];

const richmondNepeanSandGravel = [
  [-33.5988, 150.7524],
  [-33.5905, 150.7342]
];

const nepeanSandGravelKurrajong = [
  [-33.5905, 150.7342],
  [-33.5865, 150.7256],
  [-33.5860, 150.7251],
  [-33.5832, 150.7227],
  [-33.5803, 150.7199],
  [-33.5799, 150.7191],
  [-33.5793, 150.7168],
  [-33.5766, 150.6965],
  [-33.5763, 150.6945],
  [-33.5757, 150.6936],
  [-33.5750, 150.6932],
  [-33.5742, 150.6931],
  [-33.5736, 150.6930],
  [-33.5727, 150.6927],
  [-33.5722, 150.6922],
  [-33.5712, 150.6908],
  [-33.5696, 150.6892],
  [-33.5688, 150.6879],
  [-33.5677, 150.6845],
  [-33.5673, 150.6816],
  [-33.5660, 150.6771],
  [-33.5640, 150.6655],
  [-33.5636, 150.6645],
  [-33.5629, 150.6636],
  [-33.5626, 150.6631],
  [-33.5624, 150.6621],
  [-33.5615, 150.6604],
  [-33.5609, 150.6595],
  [-33.5599, 150.6594],
  [-33.5591, 150.6597],
  [-33.5586, 150.6601],
  [-33.5582, 150.6609],
  [-33.5579, 150.6613],
  [-33.5574, 150.6615],
  [-33.5569, 150.6620],
  [-33.5563, 150.6633],
  [-33.5562, 150.6637],
  [-33.5561, 150.6642],
  [-33.5559, 150.6647],
  [-33.5552, 150.6658]
];

export const richmond: Line = {
  name: 'Richmond',
  state: 'NSW',
  segments: [
    {
      segments: [blacktownQuakersHill],
      history: {
        opened: {
          date: '1864-12-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1975-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2002-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [quakersHillSchofields],
      history: {
        opened: {
          date: '1864-12-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1975-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2011-10-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [schofieldsRiverstone],
      history: {
        opened: {
          date: '1864-12-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1975-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [riverstoneRichmond],
      history: {
        opened: {
          date: '1864-12-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-08-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [richmondNepeanSandGravel],
      history: {
        opened: {
          date: '1925-04-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-07-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nepeanSandGravelKurrajong],
      history: {
        opened: {
          date: '1926-11-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-07-26',
          status: 'closed'
        }]
      }
    }
  ]
};
