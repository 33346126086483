import React from "react";
import styled from "styled-components";

const Button = styled.a`
  @media (max-width: 600px) {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }
`;

const Image = styled.img`
  height: 2rem;
  &:hover {
    opacity: 0.9;
  }
`;

const DonateButton = () => {
  return (
    <Button href="https://www.buymeacoffee.com/railhistory" target="_blank" rel="noreferrer">
      <Image src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" />
    </Button>
  );
};

export default DonateButton;
