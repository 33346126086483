import { Line } from "../../trackTypes";

const eastTamarJunctionColdwaterCreekJunctionNewOne = [
  [-41.4303, 147.1598],
  [-41.4297, 147.1595],
  [-41.4291, 147.1594],
  [-41.4285, 147.1597],
  [-41.4229, 147.1632],
  [-41.4217, 147.1632],
  [-41.4208, 147.1628],
  [-41.4125, 147.1561],
  [-41.4113, 147.1556],
  [-41.4103, 147.1555],
  [-41.4095, 147.1551],
  [-41.4085, 147.1541],
  [-41.4080, 147.1537],
  [-41.4072, 147.1534],
  [-41.4063, 147.1532],
  [-41.4057, 147.1529],
  [-41.4051, 147.1524],
  [-41.4038, 147.1508],
  [-41.4031, 147.1503],
  [-41.4021, 147.1501],
  [-41.4012, 147.1503],
  [-41.4004, 147.1508],
  [-41.3994, 147.1518],
  [-41.3983, 147.1521],
  [-41.3972, 147.1519],
  [-41.3962, 147.1512],
  [-41.3955, 147.1500],
  [-41.3952, 147.1487],
  [-41.3950, 147.1468],
  [-41.3945, 147.1454],
  [-41.3937, 147.1444],
  [-41.3907, 147.1426],
  [-41.3898, 147.1424],
  [-41.3885, 147.1426],
  [-41.3874, 147.1422],
  [-41.3863, 147.1412],
  [-41.3858, 147.1397],
  [-41.3856, 147.1383],
  [-41.3854, 147.1369],
  [-41.3847, 147.1357],
  [-41.3842, 147.1350],
  [-41.3838, 147.1344],
  [-41.3834, 147.1334],
  [-41.3829, 147.1327],
  [-41.3822, 147.1321],
  [-41.3796, 147.1307],
  [-41.3789, 147.1305],
  [-41.3762, 147.1305],
  [-41.3752, 147.1309],
  [-41.3746, 147.1316]
];

const eastTamarJunctionColdwaterCreekJunctionNewTwo = [
  [-41.3677, 147.1400],
  [-41.3661, 147.1404],
  [-41.3648, 147.1400],
  [-41.3640, 147.1393],
  [-41.3612, 147.1371],
  [-41.3597, 147.1362],
  [-41.3585, 147.1356],
  [-41.3570, 147.1345],
  [-41.3544, 147.1300]
];

const coldwaterCreekjunctionLongReachJunction = [
  [-41.3414, 147.1131],
  [-41.3393, 147.1098],
  [-41.3375, 147.1080],
  [-41.3353, 147.1069],
  [-41.3343, 147.1061],
  [-41.3329, 147.1045],
  [-41.3315, 147.1035],
  [-41.3302, 147.1028],
  [-41.3293, 147.1014],
  [-41.3287, 147.0997],
  [-41.3279, 147.0984],
  [-41.3268, 147.0975],
  [-41.3258, 147.0962],
  [-41.3249, 147.0942],
  [-41.3240, 147.0909],
  [-41.3238, 147.0876],
  [-41.3229, 147.0853],
  [-41.3219, 147.0842],
  [-41.3207, 147.0831],
  [-41.3198, 147.0806],
  [-41.3185, 147.0780],
  [-41.3150, 147.0733],
  [-41.3135, 147.0722],
  [-41.3114, 147.0704],
  [-41.3103, 147.0693],
  [-41.3090, 147.0686],
  [-41.3075, 147.0685],
  [-41.3058, 147.0676],
  [-41.3010, 147.0624],
  [-41.2994, 147.0619],
  [-41.2980, 147.0625],
  [-41.2970, 147.0639],
  [-41.2954, 147.0695],
  [-41.2936, 147.0717],
  [-41.2916, 147.0724],
  [-41.2893, 147.0718],
  [-41.2835, 147.0661],
  [-41.2819, 147.0634],
  [-41.2797, 147.0574],
  [-41.2791, 147.0542],
  [-41.2777, 147.0518],
  [-41.2766, 147.0509],
  [-41.2750, 147.0497],
  [-41.2736, 147.0481],
  [-41.2728, 147.0464],
  [-41.2721, 147.0431],
  [-41.2712, 147.0414],
  [-41.2696, 147.0401],
  [-41.2629, 147.0350],
  [-41.2613, 147.0334],
  [-41.2576, 147.0287],
  [-41.2560, 147.0275],
  [-41.2539, 147.0263],
  [-41.2525, 147.0252],
  [-41.2510, 147.0234],
  [-41.2498, 147.0227],
  [-41.2488, 147.0227],
  [-41.2470, 147.0228],
  [-41.2455, 147.0223],
  [-41.2417, 147.0196],
  [-41.2406, 147.0194],
  [-41.2398, 147.0196],
  [-41.2384, 147.0204],
  [-41.2371, 147.0206],
  [-41.2362, 147.0210],
  [-41.2348, 147.0225],
  [-41.2340, 147.0230],
  [-41.2314, 147.0236],
  [-41.2289, 147.0235],
  [-41.2280, 147.0232],
  [-41.2268, 147.0228],
  [-41.2258, 147.0230],
  [-41.2248, 147.0236],
  [-41.2223, 147.0237],
  [-41.2216, 147.0235],
  [-41.2195, 147.0223],
  [-41.2187, 147.0212],
  [-41.2183, 147.0198],
  [-41.2182, 147.0166],
  [-41.2176, 147.0147],
  [-41.2166, 147.0135],
  [-41.2151, 147.0131],
  [-41.2118, 147.0136],
  [-41.2106, 147.0135],
  [-41.2086, 147.0128],
  [-41.2075, 147.0119],
  [-41.2052, 147.0084],
  [-41.2048, 147.0071],
  [-41.2045, 147.0056],
  [-41.2038, 147.0044],
  [-41.2026, 147.0035],
  [-41.2011, 147.0035],
  [-41.1997, 147.0042],
  [-41.1986, 147.0042],
  [-41.1973, 147.0034],
  [-41.1965, 147.0022],
  [-41.1961, 147.0008],
  [-41.1960, 146.9993],
  [-41.1956, 146.9981],
  [-41.1942, 146.9958],
  [-41.1930, 146.9946],
  [-41.1922, 146.9931],
  [-41.1919, 146.9918],
  [-41.1918, 146.9906],
  [-41.1915, 146.9893],
  [-41.1909, 146.9884],
  [-41.1899, 146.9874],
  [-41.1894, 146.9867],
  [-41.1845, 146.9756],
  [-41.1832, 146.9715],
  [-41.1823, 146.9700],
  [-41.1786, 146.9663],
  [-41.1769, 146.9633],
  [-41.1765, 146.9617],
  [-41.1760, 146.9584],
  [-41.1754, 146.9570],
  [-41.1742, 146.9558],
  [-41.1735, 146.9545],
  [-41.1732, 146.9531],
  [-41.1734, 146.9512],
  [-41.1731, 146.9496],
  [-41.1717, 146.9466]
];

const mowbrayLink = [
  [-41.4085, 147.1541],
  [-41.4080, 147.1533],
  [-41.4074, 147.1514],
  [-41.4069, 147.1505]
];

const longReachBranch = [
  [-41.1717, 146.9466],
  [-41.1705, 146.9411],
  [-41.1699, 146.9402],
  [-41.1693, 146.9399],
  [-41.1687, 146.9398],
  [-41.1667, 146.9404],
  [-41.1660, 146.9403],
  [-41.1654, 146.9396],
  [-41.1651, 146.9384],
  [-41.1655, 146.9344],
  [-41.1653, 146.9334],
  [-41.1646, 146.9325],
  [-41.1630, 146.9314],
  [-41.1623, 146.9301],
  [-41.1618, 146.9276]
];

const launcestonJunction = [
  [-41.4288, 147.1577],
  [-41.4290, 147.1584],
  [-41.4290, 147.1589],
  [-41.4288, 147.1593],
  [-41.4285, 147.1597]
];

const longreachJunctionBellBay = [
  [-41.1717, 146.9466],
  [-41.1698, 146.9428],
  [-41.1686, 146.9418],
  [-41.1673, 146.9415],
  [-41.1654, 146.9417],
  [-41.1643, 146.9414],
  [-41.1631, 146.9409],
  [-41.1625, 146.9405],
  [-41.1592, 146.9373],
  [-41.1585, 146.9364],
  [-41.1550, 146.9305],
  [-41.1514, 146.9258],
  [-41.1506, 146.9252],
  [-41.1498, 146.9249],
  [-41.1485, 146.9250],
  [-41.1472, 146.9258],
  [-41.1466, 146.9259],
  [-41.1457, 146.9258],
  [-41.1449, 146.9254],
  [-41.1437, 146.9245],
  [-41.1426, 146.9241],
  [-41.1416, 146.9242],
  [-41.1407, 146.9240],
  [-41.1396, 146.9233],
  [-41.1386, 146.9220],
  [-41.1382, 146.9208],
  [-41.1381, 146.9196],
  [-41.1381, 146.9162],
  [-41.1384, 146.9145],
  [-41.1386, 146.9132],
  [-41.1385, 146.9063],
  [-41.1382, 146.9047],
  [-41.1375, 146.9029],
  [-41.1361, 146.9014],
  [-41.1350, 146.9009],
  [-41.1338, 146.9005],
  [-41.1329, 146.8999],
  [-41.1323, 146.8993],
  [-41.1313, 146.8976],
  [-41.1279, 146.8936],
  [-41.1197, 146.8838],
  [-41.1190, 146.8826],
  [-41.1188, 146.8812],
  [-41.1188, 146.8803],
  [-41.1190, 146.8788],
  [-41.1190, 146.8773],
  [-41.1161, 146.8671],
  [-41.1139, 146.8614],
  [-41.1133, 146.8605],
  [-41.1124, 146.8594],
  [-41.1106, 146.8574],
  [-41.1101, 146.8563],
  [-41.1098, 146.8550],
  [-41.1099, 146.8537],
  [-41.1104, 146.8526],
  [-41.1121, 146.8502],
  [-41.1125, 146.8497],
  [-41.1220, 146.8413],
  [-41.1223, 146.8411],
  [-41.1244, 146.8403],
  [-41.1270, 146.8387],
  [-41.1284, 146.8384],
  [-41.1295, 146.8386],
  [-41.1306, 146.8395],
  [-41.1319, 146.8409],
  [-41.1331, 146.8416],
  [-41.1340, 146.8417],
  [-41.1354, 146.8417],
  [-41.1361, 146.8419],
  [-41.1369, 146.8426],
  [-41.1372, 146.8436],
  [-41.1372, 146.8446],
  [-41.1370, 146.8453],
  [-41.1364, 146.8466],
  [-41.1362, 146.8472],
  [-41.1364, 146.8480],
  [-41.1372, 146.8501],
  [-41.1383, 146.8543],
  [-41.1383, 146.8553],
  [-41.1381, 146.8560],
  [-41.1379, 146.8566],
  [-41.1380, 146.8570],
  [-41.1381, 146.8576],
  [-41.1381, 146.8581],
  [-41.1379, 146.8585],
  [-41.1371, 146.8600],
  [-41.1361, 146.8619],
  [-41.1358, 146.8621],
  [-41.1356, 146.8622],
  [-41.1353, 146.8622],
  [-41.1350, 146.8622],
  [-41.1347, 146.8624],
  [-41.1346, 146.8626],
  [-41.1342, 146.8633],
  [-41.1341, 146.8636],
  [-41.1338, 146.8644]
];

const artecSiding = [
  [-41.1345, 146.8417],
  [-41.1352, 146.8421],
  [-41.1357, 146.8421],
  [-41.1363, 146.8424],
  [-41.1368, 146.8430],
  [-41.1370, 146.8441],
  [-41.1367, 146.8452],
  [-41.1358, 146.8466],
  [-41.1354, 146.8477],
  [-41.1340, 146.8502],
  [-41.1339, 146.8509],
  [-41.1341, 146.8513],
  [-41.1347, 146.8519]
];

export const bellBay: Line = {
  name: 'Bell Bay',
  state: 'TAS',
  segments: [
    {
      segments: [coldwaterCreekjunctionLongReachJunction, longReachBranch],
      history: {
        opened: {
          date: '1973-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [launcestonJunction],
      history: {
        opened: {
          date: '1974-08-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mowbrayLink],
      history: {
        opened: {
          date: '1974-12-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eastTamarJunctionColdwaterCreekJunctionNewOne, eastTamarJunctionColdwaterCreekJunctionNewTwo],
      history: {
        opened: {
          date: '1974-08-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [longreachJunctionBellBay],
      history: {
        opened: {
          date: '1974-05-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [artecSiding],
      history: {
        opened: {
          date: '2001-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
