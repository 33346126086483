import { Line } from "../../trackTypes";

const parkestonSaBorder = [
  [-30.7412, 121.4719],
  [-30.7403, 121.4725],
  [-30.7381, 121.4734],
  [-30.7372, 121.4740],
  [-30.7365, 121.4748],
  [-30.7360, 121.4759],
  [-30.7353, 121.4781],
  [-30.7344, 121.4799],
  [-30.7332, 121.4813],
  [-30.7305, 121.4836],
  [-30.7294, 121.4851],
  [-30.7289, 121.4863],
  [-30.7265, 121.4960],
  [-30.7265, 121.4981],
  [-30.7269, 121.4999],
  [-30.7278, 121.5015],
  [-30.7329, 121.5065],
  [-30.7402, 121.5157],
  [-30.7558, 121.5355],
  [-30.8094, 121.6032],
  [-30.8155, 121.6147],
  [-30.8374, 121.6476],
  [-30.8491, 121.6590],
  [-30.8505, 121.6611],
  [-30.8562, 121.6782],
  [-30.8586, 121.6825],
  [-30.8597, 121.6860],
  [-30.8710, 121.7598],
  [-30.8721, 121.7634],
  [-30.8852, 121.8000],
  [-30.8914, 121.8268],
  [-30.8927, 121.8564],
  [-30.8936, 121.8600],
  [-30.9041, 121.9062],
  [-30.9177, 122.0760],
  [-30.9214, 122.1006],
  [-30.9206, 122.1320],
  [-30.9439, 122.2547],
  [-30.9641, 122.3069],
  [-30.9729, 122.3657],
  [-30.9729, 122.3752],
  [-30.9666, 122.4277],
  [-30.9578, 122.4635],
  [-30.9582, 122.4959],
  [-30.9710, 122.5527],
  [-30.9765, 122.5900],
  [-30.9766, 122.5982],
  [-30.9752, 122.6122],
  [-30.9765, 122.6190],
  [-30.9767, 122.6443],
  [-31.0192, 123.0432],
  [-31.0172, 123.0509],
  [-31.0170, 123.0641],
  [-31.0192, 123.0704],
  [-31.0198, 123.0752],
  [-31.0209, 123.0783],
  [-31.0224, 123.0807],
  [-31.0233, 123.0833],
  [-31.0238, 123.0878],
  [-31.0233, 123.0938],
  [-31.0209, 123.1137],
  [-31.0363, 123.7896],
  [-31.0387, 123.8109],
  [-31.0410, 123.9270],
  [-31.0427, 123.9407],
  [-31.0422, 123.9467],
  [-31.0371, 123.9639],
  [-31.0325, 124.0953],
  [-31.0340, 124.1277],
  [-31.0207, 124.3124],
  [-30.9994, 124.8518],
  [-31.0065, 124.9884],
  [-31.0051, 125.0671],
  [-31.0086, 125.3452],
  [-31.0095, 125.4388],
  [-31.0113, 125.6329],
  [-31.0130, 125.8775],
  [-30.9871, 126.5338],
  [-30.9868, 126.5368],
  [-30.9821, 126.5628],
  [-30.9809, 126.5662],
  [-30.9783, 126.5712],
  [-30.9774, 126.5744],
  [-30.9765, 126.5856],
  [-30.9760, 126.6672],
  [-30.9425, 127.0696],
  [-30.8523, 128.0866],
  [-30.7841, 128.7975],
  [-30.7636, 129.0013]
];

export const transAustrailan: Line = {
  name: 'Trans-Australian (WA)',
  state: 'WA',
  segments: [
    {
      segments: [parkestonSaBorder],
      history: {
        opened: {
          date: '1917-10-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
