import { Line } from "../../../trackTypes";

const line = [
  [-37.1025, 144.3334],
  [-37.1029, 144.3378],
  [-37.1028, 144.3386],
  [-37.1003, 144.3468],
  [-37.1003, 144.3475],
  [-37.1002, 144.3485],
  [-37.0948, 144.3606],
  [-37.0928, 144.3627],
  [-37.0923, 144.3640],
  [-37.0912, 144.3679],
  [-37.0909, 144.3697],
  [-37.0913, 144.3731],
  [-37.0916, 144.3737],
  [-37.0922, 144.3740],
  [-37.0926, 144.3739],
  [-37.0929, 144.3737],
  [-37.0932, 144.3732],
  [-37.0935, 144.3729],
  [-37.0939, 144.3730],
  [-37.0942, 144.3733],
  [-37.0943, 144.3739],
  [-37.0938, 144.3760],
  [-37.0939, 144.3767],
  [-37.0940, 144.3776],
  [-37.0932, 144.3797],
  [-37.0919, 144.3814],
  [-37.0881, 144.3848],
  [-37.0879, 144.3849],
  [-37.0875, 144.3849],
  [-37.0838, 144.3845],
  [-37.0834, 144.3847],
  [-37.0828, 144.3861],
  [-37.0826, 144.3866],
  [-37.0824, 144.3883]
];

export const elphinstone: Line = {
  name: 'Elphinston Tramway',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1928-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
