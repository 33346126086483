import { Line } from "../../../trackTypes";

const eaglehawkBridgewater = [
  [-36.7156, 144.2320],
  [-36.7157, 144.2304],
  [-36.7159, 144.2295],
  [-36.7166, 144.2274],
  [-36.7219, 144.2157],
  [-36.7260, 144.2064],
  [-36.7269, 144.2039],
  [-36.7280, 144.1992],
  [-36.7333, 144.1649],
  [-36.7373, 144.1390],
  [-36.7373, 144.1369],
  [-36.7369, 144.1345],
  [-36.7328, 144.1205],
  [-36.7320, 144.1183],
  [-36.7304, 144.1155],
  [-36.6175, 143.9774],
  [-36.6155, 143.9745],
  [-36.6026, 143.9468]
];

const bridgewaterInglewood = [
  [-36.6026, 143.9468],
  [-36.5835, 143.9055],
  [-36.5826, 143.9033],
  [-36.5747, 143.8770]
];

export const eaglehawkInglewood: Line = {
  name: 'Eaglehawk Inglewood',
  state: 'VIC',
  segments: [
    {
      segments: [eaglehawkBridgewater],
      history: {
        opened: {
          date: '1876-09-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-05-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bridgewaterInglewood],
      history: {
        opened: {
          date: '1876-09-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-05-25',
          status: 'closed'
        }]
      }
    }
  ]
}
