import { Line } from "../../trackTypes";

const gisbornePortGisborne = [
  [-38.6769, 178.0203],
  [-38.6711, 178.0305],
  [-38.6706, 178.0308],
  [-38.6703, 178.0308],
  [-38.6698, 178.0305],
  [-38.6695, 178.0301],
  [-38.6693, 178.0292],
  [-38.6693, 178.0285],
  [-38.6696, 178.0280],
  [-38.6700, 178.0277],
  [-38.6712, 178.0276],
  [-38.6717, 178.0272],
  [-38.6720, 178.0267],
  [-38.6721, 178.0262],
  [-38.6721, 178.0247],
  [-38.6718, 178.0238],
  [-38.6707, 178.0218]
];

const gisborneMoutohoraJunction = [
  [-38.6707, 178.0218],
  [-38.6680, 178.0169],
  [-38.6623, 178.0009]
];

const moutohoraJunctionMuriwai = [
  [-38.6623, 178.0009],
  [-38.6620, 177.9986],
  [-38.6643, 177.9845],
  [-38.6664, 177.9751],
  [-38.6671, 177.9732],
  [-38.6715, 177.9643],
  [-38.6732, 177.9622],
  [-38.6814, 177.9558],
  [-38.6829, 177.9539],
  [-38.6840, 177.9514],
  [-38.6844, 177.9497],
  [-38.6853, 177.9474],
  [-38.6872, 177.9451],
  [-38.7001, 177.9346],
  [-38.7012, 177.9334],
  [-38.7065, 177.9257],
  [-38.7075, 177.9246],
  [-38.7162, 177.9173],
  [-38.7183, 177.9162],
  [-38.7210, 177.9161],
  [-38.7285, 177.9188],
  [-38.7298, 177.9191],
  [-38.7537, 177.9224],
  [-38.7548, 177.9224],
  [-38.7589, 177.9219]
];

const muriwaiTunnelNo26 = [
  [-38.7589, 177.9219],
  [-38.7719, 177.9206],
  [-38.7884, 177.9161],
  [-38.7898, 177.9152],
  [-38.7967, 177.9066],
  [-38.7985, 177.9052],
  [-38.7999, 177.9047],
  [-38.8019, 177.9032],
  [-38.8028, 177.9028],
  [-38.8067, 177.9020],
  [-38.8105, 177.8999],
  [-38.8177, 177.8946],
  [-38.8207, 177.8934],
  [-38.8215, 177.8935],
  [-38.8230, 177.8939],
  [-38.8241, 177.8936],
  [-38.8275, 177.8908],
  [-38.8283, 177.8907],
  [-38.8291, 177.8911],
  [-38.8314, 177.8945],
  [-38.8322, 177.8949],
  [-38.8330, 177.8948],
  [-38.8343, 177.8940],
  [-38.8350, 177.8939],
  [-38.8356, 177.8942],
  [-38.8430, 177.8999],
  [-38.8435, 177.9008],
  [-38.8437, 177.9022],
  [-38.8442, 177.9034],
  [-38.8465, 177.9066],
  [-38.8476, 177.9071],
  [-38.8510, 177.9070],
  [-38.8518, 177.9065],
  [-38.8524, 177.9060],
  [-38.8532, 177.9056],
  [-38.8550, 177.9054],
  [-38.8608, 177.9035],
  [-38.8655, 177.8998],
  [-38.8661, 177.8989],
  [-38.8668, 177.8977],
  [-38.8680, 177.8967],
  [-38.8683, 177.8965]
];

const tunnelNo26 = [
  [-38.8683, 177.8965],
  [-38.8771, 177.8932],
  [-38.8796, 177.8928],
  [-38.8813, 177.8930]
];

const tunnelNo26Tunnelno25 = [
  [-38.8813, 177.8930],
  [-38.8813, 177.8930],
  [-38.8819, 177.8931],
  [-38.8828, 177.8937],
  [-38.8835, 177.8946],
  [-38.8838, 177.8954],
  [-38.8838, 177.8962]
];

const tunnelNo25 = [
  [-38.8838, 177.8962],
  [-38.8839, 177.8974]
];

const tunnel24Tunnel25 = [
  [-38.8839, 177.8974],
  [-38.8841, 177.8987],
  [-38.8843, 177.8993],
  [-38.8847, 177.8997],
  [-38.8867, 177.9003],
  [-38.8875, 177.9003],
  [-38.8888, 177.8999],
  [-38.8895, 177.8999],
  [-38.8911, 177.9005],
  [-38.8919, 177.9005],
  [-38.8929, 177.9003],
  [-38.8936, 177.9004],
  [-38.8950, 177.9015],
  [-38.8957, 177.9017],
  [-38.8972, 177.9018],
  [-38.8981, 177.9024],
  [-38.8986, 177.9032]
];

const tunnelNo24 = [
  [-38.8986, 177.9032],
  [-38.8988, 177.9035],
  [-38.8992, 177.9039],
  [-38.8996, 177.9039],
  [-38.8999, 177.9038]
];

const tunnel23Tunnel24 = [
  [-38.8999, 177.9038],
  [-38.9004, 177.9038],
  [-38.9020, 177.9038],
  [-38.9041, 177.9045]
];

const tunnelNo23 = [
  [-38.9041, 177.9045],
  [-38.9045, 177.9045],
  [-38.9048, 177.9043],
  [-38.9059, 177.9037],
  [-38.9116, 177.8998]
];

const tunnel22Tunnel23 = [
  [-38.9116, 177.8998],
  [-38.9127, 177.8990],
  [-38.9143, 177.8983],
  [-38.9152, 177.8980]
];

const tunnelNo22 = [
  [-38.9152, 177.8980],
  [-38.9160, 177.8978],
  [-38.9169, 177.8972]
];

const tunnel21Tunnel22 = [
  [-38.9169, 177.8972],
  [-38.9178, 177.8963]
];

const tunnelNo21 = [
  [-38.9178, 177.8963],
  [-38.9182, 177.8958]
];

const tunnel20Tunnel21 = [
  [-38.9182, 177.8958],
  [-38.9184, 177.8955]
];

const tunnelNo20 = [
  [-38.9184, 177.8955],
  [-38.9192, 177.8943]
];

const tikiwhataTunnelTunnel20 = [
  [-38.9192, 177.8943],
  [-38.9194, 177.8941]
];

const tikiwhataTunnel = [
  [-38.9194, 177.8941],
  [-38.9415, 177.8745]
];

const tunnel18TikiwhataTunnel = [
  [-38.9415, 177.8745],
  [-38.9417, 177.8742],
  [-38.9423, 177.8738],
  [-38.9432, 177.8735],
  [-38.9451, 177.8734],
  [-38.9455, 177.8733],
  [-38.9456, 177.8732]
];

const tunnel18 = [
  [-38.9456, 177.8732],
  [-38.9464, 177.8717],
  [-38.9466, 177.8715]
];

const tunnel17Tunnel18 = [
  [-38.9466, 177.8715],
  [-38.9470, 177.8711],
  [-38.9484, 177.8705],
  [-38.9490, 177.8698],
  [-38.9492, 177.8691],
  [-38.9494, 177.8667],
  [-38.9497, 177.8658],
  [-38.9502, 177.8652],
  [-38.9524, 177.8645],
  [-38.9540, 177.8646],
  [-38.9549, 177.8644],
  [-38.9554, 177.8639],
  [-38.9561, 177.8623]
];

const tunnelNo17 = [
  [-38.9561, 177.8623],
  [-38.9565, 177.8615]
];

const tunnel16Tunnel17 = [
  [-38.9565, 177.8615],
  [-38.9573, 177.8604],
  [-38.9578, 177.8599],
  [-38.9583, 177.8597],
  [-38.9590, 177.8599],
  [-38.9599, 177.8610],
  [-38.9604, 177.8612],
  [-38.9627, 177.8612]
];

const tunnelNo16 = [
  [-38.9627, 177.8612],
  [-38.9632, 177.8610],
  [-38.9636, 177.8606]
];

const tunnel15Tunnel16 = [
  [-38.9636, 177.8606],
  [-38.9640, 177.8603],
  [-38.9646, 177.8601],
  [-38.9651, 177.8601],
  [-38.9658, 177.8604],
  [-38.9664, 177.8604],
  [-38.9669, 177.8601],
  [-38.9679, 177.8590],
  [-38.9691, 177.8584],
  [-38.9697, 177.8584],
  [-38.9712, 177.8586],
  [-38.9716, 177.8588],
  [-38.9723, 177.8596],
  [-38.9728, 177.8598],
  [-38.9749, 177.8598]
];

const tunnelNo15 = [
  [-38.9749, 177.8598],
  [-38.9755, 177.8598],
  [-38.9760, 177.8595]
];

const tunnel14Tunnel15 = [
  [-38.9760, 177.8595],
  [-38.9760, 177.8595],
  [-38.9769, 177.8588],
  [-38.9778, 177.8583],
  [-38.9786, 177.8584],
  [-38.9795, 177.8591],
  [-38.9810, 177.8614]
];

const tunnelNo14 = [
  [-38.9810, 177.8614],
  [-38.9813, 177.8620]
];

const tunnelNo14Waikokopu = [
  [-38.9813, 177.8620],
  [-38.9826, 177.8652],
  [-38.9832, 177.8658],
  [-38.9838, 177.8660],
  [-38.9864, 177.8654],
  [-38.9872, 177.8651],
  [-38.9883, 177.8643],
  [-38.9888, 177.8640],
  [-38.9924, 177.8632],
  [-38.9936, 177.8627],
  [-38.9941, 177.8624],
  [-38.9947, 177.8621],
  [-38.9987, 177.8620],
  [-39.0020, 177.8612],
  [-39.0057, 177.8598],
  [-39.0068, 177.8598],
  [-39.0084, 177.8607],
  [-39.0094, 177.8610],
  [-39.0122, 177.8612],
  [-39.0131, 177.8611],
  [-39.0206, 177.8578],
  [-39.0214, 177.8569],
  [-39.0219, 177.8558],
  [-39.0226, 177.8550],
  [-39.0400, 177.8481],
  [-39.0415, 177.8470],
  [-39.0534, 177.8360],
  [-39.0560, 177.8346],
  [-39.0607, 177.8336],
  [-39.0613, 177.8333],
  [-39.0618, 177.8329],
  [-39.0624, 177.8327],
  [-39.0633, 177.8326],
  [-39.0639, 177.8323],
  [-39.0659, 177.8308],
  [-39.0685, 177.8295],
  [-39.0706, 177.8276],
  [-39.0713, 177.8273],
  [-39.0722, 177.8271],
  [-39.0728, 177.8267]
];

const waikokopuPort = [
  [-39.0728, 177.8267],
  [-39.0724, 177.8277],
  [-39.0721, 177.8280],
  [-39.0718, 177.8284],
  [-39.0715, 177.8285],
  [-39.0709, 177.8285]
];

const waikokopuWairoa = [
  [-39.0728, 177.8267],
  [-39.0731, 177.8258],
  [-39.0731, 177.8250],
  [-39.0729, 177.8244],
  [-39.0718, 177.8234],
  [-39.0711, 177.8232],
  [-39.0704, 177.8234],
  [-39.0695, 177.8237],
  [-39.0688, 177.8239],
  [-39.0676, 177.8241],
  [-39.0670, 177.8239],
  [-39.0663, 177.8230],
  [-39.0662, 177.8221],
  [-39.0664, 177.8212],
  [-39.0669, 177.8205],
  [-39.0676, 177.8203],
  [-39.0685, 177.8204],
  [-39.0689, 177.8203],
  [-39.0693, 177.8201],
  [-39.0706, 177.8185],
  [-39.0710, 177.8175],
  [-39.0717, 177.8149],
  [-39.0719, 177.8130],
  [-39.0719, 177.8123],
  [-39.0718, 177.8117],
  [-39.0702, 177.8089],
  [-39.0695, 177.8082],
  [-39.0691, 177.8077],
  [-39.0684, 177.8061],
  [-39.0681, 177.8049],
  [-39.0675, 177.8032],
  [-39.0674, 177.8029],
  [-39.0673, 177.8025],
  [-39.0672, 177.8019],
  [-39.0667, 177.8012],
  [-39.0663, 177.8004],
  [-39.0663, 177.7997],
  [-39.0665, 177.7983],
  [-39.0664, 177.7976],
  [-39.0654, 177.7941],
  [-39.0652, 177.7927],
  [-39.0651, 177.7921],
  [-39.0648, 177.7915],
  [-39.0647, 177.7909],
  [-39.0646, 177.7904],
  [-39.0644, 177.7899],
  [-39.0638, 177.7893],
  [-39.0633, 177.7887],
  [-39.0629, 177.7877],
  [-39.0627, 177.7868],
  [-39.0627, 177.7852],
  [-39.0626, 177.7839],
  [-39.0622, 177.7826],
  [-39.0620, 177.7822],
  [-39.0613, 177.7817],
  [-39.0607, 177.7816],
  [-39.0603, 177.7818],
  [-39.0593, 177.7825],
  [-39.0587, 177.7827],
  [-39.0578, 177.7825],
  [-39.0574, 177.7821],
  [-39.0571, 177.7814],
  [-39.0568, 177.7798],
  [-39.0566, 177.7790],
  [-39.0556, 177.7770],
  [-39.0543, 177.7759],
  [-39.0529, 177.7752],
  [-39.0524, 177.7748],
  [-39.0521, 177.7744],
  [-39.0517, 177.7733],
  [-39.0508, 177.7720],
  [-39.0503, 177.7708],
  [-39.0500, 177.7697],
  [-39.0499, 177.7686],
  [-39.0504, 177.7621],
  [-39.0503, 177.7566],
  [-39.0501, 177.7552],
  [-39.0471, 177.7430],
  [-39.0460, 177.7362],
  [-39.0454, 177.7285],
  [-39.0456, 177.7166],
  [-39.0473, 177.6890],
  [-39.0468, 177.6397],
  [-39.0486, 177.6243],
  [-39.0487, 177.6229],
  [-39.0481, 177.6149],
  [-39.0479, 177.6137],
  [-39.0409, 177.5961],
  [-39.0406, 177.5947],
  [-39.0381, 177.5709],
  [-39.0382, 177.5696],
  [-39.0385, 177.5681],
  [-39.0413, 177.5629],
  [-39.0414, 177.5618],
  [-39.0413, 177.5608],
  [-39.0408, 177.5599],
  [-39.0395, 177.5587],
  [-39.0380, 177.5566],
  [-39.0373, 177.5546],
  [-39.0371, 177.5522],
  [-39.0372, 177.5492],
  [-39.0370, 177.5479],
  [-39.0347, 177.5388],
  [-39.0312, 177.5293],
  [-39.0309, 177.5273],
  [-39.0304, 177.5083],
  [-39.0327, 177.4881],
  [-39.0335, 177.4417],
  [-39.0331, 177.4397],
  [-39.0319, 177.4385],
  [-39.0258, 177.4351],
  [-39.0248, 177.4338],
  [-39.0244, 177.4322],
  [-39.0244, 177.4305]
];

const wairoaFreezingWorks = [
  [-39.0244, 177.4305],
  [-39.0247, 177.4298],
  [-39.0252, 177.4293],
  [-39.0264, 177.4293],
  [-39.0269, 177.4294],
  [-39.0315, 177.4311]
];

const freezingWorksWestJunction = [
  [-39.0243, 177.4282],
  [-39.0245, 177.4289],
  [-39.0248, 177.4292],
  [-39.0252, 177.4293]
];

const wairoaTunnelNo13 = [
  [-39.0244, 177.4305],
  [-39.0243, 177.4282],
  [-39.0239, 177.4095],
  [-39.0234, 177.4076],
  [-39.0223, 177.4059],
  [-39.0065, 177.3964],
  [-39.0053, 177.3951],
  [-39.0040, 177.3916],
  [-39.0038, 177.3901],
  [-39.0042, 177.3886],
  [-39.0077, 177.3830],
  [-39.0134, 177.3719],
  [-39.0138, 177.3704],
  [-39.0138, 177.3692],
  [-39.0135, 177.3675],
  [-39.0126, 177.3658],
  [-39.0122, 177.3643],
  [-39.0122, 177.3626],
  [-39.0133, 177.3583],
  [-39.0134, 177.3568],
  [-39.0127, 177.3435],
  [-39.0128, 177.3415],
  [-39.0143, 177.3362],
  [-39.0152, 177.3344],
  [-39.0168, 177.3328],
  [-39.0205, 177.3271],
  [-39.0210, 177.3260],
  [-39.0219, 177.3227],
  [-39.0224, 177.3214],
  [-39.0231, 177.3206],
  [-39.0362, 177.3108],
  [-39.0374, 177.3104],
  [-39.0401, 177.3102],
  [-39.0426, 177.3107],
  [-39.0485, 177.3109],
  [-39.0495, 177.3107],
  [-39.0525, 177.3097],
  [-39.0588, 177.3047],
  [-39.0595, 177.3044],
  [-39.0605, 177.3044],
  [-39.0618, 177.3051],
  [-39.0633, 177.3051],
  [-39.0644, 177.3043],
  [-39.0663, 177.3014],
  [-39.0670, 177.3003]
];

const tunnelNo13 = [
  [-39.0670, 177.3003],
  [-39.0687, 177.2977]
];

const tunnel13Tunnel12 = [
  [-39.0687, 177.2977],
  [-39.0694, 177.2967],
  [-39.0702, 177.2960],
  [-39.0707, 177.2958],
  [-39.0719, 177.2957],
  [-39.0727, 177.2952],
  [-39.0735, 177.2940],
  [-39.0747, 177.2865],
  [-39.0753, 177.2852],
  [-39.0763, 177.2840],
  [-39.0784, 177.2824],
  [-39.0791, 177.2821],
  [-39.0799, 177.2821],
  [-39.0856, 177.2835],
  [-39.0871, 177.2834],
  [-39.0885, 177.2826],
  [-39.0898, 177.2811],
  [-39.0906, 177.2790],
  [-39.0920, 177.2682],
  [-39.0919, 177.2658],
  [-39.0904, 177.2579],
  [-39.0899, 177.2560],
  [-39.0880, 177.2524],
  [-39.0870, 177.2497],
  [-39.0864, 177.2469],
  [-39.0863, 177.2429],
  [-39.0874, 177.2354],
  [-39.0876, 177.2331],
  [-39.0871, 177.2265],
  [-39.0868, 177.2245],
  [-39.0860, 177.2224],
  [-39.0830, 177.2184],
  [-39.0825, 177.2172],
  [-39.0824, 177.2164],
  [-39.0824, 177.2118],
  [-39.0820, 177.2096],
  [-39.0778, 177.2000],
  [-39.0776, 177.1985],
  [-39.0777, 177.1973],
  [-39.0784, 177.1948],
  [-39.0785, 177.1940],
  [-39.0782, 177.1931],
  [-39.0783, 177.1924],
  [-39.0785, 177.1918],
  [-39.0796, 177.1902],
  [-39.0799, 177.1893],
  [-39.0799, 177.1889],
  [-39.0799, 177.1871]
];

const tunnelNo12 = [
  [-39.0799, 177.1871],
  [-39.0834, 177.1800]
];

const tunnel12Tunnel11 = [
  [-39.0834, 177.1800],
  [-39.0838, 177.1791],
  [-39.0839, 177.1779],
  [-39.0836, 177.1769],
  [-39.0831, 177.1760],
  [-39.0814, 177.1750],
  [-39.0807, 177.1742],
  [-39.0775, 177.1688],
  [-39.0772, 177.1680],
  [-39.0769, 177.1671],
  [-39.0764, 177.1662],
  [-39.0754, 177.1654],
  [-39.0746, 177.1642],
  [-39.0745, 177.1633],
  [-39.0746, 177.1625],
  [-39.0769, 177.1580],
  [-39.0775, 177.1574],
  [-39.0778, 177.1569],
  [-39.0779, 177.1564],
  [-39.0779, 177.1533],
  [-39.0777, 177.1529],
  [-39.0774, 177.1523],
  [-39.0762, 177.1510],
  [-39.0750, 177.1490],
  [-39.0680, 177.1291],
  [-39.0679, 177.1279],
  [-39.0690, 177.1238],
  [-39.0690, 177.1231],
  [-39.0687, 177.1223],
  [-39.0682, 177.1217],
  [-39.0657, 177.1207],
  [-39.0651, 177.1199],
  [-39.0648, 177.1187],
  [-39.0650, 177.1176],
  [-39.0673, 177.1141],
  [-39.0676, 177.1129],
  [-39.0674, 177.1118],
  [-39.0667, 177.1108],
  [-39.0632, 177.1090],
  [-39.0622, 177.1087],
  [-39.0614, 177.1081],
  [-39.0610, 177.1073],
  [-39.0609, 177.1060],
  [-39.0623, 177.1020],
  [-39.0625, 177.1012],
  [-39.0623, 177.0978],
  [-39.0620, 177.0967],
  [-39.0616, 177.0958],
  [-39.0615, 177.0946]
];

const tunnelNo11 = [
  [-39.0615, 177.0946],
  [-39.0616, 177.0908]
];

const tunnel11Tunnel10 = [
  [-39.0616, 177.0908],
  [-39.0616, 177.0890],
  [-39.0623, 177.0868],
  [-39.0624, 177.0855],
  [-39.0617, 177.0816],
  [-39.0618, 177.0801],
  [-39.0619, 177.0797]
];

const tunnelNo10 = [
  [-39.0619, 177.0797],
  [-39.0627, 177.0772],
  [-39.0627, 177.0764]
];

const tunnel10Tunnel9 = [
  [-39.0627, 177.0764],
  [-39.0624, 177.0758],
  [-39.0619, 177.0750],
  [-39.0609, 177.0745],
  [-39.0602, 177.0736],
  [-39.0591, 177.0702],
  [-39.0578, 177.0682],
  [-39.0561, 177.0671],
  [-39.0556, 177.0661],
  [-39.0556, 177.0650],
  [-39.0559, 177.0636],
  [-39.0558, 177.0622],
  [-39.0551, 177.0611],
  [-39.0542, 177.0606],
  [-39.0537, 177.0600],
  [-39.0526, 177.0581],
  [-39.0523, 177.0573],
  [-39.0524, 177.0561],
  [-39.0531, 177.0538],
  [-39.0538, 177.0527],
  [-39.0546, 177.0523],
  [-39.0574, 177.0525],
  [-39.0588, 177.0528],
  [-39.0597, 177.0525],
  [-39.0602, 177.0513],
  [-39.0600, 177.0501],
  [-39.0592, 177.0493],
  [-39.0589, 177.0485],
  [-39.0589, 177.0477],
  [-39.0592, 177.0469],
  [-39.0599, 177.0459],
  [-39.0601, 177.0451],
  [-39.0602, 177.0439],
  [-39.0605, 177.0428],
  [-39.0612, 177.0423],
  [-39.0628, 177.0418],
  [-39.0637, 177.0411],
  [-39.0643, 177.0401],
  [-39.0653, 177.0390],
  [-39.0693, 177.0365],
  [-39.0700, 177.0356],
  [-39.0704, 177.0346],
  [-39.0707, 177.0341]
];

const tunnelNo9 = [
  [-39.0707, 177.0341],
  [-39.0751, 177.0272],
  [-39.0756, 177.0267]
];

const tunnelNo9Putorino = [
  [-39.0756, 177.0267],
  [-39.0771, 177.0262],
  [-39.0778, 177.0257],
  [-39.0797, 177.0231],
  [-39.0802, 177.0218],
  [-39.0807, 177.0209],
  [-39.0821, 177.0191],
  [-39.0830, 177.0185],
  [-39.0838, 177.0184],
  [-39.0846, 177.0181],
  [-39.0871, 177.0160],
  [-39.0875, 177.0153],
  [-39.0879, 177.0144],
  [-39.0886, 177.0136],
  [-39.0894, 177.0134],
  [-39.0904, 177.0138],
  [-39.0919, 177.0158],
  [-39.0933, 177.0168],
  [-39.0952, 177.0169],
  [-39.0967, 177.0161],
  [-39.0977, 177.0149],
  [-39.1008, 177.0085],
  [-39.1017, 177.0073],
  [-39.1037, 177.0054],
  [-39.1041, 177.0045],
  [-39.1042, 177.0034],
  [-39.1039, 177.0019],
  [-39.1039, 177.0009],
  [-39.1046, 176.9998],
  [-39.1074, 176.9982],
  [-39.1089, 176.9977],
  [-39.1113, 176.9962],
  [-39.1122, 176.9959],
  [-39.1134, 176.9958],
  [-39.1143, 176.9954],
  [-39.1167, 176.9936],
  [-39.1174, 176.9926],
  [-39.1191, 176.9883],
  [-39.1207, 176.9865],
  [-39.1217, 176.9860],
  [-39.1232, 176.9857],
  [-39.1316, 176.9865],
  [-39.1324, 176.9862],
  [-39.1339, 176.9854]
];

const putorinoTunnelNo8 = [
  [-39.1339, 176.9854],
  [-39.1357, 176.9842],
  [-39.1367, 176.9838],
  [-39.1392, 176.9833],
  [-39.1402, 176.9828],
  [-39.1408, 176.9822],
  [-39.1416, 176.9810],
  [-39.1421, 176.9796],
  [-39.1425, 176.9743],
  [-39.1427, 176.9734],
  [-39.1431, 176.9728],
  [-39.1471, 176.9682],
  [-39.1477, 176.9668],
  [-39.1480, 176.9652],
  [-39.1480, 176.9630],
  [-39.1482, 176.9620],
  [-39.1487, 176.9612],
  [-39.1518, 176.9584],
  [-39.1526, 176.9580],
  [-39.1535, 176.9579],
  [-39.1544, 176.9575],
  [-39.1550, 176.9569],
  [-39.1560, 176.9556],
  [-39.1572, 176.9546],
  [-39.1590, 176.9543],
  [-39.1632, 176.9556],
  [-39.1643, 176.9556],
  [-39.1674, 176.9545],
  [-39.1681, 176.9539],
  [-39.1684, 176.9529],
  [-39.1693, 176.9461],
  [-39.1711, 176.9397],
  [-39.1718, 176.9378],
  [-39.1761, 176.9310],
  [-39.1769, 176.9301],
  [-39.1783, 176.9292],
  [-39.1789, 176.9284],
  [-39.1793, 176.9277],
  [-39.1801, 176.9271],
  [-39.1808, 176.9270],
  [-39.1816, 176.9273],
  [-39.1824, 176.9273],
  [-39.1831, 176.9268],
  [-39.1836, 176.9259],
  [-39.1850, 176.9211],
  [-39.1860, 176.9186],
  [-39.1862, 176.9178],
  [-39.1861, 176.9171],
  [-39.1855, 176.9145],
  [-39.1856, 176.9135],
  [-39.1860, 176.9125],
  [-39.1875, 176.9112],
  [-39.1879, 176.9104],
  [-39.1881, 176.9096],
  [-39.1879, 176.9078],
  [-39.1877, 176.9070],
  [-39.1861, 176.9044],
  [-39.1856, 176.9026],
  [-39.1851, 176.8977],
  [-39.1852, 176.8962],
  [-39.1856, 176.8950],
  [-39.1903, 176.8871],
  [-39.1911, 176.8861],
  [-39.1921, 176.8854],
  [-39.1927, 176.8852],
  [-39.1962, 176.8850],
  [-39.1971, 176.8846],
  [-39.1976, 176.8837],
  [-39.1985, 176.8799],
  [-39.1984, 176.8785],
  [-39.1980, 176.8773],
  [-39.1980, 176.8765],
  [-39.1981, 176.8755],
  [-39.2007, 176.8690],
  [-39.2012, 176.8681],
  [-39.2042, 176.8646],
  [-39.2059, 176.8633],
  [-39.2076, 176.8625],
  [-39.2084, 176.8616],
  [-39.2089, 176.8606],
  [-39.2092, 176.8594],
  [-39.2098, 176.8581],
  [-39.2132, 176.8550],
  [-39.2154, 176.8520],
  [-39.2159, 176.8510],
  [-39.2164, 176.8505],
  [-39.2170, 176.8503],
  [-39.2191, 176.8505],
  [-39.2199, 176.8502],
  [-39.2204, 176.8496],
  [-39.2218, 176.8463],
  [-39.2219, 176.8453],
  [-39.2220, 176.8441],
  [-39.2222, 176.8434],
  [-39.2227, 176.8428],
  [-39.2245, 176.8414],
  [-39.2261, 176.8391],
  [-39.2263, 176.8383],
  [-39.2262, 176.8375],
  [-39.2257, 176.8365],
  [-39.2255, 176.8356],
  [-39.2255, 176.8327],
  [-39.2257, 176.8313],
  [-39.2264, 176.8300],
  [-39.2276, 176.8291],
  [-39.2287, 176.8289],
  [-39.2327, 176.8296],
  [-39.2335, 176.8294],
  [-39.2342, 176.8285],
  [-39.2347, 176.8264],
  [-39.2346, 176.8255],
  [-39.2340, 176.8246],
  [-39.2332, 176.8244],
  [-39.2324, 176.8246],
  [-39.2316, 176.8244],
  [-39.2310, 176.8236],
  [-39.2309, 176.8226],
  [-39.2312, 176.8218],
  [-39.2317, 176.8213],
  [-39.2328, 176.8208],
  [-39.2333, 176.8204],
  [-39.2336, 176.8196],
  [-39.2336, 176.8191],
  [-39.2335, 176.8182],
  [-39.2336, 176.8175],
  [-39.2338, 176.8170],
  [-39.2367, 176.8132],
  [-39.2374, 176.8127],
  [-39.2389, 176.8121],
  [-39.2397, 176.8116],
  [-39.2426, 176.8080],
  [-39.2466, 176.8054],
  [-39.2473, 176.8051],
  [-39.2486, 176.8051],
  [-39.2496, 176.8048],
  [-39.2503, 176.8041],
  [-39.2513, 176.8020],
  [-39.2518, 176.8012],
  [-39.2535, 176.7998],
  [-39.2547, 176.7995],
  [-39.2559, 176.7999],
  [-39.2572, 176.8008],
  [-39.2578, 176.8010],
  [-39.2596, 176.8007],
  [-39.2603, 176.8000],
  [-39.2607, 176.7990],
  [-39.2607, 176.7976],
  [-39.2610, 176.7969],
  [-39.2614, 176.7961],
  [-39.2628, 176.7950],
  [-39.2640, 176.7948],
  [-39.2650, 176.7952],
  [-39.2679, 176.7980],
  [-39.2687, 176.7985],
  [-39.2712, 176.7992],
  [-39.2721, 176.7998],
  [-39.2728, 176.8013],
  [-39.2734, 176.8018]
];

const tunnelNo8 = [
  [-39.2734, 176.8018],
  [-39.2737, 176.8019],
  [-39.2743, 176.8018]
];

const tunnel7Tunnel8 = [
  [-39.2743, 176.8018],
  [-39.2751, 176.8013],
  [-39.2763, 176.8015],
  [-39.2776, 176.8025],
  [-39.2782, 176.8035],
  [-39.2785, 176.8048],
  [-39.2794, 176.8062],
  [-39.2820, 176.8072],
  [-39.2827, 176.8079],
  [-39.2831, 176.8088],
  [-39.2831, 176.8095],
  [-39.2836, 176.8104],
  [-39.2876, 176.8136],
  [-39.2900, 176.8144],
  [-39.2906, 176.8147]
];

const tunnelNo7 = [
  [-39.2906, 176.8147],
  [-39.2907, 176.8148],
  [-39.2914, 176.8156],
];

const tunnel6Tunnel7 = [
  [-39.2914, 176.8156],
  [-39.2917, 176.8160],
  [-39.2924, 176.8163],
  [-39.2930, 176.8162],
  [-39.2935, 176.8157],
  [-39.2940, 176.8148],
  [-39.2947, 176.8142],
  [-39.2956, 176.8141],
  [-39.2965, 176.8141],
  [-39.2987, 176.8139],
  [-39.2992, 176.8138],
  [-39.2998, 176.8133],
  [-39.3004, 176.8132],
  [-39.3025, 176.8137]
];

const tunnelNo6 = [
  [-39.3025, 176.8137],
  [-39.3032, 176.8137],
  [-39.3039, 176.8135],
  [-39.3043, 176.8130]
];

const tunnelNo6Eskdale = [
  [-39.3043, 176.8130],
  [-39.3045, 176.8124],
  [-39.3047, 176.8113],
  [-39.3051, 176.8106],
  [-39.3055, 176.8103],
  [-39.3063, 176.8102],
  [-39.3071, 176.8096],
  [-39.3075, 176.8081],
  [-39.3075, 176.8072],
  [-39.3078, 176.8063],
  [-39.3083, 176.8057],
  [-39.3090, 176.8056],
  [-39.3097, 176.8061],
  [-39.3103, 176.8068],
  [-39.3107, 176.8071],
  [-39.3117, 176.8074],
  [-39.3123, 176.8081],
  [-39.3127, 176.8094],
  [-39.3130, 176.8101],
  [-39.3137, 176.8105],
  [-39.3149, 176.8106],
  [-39.3160, 176.8103],
  [-39.3173, 176.8104],
  [-39.3184, 176.8111],
  [-39.3192, 176.8111],
  [-39.3206, 176.8104],
  [-39.3238, 176.8097],
  [-39.3258, 176.8098],
  [-39.3275, 176.8105],
  [-39.3301, 176.8126],
  [-39.3320, 176.8137],
  [-39.3418, 176.8164],
  [-39.3432, 176.8162],
  [-39.3438, 176.8159],
  [-39.3449, 176.8146],
  [-39.3456, 176.8142],
  [-39.3462, 176.8141],
  [-39.3473, 176.8144],
  [-39.3478, 176.8145],
  [-39.3487, 176.8143],
  [-39.3527, 176.8135],
  [-39.3556, 176.8116],
  [-39.3583, 176.8105],
  [-39.3601, 176.8104],
  [-39.3626, 176.8113],
  [-39.3633, 176.8113],
  [-39.3640, 176.8109],
  [-39.3650, 176.8101],
  [-39.3660, 176.8100],
  [-39.3670, 176.8103],
  [-39.3677, 176.8113],
  [-39.3678, 176.8121],
  [-39.3679, 176.8130],
  [-39.3683, 176.8138],
  [-39.3692, 176.8142],
  [-39.3699, 176.8141],
  [-39.3709, 176.8132],
  [-39.3719, 176.8128],
  [-39.3728, 176.8128],
  [-39.3755, 176.8134],
  [-39.3768, 176.8133],
  [-39.3804, 176.8128],
  [-39.3814, 176.8128],
  [-39.3844, 176.8132],
  [-39.3852, 176.8137],
  [-39.3857, 176.8143],
  [-39.3870, 176.8167],
  [-39.3873, 176.8181],
  [-39.3874, 176.8197],
  [-39.3891, 176.8280]
];

const eskdalePortJunction = [
  [-39.3891, 176.8280],
  [-39.3897, 176.8303],
  [-39.3901, 176.8318],
  [-39.3918, 176.8360],
  [-39.3922, 176.8374],
  [-39.3923, 176.8387],
  [-39.3922, 176.8407],
  [-39.3924, 176.8432],
  [-39.3948, 176.8537],
  [-39.3952, 176.8582],
  [-39.3952, 176.8599],
  [-39.3945, 176.8642],
  [-39.3938, 176.8663],
  [-39.3919, 176.8695],
  [-39.3915, 176.8710],
  [-39.3918, 176.8724],
  [-39.3931, 176.8747],
  [-39.3945, 176.8760],
  [-39.4031, 176.8794],
  [-39.4049, 176.8794],
  [-39.4342, 176.8720],
  [-39.4417, 176.8712],
  [-39.4540, 176.8718],
  [-39.4603, 176.8729],
  [-39.4692, 176.8768],
  [-39.4751, 176.8785],
  [-39.4772, 176.8786],
  [-39.4894, 176.8766],
  [-39.4904, 176.8766],
  [-39.4919, 176.8770],
  [-39.4927, 176.8775],
  [-39.4932, 176.8786],
  [-39.4949, 176.8882],
  [-39.4957, 176.8924]
];

const portJunctionNapier = [
  [-39.4957, 176.8924],
  [-39.4961, 176.8942],
  [-39.4962, 176.8958],
  [-39.4962, 176.8981],
  [-39.4961, 176.8996],
  [-39.4936, 176.9096],
  [-39.4936, 176.9102],
  [-39.4937, 176.9109],
  [-39.4945, 176.9125],
  [-39.4958, 176.9142],
  [-39.4971, 176.9154]
];

const napierHastings = [
  [-39.4971, 176.9154],
  [-39.4983, 176.9163],
  [-39.5010, 176.9174],
  [-39.5025, 176.9177],
  [-39.5127, 176.9173],
  [-39.5165, 176.9174],
  [-39.5301, 176.9181],
  [-39.5437, 176.9197],
  [-39.5496, 176.9206],
  [-39.5581, 176.9227],
  [-39.5592, 176.9227],
  [-39.5629, 176.9220],
  [-39.5702, 176.9194],
  [-39.5721, 176.9185],
  [-39.5739, 176.9171],
  [-39.5833, 176.9071],
  [-39.5866, 176.9020],
  [-39.5875, 176.9011],
  [-39.5888, 176.9006],
  [-39.5921, 176.9001],
  [-39.5945, 176.8989],
  [-39.5994, 176.8942],
  [-39.6001, 176.8937],
  [-39.6022, 176.8932],
  [-39.6029, 176.8928],
  [-39.6035, 176.8920],
  [-39.6105, 176.8776],
  [-39.6113, 176.8765],
  [-39.6333, 176.8526],
  [-39.6390, 176.8464]
];

const hastingsPakipaki = [
  [-39.6390, 176.8464],
  [-39.6446, 176.8402],
  [-39.6637, 176.8192],
  [-39.6752, 176.8031],
  [-39.6770, 176.8014],
  [-39.6913, 176.7947]
];

const pakipakiTeAute = [
  [-39.6913, 176.7947],
  [-39.6945, 176.7932],
  [-39.6955, 176.7931],
  [-39.6970, 176.7935],
  [-39.6980, 176.7937],
  [-39.7019, 176.7934],
  [-39.7045, 176.7925],
  [-39.7053, 176.7919],
  [-39.7058, 176.7912],
  [-39.7068, 176.7895],
  [-39.7077, 176.7884],
  [-39.7129, 176.7833],
  [-39.7144, 176.7815],
  [-39.7187, 176.7753],
  [-39.7227, 176.7673],
  [-39.7237, 176.7661],
  [-39.7251, 176.7651],
  [-39.7296, 176.7635],
  [-39.7344, 176.7605],
  [-39.7364, 176.7586],
  [-39.7423, 176.7513],
  [-39.7510, 176.7430],
  [-39.7537, 176.7391],
  [-39.7567, 176.7339],
  [-39.7581, 176.7317],
  [-39.7586, 176.7311],
  [-39.7593, 176.7304],
  [-39.7602, 176.7290],
  [-39.7623, 176.7223],
  [-39.7625, 176.7212],
  [-39.7631, 176.7053],
  [-39.7635, 176.7026],
  [-39.7644, 176.7003],
  [-39.7660, 176.6975],
  [-39.7711, 176.6916],
  [-39.7731, 176.6900],
  [-39.7780, 176.6852],
  [-39.7790, 176.6846],
  [-39.7800, 176.6844],
  [-39.7810, 176.6840],
  [-39.7839, 176.6825],
  [-39.7848, 176.6818],
  [-39.7855, 176.6814],
  [-39.7866, 176.6811],
  [-39.7874, 176.6807],
  [-39.7892, 176.6794],
  [-39.7909, 176.6787],
  [-39.7944, 176.6765],
  [-39.7958, 176.6763],
  [-39.7969, 176.6766],
  [-39.7987, 176.6778],
  [-39.7994, 176.6780],
  [-39.8004, 176.6781],
  [-39.8012, 176.6777],
  [-39.8024, 176.6768],
  [-39.8030, 176.6763],
  [-39.8034, 176.6754]
];

const teAuteWaipawa = [
  [-39.8034, 176.6754],
  [-39.8039, 176.6736],
  [-39.8042, 176.6728],
  [-39.8054, 176.6718],
  [-39.8066, 176.6704],
  [-39.8074, 176.6700],
  [-39.8083, 176.6701],
  [-39.8089, 176.6699],
  [-39.8094, 176.6696],
  [-39.8102, 176.6686],
  [-39.8109, 176.6681],
  [-39.8114, 176.6676],
  [-39.8118, 176.6668],
  [-39.8123, 176.6662],
  [-39.8133, 176.6652],
  [-39.8140, 176.6642],
  [-39.8147, 176.6636],
  [-39.8164, 176.6624],
  [-39.8174, 176.6616],
  [-39.8182, 176.6606],
  [-39.8190, 176.6593],
  [-39.8195, 176.6588],
  [-39.8202, 176.6586],
  [-39.8220, 176.6587],
  [-39.8231, 176.6586],
  [-39.8240, 176.6583],
  [-39.8250, 176.6583],
  [-39.8257, 176.6579],
  [-39.8262, 176.6573],
  [-39.8271, 176.6549],
  [-39.8288, 176.6523],
  [-39.8291, 176.6516],
  [-39.8292, 176.6507],
  [-39.8288, 176.6471],
  [-39.8288, 176.6464],
  [-39.8290, 176.6458],
  [-39.8353, 176.6356],
  [-39.8369, 176.6337],
  [-39.8402, 176.6314],
  [-39.8408, 176.6312],
  [-39.8415, 176.6312],
  [-39.8460, 176.6322],
  [-39.8472, 176.6323],
  [-39.8583, 176.6318],
  [-39.8668, 176.6291],
  [-39.8707, 176.6283],
  [-39.8717, 176.6283],
  [-39.8728, 176.6289],
  [-39.8778, 176.6341],
  [-39.8790, 176.6349],
  [-39.8802, 176.6354],
  [-39.8815, 176.6354],
  [-39.8836, 176.6352],
  [-39.8855, 176.6347],
  [-39.8865, 176.6343],
  [-39.9034, 176.6226],
  [-39.9173, 176.6125],
  [-39.9236, 176.6088],
  [-39.9301, 176.6056],
  [-39.9308, 176.6054],
  [-39.9316, 176.6055],
  [-39.9326, 176.6052],
  [-39.9343, 176.6039],
  [-39.9360, 176.6030],
  [-39.9381, 176.6015],
  [-39.9386, 176.6007],
  [-39.9388, 176.5998],
  [-39.9389, 176.5958],
  [-39.9390, 176.5950],
  [-39.9396, 176.5931]
];

const waipawaWaipukurau = [
  [-39.9396, 176.5931],
  [-39.9403, 176.5914],
  [-39.9419, 176.5893],
  [-39.9428, 176.5885],
  [-39.9439, 176.5878],
  [-39.9448, 176.5875],
  [-39.9503, 176.5863],
  [-39.9511, 176.5863],
  [-39.9519, 176.5867],
  [-39.9534, 176.5882],
  [-39.9542, 176.5887],
  [-39.9550, 176.5886],
  [-39.9576, 176.5873],
  [-39.9587, 176.5860],
  [-39.9599, 176.5833],
  [-39.9613, 176.5817],
  [-39.9669, 176.5790],
  [-39.9714, 176.5756],
  [-39.9725, 176.5746],
  [-39.9783, 176.5673],
  [-39.9808, 176.5649],
  [-39.9818, 176.5642],
  [-39.9834, 176.5639],
  [-39.9846, 176.5630],
  [-39.9853, 176.5616],
  [-39.9859, 176.5575],
  [-39.9865, 176.5563],
  [-39.9874, 176.5555],
  [-39.9916, 176.5543],
  [-39.9925, 176.5538],
  [-39.9939, 176.5523]
];

const waipukurauTakapau = [
  [-39.9939, 176.5523],
  [-39.9956, 176.5505],
  [-39.9962, 176.5493],
  [-39.9971, 176.5451],
  [-39.9976, 176.5437],
  [-40.0069, 176.5251],
  [-40.0103, 176.5198],
  [-40.0113, 176.5186],
  [-40.0260, 176.5066],
  [-40.0279, 176.5056],
  [-40.0298, 176.5052],
  [-40.0307, 176.5046],
  [-40.0317, 176.5033],
  [-40.0350, 176.4973],
  [-40.0385, 176.4883],
  [-40.0387, 176.4862],
  [-40.0381, 176.4823],
  [-40.0381, 176.4806],
  [-40.0394, 176.4762],
  [-40.0395, 176.4749],
  [-40.0392, 176.4738],
  [-40.0378, 176.4715],
  [-40.0373, 176.4710],
  [-40.0362, 176.4705],
  [-40.0354, 176.4698],
  [-40.0350, 176.4687],
  [-40.0345, 176.4680],
  [-40.0332, 176.4669],
  [-40.0326, 176.4666],
  [-40.0305, 176.4660],
  [-40.0296, 176.4656],
  [-40.0266, 176.4629],
  [-40.0254, 176.4621],
  [-40.0243, 176.4616],
  [-40.0236, 176.4607],
  [-40.0232, 176.4596],
  [-40.0232, 176.4585],
  [-40.0234, 176.4573],
  [-40.0234, 176.4561],
  [-40.0231, 176.4552],
  [-40.0221, 176.4534],
  [-40.0214, 176.4506],
  [-40.0214, 176.4495],
  [-40.0217, 176.4485],
  [-40.0233, 176.4449],
  [-40.0237, 176.4426],
  [-40.0248, 176.3607],
  [-40.0251, 176.3590],
  [-40.0274, 176.3512],
  [-40.0282, 176.3499],
  [-40.0291, 176.3491],
  [-40.0306, 176.3481]
];

const takapauKopua = [
  [-40.0306, 176.3481],
  [-40.0399, 176.3423],
  [-40.0425, 176.3395],
  [-40.0439, 176.3387],
  [-40.0450, 176.3385],
  [-40.0461, 176.3379],
  [-40.0563, 176.3294],
  [-40.0575, 176.3275],
  [-40.0579, 176.3254],
  [-40.0569, 176.3187],
  [-40.0569, 176.3171],
  [-40.0574, 176.3106],
  [-40.0577, 176.3094],
  [-40.0591, 176.3062],
  [-40.0597, 176.3054],
  [-40.0606, 176.3052],
  [-40.0617, 176.3054],
  [-40.0632, 176.3050],
  [-40.0684, 176.3052],
  [-40.0698, 176.3047],
  [-40.0795, 176.2969],
  [-40.0816, 176.2942],
  [-40.0822, 176.2924],
  [-40.0823, 176.2908],
  [-40.0818, 176.2880],
  [-40.0820, 176.2868],
  [-40.0828, 176.2858],
  [-40.0835, 176.2855],
  [-40.0863, 176.2854]
];

const kopuaMakotuku = [
  [-40.0863, 176.2854],
  [-40.0870, 176.2854],
  [-40.0881, 176.2852],
  [-40.0934, 176.2832],
  [-40.0940, 176.2830],
  [-40.0965, 176.2814],
  [-40.0973, 176.2813],
  [-40.0986, 176.2817],
  [-40.0994, 176.2818],
  [-40.1002, 176.2813],
  [-40.1025, 176.2793],
  [-40.1035, 176.2791],
  [-40.1043, 176.2796],
  [-40.1054, 176.2811],
  [-40.1062, 176.2818],
  [-40.1074, 176.2822],
  [-40.1086, 176.2818],
  [-40.1095, 176.2810],
  [-40.1105, 176.2793],
  [-40.1107, 176.2782],
  [-40.1103, 176.2768],
  [-40.1085, 176.2746],
  [-40.1082, 176.2738],
  [-40.1083, 176.2730],
  [-40.1087, 176.2723],
  [-40.1103, 176.2705],
  [-40.1110, 176.2695],
  [-40.1113, 176.2686],
  [-40.1114, 176.2674],
  [-40.1106, 176.2622],
  [-40.1105, 176.2616],
  [-40.1107, 176.2607],
  [-40.1112, 176.2600],
  [-40.1115, 176.2595],
  [-40.1117, 176.2587],
  [-40.1117, 176.2574],
  [-40.1124, 176.2547],
  [-40.1135, 176.2529],
  [-40.1138, 176.2517],
  [-40.1151, 176.2395],
  [-40.1156, 176.2382],
  [-40.1202, 176.2315]
];

const makotukuMatamau = [
  [-40.1202, 176.2315],
  [-40.1204, 176.2313],
  [-40.1211, 176.2306],
  [-40.1218, 176.2303],
  [-40.1231, 176.2304],
  [-40.1245, 176.2297],
  [-40.1294, 176.2237],
  [-40.1300, 176.2224],
  [-40.1302, 176.2208],
  [-40.1300, 176.2194],
  [-40.1300, 176.2179],
  [-40.1306, 176.2160],
  [-40.1345, 176.2096],
  [-40.1354, 176.2087],
  [-40.1364, 176.2082],
  [-40.1370, 176.2074],
  [-40.1372, 176.2061],
  [-40.1365, 176.2031],
  [-40.1349, 176.1907],
  [-40.1353, 176.1883],
  [-40.1390, 176.1800],
  [-40.1393, 176.1781],
  [-40.1388, 176.1748],
  [-40.1391, 176.1730],
  [-40.1401, 176.1716],
  [-40.1410, 176.1710],
  [-40.1416, 176.1702],
  [-40.1421, 176.1679]
];

const matamauDanneverke = [
  [-40.1421, 176.1679],
  [-40.1423, 176.1666],
  [-40.1429, 176.1656],
  [-40.1451, 176.1640],
  [-40.1461, 176.1639],
  [-40.1470, 176.1644],
  [-40.1478, 176.1652],
  [-40.1506, 176.1659],
  [-40.1518, 176.1654],
  [-40.1527, 176.1644],
  [-40.1541, 176.1613],
  [-40.1591, 176.1547],
  [-40.1609, 176.1532],
  [-40.1644, 176.1514],
  [-40.1667, 176.1494],
  [-40.1706, 176.1438],
  [-40.1710, 176.1427],
  [-40.1710, 176.1413],
  [-40.1713, 176.1402],
  [-40.1733, 176.1365],
  [-40.1773, 176.1247],
  [-40.1792, 176.1207],
  [-40.1803, 176.1193],
  [-40.1818, 176.1184],
  [-40.1840, 176.1174],
  [-40.2035, 176.1024],
  [-40.2059, 176.0998],
  [-40.2083, 176.0964]
];

const danneverkeWoodville = [
  [-40.2083, 176.0964],
  [-40.2247, 176.0736],
  [-40.2257, 176.0715],
  [-40.2278, 176.0662],
  [-40.2302, 176.0622],
  [-40.2488, 176.0391],
  [-40.2735, 176.0125],
  [-40.2794, 176.0041],
  [-40.2804, 176.0020],
  [-40.2810, 175.9994],
  [-40.2815, 175.9846],
  [-40.2818, 175.9827],
  [-40.2825, 175.9793],
  [-40.2825, 175.9769],
  [-40.2817, 175.9731],
  [-40.2816, 175.9716],
  [-40.2820, 175.9704],
  [-40.2827, 175.9694],
  [-40.2879, 175.9645],
  [-40.2884, 175.9637],
  [-40.2886, 175.9629],
  [-40.2887, 175.9611],
  [-40.2896, 175.9579],
  [-40.2897, 175.9569],
  [-40.2895, 175.9558],
  [-40.2897, 175.9549],
  [-40.2901, 175.9540],
  [-40.2912, 175.9529],
  [-40.2917, 175.9519],
  [-40.2920, 175.9512],
  [-40.2925, 175.9506],
  [-40.2935, 175.9501],
  [-40.2942, 175.9493],
  [-40.2947, 175.9483],
  [-40.2956, 175.9474],
  [-40.2967, 175.9469],
  [-40.2975, 175.9462],
  [-40.3024, 175.9387],
  [-40.3031, 175.9382],
  [-40.3037, 175.9381],
  [-40.3046, 175.9382],
  [-40.3055, 175.9381],
  [-40.3065, 175.9375],
  [-40.3075, 175.9373],
  [-40.3090, 175.9373],
  [-40.3101, 175.9371],
  [-40.3110, 175.9365],
  [-40.3183, 175.9284],
  [-40.3339, 175.9054],
  [-40.3430, 175.8919],
  [-40.3437, 175.8900],
  [-40.3460, 175.8736],
  [-40.3460, 175.8729],
  [-40.3458, 175.8714]
];

const woodvilleTunnelNo5 = [
  [-40.3458, 175.8714],
  [-40.3441, 175.8628],
  [-40.3431, 175.8594],
  [-40.3415, 175.8562],
  [-40.3367, 175.8503],
  [-40.3359, 175.8484],
  [-40.3345, 175.8415],
  [-40.3345, 175.8398],
  [-40.3349, 175.8377],
  [-40.3357, 175.8354],
  [-40.3367, 175.8318],
  [-40.3385, 175.8272],
  [-40.3387, 175.8262]
];

const tunnelNo5 = [
  [-40.3387, 175.8262],
  [-40.3386, 175.8253]
];

const tunnelNo5TunnelNo4 = [
  [-40.3386, 175.8253],
  [-40.3386, 175.8253],
  [-40.3380, 175.8231],
  [-40.3378, 175.8213],
  [-40.3377, 175.8208]
];

const tunnelNo4 = [
  [-40.3377, 175.8208],
  [-40.3375, 175.8200],
  [-40.3372, 175.8195]
];

const tunnelNo4TunnelNo3 = [
  [-40.3372, 175.8195],
  [-40.3370, 175.8193],
  [-40.3347, 175.8183],
  [-40.3334, 175.8182],
  [-40.3294, 175.8196],
  [-40.3289, 175.8197],
  [-40.3285, 175.8196],
  [-40.3277, 175.8198],
  [-40.3274, 175.8197],
  [-40.3260, 175.8189],
  [-40.3253, 175.8181],
  [-40.3236, 175.8173]
];

const tunnelNo3 = [
  [-40.3236, 175.8173],
  [-40.3226, 175.8168]
];

const tunnelNo3TunnelNo2 = [
  [-40.3226, 175.8168],
  [-40.3217, 175.8163],
  [-40.3206, 175.8161],
  [-40.3199, 175.8162],
  [-40.3191, 175.8164],
  [-40.3186, 175.8164],
  [-40.3172, 175.8162],
  [-40.3167, 175.8160],
  [-40.3160, 175.8149],
  [-40.3158, 175.8141],
  [-40.3158, 175.8132],
  [-40.3157, 175.8124],
  [-40.3157, 175.8119],
  [-40.3153, 175.8108],
  [-40.3151, 175.8094],
  [-40.3151, 175.8090],
  [-40.3154, 175.8075],
  [-40.3159, 175.8048],
  [-40.3160, 175.8044],
  [-40.3165, 175.8036],
  [-40.3168, 175.8028],
  [-40.3171, 175.8014],
  [-40.3172, 175.7998],
  [-40.3175, 175.7984],
  [-40.3175, 175.7971],
  [-40.3173, 175.7962],
  [-40.3172, 175.7956],
  [-40.3171, 175.7952],
  [-40.3166, 175.7944],
  [-40.3163, 175.7941],
  [-40.3152, 175.7932],
  [-40.3138, 175.7925],
  [-40.3136, 175.7923]
];

const tunnelNo2 = [
  [-40.3136, 175.7923],
  [-40.3130, 175.7914],
  [-40.3121, 175.7880]
];

const tunnelNo2TunnelNo1 = [
  [-40.3121, 175.7880],
  [-40.3119, 175.7873],
  [-40.3116, 175.7855],
  [-40.3113, 175.7845]
];

const tunnelNo1 = [
  [-40.3113, 175.7845],
  [-40.3093, 175.7796],
  [-40.3093, 175.7786]
];

const tunnelNo1PalmerstonNorth = [
  [-40.3093, 175.7786],
  [-40.3089, 175.7772],
  [-40.3082, 175.7762],
  [-40.3050, 175.7718],
  [-40.3042, 175.7712],
  [-40.3039, 175.7706],
  [-40.3028, 175.7688],
  [-40.3024, 175.7684],
  [-40.3018, 175.7683],
  [-40.3010, 175.7685],
  [-40.3005, 175.7684],
  [-40.3002, 175.7683],
  [-40.2995, 175.7677],
  [-40.2987, 175.7674],
  [-40.2983, 175.7670],
  [-40.2980, 175.7664],
  [-40.2952, 175.7560],
  [-40.2952, 175.7551],
  [-40.2954, 175.7543],
  [-40.2982, 175.7480],
  [-40.2985, 175.7470],
  [-40.3006, 175.7347],
  [-40.3012, 175.7329],
  [-40.3064, 175.7214],
  [-40.3086, 175.7185],
  [-40.3100, 175.7155],
  [-40.3105, 175.7140],
  [-40.3114, 175.7122],
  [-40.3117, 175.7111],
  [-40.3123, 175.7053],
  [-40.3126, 175.7036],
  [-40.3145, 175.6977],
  [-40.3151, 175.6965],
  [-40.3177, 175.6928],
  [-40.3184, 175.6911],
  [-40.3210, 175.6866],
  [-40.3214, 175.6853],
  [-40.3246, 175.6712],
  [-40.3253, 175.6696],
  [-40.3286, 175.6648],
  [-40.3371, 175.6475],
  [-40.3375, 175.6459],
  [-40.3375, 175.6444],
  [-40.3371, 175.6430],
  [-40.3361, 175.6413]
];

export const palmerstonNorthGisborne: Line = {
  name: 'Palmerston North - Gisborne',
  state: 'NZ',
  segments: [
    {
      segments: [gisbornePortGisborne],
      history: {
        opened: {
          date: '1902-11-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gisborneMoutohoraJunction],
      history: {
        opened: {
          date: '1902-11-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [moutohoraJunctionMuriwai],
      history: {
        opened: {
          date: '1942-08-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        muriwaiTunnelNo26,
        {
          name: 'Tunnel No 26',
          segment: tunnelNo26,
          type: 'tunnel'
        },
        tunnelNo26Tunnelno25,
        {
          name: 'Tunnel No 25',
          segment: tunnelNo25,
          type: 'tunnel'
        },
        tunnel24Tunnel25,
        {
          date: '1956-01-01',
          original: [{
            name: 'Tunnel No 24',
            segment: tunnelNo24,
            type: 'tunnel'
          }],
          deviation: [tunnelNo24],
        },
        tunnel23Tunnel24,
        {
          name: 'Tunnel No 23',
          segment: tunnelNo23,
          type: 'tunnel'
        },
        tunnel22Tunnel23,
        {
          name: 'Tunnel No 22',
          segment: tunnelNo22,
          type: 'tunnel'
        },
        tunnel21Tunnel22,
        {
          name: 'Tunnel No 21',
          segment: tunnelNo21,
          type: 'tunnel'
        },
        tunnel20Tunnel21,
        {
          name: 'Tunnel No 20',
          segment: tunnelNo20,
          type: 'tunnel'
        },
        tikiwhataTunnelTunnel20,
        {
          name: 'Tikiwhata Tunnel',
          segment: tikiwhataTunnel,
          type: 'tunnel'
        },
        tunnel18TikiwhataTunnel,
        {
          name: 'Tunnel No 18',
          segment: tunnel18,
          type: 'tunnel'
        },
        tunnel17Tunnel18,
        {
          name: 'Tunnel No 17',
          segment: tunnelNo17,
          type: 'tunnel'
        },
        tunnel16Tunnel17,
        {
          name: 'Tunnel No 16',
          segment: tunnelNo16,
          type: 'tunnel'
        },
        tunnel15Tunnel16,
        {
          name: 'Tunnel No 15',
          segment: tunnelNo15,
          type: 'tunnel'
        },
        tunnel14Tunnel15,
        {
          name: 'Tunnel No 14',
          segment: tunnelNo14,
          type: 'tunnel'
        },
        tunnelNo14Waikokopu
      ],
      history: {
        opened: {
          date: '1942-08-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waikokopuPort],
      history: {
        opened: {
          date: '1924-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-08-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waikokopuWairoa],
      history: {
        opened: {
          date: '1924-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wairoaFreezingWorks],
      history: {
        opened: {
          date: '1924-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [freezingWorksWestJunction],
      history: {
        opened: {
          date: '1937-08-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        wairoaTunnelNo13,
        {
          name: 'tunnel No 13',
          segment: tunnelNo13,
          type: 'tunnel'
        },
        tunnel13Tunnel12,
        {
          name: 'tunnel No 12',
          segment: tunnelNo12,
          type: 'tunnel'
        },
        tunnel12Tunnel11,
        {
          name: 'tunnel No 11',
          segment: tunnelNo11,
          type: 'tunnel'
        },
        tunnel11Tunnel10,
        {
          name: 'tunnel No 10',
          segment: tunnelNo10,
          type: 'tunnel'
        },
        tunnel10Tunnel9,
        {
          name: 'tunnel No 9',
          segment: tunnelNo9,
          type: 'tunnel'
        },
        tunnelNo9Putorino
      ],
      history: {
        opened: {
          date: '1937-08-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-12-01',
          status: 'closed'
        }, {
          date: '2018-06-06',
          status: 'open'
        }]
      }
    },
    {
      segments: [
        putorinoTunnelNo8,
        {
          name: 'Tunnel No 8',
          segment: tunnelNo8,
          type: 'tunnel'
        },
        tunnel7Tunnel8,
        {
          name: 'Tunnel No 7',
          segment: tunnelNo7,
          type: 'tunnel'
        },
        tunnel6Tunnel7,
        {
          name: 'Tunnel No 6',
          segment: tunnelNo6,
          type: 'tunnel'
        },
        tunnelNo6Eskdale
      ],
      history: {
        opened: {
          date: '1930-10-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-02-01',
          status: 'closed'
        },{
          date: '2012-12-01',
          status: 'closed'
        }, {
          date: '1936-10-17',
          status: 'open'
        },{
          date: '2018-06-06',
          status: 'open'
        }]
      }
    },
    {
      segments: [eskdalePortJunction],
      history: {
        opened: {
          date: '1922-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-02-01',
          status: 'closed'
        },{
          date: '2012-12-01',
          status: 'closed'
        }, {
          date: '1936-10-17',
          status: 'open'
        },{
          date: '2018-06-06',
          status: 'open'
        }]
      }
    },
    {
      segments: [portJunctionNapier],
      history: {
        opened: {
          date: '1874-11-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [napierHastings],
      history: {
        opened: {
          date: '1874-10-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hastingsPakipaki],
      history: {
        opened: {
          date: '1875-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pakipakiTeAute],
      history: {
        opened: {
          date: '1876-02-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [teAuteWaipawa],
      history: {
        opened: {
          date: '1876-08-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waipawaWaipukurau],
      history: {
        opened: {
          date: '1876-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waipukurauTakapau],
      history: {
        opened: {
          date: '1877-03-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [takapauKopua],
      history: {
        opened: {
          date: '1878-01-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kopuaMakotuku],
      history: {
        opened: {
          date: '1880-08-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [makotukuMatamau],
      history: {
        opened: {
          date: '1884-06-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [matamauDanneverke],
      history: {
        opened: {
          date: '1884-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [danneverkeWoodville],
      history: {
        opened: {
          date: '1887-03-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        woodvilleTunnelNo5,
        {
          date: '2008-01-01',
          original: [{
            name: 'Tunnel No 5',
            segment: tunnelNo5,
            type: 'tunnel'
          }],
          deviation: [tunnelNo5]
        },
        tunnelNo5TunnelNo4,
        {
          date: '2008-01-01',
          original: [{
            name: 'Tunnel No 4',
            segment: tunnelNo4,
            type: 'tunnel'
          }],
          deviation: [tunnelNo4]
        },
        tunnelNo4TunnelNo3,
        {
          date: '2008-01-01',
          original: [{
            name: 'Tunnel No 3',
            segment: tunnelNo3,
            type: 'tunnel'
          }],
          deviation: [tunnelNo3]
        },
        tunnelNo3TunnelNo2,
        {
          name: 'Tunnel No 2',
          segment: tunnelNo2,
          type: 'tunnel'
        },
        tunnelNo2TunnelNo1,
        {
          name: 'Tunnel No 1',
          segment: tunnelNo1,
          type: 'tunnel'
        },
        tunnelNo1PalmerstonNorth
      ],
      history: {
        opened: {
          date: '1891-03-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
