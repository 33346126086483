import { Line } from "../../trackTypes";

const line = [
  [-34.7503, 149.7313],
  [-34.7495, 149.7325],
  [-34.7491, 149.7328],
  [-34.7472, 149.7342],
  [-34.7443, 149.7364],
  [-34.7435, 149.7373],
  [-34.7428, 149.7381],
  [-34.7420, 149.7386],
  [-34.7386, 149.7394],
  [-34.7379, 149.7393],
  [-34.7371, 149.7390],
  [-34.7352, 149.7376],
  [-34.7345, 149.7372],
  [-34.7338, 149.7372],
  [-34.7320, 149.7377],
  [-34.7314, 149.7380],
  [-34.7309, 149.7386],
  [-34.7266, 149.7440],
  [-34.7257, 149.7446],
  [-34.7246, 149.7450],
  [-34.7229, 149.7451],
  [-34.7199, 149.7451],
  [-34.7199, 149.7451],
  [-34.7199, 149.7451],
  [-34.7084, 149.7401],
  [-34.7052, 149.7384],
  [-34.7029, 149.7379],
  [-34.7000, 149.7373],
  [-34.6973, 149.7364],
  [-34.6963, 149.7354],
  [-34.6941, 149.7325],
  [-34.6930, 149.7316],
  [-34.6904, 149.7310],
  [-34.6893, 149.7309],
  [-34.6815, 149.7323],
  [-34.6799, 149.7332],
  [-34.6791, 149.7343],
  [-34.6781, 149.7360],
  [-34.6771, 149.7367],
  [-34.6759, 149.7366],
  [-34.6737, 149.7359],
  [-34.6724, 149.7358],
  [-34.6667, 149.7362],
  [-34.6657, 149.7368],
  [-34.6652, 149.7376],
  [-34.6649, 149.7387],
  [-34.6643, 149.7396],
  [-34.6633, 149.7399],
  [-34.6608, 149.7398],
  [-34.6591, 149.7403],
  [-34.6578, 149.7411],
  [-34.6566, 149.7411],
  [-34.6557, 149.7408],
  [-34.6547, 149.7398],
  [-34.6536, 149.7394],
  [-34.6517, 149.7389],
  [-34.6505, 149.7391],
  [-34.6495, 149.7398],
  [-34.6484, 149.7401],
  [-34.6435, 149.7396],
  [-34.6429, 149.7393],
  [-34.6418, 149.7382],
  [-34.6408, 149.7378],
  [-34.6397, 149.7378],
  [-34.6388, 149.7374],
  [-34.6382, 149.7366],
  [-34.6376, 149.7356],
  [-34.6369, 149.7347],
  [-34.6354, 149.7341],
  [-34.6344, 149.7339],
  [-34.6337, 149.7341],
  [-34.6325, 149.7350],
  [-34.6316, 149.7351],
  [-34.6309, 149.7350],
  [-34.6286, 149.7328],
  [-34.6277, 149.7323],
  [-34.6267, 149.7322],
  [-34.6171, 149.7324],
  [-34.6161, 149.7319],
  [-34.6153, 149.7309],
  [-34.6151, 149.7298],
  [-34.6153, 149.7287],
  [-34.6158, 149.7274],
  [-34.6159, 149.7265],
  [-34.6157, 149.7254],
  [-34.6145, 149.7226],
  [-34.6143, 149.7217],
  [-34.6146, 149.7205],
  [-34.6150, 149.7190],
  [-34.6151, 149.7179],
  [-34.6148, 149.7169],
  [-34.6137, 149.7154],
  [-34.6118, 149.7141],
  [-34.6107, 149.7127],
  [-34.6102, 149.7116],
  [-34.6098, 149.7098],
  [-34.6092, 149.7087],
  [-34.6082, 149.7080],
  [-34.6072, 149.7081],
  [-34.6060, 149.7086],
  [-34.6050, 149.7087],
  [-34.6034, 149.7087],
  [-34.6011, 149.7093],
  [-34.6003, 149.7092],
  [-34.5994, 149.7085],
  [-34.5984, 149.7073],
  [-34.5976, 149.7069],
  [-34.5968, 149.7066],
  [-34.5961, 149.7060],
  [-34.5957, 149.7053],
  [-34.5956, 149.7030],
  [-34.5951, 149.7021],
  [-34.5941, 149.7012],
  [-34.5937, 149.7003],
  [-34.5937, 149.6990],
  [-34.5940, 149.6976],
  [-34.5940, 149.6965],
  [-34.5937, 149.6955],
  [-34.5938, 149.6941],
  [-34.5949, 149.6909],
  [-34.5949, 149.6894],
  [-34.5943, 149.6883],
  [-34.5933, 149.6875],
  [-34.5926, 149.6866],
  [-34.5923, 149.6855],
  [-34.5925, 149.6843],
  [-34.5932, 149.6824],
  [-34.5934, 149.6801],
  [-34.5941, 149.6786],
  [-34.5943, 149.6775],
  [-34.5939, 149.6764],
  [-34.5911, 149.6731],
  [-34.5902, 149.6713],
  [-34.5864, 149.6610],
  [-34.5859, 149.6602],
  [-34.5829, 149.6570],
  [-34.5819, 149.6551],
  [-34.5804, 149.6514],
  [-34.5802, 149.6498],
  [-34.5805, 149.6475],
  [-34.5802, 149.6458],
  [-34.5792, 149.6441],
  [-34.5789, 149.6429],
  [-34.5789, 149.6415],
  [-34.5784, 149.6401],
  [-34.5777, 149.6394],
  [-34.5744, 149.6375],
  [-34.5737, 149.6366],
  [-34.5719, 149.6315],
  [-34.5714, 149.6308],
  [-34.5704, 149.6304],
  [-34.5664, 149.6304],
  [-34.5656, 149.6299],
  [-34.5650, 149.6292],
  [-34.5648, 149.6283],
  [-34.5649, 149.6264],
  [-34.5644, 149.6252],
  [-34.5635, 149.6245],
  [-34.5626, 149.6245],
  [-34.5613, 149.6252],
  [-34.5606, 149.6253],
  [-34.5588, 149.6249],
  [-34.5579, 149.6242],
  [-34.5573, 149.6232],
  [-34.5561, 149.6191],
  [-34.5556, 149.6184],
  [-34.5548, 149.6180],
  [-34.5509, 149.6169],
  [-34.5499, 149.6169],
  [-34.5457, 149.6175],
  [-34.5447, 149.6172],
  [-34.5440, 149.6164],
  [-34.5435, 149.6150],
  [-34.5428, 149.6141],
  [-34.5395, 149.6124],
  [-34.5388, 149.6124],
  [-34.5381, 149.6126],
  [-34.5371, 149.6132],
  [-34.5360, 149.6132],
  [-34.5351, 149.6127],
  [-34.5338, 149.6113],
  [-34.5330, 149.6110],
  [-34.5323, 149.6109],
  [-34.5290, 149.6112],
  [-34.5279, 149.6120],
  [-34.5270, 149.6137],
  [-34.5259, 149.6151],
  [-34.5219, 149.6192],
  [-34.5206, 149.6197],
  [-34.5194, 149.6194],
  [-34.5184, 149.6188],
  [-34.5177, 149.6176],
  [-34.5167, 149.6144],
  [-34.5158, 149.6134],
  [-34.5145, 149.6131],
  [-34.5135, 149.6135],
  [-34.5124, 149.6145],
  [-34.5114, 149.6148],
  [-34.5103, 149.6145],
  [-34.5088, 149.6130],
  [-34.5078, 149.6126],
  [-34.5038, 149.6124],
  [-34.5030, 149.6120],
  [-34.5023, 149.6114],
  [-34.4979, 149.6050],
  [-34.4968, 149.6042],
  [-34.4959, 149.6040],
  [-34.4950, 149.6042],
  [-34.4919, 149.6046],
  [-34.4848, 149.6041],
  [-34.4827, 149.6035],
  [-34.4762, 149.5999],
  [-34.4698, 149.5981],
  [-34.4640, 149.5951],
  [-34.4614, 149.5942],
  [-34.4603, 149.5935],
  [-34.4595, 149.5923],
  [-34.4583, 149.5904],
  [-34.4573, 149.5895],
  [-34.4560, 149.5889],
  [-34.4546, 149.5875],
  [-34.4538, 149.5861],
  [-34.4530, 149.5853],
  [-34.4516, 149.5845],
  [-34.4487, 149.5839],
  [-34.4479, 149.5835],
  [-34.4462, 149.5819],
  [-34.4457, 149.5810],
  [-34.4446, 149.5767],
  [-34.4433, 149.5744],
  [-34.4431, 149.5734],
  [-34.4432, 149.5725],
  [-34.4436, 149.5712],
  [-34.4437, 149.5703],
  [-34.4435, 149.5694],
  [-34.4404, 149.5632],
  [-34.4391, 149.5590],
  [-34.4390, 149.5553],
  [-34.4397, 149.5508],
  [-34.4402, 149.5496],
  [-34.4417, 149.5464],
  [-34.4431, 149.5449],
  [-34.4448, 149.5417],
  [-34.4450, 149.5408],
  [-34.4451, 149.5395],
  [-34.4457, 149.5381],
  [-34.4471, 149.5363],
  [-34.4491, 149.5312],
  [-34.4494, 149.5294],
  [-34.4495, 149.5243],
  [-34.4498, 149.5231],
  [-34.4508, 149.5209],
  [-34.4558, 149.5127],
  [-34.4564, 149.5113],
  [-34.4593, 149.5006],
  [-34.4626, 149.4921],
  [-34.4628, 149.4909],
  [-34.4626, 149.4898],
  [-34.4623, 149.4886],
  [-34.4626, 149.4871],
  [-34.4630, 149.4847],
  [-34.4631, 149.4830],
  [-34.4628, 149.4817],
  [-34.4620, 149.4799],
  [-34.4618, 149.4784],
  [-34.4619, 149.4768],
  [-34.4611, 149.4754],
  [-34.4601, 149.4751],
  [-34.4559, 149.4759]
];

export const crookwell: Line = {
  name: 'Crookwell',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1902-04-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-09-18',
          status: 'closed'
        }]
      }
    }
  ]
};
