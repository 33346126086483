import { Line } from "../../trackTypes";

const monartoSouthApamurra = [
  [-35.1231, 139.1295],
  [-35.1224, 139.1310],
  [-35.1215, 139.1320],
  [-35.1203, 139.1328],
  [-35.1171, 139.1335],
  [-35.1041, 139.1346],
  [-35.1029, 139.1349],
  [-35.0934, 139.1395],
  [-35.0796, 139.1494],
  [-35.0736, 139.1555],
  [-35.0727, 139.1572],
  [-35.0705, 139.1654],
  [-35.0680, 139.1702],
  [-35.0673, 139.1720],
  [-35.0658, 139.1799],
  [-35.0632, 139.1881],
  [-35.0599, 139.1947],
  [-35.0564, 139.2009],
  [-35.0506, 139.2064],
  [-35.0498, 139.2068],
  [-35.0489, 139.2070],
  [-35.0337, 139.2058],
  [-35.0329, 139.2055],
  [-35.0184, 139.1947],
  [-35.0110, 139.1906],
  [-34.9912, 139.1833],
  [-34.9896, 139.1832],
  [-34.9514, 139.1891],
  [-34.9495, 139.1891],
  [-34.9231, 139.1811],
  [-34.9221, 139.1809],
  [-34.9195, 139.1808],
  [-34.9177, 139.1801],
  [-34.9156, 139.1792],
  [-34.9146, 139.1791],
  [-34.9121, 139.1795],
  [-34.9109, 139.1799],
  [-34.9042, 139.1843],
  [-34.9029, 139.1845],
  [-34.9018, 139.1842],
  [-34.9002, 139.1834],
  [-34.8977, 139.1824],
  [-34.8895, 139.1810],
  [-34.8875, 139.1814],
  [-34.8859, 139.1824],
  [-34.8760, 139.1921],
  [-34.8738, 139.1932],
  [-34.8584, 139.1955]
];

const apamurraCambrai = [
  [-34.8584, 139.1955],
  [-34.8529, 139.1962],
  [-34.8405, 139.1959],
  [-34.8387, 139.1964],
  [-34.8371, 139.1974],
  [-34.8299, 139.2034],
  [-34.8276, 139.2045],
  [-34.8146, 139.2064],
  [-34.8138, 139.2067],
  [-34.8114, 139.2081],
  [-34.8039, 139.2151],
  [-34.8018, 139.2161],
  [-34.7913, 139.2178],
  [-34.7762, 139.2179],
  [-34.7681, 139.2171],
  [-34.7665, 139.2173],
  [-34.7500, 139.2234],
  [-34.7491, 139.2241],
  [-34.7426, 139.2357],
  [-34.7404, 139.2378],
  [-34.7368, 139.2399],
  [-34.7345, 139.2420],
  [-34.7317, 139.2453],
  [-34.7309, 139.2459],
  [-34.7301, 139.2462],
  [-34.7178, 139.2490],
  [-34.6902, 139.2510],
  [-34.6885, 139.2517],
  [-34.6874, 139.2529],
  [-34.6866, 139.2540],
  [-34.6794, 139.2694],
  [-34.6781, 139.2706],
  [-34.6716, 139.2740],
  [-34.6705, 139.2753],
  [-34.6700, 139.2768],
  [-34.6695, 139.2798],
  [-34.6691, 139.2812],
  [-34.6686, 139.2819],
  [-34.6660, 139.2836],
  [-34.6545, 139.2864]
];

const cambraiSedan = [
  [-34.6545, 139.2864],
  [-34.6513, 139.2872],
  [-34.6502, 139.2878],
  [-34.6266, 139.3105],
  [-34.6224, 139.3121],
  [-34.6213, 139.3122],
  [-34.6200, 139.3120],
  [-34.6061, 139.3046],
  [-34.6043, 139.3042],
  [-34.5864, 139.3040],
  [-34.5851, 139.3036],
  [-34.5799, 139.3012],
  [-34.5783, 139.3010],
  [-34.5731, 139.3026]
];

export const sedan: Line = {
  name: 'Sedan',
  state: 'SA',
  segments: [
    {
      segments: [monartoSouthApamurra],
      history: {
        opened: {
          date: '1919-10-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-11-20',
          gauge: 'standard'
        }, {
          date: '2005-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [apamurraCambrai],
      history: {
        opened: {
          date: '1919-10-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cambraiSedan],
      history: {
        opened: {
          date: '1919-10-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-06-01',
          status: 'closed'
        }]
      }
    }
  ]
};
