import { Line } from "../../trackTypes";

const line = [
  [-37.8885, 145.0036],
  [-37.8904, 145.0051],
  [-37.8914, 145.0063],
  [-37.8922, 145.0080],
  [-37.8931, 145.0135],
  [-37.8934, 145.0146],
  [-37.8944, 145.0167],
  [-37.8948, 145.0176],
  [-37.8983, 145.0480],
  [-37.8978, 145.0507],
  [-37.9000, 145.0692],
  [-37.9000, 145.0713],
  [-37.8995, 145.0737],
  [-37.8986, 145.0757],
  [-37.8971, 145.0778],
  [-37.8965, 145.0790],
  [-37.8964, 145.0797],
  [-37.8963, 145.0805],
  [-37.8964, 145.08115]
];

export const rosstown: Line = {
  name: 'Rosstown',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1888-11-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1916-12-28',
          status: 'closed'
        }]
      }
    }
  ]
}
