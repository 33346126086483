import { Line } from "../../trackTypes";

const line = [
  [-28.0548, 117.8467],
  [-28.0536, 117.8475],
  [-28.0528, 117.8488],
  [-28.0525, 117.8500],
  [-28.0530, 117.8629],
  [-28.0535, 117.8646],
  [-28.0579, 117.8732],
  [-28.0786, 117.8955],
  [-28.0953, 117.9194],
  [-28.1075, 117.9513],
  [-28.1075, 117.9527],
  [-28.1064, 117.9559],
  [-28.0946, 117.9789],
  [-28.0898, 117.9948],
  [-28.0840, 118.0706],
  [-28.0828, 118.0741],
  [-28.0660, 118.0899],
  [-28.0645, 118.0929],
  [-28.0568, 118.1258],
  [-28.0479, 118.2263],
  [-28.0485, 118.2356],
  [-28.0486, 118.2400],
  [-28.0497, 118.2457],
  [-28.0551, 118.2912],
  [-28.0550, 118.2934],
  [-28.0446, 118.3448],
  [-28.0422, 118.4811],
  [-28.0414, 118.4848],
  [-28.0127, 118.5827],
  [-28.0118, 118.5863],
  [-28.0084, 118.9126],
  [-28.0025, 118.9388],
  [-28.0004, 118.9433],
  [-27.9998, 118.9458],
  [-28.0003, 118.9478],
  [-28.0060, 118.9577],
  [-28.0064, 118.9595],
  [-28.0060, 118.9829],
  [-28.0066, 118.9857],
  [-28.0141, 118.9948],
  [-28.0149, 118.9968],
  [-28.0136, 119.1078],
  [-28.0126, 119.1102],
  [-28.0008, 119.1308],
  [-28.0000, 119.1332],
  [-27.9992, 119.2032],
  [-27.9981, 119.2097],
  [-27.9984, 119.2130],
  [-28.0002, 119.2190],
  [-28.0005, 119.2221],
  [-28.0021, 119.2250],
  [-28.0026, 119.2268],
  [-28.0040, 119.2429],
  [-28.0036, 119.2455],
  [-27.9982, 119.2577],
  [-27.9959, 119.2671],
  [-27.9958, 119.2720],
  [-27.9964, 119.2745],
  [-27.9963, 119.2784],
  [-27.9953, 119.2809],
  [-27.9892, 119.2859],
  [-27.9898, 119.2862],
  [-27.9893, 119.2894],
  [-27.9899, 119.2931],
  [-27.9903, 119.3018]
];

export const sandstone: Line = {
  name: 'Sandstone',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    },
  ]
};
