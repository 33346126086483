import { Line } from "../../../trackTypes";

const otiriaKawakawa = [
  [-35.3983, 174.0043],
  [-35.3802, 174.0367],
  [-35.3794, 174.0388],
  [-35.3792, 174.0406],
  [-35.3812, 174.0606],
  [-35.3811, 174.0614],
  [-35.3807, 174.0624],
  [-35.3803, 174.0635]
];

const kawakawaTaumarere = [
  [-35.3803, 174.0635],
  [-35.3801, 174.0640],
  [-35.3799, 174.0652],
  [-35.3800, 174.0660],
  [-35.3801, 174.0674],
  [-35.3800, 174.0680],
  [-35.3798, 174.0685],
  [-35.3791, 174.0692],
  [-35.3767, 174.0711],
  [-35.3762, 174.0717],
  [-35.3760, 174.0724],
  [-35.3759, 174.0736],
  [-35.3759, 174.0753],
  [-35.3757, 174.0762],
  [-35.3753, 174.0768],
  [-35.3747, 174.0772],
  [-35.3742, 174.0778],
  [-35.3738, 174.0788],
  [-35.3737, 174.0798],
  [-35.3738, 174.0807],
  [-35.3743, 174.0823],
  [-35.3744, 174.0830],
  [-35.3741, 174.0839],
  [-35.3736, 174.0845],
  [-35.3730, 174.0849],
  [-35.3724, 174.0849],
  [-35.3719, 174.0852],
  [-35.3715, 174.0857],
  [-35.3710, 174.0864],
  [-35.3705, 174.0867],
  [-35.3697, 174.0868],
  [-35.3683, 174.0864],
  [-35.3671, 174.0864],
  [-35.3662, 174.0869],
  [-35.3643, 174.0880],
  [-35.3627, 174.0896],
  [-35.3617, 174.0914],
  [-35.3607, 174.0941]
];

const taumarereTeAkeake = [
  [-35.3607, 174.0941],
  [-35.3600, 174.0966],
  [-35.3599, 174.0975],
  [-35.3601, 174.1019],
  [-35.3598, 174.1034],
  [-35.3577, 174.1080],
  [-35.3543, 174.1117],
  [-35.3535, 174.1121],
  [-35.3525, 174.1121],
  [-35.3509, 174.1114],
  [-35.3504, 174.1109],
  [-35.3492, 174.1088]
];

const teAkeakeTeAkeakeTunnel = [
  [-35.3492, 174.1088],
  [-35.3487, 174.1074],
  [-35.3469, 174.1054],
  [-35.3452, 174.1044],
  [-35.3432, 174.1041],
  [-35.3404, 174.1029],
  [-35.3396, 174.1029],
  [-35.3388, 174.1033],
  [-35.3382, 174.1041],
  [-35.3376, 174.1060],
  [-35.3372, 174.1066],
  [-35.3361, 174.1073],
  [-35.3353, 174.1074]
];

const teAkeakeTunnel = [
  [-35.3353, 174.1074],
  [-35.3337, 174.1072]
];

const teAkeakeTunnelOpua = [
  [-35.3337, 174.1072],
  [-35.3333, 174.1071],
  [-35.3324, 174.1066],
  [-35.3318, 174.1064],
  [-35.3253, 174.1066],
  [-35.3246, 174.1070],
  [-35.3222, 174.1106],
  [-35.3200, 174.1145],
  [-35.3195, 174.1160],
  [-35.3184, 174.1177],
  [-35.3181, 174.1181],
  [-35.3158, 174.1191],
  [-35.3153, 174.1195],
  [-35.3142, 174.1208],
  [-35.3129, 174.1214],
  [-35.3122, 174.1212],
  [-35.3119, 174.1210],
  [-35.3111, 174.1200]
];

export const opua: Line = {
  name: 'Opua',
  state: 'NZ',
  segments: [
    {
      segments: [otiriaKawakawa],
      history: {
        opened: {
          date: '1868-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1871-01-01',
          trackType: 'heavy'
        }, {
          date: '1877-01-01',
          gauge: 'narrow'
        }, {
          date: '2001-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kawakawaTaumarere],
      history: {
        opened: {
          date: '1868-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1871-01-01',
          trackType: 'heavy'
        }, {
          date: '1877-01-01',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [taumarereTeAkeake],
      history: {
        opened: {
          date: '1884-03-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        teAkeakeTeAkeakeTunnel,
        {
          name: 'Te Akeake Tunnel',
          segment: teAkeakeTunnel,
          type: 'tunnel'
        },
        teAkeakeTunnelOpua
      ],
      history: {
        opened: {
          date: '1884-03-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2001-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
