import { Line } from "../../trackTypes";

const korongValeCharlton = [
  [-36.3522, 143.7060],
  [-36.3517, 143.7061],
  [-36.3515, 143.7061],
  [-36.3512, 143.7060],
  [-36.3508, 143.7059],
  [-36.3505, 143.7058],
  [-36.3500, 143.7055],
  [-36.3496, 143.7051],
  [-36.3494, 143.7047],
  [-36.3492, 143.7044],
  [-36.3487, 143.7030],
  [-36.3432, 143.6922],
  [-36.3423, 143.6908],
  [-36.3401, 143.6885],
  [-36.3384, 143.6873],
  [-36.3372, 143.6868],
  [-36.3358, 143.6864],
  [-36.3346, 143.6862],
  [-36.3207, 143.6861],
  [-36.3192, 143.6859],
  [-36.3175, 143.6850],
  [-36.3163, 143.6840],
  [-36.3149, 143.6821],
  [-36.3026, 143.6612],
  [-36.3018, 143.6599],
  [-36.2852, 143.6281],
  [-36.2842, 143.6257],
  [-36.2728, 143.5949],
  [-36.2649, 143.5737],
  [-36.2645, 143.5720],
  [-36.2643, 143.5705],
  [-36.2644, 143.4534],
  [-36.2696, 143.4130],
  [-36.2696, 143.4114],
  [-36.2697, 143.3584],
  [-36.2695, 143.3574],
  [-36.2692, 143.3566],
  [-36.2678, 143.3544]
];

const charltonWycheproof = [
  [-36.2678, 143.3544],
  [-36.2620, 143.3450],
  [-36.2600, 143.3431],
  [-36.2571, 143.3415],
  [-36.2542, 143.3410],
  [-36.2505, 143.3413],
  [-36.2487, 143.3411],
  [-36.2443, 143.3394],
  [-36.2421, 143.3387],
  [-36.2384, 143.3386],
  [-36.2265, 143.3405],
  [-36.1563, 143.3415],
  [-36.1551, 143.3414],
  [-36.1421, 143.3391],
  [-36.1408, 143.3384],
  [-36.1399, 143.3375],
  [-36.0811, 143.2267]
];

const wycheproofSeaLake = [
  [-36.0811, 143.2267],
  [-36.0808, 143.2262],
  [-36.0803, 143.2256],
  [-36.0797, 143.2254],
  [-36.0679, 143.2254],
  [-36.0670, 143.2253],
  [-35.9333, 143.2259],
  [-35.9086, 143.2261],
  [-35.9068, 143.2257],
  [-35.9052, 143.2248],
  [-35.8455, 143.1693],
  [-35.8361, 143.1586],
  [-35.8340, 143.1566],
  [-35.7835, 143.1211],
  [-35.7803, 143.1195],
  [-35.7782, 143.1189],
  [-35.7280, 143.1079],
  [-35.7238, 143.1059],
  [-35.7210, 143.1041],
  [-35.7195, 143.1021],
  [-35.7173, 143.0989],
  [-35.7112, 143.0879],
  [-35.7098, 143.0858],
  [-35.6848, 143.0581],
  [-35.6663, 143.0354],
  [-35.6386, 142.9989],
  [-35.5609, 142.9127],
  [-35.5322, 142.8804],
  [-35.5105, 142.8516],
  [-35.5087, 142.8496],
  [-35.5047, 142.8461]
];

const seaLakeNandaly = [
  [-35.5047, 142.8461],
  [-35.4965, 142.8391],
  [-35.4950, 142.8372],
  [-35.4939, 142.8353],
  [-35.4752, 142.7942],
  [-35.4741, 142.7926],
  [-35.4584, 142.7727],
  [-35.4566, 142.7695],
  [-35.4523, 142.7537],
  [-35.4449, 142.7362],
  [-35.4374, 142.7218],
  [-35.4321, 142.7156],
  [-35.4306, 142.7144],
  [-35.4128, 142.7052],
  [-35.4000, 142.6996],
  [-35.3903, 142.6938],
  [-35.3878, 142.6931],
  [-35.3103, 142.6976]
];

const nandalyMittyack = [
  [-35.3103, 142.6976],
  [-35.3074, 142.6978],
  [-35.3049, 142.6973],
  [-35.2746, 142.6813],
  [-35.2003, 142.6566],
  [-35.1990, 142.6563],
  [-35.1555, 142.6564]
];

const mittyackKulwin = [
  [-35.1555, 142.6564],
  [-35.1400, 142.6565],
  [-35.1384, 142.6563],
  [-35.1374, 142.6561],
  [-35.0672, 142.6346],
  [-35.0655, 142.6342],
  [-35.0479, 142.6315],
  [-35.0461, 142.6313],
  [-35.0301, 142.6317]
];

export const kulwin: Line = {
  name: 'Kulwin',
  state: 'VIC',
  segments: [
    {
      segments: [korongValeCharlton],
      history: {
        opened: {
          date: '1883-04-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [charltonWycheproof],
      history: {
        opened: {
          date: '1883-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wycheproofSeaLake],
      history: {
        opened: {
          date: '1895-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [seaLakeNandaly],
      history: {
        opened: {
          date: '1914-06-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nandalyMittyack],
      history: {
        opened: {
          date: '1919-05-27',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mittyackKulwin],
      history: {
        opened: {
          date: '1920-06-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-02-01',
          status: 'closed'
        }]
      }
    }
  ]
};
