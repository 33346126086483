import { Line } from "../../../trackTypes";

const junctionTirau = [
  [-37.6600, 175.5310],
  [-37.6591, 175.5356],
  [-37.6595, 175.5384],
  [-37.6604, 175.5388],
  [-37.6619, 175.5398],
  [-37.6632, 175.5400],
  [-37.6643, 175.5404],
  [-37.6825, 175.5533],
  [-37.6886, 175.5576],
  [-37.6924, 175.5615],
  [-37.6956, 175.5666],
  [-37.6974, 175.5720],
  [-37.6981, 175.5776],
  [-37.6981, 175.5810],
  [-37.6991, 175.5879],
  [-37.7006, 175.5914],
  [-37.7061, 175.5984],
  [-37.7071, 175.6012],
  [-37.7075, 175.6064],
  [-37.7089, 175.6123],
  [-37.7097, 175.6144],
  [-37.7118, 175.6185],
  [-37.7131, 175.6220],
  [-37.7144, 175.6236],
  [-37.7223, 175.6290],
  [-37.7232, 175.6302],
  [-37.7235, 175.6316],
  [-37.7233, 175.6332],
  [-37.7178, 175.6463],
  [-37.7172, 175.6506],
  [-37.7174, 175.6547],
  [-37.7194, 175.6705],
  [-37.7201, 175.6737],
  [-37.7266, 175.6940],
  [-37.7352, 175.7117],
  [-37.7359, 175.7137],
  [-37.7367, 175.7202],
  [-37.7423, 175.7382],
  [-37.7435, 175.7407],
  [-37.7453, 175.7427],
  [-37.7478, 175.7443],
  [-37.7548, 175.7490],
  [-37.7584, 175.7512],
  [-37.8077, 175.7711],
  [-37.8213, 175.7712],
  [-37.8235, 175.7708],
  [-37.8614, 175.7548],
  [-37.8639, 175.7546],
  [-37.8816, 175.7599],
  [-37.9134, 175.7666],
  [-37.9155, 175.7664],
  [-37.9417, 175.7560],
  [-37.9662, 175.7533],
  [-37.9676, 175.7535],
  [-37.9686, 175.7541],
  [-37.9741, 175.7592]
];

const tirauPutaruruJunction = [
  [-37.9741, 175.7592],
  [-37.9780, 175.7631],
  [-37.9795, 175.7638],
  [-37.9807, 175.7637],
  [-37.9876, 175.7611],
  [-37.9888, 175.7609],
  [-37.9901, 175.7611],
  [-37.9914, 175.7608],
  [-37.9975, 175.7572],
  [-37.9996, 175.7569],
  [-38.0048, 175.7576],
  [-38.0059, 175.7573],
  [-38.0068, 175.7572],
  [-38.0079, 175.7575],
  [-38.0111, 175.7595],
  [-38.0141, 175.7596],
  [-38.0165, 175.7588],
  [-38.0181, 175.7590],
  [-38.0192, 175.7599],
  [-38.0211, 175.7627],
  [-38.0223, 175.7636],
  [-38.0266, 175.7648],
  [-38.0299, 175.7650],
  [-38.0319, 175.7653],
  [-38.0338, 175.7667],
  [-38.0373, 175.7713],
  [-38.0390, 175.7725],
  [-38.0406, 175.7730],
  [-38.0436, 175.7730],
  [-38.0458, 175.7738],
  [-38.0470, 175.7749],
  [-38.0508, 175.7794],
  [-38.0557, 175.7833],
  [-38.0565, 175.7845],
  [-38.0572, 175.7866]
];

const putaruruJunctionTokoroa = [
  [-38.0572, 175.7866],
  [-38.0574, 175.7874],
  [-38.0586, 175.7893],
  [-38.0619, 175.7916],
  [-38.0628, 175.7917],
  [-38.0641, 175.7914],
  [-38.0667, 175.7895],
  [-38.0678, 175.7891],
  [-38.0687, 175.7892],
  [-38.0714, 175.7903],
  [-38.0777, 175.7941],
  [-38.0797, 175.7957],
  [-38.0806, 175.7970],
  [-38.0822, 175.8009],
  [-38.0839, 175.8078],
  [-38.0846, 175.8091],
  [-38.0859, 175.8099],
  [-38.0904, 175.8111],
  [-38.0919, 175.8117],
  [-38.0982, 175.8159],
  [-38.1172, 175.8231],
  [-38.1182, 175.8233],
  [-38.1216, 175.8231],
  [-38.1223, 175.8230],
  [-38.1247, 175.8217],
  [-38.1258, 175.8217],
  [-38.1331, 175.8248],
  [-38.1362, 175.8256],
  [-38.1376, 175.8265],
  [-38.1413, 175.8304],
  [-38.1441, 175.8322],
  [-38.1459, 175.8337],
  [-38.1471, 175.8342],
  [-38.1503, 175.8346],
  [-38.1524, 175.8345],
  [-38.1534, 175.8347],
  [-38.1544, 175.8354],
  [-38.1566, 175.8381],
  [-38.1666, 175.8450],
  [-38.1683, 175.8453],
  [-38.1761, 175.8422],
  [-38.1789, 175.8420],
  [-38.1821, 175.8430],
  [-38.1831, 175.8436],
  [-38.1854, 175.8461],
  [-38.1880, 175.8486],
  [-38.1914, 175.8508],
  [-38.1961, 175.8552],
  [-38.1986, 175.8581],
  [-38.2024, 175.8614],
  [-38.2047, 175.8628],
  [-38.2058, 175.8632],
  [-38.2069, 175.8633],
  [-38.2103, 175.8626],
  [-38.2112, 175.8627],
  [-38.2154, 175.8647]
];

const tokoroaKinleith = [
  [-38.2154, 175.8647],
  [-38.2199, 175.8669],
  [-38.2583, 175.8901],
  [-38.2598, 175.8905],
  [-38.2660, 175.8898],
  [-38.2671, 175.8900],
  [-38.2739, 175.8940],
  [-38.2793, 175.8987],
  [-38.2845, 175.9053]
];

export const kinleith: Line = {
  name: 'Kinleith',
  state: 'NZ',
  segments: [
    {
      segments: [junctionTirau],
      history: {
        opened: {
          date: '1886-03-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [tirauPutaruruJunction],
      history: {
        opened: {
          date: '1893-11-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [putaruruJunctionTokoroa],
      history: {
        opened: {
          date: '1949-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [tokoroaKinleith],
      history: {
        opened: {
          date: '1952-10-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
