import { Line } from "../../../trackTypes";

const line = [
  [-19.2706, 146.8141],
  [-19.2701, 146.8140],
  [-19.2698, 146.8141],
  [-19.2693, 146.8145],
  [-19.2690, 146.8151],
  [-19.2692, 146.8197],
  [-19.2686, 146.8216],
  [-19.2675, 146.8228],
  [-19.2567, 146.8283],
  [-19.2561, 146.8292],
  [-19.2557, 146.8312],
  [-19.2553, 146.8321],
  [-19.2531, 146.8344],
  [-19.2525, 146.8349],
  [-19.2466, 146.8368]
];

export const townsvilleWharf: Line = {
  name: 'Townsville Wharf',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1929-05-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
