import { Line } from "../../../../trackTypes";

const elwoodDepot = [
  [-37.89099, 144.99083],
  [-37.89113, 144.99092],
  [-37.89122, 144.99107],
  [-37.89130, 144.99160]
];

const stKildaStationElwood = [
  [-37.85928, 144.97699],
  [-37.86409, 144.98157],
  [-37.86425, 144.98168],
  [-37.86443, 144.98173],
  [-37.86732, 144.98121],
  [-37.87027, 144.98067],
  [-37.87036, 144.98070],
  [-37.87047, 144.98077],
  [-37.87539, 144.98382],
  [-37.87552, 144.98387],
  [-37.87564, 144.98387],
  [-37.88209, 144.98262],
  [-37.88223, 144.98260],
  [-37.88230, 144.98264],
  [-37.88242, 144.98275],
  [-37.88755, 144.99119],
  [-37.88768, 144.99130],
  [-37.88780, 144.99134],
  [-37.88793, 144.99137],
  [-37.89099, 144.99083],
  [-37.89162, 144.99070]
];

const elwoodMiddleBrighton = [
  [-37.89162, 144.99070],
  [-37.90460, 144.98828],
  [-37.90769, 144.98769],
  [-37.90792, 144.98763],
  [-37.90809, 144.98753],
  [-37.90833, 144.98734],
  [-37.90846, 144.98716],
  [-37.90861, 144.98696],
  [-37.90875, 144.98681],
  [-37.90890, 144.98670],
  [-37.90907, 144.98660],
  [-37.91076, 144.98606],
  [-37.91213, 144.98580],
  [-37.91229, 144.98578]
];

const middleBrightonBrightonBeachStation = [
  [-37.91229, 144.98578],
  [-37.91250, 144.98578],
  [-37.91269, 144.98582],
  [-37.91290, 144.98591],
  [-37.91421, 144.98671],
  [-37.91456, 144.98689],
  [-37.91484, 144.98698],
  [-37.91553, 144.98715],
  [-37.91586, 144.98719],
  [-37.91685, 144.98723],
  [-37.91696, 144.98724],
  [-37.91716, 144.98728],
  [-37.91867, 144.98791],
  [-37.91892, 144.98798],
  [-37.92030, 144.98815],
  [-37.92050, 144.98815],
  [-37.92071, 144.98813],
  [-37.92221, 144.98785],
  [-37.92253, 144.98781],
  [-37.92312, 144.98780],
  [-37.92336, 144.98776],
  [-37.92410, 144.98749],
  [-37.92433, 144.98742],
  [-37.92581, 144.98714],
  [-37.92605, 144.98715],
  [-37.92632, 144.98724],
  [-37.92650, 144.98737],
  [-37.92730, 144.98829]
];

const sandringgamDepot = [
  [-37.95078, 145.00477],
  [-37.95158, 145.00460],
  [-37.95174, 145.00469],
  [-37.95182, 145.00481]
];

const sandringhamStationBlackRock = [
  [-37.94994, 145.00427],
  [-37.95036, 145.00420],
  [-37.95053, 145.00418],
  [-37.95071, 145.00418],
  [-37.95150, 145.00403],
  [-37.95159, 145.00407],
  [-37.95168, 145.00412],
  [-37.95174, 145.00423],
  [-37.95182, 145.00481],
  [-37.95223, 145.00775],
  [-37.95229, 145.00788],
  [-37.95237, 145.00803],
  [-37.95622, 145.01147],
  [-37.95634, 145.01153],
  [-37.95646, 145.01152],
  [-37.95768, 145.01126],
  [-37.95784, 145.01128],
  [-37.95796, 145.01134],
  [-37.95804, 145.01147],
  [-37.95809, 145.01163],
  [-37.95907, 145.01911],
  [-37.95914, 145.01926],
  [-37.95923, 145.01937],
  [-37.95933, 145.01945],
  [-37.95945, 145.01950],
  [-37.96748, 145.01783],
  [-37.97512, 145.01623]
];

const blackRockBeaumaris = [
  [-37.97512, 145.01623],
  [-37.97525, 145.01622],
  [-37.97537, 145.01624],
  [-37.97546, 145.01635],
  [-37.97552, 145.01649],
  [-37.97565, 145.01736],
  [-37.97573, 145.01755],
  [-37.97580, 145.01765],
  [-37.97620, 145.01775],
  [-37.97659, 145.01787],
  [-37.98027, 145.01964],
  [-37.98032, 145.01969],
  [-37.98040, 145.01981],
  [-37.98043, 145.01992],
  [-37.98099, 145.02455],
  [-37.98097, 145.02467],
  [-37.98097, 145.02479],
  [-37.98096, 145.02491],
  [-37.98098, 145.02512],
  [-37.98156, 145.02889],
  [-37.98159, 145.02896],
  [-37.98163, 145.02902],
  [-37.98170, 145.02905],
  [-37.98614, 145.02825],
  [-37.98621, 145.02829],
  [-37.98625, 145.02835],
  [-37.98674, 145.03260],
  [-37.98678, 145.03267],
  [-37.98681, 145.03270],
  [-37.98688, 145.03274],
  [-37.98926, 145.03232],
  [-37.98931, 145.03233],
  [-37.98935, 145.03237],
  [-37.98938, 145.03245],
  [-37.98989, 145.03691],
  [-37.98992, 145.03697],
  [-37.98995, 145.03700],
  [-37.99024, 145.03695],
  [-37.99028, 145.03697],
  [-37.99031, 145.03699],
  [-37.99034, 145.03703],
  [-37.99074, 145.04029]
];

export const melbourneVicRailTrams: Line = {
  name: 'Melbourne Trams (Vic Rail)',
  state: 'VIC',
  segments: [
    {
      segments: [stKildaStationElwood, elwoodDepot],
      history: {
        opened: {
          date: '1906-05-05',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1959-02-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elwoodMiddleBrighton],
      history: {
        opened: {
          date: '1906-12-22',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [middleBrightonBrightonBeachStation],
      history: {
        opened: {
          date: '1906-12-22',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandringhamStationBlackRock, sandringgamDepot],
      history: {
        opened: {
          date: '1919-03-10',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1931-08-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [blackRockBeaumaris],
      history: {
        opened: {
          date: '1926-09-01',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1931-08-31',
          status: 'closed'
        }]
      }
    }
  ]
};
