import { Line } from "../../../trackTypes";

const illawarraJunctionArncliffeTunnel = [
  [-33.8952, 151.1902],
  [-33.8971, 151.1874],
  [-33.8984, 151.1863],
  [-33.8999, 151.1855],
  [-33.9038, 151.1845],
  [-33.9051, 151.1838],
  [-33.9063, 151.1826],
  [-33.9070, 151.1811],
  [-33.9084, 151.1764],
  [-33.9098, 151.1735],
  [-33.9193, 151.1591],
  [-33.9202, 151.1581],
  [-33.9214, 151.1572],
  [-33.9222, 151.1569],
  [-33.9241, 151.1565],
  [-33.9251, 151.1561],
  [-33.9269, 151.1549],
  [-33.9314, 151.1501],
  [-33.9327, 151.1491],
  [-33.9381, 151.1465],
  [-33.9389, 151.1461]
];

const arncliffeTunnel = [
  [-33.9389, 151.1461],
  [-33.9393, 151.1458]
];

const arncliffeTunnelHurstville = [
  [-33.9393, 151.1458],
  [-33.9401, 151.1451],
  [-33.9418, 151.1431],
  [-33.9427, 151.1420],
  [-33.9439, 151.1412],
  [-33.9498, 151.1380],
  [-33.9559, 151.1348],
  [-33.9572, 151.1342],
  [-33.9635, 151.1322],
  [-33.9644, 151.1318],
  [-33.9657, 151.1309],
  [-33.9667, 151.1297],
  [-33.9675, 151.1276],
  [-33.9697, 151.1167],
  [-33.9698, 151.1155],
  [-33.9694, 151.1136],
  [-33.9686, 151.1109],
  [-33.9683, 151.1090],
  [-33.9684, 151.1067],
  [-33.9681, 151.1052],
  [-33.9680, 151.1037],
  [-33.9673, 151.1025]
];

const hurstvilleComoBridge = [
  [-33.9673, 151.1025],
  [-33.9660, 151.1000],
  [-33.9654, 151.0983],
  [-33.9651, 151.0967],
  [-33.9652, 151.0955],
  [-33.9658, 151.0908],
  [-33.9663, 151.0875],
  [-33.9666, 151.0865],
  [-33.9673, 151.0849],
  [-33.9683, 151.0833],
  [-33.9696, 151.0820],
  [-33.9718, 151.0806],
  [-33.9737, 151.0798],
  [-33.9744, 151.0793],
  [-33.9753, 151.0786],
  [-33.9762, 151.0783],
  [-33.9771, 151.0782],
  [-33.9799, 151.0790],
  [-33.9812, 151.0790],
  [-33.9824, 151.0785],
  [-33.9839, 151.0774],
  [-33.9854, 151.0768],
  [-33.9870, 151.0768],
  [-33.9877, 151.0767],
  [-33.9886, 151.0763],
  [-33.9930, 151.0726],
  [-33.9940, 151.0715]
];

const comoBridge = [
  [-33.9940, 151.0715],
  [-33.9963, 151.0690]
];

const comoBridgeSutherland = [
  [-33.9963, 151.0690],
  [-33.9975, 151.0677],
  [-33.9989, 151.0668],
  [-34.0001, 151.0664],
  [-34.0015, 151.0664],
  [-34.0028, 151.0670],
  [-34.0045, 151.0682],
  [-34.0057, 151.0687],
  [-34.0068, 151.0687],
  [-34.0076, 151.0684],
  [-34.0084, 151.0678],
  [-34.0099, 151.0659],
  [-34.0112, 151.0649],
  [-34.0126, 151.0644],
  [-34.0142, 151.0643],
  [-34.0175, 151.0648],
  [-34.0193, 151.0647],
  [-34.0215, 151.0642],
  [-34.0235, 151.0631],
  [-34.0301, 151.0582],
  [-34.0316, 151.0572]
];

const sutherlandLoftus = [
  [-34.0316, 151.0572],
  [-34.0386, 151.0522],
  [-34.0413, 151.0511],
  [-34.0437, 151.0510],
  [-34.0451, 151.0513]
];

const loftusRoyalParkJunction = [
  [-34.0451, 151.0513],
  [-34.0465, 151.0516],
  [-34.0480, 151.0516],
  [-34.0489, 151.0513]
];

const royalParkJunctionWaterfall = [
  [-34.0489, 151.0513],
  [-34.0506, 151.0503],
  [-34.0521, 151.0488],
  [-34.0529, 151.0472],
  [-34.0635, 151.0203],
  [-34.0659, 151.0166],
  [-34.0682, 151.0143],
  [-34.0727, 151.0120],
  [-34.0755, 151.0115],
  [-34.0819, 151.0117],
  [-34.0842, 151.0112],
  [-34.0866, 151.0096],
  [-34.0975, 150.9980],
  [-34.1008, 150.9956],
  [-34.1051, 150.9942],
  [-34.1235, 150.9931],
  [-34.1345, 150.9945]
];

export const waterfall: Line = {
  name: 'Waterfall',
  state: 'NSW',
  segments: [
    {
      segments: [
        illawarraJunctionArncliffeTunnel,
        {
          date: '1925-01-01',
          original: [{
            name: 'Arncliffe Tunnel',
            segment: arncliffeTunnel,
            type: 'tunnel'
          }],
          deviation: [arncliffeTunnel]
        },
        arncliffeTunnelHurstville
      ],
      history: {
        opened: {
          date: '1884-10-15',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1925-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [hurstvilleComoBridge],
      history: {
        opened: {
          date: '1885-12-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1890-04-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [comoBridge],
      history: {
        opened: {
          date: '1885-12-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1969-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [comoBridgeSutherland],
      history: {
        opened: {
          date: '1885-12-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1890-04-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [sutherlandLoftus],
      history: {
        opened: {
          date: '1886-03-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1890-04-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [loftusRoyalParkJunction],
      history: {
        opened: {
          date: '1886-03-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1890-12-12',
          tracks: 2
        }]
      }
    },
    {
      segments: [royalParkJunctionWaterfall],
      history: {
        opened: {
          date: '1886-03-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1890-12-12',
          tracks: 2
        }]
      }
    }
  ]
};
