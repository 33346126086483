import { Line } from "../../../trackTypes";

const cannonHillFishermanIslands = [
  [-27.4393, 153.1522],
  [-27.4384, 153.1529],
  [-27.4376, 153.1531],
  [-27.4351, 153.1535],
  [-27.4287, 153.1547],
  [-27.4274, 153.1555],
  [-27.4267, 153.1567],
  [-27.4246, 153.1622],
  [-27.4236, 153.1636],
  [-27.4225, 153.1645],
  [-27.4186, 153.1668],
  [-27.4167, 153.1674],
  [-27.4144, 153.1675],
  [-27.3935, 153.1642],
  [-27.3918, 153.1641],
  [-27.3904, 153.1648],
  [-27.3764, 153.1746],
  [-27.3743, 153.1761],
  [-27.3738, 153.1772],
  [-27.3740, 153.1783],
  [-27.3747, 153.1788],
  [-27.3754, 153.1788],
  [-27.3760, 153.1784],
  [-27.3763, 153.1774],
  [-27.3760, 153.1765],
  [-27.3760, 153.1758],
  [-27.3761, 153.1753],
  [-27.3764, 153.1746]
];

export const fishermanIslands: Line = {
  name: 'Fisherman Islands',
  state: 'QLD',
  segments: [
    {
      segments: [cannonHillFishermanIslands],
      history: {
        opened: {
          date: '1980-01-01',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-07-15',
          gauge: 'dual'
        }]
      }
    }
  ]
}
