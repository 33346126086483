import { Line } from "../../trackTypes";

const stMarysDunheved = [
  [-33.7613, 150.7710],
  [-33.7608, 150.7700],
  [-33.7600, 150.7694],
  [-33.7562, 150.7682],
  [-33.7487, 150.7641],
  [-33.7474, 150.7641],
  [-33.7463, 150.7651],
  [-33.7459, 150.7660],
  [-33.7467, 150.7733]
];

const dunhevedRopesCreek = [
  [-33.7467, 150.7733],
  [-33.7469, 150.7752],
  [-33.7464, 150.7768],
  [-33.7454, 150.7774],
  [-33.7331, 150.7794],
  [-33.7317, 150.7801],
  [-33.7231, 150.7816]
];

const dunhevedStMarysMunitionsWorks = [
  [-33.7459, 150.7660],
  [-33.7456, 150.7651],
  [-33.7447, 150.7639],
  [-33.7438, 150.7633],
  [-33.7421, 150.7630],
  [-33.7411, 150.7625],
  [-33.7389, 150.7592],
  [-33.7336, 150.7500],
  [-33.7289, 150.7374],
  [-33.7287, 150.7354],
  [-33.7287, 150.7332],
  [-33.7283, 150.7285]
];


export const ropesCreek: Line = {
  name: 'ropesCreek',
  state: 'NSW',
  segments: [
    {
      segments: [stMarysDunheved],
      history: {
        opened: {
          date: '1942-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-09-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1986-03-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dunhevedRopesCreek],
      history: {
        opened: {
          date: '1942-06-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-09-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1986-03-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dunhevedStMarysMunitionsWorks],
      history: {
        opened: {
          date: '1942-06-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-09-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1986-03-22',
          status: 'closed'
        }]
      }
    }
  ]
};
