import { Line } from "../../trackTypes";

const waikanaeOtaki = [
  [-40.8765, 175.0662],
  [-40.8537, 175.0840],
  [-40.8525, 175.0847],
  [-40.8515, 175.0849],
  [-40.8455, 175.0845],
  [-40.8444, 175.0847],
  [-40.8434, 175.0852],
  [-40.8399, 175.0876],
  [-40.8224, 175.1009],
  [-40.8211, 175.1024],
  [-40.8206, 175.1038],
  [-40.8204, 175.1053],
  [-40.8196, 175.1072],
  [-40.8187, 175.1084],
  [-40.7739, 175.1440],
  [-40.7726, 175.1449],
  [-40.7687, 175.1465],
  [-40.7674, 175.1474],
  [-40.7665, 175.1487],
  [-40.7654, 175.1507],
  [-40.7630, 175.1565]
];

const tokomaruOtaki = [
  [-40.4697, 175.5085],
  [-40.5084, 175.4595],
  [-40.5108, 175.4559],
  [-40.5124, 175.4529],
  [-40.5248, 175.4424],
  [-40.5269, 175.4401],
  [-40.5365, 175.4252],
  [-40.5490, 175.4071],
  [-40.5509, 175.4031],
  [-40.5521, 175.3980],
  [-40.5543, 175.3718],
  [-40.5553, 175.3685],
  [-40.5573, 175.3656],
  [-40.5604, 175.3623],
  [-40.5630, 175.3586],
  [-40.5667, 175.3511],
  [-40.5694, 175.3473],
  [-40.5749, 175.3418],
  [-40.5807, 175.3360],
  [-40.5824, 175.3340],
  [-40.5835, 175.3313],
  [-40.5839, 175.3282],
  [-40.5830, 175.3158],
  [-40.5835, 175.3135],
  [-40.5854, 175.3080],
  [-40.5864, 175.3061],
  [-40.5878, 175.3047],
  [-40.5895, 175.3041],
  [-40.6015, 175.3041],
  [-40.6044, 175.3036],
  [-40.6080, 175.3017],
  [-40.6101, 175.2999],
  [-40.6292, 175.2800],
  [-40.6470, 175.2617],
  [-40.6564, 175.2519],
  [-40.6577, 175.2513],
  [-40.6587, 175.2515],
  [-40.6601, 175.2523],
  [-40.6610, 175.2525],
  [-40.6618, 175.2523],
  [-40.6627, 175.2517],
  [-40.6697, 175.2436],
  [-40.6727, 175.2401],
  [-40.6748, 175.2385],
  [-40.7002, 175.2231],
  [-40.7169, 175.2151],
  [-40.7177, 175.2148],
  [-40.7186, 175.2148],
  [-40.7219, 175.2157],
  [-40.7229, 175.2157],
  [-40.7238, 175.2153],
  [-40.7247, 175.2141],
  [-40.7252, 175.2128],
  [-40.7265, 175.2104],
  [-40.7279, 175.2087],
  [-40.7309, 175.2057],
  [-40.7318, 175.2047],
  [-40.7326, 175.2031],
  [-40.7332, 175.2007],
  [-40.7332, 175.1988],
  [-40.7330, 175.1973],
  [-40.7329, 175.1964],
  [-40.7331, 175.1929],
  [-40.7330, 175.1919],
  [-40.7325, 175.1895],
  [-40.7325, 175.1873],
  [-40.7327, 175.1866],
  [-40.7332, 175.1857],
  [-40.7335, 175.1847],
  [-40.7335, 175.1836],
  [-40.7329, 175.1824],
  [-40.7324, 175.1812],
  [-40.7324, 175.1802],
  [-40.7330, 175.1790],
  [-40.7339, 175.1783],
  [-40.7346, 175.1783],
  [-40.7353, 175.1782],
  [-40.7360, 175.1776],
  [-40.7365, 175.1763],
  [-40.7365, 175.1748],
  [-40.7367, 175.1736],
  [-40.7371, 175.1730],
  [-40.7377, 175.1724],
  [-40.7382, 175.1719],
  [-40.7386, 175.1710],
  [-40.7401, 175.1663],
  [-40.7409, 175.1652],
  [-40.7422, 175.1645],
  [-40.7489, 175.1630],
  [-40.7518, 175.1621],
  [-40.7535, 175.1613],
  [-40.7550, 175.1608],
  [-40.7566, 175.1608],
  [-40.7591, 175.1611],
  [-40.7602, 175.1610],
  [-40.7610, 175.1604],
  [-40.7618, 175.1593],
  [-40.7630, 175.1565]
];

const longburnTokomaru = [
  [-40.3846, 175.5404],
  [-40.3857, 175.5395],
  [-40.3866, 175.5392],
  [-40.3875, 175.5390],
  [-40.3890, 175.5396],
  [-40.3976, 175.5461],
  [-40.3993, 175.5470],
  [-40.4011, 175.5474],
  [-40.4030, 175.5474],
  [-40.4052, 175.5481],
  [-40.4069, 175.5494],
  [-40.4107, 175.5533],
  [-40.4127, 175.5545],
  [-40.4149, 175.5546],
  [-40.4169, 175.5537],
  [-40.4299, 175.5444],
  [-40.4320, 175.5425],
  [-40.4336, 175.5404],
  [-40.4367, 175.5356],
  [-40.4390, 175.5328],
  [-40.4520, 175.5208],
  [-40.4546, 175.5187],
  [-40.4642, 175.5138],
  [-40.4664, 175.5123],
  [-40.4683, 175.5104],
  [-40.4697, 175.5085]
];

const palmerstonNorthFoxtonJunction = [
  [-40.3591, 175.6048],
  [-40.3796, 175.5582],
  [-40.3802, 175.5561],
  [-40.3812, 175.5483],
  [-40.3817, 175.5470],
  [-40.3846, 175.5404]
];

const manawatuJunctionPalmerstonNorth = [
  [-40.3361, 175.6413],
  [-40.3393, 175.6431],
  [-40.3405, 175.6430],
  [-40.3416, 175.6424],
  [-40.3425, 175.6414],
  [-40.3473, 175.6308],
  [-40.3591, 175.6048]
];

const milsonJunction = [
  [-40.3256, 175.6284],
  [-40.3251, 175.6297],
  [-40.3250, 175.6309],
  [-40.3251, 175.6318],
  [-40.3254, 175.6326],
  [-40.3261, 175.6338]
];

const palmerstonShared = [
  [-40.3261, 175.6338],
  [-40.3361, 175.6413]
];

const milsonDeviationUnelectrified = [
  [-40.3846, 175.5404],
  [-40.3627, 175.5565],
  [-40.3608, 175.5582],
  [-40.3594, 175.5606],
  [-40.3474, 175.5875]
];

const milsonDeviationElectrified = [
  [-40.3474, 175.5875],
  [-40.3457, 175.5912],
  [-40.3447, 175.5931],
  [-40.3423, 175.5975],
  [-40.3349, 175.6141],
  [-40.3336, 175.6166],
  [-40.3256, 175.6284],
  [-40.3244, 175.6299],
  [-40.3233, 175.6309],
  [-40.3219, 175.6315],
  [-40.3207, 175.6316]
];

const palmerstonMarton = [
  [-40.3207, 175.6316],
  [-40.2856, 175.6325],
  [-40.2845, 175.6323],
  [-40.2836, 175.6316],
  [-40.2598, 175.6040],
  [-40.2391, 175.5800],
  [-40.2372, 175.5767],
  [-40.2359, 175.5755],
  [-40.2328, 175.5740],
  [-40.2312, 175.5729],
  [-40.2184, 175.5620],
  [-40.2172, 175.5613],
  [-40.2160, 175.5612],
  [-40.2118, 175.5621],
  [-40.2104, 175.5628],
  [-40.1981, 175.5691],
  [-40.1969, 175.5694],
  [-40.1951, 175.5688],
  [-40.1936, 175.5670],
  [-40.1919, 175.5622],
  [-40.1916, 175.5608],
  [-40.1916, 175.5594],
  [-40.1919, 175.5578],
  [-40.1919, 175.5565],
  [-40.1917, 175.5550],
  [-40.1897, 175.5494],
  [-40.1887, 175.5478],
  [-40.1876, 175.5470],
  [-40.1863, 175.5468],
  [-40.1852, 175.5468],
  [-40.1842, 175.5465],
  [-40.1832, 175.5458],
  [-40.1824, 175.5451],
  [-40.1811, 175.5445],
  [-40.1800, 175.5437],
  [-40.1792, 175.5425],
  [-40.1764, 175.5374],
  [-40.1757, 175.5365],
  [-40.1749, 175.5357],
  [-40.1741, 175.5346],
  [-40.1727, 175.5317],
  [-40.1724, 175.5303],
  [-40.1721, 175.5264],
  [-40.1716, 175.5245],
  [-40.1705, 175.5229],
  [-40.1694, 175.5220],
  [-40.1673, 175.5209],
  [-40.1664, 175.5201],
  [-40.1629, 175.5156],
  [-40.1618, 175.5146],
  [-40.1608, 175.5132],
  [-40.1602, 175.5119],
  [-40.1594, 175.5107],
  [-40.1583, 175.5100],
  [-40.1571, 175.5097],
  [-40.1557, 175.5090],
  [-40.1547, 175.5077],
  [-40.1506, 175.4986],
  [-40.1488, 175.4961],
  [-40.1473, 175.4951],
  [-40.1450, 175.4943],
  [-40.1436, 175.4934],
  [-40.1381, 175.4890],
  [-40.1365, 175.4882],
  [-40.1353, 175.4871],
  [-40.1346, 175.4850],
  [-40.1337, 175.4838],
  [-40.1317, 175.4814],
  [-40.1311, 175.4801],
  [-40.1309, 175.4788],
  [-40.1308, 175.4746],
  [-40.1323, 175.4649],
  [-40.1336, 175.4605],
  [-40.1335, 175.4582],
  [-40.1310, 175.4512],
  [-40.1295, 175.4492],
  [-40.1188, 175.4416],
  [-40.1170, 175.4393],
  [-40.1153, 175.4347],
  [-40.1152, 175.4329],
  [-40.1158, 175.4311],
  [-40.1172, 175.4300],
  [-40.1188, 175.4298],
  [-40.1199, 175.4293],
  [-40.1235, 175.4265],
  [-40.1271, 175.4254],
  [-40.1281, 175.4244],
  [-40.1287, 175.4228],
  [-40.1286, 175.4212],
  [-40.1276, 175.4177],
  [-40.1266, 175.4161],
  [-40.0874, 175.3914]
];

const palmerstonEastJunction = [
  [-40.3261, 175.6338],
  [-40.3242, 175.6322],
  [-40.3229, 175.6317],
  [-40.3207, 175.6316]
];

const martonMoaDeviationStart = [
  [-40.0851, 175.3899],
  [-40.0839, 175.3902],
  [-40.0829, 175.3914],
  [-40.0650, 175.4357],
  [-40.0640, 175.4371],
  [-40.0629, 175.4379],
  [-40.0618, 175.4383],
  [-40.0608, 175.4389],
  [-40.0597, 175.4397],
  [-40.0583, 175.4402],
  [-40.0567, 175.4402],
  [-40.0538, 175.4411],
  [-40.0523, 175.4421],
  [-40.0513, 175.4426],
  [-40.0496, 175.4430],
  [-40.0456, 175.4451],
  [-40.0445, 175.4461],
  [-40.0340, 175.4614],
  [-40.0285, 175.4667],
  [-40.0275, 175.4680],
  [-40.0253, 175.4732],
  [-40.0115, 175.4962],
  [-39.9965, 175.5139],
  [-39.9948, 175.5151],
  [-39.9864, 175.5177],
  [-39.9847, 175.5187],
  [-39.9803, 175.5233],
  [-39.9787, 175.5242],
  [-39.9772, 175.5245],
  [-39.9708, 175.5241],
  [-39.9687, 175.5248],
  [-39.9674, 175.5263],
  [-39.9587, 175.5451],
  [-39.9579, 175.5478],
  [-39.9569, 175.5572],
  [-39.9565, 175.5590],
  [-39.9536, 175.5672],
  [-39.9519, 175.5693],
  [-39.9436, 175.5733],
  [-39.9425, 175.5734],
  [-39.9415, 175.5730],
  [-39.9391, 175.5711],
  [-39.9378, 175.5708],
  [-39.9365, 175.5715],
  [-39.9323, 175.5793],
  [-39.9280, 175.5826],
  [-39.9271, 175.5853],
  [-39.9267, 175.5868],
  [-39.9265, 175.5888],
  [-39.9257, 175.5909],
  [-39.9242, 175.5927],
  [-39.9178, 175.5963],
  [-39.9161, 175.5969],
  [-39.9103, 175.5979],
  [-39.9089, 175.5986],
  [-39.9055, 175.6014],
  [-39.9042, 175.6037],
  [-39.9037, 175.6059],
  [-39.9036, 175.6097],
  [-39.9031, 175.6123],
  [-39.9031, 175.6139],
  [-39.9038, 175.6193],
  [-39.9037, 175.6217],
  [-39.9030, 175.6244],
  [-39.9028, 175.6249]
];

const deviationStartMoaTunnel = [
  [-39.9028, 175.6249],
  [-39.9024, 175.6253],
  [-39.9007, 175.6266],
  [-39.9002, 175.6273]
];

const moaTunnel = [
  [-39.9002, 175.6273],
  [-39.8997, 175.6280]
];

const moaTunnelKiwiTunnel = [
  [-39.8997, 175.6280],
  [-39.8984, 175.6295],
  [-39.8979, 175.6298],
  [-39.8973, 175.6297],
  [-39.8969, 175.6294],
  [-39.8950, 175.6266],
  [-39.8945, 175.6261],
  [-39.8940, 175.6259],
  [-39.8936, 175.6259]
];

const kiwiTunnel = [
  [-39.8936, 175.6259],
  [-39.8929, 175.6260]
];

const kiwiTunnelDeviationEnd = [
  [-39.8929, 175.6260],
  [-39.8922, 175.6261],
  [-39.8917, 175.6264],
  [-39.8911, 175.6273],
  [-39.8910, 175.6282],
  [-39.8911, 175.6290],
  [-39.8914, 175.6296],
  [-39.8920, 175.6301],
  [-39.8926, 175.6304],
  [-39.8930, 175.6307],
  [-39.8934, 175.6312],
  [-39.8946, 175.6376],
  [-39.8946, 175.6380],
  [-39.8945, 175.6386]
];

const moaDeviation = [
  [-39.9028, 175.6249],
  [-39.9014, 175.6271],
  [-39.8994, 175.6289],
  [-39.8973, 175.6316],
  [-39.8962, 175.6340],
  [-39.8945, 175.6386]
];

const moaDeviationEndMakohineTunnel = [
  [-39.8945, 175.6386],
  [-39.8934, 175.6413],
  [-39.8931, 175.6441],
  [-39.8927, 175.6470],
  [-39.8914, 175.6497],
  [-39.8745, 175.6683],
  [-39.8736, 175.6698],
  [-39.8726, 175.6719],
  [-39.8702, 175.6749],
  [-39.8691, 175.6773],
  [-39.8690, 175.6795]
];

const makohineTunnel = [
  [-39.8690, 175.6795],
  [-39.8691, 175.6801],
  [-39.8693, 175.6809]
];

const makohineTunnelPowhaharoa = [
  [-39.8693, 175.6809],
  [-39.8703, 175.6850],
  [-39.8704, 175.6877],
  [-39.8700, 175.6898],
  [-39.8549, 175.7264],
  [-39.8479, 175.7374],
  [-39.8460, 175.7394],
  [-39.8416, 175.7421],
  [-39.8404, 175.7436],
  [-39.8397, 175.7455],
  [-39.8356, 175.7799],
  [-39.8349, 175.7817],
  [-39.8337, 175.7829],
  [-39.8323, 175.7831],
  [-39.8318, 175.7830]
];

const powhaharoaTunnel = [
  [-39.8318, 175.7830],
  [-39.8269, 175.7817]
];

const powhaharoaTunnelMangawekaDeviation = [
  [-39.8269, 175.7817],
  [-39.8259, 175.7814]
];

const oldStartKowhaiTunnel = [
  [-39.8259, 175.7814],
  [-39.8255, 175.7808],
  [-39.8251, 175.7800],
  [-39.8249, 175.7788],
  [-39.8245, 175.7778],
  [-39.8218, 175.7752],
  [-39.8196, 175.7748],
  [-39.8171, 175.7757],
  [-39.8154, 175.7769],
  [-39.8144, 175.7772],
  [-39.8137, 175.7778]
];

const kowhaiTunnel = [
  [-39.8137, 175.7778],
  [-39.8133, 175.7787]
];

const kowhaiTunnelMooseTunnel = [
  [-39.8133, 175.7787],
  [-39.8128, 175.7799],
  [-39.8118, 175.7815]
];

const mooseTunnel = [
  [-39.8118, 175.7815],
  [-39.8115, 175.7820]
];

const mooseTunnelElkTunnel = [
  [-39.8115, 175.7820],
  [-39.8109, 175.7831],
  [-39.8099, 175.7838],
  [-39.8092, 175.7839],
  [-39.8082, 175.7841],
  [-39.8075, 175.7850],
  [-39.8074, 175.7854]
];

const elkTunnel = [
  [-39.8074, 175.7854],
  [-39.8071, 175.7868]
];

const elkTunnelDeerTunnel = [
  [-39.8071, 175.7868],
  [-39.8068, 175.7875],
  [-39.8057, 175.7889]
];

const deerTunnel = [
  [-39.8057, 175.7889],
  [-39.8050, 175.7898]
];

const deerTunnelPossumTunnel = [
  [-39.8050, 175.7898],
  [-39.8042, 175.7910],
  [-39.8037, 175.7932],
  [-39.8027, 175.7947],
  [-39.8016, 175.7954],
  [-39.8008, 175.7961],
  [-39.7991, 175.7993],
  [-39.7982, 175.8004],
  [-39.7948, 175.8019],
  [-39.7927, 175.8024],
  [-39.7915, 175.8031],
  [-39.7907, 175.8039],
  [-39.7900, 175.8041],
  [-39.7896, 175.8041],
  [-39.7890, 175.8036],
  [-39.7886, 175.8031],
  [-39.7880, 175.8028],
  [-39.7873, 175.8029],
  [-39.7868, 175.8032],
  [-39.7867, 175.8033]
];

const possumTunnel = [
  [-39.7867, 175.8033],
  [-39.7861, 175.8039],
  [-39.7854, 175.8042]
];

const possumTunnelBlackTunnel = [
  [-39.7854, 175.8042],
  [-39.7854, 175.8042],
  [-39.7846, 175.8044],
  [-39.7834, 175.8039],
  [-39.7826, 175.8033],
  [-39.7817, 175.8031],
  [-39.7809, 175.8034]
];

const blackTunnel = [
  [-39.7809, 175.8034],
  [-39.7798, 175.8039],
  [-39.7793, 175.8039]
];

const blackTunnelBeaverTunnel = [
  [-39.7793, 175.8039],
  [-39.7786, 175.8038],
  [-39.7779, 175.8034],
  [-39.7770, 175.8033],
  [-39.7767, 175.8033]
];

const beaverTunnel = [
  [-39.7767, 175.8033],
  [-39.7749, 175.8037]
];

const beaverTunnelHedgehogTunnel = [
  [-39.7749, 175.8037],
  [-39.7742, 175.8046],
  [-39.7730, 175.8062],
  [-39.7729, 175.8063]
];

const hedgehogTunnel = [
  [-39.7729, 175.8063],
  [-39.7688, 175.8102]
];

const hedgehogTunnelOldEnd = [
  [-39.7688, 175.8102],
  [-39.7687, 175.8103],
  [-39.7678, 175.8121],
  [-39.7666, 175.8130],
  [-39.7653, 175.8138],
  [-39.7647, 175.8146],
  [-39.7645, 175.8163],
  [-39.7649, 175.8210],
  [-39.7648, 175.8222],
  [-39.7641, 175.8250],
  [-39.7637, 175.8258],
  [-39.7631, 175.8264]
];

const mangawekaDeviation = [
  [-39.8259, 175.7814],
  [-39.8175, 175.7791],
  [-39.8153, 175.7794],
  [-39.8132, 175.7810],
  [-39.8107, 175.7844],
  [-39.8073, 175.7883],
  [-39.8054, 175.7931],
  [-39.8050, 175.7966],
  [-39.8032, 175.8011],
  [-39.7960, 175.8104],
  [-39.7937, 175.8122],
  [-39.7867, 175.8141],
  [-39.7846, 175.8160],
  [-39.7837, 175.8193],
  [-39.7837, 175.8226],
  [-39.7827, 175.8255],
  [-39.7808, 175.8273],
  [-39.7787, 175.8275],
  [-39.7765, 175.8266],
  [-39.7742, 175.8252],
  [-39.7690, 175.8244],
  [-39.7668, 175.8248],
  [-39.7631, 175.8264]
];

const magawkaDeviationEndPitTunnel = [
  [-39.7631, 175.8264],
  [-39.7553, 175.8302],
  [-39.7541, 175.8316],
  [-39.7531, 175.8334],
  [-39.7506, 175.8356],
  [-39.7497, 175.8358],
  [-39.7486, 175.8357],
  [-39.7473, 175.8358],
  [-39.7450, 175.8365],
  [-39.7436, 175.8376],
  [-39.7424, 175.8396],
  [-39.7416, 175.8405],
  [-39.7334, 175.8441],
  [-39.7320, 175.8443],
  [-39.7135, 175.8389],
  [-39.7120, 175.8381],
  [-39.6988, 175.8269],
  [-39.6977, 175.8255],
  [-39.6906, 175.8104],
  [-39.6888, 175.8083],
  [-39.6862, 175.8075],
  [-39.6848, 175.8063],
  [-39.6843, 175.8048],
  [-39.6838, 175.8005],
  [-39.6827, 175.7983],
  [-39.6810, 175.7973],
  [-39.6768, 175.7970],
  [-39.6761, 175.7966],
  [-39.6756, 175.7958],
  [-39.6747, 175.7937],
  [-39.6739, 175.7925],
  [-39.6734, 175.7918],
  [-39.6725, 175.7909],
  [-39.6694, 175.7895],
  [-39.6681, 175.7881],
  [-39.6661, 175.7842],
  [-39.6643, 175.7824],
  [-39.6609, 175.7805],
  [-39.6594, 175.7789],
  [-39.6586, 175.7761],
  [-39.6574, 175.7745],
  [-39.6540, 175.7725],
  [-39.6530, 175.7712],
  [-39.6525, 175.7696],
  [-39.6525, 175.7674],
  [-39.6519, 175.7655],
  [-39.6509, 175.7646],
  [-39.6489, 175.7636],
  [-39.6484, 175.7629],
  [-39.6483, 175.7620],
  [-39.6486, 175.7609],
  [-39.6508, 175.7589],
  [-39.6514, 175.7575],
  [-39.6520, 175.7513],
  [-39.6520, 175.7500],
  [-39.6518, 175.7484],
  [-39.6521, 175.7464],
  [-39.6526, 175.7447],
  [-39.6533, 175.7405],
  [-39.6536, 175.7383],
  [-39.6534, 175.7367],
  [-39.6532, 175.7359]
];

const pitTunnel = [
  [-39.6532, 175.7359],
  [-39.6511, 175.7293]
];

const pitTunnelRabbitTunnel = [
  [-39.6511, 175.7293],
  [-39.6508, 175.7283],
  [-39.6504, 175.7255],
  [-39.6507, 175.7226],
  [-39.6505, 175.7211],
  [-39.6500, 175.7202],
  [-39.6466, 175.7165],
  [-39.6462, 175.7155],
  [-39.6462, 175.7145],
  [-39.6469, 175.7113],
  [-39.6469, 175.7102],
  [-39.6464, 175.7095],
  [-39.6449, 175.7086],
  [-39.6438, 175.7086],
  [-39.6412, 175.7103],
  [-39.6378, 175.7140],
  [-39.6373, 175.7143],
  [-39.6366, 175.7142],
  [-39.6351, 175.7128],
  [-39.6341, 175.7125],
  [-39.6333, 175.7128],
  [-39.6317, 175.7145],
  [-39.6311, 175.7158],
  [-39.6299, 175.7168],
  [-39.6290, 175.7171],
  [-39.6285, 175.7176],
  [-39.6282, 175.7183],
  [-39.6280, 175.7210],
  [-39.6283, 175.7225],
  [-39.6282, 175.7236],
  [-39.6276, 175.7243],
  [-39.6257, 175.7255],
  [-39.6250, 175.7257],
  [-39.6216, 175.7252],
  [-39.6209, 175.7248],
  [-39.6205, 175.7240],
  [-39.6201, 175.7228],
  [-39.6194, 175.7215],
  [-39.6167, 175.7191],
  [-39.6159, 175.7190],
  [-39.6152, 175.7194],
  [-39.6148, 175.7204],
  [-39.6144, 175.7227],
  [-39.6136, 175.7239],
  [-39.6122, 175.7245],
  [-39.6111, 175.7253],
  [-39.6102, 175.7255],
  [-39.6088, 175.7249],
  [-39.6079, 175.7238],
  [-39.6075, 175.7228],
  [-39.6067, 175.7202],
  [-39.6066, 175.7192],
  [-39.6070, 175.7183],
  [-39.6088, 175.7158],
  [-39.6110, 175.7117],
  [-39.6112, 175.7107],
  [-39.6112, 175.7087],
  [-39.6113, 175.7078],
  [-39.6115, 175.7071],
  [-39.6115, 175.7059],
  [-39.6099, 175.7026],
  [-39.6093, 175.7022],
  [-39.6085, 175.7022],
  [-39.6073, 175.7028],
  [-39.6066, 175.7034],
  [-39.6028, 175.7073],
  [-39.6012, 175.7079],
  [-39.5989, 175.7076],
  [-39.5977, 175.7081],
  [-39.5961, 175.7101],
  [-39.5944, 175.7113],
  [-39.5937, 175.7121],
  [-39.5929, 175.7140],
  [-39.5911, 175.7163],
  [-39.5896, 175.7167],
  [-39.5883, 175.7159],
  [-39.5873, 175.7159]
];

const rabbitTunnel = [
  [-39.5873, 175.7159],
  [-39.5869, 175.7162],
  [-39.5866, 175.7169]
];

const rabbitTunnelHapuawhenuaDeviationStart = [
  [-39.5866, 175.7169],
  [-39.5865, 175.7173],
  [-39.5864, 175.7189],
  [-39.5859, 175.7201],
  [-39.5853, 175.7210],
  [-39.5851, 175.7217],
  [-39.5853, 175.7238],
  [-39.5856, 175.7248],
  [-39.5860, 175.7252],
  [-39.5862, 175.7259],
  [-39.5861, 175.7277],
  [-39.5864, 175.7289],
  [-39.5869, 175.7298],
  [-39.5876, 175.7305],
  [-39.5880, 175.7314],
  [-39.5880, 175.7325],
  [-39.5872, 175.7333],
  [-39.5863, 175.7334],
  [-39.5857, 175.7331],
  [-39.5854, 175.7324],
  [-39.5850, 175.7294],
  [-39.5847, 175.7284],
  [-39.5836, 175.7269],
  [-39.5829, 175.7252],
  [-39.5822, 175.7243],
  [-39.5810, 175.7232],
  [-39.5806, 175.7224],
  [-39.5806, 175.7217],
  [-39.5813, 175.7194],
  [-39.5819, 175.7186],
  [-39.5823, 175.7176],
  [-39.5823, 175.7159],
  [-39.5820, 175.7148],
  [-39.5814, 175.7143],
  [-39.5809, 175.7134],
  [-39.5806, 175.7121],
  [-39.5801, 175.7112],
  [-39.5789, 175.7109],
  [-39.5779, 175.7114],
  [-39.5773, 175.7116],
  [-39.5749, 175.7113],
  [-39.5735, 175.7106],
  [-39.5719, 175.7090],
  [-39.5712, 175.7078],
  [-39.5687, 175.7018],
  [-39.5682, 175.6997],
  [-39.5674, 175.6984],
  [-39.5649, 175.6973],
  [-39.5639, 175.6973],
  [-39.5629, 175.6984],
  [-39.5586, 175.6994],
  [-39.5573, 175.6994],
  [-39.5503, 175.6967],
  [-39.5491, 175.6953],
  [-39.5481, 175.6919],
  [-39.5466, 175.6908],
  [-39.5454, 175.6909],
  [-39.5444, 175.6917],
  [-39.5429, 175.6919],
  [-39.5418, 175.6911],
  [-39.5406, 175.6913],
  [-39.5397, 175.6924],
  [-39.5391, 175.6958],
  [-39.5380, 175.6974],
  [-39.5359, 175.6981],
  [-39.5348, 175.6977],
  [-39.5341, 175.6965],
  [-39.5326, 175.6921],
  [-39.5313, 175.6906],
  [-39.5303, 175.6899],
  [-39.5294, 175.6888],
  [-39.5289, 175.6875],
  [-39.5280, 175.6867],
  [-39.5264, 175.6864],
  [-39.5255, 175.6858],
  [-39.5249, 175.6850],
  [-39.5240, 175.6846],
  [-39.5231, 175.6847],
  [-39.5219, 175.6854],
  [-39.5200, 175.6859],
  [-39.5182, 175.6858],
  [-39.5132, 175.6845],
  [-39.5111, 175.6835],
  [-39.5092, 175.6815],
  [-39.5064, 175.6760],
  [-39.5051, 175.6747],
  [-39.5033, 175.6741],
  [-39.5014, 175.6743],
  [-39.4993, 175.6738],
  [-39.4782, 175.6659],
  [-39.4760, 175.6654],
  [-39.4685, 175.6642],
  [-39.4669, 175.6632],
  [-39.4662, 175.6612],
  [-39.4664, 175.6592],
  [-39.4676, 175.6570],
  [-39.4692, 175.6557],
  [-39.4720, 175.6542],
  [-39.4729, 175.6531],
  [-39.4735, 175.6515],
  [-39.4736, 175.6492],
  [-39.4740, 175.6476],
  [-39.4753, 175.6450],
  [-39.4755, 175.6434],
  [-39.4753, 175.6413],
  [-39.4756, 175.6398],
  [-39.4769, 175.6388],
  [-39.4803, 175.6391],
  [-39.4820, 175.6386],
  [-39.4828, 175.6376],
  [-39.4830, 175.6361],
  [-39.4820, 175.6314],
  [-39.4811, 175.6298],
  [-39.4797, 175.6286],
  [-39.4787, 175.6268],
  [-39.4776, 175.6210],
  [-39.4771, 175.6195],
  [-39.4746, 175.6155],
  [-39.4736, 175.6135],
  [-39.4468, 175.5100],
  [-39.4470, 175.5055],
  [-39.4490, 175.5012],
  [-39.4490, 175.4976],
  [-39.4477, 175.4955],
  [-39.4463, 175.4941],
  [-39.4453, 175.4920],
  [-39.4435, 175.4851],
  [-39.4430, 175.4821],
  [-39.4416, 175.4798],
  [-39.4374, 175.4760],
  [-39.4357, 175.4738],
  [-39.4049, 175.4201],
  [-39.4013, 175.4138],
  [-39.4005, 175.4111],
  [-39.4002, 175.4091],
  [-39.3988, 175.4066],
  [-39.3964, 175.4047],
  [-39.3946, 175.4016],
  [-39.3929, 175.4005],
  [-39.3912, 175.4005],
  [-39.3892, 175.4015],
  [-39.3876, 175.4018],
  [-39.3867, 175.4014]
];

const oldStartMoleTunnel = [
  [-39.3867, 175.4014],
  [-39.3861, 175.4014],
  [-39.3848, 175.4017],
  [-39.3842, 175.4016],
  [-39.3833, 175.4005],
  [-39.3830, 175.3995],
  [-39.3830, 175.3988],
  [-39.3834, 175.3979],
  [-39.3841, 175.3967],
  [-39.3845, 175.3961]
];

const moleTunnel = [
  [-39.3845, 175.3961],
  [-39.3847, 175.3956],
  [-39.3849, 175.3945]
];

const moleTunnelOldEnd = [
  [-39.3849, 175.3945],
  [-39.3849, 175.3936],
  [-39.3847, 175.3927],
  [-39.3842, 175.3916],
  [-39.3833, 175.3907],
  [-39.3823, 175.3904],
  [-39.3788, 175.3900],
  [-39.3782, 175.3897],
  [-39.3780, 175.3894],
  [-39.3776, 175.3885],
  [-39.3775, 175.3877],
  [-39.3775, 175.3870]
];

const hapuawhenuaDeviation = [
  [-39.3867, 175.4014],
  [-39.3864, 175.4012],
  [-39.3857, 175.4005],
  [-39.3848, 175.3991],
  [-39.3845, 175.3978],
  [-39.3844, 175.3970],
  [-39.3844, 175.3961],
  [-39.3844, 175.3946],
  [-39.3835, 175.3920],
  [-39.3816, 175.3905],
  [-39.3801, 175.3901],
  [-39.3782, 175.3884],
  [-39.3775, 175.3870]
];

const hapuawhenuaDeviationEndUpperSpiralTunnel = [
  [-39.3775, 175.3870],
  [-39.3768, 175.3852],
  [-39.3752, 175.3828],
  [-39.3744, 175.3810],
  [-39.3738, 175.3780],
  [-39.3730, 175.3762],
  [-39.3713, 175.3738],
  [-39.3697, 175.3724],
  [-39.3639, 175.3701],
  [-39.3589, 175.3675],
  [-39.3555, 175.3678],
  [-39.3528, 175.3705],
  [-39.3515, 175.3736],
  [-39.3498, 175.3758],
  [-39.3482, 175.3766],
  [-39.3308, 175.3818],
  [-39.3228, 175.3855],
  [-39.3076, 175.3900],
  [-39.2848, 175.3901],
  [-39.2779, 175.3893],
  [-39.2694, 175.3904],
  [-39.2678, 175.3903],
  [-39.2560, 175.3876],
  [-39.2541, 175.3874],
  [-39.2478, 175.3883],
  [-39.2461, 175.3889],
  [-39.2396, 175.3934],
  [-39.2330, 175.3959],
  [-39.2314, 175.3970],
  [-39.2301, 175.3981],
  [-39.2283, 175.3987],
  [-39.2241, 175.3986],
  [-39.2220, 175.3992],
  [-39.2188, 175.4002],
  [-39.2163, 175.4000],
  [-39.2142, 175.4003],
  [-39.2103, 175.4019],
  [-39.2062, 175.4020],
  [-39.2043, 175.4008],
  [-39.2034, 175.3987],
  [-39.2034, 175.3964],
  [-39.2037, 175.3945],
  [-39.2032, 175.3919],
  [-39.2017, 175.3901],
  [-39.1994, 175.3897],
  [-39.1882, 175.3940],
  [-39.1862, 175.3942],
  [-39.1708, 175.3925],
  [-39.1694, 175.3919],
  [-39.1678, 175.3910],
  [-39.1647, 175.3910],
  [-39.1633, 175.3916],
  [-39.1594, 175.3923],
  [-39.1565, 175.3921],
  [-39.1511, 175.3948],
  [-39.1497, 175.3950],
  [-39.1480, 175.3947],
  [-39.1430, 175.3953],
  [-39.1418, 175.3959],
  [-39.1382, 175.4004],
  [-39.1379, 175.4017],
  [-39.1385, 175.4031],
  [-39.1397, 175.4035],
  [-39.1406, 175.4044],
  [-39.1406, 175.4058],
  [-39.1399, 175.4067],
  [-39.1354, 175.4076],
  [-39.1344, 175.4085],
  [-39.1335, 175.4095],
  [-39.1323, 175.4098],
  [-39.1289, 175.4067],
  [-39.1280, 175.4062],
  [-39.1273, 175.4064],
  [-39.1265, 175.4060],
  [-39.1255, 175.4053],
  [-39.1241, 175.4053],
  [-39.1231, 175.4060],
  [-39.1220, 175.4060],
  [-39.1196, 175.4050],
  [-39.1189, 175.4042],
  [-39.1187, 175.4030],
  [-39.1192, 175.4018],
  [-39.1202, 175.4013],
  [-39.1214, 175.4016],
  [-39.1220, 175.4024],
  [-39.1221, 175.4031]
];

const upperSpiralTunnel = [
  [-39.1221, 175.4031],
  [-39.1221, 175.4035],
  [-39.1219, 175.4042]
];

const upperSpiralTunnelLowerSpiralTunnel = [
  [-39.1219, 175.4042],
  [-39.1216, 175.4046],
  [-39.1214, 175.4048]
];

const lowerSpiralTunnel = [
  [-39.1214, 175.4048],
  [-39.1180, 175.4055]
];

const lowerSpiralTunnelPorootaraoDeviation = [
  [-39.1180, 175.4055],
  [-39.1173, 175.4055],
  [-39.1166, 175.4050],
  [-39.1163, 175.4043],
  [-39.1162, 175.4028],
  [-39.1168, 175.4005],
  [-39.1168, 175.3990],
  [-39.1174, 175.3978],
  [-39.1182, 175.3975],
  [-39.1191, 175.3979],
  [-39.1221, 175.4016],
  [-39.1229, 175.4020],
  [-39.1239, 175.4016],
  [-39.1243, 175.4005],
  [-39.1242, 175.3993],
  [-39.1233, 175.3985],
  [-39.1170, 175.3955],
  [-39.1153, 175.3959],
  [-39.1134, 175.3977],
  [-39.1118, 175.3985],
  [-39.1100, 175.3982],
  [-39.1085, 175.3971],
  [-39.1059, 175.3963],
  [-39.1048, 175.3954],
  [-39.1031, 175.3938],
  [-39.0967, 175.3909],
  [-39.0952, 175.3910],
  [-39.0931, 175.3923],
  [-39.0919, 175.3925],
  [-39.0906, 175.3920],
  [-39.0885, 175.3924],
  [-39.0867, 175.3921],
  [-39.0857, 175.3912],
  [-39.0836, 175.3846],
  [-39.0822, 175.3832],
  [-39.0794, 175.3825],
  [-39.0774, 175.3812],
  [-39.0737, 175.3806],
  [-39.0719, 175.3798],
  [-39.0701, 175.3800],
  [-39.0690, 175.3797],
  [-39.0677, 175.3791],
  [-39.0642, 175.3788],
  [-39.0616, 175.3802],
  [-39.0601, 175.3801],
  [-39.0572, 175.3790],
  [-39.0557, 175.3792],
  [-39.0545, 175.3796],
  [-39.0534, 175.3798],
  [-39.0429, 175.3782],
  [-39.0412, 175.3784],
  [-39.0291, 175.3818],
  [-39.0262, 175.3816],
  [-39.0230, 175.3795],
  [-39.0039, 175.3759],
  [-38.9960, 175.3775],
  [-38.9945, 175.3773],
  [-38.9930, 175.3770],
  [-38.9912, 175.3778],
  [-38.9889, 175.3807],
  [-38.9873, 175.3823],
  [-38.9864, 175.3835],
  [-38.9848, 175.3882],
  [-38.9812, 175.3933],
  [-38.9804, 175.3948],
  [-38.9802, 175.3970],
  [-38.9793, 175.3988],
  [-38.9756, 175.4006],
  [-38.9747, 175.4013],
  [-38.9742, 175.4020],
  [-38.9727, 175.4032],
  [-38.9718, 175.4045],
  [-38.9696, 175.4050],
  [-38.9673, 175.4045],
  [-38.9658, 175.4036],
  [-38.9630, 175.3992],
  [-38.9619, 175.3983],
  [-38.9595, 175.3980],
  [-38.9557, 175.3983],
  [-38.9541, 175.3988],
  [-38.9518, 175.3985],
  [-38.9503, 175.3978],
  [-38.9453, 175.3933],
  [-38.9436, 175.3928],
  [-38.9422, 175.3925],
  [-38.9409, 175.3908],
  [-38.9405, 175.3891],
  [-38.9396, 175.3878],
  [-38.9381, 175.3869],
  [-38.9367, 175.3849],
  [-38.9362, 175.3820],
  [-38.9356, 175.3803],
  [-38.9344, 175.3783],
  [-38.9325, 175.3728],
  [-38.9324, 175.3709],
  [-38.9327, 175.3679],
  [-38.9337, 175.3657],
  [-38.9360, 175.3631],
  [-38.9370, 175.3605],
  [-38.9367, 175.3577],
  [-38.9361, 175.3487],
  [-38.9349, 175.3461],
  [-38.9326, 175.3449],
  [-38.9312, 175.3452],
  [-38.9304, 175.3460],
  [-38.9296, 175.3482],
  [-38.9285, 175.3492],
  [-38.9264, 175.3492],
  [-38.9257, 175.3474],
  [-38.9250, 175.3440],
  [-38.9223, 175.3373],
  [-38.9207, 175.3356],
  [-38.9191, 175.3354],
  [-38.9169, 175.3368],
  [-38.9152, 175.3389],
  [-38.9144, 175.3405],
  [-38.9130, 175.3417],
  [-38.9112, 175.3418],
  [-38.9068, 175.3427],
  [-38.9036, 175.3424],
  [-38.8997, 175.3405],
  [-38.8981, 175.3389],
  [-38.8972, 175.3370],
  [-38.8938, 175.3331],
  [-38.8925, 175.3311],
  [-38.8835, 175.3067],
  [-38.8816, 175.3039],
  [-38.8809, 175.3017],
  [-38.8809, 175.3002],
  [-38.8813, 175.2979],
  [-38.8826, 175.2589],
  [-38.8819, 175.2530],
  [-38.8813, 175.2519],
  [-38.8805, 175.2513],
  [-38.8745, 175.2499],
  [-38.8699, 175.2470],
  [-38.8686, 175.2455],
  [-38.8647, 175.2380],
  [-38.8637, 175.2372],
  [-38.8625, 175.2370],
  [-38.8607, 175.2379],
  [-38.8596, 175.2379],
  [-38.8577, 175.2387],
  [-38.8562, 175.2386],
  [-38.8547, 175.2378],
  [-38.8527, 175.2364],
  [-38.8502, 175.2361],
  [-38.8477, 175.2371],
  [-38.8449, 175.2382],
  [-38.8438, 175.2382],
  [-38.8427, 175.2377],
  [-38.8371, 175.2338],
  [-38.8361, 175.2335],
  [-38.8348, 175.2334],
  [-38.8333, 175.2326],
  [-38.8307, 175.2299],
  [-38.8298, 175.2292],
  [-38.8256, 175.2269],
  [-38.8238, 175.2266],
  [-38.8183, 175.2276],
  [-38.8148, 175.2275],
  [-38.8137, 175.2277],
  [-38.8129, 175.2281],
  [-38.8116, 175.2279],
  [-38.8107, 175.2273],
  [-38.8099, 175.2258],
  [-38.8087, 175.2248],
  [-38.8069, 175.2244],
  [-38.8038, 175.2233],
  [-38.8022, 175.2234],
  [-38.8003, 175.2238],
  [-38.7992, 175.2240],
  [-38.7981, 175.2248],
  [-38.7972, 175.2265],
  [-38.7960, 175.2287],
  [-38.7946, 175.2298],
  [-38.7929, 175.2298],
  [-38.7914, 175.2291],
  [-38.7898, 175.2292],
  [-38.7883, 175.2304],
  [-38.7826, 175.2408],
  [-38.7814, 175.2420],
  [-38.7802, 175.2424],
  [-38.7789, 175.2423],
  [-38.7777, 175.2427],
  [-38.7675, 175.2484],
  [-38.7660, 175.2486],
  [-38.7645, 175.2482],
  [-38.7633, 175.2483],
  [-38.7584, 175.2507],
  [-38.7561, 175.2527],
  [-38.7538, 175.2536],
  [-38.7509, 175.2558],
  [-38.7490, 175.2588],
  [-38.7484, 175.2603],
  [-38.7474, 175.2615],
  [-38.7455, 175.2621],
  [-38.7392, 175.2641],
  [-38.7369, 175.2641],
  [-38.7285, 175.2623],
  [-38.7272, 175.2628],
  [-38.7264, 175.2638],
  [-38.7251, 175.2663],
  [-38.7240, 175.2670],
  [-38.7228, 175.2671],
  [-38.7217, 175.2680],
  [-38.7211, 175.2698],
  [-38.7196, 175.2720],
  [-38.7194, 175.2737],
  [-38.7199, 175.2750],
  [-38.7196, 175.2765],
  [-38.7129, 175.2863],
  [-38.7114, 175.2873],
  [-38.7101, 175.2875],
  [-38.7081, 175.2870],
  [-38.7071, 175.2872],
  [-38.7028, 175.2906],
  [-38.7012, 175.2909],
  [-38.7001, 175.2906],
  [-38.6992, 175.2907],
  [-38.6983, 175.2914],
  [-38.6929, 175.2988],
  [-38.6916, 175.2997],
  [-38.6904, 175.3003],
  [-38.6893, 175.3015],
  [-38.6884, 175.3044],
  [-38.6877, 175.3055],
  [-38.6867, 175.3060],
  [-38.6856, 175.3061],
  [-38.6835, 175.3063],
  [-38.6821, 175.3054],
  [-38.6808, 175.3052],
  [-38.6790, 175.3058],
  [-38.6719, 175.3057],
  [-38.6703, 175.3062],
  [-38.6676, 175.3083],
  [-38.6667, 175.3097],
  [-38.6659, 175.3125],
  [-38.6646, 175.3145],
  [-38.6614, 175.3166],
  [-38.6595, 175.3170],
  [-38.6579, 175.3166],
  [-38.6560, 175.3156],
  [-38.6551, 175.3145],
  [-38.6547, 175.3135],
  [-38.6545, 175.3121],
  [-38.6540, 175.3108],
  [-38.6529, 175.3092],
  [-38.6521, 175.3071],
  [-38.6508, 175.3058],
  [-38.6460, 175.3040],
  [-38.6451, 175.3031],
  [-38.6447, 175.3021],
  [-38.6439, 175.3012],
  [-38.6428, 175.3008],
  [-38.6396, 175.2986],
  [-38.6323, 175.2969],
  [-38.6311, 175.2970],
  [-38.6277, 175.2990],
  [-38.6195, 175.3058],
  [-38.6185, 175.3076],
  [-38.6182, 175.3097],
  [-38.6155, 175.3168],
  [-38.6145, 175.3181],
  [-38.6136, 175.3185],
  [-38.6124, 175.3187],
  [-38.6111, 175.3194],
  [-38.6102, 175.3206],
  [-38.6090, 175.3213],
  [-38.6059, 175.3220],
  [-38.6042, 175.3228],
  [-38.6027, 175.3227],
  [-38.6015, 175.3216],
  [-38.6009, 175.3196],
  [-38.6009, 175.3179],
  [-38.6007, 175.3165],
  [-38.5999, 175.3152],
  [-38.5939, 175.3097],
  [-38.5921, 175.3092],
  [-38.5902, 175.3095],
  [-38.5848, 175.3081],
  [-38.5836, 175.3073],
  [-38.5826, 175.3071],
  [-38.5813, 175.3074],
  [-38.5801, 175.3073],
  [-38.5789, 175.3077],
  [-38.5756, 175.3101],
  [-38.5746, 175.3116],
  [-38.5737, 175.3126],
  [-38.5685, 175.3158],
  [-38.5657, 175.3192],
  [-38.5648, 175.3199],
  [-38.5640, 175.3201]
];

const oldStartPorootaraoTunnelOld = [
  [-38.5640, 175.3201],
  [-38.5618, 175.3205],
  [-38.5613, 175.3204],
  [-38.5609, 175.3203]
];

const porootaraoTunnelOld = [
  [-38.5609, 175.3203],
  [-38.5518, 175.3158]
];

const porootaraoTunnelOldOldEnd = [
  [-38.5518, 175.3158],
  [-38.5501, 175.3149],
  [-38.5479, 175.3139],
  [-38.5470, 175.3138],
  [-38.5453, 175.3136]
];

const porootaraoDeviationStartPorootaraoTunnel = [
  [-38.5640, 175.3201],
  [-38.5632, 175.3202],
  [-38.5615, 175.3200]
];

const porootaraoTunnel = [
  [-38.5615, 175.3200],
  [-38.5502, 175.3137]
];

const porootaraoTunnelPorootaraoDeviationEnd = [
  [-38.5502, 175.3137],
  [-38.5495, 175.3134],
  [-38.5482, 175.3132],
  [-38.5453, 175.3136]
];

const poroOTaraoDeviationEndTeAwamutu = [
  [-38.5453, 175.3136],
  [-38.5421, 175.3141],
  [-38.5408, 175.3149],
  [-38.5398, 175.3168],
  [-38.5391, 175.3218],
  [-38.5385, 175.3234],
  [-38.5367, 175.3254],
  [-38.5349, 175.3260],
  [-38.5336, 175.3257],
  [-38.5324, 175.3258],
  [-38.5292, 175.3271],
  [-38.5277, 175.3271],
  [-38.5264, 175.3261],
  [-38.5248, 175.3238],
  [-38.5244, 175.3228],
  [-38.5235, 175.3189],
  [-38.5228, 175.3177],
  [-38.5217, 175.3171],
  [-38.5210, 175.3161],
  [-38.5195, 175.3131],
  [-38.5186, 175.3122],
  [-38.5180, 175.3110],
  [-38.5175, 175.3090],
  [-38.5143, 175.3031],
  [-38.5133, 175.3020],
  [-38.5108, 175.3004],
  [-38.4927, 175.2881],
  [-38.4915, 175.2876],
  [-38.4897, 175.2872],
  [-38.4880, 175.2864],
  [-38.4867, 175.2850],
  [-38.4838, 175.2805],
  [-38.4828, 175.2797],
  [-38.4814, 175.2792],
  [-38.4807, 175.2783],
  [-38.4805, 175.2771],
  [-38.4810, 175.2753],
  [-38.4808, 175.2743],
  [-38.4800, 175.2734],
  [-38.4755, 175.2725],
  [-38.4742, 175.2726],
  [-38.4683, 175.2751],
  [-38.4669, 175.2749],
  [-38.4657, 175.2734],
  [-38.4652, 175.2715],
  [-38.4643, 175.2705],
  [-38.4634, 175.2704],
  [-38.4628, 175.2700],
  [-38.4623, 175.2693],
  [-38.4619, 175.2677],
  [-38.4613, 175.2669],
  [-38.4604, 175.2663],
  [-38.4598, 175.2656],
  [-38.4596, 175.2645],
  [-38.4598, 175.2630],
  [-38.4595, 175.2620],
  [-38.4589, 175.2612],
  [-38.4582, 175.2597],
  [-38.4573, 175.2590],
  [-38.4556, 175.2587],
  [-38.4546, 175.2574],
  [-38.4547, 175.2560],
  [-38.4552, 175.2546],
  [-38.4553, 175.2534],
  [-38.4548, 175.2495],
  [-38.4542, 175.2486],
  [-38.4532, 175.2481],
  [-38.4496, 175.2438],
  [-38.4493, 175.2428],
  [-38.4490, 175.2407],
  [-38.4480, 175.2394],
  [-38.4464, 175.2386],
  [-38.4456, 175.2378],
  [-38.4432, 175.2345],
  [-38.4419, 175.2336],
  [-38.4403, 175.2333],
  [-38.4392, 175.2334],
  [-38.4380, 175.2338],
  [-38.4371, 175.2340],
  [-38.4356, 175.2337],
  [-38.4344, 175.2300],
  [-38.4313, 175.2290],
  [-38.4287, 175.2279],
  [-38.4262, 175.2285],
  [-38.4227, 175.2312],
  [-38.4210, 175.2317],
  [-38.4140, 175.2319],
  [-38.4128, 175.2315],
  [-38.4120, 175.2306],
  [-38.4116, 175.2294],
  [-38.4109, 175.2281],
  [-38.4094, 175.2267],
  [-38.4082, 175.2261],
  [-38.4071, 175.2245],
  [-38.4066, 175.2228],
  [-38.4056, 175.2216],
  [-38.4045, 175.2211],
  [-38.4036, 175.2204],
  [-38.4030, 175.2194],
  [-38.4024, 175.2187],
  [-38.4013, 175.2185],
  [-38.4001, 175.2188],
  [-38.3975, 175.2202],
  [-38.3962, 175.2200],
  [-38.3952, 175.2190],
  [-38.3949, 175.2175],
  [-38.3949, 175.2161],
  [-38.3948, 175.2147],
  [-38.3902, 175.2046],
  [-38.3899, 175.2030],
  [-38.3886, 175.2003],
  [-38.3877, 175.1993],
  [-38.3867, 175.1989],
  [-38.3859, 175.1981],
  [-38.3851, 175.1965],
  [-38.3843, 175.1956],
  [-38.3787, 175.1921],
  [-38.3666, 175.1873],
  [-38.3643, 175.1869],
  [-38.3626, 175.1872],
  [-38.3612, 175.1879],
  [-38.3596, 175.1882],
  [-38.3583, 175.1879],
  [-38.3572, 175.1872],
  [-38.3555, 175.1863],
  [-38.3536, 175.1861],
  [-38.3506, 175.1853],
  [-38.3498, 175.1848],
  [-38.3493, 175.1839],
  [-38.3489, 175.1815],
  [-38.3482, 175.1802],
  [-38.3471, 175.1795],
  [-38.3457, 175.1794],
  [-38.3450, 175.1791],
  [-38.3428, 175.1773],
  [-38.3419, 175.1761],
  [-38.3403, 175.1723],
  [-38.3377, 175.1681],
  [-38.3282, 175.1576],
  [-38.3249, 175.1547],
  [-38.3227, 175.1537],
  [-38.3181, 175.1533],
  [-38.3079, 175.1517],
  [-38.3062, 175.1519],
  [-38.3051, 175.1523],
  [-38.2898, 175.1625],
  [-38.2875, 175.1631],
  [-38.2837, 175.1630],
  [-38.2811, 175.1641],
  [-38.2789, 175.1659],
  [-38.2689, 175.1706],
  [-38.2670, 175.1722],
  [-38.2594, 175.1832],
  [-38.2563, 175.1857],
  [-38.2499, 175.1918],
  [-38.2471, 175.1937],
  [-38.2453, 175.1952],
  [-38.2385, 175.2028],
  [-38.2372, 175.2037],
  [-38.2307, 175.2068],
  [-38.2293, 175.2071],
  [-38.2245, 175.2069],
  [-38.2221, 175.2061],
  [-38.2162, 175.2052],
  [-38.2107, 175.2059],
  [-38.2088, 175.2054],
  [-38.2068, 175.2038],
  [-38.2045, 175.2034],
  [-38.1993, 175.2049],
  [-38.1981, 175.2055],
  [-38.1847, 175.2135],
  [-38.1839, 175.2141],
  [-38.1828, 175.2158],
  [-38.1817, 175.2167],
  [-38.1771, 175.2190],
  [-38.1743, 175.2213],
  [-38.1729, 175.2222],
  [-38.1707, 175.2228],
  [-38.1628, 175.2268],
  [-38.1610, 175.2287],
  [-38.1496, 175.2434],
  [-38.1425, 175.2483],
  [-38.1411, 175.2488],
  [-38.1396, 175.2488],
  [-38.1377, 175.2495],
  [-38.1366, 175.2505],
  [-38.1350, 175.2514],
  [-38.1334, 175.2515],
  [-38.1295, 175.2506],
  [-38.1275, 175.2511],
  [-38.1260, 175.2524],
  [-38.1244, 175.2551],
  [-38.1231, 175.2564],
  [-38.1209, 175.2578],
  [-38.1197, 175.2590],
  [-38.1180, 175.2614],
  [-38.1018, 175.2745],
  [-38.1008, 175.2757],
  [-38.1000, 175.2776],
  [-38.0987, 175.2795],
  [-38.0925, 175.2835],
  [-38.0890, 175.2840],
  [-38.0802, 175.2828],
  [-38.0777, 175.2837],
  [-38.0759, 175.2858],
  [-38.0748, 175.2881],
  [-38.0734, 175.2898],
  [-38.0713, 175.2908],
  [-38.0683, 175.2912],
  [-38.0665, 175.2916],
  [-38.0622, 175.2936],
  [-38.0594, 175.2940],
  [-38.0575, 175.2950],
  [-38.0510, 175.3010],
  [-38.0477, 175.3031],
  [-38.0454, 175.3049],
  [-38.0438, 175.3055],
  [-38.0418, 175.3056],
  [-38.0394, 175.3052],
  [-38.0381, 175.3051],
  [-38.0136, 175.3095],
  [-38.0107, 175.3091],
  [-38.0084, 175.3095]
];

const teAwamutuFrankton = [
  [-38.0084, 175.3095],
  [-38.0065, 175.3100],
  [-38.0053, 175.3101],
  [-37.9870, 175.3084],
  [-37.9790, 175.3082],
  [-37.9644, 175.3038],
  [-37.9632, 175.3036],
  [-37.9561, 175.3041],
  [-37.9548, 175.3040],
  [-37.9527, 175.3032],
  [-37.9510, 175.3027],
  [-37.9472, 175.3025],
  [-37.9440, 175.3021],
  [-37.9427, 175.3024],
  [-37.9414, 175.3032],
  [-37.9401, 175.3038],
  [-37.9279, 175.3063],
  [-37.9268, 175.3062],
  [-37.9257, 175.3057],
  [-37.9219, 175.3034],
  [-37.9184, 175.3020],
  [-37.8200, 175.2671],
  [-37.8167, 175.2661],
  [-37.8119, 175.2655],
  [-37.7957, 175.2658],
  [-37.7927, 175.2660],
  [-37.7913, 175.2654],
  [-37.7898, 175.2639]
];

const franktonTeRapa = [
  [-37.7898, 175.2639],
  [-37.7848, 175.2567],
  [-37.7795, 175.2503],
  [-37.7770, 175.2479],
  [-37.7697, 175.2442]
];

const teRapaHorotiu = [
  [-37.7697, 175.2442],
  [-37.7570, 175.2372],
  [-37.7524, 175.2337],
  [-37.7460, 175.2269],
  [-37.7414, 175.2216],
  [-37.7363, 175.2174],
  [-37.7214, 175.2091],
  [-37.7126, 175.2056],
  [-37.7104, 175.2040],
  [-37.7036, 175.1966],
  [-37.7011, 175.1930]
];

const horotiuNgaruawahia = [
  [-37.7011, 175.1930],
  [-37.6787, 175.1630],
  [-37.6723, 175.1519],
  [-37.6711, 175.1505],
  [-37.6678, 175.1486]
];

const ngaruawahiaHuntly = [
  [-37.6678, 175.1486],
  [-37.6661, 175.1477],
  [-37.6652, 175.1477],
  [-37.6645, 175.1478],
  [-37.6612, 175.1493],
  [-37.6594, 175.1503],
  [-37.6577, 175.1517],
  [-37.6449, 175.1650],
  [-37.6350, 175.1735],
  [-37.6333, 175.1753],
  [-37.6246, 175.1854],
  [-37.6234, 175.1864],
  [-37.6220, 175.1870],
  [-37.6152, 175.1892],
  [-37.6136, 175.1895],
  [-37.6122, 175.1892],
  [-37.6105, 175.1884],
  [-37.6093, 175.1872],
  [-37.6088, 175.1859],
  [-37.6080, 175.1829],
  [-37.6067, 175.1796],
  [-37.6047, 175.1766],
  [-37.6030, 175.1735],
  [-37.6017, 175.1716],
  [-37.6012, 175.1701],
  [-37.6009, 175.1685],
  [-37.6006, 175.1675],
  [-37.5999, 175.1658],
  [-37.5988, 175.1642],
  [-37.5940, 175.1605],
  [-37.5921, 175.1597],
  [-37.5905, 175.1595],
  [-37.5847, 175.1598],
  [-37.5832, 175.1596],
  [-37.5815, 175.1593],
  [-37.5806, 175.1593],
  [-37.5785, 175.1598],
  [-37.5765, 175.1600],
  [-37.5733, 175.1594],
  [-37.5707, 175.1585],
  [-37.5695, 175.1584],
  [-37.5640, 175.1597],
  [-37.5623, 175.1598],
  [-37.5578, 175.1596]
];

const huntlyOhinewai = [
  [-37.5578, 175.1596],
  [-37.5549, 175.1593],
  [-37.5497, 175.1587],
  [-37.5438, 175.1585],
  [-37.5411, 175.1586],
  [-37.5346, 175.1594],
  [-37.5312, 175.1594],
  [-37.5298, 175.1598],
  [-37.5245, 175.1626],
  [-37.5221, 175.1635],
  [-37.5196, 175.1638],
  [-37.5178, 175.1646],
  [-37.5166, 175.1655],
  [-37.5154, 175.1660],
  [-37.5128, 175.1667],
  [-37.5111, 175.1669],
  [-37.5085, 175.1670],
  [-37.5069, 175.1668],
  [-37.4981, 175.1637],
  [-37.4890, 175.1614]
];

const ohinewaiTeKauwhata = [
  [-37.4890, 175.1614],
  [-37.4698, 175.1590],
  [-37.4535, 175.1538],
  [-37.4362, 175.1501],
  [-37.4343, 175.1499],
  [-37.4319, 175.1499],
  [-37.4298, 175.1491],
  [-37.4260, 175.1462],
  [-37.4240, 175.1441],
  [-37.4224, 175.1432],
  [-37.4205, 175.1430],
  [-37.4188, 175.1432],
  [-37.4175, 175.1431],
  [-37.4158, 175.1425],
  [-37.4145, 175.1425],
  [-37.4135, 175.1428],
  [-37.4114, 175.1437],
  [-37.4100, 175.1439],
  [-37.4077, 175.1436],
  [-37.4061, 175.1428],
  [-37.4015, 175.1394],
  [-37.3990, 175.1382]
];

const teKauwhataAmokura = [
  [-37.3990, 175.1382],
  [-37.3979, 175.1377],
  [-37.3960, 175.1360],
  [-37.3941, 175.1333],
  [-37.3927, 175.1322],
  [-37.3865, 175.1299],
  [-37.3846, 175.1300],
  [-37.3825, 175.1306],
  [-37.3812, 175.1307],
  [-37.3766, 175.1307],
  [-37.3739, 175.1312],
  [-37.3713, 175.1312],
  [-37.3695, 175.1306],
  [-37.3587, 175.1235],
  [-37.3525, 175.1198],
  [-37.3513, 175.1184],
  [-37.3487, 175.1145],
  [-37.3374, 175.0996],
  [-37.3339, 175.0937],
  [-37.3330, 175.0930],
  [-37.3314, 175.0925],
  [-37.3306, 175.0921],
  [-37.3260, 175.0885],
  [-37.3249, 175.0880],
  [-37.3239, 175.0878],
  [-37.3232, 175.0874],
  [-37.3226, 175.0864],
  [-37.3207, 175.0806],
  [-37.3199, 175.0795],
  [-37.3185, 175.0788],
  [-37.3139, 175.0789],
  [-37.3121, 175.0786],
  [-37.3111, 175.0781],
  [-37.3104, 175.0773],
  [-37.3099, 175.0765]
];

const amokuraMercerTunnel = [
  [-37.3099, 175.0765],
  [-37.3071, 175.0723],
  [-37.3064, 175.0715],
  [-37.2952, 175.0615],
  [-37.2933, 175.0595],
  [-37.2924, 175.0582],
  [-37.2914, 175.0572],
  [-37.2888, 175.0549],
  [-37.2848, 175.0519],
  [-37.2820, 175.0492],
  [-37.2806, 175.0484]
];

const mercerTunnel = [
  [-37.2806, 175.0484],
  [-37.2799, 175.0482]
];

const mercerTunnelMercer = [
  [-37.2799, 175.0482],
  [-37.2775, 175.0477]
];

const mercerPokeno = [
  [-37.2775, 175.0477],
  [-37.2658, 175.0451],
  [-37.2651, 175.0451],
  [-37.2632, 175.0453],
  [-37.2620, 175.0451],
  [-37.2597, 175.0443],
  [-37.2577, 175.0428],
  [-37.2540, 175.0386],
  [-37.2531, 175.0368],
  [-37.2525, 175.0345],
  [-37.2476, 175.0207]
];

const pokenoPukekohe = [
  [-37.2476, 175.0207],
  [-37.2451, 175.0137],
  [-37.2446, 175.0113],
  [-37.2449, 175.0085],
  [-37.2467, 175.0043],
  [-37.2479, 175.0029],
  [-37.2490, 175.0024],
  [-37.2507, 175.0025],
  [-37.2545, 175.0042],
  [-37.2557, 175.0043],
  [-37.2571, 175.0038],
  [-37.2582, 175.0027],
  [-37.2588, 175.0013],
  [-37.2599, 174.9978],
  [-37.2600, 174.9959],
  [-37.2598, 174.9942],
  [-37.2609, 174.9698],
  [-37.2612, 174.9663],
  [-37.2615, 174.9607],
  [-37.2614, 174.9590],
  [-37.2604, 174.9535],
  [-37.2566, 174.9473],
  [-37.2561, 174.9458],
  [-37.2559, 174.9436],
  [-37.2555, 174.9420],
  [-37.2534, 174.9381],
  [-37.2502, 174.9335],
  [-37.2482, 174.9317],
  [-37.2424, 174.9296],
  [-37.2400, 174.9280],
  [-37.2383, 174.9275],
  [-37.2295, 174.9275],
  [-37.2242, 174.9272],
  [-37.2230, 174.9269],
  [-37.2217, 174.9260],
  [-37.2201, 174.9243],
  [-37.2187, 174.9236],
  [-37.2167, 174.9231],
  [-37.2148, 174.9219],
  [-37.2035, 174.9105]
];

export const northIslandMainTrunk: Line = {
  name: 'North Island Main Trunk',
  state: 'NZ',
  segments: [
    {
      segments: [waikanaeOtaki],
      history: {
        opened: {
          date: '1886-11-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [tokomaruOtaki],
      history: {
        opened: {
          date: '1886-08-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [longburnTokomaru],
      history: {
        opened: {
          date: '1885-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [palmerstonNorthFoxtonJunction],
      history: {
        opened: {
          date: '1876-04-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [manawatuJunctionPalmerstonNorth],
      history: {
        opened: {
          date: '1878-04-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [palmerstonShared],
      history: {
        opened: {
          date: '1878-04-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [milsonDeviationUnelectrified, milsonJunction],
      history: {
        opened: {
          date: '1959-07-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [milsonDeviationElectrified],
      history: {
        opened: {
          date: '1959-07-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-06-24',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [palmerstonMarton],
      history: {
        opened: {
          date: '1878-04-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-06-24',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [palmerstonEastJunction],
      history: {
        opened: {
          date: '1878-04-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        martonMoaDeviationStart,
        {
          date: '1985',
          original: [
            deviationStartMoaTunnel,
            {
              name: 'Moa Tunnel',
              segment: moaTunnel,
              type: 'tunnel'
            },
            moaTunnelKiwiTunnel,
            {
              name: 'Kiwi Tunnel', //FIX this was daylighted in 1972
              segment: kiwiTunnel,
              type: 'tunnel'
            },
            kiwiTunnelDeviationEnd
          ],
          deviation: [moaDeviation]
        },
        moaDeviationEndMakohineTunnel,
        {
          date: '1984-01-01',
          original: [{
            name: 'Makohine Tunnel',
            segment: makohineTunnel,
            type: 'tunnel'
          }],
          deviation: [makohineTunnel]
        },
        makohineTunnelPowhaharoa,
        {
          name: 'Powhaharoa Tunnel',
          segment: powhaharoaTunnel,
          type: 'tunnel'
        },
        powhaharoaTunnelMangawekaDeviation,
        {
          date: '1981-01-01',
          original: [
            oldStartKowhaiTunnel,
            {
              name: 'Kowhai Tunnel',
              segment: kowhaiTunnel,
              type: 'tunnel'
            },
            kowhaiTunnelMooseTunnel,
            {
              name: 'Moose Tunnel',
              segment: mooseTunnel,
              type: 'tunnel'
            },
            mooseTunnelElkTunnel,
            {
              name: 'Elk Tunnel',
              segment: elkTunnel,
              type: 'tunnel'
            },
            elkTunnelDeerTunnel,
            {
              name: 'Deer Tunnel',
              segment: deerTunnel,
              type: 'tunnel'
            },
            deerTunnelPossumTunnel,
            {
              name: 'Possum Tunnel',
              segment: possumTunnel,
              type: 'tunnel'
            },
            possumTunnelBlackTunnel,
            {
              name: 'Black Tunnel',
              segment: blackTunnel,
              type: 'tunnel'
            },
            blackTunnelBeaverTunnel,
            {
              name: 'Beaver Tunnel',
              segment: beaverTunnel,
              type: 'tunnel'
            },
            beaverTunnelHedgehogTunnel,
            {
              name: 'Hedgehog Tunnel',
              segment: hedgehogTunnel,
              type: 'tunnel'
            },
            hedgehogTunnelOldEnd
          ],
          deviation: [mangawekaDeviation]
        },
        magawkaDeviationEndPitTunnel,
        {
          name: 'Pit Tunnel',
          segment: pitTunnel,
          type: 'tunnel'
        },
        pitTunnelRabbitTunnel,
        {
          name: 'Rabbit Tunnel',
          segment: rabbitTunnel,
          type: 'tunnel'
        },
        rabbitTunnelHapuawhenuaDeviationStart,
        {
          date: '1987-01-01',
          original: [
            oldStartMoleTunnel,
            {
              name: 'Mole Tunnel',
              segment: moleTunnel,
              type: 'tunnel'
            },
            moleTunnelOldEnd
          ],
          deviation: [hapuawhenuaDeviation]
        },
        hapuawhenuaDeviationEndUpperSpiralTunnel,
        {
          name: 'Upper Spiral Tunnel',
          segment: upperSpiralTunnel,
          type: 'tunnel'
        },
        upperSpiralTunnelLowerSpiralTunnel,
        {
          name: 'Lower Spiral Tunnel',
          segment: lowerSpiralTunnel,
          type: 'tunnel'
        },
        lowerSpiralTunnelPorootaraoDeviation,
        {
          date: '1980-01-01',
          original: [
            oldStartPorootaraoTunnelOld,
            {
              name: 'Porootarao Tunnel (Old)',
              segment: porootaraoTunnelOld,
              type: 'tunnel'
            },
            porootaraoTunnelOldOldEnd
          ],
          deviation: [
            porootaraoDeviationStartPorootaraoTunnel,
            {
              name: 'Porootarao Tunnel',
              segment: porootaraoTunnel,
              type: 'tunnel'
            },
            porootaraoTunnelPorootaraoDeviationEnd
          ]
        },
        poroOTaraoDeviationEndTeAwamutu
      ],
      history: {
        opened: {
          date: '1908-08-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-06-24',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [teAwamutuFrankton],
      history: {
        opened: {
          date: '1880-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-06-24',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [franktonTeRapa],
      history: {
        opened: {
          date: '1877-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          tracks: 2
        }, {
          date: '1988-06-24',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [teRapaHorotiu],
      history: {
        opened: {
          date: '1877-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [horotiuNgaruawahia],
      history: {
        opened: {
          date: '1877-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-12-05',
          tracks: 2
        }]
      }
    },
    {
      segments: [ngaruawahiaHuntly],
      history: {
        opened: {
          date: '1877-08-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1938-12-04',
          tracks: 2
        }]
      }
    },
    {
      segments: [huntlyOhinewai],
      history: {
        opened: {
          date: '1877-08-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1939-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [ohinewaiTeKauwhata],
      history: {
        opened: {
          date: '1877-08-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-12-14',
          tracks: 2
        }]
      }
    },
    {
      segments: [teKauwhataAmokura],
      history: {
        opened: {
          date: '1875-05-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        amokuraMercerTunnel,
        {
          date: '1956-01-01',
          original: [{
            name: 'Mercer Tunnel',
            segment: mercerTunnel,
            type: 'tunnel'
          }],
          deviation: [mercerTunnel]
        },
        mercerTunnelMercer
      ],
      history: {
        opened: {
          date: '1875-05-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-07-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [mercerPokeno],
      history: {
        opened: {
          date: '1875-05-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-11-11',
          tracks: 2
        }]
      }
    },
    {
      segments: [pokenoPukekohe],
      history: {
        opened: {
          date: '1875-05-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-11-21',
          tracks: 2
        }]
      }
    }
  ]
}
