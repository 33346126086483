import { Line } from "../../trackTypes";

const mtBarkerMtBarkerJunction = [
  [-35.0640, 138.8639],
  [-35.0629, 138.8626],
  [-35.0545, 138.8570],
  [-35.0537, 138.8568],
  [-35.0529, 138.8568],
  [-35.0512, 138.8573],
  [-35.0503, 138.8575],
  [-35.0496, 138.8581],
  [-35.0491, 138.8592],
  [-35.0488, 138.8608],
  [-35.0484, 138.8617],
  [-35.0477, 138.8623],
  [-35.0462, 138.8633],
  [-35.0450, 138.8638],
  [-35.0438, 138.8639],
  [-35.0376, 138.8635],
  [-35.0366, 138.8633],
  [-35.0358, 138.8628],
  [-35.0330, 138.8610],
  [-35.0321, 138.8606],
  [-35.0311, 138.8606],
  [-35.0279, 138.8614],
  [-35.0265, 138.8612],
  [-35.0256, 138.86061]
];

const mtBarkerStrathalbyn = [
  [-35.0640, 138.8639],
  [-35.0660, 138.8663],
  [-35.0666, 138.8666],
  [-35.0676, 138.8668],
  [-35.0825, 138.8630],
  [-35.0847, 138.8632],
  [-35.0998, 138.8691],
  [-35.1006, 138.8694],
  [-35.1015, 138.8692],
  [-35.1058, 138.8678],
  [-35.1069, 138.8676],
  [-35.1109, 138.8683],
  [-35.1119, 138.8689],
  [-35.1126, 138.8696],
  [-35.1134, 138.8701],
  [-35.1144, 138.8702],
  [-35.1217, 138.8686],
  [-35.1226, 138.8681],
  [-35.1242, 138.8667],
  [-35.1252, 138.8662],
  [-35.1264, 138.8663],
  [-35.1275, 138.8668],
  [-35.1282, 138.8678],
  [-35.1308, 138.8733],
  [-35.1316, 138.8741],
  [-35.1326, 138.8745],
  [-35.1336, 138.8743],
  [-35.1350, 138.8738],
  [-35.1358, 138.8736],
  [-35.1370, 138.8741],
  [-35.1397, 138.8772],
  [-35.1406, 138.8778],
  [-35.1417, 138.8779],
  [-35.1427, 138.8776],
  [-35.1445, 138.8762],
  [-35.1453, 138.8758],
  [-35.1462, 138.8757],
  [-35.1471, 138.8761],
  [-35.1499, 138.8785],
  [-35.1510, 138.8789],
  [-35.1522, 138.8789],
  [-35.1536, 138.8785],
  [-35.1545, 138.8785],
  [-35.1557, 138.8794],
  [-35.1564, 138.8806],
  [-35.1571, 138.8830],
  [-35.1583, 138.8848],
  [-35.1595, 138.8858],
  [-35.1616, 138.8869],
  [-35.1626, 138.8870],
  [-35.1632, 138.8868],
  [-35.1640, 138.8864],
  [-35.1649, 138.8862],
  [-35.1674, 138.8867],
  [-35.1686, 138.8874],
  [-35.1695, 138.8882],
  [-35.1702, 138.8884],
  [-35.1714, 138.8883],
  [-35.1725, 138.8888],
  [-35.1748, 138.8912],
  [-35.1760, 138.8936],
  [-35.1767, 138.8965],
  [-35.1771, 138.8975],
  [-35.1782, 138.8981],
  [-35.1793, 138.8981],
  [-35.1807, 138.8973],
  [-35.1816, 138.8971],
  [-35.1825, 138.8972],
  [-35.1836, 138.8978],
  [-35.1845, 138.8978],
  [-35.1861, 138.8973],
  [-35.1868, 138.8973],
  [-35.1880, 138.8982],
  [-35.1885, 138.8994],
  [-35.1886, 138.9008],
  [-35.1888, 138.9016],
  [-35.1898, 138.9027],
  [-35.1907, 138.9030],
  [-35.1919, 138.9027],
  [-35.1929, 138.9026],
  [-35.1939, 138.9033],
  [-35.1945, 138.9045],
  [-35.1946, 138.9057],
  [-35.1942, 138.9068],
  [-35.1937, 138.9075],
  [-35.1934, 138.9084],
  [-35.1933, 138.9096],
  [-35.1936, 138.9106],
  [-35.1946, 138.9115],
  [-35.1956, 138.9117],
  [-35.1968, 138.9115],
  [-35.1984, 138.9116],
  [-35.1993, 138.9121],
  [-35.2004, 138.9123],
  [-35.2030, 138.9120],
  [-35.2039, 138.9121],
  [-35.2051, 138.9130],
  [-35.2058, 138.9142],
  [-35.2061, 138.9159],
  [-35.2066, 138.9170],
  [-35.2082, 138.9194],
  [-35.2091, 138.9200],
  [-35.2101, 138.9200],
  [-35.2109, 138.9197],
  [-35.2114, 138.9190],
  [-35.2119, 138.9179],
  [-35.2125, 138.9172],
  [-35.2133, 138.9169],
  [-35.2140, 138.9168],
  [-35.2164, 138.9176],
  [-35.2174, 138.9175],
  [-35.2182, 138.9171],
  [-35.2187, 138.9167],
  [-35.2210, 138.9146],
  [-35.2218, 138.9142],
  [-35.2227, 138.9142],
  [-35.2236, 138.9146],
  [-35.2263, 138.9170],
  [-35.2274, 138.9175],
  [-35.2304, 138.9175],
  [-35.2317, 138.9172],
  [-35.2400, 138.9135],
  [-35.2415, 138.9125],
  [-35.2565, 138.8995],
  [-35.2575, 138.8981],
  [-35.2587, 138.8952],
  [-35.2591, 138.8946],
  [-35.2613, 138.8920]
];

const strathalbynGoolwa = [
  [-35.2613, 138.8920],
  [-35.2637, 138.8897],
  [-35.2650, 138.8889],
  [-35.2668, 138.8885],
  [-35.2686, 138.8888],
  [-35.2712, 138.8904],
  [-35.2745, 138.8917],
  [-35.2786, 138.8920],
  [-35.2832, 138.8914],
  [-35.3344, 138.8715],
  [-35.3541, 138.8614],
  [-35.3554, 138.8606],
  [-35.3679, 138.8476],
  [-35.3854, 138.8207],
  [-35.3875, 138.8183],
  [-35.3889, 138.8157],
  [-35.3903, 138.8114],
  [-35.3931, 138.8064],
  [-35.4076, 138.7929],
  [-35.4083, 138.7924],
  [-35.4110, 138.7915],
  [-35.4119, 138.7914],
  [-35.4147, 138.7923],
  [-35.4169, 138.7939],
  [-35.4180, 138.7943],
  [-35.4195, 138.7942],
  [-35.4210, 138.7933],
  [-35.4465, 138.7650],
  [-35.4472, 138.7640],
  [-35.4486, 138.7612],
  [-35.4501, 138.7594],
  [-35.4520, 138.7584],
  [-35.4605, 138.7560],
  [-35.4612, 138.7559],
  [-35.4622, 138.7561],
  [-35.4632, 138.7566],
  [-35.4718, 138.7656],
  [-35.4737, 138.7670],
  [-35.4956, 138.7784],
  [-35.4965, 138.7794],
  [-35.4968, 138.7801],
  [-35.4972, 138.7828],
  [-35.4975, 138.7837],
  [-35.4980, 138.7846],
  [-35.5000, 138.7863],
  [-35.5007, 138.7867],
  [-35.5016, 138.7868],
  [-35.5024, 138.7867],
  [-35.5045, 138.7854]
];

const victorHarborPortElliot = [
  [-35.5560, 138.6244],
  [-35.5545, 138.6242],
  [-35.5519, 138.6240],
  [-35.5507, 138.6242],
  [-35.5475, 138.6257],
  [-35.5467, 138.6263],
  [-35.5460, 138.6273],
  [-35.5451, 138.6285],
  [-35.5435, 138.6295],
  [-35.5428, 138.6303],
  [-35.5421, 138.6315],
  [-35.5419, 138.6325],
  [-35.5419, 138.6335],
  [-35.5421, 138.6347],
  [-35.5420, 138.6352],
  [-35.5415, 138.6386],
  [-35.5412, 138.6395],
  [-35.5402, 138.6413],
  [-35.5389, 138.6455],
  [-35.5372, 138.6538],
  [-35.5365, 138.6588],
  [-35.5362, 138.6601],
  [-35.5356, 138.6617],
  [-35.5353, 138.6633],
  [-35.5354, 138.6645],
  [-35.5357, 138.6671],
  [-35.5353, 138.6768],
  [-35.5350, 138.6778],
  [-35.5337, 138.6792],
  [-35.5327, 138.6806]
];

const portElliotGoolwa = [
  [-35.5327, 138.6806],
  [-35.5303, 138.6854],
  [-35.5298, 138.6861],
  [-35.5217, 138.6910],
  [-35.5198, 138.6924],
  [-35.5178, 138.6945],
  [-35.5166, 138.6963],
  [-35.5090, 138.7087],
  [-35.5077, 138.7116],
  [-35.5070, 138.7144],
  [-35.5015, 138.7750],
  [-35.5016, 138.7772],
  [-35.5021, 138.7783],
  [-35.5027, 138.7789],
  [-35.5059, 138.7800],
  [-35.5067, 138.7807],
  [-35.5072, 138.7818],
  [-35.5071, 138.7829],
  [-35.5066, 138.7839],
  [-35.5045, 138.7854]
];

const victorHarborVictorHarborPort = [
  [-35.55598, 138.62437],
  [-35.55609, 138.62444],
  [-35.55637, 138.62452],
  [-35.55696, 138.62491],
  [-35.55738, 138.62523],
  [-35.55753, 138.62540],
  [-35.55764, 138.62563],
  [-35.55785, 138.62588],
  [-35.56161, 138.62897],
  [-35.56183, 138.62920],
  [-35.56205, 138.62954],
  [-35.56220, 138.62992],
  [-35.56222, 138.63016],
  [-35.56222, 138.63063],
  [-35.56227, 138.63092],
  [-35.56237, 138.63115],
  [-35.56253, 138.63145],
  [-35.56271, 138.63164],
  [-35.56303, 138.63183],
  [-35.56321, 138.63202],
  [-35.56329, 138.63226],
  [-35.56336, 138.63262],
  [-35.56370, 138.63388],
  [-35.56388, 138.63449]
];

export const victorHarbor: Line = {
  name: 'Victor Harbor',
  state: 'SA',
  segments: [
    {
      segments: [mtBarkerStrathalbyn],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          status: 'closed'
        }, {
          date: '1991-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [mtBarkerMtBarkerJunction],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          status: 'closed'
        }, {
          date: '1991-01-01',
          status: 'open'
        }, {
          date: '2007-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [strathalbynGoolwa],
      history: {
        opened: {
          date: '1869-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1884-12-01',
          trackType: 'heavy'
        }]
      }
    },
    {
      segments: [victorHarborPortElliot],
      history: {
        opened: {
          date: '1864-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1884-12-01',
          trackType: 'heavy'
        }]
      }
    },
    {
      segments: [victorHarborVictorHarborPort],
      history: {
        opened: {
          date: '1867-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'tram'
        }
      }
    },
    {
      segments: [portElliotGoolwa],
      history: {
        opened: {
          date: '1854-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1884-12-01',
          trackType: 'heavy'
        }]
      }
    }
  ]
};
