import { Line } from "../../trackTypes";

const line = [
  [-35.5137, 147.0349],
  [-35.5124, 147.0346],
  [-35.5116, 147.0341],
  [-35.5080, 147.0307],
  [-35.5060, 147.0282],
  [-35.4973, 147.0128],
  [-35.4956, 147.0092],
  [-35.4948, 147.0053],
  [-35.4939, 146.9687],
  [-35.4933, 146.9637],
  [-35.4927, 146.9603],
  [-35.4929, 146.9570],
  [-35.4938, 146.9542],
  [-35.5015, 146.9380],
  [-35.5153, 146.9196],
  [-35.5163, 146.9160],
  [-35.5164, 146.9135],
  [-35.5158, 146.9113],
  [-35.5104, 146.8896],
  [-35.5090, 146.8870],
  [-35.5012, 146.8772],
  [-35.4979, 146.8750],
  [-35.4942, 146.8705],
  [-35.4861, 146.8562],
  [-35.4845, 146.8507],
  [-35.4843, 146.8458],
  [-35.4833, 146.8425],
  [-35.4814, 146.8405],
  [-35.4790, 146.8398],
  [-35.4764, 146.8402],
  [-35.4743, 146.8398],
  [-35.4725, 146.8383],
  [-35.4713, 146.8362],
  [-35.4652, 146.8222],
  [-35.4641, 146.8187],
  [-35.4621, 146.8049],
  [-35.4611, 146.8021],
  [-35.4577, 146.7965],
  [-35.4571, 146.7942],
  [-35.4569, 146.7912],
  [-35.4580, 146.7831],
  [-35.4579, 146.7800],
  [-35.4566, 146.7757],
  [-35.4565, 146.7728],
  [-35.4573, 146.7704],
  [-35.4595, 146.7675],
  [-35.4630, 146.7648],
  [-35.4664, 146.7636],
  [-35.4784, 146.7607],
  [-35.4804, 146.7590],
  [-35.4819, 146.7560],
  [-35.4919, 146.6987],
  [-35.4936, 146.6946],
  [-35.5196, 146.6623],
  [-35.5410, 146.6208],
  [-35.5431, 146.6184],
  [-35.5646, 146.6064],
  [-35.5776, 146.5906],
  [-35.5915, 146.5782]
];

export const rand: Line = {
  name: 'Rand',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1920-05-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-09',
          status: 'closed'
        }]
      }
    }
  ]
};
