import { Line } from "../../trackTypes";

const line = [
  [-34.5823, 142.7688],
  [-34.5620, 142.7616],
  [-34.5601, 142.7619],
  [-34.5425, 142.7716],
  [-34.5406, 142.7734],
  [-34.5389, 142.7757],
  [-34.5288, 142.7911],
  [-34.5139, 142.8338],
  [-34.5100, 142.8589],
  [-34.5092, 142.8853],
  [-34.5084, 142.8890],
  [-34.5029, 142.9000],
  [-34.4169, 142.9934],
  [-34.4150, 142.9960],
  [-34.4065, 143.0115]
];

export const lette: Line = {
  name: 'Lette',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1932-02-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1943-02-12',
          status: 'closed'
        }]
      }
    }
  ]
};
