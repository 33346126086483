import { Line } from "../../trackTypes";

const smithtonJunction = [
  [-40.8372, 145.1177],
  [-40.8306, 145.1096]
];

const junctionPelicanPoint = [
  [-40.8306, 145.1096],
  [-40.8293, 145.1081],
  [-40.8283, 145.1072],
  [-40.8237, 145.1054],
  [-40.8126, 145.1049],
  [-40.8058, 145.1034]
];

export const pelicanPoint: Line = {
  name: 'Pelican Point',
  state: 'TAS',
  segments: [
    {
      segments: [smithtonJunction],
      history: {
        opened: {
          date: '1903-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1924-01-01',
          trackType: 'heavy'
        }, {
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [junctionPelicanPoint],
      history: {
        opened: {
          date: '1903-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1940-02-15',
          status: 'closed'
        }]
      }
    }
  ]
}
