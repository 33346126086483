import { Line } from "../../trackTypes";

const line = [
  [-43.0379, 147.2731],
  [-43.0353, 147.2691],
  [-43.0340, 147.2665],
  [-43.0335, 147.2656],
  [-43.0322, 147.2649],
  [-43.0309, 147.2643],
  [-43.0308, 147.2641],
  [-43.0307, 147.2637],
  [-43.0309, 147.2634],
  [-43.0314, 147.2628],
  [-43.0317, 147.2623],
  [-43.0322, 147.2608],
  [-43.0325, 147.2603],
  [-43.0333, 147.2595],
  [-43.0342, 147.2577],
  [-43.0346, 147.2574],
  [-43.0350, 147.2567],
  [-43.0352, 147.2561],
  [-43.0354, 147.2551],
  [-43.0357, 147.2545],
  [-43.0366, 147.2534],
  [-43.0385, 147.2520],
  [-43.0412, 147.2500],
  [-43.0423, 147.2496],
  [-43.0437, 147.2497],
  [-43.0447, 147.2493],
  [-43.0458, 147.2477],
  [-43.0458, 147.2470],
  [-43.0454, 147.2463],
  [-43.0445, 147.2461],
  [-43.0438, 147.2456],
  [-43.0436, 147.2442],
  [-43.0448, 147.2411],
  [-43.0462, 147.2388],
  [-43.0473, 147.2355],
  [-43.0474, 147.2333],
  [-43.0471, 147.2328],
  [-43.0460, 147.2323],
  [-43.0438, 147.2334],
  [-43.0430, 147.2333],
  [-43.0424, 147.2329],
  [-43.0409, 147.2300],
  [-43.0406, 147.2288],
  [-43.0407, 147.2273],
  [-43.0413, 147.2243],
  [-43.0411, 147.2230],
  [-43.0403, 147.2205],
  [-43.0397, 147.2202],
  [-43.0394, 147.2204],
  [-43.0389, 147.2209],
  [-43.0385, 147.2211],
  [-43.0380, 147.2208],
  [-43.0355, 147.2163],
  [-43.0353, 147.2155],
  [-43.0355, 147.2142],
  [-43.0362, 147.2130],
  [-43.0380, 147.2102],
  [-43.0411, 147.2083],
  [-43.0416, 147.2076],
  [-43.0417, 147.2072],
  [-43.0414, 147.2070],
  [-43.0409, 147.2071],
  [-43.0406, 147.2068],
  [-43.0406, 147.2064],
  [-43.0409, 147.2059],
  [-43.0414, 147.2044],
  [-43.0412, 147.2025],
  [-43.0402, 147.2019],
  [-43.0396, 147.2024],
  [-43.0388, 147.2051],
  [-43.0375, 147.2063],
  [-43.0365, 147.2064],
  [-43.0347, 147.2061],
  [-43.0347, 147.2041],
  [-43.0347, 147.2032],
  [-43.0344, 147.2032],
  [-43.0341, 147.2040],
  [-43.0333, 147.2042],
  [-43.0329, 147.2052],
  [-43.0317, 147.2068],
  [-43.0307, 147.2068],
  [-43.0299, 147.2076],
  [-43.0280, 147.2084],
  [-43.0273, 147.2081],
  [-43.0270, 147.2075],
  [-43.0271, 147.2066],
  [-43.0287, 147.2031],
  [-43.0288, 147.2025],
  [-43.0287, 147.2019],
  [-43.0291, 147.2003],
  [-43.0291, 147.1999],
  [-43.0288, 147.1998],
  [-43.0284, 147.1997],
  [-43.0283, 147.1995],
  [-43.0283, 147.1990],
  [-43.0282, 147.1987],
  [-43.0279, 147.1987],
  [-43.0276, 147.1989],
  [-43.0267, 147.1988],
  [-43.0263, 147.1991],
  [-43.0250, 147.1989],
  [-43.0246, 147.1991],
  [-43.0246, 147.1995],
  [-43.0247, 147.1999],
  [-43.0246, 147.2004],
  [-43.0241, 147.2007],
  [-43.0237, 147.2005],
  [-43.0231, 147.2000],
  [-43.0224, 147.1998],
  [-43.0210, 147.1990],
  [-43.0207, 147.1987],
  [-43.0203, 147.1979],
  [-43.0200, 147.1975],
  [-43.0199, 147.1969],
  [-43.0199, 147.1962],
  [-43.0207, 147.1948],
  [-43.0210, 147.1938],
  [-43.0214, 147.1932],
  [-43.0220, 147.1929],
  [-43.0223, 147.1925],
  [-43.0225, 147.1906],
  [-43.0227, 147.1902],
  [-43.0248, 147.1895],
  [-43.0261, 147.1889],
  [-43.0270, 147.1880],
  [-43.0284, 147.1859],
  [-43.0286, 147.1856],
  [-43.0298, 147.1849],
  [-43.0301, 147.1846],
  [-43.0301, 147.1843],
  [-43.0299, 147.1841],
  [-43.0296, 147.1839],
  [-43.0286, 147.1839],
  [-43.0267, 147.1843],
  [-43.0264, 147.1842],
  [-43.0262, 147.1842],
  [-43.0259, 147.1841],
  [-43.0255, 147.1843],
  [-43.0249, 147.1844],
  [-43.0246, 147.1846],
  [-43.0244, 147.1850],
  [-43.0240, 147.1853],
  [-43.0234, 147.1853],
  [-43.0229, 147.1850],
  [-43.0225, 147.1844],
  [-43.0221, 147.1833],
  [-43.0215, 147.1823],
  [-43.0213, 147.1812],
  [-43.0213, 147.1808],
  [-43.0210, 147.1803],
  [-43.0207, 147.1802],
  [-43.0199, 147.1805],
  [-43.0195, 147.1804],
  [-43.0193, 147.1800],
  [-43.0194, 147.1791],
  [-43.0192, 147.1776],
  [-43.0189, 147.1768],
  [-43.0188, 147.1756],
  [-43.0187, 147.1753],
  [-43.0181, 147.1749],
  [-43.0178, 147.1744],
  [-43.0175, 147.1723],
  [-43.0175, 147.1718],
  [-43.0176, 147.1712],
  [-43.0183, 147.1699],
  [-43.0183, 147.1694],
  [-43.0183, 147.1689],
  [-43.0181, 147.1682],
  [-43.0183, 147.1674],
  [-43.0194, 147.1665],
  [-43.0206, 147.1642],
  [-43.0211, 147.1630],
  [-43.0220, 147.1603],
  [-43.0225, 147.1592],
  [-43.0244, 147.1563],
  [-43.0248, 147.1546],
  [-43.0243, 147.1527],
  [-43.0245, 147.1504]
];

export const sandfly: Line = {
  name: 'Snadfly Tram',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1907-02-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1922-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
