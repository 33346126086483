import { east1 } from "./melbourne/east1";
import { east10 } from "./melbourne/east10";
import { east11 } from "./melbourne/east11";
import { east12 } from "./melbourne/east12";
import { east13 } from "./melbourne/east13";
import { east2 } from "./melbourne/east2";
import { east3 } from "./melbourne/east3";
import { east4 } from "./melbourne/east4";
import { east5 } from "./melbourne/east5";
import { east6 } from "./melbourne/east6";
import { east7 } from "./melbourne/east7";
import { east8 } from "./melbourne/east8";
import { east9 } from "./melbourne/east9";
import { footscrayNorth } from "./melbourne/footscrayNorth";
import { footscraySouth } from "./melbourne/footscraySouth";
import { footscrayWest } from "./melbourne/footscrayWest";
import { north1 } from "./melbourne/north1";
import { north2 } from "./melbourne/north2";
import { north3 } from "./melbourne/north3";
import { north4 } from "./melbourne/north4";
import { north5 } from "./melbourne/north5";
import { north6 } from "./melbourne/north6";
import { north7 } from "./melbourne/north7";
import { north8 } from "./melbourne/north8";
import { south1 } from "./melbourne/south1";
import { south2 } from "./melbourne/south2";
import { south3 } from "./melbourne/south3";
import { south4 } from "./melbourne/south4";
import { south5 } from "./melbourne/south5";
import { south6 } from "./melbourne/south6";
import { south7 } from "./melbourne/south7";
import { west1 } from "./melbourne/west1";
import { west2 } from "./melbourne/west2";
import { west3 } from "./melbourne/west3";
import { melbourneIndependentTrams } from "./melbourne/melbourneIndependentTrams";
import { melbourneVicRailTrams } from "./melbourne/melbourneTramsVicRail";

export const melbourneTrams = [
  east1,
  north1,
  east2,
  north2,
  north3,
  north4,
  east3,
  south1,
  south2,
  north5,
  north6,
  east4,
  west1,
  west2,
  south3,
  south4,
  south5,
  west3,
  east5,
  east6,
  east7,
  south6,
  east8,
  east9,
  east10,
  east11,
  east12,
  east13,
  north7,
  footscrayNorth,
  footscraySouth,
  footscrayWest,
  north8,
  south7,

  melbourneIndependentTrams,
  melbourneVicRailTrams
];
