import { Line } from "../../../trackTypes";

const line = [
  [-34.1868, 150.9955],
  [-34.1874, 150.9950],
  [-34.1876, 150.9943],
  [-34.1875, 150.9918],
  [-34.1873, 150.9909]
];

const originalJunction = [
  [-34.1852, 150.9955],
  [-34.1860, 150.9957],
  [-34.1868, 150.9955]
];

const deviatedJunction = [
  [-34.1842, 151.0030],
  [-34.1846, 151.0023],
  [-34.1851, 151.0019],
  [-34.1856, 151.0015],
  [-34.1861, 151.0007],
  [-34.1861, 150.9998],
  [-34.1857, 150.9986],
  [-34.1856, 150.9975],
  [-34.1858, 150.9966],
  [-34.1862, 150.9960],
  [-34.1869, 150.9954],
  [-34.1868, 150.9955]
];

export const helensburughColliery: Line = {
  name: 'Helensburugh Colliery',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1890-02-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [originalJunction],
      history: {
        opened: {
          date: '1890-02-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-05-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [deviatedJunction],
      history: {
        opened: {
          date: '1915-05-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
