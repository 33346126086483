import { Line } from "../../../trackTypes";

const pittStreet = [
  [-33.8616, 151.2092],
  [-33.8688, 151.2083],
  [-33.8767, 151.2078],
  [-33.8807, 151.2067],
  [-33.8815, 151.2059]
];

const pittStreetCentralHorseAllignment = [
  [-33.8815, 151.2059],
  [-33.8827, 151.2080]
];

const pittStreetConnection = [
  [-33.8815, 151.2059],
  [-33.8828, 151.2041]
];

const castlereaghStreet = [
  [-33.8616, 151.2092],
  [-33.8616, 151.2105],
  [-33.8642, 151.2103],
  [-33.8647, 151.2110],
  [-33.8658, 151.2101],
  [-33.8767, 151.2087],
  [-33.8782, 151.2086],
  [-33.8819, 151.2079],
  [-33.8826, 151.2072],
  [-33.8820, 151.2061],
  [-33.8808, 151.2070],
  [-33.8801, 151.2072],
  [-33.8800, 151.2068]
];

const fortMacquarie = [
  [-33.8612, 151.2083],
  [-33.8615, 151.2130],
  [-33.8589, 151.2135],
  [-33.8583, 151.2141],
  [-33.8564, 151.2146]
];

const elizabethStreet = [
  [-33.8828, 151.2083],
  [-33.8802, 151.2089],
  [-33.8797, 151.2091],
  [-33.8733, 151.2099],
  [-33.8691, 151.2103],
  [-33.8688, 151.2104],
  [-33.8663, 151.2107]
];

const elizabethExtension = [
  [-33.8663, 151.2107],
  [-33.8659, 151.2111],
  [-33.8650, 151.2115],
  [-33.8633, 151.2118],
  [-33.8617, 151.2121],
  [-33.8616, 151.2112],
  [-33.8634, 151.2111],
  [-33.8633, 151.2118]
];

const georgeStreetSouth = [
  [-33.8828, 151.2041],
  [-33.8810, 151.2047]
];

const georgeStreet = [
  [-33.8810, 151.2047],
  [-33.8789, 151.2054],
  [-33.8775, 151.2057],
  [-33.8741, 151.2068],
  [-33.8726, 151.2070],
  [-33.8685, 151.2069],
  [-33.8659, 151.2072],
  [-33.8634, 151.2075],
  [-33.8626, 151.2075],
  [-33.8622, 151.2077],
  [-33.8612, 151.2083]
];

const millersPoint = [
  [-33.8612, 151.2083],
  [-33.8602, 151.2086],
  [-33.8591, 151.2088],
  [-33.8583, 151.2091],
  [-33.8580, 151.2090],
  [-33.8566, 151.2081],
  [-33.8563, 151.2074],
  [-33.8582, 151.2054],
  [-33.8581, 151.2043],
  [-33.8584, 151.2043],
  [-33.8585, 151.2052],
  [-33.8582, 151.2054]
];

const shelleyStreet = [
  [-33.8669, 151.2019],
  [-33.8668, 151.2025],
  [-33.8688, 151.2027]
];

const kingStreet = [
  [-33.8688, 151.2027],
  [-33.8687, 151.2034],
  [-33.8687, 151.2046],
  [-33.8686, 151.2061],
  [-33.8687, 151.2075],
  [-33.8691, 151.2104],
  [-33.8693, 151.2114],
  [-33.8697, 151.2123],
  [-33.8704, 151.2129],
  [-33.8715, 151.2128]
];

export const sydneyTramsCbd: Line = {
  name: 'Sydney Trams (CBD)',
  state: 'NSW',
  segments: [
    {
      segments: [pittStreet],
      history: {
        opened: {
          date: '1861-12-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1901-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1866-12-01',
          status: 'closed'
        }, {
          date: '1957-09-27',
          status: 'closed'
        }, {
          date: '1901-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [pittStreetCentralHorseAllignment],
      history: {
        opened: {
          date: '1861-12-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1866-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pittStreetConnection],
      history: {
        opened: {
          date: '1901-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [castlereaghStreet],
      history: {
        opened: {
          date: '1901-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elizabethStreet],
      history: {
        opened: {
          date: '1879-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elizabethExtension],
      history: {
        opened: {
          date: '1880-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [shelleyStreet],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingStreet],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1898-10-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [georgeStreetSouth],
      history: {
        opened: {
          date: '1879-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1899-12-08',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [georgeStreet],
      history: {
        opened: {
          date: '1879-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1899-12-08',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }, {
          date: '2019-12-14',
          status: 'open'
        }]
      }
    },
    {
      segments: [millersPoint],
      history: {
        opened: {
          date: '1901-08-16',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fortMacquarie],
      history: {
        opened: {
          date: '1902-08-10',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1955-10-22',
          status: 'closed'
        }]
      }
    }
  ]
};
