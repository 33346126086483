import { Line } from "../../trackTypes";

const line = [
  [-32.8511, 115.9207],
  [-32.8519, 115.9203],
  [-32.8526, 115.9196],
  [-32.8553, 115.9157],
  [-32.8557, 115.9147],
  [-32.8558, 115.9133],
  [-32.8527, 115.9035],
  [-32.8524, 115.9020],
  [-32.8523, 115.8631],
  [-32.8535, 115.8589],
  [-32.8536, 115.8492],
  [-32.8528, 115.8459],
  [-32.8503, 115.8412],
  [-32.8430, 115.8341],
  [-32.8397, 115.8320],
  [-32.8383, 115.8306],
  [-32.8375, 115.8289],
  [-32.8369, 115.8274],
  [-32.8359, 115.8263],
  [-32.8349, 115.8252],
  [-32.8330, 115.8248],
  [-32.8319, 115.8241],
  [-32.8308, 115.8229],
  [-32.8259, 115.8119],
  [-32.8256, 115.8090],
  [-32.8267, 115.8026],
  [-32.8267, 115.7994],
  [-32.8263, 115.7683],
  [-32.8217, 115.7515],
  [-32.8209, 115.7475],
  [-32.8207, 115.7375],
  [-32.8201, 115.7362],
  [-32.8179, 115.7330]
];

export const lakeClifton: Line = {
  name: 'Lake Clifton',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1924-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
