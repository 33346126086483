import { Line } from "../../../trackTypes";

const marrickvilleJunctionOldBotanyYardDuplicated = [
  [-33.9152, 151.1587],
  [-33.9151, 151.1595],
  [-33.9149, 151.1603],
  [-33.9145, 151.1615],
  [-33.9144, 151.1625],
  [-33.9147, 151.1636],
  [-33.9153, 151.1643],
  [-33.9176, 151.1657],
  [-33.9182, 151.1662],
  [-33.9205, 151.1685],
  [-33.9216, 151.1697],
  [-33.9254, 151.1749],
  [-33.9258, 151.1758],
  [-33.9259, 151.1770],
  [-33.9256, 151.1783],
  [-33.9255, 151.1791],
  [-33.9262, 151.1821],
  [-33.9265, 151.1830],
  [-33.9272, 151.1837],
  [-33.9286, 151.1841]
];

const marrickvilleJunctionOldBotanyYardSingle = [
  [-33.9286, 151.1841],
  [-33.9297, 151.1840],
  [-33.9304, 151.1841],
  [-33.9311, 151.1846],
  [-33.9314, 151.1851],
  [-33.9316, 151.1860],
  [-33.9325, 151.1915],
  [-33.9329, 151.1925],
  [-33.9336, 151.1932],
  [-33.9344, 151.1935],
  [-33.9357, 151.1938],
  [-33.9365, 151.1944],
  [-33.9371, 151.1953],
  [-33.9375, 151.1968],
  [-33.9378, 151.1998],
  [-33.9382, 151.2015],
  [-33.9393, 151.2040],
  [-33.9403, 151.2053],
  [-33.9467, 151.2101],
  [-33.9488, 151.2126],
  [-33.9497, 151.2136],
  [-33.9559, 151.2190]
];

const oldBotanyYardClosed = [
  [-33.9559, 151.2190],
  [-33.9617, 151.2250],
  [-33.9624, 151.2255],
  [-33.9670, 151.2280],
  [-33.9689, 151.2282],
  [-33.9701, 151.2279],
  [-33.9724, 151.2270]
];

const cooksRiverJunctionCooksRiver = [
  [-33.9205, 151.1685],
  [-33.9208, 151.1696],
  [-33.9209, 151.1700],
  [-33.9208, 151.1708],
  [-33.9200, 151.1733],
  [-33.9178, 151.1762]
];

const botanyYardPortBotany = [
  [-33.9559, 151.2190],
  [-33.9569, 151.2192],
  [-33.9601, 151.2185],
  [-33.9611, 151.2187],
  [-33.9621, 151.2193],
  [-33.9627, 151.2195],
  [-33.9632, 151.2195],
  [-33.9639, 151.2196]
];

const portBotanyBunnerongPowerStation = [
  [-33.9632, 151.2195],
  [-33.9640, 151.2200],
  [-33.9645, 151.2206],
  [-33.9654, 151.2231],
  [-33.9660, 151.2241],
  [-33.9669, 151.2255],
  [-33.9675, 151.2260],
  [-33.9682, 151.2264],
  [-33.9699, 151.2262]
];

const portBotanyAnlTerminal = [
  [-33.9639, 151.2196],
  [-33.9645, 151.2191],
  [-33.9650, 151.2183],
  [-33.9650, 151.2174],
  [-33.9648, 151.2167],
  [-33.9637, 151.2148],
  [-33.9635, 151.2142],
  [-33.9636, 151.2136],
  [-33.9655, 151.2058]

];

const portBotanyCtalTerminal = [
  [-33.9639, 151.2196],
  [-33.9650, 151.2195],
  [-33.9680, 151.2209],
  [-33.9686, 151.2210],
  [-33.9725, 151.2217]

];

export const botanyGoods: Line = {
  name: 'Botany Goods',
  state: 'NSW',
  segments: [
    {
      segments: [marrickvilleJunctionOldBotanyYardDuplicated],
      history: {
        opened: {
          date: '1925-10-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [marrickvilleJunctionOldBotanyYardSingle],
      history: {
        opened: {
          date: '1925-10-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2024-02-16',
          tracks: 2
        }]
      }
    },
    {
      segments: [oldBotanyYardClosed],
      history: {
        opened: {
          date: '1925-10-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-12-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cooksRiverJunctionCooksRiver],
      history: {
        opened: {
          date: '1947-12-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [botanyYardPortBotany],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2024-02-16',
          tracks: 2
        }]
      }
    },
    {
      segments: [portBotanyBunnerongPowerStation],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portBotanyAnlTerminal, portBotanyCtalTerminal],
      history: {
        opened: {
          date: '1979-12-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
