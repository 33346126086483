import { Line } from "../../trackTypes";

const redcliffsHeritage = [
  [-34.3175, 142.1860],
  [-34.3194, 142.1857],
  [-34.3207, 142.1853],
  [-34.3220, 142.1845]
];

const heritage = [
  [-34.3220, 142.1845],
  [-34.3235, 142.1833],
  [-34.3246, 142.1810],
  [-34.3252, 142.1791],
  [-34.3264, 142.1727]
];

const heritageLoop = [
  [-34.3220, 142.1845],
  [-34.3219, 142.1847],
  [-34.3219, 142.1849],
  [-34.3220, 142.1853],
  [-34.3219, 142.1857],
  [-34.3217, 142.1858],
  [-34.3208, 142.1858],
  [-34.3207, 142.1857],
  [-34.3206, 142.1856],
  [-34.3206, 142.1854],
  [-34.3208, 142.1852],
  [-34.3220, 142.1845]
];

const heritageWerrimul = [
  [-34.3264, 142.1727],
  [-34.3408, 142.0949],
  [-34.3413, 142.0889],
  [-34.3462, 142.0631],
  [-34.3472, 142.0533],
  [-34.3561, 142.0075],
  [-34.3590, 141.9772],
  [-34.3666, 141.9328],
  [-34.3667, 141.9298],
  [-34.3657, 141.9128],
  [-34.3662, 141.8646],
  [-34.3724, 141.7588],
  [-34.3798, 141.6904],
  [-34.3792, 141.6575],
  [-34.3804, 141.6473],
  [-34.3815, 141.6176],
  [-34.3837, 141.6091],
  [-34.3866, 141.5959]
];

const werrimullMeringur = [
  [-34.3866, 141.5959],
  [-34.4014, 141.5366],
  [-34.4052, 141.4970],
  [-34.4050, 141.4942],
  [-34.4036, 141.4807],
  [-34.4038, 141.4767],
  [-34.4078, 141.4536],
  [-34.4078, 141.4491],
  [-34.3971, 141.3823],
  [-34.3976, 141.3706],
  [-34.3904, 141.3343]
];

const meringurMorkalla = [
  [-34.3904, 141.3343],
  [-34.3868, 141.3170],
  [-34.3865, 141.3060],
  [-34.3844, 141.2944],
  [-34.3822, 141.2767],
  [-34.3801, 141.2681],
  [-34.3781, 141.2504],
  [-34.3752, 141.2373],
  [-34.3739, 141.2216],
  [-34.3747, 141.2062],
  [-34.3720, 141.1845],
  [-34.3721, 141.1641]
];

export const morkalla: Line = {
  name: 'Morkalla',
  state: 'VIC',
  segments: [
    {
      segments: [redcliffsHeritage],
      history: {
        opened: {
          date: '1924-04-11',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-12-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heritage],
      history: {
        opened: {
          date: '1924-04-11',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-12-09',
          status: 'closed'
        }, {
          date: '1995-01-29',
          status: 'open',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [heritageLoop],
      history: {
        opened: {
          date: '1995-01-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [heritageWerrimul],
      history: {
        opened: {
          date: '1924-04-11',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-12-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [werrimullMeringur],
      history: {
        opened: {
          date: '1925-10-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-12-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [meringurMorkalla],
      history: {
        opened: {
          date: '1931-06-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-06-02',
          status: 'closed'
        }]
      }
    }
  ]
}
