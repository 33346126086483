import { Line } from "../../../trackTypes";

const balmoralLine = [
  [-33.83156, 151.24438],
  [-33.83168, 151.24442],
  [-33.83204, 151.24467],
  [-33.83211, 151.24474],
  [-33.83281, 151.24553],
  [-33.83299, 151.24581],
  [-33.83306, 151.24597],
  [-33.83318, 151.24650],
  [-33.83319, 151.24666],
  [-33.83316, 151.24959],
  [-33.83316, 151.24971],
  [-33.83314, 151.24978],
  [-33.83307, 151.24983],
  [-33.83294, 151.24984],
  [-33.83271, 151.24985],
  [-33.83138, 151.25014],
  [-33.83132, 151.25022],
  [-33.83132, 151.25038],
  [-33.83145, 151.25099],
  [-33.83142, 151.25115],
  [-33.83131, 151.25129],
  [-33.83033, 151.25149],
  [-33.83024, 151.25158],
  [-33.83013, 151.25179],
  [-33.83001, 151.25194],
  [-33.82980, 151.25195],
  [-33.82968, 151.25179],
  [-33.82963, 151.25160],
  [-33.82968, 151.25129],
  [-33.82979, 151.25084],
  [-33.82979, 151.25043],
  [-33.82983, 151.25005],
  [-33.82994, 151.24960],
  [-33.83005, 151.24935],
  [-33.83001, 151.24922],
  [-33.82994, 151.24913],
  [-33.82975, 151.24913],
  [-33.82939, 151.24948],
  [-33.82932, 151.24967],
  [-33.82923, 151.25029],
  [-33.82916, 151.25054],
  [-33.82902, 151.25070],
  [-33.82831, 151.25109],
  [-33.82816, 151.25131],
  [-33.82809, 151.25147],
  [-33.82806, 151.25159],
  [-33.82807, 151.25176],
  [-33.82822, 151.25222],
  [-33.82822, 151.25234],
  [-33.82814, 151.25246],
  [-33.82796, 151.25246],
  [-33.82786, 151.25240],
  [-33.82677, 151.25151],
  [-33.82659, 151.25143],
  [-33.82488, 151.25094],
  [-33.82421, 151.25084]
];

const balmoralSouthJunction = [
  [-33.83315, 151.24635],
  [-33.83318, 151.24627],
  [-33.83327, 151.24619],
  [-33.83463, 151.24594],
  [-33.83471, 151.24594],
  [-33.83477, 151.24594]
];

const tarongaZoo = [
  [-33.82994, 151.24418],
  [-33.83150, 151.24437],
  [-33.83156, 151.24438],
  [-33.83171, 151.24436],
  [-33.83228, 151.24439],
  [-33.83241, 151.24444],
  [-33.83537, 151.24631],
  [-33.83550, 151.24638],
  [-33.83590, 151.24651],
  [-33.83606, 151.24653],
  [-33.83625, 151.24647],
  [-33.83679, 151.24614],
  [-33.83695, 151.24599],
  [-33.83750, 151.24534],
  [-33.83763, 151.24522],
  [-33.83776, 151.24515],
  [-33.83791, 151.24508],
  [-33.83820, 151.24502],
  [-33.83863, 151.24504],
  [-33.83880, 151.24501],
  [-33.84001, 151.24412],
  [-33.84014, 151.24402],
  [-33.84089, 151.24366],
  [-33.84103, 151.24362],
  [-33.84120, 151.24363],
  [-33.84202, 151.24374],
  [-33.84211, 151.24366],
  [-33.84218, 151.24359],
  [-33.84222, 151.24344],
  [-33.84206, 151.24194]
];

const atholWharf = [
  [-33.84202, 151.24374],
  [-33.84230, 151.24380],
  [-33.84248, 151.24387],
  [-33.84346, 151.24446],
  [-33.84355, 151.24449],
  [-33.84364, 151.24450],
  [-33.84461, 151.24442],
  [-33.84478, 151.24444],
  [-33.84496, 151.24451],
  [-33.84511, 151.24460],
  [-33.84614, 151.24551],
  [-33.84629, 151.24560],
  [-33.84640, 151.24561],
  [-33.84652, 151.24557],
  [-33.84661, 151.24548],
  [-33.84664, 151.24534],
  [-33.84663, 151.24522],
  [-33.84658, 151.24508],
  [-33.84648, 151.24498],
  [-33.84636, 151.24486],
  [-33.84629, 151.24477],
  [-33.84623, 151.24465],
  [-33.84619, 151.24450],
  [-33.84596, 151.24329],
  [-33.84590, 151.24313],
  [-33.84584, 151.24302],
  [-33.84576, 151.24291],
  [-33.84554, 151.24267],
  [-33.84537, 151.24244],
  [-33.84526, 151.24225],
  [-33.84517, 151.24201],
  [-33.84515, 151.24185],
  [-33.84516, 151.24169],
  [-33.84519, 151.24155],
  [-33.84525, 151.24142],
  [-33.84555, 151.24086],
  [-33.84565, 151.24065],
  [-33.84574, 151.24023],
  [-33.84576, 151.24003],
  [-33.84569, 151.23955]
];

const cremorneWharfLine = [
  [-33.82829, 151.23001],
  [-33.82828, 151.23009],
  [-33.82830, 151.23017],
  [-33.82835, 151.23021],
  [-33.82847, 151.23029],
  [-33.83510, 151.22889],
  [-33.83515, 151.22884],
  [-33.83518, 151.22877],
  [-33.83520, 151.22868],
  [-33.83509, 151.22723],
  [-33.83514, 151.22714],
  [-33.83518, 151.22708],
  [-33.83526, 151.22700],
  [-33.83538, 151.22698],
  [-33.83549, 151.22694],
  [-33.83555, 151.22685],
  [-33.83565, 151.22656],
  [-33.83574, 151.22646],
  [-33.83583, 151.22642],
  [-33.83763, 151.22619],
  [-33.83772, 151.22618],
  [-33.83781, 151.22614],
  [-33.83803, 151.22602],
  [-33.83813, 151.22596],
  [-33.83826, 151.22592],
  [-33.83882, 151.22587],
  [-33.83896, 151.22588],
  [-33.83910, 151.22591],
  [-33.84148, 151.22695],
  [-33.84248, 151.22736],
  [-33.84259, 151.22741],
  [-33.84271, 151.22742],
  [-33.84334, 151.22741],
  [-33.84346, 151.22742],
  [-33.84354, 151.22744],
  [-33.84363, 151.22748],
  [-33.84481, 151.22865],
  [-33.84614, 151.22997],
  [-33.84623, 151.23004],
  [-33.84632, 151.23009],
  [-33.84643, 151.23010],
  [-33.84670, 151.23007],
  [-33.84681, 151.23008],
  [-33.84691, 151.23011],
  [-33.84702, 151.23020],
  [-33.84755, 151.23094]
];

const neutralBayJunction = [
  [-33.83156, 151.22261],
  [-33.83161, 151.22250],
  [-33.83167, 151.22242],
  [-33.83174, 151.22235]
];

const netutralBayLine = [
  [-33.83146, 151.22217],
  [-33.83155, 151.22227],
  [-33.83163, 151.22232],
  [-33.83174, 151.22235],
  [-33.83238, 151.22225],
  [-33.83502, 151.22171],
  [-33.83517, 151.22164],
  [-33.83539, 151.22145],
  [-33.83552, 151.22141],
  [-33.83566, 151.22140],
  [-33.83651, 151.22160],
  [-33.83669, 151.22168],
  [-33.83686, 151.22184],
  [-33.83698, 151.22192],
  [-33.83715, 151.22198],
  [-33.83792, 151.22193],
  [-33.83853, 151.22180],
  [-33.84094, 151.22096],
  [-33.84103, 151.22088],
  [-33.84109, 151.22076],
  [-33.84111, 151.22062],
  [-33.84100, 151.21904],
  [-33.84104, 151.21897],
  [-33.84107, 151.21893],
  [-33.84191, 151.21887]
];

const mosman = [
  [-33.82944, 151.20887],
  [-33.82934, 151.20900],
  [-33.82912, 151.20926],
  [-33.82905, 151.20941],
  [-33.82902, 151.20955],
  [-33.82984, 151.21510],
  [-33.82983, 151.21520],
  [-33.82979, 151.21525],
  [-33.82938, 151.21558],
  [-33.82930, 151.21567],
  [-33.82927, 151.21579],
  [-33.82926, 151.21592],
  [-33.82941, 151.21691],
  [-33.82943, 151.21703],
  [-33.82947, 151.21718],
  [-33.83146, 151.22217],
  [-33.83153, 151.22239],
  [-33.83156, 151.22261],
  [-33.83153, 151.22315],
  [-33.83145, 151.22346],
  [-33.83078, 151.22527],
  [-33.83001, 151.22707],
  [-33.82988, 151.22733],
  [-33.82966, 151.22763],
  [-33.82909, 151.22829],
  [-33.82888, 151.22865],
  [-33.82829, 151.23001],
  [-33.82794, 151.23061],
  [-33.82773, 151.23100],
  [-33.82749, 151.23131],
  [-33.82557, 151.23326],
  [-33.82530, 151.23359],
  [-33.82511, 151.23390],
  [-33.82462, 151.23485],
  [-33.82451, 151.23519],
  [-33.82440, 151.23569],
  [-33.82437, 151.23607],
  [-33.82438, 151.23885],
  [-33.82439, 151.23933],
  [-33.82452, 151.24061]
];

const theSpit = [
  [-33.82452, 151.24061],
  [-33.82450, 151.24081],
  [-33.82443, 151.24098],
  [-33.82286, 151.24337],
  [-33.82273, 151.24354],
  [-33.82260, 151.24366],
  [-33.82240, 151.24372],
  [-33.82120, 151.24390],
  [-33.82077, 151.24391],
  [-33.81990, 151.24380],
  [-33.81959, 151.24381],
  [-33.81939, 151.24386],
  [-33.81888, 151.24397],
  [-33.81865, 151.24398],
  [-33.81773, 151.24374],
  [-33.81753, 151.24365],
  [-33.81737, 151.24369],
  [-33.81723, 151.24376],
  [-33.81663, 151.24413],
  [-33.81642, 151.24421],
  [-33.81523, 151.24460],
  [-33.81501, 151.24471],
  [-33.81479, 151.24488],
  [-33.81460, 151.24497],
  [-33.81402, 151.24514],
  [-33.81366, 151.24531],
  [-33.81350, 151.24544],
  [-33.81344, 151.24551],
  [-33.81324, 151.24571],
  [-33.81304, 151.24587],
  [-33.81292, 151.24595],
  [-33.81281, 151.24594],
  [-33.81268, 151.24593],
  [-33.81250, 151.24598],
  [-33.81222, 151.24615],
  [-33.81206, 151.24621],
  [-33.81183, 151.24626],
  [-33.81157, 151.24626],
  [-33.81136, 151.24626],
  [-33.81063, 151.24638],
  [-33.81040, 151.24649],
  [-33.81003, 151.24673],
  [-33.80846, 151.24803],
  [-33.80828, 151.24812],
  [-33.80810, 151.24813],
  [-33.80788, 151.24803],
  [-33.80714, 151.24722],
  [-33.80666, 151.24682],
  [-33.80642, 151.24668],
  [-33.80455, 151.24619],
  [-33.80397, 151.24611],
  [-33.80360, 151.24592]
];

const victoriaAvenue = [
  [-33.82944, 151.20887],
  [-33.82924, 151.20891],
  [-33.82914, 151.20885],
  [-33.82905, 151.20879],
  [-33.82894, 151.20868],
  [-33.82885, 151.20853],
  [-33.82767, 151.20101],
  [-33.82762, 151.20093],
  [-33.82747, 151.20083],
  [-33.82045, 151.20095],
  [-33.82019, 151.20089],
  [-33.81975, 151.20074],
  [-33.81950, 151.20072],
  [-33.81678, 151.20129],
  [-33.81663, 151.20130],
  [-33.81648, 151.20128],
  [-33.81347, 151.19964],
  [-33.81322, 151.19954],
  [-33.81298, 151.19949],
  [-33.80847, 151.19940],
  [-33.80799, 151.19942],
  [-33.80583, 151.19913],
  [-33.80566, 151.19913],
  [-33.80385, 151.19951],
  [-33.80368, 151.19948],
  [-33.80358, 151.19939],
  [-33.80350, 151.19925],
  [-33.80338, 151.19844],
  [-33.80329, 151.19834],
  [-33.80321, 151.19826],
  [-33.80016, 151.19886],
  [-33.79991, 151.19884],
  [-33.79259, 151.19555]
];

const chatswoodStation = [
  [-33.79259, 151.19555],
  [-33.79254, 151.19548],
  [-33.79251, 151.19539],
  [-33.79251, 151.19528],
  [-33.79605, 151.18385],
  [-33.79615, 151.18324],
  [-33.79672, 151.18150]
];

const goreHill = [
  [-33.82767, 151.20101],
  [-33.82764, 151.20081],
  [-33.82758, 151.20066],
  [-33.82747, 151.20050],
  [-33.82524, 151.19841],
  [-33.82492, 151.19814],
  [-33.82388, 151.19728],
  [-33.82371, 151.19709],
  [-33.82351, 151.19667],
  [-33.82324, 151.19585],
  [-33.82323, 151.19547],
  [-33.82336, 151.19453]
];

const laneCove = [
  [-33.82336, 151.19453],
  [-33.82350, 151.19391],
  [-33.82403, 151.19243],
  [-33.82453, 151.19117],
  [-33.82473, 151.19036],
  [-33.82478, 151.19005],
  [-33.82475, 151.18971],
  [-33.82457, 151.18858],
  [-33.82447, 151.18822],
  [-33.82424, 151.18784],
  [-33.82395, 151.18757],
  [-33.82291, 151.18705],
  [-33.82262, 151.18686],
  [-33.82188, 151.18632],
  [-33.82155, 151.18603],
  [-33.82057, 151.18505],
  [-33.81793, 151.18235],
  [-33.81777, 151.18214],
  [-33.81765, 151.18191],
  [-33.81755, 151.18160],
  [-33.81724, 151.17994],
  [-33.81713, 151.17965],
  [-33.81640, 151.17877],
  [-33.81613, 151.17855],
  [-33.81464, 151.17788],
  [-33.81448, 151.17777],
  [-33.81431, 151.17758],
  [-33.81337, 151.17624],
  [-33.81319, 151.17601],
  [-33.81300, 151.17587],
  [-33.81258, 151.17561],
  [-33.81247, 151.17546],
  [-33.81243, 151.17526],
  [-33.81246, 151.17475],
  [-33.81244, 151.17431],
  [-33.81230, 151.17312],
  [-33.81229, 151.17282],
  [-33.81230, 151.17252],
  [-33.81246, 151.17132],
  [-33.81254, 151.17112],
  [-33.81265, 151.17095],
  [-33.81281, 151.17077],
  [-33.81339, 151.17025],
  [-33.81357, 151.17013],
  [-33.81403, 151.16990]
];

const mosmanWharf = [
  [-33.82994, 151.24418],
  [-33.83001, 151.24415],
  [-33.83006, 151.24413],
  [-33.83010, 151.24406],
  [-33.83109, 151.24098],
  [-33.83202, 151.23803],
  [-33.83210, 151.23782],
  [-33.83217, 151.23769],
  [-33.83261, 151.23690],
  [-33.83276, 151.23672],
  [-33.83338, 151.23620],
  [-33.83395, 151.23576],
  [-33.83403, 151.23568],
  [-33.83408, 151.23555],
  [-33.83416, 151.23526],
  [-33.83425, 151.23508],
  [-33.83449, 151.23467],
  [-33.83456, 151.23451],
  [-33.83461, 151.23426],
  [-33.83467, 151.23412],
  [-33.83480, 151.23394],
  [-33.83496, 151.23378],
  [-33.83514, 151.23366],
  [-33.83529, 151.23361],
  [-33.83554, 151.23358],
  [-33.83566, 151.23354],
  [-33.83577, 151.23346],
  [-33.83595, 151.23325],
  [-33.83604, 151.23315],
  [-33.83616, 151.23310],
  [-33.83645, 151.23308],
  [-33.83804, 151.23313]
];

const theSpitJunctionMosmanWharfJunction = [
  [-33.82452, 151.24061],
  [-33.82458, 151.24094],
  [-33.82463, 151.24112],
  [-33.82564, 151.24336],
  [-33.82572, 151.24348],
  [-33.82582, 151.24354],
  [-33.82676, 151.24389],
  [-33.82703, 151.24400],
  [-33.82728, 151.24405],
  [-33.82776, 151.24406],
  [-33.82825, 151.24403],
  [-33.82994, 151.24418]
];

const ridgeStreetFalconStreet = [
  [-33.83304, 151.20805],
  [-33.82944, 151.20887]
];

const suspensionBridge = [
  [-33.82944, 151.20887],
  [-33.82924, 151.20891],
  [-33.82287, 151.21019],
  [-33.82275, 151.21018],
  [-33.82264, 151.21015],
  [-33.82130, 151.20945],
  [-33.82115, 151.20940],
  [-33.82099, 151.20939],
  [-33.82002, 151.20961],
  [-33.81995, 151.20966],
  [-33.81991, 151.20973],
  [-33.81990, 151.20980]
];

const maranaRoad = [
  [-33.81990, 151.20980],
  [-33.82003, 151.21067],
  [-33.82000, 151.21076],
  [-33.81995, 151.21082],
  [-33.81987, 151.21086],
  [-33.81948, 151.21100],
  [-33.81919, 151.21122],
  [-33.81898, 151.21146],
  [-33.81883, 151.21166],
  [-33.81874, 151.21186],
  [-33.81859, 151.21236],
  [-33.81849, 151.21253],
  [-33.81832, 151.21261],
  [-33.81809, 151.21261],
  [-33.81612, 151.21207],
  [-33.81588, 151.21202],
  [-33.81572, 151.21204],
  [-33.81552, 151.21213],
  [-33.81539, 151.21219],
  [-33.81521, 151.21220],
  [-33.81451, 151.21202]
];

const northbridgeTerminus = [
  [-33.81451, 151.21202],
  [-33.81429, 151.21197],
  [-33.81416, 151.21186],
  [-33.81409, 151.21171],
  [-33.81395, 151.21130],
  [-33.81387, 151.21112],
  [-33.81379, 151.21099],
  [-33.81331, 151.21061],
  [-33.81292, 151.21045],
  [-33.81265, 151.21044],
  [-33.81103, 151.21078],
  [-33.81093, 151.21086],
  [-33.81091, 151.21102],
  [-33.81200, 151.21831]
];

const crowsNestJunction = [
  [-33.82788, 151.20089],
  [-33.82770, 151.20084],
  [-33.82747, 151.20083]
];

const victoriaCrossOldJunction = [
  [-33.83885, 151.20703],
  [-33.83882, 151.20692],
  [-33.83864, 151.20676]
];

const victoriaCrossNewJunction = [
  [-33.83905, 151.20684],
  [-33.83884, 151.20684],
  [-33.83864, 151.20676]
];

const victoriaCrossCrowsNest = [
  [-33.83864, 151.20676],
  [-33.83806, 151.20636],
  [-33.83783, 151.20624],
  [-33.83455, 151.20496],
  [-33.83371, 151.20478],
  [-33.83342, 151.20467],
  [-33.83211, 151.20407],
  [-33.83190, 151.20393],
  [-33.83072, 151.20295],
  [-33.83039, 151.20268],
  [-33.82801, 151.20099],
  [-33.82788, 151.20089],
  [-33.82747, 151.20050]
];

const milsonsPointOriginal = [
  [-33.84073, 151.20860],
  [-33.84250, 151.21045],
  [-33.84261, 151.21056],
  [-33.84269, 151.21058],
  [-33.84378, 151.21061],
  [-33.84407, 151.21062],
  [-33.84423, 151.21068],
  [-33.84497, 151.21112],
  [-33.84513, 151.21118],
  [-33.84947, 151.21188],
  [-33.84956, 151.21195],
  [-33.84970, 151.21204],
  [-33.84984, 151.21211],
  [-33.85060, 151.21225]
];

const milsonsPointOriginalOld = [
  [-33.83869, 151.20692],
  [-33.83885, 151.20697],
  [-33.83905, 151.20710],
  [-33.84047, 151.20837],
  [-33.84073, 151.20860]
];

const milsonsPointOriginalDeviation = [
  [-33.83869, 151.20692],
  [-33.83879, 151.20695],
  [-33.83885, 151.20703],
  [-33.83910, 151.20862],
  [-33.83915, 151.20873],
  [-33.83927, 151.20879],
  [-33.84046, 151.20855],
  [-33.84061, 151.20856],
  [-33.84073, 151.20860]
];

const milsonsPointDeviation = [
  [-33.84069, 151.20649],
  [-33.84103, 151.20639],
  [-33.84287, 151.20496],
  [-33.84315, 151.20478],
  [-33.84470, 151.20423],
  [-33.84490, 151.20419],
  [-33.84636, 151.20403],
  [-33.84657, 151.20399],
  [-33.84703, 151.20380],
  [-33.84713, 151.20376],
  [-33.84728, 151.20373],
  [-33.84836, 151.20368],
  [-33.84848, 151.20374],
  [-33.84858, 151.20386],
  [-33.84862, 151.20408],
  [-33.84860, 151.20478],
  [-33.84863, 151.20501],
  [-33.84876, 151.20541],
  [-33.84881, 151.20561],
  [-33.84883, 151.20571],
  [-33.84882, 151.20603]
];

const milsonsPointShared = [
  [-33.83869, 151.20692],
  [-33.83906, 151.20684],
  [-33.84069, 151.20649]
];

const ridgeStreet = [
  [-33.83869, 151.20692],
  [-33.83304, 151.20805]
];

const wynyard = [
  [-33.84069, 151.20649],
  [-33.84074, 151.20651],
  [-33.84079, 151.20654],
  [-33.84084, 151.20662],
  [-33.84106, 151.20835],
  [-33.84114, 151.20862],
  [-33.84127, 151.20886],
  [-33.84143, 151.20911],
  [-33.84285, 151.21050],
  [-33.84312, 151.21079],
  [-33.84376, 151.21149],
  [-33.84395, 151.21163],
  [-33.84718, 151.21267],
  [-33.84782, 151.21278],
  [-33.84851, 151.21279],
  [-33.84904, 151.21269],
  [-33.84954, 151.21255],
  [-33.84987, 151.21241],
  [-33.85468, 151.20940],
  [-33.85507, 151.20909],
  [-33.85868, 151.20683],
  [-33.85898, 151.20667]
];

const wynyardTunnel = [
  [-33.85898, 151.20667],
  [-33.86216, 151.20538],
  [-33.86231, 151.20539],
  [-33.86249, 151.20538],
  [-33.86676, 151.20596]
];

const falconStreetConnector = [
  [-33.82885, 151.20853],
  [-33.82902, 151.20955]
];

export const sydneyTramsNorth: Line = {
  name: 'Sydney Trams (North)',
  state: 'NSW',
  segments: [
    {
      segments: [balmoralLine, balmoralSouthJunction],
      history: {
        opened: {
          date: '1922-05-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tarongaZoo],
      history: {
        opened: {
          date: '1916-10-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [atholWharf],
      history: {
        opened: {
          date: '1917-10-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cremorneWharfLine],
      history: {
        opened: {
          date: '1911-12-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [netutralBayLine, neutralBayJunction],
      history: {
        opened: {
          date: '1900-06-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mosman],
      history: {
        opened: {
          date: '1893-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [theSpit],
      history: {
        opened: {
          date: '1900-10-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [falconStreetConnector],
      history: {
        opened: {
          date: '1898-04-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaAvenue],
      history: {
        opened: {
          date: '1898-04-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chatswoodStation],
      history: {
        opened: {
          date: '1908-07-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goreHill],
      history: {
        opened: {
          date: '1900-02-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [laneCove],
      history: {
        opened: {
          date: '1909-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mosmanWharf],
      history: {
        opened: {
          date: '1897-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [theSpitJunctionMosmanWharfJunction],
      history: {
        opened: {
          date: '1897-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ridgeStreetFalconStreet],
      history: {
        opened: {
          date: '1893-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1900-02-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [suspensionBridge],
      history: {
        opened: {
          date: '1909-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maranaRoad],
      history: {
        opened: {
          date: '1914-02-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1936-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northbridgeTerminus],
      history: {
        opened: {
          date: '1914-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1936-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        {
          date: '1924-01-01',
          original: [victoriaCrossOldJunction],
          deviation: [victoriaCrossNewJunction]
        },
        victoriaCrossCrowsNest,
        crowsNestJunction
      ],
      history: {
        opened: {
          date: '1909-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        milsonsPointOriginal,
        {
          date: '1909-09-01',
          original: [milsonsPointOriginalOld],
          deviation: [milsonsPointOriginalDeviation]
        }
      ],
      history: {
        opened: {
          date: '1886-05-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1900-02-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1924-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ridgeStreet],
      history: {
        opened: {
          date: '1886-05-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1900-02-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-06-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [milsonsPointShared],
      history: {
        opened: {
          date: '1924-07-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [milsonsPointDeviation],
      history: {
        opened: {
          date: '1924-07-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        wynyard, {
          type: 'tunnel',
          segment: wynyardTunnel
        }],
      history: {
        opened: {
          date: '1932-03-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-06-01',
          status: 'closed'
        }]
      }
    }
  ]
};
