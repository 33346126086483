import { Line } from "../../../trackTypes";

const line = [
  [-37.6838, 176.2182],
  [-37.6833, 176.2193],
  [-37.6827, 176.2198],
  [-37.6815, 176.2202],
  [-37.6791, 176.2203],
  [-37.6774, 176.2204],
  [-37.6750, 176.2196],
  [-37.6735, 176.2183],
  [-37.6722, 176.2167],
  [-37.6690, 176.2121],
  [-37.6678, 176.2104],
  [-37.6555, 176.1999],
  [-37.6545, 176.1986],
  [-37.6540, 176.1971],
  [-37.6539, 176.1958],
  [-37.6539, 176.1871],
  [-37.6537, 176.1861],
  [-37.6534, 176.1858],
  [-37.6528, 176.1856],
  [-37.6449, 176.1856]
];

const eastJunction = [
  [-37.6828, 176.2242],
  [-37.6825, 176.2229],
  [-37.6819, 176.2219],
  [-37.6808, 176.2208],
  [-37.6791, 176.2203]
];

export const mountMaunganui: Line = {
  name: 'Mount Maunganui',
  state: 'NZ',
  segments: [
    {
      segments: [line, eastJunction],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1942-01-01',
          status: 'closed'
        }, {
          date: '1955-03-28',
          status: 'open'
        }]
      }
    }
  ]
}
