import { Line } from "../../../../trackTypes";

const junction = [
  [-43.53993, 172.63683],
  [-43.53993, 172.63647]
];

const spreydon = [
  [-43.53980, 172.63666],
  [-43.53986, 172.63661],
  [-43.53993, 172.63647],
  [-43.53994, 172.62804],
  [-43.53999, 172.62795],
  [-43.54005, 172.62792],
  [-43.54745, 172.62793],
  [-43.54754, 172.62788],
  [-43.54761, 172.62775],
  [-43.54788, 172.62646],
  [-43.54795, 172.62596],
  [-43.54795, 172.62554],
  [-43.54791, 172.62492],
  [-43.54782, 172.62444],
  [-43.54756, 172.62355],
  [-43.54748, 172.62319],
  [-43.54742, 172.62271],
  [-43.54742, 172.62202],
  [-43.54747, 172.62191],
  [-43.54760, 172.62182],
  [-43.54924, 172.62182]
];

const barringtonStreet = [
  [-43.54924, 172.62182],
  [-43.54936, 172.62179],
  [-43.54945, 172.62171],
  [-43.55156, 172.61816],
  [-43.55163, 172.61813],
  [-43.55169, 172.61815],
  [-43.55547, 172.62238],
  [-43.55555, 172.62241],
  [-43.55565, 172.62239],
  [-43.55745, 172.61938]
];

export const line10: Line = {
  name: 'Line 10',
  state: 'NZ',
  segments: [
    {
      segments: [spreydon, junction],
      history: {
        opened: {
          date: '1911-08-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-06-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [barringtonStreet],
      history: {
        opened: {
          date: '1922-08-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-06-21',
          status: 'closed'
        }]
      }
    }
  ]
}
