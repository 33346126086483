import { Line } from "../../trackTypes";

const newMarketBostonRoad = [
  [-36.8676, 174.7793],
  [-36.8673, 174.7792],
  [-36.8670, 174.7790],
  [-36.8667, 174.7787],
  [-36.8663, 174.7776],
  [-36.8652, 174.7726],
  [-36.8651, 174.7718],
  [-36.8653, 174.7709],
  [-36.8662, 174.7675]
];

const newmarketJunction = [
  [-36.8664, 174.7801],
  [-36.8667, 174.7797],
  [-36.8668, 174.7793],
  [-36.8667, 174.7787]
];

const bostonRoadMorningside = [
  [-36.8662, 174.7675],
  [-36.8672, 174.7643],
  [-36.8681, 174.7623],
  [-36.8682, 174.7619],
  [-36.8683, 174.7608],
  [-36.8680, 174.7595],
  [-36.8678, 174.7581],
  [-36.8680, 174.7570],
  [-36.8693, 174.7533],
  [-36.8697, 174.7505],
  [-36.8701, 174.7497],
  [-36.8713, 174.7484],
  [-36.8717, 174.7477],
  [-36.8720, 174.7470],
  [-36.8721, 174.7460],
  [-36.8729, 174.7433],
  [-36.8733, 174.7425],
  [-36.8736, 174.7419],
  [-36.8738, 174.7408],
  [-36.8739, 174.7401],
  [-36.8740, 174.7385],
  [-36.8740, 174.7377],
  [-36.8743, 174.7369],
  [-36.8750, 174.7353]
];

const morningsideAvondale = [
  [-36.8750, 174.7353],
  [-36.8755, 174.7339],
  [-36.8760, 174.7322],
  [-36.8765, 174.7296],
  [-36.8766, 174.7283],
  [-36.8766, 174.7264],
  [-36.8766, 174.7252],
  [-36.8773, 174.7216],
  [-36.8777, 174.7201],
  [-36.8783, 174.7190],
  [-36.8790, 174.7182],
  [-36.8807, 174.7166],
  [-36.8814, 174.7162],
  [-36.8829, 174.7156],
  [-36.8838, 174.7150],
  [-36.8910, 174.7073],
  [-36.8926, 174.7052],
  [-36.8932, 174.7041],
  [-36.8942, 174.7015],
  [-36.8946, 174.7002],
  [-36.8950, 174.6996],
  [-36.8955, 174.6992],
  [-36.8961, 174.6991],
  [-36.8973, 174.6991]
];

const avondaleTitirangiRoad = [
  [-36.8973, 174.6991],
  [-36.8984, 174.6992],
  [-36.8990, 174.6990],
  [-36.9002, 174.6986],
  [-36.9027, 174.6980],
  [-36.9037, 174.6976],
  [-36.9069, 174.6956],
  [-36.9074, 174.6949],
  [-36.9077, 174.6939],
  [-36.9076, 174.6911],
  [-36.9077, 174.6896],
  [-36.9080, 174.6883],
  [-36.9104, 174.6807],
  [-36.9106, 174.6794],
  [-36.9106, 174.6781],
  [-36.9108, 174.6772],
  [-36.9112, 174.6757]
];

const titirangiRoadGlenEden = [
  [-36.9112, 174.6757],
  [-36.9114, 174.6745],
  [-36.9113, 174.6696],
  [-36.9112, 174.6687],
  [-36.9108, 174.6676],
  [-36.9105, 174.6666],
  [-36.9105, 174.6655],
  [-36.9106, 174.6646],
  [-36.9113, 174.6631],
  [-36.9116, 174.6616],
  [-36.9113, 174.6568],
  [-36.9110, 174.6555],
  [-36.9101, 174.6529]
];

const glenEdenHenderson = [
  [-36.9101, 174.6529],
  [-36.9080, 174.6464],
  [-36.9062, 174.6410],
  [-36.9050, 174.6388],
  [-36.8999, 174.6330],
  [-36.8990, 174.6323],
  [-36.8978, 174.6319],
  [-36.8931, 174.6316],
  [-36.8809, 174.6309]
];

const hendersonSwanson = [
  [-36.8809, 174.6309],
  [-36.8798, 174.6308],
  [-36.8790, 174.6305],
  [-36.8772, 174.6292],
  [-36.8763, 174.6283],
  [-36.8757, 174.6272],
  [-36.8722, 174.6171],
  [-36.8717, 174.6160],
  [-36.8702, 174.6143],
  [-36.8695, 174.6133],
  [-36.8692, 174.6121],
  [-36.8674, 174.6001],
  [-36.8673, 174.5984],
  [-36.8673, 174.5962],
  [-36.8677, 174.5914],
  [-36.8677, 174.5906],
  [-36.8677, 174.5871],
  [-36.8666, 174.5787],
  [-36.8661, 174.5761]
];

export const west: Line = {
  name: 'West',
  state: 'NZ',
  segments: [
    {
      segments: [newMarketBostonRoad],
      history: {
        opened: {
          date: '1880-03-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-01-01',
          tracks: 2
        }, {
          date: '2015-07-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [newmarketJunction],
      history: {
        opened: {
          date: '2007-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-01-01',
          tracks: 2
        }, {
          date: '2015-07-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [bostonRoadMorningside],
      history: {
        opened: {
          date: '1880-03-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-01-01',
          tracks: 2
        }, {
          date: '2015-07-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [morningsideAvondale],
      history: {
        opened: {
          date: '1880-03-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-01-01',
          tracks: 2
        }, {
          date: '2015-07-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [avondaleTitirangiRoad],
      history: {
        opened: {
          date: '1880-03-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-06-08',
          tracks: 2
        }, {
          date: '2015-07-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [titirangiRoadGlenEden],
      history: {
        opened: {
          date: '1880-03-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-06-01',
          tracks: 2
        }, {
          date: '2015-07-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [glenEdenHenderson],
      history: {
        opened: {
          date: '1880-12-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-06-01',
          tracks: 2
        }, {
          date: '2015-07-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [hendersonSwanson],
      history: {
        opened: {
          date: '1881-07-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-06-01',
          tracks: 2
        }, {
          date: '2015-07-20',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
