import { Line } from "../../../trackTypes";

const beenleighSouthCoastShared = [
  [-27.7246, 153.2185],
  [-27.7255, 153.2195],
  [-27.7282, 153.2210],
  [-27.7315, 153.2218],
  [-27.7335, 153.2230],
  [-27.7356, 153.2249],
  [-27.7379, 153.2281],
  [-27.7420, 153.2350],
  [-27.7453, 153.2389],
  [-27.7586, 153.2497],
  [-27.7633, 153.2521],
  [-27.7706, 153.2542],
  [-27.7838, 153.2619]
];

const southCoastSharedCoomera = [
  [-27.7838, 153.2619],
  [-27.7941, 153.2741],
  [-27.7981, 153.2810],
  [-27.8000, 153.2835],
  [-27.8069, 153.2904],
  [-27.8097, 153.2925],
  [-27.8300, 153.3053],
  [-27.8345, 153.3070],
  [-27.8386, 153.3077],
  [-27.8434, 153.3094],
  [-27.8484, 153.3130],
  [-27.8523, 153.3170]
];

const coomeraHelensville = [
  [-27.8523, 153.3170],
  [-27.8574, 153.3227],
  [-27.8597, 153.3249],
  [-27.8634, 153.3273],
  [-27.8666, 153.3284],
  [-27.8879, 153.3332],
  [-27.8919, 153.3347],
  [-27.9010, 153.3390],
  [-27.9083, 153.3405],
  [-27.9173, 153.3404],
  [-27.9255, 153.3390]
];

const helensvaleNerang = [
  [-27.9255, 153.3390],
  [-27.9266, 153.3387],
  [-27.9307, 153.3386],
  [-27.9333, 153.3389],
  [-27.9366, 153.3399],
  [-27.9408, 153.3419],
  [-27.9476, 153.3445],
  [-27.9519, 153.3455],
  [-27.9592, 153.3460],
  [-27.9620, 153.3463],
  [-27.9677, 153.3474],
  [-27.9735, 153.3475],
  [-27.9792, 153.3468],
  [-27.9819, 153.3468],
  [-27.9846, 153.3473],
  [-27.9891, 153.3491],
  [-27.9920, 153.3498],
  [-27.9941, 153.3499]
];

const nerangRobina = [
  [-27.9941, 153.3499],
  [-27.9959, 153.3499],
  [-28.0001, 153.3494],
  [-28.0031, 153.3494],
  [-28.0082, 153.3500],
  [-28.0107, 153.3502],
  [-28.0146, 153.3498],
  [-28.0184, 153.3500],
  [-28.0236, 153.3510],
  [-28.0267, 153.3520],
  [-28.0298, 153.3538],
  [-28.0340, 153.3572],
  [-28.0372, 153.3594],
  [-28.0408, 153.3609],
  [-28.0465, 153.3621],
  [-28.0495, 153.3633],
  [-28.0531, 153.3655],
  [-28.0636, 153.3750],
  [-28.0653, 153.3762],
  [-28.0678, 153.3772],
  [-28.0710, 153.3779]
];

const robinaRobinaTunnel = [
  [-28.0710, 153.3779],
  [-28.0780, 153.3791]
];

const robinaTunnel = [
  [-28.0780, 153.3791],
  [-28.0795, 153.3794],
  [-28.0807, 153.3799]
];

const robinaTunnelVarsityLakes = [
  [-28.0807, 153.3799],
  [-28.0825, 153.3812],
  [-28.0837, 153.3827],
  [-28.0855, 153.3858],
  [-28.0873, 153.3878],
  [-28.0914, 153.3909],
  [-28.0934, 153.3932],
  [-28.0979, 153.4006]
];

export const goldcoast: Line = {
  name: 'Goldcoast',
  state: 'QLD',
  segments: [
    {
      segments: [beenleighSouthCoastShared],
      history: {
        opened: {
          date: '1889-01-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-08-01',
          tracks: 2
        }, {
          date: '1996-02-26',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1964-06-30',
          status: 'closed'
        }, {
          date: '1996-02-26',
          status: 'open'
        }]
      }
    },
    {
      segments: [southCoastSharedCoomera],
      history: {
        opened: {
          date: '1996-02-26',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-08-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [coomeraHelensville],
      history: {
        opened: {
          date: '1996-02-26',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2017-10-04',
          tracks: 2
        }]
      }
    },
    {
      segments: [helensvaleNerang],
      history: {
        opened: {
          date: '1997-12-16',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-08-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [nerangRobina],
      history: {
        opened: {
          date: '1998-05-31',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-08-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        robinaRobinaTunnel,
        {
          name: 'Robina Tunnel',
          segment: robinaTunnel,
          type: 'tunnel'
        },
        robinaTunnelVarsityLakes
      ],
      history: {
        opened: {
          date: '2009-12-13',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
