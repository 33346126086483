import { Line } from "../../trackTypes";

const gheringhapCressy = [
  [-38.0742, 144.2266],
  [-38.0740, 144.2235],
  [-38.0741, 144.2212],
  [-38.0838, 144.1571],
  [-38.0840, 144.1546],
  [-38.0838, 144.1523],
  [-38.0795, 144.1237],
  [-38.0796, 144.1219],
  [-38.0803, 144.1201],
  [-38.0820, 144.1175],
  [-38.0829, 144.1151],
  [-38.0833, 144.1143],
  [-38.0839, 144.1137],
  [-38.0857, 144.1120],
  [-38.0862, 144.1113],
  [-38.0866, 144.1101],
  [-38.0870, 144.1084],
  [-38.0878, 144.1065],
  [-38.0893, 144.1038],
  [-38.0907, 144.1021],
  [-38.0915, 144.1014],
  [-38.0956, 144.0979],
  [-38.0967, 144.0964],
  [-38.0976, 144.0946],
  [-38.0981, 144.0925],
  [-38.1031, 144.0612],
  [-38.1062, 144.0432],
  [-38.1064, 144.0409],
  [-38.1064, 144.0397],
  [-38.1019, 144.0012],
  [-38.1000, 143.9820],
  [-38.0943, 143.9247],
  [-38.0918, 143.9037],
  [-38.0860, 143.8719],
  [-38.0851, 143.8560],
  [-38.0791, 143.8141],
  [-38.0466, 143.6880],
  [-38.0344, 143.6489],
  [-38.0333, 143.6467],
  [-38.0320, 143.6454],
  [-38.0320, 143.6454]
];

const cressy = [
  [-38.0320, 143.6454],
  [-38.0253, 143.6415]
];

const cressyMaroona = [
  [-38.0253, 143.6415],
  [-38.0242, 143.6406],
  [-38.0234, 143.6395],
  [-38.0230, 143.6384],
  [-38.0210, 143.6198],
  [-38.0202, 143.6163],
  [-37.9825, 143.4855],
  [-37.9816, 143.4833],
  [-37.9714, 143.4640],
  [-37.9705, 143.4614],
  [-37.9700, 143.4588],
  [-37.9693, 143.3998],
  [-37.9695, 143.3982],
  [-37.9739, 143.3625],
  [-37.9740, 143.3602],
  [-37.9740, 143.3589],
  [-37.9725, 143.3493],
  [-37.9721, 143.3476],
  [-37.9716, 143.3466],
  [-37.9704, 143.3446],
  [-37.9698, 143.3428],
  [-37.9694, 143.3410],
  [-37.9621, 143.2768],
  [-37.9617, 143.2750],
  [-37.9515, 143.2442],
  [-37.9506, 143.2425],
  [-37.9442, 143.2332],
  [-37.9430, 143.2319],
  [-37.8847, 143.1848],
  [-37.8824, 143.1824],
  [-37.8408, 143.1280],
  [-37.8392, 143.1254],
  [-37.8381, 143.1224],
  [-37.8366, 143.1151],
  [-37.8361, 143.1132],
  [-37.8303, 143.0964],
  [-37.8296, 143.0946],
  [-37.8288, 143.0933],
  [-37.7936, 143.0479],
  [-37.7677, 143.0213],
  [-37.7496, 143.0060],
  [-37.7445, 143.0032],
  [-37.7435, 143.0024],
  [-37.7418, 143.0008],
  [-37.7364, 142.9940],
  [-37.7349, 142.9920],
  [-37.7181, 142.9797],
  [-37.7161, 142.9787],
  [-37.6637, 142.9603],
  [-37.6611, 142.9600],
  [-37.6361, 142.9620],
  [-37.5906, 142.9620],
  [-37.5582, 142.9619],
  [-37.5562, 142.9615],
  [-37.5511, 142.9594],
  [-37.5491, 142.9583],
  [-37.5281, 142.9466],
  [-37.5270, 142.9461],
  [-37.5122, 142.9413],
  [-37.5112, 142.9408],
  [-37.5070, 142.9384],
  [-37.5052, 142.9368],
  [-37.5040, 142.9351],
  [-37.5030, 142.9335],
  [-37.4962, 142.9156],
  [-37.4951, 142.9135],
  [-37.4942, 142.9122],
  [-37.4830, 142.8999],
  [-37.4459, 142.8604],
  [-37.4445, 142.8596],
  [-37.4434, 142.8594],
  [-37.4424, 142.8595],
];

const maroonaArarat = [
  [-37.4424, 142.8595],
  [-37.4404, 142.8607],
  [-37.4372, 142.8624],
  [-37.4360, 142.8632],
  [-37.4347, 142.8645],
  [-37.4086, 142.8968],
  [-37.3875, 142.9181],
  [-37.3858, 142.9196],
  [-37.3842, 142.9208],
  [-37.3334, 142.9475],
  [-37.3312, 142.9484],
  [-37.3292, 142.9488],
  [-37.2934, 142.9554],
  [-37.2920, 142.9553],
  [-37.2907, 142.9548],
  [-37.2896, 142.9540],
  [-37.2853, 142.9506],
  [-37.2848, 142.9500],
  [-37.2844, 142.9494],
  [-37.2840, 142.9485]
];

const araratStawell = [
  [-37.2822, 142.9366],
  [-37.2814, 142.9296],
  [-37.2807, 142.9278],
  [-37.2797, 142.9266],
  [-37.2740, 142.9234],
  [-37.2720, 142.9229],
  [-37.2698, 142.9228],
  [-37.2609, 142.9243],
  [-37.2595, 142.9242],
  [-37.2580, 142.9238],
  [-37.2482, 142.9203],
  [-37.2464, 142.9194],
  [-37.2314, 142.9084],
  [-37.2295, 142.9063],
  [-37.2269, 142.9021],
  [-37.2252, 142.9003],
  [-37.2200, 142.8957],
  [-37.2182, 142.8946],
  [-37.2031, 142.8885],
  [-37.2014, 142.8875],
  [-37.1943, 142.8829],
  [-37.1830, 142.8788],
  [-37.1818, 142.8781],
  [-37.1807, 142.8772],
  [-37.1679, 142.8625],
  [-37.1663, 142.8613],
  [-37.1642, 142.8605],
  [-37.1613, 142.8604],
  [-37.1596, 142.8597],
  [-37.1584, 142.8588],
  [-37.1575, 142.8576],
  [-37.1480, 142.8399],
  [-37.1471, 142.8387],
  [-37.1395, 142.8300],
  [-37.1384, 142.8285],
  [-37.1373, 142.8268],
  [-37.1293, 142.8113],
  [-37.1275, 142.8093],
  [-37.1249, 142.8073],
  [-37.1226, 142.8064],
  [-37.0903, 142.8013],
  [-37.0881, 142.8006],
  [-37.0870, 142.8001],
  [-37.0678, 142.7886],
  [-37.0661, 142.7870],
  [-37.0643, 142.7843],
  [-37.0634, 142.7819],
  [-37.0601, 142.7748],
  [-37.0593, 142.7732]
];

const stawellMurtoa = [
  [-37.0593, 142.7732],
  [-37.0573, 142.7687],
  [-37.0569, 142.7673],
  [-37.0566, 142.7658],
  [-37.0565, 142.7646],
  [-37.0563, 142.7594],
  [-37.0560, 142.7577],
  [-37.0552, 142.7555],
  [-37.0540, 142.7537],
  [-37.0507, 142.7498],
  [-37.0380, 142.7350],
  [-37.0368, 142.7339],
  [-37.0358, 142.7333],
  [-37.0215, 142.7261],
  [-37.0196, 142.7247],
  [-37.0181, 142.7234],
  [-37.0173, 142.7227],
  [-37.0165, 142.7223],
  [-36.9828, 142.7096],
  [-36.9734, 142.7052],
  [-36.9684, 142.7038],
  [-36.9669, 142.7032],
  [-36.9660, 142.7026],
  [-36.9443, 142.6862],
  [-36.9428, 142.6852],
  [-36.9411, 142.6844],
  [-36.9253, 142.6782],
  [-36.9202, 142.6761],
  [-36.9176, 142.6749],
  [-36.9153, 142.6732],
  [-36.8687, 142.6324],
  [-36.8444, 142.6111],
  [-36.8425, 142.6098],
  [-36.7459, 142.5513],
  [-36.6839, 142.5137],
  [-36.6198, 142.4750]
];

const murtoaHorsham = [
  [-36.6198, 142.4750],
  [-36.6105, 142.4693],
  [-36.6093, 142.4684],
  [-36.6083, 142.4672],
  [-36.6073, 142.4654],
  [-36.6067, 142.4635],
  [-36.6065, 142.4614],
  [-36.6062, 142.3927],
  [-36.6061, 142.3679],
  [-36.6063, 142.3662],
  [-36.6066, 142.3649],
  [-36.6578, 142.2543],
  [-36.6609, 142.2506],
  [-36.6967, 142.2231],
  [-36.7054, 142.2125],
  [-36.7066, 142.2103],
  [-36.7073, 142.2075],
  [-36.7074, 142.2008]
];

const horshamDimboola = [
  [-36.7074, 142.2008],
  [-36.7074, 142.1925],
  [-36.7074, 142.1919],
  [-36.7073, 142.1910],
  [-36.7071, 142.1901],
  [-36.7068, 142.1891],
  [-36.7062, 142.1877],
  [-36.6992, 142.1731],
  [-36.6981, 142.1711],
  [-36.6971, 142.1696],
  [-36.6955, 142.1682],
  [-36.6199, 142.1298],
  [-36.6122, 142.1259],
  [-36.6115, 142.1256],
  [-36.6101, 142.1253],
  [-36.5911, 142.1251],
  [-36.5895, 142.1249],
  [-36.5881, 142.1243],
  [-36.5876, 142.1240],
  [-36.5792, 142.1175],
  [-36.5779, 142.1167],
  [-36.5768, 142.1164],
  [-36.5758, 142.1162],
  [-36.5479, 142.1156],
  [-36.5203, 142.1129],
  [-36.5192, 142.1127],
  [-36.5183, 142.1123],
  [-36.5172, 142.1116],
  [-36.5110, 142.1063],
  [-36.5098, 142.1049],
  [-36.5088, 142.1036],
  [-36.5081, 142.1022],
  [-36.5039, 142.0912],
  [-36.5022, 142.0878],
  [-36.5002, 142.0845],
  [-36.4987, 142.0823],
  [-36.4748, 142.0531],
  [-36.4730, 142.0515],
  [-36.4652, 142.0463],
  [-36.4641, 142.0454],
  [-36.4635, 142.0447],
  [-36.4627, 142.0437],
  [-36.4586, 142.0363],
  [-36.4576, 142.0349],
  [-36.4566, 142.0340],
  [-36.4520, 142.0310]
];

const dimboolaServiceton = [
  [-36.4520, 142.0310],
  [-36.4285, 142.0156],
  [-36.4263, 142.0135],
  [-36.4247, 142.0110],
  [-36.4063, 141.9704],
  [-36.3958, 141.9585],
  [-36.3946, 141.9565],
  [-36.3884, 141.9421],
  [-36.3829, 141.9328],
  [-36.3820, 141.9309],
  [-36.3730, 141.8848],
  [-36.3667, 141.8195],
  [-36.3662, 141.8175],
  [-36.3645, 141.8132],
  [-36.3640, 141.8113],
  [-36.3639, 141.8095],
  [-36.3640, 141.8079],
  [-36.3644, 141.8057],
  [-36.3645, 141.8039],
  [-36.3642, 141.8018],
  [-36.3614, 141.7932],
  [-36.3604, 141.7888],
  [-36.3378, 141.7202],
  [-36.3374, 141.7181],
  [-36.3336, 141.6752],
  [-36.3336, 141.6616],
  [-36.3332, 141.6589],
  [-36.3304, 141.6469],
  [-36.3302, 141.6465],
  [-36.3303, 141.6439],
  [-36.3310, 141.6372],
  [-36.3326, 141.6085],
  [-36.3323, 141.6062],
  [-36.3272, 141.5830],
  [-36.3251, 141.5634],
  [-36.3250, 141.5610],
  [-36.3271, 141.5357],
  [-36.3266, 141.5208],
  [-36.3224, 141.4945],
  [-36.3201, 141.4558],
  [-36.3197, 141.4542],
  [-36.3164, 141.4468],
  [-36.3158, 141.4444],
  [-36.3158, 141.4419],
  [-36.3204, 141.4204],
  [-36.3222, 141.4164],
  [-36.3305, 141.4065],
  [-36.3428, 141.3825],
  [-36.3746, 141.2711],
  [-36.3775, 141.2475],
  [-36.3775, 141.2451],
  [-36.3764, 141.2381],
  [-36.3764, 141.2354],
  [-36.3773, 141.2299],
  [-36.3772, 141.2268],
  [-36.3678, 141.1683],
  [-36.3677, 141.1413],
  [-36.3680, 141.1387],
  [-36.3791, 141.1107],
  [-36.3798, 141.1079],
  [-36.3801, 141.1044],
  [-36.3754, 141.0611],
  [-36.3754, 140.9878]
];

const servicetonSaBorder = [
  [-36.3754, 140.9878],
  [-36.3754, 140.9761],
  [-36.3753, 140.9748],
  [-36.3743, 140.9674]
];

export const westernSG: Line = {
  name: 'Western SG line',
  state: 'VIC',
  segments: [
    {
      segments: [gheringhapCressy],
      history: {
        opened: {
          date: '1913-08-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [cressy],
      history: {
        opened: {
          date: '1910-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [cressyMaroona],
      history: {
        opened: {
          date: '1913-08-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [maroonaArarat],
      history: {
        opened: {
          date: '1877-04-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-03-17',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [araratStawell],
      history: {
        opened: {
          date: '1876-04-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-14',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [stawellMurtoa],
      history: {
        opened: {
          date: '1878-12-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-14',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [murtoaHorsham],
      history: {
        opened: {
          date: '1879-02-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-14',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [horshamDimboola],
      history: {
        opened: {
          date: '1882-07-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-14',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [dimboolaServiceton],
      history: {
        opened: {
          date: '1887-01-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [servicetonSaBorder],
      history: {
        opened: {
          date: '1887-01-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    }
  ]
};
