import { Line } from "../../../trackTypes";

const wintonBrowns = [
  [-46.1406, 168.3270],
  [-46.1402, 168.3277],
  [-46.1398, 168.3287],
  [-46.1397, 168.3644],
  [-46.1391, 168.3666],
  [-46.1364, 168.3701],
  [-46.1360, 168.3713],
  [-46.1357, 168.3734],
  [-46.1358, 168.3742],
  [-46.1362, 168.3750],
  [-46.1451, 168.3825],
  [-46.1455, 168.3832],
  [-46.1504, 168.3958],
  [-46.1506, 168.3979],
  [-46.1498, 168.4261]
];

const brownsHokonuiMine = [
  [-46.1498, 168.4261],
  [-46.1500, 168.4333],
  [-46.1504, 168.4356],
  [-46.1591, 168.4715],
  [-46.1603, 168.4734],
  [-46.1638, 168.4752],
  [-46.1753, 168.4892],
  [-46.1761, 168.4916],
  [-46.1769, 168.4971],
  [-46.1780, 168.4993],
  [-46.1806, 168.5018],
  [-46.1817, 168.5042],
  [-46.1822, 168.5090]
];

const hokonuiMineHedgehope = [
  [-46.1822, 168.5090],
  [-46.1828, 168.5111],
  [-46.1853, 168.5154],
  [-46.1889, 168.5227],
  [-46.1896, 168.5236],
  [-46.1903, 168.5243],
  [-46.1979, 168.5256]
];


export const hedgehope: Line = {
  name: 'hedgehope',
  state: 'NZ',
  segments: [
    {
      segments: [wintonBrowns],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brownsHokonuiMine],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-12-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hokonuiMineHedgehope],
      history: {
        opened: {
          date: '1899-07-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-12-24',
          status: 'closed'
        }]
      }
    }
  ]
}
