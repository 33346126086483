import { Line } from "../../trackTypes";

const adelaideGaolLoop = [
  [-34.9195, 138.5883],
  [-34.9191, 138.5867],
  [-34.9191, 138.5857],
  [-34.9196, 138.5844]
];

const gaolLoopMileEndJunction = [
  [-34.9196, 138.5844],
  [-34.9201, 138.5828],
  [-34.9214, 138.5810],
  [-34.9231, 138.5801],
  [-34.9235, 138.5800]
];

const parklandsLoopInner = [
  [-34.9274, 138.5801],
  [-34.9310, 138.5809],
  [-34.9330, 138.5809],
  [-34.9338, 138.5806],
  [-34.9342, 138.5806],
  [-34.9399, 138.5816],
  [-34.9405, 138.5819],
  [-34.9413, 138.5824],
  [-34.9420, 138.5827]
];

const parklandsLoopOuter = [
  [-34.9235, 138.5800],
  [-34.9252, 138.5797],
  [-34.9274, 138.5801],
  [-34.9288, 138.5800],
  [-34.9299, 138.5797],
  [-34.9309, 138.5796],
  [-34.9398, 138.5812],
  [-34.9406, 138.5815],
  [-34.9415, 138.5823],
  [-34.9420, 138.5827],
  [-34.9425, 138.5829]
];

const parklandsBypass = [
  [-34.9235, 138.5800],
  [-34.9246, 138.5801],
  [-34.9283, 138.5807],
  [-34.9425, 138.5829]
];

const parklandsTerminalJunctionGoodwood = [
  [-34.9425, 138.5829],
  [-34.9463, 138.5838],
  [-34.9496, 138.5843],
  [-34.9523, 138.5852],
  [-34.9543, 138.5864]
];

const goodwoodMitcham = [
  [-34.9543, 138.5864],
  [-34.9550, 138.5868],
  [-34.9642, 138.5957],
  [-34.9736, 138.6045],
  [-34.9773, 138.6085]
];

const mitchamSleepsHill = [
  [-34.9773, 138.6085],
  [-34.9781, 138.6093],
  [-34.9789, 138.6099],
  [-34.9814, 138.6109],
  [-34.9825, 138.6110],
  [-34.9844, 138.6110],
  [-34.9863, 138.6106],
  [-34.9881, 138.6097],
  [-34.9932, 138.6069],
  [-34.9947, 138.6061],
  [-34.9962, 138.6049],
  [-34.9990, 138.6022],
  [-35.0004, 138.6012],
  [-35.0022, 138.6007],
  [-35.0043, 138.6009]
];

const sleepsHillOldStart = [
  [-35.0043, 138.6009],
  [-35.0048, 138.6006],
  [-35.0053, 138.6002],
  [-35.0057, 138.5996]
];

const sleepsHillOldTunnel1 = [
  [-35.0057, 138.5996],
  [-35.0066, 138.5978]
];

const sleepsHillOldMiddle = [
  [-35.0066, 138.5978],
  [-35.0076, 138.5957],
  [-35.0082, 138.5950],
  [-35.0090, 138.5947],
  [-35.0097, 138.5948],
  [-35.0105, 138.5953],
  [-35.0114, 138.5966]
];

const sleepsHillOldTunnel2 = [
  [-35.0114, 138.5966],
  [-35.0137, 138.5996]
];

const sleepsHillOldEnd = [
  [-35.0137, 138.5996],
  [-35.0144, 138.6000],
  [-35.0153, 138.5999],
  [-35.0163, 138.5992]
];

const sleepsHillDeviationStart = [
  [-35.0043, 138.6009],
  [-35.0048, 138.6009],
  [-35.0064, 138.6007]
];

const sleepsHillDeviationTunnel = [
  [-35.0064, 138.6007],
  [-35.0130, 138.6011]
];

const sleepsHillDeviationEnd = [
  [-35.0130, 138.6011],
  [-35.0138, 138.6011],
  [-35.0152, 138.6005],
  [-35.0163, 138.5992]
];

const sleepsHillEdenHills = [
  [-35.0163, 138.5992],
  [-35.0167, 138.5979],
  [-35.0168, 138.5958],
  [-35.0172, 138.5949],
  [-35.0180, 138.5941],
  [-35.0187, 138.5939],
  [-35.0199, 138.5942],
  [-35.0215, 138.5943]
];

const edenHillsEdenHillsTunnel = [
  [-35.0215, 138.5943],
  [-35.0219, 138.5943],
  [-35.0239, 138.5939],
  [-35.0256, 138.5932],
  [-35.0267, 138.5931],
  [-35.0280, 138.5938]
];

const edenHillsTunnel = [
  [-35.0280, 138.5938],
  [-35.0291, 138.5947]
];

const edenHillsTunnelCoromandelTunnel = [
  [-35.0291, 138.5947],
  [-35.0308, 138.5962],
  [-35.0314, 138.5970],
  [-35.0316, 138.5981],
  [-35.0313, 138.5992],
  [-35.0305, 138.6004],
  [-35.0301, 138.6015],
  [-35.0300, 138.6025],
  [-35.0302, 138.6035],
  [-35.0301, 138.6045],
  [-35.0298, 138.6055],
  [-35.0289, 138.6074],
  [-35.0279, 138.6085],
  [-35.0266, 138.6090],
  [-35.0259, 138.6095],
  [-35.0253, 138.6103],
  [-35.0251, 138.6111],
  [-35.0251, 138.6117],
  [-35.0252, 138.6158],
  [-35.0251, 138.6169],
  [-35.0249, 138.6175]
];

const coromandelTunnel = [
  [-35.0249, 138.6175],
  [-35.0247, 138.6179],
  [-35.0242, 138.6186]
];

const coromandelTunnelBlackwood = [
  [-35.0242, 138.6186],
  [-35.0238, 138.6189],
  [-35.0227, 138.6195],
  [-35.0220, 138.6196],
  [-35.0211, 138.6193],
  [-35.0207, 138.6190]
];

const blackwoodPineraTunnel = [
  [-35.0207, 138.6190],
  [-35.0200, 138.6186],
  [-35.0192, 138.6184],
  [-35.0184, 138.6184],
  [-35.0176, 138.6188],
  [-35.0167, 138.6195],
  [-35.0150, 138.6215],
  [-35.0140, 138.6223],
  [-35.0123, 138.6230],
  [-35.0113, 138.6232],
  [-35.0099, 138.6233],
  [-35.0084, 138.6236],
  [-35.0068, 138.6242],
  [-35.0059, 138.6243],
  [-35.0050, 138.6242],
  [-35.0040, 138.6237],
  [-35.0031, 138.6232],
  [-35.0022, 138.6232],
  [-35.0018, 138.6234]
];

const pineraTunnel = [
  [-35.0018, 138.6234],
  [-35.0012, 138.6239]
];

const pineraTunnelBelair = [
  [-35.0012, 138.6239],
  [-35.0000, 138.6248],
  [-34.9993, 138.6255],
  [-34.9977, 138.6280],
  [-34.9974, 138.6289],
  [-34.9974, 138.6298],
  [-34.9978, 138.6308],
  [-34.9980, 138.6317],
  [-34.9981, 138.6325],
  [-34.9980, 138.6329]
];

const gaolLoop = [
  [-34.9196, 138.5844],
  [-34.9190, 138.5852],
  [-34.9183, 138.5856],
  [-34.9176, 138.5857],
  [-34.9169, 138.5855]
];

export const belair: Line = {
  name: 'Belair',
  state: 'SA',
  segments: [
    {
      segments: [adelaideGaolLoop],
      history: {
        opened: {
          date: '1880-05-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1908-01-01',
          tracks: 2
        }, {
          date: '1953-01-01',
          tracks: 4
        }, {
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gaolLoopMileEndJunction],
      history: {
        opened: {
          date: '1880-05-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1908-01-01',
          tracks: 2
        }, {
          date: '1953-01-01',
          tracks: 4
        }, {
          date: '1984-01-01',
          parallelStandard: true,
          tracks: 3
        }, {
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [parklandsLoopInner, parklandsLoopOuter],
      history: {
        opened: {
          date: '1984-01-01',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [parklandsBypass],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1908-01-01',
          tracks: 2
        }, {
          date: '1953-01-01',
          tracks: 4
        }, {
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [parklandsTerminalJunctionGoodwood],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1908-01-01',
          tracks: 2
        }, {
          date: '1953-01-01',
          tracks: 4
        }, {
          date: '1995-01-01',
          parallelStandard: true,
          tracks: 3
        },{
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [goodwoodMitcham],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1908-01-01',
          tracks: 2
        }, {
          date: '1995-01-01',
          tracks: 1,
          parallelStandard: true
        }]
      }
    },
    {
      segments: [
        mitchamSleepsHill,
        {
          date: '1919-01-01',
          original: [
            sleepsHillOldStart,
            {
              name: 'Sleeps Hill Tunnel 1 (old)',
              segment: sleepsHillOldTunnel1,
              type: 'tunnel'
            },
            sleepsHillOldMiddle,
            {
              name: 'Sleeps Hill Tunnel 2 (old)',
              segment: sleepsHillOldTunnel2,
              type: 'tunnel'
            },
            sleepsHillOldEnd
          ],
          deviation: [
            sleepsHillDeviationStart,
            {
              name: 'Sleeps Hill Tunnel',
              segment: sleepsHillDeviationTunnel,
              type: 'tunnel'
            },
            sleepsHillDeviationEnd
          ]
        },
        sleepsHillEdenHills
      ],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1919-01-01',
          tracks: 2
        }, {
          date: '1995-01-01',
          tracks: 1,
          parallelStandard: true
        }]
      }
    },
    {
      segments: [
        edenHillsEdenHillsTunnel,
        {
          name: 'Eden Hills Tunnel',
          segment: edenHillsTunnel,
          type: 'tunnel'
        },
        edenHillsTunnelCoromandelTunnel,
        {
          date: '1928-06-24',
          original: [{
            name: 'Coromandel Tunnel',
            segment: coromandelTunnel,
            type: 'tunnel'
          }],
          deviation: [coromandelTunnel]
        },
        coromandelTunnelBlackwood
      ],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1928-06-24',
          tracks: 2
        }, {
          date: '1995-01-01',
          tracks: 1,
          parallelStandard: true
        }]
      }
    },
    {
      segments: [
        blackwoodPineraTunnel,
        {
          date: '1928-06-24',
          original: [{
            name: 'Pinera Tunnel',
            segment:  pineraTunnel,
            type: 'tunnel'
          }],
          deviation: [pineraTunnel] //opened to a cutting
        },
        pineraTunnelBelair
      ],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1928-06-24',
          tracks: 2
        }, {
          date: '1995-01-01',
          tracks: 1,
          parallelStandard: true
        }]
      }
    },
    {
      segments: [gaolLoop],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-01',
          parallelStandard: true
        }, {
          date: '2005-01-01',
          parallelStandard: false,
          gauge: 'standard'
        }]
      }
    }
  ]
};
