import { Line } from "../../../trackTypes";

//north line route N
const northLine = [
  [-32.05610, 115.74184],
  [-32.05602, 115.74181],
  [-32.05599, 115.74182],
  [-32.05595, 115.74184],
  [-32.05590, 115.74191],
  [-32.05571, 115.74253],
  [-32.05423, 115.74728],
  [-32.05417, 115.74739],
  [-32.05405, 115.74747],
  [-32.05069, 115.74970],
  [-32.05053, 115.74979],
  [-32.05035, 115.74984],
  [-32.05023, 115.74984],
  [-32.04982, 115.74984],
  [-32.04968, 115.74990],
  [-32.04476, 115.75320],
  [-32.04395, 115.75365],
  [-32.04355, 115.75392],
  [-32.04325, 115.75424],
  [-32.04305, 115.75450],
  [-32.04272, 115.75518],
  [-32.04264, 115.75539],
  [-32.04260, 115.75564],
  [-32.04248, 115.75694],
  [-32.04240, 115.75739],
  [-32.04231, 115.75778],
  [-32.04170, 115.75951],
  [-32.04161, 115.75980],
  [-32.04151, 115.76017],
  [-32.04124, 115.76136]
];

const northLineClosed = [
  [-32.04124, 115.76136],
  [-32.04113, 115.76146],
  [-32.04105, 115.76150],
  [-32.04097, 115.76146],
  [-32.03461, 115.75402],
  [-32.03413, 115.75353],
  [-32.03376, 115.75326],
  [-32.03318, 115.75304],
  [-32.02933, 115.75230],
  [-32.02906, 115.75229],
  [-32.02885, 115.75230],
  [-32.02854, 115.75233]
];

// Melville line route E
const melvilleLine = [
  [-32.04124, 115.76136],
  [-32.04102, 115.76237],
  [-32.04099, 115.76276],
  [-32.04093, 115.76807],
  [-32.04090, 115.76836],
  [-32.04085, 115.76857],
  [-32.04057, 115.76962]
];

const petraStreetExtension = [
  [-32.04057, 115.76962],
  [-32.04041, 115.77005],
  [-32.04032, 115.77028],
  [-32.03949, 115.77161],
  [-32.03934, 115.77192],
  [-32.03922, 115.77226],
  [-32.03835, 115.77592]
];

const stockRoadExtension = [
  [-32.03835, 115.77592],
  [-32.03628, 115.78444],
  [-32.03422, 115.79342]
];

//point walter line
const pointWalter = [
  [-32.03628, 115.78444],
  [-32.03620, 115.78454],
  [-32.03613, 115.78458],
  [-32.02896, 115.78451],
  [-32.02275, 115.78445],
  [-32.02253, 115.78447],
  [-32.02233, 115.78453],
  [-32.02122, 115.78502],
  [-32.02100, 115.78512],
  [-32.01995, 115.78547],
  [-32.01975, 115.78556],
  [-32.01961, 115.78566],
  [-32.01950, 115.78576],
  [-32.01766, 115.78805],
  [-32.01752, 115.78818],
  [-32.01738, 115.78825],
  [-32.01724, 115.78828],
  [-32.01704, 115.78826],
  [-32.01425, 115.78748],
  [-32.01388, 115.78732],
  [-32.01191, 115.78645]
];

//cemetery line route E
const marmionStreet = [
  [-32.05423, 115.74728],
  [-32.05218, 115.75386],
  [-32.05088, 115.75803],
  [-32.05082, 115.75815],
  [-32.05076, 115.75820],
  [-32.05063, 115.75821],
  [-32.04810, 115.75819],
  [-32.04747, 115.75816],
  [-32.04741, 115.75821],
  [-32.04737, 115.75829],
  [-32.04736, 115.75841],
  [-32.04725, 115.76383]
];

const cemeteryExtesion = [
  [-32.04725, 115.76383],
  [-32.04701, 115.77997],
  [-32.04696, 115.78462]
];

//marmion line route M
const marmionLine = [
  [-32.04725, 115.76383],
  [-32.04733, 115.76398],
  [-32.04746, 115.76411],
  [-32.04760, 115.76417],
  [-32.05043, 115.76421],
  [-32.05058, 115.76430],
  [-32.05065, 115.76445],
  [-32.05068, 115.76465],
  [-32.05047, 115.77919]
];

//beaconsfield line route B
const beaconsfieldLine = [
  [-32.05218, 115.75386],
  [-32.05220, 115.75397],
  [-32.05224, 115.75403],
  [-32.05289, 115.75430],
  [-32.05305, 115.75438],
  [-32.05334, 115.75458],
  [-32.05349, 115.75470],
  [-32.05359, 115.75477],
  [-32.05372, 115.75481],
  [-32.05590, 115.75503],
  [-32.05619, 115.75508],
  [-32.05827, 115.75585],
  [-32.06174, 115.75693],
  [-32.06183, 115.75697],
  [-32.06190, 115.75704],
  [-32.06196, 115.75712],
  [-32.06278, 115.75913],
  [-32.06284, 115.75921],
  [-32.06291, 115.75925],
  [-32.06336, 115.75939],
  [-32.06344, 115.75944],
  [-32.06348, 115.75951],
  [-32.06350, 115.75959],
  [-32.06327, 115.77957]
];

//south line route S
const southLine = [
  [-32.05571, 115.74253],
  [-32.05571, 115.74245],
  [-32.05568, 115.74240],
  [-32.05514, 115.74219],
  [-32.05508, 115.74221],
  [-32.05503, 115.74226],
  [-32.05499, 115.74239],
  [-32.05494, 115.74250],
  [-32.05431, 115.74319],
  [-32.05296, 115.74439],
  [-32.05287, 115.74449],
  [-32.05281, 115.74459],
  [-32.05276, 115.74470],
  [-32.05254, 115.74542],
  [-32.05254, 115.74549],
  [-32.05257, 115.74555],
  [-32.05267, 115.74564],
  [-32.05288, 115.74576],
  [-32.05521, 115.74675],
  [-32.05531, 115.74683],
  [-32.05536, 115.74690],
  [-32.05658, 115.74924],
  [-32.05674, 115.74946],
  [-32.05754, 115.75023],
  [-32.05773, 115.75037],
  [-32.06159, 115.75230],
  [-32.06181, 115.75236],
  [-32.06722, 115.75248],
  [-32.07377, 115.75314]
];

const southTerraceJunction1 = [
  [-32.05440, 115.74641],
  [-32.05445, 115.74648],
  [-32.05446, 115.74656]
];

const southTerraceJunction2 = [
  [-32.05453, 115.74634],
  [-32.05453, 115.74642],
  [-32.05458, 115.74648]
];

export const fremantleTrams: Line = {
  name: 'Fremantle Trams',
  state: 'WA',
  segments: [
    {
      segments: [northLine],
      history: {
        opened: {
          date: '1905-10-30',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northLineClosed],
      history: {
        opened: {
          date: '1905-10-30',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-11-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [melvilleLine],
      history: {
        opened: {
          date: '1906-04-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [petraStreetExtension],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stockRoadExtension],
      history: {
        opened: {
          date: '1915-12-15',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pointWalter],
      history: {
        opened: {
          date: '1915-12-15',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [marmionLine],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [marmionStreet],
      history: {
        opened: {
          date: '1906-04-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cemeteryExtesion],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [beaconsfieldLine],
      history: {
        opened: {
          date: '1906-04-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southLine, southTerraceJunction1, southTerraceJunction2],
      history: {
        opened: {
          date: '1905-10-30',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-11-08',
          status: 'closed'
        }]
      }
    }
  ]
};
