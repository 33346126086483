import { Line } from "../../trackTypes";

const goobangJunctionPeakHill = [
  [-33.1380, 148.1403],
  [-33.1314, 148.1307],
  [-33.1296, 148.1292],
  [-33.1168, 148.1218],
  [-33.0952, 148.1143],
  [-33.0919, 148.1138],
  [-33.0660, 148.1133],
  [-33.0626, 148.1138],
  [-33.0299, 148.1244],
  [-33.0278, 148.1245],
  [-33.0262, 148.1241],
  [-33.0198, 148.1217],
  [-33.0175, 148.1214],
  [-33.0154, 148.1217],
  [-32.9836, 148.1348],
  [-32.9803, 148.1368],
  [-32.9384, 148.1713],
  [-32.9358, 148.1725],
  [-32.9336, 148.1730],
  [-32.9057, 148.1753],
  [-32.8824, 148.1771],
  [-32.8794, 148.1779],
  [-32.8768, 148.1797],
  [-32.8531, 148.2028],
  [-32.8500, 148.2044],
  [-32.8471, 148.2046],
  [-32.8265, 148.2024],
  [-32.8245, 148.2027],
  [-32.8225, 148.2035],
  [-32.8088, 148.2126],
  [-32.8066, 148.2132],
  [-32.7913, 148.2150],
  [-32.7887, 148.2146],
  [-32.7789, 148.2100],
  [-32.7765, 148.2079],
  [-32.7716, 148.2003],
  [-32.7697, 148.1986],
  [-32.7678, 148.1978],
  [-32.7649, 148.1972],
  [-32.7637, 148.1968],
  [-32.7376, 148.1857],
  [-32.7345, 148.1849],
  [-32.7283, 148.1822]
];

const peakHilllNarromine = [
  [-32.7283, 148.1822],
  [-32.7126, 148.1756],
  [-32.7104, 148.1755],
  [-32.7086, 148.1760],
  [-32.7057, 148.1779],
  [-32.7032, 148.1783],
  [-32.6404, 148.1643],
  [-32.6368, 148.1644],
  [-32.6268, 148.1661],
  [-32.6230, 148.1660],
  [-32.5831, 148.1580],
  [-32.5806, 148.1570],
  [-32.5552, 148.1437],
  [-32.5420, 148.1380],
  [-32.5397, 148.1375],
  [-32.4615, 148.1306],
  [-32.4402, 148.1344],
  [-32.4227, 148.1396],
  [-32.4072, 148.1429],
  [-32.4051, 148.1439],
  [-32.3899, 148.1535],
  [-32.3672, 148.1658],
  [-32.3453, 148.1701],
  [-32.3416, 148.1715],
  [-32.2694, 148.2124],
  [-32.2657, 148.2137],
  [-32.2593, 148.2147],
  [-32.2572, 148.2157],
  [-32.2406, 148.2289],
  [-32.2330, 148.2315],
  [-32.2320, 148.2327],
  [-32.2315, 148.2345],
  [-32.2318, 148.2362]
];

export const parkesNarromine: Line = {
  name: 'Parkes - Narromine',
  state: 'NSW',
  segments: [
    {
      segments: [goobangJunctionPeakHill],
      history: {
        opened: {
          date: '1914-09-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [peakHilllNarromine],
      history: {
        opened: {
          date: '1910-12-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
