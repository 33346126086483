import { Line } from "../../trackTypes";

const line = [
  [-32.2472, 148.6193],
  [-32.2468, 148.6187],
  [-32.2463, 148.6183],
  [-32.2457, 148.6179],
  [-32.2448, 148.6178],
  [-32.2437, 148.6179],
  [-32.2346, 148.6198],
  [-32.2335, 148.6199],
  [-32.2170, 148.6184],
  [-32.2157, 148.6185],
  [-32.2113, 148.6188],
  [-32.2103, 148.6192],
  [-32.2096, 148.6197],
  [-32.2086, 148.6201],
  [-32.2075, 148.6203],
  [-32.2066, 148.6201],
  [-32.2055, 148.6198],
  [-32.2045, 148.6197],
  [-32.1952, 148.6217],
  [-32.1932, 148.6230],
  [-32.1932, 148.6230],
  [-32.1932, 148.6230],
  [-32.1900, 148.6269],
  [-32.1853, 148.6282],
  [-32.1785, 148.6307],
  [-32.1738, 148.6318],
  [-32.1462, 148.6365],
  [-32.1424, 148.6379],
  [-32.1161, 148.6572],
  [-32.1124, 148.6591],
  [-32.1086, 148.6599],
  [-32.1064, 148.6599],
  [-32.1035, 148.6607],
  [-32.0646, 148.6667],
  [-32.0605, 148.6668],
  [-32.0563, 148.6658],
  [-32.0536, 148.6647],
  [-32.0245, 148.6504],
  [-32.0213, 148.6482],
  [-32.0186, 148.6455],
  [-32.0133, 148.6391],
  [-32.0085, 148.6353],
  [-32.0039, 148.6333],
  [-32.0006, 148.6326],
  [-31.9870, 148.6313],
  [-31.9834, 148.6301],
  [-31.9626, 148.6180],
  [-31.9591, 148.6173],
  [-31.9562, 148.6178],
  [-31.9381, 148.6259],
  [-31.9351, 148.6266],
  [-31.9155, 148.6283],
  [-31.9131, 148.6290],
  [-31.8793, 148.6472],
  [-31.8775, 148.6478],
  [-31.8700, 148.6491],
  [-31.8672, 148.6502],
  [-31.8613, 148.6542],
  [-31.8591, 148.6552],
  [-31.8349, 148.6624],
  [-31.8316, 148.6626],
  [-31.8128, 148.6611],
  [-31.7884, 148.6626],
  [-31.7872, 148.6625],
  [-31.7746, 148.6609],
  [-31.7287, 148.6548],
  [-31.7189, 148.6546],
  [-31.7088, 148.6525],
  [-31.7072, 148.6516],
  [-31.7036, 148.6485],
  [-31.6683, 148.6229],
  [-31.6653, 148.6212],
  [-31.6410, 148.6122],
  [-31.6231, 148.6056],
  [-31.6194, 148.6038],
  [-31.6101, 148.5987],
  [-31.6043, 148.5946],
  [-31.5562, 148.5495],
  [-31.4973, 148.4979],
  [-31.4927, 148.4951],
  [-31.4112, 148.4685],
  [-31.3818, 148.4628],
  [-31.3608, 148.4588],
  [-31.3574, 148.4576],
  [-31.3460, 148.4525],
  [-31.3348, 148.4461],
  [-31.3331, 148.4453],
  [-31.2948, 148.4327],
  [-31.2116, 148.4059],
  [-31.1820, 148.3972],
  [-31.1790, 148.3961],
  [-31.1395, 148.3793],
  [-31.0649, 148.3548],
  [-31.0445, 148.3482],
  [-31.0398, 148.3480],
  [-31.0098, 148.3532],
  [-31.0075, 148.3539],
  [-30.9763, 148.3709],
  [-30.9712, 148.3724],
  [-30.9687, 148.3738],
  [-30.9667, 148.3763],
  [-30.9639, 148.3780],
  [-30.9536, 148.3799]
];

const junction = [
  [-32.2459, 148.6144],
  [-32.2457, 148.6157],
  [-32.2453, 148.6167],
  [-32.2447, 148.6174],
  [-32.2437, 148.6179]
];

export const coonamble: Line = {
  name: 'Coonamble',
  state: 'NSW',
  segments: [
    {
      segments: [line, junction],
      history: {
        opened: {
          date: '1903-02-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
