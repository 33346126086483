import { Line } from "../../../trackTypes";

const depot = [
  [-46.40751, 168.34584],
  [-46.40752, 168.34705],
  [-46.40755, 168.34720],
  [-46.40765, 168.34731]
];

const waikiwi = [
  [-46.40765, 168.34731],
  [-46.39201, 168.34726],
  [-46.38947, 168.34734],
  [-46.37579, 168.34736]
];

const rugbyParkStadium = [
  [-46.41771, 168.35374],
  [-46.41783, 168.35385],
  [-46.41790, 168.35410],
  [-46.41790, 168.36108]
];

const georgetown = [
  [-46.41790, 168.36108],
  [-46.41799, 168.36129],
  [-46.41819, 168.36142],
  [-46.42005, 168.36145],
  [-46.42013, 168.36149],
  [-46.42016, 168.36163],
  [-46.42016, 168.36652],
  [-46.42021, 168.36667],
  [-46.42034, 168.36679],
  [-46.42055, 168.36680],
  [-46.42063, 168.36685],
  [-46.42067, 168.36699],
  [-46.42067, 168.36922],
  [-46.42071, 168.36937],
  [-46.42085, 168.36947],
  [-46.42249, 168.36949]
];

const northInvercargill = [
  [-46.40876, 168.34730],
  [-46.40863, 168.34744],
  [-46.40859, 168.34769],
  [-46.40857, 168.36399],
  [-46.40853, 168.36410],
  [-46.40843, 168.36419],
  [-46.40341, 168.36415],
  [-46.40333, 168.36419],
  [-46.40328, 168.36425],
  [-46.40321, 168.36447],
  [-46.40312, 168.36458],
  [-46.40303, 168.36463],
  [-46.39856, 168.36465],
  [-46.39846, 168.36474],
  [-46.39842, 168.36482],
  [-46.39838, 168.36493],
  [-46.39838, 168.36541],
  [-46.39835, 168.36552],
  [-46.39828, 168.36558],
  [-46.39490, 168.36560]
];

const southInvercargill = [
  [-46.40765, 168.34731],
  [-46.40876, 168.34730],
  [-46.41282, 168.34735],
  [-46.41296, 168.34745],
  [-46.41303, 168.34774],
  [-46.41301, 168.35341],
  [-46.41308, 168.35365],
  [-46.41328, 168.35374],
  [-46.41771, 168.35374],
  [-46.42685, 168.35376],
  [-46.42693, 168.35382],
  [-46.42696, 168.35397],
  [-46.42696, 168.36120],
  [-46.42702, 168.36136],
  [-46.42715, 168.36147],
  [-46.43241, 168.36148]
];

export const invercargillTrams: Line = {
  name: 'Invercargill Trams',
  state: 'NZ',
  segments: [
    {
      segments: [depot],
      history: {
        opened: {
          date: '1912-03-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-05-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waikiwi],
      history: {
        opened: {
          date: '1881-12-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1912-03-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rugbyParkStadium],
      history: {
        opened: {
          date: '1912-03-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1951-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [georgetown],
      history: {
        opened: {
          date: '1912-03-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1951-07-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northInvercargill],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southInvercargill],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-05-31',
          status: 'closed'
        }]
      }
    }
  ]
}
