import { Line } from "../../trackTypes";

const line = [
  [-33.7290, 150.3014],
  [-33.7312, 150.3019]
];

export const katoombaScenicRailway: Line = {
  name: 'Katoomba Scenic Railway',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1928-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'funicular'
        }
      }
    }
  ]
};
