import { Line } from "../../trackTypes";

const line = [
  [-34.8088, 148.9164],
  [-34.8107, 148.9111],
  [-34.8117, 148.9099],
  [-34.8128, 148.9094],
  [-34.8282, 148.9077],
  [-34.8341, 148.9060],
  [-34.8360, 148.9059],
  [-34.8398, 148.9063],
  [-34.8404, 148.9065],
  [-34.8441, 148.9098],
  [-34.8444, 148.9104],
  [-34.8449, 148.9117]
];

export const yass: Line = {
  name: 'Yass',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1892-04-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-11-14',
          status: 'closed'
        }]
      }
    }
  ]
};
