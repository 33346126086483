import { Line } from "../../../trackTypes";

const line = [
  [-39.2677, 174.2563],
  [-39.2688, 174.2562],
  [-39.2698, 174.2556],
  [-39.2703, 174.2549],
  [-39.2718, 174.2468],
  [-39.2719, 174.2445],
  [-39.2720, 174.2433],
  [-39.2725, 174.2423],
  [-39.2751, 174.2397],
  [-39.2758, 174.2389],
  [-39.2761, 174.2384],
  [-39.2770, 174.2357],
  [-39.2774, 174.2350],
  [-39.2780, 174.2344],
  [-39.2796, 174.2335],
  [-39.2805, 174.2325],
  [-39.2810, 174.2314],
  [-39.2877, 174.2057],
  [-39.2896, 174.1925],
  [-39.2922, 174.1795],
  [-39.2924, 174.1740],
  [-39.2926, 174.1724],
  [-39.2926, 174.1715],
  [-39.2926, 174.1710],
  [-39.2927, 174.1706],
  [-39.2930, 174.1704],
  [-39.2933, 174.1701],
  [-39.2941, 174.1687],
  [-39.2954, 174.1656]
];

export const mountEgmont: Line = {
  name: 'Mount Egmont',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
