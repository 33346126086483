import { Line } from "../../../trackTypes";

const saintPeters = [
  [-33.8845, 151.1948],
  [-33.8873, 151.1935],
  [-33.8880, 151.1926],
  [-33.8892, 151.1904],
  [-33.8922, 151.1873],
  [-33.8931, 151.1841],
  [-33.8935, 151.1834],
  [-33.8958, 151.1810],
  [-33.8967, 151.1795],
  [-33.8974, 151.1790],
  [-33.8988, 151.1778],
  [-33.8991, 151.1778],
  [-33.9000, 151.1780],
  [-33.9028, 151.1794],
  [-33.9037, 151.1800],
  [-33.9067, 151.1814],
  [-33.9072, 151.1815],
  [-33.9087, 151.1812],
  [-33.9121, 151.1795],
  [-33.9137, 151.1781],
  [-33.9148, 151.1765],
  [-33.9154, 151.1746],
  [-33.9169, 151.1723],
  [-33.9192, 151.1692],
  [-33.9205, 151.1676]
];

const saintPetersCooksRiver = [
  [-33.9205, 151.1676],
  [-33.9209, 151.1663],
  [-33.9213, 151.1649],
  [-33.9216, 151.1644],
  [-33.9259, 151.1593],
  [-33.9271, 151.1584],
  [-33.9308, 151.1551],
  [-33.9301, 151.1542],
  [-33.9297, 151.1526]
];

const dulwichHill = [
  [-33.8974, 151.1790],
  [-33.8985, 151.1763],
  [-33.8989, 151.1722],
  [-33.8992, 151.1712],
  [-33.8995, 151.1708],
  [-33.9053, 151.1677],
  [-33.9055, 151.1674],
  [-33.9058, 151.1655],
  [-33.9061, 151.1651],
  [-33.9122, 151.1601],
  [-33.9105, 151.1563],
  [-33.9086, 151.1523],
  [-33.9066, 151.1458],
  [-33.9033, 151.1423]
];

const canterburyCityRoute = [
  [-33.8879, 151.1591],
  [-33.8892, 151.1585],
  [-33.8899, 151.1585],
  [-33.8910, 151.1584],
  [-33.8940, 151.1573],
  [-33.8939, 151.1564],
  [-33.8941, 151.1556],
  [-33.8946, 151.1542],
  [-33.8962, 151.1541],
  [-33.8965, 151.1525],
  [-33.8971, 151.1510],
  [-33.8971, 151.1505],
  [-33.8967, 151.1490],
  [-33.8967, 151.1486],
  [-33.8970, 151.1479],
  [-33.8989, 151.1454],
  [-33.8993, 151.1450],
  [-33.9023, 151.1445],
  [-33.9029, 151.1430],
  [-33.9033, 151.1423]
];

const canterburyCityDeviation = [
  [-33.8899, 151.1585],
  [-33.8898, 151.1565],
  [-33.8888, 151.1567],
  [-33.8884, 151.1565],
  [-33.8882, 151.1576]
];

const hurlstoneParkExtension = [
  [-33.9033, 151.1423],
  [-33.9057, 151.1360],
  [-33.9057, 151.1345],
  [-33.9051, 151.1332],
  [-33.9048, 151.1289],
  [-33.9046, 151.1285]
];

const hurlstoneParkCanterburyStation = [
  [-33.9046, 151.1285],
  [-33.9075, 151.1259],
  [-33.9120, 151.1192],
  [-33.9106, 151.1178]
];

const summerHill = [
  [-33.9046, 151.1285],
  [-33.9008, 151.1320],
  [-33.8962, 151.1323],
  [-33.8916, 151.1324],
  [-33.8915, 151.1349],
  [-33.8917, 151.1372],
  [-33.8918, 151.1380],
  [-33.8904, 151.1385],
  [-33.8901, 151.1379]
];

const earlwood = [
  [-33.9105, 151.1563],
  [-33.9117, 151.1551],
  [-33.9134, 151.1532],
  [-33.9147, 151.1524],
  [-33.9158, 151.1502],
  [-33.9172, 151.1486],
  [-33.9190, 151.1455],
  [-33.9194, 151.1450],
  [-33.9222, 151.1428],
  [-33.9233, 151.1424],
  [-33.9235, 151.1422],
  [-33.9237, 151.1415],
  [-33.9236, 151.1400],
  [-33.9232, 151.1385],
  [-33.9233, 151.1367],
  [-33.9241, 151.1352],
  [-33.9249, 151.1347],
  [-33.9253, 151.1341],
  [-33.9256, 151.1324],
  [-33.9268, 151.1292],
  [-33.9264, 151.1259],
  [-33.9265, 151.1256],
  [-33.9272, 151.1245]
];

const cooksRiverDulwichHill = [
  [-33.9192, 151.1692],
  [-33.9162, 151.1663],
  [-33.9154, 151.1662],
  [-33.9151, 151.1660],
  [-33.9146, 151.1655],
  [-33.9152, 151.1645],
  [-33.9127, 151.1611],
  [-33.9122, 151.1601]
];

const erskenville = [
  [-33.8876, 151.2015],
  [-33.8873, 151.1988],
  [-33.8886, 151.1989]
];

const centralStationWest = [
  [-33.8828, 151.2041],
  [-33.8846, 151.2031],
  [-33.8860, 151.2025],
  [-33.8868, 151.2017],
  [-33.8876, 151.2015]
];

const clevelandStreetErskineville = [
  [-33.8886, 151.1989],
  [-33.8893, 151.1981],
  [-33.8901, 151.1975],
  [-33.8910, 151.1964],
  [-33.8916, 151.1940],
  [-33.8933, 151.1890],
  [-33.8940, 151.1892],
  [-33.8954, 151.1848],
  [-33.8972, 151.1855],
  [-33.8991, 151.1856],
  [-33.8996, 151.1854],
  [-33.8998, 151.1849],
  [-33.8996, 151.1838],
  [-33.8994, 151.1836],
  [-33.8979, 151.1834],
  [-33.8977, 151.1841],
  [-33.8972, 151.1855]
];

const enmorePetersham = [
  [-33.9041, 151.1683],
  [-33.8996, 151.1573],
  [-33.8994, 151.1535],
  [-33.8975, 151.1536],
  [-33.8973, 151.1535],
  [-33.8970, 151.1533],
  [-33.8965, 151.1525]
];

export const sydneyTramsSouthWest: Line = {
  name: 'Sydney Trams (South-West)',
  state: 'NSW',
  segments: [
    {
      segments: [enmorePetersham],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [saintPeters],
      history: {
        opened: {
          date: '1891-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [saintPetersCooksRiver],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cooksRiverDulwichHill],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [canterburyCityRoute],
      history: {
        opened: {
          date: '1921-04-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dulwichHill],
      history: {
        opened: {
          date: '1900-04-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [canterburyCityDeviation],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hurlstoneParkExtension],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hurlstoneParkCanterburyStation],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [summerHill],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [earlwood],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [erskenville],
      history: {
        opened: {
          date: '1909-01-25',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [clevelandStreetErskineville],
      history: {
        opened: {
          date: '1909-01-25',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1940-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [centralStationWest],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1903-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
