import { Line } from "../../trackTypes";

const elmoreDiggoraWest = [
  [-36.4918, 144.6097],
  [-36.4893, 144.6097],
  [-36.4872, 144.6092],
  [-36.4859, 144.6084],
  [-36.4848, 144.6069],
  [-36.4718, 144.5765],
  [-36.4445, 144.5105],
  [-36.4427, 144.5084],
  [-36.4374, 144.5057],
  [-36.4357, 144.5052],
  [-36.3727, 144.5052]
];

const diggoraWestCohuna = [
  [-36.3727, 144.5052],
  [-36.3516, 144.5051],
  [-36.3495, 144.5057],
  [-36.3332, 144.5118],
  [-36.2977, 144.5251],
  [-36.2855, 144.5299],
  [-36.2784, 144.5326],
  [-36.2743, 144.5341],
  [-36.2648, 144.5355],
  [-36.2078, 144.5354],
  [-36.2035, 144.5351],
  [-36.1894, 144.5322],
  [-36.1844, 144.5301],
  [-36.1238, 144.5061],
  [-36.1207, 144.5042],
  [-36.1053, 144.4908],
  [-36.0610, 144.4527],
  [-36.0025, 144.4155],
  [-35.9632, 144.3765],
  [-35.9615, 144.3742],
  [-35.9598, 144.3705],
  [-35.9593, 144.3690],
  [-35.9591, 144.3603],
  [-35.9587, 144.3589],
  [-35.9574, 144.3572],
  [-35.9540, 144.3540],
  [-35.9418, 144.3407],
  [-35.9347, 144.3357],
  [-35.9317, 144.3330],
  [-35.9092, 144.3079],
  [-35.9039, 144.3025],
  [-35.8779, 144.2764],
  [-35.8537, 144.2519],
  [-35.8346, 144.2295],
  [-35.8326, 144.2277],
  [-35.8083, 144.2115]
];

export const cohuna: Line = {
  name: 'Cohuna',
  state: 'VIC',
  segments: [
    {
      segments: [elmoreDiggoraWest],
      history: {
        opened: {
          date: '1915-11-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [diggoraWestCohuna],
      history: {
        opened: {
          date: '1915-11-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-01',
          status: 'closed'
        }]
      }
    }
  ]
}
