import { Line } from "../../trackTypes";

const meltonParwan = [
  [-37.7032, 144.5722],
  [-37.7029, 144.5696],
  [-37.7029, 144.5681],
  [-37.7032, 144.5665],
  [-37.7041, 144.5645],
  [-37.7048, 144.5634],
  [-37.7056, 144.5625],
  [-37.7073, 144.5607],
  [-37.7135, 144.5525],
  [-37.7145, 144.5507],
  [-37.7152, 144.5490],
  [-37.7157, 144.5474],
  [-37.7161, 144.5453],
  [-37.7168, 144.5398],
  [-37.7170, 144.5385],
  [-37.7171, 144.5373],
  [-37.7171, 144.5348],
  [-37.7152, 144.5018],
  [-37.7149, 144.4992],
  [-37.7143, 144.4953],
  [-37.7131, 144.4908],
  [-37.7066, 144.4720],
  [-37.7061, 144.4702]
];

const parwanBaccusMarsh = [
  [-37.7061, 144.4702],
  [-37.7032, 144.4594],
  [-37.7028, 144.4580],
  [-37.7019, 144.4561],
  [-37.6995, 144.4527],
  [-37.6983, 144.4518],
  [-37.6972, 144.4511],
  [-37.6960, 144.4507],
  [-37.6944, 144.4505],
  [-37.6930, 144.4499],
  [-37.6915, 144.4488],
  [-37.6902, 144.4469],
  [-37.6894, 144.4447],
  [-37.6876, 144.4367]
];

const baccusMarshBallan = [
  [-37.6876, 144.4367],
  [-37.6871, 144.4341],
  [-37.6870, 144.4320],
  [-37.6876, 144.4300],
  [-37.6886, 144.4285],
  [-37.6902, 144.4270],
  [-37.6911, 144.4259],
  [-37.6930, 144.4231],
  [-37.6969, 144.4192],
  [-37.7001, 144.4167],
  [-37.7087, 144.4120],
  [-37.7239, 144.4036],
  [-37.7256, 144.4018],
  [-37.7269, 144.3985],
  [-37.7270, 144.3950],
  [-37.7259, 144.3920],
  [-37.7241, 144.3898],
  [-37.7218, 144.3887],
  [-37.7194, 144.3888],
  [-37.7177, 144.3897],
  [-37.7147, 144.3921],
  [-37.7115, 144.3938],
  [-37.7071, 144.3948],
  [-37.7044, 144.3949],
  [-37.7012, 144.3954],
  [-37.6987, 144.3949],
  [-37.6966, 144.3933],
  [-37.6949, 144.3910],
  [-37.6941, 144.3892],
  [-37.6923, 144.3865],
  [-37.6913, 144.3845],
  [-37.6898, 144.3762],
  [-37.6838, 144.3586],
  [-37.6819, 144.3554],
  [-37.6812, 144.3545],
  [-37.6707, 144.3447],
  [-37.6687, 144.3424],
  [-37.6674, 144.3398],
  [-37.6665, 144.3367],
  [-37.6661, 144.3330],
  [-37.6657, 144.3311],
  [-37.6638, 144.3274],
  [-37.6542, 144.3174],
  [-37.6524, 144.3146],
  [-37.6490, 144.3070],
  [-37.6484, 144.3054],
  [-37.6444, 144.2924],
  [-37.6436, 144.2903],
  [-37.6347, 144.2734],
  [-37.6332, 144.2711],
  [-37.6204, 144.2568],
  [-37.6178, 144.2532],
  [-37.6068, 144.2370],
  [-37.6051, 144.2333],
  [-37.6046, 144.2310],
  [-37.6042, 144.2280],
  [-37.6042, 144.2254]
];

const sunshineDeerParkJunction = [
  [-37.7870, 144.8318],
  [-37.7865, 144.8311],
  [-37.7860, 144.8303],
  [-37.7858, 144.8295],
  [-37.7814, 144.7864],
  [-37.7811, 144.7850],
  [-37.7807, 144.7828],
  [-37.7724, 144.7532],
  [-37.7701, 144.7452],
  [-37.7696, 144.7438],
  [-37.7670, 144.7378]
];

const deerParkJunctionMelton = [
  [-37.7670, 144.7378],
  [-37.7539, 144.7077],
  [-37.7241, 144.6390],
  [-37.7236, 144.6377],
  [-37.7042, 144.5785],
  [-37.7039, 144.5773],
  [-37.7038, 144.5768],
  [-37.7032, 144.5722]
];

const ballanGordon = [
  [-37.6042, 144.2254],
  [-37.6042, 144.2134],
  [-37.6039, 144.2103],
  [-37.6032, 144.2068],
  [-37.6025, 144.2046],
  [-37.5957, 144.1890],
  [-37.5949, 144.1867],
  [-37.5942, 144.1832],
  [-37.5939, 144.1791],
  [-37.5946, 144.1683],
  [-37.5949, 144.1664],
  [-37.5960, 144.1606],
  [-37.5962, 144.1585],
  [-37.5961, 144.1556],
  [-37.5957, 144.1535],
  [-37.5946, 144.1492],
  [-37.5935, 144.1415],
  [-37.5934, 144.1385],
  [-37.5935, 144.1365],
  [-37.5940, 144.1341],
  [-37.5949, 144.1306],
  [-37.5963, 144.1257],
  [-37.5969, 144.1223],
  [-37.5973, 144.1186],
  [-37.5974, 144.1147],
  [-37.5972, 144.1115],
  [-37.5966, 144.1075],
  [-37.5953, 144.1021],
  [-37.5944, 144.0994]
];

const gordonBungareeEast = [
  [-37.5944, 144.0994],
  [-37.5922, 144.0929],
  [-37.5917, 144.0911],
  [-37.5915, 144.0882],
  [-37.5914, 144.0729],
  [-37.5912, 144.0699]
];

const bungareeEastBungareeWest = [
  [-37.5912, 144.0699],
  [-37.5907, 144.0682],
  [-37.5896, 144.0661],
  [-37.5885, 144.0649],
  [-37.5876, 144.0642],
  [-37.5857, 144.0631],
  [-37.5840, 144.0624],
  [-37.5776, 144.0611],
  [-37.5758, 144.0604],
  [-37.5579, 144.0501],
  [-37.5571, 144.0494],
  [-37.5554, 144.0472],
  [-37.5516, 144.0398],
  [-37.5511, 144.0384],
  [-37.5508, 144.0369],
  [-37.5499, 144.0271],
  [-37.5499, 144.0262],
  [-37.5510, 144.0182],
  [-37.5514, 144.0159],
  [-37.5533, 144.0087],
  [-37.5541, 144.0071],
  [-37.5550, 144.0061],
  [-37.5562, 144.0051],
  [-37.5572, 144.0048],
  [-37.5624, 144.0040],
  [-37.5643, 144.0036],
  [-37.5662, 144.0029],
  [-37.5680, 144.0018],
  [-37.5691, 144.0010],
  [-37.5835, 143.9889],
  [-37.5851, 143.9869],
  [-37.5862, 143.9851],
  [-37.5867, 143.9836],
  [-37.5872, 143.9814]
]

const bungareeDeviation = [
  [-37.5912, 144.0699],
  [-37.5909, 144.0653],
  [-37.5909, 144.0635],
  [-37.5910, 144.0626],
  [-37.5964, 144.0335],
  [-37.5966, 144.0316],
  [-37.5966, 144.0281],
  [-37.5942, 144.0077],
  [-37.5934, 144.0048],
  [-37.5923, 144.0013],
  [-37.5882, 143.9903],
  [-37.5876, 143.9883],
  [-37.5873, 143.9862],
  [-37.5871, 143.9834],
  [-37.5872, 143.9814]
];

const warrenheipBungareeWest = [
  [-37.5786, 143.9248],
  [-37.5875, 143.9419],
  [-37.5885, 143.9446],
  [-37.5888, 143.9467],
  [-37.5890, 143.9492],
  [-37.5872, 143.9814]
];

const warrenheipBallarat = [
  [-37.5786, 143.9248],
  [-37.5766, 143.9216],
  [-37.5749, 143.9196],
  [-37.5736, 143.9185],
  [-37.5706, 143.9165],
  [-37.5690, 143.9154],
  [-37.5673, 143.9138],
  [-37.5659, 143.9122],
  [-37.5610, 143.9044],
  [-37.5594, 143.9007],
  [-37.5582, 143.8962],
  [-37.5575, 143.8905],
  [-37.5575, 143.8873],
  [-37.5598, 143.8659],
  [-37.5596, 143.8638],
  [-37.5594, 143.8615],
  [-37.5590, 143.8601],
  [-37.5586, 143.8594]
];

const ballaratNorthBallaratJunction = [
  [-37.5586, 143.8594],
  [-37.5581, 143.8584],
  [-37.5571, 143.8573],
  [-37.5559, 143.8564],
  [-37.5498, 143.8539]
];

const northBallaratJunctionLintonJunction = [
  [-37.5498, 143.8539],
  [-37.5474, 143.8522],
  [-37.5457, 143.8502],
  [-37.5440, 143.8474],
  [-37.5430, 143.8449],
  [-37.5425, 143.8424],
  [-37.5396, 143.8184],
  [-37.5394, 143.8176],
  [-37.5389, 143.8153],
  [-37.5378, 143.8120],
  [-37.5324, 143.8000]
];

export const ballarat: Line = {
  name: 'Ballarat',
  state: 'VIC',
  segments: [
    {
      segments: [warrenheipBungareeWest],
      history: {
        opened: {
          date: '1879-05-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [meltonParwan],
      history: {
        opened: {
          date: '1886-04-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [parwanBaccusMarsh],
      history: {
        opened: {
          date: '1887-02-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [deerParkJunctionMelton],
      history: {
        opened: {
          date: '1884-04-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-11-30',
          tracks: 2
        }]
      }
    },
    {
      segments: [ballanGordon],
      history: {
        opened: {
          date: '1886-12-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [gordonBungareeEast],
      history: {
        opened: {
          date: '1879-05-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bungareeEastBungareeWest],
      history: {
        opened: {
          date: '1879-05-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2020-12-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bungareeDeviation],
      history: {
        opened: {
          date: '2005-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [sunshineDeerParkJunction],
      history: {
        opened: {
          date: '1884-04-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-10-03',
          tracks: 2
        }]
      }
    },
    {
      segments: [warrenheipBallarat],
      history: {
        opened: {
          date: '1862-04-11',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [baccusMarshBallan],
      history: {
        opened: {
          date: '1889-12-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ballaratNorthBallaratJunction],
      history: {
        opened: {
          date: '1874-07-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-03-22',
          tracks: 2
        }, {
          date: '1992-07-24',
          tracks: 1
        }]
      }
    },
    {
      segments: [northBallaratJunctionLintonJunction],
      history: {
        opened: {
          date: '1874-08-11',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1890-03-10',
          tracks: 2
        }, {
          date: '1992-07-24',
          tracks: 1
        }]
      }
    }
  ]
};
