import { Line } from "../../trackTypes";

const cowraCanowindra = [
  [-33.8403, 148.6964],
  [-33.8412, 148.6959],
  [-33.8419, 148.6950],
  [-33.8421, 148.6940],
  [-33.8420, 148.6931],
  [-33.8418, 148.6921],
  [-33.8411, 148.6901],
  [-33.8403, 148.6892],
  [-33.8395, 148.6888],
  [-33.8386, 148.6886],
  [-33.8372, 148.6886],
  [-33.8363, 148.6882],
  [-33.8356, 148.6874],
  [-33.8345, 148.6860],
  [-33.8334, 148.6844],
  [-33.8327, 148.6840],
  [-33.8305, 148.6828],
  [-33.8288, 148.6815],
  [-33.8268, 148.6804],
  [-33.8249, 148.6799],
  [-33.8244, 148.6797],
  [-33.8239, 148.6791],
  [-33.8207, 148.6744],
  [-33.8199, 148.6738],
  [-33.8126, 148.6711],
  [-33.8096, 148.6707],
  [-33.8071, 148.6712],
  [-33.8012, 148.6733],
  [-33.7998, 148.6732],
  [-33.7856, 148.6668],
  [-33.7801, 148.6642],
  [-33.7785, 148.6629],
  [-33.7732, 148.6578],
  [-33.7600, 148.6476],
  [-33.7553, 148.6457],
  [-33.7462, 148.6450],
  [-33.7434, 148.6441],
  [-33.7257, 148.6342],
  [-33.6999, 148.6224],
  [-33.6836, 148.6150],
  [-33.6822, 148.6149],
  [-33.6813, 148.6154],
  [-33.6747, 148.6208],
  [-33.6737, 148.6211],
  [-33.6725, 148.6209],
  [-33.6716, 148.6202],
  [-33.6705, 148.6187],
  [-33.6694, 148.6179],
  [-33.6681, 148.6179],
  [-33.6666, 148.6188],
  [-33.6660, 148.6202],
  [-33.6658, 148.6218],
  [-33.6651, 148.6233],
  [-33.6643, 148.6241],
  [-33.6633, 148.6246],
  [-33.6622, 148.6256],
  [-33.6568, 148.6317],
  [-33.6558, 148.6322],
  [-33.6545, 148.6321],
  [-33.6521, 148.6310],
  [-33.6510, 148.6308],
  [-33.6466, 148.6310],
  [-33.6453, 148.6315],
  [-33.6360, 148.6379],
  [-33.6347, 148.6381],
  [-33.6336, 148.6375],
  [-33.6328, 148.6366],
  [-33.6318, 148.6349],
  [-33.6312, 148.6343],
  [-33.6303, 148.6339],
  [-33.6245, 148.6331],
  [-33.6234, 148.6336],
  [-33.6224, 148.6347],
  [-33.6219, 148.6361],
  [-33.6221, 148.6376],
  [-33.6243, 148.6423],
  [-33.6245, 148.6439],
  [-33.6240, 148.6454],
  [-33.6229, 148.6466],
  [-33.6213, 148.6468],
  [-33.6176, 148.6454],
  [-33.6164, 148.6454],
  [-33.6122, 148.6464],
  [-33.6111, 148.6463],
  [-33.6087, 148.6450],
  [-33.6076, 148.6448],
  [-33.5990, 148.6461],
  [-33.5979, 148.6459],
  [-33.5963, 148.6449],
  [-33.5952, 148.6445],
  [-33.5941, 148.6448],
  [-33.5892, 148.6475],
  [-33.5880, 148.6474],
  [-33.5870, 148.6469],
  [-33.5860, 148.6458],
  [-33.5847, 148.6446],
  [-33.5832, 148.6444],
  [-33.5818, 148.6452],
  [-33.5793, 148.6494],
  [-33.5767, 148.6529],
  [-33.5744, 148.6544],
  [-33.5707, 148.6554],
  [-33.5670, 148.6578],
  [-33.5649, 148.6589],
  [-33.5605, 148.6597]
];

const canowindraTrajere = [
  [-33.5605, 148.6597],
  [-33.5559, 148.6608],
  [-33.5544, 148.6606],
  [-33.5483, 148.6581],
  [-33.5458, 148.6555],
  [-33.5444, 148.6529],
  [-33.5425, 148.6506],
  [-33.5389, 148.6479],
  [-33.5379, 148.6467],
  [-33.5374, 148.6448],
  [-33.5413, 148.5781],
  [-33.5421, 148.5750],
  [-33.5430, 148.5740],
  [-33.5439, 148.5713],
  [-33.5445, 148.5687],
  [-33.5470, 148.5377],
  [-33.5467, 148.5360],
  [-33.5459, 148.5341],
  [-33.5456, 148.5323],
  [-33.5463, 148.5269],
  [-33.5472, 148.5235],
  [-33.5477, 148.5216],
  [-33.5489, 148.5025],
  [-33.5501, 148.4996],
  [-33.5517, 148.4979],
  [-33.5574, 148.4950],
  [-33.5612, 148.4942],
  [-33.5631, 148.4944],
  [-33.5654, 148.4953],
  [-33.5671, 148.4953],
  [-33.5688, 148.4944],
  [-33.5700, 148.4922],
  [-33.5702, 148.4898],
  [-33.5691, 148.4851],
  [-33.5675, 148.4822],
  [-33.5670, 148.4809],
  [-33.5658, 148.4762],
  [-33.5639, 148.4719],
  [-33.5631, 148.4689],
  [-33.5633, 148.4659],
  [-33.5642, 148.4633],
  [-33.5665, 148.4595],
  [-33.5677, 148.4570],
  [-33.5680, 148.4550],
  [-33.5672, 148.4497],
  [-33.5673, 148.4478],
  [-33.5684, 148.4446],
  [-33.5686, 148.4422],
  [-33.5677, 148.4393],
  [-33.5675, 148.4379],
  [-33.5678, 148.4357],
  [-33.5677, 148.4341],
  [-33.5668, 148.4318],
  [-33.5666, 148.4299],
  [-33.5661, 148.4287],
  [-33.5637, 148.4262],
  [-33.5620, 148.4255],
  [-33.5596, 148.4254],
  [-33.5573, 148.4247],
  [-33.5565, 148.4241],
  [-33.5553, 148.4223],
  [-33.5539, 148.4208],
  [-33.5516, 148.4200],
  [-33.5494, 148.4201],
  [-33.5446, 148.4188],
  [-33.5433, 148.4179],
  [-33.5385, 148.4117],
  [-33.5374, 148.4098],
  [-33.5360, 148.4063],
  [-33.5324, 148.4032],
  [-33.5302, 148.4023],
  [-33.5202, 148.4008],
  [-33.5185, 148.4008],
  [-33.5104, 148.4024],
  [-33.5088, 148.4021],
  [-33.5072, 148.4008],
  [-33.5048, 148.3961],
  [-33.5034, 148.3948],
  [-33.5004, 148.3933],
  [-33.4984, 148.3913],
  [-33.4969, 148.3899],
  [-33.4950, 148.3890],
  [-33.4922, 148.3888]
];

const trajereEugowra = [
  [-33.4922, 148.3888],
  [-33.4877, 148.3886],
  [-33.4865, 148.3879],
  [-33.4849, 148.3862],
  [-33.4830, 148.3855],
  [-33.4564, 148.3825],
  [-33.4541, 148.3829],
  [-33.4523, 148.3838],
  [-33.4508, 148.3843],
  [-33.4482, 148.3843],
  [-33.4402, 148.3822],
  [-33.4382, 148.3809],
  [-33.4371, 148.3791],
  [-33.4334, 148.3692]
];

export const eugowra: Line = {
  name: 'Eugowra',
  state: 'NSW',
  segments: [
    {
      segments: [cowraCanowindra],
      history: {
        opened: {
          date: '1910-10-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-10-21',
          status: 'closed'
        }, {
          date: '1996-01-01',
          status: 'closed'
        }, {
          date: '1994-06-07',
          status: 'open'
        }]
      }
    },
    {
      segments: [canowindraTrajere],
      history: {
        opened: {
          date: '1922-12-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-10-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [trajereEugowra],
      history: {
        opened: {
          date: '1922-12-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-01-17',
          status: 'closed'
        }]
      }
    }
  ]
};
