import { Line } from "../../trackTypes";

const wellingtonPipiteaPoint = [
  [-41.2789, 174.7805],
  [-41.2760, 174.7833],
  [-41.2741, 174.7842]
];

const pipiteaPointJohnsonvilleJunction = [
  [-41.2741, 174.7842],
  [-41.2728, 174.7840],
  [-41.2721, 174.7838],
  [-41.2699, 174.7823],
  [-41.2691, 174.7821],
  [-41.2674, 174.7824],
  [-41.2664, 174.7831],
  [-41.2655, 174.7841]
];

const johnsonvilleJunctionKaiwharawhara = [
  [-41.2655, 174.7841],
  [-41.2627, 174.7872],
  [-41.2621, 174.7880],
  [-41.2612, 174.7901],
  [-41.2606, 174.7908],
  [-41.2597, 174.7916]
];

const kaiwharawharaTawaFlatJunction = [
  [-41.2597, 174.7916],
  [-41.2589, 174.7922],
  [-41.2582, 174.7932],
  [-41.2569, 174.7954],
  [-41.2554, 174.7987]
];

const tawaFlatJunctionNgauranga = [
  [-41.2554, 174.7987],
  [-41.2529, 174.8044],
  [-41.2527, 174.8051],
  [-41.2519, 174.8072],
  [-41.2486, 174.8135]
];

const ngaurangaPetoneJunction = [
  [-41.2486, 174.8135],
  [-41.2477, 174.8151],
  [-41.2443, 174.8193],
  [-41.2433, 174.8206],
  [-41.2404, 174.8258],
  [-41.2389, 174.8280],
  [-41.2355, 174.8337],
  [-41.2341, 174.8357],
  [-41.2329, 174.8370],
  [-41.2323, 174.8380],
  [-41.2320, 174.8388],
  [-41.2316, 174.8399],
  [-41.2309, 174.8412],
  [-41.2296, 174.8424],
  [-41.2290, 174.8433],
  [-41.2285, 174.8445],
  [-41.2270, 174.8503],
  [-41.2262, 174.8521],
  [-41.2243, 174.8578],
  [-41.2240, 174.8597],
  [-41.2241, 174.8631],
  [-41.2239, 174.8645],
  [-41.2229, 174.8676],
  [-41.2184, 174.8777]
];

const petoneJunctionWesternHutt = [
  [-41.2184, 174.8777],
  [-41.2153, 174.8848],
  [-41.2147, 174.8858],
  [-41.2118, 174.8899]
];

const westernHuttMelling = [
  [-41.2118, 174.8899],
  [-41.2109, 174.8910],
  [-41.2103, 174.8921],
  [-41.2088, 174.8953],
  [-41.2065, 174.8996],
  [-41.2055, 174.9012],
  [-41.2034, 174.9056]
];

const mellingManorParkJunction = [
  [-41.2034, 174.9056],
  [-41.2004, 174.9113],
  [-41.1934, 174.9242],
  [-41.1929, 174.9256],
  [-41.1927, 174.9271],
  [-41.1920, 174.9291],
  [-41.1888, 174.9351],
  [-41.1869, 174.9382],
  [-41.1840, 174.9419],
  [-41.1812, 174.9446],
  [-41.1766, 174.9495],
  [-41.1755, 174.9513],
  [-41.1748, 174.9524],
  [-41.1725, 174.9550],
  [-41.1719, 174.9561],
  [-41.1704, 174.9585],
  [-41.1681, 174.9613],
  [-41.1629, 174.9678],
  [-41.1619, 174.9691],
  [-41.1607, 174.9713],
  [-41.1588, 174.9753]
];

const manorParkJunctionManorPark = [
  [-41.1588, 174.9753],
  [-41.1563, 174.9788]
];

const manorParkSilverstreamJunction = [
  [-41.1563, 174.9788],
  [-41.1535, 174.9830],
  [-41.1527, 174.9839],
  [-41.1509, 174.9852],
  [-41.1503, 174.9859],
  [-41.1488, 174.9876]
];

const silverstreamJunctionSilverstreamBridge = [
  [-41.1488, 174.9876],
  [-41.1485, 174.9887],
  [-41.1485, 174.9898],
  [-41.1504, 174.9930],
  [-41.1507, 174.9939]
];

const silverstreamHeritageSection = [
  [-41.1507, 174.9939],
  [-41.1509, 174.9947],
  [-41.1509, 174.9952],
  [-41.1506, 174.9957],
  [-41.1501, 174.9965],
  [-41.1495, 174.9979],
  [-41.1493, 174.9983],
  [-41.1483, 174.9995],
  [-41.1479, 174.9998],
  [-41.1476, 175.0002],
  [-41.1471, 175.0024],
  [-41.1471, 175.0030],
  [-41.1477, 175.0044],
  [-41.1480, 175.0058]
];

const silverstreamEndJunction = [
  [-41.1480, 175.0058],
  [-41.1479, 175.0082],
  [-41.1472, 175.0117]
];

const silverstreamTrentham = [
  [-41.1472, 175.0117],
  [-41.1470, 175.0127],
  [-41.1412, 175.0293],
  [-41.1379, 175.0382]
];

const trenthamUpperHutt = [
  [-41.1379, 175.0382],
  [-41.1317, 175.0551],
  [-41.1261, 175.0707]
];

const upperHuttRimutakaDeviationStart = [
  [-41.1261, 175.0707],
  [-41.1240, 175.0764],
  [-41.1220, 175.0802]
];

const rimutakaDeviationStartCruickshanksTunnel = [
  [-41.1220, 175.0802],
  [-41.1213, 175.0819],
  [-41.1211, 175.0828],
  [-41.1214, 175.0835],
  [-41.1219, 175.0840],
  [-41.1226, 175.0842],
  [-41.1236, 175.0851],
  [-41.1239, 175.0859],
  [-41.1236, 175.0868],
  [-41.1233, 175.0879],
  [-41.1235, 175.0887],
  [-41.1244, 175.0896],
  [-41.1246, 175.0904],
  [-41.1243, 175.0912],
  [-41.1237, 175.0919],
  [-41.1230, 175.0922],
  [-41.1226, 175.0928],
  [-41.1225, 175.0938],
  [-41.1222, 175.0951],
  [-41.1222, 175.0959]
];

const cruickshanksTunnel = [
  [-41.1222, 175.0959],
  [-41.1226, 175.0977]
];

const cruickshanksTunnelMangaroaTunnel = [
  [-41.1226, 175.0977],
  [-41.1224, 175.0994],
  [-41.1227, 175.1026],
  [-41.1227, 175.1036],
  [-41.1168, 175.1289],
  [-41.1156, 175.1321],
  [-41.1150, 175.1355],
  [-41.1146, 175.1365],
  [-41.1141, 175.1369],
  [-41.1131, 175.1376],
  [-41.1108, 175.1399],
  [-41.1104, 175.1408],
  [-41.1100, 175.1410],
  [-41.1088, 175.1408],
  [-41.1085, 175.1408],
  [-41.1083, 175.1408],
  [-41.1081, 175.1412],
  [-41.1078, 175.1419],
  [-41.1077, 175.1426],
  [-41.1079, 175.1435],
  [-41.1079, 175.1441],
  [-41.1074, 175.1447],
  [-41.1068, 175.1449],
  [-41.1064, 175.1453],
  [-41.1063, 175.1459],
  [-41.1067, 175.1482]
];

const mangaroaTunnel = [
  [-41.1067, 175.1482],
  [-41.1073, 175.1508]
];

const mangaroaTunnelKaitoke = [
  [-41.1073, 175.1508],
  [-41.1075, 175.1512],
  [-41.1079, 175.1518],
  [-41.1090, 175.1525],
  [-41.1095, 175.1533],
  [-41.1094, 175.1543],
  [-41.1093, 175.1544],
  [-41.1088, 175.1548],
  [-41.1080, 175.1550],
  [-41.1072, 175.1555],
  [-41.1065, 175.1558],
  [-41.1060, 175.1562],
  [-41.1055, 175.1563],
  [-41.1052, 175.1564],
  [-41.1035, 175.1582],
  [-41.1033, 175.1586],
  [-41.1026, 175.1589],
  [-41.1021, 175.1596],
  [-41.1016, 175.1604],
  [-41.1011, 175.1608],
  [-41.0983, 175.1615],
  [-41.0978, 175.1615],
  [-41.0975, 175.1611],
  [-41.0971, 175.1607],
  [-41.0966, 175.1603],
  [-41.0963, 175.1603],
  [-41.0960, 175.1605],
  [-41.0956, 175.1617],
  [-41.0951, 175.1621],
  [-41.0947, 175.1621],
  [-41.0941, 175.1620],
  [-41.0919, 175.1627],
  [-41.0913, 175.1626],
  [-41.0909, 175.1629],
  [-41.0899, 175.1649],
  [-41.0893, 175.1653],
  [-41.0877, 175.1658],
  [-41.0872, 175.1663],
  [-41.0871, 175.1667],
  [-41.0868, 175.1671],
  [-41.0845, 175.1680]
];

const kaitokePakuratahiTunnel = [
  [-41.0845, 175.1680],
  [-41.0839, 175.1684],
  [-41.0834, 175.1692],
  [-41.0831, 175.1700],
  [-41.0833, 175.1720],
  [-41.0837, 175.1727],
  [-41.0841, 175.1732],
  [-41.0852, 175.1738],
  [-41.0856, 175.1744],
  [-41.0856, 175.1750],
  [-41.0853, 175.1757],
  [-41.0847, 175.1764],
  [-41.0846, 175.1772],
  [-41.0847, 175.1780],
  [-41.0846, 175.1787],
  [-41.0842, 175.1796],
  [-41.0841, 175.1805],
  [-41.0845, 175.1815],
  [-41.0847, 175.1826],
  [-41.0847, 175.1832],
  [-41.0850, 175.1841],
  [-41.0851, 175.1847],
  [-41.0849, 175.1856],
  [-41.0849, 175.1860],
  [-41.0851, 175.1865],
  [-41.0855, 175.1868],
  [-41.0861, 175.1869],
  [-41.0883, 175.1863],
  [-41.0889, 175.1865],
  [-41.0892, 175.1869],
  [-41.0898, 175.1892],
  [-41.0901, 175.1895],
  [-41.0904, 175.1896],
  [-41.0918, 175.1893],
  [-41.0924, 175.1894],
  [-41.0932, 175.1890],
  [-41.0936, 175.1889],
  [-41.0943, 175.1890],
  [-41.0952, 175.1889],
  [-41.0956, 175.1891],
  [-41.0963, 175.1895],
  [-41.0967, 175.1895],
  [-41.0970, 175.1893],
  [-41.0978, 175.1884],
  [-41.0980, 175.1875],
  [-41.0984, 175.1869],
  [-41.0990, 175.1866],
  [-41.1003, 175.1867],
  [-41.1019, 175.1863],
  [-41.1026, 175.1863],
  [-41.1036, 175.1865],
  [-41.1042, 175.1864],
  [-41.1050, 175.1858],
  [-41.1060, 175.1843],
  [-41.1065, 175.1839],
  [-41.1092, 175.1824],
  [-41.1097, 175.1823],
  [-41.1104, 175.1824],
  [-41.1109, 175.1822],
  [-41.1113, 175.1817],
  [-41.1117, 175.1804],
  [-41.1128, 175.1789],
  [-41.1131, 175.1786],
  [-41.1157, 175.1775],
  [-41.1161, 175.1776],
  [-41.1168, 175.1787],
  [-41.1173, 175.1793],
  [-41.1175, 175.1800],
  [-41.1176, 175.1805]
];

const pakuratahiTunnel = [
  [-41.1176, 175.1805],
  [-41.1178, 175.1810]
];

const pakuratahiTunnelSummit = [
  [-41.1178, 175.1810],
  [-41.1181, 175.1813],
  [-41.1183, 175.1814],
  [-41.1191, 175.1815],
  [-41.1202, 175.1825],
  [-41.1213, 175.1857],
  [-41.1216, 175.1862],
  [-41.1233, 175.1873],
  [-41.1240, 175.1883],
  [-41.1241, 175.1887],
  [-41.1241, 175.1897],
  [-41.1238, 175.1902],
  [-41.1231, 175.1913],
  [-41.1226, 175.1923],
  [-41.1225, 175.1937],
  [-41.1228, 175.1952],
  [-41.1226, 175.1966],
  [-41.1228, 175.1973],
  [-41.1232, 175.1978],
  [-41.1236, 175.1979],
  [-41.1293, 175.1964],
  [-41.1312, 175.1955],
  [-41.1315, 175.1952],
  [-41.1320, 175.1939],
  [-41.1322, 175.1922],
  [-41.1323, 175.1916],
  [-41.1326, 175.1913],
  [-41.1327, 175.1907],
  [-41.1329, 175.1891],
  [-41.1333, 175.1884],
  [-41.1341, 175.1881],
  [-41.1345, 175.1877],
  [-41.1348, 175.1871],
  [-41.1351, 175.1869],
  [-41.1359, 175.1869],
  [-41.1365, 175.1873],
  [-41.1368, 175.1877],
  [-41.1371, 175.1883],
  [-41.1373, 175.1902],
  [-41.1374, 175.1906],
  [-41.1378, 175.1907],
  [-41.1392, 175.1906],
  [-41.1397, 175.1909],
  [-41.1403, 175.1918],
  [-41.1406, 175.1922],
  [-41.1410, 175.1925],
  [-41.1417, 175.1926],
  [-41.1422, 175.1931],
  [-41.1423, 175.1936],
  [-41.1422, 175.1944],
  [-41.1415, 175.1955],
  [-41.1413, 175.1962],
  [-41.1413, 175.1970],
  [-41.1416, 175.1975],
  [-41.1421, 175.1979],
  [-41.1427, 175.1979],
  [-41.1448, 175.1969]
];

const summitSummitTunnel = [
  [-41.1448, 175.1969],
  [-41.1453, 175.1967],
  [-41.1461, 175.1965]
];

const summitTunnel = [
  [-41.1461, 175.1965],
  [-41.1514, 175.1958]
];

const summitTunnelSiberiaTunnel = [
  [-41.1514, 175.1958],
  [-41.1522, 175.1954],
  [-41.1529, 175.1954],
  [-41.1538, 175.1953],
  [-41.1545, 175.1957],
  [-41.1554, 175.1968],
  [-41.1563, 175.1985],
  [-41.1564, 175.1987],
  [-41.1565, 175.1992]
];

const siberiaTunnel = [
  [-41.1565, 175.1992],
  [-41.1565, 175.2005]
];

const siberiaTunnelPricesTunnel = [
  [-41.1565, 175.2005],
  [-41.1566, 175.2008],
  [-41.1572, 175.2012],
  [-41.1575, 175.2014],
  [-41.1577, 175.2013],
  [-41.1583, 175.2006],
  [-41.1587, 175.1997],
  [-41.1590, 175.1994],
  [-41.1599, 175.1986],
  [-41.1602, 175.1984],
  [-41.1607, 175.1985]
];

const pricesTunnel = [
  [-41.1607, 175.1985],
  [-41.1610, 175.1987],
  [-41.1613, 175.1991]
];

const pricesTunnelRimutakaDeviationEnd = [
  [-41.1613, 175.1991],
  [-41.1615, 175.1991],
  [-41.1620, 175.1989],
  [-41.1623, 175.1985],
  [-41.1625, 175.1981],
  [-41.1625, 175.1974],
  [-41.1627, 175.1969],
  [-41.1631, 175.1965],
  [-41.1651, 175.1961],
  [-41.1657, 175.1962],
  [-41.1660, 175.1966],
  [-41.1666, 175.1978],
  [-41.1672, 175.1983],
  [-41.1683, 175.1986],
  [-41.1689, 175.1991],
  [-41.1696, 175.2004],
  [-41.1698, 175.2022],
  [-41.1696, 175.2038],
  [-41.1694, 175.2060],
  [-41.1688, 175.2081],
  [-41.1684, 175.2099],
  [-41.1678, 175.2108],
  [-41.1674, 175.2115],
  [-41.1674, 175.2119],
  [-41.1676, 175.2135],
  [-41.1675, 175.2142],
  [-41.1668, 175.2175],
  [-41.1665, 175.2195],
  [-41.1662, 175.2202],
  [-41.1657, 175.2209],
  [-41.1655, 175.2217],
  [-41.1654, 175.2225],
  [-41.1655, 175.2236],
  [-41.1654, 175.2246],
  [-41.1639, 175.2310],
  [-41.1638, 175.2319],
  [-41.1641, 175.2387],
  [-41.1644, 175.2402],
  [-41.1654, 175.2445],
  [-41.1654, 175.2457],
  [-41.1650, 175.2469],
  [-41.1622, 175.2523],
  [-41.1608, 175.2565],
  [-41.1392, 175.2886],
  [-41.1275, 175.3070],
  [-41.1248, 175.3115]
];

const rimutakaDeviationEndFeatherston = [
  [-41.1248, 175.3115],
  [-41.1134, 175.3296]
];

const featherstonWoodsideJunction = [
  [-41.1134, 175.3296],
  [-41.1085, 175.3375],
  [-41.0875, 175.3612],
  [-41.0866, 175.3626],
  [-41.0674, 175.4018]
];

const woodsideJunctionMasterton = [
  [-41.0674, 175.4018],
  [-41.0638, 175.4091],
  [-41.0578, 175.4188],
  [-41.0568, 175.4208],
  [-41.0314, 175.5089],
  [-41.0304, 175.5112],
  [-41.0206, 175.5247],
  [-40.9779, 175.5837],
  [-40.9712, 175.5929],
  [-40.9589, 175.6129],
  [-40.9404, 175.6547]
];

const mastertonMauriceville = [
  [-40.9404, 175.6547],
  [-40.9366, 175.6634],
  [-40.9352, 175.6650],
  [-40.9335, 175.6656],
  [-40.8781, 175.6607],
  [-40.8771, 175.6608],
  [-40.8745, 175.6616],
  [-40.8727, 175.6614],
  [-40.8716, 175.6608],
  [-40.8701, 175.6593],
  [-40.8687, 175.6584],
  [-40.8674, 175.6584],
  [-40.8655, 175.6591],
  [-40.8583, 175.6600],
  [-40.8256, 175.6654],
  [-40.8245, 175.6659],
  [-40.8234, 175.6668],
  [-40.8194, 175.6723],
  [-40.8121, 175.6805],
  [-40.8112, 175.6811],
  [-40.8102, 175.6812],
  [-40.8073, 175.6801],
  [-40.8056, 175.6801],
  [-40.8044, 175.6809],
  [-40.8033, 175.6822],
  [-40.8023, 175.6829],
  [-40.8009, 175.6835],
  [-40.8002, 175.6841],
  [-40.7988, 175.6856],
  [-40.7937, 175.6885],
  [-40.7906, 175.6917],
  [-40.7898, 175.6930],
  [-40.7895, 175.6944],
  [-40.7887, 175.6959],
  [-40.7876, 175.6968],
  [-40.7866, 175.6972],
  [-40.7846, 175.6974],
  [-40.7838, 175.6977],
  [-40.7774, 175.7010]
];

const mauricevilleMangamahoe = [
  [-40.7774, 175.7010],
  [-40.7765, 175.7015],
  [-40.7712, 175.7055],
  [-40.7663, 175.7101],
  [-40.7653, 175.7108],
  [-40.7611, 175.7130],
  [-40.7579, 175.7146],
  [-40.7570, 175.7154],
  [-40.7562, 175.7168],
  [-40.7555, 175.7174],
  [-40.7548, 175.7178],
  [-40.7539, 175.7179],
  [-40.7528, 175.7175],
  [-40.7519, 175.7175],
  [-40.7508, 175.7178],
  [-40.7501, 175.7177],
  [-40.7488, 175.7174],
  [-40.7480, 175.7175],
  [-40.7468, 175.7190],
  [-40.7459, 175.7200],
  [-40.7453, 175.7203],
  [-40.7447, 175.7204],
  [-40.7438, 175.7203],
  [-40.7431, 175.7205],
  [-40.7426, 175.7208],
  [-40.7420, 175.7211],
  [-40.7389, 175.7218],
  [-40.7358, 175.7232],
  [-40.7350, 175.7238],
  [-40.7340, 175.7250],
  [-40.7332, 175.7257],
  [-40.7326, 175.7259],
  [-40.7316, 175.7259],
  [-40.7299, 175.7254]
];

const mangamahoeWiwakaTunnel = [
  [-40.7299, 175.7254],
  [-40.7267, 175.7246],
  [-40.7260, 175.7243],
  [-40.7223, 175.7216],
  [-40.7214, 175.7212],
  [-40.7203, 175.7213],
  [-40.7197, 175.7215],
  [-40.7189, 175.7221],
  [-40.7183, 175.7231],
  [-40.7176, 175.7238],
  [-40.7168, 175.7243],
  [-40.7160, 175.7244],
  [-40.7148, 175.7241],
  [-40.7142, 175.7241],
  [-40.7131, 175.7244],
  [-40.7122, 175.7242],
  [-40.7114, 175.7236],
  [-40.7106, 175.7227],
  [-40.7098, 175.7221],
  [-40.7086, 175.7218],
  [-40.7077, 175.7212],
  [-40.7071, 175.7204],
  [-40.7067, 175.7192],
  [-40.7062, 175.7183],
  [-40.7053, 175.7175],
  [-40.7045, 175.7173],
  [-40.7029, 175.7174],
  [-40.7006, 175.7179],
  [-40.7005, 175.7179],
  [-40.6997, 175.7184],
  [-40.6984, 175.7197],
  [-40.6974, 175.7202],
  [-40.6966, 175.7202],
  [-40.6954, 175.7198],
  [-40.6947, 175.7196]
];

const wiwakaTunnel = [
  [-40.6947, 175.7196],
  [-40.6933, 175.7194]
];

const wiwakaTunnelEketahuna = [
  [-40.6933, 175.7194],
  [-40.6924, 175.7193],
  [-40.6914, 175.7188],
  [-40.6899, 175.7183],
  [-40.6892, 175.7178],
  [-40.6871, 175.7156],
  [-40.6866, 175.7148],
  [-40.6864, 175.7142],
  [-40.6860, 175.7117],
  [-40.6858, 175.7106],
  [-40.6852, 175.7090],
  [-40.6843, 175.7079],
  [-40.6818, 175.7063],
  [-40.6802, 175.7058],
  [-40.6751, 175.7056],
  [-40.6739, 175.7058],
  [-40.6729, 175.7065],
  [-40.6724, 175.7071],
  [-40.6719, 175.7077],
  [-40.6711, 175.7081],
  [-40.6696, 175.7080],
  [-40.6675, 175.7073],
  [-40.6665, 175.7073],
  [-40.6656, 175.7078],
  [-40.6646, 175.7088],
  [-40.6637, 175.7093],
  [-40.6630, 175.7093],
  [-40.6618, 175.7091],
  [-40.6609, 175.7092],
  [-40.6602, 175.7096],
  [-40.6593, 175.7104],
  [-40.6586, 175.7108],
  [-40.6577, 175.7108],
  [-40.6551, 175.7102],
  [-40.6543, 175.7098],
  [-40.6538, 175.7092],
  [-40.6529, 175.7079],
  [-40.6520, 175.7073],
  [-40.6495, 175.7065]
];

const eketahunaPahiatua = [
  [-40.6495, 175.7065],
  [-40.6473, 175.7059],
  [-40.6463, 175.7058],
  [-40.6451, 175.7063],
  [-40.6443, 175.7070],
  [-40.6405, 175.7119],
  [-40.6390, 175.7129],
  [-40.6377, 175.7132],
  [-40.6272, 175.7107],
  [-40.6255, 175.7105],
  [-40.6169, 175.7129],
  [-40.6154, 175.7129],
  [-40.6141, 175.7122],
  [-40.6120, 175.7100],
  [-40.6114, 175.7086],
  [-40.6112, 175.7071],
  [-40.6114, 175.7051],
  [-40.6112, 175.7037],
  [-40.6102, 175.7013],
  [-40.6043, 175.6954],
  [-40.6017, 175.6942],
  [-40.5992, 175.6943],
  [-40.5873, 175.6944],
  [-40.5845, 175.6939],
  [-40.5758, 175.6897],
  [-40.5731, 175.6896],
  [-40.5706, 175.6907],
  [-40.5197, 175.7441],
  [-40.5183, 175.7452],
  [-40.5159, 175.7467],
  [-40.5148, 175.7469],
  [-40.5109, 175.7467],
  [-40.5095, 175.7471],
  [-40.5016, 175.7520],
  [-40.5003, 175.7532],
  [-40.4973, 175.7569],
  [-40.4965, 175.7582],
  [-40.4960, 175.7598],
  [-40.4956, 175.7606],
  [-40.4948, 175.7615],
  [-40.4931, 175.7626],
  [-40.4920, 175.7637],
  [-40.4913, 175.7644],
  [-40.4853, 175.7737],
  [-40.4845, 175.7753],
  [-40.4837, 175.7770],
  [-40.4831, 175.7779],
  [-40.4800, 175.7807],
  [-40.4791, 175.7811],
  [-40.4755, 175.7840],
  [-40.4740, 175.7847],
  [-40.4655, 175.7907],
  [-40.4645, 175.7917],
  [-40.4619, 175.7957],
  [-40.4608, 175.7970],
  [-40.4557, 175.8010],
  [-40.4547, 175.8021],
  [-40.4525, 175.8050],
  [-40.4487, 175.8090],
  [-40.4460, 175.8128],
  [-40.4455, 175.8136],
  [-40.4447, 175.8157]
];

const pahiatuaWoodvilleJunction = [
  [-40.4447, 175.8157],
  [-40.4369, 175.8358],
  [-40.4366, 175.8370],
  [-40.4366, 175.8384],
  [-40.4367, 175.8395],
  [-40.4365, 175.8407],
  [-40.4361, 175.8421],
  [-40.4353, 175.8436],
  [-40.4281, 175.8516],
  [-40.4263, 175.8541],
  [-40.4255, 175.8549],
  [-40.4251, 175.8554],
  [-40.4245, 175.8562],
  [-40.4235, 175.8571],
  [-40.4144, 175.8617],
  [-40.4120, 175.8630],
  [-40.4107, 175.8642],
  [-40.4099, 175.8656],
  [-40.4093, 175.8676],
  [-40.4090, 175.8699],
  [-40.4086, 175.8709],
  [-40.4081, 175.8718],
  [-40.4005, 175.8787],
  [-40.3944, 175.8843],
  [-40.3930, 175.8851],
  [-40.3901, 175.8863],
  [-40.3875, 175.8886],
  [-40.3780, 175.8961],
  [-40.3758, 175.8971],
  [-40.3743, 175.8971],
  [-40.3726, 175.8964],
  [-40.3563, 175.8837],
  [-40.3486, 175.8795],
  [-40.3475, 175.8784],
  [-40.3468, 175.8769],
  [-40.3464, 175.8738],
  [-40.3458, 175.8714]
];

const woodvilleLoop = [
  [-40.3441, 175.8628],
  [-40.3442, 175.8612],
  [-40.3450, 175.8601],
  [-40.3460, 175.8600],
  [-40.3469, 175.8606],
  [-40.3474, 175.8618],
  [-40.3474, 175.8650],
  [-40.3464, 175.8716],
  [-40.3464, 175.8728],
  [-40.3464, 175.8738]
];

//MELLING DEVIATION
const petoneJunctionWaterloo = [
  [-41.2184, 174.8777],
  [-41.2178, 174.8797],
  [-41.2177, 174.8811],
  [-41.2180, 174.8846],
  [-41.2193, 174.8913],
  [-41.2234, 174.9031],
  [-41.2237, 174.9046],
  [-41.2236, 174.9067],
  [-41.2230, 174.9083], //POINT
  [-41.2138, 174.9213]
];

const waterlooNaenae = [
  [-41.2138, 174.9213],
  [-41.2124, 174.9233],
  [-41.2060, 174.9324],
  [-41.1976, 174.9464]
];

const naenaeTaita = [
  [-41.1976, 174.9464],
  [-41.1963, 174.9487],
  [-41.1946, 174.9506],
  [-41.1831, 174.9585],
  [-41.1802, 174.9608]
];

const taitaManorParkJunction = [
  [-41.1802, 174.9608],
  [-41.1690, 174.9700],
  [-41.1675, 174.9708],
  [-41.1632, 174.9715],
  [-41.1618, 174.9719],
  [-41.1605, 174.9730],
  [-41.1597, 174.9741],
  [-41.1588, 174.9753]
];

//SILVERSTREAM DEVIATION
const silverstreamDeviation = [
  [-41.1488, 174.9876],
  [-41.1484, 174.9881],
  [-41.1475, 174.9897],
  [-41.1462, 174.9929],
  [-41.1458, 174.9947],
  [-41.1458, 174.9969],
  [-41.1473, 175.0101],
  [-41.1472, 175.0117]
];

//RIMUTAKA DEVIATION
const rimutakaDeviationStartMaoribankTunnel = [
  [-41.1220, 175.0802],
  [-41.1215, 175.0822],
  [-41.1216, 175.0836],
  [-41.1219, 175.0848],
  [-41.1224, 175.0871],
  [-41.1221, 175.0894],
  [-41.1213, 175.0912],
  [-41.1205, 175.0923],
  [-41.1195, 175.0929],
  [-41.1177, 175.0934],
  [-41.1162, 175.0945],
  [-41.1153, 175.0962],
  [-41.1143, 175.1003],
  [-41.1138, 175.1016],
  [-41.1127, 175.1028],
  [-41.1092, 175.1053],
  [-41.1084, 175.1063],
  [-41.1078, 175.1079],
  [-41.1077, 175.1096]
];

const maoribankTunnel = [
  [-41.1077, 175.1096],
  [-41.1080, 175.1164]
];

const maoribankTunnelRimutakaTunnel = [
  [-41.1080, 175.1164],
  [-41.1080, 175.1167],
  [-41.1082, 175.1184],
  [-41.1094, 175.1229],
  [-41.1095, 175.1255],
  [-41.1076, 175.1366],
  [-41.1078, 175.1392],
  [-41.1080, 175.1401]
];

const rimutakaTunnel = [
  [-41.1080, 175.1401],
  [-41.1326, 175.2397]
];

const rimutakaTunnelRimutakaDeviationEnd = [
  [-41.1326, 175.2397],
  [-41.1328, 175.2413],
  [-41.1329, 175.2458],
  [-41.1333, 175.2483],
  [-41.1342, 175.2503],
  [-41.1352, 175.2517],
  [-41.1365, 175.2530],
  [-41.1400, 175.2579],
  [-41.1422, 175.2631],
  [-41.1426, 175.2649],
  [-41.1425, 175.2666],
  [-41.1418, 175.2693],
  [-41.1406, 175.2709],
  [-41.1392, 175.2719],
  [-41.1381, 175.2736],
  [-41.1373, 175.2760],
  [-41.1369, 175.2783],
  [-41.1359, 175.2805],
  [-41.1345, 175.2819],
  [-41.1327, 175.2831],
  [-41.1317, 175.2848],
  [-41.1312, 175.2868],
  [-41.1307, 175.2931],
  [-41.1299, 175.2956],
  [-41.1272, 175.3003],
  [-41.1265, 175.3022],
  [-41.1261, 175.3037],
  [-41.1259, 175.3073],
  [-41.1254, 175.3098],
  [-41.1248, 175.3115]
];

export const wairarapa: Line = {
  name: 'Wairarapa',
  state: 'NZ',
  segments: [
    {
      segments: [wellingtonPipiteaPoint],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1909-01-01',
          tracks: 2
        }, {
          date: '1935-07-22',
          tracks: 4
        }, {
          date: '1938-07-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [pipiteaPointJohnsonvilleJunction],
      history: {
        opened: {
          date: '1873-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1909-01-01',
          tracks: 2
        }, {
          date: '1935-07-22',
          tracks: 4
        }, {
          date: '1938-07-04',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [johnsonvilleJunctionKaiwharawhara],
      history: {
        opened: {
          date: '1873-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1909-01-01',
          tracks: 2
        }, {
          date: '1935-07-22',
          tracks: 4
        }, {
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [kaiwharawharaTawaFlatJunction],
      history: {
        opened: {
          date: '1874-04-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1909-01-01',
          tracks: 2
        }, {
          date: '1935-07-22',
          tracks: 4
        }, {
          date: '1940-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [tawaFlatJunctionNgauranga],
      history: {
        opened: {
          date: '1874-04-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1909-01-01',
          tracks: 2
        }, {
          date: '1953-09-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [ngaurangaPetoneJunction],
      history: {
        opened: {
          date: '1874-04-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1908-01-01',
          tracks: 2
        }, {
          date: '1953-09-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [petoneJunctionWesternHutt],
      history: {
        opened: {
          date: '1874-04-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1905-01-01',
          tracks: 2
        }, {
          date: '1958-07-13',
          tracks: 1
        }, {
          date: '1953-09-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [westernHuttMelling],
      history: {
        opened: {
          date: '1875-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1905-01-01',
          tracks: 2
        }, {
          date: '1958-07-13',
          tracks: 1
        }, {
          date: '1953-09-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [mellingManorParkJunction],
      history: {
        opened: {
          date: '1875-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1905-01-01',
          tracks: 2
        }, {
          date: '1954-02-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [manorParkJunctionManorPark],
      history: {
        opened: {
          date: '1875-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1905-01-01',
          tracks: 2
        }, {
          date: '1955-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [manorParkSilverstreamJunction],
      history: {
        opened: {
          date: '1875-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-06-26',
          tracks: 2
        }, {
          date: '1955-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [silverstreamJunctionSilverstreamBridge],
      history: {
        opened: {
          date: '1875-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-11-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [silverstreamHeritageSection],
      history: {
        opened: {
          date: '1876-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-11-21',
          status: 'closed'
        }, {
          date: '1986-02-15',
          status: 'open'
        }]
      }
    },
    {
      segments: [silverstreamEndJunction],
      history: {
        opened: {
          date: '1876-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-11-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [silverstreamTrentham],
      history: {
        opened: {
          date: '1876-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-06-26',
          tracks: 2
        }, {
          date: '1955-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [trenthamUpperHutt],
      history: {
        opened: {
          date: '1876-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2021-11-15',
          tracks: 2
        }]
      }
    },
    {
      segments: [upperHuttRimutakaDeviationStart],
      history: {
        opened: {
          date: '1878-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        rimutakaDeviationStartCruickshanksTunnel,
        {
          name: 'Cruickshanks Tunnel',
          segment: cruickshanksTunnel,
          type: 'tunnel'
        },
        cruickshanksTunnelMangaroaTunnel,
        {
          name: 'Mangaroa Tunnel',
          segment: mangaroaTunnel,
          type: 'tunnel'
        },
        mangaroaTunnelKaitoke
      ],
      history: {
        opened: {
          date: '1878-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-10-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        kaitokePakuratahiTunnel,
        {
          name: 'Pakuratahi Tunnel',
          segment: pakuratahiTunnel,
          type: 'tunnel'
        },
        pakuratahiTunnelSummit
      ],
      history: {
        opened: {
          date: '1878-10-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-10-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        summitSummitTunnel,
        {
          name: 'Summit Tunnel',
          segment: summitTunnel,
          type: 'tunnel'
        },
        summitTunnelSiberiaTunnel,
        {
          name: 'Siberia Tunnel',
          segment: siberiaTunnel,
          type: 'tunnel'
        },
        siberiaTunnelPricesTunnel,
        {
          name: 'Price\'s Tunnel',
          segment: pricesTunnel,
          type: 'tunnel'
        },
        pricesTunnelRimutakaDeviationEnd
      ],
      history: {
        opened: {
          date: '1878-10-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-10-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rimutakaDeviationEndFeatherston],
      history: {
        opened: {
          date: '1878-10-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [featherstonWoodsideJunction],
      history: {
        opened: {
          date: '1880-05-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [woodsideJunctionMasterton],
      history: {
        opened: {
          date: '1880-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [woodvilleLoop],
      history: {
        opened: {
          date: '1897-12-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mastertonMauriceville],
      history: {
        opened: {
          date: '1886-06-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mauricevilleMangamahoe],
      history: {
        opened: {
          date: '1887-01-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        mangamahoeWiwakaTunnel,
        {
          name: 'Wiwaka Tunnel',
          segment: wiwakaTunnel,
          type: 'tunnel'
        },
        wiwakaTunnelEketahuna
      ],
      history: {
        opened: {
          date: '1889-04-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [eketahunaPahiatua],
      history: {
        opened: {
          date: '1896-03-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pahiatuaWoodvilleJunction],
      history: {
        opened: {
          date: '1897-12-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [petoneJunctionWaterloo],
      history: {
        opened: {
          date: '1927-05-26',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-09-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [waterlooNaenae],
      history: {
        opened: {
          date: '1946-01-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-09-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1947-04-14',
          tracks: 2
        }]
      }
    },
    {
      segments: [naenaeTaita],
      history: {
        opened: {
          date: '1947-04-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-09-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-02-22',
          tracks: 2
        }]
      }
    },
    {
      segments: [taitaManorParkJunction],
      history: {
        opened: {
          date: '1954-02-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-07-19',
          tracks: 2
        }]
      }
    },
    {
      segments: [silverstreamDeviation],
      history: {
        opened: {
          date: '1954-11-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-07-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1955-06-26',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        rimutakaDeviationStartMaoribankTunnel,
        {
          name: 'Maoribank Tunnel',
          segment: maoribankTunnel,
          type: 'tunnel'
        },
        maoribankTunnelRimutakaTunnel,
        {
          name: 'Rimutaka Tunnel',
          segment: rimutakaTunnel,
          type: 'tunnel'
        },
        rimutakaTunnelRimutakaDeviationEnd
      ],
      history: {
        opened: {
          date: '1955-10-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
