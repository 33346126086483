import { Line } from "../../trackTypes";

const horshamEastNatimuk = [
  [-36.7074, 142.1929],
  [-36.7078, 142.1911],
  [-36.7132, 142.1728],
  [-36.7135, 142.1700],
  [-36.7141, 142.0659],
  [-36.7144, 142.0634],
  [-36.7145, 142.0543],
  [-36.7330, 142.0106],
  [-36.7336, 142.0052],
  [-36.7309, 141.9862],
  [-36.7311, 141.9832],
  [-36.7341, 141.9699],
  [-36.7352, 141.9680],
  [-36.7368, 141.9671],
  [-36.7411, 141.9669]
];

const eastNatimukGoroke = [
  [-36.7411, 141.9669],
  [-36.7418, 141.9669],
  [-36.7428, 141.9663],
  [-36.7436, 141.9653],
  [-36.7438, 141.9637],
  [-36.7435, 141.9442],
  [-36.7438, 141.9425],
  [-36.7461, 141.9373],
  [-36.7464, 141.9358],
  [-36.7462, 141.9342],
  [-36.7456, 141.9327],
  [-36.7428, 141.9278],
  [-36.7420, 141.9255],
  [-36.7417, 141.9231],
  [-36.7416, 141.9034],
  [-36.7411, 141.9009],
  [-36.7402, 141.8984],
  [-36.7396, 141.8959],
  [-36.7400, 141.8677],
  [-36.7395, 141.8645],
  [-36.7307, 141.8397],
  [-36.7298, 141.8365],
  [-36.7284, 141.8225],
  [-36.7288, 141.8196],
  [-36.7302, 141.8158],
  [-36.7308, 141.8121],
  [-36.7265, 141.7701],
  [-36.7255, 141.7679],
  [-36.7245, 141.7660],
  [-36.7239, 141.7645],
  [-36.7228, 141.7604],
  [-36.7228, 141.7581],
  [-36.7238, 141.7554],
  [-36.7250, 141.7538],
  [-36.7263, 141.7530],
  [-36.7289, 141.7520],
  [-36.7306, 141.7507],
  [-36.7315, 141.7493],
  [-36.7324, 141.7464],
  [-36.7326, 141.7379],
  [-36.7323, 141.7308],
  [-36.7326, 141.7274],
  [-36.7359, 141.7084],
  [-36.7380, 141.6711],
  [-36.7368, 141.6575],
  [-36.7369, 141.6542],
  [-36.7373, 141.6508],
  [-36.7371, 141.6473],
  [-36.7334, 141.6270],
  [-36.7332, 141.6241],
  [-36.7355, 141.5986],
  [-36.7353, 141.5966],
  [-36.7295, 141.5843],
  [-36.7267, 141.5709],
  [-36.7265, 141.5689],
  [-36.7266, 141.5647],
  [-36.7250, 141.5133],
  [-36.7215, 141.4793],
  [-36.7219, 141.4766],
  [-36.7227, 141.4738]
];

const gorokeCarpolac = [
  [-36.7227, 141.4738],
  [-36.7234, 141.4718],
  [-36.7238, 141.4674],
  [-36.7225, 141.4539],
  [-36.7209, 141.4439],
  [-36.7199, 141.4373],
  [-36.7195, 141.4264],
  [-36.7194, 141.4229],
  [-36.7243, 141.3817],
  [-36.7300, 141.3323],
  [-36.7310, 141.3286],
  [-36.7377, 141.3147]
];

export const carpolac: Line = {
  name: 'Carpolac',
  state: 'VIC',
  segments: [
    {
      segments: [horshamEastNatimuk],
      history: {
        opened: {
          date: '1887-08-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eastNatimukGoroke],
      history: {
        opened: {
          date: '1894-07-31',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gorokeCarpolac],
      history: {
        opened: {
          date: '1927-05-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    }
  ]
}
