import { Line } from "../../trackTypes";

const bendigoJunctionEaglehawk = [
  [-36.7502, 144.2926],
  [-36.7487, 144.2933],
  [-36.7475, 144.2936],
  [-36.7461, 144.2937],
  [-36.7443, 144.2935],
  [-36.7429, 144.2929],
  [-36.7414, 144.2920],
  [-36.7402, 144.2909],
  [-36.7393, 144.2897],
  [-36.7387, 144.2883],
  [-36.7381, 144.2864],
  [-36.7350, 144.2698],
  [-36.7345, 144.2682],
  [-36.7337, 144.2670],
  [-36.7325, 144.2658],
  [-36.7282, 144.2627],
  [-36.7269, 144.2614],
  [-36.7258, 144.2599],
  [-36.7246, 144.2577],
  [-36.7207, 144.2521],
  [-36.7190, 144.2492],
  [-36.7183, 144.2480],
  [-36.7163, 144.2438],
  [-36.7160, 144.2428],
  [-36.7159, 144.2418],
  [-36.7156, 144.2328],
  [-36.7156, 144.2320]
];

const eaglehawkRaywood = [
  [-36.7156, 144.2320],
  [-36.7155, 144.2311],
  [-36.7152, 144.2293],
  [-36.7146, 144.2277],
  [-36.7140, 144.2265],
  [-36.7124, 144.2249],
  [-36.7029, 144.2155],
  [-36.6916, 144.2058],
  [-36.6896, 144.2049],
  [-36.6878, 144.2046],
  [-36.6508, 144.2046],
  [-36.6493, 144.2049],
  [-36.6478, 144.2053],
  [-36.6444, 144.2064],
  [-36.6433, 144.2066],
  [-36.6419, 144.2065],
  [-36.6413, 144.2064],
  [-36.6225, 144.1997],
  [-36.6205, 144.1993],
  [-36.6194, 144.1992],
  [-36.5462, 144.1992],
  [-36.5449, 144.1993],
  [-36.5317, 144.2017]
];

const raywoodMitiamo = [
  [-36.5317, 144.2017],
  [-36.5275, 144.2025],
  [-36.3656, 144.2316],
  [-36.3647, 144.2316],
  [-36.2144, 144.2316],
  [-36.2132, 144.2316],
  [-36.2123, 144.2314],
  [-36.2112, 144.2308],
  [-36.2103, 144.2301],
  [-36.2095, 144.2294]
];

const mitiamoPyramid = [
  [-36.2095, 144.2294],
  [-36.1841, 144.2089],
  [-36.1296, 144.1654],
  [-36.0551, 144.1146],
  [-36.0531, 144.1131]
];

const pyramidKerang = [
  [-36.0531, 144.1131],
  [-36.0152, 144.0875],
  [-35.9976, 144.0753],
  [-35.8914, 144.0126],
  [-35.8897, 144.0114],
  [-35.8881, 144.0100],
  [-35.8640, 143.9869],
  [-35.8621, 143.9853],
  [-35.8598, 143.9839],
  [-35.7329, 143.9244]
];

const kerangSwanHill = [
  [-35.7329, 143.9244],
  [-35.7307, 143.9234],
  [-35.7260, 143.9210],
  [-35.7246, 143.9198],
  [-35.7234, 143.9183],
  [-35.7227, 143.9166],
  [-35.7160, 143.8986],
  [-35.7146, 143.8960],
  [-35.6686, 143.8386],
  [-35.6664, 143.8360],
  [-35.6631, 143.8330],
  [-35.6267, 143.8068],
  [-35.6247, 143.8048],
  [-35.6233, 143.8023],
  [-35.6227, 143.8004],
  [-35.6203, 143.7827],
  [-35.6189, 143.7778],
  [-35.6159, 143.7722],
  [-35.6125, 143.7688],
  [-35.6099, 143.7669],
  [-35.5793, 143.7490],
  [-35.5756, 143.7462],
  [-35.5725, 143.7429],
  [-35.5427, 143.7048],
  [-35.5410, 143.7030],
  [-35.5273, 143.6910],
  [-35.4939, 143.6612],
  [-35.4452, 143.6179],
  [-35.4440, 143.6167],
  [-35.4387, 143.6095],
  [-35.4352, 143.6058],
  [-35.4125, 143.5873],
  [-35.3952, 143.5733],
  [-35.3942, 143.5726],
  [-35.3770, 143.5630],
  [-35.3758, 143.5626],
  [-35.3743, 143.5624],
  [-35.3666, 143.5625],
  [-35.3648, 143.5627],
  [-35.3562, 143.5642],
  [-35.3547, 143.5644],
  [-35.3527, 143.5643],
  [-35.3469, 143.5637],
  [-35.3442, 143.5631],
  [-35.3414, 143.5624]
];

const swanHillPiangil = [
  [-35.3414, 143.5624],
  [-35.3363, 143.5610],
  [-35.3350, 143.5605],
  [-35.3343, 143.5599],
  [-35.3270, 143.5537],
  [-35.3258, 143.5523],
  [-35.3250, 143.5510],
  [-35.3243, 143.5493],
  [-35.3140, 143.5236],
  [-35.3018, 143.4868],
  [-35.2920, 143.4467],
  [-35.2828, 143.4131],
  [-35.2704, 143.3787],
  [-35.2695, 143.3769],
  [-35.2676, 143.3745],
  [-35.2574, 143.3664],
  [-35.2525, 143.3637],
  [-35.2502, 143.3631],
  [-35.2097, 143.3625],
  [-35.2074, 143.3621],
  [-35.2056, 143.3614],
  [-35.1923, 143.3530],
  [-35.1901, 143.3510],
  [-35.1799, 143.3406],
  [-35.1773, 143.3387],
  [-35.1410, 143.3224],
  [-35.0962, 143.3118],
  [-35.0726, 143.3096],
  [-35.0543, 143.3094]
];

const piangilKooloonong = [
  [-35.0543, 143.3094],
  [-35.0523, 143.3093],
  [-35.0501, 143.3084],
  [-35.0177, 143.2783],
  [-35.0151, 143.2767],
  [-34.9876, 143.2672],
  [-34.9851, 143.2657],
  [-34.9830, 143.2635],
  [-34.9713, 143.2436],
  [-34.9615, 143.2308],
  [-34.9434, 143.2199],
  [-34.9406, 143.2174],
  [-34.9071, 143.1752],
  [-34.9031, 143.1691],
  [-34.8997, 143.1660],
  [-34.8972, 143.1649],
  [-34.8886, 143.1573],
  [-34.8773, 143.1455]
];

const kooloonongYungera = [
  [-34.8773, 143.1455],
  [-34.8742, 143.1428],
  [-34.8709, 143.1416],
  [-34.8624, 143.1416],
  [-34.8609, 143.1413],
  [-34.8526, 143.1376],
  [-34.8511, 143.1373],
  [-34.8293, 143.1374],
  [-34.8271, 143.1379],
  [-34.8170, 143.1375],
  [-34.7885, 143.1435],
  [-34.7797, 143.1436]
];

export const piangil: Line = {
  name: 'Piangil',
  state: 'VIC',
  segments: [
    {
      segments: [bendigoJunctionEaglehawk],
      history: {
        opened: {
          date: '1876-09-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [eaglehawkRaywood],
      history: {
        opened: {
          date: '1882-12-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [raywoodMitiamo],
      history: {
        opened: {
          date: '1883-06-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mitiamoPyramid],
      history: {
        opened: {
          date: '1884-02-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pyramidKerang],
      history: {
        opened: {
          date: '1884-10-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kerangSwanHill],
      history: {
        opened: {
          date: '1890-05-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [swanHillPiangil],
      history: {
        opened: {
          date: '1915-05-27',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [piangilKooloonong],
      history: {
        opened: {
          date: '1920-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kooloonongYungera],
      history: {
        opened: {
          date: '1926-03-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-08-06',
          status: 'closed'
        }]
      }
    }
  ]
};
