import { Line } from "../../../../trackTypes";

const gardiner = [
  [-37.84471, 144.97864],
  [-37.84483, 144.97876],
  [-37.84487, 144.97887],
  [-37.84720, 144.99663],
  [-37.85160, 145.02984],
  [-37.85165, 145.03024],
  [-37.85428, 145.05103]
];

const gardinerCamberwellJunction = [
  [-37.85428, 145.05103],
  [-37.85456, 145.05208],
  [-37.85454, 145.05221],
  [-37.85447, 145.05232],
  [-37.85400, 145.05249],
  [-37.85261, 145.05271],
  [-37.85145, 145.05270],
  [-37.85109, 145.05275],
  [-37.84959, 145.05320],
  [-37.83154, 145.05666]
];

const camberwellJunctionCamberwell = [
  [-37.83154, 145.05666],
  [-37.81084, 145.06060]
];

const glenferrieRdJunction = [
  [-37.85160, 145.02984],
  [-37.85168, 145.02998],
  [-37.85178, 145.03002],
  [-37.85169, 145.03010],
  [-37.85165, 145.03024]
];

export const east11: Line = {
  name: 'East11',
  state: 'VIC',
  segments: [
    {
      segments: [gardiner, glenferrieRdJunction],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [gardinerCamberwellJunction],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [camberwellJunctionCamberwell],
      history: {
        opened: {
          date: '1918-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
