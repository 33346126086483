import { Line } from "../../trackTypes";

const montParkLine = [
  [-37.7267, 145.0690],
  [-37.7260, 145.0687],
  [-37.7256, 145.0684],
  [-37.7247, 145.0676],
  [-37.7239, 145.0670],
  [-37.7234, 145.0666],
  [-37.7202, 145.0643],
  [-37.7157, 145.0611]
];

export const montPark: Line = {
  name: "Mont Park",
  state: 'VIC',
  segments: [
    {
      segments: [montParkLine],
      history: {
        opened: {
          date: '1911-08-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1928-09-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1964-06-30',
          status: 'closed'
        }]
      }
    }
  ]
};
