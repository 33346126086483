import { Line } from "../../trackTypes";

const line = [
  [-32.3014, 115.9839],
  [-32.3032, 115.9841],
  [-32.3048, 115.9854],
  [-32.3054, 115.9867],
  [-32.3065, 115.9942],
  [-32.3065, 115.9953],
  [-32.3031, 116.0055],
  [-32.3025, 116.0062],
  [-32.3019, 116.0066],
  [-32.2985, 116.0079],
  [-32.2977, 116.0088],
  [-32.2977, 116.0088],
  [-32.2978, 116.0110],
  [-32.2986, 116.0118],
  [-32.2996, 116.0120],
  [-32.3006, 116.0116],
  [-32.3013, 116.0116],
  [-32.3020, 116.0119],
  [-32.3030, 116.0130],
  [-32.3037, 116.0134],
  [-32.3045, 116.0138],
  [-32.3058, 116.0150],
  [-32.3061, 116.0156],
  [-32.3065, 116.0165],
  [-32.3068, 116.0171],
  [-32.3073, 116.0175],
  [-32.3081, 116.0180],
  [-32.3087, 116.0185],
  [-32.3089, 116.0192],
  [-32.3094, 116.0218],
  [-32.3099, 116.0234],
  [-32.3099, 116.0245],
  [-32.3097, 116.0278],
  [-32.3099, 116.0289],
  [-32.3106, 116.0297],
  [-32.3116, 116.0300],
  [-32.3124, 116.0298],
  [-32.3131, 116.0296],
  [-32.3141, 116.0298],
  [-32.3148, 116.0306],
  [-32.3151, 116.0316],
  [-32.3145, 116.0409],
  [-32.3142, 116.0423],
  [-32.3142, 116.0434],
  [-32.3149, 116.0454],
  [-32.3154, 116.0464],
  [-32.3163, 116.0476],
  [-32.3166, 116.0482],
  [-32.3169, 116.0499],
  [-32.3175, 116.0508],
  [-32.3185, 116.0512],
  [-32.3213, 116.0517],
  [-32.3220, 116.0517],
  [-32.3235, 116.0511],
  [-32.3247, 116.0513],
  [-32.3255, 116.0518],
  [-32.3276, 116.0546],
  [-32.3278, 116.0558],
  [-32.3275, 116.0568],
  [-32.3227, 116.0644],
  [-32.3222, 116.0661],
  [-32.3221, 116.0692],
  [-32.3204, 116.0747],
  [-32.3202, 116.0762],
  [-32.3208, 116.0833],
  [-32.3204, 116.0849],
  [-32.3195, 116.0860],
  [-32.3186, 116.0864],
  [-32.3176, 116.0865],
  [-32.3152, 116.0856],
  [-32.3133, 116.0855],
  [-32.3114, 116.0863],
  [-32.3068, 116.0898],
  [-32.3051, 116.0903],
  [-32.3034, 116.0898],
  [-32.3017, 116.0887],
  [-32.2979, 116.0878],
  [-32.2966, 116.0882],
  [-32.2956, 116.0897],
  [-32.2953, 116.0914],
  [-32.2966, 116.0973],
  [-32.2964, 116.0997],
  [-32.2959, 116.1015],
  [-32.2851, 116.1149]
];

export const jarrahdale: Line = {
  name: 'Jarrahdale',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1963-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1999-02-26',
          status: 'closed'
        }]
      }
    }
  ]
};
