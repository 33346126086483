import { Line } from "../../trackTypes";

const temoraAriahPark = [
  [-34.4441, 147.5274],
  [-34.4435, 147.5269],
  [-34.4431, 147.5263],
  [-34.4428, 147.5252],
  [-34.4335, 147.4507],
  [-34.4328, 147.4495],
  [-34.4321, 147.4490],
  [-34.4309, 147.4486],
  [-34.4301, 147.4480],
  [-34.4293, 147.4469],
  [-34.4292, 147.4454],
  [-34.4296, 147.4428],
  [-34.4295, 147.4417],
  [-34.4291, 147.4401],
  [-34.4290, 147.4393],
  [-34.4292, 147.4383],
  [-34.4300, 147.4363],
  [-34.4301, 147.4352],
  [-34.4296, 147.4330],
  [-34.4295, 147.4320],
  [-34.4296, 147.4279],
  [-34.4293, 147.4269],
  [-34.4287, 147.4259],
  [-34.4267, 147.4244],
  [-34.4255, 147.4242],
  [-34.4244, 147.4246],
  [-34.4230, 147.4256],
  [-34.4221, 147.4258],
  [-34.4209, 147.4252],
  [-34.4188, 147.4230],
  [-34.4184, 147.4220],
  [-34.4180, 147.4210],
  [-34.4174, 147.4199],
  [-34.4160, 147.4187],
  [-34.4145, 147.4181],
  [-34.4134, 147.4180],
  [-34.4121, 147.4174],
  [-34.4115, 147.4160],
  [-34.4112, 147.4139],
  [-34.4105, 147.4126],
  [-34.4088, 147.4114],
  [-34.4078, 147.4103],
  [-34.4072, 147.4088],
  [-34.4059, 147.3994],
  [-34.4059, 147.3966],
  [-34.4071, 147.3725],
  [-34.4066, 147.3681],
  [-34.4046, 147.3629],
  [-34.3898, 147.3403],
  [-34.3886, 147.3365],
  [-34.3817, 147.2863],
  [-34.3800, 147.2812],
  [-34.3706, 147.2624],
  [-34.3561, 147.2380],
  [-34.3533, 147.2344],
  [-34.3438, 147.2181]
];

const ariahParkBarellan = [
  [-34.3438, 147.2181],
  [-34.3310, 147.1956],
  [-34.3302, 147.1933],
  [-34.3193, 147.1127],
  [-34.3186, 147.1106],
  [-34.3009, 147.0721],
  [-34.2964, 147.0488],
  [-34.2963, 147.0414],
  [-34.2982, 147.0220],
  [-34.3033, 146.9952],
  [-34.3045, 146.9914],
  [-34.3138, 146.9721],
  [-34.3169, 146.9680],
  [-34.3271, 146.9605],
  [-34.3287, 146.9588],
  [-34.3300, 146.9557],
  [-34.3301, 146.9529],
  [-34.3286, 146.9435],
  [-34.3285, 146.9411],
  [-34.3290, 146.9386],
  [-34.3307, 146.9356],
  [-34.3586, 146.9101],
  [-34.3593, 146.9091],
  [-34.3594, 146.9076],
  [-34.3543, 146.8829],
  [-34.3542, 146.8791],
  [-34.3539, 146.8775],
  [-34.3528, 146.8749],
  [-34.3523, 146.8733],
  [-34.3501, 146.8553],
  [-34.3503, 146.8537],
  [-34.3507, 146.8522],
  [-34.3509, 146.8503],
  [-34.3490, 146.8385],
  [-34.3492, 146.8357],
  [-34.3500, 146.8331],
  [-34.3500, 146.8308],
  [-34.3490, 146.8289],
  [-34.3479, 146.8276],
  [-34.3470, 146.8263],
  [-34.3465, 146.8245],
  [-34.3456, 146.8228],
  [-34.3443, 146.8214],
  [-34.3436, 146.8202],
  [-34.3430, 146.8181],
  [-34.3421, 146.8040],
  [-34.3409, 146.8016],
  [-34.3342, 146.7959],
  [-34.3199, 146.7790],
  [-34.3139, 146.7681],
  [-34.3130, 146.7661],
  [-34.3096, 146.7514],
  [-34.2917, 146.5998],
  [-34.2840, 146.5731]
];

const barellanGriffith = [
  [-34.2840, 146.5731],
  [-34.2815, 146.5641],
  [-34.2505, 146.4417],
  [-34.2492, 146.4385],
  [-34.2378, 146.4171],
  [-34.2371, 146.4147],
  [-34.2276, 146.3365],
  [-34.2278, 146.3335],
  [-34.2327, 146.3101],
  [-34.2347, 146.2869],
  [-34.2481, 146.1825],
  [-34.2479, 146.1801],
  [-34.2448, 146.1668],
  [-34.2423, 146.1472],
  [-34.2411, 146.1166],
  [-34.2416, 146.1133],
  [-34.2433, 146.1101],
  [-34.2456, 146.1077],
  [-34.2802, 146.0865],
  [-34.2872, 146.0802],
  [-34.2888, 146.0779],
  [-34.2896, 146.0754],
  [-34.2898, 146.0727],
  [-34.2887, 146.0643],
  [-34.2866, 146.0473]
];

const griffithHillston = [
  [-34.2866, 146.0473],
  [-34.2860, 146.0424],
  [-34.2854, 146.0408],
  [-34.2846, 146.0400],
  [-34.2706, 146.0285],
  [-34.2694, 146.0270],
  [-34.2682, 146.0244],
  [-34.2661, 146.0114],
  [-34.2649, 146.0087],
  [-34.2633, 146.0067],
  [-34.2611, 146.0045],
  [-34.2587, 146.0021],
  [-34.2576, 146.0005],
  [-34.2532, 145.9893],
  [-34.2521, 145.9875],
  [-34.2366, 145.9685],
  [-34.2348, 145.9663],
  [-34.2301, 145.9621],
  [-34.2275, 145.9610],
  [-34.2141, 145.9589],
  [-34.1978, 145.9564],
  [-34.1943, 145.9552],
  [-34.1918, 145.9534],
  [-34.1801, 145.9435],
  [-34.1784, 145.9412],
  [-34.1773, 145.9389],
  [-34.1740, 145.9292],
  [-34.1732, 145.9276],
  [-34.1673, 145.9198],
  [-34.1662, 145.9187],
  [-34.1532, 145.9098],
  [-34.1510, 145.9077],
  [-34.1096, 145.8550],
  [-34.0999, 145.8427],
  [-34.0790, 145.8210],
  [-34.0233, 145.7631],
  [-33.9771, 145.7014],
  [-33.9746, 145.6996],
  [-33.9714, 145.6991],
  [-33.9676, 145.6998],
  [-33.9648, 145.6994],
  [-33.9625, 145.6981],
  [-33.9260, 145.6610],
  [-33.9239, 145.6598],
  [-33.8210, 145.6231],
  [-33.7363, 145.5929],
  [-33.6998, 145.5797],
  [-33.5952, 145.5425],
  [-33.5933, 145.5412],
  [-33.5893, 145.5363],
  [-33.5870, 145.5345],
  [-33.5766, 145.5295],
  [-33.5485, 145.5264],
  [-33.5106, 145.5328],
  [-33.5094, 145.5328],
  [-33.5009, 145.5322],
  [-33.4856, 145.5347],
  [-33.4761, 145.5367]
];

const hillstonRotoSouthJunction = [
  [-33.4761, 145.5367],
  [-33.4484, 145.5418],
  [-33.4450, 145.5411],
  [-33.4425, 145.5391],
  [-33.4402, 145.5358],
  [-33.4381, 145.5344],
  [-33.4200, 145.5315],
  [-33.3691, 145.5138],
  [-33.3244, 145.5029],
  [-33.1985, 145.4835],
  [-33.1518, 145.4748],
  [-33.1059, 145.4661],
  [-33.0518, 145.4619]
];

const rotoSouthJunctionRotoEastJunction = [
  [-33.0518, 145.4619],
  [-33.0511, 145.4621],
  [-33.0506, 145.4624],
  [-33.0500, 145.4632]
];

const rotoSouthJunctionRotoWestJunction = [
  [-33.0518, 145.4619],
  [-33.0512, 145.4615],
  [-33.0508, 145.4609],
  [-33.0506, 145.4598]
];

export const temoraRoto: Line = {
  name: 'Temora - Roto',
  state: 'NSW',
  segments: [
    {
      segments: [temoraAriahPark],
      history: {
        opened: {
          date: '1906-11-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ariahParkBarellan],
      history: {
        opened: {
          date: '1908-11-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [barellanGriffith],
      history: {
        opened: {
          date: '1916-07-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [griffithHillston],
      history: {
        opened: {
          date: '1923-06-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hillstonRotoSouthJunction],
      history: {
        opened: {
          date: '1931-03-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-09-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rotoSouthJunctionRotoEastJunction],
      history: {
        opened: {
          date: '1931-03-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-09-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rotoSouthJunctionRotoWestJunction],
      history: {
        opened: {
          date: '1931-03-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-09-31',
          status: 'closed'
        }]
      }
    }
  ]
};
