import { Line } from "../../trackTypes";

const palmerstonNorthFoxton = [
  [-40.3846, 175.5404],
  [-40.3850, 175.5398],
  [-40.4133, 175.4763],
  [-40.4142, 175.4751],
  [-40.4157, 175.4741],
  [-40.4179, 175.4733],
  [-40.4192, 175.4723],
  [-40.4203, 175.4704],
  [-40.4251, 175.4581],
  [-40.4253, 175.4556],
  [-40.4250, 175.4534],
  [-40.4227, 175.4483],
  [-40.4223, 175.4467],
  [-40.4223, 175.4450],
  [-40.4225, 175.4410],
  [-40.4220, 175.4382],
  [-40.4210, 175.4353],
  [-40.4179, 175.4271],
  [-40.4180, 175.4228],
  [-40.4196, 175.4136],
  [-40.4196, 175.4120],
  [-40.4192, 175.4107],
  [-40.4156, 175.4042],
  [-40.4151, 175.4029],
  [-40.4093, 175.3799],
  [-40.4085, 175.3780],
  [-40.4059, 175.3727],
  [-40.4026, 175.3633],
  [-40.3962, 175.3387],
  [-40.3957, 175.3357],
  [-40.3944, 175.3295],
  [-40.3944, 175.3277],
  [-40.3949, 175.3258],
  [-40.4002, 175.3140],
  [-40.4010, 175.3128],
  [-40.4021, 175.3118],
  [-40.4629, 175.2907],
  [-40.4642, 175.2888],
  [-40.4644, 175.2879],
  [-40.4645, 175.2868],
  [-40.4639, 175.2832],
  [-40.4640, 175.2826],
  [-40.4646, 175.2808],
  [-40.4651, 175.2798],
  [-40.4659, 175.2788],
  [-40.4668, 175.2783]
];

export const foxton: Line = {
  name: 'Foxton',
  state: 'NZ',
  segments: [
    {
      segments: [palmerstonNorthFoxton],
      history: {
        opened: {
          date: '1871-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1876-04-27',
          trackType: 'heavy'
        }, {
          date: '1959-07-18',
          status: 'closed'
        }]
      }
    }
  ]
}
