import { Line } from "../../../trackTypes";

const workshops = [
  [-27.6131, 152.7575],
  [-27.6127, 152.7581],
  [-27.6121, 152.7585],
  [-27.6089, 152.7594],
  [-27.6082, 152.7594],
  [-27.6076, 152.7592],
  [-27.6064, 152.7585],
  [-27.6059, 152.7583],
  [-27.6043, 152.7581],
  [-27.6036, 152.7582],
  [-27.5987, 152.7596]
];

const trivoli = [
  [-27.5987, 152.7596],
  [-27.5979, 152.7600],
  [-27.5964, 152.7613],
  [-27.5950, 152.7622],
  [-27.5934, 152.7627],
  [-27.5888, 152.7635],
  [-27.5881, 152.7634],
  [-27.5867, 152.7630],
  [-27.5861, 152.7629],
  [-27.5856, 152.7631],
  [-27.5845, 152.7647],
  [-27.5839, 152.7666],
  [-27.5830, 152.7681],
  [-27.5824, 152.7686],
  [-27.5809, 152.7690],
  [-27.5787, 152.7691],
  [-27.5775, 152.7699],
  [-27.5769, 152.7707],
  [-27.5752, 152.7753],
  [-27.5749, 152.7781]
];

const mtCrosby = [
  [-27.5752, 152.7753],
  [-27.5746, 152.7754],
  [-27.5684, 152.7762],
  [-27.5675, 152.7760],
  [-27.5670, 152.7755],
  [-27.5646, 152.7722],
  [-27.5645, 152.7715],
  [-27.5647, 152.7709],
  [-27.5653, 152.7696],
  [-27.5652, 152.7685],
  [-27.5645, 152.7680],
  [-27.5638, 152.7677],
  [-27.5623, 152.7678],
  [-27.5616, 152.7683],
  [-27.5614, 152.7691],
  [-27.5615, 152.7697],
  [-27.5619, 152.7706],
  [-27.5618, 152.7713],
  [-27.5615, 152.7719],
  [-27.5609, 152.7724],
  [-27.5607, 152.7733],
  [-27.5609, 152.7743],
  [-27.5606, 152.7754],
  [-27.5598, 152.7763],
  [-27.5587, 152.7765],
  [-27.5578, 152.7776],
  [-27.5571, 152.7781],
  [-27.5566, 152.7791],
  [-27.5546, 152.7806],
  [-27.5543, 152.7811],
  [-27.5539, 152.7818],
  [-27.5533, 152.7822],
  [-27.5528, 152.7822],
  [-27.5521, 152.7820],
  [-27.5516, 152.7821],
  [-27.5513, 152.7824],
  [-27.5511, 152.7832],
  [-27.5507, 152.7836],
  [-27.5497, 152.7837],
  [-27.5487, 152.7843],
  [-27.5481, 152.7850],
  [-27.5461, 152.7868],
  [-27.5453, 152.7873],
  [-27.5447, 152.7874],
  [-27.5441, 152.7875],
  [-27.5431, 152.7874],
  [-27.5426, 152.7877],
  [-27.5381, 152.7936],
  [-27.5376, 152.7946],
  [-27.5370, 152.8001],
  [-27.5367, 152.8009]
];

export const trivoliMtCrosby: Line = {
  name: 'Trivoli / Mt Crosby',
  state: 'QLD',
  segments: [
    {
      segments: [workshops],
      history: {
        opened: {
          date: '1898-03-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [trivoli],
      history: {
        opened: {
          date: '1898-03-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1965-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mtCrosby],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1932-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
