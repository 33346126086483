import { Line } from "../../trackTypes";

const sunburyWoodend = [
  [-37.5791, 144.7279],
  [-37.5761, 144.7301],
  [-37.5750, 144.7310],
  [-37.5732, 144.7328],
  [-37.5716, 144.7349],
  [-37.5705, 144.7367],
  [-37.5701, 144.7376],
  [-37.5671, 144.7448],
  [-37.5656, 144.7474],
  [-37.5639, 144.7496],
  [-37.5622, 144.7509],
  [-37.5602, 144.7519],
  [-37.5582, 144.7524],
  [-37.5562, 144.7524],
  [-37.5551, 144.7522],
  [-37.5536, 144.7518],
  [-37.5517, 144.7516],
  [-37.5505, 144.7516],
  [-37.5492, 144.7518],
  [-37.5047, 144.7602],
  [-37.5032, 144.7603],
  [-37.5013, 144.7602],
  [-37.4988, 144.7597],
  [-37.4972, 144.7591],
  [-37.4933, 144.7573],
  [-37.4907, 144.7556],
  [-37.4889, 144.7539],
  [-37.4878, 144.7525],
  [-37.4863, 144.7502],
  [-37.4818, 144.7421],
  [-37.4661, 144.7142],
  [-37.4650, 144.7117],
  [-37.4638, 144.7079],
  [-37.4632, 144.7045],
  [-37.4631, 144.7015],
  [-37.4632, 144.6987],
  [-37.4685, 144.6524],
  [-37.4686, 144.6490],
  [-37.4685, 144.6464],
  [-37.4681, 144.6438],
  [-37.4564, 144.5866],
  [-37.4553, 144.5832],
  [-37.4540, 144.5807],
  [-37.4526, 144.5787],
  [-37.4503, 144.5764],
  [-37.4369, 144.5663],
  [-37.4355, 144.5655],
  [-37.4275, 144.5619],
  [-37.4264, 144.5616],
  [-37.4254, 144.5614],
  [-37.4129, 144.5604],
  [-37.4107, 144.5606],
  [-37.4064, 144.5614],
  [-37.4049, 144.5615],
  [-37.4028, 144.5611],
  [-37.4008, 144.5603],
  [-37.3996, 144.5596],
  [-37.3938, 144.5544],
  [-37.3919, 144.5531],
  [-37.3893, 144.5519],
  [-37.3854, 144.5511],
  [-37.3837, 144.5506],
  [-37.3743, 144.5472],
  [-37.3725, 144.5462],
  [-37.3706, 144.5447],
  [-37.3689, 144.5429],
  [-37.3676, 144.5411],
  [-37.3593, 144.5261]
];

const woodendKynton = [
  [-37.3593, 144.5261],
  [-37.3524, 144.5139],
  [-37.3513, 144.5124],
  [-37.3500, 144.5111],
  [-37.3483, 144.5097],
  [-37.3464, 144.5085],
  [-37.3452, 144.5080],
  [-37.3299, 144.5037],
  [-37.3282, 144.5030],
  [-37.3264, 144.5021],
  [-37.3247, 144.5007],
  [-37.2816, 144.4602],
  [-37.2795, 144.4586],
  [-37.2778, 144.4578],
  [-37.2763, 144.4574],
  [-37.2745, 144.4573],
  [-37.2715, 144.4574],
  [-37.2692, 144.4572],
  [-37.2680, 144.4568],
  [-37.2627, 144.4546],
  [-37.2610, 144.4536],
  [-37.2595, 144.4522],
  [-37.2587, 144.4513],
  [-37.2585, 144.4510],
  [-37.2583, 144.4507]
];

const kyntonElphinstoneTunnel = [
  [-37.2583, 144.4507],
  [-37.2573, 144.4488],
  [-37.2550, 144.4428],
  [-37.2547, 144.4411],
  [-37.2542, 144.4358],
  [-37.2532, 144.4312],
  [-37.2511, 144.4269],
  [-37.2480, 144.4234],
  [-37.1961, 144.3868],
  [-37.1933, 144.3838],
  [-37.1921, 144.3814],
  [-37.1891, 144.3737],
  [-37.1872, 144.3701],
  [-37.1847, 144.3677],
  [-37.1731, 144.3603],
  [-37.1690, 144.3590],
  [-37.1340, 144.3561],
  [-37.1316, 144.3556],
  [-37.1293, 144.3545],
  [-37.1281, 144.3538],
  [-37.1201, 144.3481],
  [-37.1164, 144.3467],
  [-37.1136, 144.3463],
  [-37.1111, 144.3456],
  [-37.1086, 144.3439],
  [-37.1072, 144.3425],
  [-37.1060, 144.3408],
  [-37.1033, 144.3352],
  [-37.1026, 144.3329],
  [-37.1024, 144.3304],
  [-37.1021, 144.3201],
  [-37.1025, 144.3159],
  [-37.1032, 144.3133],
  [-37.1044, 144.3104],
  [-37.1048, 144.3091]
];

const elphinstoneTunnel = [
  [-37.1048, 144.3091],
  [-37.1052, 144.3073],
  [-37.1054, 144.3049]
];

const elphinstoneTunnelBigHill = [
  [-37.1054, 144.3049],
  [-37.1053, 144.3029],
  [-37.1046, 144.2997],
  [-37.1031, 144.2963],
  [-37.1012, 144.2932],
  [-37.0929, 144.2824],
  [-37.0912, 144.2793],
  [-37.0904, 144.2770],
  [-37.0837, 144.2554],
  [-37.0824, 144.2504],
  [-37.0791, 144.2447],
  [-37.0781, 144.2421],
  [-37.0725, 144.2209],
  [-37.0712, 144.2178],
  [-37.0695, 144.2154],
  [-37.0674, 144.2140],
  [-37.0657, 144.2134],
  [-37.0644, 144.2134],
  [-37.0557, 144.2156],
  [-37.0538, 144.2164],
  [-37.0453, 144.2221],
  [-37.0424, 144.2250],
  [-37.0411, 144.2259],
  [-37.0385, 144.2273],
  [-37.0370, 144.2284],
  [-37.0309, 144.2343],
  [-37.0281, 144.2360],
  [-37.0234, 144.2378],
  [-37.0202, 144.2395],
  [-37.0157, 144.2428],
  [-37.0133, 144.2449],
  [-37.0113, 144.2472],
  [-37.0106, 144.2478],
  [-37.0079, 144.2494],
  [-37.0037, 144.2534],
  [-37.0008, 144.2550],
  [-36.9969, 144.2555],
  [-36.9962, 144.2553],
  [-36.9937, 144.2548],
  [-36.9910, 144.2548],
  [-36.9881, 144.2553],
  [-36.9857, 144.2553],
  [-36.9843, 144.2549],
  [-36.9816, 144.2537],
  [-36.9781, 144.2528],
  [-36.9752, 144.2513],
  [-36.9697, 144.2472],
  [-36.9646, 144.2436],
  [-36.9625, 144.2428],
  [-36.9610, 144.2425],
  [-36.9586, 144.2424],
  [-36.9559, 144.2419],
  [-36.9499, 144.2403],
  [-36.9466, 144.2388],
  [-36.9439, 144.2370],
  [-36.9411, 144.2357],
  [-36.9371, 144.2345],
  [-36.9329, 144.2314],
  [-36.9293, 144.2299],
  [-36.9201, 144.2277],
  [-36.9107, 144.2267],
  [-36.9082, 144.2261],
  [-36.9020, 144.2239],
  [-36.8998, 144.2235],
  [-36.8953, 144.2233],
  [-36.8933, 144.2235],
  [-36.8917, 144.2240],
  [-36.8864, 144.2261],
  [-36.8837, 144.2265],
  [-36.8811, 144.2265],
  [-36.8769, 144.2273],
  [-36.8658, 144.2304],
  [-36.8631, 144.2305],
  [-36.8598, 144.2298],
  [-36.8572, 144.2299],
  [-36.8549, 144.2307]
];

const bigHillTunnel = [
  [-36.8549, 144.2307],
  [-36.8515, 144.2321]
];

const bigHillBendigo = [
  [-36.8515, 144.2321],
  [-36.8496, 144.2330],
  [-36.8478, 144.2344],
  [-36.8431, 144.2400],
  [-36.8405, 144.2424],
  [-36.8389, 144.2431],
  [-36.8356, 144.2442],
  [-36.8339, 144.2449],
  [-36.8304, 144.2469],
  [-36.8283, 144.2479],
  [-36.8255, 144.2484],
  [-36.8229, 144.2479],
  [-36.8153, 144.2451],
  [-36.8122, 144.2447],
  [-36.8095, 144.2450],
  [-36.7829, 144.2522],
  [-36.7800, 144.2536],
  [-36.7774, 144.2556],
  [-36.7747, 144.2592],
  [-36.7705, 144.2682],
  [-36.7673, 144.2796],
  [-36.7662, 144.2819],
  [-36.7655, 144.2830]
];

export const bendigo: Line = {
  name: 'Bendigo',
  state: 'VIC',
  segments: [
    {
      segments: [sunburyWoodend],
      history: {
        opened: {
          date: '1861-07-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1861-10-14',
          tracks: 2
        }]
      }
    },
    {
      segments: [woodendKynton],
      history: {
        opened: {
          date: '1862-04-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1863-02-17',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        kyntonElphinstoneTunnel,
        {
          name: 'Elphinstone Tunnel',
          segment: elphinstoneTunnel,
          type: 'tunnel'
        },
        elphinstoneTunnelBigHill,
        {
          name: 'Big Hill Tunnel',
          segment: bigHillTunnel,
          type: 'tunnel'
        },
        bigHillBendigo
      ],
      history: {
        opened: {
          date: '1862-10-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1863-02-17',
          tracks: 2
        }, {
          date: '2006-02-20',
          tracks: 1
        }]
      }
    }
  ]
};
