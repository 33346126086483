import { Line } from "../../../../trackTypes";

const victoriaSquareNorth = [
  [-34.92712, 138.59986],
  [-34.92864, 138.60002]
];

const victoriaSquare = [
  [-34.92864, 138.60002],
  [-34.93013, 138.60009]
];

const cbd = [
  [-34.93013, 138.60009],
  [-34.93988, 138.60086],
  [-34.94037, 138.60082],
  [-34.94093, 138.60068],
  [-34.94214, 138.60022],
  [-34.94366, 138.59933]
];

const hydePark = [
  [-34.94366, 138.59933],
  [-34.94384, 138.59926],
  [-34.94398, 138.59924],
  [-34.95743, 138.60027],
  [-34.95802, 138.60021],
  [-34.96045, 138.60037]
];

const groveStreet = [
  [-34.96045, 138.60037],
  [-34.96049, 138.60041],
  [-34.96052, 138.60047],
  [-34.96047, 138.60129]
];

const crossRoad = [
  [-34.96045, 138.60037],
  [-34.96062, 138.60038],
  [-34.96089, 138.60035],
  [-34.96143, 138.60017],
  [-34.96185, 138.60010],
  [-34.96568, 138.60042]
];

const victoriaLoopSouthEast = [
  [-34.92859, 138.60076],
  [-34.93007, 138.60087]
];

const victoriaLoopSouthEast2 = [
  [-34.93007, 138.60087],
  [-34.93010, 138.60013]
];

const flindersStreetLoop = [
  [-34.92859, 138.60076],
  [-34.92708, 138.60065]
];

const cityDepot = [
  [-34.93007, 138.60087],
  [-34.93004, 138.60152]
];

export const south4: Line = {
  name: 'South4',
  state: 'SA',
  segments: [
    {
      segments: [victoriaSquareNorth],
      history: {
        opened: {
          date: '1883-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-04-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaSquare],
      history: {
        opened: {
          date: '1883-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2007-08-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cbd],
      history: {
        opened: {
          date: '1883-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hydePark],
      history: {
        opened: {
          date: '1883-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1955-11-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [groveStreet],
      history: {
        opened: {
          date: '1883-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-07-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [crossRoad],
      history: {
        opened: {
          date: '1924-01-27',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-11-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaLoopSouthEast],
      history: {
        opened: {
          date: '1911-07-02',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaLoopSouthEast2],
      history: {
        opened: {
          date: '1911-07-02',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1986-10-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [flindersStreetLoop],
      history: {
        opened: {
          date: '1921-05-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-03-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityDepot],
      history: {
        opened: {
          date: '1923-12-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1986-10-19',
          status: 'closed'
        }]
      }
    }
  ]
};
