import { Line } from "../../../../trackTypes";

const kingWilliamStreet = [
  [-34.92298, 138.59952],
  [-34.92155, 138.59942]
];

const festivalPlaza = [
  [-34.92155, 138.59942],
  [-34.91806, 138.59914]
];

const northAdelaide = [
  [-34.91806, 138.59914],
  [-34.91573, 138.59888],
  [-34.91536, 138.59883],
  [-34.91358, 138.59866],
  [-34.91257, 138.59866],
  [-34.91226, 138.59872],
  [-34.91204, 138.59880],
  [-34.91103, 138.59912],
  [-34.91074, 138.59913],
  [-34.91055, 138.59903],
  [-34.91040, 138.59891],
  [-34.91021, 138.59863],
  [-34.91007, 138.59827],
  [-34.90982, 138.59760],
  [-34.90967, 138.59734],
  [-34.90942, 138.59713],
  [-34.90879, 138.59688]
];

const northAdelaideUpper = [
  [-34.90879, 138.59688],
  [-34.90343, 138.59496]
];

const victoriaSquare = [
  [-34.92437, 138.59964],
  [-34.92449, 138.59965],
  [-34.92576, 138.59973],
  [-34.92712, 138.59986]
];

const northLoop = [
  [-34.90553, 138.58608],
  [-34.90544, 138.58606],
  [-34.90533, 138.58609],
  [-34.90528, 138.58617],
  [-34.90335, 138.59469],
  [-34.90334, 138.59481],
  [-34.90336, 138.59489],
  [-34.90343, 138.59496]
];

const northLoopHillStreet = [
  [-34.90879, 138.59688],
  [-34.90869, 138.59675],
  [-34.90869, 138.59655],
  [-34.91059, 138.58819],
  [-34.91054, 138.58799],
  [-34.91054, 138.58788],
  [-34.90553, 138.58608]
];

const adelaideOval = [
  [-34.91536, 138.59883],
  [-34.91524, 138.59866],
  [-34.91521, 138.59849],
  [-34.91539, 138.59744]
];

const bowden = [
  [-34.90553, 138.58608],
  [-34.90393, 138.58549],
  [-34.90348, 138.58526],
  [-34.90311, 138.58486],
  [-34.90265, 138.58409],
  [-34.90125, 138.58192]
];

const croydon = [
  [-34.90125, 138.58192],
  [-34.90018, 138.58032],
  [-34.90004, 138.58020],
  [-34.89967, 138.58008],
  [-34.89955, 138.58000],
  [-34.89944, 138.57989],
  [-34.89513, 138.57347],
  [-34.89263, 138.56932]
];

const southRd = [
  [-34.89263, 138.56932],
  [-34.89239, 138.56910],
  [-34.89203, 138.56906],
  [-34.88867, 138.57006]
];

const kilkenny = [
  [-34.88867, 138.57006],
  [-34.88854, 138.57007],
  [-34.88841, 138.57004],
  [-34.88830, 138.56997],
  [-34.87679, 138.55100]
];

const cheltenham = [
  [-34.87679, 138.55100],
  [-34.86382, 138.52967]
];

export const north1: Line = {
  name: 'North1',
  state: 'SA',
  segments: [
    {
      segments: [kingWilliamStreet],
      history: {
        opened: {
          date: '1878-12-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1909-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-11-22',
          status: 'closed'
        }, {
          date: '2007-10-14',
          status: 'open'
        }]
      }
    },
    {
      segments: [victoriaSquare],
      history: {
        opened: {
          date: '1878-12-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-04-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-11-22',
          status: 'closed'
        }, {
          date: '2007-10-07',
          status: 'open'
        }]
      }
    },
    {
      segments: [festivalPlaza],
      history: {
        opened: {
          date: '1878-12-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-04-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-11-22',
          status: 'closed'
        }, {
          date: '2018-10-13',
          status: 'open'
        }]
      }
    },
    {
      segments: [northAdelaide],
      history: {
        opened: {
          date: '1878-12-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-04-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northAdelaideUpper],
      history: {
        opened: {
          date: '1878-12-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-04-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northLoop],
      history: {
        opened: {
          date: '1883-09-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-06-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northLoopHillStreet],
      history: {
        opened: {
          date: '1883-09-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-06-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adelaideOval],
      history: {
        opened: {
          date: '1909-04-07',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-11-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bowden],
      history: {
        opened: {
          date: '1912-03-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [croydon],
      history: {
        opened: {
          date: '1923-01-28',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southRd],
      history: {
        opened: {
          date: '1928-09-02',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kilkenny],
      history: {
        opened: {
          date: '1929-07-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cheltenham],
      history: {
        opened: {
          date: '1942-04-12',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    }
  ]
};
