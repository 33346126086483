import { Line } from "../../../trackTypes";

const line = [
  [-17.97189, 122.23863],
  [-17.97007, 122.23677],
  [-17.96990, 122.23671],
  [-17.96968, 122.23665],
  [-17.96796, 122.23683],
  [-17.96755, 122.23684],
  [-17.96566, 122.23703],
  [-17.96536, 122.23713],
  [-17.96508, 122.23727],
  [-17.96471, 122.23754],
  [-17.96263, 122.23972],
  [-17.96242, 122.23990],
  [-17.96224, 122.23999],
  [-17.96210, 122.24004],
  [-17.95526, 122.24139],
  [-17.95514, 122.24154],
  [-17.95509, 122.24171],
  [-17.95528, 122.24283],
  [-17.95552, 122.24423],
  [-17.95548, 122.24435],
  [-17.95540, 122.24442],
  [-17.95163, 122.24515],
  [-17.95150, 122.24512],
  [-17.95144, 122.24504],
  [-17.95129, 122.24413],
  [-17.95133, 122.24399],
  [-17.95145, 122.24388],
  [-17.95453, 122.24331],
  [-17.95504, 122.24313],
  [-17.95520, 122.24301],
  [-17.95528, 122.24283]
];

/**
 * opened as horse tramway
 * converted to steam tram in 1910
 */

export const broomeTrams: Line = {
  name: 'Broome Trams',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
