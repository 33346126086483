import { Line } from "../../trackTypes";

const collieCardiff = [
  [-33.3604, 116.1576],
  [-33.3610, 116.1628],
  [-33.3617, 116.1650],
  [-33.3638, 116.1666],
  [-33.3705, 116.1788],
  [-33.3729, 116.1813],
  [-33.3751, 116.1823],
  [-33.3773, 116.1827],
  [-33.3832, 116.1814],
  [-33.3854, 116.1805],
  [-33.3876, 116.1810],
  [-33.3892, 116.1828],
  [-33.3905, 116.1869],
  [-33.3920, 116.1891],
  [-33.3933, 116.1899],
  [-33.4019, 116.1906],
  [-33.4044, 116.1916],
  [-33.4109, 116.1964],
  [-33.4201, 116.2004],
  [-33.4305, 116.2077],
  [-33.4323, 116.2098],
  [-33.4336, 116.2125],
  [-33.4374, 116.2170],
  [-33.4453, 116.2247],
  [-33.4463, 116.2270],
  [-33.4461, 116.2315],
  [-33.4452, 116.2334],
  [-33.4435, 116.2352],
  [-33.4415, 116.2371],
  [-33.4395, 116.2375],
  [-33.4372, 116.2367],
  [-33.4356, 116.2370],
  [-33.4344, 116.2378],
  [-33.4324, 116.2408],
  [-33.4310, 116.2421],
  [-33.4281, 116.2438]
];

const cardiffExtension = [
  [-33.4435, 116.2352],
  [-33.4429, 116.2368],
  [-33.4426, 116.2384],
  [-33.4429, 116.2451],
  [-33.4443, 116.2505],
  [-33.4444, 116.2519],
  [-33.4446, 116.2619],
  [-33.4448, 116.2640],
  [-33.4468, 116.2705]
];

export const cardiff: Line = {
  name: 'Cardiff',
  state: 'WA',
  segments: [
    {
      segments: [collieCardiff],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cardiffExtension],
      history: {
        opened: {
          date: '1951-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
