import { Line } from "../../../trackTypes";

const ryrieStreetEast = [
  [-38.14923, 144.35957],
  [-38.15278, 144.37203]
];

const easternPark = [
  [-38.15278, 144.37203],
  [-38.15565, 144.38213]
];

const gardenStreet = [
  [-38.15278, 144.37203],
  [-38.15290, 144.37216],
  [-38.15306, 144.37221],
  [-38.15471, 144.37146]
];

const cemetery = [
  [-38.15471, 144.37146],
  [-38.15487, 144.37146],
  [-38.15502, 144.37158],
  [-38.16838, 144.38424]
];

const kardiniaPark = [
  [-38.14915, 144.35989],
  [-38.16152, 144.35423]
];

const barwonBridge = [
  [-38.16152, 144.35423],
  [-38.16357, 144.35329]
];

const belmont = [
  [-38.16357, 144.35329],
  [-38.16591, 144.35220],
  [-38.16639, 144.35190],
  [-38.16689, 144.35145],
  [-38.17521, 144.34255]
];

const drumcondra = [
  [-38.12761, 144.35229],
  [-38.12817, 144.35660],
  [-38.12826, 144.35677],
  [-38.12838, 144.35682],
  [-38.12872, 144.35677],
  [-38.12900, 144.35674],
  [-38.12941, 144.35675],
  [-38.12957, 144.35674],
  [-38.12972, 144.35667],
  [-38.13043, 144.35626],
  [-38.13068, 144.35617],
  [-38.13127, 144.35607],
  [-38.13163, 144.35606],
  [-38.13186, 144.35604],
  [-38.13225, 144.35587],
  [-38.13260, 144.35560],
  [-38.13299, 144.35547],
  [-38.13553, 144.35497],
  [-38.13799, 144.35448],
  [-38.13857, 144.35450],
  [-38.14509, 144.35715],
  [-38.14549, 144.35736],
  [-38.14579, 144.35771]
];

const rippleside = [
  [-38.12327, 144.35272],
  [-38.12717, 144.35190],
  [-38.12746, 144.35198],
  [-38.12761, 144.35229]
];

const northGeelong = [
  [-38.11321, 144.35484],
  [-38.12327, 144.35272]
];

const chilwell = [
  [-38.15101, 144.34634],
  [-38.15942, 144.34477]
];

const pakingtonStreetJunction = [
  [-38.14662, 144.34717],
  [-38.14688, 144.34713]
];

const newtown = [
  [-38.14677, 144.34732],
  [-38.14680, 144.34721],
  [-38.14688, 144.34713],
  [-38.15101, 144.34634],
  [-38.15109, 144.34624],
  [-38.15111, 144.34606],
  [-38.14983, 144.33482]
];

const ryrieStreetWest = [
  [-38.14915, 144.35989],
  [-38.14919, 144.35981],
  [-38.14922, 144.35969],
  [-38.14923, 144.35957],
  [-38.14754, 144.35361],
  [-38.14745, 144.35314],
  [-38.14677, 144.34732]
];

const pakingtonStreetNorth = [
  [-38.14677, 144.34732],
  [-38.14672, 144.34723],
  [-38.14662, 144.34717],
  [-38.13233, 144.34990]
];

const easternBeach = [
  [-38.14568, 144.36338],
  [-38.14657, 144.36654]
];

const wharf = [
  [-38.14915, 144.35989],
  [-38.14742, 144.36067],
  [-38.14532, 144.36166],
  [-38.14293, 144.36271]
];

const station = [
  [-38.14742, 144.36067],
  [-38.14732, 144.36066],
  [-38.14726, 144.36062],
  [-38.14717, 144.36049],
  [-38.14660, 144.35843],
  [-38.14652, 144.35828],
  [-38.14641, 144.35821],
  [-38.14619, 144.35809],
  [-38.14598, 144.35793],
  [-38.14579, 144.35771],
  [-38.14516, 144.35511]
];

const depoConnection = [
  [-38.14532, 144.36166],
  [-38.14527, 144.36178],
  [-38.14526, 144.36193],
  [-38.14568, 144.36338],
  [-38.14574, 144.36346],
  [-38.14577, 144.36347],
  [-38.14595, 144.36339]
];

export const geelongTrams: Line = {
  name: 'Geelong Trams',
  state: 'VIC',
  segments: [
    {
      segments: [kardiniaPark],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [barwonBridge],
      history: {
        opened: {
          date: '1913-12-01',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [belmont],
      history: {
        opened: {
          date: '1927-12-16',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ryrieStreetEast],
      history: {
        opened: {
          date: '1922-10-12',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1923-01-01',
          tracks: 2
        }, {
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [easternPark],
      history: {
        opened: {
          date: '1930-09-10',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gardenStreet],
      history: {
        opened: {
          date: '1923-01-25',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cemetery],
      history: {
        opened: {
          date: '1923-03-01',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [drumcondra],
      history: {
        opened: {
          date: '1928-07-06',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1930-01-01',
          tracks: 2
        }, {
          date: '1956-03-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rippleside],
      history: {
        opened: {
          date: '1929-06-14',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1930-01-01',
          tracks: 2
        }, {
          date: '1956-03-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northGeelong],
      history: {
        opened: {
          date: '1929-12-20',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-03-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chilwell],
      history: {
        opened: {
          date: '1927-09-30',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newtown, pakingtonStreetNorth, pakingtonStreetJunction],
      history: {
        opened: {
          date: '1912-03-14',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ryrieStreetWest],
      history: {
        opened: {
          date: '1912-03-14',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1929-04-01',
          tracks: 2
        }, {
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [easternBeach],
      history: {
        opened: {
          date: '1940-10-19',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wharf, station],
      history: {
        opened: {
          date: '1912-03-14',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1914-01-01',
          tracks: 2
        }, {
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [depoConnection],
      history: {
        opened: {
          date: '1912-03-14',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1956-01-08',
          status: 'closed'
        }]
      }
    }
  ]
};
