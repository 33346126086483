import { Line } from "../../../trackTypes";

const line = [
  [-26.5780, 148.7719],
  [-26.5775, 148.7704],
  [-26.5770, 148.7696],
  [-26.5671, 148.7615],
  [-26.5658, 148.7608],
  [-26.5316, 148.7510],
  [-26.5299, 148.7501],
  [-26.5069, 148.7295],
  [-26.5046, 148.7264],
  [-26.5007, 148.7189],
  [-26.4982, 148.7122],
  [-26.4940, 148.7018],
  [-26.4935, 148.7009],
  [-26.4907, 148.6977],
  [-26.4881, 148.6938],
  [-26.4849, 148.6876],
  [-26.4838, 148.6861],
  [-26.4785, 148.6796],
  [-26.4776, 148.6779],
  [-26.4767, 148.6757],
  [-26.4756, 148.6743],
  [-26.4744, 148.6733],
  [-26.4710, 148.6720],
  [-26.4701, 148.6714],
  [-26.4685, 148.6699],
  [-26.4675, 148.6693],
  [-26.4655, 148.6691],
  [-26.4645, 148.6682],
  [-26.4611, 148.6628],
  [-26.4599, 148.6617],
  [-26.4587, 148.6615],
  [-26.4574, 148.6619],
  [-26.4557, 148.6627],
  [-26.4538, 148.6628],
  [-26.4519, 148.6619],
  [-26.4466, 148.6571],
  [-26.4434, 148.6540],
  [-26.4371, 148.6502],
  [-26.4361, 148.6500],
  [-26.4344, 148.6501],
  [-26.4317, 148.6494],
  [-26.4309, 148.6489],
  [-26.4295, 148.6474],
  [-26.4283, 148.6468],
  [-26.4274, 148.6468],
  [-26.4253, 148.6475],
  [-26.4243, 148.6482],
  [-26.4190, 148.6540],
  [-26.4172, 148.6548],
  [-26.4045, 148.6569],
  [-26.4034, 148.6569],
  [-26.4023, 148.6563],
  [-26.4002, 148.6546],
  [-26.3991, 148.6543],
  [-26.3979, 148.6544],
  [-26.3964, 148.6551],
  [-26.3952, 148.6550],
  [-26.3941, 148.6545],
  [-26.3913, 148.6526],
  [-26.3899, 148.6521],
  [-26.3651, 148.6555],
  [-26.3640, 148.6555],
  [-26.3596, 148.6545],
  [-26.3590, 148.6541],
  [-26.3585, 148.6535],
  [-26.3568, 148.6481],
  [-26.3563, 148.6471],
  [-26.3512, 148.6412],
  [-26.3490, 148.6400],
  [-26.3434, 148.6392],
  [-26.3416, 148.6382],
  [-26.3407, 148.6371],
  [-26.3391, 148.6342],
  [-26.3368, 148.6325],
  [-26.3286, 148.6307],
  [-26.3252, 148.6277],
  [-26.3184, 148.6158],
  [-26.3178, 148.6141],
  [-26.3172, 148.6122],
  [-26.3122, 148.6059],
  [-26.3085, 148.5964],
  [-26.3082, 148.5905],
  [-26.3087, 148.5868],
  [-26.3086, 148.5855],
  [-26.3078, 148.5846],
  [-26.3040, 148.5826],
  [-26.3032, 148.5815],
  [-26.3029, 148.5796],
  [-26.3024, 148.5783],
  [-26.3017, 148.5775],
  [-26.3013, 148.5767],
  [-26.3010, 148.5752],
  [-26.3002, 148.5743],
  [-26.2970, 148.5733],
  [-26.2961, 148.5726],
  [-26.2862, 148.5630],
  [-26.2837, 148.5580],
  [-26.2738, 148.5480],
  [-26.2684, 148.5416],
  [-26.2586, 148.5281],
  [-26.2560, 148.5213],
  [-26.2464, 148.5092],
  [-26.2454, 148.5088],
  [-26.2441, 148.5090],
  [-26.2414, 148.5101],
  [-26.2400, 148.5101],
  [-26.2377, 148.5095],
  [-26.2298, 148.5100],
  [-26.2284, 148.5097],
  [-26.2210, 148.5056],
  [-26.2161, 148.5021],
  [-26.2146, 148.5018],
  [-26.2132, 148.5020],
  [-26.2104, 148.5034],
  [-26.2089, 148.5037],
  [-26.2075, 148.5034],
  [-26.1951, 148.4977],
  [-26.1935, 148.4978],
  [-26.1920, 148.4986],
  [-26.1892, 148.5025],
  [-26.1878, 148.5031],
  [-26.1865, 148.5032],
  [-26.1824, 148.5023],
  [-26.1815, 148.5024],
  [-26.1792, 148.5035],
  [-26.1784, 148.5036],
  [-26.1778, 148.5034],
  [-26.1753, 148.5012],
  [-26.1735, 148.5006],
  [-26.1633, 148.5007],
  [-26.1617, 148.5011],
  [-26.1585, 148.5026],
  [-26.1513, 148.5056],
  [-26.1439, 148.5063],
  [-26.1410, 148.5073],
  [-26.1393, 148.5074],
  [-26.1375, 148.5069],
  [-26.1358, 148.5071],
  [-26.1343, 148.5079],
  [-26.1306, 148.5143],
  [-26.1234, 148.5211],
  [-26.1225, 148.5215],
  [-26.1217, 148.5213],
  [-26.1183, 148.5196],
  [-26.1165, 148.5194],
  [-26.1111, 148.5213],
  [-26.0960, 148.5298],
  [-26.0848, 148.5406],
  [-26.0838, 148.5411],
  [-26.0724, 148.5432],
  [-26.0483, 148.5523],
  [-26.0464, 148.5525],
  [-26.0354, 148.5515],
  [-26.0275, 148.5525],
  [-26.0193, 148.5569],
  [-26.0177, 148.5573],
  [-26.0020, 148.5596],
  [-25.9998, 148.5594],
  [-25.9881, 148.5562],
  [-25.9685, 148.5482],
  [-25.9653, 148.5481],
  [-25.9578, 148.5504],
  [-25.9319, 148.5525],
  [-25.9298, 148.5522],
  [-25.9178, 148.5491],
  [-25.9134, 148.5486],
  [-25.9119, 148.5492],
  [-25.9100, 148.5507],
  [-25.9088, 148.5512],
  [-25.9056, 148.5511],
  [-25.8959, 148.5492],
  [-25.8840, 148.5498],
  [-25.8824, 148.5503],
  [-25.8764, 148.5531],
  [-25.8748, 148.5534],
  [-25.8614, 148.5539],
  [-25.8598, 148.5543],
  [-25.8568, 148.5562],
  [-25.8545, 148.5590],
  [-25.8537, 148.5595],
  [-25.8516, 148.5605],
  [-25.8508, 148.5613],
  [-25.8476, 148.5660],
  [-25.8455, 148.5679],
  [-25.8420, 148.5691]
];

export const injune: Line = {
  name: 'Injune',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1920-06-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
