import { Line } from "../../../trackTypes";

const line = [
  [-38.6445, 177.9650],
  [-38.6439, 177.9638],
  [-38.6434, 177.9625],
  [-38.6432, 177.9614],
  [-38.6441, 177.9321],
  [-38.6438, 177.9301],
  [-38.6431, 177.9288],
  [-38.6396, 177.9240],
  [-38.6373, 177.9214],
  [-38.6347, 177.9203],
  [-38.6333, 177.9197],
  [-38.6325, 177.9183],
  [-38.6323, 177.9165],
  [-38.6338, 177.9128],
  [-38.6340, 177.9111],
  [-38.6338, 177.9054],
  [-38.6308, 177.8905],
  [-38.6308, 177.8893],
  [-38.6342, 177.8589],
  [-38.6340, 177.8573],
  [-38.6318, 177.8442],
  [-38.6313, 177.8404],
  [-38.6307, 177.8344],
  [-38.6303, 177.8334],
  [-38.6275, 177.8291],
  [-38.6262, 177.8284],
  [-38.6171, 177.8245],
  [-38.6159, 177.8237],
  [-38.6145, 177.8223],
  [-38.6127, 177.8218],
  [-38.6120, 177.8212],
  [-38.6067, 177.8128],
  [-38.6058, 177.8119],
  [-38.6044, 177.8109],
  [-38.6037, 177.8103],
  [-38.6033, 177.8098],
  [-38.6028, 177.8085],
  [-38.6023, 177.8079],
  [-38.5988, 177.8054],
  [-38.5949, 177.8035],
  [-38.5939, 177.8024],
  [-38.5930, 177.8003],
  [-38.5923, 177.7996],
  [-38.5825, 177.7941],
  [-38.5802, 177.7926],
  [-38.5795, 177.7924],
  [-38.5764, 177.7920],
  [-38.5757, 177.7916],
  [-38.5749, 177.7900],
  [-38.5742, 177.7866],
  [-38.5735, 177.7852],
  [-38.5727, 177.7845],
  [-38.5717, 177.7842],
  [-38.5710, 177.7835],
  [-38.5709, 177.7823],
  [-38.5712, 177.7814],
  [-38.5718, 177.7813],
  [-38.5745, 177.7820],
  [-38.5751, 177.7819],
  [-38.5755, 177.7816],
  [-38.5757, 177.7811],
  [-38.5764, 177.7803],
  [-38.5771, 177.7802],
  [-38.5778, 177.7807],
  [-38.5782, 177.7814],
  [-38.5786, 177.7831],
  [-38.5791, 177.7838],
  [-38.5806, 177.7844],
  [-38.5811, 177.7849],
  [-38.5817, 177.7852],
  [-38.5840, 177.7853],
  [-38.5847, 177.7852],
  [-38.5872, 177.7836],
  [-38.5896, 177.7799],
  [-38.5896, 177.7791],
  [-38.5884, 177.7773],
  [-38.5883, 177.7764],
  [-38.5886, 177.7758],
  [-38.5892, 177.7753],
  [-38.5912, 177.7749],
  [-38.5918, 177.7743],
  [-38.5920, 177.7733],
  [-38.5917, 177.7722],
  [-38.5909, 177.7713],
  [-38.5901, 177.7699],
  [-38.5901, 177.7689],
  [-38.5904, 177.7679],
  [-38.5909, 177.7675],
  [-38.5917, 177.7671],
  [-38.5932, 177.7675],
  [-38.5941, 177.7684],
  [-38.5946, 177.7705],
  [-38.5959, 177.7723],
  [-38.5976, 177.7730],
  [-38.5984, 177.7732],
  [-38.5993, 177.7730],
  [-38.5997, 177.7724],
  [-38.5999, 177.7711],
  [-38.5996, 177.7691],
  [-38.5997, 177.7686],
  [-38.6002, 177.7672]
];

export const ngatapa: Line = {
  name: 'Ngatapa',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1924-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-04-01',
          status: 'closed'
        }]
      }
    }
  ]
}
