import { Line } from "../../../trackTypes";

const wallsend = [//includes scott st
  [-32.92411, 151.73086],
  [-32.92323, 151.72347],
  [-32.92310, 151.72314],
  [-32.92290, 151.72282],
  [-32.92108, 151.71924],
  [-32.92098, 151.71893],
  [-32.92089, 151.71858],
  [-32.92073, 151.71632],
  [-32.92068, 151.71601],
  [-32.92057, 151.71570],
  [-32.91877, 151.71267],
  [-32.91861, 151.71246],
  [-32.91848, 151.71233],
  [-32.91829, 151.71217],
  [-32.91802, 151.71201],
  [-32.91639, 151.71130],
  [-32.91602, 151.71120],
  [-32.91563, 151.71110],
  [-32.91543, 151.71101],
  [-32.91431, 151.71023],
  [-32.91416, 151.71007],
  [-32.91403, 151.70987],
  [-32.91392, 151.70958],
  [-32.91273, 151.70150],
  [-32.91263, 151.70112],
  [-32.91246, 151.70078],
  [-32.90681, 151.69142],
  [-32.90644, 151.69105],
  [-32.90099, 151.68786],
  [-32.90009, 151.68728],
  [-32.89716, 151.68486],
  [-32.89683, 151.68440],
  [-32.89668, 151.68397],
  [-32.89662, 151.68343],
  [-32.89663, 151.68276],
  [-32.89680, 151.68114],
  [-32.89726, 151.67910],
  [-32.89804, 151.67682],
  [-32.89820, 151.67616],
  [-32.89833, 151.67521],
  [-32.89839, 151.67488],
  [-32.89849, 151.67470],
  [-32.89932, 151.67371],
  [-32.89983, 151.67335],
  [-32.90077, 151.67230],
  [-32.90094, 151.67206],
  [-32.90137, 151.67118],
  [-32.90143, 151.67080],
  [-32.90131, 151.66961],
  [-32.90132, 151.66949],
  [-32.90134, 151.66945],
  [-32.90138, 151.66942],
  [-32.90173, 151.66949],
  [-32.90187, 151.66950],
  [-32.90266, 151.66953],
  [-32.90275, 151.66949],
  [-32.90281, 151.66942],
  [-32.90325, 151.66846],
  [-32.90403, 151.66665]
];

const mayfieldJunction = [
  [-32.92362, 151.75540],
  [-32.92370, 151.75546],
  [-32.92379, 151.75546],
  [-32.92385, 151.75543],
  [-32.92393, 151.75535],
  [-32.92398, 151.75525]
];

const mayfieldJunctionWaratahJunction = [
  [-32.92411, 151.73086],
  [-32.92464, 151.73599],
  [-32.92465, 151.73623],
  [-32.92462, 151.73650],
  [-32.92450, 151.73685],
  [-32.92423, 151.73735],
  [-32.92417, 151.73748],
  [-32.92423, 151.73840],
  [-32.92425, 151.73879],
  [-32.92426, 151.74031],
  [-32.92422, 151.74063],
  [-32.92410, 151.74100],
  [-32.92349, 151.74251],
  [-32.92344, 151.74274],
  [-32.92344, 151.74304],
  [-32.92394, 151.74617],
  [-32.92437, 151.74902],
  [-32.92443, 151.74938],
  [-32.92470, 151.75116],
  [-32.92471, 151.75139],
  [-32.92468, 151.75161]
];

const depotJunctionMayfieldJunction = [
  [-32.92468, 151.75161],
  [-32.92398, 151.75525],
  [-32.92396, 151.75550],
  [-32.92395, 151.75585],
  [-32.92399, 151.75618]
];

const mayfieldJunctionScottStreet = [
  [-32.92399, 151.75618],
  [-32.92443, 151.75712],
  [-32.92453, 151.75740],
  [-32.92467, 151.75787],
  [-32.92509, 151.75912],
  [-32.92528, 151.75961],
  [-32.92619, 151.76146],
  [-32.92636, 151.76212],
  [-32.92741, 151.76715],
  [-32.92744, 151.76731],
  [-32.92743, 151.76747],
  [-32.92733, 151.76860]
];

const hunterStreet = [
  [-32.92639, 151.77734],
  [-32.92644, 151.77780],
  [-32.92648, 151.77875],
  [-32.92653, 151.77906],
  [-32.92835, 151.78644],
  [-32.92843, 151.78653],
  [-32.92856, 151.78657],
  [-32.92871, 151.78652],
  [-32.92881, 151.78659],
  [-32.92889, 151.78667],
  [-32.92892, 151.78679],
  [-32.92894, 151.78763],
  [-32.92892, 151.78790],
  [-32.92891, 151.78839],
  [-32.92896, 151.78859],
  [-32.92901, 151.78872],
  [-32.92916, 151.78899],
  [-32.92922, 151.78920],
  [-32.92922, 151.78944],
  [-32.92912, 151.78962],
  [-32.92892, 151.78974],
  [-32.92872, 151.78974],
  [-32.92856, 151.78967],
  [-32.92844, 151.78960],
  [-32.92831, 151.78948],
  [-32.92813, 151.78905],
  [-32.92763, 151.78701],
  [-32.92761, 151.78682]
];

const scottStreetRepoened = [
  [-32.92733, 151.76860],
  [-32.92705, 151.77138],
  [-32.92670, 151.77493],
  [-32.92670, 151.77494],
  [-32.92655, 151.77628],
  [-32.92650, 151.77664],
  [-32.92639, 151.77734],
  [-32.92633, 151.77764],
  [-32.92628, 151.77802],
  [-32.92626, 151.77842],
  [-32.92630, 151.78083],
  [-32.92631, 151.78114],
  [-32.92639, 151.78204],
  [-32.92644, 151.78230],
  [-32.92649, 151.78254],
  [-32.92662, 151.78293],
  [-32.92761, 151.78682]
];

const telfordSt = [
  [-32.92763, 151.78701],
  [-32.92764, 151.78695],
  [-32.92770, 151.78688],
  [-32.92826, 151.78668],
  [-32.92871, 151.78652]
];

const telfordJunctionSouth = [
  [-32.92761, 151.78682],
  [-32.92767, 151.78688],
  [-32.92770, 151.78688]
];

const telfordJunctionNorth = [
  [-32.92826, 151.78668],
  [-32.92832, 151.78658],
  [-32.92835, 151.78644]
];

const mayfield = [//elizabeth st
  [-32.92399, 151.75618],
  [-32.92362, 151.75540],
  [-32.92334, 151.75482],
  [-32.92325, 151.75466],
  [-32.92309, 151.75447],
  [-32.92225, 151.75362],
  [-32.92205, 151.75338],
  [-32.92193, 151.75316],
  [-32.92164, 151.75253],
  [-32.92149, 151.75236],
  [-32.92135, 151.75225],
  [-32.92122, 151.75219],
  [-32.92088, 151.75221],
  [-32.92075, 151.75216],
  [-32.92058, 151.75210],
  [-32.91850, 151.75048],
  [-32.91214, 151.74635],
  [-32.91176, 151.74613],
  [-32.91149, 151.74605],
  [-32.91102, 151.74592],
  [-32.91083, 151.74590],
  [-32.90979, 151.74602],
  [-32.90963, 151.74607],
  [-32.90949, 151.74614],
  [-32.90935, 151.74623],
  [-32.90925, 151.74631],
  [-32.90835, 151.74765],
  [-32.90822, 151.74779],
  [-32.90803, 151.74793],
  [-32.90787, 151.74800],
  [-32.90751, 151.74810]
];

const mayfieldExtension = [//hanbury st
  [-32.90751, 151.74810],
  [-32.90737, 151.74811],
  [-32.90724, 151.74809],
  [-32.90709, 151.74801],
  [-32.90384, 151.74524],
  [-32.90362, 151.74504],
  [-32.90346, 151.74484],
  [-32.89970, 151.73976],
  [-32.89744, 151.73663]
];

const waratah = [
  [-32.92411, 151.73086],
  [-32.92405, 151.73075],
  [-32.92400, 151.73068],
  [-32.92390, 151.73065],
  [-32.92361, 151.73070],
  [-32.92347, 151.73075],
  [-32.92334, 151.73084],
  [-32.92091, 151.73287],
  [-32.92083, 151.73295],
  [-32.92074, 151.73309],
  [-32.92067, 151.73320],
  [-32.92030, 151.73403],
  [-32.92021, 151.73412],
  [-32.92012, 151.73417],
  [-32.92003, 151.73419],
  [-32.91992, 151.73417],
  [-32.91680, 151.73229],
  [-32.91667, 151.73223],
  [-32.91649, 151.73218],
  [-32.91635, 151.73220],
  [-32.91231, 151.73311],
  [-32.91215, 151.73309],
  [-32.91200, 151.73306],
  [-32.91188, 151.73298],
  [-32.91165, 151.73275],
  [-32.91125, 151.73227],
  [-32.91117, 151.73219],
  [-32.91106, 151.73213],
  [-32.91068, 151.73198],
  [-32.90986, 151.73151],
  [-32.90971, 151.73141],
  [-32.90963, 151.73131],
  [-32.90863, 151.73005],
  [-32.90851, 151.72986],
  [-32.90779, 151.72858],
  [-32.90765, 151.72839],
  [-32.90701, 151.72770],
  [-32.90687, 151.72763],
  [-32.90674, 151.72761],
  [-32.90532, 151.72810],
  [-32.90523, 151.72812],
  [-32.90515, 151.72810],
  [-32.90508, 151.72804],
  [-32.90502, 151.72794],
  [-32.90405, 151.72180],
  [-32.90400, 151.72170],
  [-32.90393, 151.72166],
  [-32.90384, 151.72164],
  [-32.89891, 151.72271]
];

const glebe = [
  [-32.92743, 151.76747],
  [-32.92747, 151.76735],
  [-32.92751, 151.76731],
  [-32.92757, 151.76727],
  [-32.92806, 151.76724],
  [-32.92821, 151.76721],
  [-32.92965, 151.76669],
  [-32.92981, 151.76663],
  [-32.92997, 151.76651],
  [-32.93858, 151.75867],
  [-32.93865, 151.75858],
  [-32.93869, 151.75848],
  [-32.93870, 151.75837],
  [-32.93851, 151.75027],
  [-32.93852, 151.74960],
  [-32.93873, 151.74599],
  [-32.93876, 151.74575],
  [-32.93881, 151.74554],
  [-32.93913, 151.74464],
  [-32.93925, 151.74433],
  [-32.93938, 151.74404],
  [-32.93949, 151.74383],
  [-32.94004, 151.74299]
];

const merewether = [//patrick st
  [-32.92670, 151.77493],
  [-32.92678, 151.77482],
  [-32.92683, 151.77477],
  [-32.92975, 151.77324],
  [-32.92985, 151.77317],
  [-32.92998, 151.77305],
  [-32.93778, 151.76591],
  [-32.93976, 151.76409]
];

const merewetherExtension = [//ridge st
  [-32.93976, 151.76409],
  [-32.94008, 151.76386],
  [-32.94018, 151.76374],
  [-32.94022, 151.76359],
  [-32.94141, 151.75756],
  [-32.94146, 151.75744],
  [-32.94153, 151.75734],
  [-32.94621, 151.75191],
  [-32.94627, 151.75187],
  [-32.94634, 151.75186],
  [-32.94640, 151.75189],
  [-32.94899, 151.75505]
];

const adamstown = [
  [-32.92417, 151.73748],
  [-32.92423, 151.73738],
  [-32.92438, 151.73731],
  [-32.92468, 151.73727],
  [-32.92479, 151.73721],
  [-32.92494, 151.73708],
  [-32.92831, 151.73236],
  [-32.93087, 151.72837],
  [-32.93098, 151.72818],
  [-32.93146, 151.72727],
  [-32.93154, 151.72716],
  [-32.93161, 151.72707],
  [-32.93171, 151.72694],
  [-32.93234, 151.72636],
  [-32.93245, 151.72628],
  [-32.93256, 151.72624],
  [-32.93413, 151.72591]
];

const adamstownExtension = [
  [-32.93413, 151.72591],
  [-32.93518, 151.72570]
];

const racecourse = [
  [-32.92468, 151.73727],
  [-32.92683, 151.73685],
  [-32.93212, 151.73585],
  [-32.93313, 151.73566],
  [-32.93322, 151.73570],
  [-32.93328, 151.73578],
  [-32.93332, 151.73585],
  [-32.93385, 151.73990],
  [-32.93381, 151.74008],
  [-32.93376, 151.74020],
  [-32.93370, 151.74032],
  [-32.93337, 151.74037],
  [-32.93320, 151.74031],
  [-32.93304, 151.74022],
  [-32.93291, 151.74006],
  [-32.93236, 151.73609],
  [-32.93230, 151.73596],
  [-32.93222, 151.73590],
  [-32.93212, 151.73585]
];

const carrington = [
  [-32.92636, 151.76212],
  [-32.92631, 151.76202],
  [-32.92621, 151.76193],
  [-32.92534, 151.76154],
  [-32.92520, 151.76150],
  [-32.92494, 151.76153],
  [-32.92483, 151.76153],
  [-32.92114, 151.76001],
  [-32.91874, 151.75902],
  [-32.91816, 151.75879],
  [-32.91791, 151.75885],
  [-32.91778, 151.75900],
  [-32.91767, 151.75920],
  [-32.91624, 151.76553],
  [-32.91616, 151.76564],
  [-32.91607, 151.76571],
  [-32.91597, 151.76574],
  [-32.91367, 151.76511],
  [-32.91198, 151.76480],
  [-32.91190, 151.76484],
  [-32.91184, 151.76491],
  [-32.91180, 151.76502],
  [-32.91147, 151.76748],
  [-32.91142, 151.76758],
  [-32.91136, 151.76764],
  [-32.91126, 151.76766],
  [-32.90959, 151.76735],
  [-32.90748, 151.76694]
];

const carringtonLoop = [
  [-32.90748, 151.76694],
  [-32.90741, 151.76699],
  [-32.90736, 151.76704],
  [-32.90730, 151.76715],
  [-32.90714, 151.76818],
  [-32.90717, 151.76831],
  [-32.90725, 151.76841],
  [-32.90900, 151.76872],
  [-32.90913, 151.76865],
  [-32.90923, 151.76853],
  [-32.90935, 151.76754],
  [-32.90942, 151.76744],
  [-32.90959, 151.76735]
];

const portWaratah = [//estell st
  [-32.91816, 151.75879],
  [-32.91668, 151.75824],
  [-32.91620, 151.75814],
  [-32.91523, 151.75808],
  [-32.91493, 151.75810],
  [-32.91466, 151.75811],
  [-32.91284, 151.75851],
  [-32.91272, 151.75845],
  [-32.91266, 151.75839],
  [-32.91256, 151.75825],
  [-32.91173, 151.75299]
];

const portWaratahExtension = [//end
  [-32.91173, 151.75299],
  [-32.91158, 151.75216],
  [-32.91152, 151.75209],
  [-32.91145, 151.75204],
  [-32.91136, 151.75204],
  [-32.90966, 151.75244],
  [-32.90948, 151.75246],
  [-32.90857, 151.75248],
  [-32.90835, 151.75252],
  [-32.90462, 151.75338],
  [-32.90450, 151.75334],
  [-32.90441, 151.75325],
  [-32.90434, 151.75310],
  [-32.90439, 151.75247],
  [-32.90443, 151.75230],
  [-32.90446, 151.75220],
  [-32.90444, 151.75212],
  [-32.90439, 151.75202],
  [-32.90368, 151.75170],
  [-32.90352, 151.75177],
  [-32.90308, 151.75301],
  [-32.90287, 151.75340],
  [-32.90070, 151.75726],
  [-32.89926, 151.75880]
];

const waratahWharf = [
  [-32.90352, 151.75177],
  [-32.90016, 151.75438],
  [-32.89996, 151.75463],
  [-32.89962, 151.75521],
  [-32.89945, 151.75578],
  [-32.89859, 151.75946],
  [-32.89856, 151.76044],
  [-32.89882, 151.76792]
];

const speersPoint = [
  [-32.92503, 151.63742],
  [-32.92576, 151.63716],
  [-32.92619, 151.63704],
  [-32.93001, 151.63627],
  [-32.93053, 151.63609],
  [-32.93077, 151.63594],
  [-32.93108, 151.63569],
  [-32.93434, 151.63186],
  [-32.93452, 151.63161],
  [-32.93460, 151.63149],
  [-32.93586, 151.62841],
  [-32.93609, 151.62798],
  [-32.93634, 151.62761],
  [-32.93691, 151.62702],
  [-32.93803, 151.62607],
  [-32.93859, 151.62567],
  [-32.93965, 151.62507],
  [-32.94002, 151.62492],
  [-32.94040, 151.62483],
  [-32.94738, 151.62338],
  [-32.94758, 151.62334],
  [-32.94781, 151.62332],
  [-32.95168, 151.62348],
  [-32.95191, 151.62346],
  [-32.95666, 151.62190],
  [-32.95678, 151.62189],
  [-32.95691, 151.62190],
  [-32.96016, 151.62306],
  [-32.96031, 151.62309],
  [-32.96045, 151.62311],
  [-32.96486, 151.62262],
  [-32.96494, 151.62258],
  [-32.96496, 151.62252],
  [-32.96497, 151.62245],
  [-32.96487, 151.62221]
];

const wallsendWallsendJunction = [
  [-32.90403, 151.66665],
  [-32.90416, 151.66646],
  [-32.90465, 151.66597],
  [-32.90517, 151.66564],
  [-32.90544, 151.66537],
  [-32.90561, 151.66515],
  [-32.90710, 151.66288],
  [-32.90741, 151.66261],
  [-32.90817, 151.66213],
  [-32.91056, 151.66036],
  [-32.91097, 151.65994],
  [-32.91120, 151.65964],
  [-32.91158, 151.65897],
  [-32.91181, 151.65870],
  [-32.91195, 151.65856],
  [-32.91389, 151.65714],
  [-32.91438, 151.65689],
  [-32.91480, 151.65677],
  [-32.91532, 151.65676],
  [-32.91567, 151.65660],
  [-32.91592, 151.65636],
  [-32.91603, 151.65610],
  [-32.91611, 151.65573],
  [-32.91618, 151.65479],
  [-32.91627, 151.65439],
  [-32.91639, 151.65418],
  [-32.91665, 151.65388],
  [-32.91708, 151.65352],
  [-32.91725, 151.65319],
  [-32.91730, 151.65284],
  [-32.91728, 151.65252],
  [-32.91713, 151.65174],
  [-32.91715, 151.65141],
  [-32.91727, 151.65108],
  [-32.91755, 151.65072],
  [-32.91799, 151.65056],
  [-32.91865, 151.65054],
  [-32.91900, 151.65041],
  [-32.91930, 151.64996],
  [-32.91934, 151.64947],
  [-32.91928, 151.64916],
  [-32.91900, 151.64831],
  [-32.91896, 151.64793],
  [-32.91898, 151.64764],
  [-32.91971, 151.64527],
  [-32.91971, 151.64478],
  [-32.91956, 151.64416],
  [-32.91953, 151.64376],
  [-32.91963, 151.64330],
  [-32.91984, 151.64297],
  [-32.92129, 151.64208],
  [-32.92179, 151.64199],
  [-32.92257, 151.64196],
  [-32.92288, 151.64184],
  [-32.92312, 151.64156],
  [-32.92327, 151.64108],
  [-32.92345, 151.63926],
  [-32.92363, 151.63867],
  [-32.92395, 151.63817],
  [-32.92426, 151.63792],
  [-32.92503, 151.63742]
];

const westWallsend = [
  [-32.92503, 151.63742],
  [-32.92538, 151.63703],
  [-32.92572, 151.63631],
  [-32.92590, 151.63567],
  [-32.92594, 151.63518],
  [-32.92592, 151.63436],
  [-32.92590, 151.63115],
  [-32.92588, 151.63065],
  [-32.92581, 151.63030],
  [-32.92568, 151.62978],
  [-32.92512, 151.62759],
  [-32.92400, 151.62456],
  [-32.92387, 151.62412],
  [-32.92358, 151.62290],
  [-32.92355, 151.62196],
  [-32.92361, 151.62150],
  [-32.92393, 151.62047],
  [-32.92410, 151.61895],
  [-32.92412, 151.61859],
  [-32.92408, 151.61788],
  [-32.92341, 151.61294],
  [-32.92325, 151.61241],
  [-32.92294, 151.61157],
  [-32.92201, 151.60966],
  [-32.92183, 151.60909],
  [-32.92167, 151.60850],
  [-32.92157, 151.60825],
  [-32.92146, 151.60798],
  [-32.92126, 151.60780],
  [-32.91976, 151.60685],
  [-32.91951, 151.60661],
  [-32.91938, 151.60640],
  [-32.91927, 151.60619],
  [-32.91908, 151.60491],
  [-32.91897, 151.60374],
  [-32.91905, 151.60279],
  [-32.91928, 151.60217],
  [-32.92008, 151.60067],
  [-32.92015, 151.60036],
  [-32.92022, 151.59987],
  [-32.92010, 151.59945],
  [-32.91986, 151.59917],
  [-32.91953, 151.59903],
  [-32.91813, 151.59812],
  [-32.91795, 151.59795],
  [-32.91772, 151.59769],
  [-32.91726, 151.59697],
  [-32.91658, 151.59620],
  [-32.91632, 151.59604],
  [-32.91569, 151.59578],
  [-32.91533, 151.59575],
  [-32.91474, 151.59571],
  [-32.91434, 151.59566],
  [-32.91404, 151.59552],
  [-32.91386, 151.59523],
  [-32.91384, 151.59489],
  [-32.91387, 151.59452],
  [-32.91396, 151.59418],
  [-32.91452, 151.59273],
  [-32.91487, 151.59141],
  [-32.91490, 151.59108],
  [-32.91479, 151.59056],
  [-32.91475, 151.59022],
  [-32.91479, 151.58986],
  [-32.91511, 151.58848],
  [-32.91506, 151.58797],
  [-32.91493, 151.58760],
  [-32.91467, 151.58677],
  [-32.91462, 151.58666],
  [-32.91463, 151.58620],
  [-32.91481, 151.58554],
  [-32.91475, 151.58515],
  [-32.91463, 151.58489],
  [-32.91388, 151.58383],
  [-32.91144, 151.58140],
  [-32.91123, 151.58127],
  [-32.91110, 151.58125],
  [-32.91091, 151.58130],
  [-32.90951, 151.58168],
  [-32.90926, 151.58170],
  [-32.90908, 151.58164],
  [-32.90891, 151.58153],
  [-32.90764, 151.58029],
  [-32.90730, 151.57991],
  [-32.90597, 151.57778],
  [-32.90545, 151.57719],
  [-32.90519, 151.57689],
  [-32.90496, 151.57662],
  [-32.90451, 151.57646],
  [-32.90425, 151.57635],
  [-32.90396, 151.57640],
  [-32.90360, 151.57645],
  [-32.90252, 151.57769],
  [-32.90243, 151.57785],
  [-32.90235, 151.57798],
  [-32.90222, 151.57860]
];

const lightRail = [
  [-32.92404, 151.75876],
  [-32.92503, 151.76107],
  [-32.92522, 151.76177],
  [-32.92532, 151.76210],
  [-32.92569, 151.76303],
  [-32.92580, 151.76337],
  [-32.92611, 151.76433],
  [-32.92639, 151.76544],
  [-32.92661, 151.76644],
  [-32.92667, 151.76694],
  [-32.92671, 151.76744],
  [-32.92675, 151.76762],
  [-32.92681, 151.76774],
  [-32.92689, 151.76784],
  [-32.92713, 151.76808],
  [-32.92726, 151.76826],
  [-32.92730, 151.76840],
  [-32.92733, 151.76860]
];

const hamiltonJunctionEast = [
  [-32.92470, 151.75116],
  [-32.92477, 151.75127],
  [-32.92484, 151.75133],
  [-32.92491, 151.75137]
];

const hamiltonJunctionWest = [
  [-32.92455, 151.74918],
  [-32.92448, 151.74925],
  [-32.92443, 151.74938]
];

const hamiltonDepot = [
  [-32.92468, 151.75161],
  [-32.92479, 151.75145],
  [-32.92491, 151.75137],
  [-32.92565, 151.75120],
  [-32.92574, 151.75110],
  [-32.92579, 151.75100],
  [-32.92582, 151.75080],
  [-32.92556, 151.74912],
  [-32.92551, 151.74904],
  [-32.92544, 151.74902],
  [-32.92455, 151.74918],
  [-32.92445, 151.74914],
  [-32.92437, 151.74902]
];

const albertStreet = [
  [-32.91850, 151.75048],
  [-32.91856, 151.75061],
  [-32.91857, 151.75075],
  [-32.91789, 151.75328],
  [-32.91788, 151.75343],
  [-32.91788, 151.75357],
  [-32.91862, 151.75882],
  [-32.91865, 151.75892],
  [-32.91874, 151.75902]
];

export const newcastleTrams: Line = {
  name: 'Newcastle Trams',
  state: 'NSW',
  segments: [
    {
      segments: [wallsend],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1925-12-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1949-11-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mayfieldJunctionWaratahJunction],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1925-12-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-01-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [depotJunctionMayfieldJunction],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-01-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hunterStreet, telfordSt, telfordJunctionNorth, telfordJunctionSouth],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mayfieldJunctionScottStreet],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [scottStreetRepoened],
      history: {
        opened: {
          date: '1887-07-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-06-22',
          status: 'closed'
        }, {
          date: '2019-02-17',
          status: 'open',
          electrified: undefined //battery operated
        }]
      }
    },
    {
      segments: [mayfield, mayfieldJunction],
      history: {
        opened: {
          date: '1893-04-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1948-09-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mayfieldExtension],
      history: {
        opened: {
          date: '1901-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1948-09-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waratah],
      history: {
        opened: {
          date: '1915-01-20',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1925-04-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-01-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glebe],
      history: {
        opened: {
          date: '1894-04-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1924-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [merewether],
      history: {
        opened: {
          date: '1902-03-29',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1924-07-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [merewetherExtension],
      history: {
        opened: {
          date: '1903-09-21',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1924-07-27',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adamstown],
      history: {
        opened: {
          date: '1900-08-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1925-02-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-07-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adamstownExtension],
      history: {
        opened: {
          date: '1938-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1925-02-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-07-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [racecourse],
      history: {
        opened: {
          date: '1907-04-27',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1925-11-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-04-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [carrington],
      history: {
        opened: {
          date: '1912-09-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1926-08-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1938-11-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [carringtonLoop],
      history: {
        opened: {
          date: '1912-09-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1926-08-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portWaratah],
      history: {
        opened: {
          date: '1914-07-20',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1926-10-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1938-11-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portWaratahExtension],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1926-10-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1938-11-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waratahWharf],
      history: {
        opened: {
          date: '1920-02-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1921-01-01', //closed in 1920
          status: 'closed'
        }]
      }
    },
    {
      segments: [wallsendWallsendJunction],
      history: {
        opened: {
          date: '1910-09-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1931-05-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [speersPoint],
      history: {
        opened: {
          date: '1912-01-15',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1931-05-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westWallsend],
      history: {
        opened: {
          date: '1910-09-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1930-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lightRail],
      history: {
        opened: {
          date: '2019-02-17',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //battery
        }
      }
    },
    {
      segments: [hamiltonDepot],
      history: {
        opened: {
          date: '1920-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //battery
        },
        trackChange: [{
          date: '1923-12-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hamiltonJunctionEast, hamiltonJunctionWest],
      history: {
        opened: {
          date: '1920-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //battery
        },
        trackChange: [{
          date: '1925-12-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [albertStreet],
      history: {
        opened: {
          date: '1912-09-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //battery
        },
        trackChange: [{
          date: '1938-11-19',
          status: 'closed'
        }]
      }
    }
  ]
};
