import { Line } from "../../trackTypes";

const line = [
  [-12.5344, 141.8301],
  [-12.5358, 141.8465],
  [-12.5368, 141.8502],
  [-12.5386, 141.8530],
  [-12.5577, 141.8710],
  [-12.5668, 141.8847],
  [-12.5688, 141.8907],
  [-12.5712, 141.8942],
  [-12.5758, 141.8969],
  [-12.5784, 141.8974],
  [-12.5832, 141.8965],
  [-12.5872, 141.8948],
  [-12.5902, 141.8942],
  [-12.6059, 141.8941],
  [-12.6135, 141.8921],
  [-12.6553, 141.8887],
  [-12.6567, 141.8881],
  [-12.6581, 141.8867],
  [-12.6588, 141.8844],
  [-12.6570, 141.8631]
];

export const weipa: Line = {
  name: 'Weiap',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1967-03-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
}
