import { Line } from "../../../trackTypes";

const evertonBeechworth = [
  [-36.4077, 146.5471],
  [-36.4158, 146.5615],
  [-36.4165, 146.5636],
  [-36.4203, 146.5810],
  [-36.4204, 146.5834],
  [-36.4199, 146.5854],
  [-36.4135, 146.5945],
  [-36.4125, 146.5971],
  [-36.4124, 146.5993],
  [-36.4127, 146.6036],
  [-36.4117, 146.6078],
  [-36.4116, 146.6112],
  [-36.4107, 146.6135],
  [-36.4094, 146.6152],
  [-36.4073, 146.6181],
  [-36.4061, 146.6208],
  [-36.4054, 146.6235],
  [-36.4032, 146.6359],
  [-36.4032, 146.6382],
  [-36.4028, 146.6412],
  [-36.3959, 146.6547],
  [-36.3925, 146.6651],
  [-36.3917, 146.6668],
  [-36.3893, 146.6705],
  [-36.3885, 146.6723],
  [-36.3882, 146.6739],
  [-36.3877, 146.6761],
  [-36.3871, 146.6780],
  [-36.3862, 146.6793],
  [-36.3845, 146.6810],
  [-36.3830, 146.6816],
  [-36.3814, 146.6817],
  [-36.3783, 146.6813],
  [-36.3758, 146.6815],
  [-36.3737, 146.6824],
  [-36.3660, 146.6868],
  [-36.3627, 146.6892]
];

const beechworthYackandandah = [
  [-36.3627, 146.6892],
  [-36.3594, 146.6922],
  [-36.3579, 146.6940],
  [-36.3551, 146.6977],
  [-36.3532, 146.7000],
  [-36.3457, 146.7096],
  [-36.3451, 146.7109],
  [-36.3448, 146.7127],
  [-36.3447, 146.7144],
  [-36.3440, 146.7160],
  [-36.3438, 146.7174],
  [-36.3436, 146.7194],
  [-36.3429, 146.7205],
  [-36.3403, 146.7236],
  [-36.3362, 146.7256],
  [-36.3331, 146.7260],
  [-36.3316, 146.7268],
  [-36.3305, 146.7284],
  [-36.3301, 146.7309],
  [-36.3294, 146.7323],
  [-36.3283, 146.7338],
  [-36.3279, 146.7354],
  [-36.3280, 146.7370],
  [-36.3288, 146.7395],
  [-36.3284, 146.7414],
  [-36.3276, 146.7425],
  [-36.3262, 146.7428],
  [-36.3244, 146.7429],
  [-36.3216, 146.7438],
  [-36.3192, 146.7453],
  [-36.2941, 146.7690],
  [-36.2844, 146.7802],
  [-36.2833, 146.7824],
  [-36.2817, 146.7875],
  [-36.2767, 146.8030],
  [-36.2762, 146.8052],
  [-36.2768, 146.8072],
  [-36.2778, 146.8083],
  [-36.2800, 146.8090],
  [-36.2826, 146.8109],
  [-36.2903, 146.8180],
  [-36.2959, 146.8218],
  [-36.2983, 146.8247],
  [-36.2999, 146.8258],
  [-36.3037, 146.8272],
  [-36.3052, 146.8282],
  [-36.3085, 146.8321],
  [-36.3090, 146.8334],
  [-36.3090, 146.8347],
  [-36.3091, 146.8375],
  [-36.3088, 146.8401],
  [-36.3086, 146.8438]
];

export const yackandandah: Line = {
  name: 'Yackandandah',
  state: 'VIC',
  segments: [
    {
      segments: [evertonBeechworth],
      history: {
        opened: {
          date: '1876-09-30',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-12-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [beechworthYackandandah],
      history: {
        opened: {
          date: '1891-07-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-07-02',
          status: 'closed'
        }]
      }
    }
  ]
}
