import { Line } from "../../trackTypes";

const line = [
  [-17.30975, 123.64113],
  [-17.29137, 123.60981],
  [-17.29055, 123.60813],
  [-17.29051, 123.60784],
  [-17.29053, 123.60770],
  [-17.29059, 123.60748],
  [-17.29069, 123.60729],
  [-17.29086, 123.60711],
  [-17.29106, 123.60697],
  [-17.29128, 123.60687],
  [-17.29247, 123.60653]
];

export const derbyTram: Line = {
  name: 'Derby Tram',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram' //horse
        },
        trackChange: [{
          date: '1959-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
