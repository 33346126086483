import { Line } from "../../trackTypes";

const wallerawangJunctionCaperteeTunnel = [
  [-33.4114, 150.0628],
  [-33.4111, 150.0611],
  [-33.4052, 150.0491],
  [-33.3992, 150.0423],
  [-33.3889, 150.0262],
  [-33.3767, 150.0117],
  [-33.3729, 150.0045],
  [-33.3701, 149.9947],
  [-33.3691, 149.9930],
  [-33.3677, 149.9921],
  [-33.3641, 149.9914],
  [-33.3627, 149.9915],
  [-33.3612, 149.9922],
  [-33.3592, 149.9935],
  [-33.3576, 149.9940],
  [-33.3504, 149.9945],
  [-33.3490, 149.9948],
  [-33.3470, 149.9957],
  [-33.3457, 149.9956],
  [-33.3441, 149.9952],
  [-33.3433, 149.9952],
  [-33.3423, 149.9955],
  [-33.3364, 149.9988],
  [-33.3345, 149.9991],
  [-33.3335, 149.9997],
  [-33.3313, 150.0017],
  [-33.3287, 150.0032],
  [-33.3265, 150.0038],
  [-33.3198, 150.0046],
  [-33.3174, 150.0054],
  [-33.3149, 150.0069],
  [-33.3104, 150.0102],
  [-33.3087, 150.0105],
  [-33.3073, 150.0101],
  [-33.3064, 150.0095],
  [-33.3052, 150.0082],
  [-33.3037, 150.0075],
  [-33.3024, 150.0073],
  [-33.3012, 150.0075],
  [-33.2868, 150.0135],
  [-33.2857, 150.0137],
  [-33.2837, 150.0139],
  [-33.2824, 150.0143],
  [-33.2787, 150.0163],
  [-33.2773, 150.0165],
  [-33.2761, 150.0162],
  [-33.2749, 150.0156],
  [-33.2736, 150.0154],
  [-33.2725, 150.0155],
  [-33.2696, 150.0161],
  [-33.2681, 150.0161],
  [-33.2661, 150.0157],
  [-33.2645, 150.0159],
  [-33.2634, 150.0167],
  [-33.2625, 150.0178],
  [-33.2611, 150.0187],
  [-33.2595, 150.0189],
  [-33.2582, 150.0183],
  [-33.2573, 150.0174],
  [-33.2567, 150.0167],
  [-33.2558, 150.0159],
  [-33.2546, 150.0157],
  [-33.2537, 150.0159],
  [-33.2530, 150.0164],
  [-33.2517, 150.0170],
  [-33.2504, 150.0169],
  [-33.2493, 150.0164],
  [-33.2483, 150.0163],
  [-33.2476, 150.0165],
  [-33.2447, 150.0177],
  [-33.2436, 150.0178],
  [-33.2428, 150.0177],
  [-33.2371, 150.0160],
  [-33.2356, 150.0161],
  [-33.2342, 150.0168],
  [-33.2333, 150.0180],
  [-33.2329, 150.0194],
  [-33.2324, 150.0219],
  [-33.2319, 150.0232],
  [-33.2311, 150.0240],
  [-33.2291, 150.0254],
  [-33.2269, 150.0258],
  [-33.2252, 150.0255],
  [-33.2138, 150.0195],
  [-33.2120, 150.0191],
  [-33.1987, 150.0189],
  [-33.1964, 150.0184],
  [-33.1937, 150.0170],
  [-33.1913, 150.0146],
  [-33.1873, 150.0084],
  [-33.1855, 150.0068],
  [-33.1838, 150.0062],
  [-33.1816, 150.0061],
  [-33.1799, 150.0054],
  [-33.1788, 150.0043],
  [-33.1777, 150.0029],
  [-33.1764, 150.0021],
  [-33.1750, 150.0019],
  [-33.1721, 150.0026],
  [-33.1707, 150.0022],
  [-33.1695, 150.0013],
  [-33.1680, 149.9995],
  [-33.1669, 149.9990],
  [-33.1659, 149.9986]
];

const caperteeTunnel = [
  [-33.1659, 149.9986],
  [-33.1654, 149.9983],
  [-33.1651, 149.9979],
  [-33.1649, 149.9973]
];

const caperteeTunnelCapertee = [
  [-33.1649, 149.9973],
  [-33.1647, 149.9963],
  [-33.1643, 149.9953],
  [-33.1638, 149.9948],
  [-33.1629, 149.9944],
  [-33.1619, 149.9945],
  [-33.1591, 149.9955],
  [-33.1572, 149.9954],
  [-33.1558, 149.9948],
  [-33.1514, 149.9923],
  [-33.1503, 149.9912],
  [-33.1493, 149.9894],
  [-33.1487, 149.9872]
];

const capereeCarlosGapTunnel = [
  [-33.1487, 149.9872],
  [-33.1471, 149.9836],
  [-33.1460, 149.9830],
  [-33.1446, 149.9830],
  [-33.1436, 149.9835],
  [-33.1430, 149.9843],
  [-33.1421, 149.9855],
  [-33.1412, 149.9862],
  [-33.1403, 149.9864],
  [-33.1367, 149.9863],
  [-33.1350, 149.9866],
  [-33.1329, 149.9874],
  [-33.1318, 149.9871],
  [-33.1311, 149.9865],
  [-33.1303, 149.9852],
  [-33.1294, 149.9845],
  [-33.1285, 149.9844],
  [-33.1272, 149.9847],
  [-33.1262, 149.9845],
  [-33.1220, 149.9827],
  [-33.1207, 149.9826],
  [-33.1150, 149.9842],
  [-33.1142, 149.9846],
  [-33.1135, 149.9854],
  [-33.1110, 149.9898],
  [-33.1094, 149.9909],
  [-33.1077, 149.9909],
  [-33.1067, 149.9901],
  [-33.1054, 149.9878],
  [-33.1041, 149.9865],
  [-33.1018, 149.9856],
  [-33.1006, 149.9844],
  [-33.0999, 149.9831],
  [-33.0988, 149.9821],
  [-33.0975, 149.9818],
  [-33.0960, 149.9821],
  [-33.0946, 149.9818],
  [-33.0936, 149.9811],
  [-33.0927, 149.9799],
  [-33.0918, 149.9793],
  [-33.0905, 149.9793],
  [-33.0889, 149.9802],
  [-33.0873, 149.9804],
  [-33.0859, 149.9798],
  [-33.0834, 149.9776],
  [-33.0813, 149.9766],
  [-33.0796, 149.9769],
  [-33.0758, 149.9793],
  [-33.0747, 149.9795],
  [-33.0737, 149.9793],
  [-33.0728, 149.9791],
  [-33.0718, 149.9792],
  [-33.0705, 149.9797],
  [-33.0690, 149.9794],
  [-33.0681, 149.9784],
  [-33.0660, 149.9725],
  [-33.0649, 149.9713],
  [-33.0633, 149.9708],
  [-33.0615, 149.9711],
  [-33.0602, 149.9709],
  [-33.0525, 149.9659],
  [-33.0508, 149.9644],
  [-33.0480, 149.9614],
  [-33.0468, 149.9610],
  [-33.0456, 149.9614],
  [-33.0449, 149.9626],
  [-33.0447, 149.9639],
  [-33.0451, 149.9653],
  [-33.0452, 149.9664],
  [-33.0449, 149.9678],
  [-33.0449, 149.9691],
  [-33.0453, 149.9701],
  [-33.0464, 149.9715],
  [-33.0467, 149.9727],
  [-33.0464, 149.9742],
  [-33.0455, 149.9751],
  [-33.0426, 149.9765],
  [-33.0414, 149.9768],
  [-33.0404, 149.9773],
  [-33.0399, 149.9780]
];

const carlosGapTunnel = [
  [-33.0399, 149.9780],
  [-33.0382, 149.9803],
  [-33.0376, 149.9809]
];

const carlosGapTunnelKandos = [
  [-33.0376, 149.9809],
  [-33.0371, 149.9812],
  [-33.0363, 149.9815],
  [-33.0350, 149.9830],
  [-33.0336, 149.9842],
  [-33.0326, 149.9846],
  [-33.0312, 149.9843],
  [-33.0302, 149.9836],
  [-33.0291, 149.9813],
  [-33.0289, 149.9802],
  [-33.0278, 149.9784],
  [-33.0244, 149.9756],
  [-33.0237, 149.9748],
  [-33.0233, 149.9739],
  [-33.0228, 149.9717],
  [-33.0222, 149.9703],
  [-33.0207, 149.9656],
  [-33.0204, 149.9633],
  [-33.0205, 149.9621],
  [-33.0201, 149.9603],
  [-33.0184, 149.9580],
  [-33.0169, 149.9574],
  [-33.0137, 149.9568],
  [-33.0122, 149.9561],
  [-33.0106, 149.9546],
  [-33.0096, 149.9534],
  [-33.0085, 149.9527],
  [-33.0062, 149.9517],
  [-33.0050, 149.9508],
  [-33.0029, 149.9487],
  [-33.0021, 149.9475],
  [-33.0017, 149.9463],
  [-33.0009, 149.9455],
  [-32.9980, 149.9445],
  [-32.9951, 149.9446],
  [-32.9939, 149.9441],
  [-32.9929, 149.9433],
  [-32.9913, 149.9410],
  [-32.9909, 149.9399],
  [-32.9909, 149.9385],
  [-32.9913, 149.9371],
  [-32.9911, 149.9357],
  [-32.9904, 149.9346],
  [-32.9893, 149.9342],
  [-32.9881, 149.9345],
  [-32.9869, 149.9359],
  [-32.9860, 149.9365],
  [-32.9831, 149.9373],
  [-32.9823, 149.9373],
  [-32.9817, 149.9369],
  [-32.9797, 149.9348],
  [-32.9786, 149.9343],
  [-32.9776, 149.9345],
  [-32.9755, 149.9359],
  [-32.9716, 149.9374],
  [-32.9705, 149.9382],
  [-32.9697, 149.9392],
  [-32.9686, 149.9397],
  [-32.9677, 149.9396],
  [-32.9667, 149.9392],
  [-32.9656, 149.9393],
  [-32.9648, 149.9400],
  [-32.9640, 149.9413],
  [-32.9631, 149.9423],
  [-32.9618, 149.9429],
  [-32.9607, 149.9443],
  [-32.9603, 149.9457],
  [-32.9603, 149.9480],
  [-32.9595, 149.9499],
  [-32.9580, 149.9507],
  [-32.9461, 149.9524],
  [-32.9442, 149.9532],
  [-32.9428, 149.9541],
  [-32.9397, 149.9547],
  [-32.9294, 149.9535],
  [-32.9259, 149.9520],
  [-32.9242, 149.9504],
  [-32.9206, 149.9464],
  [-32.9185, 149.9453],
  [-32.9167, 149.9452],
  [-32.9026, 149.9479],
  [-32.9008, 149.9494],
  [-32.9004, 149.9509],
  [-32.9004, 149.9528],
  [-32.8996, 149.9549],
  [-32.8983, 149.9558],
  [-32.8947, 149.9562],
  [-32.8931, 149.9575],
  [-32.8924, 149.9596],
  [-32.8930, 149.9616],
  [-32.8946, 149.9641],
  [-32.8948, 149.9659],
  [-32.8940, 149.9678],
  [-32.8927, 149.9687],
  [-32.8878, 149.9693],
  [-32.8858, 149.9686],
  [-32.8824, 149.9661],
  [-32.8797, 149.9653],
  [-32.8735, 149.9663],
  [-32.8718, 149.9673],
  [-32.8677, 149.9713],
  [-32.8654, 149.9724],
  [-32.8638, 149.9725],
  [-32.8620, 149.9718],
  [-32.8595, 149.9701]
];

const kandosRylstone = [
  [-32.8595, 149.9701],
  [-32.8585, 149.9698],
  [-32.8568, 149.9700],
  [-32.8552, 149.9711],
  [-32.8542, 149.9726],
  [-32.8530, 149.9760],
  [-32.8517, 149.9785],
  [-32.8503, 149.9801],
  [-32.8486, 149.9813],
  [-32.8467, 149.9820],
  [-32.8310, 149.9867],
  [-32.8290, 149.9867],
  [-32.8278, 149.9863],
  [-32.8252, 149.9847],
  [-32.8221, 149.9838],
  [-32.8175, 149.9838],
  [-32.8134, 149.9830],
  [-32.8092, 149.9812],
  [-32.8046, 149.9780],
  [-32.8034, 149.9774],
  [-32.7956, 149.9750]
];

const rylstoneMudgee = [
  [-32.7956, 149.9750],
  [-32.7939, 149.9744],
  [-32.7927, 149.9737],
  [-32.7908, 149.9721],
  [-32.7895, 149.9713],
  [-32.7884, 149.9709],
  [-32.7864, 149.9707],
  [-32.7837, 149.9715],
  [-32.7817, 149.9727],
  [-32.7809, 149.9729],
  [-32.7799, 149.9728],
  [-32.7777, 149.9723],
  [-32.7757, 149.9723],
  [-32.7736, 149.9728],
  [-32.7723, 149.9729],
  [-32.7709, 149.9726],
  [-32.7669, 149.9709],
  [-32.7653, 149.9696],
  [-32.7641, 149.9678],
  [-32.7616, 149.9590],
  [-32.7591, 149.9550],
  [-32.7501, 149.9470],
  [-32.7457, 149.9447],
  [-32.7439, 149.9428],
  [-32.7417, 149.9393],
  [-32.7373, 149.9278],
  [-32.7353, 149.9248],
  [-32.7308, 149.9205],
  [-32.7296, 149.9188],
  [-32.7294, 149.9171],
  [-32.7293, 149.9143],
  [-32.7288, 149.9130],
  [-32.7277, 149.9117],
  [-32.7260, 149.9106],
  [-32.7248, 149.9089],
  [-32.7245, 149.9073],
  [-32.7250, 149.9007],
  [-32.7239, 149.8970],
  [-32.7213, 149.8948],
  [-32.7134, 149.8926],
  [-32.7086, 149.8929],
  [-32.7041, 149.8942],
  [-32.7020, 149.8937],
  [-32.7005, 149.8923],
  [-32.6989, 149.8893],
  [-32.6973, 149.8878],
  [-32.6920, 149.8853],
  [-32.6906, 149.8842],
  [-32.6891, 149.8827],
  [-32.6876, 149.8821],
  [-32.6858, 149.8823],
  [-32.6834, 149.8836],
  [-32.6818, 149.8837],
  [-32.6801, 149.8829],
  [-32.6723, 149.8766],
  [-32.6713, 149.8747],
  [-32.6706, 149.8726],
  [-32.6697, 149.8711],
  [-32.6674, 149.8696],
  [-32.6644, 149.8687],
  [-32.6629, 149.8675],
  [-32.6622, 149.8660],
  [-32.6621, 149.8644],
  [-32.6623, 149.8630],
  [-32.6628, 149.8612],
  [-32.6627, 149.8591],
  [-32.6620, 149.8567],
  [-32.6501, 149.8370],
  [-32.6486, 149.8355],
  [-32.6464, 149.8338],
  [-32.6447, 149.8317],
  [-32.6436, 149.8280],
  [-32.6441, 149.8000],
  [-32.6434, 149.7975],
  [-32.6424, 149.7956],
  [-32.6335, 149.7842],
  [-32.6315, 149.7793],
  [-32.6288, 149.7532],
  [-32.6290, 149.7502],
  [-32.6298, 149.7482],
  [-32.6312, 149.7459],
  [-32.6316, 149.7443],
  [-32.6316, 149.7428],
  [-32.6297, 149.7357],
  [-32.6276, 149.7326],
  [-32.6230, 149.7293],
  [-32.6210, 149.7266],
  [-32.6197, 149.7231],
  [-32.6197, 149.7196],
  [-32.6205, 149.7165],
  [-32.6219, 149.7138],
  [-32.6225, 149.7125],
  [-32.6225, 149.7110],
  [-32.6222, 149.7095],
  [-32.6227, 149.7073],
  [-32.6266, 149.7012],
  [-32.6269, 149.6976],
  [-32.6264, 149.6968],
  [-32.6255, 149.6957],
  [-32.6242, 149.6951],
  [-32.6221, 149.6947],
  [-32.6210, 149.6941],
  [-32.6201, 149.6931],
  [-32.6132, 149.6787],
  [-32.6119, 149.6770],
  [-32.6069, 149.6717],
  [-32.6059, 149.6696],
  [-32.6046, 149.6622],
  [-32.6040, 149.6610],
  [-32.6033, 149.6602],
  [-32.5955, 149.6542],
  [-32.5945, 149.6529],
  [-32.5942, 149.6515],
  [-32.5942, 149.6497],
  [-32.6004, 149.6235],
  [-32.6014, 149.6219],
  [-32.6071, 149.6170],
  [-32.6088, 149.6133],
  [-32.6090, 149.6095],
  [-32.6063, 149.6013],
  [-32.6054, 149.5999],
  [-32.6032, 149.5974],
  [-32.6024, 149.5955],
  [-32.6013, 149.5875]
];

const mudgeeGulgongJunction = [
  [-32.6013, 149.5875],
  [-32.6008, 149.5841],
  [-32.6003, 149.5829],
  [-32.5995, 149.5822],
  [-32.5985, 149.5816],
  [-32.5980, 149.5808],
  [-32.5976, 149.5798],
  [-32.5972, 149.5771],
  [-32.5966, 149.5746],
  [-32.5938, 149.5689],
  [-32.5923, 149.5672],
  [-32.5902, 149.5663],
  [-32.5890, 149.5654],
  [-32.5883, 149.5645],
  [-32.5875, 149.5638],
  [-32.5829, 149.5613],
  [-32.5812, 149.5609],
  [-32.5737, 149.5608],
  [-32.5721, 149.5611],
  [-32.5601, 149.5651],
  [-32.5579, 149.5657],
  [-32.5335, 149.5684],
  [-32.5312, 149.5692],
  [-32.5296, 149.5703],
  [-32.5249, 149.5756],
  [-32.5241, 149.5760],
  [-32.5232, 149.5767],
  [-32.5207, 149.5805],
  [-32.5200, 149.5828],
  [-32.5202, 149.5844],
  [-32.5208, 149.5868],
  [-32.5208, 149.5885],
  [-32.5203, 149.5901],
  [-32.5195, 149.5911],
  [-32.5182, 149.5920],
  [-32.5175, 149.5929],
  [-32.5164, 149.5949],
  [-32.5148, 149.5961],
  [-32.5131, 149.5963],
  [-32.5114, 149.5960],
  [-32.5103, 149.5962],
  [-32.5091, 149.5964],
  [-32.5060, 149.5963],
  [-32.5021, 149.5972],
  [-32.4953, 149.5973],
  [-32.4924, 149.5967],
  [-32.4908, 149.5967],
  [-32.4884, 149.5970],
  [-32.4729, 149.5958],
  [-32.4709, 149.5951],
  [-32.4694, 149.5944],
  [-32.4675, 149.5942],
  [-32.4661, 149.5937],
  [-32.4644, 149.5929],
  [-32.4636, 149.5927],
  [-32.4625, 149.5930],
  [-32.4601, 149.5938],
  [-32.4578, 149.5938],
  [-32.4544, 149.5932],
  [-32.4522, 149.5934],
  [-32.4505, 149.5932],
  [-32.4483, 149.5928],
  [-32.4472, 149.5928],
  [-32.4447, 149.5936],
  [-32.4437, 149.5937],
  [-32.4416, 149.5933],
  [-32.4405, 149.5934],
  [-32.4379, 149.5945],
  [-32.4369, 149.5954],
  [-32.4359, 149.5963],
  [-32.4351, 149.5967],
  [-32.4341, 149.5967],
  [-32.4235, 149.5943],
  [-32.4211, 149.5942],
  [-32.4123, 149.5946],
  [-32.4096, 149.5943],
  [-32.4063, 149.5931],
  [-32.4008, 149.5902],
  [-32.4000, 149.5894],
  [-32.3997, 149.5886],
  [-32.3993, 149.5870],
  [-32.3986, 149.5857],
  [-32.3887, 149.5763],
  [-32.3761, 149.5592],
  [-32.3751, 149.5570],
  [-32.3741, 149.5532],
  [-32.3733, 149.5519],
  [-32.3723, 149.5513],
  [-32.3604, 149.5473],
  [-32.3596, 149.5468],
  [-32.3589, 149.5461]
];

const gulgongulgongJunction = [
  [-32.3571, 149.5425],
  [-32.3589, 149.5461]
];

const gulgongDunedoo = [
  [-32.3571, 149.5425],
  [-32.3551, 149.5393],
  [-32.3532, 149.5357],
  [-32.3521, 149.5346],
  [-32.3504, 149.5340],
  [-32.3440, 149.5343],
  [-32.3430, 149.5339],
  [-32.3422, 149.5330],
  [-32.3420, 149.5318],
  [-32.3420, 149.5297],
  [-32.3417, 149.5281],
  [-32.3407, 149.5257],
  [-32.3404, 149.5245],
  [-32.3402, 149.5228],
  [-32.3396, 149.5212],
  [-32.3383, 149.5199],
  [-32.3134, 149.5087],
  [-32.3094, 149.5075],
  [-32.3031, 149.5064],
  [-32.3007, 149.5048],
  [-32.2981, 149.5019],
  [-32.2951, 149.5003],
  [-32.2887, 149.5001],
  [-32.2875, 149.5004],
  [-32.2854, 149.5018],
  [-32.2836, 149.5020],
  [-32.2796, 149.5006],
  [-32.2777, 149.4996],
  [-32.2693, 149.4928],
  [-32.2633, 149.4890],
  [-32.2604, 149.4861],
  [-32.2515, 149.4727],
  [-32.2502, 149.4717],
  [-32.2383, 149.4674],
  [-32.2334, 149.4649],
  [-32.2271, 149.4608],
  [-32.2250, 149.4598],
  [-32.2026, 149.4536],
  [-32.1964, 149.4512],
  [-32.1930, 149.4512],
  [-32.1718, 149.4574],
  [-32.1648, 149.4611],
  [-32.1619, 149.4640],
  [-32.1604, 149.4648],
  [-32.1576, 149.4651],
  [-32.1532, 149.4668],
  [-32.1515, 149.4671],
  [-32.1489, 149.4672],
  [-32.1468, 149.4680],
  [-32.1445, 149.4693],
  [-32.1430, 149.4697],
  [-32.1413, 149.4695],
  [-32.1391, 149.4693],
  [-32.1364, 149.4697],
  [-32.1346, 149.4697],
  [-32.1289, 149.4681],
  [-32.1248, 149.4659],
  [-32.1176, 149.4646],
  [-32.1064, 149.4663],
  [-32.0955, 149.4656],
  [-32.0932, 149.4657],
  [-32.0715, 149.4710],
  [-32.0589, 149.4708],
  [-32.0539, 149.4692],
  [-32.0452, 149.4641],
  [-32.0420, 149.4629],
  [-32.0356, 149.4614],
  [-32.0334, 149.4613],
  [-32.0279, 149.4620],
  [-32.0269, 149.4618],
  [-32.0259, 149.4610],
  [-32.0254, 149.4601],
  [-32.0250, 149.4592],
  [-32.0249, 149.4575],
  [-32.0254, 149.4556],
  [-32.0256, 149.4520],
  [-32.0222, 149.4303],
  [-32.0191, 149.4215],
  [-32.0171, 149.4186],
  [-32.0116, 149.4136],
  [-32.0109, 149.4125],
  [-32.0108, 149.4109],
  [-32.0112, 149.4097],
  [-32.0119, 149.4087],
  [-32.0125, 149.4076],
  [-32.0129, 149.4057],
  [-32.0136, 149.4043],
  [-32.0146, 149.4034],
  [-32.0152, 149.4024],
  [-32.0155, 149.4012],
  [-32.0154, 149.3976]
];

const dunedooBinnaway = [
  [-32.0154, 149.3976],
  [-32.0154, 149.3927],
  [-32.0148, 149.3898],
  [-32.0135, 149.3877],
  [-32.0066, 149.3819],
  [-32.0057, 149.3809],
  [-32.0031, 149.3772],
  [-31.9974, 149.3651],
  [-31.9952, 149.3626],
  [-31.9871, 149.3577],
  [-31.9851, 149.3561],
  [-31.9687, 149.3378],
  [-31.9669, 149.3368],
  [-31.9657, 149.3369],
  [-31.9596, 149.3381],
  [-31.9583, 149.3379],
  [-31.9545, 149.3363],
  [-31.9530, 149.3362],
  [-31.9478, 149.3374],
  [-31.9463, 149.3371],
  [-31.9426, 149.3350],
  [-31.9416, 149.3338],
  [-31.9411, 149.3322],
  [-31.9409, 149.3301],
  [-31.9403, 149.3286],
  [-31.9389, 149.3274],
  [-31.9373, 149.3272],
  [-31.9360, 149.3277],
  [-31.9345, 149.3287],
  [-31.9330, 149.3291],
  [-31.9312, 149.3286],
  [-31.9299, 149.3271],
  [-31.9294, 149.3256],
  [-31.9292, 149.3234],
  [-31.9285, 149.3216],
  [-31.9274, 149.3205],
  [-31.9255, 149.3196],
  [-31.9174, 149.3148],
  [-31.9156, 149.3141],
  [-31.9109, 149.3133],
  [-31.9092, 149.3136],
  [-31.9071, 149.3144],
  [-31.9031, 149.3144],
  [-31.9013, 149.3134],
  [-31.9001, 149.3113],
  [-31.8989, 149.3063],
  [-31.8979, 149.3048],
  [-31.8965, 149.3039],
  [-31.8937, 149.3033],
  [-31.8916, 149.3034],
  [-31.8872, 149.3042],
  [-31.8859, 149.3039],
  [-31.8849, 149.3032],
  [-31.8813, 149.3001],
  [-31.8801, 149.2985],
  [-31.8792, 149.2966],
  [-31.8773, 149.2896],
  [-31.8764, 149.2877],
  [-31.8733, 149.2830],
  [-31.8727, 149.2817],
  [-31.8719, 149.2788],
  [-31.8710, 149.2771],
  [-31.8699, 149.2756],
  [-31.8629, 149.2695],
  [-31.8617, 149.2687],
  [-31.8546, 149.2658],
  [-31.8528, 149.2644],
  [-31.8519, 149.2629],
  [-31.8473, 149.2513],
  [-31.8463, 149.2499],
  [-31.8451, 149.2489],
  [-31.8438, 149.2483],
  [-31.8414, 149.2477],
  [-31.8396, 149.2466],
  [-31.8381, 149.2453],
  [-31.8367, 149.2431],
  [-31.8357, 149.2403],
  [-31.8347, 149.2316],
  [-31.8342, 149.2302],
  [-31.8324, 149.2272],
  [-31.8314, 149.2265],
  [-31.8303, 149.2262],
  [-31.8294, 149.2264],
  [-31.8287, 149.2268],
  [-31.8280, 149.2275],
  [-31.8276, 149.2284],
  [-31.8245, 149.2385],
  [-31.8223, 149.2491],
  [-31.8214, 149.2507],
  [-31.8198, 149.2518],
  [-31.8180, 149.2519],
  [-31.8151, 149.2508],
  [-31.8127, 149.2506],
  [-31.8109, 149.2510],
  [-31.8091, 149.2517],
  [-31.8070, 149.2511],
  [-31.8056, 149.2499],
  [-31.8029, 149.2446],
  [-31.8014, 149.2432],
  [-31.7697, 149.2333],
  [-31.7671, 149.2332],
  [-31.7648, 149.2341],
  [-31.7630, 149.2356],
  [-31.7546, 149.2454],
  [-31.7528, 149.2483],
  [-31.7489, 149.2591],
  [-31.7479, 149.2659],
  [-31.7474, 149.2675],
  [-31.7464, 149.2687],
  [-31.7410, 149.2724],
  [-31.7372, 149.2737],
  [-31.7311, 149.2732],
  [-31.7297, 149.2735],
  [-31.7284, 149.2745],
  [-31.7277, 149.2757],
  [-31.7254, 149.2832],
  [-31.7252, 149.2870],
  [-31.7277, 149.2983],
  [-31.7274, 149.3006],
  [-31.7266, 149.3019],
  [-31.7225, 149.3056],
  [-31.7110, 149.3114],
  [-31.7090, 149.3129],
  [-31.6921, 149.3294],
  [-31.6908, 149.3313],
  [-31.6823, 149.3521],
  [-31.6807, 149.3544],
  [-31.6778, 149.3568],
  [-31.6724, 149.3595],
  [-31.6462, 149.3669],
  [-31.6422, 149.3689],
  [-31.6297, 149.3785],
  [-31.6280, 149.3806],
  [-31.6273, 149.3826],
  [-31.6268, 149.3854],
  [-31.6260, 149.3867],
  [-31.6227, 149.3893],
  [-31.6174, 149.3917],
  [-31.6102, 149.3930],
  [-31.5907, 149.3916],
  [-31.5881, 149.3910],
  [-31.5805, 149.3881],
  [-31.5792, 149.3880],
  [-31.5740, 149.3892],
  [-31.5731, 149.3892],
  [-31.5683, 149.3871],
  [-31.5674, 149.3864],
  [-31.5638, 149.3829],
  [-31.5616, 149.3817],
  [-31.5595, 149.3814],
  [-31.5527, 149.3818],
  [-31.5485, 149.3820]
];

const binnawayCoonabarabran = [
  [-31.5485, 149.3820],
  [-31.5418, 149.3810],
  [-31.5362, 149.3813],
  [-31.5348, 149.3810],
  [-31.5336, 149.3801],
  [-31.5294, 149.3741],
  [-31.5282, 149.3706],
  [-31.5275, 149.3651],
  [-31.5268, 149.3634],
  [-31.5190, 149.3531],
  [-31.5181, 149.3512],
  [-31.5166, 149.3415],
  [-31.5157, 149.3405],
  [-31.5144, 149.3403],
  [-31.5068, 149.3421],
  [-31.5021, 149.3414],
  [-31.4989, 149.3397],
  [-31.4978, 149.3389],
  [-31.4966, 149.3386],
  [-31.4956, 149.3385],
  [-31.4948, 149.3379],
  [-31.4942, 149.3365],
  [-31.4938, 149.3336],
  [-31.4923, 149.3298],
  [-31.4908, 149.3284],
  [-31.4889, 149.3281],
  [-31.4830, 149.3303],
  [-31.4810, 149.3317],
  [-31.4797, 149.3335],
  [-31.4769, 149.3401],
  [-31.4763, 149.3408],
  [-31.4720, 149.3425],
  [-31.4708, 149.3424],
  [-31.4696, 149.3418],
  [-31.4689, 149.3408],
  [-31.4681, 149.3392],
  [-31.4669, 149.3382],
  [-31.4656, 149.3379],
  [-31.4611, 149.3377],
  [-31.4597, 149.3370],
  [-31.4588, 149.3360],
  [-31.4583, 149.3346],
  [-31.4581, 149.3330],
  [-31.4574, 149.3317],
  [-31.4566, 149.3311],
  [-31.4554, 149.3304],
  [-31.4542, 149.3294],
  [-31.4539, 149.3280],
  [-31.4536, 149.3223],
  [-31.4532, 149.3211],
  [-31.4525, 149.3201],
  [-31.4511, 149.3196],
  [-31.4498, 149.3199],
  [-31.4483, 149.3207],
  [-31.4474, 149.3208],
  [-31.4462, 149.3206],
  [-31.4342, 149.3151],
  [-31.4319, 149.3149],
  [-31.4297, 149.3155],
  [-31.4247, 149.3185],
  [-31.4239, 149.3192],
  [-31.4234, 149.3199],
  [-31.4219, 149.3240],
  [-31.4211, 149.3250],
  [-31.4200, 149.3256],
  [-31.4178, 149.3261],
  [-31.4168, 149.3266],
  [-31.4127, 149.3302],
  [-31.4100, 149.3326],
  [-31.4082, 149.3336],
  [-31.4044, 149.3331],
  [-31.4006, 149.3314],
  [-31.3995, 149.3305],
  [-31.3984, 149.3290],
  [-31.3975, 149.3282],
  [-31.3920, 149.3253],
  [-31.3910, 149.3254],
  [-31.3900, 149.3259],
  [-31.3892, 149.3273],
  [-31.3879, 149.3289],
  [-31.3874, 149.3292],
  [-31.3837, 149.3303],
  [-31.3825, 149.3312],
  [-31.3819, 149.3322],
  [-31.3810, 149.3333],
  [-31.3794, 149.3340],
  [-31.3762, 149.3341],
  [-31.3729, 149.3333],
  [-31.3704, 149.3335],
  [-31.3684, 149.3345],
  [-31.3667, 149.3357],
  [-31.3648, 149.3380],
  [-31.3638, 149.3400],
  [-31.3634, 149.3424],
  [-31.3638, 149.3489],
  [-31.3634, 149.3508],
  [-31.3606, 149.3552],
  [-31.3596, 149.3563],
  [-31.3541, 149.3591],
  [-31.3529, 149.3604],
  [-31.3512, 149.3640],
  [-31.3474, 149.3688],
  [-31.3462, 149.3696],
  [-31.3447, 149.3698],
  [-31.3425, 149.3693],
  [-31.3409, 149.3696],
  [-31.3395, 149.3708],
  [-31.3387, 149.3725],
  [-31.3381, 149.3772],
  [-31.3371, 149.3791],
  [-31.3293, 149.3861],
  [-31.3285, 149.3864],
  [-31.3275, 149.3865],
  [-31.3244, 149.3860],
  [-31.3232, 149.3861],
  [-31.3171, 149.3878],
  [-31.3158, 149.3878],
  [-31.3142, 149.3875],
  [-31.3129, 149.3878],
  [-31.3072, 149.3908],
  [-31.3061, 149.3912],
  [-31.3052, 149.3912],
  [-31.3032, 149.3905],
  [-31.3015, 149.3906],
  [-31.2985, 149.3919],
  [-31.2971, 149.3920],
  [-31.2959, 149.3916],
  [-31.2908, 149.3881],
  [-31.2888, 149.3862],
  [-31.2874, 149.3835],
  [-31.2868, 149.3804],
  [-31.2875, 149.3704],
  [-31.2875, 149.3678],
  [-31.2866, 149.3644],
  [-31.2841, 149.3598],
  [-31.2823, 149.3580],
  [-31.2801, 149.3569],
  [-31.2784, 149.3565],
  [-31.2771, 149.3558],
  [-31.2760, 149.3543],
  [-31.2693, 149.3386],
  [-31.2682, 149.3370],
  [-31.2652, 149.3333],
  [-31.2637, 149.3302],
  [-31.2632, 149.3275],
  [-31.2629, 149.3217],
  [-31.2624, 149.3196],
  [-31.2612, 149.3168],
  [-31.2609, 149.3153],
  [-31.2608, 149.3139],
  [-31.2615, 149.3096],
  [-31.2618, 149.3080],
  [-31.2648, 149.2988],
  [-31.2650, 149.2967],
  [-31.2648, 149.2941],
  [-31.2649, 149.2932],
  [-31.2652, 149.2925],
  [-31.2659, 149.2919],
  [-31.2676, 149.2907],
  [-31.2682, 149.2895],
  [-31.2695, 149.2850]
];

const coonabarabranGwabegar = [
  [-31.2695, 149.2850],
  [-31.2706, 149.2816],
  [-31.2706, 149.2810],
  [-31.2705, 149.2803],
  [-31.2701, 149.2790],
  [-31.2700, 149.2777],
  [-31.2703, 149.2769],
  [-31.2708, 149.2761],
  [-31.2712, 149.2752],
  [-31.2714, 149.2742],
  [-31.2712, 149.2732],
  [-31.2705, 149.2722],
  [-31.2677, 149.2704],
  [-31.2666, 149.2693],
  [-31.2657, 149.2676],
  [-31.2653, 149.2657],
  [-31.2655, 149.2638],
  [-31.2659, 149.2622],
  [-31.2660, 149.2610],
  [-31.2657, 149.2597],
  [-31.2640, 149.2560],
  [-31.2637, 149.2550],
  [-31.2637, 149.2541],
  [-31.2639, 149.2528],
  [-31.2638, 149.2513],
  [-31.2631, 149.2504],
  [-31.2621, 149.2499],
  [-31.2610, 149.2502],
  [-31.2601, 149.2510],
  [-31.2592, 149.2529],
  [-31.2582, 149.2537],
  [-31.2570, 149.2538],
  [-31.2554, 149.2533],
  [-31.2544, 149.2533],
  [-31.2536, 149.2534],
  [-31.2524, 149.2529],
  [-31.2475, 149.2500],
  [-31.2456, 149.2494],
  [-31.2446, 149.2486],
  [-31.2433, 149.2462],
  [-31.2404, 149.2432],
  [-31.2393, 149.2415],
  [-31.2391, 149.2411],
  [-31.2391, 149.2399],
  [-31.2398, 149.2378],
  [-31.2409, 149.2361],
  [-31.2412, 149.2352],
  [-31.2410, 149.2343],
  [-31.2393, 149.2302],
  [-31.2387, 149.2295],
  [-31.2377, 149.2291],
  [-31.2360, 149.2290],
  [-31.2351, 149.2284],
  [-31.2346, 149.2274],
  [-31.2345, 149.2261],
  [-31.2349, 149.2244],
  [-31.2349, 149.2234],
  [-31.2344, 149.2223],
  [-31.2327, 149.2206],
  [-31.2317, 149.2190],
  [-31.2306, 149.2186],
  [-31.2292, 149.2187],
  [-31.2275, 149.2185],
  [-31.2267, 149.2186],
  [-31.2259, 149.2190],
  [-31.2243, 149.2208],
  [-31.2232, 149.2211],
  [-31.2222, 149.2209],
  [-31.2213, 149.2201],
  [-31.2205, 149.2184],
  [-31.2195, 149.2172],
  [-31.2186, 149.2168],
  [-31.2169, 149.2164],
  [-31.2161, 149.2153],
  [-31.2159, 149.2139],
  [-31.2166, 149.2127],
  [-31.2179, 149.2123],
  [-31.2189, 149.2127],
  [-31.2195, 149.2137],
  [-31.2201, 149.2143],
  [-31.2212, 149.2145],
  [-31.2222, 149.2141],
  [-31.2231, 149.2127],
  [-31.2232, 149.2114],
  [-31.2223, 149.2072],
  [-31.2224, 149.2043],
  [-31.2230, 149.2031],
  [-31.2241, 149.2024],
  [-31.2253, 149.2026],
  [-31.2261, 149.2031],
  [-31.2272, 149.2038],
  [-31.2283, 149.2039],
  [-31.2294, 149.2028],
  [-31.2296, 149.2015],
  [-31.2291, 149.2003],
  [-31.2282, 149.1998],
  [-31.2270, 149.1997],
  [-31.2263, 149.1993],
  [-31.2258, 149.1986],
  [-31.2252, 149.1977],
  [-31.2245, 149.1972],
  [-31.2233, 149.1972],
  [-31.2215, 149.1987],
  [-31.2207, 149.1999],
  [-31.2198, 149.2007],
  [-31.2185, 149.2007],
  [-31.2175, 149.1998],
  [-31.2170, 149.1979],
  [-31.2168, 149.1965],
  [-31.2159, 149.1958],
  [-31.2145, 149.1955],
  [-31.2132, 149.1959],
  [-31.2117, 149.1968],
  [-31.2108, 149.1969],
  [-31.2096, 149.1963],
  [-31.2085, 149.1945],
  [-31.2082, 149.1936],
  [-31.2082, 149.1926],
  [-31.2084, 149.1912],
  [-31.2081, 149.1897],
  [-31.2051, 149.1854],
  [-31.2026, 149.1838],
  [-31.2018, 149.1830],
  [-31.2010, 149.1811],
  [-31.2001, 149.1804],
  [-31.1990, 149.1804],
  [-31.1980, 149.1812],
  [-31.1973, 149.1825],
  [-31.1963, 149.1833],
  [-31.1954, 149.1832],
  [-31.1945, 149.1825],
  [-31.1928, 149.1801],
  [-31.1902, 149.1784],
  [-31.1887, 149.1780],
  [-31.1845, 149.1786],
  [-31.1830, 149.1793],
  [-31.1830, 149.1783],
  [-31.1797, 149.1855],
  [-31.1786, 149.1864],
  [-31.1769, 149.1866],
  [-31.1755, 149.1856],
  [-31.1747, 149.1843],
  [-31.1744, 149.1808],
  [-31.1738, 149.1793],
  [-31.1722, 149.1775],
  [-31.1717, 149.1761],
  [-31.1719, 149.1746],
  [-31.1731, 149.1726],
  [-31.1733, 149.1709],
  [-31.1728, 149.1690],
  [-31.1729, 149.1679],
  [-31.1731, 149.1668],
  [-31.1747, 149.1637],
  [-31.1758, 149.1627],
  [-31.1771, 149.1620],
  [-31.1779, 149.1610],
  [-31.1780, 149.1595],
  [-31.1774, 149.1581],
  [-31.1762, 149.1574],
  [-31.1735, 149.1578],
  [-31.1709, 149.1587],
  [-31.1660, 149.1614],
  [-31.1643, 149.1614],
  [-31.1632, 149.1606],
  [-31.1626, 149.1592],
  [-31.1628, 149.1575],
  [-31.1639, 149.1560],
  [-31.1653, 149.1552],
  [-31.1663, 149.1538],
  [-31.1668, 149.1516],
  [-31.1659, 149.1482],
  [-31.1652, 149.1473],
  [-31.1643, 149.1469],
  [-31.1631, 149.1471],
  [-31.1619, 149.1477],
  [-31.1608, 149.1476],
  [-31.1600, 149.1470],
  [-31.1564, 149.1422],
  [-31.1555, 149.1418],
  [-31.1546, 149.1419],
  [-31.1537, 149.1424],
  [-31.1527, 149.1437],
  [-31.1515, 149.1443],
  [-31.1502, 149.1442],
  [-31.1492, 149.1434],
  [-31.1485, 149.1419],
  [-31.1485, 149.1401],
  [-31.1490, 149.1382],
  [-31.1487, 149.1368],
  [-31.1478, 149.1357],
  [-31.1467, 149.1354],
  [-31.1457, 149.1358],
  [-31.1442, 149.1367],
  [-31.1428, 149.1368],
  [-31.1412, 149.1361],
  [-31.1400, 149.1347],
  [-31.1395, 149.1331],
  [-31.1395, 149.1302],
  [-31.1381, 149.1255],
  [-31.1374, 149.1240],
  [-31.1351, 149.1214],
  [-31.1338, 149.1207],
  [-31.1296, 149.1199],
  [-31.1282, 149.1190],
  [-31.1245, 149.1148],
  [-31.1229, 149.1136],
  [-31.1211, 149.1129],
  [-31.1138, 149.1119],
  [-31.1104, 149.1110],
  [-31.0632, 149.0981],
  [-31.0610, 149.0965],
  [-31.0560, 149.0910],
  [-31.0536, 149.0898],
  [-30.9662, 149.0708],
  [-30.9646, 149.0702],
  [-30.9540, 149.0648],
  [-30.9521, 149.0643],
  [-30.9503, 149.0643],
  [-30.9437, 149.0653],
  [-30.9415, 149.0649],
  [-30.9396, 149.0640],
  [-30.9344, 149.0605],
  [-30.8728, 149.0331],
  [-30.8473, 149.0288],
  [-30.8173, 149.0299],
  [-30.8154, 149.0295],
  [-30.8137, 149.0284],
  [-30.8125, 149.0281],
  [-30.8083, 149.0285],
  [-30.8028, 149.0284],
  [-30.7269, 149.0163],
  [-30.7251, 149.0157],
  [-30.6460, 148.9777],
  [-30.6087, 148.9704]
];

export const gwabegar: Line = {
  name: 'Gwabegar',
  state: 'NSW',
  segments: [
    {
      segments: [
        wallerawangJunctionCaperteeTunnel,
        {
          name: 'Capertee Tunnel',
          segment: caperteeTunnel,
          type: 'tunnel'
        },
        caperteeTunnelCapertee
      ],
      history: {
        opened: {
          date: '1882-05-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        capereeCarlosGapTunnel,
        {
          name: 'Carlos Gap Tunnel',
          segment: carlosGapTunnel,
          type: 'tunnel'
        },
        carlosGapTunnelKandos
      ],
      history: {
        opened: {
          date: '1884-06-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kandosRylstone],
      history: {
        opened: {
          date: '1884-06-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-03-02',
          status: 'closed'
        }, {
          date: '1997-04-27',
          status: 'closed'
        }, {
          date: '2007-06-30',
          status: 'closed'
        }, {
          date: '1997-04-25',
          status: 'open'
        }, {
          date: '2000-09-02',
          status: 'open'
        }, {
          date: '2018-09-29',
          status: 'open'
        }]
      }
    },
    {
      segments: [rylstoneMudgee],
      history: {
        opened: {
          date: '1884-09-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-03-02',
          status: 'closed'
        }, {
          date: '2007-06-30',
          status: 'closed'
        }, {
          date: '2000-09-02',
          status: 'open'
        }]
      }
    },
    {
      segments: [mudgeeGulgongJunction],
      history: {
        opened: {
          date: '1909-04-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-03-02',
          status: 'closed'
        }, {
          date: '2007-06-30',
          status: 'closed'
        }, {
          date: '2000-09-02',
          status: 'open'
        }]
      }
    },
    {
      segments: [gulgongulgongJunction],
      history: {
        opened: {
          date: '1909-04-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [gulgongDunedoo],
      history: {
        opened: {
          date: '1910-11-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [dunedooBinnaway],
      history: {
        opened: {
          date: '1917-04-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [binnawayCoonabarabran],
      history: {
        opened: {
          date: '1917-06-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-10-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [coonabarabranGwabegar],
      history: {
        opened: {
          date: '1923-09-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-10-28',
          status: 'closed'
        }]
      }
    }
  ]
};
