import { Line } from "../../trackTypes";

const line = [
  [-30.6517, 146.3968],
  [-30.6506, 146.3965],
  [-30.6497, 146.3966],
  [-30.6489, 146.3970],
  [-30.6155, 146.4247],
  [-30.6017, 146.4362],
  [-30.5942, 146.4448],
  [-30.5920, 146.4464],
  [-30.5897, 146.4472],
  [-30.5863, 146.4475],
  [-30.5824, 146.4473],
  [-30.5807, 146.4476],
  [-30.5722, 146.4505],
  [-30.5702, 146.4524],
  [-30.5653, 146.4606],
  [-30.5640, 146.4616],
  [-30.5629, 146.4619],
  [-30.5486, 146.4623],
  [-30.5464, 146.4632],
  [-30.5452, 146.4645],
  [-30.5424, 146.4722],
  [-30.5413, 146.4736],
  [-30.5357, 146.4779],
  [-30.5338, 146.4803],
  [-30.5311, 146.4840],
  [-30.5292, 146.4854],
  [-30.5271, 146.4859],
  [-30.5142, 146.4860],
  [-30.4733, 146.4982],
  [-30.4699, 146.5006],
  [-30.4676, 146.5040],
  [-30.4428, 146.5524],
  [-30.4406, 146.5548],
  [-30.4385, 146.5559],
  [-30.4354, 146.5567],
  [-30.4333, 146.5584],
  [-30.4321, 146.5602],
  [-30.4305, 146.5630],
  [-30.3880, 146.6127],
  [-30.3863, 146.6143],
  [-30.3113, 146.6676],
  [-30.2556, 146.7255],
  [-30.2536, 146.7268],
  [-30.2304, 146.7360],
  [-30.2238, 146.7398],
  [-30.1098, 146.8279],
  [-30.0723, 146.8594],
  [-30.0702, 146.8603],
  [-30.0633, 146.8616],
  [-30.0617, 146.8615],
  [-30.0598, 146.8608],
  [-30.0580, 146.8594],
  [-30.0528, 146.8532],
  [-30.0507, 146.8519],
  [-30.0485, 146.8514],
  [-30.0035, 146.8553],
  [-29.9712, 146.8650],
  [-29.9663, 146.8656]
];

export const brewarrina: Line = {
  name: 'Brewarrina',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1901-09-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-18',
          status: 'closed'
        }]
      }
    }
  ]
};
