import { Line } from "../../trackTypes";

const stockinbingalCaragabal = [
  [-34.4986, 147.8685],
  [-34.4981, 147.8678],
  [-34.4976, 147.8674],
  [-34.4967, 147.8671],
  [-34.4957, 147.8673],
  [-34.4901, 147.8718],
  [-34.4887, 147.8723],
  [-34.4867, 147.8725],
  [-34.4852, 147.8729],
  [-34.4637, 147.8829],
  [-34.4610, 147.8835],
  [-34.4568, 147.8839],
  [-34.4539, 147.8846],
  [-34.4236, 147.9007],
  [-34.4207, 147.9012],
  [-34.4109, 147.9014],
  [-34.3827, 147.9067],
  [-34.3719, 147.9100],
  [-34.3518, 147.9137],
  [-34.3490, 147.9137],
  [-34.3378, 147.9114],
  [-34.3350, 147.9113],
  [-34.2829, 147.9209],
  [-34.2807, 147.9218],
  [-34.2534, 147.9378],
  [-34.2510, 147.9383],
  [-34.2480, 147.9379],
  [-34.2461, 147.9383],
  [-34.2447, 147.9394],
  [-34.2428, 147.9415],
  [-34.2416, 147.9422],
  [-34.2401, 147.9423],
  [-34.2036, 147.9356],
  [-34.2025, 147.9357],
  [-34.2014, 147.9363],
  [-34.1997, 147.9377],
  [-34.1976, 147.9385],
  [-34.1954, 147.9382],
  [-34.1852, 147.9327],
  [-34.1836, 147.9311],
  [-34.1745, 147.9170],
  [-34.1717, 147.9144],
  [-34.1683, 147.9129],
  [-34.1456, 147.9088],
  [-34.1425, 147.9072],
  [-34.1266, 147.8928],
  [-34.1013, 147.8541],
  [-34.0984, 147.8512],
  [-34.0321, 147.8121],
  [-34.0019, 147.7878],
  [-33.9053, 147.7319],
  [-33.9025, 147.7312],
  [-33.8992, 147.7312],
  [-33.8407, 147.7420]
];

const caragabalForbes = [
  [-33.8407, 147.7420],
  [-33.7786, 147.7534],
  [-33.7075, 147.7826],
  [-33.6879, 147.7959],
  [-33.6672, 147.8066],
  [-33.6457, 147.8181],
  [-33.6421, 147.8211],
  [-33.5874, 147.8928],
  [-33.5422, 147.9520],
  [-33.4968, 148.0172],
  [-33.4759, 148.0355],
  [-33.4732, 148.0366],
  [-33.4693, 148.0370],
  [-33.4658, 148.0364],
  [-33.3994, 148.0226],
  [-33.3809, 148.0127],
  [-33.3776, 148.0115]
];

const forbesParkesJunction = [
  [-33.3776, 148.0115],
  [-33.3743, 148.0102],
  [-33.3726, 148.0101],
  [-33.3713, 148.0104],
  [-33.3696, 148.0116],
  [-33.3648, 148.0179],
  [-33.3628, 148.0199],
  [-33.3395, 148.0362],
  [-33.3378, 148.0385],
  [-33.3371, 148.0410],
  [-33.3370, 148.0448],
  [-33.3360, 148.0480],
  [-33.3345, 148.0495],
  [-33.3262, 148.0540],
  [-33.3156, 148.0583],
  [-33.3140, 148.0593],
  [-33.3115, 148.0617],
  [-33.3097, 148.0629],
  [-33.3054, 148.0646],
  [-33.3041, 148.0655],
  [-33.3033, 148.0661],
  [-33.3017, 148.0680],
  [-33.3003, 148.0689],
  [-33.2988, 148.0692],
  [-33.2962, 148.0690],
  [-33.2933, 148.0697],
  [-33.2850, 148.0753],
  [-33.2781, 148.0832],
  [-33.2764, 148.0846],
  [-33.2658, 148.0905],
  [-33.2637, 148.0921],
  [-33.2436, 148.1101],
  [-33.2387, 148.1130],
  [-33.2316, 148.1157],
  [-33.2284, 148.1171],
  [-33.2032, 148.1357],
  [-33.1978, 148.1377],
  [-33.1932, 148.1406],
  [-33.1807, 148.1521],
  [-33.1780, 148.1535],
  [-33.1739, 148.1549],
  [-33.1720, 148.1549],
  [-33.1682, 148.1539],
  [-33.1664, 148.1539],
  [-33.1636, 148.1541],
  [-33.1598, 148.1535],
  [-33.1564, 148.1539],
  [-33.1523, 148.1557],
  [-33.1503, 148.1572],
  [-33.1480, 148.1594],
  [-33.1437, 148.1637],
  [-33.1427, 148.1652]
];

const parkesJunction = [
  [-33.1480, 148.1594],
  [-33.1474, 148.1596],
  [-33.1467, 148.1596],
  [-33.1461, 148.1593],
  [-33.1456, 148.1587],
  [-33.1446, 148.1564]
];

export const stockinbingalParkes: Line = {
  name: 'Stockinbingal Parkes',
  state: 'NSW',
  segments: [
    {
      segments: [stockinbingalCaragabal],
      history: {
        opened: {
          date: '1916-08-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [caragabalForbes],
      history: {
        opened: {
          date: '1918-04-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [forbesParkesJunction, parkesJunction],
      history: {
        opened: {
          date: '1893-12-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
