import { Line } from "../../trackTypes";

const jepqaritLorquon = [
  [-36.1366, 141.9850],
  [-36.1349, 141.9832],
  [-36.1340, 141.9819],
  [-36.1332, 141.9802],
  [-36.1290, 141.9659],
  [-36.1287, 141.9639],
  [-36.1289, 141.9614],
  [-36.1386, 141.9286],
  [-36.1390, 141.9250],
  [-36.1377, 141.9117],
  [-36.1321, 141.8559],
  [-36.1318, 141.8457],
  [-36.1323, 141.8431],
  [-36.1395, 141.8280],
  [-36.1403, 141.8251],
  [-36.1405, 141.8218],
  [-36.1371, 141.7888],
  [-36.1372, 141.7860],
  [-36.1338, 141.7530]
];

const lorquonYanac = [
  [-36.1338, 141.7530],
  [-36.1315, 141.7307],
  [-36.1316, 141.7278],
  [-36.1271, 141.6837],
  [-36.1263, 141.6811],
  [-36.1126, 141.6563],
  [-36.1118, 141.6537],
  [-36.1094, 141.6308],
  [-36.1094, 141.6284],
  [-36.1328, 141.5249],
  [-36.1371, 141.4918],
  [-36.1371, 141.4889],
  [-36.1354, 141.4465],
  [-36.1337, 141.4315]
];

export const yanac: Line = {
  name: 'Yanac',
  state: 'VIC',
  segments: [
    {
      segments: [jepqaritLorquon],
      history: {
        opened: {
          date: '1912-12-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange:[{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lorquonYanac],
      history: {
        opened: {
          date: '1916-06-27',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange:[{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    }
  ]
};
