import { Line } from "../../trackTypes";

const conaraNorthJunction = [
  [-41.8295, 147.4343],
  [-41.8298, 147.4350],
  [-41.8300, 147.4359],
  [-41.8298, 147.4369],
  [-41.8292, 147.4380]
];

const conaraJunctionFingal = [
  [-41.8322, 147.4377],
  [-41.8315, 147.4372],
  [-41.8308, 147.4370],
  [-41.8301, 147.4373],
  [-41.8292, 147.4380],
  [-41.8215, 147.4491],
  [-41.8204, 147.4524],
  [-41.8201, 147.4555],
  [-41.8234, 147.4962],
  [-41.8237, 147.4979],
  [-41.8249, 147.5003],
  [-41.8252, 147.5017],
  [-41.8253, 147.5040],
  [-41.8258, 147.5057],
  [-41.8263, 147.5072],
  [-41.8266, 147.5088],
  [-41.8255, 147.5245],
  [-41.8249, 147.5263],
  [-41.8229, 147.5300],
  [-41.8224, 147.5319],
  [-41.8223, 147.5334],
  [-41.8221, 147.5345],
  [-41.8214, 147.5362],
  [-41.8195, 147.5385],
  [-41.8188, 147.5403],
  [-41.8187, 147.5428],
  [-41.8194, 147.5461],
  [-41.8193, 147.5484],
  [-41.8171, 147.5560],
  [-41.8171, 147.5568],
  [-41.8171, 147.5580],
  [-41.8167, 147.5613],
  [-41.8167, 147.5649],
  [-41.8171, 147.5665],
  [-41.8174, 147.5689],
  [-41.8174, 147.5700],
  [-41.8169, 147.5743],
  [-41.8169, 147.5753],
  [-41.8175, 147.5796],
  [-41.8173, 147.5806],
  [-41.8169, 147.5813],
  [-41.8140, 147.5831],
  [-41.8133, 147.5841],
  [-41.8102, 147.5956],
  [-41.8101, 147.5972],
  [-41.8104, 147.6020],
  [-41.8107, 147.6035],
  [-41.8127, 147.6079],
  [-41.8128, 147.6093],
  [-41.8115, 147.6211],
  [-41.8117, 147.6272],
  [-41.8124, 147.6304],
  [-41.8125, 147.6316],
  [-41.8120, 147.6409],
  [-41.8099, 147.6528],
  [-41.8101, 147.6754],
  [-41.8099, 147.6768],
  [-41.8048, 147.6865],
  [-41.8043, 147.6878],
  [-41.8025, 147.6969],
  [-41.8019, 147.6985],
  [-41.7980, 147.7033],
  [-41.7971, 147.7039],
  [-41.7875, 147.7072],
  [-41.7867, 147.7079],
  [-41.7829, 147.7123],
  [-41.7826, 147.7130],
  [-41.7824, 147.7137],
  [-41.7824, 147.7152],
  [-41.7814, 147.7184],
  [-41.7809, 147.7191],
  [-41.7800, 147.7196],
  [-41.7795, 147.7201],
  [-41.7791, 147.7210],
  [-41.7783, 147.7250],
  [-41.7777, 147.7259],
  [-41.7763, 147.7267],
  [-41.7758, 147.7273],
  [-41.7747, 147.7297],
  [-41.7706, 147.7345],
  [-41.7699, 147.7358],
  [-41.7667, 147.7460],
  [-41.7663, 147.7469],
  [-41.7566, 147.7606],
  [-41.7561, 147.7617],
  [-41.7519, 147.7744],
  [-41.7498, 147.7807],
  [-41.7491, 147.7814],
  [-41.7478, 147.7823],
  [-41.7473, 147.7829],
  [-41.7398, 147.7950],
  [-41.7395, 147.7960],
  [-41.7391, 147.7983],
  [-41.7390, 147.7993],
  [-41.7392, 147.8029],
  [-41.7387, 147.8044],
  [-41.7362, 147.8084],
  [-41.7358, 147.8093],
  [-41.7347, 147.8138],
  [-41.7347, 147.8155],
  [-41.7344, 147.8170],
  [-41.7338, 147.8184],
  [-41.7323, 147.8200],
  [-41.7316, 147.8205],
  [-41.7301, 147.8213],
  [-41.7294, 147.8220],
  [-41.7280, 147.8252],
  [-41.7273, 147.8260],
  [-41.7255, 147.8265],
  [-41.7243, 147.8263],
  [-41.7238, 147.8263],
  [-41.7223, 147.8270],
  [-41.7217, 147.8277],
  [-41.7195, 147.8327],
  [-41.7163, 147.8367],
  [-41.7154, 147.8372],
  [-41.7121, 147.8375],
  [-41.7108, 147.8381],
  [-41.7035, 147.8442],
  [-41.7027, 147.8456],
  [-41.7009, 147.8524],
  [-41.6966, 147.8598],
  [-41.6958, 147.8608],
  [-41.6934, 147.8630],
  [-41.6926, 147.8643],
  [-41.6791, 147.8910],
  [-41.6673, 147.9054],
  [-41.6659, 147.9074],
  [-41.6633, 147.9126],
  [-41.6622, 147.9137],
  [-41.6586, 147.9152],
  [-41.6579, 147.9159],
  [-41.6567, 147.9182],
  [-41.6565, 147.9194],
  [-41.6562, 147.9205],
  [-41.6540, 147.9238],
  [-41.6534, 147.9243],
  [-41.6527, 147.9247],
  [-41.6521, 147.9251],
  [-41.6513, 147.9263],
  [-41.6508, 147.9277],
  [-41.6501, 147.9317],
  [-41.6494, 147.9329],
  [-41.6478, 147.9343],
  [-41.6472, 147.9353],
  [-41.6455, 147.9403],
  [-41.6442, 147.9466],
  [-41.6441, 147.9483],
  [-41.6451, 147.9583],
  [-41.6449, 147.9598],
  [-41.6440, 147.9613],
  [-41.6386, 147.9648],
  [-41.6379, 147.9658],
  [-41.6374, 147.9672],
  [-41.6368, 147.9714]
];

const fingalStMarys = [
  [-41.6368, 147.9714],
  [-41.6364, 147.9740],
  [-41.6355, 147.9760],
  [-41.6327, 147.9794],
  [-41.6319, 147.9801],
  [-41.6306, 147.9803],
  [-41.6283, 147.9801],
  [-41.6268, 147.9806],
  [-41.6254, 147.9820],
  [-41.6242, 147.9844],
  [-41.6232, 147.9860],
  [-41.6189, 147.9913],
  [-41.6183, 147.9923],
  [-41.6161, 147.9977],
  [-41.6132, 148.0029],
  [-41.6047, 148.0210],
  [-41.6021, 148.0291],
  [-41.5925, 148.0501],
  [-41.5920, 148.0521],
  [-41.5927, 148.0561],
  [-41.5926, 148.0574],
  [-41.5921, 148.0583],
  [-41.5883, 148.0625],
  [-41.5873, 148.0629],
  [-41.5848, 148.0631],
  [-41.5816, 148.0643],
  [-41.5798, 148.0662],
  [-41.5776, 148.0702],
  [-41.5768, 148.0728],
  [-41.5760, 148.0806],
  [-41.5760, 148.0829],
  [-41.5763, 148.0893],
  [-41.5752, 148.0991],
  [-41.5741, 148.1143],
  [-41.5749, 148.1419],
  [-41.5753, 148.1447],
  [-41.5778, 148.1553],
  [-41.5782, 148.1641],
  [-41.5783, 148.1723],
  [-41.5798, 148.1815]
];

export const fingal: Line = {
  name: 'Fingal',
  state: 'TAS',
  segments: [
    {
      segments: [conaraJunctionFingal],
      history: {
        opened: {
          date: '1886-06-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [conaraNorthJunction],
      history: {
        opened: {
          date: '1886-06-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [fingalStMarys],
      history: {
        opened: {
          date: '1886-06-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-03-23',
          status: 'closed'
        }]
      }
    }
  ]
}
