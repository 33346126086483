import { Line } from "../../trackTypes";

const line = [
  [-30.8326, 119.5072],
  [-30.8273, 119.5112],
  [-30.8223, 119.5125],
  [-30.8166, 119.5113],
  [-30.8045, 119.5043],
  [-30.8020, 119.5045],
  [-30.8004, 119.5063],
  [-30.8000, 119.5086],
  [-30.8053, 119.5331]
];

export const koolyanobbing: Line = {
  name: 'Koolyanobbing',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1967-07-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2018-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
