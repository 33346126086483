import { Line } from "../../trackTypes";

const muswellbrookDenman = [
  [-32.2665, 150.8885],
  [-32.2661, 150.8876],
  [-32.2660, 150.8870],
  [-32.2665, 150.8792],
  [-32.2670, 150.8771],
  [-32.2677, 150.8751],
  [-32.2699, 150.8713],
  [-32.2719, 150.8662],
  [-32.2721, 150.8647],
  [-32.2719, 150.8632],
  [-32.2720, 150.8619],
  [-32.2725, 150.8603],
  [-32.2825, 150.8417],
  [-32.2837, 150.8396],
  [-32.2855, 150.8379],
  [-32.2870, 150.8358],
  [-32.2941, 150.8194],
  [-32.2945, 150.8176],
  [-32.2956, 150.8078],
  [-32.2966, 150.8054],
  [-32.2981, 150.8044],
  [-32.2995, 150.8044],
  [-32.3008, 150.8048],
  [-32.3022, 150.8049],
  [-32.3037, 150.8042],
  [-32.3049, 150.8026],
  [-32.3052, 150.8010],
  [-32.3054, 150.7955],
  [-32.3056, 150.7938],
  [-32.3065, 150.7902],
  [-32.3067, 150.7888],
  [-32.3066, 150.7862],
  [-32.3067, 150.7844],
  [-32.3083, 150.7791],
  [-32.3102, 150.7756],
  [-32.3107, 150.7740],
  [-32.3115, 150.7698],
  [-32.3122, 150.7682],
  [-32.3141, 150.7653],
  [-32.3148, 150.7635],
  [-32.3151, 150.7615],
  [-32.3154, 150.7560],
  [-32.3161, 150.7529],
  [-32.3173, 150.7502],
  [-32.3542, 150.6999],
  [-32.3570, 150.6974],
  [-32.3834, 150.6846],
  [-32.3847, 150.6841],
  [-32.3883, 150.6833]
];

const denmanSandyHollow = [
  [-32.3883, 150.6833],
  [-32.3903, 150.6828],
  [-32.3918, 150.6818],
  [-32.3931, 150.6799],
  [-32.3987, 150.6669],
  [-32.4031, 150.6601],
  [-32.4042, 150.6589],
  [-32.4053, 150.6568],
  [-32.4055, 150.6548],
  [-32.4047, 150.6510],
  [-32.4048, 150.6496],
  [-32.4052, 150.6487],
  [-32.4058, 150.6479],
  [-32.4080, 150.6465],
  [-32.4088, 150.6453],
  [-32.4091, 150.6437],
  [-32.4086, 150.6423],
  [-32.4077, 150.6413],
  [-32.4044, 150.6395],
  [-32.3990, 150.6357],
  [-32.3968, 150.6332],
  [-32.3964, 150.6323],
  [-32.3964, 150.6309],
  [-32.3967, 150.6288],
  [-32.3966, 150.6270],
  [-32.3961, 150.6249],
  [-32.3953, 150.6236],
  [-32.3939, 150.6231],
  [-32.3918, 150.6236],
  [-32.3892, 150.6253],
  [-32.3876, 150.6255],
  [-32.3866, 150.6249],
  [-32.3854, 150.6238],
  [-32.3844, 150.6234],
  [-32.3829, 150.6231],
  [-32.3817, 150.6225],
  [-32.3753, 150.6160],
  [-32.3735, 150.6151],
  [-32.3713, 150.6154],
  [-32.3684, 150.6172],
  [-32.3670, 150.6179],
  [-32.3653, 150.6182],
  [-32.3645, 150.6186],
  [-32.3630, 150.6197],
  [-32.3621, 150.6202],
  [-32.3556, 150.6220],
  [-32.3546, 150.6218],
  [-32.3538, 150.6211],
  [-32.3533, 150.6200],
  [-32.3532, 150.6177],
  [-32.3529, 150.6160],
  [-32.3516, 150.6114],
  [-32.3511, 150.6084],
  [-32.3505, 150.5934],
  [-32.3496, 150.5896],
  [-32.3399, 150.5682]
];

const sandyHollowMerriwa = [
  [-32.3399, 150.5682],
  [-32.3355, 150.5593],
  [-32.3345, 150.5564],
  [-32.3318, 150.5421],
  [-32.3316, 150.5389],
  [-32.3328, 150.5316],
  [-32.3326, 150.5295],
  [-32.3317, 150.5279],
  [-32.3299, 150.5268],
  [-32.3236, 150.5256],
  [-32.3157, 150.5260],
  [-32.3145, 150.5257],
  [-32.3135, 150.5250],
  [-32.3032, 150.5152],
  [-32.3007, 150.5111],
  [-32.2982, 150.5088],
  [-32.2949, 150.5081],
  [-32.2920, 150.5081],
  [-32.2891, 150.5075],
  [-32.2870, 150.5068],
  [-32.2797, 150.5056],
  [-32.2778, 150.5049],
  [-32.2738, 150.5017],
  [-32.2716, 150.5009],
  [-32.2694, 150.5009],
  [-32.2676, 150.5000],
  [-32.2656, 150.4979],
  [-32.2636, 150.4968],
  [-32.2617, 150.4966],
  [-32.2565, 150.4977],
  [-32.2547, 150.4973],
  [-32.2535, 150.4964],
  [-32.2527, 150.4950],
  [-32.2522, 150.4928],
  [-32.2518, 150.4918],
  [-32.2510, 150.4911],
  [-32.2492, 150.4905],
  [-32.2480, 150.4894],
  [-32.2469, 150.4875],
  [-32.2459, 150.4865],
  [-32.2448, 150.4860],
  [-32.2436, 150.4859],
  [-32.2381, 150.4863],
  [-32.2370, 150.4861],
  [-32.2359, 150.4857],
  [-32.2350, 150.4858],
  [-32.2341, 150.4865],
  [-32.2330, 150.4881],
  [-32.2321, 150.4887],
  [-32.2310, 150.4888],
  [-32.2300, 150.4881],
  [-32.2295, 150.4870],
  [-32.2292, 150.4856],
  [-32.2289, 150.4847],
  [-32.2282, 150.4840],
  [-32.2271, 150.4834],
  [-32.2264, 150.4829],
  [-32.2245, 150.4808],
  [-32.2241, 150.4799],
  [-32.2240, 150.4789],
  [-32.2247, 150.4763],
  [-32.2247, 150.4751],
  [-32.2244, 150.4727],
  [-32.2238, 150.4716],
  [-32.2229, 150.4710],
  [-32.2217, 150.4710],
  [-32.2206, 150.4717],
  [-32.2200, 150.4730],
  [-32.2192, 150.4740],
  [-32.2185, 150.4743],
  [-32.2106, 150.4765],
  [-32.2088, 150.4762],
  [-32.2077, 150.4751],
  [-32.2064, 150.4737],
  [-32.2040, 150.4730],
  [-32.2026, 150.4723],
  [-32.1977, 150.4673],
  [-32.1925, 150.4626],
  [-32.1918, 150.4614],
  [-32.1915, 150.4601],
  [-32.1913, 150.4554],
  [-32.1908, 150.4541],
  [-32.1901, 150.4534],
  [-32.1884, 150.4525],
  [-32.1875, 150.4512],
  [-32.1869, 150.4492],
  [-32.1867, 150.4465],
  [-32.1862, 150.4450],
  [-32.1834, 150.4413],
  [-32.1821, 150.4404],
  [-32.1796, 150.4397],
  [-32.1790, 150.4383],
  [-32.1766, 150.4360],
  [-32.1751, 150.4347],
  [-32.1712, 150.4342],
  [-32.1680, 150.4343],
  [-32.1609, 150.4333],
  [-32.1598, 150.4336],
  [-32.1584, 150.4346],
  [-32.1575, 150.4350],
  [-32.1566, 150.4352],
  [-32.1558, 150.4357],
  [-32.1546, 150.4368],
  [-32.1538, 150.4372],
  [-32.1530, 150.4372],
  [-32.1483, 150.4361],
  [-32.1471, 150.4351],
  [-32.1463, 150.4334],
  [-32.1418, 150.4200],
  [-32.1402, 150.4169],
  [-32.1355, 150.4114],
  [-32.1346, 150.4092],
  [-32.1337, 150.4076],
  [-32.1306, 150.4043],
  [-32.1267, 150.3986],
  [-32.1261, 150.3969],
  [-32.1254, 150.3913],
  [-32.1258, 150.3892],
  [-32.1269, 150.3869],
  [-32.1273, 150.3843],
  [-32.1272, 150.3821],
  [-32.1266, 150.3795],
  [-32.1265, 150.3783],
  [-32.1270, 150.3772],
  [-32.1280, 150.3764],
  [-32.1302, 150.3760],
  [-32.1316, 150.3763],
  [-32.1325, 150.3766],
  [-32.1337, 150.3765],
  [-32.1347, 150.3757],
  [-32.1355, 150.3737],
  [-32.1362, 150.3729],
  [-32.1370, 150.3726],
  [-32.1379, 150.3723],
  [-32.1387, 150.3714],
  [-32.1391, 150.3704],
  [-32.1390, 150.3692],
  [-32.1374, 150.3631],
  [-32.1357, 150.3594]
]

export const merriwa: Line = {
  name: 'Merriwa',
  state: 'NSW',
  segments: [
    {
      segments: [muswellbrookDenman],
      history: {
        opened: {
          date: '1915-04-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [denmanSandyHollow],
      history: {
        opened: {
          date: '1917-10-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [sandyHollowMerriwa],
      history: {
        opened: {
          date: '1917-10-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-02-10',
          status: 'closed'
        }]
      }
    }
  ]
};
