import { Line } from "../../../trackTypes";

const eastNatimukNoradjuha = [
  [-36.7418, 141.9669],
  [-36.7426, 141.9670],
  [-36.7444, 141.9665],
  [-36.7556, 141.9667],
  [-36.7786, 141.9720],
  [-36.7802, 141.9722],
  [-36.7873, 141.9721],
  [-36.8302, 141.9657],
  [-36.8312, 141.9653],
  [-36.8340, 141.9626]
];

const noradjuhaToolando = [
  [-36.8340, 141.9626],
  [-36.8358, 141.9610],
  [-36.8369, 141.9605],
  [-36.8388, 141.9605],
  [-36.8464, 141.9645],
  [-36.8486, 141.9650],
  [-36.9183, 141.9545],
  [-36.9205, 141.9535],
  [-36.9214, 141.9529],
  [-36.9343, 141.9437],
  [-36.9354, 141.9433],
  [-36.9507, 141.9410],
  [-36.9863, 141.9356],
  [-36.9899, 141.9343],
  [-36.9933, 141.9330]
];

const toolandoKanagulk = [
  [-36.9933, 141.9330],
  [-37.0200, 141.9242],
  [-37.0314, 141.9212],
  [-37.0338, 141.9203],
  [-37.0578, 141.9068],
  [-37.1067, 141.8682],
  [-37.1271, 141.8518]
];

const kanagulkBalmoral = [
  [-37.1271, 141.8518],
  [-37.1293, 141.8500],
  [-37.1316, 141.8488],
  [-37.1335, 141.8487],
  [-37.1351, 141.8490],
  [-37.1543, 141.8560],
  [-37.1569, 141.8563],
  [-37.1609, 141.8563],
  [-37.1633, 141.8555],
  [-37.1753, 141.8486],
  [-37.1968, 141.8424],
  [-37.1993, 141.8421],
  [-37.2057, 141.8418],
  [-37.2201, 141.8405],
  [-37.2245, 141.8413],
  [-37.2278, 141.8405],
  [-37.2301, 141.8388],
  [-37.2334, 141.8339],
  [-37.2354, 141.8328],
  [-37.2379, 141.8337],
  [-37.2402, 141.8360]
];

const cavendishBalmoral = [
  [-37.5296, 142.0377],
  [-37.5253, 142.0384],
  [-37.5219, 142.0383],
  [-37.5147, 142.0374],
  [-37.5126, 142.0368],
  [-37.5111, 142.0357],
  [-37.5097, 142.0342],
  [-37.5047, 142.0284],
  [-37.4943, 142.0164],
  [-37.4738, 141.9984],
  [-37.4451, 141.9766],
  [-37.4348, 141.9687],
  [-37.4339, 141.9679],
  [-37.4101, 141.9427],
  [-37.4079, 141.9398],
  [-37.4015, 141.9271],
  [-37.3944, 141.9171],
  [-37.3838, 141.8957],
  [-37.3500, 141.8373],
  [-37.3476, 141.8344],
  [-37.3450, 141.8327],
  [-37.3099, 141.8199],
  [-37.3085, 141.8195],
  [-37.3013, 141.8197],
  [-37.2882, 141.8203],
  [-37.2758, 141.8219],
  [-37.2744, 141.8220],
  [-37.2635, 141.8206],
  [-37.2618, 141.8208],
  [-37.2600, 141.8216],
  [-37.2590, 141.8225],
  [-37.2568, 141.8257],
  [-37.2455, 141.8372],
  [-37.2440, 141.8378],
  [-37.2426, 141.8378],
  [-37.2414, 141.8371],
  [-37.2402, 141.8360]
];

const hamiltonCavendish = [
  [-37.7280, 142.0461],
  [-37.7264, 142.0486],
  [-37.7248, 142.0502],
  [-37.7229, 142.0511],
  [-37.7209, 142.0514],
  [-37.7198, 142.0512],
  [-37.7136, 142.0498],
  [-37.7125, 142.0498],
  [-37.7048, 142.0510],
  [-37.6982, 142.0541],
  [-37.6964, 142.0545],
  [-37.6946, 142.0544],
  [-37.6190, 142.0321],
  [-37.6170, 142.0319],
  [-37.5530, 142.0318],
  [-37.5506, 142.0324],
  [-37.5455, 142.0346],
  [-37.5412, 142.0356],
  [-37.5296, 142.0377]
];

export const hamiltonEastNatimuk: Line = {
  name: 'Hamilton - East Natimuk',
  state: 'VIC',
  segments: [
    {
      segments: [eastNatimukNoradjuha],
      history: {
        opened: {
          date: '1887-08-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [noradjuhaToolando],
      history: {
        opened: {
          date: '1912-09-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [toolandoKanagulk],
      history: {
        opened: {
          date: '1917-12-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kanagulkBalmoral],
      history: {
        opened: {
          date: '1919-06-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cavendishBalmoral],
      history: {
        opened: {
          date: '1920-11-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hamiltonCavendish],
      history: {
        opened: {
          date: '1915-11-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-07-01',
          status: 'closed'
        }]
      }
    }
  ]
}
