import { Line } from "../../../trackTypes";

const line = [
  [-38.33382, 141.60799],
  [-38.33383, 141.60733],
  [-38.33390, 141.60716],
  [-38.33406, 141.60706],
  [-38.33881, 141.60687],
  [-38.33949, 141.60693],
  [-38.34267, 141.60677],
  [-38.34291, 141.60670],
  [-38.34314, 141.60670],
  [-38.34328, 141.60678],
  [-38.34342, 141.60680],
  [-38.34359, 141.60676],
  [-38.34377, 141.60673],
  [-38.34426, 141.60680],
  [-38.34560, 141.60674],
  [-38.34578, 141.60664],
  [-38.34612, 141.60663],
  [-38.34624, 141.60669],
  [-38.34642, 141.60668],
  [-38.34727, 141.60678],
  [-38.34795, 141.60694],
  [-38.34805, 141.60701],
  [-38.34821, 141.60717],
  [-38.34854, 141.60722],
  [-38.35064, 141.60782],
  [-38.35092, 141.60795],
  [-38.35128, 141.60829],
  [-38.35147, 141.60846],
  [-38.35179, 141.60905],
  [-38.35200, 141.60963],
  [-38.35208, 141.61013],
  [-38.35217, 141.61049],
  [-38.35249, 141.61125],
  [-38.35259, 141.61135],
  [-38.35272, 141.61138],
  [-38.35286, 141.61131],
  [-38.35292, 141.61118],
  [-38.35288, 141.61052],
  [-38.35286, 141.60995],
  [-38.35306, 141.60842],
  [-38.35317, 141.60816],
  [-38.35330, 141.60799],
  [-38.35357, 141.60735],
  [-38.35379, 141.60709],
  [-38.35394, 141.60704],
  [-38.35420, 141.60706],
  [-38.35443, 141.60719],
  [-38.35458, 141.60720],
  [-38.35471, 141.60713],
  [-38.35481, 141.60692],
  [-38.35476, 141.60531],
  [-38.35471, 141.60508],
  [-38.35465, 141.60374],
  [-38.35463, 141.60351],
  [-38.35434, 141.60291],
  [-38.35423, 141.60278],
  [-38.35404, 141.60269],
  [-38.35392, 141.60272],
  [-38.35382, 141.60281],
  [-38.35375, 141.60295],
  [-38.35377, 141.60312],
  [-38.35384, 141.60326],
  [-38.35410, 141.60346],
  [-38.35421, 141.60350],
  [-38.35443, 141.60349],
  [-38.35451, 141.60353],
  [-38.35459, 141.60361],
  [-38.35465, 141.60374]
];

export const portlandTrams: Line = {
  name: 'Portland Trams',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2002-03-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'cableTram'
        }
      }
    }
  ]
};
