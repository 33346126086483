import { Line } from "../../trackTypes";

const line = [
  [-32.0254, 149.4601],
  [-32.0241, 149.4594],
  [-32.0227, 149.4594],
  [-32.0215, 149.4603],
  [-32.0209, 149.4619],
  [-32.0210, 149.4633],
  [-32.0228, 149.4685],
  [-32.0228, 149.4700],
  [-32.0222, 149.4715],
  [-32.0210, 149.4725],
  [-32.0199, 149.4733],
  [-32.0179, 149.4741],
  [-32.0172, 149.4754],
  [-32.0172, 149.4770],
  [-32.0177, 149.4787],
  [-32.0179, 149.4798],
  [-32.0178, 149.4808],
  [-32.0172, 149.4822],
  [-32.0170, 149.4835],
  [-32.0172, 149.4845],
  [-32.0176, 149.4857],
  [-32.0176, 149.4870],
  [-32.0172, 149.4883],
  [-32.0145, 149.4943],
  [-32.0142, 149.4955],
  [-32.0132, 149.5452],
  [-32.0139, 149.5489],
  [-32.0137, 149.5503],
  [-32.0133, 149.5515],
  [-31.9780, 149.6075],
  [-31.9768, 149.6103],
  [-31.9712, 149.6358],
  [-31.9702, 149.6384],
  [-31.9616, 149.6523],
  [-31.9298, 149.6818],
  [-31.9176, 149.6909],
  [-31.8962, 149.6996],
  [-31.8934, 149.7003],
  [-31.8786, 149.7009],
  [-31.8763, 149.7005],
  [-31.8745, 149.6995],
  [-31.8671, 149.6929],
  [-31.8657, 149.6922],
  [-31.8646, 149.6921],
  [-31.8616, 149.6930],
  [-31.8606, 149.6937],
  [-31.8578, 149.6968],
  [-31.8564, 149.6980],
  [-31.8541, 149.6992],
  [-31.8510, 149.6999],
  [-31.8481, 149.6997],
  [-31.8471, 149.6999],
  [-31.8462, 149.7005],
  [-31.8395, 149.7075],
  [-31.8383, 149.7083],
  [-31.8314, 149.7123],
  [-31.8282, 149.7158]
];

export const coolah: Line = {
  name: 'Coolah',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1920-03-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-10-01',
          status: 'closed'
        }]
      }
    }
  ]
};
