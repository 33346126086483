import { Line } from "../../trackTypes";

const junctionMitchelPark = [
  [-34.9901, 138.5619],
  [-34.9908, 138.5616],
  [-34.9915, 138.5616],
  [-34.9969, 138.5633],
  [-34.9981, 138.5639],
  [-34.9988, 138.5645],
  [-34.9990, 138.5647]
];

const mitchelParkTonsley = [
  [-34.9990, 138.5647],
  [-34.9998, 138.5656],
  [-35.0008, 138.5663],
  [-35.0017, 138.5667],
  [-35.0024, 138.5668],
  [-35.0091, 138.5672],
  [-35.0119, 138.5674],
  [-35.0159, 138.5657]
];

const tonsleyFlinders = [
  [-35.0159, 138.5657],
  [-35.0165, 138.5655],
  [-35.0173, 138.5656],
  [-35.0183, 138.5664],
  [-35.0189, 138.5678],
  [-35.0189, 138.5703]
];

export const flinders: Line = {
  name: 'Flinders',
  state: 'SA',
  segments: [
    {
      segments: [junctionMitchelPark],
      history: {
        opened: {
          date: '1966-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-09-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2014-05-05',
          tracks: 2
        }]
      }
    },
    {
      segments: [mitchelParkTonsley],
      history: {
        opened: {
          date: '1966-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-09-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [tonsleyFlinders],
      history: {
        opened: {
          date: '2020-12-29',
          tracks: 1,
          gauge: 'broad',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
