import { Line } from "../../trackTypes";

const lintonJunctionBeaufort = [
  [-37.5324, 143.8000],
  [-37.5071, 143.7440],
  [-37.4917, 143.7124],
  [-37.4769, 143.6819],
  [-37.4744, 143.6747],
  [-37.4731, 143.6681],
  [-37.4691, 143.6352],
  [-37.4687, 143.6334],
  [-37.4651, 143.6208],
  [-37.4647, 143.6182],
  [-37.4646, 143.6156],
  [-37.4648, 143.6140],
  [-37.4673, 143.6023],
  [-37.4674, 143.5985],
  [-37.4669, 143.5933],
  [-37.4665, 143.5912],
  [-37.4638, 143.5814],
  [-37.4615, 143.5649],
  [-37.4608, 143.5622],
  [-37.4556, 143.5484],
  [-37.4551, 143.5464],
  [-37.4513, 143.5253],
  [-37.4502, 143.5211],
  [-37.4381, 143.4878],
  [-37.4374, 143.4850],
  [-37.4294, 143.4333],
  [-37.4278, 143.4205],
  [-37.4261, 143.3984],
  [-37.4261, 143.3946],
  [-37.4275, 143.3823]
];

const beaufortArarat = [
  [-37.4275, 143.3823],
  [-37.4276, 143.3813],
  [-37.4277, 143.3805],
  [-37.4276, 143.3795],
  [-37.4275, 143.3785],
  [-37.4274, 143.3778],
  [-37.4271, 143.3770],
  [-37.4268, 143.3760],
  [-37.4248, 143.3707],
  [-37.4244, 143.3685],
  [-37.4244, 143.3669],
  [-37.4246, 143.3651],
  [-37.4251, 143.3634],
  [-37.4260, 143.3616],
  [-37.4301, 143.3565],
  [-37.4314, 143.3549],
  [-37.4329, 143.3534],
  [-37.4352, 143.3517],
  [-37.4374, 143.3504],
  [-37.4383, 143.3498],
  [-37.4391, 143.3492],
  [-37.4402, 143.3478],
  [-37.4410, 143.3463],
  [-37.4416, 143.3443],
  [-37.4420, 143.3421],
  [-37.4424, 143.3387],
  [-37.4427, 143.3342],
  [-37.4429, 143.3326],
  [-37.4433, 143.3308],
  [-37.4437, 143.3292],
  [-37.4440, 143.3277],
  [-37.4441, 143.3265],
  [-37.4441, 143.3250],
  [-37.4439, 143.3235],
  [-37.4436, 143.3220],
  [-37.4427, 143.3192],
  [-37.4422, 143.3172],
  [-37.4421, 143.3155],
  [-37.4421, 143.3142],
  [-37.4425, 143.3123],
  [-37.4430, 143.3110],
  [-37.4446, 143.3074],
  [-37.4450, 143.3062],
  [-37.4453, 143.3045],
  [-37.4453, 143.3028],
  [-37.4451, 143.3009],
  [-37.4446, 143.2993],
  [-37.4437, 143.2973],
  [-37.4428, 143.2956],
  [-37.4412, 143.2934],
  [-37.4400, 143.2921],
  [-37.4153, 143.2657],
  [-37.4142, 143.2642],
  [-37.4132, 143.2627],
  [-37.4119, 143.2602],
  [-37.3861, 143.2068],
  [-37.3851, 143.2049],
  [-37.3715, 143.1844],
  [-37.3706, 143.1827],
  [-37.3697, 143.1806],
  [-37.3692, 143.1783],
  [-37.3677, 143.1639],
  [-37.3671, 143.1613],
  [-37.3660, 143.1585],
  [-37.3652, 143.1572],
  [-37.3581, 143.1464],
  [-37.3569, 143.1440],
  [-37.3558, 143.1413],
  [-37.3529, 143.1335],
  [-37.3514, 143.1300],
  [-37.3432, 143.1149],
  [-37.3416, 143.1119],
  [-37.3395, 143.1087],
  [-37.3383, 143.1067],
  [-37.3374, 143.1046],
  [-37.3358, 143.0997],
  [-37.3226, 143.0698],
  [-37.3220, 143.0682],
  [-37.3217, 143.0669],
  [-37.3180, 143.0481],
  [-37.3113, 143.0213],
  [-37.3107, 143.0198],
  [-37.3083, 143.0136],
  [-37.3077, 143.0116],
  [-37.3073, 143.0094],
  [-37.3054, 142.9932],
  [-37.3049, 142.9907],
  [-37.3041, 142.9885],
  [-37.2968, 142.9730],
  [-37.2950, 142.9686],
  [-37.2945, 142.9675],
  [-37.2934, 142.9656],
  [-37.2871, 142.9576],
  [-37.2862, 142.9560],
  [-37.2857, 142.9549],
  [-37.2840, 142.9485]
];

const araratDualGauge = [
  [-37.2840, 142.9485],
  [-37.2835, 142.9466],
  [-37.2829, 142.9422],
  [-37.2822, 142.9366]
];

export const ararat: Line = {
  name: 'Ararat',
  state: 'VIC',
  segments: [
    {
      segments: [beaufortArarat],
      history: {
        opened: {
          date: '1875-04-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [araratDualGauge],
      history: {
        opened: {
          date: '1875-04-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-14',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [lintonJunctionBeaufort],
      history: {
        opened: {
          date: '1874-08-11',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    }
  ]
};
