import { Line } from "../../trackTypes";

const line = [
  [-35.6681, 147.0377],
  [-35.6690, 147.0379],
  [-35.6709, 147.0395],
  [-35.6738, 147.0418],
  [-35.6756, 147.0427],
  [-35.6770, 147.0442],
  [-35.6782, 147.0471],
  [-35.6842, 147.0933],
  [-35.6836, 147.1095],
  [-35.6837, 147.1116],
  [-35.6871, 147.1377],
  [-35.6897, 147.1495],
  [-35.6931, 147.1763],
  [-35.6930, 147.1790],
  [-35.6907, 147.1895],
  [-35.6905, 147.1925],
  [-35.6902, 147.1965],
  [-35.6895, 147.1997],
  [-35.6893, 147.2020],
  [-35.6900, 147.2142],
  [-35.6893, 147.2273],
  [-35.6895, 147.2360],
  [-35.6894, 147.2401],
  [-35.6897, 147.2428],
  [-35.6919, 147.2489],
  [-35.6968, 147.2571],
  [-35.7018, 147.2709],
  [-35.7082, 147.2819],
  [-35.7087, 147.2833],
  [-35.7096, 147.2877],
  [-35.7106, 147.2904],
  [-35.7159, 147.3006],
  [-35.7163, 147.3025],
  [-35.7183, 147.3175]
];

export const holbrook: Line = {
  name: 'Holbrook',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1902-09-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-02-02',
          status: 'closed'
        }]
      }
    }
  ]
};
