import { Line } from "../../../trackTypes";

const sydenhamMarrickVill = [
  [-33.9160, 151.1641],
  [-33.9161, 151.1620],
  [-33.9152, 151.1587]
];

const marrickvillCampsie = [
  [-33.9152, 151.1587],
  [-33.9136, 151.1526],
  [-33.9117, 151.1486],
  [-33.9111, 151.1466],
  [-33.9110, 151.1450],
  [-33.9112, 151.1430],
  [-33.9109, 151.1406],
  [-33.9093, 151.1374],
  [-33.9091, 151.1361],
  [-33.9091, 151.1347],
  [-33.9096, 151.1333],
  [-33.9122, 151.1282],
  [-33.9126, 151.1270],
  [-33.9132, 151.1221],
  [-33.9127, 151.1201],
  [-33.9094, 151.1142],
  [-33.9089, 151.1129],
  [-33.9089, 151.1116],
  [-33.9107, 151.1006],
  [-33.9111, 151.0994],
  [-33.9117, 151.0985],
  [-33.9127, 151.0975]
];

const campsieBelmore = [
  [-33.9127, 151.0975],
  [-33.9131, 151.0971],
  [-33.9152, 151.0950],
  [-33.9161, 151.0936],
  [-33.9168, 151.0914],
  [-33.9171, 151.0887]
];

const belmoreBankstown = [
  [-33.9171, 151.0887],
  [-33.9177, 151.0837],
  [-33.9182, 151.0813],
  [-33.9245, 151.0624],
  [-33.9249, 151.0607],
  [-33.9254, 151.0558],
  [-33.9253, 151.0547],
  [-33.9246, 151.0527],
  [-33.9186, 151.0452],
  [-33.9179, 151.0439],
  [-33.9174, 151.0422],
  [-33.9173, 151.0406],
  [-33.9178, 151.0346]
];

const bankstownSeftonParkEastJunction = [
  [-33.9178, 151.0346],
  [-33.9180, 151.0317],
  [-33.9177, 151.0302],
  [-33.9171, 151.0291],
  [-33.9162, 151.0284],
  [-33.9151, 151.0281],
  [-33.9136, 151.0282],
  [-33.9127, 151.0280],
  [-33.9032, 151.0221],
  [-33.9024, 151.0218],
  [-33.9014, 151.0218],
  [-33.8974, 151.0225],
  [-33.8907, 151.0248],
  [-33.8901, 151.0249],
  [-33.8895, 151.0246],
  [-33.8890, 151.0240],
  [-33.8887, 151.0232]
];

const seftonParkEastJunctionSeftonParkNorthJunction = [
  [-33.8886, 151.0223],
  [-33.8881, 151.0215],
  [-33.8873, 151.0208],
  [-33.8865, 151.0208],
  [-33.8858, 151.0211],
  [-33.8850, 151.0220]
];

export const bankstown: Line = {
  name: 'Bankstown',
  state: 'NSW',
  segments: [
    {
      segments: [sydenhamMarrickVill],
      history: {
        opened: {
          date: '1895-02-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [marrickvillCampsie],
      history: {
        opened: {
          date: '1895-02-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1916-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [campsieBelmore],
      history: {
        opened: {
          date: '1895-02-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [belmoreBankstown],
      history: {
        opened: {
          date: '1909-04-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [bankstownSeftonParkEastJunction],
      history: {
        opened: {
          date: '1928-07-16',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1938-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [seftonParkEastJunctionSeftonParkNorthJunction],
      history: {
        opened: {
          date: '1924-10-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1938-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
