import { Line } from "../../../trackTypes";

const glenfieldLeppington = [
  [-33.9756, 150.8892],
  [-33.9762, 150.8881],
  [-33.9765, 150.8867],
  [-33.9764, 150.8855],
  [-33.9761, 150.8845],
  [-33.9742, 150.8800],
  [-33.9736, 150.8781],
  [-33.9684, 150.8550],
  [-33.9673, 150.8518],
  [-33.9638, 150.8465],
  [-33.9619, 150.8445],
  [-33.9598, 150.8434],
  [-33.9566, 150.8422],
  [-33.9545, 150.8408],
  [-33.9527, 150.8384],
  [-33.9519, 150.8358],
  [-33.9516, 150.8335],
  [-33.9520, 150.8310],
  [-33.9543, 150.8216],
  [-33.9549, 150.8181],
  [-33.9548, 150.8114],
  [-33.9546, 150.8095],
  [-33.9525, 150.7970],
  [-33.9518, 150.7894]
];

export const leppington: Line = {
  name: 'Leppington',
  state: 'NSW',
  segments: [
    {
      segments: [glenfieldLeppington],
      history: {
        opened: {
          date: '2015-12-13',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
