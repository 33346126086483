import { Line } from "../../trackTypes";

const kalgoorlieGoldenGate = [
  [-30.7412, 121.4719],
  [-30.7400, 121.4734],
  [-30.7399, 121.4740],
  [-30.7399, 121.4748],
  [-30.7402, 121.4754],
  [-30.7406, 121.4759],
  [-30.7435, 121.4780],
  [-30.7466, 121.4794],
  [-30.7500, 121.4809],
  [-30.7515, 121.4823],
  [-30.7632, 121.4935],
  [-30.7643, 121.4940],
  [-30.7719, 121.4963]
];

const goldenGateKamballie = [
  [-30.7719, 121.4963],
  [-30.7731, 121.4967],
  [-30.7738, 121.4974],
  [-30.7774, 121.5007],
  [-30.7818, 121.5034],
  [-30.7874, 121.5086]
];

const kamballieLakeside = [
  [-30.7874, 121.5086],
  [-30.7890, 121.5113],
  [-30.7903, 121.5123],
  [-30.7920, 121.5151],
  [-30.7944, 121.5194],
  [-30.7956, 121.5208],
  [-30.8040, 121.5289],
  [-30.8055, 121.5314]
];

const lakesideWhiteHope = [
  [-30.8055, 121.5314],
  [-30.8225, 121.5487],
  [-30.8429, 121.5595],
  [-30.8628, 121.5654],
  [-30.8732, 121.5656],
  [-30.9109, 121.5762],
  [-30.9187, 121.5796],
  [-30.9611, 121.5806],
  [-30.9879, 121.5862],
  [-31.0210, 121.6028],
  [-31.0411, 121.6226],
  [-31.0566, 121.6261],
  [-31.0811, 121.6426]
];

export const gnumballaLake: Line = {
  name: 'Gnumballa Lake',
  state: 'WA',
  segments: [
    {
      segments: [kalgoorlieGoldenGate],
      history: {
        opened: {
          date: '1897-11-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1900-01-01',
          tracks: 2
        }, {
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goldenGateKamballie],
      history: {
        opened: {
          date: '1897-11-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kamballieLakeside],
      history: {
        opened: {
          date: '1897-11-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lakesideWhiteHope],
      history: {
        opened: {
          date: '1925-06-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1925-06-17',
          status: 'closed'
        }]
      }
    }
  ]
};
