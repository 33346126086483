import { Line } from "../../../trackTypes";

const pakenhamEastNarNarGoon = [
  [-38.0832, 145.5271],
  [-38.0804, 145.5450],
  [-38.0802, 145.5469],
  [-38.0802, 145.5493],
  [-38.0815, 145.5717]
];

const narNarGoonTynong = [
  [-38.0815, 145.5717],
  [-38.0818, 145.5755],
  [-38.0847, 145.6255],
  [-38.0849, 145.6283]
];

const tynongBunyip = [
  [-38.0849, 145.6283],
  [-38.0862, 145.6511],
  [-38.0865, 145.6532],
  [-38.0871, 145.6565],
  [-38.0893, 145.6676],
  [-38.0968, 145.6960],
  [-38.0971, 145.6978],
  [-38.0986, 145.7162]
];

const bunyipLongwarry =  [
  [-38.0986, 145.7162],
  [-38.1007, 145.7426],
  [-38.1011, 145.7448],
  [-38.1018, 145.7472],
  [-38.1025, 145.7489],
  [-38.1131, 145.7714]
];

const longwarryDruin = [
  [-38.1131, 145.7714],
  [-38.1217, 145.7899],
  [-38.1221, 145.7910],
  [-38.1224, 145.7926],
  [-38.1225, 145.7941],
  [-38.1225, 145.7974],
  [-38.1225, 145.7989],
  [-38.1245, 145.8111],
  [-38.1245, 145.8125],
  [-38.1245, 145.8136],
  [-38.1242, 145.8152],
  [-38.1238, 145.8165],
  [-38.1226, 145.8196],
  [-38.1223, 145.8208],
  [-38.1222, 145.8215],
  [-38.1221, 145.8228],
  [-38.1221, 145.8240],
  [-38.1222, 145.8250],
  [-38.1224, 145.8258],
  [-38.1238, 145.8314],
  [-38.1241, 145.8322],
  [-38.1301, 145.8477],
  [-38.1304, 145.8482],
  [-38.1309, 145.8492],
  [-38.1317, 145.8502],
  [-38.1324, 145.8508],
  [-38.1337, 145.8519],
  [-38.1345, 145.8527],
  [-38.1351, 145.8536],
  [-38.1365, 145.8561]
];

const druinWarragul = [
  [-38.1365, 145.8561],
  [-38.1388, 145.8606],
  [-38.1396, 145.8626],
  [-38.1403, 145.8651],
  [-38.1411, 145.8691],
  [-38.1416, 145.8728],
  [-38.1420, 145.8765],
  [-38.1420, 145.8778],
  [-38.1419, 145.8806],
  [-38.1425, 145.8878],
  [-38.1428, 145.8892],
  [-38.1437, 145.8915],
  [-38.1509, 145.9062],
  [-38.1517, 145.9074],
  [-38.1529, 145.9090],
  [-38.1537, 145.9105],
  [-38.1565, 145.9174],
  [-38.1570, 145.9184],
  [-38.1575, 145.9192],
  [-38.1619, 145.9250],
  [-38.1624, 145.9257],
  [-38.1631, 145.9268],
  [-38.1637, 145.9284],
  [-38.1641, 145.9298],
  [-38.1649, 145.9329]
];

const morwellTraralgon = [
  [-38.2368, 146.3971],
  [-38.2414, 146.4138],
  [-38.2419, 146.4162],
  [-38.2419, 146.4179],
  [-38.2416, 146.4199],
  [-38.2358, 146.4388],
  [-38.2301, 146.4571],
  [-38.2292, 146.4594],
  [-38.2284, 146.4610],
  [-38.2271, 146.4630],
  [-38.2128, 146.4844],
  [-38.2121, 146.4857],
  [-38.2113, 146.4875],
  [-38.1985, 146.5393]
];

const stratfordJunctionAvonBridge = [
  [-37.9801, 147.0705],
  [-37.9764, 147.0766],
];

const avonBridgeStratford = [
  [-37.9708, 147.0809],
  [-37.9698, 147.0811],
  [-37.9670, 147.0817]
];

const avonBridgeOld = [
  [-37.9764, 147.0766],
  [-37.9754, 147.0785],
  [-37.9748, 147.0793],
  [-37.9743, 147.0798],
  [-37.9737, 147.0802],
  [-37.9728, 147.0805],
  [-37.9722, 147.0806],
  [-37.9708, 147.0809]
];

const avonBridgeNew = [
  [-37.9764, 147.0766],
  [-37.9754, 147.0779],
  [-37.9747, 147.0787],
  [-37.9740, 147.0793],
  [-37.9731, 147.0799],
  [-37.9724, 147.0803],
  [-37.9719, 147.0805],
  [-37.9708, 147.0809]
];

const saleStratfordJunction = [
  [-38.1018, 147.0569],
  [-38.1004, 147.0584],
  [-38.0998, 147.0588],
  [-38.0959, 147.0606],
  [-38.0946, 147.0610],
  [-38.0905, 147.0618],
  [-38.0898, 147.0619],
  [-38.0887, 147.0618],
  [-38.0849, 147.0610],
  [-38.0838, 147.0609],
  [-38.0827, 147.0611],
  [-38.0558, 147.0665],
  [-38.0545, 147.0666],
  [-38.0532, 147.0665],
  [-38.0523, 147.0661],
  [-38.0337, 147.0577],
  [-38.0327, 147.0574],
  [-38.0317, 147.0573],
  [-37.9851, 147.0664],
  [-37.9830, 147.0672],
  [-37.9814, 147.0686],
  [-37.9801, 147.0705]
];

const oldWarragulNilma = [
  [-38.1649, 145.9329],
  [-38.1660, 145.9367],
  [-38.1669, 145.9380],
  [-38.1679, 145.9394],
  [-38.1685, 145.9408],
  [-38.1690, 145.9427],
  [-38.1692, 145.9443],
  [-38.1691, 145.9457],
  [-38.1687, 145.9492],
  [-38.1683, 145.9519],
  [-38.1680, 145.9535],
  [-38.1680, 145.9549]
];

const warragulNilmaDeviation = [
  [-38.1649, 145.9329],
  [-38.1657, 145.9354],
  [-38.1674, 145.9421],
  [-38.1676, 145.9442],
  [-38.1680, 145.9549]
];

const nilmaYarragon = [
  [-38.1680, 145.9549],
  [-38.1682, 145.9610],
  [-38.1686, 145.9637],
  [-38.1691, 145.9652],
  [-38.1715, 145.9696],
  [-38.1824, 145.9905],
  [-38.1832, 145.9927],
  [-38.1845, 145.9974],
  [-38.1906, 146.0175],
  [-38.1968, 146.0367],
  [-38.1986, 146.0416],
  [-38.2016, 146.0509],
  [-38.2021, 146.0529],
  [-38.2024, 146.0546],
  [-38.2032, 146.0634]
];

const yarragonTrafalgar = [
  [-38.2032, 146.0634],
  [-38.2092, 146.1371],
  [-38.2093, 146.1402],
  [-38.2092, 146.1425],
  [-38.2073, 146.1548]
];

const trafalgarMoe = [
  [-38.2073, 146.1548],
  [-38.2025, 146.1858],
  [-38.2019, 146.1883],
  [-38.2014, 146.1900],
  [-38.2007, 146.1916],
  [-38.1952, 146.2026],
  [-38.1942, 146.2051],
  [-38.1773, 146.2578],
  [-38.1770, 146.2591],
  [-38.1768, 146.2600],
  [-38.1768, 146.2605]
];

const stratfordBairnsdale = [
  [-37.9670, 147.0817],
  [-37.9662, 147.0819],
  [-37.9654, 147.0823],
  [-37.9646, 147.0831],
  [-37.9642, 147.0837],
  [-37.9571, 147.0959],
  [-37.9561, 147.0979],
  [-37.9119, 147.1987],
  [-37.9113, 147.2003],
  [-37.8876, 147.2677],
  [-37.8862, 147.2730],
  [-37.8801, 147.3020],
  [-37.8791, 147.3056],
  [-37.8781, 147.3078],
  [-37.8296, 147.4043],
  [-37.8284, 147.4074],
  [-37.8276, 147.4105],
  [-37.8272, 147.4135],
  [-37.8249, 147.4363],
  [-37.8248, 147.4388],
  [-37.8249, 147.4405],
  [-37.8442, 147.5860],
  [-37.8441, 147.5877],
  [-37.8437, 147.5894],
  [-37.8304, 147.6197],
  [-37.8302, 147.6211],
  [-37.8300, 147.6218],
  [-37.8285, 147.6287],
  [-37.8283, 147.6298]
];

const moeDeviationPointStart = [
  [-38.1768, 146.2605],
  [-38.1767, 146.2619],
  [-38.1768, 146.2638],
  [-38.1772, 146.2658],
  [-38.1774, 146.2666],
  [-38.1778, 146.2676],
  [-38.1784, 146.2685],
  [-38.1800, 146.2711],
  [-38.1822, 146.2748],
  [-38.1868, 146.2822],
  [-38.1882, 146.2836],
  [-38.1894, 146.2844],
  [-38.1907, 146.2849],
  [-38.1927, 146.2855],
  [-38.1946, 146.2865],
  [-38.1958, 146.2876],
  [-38.1975, 146.2903],
  [-38.2029, 146.2979],
  [-38.2040, 146.3002],
  [-38.2048, 146.3029],
  [-38.2052, 146.3056],
  [-38.2051, 146.3075],
  [-38.2046, 146.3103],
  [-38.2042, 146.3152],
  [-38.2043, 146.3176],
  [-38.2048, 146.3193],
  [-38.2054, 146.3208],
  [-38.2067, 146.3231]
];

const moeDeviationEndMorwell = [
  [-38.2225, 146.3597],
  [-38.2312, 146.3778],
  [-38.2324, 146.3811],
  [-38.2368, 146.3971]
];

const moeMorwellOriginal = [
  [-38.2067, 146.3231],
  [-38.2092, 146.3280],
  [-38.2117, 146.3372],
  [-38.2124, 146.3385],
  [-38.2225, 146.3597]
];

const moeMorwellDeviation = [
  [-38.2067, 146.3231],
  [-38.2078, 146.3246],
  [-38.2092, 146.3256],
  [-38.2159, 146.3290],
  [-38.2173, 146.3300],
  [-38.2187, 146.3317],
  [-38.2198, 146.3341],
  [-38.2221, 146.3463],
  [-38.2223, 146.3487],
  [-38.2223, 146.3520],
  [-38.2219, 146.3554],
  [-38.2220, 146.3575],
  [-38.2225, 146.3597]
];

const traralgonSale = [
  [-38.1985, 146.5393],
  [-38.1941, 146.5571],
  [-38.1869, 146.5860],
  [-38.1701, 146.6859],
  [-38.1614, 146.7697],
  [-38.1604, 146.7745],
  [-38.1553, 146.7906],
  [-38.1545, 146.7972],
  [-38.1551, 146.8289],
  [-38.1541, 146.8349],
  [-38.1515, 146.8405],
  [-38.1130, 146.8911],
  [-38.1078, 146.9008],
  [-38.1057, 146.9056],
  [-38.1051, 146.9092],
  [-38.0976, 146.9821],
  [-38.0977, 146.9870],
  [-38.1046, 147.0323],
  [-38.1049, 147.0361],
  [-38.1041, 147.0516],
];

const saleOriginal = [
  [-38.1041, 147.0516],
  [-38.1041, 147.0541],
  [-38.1045, 147.0556],
  [-38.1053, 147.0569],
  [-38.1063, 147.0582],
  [-38.1067, 147.0590],
  [-38.1070, 147.0607],
  [-38.1069, 147.0649]
];

const saleStratfordExit = [
  [-38.1053, 147.0569],
  [-38.1043, 147.0566],
  [-38.1033, 147.0564],
  [-38.1026, 147.0566],
  [-38.1018, 147.0569]
];

const newSale = [
  [-38.1041, 147.0516],
  [-38.1037, 147.0531],
  [-38.1032, 147.0543],
  [-38.1032, 147.0543],
  [-38.1023, 147.0561],
  [-38.1018, 147.0569]
];

const bairnsdaleOrbost = [
  [-37.8283, 147.6298],
  [-37.8277, 147.6326],
  [-37.8271, 147.6376],
  [-37.8265, 147.6396],
  [-37.8246, 147.6422],
  [-37.8243, 147.6428],
  [-37.8237, 147.6458],
  [-37.8237, 147.6476],
  [-37.8254, 147.6622],
  [-37.8255, 147.6635],
  [-37.8250, 147.6990],
  [-37.8247, 147.7013],
  [-37.8242, 147.7035],
  [-37.8205, 147.7170],
  [-37.8182, 147.7269],
  [-37.8174, 147.7285],
  [-37.8150, 147.7322],
  [-37.8144, 147.7339],
  [-37.8129, 147.7407],
  [-37.8124, 147.7421],
  [-37.8098, 147.7473],
  [-37.8088, 147.7497],
  [-37.8073, 147.7555],
  [-37.8071, 147.7577],
  [-37.8074, 147.7599],
  [-37.8121, 147.7739],
  [-37.8123, 147.7757],
  [-37.8120, 147.7772],
  [-37.8113, 147.7786],
  [-37.8106, 147.7802],
  [-37.8104, 147.7821],
  [-37.8109, 147.7839],
  [-37.8121, 147.7857],
  [-37.8127, 147.7875],
  [-37.8127, 147.7889],
  [-37.8120, 147.7933],
  [-37.8111, 147.7945],
  [-37.8101, 147.7948],
  [-37.8089, 147.7947],
  [-37.8079, 147.7950],
  [-37.8070, 147.7959],
  [-37.8051, 147.7987],
  [-37.8044, 147.8000],
  [-37.8034, 147.8032],
  [-37.8032, 147.8055],
  [-37.8036, 147.8073],
  [-37.8045, 147.8098],
  [-37.8046, 147.8107],
  [-37.8045, 147.8119],
  [-37.8019, 147.8206],
  [-37.8009, 147.8224],
  [-37.7997, 147.8238],
  [-37.7927, 147.8297],
  [-37.7917, 147.8301],
  [-37.7905, 147.8299],
  [-37.7872, 147.8288],
  [-37.7856, 147.8278],
  [-37.7781, 147.8217],
  [-37.7750, 147.8193],
  [-37.7730, 147.8174],
  [-37.7719, 147.8170],
  [-37.7689, 147.8167],
  [-37.7676, 147.8172],
  [-37.7668, 147.8182],
  [-37.7659, 147.8201],
  [-37.7650, 147.8210],
  [-37.7639, 147.8212],
  [-37.7628, 147.8206],
  [-37.7621, 147.8195],
  [-37.7616, 147.8181],
  [-37.7609, 147.8172],
  [-37.7598, 147.8169],
  [-37.7587, 147.8169],
  [-37.7574, 147.8162],
  [-37.7560, 147.8145],
  [-37.7537, 147.8130],
  [-37.7435, 147.8096],
  [-37.7425, 147.8097],
  [-37.7415, 147.8103],
  [-37.7392, 147.8122],
  [-37.7379, 147.8124],
  [-37.7368, 147.8121],
  [-37.7270, 147.8065],
  [-37.7247, 147.8059],
  [-37.7193, 147.8055],
  [-37.7180, 147.8059],
  [-37.7124, 147.8101],
  [-37.7110, 147.8115],
  [-37.7102, 147.8128],
  [-37.7086, 147.8146],
  [-37.7073, 147.8159],
  [-37.7065, 147.8171],
  [-37.7062, 147.8184],
  [-37.7055, 147.8247],
  [-37.7056, 147.8262],
  [-37.7061, 147.8275],
  [-37.7084, 147.8318],
  [-37.7093, 147.8325],
  [-37.7115, 147.8328],
  [-37.7130, 147.8335],
  [-37.7139, 147.8346],
  [-37.7157, 147.8378],
  [-37.7199, 147.8459],
  [-37.7209, 147.8472],
  [-37.7251, 147.8499],
  [-37.7281, 147.8526],
  [-37.7303, 147.8554],
  [-37.7307, 147.8564],
  [-37.7307, 147.8575],
  [-37.7306, 147.8589],
  [-37.7310, 147.8601],
  [-37.7319, 147.8613],
  [-37.7322, 147.8622],
  [-37.7324, 147.8657],
  [-37.7320, 147.8670],
  [-37.7314, 147.8685],
  [-37.7313, 147.8719],
  [-37.7319, 147.8736],
  [-37.7332, 147.8744],
  [-37.7343, 147.8744],
  [-37.7352, 147.8747],
  [-37.7383, 147.8765],
  [-37.7397, 147.8781],
  [-37.7405, 147.8801],
  [-37.7403, 147.8824],
  [-37.7402, 147.8840],
  [-37.7406, 147.8854],
  [-37.7430, 147.8894],
  [-37.7443, 147.8939],
  [-37.7493, 147.9061],
  [-37.7523, 147.9120],
  [-37.7526, 147.9139],
  [-37.7533, 147.9202],
  [-37.7538, 147.9219],
  [-37.7574, 147.9296],
  [-37.7592, 147.9317],
  [-37.7597, 147.9336],
  [-37.7598, 147.9375],
  [-37.7594, 147.9391],
  [-37.7584, 147.9428],
  [-37.7583, 147.9450],
  [-37.7590, 147.9525],
  [-37.7588, 147.9552],
  [-37.7560, 147.9674],
  [-37.7550, 147.9696],
  [-37.7515, 147.9735],
  [-37.7506, 147.9750],
  [-37.7493, 147.9781],
  [-37.7488, 147.9804],
  [-37.7488, 147.9821],
  [-37.7490, 147.9863],
  [-37.7486, 147.9880],
  [-37.7476, 147.9893],
  [-37.7466, 147.9919],
  [-37.7465, 147.9955],
  [-37.7457, 147.9972],
  [-37.7442, 147.9991],
  [-37.7428, 148.0015],
  [-37.7407, 148.0040],
  [-37.7403, 148.0049],
  [-37.7401, 148.0064],
  [-37.7403, 148.0134],
  [-37.7397, 148.0183],
  [-37.7399, 148.0213],
  [-37.7418, 148.0301],
  [-37.7428, 148.0319],
  [-37.7446, 148.0334],
  [-37.7453, 148.0354],
  [-37.7449, 148.0375],
  [-37.7434, 148.0401],
  [-37.7429, 148.0421],
  [-37.7430, 148.0437],
  [-37.7440, 148.0479],
  [-37.7431, 148.0618],
  [-37.7419, 148.0645],
  [-37.7407, 148.0657],
  [-37.7391, 148.0665],
  [-37.7377, 148.0683],
  [-37.7348, 148.0759],
  [-37.7316, 148.0817],
  [-37.7312, 148.0841],
  [-37.7312, 148.0858],
  [-37.7306, 148.0886],
  [-37.7294, 148.0915],
  [-37.7290, 148.0928],
  [-37.7291, 148.0960],
  [-37.7295, 148.0983],
  [-37.7307, 148.1000],
  [-37.7320, 148.1010],
  [-37.7323, 148.1026],
  [-37.7316, 148.1065],
  [-37.7302, 148.1082],
  [-37.7273, 148.1093],
  [-37.7241, 148.1109],
  [-37.7232, 148.1119],
  [-37.7191, 148.1225],
  [-37.7188, 148.1241],
  [-37.7191, 148.1269],
  [-37.7189, 148.1283],
  [-37.7179, 148.1308],
  [-37.7179, 148.1334],
  [-37.7178, 148.1383],
  [-37.7195, 148.1433],
  [-37.7218, 148.1467],
  [-37.7225, 148.1482],
  [-37.7250, 148.1570],
  [-37.7266, 148.1603],
  [-37.7287, 148.1625],
  [-37.7296, 148.1629],
  [-37.7306, 148.1627],
  [-37.7317, 148.1620],
  [-37.7323, 148.1619],
  [-37.7336, 148.1626],
  [-37.7385, 148.1684],
  [-37.7395, 148.1689],
  [-37.7403, 148.1691],
  [-37.7413, 148.1697],
  [-37.7427, 148.1722],
  [-37.7454, 148.1752],
  [-37.7494, 148.1809],
  [-37.7501, 148.1831],
  [-37.7504, 148.1857],
  [-37.7517, 148.1906],
  [-37.7517, 148.1921],
  [-37.7528, 148.1964],
  [-37.7527, 148.1983],
  [-37.7518, 148.2003],
  [-37.7516, 148.2012],
  [-37.7515, 148.2026],
  [-37.7508, 148.2042],
  [-37.7502, 148.2061],
  [-37.7504, 148.2080],
  [-37.7521, 148.2120],
  [-37.7522, 148.2134],
  [-37.7517, 148.2149],
  [-37.7495, 148.2183],
  [-37.7485, 148.2209],
  [-37.7453, 148.2427],
  [-37.7456, 148.2609],
  [-37.7464, 148.2681],
  [-37.7475, 148.2711],
  [-37.7477, 148.2734],
  [-37.7471, 148.2756],
  [-37.7433, 148.2819],
  [-37.7428, 148.2837],
  [-37.7434, 148.2888],
  [-37.7429, 148.2910],
  [-37.7422, 148.2933],
  [-37.7421, 148.2948],
  [-37.7423, 148.2990],
  [-37.7417, 148.3015],
  [-37.7397, 148.3068],
  [-37.7394, 148.3098],
  [-37.7404, 148.3182],
  [-37.7432, 148.3310],
  [-37.7454, 148.3339],
  [-37.7472, 148.3343],
  [-37.7489, 148.3357],
  [-37.7512, 148.3422],
  [-37.7515, 148.3448],
  [-37.7515, 148.3463],
  [-37.7507, 148.3508],
  [-37.7490, 148.3542],
  [-37.7449, 148.3718],
  [-37.7447, 148.3869],
  [-37.7459, 148.4094],
  [-37.7453, 148.4119],
  [-37.7446, 148.4131],
  [-37.7426, 148.4162],
  [-37.7369, 148.4216],
  [-37.7359, 148.4230],
  [-37.7348, 148.4261],
  [-37.7337, 148.4271],
  [-37.7324, 148.4272],
  [-37.7289, 148.4251],
  [-37.7275, 148.4253],
  [-37.7266, 148.4263],
  [-37.7259, 148.4292],
  [-37.7262, 148.4330],
  [-37.7279, 148.4401],
  [-37.7279, 148.4425],
  [-37.7274, 148.4440],
  [-37.7269, 148.4446],
  [-37.7228, 148.4494],
  [-37.7216, 148.4501],
  [-37.7163, 148.4514]
];

export const laTrobeValley: Line = {
  name: 'La Trobe Valley',
  state: 'VIC',
  segments: [
    {
      segments: [pakenhamEastNarNarGoon],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-10-10',
          tracks: 2
        }, {
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2001-08-22',
          electrified: undefined
        }]
      }
    },
    {
      segments: [narNarGoonTynong],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-06-28',
          tracks: 2
        }, {
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2001-08-22',
          electrified: undefined
        }]
      }
    },
    {
      segments: [tynongBunyip],
      history: {
        opened: {
          date: '1877-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-08-19',
          tracks: 2
        }, {
          date: '1998-12-07',
          electrified: undefined
        }]
      }
    },
    {
      segments: [bunyipLongwarry],
      history: {
        opened: {
          date: '1878-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1998-12-07',
          electrified: undefined
        }]
      }
    },
    {
      segments: [longwarryDruin],
      history: {
        opened: {
          date: '1878-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-06-22',
          tracks: 2
        }, {
          date: '1954-07-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1998-12-07',
          electrified: undefined
        }]
      }
    },
    {
      segments: [druinWarragul],
      history: {
        opened: {
          date: '1878-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-06-22',
          tracks: 2
        }, {
          date: '1954-06-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1998-12-07',
          electrified: undefined
        }]
      }
    },
    {
      segments: [{
        date: '1952-08-14',
        original: [oldWarragulNilma],
        deviation: [warragulNilmaDeviation]
      }, nilmaYarragon],
      history: {
        opened: {
          date: '1878-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-10-05',
          tracks: 2
        }, {
          date: '1955-10-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-07-02',
          electrified: undefined
        }]
      }
    },
    {
      segments: [yarragonTrafalgar],
      history: {
        opened: {
          date: '1878-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-03-23',
          tracks: 2
        }, {
          date: '1955-10-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-07-02',
          electrified: undefined
        }]
      }
    },
    {
      segments: [trafalgarMoe],
      history: {
        opened: {
          date: '1878-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-06-27',
          tracks: 2
        }, {
          date: '1955-10-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-07-02',
          electrified: undefined
        }]
      }
    },
    {
      segments: [
        moeDeviationPointStart,
        {
          date: '1963-08-04',
          original: [moeMorwellOriginal],
          deviation: [moeMorwellDeviation]
        },
        moeDeviationEndMorwell
      ],
      history: {
        opened: {
          date: '1877-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-03-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-07-02',
          electrified: undefined
        }]
      }
    },
    {
      segments: [morwellTraralgon],
      history: {
        opened: {
          date: '1877-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-03-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1987-07-02',
          electrified: undefined
        }]
      }
    },
    {
      segments: [traralgonSale],
      history: {
        opened: {
          date: '1877-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [saleOriginal],
      history: {
        opened: {
          date: '1877-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [saleStratfordExit],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newSale],
      history: {
        opened: {
          date: '1983-11-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        stratfordJunctionAvonBridge,
        {
          date: '2020-11-28',
          original: [avonBridgeOld],
          deviation: [avonBridgeNew]
        },
        avonBridgeStratford
      ],
      history: {
        opened: {
          date: '1887-11-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [saleStratfordJunction],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [stratfordBairnsdale],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bairnsdaleOrbost],
      history: {
        opened: {
          date: '1916-04-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-08-24',
          status: 'closed'
        }]
      }
    }
  ]
}
