import { Line } from "../../trackTypes";

const line = [
  [-32.2819, 150.9349],
  [-32.2812, 150.9363],
  [-32.2802, 150.9371],
  [-32.2762, 150.9381],
  [-32.2748, 150.9379],
  [-32.2716, 150.9392],
  [-32.2705, 150.9393],
  [-32.2604, 150.9382],
  [-32.2560, 150.9397],
  [-32.2524, 150.9402]
];

export const muswellbrook: Line = {
  name: 'Muswellbrook',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1944-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1997-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
