import { Line } from "../../trackTypes";

const theRockLockhart = [
  [-35.2733, 147.1111],
  [-35.2732, 147.1094],
  [-35.2728, 147.1080],
  [-35.2680, 147.0998],
  [-35.2577, 147.0767],
  [-35.2562, 147.0739],
  [-35.2558, 147.0723],
  [-35.2559, 147.0706],
  [-35.2564, 147.0686],
  [-35.2610, 146.9870],
  [-35.2605, 146.9844],
  [-35.2530, 146.9662],
  [-35.2517, 146.9566],
  [-35.2518, 146.9552],
  [-35.2522, 146.9537],
  [-35.2655, 146.9304],
  [-35.2661, 146.9285],
  [-35.2662, 146.9263],
  [-35.2537, 146.8263],
  [-35.2539, 146.8239],
  [-35.2574, 146.8148],
  [-35.2578, 146.8132],
  [-35.2576, 146.8117],
  [-35.2568, 146.8097],
  [-35.2564, 146.8077],
  [-35.2580, 146.7964],
  [-35.2584, 146.7902],
  [-35.2581, 146.7847],
  [-35.2571, 146.7812],
  [-35.2278, 146.7283],
  [-35.2267, 146.7247],
  [-35.2256, 146.7159]
];

const lockhartBoreeCreek = [
  [-35.2256, 146.7159],
  [-35.2246, 146.7082],
  [-35.2233, 146.7051],
  [-35.2061, 146.6842],
  [-35.2045, 146.6829],
  [-35.2022, 146.6821],
  [-35.1974, 146.6815],
  [-35.1962, 146.6810],
  [-35.1952, 146.6800],
  [-35.1932, 146.6766],
  [-35.1913, 146.6747],
  [-35.1889, 146.6735],
  [-35.1837, 146.6726],
  [-35.1812, 146.6713],
  [-35.1630, 146.6555],
  [-35.1593, 146.6542],
  [-35.1546, 146.6541],
  [-35.1530, 146.6538],
  [-35.1513, 146.6530],
  [-35.1500, 146.6528],
  [-35.1485, 146.6533],
  [-35.1460, 146.6544],
  [-35.1439, 146.6545],
  [-35.1415, 146.6539],
  [-35.1395, 146.6524],
  [-35.1114, 146.6189],
  [-35.1106, 146.6177],
  [-35.1097, 146.6154],
  [-35.1095, 146.6138],
  [-35.1111, 146.5977]
];

const boreeCreekYuluma = [
  [-35.1111, 146.5977],
  [-35.1115, 146.5946],
  [-35.1177, 146.5661],
  [-35.1188, 146.5632],
  [-35.1578, 146.5035],
  [-35.1587, 146.5005],
  [-35.1653, 146.4900]
];

const yulumaUrana = [
  [-35.1653, 146.4900],
  [-35.1670, 146.4872],
  [-35.1695, 146.4770],
  [-35.1830, 146.4510],
  [-35.1863, 146.4482],
  [-35.1961, 146.4463],
  [-35.2020, 146.4435],
  [-35.2035, 146.4422],
  [-35.2074, 146.4357],
  [-35.2094, 146.4334],
  [-35.2222, 146.4253],
  [-35.2317, 146.4143],
  [-35.2350, 146.4069],
  [-35.2364, 146.4057],
  [-35.2383, 146.4052],
  [-35.2400, 146.4043],
  [-35.2415, 146.4021],
  [-35.2433, 146.4009],
  [-35.2468, 146.4004],
  [-35.2490, 146.3988],
  [-35.2573, 146.3870],
  [-35.2623, 146.3771],
  [-35.2668, 146.3630],
  [-35.2690, 146.3594],
  [-35.2723, 146.3573],
  [-35.2735, 146.3559],
  [-35.2744, 146.3537],
  [-35.2752, 146.3526],
  [-35.2775, 146.3511],
  [-35.2794, 146.3493],
  [-35.2867, 146.3373],
  [-35.2946, 146.3156],
  [-35.2957, 146.3135],
  [-35.3182, 146.2833],
  [-35.3203, 146.2813],
  [-35.3294, 146.2746]
];

const uranaOaklands = [
  [-35.3294, 146.2746],
  [-35.3316, 146.2729],
  [-35.3338, 146.2703],
  [-35.3352, 146.2692],
  [-35.3996, 146.2379],
  [-35.4021, 146.2370],
  [-35.4623, 146.2257],
  [-35.4832, 146.2218],
  [-35.4860, 146.2206],
  [-35.4984, 146.2113],
  [-35.5336, 146.1923],
  [-35.5436, 146.1843],
  [-35.5452, 146.1825],
  [-35.5463, 146.1805],
  [-35.5469, 146.1778],
  [-35.5472, 146.1740],
  [-35.5478, 146.1720]
];

const oaklandsVicBorder = [
  [-35.5478, 146.1720],
  [-35.5508, 146.1669],
  [-35.5513, 146.1662],
  [-35.5692, 146.1524],
  [-35.5704, 146.1518],
  [-35.5718, 146.1517],
  [-35.5745, 146.1526],
  [-35.5803, 146.1579],
  [-35.5819, 146.1590],
  [-35.5841, 146.1593],
  [-35.5997, 146.1562],
  [-35.6031, 146.1566],
  [-35.6193, 146.1633],
  [-35.6218, 146.1643],
  [-35.6625, 146.1714],
  [-35.6647, 146.1713],
  [-35.6949, 146.1652],
  [-35.6967, 146.1650],
  [-35.7008, 146.1652],
  [-35.7078, 146.1638],
  [-35.7096, 146.1631],
  [-35.7174, 146.1580],
  [-35.7194, 146.1571],
  [-35.7418, 146.1529],
  [-35.7431, 146.1526],
  [-35.7593, 146.1454],
  [-35.7939, 146.1387],
  [-35.8103, 146.1331],
  [-35.8122, 146.1322],
  [-35.8142, 146.1300],
  [-35.8153, 146.1276],
  [-35.8158, 146.1249],
  [-35.8156, 146.1111],
  [-35.8162, 146.0954],
  [-35.8125, 146.0656],
  [-35.8129, 146.0614],
  [-35.8172, 146.0481],
  [-35.8182, 146.0456],
  [-35.8234, 146.0386],
  [-35.8252, 146.0371],
  [-35.8269, 146.0365],
  [-35.8852, 146.0266],
  [-35.8913, 146.0251],
  [-35.9073, 146.0221],
  [-35.9165, 146.0192],
  [-35.9758, 146.0080],
  [-35.9783, 146.0071],
  [-35.9847, 146.0008],
  [-35.9861, 146.0001],
  [-35.9995, 145.9995],
  [-36.0043, 145.9988],
  [-36.0055, 145.9990],
  [-36.0088, 146.0000]
];

export const oaklands: Line = {
  name: 'Oaklands (NSW)',
  state: 'NSW',
  segments: [
    {
      segments: [oaklandsVicBorder],
      history: {
        opened: {
          date: '1938-08-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-11-09',
          status: 'closed'
        }, {
          date: '2011-06-26',
          status: 'open',
          gauge: 'standard'
        }],
      }
    },
    {
      segments: [theRockLockhart],
      history: {
        opened: {
          date: '1901-07-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [lockhartBoreeCreek],
      history: {
        opened: {
          date: '1910-12-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [boreeCreekYuluma],
      history: {
        opened: {
          date: '1910-12-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-11-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yulumaUrana],
      history: {
        opened: {
          date: '1911-12-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-11-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [uranaOaklands],
      history: {
        opened: {
          date: '1912-12-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982=11-24',
          status: 'closed'
        }]
      }
    }
  ]
};
