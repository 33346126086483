import { Line } from "../../../trackTypes";

const duttonParkAlbertStreet = [
  [-27.4997, 153.0290],
  [-27.4983, 153.0309],
  [-27.4976, 153.0314],
  [-27.4968, 153.0322],
  [-27.4958, 153.0334],
  [-27.4952, 153.0338],
  [-27.4933, 153.0341]
];

const albertStreetVultureStreetTunnel = [
  [-27.4933, 153.0341],
  [-27.4904, 153.0351],
  [-27.4876, 153.0372],
  [-27.4870, 153.0370],
  [-27.4866, 153.0366],
  [-27.4860, 153.0351],
  [-27.4857, 153.0317],
  [-27.4854, 153.0313],
  [-27.4846, 153.0309],
  [-27.4840, 153.0308]
];

const vultureStreetTunnel = [
  [-27.4840, 153.0308],
  [-27.4836, 153.0309],
  [-27.4830, 153.0309]
];

const vultureStreetTunnelStanleyStreet = [
  [-27.4830, 153.0309],
  [-27.4825, 153.0306],
  [-27.4823, 153.0299],
  [-27.4820, 153.0279],
  [-27.4821, 153.0273],
  [-27.4821, 153.0263]
];

const stanleyStreetStateFishmarketExtension = [
  [-27.4821, 153.0263],
  [-27.4820, 153.0258],
  [-27.4812, 153.0251],
  [-27.4796, 153.0241],
  [-27.4785, 153.0239],
  [-27.4777, 153.0232],
  [-27.4768, 153.0221],
  [-27.4736, 153.0199]
];

const clevelandJunction = [
  [-27.4962, 153.0349],
  [-27.4956, 153.0341],
  [-27.4952, 153.0338]
];

export const woolloongabba: Line = {
  name: 'Woolloongabba',
  state: 'QLD',
  segments: [
    {
      segments: [duttonParkAlbertStreet],
      history: {
        opened: {
          date: '1884-06-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        albertStreetVultureStreetTunnel,
        {
          name: 'Vulture Street Tunnel',
          segment: vultureStreetTunnel,
          type: 'tunnel'
        },
        vultureStreetTunnelStanleyStreet
      ],
      history: {
        opened: {
          date: '1884-06-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stanleyStreetStateFishmarketExtension],
      history: {
        opened: {
          date: '1897-03-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [clevelandJunction],
      history: {
        opened: {
          date: '1891-12-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
