import { Line } from "../../../trackTypes";

const romaStreetCentralNo1Tunnel = [
  [-27.4650, 153.0190],
  [-27.4656, 153.0203],
  [-27.4663, 153.0213],
  [-27.4667, 153.0221]
];

const centralNo1Tunnel = [
  [-27.4667, 153.0221],
  [-27.4668, 153.0230],
  [-27.4668, 153.0241]
];

const centralNo1TunnelCentral = [
  [-27.4668, 153.0241],
  [-27.4664, 153.0252],
  [-27.4654, 153.0266]
];

const centralCentralNo2Tunnel = [
  [-27.4654, 153.0266],
  [-27.4650, 153.0271],
  [-27.4647, 153.0274]
];

const centralNo2Tunnel = [
  [-27.4647, 153.0274],
  [-27.4609, 153.0299],
  [-27.4590, 153.0311]
];

const centralNo2TunnelBowenHillsJunction = [
  [-27.4590, 153.0311],
  [-27.4572, 153.0326],
  [-27.4539, 153.0358],
  [-27.4534, 153.0361],
  [-27.4510, 153.0373],
  [-27.4498, 153.0377],
  [-27.4485, 153.0378],
  [-27.4473, 153.0377],
  [-27.4446, 153.0375],
  [-27.4430, 153.0375],
  [-27.4425, 153.0376]
];

const bowenHillsJunction = [
  [-27.4446, 153.0375],
  [-27.4442, 153.0372],
  [-27.4440, 153.0366],
  [-27.4441, 153.0360]
];

const romaStreetBowenJunction = [
  [-27.4650, 153.0190],
  [-27.4642, 153.0169],
  [-27.4640, 153.0165],
  [-27.4635, 153.0161],
  [-27.4606, 153.0151],
  [-27.4598, 153.0151],
  [-27.4593, 153.0152],
  [-27.4589, 153.0154],
  [-27.4581, 153.0161],
  [-27.4576, 153.0171],
  [-27.4574, 153.0201],
  [-27.4569, 153.0219],
  [-27.4561, 153.0230],
  [-27.4551, 153.0237],
  [-27.4534, 153.0245],
  [-27.4525, 153.0252],
  [-27.4518, 153.0262],
  [-27.4506, 153.0299],
  [-27.4501, 153.0311],
  [-27.4487, 153.0328],
  [-27.4473, 153.0337],
  [-27.4455, 153.0342],
  [-27.4450, 153.0345],
  [-27.4446, 153.0350],
  [-27.4441, 153.0360],
  [-27.4433, 153.0370],
  [-27.4425, 153.0376]
];

const romaStreetJunction = [
  [-27.4635, 153.0161],
  [-27.4641, 153.0162],
  [-27.4647, 153.0160],
  [-27.46544, 153.0153]
];

const bownenJunctionFernyGroveJunction = [
  [-27.4425, 153.0376],
  [-27.4402, 153.0389]
];

const fernyGroveJunctionEagleJunction = [
  [-27.4402, 153.0389],
  [-27.4386, 153.0399],
  [-27.4372, 153.0403],
  [-27.4350, 153.0402],
  [-27.4319, 153.0393],
  [-27.4309, 153.0392],
  [-27.4296, 153.0398],
  [-27.4281, 153.0414],
  [-27.4272, 153.0417],
  [-27.4226, 153.0426],
  [-27.4203, 153.0432],
  [-27.4177, 153.0442],
  [-27.4170, 153.0447],
  [-27.4166, 153.0453],
  [-27.4154, 153.0486],
  [-27.4153, 153.0498]
];

const eagleJunctionNorthgate = [
  [-27.4153, 153.0498],
  [-27.4155, 153.0510],
  [-27.4153, 153.0525],
  [-27.4147, 153.0534],
  [-27.4139, 153.0540],
  [-27.4129, 153.0547],
  [-27.4109, 153.0563],
  [-27.4049, 153.0610],
  [-27.4036, 153.0615],
  [-27.4005, 153.0620],
  [-27.3994, 153.0624],
  [-27.3988, 153.0631],
  [-27.3962, 153.0671],
  [-27.3951, 153.0682],
  [-27.3940, 153.0689],
  [-27.3931, 153.0692],
  [-27.3922, 153.0694]
];

const northgateLawnton = [
  [-27.3922, 153.0694],
  [-27.3914, 153.0693],
  [-27.3906, 153.0688],
  [-27.3797, 153.0606],
  [-27.3778, 153.0587],
  [-27.3706, 153.0502],
  [-27.3657, 153.0426],
  [-27.3645, 153.0412],
  [-27.3590, 153.0369],
  [-27.3575, 153.0363],
  [-27.3555, 153.0358],
  [-27.3544, 153.0353],
  [-27.3535, 153.0340],
  [-27.3520, 153.0313],
  [-27.3511, 153.0302],
  [-27.3496, 153.0296],
  [-27.3466, 153.0287],
  [-27.3448, 153.0278],
  [-27.3336, 153.0193],
  [-27.3234, 153.0139],
  [-27.3224, 153.0131],
  [-27.3203, 153.0103],
  [-27.3199, 153.0092],
  [-27.3187, 152.9998],
  [-27.3166, 152.9940],
  [-27.3149, 152.9917],
  [-27.3124, 152.9899],
  [-27.2815, 152.9809]
];

const lawntonPetrie = [
  [-27.2815, 152.9809],
  [-27.2779, 152.9799],
  [-27.2741, 152.9796],
  [-27.2726, 152.9794],
  [-27.2719, 152.9794],
  [-27.2710, 152.9799],
  [-27.2700, 152.9806]
];

const petriekallangurDeviationStart = [
  [-27.2700, 152.9806],
  [-27.2682, 152.9819],
  [-27.2673, 152.9825],
  [-27.2660, 152.9826],
  [-27.2652, 152.9822],
  [-27.2645, 152.9814],
  [-27.2635, 152.9790],
  [-27.2626, 152.9777],
  [-27.2618, 152.9769],
  [-27.2601, 152.9758],
  [-27.2586, 152.9752],
  [-27.2578, 152.9750]
];

const kallangurOriginal = [
  [-27.2578, 152.9750],
  [-27.2571, 152.9752],
  [-27.2554, 152.9761],
  [-27.2549, 152.9762],
  [-27.2536, 152.9761],
  [-27.2525, 152.9760],
  [-27.2518, 152.9762],
  [-27.2499, 152.9771],
  [-27.2488, 152.9773],
  [-27.2479, 152.9770],
  [-27.2467, 152.9759],
  [-27.2458, 152.9754],
  [-27.2452, 152.9753],
  [-27.2435, 152.9754]
];

const kallangurDeviation = [
  [-27.2578, 152.9750],
  [-27.2570, 152.9749],
  [-27.2549, 152.9751],
  [-27.2505, 152.9763],
  [-27.2473, 152.9763],
  [-27.2451, 152.9757],
  [-27.2435, 152.9754]
];

const kallangurDeviationEndDakabinDeviationStart = [
  [-27.2435, 152.9754],
  [-27.2430, 152.9754],
  [-27.2414, 152.9756],
  [-27.2353, 152.9769],
  [-27.2334, 152.9767],
  [-27.2271, 152.9746],
  [-27.2256, 152.9738]
];

const dakabinOld = [
  [-27.2256, 152.9738],
  [-27.2248, 152.9726],
  [-27.2240, 152.9711],
  [-27.2234, 152.9705],
  [-27.2223, 152.9701],
  [-27.2203, 152.9699],
  [-27.2192, 152.9698]
];

const dakabinDeviation = [
  [-27.2256, 152.9738],
  [-27.2250, 152.9734],
  [-27.2226, 152.9714],
  [-27.2211, 152.9705],
  [-27.2192, 152.9698]
];

const dakabinDeviationEndCaboolture = [
  [-27.2192, 152.9698],
  [-27.2183, 152.9695],
  [-27.2166, 152.9685],
  [-27.2148, 152.9669],
  [-27.2131, 152.9659],
  [-27.2113, 152.9655],
  [-27.2096, 152.9656],
  [-27.2082, 152.9652],
  [-27.2071, 152.9646],
  [-27.2047, 152.9627],
  [-27.2038, 152.9617],
  [-27.2014, 152.9589],
  [-27.2002, 152.9581],
  [-27.1985, 152.9576],
  [-27.1944, 152.9576],
  [-27.1933, 152.9574],
  [-27.1828, 152.9527],
  [-27.1808, 152.9525],
  [-27.1717, 152.9542],
  [-27.1702, 152.9548],
  [-27.1676, 152.9563],
  [-27.1660, 152.9568],
  [-27.1614, 152.9573],
  [-27.1462, 152.9588],
  [-27.1387, 152.9609],
  [-27.1367, 152.9611],
  [-27.1347, 152.9609],
  [-27.1258, 152.9582],
  [-27.1239, 152.9580],
  [-27.1212, 152.9579],
  [-27.1182, 152.9569],
  [-27.1148, 152.9545],
  [-27.1128, 152.9536],
  [-27.1099, 152.9525],
  [-27.1069, 152.9519],
  [-27.1045, 152.9519],
  [-27.0987, 152.9523],
  [-27.0975, 152.9526],
  [-27.0917, 152.9552],
  [-27.0893, 152.9557],
  [-27.0872, 152.9555],
  [-27.0828, 152.9537]
];

export const northCoastBrisbaneCaboolture: Line = {
  name: 'North Coast (Brisbane - Caboolture)',
  state: 'QLD',
  segments: [
    {
      segments: [
        romaStreetCentralNo1Tunnel,
        {
          name: 'Central No 1 Tunnel',
          segment: centralNo1Tunnel,
          type: 'tunnel'
        },
        centralNo1TunnelCentral
      ],
      history: {
        opened: {
          date: '1889-08-18',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1996-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [
        centralCentralNo2Tunnel,
        {
          name: 'Central No 2 Tunnel',
          segment: centralNo2Tunnel,
          type: 'tunnel'
        },
        centralNo2TunnelBowenHillsJunction
      ],
      history: {
        opened: {
          date: '1890-11-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1996-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [bowenHillsJunction],
      history: {
        opened: {
          date: '1890-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [romaStreetBowenJunction],
      history: {
        opened: {
          date: '1882-05-11',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-03-03',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [romaStreetJunction],
      history: {
        opened: {
          date: '1882-05-11',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-03-03',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [bownenJunctionFernyGroveJunction],
      history: {
        opened: {
          date: '1882-05-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-04-01',
          tracks: 2
        }, {
          date: '1996-01-01',
          tracks: 4
        } ,{
          date: '1979-11-17',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [fernyGroveJunctionEagleJunction],
      history: {
        opened: {
          date: '1882-05-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-04-01',
          tracks: 2
        }, {
          date: '1996-01-01',
          tracks: 4
        } ,{
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [eagleJunctionNorthgate],
      history: {
        opened: {
          date: '1882-05-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1890-08-17',
          tracks: 2
        }, {
          date: '1996-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [northgateLawnton],
      history: {
        opened: {
          date: '1888-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-04-23',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1917-01-01',
          tracks: 2
        }, {
          date: '2001-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [lawntonPetrie],
      history: {
        opened: {
          date: '1888-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-04-23',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1917-01-01',
          tracks: 2
        }, {
          date: '2016-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [
        petriekallangurDeviationStart,
        {
          date: '1986-06-28',
          original: [kallangurOriginal],
          deviation: [kallangurDeviation]
        },
        kallangurDeviationEndDakabinDeviationStart,
        {
          date: '1986-06-28',
          original: [dakabinOld],
          deviation: [dakabinDeviation]
        },
        dakabinDeviationEndCaboolture
      ],
      history: {
        opened: {
          date: '1888-06-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-06-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1917-01-01',
          tracks: 2
        }]
      }
    }
  ]
}
