import { Line } from "../../../trackTypes";

const washdykeNorthJunction = [
  [-44.3532, 171.2457],
  [-44.3535, 171.2452],
  [-44.3537, 171.2445],
  [-44.3538, 171.2434]
];

const washdykeKeanesCrossing = [
  [-44.3557, 171.2449],
  [-44.3548, 171.2447],
  [-44.3543, 171.2443],
  [-44.3538, 171.2434],
  [-44.3529, 171.2383],
  [-44.3525, 171.2370],
  [-44.3520, 171.2362],
  [-44.3359, 171.2240],
  [-44.3079, 171.2001],
  [-44.2894, 171.1873],
  [-44.2887, 171.1866],
  [-44.2702, 171.1590],
  [-44.2695, 171.1575],
  [-44.2679, 171.1524]
];

const keanesCrossingPleasantPoint = [
  [-44.2679, 171.1524],
  [-44.2610, 171.1308]
];

const pleasantPointAlbury = [
  [-44.2610, 171.1308],
  [-44.2606, 171.1295],
  [-44.2603, 171.1284],
  [-44.2606, 171.1201],
  [-44.2641, 171.0993],
  [-44.2827, 171.0330],
  [-44.2833, 171.0311],
  [-44.2837, 171.0303],
  [-44.2880, 171.0255],
  [-44.2887, 171.0244],
  [-44.3000, 170.9968],
  [-44.3027, 170.9873],
  [-44.3057, 170.9673],
  [-44.3099, 170.9511],
  [-44.3099, 170.9489],
  [-44.3094, 170.9472],
  [-44.3023, 170.9364],
  [-44.2950, 170.9275],
  [-44.2893, 170.9233],
  [-44.2885, 170.9223],
  [-44.2860, 170.9190],
  [-44.2810, 170.9111],
  [-44.2612, 170.8922],
  [-44.2600, 170.8916],
  [-44.2453, 170.8889],
  [-44.2400, 170.8866],
  [-44.2382, 170.8848],
  [-44.2362, 170.8822],
  [-44.2338, 170.8781],
  [-44.2316, 170.8759],
  [-44.2299, 170.8751]
];

const alburyWinscombe = [
  [-44.2299, 170.8751],
  [-44.2278, 170.8738],
  [-44.2241, 170.8732],
  [-44.2206, 170.8723],
  [-44.2197, 170.8723],
  [-44.2167, 170.8728],
  [-44.2159, 170.8728],
  [-44.2049, 170.8696],
  [-44.2026, 170.8685],
  [-44.1959, 170.8665],
  [-44.1920, 170.8647],
  [-44.1865, 170.8637],
  [-44.1851, 170.8637],
  [-44.1823, 170.8630],
  [-44.1787, 170.8605],
  [-44.1764, 170.8594],
  [-44.1696, 170.8572],
  [-44.1658, 170.8567],
  [-44.1562, 170.8527],
  [-44.1547, 170.8518],
  [-44.1516, 170.8493],
  [-44.1504, 170.8487],
  [-44.1485, 170.8483],
  [-44.1470, 170.8474],
  [-44.1442, 170.8443],
  [-44.1429, 170.8436],
  [-44.1389, 170.8431],
  [-44.1369, 170.8432],
  [-44.1349, 170.8424],
  [-44.1335, 170.8422],
  [-44.1318, 170.8425],
  [-44.1285, 170.8442]
];

const winscombeFairlie = [
  [-44.1285, 170.8442],
  [-44.1270, 170.8448],
  [-44.1263, 170.8447],
  [-44.1241, 170.8430],
  [-44.1193, 170.8361],
  [-44.1174, 170.8345],
  [-44.1085, 170.8307],
  [-44.0979, 170.8291],
  [-44.0972, 170.8287],
  [-44.0930, 170.8233]
];

export const fairlie: Line = {
  name: 'Fairlie',
  state: 'NZ',
  segments: [
    {
      segments: [washdykeNorthJunction],
      history: {
        opened: {
          date: '1875-12-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-03-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [washdykeKeanesCrossing],
      history: {
        opened: {
          date: '1875-12-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-03-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [keanesCrossingPleasantPoint],
      history: {
        opened: {
          date: '1875-12-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pleasantPointAlbury],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-03-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [alburyWinscombe],
      history: {
        opened: {
          date: '1883-08-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-03-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [winscombeFairlie],
      history: {
        opened: {
          date: '1884-01-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-03-02',
          status: 'closed'
        }]
      }
    }
  ]
}
