import { Line } from "../../../trackTypes";

const junction = [
  [-33.9285, 151.1524],
  [-33.9285, 151.1540],
  [-33.9287, 151.1553]
];

const line = [
  [-33.9287, 151.1553],
  [-33.9293, 151.1567],
  [-33.9318, 151.1607],
  [-33.9327, 151.1619],
  [-33.9337, 151.1629],
  [-33.9342, 151.1639],
  [-33.9356, 151.1679],
  [-33.9358, 151.1693],
  [-33.9356, 151.1706],
  [-33.9352, 151.1718],
  [-33.9340, 151.1743],
  [-33.9334, 151.1762],
  [-33.9333, 151.1779],
  [-33.9337, 151.1819],
  [-33.9336, 151.1834],
  [-33.9328, 151.1851],
  [-33.9320, 151.1859],
  [-33.9309, 151.1865],
  [-33.9276, 151.1874],
  [-33.9269, 151.1874],
  [-33.9262, 151.1873],
  [-33.9251, 151.1871],
  [-33.9229, 151.1872],
  [-33.9192, 151.1880],
  [-33.9178, 151.1887],
  [-33.9089, 151.1968],
  [-33.9081, 151.1979],
  [-33.9076, 151.1991],
  [-33.9072, 151.2005],
  [-33.9066, 151.2019],
  [-33.9056, 151.2029],
  [-33.9042, 151.2036],
  [-33.9024, 151.2034],
  [-33.9010, 151.2029],
  [-33.8962, 151.2017],
  [-33.8948, 151.2017],
  [-33.8891, 151.2028],
  [-33.8881, 151.2033]
];

const junctionTwo = [
  [-33.8881, 151.2033],
  [-33.8874, 151.2038],
  [-33.8858, 151.2055]
];

export const airportLink: Line = {
  name: 'Airport Link',
  state: 'NSW',
  segments: [
    {
      segments: [
        junction, {
          segment: line,
          type: 'tunnel'
        }, junctionTwo
      ],
      history: {
        opened: {
          date: '2000-05-21',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
