import { Line } from "../../../../trackTypes";

const miltonTennisCourts = [
  [-27.46622, 153.01281],
  [-27.46616, 153.01267],
  [-27.46615, 153.01256],
  [-27.46615, 153.01244],
  [-27.46619, 153.01230],
  [-27.46630, 153.01209],
  [-27.46636, 153.01195],
  [-27.46732, 153.00915]
];

const toowong = [
  [-27.46732, 153.00915],
  [-27.46903, 153.00406],
  [-27.47023, 153.00057],
  [-27.47038, 153.00021],
  [-27.47054, 152.99994],
  [-27.47405, 152.99531],
  [-27.47846, 152.98949],
  [-27.47852, 152.98934],
  [-27.47856, 152.98916],
  [-27.47860, 152.98638],
  [-27.47857, 152.98586],
  [-27.47825, 152.98451],
  [-27.47828, 152.98298],
  [-27.47837, 152.98282],
  [-27.47852, 152.98271],
  [-27.47989, 152.98264],
  [-27.48237, 152.98216],
  [-27.48253, 152.98214],
  [-27.48320, 152.98225],
  [-27.48328, 152.98231],
  [-27.48333, 152.98242],
  [-27.48354, 152.98374]
];

export const west4: Line = {
  name: 'West 4',
  state: 'QLD',
  segments: [
    {
      segments: [miltonTennisCourts],
      history: {
        opened: {
          date: '1904-07-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1964-07-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [toowong],
      history: {
        opened: {
          date: '1904-07-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-07-02',
          status: 'closed'
        }]
      }
    }
  ]
}
