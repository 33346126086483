import { Line } from "../../../trackTypes";

const greymouthHokitika = [
  [-42.4483, 171.2145],
  [-42.4498, 171.2109],
  [-42.4519, 171.2074],
  [-42.4598, 171.1968],
  [-42.4624, 171.1944],
  [-42.4722, 171.1878],
  [-42.4806, 171.1834],
  [-42.4899, 171.1786],
  [-42.5131, 171.1675],
  [-42.5370, 171.1560],
  [-42.5427, 171.1513],
  [-42.5472, 171.1486],
  [-42.5489, 171.1468],
  [-42.5532, 171.1416],
  [-42.5548, 171.1408],
  [-42.5566, 171.1409],
  [-42.5578, 171.1420],
  [-42.5586, 171.1430],
  [-42.5597, 171.1440],
  [-42.5634, 171.1464],
  [-42.5651, 171.1468],
  [-42.5665, 171.1463],
  [-42.5729, 171.1405],
  [-42.5857, 171.1290],
  [-42.5867, 171.1269],
  [-42.5865, 171.1244],
  [-42.5836, 171.1193],
  [-42.5832, 171.1179],
  [-42.5833, 171.1168],
  [-42.5839, 171.1160],
  [-42.5851, 171.1155],
  [-42.5864, 171.1145],
  [-42.5894, 171.1129],
  [-42.5921, 171.1102],
  [-42.6018, 171.1038],
  [-42.6232, 171.0848],
  [-42.6312, 171.0733],
  [-42.6332, 171.0713],
  [-42.6342, 171.0708],
  [-42.6428, 171.0632],
  [-42.6525, 171.0546],
  [-42.6630, 171.0416],
  [-42.6713, 171.0310],
  [-42.6719, 171.0298],
  [-42.6752, 171.0147],
  [-42.6771, 171.0107],
  [-42.6918, 170.9938],
  [-42.7023, 170.9800],
  [-42.7168, 170.9658],
  [-42.7182, 170.9648],
  [-42.7194, 170.9642],
  [-42.7201, 170.9642],
  [-42.7205, 170.9646],
  [-42.7214, 170.9671],
  [-42.7232, 170.9703],
  [-42.7241, 170.9716],
  [-42.7249, 170.9734],
  [-42.7253, 170.9761],
  [-42.7253, 170.9801]
];

const hokitikaRuatapu = [
  [-42.7194, 170.9642],
  [-42.7203, 170.9634],
  [-42.7318, 170.9577],
  [-42.7331, 170.9562],
  [-42.7366, 170.9468],
  [-42.7376, 170.9448],
  [-42.7485, 170.9295],
  [-42.7499, 170.9281],
  [-42.7930, 170.8952],
  [-42.8064, 170.8866]
];

const ruatapuRoss = [
  [-42.8064, 170.8866],
  [-42.8086, 170.8852],
  [-42.8148, 170.8818],
  [-42.8165, 170.8806],
  [-42.8245, 170.8723],
  [-42.8596, 170.8410],
  [-42.8605, 170.8399],
  [-42.8817, 170.8057],
  [-42.8822, 170.8051],
  [-42.8849, 170.8007]
];

export const ross: Line = {
  name: 'Ross',
  state: 'NZ',
  segments: [
    {
      segments: [greymouthHokitika],
      history: {
        opened: {
          date: '1893-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hokitikaRuatapu],
      history: {
        opened: {
          date: '1906-11-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ruatapuRoss],
      history: {
        opened: {
          date: '1909-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-24',
          status: 'closed'
        }]
      }
    }
  ]
}
