import { Line } from "../../../trackTypes";

const kingsCross = [
  [-33.8715, 151.2128],
  [-33.8735, 151.2144],
  [-33.8739, 151.2144],
  [-33.8748, 151.2199],
  [-33.8750, 151.2208],
  [-33.8753, 151.2219],
  [-33.8738, 151.2234]
];

const kingsCrossEdgeCliffe = [
  [-33.8753, 151.2219],
  [-33.8757, 151.2229],
  [-33.8762, 151.2269],
  [-33.8762, 151.2287],
  [-33.8771, 151.2334],
  [-33.8786, 151.2351],
  [-33.8791, 151.2380],
  [-33.8792, 151.2385],
  [-33.8788, 151.2395],
  [-33.8784, 151.2410],
  [-33.8785, 151.2417],
  [-33.8783, 151.2422],
  [-33.8778, 151.2427],
  [-33.8775, 151.2433],
  [-33.8771, 151.2449],
  [-33.8758, 151.2462],
  [-33.8752, 151.2465],
  [-33.8746, 151.2472],
  [-33.8734, 151.2472],
  [-33.8730, 151.2474],
  [-33.8728, 151.2480],
  [-33.8723, 151.2500],
  [-33.8720, 151.2505],
  [-33.8708, 151.2515],
  [-33.8702, 151.2518],
  [-33.8700, 151.2523],
  [-33.8701, 151.2529],
  [-33.8705, 151.2535],
  [-33.8720, 151.2570],
  [-33.8725, 151.2595],
  [-33.8726, 151.2619],
  [-33.8728, 151.2627],
  [-33.8727, 151.2634],
  [-33.8722, 151.2643],
  [-33.8712, 151.2670],
  [-33.8697, 151.2695],
  [-33.8689, 151.2706]
];

const vaucluse = [
  [-33.8689, 151.2706],
  [-33.8680, 151.2707],
  [-33.8676, 151.2710],
  [-33.8674, 151.2717],
  [-33.8672, 151.2719],
  [-33.8670, 151.2719],
  [-33.8665, 151.2718],
  [-33.8663, 151.2719],
  [-33.8655, 151.2725],
  [-33.8654, 151.2730],
  [-33.8651, 151.2732],
  [-33.8647, 151.2730],
  [-33.8646, 151.2726],
  [-33.8642, 151.2723],
  [-33.8631, 151.2722],
  [-33.8619, 151.2721],
  [-33.8611, 151.2719],
  [-33.8607, 151.2720],
  [-33.8603, 151.2727],
  [-33.8595, 151.2734],
  [-33.8593, 151.2738],
  [-33.8591, 151.2753],
  [-33.8592, 151.2757],
  [-33.8594, 151.2762],
  [-33.8595, 151.2778],
  [-33.8593, 151.2782],
  [-33.8585, 151.2785]
];

const watsonsBay = [
  [-33.8585, 151.2785],
  [-33.8573, 151.2803],
  [-33.8569, 151.2816],
  [-33.8570, 151.2825],
  [-33.8566, 151.2830],
  [-33.8541, 151.2845],
  [-33.8534, 151.2847],
  [-33.8499, 151.2867],
  [-33.8493, 151.2868],
  [-33.8474, 151.2865],
  [-33.8465, 151.2850],
  [-33.8463, 151.2848],
  [-33.8460, 151.2849],
  [-33.8459, 151.2852],
  [-33.8463, 151.2858],
  [-33.8465, 151.2860],
  [-33.8462, 151.2861],
  [-33.8461, 151.2860],
  [-33.8455, 151.2853],
  [-33.8448, 151.2852],
  [-33.8431, 151.2840],
  [-33.8428, 151.2828],
  [-33.8421, 151.2823],
  [-33.8414, 151.2815],
  [-33.8394, 151.2800]
];

const woolloomooloo = [
  [-33.8736, 151.2125],
  [-33.8720, 151.2155],
  [-33.8697, 151.2182],
  [-33.8690, 151.2188],
  [-33.8670, 151.2189],
  [-33.8665, 151.2195]
];

const woollahra = [
  [-33.8732, 151.2099],
  [-33.8736, 151.2125],
  [-33.8739, 151.2144],
  [-33.8755, 151.2141],
  [-33.8761, 151.2177],
  [-33.8786, 151.2171],
  [-33.8794, 151.2217],
  [-33.8795, 151.2220],
  [-33.8805, 151.2230],
  [-33.8813, 151.2249],
  [-33.8817, 151.2248],
  [-33.8822, 151.2254],
  [-33.8822, 151.2260],
  [-33.8824, 151.2264],
  [-33.8824, 151.2268],
  [-33.8821, 151.2272],
  [-33.8822, 151.2284],
  [-33.8819, 151.2290],
  [-33.8823, 151.2314],
  [-33.8826, 151.2318],
  [-33.8829, 151.2319],
  [-33.8861, 151.2366],
  [-33.8864, 151.2366],
  [-33.8884, 151.2371],
  [-33.8879, 151.2399]
];

const bellevueHill = [
  [-33.8879, 151.2399],
  [-33.8873, 151.2431],
  [-33.8877, 151.2438],
  [-33.8884, 151.2440],
  [-33.8892, 151.2447],
  [-33.8894, 151.2451],
  [-33.8894, 151.2460],
  [-33.8896, 151.2467],
  [-33.8894, 151.2495],
  [-33.8893, 151.2508],
  [-33.8893, 151.2517],
  [-33.8895, 151.2526],
  [-33.8894, 151.2560],
  [-33.8885, 151.2578],
  [-33.8877, 151.2587],
  [-33.8874, 151.2599]
];

const bondiBeachCurlewisStreet = [
  [-33.8877, 151.2587],
  [-33.8869, 151.2589],
  [-33.8864, 151.2595],
  [-33.8863, 151.2599],
  [-33.8863, 151.2611],
  [-33.8864, 151.2619],
  [-33.8868, 151.2631],
  [-33.8869, 151.2644],
  [-33.8865, 151.2652],
  [-33.8860, 151.2655],
  [-33.8856, 151.2654],
  [-33.8852, 151.2647],
  [-33.8849, 151.2645],
  [-33.8847, 151.2646],
  [-33.8846, 151.2649],
  [-33.8846, 151.2652],
  [-33.8854, 151.2666],
  [-33.8854, 151.2672],
  [-33.8850, 151.2677],
  [-33.8850, 151.2684],
  [-33.8898, 151.2756]
];

const bondi = [
  [-33.8974, 151.2722],
  [-33.8973, 151.2712],
  [-33.8971, 151.2698],
  [-33.8966, 151.2692],
  [-33.8965, 151.2688],
  [-33.8960, 151.2682],
  [-33.8948, 151.2685],
  [-33.8945, 151.2682],
  [-33.8927, 151.2552],
  [-33.8910, 151.2533],
  [-33.8918, 151.2514],
  [-33.8920, 151.2498],
  [-33.8918, 151.2476],
  [-33.8900, 151.2412],
  [-33.8900, 151.2405],
  [-33.8905, 151.2391],
  [-33.8908, 151.2374],
  [-33.8907, 151.2364],
  [-33.8892, 151.2328],
  [-33.8873, 151.2307],
  [-33.8854, 151.2280],
  [-33.8851, 151.2273],
  [-33.8849, 151.2254],
  [-33.8838, 151.2224],
  [-33.8834, 151.2210],
  [-33.8819, 151.2197],
  [-33.8806, 151.2169],
];

const bondiBeach = [
  [-33.8973, 151.2712],
  [-33.8972, 151.2715],
  [-33.8969, 151.2718],
  [-33.8955, 151.2721],
  [-33.8952, 151.2725],
  [-33.8950, 151.2730],
  [-33.8947, 151.2732],
  [-33.8927, 151.2730],
  [-33.8921, 151.2731],
  [-33.8910, 151.2743]
];

const northBondi = [
  [-33.8910, 151.2743],
  [-33.8898, 151.2756],
  [-33.8891, 151.2765],
  [-33.8890, 151.2775],
  [-33.8891, 151.2787],
  [-33.8898, 151.2803],
  [-33.8897, 151.2820],
  [-33.8900, 151.2824],
  [-33.8898, 151.2829],
  [-33.8892, 151.2835]
];

const waverley = [
  [-33.8920, 151.2498],
  [-33.8936, 151.2495],
  [-33.8962, 151.2513],
  [-33.8979, 151.2520],
  [-33.8987, 151.2522],
  [-33.8993, 151.2525],
  [-33.9002, 151.2535],
  [-33.9023, 151.2547]
];

const bronte = [
  [-33.9023, 151.2547],
  [-33.9034, 151.2550],
  [-33.9042, 151.2543],
  [-33.9047, 151.2545],
  [-33.9051, 151.2574],
  [-33.9055, 151.2584],
  [-33.9058, 151.2606],
  [-33.9057, 151.2611],
  [-33.9066, 151.2668],
  [-33.9064, 151.2677],
  [-33.9065, 151.2686],
  [-33.9069, 151.2690],
  [-33.9069, 151.2695],
  [-33.9068, 151.2698],
  [-33.9066, 151.2698],
  [-33.9057, 151.2694],
  [-33.9055, 151.2692],
  [-33.9052, 151.2684],
  [-33.9049, 151.2680],
  [-33.9044, 151.2667]
];

//shared section leaving gap, need to fix
const bondiJunctionCoogee = [
  [-33.9042, 151.2543],
  [-33.9046, 151.2540],
  [-33.9059, 151.2537],
  [-33.9068, 151.2523],
  [-33.9070, 151.2516],
  [-33.9070, 151.2502],
  [-33.9071, 151.2496],
  [-33.9079, 151.2486],
  [-33.9084, 151.2475],
  [-33.9086, 151.2459],
  [-33.9088, 151.2451],
  [-33.9089, 151.2444],
  [-33.9093, 151.2437],
  [-33.9109, 151.2412],
  [-33.9107, 151.2393],
  [-33.9119, 151.2390],
  [-33.9124, 151.2392]
];

const maroubra = [
  [-33.9416, 151.2393],
  [-33.9423, 151.2443],
  [-33.9436, 151.2441],
  [-33.9439, 151.2442],
  [-33.9447, 151.2450],
  [-33.9449, 151.2469],
  [-33.9453, 151.2487],
  [-33.9450, 151.2495],
  [-33.9452, 151.2506],
  [-33.9463, 151.2513],
  [-33.9471, 151.2545],
  [-33.9473, 151.2555],
  [-33.9464, 151.2567],
  [-33.9459, 151.2559],
  [-33.9471, 151.2545]
];

const randwickRacecourse = [
  [-33.8767, 151.2087],
  [-33.8768, 151.2122],
  [-33.8806, 151.2169],
  [-33.8806, 151.2169],
  [-33.8839, 151.2175],
  [-33.8865, 151.2190],
  [-33.8899, 151.2204],
  [-33.8930, 151.2212],
  [-33.9015, 151.2227],
  [-33.9046, 151.2240],
  [-33.9048, 151.2263],
  [-33.9053, 151.2275],
  [-33.9082, 151.2263],
  [-33.9079, 151.2235],
  [-33.9046, 151.2240]
];

const kensington = [
  [-33.9079, 151.2235],
  [-33.9112, 151.2231],
  [-33.9118, 151.2233],
  [-33.9136, 151.2252],
  [-33.9173, 151.2260]
];

const longBay = [
  [-33.9173, 151.2260],
  [-33.9225, 151.2270],
  [-33.9242, 151.2279],
  [-33.9261, 151.2308],
  [-33.9284, 151.2335],
  [-33.9294, 151.2355],
  [-33.9307, 151.2366],
  [-33.9416, 151.2393],
  [-33.9541, 151.2422],
  [-33.9548, 151.2426],
  [-33.9559, 151.2439],
  [-33.9575, 151.2450],
  [-33.9581, 151.2459],
  [-33.9595, 151.2466],
  [-33.9640, 151.2460],
  [-33.9657, 151.2451],
  [-33.9670, 151.2438],
  [-33.9695, 151.2419],
  [-33.9707, 151.2417],
  [-33.9725, 151.2426],
  [-33.9753, 151.2426],
  [-33.9761, 151.2429],
  [-33.9786, 151.2446]
];

const laPerouse = [
  [-33.9786, 151.2446],
  [-33.9798, 151.2447],
  [-33.9808, 151.2440],
  [-33.9813, 151.2428],
  [-33.9813, 151.2390],
  [-33.9815, 151.2378],
  [-33.9820, 151.2364],
  [-33.9826, 151.2360]
];

const randwickHighStreet = [
  [-33.9015, 151.2227],
  [-33.9041, 151.2262],
  [-33.9062, 151.2311],
  [-33.9068, 151.2358],
  [-33.9087, 151.2354],
  [-33.9121, 151.2384],
  [-33.9124, 151.2392],
  [-33.9171, 151.2412]
];

const coogee = [
  [-33.9171, 151.2412],
  [-33.9181, 151.2421],
  [-33.9201, 151.2427],
  [-33.9206, 151.2459],
  [-33.9211, 151.2477],
  [-33.9211, 151.2488],
  [-33.9212, 151.2496],
  [-33.9218, 151.2500],
  [-33.9221, 151.2520],
  [-33.9231, 151.2534],
  [-33.9239, 151.2539],
  [-33.9241, 151.2553],
  [-33.9239, 151.2560],
  [-33.9195, 151.2571]
];

const surryHills = [
  [-33.8792, 151.2152],
  [-33.8887, 151.2133],
  [-33.8902, 151.2130],
  [-33.8915, 151.2127]
];

const surryHillsExtension = [
  [-33.8915, 151.2127],
  [-33.8916, 151.2125],
  [-33.8967, 151.2116]
];

const westKensington = [
  [-33.8967, 151.2116],
  [-33.8969, 151.2128],
  [-33.8973, 151.2136],
  [-33.8976, 151.2157],
  [-33.9009, 151.2150],
  [-33.9039, 151.2142]
];

const westKensingtonExtension = [
  [-33.9039, 151.2142],
  [-33.9040, 151.2149],
  [-33.9059, 151.2166],
  [-33.9071, 151.2171]
];

const zetlandOriginal = [
  [-33.8849, 151.2081],
  [-33.8857, 151.2069],
  [-33.8929, 151.2053],
  [-33.8932, 151.2073]
];

const zetlandDeviation = [
  [-33.8849, 151.2081],
  [-33.8868, 151.2082],
  [-33.8898, 151.2079],
  [-33.8932, 151.2073]
];

const rosebery = [
  [-33.9041, 151.2052],
  [-33.9073, 151.2076],
  [-33.9103, 151.2060],
  [-33.9109, 151.2063],
  [-33.9140, 151.2052]
];

const zetland = [
  [-33.8828, 151.2083],
  [-33.8842, 151.2081],
  [-33.8849, 151.2081]
];

const zetlandTwo = [
  [-33.8932, 151.2073],
  [-33.8983, 151.2063],
  [-33.8992, 151.2064],
  [-33.9041, 151.2052]
];

const dowlingStreetDepo = [
  [-33.9039, 151.2142],
  [-33.9033, 151.2102],
  [-33.9034, 151.2099],
  [-33.9037, 151.2096],
  [-33.9034, 151.2087],
  [-33.9033, 151.2079],
  [-33.9030, 151.2072],
  [-33.9054, 151.2028]
];

const botany = [
  [-33.8876, 151.2015],
  [-33.8884, 151.2014],
  [-33.8891, 151.2017],
  [-33.8907, 151.2009],
  [-33.8920, 151.2007],
  [-33.8956, 151.1989],
  [-33.9005, 151.2006],
  [-33.9014, 151.2011],
  [-33.9043, 151.2025],
  [-33.9061, 151.2029],
  [-33.9086, 151.2028],
  [-33.9183, 151.1995],
  [-33.9213, 151.1964],
  [-33.9340, 151.1937],
  [-33.9362, 151.1944],
  [-33.9413, 151.1950],
  [-33.9425, 151.1953],
  [-33.9461, 151.1973],
  [-33.9499, 151.1972],
  [-33.9504, 151.1975],
  [-33.9533, 151.2020],
  [-33.9541, 151.2040],
  [-33.9546, 151.2048],
  [-33.9568, 151.2061],
  [-33.9592, 151.2089],
  [-33.9619, 151.2164],
  [-33.9624, 151.2186],
  [-33.9609, 151.2196],
  [-33.9592, 151.2218],
  [-33.9603, 151.2311],
  [-33.9611, 151.2310],
  [-33.9630, 151.2317],
  [-33.9662, 151.2311],
  [-33.9670, 151.2311],
  [-33.9678, 151.2318],
  [-33.9682, 151.2331]
];

const botanyTwo = [
  [-33.9682, 151.2331],
  [-33.9696, 151.2343],
  [-33.9731, 151.2363],
  [-33.9741, 151.2367],
  [-33.9779, 151.2366],
  [-33.9789, 151.2361],
  [-33.9808, 151.2360],
  [-33.9812, 151.2357],
  [-33.9816, 151.2356],
  [-33.9826, 151.2360],
  [-33.9838, 151.2357],
  [-33.9872, 151.2339],
  [-33.9885, 151.2334]
];

const alexandria = [
  [-33.8966, 151.1992],
  [-33.8975, 151.1955],
  [-33.8989, 151.1949],
  [-33.9076, 151.1871],
  [-33.9069, 151.1825],
  [-33.9071, 151.1814]
];

const hendersonRoad = [
  [-33.8975, 151.1955],
  [-33.8977, 151.1912],
  [-33.8978, 151.1907],
  [-33.8979, 151.1902],
  [-33.8981, 151.1893],
  [-33.8982, 151.1889]
];

const hendersonRoadExtension = [
  [-33.8982, 151.1889],
  [-33.8992, 151.1863],
  [-33.9000, 151.1857]
];

const daceyville = [
  [-33.9213, 151.1964],
  [-33.9235, 151.2113],
  [-33.9239, 151.2125],
  [-33.9254, 151.2214],
  [-33.9255, 151.2242],
  [-33.9247, 151.2286]
];

const clovelly = [
  [-33.9062, 151.2311],
  [-33.9049, 151.2324],
  [-33.9049, 151.2353],
  [-33.9029, 151.2392],
  [-33.9029, 151.2401],
  [-33.9027, 151.2415],
  [-33.9044, 151.2441],
  [-33.9060, 151.2466],
  [-33.9082, 151.2475],
  [-33.9087, 151.2485],
  [-33.9096, 151.2492],
  [-33.9100, 151.2515]
];

const clovellyExtension = [
  [-33.9100, 151.2515],
  [-33.9103, 151.2523],
  [-33.9109, 151.2525],
  [-33.9113, 151.2532],
  [-33.9126, 151.2617],
  [-33.9125, 151.2625],
  [-33.9129, 151.2639],
  [-33.9127, 151.2646],
  [-33.9129, 151.2652],
  [-33.9135, 151.2654],
  [-33.9145, 151.2665],
  [-33.9149, 151.2666]
];

const malbarPrison = [
  [-33.9670, 151.2438],
  [-33.9680, 151.2439],
  [-33.9710, 151.2460]
];

const clevelandStreet = [
  [-33.8877, 151.1930],
  [-33.8899, 151.2079],
  [-33.8913, 151.2117],
  [-33.8920, 151.2168],
  [-33.8952, 151.2215]
];

export const sydneyTramsEast: Line = {
  name: 'Sydney Trams (East)',
  state: 'NSW',
  segments: [
    {
      segments: [clevelandStreet],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [botany],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1903-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [botanyTwo],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1903-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dowlingStreetDepo],
      history: {
        opened: {
          date: '1909-01-25',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1961-02-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [zetland],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [zetlandTwo],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [zetlandOriginal],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [zetlandDeviation],
      history: {
        opened: {
          date: '1948-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rosebery],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [surryHills],
      history: {
        opened: {
          date: '1881-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1909-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [surryHillsExtension],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westKensington],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westKensingtonExtension],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [malbarPrison],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [randwickHighStreet],
      history: {
        opened: {
          date: '1881-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [coogee],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [alexandria],
      history: {
        opened: {
          date: '1902-08-11',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1959-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hendersonRoad],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hendersonRoadExtension],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [laPerouse],
      history: {
        opened: {
          date: '1902-08-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-02-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [longBay],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-02-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [randwickRacecourse],
      history: {
        opened: {
          date: '1880-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-02-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kensington],
      history: {
        opened: {
          date: '1881-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-08-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-02-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [daceyville],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bondiJunctionCoogee],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bondi],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-02-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bondiBeach],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-02-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northBondi],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-02-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [woollahra],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1959-06-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bellevueHill],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1955-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bondiBeachCurlewisStreet],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1955-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [clovelly],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [clovellyExtension],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1957-09-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsCross],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsCrossEdgeCliffe],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [vaucluse],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [watsonsBay],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [woolloomooloo],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1935-01-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waverley],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1960-02-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bronte],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1960-02-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maroubra],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1961-02-25',
          status: 'closed'
        }]
      }
    }
  ]
};
