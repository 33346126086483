import { Line } from "../../../trackTypes";

const bayswaterSwanRiver = [
  [-31.9198, 115.9233],
  [-31.9207, 115.9243],
  [-31.9230, 115.9260],
  [-31.9304, 115.9284]
];

const swanRiverRacecourse = [
  [-31.9304, 115.9284],
  [-31.9348, 115.9294],
  [-31.9353, 115.9293],
  [-31.9360, 115.9290],
  [-31.9369, 115.9280],
  [-31.9390, 115.9244]
];

export const belmont: Line = {
  name: 'Belmont',
  state: 'WA',
  segments: [
    {
      segments: [bayswaterSwanRiver],
      history: {
        opened: {
          date: '1885-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [swanRiverRacecourse],
      history: {
        opened: {
          date: '1897-10-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
