import { Line } from "../../trackTypes";

const northGeelongFyansford = [
  [-38.1138, 144.3512],
  [-38.1134, 144.3509],
  [-38.1130, 144.3506],
  [-38.1127, 144.3502],
  [-38.1124, 144.3494],
  [-38.1123, 144.3486],
  [-38.1124, 144.3480],
  [-38.1125, 144.3474],
  [-38.1165, 144.3375],
  [-38.1172, 144.3361],
  [-38.1179, 144.3352],
  [-38.1189, 144.3340],
  [-38.1199, 144.3332],
  [-38.1211, 144.3323],
  [-38.1284, 144.3281],
  [-38.1294, 144.3273],
  [-38.1302, 144.3263],
  [-38.1309, 144.3251],
  [-38.1314, 144.3240],
  [-38.1321, 144.3220],
  [-38.1324, 144.3212],
  [-38.1329, 144.3205],
  [-38.1335, 144.3199],
  [-38.1343, 144.3195],
  [-38.1363, 144.3191],
  [-38.1369, 144.3190],
  [-38.1375, 144.3187],
  [-38.1383, 144.3182],
  [-38.1392, 144.3165]
];

const fyansfordTramStart = [
  [-38.1389, 144.3145],
  [-38.1382, 144.3153],
  [-38.1378, 144.3154],
  [-38.1372, 144.3152],
  [-38.1367, 144.3149],
  [-38.1363, 144.3147],
  [-38.1360, 144.3146],
  [-38.1352, 144.3148],
  [-38.1342, 144.3151],
  [-38.1337, 144.3153],
  [-38.1334, 144.3152],
  [-38.1323, 144.3143],
  [-38.1313, 144.3138],
  [-38.1306, 144.3138],
  [-38.1260, 144.3147],
  [-38.1252, 144.3152],
  [-38.1245, 144.3158],
  [-38.1237, 144.3160],
  [-38.1219, 144.3156],
  [-38.1213, 144.3152],
  [-38.1205, 144.3149],
  [-38.1188, 144.3145],
  [-38.1180, 144.3142],
  [-38.1173, 144.3133],
  [-38.1171, 144.3121],
  [-38.1170, 144.3105],
  [-38.1161, 144.3080],
  [-38.1154, 144.3067]
];

const fyansfordTramTunnel = [
  [-38.1154, 144.3067],
  [-38.1139, 144.3031]
];

const fyansfordTramEnd = [
  [-38.1139, 144.3031],
  [-38.1120, 144.3010]
];

export const fyansford: Line = {
  name: 'Fyansford',
  state: 'VIC',
  segments: [
    {
      segments: [northGeelongFyansford],
      history: {
        opened: {
          date: '1918-09-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        fyansfordTramStart,
        {
          segment: fyansfordTramTunnel,
          type: 'tunnel'
        },
        fyansfordTramEnd
      ],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1966-01-01',
          status: 'closed'
        }]
      }
    },
  ]
};
