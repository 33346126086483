import { Line } from "../../trackTypes";

const line = [
  [-36.8442, 174.7679],
  [-36.8439, 174.7669],
  [-36.8439, 174.7661],
  [-36.8444, 174.7653],
  [-36.8507, 174.7625],
  [-36.8514, 174.7618],
  [-36.8518, 174.7612],
  [-36.8523, 174.7605],
  [-36.8545, 174.7591],
  [-36.8560, 174.7588],
  [-36.8598, 174.7593],
  [-36.8604, 174.7596],
  [-36.8614, 174.7605],
  [-36.8621, 174.7608],
  [-36.8632, 174.7609],
  [-36.8652, 174.7605],
  [-36.8667, 174.7599]
];

const westJunction = [
  [-36.8667, 174.7599],
  [-36.8670, 174.7594],
  [-36.8680, 174.7570]
];

const eastJunction = [
  [-36.8667, 174.7599],
  [-36.8673, 174.7601],
  [-36.8678, 174.7604],
  [-36.8681, 174.7611],
  [-36.8681, 174.7623]
];

export const cityRailLink: Line = {
  name: 'City Rail Link',
  state: 'NZ',
  segments: [
    {
      segments: [
        {
          segment: line,
          type: 'tunnel'
        },
        eastJunction,
        westJunction
      ],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
