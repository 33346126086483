import { Line } from "../../trackTypes";

const brisbaneStreetJunction = [
  [-41.43801, 147.13737],
  [-41.43821, 147.13759]
];

const brisbaneStreetKingsBridge = [
  [-41.43804, 147.13760],
  [-41.43804, 147.13749],
  [-41.43801, 147.13737],
  [-41.43725, 147.13661],
  [-41.43724, 147.13651],
  [-41.43726, 147.13640],
  [-41.44065, 147.13021],
  [-41.44070, 147.13002],
  [-41.44071, 147.12978],
  [-41.44051, 147.12872],
  [-41.44049, 147.12850],
  [-41.44058, 147.12757]
];

const kingsBridgeTrevallym = [
  [-41.44058, 147.12757],
  [-41.44057, 147.12743],
  [-41.44051, 147.12733],
  [-41.44003, 147.12686],
  [-41.43997, 147.12683],
  [-41.43989, 147.12682],
  [-41.43939, 147.12702],
  [-41.43930, 147.12704],
  [-41.43920, 147.12702],
  [-41.43908, 147.12695],
  [-41.43845, 147.12632],
  [-41.43832, 147.12623],
  [-41.43795, 147.12601],
  [-41.43780, 147.12588],
  [-41.43768, 147.12572],
  [-41.43763, 147.12562],
  [-41.43760, 147.12546],
  [-41.43758, 147.12513],
  [-41.43755, 147.12497],
  [-41.43749, 147.12481],
  [-41.43742, 147.12469],
  [-41.43730, 147.12454],
  [-41.43688, 147.12419],
  [-41.43677, 147.12408],
  [-41.43530, 147.12253],
  [-41.43516, 147.12235],
  [-41.43508, 147.12219],
  [-41.43503, 147.12204],
  [-41.43499, 147.12189],
  [-41.43497, 147.12164],
  [-41.43502, 147.12098],
  [-41.43506, 147.12077],
  [-41.43517, 147.12043],
  [-41.43526, 147.12016],
  [-41.43533, 147.11985]
];

const cataractHill = [
  [-41.44070, 147.13002],
  [-41.44085, 147.12997],
  [-41.44093, 147.12997],
  [-41.44170, 147.13059],
  [-41.44195, 147.13079],
  [-41.44411, 147.13300],
  [-41.44424, 147.13304],
  [-41.44448, 147.13305],
  [-41.44534, 147.13331],
  [-41.44550, 147.13332],
  [-41.44565, 147.13327],
  [-41.44631, 147.13298],
  [-41.44658, 147.13287],
  [-41.44687, 147.13279],
  [-41.44738, 147.13265],
  [-41.44752, 147.13265],
  [-41.44759, 147.13266],
  [-41.44798, 147.13280],
  [-41.44827, 147.13298],
  [-41.44834, 147.13300],
  [-41.44840, 147.13296],
  [-41.44992, 147.13095]
];

const brisbaneStreetDavidStreet = [
  [-41.43804, 147.13760],
  [-41.43643, 147.14039],
  [-41.43628, 147.14062],
  [-41.43608, 147.14089],
  [-41.43458, 147.14245],
  [-41.43364, 147.14342],
  [-41.43345, 147.14367],
  [-41.43326, 147.14400],
  [-41.43238, 147.14587],
  [-41.43221, 147.14608],
  [-41.43210, 147.14619],
  [-41.43167, 147.14666],
  [-41.43156, 147.14681],
  [-41.43150, 147.14696],
  [-41.43144, 147.14715],
  [-41.43140, 147.14740],
  [-41.43138, 147.14780],
  [-41.43141, 147.14813],
  [-41.43149, 147.14848],
  [-41.43164, 147.14885],
  [-41.43576, 147.15637],
  [-41.43592, 147.15659],
  [-41.43609, 147.15676],
  [-41.43786, 147.15818],
  [-41.44150, 147.16086]
];

const newstead = [
  [-41.44150, 147.16086],
  [-41.44203, 147.16127],
  [-41.44212, 147.16134],
  [-41.44231, 147.16143],
  [-41.44525, 147.16258]
];

const highStreet = [
  [-41.43238, 147.14587],
  [-41.43191, 147.14689],
  [-41.43188, 147.14709],
  [-41.43192, 147.14726],
  [-41.43198, 147.14735],
  [-41.43216, 147.14742],
  [-41.43252, 147.14745],
  [-41.43837, 147.14720],
  [-41.43872, 147.14720],
  [-41.44037, 147.14735],
  [-41.44289, 147.14775],
  [-41.44321, 147.14784],
  [-41.44337, 147.14791],
  [-41.44348, 147.14799],
  [-41.44365, 147.14814],
  [-41.44428, 147.14889],
  [-41.44452, 147.14926],
  [-41.44472, 147.14951]
];

const highStreetExtension = [
  [-41.44472, 147.14951],
  [-41.44611, 147.15099],
  [-41.44655, 147.15136],
  [-41.44690, 147.15153],
  [-41.44824, 147.15216]
];

const brisbaneStreetMcKenzieStreet = [
  [-41.43458, 147.14245],
  [-41.43448, 147.14246],
  [-41.43441, 147.14244],
  [-41.43364, 147.14171],
  [-41.43040, 147.13888],
  [-41.42994, 147.13862],
  [-41.42385, 147.13649],
  [-41.42182, 147.13578],
  [-41.41809, 147.13446],
  [-41.41744, 147.13425],
  [-41.41448, 147.13343],
  [-41.41144, 147.13258],
  [-41.41016, 147.13223]
];

const mcKensieRacecourse = [
  [-41.41016, 147.13223],
  [-41.40964, 147.13203],
  [-41.40949, 147.13200],
  [-41.40941, 147.13203],
  [-41.40930, 147.13210],
  [-41.40875, 147.13273],
  [-41.40845, 147.13299],
  [-41.40817, 147.13312],
  [-41.40751, 147.13338],
  [-41.40723, 147.13347],
  [-41.40701, 147.13347],
  [-41.40688, 147.13344],
  [-41.40671, 147.13336],
  [-41.40632, 147.13313],
  [-41.40627, 147.13316],
  [-41.40623, 147.13320],
  [-41.40537, 147.13588]
];

const mobrayHeights = [
  [-41.40632, 147.13313],
  [-41.39902, 147.12895]
];

const sandhill = [
  [-41.43804, 147.13760],
  [-41.43812, 147.13757],
  [-41.43821, 147.13759],
  [-41.43910, 147.13851],
  [-41.43930, 147.13868],
  [-41.44014, 147.13928],
  [-41.44024, 147.13927],
  [-41.44033, 147.13921],
  [-41.44095, 147.13780],
  [-41.44102, 147.13775],
  [-41.44110, 147.13773],
  [-41.44342, 147.13856],
  [-41.44418, 147.13879],
  [-41.44489, 147.13906],
  [-41.44518, 147.13914],
  [-41.44606, 147.13928],
  [-41.44737, 147.13939],
  [-41.44763, 147.13943],
  [-41.44816, 147.13964],
  [-41.44968, 147.14083],
  [-41.45162, 147.14252]
];

const sandhillCarrVillaCemetery = [
  [-41.45162, 147.14252],
  [-41.45572, 147.14613],
  [-41.45802, 147.14854],
  [-41.45812, 147.14868],
  [-41.45822, 147.14887],
  [-41.45897, 147.15057],
  [-41.45910, 147.15094],
  [-41.45927, 147.15167],
  [-41.45927, 147.15189],
  [-41.45922, 147.15206],
  [-41.45914, 147.15224],
  [-41.45877, 147.15281],
  [-41.45870, 147.15301],
  [-41.45867, 147.15318],
  [-41.45869, 147.15337],
  [-41.45874, 147.15368],
  [-41.45950, 147.15528],
  [-41.45963, 147.15544],
  [-41.45977, 147.15551],
  [-41.45995, 147.15559],
  [-41.46016, 147.15562],
  [-41.46085, 147.15555],
  [-41.46104, 147.15557],
  [-41.46118, 147.15560],
  [-41.46139, 147.15573],
  [-41.46156, 147.15591],
  [-41.46188, 147.15634],
  [-41.46208, 147.15659],
  [-41.46233, 147.15683],
  [-41.46316, 147.15755],
  [-41.46387, 147.15814],
  [-41.46458, 147.15870],
  [-41.46488, 147.15892],
  [-41.46540, 147.15922],
  [-41.46889, 147.16109],
  [-41.46896, 147.16121],
  [-41.46899, 147.16130],
  [-41.46900, 147.16145],
  [-41.46774, 147.16428]
];

const kingsWharf = [
  [-41.43725, 147.13661],
  [-41.43443, 147.13370],
  [-41.43411, 147.13353],
  [-41.43259, 147.13342],
  [-41.43214, 147.13335],
  [-41.43088, 147.13311],
  [-41.43053, 147.13303],
  [-41.42771, 147.13197],
  [-41.42763, 147.13181],
  [-41.42759, 147.13157],
  [-41.42887, 147.12527],
  [-41.42895, 147.12514],
  [-41.42905, 147.12507],
  [-41.42921, 147.12508],
  [-41.43166, 147.12718],
  [-41.43173, 147.12729],
  [-41.43174, 147.12743],
  [-41.43077, 147.13273],
  [-41.43082, 147.13297],
  [-41.43088, 147.13311]
];

export const launcestonTrams: Line = {
  name: 'Launceston Trams',
  state: 'TAS',
  segments: [
    {
      segments: [brisbaneStreetKingsBridge, brisbaneStreetMcKenzieStreet],
      history: {
        opened: {
          date: '1911-08-04',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsBridgeTrevallym],
      history: {
        opened: {
          date: '1912-07-16',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cataractHill],
      history: {
        opened: {
          date: '1915-05-15',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mcKensieRacecourse],
      history: {
        opened: {
          date: '1916-01-26',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mobrayHeights],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brisbaneStreetDavidStreet],
      history: {
        opened: {
          date: '1911-08-04',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-12-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newstead],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-12-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsWharf, brisbaneStreetJunction],
      history: {
        opened: {
          date: '1919-02-24',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [highStreet],
      history: {
        opened: {
          date: '1914-01-17',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [highStreetExtension],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandhill],
      history: {
        opened: {
          date: '1911-10-27',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sandhillCarrVillaCemetery],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
