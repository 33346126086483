import { Line } from "../../../trackTypes";

const fremantlePortJunction = [
  [-32.0517, 115.7453],
  [-32.0473, 115.7496],
  [-32.0459, 115.7506],
  [-32.0437, 115.7531],
  [-32.0430, 115.7535],
  [-32.0399, 115.7539],
  [-32.0393, 115.7538],
  [-32.0388, 115.7536],
  [-32.0385, 115.7532],
  [-32.0379, 115.7526],
  [-32.0373, 115.7522],
];

const portJunctionJoondalupJunction = [
  [-32.0373, 115.7522],
  [-32.0342, 115.7514],
  [-32.0334, 115.7514],
  [-32.0174, 115.7534],
  [-32.0163, 115.7536],
  [-32.0104, 115.7554],
  [-32.0086, 115.7562],
  [-32.0076, 115.7569],
  [-32.0043, 115.7591],
  [-32.0026, 115.7599],
  [-32.0007, 115.7604],
  [-31.9980, 115.7607],
  [-31.9961, 115.7610],
  [-31.9934, 115.7620],
  [-31.9869, 115.7647],
  [-31.9856, 115.7655],
  [-31.9842, 115.7670],
  [-31.9833, 115.7685],
  [-31.9829, 115.7698],
  [-31.9816, 115.7745],
  [-31.9815, 115.7761],
  [-31.9815, 115.7779],
  [-31.9814, 115.7795],
  [-31.9811, 115.7806],
  [-31.9791, 115.7847],
  [-31.9783, 115.7859],
  [-31.9775, 115.7866],
  [-31.9745, 115.7883],
  [-31.9736, 115.7889],
  [-31.9730, 115.7897],
  [-31.9647, 115.8012],
  [-31.9629, 115.8031],
  [-31.9607, 115.8048],
  [-31.9566, 115.8072],
  [-31.9550, 115.8087],
  [-31.9487, 115.8177],
  [-31.9476, 115.8190],
  [-31.9463, 115.8203],
  [-31.9454, 115.8216],
  [-31.9449, 115.8228],
  [-31.9443, 115.8255],
  [-31.9435, 115.8277],
  [-31.9427, 115.8319],
  [-31.9426, 115.8367],
  [-31.9435, 115.8418],
  [-31.9464, 115.8496]
];

const joondalupJunctionMandurahJunction = [
  [-31.9464, 115.8496],
  [-31.9492, 115.8560]
];

const mandurahJunctionPerth = [
  [-31.9492, 115.8560],
  [-31.9500, 115.8578],
  [-31.9511, 115.8599]
];

const fremantleRobsJetty = [
  [-32.0517, 115.7453],
  [-32.0541, 115.7423],
  [-32.0549, 115.7416],
  [-32.0558, 115.7415],
  [-32.0572, 115.7421],
  [-32.0580, 115.7428],
  [-32.0591, 115.7463],
  [-32.0599, 115.7479],
  [-32.0610, 115.7489],
  [-32.0637, 115.7502],
  [-32.0654, 115.7505],
  [-32.0685, 115.7507],
  [-32.0735, 115.7518],
  [-32.0753, 115.7526],
  [-32.0762, 115.7528],
  [-32.0826, 115.7535],
  [-32.0841, 115.7540],
  [-32.0855, 115.7548],
  [-32.0900, 115.7590]
];

const port = [
  [-32.0373, 115.7522],
  [-32.0368, 115.7516],
  [-32.0364, 115.7509],
  [-32.0362, 115.7501],
  [-32.0363, 115.7494],
  [-32.0365, 115.7485],
  [-32.0369, 115.7471],
  [-32.0372, 115.7466],
  [-32.0375, 115.7462],
  [-32.0451, 115.7387]
];

export const fremantle: Line = {
  name: 'Fremantle',
  state: 'WA',
  segments: [
    {
      segments: [fremantlePortJunction],
      history: {
        opened: {
          date: '1881-03-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1968-08-03',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [portJunctionJoondalupJunction],
      history: {
        opened: {
          date: '1881-03-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1979-09-01',
          status: 'closed'
        }, {
          date: '1983-07-29',
          status: 'open'
        }]
      }
    },
    {
      segments: [joondalupJunctionMandurahJunction],
      history: {
        opened: {
          date: '1881-03-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-10-15',
          tracks: 4
        }, {
          date: '1991-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1979-09-01',
          status: 'closed'
        }, {
          date: '1983-07-29',
          status: 'open'
        }]
      }
    },
    {
      segments: [mandurahJunctionPerth],
      history: {
        opened: {
          date: '1881-03-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1979-09-01',
          status: 'closed'
        }, {
          date: '1983-07-29',
          status: 'open'
        }]
      }
    },
    {
      segments: [fremantleRobsJetty],
      history: {
        opened: {
          date: '1898-10-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1896-01-01',
          tracks: 2
        }, {
          date: '1968-08-03',
          tracks: 1,
          parallelStandard: true
        }, {
          date: '1986-10-01',
          parallelStandard: false,
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [port],
      history: {
        opened: {
          date: '1881-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }]
      }
    }
  ]
};
