import { Line } from "../../../../trackTypes";

const opawa = [
  [-43.54460, 172.63668],
  [-43.54467, 172.63673],
  [-43.54473, 172.63683],
  [-43.54471, 172.64589],
  [-43.54474, 172.64614],
  [-43.54479, 172.64643],
  [-43.54486, 172.64665],
  [-43.54519, 172.64756],
  [-43.54598, 172.65031],
  [-43.54673, 172.65328],
  [-43.54680, 172.65347],
  [-43.54687, 172.65359],
  [-43.54699, 172.65375],
  [-43.54889, 172.65580],
  [-43.54898, 172.65592],
  [-43.54907, 172.65609],
  [-43.55122, 172.66174],
  [-43.55129, 172.66188],
  [-43.55136, 172.66200],
  [-43.55381, 172.66581]
];

const stMartins = [
  [-43.54486, 172.64665],
  [-43.54496, 172.64675],
  [-43.54511, 172.64680],
  [-43.55073, 172.64679],
  [-43.55098, 172.64686],
  [-43.55118, 172.64697],
  [-43.55137, 172.64715],
  [-43.55312, 172.64968],
  [-43.55324, 172.64990],
  [-43.55351, 172.65049],
  [-43.55361, 172.65066],
  [-43.55370, 172.65078],
  [-43.55386, 172.65093],
  [-43.55449, 172.65136],
  [-43.55567, 172.65214],
  [-43.56258, 172.65741],
  [-43.56271, 172.65753],
  [-43.56282, 172.65779],
  [-43.56366, 172.65983],
  [-43.56369, 172.65996],
  [-43.56369, 172.66013],
  [-43.56365, 172.66030],
  [-43.56256, 172.66390]
];

export const line8: Line = {
  name: 'Line 8',
  state: 'NZ',
  segments: [
    {
      segments: [opawa],
      history: {
        opened: {
          date: '1907-03-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram', //steam
        },
        trackChange: [{
          date: '1909-09-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-02-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stMartins],
      history: {
        opened: {
          date: '1914-04-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1946-05-20',
          status: 'closed'
        }]
      }
    }
  ]
}
