import { Line } from "../../trackTypes";

const glenleeJunctionGlenleeColliery = [
  [-34.0846, 150.7629],
  [-34.0846, 150.7618],
  [-34.0845, 150.7611],
  [-34.0836, 150.7595],
  [-34.0824, 150.7579],
  [-34.0821, 150.7573],
  [-34.0820, 150.7564],
  [-34.0816, 150.7556],
  [-34.0808, 150.7549],
  [-34.0799, 150.7546],
  [-34.0793, 150.7541],
  [-34.0790, 150.7533],
  [-34.0789, 150.7519],
  [-34.0795, 150.7494],
  [-34.0794, 150.7485],
  [-34.0790, 150.7475],
  [-34.0789, 150.7467],
  [-34.0789, 150.7459],
  [-34.0796, 150.7443],
  [-34.0796, 150.7435],
  [-34.0794, 150.7427],
  [-34.0791, 150.7424]
];

const glenleeTriangle = [
  [-34.0859, 150.7590],
  [-34.0854, 150.7596],
  [-34.0847, 150.7598],
  [-34.0836, 150.7595]
];

export const glenleeColliery: Line = {
  name: 'Glenlee Colliery',
  state: 'NSW',
  segments: [
    {
      segments: [glenleeJunctionGlenleeColliery],
      history: {
        opened: {
          date: '1958-12-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [glenleeTriangle],
      history: {
        opened: {
          date: '1990-01-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
