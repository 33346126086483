import { Line } from "../../trackTypes";

const line = [
  [-32.3713, 117.0119],
  [-32.3719, 117.0126],
  [-32.3721, 117.0135],
  [-32.3721, 117.0190],
  [-32.3720, 117.0202],
  [-32.3720, 117.0227],
  [-32.3739, 117.0426],
  [-32.3744, 117.0441],
  [-32.3770, 117.0490],
  [-32.3775, 117.0514],
  [-32.3781, 117.0525],
  [-32.3800, 117.0536],
  [-32.3808, 117.0546],
  [-32.3821, 117.0586],
  [-32.3870, 117.0663],
  [-32.3872, 117.0674],
  [-32.3869, 117.0717],
  [-32.3865, 117.0731],
  [-32.3838, 117.0769],
  [-32.3814, 117.0847],
  [-32.3808, 117.0857],
  [-32.3776, 117.0888],
  [-32.3770, 117.0901],
  [-32.3769, 117.0918],
  [-32.3777, 117.0934],
  [-32.3811, 117.0966],
  [-32.3825, 117.0971],
  [-32.3849, 117.0971],
  [-32.3860, 117.0975],
  [-32.3875, 117.0989],
  [-32.3881, 117.1005],
  [-32.3878, 117.1020],
  [-32.3866, 117.1039],
  [-32.3855, 117.1073],
  [-32.3842, 117.1094],
  [-32.3828, 117.1106],
  [-32.3822, 117.1117],
  [-32.3820, 117.1128],
  [-32.3823, 117.1154],
  [-32.3820, 117.1165],
  [-32.3815, 117.1179],
  [-32.3815, 117.1197],
  [-32.3835, 117.1233],
  [-32.3839, 117.1247],
  [-32.3837, 117.1268],
  [-32.3841, 117.1279],
  [-32.3849, 117.1295],
  [-32.3852, 117.1310],
  [-32.3845, 117.1349],
  [-32.3836, 117.1364],
  [-32.3790, 117.1410],
  [-32.3785, 117.1421],
  [-32.3786, 117.1434],
  [-32.3809, 117.1487],
  [-32.3815, 117.1505],
  [-32.3834, 117.1529],
  [-32.3851, 117.1538],
  [-32.3860, 117.1550],
  [-32.3860, 117.1568],
  [-32.3849, 117.1586],
  [-32.3838, 117.1595],
  [-32.3829, 117.1604],
  [-32.3819, 117.1621],
  [-32.3811, 117.1652],
  [-32.3796, 117.1681],
  [-32.3783, 117.1693],
  [-32.3769, 117.1700],
  [-32.3761, 117.1711],
  [-32.3730, 117.1836],
  [-32.3732, 117.1852],
  [-32.3737, 117.1865],
  [-32.3762, 117.1904],
  [-32.3767, 117.1917],
  [-32.3780, 117.2048],
  [-32.3796, 117.2132],
  [-32.3802, 117.2146],
  [-32.3837, 117.2191],
  [-32.3844, 117.2197],
  [-32.3861, 117.2206],
  [-32.3871, 117.2217],
  [-32.3887, 117.2267],
  [-32.3885, 117.2289],
  [-32.3856, 117.2332],
  [-32.3851, 117.2346],
  [-32.3848, 117.2361],
  [-32.3841, 117.2374],
  [-32.3810, 117.2402],
  [-32.3799, 117.2408],
  [-32.3775, 117.2415],
  [-32.3762, 117.2426],
  [-32.3730, 117.2479],
  [-32.3725, 117.2491],
  [-32.3721, 117.2512],
  [-32.3728, 117.2653],
  [-32.3738, 117.2741],
  [-32.3739, 117.2824],
  [-32.3742, 117.2871],
  [-32.3742, 117.3107],
  [-32.3739, 117.3127],
  [-32.3701, 117.3214],
  [-32.3689, 117.3227],
  [-32.3682, 117.3246],
  [-32.3679, 117.3278],
  [-32.3656, 117.3327],
  [-32.3652, 117.3347],
  [-32.3656, 117.3366],
  [-32.3666, 117.3389],
  [-32.3676, 117.3494],
  [-32.3777, 117.3951],
  [-32.3778, 117.3980],
  [-32.3768, 117.4018],
  [-32.3762, 117.4435],
  [-32.3757, 117.4456],
  [-32.3661, 117.4646],
  [-32.3656, 117.4669],
  [-32.3662, 117.4686],
  [-32.3689, 117.4714],
  [-32.3697, 117.4732],
  [-32.3702, 117.4771],
  [-32.3695, 117.4837],
  [-32.3695, 117.4857],
  [-32.3740, 117.4997],
  [-32.3743, 117.5015],
  [-32.3748, 117.5291],
  [-32.3741, 117.5324],
  [-32.3739, 117.5347],
  [-32.3782, 117.5545],
  [-32.3791, 117.5648],
  [-32.3785, 117.5670],
  [-32.3750, 117.5722],
  [-32.3709, 117.5759],
  [-32.3688, 117.5785],
  [-32.3679, 117.5826],
  [-32.3663, 117.5849],
  [-32.3624, 117.5873],
  [-32.3605, 117.5896],
  [-32.3600, 117.5920],
  [-32.3598, 117.5944],
  [-32.3574, 117.6004],
  [-32.3566, 117.6035],
  [-32.3562, 117.6091],
  [-32.3564, 117.6108],
  [-32.3593, 117.6164],
  [-32.3596, 117.6178],
  [-32.3591, 117.6203],
  [-32.3570, 117.6233],
  [-32.3505, 117.6348],
  [-32.3500, 117.6367],
  [-32.3500, 117.6386],
  [-32.3523, 117.6501],
  [-32.3539, 117.6533],
  [-32.3542, 117.6550],
  [-32.3560, 117.6984],
  [-32.3573, 117.7058],
  [-32.3569, 117.7747],
  [-32.3579, 117.7824],
  [-32.3568, 117.7907],
  [-32.3542, 117.7982],
  [-32.3540, 117.7997],
  [-32.3544, 117.8009],
  [-32.3554, 117.8036],
  [-32.3553, 117.8054],
  [-32.3526, 117.8095],
  [-32.3482, 117.8130],
  [-32.3472, 117.8145],
  [-32.3439, 117.8238],
  [-32.3435, 117.8262],
  [-32.3426, 117.8280],
  [-32.3412, 117.8299],
  [-32.3405, 117.8322],
  [-32.3405, 117.8338],
  [-32.3414, 117.8352],
  [-32.3424, 117.8362],
  [-32.3429, 117.8377],
  [-32.3428, 117.8391],
  [-32.3428, 117.8406],
  [-32.3445, 117.8459],
  [-32.3444, 117.8478],
  [-32.3434, 117.8496],
  [-32.3421, 117.8516],
  [-32.3410, 117.8548],
  [-32.3384, 117.8582],
  [-32.3379, 117.8599],
  [-32.3378, 117.8656],
  [-32.3374, 117.8681]
];

export const brooktonCorrigin: Line = {
  name: 'Brookton - Corrigin',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
