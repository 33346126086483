import { Line } from "../../../trackTypes";

const bundambaBlacklegGully = [
  [-27.6077, 152.8019],
  [-27.6081, 152.8012],
  [-27.6089, 152.8003],
  [-27.6093, 152.8001],
  [-27.6096, 152.8001],
  [-27.6116, 152.8009],
  [-27.6160, 152.8013],
  [-27.6182, 152.8011],
  [-27.6223, 152.8004],
  [-27.6229, 152.8002]
];

const blacklegGullyWestMoreton = [
  [-27.6229, 152.8002],
  [-27.6236, 152.7999],
  [-27.6247, 152.7986],
  [-27.6257, 152.7981],
  [-27.6267, 152.7979],
  [-27.6278, 152.7974]
];

const westMoretonNewSwanbankColliery = [
  [-27.6278, 152.7974],
  [-27.6287, 152.7968],
  [-27.6291, 152.7966],
  [-27.6298, 152.7966],
  [-27.6309, 152.7963],
  [-27.6313, 152.7960],
  [-27.6314, 152.7957],
  [-27.6315, 152.7944],
  [-27.6316, 152.7936],
  [-27.6321, 152.7931],
  [-27.6338, 152.7919],
  [-27.6345, 152.7918],
  [-27.6365, 152.7928],
  [-27.6376, 152.7944],
  [-27.6393, 152.7961],
  [-27.6402, 152.7975],
  [-27.6408, 152.7987],
  [-27.6410, 152.7991],
  [-27.6419, 152.7998],
  [-27.6427, 152.8005]
];

const newSwanbankCollier = [
  [-27.6427, 152.8005],
  [-27.6431, 152.8011],
  [-27.6428, 152.8032],
  [-27.6420, 152.8055],
  [-27.6417, 152.8070],
  [-27.6414, 152.8105],
  [-27.6419, 152.8132],
  [-27.6412, 152.8166],
  [-27.6416, 152.8184],
  [-27.6418, 152.8191],
  [-27.6420, 152.8202],
  [-27.6421, 152.8207],
  [-27.6419, 152.8211],
  [-27.6414, 152.8217],
  [-27.6408, 152.8227],
  [-27.6406, 152.8231],
  [-27.6404, 152.8236],
  [-27.6400, 152.8239]
];

const newSwanbankCollierySwanbankPowerStation = [
  [-27.6427, 152.8005],
  [-27.6441, 152.8016],
  [-27.6454, 152.8021],
  [-27.6463, 152.8022],
  [-27.6474, 152.8020],
  [-27.6482, 152.8020],
  [-27.6487, 152.8022],
  [-27.6506, 152.8036],
  [-27.6512, 152.8044],
  [-27.6538, 152.8058],
  [-27.6545, 152.8067],
  [-27.6547, 152.8081],
  [-27.6544, 152.8091],
  [-27.6538, 152.8102],
  [-27.6535, 152.8116],
  [-27.6538, 152.8154],
  [-27.6543, 152.8165],
  [-27.6550, 152.8172],
  [-27.6584, 152.8190],
  [-27.6595, 152.8191],
  [-27.6605, 152.8189],
  [-27.6614, 152.8190],
  [-27.6626, 152.8197],
  [-27.6630, 152.8206],
  [-27.6629, 152.8215],
  [-27.6623, 152.8221],
  [-27.6614, 152.8222],
  [-27.6606, 152.8217],
  [-27.6596, 152.8198],
  [-27.6584, 152.8190]
];

const redbankBoxFlatJunction = [
  [-27.6008, 152.8649],
  [-27.6014, 152.8626],
  [-27.6018, 152.8619],
  [-27.6028, 152.8612],
  [-27.6043, 152.8611],
  [-27.6062, 152.8611],
  [-27.6069, 152.8607],
  [-27.6090, 152.8585],
  [-27.6097, 152.8563],
  [-27.6098, 152.8553],
  [-27.6094, 152.8536],
  [-27.6091, 152.8517],
  [-27.6094, 152.8507],
  [-27.6101, 152.8491],
  [-27.6104, 152.8483],
  [-27.6110, 152.8477],
  [-27.6122, 152.8474],
  [-27.6137, 152.8463],
  [-27.6169, 152.8457],
  [-27.6171, 152.8451],
  [-27.6168, 152.8418],
  [-27.6171, 152.8407],
  [-27.6184, 152.8383],
  [-27.6190, 152.8364],
  [-27.6196, 152.8358],
  [-27.6209, 152.8348],
  [-27.6213, 152.8346],
  [-27.6226, 152.8344],
  [-27.6245, 152.8333],
  [-27.6249, 152.8332],
  [-27.6255, 152.8334],
  [-27.6262, 152.8341],
  [-27.6269, 152.8343],
  [-27.6277, 152.8341],
  [-27.6294, 152.8321],
  [-27.6301, 152.8304],
  [-27.6301, 152.8290],
  [-27.6299, 152.8277],
  [-27.6302, 152.8270],
  [-27.6314, 152.8258],
  [-27.6327, 152.8247],
  [-27.6345, 152.8238],
  [-27.6357, 152.8234],
  [-27.6376, 152.8235],
  [-27.6389, 152.8236],
  [-27.6400, 152.8239]
];

export const redbankBudamba: Line = {
  name: 'Redbank - Bundamba Loop',
  state: 'QLD',
  segments: [
    {
      segments: [bundambaBlacklegGully],
      history: {
        opened: {
          date: '1881-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [blacklegGullyWestMoreton],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [westMoretonNewSwanbankColliery],
      history: {
        opened: {
          date: '1895-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [newSwanbankCollier],
      history: {
        opened: {
          date: '1895-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newSwanbankCollierySwanbankPowerStation],
      history: {
        opened: {
          date: '1975-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [redbankBoxFlatJunction],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
