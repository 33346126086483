import { Line } from "../../../../trackTypes";

const julietteStreet = [
  [-27.48640, 153.03542],
  [-27.48657, 153.03583],
  [-27.48667, 153.03589],
  [-27.48682, 153.03595],
  [-27.48833, 153.03599],
  [-27.48860, 153.03596],
  [-27.48887, 153.03591],
  [-27.48921, 153.03582],
  [-27.49296, 153.03475],
  [-27.49365, 153.03461],
  [-27.49421, 153.03452],
  [-27.49525, 153.03464],
  [-27.49566, 153.03473],
  [-27.49857, 153.03547],
  [-27.49895, 153.03553],
  [-27.49931, 153.03551],
  [-27.50092, 153.03513],
  [-27.50124, 153.03500],
  [-27.50222, 153.03452],
  [-27.50245, 153.03444],
  [-27.50326, 153.03429]
];

const cracknellRoad = [
  [-27.50326, 153.03429],
  [-27.50352, 153.03427],
  [-27.50455, 153.03433],
  [-27.50489, 153.03437],
  [-27.50606, 153.03461],
  [-27.50618, 153.03462],
  [-27.50635, 153.03460],
  [-27.50738, 153.03419],
  [-27.50766, 153.03407],
  [-27.50806, 153.03387],
  [-27.51053, 153.03235],
  [-27.51079, 153.03219],
  [-27.51232, 153.03153],
  [-27.51375, 153.03097],
  [-27.51618, 153.03012],
  [-27.51692, 153.02992],
  [-27.51730, 153.02980],
  [-27.51811, 153.02945]
];

const aubingneyStreet = [
  [-27.51811, 153.02945],
  [-27.51825, 153.02934],
  [-27.51839, 153.02913],
  [-27.51865, 153.02880],
  [-27.51949, 153.02788],
  [-27.52113, 153.02645]
];

const mayfieldRoad = [
  [-27.52113, 153.02645],
  [-27.52465, 153.02328],
  [-27.52484, 153.02318],
  [-27.52509, 153.02310],
  [-27.52700, 153.02268],
  [-27.52715, 153.02268],
  [-27.52730, 153.02272],
  [-27.52863, 153.02333],
  [-27.52890, 153.02342],
  [-27.53155, 153.02398],
  [-27.53180, 153.02408],
  [-27.53202, 153.02422],
  [-27.53277, 153.02475]
];

const salisbury = [
  [-27.53277, 153.02475],
  [-27.53321, 153.02507],
  [-27.53343, 153.02513],
  [-27.53363, 153.02510],
  [-27.53387, 153.02504],
  [-27.53472, 153.02441],
  [-27.53500, 153.02414],
  [-27.53566, 153.02327],
  [-27.53587, 153.02308],
  [-27.53687, 153.02245],
  [-27.53706, 153.02241],
  [-27.53725, 153.02240],
  [-27.53816, 153.02257],
  [-27.53836, 153.02260],
  [-27.53870, 153.02256],
  [-27.53953, 153.02233],
  [-27.54162, 153.02156],
  [-27.54183, 153.02155],
  [-27.54210, 153.02158],
  [-27.54330, 153.02197],
  [-27.54346, 153.02209],
  [-27.54356, 153.02223],
  [-27.54367, 153.02244],
  [-27.54487, 153.03021]
];

export const south6: Line = {
  name: 'South 6',
  state: 'QLD',
  segments: [
    {
      segments: [julietteStreet],
      history: {
        opened: {
          date: '1899-09-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cracknellRoad],
      history: {
        opened: {
          date: '1915-04-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [aubingneyStreet],
      history: {
        opened: {
          date: '1923-02-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mayfieldRoad],
      history: {
        opened: {
          date: '1937-05-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [salisbury],
      history: {
        opened: {
          date: '1941-09-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    }
  ]
}
