import { Line } from "../../trackTypes";

const line = [
  [-33.5260, 149.9009],
  [-33.5268, 149.9002],
  [-33.5275, 149.9002],
  [-33.5282, 149.9005],
  [-33.5290, 149.9011],
  [-33.5297, 149.9014],
  [-33.5303, 149.9012],
  [-33.5311, 149.9007],
  [-33.5318, 149.8998],
  [-33.5340, 149.8946],
  [-33.5340, 149.8939],
  [-33.5337, 149.8933],
  [-33.5330, 149.8928],
  [-33.5327, 149.8922],
  [-33.5327, 149.8914],
  [-33.5333, 149.8892],
  [-33.5336, 149.8888],
  [-33.5339, 149.8886],
  [-33.5344, 149.8883],
  [-33.5347, 149.8879],
  [-33.5348, 149.8873],
  [-33.5347, 149.8866],
  [-33.5347, 149.8861],
  [-33.5349, 149.8857],
  [-33.5371, 149.8825],
  [-33.5379, 149.8817],
  [-33.5420, 149.8799],
  [-33.5427, 149.8798],
  [-33.5443, 149.8801],
  [-33.5451, 149.8799],
  [-33.5505, 149.8766],
  [-33.5515, 149.8764],
  [-33.5523, 149.8764],
  [-33.5532, 149.8770],
  [-33.5537, 149.8771],
  [-33.5560, 149.8770],
  [-33.5566, 149.8773],
  [-33.5574, 149.8781],
  [-33.5587, 149.8789],
  [-33.5641, 149.8808],
  [-33.5651, 149.8815],
  [-33.5658, 149.8823],
  [-33.5663, 149.8826],
  [-33.5673, 149.8825],
  [-33.5695, 149.8814],
  [-33.5701, 149.8812],
  [-33.5720, 149.8813],
  [-33.5726, 149.8817],
  [-33.5733, 149.8825],
  [-33.5737, 149.8828],
  [-33.5745, 149.8828],
  [-33.5749, 149.8831],
  [-33.5752, 149.8835],
  [-33.5754, 149.8844],
  [-33.5757, 149.8849],
  [-33.5761, 149.8852],
  [-33.5772, 149.8857],
  [-33.5779, 149.8857],
  [-33.5785, 149.8855],
  [-33.5806, 149.8837],
  [-33.5815, 149.8825],
  [-33.5820, 149.8813],
  [-33.5826, 149.8791],
  [-33.5834, 149.8778],
  [-33.5845, 149.8770],
  [-33.5891, 149.8750],
  [-33.5897, 149.8749],
  [-33.5909, 149.8752],
  [-33.5914, 149.8751],
  [-33.5919, 149.8748],
  [-33.5927, 149.8740],
  [-33.5936, 149.8732],
  [-33.5956, 149.8725],
  [-33.5961, 149.8722],
  [-33.5964, 149.8717],
  [-33.5967, 149.8709],
  [-33.5971, 149.8705],
  [-33.5977, 149.8704],
  [-33.5983, 149.8707],
  [-33.5988, 149.8708],
  [-33.5993, 149.8705],
  [-33.6000, 149.8695],
  [-33.6006, 149.8690],
  [-33.6013, 149.8690],
  [-33.6019, 149.8692],
  [-33.6028, 149.8700],
  [-33.6032, 149.8703],
  [-33.6036, 149.8703],
  [-33.6043, 149.8699],
  [-33.6055, 149.8696],
  [-33.6068, 149.8694],
  [-33.6072, 149.8691],
  [-33.6084, 149.8681],
  [-33.6105, 149.8667],
  [-33.6109, 149.8660],
  [-33.6111, 149.8643],
  [-33.6114, 149.8636],
  [-33.6120, 149.8634],
  [-33.6128, 149.8638],
  [-33.6135, 149.8639],
  [-33.6154, 149.8636],
  [-33.6160, 149.8635],
  [-33.6189, 149.8643],
  [-33.6198, 149.8640],
  [-33.6214, 149.8621],
  [-33.6229, 149.8613],
  [-33.6240, 149.8615],
  [-33.6252, 149.8620],
  [-33.6257, 149.8626],
  [-33.6261, 149.8629],
  [-33.6287, 149.8641],
  [-33.6302, 149.8644],
  [-33.6305, 149.8644],
  [-33.6307, 149.8648],
  [-33.6310, 149.8657],
  [-33.6313, 149.8661],
  [-33.6323, 149.8668],
  [-33.6334, 149.8683],
  [-33.6338, 149.8686],
  [-33.6351, 149.8686],
  [-33.6357, 149.8690],
  [-33.6360, 149.8696],
  [-33.6364, 149.8713],
  [-33.6368, 149.8718],
  [-33.6394, 149.8735],
  [-33.6398, 149.8736],
  [-33.6403, 149.8734],
  [-33.6407, 149.8734],
  [-33.6413, 149.8736],
  [-33.6417, 149.8741],
  [-33.6421, 149.8743],
  [-33.6428, 149.8743],
  [-33.6440, 149.8751],
  [-33.6460, 149.8750],
  [-33.6476, 149.8742],
  [-33.6493, 149.8726],
  [-33.6499, 149.8713],
  [-33.6513, 149.8702],
  [-33.6537, 149.8696],
  [-33.6546, 149.8691],
  [-33.6575, 149.8670],
  [-33.6589, 149.8666],
  [-33.6615, 149.8664],
  [-33.6625, 149.8659],
  [-33.6648, 149.8631],
  [-33.6665, 149.8617],
  [-33.6687, 149.8603],
  [-33.6707, 149.8601],
  [-33.6714, 149.8602],
  [-33.6727, 149.8608],
  [-33.6737, 149.8610],
  [-33.6763, 149.8599],
  [-33.6942, 149.8564],
  [-33.6968, 149.8549],
  [-33.7022, 149.8538]
];

export const oberon: Line = {
  name: 'Oberon',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1923-10-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-11',
          status: 'closed'
        }]
      }
    }
  ]
};
