import { Line } from "../../../trackTypes";

const wendoureeDepot = [
  [-37.54340, 143.83115],
  [-37.54337, 143.83108],
  [-37.54332, 143.83105],
  [-37.54328, 143.83104],
  [-37.54291, 143.83112]
];

const wendoureeLoopSouthStart = [
  [-37.55369, 143.82234],
  [-37.55390, 143.82236],
  [-37.55523, 143.82252]
];

const morrisonStreet = [
  [-37.55523, 143.82252],
  [-37.55535, 143.82256],
  [-37.55554, 143.82270],
  [-37.55565, 143.82282],
  [-37.55571, 143.82295],
  [-37.55639, 143.82499],
  [-37.55644, 143.82505],
  [-37.55652, 143.82507],
  [-37.55807, 143.82478],
  [-37.55813, 143.82483],
  [-37.55814, 143.82492]
];

const hamiltonAvenue = [
  [-37.55523, 143.82252],
  [-37.55562, 143.82248],
  [-37.55694, 143.82217],
  [-37.55701, 143.82219],
  [-37.55706, 143.82224],
  [-37.55797, 143.82432],
  [-37.55806, 143.82462],
  [-37.55814, 143.82492]
];

const wendoureeLoopSouthEnd = [
  [-37.55814, 143.82492],
  [-37.55826, 143.82587],
  [-37.55970, 143.83823]
];

const wendoureeLoopNorth = [
  [-37.54379, 143.82731],
  [-37.54372, 143.82766],
  [-37.54356, 143.82867],
  [-37.54336, 143.83036],
  [-37.54335, 143.83068],
  [-37.54339, 143.83109],
  [-37.54363, 143.83305],
  [-37.54391, 143.83533],
  [-37.54392, 143.83557],
  [-37.54390, 143.83576],
  [-37.54376, 143.83679],
  [-37.54377, 143.83699],
  [-37.54381, 143.83724],
  [-37.54388, 143.83758],
  [-37.54412, 143.83836],
  [-37.54430, 143.83899],
  [-37.54443, 143.83955],
  [-37.54518, 143.84185],
  [-37.54524, 143.84223],
  [-37.54531, 143.84284],
  [-37.54536, 143.84306],
  [-37.54545, 143.84326],
  [-37.54593, 143.84397],
  [-37.54629, 143.84435],
  [-37.54645, 143.84445],
  [-37.54931, 143.84524],
  [-37.54967, 143.84526],
  [-37.55064, 143.84523],
  [-37.55095, 143.84521],
  [-37.55133, 143.84512],
  [-37.55164, 143.84500],
  [-37.55268, 143.84451],
  [-37.55282, 143.84438],
  [-37.55291, 143.84426],
  [-37.55371, 143.84301],
  [-37.55381, 143.84290],
  [-37.55393, 143.84284],
  [-37.55407, 143.84280],
  [-37.55478, 143.84267],
  [-37.55499, 143.84259],
  [-37.55537, 143.84243],
  [-37.55549, 143.84241],
  [-37.55681, 143.84218],
  [-37.55769, 143.84203],
  [-37.55777, 143.84198],
  [-37.55796, 143.84184],
  [-37.55815, 143.84175],
  [-37.55987, 143.84143],
  [-37.55993, 143.84149],
  [-37.55995, 143.84155]
];

const heritageSection = [
  [-37.54379, 143.82731],
  [-37.54407, 143.82641],
  [-37.54420, 143.82596],
  [-37.54462, 143.82476],
  [-37.54477, 143.82440],
  [-37.54499, 143.82396],
  [-37.54513, 143.82378],
  [-37.54544, 143.82346],
  [-37.54569, 143.82326],
  [-37.54608, 143.82304],
  [-37.54717, 143.82268],
  [-37.54784, 143.82250],
  [-37.55008, 143.82206],
  [-37.55044, 143.82205],
  [-37.55094, 143.82211],
  [-37.55204, 143.82248],
  [-37.55227, 143.82253],
  [-37.55256, 143.82253],
  [-37.55278, 143.82250],
  [-37.55325, 143.82239],
  [-37.55353, 143.82235],
  [-37.55369, 143.82234]
];

const tramMeuseum = [
  [-37.55094, 143.82211],
  [-37.55115, 143.82213],
  [-37.55122, 143.82210],
  [-37.55129, 143.82198],
  [-37.55156, 143.82099],
  [-37.55158, 143.82084],
  [-37.55153, 143.82028]
];

const sturtStreet = [
  [-37.55970, 143.83823],
  [-37.55985, 143.83907],
  [-37.55996, 143.83954],
  [-37.56019, 143.84152],
  [-37.56087, 143.84760],
  [-37.56192, 143.85646],
  [-37.56230, 143.85983],
  [-37.56229, 143.86059],
  [-37.56209, 143.85985],
  [-37.56186, 143.85794],
  [-37.56182, 143.85760],
  [-37.55995, 143.84155],
  [-37.55972, 143.83974],
  [-37.55970, 143.83823]
];

const riponStSouthJunction = [
  [-37.55987, 143.84143],
  [-37.56010, 143.84140],
  [-37.56015, 143.84144],
  [-37.56019, 143.84152]
];

const ballaratStation = [
  [-37.56182, 143.85760],
  [-37.56180, 143.85773],
  [-37.56175, 143.85779],
  [-37.55855, 143.85838]
];

const macArthurStreetWest = [
  [-37.54931, 143.84524],
  [-37.54939, 143.84532],
  [-37.54945, 143.84542],
  [-37.54949, 143.84554],
  [-37.54991, 143.84921]
];

const macArthurStreetEast = [
  [-37.54991, 143.84921],
  [-37.55112, 143.85955],
  [-37.55118, 143.85964],
  [-37.55124, 143.85969],
  [-37.55133, 143.85971]
];

const lydiardStreet = [
  [-37.55133, 143.85971],
  [-37.55855, 143.85838]
];

const lydiardStreetEastJunction = [
  [-37.56175, 143.85779],
  [-37.56180, 143.85783],
  [-37.56186, 143.85794]
];

const lydiardStreetSouthWestJunction = [
  [-37.56175, 143.85779],
  [-37.56201, 143.85773],
  [-37.56203, 143.85769],
  [-37.56205, 143.85759]
];

const drummondStreetNorth = [
  [-37.54991, 143.84921],
  [-37.54997, 143.84930],
  [-37.55008, 143.84937],
  [-37.55015, 143.84940],
  [-37.55456, 143.84859],
  [-37.56099, 143.84741]
];

const drummondStreetJunction = [
  [-37.56087, 143.84760],
  [-37.56083, 143.84752],
  [-37.56076, 143.84746],
  [-37.56069, 143.84755],
  [-37.56066, 143.84767],
  [-37.56062, 143.84757],
  [-37.56055, 143.84749]

];

const macArthurSouthJunction = [
  [-37.54967, 143.84526],
  [-37.54959, 143.84532],
  [-37.54952, 143.84542],
  [-37.54949, 143.84554]
];

const armstrongStreet = [
  [-37.56192, 143.85646],
  [-37.56196, 143.85631],
  [-37.56203, 143.85623],
  [-37.56701, 143.85532],
  [-37.56721, 143.85523],
  [-37.56738, 143.85502],
  [-37.56849, 143.85320],
  [-37.57031, 143.85007],
  [-37.57071, 143.84956],
  [-37.57314, 143.84687],
  [-37.57373, 143.84630],
  [-37.57568, 143.84470]
];

const rubiconStreet = [
  [-37.57568, 143.84470],
  [-37.57653, 143.84398],
  [-37.57699, 143.84362],
  [-37.57789, 143.84307],
  [-37.57978, 143.84201],
  [-37.58058, 143.84151],
  [-37.58092, 143.84132],
  [-37.58201, 143.84092]
];

const drummondStreet = [
  [-37.56087, 143.84760],
  [-37.56090, 143.84750],
  [-37.56099, 143.84741],
  [-37.57534, 143.84482],
  [-37.57545, 143.84480],
  [-37.57568, 143.84470]
];

const sebastopolTownHall = [
  [-37.58201, 143.84092],
  [-37.58256, 143.84082],
  [-37.58575, 143.84092],
  [-37.59970, 143.84115],
  [-37.60031, 143.84111],
  [-37.60059, 143.84108]
];

const royalMailHotel = [
  [-37.60059, 143.84108],
  [-37.60630, 143.84031],
  [-37.60732, 143.84018]
];

const sebastopolDepot = [
  [-37.60630, 143.84031],
  [-37.60638, 143.84025],
  [-37.60640, 143.84017],
  [-37.60633, 143.83977]
];

const darlingStreetDepot = [
  [-37.57571, 143.84467],
  [-37.57577, 143.84466],
  [-37.57584, 143.84471],
  [-37.57607, 143.84519]
];

const victoriaStreet = [
  [-37.56229, 143.86059],
  [-37.56274, 143.86444],
  [-37.56274, 143.86487],
  [-37.56260, 143.86641],
  [-37.56251, 143.86703],
  [-37.56231, 143.86820],
  [-37.56162, 143.87239],
  [-37.56142, 143.87341],
  [-37.56037, 143.87788],
  [-37.55902, 143.88406]
];

const gladstoneStreet = [
  [-37.56274, 143.86441],
  [-37.56276, 143.86448],
  [-37.56280, 143.86454],
  [-37.56385, 143.86541],
  [-37.56402, 143.86571],
  [-37.56444, 143.86652],
  [-37.56487, 143.86733],
  [-37.56496, 143.86737],
  [-37.56502, 143.86738],
  [-37.56509, 143.86734],
  [-37.56522, 143.86719],
  [-37.56543, 143.86697],
  [-37.56854, 143.86411],
  [-37.56873, 143.86392],
  [-37.57386, 143.85777]
];

const cobdenStreet = [
  [-37.57386, 143.85777],
  [-37.57583, 143.85542]
];

const powerhouse = [
  [-37.55556, 143.84239],
  [-37.55566, 143.84231],
  [-37.55573, 143.84223],
  [-37.55581, 143.84212],
  [-37.55595, 143.84205],
  [-37.55627, 143.84200]
];

const lydiardStreetNorth = [
  [-37.55133, 143.85971],
  [-37.54572, 143.86075]
];

const lydiardStreetNorthExtension = [
  [-37.54572, 143.86075],
  [-37.53705, 143.86241]
];

export const ballaratTrams: Line = {
  name: 'Ballarat Trams',
  state: 'VIC',
  segments: [
    {
      segments: [
        wendoureeLoopSouthStart,
        {
          date: '1905-08-18',
          original: [morrisonStreet],
          deviation: [hamiltonAvenue]
        },
        wendoureeLoopSouthEnd
      ],
      history: {
        opened: {
          date: '1887-12-21',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wendoureeLoopNorth, sturtStreet, riponStSouthJunction, ballaratStation],
      history: {
        opened: {
          date: '1887-12-21',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wendoureeDepot],
      history: {
        opened: {
          date: '1887-12-21',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-08-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heritageSection],
      history: {
        opened: {
          date: '1887-12-21',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }, {
          date: '1974-12-26',
          status: 'open'
        }]
      }
    },
    {
      segments: [macArthurStreetWest, macArthurSouthJunction],
      history: {
        opened: {
          date: '1888-12-27',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1970-03-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [drummondStreetNorth, drummondStreetJunction],
      history: {
        opened: {
          date: '1905-08-30',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1970-03-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [macArthurStreetEast],
      history: {
        opened: {
          date: '1888-12-27',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lydiardStreet],
      history: {
        opened: {
          date: '1888-12-27',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [armstrongStreet],
      history: {
        opened: {
          date: '1888-04-01',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1907-03-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rubiconStreet],
      history: {
        opened: {
          date: '1888-04-01',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1908-03-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [darlingStreetDepot],
      history: {
        opened: {
          date: '1888-04-01',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1908-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [drummondStreet],
      history: {
        opened: {
          date: '1888-04-29',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1907-03-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sebastopolTownHall],
      history: {
        opened: {
          date: '1893-03-30',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1913-04-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [royalMailHotel],
      history: {
        opened: {
          date: '1893-04-19',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1913-04-14',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sebastopolDepot],
      history: {
        opened: {
          date: '1893-04-19',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1913-04-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaStreet],
      history: {
        opened: {
          date: '1905-08-18',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1971-08-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gladstoneStreet],
      history: {
        opened: {
          date: '1905-04-26',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-09-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cobdenStreet],
      history: {
        opened: {
          date: '1906-01-01',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1971-09-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lydiardStreetNorth, lydiardStreetEastJunction, lydiardStreetSouthWestJunction],
      history: {
        opened: {
          date: '1905-08-30',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [powerhouse],
      history: {
        opened: {
          date: '1906-01-01',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lydiardStreetNorthExtension],
      history: {
        opened: {
          date: '1937-08-28',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1971-09-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tramMeuseum],
      history: {
        opened: {
          date: '1974-12-26',
          gauge: 'standard',
          tracks: 1,
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
}
