import { Line } from "../../../trackTypes";

const portHeadlandYandiJunction = [
  [-20.3175, 118.6236],
  [-20.3170, 118.6205],
  [-20.3172, 118.6099],
  [-20.3237, 118.5912],
  [-20.3238, 118.5899],
  [-20.3225, 118.5874],
  [-20.3214, 118.5867],
  [-20.3204, 118.5867],
  [-20.3195, 118.5874],
  [-20.3137, 118.6030],
  [-20.3135, 118.6044],
  [-20.3136, 118.6090],
  [-20.3161, 118.6206],
  [-20.3175, 118.6236],
  [-20.3214, 118.6305],
  [-20.3272, 118.6350],
  [-20.3307, 118.6358],
  [-20.3398, 118.6324],
  [-20.3413, 118.6321],
  [-20.3565, 118.6330],
  [-20.3643, 118.6367],
  [-20.3722, 118.6472],
  [-20.3766, 118.6502],
  [-20.3811, 118.6498],
  [-20.3921, 118.6437],
  [-20.3989, 118.6402],
  [-20.4017, 118.6394],
  [-20.4414, 118.6285],
  [-20.4531, 118.6284],
  [-20.5588, 118.6597],
  [-20.7003, 118.6701],
  [-20.8555, 118.6818],
  [-20.9241, 118.6797],
  [-20.9290, 118.6803],
  [-20.9824, 118.7012],
  [-21.0093, 118.7194],
  [-21.0463, 118.7334],
  [-21.0543, 118.7393],
  [-21.0735, 118.7616],
  [-21.1567, 118.8312],
  [-21.1708, 118.8373],
  [-21.2199, 118.8375],
  [-21.2243, 118.8362],
  [-21.2330, 118.8324],
  [-21.2365, 118.8320],
  [-21.2402, 118.8327],
  [-21.3864, 118.8989],
  [-21.4274, 118.9263],
  [-21.4336, 118.9287],
  [-21.4838, 118.9305],
  [-21.4873, 118.9301],
  [-21.5742, 118.9034],
  [-21.6277, 118.8819],
  [-21.6599, 118.8782],
  [-21.7027, 118.8607],
  [-21.7102, 118.8596],
  [-21.7192, 118.8608],
  [-21.7838, 118.8861],
  [-21.8132, 118.9097],
  [-21.8484, 118.9294],
  [-21.8730, 118.9380],
  [-21.8793, 118.9428],
  [-21.8868, 118.9535],
  [-21.9018, 118.9656],
  [-21.9351, 118.9789],
  [-21.9420, 118.9831],
  [-21.9619, 119.0003],
  [-21.9817, 119.0103],
  [-21.9860, 119.0109],
  [-22.0277, 119.0071],
  [-22.0308, 119.0056],
  [-22.0409, 118.9969],
  [-22.0444, 118.9952],
  [-22.0492, 118.9951],
  [-22.0527, 118.9916],
  [-22.0559, 118.9815],
  [-22.0582, 118.9785],
  [-22.0616, 118.9771],
  [-22.0669, 118.9787],
  [-22.0692, 118.9827],
  [-22.0710, 118.9922],
  [-22.0728, 118.9959],
  [-22.0780, 119.0004],
  [-22.0816, 119.0012],
  [-22.0845, 119.0003],
  [-22.0872, 118.9985],
  [-22.0915, 118.9983],
  [-22.1102, 119.0107],
  [-22.1146, 119.0119],
  [-22.1215, 119.0125],
  [-22.1254, 119.0149],
  [-22.1267, 119.0179],
  [-22.1270, 119.0247],
  [-22.1297, 119.0285],
  [-22.1340, 119.0287],
  [-22.1406, 119.0254],
  [-22.1439, 119.0245],
  [-22.1586, 119.0227],
  [-22.1619, 119.0231],
  [-22.1743, 119.0293],
  [-22.1814, 119.0303],
  [-22.1910, 119.0281],
  [-22.1952, 119.0279],
  [-22.2041, 119.0290],
  [-22.2063, 119.0285],
  [-22.2093, 119.0258],
  [-22.2127, 119.0249],
  [-22.2165, 119.0261],
  [-22.2940, 119.0235],
  [-22.3603, 119.0050],
  [-22.3736, 118.9968],
  [-22.3812, 118.9943],
  [-22.3908, 118.9955],
  [-22.4323, 119.0123],
  [-22.4388, 119.0166],
  [-22.4739, 119.0503],
  [-22.4780, 119.0562],
  [-22.5429, 119.1888]
];

const yandiJunctionYandi = [
  [-22.5429, 119.1888],
  [-22.5471, 119.1920],
  [-22.5510, 119.1925],
  [-22.5807, 119.1927],
  [-22.5837, 119.1805],
  [-22.5894, 119.1696],
  [-22.5898, 119.1665],
  [-22.5891, 119.1599],
  [-22.5899, 119.1557],
  [-22.5927, 119.1517],
  [-22.5971, 119.1488],
  [-22.6029, 119.1402],
  [-22.6038, 119.1370],
  [-22.6039, 119.1332],
  [-22.6048, 119.1300],
  [-22.6141, 119.1119],
  [-22.6197, 119.0892],
  [-22.6231, 119.0855],
  [-22.6289, 119.0853],
  [-22.6343, 119.0826],
  [-22.6377, 119.0820],
  [-22.6489, 119.0852],
  [-22.6657, 119.0942],
  [-22.6889, 119.1027],
  [-22.6948, 119.1031],
  [-22.7019, 119.1011],
  [-22.7106, 119.0942],
  [-22.7149, 119.0832],
  [-22.7152, 119.0766],
  [-22.7139, 119.0572],
  [-22.7130, 119.0503],
  [-22.7112, 119.0458],
  [-22.7111, 119.0433],
  [-22.7125, 119.0402],
  [-22.7155, 119.0390],
  [-22.7184, 119.0400],
  [-22.7210, 119.0439],
  [-22.7212, 119.0471],
  [-22.7198, 119.0495],
  [-22.7159, 119.0523],
  [-22.7145, 119.0543],
  [-22.7139, 119.0572]
];

const yandiJunctionAreaC = [
  [-22.7019, 119.1011],
  [-22.7049, 119.1010],
  [-22.7179, 119.1052],
  [-22.7226, 119.1079],
  [-22.7252, 119.1111],
  [-22.7269, 119.1147],
  [-22.7291, 119.1166],
  [-22.7412, 119.1212],
  [-22.7433, 119.1228],
  [-22.7449, 119.1249],
  [-22.7474, 119.1270],
  [-22.7525, 119.1292],
  [-22.7549, 119.1313],
  [-22.7599, 119.1391],
  [-22.7632, 119.1418],
  [-22.7756, 119.1459],
  [-22.7810, 119.1476],
  [-22.8025, 119.1482],
  [-22.8078, 119.1457],
  [-22.8134, 119.1372],
  [-22.8171, 119.1338],
  [-22.8235, 119.1322],
  [-22.8412, 119.1249],
  [-22.8443, 119.1220],
  [-22.8460, 119.1193],
  [-22.8480, 119.1174],
  [-22.8529, 119.1151],
  [-22.8553, 119.1131],
  [-22.8585, 119.1109],
  [-22.8654, 119.1102],
  [-22.8697, 119.1078],
  [-22.8716, 119.1051],
  [-22.8728, 119.1020],
  [-22.8746, 119.0993],
  [-22.8799, 119.0943],
  [-22.8819, 119.0903],
  [-22.8828, 119.0843],
  [-22.8843, 119.0810],
  [-22.8869, 119.0785],
  [-22.8977, 119.0735],
  [-22.9031, 119.0677],
  [-22.9041, 119.0655],
  [-22.9049, 119.0590],
  [-22.9066, 119.0560],
  [-22.9108, 119.0530],
  [-22.9121, 119.0499],
  [-22.9125, 119.0302],
  [-22.9117, 119.0095],
  [-22.9125, 119.0053],
  [-22.9135, 119.0030],
  [-22.9186, 118.9976],
  [-22.9203, 118.9939],
  [-22.9219, 118.9832],
  [-22.9215, 118.9806],
  [-22.9187, 118.9772],
  [-22.9160, 118.9765],
  [-22.9137, 118.9777],
  [-22.9126, 118.9802],
  [-22.9127, 118.9825],
  [-22.9135, 118.9853],
  [-22.9135, 118.9923],
  [-22.9126, 118.9945],
  [-22.9113, 118.9963],
  [-22.9104, 118.9994],
  [-22.9106, 119.0054],
  [-22.9125, 119.0302]
];

const yandiJunctionjimblebarJunction = [
  [-22.5429, 119.1888],
  [-22.5666, 119.2371],
  [-22.5866, 119.2628],
  [-22.6215, 119.3254],
  [-22.6511, 119.4109],
  [-22.7846, 119.6375],
  [-22.7910, 119.6446],
  [-22.8165, 119.6622],
  [-22.8775, 119.6959],
  [-22.9648, 119.7656],
  [-23.0834, 119.8791],
  [-23.0877, 119.8819],
  [-23.1389, 119.9011],
  [-23.1464, 119.9025],
  [-23.2102, 119.9030],
  [-23.2190, 119.9012],
  [-23.2571, 119.8850],
  [-23.2741, 119.8751],
  [-23.2799, 119.8728],
  [-23.2929, 119.8648],
  [-23.2966, 119.8631],
  [-23.2998, 119.8621]
];

const jimblebarTriangle = [
  [-23.3087, 119.8579],
  [-23.3076, 119.8606],
  [-23.3076, 119.8628],
  [-23.3096, 119.8669]
];

const jimblebarJunctionJimblebar = [
  [-23.2998, 119.8621],
  [-23.3038, 119.8624],
  [-23.3066, 119.8638],
  [-23.3094, 119.8665],
  [-23.3148, 119.8769],
  [-23.3155, 119.8796],
  [-23.3180, 119.9126],
  [-23.3187, 119.9160],
  [-23.3301, 119.9527],
  [-23.3365, 119.9839],
  [-23.3430, 120.0063],
  [-23.3453, 120.0098],
  [-23.3529, 120.0142],
  [-23.3565, 120.0184],
  [-23.3644, 120.0414],
  [-23.3669, 120.0440],
  [-23.3702, 120.0454],
  [-23.3803, 120.0447],
  [-23.3838, 120.0461],
  [-23.3856, 120.0495],
  [-23.3850, 120.0623],
  [-23.3853, 120.0652],
  [-23.3876, 120.0737],
  [-23.3886, 120.1230]
];

const jimblebarJunctionMountWhaleback = [
  [-23.2998, 119.8621],
  [-23.3075, 119.8587],
  [-23.3152, 119.8532],
  [-23.3361, 119.8348],
  [-23.3404, 119.8269],
  [-23.3465, 119.7881],
  [-23.3466, 119.7836],
  [-23.3454, 119.7788],
  [-23.3340, 119.7556],
  [-23.3333, 119.7531],
  [-23.3333, 119.7508],
  [-23.3353, 119.7380],
  [-23.3360, 119.7360],
  [-23.3440, 119.7209],
  [-23.3496, 119.7137],
  [-23.3560, 119.7090],
  [-23.3586, 119.7075],
  [-23.3633, 119.7050],
  [-23.3657, 119.7026],
  [-23.3697, 119.6944],
  [-23.3714, 119.6920],
  [-23.3733, 119.6907],
  [-23.3790, 119.6887],
  [-23.3804, 119.6869],
  [-23.3806, 119.6844],
  [-23.3791, 119.6816],
  [-23.3769, 119.6805],
  [-23.3747, 119.6812],
  [-23.3728, 119.6831],
  [-23.3647, 119.6984],
  [-23.3625, 119.7013],
  [-23.3598, 119.7061],
  [-23.3586, 119.7075]
];

export const mountNewman: Line = {
  name: 'Mount Newman',
  state: 'WA',
  segments: [
    {
      segments: [portHeadlandYandiJunction],
      history: {
        opened: {
          date: '1969-01-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [yandiJunctionYandi],
      history: {
        opened: {
          date: '1991-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [yandiJunctionAreaC],
      history: {
        opened: {
          date: '2003-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [yandiJunctionjimblebarJunction],
      history: {
        opened: {
          date: '1969-01-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [jimblebarTriangle],
      history: {
        opened: {
          date: '1998-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [jimblebarJunctionMountWhaleback],
      history: {
        opened: {
          date: '1969-01-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [jimblebarJunctionJimblebar],
      history: {
        opened: {
          date: '1998-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
