import { Line } from "../../../trackTypes";

const paeroaThames = [
  [-37.3772, 175.6672],
  [-37.3748, 175.6675],
  [-37.3496, 175.6627],
  [-37.2859, 175.6506],
  [-37.2833, 175.6500],
  [-37.2600, 175.6429],
  [-37.2372, 175.6359],
  [-37.2351, 175.6349],
  [-37.2229, 175.6242],
  [-37.2176, 175.6178],
  [-37.1945, 175.5765],
  [-37.1926, 175.5738],
  [-37.1811, 175.5632],
  [-37.1651, 175.5557],
  [-37.1618, 175.5547],
  [-37.1521, 175.5508],
  [-37.1506, 175.5505],
  [-37.1489, 175.5496],
  [-37.1475, 175.5483],
  [-37.1462, 175.5457],
  [-37.1460, 175.5451],
  [-37.1459, 175.5444],
  [-37.1458, 175.5440],
  [-37.1455, 175.5437],
  [-37.1424, 175.5420]
];

export const thames: Line = {
  name: 'Thames',
  state: 'NZ',
  segments: [
    {
      segments: [paeroaThames],
      history: {
        opened: {
          date: '1898-12-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-03-31',
          status: 'closed'
        }]
      }
    }
  ]
}
