import { Line } from "../../../../trackTypes";

const marketStreetConnection = [
  [-37.81763, 144.95912],
  [-37.81845, 144.95948],
  [-37.81854, 144.95961],
  [-37.81856, 144.95979],
  [-37.81839, 144.96043],
  [-37.81839, 144.96060],
  [-37.81848, 144.96072]
];

const queensBridge = [
  [-37.81848, 144.96072],
  [-37.81940, 144.96115],
  [-37.82005, 144.96136],
  [-37.82145, 144.96138],
  [-37.82547, 144.96009]
];

const queensBridgeConnector = [
  [-37.82547, 144.96009],
  [-37.82598, 144.95992],
  [-37.82621, 144.95978],
  [-37.82764, 144.95809]
];

const kingsway = [
  [-37.82547, 144.96009],
  [-37.82557, 144.96010],
  [-37.82569, 144.96014],
  [-37.83066, 144.96504],
  [-37.83094, 144.96532]
];

const kingswayJunction = [
  [-37.83066, 144.96504],
  [-37.83079, 144.96509],
  [-37.83097, 144.96509]
];

const spencerStreetConnection = [
  [-37.82090, 144.95497],
  [-37.82510, 144.95693],
  [-37.82764, 144.95809]
];

const whitemanStJunction = [
  [-37.82510, 144.95693],
  [-37.82536, 144.95695],
  [-37.82557, 144.95682]
];

const parkStreet = [
  [-37.82764, 144.95809],
  [-37.83507, 144.96152]
];

const parkStreetSouthMelbourne = [
  [-37.83526, 144.96143],
  [-37.83731, 144.95438],
  [-37.83742, 144.95427],
  [-37.83755, 144.95426],
  [-37.84055, 144.95563],
  [-37.84067, 144.95560],
  [-37.84076, 144.95546],
  [-37.84127, 144.95372],
  [-37.84144, 144.95345],
  [-37.84746, 144.94847]
];

const parkStreetNorthWestJunction = [
  [-37.83507, 144.96152],
  [-37.83516, 144.96152],
  [-37.83526, 144.96143]
];

const parkStreetNorthSouth = [
  [-37.83507, 144.96152],
  [-37.83536, 144.96167]
];

const parkStreetEastWest = [
  [-37.83516, 144.96180],
  [-37.83526, 144.96143]
];

export const south3: Line = {
  name: 'South3',
  state: 'VIC',
  segments: [
    {
      segments: [marketStreetConnection],
      history: {
        opened: {
          date: '1946-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [queensBridge],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }, {
          date: '1946-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [queensBridgeConnector],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsway],
      history: {
        opened: {
          date: '1946-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [kingswayJunction],
      history: {
        opened: {
          date: '2009-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [spencerStreetConnection],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [whitemanStJunction],
      history: {
        opened: {
          date: '1987-11-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [parkStreet],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [parkStreetSouthMelbourne],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [parkStreetNorthWestJunction],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1937-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parkStreetEastWest, parkStreetNorthSouth],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
