import { Line } from "../../../trackTypes";

const traralgonCowwarr = [
  [-38.1941, 146.5571],
  [-38.1935, 146.5585],
  [-38.1926, 146.5597],
  [-38.1914, 146.5605],
  [-38.1901, 146.5609],
  [-38.1608, 146.5670],
  [-38.1229, 146.5750],
  [-38.1205, 146.5760],
  [-38.1175, 146.5783],
  [-38.1106, 146.5867],
  [-38.1092, 146.5888],
  [-38.1078, 146.5915],
  [-38.1068, 146.5928],
  [-38.1053, 146.5935],
  [-38.1025, 146.5941],
  [-38.1010, 146.5949],
  [-38.0648, 146.6193],
  [-38.0633, 146.6210],
  [-38.0603, 146.6259],
  [-38.0597, 146.6264],
  [-38.0587, 146.6269],
  [-38.0537, 146.6279],
  [-38.0522, 146.6287],
  [-38.0512, 146.6297],
  [-38.0245, 146.6721],
  [-38.0146, 146.6919]
];

const cowwarrHeyfield = [
  [-38.0146, 146.6919],
  [-38.0134, 146.6943],
  [-38.0125, 146.6955],
  [-38.0113, 146.6963],
  [-38.0101, 146.6966],
  [-37.9948, 146.6991],
  [-37.9933, 146.6997],
  [-37.9920, 146.7005],
  [-37.9896, 146.7027],
  [-37.9883, 146.7043],
  [-37.9871, 146.7069],
  [-37.9763, 146.7601],
  [-37.9762, 146.7619],
  [-37.9763, 146.7634],
  [-37.9768, 146.7663],
  [-37.9771, 146.7675],
  [-37.9772, 146.7690],
  [-37.9773, 146.7782],
  [-37.9773, 146.7794],
  [-37.9778, 146.7810],
  [-37.9789, 146.7829],
  [-37.9793, 146.7842],
  [-37.9796, 146.7872]
];

const heyfieldMaffra = [
  [-37.9796, 146.7872],
  [-37.9799, 146.7913],
  [-37.9798, 146.7936],
  [-37.9836, 146.8234],
  [-37.9835, 146.8258],
  [-37.9776, 146.8567],
  [-37.9765, 146.8602],
  [-37.9650, 146.8855],
  [-37.9643, 146.8881],
  [-37.9643, 146.8916],
  [-37.9667, 146.9114],
  [-37.9665, 146.9156],
  [-37.9661, 146.9206],
  [-37.9718, 146.9674],
  [-37.9709, 146.9731],
  [-37.9716, 146.9804]
];

const maffraStratfordJunction = [
  [-37.9716, 146.9804],
  [-37.9725, 146.9879],
  [-37.9726, 146.9893],
  [-37.9729, 146.9923],
  [-37.9751, 147.0112],
  [-37.9815, 147.0646],
  [-37.9814, 147.0665],
  [-37.9809, 147.0687],
  [-37.9801, 147.0705]
];

const briagolong = [
  [-37.9729, 146.9923],
  [-37.9727, 146.9934],
  [-37.9722, 146.9947],
  [-37.9716, 146.9953],
  [-37.9707, 146.9958],
  [-37.9565, 146.9988],
  [-37.9542, 146.9989],
  [-37.9502, 146.9982],
  [-37.9372, 146.9938],
  [-37.9300, 146.9906],
  [-37.9276, 146.9903],
  [-37.9259, 146.9905],
  [-37.9245, 146.9901],
  [-37.9203, 146.9881],
  [-37.9156, 146.9874],
  [-37.9076, 146.9892],
  [-37.8963, 146.9919],
  [-37.8948, 146.9918],
  [-37.8840, 146.9893],
  [-37.8816, 146.9896],
  [-37.8796, 146.9909],
  [-37.8784, 146.9924],
  [-37.8739, 147.0032],
  [-37.8737, 147.0064],
  [-37.8730, 147.0110],
  [-37.8714, 147.0143],
  [-37.8649, 147.0300],
  [-37.8584, 147.0399],
  [-37.8525, 147.0541],
  [-37.8462, 147.0716]
];

export const maffra: Line = {
  name: 'Maffra',
  state: 'VIC',
  segments: [
    {
      segments: [traralgonCowwarr],
      history: {
        opened: {
          date: '1883-11-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cowwarrHeyfield],
      history: {
        opened: {
          date: '1883-11-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-08-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heyfieldMaffra],
      history: {
        opened: {
          date: '1887-03-18',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-08-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maffraStratfordJunction],
      history: {
        opened: {
          date: '1887-11-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-08-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [briagolong],
      history: {
        opened: {
          date: '1889-08-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-07-16',
          status: 'closed'
        }]
      }
    }
  ]
};
