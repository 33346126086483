import { Line } from "../../trackTypes";

const gawlerCentralNuriootpa = [
  [-34.5944, 138.7484],
  [-34.5943, 138.7495],
  [-34.5937, 138.7506],
  [-34.5928, 138.7513],
  [-34.5916, 138.7519],
  [-34.5907, 138.7529],
  [-34.5901, 138.7544],
  [-34.5902, 138.7576],
  [-34.5899, 138.7599],
  [-34.5903, 138.7631],
  [-34.5908, 138.7642],
  [-34.5925, 138.7669],
  [-34.5936, 138.7679],
  [-34.5943, 138.7689],
  [-34.5964, 138.7738],
  [-34.5969, 138.7767],
  [-34.5979, 138.7800],
  [-34.6021, 138.7892],
  [-34.6028, 138.7918],
  [-34.6031, 138.8040],
  [-34.6023, 138.8125],
  [-34.6020, 138.8137],
  [-34.6015, 138.8150],
  [-34.6014, 138.8160],
  [-34.6017, 138.8173],
  [-34.6023, 138.8182],
  [-34.6040, 138.8202],
  [-34.6045, 138.8214],
  [-34.6046, 138.8230],
  [-34.6021, 138.8301],
  [-34.6019, 138.8315],
  [-34.6020, 138.8325],
  [-34.6024, 138.8351],
  [-34.6023, 138.8362],
  [-34.6012, 138.8412],
  [-34.6011, 138.8430],
  [-34.6012, 138.8472],
  [-34.6010, 138.8491],
  [-34.6004, 138.8515],
  [-34.5998, 138.8532],
  [-34.5991, 138.8546],
  [-34.5989, 138.8554],
  [-34.5988, 138.8564],
  [-34.5982, 138.8579],
  [-34.5976, 138.8586],
  [-34.5960, 138.8600],
  [-34.5952, 138.8607],
  [-34.5925, 138.8638],
  [-34.5920, 138.8646],
  [-34.5916, 138.8658],
  [-34.5915, 138.8670],
  [-34.5916, 138.8679],
  [-34.5937, 138.8768],
  [-34.5942, 138.8781],
  [-34.6001, 138.8866],
  [-34.6030, 138.8891],
  [-34.6090, 138.8946],
  [-34.6097, 138.8956],
  [-34.6099, 138.8973],
  [-34.6095, 138.8987],
  [-34.6085, 138.8997],
  [-34.6075, 138.9001],
  [-34.6064, 138.9000],
  [-34.6056, 138.8999],
  [-34.6046, 138.9001],
  [-34.6037, 138.9007],
  [-34.6030, 138.9021],
  [-34.6028, 138.9032],
  [-34.6030, 138.9042],
  [-34.6033, 138.9053],
  [-34.6034, 138.9064],
  [-34.6032, 138.9073],
  [-34.6028, 138.9082],
  [-34.6004, 138.9112],
  [-34.5915, 138.9224],
  [-34.5900, 138.9247],
  [-34.5879, 138.9282],
  [-34.5858, 138.9332],
  [-34.5850, 138.9344],
  [-34.5821, 138.9374],
  [-34.5808, 138.9382],
  [-34.5794, 138.9389],
  [-34.5780, 138.9399],
  [-34.5768, 138.9413],
  [-34.5737, 138.9464],
  [-34.5715, 138.9515],
  [-34.5707, 138.9526],
  [-34.5698, 138.9532],
  [-34.5688, 138.9533],
  [-34.5668, 138.9533],
  [-34.5653, 138.9537],
  [-34.5464, 138.9644],
  [-34.5331, 138.9689],
  [-34.5314, 138.9687],
  [-34.5300, 138.9676],
  [-34.5291, 138.9663],
  [-34.5284, 138.9639],
  [-34.5273, 138.9623],
  [-34.5260, 138.9618],
  [-34.5245, 138.9619],
  [-34.5231, 138.9631],
  [-34.5162, 138.9758],
  [-34.5151, 138.9770],
  [-34.5140, 138.9778],
  [-34.4806, 138.9923],
  [-34.4797, 138.9931],
  [-34.4791, 138.9946],
  [-34.4792, 138.9958],
  [-34.4806, 139.0007]
];

const nuriootpaAngaston = [
  [-34.4806, 139.0007],
  [-34.4940, 139.0121],
  [-34.4952, 139.0130],
  [-34.4985, 139.0149],
  [-34.5021, 139.0170],
  [-34.5031, 139.0180],
  [-34.5035, 139.0191],
  [-34.5035, 139.0206],
  [-34.5009, 139.0298],
  [-34.5002, 139.0306],
  [-34.4989, 139.0316],
  [-34.4982, 139.0328],
  [-34.4982, 139.0344],
  [-34.4985, 139.0353],
  [-34.4996, 139.0366],
  [-34.5000, 139.0380],
  [-34.4997, 139.0396],
  [-34.4997, 139.0406],
  [-34.5000, 139.0415],
  [-34.5015, 139.0445]
];

const nuriootpaPenriceJunction = [
  [-34.4806, 139.0007],
  [-34.4813, 139.0036],
  [-34.4813, 139.0048],
  [-34.4811, 139.0059],
  [-34.4706, 139.0355],
  [-34.4699, 139.0367],
  [-34.4683, 139.0388],
];

const penriceJunctionTruro = [
  [-34.4683, 139.0388],
  [-34.4616, 139.0462],
  [-34.4601, 139.0474],
  [-34.4530, 139.0500],
  [-34.4481, 139.0529],
  [-34.4468, 139.0533],
  [-34.4407, 139.0543],
  [-34.4366, 139.0554],
  [-34.4356, 139.0562],
  [-34.4340, 139.0584],
  [-34.4328, 139.0610],
  [-34.4322, 139.0629],
  [-34.4315, 139.0640],
  [-34.4298, 139.0656],
  [-34.4291, 139.0665],
  [-34.4287, 139.0676],
  [-34.4273, 139.0760],
  [-34.4233, 139.0848],
  [-34.4223, 139.0863],
  [-34.4212, 139.0872],
  [-34.4202, 139.0886],
  [-34.4156, 139.0992],
  [-34.4144, 139.1007],
  [-34.4083, 139.1044],
  [-34.4071, 139.1056],
  [-34.4064, 139.1071],
  [-34.4061, 139.1091],
  [-34.4056, 139.1171],
  [-34.4058, 139.1183],
  [-34.4068, 139.1208],
  [-34.4070, 139.1218],
  [-34.4071, 139.1247],
  [-34.4072, 139.1256],
  [-34.4089, 139.1313]
];

export const barossaValley: Line = {
  name: 'Barossa Valley',
  state: 'SA',
  segments: [
    {
      segments: [gawlerCentralNuriootpa],
      history: {
        opened: {
          date: '1911-09-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nuriootpaAngaston],
      history: {
        opened: {
          date: '1911-09-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nuriootpaPenriceJunction],
      history: {
        opened: {
          date: '1917-09-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [penriceJunctionTruro],
      history: {
        opened: {
          date: '1917-09-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
