import { Line } from "../../../trackTypes";

const line = [
  [-37.5329, 175.1594],
  [-37.5319, 175.1598],
  [-37.5312, 175.1606],
  [-37.5310, 175.1616],
  [-37.5311, 175.1625],
  [-37.5315, 175.1633],
  [-37.5372, 175.1705],
  [-37.5379, 175.1711],
  [-37.5386, 175.1714],
  [-37.5394, 175.1723],
  [-37.5405, 175.1761],
  [-37.5414, 175.1786],
  [-37.5414, 175.1803],
  [-37.5409, 175.1814],
  [-37.5396, 175.1829]
];

export const kimihiaColliery: Line = {
  name: 'Kimihia Colliery',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1887-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          status: 'closed'
        }, {
          date: '2015-10-21',
          status: 'closed'
        }, {
          date: '1946-01-01',
          status: 'open'
        }]
      }
    }
  ]
}
