import { Line } from "../../../trackTypes";

const roeStreetTunnel = [
  [-31.9464, 115.8496],
  [-31.9460, 115.8491],
  [-31.9457, 115.8490]
];

const perthJoondalip = [
  [-31.9457, 115.8490],
  [-31.9451, 115.8489],
  [-31.9442, 115.8489],
  [-31.9437, 115.8488],
  [-31.9432, 115.8484],
  [-31.9430, 115.8479],
  [-31.9411, 115.8434],
  [-31.9400, 115.8415],
  [-31.9381, 115.8394],
  [-31.9367, 115.8384],
  [-31.9327, 115.8362],
  [-31.9306, 115.8346],
  [-31.9292, 115.8334],
  [-31.9234, 115.8269],
  [-31.9209, 115.8250],
  [-31.9186, 115.8240],
  [-31.9157, 115.8232],
  [-31.9051, 115.8219],
  [-31.9020, 115.8208],
  [-31.8998, 115.8190],
  [-31.8984, 115.8171],
  [-31.8974, 115.8146],
  [-31.8960, 115.8081],
  [-31.8949, 115.8056],
  [-31.8934, 115.8038],
  [-31.8916, 115.8025],
  [-31.8901, 115.8019],
  [-31.8837, 115.8007],
  [-31.8795, 115.8004],
  [-31.8620, 115.8015],
  [-31.8578, 115.8009],
  [-31.8402, 115.7946],
  [-31.8368, 115.7927],
  [-31.8251, 115.7850],
  [-31.8221, 115.7837],
  [-31.8197, 115.7832],
  [-31.7855, 115.7813],
  [-31.7756, 115.7799],
  [-31.7729, 115.7791],
  [-31.7689, 115.7765],
  [-31.7610, 115.7693],
  [-31.7587, 115.7681],
  [-31.7482, 115.7673],
  [-31.7445, 115.7674]
];

const joondalupCurrambine = [
  [-31.7445, 115.7674],
  [-31.7422, 115.7675],
  [-31.7409, 115.7670],
  [-31.7401, 115.7660],
  [-31.7397, 115.7648],
  [-31.7394, 115.7623],
  [-31.7389, 115.7611],
  [-31.7367, 115.7569],
  [-31.7351, 115.7555],
  [-31.7278, 115.7516],
  [-31.7261, 115.7509],
  [-31.7249, 115.7505]
];

const currambineClarkson = [
  [-31.7249, 115.7505],
  [-31.7224, 115.7498],
  [-31.7203, 115.7496],
  [-31.7162, 115.7493],
  [-31.7141, 115.7489],
  [-31.7118, 115.7479],
  [-31.7025, 115.7425],
  [-31.6994, 115.7410],
  [-31.6906, 115.7380]
];

const clarksonButler = [
  [-31.6906, 115.7380],
  [-31.6878, 115.7372],
  [-31.6799, 115.7351],
  [-31.6766, 115.7339],
  [-31.6578, 115.7262],
  [-31.6537, 115.7241],
  [-31.6507, 115.7221],
  [-31.6453, 115.7183],
  [-31.6438, 115.7166],
  [-31.6428, 115.7144],
  [-31.6411, 115.7066],
  [-31.6402, 115.7042],
  [-31.6393, 115.7029],
  [-31.6381, 115.7018],
  [-31.6369, 115.7010],
  [-31.6346, 115.6997]
];

const butlerYancheep = [
  [-31.6346, 115.6997],
  [-31.6327, 115.6988],
  [-31.6275, 115.6971],
  [-31.6209, 115.6939],
  [-31.6088, 115.6912],
  [-31.5989, 115.6887],
  [-31.5963, 115.6872],
  [-31.5943, 115.6850],
  [-31.5894, 115.6764],
  [-31.5864, 115.6736],
  [-31.5775, 115.6707],
  [-31.5742, 115.6704],
  [-31.5701, 115.6710],
  [-31.5670, 115.6707],
  [-31.5618, 115.6678],
  [-31.5587, 115.6639],
  [-31.5575, 115.6610],
  [-31.5565, 115.6550],
  [-31.5553, 115.6521],
  [-31.5533, 115.6497],
  [-31.5460, 115.6449],
  [-31.5433, 115.6440],
  [-31.5227, 115.6436]
];

export const yanchep: Line = {
  name: 'Yanchep',
  state: 'WA',
  segments: [
    {
      segments: [
        {
          name: 'Roe Street Tunnel',
          segment: roeStreetTunnel,
          type: 'tunnel'
        },
        perthJoondalip
      ],
      history: {
        opened: {
          date: '1992-12-20',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [joondalupCurrambine],
      history: {
        opened: {
          date: '1993-08-08',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [currambineClarkson],
      history: {
        opened: {
          date: '2004-10-04',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [clarksonButler],
      history: {
        opened: {
          date: '2014-09-21',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [butlerYancheep],
      history: {
        opened: {
          date: '2024-07-14',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
