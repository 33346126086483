import { Line } from "../../trackTypes";

const line = [
  [-35.5040, 143.9566],
  [-35.4899, 143.9571],
  [-35.4730, 143.9580],
  [-35.4706, 143.9575],
  [-35.4688, 143.9562],
  [-35.4681, 143.9556],
  [-35.4478, 143.9334],
  [-35.4447, 143.9309],
  [-35.4074, 143.9115],
  [-35.4040, 143.9090],
  [-35.3735, 143.8782],
  [-35.3389, 143.8571],
  [-35.3028, 143.8235],
  [-35.3013, 143.8216],
  [-35.2974, 143.8152],
  [-35.2817, 143.7894],
  [-35.2681, 143.7672],
  [-35.2657, 143.7646],
  [-35.2334, 143.7411],
  [-35.1916, 143.7103],
  [-35.1877, 143.7063],
  [-35.1659, 143.6837],
  [-35.1639, 143.6805],
  [-35.1320, 143.6212],
  [-35.1249, 143.6082],
  [-35.1223, 143.6053],
  [-35.0930, 143.5885],
  [-35.0652, 143.5727]
];

export const stonyCrossing: Line = {
  name: 'Stony Crossing (NSW)',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1928-03-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1943-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
