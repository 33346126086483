import { Line } from "../../../trackTypes";

const cabooltureBeerburrum = [
  [-27.0828, 152.9537],
  [-27.0774, 152.9512],
  [-27.0740, 152.9497],
  [-27.0730, 152.9495],
  [-27.0656, 152.9491],
  [-27.0359, 152.9475],
  [-27.0292, 152.9464],
  [-27.0179, 152.9454],
  [-27.0126, 152.9455],
  [-27.0090, 152.9461],
  [-27.0030, 152.9474],
  [-26.9972, 152.9478],
  [-26.9958, 152.9481],
  [-26.9893, 152.9493],
  [-26.9864, 152.9500],
  [-26.9682, 152.9572],
  [-26.9658, 152.9580],
  [-26.9598, 152.9592]
];

const beerburrumLandsborough = [
  [-26.9598, 152.9592],
  [-26.9585, 152.9594],
  [-26.9578, 152.9594],
  [-26.9572, 152.9592],
  [-26.9561, 152.9587],
  [-26.9557, 152.9583],
  [-26.9543, 152.9571],
  [-26.9535, 152.9566],
  [-26.9526, 152.9563],
  [-26.9516, 152.9563],
  [-26.9452, 152.9576],
  [-26.9442, 152.9576],
  [-26.9425, 152.9571],
  [-26.9414, 152.9565],
  [-26.9405, 152.9556],
  [-26.9394, 152.9551],
  [-26.9384, 152.9550],
  [-26.9372, 152.9552],
  [-26.9364, 152.9558],
  [-26.9352, 152.9568],
  [-26.9343, 152.9572],
  [-26.9333, 152.9573],
  [-26.9244, 152.9566],
  [-26.9231, 152.9568],
  [-26.9223, 152.9572],
  [-26.9213, 152.9578],
  [-26.9202, 152.9582],
  [-26.9191, 152.9582],
  [-26.9147, 152.9572],
  [-26.9133, 152.9572],
  [-26.9047, 152.9588],
  [-26.9037, 152.9587],
  [-26.9029, 152.9585],
  [-26.8955, 152.9540],
  [-26.8949, 152.9534],
  [-26.8938, 152.9520],
  [-26.8929, 152.9513],
  [-26.8920, 152.9509],
  [-26.8866, 152.9499],
  [-26.8854, 152.9499],
  [-26.8842, 152.9503],
  [-26.8836, 152.9506],
  [-26.8717, 152.9577],
  [-26.8701, 152.9583],
  [-26.8686, 152.9585],
  [-26.8554, 152.9583],
  [-26.8537, 152.9581],
  [-26.8508, 152.9574],
  [-26.8493, 152.9573],
  [-26.8440, 152.9580],
  [-26.8356, 152.9579],
  [-26.8341, 152.9581],
  [-26.8152, 152.9627],
  [-26.8143, 152.9633],
  [-26.8121, 152.9655],
  [-26.8114, 152.9659],
  [-26.8107, 152.9661],
  [-26.8078, 152.9664]
];

const landsboroughDularchaDeviationStart = [
  [-26.8078, 152.9664],
  [-26.8059, 152.9665],
  [-26.7903, 152.9677],
  [-26.7891, 152.9675],
  [-26.7877, 152.9668],
  [-26.7844, 152.9642]
];

const deviationStartDularchaTunnelOld = [
  [-26.7844, 152.9642],
  [-26.7798, 152.9608],
  [-26.7788, 152.9603],
  [-26.7787, 152.9603]
];

const dularchaTunnelOld = [
  [-26.7787, 152.9603],
  [-26.7781, 152.9604]
];

const dularchaTunnelOldDeviationEnd = [
  [-26.7781, 152.9604],
  [-26.7747, 152.9632],
  [-26.7739, 152.9635],
  [-26.7723, 152.9637],
  [-26.7710, 152.9635],
  [-26.7701, 152.9637],
  [-26.7691, 152.9640],
  [-26.7677, 152.9639],
  [-26.7668, 152.9636],
  [-26.7661, 152.9633]
];

const deviationStartDularchaTunnel = [
  [-26.7844, 152.9642],
  [-26.7833, 152.9638],
  [-26.7824, 152.9638],
  [-26.7799, 152.9646],
  [-26.7788, 152.9647],
  [-26.7783, 152.9649],
  [-26.7776, 152.9653]
];

const dularchaTunnel = [
  [-26.7776, 152.9653],
  [-26.7759, 152.9662]
];

const dularchaTunnelDeviationEnd = [
  [-26.7759, 152.9662],
  [-26.7754, 152.9665],
  [-26.7743, 152.9668],
  [-26.7716, 152.9672],
  [-26.7711, 152.9671],
  [-26.7699, 152.9664],
  [-26.7661, 152.9633]
];

const DularchaDeviationEndNambour = [
  [-26.7661, 152.9633],
  [-26.7644, 152.9619],
  [-26.7635, 152.9615],
  [-26.7626, 152.9614],
  [-26.7619, 152.9616],
  [-26.7610, 152.9621],
  [-26.7598, 152.9625],
  [-26.7586, 152.9625],
  [-26.7541, 152.9616],
  [-26.7532, 152.9611],
  [-26.7519, 152.9598],
  [-26.7511, 152.9594],
  [-26.7470, 152.9582],
  [-26.7453, 152.9574],
  [-26.7447, 152.9572],
  [-26.7428, 152.9573],
  [-26.7417, 152.9577],
  [-26.7393, 152.9575],
  [-26.7384, 152.9577],
  [-26.7328, 152.9627],
  [-26.7317, 152.9630],
  [-26.7306, 152.9628],
  [-26.7296, 152.9616],
  [-26.7290, 152.9583],
  [-26.7283, 152.9573],
  [-26.7273, 152.9569],
  [-26.7241, 152.9567],
  [-26.7234, 152.9570],
  [-26.7222, 152.9576],
  [-26.7209, 152.9574],
  [-26.7197, 152.9565],
  [-26.7185, 152.9563],
  [-26.7176, 152.9565],
  [-26.7164, 152.9574],
  [-26.7153, 152.9575],
  [-26.7144, 152.9571],
  [-26.7132, 152.9560],
  [-26.7120, 152.9558],
  [-26.7108, 152.9561],
  [-26.7074, 152.9606],
  [-26.7067, 152.9610],
  [-26.7006, 152.9617],
  [-26.6997, 152.9616],
  [-26.6989, 152.9611],
  [-26.6975, 152.9598],
  [-26.6964, 152.9593],
  [-26.6951, 152.9595],
  [-26.6929, 152.9608],
  [-26.6917, 152.9613],
  [-26.6896, 152.9613],
  [-26.6889, 152.9609],
  [-26.6879, 152.9600],
  [-26.6865, 152.9595],
  [-26.6849, 152.9597],
  [-26.6836, 152.9608],
  [-26.6827, 152.9623],
  [-26.6814, 152.9634],
  [-26.6781, 152.9646],
  [-26.6772, 152.9646],
  [-26.6738, 152.9641],
  [-26.6707, 152.9625],
  [-26.6695, 152.9622],
  [-26.6687, 152.9624],
  [-26.6658, 152.9639],
  [-26.6646, 152.9640],
  [-26.6640, 152.9638],
  [-26.6628, 152.9632],
  [-26.6620, 152.9630],
  [-26.6603, 152.9627],
  [-26.6595, 152.9624],
  [-26.6563, 152.9609],
  [-26.6551, 152.9602],
  [-26.6540, 152.9593],
  [-26.6531, 152.9589],
  [-26.6480, 152.9580],
  [-26.6469, 152.9576],
  [-26.6424, 152.9553],
  [-26.6410, 152.9550],
  [-26.6283, 152.9558],
  [-26.6273, 152.9561],
  [-26.6267, 152.9565],
  [-26.6252, 152.9581]
]

const nambourYandina = [
  [-26.6252, 152.9581],
  [-26.6217, 152.9620],
  [-26.6210, 152.9625],
  [-26.6201, 152.9625],
  [-26.6193, 152.9622],
  [-26.6167, 152.9605],
  [-26.6162, 152.9604],
  [-26.6137, 152.9602],
  [-26.6128, 152.9604],
  [-26.6122, 152.9610],
  [-26.6116, 152.9620],
  [-26.6110, 152.9627],
  [-26.6102, 152.9630],
  [-26.6081, 152.9632],
  [-26.6074, 152.9631],
  [-26.6069, 152.9627],
  [-26.6062, 152.9619],
  [-26.6055, 152.9615],
  [-26.6046, 152.9614],
  [-26.6012, 152.9615],
  [-26.5994, 152.9609],
  [-26.5985, 152.9601],
  [-26.5974, 152.9587],
  [-26.5962, 152.9579],
  [-26.5921, 152.9564],
  [-26.5909, 152.9564],
  [-26.5898, 152.9569],
  [-26.5852, 152.9601],
  [-26.5814, 152.9619],
  [-26.5727, 152.9630]
];

const yandinaOriginal = [
  [-26.5727, 152.9630],
  [-26.5712, 152.9631],
  [-26.5696, 152.9626],
  [-26.5676, 152.9606],
  [-26.5665, 152.9599],
  [-26.5656, 152.9596],
  [-26.5641, 152.9597],
  [-26.5635, 152.9596],
  [-26.5615, 152.9581],
  [-26.5598, 152.9576],
  [-26.5580, 152.9578]
];

const yandinaDeviation = [
  [-26.5727, 152.9630],
  [-26.5714, 152.9629],
  [-26.5695, 152.9622],
  [-26.5674, 152.9609],
  [-26.5643, 152.9598],
  [-26.5630, 152.9592],
  [-26.5613, 152.9583],
  [-26.5605, 152.9581],
  [-26.5605, 152.9581],
  [-26.5594, 152.9579],
  [-26.5580, 152.9578]
];

const yandinaEumundiDeviationStart = [
  [-26.5580, 152.9578],
  [-26.5434, 152.9570],
  [-26.5401, 152.9570],
  [-26.5388, 152.9568],
  [-26.5335, 152.9556],
  [-26.5313, 152.9554],
  [-26.5281, 152.9554],
  [-26.5127, 152.9549],
  [-26.5095, 152.9545],
  [-26.5048, 152.9537],
  [-26.5027, 152.9529],
  [-26.5003, 152.9513],
  [-26.4974, 152.9504],
  [-26.4952, 152.9502],
  [-26.4936, 152.9504],
  [-26.4901, 152.9515],
  [-26.4874, 152.9534],
  [-26.4853, 152.9543],
  [-26.4807, 152.9550]
];

const eumunduOld = [
  [-26.4807, 152.9550],
  [-26.4799, 152.9550],
  [-26.4793, 152.9548],
  [-26.4786, 152.9543],
  [-26.4777, 152.9537],
  [-26.4752, 152.9514],
  [-26.4728, 152.9495],
  [-26.4721, 152.9492],
  [-26.4715, 152.9491],
  [-26.4693, 152.9494],
  [-26.4683, 152.9494],
  [-26.4665, 152.9491],
  [-26.4648, 152.9484],
  [-26.4634, 152.9484],
  [-26.4618, 152.9489],
  [-26.4583, 152.9492],
  [-26.4575, 152.9488],
  [-26.4571, 152.9482],
  [-26.4569, 152.9472],
  [-26.4572, 152.9444],
  [-26.4568, 152.9433],
  [-26.4560, 152.9428],
  [-26.4554, 152.9421],
  [-26.4551, 152.9413],
  [-26.4541, 152.9404],
  [-26.4529, 152.9404],
  [-26.4521, 152.9400],
  [-26.4512, 152.9387],
  [-26.4505, 152.9365],
  [-26.4499, 152.9357],
  [-26.4487, 152.9347],
  [-26.4482, 152.9337],
  [-26.4474, 152.9329],
  [-26.4470, 152.9320],
  [-26.4465, 152.9314],
  [-26.4457, 152.9311],
  [-26.4452, 152.9306],
  [-26.4448, 152.9297],
  [-26.4407, 152.9265],
  [-26.4369, 152.9241],
  [-26.4363, 152.9232],
  [-26.4350, 152.9208],
  [-26.4342, 152.9200],
  [-26.4306, 152.9179],
  [-26.4300, 152.9174],
  [-26.4293, 152.9162],
  [-26.4285, 152.9157],
  [-26.4276, 152.9156],
  [-26.4260, 152.9159],
  [-26.4256, 152.9159],
  [-26.4252, 152.9158]
];

const eumundiDeviation = [
  [-26.4807, 152.9550],
  [-26.4757, 152.9558],
  [-26.4738, 152.9567],
  [-26.4721, 152.9585],
  [-26.4707, 152.9605],
  [-26.4693, 152.9616],
  [-26.4675, 152.9624],
  [-26.4644, 152.9629],
  [-26.4623, 152.9625],
  [-26.4605, 152.9611],
  [-26.4596, 152.9596],
  [-26.4574, 152.9546],
  [-26.4554, 152.9472],
  [-26.4545, 152.9448],
  [-26.4492, 152.9360],
  [-26.4442, 152.9298],
  [-26.4430, 152.9288],
  [-26.4372, 152.9247],
  [-26.4360, 152.9233],
  [-26.4347, 152.9211],
  [-26.4330, 152.9196],
  [-26.4296, 152.9177],
  [-26.4271, 152.9169],
  [-26.4252, 152.9158]
];

const eumundiDeviationEndCooroy = [
  [-26.4252, 152.9158],
  [-26.4210, 152.9116],
  [-26.4203, 152.9112],
  [-26.4197, 152.9111],
  [-26.4175, 152.9111]
];

const cooryDeviationStart = [
  [-26.4175, 152.9111],
  [-26.4145, 152.9111],
  [-26.4134, 152.9109],
  [-26.4118, 152.9098],
  [-26.4107, 152.9095],
  [-26.4079, 152.9096],
  [-26.4066, 152.9094],
  [-26.4050, 152.9086],
  [-26.4043, 152.9081]
];

const cooroyOld = [
  [-26.4043, 152.9081],
  [-26.4018, 152.9063],
  [-26.4010, 152.9051],
  [-26.3997, 152.9021],
  [-26.3983, 152.9008],
  [-26.3974, 152.8993],
  [-26.3969, 152.8947]
];

const cooroyDeviation = [
  [-26.4043, 152.9081],
  [-26.4039, 152.9076],
  [-26.4023, 152.9058],
  [-26.4012, 152.9034],
  [-26.3989, 152.9001],
  [-26.3977, 152.8976],
  [-26.3969, 152.8947]
];

const cooroyDeviationEndCooran = [
  [-26.3969, 152.8947],
  [-26.3967, 152.8923],
  [-26.3964, 152.8912],
  [-26.3928, 152.8849],
  [-26.3923, 152.8838],
  [-26.3922, 152.8830],
  [-26.3919, 152.8805],
  [-26.3914, 152.8794],
  [-26.3905, 152.8786],
  [-26.3893, 152.8782],
  [-26.3883, 152.8784],
  [-26.3877, 152.8787],
  [-26.3868, 152.8795],
  [-26.3856, 152.8799],
  [-26.3847, 152.8798],
  [-26.3836, 152.8791],
  [-26.3829, 152.8781],
  [-26.3824, 152.8766],
  [-26.3819, 152.8758],
  [-26.3812, 152.8752],
  [-26.3762, 152.8729],
  [-26.3752, 152.8722],
  [-26.3746, 152.8714],
  [-26.3689, 152.8625],
  [-26.3682, 152.8618],
  [-26.3676, 152.8614],
  [-26.3671, 152.8604],
  [-26.3665, 152.8576],
  [-26.3660, 152.8566],
  [-26.3652, 152.8557],
  [-26.3638, 152.8551],
  [-26.3630, 152.8552],
  [-26.3614, 152.8555],
  [-26.3606, 152.8553],
  [-26.3599, 152.8547],
  [-26.3596, 152.8538],
  [-26.3595, 152.8514],
  [-26.3584, 152.8481],
  [-26.3573, 152.8469],
  [-26.3562, 152.8465],
  [-26.3551, 152.8465],
  [-26.3540, 152.8460],
  [-26.3508, 152.8432],
  [-26.3479, 152.8394],
  [-26.3473, 152.8389],
  [-26.3461, 152.8383],
  [-26.3456, 152.8378],
  [-26.3453, 152.8371],
  [-26.3453, 152.8364],
  [-26.3460, 152.8336],
  [-26.3460, 152.8321],
  [-26.3457, 152.8306],
  [-26.3452, 152.8295],
  [-26.3430, 152.8266],
  [-26.3418, 152.8258],
  [-26.3407, 152.8258],
  [-26.3399, 152.8256],
  [-26.3346, 152.8229]
];

const cooranTandurDeviationStart = [
  [-26.3346, 152.8229],
  [-26.3333, 152.8223],
  [-26.3326, 152.8216],
  [-26.3322, 152.8207],
  [-26.3322, 152.8199],
  [-26.3326, 152.8178],
  [-26.3326, 152.8163],
  [-26.3312, 152.8116],
  [-26.3312, 152.8107],
  [-26.3315, 152.8099],
  [-26.3322, 152.8089],
  [-26.3324, 152.8081],
  [-26.3323, 152.8073],
  [-26.3307, 152.8041],
  [-26.3302, 152.8035],
  [-26.3269, 152.8017],
  [-26.3263, 152.8009],
  [-26.3260, 152.7999],
  [-26.3263, 152.7910],
  [-26.3259, 152.7896],
  [-26.3252, 152.7886],
  [-26.3245, 152.7882],
  [-26.3227, 152.7874],
  [-26.3222, 152.7870],
  [-26.3219, 152.7865],
  [-26.3212, 152.7847],
  [-26.3204, 152.7835],
  [-26.3190, 152.7822],
  [-26.3182, 152.7811],
  [-26.3177, 152.7799],
  [-26.3175, 152.7788],
  [-26.3174, 152.7735]
];

const tandurOld = [
  [-26.3174, 152.7735],
  [-26.3167, 152.7723],
  [-26.3161, 152.7708],
  [-26.3153, 152.7679],
  [-26.3123, 152.7652],
  [-26.3110, 152.7646],
  [-26.3075, 152.7644],
  [-26.3063, 152.7646],
  [-26.3054, 152.7644],
  [-26.3033, 152.7634],
  [-26.3016, 152.7630],
  [-26.3009, 152.7629],
  [-26.3004, 152.7631],
  [-26.2993, 152.7642],
  [-26.2986, 152.7643],
  [-26.2978, 152.7640],
  [-26.2965, 152.7623],
  [-26.2952, 152.7614],
  [-26.2920, 152.7601],
  [-26.2880, 152.7592],
  [-26.2861, 152.7584],
  [-26.2850, 152.7573],
  [-26.2846, 152.7560],
  [-26.2848, 152.7543],
  [-26.2846, 152.7529],
  [-26.2840, 152.7519],
  [-26.2803, 152.7497],
  [-26.2799, 152.7493],
  [-26.2797, 152.7485],
  [-26.2796, 152.7468],
  [-26.2791, 152.7457],
  [-26.2782, 152.7453],
  [-26.2768, 152.7457],
  [-26.2760, 152.7456],
  [-26.2753, 152.7451],
  [-26.2743, 152.7437],
  [-26.2735, 152.7435],
  [-26.2719, 152.7440],
  [-26.2712, 152.7439],
  [-26.2703, 152.7439],
  [-26.2694, 152.7439],
  [-26.2686, 152.7437],
  [-26.2679, 152.7430],
  [-26.2675, 152.7421],
  [-26.2671, 152.7415],
  [-26.2665, 152.7410],
  [-26.2654, 152.7411],
  [-26.2647, 152.7409],
  [-26.2631, 152.7395],
  [-26.2627, 152.7389],
  [-26.2626, 152.7382],
  [-26.2629, 152.7369],
  [-26.2629, 152.7358],
  [-26.2622, 152.7345],
  [-26.2611, 152.7340],
  [-26.2589, 152.7341],
  [-26.2582, 152.7340],
  [-26.2574, 152.7334]
];

const tandurDeviation = [
  [-26.3174, 152.7735],
  [-26.3174, 152.7724],
  [-26.3171, 152.7708],
  [-26.3165, 152.7694],
  [-26.3156, 152.7680],
  [-26.3138, 152.7664],
  [-26.3112, 152.7651],
  [-26.3084, 152.7643],
  [-26.3006, 152.7626],
  [-26.2936, 152.7606],
  [-26.2911, 152.7595],
  [-26.2885, 152.7578],
  [-26.2866, 152.7561],
  [-26.2826, 152.7519],
  [-26.2808, 152.7502],
  [-26.2748, 152.7462],
  [-26.2721, 152.7450],
  [-26.2680, 152.7436],
  [-26.2651, 152.7421],
  [-26.2629, 152.7404],
  [-26.2601, 152.7375],
  [-26.2577, 152.7339],
  [-26.2574, 152.7334]
];

const tandurDeviationEndGympieJunction = [
  [-26.2574, 152.7334],
  [-26.2559, 152.7305],
  [-26.2548, 152.7294],
  [-26.2537, 152.7287],
  [-26.2520, 152.7283],
  [-26.2512, 152.7283],
  [-26.2504, 152.7286],
  [-26.2496, 152.7290],
  [-26.2491, 152.7291],
  [-26.2487, 152.7289],
  [-26.2461, 152.7275],
  [-26.2456, 152.7269],
  [-26.2450, 152.7252],
  [-26.2445, 152.7244],
  [-26.2440, 152.7242],
  [-26.2431, 152.7237],
  [-26.2426, 152.7231],
  [-26.2424, 152.7224],
  [-26.2426, 152.7192],
  [-26.2424, 152.7184],
  [-26.2419, 152.7176],
  [-26.2412, 152.7168],
  [-26.2403, 152.7148],
  [-26.2398, 152.7141],
  [-26.2353, 152.7099],
  [-26.2339, 152.7092],
  [-26.2312, 152.7085],
  [-26.2287, 152.7073],
  [-26.2261, 152.7048],
  [-26.2249, 152.7029],
  [-26.2227, 152.6981],
  [-26.2218, 152.6972],
  [-26.2208, 152.6967],
  [-26.2178, 152.6963],
  [-26.2167, 152.6959],
  [-26.2150, 152.6947],
  [-26.2144, 152.6944]
];

const gympieJunctionGympieNorth = [
  [-26.2144, 152.6944],
  [-26.2127, 152.6938],
  [-26.2106, 152.6937],
  [-26.1989, 152.6960],
  [-26.1959, 152.6960],
  [-26.1930, 152.6951],
  [-26.1877, 152.6924],
  [-26.1847, 152.6916],
  [-26.1827, 152.6917],
  [-26.1777, 152.6925],
  [-26.1721, 152.6922],
  [-26.1689, 152.6912],
  [-26.1669, 152.6900],
  [-26.1555, 152.6790],
  [-26.1535, 152.6772]
];

const gympieJunctionGympie = [
  [-26.2144, 152.6944],
  [-26.2133, 152.6935],
  [-26.2129, 152.6927],
  [-26.2128, 152.6920],
  [-26.2130, 152.6914],
  [-26.2135, 152.6898],
  [-26.2136, 152.6891],
  [-26.2134, 152.6885],
  [-26.2110, 152.6847],
  [-26.2107, 152.6839],
  [-26.2106, 152.6829],
  [-26.2107, 152.6810],
  [-26.2106, 152.6802],
  [-26.2104, 152.6788],
  [-26.2101, 152.6781],
  [-26.2096, 152.6776],
  [-26.2069, 152.6758],
  [-26.2059, 152.6755],
  [-26.2031, 152.6754],
  [-26.2024, 152.6752],
  [-26.2018, 152.6748],
  [-26.2007, 152.6737],
  [-26.2001, 152.6733],
  [-26.1995, 152.6732],
  [-26.1979, 152.6735],
  [-26.1974, 152.6738],
  [-26.1970, 152.6742],
  [-26.1965, 152.6752],
  [-26.1958, 152.6759],
  [-26.1951, 152.6761],
  [-26.1944, 152.6760],
  [-26.1931, 152.6753],
  [-26.1926, 152.6748],
  [-26.1923, 152.6741],
  [-26.1922, 152.6733],
  [-26.1923, 152.6723],
  [-26.1922, 152.6718],
  [-26.1920, 152.6713],
  [-26.1913, 152.6705],
  [-26.1909, 152.6702],
  [-26.1903, 152.6700],
  [-26.1897, 152.6700],
  [-26.1869, 152.6715]
];

const gympieGympieNorth = [
  [-26.1869, 152.6715],
  [-26.1822, 152.6740],
  [-26.1810, 152.6744],
  [-26.1799, 152.6744],
  [-26.1792, 152.6746],
  [-26.1760, 152.6769],
  [-26.1755, 152.6772],
  [-26.1730, 152.6781],
  [-26.1719, 152.6781],
  [-26.1697, 152.6773],
  [-26.1669, 152.6772],
  [-26.1663, 152.6770],
  [-26.1649, 152.6764],
  [-26.1641, 152.6764],
  [-26.1630, 152.6765],
  [-26.1623, 152.6763],
  [-26.1618, 152.6760],
  [-26.1611, 152.6748],
  [-26.1603, 152.6741],
  [-26.1596, 152.6738]
];

const gympieDeviation = [
  [-26.1596, 152.6738],
  [-26.1585, 152.6737],
  [-26.1580, 152.6739],
  [-26.1555, 152.6764],
  [-26.1547, 152.6771],
  [-26.1542, 152.6773],
  [-26.1538, 152.6773],
  [-26.1535, 152.6772]
];

const gympieNorthJunction = [
  [-26.1555, 152.6764],
  [-26.1551, 152.6772],
  [-26.1551, 152.6781],
  [-26.1555, 152.6790]
];

export const northCoastCabooltureGympie: Line = {
  name: 'North Coast (Caboolture - Gympie)',
  state: 'QLD',
  segments: [
    {
      segments: [cabooltureBeerburrum],
      history: {
        opened: {
          date: '1890-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2009-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [beerburrumLandsborough],
      history: {
        opened: {
          date: '1890-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        landsboroughDularchaDeviationStart,
        {
          date: '1931-01-01',
          original: [
            deviationStartDularchaTunnelOld,
            {
              name: 'Dularcha Tunnel (Old)',
              segment: dularchaTunnelOld,
              type: 'tunnel'
            },
            dularchaTunnelOldDeviationEnd
          ],
          deviation: [
            deviationStartDularchaTunnel,
            {
              name: 'Dularcha Tunnel',
              segment: dularchaTunnel,
              type: 'tunnel'
            },
            dularchaTunnelDeviationEnd
          ]
        },
        DularchaDeviationEndNambour
      ],
      history: {
        opened: {
          date: '1891-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        nambourYandina,
        {
          date: '1989-02-04',
          original: [yandinaOriginal],
          deviation: [yandinaDeviation]
        }
      ],
      history: {
        opened: {
          date: '1891-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-02-04',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        yandinaEumundiDeviationStart,
        {
          date: '1989-02-04',
          original: [eumunduOld],
          deviation: [eumundiDeviation]
        },
        eumundiDeviationEndCooroy
      ],
      history: {
        opened: {
          date: '1891-07-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-02-04',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        cooryDeviationStart,
        {
          date: '1989-02-04',
          original: [cooroyOld],
          deviation: [cooroyDeviation]
        },
        cooroyDeviationEndCooran
      ],
      history: {
        opened: {
          date: '1891-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-02-04',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        cooranTandurDeviationStart,
        {
          date: '1989-02-04',
          original: [tandurOld],
          deviation: [tandurDeviation]
        },
        tandurDeviationEndGympieJunction
      ],
      history: {
        opened: {
          date: '1889-06-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-02-04',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gympieJunctionGympie],
      history: {
        opened: {
          date: '1889-06-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-11-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gympieJunctionGympieNorth],
      history: {
        opened: {
          date: '1989-02-04',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [gympieGympieNorth],
      history: {
        opened: {
          date: '1881-08-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-11-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gympieDeviation],
      history: {
        opened: {
          date: '1989-02-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-11-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gympieNorthJunction],
      history: {
        opened: {
          date: '1989-02-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-11-09',
          status: 'closed'
        }]
      }
    }
  ]
}
