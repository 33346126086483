import { Line } from "../../trackTypes";

const line = [
  [-34.8827, 138.5221],
  [-34.8831, 138.5211],
  [-34.8831, 138.5200],
  [-34.8828, 138.5192],
  [-34.8796, 138.5145],
  [-34.8794, 138.5138],
  [-34.8793, 138.5130]
];

export const hendon: Line = {
  name: 'Hendon',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1940-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-02-01',
          status: 'closed'
        }]
      }
    }
  ]
};
