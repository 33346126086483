import { Line } from "../../../../trackTypes";

const collinsStreetExtension3 = [
  [-37.82073, 144.94174],
  [-37.82150, 144.94507]
];

const collinsStreetExtension2 = [
  [-37.82150, 144.94507],
  [-37.82147, 144.94554],
  [-37.82130, 144.94644],
  [-37.82101, 144.94744]
];

const collinsStreetExtension = [
  [-37.82101, 144.94744],
  [-37.81901, 144.95435]
];

const collinsStreet = [
  [-37.81922, 144.95424],
  [-37.81910, 144.95426],
  [-37.81901, 144.95435],
  [-37.81338, 144.97369],
  [-37.81328, 144.97382],
  [-37.81065, 144.97541],
  [-37.81036, 144.97552],
  [-37.80834, 144.97586],
  [-37.80824, 144.97596],
  [-37.80820, 144.97609],
  [-37.80824, 144.97656]
];

const fitzroyNorth = [
  [-37.80824, 144.97656],
  [-37.80821, 144.97671],
  [-37.80812, 144.97679],
  [-37.78798, 144.98022],
  [-37.78740, 144.98040],
  [-37.78698, 144.98067],
  [-37.77896, 144.98756]
];

const holdenStreetSection = [
  [-37.77896, 144.98756],
  [-37.77792, 144.98847]
];

const eastPreston = [
  [-37.77792, 144.98847],
  [-37.77669, 144.98952],
  [-37.77625, 144.98971],
  [-37.76947, 144.99108],
  [-37.76832, 144.99143],
  [-37.75511, 144.99410],
  [-37.75299, 144.99477],
  [-37.75198, 144.99497],
  [-37.75179, 144.99503],
  [-37.75168, 144.99511],
  [-37.75165, 144.99520],
  [-37.75162, 144.99528],
  [-37.75165, 144.99555],
  [-37.75225, 145.00145],
  [-37.75222, 145.00161],
  [-37.75214, 145.00177],
  [-37.74559, 145.00619],
  [-37.73879, 145.01156],
  [-37.73440, 145.01368],
  [-37.73248, 145.01440],
  [-37.73202, 145.01445],
  [-37.73133, 145.01444]
];

const eastPrestonExtension = [
  [-37.73133, 145.01444],
  [-37.73100, 145.01464],
  [-37.72677, 145.02096],
  [-37.72580, 145.02229],
  [-37.72551, 145.02292]
];

const laTrobeUni = [
  [-37.72551, 145.02292],
  [-37.72520, 145.02385],
  [-37.72491, 145.02559],
  [-37.72463, 145.02665],
  [-37.72431, 145.02735],
  [-37.72238, 145.03065],
  [-37.72204, 145.03147],
  [-37.71859, 145.04076],
  [-37.71798, 145.04198],
  [-37.71749, 145.04258],
  [-37.71705, 145.04294],
  [-37.71637, 145.04337]
];

const bundoora = [
  [-37.71637, 145.04337],
  [-37.71374, 145.04495],
  [-37.71314, 145.04551],
  [-37.71134, 145.04805],
  [-37.70984, 145.04980],
  [-37.70257, 145.05717],
  [-37.70205, 145.05756],
  [-37.70131, 145.05789],
  [-37.69971, 145.05834]
];

const bundooraRmit = [
  [-37.69971, 145.05834],
  [-37.69702, 145.05924],
  [-37.69596, 145.05952],
  [-37.69537, 145.05985],
  [-37.69390, 145.06100],
  [-37.68789, 145.06685],
  [-37.68702, 145.06749],
  [-37.68627, 145.06792],
  [-37.68508, 145.06837],
  [-37.68075, 145.06923],
  [-37.67901, 145.06952]
];

const connector = [ //holden st
  [-37.77722, 144.96041],
  [-37.77715, 144.96052],
  [-37.77713, 144.96062],
  [-37.77989, 144.98590],
  [-37.77891, 144.98611],
  [-37.77904, 144.98727],
  [-37.77902, 144.98744],
  [-37.77896, 144.98756]
];

const prestonDepot = [
  [-37.75162, 144.99528],
  [-37.75157, 144.99535],
  [-37.75147, 144.99543],
  [-37.74969, 144.99603]
];

const prestonDepotEastJunction = [
  [-37.75165, 144.99555],
  [-37.75159, 144.99546],
  [-37.75147, 144.99543]
];

export const north1: Line = {
  name: 'North1',
  state: 'VIC',
  segments: [
    {
      segments: [collinsStreetExtension3],
      history: {
        opened: {
          date: '2014-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [collinsStreetExtension2],
      history: {
        opened: {
          date: '2009-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [collinsStreetExtension],
      history: {
        opened: {
          date: '2002-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [collinsStreet],
      history: {
        opened: {
          date: '1886-10-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1929-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [fitzroyNorth],
      history: {
        opened: {
          date: '1886-10-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1930-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [holdenStreetSection],
      history: {
        opened: {
          date: '1886-10-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1923-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [eastPreston],
      history: {
        opened: {
          date: '1920-10-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [connector],
      history: {
        opened: {
          date: '1924-10-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eastPrestonExtension],
      history: {
        opened: {
          date: '1983-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [laTrobeUni],
      history: {
        opened: {
          date: '1985-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [bundoora],
      history: {
        opened: {
          date: '1987-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [bundooraRmit],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [prestonDepot, prestonDepotEastJunction],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
