import { Line } from "../../trackTypes";

const dunollyInglewood = [
  [-36.8592, 143.7261],
  [-36.8585, 143.7257],
  [-36.8577, 143.7254],
  [-36.8570, 143.7254],
  [-36.8562, 143.7257],
  [-36.8555, 143.7262],
  [-36.8415, 143.7374],
  [-36.8401, 143.7382],
  [-36.8378, 143.7386],
  [-36.8219, 143.7382],
  [-36.8202, 143.7385],
  [-36.8186, 143.7393],
  [-36.8174, 143.7399],
  [-36.8165, 143.7410],
  [-36.8141, 143.7432],
  [-36.8119, 143.7445],
  [-36.8105, 143.7452],
  [-36.8092, 143.7459],
  [-36.8075, 143.7474],
  [-36.8058, 143.7497],
  [-36.8037, 143.7519],
  [-36.8013, 143.7540],
  [-36.7866, 143.7697],
  [-36.7841, 143.7727],
  [-36.7653, 143.7961],
  [-36.7641, 143.7979],
  [-36.7634, 143.7999],
  [-36.7631, 143.8022],
  [-36.7632, 143.8039],
  [-36.7642, 143.8101],
  [-36.7644, 143.8124],
  [-36.7642, 143.8146],
  [-36.7640, 143.8164],
  [-36.7640, 143.8183],
  [-36.7649, 143.8275],
  [-36.7648, 143.8288],
  [-36.7646, 143.8297],
  [-36.7638, 143.8308],
  [-36.7632, 143.8313],
  [-36.7622, 143.8316],
  [-36.7515, 143.8339],
  [-36.7501, 143.8344],
  [-36.7488, 143.8353],
  [-36.7474, 143.8368],
  [-36.7453, 143.8403],
  [-36.7447, 143.8415],
  [-36.7438, 143.8440],
  [-36.7382, 143.8547],
  [-36.7372, 143.8567],
  [-36.7358, 143.8584],
  [-36.7343, 143.8593],
  [-36.7329, 143.8599],
  [-36.7282, 143.8608],
  [-36.7251, 143.8618],
  [-36.7237, 143.8622],
  [-36.6983, 143.8660],
  [-36.6967, 143.8665],
  [-36.6947, 143.8675],
  [-36.6770, 143.8774],
  [-36.6752, 143.8782],
  [-36.6730, 143.8788],
  [-36.6600, 143.8860],
  [-36.6582, 143.8868],
  [-36.6568, 143.8871],
  [-36.6384, 143.8889],
  [-36.6342, 143.8897],
  [-36.6328, 143.8898],
  [-36.6308, 143.8893],
  [-36.6259, 143.8864],
  [-36.6244, 143.8858],
  [-36.6230, 143.8857],
  [-36.6217, 143.8858],
  [-36.6053, 143.8888],
  [-36.6032, 143.8890],
  [-36.6016, 143.8889],
  [-36.5940, 143.8878],
  [-36.5929, 143.8878],
  [-36.5919, 143.8879],
  [-36.5899, 143.8882],
  [-36.5884, 143.8882],
  [-36.5868, 143.8877],
  [-36.5852, 143.8867],
  [-36.5767, 143.8797],
  [-36.5758, 143.8787],
  [-36.5752, 143.8779],
  [-36.5747, 143.8770],
  [-36.5741, 143.8749]
];

const inglewoodKorongVale = [
  [-36.5741, 143.8749],
  [-36.5723, 143.8691],
  [-36.5719, 143.8682],
  [-36.5713, 143.8668],
  [-36.5701, 143.8648],
  [-36.5597, 143.8489],
  [-36.5581, 143.8465],
  [-36.5570, 143.8445],
  [-36.5430, 143.8177],
  [-36.5398, 143.8110],
  [-36.5370, 143.8061],
  [-36.5079, 143.7500],
  [-36.5037, 143.7409],
  [-36.5029, 143.7395],
  [-36.5017, 143.7382],
  [-36.5007, 143.7375],
  [-36.5001, 143.7371],
  [-36.4817, 143.7291],
  [-36.4808, 143.7286],
  [-36.4802, 143.7283],
  [-36.4717, 143.7224],
  [-36.4571, 143.7100],
  [-36.4561, 143.7090],
  [-36.4444, 143.6962],
  [-36.4430, 143.6950],
  [-36.4299, 143.6866],
  [-36.4288, 143.6863],
  [-36.4277, 143.6862],
  [-36.4268, 143.6864],
  [-36.4059, 143.6936],
  [-36.3887, 143.6967],
  [-36.3882, 143.6969],
  [-36.3741, 143.7030],
  [-36.3729, 143.7034],
  [-36.3722, 143.7036],
  [-36.3594, 143.7054],
  [-36.3565, 143.7053],
  [-36.3542, 143.7056]
];

const korongValeBoort = [
  [-36.3542, 143.7056],
  [-36.3522, 143.7060],
  [-36.3502, 143.7071],
  [-36.3017, 143.7442],
  [-36.2879, 143.7509],
  [-36.2865, 143.7513],
  [-36.2725, 143.7539],
  [-36.2715, 143.7540],
  [-36.2669, 143.7535],
  [-36.2660, 143.7536],
  [-36.2393, 143.7582],
  [-36.2370, 143.7582],
  [-36.2353, 143.7576],
  [-36.2030, 143.7407],
  [-36.2011, 143.7401],
  [-36.1839, 143.7347],
  [-36.1821, 143.7344],
  [-36.1637, 143.7341],
  [-36.1430, 143.7308],
  [-36.1413, 143.7301],
  [-36.1400, 143.7292],
  [-36.1283, 143.7173],
  [-36.1264, 143.7162],
  [-36.1246, 143.7161],
  [-36.1232, 143.7165],
  [-36.1221, 143.7171],
  [-36.1195, 143.7196],
  [-36.1173, 143.7208],
  [-36.1155, 143.7214]
];

const boortQuambatook = [
  [-36.1155, 143.7214],
  [-36.1118, 143.7226],
  [-36.1107, 143.7226],
  [-36.1102, 143.7224],
  [-36.0969, 143.7178],
  [-36.0956, 143.7176],
  [-36.0945, 143.7177],
  [-36.0839, 143.7196],
  [-36.0824, 143.7197],
  [-36.0812, 143.7194],
  [-36.0415, 143.6995],
  [-36.0407, 143.6990],
  [-36.0135, 143.6734],
  [-36.0120, 143.6716],
  [-36.0067, 143.6646],
  [-36.0058, 143.6638],
  [-36.0046, 143.6627],
  [-35.9735, 143.6213],
  [-35.9725, 143.6204],
  [-35.9374, 143.5968],
  [-35.9357, 143.5953],
  [-35.9335, 143.5931],
  [-35.9318, 143.5911],
  [-35.9213, 143.5774],
  [-35.9199, 143.5760],
  [-35.9066, 143.5672],
  [-35.9046, 143.5657],
  [-35.8822, 143.5482],
  [-35.8683, 143.5373],
  [-35.8674, 143.5365],
  [-35.8530, 143.5209],
  [-35.8502, 143.5184]
];

const quambatookUltima = [
  [-35.8502, 143.5184],
  [-35.8489, 143.5173],
  [-35.8186, 143.4912],
  [-35.7850, 143.4611],
  [-35.7837, 143.4603],
  [-35.7571, 143.4450],
  [-35.7560, 143.4441],
  [-35.7425, 143.4327],
  [-35.6776, 143.3800],
  [-35.6750, 143.3784],
  [-35.6565, 143.3698],
  [-35.6548, 143.3692],
  [-35.6527, 143.3687],
  [-35.6483, 143.3683],
  [-35.6397, 143.3680],
  [-35.6379, 143.3678],
  [-35.6364, 143.3675],
  [-35.6115, 143.3587],
  [-35.6008, 143.3537],
  [-35.5749, 143.3417],
  [-35.5237, 143.3084],
  [-35.5203, 143.3060],
  [-35.4934, 143.2863],
  [-35.4677, 143.2673]
];

const ultimaChillingollah = [
  [-35.4677, 143.2673],
  [-35.4419, 143.2483],
  [-35.4410, 143.2473],
  [-35.4403, 143.2464],
  [-35.4295, 143.2195],
  [-35.4015, 143.1515],
  [-35.3833, 143.1128],
  [-35.3796, 143.1081],
  [-35.3744, 143.1052],
  [-35.3446, 143.1009],
  [-35.3001, 143.0946],
  [-35.2931, 143.0920],
  [-35.2872, 143.0860],
  [-35.2732, 143.0630],
  [-35.2670, 143.0559],
  [-35.2636, 143.0528]
];

const chillingollahManangatang = [
  [-35.2636, 143.0528],
  [-35.2606, 143.0502],
  [-35.2591, 143.0493],
  [-35.2533, 143.0465],
  [-35.2514, 143.0451],
  [-35.2496, 143.0431],
  [-35.2485, 143.0413],
  [-35.2290, 142.9980],
  [-35.2274, 142.9954],
  [-35.2021, 142.9629],
  [-35.1931, 142.9514],
  [-35.1916, 142.9498],
  [-35.1455, 142.9091],
  [-35.1429, 142.9073],
  [-35.1147, 142.8929],
  [-35.1121, 142.8922],
  [-35.0974, 142.8907],
  [-35.0726, 142.8830],
  [-35.0706, 142.8828],
  [-35.0516, 142.8836]
];

const manangatangAnnuello = [
  [-35.0516, 142.8836],
  [-35.0434, 142.8840],
  [-35.0416, 142.8838],
  [-35.0150, 142.8793],
  [-34.9644, 142.8793],
  [-34.9622, 142.8789],
  [-34.9607, 142.8784],
  [-34.9431, 142.8685],
  [-34.9411, 142.8679],
  [-34.9394, 142.8677],
  [-34.9174, 142.8677],
  [-34.9150, 142.8673],
  [-34.8859, 142.8574],
  [-34.8837, 142.8562],
  [-34.8558, 142.8309]
];

const annuelloRobinvale = [
  [-34.8558, 142.8309],
  [-34.8444, 142.8207],
  [-34.8421, 142.8191],
  [-34.8393, 142.8181],
  [-34.8220, 142.8137],
  [-34.8200, 142.8129],
  [-34.7998, 142.8027],
  [-34.7972, 142.8021],
  [-34.7877, 142.8029],
  [-34.7738, 142.8060],
  [-34.7632, 142.8071],
  [-34.7617, 142.8069],
  [-34.7501, 142.8030],
  [-34.7252, 142.7961],
  [-34.7055, 142.7873],
  [-34.7035, 142.7868],
  [-34.6941, 142.7850],
  [-34.6928, 142.7846],
  [-34.6738, 142.7739],
  [-34.6474, 142.7674],
  [-34.6456, 142.7672],
  [-34.6267, 142.7672],
  [-34.6149, 142.7678],
  [-34.6117, 142.7682],
  [-34.5964, 142.7728],
  [-34.5948, 142.7729],
  [-34.5933, 142.7727],
  [-34.5823, 142.7688]
];

export const robinvale: Line = {
  name: 'Robinvale',
  state: 'VIC',
  segments: [
    {
      segments: [dunollyInglewood],
      history: {
        opened: {
          date: '1888-11-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [inglewoodKorongVale],
      history: {
        opened: {
          date: '1882-04-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [korongValeBoort],
      history: {
        opened: {
          date: '1888-11-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [boortQuambatook],
      history: {
        opened: {
          date: '1894-08-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [quambatookUltima],
      history: {
        opened: {
          date: '1900-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ultimaChillingollah],
      history: {
        opened: {
          date: '1909-07-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [chillingollahManangatang],
      history: {
        opened: {
          date: '1914-01-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [manangatangAnnuello],
      history: {
        opened: {
          date: '1921-03-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-01-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [annuelloRobinvale],
      history: {
        opened: {
          date: '1924-06-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-01-18',
          status: 'closed'
        }]
      }
    }
  ]
};
