import { Line } from "../../trackTypes";

const srlEast = [
  [-37.9561, 145.0477],
  [-37.9568, 145.0534],
  [-37.9566, 145.0555],
  [-37.9540, 145.0712],
  [-37.9534, 145.0811],
  [-37.9535, 145.0842],
  [-37.9560, 145.1045],
  [-37.9557, 145.1086],
  [-37.9544, 145.1119],
  [-37.9508, 145.1148],
  [-37.9193, 145.1205],
  [-37.9171, 145.1217],
  [-37.9151, 145.1241],
  [-37.9143, 145.1263],
  [-37.9133, 145.1314],
  [-37.9121, 145.1337],
  [-37.9110, 145.1350],
  [-37.9082, 145.1372],
  [-37.9068, 145.1378],
  [-37.9021, 145.1387],
  [-37.8999, 145.1395],
  [-37.8986, 145.1408],
  [-37.8858, 145.1594],
  [-37.8831, 145.1615],
  [-37.8784, 145.1624],
  [-37.8760, 145.1620],
  [-37.8732, 145.1598],
  [-37.8716, 145.1561],
  [-37.8703, 145.1444],
  [-37.8694, 145.1409],
  [-37.8594, 145.1147],
  [-37.8575, 145.1123],
  [-37.8537, 145.1110],
  [-37.8477, 145.1122],
  [-37.8163, 145.1235]
];

export const srl: Line = {
  name: 'Suburban Rail Loop',
  state: 'VIC',
  segments: [
    {
      segments: [{
        segment: srlEast,
        type: 'tunnel'
      }],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
