import { Line } from '../trackTypes';
import { queenstown } from './tasmania/queenstown';
import { melba } from './tasmania/melba';
import { northEast } from './tasmania/northEast';
import { mainLine } from './tasmania/mainLine';
import { bellBay } from './tasmania/bellBay';
import { derwentValley } from './tasmania/derwentValley';
import { launcestonTrams } from './tasmania/launcestonTrams';
import { hobartBranches } from './tasmania/hobartBranches';
import { sorell } from './tasmania/sorell';
import { oatlands } from './tasmania/oatlands';
import { hobartTrams } from './tasmania/hobartTrams';
import { fingal } from './tasmania/fingal';
import { moleCreek } from './tasmania/moleCreek';
import { nietta } from './tasmania/nietta';
import { tasWharfLines } from './tasmania/tasWharfLines';
import { apsley } from './tasmania/apsley';
import { sheffield } from './tasmania/sheffield';
import { pelicanPoint } from './tasmania/pelicanPoint';
import { stanleyTrowutta } from './tasmania/stanleyTrowutta';
import { smithton } from './tasmania/smithton';
import { flowerdaleBallastPit } from './tasmania/flowerdaleBallastPit';
import { mowbrayRacecourse } from './tasmania/mowbrayRacecourse';
import { hellyer } from './tasmania/hellyer';
import { preolenna } from './tasmania/preolenna';
import { styx } from './tasmania/styx';
import { shale } from './tasmania/shale';
import { donRiver } from './tasmania/donRiver';
import { strahnZeehanLink } from './tasmania/strahnZeehanLink';
import { mountFarrel } from './tasmania/mtFarrel';
import { strahnZeehan } from './tasmania/strahnZeehan';
import { waratah } from './tasmania/waratah';
import { sandfly } from './tasmania/sandfly';
import { risbyBasin } from './tasmania/risbyBasin';
import { nicolsSpur } from './tasmania/nicolsSpur';
import { invereskTram } from './tasmania/invereskTram';
import { dundas } from './tasmania/dundas';
import { marrawah } from './tasmania/marrawah';

export const tasLines: Line[] = [
  queenstown,
  melba,
  northEast,
  mainLine,
  bellBay,
  derwentValley,
  launcestonTrams,
  hobartBranches,
  sorell,
  oatlands,
  hobartTrams,
  fingal,
  moleCreek,
  nietta,
  tasWharfLines,
  apsley,
  sheffield,
  pelicanPoint,
  stanleyTrowutta,
  smithton,
  flowerdaleBallastPit,
  mowbrayRacecourse,
  hellyer,
  preolenna,
  styx,
  shale,
  donRiver,
  strahnZeehanLink,
  mountFarrel,
  strahnZeehan,
  waratah,
  sandfly,
  risbyBasin,
  nicolsSpur,
  invereskTram,
  dundas,
  marrawah
];
