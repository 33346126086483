import { Line } from "../../trackTypes";

const adelaidePortAdelaideJunction = [
  [-34.9115, 138.5817],
  [-34.9097, 138.5801],
  [-34.9078, 138.5780],
  [-34.8763, 138.5369],
  [-34.8751, 138.5353],
  [-34.8702, 138.5290],
  [-34.8651, 138.5240],
  [-34.8531, 138.5145],
  [-34.8523, 138.5138]
];

const portAdelaideJunctionPortAdelaide = [
  [-34.8523, 138.5138],
  [-34.8510, 138.5134],
  [-34.8506, 138.5134],
  [-34.8501, 138.5133],
  [-34.8496, 138.5129],
  [-34.8489, 138.5118],
  [-34.8484, 138.5110],
  [-34.8476, 138.5104],
  [-34.8452, 138.5083]
];

const portAdelaideJunctionSemaphoreJunction = [
  [-34.8523, 138.5138],
  [-34.8498, 138.5114],
  [-34.8492, 138.5105],
  [-34.8490, 138.5096],
  [-34.8490, 138.5087],
  [-34.8508, 138.4973],
  [-34.8506, 138.4961],
  [-34.8500, 138.4952],
  [-34.8492, 138.4949],
  [-34.8471, 138.4947],
  [-34.8459, 138.4943],
  [-34.8452, 138.4939]
];

const semaphoreJunctionLargsBayJunction = [
  [-34.8452, 138.4939],
  [-34.8405, 138.4935],
  [-34.8394, 138.4933],
  [-34.8374, 138.4929],
  [-34.8354, 138.4921],
  [-34.8336, 138.4917],
  [-34.8320, 138.4917],
  [-34.8309, 138.4914],
  [-34.8291, 138.4914],
  [-34.8280, 138.4912]
];

const largsBayJunctionMidlunga = [
  [-34.8280, 138.4912],
  [-34.8262, 138.4908],
  [-34.8245, 138.4910],
  [-34.8224, 138.4919],
  [-34.8215, 138.4928],
  [-34.8177, 138.4976],
  [-34.8166, 138.4986],
  [-34.8155, 138.4989],
  [-34.8043, 138.4990],
  [-34.7978, 138.4991]
];

const midlungaOuterHarbour = [
  [-34.7978, 138.4991],
  [-34.7904, 138.4991],
  [-34.7892, 138.4989],
  [-34.7881, 138.4981],
  [-34.7874, 138.4971],
  [-34.7842, 138.4903],
  [-34.7832, 138.4892],
  [-34.7810, 138.4844],
  [-34.7804, 138.4838],
  [-34.7796, 138.4835],
  [-34.7789, 138.4837],
  [-34.7774, 138.4847]
];

const balloonLoop = [
  [-34.7774, 138.4847],
  [-34.7769, 138.4854],
  [-34.7765, 138.4864],
  [-34.7765, 138.4873],
  [-34.7767, 138.4882],
  [-34.7774, 138.4890],
  [-34.7792, 138.4899],
  [-34.7800, 138.4899],
  [-34.7823, 138.4894],
  [-34.7829, 138.4894],
  [-34.7833, 138.4896],
  [-34.7842, 138.4903]
];

const portDockSpur = [
  [-34.8476, 138.5104],
  [-34.8463, 138.5098],
  [-34.8446, 138.5087]
];

export const outerHarbor: Line = {
  name: 'Outer Harbor',
  state: 'SA',
  segments: [
    {
      segments: [adelaidePortAdelaideJunction],
      history: {
        opened: {
          date: '1856-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1881-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [portAdelaideJunctionPortAdelaide],
      history: {
        opened: {
          date: '1856-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portAdelaideJunctionSemaphoreJunction],
      history: {
        opened: {
          date: '1878-01-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          tracks: 2
        }, {
          date: '1982-01-01',
          gauge: 'dual'
        }, {
          date: '2009-12-01',
          gauge: 'broad'
        }]
      }
    },
    {
      segments: [semaphoreJunctionLargsBayJunction],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [largsBayJunctionMidlunga],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [midlungaOuterHarbour],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          tracks: 2
        }, {
          date: '1988-10-29',
          tracks: 1
        }]
      }
    },
    {
      segments: [balloonLoop],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-10-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portDockSpur],
      history: {
        opened: {
          date: '2024-08-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    }
  ]
};
