import { Line } from "../../trackTypes";

const victoriaSquareNorthWest = [
  [-34.92712, 138.59986],
  [-34.92721, 138.59984],
  [-34.92745, 138.59971],
  [-34.92796, 138.59941],
  [-34.92815, 138.59934],
  [-34.92834, 138.59932],
  [-34.92877, 138.59936]
];

const victoriaSquareSouthWest = [
  [-34.92877, 138.59936],
  [-34.92914, 138.59938],
  [-34.92927, 138.59941],
  [-34.92937, 138.59948],
  [-34.92998, 138.59996],
  [-34.93010, 138.60004],
  [-34.93027, 138.60008]
];

const victoriaSquare = [
  [-34.92895, 138.59998],
  [-34.93027, 138.60008]
];

const victoriaSquareSouthTerrace = [
  [-34.93027, 138.60008],
  [-34.93536, 138.60046],
  [-34.93558, 138.60046],
  [-34.93577, 138.60043],
  [-34.93616, 138.60034],
  [-34.93660, 138.60032]
];

const southTerraceGoodwood = [
  [-34.93660, 138.60032],
  [-34.93969, 138.60054],
  [-34.94023, 138.60055],
  [-34.94072, 138.60047],
  [-34.94164, 138.60018],
  [-34.94277, 138.59966],
  [-34.94375, 138.59902],
  [-34.94452, 138.59837],
  [-34.94523, 138.59764],
  [-34.94577, 138.59696],
  [-34.94613, 138.59645],
  [-34.94663, 138.59560],
  [-34.95179, 138.58544]
];

const goodwoodBrightonRoad = [
  [-34.95179, 138.58544],
  [-34.95982, 138.56958],
  [-34.97625, 138.53714],
  [-34.97659, 138.53647],
  [-34.97739, 138.53490],
  [-34.97809, 138.53344],
  [-34.97875, 138.53167],
  [-34.97915, 138.53022],
  [-34.97945, 138.52864],
  [-34.97961, 138.52738],
  [-34.97970, 138.52623],
  [-34.97994, 138.52149],
  [-34.97998, 138.52087],
  [-34.98001, 138.52035]
];

const brightonRoadMosleySquare = [
  [-34.98001, 138.52035],
  [-34.98038, 138.51255],
  [-34.98042, 138.51175]
];

const colleyTerraceSiding = [
  [-34.98038, 138.51255],
  [-34.98036, 138.51248],
  [-34.98030, 138.51243],
  [-34.97881, 138.51232]
];

const glengowrieJunction = [
  [-34.97659, 138.53647],
  [-34.97656, 138.53669],
  [-34.97657, 138.53679],
  [-34.97662, 138.53691],
  [-34.97675, 138.53702]
];

const glengowrieDepot = [
  [-34.97625, 138.53714],
  [-34.97639, 138.53702],
  [-34.97652, 138.53698],
  [-34.97675, 138.53702],
  [-34.97733, 138.53711],
  [-34.97789, 138.53713]
];

export const glenelg: Line = {
  name: 'Glenelg',
  state: 'SA',
  segments: [
    {
      segments: [victoriaSquareNorthWest],
      history: {
        opened: {
          date: '2007-10-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [victoriaSquareSouthWest],
      history: {
        opened: {
          date: '2007-08-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [victoriaSquare],
      history: {
        opened: {
          date: '1873-08-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1914-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaSquareSouthTerrace],
      history: {
        opened: {
          date: '1873-08-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1914-01-01',
          status: 'closed'
        }, {
          date: '1929-12-14',
          gauge: 'standard',
          status: 'open',
          trackType: 'tram',
          tracks: 2,
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [southTerraceGoodwood],
      history: {
        opened: {
          date: '1873-08-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-04-02',
          status: 'closed'
        }, {
          date: '1929-12-14',
          gauge: 'standard',
          trackType: 'tram',
          tracks: 2,
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [goodwoodBrightonRoad],
      history: {
        opened: {
          date: '1873-08-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          tracks: 2
        }, {
          date: '1929-04-02',
          status: 'closed'
        }, {
          date: '1929-12-14',
          gauge: 'standard',
          trackType: 'tram',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
        }]
      }
    },
    {
      segments: [brightonRoadMosleySquare],
      history: {
        opened: {
          date: '1873-08-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-04-02',
          status: 'closed'
        }, {
          date: '1929-12-14',
          gauge: 'standard',
          trackType: 'tram',
          tracks: 2,
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
        }]
      }
    },
    {
      segments: [colleyTerraceSiding],
      history: {
        opened: {
          date: '1929-12-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glengowrieDepot, glengowrieJunction],
      history: {
        opened: {
          date: '1986-10-19',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
