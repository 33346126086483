import { Line } from "../../trackTypes";

const easternJunction = [
  [-37.6736, 176.1670],
  [-37.6729, 176.1666],
  [-37.6724, 176.1666],
  [-37.6718, 176.1669]
];

const line = [
  [-37.6719, 176.1650],
  [-37.6722, 176.1657],
  [-37.6721, 176.1663],
  [-37.6718, 176.1669],
  [-37.6711, 176.1674],
  [-37.6601, 176.1696],
  [-37.6595, 176.1700],
  [-37.6592, 176.1705],
  [-37.6583, 176.1745]
];

export const sulphurPoint: Line = {
  name: 'Sulphur Point',
  state: 'NZ',
  segments: [
    {
      segments: [easternJunction, line],
      history: {
        opened: {
          date: '1957-11-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
