import { Line } from "../../trackTypes";

const depot = [
  [-35.22369, 149.14395],
  [-35.22355, 149.14389],
  [-35.22345, 149.14375],
  [-35.22337, 149.14247]
];

const depotJunction = [
  [-35.22330, 149.14407],
  [-35.22343, 149.14392],
  [-35.22345, 149.14375]
];

const stageOne = [
  [-35.18559, 149.13520],
  [-35.18672, 149.14193],
  [-35.18780, 149.14826],
  [-35.18804, 149.14910],
  [-35.18836, 149.14969],
  [-35.18869, 149.15016],
  [-35.18911, 149.15061],
  [-35.18955, 149.15094],
  [-35.19002, 149.15119],
  [-35.19057, 149.15136],
  [-35.19106, 149.15144],
  [-35.19151, 149.15142],
  [-35.19205, 149.15132],
  [-35.20149, 149.14910],
  [-35.20793, 149.14756],
  [-35.21019, 149.14719],
  [-35.21124, 149.14693],
  [-35.21151, 149.14688],
  [-35.21351, 149.14662],
  [-35.21410, 149.14649],
  [-35.21485, 149.14619],
  [-35.21560, 149.14582],
  [-35.21617, 149.14555],
  [-35.21659, 149.14540],
  [-35.21703, 149.14529],
  [-35.21749, 149.14523],
  [-35.21877, 149.14517],
  [-35.21961, 149.14509],
  [-35.22075, 149.14490],
  [-35.22115, 149.14486],
  [-35.22202, 149.14465],
  [-35.22228, 149.14451],
  [-35.22248, 149.14436],
  [-35.22267, 149.14425],
  [-35.22330, 149.14407],
  [-35.22369, 149.14395],
  [-35.22472, 149.14368],
  [-35.22518, 149.14358],
  [-35.22551, 149.14358],
  [-35.22593, 149.14362],
  [-35.22971, 149.14453],
  [-35.23014, 149.14463],
  [-35.23083, 149.14471],
  [-35.23284, 149.14481],
  [-35.23312, 149.14485],
  [-35.23338, 149.14495],
  [-35.23364, 149.14514],
  [-35.23406, 149.14558],
  [-35.23421, 149.14569],
  [-35.23434, 149.14572],
  [-35.23451, 149.14568],
  [-35.23464, 149.14555],
  [-35.24072, 149.13683],
  [-35.24110, 149.13634],
  [-35.24153, 149.13589],
  [-35.24199, 149.13553],
  [-35.24263, 149.13513],
  [-35.24320, 149.13488],
  [-35.24367, 149.13474],
  [-35.24701, 149.13421],
  [-35.24754, 149.13416],
  [-35.24816, 149.13413],
  [-35.24849, 149.13410],
  [-35.25366, 149.13326],
  [-35.27314, 149.13012],
  [-35.27811, 149.12931]
];

const stage2a = [
  [-35.27811, 149.12931],
  [-35.27943, 149.12909],
  [-35.27952, 149.12901],
  [-35.27954, 149.12893],
  [-35.27942, 149.12786],
  [-35.27944, 149.12750],
  [-35.27948, 149.12734],
  [-35.27960, 149.12710],
  [-35.27974, 149.12695],
  [-35.28145, 149.12544],
  [-35.28168, 149.12532],
  [-35.28187, 149.12528],
  [-35.28203, 149.12532],
  [-35.28392, 149.12642],
  [-35.28413, 149.12659],
  [-35.28427, 149.12680],
  [-35.28436, 149.12715],
  [-35.28447, 149.12814],
  [-35.28452, 149.12823],
  [-35.28459, 149.12825],
  [-35.28934, 149.12748]
];

export const trams: Line = {
  name: 'Canberra Light Rail',
  state: 'ACT',
  segments: [
    {
      segments: [stageOne, depot, depotJunction],
      history: {
        opened: {
          date: '2019-04-20',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    },
    {
      segments: [stage2a],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    }
  ]
}
