import { Line } from "../../trackTypes";

const line = [
  [-34.1449, 147.3816],
  [-34.1440, 147.3807],
  [-34.1431, 147.3790],
  [-34.1408, 147.3596],
  [-34.1404, 147.3148],
  [-34.1399, 147.3125],
  [-34.1386, 147.3103],
  [-34.1323, 147.3051],
  [-34.1316, 147.3040],
  [-34.1314, 147.3030],
  [-34.1304, 147.2912],
  [-34.1297, 147.2897],
  [-34.1285, 147.2887],
  [-34.1274, 147.2883],
  [-34.1200, 147.2872],
  [-34.1187, 147.2862],
  [-34.1182, 147.2848],
  [-34.1181, 147.2835],
  [-34.1185, 147.2814],
  [-34.1183, 147.2795],
  [-34.1173, 147.2782],
  [-34.1150, 147.2772],
  [-34.1140, 147.2759],
  [-34.1136, 147.2743],
  [-34.1142, 147.2589],
  [-34.1146, 147.2564],
  [-34.1168, 147.2491],
  [-34.1167, 147.2466],
  [-34.1155, 147.2445],
  [-34.1110, 147.2417],
  [-34.1101, 147.2405],
  [-34.0894, 147.1817],
  [-34.0826, 147.1624],
  [-34.0613, 147.1144],
  [-34.0595, 147.1067],
  [-34.0583, 147.1039],
  [-34.0579, 147.1004],
  [-34.0536, 147.0903],
  [-34.0526, 147.0863],
  [-34.0515, 147.0603],
  [-34.0397, 146.9970],
  [-34.0310, 146.9684],
  [-34.0130, 146.9355],
  [-34.0125, 146.9337],
  [-34.0123, 146.9311],
  [-34.0114, 146.9281],
  [-34.0113, 146.9263],
  [-34.0126, 146.9170],
  [-34.0124, 146.9155],
  [-34.0118, 146.9142],
  [-34.0034, 146.9050],
  [-33.9853, 146.8707],
  [-33.9812, 146.8620],
  [-33.9759, 146.8286],
  [-33.9773, 146.7912],
  [-33.9762, 146.7776],
  [-33.9744, 146.7678],
  [-33.9742, 146.7569],
  [-33.9736, 146.7544],
  [-33.9721, 146.7523],
  [-33.9705, 146.7512],
  [-33.9665, 146.7498],
  [-33.9625, 146.7471],
  [-33.9568, 146.7417],
  [-33.9545, 146.7380],
  [-33.9438, 146.7192],
  [-33.9321, 146.7064],
  [-33.9298, 146.7033],
  [-33.9280, 146.7014],
  [-33.9197, 146.6957],
  [-33.9174, 146.6939],
  [-33.9020, 146.6765],
  [-33.9001, 146.6753],
  [-33.8938, 146.6737],
  [-33.8920, 146.6723],
  [-33.8910, 146.6702],
  [-33.8904, 146.6641],
  [-33.8876, 146.6578],
  [-33.8871, 146.6557],
  [-33.8865, 146.6477],
  [-33.8851, 146.6426],
  [-33.8827, 146.6384],
  [-33.8773, 146.6329],
  [-33.8763, 146.6309],
  [-33.8759, 146.6290],
  [-33.8762, 146.6212],
  [-33.8779, 146.6116],
  [-33.8777, 146.6090],
  [-33.8767, 146.6053],
  [-33.8766, 146.6024],
  [-33.8783, 146.5935],
  [-33.8794, 146.5907],
  [-33.8829, 146.5849],
  [-33.8839, 146.5821],
  [-33.8851, 146.5742],
  [-33.8849, 146.5716],
  [-33.8768, 146.5380],
  [-33.8766, 146.5338],
  [-33.8776, 146.5236],
  [-33.8770, 146.5205],
  [-33.8739, 146.5162],
  [-33.8733, 146.5151],
  [-33.8731, 146.5133],
  [-33.8741, 146.4956],
  [-33.8509, 146.3044],
  [-33.8501, 146.3014],
  [-33.8425, 146.2812],
  [-33.8419, 146.2783],
  [-33.8420, 146.2631]
];

export const rankinsSprings: Line = {
  name: 'Rankins Springs',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1923-01-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-07-04',
          status: 'closed'
        }]
      }
    }
  ]
};
