import { Line } from "../../../trackTypes";

const invercargilMakarewa = [
  [-46.4119, 168.3449],
  [-46.4104, 168.3447],
  [-46.4093, 168.3442],
  [-46.4046, 168.3436],
  [-46.3973, 168.3425],
  [-46.3924, 168.3419],
  [-46.3634, 168.3418],
  [-46.3448, 168.3402],
  [-46.3356, 168.3403],
  [-46.3174, 168.3402],
  [-46.3147, 168.3396],
  [-46.3109, 168.3376],
  [-46.3045, 168.3326]
];

const makarewaWinton = [
  [-46.3045, 168.3326],
  [-46.3010, 168.3313],
  [-46.2715, 168.3310],
  [-46.2591, 168.3312],
  [-46.2530, 168.3323],
  [-46.2141, 168.3327],
  [-46.1789, 168.3333],
  [-46.1632, 168.3358],
  [-46.1573, 168.3348],
  [-46.1528, 168.3326],
  [-46.1437, 168.3267]
];

const wintonCaroline = [
  [-46.1437, 168.3267],
  [-46.1421, 168.3267],
  [-46.1406, 168.3270],
  [-46.1373, 168.3275],
  [-46.1352, 168.3272],
  [-46.1267, 168.3254],
  [-46.1242, 168.3253],
  [-46.0866, 168.3347],
  [-46.0843, 168.3347],
  [-46.0699, 168.3335],
  [-46.0489, 168.3320],
  [-46.0457, 168.3326],
  [-46.0427, 168.3341],
  [-46.0169, 168.3517],
  [-46.0121, 168.3533],
  [-45.9413, 168.3483],
  [-45.9383, 168.3487],
  [-45.9357, 168.3500],
  [-45.9196, 168.3614],
  [-45.9161, 168.3629],
  [-45.9047, 168.3641],
  [-45.9009, 168.3639],
  [-45.8998, 168.3644],
  [-45.8993, 168.3656],
  [-45.8980, 168.3728],
  [-45.8970, 168.3753],
  [-45.8954, 168.3775],
  [-45.8930, 168.3787],
  [-45.8792, 168.3764],
  [-45.8469, 168.3780],
  [-45.8458, 168.3787],
  [-45.8454, 168.3797],
  [-45.8448, 168.3816],
  [-45.8437, 168.3827],
  [-45.8427, 168.3828],
  [-45.8419, 168.3833],
  [-45.8378, 168.3882]
];

const carolineLumsden = [
  [-45.8378, 168.3882],
  [-45.8352, 168.3894],
  [-45.8221, 168.3919],
  [-45.8203, 168.3918],
  [-45.8143, 168.3902],
  [-45.8109, 168.3880],
  [-45.8092, 168.3876],
  [-45.7980, 168.3906],
  [-45.7971, 168.3913],
  [-45.7967, 168.3923],
  [-45.7967, 168.3934],
  [-45.7979, 168.3971],
  [-45.7988, 168.3990],
  [-45.7991, 168.4008],
  [-45.7988, 168.4022],
  [-45.7956, 168.4112],
  [-45.7935, 168.4147],
  [-45.7779, 168.4319],
  [-45.7634, 168.4401],
  [-45.7614, 168.4406],
  [-45.7413, 168.4421],
  [-45.7381, 168.4421],
  [-45.7369, 168.4418],
  [-45.7330, 168.4394],
  [-45.7310, 168.4389],
  [-45.7289, 168.4390],
  [-45.7272, 168.4385],
  [-45.7238, 168.4349],
  [-45.7214, 168.4335],
  [-45.7196, 168.4335],
  [-45.7183, 168.4330],
  [-45.7169, 168.4318]
];

const lumsdenLowther = [
  [-45.7169, 168.4318],
  [-45.7155, 168.4314],
  [-45.7137, 168.4309],
  [-45.7127, 168.4300],
  [-45.7109, 168.4273],
  [-45.7097, 168.4262],
  [-45.7060, 168.4238],
  [-45.7041, 168.4232],
  [-45.6983, 168.4253],
  [-45.6949, 168.4251],
  [-45.6940, 168.4247],
  [-45.6930, 168.4236],
  [-45.6917, 168.4231],
  [-45.6905, 168.4232],
  [-45.6825, 168.4266],
  [-45.6802, 168.4269],
  [-45.6785, 168.4261],
  [-45.6772, 168.4250],
  [-45.6759, 168.4247],
  [-45.6744, 168.4250],
  [-45.6495, 168.4378],
  [-45.6395, 168.4455],
  [-45.6373, 168.4463],
  [-45.6297, 168.4480],
  [-45.6218, 168.4519]
];

const lowtherAthol = [
  [-45.6218, 168.4519],
  [-45.6165, 168.4547],
  [-45.6145, 168.4565],
  [-45.6114, 168.4611],
  [-45.6094, 168.4626],
  [-45.5922, 168.4640],
  [-45.5903, 168.4648],
  [-45.5845, 168.4697],
  [-45.5771, 168.4815],
  [-45.5740, 168.4841],
  [-45.5726, 168.4857],
  [-45.5714, 168.4880],
  [-45.5698, 168.4891],
  [-45.5685, 168.4891],
  [-45.5670, 168.4897],
  [-45.5632, 168.4926],
  [-45.5574, 168.4943],
  [-45.5565, 168.4949],
  [-45.5558, 168.4958],
  [-45.5533, 168.5015],
  [-45.5493, 168.5081],
  [-45.5440, 168.5238],
  [-45.5424, 168.5273],
  [-45.5143, 168.5683],
  [-45.5129, 168.5720],
  [-45.5122, 168.5757],
  [-45.5116, 168.5842]
];

const atholGarston = [
  [-45.5116, 168.5842],
  [-45.5109, 168.5959],
  [-45.5114, 168.5979],
  [-45.5139, 168.6030],
  [-45.5175, 168.6198],
  [-45.5176, 168.6217],
  [-45.5171, 168.6237],
  [-45.4961, 168.6624],
  [-45.4934, 168.6654],
  [-45.4685, 168.6834],
  [-45.4663, 168.6841]
];

const garstonFairlight = [
  [-45.4663, 168.6841],
  [-45.4652, 168.6842],
  [-45.4360, 168.6812],
  [-45.4335, 168.6804],
  [-45.4197, 168.6735]
];

const fairlightKingston = [
  [-45.4197, 168.6735],
  [-45.4179, 168.6730],
  [-45.3815, 168.6667],
  [-45.3777, 168.6672],
  [-45.3677, 168.6709],
  [-45.3549, 168.6782],
  [-45.3530, 168.6807],
  [-45.3523, 168.6833],
  [-45.3523, 168.6931],
  [-45.3517, 168.6951],
  [-45.3506, 168.6962],
  [-45.3480, 168.6969],
  [-45.3462, 168.6970],
  [-45.3427, 168.6962],
  [-45.3419, 168.6964],
  [-45.3413, 168.6970],
  [-45.3389, 168.7004],
  [-45.3385, 168.7016],
  [-45.3385, 168.7029],
  [-45.3390, 168.7042],
  [-45.3403, 168.7065],
  [-45.3410, 168.7082],
  [-45.3427, 168.7147],
  [-45.3428, 168.7166],
  [-45.3422, 168.7182],
  [-45.3411, 168.7195],
  [-45.3397, 168.7199],
  [-45.3384, 168.7193],
  [-45.3336, 168.7138],
  [-45.3325, 168.7131],
  [-45.3298, 168.7117]
];

export const kingston: Line = {
  name: 'Kingston (NZ)',
  state: 'NZ',
  segments: [
    {
      segments: [invercargilMakarewa],
      history: {
        opened: {
          date: '1864-10-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1875-12-20',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [makarewaWinton],
      history: {
        opened: {
          date: '1871-02-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1875-12-20',
          gauge: 'narrow'
        }, {
          date: '1982-12-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wintonCaroline],
      history: {
        opened: {
          date: '1875-10-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1875-12-20',
          gauge: 'narrow'
        }, {
          date: '1992-11-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [carolineLumsden],
      history: {
        opened: {
          date: '1876-02-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-12-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lumsdenLowther],
      history: {
        opened: {
          date: '1877-01-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lowtherAthol],
      history: {
        opened: {
          date: '1878-01-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [atholGarston],
      history: {
        opened: {
          date: '1878-07-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-11-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [garstonFairlight],
      history: {
        opened: {
          date: '1878-07-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fairlightKingston],
      history: {
        opened: {
          date: '1878-07-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
