import { Line } from "../../trackTypes";

const juneeNarrandera = [
  [-34.8606, 147.5859],
  [-34.8614, 147.5855],
  [-34.8621, 147.5846],
  [-34.8625, 147.5836],
  [-34.8625, 147.5826],
  [-34.8624, 147.5817],
  [-34.8621, 147.5809],
  [-34.8521, 147.5693],
  [-34.8490, 147.5650],
  [-34.8464, 147.5602],
  [-34.8449, 147.5552],
  [-34.8325, 147.4598],
  [-34.8318, 147.4565],
  [-34.8301, 147.4527],
  [-34.8296, 147.4506],
  [-34.8296, 147.4479],
  [-34.8324, 147.4396],
  [-34.8327, 147.4369],
  [-34.8322, 147.4349],
  [-34.8311, 147.4324],
  [-34.8306, 147.4302],
  [-34.8306, 147.4279],
  [-34.8316, 147.4125],
  [-34.8313, 147.4102],
  [-34.8283, 147.3992],
  [-34.8281, 147.3959],
  [-34.8287, 147.3930],
  [-34.8299, 147.3902],
  [-34.8305, 147.3838],
  [-34.8301, 147.3807],
  [-34.8286, 147.3764],
  [-34.8277, 147.3725],
  [-34.8267, 147.3627],
  [-34.8207, 147.3414],
  [-34.8193, 147.3344],
  [-34.8141, 147.2872],
  [-34.8143, 147.2849],
  [-34.8152, 147.2832],
  [-34.8165, 147.2817],
  [-34.8174, 147.2798],
  [-34.8174, 147.2777],
  [-34.8167, 147.2759],
  [-34.8153, 147.2744],
  [-34.8145, 147.2727],
  [-34.8143, 147.2708],
  [-34.8147, 147.2683],
  [-34.8144, 147.2661],
  [-34.8136, 147.2649],
  [-34.8128, 147.2638],
  [-34.8120, 147.2620],
  [-34.8106, 147.2554],
  [-34.8093, 147.2443],
  [-34.8093, 147.2369],
  [-34.8101, 147.2298],
  [-34.8182, 147.1897],
  [-34.8188, 147.1818],
  [-34.8182, 147.1716],
  [-34.7626, 146.8967],
  [-34.7268, 146.7219],
  [-34.7257, 146.7143],
  [-34.7257, 146.7053],
  [-34.7404, 146.5602],
  [-34.7404, 146.5554]
];

const juneeJunction = [
  [-34.8621, 147.5809],
  [-34.8631, 147.5821],
  [-34.8637, 147.5831],
  [-34.8645, 147.5839],
  [-34.8654, 147.5844],
  [-34.8667, 147.5845]
];

const narranderaYancoJunction = [
  [-34.7404, 146.5554],
  [-34.7389, 146.5438],
  [-34.7379, 146.5404],
  [-34.7358, 146.5371],
  [-34.7331, 146.5351],
  [-34.7293, 146.5342],
  [-34.7108, 146.5361],
  [-34.7073, 146.5355],
  [-34.6844, 146.5269],
  [-34.6829, 146.5258],
  [-34.6818, 146.5242],
  [-34.6722, 146.5009],
  [-34.6712, 146.4972],
  [-34.6707, 146.4923],
  [-34.6699, 146.4899],
  [-34.6680, 146.4873],
  [-34.6652, 146.4858],
  [-34.6620, 146.4851],
  [-34.6590, 146.4838],
  [-34.6563, 146.4815],
  [-34.6446, 146.4695],
  [-34.6217, 146.4463],
  [-34.6190, 146.4421],
  [-34.6035, 146.4034]
];

const yancoJunctionWillbriggie = [
  [-34.6035, 146.4034],
  [-34.4931, 146.1243],
  [-34.4894, 146.1129],
  [-34.4838, 146.0905],
  [-34.4662, 146.0196]
];

const willbriggieCarrathool = [
  [-34.4662, 146.0196],
  [-34.4530, 145.9666],
  [-34.4414, 145.9202],
  [-34.4392, 145.9060],
  [-34.4396, 145.8924],
  [-34.4438, 145.7205],
  [-34.4433, 145.7052],
  [-34.4421, 145.6958],
  [-34.4043, 145.5184],
  [-34.4021, 145.5023],
  [-34.4022, 145.4853],
  [-34.4063, 145.4360]
];

const carrathoolHay = [
  [-34.4063, 145.4360],
  [-34.4259, 145.1963],
  [-34.4422, 144.9920],
  [-34.4455, 144.9748],
  [-34.4509, 144.9585],
  [-34.4992, 144.8506],
  [-34.4997, 144.8486],
  [-34.4997, 144.8467],
  [-34.4988, 144.8389],
];

export const hay: Line = {
  name: 'Hay',
  state: 'NSW',
  segments: [
    {
      segments: [juneeNarrandera, juneeJunction],
      history: {
        opened: {
          date: '1881-02-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [narranderaYancoJunction],
      history: {
        opened: {
          date: '1881-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [yancoJunctionWillbriggie],
      history: {
        opened: {
          date: '1881-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-07-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [willbriggieCarrathool],
      history: {
        opened: {
          date: '1882-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-12-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [carrathoolHay],
      history: {
        opened: {
          date: '1882-07-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-12-06',
          status: 'closed'
        }]
      }
    }
  ]
};
