import { Line } from "../../../trackTypes";

const centralTunnelPortal = [
  [-33.8882, 151.2020],
  [-33.8858, 151.2055],
  [-33.8842, 151.2070],
  [-33.8833, 151.2077],
  [-33.8823, 151.2081],
  [-33.8795, 151.2087]
];

const tunnelPortalCityCircleJunction = [
  [-33.8795, 151.2087],
  [-33.8787, 151.2088]
];

const cityCircleJunctionWynyard = [
  [-33.8787, 151.2088],
  [-33.8776, 151.2084],
  [-33.8771, 151.2080],
  [-33.8765, 151.2072],
  [-33.8759, 151.2067],
  [-33.8753, 151.2065],
  [-33.8731, 151.2069],
  [-33.8722, 151.2068],
  [-33.8712, 151.2065],
  [-33.8701, 151.2062],
  [-33.8677, 151.2059],
  [-33.8659, 151.2057]
];

const wynyardWynyardJunction = [
  [-33.8659, 151.2057],
  [-33.8634, 151.2054]
];

const wynyardCircularQuay = [
  [-33.8634, 151.2054],
  [-33.8629, 151.2054],
  [-33.8624, 151.2056],
  [-33.8618, 151.2059],
  [-33.8615, 151.2063],
  [-33.8613, 151.2066],
  [-33.8611, 151.2076],
  [-33.8611, 151.2081]
];

const circularQuay = [
  [-33.8611, 151.2081],
  [-33.8615, 151.2127]
];

const circularQuayStJames = [
  [-33.8615, 151.2127],
  [-33.8616, 151.2135],
  [-33.8620, 151.2142],
  [-33.8624, 151.2146],
  [-33.8631, 151.2149],
  [-33.8638, 151.2149],
  [-33.8645, 151.2145],
  [-33.8651, 151.2138],
  [-33.8657, 151.2131],
  [-33.8661, 151.2126],
  [-33.8666, 151.2124],
  [-33.8707, 151.2118]
];

const stJamesCityCircleJunction = [
  [-33.8707, 151.2118],
  [-33.8722, 151.2116],
  [-33.8737, 151.2110],
  [-33.8766, 151.2096],
  [-33.8771, 151.2094],
  [-33.8782, 151.2091],
  [-33.8787, 151.2088]
];

export const cityCircle: Line = {
  name: 'City Circle',
  state: 'NSW',
  segments: [
    {
      segments: [
        centralTunnelPortal, {
          segment: tunnelPortalCityCircleJunction,
          type: 'tunnel'
        }
      ],
      history: {
        opened: {
          date: '1926-12-20',
          tracks: 6,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: cityCircleJunctionWynyard,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1932-02-28',
          tracks: 4,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: wynyardWynyardJunction,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1932-02-28',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-01-22',
          tracks: 4
        }]
      }
    },
    {
      segments: [{
          segment: wynyardCircularQuay,
          type: 'tunnel'
        },
        circularQuay, {
          segment: circularQuayStJames,
          type: 'tunnel'
        }
      ],
      history: {
        opened: {
          date: '1956-01-22',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: stJamesCityCircleJunction,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1926-12-20',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
  ]
};
