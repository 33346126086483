import { Line } from "../../../trackTypes";

const maitlandJunction = [
  [-32.7329, 151.5371],
  [-32.7329, 151.5386],
  [-32.7326, 151.5394],
  [-32.7319, 151.5402],
  [-32.7314, 151.5405]
];

const maitlandJunctionWallarobbaTunnel = [
  [-32.7348, 151.5433],
  [-32.7338, 151.5416],
  [-32.7328, 151.5409],
  [-32.7314, 151.5405],
  [-32.7255, 151.5396],
  [-32.7242, 151.5395],
  [-32.7217, 151.5400],
  [-32.7207, 151.5405],
  [-32.7199, 151.5413],
  [-32.7186, 151.5431],
  [-32.7175, 151.5442],
  [-32.7163, 151.5446],
  [-32.7128, 151.5444],
  [-32.7114, 151.5438],
  [-32.7094, 151.5424],
  [-32.7074, 151.5421],
  [-32.7058, 151.5427],
  [-32.7043, 151.5444],
  [-32.6998, 151.5539],
  [-32.6990, 151.5552],
  [-32.6975, 151.5570],
  [-32.6964, 151.5589],
  [-32.6958, 151.5611],
  [-32.6948, 151.5704],
  [-32.6945, 151.5714],
  [-32.6939, 151.5722],
  [-32.6918, 151.5737],
  [-32.6903, 151.5741],
  [-32.6888, 151.5741],
  [-32.6872, 151.5737],
  [-32.6854, 151.5740],
  [-32.6840, 151.5750],
  [-32.6813, 151.5789],
  [-32.6806, 151.5803],
  [-32.6800, 151.5822],
  [-32.6788, 151.5838],
  [-32.6773, 151.5843],
  [-32.6759, 151.5840],
  [-32.6746, 151.5834],
  [-32.6733, 151.5832],
  [-32.6650, 151.5852],
  [-32.6637, 151.5858],
  [-32.6624, 151.5866],
  [-32.6613, 151.5869],
  [-32.6601, 151.5869],
  [-32.6563, 151.5855],
  [-32.6541, 151.5850],
  [-32.6530, 151.5842],
  [-32.6520, 151.5831],
  [-32.6512, 151.5825],
  [-32.6501, 151.5823],
  [-32.6489, 151.5828],
  [-32.6475, 151.5842],
  [-32.6463, 151.5846],
  [-32.6450, 151.5845],
  [-32.6441, 151.5837],
  [-32.6432, 151.5824],
  [-32.6421, 151.5813],
  [-32.6405, 151.5804],
  [-32.6383, 151.5803],
  [-32.6319, 151.5826],
  [-32.6308, 151.5827],
  [-32.6292, 151.5823],
  [-32.6267, 151.5810],
  [-32.6217, 151.5800],
  [-32.6201, 151.5805],
  [-32.6187, 151.5817],
  [-32.6179, 151.5829],
  [-32.6164, 151.5876],
  [-32.6159, 151.5887],
  [-32.6141, 151.5908],
  [-32.6131, 151.5917],
  [-32.6123, 151.5927],
  [-32.6120, 151.5938],
  [-32.6110, 151.6017],
  [-32.6104, 151.6033],
  [-32.6091, 151.6053],
  [-32.6081, 151.6077],
  [-32.6073, 151.6087],
  [-32.6058, 151.6097],
  [-32.6049, 151.6107],
  [-32.6009, 151.6165],
  [-32.5998, 151.6173],
  [-32.5939, 151.6183],
  [-32.5854, 151.6188],
  [-32.5810, 151.6197],
  [-32.5769, 151.6201],
  [-32.5738, 151.6202],
  [-32.5721, 151.6207],
  [-32.5708, 151.6214],
  [-32.5674, 151.6242],
  [-32.5661, 151.6247],
  [-32.5647, 151.6242],
  [-32.5638, 151.6235],
  [-32.5624, 151.6209],
  [-32.5615, 151.6196],
  [-32.5600, 151.6188],
  [-32.5578, 151.6183],
  [-32.5566, 151.6176],
  [-32.5544, 151.6154],
  [-32.5521, 151.6144],
  [-32.5498, 151.6143],
  [-32.5462, 151.6153],
  [-32.5451, 151.6153],
  [-32.5439, 151.6146],
  [-32.5429, 151.6132],
  [-32.5411, 151.6123],
  [-32.5397, 151.6126],
  [-32.5361, 151.6157],
  [-32.5352, 151.6169],
  [-32.5334, 151.6198],
  [-32.5259, 151.6300],
  [-32.5227, 151.6323],
  [-32.5185, 151.6339],
  [-32.5169, 151.6348],
  [-32.5147, 151.6367],
  [-32.5126, 151.6377],
  [-32.5113, 151.6380],
  [-32.5103, 151.6386],
  [-32.5073, 151.6420],
  [-32.5067, 151.6431],
  [-32.5065, 151.6442],
  [-32.5067, 151.6460],
  [-32.5064, 151.6477],
  [-32.5056, 151.6491],
  [-32.5037, 151.6501],
  [-32.5028, 151.6509],
  [-32.5023, 151.6521],
  [-32.5023, 151.6541],
  [-32.5039, 151.6604],
  [-32.5045, 151.6614],
  [-32.5054, 151.6621],
  [-32.5069, 151.6626],
  [-32.5079, 151.6637],
  [-32.5083, 151.6652],
  [-32.5083, 151.6658]
];

const wallarobbaTunnel = [
  [-32.5083, 151.6658],
  [-32.5080, 151.6690]
];

const wallarobbaTunnelDungog = [
  [-32.5080, 151.6690],
  [-32.5079, 151.6702],
  [-32.5080, 151.6712],
  [-32.5083, 151.6756],
  [-32.5080, 151.6772],
  [-32.5073, 151.6785],
  [-32.5013, 151.6834],
  [-32.5002, 151.6848],
  [-32.4993, 151.6867],
  [-32.4979, 151.6882],
  [-32.4972, 151.6890],
  [-32.4967, 151.6904],
  [-32.4957, 151.6986],
  [-32.4951, 151.7005],
  [-32.4928, 151.7044],
  [-32.4920, 151.7070],
  [-32.4911, 151.7144],
  [-32.4906, 151.7156],
  [-32.4898, 151.7166],
  [-32.4887, 151.7172],
  [-32.4872, 151.7184],
  [-32.4830, 151.7243],
  [-32.4824, 151.7255],
  [-32.4820, 151.7271],
  [-32.4814, 151.7282],
  [-32.4787, 151.7316],
  [-32.4735, 151.7367],
  [-32.4707, 151.7398],
  [-32.4689, 151.7410],
  [-32.4667, 151.7416],
  [-32.4647, 151.7416],
  [-32.4635, 151.7421],
  [-32.4592, 151.7455],
  [-32.4575, 151.7459],
  [-32.4567, 151.7456],
  [-32.4551, 151.7446],
  [-32.4542, 151.7444],
  [-32.4529, 151.7445],
  [-32.4517, 151.7453],
  [-32.4457, 151.7530],
  [-32.4448, 151.7538],
  [-32.4435, 151.7541],
  [-32.4423, 151.7539],
  [-32.4400, 151.7526],
  [-32.4386, 151.7526],
  [-32.4329, 151.7545],
  [-32.4322, 151.7549],
  [-32.4309, 151.7561],
  [-32.4303, 151.7563],
  [-32.4190, 151.7588],
  [-32.4149, 151.7593],
  [-32.4134, 151.7592],
  [-32.4108, 151.7584],
  [-32.4089, 151.7583],
  [-32.4024, 151.7594]
];

const dungogMonkeraiTunnel = [
  [-32.4024, 151.7594],
  [-32.3986, 151.7603],
  [-32.3977, 151.7609],
  [-32.3971, 151.7617],
  [-32.3921, 151.7706],
  [-32.3907, 151.7724],
  [-32.3799, 151.7810],
  [-32.3790, 151.7821],
  [-32.3786, 151.7834],
  [-32.3782, 151.7856],
  [-32.3775, 151.7870],
  [-32.3764, 151.7876],
  [-32.3754, 151.7878],
  [-32.3739, 151.7876],
  [-32.3729, 151.7878],
  [-32.3722, 151.7882],
  [-32.3700, 151.7903],
  [-32.3694, 151.7914],
  [-32.3692, 151.7929],
  [-32.3696, 151.7943],
  [-32.3719, 151.7984],
  [-32.3723, 151.7996],
  [-32.3722, 151.8005],
  [-32.3716, 151.8029],
  [-32.3713, 151.8037],
  [-32.3706, 151.8048],
  [-32.3703, 151.8058],
  [-32.3703, 151.8068],
  [-32.3707, 151.8085],
  [-32.3713, 151.8096],
  [-32.3724, 151.8104],
  [-32.3739, 151.8105],
  [-32.3760, 151.8093],
  [-32.3767, 151.8091],
  [-32.3774, 151.8091],
  [-32.3804, 151.8103],
  [-32.3819, 151.8117],
  [-32.3822, 151.8134],
  [-32.3819, 151.8147],
  [-32.3806, 151.8171],
  [-32.3801, 151.8182],
  [-32.3796, 151.8210],
  [-32.3796, 151.8223],
  [-32.3798, 151.8243],
  [-32.3797, 151.8251]
];

const monkeraiTunnel = [
  [-32.3797, 151.8251],
  [-32.3783, 151.8329]
];

const monkeraiTunnelBulliacTunnel = [
  [-32.3783, 151.8329],
  [-32.3782, 151.8336],
  [-32.3782, 151.8350],
  [-32.3786, 151.8368],
  [-32.3792, 151.8378],
  [-32.3800, 151.8389],
  [-32.3804, 151.8403],
  [-32.3803, 151.8414],
  [-32.3800, 151.8424],
  [-32.3789, 151.8435],
  [-32.3774, 151.8442],
  [-32.3765, 151.8450],
  [-32.3759, 151.8462],
  [-32.3756, 151.8484],
  [-32.3751, 151.8495],
  [-32.3745, 151.8502],
  [-32.3734, 151.8510],
  [-32.3726, 151.8519],
  [-32.3722, 151.8530],
  [-32.3720, 151.8547],
  [-32.3701, 151.8599],
  [-32.3696, 151.8607],
  [-32.3685, 151.8617],
  [-32.3679, 151.8628],
  [-32.3675, 151.8652],
  [-32.3677, 151.8667],
  [-32.3681, 151.8676],
  [-32.3703, 151.8706],
  [-32.3708, 151.8724],
  [-32.3706, 151.8737],
  [-32.3695, 151.8753],
  [-32.3669, 151.8767],
  [-32.3660, 151.8780],
  [-32.3657, 151.8794],
  [-32.3659, 151.8841],
  [-32.3655, 151.8855],
  [-32.3623, 151.8926],
  [-32.3604, 151.8961],
  [-32.3601, 151.8973],
  [-32.3599, 151.9002],
  [-32.3594, 151.9018],
  [-32.3584, 151.9029],
  [-32.3562, 151.9039],
  [-32.3554, 151.9048],
  [-32.3548, 151.9059],
  [-32.3548, 151.9075],
  [-32.3559, 151.9104],
  [-32.3560, 151.9119],
  [-32.3557, 151.9131],
  [-32.3549, 151.9146],
  [-32.3546, 151.9160],
  [-32.3548, 151.9173],
  [-32.3553, 151.9189],
  [-32.3554, 151.9204],
  [-32.3549, 151.9215],
  [-32.3536, 151.9226],
  [-32.3525, 151.9230],
  [-32.3517, 151.9236],
  [-32.3512, 151.9244],
  [-32.3503, 151.9264],
  [-32.3500, 151.9269],
  [-32.3472, 151.9299],
  [-32.3460, 151.9305],
  [-32.3446, 151.9306],
  [-32.3437, 151.9304],
  [-32.3426, 151.9307],
  [-32.3387, 151.9336],
  [-32.3379, 151.9349],
  [-32.3372, 151.9369],
  [-32.3360, 151.9387],
  [-32.3348, 151.9395],
  [-32.3332, 151.9400],
  [-32.3321, 151.9399],
  [-32.3303, 151.9394],
  [-32.3295, 151.9394],
  [-32.3261, 151.9406],
  [-32.3253, 151.9406],
  [-32.3245, 151.9404],
  [-32.3231, 151.9394],
  [-32.3211, 151.9391],
  [-32.3179, 151.9397],
  [-32.3161, 151.9402],
  [-32.3139, 151.9403],
  [-32.3133, 151.9402],
  [-32.3120, 151.9407],
  [-32.3108, 151.9419],
  [-32.3094, 151.9425],
  [-32.3087, 151.9424],
  [-32.3078, 151.9423],
  [-32.3067, 151.9425],
  [-32.3029, 151.9443],
  [-32.3020, 151.9455],
  [-32.3017, 151.9468],
  [-32.3016, 151.9488],
  [-32.3012, 151.9502],
  [-32.2996, 151.9522],
  [-32.2984, 151.9528],
  [-32.2971, 151.9528],
  [-32.2960, 151.9521],
  [-32.2950, 151.9506],
  [-32.2939, 151.9498],
  [-32.2925, 151.9497],
  [-32.2907, 151.9507],
  [-32.2894, 151.9509],
  [-32.2882, 151.9504],
  [-32.2872, 151.9494],
  [-32.2865, 151.9479],
  [-32.2856, 151.9469],
  [-32.2841, 151.9461],
  [-32.2822, 151.9446],
  [-32.2791, 151.9428],
  [-32.2745, 151.9409],
  [-32.2724, 151.9407],
  [-32.2663, 151.9421],
  [-32.2627, 151.9433],
  [-32.2610, 151.9434],
  [-32.2543, 151.9427],
  [-32.2514, 151.9420],
  [-32.2499, 151.9423],
  [-32.2490, 151.9431],
  [-32.2483, 151.9447],
  [-32.2472, 151.9457],
  [-32.2462, 151.9460],
  [-32.2442, 151.9456],
  [-32.2434, 151.9451],
  [-32.2428, 151.9443],
  [-32.2423, 151.9432],
  [-32.2416, 151.9422],
  [-32.2406, 151.9411],
  [-32.2400, 151.9402],
  [-32.2393, 151.9388],
  [-32.2380, 151.9375],
  [-32.2367, 151.9370],
  [-32.2342, 151.9371],
  [-32.2326, 151.9369],
  [-32.2274, 151.9351],
  [-32.2263, 151.9351],
  [-32.2254, 151.9353],
  [-32.2184, 151.9390],
  [-32.2168, 151.9396],
  [-32.2153, 151.9401],
  [-32.2145, 151.9408],
  [-32.2133, 151.9420],
  [-32.2120, 151.9424],
  [-32.2076, 151.9415],
  [-32.2051, 151.9414],
  [-32.2031, 151.9417],
  [-32.2003, 151.9423],
  [-32.1992, 151.9421],
  [-32.1982, 151.9414],
  [-32.1972, 151.9401],
  [-32.1962, 151.9392],
  [-32.1953, 151.9389],
  [-32.1916, 151.9393],
  [-32.1903, 151.9398],
  [-32.1881, 151.9411],
  [-32.1868, 151.9413],
  [-32.1857, 151.9410],
  [-32.1843, 151.9402],
  [-32.1830, 151.9399],
  [-32.1804, 151.9396],
  [-32.1784, 151.9391],
  [-32.1768, 151.9381],
  [-32.1759, 151.9375],
  [-32.1746, 151.9373],
  [-32.1735, 151.9377],
  [-32.1726, 151.9386],
  [-32.1709, 151.9416],
  [-32.1697, 151.9425],
  [-32.1684, 151.9425],
  [-32.1670, 151.9421],
  [-32.1661, 151.9421],
  [-32.1652, 151.9425],
  [-32.1626, 151.9443],
  [-32.1614, 151.9446],
  [-32.1603, 151.9444],
  [-32.1449, 151.9375],
  [-32.1400, 151.9352],
  [-32.1388, 151.9351],
  [-32.1374, 151.9356],
  [-32.1354, 151.9366],
  [-32.1292, 151.9382],
  [-32.1267, 151.9382],
  [-32.1195, 151.9374],
  [-32.1108, 151.9372],
  [-32.1078, 151.9380],
  [-32.1058, 151.9393],
  [-32.1041, 151.9396],
  [-32.1024, 151.9392],
  [-32.1015, 151.9393],
  [-32.1007, 151.9398],
  [-32.0996, 151.9409],
  [-32.0987, 151.9414],
  [-32.0925, 151.9433],
  [-32.0912, 151.9434],
  [-32.0892, 151.9431],
  [-32.0878, 151.9436],
  [-32.0868, 151.9446],
  [-32.0857, 151.9471],
  [-32.0845, 151.9482],
  [-32.0832, 151.9485],
  [-32.0821, 151.9481],
  [-32.0804, 151.9471],
  [-32.0788, 151.9470],
  [-32.0776, 151.9477],
  [-32.0769, 151.9486],
  [-32.0762, 151.9500],
  [-32.0749, 151.9510],
  [-32.0717, 151.9522],
  [-32.0703, 151.9523],
  [-32.0690, 151.9520],
  [-32.0666, 151.9510],
  [-32.0656, 151.9508],
  [-32.0648, 151.9510],
  [-32.0614, 151.9526],
  [-32.0599, 151.9544],
  [-32.0591, 151.9566],
  [-32.0588, 151.9585],
  [-32.0581, 151.9598],
  [-32.0568, 151.9607],
  [-32.0556, 151.9607],
  [-32.0545, 151.9601],
  [-32.0522, 151.9579],
  [-32.0497, 151.9565],
  [-32.0447, 151.9555],
  [-32.0430, 151.9556],
  [-32.0417, 151.9562],
  [-32.0404, 151.9571],
  [-32.0394, 151.9574],
  [-32.0383, 151.9573],
  [-32.0371, 151.9569],
  [-32.0356, 151.9569],
  [-32.0312, 151.9588],
  [-32.0300, 151.9600],
  [-32.0296, 151.9611],
  [-32.0294, 151.9623],
  [-32.0290, 151.9634],
  [-32.0274, 151.9653],
  [-32.0264, 151.9660],
  [-32.0230, 151.9671],
  [-32.0219, 151.9671],
  [-32.0203, 151.9665],
  [-32.0194, 151.9666],
  [-32.0180, 151.9671],
  [-32.0168, 151.9671],
  [-32.0160, 151.9668],
  [-32.0147, 151.9660],
  [-32.0136, 151.9659],
  [-32.0127, 151.9661],
  [-32.0115, 151.9666],
  [-32.0105, 151.9666],
  [-32.0072, 151.9659],
  [-32.0060, 151.9661],
  [-32.0011, 151.9687],
  [-31.9998, 151.9690],
  [-31.9972, 151.9684],
  [-31.9963, 151.9679],
  [-31.9935, 151.9651],
  [-31.9926, 151.9645],
  [-31.9888, 151.9627],
  [-31.9875, 151.9626],
  [-31.9854, 151.9633],
  [-31.9845, 151.9639],
  [-31.9840, 151.9647],
  [-31.9835, 151.9661],
  [-31.9828, 151.9669],
  [-31.9805, 151.9687],
  [-31.9794, 151.9700],
  [-31.9776, 151.9734],
  [-31.9773, 151.9747],
  [-31.9777, 151.9768],
  [-31.9776, 151.9780],
  [-31.9773, 151.9790],
  [-31.9761, 151.9804],
  [-31.9753, 151.9808],
  [-31.9722, 151.9819],
  [-31.9692, 151.9823],
  [-31.9674, 151.9823],
  [-31.9642, 151.9829],
  [-31.9632, 151.9838],
  [-31.9627, 151.9852],
  [-31.9629, 151.9866],
  [-31.9638, 151.9883],
  [-31.9640, 151.9896],
  [-31.9640, 151.9914],
  [-31.9639, 151.9921],
  [-31.9629, 151.9949],
  [-31.9619, 151.9961],
  [-31.9595, 151.9998],
  [-31.9591, 152.0010],
  [-31.9591, 152.0025],
  [-31.9586, 152.0037],
  [-31.9574, 152.0051],
  [-31.9559, 152.0075],
  [-31.9552, 152.0094],
  [-31.9547, 152.0102],
  [-31.9534, 152.0113],
  [-31.9527, 152.0122],
  [-31.9515, 152.0143],
  [-31.9484, 152.0170],
  [-31.9473, 152.0175],
  [-31.9436, 152.0178],
  [-31.9415, 152.0172],
  [-31.9402, 152.0174],
  [-31.9392, 152.0180],
  [-31.9382, 152.0193],
  [-31.9371, 152.0200],
  [-31.9356, 152.0204],
  [-31.9347, 152.0210],
  [-31.9342, 152.0220],
  [-31.9340, 152.0233],
  [-31.9337, 152.0241],
  [-31.9327, 152.0258],
  [-31.9319, 152.0264],
  [-31.9310, 152.0265],
  [-31.9306, 152.0264]
];

const bulliacTunnel = [
  [-31.9306, 152.0264],
  [-31.9301, 152.0262],
  [-31.9299, 152.0260],
  [-31.9296, 152.0253],
  [-31.9289, 152.0243]
];

const bulliacTunnelTaree = [
  [-31.9289, 152.0243],
  [-31.9283, 152.0234],
  [-31.9247, 152.0208],
  [-31.9234, 152.0206],
  [-31.9221, 152.0211],
  [-31.9213, 152.0222],
  [-31.9187, 152.0274],
  [-31.9177, 152.0289],
  [-31.9173, 152.0302],
  [-31.9169, 152.0328],
  [-31.9156, 152.0382],
  [-31.9155, 152.0400],
  [-31.9152, 152.0412],
  [-31.9127, 152.0452],
  [-31.9119, 152.0481],
  [-31.9120, 152.0515],
  [-31.9124, 152.0529],
  [-31.9139, 152.0561],
  [-31.9141, 152.0575],
  [-31.9139, 152.0587],
  [-31.9132, 152.0596],
  [-31.9120, 152.0609],
  [-31.9116, 152.0618],
  [-31.9114, 152.0629],
  [-31.9115, 152.0640],
  [-31.9107, 152.0660],
  [-31.9096, 152.0669],
  [-31.9080, 152.0672],
  [-31.9070, 152.0677],
  [-31.9061, 152.0689],
  [-31.9058, 152.0703],
  [-31.9058, 152.0739],
  [-31.9055, 152.0750],
  [-31.9027, 152.0803],
  [-31.9022, 152.0809],
  [-31.9004, 152.0824],
  [-31.8972, 152.0836],
  [-31.8960, 152.0848],
  [-31.8955, 152.0867],
  [-31.8959, 152.0901],
  [-31.8963, 152.0913],
  [-31.8970, 152.0926],
  [-31.8973, 152.0942],
  [-31.8971, 152.0952],
  [-31.8966, 152.0971],
  [-31.8966, 152.0985],
  [-31.8962, 152.0998],
  [-31.8958, 152.1004],
  [-31.8926, 152.1039],
  [-31.8914, 152.1062],
  [-31.8902, 152.1071],
  [-31.8889, 152.1074],
  [-31.8878, 152.1082],
  [-31.8874, 152.1099],
  [-31.8877, 152.1111],
  [-31.8885, 152.1120],
  [-31.8901, 152.1129],
  [-31.8912, 152.1143],
  [-31.8914, 152.1160],
  [-31.8909, 152.1181],
  [-31.8909, 152.1200],
  [-31.8925, 152.1244],
  [-31.8938, 152.1261],
  [-31.8958, 152.1275],
  [-31.8967, 152.1291],
  [-31.8965, 152.1308],
  [-31.8953, 152.1334],
  [-31.8950, 152.1352],
  [-31.8953, 152.1390],
  [-31.8950, 152.1405],
  [-31.8940, 152.1416],
  [-31.8931, 152.1419],
  [-31.8907, 152.1417],
  [-31.8897, 152.1420],
  [-31.8890, 152.1428],
  [-31.8887, 152.1437],
  [-31.8884, 152.1454],
  [-31.8879, 152.1465],
  [-31.8864, 152.1486],
  [-31.8852, 152.1494],
  [-31.8840, 152.1497],
  [-31.8829, 152.1503],
  [-31.8822, 152.1518],
  [-31.8821, 152.1530],
  [-31.8824, 152.1541],
  [-31.8844, 152.1571],
  [-31.8859, 152.1610],
  [-31.8864, 152.1648],
  [-31.8861, 152.1663],
  [-31.8852, 152.1675],
  [-31.8826, 152.1697],
  [-31.8820, 152.1707],
  [-31.8818, 152.1720],
  [-31.8818, 152.1733],
  [-31.8817, 152.1742],
  [-31.8815, 152.1754],
  [-31.8818, 152.1770],
  [-31.8843, 152.1808],
  [-31.8879, 152.1904],
  [-31.8890, 152.1920],
  [-31.8907, 152.1932],
  [-31.8926, 152.1935],
  [-31.8978, 152.1926],
  [-31.8997, 152.1928],
  [-31.9011, 152.1937],
  [-31.9029, 152.1958],
  [-31.9061, 152.2020],
  [-31.9079, 152.2072],
  [-31.9086, 152.2130],
  [-31.9099, 152.2184],
  [-31.9101, 152.2246],
  [-31.9094, 152.2263],
  [-31.9082, 152.2271],
  [-31.9067, 152.2277],
  [-31.9054, 152.2287],
  [-31.9043, 152.2308],
  [-31.9035, 152.2316],
  [-31.9020, 152.2324],
  [-31.9012, 152.2332],
  [-31.9008, 152.2343],
  [-31.9006, 152.2366],
  [-31.9002, 152.2378],
  [-31.8985, 152.2415],
  [-31.8979, 152.2437],
  [-31.8980, 152.2463],
  [-31.8983, 152.2482],
  [-31.8995, 152.2524],
  [-31.9004, 152.2573],
  [-31.9020, 152.2613],
  [-31.9047, 152.2649],
  [-31.9057, 152.2674],
  [-31.9066, 152.2690],
  [-31.9085, 152.2705],
  [-31.9114, 152.2724],
  [-31.9154, 152.2755],
  [-31.9180, 152.2764],
  [-31.9193, 152.2776],
  [-31.9200, 152.2794],
  [-31.9210, 152.2807],
  [-31.9246, 152.2830],
  [-31.9254, 152.2839],
  [-31.9259, 152.2853],
  [-31.9258, 152.2870],
  [-31.9236, 152.2923],
  [-31.9223, 152.2935],
  [-31.9209, 152.2936],
  [-31.9196, 152.2930],
  [-31.9181, 152.2915],
  [-31.9135, 152.2890],
  [-31.9124, 152.2889],
  [-31.9113, 152.2895],
  [-31.9106, 152.2905],
  [-31.9097, 152.2916],
  [-31.9085, 152.2924],
  [-31.9049, 152.2957],
  [-31.9037, 152.2972],
  [-31.8959, 152.3105],
  [-31.8949, 152.3114],
  [-31.8938, 152.3116],
  [-31.8907, 152.3119],
  [-31.8893, 152.3125],
  [-31.8870, 152.3149],
  [-31.8864, 152.3162],
  [-31.8861, 152.3182],
  [-31.8857, 152.3192],
  [-31.8850, 152.3201],
  [-31.8834, 152.3211],
  [-31.8822, 152.3225],
  [-31.8812, 152.3232],
  [-31.8793, 152.3236],
  [-31.8783, 152.3242],
  [-31.8776, 152.3252],
  [-31.8773, 152.3264],
  [-31.8772, 152.3285],
  [-31.8767, 152.3300],
  [-31.8759, 152.3309],
  [-31.8748, 152.3318],
  [-31.8740, 152.3329],
  [-31.8738, 152.3339],
  [-31.8736, 152.3353],
  [-31.8731, 152.3367],
  [-31.8706, 152.3402],
  [-31.8700, 152.3418],
  [-31.8699, 152.3432],
  [-31.8704, 152.3443],
  [-31.8714, 152.3456],
  [-31.8720, 152.3468],
  [-31.8720, 152.3481],
  [-31.8720, 152.3496],
  [-31.8724, 152.3511],
  [-31.8735, 152.3528],
  [-31.8739, 152.3544],
  [-31.8738, 152.3556],
  [-31.8725, 152.3608],
  [-31.8718, 152.3623],
  [-31.8693, 152.3647],
  [-31.8687, 152.3656],
  [-31.8667, 152.3705],
  [-31.8661, 152.3712],
  [-31.8653, 152.3721],
  [-31.8648, 152.3733],
  [-31.8645, 152.3798],
  [-31.8641, 152.3813],
  [-31.8623, 152.3842],
  [-31.8619, 152.3857],
  [-31.8621, 152.3872],
  [-31.8629, 152.3886],
  [-31.8632, 152.3897],
  [-31.8636, 152.3949],
  [-31.8641, 152.3963],
  [-31.8654, 152.3986],
  [-31.8684, 152.4013],
  [-31.8700, 152.4017],
  [-31.8730, 152.4012],
  [-31.8742, 152.4012],
  [-31.8810, 152.4041],
  [-31.8821, 152.4048],
  [-31.8856, 152.4079],
  [-31.8866, 152.4098],
  [-31.8884, 152.4200],
  [-31.8907, 152.4316],
  [-31.8907, 152.4326],
  [-31.8904, 152.4350],
  [-31.8905, 152.4365],
  [-31.8919, 152.4394],
  [-31.8922, 152.4409],
  [-31.8921, 152.4422],
  [-31.8918, 152.4433],
  [-31.8919, 152.4443],
  [-31.8924, 152.4470],
  [-31.8932, 152.4486],
  [-31.8950, 152.4498],
  [-31.8983, 152.4503],
  [-31.9009, 152.4511],
  [-31.9035, 152.4525],
  [-31.9048, 152.4538],
  [-31.9064, 152.4571]
];

const tareeWauchopeTunnel = [
  [-31.9064, 152.4571],
  [-31.9072, 152.4588],
  [-31.9073, 152.4604],
  [-31.9068, 152.4618],
  [-31.9045, 152.4651],
  [-31.9020, 152.4672],
  [-31.9006, 152.4678],
  [-31.8948, 152.4680],
  [-31.8935, 152.4684],
  [-31.8923, 152.4696],
  [-31.8911, 152.4718],
  [-31.8899, 152.4729],
  [-31.8881, 152.4739],
  [-31.8871, 152.4740],
  [-31.8850, 152.4738],
  [-31.8831, 152.4738],
  [-31.8813, 152.4744],
  [-31.8794, 152.4757],
  [-31.8736, 152.4822],
  [-31.8723, 152.4831],
  [-31.8654, 152.4857],
  [-31.8636, 152.4871],
  [-31.8605, 152.4916],
  [-31.8600, 152.4927],
  [-31.8593, 152.4968],
  [-31.8585, 152.4984],
  [-31.8573, 152.4991],
  [-31.8538, 152.4997],
  [-31.8525, 152.5006],
  [-31.8517, 152.5018],
  [-31.8513, 152.5034],
  [-31.8513, 152.5057],
  [-31.8509, 152.5069],
  [-31.8504, 152.5078],
  [-31.8459, 152.5116],
  [-31.8450, 152.5126],
  [-31.8440, 152.5146],
  [-31.8430, 152.5155],
  [-31.8419, 152.5158],
  [-31.8322, 152.5157],
  [-31.8260, 152.5140],
  [-31.8241, 152.5144],
  [-31.8232, 152.5156],
  [-31.8228, 152.5170],
  [-31.8226, 152.5185],
  [-31.8213, 152.5203],
  [-31.8197, 152.5208],
  [-31.8069, 152.5179],
  [-31.8045, 152.5179],
  [-31.7943, 152.5217],
  [-31.7932, 152.5229],
  [-31.7906, 152.5269],
  [-31.7891, 152.5282],
  [-31.7869, 152.5297],
  [-31.7862, 152.5306],
  [-31.7858, 152.5319],
  [-31.7861, 152.5406],
  [-31.7858, 152.5419],
  [-31.7836, 152.5467],
  [-31.7833, 152.5484],
  [-31.7842, 152.5542],
  [-31.7853, 152.5568],
  [-31.7908, 152.5638],
  [-31.7920, 152.5645],
  [-31.7939, 152.5648],
  [-31.7954, 152.5658],
  [-31.7960, 152.5680],
  [-31.7951, 152.5708],
  [-31.7948, 152.5726],
  [-31.7960, 152.5815],
  [-31.7967, 152.5830],
  [-31.7995, 152.5866],
  [-31.8032, 152.5939],
  [-31.8042, 152.5949],
  [-31.8059, 152.5957],
  [-31.8071, 152.5969],
  [-31.8075, 152.5983],
  [-31.8074, 152.6038],
  [-31.8078, 152.6052],
  [-31.8083, 152.6069],
  [-31.8081, 152.6082],
  [-31.8073, 152.6099],
  [-31.8069, 152.6115],
  [-31.8072, 152.6130],
  [-31.8083, 152.6149],
  [-31.8085, 152.6172],
  [-31.8073, 152.6205],
  [-31.8072, 152.6225],
  [-31.8088, 152.6257],
  [-31.8097, 152.6289],
  [-31.8091, 152.6312],
  [-31.8046, 152.6348],
  [-31.8033, 152.6366],
  [-31.8015, 152.6454],
  [-31.8007, 152.6469],
  [-31.7952, 152.6529],
  [-31.7879, 152.6563],
  [-31.7757, 152.6690],
  [-31.7746, 152.6696],
  [-31.7663, 152.6726],
  [-31.7651, 152.6738],
  [-31.7647, 152.6750],
  [-31.7643, 152.6785],
  [-31.7637, 152.6806],
  [-31.7622, 152.6836],
  [-31.7600, 152.6854],
  [-31.7541, 152.6868],
  [-31.7525, 152.6876],
  [-31.7415, 152.6951],
  [-31.7322, 152.6996],
  [-31.7247, 152.7013],
  [-31.7234, 152.7019],
  [-31.7203, 152.7043],
  [-31.7196, 152.7053],
  [-31.7178, 152.7084],
  [-31.7157, 152.7099],
  [-31.7147, 152.7114],
  [-31.7141, 152.7146],
  [-31.7133, 152.7159],
  [-31.7121, 152.7169],
  [-31.7114, 152.7180],
  [-31.7105, 152.7203],
  [-31.7095, 152.7215],
  [-31.7032, 152.7254],
  [-31.7005, 152.7284],
  [-31.6995, 152.7301],
  [-31.6958, 152.7344],
  [-31.6946, 152.7350],
  [-31.6936, 152.7349],
  [-31.6914, 152.7342],
  [-31.6898, 152.7345],
  [-31.6889, 152.7355],
  [-31.6881, 152.7365],
  [-31.6872, 152.7373],
  [-31.6758, 152.7418],
  [-31.6744, 152.7417],
  [-31.6716, 152.7410],
  [-31.6694, 152.7409],
  [-31.6678, 152.7400],
  [-31.6635, 152.7351],
  [-31.6630, 152.7335],
  [-31.6632, 152.7322],
  [-31.6638, 152.7307],
  [-31.6638, 152.7286],
  [-31.6613, 152.7197],
  [-31.6611, 152.7159],
  [-31.6599, 152.7114],
  [-31.6589, 152.7096],
  [-31.6549, 152.7051],
  [-31.6534, 152.7048],
  [-31.6524, 152.7050],
  [-31.6509, 152.7061],
  [-31.6495, 152.7062],
  [-31.6476, 152.7055],
  [-31.6458, 152.7060],
  [-31.6443, 152.7072],
  [-31.6432, 152.7074],
  [-31.6422, 152.7071],
  [-31.6390, 152.7053],
  [-31.6376, 152.7054],
  [-31.6311, 152.7080],
  [-31.6297, 152.7094],
  [-31.6285, 152.7121],
  [-31.6204, 152.7206],
  [-31.6171, 152.7261],
  [-31.6156, 152.7271],
  [-31.6143, 152.7270],
  [-31.6080, 152.7234],
  [-31.5972, 152.7226],
  [-31.5956, 152.7233],
  [-31.5944, 152.7246],
  [-31.5932, 152.7259],
  [-31.5921, 152.7264],
  [-31.5815, 152.7293],
  [-31.5799, 152.7293],
  [-31.5725, 152.7276],
  [-31.5704, 152.7279],
  [-31.5688, 152.7289],
  [-31.5667, 152.7308],
  [-31.5653, 152.7311],
  [-31.5641, 152.7306],
  [-31.5622, 152.7287],
  [-31.5606, 152.7281],
  [-31.5597, 152.7284],
  [-31.5580, 152.7295],
  [-31.5567, 152.7297],
  [-31.5552, 152.7294],
  [-31.5540, 152.7295],
  [-31.5528, 152.7300],
  [-31.5511, 152.7299],
  [-31.5482, 152.7282],
  [-31.5473, 152.7280],
  [-31.5458, 152.7280],
  [-31.5449, 152.7276],
  [-31.5428, 152.7260],
  [-31.5411, 152.7256],
  [-31.5400, 152.7261],
  [-31.5384, 152.7272],
  [-31.5361, 152.7278],
  [-31.5351, 152.7286],
  [-31.5345, 152.7297],
  [-31.5342, 152.7310],
  [-31.5336, 152.7320],
  [-31.5318, 152.7337],
  [-31.5306, 152.7341],
  [-31.5282, 152.7339],
  [-31.5273, 152.7342],
  [-31.5258, 152.7352],
  [-31.5246, 152.7354],
  [-31.5226, 152.7345],
  [-31.5197, 152.7317]
];

const wauchopeTunnel = [
  [-31.5197, 152.7317],
  [-31.5191, 152.7313]
];

const wauchopeTunnelWauchope = [
  [-31.5191, 152.7313],
  [-31.5180, 152.7311],
  [-31.5164, 152.7313],
  [-31.5152, 152.7310],
  [-31.5133, 152.7297],
  [-31.5117, 152.7297],
  [-31.5106, 152.7305],
  [-31.5096, 152.7320],
  [-31.5032, 152.7346],
  [-31.4794, 152.7364],
  [-31.4775, 152.7362],
  [-31.4733, 152.7352],
  [-31.4653, 152.7359],
  [-31.4617, 152.7354],
  [-31.4560, 152.7364]
];

const wauchopeKempsey = [
  [-31.4560, 152.7364],
  [-31.4381, 152.7400],
  [-31.4310, 152.7434],
  [-31.4291, 152.7438],
  [-31.4125, 152.7437],
  [-31.4112, 152.7442],
  [-31.4059, 152.7476],
  [-31.4049, 152.7477],
  [-31.4041, 152.7474],
  [-31.4020, 152.7465],
  [-31.3969, 152.7459],
  [-31.3955, 152.7463],
  [-31.3873, 152.7526],
  [-31.3857, 152.7534],
  [-31.3833, 152.7543],
  [-31.3818, 152.7552],
  [-31.3798, 152.7569],
  [-31.3726, 152.7616],
  [-31.3706, 152.7621],
  [-31.3663, 152.7619],
  [-31.3646, 152.7623],
  [-31.3630, 152.7635],
  [-31.3560, 152.7714],
  [-31.3536, 152.7734],
  [-31.3524, 152.7738],
  [-31.3501, 152.7740],
  [-31.3488, 152.7746],
  [-31.3454, 152.7781],
  [-31.3441, 152.7787],
  [-31.3429, 152.7785],
  [-31.3415, 152.7778],
  [-31.3403, 152.7776],
  [-31.3347, 152.7782],
  [-31.3333, 152.7788],
  [-31.3273, 152.7842],
  [-31.3262, 152.7858],
  [-31.3258, 152.7873],
  [-31.3258, 152.7889],
  [-31.3255, 152.7898],
  [-31.3250, 152.7912],
  [-31.3247, 152.7928],
  [-31.3246, 152.7952],
  [-31.3234, 152.7994],
  [-31.3229, 152.8006],
  [-31.3177, 152.8081],
  [-31.3169, 152.8098],
  [-31.3139, 152.8207],
  [-31.3078, 152.8314],
  [-31.3069, 152.8334],
  [-31.3047, 152.8346],
  [-31.3008, 152.8353],
  [-31.2992, 152.8353],
  [-31.2864, 152.8339],
  [-31.2841, 152.8343],
  [-31.2790, 152.8367],
  [-31.2762, 152.8369],
  [-31.2718, 152.8358],
  [-31.2697, 152.8359],
  [-31.2586, 152.8389],
  [-31.2564, 152.8388],
  [-31.2537, 152.8382],
  [-31.2504, 152.8384],
  [-31.2434, 152.8363],
  [-31.2390, 152.8339],
  [-31.2374, 152.8335],
  [-31.2310, 152.8336],
  [-31.2284, 152.8333],
  [-31.2224, 152.8318],
  [-31.2192, 152.8322],
  [-31.2153, 152.8344],
  [-31.2135, 152.8348],
  [-31.2067, 152.8359],
  [-31.2012, 152.8374],
  [-31.1982, 152.8370],
  [-31.1971, 152.8365],
  [-31.1958, 152.8356],
  [-31.1947, 152.8353],
  [-31.1937, 152.8356],
  [-31.1907, 152.8378],
  [-31.1887, 152.8383],
  [-31.1862, 152.8379],
  [-31.1850, 152.8380],
  [-31.1783, 152.8410],
  [-31.1773, 152.8419],
  [-31.1769, 152.8430],
  [-31.1759, 152.8482],
  [-31.1753, 152.8494],
  [-31.1742, 152.8501],
  [-31.1702, 152.8511],
  [-31.1662, 152.8509],
  [-31.1590, 152.8513],
  [-31.1575, 152.8519],
  [-31.1529, 152.8566],
  [-31.1518, 152.8570],
  [-31.1508, 152.8569],
  [-31.1470, 152.8551],
  [-31.1459, 152.8540],
  [-31.1445, 152.8518],
  [-31.1434, 152.8507],
  [-31.1403, 152.8497],
  [-31.1386, 152.8497],
  [-31.1369, 152.8503],
  [-31.1356, 152.8502],
  [-31.1322, 152.8492],
  [-31.1251, 152.8481],
  [-31.1236, 152.8469],
  [-31.1229, 152.8460],
  [-31.1219, 152.8452],
  [-31.1203, 152.8447],
  [-31.1196, 152.8441],
  [-31.1168, 152.8408],
  [-31.1144, 152.8386],
  [-31.1135, 152.8383],
  [-31.1125, 152.8383],
  [-31.1102, 152.8391],
  [-31.1092, 152.8390],
  [-31.1025, 152.8379],
  [-31.1014, 152.8374],
  [-31.0954, 152.8327],
  [-31.0939, 152.8321],
  [-31.0848, 152.8311],
  [-31.0832, 152.8312],
  [-31.0794, 152.8321],
  [-31.0768, 152.8330]
];

const kempseyMacksville = [
  [-31.0768, 152.8330],
  [-31.0715, 152.8345],
  [-31.0705, 152.8346],
  [-31.0692, 152.8343],
  [-31.0683, 152.8343],
  [-31.0658, 152.8349],
  [-31.0650, 152.8354],
  [-31.0621, 152.8378],
  [-31.0607, 152.8383],
  [-31.0592, 152.8381],
  [-31.0575, 152.8372],
  [-31.0559, 152.8369],
  [-31.0468, 152.8388],
  [-31.0457, 152.8392],
  [-31.0451, 152.8397],
  [-31.0427, 152.8426],
  [-31.0418, 152.8433],
  [-31.0408, 152.8437],
  [-31.0350, 152.8447],
  [-31.0340, 152.8446],
  [-31.0261, 152.8419],
  [-31.0248, 152.8419],
  [-31.0203, 152.8431],
  [-31.0189, 152.8429],
  [-31.0183, 152.8427],
  [-31.0157, 152.8411],
  [-31.0032, 152.8330],
  [-30.9904, 152.8246],
  [-30.9890, 152.8242],
  [-30.9871, 152.8241],
  [-30.9798, 152.8261],
  [-30.9783, 152.8262],
  [-30.9749, 152.8257],
  [-30.9732, 152.8258],
  [-30.9711, 152.8267],
  [-30.9659, 152.8310],
  [-30.9647, 152.8329],
  [-30.9639, 152.8350],
  [-30.9630, 152.8361],
  [-30.9600, 152.8382],
  [-30.9592, 152.8385],
  [-30.9578, 152.8388],
  [-30.9567, 152.8395],
  [-30.9559, 152.8405],
  [-30.9547, 152.8428],
  [-30.9539, 152.8437],
  [-30.9523, 152.8445],
  [-30.9515, 152.8454],
  [-30.9511, 152.8466],
  [-30.9506, 152.8498],
  [-30.9507, 152.8510],
  [-30.9509, 152.8524],
  [-30.9507, 152.8536],
  [-30.9502, 152.8545],
  [-30.9446, 152.8610],
  [-30.9442, 152.8617],
  [-30.9432, 152.8638],
  [-30.9425, 152.8645],
  [-30.9414, 152.8649],
  [-30.9403, 152.8649],
  [-30.9389, 152.8652],
  [-30.9355, 152.8673],
  [-30.9346, 152.8682],
  [-30.9343, 152.8688],
  [-30.9339, 152.8698],
  [-30.9332, 152.8707],
  [-30.9294, 152.8739],
  [-30.9129, 152.8824],
  [-30.9092, 152.8851],
  [-30.9082, 152.8863],
  [-30.9078, 152.8874],
  [-30.9071, 152.8904],
  [-30.9064, 152.8916],
  [-30.9052, 152.8924],
  [-30.9038, 152.8924],
  [-30.9027, 152.8917],
  [-30.9021, 152.8908],
  [-30.9004, 152.8857],
  [-30.8996, 152.8845],
  [-30.8984, 152.8839],
  [-30.8973, 152.8840],
  [-30.8964, 152.8845],
  [-30.8931, 152.8876],
  [-30.8919, 152.8881],
  [-30.8907, 152.8881],
  [-30.8893, 152.8877],
  [-30.8882, 152.8878],
  [-30.8870, 152.8881],
  [-30.8852, 152.8882],
  [-30.8844, 152.8885],
  [-30.8837, 152.8892],
  [-30.8832, 152.8902],
  [-30.8830, 152.8912],
  [-30.8829, 152.8928],
  [-30.8825, 152.8941],
  [-30.8816, 152.8952],
  [-30.8805, 152.8956],
  [-30.8794, 152.8954],
  [-30.8744, 152.8922],
  [-30.8698, 152.8905],
  [-30.8683, 152.8905],
  [-30.8667, 152.8915],
  [-30.8656, 152.8932],
  [-30.8645, 152.8978],
  [-30.8635, 152.8990],
  [-30.8620, 152.8996],
  [-30.8605, 152.8991],
  [-30.8597, 152.8982],
  [-30.8588, 152.8969],
  [-30.8576, 152.8962],
  [-30.8562, 152.8965],
  [-30.8549, 152.8975],
  [-30.8538, 152.8994],
  [-30.8527, 152.9000],
  [-30.8515, 152.9002],
  [-30.8470, 152.8999],
  [-30.8458, 152.9002],
  [-30.8411, 152.9036],
  [-30.8402, 152.9038],
  [-30.8360, 152.9037],
  [-30.8349, 152.9034],
  [-30.8341, 152.9029],
  [-30.8278, 152.8973],
  [-30.8187, 152.8872],
  [-30.8177, 152.8866],
  [-30.8169, 152.8865],
  [-30.8074, 152.8877],
  [-30.8061, 152.8874],
  [-30.8043, 152.8859],
  [-30.8028, 152.8851],
  [-30.8012, 152.8852],
  [-30.7997, 152.8861],
  [-30.7981, 152.8888],
  [-30.7970, 152.8897],
  [-30.7915, 152.8907],
  [-30.7904, 152.8905],
  [-30.7899, 152.8900],
  [-30.7887, 152.8888],
  [-30.7877, 152.8882],
  [-30.7864, 152.8880],
  [-30.7813, 152.8906],
  [-30.7790, 152.8907],
  [-30.7759, 152.8900],
  [-30.7741, 152.8902],
  [-30.7727, 152.8910],
  [-30.7622, 152.8997],
  [-30.7613, 152.9006],
  [-30.7604, 152.9017],
  [-30.7595, 152.9022],
  [-30.7565, 152.9026],
  [-30.7555, 152.9031],
  [-30.7547, 152.9043],
  [-30.7539, 152.9058],
  [-30.7530, 152.9069],
  [-30.7520, 152.9072],
  [-30.7506, 152.9071],
  [-30.7471, 152.9061],
  [-30.7447, 152.9048],
  [-30.7439, 152.9042],
  [-30.7428, 152.9039],
  [-30.7411, 152.9040],
  [-30.7404, 152.9039],
  [-30.7392, 152.9035],
  [-30.7384, 152.9035],
  [-30.7373, 152.9040],
  [-30.7367, 152.9047],
  [-30.7363, 152.9056],
  [-30.7359, 152.9077],
  [-30.7354, 152.9087],
  [-30.7324, 152.9124],
  [-30.7313, 152.9131],
  [-30.7280, 152.9139],
  [-30.7273, 152.9139],
  [-30.7266, 152.9136],
  [-30.7254, 152.9129],
  [-30.7245, 152.9126],
  [-30.7237, 152.9127],
  [-30.7192, 152.9139],
  [-30.7180, 152.9138],
  [-30.7166, 152.9132],
  [-30.7147, 152.9117],
  [-30.7135, 152.9111],
  [-30.7128, 152.9110],
  [-30.7120, 152.9113],
  [-30.7099, 152.9126],
  [-30.7094, 152.9130]
];

const macksvilleUrunga = [
  [-30.7094, 152.9130],
  [-30.7074, 152.9145],
  [-30.7064, 152.9150],
  [-30.7055, 152.9150],
  [-30.7048, 152.9147],
  [-30.7036, 152.9141],
  [-30.7027, 152.9139],
  [-30.7018, 152.9141],
  [-30.7010, 152.9145],
  [-30.6980, 152.9170],
  [-30.6969, 152.9175],
  [-30.6959, 152.9175],
  [-30.6939, 152.9172],
  [-30.6925, 152.9174],
  [-30.6879, 152.9200],
  [-30.6873, 152.9202],
  [-30.6859, 152.9204],
  [-30.6849, 152.9209],
  [-30.6838, 152.9218],
  [-30.6817, 152.9245],
  [-30.6809, 152.9251],
  [-30.6797, 152.9253],
  [-30.6781, 152.9249],
  [-30.6775, 152.9248],
  [-30.6770, 152.9250],
  [-30.6724, 152.9268],
  [-30.6717, 152.9268],
  [-30.6704, 152.9266],
  [-30.6695, 152.9267],
  [-30.6646, 152.9287],
  [-30.6631, 152.9300],
  [-30.6622, 152.9304],
  [-30.6606, 152.9308],
  [-30.6595, 152.9314],
  [-30.6587, 152.9326],
  [-30.6585, 152.9339],
  [-30.6599, 152.9385],
  [-30.6603, 152.9441],
  [-30.6586, 152.9506],
  [-30.6578, 152.9517],
  [-30.6569, 152.9521],
  [-30.6541, 152.9524],
  [-30.6523, 152.9523],
  [-30.6513, 152.9525],
  [-30.6493, 152.9539],
  [-30.6481, 152.9544],
  [-30.6475, 152.9550],
  [-30.6457, 152.9582],
  [-30.6441, 152.9596],
  [-30.6416, 152.9627],
  [-30.6407, 152.9644],
  [-30.6402, 152.9649],
  [-30.6381, 152.9662],
  [-30.6369, 152.9663],
  [-30.6357, 152.9661],
  [-30.6347, 152.9662],
  [-30.6336, 152.9669],
  [-30.6329, 152.9681],
  [-30.6328, 152.9694],
  [-30.6330, 152.9710],
  [-30.6327, 152.9725],
  [-30.6314, 152.9752],
  [-30.6308, 152.9759],
  [-30.6280, 152.9782],
  [-30.6261, 152.9786],
  [-30.6245, 152.9783],
  [-30.6225, 152.9771],
  [-30.6214, 152.9768],
  [-30.6201, 152.9771],
  [-30.6191, 152.9779],
  [-30.6151, 152.9821],
  [-30.6136, 152.9828],
  [-30.6110, 152.9831],
  [-30.6091, 152.9837],
  [-30.6059, 152.9857],
  [-30.6019, 152.9893],
  [-30.6007, 152.9898],
  [-30.5992, 152.9899],
  [-30.5982, 152.9904],
  [-30.5974, 152.9910],
  [-30.5960, 152.9920],
  [-30.5949, 152.9938],
  [-30.5942, 152.9948],
  [-30.5915, 153.0000],
  [-30.5903, 153.0011],
  [-30.5889, 153.0016],
  [-30.5868, 153.0018],
  [-30.5857, 153.0022],
  [-30.5817, 153.0052],
  [-30.5802, 153.0068],
  [-30.5762, 153.0095],
  [-30.5736, 153.0102],
  [-30.5681, 153.0127],
  [-30.5651, 153.0136],
  [-30.5640, 153.0142],
  [-30.5618, 153.0157],
  [-30.5606, 153.0162],
  [-30.5590, 153.0163],
  [-30.5580, 153.0166],
  [-30.5499, 153.0208],
  [-30.5439, 153.0223],
  [-30.5273, 153.0239],
  [-30.5242, 153.0233],
  [-30.5182, 153.0201],
  [-30.5122, 153.0183],
  [-30.5109, 153.0183],
  [-30.5065, 153.0191],
  [-30.5012, 153.0193],
  [-30.5004, 153.0191],
  [-30.4990, 153.0186]
];

const urungaRaleigh = [
  [-30.4990, 153.0186],
  [-30.4944, 153.0166],
  [-30.4930, 153.0155],
  [-30.4900, 153.0117],
  [-30.4888, 153.0107],
  [-30.4831, 153.0082],
  [-30.4819, 153.0080],
  [-30.4683, 153.0106],
  [-30.4669, 153.0113],
  [-30.4583, 153.0167],
  [-30.4567, 153.0172],
  [-30.4543, 153.0177]
];

const raleighReptonTunnel = [
  [-30.4543, 153.0177],
  [-30.4495, 153.0187],
  [-30.4475, 153.0198],
  [-30.4449, 153.0222],
  [-30.4436, 153.0230],
  [-30.4403, 153.0246],
  [-30.4394, 153.0252],
  [-30.4379, 153.0265],
  [-30.4376, 153.0269]
];

const reptonTunnel = [
  [-30.4376, 153.0269],
  [-30.4366, 153.0285]
];

const reptonTunnelCoffsHarbour = [
  [-30.4366, 153.0285],
  [-30.4361, 153.0290],
  [-30.4341, 153.0300],
  [-30.4334, 153.0301],
  [-30.4325, 153.0303],
  [-30.4311, 153.0312],
  [-30.4306, 153.0317],
  [-30.4298, 153.0326],
  [-30.4290, 153.0332],
  [-30.4279, 153.0334],
  [-30.4264, 153.0332],
  [-30.4256, 153.0333],
  [-30.4233, 153.0339],
  [-30.4224, 153.0339],
  [-30.4213, 153.0335],
  [-30.4199, 153.0336],
  [-30.4188, 153.0345],
  [-30.4162, 153.0383],
  [-30.4150, 153.0405],
  [-30.4139, 153.0415],
  [-30.4126, 153.0419],
  [-30.4116, 153.0418],
  [-30.4103, 153.0415],
  [-30.4091, 153.0416],
  [-30.4072, 153.0425],
  [-30.4064, 153.0425],
  [-30.4057, 153.0424],
  [-30.4046, 153.0419],
  [-30.4038, 153.0418],
  [-30.4028, 153.0420],
  [-30.4020, 153.0426],
  [-30.4015, 153.0432],
  [-30.4009, 153.0441],
  [-30.4002, 153.0448],
  [-30.3994, 153.0451],
  [-30.3979, 153.0454],
  [-30.3933, 153.0471],
  [-30.3922, 153.0476],
  [-30.3844, 153.0536],
  [-30.3829, 153.0554],
  [-30.3738, 153.0714],
  [-30.3728, 153.0753],
  [-30.3730, 153.0810],
  [-30.3726, 153.0830],
  [-30.3719, 153.0844],
  [-30.3702, 153.0863],
  [-30.3623, 153.0950],
  [-30.3602, 153.0968],
  [-30.3554, 153.1003],
  [-30.3545, 153.1013],
  [-30.3525, 153.1041],
  [-30.3513, 153.1053],
  [-30.3504, 153.1057],
  [-30.3451, 153.1073],
  [-30.3416, 153.1088],
  [-30.3379, 153.1114],
  [-30.3191, 153.1278],
  [-30.3175, 153.1297],
  [-30.3119, 153.1366],
  [-30.3110, 153.1373],
  [-30.3101, 153.1375],
  [-30.3072, 153.1377],
  [-30.3061, 153.1378]
];

const coffsHarbourRedHill1 = [
  [-30.3061, 153.1378],
  [-30.3045, 153.1382],
  [-30.3026, 153.1388],
  [-30.3013, 153.1389],
  [-30.3000, 153.1387],
  [-30.2984, 153.1379],
  [-30.2946, 153.1349],
  [-30.2933, 153.1338],
  [-30.2859, 153.1291],
  [-30.2831, 153.1278],
  [-30.2816, 153.1271],
  [-30.2803, 153.1262],
  [-30.2795, 153.1253],
  [-30.2789, 153.1246],
  [-30.2786, 153.1236],
  [-30.2782, 153.1206],
  [-30.2783, 153.1195],
  [-30.2789, 153.1177],
  [-30.2790, 153.1166],
  [-30.2788, 153.1154],
  [-30.2746, 153.1078],
  [-30.2743, 153.1068],
  [-30.2743, 153.1057],
  [-30.2757, 153.0991],
  [-30.2758, 153.0979],
  [-30.2755, 153.0965],
  [-30.2750, 153.0953],
  [-30.2748, 153.0939],
  [-30.2751, 153.0921],
  [-30.2750, 153.0911],
  [-30.2745, 153.0898],
  [-30.2733, 153.0886],
  [-30.2727, 153.0876],
  [-30.2724, 153.0863],
  [-30.2726, 153.0853]
];

const redHillNo1Tunnel = [
  [-30.2726, 153.0853],
  [-30.2730, 153.0844]
];

const redHill1RedHill2 = [
  [-30.2730, 153.0844],
  [-30.2740, 153.0825],
  [-30.2746, 153.0810],
  [-30.2756, 153.0763],
  [-30.2760, 153.0751],
  [-30.2783, 153.0706]
];

const redHillNo2Tunnel = [
  [-30.2783, 153.0706],
  [-30.2793, 153.0688]
];

const redHill2RedHill3 = [
  [-30.2793, 153.0688],
  [-30.2800, 153.0681],
  [-30.2808, 153.0678],
  [-30.2821, 153.0677],
  [-30.2827, 153.0676]
];

const redHillNo3Tunnel = [
  [-30.2827, 153.0676],
  [-30.2832, 153.0674],
  [-30.2839, 153.0667],
  [-30.2844, 153.0659]
];

const redHill3RedHill4 = [
  [-30.2844, 153.0659],
  [-30.2849, 153.0652],
  [-30.2862, 153.0642]
];

const redHillNo4Tunnel = [
  [-30.2862, 153.0642],
  [-30.2872, 153.0634]
];

const redHill4RedHill5 = [
  [-30.2872, 153.0634],
  [-30.2877, 153.0622],
  [-30.2877, 153.0606],

  [-30.2875, 153.0598]
];

const redHillNo5Tunnel = [
  [-30.2875, 153.0598],
  [-30.2856, 153.0577]
];

const redHill5Glenreagh = [
  [-30.2856, 153.0577],
  [-30.2851, 153.0571],
  [-30.2840, 153.0549],
  [-30.2825, 153.0524],
  [-30.2814, 153.0512],
  [-30.2808, 153.0502],
  [-30.2803, 153.0486],
  [-30.2795, 153.0475],
  [-30.2786, 153.0469],
  [-30.2752, 153.0461],
  [-30.2739, 153.0461],
  [-30.2726, 153.0465],
  [-30.2709, 153.0472],
  [-30.2701, 153.0473],
  [-30.2692, 153.0472],
  [-30.2649, 153.0460],
  [-30.2639, 153.0460],
  [-30.2630, 153.0464],
  [-30.2618, 153.0474],
  [-30.2607, 153.0477],
  [-30.2596, 153.0475],
  [-30.2587, 153.0468],
  [-30.2569, 153.0447],
  [-30.2565, 153.0438],
  [-30.2561, 153.0423],
  [-30.2554, 153.0409],
  [-30.2542, 153.0401],
  [-30.2529, 153.0401],
  [-30.2470, 153.0415],
  [-30.2457, 153.0414],
  [-30.2446, 153.0405],
  [-30.2441, 153.0395],
  [-30.2439, 153.0381],
  [-30.2445, 153.0349],
  [-30.2443, 153.0336],
  [-30.2436, 153.0323],
  [-30.2426, 153.0312],
  [-30.2421, 153.0301],
  [-30.2418, 153.0288],
  [-30.2412, 153.0276],
  [-30.2403, 153.0268],
  [-30.2389, 153.0265],
  [-30.2373, 153.0269],
  [-30.2360, 153.0267],
  [-30.2350, 153.0260],
  [-30.2344, 153.0249],
  [-30.2338, 153.0226],
  [-30.2330, 153.0213],
  [-30.2317, 153.0204],
  [-30.2231, 153.0188],
  [-30.2223, 153.0185],
  [-30.2217, 153.0179],
  [-30.2210, 153.0170],
  [-30.2202, 153.0164],
  [-30.2194, 153.0161],
  [-30.2176, 153.0157],
  [-30.2165, 153.0158],
  [-30.2157, 153.0162],
  [-30.2150, 153.0170],
  [-30.2142, 153.0181],
  [-30.2134, 153.0189],
  [-30.2114, 153.0197],
  [-30.2104, 153.0198],
  [-30.2094, 153.0195],
  [-30.2081, 153.0186],
  [-30.2069, 153.0184],
  [-30.2059, 153.0186],
  [-30.2049, 153.0195],
  [-30.2043, 153.0208],
  [-30.2040, 153.0222],
  [-30.2034, 153.0237],
  [-30.2022, 153.0251],
  [-30.1990, 153.0276],
  [-30.1980, 153.0281],
  [-30.1971, 153.0283],
  [-30.1943, 153.0282],
  [-30.1932, 153.0279],
  [-30.1924, 153.0273],
  [-30.1903, 153.0255],
  [-30.1894, 153.0241],
  [-30.1890, 153.0230],
  [-30.1888, 153.0213],
  [-30.1884, 153.0201],
  [-30.1861, 153.0176],
  [-30.1848, 153.0171],
  [-30.1805, 153.0172],
  [-30.1791, 153.0174],
  [-30.1775, 153.0180],
  [-30.1764, 153.0180],
  [-30.1756, 153.0176],
  [-30.1748, 153.0168],
  [-30.1740, 153.0156],
  [-30.1720, 153.0137],
  [-30.1701, 153.0128],
  [-30.1682, 153.0127],
  [-30.1658, 153.0130],
  [-30.1649, 153.0134],
  [-30.1643, 153.0139],
  [-30.1631, 153.0152],
  [-30.1623, 153.0158],
  [-30.1613, 153.0160],
  [-30.1572, 153.0164],
  [-30.1559, 153.0168],
  [-30.1550, 153.0173],
  [-30.1542, 153.0176],
  [-30.1519, 153.0177],
  [-30.1510, 153.0175],
  [-30.1499, 153.0171],
  [-30.1491, 153.0170],
  [-30.1479, 153.0171],
  [-30.1472, 153.0169],
  [-30.1466, 153.0165],
  [-30.1456, 153.0158],
  [-30.1446, 153.0154],
  [-30.1437, 153.0155],
  [-30.1428, 153.0160],
  [-30.1401, 153.0180],
  [-30.1391, 153.0183],
  [-30.1382, 153.0182],
  [-30.1350, 153.0170],
  [-30.1341, 153.0170],
  [-30.1334, 153.0172],
  [-30.1294, 153.0194],
  [-30.1275, 153.0198],
  [-30.1251, 153.0195],
  [-30.1234, 153.0185],
  [-30.1212, 153.0167],
  [-30.1197, 153.0158],
  [-30.1170, 153.0151],
  [-30.1158, 153.0146],
  [-30.1145, 153.0138],
  [-30.1130, 153.0137],
  [-30.1114, 153.0141],
  [-30.1104, 153.0140],
  [-30.1095, 153.0136],
  [-30.1075, 153.0120],
  [-30.1064, 153.0116],
  [-30.1052, 153.0117],
  [-30.1041, 153.0125],
  [-30.1035, 153.0138],
  [-30.1033, 153.0149],
  [-30.1034, 153.0164],
  [-30.1029, 153.0179],
  [-30.1019, 153.0189],
  [-30.1004, 153.0198],
  [-30.0991, 153.0199],
  [-30.0982, 153.0194],
  [-30.0970, 153.0185],
  [-30.0960, 153.0180],
  [-30.0951, 153.0181],
  [-30.0925, 153.0189],
  [-30.0915, 153.0188],
  [-30.0904, 153.0185],
  [-30.0887, 153.0177],
  [-30.0877, 153.0175],
  [-30.0865, 153.0174],
  [-30.0853, 153.0167],
  [-30.0844, 153.0155],
  [-30.0839, 153.0138],
  [-30.0832, 153.0124],
  [-30.0762, 153.0062],
  [-30.0755, 153.0054],
  [-30.0747, 153.0040],
  [-30.0718, 153.0009],
  [-30.0699, 152.9996],
  [-30.0691, 152.9984],
  [-30.0688, 152.9970],
  [-30.0691, 152.9940],
  [-30.0688, 152.9923],
  [-30.0675, 152.9904],
  [-30.0657, 152.9896],
  [-30.0634, 152.9893],
  [-30.0624, 152.9888],
  [-30.0594, 152.9853],
  [-30.0583, 152.9834],
  [-30.0575, 152.9826],
  [-30.0562, 152.9822],
  [-30.0548, 152.9826],
  [-30.0496, 152.9867]
];

const glenreaghGraftonCity = [
  [-30.0496, 152.9867],
  [-30.0400, 152.9943],
  [-30.0335, 152.9977],
  [-30.0321, 152.9989],
  [-30.0313, 152.9993],
  [-30.0278, 153.0002],
  [-30.0268, 153.0001],
  [-30.0257, 152.9997],
  [-30.0208, 152.9964],
  [-30.0174, 152.9955],
  [-30.0144, 152.9940],
  [-30.0135, 152.9938],
  [-30.0113, 152.9938],
  [-30.0075, 152.9926],
  [-30.0051, 152.9924],
  [-30.0038, 152.9919],
  [-30.0015, 152.9902],
  [-29.9992, 152.9896],
  [-29.9972, 152.9900],
  [-29.9950, 152.9911],
  [-29.9939, 152.9913],
  [-29.9925, 152.9914],
  [-29.9907, 152.9916],
  [-29.9887, 152.9912],
  [-29.9871, 152.9917],
  [-29.9858, 152.9932],
  [-29.9850, 152.9950],
  [-29.9835, 152.9965],
  [-29.9817, 152.9969],
  [-29.9767, 152.9959],
  [-29.9752, 152.9962],
  [-29.9738, 152.9974],
  [-29.9729, 152.9991],
  [-29.9717, 153.0005],
  [-29.9704, 153.0015],
  [-29.9695, 153.0027],
  [-29.9688, 153.0043],
  [-29.9676, 153.0056],
  [-29.9663, 153.0061],
  [-29.9647, 153.0062],
  [-29.9635, 153.0065],
  [-29.9565, 153.0092],
  [-29.9546, 153.0095],
  [-29.9489, 153.0093],
  [-29.9470, 153.0086],
  [-29.9423, 153.0064],
  [-29.9383, 153.0052],
  [-29.9367, 153.0051],
  [-29.9343, 153.0056],
  [-29.9332, 153.0064],
  [-29.9314, 153.0088],
  [-29.9300, 153.0098],
  [-29.9285, 153.0100],
  [-29.9256, 153.0090],
  [-29.9245, 153.0077],
  [-29.9240, 153.0063],
  [-29.9236, 153.0047],
  [-29.9227, 153.0033],
  [-29.9211, 153.0024],
  [-29.9143, 153.0022],
  [-29.9047, 153.0038],
  [-29.8979, 153.0038],
  [-29.8915, 153.0053],
  [-29.8896, 153.0051],
  [-29.8880, 153.0041],
  [-29.8856, 153.0015],
  [-29.8841, 153.0008],
  [-29.8830, 153.0008],
  [-29.8790, 153.0015],
  [-29.8775, 153.0022],
  [-29.8739, 153.0049],
  [-29.8726, 153.0052],
  [-29.8709, 153.0049],
  [-29.8697, 153.0041],
  [-29.8667, 153.0009],
  [-29.8651, 152.9998],
  [-29.8578, 152.9964],
  [-29.8542, 152.9938],
  [-29.8502, 152.9923],
  [-29.8461, 152.9919],
  [-29.8296, 152.9880],
  [-29.8267, 152.9862],
  [-29.8251, 152.9841],
  [-29.8220, 152.9776],
  [-29.8209, 152.9764],
  [-29.8188, 152.9755],
  [-29.8172, 152.9756],
  [-29.8156, 152.9759],
  [-29.8140, 152.9755],
  [-29.8106, 152.9733],
  [-29.8097, 152.9722],
  [-29.8078, 152.9665],
  [-29.8068, 152.9651],
  [-29.8051, 152.9640],
  [-29.8040, 152.9627],
  [-29.8033, 152.9611],
  [-29.8005, 152.9580],
  [-29.7993, 152.9574],
  [-29.7962, 152.9570],
  [-29.7949, 152.9565],
  [-29.7937, 152.9555],
  [-29.7901, 152.9509],
  [-29.7882, 152.9493],
  [-29.7857, 152.9484],
  [-29.7832, 152.9468],
  [-29.7806, 152.9461],
  [-29.7794, 152.9451],
  [-29.7781, 152.9429],
  [-29.7764, 152.9416],
  [-29.7714, 152.9406],
  [-29.7701, 152.9398],
  [-29.7650, 152.9342],
  [-29.7637, 152.9336],
  [-29.7624, 152.9338],
  [-29.7612, 152.9349],
  [-29.7602, 152.9369],
  [-29.7590, 152.9381],
  [-29.7564, 152.9389],
  [-29.7551, 152.9387],
  [-29.7535, 152.9379],
  [-29.7523, 152.9378],
  [-29.7502, 152.9386],
  [-29.7479, 152.9389],
  [-29.7435, 152.9415],
  [-29.7424, 152.9417],
  [-29.7410, 152.9417],
  [-29.7404, 152.9417],
  [-29.7389, 152.9424],
  [-29.7381, 152.9425],
  [-29.7367, 152.9423],
  [-29.7359, 152.9425],
  [-29.7341, 152.9433],
  [-29.7333, 152.9433],
  [-29.7320, 152.9431],
  [-29.7311, 152.9425],
  [-29.7300, 152.9415],
  [-29.7288, 152.9413],
  [-29.7277, 152.9416],
  [-29.7264, 152.9425],
  [-29.7252, 152.9428],
  [-29.7236, 152.9427],
  [-29.7225, 152.9429],
  [-29.7210, 152.9441],
  [-29.7199, 152.9444],
  [-29.7192, 152.9444],
  [-29.7175, 152.9436],
  [-29.7163, 152.9435],
  [-29.7147, 152.9441],
  [-29.7132, 152.9443],
  [-29.7113, 152.9441],
  [-29.7105, 152.9437],
  [-29.7099, 152.9431],
  [-29.7090, 152.9419],
  [-29.7083, 152.9412],
  [-29.7073, 152.9408],
  [-29.7064, 152.9408],
  [-29.7055, 152.9409],
  [-29.7039, 152.9417]
];

const graftonCityGrafton = [
  [-29.7039, 152.9417],
  [-29.7019, 152.9425],
  [-29.6999, 152.9426],
  [-29.6933, 152.9411],
  [-29.6921, 152.9405],
  [-29.6914, 152.9397],
  [-29.6852, 152.9299],
  [-29.6850, 152.9288],
  [-29.6848, 152.9270],
  [-29.6845, 152.9262],
  [-29.6836, 152.9246]
];

const graftonSheepCreek = [
  [-29.6836, 152.9246],
  [-29.6817, 152.9222],
  [-29.6713, 152.9175],
  [-29.6698, 152.9171],
  [-29.6624, 152.9162],
  [-29.6581, 152.9154],
  [-29.6561, 152.9154],
  [-29.6453, 152.9177],
  [-29.6349, 152.9208],
  [-29.6340, 152.9213],
  [-29.6313, 152.9238],
  [-29.6294, 152.9249],
  [-29.6258, 152.9253],
  [-29.6245, 152.9261],
  [-29.6188, 152.9394],
  [-29.6172, 152.9411],
  [-29.6151, 152.9417],
  [-29.6107, 152.9415],
  [-29.6091, 152.9421],
  [-29.6074, 152.9440],
  [-29.6060, 152.9450],
  [-29.5995, 152.9474],
  [-29.5967, 152.9476],
  [-29.5955, 152.9481],
  [-29.5921, 152.9508],
  [-29.5912, 152.9520],
  [-29.5896, 152.9564],
  [-29.5894, 152.9577],
  [-29.5896, 152.9608],
  [-29.5890, 152.9631],
  [-29.5836, 152.9716],
  [-29.5825, 152.9725],
  [-29.5803, 152.9735],
  [-29.5789, 152.9752],
  [-29.5772, 152.9801],
  [-29.5757, 152.9817],
  [-29.5737, 152.9826],
  [-29.5729, 152.9831],
  [-29.5692, 152.9871],
  [-29.5680, 152.9878],
  [-29.5654, 152.9882],
  [-29.5627, 152.9900],
  [-29.5607, 152.9903],
  [-29.5594, 152.9898],
  [-29.5573, 152.9880],
  [-29.5557, 152.9873],
  [-29.5531, 152.9873],
  [-29.5476, 152.9894],
  [-29.5460, 152.9895],
  [-29.5440, 152.9890],
  [-29.5425, 152.9893],
  [-29.5389, 152.9923],
  [-29.5378, 152.9927],
  [-29.5368, 152.9926],
  [-29.5332, 152.9910],
  [-29.5313, 152.9892],
  [-29.5301, 152.9888],
  [-29.5275, 152.9889],
  [-29.5260, 152.9884],
  [-29.5245, 152.9873],
  [-29.5232, 152.9868],
  [-29.5201, 152.9865],
  [-29.5167, 152.9850],
  [-29.5112, 152.9845],
  [-29.5094, 152.9848],
  [-29.5081, 152.9846],
  [-29.5054, 152.9833],
  [-29.5014, 152.9827],
  [-29.4983, 152.9818],
  [-29.4968, 152.9819],
  [-29.4959, 152.9828],
  [-29.4949, 152.9846],
  [-29.4938, 152.9857],
  [-29.4893, 152.9885],
  [-29.4842, 152.9899],
  [-29.4828, 152.9909],
  [-29.4807, 152.9930],
  [-29.4775, 152.9948],
  [-29.4751, 152.9953],
  [-29.4683, 152.9949],
  [-29.4632, 152.9955],
  [-29.4597, 152.9972],
  [-29.4584, 152.9975],
  [-29.4549, 152.9975],
  [-29.4538, 152.9979],
  [-29.4522, 152.9987],
  [-29.4493, 152.9992],
  [-29.4443, 152.9981],
  [-29.4430, 152.9971],
  [-29.4413, 152.9929],
  [-29.4401, 152.9917],
  [-29.4360, 152.9904],
  [-29.4347, 152.9905],
  [-29.4329, 152.9912],
  [-29.4315, 152.9910],
  [-29.4297, 152.9899],
  [-29.4279, 152.9898],
  [-29.4239, 152.9924],
  [-29.4222, 152.9926],
  [-29.4200, 152.9914]
];

const sheepCreekOld = [
  [-29.4200, 152.9914],
  [-29.4191, 152.9914],
  [-29.4167, 152.9921],
  [-29.4151, 152.9931],
  [-29.4126, 152.9939],
  [-29.4117, 152.9940],
  [-29.4106, 152.9936],
  [-29.4088, 152.9925],
  [-29.4079, 152.9924],
  [-29.4073, 152.9925],
  [-29.4051, 152.9940],
  [-29.4043, 152.9953],
  [-29.4037, 152.9971],
  [-29.4037, 152.9982],
  [-29.4042, 152.9997],
  [-29.4042, 153.0011],
  [-29.4037, 153.0021],
  [-29.4026, 153.0028],
  [-29.4012, 153.0031],
  [-29.3999, 153.0040],
  [-29.3989, 153.0054],
  [-29.3981, 153.0072],
  [-29.3973, 153.0084],
  [-29.3965, 153.0091],
  [-29.3951, 153.0095],
  [-29.3935, 153.0093],
  [-29.3909, 153.0082],
  [-29.3899, 153.0082],
  [-29.3891, 153.0085],
  [-29.3881, 153.0092],
  [-29.3871, 153.0095],
  [-29.3858, 153.0097],
  [-29.3849, 153.0102]
];

const sheepCreekDeviation = [
  [-29.4200, 152.9914],
  [-29.4192, 152.9910],
  [-29.4153, 152.9899],
  [-29.4118, 152.9898],
  [-29.4074, 152.9908],
  [-29.4038, 152.9929],
  [-29.3999, 152.9967],
  [-29.3957, 153.0027],
  [-29.3936, 153.0046],
  [-29.3881, 153.0073],
  [-29.3866, 153.0084],
  [-29.3849, 153.0102],
];

const rappvilleOld = [
  [-29.0730, 152.9551],
  [-29.0724, 152.9559],
  [-29.0716, 152.9564],
  [-29.0705, 152.9562],
  [-29.0695, 152.9556],
  [-29.0689, 152.9546],
  [-29.0686, 152.9535],
  [-29.0680, 152.9525],
  [-29.0676, 152.9521],
  [-29.0660, 152.9511],
  [-29.0646, 152.9510],
  [-29.0635, 152.9515],
  [-29.0624, 152.9516],
  [-29.0612, 152.9517],
  [-29.0602, 152.9522],
  [-29.0596, 152.9532],
  [-29.0592, 152.9553],
  [-29.0587, 152.9563],
  [-29.0578, 152.9569],
  [-29.0567, 152.9570],
  [-29.0555, 152.9567],
  [-29.0542, 152.9566],
  [-29.0526, 152.9576]
];

const rappvilleDeviation = [
  [-29.0730, 152.9551],
  [-29.0697, 152.9575],
  [-29.0678, 152.9581],
  [-29.0661, 152.9579],
  [-29.0635, 152.9568],
  [-29.0607, 152.9564],
  [-29.0570, 152.9574],
  [-29.0539, 152.9574],
  [-29.0526, 152.9576]
];

const rappvilleCasino = [
  [-29.0526, 152.9576],
  [-29.0476, 152.9600],
  [-29.0433, 152.9641],
  [-29.0416, 152.9648],
  [-29.0385, 152.9650],
  [-29.0315, 152.9682],
  [-29.0191, 152.9724],
  [-29.0163, 152.9726],
  [-29.0095, 152.9724],
  [-29.0049, 152.9731],
  [-29.0028, 152.9743],
  [-29.0007, 152.9762],
  [-28.9988, 152.9771],
  [-28.9910, 152.9787],
  [-28.9744, 152.9861],
  [-28.9712, 152.9871],
  [-28.9666, 152.9902],
  [-28.9565, 152.9937],
  [-28.9420, 152.9953],
  [-28.9398, 152.9960],
  [-28.9295, 153.0021],
  [-28.9259, 153.0033],
  [-28.9179, 153.0104],
  [-28.9111, 153.0143],
  [-28.9076, 153.0184],
  [-28.9038, 153.0220],
  [-28.8992, 153.0278],
  [-28.8983, 153.0302],
  [-28.8980, 153.0352],
  [-28.8970, 153.0378],
  [-28.8912, 153.0446],
  [-28.8888, 153.0456],
  [-28.8861, 153.0453],
  [-28.8699, 153.0369],
  [-28.8677, 153.0367],
  [-28.8610, 153.0381]
];

const sheepCreekRappville = [
  [-29.3849, 153.0102],
  [-29.3815, 153.0136],
  [-29.3793, 153.0150],
  [-29.3759, 153.0159],
  [-29.3740, 153.0159],
  [-29.3722, 153.0155],
  [-29.3705, 153.0157],
  [-29.3637, 153.0175],
  [-29.3618, 153.0174],
  [-29.3601, 153.0167],
  [-29.3569, 153.0146],
  [-29.3544, 153.0141],
  [-29.3521, 153.0149],
  [-29.3492, 153.0171],
  [-29.3413, 153.0210],
  [-29.3402, 153.0211],
  [-29.3388, 153.0203],
  [-29.3363, 153.0169],
  [-29.3341, 153.0158],
  [-29.3266, 153.0158],
  [-29.3231, 153.0143],
  [-29.3200, 153.0111],
  [-29.3182, 153.0100],
  [-29.3171, 153.0081],
  [-29.3170, 153.0052],
  [-29.3157, 153.0021],
  [-29.3155, 152.9991],
  [-29.3149, 152.9973],
  [-29.3136, 152.9956],
  [-29.3048, 152.9891],
  [-29.3038, 152.9877],
  [-29.3036, 152.9874],
  [-29.3020, 152.9851],
  [-29.2997, 152.9839],
  [-29.2984, 152.9824],
  [-29.2958, 152.9755],
  [-29.2887, 152.9682],
  [-29.2870, 152.9673],
  [-29.2824, 152.9661],
  [-29.2811, 152.9662],
  [-29.2759, 152.9678],
  [-29.2741, 152.9676],
  [-29.2723, 152.9669],
  [-29.2709, 152.9667],
  [-29.2673, 152.9674],
  [-29.2651, 152.9666],
  [-29.2623, 152.9639],
  [-29.2606, 152.9635],
  [-29.2564, 152.9640],
  [-29.2532, 152.9653],
  [-29.2495, 152.9652],
  [-29.2460, 152.9638],
  [-29.2440, 152.9639],
  [-29.2424, 152.9645],
  [-29.2408, 152.9644],
  [-29.2395, 152.9633],
  [-29.2386, 152.9614],
  [-29.2373, 152.9606],
  [-29.2355, 152.9603],
  [-29.2343, 152.9598],
  [-29.2317, 152.9574],
  [-29.2297, 152.9533],
  [-29.2267, 152.9507],
  [-29.2073, 152.9448],
  [-29.2053, 152.9434],
  [-29.2034, 152.9428],
  [-29.1986, 152.9433],
  [-29.1963, 152.9456],
  [-29.1956, 152.9479],
  [-29.1938, 152.9495],
  [-29.1742, 152.9484],
  [-29.1657, 152.9465],
  [-29.1427, 152.9355],
  [-29.1401, 152.9357],
  [-29.1386, 152.9371],
  [-29.1374, 152.9393],
  [-29.1358, 152.9407],
  [-29.1331, 152.9419],
  [-29.1296, 152.9415],
  [-29.1282, 152.9417],
  [-29.1250, 152.9434],
  [-29.0967, 152.9478],
  [-29.0939, 152.9490],
  [-29.0869, 152.9532],
  [-29.0851, 152.9537],
  [-29.0755, 152.9541],
  [-29.0738, 152.9546],
  [-29.0730, 152.9551]
];

const casinoJunction = [
  [-28.8568, 153.0392],
  [-28.8556, 153.0401],
  [-28.8547, 153.0415],
  [-28.8543, 153.0430],
  [-28.8543, 153.0439],
  [-28.8544, 153.0447]
];

const casinoNorthJunction = [
  [-28.8610, 153.0381],
  [-28.8568, 153.0392],
  [-28.8534, 153.0397],
  [-28.8521, 153.0396],
  [-28.8512, 153.0394],
  [-28.8502, 153.0388]
];

const casinoNorthJunctionKyogle = [
  [-28.8502, 153.0388],
  [-28.8485, 153.0376],
  [-28.8474, 153.0362],
  [-28.8454, 153.0316],
  [-28.8445, 153.0307],
  [-28.8436, 153.0303],
  [-28.8398, 153.0296],
  [-28.8388, 153.0291],
  [-28.8382, 153.0284],
  [-28.8369, 153.0263],
  [-28.8357, 153.0251],
  [-28.8343, 153.0244],
  [-28.8229, 153.0216],
  [-28.8184, 153.0214],
  [-28.8169, 153.0210],
  [-28.8091, 153.0170],
  [-28.8035, 153.0152],
  [-28.8023, 153.0145],
  [-28.8007, 153.0131],
  [-28.7994, 153.0125],
  [-28.7983, 153.0124],
  [-28.7957, 153.0131],
  [-28.7943, 153.0131],
  [-28.7882, 153.0114],
  [-28.7853, 153.0095],
  [-28.7842, 153.0092],
  [-28.7824, 153.0091],
  [-28.7807, 153.0086],
  [-28.7789, 153.0076],
  [-28.7775, 153.0074],
  [-28.7764, 153.0076],
  [-28.7720, 153.0093],
  [-28.7711, 153.0094],
  [-28.7661, 153.0085],
  [-28.7633, 153.0087],
  [-28.7599, 153.0094],
  [-28.7585, 153.0095],
  [-28.7527, 153.0083],
  [-28.7467, 153.0052],
  [-28.7212, 152.9953],
  [-28.7196, 152.9950],
  [-28.7023, 152.9945],
  [-28.7003, 152.9950],
  [-28.6993, 152.9950],
  [-28.6980, 152.9949],
  [-28.6968, 152.9954],
  [-28.6959, 152.9962],
  [-28.6951, 152.9973],
  [-28.6943, 152.9979],
  [-28.6904, 152.9998],
  [-28.6814, 153.0016],
  [-28.6741, 153.0026],
  [-28.6728, 153.0025],
  [-28.6657, 153.0017],
  [-28.6645, 153.0019],
  [-28.6599, 153.0037],
  [-28.6569, 153.0045],
  [-28.6554, 153.0045],
  [-28.6544, 153.0039],
  [-28.6533, 153.0024],
  [-28.6527, 153.0020],
  [-28.6520, 153.0019],
  [-28.6508, 153.0019],
  [-28.6499, 153.0016],
  [-28.6487, 153.0005],
  [-28.6481, 153.0002],
  [-28.6456, 152.9997],
  [-28.6447, 153.0000],
  [-28.6420, 153.0027],
  [-28.6413, 153.0030],
  [-28.6400, 153.0031],
  [-28.6393, 153.0034],
  [-28.6378, 153.0047],
  [-28.6366, 153.0052],
  [-28.6354, 153.0052],
  [-28.6343, 153.0046],
  [-28.6322, 153.0023],
  [-28.6311, 153.0018],
  [-28.6303, 153.0017],
  [-28.6295, 153.0017],
  [-28.6289, 153.0016],
  [-28.6282, 153.0011],
  [-28.6275, 153.0005],
  [-28.6267, 153.0001],
  [-28.6258, 153.0001],
  [-28.6251, 153.0004],
  [-28.6242, 153.0011],
  [-28.6231, 153.0015],
  [-28.6226, 153.0015],
  [-28.6201, 153.0012]
];

const kyogleCougalSpiral1 = [
  [-28.6201, 153.0012],
  [-28.6161, 153.0006],
  [-28.6150, 152.9999],
  [-28.6084, 152.9925],
  [-28.6057, 152.9909],
  [-28.5847, 152.9829],
  [-28.5827, 152.9826],
  [-28.5736, 152.9828],
  [-28.5725, 152.9826],
  [-28.5715, 152.9819],
  [-28.5707, 152.9809],
  [-28.5697, 152.9792],
  [-28.5688, 152.9783],
  [-28.5678, 152.9779],
  [-28.5669, 152.9779],
  [-28.5640, 152.9788],
  [-28.5623, 152.9786],
  [-28.5612, 152.9779],
  [-28.5602, 152.9770],
  [-28.5590, 152.9763],
  [-28.5552, 152.9754],
  [-28.5534, 152.9754],
  [-28.5504, 152.9759],
  [-28.5484, 152.9761],
  [-28.5464, 152.9761],
  [-28.5434, 152.9756],
  [-28.5315, 152.9719],
  [-28.5296, 152.9710],
  [-28.5225, 152.9670],
  [-28.5142, 152.9634],
  [-28.5129, 152.9633],
  [-28.5113, 152.9640],
  [-28.5090, 152.9656],
  [-28.5082, 152.9667],
  [-28.5076, 152.9679],
  [-28.5064, 152.9688],
  [-28.5051, 152.9689],
  [-28.5040, 152.9684],
  [-28.5013, 152.9661],
  [-28.5007, 152.9650],
  [-28.5005, 152.9641],
  [-28.5006, 152.9630],
  [-28.5013, 152.9612],
  [-28.5015, 152.9599],
  [-28.5012, 152.9586],
  [-28.5003, 152.9574],
  [-28.4966, 152.9552],
  [-28.4941, 152.9547],
  [-28.4921, 152.9548],
  [-28.4906, 152.9547],
  [-28.4857, 152.9538],
  [-28.4848, 152.9533],
  [-28.4841, 152.9526],
  [-28.4837, 152.9515],
  [-28.4834, 152.9494],
  [-28.4835, 152.9484],
  [-28.4839, 152.9475],
  [-28.4848, 152.9463],
  [-28.4852, 152.9451],
  [-28.4852, 152.9438],
  [-28.4844, 152.9413],
  [-28.4838, 152.9404],
  [-28.4827, 152.9396],
  [-28.4815, 152.9395],
  [-28.4727, 152.9410],
  [-28.4702, 152.9410],
  [-28.4690, 152.9414],
  [-28.4679, 152.9428],
  [-28.4673, 152.9452],
  [-28.4659, 152.9466],
  [-28.4642, 152.9468],
  [-28.4596, 152.9444],
  [-28.4552, 152.9447],
  [-28.4540, 152.9456],
  [-28.4501, 152.9521],
  [-28.4491, 152.9527],
  [-28.4481, 152.9528],
  [-28.4458, 152.9525],
  [-28.4447, 152.9527],
  [-28.4438, 152.9533],
  [-28.4433, 152.9541],
  [-28.4416, 152.9578],
  [-28.4406, 152.9587],
  [-28.4386, 152.9593],
  [-28.4377, 152.9592],
  [-28.4367, 152.9585],
  [-28.4357, 152.9572],
  [-28.4345, 152.9563],
  [-28.4331, 152.9562],
  [-28.4287, 152.9579],
  [-28.4278, 152.9589],
  [-28.4274, 152.9600],
  [-28.4275, 152.9622],
  [-28.4273, 152.9650],
  [-28.4274, 152.9662],
  [-28.4282, 152.9679],
  [-28.4283, 152.9690],
  [-28.4278, 152.9702],
  [-28.4268, 152.9710],
  [-28.4259, 152.9710],
  [-28.4238, 152.9700],
  [-28.4229, 152.9700],
  [-28.4218, 152.9705],
  [-28.4212, 152.9714],
  [-28.4208, 152.9732],
  [-28.4196, 152.9752],
  [-28.4186, 152.9758],
  [-28.4173, 152.9757],
  [-28.4158, 152.9749],
  [-28.4131, 152.9746],
  [-28.4122, 152.9750],
  [-28.4114, 152.9761],
  [-28.4109, 152.9773],
  [-28.4103, 152.9779],
  [-28.4077, 152.9794],
  [-28.4065, 152.9795],
  [-28.4051, 152.9790],
  [-28.3995, 152.9756],
  [-28.3983, 152.9754],
  [-28.3954, 152.9756],
  [-28.3946, 152.9761],
  [-28.3931, 152.9774],
  [-28.3924, 152.9778],
  [-28.3917, 152.9778],
  [-28.3902, 152.9776],
  [-28.3895, 152.9779],
  [-28.3887, 152.9785],
  [-28.3877, 152.9794],
  [-28.3866, 152.9796],
  [-28.3857, 152.9794],
  [-28.3843, 152.9782],
  [-28.3830, 152.9778],
  [-28.3826, 152.9778],
  [-28.3816, 152.9782],
  [-28.3811, 152.9787],
  [-28.3804, 152.9801],
  [-28.3799, 152.9807],
  [-28.3787, 152.9815],
  [-28.3778, 152.9814],
  [-28.3770, 152.9809],
  [-28.3763, 152.9797],
  [-28.3759, 152.9784],
  [-28.3753, 152.9774],
  [-28.3743, 152.9767],
  [-28.3731, 152.9765],
  [-28.3710, 152.9771],
  [-28.3692, 152.9778],
  [-28.3682, 152.9777],
  [-28.3672, 152.9769],
  [-28.3669, 152.9760],
  [-28.3670, 152.9731],
  [-28.3668, 152.9720],
  [-28.3660, 152.9709],
  [-28.3649, 152.9703],
  [-28.3639, 152.9696],
  [-28.3613, 152.9670],
  [-28.3608, 152.9661],
  [-28.3607, 152.9652],
  [-28.3608, 152.9641],
  [-28.3605, 152.9631],
  [-28.3597, 152.9618],
  [-28.3590, 152.9612],
  [-28.3568, 152.9602],
  [-28.3562, 152.9601],
  [-28.3545, 152.9603],
  [-28.3537, 152.9606],
  [-28.3526, 152.9615],
  [-28.3521, 152.9622],
  [-28.3519, 152.9632]
];

const cougalSpiralNo1Tunnel = [
  [-28.3519, 152.9632],
  [-28.3519, 152.9638],
  [-28.3520, 152.9643],
  [-28.3524, 152.9650]
];

const cougalSpiral1CougalSpiral2 = [
  [-28.3524, 152.9650],
  [-28.3527, 152.9655],
  [-28.3534, 152.9663],
  [-28.3545, 152.9667],
  [-28.3556, 152.9664],
  [-28.3564, 152.9657]
];

const cougalSpiralNo2Tunnel = [
  [-28.3564, 152.9657],
  [-28.3567, 152.9648],
  [-28.3568, 152.9640]
];

const cougalSpiral2BorderTunnel = [
  [-28.3568, 152.9640],
  [-28.3567, 152.9635],
  [-28.3563, 152.9626],
  [-28.3554, 152.9619],
  [-28.3547, 152.9617],
  [-28.3539, 152.9618],
  [-28.3533, 152.9622],
  [-28.3523, 152.9632],
  [-28.3516, 152.9636],
  [-28.3507, 152.9636],
  [-28.3499, 152.9633],
  [-28.3485, 152.9619],
  [-28.3479, 152.9614],
  [-28.3472, 152.9613],
  [-28.3443, 152.9617],
  [-28.3435, 152.9616],
  [-28.3413, 152.9612],
  [-28.3404, 152.9615],
  [-28.3396, 152.9622],
  [-28.3382, 152.9644],
  [-28.3373, 152.9652],
  [-28.3368, 152.9655]
];

const borderTunnel = [
  [-28.3368, 152.9655],
  [-28.3324, 152.9676]
];

const casinoTriangle = [
  [-28.8502, 153.0388],
  [-28.8511, 153.0398],
  [-28.8538, 153.0437],
  [-28.8544, 153.0447]
];

export const northCoast: Line = {
  name: 'North Coast (NSW)',
  state: 'NSW',
  segments: [
    {
      segments: [
        maitlandJunction,
        maitlandJunctionWallarobbaTunnel,
        {
          name: 'Wallarobba Tunnel',
          segment: wallarobbaTunnel,
          type: 'tunnel'
        },
        wallarobbaTunnelDungog
      ],
      history: {
        opened: {
          date: '1911-08-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        dungogMonkeraiTunnel,
        {
          name: 'Monkerai Tunnel',
          segment: monkeraiTunnel,
          type: 'tunnel'
        },
        monkeraiTunnelBulliacTunnel,
        {
          name: 'Bulliac Tunnel',
          segment: bulliacTunnel,
          type: 'tunnel'
        },
        bulliacTunnelTaree
      ],
      history: {
        opened: {
          date: '1913-02-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        tareeWauchopeTunnel,
        {
          name: 'Wauchope Tunnel',
          segment: wauchopeTunnel,
          type: 'tunnel'
        },
        wauchopeTunnelWauchope
      ],
      history: {
        opened: {
          date: '1915-04-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wauchopeKempsey],
      history: {
        opened: {
          date: '1917-11-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kempseyMacksville],
      history: {
        opened: {
          date: '1919-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [macksvilleUrunga],
      history: {
        opened: {
          date: '1923-12-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [urungaRaleigh],
      history: {
        opened: {
          date: '1923-03-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        raleighReptonTunnel,
        {
          name: 'Repton Tunnel',
          segment: reptonTunnel,
          type: 'tunnel'
        },
        reptonTunnelCoffsHarbour
      ],
      history: {
        opened: {
          date: '1915-09-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        coffsHarbourRedHill1,
        {
          name: 'Red Hill No 1 Tunnel',
          segment: redHillNo1Tunnel,
          type: 'tunnel'
        },
        redHill1RedHill2,
        {
          name: 'Red Hill No 2 Tunnel',
          segment: redHillNo2Tunnel,
          type: 'tunnel'
        },
        redHill2RedHill3,
        {
          name: 'Red Hill No 3 Tunnel',
          segment: redHillNo3Tunnel,
          type: 'tunnel'
        },
        redHill3RedHill4,
        {
          name: 'Red Hill No 4 Tunnel',
          segment: redHillNo4Tunnel,
          type: 'tunnel'
        },
        redHill4RedHill5,
        {
          name: 'Red Hill No 5 Tunnel',
          segment: redHillNo5Tunnel,
          type: 'tunnel'
        },
        redHill5Glenreagh
      ],
      history: {
        opened: {
          date: '1922-07-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [glenreaghGraftonCity],
      history: {
        opened: {
          date: '1915-10-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [graftonCityGrafton],
      history: {
        opened: {
          date: '1932-05-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        graftonSheepCreek,
        {
          date: '1995-01-01',
          original: [sheepCreekOld],
          deviation: [sheepCreekDeviation]
        },
        sheepCreekRappville,
        {
          date: '1995-01-01',
          original: [rappvilleOld],
          deviation: [rappvilleDeviation]
        }
      ],
      history: {
        opened: {
          date: '1905-11-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [rappvilleCasino, casinoJunction],
      history: {
        opened: {
          date: '1905-11-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [casinoNorthJunction],
      history: {
        opened: {
          date: '1930-09-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [casinoNorthJunctionKyogle],
      history: {
        opened: {
          date: '1910-06-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        kyogleCougalSpiral1,
        {
          name: 'Cougal Spiral No 1 Tunnel',
          segment: cougalSpiralNo1Tunnel,
          type: 'tunnel'
        },
        cougalSpiral1CougalSpiral2,
        {
          name: 'Cougal Spiral No 2 Tunnel',
          segment: cougalSpiralNo2Tunnel,
          type: 'tunnel'
        },
        cougalSpiral2BorderTunnel,
        {
          name: 'Border Tunnel',
          segment: borderTunnel,
          type: 'tunnel'
        }
      ],
      history: {
        opened: {
          date: '1930-09-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [casinoTriangle],
      history: {
        opened: {
          date: '1910-06-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-04-09',
          status: 'closed'
        }]
      }
    }
  ]
};
