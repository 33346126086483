import { Line } from "../../../../trackTypes";

const wellingtonStreet = [
  [-37.85765, 144.99229],
  [-37.85751, 144.99192],
  [-37.85642, 144.98340],
  [-37.85645, 144.98321],
  [-37.85657, 144.98293],
  [-37.85670, 144.98272]
];

const princesHighway = [
  [-37.85765, 144.99229],
  [-37.85748, 144.99186],
  [-37.85702, 144.99108],
  [-37.85673, 144.99051],
  [-37.85649, 144.98977],
  [-37.85557, 144.98272],
  [-37.85553, 144.98262],
  [-37.85544, 144.98252]
];

const stKildaJunction = [
  [-37.85646, 144.98288],
  [-37.85661, 144.98285],
  [-37.85670, 144.98272]
];

const stKilda = [
  [-37.85670, 144.98272],
  [-37.85700, 144.98203],
  [-37.85963, 144.97761],
  [-37.86122, 144.97491],
  [-37.86191, 144.97375],
  [-37.86234, 144.97303],
  [-37.86256, 144.97280],
  [-37.86287, 144.97262],
  [-37.86379, 144.97245],
  [-37.86410, 144.97250],
  [-37.86444, 144.97269],
  [-37.86487, 144.97310],
  [-37.86617, 144.97503],
  [-37.86643, 144.97553],
  [-37.86660, 144.97594],
  [-37.86704, 144.97720],
  [-37.86707, 144.97747],
  [-37.86723, 144.97839],
  [-37.86728, 144.97851],
  [-37.86734, 144.97860],
  [-37.86913, 144.98027]
];

export const south5: Line = {
  name: 'South5',
  state: 'VIC',
  segments: [
    {
      segments: [
        {
          date: '1968-01-01',
          original: [wellingtonStreet],
          deviation: [princesHighway, stKildaJunction]
        },
        stKilda
      ],
      history: {
        opened: {
          date: '1891-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1925-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
