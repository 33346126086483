import { Line } from "../../../trackTypes";

const mtGambierMillicent = [
  [-37.8245, 140.7559],
  [-37.8227, 140.7503],
  [-37.8210, 140.7423],
  [-37.8202, 140.7352],
  [-37.8198, 140.7249],
  [-37.8198, 140.7155],
  [-37.8194, 140.7115],
  [-37.8183, 140.7079],
  [-37.8177, 140.7046],
  [-37.8166, 140.6896],
  [-37.8168, 140.6858],
  [-37.8175, 140.6826],
  [-37.8181, 140.6787],
  [-37.8180, 140.6747],
  [-37.8174, 140.6705],
  [-37.8160, 140.6660],
  [-37.8152, 140.6623],
  [-37.8151, 140.6567],
  [-37.8147, 140.6539],
  [-37.8121, 140.6430],
  [-37.8107, 140.6398],
  [-37.8083, 140.6357],
  [-37.8072, 140.6324],
  [-37.8065, 140.6296],
  [-37.8038, 140.6223],
  [-37.7908, 140.5948],
  [-37.7870, 140.5886],
  [-37.7856, 140.5854],
  [-37.7835, 140.5791],
  [-37.7826, 140.5770],
  [-37.7791, 140.5714],
  [-37.7663, 140.5571],
  [-37.7651, 140.5546],
  [-37.7637, 140.5516],
  [-37.7550, 140.5393],
  [-37.7542, 140.5372],
  [-37.7478, 140.5196],
  [-37.7378, 140.5018],
  [-37.7100, 140.4639],
  [-37.7083, 140.4621],
  [-37.6950, 140.4546],
  [-37.6924, 140.4525],
  [-37.6780, 140.4392],
  [-37.6707, 140.4324],
  [-37.6646, 140.4269],
  [-37.6409, 140.4052],
  [-37.6339, 140.3987],
  [-37.6298, 140.3948],
  [-37.6277, 140.3922],
  [-37.6268, 140.3916],
  [-37.6193, 140.3865],
  [-37.6174, 140.3844],
  [-37.6133, 140.3773],
  [-37.6035, 140.3658],
  [-37.5980, 140.3609],
  [-37.5964, 140.3591],
  [-37.5949, 140.3564],
  [-37.5943, 140.3554]
];

const millicentBeachport = [
  [-37.5943, 140.3554],
  [-37.5896, 140.3475],
  [-37.5838, 140.3381],
  [-37.5787, 140.3298],
  [-37.5726, 140.3200],
  [-37.5715, 140.3178],
  [-37.5709, 140.3156],
  [-37.5633, 140.2681],
  [-37.5623, 140.2646],
  [-37.5512, 140.2372],
  [-37.5500, 140.2336],
  [-37.5491, 140.2275],
  [-37.5492, 140.2225],
  [-37.5512, 140.1995],
  [-37.5516, 140.1977],
  [-37.5513, 140.1954],
  [-37.5504, 140.1926],
  [-37.5454, 140.1820],
  [-37.5446, 140.1794],
  [-37.5445, 140.1774],
  [-37.5440, 140.1761],
  [-37.5411, 140.1729],
  [-37.5408, 140.1724],
  [-37.5403, 140.1713],
  [-37.5400, 140.1701],
  [-37.5394, 140.1656],
  [-37.5396, 140.1639],
  [-37.5402, 140.1625],
  [-37.5414, 140.1607],
  [-37.5422, 140.1597],
  [-37.5424, 140.1591],
  [-37.5422, 140.1584],
  [-37.5388, 140.1512],
  [-37.5385, 140.1509],
  [-37.5379, 140.1507],
  [-37.5346, 140.1503],
  [-37.5282, 140.1483],
  [-37.5241, 140.1477],
  [-37.5170, 140.1446],
  [-37.5128, 140.1418],
  [-37.5067, 140.1370],
  [-37.5038, 140.1342],
  [-37.4990, 140.1283],
  [-37.4953, 140.1221],
  [-37.4926, 140.1161],
  [-37.4923, 140.1135],
  [-37.4931, 140.1112],
  [-37.4941, 140.1105],
  [-37.4949, 140.1108],
  [-37.4965, 140.1111],
  [-37.4979, 140.1102],
  [-37.4988, 140.1085],
  [-37.4989, 140.1067],
  [-37.4983, 140.1004],
  [-37.4979, 140.0997],
  [-37.4962, 140.0983],
  [-37.4943, 140.0956],
  [-37.4895, 140.0875],
  [-37.4849, 140.0782],
  [-37.4807, 140.0654],
  [-37.4796, 140.0588],
  [-37.4792, 140.0574],
  [-37.4768, 140.0522],
  [-37.4754, 140.0478],
  [-37.4748, 140.0445],
  [-37.4737, 140.0372],
  [-37.4739, 140.0354],
  [-37.4737, 140.0332],
  [-37.4737, 140.0319],
  [-37.4751, 140.0225],
  [-37.4757, 140.0209],
  [-37.4796, 140.0138],
  [-37.4798, 140.0132],
  [-37.4796, 140.0126],
  [-37.4773, 140.0096]
];

export const rivoliBay: Line = {
  name: 'Rivoli Bay',
  state: 'SA',
  segments: [
    {
      segments: [mtGambierMillicent],
      history: {
        opened: {
          date: '1878-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          gauge: 'broad'
        }, {
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [millicentBeachport],
      history: {
        opened: {
          date: '1878-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
