import { Line } from "../../../../trackTypes";

const clayfield = [
  [-27.44129, 153.04587],
  [-27.44118, 153.04587],
  [-27.44111, 153.04581],
  [-27.44080, 153.04491],
  [-27.44058, 153.04448],
  [-27.44015, 153.04387],
  [-27.44003, 153.04353],
  [-27.43995, 153.04334],
  [-27.43947, 153.04296],
  [-27.43923, 153.04284],
  [-27.43844, 153.04267],
  [-27.43813, 153.04267],
  [-27.43782, 153.04271],
  [-27.43739, 153.04287],
  [-27.43681, 153.04318],
  [-27.43599, 153.04363],
  [-27.43561, 153.04373],
  [-27.43531, 153.04376],
  [-27.43504, 153.04369],
  [-27.43414, 153.04333],
  [-27.43385, 153.04326],
  [-27.43251, 153.04299],
  [-27.43221, 153.04289],
  [-27.43199, 153.04270],
  [-27.43073, 153.04172],
  [-27.43038, 153.04166],
  [-27.43028, 153.04168],
  [-27.42974, 153.04183],
  [-27.42958, 153.04192],
  [-27.42943, 153.04206],
  [-27.42894, 153.04274],
  [-27.42885, 153.04292],
  [-27.42854, 153.04411],
  [-27.42842, 153.04441],
  [-27.42806, 153.04517],
  [-27.42798, 153.04538],
  [-27.42790, 153.04577],
  [-27.42791, 153.04617],
  [-27.42806, 153.04682],
  [-27.42820, 153.04758],
  [-27.42820, 153.04777],
  [-27.42817, 153.04794],
  [-27.42774, 153.04911],
  [-27.42764, 153.04933],
  [-27.42714, 153.05013],
  [-27.42697, 153.05031],
  [-27.42676, 153.05045],
  [-27.42657, 153.05055],
  [-27.42640, 153.05059],
  [-27.42272, 153.05121],
  [-27.42250, 153.05129],
  [-27.42234, 153.05139],
  [-27.42214, 153.05157],
  [-27.41963, 153.05427]
];

const amyStreetSiding = [
  [-27.44118, 153.04598],
  [-27.44106, 153.04608],
  [-27.44086, 153.04614],
  [-27.43989, 153.04627]
];

export const east5: Line = {
  name: 'East 5',
  state: 'QLD',
  segments: [
    {
      segments: [clayfield],
      history: {
        opened: {
          date: '1901-12-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [amyStreetSiding],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    }
  ]
}
