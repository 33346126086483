import { Line } from "../../trackTypes";

const waurnPondsMoirac = [
  [-38.2159, 144.3061],
  [-38.2163, 144.3031],
  [-38.2168, 144.3005],
  [-38.2415, 144.1871],
  [-38.2420, 144.1838],
  [-38.2419, 144.1810],
  [-38.2414, 144.1781],
  [-38.2403, 144.1731]
];

const moiracWinchealsie = [
  [-38.2403, 144.1731],
  [-38.2295, 144.1241],
  [-38.2289, 144.1208],
  [-38.2289, 144.1182],
  [-38.2294, 144.0840],
  [-38.2297, 144.0818],
  [-38.2317, 144.0710],
  [-38.2319, 144.0697],
  [-38.2318, 144.0684],
  [-38.2300, 144.0515],
  [-38.2299, 144.0495],
  [-38.2304, 144.0231],
  [-38.2304, 144.0225],
  [-38.2336, 143.9976],
  [-38.2339, 143.9961],
  [-38.2343, 143.9952],
  [-38.2401, 143.9836]
];

const winchealsieBirregurra = [
  [-38.2401, 143.9836],
  [-38.2423, 143.9792],
  [-38.2428, 143.9778],
  [-38.2610, 143.9121],
  [-38.2877, 143.8356],
  [-38.2882, 143.8349],
  [-38.3141, 143.8000],
  [-38.3150, 143.7991],
  [-38.3263, 143.7899],
  [-38.3272, 143.7888],
  [-38.3279, 143.7875],
  [-38.3283, 143.7863],
  [-38.3286, 143.7831]
];

const burregurraColac = [
  [-38.3286, 143.7831],
  [-38.3293, 143.7783],
  [-38.3294, 143.7765],
  [-38.3292, 143.7746],
  [-38.3286, 143.7725],
  [-38.3117, 143.7266],
  [-38.3114, 143.7253],
  [-38.3112, 143.7233],
  [-38.3117, 143.6834],
  [-38.3116, 143.6814],
  [-38.3103, 143.6664],
  [-38.3102, 143.6648],
  [-38.3120, 143.6380],
  [-38.3124, 143.6327],
  [-38.3129, 143.6306],
  [-38.3135, 143.6292],
  [-38.3172, 143.6223],
  [-38.3181, 143.6210],
  [-38.3189, 143.6202],
  [-38.3380, 143.6038],
  [-38.3389, 143.6027],
  [-38.3394, 143.6018],
  [-38.3398, 143.6009],
  [-38.3433, 143.5871]
];

const colacCamperdown = [
  [-38.3433, 143.5871],
  [-38.3437, 143.5857],
  [-38.3439, 143.5844],
  [-38.3441, 143.5831],
  [-38.3441, 143.5819],
  [-38.3415, 143.5591],
  [-38.3413, 143.5565],
  [-38.3413, 143.5545],
  [-38.3416, 143.5522],
  [-38.3420, 143.5499],
  [-38.3472, 143.5299],
  [-38.3669, 143.4556],
  [-38.3672, 143.4542],
  [-38.3673, 143.4531],
  [-38.3675, 143.4513],
  [-38.3676, 143.4498],
  [-38.3674, 143.4473],
  [-38.3594, 143.3744],
  [-38.3590, 143.3713],
  [-38.3588, 143.3700],
  [-38.3584, 143.3680],
  [-38.3569, 143.3642],
  [-38.3544, 143.3606],
  [-38.3511, 143.3577],
  [-38.3352, 143.3487],
  [-38.3343, 143.3481],
  [-38.3331, 143.3472],
  [-38.3315, 143.3456],
  [-38.3303, 143.3445],
  [-38.3295, 143.3434],
  [-38.3290, 143.3424],
  [-38.3284, 143.3409],
  [-38.3267, 143.3349],
  [-38.3263, 143.3340],
  [-38.3246, 143.3303],
  [-38.3235, 143.3277],
  [-38.3220, 143.3254],
  [-38.3173, 143.3195],
  [-38.3154, 143.3177],
  [-38.3122, 143.3156],
  [-38.3107, 143.3143],
  [-38.3097, 143.3133],
  [-38.2921, 143.2905],
  [-38.2908, 143.2893],
  [-38.2894, 143.2885],
  [-38.2885, 143.2882],
  [-38.2749, 143.2854],
  [-38.2736, 143.2850],
  [-38.2725, 143.2845],
  [-38.2716, 143.2838],
  [-38.2705, 143.2827],
  [-38.2695, 143.2812],
  [-38.2492, 143.2389],
  [-38.2482, 143.2360],
  [-38.2413, 143.2039],
  [-38.2391, 143.1968],
  [-38.2389, 143.1960],
  [-38.2379, 143.1876],
  [-38.2373, 143.1848],
  [-38.2317, 143.1588],
  [-38.2312, 143.1572],
  [-38.2308, 143.1558],
  [-38.2287, 143.1508]
];

const camperdownTerang = [
  [-38.2287, 143.1508],
  [-38.2227, 143.1365],
  [-38.2138, 143.1207],
  [-38.2096, 143.1133],
  [-38.2091, 143.1121],
  [-38.2085, 143.1104],
  [-38.2081, 143.1081],
  [-38.2079, 143.1066],
  [-38.2080, 143.1044],
  [-38.2142, 143.0299],
  [-38.2142, 143.0281],
  [-38.2134, 142.9925],
  [-38.2135, 142.9912],
  [-38.2139, 142.9891],
  [-38.2144, 142.9877],
  [-38.2171, 142.9825],
  [-38.2366, 142.9266],
  [-38.2371, 142.9247],
  [-38.2373, 142.9230],
  [-38.2374, 142.9218],
  [-38.2361, 142.9114]
];

const terangWarrnambool = [
  [-38.2361, 142.9114],
  [-38.2349, 142.9007],
  [-38.2349, 142.8995],
  [-38.2350, 142.8984],
  [-38.2354, 142.8973],
  [-38.2376, 142.8913],
  [-38.2386, 142.8895],
  [-38.2394, 142.8885],
  [-38.2402, 142.8877],
  [-38.2485, 142.8816],
  [-38.2607, 142.8723],
  [-38.2641, 142.8698],
  [-38.2747, 142.8618],
  [-38.2756, 142.8610],
  [-38.2924, 142.8453],
  [-38.2938, 142.8436],
  [-38.2950, 142.8413],
  [-38.2957, 142.8386],
  [-38.2974, 142.8249],
  [-38.2978, 142.8235],
  [-38.2993, 142.8192],
  [-38.3000, 142.8167],
  [-38.3002, 142.8153],
  [-38.3005, 142.8038],
  [-38.3008, 142.8020],
  [-38.3013, 142.8005],
  [-38.3040, 142.7931],
  [-38.3056, 142.7900],
  [-38.3072, 142.7876],
  [-38.3087, 142.7845],
  [-38.3149, 142.7682],
  [-38.3158, 142.7634],
  [-38.3172, 142.7527],
  [-38.3177, 142.7507],
  [-38.3194, 142.7479],
  [-38.3404, 142.7276],
  [-38.3414, 142.7261],
  [-38.3419, 142.7246],
  [-38.3422, 142.7232],
  [-38.3427, 142.7178],
  [-38.3426, 142.7162],
  [-38.3416, 142.7069],
  [-38.3415, 142.7048],
  [-38.3416, 142.7024],
  [-38.3435, 142.6509],
  [-38.3440, 142.6483],
  [-38.3456, 142.6443],
  [-38.3474, 142.6419],
  [-38.3488, 142.6406],
  [-38.3588, 142.6343],
  [-38.3599, 142.6333],
  [-38.3622, 142.6307],
  [-38.3646, 142.6294],
  [-38.3680, 142.6288],
  [-38.3693, 142.6282],
  [-38.3733, 142.6258],
  [-38.3801, 142.6212],
  [-38.3817, 142.6196],
  [-38.3834, 142.6173],
  [-38.3893, 142.6052],
  [-38.3898, 142.6033],
  [-38.3899, 142.6014],
  [-38.3882, 142.5841],
  [-38.3882, 142.5817],
  [-38.3891, 142.5730],
  [-38.3891, 142.5707],
  [-38.3864, 142.5454],
  [-38.3862, 142.5436],
  [-38.3867, 142.5237],
  [-38.3869, 142.5218],
  [-38.3871, 142.5206],
  [-38.3881, 142.5169],
  [-38.3883, 142.5156],
  [-38.3883, 142.5148],
  [-38.3883, 142.5135],
  [-38.3884, 142.5124],
  [-38.3886, 142.5109],
  [-38.3891, 142.5094],
  [-38.3898, 142.5079],
  [-38.3905, 142.5057],
  [-38.3912, 142.5016],
  [-38.3915, 142.4994],
  [-38.3916, 142.4978],
  [-38.3915, 142.4961],
  [-38.3909, 142.4908],
  [-38.3908, 142.4889],
  [-38.3910, 142.4874],
  [-38.3911, 142.4861],
  [-38.3911, 142.4852],
  [-38.3906, 142.4836],
  [-38.3894, 142.4818],
  [-38.3886, 142.4810],
  [-38.3881, 142.4805],
  [-38.3874, 142.4797],
  [-38.3862, 142.4777],
  [-38.3852, 142.4753],
  [-38.3844, 142.4735],
  [-38.3841, 142.4722],
  [-38.3839, 142.4711],
  [-38.3838, 142.4696],
  [-38.3835, 142.4688],
  [-38.3829, 142.4674],
  [-38.3817, 142.4657],
  [-38.3806, 142.4648],
  [-38.3782, 142.4634],
  [-38.3772, 142.4630],
  [-38.3727, 142.4623],
  [-38.3717, 142.4614],
  [-38.3704, 142.4609],
  [-38.3694, 142.4602],
  [-38.3603, 142.4512],
  [-38.3596, 142.4501],
  [-38.3533, 142.4389]
];

const warrnamboolPortFairy = [
  [-38.3533, 142.4389],
  [-38.3515, 142.4355],
  [-38.3405, 142.4159],
  [-38.3394, 142.4143],
  [-38.3371, 142.4122],
  [-38.3281, 142.4060],
  [-38.3086, 142.3930],
  [-38.3055, 142.3905],
  [-38.2938, 142.3778],
  [-38.2931, 142.3764],
  [-38.2907, 142.3702],
  [-38.2897, 142.3686],
  [-38.2852, 142.3630],
  [-38.2848, 142.3619],
  [-38.2847, 142.3607],
  [-38.2849, 142.3595],
  [-38.2922, 142.3469],
  [-38.2925, 142.3462],
  [-38.3001, 142.3229],
  [-38.3122, 142.2743],
  [-38.3130, 142.2724],
  [-38.3140, 142.2708],
  [-38.3277, 142.2520],
  [-38.3296, 142.2501],
  [-38.3321, 142.2484],
  [-38.3650, 142.2340],
  [-38.3661, 142.2338],
  [-38.3669, 142.2338],
  [-38.3795, 142.2374],
  [-38.3847, 142.2390],
  [-38.3857, 142.2402],
  [-38.3866, 142.2405]
];

export const warrnambool: Line = {
  name: 'Warrnambool',
  state: 'VIC',
  segments: [
    {
      segments: [waurnPondsMoirac],
      history: {
        opened: {
          date: '1876-11-25',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [moiracWinchealsie],
      history: {
        opened: {
          date: '1876-11-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [winchealsieBirregurra],
      history: {
        opened: {
          date: '1877-03-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
      }
    },
    {
      segments: [burregurraColac],
      history: {
        opened: {
          date: '1877-07-27',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [colacCamperdown],
      history: {
        opened: {
          date: '1883-07-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [camperdownTerang],
      history: {
        opened: {
          date: '1887-04-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [terangWarrnambool],
      history: {
        opened: {
          date: '1890-02-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [warrnamboolPortFairy],
      history: {
        opened: {
          date: '1890-02-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-09-12',
          status: 'closed'
        }]
      }
    }
  ]
};
