import { Line } from "../../../trackTypes";

const eastWest = [
  [-32.73226, 151.54617],
  [-32.73231, 151.54621],
  [-32.73235, 151.54630],
  [-32.73237, 151.54676],
  [-32.73239, 151.54682],
  [-32.73245, 151.54690],
  [-32.73259, 151.54690],
  [-32.73268, 151.54685],
  [-32.73275, 151.54673],
  [-32.73276, 151.54628],
  [-32.73272, 151.54620],
  [-32.73267, 151.54615],
  [-32.73226, 151.54617],
  [-32.73146, 151.54621],
  [-32.73115, 151.54620],
  [-32.72813, 151.54592],
  [-32.72804, 151.54602],
  [-32.72800, 151.54611],
  [-32.72767, 151.55018],
  [-32.72762, 151.55063],
  [-32.72763, 151.55084],
  [-32.72767, 151.55097],
  [-32.72775, 151.55113],
  [-32.72791, 151.55127],
  [-32.73100, 151.55324],
  [-32.73115, 151.55337],
  [-32.73131, 151.55354],
  [-32.73148, 151.55381],
  [-32.73254, 151.55617],
  [-32.73322, 151.55721],
  [-32.73353, 151.55757],
  [-32.73384, 151.55790],
  [-32.73563, 151.55933],
  [-32.73818, 151.56168],
  [-32.73851, 151.56194],
  [-32.73902, 151.56227],
  [-32.73953, 151.56273],
  [-32.74037, 151.56372],
  [-32.74045, 151.56389],
  [-32.74056, 151.56419],
  [-32.74079, 151.56520],
  [-32.74089, 151.56553],
  [-32.74105, 151.56578],
  [-32.74157, 151.56640],
  [-32.74179, 151.56675],
  [-32.74199, 151.56725],
  [-32.74208, 151.56770],
  [-32.74204, 151.56852],
  [-32.74151, 151.57089],
  [-32.74150, 151.57123],
  [-32.74155, 151.57165],
  [-32.74179, 151.57241],
  [-32.74230, 151.57348],
  [-32.74270, 151.57409],
  [-32.74312, 151.57460],
  [-32.74320, 151.57476],
  [-32.74323, 151.57494],
  [-32.74325, 151.57746],
  [-32.74331, 151.57779],
  [-32.74341, 151.57809],
  [-32.74361, 151.57840],
  [-32.74526, 151.58031],
  [-32.74653, 151.58140],
  [-32.74659, 151.58157],
  [-32.74656, 151.58171],
  [-32.74487, 151.58448],
  [-32.74472, 151.58479],
  [-32.74464, 151.58510],
  [-32.74448, 151.58575],
  [-32.74450, 151.58590],
  [-32.74458, 151.58605],
  [-32.74792, 151.58888],
  [-32.74910, 151.59011],
  [-32.74933, 151.59033],
  [-32.74942, 151.59034],
  [-32.74954, 151.59028],
  [-32.75012, 151.58931],
  [-32.75021, 151.58927],
  [-32.75030, 151.58928],
  [-32.75147, 151.59027],
  [-32.75266, 151.59129],
  [-32.75269, 151.59140],
  [-32.75268, 151.59152],
  [-32.75229, 151.59216],
  [-32.75221, 151.59218],
  [-32.75213, 151.59216],
  [-32.75111, 151.59132],
  [-32.75110, 151.59126],
  [-32.75111, 151.59116],
  [-32.75151, 151.59048],
  [-32.75152, 151.59039],
  [-32.75147, 151.59027]
];

const highStreetStation = [
  [-32.74037, 151.56372],
  [-32.74125, 151.56480]
];

const maitlandStationJunction = [
  [-32.73148, 151.55381],
  [-32.73148, 151.55374],
  [-32.73151, 151.55365],
  [-32.73157, 151.55358]
];

const westMaitlandStationBranch = [
  [-32.73131, 151.55354],
  [-32.73141, 151.55358],
  [-32.73157, 151.55358],
  [-32.73476, 151.55273],
  [-32.73699, 151.55222],
  [-32.73727, 151.55216],
  [-32.73743, 151.55222],
  [-32.73759, 151.55236],
  [-32.73776, 151.55254],
  [-32.73790, 151.55290],
  [-32.73788, 151.55308],
  [-32.73782, 151.55316],
  [-32.73768, 151.55327],
  [-32.73758, 151.55327],
  [-32.73748, 151.55318],
  [-32.73732, 151.55241],
  [-32.73724, 151.55229],
  [-32.73714, 151.55223],
  [-32.73699, 151.55222]
];

export const maitlandTrams: Line = {
  name: 'Maitland (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [eastWest, highStreetStation],
      history: {
        opened: {
          date: '1909-02-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westMaitlandStationBranch, maitlandStationJunction],
      history: {
        opened: {
          date: '1910-10-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    }
  ]
};
