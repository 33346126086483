import { Line } from "../../trackTypes";

const line = [
  [-34.60354, 146.4035],
  [-34.6030, 146.4027],
  [-34.6018, 146.4019],
  [-34.6008, 146.4017],
  [-34.6001, 146.4020],
  [-34.5965, 146.4045],
  [-34.5951, 146.4048],
  [-34.5865, 146.4034],
  [-34.5856, 146.4031],
  [-34.5806, 146.3995],
  [-34.5769, 146.3955],
  [-34.5757, 146.3948],
  [-34.5706, 146.3938],
  [-34.5696, 146.3939],
  [-34.5684, 146.3945],
  [-34.5599, 146.4018],
  [-34.5588, 146.4022],
  [-34.5576, 146.4020],
  [-34.5565, 146.4012],
  [-34.5529, 146.3952],
  [-34.5521, 146.3934],
  [-34.5518, 146.3918],
  [-34.5509, 146.3849],
  [-34.5504, 146.3834],
  [-34.5480, 146.3799],
  [-34.5472, 146.3781],
  [-34.5466, 146.3762],
  [-34.5459, 146.3748],
  [-34.5433, 146.3707],
  [-34.5418, 146.3694],
  [-34.5405, 146.3690],
  [-34.5112, 146.3667],
  [-34.4974, 146.3642],
  [-34.4963, 146.3642],
  [-34.4773, 146.3676],
  [-34.4755, 146.3676],
  [-34.4742, 146.3671],
  [-34.4656, 146.3621],
  [-34.4641, 146.3606],
  [-34.4297, 146.3142],
  [-34.4284, 146.3118],
  [-34.4278, 146.3094],
  [-34.4257, 146.2923],
  [-34.4243, 146.2888],
  [-34.4218, 146.2861],
  [-34.3918, 146.2684],
  [-34.3898, 146.2666],
  [-34.3777, 146.2513],
  [-34.3761, 146.2483],
  [-34.3730, 146.2400],
  [-34.3717, 146.2284],
  [-34.3703, 146.2247],
  [-34.3642, 146.2151],
  [-34.3503, 146.2030],
  [-34.3482, 146.1996],
  [-34.3309, 146.1352],
  [-34.3297, 146.1324],
  [-34.2889, 146.0621],
  [-34.2881, 146.0595]
];

export const yanco: Line = {
  name: 'Yanco',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1922-03-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
