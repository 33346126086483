import { Line } from "../../trackTypes";

const line = [
  [-33.6337, 146.9557],
  [-33.6334, 146.9543],
  [-33.6335, 146.9534],
  [-33.6431, 146.8990],
  [-33.6471, 146.8768],
  [-33.6469, 146.8743],
  [-33.6460, 146.8717],
  [-33.6459, 146.8692],
  [-33.6460, 146.8672],
  [-33.6518, 146.8482],
  [-33.6523, 146.8458],
  [-33.6594, 146.7709],
  [-33.6558, 146.7360],
  [-33.6491, 146.7094],
  [-33.6486, 146.7065],
  [-33.6489, 146.7038],
  [-33.6551, 146.6797],
  [-33.6553, 146.6765],
  [-33.6512, 146.6402],
  [-33.6455, 146.6213],
  [-33.6450, 146.6193],
  [-33.6327, 146.5081],
  [-33.6123, 146.3225]
];

export const naradhan: Line = {
  name: 'Naradhan',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1929-02-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
