import { Line } from "../../../trackTypes";

const robbsJettyCoogee = [
  [-32.0900, 115.7590],
  [-32.0914, 115.7598],
  [-32.0951, 115.7607]
];

const coogeeWoodmanPoint = [
  [-32.0951, 115.7607],
  [-32.1036, 115.7620],
  [-32.1054, 115.7623],
  [-32.1111, 115.7646],
  [-32.1128, 115.7651],
  [-32.1134, 115.7650]
];

const woodmanPointNavalBase = [
  [-32.1134, 115.7650],
  [-32.1201, 115.7658],
  [-32.1259, 115.7672],
  [-32.1307, 115.7677],
  [-32.1341, 115.7670],
  [-32.1396, 115.7677],
  [-32.1417, 115.7671],
  [-32.1578, 115.7674],
  [-32.1732, 115.7723],
  [-32.1833, 115.7761]
];

const robbsJettySpearwood = [
  [-32.0951, 115.7607],
  [-32.0969, 115.7618],
  [-32.0982, 115.7634],
  [-32.1006, 115.7686],
  [-32.1014, 115.7745],
  [-32.1021, 115.7766],
  [-32.1030, 115.7779],
  [-32.1036, 115.7793],
  [-32.1038, 115.7818],
  [-32.1043, 115.7833],
  [-32.1060, 115.7863],
  [-32.1064, 115.7880]
];

const spearwoodBibraLake = [
  [-32.1064, 115.7880],
  [-32.1065, 115.7911],
  [-32.1060, 115.7929],
  [-32.1047, 115.7947],
  [-32.1040, 115.7964],
  [-32.1038, 115.7975],
  [-32.1039, 115.8006],
  [-32.1044, 115.8026],
  [-32.1074, 115.8060],
  [-32.1085, 115.8091],
  [-32.1083, 115.8123],
  [-32.1072, 115.8151],
  [-32.1069, 115.8174],
  [-32.1072, 115.8238]
];

const bibraLakeJandakot = [
  [-32.1072, 115.8238],
  [-32.1087, 115.8283],
  [-32.1105, 115.8305],
  [-32.1147, 115.8343],
  [-32.1170, 115.8393],
  [-32.1183, 115.8411],
  [-32.1213, 115.8480]
];

const jandakotArmadale = [
  [-32.1213, 115.8480],
  [-32.1239, 115.8537],
  [-32.1273, 115.8678],
  [-32.1282, 115.8700],
  [-32.1308, 115.8751],
  [-32.1344, 115.8857],
  [-32.1345, 115.8870],
  [-32.1341, 115.8903],
  [-32.1343, 115.8921],
  [-32.1474, 115.9213],
  [-32.1478, 115.9233],
  [-32.1468, 116.0116],
  [-32.1471, 116.0126],
  [-32.1477, 116.0136],
  [-32.1487, 116.0141]
];

const welshpoolKewdaleTemporaryJunction = [
  [-32.0012, 115.9312],
  [-32.0013, 115.9324],
  [-32.0009, 115.9340],
  [-31.9997, 115.9350],
  [-31.9964, 115.9351],
  [-31.9916, 115.9358],
  [-31.9869, 115.9376],
  [-31.9862, 115.9381],
  [-31.9842, 115.9405],
  [-31.9840, 115.9416],
  [-31.9868, 115.9696],
  [-31.9863, 115.9721],
  [-31.9863, 115.9759],
  [-31.9856, 115.9779],
  [-31.9835, 115.9800]
];

const kewdaleTemporaryJunctionMidlandJunction = [
  [-31.9835, 115.9800],
  [-31.9820, 115.9814],
  [-31.9790, 115.9854],
  [-31.9760, 115.9873],
  [-31.9589, 115.9911],
  [-31.9494, 115.9916],
  [-31.9478, 115.9914],
  [-31.9426, 115.9903],
  [-31.9412, 115.9903],
  [-31.9398, 115.9906],
  [-31.9285, 115.9943],
  [-31.9264, 115.9944],
  [-31.9085, 115.9893],
  [-31.9073, 115.9892],
  [-31.8980, 115.9895],
  [-31.8958, 115.9892],
  [-31.8944, 115.9893],
  [-31.8931, 115.9900],
  [-31.8921, 115.9912],
  [-31.8915, 115.9932]
];

const midlandJunction = [
  [-31.8958, 115.9892],
  [-31.8949, 115.9887],
  [-31.8943, 115.9880],
  [-31.8938, 115.9869],
  [-31.8937, 115.9855],
  [-31.8942, 115.9838]
];

const kewdaleTemporaryJunctionKenwickJunction = [
  [-31.9835, 115.9800],
  [-31.9853, 115.9791],
  [-31.9937, 115.9770],
  [-32.0011, 115.9780],
  [-32.0047, 115.9772],
  [-32.0093, 115.9736],
  [-32.0124, 115.9725],
  [-32.0223, 115.9727],
  [-32.0258, 115.9720],
  [-32.0297, 115.9695]
];

const kenwickJunction = [
  [-32.0297, 115.9695],
  [-32.0314, 115.9680],
  [-32.0324, 115.9677],
  [-32.0337, 115.9677],
  [-32.0351, 115.9683]
];

const kenwickJunctionThornlieJunction = [
  [-32.0297, 115.9695],
  [-32.0345, 115.9638]
];

const kewdaleCockburnCement = [
  [-32.0473, 115.9521],
  [-32.0686, 115.9253],
  [-32.0700, 115.9227],
  [-32.0713, 115.9186],
  [-32.0760, 115.8924],
  [-32.0771, 115.8889],
  [-32.0970, 115.8450],
  [-32.1021, 115.8363],
  [-32.1037, 115.8309],
  [-32.1052, 115.8275],
  [-32.1179, 115.8120],
  [-32.1190, 115.8096],
  [-32.1201, 115.8041],
  [-32.1214, 115.8016],
  [-32.1234, 115.8000],
  [-32.1256, 115.7994],
  [-32.1275, 115.7997]
];

const bibraLakeConnectionOne = [
  [-32.1068, 115.8256],
  [-32.1073, 115.8246],
  [-32.1072, 115.8238]
];

const bibraLakeConnectionTwo = [
  [-32.1201, 115.8041],
  [-32.1200, 115.8014],
  [-32.1191, 115.7993],
  [-32.1178, 115.7980],
  [-32.1160, 115.7972]
];

const cockburnCement = [
  [-32.1066, 115.7888],
  [-32.1074, 115.7902],
  [-32.1088, 115.7914],
  [-32.1096, 115.7926],
  [-32.1104, 115.7950],
  [-32.1112, 115.7962],
  [-32.1128, 115.7971],
  [-32.1220, 115.7972],
  [-32.1230, 115.7974],
  [-32.1275, 115.7997],
  [-32.1309, 115.8004],
  [-32.1370, 115.8005],
  [-32.1397, 115.8004],
  [-32.1410, 115.8000],
  [-32.1436, 115.7976],
  [-32.1453, 115.7969],
  [-32.1476, 115.7971],
  [-32.1506, 115.7984]
];

const cockburnCementKwinana = [
  [-32.1370, 115.8005],
  [-32.1417, 115.8010],
  [-32.1452, 115.8022],
  [-32.1467, 115.8024],
  [-32.1489, 115.8019],
  [-32.1524, 115.8002],
  [-32.1546, 115.7998],
  [-32.1564, 115.8003],
  [-32.1595, 115.8018],
  [-32.1623, 115.8027],
  [-32.1704, 115.8027],
  [-32.1725, 115.8022],
  [-32.1762, 115.7998],
  [-32.1784, 115.7991],
  [-32.1877, 115.7982],
  [-32.1907, 115.7967],
  [-32.1986, 115.7895],
  [-32.2089, 115.7842],
  [-32.2102, 115.7839],
  [-32.2118, 115.7839]
];

const alcoaKwinana = [
  [-32.1833, 115.7761],
  [-32.1841, 115.7764],
  [-32.1858, 115.7775],
  [-32.1879, 115.7795],
  [-32.1896, 115.7805],
  [-32.1914, 115.7808],
  [-32.1937, 115.7808],
  [-32.1972, 115.7798],
  [-32.1989, 115.7799],
  [-32.2092, 115.7832],
  [-32.2118, 115.7839],
  [-32.2201, 115.7854],
  [-32.2226, 115.7855],
  [-32.2244, 115.7849],
  [-32.2250, 115.7842],
  [-32.2256, 115.7823]
];

const kwinanaLoop = [
  [-32.2256, 115.7823],
  [-32.2257, 115.7793],
  [-32.2260, 115.7778],
  [-32.2269, 115.7768],
  [-32.2282, 115.7762],
  [-32.2394, 115.7767],
  [-32.2411, 115.7761],
  [-32.2424, 115.7744],
  [-32.2443, 115.7692],
  [-32.2451, 115.7680],
  [-32.2491, 115.7635],
  [-32.2577, 115.7559],
  [-32.2586, 115.7548],
  [-32.2632, 115.7507],
  [-32.2646, 115.7502],
  [-32.2661, 115.7507],
  [-32.2670, 115.7519],
  [-32.2673, 115.7545],
  [-32.2669, 115.7559],
  [-32.2659, 115.7570],
  [-32.2645, 115.7573],
  [-32.2634, 115.7570],
  [-32.2627, 115.7563],
  [-32.2620, 115.7554],
  [-32.2611, 115.7548],
  [-32.2599, 115.7547],
  [-32.2577, 115.7559]
];

const kwinanaMundijong = [
  [-32.2226, 115.7855],
  [-32.2237, 115.7856],
  [-32.2245, 115.7855],
  [-32.2278, 115.7848],
  [-32.2304, 115.7842],
  [-32.2439, 115.7846],
  [-32.2478, 115.7835],
  [-32.2689, 115.7844],
  [-32.2754, 115.7877],
  [-32.2830, 115.7966],
  [-32.2854, 115.8015],
  [-32.2866, 115.8064],
  [-32.2861, 115.8135],
  [-32.2850, 115.8160],
  [-32.2803, 115.8219],
  [-32.2731, 115.8386],
  [-32.2721, 115.8426],
  [-32.2719, 115.8464],
  [-32.2733, 115.8555],
  [-32.2752, 115.8621],
  [-32.2752, 115.8665],
  [-32.2709, 115.8918],
  [-32.2707, 115.8954],
  [-32.2710, 115.9020],
  [-32.2693, 115.9070],
  [-32.2632, 115.9157],
  [-32.2611, 115.9213],
  [-32.2609, 115.9275],
  [-32.2681, 115.9620],
  [-32.2684, 115.9651],
  [-32.2682, 115.9860],
  [-32.2689, 115.9886],
  [-32.2702, 115.9904],
  [-32.2718, 115.9914],
  [-32.2744, 115.9917]
];

const kwinanaJunction = [
  [-32.2278, 115.7848],
  [-32.2269, 115.7847],
  [-32.2262, 115.7842],
  [-32.2258, 115.7835],
  [-32.2256, 115.7823]
];

export const kwinanaFreight: Line = {
  name: 'Kwinana Freight',
  state: 'WA',
  segments: [
    {
      segments: [robbsJettyCoogee],
      history: {
        opened: {
          date: '1903-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [coogeeWoodmanPoint],
      history: {
        opened: {
          date: '1903-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }, {
          date: '1973-09-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [woodmanPointNavalBase],
      history: {
        opened: {
          date: '1955-12-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-09-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [robbsJettySpearwood],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [spearwoodBibraLake],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }, {
          date: '1991-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bibraLakeJandakot],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-06-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [jandakotArmadale],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kwinanaMundijong, kwinanaJunction],
      history: {
        opened: {
          date: '1961-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [welshpoolKewdaleTemporaryJunction],
      history: {
        opened: {
          date: '1957-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kewdaleTemporaryJunctionMidlandJunction],
      history: {
        opened: {
          date: '1957-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          gauge: 'dual',
          tracks: 2
        }]
      }
    },
    {
      segments: [midlandJunction],
      history: {
        opened: {
          date: '1957-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          gauge: 'dual',
        }]
      }
    },
    {
      segments: [kewdaleTemporaryJunctionKenwickJunction, kenwickJunctionThornlieJunction],
      history: {
        opened: {
          date: '1968-01-01',
          tracks: 2,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kenwickJunction],
      history: {
        opened: {
          date: '1968-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cockburnCement],
      history: {
        opened: {
          date: '1961-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [kwinanaLoop],
      history: {
        opened: {
          date: '1966-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [alcoaKwinana],
      history: {
        opened: {
          date: '1955-12-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-08-03',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [cockburnCementKwinana],
      history: {
        opened: {
          date: '1968-08-03',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kewdaleCockburnCement],
      history: {
        opened: {
          date: '1968-08-03',
          tracks: 2,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bibraLakeConnectionOne],
      history: {
        opened: {
          date: '1968-08-03',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bibraLakeConnectionTwo],
      history: {
        opened: {
          date: '1968-08-03',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    }
  ]
};
