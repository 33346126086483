import { Line } from "../../../trackTypes";

const devonport = [
  [-36.83211, 174.79688],
  [-36.83045, 174.79777],
  [-36.83024, 174.79785],
  [-36.82904, 174.79786],
  [-36.82892, 174.79783],
  [-36.82879, 174.79775],
  [-36.82870, 174.79765],
  [-36.82864, 174.79754],
  [-36.82849, 174.79701],
  [-36.82845, 174.79690],
  [-36.82836, 174.79685],
  [-36.82827, 174.79683],
  [-36.82625, 174.79658],
  [-36.82609, 174.79659],
  [-36.82598, 174.79663],
  [-36.82585, 174.79669],
  [-36.82571, 174.79682],
  [-36.82544, 174.79714],
  [-36.82528, 174.79742],
  [-36.82490, 174.79818],
  [-36.82476, 174.79847],
  [-36.82427, 174.80008],
  [-36.82420, 174.80027],
  [-36.82412, 174.80042],
  [-36.82297, 174.80258],
  [-36.82293, 174.80270],
  [-36.82290, 174.80279],
  [-36.82258, 174.80426],
  [-36.82253, 174.80437],
  [-36.82118, 174.80491],
  [-36.82113, 174.80496],
  [-36.82112, 174.80503],
  [-36.82113, 174.80510],
  [-36.82116, 174.80517],
  [-36.82134, 174.80539],
  [-36.82137, 174.80553],
  [-36.82134, 174.80565],
  [-36.82071, 174.80655]
];

const milfordBayswater = [
  [-36.81950, 174.76714],
  [-36.81877, 174.76789],
  [-36.81815, 174.76851],
  [-36.81783, 174.76876],
  [-36.81756, 174.76889],
  [-36.81734, 174.76894],
  [-36.81712, 174.76894],
  [-36.81670, 174.76885],
  [-36.81655, 174.76887],
  [-36.81639, 174.76893],
  [-36.81622, 174.76907],
  [-36.81614, 174.76923],
  [-36.81606, 174.76955],
  [-36.81480, 174.77611],
  [-36.81472, 174.77637],
  [-36.80849, 174.78977],
  [-36.80836, 174.78994],
  [-36.80824, 174.79002],
  [-36.80811, 174.79005],
  [-36.79872, 174.78565],
  [-36.79855, 174.78552],
  [-36.79842, 174.78539],
  [-36.79829, 174.78520],
  [-36.79818, 174.78498],
  [-36.79812, 174.78479],
  [-36.79805, 174.78453],
  [-36.79803, 174.78430],
  [-36.79744, 174.77930],
  [-36.79738, 174.77905],
  [-36.79730, 174.77890],
  [-36.79717, 174.77874],
  [-36.78923, 174.77233],
  [-36.78917, 174.77232],
  [-36.78909, 174.77232],
  [-36.77683, 174.77530],
  [-36.77666, 174.77532],
  [-36.77653, 174.77529],
  [-36.77519, 174.77433],
  [-36.77510, 174.77424],
  [-36.77503, 174.77411],
  [-36.77235, 174.76394],
  [-36.77236, 174.76380],
  [-36.77239, 174.76369],
  [-36.77247, 174.76355],
  [-36.77492, 174.76089],
  [-36.78182, 174.75354],
  [-36.78210, 174.75333],
  [-36.78254, 174.75310],
  [-36.78268, 174.75307],
  [-36.78280, 174.75313],
  [-36.78975, 174.76158],
  [-36.78980, 174.76179],
  [-36.78976, 174.76193],
  [-36.78862, 174.76342],
  [-36.78845, 174.76376],
  [-36.78659, 174.76746],
  [-36.78564, 174.76926],
  [-36.78563, 174.76936],
  [-36.78566, 174.76943],
  [-36.78923, 174.77233]
];

export const northAucklandTrams: Line = {
  name: 'North Auckland Trams',
  state: 'NZ',
  segments: [
    {
      segments: [devonport],
      history: {
        opened: {
          date: '1886-09-25',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'//horse
        },
        trackChange: [{
          date: '1888-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [milfordBayswater],
      history: {
        opened: {
          date: '1910-12-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //steam
        },
        trackChange: [{
          date: '1927-04-26',
          status: 'closed'
        }]
      }
    }
  ]
}
