import { Line } from "../../../trackTypes";

/**
 * no 2 jetty rail unused from 1916
 *
 * WORKING ZONE
 * osborne-wallsend colliery (mount keira) -> (belmore basin
 * - opened 1859 as horse tram as narrow
 * - converted to heavy rail in 1879-11 as standard
 * - triangle added to connect to main line in 1890-04-10
 * - closed 1937-12
 *
 * bulli colliery -> bulli point jetty
 *  - 1861 as horse tram (far north)
 *  - 1867-05 converted to standard gauge heavy rail
 *  - need close date
 *
 * mount pleasant colliery -> belmore basin (slight north)
 *  - narrow gauge
 *  - opeened 1863 as horse tramway
 *  - converted to heavy rail in 1884 (same gauge)
 *  - both gauged caused conflict points with the mt keira line
 *
 * woonona colliery -> bellambi point (3rd jetty) (north)
 *  - opened 1887 as heavy rail standard gauge
 *
 * south bulli mine -> bellambu point (2nd jetty)
 * - 1864 as horse tram
 * - converted to havy rail standard gauge 1887 to a 4th jetty
 *
 * mt keira line ???????
 *
 * DUPLICATION
 * Illawarra Junction-Hurstville:
  Opened as double track ...... 15th October 1884
  Q uadruplicated... 10th August 1925
  Hurstville-Oatley Temporary J u n c tio n 4th April 1890
  Oatley Temporary Junction-Como Bridge .... 9th November 1890
  Como Bridge “gauntletted”. See page 18
  C om o-Sutherland.. 22nd March 1891
  Sutherland-Loftus J u n c tio n .... 4th April 1890
  Loftus Junction-H eathcote...... 9th November 1890
  H eathcote-W aterfall..... 12th December 1890
  Waterfall-South Waterfall Temporary Junction 17th August 1914
  South Waterfall Temporary Junction-Helensburgh Temporary Ju n c tio n .. 22nd February 1914
  Helensburgh Temporary Junction-O tford 30th May 1915
  Otford-Coal C liff.. 10th October 1920
  Coalcliff-Scarborough not duplicated
  Scarborough-Thirroul... 14th November 1915
  Thirroul-Bulli Coal S id in g ...... 25th October 1916
  Bulli Coal Siding-Woonona Signal B o x 20th May 1923
  Woonona Signal Box-Bellambi 25th September 1913
  Bellambi-Mount P le a sa n t. 27th May 1923
  Mount Pleasant-M ount Keira.. 14th May 1913
  Mount Keira-Wollongong Signal B o x . 29th April 1923
  Wollongong Signal B ox-C oniston 15th July 1941
  Coniston-Nowra not duplicated
 */

const triangle = [
  [-34.4535, 150.8585],
  [-34.4529, 150.8574],
  [-34.4523, 150.8557],
  [-34.4508, 150.8538],
  [-34.4506, 150.8532],
  [-34.4505, 150.8524],
  [-34.4509, 150.8507]
];

const portKemblaBranch = [
  [-34.4306, 150.8288],
  [-34.4291, 150.8271],
  [-34.4282, 150.8256],
  [-34.4278, 150.8245],
  [-34.4279, 150.8232],
  [-34.4284, 150.8222],
  [-34.4285, 150.8214],
  [-34.4283, 150.8182]
];

const neroColliery = [
  [-34.4306, 150.8288],
  [-34.4293, 150.8279],
  [-34.4288, 150.8277],
  [-34.4283, 150.8278],
  [-34.4278, 150.8280],
  [-34.4263, 150.8298],
  [-34.4259, 150.8301],
  [-34.4254, 150.8303],
  [-34.4249, 150.8302],
  [-34.4241, 150.8298],
  [-34.4237, 150.8297],
  [-34.4232, 150.8294],
  [-34.4229, 150.8288],
  [-34.4226, 150.8278],
  [-34.4218, 150.8267],
  [-34.4211, 150.8262],
  [-34.4204, 150.8261],
  [-34.4186, 150.8265],
  [-34.4182, 150.8269],
  [-34.4180, 150.8273],
  [-34.4176, 150.8277],
  [-34.4167, 150.8279]
];

const line = [
  [-34.4687, 150.8803],
  [-34.4675, 150.8794],
  [-34.4641, 150.8783],
  [-34.4634, 150.8777],
  [-34.4632, 150.8764],
  [-34.4634, 150.8754],
  [-34.4635, 150.8744],
  [-34.4635, 150.8736],
  [-34.4632, 150.8728],
  [-34.4615, 150.8702],
  [-34.4610, 150.8693],
  [-34.4599, 150.8668],
  [-34.4584, 150.8646],
  [-34.4567, 150.8615],
  [-34.4562, 150.8610],
  [-34.4552, 150.8607],
  [-34.4548, 150.8605],
  [-34.4544, 150.8599],
  [-34.4535, 150.8585],
  [-34.4531, 150.8581],
  [-34.4506, 150.8559],
  [-34.4498, 150.8550],
  [-34.4493, 150.8543],
  [-34.4479, 150.8514],
  [-34.4474, 150.8504],
  [-34.4464, 150.8463],
  [-34.4460, 150.8453],
  [-34.4441, 150.8426],
  [-34.4435, 150.8422],
  [-34.4417, 150.8415],
  [-34.4409, 150.8407],
  [-34.4399, 150.8391],
  [-34.4391, 150.8382],
  [-34.4386, 150.8377],
  [-34.4378, 150.8373],
  [-34.4367, 150.8372],
  [-34.4362, 150.8371],
  [-34.4358, 150.8367],
  [-34.4354, 150.8360],
  [-34.4348, 150.8333],
  [-34.4346, 150.8329],
  [-34.4339, 150.8319],
  [-34.4326, 150.8307],
  [-34.4315, 150.8295],
  [-34.4306, 150.8288],
];

const jettyConnection = [
  [-34.4728, 150.8895],
  [-34.4727, 150.8908],
  [-34.4711, 150.8970],
  [-34.4711, 150.8994],
  [-34.4714, 150.9002],
  [-34.4720, 150.9012],
  [-34.4760, 150.9039],
  [-34.4765, 150.9041],
  [-34.4774, 150.9044],
  [-34.4781, 150.9048],
  [-34.4788, 150.9057]
];

const numberOneJetty = [
  [-34.4788, 150.9057],
  [-34.4788, 150.9068],
  [-34.4786, 150.9074],
  [-34.4785, 150.9075],
  [-34.4764, 150.9097],
  [-34.4752, 150.9102],
  [-34.4722, 150.9101]
];

export const mountKembla: Line = {
  name: 'Mount Kembla',
  state: 'NSW',
  segments: [
    {
      segments: [triangle],
      history: {
        opened: {
          date: '1928-08-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portKemblaBranch],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-10-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [neroColliery],
      history: {
        opened: {
          date: '1949-10-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [line],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [jettyConnection],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [numberOneJetty],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
