import { Line } from "../../../trackTypes";

const bethaniaLoganVillage = [
  [-27.6894, 153.1595],
  [-27.6898, 153.1596],
  [-27.6902, 153.1596],
  [-27.6906, 153.1594],
  [-27.6909, 153.1591],
  [-27.6911, 153.1585],
  [-27.6912, 153.1578],
  [-27.6915, 153.1573],
  [-27.6921, 153.1569],
  [-27.6929, 153.1567],
  [-27.6933, 153.1565],
  [-27.6938, 153.1561],
  [-27.6944, 153.1557],
  [-27.6980, 153.1551],
  [-27.6985, 153.1547],
  [-27.6991, 153.1540],
  [-27.6993, 153.1532],
  [-27.6993, 153.1523],
  [-27.6995, 153.1515],
  [-27.6998, 153.1510],
  [-27.7023, 153.1482],
  [-27.7028, 153.1479],
  [-27.7063, 153.1475],
  [-27.7087, 153.1478],
  [-27.7094, 153.1477],
  [-27.7098, 153.1475],
  [-27.7110, 153.1460],
  [-27.7113, 153.1452],
  [-27.7113, 153.1437],
  [-27.7116, 153.1427],
  [-27.7122, 153.1421],
  [-27.7132, 153.1418],
  [-27.7157, 153.1423],
  [-27.7163, 153.1422],
  [-27.7264, 153.1373],
  [-27.7341, 153.1336],
  [-27.7379, 153.1324],
  [-27.7429, 153.1296],
  [-27.7438, 153.1285],
  [-27.7450, 153.1270],
  [-27.7506, 153.1254],
  [-27.7520, 153.1247],
  [-27.7535, 153.1232],
  [-27.7544, 153.1223],
  [-27.7549, 153.1210],
  [-27.7548, 153.1196],
  [-27.7552, 153.1187],
  [-27.7557, 153.1181],
  [-27.7565, 153.1177],
  [-27.7570, 153.1169],
  [-27.7572, 153.1160],
  [-27.7577, 153.1151],
  [-27.7614, 153.1119],
  [-27.7660, 153.1098],
  [-27.7687, 153.1080]
];

const loganVillageBeaudesert = [
  [-27.7687, 153.1080],
  [-27.7705, 153.1068],
  [-27.7790, 153.1005],
  [-27.7800, 153.0984],
  [-27.7810, 153.0973],
  [-27.7828, 153.0963],
  [-27.7834, 153.0957],
  [-27.7836, 153.0946],
  [-27.7834, 153.0937],
  [-27.7828, 153.0929],
  [-27.7818, 153.0922],
  [-27.7814, 153.0912],
  [-27.7815, 153.0901],
  [-27.7820, 153.0893],
  [-27.7830, 153.0880],
  [-27.7840, 153.0859],
  [-27.7858, 153.0806],
  [-27.7860, 153.0794],
  [-27.7861, 153.0760],
  [-27.7868, 153.0742],
  [-27.7879, 153.0727],
  [-27.7988, 153.0631],
  [-27.8073, 153.0576],
  [-27.8079, 153.0567],
  [-27.8083, 153.0551],
  [-27.8090, 153.0540],
  [-27.8129, 153.0516],
  [-27.8136, 153.0507],
  [-27.8139, 153.0498],
  [-27.8143, 153.0488],
  [-27.8154, 153.0478],
  [-27.8158, 153.0470],
  [-27.8161, 153.0457],
  [-27.8193, 153.0415],
  [-27.8200, 153.0409],
  [-27.8257, 153.0390],
  [-27.8294, 153.0361],
  [-27.8299, 153.0351],
  [-27.8298, 153.0331],
  [-27.8300, 153.0321],
  [-27.8359, 153.0189],
  [-27.8427, 153.0118],
  [-27.8437, 153.0100],
  [-27.8445, 153.0075],
  [-27.8447, 153.0023],
  [-27.8450, 153.0012],
  [-27.8455, 153.0005],
  [-27.8491, 152.9980],
  [-27.8593, 152.9941],
  [-27.8611, 152.9925],
  [-27.8619, 152.9911],
  [-27.8628, 152.9891],
  [-27.8635, 152.9883],
  [-27.8643, 152.9877],
  [-27.8675, 152.9871],
  [-27.8692, 152.9860],
  [-27.8702, 152.9859],
  [-27.8718, 152.9865],
  [-27.8738, 152.9868],
  [-27.8772, 152.9864],
  [-27.8779, 152.9860],
  [-27.8800, 152.9839],
  [-27.8812, 152.9833],
  [-27.8832, 152.9829],
  [-27.8841, 152.9822],
  [-27.8848, 152.9809],
  [-27.8850, 152.9799],
  [-27.8855, 152.9792],
  [-27.8861, 152.9785],
  [-27.8867, 152.9775],
  [-27.8868, 152.9748],
  [-27.8872, 152.9739],
  [-27.8879, 152.9734],
  [-27.8917, 152.9728],
  [-27.8968, 152.9736],
  [-27.8981, 152.9733],
  [-27.8996, 152.9719],
  [-27.9011, 152.9712],
  [-27.9023, 152.9712],
  [-27.9035, 152.9714],
  [-27.9051, 152.9727],
  [-27.9064, 152.9758],
  [-27.9074, 152.9771],
  [-27.9105, 152.9791],
  [-27.9124, 152.9793],
  [-27.9197, 152.9778],
  [-27.9212, 152.9777],
  [-27.9378, 152.9816],
  [-27.9392, 152.9818],
  [-27.9450, 152.9814],
  [-27.9461, 152.9816],
  [-27.9527, 152.9850],
  [-27.9541, 152.9853],
  [-27.9620, 152.9854],
  [-27.9632, 152.9856],
  [-27.9675, 152.9872],
  [-27.9688, 152.9881],
  [-27.9704, 152.9908],
  [-27.9719, 152.9919],
  [-27.9843, 152.9947],
  [-27.9868, 152.9953]
];

export const beaudesert: Line = {
  name: 'Beaudesert',
  state: 'QLD',
  segments: [
    {
      segments: [bethaniaLoganVillage],
      history: {
        opened: {
          date: '1885-09-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-05-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [loganVillageBeaudesert],
      history: {
        opened: {
          date: '1888-05-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-05-20',
          status: 'closed'
        }]
      }
    }
  ]
}
