import React from "react";
import Modal from "./Modal";
import styled from "styled-components";

const ScrollableContainer = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
`;

const Date = styled.span`
  color: ${({ theme }) => theme.highlightText};
`;

type ChangelogProps = {
  isOpen: boolean;
  close: Function;
};

const ChangelogModal = ({ isOpen, close}: ChangelogProps) => {
  return (
    <Modal isOpen={isOpen} close={close}>
      <ScrollableContainer>
        <h2 style={{textAlign: 'center'}}>Changelog:</h2>
        <p><Date>2024-09-27</Date> Initial Release. Includes all lines in Australia and New Zealand, including trains, trams, and some industrial trams</p>
        <p><Date>2024-09-29</Date> Added Kaili - Durroburra duplication</p>
        <p><Date>2024-09-30</Date> Added Marton - New Plymouth line tunnels. Fixed rockhampton electrification extent</p>
        <p><Date>2024-10-01</Date> Added Wellington Cable Car tunnels. Added Argent Tunnel in TAS</p>
        <p><Date>2024-10-03</Date> Added Palmerston North - Gisborne line tunnels. Added Nelson line tunnels</p>
        <p><Date>2024-10-04</Date> Added North Auckland line tunnels. Added Main South Line (NZ) tunnels with missing deviations. Added missing stratford (NZ) deviation. Fixed dates around kalgoorlie standardization</p>
        <p><Date>2024-10-05</Date> Fixed Rockhampton double track extent and singling date. Added North Island Main Trunk line tunnels and missing deviations</p>
        <p><Date>2024-10-06</Date> Added Stratford - Okahukura line tunnels</p>
        <p><Date>2024-10-07</Date> Added Midland (NZ) line tunnels. Added Otago Central Line tunnels. Added Main North Line (NZ) tunnels</p>
        <p><Date>2024-10-08</Date> Added Wellington tram tunnels. Reworked Sydney Central Station area</p>
        <p><Date>2024-10-10</Date> Added slightly different colours for eletcified trams vs cable trams vs horse/steam/gas trams. Rewrote Melbourn's tram lines to be more accurate and take into account cable trams, as well as to include missing tram sidings</p>
        <p><Date>2024-10-11</Date> Added the Kanowna line (WA). Added Gnumballa Lake line (WA) and fixed boulder/brown hill loop lines</p>
        <p><Date>2024-10-14</Date> Added quad track on the Sandridge Bridge. Added Largs Bay branch. Added StKilda tram museum line. Rewrote Adelaide's tram network, fixing multiple allignemnt issues, and tram classifications</p>
        <p><Date>2024-10-17</Date> Added missing Broken Hill tram lines with fixed dates. Fixed the L2 and L3 lines in Sydney being around the wrong way</p>
        <p><Date>2024-10-18</Date> Added Lorne timber tramway</p>
        <p><Date>2024-10-22</Date> Added Brisbane abbatoirs and Gibson Island branchs. Added all tram junctions in Melbourne and VIC + tram depo spurs. Added Derby horse tram. Fixed Napier tram electrification status</p>
        <p><Date>2024-10-30</Date> Added Illabo - Stockingbingal section of inalnd rail (under construction). Added missing section of tram on liverpool street(Hobart). Added tram junctions for Brisbane, Kalgoorlie, Whanganui, Fremantle, Hobart, Launceston, Broken Hill</p>
        <p><Date>2024-11-02</Date> Fixed Dunedin cable trams not being tagged as such. Added junctions for Dunedin and Manly Trams</p>
      </ScrollableContainer>
    </Modal>
  );
};

export default ChangelogModal;
