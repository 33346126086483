import { Line } from "../../../trackTypes";

const toowoombaCabarlah = [
  [-27.4954, 151.9208],
  [-27.4950, 151.9206],
  [-27.4947, 151.9204],
  [-27.4942, 151.9204],
  [-27.4914, 151.9212],
  [-27.4904, 151.9212],
  [-27.4893, 151.9208],
  [-27.4874, 151.9190],
  [-27.4855, 151.9161],
  [-27.4851, 151.9157],
  [-27.4846, 151.9155],
  [-27.4843, 151.9151],
  [-27.4842, 151.9144],
  [-27.4844, 151.9138],
  [-27.4848, 151.9132],
  [-27.4850, 151.9123],
  [-27.4849, 151.9109],
  [-27.4844, 151.9104],
  [-27.4839, 151.9103],
  [-27.4828, 151.9106],
  [-27.4823, 151.9111],
  [-27.4813, 151.9126],
  [-27.4809, 151.9128],
  [-27.4805, 151.9128],
  [-27.4802, 151.9125],
  [-27.4798, 151.9124],
  [-27.4793, 151.9126],
  [-27.4790, 151.9132],
  [-27.4787, 151.9135],
  [-27.4784, 151.9136],
  [-27.4780, 151.9135],
  [-27.4777, 151.9131],
  [-27.4776, 151.9123],
  [-27.4773, 151.9120],
  [-27.4769, 151.9118],
  [-27.4761, 151.9118],
  [-27.4754, 151.9114],
  [-27.4751, 151.9106],
  [-27.4747, 151.9103],
  [-27.4739, 151.9101],
  [-27.4735, 151.9097],
  [-27.4723, 151.9087],
  [-27.4660, 151.9055],
  [-27.4653, 151.9058],
  [-27.4648, 151.9063],
  [-27.4633, 151.9063],
  [-27.4629, 151.9066],
  [-27.4623, 151.9078],
  [-27.4620, 151.9081],
  [-27.4609, 151.9087],
  [-27.4595, 151.9100],
  [-27.4587, 151.9101],
  [-27.4580, 151.9097],
  [-27.4532, 151.9092],
  [-27.4524, 151.9096],
  [-27.4518, 151.9106],
  [-27.4515, 151.9118],
  [-27.4511, 151.9123],
  [-27.4499, 151.9128],
  [-27.4486, 151.9136],
  [-27.4465, 151.9145],
  [-27.4453, 151.9146],
  [-27.4386, 151.9138],
  [-27.4377, 151.9140],
  [-27.4368, 151.9145],
  [-27.4362, 151.9151],
  [-27.4358, 151.9152],
  [-27.4353, 151.9152],
  [-27.4345, 151.9146],
  [-27.4310, 151.9139],
  [-27.4305, 151.9140],
  [-27.4274, 151.9154],
  [-27.4267, 151.9159],
  [-27.4259, 151.9170],
  [-27.4247, 151.9203],
  [-27.4247, 151.9209],
  [-27.4248, 151.9214],
  [-27.4248, 151.9219],
  [-27.4246, 151.9225],
  [-27.4246, 151.9232],
  [-27.4249, 151.9262],
  [-27.4246, 151.9268],
  [-27.4241, 151.9275],
  [-27.4238, 151.9282],
  [-27.4237, 151.9288],
  [-27.4255, 151.9452],
  [-27.4255, 151.9466],
  [-27.4246, 151.9524],
  [-27.4249, 151.9537],
  [-27.4255, 151.9544],
  [-27.4263, 151.9546],
  [-27.4272, 151.9543],
  [-27.4279, 151.9546],
  [-27.4287, 151.9555],
  [-27.4294, 151.9557],
  [-27.4301, 151.9555],
  [-27.4305, 151.9554],
  [-27.4317, 151.9556],
  [-27.4323, 151.9563],
  [-27.4324, 151.9573],
  [-27.4323, 151.9580],
  [-27.4315, 151.9602],
  [-27.4308, 151.9614],
  [-27.4306, 151.9627],
  [-27.4307, 151.9652],
  [-27.4305, 151.9660],
  [-27.4297, 151.9669],
  [-27.4289, 151.9691],
  [-27.4290, 151.9699],
  [-27.4294, 151.9704],
  [-27.4303, 151.9707],
  [-27.4307, 151.9712],
  [-27.4307, 151.9718],
  [-27.4305, 151.9729],
  [-27.4306, 151.9734],
  [-27.4309, 151.9738],
  [-27.4323, 151.9739],
  [-27.4328, 151.9745],
  [-27.4329, 151.9751],
  [-27.4328, 151.9756],
  [-27.4320, 151.9765],
  [-27.4318, 151.9771],
  [-27.4320, 151.9777],
  [-27.4325, 151.9782],
  [-27.4330, 151.9791],
  [-27.4329, 151.9802],
  [-27.4325, 151.9807],
  [-27.4317, 151.9806],
  [-27.4301, 151.9802],
  [-27.4294, 151.9805],
  [-27.4275, 151.9817],
  [-27.4270, 151.9824],
  [-27.4268, 151.9837],
  [-27.4278, 151.9916]
];

const cabarlahCrowsNest = [
  [-27.4278, 151.9916],
  [-27.4278, 151.9922],
  [-27.4275, 151.9933],
  [-27.4265, 151.9948],
  [-27.4262, 151.9955],
  [-27.4264, 151.9962],
  [-27.4265, 151.9971],
  [-27.4264, 151.9979],
  [-27.4260, 151.9982],
  [-27.4254, 151.9984],
  [-27.4249, 151.9988],
  [-27.4245, 151.9996],
  [-27.4245, 152.0007],
  [-27.4242, 152.0016],
  [-27.4235, 152.0019],
  [-27.4229, 152.0017],
  [-27.4222, 152.0017],
  [-27.4217, 152.0021],
  [-27.4189, 152.0052],
  [-27.4185, 152.0054],
  [-27.4177, 152.0054],
  [-27.4172, 152.0057],
  [-27.4170, 152.0061],
  [-27.4165, 152.0083],
  [-27.4160, 152.0090],
  [-27.4154, 152.0094],
  [-27.4146, 152.0095],
  [-27.4098, 152.0094],
  [-27.4049, 152.0090],
  [-27.4040, 152.0095],
  [-27.4034, 152.0101],
  [-27.4030, 152.0110],
  [-27.4032, 152.0135],
  [-27.4028, 152.0148],
  [-27.3980, 152.0225],
  [-27.3974, 152.0230],
  [-27.3966, 152.0231],
  [-27.3961, 152.0230],
  [-27.3955, 152.0227],
  [-27.3952, 152.0225],
  [-27.3947, 152.0226],
  [-27.3942, 152.0229],
  [-27.3935, 152.0244],
  [-27.3931, 152.0256],
  [-27.3936, 152.0273],
  [-27.3935, 152.0282],
  [-27.3929, 152.0291],
  [-27.3927, 152.0297],
  [-27.3926, 152.0318],
  [-27.3923, 152.0328],
  [-27.3916, 152.0341],
  [-27.3913, 152.0350],
  [-27.3904, 152.0391],
  [-27.3897, 152.0400],
  [-27.3888, 152.0403],
  [-27.3875, 152.0402],
  [-27.3869, 152.0397],
  [-27.3844, 152.0362],
  [-27.3832, 152.0354],
  [-27.3816, 152.0353],
  [-27.3810, 152.0350],
  [-27.3808, 152.0343],
  [-27.3808, 152.0332],
  [-27.3805, 152.0327],
  [-27.3801, 152.0325],
  [-27.3790, 152.0323],
  [-27.3783, 152.0318],
  [-27.3779, 152.0317],
  [-27.3775, 152.0318],
  [-27.3767, 152.0323],
  [-27.3763, 152.0323],
  [-27.3758, 152.0321],
  [-27.3751, 152.0315],
  [-27.3743, 152.0314],
  [-27.3736, 152.0317],
  [-27.3717, 152.0333],
  [-27.3708, 152.0339],
  [-27.3677, 152.0351],
  [-27.3670, 152.0357],
  [-27.3643, 152.0390],
  [-27.3639, 152.0401],
  [-27.3641, 152.0463],
  [-27.3637, 152.0513],
  [-27.3638, 152.0525],
  [-27.3650, 152.0563],
  [-27.3649, 152.0573],
  [-27.3646, 152.0579],
  [-27.3608, 152.0630],
  [-27.3599, 152.0654],
  [-27.3594, 152.0661],
  [-27.3589, 152.0663],
  [-27.3544, 152.0671],
  [-27.3533, 152.0669],
  [-27.3513, 152.0662],
  [-27.3510, 152.0660],
  [-27.3502, 152.0661],
  [-27.3498, 152.0664],
  [-27.3492, 152.0682],
  [-27.3484, 152.0695],
  [-27.3475, 152.0701],
  [-27.3452, 152.0704],
  [-27.3440, 152.0702],
  [-27.3286, 152.0625],
  [-27.3218, 152.0569],
  [-27.3116, 152.0501],
  [-27.3100, 152.0483],
  [-27.3080, 152.0456],
  [-27.3052, 152.0423],
  [-27.3036, 152.0415],
  [-27.3022, 152.0415],
  [-27.3007, 152.0405],
  [-27.2985, 152.0383],
  [-27.2976, 152.0371],
  [-27.2970, 152.0368],
  [-27.2963, 152.0368],
  [-27.2953, 152.0372],
  [-27.2940, 152.0385],
  [-27.2934, 152.0387],
  [-27.2914, 152.0384],
  [-27.2908, 152.0385],
  [-27.2900, 152.0388],
  [-27.2895, 152.0387],
  [-27.2891, 152.0382],
  [-27.2890, 152.0377],
  [-27.2892, 152.0369],
  [-27.2890, 152.0361],
  [-27.2884, 152.0357],
  [-27.2879, 152.0358],
  [-27.2863, 152.0368],
  [-27.2858, 152.0375],
  [-27.2853, 152.0379],
  [-27.2847, 152.0381],
  [-27.2839, 152.0387],
  [-27.2832, 152.0389],
  [-27.2828, 152.0392],
  [-27.2826, 152.0395],
  [-27.2825, 152.0407],
  [-27.2814, 152.0437],
  [-27.2807, 152.0443],
  [-27.2799, 152.0445],
  [-27.2793, 152.0442],
  [-27.2786, 152.0434],
  [-27.2779, 152.0432],
  [-27.2771, 152.0433],
  [-27.2760, 152.0440],
  [-27.2751, 152.0441],
  [-27.2737, 152.0437],
  [-27.2728, 152.0437],
  [-27.2720, 152.0442],
  [-27.2714, 152.0452],
  [-27.2707, 152.0476],
  [-27.2701, 152.0485],
  [-27.2692, 152.0491],
  [-27.2681, 152.0493],
  [-27.2663, 152.0489],
  [-27.2654, 152.0489],
  [-27.2640, 152.0492]
];

export const crowsNest: Line = {
  name: 'Crows Nest',
  state: 'QLD',
  segments: [
    {
      segments: [toowoombaCabarlah],
      history: {
        opened: {
          date: '1883-09-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cabarlahCrowsNest],
      history: {
        opened: {
          date: '1886-06-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-07-01',
          status: 'closed'
        }]
      }
    }
  ]
}
