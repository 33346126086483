import { Line } from "../../../trackTypes";

const thornburyRiverton = [
  [-46.2861, 168.0995],
  [-46.2868, 168.0962],
  [-46.2875, 168.0949],
  [-46.2949, 168.0888],
  [-46.3012, 168.0834],
  [-46.3098, 168.0726],
  [-46.3208, 168.0588],
  [-46.3238, 168.0519],
  [-46.3470, 168.0187]
];

const rivertonColac = [
  [-46.3470, 168.0187],
  [-46.3500, 168.0143],
  [-46.3508, 168.0120],
  [-46.3510, 168.0092],
  [-46.3506, 168.0008],
  [-46.3486, 167.9924],
  [-46.3450, 167.9531],
  [-46.3453, 167.9515],
  [-46.3459, 167.9506],
  [-46.3505, 167.9464],
  [-46.3516, 167.9443],
  [-46.3545, 167.9332],
  [-46.3550, 167.9310],
  [-46.3551, 167.9091],
  [-46.3554, 167.9069],
  [-46.3621, 167.8751]
];

const colacRoundhill = [
  [-46.3621, 167.8751],
  [-46.3664, 167.8545],
  [-46.3665, 167.8524],
  [-46.3593, 167.8116]
];

const roundhillOrepuki = [
  [-46.3593, 167.8116],
  [-46.3547, 167.7858],
  [-46.3535, 167.7822],
  [-46.3522, 167.7802],
  [-46.3282, 167.7532],
  [-46.3118, 167.7345],
  [-46.3103, 167.7338],
  [-46.3089, 167.7338],
  [-46.2996, 167.7386],
  [-46.2990, 167.7389],
  [-46.2889, 167.7395],
  [-46.2873, 167.7392],
  [-46.2802, 167.7360]
];

const orepukiWaihoaka = [
  [-46.2802, 167.7360],
  [-46.2792, 167.7355],
  [-46.2786, 167.7351],
  [-46.2758, 167.7325],
  [-46.2725, 167.7299],
  [-46.2717, 167.7296],
  [-46.2707, 167.7300],
  [-46.2673, 167.7334],
  [-46.2660, 167.7341],
  [-46.2634, 167.7346],
  [-46.2609, 167.7364],
  [-46.2599, 167.7365],
  [-46.2585, 167.7360],
  [-46.2576, 167.7348],
  [-46.2574, 167.7339],
  [-46.2576, 167.7327],
  [-46.2591, 167.7283],
  [-46.2592, 167.7270],
  [-46.2587, 167.7258],
  [-46.2525, 167.7179]
];

const waihoakaTuatapere = [
  [-46.2525, 167.7179],
  [-46.2480, 167.7124],
  [-46.2466, 167.7117],
  [-46.2428, 167.7120],
  [-46.2411, 167.7113],
  [-46.2385, 167.7084],
  [-46.2380, 167.7065],
  [-46.2383, 167.7054],
  [-46.2391, 167.7039],
  [-46.2393, 167.7017],
  [-46.2372, 167.6958],
  [-46.2336, 167.6915],
  [-46.2323, 167.6889],
  [-46.2256, 167.6709],
  [-46.2248, 167.6696],
  [-46.2233, 167.6687],
  [-46.1867, 167.6689],
  [-46.1854, 167.6695],
  [-46.1693, 167.6855],
  [-46.1685, 167.6860],
  [-46.1676, 167.6859],
  [-46.1647, 167.6840],
  [-46.1637, 167.6839],
  [-46.1527, 167.6871],
  [-46.1499, 167.6889],
  [-46.1484, 167.6895],
  [-46.1348, 167.6900]
];

const tuatapereOrawia = [
  [-46.1348, 167.6900],
  [-46.1331, 167.6901],
  [-46.1319, 167.6909],
  [-46.1307, 167.6923],
  [-46.1286, 167.6952],
  [-46.1277, 167.6959],
  [-46.1199, 167.6979],
  [-46.1159, 167.6978],
  [-46.1128, 167.6986],
  [-46.1121, 167.6991],
  [-46.1114, 167.7006],
  [-46.1111, 167.7024],
  [-46.1126, 167.7172],
  [-46.1126, 167.7187],
  [-46.1116, 167.7240],
  [-46.1110, 167.7253],
  [-46.1094, 167.7268],
  [-46.1090, 167.7275],
  [-46.1088, 167.7283],
  [-46.1084, 167.7322],
  [-46.1054, 167.7433],
  [-46.1044, 167.7451],
  [-46.1000, 167.7513],
  [-46.0996, 167.7525],
  [-46.0996, 167.7536],
  [-46.0999, 167.7550],
  [-46.0998, 167.7560],
  [-46.0995, 167.7570],
  [-46.0957, 167.7620],
  [-46.0949, 167.7625],
  [-46.0943, 167.7628],
  [-46.0934, 167.7635],
  [-46.0893, 167.7675],
  [-46.0853, 167.7728],
  [-46.0849, 167.7737],
  [-46.0826, 167.7808],
  [-46.0824, 167.7817],
  [-46.0821, 167.7882],
  [-46.0818, 167.7900],
  [-46.0807, 167.7912],
  [-46.0797, 167.7915],
  [-46.0717, 167.7905],
  [-46.0624, 167.7873],
  [-46.0615, 167.7874],
  [-46.0583, 167.7897],
  [-46.0571, 167.7905]
];

export const tuatapere: Line = {
  name: 'Tuatapere',
  state: 'NZ',
  segments: [
    {
      segments: [thornburyRiverton],
      history: {
        opened: {
          date: '1879-06-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-15',
           status: 'closed'
        }]
      }
    },
    {
      segments: [rivertonColac],
      history: {
        opened: {
          date: '1881-07-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-07-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [colacRoundhill],
      history: {
        opened: {
          date: '1883-09-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-07-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [roundhillOrepuki],
      history: {
        opened: {
          date: '1885-05-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-07-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [orepukiWaihoaka],
      history: {
        opened: {
          date: '1903-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-07-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waihoakaTuatapere],
      history: {
        opened: {
          date: '1909-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-07-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tuatapereOrawia],
      history: {
        opened: {
          date: '1925-10-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-10-01',
          status: 'closed'
        }]
      }
    }
  ]
}
