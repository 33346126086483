import { Line } from "../../trackTypes";

const line = [
  [-41.2248, 174.8563],
  [-41.2244, 174.8584],
  [-41.2244, 174.8591],
  [-41.2248, 174.8604],
  [-41.2249, 174.8611],
  [-41.2249, 174.8621],
  [-41.2251, 174.8636],
  [-41.2252, 174.8649],
  [-41.2275, 174.8743],
  [-41.2289, 174.8788],
  [-41.2292, 174.8803],
  [-41.2313, 174.8876],
  [-41.2319, 174.8895],
  [-41.2323, 174.8956],
  [-41.2323, 174.8974]
];

export const huttPark: Line = {
  name: 'Hutt Park',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
