import { Line } from "../../trackTypes";

const junction = [
  [-21.1186, 149.1226],
  [-21.1193, 149.1250],
  [-21.1192, 149.1270],
  [-21.1183, 149.1292]
];

const line = [
  [-21.1237, 149.1291],
  [-21.1223, 149.1282],
  [-21.1212, 149.1280],
  [-21.1198, 149.1282],
  [-21.1183, 149.1292],
  [-21.1117, 149.1361],
  [-21.1099, 149.1389],
  [-21.1074, 149.1458],
  [-21.1069, 149.1497],
  [-21.1097, 149.1736],
  [-21.1104, 149.1755],
  [-21.1119, 149.1772],
  [-21.1129, 149.1791],
  [-21.1134, 149.1831],
  [-21.1133, 149.1842],
  [-21.1127, 149.1855],
  [-21.1100, 149.1886],
  [-21.1093, 149.1900],
  [-21.1090, 149.1916],
  [-21.1092, 149.1938],
  [-21.1091, 149.1967],
  [-21.1059, 149.2081],
  [-21.1059, 149.2092],
  [-21.1063, 149.2140],
  [-21.1069, 149.2155],
  [-21.1081, 149.2168]
];

export const mackayHarbour: Line = {
  name: 'Mackay Harbour',
  state: 'QLD',
  segments: [
    {
      segments: [line, junction],
      history: {
        opened: {
          date: '1939-07-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
