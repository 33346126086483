import { Line } from "../../../../trackTypes";

const stKildaRdPuntRd = [
  [-37.85006, 144.98059],
  [-37.85017, 144.98069],
  [-37.85023, 144.98086],
  [-37.85068, 144.98438]
];

const puntRoadTheAvenue = [
  [-37.85068, 144.98438],
  [-37.85256, 144.99852]
];

const glenIris = [
  [-37.85256, 144.99852],
  [-37.85659, 145.02911],
  [-37.85664, 145.02948],
  [-37.85815, 145.04109]
];

const glenIrisStation = [
  [-37.85815, 145.04109],
  [-37.86002, 145.05686]
];

const glenferrieRdJunction = [
  [-37.85659, 145.02911],
  [-37.85666, 145.02923],
  [-37.85677, 145.02926],
  [-37.85667, 145.02935],
  [-37.85664, 145.02948]
];

export const east6: Line = {
  name: 'East6',
  state: 'VIC',
  segments: [
    {
      segments: [stKildaRdPuntRd],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [puntRoadTheAvenue],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [glenIris, glenferrieRdJunction],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [glenIrisStation],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
