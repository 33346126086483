import { Line } from "../../trackTypes";

const brunswickJunction = [
  [-33.2456, 115.8443],
  [-33.2504, 115.8454],
  [-33.2515, 115.8462],
  [-33.2519, 115.8471],
  [-33.2521, 115.8486]
];

const brunswickWorsleyJunction = [
  [-33.2534, 115.8406],
  [-33.2516, 115.8428],
  [-33.2513, 115.8437],
  [-33.2514, 115.8447],
  [-33.2520, 115.8462],
  [-33.2522, 115.8469],
  [-33.2521, 115.8486],
  [-33.2528, 115.8529],
  [-33.2528, 115.8544],
  [-33.2519, 115.8585],
  [-33.2506, 115.8609],
  [-33.2497, 115.8639],
  [-33.2489, 115.8651],
  [-33.2479, 115.8655],
  [-33.2459, 115.8654],
  [-33.2448, 115.8660],
  [-33.2442, 115.8670],
  [-33.2437, 115.8685],
  [-33.2433, 115.8692],
  [-33.2418, 115.8711],
  [-33.2415, 115.8721],
  [-33.2417, 115.8732],
  [-33.2420, 115.8742],
  [-33.2417, 115.8757],
  [-33.2406, 115.8766],
  [-33.2400, 115.8776],
  [-33.2398, 115.8791],
  [-33.2392, 115.8800],
  [-33.2384, 115.8803],
  [-33.2375, 115.8803],
  [-33.2367, 115.8807],
  [-33.2361, 115.8817],
  [-33.2361, 115.8829],
  [-33.2367, 115.8837],
  [-33.2398, 115.8855],
  [-33.2404, 115.8865],
  [-33.2404, 115.8876],
  [-33.2397, 115.8888],
  [-33.2389, 115.8892],
  [-33.2376, 115.8891],
  [-33.2370, 115.8891],
  [-33.2357, 115.8901],
  [-33.2350, 115.8915],
  [-33.2351, 115.8939],
  [-33.2354, 115.8954],
  [-33.2351, 115.8965],
  [-33.2336, 115.8979],
  [-33.2327, 115.8980],
  [-33.2312, 115.8972],
  [-33.2304, 115.8961],
  [-33.2293, 115.8957],
  [-33.2282, 115.8962],
  [-33.2276, 115.8973],
  [-33.2278, 115.8985],
  [-33.2287, 115.8995],
  [-33.2292, 115.9006],
  [-33.2293, 115.9029],
  [-33.2299, 115.9056],
  [-33.2322, 115.9119],
  [-33.2332, 115.9133],
  [-33.2354, 115.9146],
  [-33.2361, 115.9154],
  [-33.2374, 115.9171],
  [-33.2392, 115.9180],
  [-33.2438, 115.9229],
  [-33.2442, 115.9236],
  [-33.2446, 115.9251],
  [-33.2446, 115.9272],
  [-33.2451, 115.9293],
  [-33.2452, 115.9306],
  [-33.2456, 115.9317],
  [-33.2463, 115.9323],
  [-33.2472, 115.9327],
  [-33.2478, 115.9332],
  [-33.2482, 115.9338],
  [-33.2488, 115.9343],
  [-33.2502, 115.9349],
  [-33.2516, 115.9368],
  [-33.2520, 115.9379],
  [-33.2537, 115.9477],
  [-33.2542, 115.9490],
  [-33.2561, 115.9515],
  [-33.2568, 115.9521],
  [-33.2577, 115.9526],
  [-33.2584, 115.9536],
  [-33.2594, 115.9569],
  [-33.2608, 115.9595],
  [-33.2623, 115.9620],
  [-33.2632, 115.9631],
  [-33.2639, 115.9652],
  [-33.2645, 115.9662],
  [-33.2654, 115.9669],
  [-33.2660, 115.9675],
  [-33.2665, 115.9684],
  [-33.2685, 115.9711],
  [-33.2689, 115.9721],
  [-33.2688, 115.9733],
  [-33.2675, 115.9768],
  [-33.2674, 115.9782],
  [-33.2680, 115.9796],
  [-33.2695, 115.9809],
  [-33.2701, 115.9819],
  [-33.2701, 115.9827],
  [-33.2699, 115.9840],
  [-33.2700, 115.9853],
  [-33.2704, 115.9864],
  [-33.2735, 115.9901],
  [-33.2746, 115.9907],
  [-33.2759, 115.9905],
  [-33.2768, 115.9908],
  [-33.2774, 115.9915],
  [-33.2779, 115.9927],
  [-33.2785, 115.9933],
  [-33.2806, 115.9948],
  [-33.2816, 115.9960],
  [-33.2824, 115.9965],
  [-33.2855, 115.9974],
  [-33.2900, 115.9992]
];

const worsleyMine = [
  [-33.2900, 115.9992],
  [-33.2911, 116.0005],
  [-33.2912, 116.0016],
  [-33.2909, 116.0030],
  [-33.2909, 116.0043],
  [-33.2919, 116.0083],
  [-33.2922, 116.0117],
  [-33.2918, 116.0164],
  [-33.2914, 116.0224],
  [-33.2914, 116.0224],
  [-33.2893, 116.0258],
  [-33.2872, 116.0264],
  [-33.2677, 116.0267],
  [-33.2655, 116.0273],
  [-33.2634, 116.0286],
  [-33.2593, 116.0324],
  [-33.2581, 116.0348],
  [-33.2546, 116.0501],
  [-33.2544, 116.0526],
  [-33.2555, 116.0640],
  [-33.2553, 116.0674],
  [-33.2513, 116.0799],
  [-33.2496, 116.0822],
  [-33.2467, 116.0828],
  [-33.2444, 116.0814],
  [-33.2352, 116.0709],
  [-33.2344, 116.0692],
  [-33.2342, 116.0681],
  [-33.2342, 116.0659],
  [-33.2343, 116.0646],
  [-33.2347, 116.0638],
  [-33.2358, 116.0624],
  [-33.2360, 116.0615],
  [-33.2359, 116.0603],
  [-33.2352, 116.0594],
  [-33.2343, 116.0591],
  [-33.2331, 116.0592],
  [-33.2323, 116.0596],
  [-33.2318, 116.0606],
  [-33.2318, 116.0618],
  [-33.2321, 116.0626],
  [-33.2335, 116.0642],
  [-33.2340, 116.0651],
  [-33.2342, 116.0659]
];

const worsleyOld = [
  [-33.2900, 115.9992],
  [-33.2912, 115.9998],
  [-33.2934, 116.0003],
  [-33.2960, 116.0004],
  [-33.2969, 116.0007],
  [-33.2986, 116.0019],
  [-33.3028, 116.0023],
  [-33.3049, 116.0031],
  [-33.3076, 116.0060],
  [-33.3087, 116.0063],
  [-33.3158, 116.0082],
  [-33.3170, 116.0091],
  [-33.3177, 116.0102],
  [-33.3184, 116.0123],
  [-33.3181, 116.0136],
  [-33.3171, 116.0145],
  [-33.3166, 116.0156],
  [-33.3169, 116.0182],
  [-33.3167, 116.0198],
  [-33.3167, 116.0209],
  [-33.3181, 116.0243],
  [-33.3183, 116.0261],
  [-33.3174, 116.0291],
  [-33.3165, 116.0308],
  [-33.3148, 116.0320],
  [-33.3138, 116.0334],
  [-33.3121, 116.0363],
  [-33.3119, 116.0372]
];

const worsleyNew = [
  [-33.2918, 116.0164],
  [-33.2920, 116.0175],
  [-33.2924, 116.0192],
  [-33.2924, 116.0203],
  [-33.2921, 116.0218],
  [-33.2924, 116.0229],
  [-33.2931, 116.0237],
  [-33.2939, 116.0239],
  [-33.2949, 116.0236],
  [-33.2958, 116.0231],
  [-33.2975, 116.0231],
  [-33.2988, 116.0242],
  [-33.2999, 116.0271],
  [-33.3015, 116.0297],
  [-33.3037, 116.0316],
  [-33.3047, 116.0319],
  [-33.3062, 116.0316],
  [-33.3073, 116.0322],
  [-33.3083, 116.0340],
  [-33.3092, 116.0346],
  [-33.3108, 116.0352],
  [-33.3116, 116.0361],
  [-33.3119, 116.0372]
];

const worsleyNothSouth = [
  [-33.2872, 116.0264],
  [-33.2887, 116.0264],
  [-33.2935, 116.0246],
  [-33.2947, 116.0237]
];

const worsleyCollieJunction = [
  [-33.3119, 116.0372],
  [-33.3134, 116.0422],
  [-33.3147, 116.0432],
  [-33.3170, 116.0430],
  [-33.3184, 116.0423],
  [-33.3200, 116.0426],
  [-33.3217, 116.0443],
  [-33.3243, 116.0456],
  [-33.3282, 116.0504],
  [-33.3290, 116.0509],
  [-33.3305, 116.0510],
  [-33.3313, 116.0515],
  [-33.3318, 116.0523],
  [-33.3321, 116.0542],
  [-33.3338, 116.0581],
  [-33.3345, 116.0610],
  [-33.3343, 116.0633],
  [-33.3330, 116.0656],
  [-33.3329, 116.0668],
  [-33.3335, 116.0686],
  [-33.3329, 116.0706],
  [-33.3320, 116.0712],
  [-33.3294, 116.0711],
  [-33.3284, 116.0719],
  [-33.3280, 116.0731],
  [-33.3289, 116.0950],
  [-33.3294, 116.0963],
  [-33.3301, 116.0971],
  [-33.3344, 116.0993],
  [-33.3355, 116.1002],
  [-33.3416, 116.1088],
  [-33.3438, 116.1149],
  [-33.3455, 116.1175],
  [-33.3464, 116.1195],
  [-33.3470, 116.1228],
  [-33.3475, 116.1240],
  [-33.3489, 116.1259],
  [-33.3511, 116.1345],
  [-33.3574, 116.1474],
  [-33.3603, 116.1563],
  [-33.3604, 116.1576]
];

const collieCollieMine = [
  [-33.3604, 116.1576],
  [-33.3603, 116.1595],
  [-33.3600, 116.1604],
  [-33.3583, 116.1630],
  [-33.3579, 116.1641],
  [-33.3575, 116.1675],
  [-33.3577, 116.1699],
  [-33.3573, 116.1737],
  [-33.3574, 116.1751],
  [-33.3581, 116.1763],
  [-33.3593, 116.1770],
  [-33.3618, 116.1775],
  [-33.3644, 116.1791],
  [-33.3653, 116.1803],
  [-33.3655, 116.1816],
  [-33.3653, 116.1854],
  [-33.3646, 116.1872],
  [-33.3640, 116.1881],
  [-33.3632, 116.1890],
  [-33.3627, 116.1900],
  [-33.3626, 116.1910],
  [-33.3626, 116.1927],
  [-33.3621, 116.1943],
  [-33.3623, 116.1963],
  [-33.3632, 116.1981],
  [-33.3635, 116.1994],
  [-33.3633, 116.2005],
  [-33.3630, 116.2018],
  [-33.3630, 116.2030],
  [-33.3636, 116.2043],
  [-33.3730, 116.2155],
  [-33.3736, 116.2173],
  [-33.3736, 116.2193],
  [-33.3740, 116.2206],
  [-33.3751, 116.2218],
  [-33.3763, 116.2223],
  [-33.3774, 116.2235],
  [-33.3787, 116.2265],
  [-33.3800, 116.2276],
  [-33.3807, 116.2290],
  [-33.3814, 116.2334],
  [-33.3811, 116.2349],
  [-33.3802, 116.2360],
  [-33.3787, 116.2369],
  [-33.3777, 116.2381],
  [-33.3765, 116.2387],
  [-33.3740, 116.2400],
  [-33.3714, 116.2431],
  [-33.3695, 116.2441],
  [-33.3681, 116.2441],
  [-33.3667, 116.2433],
  [-33.3633, 116.2399],
  [-33.3621, 116.2390],
  [-33.3580, 116.2373],
  [-33.3528, 116.2352],
  [-33.3515, 116.2353],
  [-33.3503, 116.2364],
  [-33.3497, 116.2385],
  [-33.3500, 116.2400],
  [-33.3513, 116.2413],
  [-33.3530, 116.2415],
  [-33.3546, 116.2403],
  [-33.3553, 116.2387],
  [-33.3561, 116.2378],
  [-33.3570, 116.2374],
  [-33.3580, 116.2373]
];

const collieDarkan = [
  [-33.3765, 116.2387],
  [-33.3755, 116.2398],
  [-33.3751, 116.2410],
  [-33.3748, 116.2473],
  [-33.3752, 116.2488],
  [-33.3778, 116.2530],
  [-33.3780, 116.2541],
  [-33.3780, 116.2552],
  [-33.3781, 116.2559],
  [-33.3785, 116.2570],
  [-33.3819, 116.2625],
  [-33.3840, 116.2703],
  [-33.3842, 116.2727],
  [-33.3833, 116.2772],
  [-33.3834, 116.2788],
  [-33.3844, 116.2815],
  [-33.3866, 116.2912],
  [-33.3871, 116.2967],
  [-33.3880, 116.2986],
  [-33.3919, 116.3022],
  [-33.3932, 116.3038],
  [-33.3935, 116.3062],
  [-33.3931, 116.3074],
  [-33.3914, 116.3087],
  [-33.3906, 116.3102],
  [-33.3906, 116.3120],
  [-33.3924, 116.3212],
  [-33.3934, 116.3229],
  [-33.4010, 116.3280],
  [-33.4027, 116.3303],
  [-33.4069, 116.3423],
  [-33.4089, 116.3589],
  [-33.4097, 116.3614],
  [-33.4214, 116.3815],
  [-33.4235, 116.3840],
  [-33.4244, 116.3864],
  [-33.4250, 116.3948],
  [-33.4248, 116.3969],
  [-33.4229, 116.4021],
  [-33.4222, 116.4032],
  [-33.4211, 116.4045],
  [-33.4205, 116.4061],
  [-33.4207, 116.4077],
  [-33.4220, 116.4105],
  [-33.4224, 116.4120],
  [-33.4223, 116.4140],
  [-33.4221, 116.4151],
  [-33.4213, 116.4172],
  [-33.4210, 116.4185],
  [-33.4215, 116.4201],
  [-33.4249, 116.4262],
  [-33.4255, 116.4283],
  [-33.4258, 116.4349],
  [-33.4255, 116.4369],
  [-33.4243, 116.4394],
  [-33.4240, 116.4423],
  [-33.4257, 116.4533],
  [-33.4258, 116.4561],
  [-33.4251, 116.4591],
  [-33.4213, 116.4679],
  [-33.4207, 116.4702],
  [-33.4206, 116.4731],
  [-33.4198, 116.4775],
  [-33.4198, 116.4791],
  [-33.4202, 116.4802],
  [-33.4213, 116.4821],
  [-33.4217, 116.4841],
  [-33.4216, 116.4914],
  [-33.4215, 116.4937],
  [-33.4206, 116.4985],
  [-33.4158, 116.5105],
  [-33.4150, 116.5118],
  [-33.4131, 116.5138],
  [-33.4121, 116.5155],
  [-33.4115, 116.5180],
  [-33.4108, 116.5200],
  [-33.4092, 116.5228],
  [-33.4076, 116.5243],
  [-33.3934, 116.5331],
  [-33.3920, 116.5345],
  [-33.3888, 116.5403],
  [-33.3880, 116.5422],
  [-33.3849, 116.5525],
  [-33.3846, 116.5546],
  [-33.3839, 116.5708],
  [-33.3836, 116.5724],
  [-33.3814, 116.5799],
  [-33.3802, 116.5820],
  [-33.3782, 116.5835],
  [-33.3762, 116.5841],
  [-33.3749, 116.5846],
  [-33.3731, 116.5847],
  [-33.3717, 116.5853],
  [-33.3708, 116.5870],
  [-33.3702, 116.5904],
  [-33.3703, 116.5922],
  [-33.3707, 116.5951],
  [-33.3702, 116.5981],
  [-33.3682, 116.6027],
  [-33.3672, 116.6084],
  [-33.3656, 116.6130],
  [-33.3640, 116.6150],
  [-33.3621, 116.6160],
  [-33.3606, 116.6174],
  [-33.3588, 116.6197],
  [-33.3581, 116.6211],
  [-33.3555, 116.6288],
  [-33.3554, 116.6304],
  [-33.3557, 116.6343],
  [-33.3563, 116.6359],
  [-33.3573, 116.6373],
  [-33.3578, 116.6392],
  [-33.3577, 116.6407],
  [-33.3569, 116.6437],
  [-33.3566, 116.6453],
  [-33.3571, 116.6473],
  [-33.3581, 116.6489],
  [-33.3589, 116.6509],
  [-33.3589, 116.6530],
  [-33.3584, 116.6550],
  [-33.3582, 116.6564],
  [-33.3583, 116.6585],
  [-33.3582, 116.6611],
  [-33.3583, 116.6631],
  [-33.3581, 116.6648],
  [-33.3582, 116.6669],
  [-33.3580, 116.6692],
  [-33.3574, 116.6708],
  [-33.3564, 116.6721],
  [-33.3551, 116.6728],
  [-33.3534, 116.6729],
  [-33.3527, 116.6731],
  [-33.3519, 116.6740],
  [-33.3513, 116.6751],
  [-33.3508, 116.6782],
  [-33.3510, 116.6801],
  [-33.3519, 116.6827],
  [-33.3519, 116.6851],
  [-33.3515, 116.6868],
  [-33.3505, 116.6884],
  [-33.3502, 116.6896],
  [-33.3501, 116.6911],
  [-33.3508, 116.6950],
  [-33.3507, 116.6967],
  [-33.3501, 116.6991],
  [-33.3502, 116.7009],
  [-33.3508, 116.7024],
  [-33.3519, 116.7033],
  [-33.3527, 116.7045],
  [-33.3530, 116.7057],
  [-33.3527, 116.7075],
  [-33.3519, 116.7086],
  [-33.3501, 116.7098],
  [-33.3487, 116.7114],
  [-33.3473, 116.7125],
  [-33.3466, 116.7134],
  [-33.3461, 116.7146],
  [-33.3455, 116.7155],
  [-33.3449, 116.7160],
  [-33.3438, 116.7164],
  [-33.3429, 116.7169],
  [-33.3423, 116.7177],
  [-33.3416, 116.7194],
  [-33.3408, 116.7206],
  [-33.3369, 116.7243],
  [-33.3357, 116.7260],
  [-33.3351, 116.7279],
  [-33.3348, 116.7317],
  [-33.3349, 116.7338],
  [-33.3351, 116.7351],
  [-33.3358, 116.7382],
  [-33.3360, 116.7396],
  [-33.3362, 116.7465],
  [-33.3358, 116.7483],
  [-33.3345, 116.7525],
  [-33.3345, 116.7547],
  [-33.3356, 116.7623]
];

const darkanNarrogin = [
  [-33.3356, 116.7623],
  [-33.3360, 116.7652],
  [-33.3357, 116.7668],
  [-33.3347, 116.7703],
  [-33.3346, 116.7723],
  [-33.3350, 116.7742],
  [-33.3347, 116.7765],
  [-33.3336, 116.7781],
  [-33.3309, 116.7802],
  [-33.3297, 116.7817],
  [-33.3285, 116.7842],
  [-33.3273, 116.7851],
  [-33.3248, 116.7854],
  [-33.3234, 116.7860],
  [-33.3223, 116.7872],
  [-33.3217, 116.7886],
  [-33.3210, 116.7926],
  [-33.3200, 116.7946],
  [-33.3186, 116.7958],
  [-33.3164, 116.7969],
  [-33.3149, 116.7983],
  [-33.3100, 116.8060],
  [-33.3085, 116.8073],
  [-33.2422, 116.8401],
  [-33.2324, 116.8471],
  [-33.2295, 116.8484],
  [-33.2269, 116.8484],
  [-33.2143, 116.8475],
  [-33.2123, 116.8480],
  [-33.2101, 116.8494],
  [-33.2078, 116.8501],
  [-33.2015, 116.8503],
  [-33.1993, 116.8497],
  [-33.1928, 116.8456],
  [-33.1909, 116.8454],
  [-33.1838, 116.8474],
  [-33.1782, 116.8507],
  [-33.1757, 116.8516],
  [-33.1742, 116.8516],
  [-33.1716, 116.8510],
  [-33.1700, 116.8498],
  [-33.1691, 116.8480],
  [-33.1639, 116.8435],
  [-33.1633, 116.8423],
  [-33.1627, 116.8389],
  [-33.1617, 116.8371],
  [-33.1587, 116.8353],
  [-33.1569, 116.8351],
  [-33.1550, 116.8356],
  [-33.1525, 116.8352],
  [-33.1496, 116.8346],
  [-33.1483, 116.8333],
  [-33.1440, 116.8243],
  [-33.1435, 116.8215],
  [-33.1443, 116.8162],
  [-33.1441, 116.8141],
  [-33.1430, 116.8125],
  [-33.1397, 116.8097],
  [-33.1379, 116.8070],
  [-33.1343, 116.7978],
  [-33.1319, 116.7954],
  [-33.1291, 116.7947],
  [-33.1248, 116.7958],
  [-33.1229, 116.7974],
  [-33.1119, 116.8182],
  [-33.1103, 116.8199],
  [-33.1065, 116.8224],
  [-33.1044, 116.8222],
  [-33.0975, 116.8171],
  [-33.0953, 116.8171],
  [-33.0832, 116.8229],
  [-33.0810, 116.8249],
  [-33.0774, 116.8290],
  [-33.0730, 116.8313],
  [-33.0717, 116.8327],
  [-33.0612, 116.8547],
  [-33.0595, 116.8561],
  [-33.0528, 116.8591],
  [-33.0508, 116.8613],
  [-33.0491, 116.8659],
  [-33.0478, 116.8675],
  [-33.0426, 116.8700],
  [-33.0415, 116.8711],
  [-33.0402, 116.8731],
  [-33.0388, 116.8744],
  [-33.0367, 116.8754],
  [-33.0354, 116.8772],
  [-33.0346, 116.8800],
  [-33.0337, 116.8818],
  [-33.0289, 116.8877],
  [-33.0264, 116.8891],
  [-33.0201, 116.8919],
  [-33.0181, 116.8941],
  [-33.0168, 116.8970],
  [-33.0165, 116.9001],
  [-33.0172, 116.9092],
  [-33.0171, 116.9111],
  [-33.0159, 116.9177],
  [-33.0119, 116.9292],
  [-33.0089, 116.9329],
  [-33.0044, 116.9405],
  [-33.0036, 116.9435],
  [-33.0037, 116.9500],
  [-33.0047, 116.9522],
  [-33.0062, 116.9535],
  [-33.0082, 116.9546],
  [-33.0092, 116.9562],
  [-33.0097, 116.9680],
  [-33.0093, 116.9650],
  [-33.0100, 116.9719],
  [-33.0099, 116.9867],
  [-33.0097, 116.9881],
  [-32.9903, 117.0433],
  [-32.9896, 117.0486],
  [-32.9876, 117.0557],
  [-32.9874, 117.0579],
  [-32.9880, 117.0600],
  [-32.9875, 117.0618],
  [-32.9850, 117.0671],
  [-32.9848, 117.0696],
  [-32.9856, 117.0717],
  [-32.9876, 117.0735],
  [-32.9882, 117.0758],
  [-32.9874, 117.0789],
  [-32.9874, 117.0812],
  [-32.9890, 117.0889],
  [-32.9885, 117.0918],
  [-32.9871, 117.0936],
  [-32.9860, 117.0970],
  [-32.9844, 117.0987],
  [-32.9823, 117.0997],
  [-32.9813, 117.1012],
  [-32.9813, 117.1032],
  [-32.9828, 117.1070],
  [-32.9832, 117.1099],
  [-32.9818, 117.1162],
  [-32.9817, 117.1210],
  [-32.9808, 117.1234],
  [-32.9791, 117.1254],
  [-32.9781, 117.1277],
  [-32.9777, 117.1328],
  [-32.9769, 117.1348],
  [-32.9758, 117.1368],
  [-32.9749, 117.1418],
  [-32.9756, 117.1441],
  [-32.9769, 117.1458],
  [-32.9776, 117.1483],
  [-32.9763, 117.1533],
  [-32.9746, 117.1549],
  [-32.9729, 117.1558],
  [-32.9720, 117.1568],
  [-32.9707, 117.1587],
  [-32.9687, 117.1602],
  [-32.9668, 117.1623],
  [-32.9653, 117.1633],
  [-32.9636, 117.1639],
  [-32.9626, 117.1648],
  [-32.9614, 117.1660],
  [-32.9601, 117.1666],
  [-32.9586, 117.1666],
  [-32.9568, 117.1660],
  [-32.9550, 117.1663],
  [-32.9539, 117.1673],
  [-32.9527, 117.1690],
  [-32.9514, 117.1696],
  [-32.9500, 117.1702],
  [-32.9485, 117.1723],
  [-32.9482, 117.1743],
  [-32.9475, 117.1757],
  [-32.9456, 117.1782],
  [-32.9423, 117.1810],
  [-32.9416, 117.1812],
  [-32.9402, 117.1813]
];

export const brunswickNarrogin: Line = {
  name: 'Brunswick - Narrogin',
  state: 'WA',
  segments: [
    {
      segments: [brunswickJunction],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [brunswickWorsleyJunction],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [worsleyMine],
      history: {
        opened: {
          date: '1984-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [worsleyNew],
      history: {
        opened: {
          date: '1984-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [worsleyNothSouth],
      history: {
        opened: {
          date: '1984-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [worsleyOld],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [worsleyCollieJunction],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [collieCollieMine],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [collieDarkan],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [darkanNarrogin],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
