import { Line } from "../../trackTypes";

const moreeBiniguy = [
  [-29.4799, 149.8492],
  [-29.4817, 149.8510],
  [-29.4829, 149.8531],
  [-29.4834, 149.8550],
  [-29.4875, 149.8941],
  [-29.4872, 149.8974],
  [-29.4855, 149.9022],
  [-29.4852, 149.9040],
  [-29.4853, 149.9059],
  [-29.4878, 149.9183],
  [-29.4896, 149.9280],
  [-29.4957, 149.9695],
  [-29.5004, 149.9867],
  [-29.5051, 150.0216],
  [-29.5058, 150.0242],
  [-29.5244, 150.0689],
  [-29.5477, 150.1022],
  [-29.5488, 150.1053],
  [-29.5516, 150.1245],
  [-29.5512, 150.1265],
  [-29.5503, 150.1280],
  [-29.5486, 150.1295],
  [-29.5474, 150.1313],
  [-29.5471, 150.1329],
  [-29.5484, 150.1422],
  [-29.5480, 150.1441],
  [-29.5470, 150.1456],
  [-29.5458, 150.1464],
  [-29.5400, 150.1478],
  [-29.5388, 150.1487],
  [-29.5381, 150.1503],
  [-29.5381, 150.1518],
  [-29.5386, 150.1530],
  [-29.5397, 150.1546],
  [-29.5401, 150.1555],
  [-29.5402, 150.1567],
  [-29.5397, 150.1604],
  [-29.5398, 150.1616],
  [-29.5405, 150.1629],
  [-29.5415, 150.1638],
  [-29.5444, 150.1646],
  [-29.5458, 150.1657],
  [-29.5467, 150.1672],
  [-29.5477, 150.1752],
  [-29.5475, 150.1772],
  [-29.5444, 150.1886],
  [-29.5435, 150.1901],
  [-29.5419, 150.1916],
  [-29.5409, 150.1935],
  [-29.5407, 150.1957],
  [-29.5419, 150.2042],
  [-29.5431, 150.2091]
];

const biniguyGravesend = [
  [-29.5431, 150.2091],
  [-29.5434, 150.2107],
  [-29.5435, 150.2117],
  [-29.5421, 150.2256],
  [-29.5413, 150.2275],
  [-29.5402, 150.2285],
  [-29.5389, 150.2293],
  [-29.5375, 150.2309],
  [-29.5355, 150.2362],
  [-29.5354, 150.2375],
  [-29.5362, 150.2394],
  [-29.5374, 150.2408],
  [-29.5377, 150.2419],
  [-29.5376, 150.2428],
  [-29.5371, 150.2438],
  [-29.5368, 150.2450],
  [-29.5370, 150.2459],
  [-29.5373, 150.2470],
  [-29.5372, 150.2478],
  [-29.5360, 150.2503],
  [-29.5358, 150.2517],
  [-29.5362, 150.2551],
  [-29.5360, 150.2563],
  [-29.5353, 150.2572],
  [-29.5323, 150.2588],
  [-29.5313, 150.2599],
  [-29.5294, 150.2663],
  [-29.5294, 150.2674],
  [-29.5297, 150.2692],
  [-29.5297, 150.2718],
  [-29.5303, 150.2737],
  [-29.5311, 150.2747],
  [-29.5340, 150.2768],
  [-29.5349, 150.2779],
  [-29.5380, 150.2830],
  [-29.5389, 150.2839],
  [-29.5468, 150.2885],
  [-29.5481, 150.2889],
  [-29.5552, 150.2898],
  [-29.5566, 150.2903],
  [-29.5669, 150.2958],
  [-29.5683, 150.2972],
  [-29.5708, 150.3030],
  [-29.5716, 150.3082],
  [-29.5721, 150.3098],
  [-29.5728, 150.3107],
  [-29.5746, 150.3122],
  [-29.5757, 150.3137],
  [-29.5767, 150.3158],
  [-29.5790, 150.3188],
  [-29.5795, 150.3209],
  [-29.5791, 150.3225],
  [-29.5779, 150.3240],
  [-29.5775, 150.3254],
  [-29.5778, 150.3267],
  [-29.5783, 150.3281],
  [-29.5783, 150.3289],
  [-29.5782, 150.3302],
  [-29.5787, 150.3317],
  [-29.5810, 150.3343]
];

const gravesendDelungra = [
  [-29.5810, 150.3343],
  [-29.5824, 150.3368],
  [-29.5825, 150.3379],
  [-29.5822, 150.3389],
  [-29.5815, 150.3407],
  [-29.5814, 150.3419],
  [-29.5819, 150.3429],
  [-29.5836, 150.3445],
  [-29.5842, 150.3463],
  [-29.5840, 150.3477],
  [-29.5809, 150.3531],
  [-29.5805, 150.3542],
  [-29.5801, 150.3569],
  [-29.5795, 150.3582],
  [-29.5772, 150.3600],
  [-29.5766, 150.3608],
  [-29.5747, 150.3672],
  [-29.5746, 150.3694],
  [-29.5760, 150.3753],
  [-29.5767, 150.3765],
  [-29.5776, 150.3774],
  [-29.5780, 150.3782],
  [-29.5782, 150.3792],
  [-29.5773, 150.3854],
  [-29.5775, 150.3872],
  [-29.5791, 150.3913],
  [-29.5793, 150.3926],
  [-29.5773, 150.3994],
  [-29.5772, 150.4005],
  [-29.5775, 150.4015],
  [-29.5786, 150.4032],
  [-29.5795, 150.4077],
  [-29.5792, 150.4109],
  [-29.5794, 150.4119],
  [-29.5801, 150.4129],
  [-29.5832, 150.4153],
  [-29.5838, 150.4165],
  [-29.5840, 150.4186],
  [-29.5844, 150.4204],
  [-29.5852, 150.4222],
  [-29.5854, 150.4239],
  [-29.5850, 150.4266],
  [-29.5852, 150.4285],
  [-29.5859, 150.4300],
  [-29.5891, 150.4335],
  [-29.5897, 150.4347],
  [-29.5899, 150.4363],
  [-29.5861, 150.4618],
  [-29.5862, 150.4650],
  [-29.5897, 150.4783],
  [-29.5906, 150.4800],
  [-29.5954, 150.4855],
  [-29.5956, 150.4874],
  [-29.5948, 150.4888],
  [-29.5920, 150.4899],
  [-29.5913, 150.4905],
  [-29.5908, 150.4916],
  [-29.5900, 150.4955],
  [-29.5882, 150.4999],
  [-29.5874, 150.5007],
  [-29.5865, 150.5010],
  [-29.5848, 150.5011],
  [-29.5832, 150.5016],
  [-29.5822, 150.5016],
  [-29.5811, 150.5016],
  [-29.5800, 150.5018],
  [-29.5788, 150.5029],
  [-29.5783, 150.5041],
  [-29.5780, 150.5112],
  [-29.5773, 150.5129],
  [-29.5772, 150.5145],
  [-29.5779, 150.5157],
  [-29.5798, 150.5164],
  [-29.5832, 150.5209],
  [-29.5848, 150.5219],
  [-29.5855, 150.5228],
  [-29.5856, 150.5243],
  [-29.5841, 150.5292],
  [-29.5804, 150.5440],
  [-29.5804, 150.5449],
  [-29.5809, 150.5459],
  [-29.5846, 150.5501],
  [-29.5857, 150.5510],
  [-29.5861, 150.5520],
  [-29.5860, 150.5532],
  [-29.5855, 150.5543],
  [-29.5854, 150.5555],
  [-29.5865, 150.5583],
  [-29.5866, 150.5596],
  [-29.5862, 150.5603],
  [-29.5853, 150.5616],
  [-29.5849, 150.5626],
  [-29.5851, 150.5635],
  [-29.5857, 150.5648],
  [-29.5858, 150.5655],
  [-29.5855, 150.5675],
  [-29.5857, 150.5685],
  [-29.5864, 150.5695],
  [-29.5883, 150.5709],
  [-29.5887, 150.5714],
  [-29.5924, 150.5794],
  [-29.5934, 150.5836],
  [-29.5937, 150.5844],
  [-29.5943, 150.5858],
  [-29.5945, 150.5877],
  [-29.5943, 150.5889],
  [-29.5940, 150.5902],
  [-29.5938, 150.5912],
  [-29.5941, 150.5931],
  [-29.5939, 150.5942],
  [-29.5936, 150.5947],
  [-29.5929, 150.5956],
  [-29.5925, 150.5965],
  [-29.5925, 150.5974],
  [-29.5936, 150.6021],
  [-29.5947, 150.6052],
  [-29.5949, 150.6065],
  [-29.5948, 150.6083],
  [-29.5949, 150.6092],
  [-29.5960, 150.6113],
  [-29.5961, 150.6127],
  [-29.5959, 150.6142],
  [-29.5959, 150.6159],
  [-29.5995, 150.6310],
  [-29.5999, 150.6319],
  [-29.6007, 150.6332],
  [-29.6012, 150.6349],
  [-29.6018, 150.6357],
  [-29.6046, 150.6373],
  [-29.6125, 150.6380],
  [-29.6176, 150.6404],
  [-29.6193, 150.6406],
  [-29.6204, 150.6415],
  [-29.6215, 150.6447],
  [-29.6218, 150.6465],
  [-29.6229, 150.6482],
  [-29.6258, 150.6498],
  [-29.6267, 150.6508],
  [-29.6278, 150.6600],
  [-29.6290, 150.6626],
  [-29.6294, 150.6651],
  [-29.6290, 150.6666],
  [-29.6278, 150.6690],
  [-29.6278, 150.6707],
  [-29.6284, 150.6717],
  [-29.6300, 150.6723],
  [-29.6307, 150.6729],
  [-29.6319, 150.6748],
  [-29.6320, 150.6757],
  [-29.6319, 150.6773],
  [-29.6322, 150.6786],
  [-29.6334, 150.6804],
  [-29.6336, 150.6816],
  [-29.6331, 150.6827],
  [-29.6322, 150.6837],
  [-29.6316, 150.6846],
  [-29.6315, 150.6865],
  [-29.6318, 150.6879],
  [-29.6329, 150.6906],
  [-29.6331, 150.6919],
  [-29.6323, 150.6970],
  [-29.6313, 150.6992],
  [-29.6308, 150.7011],
  [-29.6303, 150.7021],
  [-29.6277, 150.7042],
  [-29.6269, 150.7053],
  [-29.6266, 150.7066],
  [-29.6264, 150.7083],
  [-29.6259, 150.7097],
  [-29.6218, 150.7154],
  [-29.6211, 150.7171],
  [-29.6209, 150.7249],
  [-29.6219, 150.7299],
  [-29.6230, 150.7324],
  [-29.6234, 150.7342],
  [-29.6233, 150.7366],
  [-29.6245, 150.7431],
  [-29.6251, 150.7444],
  [-29.6257, 150.7455],
  [-29.6260, 150.7465],
  [-29.6259, 150.7475],
  [-29.6251, 150.7498],
  [-29.6248, 150.7511],
  [-29.6253, 150.7554],
  [-29.6267, 150.7580],
  [-29.6284, 150.7589],
  [-29.6305, 150.7589],
  [-29.6328, 150.7599],
  [-29.6340, 150.7617],
  [-29.6356, 150.7695],
  [-29.6370, 150.7719],
  [-29.6385, 150.7733],
  [-29.6412, 150.7750],
  [-29.6424, 150.7765],
  [-29.6430, 150.7781],
  [-29.6451, 150.7955],
  [-29.6473, 150.8057],
  [-29.6484, 150.8078],
  [-29.6498, 150.8091],
  [-29.6507, 150.8112],
  [-29.6510, 150.8134],
  [-29.6535, 150.8203]
];

const delungraInverell = [
  [-29.6535, 150.8203],
  [-29.6553, 150.8281],
  [-29.6562, 150.8306],
  [-29.6568, 150.8342],
  [-29.6568, 150.8373],
  [-29.6572, 150.8397],
  [-29.6584, 150.8427],
  [-29.6585, 150.8443],
  [-29.6582, 150.8457],
  [-29.6575, 150.8476],
  [-29.6574, 150.8489],
  [-29.6588, 150.8572],
  [-29.6589, 150.8612],
  [-29.6585, 150.8628],
  [-29.6566, 150.8672],
  [-29.6555, 150.8693],
  [-29.6551, 150.8707],
  [-29.6549, 150.8724],
  [-29.6543, 150.8737],
  [-29.6532, 150.8749],
  [-29.6527, 150.8761],
  [-29.6528, 150.8773],
  [-29.6537, 150.8791],
  [-29.6539, 150.8804],
  [-29.6536, 150.8833],
  [-29.6532, 150.8847],
  [-29.6532, 150.8871],
  [-29.6539, 150.8886],
  [-29.6554, 150.8892],
  [-29.6563, 150.8889],
  [-29.6582, 150.8877],
  [-29.6612, 150.8874],
  [-29.6636, 150.8885],
  [-29.6660, 150.8920],
  [-29.6668, 150.8949],
  [-29.6681, 150.8964],
  [-29.6699, 150.8971],
  [-29.6710, 150.8981],
  [-29.6726, 150.9029],
  [-29.6736, 150.9076],
  [-29.6736, 150.9096],
  [-29.6731, 150.9134],
  [-29.6733, 150.9149],
  [-29.6741, 150.9162],
  [-29.6756, 150.9171],
  [-29.6764, 150.9186],
  [-29.6766, 150.9199],
  [-29.6760, 150.9229],
  [-29.6762, 150.9250],
  [-29.6774, 150.9276],
  [-29.6777, 150.9297],
  [-29.6770, 150.9315],
  [-29.6731, 150.9370],
  [-29.6727, 150.9389],
  [-29.6732, 150.9405],
  [-29.6743, 150.9414],
  [-29.6758, 150.9426],
  [-29.6765, 150.9441],
  [-29.6765, 150.9468],
  [-29.6779, 150.9509],
  [-29.6790, 150.9527],
  [-29.6806, 150.9538],
  [-29.6813, 150.9555],
  [-29.6810, 150.9572],
  [-29.6799, 150.9594],
  [-29.6797, 150.9619],
  [-29.6810, 150.9670],
  [-29.6819, 150.9685],
  [-29.6834, 150.9697],
  [-29.6843, 150.9710],
  [-29.6845, 150.9731],
  [-29.6852, 150.9744],
  [-29.6865, 150.9754],
  [-29.6874, 150.9768],
  [-29.6877, 150.9788],
  [-29.6891, 150.9813],
  [-29.6921, 150.9834],
  [-29.6954, 150.9835],
  [-29.6967, 150.9840],
  [-29.7035, 150.9896],
  [-29.7161, 151.0038],
  [-29.7173, 151.0046],
  [-29.7187, 151.0047],
  [-29.7240, 151.0037],
  [-29.7260, 151.0043],
  [-29.7349, 151.0122],
  [-29.7355, 151.0133],
  [-29.7359, 151.0153],
  [-29.7369, 151.0173],
  [-29.7370, 151.0185],
  [-29.7365, 151.0202],
  [-29.7365, 151.0213],
  [-29.7370, 151.0225],
  [-29.7383, 151.0239],
  [-29.7385, 151.0249],
  [-29.7382, 151.0261],
  [-29.7375, 151.0273],
  [-29.7372, 151.0284],
  [-29.7376, 151.0304],
  [-29.7372, 151.0316],
  [-29.7364, 151.0325],
  [-29.7355, 151.0335],
  [-29.7352, 151.0345],
  [-29.7351, 151.0362],
  [-29.7343, 151.0375],
  [-29.7323, 151.0393],
  [-29.7311, 151.0432],
  [-29.7315, 151.0466],
  [-29.7331, 151.0490],
  [-29.7368, 151.0512],
  [-29.7386, 151.0538],
  [-29.7394, 151.0566],
  [-29.7436, 151.0622],
  [-29.7454, 151.0637],
  [-29.7462, 151.0665],
  [-29.7445, 151.0705],
  [-29.7430, 151.0718],
  [-29.7391, 151.0740],
  [-29.7383, 151.0750],
  [-29.7381, 151.0765],
  [-29.7388, 151.0780],
  [-29.7405, 151.0792],
  [-29.7409, 151.0803],
  [-29.7407, 151.0817],
  [-29.7399, 151.0828],
  [-29.7395, 151.0839],
  [-29.7397, 151.0851],
  [-29.7405, 151.0868],
  [-29.7402, 151.0884],
  [-29.7394, 151.0896],
  [-29.7386, 151.0902],
  [-29.7379, 151.0910],
  [-29.7359, 151.0957],
  [-29.7359, 151.0969],
  [-29.7364, 151.0979],
  [-29.7376, 151.0985],
  [-29.7393, 151.0987],
  [-29.7404, 151.0993],
  [-29.7409, 151.1005],
  [-29.7406, 151.1019],
  [-29.7391, 151.1038],
  [-29.7387, 151.1051],
  [-29.7391, 151.1066],
  [-29.7400, 151.1074],
  [-29.7431, 151.1085],
  [-29.7442, 151.1084],
  [-29.7453, 151.1074],
  [-29.7460, 151.1051],
  [-29.7466, 151.1041],
  [-29.7477, 151.1037],
  [-29.7485, 151.1039],
  [-29.7499, 151.1049],
  [-29.7510, 151.1051],
  [-29.7534, 151.1047],
  [-29.7546, 151.1048],
  [-29.7556, 151.1055],
  [-29.7567, 151.1069],
  [-29.7583, 151.1078],
  [-29.7599, 151.1079],
  [-29.7607, 151.1083],
  [-29.7614, 151.1091],
  [-29.7616, 151.1104],
  [-29.7613, 151.1116],
  [-29.7613, 151.1127],
  [-29.7625, 151.1195]
];

export const inverell: Line = {
  name: 'Inverell',
  state: 'NSW',
  segments: [
    {
      segments: [moreeBiniguy],
      history: {
        opened: {
          date: '1900-02-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-06-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [biniguyGravesend],
      history: {
        opened: {
          date: '1900-02-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-08-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gravesendDelungra],
      history: {
        opened: {
          date: '1901-11-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-08-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [delungraInverell],
      history: {
        opened: {
          date: '1902-03-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-12-02',
          status: 'closed'
        }]
      }
    }
  ]
};
