import { Line } from "../../../trackTypes";

const line = [
  [-24.8843, 113.6564],
  [-24.8838, 113.6556],
  [-24.8838, 113.6548],
  [-24.8852, 113.6484],
  [-24.8852, 113.6476],
  [-24.8849, 113.6470],
  [-24.8845, 113.6465],
  [-24.8832, 113.6455],
  [-24.8829, 113.6451],
  [-24.8822, 113.6439],
  [-24.8812, 113.6423],
  [-24.8800, 113.6410],
  [-24.8782, 113.6395],
  [-24.8762, 113.6381],
  [-24.8757, 113.6377],
  [-24.8753, 113.6371],
  [-24.8750, 113.6361],
  [-24.8752, 113.6318],
  [-24.8754, 113.6303],
  [-24.8803, 113.6173],
  [-24.8806, 113.6168],
  [-24.8812, 113.6164]
];

export const carnarvonTrams: Line = {
  name: 'Carnarvon Trams',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1900-11-09',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1965-12-01',
          status: 'closed'
        }]
      }
    }
  ]
};
