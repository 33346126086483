import { Line } from "../../../trackTypes";

const wolliCreekKingsgrove = [
  [-33.92775, 151.1540],
  [-33.9285, 151.1524],
  [-33.9286, 151.1504],
  [-33.9281, 151.1473],
  [-33.9281, 151.1460],
  [-33.9284, 151.1446],
  [-33.9317, 151.1339],
  [-33.9319, 151.1325],
  [-33.9317, 151.1310],
  [-33.9310, 151.1287],
  [-33.9310, 151.1270],
  [-33.9313, 151.1253],
  [-33.9327, 151.1225],
  [-33.9335, 151.1203],
  [-33.9344, 151.1189],
  [-33.9359, 151.1172],
  [-33.9369, 151.1157],
  [-33.9374, 151.1139],
  [-33.9382, 151.1070],
  [-33.9389, 151.1043],
  [-33.9406, 151.1004]
];

const kingsgroveRiverwood = [
  [-33.9406, 151.1004],
  [-33.9427, 151.0956],
  [-33.9447, 151.0929],
  [-33.9458, 151.0911],
  [-33.9495, 151.0794],
  [-33.9498, 151.0773],
  [-33.9496, 151.0753],
  [-33.9475, 151.0703],
  [-33.9473, 151.0684],
  [-33.9474, 151.0667],
  [-33.9515, 151.0518]
];

const riverwoodReversby = [
  [-33.9515, 151.0518],
  [-33.9525, 151.0481],
  [-33.9526, 151.0463],
  [-33.9517, 151.0388],
  [-33.9525, 151.0149]
];

const reversbyEastHills = [
  [-33.9525, 151.0149],
  [-33.9526, 151.0116],
  [-33.9544, 150.9962],
  [-33.9553, 150.9930],
  [-33.9572, 150.9897],
  [-33.9604, 150.9862],
  [-33.9617, 150.9846]
];

const eastHillsGlenfieldJunction = [
  [-33.9617, 150.9846],
  [-33.9628, 150.9833],
  [-33.9640, 150.9810],
  [-33.9650, 150.9776],
  [-33.9652, 150.9741],
  [-33.9633, 150.9596],
  [-33.9631, 150.9554],
  [-33.9643, 150.9366],
  [-33.9654, 150.9182],
  [-33.9653, 150.9155],
  [-33.9643, 150.9061],
  [-33.9646, 150.9033],
  [-33.9653, 150.9013],
  [-33.9665, 150.8993]
];

export const easternHills: Line = {
  name: 'Eastern Hills',
  state: 'NSW',
  segments: [
    {
      segments: [wolliCreekKingsgrove],
      history: {
        opened: {
          date: '1931-09-21',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2000-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [kingsgroveRiverwood],
      history: {
        opened: {
          date: '1931-12-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-01-01',
          tracks: 2
        }, {
          date: '1939-12-17',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '2013-04-15',
          tracks: 4
        }]
      }
    },
    {
      segments: [riverwoodReversby],
      history: {
        opened: {
          date: '1931-12-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1939-12-17',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1985-01-01',
          tracks: 2
        }, {
          date: '2013-04-15',
          tracks: 4
        }]
      }
    },
    {
      segments: [reversbyEastHills],
      history: {
        opened: {
          date: '1931-12-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1939-12-17',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1985-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [eastHillsGlenfieldJunction],
      history: {
        opened: {
          date: '1987-12-21',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
