import { Line } from "../../trackTypes";

const burrenMerrywinebone = [
  [-30.1045, 148.9549],
  [-30.1040, 148.9537],
  [-30.1031, 148.9528],
  [-30.1019, 148.9522],
  [-30.1007, 148.9522],
  [-30.0984, 148.9526],
  [-30.0971, 148.9529],
  [-30.0887, 148.9556],
  [-30.0581, 148.9608],
  [-30.0554, 148.9606],
  [-30.0528, 148.9597],
  [-30.0072, 148.9312],
  [-29.9661, 148.9015],
  [-29.9630, 148.9005],
  [-29.9606, 148.9009],
  [-29.9437, 148.9075],
  [-29.9393, 148.9084],
  [-29.8274, 148.9126],
  [-29.8227, 148.9119],
  [-29.7975, 148.9034],
  [-29.7954, 148.9024],
  [-29.7048, 148.8397],
  [-29.7018, 148.8368],
  [-29.6820, 148.8107]
];

const merrywinebonePokataroo = [
  [-29.6820, 148.8107],
  [-29.6797, 148.8081],
  [-29.6570, 148.7893],
  [-29.6541, 148.7860],
  [-29.6418, 148.7619],
  [-29.5974, 148.7059],
  [-29.5853, 148.6947]
];

export const pokataroo: Line = {
  name: 'Pokataroo',
  state: 'NSW',
  segments: [
    {
      segments: [burrenMerrywinebone],
      history: {
        opened: {
          date: '1906-12-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [merrywinebonePokataroo],
      history: {
        opened: {
          date: '1906-12-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-01-08',
          status: 'closed'
        }]
      }
    }
  ]
};
