import { Line } from "../../../trackTypes";

const annandaleHaberfield = [
  [-33.8837, 151.1571],
  [-33.8842, 151.1500],
  [-33.8841, 151.1438],
  [-33.8844, 151.1420],
  [-33.8787, 151.1374]
];

const haberfieldFiveDock = [
  [-33.8787, 151.1374],
  [-33.8725, 151.1325],
  [-33.8708, 151.1318],
  [-33.8705, 151.1316],
  [-33.8692, 151.1295],
  [-33.8663, 151.1301],
  [-33.8639, 151.1303],
  [-33.8633, 151.1303]
];

const fiveDockAbbortsford = [
  [-33.8633, 151.1303],
  [-33.8594, 151.1310],
  [-33.8583, 151.1310],
  [-33.8576, 151.1309],
  [-33.8532, 151.1298],
  [-33.8497, 151.1281],
  [-33.8472, 151.1283],
  [-33.8464, 151.1284],
  [-33.8453, 151.1285]
];

const lilyfield = [
  [-33.8797, 151.1774],
  [-33.8797, 151.1769],
  [-33.8798, 151.1767],
  [-33.8806, 151.1765],
  [-33.8808, 151.1757],
  [-33.8810, 151.1754],
  [-33.8827, 151.1746],
  [-33.8803, 151.1675],
  [-33.8799, 151.1671],
  [-33.8781, 151.1596],
  [-33.8774, 151.1599],
  [-33.8756, 151.1610],
  [-33.8751, 151.1608]
];

const birchgrove = [
  [-33.8564, 151.1780],
  [-33.8544, 151.1788],
  [-33.8529, 151.1813],
  [-33.8520, 151.1806],
  [-33.8500, 151.1835]
];

const glebePoint = [
  [-33.8845, 151.1930],
  [-33.8807, 151.1878],
  [-33.8744, 151.1827]
];

const glebePointExtension = [
  [-33.8744, 151.1827],
  [-33.8733, 151.1819]
];

const drummoyne = [
  [-33.8638, 151.1700],
  [-33.8622, 151.1675],
  [-33.8609, 151.1645],
  [-33.8602, 151.1631],
  [-33.8582, 151.1610],
  [-33.8570, 151.1601],
  [-33.8504, 151.1539],
  [-33.8462, 151.1512],
  [-33.8458, 151.1503],
  [-33.8455, 151.1497],
  [-33.8452, 151.1495],
  [-33.8449, 151.1488],
  [-33.8447, 151.1479],
  [-33.8448, 151.1475],
  [-33.8451, 151.1465],
  [-33.8451, 151.1462],
  [-33.8448, 151.1455],
  [-33.8437, 151.1447]
];

const gladesvilleBridgeHattonsFlat = [
  [-33.8437, 151.1447],
  [-33.8414, 151.1423],
  [-33.8412, 151.1418],
  [-33.8411, 151.1412],
  [-33.8410, 151.1409],
  [-33.8407, 151.1404],
  [-33.8394, 151.1395],
  [-33.8397, 151.1386],
  [-33.8401, 151.1368],
  [-33.8399, 151.1358],
  [-33.8376, 151.1325],
  [-33.8362, 151.1287],
  [-33.8358, 151.1282],
  [-33.8330, 151.1269],
  [-33.8296, 151.1267],
  [-33.8256, 151.1260],
  [-33.8250, 151.1257],
  [-33.8242, 151.1245],
  [-33.8226, 151.1218],
  [-33.8201, 151.1185],
  [-33.8192, 151.1164],
  [-33.8167, 151.1120],
  [-33.8165, 151.1112],
  [-33.8165, 151.1099],
  [-33.8136, 151.1058],
  [-33.8125, 151.1045],
  [-33.8122, 151.1045],
  [-33.8117, 151.1046],
  [-33.8107, 151.1055]
];

const hattonsFlatWestRydeStation = [
  [-33.8136, 151.1058],
  [-33.8158, 151.1035],
  [-33.8151, 151.0991],
  [-33.8135, 151.0963],
  [-33.8111, 151.0944],
  [-33.8107, 151.0939],
  [-33.8097, 151.0934],
  [-33.8094, 151.0931],
  [-33.8081, 151.0905],
  [-33.8067, 151.0909]
];

const parramattaRoad = [
  [-33.8828, 151.2041],
  [-33.8839, 151.2022],
  [-33.8842, 151.2012],
  [-33.8845, 151.1930],
  [-33.8847, 151.1917],
  [-33.8847, 151.1905],
  [-33.8846, 151.1878],
  [-33.8848, 151.1859],
  [-33.8857, 151.1810],
  [-33.8872, 151.1745],
  [-33.8876, 151.1718],
  [-33.8882, 151.1632],
  [-33.8877, 151.1607],
  [-33.8878, 151.1596],
  [-33.8882, 151.1576]
];

const parramattaRoadShortStreet = [
  [-33.8882, 151.1576],
  [-33.8837, 151.1571],
  [-33.8820, 151.1568]
];

const shortStreetDarleyRoad = [
  [-33.8820, 151.1568],
  [-33.8773, 151.1562],
  [-33.8750, 151.1560]
];

const balmainConnection = [
  [-33.8750, 151.1560],
  [-33.8736, 151.1559],
  [-33.8734, 151.1551],
  [-33.8726, 151.1555],
  [-33.8723, 151.1559],
  [-33.8712, 151.1608],
  [-33.8661, 151.1663],
  [-33.8638, 151.1700]
];

const balmain = [
  [-33.8847, 151.1917],
  [-33.8839, 151.1907],
  [-33.8838, 151.1873],
  [-33.8823, 151.1850],
  [-33.8832, 151.1831],
  [-33.8814, 151.1817],
  [-33.8804, 151.1797],
  [-33.8797, 151.1774],
  [-33.8793, 151.1772],
  [-33.8765, 151.1762],
  [-33.8762, 151.1755],
  [-33.8756, 151.1750],
  [-33.8730, 151.1750],
  [-33.8724, 151.1747],
  [-33.8716, 151.1735],
  [-33.8712, 151.1731],
  [-33.8710, 151.1729],
  [-33.8690, 151.1764],
  [-33.8679, 151.1759],
  [-33.8669, 151.1753],
  [-33.8661, 151.1741],
  [-33.8659, 151.1729],
  [-33.8656, 151.1724],
  [-33.8638, 151.1700],
  [-33.8607, 151.1718],
  [-33.8581, 151.1721],
  [-33.8567, 151.1726],
  [-33.8565, 151.1728],
  [-33.8563, 151.1732],
  [-33.8559, 151.1745],
  [-33.8561, 151.1769],
  [-33.8564, 151.1780],
  [-33.8568, 151.1796],
  [-33.8575, 151.1809],
  [-33.8578, 151.1827],
  [-33.8582, 151.1840],
  [-33.8581, 151.1888],
  [-33.8570, 151.1956]
];

const prymont = [
  [-33.8839, 151.2022],
  [-33.8815, 151.2007],
  [-33.8808, 151.2003],
  [-33.8793, 151.1995],
  [-33.8697, 151.1939],
  [-33.8675, 151.1927],
  [-33.8672, 151.1935]
];

const glebeBalmainConnector = [
  [-33.8697, 151.1939],
  [-33.8708, 151.1907],
  [-33.8697, 151.1885],
  [-33.8689, 151.1877],
  [-33.8686, 151.1872],
  [-33.8675, 151.1834],
  [-33.8676, 151.1828],
  [-33.8680, 151.1817],
  [-33.8678, 151.1808],
  [-33.8680, 151.1793],
  [-33.8690, 151.1764]
];

const ultimoTramDepo = [
  [-33.8828, 151.2041],
  [-33.8814, 151.2032],
  [-33.8807, 151.2028],
  [-33.8815, 151.2007]
];

export const sydneyTramsWest: Line = {
  name: 'Sydney Trams (West)',
  state: 'NSW',
  segments: [
    {
      segments: [annandaleHaberfield],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-04-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [haberfieldFiveDock],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-04-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fiveDockAbbortsford],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-04-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [drummoyne],
      history: {
        opened: {
          date: '1902-12-28',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gladesvilleBridgeHattonsFlat],
      history: {
        opened: {
          date: '1910-06-12',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-12-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hattonsFlatWestRydeStation],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-12-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glebePoint],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-04-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glebePointExtension],
      history: {
        opened: {
          date: '1896-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1900-04-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [birchgrove],
      history: {
        opened: {
          date: '1908-01-01',//???
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glebeBalmainConnector],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lilyfield],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [prymont],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ultimoTramDepo],
      history: {
        opened: {
          date: '1899-12-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-06-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parramattaRoad],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parramattaRoadShortStreet],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [shortStreetDarleyRoad],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balmain],
      history: {
        opened: {
          date: '1892-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1902-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balmainConnection],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1954-11-18',
          status: 'closed'
        }]
      }
    }
  ]
};
