import { Line } from "../../trackTypes";

const theebineDickabram = [
  [-25.9475, 152.5443],
  [-25.9480, 152.5438],
  [-25.9482, 152.5432],
  [-25.9480, 152.5420],
  [-25.9482, 152.5409],
  [-25.9504, 152.5379],
  [-25.9507, 152.5373],
  [-25.9513, 152.5352],
  [-25.9513, 152.5343],
  [-25.9511, 152.5297],
  [-25.9507, 152.5284],
  [-25.9501, 152.5275],
  [-25.9489, 152.5266],
  [-25.9482, 152.5256],
  [-25.9466, 152.5225],
  [-25.9459, 152.5217],
  [-25.9457, 152.5212],
  [-25.9461, 152.5199],
  [-25.9469, 152.5193],
  [-25.9474, 152.5187],
  [-25.9481, 152.5169],
  [-25.9486, 152.5162],
  [-25.9529, 152.5121],
  [-25.9534, 152.5111],
  [-25.9545, 152.5042],
  [-25.9545, 152.5032],
  [-25.9535, 152.5000]
];

const dickabramKilkivan = [
  [-25.9535, 152.5000],
  [-25.9534, 152.4990],
  [-25.9535, 152.4971],
  [-25.9542, 152.4944],
  [-25.9547, 152.4937],
  [-25.9555, 152.4935],
  [-25.9565, 152.4935],
  [-25.9575, 152.4930],
  [-25.9590, 152.4905],
  [-25.9596, 152.4899],
  [-25.9632, 152.4880],
  [-25.9714, 152.4864],
  [-25.9725, 152.4853],
  [-25.9732, 152.4839],
  [-25.9739, 152.4830],
  [-25.9751, 152.4825],
  [-25.9770, 152.4823],
  [-25.9777, 152.4822],
  [-25.9789, 152.4811],
  [-25.9796, 152.4806],
  [-25.9805, 152.4802],
  [-25.9814, 152.4797],
  [-25.9818, 152.4784],
  [-25.9820, 152.4769],
  [-25.9834, 152.4742],
  [-25.9844, 152.4720],
  [-25.9850, 152.4714],
  [-25.9866, 152.4706],
  [-25.9873, 152.4700],
  [-25.9892, 152.4673],
  [-25.9900, 152.4666],
  [-25.9916, 152.4659],
  [-25.9924, 152.4652],
  [-25.9939, 152.4625],
  [-25.9941, 152.4611],
  [-25.9939, 152.4592],
  [-25.9939, 152.4581],
  [-25.9943, 152.4565],
  [-25.9941, 152.4554],
  [-25.9935, 152.4543],
  [-25.9934, 152.4537],
  [-25.9936, 152.4529],
  [-25.9936, 152.4522],
  [-25.9932, 152.4511],
  [-25.9933, 152.4503],
  [-25.9939, 152.4494],
  [-25.9940, 152.4487],
  [-25.9939, 152.4468],
  [-25.9942, 152.4450],
  [-25.9944, 152.4445],
  [-25.9951, 152.4438],
  [-25.9953, 152.4433],
  [-25.9956, 152.4419],
  [-25.9964, 152.4404],
  [-25.9968, 152.4400],
  [-25.9973, 152.4398],
  [-26.0016, 152.4393],
  [-26.0029, 152.4395],
  [-26.0035, 152.4394],
  [-26.0052, 152.4381],
  [-26.0058, 152.4375],
  [-26.0064, 152.4361],
  [-26.0071, 152.4354],
  [-26.0081, 152.4349],
  [-26.0102, 152.4345],
  [-26.0109, 152.4348],
  [-26.0115, 152.4352],
  [-26.0122, 152.4357],
  [-26.0132, 152.4360],
  [-26.0155, 152.4357],
  [-26.0159, 152.4354],
  [-26.0162, 152.4349],
  [-26.0162, 152.4336],
  [-26.0157, 152.4317],
  [-26.0157, 152.4301],
  [-26.0159, 152.4293],
  [-26.0159, 152.4284],
  [-26.0162, 152.4275],
  [-26.0162, 152.4269],
  [-26.0159, 152.4259],
  [-26.0159, 152.4251],
  [-26.0168, 152.4231],
  [-26.0175, 152.4225],
  [-26.0182, 152.4224],
  [-26.0208, 152.4228],
  [-26.0216, 152.4226],
  [-26.0224, 152.4220],
  [-26.0282, 152.4198],
  [-26.0288, 152.4192],
  [-26.0291, 152.4180],
  [-26.0290, 152.4168],
  [-26.0290, 152.4162],
  [-26.0294, 152.4156],
  [-26.0309, 152.4144],
  [-26.0331, 152.4137],
  [-26.0350, 152.4134],
  [-26.0373, 152.4121],
  [-26.0378, 152.4115],
  [-26.0387, 152.4085],
  [-26.0391, 152.4079],
  [-26.0398, 152.4071],
  [-26.0408, 152.4046],
  [-26.0417, 152.4037],
  [-26.0440, 152.4030],
  [-26.0446, 152.4023],
  [-26.0447, 152.4015],
  [-26.0449, 152.4010],
  [-26.0459, 152.3999],
  [-26.0465, 152.3997],
  [-26.0472, 152.4000],
  [-26.0478, 152.3999],
  [-26.0483, 152.3994],
  [-26.0484, 152.3986],
  [-26.0475, 152.3967],
  [-26.0475, 152.3961],
  [-26.0477, 152.3956],
  [-26.0485, 152.3951],
  [-26.0504, 152.3934],
  [-26.0514, 152.3919],
  [-26.0523, 152.3915],
  [-26.0529, 152.3913],
  [-26.0536, 152.3909],
  [-26.0558, 152.3885],
  [-26.0561, 152.3878],
  [-26.0561, 152.3868],
  [-26.0555, 152.3856],
  [-26.0553, 152.3845],
  [-26.0561, 152.3815],
  [-26.0569, 152.3802],
  [-26.0591, 152.3780],
  [-26.0599, 152.3768],
  [-26.0608, 152.3764],
  [-26.0613, 152.3759],
  [-26.0617, 152.3751],
  [-26.0614, 152.3706],
  [-26.0615, 152.3699],
  [-26.0619, 152.3695],
  [-26.0628, 152.3692],
  [-26.0632, 152.3688],
  [-26.0633, 152.3681],
  [-26.0631, 152.3672],
  [-26.0632, 152.3664],
  [-26.0657, 152.3611],
  [-26.0659, 152.3599],
  [-26.0656, 152.3590],
  [-26.0655, 152.3582],
  [-26.0658, 152.3572],
  [-26.0685, 152.3542],
  [-26.0688, 152.3530],
  [-26.0685, 152.3511],
  [-26.0686, 152.3502],
  [-26.0703, 152.3480],
  [-26.0708, 152.3477],
  [-26.0715, 152.3476],
  [-26.0721, 152.3473],
  [-26.0730, 152.3462],
  [-26.0730, 152.3456],
  [-26.0727, 152.3443],
  [-26.0727, 152.3439],
  [-26.0729, 152.3434],
  [-26.0744, 152.3422],
  [-26.0748, 152.3414],
  [-26.0748, 152.3403],
  [-26.0741, 152.3384],
  [-26.0741, 152.3378],
  [-26.0744, 152.3372],
  [-26.0757, 152.3363],
  [-26.0760, 152.3357],
  [-26.0762, 152.3341],
  [-26.0763, 152.3329],
  [-26.0773, 152.3298],
  [-26.0772, 152.3287],
  [-26.0770, 152.3281],
  [-26.0769, 152.3276],
  [-26.0772, 152.3270],
  [-26.0778, 152.3264],
  [-26.0783, 152.3256],
  [-26.0786, 152.3242],
  [-26.0782, 152.3225],
  [-26.0767, 152.3204],
  [-26.0743, 152.3184],
  [-26.0739, 152.3177],
  [-26.0739, 152.3171],
  [-26.0741, 152.3162],
  [-26.0740, 152.3155],
  [-26.0735, 152.3147],
  [-26.0723, 152.3138],
  [-26.0714, 152.3130],
  [-26.0695, 152.3100],
  [-26.0691, 152.3084],
  [-26.0695, 152.3052],
  [-26.0691, 152.3033],
  [-26.0682, 152.3016],
  [-26.0622, 152.2970],
  [-26.0620, 152.2964],
  [-26.0622, 152.2957],
  [-26.0641, 152.2938],
  [-26.0645, 152.2926],
  [-26.0641, 152.2897],
  [-26.0633, 152.2888],
  [-26.0613, 152.2877],
  [-26.0606, 152.2865],
  [-26.0596, 152.2798],
  [-26.0597, 152.2784],
  [-26.0625, 152.2704],
  [-26.0629, 152.2699],
  [-26.0633, 152.2696],
  [-26.0648, 152.2695],
  [-26.0652, 152.2693],
  [-26.0659, 152.2687],
  [-26.0685, 152.2644],
  [-26.0688, 152.2630],
  [-26.0689, 152.2600],
  [-26.0692, 152.2587],
  [-26.0724, 152.2513],
  [-26.0735, 152.2491],
  [-26.0774, 152.2439],
  [-26.0783, 152.2432],
  [-26.0814, 152.2417],
  [-26.0826, 152.2407],
  [-26.0838, 152.2389],
  [-26.0843, 152.2370],
  [-26.0842, 152.2352]
];

const kilkivanGoomeri = [
  [-26.0842, 152.2352],
  [-26.0836, 152.2297],
  [-26.0837, 152.2280],
  [-26.0864, 152.2221],
  [-26.0918, 152.2159],
  [-26.0932, 152.2152],
  [-26.1053, 152.2131],
  [-26.1066, 152.2122],
  [-26.1077, 152.2101],
  [-26.1094, 152.2087],
  [-26.1120, 152.2083],
  [-26.1134, 152.2074],
  [-26.1152, 152.2039],
  [-26.1154, 152.2027],
  [-26.1154, 152.2007],
  [-26.1188, 152.1911],
  [-26.1192, 152.1885],
  [-26.1192, 152.1828],
  [-26.1201, 152.1755],
  [-26.1211, 152.1733],
  [-26.1243, 152.1694],
  [-26.1249, 152.1676],
  [-26.1248, 152.1660],
  [-26.1234, 152.1618],
  [-26.1234, 152.1609],
  [-26.1239, 152.1599],
  [-26.1270, 152.1569],
  [-26.1277, 152.1555],
  [-26.1278, 152.1540],
  [-26.1276, 152.1512],
  [-26.1280, 152.1490],
  [-26.1296, 152.1466],
  [-26.1374, 152.1404],
  [-26.1391, 152.1401],
  [-26.1466, 152.1429],
  [-26.1484, 152.1428],
  [-26.1501, 152.1415],
  [-26.1512, 152.1398],
  [-26.1528, 152.1388],
  [-26.1546, 152.1385],
  [-26.1559, 152.1377],
  [-26.1633, 152.1276],
  [-26.1641, 152.1272],
  [-26.1650, 152.1275],
  [-26.1654, 152.1284],
  [-26.1655, 152.1316],
  [-26.1659, 152.1329],
  [-26.1668, 152.1339],
  [-26.1680, 152.1341],
  [-26.1689, 152.1333],
  [-26.1701, 152.1297],
  [-26.1713, 152.1281],
  [-26.1727, 152.1276],
  [-26.1750, 152.1286],
  [-26.1758, 152.1287],
  [-26.1772, 152.1287],
  [-26.1786, 152.1292],
  [-26.1792, 152.1291],
  [-26.1797, 152.1286],
  [-26.1803, 152.1274],
  [-26.1818, 152.1255],
  [-26.1824, 152.1253],
  [-26.1832, 152.1255],
  [-26.1844, 152.1264],
  [-26.1856, 152.1266],
  [-26.1865, 152.1261],
  [-26.1867, 152.1252],
  [-26.1866, 152.1244],
  [-26.1868, 152.1236],
  [-26.1876, 152.1231],
  [-26.1889, 152.1233],
  [-26.1903, 152.1240],
  [-26.1910, 152.1240],
  [-26.1919, 152.1235],
  [-26.1926, 152.1235],
  [-26.1943, 152.1239],
  [-26.1951, 152.1236],
  [-26.1954, 152.1229],
  [-26.1959, 152.1166],
  [-26.1962, 152.1151],
  [-26.1968, 152.1137],
  [-26.1969, 152.1121],
  [-26.1965, 152.1096],
  [-26.1967, 152.1063],
  [-26.1972, 152.1050],
  [-26.1990, 152.1026],
  [-26.1993, 152.1017],
  [-26.1992, 152.1005],
  [-26.1982, 152.0985],
  [-26.1976, 152.0978],
  [-26.1963, 152.0970],
  [-26.1956, 152.0962],
  [-26.1951, 152.0951],
  [-26.1942, 152.0847],
  [-26.1938, 152.0837],
  [-26.1928, 152.0829],
  [-26.1912, 152.0824],
  [-26.1902, 152.0816],
  [-26.1893, 152.0802],
  [-26.1882, 152.0791],
  [-26.1858, 152.0779],
  [-26.1848, 152.0771],
  [-26.1838, 152.0752],
  [-26.1833, 152.0746],
  [-26.1827, 152.0742],
  [-26.1823, 152.0737],
  [-26.1820, 152.0730],
  [-26.1816, 152.0689]
];

const goomeriMurgon = [
  [-26.1816, 152.0689],
  [-26.1818, 152.0675],
  [-26.1825, 152.0664],
  [-26.1834, 152.0655],
  [-26.1851, 152.0645],
  [-26.1863, 152.0642],
  [-26.1869, 152.0638],
  [-26.1873, 152.0632],
  [-26.1893, 152.0563],
  [-26.1899, 152.0554],
  [-26.1909, 152.0547],
  [-26.1965, 152.0533],
  [-26.1975, 152.0525],
  [-26.1982, 152.0513],
  [-26.1981, 152.0501],
  [-26.1976, 152.0493],
  [-26.1956, 152.0477],
  [-26.1946, 152.0462],
  [-26.1936, 152.0422],
  [-26.1928, 152.0406],
  [-26.1925, 152.0393],
  [-26.1924, 152.0369],
  [-26.1925, 152.0359],
  [-26.1929, 152.0351],
  [-26.1940, 152.0337],
  [-26.1951, 152.0320],
  [-26.1958, 152.0312],
  [-26.1985, 152.0290],
  [-26.2008, 152.0280],
  [-26.2015, 152.0280],
  [-26.2027, 152.0281],
  [-26.2036, 152.0280],
  [-26.2047, 152.0272],
  [-26.2066, 152.0242],
  [-26.2080, 152.0196],
  [-26.2086, 152.0185],
  [-26.2088, 152.0177],
  [-26.2087, 152.0165],
  [-26.2088, 152.0157],
  [-26.2091, 152.0151],
  [-26.2105, 152.0135],
  [-26.2124, 152.0122],
  [-26.2142, 152.0116],
  [-26.2153, 152.0107],
  [-26.2164, 152.0085],
  [-26.2165, 152.0075],
  [-26.2165, 152.0059],
  [-26.2168, 152.0047],
  [-26.2181, 152.0035],
  [-26.2189, 152.0025],
  [-26.2204, 152.0017],
  [-26.2217, 152.0018],
  [-26.2228, 152.0015],
  [-26.2240, 152.0005],
  [-26.2255, 152.0001],
  [-26.2266, 151.9992],
  [-26.2279, 151.9971],
  [-26.2286, 151.9947],
  [-26.2308, 151.9889],
  [-26.2329, 151.9853],
  [-26.2337, 151.9832],
  [-26.2344, 151.9819],
  [-26.2388, 151.9764],
  [-26.2391, 151.9751],
  [-26.2382, 151.9681],
  [-26.2375, 151.9655],
  [-26.2375, 151.9639],
  [-26.2386, 151.9602],
  [-26.2385, 151.9589],
  [-26.2374, 151.9572],
  [-26.2370, 151.9555],
  [-26.2374, 151.9538],
  [-26.2383, 151.9525],
  [-26.2403, 151.9511],
  [-26.2411, 151.9497],
  [-26.2409, 151.9484],
  [-26.2399, 151.9464],
  [-26.2397, 151.9449],
  [-26.2401, 151.9439],
  [-26.2467, 151.9377]
];

const murgonKingaroy = [
  [-26.2467, 151.9377],
  [-26.2485, 151.9360],
  [-26.2492, 151.9345],
  [-26.2498, 151.9314],
  [-26.2497, 151.9298],
  [-26.2491, 151.9280],
  [-26.2493, 151.9267],
  [-26.2501, 151.9254],
  [-26.2508, 151.9232],
  [-26.2514, 151.9226],
  [-26.2525, 151.9221],
  [-26.2536, 151.9213],
  [-26.2561, 151.9193],
  [-26.2573, 151.9188],
  [-26.2587, 151.9187],
  [-26.2595, 151.9182],
  [-26.2602, 151.9171],
  [-26.2641, 151.9141],
  [-26.2647, 151.9138],
  [-26.2673, 151.9139],
  [-26.2680, 151.9142],
  [-26.2720, 151.9167],
  [-26.2737, 151.9173],
  [-26.2755, 151.9174],
  [-26.2796, 151.9166],
  [-26.2805, 151.9158],
  [-26.2808, 151.9142],
  [-26.2796, 151.9111],
  [-26.2761, 151.9058],
  [-26.2758, 151.9049],
  [-26.2762, 151.9035],
  [-26.2780, 151.9014],
  [-26.2801, 151.9003],
  [-26.2826, 151.9001],
  [-26.2861, 151.8990],
  [-26.2926, 151.8982],
  [-26.2934, 151.8977],
  [-26.2940, 151.8968],
  [-26.2949, 151.8921],
  [-26.2958, 151.8902],
  [-26.2972, 151.8884],
  [-26.2984, 151.8875],
  [-26.3013, 151.8861],
  [-26.3025, 151.8854],
  [-26.3068, 151.8815],
  [-26.3080, 151.8810],
  [-26.3091, 151.8809],
  [-26.3107, 151.8812],
  [-26.3122, 151.8810],
  [-26.3140, 151.8798],
  [-26.3200, 151.8699],
  [-26.3211, 151.8673],
  [-26.3226, 151.8620],
  [-26.3236, 151.8597],
  [-26.3255, 151.8576],
  [-26.3337, 151.8522],
  [-26.3366, 151.8492],
  [-26.3382, 151.8467],
  [-26.3397, 151.8447],
  [-26.3417, 151.8433],
  [-26.3487, 151.8404],
  [-26.3497, 151.8403],
  [-26.3505, 151.8407],
  [-26.3513, 151.8409],
  [-26.3521, 151.8406],
  [-26.3555, 151.8382],
  [-26.3563, 151.8371],
  [-26.3572, 151.8350],
  [-26.3579, 151.8342],
  [-26.3591, 151.8336],
  [-26.3596, 151.8330],
  [-26.3598, 151.8321],
  [-26.3596, 151.8307],
  [-26.3598, 151.8297],
  [-26.3606, 151.8282],
  [-26.3608, 151.8273],
  [-26.3607, 151.8261],
  [-26.3612, 151.8250],
  [-26.3629, 151.8230],
  [-26.3636, 151.8218],
  [-26.3639, 151.8207],
  [-26.3645, 151.8199],
  [-26.3656, 151.8196],
  [-26.3671, 151.8199],
  [-26.3688, 151.8196],
  [-26.3709, 151.8182],
  [-26.3716, 151.8180],
  [-26.3726, 151.8183],
  [-26.3738, 151.8196],
  [-26.3748, 151.8200],
  [-26.3761, 151.8198],
  [-26.3772, 151.8199],
  [-26.3818, 151.8217],
  [-26.3827, 151.8218],
  [-26.3834, 151.8214],
  [-26.3868, 151.8173],
  [-26.3885, 151.8166],
  [-26.3909, 151.8173],
  [-26.3938, 151.8198],
  [-26.3949, 151.8205],
  [-26.3965, 151.8207],
  [-26.4089, 151.8161],
  [-26.4127, 151.8157],
  [-26.4152, 151.8158],
  [-26.4190, 151.8175],
  [-26.4204, 151.8176],
  [-26.4214, 151.8174],
  [-26.4269, 151.8138],
  [-26.4298, 151.8116],
  [-26.4319, 151.8113],
  [-26.4336, 151.8122],
  [-26.4362, 151.8154],
  [-26.4385, 151.8177],
  [-26.4421, 151.8197],
  [-26.4497, 151.8221],
  [-26.4573, 151.8219],
  [-26.4573, 151.8219],
  [-26.4692, 151.8167],
  [-26.4733, 151.8163],
  [-26.4765, 151.8147],
  [-26.4786, 151.8126],
  [-26.4804, 151.8114],
  [-26.4829, 151.8111],
  [-26.4862, 151.8121],
  [-26.4877, 151.8131],
  [-26.4891, 151.8151],
  [-26.4899, 151.8178],
  [-26.4911, 151.8201],
  [-26.4946, 151.8234],
  [-26.4972, 151.8239],
  [-26.5068, 151.8223],
  [-26.5096, 151.8228],
  [-26.5156, 151.8266],
  [-26.5165, 151.8286],
  [-26.5169, 151.8311],
  [-26.5176, 151.8323],
  [-26.5189, 151.8332],
  [-26.5334, 151.8352],
  [-26.5352, 151.8358],
  [-26.5386, 151.8392]
];

const kingaroyNanango = [
  [-26.5386, 151.8392],
  [-26.5409, 151.8413],
  [-26.5415, 151.8417],
  [-26.5443, 151.8431],
  [-26.5512, 151.8450],
  [-26.5528, 151.8456],
  [-26.5615, 151.8511],
  [-26.5634, 151.8531],
  [-26.5701, 151.8645],
  [-26.5844, 151.8854],
  [-26.5860, 151.8890],
  [-26.5874, 151.8950],
  [-26.5870, 151.8966],
  [-26.5840, 151.8990],
  [-26.5834, 151.9003],
  [-26.5836, 151.9017],
  [-26.5868, 151.9057],
  [-26.5884, 151.9062],
  [-26.5966, 151.9053],
  [-26.6007, 151.9058],
  [-26.6033, 151.9071],
  [-26.6099, 151.9095],
  [-26.6109, 151.9103],
  [-26.6148, 151.9182],
  [-26.6178, 151.9224],
  [-26.6187, 151.9233],
  [-26.6195, 151.9233],
  [-26.6205, 151.9232],
  [-26.6216, 151.9237],
  [-26.6252, 151.9294],
  [-26.6271, 151.9345],
  [-26.6288, 151.9382],
  [-26.6314, 151.9401],
  [-26.6323, 151.9415],
  [-26.6321, 151.9453],
  [-26.6331, 151.9476],
  [-26.6342, 151.9486],
  [-26.6461, 151.9520],
  [-26.6507, 151.9546],
  [-26.6526, 151.9559],
  [-26.6538, 151.9576],
  [-26.6579, 151.9692],
  [-26.6586, 151.9759],
  [-26.6594, 151.9778],
  [-26.6625, 151.9812],
  [-26.6636, 151.9829],
  [-26.6643, 151.9914],
  [-26.6641, 151.9960],
  [-26.6645, 151.9977],
  [-26.6673, 152.0016],
  [-26.6683, 152.0023],
  [-26.6693, 152.0025],
  [-26.6743, 152.0021]
];

const kingaroyTarong = [
  [-26.5404, 151.8408],
  [-26.5416, 151.8413],
  [-26.5426, 151.8413],
  [-26.5698, 151.8368],
  [-26.5789, 151.8325],
  [-26.5870, 151.8244],
  [-26.6082, 151.8096],
  [-26.6089, 151.8087],
  [-26.6096, 151.8069],
  [-26.6103, 151.8060],
  [-26.6205, 151.8022],
  [-26.6218, 151.8024],
  [-26.6245, 151.8034],
  [-26.6288, 151.8055],
  [-26.6311, 151.8058],
  [-26.6359, 151.8080],
  [-26.6375, 151.8081],
  [-26.6409, 151.8071],
  [-26.6422, 151.8069],
  [-26.6550, 151.8076],
  [-26.6562, 151.8081],
  [-26.6687, 151.8159],
  [-26.6708, 151.8162],
  [-26.6718, 151.8158],
  [-26.6758, 151.8131],
  [-26.6780, 151.8121],
  [-26.6902, 151.8103],
  [-26.6949, 151.8072],
  [-26.7009, 151.8051],
  [-26.7085, 151.8041],
  [-26.7136, 151.8044],
  [-26.7289, 151.8043],
  [-26.7310, 151.8051],
  [-26.7366, 151.8096],
  [-26.7383, 151.8119],
  [-26.7436, 151.8288],
  [-26.7461, 151.8331],
  [-26.7479, 151.8344],
  [-26.7506, 151.8350],
  [-26.7519, 151.8357],
  [-26.7545, 151.8385],
  [-26.7560, 151.8395],
  [-26.7590, 151.8400]
];

export const nanango: Line = {
  name: 'Nanango',
  state: 'QLD',
  segments: [
    {
      segments: [theebineDickabram],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dickabramKilkivan],
      history: {
        opened: {
          date: '1886-12-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kilkivanGoomeri],
      history: {
        opened: {
          date: '1902-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goomeriMurgon],
      history: {
        opened: {
          date: '1903-09-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [murgonKingaroy],
      history: {
        opened: {
          date: '1904-12-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingaroyNanango],
      history: {
        opened: {
          date: '1911-11-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingaroyTarong],
      history: {
        opened: {
          date: '1915-12-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-07-01',
          status: 'closed'
        }]
      }
    }
  ]
}
