import { Line } from "../../trackTypes";

const castlemaineMaldonJunction = [
  [-37.0695, 144.2154],
  [-37.0699, 144.2155],
  [-37.0704, 144.2156],
  [-37.0710, 144.2154],
  [-37.0715, 144.2152],
  [-37.0720, 144.2148],
  [-37.0723, 144.2142],
  [-37.0725, 144.2137],
  [-37.0725, 144.2131],
  [-37.0725, 144.2126],
  [-37.0723, 144.2114],
  [-37.0723, 144.2111],
  [-37.0723, 144.2106],
  [-37.0725, 144.2100],
  [-37.0731, 144.2083]
];

export const moolort: Line = {
  name: 'Moolort',
  state: 'VIC',
  segments: [
    {
      segments: [castlemaineMaldonJunction],
      history: {
        opened: {
          date: '1874-07-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    }
  ]
}
