import { Line } from "../../../../trackTypes";

const wattlePark = [
  [-37.83135, 145.05653],
  [-37.83421, 145.08148],
  [-37.83430, 145.08374],
  [-37.83595, 145.09807]
];

const wattleParkExtension = [
  [-37.83595, 145.09807],
  [-37.83616, 145.09985],
  [-37.83660, 145.10217],
  [-37.83668, 145.10270],
  [-37.83764, 145.11083]
];

export const east13: Line = {
  name: 'East13',
  state: 'VIC',
  segments: [
    {
      segments: [wattlePark],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [wattleParkExtension],
      history: {
        opened: {
          date: '1928-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
