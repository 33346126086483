import { Line } from "../../../trackTypes";

const koumalaCoppabella = [
  [-21.5309, 149.2341],
  [-21.5326, 149.2339],
  [-21.5340, 149.2331],
  [-21.5485, 149.2160],
  [-21.5502, 149.2144],
  [-21.5549, 149.2118],
  [-21.5612, 149.2075],
  [-21.5645, 149.2069],
  [-21.5665, 149.2074],
  [-21.5691, 149.2068],
  [-21.5730, 149.2034],
  [-21.5741, 149.2028],
  [-21.5754, 149.2029],
  [-21.5767, 149.2035],
  [-21.5781, 149.2035],
  [-21.5797, 149.2030],
  [-21.5805, 149.2029],
  [-21.5819, 149.2033],
  [-21.5828, 149.2033],
  [-21.5851, 149.2023],
  [-21.5861, 149.2014],
  [-21.5868, 149.1995],
  [-21.5870, 149.1981],
  [-21.5866, 149.1967],
  [-21.5867, 149.1954],
  [-21.5877, 149.1938],
  [-21.5895, 149.1933],
  [-21.5909, 149.1936],
  [-21.5922, 149.1936],
  [-21.5987, 149.1913],
  [-21.5999, 149.1905],
  [-21.6024, 149.1869],
  [-21.6089, 149.1838],
  [-21.6098, 149.1829],
  [-21.6104, 149.1814],
  [-21.6124, 149.1793],
  [-21.6138, 149.1787],
  [-21.6151, 149.1789],
  [-21.6163, 149.1796],
  [-21.6178, 149.1798],
  [-21.6197, 149.1791],
  [-21.6211, 149.1778],
  [-21.6227, 149.1773],
  [-21.6239, 149.1777],
  [-21.6250, 149.1787],
  [-21.6263, 149.1796],
  [-21.6279, 149.1811],
  [-21.6288, 149.1814],
  [-21.6304, 149.1818],
  [-21.6335, 149.1842],
  [-21.6351, 149.1845],
  [-21.6366, 149.1839],
  [-21.6378, 149.1838],
  [-21.6395, 149.1842],
  [-21.6409, 149.1839],
  [-21.6442, 149.1810],
  [-21.6457, 149.1774],
  [-21.6458, 149.1744],
  [-21.6489, 149.1625],
  [-21.6489, 149.1596],
  [-21.6458, 149.1499],
  [-21.6455, 149.1470],
  [-21.6459, 149.1437],
  [-21.6454, 149.1381],
  [-21.6456, 149.1361],
  [-21.6513, 149.1170],
  [-21.6515, 149.1146],
  [-21.6510, 149.1122],
  [-21.6375, 149.0881],
  [-21.6320, 149.0685],
  [-21.6322, 149.0650],
  [-21.6394, 149.0454],
  [-21.6401, 149.0344],
  [-21.6408, 149.0328],
  [-21.6431, 149.0304],
  [-21.6439, 149.0289],
  [-21.6449, 149.0249],
  [-21.6457, 149.0233],
  [-21.6633, 149.0020],
  [-21.6662, 148.9993],
  [-21.6828, 148.9907],
  [-21.6844, 148.9900],
  [-21.6873, 148.9897],
  [-21.6898, 148.9900],
  [-21.6940, 148.9918],
  [-21.6968, 148.9925],
  [-21.7001, 148.9918],
  [-21.7069, 148.9870],
  [-21.7113, 148.9807],
  [-21.7138, 148.9788],
  [-21.7329, 148.9711],
  [-21.7400, 148.9661],
  [-21.7491, 148.9625],
  [-21.7659, 148.9529],
  [-21.7680, 148.9525],
  [-21.7704, 148.9515],
  [-21.7717, 148.9496],
  [-21.7721, 148.9470],
  [-21.7690, 148.9336],
  [-21.7690, 148.9316],
  [-21.7697, 148.9299],
  [-21.7780, 148.9182],
  [-21.7785, 148.9152],
  [-21.7777, 148.8885],
  [-21.7773, 148.8868],
  [-21.7706, 148.8732],
  [-21.7700, 148.8710],
  [-21.7698, 148.8611],
  [-21.7712, 148.8480],
  [-21.7707, 148.8121],
  [-21.7680, 148.8036],
  [-21.7681, 148.7977],
  [-21.7698, 148.7913],
  [-21.7704, 148.7856],
  [-21.7855, 148.7425],
  [-21.8076, 148.6927],
  [-21.8085, 148.6895],
  [-21.8092, 148.6825],
  [-21.8100, 148.6805],
  [-21.8400, 148.6335],
  [-21.8419, 148.6292],
  [-21.8537, 148.5746],
  [-21.8532, 148.5710],
  [-21.8470, 148.5581],
  [-21.8462, 148.5539],
  [-21.8470, 148.5498],
  [-21.8537, 148.5353],
  [-21.8563, 148.5213],
  [-21.8624, 148.4889]
];

const coppabellaMineOld = [
  [-21.8624, 148.4889],
  [-21.8587, 148.4651],
  [-21.8597, 148.4610],
  [-21.8697, 148.4394]
];

const coppabellaMineDeviation = [
  [-21.8624, 148.4889],
  [-21.8710, 148.4660],
  [-21.8711, 148.4557],
  [-21.8690, 148.4480],
  [-21.8686, 148.4440],
  [-21.8697, 148.4394]
];

const coppabellaMineCoppabella = [
  [-21.8697, 148.4394],
  [-21.9017, 148.3763],
  [-21.9022, 148.3736],
  [-21.9022, 148.3661],
  [-21.9027, 148.3634],
  [-21.9050, 148.3590]
];

const coppabellaBroadlea = [
  [-21.9050, 148.3590],
  [-21.9161, 148.3367],
  [-21.9182, 148.3342],
  [-21.9216, 148.3325],
  [-21.9230, 148.3311],
  [-21.9244, 148.3283],
  [-21.9309, 148.3199],
  [-21.9316, 148.3182],
  [-21.9316, 148.3149],
  [-21.9311, 148.3132],
  [-21.9214, 148.2988],
  [-21.9192, 148.2920],
  [-21.9195, 148.2837],
  [-21.9180, 148.2720],
  [-21.9182, 148.2697],
  [-21.9207, 148.2614],
  [-21.9320, 148.2378],
  [-21.9375, 148.2300],
  [-21.9393, 148.2259],
  [-21.9423, 148.2097],
  [-21.9418, 148.2054],
  [-21.9391, 148.2000],
  [-21.9378, 148.1951],
  [-21.9372, 148.1730]
];

const broadleaWotonga = [
  [-21.9372, 148.1730],
  [-21.9376, 148.1696],
  [-21.9415, 148.1520],
  [-21.9418, 148.1473],
  [-21.9410, 148.1336],
  [-21.9377, 148.1058]
];

const wotongaJunctionGoonyella = [
  [-21.9377, 148.1058],
  [-21.9371, 148.1023],
  [-21.9235, 148.0723],
  [-21.9129, 148.0500],
  [-21.9092, 148.0377],
  [-21.9056, 148.0323],
  [-21.9012, 148.0286],
  [-21.8818, 148.0189],
  [-21.8794, 148.0169],
  [-21.8690, 148.0039],
  [-21.8664, 147.9993],
  [-21.8578, 147.9767],
  [-21.8543, 147.9711],
  [-21.8510, 147.9682],
  [-21.8276, 147.9553],
  [-21.8247, 147.9544],
  [-21.8174, 147.9547],
  [-21.8045, 147.9544],
  [-21.8026, 147.9550],
  [-21.7999, 147.9560],
  [-21.7968, 147.9562],
  [-21.7836, 147.9538],
  [-21.7718, 147.9489],
  [-21.7698, 147.9470],
  [-21.7669, 147.9424],
  [-21.7658, 147.9390],
  [-21.7640, 147.9307],
  [-21.7626, 147.9279],
  [-21.7520, 147.9145],
  [-21.7484, 147.9127],
  [-21.7444, 147.9133],
  [-21.7415, 147.9153],
  [-21.7392, 147.9184],
  [-21.7352, 147.9217],
  [-21.6894, 147.9366],
  [-21.6860, 147.9381],
  [-21.6818, 147.9416],
  [-21.6756, 147.9484],
  [-21.6737, 147.9496],
  [-21.6722, 147.9502],
  [-21.6662, 147.9508],
  [-21.6649, 147.9519],
  [-21.6645, 147.9534],
  [-21.6647, 147.9548],
  [-21.6658, 147.9561],
  [-21.6672, 147.9565],
  [-21.6688, 147.9560],
  [-21.6756, 147.9484]
];

export const goonyella: Line = {
  name: 'Goonyella',
  state: 'QLD',
  segments: [
    {
      segments: [koumalaCoppabella, {
        date: '2003-01-01',
        original: [coppabellaMineOld],
        deviation: [coppabellaMineDeviation]
      }],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-29',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1984-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [coppabellaMineCoppabella],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-29',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1984-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [coppabellaBroadlea],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1984-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [broadleaWotonga],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2006-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [wotongaJunctionGoonyella],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
