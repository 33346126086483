import { Line } from "../../trackTypes";

const molongYeoval = [
  [-33.0905, 148.8723],
  [-33.0826, 148.8749],
  [-33.0797, 148.8750],
  [-33.0735, 148.8734],
  [-33.0719, 148.8726],
  [-33.0679, 148.8701],
  [-33.0657, 148.8695],
  [-33.0596, 148.8699],
  [-33.0583, 148.8694],
  [-33.0489, 148.8611],
  [-33.0476, 148.8603],
  [-33.0412, 148.8581],
  [-33.0399, 148.8580],
  [-33.0343, 148.8592],
  [-33.0316, 148.8603],
  [-33.0298, 148.8605],
  [-33.0180, 148.8597],
  [-33.0167, 148.8598],
  [-33.0054, 148.8628],
  [-33.0036, 148.8626],
  [-32.9968, 148.8582],
  [-32.9953, 148.8560],
  [-32.9937, 148.8514],
  [-32.9925, 148.8500],
  [-32.9908, 148.8496],
  [-32.9895, 148.8500],
  [-32.9881, 148.8496],
  [-32.9870, 148.8484],
  [-32.9866, 148.8467],
  [-32.9867, 148.8400],
  [-32.9861, 148.8384],
  [-32.9844, 148.8372],
  [-32.9828, 148.8374],
  [-32.9809, 148.8388],
  [-32.9790, 148.8388],
  [-32.9777, 148.8375],
  [-32.9766, 148.8357],
  [-32.9751, 148.8347],
  [-32.9733, 148.8346],
  [-32.9720, 148.8336],
  [-32.9713, 148.8318],
  [-32.9719, 148.8294],
  [-32.9739, 148.8274],
  [-32.9745, 148.8263],
  [-32.9748, 148.8248],
  [-32.9748, 148.8227],
  [-32.9752, 148.8211],
  [-32.9759, 148.8201],
  [-32.9784, 148.8182],
  [-32.9802, 148.8157],
  [-32.9809, 148.8133],
  [-32.9807, 148.8113],
  [-32.9796, 148.8100],
  [-32.9779, 148.8096],
  [-32.9767, 148.8101],
  [-32.9738, 148.8122],
  [-32.9721, 148.8123],
  [-32.9708, 148.8114],
  [-32.9701, 148.8099],
  [-32.9695, 148.8059],
  [-32.9680, 148.8033],
  [-32.9651, 148.8015],
  [-32.9634, 148.7987],
  [-32.9631, 148.7958],
  [-32.9639, 148.7932],
  [-32.9635, 148.7905],
  [-32.9622, 148.7894],
  [-32.9595, 148.7892],
  [-32.9585, 148.7887],
  [-32.9576, 148.7876],
  [-32.9545, 148.7816],
  [-32.9530, 148.7799],
  [-32.9477, 148.7764],
  [-32.9455, 148.7761],
  [-32.9437, 148.7771],
  [-32.9420, 148.7793],
  [-32.9403, 148.7802],
  [-32.9384, 148.7798],
  [-32.9372, 148.7786],
  [-32.9366, 148.7766],
  [-32.9370, 148.7702],
  [-32.9385, 148.7665],
  [-32.9428, 148.7623],
  [-32.9436, 148.7606],
  [-32.9434, 148.7587],
  [-32.9422, 148.7570],
  [-32.9294, 148.7502],
  [-32.9266, 148.7497],
  [-32.9244, 148.7507],
  [-32.9213, 148.7539],
  [-32.9193, 148.7548],
  [-32.9112, 148.7546],
  [-32.9092, 148.7552],
  [-32.9056, 148.7583],
  [-32.9021, 148.7598],
  [-32.8997, 148.7597],
  [-32.8956, 148.7573],
  [-32.8937, 148.7545],
  [-32.8925, 148.7537],
  [-32.8904, 148.7533],
  [-32.8890, 148.7527],
  [-32.8879, 148.7510],
  [-32.8854, 148.7449],
  [-32.8812, 148.7395],
  [-32.8794, 148.7386],
  [-32.8776, 148.7383],
  [-32.8759, 148.7370],
  [-32.8750, 148.7341],
  [-32.8757, 148.7291],
  [-32.8749, 148.7251],
  [-32.8724, 148.7225],
  [-32.8686, 148.7212],
  [-32.8664, 148.7210],
  [-32.8635, 148.7216],
  [-32.8617, 148.7216],
  [-32.8513, 148.7203],
  [-32.8485, 148.7192],
  [-32.8370, 148.7120],
  [-32.8329, 148.7103],
  [-32.8109, 148.7032],
  [-32.8002, 148.7024],
  [-32.7907, 148.6994],
  [-32.7586, 148.6791],
  [-32.7517, 148.6729],
  [-32.7496, 148.6694],
  [-32.7467, 148.6521],
  [-32.7456, 148.6502],
  [-32.7438, 148.6496],
  [-32.7426, 148.6498]
];

const yeovalDubboEastJunction = [
  [-32.7426, 148.6498],
  [-32.7284, 148.6517],
  [-32.7064, 148.6584],
  [-32.7040, 148.6603],
  [-32.7025, 148.6627],
  [-32.6999, 148.6698],
  [-32.6982, 148.6725],
  [-32.6923, 148.6779],
  [-32.6845, 148.6854],
  [-32.6836, 148.6871],
  [-32.6834, 148.6890],
  [-32.6833, 148.6914],
  [-32.6822, 148.6936],
  [-32.6687, 148.7054],
  [-32.6677, 148.7079],
  [-32.6678, 148.7102],
  [-32.6684, 148.7123],
  [-32.6683, 148.7140],
  [-32.6676, 148.7159],
  [-32.6662, 148.7172],
  [-32.6648, 148.7184],
  [-32.6641, 148.7200],
  [-32.6638, 148.7223],
  [-32.6629, 148.7247],
  [-32.6603, 148.7295],
  [-32.6589, 148.7345],
  [-32.6582, 148.7359],
  [-32.6571, 148.7367],
  [-32.6549, 148.7376],
  [-32.6529, 148.7391],
  [-32.6512, 148.7408],
  [-32.6487, 148.7417],
  [-32.6458, 148.7416],
  [-32.6436, 148.7420],
  [-32.6418, 148.7433],
  [-32.6402, 148.7438],
  [-32.6277, 148.7473],
  [-32.6255, 148.7484],
  [-32.6240, 148.7486],
  [-32.6219, 148.7477],
  [-32.6207, 148.7456],
  [-32.6200, 148.7422],
  [-32.6188, 148.7402],
  [-32.6169, 148.7390],
  [-32.6152, 148.7391],
  [-32.6075, 148.7419],
  [-32.6055, 148.7420],
  [-32.6038, 148.7416],
  [-32.6017, 148.7408],
  [-32.5990, 148.7410],
  [-32.5943, 148.7436],
  [-32.5908, 148.7444],
  [-32.5855, 148.7435],
  [-32.5835, 148.7437],
  [-32.5811, 148.7443],
  [-32.5795, 148.7443],
  [-32.5767, 148.7433],
  [-32.5567, 148.7321],
  [-32.5539, 148.7310],
  [-32.5483, 148.7295],
  [-32.5455, 148.7280],
  [-32.5352, 148.7193],
  [-32.5337, 148.7172],
  [-32.5328, 148.7150],
  [-32.5276, 148.6888],
  [-32.5239, 148.6819],
  [-32.5201, 148.6781],
  [-32.5184, 148.6754],
  [-32.5170, 148.6729],
  [-32.5081, 148.6610],
  [-32.5070, 148.6585],
  [-32.5068, 148.6562],
  [-32.5072, 148.6534],
  [-32.5070, 148.6510],
  [-32.5057, 148.6486],
  [-32.4993, 148.6429],
  [-32.4983, 148.6406],
  [-32.4985, 148.6386],
  [-32.4999, 148.6358],
  [-32.5004, 148.6344],
  [-32.5002, 148.6326],
  [-32.4964, 148.6190],
  [-32.4952, 148.6164],
  [-32.4897, 148.6083],
  [-32.4881, 148.6070],
  [-32.4862, 148.6064],
  [-32.4800, 148.6059],
  [-32.4787, 148.6051],
  [-32.4779, 148.6037],
  [-32.4778, 148.6023],
  [-32.4783, 148.6001],
  [-32.4780, 148.5986],
  [-32.4770, 148.5973],
  [-32.4726, 148.5955],
  [-32.4707, 148.5953],
  [-32.4696, 148.5948],
  [-32.4664, 148.5922],
  [-32.4646, 148.5917],
  [-32.4603, 148.5923],
  [-32.4589, 148.5919],
  [-32.4562, 148.5902],
  [-32.4540, 148.5900],
  [-32.4389, 148.5961],
  [-32.4351, 148.5993],
  [-32.4308, 148.6046],
  [-32.4275, 148.6064],
  [-32.4245, 148.6068],
  [-32.4217, 148.6062],
  [-32.3861, 148.5824],
  [-32.3848, 148.5801],
  [-32.3851, 148.5767],
  [-32.3864, 148.5744],
  [-32.3865, 148.5719],
  [-32.3850, 148.5701],
  [-32.3828, 148.5702],
  [-32.3796, 148.5732],
  [-32.3776, 148.5740],
  [-32.3736, 148.5747],
  [-32.3649, 148.5790],
  [-32.3630, 148.5814],
  [-32.3616, 148.5840],
  [-32.3581, 148.5866],
  [-32.3568, 148.5868],
  [-32.3555, 148.5863],
  [-32.3505, 148.5819],
  [-32.3491, 148.5817],
  [-32.3473, 148.5826],
  [-32.3465, 148.5849],
  [-32.3465, 148.5923],
  [-32.3457, 148.5952],
  [-32.3441, 148.5967],
  [-32.3422, 148.5970],
  [-32.3372, 148.5959],
  [-32.3347, 148.5961],
  [-32.3086, 148.6052],
  [-32.3067, 148.6054],
  [-32.3048, 148.6045],
  [-32.2999, 148.6011],
  [-32.2978, 148.6004],
  [-32.2959, 148.6006],
  [-32.2939, 148.6016],
  [-32.2915, 148.6020],
  [-32.2888, 148.6016],
  [-32.2869, 148.6019],
  [-32.2852, 148.6028],
  [-32.2815, 148.6057],
  [-32.2798, 148.6077],
  [-32.2761, 148.6142],
  [-32.2745, 148.6161],
  [-32.2672, 148.6224],
  [-32.2653, 148.6232],
  [-32.2632, 148.6234],
  [-32.2558, 148.6210],
  [-32.2477, 148.6181],
  [-32.2468, 148.6174],
  [-32.2464, 148.6165],
  [-32.2460, 148.6153]
];

export const molongDubbo: Line = {
  name: 'Molong - Dubbo',
  state: 'NSW',
  segments: [
    {
      segments: [molongYeoval],
      history: {
        opened: {
          date: '1925-01-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-07-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yeovalDubboEastJunction],
      history: {
        opened: {
          date: '1925-5-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-01-25',
          status: 'closed'
        }]
      }
    }
  ]
};
