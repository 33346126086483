import { Line } from "../../trackTypes";

const kadinaSnowtown = [
  [-33.9676, 137.7245],
  [-33.9677, 137.7264],
  [-33.9675, 137.7280],
  [-33.9464, 137.7861],
  [-33.9254, 137.8608],
  [-33.9181, 137.8789],
  [-33.9177, 137.8804],
  [-33.9173, 137.8828],
  [-33.8644, 138.0114],
  [-33.8640, 138.0132],
  [-33.8641, 138.0146],
  [-33.8657, 138.0209],
  [-33.8657, 138.0289],
  [-33.8652, 138.0308],
  [-33.8644, 138.0322],
  [-33.8630, 138.0341],
  [-33.8624, 138.0353],
  [-33.8623, 138.0364],
  [-33.8623, 138.0385],
  [-33.8616, 138.0409],
  [-33.8605, 138.0424],
  [-33.8566, 138.0455],
  [-33.8556, 138.0470],
  [-33.8552, 138.0485],
  [-33.8548, 138.0541],
  [-33.8506, 138.0723],
  [-33.8505, 138.0758],
  [-33.8498, 138.0780],
  [-33.8487, 138.0797],
  [-33.8483, 138.0809],
  [-33.8484, 138.0821],
  [-33.8494, 138.0839],
  [-33.8499, 138.0862],
  [-33.8494, 138.0889],
  [-33.8481, 138.0907],
  [-33.8445, 138.0937],
  [-33.8301, 138.1101],
  [-33.8283, 138.1114],
  [-33.8273, 138.1130],
  [-33.8270, 138.1146],
  [-33.8274, 138.1188],
  [-33.8265, 138.1285],
  [-33.8267, 138.1334],
  [-33.8276, 138.1371],
  [-33.8277, 138.1426],
  [-33.8269, 138.1450],
  [-33.8257, 138.1463],
  [-33.8244, 138.1470],
  [-33.8239, 138.1481],
  [-33.8238, 138.1491],
  [-33.8243, 138.1510],
  [-33.8242, 138.1528],
  [-33.8234, 138.1552],
  [-33.8233, 138.1594],
  [-33.8226, 138.1607],
  [-33.8212, 138.1612],
  [-33.8203, 138.1607],
  [-33.8193, 138.1599],
  [-33.8180, 138.1598],
  [-33.8171, 138.1607],
  [-33.8168, 138.1621],
  [-33.8173, 138.1635],
  [-33.8184, 138.1643],
  [-33.8207, 138.1683],
  [-33.8209, 138.1709],
  [-33.8200, 138.1731],
  [-33.8182, 138.1746],
  [-33.7958, 138.2044],
  [-33.7917, 138.2079],
  [-33.7833, 138.2124]
];

const snowtownBrinkworth = [
  [-33.7833, 138.2124],
  [-33.7788, 138.2152],
  [-33.7773, 138.2167],
  [-33.7765, 138.2183],
  [-33.7716, 138.2323],
  [-33.7709, 138.2363],
  [-33.7707, 138.2476],
  [-33.7702, 138.2512],
  [-33.7618, 138.2757],
  [-33.7202, 138.3529],
  [-33.7187, 138.3563],
  [-33.7057, 138.3993],
  [-33.7044, 138.4015],
  [-33.7021, 138.4034],
  [-33.6997, 138.40417]
];

export const kadinaBrinkworth: Line = {
  name: 'kadina - Brinkworth',
  state: 'SA',
  segments: [
    {
      segments: [kadinaSnowtown],
      history: {
        opened: {
          date: '1879-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '1993-03-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [snowtownBrinkworth],
      history: {
        opened: {
          date: '1894-07-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1927-08-01',
          gauge: 'broad'
        }, {
          date: '1990-02-20',
          status: 'closed'
        }]
      }
    }
  ]
};
