import { Line } from "../../../trackTypes";

const putaruruJunctionTarukenga = [
  [-38.0572, 175.7866],
  [-38.0580, 175.7897],
  [-38.0576, 175.7939],
  [-38.0563, 175.7989],
  [-38.0561, 175.8008],
  [-38.0565, 175.8028],
  [-38.0587, 175.8071],
  [-38.0590, 175.8084],
  [-38.0593, 175.8126],
  [-38.0587, 175.8176],
  [-38.0584, 175.8194],
  [-38.0589, 175.8297],
  [-38.0607, 175.8420],
  [-38.0616, 175.8447],
  [-38.0634, 175.8473],
  [-38.0643, 175.8486],
  [-38.0649, 175.8487],
  [-38.0658, 175.8483],
  [-38.0675, 175.8482],
  [-38.0687, 175.8485],
  [-38.0696, 175.8494],
  [-38.0700, 175.8507],
  [-38.0710, 175.8515],
  [-38.0715, 175.8517],
  [-38.0735, 175.8515],
  [-38.0762, 175.8527],
  [-38.0770, 175.8534],
  [-38.0796, 175.8570],
  [-38.0808, 175.8581],
  [-38.0834, 175.8591],
  [-38.0862, 175.8611],
  [-38.0880, 175.8637],
  [-38.0928, 175.8746],
  [-38.0937, 175.8782],
  [-38.0938, 175.8819],
  [-38.0937, 175.8861],
  [-38.0941, 175.8890],
  [-38.0950, 175.8916],
  [-38.0971, 175.9004],
  [-38.0973, 175.9028],
  [-38.0971, 175.9052],
  [-38.0957, 175.9173],
  [-38.0959, 175.9187],
  [-38.0966, 175.9203],
  [-38.0980, 175.9219],
  [-38.0988, 175.9238],
  [-38.0988, 175.9255],
  [-38.0979, 175.9284],
  [-38.0976, 175.9303],
  [-38.0976, 175.9320],
  [-38.0962, 175.9379],
  [-38.0951, 175.9405],
  [-38.0948, 175.9423],
  [-38.0944, 175.9466],
  [-38.0932, 175.9571],
  [-38.0934, 175.9590],
  [-38.0943, 175.9606],
  [-38.0960, 175.9629],
  [-38.0966, 175.9647],
  [-38.0970, 175.9681],
  [-38.0971, 175.9722],
  [-38.0965, 175.9795],
  [-38.0964, 175.9851],
  [-38.0968, 175.9875],
  [-38.0976, 175.9896],
  [-38.0984, 175.9959],
  [-38.0998, 175.9999],
  [-38.1010, 176.0019],
  [-38.1020, 176.0047],
  [-38.1082, 176.0331],
  [-38.1084, 176.0363],
  [-38.1069, 176.0525],
  [-38.1058, 176.0564],
  [-38.0906, 176.0893],
  [-38.0894, 176.0934],
  [-38.0891, 176.0961],
  [-38.0882, 176.1150],
  [-38.0872, 176.1190],
  [-38.0872, 176.1217],
  [-38.0876, 176.1245],
  [-38.0872, 176.1305],
  [-38.0877, 176.1330],
  [-38.0894, 176.1374],
  [-38.0895, 176.1400],
  [-38.0888, 176.1416],
  [-38.0875, 176.1427],
  [-38.0832, 176.1430],
  [-38.0817, 176.1436],
  [-38.0804, 176.1450],
  [-38.0800, 176.1474],
  [-38.0803, 176.1493],
  [-38.0801, 176.1505],
  [-38.0797, 176.1511],
  [-38.0746, 176.1566]
];

const tarukengaKoutu = [
  [-38.0746, 176.1566],
  [-38.0730, 176.1583],
  [-38.0723, 176.1595],
  [-38.0720, 176.1607],
  [-38.0719, 176.1622],
  [-38.0727, 176.1677],
  [-38.0725, 176.1692],
  [-38.0720, 176.1707],
  [-38.0718, 176.1722],
  [-38.0719, 176.1739],
  [-38.0725, 176.1755],
  [-38.0736, 176.1773],
  [-38.0739, 176.1780],
  [-38.0740, 176.1790],
  [-38.0729, 176.1927],
  [-38.0727, 176.1955],
  [-38.0733, 176.1988],
  [-38.0765, 176.2111],
  [-38.0770, 176.2121],
  [-38.0779, 176.2128],
  [-38.0894, 176.2187],
  [-38.1114, 176.2299]
];

const koutuRotorua = [
  [-38.1114, 176.2299],
  [-38.1179, 176.2331],
  [-38.1201, 176.2341],
  [-38.1371, 176.2394],
  [-38.1388, 176.2408],
  [-38.1399, 176.2429],
  [-38.1405, 176.2455],
  [-38.1406, 176.2535]
];

export const rotorua: Line = {
  name: 'Rotorua',
  state: 'NZ',
  segments: [
    {
      segments: [putaruruJunctionTarukenga],
      history: {
        opened: {
          date: '1893-11-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2001-10-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tarukengaKoutu],
      history: {
        opened: {
          date: '1894-12-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2001-10-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [koutuRotorua],
      history: {
        opened: {
          date: '1894-12-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
