import { Line } from "../../../trackTypes";

const cairnsOld = [
  [-16.9256, 145.7732],
  [-16.9238, 145.7715],
  [-16.9234, 145.7709],
  [-16.9222, 145.7690],
  [-16.9216, 145.7684]
];

const cairnsNew = [
  [-16.9256, 145.7712],
  [-16.9236, 145.7695],
  [-16.9216, 145.7684]
];

const cairnsRedlynch = [
  [-16.9216, 145.7684],
  [-16.9080, 145.7570],
  [-16.9067, 145.7564],
  [-16.9049, 145.7560],
  [-16.9007, 145.7556],
  [-16.8984, 145.7548],
  [-16.8964, 145.7537],
  [-16.8881, 145.7506],
  [-16.8869, 145.7497],
  [-16.8849, 145.7470],
  [-16.8838, 145.7463],
  [-16.8797, 145.7447],
  [-16.8786, 145.7440],
  [-16.8752, 145.7405],
  [-16.8747, 145.7393],
  [-16.8751, 145.7291],
  [-16.8760, 145.7258],
  [-16.8760, 145.7258],
  [-16.8766, 145.7183],
  [-16.8768, 145.7174],
  [-16.8816, 145.7085],
  [-16.8827, 145.7072],
  [-16.8863, 145.6978],
  [-16.8869, 145.6966]
];

const redlynchTunnel1 = [
  [-16.8869, 145.6966],
  [-16.8878, 145.6956],
  [-16.8888, 145.6951],
  [-16.8897, 145.6952],
  [-16.8904, 145.6951],
  [-16.8921, 145.6943],
  [-16.8927, 145.6942],
  [-16.8936, 145.6941],
  [-16.8972, 145.6926],
  [-16.8985, 145.6919],
  [-16.8996, 145.6918],
  [-16.9016, 145.6922],
  [-16.9025, 145.6921],
  [-16.9063, 145.6907],
  [-16.9084, 145.6896],
  [-16.9089, 145.6896],
  [-16.9092, 145.6897],
  [-16.9103, 145.6912],
  [-16.9109, 145.6917],
  [-16.9136, 145.6924],
  [-16.9142, 145.6923],
  [-16.9146, 145.6918],
  [-16.9145, 145.6910],
  [-16.9141, 145.6905],
  [-16.9136, 145.6903],
  [-16.9128, 145.6903],
  [-16.9121, 145.6904],
  [-16.9117, 145.6904],
  [-16.9114, 145.6902],
  [-16.9105, 145.6894],
  [-16.9102, 145.6890],
  [-16.9100, 145.6884],
  [-16.9095, 145.6879],
  [-16.9090, 145.6878],
  [-16.9085, 145.6878],
  [-16.9080, 145.6877],
  [-16.9077, 145.6873],
  [-16.9073, 145.6869],
  [-16.9069, 145.6867],
  [-16.9064, 145.6863],
  [-16.9060, 145.6857],
  [-16.9056, 145.6854],
  [-16.9045, 145.6849],
  [-16.9039, 145.6849],
  [-16.9028, 145.6855],
  [-16.9024, 145.6855],
  [-16.9020, 145.6854],
  [-16.9014, 145.6850],
  [-16.9009, 145.6851],
  [-16.8996, 145.6859]
];

const barronGorgeNo1Tunnel = [
  [-16.8996, 145.6859],
  [-16.8987, 145.6863]
];

const tunnel1Tunnel2 = [
  [-16.8987, 145.6863],
  [-16.8972, 145.6868],
  [-16.8966, 145.6868],
  [-16.8960, 145.6868],
  [-16.8953, 145.6870],
  [-16.8949, 145.6875],
  [-16.8948, 145.6882],
  [-16.8943, 145.6888],
  [-16.8937, 145.6889],
  [-16.8932, 145.6886],
  [-16.8926, 145.6876],
  [-16.8922, 145.6867],
  [-16.8918, 145.6864],
  [-16.8912, 145.6855],
  [-16.8909, 145.6853]
];

const barronGorgeNo2Tunnel = [
  [-16.8909, 145.6853],
  [-16.8898, 145.6848]
];

const tunnel2Tunnel3 = [
  [-16.8898, 145.6848],
  [-16.8894, 145.6845],
  [-16.8888, 145.6844],
  [-16.8884, 145.6842],
  [-16.8882, 145.6839],
  [-16.8881, 145.6835],
  [-16.8878, 145.6832],
  [-16.8869, 145.6827],
  [-16.8865, 145.6827],
  [-16.8861, 145.6829],
  [-16.8858, 145.6828]
];

const barronGorgeNo3Tunnel = [
  [-16.8858, 145.6828],
  [-16.8855, 145.6827],
  [-16.8851, 145.6824],
  [-16.8848, 145.6821]
];

const tunnel3Tunnel4 = [
  [-16.8848, 145.6821],
  [-16.8844, 145.6818],
  [-16.8838, 145.6816],
  [-16.8834, 145.6816]
];

const barronGorgeNo4Tunnel = [
  [-16.8834, 145.6816],
  [-16.8825, 145.6817]
];

const tunnel4Tunnel5 = [
  [-16.8825, 145.6817],
  [-16.8821, 145.6816],
  [-16.8817, 145.6814],
  [-16.8813, 145.6810],
  [-16.8813, 145.6810]
];

const barronGorgeNo5Tunnel = [
  [-16.8813, 145.6810],
  [-16.8811, 145.6802]
];

const tunnel5Tunnel6 = [
  [-16.8811, 145.6802],
  [-16.8810, 145.6799],
  [-16.8808, 145.6795],
  [-16.8804, 145.6792]
];

const barronGorgeNo6Tunnel = [
  [-16.8804, 145.6792],
  [-16.8794, 145.6783]
];

const tunnel6Tunnel7 = [
  [-16.8794, 145.6783],
  [-16.8792, 145.6781],
  [-16.8791, 145.6778]
];

const barronGorgeNo7Tunnel = [
  [-16.8791, 145.6778],
  [-16.8791, 145.6773]
];

const tunnel7Tunnel8 = [
  [-16.8791, 145.6773],
  [-16.8792, 145.6769],
  [-16.8792, 145.6763]
];

const barronGorgeNo8Tunnel = [
  [-16.8792, 145.6763],
  [-16.8791, 145.6759],
  [-16.8792, 145.6753]
];

const tunnel8Tunnel9 = [
  [-16.8792, 145.6753],
  [-16.8793, 145.6748],
  [-16.8791, 145.6743],
  [-16.8788, 145.6740]
];

const barronGorgeNo9Tunnel = [
  [-16.8788, 145.6740],
  [-16.8780, 145.6729],
  [-16.8779, 145.6726]
];

const tunnel9Tunnel10 = [
  [-16.8779, 145.6726],
  [-16.8779, 145.6723],
  [-16.8781, 145.6713],
  [-16.8783, 145.6708],
  [-16.8785, 145.6696],
  [-16.8785, 145.6692]
];

const barronGorgeNo10Tunnel = [
  [-16.8785, 145.6692],
  [-16.8785, 145.6688],
  [-16.8786, 145.6684]
];

const tunnel10Tunnel11 = [
  [-16.8786, 145.6684],
  [-16.8787, 145.6683],
  [-16.8791, 145.6680],
  [-16.8794, 145.6676],
  [-16.8795, 145.6672]
];

const barronGorgeNo11Tunnel = [
  [-16.8795, 145.6672],
  [-16.8795, 145.6664]
];

const tunnel11Tunnel12 = [
  [-16.8795, 145.6664],
  [-16.8798, 145.6658],
  [-16.8800, 145.6655],
  [-16.8803, 145.6653],
  [-16.8806, 145.6650],
  [-16.8808, 145.6643],
  [-16.8809, 145.6637],
  [-16.8810, 145.6633],
  [-16.8811, 145.6629],
  [-16.8808, 145.6622],
  [-16.8808, 145.6617],
  [-16.8811, 145.6611],
  [-16.8812, 145.6608]
];

const barronGorgeNo12Tunnel = [
  [-16.8812, 145.6608],
  [-16.8812, 145.6601]
];

const tunnel12Tunnel13 = [
  [-16.8812, 145.6601],
  [-16.8812, 145.6597],
  [-16.8806, 145.6588],
  [-16.8801, 145.6585],
  [-16.8798, 145.6580],
  [-16.8797, 145.6576]
];

const barronGorgeNo13Tunnel = [
  [-16.8797, 145.6576],
  [-16.8796, 145.6572],
  [-16.8796, 145.6567]
];

const tunnel13Tunnel14 = [
  [-16.8796, 145.6567],
  [-16.8797, 145.6562],
  [-16.8797, 145.6558],
  [-16.8796, 145.6553],
  [-16.8796, 145.6548],
  [-16.8799, 145.6536],
  [-16.8806, 145.6521],
  [-16.8807, 145.6516],
  [-16.8804, 145.6511],
  [-16.8800, 145.6510],
  [-16.8795, 145.6511],
  [-16.8790, 145.6514],
  [-16.8787, 145.6516],
  [-16.8780, 145.6518],
  [-16.8776, 145.6520],
  [-16.8772, 145.6525],
  [-16.8769, 145.6527],
  [-16.8765, 145.6528],
  [-16.8761, 145.6530],
  [-16.8748, 145.6541],
  [-16.8745, 145.6545],
  [-16.8740, 145.6556],
  [-16.8739, 145.6563],
  [-16.8738, 145.6566],
  [-16.8734, 145.6571],
  [-16.8732, 145.6578],
  [-16.8732, 145.6584],
  [-16.8731, 145.6589],
  [-16.8725, 145.6596],
  [-16.8723, 145.6606]
];

const barronGorgeNo14Tunnel = [
  [-16.8723, 145.6606],
  [-16.8721, 145.6613]
];

const tunnel14Tunnel15 = [
  [-16.8721, 145.6613],
  [-16.8720, 145.6615],
  [-16.8715, 145.6617],
  [-16.8709, 145.6617],
  [-16.8705, 145.6617],
  [-16.8700, 145.6618],
  [-16.8697, 145.6621],
  [-16.8696, 145.6627],
  [-16.8696, 145.6631],
  [-16.8699, 145.6638],
  [-16.8698, 145.6646],
  [-16.8692, 145.6654],
  [-16.8687, 145.6663],
  [-16.8683, 145.6665],
  [-16.8679, 145.6665],
  [-16.8664, 145.6663],
  [-16.8660, 145.6660],
  [-16.8657, 145.6656],
  [-16.8657, 145.6652],
  [-16.8656, 145.6645],
  [-16.8646, 145.6634],
  [-16.8645, 145.6631],
  [-16.8645, 145.6626],
  [-16.8645, 145.6623],
  [-16.8644, 145.6619],
  [-16.8638, 145.6613],
  [-16.8637, 145.6611]
];

const barronGorgeNo15Tunnel = [
  [-16.8637, 145.6611],
  [-16.8619, 145.6578]
];

const tunnel15Myola = [
  [-16.8619, 145.6578],
  [-16.8611, 145.6568],
  [-16.8608, 145.6566],
  [-16.8603, 145.6565],
  [-16.8600, 145.6563],
  [-16.8595, 145.6558],
  [-16.8586, 145.6551],
  [-16.8581, 145.6541],
  [-16.8581, 145.6535],
  [-16.8583, 145.6529],
  [-16.8582, 145.6523],
  [-16.8583, 145.6517],
  [-16.8581, 145.6510],
  [-16.8580, 145.6504],
  [-16.8574, 145.6491],
  [-16.8570, 145.6488],
  [-16.8565, 145.6486],
  [-16.8560, 145.6481],
  [-16.8548, 145.6470],
  [-16.8544, 145.6465],
  [-16.8539, 145.6455],
  [-16.8536, 145.6446],
  [-16.8527, 145.6436],
  [-16.8521, 145.6434],
  [-16.8514, 145.6433],
  [-16.8507, 145.6436],
  [-16.8502, 145.6436],
  [-16.8496, 145.6432],
  [-16.8491, 145.6432],
  [-16.8463, 145.6445],
  [-16.8458, 145.6446],
  [-16.8454, 145.6447],
  [-16.8449, 145.6451],
  [-16.8445, 145.6451],
  [-16.8436, 145.6449],
  [-16.8432, 145.6449],
  [-16.8427, 145.6451],
  [-16.8423, 145.6451],
  [-16.8413, 145.6449],
  [-16.8409, 145.6450],
  [-16.8405, 145.6451],
  [-16.8401, 145.6451],
  [-16.8392, 145.6448],
  [-16.8383, 145.6446],
  [-16.8371, 145.6441],
  [-16.8367, 145.6438],
  [-16.8365, 145.6430],
  [-16.8360, 145.6426],
  [-16.8349, 145.6424],
  [-16.8344, 145.6425],
  [-16.8340, 145.6424],
  [-16.8332, 145.6421],
  [-16.8328, 145.6417],
  [-16.8323, 145.6400],
  [-16.8317, 145.6387],
  [-16.8306, 145.6381],
  [-16.8301, 145.6380],
  [-16.8280, 145.6381],
  [-16.8259, 145.6390],
  [-16.8253, 145.6394],
  [-16.8244, 145.6406],
  [-16.8237, 145.6410],
  [-16.8228, 145.6410],
  [-16.8216, 145.6406],
  [-16.8198, 145.6399],
  [-16.8190, 145.6395],
  [-16.8177, 145.6383],
  [-16.8173, 145.6375],
  [-16.8171, 145.6364],
  [-16.8164, 145.6327],
  [-16.8164, 145.6317],
  [-16.8161, 145.6307],
  [-16.8143, 145.6293],
  [-16.8125, 145.6284],
  [-16.8117, 145.6283],
  [-16.8098, 145.6285],
  [-16.8087, 145.6287],
  [-16.8083, 145.6290],
  [-16.8066, 145.6307],
  [-16.8058, 145.6310],
  [-16.8052, 145.6310],
  [-16.8046, 145.6308],
  [-16.8040, 145.6302],
  [-16.8006, 145.6228],
  [-16.8003, 145.6216],
  [-16.8003, 145.6204],
  [-16.8007, 145.6195],
  [-16.8013, 145.6183],
  [-16.8015, 145.6168],
  [-16.8015, 145.6123],
  [-16.8017, 145.6113]
];

const myolaMareeba = [
  [-16.8017, 145.6113],
  [-16.8019, 145.6107],
  [-16.8028, 145.6092],
  [-16.8046, 145.6072],
  [-16.8070, 145.6036],
  [-16.8072, 145.6027],
  [-16.8066, 145.5973],
  [-16.8059, 145.5958],
  [-16.8043, 145.5943],
  [-16.8032, 145.5927],
  [-16.8031, 145.5917],
  [-16.8035, 145.5904],
  [-16.8042, 145.5897],
  [-16.8073, 145.5879],
  [-16.8080, 145.5868],
  [-16.8082, 145.5847],
  [-16.8078, 145.5830],
  [-16.8065, 145.5811],
  [-16.8024, 145.5774],
  [-16.8022, 145.5768],
  [-16.8023, 145.5761],
  [-16.8037, 145.5744],
  [-16.8040, 145.5736],
  [-16.8048, 145.5676],
  [-16.8047, 145.5622],
  [-16.8042, 145.5569],
  [-16.8042, 145.5563],
  [-16.8044, 145.5553],
  [-16.8044, 145.5548],
  [-16.8042, 145.5541],
  [-16.8041, 145.5528],
  [-16.8037, 145.5517],
  [-16.8037, 145.5512],
  [-16.8039, 145.5508],
  [-16.8044, 145.5503],
  [-16.8054, 145.5489],
  [-16.8061, 145.5483],
  [-16.8064, 145.5475],
  [-16.8066, 145.5459],
  [-16.8071, 145.5450],
  [-16.8075, 145.5447],
  [-16.8091, 145.5439],
  [-16.8106, 145.5427],
  [-16.8109, 145.5423],
  [-16.8112, 145.5416],
  [-16.8117, 145.5400],
  [-16.8120, 145.5395],
  [-16.8152, 145.5359],
  [-16.8161, 145.5349],
  [-16.8168, 145.5337],
  [-16.8171, 145.5334],
  [-16.8186, 145.5324],
  [-16.8190, 145.5319],
  [-16.8229, 145.5211],
  [-16.8229, 145.5195],
  [-16.8220, 145.5166],
  [-16.8220, 145.5158],
  [-16.8222, 145.5149],
  [-16.8229, 145.5138],
  [-16.8231, 145.5132],
  [-16.8244, 145.5068],
  [-16.8248, 145.5034],
  [-16.8252, 145.5024],
  [-16.8257, 145.5017],
  [-16.8289, 145.4989],
  [-16.8293, 145.4983],
  [-16.8299, 145.4969],
  [-16.8306, 145.4957],
  [-16.8314, 145.4949],
  [-16.8367, 145.4919],
  [-16.8375, 145.4910],
  [-16.8396, 145.4877],
  [-16.8399, 145.4867],
  [-16.8397, 145.4823],
  [-16.8400, 145.4812],
  [-16.8406, 145.4803],
  [-16.8417, 145.4795],
  [-16.8422, 145.4790],
  [-16.8494, 145.4675],
  [-16.8497, 145.4668],
  [-16.8533, 145.4526],
  [-16.8539, 145.4515],
  [-16.8576, 145.4478],
  [-16.8587, 145.4473],
  [-16.8595, 145.4472],
  [-16.8633, 145.4481],
  [-16.8641, 145.4482],
  [-16.8657, 145.4480],
  [-16.8662, 145.4479],
  [-16.8667, 145.4481],
  [-16.8676, 145.4480],
  [-16.8693, 145.4476],
  [-16.8707, 145.4479],
  [-16.8717, 145.4477],
  [-16.8768, 145.4452],
  [-16.8777, 145.4449],
  [-16.8807, 145.4446],
  [-16.8871, 145.4414],
  [-16.8909, 145.4382],
  [-16.9001, 145.4338],
  [-16.9117, 145.4281],
  [-16.9145, 145.4260],
  [-16.9162, 145.4223],
  [-16.9166, 145.4216],
  [-16.9178, 145.4207],
  [-16.9196, 145.4200],
  [-16.9216, 145.4197],
  [-16.9575, 145.4144],
  [-16.9719, 145.4148],
  [-16.9740, 145.4150],
  [-16.9844, 145.4177],
  [-16.9858, 145.4185],
  [-16.9874, 145.4199],
  [-16.9885, 145.4204],
  [-16.9937, 145.4213]
];

const mareebaAtherton = [
  [-16.9937, 145.4213],
  [-17.0246, 145.4263],
  [-17.0856, 145.4364],
  [-17.1008, 145.4410],
  [-17.1035, 145.4413],
  [-17.1076, 145.4400],
  [-17.1091, 145.4389],
  [-17.1101, 145.4386],
  [-17.1115, 145.4391],
  [-17.1129, 145.4391],
  [-17.1139, 145.4386],
  [-17.1147, 145.4385],
  [-17.1158, 145.4386],
  [-17.1167, 145.4383],
  [-17.1175, 145.4375],
  [-17.1186, 145.4369],
  [-17.1200, 145.4367],
  [-17.1212, 145.4358],
  [-17.1218, 145.4345],
  [-17.1218, 145.4307],
  [-17.1222, 145.4297],
  [-17.1229, 145.4291],
  [-17.1236, 145.4290],
  [-17.1338, 145.4299],
  [-17.1362, 145.4307],
  [-17.1471, 145.4375],
  [-17.1504, 145.4389],
  [-17.1548, 145.4401],
  [-17.1584, 145.4407],
  [-17.1610, 145.4414],
  [-17.1745, 145.4478],
  [-17.1762, 145.4481],
  [-17.1825, 145.4482],
  [-17.1835, 145.4485],
  [-17.1839, 145.4493],
  [-17.1838, 145.4501],
  [-17.1833, 145.4510],
  [-17.1832, 145.4517],
  [-17.1836, 145.4524],
  [-17.1846, 145.4530],
  [-17.1852, 145.4536],
  [-17.1854, 145.4544],
  [-17.1853, 145.4554],
  [-17.1838, 145.4579],
  [-17.1836, 145.4588],
  [-17.1839, 145.4606],
  [-17.1838, 145.4622],
  [-17.1840, 145.4638],
  [-17.1851, 145.4653],
  [-17.1952, 145.4716],
  [-17.2072, 145.4774],
  [-17.2106, 145.4783],
  [-17.2182, 145.4791],
  [-17.2203, 145.4795],
  [-17.2409, 145.4849],
  [-17.2426, 145.4850],
  [-17.2441, 145.4847],
  [-17.2477, 145.4831],
  [-17.2486, 145.4829],
  [-17.2498, 145.4827],
  [-17.2505, 145.4824],
  [-17.2510, 145.4817],
  [-17.2518, 145.4792],
  [-17.2524, 145.4785],
  [-17.2534, 145.4782],
  [-17.2553, 145.4785],
  [-17.2569, 145.4781],
  [-17.2577, 145.4774],
  [-17.2581, 145.4772],
  [-17.2602, 145.4766],
  [-17.2612, 145.4765],
  [-17.2621, 145.4765],
  [-17.2628, 145.4763],
  [-17.2670, 145.4731]
];

const athertonHerbertonRangeTunnel = [
  [-17.2670, 145.4731],
  [-17.2689, 145.4722],
  [-17.2724, 145.4713],
  [-17.2754, 145.4718],
  [-17.2769, 145.4726],
  [-17.2783, 145.4740],
  [-17.2794, 145.4745],
  [-17.2861, 145.4733],
  [-17.2887, 145.4737],
  [-17.2919, 145.4750],
  [-17.2927, 145.4751],
  [-17.2934, 145.4748],
  [-17.2946, 145.4739],
  [-17.2959, 145.4734],
  [-17.2998, 145.4727],
  [-17.3240, 145.4729],
  [-17.3252, 145.4722],
  [-17.3262, 145.4701],
  [-17.3270, 145.4694],
  [-17.3283, 145.4689],
  [-17.3291, 145.4680],
  [-17.3292, 145.4668],
  [-17.3288, 145.4656],
  [-17.3288, 145.4599],
  [-17.3291, 145.4590],
  [-17.3306, 145.4577],
  [-17.3311, 145.4567],
  [-17.3310, 145.4552],
  [-17.3313, 145.4537],
  [-17.3313, 145.4527],
  [-17.3310, 145.4520],
  [-17.3311, 145.4514],
  [-17.3314, 145.4510],
  [-17.3325, 145.4505],
  [-17.3330, 145.4500],
  [-17.3333, 145.4489],
  [-17.3330, 145.4480],
  [-17.3315, 145.4471],
  [-17.3305, 145.4463],
  [-17.3294, 145.4457],
  [-17.3288, 145.4450],
  [-17.3286, 145.4441],
  [-17.3288, 145.4431],
  [-17.3295, 145.4418],
  [-17.3317, 145.4405],
  [-17.3322, 145.4403],
  [-17.3331, 145.4404],
  [-17.3338, 145.4402],
  [-17.3343, 145.4398],
  [-17.3347, 145.4390],
  [-17.3353, 145.4385],
  [-17.3361, 145.4385],
  [-17.3366, 145.4383],
  [-17.3378, 145.4373],
  [-17.3381, 145.4368],
  [-17.3383, 145.4354],
  [-17.3381, 145.4345],
  [-17.3382, 145.4337],
  [-17.3388, 145.4324],
  [-17.3400, 145.4315],
  [-17.3404, 145.4309],
  [-17.3407, 145.4298],
  [-17.3410, 145.4292],
  [-17.3421, 145.4282],
  [-17.3423, 145.4276],
  [-17.3420, 145.4270],
  [-17.3416, 145.4267],
  [-17.3410, 145.4268],
  [-17.3396, 145.4275],
  [-17.3389, 145.4284],
  [-17.3383, 145.4286],
  [-17.3377, 145.4284],
  [-17.3374, 145.4277],
  [-17.3377, 145.4270],
  [-17.3391, 145.4261],
  [-17.3397, 145.4254],
  [-17.3406, 145.4233],
  [-17.3414, 145.4224]
];

const herbertonRangeTunnel = [
  [-17.3414, 145.4224],
  [-17.3427, 145.4211]
];

const herbertonRangeTunnelHeberton = [
  [-17.3427, 145.4211],
  [-17.3430, 145.4205],
  [-17.3423, 145.4133],
  [-17.3426, 145.4124],
  [-17.3434, 145.4119],
  [-17.3467, 145.4114],
  [-17.3496, 145.4098],
  [-17.3503, 145.4096],
  [-17.3511, 145.4098],
  [-17.3523, 145.4106],
  [-17.3534, 145.4110],
  [-17.3550, 145.4114],
  [-17.3558, 145.4114],
  [-17.3596, 145.4103],
  [-17.3607, 145.4096],
  [-17.3618, 145.4085],
  [-17.3629, 145.4066],
  [-17.3646, 145.4053],
  [-17.3653, 145.4040],
  [-17.3655, 145.4027],
  [-17.3662, 145.4018],
  [-17.3680, 145.4015],
  [-17.3687, 145.4009],
  [-17.3689, 145.4000],
  [-17.3688, 145.3987],
  [-17.3691, 145.3976],
  [-17.3700, 145.3966],
  [-17.3703, 145.3957],
  [-17.3701, 145.3949],
  [-17.3696, 145.3941],
  [-17.3694, 145.3931],
  [-17.3699, 145.3923],
  [-17.3707, 145.3919],
  [-17.3712, 145.3915],
  [-17.3718, 145.3899],
  [-17.3718, 145.3891],
  [-17.3715, 145.3877],
  [-17.3718, 145.3868],
  [-17.3725, 145.3863],
  [-17.3737, 145.3864],
  [-17.3744, 145.3864],
  [-17.3756, 145.3862],
  [-17.3769, 145.3863],
  [-17.3795, 145.3876],
  [-17.3805, 145.3876],
  [-17.3814, 145.3871],
  [-17.3818, 145.3862],
  [-17.3821, 145.3854],
  [-17.3830, 145.3842],
  [-17.3837, 145.3839],
  [-17.3844, 145.3841],
  [-17.3848, 145.3842],
  [-17.3863, 145.3857]
];

const herbertonTumoulin = [
  [-17.3863, 145.3857],
  [-17.3870, 145.3863],
  [-17.3880, 145.3866],
  [-17.3888, 145.3864],
  [-17.3902, 145.3854],
  [-17.3914, 145.3852],
  [-17.3947, 145.3863],
  [-17.3954, 145.3862],
  [-17.3981, 145.3851],
  [-17.3991, 145.3852],
  [-17.3998, 145.3859],
  [-17.4003, 145.3882],
  [-17.4012, 145.3901],
  [-17.4019, 145.3906],
  [-17.4029, 145.3907],
  [-17.4035, 145.3903],
  [-17.4044, 145.3892],
  [-17.4049, 145.3889],
  [-17.4059, 145.3892],
  [-17.4081, 145.3915],
  [-17.4097, 145.3922],
  [-17.4202, 145.3906],
  [-17.4213, 145.3910],
  [-17.4219, 145.3920],
  [-17.4226, 145.3923],
  [-17.4278, 145.3909],
  [-17.4286, 145.3903],
  [-17.4296, 145.3890],
  [-17.4309, 145.3887],
  [-17.4332, 145.3894],
  [-17.4340, 145.3895],
  [-17.4362, 145.3891],
  [-17.4397, 145.3891],
  [-17.4406, 145.3887],
  [-17.4412, 145.3878],
  [-17.4414, 145.3852],
  [-17.4423, 145.3813],
  [-17.4430, 145.3803],
  [-17.4440, 145.3794],
  [-17.4445, 145.3787],
  [-17.4449, 145.3771],
  [-17.4457, 145.3764],
  [-17.4464, 145.3764],
  [-17.4485, 145.3773],
  [-17.4503, 145.3787],
  [-17.4511, 145.3790],
  [-17.4520, 145.3788],
  [-17.4552, 145.3770],
  [-17.4573, 145.3745],
  [-17.4624, 145.3711],
  [-17.4651, 145.3702],
  [-17.4657, 145.3696],
  [-17.4658, 145.3687],
  [-17.4652, 145.3676],
  [-17.4651, 145.3669],
  [-17.4651, 145.3657],
  [-17.4657, 145.3651],
  [-17.4669, 145.3651],
  [-17.4678, 145.3658],
  [-17.4686, 145.3660],
  [-17.4696, 145.3657],
  [-17.4711, 145.3647],
  [-17.4735, 145.3637],
  [-17.4744, 145.3637],
  [-17.4768, 145.3646],
  [-17.4773, 145.3652],
  [-17.4774, 145.3659],
  [-17.4769, 145.3673],
  [-17.4770, 145.3681],
  [-17.4777, 145.3688],
  [-17.4787, 145.3689],
  [-17.4810, 145.3677],
  [-17.4822, 145.3677],
  [-17.4852, 145.3688],
  [-17.4860, 145.3696],
  [-17.4868, 145.3709],
  [-17.4870, 145.3721],
  [-17.4869, 145.3741],
  [-17.4872, 145.3753],
  [-17.4890, 145.3777],
  [-17.4908, 145.3815],
  [-17.4920, 145.3826],
  [-17.4979, 145.3856],
  [-17.5026, 145.3885],
  [-17.5036, 145.3898],
  [-17.5058, 145.3990],
  [-17.5073, 145.4017],
  [-17.5100, 145.4039],
  [-17.5138, 145.4055],
  [-17.5386, 145.4239],
  [-17.5404, 145.4265],
  [-17.5410, 145.4287],
  [-17.5410, 145.4324],
  [-17.5414, 145.4340],
  [-17.5449, 145.4374],
  [-17.5534, 145.4413],
  [-17.5573, 145.4446],
  [-17.5609, 145.4499],
  [-17.5621, 145.4512],
  [-17.5649, 145.4531]
];

const tumoulinRavenshoe = [
  [-17.5649, 145.4531],
  [-17.5656, 145.4536],
  [-17.5666, 145.4539],
  [-17.5724, 145.4547],
  [-17.5739, 145.4550],
  [-17.5800, 145.4576],
  [-17.5809, 145.4576],
  [-17.5817, 145.4573],
  [-17.5823, 145.4565],
  [-17.5828, 145.4547],
  [-17.5836, 145.4540],
  [-17.5846, 145.4538],
  [-17.5854, 145.4541],
  [-17.5874, 145.4563],
  [-17.5879, 145.4577],
  [-17.5883, 145.4583],
  [-17.5892, 145.4591],
  [-17.5896, 145.4600],
  [-17.5895, 145.4610],
  [-17.5891, 145.4618],
  [-17.5890, 145.4627],
  [-17.5893, 145.4645],
  [-17.5896, 145.4651],
  [-17.5905, 145.4660],
  [-17.5909, 145.4666],
  [-17.5915, 145.4683],
  [-17.5915, 145.4691],
  [-17.5912, 145.4703],
  [-17.5916, 145.4715],
  [-17.5939, 145.4736],
  [-17.5952, 145.4753],
  [-17.5963, 145.4758],
  [-17.5978, 145.4756],
  [-17.5986, 145.4758],
  [-17.6008, 145.4764],
  [-17.6064, 145.4789],
  [-17.6069, 145.4798],
  [-17.6070, 145.4804],
  [-17.6074, 145.4813],
  [-17.6095, 145.4830]
];

export const tablelands: Line = {
  name: 'Tablelands',
  state: 'QLD',
  segments: [
    {
      segments: [
        {
          date: '1990-01-01',
          original: [cairnsOld],
          deviation: [cairnsNew]
        },
        cairnsRedlynch
      ],
      history: {
        opened: {
          date: '1887-10-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        redlynchTunnel1,
        {
          name: 'Barron Gorge No 1 Tunnel',
          segment: barronGorgeNo1Tunnel,
          type: 'tunnel'
        },
        tunnel1Tunnel2,
        {
          name: 'Barron Gorge No 2 Tunnel',
          segment: barronGorgeNo2Tunnel,
          type: 'tunnel'
        },
        tunnel2Tunnel3,
        {
          name: 'Barron Gorge No 3 Tunnel',
          segment: barronGorgeNo3Tunnel,
          type: 'tunnel'
        },
        tunnel3Tunnel4,
        {
          name: 'Barron Gorge No 4 Tunnel',
          segment: barronGorgeNo4Tunnel,
          type: 'tunnel'
        },
        tunnel4Tunnel5,
        {
          name: 'Barron Gorge No 5 Tunnel',
          segment: barronGorgeNo5Tunnel,
          type: 'tunnel'
        },
        tunnel5Tunnel6,
        {
          name: 'Barron Gorge No 6 Tunnel',
          segment: barronGorgeNo6Tunnel,
          type: 'tunnel'
        },
        tunnel6Tunnel7,
        {
          name: 'Barron Gorge No 7 Tunnel',
          segment: barronGorgeNo7Tunnel,
          type: 'tunnel'
        },
        tunnel7Tunnel8,
        {
          name: 'Barron Gorge No 8 Tunnel',
          segment: barronGorgeNo8Tunnel,
          type: 'tunnel'
        },
        tunnel8Tunnel9,
        {
          name: 'Barron Gorge No 9 Tunnel',
          segment: barronGorgeNo9Tunnel,
          type: 'tunnel'
        },
        tunnel9Tunnel10,
        {
          name: 'Barron Gorge No 10 Tunnel',
          segment: barronGorgeNo10Tunnel,
          type: 'tunnel'
        },
        tunnel10Tunnel11,
        {
          name: 'Barron Gorge No 11 Tunnel',
          segment: barronGorgeNo11Tunnel,
          type: 'tunnel'
        },
        tunnel11Tunnel12,
        {
          name: 'Barron Gorge No 12 Tunnel',
          segment: barronGorgeNo12Tunnel,
          type: 'tunnel'
        },
        tunnel12Tunnel13,
        {
          name: 'Barron Gorge No 13 Tunnel',
          segment: barronGorgeNo13Tunnel,
          type: 'tunnel'
        },
        tunnel13Tunnel14,
        {
          name: 'Barron Gorge No 14 Tunnel',
          segment: barronGorgeNo14Tunnel,
          type: 'tunnel'
        },
        tunnel14Tunnel15,
        {
          name: 'Barron Gorge No 15 Tunnel',
          segment: barronGorgeNo15Tunnel,
          type: 'tunnel'
        },
        tunnel15Myola
      ],
      history: {
        opened: {
          date: '1891-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [myolaMareeba],
      history: {
        opened: {
          date: '1893-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mareebaAtherton],
      history: {
        opened: {
          date: '1903-08-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-03-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        athertonHerbertonRangeTunnel,
        {
          name: 'Heberton Range Tunnel',
          segment: herbertonRangeTunnel,
          type: 'tunnel'
        },
        herbertonRangeTunnelHeberton
      ],
      history: {
        opened: {
          date: '1910-10-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [herbertonTumoulin],
      history: {
        opened: {
          date: '1911-07-31',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tumoulinRavenshoe],
      history: {
        opened: {
          date: '1916-12-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
