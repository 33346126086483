import { Line } from "../../../trackTypes";

const line = [
  [-21.4100, 139.8195],
  [-21.4108, 139.8192],
  [-21.4115, 139.8193],
  [-21.4394, 139.8355],
  [-21.4408, 139.8367],
  [-21.4419, 139.8397],
  [-21.4424, 139.8406],
  [-21.4543, 139.8585],
  [-21.4629, 139.8690],
  [-21.4689, 139.8759],
  [-21.4842, 139.9022]
];

export const trekalano: Line = {
  name: 'Trekalano',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1918-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1943-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
