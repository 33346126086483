import { Line } from "../../../trackTypes";

const wyreemaPittsworth = [
  [-27.6568, 151.8575],
  [-27.6580, 151.8575],
  [-27.6590, 151.8569],
  [-27.6598, 151.8559],
  [-27.6602, 151.8544],
  [-27.6600, 151.8533],
  [-27.6563, 151.8416],
  [-27.6562, 151.8403],
  [-27.6564, 151.8390],
  [-27.6571, 151.8370],
  [-27.6573, 151.8357],
  [-27.6574, 151.8201],
  [-27.6578, 151.8172],
  [-27.6596, 151.8121],
  [-27.6596, 151.8110],
  [-27.6595, 151.8100],
  [-27.6571, 151.8033],
  [-27.6568, 151.8022],
  [-27.6569, 151.8008],
  [-27.6594, 151.7889],
  [-27.6632, 151.7708],
  [-27.6641, 151.7691],
  [-27.6661, 151.7673],
  [-27.6667, 151.7670],
  [-27.6676, 151.7668],
  [-27.6682, 151.7663],
  [-27.6685, 151.7654],
  [-27.6684, 151.7646],
  [-27.6679, 151.7637],
  [-27.6676, 151.7630],
  [-27.6677, 151.7621],
  [-27.6685, 151.7601],
  [-27.6694, 151.7592],
  [-27.6706, 151.7587],
  [-27.6712, 151.7582],
  [-27.6716, 151.7572],
  [-27.6715, 151.7558],
  [-27.6692, 151.7524],
  [-27.6690, 151.7516],
  [-27.6691, 151.7508],
  [-27.6700, 151.7485],
  [-27.6708, 151.7476],
  [-27.6717, 151.7470],
  [-27.6756, 151.7458],
  [-27.6765, 151.7449],
  [-27.6767, 151.7438],
  [-27.6764, 151.7428],
  [-27.6747, 151.7401],
  [-27.6741, 151.7384],
  [-27.6739, 151.7349],
  [-27.6743, 151.7333],
  [-27.6774, 151.7269],
  [-27.6778, 151.7255],
  [-27.6787, 151.7129],
  [-27.6783, 151.7093],
  [-27.6797, 151.6919],
  [-27.6806, 151.6890],
  [-27.6843, 151.6827],
  [-27.6854, 151.6815],
  [-27.6973, 151.6710],
  [-27.7008, 151.6696],
  [-27.7015, 151.6689],
  [-27.7018, 151.6680],
  [-27.7019, 151.6667],
  [-27.7024, 151.6657],
  [-27.7032, 151.6649],
  [-27.7037, 151.6637],
  [-27.7035, 151.6625],
  [-27.7025, 151.6613],
  [-27.7019, 151.6599],
  [-27.7021, 151.6583],
  [-27.7089, 151.6437],
  [-27.7098, 151.6426],
  [-27.7109, 151.6418],
  [-27.7117, 151.6408],
  [-27.7149, 151.6332],
  [-27.7157, 151.6303]
];

const pittsworthMillmerran = [
  [-27.7157, 151.6303],
  [-27.7221, 151.6049],
  [-27.7222, 151.6027],
  [-27.7180, 151.5836],
  [-27.7180, 151.5827],
  [-27.7187, 151.5804],
  [-27.7186, 151.5794],
  [-27.7182, 151.5786],
  [-27.7174, 151.5781],
  [-27.7161, 151.5778],
  [-27.7153, 151.5772],
  [-27.7149, 151.5763],
  [-27.7150, 151.5751],
  [-27.7162, 151.5726],
  [-27.7163, 151.5716],
  [-27.7162, 151.5706],
  [-27.7147, 151.5673],
  [-27.7144, 151.5661],
  [-27.7145, 151.5649],
  [-27.7148, 151.5640],
  [-27.7157, 151.5621],
  [-27.7166, 151.5614],
  [-27.7173, 151.5612],
  [-27.7197, 151.5615],
  [-27.7206, 151.5613],
  [-27.7213, 151.5607],
  [-27.7225, 151.5581],
  [-27.7230, 151.5556],
  [-27.7226, 151.5536],
  [-27.7186, 151.5442],
  [-27.7183, 151.5425],
  [-27.7186, 151.5411],
  [-27.7224, 151.5323],
  [-27.7236, 151.5307],
  [-27.7261, 151.5285],
  [-27.7271, 151.5271],
  [-27.7618, 151.4421],
  [-27.7642, 151.4381],
  [-27.7674, 151.4348],
  [-27.7835, 151.4215],
  [-27.7878, 151.4169],
  [-27.8107, 151.3854],
  [-27.8127, 151.3836],
  [-27.8148, 151.3821],
  [-27.8355, 151.3705],
  [-27.8442, 151.3629],
  [-27.8469, 151.3584],
  [-27.8664, 151.2851],
  [-27.8671, 151.2763],
  [-27.8678, 151.2741],
  [-27.8687, 151.2728],
  [-27.8724, 151.2695],
  [-27.8738, 151.2689],
  [-27.8784, 151.2681]
];

export const millmerran: Line = {
  name: 'Millmerran',
  state: 'QLD',
  segments: [
    {
      segments: [wyreemaPittsworth],
      history: {
        opened: {
          date: '1885-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pittsworthMillmerran],
      history: {
        opened: {
          date: '1911-10-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
