import { Line } from "../../trackTypes";

const line = [
  [-41.2654, 146.4084],
  [-41.2646, 146.4104],
  [-41.2645, 146.4127],
  [-41.2655, 146.4162],
  [-41.2660, 146.4171],
  [-41.2670, 146.4178],
  [-41.2681, 146.4181]
];

export const shale: Line = {
  name: 'Shale',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1924-12-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1940-04-09',
          status: 'closed'
        }]
      }
    }
  ]
}
