import { Line } from "../../../trackTypes";

//  - paddock loop o1974-03-23
//  - truscotts rd o1976-08-01
//  - loop o1984-09-17

const ferrymeadReserve = [
  [-43.56373, 172.70805],
  [-43.56379, 172.70701],
  [-43.56385, 172.70687],
  [-43.56392, 172.70678],
  [-43.56404, 172.70672],
  [-43.56603, 172.70668]
];

const reserveEnd = [
  [-43.56603, 172.70668],
  [-43.56621, 172.70664],
  [-43.56640, 172.70650],
  [-43.56651, 172.70630],
  [-43.56657, 172.70612],
  [-43.56659, 172.70594],
  [-43.56647, 172.70437],
  [-43.56646, 172.70409],
  [-43.56650, 172.70383],
  [-43.56696, 172.70217],
  [-43.56701, 172.70206],
  [-43.56706, 172.70200],
  [-43.56714, 172.70197],
  [-43.56728, 172.70197]
];

const paddockLoopStart = [
  [-43.56728, 172.70197],
  [-43.56795, 172.70202],
  [-43.56813, 172.70199],
  [-43.56832, 172.70193],
  [-43.56846, 172.70193],
  [-43.56855, 172.70195],
  [-43.56878, 172.70209],
  [-43.56885, 172.70213],
  [-43.56896, 172.70216]
];

const truscottsRoad = [
  [-43.56896, 172.70216],
  [-43.56927, 172.70217],
  [-43.56938, 172.70213],
  [-43.56945, 172.70203],
  [-43.56950, 172.70189],
  [-43.56951, 172.70146]
];

const loopEnd = [
  [-43.56951, 172.70146],
  [-43.56945, 172.70137],
  [-43.56942, 172.70131],
  [-43.56933, 172.70126],
  [-43.56881, 172.70122],
  [-43.56874, 172.70127],
  [-43.56844, 172.70178],
  [-43.56837, 172.70187],
  [-43.56832, 172.70193]
];

export const ferrymeadTram: Line = {
  name: 'Ferrymead Tram',
  state: 'NZ',
  segments: [
    {
      segments: [ferrymeadReserve],
      history: {
        opened: {
          date: '1968-01-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [reserveEnd],
      history: {
        opened: {
          date: '1971-12-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [paddockLoopStart],
      history: {
        opened: {
          date: '1974-03-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [truscottsRoad],
      history: {
        opened: {
          date: '1976-08-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [loopEnd],
      history: {
        opened: {
          date: '1976-08-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
}
