import { Line } from "../../trackTypes";

const britomartLink = [
  [-36.8483, 174.7827],
  [-36.8480, 174.7799],
  [-36.8477, 174.7793],
  [-36.8472, 174.7786],
  [-36.8466, 174.7772]
];

const aucklandStationMechanicsBay = [
  [-36.8484, 174.7802],
  [-36.8483, 174.7827],
  [-36.8481, 174.7870]
];

const aucklandStationPurewaTunnel = [
  [-36.8481, 174.7870],
  [-36.8481, 174.7905],
  [-36.8484, 174.7916],
  [-36.8490, 174.7927],
  [-36.8594, 174.8045],
  [-36.8617, 174.8081],
  [-36.8659, 174.8191],
  [-36.8663, 174.8215],
  [-36.8662, 174.8234],
  [-36.8657, 174.8256],
  [-36.8654, 174.8289],
  [-36.8656, 174.8328],
  [-36.8660, 174.8353],
  [-36.8671, 174.8383],
  [-36.8687, 174.8417],
  [-36.8690, 174.8425]
];

const purewaTunnel = [
  [-36.8690, 174.8425],
  [-36.8713, 174.8485]
];

const purewaTunnelWestfieldJunction = [
  [-36.8713, 174.8485],
  [-36.8716, 174.8493],
  [-36.8727, 174.8513],
  [-36.8747, 174.8529],
  [-36.8793, 174.8542],
  [-36.8827, 174.8553],
  [-36.8856, 174.8555],
  [-36.8875, 174.8552],
  [-36.8927, 174.8533],
  [-36.8953, 174.8518],
  [-36.8976, 174.8498],
  [-36.9022, 174.8445],
  [-36.9031, 174.8437],
  [-36.9044, 174.8431],
  [-36.9089, 174.8416],
  [-36.9104, 174.8415],
  [-36.9168, 174.8432],
  [-36.9187, 174.8431],
  [-36.9206, 174.8421],
  [-36.9220, 174.8405],
  [-36.9264, 174.8322],
  [-36.9276, 174.8307],
  [-36.9286, 174.8302],
  [-36.9303, 174.8302]
];

export const eastern: Line = {
  name: 'Westfield Deviation (Eastern)',
  state: 'NZ',
  segments: [
    {
      segments: [britomartLink],
      history: {
        opened: {
          date: '2003-07-07',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [aucklandStationMechanicsBay],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        aucklandStationPurewaTunnel,
        {
          name: 'Purewa Tunnel',
          segment: purewaTunnel,
          type: 'tunnel'
        },
        purewaTunnelWestfieldJunction
      ],
      history: {
        opened: {
          date: '1929-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-05-11',
          tracks: 2
        }, {
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
