import { Line } from "../../../trackTypes";

const hendonAllora = [
  [-28.0708, 151.9459],
  [-28.0708, 151.9462],
  [-28.0707, 151.9466],
  [-28.0699, 151.9479],
  [-28.0690, 151.9495],
  [-28.0681, 151.9527],
  [-28.0680, 151.9565],
  [-28.0677, 151.9576],
  [-28.0624, 151.9687],
  [-28.0615, 151.9697],
  [-28.0486, 151.9777],
  [-28.0467, 151.9786],
  [-28.0400, 151.9797],
  [-28.0380, 151.9807],
  [-28.0355, 151.9812]
];

const hendonJunction = [
  [-28.0699, 151.9479],
  [-28.0706, 151.9471],
  [-28.0714, 151.9470],
  [-28.0722, 151.9474]
];

const alloraGoomburra = [
  [-28.0389, 151.9803],
  [-28.0381, 151.9811],
  [-28.0379, 151.9821],
  [-28.0381, 151.9851],
  [-28.0388, 151.9901],
  [-28.0393, 151.9913],
  [-28.0420, 151.9947],
  [-28.0428, 151.9973],
  [-28.0454, 152.0163],
  [-28.0452, 152.0174],
  [-28.0428, 152.0296],
  [-28.0427, 152.0307],
  [-28.0432, 152.0409],
  [-28.0431, 152.0416],
  [-28.0425, 152.0437],
  [-28.0424, 152.0452],
  [-28.0430, 152.0463],
  [-28.0441, 152.0479],
  [-28.0445, 152.0488],
  [-28.0455, 152.0518],
  [-28.0475, 152.0648],
  [-28.0482, 152.0898],
  [-28.0477, 152.0919],
  [-28.0435, 152.1026],
  [-28.0432, 152.1041],
  [-28.0427, 152.1179]
];

export const goomburra: Line = {
  name: 'Goomburra',
  state: 'QLD',
  segments: [
    {
      segments: [hendonAllora, hendonJunction],
      history: {
        opened: {
          date: '1897-04-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [alloraGoomburra],
      history: {
        opened: {
          date: '1912-07-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
