import { Line } from "../../../trackTypes";

const springhurstWahgunyah = [
  [-36.1844, 146.4716],
  [-36.1813, 146.4726],
  [-36.1796, 146.4727],
  [-36.1553, 146.4720],
  [-36.1377, 146.4721],
  [-36.1337, 146.4719],
  [-36.0738, 146.4732],
  [-36.0712, 146.4728],
  [-36.0686, 146.4717],
  [-36.0391, 146.4530],
  [-36.0368, 146.4511],
  [-36.0349, 146.4486],
  [-36.0127, 146.4078],
  [-36.0118, 146.4052],
  [-36.0112, 146.3995]
];

export const wahgunyah: Line = {
  name: 'Wahgunyah',
  state: 'VIC',
  segments: [
    {
      segments: [springhurstWahgunyah],
      history: {
        opened: {
          date: '1879-01-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-07-01',
          status: 'closed'
        }]
      }
    }
  ]
};
