import { Line } from "../../../../trackTypes";

const line = [
  [-37.79992, 144.90099],
  [-37.80015, 144.90298],
  [-37.80021, 144.90320],
  [-37.80026, 144.90328],
  [-37.80032, 144.90332],
  [-37.80043, 144.90330],
  [-37.80053, 144.90323],
  [-37.80066, 144.90304],
  [-37.80227, 144.89927],
  [-37.80233, 144.89915],
  [-37.80238, 144.89911],
  [-37.80400, 144.89881],
  [-37.80408, 144.89872],
  [-37.80411, 144.89857],
  [-37.80336, 144.89255],
  [-37.80338, 144.89243],
  [-37.80344, 144.89237],
  [-37.80654, 144.89182],
  [-37.80661, 144.89175],
  [-37.80664, 144.89160],
  [-37.80652, 144.89059],
  [-37.80657, 144.89048],
  [-37.80664, 144.89041],
  [-37.81334, 144.88923],
  [-37.81341, 144.88917],
  [-37.81345, 144.88905],
  [-37.81284, 144.88363]
];

export const footscraySouth: Line = {
  name: 'Footscray South',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
