import { Line } from "../../trackTypes";

const eastMaitlandMorpethFirst = [
  [-32.7455, 151.5877],
  [-32.7440, 151.5879],
  [-32.7434, 151.5882],
  [-32.7429, 151.5892],
  [-32.7424, 151.5907],
  [-32.7415, 151.5926],
  [-32.7379, 151.5971],
  [-32.7359, 151.6015],
  [-32.7349, 151.6028],
  [-32.7336, 151.6042],
  [-32.7320, 151.6065],
  [-32.7287, 151.6095],
  [-32.7273, 151.6114],
  [-32.7261, 151.6139],
  [-32.7246, 151.6218],
  [-32.7245, 151.6233],
  [-32.7246, 151.6249],
  [-32.7243, 151.6303]
];

const morpethFirstMorpeth = [
  [-32.7243, 151.6303],
  [-32.7241, 151.6328]
];

export const morpeth: Line = {
  name: 'Morpeth',
  state: 'NSW',
  segments: [
    {
      segments: [eastMaitlandMorpethFirst],
      history: {
        opened: {
          date: '1864-05-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-08-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morpethFirstMorpeth],
      history: {
        opened: {
          date: '1870-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-08-31',
          status: 'closed'
        }]
      }
    }
  ]
};
