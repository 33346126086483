import { Line } from "../../../trackTypes";

const centralIllawarraJunction = [
  [-33.8828, 151.2063],
  [-33.8882, 151.2020],
  [-33.8907, 151.1999],
  [-33.8924, 151.1979],
  [-33.8938, 151.1951],
  [-33.8952, 151.1902]
];

const southCoastJunctionNewtown = [
  [-33.8952, 151.1902],
  [-33.8963, 151.1870],
  [-33.8974, 151.1837],
  [-33.8977, 151.1820],
  [-33.8977, 151.1797]
];

const newtownHomebush = [
  [-33.8977, 151.1797],
  [-33.8975, 151.1766],
  [-33.8968, 151.1742],
  [-33.8957, 151.1725],
  [-33.8943, 151.1686],
  [-33.8941, 151.1660],
  [-33.8946, 151.1619],
  [-33.8943, 151.1598],
  [-33.8937, 151.1575],
  [-33.8937, 151.1558],
  [-33.8941, 151.1535],
  [-33.8941, 151.1515],
  [-33.8935, 151.1484],
  [-33.8902, 151.1387],
  [-33.8897, 151.1367],
  [-33.8896, 151.1327],
  [-33.8892, 151.1306],
  [-33.8870, 151.1243],
  [-33.8861, 151.1214],
  [-33.8852, 151.1192],
  [-33.8814, 151.1129],
  [-33.8793, 151.1103],
  [-33.8783, 151.1086],
  [-33.8773, 151.1054],
  [-33.8765, 151.1011],
  [-33.8749, 151.0980],
  [-33.8733, 151.0965],
  [-33.8727, 151.0958],
  [-33.8699, 151.0920],
  [-33.8673, 151.0875],
  [-33.8666, 151.0861]
];

const homebushLidcombe = [
  [-33.8666, 151.0861],
  [-33.8647, 151.0796],
  [-33.8646, 151.0770],
  [-33.8650, 151.0728],
  [-33.8650, 151.0719],
  [-33.8638, 151.0653],
  [-33.8638, 151.0602],
  [-33.8642, 151.0578],
  [-33.8645, 151.0557],
  [-33.8644, 151.0507],
  [-33.8643, 151.0474],
  [-33.8636, 151.0447]
];

const lidcombeGranville = [
  [-33.8636, 151.0447],
  [-33.8631, 151.0436],
  [-33.8617, 151.0420],
  [-33.8606, 151.0411],
  [-33.8505, 151.0340],
  [-33.8489, 151.0325],
  [-33.8471, 151.0306],
  [-33.8401, 151.0234],
  [-33.8384, 151.0211],
  [-33.8355, 151.0164],
  [-33.8332, 151.0125]
];

const granvilleGranvillJunction = [
  [-33.8332, 151.0125],
  [-33.8314, 151.0099]
];

const granvilleJunctionCabramattaJunction = [
  [-33.8314, 151.0099],
  [-33.8301, 151.0077],
  [-33.8295, 151.0062],
  [-33.8293, 151.0049],
  [-33.8293, 151.0038],
  [-33.8296, 151.0021],
  [-33.8306, 150.9999],
  [-33.8369, 150.9921],
  [-33.8379, 150.9911],
  [-33.8394, 150.9905],
  [-33.8410, 150.9906],
  [-33.8422, 150.9910],
  [-33.8432, 150.9912],
  [-33.8444, 150.9911],
  [-33.8452, 150.9907],
  [-33.8573, 150.9824],
  [-33.8585, 150.9812],
  [-33.8604, 150.9788],
  [-33.8686, 150.9637],
  [-33.8719, 150.9576],
  [-33.8735, 150.9555],
  [-33.8912, 150.9403]
];

const cabramattaJunctionLiverpool = [
  [-33.8912, 150.9403],
  [-33.8921, 150.9398],
  [-33.8940, 150.9390],
  [-33.9183, 150.9340],
  [-33.9198, 150.9333],
  [-33.9212, 150.9318],
  [-33.9225, 150.9298],
  [-33.9233, 150.9288],
  [-33.9250, 150.9272]
];

const liverpoolCampbelltown = [
  [-33.9250, 150.9272],
  [-33.9294, 150.9234],
  [-33.9309, 150.9224],
  [-33.9447, 150.9156],
  [-33.9462, 150.9144],
  [-33.9478, 150.9131],
  [-33.9495, 150.9122],
  [-33.9514, 150.9117],
  [-33.9528, 150.9118],
  [-33.9543, 150.9115],
  [-33.9558, 150.9108],
  [-33.9579, 150.9093],
  [-33.9665, 150.8993],
  [-33.9756, 150.8892],
  [-33.9794, 150.8849],
  [-34.0002, 150.8614],
  [-34.0089, 150.8515],
  [-34.0121, 150.8487],
  [-34.0147, 150.8473],
  [-34.0455, 150.8354],
  [-34.0475, 150.8342],
  [-34.0486, 150.8330],
  [-34.0639, 150.8140]
];

const campbelltownMacarthur = [
  [-34.0639, 150.8140],
  [-34.0657, 150.8118],
  [-34.0686, 150.8080],
  [-34.0707, 150.8031],
  [-34.0720, 150.7968]
];

export const macarthur: Line = {
  name: 'Macarthur',
  state: 'NSW',
  segments: [
    {
      segments: [centralIllawarraJunction],
      history: {
        opened: {
          date: '1855-09-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1891-01-01',
          tracks: 4
        }, {
          date: '1927-01-01',
          tracks: 6
        }]
      }
    },
    {
      segments: [southCoastJunctionNewtown],
      history: {
        opened: {
          date: '1855-09-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1891-01-01',
          tracks: 4
        }, {
          date: '1927-01-01',
          tracks: 6
        }]
      }
    },
    {
      segments: [newtownHomebush],
      history: {
        opened: {
          date: '1855-09-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1856-09-26',
          tracks: 2
        }, {
          date: '1891-01-01',
          tracks: 4
        }, {
          date: '1927-01-01',
          tracks: 6
        }]
      }
    },
    {
      segments: [homebushLidcombe],
      history: {
        opened: {
          date: '1855-09-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1856-09-26',
          tracks: 2
        }, {
          date: '1924-05-25',
          tracks: 4
        }]
      }
    },
    {
      segments: [lidcombeGranville],
      history: {
        opened: {
          date: '1856-09-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1857-01-01',
          tracks: 2
        }, {
          date: '1955-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [granvilleGranvillJunction],
      history: {
        opened: {
          date: '1856-09-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1857-01-01',
          tracks: 2
        }, {
          date: '1965-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [granvilleJunctionCabramattaJunction],
      history: {
        opened: {
          date: '1856-09-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1857-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [cabramattaJunctionLiverpool],
      history: {
        opened: {
          date: '1856-09-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1857-01-01',
          tracks: 2
        }, {
          date: '2013-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [liverpoolCampbelltown],
      history: {
        opened: {
          date: '1858-05-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-05-05',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1891-01-01',
          tracks: 2
        }, {
          date: '2013-01-01',
          tracks: 3
        }]
      }
    },
    {
      segments: [campbelltownMacarthur],
      history: {
        opened: {
          date: '1862-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1892-01-01',
          tracks: 2
        }, {
          date: '2013-01-01',
          tracks: 3
        }]
      }
    }
  ]
};
