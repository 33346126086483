import { Line } from "../../../trackTypes";

//central perth
const carbarnThomasStreetHayStreet = [
  [-31.96042, 115.87803],
  [-31.95532, 115.86236],
  [-31.95525, 115.86212],
  [-31.95177, 115.85152],
  [-31.94876, 115.84233],
  [-31.94725, 115.83764]
];

const colinStreetSpur = [
  [-31.94876, 115.84233],
  [-31.94877, 115.84219],
  [-31.94881, 115.84210],
  [-31.95261, 115.84039]
];

//south perth lines
const victoriaPark = [
  [-31.96042, 115.87803],
  [-31.96052, 115.87812],
  [-31.96063, 115.87813],
  [-31.96164, 115.87765],
  [-31.96172, 115.87766],
  [-31.96178, 115.87772],
  [-31.96198, 115.87818],
  [-31.96211, 115.87851],
  [-31.96765, 115.88777],
  [-31.96852, 115.88924],
  [-31.96964, 115.89083],
  [-31.96980, 115.89106],
  [-31.97087, 115.89302],
  [-31.97258, 115.89583],
  [-31.97271, 115.89600],
  [-31.97320, 115.89646],
  [-31.97384, 115.89701],
  [-31.97412, 115.89721],
  [-31.97469, 115.89754],
  [-31.97515, 115.89776],
  [-31.97735, 115.89856],
  [-31.97835, 115.89904],
  [-31.97856, 115.89916],
  [-31.97959, 115.89980],
  [-31.98848, 115.90563],
  [-31.98866, 115.90579],
  [-31.98906, 115.90623]
];

const welshpool = [
  [-31.98906, 115.90623],
  [-31.99293, 115.91160],
  [-31.99362, 115.91251],
  [-31.99370, 115.91266],
  [-31.99378, 115.91285],
  [-31.99381, 115.91318],
  [-31.99390, 115.91557],
  [-31.99401, 115.92086]
];

const southPerthZoo = [
  [-31.96765, 115.88777],
  [-31.96784, 115.88784],
  [-31.96800, 115.88783],
  [-31.98343, 115.87509],
  [-31.98350, 115.87492],
  [-31.98351, 115.87443],
  [-31.98348, 115.87431],
  [-31.98342, 115.87422],
  [-31.98164, 115.87213],
  [-31.98160, 115.87202],
  [-31.98159, 115.87187],
  [-31.98159, 115.86788],
  [-31.98157, 115.86761],
  [-31.97925, 115.85349],
  [-31.97922, 115.85340],
  [-31.97913, 115.85330],
  [-31.97430, 115.85124],
  [-31.97419, 115.85125],
  [-31.97406, 115.85130],
  [-31.97167, 115.85315],
  [-31.97164, 115.85322],
  [-31.97164, 115.85331],
  [-31.97183, 115.85374]
];

const comoJunction = [
  [-31.97913, 115.85330],
  [-31.97940, 115.85341]
];

const como = [
  [-31.97925, 115.85349],
  [-31.97929, 115.85343],
  [-31.97933, 115.85341],
  [-31.97940, 115.85341],
  [-31.99360, 115.85950],
  [-31.99366, 115.85949],
  [-31.99372, 115.85944],
  [-31.99377, 115.85935],
  [-31.99378, 115.85739],
  [-31.99379, 115.85734],
  [-31.99382, 115.85731],
  [-31.99387, 115.85728],
  [-31.99497, 115.85727]
];

//west perth
const subiaco = [
  [-31.94725, 115.83764],
  [-31.94719, 115.83740],
  [-31.94715, 115.83716],
  [-31.94704, 115.82418],
  [-31.94708, 115.82408],
  [-31.94716, 115.82402],
  [-31.95858, 115.82391]
];

const nedlands = [
  [-31.95858, 115.82391],
  [-31.95877, 115.82388],
  [-31.95890, 115.82382],
  [-31.96289, 115.81926],
  [-31.97043, 115.81360],
  [-31.97058, 115.81349],
  [-31.97069, 115.81346],
  [-31.97530, 115.81346],
  [-31.97552, 115.81348],
  [-31.97696, 115.81383]
];

const crawleyNedlandsBaths = [
  [-31.97696, 115.81383],
  [-31.97987, 115.81449],
  [-31.98018, 115.81453],
  [-31.98726, 115.81456],
  [-31.98737, 115.81458],
  [-31.98758, 115.81468],
  [-31.98831, 115.81530]
];

//north perth lines
const esplanadeBarrackStreet = [
  [-31.95949, 115.85799],
  [-31.95689, 115.85921],
  [-31.95182, 115.86161],
  [-31.95168, 115.86165],
  [-31.95161, 115.86167],
  [-31.95126, 115.86168],
  [-31.95108, 115.86172],
  [-31.95087, 115.86181],
  [-31.94764, 115.86387],
  [-31.94275, 115.86694],
  [-31.93460, 115.87207]
];

const northPerth = [
  [-31.94275, 115.86694],
  [-31.94265, 115.86694],
  [-31.94258, 115.86693],
  [-31.94253, 115.86690],
  [-31.93820, 115.85746],
  [-31.93814, 115.85739],
  [-31.93810, 115.85738],
  [-31.93803, 115.85739],
  [-31.93673, 115.85819],
  [-31.93653, 115.85830],
  [-31.93627, 115.85842],
  [-31.93315, 115.85913]
];

const northPerthAngoveStreet = [
  [-31.93315, 115.85913],
  [-31.93294, 115.85917],
  [-31.93274, 115.85918],
  [-31.93088, 115.85919]
];

const angoveStreetCharlesStreet = [
  [-31.93088, 115.85919],
  [-31.93079, 115.85914],
  [-31.93072, 115.85908],
  [-31.92749, 115.85271]
];

const mtLawley = [
  [-31.93460, 115.87207],
  [-31.93452, 115.87209],
  [-31.93445, 115.87209],
  [-31.93438, 115.87205],
  [-31.92679, 115.86319]
];

//inglewood line
const inglewood = [
  [-31.93460, 115.87207],
  [-31.92944, 115.87639],
  [-31.92928, 115.87654],
  [-31.92078, 115.88667],
  [-31.92059, 115.88694],
  [-31.92043, 115.88726]
];

const dundasStreetSailsburyStreet = [
  [-31.92043, 115.88726],
  [-31.92028, 115.88746],
  [-31.91721, 115.89127]
];

const sailsburyStreetGrandPromenard = [
  [-31.91721, 115.89127],
  [-31.91615, 115.89260],
  [-31.91595, 115.89291],
  [-31.91576, 115.89324]
];

//leederville =
const leedervilleCbd = [
  [-31.94764, 115.86387],
  [-31.94757, 115.86386],
  [-31.94750, 115.86380],
  [-31.94620, 115.86097]
];

const leederville = [
  [-31.94620, 115.86097],
  [-31.93748, 115.84193],
  [-31.93745, 115.84181],
  [-31.93743, 115.84171],
  [-31.93743, 115.84140],
  [-31.93740, 115.84132],
  [-31.93736, 115.84127],
  [-31.93729, 115.84123],
  [-31.93210, 115.84123]
];

const wembley = [
  [-31.93743, 115.84140],
  [-31.93744, 115.84131],
  [-31.93746, 115.84128],
  [-31.93752, 115.84123],
  [-31.93860, 115.84122],
  [-31.93866, 115.84118],
  [-31.93871, 115.84108],
  [-31.93872, 115.82779],
  [-31.93876, 115.82773],
  [-31.93885, 115.82767],
  [-31.94063, 115.82769],
  [-31.94068, 115.82765],
  [-31.94072, 115.82757],
  [-31.94073, 115.82484]
];

const wembleyExtension = [
  [-31.94073, 115.82484],
  [-31.94078, 115.81001]
];

const oxfordStreetMtHawthorne = [
  [-31.93210, 115.84123],
  [-31.92255, 115.84125],
  [-31.92245, 115.84122],
  [-31.92239, 115.84117],
  [-31.92234, 115.84108],
  [-31.92206, 115.84026],
  [-31.92190, 115.83982],
  [-31.92007, 115.83611],
  [-31.92003, 115.83604],
  [-31.91994, 115.83594]
];

const mtHawthorneosbornePark = [
  [-31.91994, 115.83594],
  [-31.91695, 115.83329],
  [-31.91684, 115.83318],
  [-31.91676, 115.83307],
  [-31.91500, 115.82991],
  [-31.91459, 115.82928],
  [-31.91441, 115.82897],
  [-31.91430, 115.82869],
  [-31.91425, 115.82838],
  [-31.91417, 115.82826],
  [-31.91409, 115.82821],
  [-31.89673, 115.82827]
];

//maylands
const lincolnStreet = [
  [-31.94764, 115.86387],
  [-31.94761, 115.86395],
  [-31.94760, 115.86403],
  [-31.94974, 115.86877],
  [-31.94975, 115.86891],
  [-31.94967, 115.86906],
  [-31.94371, 115.87349]
];

const maylands = [
  [-31.94371, 115.87349],
  [-31.93857, 115.87697],
  [-31.93849, 115.87705],
  [-31.93840, 115.87717],
  [-31.93628, 115.88150],
  [-31.93255, 115.88819],
  [-31.93083, 115.89185],
  [-31.93078, 115.89198],
  [-31.93071, 115.89219],
  [-31.92631, 115.90843],
  [-31.92564, 115.91107],
  [-31.92547, 115.91190]
];

//cbd
const murrayStreetDeviation = [
  [-31.95177, 115.85152],
  [-31.95176, 115.85161],
  [-31.95169, 115.85169],
  [-31.95082, 115.85207],
  [-31.95076, 115.85216],
  [-31.95075, 115.85226],
  [-31.95414, 115.86266],
  [-31.95420, 115.86270],
  [-31.95427, 115.86273],
  [-31.95520, 115.86229],
  [-31.95526, 115.86230],
  [-31.95532, 115.86236]
];

const pierStreetJunction = [
  [-31.95520, 115.86229],
  [-31.95523, 115.86223],
  [-31.95525, 115.86212]
];

//mount's bay road
const pointLewis = [
  [-31.95689, 115.85921],
  [-31.95696, 115.85910],
  [-31.95698, 115.85896],
  [-31.95633, 115.85710],
  [-31.95613, 115.85632],
  [-31.95609, 115.85608],
  [-31.95607, 115.85578],
  [-31.95602, 115.85543],
  [-31.95523, 115.85275],
  [-31.95513, 115.85203],
  [-31.95521, 115.85084],
  [-31.95537, 115.84959],
  [-31.95560, 115.84880],
  [-31.95602, 115.84804],
  [-31.95648, 115.84742],
  [-31.95702, 115.84697],
  [-31.95800, 115.84637],
  [-31.95853, 115.84614],
  [-31.95887, 115.84606],
  [-31.95923, 115.84603],
  [-31.95978, 115.84607],
  [-31.96088, 115.84628],
  [-31.96133, 115.84632],
  [-31.96163, 115.84625],
  [-31.96187, 115.84609],
  [-31.96203, 115.84588]
];

const cityBaths = [
  [-31.96203, 115.84588],
  [-31.96266, 115.84397],
  [-31.96296, 115.84284],
  [-31.96316, 115.84244],
  [-31.96339, 115.84212],
  [-31.96406, 115.84151],
  [-31.96431, 115.84115],
  [-31.96464, 115.84061],
  [-31.96491, 115.84027],
  [-31.96513, 115.84008],
  [-31.96541, 115.83992],
  [-31.96596, 115.83968],
  [-31.96633, 115.83948],
  [-31.96757, 115.83863],
  [-31.96796, 115.83832],
  [-31.96886, 115.83738],
  [-31.96918, 115.83697],
  [-31.96947, 115.83647],
  [-31.96971, 115.83585],
  [-31.97010, 115.83461],
  [-31.97035, 115.83422],
  [-31.97067, 115.83394],
  [-31.97108, 115.83371],
  [-31.97136, 115.83344],
  [-31.97154, 115.83321],
  [-31.97169, 115.83292],
  [-31.97217, 115.83152],
  [-31.97226, 115.83111],
  [-31.97230, 115.83075],
  [-31.97244, 115.82907]
];

const nedlandsConnection = [
  [-31.97244, 115.82907],
  [-31.97264, 115.82767],
  [-31.97279, 115.82704],
  [-31.97297, 115.82647],
  [-31.97316, 115.82605],
  [-31.97346, 115.82554],
  [-31.97449, 115.82417],
  [-31.97468, 115.82380],
  [-31.97482, 115.82346],
  [-31.97493, 115.82313],
  [-31.97500, 115.82303],
  [-31.97507, 115.82294],
  [-31.97604, 115.82188],
  [-31.97668, 115.82123],
  [-31.97690, 115.82106],
  [-31.97775, 115.82069],
  [-31.97808, 115.82060],
  [-31.97848, 115.82056],
  [-31.98066, 115.82054],
  [-31.98118, 115.82048],
  [-31.98217, 115.82056],
  [-31.98254, 115.82064],
  [-31.98289, 115.82079],
  [-31.98461, 115.82185],
  [-31.98500, 115.82195],
  [-31.98524, 115.82196],
  [-31.98579, 115.82183],
  [-31.98605, 115.82166],
  [-31.98622, 115.82149],
  [-31.98639, 115.82125],
  [-31.98653, 115.82095],
  [-31.98661, 115.82060],
  [-31.98664, 115.82027],
  [-31.98659, 115.81995],
  [-31.98646, 115.81931],
  [-31.98645, 115.81920],
  [-31.98640, 115.81891],
  [-31.98644, 115.81828],
  [-31.98649, 115.81813],
  [-31.98660, 115.81791],
  [-31.98835, 115.81556],
  [-31.98836, 115.81543],
  [-31.98831, 115.81530]
];

//wellingtonStreet
const wellingtonStreet = [
  [-31.95609, 115.85608],
  [-31.95601, 115.85600],
  [-31.95589, 115.85600],
  [-31.95136, 115.85802],
  [-31.95131, 115.85813],
  [-31.95129, 115.85827],
  [-31.95500, 115.86960],
  [-31.95500, 115.86969],
  [-31.95497, 115.86977],
  [-31.95313, 115.87060],
  [-31.95279, 115.87083],
  [-31.95216, 115.87146],
  [-31.95212, 115.87153],
  [-31.95210, 115.87157],
  [-31.95215, 115.87275],
  [-31.95212, 115.87285],
  [-31.95202, 115.87293],
  [-31.95028, 115.87306],
  [-31.95016, 115.87313],
  [-31.95009, 115.87321],
  [-31.95006, 115.87331],
  [-31.94999, 115.87984]
];

const claremont = [
  [-31.97493, 115.82313],
  [-31.97528, 115.82003],
  [-31.97539, 115.81929],
  [-31.97570, 115.81759],
  [-31.97582, 115.81718],
  [-31.97598, 115.81676],
  [-31.97712, 115.81420],
  [-31.97730, 115.81375],
  [-31.97740, 115.81334],
  [-31.97859, 115.80658],
  [-31.98204, 115.78729],
  [-31.98214, 115.78667],
  [-31.98255, 115.78354],
  [-31.98264, 115.78315],
  [-31.98308, 115.78148],
  [-31.98307, 115.78142],
  [-31.98305, 115.78137],
  [-31.98302, 115.78131],
  [-31.98283, 115.78127],
  [-31.98129, 115.78127],
  [-31.98121, 115.78129],
  [-31.98113, 115.78135],
  [-31.98080, 115.78204]
];

const claremontJunction = [
  [-31.98302, 115.78131],
  [-31.98323, 115.78142]
];

const westanaRoad = [
  [-31.98308, 115.78148],
  [-31.98314, 115.78143],
  [-31.98323, 115.78142],
  [-31.98823, 115.78142],
  [-31.98829, 115.78147],
  [-31.98835, 115.78155],
  [-31.98882, 115.78358],
  [-31.98889, 115.78384],
  [-31.99045, 115.78762],
  [-31.99050, 115.78775],
  [-31.99061, 115.78787],
  [-31.99070, 115.78792],
  [-31.99203, 115.78867],
  [-31.99212, 115.78870],
  [-31.99221, 115.78871],
  [-31.99465, 115.78870]
];

export const perthTrams: Line = {
  name: 'Perth Trams',
  state: 'WA',
  segments: [
    {
      segments: [carbarnThomasStreetHayStreet],
      history: {
        opened: {
          date: '1899-09-28',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-04-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [colinStreetSpur],
      history: {
        opened: {
          date: '1899-09-28',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1930-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaPark],
      history: {
        opened: {
          date: '1905-07-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [welshpool],
      history: {
        opened: {
          date: '1942-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southPerthZoo],
      history: {
        opened: {
          date: '1922-08-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [como, comoJunction],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [subiaco],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-04-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nedlands],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [crawleyNedlandsBaths],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northPerth],
      history: {
        opened: {
          date: '1906-12-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northPerthAngoveStreet],
      history: {
        opened: {
          date: '1910-03-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [angoveStreetCharlesStreet],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [inglewood],
      history: {
        opened: {
          date: '1916-09-09',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dundasStreetSailsburyStreet],
      history: {
        opened: {
          date: '1933-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sailsburyStreetGrandPromenard],
      history: {
        opened: {
          date: '1943-03-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [leederville],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [esplanadeBarrackStreet],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1958-04-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mtLawley],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oxfordStreetMtHawthorne],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mtHawthorneosbornePark],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1948-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wembley],
      history: {
        opened: {
          date: '1926-11-20',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wembleyExtension],
      history: {
        opened: {
          date: '1934-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-01-01',
           status: 'closed'
        }]
      }
    },
    {
      segments: [lincolnStreet],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maylands],
      history: {
        opened: {
          date: '1928-03-11',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1951-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [leedervilleCbd],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [murrayStreetDeviation, pierStreetJunction],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pointLewis],
      history: {
        opened: {
          date: '1905-001-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityBaths],
      history: {
        opened: {
          date: '1915-001-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nedlandsConnection],
      history: {
        opened: {
          date: '1929-07-08',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1938-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wellingtonStreet],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westanaRoad, claremont, claremontJunction],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
