import { Line } from "../../trackTypes";

const line = [
  [-35.3339, 138.8717],
  [-35.3362, 138.8710],
  [-35.3381, 138.8711],
  [-35.3401, 138.8717],
  [-35.3461, 138.8757],
  [-35.3480, 138.8777],
  [-35.3921, 138.9369],
  [-35.3938, 138.9407],
  [-35.3947, 138.9455],
  [-35.3965, 138.9488],
  [-35.4094, 138.9613],
  [-35.4107, 138.9632],
  [-35.4113, 138.9645],
  [-35.4119, 138.9672],
  [-35.4119, 138.9682],
  [-35.4115, 138.9691],
  [-35.4109, 138.9699],
  [-35.4081, 138.9724]
];

export const millang: Line = {
  name: 'Millang',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1884-12-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-06-17',
          status: 'closed'
        }]
      }
    }
  ]
};
