import { Line } from "../../trackTypes";

const adelaideAdelaideMainJunction = [
  [-34.9210, 138.5967],
  [-34.9212, 138.5918],
  [-34.9209, 138.5905],
  [-34.9195, 138.5883]
];

const adelaideMainJunctionGaolLoop = [
  [-34.9195, 138.5883],
  [-34.9185, 138.5869],
  [-34.9169, 138.5855]
];

const gaolLoopNorthAdelaideJunction = [
  [-34.9115, 138.5817],
  [-34.9169, 138.5855]
];

const northAdelaideJunctionDrycreekJunction = [
  [-34.9115, 138.5817],
  [-34.9097, 138.5810],
  [-34.9080, 138.5811],
  [-34.8972, 138.5841],
  [-34.8957, 138.5842],
  [-34.8780, 138.5828],
  [-34.8766, 138.5823],
  [-34.8750, 138.5816],
  [-34.8601, 138.5783],
  [-34.8570, 138.5780],
  [-34.8518, 138.5786],
  [-34.8432, 138.5811],
  [-34.8405, 138.5823],
  [-34.8380, 138.5837],
  [-34.8370, 138.5843]
];

const dryCreekJunctionSalisbury = [
  [-34.8370, 138.5843],
  [-34.8325, 138.5872],
  [-34.8307, 138.5883],
  [-34.8288, 138.5897],
  [-34.8032, 138.6126],
  [-34.7742, 138.6384],
  [-34.7699, 138.6411],
  [-34.7664, 138.6421],
  [-34.7595, 138.6423],
  [-34.7572, 138.6424]
];

const salisburyGawler = [
  [-34.7572, 138.6424],
  [-34.7556, 138.6427],
  [-34.7546, 138.6430],
  [-34.7511, 138.6439],
  [-34.7465, 138.6461],
  [-34.7412, 138.6495],
  [-34.7116, 138.6682],
  [-34.7070, 138.6698],
  [-34.7039, 138.6700],
  [-34.6987, 138.6721],
  [-34.6878, 138.6798],
  [-34.6840, 138.6839],
  [-34.6815, 138.6873],
  [-34.6775, 138.6911],
  [-34.6539, 138.7051],
  [-34.6490, 138.7087],
  [-34.6141, 138.7379],
  [-34.6118, 138.7391],
  [-34.6084, 138.7397]
];

const gawlerMorganJunction = [
  [-34.6084, 138.7397],
  [-34.6055, 138.7396],
  [-34.6019, 138.7380],
];

const morganJunctionGawlerCentral = [
  [-34.6019, 138.7380],
  [-34.6008, 138.7375],
  [-34.5993, 138.7374],
  [-34.5967, 138.7382],
  [-34.5957, 138.7394],
  [-34.5949, 138.7423],
  [-34.5947, 138.7431],
  [-34.5944, 138.7484]
];

export const gawler: Line = {
  name: 'Gawler',
  state: 'SA',
  segments: [
    {
      segments: [adelaideAdelaideMainJunction],
      history: {
        opened: {
          date: '1856-01-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1857-01-01',
          tracks: 4
        }, {
          date: '1883-01-01',
          tracks: 6
        }, {
          date: '1915-01-20',
          tracks: 8
        }]
      }
    },
    {
      segments: [adelaideMainJunctionGaolLoop],
      history: {
        opened: {
          date: '1856-01-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2022-06-12',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1857-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [gaolLoopNorthAdelaideJunction],
      history: {
        opened: {
          date: '1856-01-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-01',
          parallelStandard: true
        }, {
          date: '2022-06-12',
          electrified: {
            current:  'AC',
            isOverhead: true
          }
        }, {
          date: '1857-01-01',
          tracks: 4
        }]
      }
    },
    {
      segments: [northAdelaideJunctionDrycreekJunction],
      history: {
        opened: {
          date: '1857-01-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-01',
          parallelStandard: true
        }, {
          date: '2022-06-12',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [dryCreekJunctionSalisbury],
      history: {
        opened: {
          date: '1857-01-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          parallelStandard: true
        }, {
          date: '2022-06-12',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [salisburyGawler],
      history: {
        opened: {
          date: '1857-01-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2022-06-12',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [morganJunctionGawlerCentral],
      history: {
        opened: {
          date: '1911-05-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2022-06-12',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gawlerMorganJunction],
      history: {
        opened: {
          date: '1860-08-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2022-06-12',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
