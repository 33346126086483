import { Line } from "../../../trackTypes";

const line = [
  [-27.4777, 151.8139],
  [-27.4773, 151.8135],
  [-27.4769, 151.8134],
  [-27.4732, 151.8131],
  [-27.4651, 151.8127],
  [-27.4636, 151.8128],
  [-27.4622, 151.8133],
  [-27.4564, 151.8169],
  [-27.4541, 151.8176],
  [-27.4493, 151.8185],
  [-27.4482, 151.8190],
  [-27.4458, 151.8216],
  [-27.4441, 151.8222],
  [-27.4318, 151.8220],
  [-27.4118, 151.8250],
  [-27.3972, 151.8267],
  [-27.3806, 151.8293],
  [-27.3791, 151.8291],
  [-27.3724, 151.8257],
  [-27.3703, 151.8254],
  [-27.3672, 151.8248],
  [-27.3656, 151.8239],
  [-27.3555, 151.8173],
  [-27.3538, 151.8169],
  [-27.3525, 151.8171],
  [-27.3155, 151.8430],
  [-27.3130, 151.8452],
  [-27.3118, 151.8474],
  [-27.3105, 151.8484],
  [-27.3050, 151.8493],
  [-27.3032, 151.8487],
  [-27.3020, 151.8486],
  [-27.2999, 151.8489],
  [-27.2981, 151.8498],
  [-27.2981, 151.8498],
  [-27.2958, 151.8526],
  [-27.2950, 151.8532],
  [-27.2923, 151.8540],
  [-27.2913, 151.8546],
  [-27.2864, 151.8597],
  [-27.2841, 151.8630],
  [-27.2833, 151.8655],
  [-27.2835, 151.8664],
  [-27.2838, 151.8678],
  [-27.2837, 151.8688],
  [-27.2831, 151.8696],
  [-27.2821, 151.8703],
  [-27.2815, 151.8712],
  [-27.2813, 151.8720],
  [-27.2815, 151.8736],
  [-27.2813, 151.8747],
  [-27.2806, 151.8754],
  [-27.2794, 151.8762],
  [-27.2773, 151.8767],
  [-27.2755, 151.8776],
  [-27.2709, 151.8824],
  [-27.2701, 151.8841],
  [-27.2696, 151.8867],
  [-27.2691, 151.8876],
  [-27.2682, 151.8883],
  [-27.2615, 151.8906],
  [-27.2601, 151.8905],
  [-27.2591, 151.8902],
  [-27.2576, 151.8890],
  [-27.2568, 151.8890],
  [-27.2560, 151.8895],
  [-27.2557, 151.8906],
  [-27.2558, 151.8925],
  [-27.2553, 151.8935],
  [-27.2543, 151.8939],
  [-27.2531, 151.8937],
  [-27.2523, 151.8939],
  [-27.2517, 151.8944],
  [-27.2509, 151.8960],
  [-27.2499, 151.8972],
  [-27.2481, 151.8978],
  [-27.2463, 151.8973],
  [-27.2444, 151.8954],
  [-27.2436, 151.8950],
  [-27.2427, 151.8951],
  [-27.2416, 151.8954],
  [-27.2409, 151.8954],
  [-27.2385, 151.8941],
  [-27.2378, 151.8939],
  [-27.2360, 151.8939],
  [-27.2352, 151.8935],
  [-27.2347, 151.8929],
  [-27.2341, 151.8914],
  [-27.2336, 151.8907],
  [-27.2329, 151.8903],
  [-27.2317, 151.8899],
  [-27.2311, 151.8894],
  [-27.2303, 151.8881],
  [-27.2296, 151.8875],
  [-27.2287, 151.8874],
  [-27.2263, 151.8880],
  [-27.2253, 151.8878],
  [-27.2247, 151.8871],
  [-27.2244, 151.8860],
  [-27.2246, 151.8852],
  [-27.2262, 151.8825]
];

export const haden: Line = {
  name: 'Haden',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1910-12-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-05-01',
          status: 'closed'
        }]
      }
    }
  ]
}
