import { Line } from "../../../trackTypes";

const line = [
  [-38.3385, 145.1770],
  [-38.3397, 145.1756],
  [-38.3406, 145.1740],
  [-38.3419, 145.1720],
  [-38.3511, 145.1592],
  [-38.3530, 145.1567],
  [-38.3558, 145.1526],
  [-38.3609, 145.1453],
  [-38.3774, 145.1226],
  [-38.3790, 145.1209],
  [-38.3827, 145.1175],
  [-38.3834, 145.1165],
  [-38.3840, 145.1151],
  [-38.3851, 145.1111],
  [-38.3863, 145.1054],
  [-38.3873, 145.1030],
  [-38.3887, 145.1006],
  [-38.3895, 145.0984],
  [-38.3899, 145.0968],
  [-38.3901, 145.0925],
  [-38.3900, 145.0914],
  [-38.3898, 145.0906],
  [-38.3886, 145.0864],
  [-38.3871, 145.0840],
  [-38.3866, 145.0827],
  [-38.3863, 145.0814],
  [-38.3863, 145.0715],
  [-38.3860, 145.0681],
  [-38.3855, 145.0669],
  [-38.3851, 145.0660],
  [-38.3832, 145.0625],
  [-38.3828, 145.0616],
  [-38.3814, 145.0592],
  [-38.3812, 145.0586],
  [-38.3810, 145.0569],
  [-38.3811, 145.0524],
  [-38.3810, 145.0514],
  [-38.3806, 145.0490],
  [-38.3805, 145.0481],
  [-38.3810, 145.0408],
  [-38.3813, 145.0389],
  [-38.3812, 145.0371],
  [-38.3809, 145.0357],
  [-38.3809, 145.0341],
  [-38.3808, 145.0331],
  [-38.3805, 145.0325],
  [-38.3798, 145.0315],
  [-38.3794, 145.0303],
  [-38.3795, 145.0289],
  [-38.3808, 145.0255],
  [-38.3813, 145.0246]
];

export const redHill: Line = {
  name: 'Red Hill',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1921-12-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-02-25',
          status: 'closed'
        }]
      }
    }
  ]
};
