import { Line } from "../../../../trackTypes";

const eastMalvernConnector = [
  [-37.85765, 144.99229],
  [-37.85786, 144.99307],
  [-37.86079, 145.01478],
  [-37.86088, 145.01516],
  [-37.86550, 145.02586],
  [-37.86574, 145.02639],
  [-37.86558, 145.02603],
  [-37.86617, 145.02739],
  [-37.86618, 145.02760],
  [-37.86607, 145.02773],
  [-37.86220, 145.02837]
];

export const east7: Line = {
  name: 'East7',
  state: 'VIC',
  segments: [
    {
      segments: [eastMalvernConnector],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
