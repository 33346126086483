import { Line } from "../../trackTypes";

const northamToodyay = [
  [-31.6492, 116.6770],
  [-31.6484, 116.6751],
  [-31.6452, 116.6701],
  [-31.6426, 116.6668],
  [-31.6416, 116.6641],
  [-31.6416, 116.6606],
  [-31.6424, 116.6571],
  [-31.6423, 116.6541],
  [-31.6412, 116.6511],
  [-31.6392, 116.6484],
  [-31.6382, 116.6461],
  [-31.6379, 116.6445],
  [-31.6382, 116.6423],
  [-31.6394, 116.6403],
  [-31.6406, 116.6369],
  [-31.6416, 116.6315],
  [-31.6416, 116.6298],
  [-31.6390, 116.6186],
  [-31.6390, 116.6168],
  [-31.6397, 116.6151],
  [-31.6454, 116.6086],
  [-31.6465, 116.6052],
  [-31.6461, 116.6022],
  [-31.6443, 116.5995],
  [-31.6385, 116.5966],
  [-31.6368, 116.5962],
  [-31.6333, 116.5955],
  [-31.6313, 116.5943],
  [-31.6296, 116.5917],
  [-31.6292, 116.5897],
  [-31.6294, 116.5875],
  [-31.6312, 116.5831],
  [-31.6317, 116.5810],
  [-31.6325, 116.5764],
  [-31.6322, 116.5731],
  [-31.6306, 116.5697],
  [-31.6249, 116.5637],
  [-31.6236, 116.5619],
  [-31.6174, 116.5524],
  [-31.6086, 116.5345],
  [-31.6006, 116.5220],
  [-31.5979, 116.5194],
  [-31.5949, 116.5181],
  [-31.5923, 116.5177],
  [-31.5784, 116.5181],
  [-31.5767, 116.5174],
  [-31.5755, 116.5160],
  [-31.5750, 116.5142],
  [-31.5753, 116.5122],
  [-31.5763, 116.5107],
  [-31.5775, 116.5094],
  [-31.5785, 116.5074],
  [-31.5784, 116.5055],
  [-31.5778, 116.5041],
  [-31.5767, 116.5025],
  [-31.5758, 116.5009],
  [-31.5742, 116.4970],
  [-31.5726, 116.4909],
  [-31.5725, 116.4881],
  [-31.5731, 116.4843],
  [-31.5730, 116.4817],
  [-31.5719, 116.4788],
  [-31.5695, 116.4762],
  [-31.5676, 116.4753],
  [-31.5658, 116.4750],
  [-31.5638, 116.4752]
];

const toodayMillendon = [
  [-31.5517, 116.4680],
  [-31.5514, 116.4639],
  [-31.5509, 116.4614],
  [-31.5496, 116.4584],
  [-31.5487, 116.4554],
  [-31.5469, 116.4524],
  [-31.5447, 116.4504],
  [-31.5410, 116.4482],
  [-31.5399, 116.4477],
  [-31.5368, 116.4466],
  [-31.5352, 116.4456],
  [-31.5334, 116.4437],
  [-31.5275, 116.4333],
  [-31.5269, 116.4304],
  [-31.5279, 116.4271],
  [-31.5302, 116.4253],
  [-31.5334, 116.4255],
  [-31.5368, 116.4283],
  [-31.5391, 116.4290],
  [-31.5418, 116.4280],
  [-31.5440, 116.4259],
  [-31.5525, 116.4219],
  [-31.5548, 116.4212],
  [-31.5623, 116.4205],
  [-31.5652, 116.4191],
  [-31.5676, 116.4155],
  [-31.5707, 116.4050],
  [-31.5725, 116.4010],
  [-31.5734, 116.3984],
  [-31.5743, 116.3933],
  [-31.5756, 116.3905],
  [-31.5777, 116.3880],
  [-31.5794, 116.3843],
  [-31.5798, 116.3809],
  [-31.5794, 116.3765],
  [-31.5798, 116.3701],
  [-31.5793, 116.3667],
  [-31.5781, 116.3643],
  [-31.5759, 116.3617],
  [-31.5747, 116.3587],
  [-31.5746, 116.3548],
  [-31.5762, 116.3492],
  [-31.5764, 116.3462],
  [-31.5758, 116.3438],
  [-31.5717, 116.3356],
  [-31.5696, 116.3331],
  [-31.5664, 116.3311],
  [-31.5645, 116.3291],
  [-31.5634, 116.3268],
  [-31.5619, 116.3210],
  [-31.5610, 116.3188],
  [-31.5593, 116.3163],
  [-31.5587, 116.3140],
  [-31.5590, 116.3111],
  [-31.5602, 116.3084],
  [-31.5614, 116.3050],
  [-31.5628, 116.3031],
  [-31.5643, 116.3022],
  [-31.5664, 116.3015],
  [-31.5685, 116.2999],
  [-31.5699, 116.2970],
  [-31.5703, 116.2943],
  [-31.5707, 116.2929],
  [-31.5714, 116.2910],
  [-31.5717, 116.2888],
  [-31.5715, 116.2867],
  [-31.5707, 116.2844],
  [-31.5704, 116.2820],
  [-31.5713, 116.2789],
  [-31.5735, 116.2762],
  [-31.5743, 116.2747],
  [-31.5793, 116.2607],
  [-31.5797, 116.2579],
  [-31.5797, 116.2541],
  [-31.5808, 116.2483],
  [-31.5829, 116.2441],
  [-31.5879, 116.2400],
  [-31.5901, 116.2377],
  [-31.5968, 116.2275],
  [-31.6025, 116.2163],
  [-31.6060, 116.2116],
  [-31.6074, 116.2077],
  [-31.6101, 116.2016],
  [-31.6111, 116.1998],
  [-31.6195, 116.1901],
  [-31.6235, 116.1878],
  [-31.6260, 116.1875],
  [-31.6285, 116.1883],
  [-31.6315, 116.1901],
  [-31.6340, 116.1907],
  [-31.6385, 116.1902],
  [-31.6417, 116.1908],
  [-31.6431, 116.1907],
  [-31.6484, 116.1900],
  [-31.6500, 116.1889],
  [-31.6511, 116.1868],
  [-31.6514, 116.1847],
  [-31.6522, 116.1830],
  [-31.6535, 116.1819],
  [-31.6574, 116.1805],
  [-31.6598, 116.1804],
  [-31.6619, 116.1796],
  [-31.6629, 116.1782],
  [-31.6633, 116.1762],
  [-31.6629, 116.1689],
  [-31.6618, 116.1641],
  [-31.6621, 116.1607],
  [-31.6636, 116.1576],
  [-31.6655, 116.1530],
  [-31.6676, 116.1499],
  [-31.6723, 116.1420],
  [-31.6776, 116.1349],
  [-31.6786, 116.1332],
  [-31.6850, 116.1191],
  [-31.6881, 116.1152],
  [-31.6898, 116.1131],
  [-31.6927, 116.1108],
  [-31.6940, 116.1086],
  [-31.6957, 116.1041],
  [-31.6960, 116.1007],
  [-31.6972, 116.0942],
  [-31.6986, 116.0915],
  [-31.7005, 116.0900],
  [-31.7051, 116.0879],
  [-31.7165, 116.0803],
  [-31.7198, 116.0788],
  [-31.7275, 116.0777],
  [-31.7322, 116.0777],
  [-31.7347, 116.0767],
  [-31.7361, 116.0753],
  [-31.7380, 116.0713],
  [-31.7402, 116.0689],
  [-31.7429, 116.0686],
  [-31.7465, 116.0694],
  [-31.7518, 116.0692],
  [-31.7557, 116.0681],
  [-31.7605, 116.0682],
  [-31.7628, 116.0674],
  [-31.7652, 116.0662],
  [-31.7671, 116.0661],
  [-31.7700, 116.0664],
  [-31.7727, 116.0653],
  [-31.7753, 116.0615],
  [-31.7771, 116.0595],
  [-31.7782, 116.0575],
  [-31.7792, 116.0543],
  [-31.7809, 116.0522],
  [-31.7870, 116.0491],
  [-31.7899, 116.0463],
  [-31.7915, 116.0429],
  [-31.7930, 116.0368],
  [-31.7955, 116.0324],
  [-31.7992, 116.0298],
  [-31.8041, 116.0283]
];

export const avonValleyDeviation: Line = {
  name: 'Avon Valley Deviation',
  state: 'WA',
  segments: [
    {
      segments: [northamToodyay],
      history: {
        opened: {
          date: '1966-02-14',
          tracks: 2,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [toodayMillendon],
      history: {
        opened: {
          date: '1966-02-14',
          tracks: 2,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    }
  ]
};
