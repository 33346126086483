import { Line } from "../../../trackTypes";

const sarinaJilalan = [
  [-21.4706, 149.2341],
  [-21.4640, 149.2347],
  [-21.4627, 149.2351],
  [-21.4593, 149.2372],
  [-21.4579, 149.2374],
  [-21.4449, 149.2371],
  [-21.4407, 149.2377],
  [-21.4392, 149.2384],
  [-21.4369, 149.2407],
  [-21.4360, 149.2411],
  [-21.4262, 149.2427],
];

const jilalanHayPoint = [
  [-21.4262, 149.2427],
  [-21.4241, 149.2436],
  [-21.4220, 149.2456],
  [-21.4207, 149.2461],
  [-21.4192, 149.2459],
  [-21.4157, 149.2441],
  [-21.4143, 149.2439],
  [-21.4119, 149.2447],
  [-21.4107, 149.2458],
  [-21.4103, 149.2468],
  [-21.4101, 149.2482],
  [-21.4093, 149.2498],
  [-21.4042, 149.2542],
  [-21.4019, 149.2553],
  [-21.3931, 149.2566],
  [-21.3912, 149.2573],
  [-21.3817, 149.2634],
  [-21.3805, 149.2652],
  [-21.3795, 149.2681],
  [-21.3772, 149.2704],
  [-21.3707, 149.2719],
  [-21.3688, 149.2727],
  [-21.3603, 149.2779],
  [-21.3574, 149.2789],
  [-21.3385, 149.2841],
  [-21.3283, 149.2840],
  [-21.3261, 149.2834],
  [-21.3201, 149.2806],
  [-21.3175, 149.2803],
  [-21.3125, 149.2813],
  [-21.3096, 149.2811],
  [-21.3034, 149.2786],
  [-21.3015, 149.2786],
  [-21.2999, 149.2792],
  [-21.2903, 149.2883],
  [-21.2882, 149.2896],
  [-21.2834, 149.2923],
  [-21.2823, 149.2923],
  [-21.2813, 149.2917],
  [-21.2805, 149.2897],
  [-21.2806, 149.2884],
  [-21.2816, 149.2875],
  [-21.2823, 149.2873],
  [-21.2830, 149.2874],
  [-21.2869, 149.2894],
  [-21.2876, 149.2896],
  [-21.2882, 149.2896]
];

export const hayPoint: Line = {
  name: 'Hay Point',
  state: 'QLD',
  segments: [
    {
      segments: [sarinaJilalan],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2009-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [jilalanHayPoint],
      history: {
        opened: {
          date: '1971-11-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2013-01-01',
          tracks: 2
        }]
      }
    }
  ]
}
