import { Line } from "../../../trackTypes";

const line = [
  [-38.1822, 146.2748],
  [-38.1836, 146.2762],
  [-38.1856, 146.2772],
  [-38.1895, 146.2777],
  [-38.1923, 146.2773],
  [-38.1977, 146.2761],
  [-38.1997, 146.2754],
  [-38.2013, 146.2745],
  [-38.2068, 146.2741],
  [-38.2076, 146.2739],
  [-38.2081, 146.2738],
  [-38.2093, 146.2732],
  [-38.2102, 146.2733],
  [-38.2110, 146.2738],
  [-38.2119, 146.2751],
  [-38.2124, 146.2762],
  [-38.2131, 146.2770],
  [-38.2141, 146.2772],
  [-38.2155, 146.2772],
  [-38.2161, 146.2774],
  [-38.2170, 146.2776],
  [-38.2181, 146.2774],
  [-38.2190, 146.2766],
  [-38.2202, 146.2746],
  [-38.2208, 146.2739],
  [-38.2224, 146.2724],
  [-38.2239, 146.2718],
  [-38.2260, 146.2709],
  [-38.2272, 146.2710],
  [-38.2287, 146.2693],
  [-38.2301, 146.2675],
  [-38.2324, 146.2648],
  [-38.2338, 146.2641],
  [-38.2345, 146.2635],
  [-38.2357, 146.2609],
  [-38.2371, 146.2590],
  [-38.2375, 146.2587],
  [-38.2387, 146.2587],
  [-38.2393, 146.2584],
  [-38.2419, 146.2565],
  [-38.2433, 146.2556],
  [-38.2450, 146.2536],
  [-38.2465, 146.2505],
  [-38.2478, 146.2473],
  [-38.2482, 146.2464],
  [-38.2511, 146.2417],
  [-38.2520, 146.2405],
  [-38.2536, 146.2396],
  [-38.2560, 146.2386],
  [-38.2572, 146.2375],
  [-38.2579, 146.2359],
  [-38.2584, 146.2336],
  [-38.2591, 146.2313],
  [-38.2602, 146.2295],
  [-38.2620, 146.2283],
  [-38.2625, 146.2276],
  [-38.2633, 146.2260],
  [-38.2641, 146.2251],
  [-38.2659, 146.2228],
  [-38.2677, 146.2214],
  [-38.2680, 146.2208],
  [-38.2682, 146.2190],
  [-38.2683, 146.2143],
  [-38.2685, 146.2128],
  [-38.2682, 146.2120],
  [-38.2676, 146.2102],
  [-38.2675, 146.2085],
  [-38.2679, 146.2066],
  [-38.2690, 146.2048],
  [-38.2697, 146.2033],
  [-38.2701, 146.2010],
  [-38.2707, 146.1993],
  [-38.2715, 146.1983],
  [-38.2724, 146.1976],
  [-38.2753, 146.1927],
  [-38.2756, 146.1915],
  [-38.2764, 146.1853],
  [-38.2767, 146.1840],
  [-38.2771, 146.1831],
  [-38.2796, 146.1781],
  [-38.2802, 146.1772],
  [-38.2809, 146.1767],
  [-38.2838, 146.1754],
  [-38.2869, 146.1755]
];

export const thorpdale: Line = {
  name: 'Thorpdale',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-12-03',
          status: 'closed'
        }]
      }
    }
  ]
};
