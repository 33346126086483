import { Line } from "../../../trackTypes";

const bendigoRangelea = [
  [-36.7455, 144.2962],
  [-36.7446, 144.2977],
  [-36.7443, 144.2993],
  [-36.7443, 144.3002],
  [-36.7448, 144.3045],
  [-36.7458, 144.3087],
  [-36.7475, 144.3126],
  [-36.7562, 144.3293],
  [-36.7618, 144.3379]
];

const rangeleaHeathcote = [
  [-36.7618, 144.3379],
  [-36.7713, 144.3466],
  [-36.7730, 144.3493],
  [-36.7758, 144.3596],
  [-36.7773, 144.3655],
  [-36.7821, 144.3933],
  [-36.7832, 144.4065],
  [-36.7831, 144.4083],
  [-36.7797, 144.4359],
  [-36.7788, 144.4513],
  [-36.7788, 144.4528],
  [-36.7793, 144.4552],
  [-36.7809, 144.4612],
  [-36.7811, 144.4631],
  [-36.7811, 144.4726],
  [-36.7813, 144.4742],
  [-36.7830, 144.4809],
  [-36.7861, 144.4892],
  [-36.7871, 144.4908],
  [-36.7944, 144.4956],
  [-36.7960, 144.4986],
  [-36.7979, 144.5032],
  [-36.7995, 144.5100],
  [-36.7988, 144.5151],
  [-36.7986, 144.5179],
  [-36.7995, 144.5215],
  [-36.8037, 144.5323],
  [-36.8154, 144.5556],
  [-36.8165, 144.5590],
  [-36.8191, 144.5729],
  [-36.8205, 144.5773],
  [-36.8283, 144.5876],
  [-36.8308, 144.5919],
  [-36.8507, 144.6127],
  [-36.8577, 144.6211],
  [-36.8595, 144.6224],
  [-36.8619, 144.6231],
  [-36.8659, 144.6230],
  [-36.8679, 144.6238],
  [-36.8701, 144.6257],
  [-36.8712, 144.6273],
  [-36.8755, 144.6380],
  [-36.8778, 144.6419],
  [-36.8795, 144.6443],
  [-36.8809, 144.6458],
  [-36.8816, 144.6497],
  [-36.8823, 144.6526],
  [-36.8834, 144.6543],
  [-36.8853, 144.6560],
  [-36.8867, 144.6576],
  [-36.8887, 144.6609],
  [-36.8905, 144.6648],
  [-36.8918, 144.6666],
  [-36.8958, 144.6701],
  [-36.9012, 144.6766],
  [-36.9023, 144.6789],
  [-36.9031, 144.6806],
  [-36.9060, 144.6845],
  [-36.9078, 144.6885],
  [-36.9091, 144.6907],
  [-36.9104, 144.6918],
  [-36.9133, 144.6941],
  [-36.9152, 144.6956],
  [-36.9184, 144.6985],
  [-36.9226, 144.7031]
];

const tooboracHeathcote = [
  [-37.0428, 144.7999],
  [-37.0399, 144.7999],
  [-37.0380, 144.7993],
  [-37.0364, 144.7985],
  [-37.0328, 144.7962],
  [-37.0300, 144.7946],
  [-37.0268, 144.7939],
  [-37.0242, 144.7912],
  [-37.0219, 144.7895],
  [-37.0187, 144.7888],
  [-37.0161, 144.7870],
  [-37.0131, 144.7863],
  [-37.0029, 144.7869],
  [-37.0002, 144.7863],
  [-36.9979, 144.7850],
  [-36.9899, 144.7787],
  [-36.9795, 144.7750],
  [-36.9775, 144.7736],
  [-36.9761, 144.7720],
  [-36.9671, 144.7572],
  [-36.9637, 144.7534],
  [-36.9602, 144.7501],
  [-36.9542, 144.7421],
  [-36.9510, 144.7352],
  [-36.9450, 144.7257],
  [-36.9397, 144.7174],
  [-36.9375, 144.7156],
  [-36.9348, 144.7145],
  [-36.9335, 144.7138],
  [-36.9273, 144.7085],
  [-36.9226, 144.7031]
];

const kilmoreTooborac = [
  [-37.2967, 144.9470],
  [-37.2928, 144.9446],
  [-37.2907, 144.9429],
  [-37.2782, 144.9295],
  [-37.2756, 144.9266],
  [-37.2722, 144.9217],
  [-37.2713, 144.9200],
  [-37.2711, 144.9185],
  [-37.2709, 144.9154],
  [-37.2691, 144.9118],
  [-37.2657, 144.9093],
  [-37.2534, 144.9081],
  [-37.2261, 144.9011],
  [-37.2099, 144.8936],
  [-37.2004, 144.8914],
  [-37.1872, 144.8904],
  [-37.1829, 144.8896],
  [-37.1790, 144.8875],
  [-37.1762, 144.8838],
  [-37.1742, 144.8805],
  [-37.1719, 144.8787],
  [-37.1627, 144.8766],
  [-37.1546, 144.8763],
  [-37.1524, 144.8755],
  [-37.1497, 144.8742],
  [-37.1467, 144.8727],
  [-37.1432, 144.8716],
  [-37.1403, 144.8714],
  [-37.1376, 144.8719],
  [-37.1327, 144.8739],
  [-37.1307, 144.8741],
  [-37.1256, 144.8726],
  [-37.1215, 144.8703],
  [-37.1192, 144.8682],
  [-37.1161, 144.8667],
  [-37.1076, 144.8634],
  [-37.1005, 144.8595],
  [-37.0987, 144.8577],
  [-37.0976, 144.8558],
  [-37.0966, 144.8530],
  [-37.0954, 144.8508],
  [-37.0937, 144.8485],
  [-37.0923, 144.8473],
  [-37.0912, 144.8456],
  [-37.0897, 144.8420],
  [-37.0840, 144.8356],
  [-37.0796, 144.8337],
  [-37.0766, 144.8316],
  [-37.0751, 144.8298],
  [-37.0727, 144.8259],
  [-37.0610, 144.8139],
  [-37.0499, 144.8011],
  [-37.0484, 144.8000],
  [-37.0474, 144.7997],
  [-37.0428, 144.7999]
];

const heathcoteJunctionKilmore = [
  [-37.3711, 145.0277],
  [-37.3704, 145.0251],
  [-37.3704, 145.0230],
  [-37.3709, 145.0209],
  [-37.3717, 145.0191],
  [-37.3723, 145.0177],
  [-37.3735, 145.0164],
  [-37.3816, 145.0141],
  [-37.3831, 145.0130],
  [-37.3839, 145.0110],
  [-37.3837, 145.0081],
  [-37.3827, 145.0064],
  [-37.3800, 145.0030],
  [-37.3785, 145.0000],
  [-37.3774, 144.9967],
  [-37.3761, 144.9947],
  [-37.3686, 144.9880],
  [-37.3677, 144.9863],
  [-37.3669, 144.9836],
  [-37.3656, 144.9739],
  [-37.3646, 144.9706],
  [-37.3628, 144.9683],
  [-37.3597, 144.9663],
  [-37.3569, 144.9657],
  [-37.3546, 144.9660],
  [-37.3495, 144.9673],
  [-37.3441, 144.9676],
  [-37.3395, 144.9665],
  [-37.3348, 144.9643],
  [-37.3310, 144.9636],
  [-37.3278, 144.9637],
  [-37.3234, 144.9641],
  [-37.3202, 144.9626],
  [-37.3180, 144.9601],
  [-37.3103, 144.9505],
  [-37.3091, 144.9495],
  [-37.3075, 144.9489],
  [-37.3016, 144.9498],
  [-37.3005, 144.9498],
  [-37.2991, 144.9488],
  [-37.2967, 144.9470]
];

export const heathcote: Line = {
  name: 'Heathcote',
  state: 'VIC',
  segments: [
    {
      segments: [bendigoRangelea],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-05-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rangeleaHeathcote],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-12-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tooboracHeathcote],
      history: {
        opened: {
          date: '1889-12-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-11-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heathcoteJunctionKilmore],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-11-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kilmoreTooborac],
      history: {
        opened: {
          date: '1890-08-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-11-07',
          status: 'closed'
        }]
      }
    }
  ]
};
