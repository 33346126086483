import { Line } from "../../../../trackTypes";

const connector = [
  [-27.45175, 153.03951],
  [-27.45162, 153.03978],
  [-27.45166, 153.03999],
  [-27.45209, 153.04065],
  [-27.45216, 153.04071],
  [-27.45228, 153.04074]
];

const annStreetShort = [
  [-27.45228, 153.04074],
  [-27.45258, 153.04061]
];

const bulimbaFerry = [
  [-27.45258, 153.04061],
  [-27.45269, 153.04063],
  [-27.45277, 153.04070],
  [-27.45425, 153.04656],
  [-27.45426, 153.04667],
  [-27.45423, 153.04677],
  [-27.45417, 153.04684],
  [-27.45209, 153.04890]
];

const annStreetEast = [
  [-27.45228, 153.04074],
  [-27.45066, 153.04119],
  [-27.45043, 153.04128],
  [-27.45020, 153.04140]
];

const annStreetWest = [
  [-27.45258, 153.04061],
  [-27.45280, 153.04049],
  [-27.45302, 153.04033],
  [-27.45362, 153.03981],
  [-27.45564, 153.03782],
  [-27.46197, 153.03158],
  [-27.46226, 153.03137],
  [-27.46256, 153.03126]
];

const adelaideStreet = [
  [-27.47030, 153.02290],
  [-27.47020, 153.02288],
  [-27.47011, 153.02291],
  [-27.46505, 153.02940],
  [-27.46364, 153.03125],
  [-27.46353, 153.03132],
  [-27.46341, 153.03134]
];

const wharfStreetJunction = [
  [-27.46482, 153.02971],
  [-27.46495, 153.02967],
  [-27.46507, 153.02969]
];

const georgeStreetNorthEastJunction = [
  [-27.47011, 153.02291],
  [-27.47009, 153.02280],
  [-27.47005, 153.02267]
];

const wharfStreetSouthWestJunction = [
  [-27.46505, 153.02940],
  [-27.46503, 153.02951],
  [-27.46504, 153.02960],
  [-27.46507, 153.02969]
];

const annStreet = [
  [-27.46225, 153.03122],
  [-27.46238, 153.03114],
  [-27.46249, 153.03103],
  [-27.46412, 153.02894],
  [-27.46420, 153.02891],
  [-27.46428, 153.02892]
];

export const east3: Line = {
  name: 'East 3',
  state: 'QLD',
  segments: [
    {
      segments: [annStreetShort],
      history: {
        opened: {
          date: '1885-08-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1898-01-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [connector, bulimbaFerry],
      history: {
        opened: {
          date: '1885-08-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1898-01-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1963-07-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [annStreetEast, annStreetWest],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [adelaideStreet],
      history: {
        opened: {
          date: '1917-02-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wharfStreetJunction],//south east
      history: {
        opened: {
          date: '1925-07-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [georgeStreetNorthEastJunction],
      history: {
        opened: {
          date: '1930-11-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wharfStreetSouthWestJunction],
      history: {
        opened: {
          date: '1931-06-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [annStreet],
      history: {
        opened: {
          date: '1946-07-16',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
