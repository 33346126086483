import { Line } from "../../../trackTypes";

const northBundabergRosedale = [
  [-24.8592, 152.3358],
  [-24.8593, 152.3339],
  [-24.8592, 152.3333],
  [-24.8584, 152.3314],
  [-24.8372, 152.3031],
  [-24.7883, 152.2381],
  [-24.7548, 152.1933],
  [-24.7451, 152.1792],
  [-24.7437, 152.1765],
  [-24.7383, 152.1629],
  [-24.7381, 152.1620],
  [-24.7382, 152.1613],
  [-24.7384, 152.1604],
  [-24.7383, 152.1596],
  [-24.7374, 152.1561],
  [-24.7373, 152.1543],
  [-24.7377, 152.1481],
  [-24.7377, 152.1464]
];

const yandaranOld = [
  [-24.7377, 152.1464],
  [-24.7372, 152.1453],
  [-24.7352, 152.1441],
  [-24.7346, 152.1429],
  [-24.7344, 152.1396],
  [-24.7347, 152.1384],
  [-24.7354, 152.1367],
  [-24.7354, 152.1353],
  [-24.7351, 152.1345],
  [-24.7322, 152.1327],
  [-24.7311, 152.1312],
  [-24.7300, 152.1278]
];

const yandaranDeviation = [
  [-24.7377, 152.1464],
  [-24.7374, 152.1442],
  [-24.7365, 152.1403],
  [-24.7347, 152.1362],
  [-24.7316, 152.1309],
  [-24.7300, 152.1278]
];

const yandaranDeviationEndWatalganDeviationStart = [
  [-24.7300, 152.1278],
  [-24.7270, 152.1204],
  [-24.7257, 152.1185],
  [-24.7229, 152.1165],
  [-24.7217, 152.1152],
  [-24.7207, 152.1139],
  [-24.7171, 152.1065],
  [-24.7157, 152.1051],
  [-24.7077, 152.1014],
  [-24.7069, 152.1009],
  [-24.6847, 152.0780],
  [-24.6780, 152.0740],
  [-24.6612, 152.0638],
  [-24.6602, 152.0628],
  [-24.6592, 152.0609],
  [-24.6579, 152.0597],
  [-24.6561, 152.0588],
  [-24.6552, 152.0579],
  [-24.6509, 152.0517],
  [-24.6505, 152.0507],
  [-24.6492, 152.0398],
  [-24.6489, 152.0384]
];

const watalganOld = [
  [-24.6489, 152.0384],
  [-24.6485, 152.0359],
  [-24.6485, 152.0336],
  [-24.6486, 152.0305],
  [-24.6484, 152.0286],
  [-24.6465, 152.0231],
  [-24.6462, 152.0215],
  [-24.6462, 152.0192],
  [-24.6465, 152.0173],
  [-24.6476, 152.0139],
  [-24.6476, 152.0118],
  [-24.6460, 152.0070],
  [-24.6456, 152.0053],
  [-24.6458, 152.0014],
  [-24.6455, 151.9998],
  [-24.6444, 151.9985],
  [-24.6441, 151.9972],
  [-24.6445, 151.9937],
  [-24.6444, 151.9920],
  [-24.6435, 151.9892],
  [-24.6435, 151.9876],
  [-24.6445, 151.9839],
  [-24.6444, 151.9824],
  [-24.6428, 151.9785],
  [-24.6421, 151.9744],
  [-24.6410, 151.9721],
  [-24.6409, 151.9711],
  [-24.6411, 151.9686],
  [-24.6418, 151.9625],
  [-24.6414, 151.9599],
  [-24.6328, 151.9459],
  [-24.6321, 151.9436],
  [-24.6317, 151.9349],
  [-24.6319, 151.9328],
  [-24.6326, 151.9298],
  [-24.6325, 151.9284],
  [-24.6318, 151.9274],
  [-24.6301, 151.9259],
  [-24.6292, 151.9241]
];

const watalganDeviation = [
  [-24.6489, 152.0384],
  [-24.6485, 152.0370],
  [-24.6463, 152.0312],
  [-24.6452, 152.0269],
  [-24.6449, 152.0227],
  [-24.6452, 152.0152],
  [-24.6441, 152.0068],
  [-24.6434, 152.0039],
  [-24.6401, 151.9955],
  [-24.6388, 151.9914],
  [-24.6381, 151.9871],
  [-24.6381, 151.9826],
  [-24.6378, 151.9790],
  [-24.6401, 151.9738],
  [-24.6408, 151.9684],
  [-24.6402, 151.9646],
  [-24.6379, 151.9555],
  [-24.6362, 151.9518],
  [-24.6323, 151.9451],
  [-24.6306, 151.9405],
  [-24.6300, 151.9361],
  [-24.6300, 151.9305],
  [-24.6292, 151.9241],
  [-24.6292, 151.9241]
];

const watalganDeviationEndRosedale = [
  [-24.6292, 151.9241],
  [-24.6283, 151.9179],
  [-24.6283, 151.9162],
  [-24.6284, 151.9156]
];

const rosedaleRosedaleDeviation = [
  [-24.6284, 151.9156],
  [-24.6291, 151.9116]
];

const rosedaleOld = [
  [-24.6291, 151.9116],
  [-24.6289, 151.9107],
  [-24.6285, 151.9098],
  [-24.6271, 151.9082],
  [-24.6267, 151.9072],
  [-24.6267, 151.9058],
  [-24.6285, 151.9021],
  [-24.6288, 151.9009],
  [-24.6284, 151.8950],
  [-24.6278, 151.8928],
  [-24.6277, 151.8919],
  [-24.6279, 151.8907],
  [-24.6290, 151.8886],
  [-24.6299, 151.8856],
  [-24.6302, 151.8839]
];

const rosedaleDeviation = [
  [-24.6291, 151.9116],
  [-24.6296, 151.9087],
  [-24.6306, 151.8964],
  [-24.6302, 151.8839]
];

const rosedaleDeviationLowmeadDeviation = [
  [-24.6302, 151.8839],
  [-24.6297, 151.8778],
  [-24.6254, 151.8634],
  [-24.6187, 151.8496],
  [-24.6158, 151.8393],
  [-24.6157, 151.8379],
  [-24.6160, 151.8366],
  [-24.6170, 151.8341],
  [-24.6172, 151.8328],
  [-24.6166, 151.8315],
  [-24.6146, 151.8296],
  [-24.6138, 151.8280],
  [-24.6133, 151.8222],
  [-24.6123, 151.8184],
  [-24.6103, 151.8150],
  [-24.6031, 151.8079],
  [-24.6019, 151.8073],
  [-24.5957, 151.8052],
  [-24.5867, 151.8020],
  [-24.5859, 151.8014],
  [-24.5856, 151.8006],
  [-24.5851, 151.7984],
  [-24.5847, 151.7972],
  [-24.5812, 151.7935],
  [-24.5793, 151.7925],
  [-24.5645, 151.7904],
  [-24.5624, 151.7894],
  [-24.5513, 151.7804],
  [-24.5445, 151.7757]
];

const lowmeadOld = [
  [-24.5445, 151.7757],
  [-24.5404, 151.7748],
  [-24.5389, 151.7741],
  [-24.5343, 151.7704],
  [-24.5333, 151.7687],
  [-24.5328, 151.7666],
  [-24.5328, 151.7653],
  [-24.5328, 151.7641],
  [-24.5322, 151.7631],
  [-24.5315, 151.7621],
  [-24.5313, 151.7606],
  [-24.5319, 151.7585],
  [-24.5318, 151.7569]
];

const lowmeadDeviation = [
  [-24.5445, 151.7757],
  [-24.5408, 151.7732],
  [-24.5387, 151.7715],
  [-24.5359, 151.7678],
  [-24.5338, 151.7636],
  [-24.5318, 151.7569]
];

const lowemeadDeviationBaffleCreekDeviation = [
  [-24.5318, 151.7569],
  [-24.5315, 151.7560],
  [-24.5237, 151.7431],
  [-24.5237, 151.7432]
];

const baffleCreekOld = [
  [-24.5237, 151.7432],
  [-24.5226, 151.7424],
  [-24.5218, 151.7421],
  [-24.5211, 151.7411],
  [-24.5209, 151.7396],
  [-24.5214, 151.7357],
  [-24.5212, 151.7340],
  [-24.5191, 151.7296],
  [-24.5182, 151.7287],
  [-24.5134, 151.7279],
  [-24.5124, 151.7274],
  [-24.5120, 151.7263],
  [-24.5113, 151.7242],
  [-24.5108, 151.7231]
];

const baffleCreekDeviation = [
  [-24.5237, 151.7432],
  [-24.5191, 151.7368],
  [-24.5170, 151.7313],
  [-24.5141, 151.7264],
  [-24.5130, 151.7252],
  [-24.5108, 151.7231]
];

const baffleCreekDeviationMountTomDeviation = [
  [-24.5108, 151.7231],
  [-24.4733, 151.6864],
  [-24.4699, 151.6812],
  [-24.4645, 151.6669],
  [-24.4621, 151.6630],
  [-24.4580, 151.6588],
  [-24.4541, 151.6565],
  [-24.4500, 151.6551],
  [-24.4266, 151.6515],
  [-24.4247, 151.6506],
  [-24.4078, 151.6386],
  [-24.4065, 151.6371],
  [-24.4004, 151.6248]
];

const mountTomOld = [
  [-24.4004, 151.6248],
  [-24.3992, 151.6238],
  [-24.3978, 151.6231],
  [-24.3962, 151.6215],
  [-24.3942, 151.6178],
  [-24.3939, 151.6145],
  [-24.3930, 151.6124],
  [-24.3921, 151.6116],
  [-24.3909, 151.6110],
  [-24.3902, 151.6103],
  [-24.3885, 151.6073],
  [-24.3874, 151.6049],
  [-24.3870, 151.6001],
  [-24.3865, 151.5988],
  [-24.3851, 151.5977],
  [-24.3814, 151.5969],
  [-24.3801, 151.5958],
  [-24.3755, 151.5895],
  [-24.3737, 151.5882],
  [-24.3717, 151.5879],
  [-24.3705, 151.5877]
];

const mountTomDeviation = [
  [-24.4004, 151.6248],
  [-24.3989, 151.6220],
  [-24.3924, 151.6113],
  [-24.3878, 151.6064],
  [-24.3774, 151.5925],
  [-24.3711, 151.5880],
  [-24.3705, 151.5877]
];

const mountTomDeviationMiriamValeDeviation = [
  [-24.3705, 151.5877],
  [-24.3643, 151.5850],
  [-24.3607, 151.5821],
  [-24.3494, 151.5767]
];

const miriamValeOld = [
  [-24.3494, 151.5767],
  [-24.3456, 151.5732],
  [-24.3434, 151.5722],
  [-24.3357, 151.5715],
  [-24.3346, 151.5710],
  [-24.3339, 151.5702],
  [-24.3330, 151.5687]
];

const miriamValeDeviation = [
  [-24.3494, 151.5767],
  [-24.3475, 151.5759],
  [-24.3415, 151.5745],
  [-24.3393, 151.5735],
  [-24.3352, 151.5709],
  [-24.3330, 151.5687]
];

const miriamValeDeviationInveragh = [
  [-24.3330, 151.5687],
  [-24.3324, 151.5679],
  [-24.3204, 151.5527],
  [-24.3041, 151.5407],
  [-24.3025, 151.5391],
  [-24.2865, 151.5155],
  [-24.2849, 151.5143],
  [-24.2222, 151.4881],
  [-24.2036, 151.4854],
  [-24.2020, 151.4846],
  [-24.1998, 151.4828],
  [-24.1982, 151.4821],
  [-24.1968, 151.4822],
  [-24.1947, 151.4830],
  [-24.1929, 151.4832],
  [-24.1916, 151.4828],
  [-24.1908, 151.4827],
  [-24.1896, 151.4827],
  [-24.1886, 151.4825],
  [-24.1858, 151.4809],
  [-24.1851, 151.4807],
  [-24.1844, 151.4807],
  [-24.1762, 151.4825],
  [-24.1752, 151.4822],
  [-24.1746, 151.4817],
  [-24.1727, 151.4797],
  [-24.1717, 151.4791],
  [-24.1619, 151.4768],
  [-24.1572, 151.4750],
  [-24.1374, 151.4645],
  [-24.1345, 151.4637],
  [-24.1327, 151.4628],
  [-24.1270, 151.4584],
  [-24.1264, 151.4575],
  [-24.1250, 151.4546]
];

const iveraghGladstone = [
  [-24.1250, 151.4546],
  [-24.1241, 151.4525],
  [-24.1238, 151.4509],
  [-24.1238, 151.4491],
  [-24.1233, 151.4473],
  [-24.1165, 151.4398],
  [-24.1136, 151.4351],
  [-24.1114, 151.4282],
  [-24.1097, 151.4243],
  [-24.1067, 151.4201],
  [-24.1018, 151.4154],
  [-24.0995, 151.4124],
  [-24.0928, 151.4016],
  [-24.0899, 151.3985],
  [-24.0854, 151.3951],
  [-24.0675, 151.3875],
  [-24.0624, 151.3838],
  [-24.0499, 151.3696],
  [-24.0438, 151.3650],
  [-24.0427, 151.3640],
  [-24.0377, 151.3566],
  [-24.0361, 151.3550],
  [-24.0326, 151.3523],
  [-24.0300, 151.3485],
  [-24.0285, 151.3436],
  [-24.0284, 151.3406],
  [-24.0288, 151.3333],
  [-24.0283, 151.3284],
  [-24.0256, 151.3232],
  [-24.0223, 151.3203],
  [-24.0194, 151.3187],
  [-24.0099, 151.3162],
  [-24.0047, 151.3135],
  [-24.0027, 151.3118],
  [-23.9964, 151.3049],
  [-23.9948, 151.3018],
  [-23.9932, 151.2963],
  [-23.9916, 151.2933],
  [-23.9884, 151.2902],
  [-23.9712, 151.2828],
  [-23.9678, 151.2824],
  [-23.9457, 151.2861],
  [-23.9429, 151.2862],
  [-23.9411, 151.2867],
  [-23.9364, 151.2894],
  [-23.9341, 151.2898],
  [-23.9316, 151.2895],
  [-23.9294, 151.2887],
  [-23.9275, 151.2885],
  [-23.9232, 151.2888],
  [-23.9205, 151.2882],
  [-23.9180, 151.2866],
  [-23.9157, 151.2854],
  [-23.9102, 151.2844],
  [-23.8923, 151.2774],
  [-23.8888, 151.2770],
  [-23.8821, 151.2782],
  [-23.8782, 151.2777],
  [-23.8750, 151.2763],
  [-23.8738, 151.2761],
  [-23.8724, 151.2763],
  [-23.8714, 151.2762],
  [-23.8640, 151.2716],
  [-23.8623, 151.2711],
  [-23.8555, 151.2696],
  [-23.8535, 151.2689],
  [-23.8522, 151.2681],
  [-23.8507, 151.2666],
  [-23.8462, 151.2630]
];

const gladstoneGladstoneBridges = [
  [-23.8462, 151.2630],
  [-23.8447, 151.2616],
  [-23.8443, 151.2608],
  [-23.8440, 151.2589],
  [-23.8440, 151.2577],
  [-23.8442, 151.2570],
  [-23.8446, 151.2560],
  [-23.8450, 151.2553],
  [-23.8465, 151.2538],
  [-23.8477, 151.2527],
  [-23.8518, 151.2494],
  [-23.8527, 151.2488],
  [-23.8537, 151.2485],
  [-23.8562, 151.2482],
  [-23.8573, 151.2477],
  [-23.8582, 151.2469],
  [-23.8586, 151.2463],
  [-23.8613, 151.2403],
  [-23.8649, 151.2325],
  [-23.8656, 151.2298],
  [-23.8655, 151.2267],
  [-23.8651, 151.2214],
  [-23.8651, 151.2188],
  [-23.8664, 151.2083],
  [-23.8672, 151.2062],
  [-23.8689, 151.2036],
  [-23.8694, 151.2025],
  [-23.8696, 151.2012],
  [-23.8695, 151.1959],
  [-23.8696, 151.1945]
];

const gladstoneBridgeOne = [
  [-23.8696, 151.1945],
  [-23.8702, 151.1925],
  [-23.8711, 151.1907],
  [-23.8719, 151.1883],
  [-23.8721, 151.1858],
  [-23.8714, 151.1827],
  [-23.8702, 151.1805]
];

const gladstonBridgeTwo = [
  [-23.8696, 151.1945],
  [-23.8702, 151.1930],
  [-23.8711, 151.1919],
  [-23.8738, 151.1891],
  [-23.8745, 151.1880],
  [-23.8749, 151.1866],
  [-23.8748, 151.1853],
  [-23.8743, 151.1841],
  [-23.8736, 151.1832],
  [-23.8713, 151.1815],
  [-23.8702, 151.1805]
];

const gladstoneBridgeMountLarcomDeviation = [
  [-23.8702, 151.1805],
  [-23.8673, 151.1772],
  [-23.8589, 151.1701],
  [-23.8552, 151.1685],
  [-23.8536, 151.1669],
  [-23.8480, 151.1574],
  [-23.8466, 151.1558],
  [-23.8441, 151.1541],
  [-23.8430, 151.1528],
  [-23.8415, 151.1499],
  [-23.8410, 151.1481],
  [-23.8409, 151.1458],
  [-23.8412, 151.1444],
  [-23.8414, 151.1432],
  [-23.8413, 151.1415],
  [-23.8409, 151.1402],
  [-23.8406, 151.1382],
  [-23.8409, 151.1366],
  [-23.8420, 151.1335],
  [-23.8423, 151.1320],
  [-23.8423, 151.1304],
  [-23.8403, 151.1207],
  [-23.8405, 151.1186],
  [-23.8415, 151.1167],
  [-23.8448, 151.1128],
  [-23.8456, 151.1115],
  [-23.8477, 151.1073],
  [-23.8481, 151.1057],
  [-23.8481, 151.1040],
  [-23.8476, 151.1024],
  [-23.8462, 151.0998],
  [-23.8453, 151.0989],
  [-23.8442, 151.0982],
  [-23.8427, 151.0979],
  [-23.8417, 151.0974],
  [-23.8407, 151.0963],
  [-23.8401, 151.0949],
  [-23.8401, 151.0936],
  [-23.8404, 151.0921],
  [-23.8413, 151.0906],
  [-23.8419, 151.0885],
  [-23.8418, 151.0873],
  [-23.8413, 151.0860],
  [-23.8395, 151.0836],
  [-23.8389, 151.0823],
  [-23.8383, 151.0797],
  [-23.8375, 151.0778],
  [-23.8351, 151.0741],
  [-23.8343, 151.0719],
  [-23.8341, 151.0697],
  [-23.8374, 151.0428],
  [-23.8370, 151.0403],
  [-23.8246, 151.0161],
  [-23.8237, 151.0149],
  [-23.8223, 151.0136],
  [-23.8216, 151.0128],
  [-23.8207, 151.0110],
  [-23.8204, 151.0092],
  [-23.8211, 151.0013],
  [-23.8211, 151.0003]
];

const mountLarcomOld = [
  [-23.8211, 151.0003],
  [-23.8197, 150.9983],
  [-23.8158, 150.9949],
  [-23.8145, 150.9931],
  [-23.8127, 150.9892],
  [-23.8112, 150.9865],
  [-23.8107, 150.9846],
  [-23.8105, 150.9812],
  [-23.8101, 150.9805],
  [-23.8094, 150.9799],
  [-23.8082, 150.9793]
];

const mountLarcomDeviation = [
  [-23.8211, 151.0003],
  [-23.8208, 150.9993],
  [-23.8198, 150.9970],
  [-23.8171, 150.9946],
  [-23.8157, 150.9931],
  [-23.8136, 150.9887],
  [-23.8130, 150.9856],
  [-23.8125, 150.9841],
  [-23.8113, 150.9824],
  [-23.8086, 150.9798],
  [-23.8082, 150.9793]
];

const mountLarcomDeviationMarmorDeviation = [
  [-23.8082, 150.9793],
  [-23.8074, 150.9783],
  [-23.8065, 150.9761],
  [-23.8044, 150.9657],
  [-23.8032, 150.9619],
  [-23.7876, 150.9326],
  [-23.7704, 150.9116],
  [-23.7672, 150.9055],
  [-23.7666, 150.9031],
  [-23.7653, 150.8936],
  [-23.7632, 150.8863],
  [-23.7553, 150.8705],
  [-23.7547, 150.8675],
  [-23.7545, 150.8621],
  [-23.7526, 150.8565],
  [-23.7394, 150.8364],
  [-23.7317, 150.8278],
  [-23.7309, 150.8270],
  [-23.7276, 150.8251],
  [-23.7262, 150.8244],
  [-23.7228, 150.8232],
  [-23.7203, 150.8217],
  [-23.7079, 150.8107],
  [-23.7053, 150.8078],
  [-23.7042, 150.8063],
  [-23.7033, 150.8041],
  [-23.6959, 150.7750],
  [-23.6939, 150.7599],
  [-23.6933, 150.7571],
  [-23.6846, 150.7305],
  [-23.6837, 150.7254],
  [-23.6818, 150.7189],
  [-23.6779, 150.7104],
  [-23.6762, 150.7055]
];

const marmorOld = [
  [-23.6762, 150.7055],
  [-23.6739, 150.6992],
  [-23.6737, 150.6980],
  [-23.6743, 150.6969],
  [-23.6755, 150.6958],
  [-23.6759, 150.6951],
  [-23.6759, 150.6937]
];

const marmorDeviation = [
  [-23.6762, 150.7055],
  [-23.6755, 150.7027],
  [-23.6755, 150.6998],
  [-23.6761, 150.6970],
  [-23.6761, 150.6948],
  [-23.6759, 150.6937]
];

const marmorDeviationRocklands = [
  [-23.6759, 150.6937],
  [-23.6754, 150.6904],
  [-23.6740, 150.6875],
  [-23.6716, 150.6845],
  [-23.6708, 150.6826],
  [-23.6707, 150.6799],
  [-23.6716, 150.6769],
  [-23.6716, 150.6751],
  [-23.6699, 150.6634],
  [-23.6689, 150.6597],
  [-23.6680, 150.6580],
  [-23.6665, 150.6561],
  [-23.6522, 150.6451],
  [-23.6504, 150.6437],
  [-23.6389, 150.6348],
  [-23.5972, 150.5956],
  [-23.5861, 150.5852],
  [-23.5822, 150.5831],
  [-23.5738, 150.5814],
  [-23.5694, 150.5798],
  [-23.5306, 150.5565],
  [-23.5252, 150.5542],
  [-23.5095, 150.5510],
  [-23.5055, 150.5490],
  [-23.5001, 150.5445],
  [-23.4966, 150.5431],
  [-23.4779, 150.5394],
  [-23.4691, 150.5352],
  [-23.4592, 150.5330],
  [-23.4567, 150.5317],
  [-23.4452, 150.5223],
  [-23.4400, 150.5192],
  [-23.4387, 150.5184],
  [-23.4366, 150.5162],
  [-23.4331, 150.5113]
];

const rocklandsGracemereJunction = [
  [-23.4331, 150.5113],
  [-23.4285, 150.5050],
  [-23.4271, 150.5035],
  [-23.4251, 150.5024],
  [-23.4171, 150.4994],
  [-23.4149, 150.4992],
  [-23.4129, 150.4998],
  [-23.4111, 150.5009]
];

const gracemereJunctionRockhampton = [
  [-23.4111, 150.5009],
  [-23.3940, 150.5107],
  [-23.3928, 150.5113],
  [-23.3916, 150.5118],
  [-23.3896, 150.5124],
  [-23.3874, 150.5131]
];

const rockhamptonStanleyStreet = [
  [-23.3874, 150.5131],
  [-23.3858, 150.5136],
  [-23.3856, 150.5136]
];

const stanleyStreetRockhamptonArcherStreet = [
  [-23.3856, 150.5136],
  [-23.3853, 150.5136],
  [-23.3848, 150.5134],
  [-23.3756, 150.5066]
];

const northRockhamptonRockhamptonJunction = [
  [-23.3727, 150.5230],
  [-23.3730, 150.5212],
  [-23.3732, 150.5207],
  [-23.3736, 150.5200],
  [-23.3738, 150.5191],
  [-23.3736, 150.5182],
  [-23.3730, 150.5175],
  [-23.3706, 150.5161],
  [-23.3701, 150.5156],
  [-23.3675, 150.5119],
  [-23.3673, 150.5112],
  [-23.3673, 150.5106],
  [-23.3675, 150.5099],
];

const rockhamptonJunction = [
  [-23.3675, 150.5119],
  [-23.3669, 150.5115],
  [-23.3664, 150.5114],
  [-23.3659, 150.5115]
];

const rockhamptonRockhamptonJunction = [
  [-23.3756, 150.5066],
  [-23.3721, 150.5041],
  [-23.3717, 150.5040],
  [-23.3713, 150.5041],
  [-23.3709, 150.5045],
  [-23.3675, 150.5099]
];

export const northCoastBundabergRockhampton: Line = {
  name: 'North Coast (Bundaberg - Rockhampton)',
  state: 'QLD',
  segments: [
    {
      segments: [
        northBundabergRosedale,
        {
          date: '1989-01-01',
          original: [yandaranOld],
          deviation: [yandaranDeviation]
        },
        yandaranDeviationEndWatalganDeviationStart,
        {
          date: '1989-01-01',
          original: [watalganOld],
          deviation: [watalganDeviation]
        },
        watalganDeviationEndRosedale
      ],
      history: {
        opened: {
          date: '1892-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gladstoneGladstoneBridges],
      history: {
        opened: {
          date: '1903-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        rosedaleRosedaleDeviation,
        {
          date: '1989-01-01',
          original: [rosedaleOld],
          deviation: [rosedaleDeviation]
        },
        rosedaleDeviationLowmeadDeviation,
        {
          date: '1989-01-01',
          original: [lowmeadOld],
          deviation: [lowmeadDeviation]
        },
        lowemeadDeviationBaffleCreekDeviation,
        {
          date: '1989-01-01',
          original: [baffleCreekOld],
          deviation: [baffleCreekDeviation]
        },
        baffleCreekDeviationMountTomDeviation,
        {
          date: '1989-01-01',
          original: [mountTomOld],
          deviation: [mountTomDeviation]
        },
        mountTomDeviationMiriamValeDeviation,
        {
          date: '1989-01-01',
          original: [miriamValeOld],
          deviation: [miriamValeDeviation]
        },
        miriamValeDeviationInveragh
      ],
      history: {
        opened: {
          date: '1897-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [iveraghGladstone],
      history: {
        opened: {
          date: '1896-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gladstoneBridgeOne],
      history: {
        opened: {
          date: '1903-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gladstonBridgeTwo],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        gladstoneBridgeMountLarcomDeviation,
        {
          date: '1986-09-06',
          original: [mountLarcomOld],
          deviation: [mountLarcomDeviation]
        },
        mountLarcomDeviationMarmorDeviation,
        {
          date: '1986-09-06',
          original: [marmorOld],
          deviation: [marmorDeviation]
        },
        marmorDeviationRocklands
      ],
      history: {
        opened: {
          date: '1903-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1995-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [rocklandsGracemereJunction],
      history: {
        opened: {
          date: '1903-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gracemereJunctionRockhampton],
      history: {
        opened: {
          date: '1867-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [rockhamptonStanleyStreet],
      history: {
        opened: {
          date: '1867-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [stanleyStreetRockhamptonArcherStreet],
      history: {
        opened: {
          date: '1867-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1899-11-06',
          tracks: 2
        }, {
          date: '1975-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [rockhamptonRockhamptonJunction],
      history: {
        opened: {
          date: '1899-11-06',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1975-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [northRockhamptonRockhamptonJunction],
      history: {
        opened: {
          date: '1899-11-06',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1975-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [rockhamptonJunction],
      history: {
        opened: {
          date: '1911-10-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
