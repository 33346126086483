import { Line } from "../../../trackTypes";

const wardellRoadJunctionBalmainRoadJunction = [
  [-33.8741, 151.1648],
  [-33.8741, 151.1648],
  [-33.8743, 151.1621],
  [-33.8745, 151.1611],
  [-33.8748, 151.1601],
  [-33.8750, 151.1583],
  [-33.8751, 151.1568],
  [-33.8749, 151.1551],
  [-33.8749, 151.1541],
  [-33.8753, 151.1517],
  [-33.8757, 151.1506],
  [-33.8774, 151.1485],
  [-33.8786, 151.1476],
  [-33.8798, 151.1471],
  [-33.8805, 151.1467],
  [-33.8817, 151.1457],
  [-33.8830, 151.1451],
  [-33.8861, 151.1450],
  [-33.8867, 151.1450],
  [-33.8875, 151.1452],
  [-33.8883, 151.1452],
  [-33.8903, 151.1451],
  [-33.8917, 151.1448],
  [-33.8925, 151.1444],
  [-33.8943, 151.1430],
  [-33.8955, 151.1424],
  [-33.8964, 151.1423],
  [-33.8971, 151.1421],
  [-33.8980, 151.1413],
  [-33.8989, 151.1398],
  [-33.8997, 151.1389],
  [-33.9007, 151.1383],
  [-33.9020, 151.1381],
  [-33.9035, 151.1383],
  [-33.9041, 151.1385],
  [-33.9058, 151.1395],
  [-33.9066, 151.1401],
  [-33.9072, 151.1402],
  [-33.9079, 151.1401],
  [-33.9087, 151.1397],
  [-33.9094, 151.1396],
  [-33.9101, 151.1399],
  [-33.9105, 151.1403],
  [-33.9109, 151.1406]
];

const wentworthParkGlebeRoadTunnel = [
  [-33.8744, 151.1940],
  [-33.8754, 151.1925],
  [-33.8756, 151.1915],
  [-33.8758, 151.1906],
  [-33.8770, 151.1885],
  [-33.8771, 151.1879],
  [-33.8772, 151.1868]
];

const glebeRoadTunnel = [
  [-33.8772, 151.1868],
  [-33.8754, 151.1800],
  [-33.8754, 151.1790]
];

const glebeRoadTunnelLilyfield = [
  [-33.8754, 151.1790],
  [-33.8754, 151.1779],
  [-33.8753, 151.1771],
  [-33.8748, 151.1761],
  [-33.8743, 151.1757],
  [-33.8736, 151.1754],
  [-33.8730, 151.1750],
  [-33.8721, 151.1735],
  [-33.8719, 151.1730],
  [-33.8718, 151.1721],
  [-33.8721, 151.1712],
  [-33.8728, 151.1702],
  [-33.8735, 151.1692],
  [-33.8739, 151.1680],
  [-33.8741, 151.1648]
];

const balmainRoadJunctionJohnStreetTunnel = [
  [-33.8744, 151.1940],
  [-33.8735, 151.1944],
  [-33.8724, 151.1942],
  [-33.8714, 151.1934],
  [-33.8706, 151.1919],
  [-33.8699, 151.1913],
  [-33.8692, 151.1911],
  [-33.8688, 151.1911]
];

const johnStreetTunnel = [
  [-33.8688, 151.1911],
  [-33.8680, 151.1914],
  [-33.8675, 151.1919]
];

const johnStreetTunnelDarlingIsland = [
  [-33.8675, 151.1919],
  [-33.8671, 151.1926],
  [-33.8669, 151.1939],
  [-33.8669, 151.1943]
];

const darlingIslandDarlingHarbour = [
  [-33.8669, 151.1943],
  [-33.8673, 151.1949],
  [-33.8682, 151.1956],
  [-33.8686, 151.1960],
  [-33.8694, 151.1976],
  [-33.8698, 151.1980],
  [-33.8706, 151.1982],
  [-33.8728, 151.1980],
  [-33.8734, 151.1981]
];

const darlingHarbourTramStart = [
  [-33.8734, 151.1981],
  [-33.8748, 151.1984],
  [-33.8787, 151.2006]
];

const tramStartPowerhouseMuseum = [
  [-33.8787, 151.2006],
  [-33.8799, 151.2011]
];

const harbourOne = [
  [-33.8656, 151.1985],
  [-33.8678, 151.1977],
  [-33.8687, 151.1977],
  [-33.8698, 151.1980]
];

const harbourTwo = [
  [-33.8640, 151.1971],
  [-33.8671, 151.1958],
  [-33.8679, 151.1958],
  [-33.8686, 151.1960]
];

const harbourThree = [
  [-33.8636, 151.1967],
  [-33.8652, 151.1952],
  [-33.8660, 151.1949],
  [-33.8673, 151.1949]
];

const harbourFour = [
  [-33.8626, 151.1940],
  [-33.8637, 151.1932],
  [-33.8643, 151.1931],
  [-33.8657, 151.1938],
  [-33.8669, 151.1943]
];

const harbourFive = [
  [-33.8657, 151.1938],
  [-33.8650, 151.1938],
  [-33.8646, 151.1939],
  [-33.8627, 151.1954]
];

const powerhouseMuseumCentralGoodsJunction = [
  [-33.8799, 151.2011],
  [-33.8845, 151.2033],
  [-33.8853, 151.2034],
  [-33.8862, 151.2031],
  [-33.8882, 151.2020]
];

const balmainRoadJunctionRozelleDe = [
  [-33.8741, 151.1648],
  [-33.8734, 151.1674],
  [-33.8731, 151.1687],
  [-33.8680, 151.1768],
  [-33.8676, 151.1774],
  [-33.8673, 151.1779],
  [-33.8649, 151.1798],
  [-33.8644, 151.1804],
  [-33.8634, 151.1812],
  [-33.8630, 151.1816],
  [-33.8625, 151.1827],
  [-33.8616, 151.1837],
  [-33.8607, 151.1858],
  [-33.8605, 151.1864]
];

const regentStreetRegentStreetJunction = [
  [-33.8882, 151.2020],
  [-33.8871, 151.2021]
];

export const darlingHarbourGoodsLine: Line = {
  name: 'Darling Harbour Goods Line',
  state: 'NSW',
  segments: [
    {
      segments: [wardellRoadJunctionBalmainRoadJunction],
      history: {
        opened: {
          date: '1916-05-29',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2000-08-01',
          status: 'closed'
        }, {
          date: '2014-03-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        wentworthParkGlebeRoadTunnel,
        {
          name: 'Glebe Road Tunnel',
          segment: glebeRoadTunnel,
          type: 'tunnel'
        },
        glebeRoadTunnelLilyfield
      ],
      history: {
        opened: {
          date: '1922-01-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-22',
          status: 'closed'
        }, {
          date: '2000-08-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        balmainRoadJunctionJohnStreetTunnel,
        {
          name: 'John Street Tunnel',
          segment: johnStreetTunnel,
          type: 'tunnel'
        },
        johnStreetTunnelDarlingIsland
      ],
      history: {
        opened: {
          date: '1922-01-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-22',
          status: 'closed'
        }, {
          date: '1997-08-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [darlingIslandDarlingHarbour],
      history: {
        opened: {
          date: '1900-01-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-06-06',
          status: 'closed'
        }, {
          date: '1997-08-01',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }]
      }
    },
    {
      segments: [harbourOne, harbourTwo, harbourThree, harbourFour, harbourFive],
      history: {
        opened: {
          date: '1900-01-22',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-06-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [darlingHarbourTramStart],
      history: {
        opened: {
          date: '1855-09-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-06-06',
          status: 'closed'
        }, {
          date: '1997-08-01',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }]
      }
    },
    {
      segments: [tramStartPowerhouseMuseum],
      history: {
        opened: {
          date: '1855-09-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-06-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [powerhouseMuseumCentralGoodsJunction],
      history: {
        opened: {
          date: '1855-09-26',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2015-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [regentStreetRegentStreetJunction],
      history: {
        opened: {
          date: '1869-06-29',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-04-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balmainRoadJunctionRozelleDe],
      history: {
        opened: {
          date: '1916-05-29',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-22',
          status: 'closed'
        }]
      }
    }
  ]
};
