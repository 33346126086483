import { Line } from "../../trackTypes";

const adamstownFernleighTunnel = [
  [-32.9346, 151.7188],
  [-32.9364, 151.7170],
  [-32.9388, 151.7157],
  [-32.9400, 151.7148],
  [-32.9413, 151.7138],
  [-32.9425, 151.7136],
  [-32.9436, 151.7140],
  [-32.9453, 151.7154],
  [-32.9465, 151.7157],
  [-32.9477, 151.7153],
  [-32.9484, 151.7152],
  [-32.9508, 151.7155]
];

const fernleighTunnel = [
  [-32.9508, 151.7155],
  [-32.9521, 151.7157],
  [-32.9525, 151.7159]
];

const fernleighTunnelBurwoodColliery = [
  [-32.9525, 151.7159],
  [-32.9529, 151.7163],
  [-32.9536, 151.7175],
  [-32.9544, 151.7182],
  [-32.9554, 151.7183],
  [-32.9573, 151.7176],
  [-32.9585, 151.7177],
  [-32.9596, 151.7186],
  [-32.9608, 151.7188],
  [-32.9620, 151.7183],
  [-32.9628, 151.7182],
  [-32.9634, 151.7184],
  [-32.9643, 151.7185],
  [-32.9655, 151.7179],
  [-32.9662, 151.7178],
  [-32.9677, 151.7178],
  [-32.9694, 151.7184],
  [-32.9701, 151.7184],
  [-32.9706, 151.7182],
  [-32.9727, 151.7168],
  [-32.9745, 151.7152],
  [-32.9749, 151.7148],
  [-32.9755, 151.7134],
  [-32.9795, 151.7093],
  [-32.9806, 151.7088],
  [-32.9821, 151.7089],
  [-32.9865, 151.7079],
  [-32.9872, 151.7080],
  [-32.9878, 151.7084],
  [-32.9887, 151.7093],
  [-32.9894, 151.7096],
  [-32.9902, 151.7095],
  [-32.9915, 151.7084],
  [-32.9926, 151.7091],
  [-32.9944, 151.7082],
  [-32.9952, 151.7087],
  [-32.9967, 151.7106],
  [-32.9978, 151.7112],
  [-32.9987, 151.7110],
  [-33.0000, 151.7099],
  [-33.0008, 151.7096],
  [-33.0018, 151.7099],
  [-33.0026, 151.7107],
  [-33.0035, 151.7112],
  [-33.0043, 151.7110],
  [-33.0051, 151.7103],
  [-33.0107, 151.7031],
  [-33.0121, 151.7017],
  [-33.0153, 151.6986]
];

const burwoodCollieryBelmont = [
  [-33.0153, 151.6986],
  [-33.0161, 151.6975],
  [-33.0176, 151.6898],
  [-33.0185, 151.6881],
  [-33.0255, 151.6824],
  [-33.0304, 151.6777],
  [-33.0321, 151.6755],
  [-33.0343, 151.6743],
  [-33.0365, 151.6723],
  [-33.0378, 151.6699],
  [-33.0391, 151.6649],
  [-33.0392, 151.6636],
  [-33.0390, 151.6611]
];

const dudleyJunctionDudleyColliery = [
  [-32.9727, 151.7168],
  [-32.9745, 151.7164],
  [-32.9754, 151.7167],
  [-32.9769, 151.7171],
  [-32.9779, 151.7173],
  [-32.9811, 151.7149],
  [-32.9821, 151.7148],
  [-32.9829, 151.7152],
  [-32.9850, 151.7181],
  [-32.9861, 151.7190],
  [-32.9887, 151.7196],
  [-32.9922, 151.7187],
  [-32.9934, 151.7189]
];

export const belmont: Line = {
  name: 'Belmont',
  state: 'NSW',
  segments: [
    {
      segments: [
        adamstownFernleighTunnel,
        {
          name: 'Fernleigh Tunnel',
          segment: fernleighTunnel,
          type: 'tunnel'
        },
        fernleighTunnelBurwoodColliery
      ],
      history: {
        opened: {
          date: '1892-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-12-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [burwoodCollieryBelmont],
      history: {
        opened: {
          date: '1916-12-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-12-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dudleyJunctionDudleyColliery],
      history: {
        opened: {
          date: '1892-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-12-19',
          status: 'closed'
        }]
      }
    }
  ]
};
