import { Line } from "../../trackTypes";

const bundabergMillaquinSugarRefinary = [
  [-24.8666, 152.3446],
  [-24.8661, 152.3447],
  [-24.8658, 152.3450],
  [-24.8629, 152.3558],
  [-24.8628, 152.3566],
  [-24.8613, 152.3623],
  [-24.8608, 152.3641],
  [-24.8592, 152.3666],
  [-24.8581, 152.3670],
  [-24.8570, 152.3668],
  [-24.8554, 152.3648]
];

const millaquinSugarRefinaryQunaba = [
  [-24.8592, 152.3666],
  [-24.8587, 152.3688],
  [-24.8581, 152.3697],
  [-24.8559, 152.3716],
  [-24.8405, 152.3865],
  [-24.8349, 152.3957],
  [-24.8318, 152.4037],
  [-24.8312, 152.4044],
  [-24.8215, 152.4060],
  [-24.8207, 152.4067],
  [-24.8203, 152.4073],
  [-24.8172, 152.4144]
];

const qunabaPemberton = [
  [-24.8172, 152.4144],
  [-24.8055, 152.4318],
  [-24.8052, 152.4330],
  [-24.8058, 152.4481],
  [-24.8048, 152.4521],
  [-24.8048, 152.4528],
  [-24.8050, 152.4537],
  [-24.8093, 152.4592],
  [-24.8114, 152.4607],
  [-24.8139, 152.4620],
  [-24.8150, 152.4623],
  [-24.8155, 152.4622],
  [-24.8279, 152.4568],
  [-24.8318, 152.4559],
  [-24.8419, 152.4558],
  [-24.8545, 152.4509],
  [-24.8553, 152.4506],
  [-24.8697, 152.4525]
];

export const pemberton: Line = {
  name: 'Pemberton',
  state: 'QLD',
  segments: [
    {
      segments: [bundabergMillaquinSugarRefinary, millaquinSugarRefinaryQunaba],
      history: {
        opened: {
          date: '1894-07-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [qunabaPemberton],
      history: {
        opened: {
          date: '1894-07-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-05-30',
          status: 'closed'
        }]
      }
    }
  ]
}
