import { Line } from "../../trackTypes";

const line = [
  [-35.7307, 143.9234],
  [-35.7298, 143.9234],
  [-35.7290, 143.9239],
  [-35.7284, 143.9247],
  [-35.7281, 143.9258],
  [-35.7281, 143.9359],
  [-35.7277, 143.9389],
  [-35.7267, 143.9419],
  [-35.7188, 143.9569],
  [-35.7180, 143.9591],
  [-35.7148, 143.9702],
  [-35.7146, 143.9719],
  [-35.7145, 143.9899],
  [-35.7143, 143.9917],
  [-35.7139, 143.9930],
  [-35.7061, 144.0124],
  [-35.6906, 144.0527],
  [-35.6896, 144.0545],
  [-35.6704, 144.0781],
  [-35.6696, 144.0797],
  [-35.6568, 144.1206],
  [-35.6562, 144.1220],
  [-35.6495, 144.1293],
  [-35.6475, 144.1304],
  [-35.6418, 144.1313]
];

export const koondrook: Line = {
  name: 'Koondrook',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1889-07-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-01',
          status: 'closed'
        }]
      }
    }
  ]
};
