import { Line } from "../../../trackTypes";

const line = [
  [-35.7403, 174.3368],
  [-35.7446, 174.3456],
  [-35.7453, 174.3466],
  [-35.7541, 174.3573],
  [-35.7557, 174.3584],
  [-35.7626, 174.3599],
  [-35.7641, 174.3597],
  [-35.7656, 174.3586],
  [-35.7665, 174.3579],
  [-35.7670, 174.3574],
  [-35.7674, 174.3561],
  [-35.7682, 174.3552],
  [-35.7688, 174.3549],
  [-35.7695, 174.3549],
  [-35.7711, 174.3552]
];

export const onerahi: Line = {
  name: 'Onerahi',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1911-10-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1933-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
