import { Line } from "../../../trackTypes";

const portHeadlandRoyHillMine = [
  [-20.3625, 118.5122],
  [-20.3540, 118.5162],
  [-20.3511, 118.5179],
  [-20.3497, 118.5195],
  [-20.3485, 118.5216],
  [-20.3436, 118.5331],
  [-20.3433, 118.5353],
  [-20.3440, 118.5372],
  [-20.3459, 118.5388],
  [-20.3480, 118.5391],
  [-20.3496, 118.5384],
  [-20.3512, 118.5363],
  [-20.3543, 118.5235],
  [-20.3540, 118.5212],
  [-20.3536, 118.5197],
  [-20.3539, 118.5180],
  [-20.3545, 118.5168],
  [-20.3560, 118.5155],
  [-20.3604, 118.5134],
  [-20.3625, 118.5122],
  [-20.3815, 118.5032],
  [-20.3873, 118.5018],
  [-20.4548, 118.5021],
  [-20.4625, 118.5047],
  [-20.4846, 118.5198],
  [-20.4975, 118.5343],
  [-20.4994, 118.5359],
  [-20.5305, 118.5576],
  [-20.5374, 118.5617],
  [-20.5450, 118.5642],
  [-20.5983, 118.5732],
  [-20.6540, 118.5848],
  [-20.6761, 118.5873],
  [-20.7007, 118.5943],
  [-20.7040, 118.5949],
  [-20.7160, 118.5951],
  [-20.7292, 118.5988],
  [-20.7408, 118.6052],
  [-20.7453, 118.6071],
  [-20.7566, 118.6108],
  [-20.7684, 118.6171],
  [-20.7740, 118.6228],
  [-20.7937, 118.6559],
  [-20.8001, 118.6628],
  [-20.8103, 118.6675],
  [-20.8184, 118.6677],
  [-20.8512, 118.6626],
  [-20.8613, 118.6626],
  [-20.9429, 118.6788],
  [-20.9939, 118.6966],
  [-20.9986, 118.6976],
  [-21.0264, 118.6998],
  [-21.0323, 118.7014],
  [-21.0391, 118.7046],
  [-21.0462, 118.7060],
  [-21.0676, 118.7038],
  [-21.0753, 118.7045],
  [-21.0818, 118.7071],
  [-21.1366, 118.7459],
  [-21.1457, 118.7508],
  [-21.1642, 118.7567],
  [-21.1840, 118.7599],
  [-21.2001, 118.7656],
  [-21.2140, 118.7718],
  [-21.2286, 118.7765],
  [-21.2602, 118.7930],
  [-21.2701, 118.7961],
  [-21.3001, 118.8014],
  [-21.3096, 118.8019],
  [-21.3285, 118.8069],
  [-21.3752, 118.8156],
  [-21.3814, 118.8156],
  [-21.4765, 118.8154],
  [-21.5522, 118.8148],
  [-21.5897, 118.8230],
  [-21.6117, 118.8231],
  [-21.6648, 118.8364],
  [-21.7014, 118.8417],
  [-21.7111, 118.8490],
  [-21.7167, 118.8578],
  [-21.7229, 118.8829],
  [-21.7273, 118.8927],
  [-21.7341, 118.8975],
  [-21.7429, 118.8984],
  [-21.7576, 118.8958],
  [-21.7643, 118.8964],
  [-21.7700, 118.8992],
  [-21.7929, 118.9189],
  [-21.8020, 118.9251],
  [-21.8263, 118.9444],
  [-21.8306, 118.9465],
  [-21.8438, 118.9499],
  [-21.8514, 118.9491],
  [-21.8549, 118.9497],
  [-21.8696, 118.9593],
  [-21.8736, 118.9603],
  [-21.8781, 118.9602],
  [-21.8814, 118.9612],
  [-21.9020, 118.9784],
  [-21.9041, 118.9821],
  [-21.9147, 119.0334],
  [-21.9182, 119.0402],
  [-21.9501, 119.0719],
  [-21.9522, 119.0766],
  [-21.9569, 119.1050],
  [-21.9589, 119.1088],
  [-21.9640, 119.1144],
  [-21.9664, 119.1206],
  [-21.9669, 119.1250],
  [-21.9686, 119.1292],
  [-22.0008, 119.1738],
  [-22.0074, 119.1782],
  [-22.0167, 119.1795],
  [-22.0290, 119.1869],
  [-22.0360, 119.1891],
  [-22.0514, 119.1989],
  [-22.0621, 119.2145],
  [-22.0661, 119.2189],
  [-22.0916, 119.2330],
  [-22.0948, 119.2362],
  [-22.1003, 119.2459],
  [-22.1040, 119.2496],
  [-22.1172, 119.2586],
  [-22.1583, 119.2731],
  [-22.1605, 119.2745],
  [-22.1633, 119.2768],
  [-22.1668, 119.2784],
  [-22.1756, 119.2786],
  [-22.1917, 119.2808],
  [-22.1967, 119.2830],
  [-22.2011, 119.2867],
  [-22.2102, 119.3003],
  [-22.2189, 119.3195],
  [-22.2249, 119.3285],
  [-22.2547, 119.3524],
  [-22.2725, 119.3751],
  [-22.2737, 119.3787],
  [-22.2750, 119.4080],
  [-22.2746, 119.4279],
  [-22.2754, 119.4323],
  [-22.2836, 119.4503],
  [-22.2908, 119.4703],
  [-22.2972, 119.4783],
  [-22.2999, 119.4833],
  [-22.3064, 119.5089],
  [-22.3152, 119.5230],
  [-22.3198, 119.5416],
  [-22.3304, 119.5593],
  [-22.3325, 119.5656],
  [-22.3336, 119.5766],
  [-22.3371, 119.5939],
  [-22.3362, 119.6002],
  [-22.3321, 119.6102],
  [-22.3312, 119.6180],
  [-22.3327, 119.6243],
  [-22.3366, 119.6334],
  [-22.3371, 119.6395],
  [-22.3359, 119.6447],
  [-22.3285, 119.6606],
  [-22.3242, 119.6657],
  [-22.3216, 119.6715],
  [-22.3215, 119.6774],
  [-22.3230, 119.6851],
  [-22.3239, 119.7033],
  [-22.3237, 119.7063],
  [-22.3135, 119.7397],
  [-22.3123, 119.7455],
  [-22.3125, 119.7500],
  [-22.3163, 119.7611],
  [-22.3165, 119.7656],
  [-22.3152, 119.7693],
  [-22.3111, 119.7747],
  [-22.3098, 119.7777],
  [-22.3063, 119.7913],
  [-22.3048, 119.7943],
  [-22.2999, 119.7998],
  [-22.2979, 119.8037],
  [-22.2959, 119.8135],
  [-22.2972, 119.8239],
  [-22.3018, 119.8345],
  [-22.3026, 119.8394],
  [-22.2998, 119.8668],
  [-22.2975, 119.8735],
  [-22.2874, 119.8841],
  [-22.2841, 119.8893],
  [-22.2832, 119.8937],
  [-22.2821, 119.9053],
  [-22.2853, 119.9754],
  [-22.2869, 119.9811],
  [-22.2980, 119.9957],
  [-22.3078, 120.0006],
  [-22.3133, 120.0004],
  [-22.3284, 119.9915],
  [-22.3422, 119.9833],
  [-22.3686, 119.9758],
  [-22.3751, 119.9748],
  [-22.4006, 119.9785],
  [-22.4222, 119.9862],
  [-22.4265, 119.9869],
  [-22.4414, 119.9858],
  [-22.4449, 119.9837],
  [-22.4473, 119.9796],
  [-22.4505, 119.9670],
  [-22.4508, 119.9463],
  [-22.4511, 119.9403],
  [-22.4503, 119.9375],
  [-22.4487, 119.9357],
  [-22.4422, 119.9328],
  [-22.4396, 119.9330],
  [-22.4378, 119.9350],
  [-22.4375, 119.9382],
  [-22.4387, 119.9403],
  [-22.4405, 119.9413],
  [-22.4477, 119.9424],
  [-22.4496, 119.9436],
  [-22.4508, 119.9463]
];

export const royHill: Line = {
  name: 'Roy Hill',
  state: 'WA',
  segments: [
    {
      segments: [portHeadlandRoyHillMine],
      history: {
        opened: {
          date: '2015-12-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
  ]
};
