import { Line } from "../../trackTypes";

const line = [
  [-34.7546, 138.6430],
  [-34.7526, 138.6424],
  [-34.7507, 138.6414],
  [-34.7495, 138.6404],
  [-34.7423, 138.6334],
  [-34.7414, 138.6331],
  [-34.7405, 138.6332],
  [-34.7128, 138.6509]
];

const balloonLoop = [
  [-34.7128, 138.6509],
  [-34.7119, 138.6511],
  [-34.7108, 138.6506],
  [-34.7097, 138.6507],
  [-34.7087, 138.6520],
  [-34.7086, 138.6533],
  [-34.7093, 138.6546],
  [-34.7104, 138.6548],
  [-34.7112, 138.6542],
  [-34.7118, 138.6530],
  [-34.7121, 138.6517],
  [-34.7128, 138.6509]
];

export const penfield: Line = {
  name: 'Penfield',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1941-01-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-01-01',
          tracks: 1
        }, {
          date: '1991-01-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balloonLoop],
      history: {
        opened: {
          date: '1941-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-06-08',
          status: 'closed'
        }]
      }
    }
  ]
};
