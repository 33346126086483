import { Line } from "../../../../trackTypes";

const toorak = [
  [-37.83949, 144.99562],
  [-37.84274, 145.02295]
];

const toorakExtension = [
  [-37.84274, 145.02295],
  [-37.84376, 145.03134],
  [-37.84382, 145.03146],
  [-37.84392, 145.03151]
];

export const east4: Line = {
  name: 'East4',
  state: 'VIC',
  segments: [
    {
      segments: [toorak],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1927-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [toorakExtension],
      history: {
        opened: {
          date: '1927-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
