import { Line } from "../../../trackTypes";

const line = [
  [-34.59560, 138.74791],
  [-34.59589, 138.74883],
  [-34.59603, 138.74896],
  [-34.59617, 138.74905],
  [-34.60242, 138.74933],
  [-34.60263, 138.74930],
  [-34.60282, 138.74919],
  [-34.60294, 138.74902],
  [-34.60329, 138.74832],
  [-34.60344, 138.74802],
  [-34.60377, 138.74715],
  [-34.60389, 138.74694],
  [-34.60403, 138.74675],
  [-34.60810, 138.74330],
  [-34.60819, 138.74319],
  [-34.60825, 138.74306],
  [-34.60834, 138.74063],
  [-34.60839, 138.74051],
  [-34.60845, 138.74039],
  [-34.60905, 138.73994]
];

export const gawlerTram: Line = {
  name: 'Gawler Tram',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1879-02-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1901-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
