import { Line } from "../../trackTypes";

const berrimaJunctionBerrimaCementWorks = [
  [-34.5266, 150.3809],
  [-34.5259, 150.3807],
  [-34.5244, 150.3795],
  [-34.5236, 150.3781],
  [-34.5212, 150.3666],
  [-34.5190, 150.3607],
  [-34.5185, 150.3579],
  [-34.5147, 150.3504],
  [-34.5138, 150.3495],
  [-34.5127, 150.3486],
  [-34.5103, 150.3452],
  [-34.5100, 150.3444],
  [-34.5086, 150.3365]
];

const berrimaCementWorksBerrimaColliery = [
  [-34.5086, 150.3365],
  [-34.5076, 150.3312],
  [-34.5074, 150.3307],
  [-34.5017, 150.3193],
  [-34.4975, 150.3088],
  [-34.4973, 150.3078],
  [-34.4957, 150.2976],
  [-34.4921, 150.2893],
  [-34.4919, 150.2885],
  [-34.4864, 150.2664]
];

export const berrima: Line = {
  name: 'Berrima',
  state: 'NSW',
  segments: [
    {
      segments: [berrimaJunctionBerrimaCementWorks],
      history: {
        opened: {
          date: '1927-02-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [berrimaCementWorksBerrimaColliery],
      history: {
        opened: {
          date: '1927-02-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
