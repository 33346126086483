import { Line } from "../../trackTypes";

const line = [
  [-36.9962, 174.8601],
  [-36.9969, 174.8607],
  [-36.9971, 174.8612],
  [-36.9972, 174.8619],
  [-36.9973, 174.8641],
  [-36.9968, 174.8687],
  [-36.9964, 174.8709],
  [-36.9960, 174.8721],
  [-36.9950, 174.8742],
  [-36.9932, 174.8793]
];

export const manukau: Line = {
  name: 'Manukau',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2012-04-15',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
