import { Line } from "../../trackTypes";

const springvaleCemeteryLine = [
  [-37.9505, 145.1547],
  [-37.9508, 145.1561],
  [-37.9509, 145.1569],
  [-37.9506, 145.1590],
  [-37.9502, 145.1597],
  [-37.9487, 145.1637],
  [-37.9450, 145.1703],
  [-37.9441, 145.1714],
  [-37.9432, 145.1718]
];

export const springvaleCemetery: Line = {
  name: 'Springvale Cemetery',
  state: 'VIC',
  segments: [
    {
      segments: [springvaleCemeteryLine],
      history: {
        opened: {
          date: '1904-02-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1922-12-11',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1950-12-19',
          status: 'closed'
        }]
      }
    }
  ]
}
