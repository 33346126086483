import { Line } from "../../../trackTypes";

const flynnPhosphateHill = [
  [-21.3192, 139.9555],
  [-21.3228, 139.9540],
  [-21.3746, 139.9473],
  [-21.4513, 139.9540],
  [-21.4549, 139.9550],
  [-21.4892, 139.9725],
  [-21.4923, 139.9736],
  [-21.5442, 139.9819],
  [-21.6304, 139.9782],
  [-21.6674, 139.9720],
  [-21.6709, 139.9720],
  [-21.6933, 139.9767],
  [-21.6961, 139.9769],
  [-21.7311, 139.9735],
  [-21.7422, 139.9701],
  [-21.7455, 139.9698],
  [-21.7900, 139.9775],
  [-21.7951, 139.9772],
  [-21.8144, 139.9699],
  [-21.8269, 139.9676],
  [-21.8327, 139.9683],
  [-21.8594, 139.9797],
  [-21.8633, 139.9805],
  [-21.8828, 139.9778],
  [-21.8875, 139.9769],
  [-21.8891, 139.9774],
  [-21.8902, 139.9793],
  [-21.8900, 139.9810],
  [-21.8885, 139.9825],
  [-21.8870, 139.9826],
  [-21.8858, 139.9820],
  [-21.8851, 139.9811],
  [-21.8846, 139.9794],
  [-21.8839, 139.9785],
  [-21.8832, 139.9780],
  [-21.8828, 139.9778]
];

export const phosphateHill: Line = {
  name: 'Phosphate Hill',
  state: 'QLD',
  segments: [
    {
      segments: [flynnPhosphateHill],
      history: {
        opened: {
          date: '1976-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
