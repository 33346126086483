import { Line } from "../../../trackTypes";

const line = [
  [-27.6333, 152.6164],
  [-27.6346, 152.6153],
  [-27.6365, 152.6148],
  [-27.6381, 152.6153],
  [-27.6620, 152.6409],
  [-27.6643, 152.6454],
  [-27.6655, 152.6524],
  [-27.6668, 152.6617],
  [-27.6665, 152.6631],
  [-27.6655, 152.6640],
  [-27.6643, 152.6643],
  [-27.6631, 152.6639],
  [-27.6624, 152.6628],
  [-27.6623, 152.6613],
  [-27.6652, 152.6536],
  [-27.6655, 152.6524]
];

export const yarrowleaEbenezer: Line = {
  name: 'Yarrowlea - Ebenezer',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1990-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
