import { Line } from "../../../trackTypes";

const otiriaKaikohe = [
  [-35.4011, 173.9961],
  [-35.4019, 173.9881],
  [-35.4055, 173.9714],
  [-35.4054, 173.9678],
  [-35.4049, 173.9666],
  [-35.4039, 173.9658],
  [-35.4027, 173.9656],
  [-35.4020, 173.9651],
  [-35.4015, 173.9641],
  [-35.4013, 173.9621],
  [-35.4016, 173.9611],
  [-35.4030, 173.9596],
  [-35.4073, 173.9565],
  [-35.4084, 173.9547],
  [-35.4119, 173.9435],
  [-35.4129, 173.9410],
  [-35.4175, 173.9325],
  [-35.4179, 173.9298],
  [-35.4176, 173.9279],
  [-35.4167, 173.9262],
  [-35.4165, 173.9248],
  [-35.4167, 173.9236],
  [-35.4174, 173.9226],
  [-35.4222, 173.9193],
  [-35.4300, 173.9115],
  [-35.4325, 173.9083],
  [-35.4362, 173.9048],
  [-35.4378, 173.9033],
  [-35.4390, 173.9017],
  [-35.4397, 173.9009],
  [-35.4406, 173.9007],
  [-35.4427, 173.9007],
  [-35.4438, 173.9005],
  [-35.4444, 173.9000],
  [-35.4449, 173.8987],
  [-35.4448, 173.8970],
  [-35.4452, 173.8932],
  [-35.4458, 173.8917],
  [-35.4470, 173.8892],
  [-35.4473, 173.8874],
  [-35.4470, 173.8793],
  [-35.4467, 173.8769],
  [-35.4448, 173.8697],
  [-35.4401, 173.8596],
  [-35.4399, 173.8583],
  [-35.4405, 173.8508],
  [-35.4409, 173.8491],
  [-35.4420, 173.8469],
  [-35.4422, 173.8458],
  [-35.4422, 173.8419],
  [-35.4425, 173.8395],
  [-35.4438, 173.8338],
  [-35.4444, 173.8327],
  [-35.4452, 173.8321],
  [-35.4464, 173.8319],
  [-35.4473, 173.8312],
  [-35.4480, 173.8298],
  [-35.4480, 173.8286],
  [-35.4488, 173.8252],
  [-35.4498, 173.8239],
  [-35.4509, 173.8234],
  [-35.4521, 173.8236],
  [-35.4528, 173.8234],
  [-35.4539, 173.8225],
  [-35.4544, 173.8215],
  [-35.4549, 173.8209],
  [-35.4556, 173.8205],
  [-35.4573, 173.8206],
  [-35.4581, 173.8202],
  [-35.4585, 173.8193],
  [-35.4586, 173.8183],
  [-35.4587, 173.8175],
  [-35.4594, 173.8159],
  [-35.4594, 173.8146],
  [-35.4588, 173.8134],
  [-35.4576, 173.8126],
  [-35.4220, 173.8084],
  [-35.4209, 173.8082],
  [-35.4190, 173.8071],
  [-35.4173, 173.8049],
  [-35.4144, 173.8001]
];

const kaikoheOkaihau = [
  [-35.4144, 173.8001],
  [-35.4118, 173.7961],
  [-35.4113, 173.7945],
  [-35.4112, 173.7930],
  [-35.4118, 173.7916],
  [-35.4128, 173.7905],
  [-35.4133, 173.7893],
  [-35.4133, 173.7880],
  [-35.4126, 173.7861],
  [-35.4124, 173.7849],
  [-35.4125, 173.7835],
  [-35.4123, 173.7826],
  [-35.4115, 173.7816],
  [-35.4096, 173.7800],
  [-35.4089, 173.7798],
  [-35.4081, 173.7798],
  [-35.4069, 173.7793],
  [-35.4062, 173.7784],
  [-35.4049, 173.7743],
  [-35.4041, 173.7735],
  [-35.4030, 173.7734],
  [-35.4024, 173.7740],
  [-35.4020, 173.7752],
  [-35.4014, 173.7760],
  [-35.4008, 173.7764],
  [-35.3978, 173.7764],
  [-35.3971, 173.7758],
  [-35.3966, 173.7749],
  [-35.3965, 173.7742],
  [-35.3965, 173.7735],
  [-35.3963, 173.7727],
  [-35.3960, 173.7721],
  [-35.3952, 173.7719],
  [-35.3946, 173.7720],
  [-35.3941, 173.7724],
  [-35.3936, 173.7733],
  [-35.3930, 173.7737],
  [-35.3920, 173.7737],
  [-35.3892, 173.7721],
  [-35.3876, 173.7715],
  [-35.3866, 173.7713],
  [-35.3831, 173.7715],
  [-35.3780, 173.7717],
  [-35.3770, 173.7723],
  [-35.3763, 173.7732],
  [-35.3752, 173.7764],
  [-35.3741, 173.7774],
  [-35.3714, 173.7776],
  [-35.3704, 173.7773],
  [-35.3690, 173.7762],
  [-35.3621, 173.7732],
  [-35.3613, 173.7726],
  [-35.3605, 173.7718],
  [-35.3597, 173.7716],
  [-35.3593, 173.7718],
  [-35.3587, 173.7718],
  [-35.3538, 173.7694],
  [-35.3494, 173.7684],
  [-35.3440, 173.7686],
  [-35.3432, 173.7686],
  [-35.3391, 173.7678],
  [-35.3371, 173.7679],
  [-35.3365, 173.7676],
  [-35.3345, 173.7657],
  [-35.3338, 173.7655],
  [-35.3330, 173.7659],
  [-35.3308, 173.7682],
  [-35.3302, 173.7683],
  [-35.3292, 173.7681],
  [-35.3284, 173.7682],
  [-35.3277, 173.7690],
  [-35.3270, 173.7708],
  [-35.3266, 173.7746],
  [-35.3260, 173.7758],
  [-35.3251, 173.7760],
  [-35.3244, 173.7757],
  [-35.3207, 173.7706]
];

const okaihauRangaihua = [
  [-35.3207, 173.7706],
  [-35.3205, 173.7695],
  [-35.3208, 173.7685],
  [-35.3215, 173.7671],
  [-35.3229, 173.7599],
  [-35.3232, 173.7590],
  [-35.3239, 173.7584],
  [-35.3247, 173.7579],
  [-35.3249, 173.7576],
  [-35.3250, 173.7571],
  [-35.3248, 173.7547],
  [-35.3250, 173.7539],
  [-35.3254, 173.7533],
  [-35.3260, 173.7529],
  [-35.3269, 173.7528],
  [-35.3273, 173.7525],
  [-35.3288, 173.7503],
  [-35.3300, 173.7483],
  [-35.3309, 173.7452],
  [-35.3308, 173.7437],
  [-35.3287, 173.7381],
  [-35.3281, 173.7342],
  [-35.3283, 173.7330],
  [-35.3289, 173.7320],
  [-35.3291, 173.7314],
  [-35.3291, 173.7296],
  [-35.3294, 173.7266],
  [-35.3291, 173.7257],
  [-35.3283, 173.7246],
  [-35.3281, 173.7239],
  [-35.3277, 173.7222],
  [-35.3271, 173.7210],
  [-35.3268, 173.7173],
  [-35.3262, 173.7166],
  [-35.3253, 173.7165],
  [-35.3245, 173.7171],
  [-35.3241, 173.7178],
  [-35.3240, 173.7198],
  [-35.3245, 173.7219],
  [-35.3244, 173.7251],
  [-35.3242, 173.7258],
  [-35.3235, 173.7274],
  [-35.3231, 173.7280],
  [-35.3224, 173.7286],
  [-35.3218, 173.7294],
  [-35.3208, 173.7296],
  [-35.3200, 173.7289],
  [-35.3192, 173.7272],
  [-35.3186, 173.7266],
  [-35.3175, 173.7267],
  [-35.3168, 173.7275],
  [-35.3167, 173.7289],
  [-35.3172, 173.7305],
  [-35.3186, 173.7332],
  [-35.3201, 173.7350],
  [-35.3206, 173.7373],
  [-35.3200, 173.7393],
  [-35.3188, 173.7399],
  [-35.3175, 173.7400],
  [-35.3165, 173.7396],
  [-35.3158, 173.7383],
  [-35.3151, 173.7380],
  [-35.3142, 173.7383],
  [-35.3137, 173.7392],
  [-35.3135, 173.7418],
  [-35.3126, 173.7430],
  [-35.3115, 173.7429],
  [-35.3107, 173.7421],
  [-35.3104, 173.7413],
  [-35.3097, 173.7406],
  [-35.3087, 173.7404],
  [-35.3073, 173.7407],
  [-35.3065, 173.7401],
  [-35.3061, 173.7392],
  [-35.3062, 173.7367],
  [-35.3057, 173.7354],
  [-35.3046, 173.7348],
  [-35.3035, 173.7350],
  [-35.3015, 173.7371],
  [-35.3008, 173.7375],
  [-35.3000, 173.7374],
  [-35.2976, 173.7368],
  [-35.2969, 173.7363],
  [-35.2941, 173.7326],
  [-35.2916, 173.7285],
  [-35.2880, 173.7224],
  [-35.2876, 173.7212],
  [-35.2873, 173.7202],
  [-35.2882, 173.7113],
  [-35.2880, 173.7105],
  [-35.2876, 173.7101],
  [-35.2872, 173.7100],
  [-35.2869, 173.7095],
  [-35.2863, 173.7086],
  [-35.2861, 173.7080],
  [-35.2863, 173.7043],
  [-35.2867, 173.7019],
  [-35.2882, 173.6943],
  [-35.2912, 173.6872],
  [-35.2924, 173.6827],
  [-35.2931, 173.6738],
  [-35.2926, 173.6680],
  [-35.2927, 173.6664],
  [-35.2934, 173.6649],
  [-35.2998, 173.6565],
  [-35.3029, 173.6538]
];

export const okaihau: Line = {
  name: 'Okaihau',
  state: 'NZ',
  segments: [
    {
      segments: [otiriaKaikohe],
      history: {
        opened: {
          date: '1914-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kaikoheOkaihau],
      history: {
        opened: {
          date: '1923-10-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [okaihauRangaihua],
      history: {
        opened: {
          date: '1931-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1936-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
