import { Line } from "../../../trackTypes";

const invercargillKewDeviationStart = [
  [-46.4119, 168.3449],
  [-46.4142, 168.3447],
  [-46.4164, 168.3437],
  [-46.4205, 168.3436]
];

const kewOld = [
  [-46.4205, 168.3436],
  [-46.4232, 168.3436],
  [-46.4236, 168.3432],
  [-46.4247, 168.3400],
  [-46.4248, 168.3397],
  [-46.4250, 168.3396],
  [-46.4278, 168.3399],
  [-46.4344, 168.3446],
  [-46.4380, 168.3476],
  [-46.4409, 168.3496],
  [-46.4412, 168.3502],
  [-46.4412, 168.3509],
  [-46.4411, 168.3517],
  [-46.4412, 168.3521],
  [-46.4413, 168.3524]
];

const kewDeviation = [
  [-46.4205, 168.3436],
  [-46.4214, 168.3439],
  [-46.4224, 168.3443],
  [-46.4238, 168.3455],
  [-46.4256, 168.3485],
  [-46.4274, 168.3501],
  [-46.4282, 168.3504],
  [-46.4296, 168.3505],
  [-46.4350, 168.3498],
  [-46.4376, 168.3501],
  [-46.4397, 168.3512],
  [-46.4413, 168.3524]
];

const kewDeviationEndBluff = [
  [-46.4413, 168.3524],
  [-46.4606, 168.3688],
  [-46.4626, 168.3713],
  [-46.4682, 168.3798],
  [-46.4723, 168.3839],
  [-46.4781, 168.3865],
  [-46.4842, 168.3865],
  [-46.4900, 168.3828],
  [-46.5027, 168.3673],
  [-46.5092, 168.3529],
  [-46.5112, 168.3461],
  [-46.5125, 168.3371],
  [-46.5144, 168.3287],
  [-46.5164, 168.3232],
  [-46.5211, 168.3142],
  [-46.5255, 168.3086],
  [-46.5300, 168.3059],
  [-46.5424, 168.3019],
  [-46.5568, 168.2997],
  [-46.5647, 168.2993],
  [-46.5688, 168.3006],
  [-46.5758, 168.3056],
  [-46.5789, 168.3066],
  [-46.5832, 168.3059],
  [-46.5859, 168.3051],
  [-46.5878, 168.3056],
  [-46.5904, 168.3082],
  [-46.5932, 168.3135],
  [-46.5940, 168.3169],
  [-46.5943, 168.3231],
  [-46.5946, 168.3258],
  [-46.5951, 168.3273],
  [-46.5959, 168.3289],
  [-46.5968, 168.3309],
  [-46.5973, 168.3324],
  [-46.5987, 168.3446],
  [-46.5989, 168.3454],
  [-46.5997, 168.3462],
  [-46.6002, 168.3470],
  [-46.6006, 168.3481],
  [-46.6009, 168.3488]
];

const harbourIsland = [
  [-46.5959, 168.3289],
  [-46.5962, 168.3301],
  [-46.5960, 168.3309],
  [-46.5956, 168.3314],
  [-46.5944, 168.3323],
  [-46.5938, 168.3324],
  [-46.5934, 168.3321],
  [-46.5926, 168.3309],
  [-46.5903, 168.3262],
  [-46.5881, 168.3229]
];

const mokomokoHarbourBranch = [
  [-46.5164, 168.3232],
  [-46.5172, 168.3192],
  [-46.5170, 168.3137],
  [-46.5159, 168.3099],
  [-46.5144, 168.3066],
  [-46.5120, 168.3039],
  [-46.5101, 168.3021]
];

export const bluff: Line = {
  name: 'Bluff',
  state: 'NZ',
  segments: [
    {
      segments: [
        invercargillKewDeviationStart,
        {
          date: '1872-01-01',
          original: [kewOld],
          deviation: [kewDeviation]
        },
        kewDeviationEndBluff
      ],
      history: {
        opened: {
          date: '1867-02-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1875-12-18',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [harbourIsland],
      history: {
        opened: {
          date: '1960-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mokomokoHarbourBranch],
      history: {
        opened: {
          date: '1867-02-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1875-12-18',
          status: 'closed'
        }]
      }
    }
  ]
}
