import { Line } from "../../trackTypes";

const wanbiYinkanie = [
  [-34.7653, 140.3080],
  [-34.7647, 140.3099],
  [-34.7629, 140.3120],
  [-34.7611, 140.3127],
  [-34.7595, 140.3125],
  [-34.7581, 140.3118],
  [-34.7571, 140.3107],
  [-34.7554, 140.3081],
  [-34.7541, 140.3069],
  [-34.7527, 140.3064],
  [-34.7366, 140.3038],
  [-34.7244, 140.2998],
  [-34.7150, 140.2968],
  [-34.7135, 140.2965],
  [-34.6876, 140.2966],
  [-34.6862, 140.2962],
  [-34.6849, 140.2953],
  [-34.6836, 140.2937],
  [-34.6805, 140.2885],
  [-34.6719, 140.2788],
  [-34.6703, 140.2779],
  [-34.6690, 140.2776],
  [-34.6673, 140.2779],
  [-34.6615, 140.2801],
  [-34.6372, 140.2891],
  [-34.6340, 140.2894],
  [-34.6180, 140.2896],
  [-34.6157, 140.2901],
  [-34.6120, 140.2921],
  [-34.6103, 140.2927],
  [-34.6091, 140.2940],
  [-34.6045, 140.2960],
  [-34.6027, 140.2963],
  [-34.5843, 140.2963],
  [-34.5823, 140.2970],
  [-34.5747, 140.3017],
  [-34.5731, 140.3020],
  [-34.5227, 140.3024],
  [-34.5132, 140.3021],
  [-34.5080, 140.3008],
  [-34.4869, 140.3006],
  [-34.4849, 140.3011],
  [-34.4585, 140.3127],
  [-34.4437, 140.3156],
  [-34.4416, 140.3156],
  [-34.4202, 140.3089],
  [-34.4167, 140.3089],
  [-34.4132, 140.3099],
  [-34.4085, 140.3144],
  [-34.4058, 140.3156],
  [-34.3924, 140.3173],
  [-34.3531, 140.3177],
  [-34.3517, 140.3180],
  [-34.3413, 140.3207],
  [-34.3400, 140.3207],
  [-34.3301, 140.3192]
];

export const yinkanie: Line = {
  name: 'Yinkanie',
  state: 'SA',
  segments: [
    {
      segments: [wanbiYinkanie],
      history: {
        opened: {
          date: '1925-09-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-05-01',
          status: 'closed'
        }]
      }
    }
  ]
};
