import React, { useState } from "react";
import styled from "styled-components";

const CollapsedMenu = styled.div<{ $isOpen: boolean; $openCount: number }>`
  background-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 0, 15px, 15px, 0;
  border: 1px solid ${({ theme }) => theme.textColour};
  border-left: none;
  z-index: 10000;
  position: absolute;
  color: ${({ theme }) => theme.textColour};

  top: 100px;
  left: ${({ $isOpen }) => ($isOpen ? "280px" : "0")};

  padding: 0.25rem 0.5rem;

  @media (max-width: 600px) {
    left: ${({ $isOpen }) => ($isOpen ? "238px" : "0")};
  }

  ${({ $openCount }) =>
    $openCount === 0 && `
    animation: flash 0.75s linear 2;
      @keyframes flash {
      50% {
        background-color: yellow;
      }
    }
  `}
`;

const OpenMenu = styled.div`
  background-color: ${({ theme }) => theme.backgroundSecondary};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 100000;
`;

type SideMenuProps = {
  children: React.ReactNode;
};

const SideMenu = ({ children }: SideMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openCount, setOpenCount] = useState(0);

  return (
    <>
      {!isOpen && (
        <CollapsedMenu
          $openCount={openCount}
          $isOpen={false}
          onClick={() => {
            setIsOpen(true);
            setOpenCount(openCount + 1);
          }}
        >
          <p>{">"}</p>
        </CollapsedMenu>
      )}
      {isOpen && (
        <>
          <OpenMenu>{children}</OpenMenu>
          <CollapsedMenu
            $openCount={openCount}
            $isOpen={true}
            onClick={() => {
              setIsOpen(false);
              setOpenCount(openCount + 1);
            }}
          >
            <p>{"<"}</p>
          </CollapsedMenu>
        </>
      )}
    </>
  );
};

export default SideMenu;
