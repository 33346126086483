import { Line } from "../../../trackTypes";

const mararoaJunctionCastleRock = [
  [-45.7169, 168.4318],
  [-45.7166, 168.4313],
  [-45.7164, 168.4305],
  [-45.7161, 168.4254],
  [-45.7166, 168.3985],
  [-45.7164, 168.3963],
  [-45.7149, 168.3916]
];

const castleRockMurrayCreek = [
  [-45.7149, 168.3916],
  [-45.6925, 168.3286]
];

const murrayCreekMossburn = [
  [-45.6925, 168.3286],
  [-45.6840, 168.2860],
  [-45.6720, 168.2428],
  [-45.6716, 168.2398],
  [-45.6711, 168.2294]
];

export const mossburn: Line = {
  name: 'Mossburn',
  state: 'NZ',
  segments: [
    {
      segments: [mararoaJunctionCastleRock],
      history: {
        opened: {
          date: '1881-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-12-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [castleRockMurrayCreek],
      history: {
        opened: {
          date: '1886-03-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-12-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [murrayCreekMossburn],
      history: {
        opened: {
          date: '1887-01-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-12-16',
          status: 'closed'
        }]
      }
    }
  ]
}
