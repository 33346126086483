import { Line } from "../../../trackTypes";

const pukeruiMaerewhenuaRiver = [
  [-45.0327, 171.0302],
  [-45.0318, 171.0303],
  [-45.0308, 171.0298],
  [-45.0208, 171.0206],
  [-45.0118, 171.0147],
  [-45.0111, 171.0140],
  [-44.9958, 170.9920],
  [-44.9914, 170.9840],
  [-44.9875, 170.9779],
  [-44.9821, 170.9738],
  [-44.9773, 170.9696],
  [-44.9712, 170.9651],
  [-44.9628, 170.9573],
  [-44.9431, 170.9359],
  [-44.9363, 170.9259],
  [-44.9354, 170.9242],
  [-44.9122, 170.8602],
  [-44.9096, 170.8555],
  [-44.9040, 170.8431],
  [-44.8895, 170.8111],
  [-44.8891, 170.8095],
  [-44.8814, 170.7812],
  [-44.8815, 170.7800],
  [-44.8820, 170.7767],
  [-44.8820, 170.7757],
  [-44.8794, 170.7652],
  [-44.8648, 170.7161],
  [-44.8577, 170.6926]
];

const warewhenuaRiverKurow = [
  [-44.8577, 170.6926],
  [-44.8552, 170.6862],
  [-44.8538, 170.6824],
  [-44.8498, 170.6769],
  [-44.8490, 170.6751],
  [-44.8424, 170.6527],
  [-44.8419, 170.6492],
  [-44.8428, 170.6422],
  [-44.8427, 170.6407],
  [-44.8400, 170.6298],
  [-44.8364, 170.6206],
  [-44.8353, 170.6187],
  [-44.8301, 170.6120],
  [-44.8295, 170.6107],
  [-44.8291, 170.6095],
  [-44.8280, 170.5993],
  [-44.8287, 170.5775],
  [-44.8283, 170.5756],
  [-44.8275, 170.5737],
  [-44.8203, 170.5628],
  [-44.7898, 170.5293],
  [-44.7583, 170.4944],
  [-44.7563, 170.4905],
  [-44.7558, 170.4900],
  [-44.7551, 170.4897],
  [-44.7546, 170.4893],
  [-44.7540, 170.4886],
  [-44.7536, 170.4878],
  [-44.7520, 170.4825],
  [-44.7514, 170.4815],
  [-44.7507, 170.4808],
  [-44.7334, 170.4686],
  [-44.7326, 170.4685],
  [-44.7316, 170.4688]
];

const kurowHakataramea = [
  [-44.7316, 170.4688],
  [-44.7311, 170.4694],
  [-44.7291, 170.4749],
  [-44.7262, 170.4810],
  [-44.7256, 170.4814],
  [-44.7215, 170.4820],
  [-44.7206, 170.4816],
  [-44.7198, 170.4803],
  [-44.7176, 170.4787],
  [-44.7171, 170.4787],
  [-44.7165, 170.4789],
  [-44.7154, 170.4791],
  [-44.7148, 170.4796],
  [-44.7147, 170.4804],
  [-44.7153, 170.4831],
  [-44.7151, 170.4841],
  [-44.7146, 170.4844],
  [-44.7130, 170.4844],
  [-44.7122, 170.4842],
  [-44.7116, 170.4845],
  [-44.7106, 170.4859],
  [-44.7087, 170.4870],
  [-44.7077, 170.4871],
  [-44.7071, 170.4877],
  [-44.7064, 170.4902],
  [-44.7048, 170.4929],
  [-44.7028, 170.4951],
  [-44.7003, 170.4978],
  [-44.6968, 170.5050],
  [-44.6963, 170.5054],
  [-44.6960, 170.5053],
  [-44.6954, 170.5051],
  [-44.6949, 170.5052],
  [-44.6940, 170.5062],
  [-44.6937, 170.5068],
  [-44.6918, 170.5129]
];

const kurowWaitakiHydroElectricDam = [
  [-44.7316, 170.4688],
  [-44.7308, 170.4688],
  [-44.7252, 170.4660],
  [-44.7225, 170.4638],
  [-44.7214, 170.4622],
  [-44.7189, 170.4599],
  [-44.7178, 170.4587],
  [-44.7170, 170.4569],
  [-44.7164, 170.4564],
  [-44.7130, 170.4565],
  [-44.7101, 170.4556],
  [-44.7092, 170.4550],
  [-44.7087, 170.4536],
  [-44.7047, 170.4457],
  [-44.7021, 170.4431],
  [-44.7015, 170.4420],
  [-44.6996, 170.4352],
  [-44.6983, 170.4331],
  [-44.6926, 170.4279],
  [-44.6918, 170.4270]
];

export const kurow: Line = {
  name: 'Kurow',
  state: 'NZ',
  segments: [
    {
      segments: [pukeruiMaerewhenuaRiver],
      history: {
        opened: {
          date: '1875-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-06-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [warewhenuaRiverKurow],
      history: {
        opened: {
          date: '1881-11-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1983-06-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kurowHakataramea],
      history: {
        opened: {
          date: '1881-11-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-07-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kurowWaitakiHydroElectricDam],
      history: {
        opened: {
          date: '1928-12-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-07-14',
          status: 'closed'
        }]
      }
    }
  ]
}
