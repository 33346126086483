import { Line } from "../../../trackTypes";

const line = [
  [-39.9079, 175.0552],
  [-39.9077, 175.0545],
  [-39.9078, 175.0539],
  [-39.9081, 175.0534],
  [-39.9240, 175.0374],
  [-39.9247, 175.0370],
  [-39.9252, 175.0370],
  [-39.9258, 175.0373],
  [-39.9290, 175.0407],
  [-39.9302, 175.0416],
  [-39.9317, 175.0423],
  [-39.9344, 175.0427],
  [-39.9353, 175.0430],
  [-39.9360, 175.0437],
  [-39.9387, 175.0485],
  [-39.9390, 175.0492],
  [-39.9391, 175.0501],
  [-39.9393, 175.0507],
  [-39.9396, 175.0510],
  [-39.9400, 175.0511],
  [-39.9405, 175.0509],
  [-39.9411, 175.0502],
  [-39.9415, 175.0495],
  [-39.9425, 175.0464],
  [-39.9439, 175.0437],
  [-39.9446, 175.0427],
  [-39.9466, 175.0395],
  [-39.9469, 175.0387],
  [-39.9468, 175.0381],
  [-39.9466, 175.0374],
  [-39.9466, 175.0368],
  [-39.9470, 175.0361],
  [-39.9487, 175.0332],
  [-39.9490, 175.0324],
  [-39.9491, 175.0315],
  [-39.9481, 175.0219],
  [-39.9482, 175.0208],
  [-39.9498, 175.0137],
  [-39.9498, 175.0128],
  [-39.9495, 175.0123],
  [-39.9481, 175.0094],
  [-39.9477, 175.0084],
  [-39.9468, 175.0045]
];

const oldJunction = [
  [-39.9068, 175.0546],
  [-39.9072, 175.0543],
  [-39.9081, 175.0534]
];

export const whanganui: Line = {
  name: 'Whanganui',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1878-01-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [oldJunction],
      history: {
        opened: {
          date: '1878-01-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
