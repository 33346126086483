import { Line } from "../../trackTypes";

const line = [
  [-37.1919, 143.1203],
  [-37.1910, 143.1219],
  [-37.1892, 143.1232],
  [-37.1796, 143.1281],
  [-37.1768, 143.1289],
  [-37.1686, 143.1296],
  [-37.1669, 143.1292],
  [-37.1649, 143.1278],
  [-37.1582, 143.1213],
  [-37.1534, 143.1139],
  [-37.1521, 143.1113],
  [-37.1406, 143.0941],
  [-37.1391, 143.0923],
  [-37.1291, 143.0851],
  [-37.1278, 143.0839],
  [-37.1265, 143.0818],
  [-37.1227, 143.0743],
  [-37.1206, 143.0722],
  [-37.0927, 143.0573],
  [-37.0906, 143.0566],
  [-37.0526, 143.0510],
  [-37.0193, 143.0511],
  [-37.0167, 143.0514],
  [-37.0142, 143.0524],
  [-37.0123, 143.0534],
  [-37.0078, 143.0573],
  [-37.0063, 143.0584],
  [-37.0040, 143.0588],
  [-36.9579, 143.0585],
  [-36.9370, 143.0628],
  [-36.9348, 143.0640],
  [-36.9325, 143.0659],
  [-36.9305, 143.0687],
  [-36.9112, 143.1029],
  [-36.9100, 143.1044],
  [-36.9092, 143.1049],
  [-36.9073, 143.1056],
  [-36.9045, 143.1063]
];

export const navarre: Line = {
  name: 'Navarre',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-05-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-02-24',
          status: 'closed'
        }]
      }
    }
  ]
};
