import { Line } from "../../trackTypes";

const queanbeyanCanberra = [
  [-35.3414, 149.2202],
  [-35.3404, 149.2149],
  [-35.3390, 149.2111],
  [-35.3388, 149.2093],
  [-35.3391, 149.2074],
  [-35.3396, 149.2063],
  [-35.3412, 149.2035],
  [-35.3423, 149.1998],
  [-35.3423, 149.1974],
  [-35.3409, 149.1910],
  [-35.3400, 149.1893],
  [-35.3377, 149.1865],
  [-35.3210, 149.1569],
  [-35.3203, 149.1547],
  [-35.3189, 149.1478]
];

const canberraCanberraCivic = [
  [-35.3203, 149.1549],
  [-35.3186, 149.1504],
  [-35.3181, 149.1501],
  [-35.3176, 149.1501],
  [-35.3088, 149.1515],
  [-35.3069, 149.1520],
  [-35.3050, 149.1533],
  [-35.3045, 149.1534],
  [-35.3019, 149.1526],
  [-35.2961, 149.1490],
  [-35.2918, 149.1462],
  [-35.2849, 149.1348],
  [-35.2823, 149.1332],
  [-35.2803, 149.1333]
];

export const canberra: Line = {
  name: 'Canberra',
  state: 'ACT',
  segments: [
    {
      segments: [queanbeyanCanberra],
      history: {
        opened: {
          date: '1914-05-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [canberraCanberraCivic],
      history: {
        opened: {
          date: '1921-08-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1922-07-27',
          status: 'closed'
        }]
      }
    }
  ]
}
