import { Line } from "../../trackTypes";

const richmondBurnley = [
  [-37.8254, 144.9926],
  [-37.8258, 144.9934],
  [-37.8261, 144.9945],
  [-37.8278, 145.0100],
  [-37.8278, 145.0118],
  [-37.8277, 145.0130]
];

const burnleyHawthorn = [
  [-37.8277, 145.0130],
  [-37.8271, 145.0147],
  [-37.8267, 145.0155],
  [-37.8243, 145.0196],
  [-37.8238, 145.0203],
  [-37.8219, 145.0231]
];

const hawthornCamberwell = [
  [-37.8219, 145.0231],
  [-37.8214, 145.0237],
  [-37.8210, 145.0245],
  [-37.8207, 145.0255],
  [-37.8206, 145.0265],
  [-37.8206, 145.0272],
  [-37.8212, 145.0323],
  [-37.8212, 145.0331],
  [-37.8213, 145.0352],
  [-37.8217, 145.0390],
  [-37.8221, 145.0424],
  [-37.8223, 145.0450],
  [-37.8226, 145.0469],
  [-37.8229, 145.0487],
  [-37.8234, 145.0513],
  [-37.8237, 145.0523],
  [-37.8241, 145.0530],
  [-37.8246, 145.0537],
  [-37.8254, 145.0545],
  [-37.8259, 145.0552],
  [-37.8263, 145.0562],
  [-37.8265, 145.0574],
  [-37.8265, 145.0589]
];

const camberwellEastCamberwell = [
  [-37.8265, 145.0589],
  [-37.8265, 145.0610],
  [-37.8263, 145.0651],
  [-37.8262, 145.0657],
  [-37.8257, 145.0683]
];

const eastCamberwellBoxHill = [
  [-37.8257, 145.0683],
  [-37.8256, 145.0688],
  [-37.8254, 145.0706],
  [-37.8255, 145.0721],
  [-37.8258, 145.0768],
  [-37.8258, 145.0777],
  [-37.8255, 145.0789],
  [-37.8239, 145.0827],
  [-37.8236, 145.0838],
  [-37.8236, 145.0849],
  [-37.8236, 145.0856],
  [-37.8249, 145.0932],
  [-37.8249, 145.0948],
  [-37.8247, 145.0964],
  [-37.8243, 145.0980],
  [-37.8236, 145.0995],
  [-37.8189, 145.1063],
  [-37.8186, 145.1072],
  [-37.8184, 145.1079],
  [-37.8183, 145.1087],
  [-37.8182, 145.1128],
  [-37.8183, 145.1153],
  [-37.8187, 145.1184],
  [-37.8192, 145.1212]
];

const boxHillRingwood = [
  [-37.8192, 145.1212],
  [-37.8196, 145.1243],
  [-37.8215, 145.1373],
  [-37.8214, 145.1390],
  [-37.8211, 145.1398],
  [-37.8201, 145.1420],
  [-37.8198, 145.1429],
  [-37.8197, 145.1440],
  [-37.8202, 145.1485],
  [-37.8202, 145.1526],
  [-37.8217, 145.1647],
  [-37.8218, 145.1669],
  [-37.8216, 145.1681],
  [-37.8185, 145.1884],
  [-37.8184, 145.1903],
  [-37.8177, 145.1953],
  [-37.8177, 145.1963],
  [-37.8189, 145.2114],
  [-37.8189, 145.2122],
  [-37.8183, 145.2219],
  [-37.8180, 145.2236],
  [-37.8176, 145.2247],
  [-37.8156, 145.2294]
];

const ringwoodCroydon = [
  [-37.8156, 145.2294],
  [-37.8140, 145.2334],
  [-37.8137, 145.2348],
  [-37.8137, 145.2357],
  [-37.8141, 145.2398],
  [-37.8141, 145.2413],
  [-37.8138, 145.2432],
  [-37.8130, 145.2460],
  [-37.8126, 145.2475],
  [-37.8114, 145.2512],
  [-37.8072, 145.2654],
  [-37.8068, 145.2666],
  [-37.8058, 145.2682],
  [-37.8039, 145.2709],
  [-37.8034, 145.2714],
  [-37.7993, 145.2744],
  [-37.7984, 145.2753],
  [-37.7976, 145.2765],
  [-37.7954, 145.2804]
];

const croydonMooroolbark = [
  [-37.7954, 145.2804],
  [-37.7920, 145.2862],
  [-37.7915, 145.2869],
  [-37.7911, 145.2873],
  [-37.7899, 145.2883],
  [-37.7889, 145.2891],
  [-37.7882, 145.2896],
  [-37.7877, 145.2903],
  [-37.7873, 145.2912],
  [-37.7833, 145.3030],
  [-37.7831, 145.3040],
  [-37.7830, 145.3050],
  [-37.7832, 145.3059],
  [-37.7834, 145.3067],
  [-37.7846, 145.3103],
  [-37.7848, 145.3110],
  [-37.7848, 145.3116],
  [-37.7848, 145.3120],
  [-37.7847, 145.3126]
];

const mooroolbarkLilydale = [
  [-37.7847, 145.3126],
  [-37.7845, 145.3155],
  [-37.7844, 145.3162],
  [-37.7841, 145.3169],
  [-37.7838, 145.3175],
  [-37.7805, 145.3230],
  [-37.7801, 145.3235],
  [-37.7798, 145.3238],
  [-37.7778, 145.3256],
  [-37.7772, 145.3264],
  [-37.7766, 145.3272],
  [-37.7763, 145.3279],
  [-37.7736, 145.3333],
  [-37.7731, 145.3341],
  [-37.7724, 145.3348],
  [-37.7716, 145.3353],
  [-37.7707, 145.3356],
  [-37.7665, 145.3364],
  [-37.7657, 145.3367],
  [-37.7650, 145.3372],
  [-37.7645, 145.3377],
  [-37.7637, 145.3387],
  [-37.7632, 145.3394],
  [-37.7556, 145.3474]
];

export const lilydale: Line = {
  name: 'Lilydale',
  state: 'VIC',
  segments: [
    {
      segments: [richmondBurnley],
      history: {
        opened: {
          date: '1860-09-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1882-12-01',
          tracks: 2
        }, {
          date: '1966-12-04',
          tracks: 4
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [burnleyHawthorn],
      history: {
        opened: {
          date: '1861-04-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1882-12-09',
          tracks: 2
        }, {
          date: '1972-08-13',
          tracks: 3
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [hawthornCamberwell],
      history: {
        opened: {
          date: '1882-04-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1885-05-03',
          tracks: 2
        }, {
          date: '1963-12-08',
          tracks: 3
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [camberwellEastCamberwell],
      history: {
        opened: {
          date: '1882-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-12-09',
          tracks: 2
        }, {
          date: '1964-11-08',
          tracks: 3
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [eastCamberwellBoxHill],
      history: {
        opened: {
          date: '1882-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-12-09',
          tracks: 2
        }, {
          date: '1971-12-19',
          tracks: 3
        }, {
          date: '1922-10-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [boxHillRingwood],
      history: {
        opened: {
          date: '1882-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1891-12-21',
          tracks: 2
        }, {
          date: '1923-01-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [ringwoodCroydon],
      history: {
        opened: {
          date: '1882-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-06-30',
          tracks: 2
        }, {
          date: '1924-11-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [croydonMooroolbark],
      history: {
        opened: {
          date: '1882-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-09-08',
          tracks: 2
        }, {
          date: '1925-11-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [mooroolbarkLilydale],
      history: {
        opened: {
          date: '1882-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1925-11-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
