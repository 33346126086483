import { Line } from "../../trackTypes";

const line = [
  [-37.2069, 174.7821],
  [-37.2026, 174.7679],
  [-37.1983, 174.7596],
  [-37.1979, 174.7584],
  [-37.1972, 174.7547],
  [-37.1974, 174.7529],
  [-37.1979, 174.7516],
  [-37.1989, 174.7503],
  [-37.2004, 174.7496],
  [-37.2031, 174.7491],
  [-37.2043, 174.7485],
  [-37.2052, 174.7475],
  [-37.2059, 174.7457],
  [-37.2062, 174.7427],
  [-37.2072, 174.7392],
  [-37.2074, 174.7377],
  [-37.2070, 174.7357],
  [-37.2047, 174.7289],
  [-37.2042, 174.7254]
];

export const missionBush: Line = {
  name: 'Mission Bush',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1968-10-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
