import { Line } from "../../../../trackTypes";

const northRichmondJunction = [
  [-37.81059, 145.00084],
  [-37.81062, 145.00071],
  [-37.81072, 145.00062]
];

const richmond = [
  [-37.81072, 145.00062],
  [-37.81870, 144.99914],
  [-37.82584, 144.99790],
  [-37.83241, 144.99673],
  [-37.83487, 144.99655],
  [-37.83949, 144.99562]
];

const richmondJunction = [
  [-37.82565, 144.99770],
  [-37.82572, 144.99785],
  [-37.82584, 144.99790]
];

const  prahran = [
  [-37.83949, 144.99562],
  [-37.84687, 144.99413],
  [-37.85186, 144.99336],
  [-37.85765, 144.99229],
  [-37.86819, 144.99040]
];

const prahranJunction = [
  [-37.86819, 144.99040],
  [-37.86830, 144.99030],
  [-37.86834, 144.99013]
];

const prahranExtension = [
  [-37.86819, 144.99040],
  [-37.87200, 144.98967],
  [-37.87209, 144.98967],
  [-37.87221, 144.98970]
];

const domainRoad = [
  [-37.83283, 144.97231],
  [-37.83411, 144.98234],
  [-37.83419, 144.98245],
  [-37.83432, 144.98249],
  [-37.83757, 144.98182],
  [-37.83769, 144.98187],
  [-37.83777, 144.98200]
];

const toorakRoad = [
  [-37.83607, 144.97546],
  [-37.83642, 144.97589],
  [-37.83691, 144.97672],
  [-37.83715, 144.97744],
  [-37.83777, 144.98200]
];

const anzacSouthYarra = [
  [-37.83777, 144.98200],
  [-37.83949, 144.99562]
];

export const south2: Line = {
  name: 'South2',
  state: 'VIC',
  segments: [
    {
      segments: [northRichmondJunction],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [richmond, richmondJunction],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [prahran],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1926-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [prahranJunction, prahranExtension],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [
        {
          date: '2017-01-01',
          original: [domainRoad],
          deviation: [toorakRoad]
        },
        anzacSouthYarra
      ],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1927-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
