import { Line } from "../../trackTypes";

const eastMalvernMountWaverley = [
  [-37.8768, 145.0692],
  [-37.8772, 145.0701],
  [-37.8774, 145.0708],
  [-37.8775, 145.0716],
  [-37.8774, 145.0725],
  [-37.8772, 145.0747],
  [-37.8772, 145.0763],
  [-37.8768, 145.0806],
  [-37.8767, 145.0812],
  [-37.8742, 145.0915],
  [-37.8730, 145.1082],
  [-37.8730, 145.1094],
  [-37.8732, 145.1110],
  [-37.8743, 145.1142],
  [-37.8746, 145.1152],
  [-37.8749, 145.1166],
  [-37.8752, 145.1214],
  [-37.8752, 145.1277]
];

const mountWaverleySyndal = [
  [-37.8752, 145.1277],
  [-37.8752, 145.1392],
  [-37.8752, 145.1403],
  [-37.8762, 145.1496]
];

const syndalGlenWaverley = [
  [-37.8762, 145.1496],
  [-37.8764, 145.1510],
  [-37.8794, 145.1620]
];

const heyingtonKooyong = [
  [-37.8347, 145.0227],
  [-37.8350, 145.0231],
  [-37.8357, 145.0239],
  [-37.8361, 145.0243],
  [-37.8366, 145.0247],
  [-37.8373, 145.0251],
  [-37.8381, 145.0258],
  [-37.8385, 145.0263],
  [-37.8390, 145.0273],
  [-37.8392, 145.0284],
  [-37.8396, 145.0324],
  [-37.8397, 145.0327],
  [-37.8397, 145.0329]
];

const kooyongGardiner = [
  [-37.8397, 145.0329],
  [-37.8399, 145.0335],
  [-37.8402, 145.0345],
  [-37.8406, 145.0354],
  [-37.8413, 145.0364],
  [-37.8422, 145.0371],
  [-37.8429, 145.0376],
  [-37.8470, 145.0397],
  [-37.8481, 145.0405],
  [-37.8490, 145.0413],
  [-37.8496, 145.0421],
  [-37.8503, 145.0432],
  [-37.8508, 145.0445],
  [-37.8533, 145.0517]
];

const gardinerGleniris = [
  [-37.8533, 145.0517],
  [-37.8536, 145.0527],
  [-37.8540, 145.0536],
  [-37.8547, 145.0546],
  [-37.8554, 145.0552],
  [-37.8566, 145.0561],
  [-37.8592, 145.0581]
];

const glenIrisDarling = [
  [-37.8592, 145.0581],
  [-37.8596, 145.0584],
  [-37.8608, 145.0591],
  [-37.8612, 145.0592],
  [-37.8660, 145.0607],
  [-37.8666, 145.0610],
  [-37.8672, 145.0614],
  [-37.8690, 145.0629]
];

const burnleyHeyington = [
  [-37.8277, 145.0130],
  [-37.8276, 145.0135],
  [-37.8276, 145.0164],
  [-37.8277, 145.0173],
  [-37.8282, 145.0185],
  [-37.8288, 145.0194],
  [-37.8296, 145.0201],
  [-37.8303, 145.0204],
  [-37.8330, 145.0215],
  [-37.8334, 145.0217],
  [-37.8342, 145.0223],
  [-37.8347, 145.0227]
];

const darlingEastMalvern = [
  [-37.8690, 145.0629],
  [-37.8696, 145.0635],
  [-37.8709, 145.0643],
  [-37.8736, 145.0655],
  [-37.8744, 145.0660],
  [-37.8754, 145.0668],
  [-37.8756, 145.0671],
  [-37.8762, 145.0680],
  [-37.8767, 145.0688],
  [-37.8768, 145.0692]
];

export const glenwaverley: Line = {
  name: 'Glen Waverley',
  state: 'VIC',
  segments: [
    {
      segments: [burnleyHeyington],
      history: {
        opened: {
          date: '1890-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-08-08',
          tracks: 2
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [syndalGlenWaverley],
      history: {
        opened: {
          date: '1930-05-05',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-11-29',
          tracks: 2
        }]
      }
    },
    {
      segments: [mountWaverleySyndal],
      history: {
        opened: {
          date: '1930-05-05',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1958-09-07',
          tracks: 2
        }]
      }
    },
    {
      segments: [eastMalvernMountWaverley],
      history: {
        opened: {
          date: '1930-05-05',
          tracks: 1,
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-06-28',
          tracks: 2
        }]
      }
    },
    {
      segments: [glenIrisDarling],
      history: {
        opened: {
          date: '1890-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-03-18',
          tracks: 2
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gardinerGleniris],
      history: {
        opened: {
          date: '1890-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-11-10',
          tracks: 2
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [kooyongGardiner],
      history: {
        opened: {
          date: '1890-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-11-20',
          tracks: 2
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [heyingtonKooyong],
      history: {
        opened: {
          date: '1890-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-12-15',
          tracks: 2
        }, {
          date: '1922-12-19',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [darlingEastMalvern],
      history: {
        opened: {
          date: '1890-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1895-12-09',
          status: 'closed'
        }, {
          date: '1929-02-03',
          status: 'open',
          tracks: 2
        }, {
          date: '1929-02-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
