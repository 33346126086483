import { Line } from "../../trackTypes";

const salisburyLongPlains = [
  [-34.7572, 138.6424],
  [-34.7541, 138.6418],
  [-34.7508, 138.6401],
  [-34.7434, 138.6336],
  [-34.6903, 138.5861],
  [-34.6720, 138.5652],
  [-34.6702, 138.5641],
  [-34.6613, 138.5611],
  [-34.6386, 138.5570],
  [-34.6330, 138.5567],
  [-34.6313, 138.5560],
  [-34.6302, 138.5552],
  [-34.6292, 138.5539],
  [-34.6225, 138.5415],
  [-34.6204, 138.5390],
  [-34.6031, 138.5243],
  [-34.6013, 138.5234],
  [-34.5986, 138.5231],
  [-34.5974, 138.5228],
  [-34.5836, 138.5146],
  [-34.5820, 138.5141],
  [-34.5121, 138.5100],
  [-34.5026, 138.5086],
  [-34.5010, 138.5086],
  [-34.4994, 138.5090],
  [-34.4974, 138.5095],
  [-34.4962, 138.5096],
  [-34.4452, 138.5096],
  [-34.4402, 138.5083],
  [-34.4394, 138.5080],
  [-34.4377, 138.5069],
  [-34.4364, 138.5057],
  [-34.4355, 138.5044],
  [-34.4035, 138.4378],
  [-34.4020, 138.4355],
  [-34.3925, 138.4165],
  [-34.3914, 138.4148],
  [-34.3640, 138.3795],
  [-34.3621, 138.3781],
  [-34.3593, 138.3772],
  [-34.3574, 138.3771],
  [-34.3561, 138.3774]
];

const longPlainsRedhill = [
  [-34.3561, 138.3774],
  [-34.3459, 138.3798],
  [-34.3384, 138.3805],
  [-34.3363, 138.3800],
  [-34.3348, 138.3792],
  [-34.2998, 138.3527],
  [-34.2887, 138.3421],
  [-34.2868, 138.3408],
  [-34.2780, 138.3368],
  [-34.2768, 138.3360],
  [-34.2719, 138.3321],
  [-34.2705, 138.3306],
  [-34.2548, 138.3083],
  [-34.2451, 138.2976],
  [-34.2434, 138.2962],
  [-34.2416, 138.2954],
  [-34.2086, 138.2878],
  [-34.1751, 138.2754],
  [-34.1721, 138.2736],
  [-34.1707, 138.2722],
  [-34.1616, 138.2588],
  [-34.1603, 138.2572],
  [-34.1579, 138.2551],
  [-34.1482, 138.2464],
  [-34.1460, 138.2455],
  [-34.1450, 138.2452],
  [-34.1363, 138.2452],
  [-34.1348, 138.2449],
  [-34.1188, 138.2396],
  [-34.0974, 138.2371],
  [-34.0816, 138.2370],
  [-34.0792, 138.2365],
  [-34.0604, 138.2297],
  [-34.0587, 138.2293],
  [-34.0287, 138.2281],
  [-34.0134, 138.2253],
  [-34.0107, 138.2253],
  [-34.0027, 138.2274],
  [-34.0008, 138.2274],
  [-33.9871, 138.2246],
  [-33.9847, 138.2246],
  [-33.9669, 138.2285],
  [-33.9649, 138.2286],
  [-33.9457, 138.2262],
  [-33.9441, 138.2263],
  [-33.9230, 138.2305],
  [-33.9210, 138.2306],
  [-33.9125, 138.2292],
  [-33.9112, 138.2292],
  [-33.9062, 138.2299],
  [-33.8306, 138.2309],
  [-33.8291, 138.2307],
  [-33.8277, 138.2301],
  [-33.7923, 138.2105],
  [-33.7896, 138.2099],
  [-33.7874, 138.2103],
  [-33.7782, 138.2155],
  [-33.7757, 138.2161],
  [-33.7638, 138.2160],
  [-33.7515, 138.2134],
  [-33.7501, 138.2133],
  [-33.6974, 138.2159],
  [-33.6959, 138.2161],
  [-33.6558, 138.2276],
  [-33.6544, 138.2278],
  [-33.6282, 138.2277],
  [-33.6095, 138.2250],
  [-33.5638, 138.2250],
  [-33.5621, 138.2247],
  [-33.5518, 138.2212],
  [-33.5498, 138.2210],
  [-33.5453, 138.2215]
];

const redHillMerritonJunction = [
  [-33.5453, 138.2215],
  [-33.5440, 138.2217],
  [-33.5418, 138.2213],
  [-33.5400, 138.2203],
  [-33.5386, 138.2189],
  [-33.5335, 138.2115],
  [-33.5247, 138.2007],
  [-33.5228, 138.1991],
  [-33.5203, 138.1980],
  [-33.5104, 138.1954],
  [-33.5069, 138.1950],
  [-33.4717, 138.1834],
  [-33.4505, 138.1763],
  [-33.4479, 138.1750],
  [-33.4332, 138.1647],
  [-33.4307, 138.1628],
  [-33.4284, 138.1608]
];

const merritonJunctionCrystalBrook = [
  [-33.4284, 138.1608],
  [-33.4251, 138.1591],
  [-33.4223, 138.1588],
  [-33.4199, 138.1593],
  [-33.4028, 138.1669],
  [-33.3910, 138.1711],
  [-33.3892, 138.1722],
  [-33.3640, 138.1983],
  [-33.3625, 138.2007],
  [-33.3617, 138.2034],
  [-33.3611, 138.2060],
  [-33.3603, 138.2076],
  [-33.3589, 138.2087],
  [-33.3577, 138.2089],
  [-33.3567, 138.2087],
  [-33.3553, 138.2079]
];

const crystalbrookLeftJunction = [
  [-33.3603, 138.2076],
  [-33.3594, 138.2089],
  [-33.3588, 138.2102],
  [-33.3586, 138.2116],
  [-33.3588, 138.2134]
];

const crystalBrookPortPirieJunction = [
  [-33.3553, 138.2079],
  [-33.3504, 138.2022],
  [-33.3470, 138.1992],
  [-33.3430, 138.1966],
  [-33.3398, 138.1938],
  [-33.3193, 138.1650],
  [-33.3158, 138.1617],
  [-33.2441, 138.1249],
  [-33.2413, 138.1230],
  [-33.2029, 138.0875],
  [-33.2010, 138.0849],
  [-33.1996, 138.0824],
  [-33.1985, 138.0790],
  [-33.1982, 138.0771],
  [-33.1936, 138.0401],
  [-33.1928, 138.0339]
];

const portPirieJunctionellenStreet = [
  [-33.1928, 138.0339],
  [-33.1908, 138.0198],
  [-33.1906, 138.0191],
  [-33.1901, 138.0173],
  [-33.1896, 138.0141],
  [-33.1891, 138.0133],
  [-33.1884, 138.0127],
  [-33.1875, 138.0123],
  [-33.1810, 138.0118]
];

const ellenStreetDeviation = [
  [-33.1810, 138.0118],
  [-33.1795, 138.0119],
  [-33.1789, 138.0118],
  [-33.1776, 138.0112],
  [-33.1762, 138.0108],
  [-33.1750, 138.0106],
  [-33.1731, 138.0109],
  [-33.1706, 138.0128]
];

const portPirieOld = [
  [-33.1928, 138.0339],
  [-33.1934, 138.0353],
  [-33.1953, 138.0381],
  [-33.1970, 138.0396],
  [-33.1992, 138.0405],
  [-33.2378, 138.0505],
  [-33.2793, 138.0745],
  [-33.2995, 138.0821],
  [-33.3204, 138.0936],
  [-33.3829, 138.1360],
  [-33.4010, 138.1451],
  [-33.4026, 138.1453],
  [-33.4084, 138.1454],
  [-33.4120, 138.1467],
  [-33.4284, 138.1608]
];

const ellenStreet = [
  [-33.1810, 138.0118],
  [-33.1801, 138.0117],
  [-33.1794, 138.0114],
  [-33.1783, 138.0107],
  [-33.1770, 138.0102],
  [-33.1758, 138.0100],
  [-33.1745, 138.0100],
  [-33.1732, 138.0104],
  [-33.1721, 138.0107],
  [-33.1705, 138.0111]
];

export const portPirie: Line = {
  name: 'Port Pirie',
  state: 'SA',
  segments: [
    {
      segments: [salisburyLongPlains],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [longPlainsRedhill],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [crystalbrookLeftJunction],
      history: {
        opened: {
          date: '1982-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [crystalBrookPortPirieJunction],
      history: {
        opened: {
          date: '1875-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [portPirieJunctionellenStreet],
      history: {
        opened: {
          date: '1875-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-01-01',
          gauge: 'dual'
        }, {
          date: '1982-04-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [portPirieOld],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [redHillMerritonJunction],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-04-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [merritonJunctionCrystalBrook],
      history: {
        opened: {
          date: '1982-04-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        date: '1967-11-01',
        original: [ellenStreet],
        deviation: [ellenStreetDeviation]
      }],
      history: {
        opened: {
          date: '1875-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-01-01',
          gauge: 'dual'
        }, {
          date: '1967-11-01',
          gauge: 'standard'
        }]
      }
    }
  ]
};
