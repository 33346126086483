import { Line } from "../../trackTypes";

const rollestonDartfield = [
  [-43.5906, 172.3784],
  [-43.5910, 172.3767],
  [-43.5909, 172.3751],
  [-43.5904, 172.3733],
  [-43.5003, 172.2149],
  [-43.4994, 172.2116],
  [-43.4889, 172.1104]
];

const dartfieldSpringfield = [
  [-43.4889, 172.1104],
  [-43.4879, 172.1070],
  [-43.4865, 172.1049],
  [-43.4543, 172.0757],
  [-43.4408, 172.0662],
  [-43.3996, 172.0330],
  [-43.3924, 172.0259],
  [-43.3912, 172.0240],
  [-43.3909, 172.0235],
  [-43.3430, 171.9401],
  [-43.3419, 171.9386],
  [-43.3331, 171.9297]
];

const springfieldTunnelNo1 = [
  [-43.3331, 171.9297],
  [-43.3301, 171.9266],
  [-43.3284, 171.9240],
  [-43.3276, 171.9218],
  [-43.3273, 171.9198],
  [-43.3261, 171.9169],
  [-43.3248, 171.9154],
  [-43.3229, 171.9145],
  [-43.3175, 171.9140],
  [-43.3159, 171.9143],
  [-43.3039, 171.9203],
  [-43.2939, 171.9279],
  [-43.2930, 171.9284],
  [-43.2868, 171.9305],
  [-43.2856, 171.9306],
  [-43.2837, 171.9304],
  [-43.2822, 171.9308],
  [-43.2715, 171.9373],
  [-43.2704, 171.9377],
  [-43.2690, 171.9391],
  [-43.2684, 171.9400],
  [-43.2678, 171.9403]
];

const tunnelNo1 = [
  [-43.2678, 171.9403],
  [-43.2670, 171.9402]
];

const tunnelNo1TunnelNo2 = [
  [-43.2670, 171.9402],
  [-43.2666, 171.9399],
  [-43.2656, 171.9388],
  [-43.2643, 171.9382],
  [-43.2631, 171.9384],
  [-43.2603, 171.9404],
  [-43.2564, 171.9425],
  [-43.2558, 171.9431],
  [-43.2548, 171.9448],
  [-43.2541, 171.9454],
  [-43.2539, 171.9455]
];

const tunnelNo2 = [
  [-43.2539, 171.9455],
  [-43.2531, 171.9456],
  [-43.2517, 171.9455]
];

const tunnelNo2TunnelNo3 = [
  [-43.2517, 171.9455],
  [-43.2510, 171.9454]
];

const tunnelNo3 = [
  [-43.2510, 171.9454],
  [-43.2506, 171.9454],
  [-43.2498, 171.9458],
  [-43.2477, 171.9481],
  [-43.2471, 171.9494]
];

const tunnelNo3TunnelNo4 = [
  [-43.2471, 171.9494],
  [-43.2470, 171.9499]
];

const tunnelNo4 = [
  [-43.2470, 171.9499],
  [-43.2464, 171.9513],
  [-43.2462, 171.9521],
  [-43.2460, 171.9531]
];

const tunnelNo4TunnelNo5 = [
  [-43.2460, 171.9531],
  [-43.2459, 171.9535],
  [-43.2455, 171.9543],
  [-43.2447, 171.9550],
  [-43.2433, 171.9555],
  [-43.2425, 171.9555],
  [-43.2402, 171.9548],
  [-43.2393, 171.9546],
  [-43.2355, 171.9545],
  [-43.2347, 171.9544],
  [-43.2331, 171.9536]
];

const tunnelNo5 = [
  [-43.2331, 171.9536],
  [-43.2298, 171.9520]
];

const tunnelNo5TunnelNo6 = [
  [-43.2298, 171.9520],
  [-43.2297, 171.9519],
  [-43.2291, 171.9515],
  [-43.2286, 171.9503],
  [-43.2287, 171.9488],
  [-43.2292, 171.9473],
  [-43.2293, 171.9462],
  [-43.2291, 171.9452],
  [-43.2290, 171.9448]
];

const tunnelNo6 = [
  [-43.2290, 171.9448],
  [-43.2281, 171.9419]
];

const tunnelNo6TunnelNo7 = [
  [-43.2281, 171.9419],
  [-43.2280, 171.9417],
  [-43.2270, 171.9406],
  [-43.2259, 171.9402],
  [-43.2253, 171.9399],
  [-43.2248, 171.9391],
  [-43.2228, 171.9338],
  [-43.2227, 171.9324]
];

const tunnelNo7 = [
  [-43.2227, 171.9324],
  [-43.2225, 171.9311]
];

const tunnelNo7TunnelNo8 = [
  [-43.2225, 171.9311],
  [-43.2222, 171.9295],
  [-43.2218, 171.9286],
  [-43.2208, 171.9270]
];

const tunnelNo8 = [
  [-43.2208, 171.9270],
  [-43.2203, 171.9265],
  [-43.2197, 171.9263],
  [-43.2175, 171.9266]
];

const tunnelNo8TunnelNo9 = [
  [-43.2175, 171.9266],
  [-43.2164, 171.9267],
  [-43.2155, 171.9271],
  [-43.2146, 171.9280],
  [-43.2140, 171.9285],
  [-43.2127, 171.9291],
  [-43.2114, 171.9289],
  [-43.2112, 171.9288]
];

const tunnelNo9 = [
  [-43.2112, 171.9288],
  [-43.2107, 171.9283],
  [-43.2075, 171.9246]
];

const tunnelNo9TunnelNo10 = [
  [-43.2075, 171.9246],
  [-43.2062, 171.9231]
];

const tunnelNo10 = [
  [-43.2062, 171.9231],
  [-43.2020, 171.9181]
];

const tunnelNo10BrokenRiver = [
  [-43.2020, 171.9181],
  [-43.2017, 171.9177],
  [-43.2008, 171.9163],
  [-43.2004, 171.9154],
  [-43.2002, 171.9139],
  [-43.2001, 171.9121],
  [-43.1999, 171.9113],
  [-43.1994, 171.9106],
  [-43.1989, 171.9102],
  [-43.1982, 171.9101],
  [-43.1978, 171.9102]
];

const brokenRiverTunnelNo12 = [
  [-43.1978, 171.9102],
  [-43.1968, 171.9106],
  [-43.1961, 171.9107],
  [-43.1955, 171.9105],
  [-43.1948, 171.9098],
  [-43.1945, 171.9087],
  [-43.1946, 171.9049],
  [-43.1945, 171.9043]
];

const tunnelNo12 = [
  [-43.1945, 171.9043],
  [-43.1943, 171.9036],
  [-43.1943, 171.9036]
];

const tunnelNo12TunnelNo13 = [
  [-43.1943, 171.9036],
  [-43.1941, 171.9030],
  [-43.1935, 171.9021]
];

const tunnelNo13 = [
  [-43.1935, 171.9021],
  [-43.1930, 171.9007],
  [-43.1926, 171.8990]
];

const tunnelNo13TunnelNo14 = [
  [-43.1926, 171.8990],
  [-43.1924, 171.8980]
];

const tunnelNo14 = [
  [-43.1924, 171.8980],
  [-43.19235, 171.8975]
];

const tunnelNo14TunnelNo15 = [
  [-43.19235, 171.8975],
  [-43.1923, 171.8971]
];

const tunnelNo15 = [
  [-43.1923, 171.8971],
  [-43.1919, 171.8949]
];

const tunnelNo15TunnelNo16 = [
  [-43.1919, 171.8949],
  [-43.1917, 171.8941],
  [-43.1916, 171.8930],
  [-43.1916, 171.8923]
];

const tunnelNo16 = [
  [-43.1916, 171.8923],
  [-43.1915, 171.8916]
];

const tunnelNo16Cass = [
  [-43.1915, 171.8916],
  [-43.1911, 171.8896],
  [-43.1907, 171.8887],
  [-43.1901, 171.8882],
  [-43.1885, 171.8875],
  [-43.1880, 171.8870],
  [-43.1875, 171.8861],
  [-43.1872, 171.8848],
  [-43.1862, 171.8831],
  [-43.1856, 171.8826],
  [-43.1784, 171.8788],
  [-43.1771, 171.8785],
  [-43.1750, 171.8787],
  [-43.1733, 171.8783],
  [-43.1696, 171.8765],
  [-43.1683, 171.8763],
  [-43.1672, 171.8764],
  [-43.1662, 171.8762],
  [-43.1534, 171.8710],
  [-43.1522, 171.8710],
  [-43.1484, 171.8721],
  [-43.1472, 171.8720],
  [-43.1449, 171.8711],
  [-43.1440, 171.8712],
  [-43.1429, 171.8722],
  [-43.1421, 171.8739],
  [-43.1409, 171.8747],
  [-43.1378, 171.8750],
  [-43.1370, 171.8746],
  [-43.1361, 171.8737],
  [-43.1348, 171.8712],
  [-43.1341, 171.8704],
  [-43.1329, 171.8699],
  [-43.1303, 171.8701],
  [-43.1293, 171.8698],
  [-43.1286, 171.8691],
  [-43.1277, 171.8677],
  [-43.1268, 171.8669],
  [-43.1242, 171.8659],
  [-43.1232, 171.8659],
  [-43.1140, 171.8684],
  [-43.1127, 171.8684],
  [-43.1095, 171.8680],
  [-43.1080, 171.8672],
  [-43.1038, 171.8629],
  [-43.0959, 171.8527],
  [-43.0948, 171.8502],
  [-43.0843, 171.8174],
  [-43.0825, 171.8141],
  [-43.0668, 171.7980],
  [-43.0656, 171.7971],
  [-43.0647, 171.7957],
  [-43.0628, 171.7902],
  [-43.0618, 171.7888],
  [-43.0593, 171.7870],
  [-43.0567, 171.7856],
  [-43.0559, 171.7847],
  [-43.0553, 171.7832],
  [-43.0552, 171.7819],
  [-43.0549, 171.7808],
  [-43.0531, 171.7767],
  [-43.0507, 171.7725],
  [-43.0497, 171.7696],
  [-43.0478, 171.7672],
  [-43.0470, 171.7665],
  [-43.0433, 171.7646],
  [-43.0422, 171.7633],
  [-43.0397, 171.7588],
  [-43.0388, 171.7580],
  [-43.0366, 171.7571]
];

const cassArthursPass = [
  [-43.0366, 171.7571],
  [-43.0329, 171.7559],
  [-43.0235, 171.7547],
  [-43.0207, 171.7550],
  [-43.0178, 171.7544],
  [-43.0164, 171.7537],
  [-43.0154, 171.7537],
  [-43.0146, 171.7543],
  [-43.0137, 171.7555],
  [-43.0123, 171.7562],
  [-43.0111, 171.7561],
  [-43.0096, 171.7550],
  [-43.0081, 171.7531],
  [-43.0072, 171.7509],
  [-43.0063, 171.7468],
  [-43.0063, 171.7456],
  [-43.0065, 171.7440],
  [-43.0066, 171.7433],
  [-43.0064, 171.7422],
  [-43.0065, 171.7411],
  [-43.0080, 171.7355],
  [-43.0081, 171.7345],
  [-43.0080, 171.7334],
  [-43.0075, 171.7315],
  [-43.0074, 171.7306],
  [-43.0076, 171.7297],
  [-43.0090, 171.7255],
  [-43.0121, 171.7203],
  [-43.0126, 171.7176],
  [-43.0119, 171.7153],
  [-43.0107, 171.7134],
  [-43.0104, 171.7123],
  [-43.0103, 171.7109],
  [-43.0106, 171.7041],
  [-43.0098, 171.6923],
  [-43.0099, 171.6902],
  [-43.0106, 171.6880],
  [-43.0128, 171.6833],
  [-43.0134, 171.6810],
  [-43.0134, 171.6788],
  [-43.0126, 171.6736],
  [-43.0128, 171.6714],
  [-43.0139, 171.6676],
  [-43.0145, 171.6622],
  [-43.0144, 171.6609],
  [-43.0128, 171.6518],
  [-43.0127, 171.6481],
  [-43.0124, 171.6465],
  [-43.0113, 171.6436],
  [-43.0110, 171.6412],
  [-43.0106, 171.6342],
  [-43.0042, 171.6000],
  [-43.0031, 171.5980],
  [-43.0015, 171.5969],
  [-42.9909, 171.5945],
  [-42.9902, 171.5941],
  [-42.9897, 171.5934],
  [-42.9887, 171.5911],
  [-42.9882, 171.5904],
  [-42.9873, 171.5899],
  [-42.9864, 171.5900],
  [-42.9807, 171.5931],
  [-42.9799, 171.5932],
  [-42.9791, 171.5929],
  [-42.9783, 171.5921],
  [-42.9768, 171.5890],
  [-42.9765, 171.5883],
  [-42.9746, 171.5854],
  [-42.9714, 171.5804],
  [-42.9704, 171.5794],
  [-42.9694, 171.5790],
  [-42.9680, 171.5789],
  [-42.9667, 171.5785],
  [-42.9605, 171.5748],
  [-42.9540, 171.5698],
  [-42.9521, 171.5690],
  [-42.9484, 171.5682],
  [-42.9467, 171.5671],
  [-42.9452, 171.5654],
  [-42.9440, 171.5644]
];

const arthursPassOtiraTunnel = [
  [-42.9440, 171.5644],
  [-42.9430, 171.5639],
  [-42.9413, 171.5637],
  [-42.9398, 171.5642],
  [-42.9391, 171.5641]
];

const otiraTunnel = [
  [-42.9391, 171.5641],
  [-42.8626, 171.5496]
];

const otiraTunnelOtira = [
  [-42.8626, 171.5496],
  [-42.8615, 171.5496],
  [-42.8604, 171.5501],
  [-42.8550, 171.5554],
  [-42.8523, 171.5572],
  [-42.8436, 171.5607],
  [-42.8357, 171.5632],
  [-42.8342, 171.5633],
  [-42.8287, 171.5624]
];

const otiraJackson = [
  [-42.8287, 171.5624],
  [-42.8275, 171.5626],
  [-42.8251, 171.5629],
  [-42.8112, 171.5664],
  [-42.8083, 171.5684],
  [-42.8034, 171.5749],
  [-42.8024, 171.5759],
  [-42.7984, 171.5787],
  [-42.7976, 171.5800],
  [-42.7971, 171.5812],
  [-42.7962, 171.5827],
  [-42.7959, 171.5842],
  [-42.7956, 171.5881],
  [-42.7952, 171.5895],
  [-42.7924, 171.5935],
  [-42.7901, 171.5985],
  [-42.7879, 171.6022],
  [-42.7850, 171.6057],
  [-42.7740, 171.6232],
  [-42.7730, 171.6242],
  [-42.7718, 171.6245],
  [-42.7681, 171.6237],
  [-42.7669, 171.6228],
  [-42.7645, 171.6194],
  [-42.7641, 171.6182],
  [-42.7640, 171.6166],
  [-42.7642, 171.6134],
  [-42.7640, 171.6111],
  [-42.7638, 171.6100],
  [-42.7635, 171.6090],
  [-42.7633, 171.6074],
  [-42.7633, 171.6055],
  [-42.7635, 171.6043],
  [-42.7635, 171.6027],
  [-42.7633, 171.5980],
  [-42.7633, 171.5963],
  [-42.7635, 171.5942],
  [-42.7631, 171.5913],
  [-42.7626, 171.5893],
  [-42.7622, 171.5837],
  [-42.7623, 171.5826],
  [-42.7626, 171.5814],
  [-42.7626, 171.5799],
  [-42.7621, 171.5784],
  [-42.7604, 171.5758],
  [-42.7597, 171.5742],
  [-42.7590, 171.5715],
  [-42.7574, 171.5667],
  [-42.7547, 171.5521],
  [-42.7542, 171.5506],
  [-42.7473, 171.5395],
  [-42.7461, 171.5367],
  [-42.7428, 171.5258]
];

const jacksonMoana = [
  [-42.7428, 171.5258],
  [-42.7427, 171.5237],
  [-42.7428, 171.5152],
  [-42.7423, 171.5136],
  [-42.7413, 171.5127],
  [-42.7373, 171.5110],
  [-42.7365, 171.5104],
  [-42.7360, 171.5090],
  [-42.7327, 171.4860],
  [-42.7296, 171.4761],
  [-42.7289, 171.4750],
  [-42.7270, 171.4742],
  [-42.7253, 171.4747],
  [-42.7241, 171.4760],
  [-42.7191, 171.4840],
  [-42.7182, 171.4860],
  [-42.7173, 171.4893],
  [-42.7142, 171.4962],
  [-42.7132, 171.4976],
  [-42.6970, 171.5150],
  [-42.6943, 171.5192],
  [-42.6869, 171.5284],
  [-42.6854, 171.5297],
  [-42.6738, 171.5359],
  [-42.6726, 171.5362],
  [-42.6639, 171.5369],
  [-42.6627, 171.5368],
  [-42.6586, 171.5350],
  [-42.6573, 171.5348],
  [-42.6561, 171.5353],
  [-42.6548, 171.5351],
  [-42.6540, 171.5351],
  [-42.6522, 171.5356],
  [-42.6508, 171.5354],
  [-42.6462, 171.5328],
  [-42.6452, 171.5317],
  [-42.6433, 171.5285],
  [-42.6425, 171.5260],
  [-42.6416, 171.5240],
  [-42.6409, 171.5229],
  [-42.6400, 171.5225],
  [-42.6367, 171.5221],
  [-42.6356, 171.5217],
  [-42.6329, 171.5197],
  [-42.6257, 171.5160],
  [-42.6220, 171.5134],
  [-42.6186, 171.5127],
  [-42.6174, 171.5122],
  [-42.6130, 171.5118],
  [-42.6118, 171.5115],
  [-42.6033, 171.5064],
  [-42.6024, 171.5062],
  [-42.6008, 171.5063],
  [-42.5998, 171.5059],
  [-42.5974, 171.5045],
  [-42.5939, 171.5038],
  [-42.5929, 171.5039],
  [-42.5864, 171.5055],
  [-42.5854, 171.5054],
  [-42.5807, 171.5041],
  [-42.5798, 171.5032],
  [-42.5790, 171.5010],
  [-42.5788, 171.4993],
  [-42.5800, 171.4909],
  [-42.5794, 171.4889],
  [-42.5778, 171.4870],
  [-42.5772, 171.4852],
  [-42.5769, 171.4788]
];

const moanaTunnelNo18 = [
  [-42.5769, 171.4788],
  [-42.5768, 171.4770],
  [-42.5761, 171.4727],
  [-42.5753, 171.4710],
  [-42.5740, 171.4701],
  [-42.5729, 171.4701],
  [-42.5672, 171.4729],
  [-42.5664, 171.4732],
  [-42.5628, 171.4732],
  [-42.5617, 171.4730],
  [-42.5590, 171.4711],
  [-42.5575, 171.4678],
  [-42.5570, 171.4648],
  [-42.5560, 171.4609],
  [-42.5496, 171.4361],
  [-42.5468, 171.4299],
  [-42.5461, 171.4281],
  [-42.5447, 171.4226],
  [-42.5446, 171.4211],
  [-42.5450, 171.4173],
  [-42.5441, 171.4148],
  [-42.5428, 171.4141],
  [-42.5417, 171.4146],
  [-42.5396, 171.4166],
  [-42.5383, 171.4175],
  [-42.5376, 171.4180],
  [-42.5367, 171.4196],
  [-42.5357, 171.4215],
  [-42.5344, 171.4224],
  [-42.5333, 171.4223],
  [-42.5324, 171.4214],
  [-42.5300, 171.4162],
  [-42.5297, 171.4149],
  [-42.5298, 171.4138],
  [-42.5302, 171.4128],
  [-42.5312, 171.4113],
  [-42.5317, 171.4099],
  [-42.5317, 171.4089],
  [-42.5314, 171.4080],
  [-42.5311, 171.4075]
];

const tunnelNo18 = [
  [-42.5311, 171.4075],
  [-42.5305, 171.4072],
  [-42.5299, 171.4071]
];

const tunnelNo18Stillwater = [
  [-42.5299, 171.4071],
  [-42.5231, 171.4060],
  [-42.5220, 171.4052],
  [-42.5212, 171.4034],
  [-42.5206, 171.3993],
  [-42.5200, 171.3977],
  [-42.5190, 171.3968],
  [-42.5168, 171.3959],
  [-42.5161, 171.3959],
  [-42.5141, 171.3961],
  [-42.5131, 171.3959],
  [-42.5120, 171.3948],
  [-42.5114, 171.3934],
  [-42.5111, 171.3921],
  [-42.5100, 171.3903],
  [-42.5045, 171.3868],
  [-42.5036, 171.3865],
  [-42.5006, 171.3861],
  [-42.4997, 171.3857],
  [-42.4713, 171.3670],
  [-42.4697, 171.3664],
  [-42.4588, 171.3649],
  [-42.4572, 171.3648],
  [-42.4538, 171.3652],
  [-42.4526, 171.3650],
  [-42.4481, 171.3618],
  [-42.4475, 171.3610],
  [-42.4469, 171.3598],
  [-42.4459, 171.3588],
  [-42.4420, 171.3567],
  [-42.4404, 171.3557],
  [-42.4395, 171.3554],
  [-42.4386, 171.3555],
  [-42.4374, 171.3553],
  [-42.4369, 171.3549],
  [-42.4365, 171.3543],
  [-42.4363, 171.3533]
];

const stillwaterbrunner = [
  [-42.4363, 171.3533],
  [-42.4387, 171.3376],
  [-42.4386, 171.3360],
  [-42.4386, 171.3349],
  [-42.4384, 171.3340],
  [-42.4370, 171.3324],
  [-42.4365, 171.3321],
  [-42.4352, 171.3317],
  [-42.4339, 171.3306],
  [-42.4332, 171.3288],
  [-42.4329, 171.3271],
  [-42.4331, 171.3262],
  [-42.4337, 171.3245],

];

const brunnerGreymouth = [
  [-42.4337, 171.3245],
  [-42.4343, 171.3232],
  [-42.4358, 171.3210],
  [-42.4372, 171.3199],
  [-42.4386, 171.3195],
  [-42.4407, 171.3194],
  [-42.4423, 171.3189],
  [-42.4428, 171.3186],
  [-42.4431, 171.3181],
  [-42.4434, 171.3178],
  [-42.4442, 171.3175],
  [-42.4446, 171.3171],
  [-42.4450, 171.3162],
  [-42.4458, 171.3151],
  [-42.4481, 171.3131],
  [-42.4490, 171.3119],
  [-42.4537, 171.3019],
  [-42.4543, 171.3003],
  [-42.4561, 171.2920],
  [-42.4563, 171.2892],
  [-42.4548, 171.2674],
  [-42.4551, 171.2654],
  [-42.4572, 171.2603],
  [-42.4581, 171.2587],
  [-42.4615, 171.2540],
  [-42.4632, 171.2511],
  [-42.4642, 171.2488],
  [-42.4648, 171.2468],
  [-42.4648, 171.2448],
  [-42.4641, 171.2429],
  [-42.4627, 171.2411],
  [-42.4611, 171.2398],
  [-42.4593, 171.2391],
  [-42.4589, 171.2387],
  [-42.4583, 171.2377],
  [-42.4581, 171.2372],
  [-42.4580, 171.2364],
  [-42.4570, 171.2336],
  [-42.4568, 171.2326],
  [-42.4564, 171.2321],
  [-42.4561, 171.2318],
  [-42.4558, 171.2315],
  [-42.4548, 171.2300],
  [-42.4542, 171.2295],
  [-42.4531, 171.2290],
  [-42.4524, 171.2282],
  [-42.4521, 171.2275],
  [-42.4517, 171.2270],
  [-42.4504, 171.2257],
  [-42.4499, 171.2251],
  [-42.4489, 171.2228],
  [-42.4482, 171.2208],
  [-42.4480, 171.2200],
  [-42.4478, 171.2186],
  [-42.4478, 171.2163],
  [-42.4479, 171.2156],
  [-42.4483, 171.2145]
];

export const midland: Line = {
  name: 'Midland (NZ)',
  state: 'NZ',
  segments: [
    {
      segments: [rollestonDartfield],
      history: {
        opened: {
          date: '1875-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [dartfieldSpringfield],
      history: {
        opened: {
          date: '1880-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        springfieldTunnelNo1,
        {
          name: 'Tunnel No 1',
          segment: tunnelNo1,
          type: 'tunnel'
        },
        tunnelNo1TunnelNo2,
        {
          name: 'Tunnel No 2',
          segment: tunnelNo2,
          type: 'tunnel'
        },
        tunnelNo2TunnelNo3,
        {
          name: 'Tunnel No 3',
          segment: tunnelNo3,
          type: 'tunnel'
        },
        tunnelNo3TunnelNo4,
        {
          name: 'Tunnel No 4',
          segment: tunnelNo4,
          type: 'tunnel'
        },
        tunnelNo4TunnelNo5,
        {
          name: 'Tunnel No 5',
          segment: tunnelNo5,
          type: 'tunnel'
        },
        tunnelNo5TunnelNo6,
        {
          name: 'Tunnel No 6',
          segment: tunnelNo6,
          type: 'tunnel'
        },
        tunnelNo6TunnelNo7,
        {
          name: 'Tunnel No 7',
          segment: tunnelNo7,
          type: 'tunnel'
        },
        tunnelNo7TunnelNo8,
        {
          name: 'Tunnel No 8',
          segment: tunnelNo8,
          type: 'tunnel'
        },
        tunnelNo8TunnelNo9,
        {
          name: 'Tunnel No 9',
          segment: tunnelNo9,
          type: 'tunnel'
        },
        tunnelNo9TunnelNo10,
        {
          name: 'Tunnel No 10',
          segment: tunnelNo10,
          type: 'tunnel'
        },
        tunnelNo10BrokenRiver
      ],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        brokenRiverTunnelNo12,
        {
          name: 'Tunnel No 12',
          segment: tunnelNo12,
          type: 'tunnel'
        },
        tunnelNo12TunnelNo13,
        {
          name: 'Tunnel No 13',
          segment: tunnelNo13,
          type: 'tunnel'
        },
        tunnelNo13TunnelNo14,
        {
          name: 'Tunnel No 14',
          segment: tunnelNo14,
          type: 'tunnel'
        },
        tunnelNo14TunnelNo15,
        {
          name: 'Tunnel No 15',
          segment: tunnelNo15,
          type: 'tunnel'
        },
        tunnelNo15TunnelNo16,
        {
          name: 'Tunnel No 16',
          segment: tunnelNo16,
          type: 'tunnel'
        },
        tunnelNo16Cass
      ],
      history: {
        opened: {
          date: '1910-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cassArthursPass],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        arthursPassOtiraTunnel,
        {
          name: 'Otira Tunnel',
          segment: otiraTunnel,
          type: 'tunnel'
        },
        otiraTunnelOtira
      ],
      history: {
        opened: {
          date: '1923-08-04',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1997-01-01',
          electrified: undefined
        }]
      }
    },
    {
      segments: [otiraJackson],
      history: {
        opened: {
          date: '1899-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [jacksonMoana],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        moanaTunnelNo18,
        {
          name: 'Tunnel No 18',
          segment: tunnelNo18,
          type: 'tunnel'
        },
        tunnelNo18Stillwater
      ],
      history: {
        opened: {
          date: '1892-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [stillwaterbrunner],
      history: {
        opened: {
          date: '1887-11-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [brunnerGreymouth],
      history: {
        opened: {
          date: '1876-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
