import { Line } from "../../trackTypes";

const bridgewaterBoyer = [
  [-42.7372, 147.2259],
  [-42.7376, 147.2255],
  [-42.7378, 147.2250],
  [-42.7377, 147.2244],
  [-42.7375, 147.2240],
  [-42.7369, 147.2232],
  [-42.7365, 147.2223],
  [-42.7363, 147.2216],
  [-42.7364, 147.2181],
  [-42.7364, 147.2176],
  [-42.7368, 147.2163],
  [-42.7369, 147.2155],
  [-42.7367, 147.2146],
  [-42.7357, 147.2132],
  [-42.7320, 147.2100],
  [-42.7317, 147.2098],
  [-42.7313, 147.2097],
  [-42.7309, 147.2094],
  [-42.7307, 147.2091],
  [-42.7298, 147.2073],
  [-42.7291, 147.2065],
  [-42.7264, 147.2041],
  [-42.7261, 147.2037],
  [-42.7255, 147.2022],
  [-42.7252, 147.1998],
  [-42.7252, 147.1989],
  [-42.7259, 147.1957],
  [-42.7266, 147.1942],
  [-42.7267, 147.1938],
  [-42.7268, 147.1932],
  [-42.7267, 147.1904],
  [-42.7268, 147.1895],
  [-42.7271, 147.1888],
  [-42.7277, 147.1881],
  [-42.7281, 147.1874],
  [-42.7283, 147.1867],
  [-42.7282, 147.1856],
  [-42.7283, 147.1849],
  [-42.7287, 147.1840],
  [-42.7291, 147.1836],
  [-42.7311, 147.1825],
  [-42.7325, 147.1814],
  [-42.7356, 147.1803],
  [-42.7360, 147.1800],
  [-42.7365, 147.1794],
  [-42.7375, 147.1771],
  [-42.7376, 147.1765],
  [-42.7376, 147.1742],
  [-42.7377, 147.1736],
  [-42.7381, 147.1727],
  [-42.7386, 147.1722],
  [-42.7398, 147.1714],
  [-42.7403, 147.1709],
  [-42.7418, 147.1683],
  [-42.7423, 147.1673],
  [-42.7426, 147.1665],
  [-42.7429, 147.1661],
  [-42.7433, 147.1658],
  [-42.7448, 147.1648],
  [-42.7452, 147.1644],
  [-42.7455, 147.1641],
  [-42.7458, 147.1638],
  [-42.7464, 147.1636],
  [-42.7468, 147.1633],
  [-42.7485, 147.1616],
  [-42.7488, 147.1611],
  [-42.7499, 147.1580],
  [-42.7500, 147.1569],
  [-42.7499, 147.1564],
  [-42.7497, 147.1553],
  [-42.7497, 147.1550],
  [-42.7498, 147.1539],
  [-42.7500, 147.1534],
  [-42.7517, 147.1497],
  [-42.7529, 147.1478],
  [-42.7548, 147.1453],
  [-42.7550, 147.1448],
  [-42.7554, 147.1439],
  [-42.7557, 147.1433],
  [-42.7570, 147.1423],
  [-42.7573, 147.1417],
  [-42.7576, 147.1410],
  [-42.7579, 147.1406],
  [-42.7587, 147.1399],
  [-42.7591, 147.1392],
  [-42.7671, 147.1337],
  [-42.7676, 147.1331],
  [-42.7697, 147.1290],
  [-42.7699, 147.1280],
  [-42.7698, 147.1269],
  [-42.7688, 147.1228],
  [-42.7688, 147.1218],
  [-42.7693, 147.1202],
  [-42.7696, 147.1196],
  [-42.7704, 147.1176],
  [-42.7711, 147.1162],
  [-42.7737, 147.1118],
  [-42.7740, 147.1114],
  [-42.7750, 147.1108],
  [-42.7755, 147.1104],
  [-42.7764, 147.1093],
  [-42.7768, 147.1086],
  [-42.7769, 147.1080],
  [-42.7770, 147.1066],
  [-42.7771, 147.1058],
  [-42.7775, 147.1042],
  [-42.7786, 147.1020],
  [-42.7793, 147.1000],
  [-42.7795, 147.0989],
  [-42.7801, 147.0951],
  [-42.7801, 147.0944]
];

const boyerNewNorfolk = [
  [-42.7801, 147.0944],
  [-42.7795, 147.0906],
  [-42.7768, 147.0838],
  [-42.7766, 147.0835],
  [-42.7758, 147.0826],
  [-42.7754, 147.0819],
  [-42.7741, 147.0794],
  [-42.7726, 147.0773],
  [-42.7720, 147.0767],
  [-42.7709, 147.0761],
  [-42.7704, 147.0756],
  [-42.7701, 147.0752],
  [-42.7695, 147.0739],
  [-42.7694, 147.0733],
  [-42.7694, 147.0728],
  [-42.7695, 147.0710],
  [-42.7700, 147.0693],
  [-42.7705, 147.0685],
  [-42.7710, 147.0677],
  [-42.7728, 147.0656],
  [-42.7738, 147.0642],
  [-42.7752, 147.0611],
  [-42.7753, 147.0605],
  [-42.7760, 147.0556]
];

const newNorfolkGlenora = [
  [-42.7760, 147.0556],
  [-42.7762, 147.0533],
  [-42.7763, 147.0521],
  [-42.7761, 147.0506],
  [-42.7761, 147.0499],
  [-42.7762, 147.0491],
  [-42.7779, 147.0447],
  [-42.7781, 147.0434],
  [-42.7768, 147.0323],
  [-42.7765, 147.0315],
  [-42.7757, 147.0307],
  [-42.7734, 147.0292],
  [-42.7709, 147.0285],
  [-42.7667, 147.0253],
  [-42.7664, 147.0246],
  [-42.7660, 147.0241],
  [-42.7653, 147.0234],
  [-42.7648, 147.0225],
  [-42.7592, 147.0099],
  [-42.7585, 147.0082],
  [-42.7584, 147.0076],
  [-42.7583, 147.0069],
  [-42.7582, 147.0063],
  [-42.7578, 147.0053],
  [-42.7577, 147.0047],
  [-42.7576, 147.0029],
  [-42.7576, 146.9921],
  [-42.7572, 146.9893],
  [-42.7569, 146.9886],
  [-42.7564, 146.9883],
  [-42.7559, 146.9881],
  [-42.7555, 146.9877],
  [-42.7542, 146.9861],
  [-42.7537, 146.9859],
  [-42.7526, 146.9859],
  [-42.7521, 146.9857],
  [-42.7501, 146.9844],
  [-42.7488, 146.9840],
  [-42.7474, 146.9839],
  [-42.7425, 146.9817],
  [-42.7416, 146.9810],
  [-42.7391, 146.9780],
  [-42.7366, 146.9746],
  [-42.7362, 146.9736],
  [-42.7354, 146.9711],
  [-42.7352, 146.9700],
  [-42.7352, 146.9679],
  [-42.7375, 146.9588],
  [-42.7378, 146.9572],
  [-42.7376, 146.9558],
  [-42.7367, 146.9532],
  [-42.7362, 146.9522],
  [-42.7353, 146.9514],
  [-42.7346, 146.9513],
  [-42.7330, 146.9519],
  [-42.7325, 146.9526],
  [-42.7319, 146.9536],
  [-42.7310, 146.9542],
  [-42.7297, 146.9543],
  [-42.7282, 146.9536],
  [-42.7265, 146.9518],
  [-42.7240, 146.9464],
  [-42.7228, 146.9423],
  [-42.7223, 146.9414],
  [-42.7216, 146.9405],
  [-42.7209, 146.9390],
  [-42.7207, 146.9364],
  [-42.7209, 146.9353],
  [-42.7219, 146.9325],
  [-42.7226, 146.9252],
  [-42.7223, 146.9212],
  [-42.7220, 146.9178],
  [-42.7208, 146.9131],
  [-42.7193, 146.9106],
  [-42.7188, 146.9102],
  [-42.7180, 146.9101],
  [-42.7133, 146.9117],
  [-42.7115, 146.9118],
  [-42.7064, 146.9125],
  [-42.7059, 146.9127],
  [-42.7047, 146.9136],
  [-42.7042, 146.9137],
  [-42.7024, 146.9128],
  [-42.7017, 146.9116],
  [-42.7011, 146.9089],
  [-42.7006, 146.9076],
  [-42.6993, 146.9056],
  [-42.6980, 146.9025],
  [-42.6979, 146.9019],
  [-42.6980, 146.9010],
  [-42.6980, 146.9002],
  [-42.6973, 146.8972],
  [-42.6972, 146.8951],
  [-42.6969, 146.8941],
  [-42.6956, 146.8925],
  [-42.6954, 146.8919],
  [-42.6951, 146.8908],
  [-42.6943, 146.8888],
  [-42.6921, 146.8857],
  [-42.6913, 146.8840]
];

const glenoraKaranja = [
  [-42.6913, 146.8840],
  [-42.6909, 146.8828],
  [-42.6907, 146.8820],
  [-42.6908, 146.8813],
  [-42.6914, 146.8787],
  [-42.6914, 146.8778],
  [-42.6908, 146.8770],
  [-42.6896, 146.8765],
  [-42.6890, 146.8759],
  [-42.6887, 146.8748],
  [-42.6886, 146.8734],
  [-42.6880, 146.8720],
  [-42.6848, 146.8693],
  [-42.6843, 146.8686],
  [-42.6834, 146.8669],
  [-42.6832, 146.8662],
  [-42.6831, 146.8650],
  [-42.6827, 146.8638],
  [-42.6809, 146.8605],
  [-42.6807, 146.8591],
  [-42.6813, 146.8555],
  [-42.6811, 146.8518],
  [-42.6815, 146.8510],
  [-42.6821, 146.8507],
  [-42.6830, 146.8509],
  [-42.6837, 146.8507],
  [-42.6842, 146.8498],
  [-42.6843, 146.8438],
  [-42.6842, 146.8428],
  [-42.6817, 146.8380],
  [-42.6814, 146.8367],
  [-42.6814, 146.8360]
];

const karanjaOld = [
  [-42.6814, 146.8360],
  [-42.6816, 146.8348],
  [-42.6824, 146.8325],
  [-42.6825, 146.8314],
  [-42.6822, 146.8303],
  [-42.6807, 146.8281]
];

const karanjaDeviation = [
  [-42.6814, 146.8360],
  [-42.6815, 146.8355],
  [-42.6821, 146.8344],
  [-42.6835, 146.8333],
  [-42.6840, 146.8322],
  [-42.6841, 146.8308],
  [-42.6836, 146.8297],
  [-42.6827, 146.8290],
  [-42.6817, 146.8289],
  [-42.6809, 146.8284],
  [-42.6807, 146.8281]
];

const karanjaWesterway = [
  [-42.6807, 146.8281],
  [-42.6793, 146.8258],
  [-42.6788, 146.8245],
  [-42.6783, 146.8210],
  [-42.6779, 146.8194],
  [-42.6766, 146.8160],
  [-42.6763, 146.8145],
  [-42.6779, 146.7896],
  [-42.6785, 146.7879],
  [-42.6824, 146.7843]
];

const westerwayNationalPark = [
  [-42.6824, 146.7843],
  [-42.6836, 146.7830],
  [-42.6840, 146.7826],
  [-42.6844, 146.7817],
  [-42.6846, 146.7813],
  [-42.6853, 146.7802],
  [-42.6857, 146.7794],
  [-42.6857, 146.7787],
  [-42.6847, 146.7740],
  [-42.6843, 146.7729],
  [-42.6842, 146.7722],
  [-42.6840, 146.7707],
  [-42.6836, 146.7691],
  [-42.6827, 146.7679],
  [-42.6820, 146.7668],
  [-42.6813, 146.7665],
  [-42.6806, 146.7664],
  [-42.6800, 146.7665],
  [-42.6796, 146.7665],
  [-42.6792, 146.7662],
  [-42.6790, 146.7657],
  [-42.6788, 146.7649],
  [-42.6786, 146.7646],
  [-42.6783, 146.7643],
  [-42.6779, 146.7640],
  [-42.6777, 146.7635],
  [-42.6774, 146.7619],
  [-42.6775, 146.7610],
  [-42.6774, 146.7603],
  [-42.6770, 146.7591],
  [-42.6769, 146.7585],
  [-42.6770, 146.7578],
  [-42.6776, 146.7564],
  [-42.6780, 146.7559],
  [-42.6787, 146.7550],
  [-42.6798, 146.7530],
  [-42.6803, 146.7510],
  [-42.6803, 146.7504],
  [-42.6801, 146.7494],
  [-42.6800, 146.7486],
  [-42.6803, 146.7473],
  [-42.6806, 146.7468],
  [-42.6815, 146.7457],
  [-42.6820, 146.7452],
  [-42.6824, 146.7445],
  [-42.6825, 146.7437],
  [-42.6823, 146.7419],
  [-42.6819, 146.7408],
  [-42.6813, 146.7398],
  [-42.6811, 146.7391],
  [-42.6811, 146.7380],
  [-42.6816, 146.7372],
  [-42.6824, 146.7367],
  [-42.6834, 146.7366],
  [-42.6839, 146.7362],
  [-42.6842, 146.7353],
  [-42.6838, 146.7322],
  [-42.6835, 146.7317],
  [-42.6831, 146.7313],
  [-42.6823, 146.7307],
  [-42.6816, 146.7296],
  [-42.6814, 146.7288],
  [-42.6815, 146.7278],
  [-42.6826, 146.7256],
  [-42.6832, 146.7250],
  [-42.6840, 146.7247],
  [-42.6844, 146.7242],
  [-42.6848, 146.7231],
  [-42.6849, 146.7221],
  [-42.6849, 146.7210]
];

const nationalParkfitzgerald = [
  [-42.6849, 146.7210],
  [-42.6849, 146.7187],
  [-42.6851, 146.7178],
  [-42.6855, 146.7169],
  [-42.6873, 146.7146],
  [-42.6882, 146.7145],
  [-42.6892, 146.7156],
  [-42.6900, 146.7154],
  [-42.6922, 146.7146],
  [-42.6926, 146.7142],
  [-42.6931, 146.7131],
  [-42.6937, 146.7127],
  [-42.7051, 146.7117],
  [-42.7060, 146.7112],
  [-42.7081, 146.7069],
  [-42.7091, 146.7059],
  [-42.7106, 146.7051],
  [-42.7112, 146.7046],
  [-42.7126, 146.7015],
  [-42.7127, 146.7005],
  [-42.7127, 146.6980],
  [-42.7129, 146.6968],
  [-42.7141, 146.6938],
  [-42.7156, 146.6925],
  [-42.7167, 146.6906],
  [-42.7285, 146.6642],
  [-42.7291, 146.6633],
  [-42.7298, 146.6629],
  [-42.7327, 146.6629],
  [-42.7334, 146.6623],
  [-42.7340, 146.6601],
  [-42.7349, 146.6591],
  [-42.7357, 146.6587],
  [-42.7364, 146.6581],
  [-42.7376, 146.6553],
  [-42.7381, 146.6548],
  [-42.7389, 146.6543],
  [-42.7399, 146.6530],
  [-42.7420, 146.6512],
  [-42.7427, 146.6501],
  [-42.7429, 146.6483],
  [-42.7433, 146.6476],
  [-42.7434, 146.6468],
  [-42.7429, 146.6438]
];

const fitzgeraldFlorentineJunction = [
  [-42.7429, 146.6438],
  [-42.7431, 146.6427],
  [-42.7448, 146.6398],
  [-42.7451, 146.6391],
  [-42.7451, 146.6384],
  [-42.7453, 146.6379],
  [-42.7457, 146.6376],
  [-42.7463, 146.6375],
  [-42.7468, 146.6372],
  [-42.7474, 146.6367],
  [-42.7478, 146.6361],
  [-42.7482, 146.6350],
  [-42.7486, 146.6342],
  [-42.7500, 146.6331],
  [-42.7505, 146.6324],
  [-42.7510, 146.6310],
  [-42.7524, 146.6289],
  [-42.7526, 146.6281],
  [-42.7530, 146.6268],
  [-42.7540, 146.6248],
  [-42.7548, 146.6225],
  [-42.7555, 146.6216],
  [-42.7557, 146.6211],
  [-42.7558, 146.6200],
  [-42.7562, 146.6171],
  [-42.7559, 146.6139],
  [-42.7544, 146.6114],
  [-42.7543, 146.6105],
  [-42.7544, 146.6097],
  [-42.7549, 146.6093],
  [-42.7557, 146.6093],
  [-42.7561, 146.6089],
  [-42.7564, 146.6081],
  [-42.7564, 146.6065],
  [-42.7564, 146.6058],
  [-42.7570, 146.6050],
  [-42.7578, 146.6050],
  [-42.7581, 146.6049],
  [-42.7583, 146.6045],
  [-42.7596, 146.6002],
  [-42.7606, 146.5961],
  [-42.7609, 146.5945],
  [-42.7609, 146.5915]
];

const florentineJunctionKalista = [
  [-42.7609, 146.5915],
  [-42.7610, 146.5892],
  [-42.7614, 146.5875],
  [-42.7640, 146.5845],
  [-42.7646, 146.5831],
  [-42.7647, 146.5820],
  [-42.7651, 146.5809],
  [-42.7664, 146.5791],
  [-42.7668, 146.5777],
  [-42.7671, 146.5755]
];

export const derwentValley: Line = {
  name: 'Derwent Valley',
  state: 'TAS',
  segments: [
    {
      segments: [bridgewaterBoyer],
      history: {
        opened: {
          date: '1887-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [boyerNewNorfolk],
      history: {
        opened: {
          date: '1887-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newNorfolkGlenora],
      history: {
        opened: {
          date: '1888-07-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-09-01',
          status: 'closed'
        }, {
          date: '2005-01-01',
          status: 'closed'
        }, {
          date: '1996-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [
        glenoraKaranja,
        {
          date: '1950-01-01',
          original: [karanjaOld],
          deviation: [karanjaDeviation]
        },
        karanjaWesterway
      ],
      history: {
        opened: {
          date: '1909-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-00-01',
          status: 'closed'
        }, {
          date: '2005-01-01',
          status: 'closed'
        }, {
          date: '1996-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [westerwayNationalPark],
      history: {
        opened: {
          date: '1916-10-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-09-01',
          status: 'closed'
        }, {
          date: '2005-01-01',
          status: 'closed'
        }, {
          date: '1996-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [nationalParkfitzgerald],
      history: {
        opened: {
          date: '1917-03-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fitzgeraldFlorentineJunction],
      history: {
        opened: {
          date: '1936-07-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [florentineJunctionKalista],
      history: {
        opened: {
          date: '1936-07-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1950-02-02',
          status: 'closed'
        }]
      }
    }
  ]
}
