import { Line } from "../../trackTypes";

const irishtownJunction = [
  [-40.9085, 145.1437],
  [-40.9068, 145.1439]
];

const irishtownSmithton = [
  [-40.9079, 145.1452],
  [-40.9077, 145.1445],
  [-40.9073, 145.1441],
  [-40.9068, 145.1439],
  [-40.9064, 145.1440],
  [-40.9032, 145.1453],
  [-40.8990, 145.1479],
  [-40.8984, 145.1481],
  [-40.8950, 145.1486],
  [-40.8902, 145.1493],
  [-40.8874, 145.1505],
  [-40.8865, 145.1504],
  [-40.8749, 145.1421],
  [-40.8613, 145.1326],
  [-40.8598, 145.1323],
  [-40.8584, 145.1315],
  [-40.8580, 145.1298],
  [-40.8578, 145.1277],
  [-40.8566, 145.1236],
  [-40.8562, 145.1227],
  [-40.8556, 145.1219],
  [-40.8520, 145.1212],
  [-40.8482, 145.1224],
  [-40.8457, 145.1230],
  [-40.8449, 145.1227],
  [-40.8437, 145.1220],
  [-40.8431, 145.1219],
  [-40.8423, 145.1219],
  [-40.8411, 145.1213],
  [-40.8389, 145.1198],
  [-40.8367, 145.1171]
];

export const smithton: Line = {
  name: 'Smithton',
  state: 'TAS',
  segments: [
    {
      segments: [irishtownJunction],
      history: {
        opened: {
          date: '1921-10-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-09-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [irishtownSmithton],
      history: {
        opened: {
          date: '1921-10-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-04-06',
          status: 'closed'
        }]
      }
    }
  ]
}
