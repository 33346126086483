import { Line } from "../../trackTypes";

const queenstownTeepookana = [
  [-42.0791, 145.5546],
  [-42.0796, 145.5537],
  [-42.0802, 145.5530],
  [-42.0818, 145.5512],
  [-42.0824, 145.5502],
  [-42.0826, 145.5499],
  [-42.0832, 145.5495],
  [-42.0852, 145.5488],
  [-42.0861, 145.5484],
  [-42.0876, 145.5476],
  [-42.0881, 145.5473],
  [-42.0907, 145.5471],
  [-42.0913, 145.5469],
  [-42.0919, 145.5465],
  [-42.0930, 145.5447],
  [-42.0931, 145.5442],
  [-42.0981, 145.5360],
  [-42.0988, 145.5352],
  [-42.0998, 145.5348],
  [-42.1012, 145.5343],
  [-42.1023, 145.5341],
  [-42.1056, 145.5344],
  [-42.1067, 145.5342],
  [-42.1073, 145.5336],
  [-42.1077, 145.5328],
  [-42.1079, 145.5311],
  [-42.1082, 145.5303],
  [-42.1087, 145.5293],
  [-42.1091, 145.5291],
  [-42.1096, 145.5290],
  [-42.1111, 145.5301],
  [-42.1117, 145.5301],
  [-42.1127, 145.5297],
  [-42.1141, 145.5279],
  [-42.1150, 145.5274],
  [-42.1173, 145.5277],
  [-42.1181, 145.5274],
  [-42.1189, 145.5264],
  [-42.1196, 145.5246],
  [-42.1201, 145.5239],
  [-42.1245, 145.5234],
  [-42.1251, 145.5229],
  [-42.1254, 145.5223],
  [-42.1255, 145.5214],
  [-42.1258, 145.5205],
  [-42.1263, 145.5198],
  [-42.1269, 145.5194],
  [-42.1316, 145.5177],
  [-42.1320, 145.5175],
  [-42.1327, 145.5167],
  [-42.1353, 145.5118],
  [-42.1355, 145.5111],
  [-42.1355, 145.5103],
  [-42.1354, 145.5081],
  [-42.1350, 145.5060],
  [-42.1350, 145.5054],
  [-42.1356, 145.5033],
  [-42.1359, 145.5025],
  [-42.1364, 145.5015],
  [-42.1367, 145.5012],
  [-42.1371, 145.5009],
  [-42.1373, 145.5007],
  [-42.1379, 145.4980],
  [-42.1379, 145.4964],
  [-42.1379, 145.4961],
  [-42.1383, 145.4955],
  [-42.1385, 145.4954],
  [-42.1388, 145.4953],
  [-42.1391, 145.4951],
  [-42.1398, 145.4942],
  [-42.1402, 145.4940],
  [-42.1421, 145.4934],
  [-42.1423, 145.4933],
  [-42.1427, 145.4930],
  [-42.1429, 145.4930],
  [-42.1446, 145.4932],
  [-42.1448, 145.4933],
  [-42.1451, 145.4936],
  [-42.1454, 145.4937],
  [-42.1461, 145.4937],
  [-42.1464, 145.4939],
  [-42.1466, 145.4940],
  [-42.1471, 145.4940],
  [-42.1475, 145.4941],
  [-42.1479, 145.4940],
  [-42.1481, 145.4940],
  [-42.1506, 145.4952],
  [-42.1509, 145.4953],
  [-42.1528, 145.4955],
  [-42.1545, 145.4949],
  [-42.1552, 145.4948],
  [-42.1563, 145.4949],
  [-42.1569, 145.4947],
  [-42.1593, 145.4950],
  [-42.1596, 145.4949],
  [-42.1603, 145.4950],
  [-42.1606, 145.4949],
  [-42.1616, 145.4949],
  [-42.1631, 145.4945],
  [-42.1640, 145.4942],
  [-42.1650, 145.4942],
  [-42.1657, 145.4940],
  [-42.1665, 145.4942],
  [-42.1710, 145.4943],
  [-42.1727, 145.4941],
  [-42.1758, 145.4929],
  [-42.1764, 145.4928],
  [-42.1774, 145.4924],
  [-42.1796, 145.4906],
  [-42.1802, 145.4904],
  [-42.1811, 145.4906],
  [-42.1815, 145.4906],
  [-42.1828, 145.4900],
  [-42.1835, 145.4899],
  [-42.1840, 145.4896],
  [-42.1843, 145.4893],
  [-42.1849, 145.4871],
  [-42.1846, 145.4857],
  [-42.1832, 145.4832],
  [-42.1831, 145.4828],
  [-42.1832, 145.4823],
  [-42.1837, 145.4808],
  [-42.1842, 145.4803],
  [-42.1854, 145.4783],
  [-42.1858, 145.4774],
  [-42.1858, 145.4768],
  [-42.1857, 145.4763],
  [-42.1852, 145.4753],
  [-42.1847, 145.4741],
  [-42.1844, 145.4723],
  [-42.1845, 145.4718],
  [-42.1847, 145.4714],
  [-42.1849, 145.4711],
  [-42.1861, 145.4701],
  [-42.1864, 145.4698],
  [-42.1871, 145.4684],
  [-42.1873, 145.4681],
  [-42.1884, 145.4675],
  [-42.1897, 145.4673],
  [-42.1901, 145.4676],
  [-42.1925, 145.4700],
  [-42.1940, 145.4704],
  [-42.1942, 145.4704],
  [-42.1948, 145.4698],
  [-42.1949, 145.4694],
  [-42.1947, 145.4689],
  [-42.1939, 145.4681],
  [-42.1926, 145.4668],
  [-42.1921, 145.4656],
  [-42.1919, 145.4648],
  [-42.1919, 145.4649],
  [-42.1920, 145.4634],
  [-42.1924, 145.4628],
  [-42.1926, 145.4626],
  [-42.1929, 145.4625],
  [-42.1930, 145.4623],
  [-42.1933, 145.4617],
  [-42.1937, 145.4612],
  [-42.1940, 145.4606],
  [-42.1947, 145.4598],
  [-42.1949, 145.4592],
  [-42.1950, 145.4585],
  [-42.1958, 145.4559],
  [-42.1960, 145.4552],
  [-42.1966, 145.4543],
  [-42.1968, 145.4538],
  [-42.1970, 145.4528],
  [-42.1978, 145.4511],
  [-42.1987, 145.4501],
  [-42.1988, 145.4496],
  [-42.1988, 145.4492],
  [-42.1978, 145.4480],
  [-42.1973, 145.4472],
  [-42.1968, 145.4461],
  [-42.1966, 145.4458],
  [-42.1957, 145.4455],
  [-42.1949, 145.4446],
  [-42.1935, 145.4442],
  [-42.1926, 145.4434],
  [-42.1923, 145.4429],
  [-42.1913, 145.4391],
  [-42.1907, 145.4375],
  [-42.1900, 145.4365],
  [-42.1898, 145.4359],
  [-42.1899, 145.4354],
  [-42.1903, 145.4348],
  [-42.1917, 145.4337],
  [-42.1920, 145.4334],
  [-42.1921, 145.4330],
  [-42.1920, 145.4319],
  [-42.1912, 145.4306],
  [-42.1896, 145.4287],
  [-42.1893, 145.4279]
];

const teepookanaRegattaPoint = [
  [-42.1893, 145.4279],
  [-42.1887, 145.4267],
  [-42.1887, 145.4263],
  [-42.1890, 145.4244],
  [-42.1902, 145.4216],
  [-42.1909, 145.4199],
  [-42.1918, 145.4191],
  [-42.1926, 145.4189],
  [-42.1960, 145.4201],
  [-42.1964, 145.4200],
  [-42.1966, 145.4196],
  [-42.1967, 145.4192],
  [-42.1964, 145.4161],
  [-42.1965, 145.4151],
  [-42.1976, 145.4121],
  [-42.1977, 145.4107],
  [-42.1976, 145.4098],
  [-42.1964, 145.4056],
  [-42.1949, 145.4020],
  [-42.1947, 145.4013],
  [-42.1948, 145.4007],
  [-42.1954, 145.3981],
  [-42.1953, 145.3973],
  [-42.1941, 145.3943],
  [-42.1935, 145.3933],
  [-42.1931, 145.3924],
  [-42.1930, 145.3910],
  [-42.1934, 145.3899],
  [-42.1953, 145.3879],
  [-42.1960, 145.3865],
  [-42.1964, 145.3847],
  [-42.1965, 145.3794],
  [-42.1962, 145.3774],
  [-42.1958, 145.3765],
  [-42.1942, 145.3739],
  [-42.1939, 145.3730],
  [-42.1933, 145.3706],
  [-42.1927, 145.3672],
  [-42.1920, 145.3654],
  [-42.1910, 145.3644],
  [-42.1900, 145.3639],
  [-42.1789, 145.3615],
  [-42.1772, 145.3606],
  [-42.1761, 145.3596],
  [-42.1750, 145.3582],
  [-42.1746, 145.3571],
  [-42.1744, 145.3549],
  [-42.1736, 145.3525],
  [-42.1717, 145.3502],
  [-42.1704, 145.3494],
  [-42.1692, 145.3482],
  [-42.1678, 145.3455],
  [-42.1677, 145.3449],
  [-42.1677, 145.3432],
  [-42.1668, 145.3389],
  [-42.1666, 145.3375],
  [-42.1668, 145.3362],
  [-42.1667, 145.3356],
  [-42.1656, 145.3312],
  [-42.1650, 145.3300],
  [-42.1624, 145.3266],
  [-42.1619, 145.3263],
  [-42.1611, 145.3262],
  [-42.1608, 145.3264],
  [-42.1597, 145.3280],
  [-42.1590, 145.3287]
];

export const queenstown: Line = {
  name: 'Queenstown',
  state: 'TAS',
  segments: [
    {
      segments: [queenstownTeepookana],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-08-10',
          status: 'closed'
        }, {
          date: '2002-12-27',
          status: 'open'
        }]
      }
    },
    {
      segments: [teepookanaRegattaPoint],
      history: {
        opened: {
          date: '1899-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-08-10',
          status: 'closed'
        }, {
          date: '2002-12-27',
          status: 'open'
        }]
      }
    }
  ]
}
