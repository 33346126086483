import { Line } from "../../../../trackTypes";

const manchesterStreet = [
  [-43.53404, 172.63968],
  [-43.53322, 172.63968],
  [-43.53313, 172.63973],
  [-43.53310, 172.63988]
];

const cashelStreetLink = [
  [-43.53306, 172.63810],
  [-43.53311, 172.63822],
  [-43.53313, 172.63833],
  [-43.53310, 172.63988]
];

const cashelStreet = [
  [-43.53310, 172.63988],
  [-43.53309, 172.65683]
];

const stanmoreRoad = [
  [-43.53309, 172.65683],
  [-43.53304, 172.65696],
  [-43.53297, 172.65703],
  [-43.53287, 172.65707],
  [-43.53081, 172.65704]
];

const stanmoreRoadJunction = [
  [-43.53091, 172.65690],
  [-43.53086, 172.65701],
  [-43.53081, 172.65704]
];

const burwoodChurch = [
  [-43.53081, 172.65704],
  [-43.52465, 172.65705],
  [-43.52440, 172.65703],
  [-43.52242, 172.65642],
  [-43.52222, 172.65640],
  [-43.51772, 172.65634],
  [-43.51767, 172.65639],
  [-43.51764, 172.65644],
  [-43.51760, 172.65653],
  [-43.51754, 172.65971],
  [-43.51750, 172.65982],
  [-43.51742, 172.65987],
  [-43.51673, 172.65988],
  [-43.51525, 172.65986],
  [-43.51508, 172.65991],
  [-43.51487, 172.66000],
  [-43.51442, 172.66044],
  [-43.51434, 172.66051],
  [-43.51424, 172.66054],
  [-43.51307, 172.66053],
  [-43.51292, 172.66059],
  [-43.51280, 172.66068],
  [-43.51244, 172.66109],
  [-43.51226, 172.66127],
  [-43.51080, 172.66224],
  [-43.51009, 172.66282],
  [-43.50984, 172.66297],
  [-43.50955, 172.66301],
  [-43.50822, 172.66289],
  [-43.50814, 172.66293],
  [-43.50808, 172.66299],
  [-43.50805, 172.66309],
  [-43.50799, 172.66506],
  [-43.50795, 172.66541],
  [-43.50772, 172.66710],
  [-43.50757, 172.66764],
  [-43.50747, 172.66784],
  [-43.50733, 172.66798],
  [-43.50718, 172.66805],
  [-43.50691, 172.66817],
  [-43.50677, 172.66828],
  [-43.50664, 172.66845],
  [-43.50652, 172.66871],
  [-43.50647, 172.66892],
  [-43.50645, 172.66914],
  [-43.50633, 172.67156],
  [-43.50629, 172.67191],
  [-43.50621, 172.67225],
  [-43.50550, 172.67452],
  [-43.50536, 172.67501],
  [-43.50530, 172.67549],
  [-43.50530, 172.67572],
  [-43.50532, 172.67587],
  [-43.50544, 172.67642],
  [-43.50546, 172.67668],
  [-43.50521, 172.68008],
  [-43.50517, 172.68033],
  [-43.50491, 172.68109],
  [-43.50479, 172.68130],
  [-43.50452, 172.68168],
  [-43.50410, 172.68209],
  [-43.50364, 172.68242],
  [-43.50343, 172.68254],
  [-43.50260, 172.68293],
  [-43.50128, 172.68367],
  [-43.50106, 172.68384],
  [-43.50087, 172.68406],
  [-43.50063, 172.68439],
  [-43.50032, 172.68500],
  [-43.50020, 172.68535],
  [-43.50015, 172.68563],
  [-43.50013, 172.68666]
];

const newBrightonPier = [
  [-43.50013, 172.68666],
  [-43.50011, 172.68604],
  [-43.50014, 172.68754],
  [-43.50008, 172.68776],
  [-43.49996, 172.68791],
  [-43.49657, 172.68844],
  [-43.49638, 172.68853],
  [-43.49269, 172.69147],
  [-43.49262, 172.69158],
  [-43.49259, 172.69171],
  [-43.49374, 172.70571],
  [-43.49380, 172.70602],
  [-43.49388, 172.70625],
  [-43.49482, 172.70808],
  [-43.49491, 172.70831],
  [-43.49495, 172.70855],
  [-43.49511, 172.71029],
  [-43.49536, 172.71281],
  [-43.49543, 172.71312],
  [-43.49553, 172.71334],
  [-43.49691, 172.71576],
  [-43.49704, 172.71593],
  [-43.49735, 172.71623],
  [-43.49743, 172.71639],
  [-43.49747, 172.71656],
  [-43.49746, 172.71679],
  [-43.49519, 172.72637],
  [-43.49521, 172.72650],
  [-43.49526, 172.72658],
  [-43.50022, 172.72827],
  [-43.50676, 172.73075]
];

const brightonLink = [
  [-43.50676, 172.73075],
  [-43.50689, 172.73074],
  [-43.50700, 172.73060]
];

export const line5: Line = {
  name: 'Line 5',
  state: 'NZ',
  segments: [
    {
      segments: [manchesterStreet],
      history: {
        opened: {
          date: '1893-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1910-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cashelStreetLink],
      history: {
        opened: {
          date: '1910-08-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cashelStreet],
      history: {
        opened: {
          date: '1893-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1910-10-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stanmoreRoad],
      history: {
        opened: {
          date: '1894-10-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1910-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stanmoreRoadJunction],
      history: {
        opened: {
          date: '1910-08-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1931-07-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [burwoodChurch],
      history: {
        opened: {
          date: '1894-10-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1910-08-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1931-07-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newBrightonPier],
      history: {
        opened: {
          date: '1894-10-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1914-09-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1931-07-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brightonLink],
      history: {
        opened: {
          date: '1914-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1931-07-05',
          status: 'closed'
        }]
      }
    }
  ]
}
