import { Line } from "../../../trackTypes";

const junctionMacGregorHillTunnel = [
  [-21.1453, 140.1956],
  [-21.1454, 140.1947],
  [-21.1452, 140.1939],
  [-21.1122, 140.1675],
  [-21.1023, 140.1592],
  [-21.0904, 140.1439],
  [-21.0868, 140.1401],
  [-21.0832, 140.1206],
  [-21.0752, 140.1063],
  [-21.0500, 140.0926],
  [-21.0476, 140.0894],
  [-21.0440, 140.0841],
  [-21.0439, 140.0826],
  [-21.0442, 140.0805],
  [-21.0436, 140.0787],
  [-21.0419, 140.0773],
  [-21.0363, 140.0744],
  [-21.0351, 140.0726],
  [-21.0335, 140.0717],
  [-21.0294, 140.0704],
  [-21.0280, 140.0692],
  [-21.0203, 140.0611],
  [-21.0162, 140.0590],
  [-21.0139, 140.0567],
  [-21.0118, 140.0480],
  [-21.0098, 140.0380],
  [-21.0064, 140.0282],
  [-21.0049, 140.0266],
  [-20.9974, 140.0234],
  [-20.9895, 140.0172],
  [-20.9863, 140.0130],
  [-20.9858, 140.0111],
  [-20.9856, 140.0092],
  [-20.9848, 140.0081],
  [-20.9830, 140.0077],
  [-20.9809, 140.0067],
  [-20.9770, 140.0065],
  [-20.9761, 140.0059],
  [-20.9759, 140.0050],
  [-20.9762, 140.0023],
  [-20.9760, 140.0013],
  [-20.9753, 140.0007],
  [-20.9714, 139.9995],
  [-20.9694, 139.9982],
  [-20.9633, 139.9969],
  [-20.9606, 139.9956],
  [-20.9568, 139.9928],
  [-20.9555, 139.9922],
  [-20.9478, 139.9879],
  [-20.9472, 139.9868],
  [-20.9474, 139.9850],
  [-20.9470, 139.9789],
  [-20.9462, 139.9768],
  [-20.9450, 139.9758],
  [-20.9446, 139.9753],
  [-20.9445, 139.9746],
  [-20.9450, 139.9723],
  [-20.9445, 139.9696],
  [-20.9445, 139.9685],
  [-20.9451, 139.9660],
  [-20.9454, 139.9610],
  [-20.9448, 139.9593],
  [-20.9448, 139.9558],
  [-20.9450, 139.9545],
  [-20.9448, 139.9538],
  [-20.9443, 139.9534],
  [-20.9436, 139.9534],
  [-20.9429, 139.9531],
  [-20.9426, 139.9527],
  [-20.9424, 139.9522],
  [-20.9419, 139.9520],
  [-20.9412, 139.9521],
  [-20.9400, 139.9517],
  [-20.9393, 139.9518],
  [-20.9380, 139.9523],
  [-20.9374, 139.9523],
  [-20.9369, 139.9522],
  [-20.9365, 139.9522],
  [-20.9355, 139.9526],
  [-20.9349, 139.9525],
  [-20.9335, 139.9517],
  [-20.9323, 139.9513],
  [-20.9317, 139.9513],
  [-20.9313, 139.9511],
  [-20.9308, 139.9506],
  [-20.9305, 139.9505],
  [-20.9289, 139.9511],
  [-20.9285, 139.9511],
  [-20.9274, 139.9506],
  [-20.9270, 139.9502],
  [-20.9267, 139.9497],
  [-20.9263, 139.9495],
  [-20.9244, 139.9496],
  [-20.9231, 139.9493],
  [-20.9220, 139.9486],
  [-20.9211, 139.9476],
  [-20.9206, 139.9464],
  [-20.9203, 139.9461],
  [-20.9196, 139.9461],
  [-20.9192, 139.9463],
  [-20.9188, 139.9467],
  [-20.9177, 139.9469],
  [-20.9173, 139.9470],
  [-20.9169, 139.9469],
  [-20.9161, 139.9465]
];

const macGregorHillTunnel = [
  [-20.9161, 139.9465],
  [-20.9154, 139.9462]
];

const macGregorHillTunnelBallara = [
  [-20.9154, 139.9462],
  [-20.9149, 139.9460],
  [-20.9149, 139.9460],
  [-20.9144, 139.9461],
  [-20.9141, 139.9463],
  [-20.9137, 139.9468],
  [-20.9135, 139.9469],
  [-20.9133, 139.9468],
  [-20.9132, 139.9466],
  [-20.9130, 139.9461],
  [-20.9128, 139.9460],
  [-20.9125, 139.9459],
  [-20.9122, 139.9458],
  [-20.9118, 139.9454],
  [-20.9110, 139.9451],
  [-20.9107, 139.9447],
  [-20.9105, 139.9443],
  [-20.9106, 139.9440],
  [-20.9108, 139.9438],
  [-20.9111, 139.9439],
  [-20.9114, 139.9440],
  [-20.9118, 139.9445],
  [-20.9119, 139.9447],
  [-20.9119, 139.9449],
  [-20.9121, 139.9451],
  [-20.9132, 139.9454],
  [-20.9135, 139.9453],
  [-20.9137, 139.9451],
  [-20.9137, 139.9447],
  [-20.9134, 139.9444],
  [-20.9120, 139.9434]
];

export const ballara: Line = {
  name: 'Ballara',
  state: 'QLD',
  segments: [
    {
      segments: [
        junctionMacGregorHillTunnel,
        {
          name: 'MacGregor Hill Tunnel',
          segment: macGregorHillTunnel,
          type: 'tunnel'
        },
        macGregorHillTunnelBallara
      ],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
