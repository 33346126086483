import { Line } from "../../../trackTypes";

/**
 * clyde -> cromwell o1921 c1980
 */

const wingatuiTunnelNo1 = [
  [-45.8775, 170.3844],
  [-45.8773, 170.3837],
  [-45.8770, 170.3832],
  [-45.8766, 170.3828],
  [-45.8401, 170.3615],
  [-45.8391, 170.3603],
  [-45.8379, 170.3575],
  [-45.8375, 170.3559],
  [-45.8374, 170.3550],
  [-45.8375, 170.3534],
  [-45.8373, 170.3523],
  [-45.8364, 170.3494],
  [-45.8363, 170.3486],
  [-45.8363, 170.3478],
  [-45.8361, 170.3471],
  [-45.8355, 170.3463],
  [-45.8335, 170.3451],
  [-45.8330, 170.3447],
  [-45.8324, 170.3437],
  [-45.8321, 170.3428],
  [-45.8321, 170.3418],
  [-45.8328, 170.3384],
  [-45.8328, 170.3375],
  [-45.8328, 170.3349],
  [-45.8325, 170.3338],
  [-45.8319, 170.3330],
  [-45.8311, 170.3327],
  [-45.8304, 170.3331],
  [-45.8294, 170.3340],
  [-45.8290, 170.3347],
  [-45.8289, 170.3355],
  [-45.8288, 170.3370],
  [-45.8284, 170.3385],
  [-45.8275, 170.3396],
  [-45.8265, 170.3400],
  [-45.8255, 170.3397],
  [-45.8252, 170.3394],
  [-45.8247, 170.3392],
  [-45.8245, 170.3392]
];

const tunnelNo1 = [
  [-45.8245, 170.3392],
  [-45.8239, 170.3393],
  [-45.8230, 170.3399],
  [-45.8222, 170.3401]
];

const tunnelNo1SalisburyTunnel = [
  [-45.8222, 170.3401],
  [-45.8196, 170.3395],
  [-45.8188, 170.3397],
  [-45.8173, 170.3409],
  [-45.8166, 170.3422],
  [-45.8163, 170.3436],
  [-45.8157, 170.3450],
  [-45.8146, 170.3463]
];

const salisburyTunnel = [
  [-45.8146, 170.3463],
  [-45.8105, 170.3485]
];

const salisburyTunnelTunnelNo3 = [
  [-45.8105, 170.3485],
  [-45.8098, 170.3485],
  [-45.8090, 170.3480],
  [-45.8075, 170.3459],
  [-45.8060, 170.3424],
  [-45.8040, 170.3394],
  [-45.8031, 170.3386],
  [-45.8022, 170.3381],
  [-45.8016, 170.3372],
  [-45.8013, 170.3360],
  [-45.8016, 170.3324],
  [-45.8022, 170.3306],
  [-45.8023, 170.3292],
  [-45.8017, 170.3277]
];

const tunnelNo3 = [
  [-45.8017, 170.3277],
  [-45.8008, 170.3264]
];

const tunnelNo3TunnelNo4 = [
  [-45.8008, 170.3264],
  [-45.7997, 170.3249],
  [-45.7977, 170.3232],
  [-45.7973, 170.3225],
  [-45.7972, 170.3220],
  [-45.7971, 170.3208],
  [-45.7965, 170.3196],
  [-45.7958, 170.3192],
  [-45.7953, 170.3185],
  [-45.7951, 170.3176],
  [-45.7951, 170.3165],
  [-45.7947, 170.3152],
  [-45.7920, 170.3119],
  [-45.7914, 170.3110],
  [-45.7908, 170.3105],
  [-45.7901, 170.3105],
  [-45.7891, 170.3108],
  [-45.7883, 170.3107],
  [-45.7874, 170.3102],
  [-45.7865, 170.3101],
  [-45.7861, 170.3100],
  [-45.7857, 170.3098],
  [-45.7852, 170.3097],
  [-45.7840, 170.3101],
  [-45.7833, 170.3107],
  [-45.7826, 170.3120],
  [-45.7818, 170.3133],
  [-45.7809, 170.3138],
  [-45.7801, 170.3140]
];

const tunnelNo4 = [
  [-45.7801, 170.3140],
  [-45.7795, 170.3145],
  [-45.7792, 170.3154]
];

const tunnelNo4TunnelNo5 = [
  [-45.7792, 170.3154],
  [-45.7792, 170.3165],
  [-45.7797, 170.3174],
  [-45.7803, 170.3179],
  [-45.7808, 170.3186],
  [-45.7809, 170.3195],
  [-45.7806, 170.3228],
  [-45.7802, 170.3240],
  [-45.7793, 170.3255],
  [-45.7785, 170.3258],
  [-45.7779, 170.3256],
  [-45.7774, 170.3251],
  [-45.7770, 170.3244],
  [-45.7764, 170.3239],
  [-45.7757, 170.3240],
  [-45.7748, 170.3246],
  [-45.7744, 170.3253],
  [-45.7742, 170.3262],
  [-45.7743, 170.3286],
  [-45.7742, 170.3296],
  [-45.7735, 170.3322],
  [-45.7731, 170.3330],
  [-45.7727, 170.3333],
  [-45.7720, 170.3335],
  [-45.7715, 170.3333],
  [-45.7707, 170.3327],
  [-45.7700, 170.3318],
  [-45.7695, 170.3314],
  [-45.7691, 170.3314],
  [-45.7683, 170.3318],
  [-45.7670, 170.3331],
  [-45.7666, 170.3333],
  [-45.7658, 170.3334],
  [-45.7652, 170.3339],
  [-45.7648, 170.3342],
  [-45.7643, 170.3345],
  [-45.7637, 170.3345],
  [-45.7632, 170.3347],
  [-45.7622, 170.3354],
  [-45.7616, 170.3362],
  [-45.7615, 170.3371],
  [-45.7616, 170.3385],
  [-45.7615, 170.3401],
  [-45.7615, 170.3407],
  [-45.7611, 170.3419],
  [-45.7605, 170.3428],
  [-45.7593, 170.3437],
  [-45.7584, 170.3448],
  [-45.7579, 170.3454],
  [-45.7575, 170.3456],
  [-45.7567, 170.3455],
  [-45.7564, 170.3456]
];

const tunnelNo5 = [
  [-45.7564, 170.3456],
  [-45.7558, 170.3457],
  [-45.7555, 170.3459],
  [-45.7551, 170.3463]
];

const tunnelNo5TunnelNo6 = [
  [-45.7551, 170.3463],
  [-45.7549, 170.3464],
  [-45.7541, 170.3464],
  [-45.7538, 170.3463],
  [-45.7527, 170.3458],
  [-45.7521, 170.3458],
  [-45.7515, 170.3464],
  [-45.7508, 170.3478],
  [-45.7502, 170.3484],
  [-45.7489, 170.3489],
  [-45.7484, 170.3492],
  [-45.7478, 170.3494],
  [-45.7473, 170.3495],
  [-45.7468, 170.3498],
  [-45.7456, 170.3513],
  [-45.7448, 170.3521],
  [-45.7444, 170.3528],
  [-45.7438, 170.3533],
  [-45.7418, 170.3536],
  [-45.7413, 170.3536],
  [-45.7408, 170.3534],
  [-45.7401, 170.3534],
  [-45.7389, 170.3539],
  [-45.7382, 170.3545],
  [-45.7373, 170.3559],
  [-45.7366, 170.3564],
  [-45.7355, 170.3566],
  [-45.7333, 170.3576],
  [-45.7324, 170.3582],
  [-45.7319, 170.3583],
  [-45.7314, 170.3580],
  [-45.7311, 170.3576],
  [-45.7308, 170.3569],
  [-45.7306, 170.3555],
  [-45.7307, 170.3548],
  [-45.7308, 170.3540],
  [-45.7309, 170.3532],
  [-45.7306, 170.3520],
  [-45.7298, 170.3504],
  [-45.7294, 170.3500],
  [-45.7270, 170.3488],
  [-45.7260, 170.3488],
  [-45.7253, 170.3493],
  [-45.7230, 170.3515],
  [-45.7225, 170.3519],
  [-45.7209, 170.3525],
  [-45.7203, 170.3525],
  [-45.7198, 170.3522],
  [-45.7194, 170.3517],
  [-45.7192, 170.3507],
  [-45.7192, 170.3495],
  [-45.7191, 170.3487],
  [-45.7186, 170.3477]
];

const tunnelNo6 = [
  [-45.7186, 170.3477],
  [-45.7180, 170.3471]
];

const tunnelNo6Hindon = [
  [-45.7180, 170.3471],
  [-45.7175, 170.3467],
  [-45.7165, 170.3452],
  [-45.7161, 170.3448],
  [-45.7154, 170.3446],
  [-45.7145, 170.3439],
  [-45.7129, 170.3417],
  [-45.7117, 170.3397]
];

const hindonTunnelNo7 = [
  [-45.7117, 170.3397],
  [-45.7112, 170.3390],
  [-45.7107, 170.3386],
  [-45.7101, 170.3386],
  [-45.7079, 170.3399],
  [-45.7071, 170.3399],
  [-45.7065, 170.3393],
  [-45.7063, 170.3386],
  [-45.7063, 170.3377],
  [-45.7066, 170.3368],
  [-45.7074, 170.3358],
  [-45.7091, 170.3328],
  [-45.7094, 170.3320],
  [-45.7098, 170.3302],
  [-45.7106, 170.3286],
  [-45.7109, 170.3276],
  [-45.7117, 170.3261],
  [-45.7123, 170.3254],
  [-45.7129, 170.3250],
  [-45.7133, 170.3244],
  [-45.7135, 170.3233],
  [-45.7132, 170.3221],
  [-45.7101, 170.3168],
  [-45.7095, 170.3161],
  [-45.7084, 170.3153],
  [-45.7069, 170.3134],
  [-45.7064, 170.3122],
  [-45.7059, 170.3117],
  [-45.7057, 170.3116]
];

const tunnelNo7 = [
  [-45.7057, 170.3116],
  [-45.7051, 170.3112]
];

const tunnelNo7TunnelNo8 = [
  [-45.7051, 170.3112],
  [-45.7048, 170.3107],
  [-45.7045, 170.3093],
  [-45.7044, 170.3083],
  [-45.7045, 170.3067],
  [-45.7043, 170.3056],
  [-45.7033, 170.3045],
  [-45.7023, 170.3040],
  [-45.7015, 170.3041],
  [-45.7002, 170.3045],
  [-45.6995, 170.3050],
  [-45.6984, 170.3061],
  [-45.6982, 170.3062]
];

const tunnelNo8 = [
  [-45.6982, 170.3062],
  [-45.6973, 170.3060]
];

const tunnelNo8TunnelNo9 = [
  [-45.6973, 170.3060],
  [-45.6969, 170.3055],
  [-45.6965, 170.3046],
  [-45.6963, 170.3036],
  [-45.6959, 170.3027],
  [-45.6950, 170.3016],
  [-45.6944, 170.3013],
  [-45.6938, 170.3012],
  [-45.6930, 170.3007],
  [-45.6920, 170.2995],
  [-45.6913, 170.2990],
  [-45.6906, 170.2989],
  [-45.6892, 170.2992],
  [-45.6884, 170.2991],
  [-45.6875, 170.2986],
  [-45.6865, 170.2987],
  [-45.6858, 170.2993],
  [-45.6853, 170.3003],
  [-45.6849, 170.3008],
  [-45.6843, 170.3010],
  [-45.6826, 170.3011],
  [-45.6818, 170.3008],
  [-45.6811, 170.3002],
  [-45.6804, 170.3001],
  [-45.6799, 170.3004],
  [-45.6789, 170.3017],
  [-45.6783, 170.3020],
  [-45.6777, 170.3019],
  [-45.6773, 170.3020],
  [-45.6763, 170.3024],
  [-45.6756, 170.3021],
  [-45.6751, 170.3014],
  [-45.6745, 170.3009],
  [-45.6739, 170.3007],
  [-45.6722, 170.3010],
  [-45.6718, 170.3009],
  [-45.6713, 170.3004],
  [-45.6710, 170.2996],
  [-45.6710, 170.2988],
  [-45.6716, 170.2946],
  [-45.6716, 170.2938],
  [-45.6712, 170.2930],
  [-45.6707, 170.2925],
  [-45.6671, 170.2910],
  [-45.6666, 170.2904],
  [-45.6664, 170.2895],
  [-45.6664, 170.2887],
  [-45.6664, 170.2879],
  [-45.6660, 170.2871],
  [-45.6658, 170.2869]
];

const tunnelNo9 = [
  [-45.6658, 170.2869],
  [-45.6649, 170.2863]
];

const tunnelNo9TunnelNo10 = [
  [-45.6649, 170.2863],
  [-45.6646, 170.2861],
  [-45.6631, 170.2843],
  [-45.6626, 170.2841],
  [-45.6622, 170.2843],
  [-45.6603, 170.2857],
  [-45.6597, 170.2866]
];

const tunnelNo10 = [
  [-45.6597, 170.2866],
  [-45.6593, 170.2869],
  [-45.6591, 170.2869]
];

const tunnelNo10Middlemarch = [
  [-45.6591, 170.2869],
  [-45.6590, 170.2869],
  [-45.6583, 170.2867],
  [-45.6579, 170.2862],
  [-45.6576, 170.2857],
  [-45.6570, 170.2847],
  [-45.6554, 170.2827],
  [-45.6548, 170.2817],
  [-45.6542, 170.2805],
  [-45.6536, 170.2795],
  [-45.6530, 170.2782],
  [-45.6507, 170.2748],
  [-45.6501, 170.2742],
  [-45.6495, 170.2738],
  [-45.6490, 170.2729],
  [-45.6489, 170.2720],
  [-45.6491, 170.2698],
  [-45.6489, 170.2688],
  [-45.6483, 170.2679],
  [-45.6462, 170.2670],
  [-45.6457, 170.2666],
  [-45.6454, 170.2659],
  [-45.6445, 170.2622],
  [-45.6438, 170.2607],
  [-45.6429, 170.2599],
  [-45.6422, 170.2597],
  [-45.6415, 170.2597],
  [-45.6409, 170.2595],
  [-45.6402, 170.2588],
  [-45.6394, 170.2585],
  [-45.6385, 170.2585],
  [-45.6378, 170.2581],
  [-45.6374, 170.2574],
  [-45.6364, 170.2533],
  [-45.6363, 170.2521],
  [-45.6363, 170.2512],
  [-45.6366, 170.2505],
  [-45.6368, 170.2491],
  [-45.6366, 170.2479],
  [-45.6352, 170.2444],
  [-45.6343, 170.2432],
  [-45.6338, 170.2422],
  [-45.6333, 170.2404],
  [-45.6335, 170.2387],
  [-45.6350, 170.2342],
  [-45.6361, 170.2295],
  [-45.6366, 170.2285],
  [-45.6374, 170.2274],
  [-45.6376, 170.2261],
  [-45.6371, 170.2247],
  [-45.6360, 170.2240],
  [-45.6352, 170.2228],
  [-45.6348, 170.2208],
  [-45.6347, 170.2126],
  [-45.6352, 170.2098],
  [-45.6359, 170.2080],
  [-45.6360, 170.2065],
  [-45.6352, 170.2049],
  [-45.6299, 170.2010],
  [-45.6246, 170.1946],
  [-45.6239, 170.1927],
  [-45.6235, 170.1890],
  [-45.6221, 170.1862],
  [-45.6210, 170.1849],
  [-45.6205, 170.1840],
  [-45.6203, 170.1830],
  [-45.6204, 170.1817],
  [-45.6202, 170.1804],
  [-45.6197, 170.1796],
  [-45.6195, 170.1785],
  [-45.6195, 170.1775],
  [-45.6199, 170.1758],
  [-45.6198, 170.1744],
  [-45.6188, 170.1731],
  [-45.6176, 170.1725],
  [-45.6167, 170.1717],
  [-45.6141, 170.1679],
  [-45.6124, 170.1646],
  [-45.6122, 170.1634],
  [-45.6125, 170.1622],
  [-45.6131, 170.1614],
  [-45.6135, 170.1605],
  [-45.6149, 170.1544],
  [-45.6148, 170.1524],
  [-45.6127, 170.1466],
  [-45.6118, 170.1452],
  [-45.6088, 170.1424],
  [-45.6070, 170.1411],
  [-45.6049, 170.1402],
  [-45.6037, 170.1395],
  [-45.6004, 170.1363],
  [-45.5993, 170.1356],
  [-45.5861, 170.1314],
  [-45.5843, 170.1305],
  [-45.5697, 170.1203],
  [-45.5681, 170.1197],
  [-45.5258, 170.1134],
  [-45.5240, 170.1138],
  [-45.5058, 170.1242]
];

const middlemarchHyde = [
  [-45.5058, 170.1242],
  [-45.4574, 170.1522],
  [-45.4566, 170.1528],
  [-45.4550, 170.1550],
  [-45.4544, 170.1557],
  [-45.4522, 170.1575],
  [-45.4513, 170.1580],
  [-45.4467, 170.1594],
  [-45.4419, 170.1621],
  [-45.4412, 170.1628],
  [-45.4398, 170.1663],
  [-45.4392, 170.1671],
  [-45.4384, 170.1674],
  [-45.4372, 170.1677],
  [-45.4365, 170.1683],
  [-45.4354, 170.1700],
  [-45.4344, 170.1700],
  [-45.4330, 170.1704],
  [-45.4321, 170.1708],
  [-45.4307, 170.1726],
  [-45.4298, 170.1731],
  [-45.4287, 170.1731],
  [-45.4281, 170.1733],
  [-45.4262, 170.1742],
  [-45.4252, 170.1752],
  [-45.4231, 170.1784],
  [-45.4226, 170.1788],
  [-45.4220, 170.1790],
  [-45.4211, 170.1790],
  [-45.4204, 170.1794],
  [-45.4175, 170.1832],
  [-45.4165, 170.1838],
  [-45.4126, 170.1842],
  [-45.4106, 170.1840],
  [-45.4099, 170.1842],
  [-45.4094, 170.1847],
  [-45.4078, 170.1871],
  [-45.4072, 170.1877],
  [-45.4060, 170.1882],
  [-45.4054, 170.1888],
  [-45.4047, 170.1898],
  [-45.4041, 170.1904],
  [-45.3989, 170.1939],
  [-45.3973, 170.1956],
  [-45.3963, 170.1965],
  [-45.3889, 170.1998],
  [-45.3880, 170.2005],
  [-45.3856, 170.2029],
  [-45.3844, 170.2034],
  [-45.3833, 170.2033],
  [-45.3823, 170.2037],
  [-45.3813, 170.2046],
  [-45.3789, 170.2059],
  [-45.3784, 170.2064],
  [-45.3768, 170.2086],
  [-45.3764, 170.2096],
  [-45.3764, 170.2104],
  [-45.3771, 170.2143],
  [-45.3769, 170.2155],
  [-45.3765, 170.2164],
  [-45.3756, 170.2172],
  [-45.3749, 170.2174],
  [-45.3699, 170.2179],
  [-45.3686, 170.2188],
  [-45.3672, 170.2212],
  [-45.3654, 170.2228],
  [-45.3601, 170.2241],
  [-45.3594, 170.2245],
  [-45.3590, 170.2252],
  [-45.3578, 170.2283],
  [-45.3573, 170.2291],
  [-45.3552, 170.2314],
  [-45.3547, 170.2324],
  [-45.3536, 170.2362],
  [-45.3530, 170.2372],
  [-45.3521, 170.2376],
  [-45.3513, 170.2374],
  [-45.3505, 170.2368],
  [-45.3498, 170.2365],
  [-45.3463, 170.2362],
  [-45.3452, 170.2365],
  [-45.3436, 170.2376],
  [-45.3425, 170.2378],
  [-45.3382, 170.2374],
  [-45.3368, 170.2377],
  [-45.3344, 170.2393],
  [-45.3335, 170.2403],
  [-45.3318, 170.2428],
  [-45.3304, 170.2436],
  [-45.3290, 170.2434],
  [-45.3279, 170.2423],
  [-45.3273, 170.2408],
  [-45.3264, 170.2399],
  [-45.3256, 170.2397],
  [-45.3245, 170.2401],
  [-45.3238, 170.2411],
  [-45.3233, 170.2424],
  [-45.3227, 170.2434],
  [-45.3218, 170.2442],
  [-45.3198, 170.2449],
  [-45.3190, 170.2448],
  [-45.3182, 170.2445],
  [-45.3172, 170.2446],
  [-45.3164, 170.2452],
  [-45.3157, 170.2464],
  [-45.3148, 170.2471],
  [-45.3138, 170.2471],
  [-45.3129, 170.2465],
  [-45.3122, 170.2456],
  [-45.3114, 170.2451],
  [-45.3104, 170.2452],
  [-45.3081, 170.2463]
];

const hydePricesCreekTunnel = [
  [-45.3081, 170.2463],
  [-45.3070, 170.2470],
  [-45.3064, 170.2477],
  [-45.3015, 170.2569],
  [-45.3005, 170.2577],
  [-45.2996, 170.2579],
  [-45.2987, 170.2576],
  [-45.2979, 170.2577],
  [-45.2971, 170.2587],
  [-45.2969, 170.2599],
  [-45.2974, 170.2616],
  [-45.2982, 170.2625],
  [-45.2986, 170.2635],
  [-45.2986, 170.2648],
  [-45.2978, 170.2676],
  [-45.2967, 170.2753],
  [-45.2960, 170.2771],
  [-45.2945, 170.2787],
  [-45.2930, 170.2794],
  [-45.2893, 170.2794],
  [-45.2884, 170.2799],
  [-45.2879, 170.2806],
  [-45.2874, 170.2815],
  [-45.2869, 170.2821],
  [-45.2862, 170.2824],
  [-45.2839, 170.2826],
  [-45.2832, 170.2822],
  [-45.2825, 170.2814],
  [-45.2817, 170.2808],
  [-45.2805, 170.2804],
  [-45.2803, 170.2803]
];

const pricesCreekTunnel = [
  [-45.2803, 170.2803],
  [-45.2797, 170.2797],
  [-45.2793, 170.2791]
];

const pricesCreekTunnelKokonga = [
  [-45.2793, 170.2791],
  [-45.2790, 170.2785],
  [-45.2783, 170.2771],
  [-45.2765, 170.2751],
  [-45.2754, 170.2744],
  [-45.2731, 170.2739],
  [-45.2697, 170.2741],
  [-45.2689, 170.2744],
  [-45.2680, 170.2751],
  [-45.2670, 170.2751],
  [-45.2662, 170.2744],
  [-45.2658, 170.2732],
  [-45.2658, 170.2718],
  [-45.2654, 170.2706],
  [-45.2647, 170.2699],
  [-45.2592, 170.2690],
  [-45.2585, 170.2692],
  [-45.2571, 170.2706],
  [-45.2563, 170.2710],
  [-45.2557, 170.2711],
  [-45.2552, 170.2713],
  [-45.2535, 170.2725],
  [-45.2521, 170.2733],
  [-45.2512, 170.2739],
  [-45.2500, 170.2753],
  [-45.2490, 170.2756],
  [-45.2484, 170.2754],
  [-45.2476, 170.2749],
  [-45.2467, 170.2747],
  [-45.2460, 170.2749],
  [-45.2451, 170.2753],
  [-45.2443, 170.2752],
  [-45.2438, 170.2747],
  [-45.2435, 170.2739],
  [-45.2435, 170.2730],
  [-45.2437, 170.2720],
  [-45.2438, 170.2710],
  [-45.2432, 170.2686],
  [-45.2432, 170.2674],
  [-45.2435, 170.2664],
  [-45.2435, 170.2657],
  [-45.2433, 170.2647],
  [-45.2424, 170.2630],
  [-45.2419, 170.2626],
  [-45.2414, 170.2625],
  [-45.2410, 170.2627],
  [-45.2406, 170.2630],
  [-45.2401, 170.2637],
  [-45.2394, 170.2641],
  [-45.2389, 170.2641],
  [-45.2383, 170.2636],
  [-45.2380, 170.2629],
  [-45.2378, 170.2622],
  [-45.2375, 170.2617],
  [-45.2370, 170.2611],
  [-45.2367, 170.2604],
  [-45.2367, 170.2596],
  [-45.2368, 170.2588],
  [-45.2367, 170.2580],
  [-45.2362, 170.2571],
  [-45.2357, 170.2568],
  [-45.2352, 170.2568],
  [-45.2346, 170.2569],
  [-45.2341, 170.2569],
  [-45.2327, 170.2568],
  [-45.2319, 170.2566],
  [-45.2314, 170.2560],
  [-45.2310, 170.2555],
  [-45.2305, 170.2547],
  [-45.2299, 170.2542],
  [-45.2270, 170.2535],
  [-45.2261, 170.2536],
  [-45.2252, 170.2543],
  [-45.2240, 170.2558],
  [-45.2192, 170.2599],
  [-45.2186, 170.2606],
  [-45.2180, 170.2615],
  [-45.2174, 170.2622],
  [-45.2141, 170.2638],
  [-45.2132, 170.2638],
  [-45.2123, 170.2632],
  [-45.2118, 170.2618],
  [-45.2118, 170.2601],
  [-45.2124, 170.2584],
  [-45.2124, 170.2570],
  [-45.2120, 170.2555],
  [-45.2092, 170.2521]
];

const kokongaRanfurly = [
  [-45.2092, 170.2521],
  [-45.2068, 170.2493],
  [-45.2061, 170.2477],
  [-45.2061, 170.2460],
  [-45.2069, 170.2427],
  [-45.2068, 170.2411],
  [-45.2051, 170.2353],
  [-45.2046, 170.2343],
  [-45.2010, 170.2297],
  [-45.2004, 170.2285],
  [-45.1997, 170.2265],
  [-45.1990, 170.2250],
  [-45.1967, 170.2215],
  [-45.1964, 170.2205],
  [-45.1965, 170.2193],
  [-45.1974, 170.2161],
  [-45.1975, 170.2145],
  [-45.1972, 170.2132],
  [-45.1968, 170.2121],
  [-45.1965, 170.2110],
  [-45.1949, 170.2014],
  [-45.1942, 170.1998],
  [-45.1929, 170.1983],
  [-45.1916, 170.1979],
  [-45.1904, 170.1980],
  [-45.1892, 170.1985],
  [-45.1877, 170.1986],
  [-45.1844, 170.1979],
  [-45.1836, 170.1973],
  [-45.1833, 170.1963],
  [-45.1832, 170.1953],
  [-45.1835, 170.1944],
  [-45.1842, 170.1935],
  [-45.1897, 170.1913],
  [-45.1915, 170.1902],
  [-45.1921, 170.1893],
  [-45.1923, 170.1877],
  [-45.1919, 170.1864],
  [-45.1913, 170.1858],
  [-45.1905, 170.1852],
  [-45.1900, 170.1845],
  [-45.1898, 170.1834],
  [-45.1898, 170.1825],
  [-45.1897, 170.1818],
  [-45.1873, 170.1735],
  [-45.1871, 170.1723],
  [-45.1872, 170.1711],
  [-45.1878, 170.1690],
  [-45.1878, 170.1675],
  [-45.1875, 170.1663],
  [-45.1869, 170.1651],
  [-45.1737, 170.1496],
  [-45.1725, 170.1486],
  [-45.1671, 170.1458],
  [-45.1664, 170.1451],
  [-45.1658, 170.1439],
  [-45.1623, 170.1318],
  [-45.1611, 170.1300],
  [-45.1286, 170.0974]
];

const ranfurlyWedderburn = [
  [-45.1286, 170.0974],
  [-45.1119, 170.0808],
  [-45.1109, 170.0792],
  [-45.1093, 170.0764],
  [-45.1083, 170.0752],
  [-45.0677, 170.0414],
  [-45.0570, 170.0301],
  [-45.0560, 170.0295],
  [-45.0538, 170.0291],
  [-45.0531, 170.0292],
  [-45.0522, 170.0297],
  [-45.0516, 170.0295],
  [-45.0509, 170.0289],
  [-45.0505, 170.0280],
  [-45.0504, 170.0271],
  [-45.0506, 170.0255],
  [-45.0504, 170.0241],
  [-45.0498, 170.0230],
  [-45.0490, 170.0222],
  [-45.0485, 170.0214],
  [-45.0483, 170.0205],
  [-45.0482, 170.0191],
  [-45.0477, 170.0181],
  [-45.0470, 170.0177],
  [-45.0461, 170.0177],
  [-45.0456, 170.0175],
  [-45.0451, 170.0168],
  [-45.0432, 170.0127],
  [-45.0421, 170.0114],
  [-45.0406, 170.0107],
  [-45.0301, 170.0108]
];

const wedderburnIdaValley = [
  [-45.0301, 170.0108],
  [-45.0292, 170.0107],
  [-45.0279, 170.0102],
  [-45.0266, 170.0086],
  [-45.0260, 170.0069],
  [-45.0259, 170.0051],
  [-45.0260, 170.0033],
  [-45.0260, 170.0025],
  [-45.0256, 170.0012],
  [-45.0249, 170.0001],
  [-45.0240, 169.9992],
  [-45.0235, 169.9983],
  [-45.0233, 169.9972],
  [-45.0226, 169.9863],
  [-45.0223, 169.9854],
  [-45.0216, 169.9847],
  [-45.0211, 169.9845],
  [-45.0205, 169.9846],
  [-45.0190, 169.9854],
  [-45.0175, 169.9855],
  [-45.0163, 169.9851],
  [-45.0153, 169.9851],
  [-45.0145, 169.9856],
  [-45.0139, 169.9862],
  [-45.0133, 169.9865],
  [-45.0108, 169.9874],
  [-45.0093, 169.9873],
  [-45.0076, 169.9864],
  [-45.0069, 169.9862],
  [-45.0060, 169.9863],
  [-45.0007, 169.9883],
  [-45.0001, 169.9882],
  [-44.9993, 169.9876],
  [-44.9986, 169.9869],
  [-44.9973, 169.9862],
  [-44.9965, 169.9847],
  [-44.9941, 169.9818],
  [-44.9931, 169.9793],
  [-44.9928, 169.9767],
  [-44.9933, 169.9711],
  [-44.9933, 169.9696],
  [-44.9926, 169.9643],
  [-44.9926, 169.9622],
  [-44.9933, 169.9560],
  [-44.9933, 169.9540],
  [-44.9926, 169.9487],
  [-44.9927, 169.9457],
  [-44.9961, 169.9312],
  [-44.9980, 169.9268],
  [-45.0153, 169.9029],
  [-45.0264, 169.8849],
  [-45.0273, 169.8821],
  [-45.0359, 169.8266]
];

const idaValleyPoolburnNo1Tunnel = [
  [-45.0359, 169.8266],
  [-45.0382, 169.8115],
  [-45.0391, 169.8081],
  [-45.0474, 169.7864],
  [-45.0476, 169.7849],
  [-45.0474, 169.7796],
  [-45.0477, 169.7770],
  [-45.0485, 169.7747],
  [-45.0514, 169.7685],
  [-45.0520, 169.7678],
  [-45.0528, 169.7672],
  [-45.0534, 169.7665],
  [-45.0540, 169.7654],
  [-45.0545, 169.7649],
  [-45.0552, 169.7646],
  [-45.0566, 169.7647],
  [-45.0576, 169.7643],
  [-45.0594, 169.7629],
  [-45.0603, 169.7624],
  [-45.0610, 169.7624],
  [-45.0618, 169.7619],
  [-45.0625, 169.7609],
  [-45.0631, 169.7597],
  [-45.0643, 169.7543],
  [-45.0643, 169.7536],
  [-45.0642, 169.7526],
  [-45.0643, 169.7517],
  [-45.0644, 169.7510],
  [-45.0645, 169.7499],
  [-45.0641, 169.7475],
  [-45.0639, 169.7470],
  [-45.0636, 169.7465],
  [-45.0634, 169.7460],
  [-45.0631, 169.7449],
  [-45.0629, 169.7435],
  [-45.0621, 169.7420]
];

const poolburnNo1Tunnel = [
  [-45.0621, 169.7420],
  [-45.0605, 169.7407]
];

const poolburnNo1TunnelPoolburnNo2Tunnel = [
  [-45.0605, 169.7407],
  [-45.0593, 169.7398],
  [-45.0588, 169.7398],
  [-45.0579, 169.7400],
  [-45.0573, 169.7398],
  [-45.0567, 169.7389],
  [-45.0565, 169.7383],
  [-45.0560, 169.7375],
  [-45.0556, 169.7372]
];

const poolburnNo2Tunnel = [
  [-45.0556, 169.7372],
  [-45.0541, 169.7361],
  [-45.0538, 169.7357]
];

const poolburnNo2TunnelOmakau = [
  [-45.0538, 169.7357],
  [-45.0536, 169.7352],
  [-45.0532, 169.7316],
  [-45.0528, 169.7308],
  [-45.0521, 169.7304],
  [-45.0514, 169.7304],
  [-45.0509, 169.7301],
  [-45.0504, 169.7295],
  [-45.0499, 169.7280],
  [-45.0494, 169.7274],
  [-45.0486, 169.7269],
  [-45.0481, 169.7260],
  [-45.0480, 169.7247],
  [-45.0483, 169.7234],
  [-45.0489, 169.7187],
  [-45.0488, 169.7172],
  [-45.0485, 169.7159],
  [-45.0484, 169.7144],
  [-45.0487, 169.7105],
  [-45.0486, 169.7093],
  [-45.0483, 169.7083],
  [-45.0483, 169.7074],
  [-45.0484, 169.7063],
  [-45.0493, 169.7043],
  [-45.0494, 169.7037],
  [-45.0493, 169.7028],
  [-45.0487, 169.7012],
  [-45.0484, 169.6999],
  [-45.0477, 169.6884],
  [-45.0479, 169.6869],
  [-45.0511, 169.6757],
  [-45.0595, 169.6582],
  [-45.0747, 169.6336],
  [-45.0773, 169.6310],
  [-45.0835, 169.6207],
  [-45.0923, 169.6021]
];

const omakauChattoCreek = [
  [-45.0923, 169.6021],
  [-45.0973, 169.5915],
  [-45.0980, 169.5893],
  [-45.0999, 169.5799],
  [-45.1001, 169.5783],
  [-45.1004, 169.5708],
  [-45.1010, 169.5687],
  [-45.1124, 169.5448],
  [-45.1130, 169.5441],
  [-45.1137, 169.5436],
  [-45.1143, 169.5429],
  [-45.1165, 169.5396],
  [-45.1169, 169.5387],
  [-45.1170, 169.5376],
  [-45.1174, 169.5367],
  [-45.1181, 169.5362],
  [-45.1189, 169.5363],
  [-45.1203, 169.5374],
  [-45.1210, 169.5377],
  [-45.1215, 169.5377],
  [-45.1248, 169.5368],
  [-45.1269, 169.5365],
  [-45.1277, 169.5356],
  [-45.1278, 169.5343],
  [-45.1273, 169.5331],
  [-45.1264, 169.5327],
  [-45.1249, 169.5329],
  [-45.1240, 169.5324],
  [-45.1235, 169.5313],
  [-45.1234, 169.5303],
  [-45.1226, 169.5272],
  [-45.1220, 169.5260],
  [-45.1216, 169.5244],
  [-45.1217, 169.5230],
  [-45.1226, 169.5193],
  [-45.1228, 169.5179],
  [-45.1225, 169.5164],
  [-45.1202, 169.5113],
  [-45.1199, 169.5102],
  [-45.1202, 169.5087],
  [-45.1209, 169.5077],
  [-45.1219, 169.5074],
  [-45.1321, 169.5111],
  [-45.1338, 169.5114],
  [-45.1409, 169.5103]
];

const chattoCreekAlexandra = [
  [-45.1409, 169.5103],
  [-45.1450, 169.5098],
  [-45.1459, 169.5093],
  [-45.1465, 169.5090],
  [-45.1489, 169.5089],
  [-45.1502, 169.5086],
  [-45.1512, 169.5080],
  [-45.1521, 169.5076],
  [-45.1534, 169.5076],
  [-45.1544, 169.5073],
  [-45.1561, 169.5062],
  [-45.1568, 169.5059],
  [-45.1575, 169.5058],
  [-45.1581, 169.5054],
  [-45.1592, 169.5043],
  [-45.1599, 169.5039],
  [-45.1605, 169.5040],
  [-45.1611, 169.5038],
  [-45.1620, 169.5036],
  [-45.1636, 169.5040],
  [-45.1646, 169.5040],
  [-45.1667, 169.5032],
  [-45.1671, 169.5027],
  [-45.1679, 169.5024],
  [-45.1685, 169.5025],
  [-45.1704, 169.5038],
  [-45.1710, 169.5040],
  [-45.1725, 169.5041],
  [-45.1731, 169.5038],
  [-45.1735, 169.5031],
  [-45.1749, 169.4992],
  [-45.1757, 169.4979],
  [-45.1763, 169.4972],
  [-45.1771, 169.4961],
  [-45.1792, 169.4912],
  [-45.1802, 169.4895],
  [-45.1820, 169.4879],
  [-45.1846, 169.4871],
  [-45.1857, 169.4864],
  [-45.1886, 169.4833],
  [-45.1894, 169.4821],
  [-45.1901, 169.4809],
  [-45.1910, 169.4794],
  [-45.1925, 169.4776],
  [-45.1932, 169.4763],
  [-45.1937, 169.4751],
  [-45.1941, 169.4746],
  [-45.1947, 169.4741],
  [-45.1977, 169.4730],
  [-45.1993, 169.4719],
  [-45.2315, 169.4339],
  [-45.2375, 169.4285],
  [-45.2381, 169.4276],
  [-45.2392, 169.4247],
  [-45.2397, 169.4222],
  [-45.2403, 169.4204],
  [-45.2441, 169.4128],
  [-45.2448, 169.4119],
  [-45.2455, 169.4113],
  [-45.2461, 169.4103],
  [-45.2465, 169.4087],
  [-45.2472, 169.4075],
  [-45.2485, 169.4064],
  [-45.2495, 169.4051],
  [-45.2500, 169.4041],
  [-45.2502, 169.4030],
  [-45.2498, 169.4007],
  [-45.2492, 169.3990],
  [-45.2459, 169.3952]
];

const alexandraClyde = [
  [-45.2459, 169.3952],
  [-45.2224, 169.3691],
  [-45.2216, 169.3681],
  [-45.2071, 169.3499],
  [-45.1985, 169.3379],
  [-45.1956, 169.3351],
  [-45.1939, 169.3342]
];

export const otagoCentral: Line = {
  name: 'Otago Central',
  state: 'NZ',
  segments: [
    {
      segments: [
        wingatuiTunnelNo1,
        {
          name: 'Tunnel No 1',
          segment: tunnelNo1,
          type: 'tunnel'
        },
        tunnelNo1SalisburyTunnel,
        {
          name: 'Salisbury Tunnel',
          segment: salisburyTunnel,
          type: 'tunnel'
        },
        salisburyTunnelTunnelNo3,
        {
          name: 'Tunnel No 3',
          segment: tunnelNo3,
          type: 'tunnel'
        },
        tunnelNo3TunnelNo4,
        {
          name: 'Tunnel No 4',
          segment: tunnelNo4,
          type: 'tunnel'
        },
        tunnelNo4TunnelNo5,
        {
          name: 'Tunnel No 5',
          segment: tunnelNo5,
          type: 'tunnel'
        },
        tunnelNo5TunnelNo6,
        {
          name: 'Tunnel No 6',
          segment: tunnelNo6,
          type: 'tunnel'
        },
        tunnelNo6Hindon
      ],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        hindonTunnelNo7,
        {
          name: 'Tunnel No 7',
          segment: tunnelNo7,
          type: 'tunnel'
        },
        tunnelNo7TunnelNo8,
        {
          name: 'Tunnel No 8',
          segment: tunnelNo8,
          type: 'tunnel'
        },
        tunnelNo8TunnelNo9,
        {
          name: 'Tunnel No 9',
          segment: tunnelNo9,
          type: 'tunnel'
        },
        tunnelNo9TunnelNo10,
        {
          name: 'Tunnel No 10',
          segment: tunnelNo10,
          type: 'tunnel'
        },
        tunnelNo10Middlemarch
      ],
      history: {
        opened: {
          date: '1891-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [middlemarchHyde],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        hydePricesCreekTunnel,
        {
          name: 'Prices Creek Tunnel',
          segment: pricesCreekTunnel,
          type: 'tunnel'
        },
        pricesCreekTunnelKokonga
      ],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kokongaRanfurly],
      history: {
        opened: {
          date: '1898-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ranfurlyWedderburn],
      history: {
        opened: {
          date: '1900-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wedderburnIdaValley],
      history: {
        opened: {
          date: '1901-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        idaValleyPoolburnNo1Tunnel,
        {
          name: 'Poolburn No 1 Tunnel',
          segment: poolburnNo1Tunnel,
          type: 'tunnel'
        },
        poolburnNo1TunnelPoolburnNo2Tunnel,
        {
          name: 'Poolburn No 2 Tunnel',
          segment: poolburnNo2Tunnel,
          type: 'tunnel'
        },
        poolburnNo2TunnelOmakau
      ],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [omakauChattoCreek],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chattoCreekAlexandra],
      history: {
        opened: {
          date: '1906-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [alexandraClyde],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-04-30',
          status: 'closed'
        }]
      }
    }
  ]
}
