import { Line } from "../../trackTypes";

const line = [
  [-33.8702, 151.1986],
  [-33.8709, 151.2027],
  [-33.8709, 151.2048],
  [-33.8709, 151.2060],
  [-33.8707, 151.2066],
  [-33.8707, 151.2080],
  [-33.8708, 151.2082],
  [-33.8744, 151.2080],
  [-33.8764, 151.2078],
  [-33.8766, 151.2076],
  [-33.8760, 151.2038],
  [-33.8762, 151.2035],
  [-33.8774, 151.2033],
  [-33.8785, 151.2033],
  [-33.8788, 151.2032],
  [-33.8791, 151.2025],
  [-33.8789, 151.2012],
  [-33.8787, 151.2009],
  [-33.8779, 151.2003],
  [-33.8777, 151.2001],
  [-33.8746, 151.1984],
  [-33.8731, 151.1982],
  [-33.8724, 151.1981],
  [-33.8720, 151.1984],
  [-33.8708, 151.1984],
  [-33.8705, 151.1983],
  [-33.8702, 151.1986]
];

export const sydneyMonorail: Line = {
  name: 'Sydney Monorail',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1988-07-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'monorail'
        },
        trackChange: [{
          date: '2013-06-30',
          status: 'closed'
        }]
      }
    }
  ]
};
