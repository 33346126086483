import { Line } from "../../../trackTypes";

const munbillaKalbar = [
  [-27.8746, 152.6579],
  [-27.8754, 152.6579],
  [-27.8760, 152.6573],
  [-27.8772, 152.6561],
  [-27.8779, 152.6557],
  [-27.8787, 152.6557],
  [-27.8821, 152.6567],
  [-27.8835, 152.6566],
  [-27.8844, 152.6563],
  [-27.8964, 152.6478],
  [-27.8984, 152.6456],
  [-27.9003, 152.6415],
  [-27.9007, 152.6389],
  [-27.9013, 152.6373],
  [-27.9026, 152.6359],
  [-27.9029, 152.6353],
  [-27.9034, 152.6324],
  [-27.9038, 152.6315],
  [-27.9052, 152.6296],
  [-27.9061, 152.6285],
  [-27.9074, 152.6278],
  [-27.9095, 152.6277],
  [-27.9109, 152.6280],
  [-27.9125, 152.6284],
  [-27.9136, 152.6286],
  [-27.9142, 152.6284],
  [-27.9154, 152.6275],
  [-27.9159, 152.6267],
  [-27.9172, 152.6238],
  [-27.9182, 152.6227],
  [-27.9195, 152.6219],
  [-27.9242, 152.6208],
  [-27.9260, 152.6200],
  [-27.9269, 152.6193],
  [-27.9292, 152.6193],
  [-27.9347, 152.6215],
  [-27.9367, 152.6216],
  [-27.9417, 152.6207],
];

const kalbarMtEdwards = [
  [-27.9417, 152.6207],
  [-27.9424, 152.6200],
  [-27.9428, 152.6188],
  [-27.9427, 152.6123],
  [-27.9431, 152.6109],
  [-27.9442, 152.6097],
  [-27.9493, 152.6082],
  [-27.9507, 152.6085],
  [-27.9524, 152.6098],
  [-27.9539, 152.6103],
  [-27.9601, 152.6110],
  [-27.9632, 152.6106],
  [-27.9679, 152.6086],
  [-27.9692, 152.6067],
  [-27.9757, 152.5924],
  [-27.9759, 152.5905],
  [-27.9719, 152.5830],
  [-27.9716, 152.5818],
  [-27.9718, 152.5804],
  [-27.9739, 152.5758],
  [-27.9747, 152.5745],
  [-27.9788, 152.5717],
  [-27.9802, 152.5696],
  [-27.9817, 152.5623],
  [-27.9815, 152.5601],
  [-27.9809, 152.5583],
  [-27.9812, 152.5562],
  [-27.9823, 152.5543],
  [-27.9879, 152.5490],
  [-27.9894, 152.5486],
  [-27.9903, 152.5481],
  [-27.9917, 152.5467],
  [-27.9930, 152.5462],
  [-27.9942, 152.5461],
  [-27.9951, 152.5454],
  [-27.9954, 152.5442],
  [-27.9949, 152.5414],
  [-27.9949, 152.5406],
  [-27.9952, 152.5399],
  [-27.9979, 152.5366],
  [-27.9983, 152.5355],
  [-27.9987, 152.5334],
  [-27.9992, 152.5321],
  [-28.0000, 152.5315],
  [-28.0009, 152.5314],
  [-28.0019, 152.5315],
  [-28.0026, 152.5312],
  [-28.0030, 152.5307],
  [-28.0045, 152.5284],
  [-28.0055, 152.5275],
  [-28.0073, 152.5273],
  [-28.0086, 152.5275],
  [-28.0097, 152.5272],
  [-28.0108, 152.5267],
  [-28.0117, 152.5266],
  [-28.0135, 152.5267],
  [-28.0147, 152.5262],
  [-28.0184, 152.5216],
  [-28.0197, 152.5186]
];

export const mtEdwards: Line = {
  name: 'Mt Edwards',
  state: 'QLD',
  segments: [
    {
      segments: [munbillaKalbar],
      history: {
        opened: {
          date: '1916-04-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kalbarMtEdwards],
      history: {
        opened: {
          date: '1922-10-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-11-01',
          status: 'closed'
        }]
      }
    }
  ]
}
