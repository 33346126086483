import { Line } from "../../trackTypes";

const flindersStreetPrincesBridge = [
  [-37.8182, 144.9671],
  [-37.8179, 144.9681]
];

const princesBridgeRichmond = [
  [-37.8179, 144.9681],
  [-37.8168, 144.9718],
  [-37.8167, 144.9738],
  [-37.8172, 144.9754],
  [-37.8177, 144.9764],
  [-37.8179, 144.9768],
  [-37.8203, 144.9811],
  [-37.8217, 144.9831],
  [-37.8230, 144.9864],
  [-37.8241, 144.9906],
  [-37.8246, 144.9917],
  [-37.8254, 144.9926]
];

const richmondCremorne = [
  [-37.8254, 144.9926],
  [-37.8262, 144.9934],
  [-37.8274, 144.9940],
  [-37.8284, 144.9941],
  [-37.8300, 144.9940]
];

const cremorneSouthYarra = [
  [-37.8300, 144.9940],
  [-37.8381, 144.9923]
];

const southYarraWindsor = [
  [-37.8381, 144.9923],
  [-37.8511, 144.9895],
  [-37.8521, 144.9895],
  [-37.8528, 144.9896],
  [-37.8536, 144.9899],
  [-37.8543, 144.9903],
  [-37.8553, 144.9911]
];

const windsorNorthBrighton = [
  [-37.8553, 144.9911],
  [-37.8559, 144.9918],
  [-37.8565, 144.9926],
  [-37.8569, 144.9930],
  [-37.8576, 144.9935],
  [-37.8584, 144.9941],
  [-37.8590, 144.9945],
  [-37.8597, 144.9947],
  [-37.8605, 144.9949],
  [-37.8613, 144.9949],
  [-37.8636, 144.9946],
  [-37.8703, 144.9933],
  [-37.8710, 144.9932],
  [-37.8715, 144.9933],
  [-37.8724, 144.9935],
  [-37.8730, 144.9937],
  [-37.8791, 144.9968],
  [-37.8802, 144.9975],
  [-37.8864, 145.0021],
  [-37.8884, 145.0035],
  [-37.8889, 145.0038],
  [-37.8894, 145.0040],
  [-37.8908, 145.0044],
  [-37.8918, 145.0046],
  [-37.8930, 145.0047],
  [-37.8938, 145.0046],
  [-37.8957, 145.0043],
  [-37.9046, 145.0026]
];

const northBrightonBrightonBeach = [
  [-37.9046, 145.0026],
  [-37.9078, 145.0020],
  [-37.9088, 145.0018],
  [-37.9093, 145.0016],
  [-37.9100, 145.0013],
  [-37.9107, 145.0009],
  [-37.9113, 145.0005],
  [-37.9119, 145.0000],
  [-37.9122, 144.9997],
  [-37.9153, 144.9960],
  [-37.9158, 144.9955],
  [-37.9167, 144.9948],
  [-37.9173, 144.9944],
  [-37.9249, 144.9898],
  [-37.9258, 144.9893],
  [-37.9262, 144.9892],
  [-37.9265, 144.9892]
];

const brightonBeachSandringham = [
  [-37.9265, 144.9892],
  [-37.9269, 144.9892],
  [-37.9275, 144.9893],
  [-37.9281, 144.9896],
  [-37.9286, 144.9901],
  [-37.9303, 144.9919],
  [-37.9321, 144.9936],
  [-37.9325, 144.9940],
  [-37.9329, 144.9946],
  [-37.9338, 144.9965],
  [-37.9341, 144.9969],
  [-37.9345, 144.9975],
  [-37.9405, 145.0041],
  [-37.9409, 145.0045],
  [-37.9416, 145.0050],
  [-37.9423, 145.0053],
  [-37.9428, 145.0055],
  [-37.9436, 145.0056],
  [-37.9444, 145.0056],
  [-37.9503, 145.0045]
];

export const sandringham: Line = {
  name: 'Sandringham',
  state: 'VIC',
  segments: [
    {
      segments: [flindersStreetPrincesBridge],
      history: {
        opened: {
          date: '1865-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1861-01-01',
          tracks: 2
        }, {
          date: '1883-10-03',
          tracks: 6
        }, {
          date: '1915-10-03',
          tracks: 8
        }, {
          date: '1882-12-01',
          tracks: 4
        }, {
          date: '1966-12-04',
          tracks: 10
        }, {
          date: '1998-01-01',
          tracks: 12
        }, {
          date: '1919-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [princesBridgeRichmond],
      history: {
        opened: {
          date: '1859-02-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1861-01-01',
          tracks: 2
        }, {
          date: '1883-10-03',
          tracks: 6
        }, {
          date: '1915-10-03',
          tracks: 8
        }, {
          date: '1882-12-01',
          tracks: 4
        }, {
          date: '1966-12-04',
          tracks: 10
        }, {
          date: '1998-01-01',
          tracks: 12
        }, {
          date: '1919-06-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [richmondCremorne],
      history: {
        opened: {
          date: '1859-12-12',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1879-04-02',
          tracks: 2
        }, {
          date: '1883-10-03',
          tracks: 4
        }, {
          date: '1915-10-03',
          tracks: 6
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [cremorneSouthYarra],
      history: {
        opened: {
          date: '1860-12-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1879-04-02',
          tracks: 2
        }, {
          date: '1883-10-03',
          tracks: 4
        }, {
          date: '1915-10-03',
          tracks: 6
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [southYarraWindsor],
      history: {
        opened: {
          date: '1860-12-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1861-01-01',
          tracks: 2
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [windsorNorthBrighton],
      history: {
        opened: {
          date: '1859-12-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1882-09-18',
          tracks: 2
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [northBrightonBrightonBeach],
      history: {
        opened: {
          date: '1861-12-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1882-09-18',
          tracks: 2
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [brightonBeachSandringham],
      history: {
        opened: {
          date: '1887-09-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-12-23',
          tracks: 2
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
