import { Line } from "../../../trackTypes";

const seymourMangalore = [
  [-37.0243, 145.1384],
  [-37.0234, 145.1406],
  [-37.0227, 145.1420],
  [-37.0211, 145.1444],
  [-37.0191, 145.1465],
  [-37.0164, 145.1484],
  [-37.0147, 145.1491],
  [-36.9385, 145.1754],
  [-36.9361, 145.1766],
];

const mangaloreShepparton = [
  [-36.9361, 145.1766],
  [-36.9349, 145.1767],
  [-36.9335, 145.1766],
  [-36.9050, 145.1691],
  [-36.8735, 145.1609],
  [-36.8714, 145.1605],
  [-36.8695, 145.1603],
  [-36.7836, 145.1604],
  [-36.7823, 145.1605],
  [-36.7818, 145.1606],
  [-36.7806, 145.1611],
  [-36.7799, 145.1616],
  [-36.6812, 145.2390],
  [-36.6804, 145.2396],
  [-36.6795, 145.2400],
  [-36.6786, 145.2403],
  [-36.6772, 145.2405],
  [-36.6755, 145.2405],
  [-36.6113, 145.2407],
  [-36.6103, 145.2408],
  [-36.6092, 145.2415],
  [-36.6083, 145.2425],
  [-36.6077, 145.2440],
  [-36.5992, 145.2941],
  [-36.5986, 145.2962],
  [-36.5975, 145.2985],
  [-36.5963, 145.3001],
  [-36.5607, 145.3365],
  [-36.5582, 145.3384],
  [-36.5549, 145.3399],
  [-36.5514, 145.3407],
  [-36.5348, 145.3436],
  [-36.5323, 145.3439],
  [-36.5291, 145.3436],
  [-36.5017, 145.3400],
  [-36.4895, 145.3383],
  [-36.4849, 145.3376],
  [-36.4783, 145.3367],
  [-36.4740, 145.3365],
  [-36.4328, 145.3365],
  [-36.4300, 145.3369],
  [-36.4272, 145.3381],
  [-36.4012, 145.3527],
  [-36.4000, 145.3536],
  [-36.3994, 145.3546],
  [-36.3992, 145.3557],
  [-36.3984, 145.3655],
  [-36.3970, 145.3833],
  [-36.3968, 145.3843],
  [-36.3967, 145.3850],
  [-36.3931, 145.3947],
  [-36.3925, 145.3960],
  [-36.3915, 145.3974],
  [-36.3837, 145.4059]
];

const sheppartonNumurkah = [
  [-36.3837, 145.4059],
  [-36.3629, 145.4285],
  [-36.3618, 145.4294],
  [-36.3605, 145.4302],
  [-36.3593, 145.4309],
  [-36.3582, 145.4313],
  [-36.3560, 145.4318],
  [-36.3548, 145.4319],
  [-36.3534, 145.4320],
  [-36.3062, 145.4319],
  [-36.3051, 145.4321],
  [-36.3039, 145.4325],
  [-36.3031, 145.4326],
  [-36.3024, 145.4325],
  [-36.2986, 145.4320],
  [-36.2102, 145.4320],
  [-36.2093, 145.4319],
  [-36.1672, 145.4319],
  [-36.1655, 145.4321],
  [-36.1642, 145.4324],
  [-36.1633, 145.4327],
  [-36.1618, 145.4333],
  [-36.1551, 145.4350],
  [-36.1517, 145.4350],
  [-36.1510, 145.4349],
  [-36.1496, 145.4344],
  [-36.1455, 145.4327],
  [-36.1440, 145.4323],
  [-36.1423, 145.4320],
  [-36.1413, 145.4320],
  [-36.1017, 145.4319],
  [-36.1010, 145.4320],
  [-36.1004, 145.4321],
  [-36.0996, 145.4323],
  [-36.0988, 145.4327],
  [-36.0983, 145.4329],
  [-36.0924, 145.4374]
];

const numurkahStrathmerton = [
  [-36.0924, 145.4374],
  [-36.0895, 145.4396],
  [-36.0872, 145.4406],
  [-36.0859, 145.4412],
  [-36.0848, 145.4420],
  [-36.0841, 145.4426],
  [-36.0822, 145.4441],
  [-36.0813, 145.4447],
  [-36.0803, 145.4453],
  [-36.0469, 145.4594],
  [-36.0458, 145.4597],
  [-36.0446, 145.4599],
  [-36.0131, 145.4600],
  [-36.0117, 145.4598],
  [-36.0105, 145.4596],
  [-36.0054, 145.4582],
  [-36.0041, 145.4580],
  [-35.9984, 145.4580],
  [-35.9976, 145.4581],
  [-35.9970, 145.4583],
  [-35.9937, 145.4594],
  [-35.9925, 145.4598],
  [-35.9908, 145.4600],
  [-35.9477, 145.4600],
  [-35.9472, 145.4600],
  [-35.9465, 145.4602],
  [-35.9457, 145.4605],
  [-35.9448, 145.4609],
  [-35.9297, 145.4719],
  [-35.9289, 145.4726],
  [-35.9282, 145.4735],
  [-35.9275, 145.4746],
  [-35.9268, 145.4764],
  [-35.9265, 145.4778],
  [-35.9263, 145.4790],
  [-35.9263, 145.4804],
  [-35.9263, 145.4811]
];

const strathmertonNswBorder = [
  [-35.9263, 145.4811],
  [-35.9263, 145.4833],
  [-35.9257, 145.4876],
  [-35.9243, 145.4909],
  [-35.9224, 145.4928],
  [-35.9207, 145.4937],
  [-35.9190, 145.4940],
  [-35.8666, 145.4940],
  [-35.8646, 145.4942],
  [-35.8631, 145.4949],
  [-35.8620, 145.4958],
  [-35.8531, 145.5046],
  [-35.8520, 145.5061],
  [-35.8512, 145.5074],
  [-35.8482, 145.5148],
  [-35.8467, 145.5172],
  [-35.8346, 145.5312],
  [-35.8174, 145.5555],
  [-35.8162, 145.5565],
  [-35.8153, 145.5567],
  [-35.8149, 145.5568],
  [-35.8145, 145.5567],
  [-35.8143, 145.5567]
];

export const tocumwal: Line = {
  name: 'Tocumwal',
  state: 'VIC',
  segments: [
    {
      segments: [sheppartonNumurkah],
      history: {
        opened: {
          date: '1881-09-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
      }
    },
    {
      segments: [numurkahStrathmerton],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [strathmertonNswBorder],
      history: {
        opened: {
          date: '1905-02-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [seymourMangalore],
      history: {
        opened: {
          date: '1872-11-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-07-25',
          tracks: 1
        }, {
          date: '1889-09-23',
          tracks: 2
        }, {
          date: '1962-01-03',
          parallelStandard: true
        }]
      }
    },
    {
      segments: [mangaloreShepparton],
      history: {
        opened: {
          date: '1880-01-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    }
  ]
};
