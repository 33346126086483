import { Line } from "../../../trackTypes";

const unanderraDombarton = [
  [-34.4595, 150.8414],
  [-34.4603, 150.8404],
  [-34.4618, 150.8373],
  [-34.4621, 150.8361],
  [-34.4623, 150.8341],
  [-34.4625, 150.8329],
  [-34.4635, 150.8304],
  [-34.4636, 150.8285],
  [-34.4629, 150.8265],
  [-34.4619, 150.8251],
  [-34.4612, 150.8227],
  [-34.4612, 150.8217],
  [-34.4615, 150.8204],
  [-34.4616, 150.8198],
  [-34.4615, 150.8186],
  [-34.4616, 150.8177],
  [-34.4619, 150.8167],
  [-34.4620, 150.8158],
  [-34.4618, 150.8150],
  [-34.4582, 150.8088],
  [-34.4577, 150.8083],
  [-34.4564, 150.8075],
  [-34.4557, 150.8065],
  [-34.4553, 150.8048],
  [-34.4548, 150.8042],
  [-34.4542, 150.8037],
  [-34.4530, 150.8034],
  [-34.4524, 150.8029],
  [-34.4509, 150.8004],
  [-34.4505, 150.7986],
  [-34.4498, 150.7977],
  [-34.4488, 150.7970],
  [-34.4484, 150.7967],
  [-34.4474, 150.7955],
  [-34.4465, 150.7951],
  [-34.4455, 150.7951],
  [-34.4447, 150.7949],
  [-34.4430, 150.7931],
  [-34.4427, 150.7923],
  [-34.4427, 150.7911],
  [-34.4440, 150.7880],
  [-34.4445, 150.7873],
  [-34.4458, 150.7861],
  [-34.4472, 150.7853],
  [-34.4479, 150.7845],
  [-34.4482, 150.7833],
  [-34.4480, 150.7822],
  [-34.4480, 150.7814],
  [-34.4484, 150.7788],
  [-34.4487, 150.7780],
  [-34.4495, 150.7766],
  [-34.4505, 150.7720]
];

const dombartonRockfallShelter = [
  [-34.4505, 150.7720],
  [-34.4505, 150.7693],
  [-34.4501, 150.7682],
  [-34.4494, 150.7671],
  [-34.4492, 150.7660],
  [-34.4500, 150.7596],
  [-34.4505, 150.7586],
  [-34.4510, 150.7581],
  [-34.4519, 150.7576],
  [-34.4532, 150.7559],
  [-34.4542, 150.7554],
  [-34.4549, 150.7547],
  [-34.4553, 150.7540],
  [-34.4553, 150.7537]
];

const rockfallShelter = [
  [-34.4553, 150.7537],
  [-34.4553, 150.7528]
];

const rockfallShelterIllawarraNo1Tunnel = [
  [-34.4553, 150.7528],
  [-34.4554, 150.7514],
  [-34.4556, 150.7510],
  [-34.4558, 150.7509]
];

const illawarraNo1Tunnel = [
  [-34.4558, 150.7509],
  [-34.4567, 150.7505]
];

const illawarraNo1TunnelIllawarraNo2Tunnel = [
  [-34.4567, 150.7505],
  [-34.4594, 150.7491],
  [-34.4606, 150.7483],
  [-34.4612, 150.7477],
  [-34.4615, 150.7467],
  [-34.4614, 150.7453],
  [-34.4615, 150.7445],
  [-34.4619, 150.7436],
  [-34.4654, 150.7405],
  [-34.4668, 150.7387],
  [-34.4683, 150.7373],
  [-34.4696, 150.7359],
  [-34.4712, 150.7352],
  [-34.4730, 150.7331],
  [-34.4743, 150.7312],
  [-34.4750, 150.7304],
  [-34.4764, 150.7298],
  [-34.4771, 150.7292],
  [-34.4777, 150.7285]
];

const illawarraNo2Tunnel = [
  [-34.4777, 150.7285],
  [-34.4784, 150.7269],
  [-34.4801, 150.7230]
];

const tillawarraNoTunnelWingecarribee = [
  [-34.4801, 150.7230],
  [-34.4803, 150.7213],
  [-34.4799, 150.7189],
  [-34.4799, 150.7179],
  [-34.4804, 150.7167],
  [-34.4816, 150.7160],
  [-34.4825, 150.7162],
  [-34.4838, 150.7174],
  [-34.4844, 150.7177],
  [-34.4850, 150.7178],
  [-34.4860, 150.7172],
  [-34.4866, 150.7159],
  [-34.4865, 150.7148],
  [-34.4857, 150.7133],
  [-34.4855, 150.7125],
  [-34.4856, 150.7115],
  [-34.4862, 150.7105],
  [-34.4878, 150.7096],
  [-34.4891, 150.7082],
  [-34.4920, 150.7063],
  [-34.4928, 150.7061],
  [-34.4956, 150.7064],
  [-34.4968, 150.7063],
  [-34.5017, 150.7042],
  [-34.5030, 150.7041],
  [-34.5042, 150.7044],
  [-34.5057, 150.7044],
  [-34.5071, 150.7039],
  [-34.5071, 150.7039],
  [-34.5078, 150.7038],
  [-34.5081, 150.7039],
  [-34.5098, 150.7049],
  [-34.5133, 150.7062],
  [-34.5145, 150.7064],
  [-34.5158, 150.7062],
  [-34.5171, 150.7061],
  [-34.5193, 150.7066],
  [-34.5199, 150.7066],
  [-34.5204, 150.7065],
  [-34.5223, 150.7050],
  [-34.5229, 150.7043],
  [-34.5236, 150.7030],
  [-34.5244, 150.7024],
  [-34.5254, 150.7021],
  [-34.5262, 150.7016],
  [-34.5271, 150.7007],
  [-34.5279, 150.7003],
  [-34.5291, 150.7003],
  [-34.5298, 150.7001],
  [-34.5301, 150.6999],
  [-34.5304, 150.6996],
  [-34.5307, 150.6990],
  [-34.5316, 150.6948],
  [-34.5320, 150.6939],
  [-34.5329, 150.6930],
  [-34.5336, 150.6919],
  [-34.5343, 150.6898],
  [-34.5349, 150.6891],
  [-34.5353, 150.6888],
  [-34.5368, 150.6883],
  [-34.5375, 150.6877],
  [-34.5382, 150.6860],
  [-34.5383, 150.6850],
  [-34.5381, 150.6843],
  [-34.5374, 150.6835],
  [-34.5360, 150.6826],
  [-34.5354, 150.6820],
  [-34.5351, 150.6810],
  [-34.5352, 150.6798],
  [-34.5360, 150.6788],
  [-34.5369, 150.6782],
  [-34.5374, 150.6776],
  [-34.5377, 150.6764],
  [-34.5375, 150.6753],
  [-34.5370, 150.6746],
  [-34.5358, 150.6738],
  [-34.5354, 150.6735],
  [-34.5343, 150.6715],
  [-34.5340, 150.6706],
  [-34.5340, 150.6697],
  [-34.5344, 150.6687],
  [-34.5351, 150.6681],
  [-34.5356, 150.6680],
  [-34.5366, 150.6679],
  [-34.5374, 150.6678],
  [-34.5380, 150.6673],
  [-34.5385, 150.6663],
  [-34.5385, 150.6650],
  [-34.5379, 150.6641],
  [-34.5372, 150.6636],
  [-34.5363, 150.6633],
  [-34.5358, 150.6631],
  [-34.5353, 150.6624],
  [-34.5341, 150.6601],
  [-34.5340, 150.6593],
  [-34.5341, 150.6584],
  [-34.5345, 150.6576],
  [-34.5352, 150.6571],
  [-34.5363, 150.6568],
  [-34.5377, 150.6562],
  [-34.5389, 150.6545],
  [-34.5393, 150.6527],
  [-34.5390, 150.6484],
  [-34.5392, 150.6469],
  [-34.5399, 150.6454],
  [-34.5401, 150.6449],
  [-34.5406, 150.6425],
  [-34.5410, 150.6417],
  [-34.5418, 150.6412],
  [-34.5431, 150.6410],
  [-34.5439, 150.6407],
  [-34.5450, 150.6399],
  [-34.5456, 150.6396],
  [-34.5463, 150.6397],
  [-34.5490, 150.6412],
  [-34.5499, 150.6413],
  [-34.5509, 150.6408],
  [-34.5515, 150.6397],
  [-34.5519, 150.6359],
  [-34.5516, 150.6348],
  [-34.5486, 150.6286],
  [-34.5485, 150.6274],
  [-34.5489, 150.6264],
  [-34.5494, 150.6259],
  [-34.5518, 150.6248],
  [-34.5526, 150.6242],
  [-34.5540, 150.6224],
  [-34.5548, 150.6217],
  [-34.5576, 150.6202],
  [-34.5584, 150.6202],
  [-34.5592, 150.6207],
  [-34.5600, 150.6221],
  [-34.5605, 150.6225],
  [-34.5610, 150.6228],
  [-34.5625, 150.6230],
  [-34.5631, 150.6233],
  [-34.5643, 150.6243],
  [-34.5650, 150.6246],
  [-34.5657, 150.6245],
  [-34.5670, 150.6241],
  [-34.5678, 150.6241],
  [-34.5685, 150.6246],
  [-34.5697, 150.6263],
  [-34.5703, 150.6270],
  [-34.5709, 150.6273],
  [-34.5719, 150.6275],
  [-34.5725, 150.6277],
  [-34.5730, 150.6281],
  [-34.5742, 150.6299],
  [-34.5749, 150.6306],
  [-34.5756, 150.6308],
  [-34.5764, 150.6306],
  [-34.5789, 150.6283],
  [-34.5797, 150.6278],
  [-34.5805, 150.6276],
  [-34.5825, 150.6276],
  [-34.5830, 150.6275],
  [-34.5835, 150.6271],
  [-34.5861, 150.6237],
  [-34.5869, 150.6228],
  [-34.5883, 150.6215],
  [-34.5889, 150.6207],
  [-34.5892, 150.6199],
  [-34.5892, 150.6195],
  [-34.5891, 150.6176],
  [-34.5888, 150.6163],
  [-34.5882, 150.6150],
  [-34.5881, 150.6140],
  [-34.5883, 150.6130],
  [-34.5890, 150.6123],
  [-34.5900, 150.6119],
  [-34.5906, 150.6113],
  [-34.5911, 150.6101],
  [-34.5916, 150.6071],
  [-34.5916, 150.6064],
  [-34.5913, 150.6055],
  [-34.5901, 150.6035],
  [-34.5897, 150.6019],
  [-34.5898, 150.6004],
  [-34.5909, 150.5959],
  [-34.5910, 150.5946],
  [-34.5906, 150.5915],
  [-34.5902, 150.5900],
  [-34.5885, 150.5864],
  [-34.5879, 150.5856],
  [-34.5823, 150.5809],
  [-34.5807, 150.5801],
  [-34.5800, 150.5794],
  [-34.5793, 150.5782],
  [-34.5790, 150.5767],
  [-34.5792, 150.5756],
  [-34.5806, 150.5709],
  [-34.5808, 150.5694],
  [-34.5807, 150.5633],
  [-34.5796, 150.5565],
  [-34.5792, 150.5554],
  [-34.5784, 150.5540],
  [-34.5780, 150.5528],
  [-34.5774, 150.5488],
  [-34.5774, 150.5473],
  [-34.5778, 150.5460],
  [-34.5806, 150.5402],
  [-34.5813, 150.5376],
  [-34.5818, 150.5314],
  [-34.5819, 150.5285],
  [-34.5802, 150.5112],
  [-34.5794, 150.5082],
  [-34.5780, 150.5051]
];

const wingecarribeeOld = [
  [-34.5780, 150.5051],
  [-34.5717, 150.4919],
  [-34.5672, 150.4852]
];

const wingecarribeeDeviation = [
  [-34.5780, 150.5051],
  [-34.5778, 150.5045],
  [-34.5773, 150.5017],
  [-34.5773, 150.4981],
  [-34.5765, 150.4942],
  [-34.5750, 150.4914],
  [-34.5726, 150.4890],
  [-34.5689, 150.4870],
  [-34.5678, 150.4860],
  [-34.5672, 150.4852]
];

const wingecarribeeMossVale = [
  [-34.5672, 150.4852],
  [-34.5658, 150.4835],
  [-34.5643, 150.4807],
  [-34.5632, 150.4768],
  [-34.5617, 150.4656],
  [-34.5582, 150.4493],
  [-34.5508, 150.4287],
  [-34.5468, 150.4056],
  [-34.5467, 150.4021],
  [-34.5471, 150.3992],
  [-34.5472, 150.3974],
  [-34.5467, 150.3888],
  [-34.5456, 150.3842],
  [-34.5423, 150.3779],
  [-34.5421, 150.3773],
  [-34.5420, 150.3764],
  [-34.5421, 150.3754],
  [-34.5424, 150.3747],
  [-34.5432, 150.3739]
];

const junction = [
  [-34.5391, 150.3759],
  [-34.5403, 150.3757],
  [-34.5411, 150.3760],
  [-34.5416, 150.3765],
  [-34.5421, 150.3773]
];

export const unanderraMossVale: Line = {
  name: 'Unanderra - Moss Vale',
  state: 'NSW',
  segments: [
    {
      segments: [
        dombartonRockfallShelter,
        rockfallShelter,
        rockfallShelterIllawarraNo1Tunnel,
        {
          name: 'Illawarra No 1 Tunnel',
          segment: illawarraNo1Tunnel,
          type: 'tunnel'
        },
        illawarraNo1TunnelIllawarraNo2Tunnel,
        {
          name: 'Illawarra No 2 Tunnel',
          segment: illawarraNo2Tunnel,
          type: 'tunnel'
        },
        tillawarraNoTunnelWingecarribee,
        {
          date: '1973-01-01',
          original: [wingecarribeeOld],
          deviation: [wingecarribeeDeviation]
        },
        wingecarribeeMossVale,
        junction
      ],
      history: {
        opened: {
          date: '1932-08-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [unanderraDombarton],
      history: {
        opened: {
          date: '1932-08-20',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-01',
          tracks: 2
        }]
      }
    }
  ]
};
