import { Line } from "../../trackTypes";

const boganGateTrundle = [
  [-33.1087, 147.7987],
  [-33.1084, 147.7971],
  [-33.1077, 147.7958],
  [-33.1067, 147.7949],
  [-33.1055, 147.7944],
  [-33.1041, 147.7944],
  [-33.1030, 147.7949],
  [-33.1020, 147.7956],
  [-33.0991, 147.7992],
  [-33.0973, 147.8006],
  [-33.0953, 147.8014],
  [-33.0936, 147.8017],
  [-33.0921, 147.8023],
  [-33.0905, 147.8033],
  [-33.0886, 147.8051],
  [-33.0868, 147.8063],
  [-33.0733, 147.8109],
  [-33.0703, 147.8111],
  [-33.0678, 147.8107],
  [-33.0450, 147.8016],
  [-33.0437, 147.8006],
  [-33.0431, 147.7994],
  [-33.0409, 147.7946],
  [-33.0397, 147.7932],
  [-33.0382, 147.7925],
  [-33.0353, 147.7923],
  [-33.0336, 147.7916],
  [-33.0326, 147.7904],
  [-33.0298, 147.7852],
  [-33.0129, 147.7674],
  [-33.0112, 147.7664],
  [-33.0055, 147.7648],
  [-33.0036, 147.7636],
  [-32.9927, 147.7525],
  [-32.9906, 147.7510],
  [-32.9850, 147.7487],
  [-32.9812, 147.7462],
  [-32.9692, 147.7340],
  [-32.9668, 147.7320],
  [-32.9467, 147.7192],
  [-32.9432, 147.7181],
  [-32.9304, 147.7174],
  [-32.9283, 147.7167],
  [-32.9212, 147.7109],
  [-32.9200, 147.7103],
  [-32.9159, 147.7091]
];

const trundleTullamore = [
  [-32.9159, 147.7091],
  [-32.9085, 147.7071],
  [-32.9073, 147.7062],
  [-32.9064, 147.7051],
  [-32.9007, 147.6974],
  [-32.8991, 147.6959],
  [-32.8821, 147.6886],
  [-32.8800, 147.6873],
  [-32.8754, 147.6830],
  [-32.8546, 147.6663],
  [-32.8521, 147.6647],
  [-32.8292, 147.6543],
  [-32.7543, 147.6199],
  [-32.7377, 147.6095],
  [-32.7356, 147.6089],
  [-32.7325, 147.6087],
  [-32.7303, 147.6077],
  [-32.7291, 147.6065],
  [-32.7274, 147.6040],
  [-32.7256, 147.6023],
  [-32.7063, 147.5954],
  [-32.7030, 147.5951],
  [-32.6966, 147.5957],
  [-32.6930, 147.5949],
  [-32.6517, 147.5747],
  [-32.6489, 147.5724],
  [-32.6434, 147.5654],
  [-32.6410, 147.5638],
  [-32.6384, 147.5631],
  [-32.6314, 147.5631]
];

const tullamoreTottenham = [
  [-32.6314, 147.5631],
  [-32.6232, 147.5632],
  [-32.6215, 147.5627],
  [-32.6189, 147.5614],
  [-32.6170, 147.5608],
  [-32.6150, 147.5607],
  [-32.5978, 147.5635],
  [-32.4946, 147.5804],
  [-32.4452, 147.5839],
  [-32.4428, 147.5836],
  [-32.4404, 147.5822],
  [-32.4080, 147.5466],
  [-32.4041, 147.5438],
  [-32.3831, 147.5323],
  [-32.3515, 147.5053],
  [-32.3109, 147.4708],
  [-32.3098, 147.4692],
  [-32.3090, 147.4669],
  [-32.3081, 147.4655],
  [-32.2998, 147.4588],
  [-32.2982, 147.4580],
  [-32.2799, 147.4509],
  [-32.2772, 147.4489],
  [-32.2731, 147.4428],
  [-32.2721, 147.4397],
  [-32.2713, 147.4325],
  [-32.2703, 147.4304],
  [-32.2687, 147.4294],
  [-32.2669, 147.4293],
  [-32.2648, 147.4303],
  [-32.2632, 147.4303],
  [-32.2615, 147.4293],
  [-32.2605, 147.4275],
  [-32.2595, 147.4218],
  [-32.2570, 147.4148],
  [-32.2413, 147.3565],
  [-32.2399, 147.3535],
  [-32.2387, 147.3522],
  [-32.2347, 147.3486]
];

const tottenhamMountRoyalSmelters = [
  [-32.2347, 147.3486],
  [-32.2345, 147.3486],
  [-32.2344, 147.3489],
  [-32.2345, 147.3495],
  [-32.2342, 147.3521]
];

const albertIronDukeMine = [
  [-32.3515, 147.5053],
  [-32.3511, 147.5045],
  [-32.3497, 147.4982],
  [-32.3464, 147.4681],
  [-32.3460, 147.4667],
  [-32.3409, 147.4623],
  [-32.3406, 147.4613],
  [-32.3406, 147.4603],
  [-32.3414, 147.4594],
  [-32.3419, 147.4587],
  [-32.3431, 147.4569],
  [-32.3449, 147.4554],
  [-32.3469, 147.4527],
  [-32.3478, 147.4523],
  [-32.3497, 147.4525],
  [-32.3511, 147.4520],
  [-32.3525, 147.4512],
  [-32.3531, 147.4510],
  [-32.3537, 147.4511],
  [-32.3553, 147.4521],
  [-32.3560, 147.4532],
  [-32.3573, 147.4679]
];

const carolineJunctionCarolineMine = [
  [-32.2570, 147.4148],
  [-32.2573, 147.4152],
  [-32.2568, 147.4163],
  [-32.2282, 147.4414],
  [-32.2228, 147.4475],
  [-32.2223, 147.4478],
  [-32.2219, 147.4478],
  [-32.2196, 147.4469]
];

export const tottenham: Line = {
  name: 'Tottenham',
  state: 'NSW',
  segments: [
    {
      segments: [boganGateTrundle],
      history: {
        opened: {
          date: '1907-08-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [trundleTullamore],
      history: {
        opened: {
          date: '1908-12-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [tullamoreTottenham],
      history: {
        opened: {
          date: '1916-10-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [tottenhamMountRoyalSmelters],
      history: {
        opened: {
          date: '1918-09-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1941-01-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [albertIronDukeMine],
      history: {
        opened: {
          date: '1918-10-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-06-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [carolineJunctionCarolineMine],
      history: {
        opened: {
          date: '1918-09-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-06-18',
          status: 'closed'
        }]
      }
    }
  ]
};
