import { Line } from "../../trackTypes";

const line = [
  [-27.96067, 153.42660],
  [-27.95983, 153.42660],
  [-27.95931, 153.42658],
  [-27.95809, 153.42659],
  [-27.95792, 153.42657],
  [-27.95764, 153.42647],
  [-27.95752, 153.42641],
  [-27.95732, 153.42627],
  [-27.95721, 153.42621],
  [-27.95708, 153.42618],
  [-27.95695, 153.42617],
  [-27.95683, 153.42618],
  [-27.95610, 153.42640],
  [-27.95556, 153.42655],
  [-27.95542, 153.42657],
  [-27.95441, 153.42659],
  [-27.95424, 153.42655],
  [-27.95407, 153.42648],
  [-27.95392, 153.42632],
  [-27.95383, 153.42613],
  [-27.95379, 153.42596],
  [-27.95378, 153.42516],
  [-27.95382, 153.42492],
  [-27.95389, 153.42477],
  [-27.95399, 153.42464],
  [-27.95415, 153.42446],
  [-27.95427, 153.42437],
  [-27.95438, 153.42433],
  [-27.95451, 153.42432],
  [-27.95462, 153.42436],
  [-27.95474, 153.42443],
  [-27.95509, 153.42480],
  [-27.95521, 153.42488],
  [-27.95537, 153.42495],
  [-27.95554, 153.42496],
  [-27.95608, 153.42497],
  [-27.95616, 153.42494],
  [-27.95650, 153.42484],
  [-27.95666, 153.42479],
  [-27.95683, 153.42479],
  [-27.95711, 153.42491],
  [-27.95721, 153.42495],
  [-27.95736, 153.42498],
  [-27.95750, 153.42496],
  [-27.95815, 153.42481],
  [-27.95832, 153.42474],
  [-27.95843, 153.42465],
  [-27.95861, 153.42450],
  [-27.95874, 153.42443],
  [-27.95890, 153.42437],
  [-27.96009, 153.42435],
  [-27.96021, 153.42438],
  [-27.96032, 153.42445],
  [-27.96093, 153.42500],
  [-27.96102, 153.42511],
  [-27.96107, 153.42522],
  [-27.96110, 153.42540],
  [-27.96113, 153.42601],
  [-27.96109, 153.42625],
  [-27.96102, 153.42639],
  [-27.96088, 153.42653],
  [-27.96076, 153.42659],
  [-27.96067, 153.42660]
];

export const seaWorldMonorail: Line = {
  name: 'Sea World Monorail',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1986-08-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'monorail'
        },
        trackChange: [{
          date: '2022-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
