import { Line } from "../../../trackTypes";

const wolseleyTatiara = [
  [-36.3662, 140.9084],
  [-36.3673, 140.9109],
  [-36.3686, 140.9122],
  [-36.3703, 140.9128],
  [-36.4287, 140.9132],
  [-36.4346, 140.9160]
];

const tatiaraNaracoorte = [
  [-36.4346, 140.9160],
  [-36.4636, 140.9293],
  [-36.4807, 140.9306],
  [-36.4852, 140.9323],
  [-36.4873, 140.9330],
  [-36.4896, 140.9326],
  [-36.4967, 140.9297],
  [-36.5191, 140.9241],
  [-36.5211, 140.9241],
  [-36.5274, 140.9250],
  [-36.5301, 140.9249],
  [-36.5325, 140.9257],
  [-36.5445, 140.9352],
  [-36.5469, 140.9362],
  [-36.5658, 140.9395],
  [-36.5683, 140.9407],
  [-36.5793, 140.9490],
  [-36.5816, 140.9498],
  [-36.5864, 140.9496],
  [-36.5994, 140.9502],
  [-36.6079, 140.9520],
  [-36.6311, 140.9530],
  [-36.6379, 140.9519],
  [-36.6401, 140.9521],
  [-36.6449, 140.9546],
  [-36.6534, 140.9558],
  [-36.6700, 140.9542],
  [-36.7088, 140.9544],
  [-36.7110, 140.9537],
  [-36.7328, 140.9459],
  [-36.8451, 140.9279],
  [-36.8752, 140.9231],
  [-36.8766, 140.9222],
  [-36.9378, 140.8632],
  [-36.9386, 140.8617],
  [-36.9389, 140.8593],
  [-36.9367, 140.8321],
  [-36.9346, 140.8239],
  [-36.9348, 140.8211],
  [-36.9436, 140.7849],
  [-36.9471, 140.7703],
  [-36.9481, 140.7684],
  [-36.9494, 140.7669],
  [-36.9499, 140.7660],
  [-36.9505, 140.7644],
  [-36.9529, 140.7452],
  [-36.9536, 140.7430],
  [-36.9556, 140.7392],
  [-36.9562, 140.7375],
  [-36.9579, 140.7313]
];

const naracoorteMtGambier = [
  [-36.9579, 140.7313],
  [-36.9584, 140.7301],
  [-36.9592, 140.7291],
  [-36.9603, 140.7286],
  [-36.9613, 140.7287],
  [-36.9780, 140.7361],
  [-36.9816, 140.7372],
  [-36.9940, 140.7390],
  [-37.0091, 140.7430],
  [-37.0282, 140.7516],
  [-37.0333, 140.7555],
  [-37.0646, 140.7712],
  [-37.0676, 140.7729],
  [-37.0955, 140.7830],
  [-37.1626, 140.8018],
  [-37.2232, 140.8187],
  [-37.2276, 140.8190],
  [-37.2585, 140.8147],
  [-37.2613, 140.8148],
  [-37.3668, 140.8335],
  [-37.3684, 140.8336],
  [-37.3792, 140.8310],
  [-37.3808, 140.8302],
  [-37.4062, 140.8104],
  [-37.4091, 140.8088],
  [-37.4241, 140.8051],
  [-37.4270, 140.8037],
  [-37.4308, 140.8008],
  [-37.4356, 140.7981],
  [-37.4531, 140.7904],
  [-37.4579, 140.7873],
  [-37.4789, 140.7649],
  [-37.5051, 140.7447],
  [-37.5099, 140.7398],
  [-37.5638, 140.6978],
  [-37.5664, 140.6968],
  [-37.5771, 140.6971],
  [-37.5795, 140.6975],
  [-37.6399, 140.7184],
  [-37.6415, 140.7187],
  [-37.7211, 140.7256],
  [-37.7391, 140.7272],
  [-37.7503, 140.7282],
  [-37.7517, 140.7287],
  [-37.7554, 140.7306],
  [-37.7576, 140.7312],
  [-37.7617, 140.7310],
  [-37.7644, 140.7316],
  [-37.7678, 140.7336],
  [-37.7760, 140.7376],
  [-37.7870, 140.7405],
  [-37.8214, 140.7527],
  [-37.8232, 140.7540],
  [-37.8245, 140.7559],
  [-37.8306, 140.7754]
];

const mtGambierVicBorer = [
  [-37.8306, 140.7754],
  [-37.8320, 140.7797],
  [-37.8323, 140.7822],
  [-37.8334, 140.7881],
  [-37.8342, 140.7906],
  [-37.8354, 140.7930],
  [-37.8393, 140.7982],
  [-37.8403, 140.8001],
  [-37.8477, 140.8235],
  [-37.8575, 140.8442],
  [-37.8578, 140.8457],
  [-37.8579, 140.8476],
  [-37.8571, 140.8539],
  [-37.8570, 140.8557],
  [-37.8577, 140.8698],
  [-37.8575, 140.8715],
  [-37.8570, 140.8732],
  [-37.8494, 140.8881],
  [-37.8233, 140.9523],
  [-37.8181, 140.9612],
  [-37.8175, 140.9627],
  [-37.8173, 140.9648],
  [-37.8173, 140.9702]
];

// mt gambier (SA) 1953, dual gauge, broad gauge only on 1959

export const mtGambier: Line = {
  name: 'Mt Gambier',
  state: 'SA',
  segments: [
    {
      segments: [wolseleyTatiara],
      history: {
        opened: {
          date: '1883-04-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-01-01',
          gauge: 'dual'
        }, {
          date: '1959-01-01',
          gauge: 'broad'
        }, {
          date: '1995-04-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tatiaraNaracoorte],
      history: {
        opened: {
          date: '1881-09-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-01-01',
          gauge: 'dual'
        }, {
          date: '1959-01-01',
          gauge: 'broad'
        }, {
          date: '1995-04-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [naracoorteMtGambier],
      history: {
        opened: {
          date: '1887-06-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-01-01',
          gauge: 'dual'
        }, {
          date: '1959-01-01',
          gauge: 'broad'
        }, {
          date: '1995-04-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mtGambierVicBorer],
      history: {
        opened: {
          date: '1917-11-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-04-11',
          status: 'closed'
        }]
      }
    }
  ]
};
