import { Line } from "../../trackTypes";

const westonJunctionHebburnNoTwoColliery = [
  [-32.8170, 151.4503],
  [-32.8181, 151.4483],
  [-32.8201, 151.4418],
  [-32.8228, 151.4384],
  [-32.8276, 151.4358],
  [-32.8297, 151.4353],
  [-32.8426, 151.4364],
  [-32.8443, 151.4359],
  [-32.8469, 151.4343],
  [-32.8492, 151.4345],
  [-32.8508, 151.4360]
];

const elringtonJunctionElringtonColliery = [
  [-32.8469, 151.4343],
  [-32.8488, 151.4336],
  [-32.8507, 151.4336],
  [-32.8532, 151.4318],
  [-32.8545, 151.4310],
  [-32.8568, 151.4308],
  [-32.8585, 151.4298],
  [-32.8592, 151.4282],
  [-32.8592, 151.4257],
  [-32.8597, 151.4240],
  [-32.8607, 151.4228],
  [-32.8610, 151.4214],
  [-32.8609, 151.4194],
  [-32.8614, 151.4178],
  [-32.8627, 151.4168],
  [-32.8661, 151.4165],
  [-32.8682, 151.4173],
  [-32.8694, 151.4173],
  [-32.8711, 151.4171],
  [-32.8727, 151.4176],
  [-32.8737, 151.4182],
  [-32.8756, 151.4189]
];

export const hebburn: Line = {
  name: 'Hebburn',
  state: 'NSW',
  segments: [
    {
      segments: [westonJunctionHebburnNoTwoColliery],
      history: {
        opened: {
          date: '1918-11-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1972-09-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elringtonJunctionElringtonColliery],
      history: {
        opened: {
          date: '1928-06-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
