import { Line } from "../../trackTypes";

const hopetounKundip = [
  [-33.9524, 120.1257],
  [-33.9504, 120.1269],
  [-33.9488, 120.1288],
  [-33.9445, 120.1346],
  [-33.9433, 120.1371],
  [-33.9428, 120.1397],
  [-33.9427, 120.1414],
  [-33.9417, 120.1431],
  [-33.9378, 120.1455],
  [-33.9365, 120.1456],
  [-33.9353, 120.1451],
  [-33.9340, 120.1451],
  [-33.9328, 120.1445],
  [-33.9314, 120.1436],
  [-33.9297, 120.1437],
  [-33.9285, 120.1448],
  [-33.9258, 120.1495],
  [-33.9237, 120.1515],
  [-33.8334, 120.1818],
  [-33.8315, 120.1818],
  [-33.8268, 120.1806],
  [-33.8258, 120.1805],
  [-33.8051, 120.1841]
];

const kundipRavensthorpe = [
  [-33.8051, 120.1841],
  [-33.8041, 120.1847],
  [-33.8033, 120.1857],
  [-33.7968, 120.1934],
  [-33.7921, 120.1962],
  [-33.7902, 120.1960],
  [-33.7883, 120.1947],
  [-33.7813, 120.1850],
  [-33.7795, 120.1839],
  [-33.7779, 120.1838],
  [-33.7747, 120.1844],
  [-33.7633, 120.1889],
  [-33.7601, 120.1895],
  [-33.7587, 120.1901],
  [-33.7575, 120.1914],
  [-33.7561, 120.1920],
  [-33.7535, 120.1921],
  [-33.7522, 120.1927],
  [-33.7511, 120.1938],
  [-33.7499, 120.1942],
  [-33.7489, 120.1940],
  [-33.7398, 120.1886],
  [-33.7363, 120.1877],
  [-33.7354, 120.1878],
  [-33.7327, 120.1887],
  [-33.7313, 120.1885],
  [-33.7215, 120.1829],
  [-33.7199, 120.1828],
  [-33.7161, 120.1849],
  [-33.7145, 120.1847],
  [-33.7125, 120.1834],
  [-33.7118, 120.1827],
  [-33.7097, 120.1782],
  [-33.7085, 120.1773],
  [-33.7074, 120.1772],
  [-33.7003, 120.1805],
  [-33.6995, 120.1814],
  [-33.6987, 120.1833],
  [-33.6980, 120.1842],
  [-33.6906, 120.1893],
  [-33.6898, 120.1895],
  [-33.6889, 120.1891],
  [-33.6866, 120.1869],
  [-33.6848, 120.1866],
  [-33.6764, 120.1894],
  [-33.6751, 120.1897],
  [-33.6701, 120.1887],
  [-33.6689, 120.1881],
  [-33.6672, 120.1865],
  [-33.6664, 120.1862],
  [-33.6625, 120.1857],
  [-33.6602, 120.1858],
  [-33.6555, 120.1843],
  [-33.6539, 120.1842],
  [-33.6528, 120.1836],
  [-33.6518, 120.1826],
  [-33.6481, 120.1805],
  [-33.6472, 120.1792],
  [-33.6463, 120.1756],
  [-33.6463, 120.1738],
  [-33.6458, 120.1726],
  [-33.6449, 120.1720],
  [-33.6421, 120.1719],
  [-33.6410, 120.1709],
  [-33.6400, 120.1685],
  [-33.6385, 120.1671],
  [-33.6381, 120.1662],
  [-33.6379, 120.1645],
  [-33.6381, 120.1631],
  [-33.6377, 120.1616],
  [-33.6368, 120.1606],
  [-33.6358, 120.1588],
  [-33.6350, 120.1582],
  [-33.6337, 120.1580],
  [-33.6331, 120.1577],
  [-33.6318, 120.1565],
  [-33.6296, 120.1529],
  [-33.6289, 120.1498],
  [-33.6279, 120.1487],
  [-33.6268, 120.1487],
  [-33.6250, 120.1499],
  [-33.6212, 120.1510],
  [-33.6203, 120.1508],
  [-33.6194, 120.1500],
  [-33.6183, 120.1495],
  [-33.6178, 120.1491],
  [-33.6173, 120.1482],
  [-33.6168, 120.1477],
  [-33.6154, 120.1470],
  [-33.6134, 120.1447],
  [-33.6080, 120.1406],
  [-33.6066, 120.1385],
  [-33.6054, 120.1376],
  [-33.6050, 120.1367],
  [-33.6046, 120.1352],
  [-33.6041, 120.1345],
  [-33.6028, 120.1334],
  [-33.6014, 120.1319],
  [-33.6000, 120.1311],
  [-33.5993, 120.1298],
  [-33.5983, 120.1290],
  [-33.5973, 120.1289],
  [-33.5967, 120.1286],
  [-33.5942, 120.1259],
  [-33.5930, 120.1229],
  [-33.5925, 120.1221],
  [-33.5915, 120.1216],
  [-33.5903, 120.1217],
  [-33.5895, 120.1216],
  [-33.5830, 120.1186],
  [-33.5787, 120.1149],
  [-33.5759, 120.1123],
  [-33.5731, 120.1114],
  [-33.5720, 120.1107],
  [-33.5713, 120.1099],
  [-33.5707, 120.1086],
  [-33.5692, 120.1007],
  [-33.5696, 120.0728],
  [-33.5689, 120.0710],
  [-33.5688, 120.0698],
  [-33.5692, 120.0684],
  [-33.5704, 120.0662],
  [-33.5718, 120.0644],
  [-33.5758, 120.0608],
  [-33.5795, 120.0547]
];

export const ravensthorpe: Line = {
  name: 'Ravensthorpe',
  state: 'WA',
  segments: [
    {
      segments: [hopetounKundip],
      history: {
        opened: {
          date: '1908-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kundipRavensthorpe],
      history: {
        opened: {
          date: '1909-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1935-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
