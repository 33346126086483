import { Line } from "../../../../trackTypes";

const emlynStreet = [
  [-27.49459, 153.05912],
  [-27.49459, 153.05924],
  [-27.49464, 153.05934],
  [-27.49930, 153.06223],
  [-27.49945, 153.06228],
  [-27.49958, 153.06230],
  [-27.49974, 153.06228],
  [-27.50170, 153.06187]
];

const ladeStreet = [
  [-27.50170, 153.06187],
  [-27.50513, 153.06139],
  [-27.50531, 153.06141],
  [-27.50545, 153.06148],
  [-27.50561, 153.06165],
  [-27.50707, 153.06364],
  [-27.50722, 153.06373],
  [-27.50738, 153.06379],
  [-27.51062, 153.06412]
];

export const south7: Line = {
  name: 'South 7',
  state: 'QLD',
  segments: [
    {
      segments: [emlynStreet],
      history: {
        opened: {
          date: '1925-04-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-11-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ladeStreet],
      history: {
        opened: {
          date: '1925-04-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-11-14',
          status: 'closed'
        }]
      }
    }
  ]
}
