import { Line } from "../../../trackTypes";

const baxterMoorooduc = [
  [-38.1964, 145.1599],
  [-38.1972, 145.1595],
  [-38.1977, 145.1589],
  [-38.1981, 145.1581],
  [-38.1994, 145.1548],
  [-38.2101, 145.1270],
  [-38.2108, 145.1248],
  [-38.2140, 145.1074]
];

const moorooducMornington = [
  [-38.2140, 145.1074],
  [-38.2158, 145.0972],
  [-38.2160, 145.0962],
  [-38.2170, 145.0923],
  [-38.2173, 145.0888],
  [-38.2196, 145.0762],
  [-38.2206, 145.0727],
  [-38.2223, 145.0689],
  [-38.2295, 145.0578],
  [-38.2302, 145.0563],
  [-38.2306, 145.0542],
  [-38.2305, 145.0525],
  [-38.2301, 145.0508],
  [-38.2291, 145.0489]
];

const morningtonMorningtonOld = [
  [-38.2291, 145.0489],
  [-38.2257, 145.0449],
  [-38.2235, 145.0424],
  [-38.2213, 145.0414],
  [-38.2174, 145.0386]
];

export const mornington: Line = {
  name: 'Mornington',
  state: 'VIC',
  segments: [
    {
      segments: [baxterMoorooduc],
      history: {
        opened: {
          date: '1889-09-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-06-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moorooducMornington],
      history: {
        opened: {
          date: '1889-09-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-06-15',
          status: 'closed'
        }, {
          date: '1999-04-18',
          status: 'open'
        }]
      }
    },
    {
      segments: [morningtonMorningtonOld],
      history: {
        opened: {
          date: '1889-09-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-06-15',
          status: 'closed'
        }]
      }
    }
  ]
};
