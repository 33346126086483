import { Line } from "../../trackTypes"

const dimboolaJaparit = [
  [-36.4328, 142.0184],
  [-36.4319, 142.0181],
  [-36.4312, 142.0181],
  [-36.4001, 142.0179],
  [-36.3983, 142.0181],
  [-36.3936, 142.0198],
  [-36.3927, 142.0200],
  [-36.3918, 142.0200],
  [-36.3887, 142.0199],
  [-36.3710, 142.0187],
  [-36.3693, 142.0191],
  [-36.3604, 142.0229],
  [-36.3588, 142.0232],
  [-36.3571, 142.0228],
  [-36.3516, 142.0201],
  [-36.3505, 142.0198],
  [-36.3299, 142.0167],
  [-36.3290, 142.0167],
  [-36.3068, 142.0195],
  [-36.3051, 142.0202],
  [-36.2911, 142.0305],
  [-36.2903, 142.0309],
  [-36.2733, 142.0380],
  [-36.2718, 142.0383],
  [-36.2634, 142.0382],
  [-36.2619, 142.0380],
  [-36.2590, 142.0371],
  [-36.2541, 142.0350],
  [-36.2526, 142.0346],
  [-36.2075, 142.0348],
  [-36.2058, 142.0344],
  [-36.1491, 142.0019],
  [-36.1475, 142.0003],
  [-36.1408, 141.9888],
  [-36.1396, 141.9878]
];

const japaritRainbow = [
  [-36.1396, 141.9878],
  [-36.1366, 141.9850],
  [-36.1359, 141.9845],
  [-36.1350, 141.9843],
  [-36.1246, 141.9836],
  [-36.1235, 141.9838],
  [-36.1193, 141.9860],
  [-36.1178, 141.9864],
  [-36.1126, 141.9871],
  [-36.1119, 141.9874],
  [-36.1111, 141.9877],
  [-36.0983, 141.9973],
  [-36.0969, 141.9991],
  [-36.0892, 142.0117],
  [-36.0877, 142.0133],
  [-36.0865, 142.0141],
  [-36.0840, 142.0153],
  [-36.0822, 142.0168],
  [-36.0811, 142.0190],
  [-36.0801, 142.0234],
  [-36.0791, 142.0258],
  [-36.0773, 142.0278],
  [-36.0750, 142.0291],
  [-36.0727, 142.0295],
  [-36.0712, 142.0304],
  [-36.0705, 142.0312],
  [-36.0685, 142.0335],
  [-36.0665, 142.0350],
  [-36.0638, 142.0356],
  [-36.0618, 142.0355],
  [-36.0599, 142.0363],
  [-36.0589, 142.0373],
  [-36.0576, 142.0397],
  [-36.0555, 142.0413],
  [-36.0519, 142.0419],
  [-36.0426, 142.0419],
  [-36.0400, 142.0416],
  [-36.0123, 142.0362],
  [-35.9980, 142.0360],
  [-35.9955, 142.0355],
  [-35.9683, 142.0276],
  [-35.9388, 142.0120],
  [-35.9126, 141.9971],
  [-35.9072, 141.9932],
  [-35.9064, 141.9927],
  [-35.9051, 141.9924],
  [-35.8976, 141.9926]
];

const rainbowYaapeet = [
  [-35.8976, 141.9926],
  [-35.8950, 141.9927],
  [-35.8947, 141.9927],
  [-35.8935, 141.9925],
  [-35.8904, 141.9926],
  [-35.8887, 141.9930],
  [-35.8872, 141.9937],
  [-35.8851, 141.9951],
  [-35.8837, 141.9958],
  [-35.8819, 141.9964],
  [-35.8803, 141.9966],
  [-35.8791, 141.9965],
  [-35.8774, 141.9961],
  [-35.8725, 141.9945],
  [-35.8710, 141.9942],
  [-35.8694, 141.9942],
  [-35.8678, 141.9945],
  [-35.8541, 141.9973],
  [-35.8528, 141.9974],
  [-35.8085, 141.9974],
  [-35.8070, 141.9977],
  [-35.8054, 141.9985],
  [-35.8040, 141.9997],
  [-35.8026, 142.0020],
  [-35.7939, 142.0222],
  [-35.7809, 142.0445],
  [-35.7798, 142.0460],
  [-35.7780, 142.0471],
  [-35.7762, 142.0480],
  [-35.7722, 142.0497],
  [-35.7712, 142.0503],
  [-35.7662, 142.0542]
];

export const yaapeet: Line = {
  name: 'Yaapeet',
  state: 'VIC',
  segments: [
    {
      segments: [dimboolaJaparit],
      history: {
        opened: {
          date: '1894-06-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-06-02',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [japaritRainbow],
      history: {
        opened: {
          date: '1899-11-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-06-02',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [rainbowYaapeet],
      history: {
        opened: {
          date: '1914-06-26',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-06-02',
          gauge: 'standard'
        }]
      }
    }
  ]
}
