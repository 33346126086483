import { Line } from "../../trackTypes";

const line = [
  [-34.8751, 138.5353],
  [-34.8747, 138.5351],
  [-34.8745, 138.5351],
  [-34.8711, 138.5360],
  [-34.8705, 138.5366],
  [-34.8697, 138.5378],
  [-34.8687, 138.5383],
  [-34.8654, 138.5381],
  [-34.8648, 138.5384],
  [-34.8637, 138.5383],
  [-34.8629, 138.5379],
  [-34.8605, 138.5377],
  [-34.8601, 138.5379],
  [-34.8598, 138.5383],
  [-34.8595, 138.5384],
  [-34.8503, 138.5378],
  [-34.8485, 138.5370],
  [-34.8463, 138.5360],
  [-34.8440, 138.5340],
  [-34.8435, 138.5332],
  [-34.8433, 138.5326],
  [-34.8433, 138.5317]
];

export const finsbury: Line = {
  name: 'Finsbury',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1940-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
