import { Line } from "../../trackTypes";

const line = [
  [-34.7412, 138.6495],
  [-34.7408, 138.6502],
  [-34.7406, 138.6514],
  [-34.7407, 138.6543]
];

export const gmhElizabeth: Line = {
  name: 'GMH Elizabeth',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1959-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1992-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
