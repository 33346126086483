import { Line } from "../../../trackTypes";

const northgateSandgate = [
  [-27.3922, 153.0694],
  [-27.3878, 153.0702],
  [-27.3860, 153.0709],
  [-27.3722, 153.0788],
  [-27.3654, 153.0838],
  [-27.3644, 153.0841],
  [-27.3635, 153.0839],
  [-27.3627, 153.0831],
  [-27.3607, 153.0788],
  [-27.3589, 153.0768],
  [-27.3517, 153.0727],
  [-27.3393, 153.0611],
  [-27.3374, 153.0602],
  [-27.3343, 153.0600],
  [-27.3325, 153.0607],
  [-27.3234, 153.0655],
  [-27.3224, 153.0667],
  [-27.3221, 153.0684],
  [-27.3224, 153.0708]
];

const sandgateShornCliffe = [
  [-27.3224, 153.0708],
  [-27.3227, 153.0729],
  [-27.3234, 153.0743],
  [-27.3254, 153.0761],
  [-27.3261, 153.0771],
  [-27.3274, 153.0816],
  [-27.3278, 153.0820],
  [-27.3292, 153.0828]
];

export const shorncliffe: Line = {
  name: 'Shorncliffe',
  state: 'QLD',
  segments: [
    {
      segments: [northgateSandgate],
      history: {
        opened: {
          date: '1882-05-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1901-12-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [sandgateShornCliffe],
      history: {
        opened: {
          date: '1882-05-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
