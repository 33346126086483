import { Line } from "../../trackTypes";

const gilmoreJunctionWereboldera = [
  [-35.3333, 148.1749],
  [-35.3359, 148.1725],
  [-35.3367, 148.1717],
  [-35.3370, 148.1707],
  [-35.3371, 148.1696],
  [-35.3369, 148.1680],
  [-35.3371, 148.1667],
  [-35.3377, 148.1658],
  [-35.3402, 148.1639],
  [-35.3415, 148.1634],
  [-35.3440, 148.1639],
  [-35.3485, 148.1629],
  [-35.3509, 148.1621],
  [-35.3533, 148.1617],
  [-35.3548, 148.1610],
  [-35.3558, 148.1608],
  [-35.3613, 148.1610],
  [-35.3635, 148.1614],
  [-35.3652, 148.1615],
  [-35.3766, 148.1638],
  [-35.3774, 148.1638],
  [-35.3790, 148.1634],
  [-35.3799, 148.1635],
  [-35.3822, 148.1643],
  [-35.3890, 148.1668],
  [-35.3945, 148.1708],
  [-35.3959, 148.1715],
  [-35.4032, 148.1733],
  [-35.4060, 148.1732]
];

const werebolderaBatlow = [
  [-35.4060, 148.1732],
  [-35.4107, 148.1733],
  [-35.4122, 148.1728],
  [-35.4134, 148.1726],
  [-35.4153, 148.1730],
  [-35.4230, 148.1723],
  [-35.4236, 148.1720],
  [-35.4249, 148.1705],
  [-35.4252, 148.1698],
  [-35.4251, 148.1682],
  [-35.4254, 148.1674],
  [-35.4259, 148.1670],
  [-35.4265, 148.1670],
  [-35.4269, 148.1675],
  [-35.4271, 148.1682],
  [-35.4268, 148.1701],
  [-35.4270, 148.1707],
  [-35.4276, 148.1711],
  [-35.4299, 148.1711],
  [-35.4304, 148.1714],
  [-35.4309, 148.1714],
  [-35.4316, 148.1715],
  [-35.4323, 148.1714],
  [-35.4333, 148.1708],
  [-35.4338, 148.1707],
  [-35.4342, 148.1709],
  [-35.4349, 148.1709],
  [-35.4365, 148.1702],
  [-35.4391, 148.1698],
  [-35.4403, 148.1701],
  [-35.4420, 148.1708],
  [-35.4426, 148.1708],
  [-35.4433, 148.1702],
  [-35.4438, 148.1701],
  [-35.4462, 148.1707],
  [-35.4466, 148.1712],
  [-35.4469, 148.1720],
  [-35.4473, 148.1725],
  [-35.4487, 148.1732],
  [-35.4498, 148.1746],
  [-35.4503, 148.1748],
  [-35.4509, 148.1747],
  [-35.4513, 148.1749],
  [-35.4521, 148.1757],
  [-35.4527, 148.1760],
  [-35.4535, 148.1758],
  [-35.4545, 148.1751],
  [-35.4549, 148.1750],
  [-35.4553, 148.1752],
  [-35.4560, 148.1758],
  [-35.4567, 148.1759],
  [-35.4574, 148.1762],
  [-35.4576, 148.1768],
  [-35.4577, 148.1774],
  [-35.4575, 148.1783],
  [-35.4576, 148.1788],
  [-35.4578, 148.1791],
  [-35.4584, 148.1794],
  [-35.4598, 148.1791],
  [-35.4602, 148.1794],
  [-35.4604, 148.1799],
  [-35.4605, 148.1805],
  [-35.4606, 148.1813],
  [-35.4611, 148.1818],
  [-35.4617, 148.1820],
  [-35.4623, 148.1823],
  [-35.4632, 148.1841],
  [-35.4637, 148.1845],
  [-35.4641, 148.1846],
  [-35.4646, 148.1844],
  [-35.4660, 148.1819],
  [-35.4671, 148.1809],
  [-35.4682, 148.1800],
  [-35.4693, 148.1793],
  [-35.4723, 148.1789],
  [-35.4750, 148.1793],
  [-35.4757, 148.1796],
  [-35.4764, 148.1804],
  [-35.4769, 148.1806],
  [-35.4773, 148.1803],
  [-35.4778, 148.1796],
  [-35.4784, 148.1788],
  [-35.4801, 148.1782],
  [-35.4804, 148.1779],
  [-35.4806, 148.1774],
  [-35.4806, 148.1765],
  [-35.4809, 148.1759],
  [-35.4815, 148.1755],
  [-35.4842, 148.1759],
  [-35.4848, 148.1756],
  [-35.4854, 148.1748],
  [-35.4858, 148.1747],
  [-35.4863, 148.1748],
  [-35.4870, 148.1753],
  [-35.4875, 148.1755],
  [-35.4880, 148.1752],
  [-35.4903, 148.1722],
  [-35.4909, 148.1718],
  [-35.4937, 148.1717],
  [-35.4942, 148.1718],
  [-35.4948, 148.1721],
  [-35.4954, 148.1720],
  [-35.4958, 148.1715],
  [-35.4959, 148.1707],
  [-35.4956, 148.1701],
  [-35.4949, 148.1698],
  [-35.4942, 148.1700],
  [-35.4936, 148.1697],
  [-35.4933, 148.1689],
  [-35.4935, 148.1677],
  [-35.4938, 148.1672],
  [-35.4943, 148.1669],
  [-35.4948, 148.1665],
  [-35.4948, 148.1659],
  [-35.4946, 148.1650],
  [-35.4948, 148.1644],
  [-35.4951, 148.1639],
  [-35.4988, 148.1614],
  [-35.5000, 148.1612],
  [-35.5006, 148.1609],
  [-35.5008, 148.1603],
  [-35.5008, 148.1593],
  [-35.5012, 148.1585],
  [-35.5024, 148.1577],
  [-35.5033, 148.1572],
  [-35.5053, 148.1570],
  [-35.5059, 148.1567],
  [-35.5065, 148.1557],
  [-35.5072, 148.1549],
  [-35.5085, 148.1542],
  [-35.5090, 148.1538],
  [-35.5095, 148.1530],
  [-35.5098, 148.1526],
  [-35.5108, 148.1523],
  [-35.5118, 148.1514],
  [-35.5137, 148.1504],
  [-35.5143, 148.1496],
  [-35.5161, 148.1493],
  [-35.5195, 148.1484],
  [-35.5231, 148.1485]
];

const batlowKunama = [
  [-35.5231, 148.1485],
  [-35.5242, 148.1482],
  [-35.5252, 148.1481],
  [-35.5267, 148.1487],
  [-35.5274, 148.1488],
  [-35.5287, 148.1486],
  [-35.5292, 148.1486],
  [-35.5298, 148.1488],
  [-35.5303, 148.1490],
  [-35.5321, 148.1486],
  [-35.5332, 148.1478],
  [-35.5341, 148.1476],
  [-35.5348, 148.1479],
  [-35.5356, 148.1483],
  [-35.5369, 148.1485],
  [-35.5375, 148.1484],
  [-35.5378, 148.1479],
  [-35.5380, 148.1474],
  [-35.5385, 148.1470],
  [-35.5393, 148.1467],
  [-35.5398, 148.1461],
  [-35.5399, 148.1455],
  [-35.5398, 148.1444],
  [-35.5401, 148.1436],
  [-35.5406, 148.1432],
  [-35.5413, 148.1434],
  [-35.5431, 148.1437],
  [-35.5436, 148.1437],
  [-35.5441, 148.1433],
  [-35.5449, 148.1409],
  [-35.5449, 148.1404],
  [-35.5443, 148.1391],
  [-35.5442, 148.1385],
  [-35.5445, 148.1378],
  [-35.5459, 148.1370],
  [-35.5476, 148.1365],
  [-35.5485, 148.1342],
  [-35.5490, 148.1338],
  [-35.5497, 148.1336],
  [-35.5502, 148.1329],
  [-35.5501, 148.1323],
  [-35.5496, 148.1308],
  [-35.5459, 148.1266],
  [-35.5455, 148.1252],
  [-35.5458, 148.1241],
  [-35.5456, 148.1232],
  [-35.5434, 148.1216],
  [-35.5430, 148.1207],
  [-35.5432, 148.1199],
  [-35.5440, 148.1190],
  [-35.5443, 148.1178],
  [-35.5448, 148.1170],
  [-35.5483, 148.1155],
  [-35.5486, 148.1149],
  [-35.5487, 148.1142],
  [-35.5480, 148.1126],
  [-35.5481, 148.1113],
  [-35.5488, 148.1105],
  [-35.5559, 148.1090],
  [-35.5600, 148.1102],
  [-35.5614, 148.1099],
  [-35.5622, 148.1092],
  [-35.5628, 148.1071]
];

export const kunama: Line = {
  name: 'Kunama',
  state: 'NSW',
  segments: [
    {
      segments: [gilmoreJunctionWereboldera],
      history: {
        opened: {
          date: '1923-12-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-13',
          status: 'closed'
        }, {
          date: '1990-05-01',
          status: 'closed'
        }, {
          date: '1988-12-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [werebolderaBatlow],
      history: {
        opened: {
          date: '1923-12-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-01-13',
          status: 'closed'
        }, {
          date: '1990-05-01',
          status: 'closed'
        }, {
          date: '1989-09-16',
          status: 'open'
        }]
      }
    },
    {
      segments: [batlowKunama],
      history: {
        opened: {
          date: '1923-12-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-02-01',
          status: 'closed'
        }]
      }
    }
  ]
};
