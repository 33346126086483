import { Line } from "../../../trackTypes";

const teRotiKapuni = [
  [-39.4732, 174.2649],
  [-39.4747, 174.2631],
  [-39.4778, 174.2578],
  [-39.4784, 174.2556],
  [-39.4785, 174.2533],
  [-39.4759, 174.2391],
  [-39.4757, 174.2346],
  [-39.4754, 174.2314],
  [-39.4754, 174.1629]
];

const kapuniOpunake = [
  [-39.4754, 174.1629],
  [-39.4754, 174.1397],
  [-39.4757, 174.1375],
  [-39.4760, 174.1342],
  [-39.4756, 174.1314],
  [-39.4753, 174.1295],
  [-39.4750, 173.9688],
  [-39.4742, 173.9615],
  [-39.4724, 173.9537],
  [-39.4606, 173.9171],
  [-39.4554, 173.8848],
  [-39.4534, 173.8637],
  [-39.4531, 173.8626],
  [-39.4512, 173.8580]
];

export const opunake: Line = {
  name: 'Opunake',
  state: 'NZ',
  segments: [
    {
      segments: [teRotiKapuni],
      history: {
        opened: {
          date: '1926-07-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kapuniOpunake],
      history: {
        opened: {
          date: '1926-07-12',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-07-31',
          status: 'closed'
        }]
      }
    }
  ]
}
