import { Line } from "../../../../trackTypes";

const grange = [
  [-27.42703, 153.03225],
  [-27.42689, 153.03213],
  [-27.42681, 153.03200],
  [-27.42656, 153.03035],
  [-27.42657, 153.03020],
  [-27.42669, 153.02960],
  [-27.42669, 153.02937],
  [-27.42651, 153.02809],
  [-27.42651, 153.02786],
  [-27.42661, 153.02745],
  [-27.42672, 153.02716],
  [-27.42691, 153.02677],
  [-27.42693, 153.02665],
  [-27.42694, 153.02649],
  [-27.42682, 153.02567],
  [-27.42682, 153.02556],
  [-27.42685, 153.02542],
  [-27.42731, 153.02384],
  [-27.42732, 153.02369],
  [-27.42721, 153.02323],
  [-27.42715, 153.02300],
  [-27.42587, 153.01378]
];

export const east6: Line = {
  name: 'East 6',
  state: 'QLD',
  segments: [
    {
      segments: [grange],
      history: {
        opened: {
          date: '1928-06-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
