import { Line } from "../../trackTypes";

const line = [
  [-33.0889, 118.4751],
  [-33.0853, 118.4760],
  [-33.0835, 118.4775],
  [-33.0820, 118.4800],
  [-33.0798, 118.4815],
  [-33.0466, 118.4850],
  [-33.0447, 118.4861],
  [-33.0432, 118.4876],
  [-33.0420, 118.4886],
  [-33.0347, 118.4915],
  [-33.0331, 118.4914],
  [-33.0317, 118.4906],
  [-33.0302, 118.4889],
  [-33.0288, 118.4882],
  [-33.0272, 118.4882],
  [-33.0220, 118.4898],
  [-33.0044, 118.4918],
  [-33.0027, 118.4925],
  [-32.9911, 118.5024],
  [-32.9827, 118.5108],
  [-32.9814, 118.5117],
  [-32.9742, 118.5209],
  [-32.9725, 118.5221],
  [-32.9627, 118.5256],
  [-32.9608, 118.5268],
  [-32.9536, 118.5347],
  [-32.9165, 118.5600],
  [-32.9139, 118.5608],
  [-32.8617, 118.5608],
  [-32.8267, 118.5643],
  [-32.8253, 118.5642],
  [-32.8177, 118.5620],
  [-32.8156, 118.5622],
  [-32.8142, 118.5630],
  [-32.7911, 118.5865],
  [-32.7610, 118.6239],
  [-32.7592, 118.6251],
  [-32.7576, 118.6255],
  [-32.7340, 118.6255],
  [-32.7307, 118.6267],
  [-32.6883, 118.6646],
  [-32.6844, 118.6660],
  [-32.6828, 118.6661],
  [-32.6795, 118.6657],
  [-32.6775, 118.6664],
  [-32.6718, 118.6718],
  [-32.6710, 118.6729],
  [-32.6702, 118.6745],
  [-32.6694, 118.6756],
  [-32.6640, 118.6795],
  [-32.6630, 118.6797],
  [-32.6616, 118.6796],
  [-32.6606, 118.6798],
  [-32.6588, 118.6806],
  [-32.6569, 118.6805],
  [-32.6554, 118.6797],
  [-32.6535, 118.6778],
  [-32.6518, 118.6770],
  [-32.6501, 118.6773],
  [-32.6483, 118.6783],
  [-32.6463, 118.6785],
  [-32.6443, 118.6776],
  [-32.6429, 118.6775],
  [-32.6419, 118.6779],
  [-32.6402, 118.6789],
  [-32.6386, 118.6793],
  [-32.6374, 118.6790],
  [-32.6274, 118.6731],
  [-32.6257, 118.6729],
  [-32.6243, 118.6735],
  [-32.6198, 118.6777],
  [-32.6190, 118.6793],
  [-32.6188, 118.6809],
  [-32.6191, 118.6826],
  [-32.6208, 118.6855],
  [-32.6219, 118.6903],
  [-32.6218, 118.6923],
  [-32.6213, 118.6937],
  [-32.6197, 118.6956],
  [-32.6182, 118.6962],
  [-32.6171, 118.6962],
  [-32.6155, 118.6958],
  [-32.6144, 118.6951],
  [-32.6103, 118.6915],
  [-32.6091, 118.6907],
  [-32.5964, 118.6850],
  [-32.5951, 118.6848],
  [-32.5854, 118.6848],
  [-32.5840, 118.6845],
  [-32.5784, 118.6821],
  [-32.5769, 118.6820],
  [-32.5406, 118.6859],
  [-32.5192, 118.6948],
  [-32.5090, 118.6968],
  [-32.5071, 118.6977],
  [-32.5011, 118.7043],
  [-32.4962, 118.7142],
  [-32.4955, 118.7167],
  [-32.4955, 118.7200],
  [-32.4950, 118.7221],
  [-32.4938, 118.7246],
  [-32.4935, 118.7277],
  [-32.4952, 118.7345],
  [-32.4954, 118.7362],
  [-32.4953, 118.7577],
  [-32.4948, 118.7600],
  [-32.4896, 118.7691],
  [-32.4741, 118.8100],
  [-32.4728, 118.8118],
  [-32.4717, 118.8125],
  [-32.4688, 118.8139],
  [-32.4639, 118.8179],
  [-32.4624, 118.8206],
  [-32.4612, 118.8275],
  [-32.4597, 118.8305],
  [-32.4586, 118.8352],
  [-32.4586, 118.8369],
  [-32.4592, 118.8396],
  [-32.4589, 118.8438],
  [-32.4559, 118.8513],
  [-32.4549, 118.8551],
  [-32.4533, 118.8575],
  [-32.4490, 118.8679]
];

export const hyden: Line = {
  name: 'Hyden',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1933-07-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
};
