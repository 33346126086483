import { Line } from "../../../../trackTypes";

const coorparoo = [
  [-27.49857, 153.04443],
  [-27.49863, 153.04483],
  [-27.49877, 153.04790],
  [-27.49892, 153.04956],
  [-27.49890, 153.05091],
  [-27.49886, 153.05134],
  [-27.49860, 153.05231],
  [-27.49849, 153.05258],
  [-27.49810, 153.05302],
  [-27.49786, 153.05331],
  [-27.49692, 153.05462],
  [-27.49674, 153.05498],
  [-27.49600, 153.05686],
  [-27.49584, 153.05715],
  [-27.49529, 153.05782],
  [-27.49509, 153.05810],
  [-27.49459, 153.05911]
];

const campHill = [
  [-27.49459, 153.05911],
  [-27.49271, 153.06246],
  [-27.49257, 153.06281],
  [-27.49214, 153.06429],
  [-27.49175, 153.06724],
  [-27.49167, 153.06770],
  [-27.49161, 153.06794],
  [-27.49120, 153.06926],
  [-27.49116, 153.06953],
  [-27.49118, 153.06972],
  [-27.49147, 153.07096],
  [-27.49177, 153.07178],
  [-27.49193, 153.07209],
  [-27.49244, 153.07286],
  [-27.49251, 153.07301],
  [-27.49255, 153.07321],
  [-27.49259, 153.07436],
  [-27.49263, 153.07466],
  [-27.49284, 153.07535],
  [-27.49288, 153.07565],
  [-27.49309, 153.07710]
];

const belmont = [
  [-27.49309, 153.07710],
  [-27.49310, 153.07745],
  [-27.49308, 153.08347],
  [-27.49310, 153.08378], //shared allignemnt on belmont tram
  [-27.49316, 153.08408],
  [-27.49327, 153.08433],
  [-27.49340, 153.08455],
  [-27.49356, 153.08472],
  [-27.49369, 153.08484],
  [-27.49543, 153.08621],
  [-27.49560, 153.08638],
  [-27.49573, 153.08658],
  [-27.49583, 153.08679],
  [-27.49589, 153.08700],
  [-27.49591, 153.08730],
  [-27.49588, 153.08754],
  [-27.49564, 153.08933]
];

export const south5: Line = {
  name: 'South 5',
  state: 'QLD',
  segments: [
    {
      segments: [coorparoo],
      history: {
        opened: {
          date: '1915-02-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [campHill],
      history: {
        opened: {
          date: '1925-01-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [belmont],
      history: {
        opened: {
          date: '1947-08-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    }
  ]
}
