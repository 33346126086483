import { Line } from "../../../trackTypes";

const line = [
  [-20.3567, 118.6329],
  [-20.3666, 118.6315],
  [-20.3754, 118.6334],
  [-20.3771, 118.6342],
  [-20.3783, 118.6357],
  [-20.3866, 118.6551],
  [-20.4148, 118.7129],
  [-20.4539, 118.8076],
  [-20.4642, 118.8240],
  [-20.4717, 118.8354],
  [-20.4721, 118.8374],
  [-20.4688, 118.8482],
  [-20.4696, 118.8525],
  [-20.4773, 118.8749],
  [-20.4790, 118.8863],
  [-20.4896, 118.9045],
  [-20.5588, 119.0170],
  [-20.5793, 119.0695],
  [-20.6099, 119.1199],
  [-20.6750, 119.2532],
  [-20.6770, 119.2556],
  [-20.6830, 119.2678],
  [-20.6834, 119.2701],
  [-20.6829, 119.2779],
  [-20.6833, 119.2799],
  [-20.6902, 119.2939],
  [-20.7098, 119.3243],
  [-20.7102, 119.3352],
  [-20.7109, 119.3373],
  [-20.7892, 119.4532],
  [-20.7983, 119.4766],
  [-20.8000, 119.4792],
  [-20.8269, 119.5108],
  [-20.8280, 119.5128],
  [-20.8318, 119.5258],
  [-20.8430, 119.6284],
  [-20.8431, 119.6302],
  [-20.8410, 119.6476],
  [-20.8412, 119.6518],
  [-20.8426, 119.6541],
  [-20.8528, 119.6626],
  [-20.8543, 119.6649],
  [-20.8663, 119.7007],
  [-20.8722, 119.7204],
  [-20.8723, 119.7221],
  [-20.8716, 119.7252],
  [-20.8719, 119.7274],
  [-20.8749, 119.7325],
  [-20.8754, 119.7340],
  [-20.8774, 119.7514],
  [-20.8780, 119.7545],
  [-20.8881, 119.7750],
  [-20.8906, 119.7872],
  [-20.8943, 119.7942],
  [-20.9112, 119.8216],
  [-20.9122, 119.8227],
  [-20.9128, 119.8230],
  [-20.9136, 119.8230],
  [-20.9168, 119.8214],
  [-20.9195, 119.8212],
  [-20.9214, 119.8219],
  [-20.9269, 119.8263],
  [-20.9286, 119.8270],
  [-20.9302, 119.8270],
  [-20.9330, 119.8259],
  [-20.9399, 119.8193],
  [-20.9566, 119.8112],
  [-20.9571, 119.8107],
  [-20.9575, 119.8099],
  [-20.9577, 119.8055],
  [-20.9581, 119.8028],
  [-20.9587, 119.8006],
  [-20.9592, 119.7998],
  [-20.9633, 119.7957],
  [-20.9658, 119.7928],
  [-20.9673, 119.7899],
  [-20.9736, 119.7852],
  [-20.9744, 119.7849],
  [-20.9784, 119.7848],
  [-20.9792, 119.7847],
  [-20.9818, 119.7829],
  [-20.9871, 119.7802],
  [-21.0190, 119.7708],
  [-21.0221, 119.7708],
  [-21.0267, 119.7693],
  [-21.0277, 119.7693],
  [-21.0302, 119.7704],
  [-21.0318, 119.7706],
  [-21.0382, 119.7699],
  [-21.0537, 119.7705],
  [-21.0600, 119.7726],
  [-21.0616, 119.7719],
  [-21.0643, 119.7665],
  [-21.0659, 119.7648],
  [-21.0873, 119.7613],
  [-21.0893, 119.7605],
  [-21.1050, 119.7585],
  [-21.1075, 119.7558],
  [-21.1379, 119.7451],
  [-21.1438, 119.7440],
  [-21.1450, 119.7432],
  [-21.1458, 119.7414],
  [-21.1470, 119.7409],
  [-21.1519, 119.7407],
  [-21.1596, 119.7361],
  [-21.1602, 119.7360],
  [-21.1608, 119.7363],
  [-21.1614, 119.7364],
  [-21.1638, 119.7361],
  [-21.1689, 119.7374],
  [-21.1700, 119.7379],
  [-21.1713, 119.7393],
  [-21.1725, 119.7397],
  [-21.1768, 119.7412],
  [-21.1784, 119.7424]
];

export const marbleBar: Line = {
  name: 'Marble Bar',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-10-31',
          status: 'closed'
        }]
      }
    }
  ]
};
