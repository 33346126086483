import { Line } from "../../../trackTypes";

const mareebaLappalappa = [
  [-16.9979, 145.4220],
  [-16.9989, 145.4218],
  [-16.9999, 145.4213],
  [-17.0103, 145.4085],
  [-17.0140, 145.4019],
  [-17.0225, 145.3911],
  [-17.0291, 145.3856],
  [-17.0312, 145.3848],
  [-17.0364, 145.3841],
  [-17.0391, 145.3831],
  [-17.0398, 145.3825],
  [-17.0402, 145.3816],
  [-17.0409, 145.3762],
  [-17.0415, 145.3751],
  [-17.0424, 145.3748],
  [-17.0450, 145.3748],
  [-17.0460, 145.3753],
  [-17.0467, 145.3761],
  [-17.0478, 145.3765],
  [-17.0494, 145.3762],
  [-17.0535, 145.3767],
  [-17.0543, 145.3771],
  [-17.0561, 145.3790],
  [-17.0567, 145.3793],
  [-17.0587, 145.3797],
  [-17.0618, 145.3807],
  [-17.0656, 145.3806],
  [-17.0693, 145.3794],
  [-17.0712, 145.3778],
  [-17.0859, 145.3503],
  [-17.1067, 145.3164],
  [-17.1085, 145.3118],
  [-17.1124, 145.2932],
  [-17.1131, 145.2906],
  [-17.1192, 145.2753],
  [-17.1200, 145.2740],
  [-17.1230, 145.2712],
  [-17.1234, 145.2701],
  [-17.1233, 145.2685],
  [-17.1236, 145.2669],
  [-17.1243, 145.2656],
  [-17.1387, 145.2519],
  [-17.1393, 145.2507],
  [-17.1396, 145.2494],
  [-17.1392, 145.2352],
  [-17.1383, 145.2227],
  [-17.1390, 145.2125],
  [-17.1388, 145.2108],
  [-17.1314, 145.1955],
  [-17.1310, 145.1927],
  [-17.1339, 145.1772],
  [-17.1339, 145.1755],
  [-17.1315, 145.1601],
  [-17.1320, 145.1338],
  [-17.1328, 145.1316],
  [-17.1355, 145.1277],
  [-17.1370, 145.1264],
  [-17.1389, 145.1256],
  [-17.1403, 145.1247],
  [-17.1440, 145.1215],
  [-17.1448, 145.1204],
  [-17.1546, 145.1013],
  [-17.1551, 145.0997],
  [-17.1557, 145.0953],
  [-17.1566, 145.0927],
  [-17.1583, 145.0904],
  [-17.1599, 145.0893],
  [-17.1621, 145.0884],
  [-17.1639, 145.0870],
  [-17.1651, 145.0852],
  [-17.1666, 145.0841],
  [-17.1685, 145.0835],
  [-17.1697, 145.0829],
  [-17.1708, 145.0817],
  [-17.1746, 145.0757],
  [-17.1762, 145.0740],
  [-17.1788, 145.0723],
  [-17.1802, 145.0718],
  [-17.1818, 145.0718],
  [-17.1844, 145.0726],
  [-17.1865, 145.0736],
  [-17.1901, 145.0756],
  [-17.1913, 145.0760],
  [-17.1943, 145.0761],
  [-17.1953, 145.0759],
  [-17.1985, 145.0742],
  [-17.2009, 145.0737],
  [-17.2022, 145.0732],
  [-17.2050, 145.0717],
  [-17.2062, 145.0713],
  [-17.2112, 145.0716],
  [-17.2127, 145.0721],
  [-17.2146, 145.0736],
  [-17.2166, 145.0745],
  [-17.2238, 145.0747],
  [-17.2275, 145.0740],
  [-17.2294, 145.0732],
  [-17.2302, 145.0731],
  [-17.2320, 145.0734],
  [-17.2348, 145.0734],
  [-17.2414, 145.0729],
  [-17.2424, 145.0729],
  [-17.2450, 145.0734],
  [-17.2461, 145.0734],
  [-17.2523, 145.0719],
  [-17.2536, 145.0712],
  [-17.2566, 145.0689],
  [-17.2583, 145.0685],
  [-17.2615, 145.0688],
  [-17.2635, 145.0683],
  [-17.2647, 145.0669],
  [-17.2652, 145.0651],
  [-17.2658, 145.0640],
  [-17.2679, 145.0615],
  [-17.2696, 145.0601],
  [-17.2702, 145.0594],
  [-17.2708, 145.0583],
  [-17.2716, 145.0574],
  [-17.2724, 145.0568],
  [-17.2728, 145.0559],
  [-17.2728, 145.0548],
  [-17.2724, 145.0541],
  [-17.2709, 145.0530],
  [-17.2704, 145.0522],
  [-17.2699, 145.0509],
  [-17.2692, 145.0500],
  [-17.2683, 145.0491],
  [-17.2676, 145.0481],
  [-17.2628, 145.0369],
  [-17.2624, 145.0357],
  [-17.2618, 145.0303],
  [-17.2618, 145.0287],
  [-17.2643, 145.0173],
  [-17.2650, 145.0161],
  [-17.2723, 145.0095],
  [-17.2734, 145.0076],
  [-17.2742, 145.0039],
  [-17.2758, 145.0001],
  [-17.2759, 144.9986],
  [-17.2756, 144.9959],
  [-17.2758, 144.9949],
  [-17.2799, 144.9877],
  [-17.2812, 144.9866],
  [-17.2831, 144.9861],
  [-17.2892, 144.9824],
  [-17.2898, 144.9815],
  [-17.2897, 144.9806],
  [-17.2891, 144.9798],
  [-17.2889, 144.9791],
  [-17.2890, 144.9785],
  [-17.2894, 144.9774],
  [-17.2893, 144.9766],
  [-17.2889, 144.9758],
  [-17.2870, 144.9743],
  [-17.2859, 144.9724],
  [-17.2858, 144.9705],
  [-17.2863, 144.9689],
  [-17.2893, 144.9644],
  [-17.2897, 144.9628],
  [-17.2897, 144.9593],
  [-17.2904, 144.9573],
  [-17.2924, 144.9549],
  [-17.2928, 144.9541],
  [-17.2935, 144.9522],
  [-17.2941, 144.9514],
  [-17.2949, 144.9511],
  [-17.2957, 144.9512],
  [-17.2990, 144.9528],
  [-17.2999, 144.9530],
  [-17.3020, 144.9525],
  [-17.3033, 144.9517],
  [-17.3040, 144.9514],
  [-17.3054, 144.9511],
  [-17.3059, 144.9507],
  [-17.3060, 144.9501],
  [-17.3058, 144.9495],
  [-17.3051, 144.9490],
  [-17.3048, 144.9484],
  [-17.3047, 144.9478],
  [-17.3046, 144.9464],
  [-17.3047, 144.9456],
  [-17.3045, 144.9450],
  [-17.3039, 144.9438],
  [-17.3038, 144.9432],
  [-17.3041, 144.9427],
  [-17.3047, 144.9424],
  [-17.3063, 144.9426],
  [-17.3071, 144.9430],
  [-17.3075, 144.9431],
  [-17.3079, 144.9429],
  [-17.3082, 144.9427],
  [-17.3085, 144.9426],
  [-17.3097, 144.9425],
  [-17.3106, 144.9427],
  [-17.3111, 144.9429],
  [-17.3115, 144.9429],
  [-17.3121, 144.9427],
  [-17.3126, 144.9427],
  [-17.3131, 144.9430],
  [-17.3135, 144.9434],
  [-17.3143, 144.9436],
  [-17.3148, 144.9433],
  [-17.3155, 144.9423],
  [-17.3163, 144.9417],
  [-17.3186, 144.9415],
  [-17.3195, 144.9419],
  [-17.3199, 144.9427],
  [-17.3198, 144.9433],
  [-17.3202, 144.9441],
  [-17.3208, 144.9444],
  [-17.3235, 144.9451],
  [-17.3274, 144.9453],
  [-17.3315, 144.9463],
  [-17.3341, 144.9465],
  [-17.3349, 144.9462],
  [-17.3355, 144.9454],
  [-17.3406, 144.9317],
  [-17.3418, 144.9306],
  [-17.3458, 144.9290],
  [-17.3467, 144.9283],
  [-17.3493, 144.9243],
  [-17.3538, 144.9201],
  [-17.3545, 144.9190],
  [-17.3558, 144.9152],
  [-17.3559, 144.9143],
  [-17.3561, 144.9135],
  [-17.3567, 144.9125],
  [-17.3568, 144.9117],
  [-17.3565, 144.9112],
  [-17.3559, 144.9110],
  [-17.3550, 144.9112],
  [-17.3545, 144.9111],
  [-17.3541, 144.9108],
  [-17.3538, 144.9102],
  [-17.3533, 144.9099],
  [-17.3527, 144.9097],
  [-17.3523, 144.9091],
  [-17.3523, 144.9084],
  [-17.3528, 144.9077],
  [-17.3528, 144.9070],
  [-17.3520, 144.9054],
  [-17.3519, 144.9048],
  [-17.3515, 144.9043],
  [-17.3508, 144.9039],
  [-17.3503, 144.9031],
  [-17.3503, 144.9021],
  [-17.3511, 144.9007],
  [-17.3514, 144.8997],
  [-17.3518, 144.8993],
  [-17.3524, 144.8992],
  [-17.3540, 144.9000],
  [-17.3548, 144.9002],
  [-17.3553, 144.9008],
  [-17.3562, 144.9014],
  [-17.3568, 144.9015],
  [-17.3573, 144.9011],
  [-17.3575, 144.9004],
  [-17.3572, 144.8999],
  [-17.3562, 144.8992],
  [-17.3558, 144.8988],
  [-17.3558, 144.8982],
  [-17.3562, 144.8975],
  [-17.3568, 144.8973],
  [-17.3572, 144.8969],
  [-17.3576, 144.8961],
  [-17.3585, 144.8955],
  [-17.3595, 144.8957],
  [-17.3614, 144.8972],
  [-17.3620, 144.8974],
  [-17.3626, 144.8970],
  [-17.3631, 144.8955],
  [-17.3630, 144.8947],
  [-17.3625, 144.8941],
  [-17.3616, 144.8939],
  [-17.3611, 144.8936],
  [-17.3606, 144.8930],
  [-17.3600, 144.8926],
  [-17.3597, 144.8922],
  [-17.3595, 144.8915],
  [-17.3590, 144.8910],
  [-17.3584, 144.8907],
  [-17.3581, 144.8902],
  [-17.3580, 144.8898],
  [-17.3582, 144.8892],
  [-17.3581, 144.8885],
  [-17.3578, 144.8878],
  [-17.3578, 144.8872],
  [-17.3581, 144.8866],
  [-17.3594, 144.8858],
];

const lappaLappaAlmadenJunction = [
  [-17.3594, 144.8858],
  [-17.3605, 144.8853],
  [-17.3629, 144.8846],
  [-17.3637, 144.8838],
  [-17.3639, 144.8827],
  [-17.3637, 144.8818],
  [-17.3622, 144.8795],
  [-17.3608, 144.8742],
  [-17.3605, 144.8737],
  [-17.3597, 144.8728],
  [-17.3593, 144.8718],
  [-17.3594, 144.8707],
  [-17.3604, 144.8688],
  [-17.3608, 144.8673],
  [-17.3613, 144.8665],
  [-17.3622, 144.8659],
  [-17.3627, 144.8655],
  [-17.3648, 144.8625],
  [-17.3650, 144.8616],
  [-17.3648, 144.8604],
  [-17.3648, 144.8597],
  [-17.3659, 144.8554],
  [-17.3664, 144.8545],
  [-17.3676, 144.8537],
  [-17.3684, 144.8535],
  [-17.3693, 144.8535],
  [-17.3703, 144.8530],
  [-17.3725, 144.8504],
  [-17.3728, 144.8496],
  [-17.3727, 144.8489],
  [-17.3724, 144.8480],
  [-17.3723, 144.8470],
  [-17.3729, 144.8430],
  [-17.3737, 144.8412],
  [-17.3756, 144.8390],
  [-17.3773, 144.8356],
  [-17.3790, 144.8303],
  [-17.3806, 144.8207],
  [-17.3806, 144.8150],
  [-17.3809, 144.8138],
  [-17.3816, 144.8122],
  [-17.3818, 144.8108],
  [-17.3815, 144.8094],
  [-17.3801, 144.8066],
  [-17.3799, 144.8052],
  [-17.3808, 144.8021],
  [-17.3810, 144.8001],
  [-17.3814, 144.7982],
  [-17.3821, 144.7972],
  [-17.3824, 144.7962],
  [-17.3829, 144.7952],
  [-17.3839, 144.7944],
  [-17.3844, 144.7936],
  [-17.3844, 144.7923],
  [-17.3847, 144.7916],
  [-17.3853, 144.7911],
  [-17.3859, 144.7909],
  [-17.3869, 144.7911],
  [-17.3877, 144.7910],
  [-17.3883, 144.7904],
  [-17.3887, 144.7895],
  [-17.3898, 144.7884],
  [-17.3904, 144.7881],
  [-17.3941, 144.7868],
  [-17.3949, 144.7864],
  [-17.3959, 144.7863],
  [-17.3965, 144.7859],
  [-17.3969, 144.7852],
  [-17.3968, 144.7845],
  [-17.3963, 144.7837],
  [-17.3962, 144.7829],
  [-17.3966, 144.7821],
  [-17.3966, 144.7814],
  [-17.3968, 144.7808],
  [-17.3973, 144.7803],
  [-17.3982, 144.7801],
  [-17.4003, 144.7785],
  [-17.4008, 144.7783],
  [-17.4015, 144.7784],
  [-17.4021, 144.7781],
  [-17.4027, 144.7776],
  [-17.4034, 144.7773],
  [-17.4043, 144.7775],
  [-17.4048, 144.7774],
  [-17.4051, 144.7770],
  [-17.4054, 144.7764],
  [-17.4061, 144.7757],
  [-17.4064, 144.7748],
  [-17.4068, 144.7744],
  [-17.4073, 144.7742],
  [-17.4081, 144.7741],
  [-17.4086, 144.7739],
  [-17.4089, 144.7733],
  [-17.4090, 144.7727],
  [-17.4060, 144.7569],
  [-17.4019, 144.7450],
  [-17.4005, 144.7434],
  [-17.3975, 144.7422],
  [-17.3967, 144.7417],
  [-17.3959, 144.7408],
  [-17.3953, 144.7405],
  [-17.3943, 144.7402],
  [-17.3938, 144.7399],
  [-17.3937, 144.7395],
  [-17.3938, 144.7391],
  [-17.3940, 144.7384],
  [-17.3940, 144.7375],
  [-17.3937, 144.7367],
  [-17.3932, 144.7362],
  [-17.3922, 144.7354],
  [-17.3909, 144.7340],
  [-17.3893, 144.7329],
  [-17.3871, 144.7325],
  [-17.3860, 144.7326],
  [-17.3849, 144.7324],
  [-17.3832, 144.7314],
  [-17.3812, 144.7289],
  [-17.3808, 144.7280],
  [-17.3794, 144.7263],
  [-17.3769, 144.7251],
  [-17.3758, 144.7249],
  [-17.3750, 144.7244],
  [-17.3740, 144.7228],
  [-17.3739, 144.7220],
  [-17.3740, 144.7213],
  [-17.3748, 144.7193],
  [-17.3749, 144.7182],
  [-17.3745, 144.7170],
  [-17.3732, 144.7152],
  [-17.3725, 144.7133],
  [-17.3727, 144.7089],
  [-17.3724, 144.7077],
  [-17.3717, 144.7065],
  [-17.3699, 144.7050],
  [-17.3691, 144.7048],
  [-17.3680, 144.7048],
  [-17.3672, 144.7045],
  [-17.3668, 144.7039],
  [-17.3660, 144.7022],
  [-17.3653, 144.7016],
  [-17.3646, 144.7014],
  [-17.3630, 144.7017],
  [-17.3620, 144.7014],
  [-17.3615, 144.7006],
  [-17.3616, 144.6994],
  [-17.3638, 144.6969],
  [-17.3641, 144.6961],
  [-17.3640, 144.6952],
  [-17.3632, 144.6935],
  [-17.3618, 144.6898],
  [-17.3608, 144.6885],
  [-17.3592, 144.6875],
  [-17.3577, 144.6862],
  [-17.3564, 144.6857],
  [-17.3550, 144.6858],
  [-17.3529, 144.6866],
  [-17.3520, 144.6866],
  [-17.3461, 144.6837],
  [-17.3386, 144.6783],
  [-17.3378, 144.6776]
];

const almadenJunctionChillagoe = [
  [-17.3378, 144.6776],
  [-17.3368, 144.6773],
  [-17.3341, 144.6773],
  [-17.3335, 144.6771],
  [-17.3324, 144.6766],
  [-17.3244, 144.6724],
  [-17.3209, 144.6693],
  [-17.3186, 144.6678],
  [-17.3160, 144.6653],
  [-17.3135, 144.6613],
  [-17.3118, 144.6596],
  [-17.3059, 144.6556],
  [-17.2979, 144.6519],
  [-17.2963, 144.6508],
  [-17.2911, 144.6434],
  [-17.2884, 144.6381],
  [-17.2850, 144.6343],
  [-17.2820, 144.6316],
  [-17.2799, 144.6290],
  [-17.2732, 144.6239],
  [-17.2715, 144.6218],
  [-17.2690, 144.6200],
  [-17.2654, 144.6184],
  [-17.2619, 144.6160],
  [-17.2585, 144.6149],
  [-17.2555, 144.6145],
  [-17.2515, 144.6128],
  [-17.2421, 144.6072],
  [-17.2394, 144.6066],
  [-17.2358, 144.6052],
  [-17.2338, 144.6036],
  [-17.2314, 144.6026],
  [-17.2278, 144.6016],
  [-17.2267, 144.6007],
  [-17.2242, 144.5983],
  [-17.2231, 144.5970],
  [-17.2216, 144.5964],
  [-17.2202, 144.5955],
  [-17.2188, 144.5949],
  [-17.2182, 144.5943],
  [-17.2179, 144.5937],
  [-17.2172, 144.5930],
  [-17.2139, 144.5919],
  [-17.2127, 144.5910],
  [-17.2118, 144.5898],
  [-17.2107, 144.5889],
  [-17.2096, 144.5886],
  [-17.2086, 144.5879],
  [-17.2074, 144.5878],
  [-17.2065, 144.5874],
  [-17.2040, 144.5876],
  [-17.2028, 144.5880],
  [-17.2001, 144.5875],
  [-17.1992, 144.5878],
  [-17.1984, 144.5884],
  [-17.1975, 144.5886],
  [-17.1963, 144.5885],
  [-17.1949, 144.5887],
  [-17.1901, 144.5902],
  [-17.1894, 144.5901],
  [-17.1890, 144.5898],
  [-17.1886, 144.5883],
  [-17.1879, 144.5874],
  [-17.1876, 144.5856],
  [-17.1873, 144.5849],
  [-17.1858, 144.5836],
  [-17.1807, 144.5757],
  [-17.1796, 144.5747],
  [-17.1773, 144.5712],
  [-17.1759, 144.5700],
  [-17.1750, 144.5688],
  [-17.1743, 144.5675],
  [-17.1718, 144.5649],
  [-17.1713, 144.5637],
  [-17.1713, 144.5613],
  [-17.1705, 144.5595],
  [-17.1671, 144.5543],
  [-17.1652, 144.5524],
  [-17.1642, 144.5493],
  [-17.1588, 144.5428],
  [-17.1576, 144.5416],
  [-17.1563, 144.5412],
  [-17.1556, 144.5405],
  [-17.1552, 144.5394],
  [-17.1543, 144.5387],
  [-17.1538, 144.5381],
  [-17.1538, 144.5372],
  [-17.1543, 144.5359],
  [-17.1544, 144.5348],
  [-17.1535, 144.5332],
  [-17.1529, 144.5304],
  [-17.1496, 144.5262]
];

const chillagoeMungana = [
  [-17.1496, 144.5262],
  [-17.1487, 144.5245],
  [-17.1480, 144.5213],
  [-17.1457, 144.5170],
  [-17.1451, 144.5138],
  [-17.1437, 144.5061],
  [-17.1420, 144.4979],
  [-17.1425, 144.4894],
  [-17.1435, 144.4797],
  [-17.1441, 144.4785],
  [-17.1440, 144.4772],
  [-17.1420, 144.4749],
  [-17.1416, 144.4737],
  [-17.1417, 144.4729],
  [-17.1423, 144.4713],
  [-17.1423, 144.4698],
  [-17.1377, 144.4570],
  [-17.1375, 144.4554],
  [-17.1382, 144.4489],
  [-17.1386, 144.4479],
  [-17.1391, 144.4472],
  [-17.1394, 144.4464],
  [-17.1392, 144.4447],
  [-17.1393, 144.4440],
  [-17.1390, 144.4430],
  [-17.1380, 144.4421],
  [-17.1377, 144.4409],
  [-17.1380, 144.4390],
  [-17.1377, 144.4374],
  [-17.1366, 144.4354],
  [-17.1363, 144.4341],
  [-17.1365, 144.4315],
  [-17.1363, 144.4301],
  [-17.1352, 144.4282],
  [-17.1348, 144.4262],
  [-17.1268, 144.4134],
  [-17.1259, 144.4128],
  [-17.1249, 144.4128],
  [-17.1233, 144.4135],
  [-17.1221, 144.4134],
  [-17.1204, 144.4120],
  [-17.1192, 144.4117],
  [-17.1185, 144.4110],
  [-17.1173, 144.4097],
  [-17.1144, 144.4012],
  [-17.1116, 144.3988],
  [-17.1103, 144.3964],
  [-17.1007, 144.3874],
  [-17.0996, 144.3871],
  [-17.0988, 144.3870],
  [-17.0979, 144.3867],
  [-17.0870, 144.3759],
  [-17.0791, 144.3663],
  [-17.0681, 144.3618],
  [-17.0534, 144.3512],
  [-17.0515, 144.3507],
  [-17.0503, 144.3507],
  [-17.0492, 144.3500],
  [-17.0468, 144.3478],
  [-17.0357, 144.3320],
  [-17.0322, 144.3261],
  [-17.0299, 144.3234],
  [-17.0257, 144.3210]
];

export const chillagoe: Line = {
  name: 'Chillagoe',
  state: 'QLD',
  segments: [
    {
      segments: [mareebaLappalappa],
      history: {
        opened: {
          date: '1900-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [lappaLappaAlmadenJunction],
      history: {
        opened: {
          date: '1901-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [almadenJunctionChillagoe],
      history: {
        opened: {
          date: '1901-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1943-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chillagoeMungana],
      history: {
        opened: {
          date: '1901-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1943-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
