import { Line } from "../../trackTypes";

const line = [
  [-34.1749, 139.0960],
  [-34.1721, 139.0942],
  [-34.1656, 139.0922],
  [-34.1525, 139.0837],
  [-34.1141, 139.0682],
  [-34.0897, 139.0560],
  [-34.0886, 139.0557],
  [-34.0782, 139.0540],
  [-34.0768, 139.0541],
  [-34.0663, 139.0578],
  [-34.0653, 139.0583],
  [-34.0466, 139.0717],
  [-34.0351, 139.0761],
  [-34.0207, 139.0787],
  [-34.0079, 139.0837],
  [-34.0017, 139.0850],
  [-33.9985, 139.0851],
  [-33.9965, 139.0846],
  [-33.9901, 139.0817]
];

export const robertstown: Line = {
  name: 'Robertstown',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-12-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-09-25',
          status: 'closed'
        }]
      }
    }
  ]
};
