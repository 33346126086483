import { Line } from "../../../trackTypes";

const line = [
  [-33.7896, 150.9517],
  [-33.7902, 150.9512],
  [-33.7907, 150.9506],
  [-33.7925, 150.9459],
  [-33.7933, 150.9428],
  [-33.7945, 150.9405],
  [-33.7967, 150.9386],
  [-33.8005, 150.9375],
  [-33.8060, 150.9346],
  [-33.8074, 150.9334],
  [-33.8078, 150.9317],
  [-33.8081, 150.9262],
  [-33.8096, 150.9226],
  [-33.8125, 150.9199],
  [-33.8135, 150.9199],
  [-33.8198, 150.9196]
];

export const prospectQuarry: Line = {
  name: 'Prospect Quarry',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1902-04-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1945-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
