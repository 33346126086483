import { Line } from "../../trackTypes";

const yilliminningKulin = [
  [-32.9012, 117.3783],
  [-32.9011, 117.3795],
  [-32.9012, 117.3808],
  [-32.9079, 117.4031],
  [-32.9080, 117.4048],
  [-32.9074, 117.4159],
  [-32.9076, 117.4178],
  [-32.9112, 117.4314],
  [-32.9248, 117.4700],
  [-32.9248, 117.4728],
  [-32.9187, 117.4963],
  [-32.9185, 117.4979],
  [-32.9192, 117.5075],
  [-32.9196, 117.5097],
  [-32.9224, 117.5157],
  [-32.9290, 117.5437],
  [-32.9293, 117.5461],
  [-32.9293, 117.5510],
  [-32.9299, 117.5531],
  [-32.9373, 117.5663],
  [-32.9377, 117.5680],
  [-32.9378, 117.5785],
  [-32.9381, 117.5804],
  [-32.9392, 117.5830],
  [-32.9395, 117.5845],
  [-32.9395, 117.5995],
  [-32.9385, 117.6027],
  [-32.9384, 117.6044],
  [-32.9395, 117.6141],
  [-32.9408, 117.6171],
  [-32.9548, 117.6333],
  [-32.9556, 117.6351],
  [-32.9626, 117.6764],
  [-32.9640, 117.6791],
  [-32.9678, 117.6828],
  [-32.9690, 117.6850],
  [-32.9746, 117.7146],
  [-32.9755, 117.7168],
  [-32.9838, 117.7308],
  [-32.9841, 117.7337],
  [-32.9776, 117.7649],
  [-32.9773, 117.7690],
  [-32.9766, 117.7714],
  [-32.9757, 117.7861],
  [-32.9751, 117.7889],
  [-32.9678, 117.8082],
  [-32.9665, 117.8100],
  [-32.9649, 117.8110],
  [-32.9587, 117.8135],
  [-32.9570, 117.8155],
  [-32.9552, 117.8205],
  [-32.9536, 117.8225],
  [-32.9530, 117.8237],
  [-32.9515, 117.8288],
  [-32.9494, 117.8323],
  [-32.9487, 117.8339],
  [-32.9439, 117.8505],
  [-32.9426, 117.8520],
  [-32.9385, 117.8537],
  [-32.9369, 117.8554],
  [-32.9334, 117.8639],
  [-32.9321, 117.8654],
  [-32.9303, 117.8664],
  [-32.9292, 117.8672],
  [-32.9145, 117.8839],
  [-32.9136, 117.8858],
  [-32.9116, 117.8939],
  [-32.9100, 117.8957],
  [-32.9080, 117.8962],
  [-32.9056, 117.8955],
  [-32.9043, 117.8943],
  [-32.9034, 117.8926],
  [-32.9025, 117.8915],
  [-32.9009, 117.8911],
  [-32.8987, 117.8919],
  [-32.8888, 117.8914],
  [-32.8878, 117.8917],
  [-32.8867, 117.8930],
  [-32.8855, 117.8964],
  [-32.8845, 117.8980],
  [-32.8830, 117.8992],
  [-32.8604, 117.9057],
  [-32.8552, 117.9090],
  [-32.8537, 117.9108],
  [-32.8494, 117.9181],
  [-32.8480, 117.9194],
  [-32.8432, 117.9225],
  [-32.8420, 117.9242],
  [-32.8356, 117.9412],
  [-32.8301, 117.9488],
  [-32.8283, 117.9502],
  [-32.8233, 117.9531],
  [-32.8211, 117.9536],
  [-32.8198, 117.9544],
  [-32.8186, 117.9555],
  [-32.8175, 117.9559],
  [-32.8153, 117.9561],
  [-32.8137, 117.9568],
  [-32.8123, 117.9580],
  [-32.8110, 117.9586],
  [-32.8085, 117.9591],
  [-32.8072, 117.9604],
  [-32.8068, 117.9623],
  [-32.8071, 117.9644],
  [-32.8070, 117.9665],
  [-32.8063, 117.9685],
  [-32.8053, 117.9695],
  [-32.8009, 117.9719],
  [-32.7971, 117.9750],
  [-32.7962, 117.9763],
  [-32.7958, 117.9781],
  [-32.7960, 117.9814],
  [-32.7952, 117.9833],
  [-32.7938, 117.9844],
  [-32.7932, 117.9853],
  [-32.7922, 117.9870],
  [-32.7909, 117.9878],
  [-32.7892, 117.9880],
  [-32.7883, 117.9884],
  [-32.7874, 117.9898],
  [-32.7863, 117.9934],
  [-32.7862, 117.9964],
  [-32.7875, 118.0042],
  [-32.7873, 118.0055],
  [-32.7864, 118.0069],
  [-32.7735, 118.0124],
  [-32.7720, 118.0136],
  [-32.7625, 118.0242],
  [-32.7614, 118.0262],
  [-32.7612, 118.0283],
  [-32.7623, 118.0376],
  [-32.7623, 118.0394],
  [-32.7562, 118.0631],
  [-32.7550, 118.0651],
  [-32.7502, 118.0696],
  [-32.7484, 118.0705],
  [-32.7464, 118.0707],
  [-32.7442, 118.0722],
  [-32.7422, 118.0751],
  [-32.7401, 118.0763],
  [-32.7378, 118.0767],
  [-32.7344, 118.0786],
  [-32.7186, 118.0965],
  [-32.7167, 118.0978],
  [-32.7125, 118.0997],
  [-32.7115, 118.1006],
  [-32.7110, 118.1022],
  [-32.7112, 118.1082],
  [-32.7107, 118.1097],
  [-32.7083, 118.1119],
  [-32.7063, 118.1127],
  [-32.7049, 118.1135],
  [-32.7026, 118.1165],
  [-32.7016, 118.1199],
  [-32.6994, 118.1232],
  [-32.6983, 118.1258],
  [-32.6876, 118.1378],
  [-32.6857, 118.1413],
  [-32.6843, 118.1426],
  [-32.6810, 118.1439],
  [-32.6792, 118.1455],
  [-32.6737, 118.1533],
  [-32.6680, 118.1583]
];

const kulinKondinin = [
  [-32.6680, 118.1583],
  [-32.6650, 118.1608],
  [-32.6639, 118.1627],
  [-32.6599, 118.1775],
  [-32.6585, 118.1792],
  [-32.6563, 118.1798],
  [-32.6505, 118.1835],
  [-32.6490, 118.1851],
  [-32.6463, 118.1912],
  [-32.6459, 118.1941],
  [-32.6446, 118.1957],
  [-32.6419, 118.1965],
  [-32.6407, 118.1977],
  [-32.6397, 118.1997],
  [-32.6384, 118.2012],
  [-32.6338, 118.2041],
  [-32.6177, 118.2085],
  [-32.6155, 118.2099],
  [-32.6134, 118.2122],
  [-32.6110, 118.2133],
  [-32.6035, 118.2149],
  [-32.5843, 118.2269],
  [-32.5359, 118.2637],
  [-32.5333, 118.2644],
  [-32.5064, 118.2643],
  [-32.5041, 118.2653],
  [-32.5012, 118.2681],
  [-32.4992, 118.2686],
  [-32.4892, 118.2689]
];

const kondininNarembeen = [
  [-32.4892, 118.2689],
  [-32.4605, 118.2689],
  [-32.4586, 118.2692],
  [-32.4415, 118.2764],
  [-32.4398, 118.2776],
  [-32.4348, 118.2832],
  [-32.4028, 118.3007],
  [-32.4004, 118.3013],
  [-32.3588, 118.3033],
  [-32.3556, 118.3028],
  [-32.3541, 118.3030],
  [-32.3525, 118.3034],
  [-32.3507, 118.3032],
  [-32.3480, 118.3016],
  [-32.3467, 118.3014],
  [-32.3453, 118.3019],
  [-32.3407, 118.3049],
  [-32.3394, 118.3054],
  [-32.3264, 118.3080],
  [-32.3215, 118.3075],
  [-32.3203, 118.3076],
  [-32.3047, 118.3119],
  [-32.2997, 118.3150],
  [-32.2979, 118.3177],
  [-32.2973, 118.3201],
  [-32.2959, 118.3220],
  [-32.2902, 118.3254],
  [-32.2868, 118.3266],
  [-32.2721, 118.3280],
  [-32.2698, 118.3287],
  [-32.2608, 118.3342],
  [-32.2592, 118.3345],
  [-32.1900, 118.3345],
  [-32.1881, 118.3350],
  [-32.1836, 118.3380],
  [-32.1820, 118.3384],
  [-32.1597, 118.3384],
  [-32.1428, 118.3434],
  [-32.1088, 118.3619],
  [-32.1074, 118.3633],
  [-32.1022, 118.3717],
  [-32.1012, 118.3728],
  [-32.0694, 118.3942],
  [-32.0617, 118.3997]
];

const narambeenMerredin = [
  [-32.0617, 118.3997],
  [-32.0514, 118.4075],
  [-32.0330, 118.4210],
  [-32.0252, 118.4258],
  [-32.0042, 118.4430],
  [-31.9831, 118.4523],
  [-31.9808, 118.4539],
  [-31.9517, 118.4837],
  [-31.9251, 118.5064],
  [-31.9155, 118.5113],
  [-31.9140, 118.5127],
  [-31.9090, 118.5205],
  [-31.8798, 118.5458],
  [-31.8748, 118.5522],
  [-31.8719, 118.5533],
  [-31.8535, 118.5496],
  [-31.8517, 118.5496],
  [-31.8163, 118.5605],
  [-31.6963, 118.5601],
  [-31.6894, 118.5624],
  [-31.6838, 118.5655],
  [-31.6821, 118.5658],
  [-31.6736, 118.5658],
  [-31.6719, 118.5653],
  [-31.6489, 118.5548],
  [-31.6311, 118.5508],
  [-31.6292, 118.5498],
  [-31.6146, 118.5336],
  [-31.6136, 118.5318],
  [-31.5962, 118.4863],
  [-31.5951, 118.4792],
  [-31.5947, 118.4777],
  [-31.5934, 118.4752],
  [-31.5926, 118.4719],
  [-31.5932, 118.4681],
  [-31.5953, 118.4635],
  [-31.5956, 118.4603],
  [-31.5909, 118.4471],
  [-31.5897, 118.4413],
  [-31.5866, 118.4344],
  [-31.5863, 118.4324],
  [-31.5863, 118.4276],
  [-31.5856, 118.4253],
  [-31.5798, 118.4169],
  [-31.5785, 118.4158],
  [-31.5696, 118.4117],
  [-31.5672, 118.4092],
  [-31.5572, 118.3862],
  [-31.5569, 118.3846],
  [-31.5569, 118.3714],
  [-31.5490, 118.3376],
  [-31.5483, 118.3359],
  [-31.5431, 118.3277],
  [-31.5403, 118.3250],
  [-31.5346, 118.3216],
  [-31.5332, 118.3201],
  [-31.5326, 118.3185],
  [-31.5319, 118.3136],
  [-31.5309, 118.3112],
  [-31.5219, 118.3006],
  [-31.5203, 118.2996],
  [-31.5152, 118.2986],
  [-31.4850, 118.2887],
  [-31.4841, 118.2877],
  [-31.4834, 118.2860]
];

export const yilliminningMerredin: Line = {
  name: 'Yilliminning - Merredin',
  state: 'WA',
  segments: [
    {
      segments: [yilliminningKulin],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kulinKondinin],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kondininNarembeen],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [narambeenMerredin],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    }
  ]
};
