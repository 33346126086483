import { Line } from "../../../trackTypes";

const junctionHautapu = [
  [-37.7685, 175.3333],
  [-37.7679, 175.3345],
  [-37.7678, 175.3354],
  [-37.7679, 175.3365],
  [-37.7683, 175.3376],
  [-37.7824, 175.3582],
  [-37.7835, 175.3594],
  [-37.7841, 175.3602],
  [-37.7919, 175.3751],
  [-37.7992, 175.3892],
  [-37.8006, 175.3916],
  [-37.8096, 175.4035],
  [-37.8182, 175.4151],
  [-37.8202, 175.4176],
  [-37.8346, 175.4310],
  [-37.8453, 175.4411],
  [-37.8568, 175.4509],
  [-37.8573, 175.4512],
  [-37.8579, 175.4514],
  [-37.8591, 175.4524],
  [-37.8595, 175.4528],
  [-37.8598, 175.4534],
  [-37.8615, 175.4548]
];

const hautapuCambridge = [
  [-37.8615, 175.4548],
  [-37.8623, 175.4555],
  [-37.8635, 175.4562],
  [-37.8886, 175.4662],
  [-37.8895, 175.4671],
  [-37.8900, 175.4681]
];

export const cambridge: Line = {
  name: 'Cambridge',
  state: 'NZ',
  segments: [
    {
      segments: [junctionHautapu],
      history: {
        opened: {
          date: '1884-10-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hautapuCambridge],
      history: {
        opened: {
          date: '1884-10-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1946-09-09',
          status: 'closed'
        }]
      }
    }
  ]
}
