import { Line } from "../../../trackTypes";

const burngroveGregory = [
  [-23.5908, 148.7544],
  [-23.5906, 148.7509],
  [-23.5895, 148.7470],
  [-23.5829, 148.7349],
  [-23.5794, 148.7308],
  [-23.5651, 148.7181],
  [-23.5483, 148.7098],
  [-23.5441, 148.7065],
  [-23.5303, 148.6902],
  [-23.4947, 148.6591],
  [-23.4905, 148.6567],
  [-23.4581, 148.6458],
  [-23.4249, 148.6273],
  [-23.4219, 148.6255],
  [-23.3960, 148.6042],
  [-23.3902, 148.6012],
  [-23.3609, 148.5956],
  [-23.3552, 148.5927],
  [-23.3222, 148.5618],
  [-23.3184, 148.5596],
  [-23.2839, 148.5489],
  [-23.2785, 148.5441],
  [-23.2720, 148.5319],
  [-23.2706, 148.5274],
  [-23.2677, 148.5069],
  [-23.2652, 148.5002],
  [-23.2409, 148.4653],
  [-23.2361, 148.4569],
  [-23.2149, 148.4204],
  [-23.2110, 148.4162],
  [-23.2063, 148.4134],
  [-23.1941, 148.4097],
  [-23.1778, 148.4046],
  [-23.1587, 148.3987],
  [-23.1505, 148.3960],
  [-23.1482, 148.3962],
  [-23.1465, 148.3978],
  [-23.1460, 148.4001],
  [-23.1471, 148.4026],
  [-23.1493, 148.4037],
  [-23.1514, 148.4032],
  [-23.1570, 148.3992],
  [-23.1579, 148.3988],
  [-23.1587, 148.3987]
];

const enshamBranch = [
  [-23.4249, 148.6273],
  [-23.4222, 148.6241],
  [-23.4214, 148.6206],
  [-23.4216, 148.6180],
  [-23.4226, 148.6156],
  [-23.4385, 148.5931],
  [-23.4565, 148.5705],
  [-23.4593, 148.5643],
  [-23.4632, 148.5449],
  [-23.4665, 148.5271],
  [-23.4679, 148.5256],
  [-23.4697, 148.5253],
  [-23.4715, 148.5267],
  [-23.4718, 148.5289],
  [-23.4639, 148.5436],
  [-23.4632, 148.5449]
];

const goronstoneBranch = [
  [-23.2361, 148.4569],
  [-23.2350, 148.4540],
  [-23.2318, 148.4275],
  [-23.2317, 148.3624],
  [-23.2315, 148.3601],
  [-23.2315, 148.3558],
  [-23.2316, 148.3495],
  [-23.2308, 148.3479],
  [-23.2292, 148.3470],
  [-23.2273, 148.3474],
  [-23.2261, 148.3491],
  [-23.2262, 148.3511],
  [-23.2274, 148.3526],
  [-23.2298, 148.3534],
  [-23.2309, 148.3542],
  [-23.2315, 148.3558]
];

export const gregory: Line = {
  name: 'Gregory',
  state: 'QLD',
  segments: [
    {
      segments: [burngroveGregory],
      history: {
        opened: {
          date: '1980-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [enshamBranch],
      history: {
        opened: {
          date: '1997-01-01',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [goronstoneBranch],
      history: {
        opened: {
          date: '1992-01-01',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
