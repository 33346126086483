import { Line } from "../../../../trackTypes";

const northAdelaideJunction = [
  [-34.90343, 138.59496],
  [-34.90218, 138.59454]
];

const enfield = [
  [-34.90218, 138.59454],
  [-34.90196, 138.59457],
  [-34.90182, 138.59463],
  [-34.89855, 138.59739],
  [-34.89785, 138.59813],
  [-34.89379, 138.60301],
  [-34.89354, 138.60321],
  [-34.89329, 138.60335],
  [-34.88341, 138.60266],
  [-34.87399, 138.60198]
];

export const north3: Line = {
  name: 'North3',
  state: 'SA',
  segments: [
    {
      segments: [northAdelaideJunction],
      history: {
        opened: {
          date: '1883-09-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-04-07',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [enfield],
      history: {
        opened: {
          date: '1883-09-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    }
  ]
};
