import { Line } from "../../trackTypes";

const hanburyEastJunctionWalshPoint = [
  [-32.8844, 151.7080],
  [-32.8819, 151.7080],
  [-32.8802, 151.7090],
  [-32.8784, 151.7108],
  [-32.8754, 151.7147],
  [-32.8655, 151.7241],
  [-32.8639, 151.7270],
  [-32.8633, 151.7305],
  [-32.8670, 151.7563],
  [-32.8711, 151.7731],
  [-32.8727, 151.7756],
  [-32.8751, 151.7774],
  [-32.8780, 151.7779],
  [-32.8843, 151.7765]
];

const hanburyWestJunctionHanburyNorthJunction = [
  [-32.8769, 151.7048],
  [-32.8784, 151.7063],
  [-32.8789, 151.7084],
  [-32.8784, 151.7108]
];

export const kooragangIsland: Line = {
  name: 'Kooragang Island',
  state: 'NSW',
  segments: [
    {
      segments: [hanburyEastJunctionWalshPoint],
      history: {
        opened: {
          date: '1968-01-30',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hanburyWestJunctionHanburyNorthJunction],
      history: {
        opened: {
          date: '1984-03-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
