import { Line } from "../../trackTypes";

const queensCliffLakersSiding = [
  [-38.2642, 144.6616],
  [-38.2640, 144.6601],
  [-38.2640, 144.6594],
  [-38.2640, 144.6589],
  [-38.2642, 144.6582],
  [-38.2645, 144.6575],
  [-38.2649, 144.6569],
  [-38.2663, 144.6552],
  [-38.2667, 144.6545],
  [-38.2672, 144.6528],
  [-38.2673, 144.6516],
  [-38.2674, 144.6487],
  [-38.2676, 144.6477],
  [-38.2678, 144.6470],
  [-38.2681, 144.6465],
  [-38.2695, 144.6441],
  [-38.2699, 144.6430],
  [-38.2701, 144.6421],
  [-38.2703, 144.6410],
  [-38.2708, 144.6349],
  [-38.2708, 144.6338],
  [-38.2706, 144.6331],
  [-38.2703, 144.6319],
  [-38.2700, 144.6311],
  [-38.2625, 144.6166],
  [-38.2612, 144.6145]
];

const lakersSidingMarcus = [
  [-38.2612, 144.6145],
  [-38.2565, 144.6079],
  [-38.2550, 144.6060],
  [-38.2536, 144.6033],
  [-38.2531, 144.6015],
  [-38.2529, 144.6004],
  [-38.2526, 144.5979],
  [-38.2521, 144.5964],
  [-38.2513, 144.5950],
  [-38.2506, 144.5942],
  [-38.2356, 144.5825],
  [-38.2339, 144.5818],
  [-38.2302, 144.5809]
];

const southGeelongDrysdale = [
  [-38.1669, 144.3704],
  [-38.1677, 144.3716],
  [-38.1683, 144.3728],
  [-38.1686, 144.3739],
  [-38.1752, 144.4034],
  [-38.1755, 144.4060],
  [-38.1762, 144.4209],
  [-38.1766, 144.4313],
  [-38.1779, 144.4587],
  [-38.1780, 144.4621],
  [-38.1779, 144.4634],
  [-38.1779, 144.4643],
  [-38.1783, 144.4660],
  [-38.1788, 144.4675],
  [-38.1792, 144.4683],
  [-38.1796, 144.4689],
  [-38.1817, 144.4720],
  [-38.1825, 144.4734],
  [-38.1829, 144.4748],
  [-38.1832, 144.4758],
  [-38.1834, 144.4770],
  [-38.1835, 144.4781],
  [-38.1810, 144.5111],
  [-38.1796, 144.5325],
  [-38.1784, 144.5489],
  [-38.1784, 144.5504],
  [-38.1787, 144.5522],
  [-38.1791, 144.5538],
  [-38.1801, 144.5574]
];

const drysdaleMarcus = [
  [-38.1801, 144.5574],
  [-38.1815, 144.5627],
  [-38.1818, 144.5638],
  [-38.1836, 144.5702],
  [-38.1839, 144.5712],
  [-38.1846, 144.5725],
  [-38.1852, 144.5733],
  [-38.1888, 144.5764],
  [-38.1943, 144.5812],
  [-38.1966, 144.5831],
  [-38.1973, 144.5835],
  [-38.1981, 144.5839],
  [-38.2001, 144.5843],
  [-38.2011, 144.5842],
  [-38.2233, 144.5799],
  [-38.2243, 144.5799],
  [-38.2250, 144.5799],
  [-38.2302, 144.5809]
];

export const queenscliff: Line = {
  name: 'Queenscliff',
  state: 'VIC',
  segments: [
    {
      segments: [southGeelongDrysdale],
      history: {
        opened: {
          date: '1879-05-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-11-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [queensCliffLakersSiding],
      history: {
        opened: {
          date: '1879-05-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-11-06',
          status: 'closed'
        }, {
          date: '1979-05-01',
          status: 'open',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [lakersSidingMarcus],
      history: {
        opened: {
          date: '1879-05-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-11-06',
          status: 'closed'
        }, {
          date: '1979-05-01',
          status: 'open',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [drysdaleMarcus],
      history: {
        opened: {
          date: '1879-05-21',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-11-06',
          status: 'closed'
        }, {
          date: '1979-05-01',
          status: 'open',
          gauge: 'narrow'
        }]
      }
    }
  ]
};
