import { Line } from "../../../trackTypes";

const frankstonBaxter = [
  [-38.1430, 145.1260],
  [-38.1448, 145.1253],
  [-38.1454, 145.1252],
  [-38.1461, 145.1252],
  [-38.1468, 145.1254],
  [-38.1474, 145.1258],
  [-38.1481, 145.1264],
  [-38.1485, 145.1271],
  [-38.1489, 145.1280],
  [-38.1528, 145.1425],
  [-38.1532, 145.1439],
  [-38.1538, 145.1450],
  [-38.1543, 145.1457],
  [-38.1591, 145.1525],
  [-38.1598, 145.1537],
  [-38.1602, 145.1550],
  [-38.1619, 145.1633],
  [-38.1623, 145.1644],
  [-38.1626, 145.1651],
  [-38.1632, 145.1660],
  [-38.1640, 145.1667],
  [-38.1650, 145.1672],
  [-38.1661, 145.1673],
  [-38.1667, 145.1673],
  [-38.1941, 145.1605]
];

const baxterHastings = [
  [-38.1941, 145.1605],
  [-38.1969, 145.1598],
  [-38.1980, 145.1596],
  [-38.1994, 145.1597],
  [-38.2006, 145.1600],
  [-38.2059, 145.1627],
  [-38.2306, 145.1798],
  [-38.2317, 145.1803],
  [-38.2327, 145.1806],
  [-38.2335, 145.1806],
  [-38.2501, 145.1807],
  [-38.2514, 145.1808],
  [-38.2528, 145.1813],
  [-38.2538, 145.1819],
  [-38.2628, 145.1883],
  [-38.2637, 145.1889],
  [-38.2646, 145.1892],
  [-38.2654, 145.1893],
  [-38.2721, 145.1893],
  [-38.2726, 145.1893],
  [-38.2855, 145.1879],
  [-38.2861, 145.1878],
  [-38.2948, 145.1860],
  [-38.2953, 145.1859],
  [-38.2961, 145.1859],
  [-38.3056, 145.1859]
];

const hastingsStonyPoint = [
  [-38.3056, 145.1859],
  [-38.3140, 145.1859],
  [-38.3149, 145.1858],
  [-38.3155, 145.1856],
  [-38.3191, 145.1837],
  [-38.3200, 145.1834],
  [-38.3211, 145.1833],
  [-38.3287, 145.1833],
  [-38.3296, 145.1831],
  [-38.3311, 145.1823],
  [-38.3388, 145.1769],
  [-38.3399, 145.1764],
  [-38.3410, 145.1764],
  [-38.3419, 145.1767],
  [-38.3425, 145.1772],
  [-38.3601, 145.1962],
  [-38.3636, 145.2000],
  [-38.3646, 145.2013],
  [-38.3649, 145.2019],
  [-38.3671, 145.2062],
  [-38.3677, 145.2078],
  [-38.3692, 145.2125],
  [-38.3700, 145.2142],
  [-38.3730, 145.2185],
  [-38.3737, 145.2198],
  [-38.3739, 145.2207],
  [-38.3740, 145.2213],
  [-38.3740, 145.2218]
];

export const stonyPoint: Line = {
  name: 'Stony Point',
  state: 'VIC',
  segments: [
    {
      segments: [frankstonBaxter],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [baxterHastings],
      history: {
        opened: {
          date: '1889-09-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hastingsStonyPoint],
      history: {
        opened: {
          date: '1889-12-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-06-22',
          status: 'closed'
        }, {
          date: '1984-09-27',
          status: 'open'
        }]
      }
    }
  ]
};
