import { Line } from "../../trackTypes";

const ringwoodBayswater = [
  [-37.81452, 145.2321],
  [-37.8142, 145.2338],
  [-37.8144, 145.2351],
  [-37.8148, 145.2362],
  [-37.8155, 145.2372],
  [-37.8162, 145.2378],
  [-37.8174, 145.2385],
  [-37.8302, 145.2455],
  [-37.8312, 145.2463],
  [-37.8320, 145.2474],
  [-37.8326, 145.2489],
  [-37.8345, 145.2587],
  [-37.8350, 145.2605],
  [-37.8358, 145.2620],
  [-37.8370, 145.2636],
  [-37.8411, 145.2674]
];

const bayswaterFerntreeGully = [
  [-37.8411, 145.2674],
  [-37.8437, 145.2701],
  [-37.8563, 145.2823],
  [-37.8567, 145.2826],
  [-37.8573, 145.2829],
  [-37.8586, 145.2836],
  [-37.8629, 145.2858],
  [-37.8632, 145.2859],
  [-37.8650, 145.2865],
  [-37.8686, 145.2875],
  [-37.8695, 145.2879],
  [-37.8706, 145.2886],
  [-37.8731, 145.2907],
  [-37.8739, 145.2912],
  [-37.8815, 145.2951]
];

const ferntreeGullyUpperFerntreeGully = [
  [-37.8815, 145.2951],
  [-37.8878, 145.2983],
  [-37.8890, 145.2993],
  [-37.8898, 145.3003],
  [-37.8908, 145.3021],
  [-37.8923, 145.3060],
  [-37.8926, 145.3078]
]

const upperFerntreeGullyBelgrave = [
  [-37.8926, 145.3078],
  [-37.8930, 145.3106],
  [-37.8929, 145.3128],
  [-37.8931, 145.3140],
  [-37.8937, 145.3149],
  [-37.8946, 145.3155],
  [-37.8951, 145.3161],
  [-37.8955, 145.3167],
  [-37.8967, 145.3188],
  [-37.8974, 145.3200],
  [-37.8997, 145.3240],
  [-37.9003, 145.3254],
  [-37.9015, 145.3277],
  [-37.9019, 145.3287],
  [-37.9028, 145.3304],
  [-37.9045, 145.3324],
  [-37.9051, 145.3339],
  [-37.9053, 145.3347],
  [-37.9054, 145.3356],
  [-37.9055, 145.3363],
  [-37.9057, 145.3370],
  [-37.9066, 145.3385],
  [-37.9070, 145.3398],
  [-37.9071, 145.3407],
  [-37.9073, 145.3415],
  [-37.9075, 145.3420],
  [-37.9085, 145.3436],
  [-37.9088, 145.3447],
  [-37.9089, 145.3460],
  [-37.9089, 145.3464],
  [-37.9090, 145.3469],
  [-37.9093, 145.3489],
  [-37.9096, 145.3498],
  [-37.9100, 145.3506],
  [-37.9104, 145.3514],
  [-37.9105, 145.3521],
  [-37.9104, 145.3531],
  [-37.9101, 145.3539],
  [-37.9098, 145.3545],
  [-37.9088, 145.3554]
];

export const belgrave: Line = {
  name: 'Belgrave',
  state: 'VIC',
  segments: [
    {
      segments: [ringwoodBayswater],
      history: {
        opened: {
          date: '1889-12-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-12-19',
          tracks: 2
        }, {
          date: '1925-10-12',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [bayswaterFerntreeGully],
      history: {
        opened: {
          date: '1889-12-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-02-10',
          tracks: 2
        }, {
          date: '1925-10-12',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [ferntreeGullyUpperFerntreeGully],
      history: {
        opened: {
          date: '1889-12-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1925-10-12',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [upperFerntreeGullyBelgrave],
      history: {
        opened: {
          date: '1900-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-04-30',
          status: 'closed'
        }, {
          date: '1962-02-18',
          status: 'open'
        }, {
          date: '1962-02-18',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1962-02-18',
          gauge: 'broad'
        }]
      }
    }
  ]
}
