import { Line } from "../../trackTypes";

const monklandAmamoor = [
  [-26.2208, 152.6967],
  [-26.2219, 152.6959],
  [-26.2226, 152.6954],
  [-26.2248, 152.6922],
  [-26.2262, 152.6917],
  [-26.2271, 152.6922],
  [-26.2285, 152.6939],
  [-26.2318, 152.6952],
  [-26.2335, 152.6947],
  [-26.2345, 152.6940],
  [-26.2357, 152.6924],
  [-26.2362, 152.6920],
  [-26.2425, 152.6891],
  [-26.2449, 152.6867],
  [-26.2454, 152.6852],
  [-26.2457, 152.6816],
  [-26.2462, 152.6806],
  [-26.2472, 152.6797],
  [-26.2475, 152.6792],
  [-26.2479, 152.6782],
  [-26.2482, 152.6777],
  [-26.2491, 152.6769],
  [-26.2495, 152.6763],
  [-26.2500, 152.6746],
  [-26.2507, 152.6738],
  [-26.2522, 152.6731],
  [-26.2529, 152.6719],
  [-26.2539, 152.6715],
  [-26.2550, 152.6719],
  [-26.2560, 152.6733],
  [-26.2566, 152.6737],
  [-26.2578, 152.6739],
  [-26.2594, 152.6749],
  [-26.2609, 152.6750],
  [-26.2622, 152.6743],
  [-26.2637, 152.6741],
  [-26.2652, 152.6744],
  [-26.2679, 152.6739],
  [-26.2686, 152.6731],
  [-26.2689, 152.6720],
  [-26.2695, 152.6712],
  [-26.2704, 152.6710],
  [-26.2716, 152.6715],
  [-26.2725, 152.6714],
  [-26.2748, 152.6702],
  [-26.2759, 152.6701],
  [-26.2772, 152.6704],
  [-26.2781, 152.6700],
  [-26.2791, 152.6686],
  [-26.2798, 152.6681],
  [-26.2807, 152.6685],
  [-26.2814, 152.6688],
  [-26.2819, 152.6698],
  [-26.2827, 152.6703],
  [-26.2851, 152.6703],
  [-26.2857, 152.6705],
  [-26.2862, 152.6711],
  [-26.2866, 152.6723],
  [-26.2872, 152.6729],
  [-26.2897, 152.6743],
  [-26.2902, 152.6754],
  [-26.2902, 152.6766],
  [-26.2904, 152.6772],
  [-26.2916, 152.6784],
  [-26.2925, 152.6786],
  [-26.2936, 152.6782],
  [-26.2947, 152.6770],
  [-26.2955, 152.6765],
  [-26.2965, 152.6764],
  [-26.2979, 152.6769],
  [-26.2988, 152.6768],
  [-26.3007, 152.6760],
  [-26.3018, 152.6763],
  [-26.3027, 152.6770],
  [-26.3037, 152.6775],
  [-26.3058, 152.6781],
  [-26.3084, 152.6796],
  [-26.3117, 152.6803],
  [-26.3132, 152.6809],
  [-26.3141, 152.6806],
  [-26.3146, 152.6795],
  [-26.3141, 152.6768],
  [-26.3142, 152.6762],
  [-26.3147, 152.6755],
  [-26.3179, 152.6742],
  [-26.3190, 152.6746],
  [-26.3202, 152.6760],
  [-26.3216, 152.6763],
  [-26.3230, 152.6753],
  [-26.3239, 152.6750],
  [-26.3250, 152.6757],
  [-26.3274, 152.6808],
  [-26.3280, 152.6812],
  [-26.3288, 152.6812],
  [-26.3311, 152.6799],
  [-26.3320, 152.6796],
  [-26.3379, 152.6785],
  [-26.3394, 152.6777],
  [-26.3437, 152.6737]
];

const amamoorKandanga = [
  [-26.3437, 152.6737],
  [-26.3441, 152.6733],
  [-26.3444, 152.6730],
  [-26.3448, 152.6728],
  [-26.3460, 152.6725],
  [-26.3472, 152.6715],
  [-26.3477, 152.6713],
  [-26.3480, 152.6713],
  [-26.3503, 152.6727],
  [-26.3506, 152.6729],
  [-26.3512, 152.6738],
  [-26.3517, 152.6744],
  [-26.3524, 152.6747],
  [-26.3534, 152.6745],
  [-26.3547, 152.6746],
  [-26.3575, 152.6760],
  [-26.3584, 152.6768],
  [-26.3591, 152.6778],
  [-26.3599, 152.6783],
  [-26.3611, 152.6781],
  [-26.3625, 152.6770],
  [-26.3633, 152.6767],
  [-26.3659, 152.6776],
  [-26.3674, 152.6789],
  [-26.3684, 152.6790],
  [-26.3694, 152.6783],
  [-26.3698, 152.6774],
  [-26.3698, 152.6757],
  [-26.3702, 152.6749],
  [-26.3712, 152.6747],
  [-26.3755, 152.6774],
  [-26.3762, 152.6780],
  [-26.3776, 152.6805],
  [-26.3787, 152.6814],
  [-26.3805, 152.6815],
  [-26.3819, 152.6805],
  [-26.3834, 152.6777],
  [-26.3840, 152.6772],
  [-26.3866, 152.6765],
  [-26.3898, 152.6734]
];

const kandangeMelawondiTunnel = [
  [-26.3898, 152.6734],
  [-26.3911, 152.6727],
  [-26.3926, 152.6725],
  [-26.3941, 152.6728],
  [-26.3950, 152.6725],
  [-26.3958, 152.6718],
  [-26.3963, 152.6702],
  [-26.3971, 152.6688],
  [-26.3984, 152.6675],
  [-26.4056, 152.6636],
  [-26.4061, 152.6631],
  [-26.4066, 152.6621],
  [-26.4073, 152.6613],
  [-26.4118, 152.6605],
  [-26.4145, 152.6589],
  [-26.4159, 152.6586],
  [-26.4176, 152.6592],
  [-26.4185, 152.6607],
  [-26.4182, 152.6629],
  [-26.4187, 152.6641],
  [-26.4196, 152.6644],
  [-26.4209, 152.6642],
  [-26.4217, 152.6635],
  [-26.4224, 152.6620],
  [-26.4233, 152.6613],
  [-26.4245, 152.6614],
  [-26.4255, 152.6612],
  [-26.4267, 152.6603],
  [-26.4276, 152.6604],
  [-26.4282, 152.6611],
  [-26.4285, 152.6621],
  [-26.4289, 152.6627],
  [-26.4306, 152.6634],
  [-26.4316, 152.6634],
  [-26.4332, 152.6624],
  [-26.4347, 152.6628],
  [-26.4350, 152.6631]
];

const melawondiTunnel = [
  [-26.4350, 152.6631],
  [-26.4355, 152.6637]
];

const melawondiTunnelBrooloo = [
  [-26.4355, 152.6637],
  [-26.4361, 152.6644],
  [-26.4372, 152.6650],
  [-26.4390, 152.6646],
  [-26.4421, 152.6650],
  [-26.4431, 152.6658],
  [-26.4437, 152.6671],
  [-26.4450, 152.6678],
  [-26.4466, 152.6671],
  [-26.4503, 152.6664],
  [-26.4523, 152.6669],
  [-26.4540, 152.6689],
  [-26.4556, 152.6740],
  [-26.4563, 152.6749],
  [-26.4575, 152.6754],
  [-26.4639, 152.6766],
  [-26.4666, 152.6778],
  [-26.4674, 152.6788],
  [-26.4679, 152.6803],
  [-26.4684, 152.6810],
  [-26.4696, 152.6810],
  [-26.4703, 152.6806],
  [-26.4710, 152.6794],
  [-26.4718, 152.6788],
  [-26.4722, 152.6788],
  [-26.4739, 152.6796],
  [-26.4748, 152.6808],
  [-26.4774, 152.6827],
  [-26.4786, 152.6830],
  [-26.4795, 152.6828],
  [-26.4815, 152.6819],
  [-26.4826, 152.6819],
  [-26.4834, 152.6828],
  [-26.4836, 152.6850],
  [-26.4847, 152.6884],
  [-26.4847, 152.6908],
  [-26.4838, 152.6933],
  [-26.4835, 152.6949],
  [-26.4838, 152.6960],
  [-26.4883, 152.7010]
];

export const maryValley: Line = {
  name: 'Mary Valley',
  state: 'QLD',
  segments: [
    {
      segments: [monklandAmamoor],
      history: {
        opened: {
          date: '1914-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'closed'
        }, {
          date: '1998-05-23',
          status: 'open'
        }, {
          date: '2018-10-06',
          status: 'open'
        }]
      }
    },
    {
      segments: [amamoorKandanga],
      history: {
        opened: {
          date: '1914-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'closed'
        }, {
          date: '1998-05-23',
          status: 'open'
        }]
      }
    },
    {
      segments: [
        kandangeMelawondiTunnel,
        {
          name: 'Melawondi Tunnel',
          segment: melawondiTunnel,
          type: 'tunnel'
        },
        melawondiTunnelBrooloo
      ],
      history: {
        opened: {
          date: '1915-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'closed'
        }, {
          date: '1998-05-23',
          status: 'open'
        }]
      }
    }
  ]
}
