import { Line } from "../../trackTypes";

const elwickBranch = [
  [-42.8343, 147.2849],
  [-42.8325, 147.2836],
  [-42.8312, 147.2833],
  [-42.8312, 147.2833],
  [-42.8299, 147.2837],
  [-42.8289, 147.2844],
  [-42.8282, 147.2850]
];

const cadburyBranch = [
  [-42.7935, 147.2565],
  [-42.7931, 147.2568],
  [-42.7924, 147.2573],
  [-42.7916, 147.2580],
  [-42.7913, 147.2585],
  [-42.7911, 147.2594],
  [-42.7911, 147.2598],
  [-42.7912, 147.2601],
  [-42.7918, 147.2607],
  [-42.7921, 147.2615],
  [-42.7922, 147.2626],
  [-42.7924, 147.2634],
  [-42.7927, 147.2639],
  [-42.7928, 147.2644],
  [-42.7930, 147.2654],
  [-42.7941, 147.2688]
];

const wharfShared = [
  [-42.8772, 147.3387],
  [-42.8773, 147.3389],
  [-42.8776, 147.3391],
  [-42.8780, 147.3391],
  [-42.8792, 147.3386],
];

const oceanPier = [
  [-42.8792, 147.3386],
  [-42.8800, 147.3388],
  [-42.8816, 147.3399],
  [-42.8818, 147.3402],
  [-42.8819, 147.3406],
  [-42.8820, 147.3413],
  [-42.8815, 147.3450]
];

const kingsPierExtension = [
  [-42.8792, 147.3386],
  [-42.8814, 147.3376],
  [-42.8819, 147.3372],
  [-42.8820, 147.3370],
  [-42.8820, 147.3365],
  [-42.8819, 147.3363],
  [-42.8818, 147.3357],
  [-42.8819, 147.3354],
  [-42.8822, 147.3350],
  [-42.8823, 147.3346],
  [-42.8825, 147.3343],
  [-42.8828, 147.3341],
  [-42.8830, 147.3342],
  [-42.8838, 147.3354]
];

const constitutionDockExtension = [
  [-42.8825, 147.3343],
  [-42.8832, 147.3332],
  [-42.8834, 147.3330],
  [-42.8837, 147.3330],
  [-42.8839, 147.3332],
  [-42.8846, 147.3345]
];

const abattoirsBranch = [
  [-42.8375, 147.2894],
  [-42.8380, 147.2910],
  [-42.8380, 147.2918],
  [-42.8379, 147.2929],
  [-42.8375, 147.2957],
  [-42.8374, 147.2975]
];

const abattoirsSouthJunction = [
  [-42.8379, 147.2929],
  [-42.8382, 147.2920],
  [-42.8387, 147.2915],
  [-42.8392, 147.2915],
  [-42.8397, 147.2916]
];

const risdonExtension = [
  [-42.8374, 147.2975],
  [-42.8371, 147.3014],
  [-42.8367, 147.3030],
  [-42.8360, 147.3039],
  [-42.8344, 147.3047],
  [-42.8339, 147.3052],
  [-42.8329, 147.3064],
  [-42.8311, 147.3067],
  [-42.8303, 147.3073],
  [-42.8298, 147.3086],
  [-42.8298, 147.3097],
  [-42.8301, 147.3107],
  [-42.8301, 147.3116],
  [-42.8299, 147.3128]
];

const brightonIntermodal = [
  [-42.7153, 147.2387],
  [-42.7150, 147.2390],
  [-42.7146, 147.2392],
  [-42.7140, 147.2392],
  [-42.7134, 147.2388],
  [-42.7127, 147.2379],
  [-42.7122, 147.2369],
  [-42.7116, 147.2352],
  [-42.7116, 147.2343],
  [-42.7118, 147.2334],
  [-42.7123, 147.2326],
  [-42.7189, 147.2275],
  [-42.7194, 147.2273],
  [-42.7202, 147.2276],
  [-42.7206, 147.2281],
  [-42.7209, 147.2290],
  [-42.7210, 147.2307]
];

const northArm = [
  [-42.7116, 147.2352],
  [-42.7114, 147.2343],
  [-42.7115, 147.2331],
  [-42.7122, 147.2319],
  [-42.7187, 147.2270],
  [-42.7192, 147.2268],
  [-42.7197, 147.2269],
  [-42.7203, 147.2273],
  [-42.7207, 147.2280],
  [-42.7209, 147.2290]
];

const brightonJunction = [
  [-42.7141, 147.2423],
  [-42.7141, 147.2409],
  [-42.7139, 147.2399],
  [-42.7134, 147.2388]
];

export const hobartBranches: Line = {
  name: 'Hobart Branches',
  state: 'TAS',
  segments: [
    {
      segments: [elwickBranch],
      history: {
        opened: {
          date: '1884-12-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cadburyBranch],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1998-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wharfShared],
      history: {
        opened: {
          date: '1914-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oceanPier],
      history: {
        opened: {
          date: '1914-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-03-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kingsPierExtension],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [constitutionDockExtension],
      history: {
        opened: {
          date: '1934-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-08-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [abattoirsBranch],
      history: {
        opened: {
          date: '1912-05-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [abattoirsSouthJunction],
      history: {
        opened: {
          date: '1912-05-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [risdonExtension],
      history: {
        opened: {
          date: '1919-10-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brightonIntermodal, northArm, brightonJunction],
      history: {
        opened: {
          date: '2014-07-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
