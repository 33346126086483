import { Line } from "../../trackTypes";

const line = [
  [-37.9961, 146.3682],
  [-37.9954, 146.3665],
  [-37.9947, 146.3645],
  [-37.9947, 146.3644],
  [-37.9944, 146.3642],
  [-37.9936, 146.3637],
  [-37.9933, 146.3637],
  [-37.9930, 146.3638],
  [-37.9927, 146.3637],
  [-37.9918, 146.3621],
  [-37.9916, 146.3616],
  [-37.9916, 146.3607],
  [-37.9913, 146.3602],
  [-37.9910, 146.3600],
  [-37.9898, 146.3608],
  [-37.9895, 146.3607],
  [-37.9895, 146.3604],
  [-37.9896, 146.3599],
  [-37.9895, 146.3590],
  [-37.9904, 146.3574],
  [-37.9908, 146.3562],
  [-37.9916, 146.3546],
  [-37.9919, 146.3536],
  [-37.9919, 146.3532],
  [-37.9918, 146.3531],
  [-37.9910, 146.3532],
  [-37.9905, 146.3537],
  [-37.9902, 146.3538],
  [-37.9900, 146.3537],
  [-37.9896, 146.3537],
  [-37.9891, 146.3543],
  [-37.9892, 146.3551],
  [-37.9891, 146.3556],
  [-37.9888, 146.3558],
  [-37.9882, 146.3555],
  [-37.9880, 146.3555],
  [-37.9878, 146.3560],
  [-37.9877, 146.3566],
  [-37.9875, 146.3568],
  [-37.9870, 146.3568],
  [-37.9869, 146.3569],
  [-37.9869, 146.3573],
  [-37.9869, 146.3581],
  [-37.9868, 146.3584],
  [-37.9866, 146.3585],
  [-37.9859, 146.3574],
  [-37.9855, 146.3571],
  [-37.9849, 146.3570],
  [-37.9843, 146.3570],
  [-37.9837, 146.3571],
  [-37.9832, 146.3570],
  [-37.9830, 146.3566],
  [-37.9832, 146.3563],
  [-37.9837, 146.3564],
  [-37.9840, 146.3561],
  [-37.9844, 146.3561],
  [-37.9850, 146.3557],
  [-37.9851, 146.3554],
  [-37.9850, 146.3550],
  [-37.9851, 146.3546],
  [-37.9856, 146.3539],
  [-37.9864, 146.3532],
  [-37.9870, 146.3530],
  [-37.9875, 146.3524],
  [-37.9877, 146.3513],
  [-37.9879, 146.3507],
  [-37.9878, 146.3501],
  [-37.9870, 146.3499],
  [-37.9867, 146.3500],
  [-37.9863, 146.3499],
  [-37.9861, 146.3494],
  [-37.9853, 146.3489],
  [-37.9844, 146.3489],
  [-37.9841, 146.3487],
  [-37.9837, 146.3481],
  [-37.9830, 146.3479],
  [-37.9825, 146.3480],
  [-37.9820, 146.3477],
  [-37.9815, 146.3470],
  [-37.9812, 146.3469],
  [-37.9805, 146.3468],
  [-37.9800, 146.3464],
  [-37.9792, 146.3451],
  [-37.9774, 146.3443],
  [-37.9757, 146.3446],
  [-37.9716, 146.3447],
  [-37.9708, 146.3448],
  [-37.9705, 146.3447],
  [-37.9705, 146.3443],
  [-37.9711, 146.3441],
  [-37.9720, 146.3440],
  [-37.9738, 146.3437],
  [-37.9749, 146.3434],
  [-37.9759, 146.3421],
  [-37.9764, 146.3420],
  [-37.9789, 146.3427],
  [-37.9791, 146.3425],
  [-37.9791, 146.3422],
  [-37.9781, 146.3414],
  [-37.9766, 146.3396],
  [-37.9762, 146.3395],
  [-37.9758, 146.3395],
  [-37.9751, 146.3399],
  [-37.9746, 146.3399],
  [-37.9741, 146.3397],
  [-37.9735, 146.3397],
  [-37.9730, 146.3400],
  [-37.9724, 146.3402],
  [-37.9720, 146.3400],
  [-37.9713, 146.3395],
  [-37.9708, 146.3395],
  [-37.9695, 146.3394],
  [-37.9670, 146.3382],
  [-37.9639, 146.3373],
  [-37.9638, 146.3370],
  [-37.9638, 146.3368],
  [-37.9641, 146.3360],
  [-37.9641, 146.3354],
  [-37.9639, 146.3350],
  [-37.9626, 146.3346],
  [-37.9623, 146.3346],
  [-37.9618, 146.3349],
  [-37.9614, 146.3350],
  [-37.9604, 146.3350],
  [-37.9599, 146.3348],
  [-37.9598, 146.3346],
  [-37.9599, 146.3345],
  [-37.9604, 146.3343]
];

export const tyersValley: Line = {
  name: 'Tyers Valley',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
