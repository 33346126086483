import { Line } from "../../trackTypes";

const line = [
  [-41.0675, 175.4016],
  [-41.0675, 175.4026],
  [-41.0676, 175.4031],
  [-41.0678, 175.4039],
  [-41.0705, 175.4080],
  [-41.0785, 175.4262],
  [-41.0823, 175.4350],
  [-41.0871, 175.4457],
  [-41.0893, 175.4495]
];

export const greytown: Line = {
  name: 'Greytown',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1880-05-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-12-24',
          status: 'closed'
        }]
      }
    }
  ]
}
