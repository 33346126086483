import { Line } from "../../trackTypes";

const line = [
  [-41.5023, 145.6558],
  [-41.5027, 145.6561],
  [-41.5030, 145.6566],
  [-41.5035, 145.6579],
  [-41.5042, 145.6592],
  [-41.5053, 145.6607],
  [-41.5066, 145.6617],
  [-41.5145, 145.6656],
  [-41.5156, 145.6657],
  [-41.5168, 145.6651],
  [-41.5179, 145.6651],
  [-41.5192, 145.6656],
  [-41.5211, 145.6674],
  [-41.5256, 145.6706],
  [-41.5264, 145.6722],
  [-41.5269, 145.6749],
  [-41.5275, 145.6765],
  [-41.5389, 145.6931],
  [-41.5582, 145.7231],
  [-41.5594, 145.7259],
  [-41.5611, 145.7311],
  [-41.5620, 145.7322],
  [-41.5632, 145.7326],
  [-41.5661, 145.7324],
  [-41.5678, 145.7317],
  [-41.5713, 145.7272],
  [-41.5726, 145.7265],
  [-41.5756, 145.7269],
  [-41.5795, 145.7262]
];

export const hellyer: Line = {
  name: 'Hellyer',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1989-04-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2000-06-23',
          status: 'closed'
        }]
      }
    }
  ]
}
