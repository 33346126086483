import { Line } from "../../../trackTypes";

const line = [
  [-21.0773, 140.3019],
  [-21.0842, 140.3052],
  [-21.0995, 140.3108],
  [-21.1011, 140.3109],
  [-21.1065, 140.3094],
  [-21.1090, 140.3080],
  [-21.1107, 140.3055],
  [-21.1128, 140.3046],
  [-21.1226, 140.3028],
  [-21.1240, 140.3028],
  [-21.1259, 140.3038],
  [-21.1278, 140.3043],
  [-21.1324, 140.3042],
  [-21.1348, 140.3052],
  [-21.1392, 140.3140],
  [-21.1426, 140.3167],
  [-21.1455, 140.3175],
  [-21.1478, 140.3191],
  [-21.1491, 140.3221],
  [-21.1487, 140.3291],
  [-21.1495, 140.3319],
  [-21.1667, 140.3555],
  [-21.1686, 140.3570],
  [-21.1715, 140.3584],
  [-21.1729, 140.3594],
  [-21.1844, 140.3765],
  [-21.1900, 140.3807],
  [-21.2027, 140.3934],
  [-21.2048, 140.3967],
  [-21.2065, 140.3982],
  [-21.2154, 140.4027],
  [-21.2203, 140.4064],
  [-21.2216, 140.4084],
  [-21.2231, 140.4127],
  [-21.2239, 140.4138],
  [-21.2250, 140.4147],
  [-21.2260, 140.4161],
  [-21.2272, 140.4206],
  [-21.2285, 140.4228],
  [-21.2634, 140.4539],
  [-21.2693, 140.4609],
  [-21.2696, 140.4625],
  [-21.2693, 140.4640],
  [-21.2685, 140.4657],
  [-21.2684, 140.4673],
  [-21.2704, 140.4751],
  [-21.2715, 140.4771],
  [-21.2744, 140.4801],
  [-21.2755, 140.4817],
  [-21.2763, 140.4830],
  [-21.2779, 140.4840],
  [-21.2795, 140.4843],
  [-21.2810, 140.4853],
  [-21.2838, 140.4904],
  [-21.2843, 140.4925],
  [-21.2838, 140.5002],
  [-21.2844, 140.5017],
  [-21.2864, 140.5030],
  [-21.2895, 140.5034],
  [-21.2907, 140.5029],
  [-21.2930, 140.5007],
  [-21.2950, 140.4992],
  [-21.3052, 140.4951],
  [-21.3377, 140.4945],
  [-21.3398, 140.4939],
  [-21.3476, 140.4886],
  [-21.3496, 140.4879],
  [-21.3569, 140.4893],
  [-21.3654, 140.4927],
  [-21.3683, 140.4929],
  [-21.3767, 140.4910],
  [-21.3837, 140.4913],
  [-21.3851, 140.4915],
  [-21.3976, 140.4976],
  [-21.4003, 140.4983],
  [-21.4022, 140.4983],
  [-21.4033, 140.4986],
  [-21.4212, 140.5083],
  [-21.4233, 140.5088],
  [-21.4349, 140.5082],
  [-21.4387, 140.5070],
  [-21.4585, 140.4935],
  [-21.4610, 140.4925],
  [-21.4734, 140.4894],
  [-21.4751, 140.4893],
  [-21.4793, 140.4899],
  [-21.4810, 140.4898],
  [-21.4872, 140.4902],
  [-21.4907, 140.4914],
  [-21.4924, 140.4917],
  [-21.4998, 140.4905],
  [-21.5015, 140.4909],
  [-21.5028, 140.4918],
  [-21.5052, 140.4923],
  [-21.5074, 140.4937],
  [-21.5159, 140.4973],
  [-21.5229, 140.5014],
  [-21.5244, 140.5018],
  [-21.5304, 140.5005],
  [-21.5325, 140.5006],
  [-21.5365, 140.5026],
  [-21.5393, 140.5037],
  [-21.5409, 140.5047]
];

export const selwyn: Line = {
  name: 'Selwyn',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1910-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
