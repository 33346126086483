import { Line } from "../../../trackTypes";
// - Gloucester street tunnel (beenleigh line)

const upperRomaStreetTunnel = [
  [-27.4660, 153.0136],
  [-27.4665, 153.0129],
  [-27.4670, 153.0125]
];

const merivaleBridge = [
  [-27.4670, 153.0125],
  [-27.4675, 153.0124],
  [-27.4681, 153.0125],
  [-27.4712, 153.0141],
  [-27.4721, 153.0148],
  [-27.4724, 153.0154],
  [-27.4729, 153.0165],
  [-27.4736, 153.0174],
  [-27.4750, 153.0184]
];

const southBrisbaneGloucesterStreetTunnel = [
  [-27.4750, 153.0184],
  [-27.4813, 153.0227],
  [-27.4824, 153.0232],
  [-27.4855, 153.0239]
];

const gloucesterStreetTunnel = [
  [-27.4855, 153.0239],
  [-27.4869, 153.0239]
];

const gloucesterStreetTunnelDuttonPark = [
  [-27.4869, 153.0239],
  [-27.4874, 153.0239],
  [-27.4884, 153.0242],
  [-27.4890, 153.0247],
  [-27.4927, 153.0284],
  [-27.4931, 153.0294],
  [-27.4936, 153.0306],
  [-27.4941, 153.0313],
  [-27.4944, 153.0314],
  [-27.4954, 153.0317],
  [-27.4963, 153.0314]
];

const clevelandJunctionDuttonPark = [
  [-27.4963, 153.0314],
  [-27.4997, 153.0290]
];

const duttonParkFairfield = [
  [-27.4997, 153.0290],
  [-27.5009, 153.0284],
  [-27.5015, 153.0282]
];

const fairfield = [
  [-27.5015, 153.0282],
  [-27.5036, 153.0263],
  [-27.5044, 153.0258],
  [-27.5078, 153.0251],
  [-27.5091, 153.0246],
  [-27.5103, 153.0233],
  [-27.5112, 153.0226],
  [-27.5123, 153.0222],
  [-27.5130, 153.0214],
  [-27.5150, 153.0189],
  [-27.5165, 153.0178]
];

const fairfieldDeviation = [
  [-27.5015, 153.0282],
  [-27.5022, 153.0282],
  [-27.5050, 153.0288],
  [-27.5063, 153.0288],
  [-27.5085, 153.0284],
  [-27.5097, 153.0279],
  [-27.5104, 153.0273],
  [-27.5138, 153.0232],
  [-27.5148, 153.0214],
  [-27.5159, 153.0187],
  [-27.5165, 153.0178]
];

const fairfieldYeerongpilly = [
  [-27.5165, 153.0178],
  [-27.5202, 153.0153],
  [-27.5212, 153.0150],
  [-27.5229, 153.0147],
  [-27.5260, 153.0135]
];

const yeerongpillySailsbury = [
  [-27.5260, 153.0135],
  [-27.5260, 153.0135],
  [-27.5271, 153.0133],
  [-27.5343, 153.0139],
  [-27.5365, 153.0138],
  [-27.5374, 153.0136],
  [-27.5403, 153.0122],
  [-27.5415, 153.0120],
  [-27.5427, 153.0122],
  [-27.5437, 153.0128],
  [-27.5488, 153.0197],
  [-27.5497, 153.0205],
  [-27.5524, 153.0223],
  [-27.5531, 153.0226],
  [-27.5551, 153.0227],
  [-27.5559, 153.0231],
  [-27.5566, 153.0238],
  [-27.5576, 153.0254]
];

const sailsburyKuraby = [
  [-27.5576, 153.0254],
  [-27.5620, 153.0322],
  [-27.5625, 153.0328],
  [-27.5652, 153.0351],
  [-27.5686, 153.0363],
  [-27.5695, 153.0368],
  [-27.5728, 153.0400],
  [-27.5736, 153.0413],
  [-27.5742, 153.0434],
  [-27.5752, 153.0453],
  [-27.5770, 153.0470],
  [-27.5778, 153.0483],
  [-27.5787, 153.0492],
  [-27.5799, 153.0502],
  [-27.5805, 153.0511],
  [-27.5808, 153.0521],
  [-27.5807, 153.0548],
  [-27.5805, 153.0555],
  [-27.5801, 153.0566],
  [-27.5798, 153.0581],
  [-27.5799, 153.0599],
  [-27.5801, 153.0610],
  [-27.5806, 153.0621],
  [-27.5812, 153.0628],
  [-27.5821, 153.0634],
  [-27.5845, 153.0646],
  [-27.5859, 153.0656],
  [-27.5871, 153.0661],
  [-27.5896, 153.0663],
  [-27.5910, 153.0669],
  [-27.5918, 153.0677],
  [-27.5951, 153.0723],
  [-27.5989, 153.0814],
  [-27.6049, 153.0914]
];

const kurabyKingston = [
  [-27.6049, 153.0914],
  [-27.6053, 153.0921],
  [-27.6057, 153.0926],
  [-27.6092, 153.0948],
  [-27.6097, 153.0950],
  [-27.6122, 153.0954],
  [-27.6134, 153.0955],
  [-27.6179, 153.0953],
  [-27.6192, 153.0955],
  [-27.6204, 153.0960],
  [-27.6214, 153.0968],
  [-27.6227, 153.0976],
  [-27.6240, 153.0984],
  [-27.6254, 153.0996],
  [-27.6261, 153.0999],
  [-27.6268, 153.0999],
  [-27.6277, 153.0996],
  [-27.6289, 153.0983],
  [-27.6297, 153.0978],
  [-27.6304, 153.0976],
  [-27.6309, 153.0977],
  [-27.6332, 153.0982],
  [-27.6343, 153.0988],
  [-27.6367, 153.1011],
  [-27.6376, 153.1024],
  [-27.6384, 153.1045],
  [-27.6390, 153.1054],
  [-27.6399, 153.1061],
  [-27.6407, 153.1063],
  [-27.6441, 153.1065],
  [-27.6453, 153.1068],
  [-27.6494, 153.1097],
  [-27.6501, 153.1104],
  [-27.6510, 153.1117],
  [-27.6518, 153.1124],
  [-27.6525, 153.1127],
  [-27.6537, 153.1130],
  [-27.6545, 153.1133],
  [-27.6559, 153.1145],
  [-27.6566, 153.1153],
  [-27.6570, 153.1165],
  [-27.6573, 153.1183]
];

const kingstonLoganlea = [
  [-27.6573, 153.1183],
  [-27.6577, 153.1204],
  [-27.6581, 153.1215],
  [-27.6598, 153.1232],
  [-27.6609, 153.1261],
  [-27.6622, 153.1274],
  [-27.6666, 153.1305],
  [-27.6679, 153.1318],
  [-27.6691, 153.1337],
  [-27.6707, 153.1381]
];

const longanleaBethania = [
  [-27.6707, 153.1381],
  [-27.6710, 153.1388],
  [-27.6713, 153.1401],
  [-27.6718, 153.1438],
  [-27.6724, 153.1457],
  [-27.6734, 153.1473],
  [-27.6745, 153.1482],
  [-27.6760, 153.1491],
  [-27.6792, 153.1523],
  [-27.6805, 153.1531],
  [-27.6830, 153.1539],
  [-27.6848, 153.1550],
  [-27.6894, 153.1595]
];

const bethaniaBethaniaDeviation = [
  [-27.6894, 153.1595],
  [-27.6927, 153.1631],
  [-27.6935, 153.1638],
  [-27.6954, 153.1648],
  [-27.6962, 153.1654]
];

const bethaniaOld = [
  [-27.6962, 153.1654],
  [-27.6987, 153.1676],
  [-27.6991, 153.1687],
  [-27.6990, 153.1702],
  [-27.6982, 153.1721],
  [-27.6977, 153.1734],
  [-27.6978, 153.1745],
  [-27.6984, 153.1754],
  [-27.6992, 153.1766],
  [-27.6994, 153.1773],
  [-27.6995, 153.1789],
  [-27.6999, 153.1798],
  [-27.7005, 153.1802],
  [-27.7017, 153.1803],
  [-27.7021, 153.1804],
  [-27.7028, 153.1813],
  [-27.7030, 153.1823],
  [-27.7033, 153.1829],
  [-27.7041, 153.1836],
  [-27.7048, 153.1837],
  [-27.7058, 153.1835],
  [-27.7064, 153.1836],
  [-27.7069, 153.1838]
];

const bethaniaDeviation = [
  [-27.6962, 153.1654],
  [-27.6967, 153.1659],
  [-27.6977, 153.1677],
  [-27.6982, 153.1699],
  [-27.6979, 153.1722],
  [-27.6982, 153.1744],
  [-27.6989, 153.1761],
  [-27.7015, 153.1794],
  [-27.7026, 153.1804],
  [-27.7059, 153.1827],
  [-27.7069, 153.1838]
];

const bathaniaDevationBeenleighDeviation = [
  [-27.7069, 153.1838],
  [-27.7093, 153.1873],
  [-27.7109, 153.1890],
  [-27.7157, 153.1938],
  [-27.7166, 153.1954],
  [-27.7170, 153.1973],
  [-27.7167, 153.1988],
  [-27.7163, 153.2005],
  [-27.7162, 153.2020],
  [-27.7164, 153.2026]
];

const beenleighOld = [
  [-27.7164, 153.2026],
  [-27.7166, 153.2030],
  [-27.7176, 153.2038],
  [-27.7187, 153.2043],
  [-27.7201, 153.2061],
  [-27.7210, 153.2083],
  [-27.7221, 153.2103],
  [-27.7228, 153.2131],
  [-27.7231, 153.2154],
  [-27.7238, 153.2168],
  [-27.7246, 153.2185]
];

const beenleighDeviation = [
  [-27.7164, 153.2026],
  [-27.7165, 153.2031],
  [-27.7170, 153.2039],
  [-27.7176, 153.2047],
  [-27.7176, 153.2047],
  [-27.7206, 153.2087],
  [-27.7217, 153.2115],
  [-27.7221, 153.2138],
  [-27.7229, 153.2161],
  [-27.7240, 153.2179],
  [-27.7246, 153.2185]
];

export const beenleigh: Line = {
  name: 'Beenleigh',
  state: 'QLD',
  segments: [
    {
      segments: [
        {
          name: 'Upper Roma Street Tunnel',
          segment: upperRomaStreetTunnel,
          type: 'tunnel'
        },
        merivaleBridge
      ],
      history: {
        opened: {
          date: '1978-11-18',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1986-01-01',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [
        southBrisbaneGloucesterStreetTunnel,
        {
          name: 'Gloucester Street Tunnel',
          segment: gloucesterStreetTunnel,
          type: 'tunnel'
        },
        gloucesterStreetTunnelDuttonPark
      ],
      history: {
        opened: {
          date: '1891-12-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1891-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1930-01-01',
          parallelStandard: true
        }, {
          date: '1995-01-01',
          parallelStandard: false,
          tracks: 3,
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [clevelandJunctionDuttonPark],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1893-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1930-01-01',
          parallelStandard: true
        }, {
          date: '1995-01-01',
          parallelStandard: false,
          tracks: 3,
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [duttonParkFairfield],
      history: {
        opened: {
          date: '1884-06-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1893-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1930-01-01',
          parallelStandard: true
        }, {
          date: '1995-01-01',
          parallelStandard: false,
          tracks: 3,
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [{
        date: '1895-01-01',
        original: [fairfield],
        deviation: [fairfieldDeviation]
      }],
      history: {
        opened: {
          date: '1884-06-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1893-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1930-01-01',
          parallelStandard: true
        }, {
          date: '1995-01-01',
          parallelStandard: false,
          tracks: 3,
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [fairfieldYeerongpilly],
      history: {
        opened: {
          date: '1884-06-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1893-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1930-01-01',
          parallelStandard: true
        }, {
          date: '1995-01-01',
          parallelStandard: false,
          tracks: 3,
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [yeerongpillySailsbury],
      history: {
        opened: {
          date: '1885-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1930-01-01',
          parallelStandard: true
        }, {
          date: '1995-01-01',
          parallelStandard: false,
          tracks: 3,
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [sailsburyKuraby],
      history: {
        opened: {
          date: '1885-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1952-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '2008-01-01',
          tracks: 3,
        }]
      }
    },
    {
      segments: [kurabyKingston],
      history: {
        opened: {
          date: '1885-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          tracks: 2
        }, {
          date: '1982-09-18',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [kingstonLoganlea],
      history: {
        opened: {
          date: '1885-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          tracks: 2
        }, {
          date: '1984-11-03',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [longanleaBethania],
      history: {
        opened: {
          date: '1885-09-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          tracks: 2
        }, {
          date: '1984-11-03',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        bethaniaBethaniaDeviation,
        {
          date: '1988-03-12',
          original: [bethaniaOld],
          deviation: [bethaniaDeviation]
        },
        bathaniaDevationBeenleighDeviation,
        {
          date: '1988-03-12',
          original: [beenleighOld],
          deviation: [beenleighDeviation]
        },
      ],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-01-01',
          tracks: 2
        }, {
          date: '1984-11-03',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
