import { Line } from "../../../../trackTypes";

const loop = [
  [-43.53095, 172.63413],
  [-43.53095, 172.62769],
  [-43.53092, 172.62763],
  [-43.53088, 172.62758],
  [-43.53081, 172.62756],
  [-43.52887, 172.62757],
  [-43.52880, 172.62759],
  [-43.52875, 172.62766],
  [-43.52872, 172.62777],
  [-43.52872, 172.62807],
  [-43.52877, 172.62848],
  [-43.52875, 172.63282],
  [-43.52873, 172.63301],
  [-43.52871, 172.63320],
  [-43.52871, 172.63352],
  [-43.52873, 172.63374],
  [-43.52875, 172.63395],
  [-43.52875, 172.63570],
  [-43.52872, 172.63589],
  [-43.52870, 172.63613],
  [-43.52870, 172.63647],
  [-43.52874, 172.63677],
  [-43.52875, 172.63851],
  [-43.52876, 172.63862],
  [-43.52880, 172.63868],
  [-43.52887, 172.63872],
  [-43.52976, 172.63873],
  [-43.52982, 172.63875],
  [-43.52994, 172.63880],
  [-43.53003, 172.63882],
  [-43.53069, 172.63882],
  [-43.53078, 172.63877],
  [-43.53086, 172.63866],
  [-43.53090, 172.63847]
];

const depot = [
  [-43.53069, 172.63882],
  [-43.53080, 172.63882],
  [-43.53086, 172.63884],
  [-43.53093, 172.63891],
  [-43.53098, 172.63898],
  [-43.53105, 172.63902],
  [-43.53129, 172.63902],
  [-43.53137, 172.63898],
  [-43.53145, 172.63894],
  [-43.53162, 172.63894]
];

const cathedralSquare = [
  [-43.53085, 172.63774],
  [-43.53091, 172.63779],
  [-43.53097, 172.63781],
  [-43.53103, 172.63777],
  [-43.53109, 172.63769],
  [-43.53145, 172.63677],
  [-43.53153, 172.63666],
  [-43.53165, 172.63660]
];

const poparStreetLoop = [
  [-43.53513, 172.64089],
  [-43.53515, 172.64097],
  [-43.53516, 172.64106],
  [-43.53512, 172.64122],
  [-43.53507, 172.64130],
  [-43.53502, 172.64134],
  [-43.53433, 172.64134],
  [-43.53426, 172.64130],
  [-43.53421, 172.64125],
  [-43.53418, 172.64114],
  [-43.53418, 172.63975],
  [-43.53417, 172.63967],
  [-43.53414, 172.63955]
];

const newLoop = [
  [-43.53273, 172.63384],
  [-43.53281, 172.63382],
  [-43.53291, 172.63376],
  [-43.53298, 172.63376],
  [-43.53304, 172.63379],
  [-43.53308, 172.63386],
  [-43.53312, 172.63411],
  [-43.53310, 172.63793],
  [-43.53313, 172.63810],
  [-43.53316, 172.63820]
];

export const line11: Line = {
  name: 'Line 11',
  state: 'NZ',
  segments: [
    {
      segments: [loop, depot],
      history: {
        opened: {
          date: '1995-02-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [cathedralSquare, poparStreetLoop, newLoop],
      history: {
        opened: {
          date: '2015-02-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
}
