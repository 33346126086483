import { Line } from "../../../../trackTypes";

const queenStreet = [
  [-27.46392, 153.03143],
  [-27.46482, 153.03123],
  [-27.46501, 153.03113],
  [-27.46523, 153.03094],
  [-27.47092, 153.02370],
  [-27.47155, 153.02275],
  [-27.47295, 153.01998],
  [-27.47324, 153.01968]
];

const victoriaBridgeLoganRoad = [
  [-27.47324, 153.01968],
  [-27.47336, 153.01958],
  [-27.47353, 153.01951],
  [-27.47366, 153.01950],
  [-27.48200, 153.02518],
  [-27.48230, 153.02547],
  [-27.48536, 153.02884],
  [-27.48548, 153.02905],
  [-27.48553, 153.02928],
  [-27.48640, 153.03542],
  [-27.48659, 153.03591],
  [-27.48672, 153.03611],
  [-27.48757, 153.03724],
  [-27.48771, 153.03740],
  [-27.48787, 153.03752],
  [-27.48936, 153.03836],
  [-27.48956, 153.03853],
  [-27.49015, 153.03933],
  [-27.49067, 153.03999],
  [-27.49098, 153.04027],
  [-27.49204, 153.04087],
  [-27.49234, 153.04098],
  [-27.49411, 153.04135],
  [-27.49452, 153.04139],
  [-27.49630, 153.04130],
  [-27.49651, 153.04133],
  [-27.49674, 153.04144],
  [-27.49792, 153.04236],
  [-27.49802, 153.04247],
  [-27.49808, 153.04262],
  [-27.49857, 153.04443],
  [-27.49864, 153.04454],
  [-27.49877, 153.04465],
  [-27.50105, 153.04611]
];

const chatsworthRoad = [
  [-27.50105, 153.04611],
  [-27.50158, 153.04651],
  [-27.50181, 153.04659],
  [-27.50246, 153.04684],
  [-27.50372, 153.04718],
  [-27.50400, 153.04734],
  [-27.50605, 153.04958],
  [-27.50622, 153.04974],
  [-27.50638, 153.04986],
  [-27.50695, 153.05018],
  [-27.50719, 153.05037],
  [-27.50792, 153.05115],
];

const greenslopes = [
  [-27.50792, 153.05115],
  [-27.50796, 153.05134],
  [-27.50795, 153.05150],
  [-27.50782, 153.05170],
  [-27.50577, 153.05487]
];

const northQuayLoop = [
  [-27.46960, 153.02223],
  [-27.46955, 153.02208],
  [-27.46958, 153.02194],
  [-27.47002, 153.02131],
  [-27.47012, 153.02129],
  [-27.47024, 153.02130],
  [-27.47033, 153.02135],
  [-27.47144, 153.02260],
  [-27.47147, 153.02271],
  [-27.47148, 153.02288]
];

const greyStreet = [
  [-27.47385, 153.01855],
  [-27.47404, 153.01850],
  [-27.47420, 153.01852],
  [-27.48249, 153.02423],
  [-27.48263, 153.02436],
  [-27.48275, 153.02454],
  [-27.48284, 153.02480],
  [-27.48297, 153.02596],
  [-27.48303, 153.02616],
  [-27.48312, 153.02637]
];

const arnoldStreet = [
  [-27.50792, 153.05115],
  [-27.50981, 153.05294],
  [-27.51001, 153.05303],
  [-27.51020, 153.05307],
  [-27.51085, 153.05301],
  [-27.51132, 153.05301],
  [-27.51192, 153.05303],
  [-27.51210, 153.05308],
  [-27.51223, 153.05314],
  [-27.51303, 153.05365],
  [-27.51318, 153.05378],
  [-27.51916, 153.06126]
];

const nurseryRoad = [
  [-27.51916, 153.06126],
  [-27.52210, 153.06497],
  [-27.52234, 153.06521],
  [-27.52754, 153.07015],
  [-27.52924, 153.07154]
];

const mtGravatt = [
  [-27.52924, 153.07154],
  [-27.53249, 153.07412],
  [-27.53295, 153.07455],
  [-27.53524, 153.07671],
  [-27.53658, 153.07794]
];

export const south1: Line = {
  name: 'South 1',
  state: 'QLD',
  segments: [
    {
      segments: [queenStreet],
      history: {
        opened: {
          date: '1885-08-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1897-07-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaBridgeLoganRoad],
      history: {
        opened: {
          date: '1885-08-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1897-06-21',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chatsworthRoad],
      history: {
        opened: {
          date: '1914-04-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greenslopes],
      history: {
        opened: {
          date: '1914-04-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1941-06-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northQuayLoop],
      history: {
        opened: {
          date: '1901-09-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greyStreet],
      history: {
        opened: {
          date: '1917-07-16',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [arnoldStreet],
      history: {
        opened: {
          date: '1926-08-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nurseryRoad],
      history: {
        opened: {
          date: '1950-11-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mtGravatt],
      history: {
        opened: {
          date: '1951-03-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    }
  ]
}
