import { Line } from "../../../trackTypes";

const biboohraMountMolloy = [
  [-16.9175, 145.4209],
  [-16.9178, 145.4195],
  [-16.9176, 145.4187],
  [-16.9172, 145.4181],
  [-16.9161, 145.4176],
  [-16.9109, 145.4160],
  [-16.8682, 145.3945],
  [-16.8653, 145.3925],
  [-16.8402, 145.3673],
  [-16.8321, 145.3624],
  [-16.8176, 145.3569],
  [-16.8142, 145.3535],
  [-16.8074, 145.3510],
  [-16.7759, 145.3445],
  [-16.7713, 145.3429],
  [-16.7688, 145.3429],
  [-16.7676, 145.3448],
  [-16.7644, 145.3453],
  [-16.7575, 145.3448],
  [-16.7459, 145.3466],
  [-16.7438, 145.3466],
  [-16.7384, 145.3499],
  [-16.7358, 145.3509],
  [-16.7350, 145.3516],
  [-16.7349, 145.3525],
  [-16.7341, 145.3529],
  [-16.7331, 145.3531],
  [-16.7325, 145.3538],
  [-16.7319, 145.3540],
  [-16.7292, 145.3531],
  [-16.7281, 145.3530],
  [-16.7196, 145.3540],
  [-16.7186, 145.3537],
  [-16.7164, 145.3526],
  [-16.7144, 145.3523],
  [-16.7135, 145.3517],
  [-16.7062, 145.3484],
  [-16.7023, 145.3469],
  [-16.7017, 145.3470],
  [-16.7010, 145.3473],
  [-16.7001, 145.3473],
  [-16.6984, 145.3463],
  [-16.6958, 145.3436],
  [-16.6954, 145.3425],
  [-16.6948, 145.3406],
  [-16.6937, 145.3392],
  [-16.6879, 145.3347],
  [-16.6870, 145.3345],
  [-16.6858, 145.3343],
  [-16.6839, 145.3333],
  [-16.6818, 145.3334],
  [-16.6794, 145.3352],
  [-16.6783, 145.3355],
  [-16.6762, 145.3355],
  [-16.6753, 145.3350],
  [-16.6748, 145.3344],
  [-16.6741, 145.3330]
];

const mountMolloyRumala = [
  [-16.6741, 145.3330],
  [-16.6739, 145.3326],
  [-16.6732, 145.3317],
  [-16.6726, 145.3315],
  [-16.6717, 145.3316],
  [-16.6680, 145.3323],
  [-16.6664, 145.3323],
  [-16.6655, 145.3323],
  [-16.6649, 145.3322],
  [-16.6638, 145.3327],
  [-16.6632, 145.3330],
  [-16.6627, 145.3329],
  [-16.6621, 145.3325],
  [-16.6617, 145.3325],
  [-16.6613, 145.3326],
  [-16.6608, 145.3330],
  [-16.6604, 145.3331],
  [-16.6596, 145.3332],
  [-16.6592, 145.3335],
  [-16.6589, 145.3337],
  [-16.6583, 145.3347],
  [-16.6578, 145.3350],
  [-16.6574, 145.3350],
  [-16.6569, 145.3348],
  [-16.6564, 145.3337],
  [-16.6561, 145.3334],
  [-16.6556, 145.3332],
  [-16.6543, 145.3335],
  [-16.6509, 145.3328],
  [-16.6497, 145.3321],
  [-16.6490, 145.3319],
  [-16.6482, 145.3320],
  [-16.6476, 145.3327],
  [-16.6468, 145.3387],
  [-16.6465, 145.3392],
  [-16.6459, 145.3395],
  [-16.6437, 145.3395],
  [-16.6429, 145.3399],
  [-16.6422, 145.3406],
  [-16.6417, 145.3409],
  [-16.6409, 145.3412],
  [-16.6382, 145.3419],
  [-16.6334, 145.3415],
  [-16.6313, 145.3419],
  [-16.6281, 145.3418],
  [-16.6276, 145.3417],
  [-16.6266, 145.3409],
  [-16.6259, 145.3404],
  [-16.6223, 145.3395],
  [-16.6195, 145.3393],
  [-16.6151, 145.3381],
  [-16.6128, 145.3373],
  [-16.6090, 145.3372],
  [-16.6053, 145.3367],
  [-16.6033, 145.3367],
  [-16.6023, 145.3368],
  [-16.5991, 145.3390],
  [-16.5973, 145.3398],
  [-16.5961, 145.3401],
  [-16.5931, 145.3399],
  [-16.5849, 145.3383]
];

export const julatten: Line = {
  name: 'Julatten',
  state: 'QLD',
  segments: [
    {
      segments: [biboohraMountMolloy],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mountMolloyRumala],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
