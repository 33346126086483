import { Line } from "../../trackTypes";

const royalParkNorthCarlton = [
  [-37.7803, 144.9564],
  [-37.7798, 144.9578],
  [-37.7797, 144.9588],
  [-37.7799, 144.9622],
  [-37.7799, 144.9631],
  [-37.7798, 144.9642],
  [-37.7798, 144.9648],
  [-37.7792, 144.9667]
];

const northCarltonNorthFitzroy = [
  [-37.7792, 144.9667],
  [-37.7790, 144.9678],
  [-37.7789, 144.9692],
  [-37.7789, 144.9705],
  [-37.7799, 144.9787]
];

const northFitzroyFitzroyJunction = [
  [-37.7799, 144.9787],
  [-37.7804, 144.9834]
];

const fitzroyJunctionNorthFitzroyJunction = [
  [-37.7804, 144.9834],
  [-37.7811, 144.9895]
];

const northFitzroyJunctionNorth = [
  [-37.7811, 144.9895],
  [-37.7811, 144.9901],
  [-37.7809, 144.9906],
  [-37.7807, 144.9910]
];

const northFitzroyJunctionSouth = [
  [-37.7811, 144.9895],
  [-37.7812, 144.9897],
  [-37.7816, 144.9904],
  [-37.7820, 144.9909]
];

export const innerCircle: Line = {
  name: 'Inner Circle',
  state: 'VIC',
  segments: [
    {
      segments: [royalParkNorthCarlton],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-09-02',
          tracks: 2
        }, {
          date: '1965-05-02',
          tracks: 1
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-01-01',
          electrified: undefined
        }, {
          date: '1981-07-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northCarltonNorthFitzroy],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-11-04',
          tracks: 2
        }, {
          date: '1965-05-02',
          tracks: 1
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-01-01',
          electrified: undefined
        }, {
          date: '1981-07-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northFitzroyFitzroyJunction],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-11-04',
          tracks: 2
        }, {
          date: '1965-05-02',
          tracks: 1
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-01-01',
          electrified: undefined
        }, {
          date: '1981-07-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northFitzroyJunctionNorth],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1904-12-04',
          tracks: 1
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-01-01',
          electrified: undefined
        }, {
          date: '1965-06-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northFitzroyJunctionSouth],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-01-01',
          electrified: undefined
        }, {
          date: '1965-05-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fitzroyJunctionNorthFitzroyJunction],
      history: {
        opened: {
          date: '1888-05-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1889-10-08',
          tracks: 2
        }, {
          date: '1965-05-02',
          tracks: 1
        }, {
          date: '1921-07-31',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1961-01-01',
          electrified: undefined
        }, {
          date: '1965-05-02',
          status: 'closed'
        }]
      }
    }
  ]
}
