import { Line } from "../../trackTypes";

const line = [
  [-41.8874, 145.3423],
  [-41.8864, 145.3418],
  [-41.8857, 145.3419],
  [-41.8850, 145.3426],
  [-41.8845, 145.3438],
  [-41.8842, 145.3480],
  [-41.8840, 145.3486],
  [-41.8837, 145.3492],
  [-41.8643, 145.3709],
  [-41.8636, 145.3714],
  [-41.8629, 145.3714],
  [-41.8558, 145.3711],
  [-41.8551, 145.3708],
  [-41.8537, 145.3710],
  [-41.8525, 145.3719],
  [-41.8515, 145.3732],
  [-41.8512, 145.3737],
  [-41.8508, 145.3749],
  [-41.8466, 145.3825],
  [-41.8460, 145.3846],
  [-41.8454, 145.3872],
  [-41.8454, 145.3884],
  [-41.8468, 145.3923],
  [-41.8491, 145.3956],
  [-41.8492, 145.3960],
  [-41.8491, 145.3966],
  [-41.8487, 145.3981],
  [-41.8483, 145.3987],
  [-41.8477, 145.3995],
  [-41.8468, 145.4008],
  [-41.8462, 145.4014],
  [-41.8455, 145.4017],
  [-41.8446, 145.4019],
  [-41.8439, 145.4024],
  [-41.8434, 145.4034],
  [-41.8433, 145.4051],
  [-41.8434, 145.4058],
  [-41.8437, 145.4061],
  [-41.8438, 145.4066],
  [-41.8436, 145.4072],
  [-41.8433, 145.4074],
  [-41.8431, 145.4074],
  [-41.8427, 145.4069],
  [-41.8423, 145.4069],
  [-41.8420, 145.4077],
  [-41.8423, 145.4085],
  [-41.8426, 145.4088],
  [-41.8432, 145.4088],
  [-41.8434, 145.4089],
  [-41.8435, 145.4092],
  [-41.8434, 145.4099],
  [-41.8432, 145.4101],
  [-41.8428, 145.4102],
  [-41.8426, 145.4105],
  [-41.8426, 145.4111],
  [-41.8424, 145.4114],
  [-41.8423, 145.4115],
  [-41.8421, 145.4117],
  [-41.8421, 145.4121],
  [-41.8423, 145.4130],
  [-41.8423, 145.4133],
  [-41.8418, 145.4142],
  [-41.8418, 145.4145],
  [-41.8420, 145.4146],
  [-41.8424, 145.4144],
  [-41.8426, 145.4145],
  [-41.8427, 145.4148],
  [-41.8425, 145.4151],
  [-41.8419, 145.4152],
  [-41.8417, 145.4154],
  [-41.8416, 145.4156],
  [-41.8416, 145.4159],
  [-41.8418, 145.4162],
  [-41.8418, 145.4166],
  [-41.8420, 145.4169],
  [-41.8421, 145.4172],
  [-41.8420, 145.4176],
  [-41.8416, 145.4181],
  [-41.8411, 145.4184],
  [-41.8406, 145.4189],
  [-41.8404, 145.4193],
  [-41.8402, 145.4195],
  [-41.8398, 145.4197],
  [-41.8396, 145.4199],
  [-41.8396, 145.4201],
  [-41.8396, 145.4207],
  [-41.8395, 145.4213],
  [-41.8395, 145.4217],
  [-41.8392, 145.4220],
  [-41.8389, 145.4219],
  [-41.8387, 145.4220],
  [-41.8385, 145.4224],
  [-41.8385, 145.4233],
  [-41.8387, 145.4238],
  [-41.8387, 145.4243],
  [-41.8384, 145.4248],
  [-41.8383, 145.4252],
  [-41.8381, 145.4253],
  [-41.8378, 145.4254],
  [-41.8376, 145.4257],
  [-41.8376, 145.4262],
  [-41.8380, 145.4267],
  [-41.8382, 145.4273],
  [-41.8381, 145.4275],
  [-41.8380, 145.4277],
  [-41.8374, 145.4279],
  [-41.8371, 145.4282],
  [-41.8371, 145.4285],
  [-41.8368, 145.4288],
  [-41.8363, 145.4290],
  [-41.8359, 145.4293],
  [-41.8351, 145.4293],
  [-41.8350, 145.4291],
  [-41.8350, 145.4289],
  [-41.8351, 145.4285],
  [-41.8350, 145.4281],
  [-41.8347, 145.4277],
  [-41.8344, 145.4277],
  [-41.8340, 145.4279],
  [-41.8337, 145.4278],
  [-41.8335, 145.4275],
  [-41.8333, 145.4270],
  [-41.8321, 145.4257],
  [-41.8318, 145.4257],
  [-41.8316, 145.4259],
  [-41.8314, 145.4268],
  [-41.8314, 145.4274],
  [-41.8315, 145.4282],
  [-41.8314, 145.4285],
  [-41.8312, 145.4289],
  [-41.8312, 145.4292],
  [-41.8313, 145.4294],
  [-41.8316, 145.4297],
  [-41.8318, 145.4300],
  [-41.8321, 145.4312],
  [-41.8322, 145.4315],
  [-41.8324, 145.4316],
  [-41.8325, 145.4318],
  [-41.8325, 145.4322],
  [-41.8321, 145.4328],
  [-41.8320, 145.4332],
  [-41.8323, 145.4335],
  [-41.8326, 145.4334],
  [-41.8330, 145.4329],
  [-41.8334, 145.4328],
  [-41.8336, 145.4329],
  [-41.8337, 145.4333],
  [-41.8334, 145.4337],
  [-41.8334, 145.4341],
  [-41.8336, 145.4350],
  [-41.8338, 145.4353],
  [-41.8339, 145.4357],
  [-41.8338, 145.4362],
  [-41.8339, 145.4367],
  [-41.8344, 145.4370],
  [-41.8346, 145.4373],
  [-41.8345, 145.4377],
  [-41.8347, 145.4380],
  [-41.8350, 145.4381],
  [-41.8355, 145.4380],
  [-41.8357, 145.4380],
  [-41.8357, 145.4383],
  [-41.8357, 145.4387],
  [-41.8359, 145.4391],
  [-41.8361, 145.4392],
  [-41.8366, 145.4393],
  [-41.8374, 145.4390],
  [-41.8377, 145.4390],
  [-41.8383, 145.4394],
  [-41.8385, 145.4396],
  [-41.8388, 145.4394],
  [-41.8389, 145.4392],
  [-41.8391, 145.4391],
  [-41.8394, 145.4393],
  [-41.8396, 145.4396],
  [-41.8399, 145.4395],
  [-41.8401, 145.4393],
  [-41.8403, 145.4393],
  [-41.8405, 145.4395],
  [-41.8406, 145.4404],
  [-41.8408, 145.4407],
  [-41.8411, 145.4407],
  [-41.8416, 145.4406],
  [-41.8420, 145.4406],
  [-41.8424, 145.4404],
  [-41.8427, 145.4404],
  [-41.8427, 145.4409],
  [-41.8426, 145.4412],
  [-41.8422, 145.4413],
  [-41.8418, 145.4413],
  [-41.8411, 145.4415],
  [-41.8409, 145.4417],
  [-41.8409, 145.4419],
  [-41.8409, 145.4422],
  [-41.8408, 145.4424],
  [-41.8406, 145.4424],
  [-41.8404, 145.4422],
  [-41.8403, 145.4418],
  [-41.8401, 145.4416],
  [-41.8397, 145.4416],
  [-41.8394, 145.4418],
  [-41.8387, 145.4419],
  [-41.8384, 145.4420],
  [-41.8382, 145.4423],
  [-41.8380, 145.4424],
  [-41.8377, 145.4424],
  [-41.8375, 145.4425],
  [-41.8369, 145.4433],
  [-41.8367, 145.4433],
  [-41.8365, 145.4431],
  [-41.8362, 145.4430],
  [-41.8356, 145.4435],
  [-41.8353, 145.4441],
  [-41.8352, 145.4454],
  [-41.8350, 145.4456],
  [-41.8349, 145.4459],
  [-41.8350, 145.4462],
  [-41.8352, 145.4464],
  [-41.8352, 145.4468],
  [-41.8350, 145.4471],
  [-41.8347, 145.4473],
  [-41.8346, 145.4475],
  [-41.8345, 145.4478],
  [-41.8342, 145.4480],
  [-41.8340, 145.4480],
  [-41.8339, 145.4473],
  [-41.8336, 145.4471],
  [-41.8333, 145.4472],
  [-41.8329, 145.4479],
  [-41.8325, 145.4482],
  [-41.8323, 145.4484],
  [-41.8316, 145.4485],
  [-41.8311, 145.4489],
  [-41.8310, 145.4492],
  [-41.8309, 145.4496],
  [-41.8310, 145.4499],
  [-41.8312, 145.4503],
  [-41.8313, 145.4507],
  [-41.8310, 145.4513],
  [-41.8310, 145.4518],
  [-41.8310, 145.4523],
  [-41.8313, 145.4530],
  [-41.8315, 145.4533],
  [-41.8318, 145.4533],
  [-41.8321, 145.4533],
  [-41.8323, 145.4534],
  [-41.8324, 145.4537],
  [-41.8326, 145.4539],
  [-41.8331, 145.4541],
  [-41.8344, 145.4536],
  [-41.8346, 145.4535],
  [-41.8348, 145.4534],
  [-41.8354, 145.4537],
  [-41.8358, 145.4537],
  [-41.8370, 145.4541],
  [-41.8385, 145.4543],
  [-41.8387, 145.4545],
  [-41.8393, 145.4560],
  [-41.8393, 145.4563],
  [-41.8384, 145.4573],
  [-41.8371, 145.4584],
  [-41.8370, 145.4589],
  [-41.8373, 145.4592],
  [-41.8386, 145.4590],
  [-41.8399, 145.4585],
  [-41.8409, 145.4581],
  [-41.8416, 145.4574],
  [-41.8418, 145.4573],
  [-41.8419, 145.4575],
  [-41.8422, 145.4575],
  [-41.8427, 145.4566],
  [-41.8430, 145.4564],
  [-41.8443, 145.4574],
  [-41.8444, 145.4576],
  [-41.8443, 145.4578],
  [-41.8439, 145.4580],
  [-41.8437, 145.4580],
  [-41.8434, 145.4579],
  [-41.8432, 145.4581],
  [-41.8429, 145.4585],
  [-41.8425, 145.4589],
  [-41.8420, 145.4590],
  [-41.8416, 145.4594],
  [-41.8410, 145.4602],
  [-41.8392, 145.4615],
  [-41.8388, 145.4620],
  [-41.8387, 145.4624],
  [-41.8388, 145.4635],
  [-41.8387, 145.4638],
  [-41.8385, 145.4642],
  [-41.8385, 145.4647],
  [-41.8391, 145.4651],
  [-41.8395, 145.4650],
  [-41.8397, 145.4652],
  [-41.8399, 145.4655],
  [-41.8399, 145.4658],
  [-41.8401, 145.4660],
  [-41.8405, 145.4660],
  [-41.8407, 145.4663],
  [-41.8407, 145.4666],
  [-41.8404, 145.4666],
  [-41.8401, 145.4669],
  [-41.8398, 145.4669],
  [-41.8393, 145.4667],
  [-41.8389, 145.4667],
  [-41.8387, 145.4669],
  [-41.8385, 145.4673],
  [-41.8383, 145.4675],
  [-41.8379, 145.4675],
  [-41.8377, 145.4677],
  [-41.8376, 145.4680],
  [-41.8375, 145.4681],
  [-41.8373, 145.4680],
  [-41.8367, 145.4670],
  [-41.8363, 145.4668],
  [-41.8360, 145.4668],
  [-41.8359, 145.4671],
  [-41.8359, 145.4674],
  [-41.8358, 145.4676],
  [-41.8356, 145.4677],
  [-41.8356, 145.4680],
  [-41.8356, 145.4685],
  [-41.8355, 145.4691],
  [-41.8355, 145.4693],
  [-41.8356, 145.4695],
  [-41.8356, 145.4698],
  [-41.8353, 145.4699],
  [-41.8350, 145.4702],
  [-41.8349, 145.4706],
  [-41.8350, 145.4708],
  [-41.8354, 145.4708],
  [-41.8357, 145.4705],
  [-41.8359, 145.4704],
  [-41.8363, 145.4704],
  [-41.8365, 145.4702],
  [-41.8368, 145.4703],
  [-41.8369, 145.4705],
  [-41.8363, 145.4719],
  [-41.8363, 145.4722],
  [-41.8364, 145.4725],
  [-41.8366, 145.4725],
  [-41.8367, 145.4729],
  [-41.8367, 145.4732],
  [-41.8367, 145.4735],
  [-41.8371, 145.4742],
  [-41.8372, 145.4748],
  [-41.8374, 145.4751],
  [-41.8380, 145.4757],
  [-41.8380, 145.4762],
  [-41.8376, 145.4766],
  [-41.8361, 145.4763],
  [-41.8358, 145.4761],
  [-41.8355, 145.4762],
  [-41.8354, 145.4765],
  [-41.8355, 145.4769],
  [-41.8354, 145.4771],
  [-41.8345, 145.4773],
  [-41.8342, 145.4776],
  [-41.8341, 145.4782],
  [-41.8337, 145.4787],
  [-41.8333, 145.4788],
  [-41.8327, 145.4787],
  [-41.8324, 145.4788],
  [-41.8321, 145.4790],
  [-41.8315, 145.4791],
  [-41.8310, 145.4794],
  [-41.8311, 145.4799],
  [-41.8318, 145.4805],
  [-41.8319, 145.4808],
  [-41.8319, 145.4814],
  [-41.8321, 145.4817],
  [-41.8325, 145.4818],
  [-41.8326, 145.4821],
  [-41.8322, 145.4828],
  [-41.8319, 145.4830],
  [-41.8318, 145.4834],
  [-41.8318, 145.4838],
  [-41.8324, 145.4844],
  [-41.8325, 145.4848],
  [-41.8324, 145.4852],
  [-41.8322, 145.4857],
  [-41.8320, 145.4858],
  [-41.8317, 145.4857],
  [-41.8315, 145.4859],
  [-41.8315, 145.4862],
  [-41.8316, 145.4865],
  [-41.8323, 145.4867],
  [-41.8327, 145.4870],
  [-41.8332, 145.4883],
  [-41.8336, 145.4905],
  [-41.8339, 145.4908],
  [-41.8343, 145.4909],
  [-41.8345, 145.4910],
  [-41.8346, 145.4919],
  [-41.8349, 145.4922],
  [-41.8352, 145.4921],
  [-41.8362, 145.4922],
  [-41.8365, 145.4927],
  [-41.8369, 145.4928],
  [-41.8377, 145.4924],
  [-41.8379, 145.4924],
  [-41.8385, 145.4930],
  [-41.8389, 145.4937],
  [-41.8400, 145.4950],
  [-41.8401, 145.4953],
  [-41.8400, 145.4957],
  [-41.8398, 145.4958],
  [-41.8391, 145.4957],
  [-41.8389, 145.4959],
  [-41.8382, 145.4967],
  [-41.8380, 145.4968],
  [-41.8378, 145.4967],
  [-41.8374, 145.4964],
  [-41.8371, 145.4964],
  [-41.8367, 145.4969],
  [-41.8365, 145.4971],
  [-41.8362, 145.4974],
  [-41.8360, 145.4976],
  [-41.8355, 145.4977],
  [-41.8353, 145.4979],
  [-41.8350, 145.4986],
  [-41.8347, 145.4990],
  [-41.8340, 145.4993],
  [-41.8333, 145.4994],
  [-41.8330, 145.4993],
  [-41.8329, 145.4994],
  [-41.8327, 145.4998],
  [-41.8324, 145.4999],
  [-41.8320, 145.4999],
  [-41.8318, 145.5000],
  [-41.8312, 145.5009],
  [-41.8311, 145.5013],
  [-41.8313, 145.5016],
  [-41.8314, 145.5016],
  [-41.8316, 145.5016],
  [-41.8316, 145.5018],
  [-41.8315, 145.5019],
  [-41.8308, 145.5021],
  [-41.8295, 145.5028],
  [-41.8287, 145.5030],
  [-41.8277, 145.5037]
];

// (brewary branch)o1892, c1894
// (race course branch)o1897-01-01

export const dundas: Line = {
  name: 'Dundas',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1892-04-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1933-02-01',
          status: 'closed'
        }]
      }
    }
  ]
}
