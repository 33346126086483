import { Line } from "../../../../trackTypes";

const line = [
  [-37.86707, 144.97747],
  [-37.86693, 144.97891],
  [-37.86693, 144.97951],
  [-37.86796, 144.98724],
  [-37.86834, 144.99013],
  [-37.87263, 145.02452],
  [-37.87267, 145.02490],
  [-37.87428, 145.03788],
  [-37.87437, 145.03812],
  [-37.87550, 145.03938],
  [-37.87555, 145.03954],
  [-37.87555, 145.03963],
  [-37.87541, 145.03985],
  [-37.87539, 145.04002],
  [-37.87546, 145.04016],
  [-37.87570, 145.04038],
  [-37.87588, 145.04058],
  [-37.87651, 145.04146],
  [-37.87654, 145.04160],
  [-37.87652, 145.04169],
  [-37.87642, 145.04179],
  [-37.87572, 145.04191],
  [-37.87559, 145.04200],
  [-37.87550, 145.04212],
  [-37.87546, 145.04235],
  [-37.87728, 145.05903]
];

const stKildaRdJunction = [
  [-37.86774, 144.98690],
  [-37.86788, 144.98705],
  [-37.86796, 144.98724]
];

const grandUnionJunction = [
  [-37.87248, 145.02475],
  [-37.87261, 145.02479],
  [-37.87267, 145.02490],
  [-37.87271, 145.02478],
  [-37.87280, 145.02469],
  [-37.87269, 145.02464],
  [-37.87263, 145.02452],
  [-37.87260, 145.02464],
  [-37.87248, 145.02475]
];

export const east8: Line = {
  name: 'East8',
  state: 'VIC',
  segments: [
    {
      segments: [line, grandUnionJunction],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [stKildaRdJunction],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
