import { Line } from "../../../trackTypes";

const claisebrookArmadale = [
  [-31.9487, 115.8738],
  [-31.9484, 115.8748],
  [-31.9483, 115.8774],
  [-31.9478, 115.8802],
  [-31.9474, 115.8862],
  [-31.9476, 115.8886],
  [-31.9483, 115.8906],
  [-31.9492, 115.8920],
  [-31.9547, 115.8978],
  [-31.9571, 115.8994],
  [-31.9588, 115.8999],
  [-31.9669, 115.9000],
  [-31.9692, 115.9005],
  [-31.9712, 115.9017],
  [-31.9836, 115.9137],
  [-31.9850, 115.9148],
  [-31.9900, 115.9175],
  [-31.9918, 115.9189],
  [-32.0058, 115.9372],
  [-32.0221, 115.9538],
  [-32.0310, 115.9636],
  [-32.0373, 115.9707],
  [-32.0389, 115.9723],
  [-32.0452, 115.9767],
  [-32.0476, 115.9794],
  [-32.0529, 115.9895],
  [-32.0539, 115.9905],
  [-32.0552, 115.9911],
  [-32.0565, 115.9911],
  [-32.0579, 115.9906],
  [-32.0593, 115.9904],
  [-32.0608, 115.9910],
  [-32.0691, 115.9974],
  [-32.0705, 115.9987],
  [-32.0743, 116.0039],
  [-32.0766, 116.0067],
  [-32.0778, 116.0080],
  [-32.0808, 116.0100],
  [-32.0822, 116.0106],
  [-32.0937, 116.0128],
  [-32.0954, 116.0128],
  [-32.1024, 116.0117],
  [-32.1107, 116.0126],
  [-32.1204, 116.0147],
  [-32.1225, 116.0147],
  [-32.1240, 116.0141],
  [-32.1282, 116.0118],
  [-32.1293, 116.0114],
  [-32.1359, 116.0102],
  [-32.1380, 116.0104],
  [-32.1460, 116.0140],
  [-32.1485, 116.0141],
  [-32.1539, 116.0128]
];

export const armidale: Line = {
  name: 'Armidale',
  state: 'WA',
  segments: [
    {
      segments: [claisebrookArmadale],
      history: {
        opened: {
          date: '1893-05-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-09-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }, {
          date: '1968-03-31',
          tracks: 2
        }]
      }
    }
  ]
};
