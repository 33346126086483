import { Line } from "../../trackTypes";

/**
 * glenhope
 * - kawatiri tunnel
 */

const nelsonPortNelson = [
  [-41.2601, 173.2712],
  [-41.2613, 173.2698],
  [-41.2616, 173.2698],
  [-41.2618, 173.2699],
  [-41.2631, 173.2713],
  [-41.2640, 173.2718],
  [-41.2647, 173.2723],
  [-41.2652, 173.2732],
  [-41.2671, 173.2778],
  [-41.2678, 173.2783],
  [-41.2702, 173.2790],
  [-41.2708, 173.2790],
  [-41.2712, 173.2787],
  [-41.2733, 173.2768]
];

const nelsonFoxhill = [
  [-41.2733, 173.2768],
  [-41.2820, 173.2686],
  [-41.2828, 173.2681],
  [-41.2864, 173.2661],
  [-41.2869, 173.2659],
  [-41.2904, 173.2657],
  [-41.2909, 173.2657],
  [-41.2922, 173.2654],
  [-41.2942, 173.2649],
  [-41.2956, 173.2643],
  [-41.2963, 173.2639],
  [-41.2993, 173.2615],
  [-41.3004, 173.2604],
  [-41.3012, 173.2589],
  [-41.3019, 173.2580],
  [-41.3029, 173.2573],
  [-41.3034, 173.2568],
  [-41.3038, 173.2559],
  [-41.3039, 173.2553],
  [-41.3037, 173.2540],
  [-41.3023, 173.2518],
  [-41.3015, 173.2510],
  [-41.2991, 173.2478],
  [-41.2983, 173.2462],
  [-41.2982, 173.2457],
  [-41.2982, 173.2452],
  [-41.2987, 173.2435],
  [-41.2991, 173.2429],
  [-41.3160, 173.2274],
  [-41.3178, 173.2251],
  [-41.3312, 173.2028],
  [-41.3320, 173.1998],
  [-41.3360, 173.1805],
  [-41.3367, 173.1780],
  [-41.3379, 173.1755],
  [-41.3752, 173.1164],
  [-41.3765, 173.1126],
  [-41.3767, 173.1093],
  [-41.3779, 173.0985],
  [-41.3783, 173.0966],
  [-41.3789, 173.0949],
  [-41.3797, 173.0932],
  [-41.4012, 173.0616],
  [-41.4026, 173.0578],
  [-41.4033, 173.0486],
  [-41.4037, 173.0463],
  [-41.4046, 173.0440],
  [-41.4086, 173.0380],
  [-41.4092, 173.0362],
  [-41.4102, 173.0305],
  [-41.4109, 173.0283],
  [-41.4130, 173.0233],
  [-41.4271, 173.0011],
  [-41.4278, 172.9994],
  [-41.4302, 172.9891],
  [-41.4313, 172.9864],
  [-41.4351, 172.9814]
];

const foxhillBelgrove = [
  [-41.4351, 172.9814],
  [-41.4485, 172.9629],
  [-41.4489, 172.9619],
  [-41.4499, 172.9579],
  [-41.4507, 172.9567]
];

const belgroveSpoonersTunnel = [
  [-41.4507, 172.9567],
  [-41.4546, 172.9527],
  [-41.4563, 172.9515],
  [-41.4569, 172.9507],
  [-41.4577, 172.9493],
  [-41.4586, 172.9484],
  [-41.4595, 172.9483],
  [-41.4621, 172.9498],
  [-41.4631, 172.9501],
  [-41.4656, 172.9500],
  [-41.4666, 172.9495],
  [-41.4677, 172.9485],
  [-41.4729, 172.9447],
  [-41.4814, 172.9373],
  [-41.4837, 172.9341],
  [-41.4860, 172.9297],
  [-41.4864, 172.9277],
  [-41.4864, 172.9224],
  [-41.4868, 172.9205],
  [-41.4877, 172.9180],
  [-41.4882, 172.9174],
  [-41.4885, 172.9150]
];

const spoonersTunnel = [
  [-41.4885, 172.9150],
  [-41.4892, 172.8990]
];

const spoonersTunnelMotupiko = [
  [-41.4892, 172.8990],
  [-41.4889, 172.8966],
  [-41.4890, 172.8947],
  [-41.4888, 172.8934],
  [-41.4831, 172.8857],
  [-41.4789, 172.8774],
  [-41.4779, 172.8719],
  [-41.4777, 172.8683],
  [-41.4765, 172.8620],
  [-41.4756, 172.8581],
  [-41.4755, 172.8529],
  [-41.4753, 172.8517],
  [-41.4749, 172.8504],
  [-41.4745, 172.8472],
  [-41.4736, 172.8452],
  [-41.4713, 172.8425],
  [-41.4682, 172.8409]
];

const motupikoTadmor = [
  [-41.4682, 172.8409],
  [-41.4582, 172.8376],
  [-41.4531, 172.8371],
  [-41.4511, 172.8375],
  [-41.4491, 172.8372],
  [-41.4466, 172.8375],
  [-41.4447, 172.8383],
  [-41.4403, 172.8407],
  [-41.4393, 172.8408],
  [-41.4379, 172.8407],
  [-41.4300, 172.8420],
  [-41.4287, 172.8421],
  [-41.4241, 172.8428],
  [-41.4201, 172.8435],
  [-41.4186, 172.8432],
  [-41.4119, 172.8387],
  [-41.4073, 172.8365],
  [-41.3978, 172.8291],
  [-41.3892, 172.8245],
  [-41.3876, 172.8226],
  [-41.3867, 172.8211],
  [-41.3864, 172.8189],
  [-41.3875, 172.8122],
  [-41.3881, 172.8111],
  [-41.3888, 172.8103],
  [-41.3958, 172.8063],
  [-41.4003, 172.8026],
  [-41.4091, 172.7965],
  [-41.4180, 172.7866],
  [-41.4221, 172.7806],
  [-41.4297, 172.7698],
  [-41.4328, 172.7644],
  [-41.4346, 172.7604],
  [-41.4373, 172.7568],
  [-41.4385, 172.7540]
];

const tadmorKiwi = [
  [-41.4385, 172.7540],
  [-41.4397, 172.7511],
  [-41.4405, 172.7496],
  [-41.4506, 172.7384],
  [-41.4525, 172.7376],
  [-41.4649, 172.7374],
  [-41.4664, 172.7378],
  [-41.4689, 172.7392],
  [-41.4704, 172.7393],
  [-41.4742, 172.7384],
  [-41.4749, 172.7385],
  [-41.4773, 172.7389],
  [-41.4819, 172.7390],
  [-41.4853, 172.7393],
  [-41.4860, 172.7396],
  [-41.4871, 172.7406],
  [-41.4880, 172.7409],
  [-41.4906, 172.7406],
  [-41.4918, 172.7410],
  [-41.4976, 172.7442],
  [-41.5035, 172.7489],
  [-41.5049, 172.7494],
  [-41.5142, 172.7498],
  [-41.5149, 172.7500],
  [-41.5159, 172.7506],
  [-41.5167, 172.7508],
  [-41.5178, 172.7507],
  [-41.5197, 172.7499],
  [-41.5207, 172.7498],
  [-41.5214, 172.7500],
  [-41.5222, 172.7504],
  [-41.5232, 172.7506],
  [-41.5264, 172.7506],
  [-41.5280, 172.7505],
  [-41.5330, 172.7484],
  [-41.5341, 172.7475],
  [-41.5361, 172.7444],
  [-41.5367, 172.7430],
  [-41.5379, 172.7390],
  [-41.5379, 172.7375],
  [-41.5375, 172.7357],
  [-41.5373, 172.7338],
  [-41.5374, 172.7275],
  [-41.5381, 172.7251],
  [-41.5390, 172.7239]
];

const kiwiGlenhope = [
  [-41.5390, 172.7239],
  [-41.5424, 172.7203],
  [-41.5430, 172.7193],
  [-41.5433, 172.7178],
  [-41.5431, 172.7145],
  [-41.5435, 172.7103],
  [-41.5440, 172.7087],
  [-41.5452, 172.7070],
  [-41.5470, 172.7055],
  [-41.5479, 172.7046],
  [-41.5485, 172.7034],
  [-41.5501, 172.7015],
  [-41.5509, 172.7005],
  [-41.5533, 172.6952],
  [-41.5540, 172.6944],
  [-41.5549, 172.6942],
  [-41.5563, 172.6946],
  [-41.5574, 172.6945],
  [-41.5581, 172.6947],
  [-41.5586, 172.6948],
  [-41.5597, 172.6943],
  [-41.5607, 172.6937],
  [-41.5616, 172.6932],
  [-41.5629, 172.6930],
  [-41.5638, 172.6926],
  [-41.5672, 172.6891],
  [-41.5681, 172.6886],
  [-41.5711, 172.6884],
  [-41.5721, 172.6881],
  [-41.5753, 172.6851],
  [-41.5765, 172.6830],
  [-41.5768, 172.6824],
  [-41.5772, 172.6810],
  [-41.5777, 172.6800],
  [-41.5789, 172.6789],
  [-41.5797, 172.6777],
  [-41.5800, 172.6773],
  [-41.5805, 172.6769],
  [-41.5809, 172.6765],
  [-41.5818, 172.6752],
  [-41.5832, 172.6737],
  [-41.5839, 172.6722],
  [-41.5842, 172.6714],
  [-41.5850, 172.6707],
  [-41.5863, 172.6698],
  [-41.5867, 172.6694],
  [-41.5875, 172.6683],
  [-41.5879, 172.6679],
  [-41.5907, 172.6669],
  [-41.5916, 172.6668],
  [-41.5924, 172.6664],
  [-41.5954, 172.6633],
  [-41.5962, 172.6627],
  [-41.5974, 172.6624],
  [-41.5983, 172.6617],
  [-41.5991, 172.6600],
  [-41.6001, 172.6592],
  [-41.6019, 172.6591],
  [-41.6032, 172.6586],
  [-41.6046, 172.6588],
  [-41.6108, 172.6630],
  [-41.6115, 172.6633],
  [-41.6125, 172.6632],
  [-41.6271, 172.6571],
  [-41.6359, 172.6557],
  [-41.6382, 172.6546],
  [-41.6423, 172.6509],
  [-41.6433, 172.6505],
  [-41.6443, 172.6505],
  [-41.6478, 172.6496]
];

const glenhopeKawatiriTunnel = [
  [-41.6478, 172.6496],
  [-41.6493, 172.6492],
  [-41.6500, 172.6487],
  [-41.6512, 172.6474],
  [-41.6522, 172.6461],
  [-41.6528, 172.6457],
  [-41.6537, 172.6455],
  [-41.6542, 172.6451],
  [-41.6545, 172.6444],
  [-41.6551, 172.6437],
  [-41.6564, 172.6438],
  [-41.6571, 172.6437],
  [-41.6577, 172.6430],
  [-41.6582, 172.6417],
  [-41.6587, 172.6410],
  [-41.6597, 172.6407],
  [-41.6610, 172.6403],
  [-41.6620, 172.6397],
  [-41.6627, 172.6388],
  [-41.6633, 172.6374],
  [-41.6641, 172.6366],
  [-41.6654, 172.6360],
  [-41.6665, 172.6348],
  [-41.6675, 172.6324],
  [-41.6682, 172.6314],
  [-41.6690, 172.6307],
  [-41.6696, 172.6299],
  [-41.6703, 172.6286],
  [-41.6714, 172.6275],
  [-41.6725, 172.6272],
  [-41.6735, 172.6267],
  [-41.6741, 172.6256],
  [-41.6748, 172.6228],
  [-41.6754, 172.6218],
  [-41.6761, 172.6213],
  [-41.6771, 172.6210],
  [-41.6778, 172.6205],
  [-41.6788, 172.6189],
  [-41.6796, 172.6182],
  [-41.6806, 172.6181],
  [-41.6814, 172.6179],
  [-41.6819, 172.6176],
  [-41.6822, 172.6170],
  [-41.6826, 172.6164],
  [-41.6831, 172.6162],
  [-41.6835, 172.6164],
  [-41.6840, 172.6167],
  [-41.6863, 172.6175],
  [-41.6866, 172.6175],
  [-41.6869, 172.6172],
  [-41.6878, 172.6170]
];

const kawatiriTunnel = [
  [-41.6878, 172.6170],
  [-41.6885, 172.6168],
  [-41.6894, 172.6169]
];

const kawatiriTunnelKawatiri = [
  [-41.6894, 172.6169],
  [-41.6904, 172.6170],
  [-41.6919, 172.6175],
  [-41.6927, 172.6178],
  [-41.6933, 172.6176],
  [-41.6940, 172.6171]
];

export const nelson: Line = {
  name: 'Nelson',
  state: 'NZ',
  segments: [
    {
      segments: [nelsonPortNelson],
      history: {
        opened: {
          date: '1880-05-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-09-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nelsonFoxhill],
      history: {
        opened: {
          date: '1876-01-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-09-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [foxhillBelgrove],
      history: {
        opened: {
          date: '1881-07-25',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-09-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        belgroveSpoonersTunnel,
        {
          name: 'Spooners Tunnel',
          segment: spoonersTunnel,
          type: 'tunnel'
        },
        spoonersTunnelMotupiko
      ],
      history: {
        opened: {
          date: '1899-03-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-09-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [motupikoTadmor],
      history: {
        opened: {
          date: '1906-08-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-09-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tadmorKiwi],
      history: {
        opened: {
          date: '1908-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-09-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kiwiGlenhope],
      history: {
        opened: {
          date: '1912-09-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-09-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        glenhopeKawatiriTunnel,
        {
          name: 'Kawatiri Tunnel',
          segment: kawatiriTunnel,
          type: 'tunnel'
        },
        kawatiriTunnelKawatiri
      ],
      history: {
        opened: {
          date: '1926-06-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-07-12',
          status: 'closed'
        }]
      }
    }
  ]
}
