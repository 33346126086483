import { Line } from "../../trackTypes";

const junctionHeritageStart = [
  [-41.7172, 145.5529],
  [-41.7160, 145.5548],
  [-41.7157, 145.5554],
  [-41.7152, 145.5560],
  [-41.7152, 145.5564],
  [-41.7155, 145.5565],
  [-41.7161, 145.5564],
  [-41.7168, 145.5555],
  [-41.7178, 145.5550],
  [-41.7182, 145.5551],
  [-41.7185, 145.5554],
  [-41.7184, 145.5568],
  [-41.7186, 145.5572],
  [-41.7193, 145.5577],
  [-41.7196, 145.5584],
  [-41.7195, 145.5593],
  [-41.7193, 145.5603],
  [-41.7193, 145.5610],
  [-41.7197, 145.5624],
  [-41.7196, 145.5630],
  [-41.7196, 145.5633],
  [-41.7198, 145.5637],
  [-41.7197, 145.5640],
  [-41.7196, 145.5643],
  [-41.7197, 145.5645],
  [-41.7200, 145.5648],
  [-41.7201, 145.5648],
  [-41.7204, 145.5648],
  [-41.7208, 145.5651],
  [-41.7211, 145.5653],
  [-41.7213, 145.5654],
  [-41.7216, 145.5653],
  [-41.7218, 145.5653],
  [-41.7220, 145.5657],
  [-41.7222, 145.5658],
  [-41.7223, 145.5662],
  [-41.7222, 145.5664],
  [-41.7220, 145.5666],
  [-41.7218, 145.5671],
  [-41.7218, 145.5675],
  [-41.7220, 145.5676],
  [-41.7222, 145.5675],
  [-41.7226, 145.5669],
  [-41.7227, 145.5666],
  [-41.7232, 145.5663],
  [-41.7236, 145.5659],
  [-41.7238, 145.5658],
  [-41.7245, 145.5662],
  [-41.7246, 145.5664],
  [-41.7253, 145.5679],
  [-41.7256, 145.5682],
  [-41.7256, 145.5686],
  [-41.7256, 145.5689],
  [-41.7256, 145.5692],
  [-41.7255, 145.5694],
  [-41.7252, 145.5697],
  [-41.7251, 145.5700],
  [-41.7249, 145.5708],
  [-41.7247, 145.5714],
  [-41.7246, 145.5721],
  [-41.7246, 145.5723],
  [-41.7246, 145.5726],
  [-41.7245, 145.5728],
  [-41.7240, 145.5734],
  [-41.7239, 145.5736],
  [-41.7236, 145.5737],
  [-41.7235, 145.5740],
  [-41.7234, 145.5743],
  [-41.7234, 145.5745],
  [-41.7229, 145.5747],
  [-41.7222, 145.5753],
  [-41.7219, 145.5757],
  [-41.7219, 145.5759],
  [-41.7218, 145.5762],
  [-41.7216, 145.5769],
  [-41.7211, 145.5775],
  [-41.7210, 145.5777],
  [-41.7212, 145.5791],
  [-41.7218, 145.5801],
  [-41.7226, 145.5813],
  [-41.7238, 145.5824],
  [-41.7244, 145.5864],
  [-41.7249, 145.5868],
  [-41.7254, 145.5868],
  [-41.7258, 145.5871],
  [-41.7265, 145.5888],
  [-41.7277, 145.5908],
  [-41.7281, 145.5925],
  [-41.7280, 145.5959],
  [-41.7283, 145.5972],
  [-41.7305, 145.5991],
  [-41.7317, 145.6005],
  [-41.7328, 145.6055],
  [-41.7325, 145.6112],
  [-41.7327, 145.6121],
  [-41.7337, 145.6135],
  [-41.7340, 145.6143],
  [-41.7340, 145.6160],
  [-41.7336, 145.6171],
  [-41.7331, 145.6197]
];

const heritageSection = [
  [-41.7331, 145.6197],
  [-41.7330, 145.6204],
  [-41.7330, 145.6210],
  [-41.7328, 145.6214],
  [-41.7323, 145.6215],
  [-41.7320, 145.6218],
  [-41.7317, 145.6223],
  [-41.7314, 145.6222],
  [-41.7313, 145.6221],
  [-41.7308, 145.6211],
  [-41.7306, 145.6210],
  [-41.7305, 145.6210],
  [-41.7297, 145.6215],
  [-41.7296, 145.6217],
  [-41.7293, 145.6229],
  [-41.7293, 145.6233],
  [-41.7297, 145.6243]
];

const heritageEndSawMill = [
  [-41.7297, 145.6243],
  [-41.7298, 145.6251],
  [-41.7298, 145.6257],
  [-41.7296, 145.6260],
  [-41.7283, 145.6269],
  [-41.7279, 145.6273],
  [-41.7272, 145.6287],
  [-41.7269, 145.6290],
  [-41.7241, 145.6298],
  [-41.7236, 145.6297],
  [-41.7230, 145.6293],
  [-41.7225, 145.6292],
  [-41.7205, 145.6295],
  [-41.7200, 145.6297],
  [-41.7185, 145.6319],
  [-41.7181, 145.6328],
  [-41.7175, 145.6336],
  [-41.7152, 145.6357]
];

export const mountFarrel: Line = {
  name: 'Mount Farrel Tramway',
  state: 'TAS',
  segments: [
    {
      segments: [junctionHeritageStart],
      history: {
        opened: {
          date: '1909-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heritageSection],
      history: {
        opened: {
          date: '1909-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }, {
          date: '1987-02-05',
          status: 'open'
        }]
      }
    },
    {
      segments: [heritageEndSawMill],
      history: {
        opened: {
          date: '1909-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
