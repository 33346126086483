import { Line } from "../../trackTypes";

const emuPlainsLapstoneHill = [
  [-33.7456, 150.6717],
  [-33.7483, 150.6571],
  [-33.7484, 150.6549],
  [-33.7479, 150.6532],
  [-33.7471, 150.6521],
  [-33.7464, 150.6516],
  [-33.7453, 150.6511],
  [-33.7444, 150.6501],
  [-33.7440, 150.6488],
  [-33.7441, 150.6474],
  [-33.7445, 150.6464],
  [-33.7466, 150.6441],
  [-33.7476, 150.6436],
  [-33.7488, 150.6436],
  [-33.7493, 150.6438]
];

const lapstoneLapstoneHillBottomPoints = [
  [-33.7493, 150.6438],
  [-33.7501, 150.6436],
  [-33.7533, 150.6427],
  [-33.7547, 150.6417],
  [-33.7565, 150.6403],
  [-33.7577, 150.6398],
  [-33.7622, 150.6402],
  [-33.7648, 150.6400],
  [-33.7663, 150.6405],
  [-33.7674, 150.6404],
  [-33.7680, 150.6398]
];

const lapstoneBottomPointsTopPoints = [
  [-33.7663, 150.6405],
  [-33.7653, 150.6396],
  [-33.7646, 150.6393],
  [-33.7634, 150.6395],
  [-33.7629, 150.6394],
  [-33.7620, 150.6392],
  [-33.7614, 150.6391],
  [-33.7600, 150.6387]
];

const lapstoneTopPointsGlenbrook1st = [
  [-33.7620, 150.6392],
  [-33.7628, 150.6391],
  [-33.7636, 150.6388],
  [-33.7642, 150.6384],
  [-33.7646, 150.6383],
  [-33.7653, 150.6384],
  [-33.7661, 150.6389],
  [-33.7665, 150.6390],
  [-33.7672, 150.6387],
  [-33.7676, 150.6382],
  [-33.7677, 150.6368],
  [-33.7674, 150.6359],
  [-33.7670, 150.6352],
  [-33.7664, 150.6348],
  [-33.7645, 150.6344],
  [-33.7638, 150.6340],
  [-33.7632, 150.6331],
  [-33.7630, 150.6319],
  [-33.7633, 150.6309],
  [-33.7639, 150.6297],
  [-33.7646, 150.6271],
  [-33.7653, 150.6223]
];

const lapstonDeviationStartGlenbrookTunnel = [
  [-33.7680, 150.6398],
  [-33.7685, 150.6382],
  [-33.7684, 150.6365],
  [-33.7677, 150.6347]
];

const glenbrookTunnelOld = [
  [-33.7677, 150.6347],
  [-33.7672, 150.6344],
  [-33.7654, 150.6336],
  [-33.7645, 150.6331],
  [-33.7640, 150.6324],
  [-33.7637, 150.6318],
  [-33.7635, 150.6296]
];

const glenbrookTunnelLapstonDeviationEnd = [
  [-33.7635, 150.6296],
  [-33.7636, 150.6279],
  [-33.7649, 150.6241],
  [-33.7653, 150.6223]
];

const glenbrook1stBlaxlandJunction = [
  [-33.7653, 150.6223],
  [-33.7651, 150.6207],
  [-33.7646, 150.6194],
  [-33.7629, 150.6158],
  [-33.7612, 150.6138],
  [-33.7592, 150.6126],
  [-33.7575, 150.6121],
  [-33.7545, 150.6116],
  [-33.7527, 150.6120],
  [-33.7519, 150.6123],
  [-33.7506, 150.6133]
];

const glenbrookGorgeDeviationStartGlenbrookTunnel = [
  [-33.7493, 150.6438],
  [-33.7506, 150.6446],
  [-33.7521, 150.6449],
  [-33.7539, 150.6442],
  [-33.7555, 150.6428],
  [-33.7569, 150.6419],
  [-33.7584, 150.6417],
  [-33.7605, 150.6424],
  [-33.7620, 150.6425],
  [-33.7631, 150.6420],
  [-33.7641, 150.6419],
  [-33.7653, 150.6424],
  [-33.7665, 150.6431],
  [-33.7678, 150.6431],
  [-33.7690, 150.6424],
  [-33.7701, 150.6420],
  [-33.7738, 150.6428],
  [-33.7755, 150.6426],
  [-33.7769, 150.6419],
  [-33.7777, 150.6408],
  [-33.7780, 150.6396],
  [-33.7779, 150.6381],
  [-33.7779, 150.6372],
  [-33.7782, 150.6359],
  [-33.7782, 150.6349],
  [-33.7780, 150.6333],
  [-33.7785, 150.6314],
  [-33.7799, 150.6296]
];

const glenbrookTunnel = [
  [-33.7799, 150.6296],
  [-33.7803, 150.6285],
  [-33.7802, 150.6274],
  [-33.7799, 150.6267]
];

const glenbrookTunnelGlenbrookGorgeDeviationEnd = [
  [-33.7799, 150.6267],
  [-33.7787, 150.6253],
  [-33.7780, 150.6246],
  [-33.7769, 150.6225],
  [-33.7760, 150.6218],
  [-33.7748, 150.6216],
  [-33.7738, 150.6220],
  [-33.7724, 150.6230],
  [-33.7712, 150.6231],
  [-33.7699, 150.6225],
  [-33.7691, 150.6212],
  [-33.7680, 150.6166],
  [-33.7673, 150.6150],
  [-33.7665, 150.6137],
  [-33.7661, 150.6124],
  [-33.7650, 150.6113],
  [-33.7637, 150.6106],
  [-33.7628, 150.6096],
  [-33.7620, 150.6087],
  [-33.7608, 150.6081],
  [-33.7592, 150.6080],
  [-33.7581, 150.6075],
  [-33.7572, 150.6067],
  [-33.7559, 150.6061],
  [-33.7543, 150.6065],
  [-33.7533, 150.6077],
  [-33.7528, 150.6094],
  [-33.7521, 150.6108],
  [-33.7506, 150.6133]
];

const blaxlandJunctionWentworthFalls = [
  [-33.7506, 150.6133],
  [-33.7490, 150.6156],
  [-33.7478, 150.6164],
  [-33.7467, 150.6163],
  [-33.7457, 150.6156],
  [-33.7450, 150.6145],
  [-33.7448, 150.6138],
  [-33.7447, 150.6121],
  [-33.7443, 150.6108],
  [-33.7422, 150.6078],
  [-33.7399, 150.6060],
  [-33.7387, 150.6055],
  [-33.7376, 150.6056],
  [-33.7364, 150.6058],
  [-33.7348, 150.6057],
  [-33.7339, 150.6053],
  [-33.7330, 150.6046],
  [-33.7322, 150.6043],
  [-33.7314, 150.6044],
  [-33.7298, 150.6052],
  [-33.7283, 150.6056],
  [-33.7259, 150.6057],
  [-33.7237, 150.6050],
  [-33.7220, 150.6038],
  [-33.7213, 150.6027],
  [-33.7207, 150.5994],
  [-33.7207, 150.5983],
  [-33.7210, 150.5975],
  [-33.7214, 150.5966],
  [-33.7216, 150.5954],
  [-33.7213, 150.5943],
  [-33.7207, 150.5935],
  [-33.7200, 150.5931],
  [-33.7180, 150.5927],
  [-33.7166, 150.5928],
  [-33.7150, 150.5935],
  [-33.7138, 150.5934],
  [-33.7127, 150.5926],
  [-33.7122, 150.5917],
  [-33.7120, 150.5909],
  [-33.7119, 150.5895],
  [-33.7114, 150.5879],
  [-33.7104, 150.5866],
  [-33.7095, 150.5858],
  [-33.7088, 150.5856],
  [-33.7075, 150.5854],
  [-33.7060, 150.5848],
  [-33.7048, 150.5841],
  [-33.7030, 150.5828],
  [-33.7024, 150.5818],
  [-33.7022, 150.5811],
  [-33.7022, 150.5802],
  [-33.7026, 150.5774],
  [-33.7028, 150.5755],
  [-33.7025, 150.5742],
  [-33.7017, 150.5733],
  [-33.6994, 150.5723],
  [-33.6986, 150.5716],
  [-33.6982, 150.5708],
  [-33.6981, 150.5697],
  [-33.6982, 150.5688],
  [-33.6982, 150.5678],
  [-33.6980, 150.5660],
  [-33.6981, 150.5650],
  [-33.6987, 150.5639],
  [-33.6995, 150.5632],
  [-33.7001, 150.5625],
  [-33.7009, 150.5612],
  [-33.7013, 150.5598],
  [-33.7012, 150.5586],
  [-33.7009, 150.5576],
  [-33.6999, 150.5558],
  [-33.6990, 150.5538],
  [-33.6962, 150.5494],
  [-33.6957, 150.5488],
  [-33.6949, 150.5480],
  [-33.6932, 150.5458],
  [-33.6928, 150.5446],
  [-33.6928, 150.5437],
  [-33.6932, 150.5427],
  [-33.6943, 150.5411],
  [-33.6947, 150.5402],
  [-33.6948, 150.5391],
  [-33.6946, 150.5379],
  [-33.6947, 150.5369],
  [-33.6952, 150.5360],
  [-33.6969, 150.5346],
  [-33.6979, 150.5341],
  [-33.6990, 150.5339],
  [-33.6998, 150.5335],
  [-33.7003, 150.5329],
  [-33.7011, 150.5322],
  [-33.7017, 150.5319],
  [-33.7027, 150.5316],
  [-33.7036, 150.5311],
  [-33.7046, 150.5300],
  [-33.7054, 150.5295],
  [-33.7061, 150.5287],
  [-33.7065, 150.5278],
  [-33.7069, 150.5263],
  [-33.7074, 150.5251],
  [-33.7080, 150.5245],
  [-33.7098, 150.5236],
  [-33.7107, 150.5229],
  [-33.7122, 150.5211],
  [-33.7129, 150.5197],
  [-33.7133, 150.5162],
  [-33.7136, 150.5151],
  [-33.7144, 150.5116],
  [-33.7148, 150.5107],
  [-33.7154, 150.5100],
  [-33.7158, 150.5091],
  [-33.7158, 150.5081],
  [-33.7155, 150.5071],
  [-33.7151, 150.5063],
  [-33.7149, 150.5057],
  [-33.7148, 150.5046],
  [-33.7155, 150.5003],
  [-33.7165, 150.4973],
  [-33.7168, 150.4967],
  [-33.7174, 150.4962],
  [-33.7189, 150.4955],
  [-33.7193, 150.4950],
  [-33.7199, 150.4943],
  [-33.7206, 150.4937],
  [-33.7213, 150.4936],
  [-33.7230, 150.4938],
  [-33.7238, 150.4936],
  [-33.7252, 150.4929],
  [-33.7259, 150.4923],
  [-33.7270, 150.4910],
  [-33.7276, 150.4905],
  [-33.7285, 150.4902],
  [-33.7291, 150.4902],
  [-33.7299, 150.4899],
  [-33.7305, 150.4895],
  [-33.7309, 150.4888],
  [-33.7315, 150.4881],
  [-33.7321, 150.4878],
  [-33.7329, 150.4877],
  [-33.7337, 150.4872],
  [-33.7341, 150.4865],
  [-33.7344, 150.4857],
  [-33.7344, 150.4842],
  [-33.7345, 150.4836],
  [-33.7348, 150.4830],
  [-33.7353, 150.4824],
  [-33.7357, 150.4815],
  [-33.7357, 150.4805],
  [-33.7353, 150.4778],
  [-33.7349, 150.4768],
  [-33.7343, 150.4757],
  [-33.7336, 150.4737],
  [-33.7335, 150.4718],
  [-33.7335, 150.4706],
  [-33.7338, 150.4695],
  [-33.7339, 150.4681],
  [-33.7334, 150.4664],
  [-33.7326, 150.4651],
  [-33.7317, 150.4645],
  [-33.7311, 150.4642],
  [-33.7306, 150.4634],
  [-33.7302, 150.4626],
  [-33.7300, 150.4614],
  [-33.7297, 150.4607],
  [-33.7290, 150.4600],
  [-33.7265, 150.4582],
  [-33.7253, 150.4569],
  [-33.7245, 150.4556],
  [-33.7239, 150.4544],
  [-33.7210, 150.4512],
  [-33.7204, 150.4503],
  [-33.7200, 150.4491],
  [-33.7199, 150.4473],
  [-33.7194, 150.4458],
  [-33.7187, 150.4443],
  [-33.7184, 150.4428],
  [-33.7186, 150.4416],
  [-33.7191, 150.4405],
  [-33.7193, 150.4393],
  [-33.7193, 150.4374],
  [-33.7190, 150.4364],
  [-33.7186, 150.4354],
  [-33.7184, 150.4344],
  [-33.7182, 150.4323],
  [-33.7185, 150.4309],
  [-33.7212, 150.4259],
  [-33.7234, 150.4230],
  [-33.7238, 150.4215],
  [-33.7235, 150.4202],
  [-33.7229, 150.4190],
  [-33.7226, 150.4178],
  [-33.7226, 150.4167],
  [-33.7230, 150.4155],
  [-33.7238, 150.4141],
  [-33.7241, 150.4130],
  [-33.7244, 150.4097],
  [-33.7242, 150.4082],
  [-33.7234, 150.4066],
  [-33.7234, 150.4054],
  [-33.7240, 150.4040],
  [-33.7248, 150.4031],
  [-33.7252, 150.4024],
  [-33.7258, 150.4007],
  [-33.7258, 150.3994],
  [-33.7252, 150.3973],
  [-33.7252, 150.3961],
  [-33.7256, 150.3951],
  [-33.7268, 150.3935],
  [-33.7273, 150.3914],
  [-33.7271, 150.3900],
  [-33.7266, 150.3889],
  [-33.7255, 150.3876],
  [-33.7246, 150.3869],
  [-33.7224, 150.3861],
  [-33.7213, 150.3861],
  [-33.7200, 150.3863],
  [-33.7190, 150.3861],
  [-33.7178, 150.3854],
  [-33.7153, 150.3825],
  [-33.7148, 150.3815],
  [-33.7142, 150.3802],
  [-33.7121, 150.3776],
  [-33.7115, 150.3770],
  [-33.7105, 150.3766],
  [-33.7098, 150.3763]
];

const wentworthFallsMountVictoria = [
  [-33.7098, 150.3763],
  [-33.7084, 150.3761],
  [-33.7078, 150.3757],
  [-33.7070, 150.3747],
  [-33.7059, 150.3727],
  [-33.7056, 150.3715],
  [-33.7055, 150.3705],
  [-33.7057, 150.3693],
  [-33.7063, 150.3681],
  [-33.7069, 150.3673],
  [-33.7079, 150.3665],
  [-33.7085, 150.3659],
  [-33.7100, 150.3639],
  [-33.7104, 150.3631],
  [-33.7106, 150.3621],
  [-33.7107, 150.3575],
  [-33.7105, 150.3565],
  [-33.7099, 150.3557],
  [-33.7092, 150.3550],
  [-33.7087, 150.3540],
  [-33.7085, 150.3529],
  [-33.7087, 150.3520],
  [-33.7092, 150.3510],
  [-33.7098, 150.3483],
  [-33.7098, 150.3458],
  [-33.7103, 150.3424],
  [-33.7106, 150.3417],
  [-33.7111, 150.3411],
  [-33.7116, 150.3406],
  [-33.7122, 150.3398],
  [-33.7125, 150.3385],
  [-33.7124, 150.3371],
  [-33.7119, 150.3355],
  [-33.7118, 150.3343],
  [-33.7119, 150.3333],
  [-33.7121, 150.3321],
  [-33.7121, 150.3311],
  [-33.7117, 150.3302],
  [-33.7112, 150.3293],
  [-33.7109, 150.3285],
  [-33.7106, 150.3273],
  [-33.7086, 150.3237],
  [-33.7080, 150.3230],
  [-33.7071, 150.3223],
  [-33.7065, 150.3214],
  [-33.7063, 150.3200],
  [-33.7067, 150.3184],
  [-33.7075, 150.3174],
  [-33.7108, 150.3156],
  [-33.7114, 150.3150],
  [-33.7118, 150.3143],
  [-33.7122, 150.3127],
  [-33.7120, 150.3117],
  [-33.7116, 150.3108],
  [-33.7108, 150.3102],
  [-33.7099, 150.3098],
  [-33.7092, 150.3093],
  [-33.7084, 150.3084],
  [-33.7079, 150.3076],
  [-33.7077, 150.3067],
  [-33.7078, 150.3040],
  [-33.7074, 150.3025],
  [-33.7067, 150.3016],
  [-33.7059, 150.3009],
  [-33.7055, 150.3001],
  [-33.7053, 150.2990],
  [-33.7054, 150.2982],
  [-33.7058, 150.2969],
  [-33.7060, 150.2958],
  [-33.7057, 150.2945],
  [-33.7051, 150.2935],
  [-33.7037, 150.2918],
  [-33.7026, 150.2911],
  [-33.7012, 150.2907],
  [-33.7000, 150.2908],
  [-33.6989, 150.2913],
  [-33.6981, 150.2914],
  [-33.6972, 150.2911],
  [-33.6965, 150.2904],
  [-33.6953, 150.2899],
  [-33.6943, 150.2901],
  [-33.6932, 150.2910],
  [-33.6925, 150.2912],
  [-33.6918, 150.2912],
  [-33.6906, 150.2909],
  [-33.6881, 150.2905],
  [-33.6872, 150.2901],
  [-33.6797, 150.2839],
  [-33.6778, 150.2827],
  [-33.6721, 150.2800],
  [-33.6697, 150.2795],
  [-33.6684, 150.2795],
  [-33.6673, 150.2798],
  [-33.6663, 150.2797],
  [-33.6649, 150.2790],
  [-33.6638, 150.2779],
  [-33.6631, 150.2775],
  [-33.6625, 150.2775],
  [-33.6592, 150.2782],
  [-33.6577, 150.2790],
  [-33.6543, 150.2813],
  [-33.6535, 150.2822],
  [-33.6529, 150.2833],
  [-33.6519, 150.2842],
  [-33.6506, 150.2847],
  [-33.6495, 150.2846],
  [-33.6456, 150.2832],
  [-33.6450, 150.2831],
  [-33.6443, 150.2830],
  [-33.6424, 150.2823],
  [-33.6417, 150.2822],
  [-33.6408, 150.2827],
  [-33.6401, 150.2835],
  [-33.6395, 150.2841],
  [-33.6389, 150.2844],
  [-33.6380, 150.2846],
  [-33.6310, 150.2842],
  [-33.6280, 150.2840],
  [-33.6273, 150.2838],
  [-33.6266, 150.2830],
  [-33.6262, 150.2820],
  [-33.6260, 150.2803],
  [-33.6254, 150.2790],
  [-33.6240, 150.2777],
  [-33.6226, 150.2769],
  [-33.6219, 150.2762],
  [-33.6211, 150.2750],
  [-33.6203, 150.2743],
  [-33.6180, 150.2731],
  [-33.6172, 150.2723],
  [-33.6155, 150.2694],
  [-33.6145, 150.2687],
  [-33.6138, 150.2685],
  [-33.6126, 150.2685],
  [-33.6118, 150.2683],
  [-33.6103, 150.2676],
  [-33.6090, 150.2675],
  [-33.6076, 150.2680],
  [-33.6067, 150.2681],
  [-33.6059, 150.2677],
  [-33.6013, 150.2640],
  [-33.6004, 150.2636],
  [-33.5992, 150.2635],
  [-33.5978, 150.2638],
  [-33.5969, 150.2637],
  [-33.5960, 150.2632],
  [-33.5905, 150.2579],
  [-33.5893, 150.2571],
  [-33.5878, 150.2569]
];

const mountVictoriaDargensDeviationJunction = [
  [-33.5878, 150.2569],
  [-33.5869, 150.2570],
  [-33.5849, 150.2579],
  [-33.5800, 150.2582],
  [-33.5788, 150.2580],
  [-33.5780, 150.2581],
  [-33.5772, 150.2586],
  [-33.5759, 150.2598],
  [-33.5748, 150.2605],
  [-33.5733, 150.2609],
  [-33.5721, 150.2607],
  [-33.5708, 150.2600],
  [-33.5696, 150.2592],
  [-33.5684, 150.2588],
  [-33.5664, 150.2589],
  [-33.5586, 150.2616],
  [-33.5571, 150.2625],
  [-33.5553, 150.2635],
  [-33.5535, 150.2637],
  [-33.5484, 150.2625],
  [-33.5475, 150.2621],
  [-33.5455, 150.2611],
  [-33.5423, 150.2605],
  [-33.5411, 150.2606],
  [-33.5399, 150.2612],
  [-33.5375, 150.2625],
  [-33.5356, 150.2632],
  [-33.5346, 150.2636],
  [-33.5337, 150.2644],
  [-33.5310, 150.2679],
  [-33.5304, 150.2691],
  [-33.5302, 150.2707],
  [-33.5297, 150.2722],
  [-33.5286, 150.2735],
  [-33.5271, 150.2740],
  [-33.5260, 150.2739],
  [-33.5251, 150.2734],
  [-33.5243, 150.2728],
  [-33.5227, 150.2726],
  [-33.5199, 150.2728],
  [-33.5184, 150.2735],
  [-33.5174, 150.2744],
  [-33.5141, 150.2802],
  [-33.5131, 150.2812],
  [-33.5118, 150.2817],
  [-33.5104, 150.2816],
  [-33.5094, 150.2811],
  [-33.5043, 150.2781],
  [-33.5030, 150.2769],
  [-33.5019, 150.2754],
  [-33.5013, 150.2741],
  [-33.5007, 150.2733],
  [-33.4960, 150.2687],
  [-33.4946, 150.2679],
  [-33.4925, 150.2670],
  [-33.4910, 150.2660],
  [-33.4866, 150.2618],
  [-33.4857, 150.2605],
  [-33.4854, 150.2592],
  [-33.4849, 150.2580],
  [-33.4834, 150.2568],
  [-33.4824, 150.2551],
  [-33.4819, 150.2545],
  [-33.4816, 150.2543]
];

const tenTunnelsDeviationStartTunnel1 = [
  [-33.4714, 150.2467],
  [-33.4709, 150.2461],
  [-33.4694, 150.2450],
  [-33.4687, 150.2438],
  [-33.4685, 150.2422],
  [-33.4691, 150.2406],
  [-33.4700, 150.2397],
  [-33.4708, 150.2394],
  [-33.4720, 150.2395],
  [-33.4730, 150.2402],
  [-33.4741, 150.2412],
  [-33.4779, 150.2452],
  [-33.4789, 150.2458],
  [-33.4804, 150.2460],
  [-33.4825, 150.2457],
  [-33.4843, 150.2446],
  [-33.4857, 150.2431],
  [-33.4883, 150.2396],
  [-33.4888, 150.2385],
  [-33.4896, 150.2363]
];

const zigZagNo1Tunnel = [
  [-33.4896, 150.2363],
  [-33.4909, 150.2316],
  [-33.4915, 150.2308]
];

const tunnel1Tunnel2 = [
  [-33.4915, 150.2308],
  [-33.4920, 150.2302],
  [-33.4929, 150.2297],
  [-33.4941, 150.2293],
  [-33.4952, 150.2287]
];

const zigZagNo2Tunnel = [
  [-33.4952, 150.2287],
  [-33.4958, 150.2280]
];

const tunnel2Tunnel3 = [
  [-33.4958, 150.2280],
  [-33.4962, 150.2273],
  [-33.4965, 150.2259],
  [-33.4965, 150.2251]
];

const zigZagNo3Tunnel = [
  [-33.4965, 150.2251],
  [-33.4963, 150.2243],
  [-33.4958, 150.2233]
];

const tunnel3Tunnel4 = [
  [-33.4958, 150.2233],
  [-33.4952, 150.2223]
];

const zigZagNo4Tunnel = [
  [-33.4952, 150.2223],
  [-33.4930, 150.2189]
];

const tunnel4Tunnel5 = [
  [-33.4930, 150.2189],
  [-33.4926, 150.2183]
];

const zigZagNo5Tunnel = [
  [-33.4926, 150.2183],
  [-33.4917, 150.2169]
];

const tunnel5Tunnel6 = [
  [-33.4917, 150.2169],
  [-33.4914, 150.2164]
];

const zigZagNo6Tunnel = [
  [-33.4914, 150.2164],
  [-33.4909, 150.2157],
  [-33.4900, 150.2151]
];

const tunnel6Tunnel7 = [
  [-33.4900, 150.2151],
  [-33.4894, 150.2150]
];

const zigZagNo7Tunnel = [
  [-33.4894, 150.2150],
  [-33.4874, 150.2149]
];

const tunnel7Tunnel8 = [
  [-33.4874, 150.2149],
  [-33.4866, 150.2144],
  [-33.4861, 150.2138]
];

const zigZagNo8Tunnel = [
  [-33.4861, 150.2138],
  [-33.4858, 150.2134],
  [-33.4854, 150.2123]
];

const tunnel8Tunnel9 = [
  [-33.4854, 150.2123],
  [-33.4850, 150.2110]
];

const zigZagNo9Tunnel = [
  [-33.4850, 150.2110],
  [-33.4845, 150.2103]
];

const tunnel9Tunnel10 = [
  [-33.4845, 150.2103],
  [-33.4836, 150.2096],
  [-33.4826, 150.2092]
];

const zigZagNo10Tunnel = [
  [-33.4826, 150.2092],
  [-33.4821, 150.2090],
  [-33.4813, 150.2085],
  [-33.4808, 150.2079],
  [-33.4789, 150.2049],
  [-33.4774, 150.2035]
];

const tunnel10ZigZagDeviationEnd = [
  [-33.4774, 150.2035],
  [-33.4760, 150.2031],
  [-33.4740, 150.2029],
  [-33.4727, 150.2025],
  [-33.4720, 150.2022],
  [-33.4710, 150.2014],
  [-33.4702, 150.2004]
];

const dargensDeviationJunctionNewnesJunction = [
  [-33.4816, 150.2543],
  [-33.4808, 150.2539],
  [-33.4796, 150.2536],
  [-33.4788, 150.2531],
  [-33.4779, 150.2522],
  [-33.4771, 150.2517],
  [-33.4762, 150.2515],
  [-33.4748, 150.2514],
  [-33.4737, 150.2509],
  [-33.4730, 150.2501],
  [-33.4725, 150.2491],
  [-33.4723, 150.2485],
  [-33.4718, 150.2473],
  [-33.4714, 150.2467]
];

const dargensDeviation = [
  [-33.4714, 150.2467],
  [-33.4702, 150.2461],
  [-33.4693, 150.2456],
  [-33.4684, 150.2443],
  [-33.4684, 150.2443],
  [-33.4682, 150.2430],
  [-33.4683, 150.2415],
  [-33.4685, 150.2407],
  [-33.4682, 150.2381],
  [-33.4676, 150.2346],
  [-33.4679, 150.2332],
  [-33.4688, 150.2322],
  [-33.4708, 150.2312],
  [-33.4717, 150.2299],
  [-33.4728, 150.2267],
  [-33.4746, 150.2234],
  [-33.4757, 150.2227],
  [-33.4767, 150.2216]
];

const original = [
  [-33.4816, 150.2543],
  [-33.4814, 150.2540],
  [-33.4811, 150.2523],
  [-33.4808, 150.2516],
  [-33.4798, 150.2507],
  [-33.4787, 150.2503],
  [-33.4783, 150.2499],
  [-33.4775, 150.2490],
  [-33.4754, 150.2479],
  [-33.4729, 150.2474],
  [-33.4719, 150.2464],
  [-33.4712, 150.2451],
  [-33.4712, 150.2436],
  [-33.4731, 150.2385],
  [-33.4735, 150.2368],
  [-33.4732, 150.2307],
  [-33.4735, 150.2290],
  [-33.4752, 150.2243],
  [-33.4767, 150.2216]
];

const clarenceClarenceTunnel = [
  [-33.4767, 150.2216],
  [-33.4778, 150.2206],
  [-33.4783, 150.2196],
  [-33.4783, 150.2182]
];

const clarenceTunnel = [
  [-33.4783, 150.2182],
  [-33.4775, 150.2130]
];

const clarenceTunnelMtSinai = [
  [-33.4775, 150.2130],
  [-33.4770, 150.2116],
  [-33.4770, 150.2109],
  [-33.4772, 150.2101],
  [-33.4783, 150.2085],
  [-33.4790, 150.2079],
  [-33.4806, 150.2073],
  [-33.4810, 150.2073],
  [-33.4814, 150.2074],
  [-33.4820, 150.2076],
  [-33.4825, 150.2078],
  [-33.4832, 150.2078],
  [-33.4840, 150.2072],
  [-33.4845, 150.2063],
  [-33.4845, 150.2051],
  [-33.4841, 150.2042],
  [-33.4834, 150.2037],
  [-33.4826, 150.2034]
];

const mtSinaiTopPoints = [
  [-33.4826, 150.2034],
  [-33.4804, 150.2027],
  [-33.4797, 150.2026],
  [-33.4792, 150.2026],
  [-33.4788, 150.2025],
  [-33.4783, 150.2022],
  [-33.4778, 150.2017],
  [-33.4772, 150.2015],
  [-33.4766, 150.2015],
  [-33.4755, 150.2017],
  [-33.4749, 150.2017],
  [-33.4743, 150.2017],
  [-33.4721, 150.2007],
  [-33.4717, 150.2004],
  [-33.4714, 150.2000],
  [-33.4713, 150.1994],
  [-33.4713, 150.1989],
  [-33.4715, 150.1982],
  [-33.4721, 150.1971],
  [-33.4723, 150.1963],
  [-33.4722, 150.1955],
  [-33.4720, 150.1948],
  [-33.4710, 150.1934],
  [-33.4704, 150.1927],
  [-33.4700, 150.1917]
];

const topPointsZigZagTunnel = [
  [-33.4727, 150.1905],
  [-33.4721, 150.1899],
  [-33.4717, 150.1896],
  [-33.4711, 150.1895],
  [-33.4708, 150.1896],
  [-33.4704, 150.1899],
  [-33.4701, 150.1903],
  [-33.4700, 150.1908],
  [-33.4700, 150.1917],
  [-33.4701, 150.1923],
  [-33.4703, 150.1928],
  [-33.4716, 150.1946],
  [-33.4718, 150.1950],
  [-33.4719, 150.1958],
  [-33.4718, 150.1966],
  [-33.4715, 150.1972],
  [-33.4707, 150.1981],
  [-33.4705, 150.1985]
];

const zigZagTunnel = [
  [-33.4705, 150.1985],
  [-33.4704, 150.1988],
  [-33.4704, 150.1993]
];

const zigZagTunnelZigZag = [
  [-33.4704, 150.1993],
  [-33.4705, 150.1999],
  [-33.4709, 150.2005],
  [-33.4718, 150.2012],
  [-33.4723, 150.2018],
  [-33.4730, 150.2024],
  [-33.4738, 150.2026],
  [-33.4755, 150.2028],
  [-33.4738, 150.2026],
  [-33.4730, 150.2024],
  [-33.4724, 150.2022],
  [-33.4702, 150.2004]
];

const zigZagLithgow = [
  [-33.4702, 150.2004],
  [-33.4699, 150.1999],
  [-33.4697, 150.1990],
  [-33.4701, 150.1980],
  [-33.4708, 150.1972],
  [-33.4712, 150.1958],
  [-33.4707, 150.1944],
  [-33.4702, 150.1938],
  [-33.4689, 150.1931],
  [-33.4685, 150.1927],
  [-33.4682, 150.1917],
  [-33.4686, 150.1878],
  [-33.4684, 150.1850],
  [-33.4684, 150.1839],
  [-33.4691, 150.1821],
  [-33.4754, 150.1728],
  [-33.4769, 150.1699],
  [-33.4783, 150.1661],
  [-33.4806, 150.1567]
];

const lithgowBowenfels = [
  [-33.4806, 150.1567],
  [-33.4833, 150.1456],
  [-33.4833, 150.1424],
  [-33.4819, 150.1391],
  [-33.4801, 150.1374],
  [-33.4768, 150.1357]
];

const bowenfelsMarrangaroo = [
  [-33.4768, 150.1357],
  [-33.4759, 150.1350],
  [-33.4748, 150.1334],
  [-33.4741, 150.1316],
  [-33.4738, 150.1294],
  [-33.4707, 150.1242],
  [-33.4707, 150.1242],
  [-33.4688, 150.1229],
  [-33.4668, 150.1222],
  [-33.4629, 150.1216],
  [-33.4613, 150.1212],
  [-33.4596, 150.1202],
  [-33.4553, 150.1175],
  [-33.4387, 150.1124],
  [-33.4322, 150.1108]
];

const marrangarooOldStartMarrangarooTunnelOld = [
  [-33.4322, 150.1108],
  [-33.4305, 150.1095],
  [-33.4267, 150.1052]
];

const marrangarooTunnelOld = [
  [-33.4267, 150.1052],
  [-33.4250, 150.1035]
];

const marrangarooTunnelOldMudgeeRoadTunnel = [
  [-33.4250, 150.1035],
  [-33.4226, 150.1012],
  [-33.4213, 150.1008],
  [-33.4200, 150.1008],
  [-33.4182, 150.1013]
];

const mudgeeRoadTunnel = [
  [-33.4182, 150.1013],
  [-33.4177, 150.1016]
];

const mudgeeRoadTunnelMarrangarooNo3Tunnel = [
  [-33.4177, 150.1016],
  [-33.4175, 150.1019],
  [-33.4172, 150.1026],
  [-33.4171, 150.1033],
  [-33.4169, 150.1047],
  [-33.4162, 150.1055],
  [-33.4152, 150.1058],
  [-33.4145, 150.1056]
];

const marangarooNo3Tunnel = [
  [-33.4145, 150.1056],
  [-33.4141, 150.1053],
  [-33.4136, 150.1044],
  [-33.4136, 150.1012]
];

const marangarooNo3TunnelMarrangarooOldEnd = [
  [-33.4136, 150.1012],
  [-33.4136, 150.1010],
  [-33.4131, 150.0989],
  [-33.4123, 150.0974],
  [-33.4088, 150.0929],
  [-33.4071, 150.0895],
  [-33.4059, 150.0865]
];

const marrangarooDeviationStartMarrangarooTunnel = [
  [-33.4322, 150.1108],
  [-33.4264, 150.1093],
  [-33.4253, 150.1092]
];

const marrangarooTunnel = [
  [-33.4253, 150.1092],
  [-33.4190, 150.1094],
  [-33.4177, 150.1091],
  [-33.4167, 150.1084]
];

const marrangarooTunnelMarrangarooDeviationEnd = [
  [-33.4167, 150.1084],
  [-33.4158, 150.1079],
  [-33.4141, 150.1081],
  [-33.4132, 150.1088],
  [-33.4122, 150.1101],
  [-33.4113, 150.1107],
  [-33.4100, 150.1108],
  [-33.4088, 150.1102],
  [-33.4050, 150.1048],
  [-33.4021, 150.0981],
  [-33.4019, 150.0966],
  [-33.4022, 150.0952],
  [-33.4027, 150.0940],
  [-33.4040, 150.0926],
  [-33.4050, 150.0914],
  [-33.4059, 150.0891],
  [-33.4059, 150.0865]
];

const wallerawangDeviationEndWallerwang = [
  [-33.4059, 150.0865],
  [-33.4041, 150.0751],
  [-33.4042, 150.0728],
  [-33.4050, 150.0707],
  [-33.4061, 150.0692],
  [-33.4077, 150.0680]
];

const wallerawangRydal = [
  [-33.4077, 150.0680],
  [-33.4100, 150.0661],
  [-33.4108, 150.0652],
  [-33.4113, 150.0638],
  [-33.4114, 150.0628],
  [-33.4115, 150.0615],
  [-33.4118, 150.0601],
  [-33.4128, 150.0588],
  [-33.4139, 150.0581],
  [-33.4381, 150.0508],
  [-33.4392, 150.0500],
  [-33.4401, 150.0485],
  [-33.4404, 150.0464],
  [-33.4398, 150.0439],
  [-33.4398, 150.0420],
  [-33.4404, 150.0405],
  [-33.4416, 150.0393],
  [-33.4428, 150.0389],
  [-33.4441, 150.0392],
  [-33.4458, 150.0397],
  [-33.4470, 150.0396],
  [-33.4480, 150.0393],
  [-33.4501, 150.0380],
  [-33.4516, 150.0373],
  [-33.4531, 150.0371],
  [-33.4555, 150.0375],
  [-33.4575, 150.0384],
  [-33.4591, 150.0389],
  [-33.4607, 150.0388],
  [-33.4771, 150.0345],
  [-33.4839, 150.0316]
];

const rydalTarana = [
  [-33.4839, 150.0316],
  [-33.4880, 150.0299],
  [-33.4898, 150.0284],
  [-33.4913, 150.0261],
  [-33.4921, 150.0229],
  [-33.4926, 150.0215],
  [-33.4988, 150.0125],
  [-33.4999, 150.0117],
  [-33.5007, 150.0114],
  [-33.5024, 150.0113],
  [-33.5033, 150.0110],
  [-33.5043, 150.0103],
  [-33.5054, 150.0099],
  [-33.5086, 150.0106],
  [-33.5098, 150.0115],
  [-33.5106, 150.0123],
  [-33.5114, 150.0127],
  [-33.5127, 150.0128],
  [-33.5143, 150.0124],
  [-33.5156, 150.0113],
  [-33.5164, 150.0109],
  [-33.5172, 150.0109],
  [-33.5184, 150.0114],
  [-33.5194, 150.0114],
  [-33.5204, 150.0107],
  [-33.5210, 150.0093],
  [-33.5209, 150.0078],
  [-33.5201, 150.0067],
  [-33.5191, 150.0062],
  [-33.5181, 150.0064],
  [-33.5168, 150.0069],
  [-33.5161, 150.0068],
  [-33.5153, 150.0064],
  [-33.5148, 150.0058],
  [-33.5144, 150.0049],
  [-33.5128, 149.9975],
  [-33.5124, 149.9965],
  [-33.5117, 149.9956],
  [-33.5106, 149.9945],
  [-33.5099, 149.9934],
  [-33.5097, 149.9919],
  [-33.5097, 149.9904],
  [-33.5095, 149.9891],
  [-33.5085, 149.9864],
  [-33.5082, 149.9851],
  [-33.5083, 149.9839],
  [-33.5087, 149.9821],
  [-33.5087, 149.9814],
  [-33.5085, 149.9807],
  [-33.5078, 149.9793],
  [-33.5075, 149.9784],
  [-33.5077, 149.9774],
  [-33.5083, 149.9758],
  [-33.5089, 149.9746],
  [-33.5082, 149.9729],
  [-33.5076, 149.9718],
  [-33.5073, 149.9712],
  [-33.5072, 149.9701],
  [-33.5074, 149.9691],
  [-33.5082, 149.9679],
  [-33.5086, 149.9668],
  [-33.5087, 149.9654],
  [-33.5082, 149.9639],
  [-33.5071, 149.9626],
  [-33.5064, 149.9617],
  [-33.5061, 149.9604],
  [-33.5060, 149.9529],
  [-33.5064, 149.9516],
  [-33.5070, 149.9508],
  [-33.5079, 149.9504],
  [-33.5084, 149.9499],
  [-33.5090, 149.9485],
  [-33.5089, 149.9472],
  [-33.5085, 149.9463],
  [-33.5085, 149.9454],
  [-33.5087, 149.9444],
  [-33.5125, 149.9379],
  [-33.5127, 149.9368],
  [-33.5125, 149.9357],
  [-33.5120, 149.9342],
  [-33.5118, 149.9323],
  [-33.5122, 149.9300],
  [-33.5131, 149.9283],
  [-33.5139, 149.9272],
  [-33.5142, 149.9263],
  [-33.5143, 149.9253],
  [-33.5140, 149.9243],
  [-33.5140, 149.9236],
  [-33.5146, 149.9222],
  [-33.5155, 149.9215],
  [-33.5162, 149.9213],
  [-33.5172, 149.9215],
  [-33.5184, 149.9210],
  [-33.5191, 149.9198],
  [-33.5193, 149.9187],
  [-33.5185, 149.9160],
  [-33.5185, 149.9149],
  [-33.5189, 149.9138],
  [-33.5198, 149.9129],
  [-33.5213, 149.9126],
  [-33.5225, 149.9120],
  [-33.5236, 149.9104],
  [-33.5240, 149.9086],
  [-33.5241, 149.9074]
];

const taranaLocksley = [
  [-33.5241, 149.9074],
  [-33.5243, 149.9041],
  [-33.5246, 149.9030],
  [-33.5251, 149.9020],
  [-33.5296, 149.8960],
  [-33.5303, 149.8943],
  [-33.5306, 149.8919],
  [-33.5295, 149.8809],
  [-33.5289, 149.8789],
  [-33.5278, 149.8772],
  [-33.5260, 149.8755],
  [-33.5254, 149.8744],
  [-33.5250, 149.8733],
  [-33.5247, 149.8721],
  [-33.5241, 149.8711],
  [-33.5232, 149.8706],
  [-33.5223, 149.8706],
  [-33.5201, 149.8712],
  [-33.5191, 149.8709],
  [-33.5183, 149.8701],
  [-33.5162, 149.8659],
  [-33.5158, 149.8643],
  [-33.5162, 149.8622],
  [-33.5172, 149.8607],
  [-33.5205, 149.8585],
  [-33.5213, 149.8574],
  [-33.5219, 149.8559],
  [-33.5219, 149.8541],
  [-33.5214, 149.8521],
  [-33.5214, 149.8503],
  [-33.5219, 149.8488],
  [-33.5227, 149.8477],
  [-33.5245, 149.8462],
  [-33.5256, 149.8442],
  [-33.5261, 149.8416],
  [-33.5259, 149.8397],
  [-33.5238, 149.8334],
  [-33.5233, 149.8301],
  [-33.5238, 149.8238],
  [-33.5243, 149.8224],
  [-33.5254, 149.8212],
  [-33.5263, 149.8209],
  [-33.5273, 149.8208],
  [-33.5285, 149.8202],
  [-33.5294, 149.8192],
  [-33.5298, 149.8179],
  [-33.5305, 149.8081],
  [-33.5309, 149.8065],
  [-33.5316, 149.8055],
  [-33.5325, 149.8048],
  [-33.5349, 149.8034],
  [-33.5358, 149.8024],
  [-33.5361, 149.8017]
];

const locksleyBrewongle = [
  [-33.5361, 149.8017],
  [-33.5363, 149.8007],
  [-33.5360, 149.7989],
  [-33.5351, 149.7976],
  [-33.5337, 149.7970],
  [-33.5307, 149.7970],
  [-33.5285, 149.7965],
  [-33.5265, 149.7950],
  [-33.5253, 149.7932],
  [-33.5246, 149.7909],
  [-33.5238, 149.7838],
  [-33.5229, 149.7819],
  [-33.5216, 149.7808],
  [-33.5202, 149.7805],
  [-33.5178, 149.7807],
  [-33.5148, 149.7802],
  [-33.5130, 149.7792],
  [-33.5106, 149.7773],
  [-33.5092, 149.7757],
  [-33.5088, 149.7742],
  [-33.5087, 149.7732],
  [-33.5091, 149.7719],
  [-33.5097, 149.7711],
  [-33.5108, 149.7705],
  [-33.5119, 149.7704],
  [-33.5145, 149.7710],
  [-33.5163, 149.7711],
  [-33.5178, 149.7702],
  [-33.5186, 149.7685],
  [-33.5185, 149.7669],
  [-33.5176, 149.7654],
  [-33.5167, 149.7648],
  [-33.5154, 149.7647],
  [-33.5140, 149.7650],
  [-33.5130, 149.7648],
  [-33.5119, 149.7638],
  [-33.5113, 149.7623],
  [-33.5113, 149.7610],
  [-33.5116, 149.7601],
  [-33.5123, 149.7588],
  [-33.5126, 149.7575],
  [-33.5123, 149.7560],
  [-33.5116, 149.7548],
  [-33.5104, 149.7541],
  [-33.5083, 149.7533],
  [-33.5075, 149.7523],
  [-33.5071, 149.7514],
  [-33.5068, 149.7501],
  [-33.5061, 149.7489],
  [-33.5051, 149.7481],
  [-33.5038, 149.7480],
  [-33.5021, 149.7483],
  [-33.5011, 149.7481],
  [-33.5000, 149.7471],
  [-33.4994, 149.7458],
  [-33.4993, 149.7446],
  [-33.4996, 149.7436],
  [-33.5003, 149.7418],
  [-33.5005, 149.7403],
  [-33.5000, 149.7386],
  [-33.4991, 149.7372],
  [-33.4987, 149.7359],
  [-33.4988, 149.7344],
  [-33.4993, 149.7334],
  [-33.5001, 149.7323],
  [-33.5006, 149.7313],
  [-33.5007, 149.7296],
  [-33.4994, 149.7246],
  [-33.4988, 149.7233],
  [-33.4974, 149.7211],
  [-33.4935, 149.7162],
  [-33.4925, 149.7156],
  [-33.4913, 149.7155],
  [-33.4901, 149.7160],
  [-33.4891, 149.7170],
  [-33.4876, 149.7179],
  [-33.4862, 149.7180],
  [-33.4848, 149.7176],
  [-33.4836, 149.7168],
  [-33.4798, 149.7130]
];

const brewongleRaglan = [
  [-33.4798, 149.7130],
  [-33.4658, 149.6991],
  [-33.4603, 149.6917],
  [-33.4552, 149.6875],
  [-33.4484, 149.6840],
  [-33.4459, 149.6818],
  [-33.4438, 149.6783],
  [-33.4333, 149.6519]
];

const raglanKelso = [
  [-33.4333, 149.6519],
  [-33.4330, 149.6501],
  [-33.4306, 149.6327],
  [-33.4297, 149.6304],
  [-33.4282, 149.6289],
  [-33.4256, 149.6272],
  [-33.4245, 149.6258],
  [-33.4236, 149.6236],
  [-33.4220, 149.6137],
  [-33.4219, 149.6104],
  [-33.4223, 149.6046]
]

const kelsoBathurst = [
  [-33.4223, 149.6046],
  [-33.4224, 149.6020],
  [-33.4233, 149.5868],
  [-33.4237, 149.5854],
  [-33.4245, 149.5840],
  [-33.4255, 149.5833]
];

const bathurstBlayneyDeviation = [
  [-33.4255, 149.5833],
  [-33.4342, 149.5781],
  [-33.4354, 149.5776],
  [-33.4366, 149.5775],
  [-33.4410, 149.5781],
  [-33.4497, 149.5785],
  [-33.4515, 149.5780],
  [-33.4537, 149.5765],
  [-33.4587, 149.5734],
  [-33.4694, 149.5670],
  [-33.4715, 149.5647],
  [-33.4730, 149.5634],
  [-33.4803, 149.5589],
  [-33.4810, 149.5581],
  [-33.4814, 149.5570],
  [-33.4819, 149.5533],
  [-33.4832, 149.5500],
  [-33.4844, 149.5481],
  [-33.4856, 149.5468],
  [-33.4938, 149.5405],
  [-33.5075, 149.5334],
  [-33.5093, 149.5318],
  [-33.5134, 149.5253],
  [-33.5137, 149.5244],
  [-33.5137, 149.5231],
  [-33.5121, 149.5155],
  [-33.5121, 149.5139],
  [-33.5125, 149.5122],
  [-33.5124, 149.5085],
  [-33.5119, 149.5060],
  [-33.5107, 149.5025],
  [-33.5105, 149.5016],
  [-33.5106, 149.5003],
  [-33.5137, 149.4913],
  [-33.5138, 149.4895],
  [-33.5142, 149.4884],
  [-33.5150, 149.4874],
  [-33.5163, 149.4868],
  [-33.5170, 149.4860],
  [-33.5174, 149.4845],
  [-33.5172, 149.4833],
  [-33.5175, 149.4818],
  [-33.5187, 149.4807],
  [-33.5198, 149.4805],
  [-33.5206, 149.4798],
  [-33.5212, 149.4779],
  [-33.5207, 149.4742],
  [-33.5209, 149.4731],
  [-33.5216, 149.4720],
  [-33.5235, 149.4705],
  [-33.5240, 149.4694],
  [-33.5244, 149.4637],
  [-33.5248, 149.4624],
  [-33.5259, 149.4615],
  [-33.5273, 149.4610],
  [-33.5280, 149.4603],
  [-33.5301, 149.4559],
  [-33.5304, 149.4542],
  [-33.5299, 149.4523],
  [-33.5291, 149.4507],
  [-33.5289, 149.4489],
  [-33.5295, 149.4472],
  [-33.5352, 149.4408],
  [-33.5363, 149.4399],
  [-33.5376, 149.4393],
  [-33.5387, 149.4382],
  [-33.5395, 149.4364],
  [-33.5405, 149.4353],
  [-33.5416, 149.4346],
  [-33.5431, 149.4336],
  [-33.5445, 149.4318],
  [-33.5460, 149.4291],
  [-33.5470, 149.4283],
  [-33.5489, 149.4276],
  [-33.5506, 149.4259],
  [-33.5520, 149.4226],
  [-33.5527, 149.4215],
  [-33.5538, 149.4205],
  [-33.5555, 149.4200],
  [-33.5573, 149.4190],
  [-33.5586, 149.4175],
  [-33.5600, 149.4155],
  [-33.5611, 149.4146],
  [-33.5629, 149.4139],
  [-33.5641, 149.4131],
  [-33.5654, 149.4116],
  [-33.5663, 149.4092],
  [-33.5670, 149.4080],
  [-33.5681, 149.4071],
  [-33.5726, 149.4052],
  [-33.5740, 149.4041],
  [-33.5751, 149.4039],
  [-33.5768, 149.4040],
  [-33.5778, 149.4038],
  [-33.5787, 149.4030],
  [-33.5794, 149.4012],
  [-33.5791, 149.3996],
  [-33.5782, 149.3979],
  [-33.5781, 149.3966],
  [-33.5782, 149.3949],
  [-33.5779, 149.3936],
  [-33.5770, 149.3924],
  [-33.5757, 149.3916],
  [-33.5751, 149.3909],
  [-33.5747, 149.3898],
  [-33.5741, 149.3890],
  [-33.5721, 149.3868],
  [-33.5706, 149.3861],
  [-33.5699, 149.3853],
  [-33.5693, 149.3845],
  [-33.5686, 149.3839],
  [-33.5672, 149.3832],
  [-33.5663, 149.3818],
  [-33.5663, 149.3797],
  [-33.5674, 149.3781],
  [-33.5689, 149.3776],
  [-33.5731, 149.3784],
  [-33.5746, 149.3779],
  [-33.5754, 149.3769],
  [-33.5760, 149.3763],
  [-33.5769, 149.3758],
  [-33.5787, 149.3757],
  [-33.5796, 149.3752],
  [-33.5801, 149.3745],
  [-33.5809, 149.3735],
  [-33.5820, 149.3727],
  [-33.5828, 149.3718],
  [-33.5834, 149.3701],
  [-33.5833, 149.3675],
  [-33.5822, 149.3658],
  [-33.5772, 149.3619],
  [-33.5766, 149.3608],
  [-33.5764, 149.3595],
  [-33.5766, 149.3582],
  [-33.5775, 149.3565],
  [-33.5778, 149.3549],
  [-33.5771, 149.3531],
  [-33.5756, 149.3522],
  [-33.5742, 149.3524],
  [-33.5728, 149.3536],
  [-33.5718, 149.3541],
  [-33.5707, 149.3541],
  [-33.5696, 149.3537],
  [-33.5686, 149.3535],
  [-33.5676, 149.3539],
  [-33.5661, 149.3552],
  [-33.5651, 149.3556],
  [-33.5638, 149.3555],
  [-33.5629, 149.3548],
  [-33.5602, 149.3511],
  [-33.5596, 149.3494],
  [-33.5589, 149.3431],
  [-33.5580, 149.3400],
  [-33.5563, 149.3372],
  [-33.5546, 149.3353],
  [-33.5542, 149.3343],
  [-33.5536, 149.3324],
  [-33.5525, 149.3310],
  [-33.5512, 149.3307],
  [-33.5501, 149.3309],
  [-33.5487, 149.3318],
  [-33.5477, 149.3319],
  [-33.5467, 149.3315],
  [-33.5460, 149.3307],
  [-33.5455, 149.3299],
  [-33.5449, 149.3291],
  [-33.5440, 149.3283],
  [-33.5433, 149.3270],
  [-33.5431, 149.3255],
  [-33.5436, 149.3241],
  [-33.5444, 149.3233],
  [-33.5461, 149.3222],
  [-33.5473, 149.3207],
  [-33.5480, 149.3187],
  [-33.5495, 149.3164],
  [-33.5498, 149.3153],
  [-33.5497, 149.3144],
  [-33.5487, 149.3099],
  [-33.5480, 149.3080],
  [-33.5422, 149.2977],
];

const blayneyBlayneyDeviation = [
  [-33.5309, 149.2735],
  [-33.5280, 149.2603],
  [-33.5274, 149.2572],
  [-33.5270, 149.2543]
];

const blayneyDeviation = [
  [-33.5422, 149.2977],
  [-33.5420, 149.2966],
  [-33.5420, 149.2954],
  [-33.5435, 149.2899],
  [-33.5443, 149.2889],
  [-33.5457, 149.2879],
  [-33.5465, 149.2870],
  [-33.5475, 149.2853],
  [-33.5483, 149.2844],
  [-33.5495, 149.2836],
  [-33.5505, 149.2824],
  [-33.5508, 149.2809],
  [-33.5505, 149.2792],
  [-33.5494, 149.2779],
  [-33.5480, 149.2776],
  [-33.5467, 149.2781],
  [-33.5457, 149.2794],
  [-33.5452, 149.2810],
  [-33.5445, 149.2822],
  [-33.5433, 149.2830],
  [-33.5410, 149.2835],
  [-33.5401, 149.2834],
  [-33.5391, 149.2829],
  [-33.5347, 149.2791],
  [-33.5316, 149.2749],
  [-33.5309, 149.2735]
];

const blayneyOld = [
  [-33.5422, 149.2977],
  [-33.5402, 149.2940],
  [-33.5390, 149.2913],
  [-33.5385, 149.2896],
  [-33.5384, 149.2879],
  [-33.5384, 149.2852],
  [-33.5380, 149.2839],
  [-33.5371, 149.2830],
  [-33.5356, 149.2827],
  [-33.5350, 149.2823],
  [-33.5344, 149.2814],
  [-33.5340, 149.2797],
  [-33.5334, 149.2788],
  [-33.5324, 149.2776],
  [-33.5315, 149.2760],
  [-33.5312, 149.2750],
  [-33.5309, 149.2735]
];

const blayneyOrange = [
  [-33.5270, 149.2543],
  [-33.5268, 149.2523],
  [-33.5263, 149.2512],
  [-33.5234, 149.2468],
  [-33.5218, 149.2439],
  [-33.5188, 149.2363],
  [-33.5176, 149.2347],
  [-33.5164, 149.2339],
  [-33.5126, 149.2323],
  [-33.5108, 149.2307],
  [-33.5096, 149.2285],
  [-33.5067, 149.2267],
  [-33.5052, 149.2271],
  [-33.5037, 149.2280],
  [-33.5018, 149.2281],
  [-33.5002, 149.2274],
  [-33.4989, 149.2261],
  [-33.4971, 149.2232],
  [-33.4958, 149.2220],
  [-33.4939, 149.2205],
  [-33.4932, 149.2195],
  [-33.4927, 149.2184],
  [-33.4923, 149.2161],
  [-33.4923, 149.2148],
  [-33.4930, 149.2101],
  [-33.4928, 149.2074],
  [-33.4916, 149.2049],
  [-33.4851, 149.1983],
  [-33.4720, 149.1886],
  [-33.4693, 149.1873],
  [-33.4676, 149.1870],
  [-33.4608, 149.1861],
  [-33.4595, 149.1856],
  [-33.4580, 149.1848],
  [-33.4563, 149.1846],
  [-33.4539, 149.1851],
  [-33.4514, 149.1848],
  [-33.4499, 149.1841],
  [-33.4443, 149.1775],
  [-33.4371, 149.1725],
  [-33.4328, 149.1710],
  [-33.4310, 149.1698],
  [-33.4298, 149.1686],
  [-33.4282, 149.1676],
  [-33.4182, 149.1653],
  [-33.4148, 149.1638],
  [-33.4057, 149.1579],
  [-33.3928, 149.1525],
  [-33.3864, 149.1485],
  [-33.3637, 149.1298],
  [-33.3534, 149.1198],
  [-33.3519, 149.1191],
  [-33.3499, 149.1186],
  [-33.3483, 149.1176],
  [-33.3465, 149.1156],
  [-33.3444, 149.1144],
  [-33.3351, 149.1130],
  [-33.3316, 149.1119],
  [-33.3288, 149.1119],
  [-33.3270, 149.1113],
  [-33.3242, 149.1096],
  [-33.3224, 149.1079],
  [-33.3192, 149.1041],
  [-33.3176, 149.1030],
  [-33.3159, 149.1027],
  [-33.2954, 149.1022],
  [-33.2928, 149.1025],
  [-33.2867, 149.1038]
];

const orangeWellington = [
  [-33.2867, 149.1038],
  [-33.2773, 149.1056],
  [-33.2756, 149.1062],
  [-33.2732, 149.1078],
  [-33.2716, 149.1084],
  [-33.2700, 149.1086],
  [-33.2627, 149.1086],
  [-33.2597, 149.1091],
  [-33.2524, 149.1115],
  [-33.2504, 149.1118],
  [-33.2460, 149.1117],
  [-33.2444, 149.1112],
  [-33.2431, 149.1103],
  [-33.2417, 149.1089],
  [-33.2405, 149.1082],
  [-33.2394, 149.1080],
  [-33.2383, 149.1081],
  [-33.2371, 149.1084],
  [-33.2359, 149.1084],
  [-33.2346, 149.1078],
  [-33.2338, 149.1069],
  [-33.2327, 149.1052],
  [-33.2317, 149.1043],
  [-33.2307, 149.1039],
  [-33.2294, 149.1039],
  [-33.1993, 149.1117],
  [-33.1967, 149.1119],
  [-33.1941, 149.1116],
  [-33.1920, 149.1108],
  [-33.1854, 149.1074],
  [-33.1831, 149.1068],
  [-33.1810, 149.1068],
  [-33.1643, 149.1086],
  [-33.1613, 149.1094],
  [-33.1578, 149.1110],
  [-33.1440, 149.1184],
  [-33.1412, 149.1192],
  [-33.1382, 149.1191],
  [-33.1272, 149.1156],
  [-33.1247, 149.1153],
  [-33.1191, 149.1156],
  [-33.1174, 149.1155],
  [-33.1159, 149.1150],
  [-33.1135, 149.1137],
  [-33.1119, 149.1133],
  [-33.1107, 149.1133],
  [-33.1096, 149.1134],
  [-33.1081, 149.1131],
  [-33.1066, 149.1124],
  [-33.1055, 149.1122],
  [-33.1041, 149.1122],
  [-33.1029, 149.1126],
  [-33.1008, 149.1124],
  [-33.0985, 149.1111],
  [-33.0962, 149.1104],
  [-33.0950, 149.1094],
  [-33.0937, 149.1077],
  [-33.0927, 149.1052],
  [-33.0916, 149.1038],
  [-33.0902, 149.1028],
  [-33.0883, 149.1020],
  [-33.0869, 149.1008],
  [-33.0855, 149.0994],
  [-33.0841, 149.0987],
  [-33.0824, 149.0984],
  [-33.0812, 149.0979],
  [-33.0794, 149.0967],
  [-33.0778, 149.0963],
  [-33.0750, 149.0962],
  [-33.0734, 149.0965],
  [-33.0719, 149.0974],
  [-33.0694, 149.0993],
  [-33.0672, 149.1004],
  [-33.0647, 149.1010],
  [-33.0623, 149.1009],
  [-33.0599, 149.1002],
  [-33.0507, 149.0960],
  [-33.0489, 149.0956],
  [-33.0474, 149.0956],
  [-33.0459, 149.0961],
  [-33.0405, 149.0981],
  [-33.0359, 149.0985],
  [-33.0134, 149.0951],
  [-33.0115, 149.0942],
  [-33.0103, 149.0930],
  [-33.0063, 149.0868],
  [-33.0048, 149.0856],
  [-33.0030, 149.0850],
  [-32.9851, 149.0851],
  [-32.9834, 149.0846],
  [-32.9807, 149.0832],
  [-32.9788, 149.0830],
  [-32.9676, 149.0850],
  [-32.9376, 149.0983],
  [-32.9358, 149.0999],
  [-32.9338, 149.1020],
  [-32.9321, 149.1027],
  [-32.9303, 149.1026],
  [-32.9140, 149.1002],
  [-32.9124, 149.0994],
  [-32.9109, 149.0983],
  [-32.9091, 149.0977],
  [-32.9016, 149.0963],
  [-32.8979, 149.0952],
  [-32.8839, 149.0895],
  [-32.8751, 149.0879],
  [-32.8709, 149.0877],
  [-32.8667, 149.0885],
  [-32.8620, 149.0903],
  [-32.8587, 149.0908],
  [-32.8552, 149.0903],
  [-32.8516, 149.0894],
  [-32.8429, 149.0890],
  [-32.8413, 149.0884],
  [-32.8372, 149.0858],
  [-32.8352, 149.0838],
  [-32.8332, 149.0827],
  [-32.8307, 149.0823],
  [-32.8284, 149.0825],
  [-32.8269, 149.0822],
  [-32.8249, 149.0815],
  [-32.8233, 149.0815],
  [-32.8215, 149.0821],
  [-32.8182, 149.0843],
  [-32.8164, 149.0846],
  [-32.8146, 149.0843],
  [-32.8004, 149.0780],
  [-32.7987, 149.0768],
  [-32.7937, 149.0723],
  [-32.7878, 149.0698],
  [-32.7774, 149.0667],
  [-32.7754, 149.0667],
  [-32.7729, 149.0674],
  [-32.7682, 149.0673],
  [-32.7657, 149.0665],
  [-32.7599, 149.0628],
  [-32.7587, 149.0616],
  [-32.7579, 149.0601],
  [-32.7568, 149.0581],
  [-32.7551, 149.0565],
  [-32.7469, 149.0534],
  [-32.7308, 149.0512],
  [-32.7255, 149.0491],
  [-32.7166, 149.0431],
  [-32.7093, 149.0362],
  [-32.7022, 149.0267],
  [-32.6994, 149.0246],
  [-32.6816, 149.0181],
  [-32.6803, 149.0180],
  [-32.6785, 149.0182],
  [-32.6773, 149.0178],
  [-32.6759, 149.0166],
  [-32.6751, 149.0144],
  [-32.6752, 149.0116],
  [-32.6744, 149.0095],
  [-32.6725, 149.0081],
  [-32.6707, 149.0075],
  [-32.6692, 149.0062],
  [-32.6674, 149.0036],
  [-32.6652, 149.0018],
  [-32.6631, 149.0015],
  [-32.6591, 149.0023],
  [-32.6570, 149.0019],
  [-32.6555, 149.0008],
  [-32.6504, 148.9947],
  [-32.6439, 148.9910],
  [-32.6427, 148.9908],
  [-32.6412, 148.9909],
  [-32.6391, 148.9900],
  [-32.6379, 148.9880],
  [-32.6377, 148.9861],
  [-32.6385, 148.9813],
  [-32.6382, 148.9792],
  [-32.6374, 148.9778],
  [-32.6328, 148.9733],
  [-32.6314, 148.9725],
  [-32.6297, 148.9723],
  [-32.6277, 148.9726],
  [-32.6261, 148.9721],
  [-32.6248, 148.9707],
  [-32.6232, 148.9676],
  [-32.6211, 148.9652],
  [-32.6185, 148.9640],
  [-32.6163, 148.9639],
  [-32.6050, 148.9657],
  [-32.6024, 148.9656],
  [-32.6000, 148.9647],
  [-32.5968, 148.9624],
  [-32.5954, 148.9618],
  [-32.5933, 148.9617],
  [-32.5916, 148.9617],
  [-32.5900, 148.9610],
  [-32.5838, 148.9561],
  [-32.5806, 148.9545],
  [-32.5776, 148.9538],
  [-32.5738, 148.9537],
  [-32.5704, 148.9531],

  [-32.5539, 148.9468]
];

const wellingtonDubbo = [
  [-32.5539, 148.9468],
  [-32.5474, 148.9443],
  [-32.5461, 148.9442],
  [-32.5413, 148.9450],
  [-32.5392, 148.9447],
  [-32.5377, 148.9438],
  [-32.5360, 148.9422],
  [-32.5340, 148.9398],
  [-32.5321, 148.9383],
  [-32.5048, 148.9231],
  [-32.5016, 148.9206],
  [-32.4944, 148.9140],
  [-32.4910, 148.9118],
  [-32.4884, 148.9110],
  [-32.4786, 148.9095],
  [-32.4755, 148.9085],
  [-32.4725, 148.9066],
  [-32.4705, 148.9051],
  [-32.4689, 148.9049],
  [-32.4674, 148.9053],
  [-32.4593, 148.9091],
  [-32.4573, 148.9095],
  [-32.4551, 148.9094],
  [-32.4533, 148.9088],
  [-32.4511, 148.9077],
  [-32.4487, 148.9057],
  [-32.4435, 148.8996],
  [-32.4300, 148.8826],
  [-32.4275, 148.8804],
  [-32.4258, 148.8771],
  [-32.4226, 148.8595],
  [-32.4219, 148.8577],
  [-32.4159, 148.8453],
  [-32.4153, 148.8432],
  [-32.4151, 148.8404],
  [-32.4142, 148.8381],
  [-32.4124, 148.8364],
  [-32.4100, 148.8357],
  [-32.4065, 148.8358],
  [-32.4050, 148.8361],
  [-32.4032, 148.8365],
  [-32.4016, 148.8360],
  [-32.4004, 148.8348],
  [-32.3923, 148.8215],
  [-32.3867, 148.8154],
  [-32.3826, 148.8131],
  [-32.3768, 148.8117],
  [-32.3729, 148.8097],
  [-32.3707, 148.8073],
  [-32.3685, 148.8050],
  [-32.3651, 148.8026],
  [-32.3634, 148.8001],
  [-32.3625, 148.7969],
  [-32.3610, 148.7925],
  [-32.3255, 148.7469],
  [-32.3222, 148.7421],
  [-32.3118, 148.7242],
  [-32.3049, 148.7149],
  [-32.2528, 148.6488],
  [-32.2509, 148.6452],
  [-32.2492, 148.6387],
  [-32.2479, 148.6291],
  [-32.2479, 148.6273],
  [-32.2484, 148.6251],
  [-32.2484, 148.6225],
  [-32.2472, 148.6193],
  [-32.2464, 148.6171],
  [-32.2460, 148.6153],
  [-32.2459, 148.6144],
  [-32.2448, 148.6069]
];

const dubboNeverite = [
  [-32.2448, 148.6069],
  [-32.2427, 148.5910],
  [-32.2429, 148.5850],
  [-32.2510, 148.5559],
  [-32.2523, 148.5494],
  [-32.2595, 148.4873],
  [-32.2597, 148.4825],
  [-32.2589, 148.4469],
  [-32.2553, 148.3120],
  [-32.2533, 148.2994],
  [-32.2381, 148.2499],
  [-32.2355, 148.2434],
  [-32.2316, 148.2358],
  [-32.2274, 148.2295],
  [-32.0809, 148.0495],
  [-32.0549, 148.0177],
  [-32.0312, 147.9856],
  [-32.0114, 147.9589],
  [-31.8593, 147.7545],
  [-31.8520, 147.7431],
  [-31.8375, 147.7157]
];

const neveriteNyngan = [
  [-31.8375, 147.7157],
  [-31.8361, 147.7130],
  [-31.7380, 147.5273],
  [-31.6825, 147.4225],
  [-31.5995, 147.2661],
  [-31.5625, 147.1968]
];

const nynganNynganJunction = [
  [-31.5625, 147.1968],
  [-31.5538, 147.1803]
];

const nynganJunctionByrock = [
  [-31.5538, 147.1803],
  [-31.5444, 147.1633],
  [-31.5394, 147.1564],
  [-31.2976, 146.9467],
  [-31.2682, 146.9217],
  [-31.1437, 146.8137],
  [-31.0299, 146.7161],
  [-30.9895, 146.6735],
  [-30.6684, 146.4111],
  [-30.6586, 146.4026]
];

const byrockBourke = [
  [-30.6586, 146.4026],
  [-30.6517, 146.3968],
  [-30.5144, 146.2820],
  [-30.2681, 146.0783],
  [-30.1739, 146.0009],
  [-30.1080, 145.9546],
  [-30.0982, 145.9478],
  [-30.0965, 145.9456],
  [-30.0955, 145.9428],
  [-30.0946, 145.9345]
];

export const mainWest: Line = {
  name: 'Main West Line',
  state: 'NSW',
  segments: [
    {
      segments: [emuPlainsLapstoneHill],
      history: {
        opened: {
          date: '1867-01-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1902-01-01',
          tracks: 2
        }, {
          date: '1956-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [lapstoneLapstoneHillBottomPoints],
      history: {
        opened: {
          date: '1867-07-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1913-08-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        {
          date: '1892-12-18',
          original: [
            lapstoneBottomPointsTopPoints,
            lapstoneTopPointsGlenbrook1st,
            glenbrook1stBlaxlandJunction
          ],
          deviation: [
            lapstonDeviationStartGlenbrookTunnel,
            {
              name: 'Glenbrook Tunnel (Old)',
              segment: glenbrookTunnelOld,
              type: 'tunnel'
            },
            glenbrookTunnelLapstonDeviationEnd
          ]
        }

      ],
      history: {
        opened: {
          date: '1867-07-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1913-09-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [glenbrook1stBlaxlandJunction],
      history: {
        opened: {
          date: '1867-07-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1913-09-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        glenbrookGorgeDeviationStartGlenbrookTunnel,
        {
          name: 'Glenbrook Tunnel',
          segment: glenbrookTunnel,
          type: 'tunnel'
        },
        glenbrookTunnelGlenbrookGorgeDeviationEnd
      ],
      history: {
        opened: {
          date: '1913-05-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [blaxlandJunctionWentworthFalls],
      history: {
        opened: {
          date: '1867-07-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1911-01-01',
          tracks: 2
        }, {
          date: '1957-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [wentworthFallsMountVictoria],
      history: {
        opened: {
          date: '1868-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1911-01-01',
          tracks: 2
        }, {
          date: '1957-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [mountVictoriaDargensDeviationJunction],
      history: {
        opened: {
          date: '1869-10-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1880-01-01',
          tracks: 2
        }, {
          date: '1957-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [dargensDeviationJunctionNewnesJunction],
      history: {
        opened: {
          date: '1897-04-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [original],
      history: {
        opened: {
          date: '1869-10-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1897-04-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        tenTunnelsDeviationStartTunnel1,
        {
          name: 'Zig Zag No 1 Tunnel',
          segment: zigZagNo1Tunnel,
          type: 'tunnel'
        },
        tunnel1Tunnel2,
        {
          name: 'Zig Zag No 2 Tunnel',
          segment: zigZagNo2Tunnel,
          type: 'tunnel'
        },
        tunnel2Tunnel3,
        {
          name: 'Zig Zag No 3 Tunnel',
          segment: zigZagNo3Tunnel,
          type: 'tunnel'
        },
        tunnel3Tunnel4,
        {
          name: 'Zig Zag No 4 Tunnel',
          segment: zigZagNo4Tunnel,
          type: 'tunnel'
        },
        tunnel4Tunnel5,
        {
          name: 'Zig Zag No 5 Tunnel',
          segment: zigZagNo5Tunnel,
          type: 'tunnel'
        },
        tunnel5Tunnel6,
        {
          name: 'Zig Zag No 6 Tunnel',
          segment: zigZagNo6Tunnel,
          type: 'tunnel'
        },
        tunnel6Tunnel7,
        {
          name: 'Zig Zag No 7 Tunnel',
          segment: zigZagNo7Tunnel,
          type: 'tunnel'
        },
        tunnel7Tunnel8,
        {
          name: 'Zig Zag No 8 Tunnel',
          segment: zigZagNo8Tunnel,
          type: 'tunnel'
        },
        tunnel8Tunnel9,
        {
          name: 'Zig Zag No 9 Tunnel',
          segment: zigZagNo9Tunnel,
          type: 'tunnel'
        },
        tunnel9Tunnel10,
        {
          name: 'Zig Zag No 10 Tunnel',
          segment: zigZagNo10Tunnel,
          type: 'tunnel'
        },
        tunnel10ZigZagDeviationEnd
      ],
      history: {
        opened: {
          date: '1910-10-16',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [dargensDeviation],
      history: {
        opened: {
          date: '1897-04-11',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-10-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        clarenceClarenceTunnel,
        {
          name: 'Clarence Tunnel',
          segment: clarenceTunnel,
          type: 'tunnel'
        },
        clarenceTunnelMtSinai
      ],
      history: {
        opened: {
          date: '1869-10-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-10-16',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'closed'
        }, {
          date: '1988-10-29',
          status: 'open'
        }, {
          date: '2023-05-27',
          status: 'open'
        }]
      }
    },
    {
      segments: [mtSinaiTopPoints],
      history: {
        opened: {
          date: '1869-10-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-10-16',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'closed'
        }, {
          date: '1987-04-04',
          status: 'open'
        }, {
          date: '2023-05-27',
          status: 'open'
        }]
      }
    },
    {
      segments: [
        topPointsZigZagTunnel,
        {
          name: 'Zig Zag Tunnel',
          segment: zigZagTunnel,
          type: 'tunnel'
        },
        zigZagTunnelZigZag
      ],
      history: {
        opened: {
          date: '1869-10-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-10-16',
          status: 'closed'
        }, {
          date: '2012-01-01',
          status: 'closed'
        }, {
          date: '1975-10-18',
          status: 'open'
        }, {
          date: '2023-05-27',
          status: 'open'
        }]
      }
    },
    {
      segments: [zigZagLithgow],
      history: {
        opened: {
          date: '1869-10-18',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1880-01-01',
          tracks: 2
        }, {
          date: '1957-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [lithgowBowenfels],
      history: {
        opened: {
          date: '1870-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1880-01-01',
          tracks: 2
        }, {
          date: '1957-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1974-09-21',
          electrified: undefined
        }]
      }
    },
    {
      segments: [bowenfelsMarrangaroo],
      history: {
        opened: {
          date: '1870-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-03-14',
          tracks: 2
        }]
      }
    },
    {
      segments: [{
        date: '1928-10-28',
        original: [
          marrangarooOldStartMarrangarooTunnelOld,
          {
            name: 'Marrangaroo Tunnel (Old)',
            segment: marrangarooTunnelOld,
            type: 'tunnel'
          },
          marrangarooTunnelOldMudgeeRoadTunnel,
          {
            name: 'Mudgee Road Tunnel',
            segment: mudgeeRoadTunnel,
            type: 'tunnel'
          },
          mudgeeRoadTunnelMarrangarooNo3Tunnel,
          {
            name: 'Marrangaroo No 3 Tunnel',
            segment: marangarooNo3Tunnel,
            type: 'tunnel'
          },
          marangarooNo3TunnelMarrangarooOldEnd
        ],
        deviation: [
          marrangarooDeviationStartMarrangarooTunnel,
          {
            name: 'Marrangaroo Tunnel',
            segment: marrangarooTunnel,
            type: 'tunnel'
          },
          marrangarooTunnelMarrangarooDeviationEnd
        ]
      }, wallerawangDeviationEndWallerwang],
      history: {
        opened: {
          date: '1870-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1928-10-28',
          tracks: 2
        }]
      }
    },
    {
      segments: [wallerawangRydal],
      history: {
        opened: {
          date: '1870-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-03-14',
          tracks: 2
        }, {
          date: '1996-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [rydalTarana],
      history: {
        opened: {
          date: '1872-04-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-03-14',
          tracks: 2
        }, {
          date: '1996-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [taranaLocksley],
      history: {
        opened: {
          date: '1872-04-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-03-14',
          tracks: 2
        }, {
          date: '1996-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [locksleyBrewongle],
      history: {
        opened: {
          date: '1872-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-03-14',
          tracks: 2
        }, {
          date: '1996-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [brewongleRaglan],
      history: {
        opened: {
          date: '1873-03-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-03-14',
          tracks: 2
        }]
      }
    },
    {
      segments: [raglanKelso],
      history: {
        opened: {
          date: '1875-02-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-03-14',
          tracks: 2
        }]
      }
    },
    {
      segments: [kelsoBathurst],
      history: {
        opened: {
          date: '1876-04-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-03-14',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        bathurstBlayneyDeviation,
        blayneyBlayneyDeviation, {
          date: '1898-02-02',
          original: [blayneyOld],
          deviation: [blayneyDeviation]
        }
      ],
      history: {
        opened: {
          date: '1876-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [blayneyOrange],
      history: {
        opened: {
          date: '1877-04-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [orangeWellington],
      history: {
        opened: {
          date: '1880-06-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wellingtonDubbo],
      history: {
        opened: {
          date: '1881-02-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [dubboNeverite],
      history: {
        opened: {
          date: '1883-06-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [neveriteNyngan],
      history: {
        opened: {
          date: '1883-06-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [nynganNynganJunction],
      history: {
        opened: {
          date: '1884-09-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [nynganJunctionByrock],
      history: {
        opened: {
          date: '1884-09-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-05-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [byrockBourke],
      history: {
        opened: {
          date: '1884-09-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1989-05-17',
          status: 'closed'
        }]
      }
    }
  ]
};
