import { Line } from "../../trackTypes";

const penshustHamilton = [
  [-37.8689, 142.2983],
  [-37.8713, 142.2872],
  [-37.8714, 142.2843],
  [-37.8712, 142.2827],
  [-37.8686, 142.2685],
  [-37.8678, 142.2663],
  [-37.8584, 142.2529],
  [-37.8573, 142.2506],
  [-37.8446, 142.2115],
  [-37.8393, 142.2026],
  [-37.8374, 142.2002],
  [-37.8315, 142.1948],
  [-37.8221, 142.1838],
  [-37.8199, 142.1796],
  [-37.8090, 142.1367],
  [-37.8081, 142.0947],
  [-37.8075, 142.0905],
  [-37.8025, 142.0692],
  [-37.8004, 142.0654],
  [-37.7890, 142.0570],
  [-37.7864, 142.0537],
  [-37.7832, 142.0466],
  [-37.7801, 142.0427],
  [-37.7699, 142.0367],
  [-37.7660, 142.0328],
  [-37.7624, 142.0267],
  [-37.7606, 142.0229],
  [-37.7590, 142.0219],
  [-37.7573, 142.0221],
  [-37.7561, 142.02254]
];

const dunkeldPenshurst = [
  [-37.65283, 142.3380],
  [-37.6530, 142.3357],
  [-37.6541, 142.3327],
  [-37.6554, 142.3308],
  [-37.6578, 142.3292],
  [-37.6888, 142.3181],
  [-37.7071, 142.3116],
  [-37.7208, 142.3105],
  [-37.7427, 142.3015],
  [-37.7623, 142.2890],
  [-37.7646, 142.2883],
  [-37.8547, 142.2898],
  [-37.8601, 142.2891],
  [-37.8614, 142.2891],
  [-37.8659, 142.2900],
  [-37.8676, 142.2913],
  [-37.8689, 142.2932],
  [-37.8694, 142.2946],
  [-37.869375, 142.2961]
];

const koroitPenshurst = [
  [-38.2852, 142.3630],
  [-38.2845, 142.3623],
  [-38.2836, 142.3617],
  [-38.2825, 142.3615],
  [-38.2817, 142.3616],
  [-38.2400, 142.3695],
  [-38.2372, 142.3699],
  [-38.1087, 142.3680],
  [-38.1063, 142.3675],
  [-38.1043, 142.3662],
  [-38.0880, 142.3506],
  [-38.0865, 142.3479],
  [-38.0847, 142.3493],
  [-38.0657, 142.3486],
  [-38.0635, 142.3493],
  [-38.0034, 142.3484],
  [-38.0013, 142.3489],
  [-37.9524, 142.3687],
  [-37.9509, 142.3699],
  [-37.9476, 142.3740],
  [-37.9451, 142.3754],
  [-37.9430, 142.3757],
  [-37.9315, 142.3756],
  [-37.9285, 142.3761],
  [-37.9037, 142.3845],
  [-37.9002, 142.3849],
  [-37.8969, 142.3839],
  [-37.8947, 142.3823],
  [-37.8925, 142.3795],
  [-37.8678, 142.3380],
  [-37.8665, 142.3349],
  [-37.8655, 142.3304],
  [-37.8653, 142.3273],
  [-37.8667, 142.3134],
  [-37.8677, 142.3084],
  [-37.8679, 142.3030],
  [-37.8682, 142.3008],
  [-37.8689, 142.2983]
];

export const koroitHamilton: Line = {
  name: 'Koroit - Hamilton',
  state: 'VIC',
  segments: [
    {
      segments: [penshustHamilton],
      history: {
        opened: {
          date: '1890-08-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-09-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dunkeldPenshurst],
      history: {
        opened: {
          date: '1890-08-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1892-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [koroitPenshurst],
      history: {
        opened: {
          date: '1890-08-22',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1977-09-12',
          status: 'closed'
        }]
      }
    }
  ]
}
