import { Line } from "../../../trackTypes";

const greymouthRapahoeJunction = [
  [-42.4482, 171.2208],
  [-42.4476, 171.2201],
  [-42.4470, 171.2199],
  [-42.4456, 171.2202],
  [-42.4450, 171.2205],
  [-42.4447, 171.2209],
  [-42.4445, 171.2215],
  [-42.4442, 171.2220],
  [-42.4440, 171.2224],
  [-42.4440, 171.2228],
  [-42.4438, 171.2235],
  [-42.4437, 171.2244],
  [-42.4436, 171.2254],
  [-42.4429, 171.2272],
  [-42.4424, 171.2279],
  [-42.4416, 171.2287],
  [-42.4404, 171.2301],
  [-42.4400, 171.2304],
  [-42.4394, 171.2306],
  [-42.4381, 171.2314],
  [-42.4374, 171.2321],
  [-42.4369, 171.2329],
  [-42.4363, 171.2334],
  [-42.4357, 171.2336],
  [-42.4352, 171.2339],
  [-42.4282, 171.2384],
  [-42.4189, 171.2453],
  [-42.4114, 171.2494],
  [-42.4092, 171.2499],
  [-42.4074, 171.2500],
  [-42.4064, 171.2498],
  [-42.4053, 171.2493],
  [-42.4050, 171.2492]
];

const rapahoeJunctionRewanuiTunnel = [
  [-42.4050, 171.2492],
  [-42.4044, 171.2493],
  [-42.4036, 171.2499],
  [-42.4032, 171.2508],
  [-42.4028, 171.2529],
  [-42.4021, 171.2550],
  [-42.4011, 171.2564],
  [-42.4001, 171.2572],
  [-42.3989, 171.2583],
  [-42.3974, 171.2597],
  [-42.3967, 171.2602],
  [-42.3954, 171.2604],
  [-42.3944, 171.2611],
  [-42.3939, 171.2619],
  [-42.3927, 171.2645],
  [-42.3921, 171.2654],
  [-42.3907, 171.2669],
  [-42.3901, 171.2679],
  [-42.3899, 171.2686],
  [-42.3890, 171.2747],
  [-42.3888, 171.2753],
  [-42.3888, 171.2757],
  [-42.3888, 171.2764],
  [-42.3886, 171.2773],
  [-42.3880, 171.2782],
  [-42.3878, 171.2790],
  [-42.3877, 171.2798],
  [-42.3885, 171.2833],
  [-42.3884, 171.2843],
  [-42.3879, 171.2853],
  [-42.3877, 171.2860],
  [-42.3874, 171.2874],
  [-42.3870, 171.2882],
  [-42.3866, 171.2888],
  [-42.3865, 171.2890]
];

const rewanuiTunnel = [
  [-42.3865, 171.2890],
  [-42.3864, 171.2898]
];

const rewanuiTunnelRewanui = [
  [-42.3864, 171.2898],
  [-42.3864, 171.2901],
  [-42.3865, 171.2906],
  [-42.3867, 171.2943],
  [-42.3870, 171.2964],
  [-42.3870, 171.2974],
  [-42.3866, 171.2988],
  [-42.3862, 171.2996],
  [-42.3860, 171.3008],
  [-42.3855, 171.3022],
  [-42.3849, 171.3031],
  [-42.3844, 171.3047],
  [-42.3840, 171.3061],
  [-42.3838, 171.3066],
  [-42.3836, 171.3071],
  [-42.3835, 171.3076],
  [-42.3835, 171.3089],
  [-42.3832, 171.3097],
  [-42.3827, 171.3105],
  [-42.3822, 171.3117],
  [-42.3815, 171.3123],
  [-42.3810, 171.3124],
  [-42.3803, 171.3123],
  [-42.3798, 171.3125],
  [-42.3793, 171.3129],
  [-42.3789, 171.3135],
  [-42.3784, 171.3138],
  [-42.3774, 171.3139],
  [-42.3770, 171.3141],
  [-42.3750, 171.3158]
];

export const rewanui: Line = {
  name: 'Rewanui',
  state: 'NZ',
  segments: [
    {
      segments: [greymouthRapahoeJunction],
      history: {
        opened: {
          date: '1914-01-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        rapahoeJunctionRewanuiTunnel,
        {
          name: 'Rewanui Tunnel',
          segment: rewanuiTunnel,
          type: 'tunnel'
        },
        rewanuiTunnelRewanui
      ],
      history: {
        opened: {
          date: '1914-01-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-08-19',
          status: 'closed'
        }]
      }
    }
  ]
}
