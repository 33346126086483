import { Line } from "../../trackTypes";

const gawlerKapunda = [
  [-34.6019, 138.7380],
  [-34.5899, 138.7294],
  [-34.5868, 138.7279],
  [-34.5821, 138.7267],
  [-34.5763, 138.7265],
  [-34.5708, 138.7272],
  [-34.5618, 138.7296],
  [-34.5549, 138.7329],
  [-34.5501, 138.7366],
  [-34.5455, 138.7406],
  [-34.5379, 138.7448],
  [-34.5300, 138.7469],
  [-34.5284, 138.7473],
  [-34.5244, 138.7482],
  [-34.5203, 138.7503],
  [-34.5173, 138.7529],
  [-34.5095, 138.7619],
  [-34.5032, 138.7668],
  [-34.4801, 138.7887],
  [-34.4721, 138.7982],
  [-34.4637, 138.8044],
  [-34.4560, 138.8070],
  [-34.4519, 138.8089],
  [-34.4498, 138.8107],
  [-34.4259, 138.8351],
  [-34.4223, 138.8395],
  [-34.4179, 138.8471],
  [-34.4168, 138.8511],
  [-34.4159, 138.8592],
  [-34.4140, 138.8639],
  [-34.4103, 138.8667],
  [-34.4073, 138.8670],
  [-34.4055, 138.8682],
  [-34.4048, 138.8704],
  [-34.4049, 138.8733],
  [-34.4037, 138.8754],
  [-34.4020, 138.8760],
  [-34.4005, 138.8756],
  [-34.3982, 138.8730],
  [-34.3968, 138.8721],
  [-34.3923, 138.8713],
  [-34.3908, 138.8717],
  [-34.3884, 138.8733],
  [-34.3877, 138.8752],
  [-34.3868, 138.8823],
  [-34.3859, 138.8841],
  [-34.3848, 138.8848],
  [-34.3803, 138.8867],
  [-34.3781, 138.8868],
  [-34.3763, 138.8861],
  [-34.3749, 138.8863],
  [-34.3739, 138.8870],
  [-34.3724, 138.8903],
  [-34.3722, 138.8920],
  [-34.3724, 138.8938],
  [-34.3720, 138.8950],
  [-34.3713, 138.8956],
  [-34.3700, 138.8959],
  [-34.3692, 138.8965],
  [-34.3683, 138.8983],
  [-34.3673, 138.8989],
  [-34.3665, 138.8990],
  [-34.3630, 138.8974],
  [-34.3610, 138.8974],
  [-34.3603, 138.8977],
  [-34.3472, 138.9084],
  [-34.3457, 138.9091],
  [-34.3445, 138.9091],
  [-34.3426, 138.9088]
];

const kapundaEudunda = [
  [-34.3426, 138.9088],
  [-34.3417, 138.9087],
  [-34.3406, 138.9092],
  [-34.3398, 138.9102],
  [-34.3395, 138.9110],
  [-34.3391, 138.9122],
  [-34.3388, 138.9127],
  [-34.3378, 138.9135],
  [-34.3373, 138.9143],
  [-34.3358, 138.9162],
  [-34.3353, 138.9174],
  [-34.3343, 138.9248],
  [-34.3344, 138.9262],
  [-34.3348, 138.9280],
  [-34.3357, 138.9304],
  [-34.3359, 138.9322],
  [-34.3352, 138.9343],
  [-34.3342, 138.9356],
  [-34.3314, 138.9376],
  [-34.3306, 138.9386],
  [-34.3304, 138.9394],
  [-34.3300, 138.9409],
  [-34.3300, 138.9430],
  [-34.3296, 138.9447],
  [-34.3274, 138.9487],
  [-34.3266, 138.9496],
  [-34.3257, 138.9502],
  [-34.3250, 138.9511],
  [-34.3246, 138.9522],
  [-34.3210, 138.9701],
  [-34.3200, 138.9726],
  [-34.3189, 138.9741],
  [-34.2886, 139.0054],
  [-34.2867, 139.0066],
  [-34.2764, 139.0105],
  [-34.2745, 139.0106],
  [-34.2731, 139.0103],
  [-34.2670, 139.0083],
  [-34.2646, 139.0083],
  [-34.2628, 139.0090],
  [-34.2526, 139.0153],
  [-34.2509, 139.0170],
  [-34.2503, 139.0183],
  [-34.2452, 139.0305],
  [-34.2441, 139.0321],
  [-34.2432, 139.0328],
  [-34.2362, 139.0368],
  [-34.2232, 139.0395],
  [-34.2046, 139.0453],
  [-34.2013, 139.0454],
  [-34.1634, 139.0416],
  [-34.1605, 139.0421],
  [-34.1584, 139.0435],
  [-34.1487, 139.0531],
  [-34.1471, 139.0557],
  [-34.1470, 139.0570],
  [-34.1476, 139.0585],
  [-34.1486, 139.0593],
  [-34.1499, 139.0605],
  [-34.1544, 139.0658],
  [-34.1553, 139.0673],
  [-34.1568, 139.0711],
  [-34.1582, 139.0736],
  [-34.1615, 139.0767],
  [-34.1721, 139.0825],
  [-34.1752, 139.0854]
];

const eudundaMorgan = [
  [-34.1752, 139.0854],
  [-34.1771, 139.0875],
  [-34.1790, 139.0902],
  [-34.1792, 139.0908],
  [-34.1792, 139.0920],
  [-34.1789, 139.0930],
  [-34.1778, 139.0952],
  [-34.1772, 139.0958],
  [-34.1759, 139.0962],
  [-34.1749, 139.0960],
  [-34.1725, 139.0953],
  [-34.1713, 139.0954],
  [-34.1702, 139.0961],
  [-34.1695, 139.0977],
  [-34.1642, 139.1275],
  [-34.1642, 139.1295],
  [-34.1653, 139.1439],
  [-34.1656, 139.1463],
  [-34.1707, 139.1734],
  [-34.1707, 139.1753],
  [-34.1700, 139.1772],
  [-34.1640, 139.1885],
  [-34.1628, 139.1917],
  [-34.1227, 139.3569],
  [-34.1096, 139.4116],
  [-34.1094, 139.4168],
  [-34.0696, 139.5741],
  [-34.0669, 139.5800],
  [-34.0556, 139.6287],
  [-34.0546, 139.6308],
  [-34.0465, 139.6440],
  [-34.0456, 139.6464],
  [-34.0424, 139.6642],
  [-34.0413, 139.6666],
  [-34.0405, 139.6692],
  [-34.0401, 139.6700],
  [-34.0395, 139.6703],
  [-34.0382, 139.6704],
  [-34.0375, 139.6709],
  [-34.0360, 139.6732],
  [-34.0354, 139.6736],
  [-34.0338, 139.6746]
];

export const morgan: Line = {
  name: 'Morgan',
  state: 'SA',
  segments: [
    {
      segments: [gawlerKapunda],
      history: {
        opened: {
          date: '1860-08-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2003-05-21',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kapundaEudunda],
      history: {
        opened: {
          date: '1878-09-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-03-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eudundaMorgan],
      history: {
        opened: {
          date: '1878-09-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-11-02',
          status: 'closed'
        }]
      }
    }
  ]
};
