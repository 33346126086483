import { Line } from "../../../trackTypes";

const waiparaWaikari = [
  [-43.0569, 172.7592],
  [-43.0559, 172.7592],
  [-43.0551, 172.7587],
  [-43.0339, 172.7365],
  [-43.0327, 172.7349],
  [-43.0296, 172.7278],
  [-43.0290, 172.7270],
  [-43.0281, 172.7260],
  [-43.0267, 172.7233],
  [-43.0254, 172.7188],
  [-43.0253, 172.7169],
  [-43.0247, 172.7147],
  [-43.0237, 172.7132],
  [-43.0221, 172.7124],
  [-43.0206, 172.7113],
  [-43.0197, 172.7110],
  [-43.0189, 172.7107],
  [-43.0171, 172.7089],
  [-43.0167, 172.7080],
  [-43.0167, 172.7072],
  [-43.0167, 172.7063],
  [-43.0164, 172.7052],
  [-43.0156, 172.7046],
  [-43.0149, 172.7047],
  [-43.0140, 172.7052],
  [-43.0131, 172.7053],
  [-43.0119, 172.7048],
  [-43.0109, 172.7038],
  [-43.0101, 172.7036],
  [-43.0095, 172.7039],
  [-43.0087, 172.7047],
  [-43.0078, 172.7052],
  [-43.0069, 172.7053],
  [-43.0061, 172.7061],
  [-43.0055, 172.7076],
  [-43.0046, 172.7093],
  [-43.0042, 172.7101],
  [-43.0037, 172.7108],
  [-43.0028, 172.7112],
  [-43.0018, 172.7121],
  [-43.0009, 172.7133],
  [-42.9963, 172.7193],
  [-42.9941, 172.7204],
  [-42.9900, 172.7204],
  [-42.9880, 172.7209],
  [-42.9870, 172.7207],
  [-42.9848, 172.7190],
  [-42.9824, 172.7180],
  [-42.9812, 172.7168],
  [-42.9782, 172.7103],
  [-42.9761, 172.7080],
  [-42.9748, 172.7074],
  [-42.9727, 172.7073],
  [-42.9720, 172.7068],
  [-42.9716, 172.7063],
  [-42.9714, 172.7058]
];

const waikariMedbury = [
  [-42.9714, 172.7058],
  [-42.9707, 172.7035],
  [-42.9694, 172.6945],
  [-42.9691, 172.6931],
  [-42.9600, 172.6679],
  [-42.9588, 172.6658],
  [-42.9573, 172.6642],
  [-42.9362, 172.6483],
  [-42.9349, 172.6479],
  [-42.9338, 172.6481],
  [-42.9314, 172.6487],
  [-42.8715, 172.6736]
];

const medburyCulverden = [
  [-42.8715, 172.6736],
  [-42.8688, 172.6744],
  [-42.8674, 172.6746],
  [-42.8486, 172.6750],
  [-42.8469, 172.6754],
  [-42.8451, 172.6766],
  [-42.8435, 172.6791],
  [-42.7908, 172.8065],
  [-42.7748, 172.8452],
  [-42.7725, 172.8490],
  [-42.7693, 172.8515]
];

const culverdenWaiau = [
  [-42.7693, 172.8515],
  [-42.7485, 172.8684],
  [-42.7033, 172.9366],
  [-42.7024, 172.9376],
  [-42.6861, 172.9449],
  [-42.6790, 172.9544],
  [-42.6779, 172.9565],
  [-42.6685, 172.9674],
  [-42.6593, 172.9856],
  [-42.6577, 172.9915],
  [-42.6564, 173.0005],
  [-42.6519, 173.0179],
  [-42.6518, 173.0190],
  [-42.6522, 173.0209],
  [-42.6530, 173.0227],
  [-42.6550, 173.0272]
];

export const waiau: Line = {
  name: 'Waiau',
  state: 'NZ',
  segments: [
    {
      segments: [waiparaWaikari],
      history: {
        opened: {
          date: '1882-04-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-15',
          status: 'closed'
        }, {
          date: '1999-09-11',
          status: 'open'
        }]
      }
    },
    {
      segments: [waikariMedbury],
      history: {
        opened: {
          date: '1884-09-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [medburyCulverden],
      history: {
        opened: {
          date: '1886-02-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [culverdenWaiau],
      history: {
        opened: {
          date: '1919-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-15',
          status: 'closed'
        }]
      }
    }
  ]
}
