import { Line } from "../../../trackTypes";

const line = [
  [-34.0489, 151.0513],
  [-34.0529, 151.0525],
  [-34.0542, 151.0525],
  [-34.0555, 151.0527],
  [-34.0566, 151.0537],
  [-34.0572, 151.0547],
  [-34.0581, 151.0552],
  [-34.0589, 151.0552],
  [-34.0597, 151.0551],
  [-34.0606, 151.0554],
  [-34.0630, 151.0569]
];

export const royalPark: Line = {
  name: 'Royal Park',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1886-03-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1991-06-11',
          status: 'closed'
        }, {
          date: '1993-05-01',
          status: 'open',
          trackType: 'tram'
        }]
      }
    }
  ]
};
