import { Line } from "../../trackTypes";

const portAlbertTurnBack = [
  [-38.66115, 146.68636],
  [-38.66424, 146.68830],
  [-38.66441, 146.68858],
  [-38.66448, 146.68876],
  [-38.66443, 146.68897],
  [-38.66422, 146.68911],
  [-38.65977, 146.69009],
  [-38.65926, 146.69031],
  [-38.65884, 146.69071],
  [-38.65829, 146.69127],
  [-38.64567, 146.70642],
  [-38.64532, 146.70676],
  [-38.64482, 146.70713],
  [-38.64380, 146.70768],
  [-38.63730, 146.71264],
  [-38.63678, 146.71331],
  [-38.63650, 146.71387],
  [-38.63646, 146.71474],
  [-38.63683, 146.71629],
  [-38.63707, 146.71826],
  [-38.63702, 146.71866],
  [-38.63679, 146.71898],
  [-38.63653, 146.71910],
  [-38.62885, 146.72097],
  [-38.62784, 146.72140],
  [-38.62682, 146.72197],
  [-38.62177, 146.72557],
  [-38.62111, 146.72624],
  [-38.61419, 146.73491],
  [-38.61326, 146.73594],
  [-38.60958, 146.74018],
  [-38.60891, 146.74074],
  [-38.60777, 146.74163],
  [-38.60415, 146.74746],
  [-38.60344, 146.74869],
  [-38.60200, 146.75253],
  [-38.60171, 146.75320],
  [-38.60106, 146.75410],
  [-38.58385, 146.77495],
  [-38.57488, 146.78733],
  [-38.57390, 146.78830],
  [-38.56745, 146.79273],
  [-38.56685, 146.79333],
  [-38.56628, 146.79395],
  [-38.54807, 146.80176],
  [-38.54658, 146.80258],
  [-38.53459, 146.81151],
  [-38.53323, 146.81285],
  [-38.53235, 146.81414],
  [-38.52715, 146.82406],
  [-38.52613, 146.82548],
  [-38.50675, 146.84851],
  [-38.50549, 146.84933],
  [-38.48306, 146.85412],
  [-38.48148, 146.85384],
  [-38.48022, 146.85281],
  [-38.47954, 146.85043],
  [-38.47967, 146.84667]
];

const turnBackTemporaryJunction = [
  [-38.47954, 146.85043],
  [-38.47904, 146.85199],
  [-38.47839, 146.85313],
  [-38.47784, 146.85359],
  [-38.47677, 146.85368],
  [-38.46901, 146.85327],
  [-38.46771, 146.85236],
  [-38.46562, 146.84983],
  [-38.45992, 146.84598],
  [-38.45604, 146.84504],
  [-38.45293, 146.84521],
  [-38.44771, 146.84642],
  [-38.44594, 146.84788],
  [-38.44486, 146.84881],
  [-38.43888, 146.85073]
];

const initial = [
  [-38.43888, 146.85073],
  [-38.43407, 146.85175],
  [-38.42892, 146.85155],
  [-38.42780, 146.85094],
  [-38.42496, 146.84869],
  [-38.41807, 146.84652],
  [-38.41592, 146.84666],
  [-38.40889, 146.84722]
];

const initialExtension = [
  [-38.40889, 146.84722],
  [-38.40748, 146.84804],
  [-38.39852, 146.85905],
  [-38.39732, 146.86205],
  [-38.39450, 146.86981],
  [-38.39227, 146.87323],
  [-38.39148, 146.87413],
  [-38.39034, 146.87510],
  [-38.38988, 146.87613],
  [-38.38743, 146.88343]
];

const lineShift = [
  [-38.43888, 146.85073],
  [-38.43649, 146.85244],
  [-38.43550, 146.85362],
  [-38.43480, 146.85506],
  [-38.43400, 146.85711],
  [-38.43348, 146.85913],
  [-38.43298, 146.86302],
  [-38.43301, 146.86669],
  [-38.42935, 146.88335],
  [-38.42567, 146.89013],
  [-38.42514, 146.89214],
  [-38.42509, 146.89397],
  [-38.42552, 146.89598],
  [-38.42727, 146.89964]
];

const lineShiftExtension = [
  [-38.42567, 146.89013],
  [-38.41691, 146.90271],
  [-38.40127, 146.91408],
  [-38.38755, 146.92003],
  [-38.38346, 146.92081],
  [-38.38192, 146.92048]
];

export const goodwoodTimberTram: Line = {
  name: 'Goodwood Timber Tram',
  state: 'VIC',
  segments: [
    {
      segments: [portAlbertTurnBack, turnBackTemporaryJunction],
      history: {
        opened: {
          date: '1910-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1920-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [initial],
      history: {
        opened: {
          date: '1910-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1915-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [initialExtension],
      history: {
        opened: {
          date: '1912-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1915-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lineShift],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1920-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lineShiftExtension],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1920-03-01',
          status: 'closed'
        }]
      }
    }
  ]
}
