import { Line } from "../../../trackTypes";

const bulimbaA = [
  [-27.4598, 153.1101],
  [-27.4594, 153.1099],
  [-27.4592, 153.1098],
  [-27.4589, 153.1096],
  [-27.4585, 153.1092],
  [-27.4582, 153.1090],
  [-27.4556, 153.1076]
];

const bulimbaB = [
  [-27.4556, 153.1076],
  [-27.4552, 153.1074],
  [-27.4547, 153.1074],
  [-27.4541, 153.1074],
  [-27.4520, 153.1085],
  [-27.4516, 153.1086],
  [-27.4512, 153.1086],
  [-27.4508, 153.1086],
  [-27.4475, 153.1082],
  [-27.4470, 153.1082],
  [-27.4455, 153.1083],
  [-27.4453, 153.1084],
  [-27.4450, 153.1086],
  [-27.4448, 153.1089],
  [-27.4443, 153.1094],
  [-27.4434, 153.1110],
  [-27.4431, 153.1117],
  [-27.4425, 153.1137]
];

const fertilizerPlant = [
  [-27.4425, 153.1137],
  [-27.4411, 153.1188],
  [-27.4407, 153.1196],
  [-27.4394, 153.1227],
  [-27.4388, 153.1237],
  [-27.4374, 153.1249],
  [-27.4368, 153.1254],
  [-27.4362, 153.1255],
  [-27.4358, 153.1254],
  [-27.4321, 153.1230]
];

export const gibsonIsland: Line = {
  name: 'GibsonIsland',
  state: 'QLD',
  segments: [
    {
      segments: [bulimbaA],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bulimbaB],
      history: {
        opened: {
          date: '1955-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fertilizerPlant],
      history: {
        opened: {
          date: '1969-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
