import { Line } from "../../../trackTypes";

const line = [
  [-27.1788, 151.2680],
  [-27.1784, 151.2679],
  [-27.1780, 151.2679],
  [-27.1772, 151.2684],
  [-27.1764, 151.2693],
  [-27.1668, 151.2819],
  [-27.1662, 151.2826],
  [-27.1488, 151.2995],
  [-27.1482, 151.3001],
  [-27.1151, 151.3543],
  [-27.1148, 151.3556],
  [-27.1159, 151.3638],
  [-27.1159, 151.3638],
  [-27.1150, 151.3656],
  [-27.1119, 151.3676],
  [-27.1109, 151.3688],
  [-27.1096, 151.3725],
  [-27.0629, 151.4337],
  [-27.0617, 151.4348],
  [-27.0611, 151.4350],
  [-27.0538, 151.4362],
  [-27.0526, 151.4362],
  [-27.0496, 151.4354],
  [-27.0479, 151.4354],
  [-27.0390, 151.4378],
  [-27.0370, 151.4388],
  [-27.0303, 151.4452],
  [-27.0282, 151.4462],
  [-27.0259, 151.4470],
  [-27.0034, 151.4496],
  [-27.0003, 151.4505],
  [-26.9763, 151.4644],
  [-26.9693, 151.4683],
  [-26.9646, 151.4689],
  [-26.9625, 151.4683],
  [-26.9597, 151.4664],
  [-26.9578, 151.4658],
  [-26.9516, 151.4654],
  [-26.9498, 151.4644],
  [-26.9474, 151.4617],
  [-26.9469, 151.4601],
  [-26.9468, 151.4581],
  [-26.9464, 151.4565],
  [-26.9464, 151.4550],
  [-26.9460, 151.4538],
  [-26.9431, 151.4499],
  [-26.9418, 151.4493],
  [-26.9409, 151.4493],
  [-26.9384, 151.4501],
  [-26.9375, 151.4502],
  [-26.9361, 151.4500],
  [-26.9352, 151.4497],
  [-26.9330, 151.4483]
];

export const bell: Line = {
  name: 'Bell',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1906-04-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1994-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
