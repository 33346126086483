import { Line } from "../../trackTypes";

const waubraJunctionRacecourse = [
  [-37.4752, 143.85097],
  [-37.4736, 143.8497],
  [-37.4725, 143.8480],
  [-37.4718, 143.8457],
  [-37.4690, 143.8237],
  [-37.4677, 143.8165]
];

const racecourseWaubra = [
  [-37.4677, 143.8165],
  [-37.4668, 143.8154],
  [-37.4656, 143.8137],
  [-37.4641, 143.8125],
  [-37.4590, 143.8100],
  [-37.4528, 143.8085],
  [-37.4482, 143.8083],
  [-37.4472, 143.8080],
  [-37.4463, 143.8072],
  [-37.4379, 143.7997],
  [-37.4321, 143.7957],
  [-37.4312, 143.7946],
  [-37.4306, 143.7928],
  [-37.4290, 143.7833],
  [-37.4297, 143.7746],
  [-37.4278, 143.7548],
  [-37.4280, 143.7529],
  [-37.4315, 143.7345],
  [-37.4316, 143.7318],
  [-37.4314, 143.7287],
  [-37.4308, 143.7267],
  [-37.4216, 143.7131],
  [-37.4204, 143.7123],
  [-37.4123, 143.7088],
  [-37.4091, 143.7067],
  [-37.4032, 143.7010],
  [-37.4023, 143.6997],
  [-37.3997, 143.6944],
  [-37.3987, 143.6932],
  [-37.3974, 143.6922],
  [-37.3915, 143.6894],
  [-37.3900, 143.6882],
  [-37.3840, 143.6809],
  [-37.3827, 143.6789],
  [-37.3755, 143.6658],
  [-37.3641, 143.6468],
  [-37.3615, 143.6401]
];

export const waubra: Line = {
  name: 'Waubra',
  state: 'VIC',
  segments: [
    {
      segments: [waubraJunctionRacecourse],
      history: {
        opened: {
          date: '1881-08-11',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-04-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [racecourseWaubra],
      history: {
        opened: {
          date: '1881-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-31',
          status: 'closed'
        }]
      }
    }
  ]
}
