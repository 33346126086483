import { Line } from "../../../trackTypes";

const line = [
  [-38.4333, 145.8264],
  [-38.4343, 145.8275],
  [-38.4349, 145.8278],
  [-38.4358, 145.8276],
  [-38.4368, 145.8271],
  [-38.4375, 145.8270],
  [-38.4402, 145.8283],
  [-38.4411, 145.8291],
  [-38.4420, 145.8297],
  [-38.4428, 145.8300],
  [-38.4435, 145.8297],
  [-38.4447, 145.8291],
  [-38.4456, 145.8290],
  [-38.4465, 145.8292],
  [-38.4478, 145.8299],
  [-38.4483, 145.8298],
  [-38.4487, 145.8295],
  [-38.4493, 145.8288],
  [-38.4501, 145.8281],
  [-38.4516, 145.8275],
  [-38.4526, 145.8261],
  [-38.4527, 145.8229],
  [-38.4530, 145.8219],
  [-38.4535, 145.8206]
];

const sunbeamCollieriesSiding = [
  [-38.4411, 145.8291],
  [-38.4422, 145.8307],
  [-38.4428, 145.8312],
  [-38.4436, 145.8315],
  [-38.4442, 145.8315],
  [-38.4450, 145.8310]
];

const strezleckiSiding = [
  [-38.4531, 145.8429],
  [-38.4534, 145.8434],
  [-38.4541, 145.8440],
  [-38.4549, 145.8441],
  [-38.4559, 145.8436],
  [-38.4565, 145.8430],
  [-38.4575, 145.8406],
  [-38.4585, 145.8402],
  [-38.4594, 145.8403],
  [-38.4602, 145.8412],
  [-38.4629, 145.8456],
  [-38.4649, 145.8487]
];

const australSiding = [
  [-38.4478, 145.8299],
  [-38.4486, 145.8305],
  [-38.4490, 145.8317],
  [-38.4494, 145.8360],
  [-38.4499, 145.8375],
  [-38.4507, 145.8394],
  [-38.4512, 145.8402],
  [-38.4523, 145.8411],
  [-38.4529, 145.8421],
  [-38.4531, 145.8429],
  [-38.4531, 145.8439],
  [-38.4524, 145.8454],
  [-38.4507, 145.8465]
];

export const coalCreek: Line = {
  name: 'Coal Creek',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1892-10-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-04-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sunbeamCollieriesSiding],
      history: {
        opened: {
          date: '1894-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-05-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [strezleckiSiding],
      history: {
        opened: {
          date: '1894-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1911-06-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [australSiding],
      history: {
        opened: {
          date: '1894-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1951-05-10',
          status: 'closed'
        }]
      }
    }
  ]
};
