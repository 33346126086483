import { Line } from "../../../trackTypes";

const loop = [
  [-23.37488, 150.51098],
  [-23.37980, 150.51457],
  [-23.37991, 150.51459],
  [-23.38003, 150.51455],
  [-23.38773, 150.50223],
  [-23.38773, 150.50211],
  [-23.38768, 150.50199],
  [-23.38276, 150.49839],
  [-23.38265, 150.49838],
  [-23.38256, 150.49843],
  [-23.37968, 150.50300],
  [-23.37952, 150.50326],
  [-23.37484, 150.51076],
  [-23.37482, 150.51087],
  [-23.37488, 150.51098]
];

const showgroundsJunction = [
  [-23.37968, 150.50300],
  [-23.37958, 150.50304],
  [-23.37948, 150.50305],
];

const showgrounds = [
  [-23.37952, 150.50326],
  [-23.37952, 150.50316],
  [-23.37948, 150.50305],
  [-23.37271, 150.49811],
  [-23.37258, 150.49804],
  [-23.37235, 150.49800],
  [-23.36892, 150.49848],
  [-23.36846, 150.49855],
  [-23.36835, 150.49851],
  [-23.36826, 150.49846]
];

const wandal = [
  [-23.36892, 150.49848],
  [-23.36878, 150.49838],
  [-23.36870, 150.49827],
  [-23.36740, 150.48909]
];

const gardens = [
  [-23.38773, 150.50223],
  [-23.38859, 150.50084],
  [-23.38866, 150.50077],
  [-23.38876, 150.50071],
  [-23.39511, 150.49963],
  [-23.39526, 150.49958],
  [-23.39548, 150.49943],
  [-23.39560, 150.49938],
  [-23.39745, 150.49906],
  [-23.39753, 150.49899],
  [-23.39756, 150.49887],
  [-23.39704, 150.49493],
  [-23.39709, 150.49480],
  [-23.39718, 150.49472],
  [-23.39946, 150.49436]
];

const dawsonRoadJunction = [
  [-23.38773, 150.50223],
  [-23.38785, 150.50219],
  [-23.38800, 150.50221]
]

const dawsonRoad = [
  [-23.38768, 150.50199],
  [-23.38800, 150.50221],
  [-23.38884, 150.50281],
  [-23.38899, 150.50284],
  [-23.38914, 150.50285],
  [-23.40194, 150.50072]
];

export const rockhamptonTrams: Line = {
  name: 'Rockhampton Trams',
  state: 'QLD',
  segments: [
    {
      segments: [loop, showgrounds, showgroundsJunction, wandal, gardens, dawsonRoadJunction, dawsonRoad],
      history: {
        opened: {
          date: '1909-06-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-06-24',
          status: 'closed'
        }]
      }
    }
  ]
}
