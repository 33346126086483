import { Line } from "../../trackTypes";

const line = [
  [-39.4049, 175.4201],
  [-39.4059, 175.4211],
  [-39.4072, 175.4215],
  [-39.4078, 175.4217],
  [-39.4083, 175.4214],
  [-39.4088, 175.4207],
  [-39.4093, 175.4189],
  [-39.4098, 175.4181],
  [-39.4113, 175.4166],
  [-39.4116, 175.4161],
  [-39.4132, 175.4116],
  [-39.4135, 175.4110],
  [-39.4202, 175.4032],
  [-39.4205, 175.4023],
  [-39.4211, 175.3918],
  [-39.4210, 175.3904],
  [-39.4207, 175.3883],
  [-39.4206, 175.3872],
  [-39.4202, 175.3773],
  [-39.4205, 175.3651],
  [-39.4210, 175.3542],
  [-39.4209, 175.3524],
  [-39.4218, 175.3468],
  [-39.4218, 175.3460],
  [-39.4217, 175.3435],
  [-39.4217, 175.3427],
  [-39.4235, 175.3372],
  [-39.4240, 175.3362],
  [-39.4246, 175.3357],
  [-39.4257, 175.3352],
  [-39.4265, 175.3342],
  [-39.4269, 175.3328],
  [-39.4280, 175.3244],
  [-39.4289, 175.3214],
  [-39.4294, 175.3204],
  [-39.4313, 175.3182],
  [-39.4344, 175.3144],
  [-39.4350, 175.3126],
  [-39.4351, 175.3113],
  [-39.4336, 175.2931],
  [-39.4328, 175.2909],
  [-39.4292, 175.2855]
];

export const raetihi: Line = {
  name: 'Raetihi',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1917-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
