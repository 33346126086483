import { Line } from "../../../trackTypes";

const stMarysOrchardHills = [
  [-33.7633, 150.7804],
  [-33.7615, 150.7697],
  [-33.7615, 150.7650],
  [-33.7641, 150.7580],
  [-33.7682, 150.7543],
  [-33.7723, 150.7525],
  [-33.7778, 150.7513],
  [-33.7811, 150.7512],
  [-33.7839, 150.7516],
  [-33.7875, 150.7517]
];

const orchardHillsAirportBusinessPark = [
  [-33.7875, 150.7517],
  [-33.7925, 150.7519],
  [-33.7966, 150.7517],
  [-33.8256, 150.7462],
  [-33.8285, 150.7450],
  [-33.8343, 150.7416],
  [-33.8369, 150.7408],
  [-33.8443, 150.7405],
  [-33.8479, 150.7415],
  [-33.8530, 150.7453],
  [-33.8558, 150.7468],
  [-33.8586, 150.7473],
  [-33.8765, 150.7436],
  [-33.8787, 150.7425],
  [-33.8806, 150.7400],
  [-33.8836, 150.7339]
];

const airportBusinessParkBradfield = [
  [-33.8836, 150.7339],
  [-33.8864, 150.7282],
  [-33.8875, 150.7247],
  [-33.8909, 150.7179],
  [-33.8930, 150.7158],
  [-33.8953, 150.7154],
  [-33.8977, 150.7159],
  [-33.9190, 150.7345],
  [-33.9206, 150.7353],
  [-33.9263, 150.7361]
];

export const metroAirportWest: Line = {
  name: 'Metro Airport West',
  state: 'NSW',
  segments: [
    {
      segments: [
        {
          segment: stMarysOrchardHills,
          type: 'tunnel'
        },
        orchardHillsAirportBusinessPark,
        {
          segment: airportBusinessParkBradfield,
          type: 'tunnel'
        }
      ],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
