import { Line } from "../../../trackTypes";

const irrewarraBeeac = [
  [-38.3120, 143.6380],
  [-38.3116, 143.6394],
  [-38.3109, 143.6405],
  [-38.3101, 143.6410],
  [-38.3092, 143.6412],
  [-38.3084, 143.6411],
  [-38.2917, 143.6364],
  [-38.2903, 143.6361],
  [-38.2847, 143.6353],
  [-38.2705, 143.6314],
  [-38.2678, 143.6312],
  [-38.2613, 143.6332],
  [-38.2586, 143.6336],
  [-38.2525, 143.6354],
  [-38.2466, 143.6373],
  [-38.2381, 143.6411],
  [-38.2351, 143.6415],
  [-38.2095, 143.6377],
  [-38.1890, 143.6377]
];

const beeacCressy = [
  [-38.1890, 143.6377],
  [-38.1836, 143.6379],
  [-38.1810, 143.6375],
  [-38.1796, 143.6372],
  [-38.1712, 143.6340],
  [-38.1684, 143.6337],
  [-38.1518, 143.6311],
  [-38.1388, 143.6281],
  [-38.1376, 143.6280],
  [-38.1278, 143.6296],
  [-38.1166, 143.6335],
  [-38.1057, 143.6358],
  [-38.0863, 143.6377],
  [-38.0810, 143.6372],
  [-38.0782, 143.6367],
  [-38.0764, 143.6365],
  [-38.0752, 143.6367],
  [-38.0652, 143.6382],
  [-38.0530, 143.6424],
  [-38.0341, 143.6458],
  [-38.0330, 143.6457],
  [-38.0320, 143.6454]
];

const cressyNewtown = [
  [-38.0253, 143.6415],
  [-38.0246, 143.6414],
  [-38.0239, 143.6414],
  [-38.0227, 143.6418],
  [-38.0218, 143.6426],
  [-38.0211, 143.6436],
  [-38.0127, 143.6574],
  [-38.0121, 143.6579],
  [-38.0111, 143.6585],
  [-38.0096, 143.6590],
  [-37.9933, 143.6573],
  [-37.9921, 143.6569],
  [-37.9897, 143.6550],
  [-37.9887, 143.6544],
  [-37.9876, 143.6543],
  [-37.9109, 143.6681],
  [-37.9086, 143.6687],
  [-37.9056, 143.6698],
  [-37.8961, 143.6744],
  [-37.8946, 143.6745],
  [-37.8632, 143.6654],
  [-37.8559, 143.6620],
  [-37.8541, 143.6619],
  [-37.8524, 143.6630],
  [-37.8513, 143.6646],
  [-37.8499, 143.6656],
  [-37.8461, 143.6660],
  [-37.8446, 143.6657],
  [-37.8398, 143.6641],
  [-37.8386, 143.6633],
  [-37.8368, 143.6619],
  [-37.8353, 143.6616],
  [-37.8275, 143.6599],
  [-37.8261, 143.6600],
  [-37.8073, 143.6650],
  [-37.8057, 143.6650],
  [-37.8015, 143.6651],
  [-37.7998, 143.6647],
  [-37.7962, 143.6631],
  [-37.7945, 143.6629],
  [-37.7900, 143.6635],
  [-37.7877, 143.6647],
  [-37.7860, 143.6648],
  [-37.7733, 143.6611],
  [-37.7716, 143.6612],
  [-37.7695, 143.6613],
  [-37.7663, 143.6607],
  [-37.7632, 143.6607],
  [-37.7582, 143.6592],
  [-37.7531, 143.6588],
  [-37.7512, 143.6575],
  [-37.7501, 143.6559],
  [-37.7466, 143.6529],
  [-37.7435, 143.6514],
  [-37.7398, 143.6501],
  [-37.7381, 143.6488],
  [-37.7369, 143.6463],
  [-37.7353, 143.6446],
  [-37.7338, 143.6443],
  [-37.7326, 143.6446],
  [-37.7301, 143.6460],
  [-37.7250, 143.6539],
  [-37.7232, 143.6549],
  [-37.7218, 143.6553],
  [-37.7164, 143.6597],
  [-37.7147, 143.6600],
  [-37.7069, 143.6574],
  [-37.7057, 143.6564],
  [-37.7034, 143.6544],
  [-37.7022, 143.6539],
  [-37.7012, 143.6540],
  [-37.7000, 143.6543]
];

export const colacBallarat: Line = {
  name: 'Colac Ballarat',
  state: 'VIC',
  segments: [
    {
      segments: [irrewarraBeeac],
      history: {
        opened: {
          date: '1889-08-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-11-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [beeacCressy],
      history: {
        opened: {
          date: '1910-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-11-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cressyNewtown],
      history: {
        opened: {
          date: '1911-09-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-11-18',
          status: 'closed'
        }]
      }
    }
  ]
};
