import { Line } from "../../trackTypes";

const line = [
  [-41.4042, 147.1477],
  [-41.4033, 147.1465],
  [-41.4022, 147.1428],
  [-41.4021, 147.1417],
  [-41.4022, 147.1409],
  [-41.4032, 147.1387]
];

export const mowbrayRacecourse: Line = {
  name: 'Mowbray Racecourse',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1907-11-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1939-09-01',
          status: 'closed'
        }]
      }
    }
  ]
}
