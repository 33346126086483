import { Line } from "../../trackTypes";

const donnybrookNoggerup = [
  [-33.5795, 115.8377],
  [-33.5793, 115.8395],
  [-33.5766, 115.8461],
  [-33.5754, 115.8477],
  [-33.5742, 115.8486],
  [-33.5694, 115.8507],
  [-33.5680, 115.8517],
  [-33.5622, 115.8572],
  [-33.5617, 115.8580],
  [-33.5615, 115.8591],
  [-33.5614, 115.8625],
  [-33.5618, 115.8639],
  [-33.5634, 115.8659],
  [-33.5641, 115.8673],
  [-33.5641, 115.8690],
  [-33.5626, 115.8745],
  [-33.5627, 115.8762],
  [-33.5632, 115.8774],
  [-33.5645, 115.8786],
  [-33.5652, 115.8802],
  [-33.5651, 115.8834],
  [-33.5649, 115.8848],
  [-33.5616, 115.8922],
  [-33.5582, 115.8974],
  [-33.5561, 115.9031],
  [-33.5436, 115.9291],
  [-33.5401, 115.9334],
  [-33.5390, 115.9357],
  [-33.5387, 115.9431],
  [-33.5396, 115.9534],
  [-33.5394, 115.9554],
  [-33.5347, 115.9756],
  [-33.5346, 115.9774],
  [-33.5364, 115.9832],
  [-33.5366, 115.9850],
  [-33.5360, 115.9920],
  [-33.5356, 115.9947],
  [-33.5356, 115.9963],
  [-33.5362, 115.9996],
  [-33.5386, 116.0068],
  [-33.5388, 116.0087],
  [-33.5387, 116.0119],
  [-33.5391, 116.0142],
  [-33.5409, 116.0203],
  [-33.5411, 116.0219],
  [-33.5409, 116.0267],
  [-33.5399, 116.0324],
  [-33.5398, 116.0374],
  [-33.5393, 116.0424],
  [-33.5376, 116.0505],
  [-33.5345, 116.0565],
  [-33.5338, 116.0588],
  [-33.5331, 116.0620],
  [-33.5304, 116.0699],
  [-33.5284, 116.0860],
  [-33.5300, 116.1033],
  [-33.5298, 116.1050],
  [-33.5289, 116.1089],
  [-33.5287, 116.1167],
  [-33.5295, 116.1205],
  [-33.5309, 116.1234],
  [-33.5322, 116.1243],
  [-33.5337, 116.1252],
  [-33.5346, 116.1265],
  [-33.5386, 116.1320],
  [-33.5396, 116.1329],
  [-33.5419, 116.1342],
  [-33.5457, 116.1355],
  [-33.5568, 116.1412],
  [-33.5581, 116.1426],
  [-33.5614, 116.1486],
  [-33.5625, 116.1498],
  [-33.5726, 116.1558],
  [-33.5797, 116.1611],
  [-33.5820, 116.1621],
  [-33.5854, 116.1620]
];

const noggerupBoyupBrook = [
  [-33.5854, 116.1620],
  [-33.5879, 116.1616],
  [-33.5893, 116.1616],
  [-33.5942, 116.1627],
  [-33.5955, 116.1636],
  [-33.5962, 116.1654],
  [-33.5970, 116.1731],
  [-33.5976, 116.1743],
  [-33.5989, 116.1754],
  [-33.6015, 116.1754],
  [-33.6044, 116.1764],
  [-33.6061, 116.1778],
  [-33.6086, 116.1790],
  [-33.6103, 116.1810],
  [-33.6113, 116.1830],
  [-33.6130, 116.1843],
  [-33.6141, 116.1858],
  [-33.6171, 116.1898],
  [-33.6216, 116.1935],
  [-33.6230, 116.1937],
  [-33.6250, 116.1932],
  [-33.6282, 116.1936],
  [-33.6313, 116.1935],
  [-33.6328, 116.1939],
  [-33.6454, 116.2032],
  [-33.6578, 116.2148],
  [-33.6592, 116.2155],
  [-33.6614, 116.2157],
  [-33.6637, 116.2169],
  [-33.6663, 116.2196],
  [-33.6681, 116.2204],
  [-33.6717, 116.2207],
  [-33.6735, 116.2211],
  [-33.6754, 116.2229],
  [-33.6770, 116.2238],
  [-33.6798, 116.2241],
  [-33.6904, 116.2292],
  [-33.6967, 116.2321],
  [-33.6985, 116.2340],
  [-33.7020, 116.2361],
  [-33.7031, 116.2380],
  [-33.7032, 116.2401],
  [-33.7036, 116.2414],
  [-33.7045, 116.2424],
  [-33.7063, 116.2430],
  [-33.7072, 116.2436],
  [-33.7129, 116.2509],
  [-33.7264, 116.2626],
  [-33.7324, 116.2650],
  [-33.7335, 116.2659],
  [-33.7370, 116.2716],
  [-33.7471, 116.2805],
  [-33.7478, 116.2818],
  [-33.7482, 116.2840],
  [-33.7493, 116.2858],
  [-33.7511, 116.2867],
  [-33.7520, 116.2875],
  [-33.7530, 116.2897],
  [-33.7544, 116.2907],
  [-33.7559, 116.2911],
  [-33.7585, 116.2933],
  [-33.7592, 116.2948],
  [-33.7591, 116.2963],
  [-33.7578, 116.2988],
  [-33.7565, 116.3027],
  [-33.7567, 116.3046],
  [-33.7578, 116.3060],
  [-33.7581, 116.3075],
  [-33.7582, 116.3099],
  [-33.7591, 116.3117],
  [-33.7632, 116.3150],
  [-33.7649, 116.3154],
  [-33.7688, 116.3159],
  [-33.7719, 116.3155],
  [-33.7731, 116.3159],
  [-33.7744, 116.3173],
  [-33.7756, 116.3182],
  [-33.7784, 116.3192],
  [-33.7797, 116.3207],
  [-33.7802, 116.3226],
  [-33.7810, 116.3239],
  [-33.7823, 116.3244],
  [-33.7835, 116.3252],
  [-33.7844, 116.3264],
  [-33.7845, 116.3284],
  [-33.7828, 116.3339],
  [-33.7827, 116.3356],
  [-33.7832, 116.3370],
  [-33.7851, 116.3386],
  [-33.7865, 116.3400],
  [-33.7874, 116.3419],
  [-33.7897, 116.3441],
  [-33.7902, 116.3462],
  [-33.7894, 116.3488],
  [-33.7894, 116.3508],
  [-33.7906, 116.3532],
  [-33.7924, 116.3543],
  [-33.7932, 116.3556],
  [-33.7936, 116.3574],
  [-33.7944, 116.3585],
  [-33.7981, 116.3604],
  [-33.7993, 116.3620],
  [-33.8005, 116.3650],
  [-33.8017, 116.3665],
  [-33.8042, 116.3681],
  [-33.8057, 116.3687],
  [-33.8084, 116.3684],
  [-33.8105, 116.3687],
  [-33.8120, 116.3701],
  [-33.8139, 116.3739],
  [-33.8170, 116.3773],
  [-33.8186, 116.3779],
  [-33.8211, 116.3774],
  [-33.8226, 116.3782],
  [-33.8274, 116.3852],
  [-33.8280, 116.3857],
  [-33.8294, 116.3865],
  [-33.8301, 116.3873],
  [-33.8328, 116.3925]
];

const boyupBrookKojonup = [
  [-33.8328, 116.3925],
  [-33.8333, 116.3940],
  [-33.8331, 116.3950],
  [-33.8320, 116.3993],
  [-33.8319, 116.4034],
  [-33.8308, 116.4058],
  [-33.8285, 116.4069],
  [-33.8264, 116.4064],
  [-33.8249, 116.4070],
  [-33.8244, 116.4077],
  [-33.8238, 116.4091],
  [-33.8238, 116.4104],
  [-33.8237, 116.4120],
  [-33.8229, 116.4135],
  [-33.8212, 116.4159],
  [-33.8200, 116.4187],
  [-33.8181, 116.4206],
  [-33.8162, 116.4209],
  [-33.8137, 116.4206],
  [-33.8124, 116.4211],
  [-33.8114, 116.4227],
  [-33.8107, 116.4258],
  [-33.8110, 116.4285],
  [-33.8120, 116.4304],
  [-33.8132, 116.4315],
  [-33.8140, 116.4330],
  [-33.8141, 116.4344],
  [-33.8134, 116.4360],
  [-33.8124, 116.4369],
  [-33.8114, 116.4374],
  [-33.8102, 116.4385],
  [-33.8089, 116.4420],
  [-33.8081, 116.4430],
  [-33.8066, 116.4442],
  [-33.8060, 116.4454],
  [-33.8060, 116.4467],
  [-33.8067, 116.4488],
  [-33.8069, 116.4501],
  [-33.8071, 116.4523],
  [-33.8075, 116.4537],
  [-33.8080, 116.4548],
  [-33.8081, 116.4556],
  [-33.8082, 116.4569],
  [-33.8087, 116.4581],
  [-33.8111, 116.4618],
  [-33.8114, 116.4632],
  [-33.8110, 116.4646],
  [-33.8101, 116.4655],
  [-33.8088, 116.4657],
  [-33.8078, 116.4655],
  [-33.8068, 116.4656],
  [-33.8054, 116.4665],
  [-33.8047, 116.4678],
  [-33.8045, 116.4688],
  [-33.8044, 116.4731],
  [-33.8041, 116.4742],
  [-33.8036, 116.4751],
  [-33.8029, 116.4760],
  [-33.8026, 116.4771],
  [-33.8027, 116.4784],
  [-33.8035, 116.4796],
  [-33.8060, 116.4812],
  [-33.8068, 116.4820],
  [-33.8072, 116.4832],
  [-33.8080, 116.4885],
  [-33.8077, 116.4902],
  [-33.8067, 116.4915],
  [-33.8025, 116.4937],
  [-33.8013, 116.4950],
  [-33.8008, 116.4965],
  [-33.8009, 116.4980],
  [-33.8027, 116.5068],
  [-33.8028, 116.5094],
  [-33.8010, 116.5163],
  [-33.8013, 116.5187],
  [-33.8028, 116.5206],
  [-33.8069, 116.5226],
  [-33.8086, 116.5243],
  [-33.8097, 116.5270],
  [-33.8101, 116.5298],
  [-33.8105, 116.5309],
  [-33.8123, 116.5349],
  [-33.8125, 116.5372],
  [-33.8114, 116.5429],
  [-33.8114, 116.5445],
  [-33.8127, 116.5490],
  [-33.8127, 116.5504],
  [-33.8122, 116.5523],
  [-33.8121, 116.5534],
  [-33.8124, 116.5545],
  [-33.8157, 116.5595],
  [-33.8159, 116.5614],
  [-33.8147, 116.5657],
  [-33.8141, 116.5674],
  [-33.8140, 116.5685],
  [-33.8146, 116.5698],
  [-33.8157, 116.5708],
  [-33.8160, 116.5720],
  [-33.8161, 116.5736],
  [-33.8170, 116.5754],
  [-33.8206, 116.5780],
  [-33.8215, 116.5793],
  [-33.8215, 116.5804],
  [-33.8210, 116.5818],
  [-33.8203, 116.5829],
  [-33.8200, 116.5843],
  [-33.8202, 116.5990],
  [-33.8196, 116.6013],
  [-33.8153, 116.6077],
  [-33.8142, 116.6115],
  [-33.8143, 116.6140],
  [-33.8150, 116.6184],
  [-33.8155, 116.6198],
  [-33.8167, 116.6229],
  [-33.8168, 116.6245],
  [-33.8167, 116.6263],
  [-33.8169, 116.6275],
  [-33.8174, 116.6284],
  [-33.8198, 116.6313],
  [-33.8206, 116.6331],
  [-33.8282, 116.6677],
  [-33.8283, 116.6699],
  [-33.8276, 116.6720],
  [-33.8263, 116.6748],
  [-33.8255, 116.6800],
  [-33.8257, 116.6822],
  [-33.8336, 116.7000],
  [-33.8347, 116.7048],
  [-33.8385, 116.7149],
  [-33.8386, 116.7168],
  [-33.8382, 116.7195],
  [-33.8382, 116.7218],
  [-33.8408, 116.7319],
  [-33.8407, 116.7342],
  [-33.8352, 116.7533],
  [-33.8351, 116.7552],
  [-33.8356, 116.7588],
  [-33.8354, 116.7605],
  [-33.8335, 116.7666],
  [-33.8332, 116.7683],
  [-33.8332, 116.7790],
  [-33.8335, 116.7803],
  [-33.8341, 116.7811],
  [-33.8348, 116.7817],
  [-33.8356, 116.7829],
  [-33.8368, 116.7921],
  [-33.8367, 116.7946],
  [-33.8335, 116.8032],
  [-33.8333, 116.8050],
  [-33.8335, 116.8068],
  [-33.8361, 116.8132],
  [-33.8370, 116.8145],
  [-33.8397, 116.8174],
  [-33.8406, 116.8191],
  [-33.8435, 116.8289],
  [-33.8434, 116.8300],
  [-33.8429, 116.8318],
  [-33.8429, 116.8329],
  [-33.8438, 116.8359],
  [-33.8439, 116.8380],
  [-33.8432, 116.8430],
  [-33.8436, 116.8505],
  [-33.8440, 116.8522],
  [-33.8482, 116.8601],
  [-33.8493, 116.8631],
  [-33.8508, 116.8694],
  [-33.8508, 116.8712],
  [-33.8502, 116.8732],
  [-33.8499, 116.8767],
  [-33.8493, 116.8786],
  [-33.8493, 116.8801],
  [-33.8500, 116.8826],
  [-33.8499, 116.8839],
  [-33.8490, 116.8855],
  [-33.8487, 116.8869],
  [-33.8492, 116.8896],
  [-33.8489, 116.8914],
  [-33.8449, 116.8965],
  [-33.8442, 116.8983],
  [-33.8442, 116.9001],
  [-33.8469, 116.9106],
  [-33.8470, 116.9119],
  [-33.8468, 116.9140],
  [-33.8470, 116.9153],
  [-33.8483, 116.9182],
  [-33.8486, 116.9199],
  [-33.8492, 116.9213],
  [-33.8495, 116.9234],
  [-33.8493, 116.9243],
  [-33.8489, 116.9252],
  [-33.8484, 116.9260],
  [-33.8480, 116.9270],
  [-33.8481, 116.9284],
  [-33.8476, 116.9335],
  [-33.8479, 116.9372],
  [-33.8478, 116.9402],
  [-33.8474, 116.9421],
  [-33.8473, 116.9433],
  [-33.8477, 116.9443],
  [-33.8485, 116.9455],
  [-33.8487, 116.9466],
  [-33.8487, 116.9481],
  [-33.8487, 116.9487],
  [-33.8491, 116.9502],
  [-33.8490, 116.9516],
  [-33.8485, 116.9528],
  [-33.8484, 116.9535],
  [-33.8484, 116.9551],
  [-33.8482, 116.9559],
  [-33.8478, 116.9568],
  [-33.8475, 116.9579],
  [-33.8475, 116.9612],
  [-33.8480, 116.9668],
  [-33.8477, 116.9683],
  [-33.8470, 116.9694],
  [-33.8419, 116.9722],
  [-33.8413, 116.9727],
  [-33.8409, 116.9735],
  [-33.8399, 116.9797],
  [-33.8397, 116.9874],
  [-33.8393, 116.9890],
  [-33.8383, 116.9922],
  [-33.8381, 116.9934],
  [-33.8384, 116.9950],
  [-33.8394, 116.9973],
  [-33.8397, 116.9986],
  [-33.8388, 117.0050],
  [-33.8387, 117.0090],
  [-33.8385, 117.0101],
  [-33.8375, 117.0121],
  [-33.8374, 117.0137],
  [-33.8383, 117.0164],
  [-33.8384, 117.0181],
  [-33.8385, 117.0220],
  [-33.8389, 117.0233],
  [-33.8402, 117.0251],
  [-33.8405, 117.0263],
  [-33.8404, 117.0275],
  [-33.8394, 117.0303],
  [-33.8394, 117.0319],
  [-33.8414, 117.0363],
  [-33.8416, 117.0390],
  [-33.8411, 117.0413],
  [-33.8397, 117.0443],
  [-33.8396, 117.0460],
  [-33.8403, 117.0475],
  [-33.8414, 117.0485],
  [-33.8431, 117.0486],
  [-33.8447, 117.0495],
  [-33.8454, 117.0507],
  [-33.8455, 117.0522],
  [-33.8447, 117.0543],
  [-33.8446, 117.0556],
  [-33.8450, 117.0583],
  [-33.8449, 117.0597],
  [-33.8445, 117.0614],
  [-33.8457, 117.0648],
  [-33.8467, 117.0658],
  [-33.8481, 117.0662],
  [-33.8490, 117.0670],
  [-33.8495, 117.0687],
  [-33.8493, 117.0708],
  [-33.8475, 117.0726],
  [-33.8465, 117.0744],
  [-33.8436, 117.0772],
  [-33.8423, 117.0792],
  [-33.8397, 117.0810],
  [-33.8384, 117.0813],
  [-33.8354, 117.0815],
  [-33.8341, 117.0818],
  [-33.8300, 117.0819],
  [-33.8272, 117.0829],
  [-33.8261, 117.0842],
  [-33.8240, 117.0913],
  [-33.8239, 117.0934],
  [-33.8244, 117.0969],
  [-33.8240, 117.0997],
  [-33.8241, 117.1011],
  [-33.8243, 117.1024],
  [-33.8242, 117.1037],
  [-33.8234, 117.1067],
  [-33.8234, 117.1188],
  [-33.8237, 117.1202],
  [-33.8277, 117.1272],
  [-33.8288, 117.1284],
  [-33.8343, 117.1332],
  [-33.8350, 117.1344],
  [-33.8374, 117.1436],
  [-33.8374, 117.1447],
  [-33.8366, 117.1494],
  [-33.8361, 117.1505],
  [-33.8356, 117.1538],
  [-33.8357, 117.1550],
  [-33.8362, 117.1560]
];

const kojonupKatanning = [
  [-33.8362, 117.1560],
  [-33.8376, 117.1599],
  [-33.8382, 117.1625],
  [-33.8388, 117.1634],
  [-33.8394, 117.1638],
  [-33.8406, 117.1643],
  [-33.8415, 117.1653],
  [-33.8416, 117.1666],
  [-33.8412, 117.1676],
  [-33.8406, 117.1683],
  [-33.8402, 117.1691],
  [-33.8400, 117.1706],
  [-33.8390, 117.1723],
  [-33.8388, 117.1734],
  [-33.8392, 117.1769],
  [-33.8392, 117.1784],
  [-33.8393, 117.1793],
  [-33.8391, 117.1803],
  [-33.8387, 117.1813],
  [-33.8386, 117.1822],
  [-33.8390, 117.1851],
  [-33.8395, 117.1861],
  [-33.8402, 117.1867],
  [-33.8426, 117.1872],
  [-33.8436, 117.1882],
  [-33.8438, 117.1894],
  [-33.8437, 117.1905],
  [-33.8437, 117.1913],
  [-33.8441, 117.1926],
  [-33.8441, 117.1936],
  [-33.8433, 117.1955],
  [-33.8433, 117.1968],
  [-33.8450, 117.2008],
  [-33.8461, 117.2023],
  [-33.8478, 117.2058],
  [-33.8486, 117.2068],
  [-33.8504, 117.2077],
  [-33.8514, 117.2102],
  [-33.8516, 117.2134],
  [-33.8518, 117.2160],
  [-33.8522, 117.2168],
  [-33.8537, 117.2180],
  [-33.8543, 117.2191],
  [-33.8541, 117.2207],
  [-33.8534, 117.2216],
  [-33.8531, 117.2226],
  [-33.8530, 117.2242],
  [-33.8524, 117.2253],
  [-33.8514, 117.2258],
  [-33.8501, 117.2260],
  [-33.8491, 117.2269],
  [-33.8484, 117.2283],
  [-33.8474, 117.2322],
  [-33.8467, 117.2333],
  [-33.8449, 117.2345],
  [-33.8436, 117.2362],
  [-33.8398, 117.2440],
  [-33.8323, 117.2556],
  [-33.8292, 117.2583],
  [-33.8282, 117.2593],
  [-33.8212, 117.2699],
  [-33.8209, 117.2714],
  [-33.8214, 117.2726],
  [-33.8238, 117.2747],
  [-33.8259, 117.2790],
  [-33.8281, 117.2876],
  [-33.8284, 117.2886],
  [-33.8299, 117.2910],
  [-33.8305, 117.2925],
  [-33.8304, 117.2945],
  [-33.8310, 117.2962],
  [-33.8323, 117.2970],
  [-33.8327, 117.2981],
  [-33.8327, 117.3016],
  [-33.8322, 117.3042],
  [-33.8311, 117.3058],
  [-33.8307, 117.3073],
  [-33.8317, 117.3102],
  [-33.8328, 117.3129],
  [-33.8329, 117.3187],
  [-33.8316, 117.3225],
  [-33.8258, 117.3297],
  [-33.8228, 117.3353],
  [-33.8223, 117.3375],
  [-33.8233, 117.3507],
  [-33.8218, 117.3563],
  [-33.8157, 117.3637],
  [-33.8136, 117.3652],
  [-33.8125, 117.3668],
  [-33.8102, 117.3720],
  [-33.8099, 117.3737],
  [-33.8101, 117.3779],
  [-33.8098, 117.3813],
  [-33.8098, 117.3820],
  [-33.8103, 117.3858],
  [-33.8103, 117.3871],
  [-33.8101, 117.3882],
  [-33.8101, 117.3891],
  [-33.8104, 117.3901],
  [-33.8103, 117.3916],
  [-33.8098, 117.3926],
  [-33.8097, 117.3935],
  [-33.8098, 117.3945],
  [-33.8116, 117.3980],
  [-33.8116, 117.3997],
  [-33.8107, 117.4007],
  [-33.8090, 117.4016],
  [-33.8083, 117.4026],
  [-33.8077, 117.4053],
  [-33.8078, 117.4060],
  [-33.8079, 117.4067],
  [-33.8079, 117.4077],
  [-33.8071, 117.4102],
  [-33.8063, 117.4111],
  [-33.8042, 117.4128],
  [-33.8037, 117.4134],
  [-33.8026, 117.4154],
  [-33.8024, 117.4163],
  [-33.8026, 117.4171],
  [-33.8050, 117.4218],
  [-33.8051, 117.4233],
  [-33.8031, 117.4293],
  [-33.8029, 117.4321],
  [-33.8031, 117.4333],
  [-33.8028, 117.4345],
  [-33.8014, 117.4358],
  [-33.8009, 117.4371],
  [-33.8011, 117.4382],
  [-33.8042, 117.4434],
  [-33.8048, 117.4465],
  [-33.8048, 117.4625],
  [-33.8042, 117.4654],
  [-33.8008, 117.4717],
  [-33.7966, 117.4762],
  [-33.7957, 117.4784],
  [-33.7954, 117.4805],
  [-33.7974, 117.4989],
  [-33.7970, 117.5007],
  [-33.7960, 117.5022],
  [-33.7899, 117.5072],
  [-33.7882, 117.5080],
  [-33.7854, 117.5110],
  [-33.7850, 117.5124],
  [-33.7853, 117.5136],
  [-33.7861, 117.5149],
  [-33.7862, 117.5163],
  [-33.7858, 117.5175],
  [-33.7849, 117.5187],
  [-33.7841, 117.5190],
  [-33.7829, 117.5193],
  [-33.7806, 117.5208],
  [-33.7793, 117.5227],
  [-33.7784, 117.5233],
  [-33.7746, 117.5228],
  [-33.7735, 117.5225],
  [-33.7720, 117.5227],
  [-33.7711, 117.5235],
  [-33.7707, 117.5246],
  [-33.7708, 117.5260],
  [-33.7701, 117.5274],
  [-33.7690, 117.5278],
  [-33.7677, 117.5275],
  [-33.7666, 117.5276],
  [-33.7658, 117.5272],
  [-33.7646, 117.5262],
  [-33.7626, 117.5254],
  [-33.7617, 117.5248],
  [-33.7555, 117.5203],
  [-33.7541, 117.5197],
  [-33.7515, 117.5190],
  [-33.7503, 117.5191],
  [-33.7465, 117.5212],
  [-33.7447, 117.5229],
  [-33.7441, 117.5242],
  [-33.7441, 117.5263],
  [-33.7436, 117.5275],
  [-33.7434, 117.5286],
  [-33.7436, 117.5309],
  [-33.7435, 117.5320],
  [-33.7432, 117.5329],
  [-33.7431, 117.5336],
  [-33.7436, 117.5368],
  [-33.7432, 117.5383],
  [-33.7385, 117.5427],
  [-33.7376, 117.5443],
  [-33.7366, 117.5498],
  [-33.7351, 117.5514],
  [-33.7229, 117.5549],
  [-33.7028, 117.5606]
];

export const donnybrookKatanning: Line = {
  name: 'Donnybrook - Katanning',
  state: 'WA',
  segments: [
    {
      segments: [donnybrookNoggerup],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [noggerupBoyupBrook],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boyupBrookKojonup],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kojonupKatanning],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    }
  ]
};
