import { Line } from "../../../trackTypes";

const line = [
  [-27.4593, 153.0152],
  [-27.4606, 153.0146],
  [-27.4622, 153.0146],
  [-27.4639, 153.0157],
  [-27.4652, 153.0181],
  [-27.4661, 153.0204],
  [-27.4681, 153.0235],
  [-27.4736, 153.0291],
  [-27.4744, 153.0306],
  [-27.4749, 153.0316],
  [-27.4759, 153.0330],
  [-27.4771, 153.0339],
  [-27.4788, 153.0345],
  [-27.4807, 153.0344],
  [-27.4872, 153.0332],
  [-27.4885, 153.0325],
  [-27.4902, 153.0310],
  [-27.4912, 153.0304],
  [-27.4926, 153.0303],
  [-27.4961, 153.0311],
  [-27.4972, 153.0311],
  [-27.4982, 153.0306],
  [-27.4997, 153.0290]
];

export const crossRiverRail: Line = {
  name: 'Cross River Rail',
  state: 'QLD',
  segments: [
    {
      segments: [{
        segment: line,
        type: 'tunnel'
      }],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
