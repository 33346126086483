import { Line } from "../../trackTypes";

const narroginWickepin = [
  [-32.9467, 117.1862],
  [-32.9498, 117.1908],
  [-32.9508, 117.1937],
  [-32.9509, 117.2140],
  [-32.9520, 117.2200],
  [-32.9521, 117.2291],
  [-32.9524, 117.2304],
  [-32.9532, 117.2323],
  [-32.9534, 117.2336],
  [-32.9526, 117.2377],
  [-32.9527, 117.2392],
  [-32.9532, 117.2408],
  [-32.9549, 117.2446],
  [-32.9551, 117.2464],
  [-32.9523, 117.2577],
  [-32.9521, 117.2588],
  [-32.9521, 117.2615],
  [-32.9524, 117.2632],
  [-32.9541, 117.2675],
  [-32.9543, 117.2688],
  [-32.9544, 117.2743],
  [-32.9541, 117.2756],
  [-32.9536, 117.2764],
  [-32.9486, 117.2810],
  [-32.9478, 117.2821],
  [-32.9475, 117.2835],
  [-32.9474, 117.2879],
  [-32.9492, 117.2992],
  [-32.9492, 117.3004],
  [-32.9483, 117.3065],
  [-32.9483, 117.3129],
  [-32.9476, 117.3154],
  [-32.9443, 117.3201],
  [-32.9339, 117.3304],
  [-32.9305, 117.3353],
  [-32.9282, 117.3372],
  [-32.9259, 117.3397],
  [-32.9241, 117.3427],
  [-32.9223, 117.3463],
  [-32.9204, 117.3488],
  [-32.9191, 117.3496],
  [-32.9174, 117.3501],
  [-32.9160, 117.3512],
  [-32.9153, 117.3527],
  [-32.9148, 117.3548],
  [-32.9130, 117.3567],
  [-32.9109, 117.3568],
  [-32.9061, 117.3533],
  [-32.9041, 117.3529],
  [-32.9024, 117.3536],
  [-32.9013, 117.3552],
  [-32.9009, 117.3571],
  [-32.9020, 117.3728],
  [-32.9012, 117.3783],
  [-32.8997, 117.3814],
  [-32.8986, 117.3852],
  [-32.8978, 117.3868],
  [-32.8811, 117.4058],
  [-32.8758, 117.4099],
  [-32.8747, 117.4113],
  [-32.8654, 117.4292],
  [-32.8627, 117.4325],
  [-32.8603, 117.4367],
  [-32.8588, 117.4383],
  [-32.8581, 117.4393],
  [-32.8542, 117.4491],
  [-32.8525, 117.4512],
  [-32.8515, 117.4531],
  [-32.8500, 117.4546],
  [-32.8482, 117.4550],
  [-32.8465, 117.4547],
  [-32.8447, 117.4552],
  [-32.8432, 117.4568],
  [-32.8427, 117.4588],
  [-32.8426, 117.4607],
  [-32.8423, 117.4620],
  [-32.8417, 117.4634],
  [-32.8412, 117.4660],
  [-32.8403, 117.4675],
  [-32.8394, 117.4682],
  [-32.8375, 117.4691],
  [-32.8363, 117.4702],
  [-32.8352, 117.4719],
  [-32.8341, 117.4728],
  [-32.8326, 117.4736],
  [-32.8316, 117.4746],
  [-32.8310, 117.4756],
  [-32.8306, 117.4769],
  [-32.8277, 117.4826],
  [-32.8268, 117.4836],
  [-32.8253, 117.4843],
  [-32.8243, 117.4853],
  [-32.8239, 117.4864],
  [-32.8237, 117.4881],
  [-32.8221, 117.4900],
  [-32.8206, 117.4902],
  [-32.8186, 117.4894],
  [-32.8169, 117.4892],
  [-32.8155, 117.4887],
  [-32.8143, 117.4888],
  [-32.8123, 117.4895],
  [-32.8109, 117.4895],
  [-32.8092, 117.4891],
  [-32.8079, 117.4892],
  [-32.8059, 117.4899],
  [-32.7978, 117.4894],
  [-32.7942, 117.4904],
  [-32.7864, 117.4904],
  [-32.7844, 117.4913],
  [-32.7833, 117.4926],
  [-32.7813, 117.4973],
  [-32.7807, 117.5007]
];

const wickepinCorrigin = [
  [-32.7807, 117.5007],
  [-32.7801, 117.5034],
  [-32.7791, 117.5054],
  [-32.7772, 117.5071],
  [-32.7466, 117.5375],
  [-32.7401, 117.5468],
  [-32.7377, 117.5479],
  [-32.7234, 117.5469],
  [-32.7220, 117.5472],
  [-32.7114, 117.5515],
  [-32.7099, 117.5517],
  [-32.6859, 117.5517],
  [-32.6626, 117.5595],
  [-32.6612, 117.5604],
  [-32.6465, 117.5748],
  [-32.6454, 117.5766],
  [-32.6385, 117.5940],
  [-32.6372, 117.5958],
  [-32.6342, 117.5978],
  [-32.6330, 117.5992],
  [-32.6321, 117.6010],
  [-32.6297, 117.6029],
  [-32.6283, 117.6033],
  [-32.6243, 117.6027],
  [-32.6227, 117.6029],
  [-32.6108, 117.6067],
  [-32.6087, 117.6080],
  [-32.5890, 117.6273],
  [-32.5801, 117.6312],
  [-32.5692, 117.6413],
  [-32.5687, 117.6428],
  [-32.5678, 117.6464],
  [-32.5668, 117.6485],
  [-32.5511, 117.6687],
  [-32.5500, 117.6711],
  [-32.5463, 117.6873],
  [-32.5452, 117.6895],
  [-32.5441, 117.6905],
  [-32.5431, 117.6916],
  [-32.5355, 117.7072],
  [-32.5341, 117.7090],
  [-32.4779, 117.7615],
  [-32.4445, 117.7851],
  [-32.4409, 117.7859],
  [-32.4395, 117.7867],
  [-32.4374, 117.7890],
  [-32.4359, 117.7899],
  [-32.4326, 117.7906],
  [-32.4312, 117.7915],
  [-32.4285, 117.7939],
  [-32.4233, 117.7962],
  [-32.4200, 117.7964],
  [-32.4109, 117.8011],
  [-32.4094, 117.8015],
  [-32.4064, 117.8012],
  [-32.3995, 117.8023],
  [-32.3980, 117.8032],
  [-32.3973, 117.8044],
  [-32.3971, 117.8062],
  [-32.3965, 117.8076],
  [-32.3949, 117.8093],
  [-32.3851, 117.8264],
  [-32.3835, 117.8280],
  [-32.3820, 117.8288],
  [-32.3807, 117.8305],
  [-32.3803, 117.8320],
  [-32.3802, 117.8336],
  [-32.3793, 117.8355],
  [-32.3766, 117.8379],
  [-32.3752, 117.8383],
  [-32.3730, 117.8383],
  [-32.3711, 117.8391],
  [-32.3693, 117.8405],
  [-32.3677, 117.8409],
  [-32.3664, 117.8406],
  [-32.3645, 117.8395],
  [-32.3632, 117.8393],
  [-32.3619, 117.8397],
  [-32.3571, 117.8426],
  [-32.3547, 117.8451],
  [-32.3541, 117.8464],
  [-32.3536, 117.8481],
  [-32.3527, 117.8497],
  [-32.3499, 117.8529],
  [-32.3492, 117.8542],
  [-32.3490, 117.8556],
  [-32.3489, 117.8576],
  [-32.3477, 117.8611],
  [-32.3447, 117.8646],
  [-32.3431, 117.8654],
  [-32.3399, 117.8655],
  [-32.3387, 117.8664],
  [-32.3378, 117.8674],
  [-32.3357, 117.8711],
  [-32.3343, 117.8721],
  [-32.3331, 117.8724],
  [-32.3267, 117.8723]
];

const corriginBruceRock = [
  [-32.3267, 117.8723],
  [-32.3246, 117.8725],
  [-32.3225, 117.8729],
  [-32.3160, 117.8759],
  [-32.3056, 117.8838],
  [-32.3041, 117.8852],
  [-32.3024, 117.8858],
  [-32.3008, 117.8855],
  [-32.2993, 117.8845],
  [-32.2977, 117.8843],
  [-32.2964, 117.8850],
  [-32.2909, 117.8929],
  [-32.2893, 117.8971],
  [-32.2882, 117.8985],
  [-32.2866, 117.8992],
  [-32.2690, 117.8992],
  [-32.2677, 117.8997],
  [-32.2659, 117.9005],
  [-32.2641, 117.9005],
  [-32.2620, 117.8995],
  [-32.2607, 117.8992],
  [-32.2557, 117.8993],
  [-32.2535, 117.9002],
  [-32.2525, 117.9015],
  [-32.2465, 117.9139],
  [-32.2452, 117.9156],
  [-32.2331, 117.9269],
  [-32.2276, 117.9346],
  [-32.1653, 117.9888],
  [-32.1133, 118.0344],
  [-32.1116, 118.0354],
  [-32.1094, 118.0373],
  [-32.1040, 118.0397],
  [-32.1030, 118.0406],
  [-32.0988, 118.0467],
  [-32.0980, 118.0474],
  [-32.0967, 118.0480],
  [-32.0957, 118.0491],
  [-32.0950, 118.0505],
  [-32.0939, 118.0514],
  [-32.0925, 118.0516],
  [-32.0908, 118.0511],
  [-32.0895, 118.0514],
  [-32.0885, 118.0524],
  [-32.0865, 118.0553],
  [-32.0853, 118.0563],
  [-32.0793, 118.0598],
  [-32.0780, 118.0610],
  [-32.0683, 118.0768],
  [-32.0672, 118.0780],
  [-32.0445, 118.0911],
  [-32.0241, 118.0986],
  [-32.0229, 118.0996],
  [-32.0223, 118.1008],
  [-32.0220, 118.1038],
  [-32.0212, 118.1054],
  [-32.0198, 118.1062],
  [-32.0121, 118.1062],
  [-32.0107, 118.1053],
  [-32.0096, 118.1035],
  [-32.0085, 118.1027],
  [-32.0018, 118.1009],
  [-32.0004, 118.1010],
  [-31.9712, 118.1144],
  [-31.9638, 118.1211],
  [-31.9622, 118.1217],
  [-31.9272, 118.1218],
  [-31.9253, 118.1224],
  [-31.8980, 118.1473],
  [-31.8966, 118.1479],
  [-31.8874, 118.1497]
];

const bruceRockMerredin = [
  [-31.8874, 118.1497],
  [-31.8719, 118.1499],
  [-31.8701, 118.1503],
  [-31.8402, 118.1652],
  [-31.8384, 118.1654],
  [-31.8328, 118.1639],
  [-31.8312, 118.1640],
  [-31.8215, 118.1673],
  [-31.8205, 118.1682],
  [-31.8193, 118.1703],
  [-31.8176, 118.1720],
  [-31.8146, 118.1730],
  [-31.8134, 118.1737],
  [-31.8102, 118.1765],
  [-31.7855, 118.1865],
  [-31.7838, 118.1866],
  [-31.7250, 118.1705],
  [-31.7050, 118.1651],
  [-31.6418, 118.1478],
  [-31.6401, 118.1479],
  [-31.6389, 118.1483],
  [-31.6192, 118.1652],
  [-31.6111, 118.1696],
  [-31.6009, 118.1800],
  [-31.5899, 118.1939],
  [-31.5864, 118.1964],
  [-31.5848, 118.1970],
  [-31.5807, 118.1971],
  [-31.5750, 118.1957],
  [-31.5734, 118.1957],
  [-31.5690, 118.1969],
  [-31.5645, 118.2002],
  [-31.5634, 118.2015],
  [-31.5611, 118.2060],
  [-31.5521, 118.2162],
  [-31.5507, 118.2173],
  [-31.5256, 118.2269],
  [-31.4967, 118.2486],
  [-31.4945, 118.2491],
  [-31.4930, 118.2489],
  [-31.4902, 118.2472],
  [-31.4894, 118.2471],
  [-31.4885, 118.2476]
];

export const narroginMerredin: Line = {
  name: 'Narrogin - Merredin',
  state: 'WA',
  segments: [
    {
      segments: [narroginWickepin],
      history: {
        opened: {
          date: '1909-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wickepinCorrigin],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [corriginBruceRock],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bruceRockMerredin],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-01',
          status: 'closed'
        }]
      }
    }
  ]
};
