import { Line } from "../../../../trackTypes";

const tuamStreet = [
  [-43.53520, 172.63663],
  [-43.53528, 172.63656],
  [-43.53531, 172.63645],
  [-43.53534, 172.62595],
  [-43.53535, 172.62572],
  [-43.53540, 172.62553]
];

const oxfordTerrace = [
  [-43.53489, 172.62641],
  [-43.53540, 172.62553]
];

const addingtonStation = [
  [-43.53540, 172.62553],
  [-43.54078, 172.61655]
];

const addingtonShowgrounds = [
  [-43.54078, 172.61655],
  [-43.54677, 172.60640]
];

const sunnysideAsylum = [
  [-43.54677, 172.60640],
  [-43.55467, 172.59306]
];

export const line2: Line = {
  name: 'Line 2',
  state: 'NZ',
  segments: [
    {
      segments: [
        {
          date: '1906-02-08',
          original: [tuamStreet],
          deviation: [oxfordTerrace]
        },
        addingtonStation

      ],
      history: {
        opened: {
          date: '1882-01-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-02-08',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-07-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [addingtonShowgrounds],
      history: {
        opened: {
          date: '1887-10-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-02-08',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-07-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sunnysideAsylum],
      history: {
        opened: {
          date: '1896-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-02-08',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1953-07-26',
          status: 'closed'
        }]
      }
    }
  ]
}
