import { Line } from "../../../trackTypes";

const line = [
  [-42.3949, 171.4371],
  [-42.3943, 171.4371],
  [-42.3938, 171.4368],
  [-42.3889, 171.4322],
  [-42.3878, 171.4304],
  [-42.3815, 171.4166],
  [-42.3783, 171.4129],
  [-42.3776, 171.4114],
  [-42.3770, 171.4108],
  [-42.3758, 171.4102],
  [-42.3727, 171.4079],
  [-42.3692, 171.4068],
  [-42.3675, 171.4070],
  [-42.3624, 171.4079],
  [-42.3617, 171.4078],
  [-42.3614, 171.4074],
  [-42.3607, 171.4053],
  [-42.3585, 171.4028],
  [-42.3563, 171.4016],
  [-42.3557, 171.4007],
  [-42.3556, 171.3995],
  [-42.3560, 171.3972],
  [-42.3558, 171.3953],
  [-42.3543, 171.3936],
  [-42.3538, 171.3925],
  [-42.3535, 171.3911],
  [-42.3537, 171.3883],
  [-42.3533, 171.3848],
  [-42.3536, 171.3822],
  [-42.3534, 171.3808],
  [-42.3529, 171.3788],
  [-42.3524, 171.3779],
  [-42.3509, 171.3771]
];

export const blackball: Line = {
  name: 'Blackball',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1910-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1966-02-21',
          status: 'closed'
        }]
      }
    }
  ]
}
