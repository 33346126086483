import { Line } from "../../trackTypes";

const line = [
  [-37.2276, 144.4090],
  [-37.2265, 144.4087],
  [-37.2257, 144.4087],
  [-37.2198, 144.4099],
  [-37.2180, 144.4107],
  [-37.2105, 144.4162],
  [-37.2081, 144.4172],
  [-37.1991, 144.4189],
  [-37.1938, 144.4201],
  [-37.1926, 144.4208],
  [-37.1800, 144.4317],
  [-37.1785, 144.4338],
  [-37.1675, 144.4520],
  [-37.1661, 144.4538],
  [-37.1646, 144.4547],
  [-37.1584, 144.4577],
  [-37.1568, 144.4592],
  [-37.1553, 144.4610],
  [-37.1537, 144.4617],
  [-37.1525, 144.4618],
  [-37.1507, 144.4618],
  [-37.1490, 144.4618],
  [-37.1439, 144.4630],
  [-37.1420, 144.4638],
  [-37.1372, 144.4666],
  [-37.1358, 144.4678],
  [-37.1347, 144.4691],
  [-37.1329, 144.4704],
  [-37.1316, 144.4720],
  [-37.1301, 144.4731],
  [-37.1284, 144.4739],
  [-37.1239, 144.4751],
  [-37.1227, 144.4759],
  [-37.1206, 144.4775],
  [-37.1159, 144.4797],
  [-37.1150, 144.4803],
  [-37.1084, 144.4851],
  [-37.1056, 144.4863],
  [-37.1035, 144.4880],
  [-37.0972, 144.4921],
  [-37.0915, 144.4958],
  [-37.0895, 144.4975],
  [-37.0883, 144.4991],
  [-37.0855, 144.5036],
  [-37.0842, 144.5050],
  [-37.0827, 144.5062],
  [-37.0813, 144.5068],
  [-37.0650, 144.5124],
  [-37.0636, 144.5129],
  [-37.0630, 144.5138],
  [-37.0524, 144.5253],
  [-37.0507, 144.5262],
  [-37.0318, 144.5296],
  [-37.0308, 144.5296],
  [-37.0290, 144.5294],
  [-37.0279, 144.5296],
  [-37.0265, 144.5302],
  [-37.0246, 144.5300],
  [-37.0232, 144.5295]
];

export const redesdale: Line = {
  name: 'Redesdale',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1891-01-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-09-26',
          status: 'closed'
        }]
      }
    }
  ]
}
