import { Line } from "../../../trackTypes";

const line = [
  [-27.2700, 152.9806],
  [-27.2675, 152.9833],
  [-27.2663, 152.9837],
  [-27.2644, 152.9837],
  [-27.2629, 152.9839],
  [-27.2615, 152.9846],
  [-27.2601, 152.9856],
  [-27.2591, 152.9869],
  [-27.2584, 152.9886],
  [-27.2570, 152.9982],
  [-27.2561, 153.0017],
  [-27.2532, 153.0085],
  [-27.2493, 153.0226],
  [-27.2475, 153.0262],
  [-27.2448, 153.0290],
  [-27.2351, 153.0346],
  [-27.2316, 153.0374],
  [-27.2260, 153.0445],
  [-27.2226, 153.0508],
  [-27.2219, 153.0528],
  [-27.2213, 153.0578],
  [-27.2214, 153.0617],
  [-27.2224, 153.0664],
  [-27.2253, 153.0728],
  [-27.2263, 153.0759],
  [-27.2264, 153.0777],
  [-27.2252, 153.0825]
];

export const redcliffePeninsula: Line = {
  name: 'Redcliffe Peninsula',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2016-10-04',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
