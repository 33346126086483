import { Line } from "../../../trackTypes";

const castlecliff = [
  [-39.91465, 175.05026],
  [-39.91483, 175.05018],
  [-39.91519, 175.05005],
  [-39.91716, 175.04947],
  [-39.91737, 175.04944],
  [-39.91755, 175.04944],
  [-39.91860, 175.04959],
  [-39.91904, 175.04971],
  [-39.91928, 175.04981],
  [-39.92139, 175.05074],
  [-39.92258, 175.05125],
  [-39.92293, 175.05138],
  [-39.92438, 175.05178],
  [-39.92466, 175.05191],
  [-39.92494, 175.05205],
  [-39.92519, 175.05223],
  [-39.92555, 175.05250],
  [-39.92727, 175.05381],
  [-39.92736, 175.05382],
  [-39.92744, 175.05380],
  [-39.92753, 175.05374],
  [-39.93146, 175.04984],
  [-39.93521, 175.04607],
  [-39.93532, 175.04597],
  [-39.93546, 175.04591],
  [-39.93562, 175.04585],
  [-39.93855, 175.04567],
  [-39.93884, 175.04569],
  [-39.93959, 175.04584],
  [-39.93970, 175.04582],
  [-39.93990, 175.04572],
  [-39.93999, 175.04562],
  [-39.94006, 175.04547],
  [-39.94014, 175.04485],
  [-39.94021, 175.04459],
  [-39.94073, 175.04321],
  [-39.94081, 175.04303],
  [-39.94092, 175.04288],
  [-39.94257, 175.04082],
  [-39.94273, 175.04063],
  [-39.94288, 175.04052],
  [-39.94421, 175.03983],
  [-39.94438, 175.03973],
  [-39.94457, 175.03958],
  [-39.94474, 175.03943],
  [-39.94497, 175.03918],
  [-39.94516, 175.03891],
  [-39.94531, 175.03867],
  [-39.94615, 175.03733],
  [-39.94860, 175.03333],
  [-39.94877, 175.03298],
  [-39.94890, 175.03261],
  [-39.94898, 175.03221],
  [-39.94900, 175.03188],
  [-39.94898, 175.03151],
  [-39.94806, 175.02202],
  [-39.94806, 175.02176],
  [-39.94808, 175.02160],
  [-39.94814, 175.02140],
  [-39.94826, 175.02107],
  [-39.94835, 175.02086],
  [-39.94841, 175.02058],
  [-39.94882, 175.01883],
  [-39.94956, 175.01550],
  [-39.94958, 175.01534],
  [-39.94956, 175.01518],
  [-39.94950, 175.01500],
  [-39.94796, 175.01103],
  [-39.94790, 175.01081],
  [-39.94784, 175.01053],
  [-39.94761, 175.00867],
  [-39.94754, 175.00829],
  [-39.94660, 175.00418],
  [-39.94653, 175.00397],
  [-39.94644, 175.00382],
  [-39.94582, 175.00302],
  [-39.94573, 175.00287],
  [-39.94566, 175.00266],
  [-39.94542, 175.00160],
  [-39.94532, 175.00131],
  [-39.94410, 174.99909],
  [-39.94397, 174.99875],
  [-39.94332, 174.99623],
  [-39.94328, 174.99600],
  [-39.94320, 174.99453],
  [-39.94316, 174.99416],
  [-39.94301, 174.99316],
  [-39.94299, 174.99286],
  [-39.94330, 174.98837],
  [-39.94329, 174.98822],
  [-39.94325, 174.98810],
  [-39.94317, 174.98796],
  [-39.93634, 174.98190],
  [-39.93631, 174.98181],
  [-39.93632, 174.98170],
  [-39.93840, 174.97776]
];

const eastTown = [
  [-39.92798, 175.04371],
  [-39.92788, 175.04363],
  [-39.92780, 175.04364],
  [-39.92112, 175.05026],
  [-39.92098, 175.05045],
  [-39.92092, 175.05065],
  [-39.92019, 175.05423],
  [-39.91985, 175.05578],
  [-39.91975, 175.05609],
  [-39.91821, 175.05955],
  [-39.91815, 175.05965],
  [-39.91808, 175.05972],
  [-39.91799, 175.05976],
  [-39.91790, 175.05977],
  [-39.91759, 175.05976],
  [-39.91748, 175.05979],
  [-39.91741, 175.05985],
  [-39.91736, 175.05995],
  [-39.91733, 175.06007],
  [-39.91728, 175.06114],
  [-39.91722, 175.06317],
  [-39.91723, 175.06331],
  [-39.91726, 175.06345],
  [-39.91774, 175.06494],
  [-39.91777, 175.06506],
  [-39.91778, 175.06520],
  [-39.91742, 175.07453],
  [-39.91738, 175.07694]
];

const aramoho = [
  [-39.94321, 175.04493],
  [-39.94087, 175.04927],
  [-39.94071, 175.04954],
  [-39.94057, 175.04970],
  [-39.93500, 175.05524],
  [-39.93491, 175.05527],
  [-39.93479, 175.05522],
  [-39.92798, 175.04371],
  [-39.92553, 175.03961],
  [-39.92546, 175.03956],
  [-39.92540, 175.03955],
  [-39.92533, 175.03957],
  [-39.91465, 175.05026],
  [-39.91428, 175.05058],
  [-39.91408, 175.05074],
  [-39.91317, 175.05139],
  [-39.91295, 175.05160],
  [-39.91251, 175.05203],
  [-39.91147, 175.05305],
  [-39.91128, 175.05322],
  [-39.91026, 175.05398],
  [-39.90824, 175.05551]
];

const railwayBridgeQuickAvenue = [
  [-39.90824, 175.05551],
  [-39.90805, 175.05570],
  [-39.90762, 175.05619],
  [-39.90646, 175.05754],
  [-39.90595, 175.05822],
  [-39.90457, 175.06033],
  [-39.90430, 175.06075],
  [-39.90393, 175.06142],
  [-39.90191, 175.06523],
  [-39.90147, 175.06596],
  [-39.90096, 175.06681],
  [-39.90068, 175.06726],
  [-39.90043, 175.06762],
  [-39.89968, 175.06867],
  [-39.89878, 175.06978],
  [-39.89842, 175.07025],
  [-39.89794, 175.07094],
  [-39.89721, 175.07214],
  [-39.89521, 175.07573]
];

const quickAvenueAramohoCemetery = [
  [-39.89521, 175.07573],
  [-39.89497, 175.07615],
  [-39.89475, 175.07653],
  [-39.89400, 175.07831],
  [-39.89393, 175.07851],
  [-39.89388, 175.07869],
  [-39.89351, 175.08126],
  [-39.89350, 175.08144],
  [-39.89350, 175.08163],
  [-39.89373, 175.08407],
  [-39.89406, 175.08673],
  [-39.89445, 175.08942],
  [-39.89481, 175.09130]
];

export const whanganuiTrams: Line = {
  name: 'Whanganui Trams',
  state: 'NZ',
  segments: [
    {
      segments: [eastTown],
      history: {
        opened: {
          date: '1914-11-03',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-09-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [castlecliff],
      history: {
        opened: {
          date: '1912-10-17',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-09-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [aramoho],
      history: {
        opened: {
          date: '1908-12-11',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-07-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [railwayBridgeQuickAvenue],
      history: {
        opened: {
          date: '1915-04-28',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-07-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [quickAvenueAramohoCemetery],
      history: {
        opened: {
          date: '1923-09-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1950-07-22',
          status: 'closed'
        }]
      }
    }
  ]
}
