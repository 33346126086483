import { Line } from "../../trackTypes";

const line = [
  [-37.0539, 142.75358],
  [-37.0525, 142.7512],
  [-37.0522, 142.7497],
  [-37.0523, 142.7469],
  [-37.0530, 142.7287],
  [-37.0540, 142.7205],
  [-37.0590, 142.6873],
  [-37.0642, 142.6510],
  [-37.0642, 142.6007],
  [-37.0649, 142.5963],
  [-37.0683, 142.5800],
  [-37.0704, 142.5707],
  [-37.0704, 142.5684],
  [-37.0700, 142.5661],
  [-37.0664, 142.5608],
  [-37.0606, 142.5516],
  [-37.0577, 142.5473],
  [-37.0569, 142.5442],
  [-37.0554, 142.5347],
  [-37.0546, 142.5318],
  [-37.0490, 142.5182],
  [-37.0460, 142.5097],
  [-37.0453, 142.5087],
  [-37.0403, 142.5062],
  [-37.0368, 142.5027],
  [-37.0339, 142.4988],
  [-37.0329, 142.4978],
  [-37.0280, 142.4966],
  [-37.0231, 142.4949]
];

export const grampians: Line = {
  name: 'Grampians',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1882-02-23',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1942-07-23',
          status: 'closed'
        }]
      }
    }
  ]
}
