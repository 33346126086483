import { Line } from "../../trackTypes";

/**
 * 1897
 *  - east to perrins barn
 *  - west to foot of perwillowen range
 *
 * 1911
 *  - branch to maroochy river + eastern extension
 *  - extended to mapleton
 *
 * 1970
 *  - reduced back to foot of range
 *
 * 1922
 *  - isolated sysem between coolum creek and coolum beach
 *
 * 1923
 *  - north section across maroochy river, connected to isolted line
 *
 * 1923-11-22
 *  - line to coolum beach from namboor
 *  - c1935???
 *
 * all closed 2003-12-03
 */

const namboorPerwillowenRange = [
  [-26.6264, 152.9575],
  [-26.6271, 152.9565],
  [-26.6284, 152.9561],
  [-26.6299, 152.9566],
  [-26.6302, 152.9567],
  [-26.6305, 152.9564],
  [-26.6306, 152.9560],
  [-26.6306, 152.9554],
  [-26.6311, 152.9552],
  [-26.6314, 152.9549],
  [-26.6318, 152.9543],
  [-26.6323, 152.9543],
  [-26.6324, 152.9541],
  [-26.6324, 152.9535],
  [-26.6320, 152.9528],
  [-26.6319, 152.9523],
  [-26.6332, 152.9437], //POINT
  [-26.6331, 152.9420],
  [-26.6328, 152.9413],
  [-26.6318, 152.9399],
  [-26.6316, 152.9392],
  [-26.6313, 152.9387],
  [-26.6309, 152.9385],
  [-26.6305, 152.9385],
  [-26.6300, 152.9381],
  [-26.6287, 152.9364],
  [-26.6281, 152.9360],
  [-26.6278, 152.9354],
  [-26.6271, 152.9348],
  [-26.6268, 152.9339],
  [-26.6265, 152.9334],
  [-26.6255, 152.9329],
  [-26.6249, 152.9328],
  [-26.6245, 152.9329],
  [-26.6226, 152.9318],
  [-26.6220, 152.9315],
  [-26.6217, 152.9310],
  [-26.6216, 152.9302],
  [-26.6213, 152.9298],
  [-26.6209, 152.9295],
  [-26.6203, 152.9292], //POINT
  [-26.6196, 152.9287],
  [-26.6190, 152.9277],
  [-26.6185, 152.9265],
  [-26.6185, 152.9255],
  [-26.6188, 152.9247],
  [-26.6196, 152.9238],
  [-26.6200, 152.9230],
  [-26.6220, 152.9194]
];

const perwillowenRangeMapleton = [
  [-26.6220, 152.9194],
  [-26.6220, 152.9187],
  [-26.6217, 152.9177],
  [-26.6222, 152.9171],
  [-26.6225, 152.9168],
  [-26.6225, 152.9166],
  [-26.6223, 152.9166],
  [-26.6222, 152.9168],
  [-26.6220, 152.9169],
  [-26.6215, 152.9162],
  [-26.6212, 152.9161],
  [-26.6212, 152.9164],
  [-26.6212, 152.9166],
  [-26.6210, 152.9169],
  [-26.6209, 152.9169],
  [-26.6205, 152.9168],
  [-26.6203, 152.9168],
  [-26.6202, 152.9171],
  [-26.6201, 152.9173],
  [-26.6194, 152.9177],
  [-26.6191, 152.9176],
  [-26.6189, 152.9174],
  [-26.6183, 152.9168],
  [-26.6181, 152.9163],
  [-26.6180, 152.9160],
  [-26.6182, 152.9158],
  [-26.6188, 152.9163],
  [-26.6191, 152.9162],
  [-26.6191, 152.9161],
  [-26.6190, 152.9159],
  [-26.6190, 152.9156],
  [-26.6189, 152.9152],
  [-26.6189, 152.9149],
  [-26.6192, 152.9147],
  [-26.6194, 152.9143],
  [-26.6198, 152.9141],
  [-26.6198, 152.9138],
  [-26.6197, 152.9137],
  [-26.6192, 152.9133],
  [-26.6189, 152.9132],
  [-26.6181, 152.9135],
  [-26.6179, 152.9133],
  [-26.6178, 152.9131],
  [-26.6177, 152.9130],
  [-26.6174, 152.9130],
  [-26.6170, 152.9127],
  [-26.6166, 152.9126],
  [-26.6164, 152.9123],
  [-26.6163, 152.9120],
  [-26.6162, 152.9118],
  [-26.6146, 152.9116],
  [-26.6137, 152.9112],
  [-26.6131, 152.9113],
  [-26.6129, 152.9111],
  [-26.6120, 152.9100],
  [-26.6105, 152.9072],
  [-26.6103, 152.9066], //POINT
  [-26.6105, 152.9062],
  [-26.6117, 152.9061],
  [-26.6120, 152.9060],
  [-26.6122, 152.9058],
  [-26.6127, 152.9061],
  [-26.6134, 152.9062],
  [-26.6137, 152.9060],
  [-26.6139, 152.9055],
  [-26.6142, 152.9052],
  [-26.6147, 152.9051],
  [-26.6150, 152.9047],
  [-26.6151, 152.9042],
  [-26.6150, 152.9037],
  [-26.6142, 152.9029],
  [-26.6143, 152.9026],
  [-26.6148, 152.9023],
  [-26.6156, 152.9013],
  [-26.6166, 152.9009],
  [-26.6170, 152.9007],
  [-26.6172, 152.9004], //POINT
  [-26.6171, 152.8998],
  [-26.6170, 152.8989],
  [-26.6167, 152.8985],
  [-26.6161, 152.8984],
  [-26.6157, 152.8979],
  [-26.6157, 152.8973],
  [-26.6153, 152.8970],
  [-26.6139, 152.8968],
  [-26.6134, 152.8966],
  [-26.6132, 152.8964],
  [-26.6127, 152.8963],
  [-26.6118, 152.8965],
  [-26.6114, 152.8963],
  [-26.6114, 152.8959],
  [-26.6119, 152.8953],
  [-26.6133, 152.8946],
  [-26.6141, 152.8936],
  [-26.6151, 152.8932],
  [-26.6152, 152.8929],
  [-26.6151, 152.8918],
  [-26.6151, 152.8914],
  [-26.6159, 152.8902],
  [-26.6163, 152.8889],
  [-26.6181, 152.8852],
  [-26.6189, 152.8830],
  [-26.6189, 152.8822],
  [-26.6191, 152.8816],
  [-26.6212, 152.8818],
  [-26.6216, 152.8821],
  [-26.6221, 152.8819],
  [-26.6226, 152.8816],
  [-26.6229, 152.8815],
  [-26.6234, 152.8817],
  [-26.6241, 152.8815],
  [-26.6251, 152.8795],
  [-26.6257, 152.8787],
  [-26.6264, 152.8784],
  [-26.6273, 152.8796],
  [-26.6280, 152.8801],
  [-26.6281, 152.8801],
  [-26.6285, 152.8797],
  [-26.6288, 152.8775],
  [-26.6290, 152.8770],
  [-26.6292, 152.8759],
  [-26.6294, 152.8751],
  [-26.6293, 152.8744],
  [-26.6290, 152.8742],
  [-26.6289, 152.8739],
  [-26.6288, 152.8736],
  [-26.6287, 152.8734],
  [-26.6283, 152.8732],
  [-26.6282, 152.8731],
  [-26.6282, 152.8727],
  [-26.6285, 152.8717],
  [-26.6285, 152.8713],
  [-26.6280, 152.8711],
  [-26.6260, 152.8695],
  [-26.6255, 152.8696],
  [-26.6248, 152.8697],
  [-26.6244, 152.8695],
  [-26.6238, 152.8680],
  [-26.6233, 152.8676],
  [-26.6227, 152.8676],
  [-26.6221, 152.8678],
  [-26.6215, 152.8674],
  [-26.6207, 152.8676],
  [-26.6201, 152.8675],
  [-26.6189, 152.8666],
  [-26.6172, 152.8658],
  [-26.6169, 152.8653],
  [-26.6171, 152.8647],
  [-26.6175, 152.8641],
  [-26.6180, 152.8638],
  [-26.6187, 152.8638],
  [-26.6191, 152.8633],
  [-26.6196, 152.8623],
  [-26.6201, 152.8620],
  [-26.6209, 152.8620],
  [-26.6217, 152.8622],
  [-26.6229, 152.8638],
  [-26.6235, 152.8641],
  [-26.6251, 152.8643],
  [-26.6257, 152.8640],
  [-26.6264, 152.8631]
];

export const mapleton: Line = {
  name: 'Mapleton Tramway',
  state: 'QLD',
  segments: [
    {
      segments: [namboorPerwillowenRange],
      history: {
        opened: {
          date: '1897-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '2003-12-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [perwillowenRangeMapleton],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1970-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
