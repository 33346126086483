import { Line } from "../../trackTypes";

const maldonJunctionMuckleford = [
  [-37.0731, 144.2083],
  [-37.0734, 144.2074],
  [-37.0734, 144.2061],
  [-37.0731, 144.2048],
  [-37.0714, 144.2010],
  [-37.0710, 144.1999],
  [-37.0699, 144.1970],
  [-37.0693, 144.1961],
  [-37.0686, 144.1954],
  [-37.0679, 144.1948],
  [-37.0583, 144.1912],
  [-37.0569, 144.1903],
  [-37.0430, 144.1785],
  [-37.0419, 144.1779],
  [-37.0411, 144.1777],
  [-37.0391, 144.1775],
  [-37.0382, 144.1773],
  [-37.0375, 144.1770],
  [-37.0369, 144.1765],
  [-37.0332, 144.1741],
  [-37.0320, 144.1731],
  [-37.0309, 144.1718],
  [-37.0302, 144.1702],
  [-37.0263, 144.1605],
  [-37.0247, 144.1541],
  [-37.0243, 144.1532],
  [-37.0240, 144.1525],
  [-37.0223, 144.1501]
];

const maldonMaldonHault = [
  [-36.9923, 144.0730],
  [-36.9928, 144.0777],
  [-36.9925, 144.0790],
  [-36.9908, 144.0832],
  [-36.9905, 144.0844],
  [-36.9906, 144.0860],
  [-36.9929, 144.0938],
  [-36.9957, 144.1030],
  [-36.9964, 144.1048],
  [-36.9983, 144.1070],
  [-37.0004, 144.1097],
  [-37.0019, 144.1112],
  [-37.0041, 144.1124],
  [-37.0053, 144.1141],
  [-37.0059, 144.1163],
  [-37.0064, 144.1202],
  [-37.0076, 144.1237],
  [-37.0136, 144.1327],
  [-37.0141, 144.1339],
  [-37.0156, 144.1382]
];

const maldonHaultMuckleford = [
  [-37.0156, 144.1382],
  [-37.0172, 144.1426],
  [-37.0184, 144.1448],
  [-37.0223, 144.1501]
];

const maldonShelborne = [
  [-36.9908, 144.0832],
  [-36.9896, 144.0844],
  [-36.9884, 144.0857],
  [-36.9879, 144.0867],
  [-36.9873, 144.0894],
  [-36.9861, 144.0909],
  [-36.9833, 144.0937],
  [-36.9797, 144.0961],
  [-36.9769, 144.0968],
  [-36.9747, 144.0975],
  [-36.9722, 144.0992],
  [-36.9692, 144.1010],
  [-36.9657, 144.1013],
  [-36.9647, 144.1008],
  [-36.9621, 144.0989],
  [-36.9593, 144.0967],
  [-36.9576, 144.0949],
  [-36.9561, 144.0925],
  [-36.9543, 144.0880],
  [-36.9532, 144.0858],
  [-36.9518, 144.0844],
  [-36.9499, 144.0835],
  [-36.9452, 144.0820],
  [-36.9428, 144.0807],
  [-36.9411, 144.0790],
  [-36.9391, 144.0765],
  [-36.9376, 144.0750],
  [-36.9297, 144.0688],
  [-36.9269, 144.0661],
  [-36.9167, 144.0525],
  [-36.9150, 144.0494],
  [-36.9126, 144.0440],
  [-36.9104, 144.0399],
  [-36.9082, 144.0374],
  [-36.9057, 144.0356],
  [-36.9002, 144.0331],
  [-36.8991, 144.0324],
  [-36.8981, 144.0314],
  [-36.8891, 144.0215],
  [-36.8873, 144.0200],
  [-36.8854, 144.0187],
  [-36.8802, 144.0164],
  [-36.8787, 144.0155],
  [-36.8778, 144.0143],
  [-36.8705, 144.0059],
  [-36.8690, 144.0047],
  [-36.8640, 144.0006],
  [-36.8580, 143.9950],
  [-36.8551, 143.9933]
];

export const maldon: Line = {
  name: 'Shelbourne',
  state: 'VIC',
  segments: [
    {
      segments: [maldonJunctionMuckleford],
      history: {
        opened: {
          date: '1884-06-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-12-03',
          status: 'closed'
        }, {
          date: '2004-12-14',
          status: 'open'
        }]
      }
    },
    {
      segments: [maldonHaultMuckleford],
      history: {
        opened: {
          date: '1884-06-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-12-03',
          status: 'closed'
        }, {
          date: '1996-04-07',
          status: 'open'
        }]
      }
    },
    {
      segments: [maldonMaldonHault],
      history: {
        opened: {
          date: '1884-06-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-12-03',
          status: 'closed'
        }, {
          date: '1986-03-31',
          status: 'open'
        }]
      }
    },
    {
      segments: [maldonShelborne],
      history: {
        opened: {
          date: '1891-03-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
