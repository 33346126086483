import { Line } from "../../trackTypes";

const woodvilleGrange = [
  [-34.8763, 138.5369],
  [-34.8759, 138.5359],
  [-34.8758, 138.5351],
  [-34.8760, 138.5320],
  [-34.8766, 138.5295],
  [-34.8780, 138.5268],
  [-34.8792, 138.5254],
  [-34.8827, 138.5221],
  [-34.8978, 138.5081],
  [-34.8984, 138.5072],
  [-34.8990, 138.5052],
  [-34.8996, 138.4923],
  [-34.8998, 138.4915],
  [-34.9003, 138.4906]
];

const grangeHenleyBeach = [
  [-34.9003, 138.4906],
  [-34.9009, 138.4904],
  [-34.9028, 138.4908],
  [-34.9080, 138.4921],
  [-34.9099, 138.4924],
  [-34.9118, 138.4932],
  [-34.9128, 138.4940],
  [-34.9131, 138.4941],
  [-34.9133, 138.4941],
  [-34.9144, 138.4940],
  [-34.9150, 138.4941],
  [-34.9162, 138.4946],
  [-34.9168, 138.4947],
  [-34.9195, 138.4951]
];

export const grange: Line = {
  name: 'Grange',
  state: 'SA',
  segments: [
    {
      segments: [woodvilleGrange],
      history: {
        opened: {
          date: '1882-09-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [grangeHenleyBeach],
      history: {
        opened: {
          date: '1894-02-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-08-31',
          status: 'closed'
        }]
      }
    }
  ]
};
