import { Line } from "../../trackTypes";

const rivertonClare = [
  [-34.1527, 138.7516],
  [-34.1522, 138.7516],
  [-34.1506, 138.7507],
  [-34.1492, 138.7490],
  [-34.1489, 138.7476],
  [-34.1486, 138.7454],
  [-34.1504, 138.7373],
  [-34.1505, 138.7344],
  [-34.1501, 138.7307],
  [-34.1501, 138.7285],
  [-34.1528, 138.7154],
  [-34.1528, 138.7139],
  [-34.1525, 138.7125],
  [-34.1520, 138.7115],
  [-34.1418, 138.6978],
  [-34.1408, 138.6967],
  [-34.1346, 138.6928],
  [-34.1332, 138.6924],
  [-34.1239, 138.6923],
  [-34.1228, 138.6924],
  [-34.1166, 138.6936],
  [-34.1111, 138.6939],
  [-34.1095, 138.6945],
  [-34.1067, 138.6965],
  [-34.1051, 138.6970],
  [-34.0979, 138.6968],
  [-34.0961, 138.6971],
  [-34.0877, 138.6994],
  [-34.0850, 138.7008],
  [-34.0811, 138.7038],
  [-34.0787, 138.7046],
  [-34.0748, 138.7042],
  [-34.0731, 138.7044],
  [-34.0644, 138.7068],
  [-34.0627, 138.7067],
  [-34.0616, 138.7063],
  [-34.0413, 138.6889],
  [-34.0393, 138.6881],
  [-34.0375, 138.6880],
  [-34.0303, 138.6901],
  [-34.0278, 138.6900],
  [-34.0210, 138.6881],
  [-34.0196, 138.6872],
  [-34.0180, 138.6852],
  [-34.0172, 138.6836],
  [-34.0161, 138.6827],
  [-34.0085, 138.6780],
  [-34.0066, 138.6758],
  [-34.0049, 138.6723],
  [-34.0045, 138.6710],
  [-34.0046, 138.6700],
  [-34.0065, 138.6589],
  [-34.0062, 138.6567],
  [-34.0052, 138.6551],
  [-34.0039, 138.6542],
  [-34.0021, 138.6538],
  [-34.0006, 138.6527],
  [-33.9990, 138.6512],
  [-33.9966, 138.6504],
  [-33.9903, 138.6503],
  [-33.9885, 138.6498],
  [-33.9871, 138.6484],
  [-33.9863, 138.6467],
  [-33.9854, 138.6436],
  [-33.9844, 138.6423],
  [-33.9795, 138.6390],
  [-33.9741, 138.6379],
  [-33.9726, 138.6379],
  [-33.9688, 138.6388],
  [-33.9672, 138.6388],
  [-33.9591, 138.6381],
  [-33.9575, 138.6385],
  [-33.9558, 138.6395],
  [-33.9533, 138.6398],
  [-33.9450, 138.6369],
  [-33.9436, 138.6368],
  [-33.9286, 138.6387],
  [-33.9273, 138.6393],
  [-33.9267, 138.6398],
  [-33.9256, 138.6413],
  [-33.9242, 138.6425],
  [-33.9225, 138.6428],
  [-33.9209, 138.6426],
  [-33.9193, 138.6415],
  [-33.9178, 138.6410],
  [-33.9160, 138.6411],
  [-33.9103, 138.6393],
  [-33.9093, 138.6386],
  [-33.9082, 138.6375],
  [-33.9064, 138.6368],
  [-33.9007, 138.6376],
  [-33.8993, 138.6374],
  [-33.8931, 138.6356],
  [-33.8911, 138.6345],
  [-33.8778, 138.6315],
  [-33.8762, 138.6309],
  [-33.8737, 138.6292],
  [-33.8728, 138.6282],
  [-33.8717, 138.6266],
  [-33.8703, 138.6257],
  [-33.8692, 138.6256],
  [-33.8676, 138.6255],
  [-33.8639, 138.6241],
  [-33.8571, 138.6232],
  [-33.8563, 138.6230],
  [-33.8542, 138.6220],
  [-33.8525, 138.6216],
  [-33.8491, 138.6217],
  [-33.8477, 138.6216],
  [-33.8437, 138.6206],
  [-33.8406, 138.6202],
  [-33.8348, 138.6182]
];

const clareSpalding = [
  [-33.8348, 138.6182],
  [-33.8332, 138.6177],
  [-33.8325, 138.6171],
  [-33.8317, 138.6165],
  [-33.8307, 138.6164],
  [-33.8297, 138.6167],
  [-33.8288, 138.6174],
  [-33.8283, 138.6183],
  [-33.8273, 138.6212],
  [-33.8264, 138.6225],
  [-33.8252, 138.6232],
  [-33.8230, 138.6234],
  [-33.8181, 138.6228],
  [-33.8137, 138.6234],
  [-33.8121, 138.6233],
  [-33.8082, 138.6222],
  [-33.8068, 138.6223],
  [-33.8057, 138.6227],
  [-33.8044, 138.6238],
  [-33.8031, 138.6253],
  [-33.8012, 138.6264],
  [-33.7909, 138.6280],
  [-33.7833, 138.6281],
  [-33.7726, 138.6249],
  [-33.7709, 138.6248],
  [-33.7689, 138.6252],
  [-33.7624, 138.6274],
  [-33.7596, 138.6272],
  [-33.7550, 138.6258],
  [-33.7526, 138.6254],
  [-33.7478, 138.6254],
  [-33.7462, 138.6257],
  [-33.7448, 138.6265],
  [-33.7431, 138.6278],
  [-33.7411, 138.6288],
  [-33.7294, 138.6304],
  [-33.7276, 138.6315],
  [-33.7268, 138.6328],
  [-33.7261, 138.6348],
  [-33.7240, 138.6377],
  [-33.7117, 138.6460],
  [-33.7106, 138.6462],
  [-33.7094, 138.6461],
  [-33.6829, 138.6379],
  [-33.6731, 138.6372],
  [-33.6617, 138.6367],
  [-33.6583, 138.6361],
  [-33.6434, 138.6318],
  [-33.6340, 138.6310],
  [-33.6266, 138.6304],
  [-33.6170, 138.6288],
  [-33.6153, 138.6288],
  [-33.6083, 138.6292],
  [-33.6036, 138.6304],
  [-33.5987, 138.6306],
  [-33.5927, 138.6322],
  [-33.5890, 138.6322],
  [-33.5842, 138.6314],
  [-33.5786, 138.6302],
  [-33.5699, 138.6293],
  [-33.5663, 138.6289],
  [-33.5540, 138.6268],
  [-33.5471, 138.6240],
  [-33.5417, 138.6209],
  [-33.5396, 138.6200],
  [-33.5361, 138.6194],
  [-33.5337, 138.6196],
  [-33.5285, 138.6214],
  [-33.5253, 138.6229],
  [-33.5236, 138.6232],
  [-33.5182, 138.6239],
  [-33.5165, 138.6238],
  [-33.5143, 138.6226],
  [-33.5078, 138.6167],
  [-33.5059, 138.6157],
  [-33.4991, 138.6141]
];

export const spalding: Line = {
  name: 'Spalding',
  state: 'SA',
  segments: [
    {
      segments: [rivertonClare],
      history: {
        opened: {
          date: '1916-07-05',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-04-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [clareSpalding],
      history: {
        opened: {
          date: '1922-01-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-04-17',
          status: 'closed'
        }]
      }
    }
  ]
};
