import { Line } from "../../trackTypes";

const line = [
  [-27.4235, 117.9029],
  [-27.4222, 117.9038],
  [-27.4205, 117.9039],
  [-27.4184, 117.9029],
  [-27.4164, 117.9013],
  [-27.4147, 117.8985],
  [-27.4000, 117.8723],
  [-27.3625, 117.8053],
  [-27.3506, 117.7842],
  [-27.3497, 117.7806],
  [-27.3387, 117.6643],
  [-27.3370, 117.6562],
  [-27.3361, 117.6543],
  [-27.3349, 117.6527],
  [-27.3311, 117.6483]
];

export const bigBell: Line = {
  name: 'Big Bell',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1938-08-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1956-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
