import { Line } from "../../../trackTypes";

const miltonWaitahuna = [
  [-46.1270, 169.9186],
  [-46.1269, 169.9172],
  [-46.1268, 169.9164],
  [-46.1262, 169.9151],
  [-46.1129, 169.8915],
  [-46.1089, 169.8874],
  [-46.1070, 169.8862],
  [-46.1059, 169.8851],
  [-46.1046, 169.8827],
  [-46.1015, 169.8730],
  [-46.1008, 169.8719],
  [-46.1001, 169.8714],
  [-46.0973, 169.8707],
  [-46.0941, 169.8690],
  [-46.0935, 169.8688],
  [-46.0926, 169.8688],
  [-46.0919, 169.8684],
  [-46.0914, 169.8676],
  [-46.0906, 169.8650],
  [-46.0900, 169.8639],
  [-46.0883, 169.8619],
  [-46.0867, 169.8589],
  [-46.0856, 169.8574],
  [-46.0846, 169.8557],
  [-46.0834, 169.8544],
  [-46.0828, 169.8536],
  [-46.0824, 169.8520],
  [-46.0826, 169.8486],
  [-46.0827, 169.8476],
  [-46.0830, 169.8468],
  [-46.0836, 169.8461],
  [-46.0837, 169.8455],
  [-46.0834, 169.8448],
  [-46.0825, 169.8440],
  [-46.0820, 169.8429],
  [-46.0820, 169.8419],
  [-46.0822, 169.8408],
  [-46.0821, 169.8401],
  [-46.0820, 169.8395],
  [-46.0819, 169.8388],
  [-46.0817, 169.8373],
  [-46.0812, 169.8367],
  [-46.0806, 169.8365],
  [-46.0803, 169.8361],
  [-46.0801, 169.8352],
  [-46.0797, 169.8346],
  [-46.0788, 169.8346],
  [-46.0783, 169.8341],
  [-46.0780, 169.8332],
  [-46.0773, 169.8323],
  [-46.0768, 169.8321],
  [-46.0755, 169.8320],
  [-46.0747, 169.8299],
  [-46.0746, 169.8291],
  [-46.0744, 169.8285],
  [-46.0735, 169.8275],
  [-46.0724, 169.8273],
  [-46.0689, 169.8279],
  [-46.0681, 169.8273],
  [-46.0677, 169.8266],
  [-46.0674, 169.8258],
  [-46.0654, 169.8234],
  [-46.0636, 169.8221],
  [-46.0619, 169.8200],
  [-46.0615, 169.8189],
  [-46.0611, 169.8171],
  [-46.0612, 169.8162],
  [-46.0617, 169.8142],
  [-46.0616, 169.8135],
  [-46.0614, 169.8131],
  [-46.0593, 169.8122],
  [-46.0577, 169.8122],
  [-46.0568, 169.8127],
  [-46.0563, 169.8132],
  [-46.0557, 169.8133],
  [-46.0546, 169.8133],
  [-46.0544, 169.8138],
  [-46.0539, 169.8141],
  [-46.0526, 169.8140],
  [-46.0521, 169.8138],
  [-46.0518, 169.8133],
  [-46.0514, 169.8131],
  [-46.0511, 169.8130],
  [-46.0506, 169.8127],
  [-46.0502, 169.8120],
  [-46.0498, 169.8117],
  [-46.0492, 169.8115],
  [-46.0486, 169.8109],
  [-46.0473, 169.8107],
  [-46.0461, 169.8102],
  [-46.0452, 169.8092],
  [-46.0435, 169.8083],
  [-46.0431, 169.8077],
  [-46.0429, 169.8071],
  [-46.0429, 169.8063],
  [-46.0424, 169.8054],
  [-46.0399, 169.8041],
  [-46.0393, 169.8041],
  [-46.0387, 169.8044],
  [-46.0382, 169.8040],
  [-46.0379, 169.8034],
  [-46.0376, 169.8028],
  [-46.0368, 169.8024],
  [-46.0362, 169.8015],
  [-46.0359, 169.7999],
  [-46.0355, 169.7990],
  [-46.0350, 169.7985],
  [-46.0345, 169.7981],
  [-46.0305, 169.7937],
  [-46.0303, 169.7929],
  [-46.0303, 169.7914],
  [-46.0307, 169.7904],
  [-46.0313, 169.7898],
  [-46.0316, 169.7892],
  [-46.0316, 169.7886],
  [-46.0314, 169.7880],
  [-46.0314, 169.7873],
  [-46.0316, 169.7867],
  [-46.0320, 169.7861],
  [-46.0321, 169.7854],
  [-46.0319, 169.7845],
  [-46.0312, 169.7839],
  [-46.0283, 169.7801],
  [-46.0278, 169.7797],
  [-46.0274, 169.7793],
  [-46.0269, 169.7784],
  [-46.0260, 169.7773],
  [-46.0257, 169.7764],
  [-46.0256, 169.7754],
  [-46.0253, 169.7747],
  [-46.0247, 169.7742],
  [-46.0208, 169.7728],
  [-46.0148, 169.7709],
  [-46.0034, 169.7653],
  [-46.0023, 169.7645],
  [-45.9998, 169.7619],
  [-45.9990, 169.7614],
  [-45.9981, 169.7607],
  [-45.9966, 169.7589],
  [-45.9959, 169.7568],
  [-45.9958, 169.7546],
  [-45.9962, 169.7523],
  [-45.9962, 169.7515],
  [-45.9960, 169.7510],
  [-45.9955, 169.7497],
  [-45.9951, 169.7493],
  [-45.9937, 169.7482],
  [-45.9929, 169.7481],
  [-45.9921, 169.7484],
  [-45.9842, 169.7532]
];

const waitahunaLawrence = [
  [-45.9842, 169.7532],
  [-45.9767, 169.7577],
  [-45.9758, 169.7578],
  [-45.9751, 169.7572],
  [-45.9734, 169.7547],
  [-45.9714, 169.7528],
  [-45.9694, 169.7522],
  [-45.9684, 169.7520],
  [-45.9648, 169.7523],
  [-45.9631, 169.7519],
  [-45.9618, 169.7508],
  [-45.9605, 169.7494],
  [-45.9597, 169.7477],
  [-45.9591, 169.7458],
  [-45.9578, 169.7442],
  [-45.9552, 169.7430],
  [-45.9505, 169.7428],
  [-45.9488, 169.7418],
  [-45.9482, 169.7407],
  [-45.9480, 169.7389],
  [-45.9474, 169.7374],
  [-45.9471, 169.7334],
  [-45.9466, 169.7325],
  [-45.9456, 169.7323],
  [-45.9446, 169.7321],
  [-45.9400, 169.7330],
  [-45.9392, 169.7329],
  [-45.9386, 169.7326],
  [-45.9382, 169.7326],
  [-45.9375, 169.7332],
  [-45.9370, 169.7333],
  [-45.9356, 169.7321],
  [-45.9354, 169.7316],
  [-45.9353, 169.7311],
  [-45.9354, 169.7301],
  [-45.9353, 169.7295],
  [-45.9348, 169.7288],
  [-45.9347, 169.7283],
  [-45.9344, 169.7267],
  [-45.9341, 169.7261],
  [-45.9339, 169.7258],
  [-45.9330, 169.7254],
  [-45.9319, 169.7243],
  [-45.9309, 169.7237],
  [-45.9301, 169.7227],
  [-45.9294, 169.7210],
  [-45.9279, 169.7195],
  [-45.9274, 169.7188],
  [-45.9258, 169.7179],
  [-45.9247, 169.7168],
  [-45.9220, 169.7160],
  [-45.9210, 169.7151],
  [-45.9187, 169.7136],
  [-45.9182, 169.7130],
  [-45.9178, 169.7123],
  [-45.9163, 169.7106],
  [-45.9158, 169.7097],
  [-45.9153, 169.7094],
  [-45.9136, 169.7085],
  [-45.9126, 169.7076],
  [-45.9123, 169.7069],
  [-45.9120, 169.7066],
  [-45.9106, 169.7057],
  [-45.9100, 169.7050],
  [-45.9094, 169.7032],
  [-45.9094, 169.7020],
  [-45.9099, 169.7005],
  [-45.9101, 169.6980],
  [-45.9103, 169.6967],
  [-45.9125, 169.6906],
  [-45.9126, 169.6901],
  [-45.9125, 169.6884],
  [-45.9122, 169.6858]
];

const lawrenceBigHill = [
  [-45.9122, 169.6858],
  [-45.9119, 169.6843],
  [-45.9119, 169.6835],
  [-45.9121, 169.6830],
  [-45.9129, 169.6820],
  [-45.9134, 169.6808],
  [-45.9135, 169.6795],
  [-45.9129, 169.6747],
  [-45.9122, 169.6705],
  [-45.9124, 169.6641],
  [-45.9130, 169.6605],
  [-45.9134, 169.6587],
  [-45.9158, 169.6536],
  [-45.9161, 169.6523],
  [-45.9161, 169.6509],
  [-45.9140, 169.6429],
  [-45.9131, 169.6407],
  [-45.9118, 169.6389],
  [-45.9101, 169.6379],
  [-45.9077, 169.6376],
  [-45.9034, 169.6360],
  [-45.8969, 169.6345],
  [-45.8936, 169.6344],
  [-45.8930, 169.6340],
  [-45.8917, 169.6324],
  [-45.8891, 169.6302],
  [-45.8872, 169.6268],
  [-45.8845, 169.6199],
  [-45.8819, 169.6170],
  [-45.8812, 169.6167],
  [-45.8804, 169.6169],
  [-45.8782, 169.6186],
  [-45.8772, 169.6190],
  [-45.8750, 169.6188],
  [-45.8744, 169.6186],
  [-45.8740, 169.6181],
  [-45.8735, 169.6170],
  [-45.8726, 169.6162],
  [-45.8719, 169.6161],
  [-45.8698, 169.6169],
  [-45.8689, 169.6170],
  [-45.8683, 169.6172],
  [-45.8671, 169.6181],
  [-45.8662, 169.6183],
  [-45.8650, 169.6179],
  [-45.8638, 169.6181],
  [-45.8633, 169.6178],
  [-45.8624, 169.6170],
  [-45.8612, 169.6168],
  [-45.8592, 169.6171],
  [-45.8585, 169.6168],
  [-45.8581, 169.6158],
  [-45.8579, 169.6126],
  [-45.8587, 169.6078],
  [-45.8588, 169.6069],
  [-45.8587, 169.6058],
  [-45.8585, 169.6050]
];

const bigHillTunnel = [
  [-45.8585, 169.6050],
  [-45.8566, 169.5999]
];

const bigHillBeaumont = [
  [-45.8566, 169.5999],
  [-45.8557, 169.5982],
  [-45.8555, 169.5974],
  [-45.8555, 169.5961],
  [-45.8553, 169.5955],
  [-45.8550, 169.5952],
  [-45.8545, 169.5950],
  [-45.8541, 169.5946],
  [-45.8535, 169.5929],
  [-45.8531, 169.5923],
  [-45.8524, 169.5917],
  [-45.8517, 169.5907],
  [-45.8508, 169.5882],
  [-45.8495, 169.5860],
  [-45.8486, 169.5851],
  [-45.8483, 169.5843],
  [-45.8483, 169.5832],
  [-45.8488, 169.5823],
  [-45.8498, 169.5813],
  [-45.8502, 169.5800],
  [-45.8500, 169.5789],
  [-45.8493, 169.5781],
  [-45.8485, 169.5779],
  [-45.8477, 169.5784],
  [-45.8469, 169.5799],
  [-45.8463, 169.5803],
  [-45.8457, 169.5804],
  [-45.8446, 169.5797],
  [-45.8414, 169.5749],
  [-45.8388, 169.5727],
  [-45.8359, 169.5716],
  [-45.8353, 169.5711],
  [-45.8346, 169.5704],
  [-45.8323, 169.5690],
  [-45.8300, 169.5666],
  [-45.8293, 169.5648],
  [-45.8293, 169.5629],
  [-45.8291, 169.5608],
  [-45.8291, 169.5597],
  [-45.8295, 169.5583],
  [-45.8295, 169.5573],
  [-45.8283, 169.5479],
  [-45.8277, 169.5412],
  [-45.8269, 169.5394],
  [-45.8264, 169.5372],
  [-45.8227, 169.5303]
];

const beaumontMillarsFlat = [
  [-45.8227, 169.5303],
  [-45.8223, 169.5297],
  [-45.8217, 169.5291],
  [-45.8207, 169.5287],
  [-45.8166, 169.5290],
  [-45.8127, 169.5290],
  [-45.8108, 169.5284],
  [-45.8098, 169.5277],
  [-45.8092, 169.5265],
  [-45.8075, 169.5204],
  [-45.8068, 169.5193],
  [-45.8048, 169.5135],
  [-45.8040, 169.5122],
  [-45.8011, 169.5094],
  [-45.8002, 169.5089],
  [-45.7993, 169.5077],
  [-45.7986, 169.5069],
  [-45.7980, 169.5057],
  [-45.7958, 169.5022],
  [-45.7948, 169.5014],
  [-45.7925, 169.5007],
  [-45.7873, 169.5015],
  [-45.7861, 169.5020],
  [-45.7840, 169.5018],
  [-45.7828, 169.5022],
  [-45.7815, 169.5036],
  [-45.7783, 169.5117],
  [-45.7764, 169.5153],
  [-45.7751, 169.5166],
  [-45.7733, 169.5175],
  [-45.7724, 169.5177],
  [-45.7717, 169.5174],
  [-45.7696, 169.5152],
  [-45.7680, 169.5145],
  [-45.7657, 169.5144],
  [-45.7637, 169.5148],
  [-45.7624, 169.5147],
  [-45.7586, 169.5134],
  [-45.7565, 169.5131],
  [-45.7558, 169.5128],
  [-45.7550, 169.5118],
  [-45.7541, 169.5095],
  [-45.7536, 169.5072],
  [-45.7520, 169.5040],
  [-45.7514, 169.5035],
  [-45.7508, 169.5031],
  [-45.7500, 169.5021],
  [-45.7486, 169.4997],
  [-45.7463, 169.4971],
  [-45.7448, 169.4945],
  [-45.7443, 169.4928],
  [-45.7439, 169.4918],
  [-45.7421, 169.4902],
  [-45.7416, 169.4900],
  [-45.7410, 169.4900],
  [-45.7391, 169.4893],
  [-45.7370, 169.4875],
  [-45.7365, 169.4871],
  [-45.7358, 169.4861],
  [-45.7352, 169.4857],
  [-45.7344, 169.4857],
  [-45.7339, 169.4859],
  [-45.7320, 169.4861],
  [-45.7314, 169.4856],
  [-45.7310, 169.4845],
  [-45.7309, 169.4831],
  [-45.7308, 169.4825],
  [-45.7300, 169.4806],
  [-45.7299, 169.4796],
  [-45.7297, 169.4777],
  [-45.7289, 169.4752],
  [-45.7284, 169.4744],
  [-45.7278, 169.4740],
  [-45.7268, 169.4743],
  [-45.7258, 169.4754],
  [-45.7250, 169.4769],
  [-45.7239, 169.4776],
  [-45.7227, 169.4775],
  [-45.7204, 169.4754],
  [-45.7197, 169.4741],
  [-45.7188, 169.4685],
  [-45.7185, 169.4675],
  [-45.7173, 169.4657],
  [-45.7165, 169.4648],
  [-45.7140, 169.4635],
  [-45.7118, 169.4618],
  [-45.7100, 169.4598],
  [-45.7092, 169.4584],
  [-45.7083, 169.4575],
  [-45.7071, 169.4571],
  [-45.7066, 169.4568],
  [-45.7039, 169.4545],
  [-45.7015, 169.4514],
  [-45.7009, 169.4508],
  [-45.6991, 169.4499],
  [-45.6983, 169.4498],
  [-45.6977, 169.4495],
  [-45.6971, 169.4489],
  [-45.6957, 169.4478],
  [-45.6943, 169.4462],
  [-45.6941, 169.4454],
  [-45.6941, 169.4446],
  [-45.6946, 169.4429],
  [-45.6945, 169.4411],
  [-45.6940, 169.4394],
  [-45.6930, 169.4370],
  [-45.6926, 169.4365],
  [-45.6910, 169.4354],
  [-45.6874, 169.4319],
  [-45.6839, 169.4300],
  [-45.6829, 169.4293],
  [-45.6728, 169.4187],
  [-45.6672, 169.4153],
  [-45.6666, 169.4154],
  [-45.6655, 169.4157],
  [-45.6648, 169.4156],
  [-45.6597, 169.4121]
];

const millarsFlatRoxburgh = [
  [-45.6597, 169.4121],
  [-45.6589, 169.4112],
  [-45.6565, 169.4087],
  [-45.6533, 169.4050],
  [-45.6526, 169.4039],
  [-45.6496, 169.3972],
  [-45.6483, 169.3947],
  [-45.6472, 169.3931],
  [-45.6380, 169.3849],
  [-45.6315, 169.3803],
  [-45.6308, 169.3800],
  [-45.6271, 169.3795],
  [-45.6202, 169.3774],
  [-45.6195, 169.3768],
  [-45.6185, 169.3757],
  [-45.6171, 169.3749],
  [-45.6161, 169.3750],
  [-45.6154, 169.3747],
  [-45.6137, 169.3718],
  [-45.6129, 169.3698],
  [-45.6128, 169.3688],
  [-45.6129, 169.3662],
  [-45.6113, 169.3572],
  [-45.6111, 169.3565],
  [-45.6076, 169.3511],
  [-45.5944, 169.3345],
  [-45.5906, 169.3300],
  [-45.5900, 169.3297],
  [-45.5895, 169.3297],
  [-45.5891, 169.3301],
  [-45.5887, 169.3306],
  [-45.5882, 169.3310],
  [-45.5875, 169.3309],
  [-45.5869, 169.3305],
  [-45.5863, 169.3297],
  [-45.5859, 169.3294],
  [-45.5855, 169.3292],
  [-45.5848, 169.3283],
  [-45.5842, 169.3279],
  [-45.5832, 169.3275],
  [-45.5821, 169.3268],
  [-45.5800, 169.3232],
  [-45.5796, 169.3224],
  [-45.5790, 169.3219],
  [-45.5784, 169.3219],
  [-45.5775, 169.3225],
  [-45.5769, 169.3226],
  [-45.5764, 169.3223],
  [-45.5760, 169.3220],
  [-45.5755, 169.3218],
  [-45.5751, 169.3219],
  [-45.5744, 169.3223],
  [-45.5727, 169.3228],
  [-45.5720, 169.3233],
  [-45.5678, 169.3275],
  [-45.5669, 169.3278],
  [-45.5659, 169.3278],
  [-45.5653, 169.3274],
  [-45.5632, 169.3259],
  [-45.5615, 169.3255],
  [-45.5589, 169.3260]
];

export const roxburgh: Line = {
  name: 'Roxburgh',
  state: 'NZ',
  segments: [
    {
      segments: [miltonWaitahuna],
      history: {
        opened: {
          date: '1877-01-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waitahunaLawrence],
      history: {
        opened: {
          date: '1877-04-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lawrenceBigHill],
      history: {
        opened: {
          date: '1910-10-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        {
          name: 'Big Hill Tunnel',
          segment: bigHillTunnel,
          type: 'tunnel'
        },
        bigHillBeaumont
      ],
      history: {
        opened: {
          date: '1914-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [beaumontMillarsFlat],
      history: {
        opened: {
          date: '1925-12-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-06-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [millarsFlatRoxburgh],
      history: {
        opened: {
          date: '1928-04-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-06-01',
          status: 'closed'
        }]
      }
    }
  ]
}
