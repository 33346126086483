import { Line } from "../../trackTypes";

const line = [
  [-41.3467, 146.4269],
  [-41.3466, 146.4263],
  [-41.3467, 146.4258],
  [-41.3470, 146.4253],
  [-41.3483, 146.4232],
  [-41.3490, 146.4228],
  [-41.3541, 146.4211],
  [-41.3553, 146.4212],
  [-41.3565, 146.4218],
  [-41.3575, 146.4219],
  [-41.3596, 146.4211],
  [-41.3606, 146.4212],
  [-41.3628, 146.4222],
  [-41.3636, 146.4227],
  [-41.3644, 146.4225],
  [-41.3663, 146.4204],
  [-41.3687, 146.4184],
  [-41.3689, 146.4173],
  [-41.3685, 146.4163],
  [-41.3678, 146.4155],
  [-41.3678, 146.4145],
  [-41.3687, 146.4139],
  [-41.3698, 146.4141],
  [-41.3707, 146.4137],
  [-41.3712, 146.4127],
  [-41.3713, 146.4073],
  [-41.3709, 146.4060],
  [-41.3703, 146.4051],
  [-41.3700, 146.4039],
  [-41.3701, 146.4010],
  [-41.3704, 146.4000],
  [-41.3710, 146.3995],
  [-41.3724, 146.3993],
  [-41.3732, 146.3995],
  [-41.3744, 146.3997],
  [-41.3768, 146.3983],
  [-41.3778, 146.3981],
  [-41.3808, 146.3979],
  [-41.3817, 146.3974],
  [-41.3822, 146.3962],
  [-41.3820, 146.3945],
  [-41.3820, 146.3933],
  [-41.3825, 146.3921],
  [-41.3825, 146.3912],
  [-41.3823, 146.3900],
  [-41.3824, 146.3891],
  [-41.3830, 146.3886],
  [-41.3839, 146.3886],
  [-41.3844, 146.3881],
  [-41.3846, 146.3873],
  [-41.3842, 146.3864],
  [-41.3817, 146.3853],
  [-41.3804, 146.3851],
  [-41.3789, 146.3852],
  [-41.3780, 146.3848],
  [-41.3773, 146.3836],
  [-41.3772, 146.3823],
  [-41.3770, 146.3816],
  [-41.3765, 146.3814],
  [-41.3757, 146.3814],
  [-41.3752, 146.3811],
  [-41.3738, 146.3784],
  [-41.3737, 146.3772],
  [-41.3759, 146.3732],
  [-41.3768, 146.3724],
  [-41.3779, 146.3705],
  [-41.3781, 146.3695],
  [-41.3780, 146.3671],
  [-41.3784, 146.3652],
  [-41.3777, 146.3620],
  [-41.3779, 146.3592],
  [-41.3786, 146.3569],
  [-41.3791, 146.3554],
  [-41.3809, 146.3439],
  [-41.3839, 146.3328],
  [-41.3845, 146.3314],
  [-41.3873, 146.3281],
  [-41.3876, 146.3272],
  [-41.3878, 146.3258],
  [-41.3882, 146.3249],
  [-41.3904, 146.3234],
  [-41.3921, 146.3230],
  [-41.3930, 146.3221],
  [-41.3937, 146.3193],
  [-41.3943, 146.3184],
  [-41.3949, 146.3175],
  [-41.3960, 146.3123],
  [-41.3966, 146.3110],
  [-41.3980, 146.3085],
  [-41.3981, 146.3070],
  [-41.3972, 146.3030],
  [-41.3969, 146.3010],
  [-41.3971, 146.2985],
  [-41.3976, 146.2973],
  [-41.4003, 146.2946],
  [-41.4024, 146.2917],
  [-41.4029, 146.2906],
  [-41.4044, 146.2852],
  [-41.4047, 146.2815],
  [-41.4060, 146.2773],
  [-41.4063, 146.2743],
  [-41.4068, 146.2732],
  [-41.4088, 146.2711],
  [-41.4091, 146.2704],
  [-41.4087, 146.2676],
  [-41.4076, 146.2665],
  [-41.4062, 146.2659],
  [-41.4057, 146.2647],
  [-41.4062, 146.2617],
  [-41.4088, 146.2548],
  [-41.4090, 146.2536],
  [-41.4090, 146.2505],
  [-41.4088, 146.2493],
  [-41.4081, 146.2468],
  [-41.4074, 146.2402],
  [-41.4079, 146.2388],
  [-41.4102, 146.2359]
];

export const sheffield: Line = {
  name: 'Sheffield',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-11-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1957-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
