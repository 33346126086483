import { Line } from "../../../trackTypes";

const gladstoneRoadLine = [
  [-38.66784, 178.02790],
  [-38.66652, 178.02612],
  [-38.65881, 178.01560],
  [-38.65859, 178.01518],
  [-38.65582, 178.00918],
  [-38.65181, 177.99838]
];

const whataupokoLine = [
  [-38.66652, 178.02612],
  [-38.66646, 178.02608],
  [-38.66638, 178.02607],
  [-38.66630, 178.02609],
  [-38.66546, 178.02706],
  [-38.66533, 178.02732],
  [-38.66490, 178.02847],
  [-38.66485, 178.02866],
  [-38.66482, 178.02891],
  [-38.66471, 178.03015],
  [-38.66466, 178.03026],
  [-38.66460, 178.03031],
  [-38.66450, 178.03035],
  [-38.66153, 178.03007],
  [-38.66125, 178.03000],
  [-38.66104, 178.02991],
  [-38.65837, 178.02838],
  [-38.65814, 178.02820],
  [-38.65043, 178.02056]
];

export const gisborneTrams: Line = {
  name: 'Gisborne Trams',
  state: 'NZ',
  segments: [
    {
      segments: [gladstoneRoadLine],
      history: {
        opened: {
          date: '1913-04-16',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram' //batery
        },
        trackChange: [{
          date: '1929-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [whataupokoLine],
      history: {
        opened: {
          date: '1923-11-24',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'tram' //batery
        },
        trackChange: [{
          date: '1928-11-01',
          status: 'closed'
        }]
      }
    }
  ]
}
