import { Line } from "../../../trackTypes";

const canungraLaheysTunnel = [
  [-28.0172, 153.1621],
  [-28.0176, 153.1641],
  [-28.0180, 153.1650],
  [-28.0193, 153.1680],
  [-28.0197, 153.1685],
  [-28.0203, 153.1690],
  [-28.0205, 153.1697],
  [-28.0207, 153.1704],
  [-28.0214, 153.1710],
  [-28.0224, 153.1713],
  [-28.0227, 153.1715]
];

const laheysTunnel = [
  [-28.0227, 153.1715],
  [-28.0232, 153.1723]
];

const laheysTunnelLineEnd = [
  [-28.0232, 153.1723],
  [-28.0232, 153.1725],
  [-28.0230, 153.1738],
  [-28.0232, 153.1747],
  [-28.0241, 153.1755],
  [-28.0257, 153.1755],
  [-28.0265, 153.1747],
  [-28.0274, 153.1729],
  [-28.0278, 153.1724],
  [-28.0285, 153.1726],
  [-28.0289, 153.1731],
  [-28.0305, 153.1731],
  [-28.0311, 153.1736],
  [-28.0342, 153.1740],
  [-28.0367, 153.1739],
  [-28.0371, 153.1741],
  [-28.0372, 153.1745],
  [-28.0373, 153.1750],
  [-28.0387, 153.1763],
  [-28.0393, 153.1764],
  [-28.0406, 153.1760],
  [-28.0410, 153.1759],
  [-28.0414, 153.1760],
  [-28.0418, 153.1758],
  [-28.0423, 153.1754],
  [-28.0430, 153.1752],
  [-28.0444, 153.1750],
  [-28.0449, 153.1752],
  [-28.0463, 153.1768],
  [-28.0471, 153.1771],
  [-28.0482, 153.1773],
  [-28.0496, 153.1776],
  [-28.0501, 153.1776],
  [-28.0508, 153.1772],
  [-28.0512, 153.1770],
  [-28.0529, 153.1768],
  [-28.0543, 153.1763],
  [-28.0550, 153.1757],
  [-28.0563, 153.1751],
  [-28.0566, 153.1746],
  [-28.0569, 153.1738],
  [-28.0573, 153.1736],
  [-28.0588, 153.1741],
  [-28.0597, 153.1736],
  [-28.0619, 153.1684],
  [-28.0626, 153.1677],
  [-28.0637, 153.1675],
  [-28.0650, 153.1676],
  [-28.0657, 153.1675],
  [-28.0665, 153.1672],
  [-28.0701, 153.1665],
  [-28.0711, 153.1659],
  [-28.0715, 153.1647],
  [-28.0716, 153.1615],
  [-28.0720, 153.1602],
  [-28.0729, 153.1591],
  [-28.0743, 153.1589],
  [-28.0757, 153.1593],
  [-28.0769, 153.1593],
  [-28.0786, 153.1590],
  [-28.0792, 153.1591],
  [-28.0803, 153.1594],
  [-28.0805, 153.1589],
  [-28.0812, 153.1583],
  [-28.0821, 153.1566],
  [-28.0826, 153.1562],
  [-28.0840, 153.1549],
  [-28.0844, 153.1542],
  [-28.0843, 153.1535],
  [-28.0838, 153.1522],
  [-28.0837, 153.1515],
  [-28.0841, 153.1507],
  [-28.0856, 153.1494],
  [-28.0861, 153.1492],
  [-28.0888, 153.1489],
  [-28.0895, 153.1487],
  [-28.0899, 153.1484],
  [-28.0918, 153.1472],
  [-28.0971, 153.1452],
  [-28.0980, 153.1451],
  [-28.1013, 153.1456],
  [-28.1021, 153.1455],
  [-28.1033, 153.1449],
  [-28.1039, 153.1440],
  [-28.1075, 153.1414],
  [-28.1087, 153.1411],
  [-28.1119, 153.1418],
  [-28.1150, 153.1419],
  [-28.1156, 153.1419],
  [-28.1163, 153.1415],
  [-28.1169, 153.1415],
  [-28.1197, 153.1424],
  [-28.1203, 153.1423],
  [-28.1212, 153.1418],
  [-28.1227, 153.1415],
  [-28.1232, 153.1415],
  [-28.1250, 153.1424],
  [-28.1268, 153.1439],
  [-28.1318, 153.1486],
  [-28.1322, 153.1494],
  [-28.1328, 153.1501],
  [-28.1333, 153.1502],
  [-28.1339, 153.1501],
  [-28.1355, 153.1503],
  [-28.1360, 153.1504],
  [-28.1367, 153.1512],
  [-28.1371, 153.1527],
  [-28.1378, 153.1535],
  [-28.1397, 153.1552]
];

const flyingFoxBranch = [
  [-28.0803, 153.1594],
  [-28.0818, 153.1604],
  [-28.0832, 153.1607],
  [-28.0840, 153.1612],
  [-28.0847, 153.1620],
  [-28.0860, 153.1650],
  [-28.0862, 153.1670],
  [-28.0876, 153.1689],
  [-28.0884, 153.1694],
  [-28.0887, 153.1697],
  [-28.0893, 153.1700],
  [-28.0899, 153.1700],
  [-28.0903, 153.1703],
  [-28.0912, 153.1711],
  [-28.0917, 153.1714],
  [-28.0931, 153.1716],
  [-28.0938, 153.1720],
  [-28.0964, 153.1720],
  [-28.0973, 153.1723],
  [-28.1025, 153.1754],
  [-28.1030, 153.1760],
  [-28.1054, 153.1768],
  [-28.1070, 153.1777],
  [-28.1076, 153.1777],
  [-28.1092, 153.1774],
  [-28.1097, 153.1773],
  [-28.1117, 153.1777],
  [-28.1127, 153.1775],
  [-28.1143, 153.1775],
  [-28.1155, 153.1773]
];

const pricesCreekBranch = [
  [-28.0899, 153.1484],
  [-28.0909, 153.1487],
  [-28.0935, 153.1509],
  [-28.0943, 153.1514],
  [-28.0966, 153.1526],
  [-28.0971, 153.1529],
  [-28.0977, 153.1539],
  [-28.0980, 153.1541],
  [-28.0984, 153.1541],
  [-28.0998, 153.1539],
  [-28.1020, 153.1540],
  [-28.1038, 153.1538],
  [-28.1057, 153.1543],
  [-28.1065, 153.1548],
  [-28.1068, 153.1549],
  [-28.1076, 153.1545],
  [-28.1093, 153.1544],
  [-28.1118, 153.1541],
  [-28.1127, 153.1537]
];

export const laheysTramway: Line = {
  name: 'Laheys Tramwas',
  state: 'QLD',
  segments: [
    {
      segments: [
        canungraLaheysTunnel,
        {
          name: 'Laheys Canungra Tramway Tunnel',
          segment: laheysTunnel,
          type: 'tunnel'
        },
        laheysTunnelLineEnd
      ],
      history: {
        opened: {
          date: '1900-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [flyingFoxBranch, pricesCreekBranch],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1933-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
