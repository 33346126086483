import { Line } from "../../trackTypes";

const line = [
  [-42.1534, 145.3284],
  [-42.1528, 145.3291],
  [-42.1525, 145.3296],
  [-42.1525, 145.3301],
  [-42.1526, 145.3307],
  [-42.1526, 145.3315],
  [-42.1524, 145.3322],
  [-42.1517, 145.3329],
  [-42.1515, 145.3335],
  [-42.1516, 145.3342],
  [-42.1521, 145.3347],
  [-42.1529, 145.3351],
  [-42.1548, 145.3350],
  [-42.1554, 145.3346],
  [-42.1565, 145.3325],
  [-42.1568, 145.3311],
  [-42.1572, 145.3304],
  [-42.1576, 145.3300],
  [-42.1581, 145.3297],
  [-42.1586, 145.3293],
  [-42.1590, 145.3287]
];

export const strahnZeehanLink: Line = {
  name: 'Strahn - Zeehan Link',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1900-10-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-06-04',
          status: 'closed'
        }]
      }
    }
  ]
}
