import { Line } from "../../trackTypes";

const ballaratCreswick = [
  [-37.5498, 143.8539],
  [-37.5479, 143.8533],
  [-37.5415, 143.8507],
  [-37.5400, 143.8502],
  [-37.5385, 143.8500],
  [-37.5358, 143.8498],
  [-37.5336, 143.8498],
  [-37.5314, 143.8499],
  [-37.4868, 143.8541],
  [-37.4856, 143.8539],
  [-37.4682, 143.8490],
  [-37.4669, 143.8487],
  [-37.4653, 143.8487],
  [-37.4635, 143.8490],
  [-37.4591, 143.8498],
  [-37.4574, 143.8500],
  [-37.4443, 143.8509],
  [-37.4424, 143.8508],
  [-37.4284, 143.8520],
  [-37.4271, 143.8524],
  [-37.4262, 143.8530],
  [-37.4251, 143.8542],
  [-37.4242, 143.8563],
  [-37.4237, 143.8587],
  [-37.4237, 143.8609],
  [-37.4241, 143.8629],
  [-37.4245, 143.8638],
  [-37.4263, 143.8678],
  [-37.4270, 143.8702],
  [-37.4271, 143.8714],
  [-37.4271, 143.8738],
  [-37.4275, 143.8764],
  [-37.4292, 143.8817],
  [-37.4295, 143.8834],
  [-37.4292, 143.8853],
  [-37.4285, 143.8866],
  [-37.4273, 143.8877],
  [-37.4261, 143.8882],
  [-37.4250, 143.8882],
  [-37.4246, 143.8880]
];

const creswickClunes = [
  [-37.4246, 143.8880],
  [-37.4217, 143.8874],
  [-37.4201, 143.8872],
  [-37.4180, 143.8873],
  [-37.4168, 143.8876],
  [-37.4133, 143.8890],
  [-37.4123, 143.8890],
  [-37.4114, 143.8888],
  [-37.4108, 143.8885],
  [-37.3933, 143.8737],
  [-37.3923, 143.8725],
  [-37.3906, 143.8700],
  [-37.3419, 143.7847],
  [-37.3413, 143.7840],
  [-37.3405, 143.7833],
  [-37.3390, 143.7825],
  [-37.3375, 143.7822],
  [-37.3251, 143.7799],
  [-37.3238, 143.7798],
  [-37.3227, 143.7798],
  [-37.3155, 143.7811],
  [-37.3058, 143.7822],
  [-37.3039, 143.7822],
  [-37.3027, 143.7820]
];

const clunesAvocaJunction = [
  [-37.3027, 143.7820],
  [-37.3018, 143.7818],
  [-37.3008, 143.7815],
  [-37.3003, 143.7810],
  [-37.2998, 143.7804],
  [-37.2994, 143.7795],
  [-37.2942, 143.7642],
  [-37.2938, 143.7633],
  [-37.2927, 143.7620],
  [-37.2682, 143.7401],
  [-37.2673, 143.7392],
  [-37.2664, 143.7381],
  [-37.2597, 143.7290],
  [-37.2590, 143.7282],
  [-37.2581, 143.7275],
  [-37.2572, 143.7269],
  [-37.2515, 143.7245],
  [-37.2503, 143.7238],
  [-37.2490, 143.7227],
  [-37.2365, 143.7097],
  [-37.2352, 143.7087],
  [-37.2336, 143.7080],
  [-37.2327, 143.7079],
  [-37.2160, 143.7059],
  [-37.2152, 143.7057],
  [-37.2136, 143.7051],
  [-37.2054, 143.7018],
  [-37.2045, 143.7016],
  [-37.2040, 143.7015],
  [-37.1792, 143.7040],
  [-37.1778, 143.7043],
  [-37.1586, 143.7103],
  [-37.1574, 143.7106],
  [-37.1504, 143.7118],
  [-37.1451, 143.7127],
  [-37.1389, 143.7132],
  [-37.1375, 143.7135],
  [-37.1363, 143.7140],
  [-37.1340, 143.7152],
  [-37.1327, 143.7155],
  [-37.1275, 143.7166],
  [-37.1260, 143.7174],
  [-37.1247, 143.7185],
  [-37.1160, 143.7274],
  [-37.1150, 143.7280],
  [-37.1135, 143.7284],
  [-37.1090, 143.7287],
  [-37.1075, 143.7291],
  [-37.1067, 143.7295],
  [-37.1016, 143.7329],
  [-37.1004, 143.7335],
  [-37.0993, 143.7338],
  [-37.0982, 143.7338],
  [-37.0785, 143.7322],
  [-37.0754, 143.7310],
  [-37.0744, 143.7307],
  [-37.0734, 143.7307],
  [-37.0726, 143.7309],
  [-37.0625, 143.7342],
  [-37.0612, 143.7349],
  [-37.0545, 143.7402]
];

const avocaJunctionMaryborough = [
  [-37.0545, 143.7402],
  [-37.0511, 143.7426]
];

export const maryborough: Line = {
  name: 'Maryborourgh',
  state: 'VIC',
  segments: [
    {
      segments: [ballaratCreswick],
      history: {
        opened: {
          date: '1874-07-07',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [creswickClunes],
      history: {
        opened: {
          date: '1874-11-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [clunesAvocaJunction],
      history: {
        opened: {
          date: '1875-02-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [avocaJunctionMaryborough],
      history: {
        opened: {
          date: '1875-02-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-04-28',
          gauge: 'dual'
        }]
      }
    }
  ]
}
