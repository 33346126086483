import { Line } from "../../trackTypes";

const invercargillWoodlands = [
  [-46.4119, 168.3449],
  [-46.4142, 168.3452],
  [-46.4151, 168.3460],
  [-46.4155, 168.3476],
  [-46.4155, 168.3634],
  [-46.4154, 168.3658],
  [-46.4131, 168.3836],
  [-46.4101, 168.3969],
  [-46.4095, 168.3983],
  [-46.4017, 168.4098],
  [-46.4007, 168.4124],
  [-46.3816, 168.4891],
  [-46.3798, 168.5044],
  [-46.3780, 168.5145],
  [-46.3765, 168.5202],
  [-46.3681, 168.5388],
  [-46.3651, 168.5434],
  [-46.3584, 168.5563]
];

const woodlandsGore = [
  [-46.3584, 168.5563],
  [-46.3554, 168.5621],
  [-46.3544, 168.5652],
  [-46.3519, 168.5782],
  [-46.3482, 168.5935],
  [-46.3436, 168.6040],
  [-46.3426, 168.6086],
  [-46.3422, 168.6458],
  [-46.3399, 168.6648],
  [-46.3390, 168.6679],
  [-46.3353, 168.6758],
  [-46.3345, 168.6786],
  [-46.3304, 168.7066],
  [-46.3284, 168.7171],
  [-46.3219, 168.7428],
  [-46.3215, 168.7451],
  [-46.3215, 168.7675],
  [-46.3210, 168.7700],
  [-46.3173, 168.7794],
  [-46.3163, 168.7808],
  [-46.3108, 168.7855],
  [-46.2560, 168.8078],
  [-46.2535, 168.8095],
  [-46.2136, 168.8480],
  [-46.2039, 168.8534],
  [-46.2005, 168.8570],
  [-46.1963, 168.8629],
  [-46.1538, 168.9082],
  [-46.1524, 168.9094],
  [-46.1339, 168.9199],
  [-46.1008, 168.9451]
];

const goreBalclutha = [
  [-46.1008, 168.9451],
  [-46.1001, 168.9464],
  [-46.0989, 168.9519],
  [-46.0980, 168.9541],
  [-46.0927, 168.9606],
  [-46.0917, 168.9629],
  [-46.0897, 168.9717],
  [-46.0889, 168.9739],
  [-46.0874, 168.9759],
  [-46.0862, 168.9784],
  [-46.0852, 168.9795],
  [-46.0826, 168.9807],
  [-46.0818, 168.9819],
  [-46.0816, 168.9835],
  [-46.0820, 168.9848],
  [-46.0851, 168.9892],
  [-46.0857, 168.9901],
  [-46.0860, 168.9913],
  [-46.0860, 168.9924],
  [-46.0847, 168.9967],
  [-46.0845, 168.9984],
  [-46.0847, 169.0016],
  [-46.0885, 169.0132],
  [-46.0888, 169.0156],
  [-46.0889, 169.0183],
  [-46.0905, 169.0248],
  [-46.0970, 169.0390],
  [-46.0976, 169.0420],
  [-46.0971, 169.0616],
  [-46.0966, 169.0657],
  [-46.0947, 169.0750],
  [-46.0943, 169.0779],
  [-46.0941, 169.0837],
  [-46.0953, 169.0969],
  [-46.0958, 169.1079],
  [-46.0964, 169.1116],
  [-46.1040, 169.1399],
  [-46.1058, 169.1432],
  [-46.1072, 169.1446],
  [-46.1088, 169.1477],
  [-46.1101, 169.1524],
  [-46.1121, 169.1556],
  [-46.1141, 169.1571],
  [-46.1159, 169.1596],
  [-46.1173, 169.1639],
  [-46.1177, 169.1663],
  [-46.1179, 169.1717],
  [-46.1170, 169.1779],
  [-46.1114, 169.1922],
  [-46.1110, 169.1950],
  [-46.1117, 169.2003],
  [-46.1129, 169.2033],
  [-46.1143, 169.2055],
  [-46.1150, 169.2078],
  [-46.1146, 169.2096],
  [-46.1137, 169.2118],
  [-46.1128, 169.2153],
  [-46.1124, 169.2195],
  [-46.1117, 169.2224],
  [-46.1079, 169.2316],
  [-46.1074, 169.2343],
  [-46.1077, 169.2365],
  [-46.1101, 169.2421],
  [-46.1107, 169.2436],
  [-46.1111, 169.2449],
  [-46.1115, 169.2468],
  [-46.1124, 169.2481],
  [-46.1135, 169.2483],
  [-46.1169, 169.2469],
  [-46.1182, 169.2473],
  [-46.1189, 169.2488],
  [-46.1197, 169.2559],
  [-46.1210, 169.2585],
  [-46.1225, 169.2590],
  [-46.1243, 169.2586],
  [-46.1264, 169.2593],
  [-46.1297, 169.2634],
  [-46.1324, 169.2648],
  [-46.1404, 169.2647],
  [-46.1423, 169.2653],
  [-46.1473, 169.2691],
  [-46.1488, 169.2711],
  [-46.1502, 169.2767],
  [-46.1509, 169.2783],
  [-46.1538, 169.2827],
  [-46.1583, 169.2914],
  [-46.1592, 169.2940],
  [-46.1602, 169.2979],
  [-46.1614, 169.3007],
  [-46.1639, 169.3038],
  [-46.1656, 169.3076],
  [-46.1709, 169.3257],
  [-46.1748, 169.3373],
  [-46.1754, 169.3405],
  [-46.1757, 169.3430],
  [-46.1765, 169.3453],
  [-46.1776, 169.3466],
  [-46.1848, 169.3500],
  [-46.1869, 169.3521],
  [-46.1901, 169.3571],
  [-46.1947, 169.3618],
  [-46.1961, 169.3637],
  [-46.1980, 169.3683],
  [-46.1990, 169.3698],
  [-46.2005, 169.3716],
  [-46.2024, 169.3754],
  [-46.2029, 169.3770],
  [-46.2030, 169.3784],
  [-46.2027, 169.3828],
  [-46.2028, 169.3846],
  [-46.2035, 169.3864],
  [-46.2105, 169.3979],
  [-46.2111, 169.3996],
  [-46.2113, 169.4013],
  [-46.2103, 169.4081],
  [-46.2103, 169.4101],
  [-46.2109, 169.4140],
  [-46.2136, 169.4213],
  [-46.2137, 169.4233],
  [-46.2105, 169.4343],
  [-46.2103, 169.4373],
  [-46.2109, 169.4456],
  [-46.2107, 169.4485],
  [-46.2094, 169.4533],
  [-46.2092, 169.4561],
  [-46.2095, 169.4586],
  [-46.2112, 169.4641],
  [-46.2116, 169.4668],
  [-46.2116, 169.4737],
  [-46.2120, 169.4778],
  [-46.2139, 169.4856],
  [-46.2170, 169.5054],
  [-46.2178, 169.5077],
  [-46.2211, 169.5129],
  [-46.2219, 169.5151],
  [-46.2221, 169.5173],
  [-46.2220, 169.5214],
  [-46.2221, 169.5244],
  [-46.2231, 169.5296],
  [-46.2237, 169.5313],
  [-46.2251, 169.5342],
  [-46.2258, 169.5365],
  [-46.2277, 169.5484],
  [-46.2278, 169.5520],
  [-46.2270, 169.5616],
  [-46.2273, 169.5647],
  [-46.2284, 169.5676],
  [-46.2384, 169.5795],
  [-46.2399, 169.5805],
  [-46.2434, 169.5813],
  [-46.2460, 169.5830],
  [-46.2479, 169.5854],
  [-46.2503, 169.5878],
  [-46.2518, 169.5886],
  [-46.2533, 169.5887],
  [-46.2547, 169.5895],
  [-46.2582, 169.5931],
  [-46.2591, 169.5951],
  [-46.2594, 169.5975],
  [-46.2585, 169.6043],
  [-46.2588, 169.6076],
  [-46.2613, 169.6136],
  [-46.2618, 169.6185],
  [-46.2603, 169.6250],
  [-46.2600, 169.6286],
  [-46.2600, 169.6386],
  [-46.2607, 169.6417],
  [-46.2617, 169.6459],
  [-46.2623, 169.6642],
  [-46.2634, 169.6713],
  [-46.2632, 169.6740],
  [-46.2605, 169.6812],
  [-46.2578, 169.6899],
  [-46.2576, 169.6928],
  [-46.2587, 169.6996],
  [-46.2583, 169.7018],
  [-46.2574, 169.7040],
  [-46.2553, 169.7160],
  [-46.2557, 169.7215],
  [-46.2589, 169.7310],
  [-46.2591, 169.7325],
  [-46.2589, 169.7339],
  [-46.2581, 169.7365],
  [-46.2572, 169.7375],
  [-46.2562, 169.7376],
  [-46.2551, 169.7369],
  [-46.2542, 169.7359],
  [-46.2533, 169.7345],
  [-46.2520, 169.7332],
  [-46.2494, 169.7318],
  [-46.2480, 169.7315],
  [-46.2468, 169.7318],
  [-46.2441, 169.7334],
  [-46.2428, 169.7349],
  [-46.2424, 169.7362],
  [-46.2420, 169.7389]
];

const balcluthaMosgiel = [
  [-46.2420, 169.7389],
  [-46.2420, 169.7402],
  [-46.2439, 169.7504],
  [-46.2437, 169.7524],
  [-46.2421, 169.7566],
  [-46.2418, 169.7583],
  [-46.2423, 169.7612],
  [-46.2502, 169.7800],
  [-46.2508, 169.7828],
  [-46.2509, 169.7874],
  [-46.2501, 169.7898],
  [-46.2489, 169.7908],
  [-46.2280, 169.7951],
  [-46.2270, 169.7950],
  [-46.2260, 169.7946],
  [-46.2245, 169.7948],
  [-46.2236, 169.7954],
  [-46.2125, 169.8077],
  [-46.2104, 169.8093],
  [-46.2083, 169.8103],
  [-46.1976, 169.8136],
  [-46.1960, 169.8135],
  [-46.1934, 169.8128],
  [-46.1924, 169.8128],
  [-46.1906, 169.8133],
  [-46.1889, 169.8145],
  [-46.1779, 169.8252],
  [-46.1757, 169.8285],
  [-46.1660, 169.8522],
  [-46.1642, 169.8552],
  [-46.1627, 169.8570],
  [-46.1605, 169.8586],
  [-46.1507, 169.8621],
  [-46.1486, 169.8621],
  [-46.1467, 169.8611],
  [-46.1441, 169.8584],
  [-46.1419, 169.8574],
  [-46.1400, 169.8574],
  [-46.1369, 169.8592],
  [-46.1359, 169.8609],
  [-46.1353, 169.8629],
  [-46.1338, 169.8746],
  [-46.1270, 169.9186],
  [-46.1219, 169.9508],
  [-46.1207, 169.9536],
  [-46.1168, 169.9588],
  [-46.1146, 169.9607],
  [-46.1125, 169.9616],
  [-46.1090, 169.9625],
  [-46.1058, 169.9646],
  [-46.0757, 169.9989],
  [-46.0739, 170.0019],
  [-46.0716, 170.0089],
  [-46.0687, 170.0143],
  [-46.0659, 170.0176],
  [-46.0620, 170.0207],
  [-46.0503, 170.0350],
  [-46.0490, 170.0371],
  [-46.0286, 170.0785],
  [-46.0270, 170.0834],
  [-46.0262, 170.0880],
  [-46.0245, 170.0919],
  [-46.0191, 170.0998],
  [-46.0180, 170.1007],
  [-46.0168, 170.1010],
  [-46.0155, 170.1010],
  [-46.0142, 170.1015],
  [-46.0130, 170.1023],
  [-46.0114, 170.1041],
  [-46.0107, 170.1055],
  [-46.0102, 170.1070],
  [-46.0093, 170.1084],
  [-46.0082, 170.1100],
  [-46.0073, 170.1123],
  [-46.0058, 170.1142],
  [-46.0048, 170.1153],
  [-46.0010, 170.1204],
  [-46.0002, 170.1222],
  [-45.9991, 170.1259],
  [-45.9975, 170.1281],
  [-45.9955, 170.1292],
  [-45.9926, 170.1293],
  [-45.9906, 170.1301],
  [-45.9891, 170.1318],
  [-45.9354, 170.2239],
  [-45.9255, 170.2420],
  [-45.9183, 170.2502],
  [-45.9177, 170.2515],
  [-45.9171, 170.2538],
  [-45.9162, 170.2560],
  [-45.9129, 170.2609],
  [-45.9123, 170.2623],
  [-45.9115, 170.2640],
  [-45.9101, 170.2661],
  [-45.9092, 170.2682],
  [-45.9089, 170.2694],
  [-45.9083, 170.2758],
  [-45.9073, 170.2787],
  [-45.9065, 170.2798],
  [-45.9054, 170.2809],
  [-45.9037, 170.2839],
  [-45.9026, 170.2864],
  [-45.9023, 170.2877],
  [-45.9021, 170.2894],
  [-45.9019, 170.2907],
  [-45.9018, 170.2922],
  [-45.9021, 170.2941],
  [-45.9019, 170.2969],
  [-45.8869, 170.3495]
];

const mosgielAbbotsfordDeviationStart = [
  [-45.8869, 170.3495],
  [-45.8777, 170.3821],
  [-45.8775, 170.3844],
  [-45.8775, 170.3894]
];

const abbotsfordOldStart = [
  [-45.8775, 170.3894],
  [-45.8771, 170.3906],
  [-45.8763, 170.3921],
  [-45.8760, 170.3944],
  [-45.8761, 170.3957],
  [-45.8770, 170.3975]
];

const abbotsfordOldTunnel = [
  [-45.8770, 170.3975],
  [-45.8790, 170.4000]
];

const abbotsfordOldEnd = [
  [-45.8790, 170.4000],
  [-45.8810, 170.4027],
  [-45.8816, 170.4031],
  [-45.8823, 170.4029],
  [-45.8846, 170.4003],
  [-45.8850, 170.4001],
  [-45.8854, 170.4000]
];

const deviationStartAbbotsfordTunnel = [
  [-45.8775, 170.3894],
  [-45.8776, 170.3912],
  [-45.8782, 170.3928]
];

const abbotsfordTunnel = [
  [-45.8782, 170.3928],
  [-45.8847, 170.3996]
];

const abbotsfordTunnelDeviationEnd = [
  [-45.8847, 170.3996],
  [-45.8854, 170.4000]
];

const abbotsfordDeviationStartAbbotsford = [
  [-45.8854, 170.4000],
  [-45.8867, 170.4009],
  [-45.8871, 170.4015],
  [-45.8924, 170.4147],
  [-45.8931, 170.4160],
  [-45.8949, 170.4186],
  [-45.8955, 170.4193],
  [-45.8980, 170.4215]
];

const abbotsfordCaversham = [
  [-45.8980, 170.4215],
  [-45.8992, 170.4233],
  [-45.8999, 170.4252],
  [-45.9001, 170.4271],
  [-45.9001, 170.4373],
  [-45.9000, 170.4398],
  [-45.9002, 170.4414],
  [-45.9006, 170.4431],
  [-45.9012, 170.4446],
  [-45.9016, 170.4462],
  [-45.9016, 170.4474],
  [-45.9013, 170.4486],
  [-45.9006, 170.4499]
];

const cavershamOldStart = [
  [-45.8924, 170.4891],
  [-45.8940, 170.4822],
  [-45.8946, 170.4797],
  [-45.8954, 170.4732],
  [-45.8954, 170.4730]
];

const cavershamOldTunnel = [
  [-45.8949, 170.4597],
  [-45.8954, 170.4730]
];

const cavershamOldEnd = [
  [-45.8949, 170.4597],
  [-45.8948, 170.4585],
  [-45.8949, 170.4576],
  [-45.8953, 170.4563],
  [-45.8986, 170.4518],
  [-45.9006, 170.4499]
];

const cavershamDeviationStart = [
  [-45.8924, 170.4891],
  [-45.8937, 170.4853],
  [-45.8939, 170.4843],
  [-45.8942, 170.4827],
  [-45.8949, 170.4801],
  [-45.8952, 170.4789],
  [-45.8954, 170.4768],
  [-45.8960, 170.4737]
];

const cavershamTunnel = [
  [-45.8960, 170.4737],
  [-45.8991, 170.4561]
];

const cavershamTunnelDeviationEnd = [
  [-45.8991, 170.4561],
  [-45.8991, 170.4560],
  [-45.8992, 170.4548],
  [-45.8995, 170.4528],
  [-45.9002, 170.4507],
  [-45.9006, 170.4499]
];

const cavershamDunedin = [
  [-45.8924, 170.4891],
  [-45.8923, 170.4900],
  [-45.8925, 170.4915],
  [-45.8930, 170.4945],
  [-45.8928, 170.4962],
  [-45.8918, 170.4987],
  [-45.8911, 170.4995],
  [-45.8905, 170.5001],
  [-45.8880, 170.5012],
  [-45.8856, 170.5017],
  [-45.8780, 170.5061],
  [-45.8770, 170.5067],
  [-45.8738, 170.5112]
];

const dunedinPelichetBay = [
  [-45.8738, 170.5112],
  [-45.8719, 170.5138]
];

const pelichetBayOld = [
  [-45.8719, 170.5138],
  [-45.8698, 170.5170],
  [-45.8682, 170.5225],
  [-45.8682, 170.5235],
  [-45.8692, 170.5266],
  [-45.8692, 170.5282],
  [-45.8690, 170.5309],
  [-45.8691, 170.5321],
  [-45.8699, 170.5371],
  [-45.8699, 170.5379],
  [-45.8697, 170.5390],
  [-45.8693, 170.5397],
  [-45.8687, 170.5404],
  [-45.8682, 170.5417],
  [-45.8670, 170.5438],
  [-45.8666, 170.5449],
  [-45.8667, 170.5459],
  [-45.8669, 170.5469],
  [-45.8670, 170.5507],
  [-45.8669, 170.5515],
  [-45.8663, 170.5528],
  [-45.8661, 170.5535],
  [-45.8662, 170.5545],
  [-45.8658, 170.5566],
  [-45.8658, 170.5576],
  [-45.8656, 170.5583],
  [-45.8646, 170.5602],
  [-45.8644, 170.5611],
  [-45.8642, 170.5621],
  [-45.8627, 170.5651],
  [-45.8621, 170.5666],
  [-45.8617, 170.5676],
  [-45.8603, 170.5690],
  [-45.8594, 170.5693],
  [-45.8583, 170.5694],
  [-45.8574, 170.5701],
  [-45.8568, 170.5714],
  [-45.8565, 170.5730],
  [-45.8559, 170.5740],
  [-45.8552, 170.5743],
  [-45.8529, 170.5739],
  [-45.8523, 170.5742],
  [-45.8516, 170.5754],
  [-45.8513, 170.5770],
  [-45.8507, 170.5779],
  [-45.8492, 170.5787]
];

const pelichetBayDeviation = [
  [-45.8719, 170.5138],
  [-45.8710, 170.5162],
  [-45.8709, 170.5180],
  [-45.8707, 170.5288],
  [-45.8701, 170.5376],
  [-45.8684, 170.5460]
];

const pelichetBayStLeonards = [
  [-45.8684, 170.5460],
  [-45.8675, 170.5502],
  [-45.8665, 170.5531],
  [-45.8663, 170.5544],
  [-45.8660, 170.5574],
  [-45.8658, 170.5585],
  [-45.8641, 170.5628],
  [-45.8620, 170.5673],
  [-45.8613, 170.5683],
  [-45.8562, 170.5739],
  [-45.8508, 170.5780],
  [-45.8494, 170.5786],
  [-45.8492, 170.5787]
];

const stLeonardsBlanketBay = [
  [-45.8492, 170.5787],
  [-45.8472, 170.5790],
  [-45.8462, 170.5796],
  [-45.8414, 170.5850],
  [-45.8373, 170.5882]
];

const roseneathTunnelStart = [
  [-45.8373, 170.5882],
  [-45.8368, 170.5883],
  [-45.8364, 170.5883],
  [-45.8334, 170.5880],
  [-45.8324, 170.5885],
  [-45.8319, 170.5892],
  [-45.8316, 170.5900],
  [-45.8313, 170.5915],
  [-45.8309, 170.5923],
  [-45.8303, 170.5928],
  [-45.8283, 170.5934],
  [-45.8279, 170.5935],
  [-45.8275, 170.5941],
  [-45.8268, 170.5956]
];

const roseneathTunnel = [
  [-45.8268, 170.5956],
  [-45.8261, 170.5975]
];

const roseneathTunnelEnd = [
  [-45.8261, 170.5975],
  [-45.8249, 170.6009]
];

const blanketBayTunnelStart = [
  [-45.8373, 170.5882],
  [-45.8353, 170.5896],
  [-45.8339, 170.5909],
  [-45.8280, 170.5976]
];

const blanketBayTunnel = [
  [-45.8280, 170.5976],
  [-45.8256, 170.6001]
];

const blanketBayTunnelEnd = [
  [-45.8256, 170.6001],
  [-45.8249, 170.6009]
];

const sawyersBayJunctionPortChalmers = [
  [-45.8249, 170.6009],
  [-45.8211, 170.6052]
];

const portChalmersMansfordTunnel = [
  [-45.8211, 170.6052],
  [-45.8202, 170.6069],
  [-45.8200, 170.6083],
  [-45.8198, 170.6091],
  [-45.8195, 170.6096],
  [-45.8191, 170.6102],
  [-45.8188, 170.6112],
  [-45.8188, 170.6132],
  [-45.8185, 170.6144],
  [-45.8159, 170.6188],
  [-45.8150, 170.6198],
  [-45.8124, 170.6214],
  [-45.8117, 170.6216]
];

const mansfordTunnel = [
  [-45.8117, 170.6216],
  [-45.8111, 170.6214],
  [-45.8107, 170.6209],
  [-45.8103, 170.6202]
];

const mansfordTunnelMacgregorTunnel = [
  [-45.8103, 170.6202],
  [-45.8100, 170.6200],
  [-45.8093, 170.6197],
  [-45.8070, 170.6198],
  [-45.8063, 170.6202],
  [-45.8055, 170.6211],
  [-45.8048, 170.6216],
  [-45.8044, 170.6222],
  [-45.8042, 170.6227],
  [-45.8041, 170.6237],
  [-45.8039, 170.6245],
  [-45.8033, 170.6250],
  [-45.8026, 170.6251],
  [-45.8020, 170.6248],
  [-45.8016, 170.6247],
  [-45.8010, 170.6247],
  [-45.8004, 170.6244],
  [-45.8000, 170.6236],
  [-45.7999, 170.6226],
  [-45.8000, 170.6216],
  [-45.7999, 170.6207]
];

const macgregorTunnel = [
  [-45.7999, 170.6207],
  [-45.7994, 170.6199]
];

const macgregorTunnelMihiwakaTunnel = [
  [-45.7994, 170.6199],
  [-45.7989, 170.6195],
  [-45.7981, 170.6191],
  [-45.7965, 170.6182],
  [-45.7958, 170.6182],
  [-45.7952, 170.6188],
  [-45.7947, 170.6198],
  [-45.7942, 170.6204],
  [-45.7935, 170.6206],
  [-45.7928, 170.6201],
  [-45.7919, 170.6194],
  [-45.7909, 170.6192]
];

const mihiwakaTunnel = [
  [-45.7909, 170.6192],
  [-45.7788, 170.6202]
];

const mihiwakaCliffs = [
  [-45.7788, 170.6202],
  [-45.7784, 170.6203],
  [-45.7776, 170.6200],
  [-45.7767, 170.6195],
  [-45.7761, 170.6194],
  [-45.7755, 170.6198],
  [-45.7745, 170.6210],
  [-45.7737, 170.6218],
  [-45.7719, 170.6225],
  [-45.7710, 170.6223],
  [-45.7690, 170.6209],
  [-45.7681, 170.6206],
  [-45.7672, 170.6204],
  [-45.7654, 170.6196],
  [-45.7649, 170.6190],
  [-45.7644, 170.6183],
  [-45.7638, 170.6181],
  [-45.7625, 170.6182],
  [-45.7618, 170.6179],
  [-45.7614, 170.6173],
  [-45.7612, 170.6167],
  [-45.7610, 170.6157],
  [-45.7605, 170.6147],
  [-45.7597, 170.6144],
  [-45.7590, 170.6148],
  [-45.7584, 170.6154],
  [-45.7580, 170.6157],
  [-45.7560, 170.6162],
  [-45.7552, 170.6158],
  [-45.7547, 170.6148],
  [-45.7546, 170.6134],
  [-45.7541, 170.6124],
  [-45.7536, 170.6120],
  [-45.7529, 170.6120],
  [-45.7521, 170.6124],
  [-45.7489, 170.6130],
  [-45.7481, 170.6128],
  [-45.7469, 170.6120],
  [-45.7463, 170.6119],
  [-45.7447, 170.6123],
  [-45.7432, 170.6122],
  [-45.7425, 170.6123],
  [-45.7406, 170.6135],
  [-45.7400, 170.6140],
  [-45.7397, 170.6141],
  [-45.7388, 170.6142],
  [-45.7384, 170.6141],
  [-45.7379, 170.6139],
  [-45.7373, 170.6138],
  [-45.7367, 170.6133],
  [-45.7363, 170.6124],
  [-45.7362, 170.6116],
  [-45.7361, 170.6114]
];

const cliffsOld = [
  [-45.7361, 170.6114],
  [-45.7357, 170.6109],
  [-45.7350, 170.6104],
  [-45.7347, 170.6101],
  [-45.7343, 170.6089],
  [-45.7340, 170.6076]
];

const cliffsDeviationStart = [
  [-45.7361, 170.6114],
  [-45.7360, 170.6110]
];

const cliffsTunnel = [
  [-45.7360, 170.6110],
  [-45.7343, 170.6082]
];

const cliffsDeviationEnd = [
  [-45.7343, 170.6082],
  [-45.7340, 170.6076]
];

const cliffsPuketeraki = [
  [-45.7340, 170.6076],
  [-45.7339, 170.6072],
  [-45.7336, 170.6050],
  [-45.7336, 170.6034],
  [-45.7338, 170.6025],
  [-45.7343, 170.6017],
  [-45.7368, 170.5996],
  [-45.7381, 170.5992],
  [-45.7401, 170.5999],
  [-45.7419, 170.6000],
  [-45.7429, 170.5994],
  [-45.7434, 170.5984],
  [-45.7434, 170.5969],
  [-45.7427, 170.5944],
  [-45.7425, 170.5934],
  [-45.7424, 170.5901],
  [-45.7427, 170.5889],
  [-45.7434, 170.5883],
  [-45.7441, 170.5881],
  [-45.7448, 170.5875],
  [-45.7451, 170.5862],
  [-45.7449, 170.5847],
  [-45.7450, 170.5838],
  [-45.7455, 170.5828],
  [-45.7465, 170.5819],
  [-45.7468, 170.5812],
  [-45.7470, 170.5804],
  [-45.7464, 170.5710],
  [-45.7457, 170.5692],
  [-45.7446, 170.5684],
  [-45.7407, 170.5679],
  [-45.7394, 170.5681],
  [-45.7337, 170.5702],
  [-45.7321, 170.5703],
  [-45.7275, 170.5693],
  [-45.7264, 170.5694],
  [-45.7233, 170.5709],
  [-45.7223, 170.5711],
  [-45.7202, 170.5708],
  [-45.7197, 170.5708],
  [-45.7177, 170.5720],
  [-45.7168, 170.5723],
  [-45.7161, 170.5729],
  [-45.7157, 170.5737],
  [-45.7155, 170.5750],
  [-45.7152, 170.5757],
  [-45.7148, 170.5765],
  [-45.7145, 170.5773],
  [-45.7142, 170.5792],
  [-45.7135, 170.5812],
  [-45.7107, 170.5853],
  [-45.7104, 170.5863],
  [-45.7103, 170.5868],
  [-45.7103, 170.5885],
  [-45.7101, 170.5899],
  [-45.7092, 170.5909],
  [-45.7082, 170.5911],
  [-45.7074, 170.5918],
  [-45.7059, 170.5944],
  [-45.7052, 170.5960],
  [-45.7044, 170.5971],
  [-45.7026, 170.5984],
  [-45.7018, 170.5994],
  [-45.7016, 170.6005],
  [-45.7015, 170.6016],
  [-45.7012, 170.6024],
  [-45.7007, 170.6030],
  [-45.6996, 170.6037],
  [-45.6985, 170.6047],
  [-45.6979, 170.6050],
  [-45.6972, 170.6051],
  [-45.6966, 170.6053],
  [-45.6960, 170.6059],
  [-45.6952, 170.6062],
  [-45.6944, 170.6060],
  [-45.6937, 170.6055],
  [-45.6927, 170.6053],
  [-45.6919, 170.6056],
  [-45.6910, 170.6055],
  [-45.6898, 170.6048],
  [-45.6889, 170.6034],
  [-45.6879, 170.6030],
  [-45.6871, 170.6035],
  [-45.6867, 170.6046],
  [-45.6868, 170.6056],
  [-45.6875, 170.6074],
  [-45.6876, 170.6086],
  [-45.6872, 170.6098],
  [-45.6862, 170.6107],
  [-45.6856, 170.6115],
  [-45.6846, 170.6137],
  [-45.6844, 170.6148],
  [-45.6842, 170.6164],
  [-45.6843, 170.6174],
  [-45.6841, 170.6186],
  [-45.6836, 170.6192],
  [-45.6827, 170.6200],
  [-45.6805, 170.6233],
  [-45.6798, 170.6251],
  [-45.6792, 170.6263],
  [-45.6780, 170.6273],
  [-45.6775, 170.6281],
  [-45.6772, 170.6292],
  [-45.6766, 170.6302],
  [-45.6758, 170.6308],
  [-45.6750, 170.6318],
  [-45.6741, 170.6332],
  [-45.6736, 170.6339],
  [-45.6731, 170.6344],
  [-45.6727, 170.6351],
  [-45.6727, 170.6359],
  [-45.6727, 170.6369],
  [-45.6724, 170.6380],
  [-45.6719, 170.6387],
  [-45.6713, 170.6393],
  [-45.6709, 170.6401],
  [-45.6709, 170.6409],
  [-45.6710, 170.6419],
  [-45.6706, 170.6429],
  [-45.6698, 170.6438],
  [-45.6694, 170.6444],
  [-45.6690, 170.6453],
  [-45.6683, 170.6462],
  [-45.6677, 170.6462],
  [-45.6670, 170.6458],
  [-45.6665, 170.6454],
  [-45.6658, 170.6452],
  [-45.6650, 170.6456],
  [-45.6644, 170.6465],
  [-45.6641, 170.6472],
  [-45.6635, 170.6480],
  [-45.6629, 170.6482],
  [-45.6620, 170.6483],
  [-45.6614, 170.6485],
  [-45.6609, 170.6490],
  [-45.6605, 170.6497],
  [-45.6599, 170.6503],
  [-45.6594, 170.6506]
];

const puketerakiTunnelStart = [
  [-45.6594, 170.6506],
  [-45.6590, 170.6507],
  [-45.6587, 170.6506]
];

const puketerakiTunnel = [
  [-45.6587, 170.6506],
  [-45.6573, 170.6501]
];

const puketerakiTunnelEnd = [
  [-45.6573, 170.6501],
  [-45.6567, 170.6499],
  [-45.6561, 170.6495]
];

const puketerakiDeviation = [
  [-45.6594, 170.6506],
  [-45.6585, 170.6512],
  [-45.6579, 170.6513],
  [-45.6572, 170.6509],
  [-45.6561, 170.6495]
];

const puketerakiOtepopoTunnel = [
  [-45.6561, 170.6495],
  [-45.6555, 170.6487],
  [-45.6545, 170.6481],
  [-45.6534, 170.6480],
  [-45.6524, 170.6476],
  [-45.6515, 170.6466],
  [-45.6512, 170.6454],
  [-45.6513, 170.6442],
  [-45.6517, 170.6432],
  [-45.6517, 170.6420],
  [-45.6512, 170.6409],
  [-45.6504, 170.6406],
  [-45.6497, 170.6408],
  [-45.6488, 170.6419],
  [-45.6485, 170.6425],
  [-45.6481, 170.6434],
  [-45.6474, 170.6442],
  [-45.6466, 170.6442],
  [-45.6459, 170.6440],
  [-45.6453, 170.6440],
  [-45.6447, 170.6440],
  [-45.6440, 170.6438],
  [-45.6434, 170.6430],
  [-45.6420, 170.6404],
  [-45.6412, 170.6397],
  [-45.6403, 170.6396],
  [-45.6395, 170.6399],
  [-45.6384, 170.6398],
  [-45.6374, 170.6391],
  [-45.6358, 170.6373],
  [-45.6346, 170.6367],
  [-45.6333, 170.6371],
  [-45.6324, 170.6383],
  [-45.6319, 170.6401],
  [-45.6310, 170.6418],
  [-45.6294, 170.6434],
  [-45.6273, 170.6440],
  [-45.6242, 170.6439],
  [-45.6232, 170.6442],
  [-45.6224, 170.6449],
  [-45.6158, 170.6541],
  [-45.6117, 170.6596],
  [-45.6054, 170.6651],
  [-45.6043, 170.6665],
  [-45.6030, 170.6690],
  [-45.6021, 170.6701],
  [-45.5963, 170.6745],
  [-45.5953, 170.6759],
  [-45.5937, 170.6794],
  [-45.5928, 170.6805],
  [-45.5920, 170.6808],
  [-45.5907, 170.6808],
  [-45.5893, 170.6815],
  [-45.5789, 170.6922],
  [-45.5777, 170.6931],
  [-45.5765, 170.6935],
  [-45.5758, 170.6934],
  [-45.5753, 170.6929],
  [-45.5748, 170.6921],
  [-45.5739, 170.6916],
  [-45.5731, 170.6919],
  [-45.5707, 170.6942],
  [-45.5696, 170.6947],
  [-45.5682, 170.6947],
  [-45.5672, 170.6948],
  [-45.5662, 170.6946],
  [-45.5652, 170.6942],
  [-45.5637, 170.6943],
  [-45.5624, 170.6949],
  [-45.5600, 170.6975],
  [-45.5590, 170.6982],
  [-45.5566, 170.7010],
  [-45.5561, 170.7018],
  [-45.5556, 170.7034],
  [-45.5540, 170.7054],
  [-45.5509, 170.7109],
  [-45.5494, 170.7120],
  [-45.5479, 170.7120],
  [-45.5464, 170.7110],
  [-45.5449, 170.7108],
  [-45.5414, 170.7121],
  [-45.5397, 170.7121],
  [-45.5386, 170.7113],
  [-45.5319, 170.7044],
  [-45.5307, 170.7038],
  [-45.5295, 170.7036],
  [-45.5279, 170.7023],
  [-45.5267, 170.6998],
  [-45.5256, 170.6986],
  [-45.5236, 170.6977],
  [-45.5180, 170.6977],
  [-45.5158, 170.6975],
  [-45.5150, 170.6979],
  [-45.5140, 170.6990],
  [-45.5123, 170.6999],
  [-45.5062, 170.6997],
  [-45.5047, 170.7005],
  [-45.5033, 170.7025],
  [-45.5029, 170.7040],
  [-45.5027, 170.7057],
  [-45.5022, 170.7072],
  [-45.5002, 170.7109],
  [-45.4990, 170.7119],
  [-45.4968, 170.7127],
  [-45.4959, 170.7134],
  [-45.4950, 170.7141],
  [-45.4940, 170.7146],
  [-45.4890, 170.7159],
  [-45.4856, 170.7160],
  [-45.4846, 170.7164],
  [-45.4838, 170.7171],
  [-45.4820, 170.7201],
  [-45.4792, 170.7267],
  [-45.4783, 170.7329],
  [-45.4782, 170.7662],
  [-45.4777, 170.7682],
  [-45.4766, 170.7700],
  [-45.4693, 170.7750],
  [-45.4681, 170.7765],
  [-45.4677, 170.7783],
  [-45.4678, 170.7798],
  [-45.4680, 170.7810],
  [-45.4679, 170.7831],
  [-45.4669, 170.7854],
  [-45.4666, 170.7876],
  [-45.4668, 170.7895],
  [-45.4668, 170.7912],
  [-45.4654, 170.7951],
  [-45.4646, 170.7964],
  [-45.4630, 170.7979],
  [-45.4618, 170.8001],
  [-45.4587, 170.8040],
  [-45.4581, 170.8051],
  [-45.4577, 170.8062],
  [-45.4568, 170.8071],
  [-45.4546, 170.8081],
  [-45.4535, 170.8081],
  [-45.4528, 170.8079],
  [-45.4522, 170.8079],
  [-45.4496, 170.8085],
  [-45.4488, 170.8086],
  [-45.4482, 170.8087],
  [-45.4468, 170.8094],
  [-45.4454, 170.8098],
  [-45.4443, 170.8099],
  [-45.4400, 170.8111],
  [-45.4388, 170.8116],
  [-45.4361, 170.8132],
  [-45.4346, 170.8137],
  [-45.4331, 170.8138],
  [-45.4321, 170.8142],
  [-45.4282, 170.8161],
  [-45.4238, 170.8180],
  [-45.4188, 170.8208],
  [-45.4131, 170.8250],
  [-45.4122, 170.8259],
  [-45.4110, 170.8277],
  [-45.4100, 170.8290],
  [-45.3953, 170.8407],
  [-45.3939, 170.8424],
  [-45.3899, 170.8500],
  [-45.3889, 170.8509],
  [-45.3876, 170.8508],
  [-45.3866, 170.8499],
  [-45.3848, 170.8461],
  [-45.3840, 170.8434],
  [-45.3832, 170.8420],
  [-45.3807, 170.8397],
  [-45.3797, 170.8379],
  [-45.3791, 170.8360],
  [-45.3768, 170.8309],
  [-45.3749, 170.8290],
  [-45.3717, 170.8283],
  [-45.3705, 170.8283],
  [-45.3688, 170.8285],
  [-45.3678, 170.8284],
  [-45.3662, 170.8282],
  [-45.3619, 170.8281],
  [-45.3587, 170.8273],
  [-45.3575, 170.8266],
  [-45.3557, 170.8252],
  [-45.3500, 170.8227],
  [-45.3465, 170.8220],
  [-45.3406, 170.8229],
  [-45.3393, 170.8229],
  [-45.3337, 170.8218],
  [-45.3327, 170.8218],
  [-45.3263, 170.8223],
  [-45.3249, 170.8221],
  [-45.3192, 170.8192],
  [-45.3073, 170.8152],
  [-45.3053, 170.8151],
  [-45.2961, 170.8185],
  [-45.2950, 170.8191],
  [-45.2938, 170.8202],
  [-45.2926, 170.8205],
  [-45.2851, 170.8201],
  [-45.2831, 170.8195],
  [-45.2805, 170.8170],
  [-45.2681, 170.8025],
  [-45.2665, 170.8012],
  [-45.2549, 170.7967],
  [-45.2515, 170.7961],
  [-45.2486, 170.7948],
  [-45.2469, 170.7928],
  [-45.2457, 170.7896],
  [-45.2450, 170.7869],
  [-45.2424, 170.7805],
  [-45.2412, 170.7791],
  [-45.2388, 170.7771],
  [-45.2375, 170.7761],
  [-45.2366, 170.7759],
  [-45.2343, 170.7768],
  [-45.2336, 170.7776],
  [-45.2330, 170.7777],
  [-45.2329, 170.7777]
];

const otepopoTunnel = [
  [-45.2329, 170.7777],
  [-45.2305, 170.7771]
];

const otepopoTunnelOamaru = [
  [-45.2305, 170.7771],
  [-45.2290, 170.7766],
  [-45.2269, 170.7755],
  [-45.2258, 170.7754],
  [-45.2246, 170.7759],
  [-45.2110, 170.7873],
  [-45.1937, 170.7967],
  [-45.1918, 170.7986],
  [-45.1763, 170.8225],
  [-45.1647, 170.8347],
  [-45.1580, 170.8432],
  [-45.1550, 170.8464],
  [-45.1520, 170.8485],
  [-45.1505, 170.8507],
  [-45.1465, 170.8627],
  [-45.1444, 170.8664],
  [-45.1435, 170.8673],
  [-45.1425, 170.8680],
  [-45.1415, 170.8693],
  [-45.1386, 170.8757],
  [-45.1354, 170.8892],
  [-45.1298, 170.8996],
  [-45.1216, 170.9067],
  [-45.1044, 170.9277],
  [-45.1032, 170.9295],
  [-45.1017, 170.9331],
  [-45.0989, 170.9375],
  [-45.0975, 170.9387],
  [-45.0962, 170.9400],
  [-45.0958, 170.9412],
  [-45.0961, 170.9429],
  [-45.0978, 170.9462],
  [-45.0983, 170.9485],
  [-45.0982, 170.9502],
  [-45.0977, 170.9516],
  [-45.0976, 170.9529],
  [-45.0979, 170.9543],
  [-45.0986, 170.9554],
  [-45.0990, 170.9567],
  [-45.0994, 170.9602],
  [-45.0994, 170.9644],
  [-45.0998, 170.9656],
  [-45.1009, 170.9669],
  [-45.1018, 170.9691],
  [-45.1018, 170.9702],
  [-45.1015, 170.9712],
  [-45.1008, 170.9719]
];

const oamaruWharf = [
  [-45.0966, 170.9748],
  [-45.0985, 170.9731],
  [-45.0998, 170.9723],
  [-45.1008, 170.9719],
  [-45.1044, 170.9710],
  [-45.1055, 170.9707],
  [-45.1063, 170.9705],
  [-45.1070, 170.9706],
  [-45.1085, 170.9720],
  [-45.1088, 170.9727],
  [-45.1092, 170.9747],
  [-45.1094, 170.9755],
  [-45.1097, 170.9775],
  [-45.1098, 170.9779],
  [-45.1099, 170.9786],
];

const oamaruBreakwater = [
  [-45.1055, 170.9707],
  [-45.1054, 170.9709],
  [-45.1050, 170.9717],
  [-45.1048, 170.9724],
  [-45.1060, 170.9779],
];

const oamaruTimaru = [
  [-45.0966, 170.9748],
  [-45.0545, 171.0109],
  [-45.0376, 171.0268],
  [-45.0327, 171.0302],
  [-44.9364, 171.0983],
  [-44.9349, 171.0991],
  [-44.9022, 171.1080],
  [-44.8165, 171.1107],
  [-44.7992, 171.1114],
  [-44.7865, 171.1142],
  [-44.7845, 171.1144],
  [-44.7342, 171.1261],
  [-44.7268, 171.1279],
  [-44.6819, 171.1377],
  [-44.5951, 171.1518],
  [-44.5934, 171.1525],
  [-44.5502, 171.1770],
  [-44.5187, 171.1987],
  [-44.5177, 171.1993],
  [-44.5038, 171.2034],
  [-44.5012, 171.2047],
  [-44.4964, 171.2085],
  [-44.4949, 171.2102],
  [-44.4874, 171.2219],
  [-44.4814, 171.2278],
  [-44.4774, 171.2305],
  [-44.4596, 171.2466],
  [-44.4528, 171.2519],
  [-44.4510, 171.2526],
  [-44.4487, 171.2527],
  [-44.4476, 171.2530],
  [-44.4396, 171.2567],
  [-44.4384, 171.2568],
  [-44.4371, 171.2566],
  [-44.4317, 171.2577],
  [-44.4309, 171.2577],
  [-44.4302, 171.2572],
  [-44.4290, 171.2560],
  [-44.4282, 171.2556],
  [-44.4270, 171.2552],
  [-44.4261, 171.2553],
  [-44.4251, 171.2556],
  [-44.4157, 171.2620],
  [-44.4148, 171.2622],
  [-44.4140, 171.2620],
  [-44.4093, 171.2594],
  [-44.4082, 171.2590],
  [-44.4040, 171.2582],
  [-44.4032, 171.2581],
  [-44.3999, 171.2582],
  [-44.3992, 171.2580],
  [-44.3974, 171.2571],
  [-44.3951, 171.2554]
];

const timaruTemuka = [
  [-44.3951, 171.2554],
  [-44.3937, 171.2538],
  [-44.3931, 171.2529],
  [-44.3928, 171.2516],
  [-44.3927, 171.2503],
  [-44.3924, 171.2491],
  [-44.3912, 171.2467],
  [-44.3907, 171.2461],
  [-44.3899, 171.2458],
  [-44.3879, 171.2455],
  [-44.3866, 171.2456],
  [-44.3851, 171.2461],
  [-44.3845, 171.2461],
  [-44.3836, 171.2457],
  [-44.3809, 171.2441],
  [-44.3797, 171.2438],
  [-44.3784, 171.2439],
  [-44.3720, 171.2468],
  [-44.3710, 171.2469],
  [-44.3695, 171.2464],
  [-44.3641, 171.2436],
  [-44.3605, 171.2433],
  [-44.3557, 171.2449],
  [-44.3532, 171.2457],
  [-44.2497, 171.2801],
  [-44.2484, 171.2803],
  [-44.2418, 171.2803]
];

const temukaRakaia = [
  [-44.2418, 171.2803],
  [-44.1800, 171.2815],
  [-44.1467, 171.2917],
  [-44.0987, 171.3196],
  [-44.0948, 171.3234],
  [-44.0625, 171.3778],
  [-44.0456, 171.4154],
  [-44.0449, 171.4173],
  [-44.0115, 171.5501],
  [-44.0098, 171.5545],
  [-43.9215, 171.7167],
  [-43.9056, 171.7452],
  [-43.8935, 171.7640],
  [-43.8916, 171.7666],
  [-43.7586, 172.0168],
  [-43.7577, 172.0185]
];

const rakaiaSelwyn = [
  [-43.7577, 172.0185],
  [-43.7554, 172.0227],
  [-43.7532, 172.0261],
  [-43.7013, 172.0877],
  [-43.6985, 172.0927],
  [-43.6636, 172.1855],
  [-43.6631, 172.1869],
  [-43.6485, 172.2257]
];

const selwynRolleston = [
  [-43.6485, 172.2257],
  [-43.5927, 172.3729], //POINT
  [-43.5906, 172.3784],
  [-43.5891, 172.3824]
];

const rollestonIslington = [
  [-43.5891, 172.3824],
  [-43.5844, 172.3949], //POINT
  [-43.5465, 172.4945]
];

//first christchirch station building
const islingtonChirstchurch = [
  [-43.5465, 172.4945],
  [-43.5423, 172.5057],
  [-43.5414, 172.5089],
  [-43.5410, 172.5132],
  [-43.5410, 172.5283],
  [-43.5410, 172.5534],
  [-43.5411, 172.5818],
  [-43.5417, 172.5864],
  [-43.5416, 172.5887],
  [-43.5411, 172.5911],
  [-43.5410, 172.5927],
  [-43.5410, 172.6051],
  [-43.5409, 172.6075],
  [-43.5409, 172.6129],
  [-43.5410, 172.6428]
];

export const mainSouthLine: Line = {
  name: 'Main South Line (NZ)',
  state: 'NZ',
  segments: [
    {
      segments: [invercargillWoodlands],
      history: {
        opened: {
          date: '1874-02-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [woodlandsGore],
      history: {
        opened: {
          date: '1875-08-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [goreBalclutha],
      history: {
        opened: {
          date: '1879-01-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [balcluthaMosgiel],
      history: {
        opened: {
          date: '1875-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        mosgielAbbotsfordDeviationStart,
        {
          date: '1914-01-01',
          original: [
            abbotsfordOldStart,
            {
              name: 'Abbotsford Tunnel (Old)',
              segment: abbotsfordOldTunnel,
              type: 'tunnel'
            },
            abbotsfordOldEnd
          ],
          deviation: [
            deviationStartAbbotsfordTunnel,
            {
              name: 'Abbotsford Tunnel',
              segment: abbotsfordTunnel,
              type: 'tunnel'
            },
            abbotsfordTunnelDeviationEnd
          ]
        },
        abbotsfordDeviationStartAbbotsford
      ],
      history: {
        opened: {
          date: '1875-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1914-01-01',
          tracks: 2
        }, {
          date: '1986-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [abbotsfordCaversham],
      history: {
        opened: {
          date: '1874-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          tracks: 2
        }, {
          date: '1986-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [stLeonardsBlanketBay],
      history: {
        opened: {
          date: '1873-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        {
          date: '1910-01-01',
          original: [
            cavershamOldStart,
            {
              name: 'Caversham Tunnel (Old)',
              segment: cavershamOldTunnel,
              type: 'tunnel'
            },
            cavershamOldEnd
          ],
          deviation: [
            cavershamDeviationStart,
            {
              name: 'Caversham Tunnel',
              segment: cavershamTunnel,
              type: 'tunnel'
            },
            cavershamTunnelDeviationEnd
          ]
        },
        cavershamDunedin
      ],
      history: {
        opened: {
          date: '1874-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          tracks: 2
        }, {
          date: '1988-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [dunedinPelichetBay],
      history: {
        opened: {
          date: '1873-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [{
        date: '1925-01-01',
        original: [pelichetBayOld],
        deviation: [pelichetBayDeviation]
      }],
      history: {
        opened: {
          date: '1873-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1925-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [pelichetBayStLeonards],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-01-01',
          tracks: 1
        }]
      }
    },
    {
      segments: [
        {
          date: '1948-01-01',
          original: [
            roseneathTunnelStart,
            {
              name: 'Roseneath Tunnel',
              segment: roseneathTunnel,
              type: 'tunnel'
            },
            roseneathTunnelEnd
          ],
          deviation: [
            blanketBayTunnelStart,
            {
              name: 'Blanket Bay Tunnel',
              segment: blanketBayTunnel,
              type: 'tunnel'
            },
            blanketBayTunnelEnd
          ]
        },
        sawyersBayJunctionPortChalmers
      ],
      history: {
        opened: {
          date: '1873-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        portChalmersMansfordTunnel,
        {
          name: 'MansfordTunnel',
          segment: mansfordTunnel,
          type: 'tunnel'
        },
        mansfordTunnelMacgregorTunnel,
        {
          date: '1971-01-01',
          original: [{
            name: 'Macgregor Tunnel',
            segment: macgregorTunnel,
            type: 'tunnel'
          }],
          deviation: [macgregorTunnel]
        },
        macgregorTunnelMihiwakaTunnel,
        {
          name: 'Mihiwaka Tunnel',
          segment: mihiwakaTunnel,
          type: 'tunnel'
        },
        mihiwakaCliffs,
        {
          date: '1891-01-01',
          original: [cliffsOld],
          deviation: [
            cliffsDeviationStart,
            {
              name: 'Cliffs Tunnel',
              segment: cliffsTunnel,
              type: 'tunnel'
            },
            cliffsDeviationEnd
          ]
        },
        cliffsPuketeraki,
        {
          date: '1936-01-01',
          original: [
            puketerakiTunnelStart,
            {
              name: 'Puketeraki Tunnel',
              segment: puketerakiTunnel,
              type: 'tunnel'
            },
            puketerakiTunnelEnd
          ],
          deviation: [puketerakiDeviation]
        },
        puketerakiOtepopoTunnel,
        {
          name: 'Otepopo Tunnel',
          segment: otepopoTunnel,
          type: 'tunnel'
        },
        otepopoTunnelOamaru
      ],
      history: {
        opened: {
          date: '1878-09-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [oamaruBreakwater, oamaruWharf],
      history: {
        opened: {
          date: '1875-09-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [oamaruTimaru],
      history: {
        opened: {
          date: '1877-02-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [timaruTemuka],
      history: {
        opened: {
          date: '1874-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [temukaRakaia],
      history: {
        opened: {
          date: '1876-02-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [rakaiaSelwyn],
      history: {
        opened: {
          date: '1873-06-02',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-03-06',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [selwynRolleston],
      history: {
        opened: {
          date: '1866-10-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-03-06',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [rollestonIslington],
      history: {
        opened: {
          date: '1866-10-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-03-06',
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [islingtonChirstchurch],
      history: {
        opened: {
          date: '1866-10-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1876-03-06',
          gauge: 'narrow'
        }, {
          date: '1878-01-01',
          tracks: 2
        }]
      }
    }
  ]
}
