import { Line } from "../../../../trackTypes";

const sturtStreet = [
  [-37.82041, 144.96878],
  [-37.82054, 144.96875],
  [-37.82063, 144.96865],
  [-37.82072, 144.96847],
  [-37.82090, 144.96826],
  [-37.82113, 144.96807],
  [-37.82358, 144.96733]
];

const southbankBvld = [
  [-37.82312, 144.97002],
  [-37.82328, 144.96999],
  [-37.82339, 144.96985],
  [-37.82366, 144.96892],
  [-37.82367, 144.96860],
  [-37.82347, 144.96765],
  [-37.82352, 144.96743],
  [-37.82358, 144.96733]
];

const line = [
  [-37.82358, 144.96733],
  [-37.83059, 144.96522],
  [-37.83097, 144.96509],
  [-37.83165, 144.96489],
  [-37.83173, 144.96488],
  [-37.83184, 144.96490],
  [-37.83289, 144.96539],
  [-37.83310, 144.96538],
  [-37.83420, 144.96481],
  [-37.83434, 144.96464],
  [-37.83516, 144.96180],
  [-37.83524, 144.96170],
  [-37.83536, 144.96167],
  [-37.83912, 144.96339],
  [-37.83930, 144.96334],
  [-37.84033, 144.96170],
  [-37.84098, 144.96083],
  [-37.84168, 144.96006],
  [-37.84228, 144.95944],
  [-37.84239, 144.95940],
  [-37.84251, 144.95941],
  [-37.84390, 144.96024],
  [-37.84402, 144.96026],
  [-37.84416, 144.96020],
  [-37.84936, 144.95590],
  [-37.84949, 144.95588],
  [-37.84958, 144.95595],
  [-37.85356, 144.96360],
  [-37.85382, 144.96388],
  [-37.85733, 144.97065]
];

const parkStreet = [
  [-37.85733, 144.97065],
  [-37.85762, 144.97118],
  [-37.85778, 144.97139],
  [-37.86116, 144.97457],
  [-37.86123, 144.97472],
  [-37.86122, 144.97491]
];

const beaconsfieldParade = [
  [-37.85733, 144.97065],
  [-37.85822, 144.96988],
  [-37.85846, 144.97025],
  [-37.86190, 144.97351],
  [-37.86192, 144.97362],
  [-37.86191, 144.97375]
];

const connector = [
  [-37.83243, 144.97192],
  [-37.83236, 144.97180],
  [-37.83233, 144.97160],
  [-37.83338, 144.96797],
  [-37.83337, 144.96780],
  [-37.83329, 144.96763],
  [-37.83094, 144.96532],
  [-37.83076, 144.96524],
  [-37.83059, 144.96522]
];

const millsStreetSiding = [
  [-37.84936, 144.95590],
  [-37.85016, 144.95525]
];

const southMelbourneDepot = [
  [-37.83131, 144.96568],
  [-37.83136, 144.96581],
  [-37.83140, 144.96612],
  [-37.83144, 144.96626],
  [-37.83162, 144.96647],
  [-37.83171, 144.96658],
  [-37.83173, 144.96671],
  [-37.83138, 144.96819]
];

export const south7: Line = {
  name: 'South7',
  state: 'VIC',
  segments: [
    {
      segments: [
        {
          date: '1970-01-01',
          original: [sturtStreet],
          deviation: [southbankBvld]
        },
        line,
        {
          date: '1959-01-01',
          original: [beaconsfieldParade],
          deviation: [parkStreet]
        },
        connector,
        millsStreetSiding
      ],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [southMelbourneDepot],
      history: {
        opened: {
          date: '1925-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1997-02-08',
          status: 'closed'
        }]
      }
    }
  ]
};
