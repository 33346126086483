import { Line } from "../../../trackTypes";

const huntlyAwaroaJunction = [
  [-37.5618, 175.1597],
  [-37.5612, 175.1593],
  [-37.5609, 175.1589],
  [-37.5608, 175.1584],
  [-37.5607, 175.1543],
  [-37.5612, 175.1481],
  [-37.5614, 175.1470],
  [-37.5618, 175.1463],
  [-37.5627, 175.1448]
];

const awaroaJunctionAwaroa = [
  [-37.5627, 175.1448],
  [-37.5630, 175.1444],
  [-37.5635, 175.1443],
  [-37.5656, 175.1450],
  [-37.5671, 175.1462],
  [-37.5684, 175.1468]
];

const awaroaJunctionRotowaro = [
  [-37.5627, 175.1448],
  [-37.5628, 175.1442],
  [-37.5628, 175.1435],
  [-37.5626, 175.1429],
  [-37.5621, 175.1419],
  [-37.5618, 175.1407],
  [-37.5619, 175.1393],
  [-37.5624, 175.1381],
  [-37.5632, 175.1372],
  [-37.5641, 175.1366],
  [-37.5648, 175.1355],
  [-37.5653, 175.1342],
  [-37.5659, 175.1334],
  [-37.5664, 175.1332],
  [-37.5672, 175.1332],
  [-37.5695, 175.1339],
  [-37.5706, 175.1340],
  [-37.5728, 175.1334],
  [-37.5739, 175.1333],
  [-37.5746, 175.1332],
  [-37.5752, 175.1327],
  [-37.5756, 175.1320],
  [-37.5757, 175.1312],
  [-37.5761, 175.1259],
  [-37.5762, 175.1231],
  [-37.5761, 175.1225],
  [-37.5743, 175.1072],
  [-37.5743, 175.1062],
  [-37.5744, 175.1054],
  [-37.5759, 175.1012],
  [-37.5764, 175.1000],
  [-37.5787, 175.0961],
  [-37.5798, 175.0952],
  [-37.5810, 175.0947],
  [-37.5818, 175.0941],
  [-37.5823, 175.0931],
  [-37.5838, 175.0889],
  [-37.5844, 175.0878],
  [-37.5851, 175.0864]
];

const rotowaroHeritageStart = [
  [-37.5851, 175.0864],
  [-37.5884, 175.0827],
  [-37.5902, 175.0813],
  [-37.5926, 175.0816],
  [-37.5938, 175.0814],
  [-37.5958, 175.0794],
  [-37.5969, 175.0777],
  [-37.5979, 175.0746],
  [-37.5978, 175.0686],
  [-37.5979, 175.0668]
];

const heritageStartPukemiroJunction = [
  [-37.5979, 175.0668],
  [-37.5980, 175.0629],
  [-37.5981, 175.0619],
  [-37.5986, 175.0612],
  [-37.6000, 175.0598],
  [-37.6008, 175.0594],
  [-37.6014, 175.0588],
  [-37.6024, 175.0574],
  [-37.6051, 175.0547]
];

const pukemiroJunctionPukemiro = [
  [-37.6051, 175.0547],
  [-37.6063, 175.0542],
  [-37.6080, 175.0541],
  [-37.6085, 175.0539],
  [-37.6088, 175.0535],
  [-37.6092, 175.0532],
  [-37.6105, 175.0526],
  [-37.6112, 175.0526],
  [-37.6124, 175.0530],
  [-37.6134, 175.0529]
];

const pukemiroGlenAfton = [
  [-37.6051, 175.0547],
  [-37.6056, 175.0538],
  [-37.6062, 175.0517],
  [-37.6064, 175.0504],
  [-37.6066, 175.0499],
  [-37.6067, 175.0493],
  [-37.6066, 175.0485],
  [-37.6065, 175.0478],
  [-37.6065, 175.0472],
  [-37.6067, 175.0462],
  [-37.6066, 175.0452],
  [-37.6058, 175.0430],
  [-37.6057, 175.0419],
  [-37.6060, 175.0408],
  [-37.6071, 175.0389],
  [-37.6074, 175.0377],
  [-37.6074, 175.0359],
  [-37.6078, 175.0341],
  [-37.6088, 175.0321]
];

export const glenAfton: Line = {
  name: 'Glen Afton',
  state: 'NZ',
  segments: [
    {
      segments: [huntlyAwaroaJunction],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [awaroaJunctionAwaroa],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-03-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [awaroaJunctionRotowaro],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [rotowaroHeritageStart],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-03-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heritageStartPukemiroJunction],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-03-05',
          status: 'closed'
        }, {
          date: '1977-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [pukemiroJunctionPukemiro],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-03-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pukemiroGlenAfton],
      history: {
        opened: {
          date: '1924-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-03-05',
          status: 'closed'
        }, {
          date: '1977-01-01',
          status: 'open'
        }]
      }
    }
  ]
}
