import { Line } from "../../../trackTypes";

const line = [
  [-41.7644, 171.5917],
  [-41.7547, 171.5862],
  [-41.7540, 171.5854],
  [-41.7539, 171.5845],
  [-41.7541, 171.5821],
  [-41.7539, 171.5807],
  [-41.7487, 171.5668],
  [-41.7487, 171.5657],
  [-41.7490, 171.5642],
  [-41.7528, 171.5532],
  [-41.7543, 171.5442],
  [-41.7553, 171.5316],
  [-41.7550, 171.5254],
  [-41.7529, 171.5141],
  [-41.7507, 171.5092],
  [-41.7479, 171.4955]
];

const lineExtension = [
  [-41.7479, 171.4955],
  [-41.7478, 171.4940],
  [-41.7480, 171.4880],
  [-41.7504, 171.4803],
  [-41.7559, 171.4724],
  [-41.7575, 171.4710],
  [-41.7591, 171.4705],
  [-41.7620, 171.4710],
  [-41.7654, 171.4700]
];

const riverCrossing = [
  [-41.7625, 171.5988],
  [-41.7637, 171.5990],
  [-41.7642, 171.5988],
  [-41.7645, 171.5983],
  [-41.7649, 171.5971],
  [-41.7652, 171.5937],
  [-41.7651, 171.5926],
  [-41.7644, 171.5917]
];

export const capeFoulwind: Line = {
  name: 'Cape Foulwind',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lineExtension],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [riverCrossing],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
