import { Line } from "../../trackTypes";

const line = [
  [-32.9900, 145.8996],
  [-32.9895, 145.8987],
  [-32.9888, 145.8981],
  [-32.9523, 145.8848],
  [-32.9454, 145.8812],
  [-32.9323, 145.8778],
  [-32.9306, 145.8769],
  [-32.9268, 145.8748],
  [-32.9255, 145.8745],
  [-32.9223, 145.8747],
  [-32.9201, 145.8742],
  [-32.9145, 145.8702],
  [-32.9043, 145.8671],
  [-32.9011, 145.8670],
  [-32.8492, 145.8805]
];

export const mountHope: Line = {
  name: 'Mount Hope',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1919-02-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1924-07-06',
          status: 'closed'
        }]
      }
    }
  ]
};
