import { Line } from "../../trackTypes";

const cooktownPalmerRoad = [
  [-15.4596, 145.2501],
  [-15.4605, 145.2497],
  [-15.4645, 145.2495],
  [-15.4664, 145.2485],
  [-15.4676, 145.2477],
  [-15.4702, 145.2469],
  [-15.4714, 145.2458],
  [-15.4727, 145.2436],
  [-15.4730, 145.2425],
  [-15.4733, 145.2334],
  [-15.4713, 145.2201],
  [-15.4710, 145.2181],
  [-15.4707, 145.2173],
  [-15.4697, 145.2163],
  [-15.4685, 145.2131],
  [-15.4678, 145.2075],
  [-15.4675, 145.2066],
  [-15.4654, 145.2028],
  [-15.4649, 145.2013],
  [-15.4642, 145.1971],
  [-15.4638, 145.1957],
  [-15.4518, 145.1770],
  [-15.4491, 145.1749],
  [-15.4481, 145.1735],
  [-15.4467, 145.1690],
  [-15.4374, 145.1036],
  [-15.4353, 145.0981],
  [-15.4350, 145.0961]
];

const palmerRoadLaura = [
  [-15.4350, 145.0961],
  [-15.4347, 145.0872],
  [-15.4341, 145.0866],
  [-15.4327, 145.0863],
  [-15.4320, 145.0856],
  [-15.4293, 145.0737],
  [-15.4289, 145.0683],
  [-15.4285, 145.0632],
  [-15.4277, 145.0604],
  [-15.4216, 145.0518],
  [-15.4202, 145.0483],
  [-15.4184, 145.0462],
  [-15.4181, 145.0452],
  [-15.4182, 145.0437],
  [-15.4196, 145.0391],
  [-15.4197, 145.0377],
  [-15.4190, 145.0351],
  [-15.4191, 145.0334],
  [-15.4239, 145.0222],
  [-15.4246, 145.0177],
  [-15.4257, 145.0165],
  [-15.4290, 145.0147],
  [-15.4295, 145.0139],
  [-15.4300, 145.0125],
  [-15.4314, 145.0021],
  [-15.4331, 144.9983],
  [-15.4343, 144.9971],
  [-15.4348, 144.9962],
  [-15.4347, 144.9950],
  [-15.4341, 144.9929],
  [-15.4343, 144.9863],
  [-15.4349, 144.9849],
  [-15.4364, 144.9834],
  [-15.4368, 144.9827],
  [-15.4366, 144.9820],
  [-15.4362, 144.9806],
  [-15.4363, 144.9793],
  [-15.4371, 144.9768],
  [-15.4369, 144.9733],
  [-15.4364, 144.9717],
  [-15.4349, 144.9689],
  [-15.4347, 144.9678],
  [-15.4349, 144.9669],
  [-15.4352, 144.9658],
  [-15.4349, 144.9621],
  [-15.4354, 144.9601],
  [-15.4367, 144.9585],
  [-15.4397, 144.9571],
  [-15.4425, 144.9544],
  [-15.4500, 144.9495],
  [-15.4520, 144.9467],
  [-15.4526, 144.9443],
  [-15.4525, 144.9427],
  [-15.4505, 144.9379],
  [-15.4441, 144.9281],
  [-15.4140, 144.9063],
  [-15.3921, 144.8833],
  [-15.3894, 144.8780],
  [-15.3885, 144.8716],
  [-15.3887, 144.8710],
  [-15.3892, 144.8705],
  [-15.3904, 144.8702],
  [-15.3911, 144.8695],
  [-15.3918, 144.8673],
  [-15.3927, 144.8661],
  [-15.3930, 144.8660],
  [-15.3947, 144.8653],
  [-15.3958, 144.8638],
  [-15.3962, 144.8619],
  [-15.3970, 144.8606],
  [-15.3989, 144.8591],
  [-15.3998, 144.8574],
  [-15.4010, 144.8534],
  [-15.4008, 144.8513],
  [-15.3957, 144.8390],
  [-15.3952, 144.8366],
  [-15.3955, 144.8342],
  [-15.3963, 144.8317],
  [-15.3962, 144.8300],
  [-15.3955, 144.8288],
  [-15.3877, 144.8241],
  [-15.3868, 144.8230],
  [-15.3864, 144.8183],
  [-15.3859, 144.8172],
  [-15.3853, 144.8168],
  [-15.3841, 144.8173],
  [-15.3832, 144.8184],
  [-15.3821, 144.8189],
  [-15.3806, 144.8188],
  [-15.3796, 144.8180],
  [-15.3789, 144.8144],
  [-15.3779, 144.8129],
  [-15.3766, 144.8124],
  [-15.3740, 144.8124],
  [-15.3730, 144.8118],
  [-15.3654, 144.7994],
  [-15.3631, 144.7918],
  [-15.3620, 144.7904],
  [-15.3602, 144.7895],
  [-15.3594, 144.7884],
  [-15.3586, 144.7851],
  [-15.3589, 144.7803],
  [-15.3606, 144.7775],
  [-15.3627, 144.7699],
  [-15.3634, 144.7688],
  [-15.3642, 144.7685],
  [-15.3651, 144.7677],
  [-15.3660, 144.7651],
  [-15.3661, 144.7640],
  [-15.3657, 144.7632],
  [-15.3658, 144.7618],
  [-15.3664, 144.7602],
  [-15.3662, 144.7591],
  [-15.3641, 144.7565],
  [-15.3639, 144.7554],
  [-15.3642, 144.7542],
  [-15.3656, 144.7527],
  [-15.3663, 144.7507],
  [-15.3649, 144.7345],
  [-15.3644, 144.7325],
  [-15.3641, 144.7268],
  [-15.3628, 144.7200],
  [-15.3502, 144.6673],
  [-15.3503, 144.6656],
  [-15.3523, 144.6628],
  [-15.3526, 144.6611],
  [-15.3528, 144.6297],
  [-15.3540, 144.6257],
  [-15.3540, 144.6224],
  [-15.3544, 144.6205],
  [-15.3560, 144.6172],
  [-15.3576, 144.6156],
  [-15.3634, 144.6135],
  [-15.3770, 144.6008],
  [-15.3783, 144.5979],
  [-15.3780, 144.5926],
  [-15.3783, 144.5903],
  [-15.3862, 144.5788],
  [-15.3933, 144.5660],
  [-15.3950, 144.5643],
  [-15.4544, 144.5287],
  [-15.4581, 144.5254],
  [-15.4589, 144.5230],
  [-15.4590, 144.5172],
  [-15.4606, 144.5134],
  [-15.4658, 144.5070],
  [-15.5601, 144.4447]
];

export const cooktown: Line = {
  name: 'Cooktown',
  state: 'QLD',
  segments: [
    {
      segments: [cooktownPalmerRoad],
      history: {
        opened: {
          date: '1885-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [palmerRoadLaura],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
