import { Line } from "../../../trackTypes";

const line = [
  [-41.7124, 171.7628],
  [-41.7122, 171.7649],
  [-41.7129, 171.7689],
  [-41.7151, 171.7738],
  [-41.7162, 171.7755],
  [-41.7172, 171.7759],
  [-41.7181, 171.7757],
  [-41.7201, 171.7744],
  [-41.7221, 171.7741],
  [-41.7231, 171.7744],
  [-41.7241, 171.7754],
  [-41.7248, 171.7758],
  [-41.7252, 171.7764],
  [-41.7253, 171.7786],
  [-41.7256, 171.7796],
  [-41.7257, 171.7806]
];

const dennistonIncline = [
  [-41.7257, 171.7806],
  [-41.7259, 171.7811],
  [-41.7317, 171.7893],
  [-41.7355, 171.7975],
  [-41.7363, 171.7981],
  [-41.7387, 171.8014],
  [-41.7449, 171.8079],
  [-41.7454, 171.8083],
  [-41.7509, 171.8106]
];

const koranuiIncline = [
  [-41.7248, 171.7758],
  [-41.7230, 171.7800],
  [-41.7201, 171.8019]
];

export const connsCreek: Line = {
  name: 'Conns Creek',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-08-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dennistonIncline],
      history: {
        opened: {
          date: '1879-02-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1967-08-16',
          status: 'closed'
        }]
      }
    },
    {
      segments: [koranuiIncline],
      history: {
        opened: {
          date: '1882-01-01',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1886-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
