import { Line } from "../../../trackTypes";

const toolambaTatura = [
  [-36.4895, 145.3383],
  [-36.4888, 145.3379],
  [-36.4873, 145.3369],
  [-36.4863, 145.3358],
  [-36.4858, 145.3347],
  [-36.4552, 145.2606],
  [-36.4405, 145.2305]
];

const taturaEchuca = [
  [-36.4405, 145.2305],
  [-36.4320, 145.2158],
  [-36.4091, 145.1760],
  [-36.4076, 145.1740],
  [-36.4067, 145.1730],
  [-36.4022, 145.1687],
  [-36.4007, 145.1670],
  [-36.3693, 145.1280],
  [-36.3682, 145.1269],
  [-36.3675, 145.1263],
  [-36.3585, 145.1206],
  [-36.3569, 145.1193],
  [-36.3557, 145.1181],
  [-36.3423, 145.1028],
  [-36.3410, 145.1009],
  [-36.3398, 145.0986],
  [-36.3305, 145.0774],
  [-36.3289, 145.0746],
  [-36.3061, 145.0426],
  [-36.3006, 145.0350],
  [-36.2987, 145.0318],
  [-36.2938, 145.0228],
  [-36.2787, 144.9966],
  [-36.2489, 144.9451],
  [-36.2477, 144.9422],
  [-36.2412, 144.9250],
  [-36.2176, 144.8795],
  [-36.1984, 144.8493],
  [-36.1973, 144.8473],
  [-36.1875, 144.8247],
  [-36.1856, 144.8194],
  [-36.1826, 144.8107],
  [-36.1818, 144.8091],
  [-36.1711, 144.7936],
  [-36.1545, 144.7746],
  [-36.1524, 144.7718],
  [-36.1510, 144.7696],
  [-36.1432, 144.7559],
  [-36.1425, 144.7552],
  [-36.1419, 144.7548],
  [-36.1410, 144.7544]
];

export const toolamba: Line = {
  name: 'Toolamba Echuca',
  state: 'VIC',
  segments: [
    {
      segments: [toolambaTatura],
      history: {
        opened: {
          date: '1880-01-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2003-06-01',
          status: 'closed'
        }, {
          date: '2020-01-10',
          status: 'closed'
        }, {
          date: '2006-09-03',
          status: 'open'
        }]
      }
    },
    {
      segments: [taturaEchuca],
      history: {
        opened: {
          date: '1887-08-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2003-06-01',
          status: 'closed'
        }, {
          date: '2020-01-10',
          status: 'closed'
        }, {
          date: '2006-09-03',
          status: 'open'
        }]
      }
    }
  ]
};
