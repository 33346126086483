import { Line } from "../../../trackTypes";

const rushworthStanhope = [
  [-36.5847, 145.0069],
  [-36.5839, 145.0050],
  [-36.5828, 145.0038],
  [-36.5818, 145.0032],
  [-36.5757, 145.0007],
  [-36.5617, 144.9977],
  [-36.5460, 144.9957],
  [-36.5237, 144.9861],
  [-36.5219, 144.9858],
  [-36.5089, 144.9854],
  [-36.5031, 144.9844],
  [-36.5000, 144.9824],
  [-36.4969, 144.9816],
  [-36.4463, 144.9815]
];

const stanhopeGigarre = [
  [-36.4463, 144.9815],
  [-36.3940, 144.9816]
];

export const girgarre: Line = {
  name: 'Girgarre',
  state: 'VIC',
  segments: [
    {
      segments: [rushworthStanhope],
      history: {
        opened: {
          date: '1917-05-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stanhopeGigarre],
      history: {
        opened: {
          date: '1917-05-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1975-03-01',
          status: 'closed'
        }]
      }
    }
  ]
}
