import { Line } from "../../trackTypes";

const gladstoneBypass = [
  [-23.8654, 151.2251],
  [-23.8661, 151.2306],
  [-23.8668, 151.2323],
  [-23.8684, 151.2346],
  [-23.8701, 151.2355],
  [-23.8741, 151.2362],
  [-23.8756, 151.2370],
  [-23.8770, 151.2386],
  [-23.8782, 151.2409],
  [-23.8794, 151.2418],
  [-23.8817, 151.2429],
  [-23.8834, 151.2445],
  [-23.8842, 151.2469],
  [-23.8840, 151.2489],
  [-23.8820, 151.2534],
  [-23.8818, 151.2549],
  [-23.8819, 151.2564],
  [-23.8831, 151.2603],
  [-23.8830, 151.2625],
  [-23.8819, 151.2646],
  [-23.8807, 151.2656],
  [-23.8770, 151.2671],
  [-23.8759, 151.2681],
  [-23.8755, 151.2694],
  [-23.8758, 151.2746],
  [-23.8755, 151.2755],
  [-23.8748, 151.2772],
  [-23.8736, 151.2783],
  [-23.8721, 151.2785],
  [-23.8706, 151.2777],
  [-23.8689, 151.2751],
  [-23.8683, 151.2743]
];

const toolooaJunction = [
  [-23.8758, 151.2746],
  [-23.8763, 151.2759],
  [-23.8770, 151.2769],
  [-23.8782, 151.2777]
];

const calliopeMouraMineJunction = [
  [-23.9898, 151.1993],
  [-23.9905, 151.1965],
  [-23.9905, 151.1945],
  [-23.9897, 151.1926],
  [-23.9870, 151.1890],
  [-23.9863, 151.1872],
  [-23.9862, 151.1855],
  [-23.9900, 151.1687],
  [-23.9900, 151.1661],
  [-23.9893, 151.1636],
  [-23.9891, 151.1615],
  [-23.9894, 151.1575],
  [-23.9893, 151.1558],
  [-23.9888, 151.1542],
  [-23.9887, 151.1522],
  [-23.9894, 151.1488],
  [-23.9903, 151.1467],
  [-23.9934, 151.1426],
  [-23.9949, 151.1387],
  [-23.9951, 151.1367],
  [-23.9954, 151.1326],
  [-24.0003, 151.1174],
  [-24.0013, 151.1100],
  [-24.0017, 151.1005],
  [-24.0013, 151.0988],
  [-23.9960, 151.0851],
  [-23.9958, 151.0826],
  [-23.9970, 151.0787],
  [-23.9971, 151.0770],
  [-23.9964, 151.0731],
  [-23.9964, 151.0715],
  [-23.9969, 151.0701],
  [-23.9982, 151.0682],
  [-23.9988, 151.0667],
  [-23.9989, 151.0651],
  [-23.9988, 151.0632],
  [-23.9993, 151.0608],
  [-24.0011, 151.0580],
  [-24.0024, 151.0534],
  [-24.0026, 151.0445],
  [-24.0033, 151.0420],
  [-24.0046, 151.0388],
  [-24.0050, 151.0369],
  [-24.0046, 151.0340],
  [-24.0048, 151.0321],
  [-24.0055, 151.0286],
  [-24.0059, 151.0274],
  [-24.0068, 151.0261],
  [-24.0086, 151.0247],
  [-24.0119, 151.0236],
  [-24.0136, 151.0237],
  [-24.0161, 151.0247],
  [-24.0182, 151.0252],
  [-24.0201, 151.0248],
  [-24.0229, 151.0233],
  [-24.0255, 151.0227],
  [-24.0280, 151.0233],
  [-24.0338, 151.0264],
  [-24.0357, 151.0271],
  [-24.0394, 151.0278],
  [-24.0414, 151.0277],
  [-24.0443, 151.0263],
  [-24.0460, 151.0243],
  [-24.0594, 150.9910],
  [-24.0661, 150.9683],
  [-24.0663, 150.9659],
  [-24.0623, 150.9448],
  [-24.0621, 150.9418],
  [-24.0660, 150.9060],
  [-24.0672, 150.9025],
  [-24.0740, 150.8928],
  [-24.0792, 150.8833],
  [-24.0800, 150.8799],
  [-24.0810, 150.8777],
  [-24.0865, 150.8697],
  [-24.0875, 150.8671],
  [-24.0909, 150.8455],
  [-24.0908, 150.8436],
  [-24.0897, 150.8403],
  [-24.0830, 150.7647],
  [-24.0820, 150.7610],
  [-24.0824, 150.7579],
  [-24.0838, 150.7556],
  [-24.0863, 150.7541],
  [-24.0885, 150.7541],
  [-24.0895, 150.7538],
  [-24.0905, 150.7531],
  [-24.0914, 150.7527],
  [-24.0929, 150.7525],
  [-24.0940, 150.7515],
  [-24.0947, 150.7499],
  [-24.0946, 150.7489],
  [-24.0940, 150.7476],
  [-24.0937, 150.7465],
  [-24.0940, 150.7451],
  [-24.0945, 150.7439],
  [-24.0946, 150.7427],
  [-24.0941, 150.7410],
  [-24.0940, 150.7395],
  [-24.0948, 150.7381],
  [-24.0963, 150.7370],
  [-24.0972, 150.7358],
  [-24.0974, 150.7344],
  [-24.0970, 150.7325],
  [-24.0980, 150.7304],
  [-24.0975, 150.7290],
  [-24.0977, 150.7277],
  [-24.0976, 150.7261],
  [-24.0984, 150.7244],
  [-24.0993, 150.7238],
  [-24.1016, 150.7240],
  [-24.1029, 150.7247],
  [-24.1042, 150.7248],
  [-24.1052, 150.7244],
  [-24.1064, 150.7235],
  [-24.1078, 150.7233],
  [-24.1170, 150.7274],
  [-24.1178, 150.7283],
  [-24.1186, 150.7298],
  [-24.1201, 150.7307],
  [-24.1238, 150.7305],
  [-24.1254, 150.7295],
  [-24.1262, 150.7292],
  [-24.1280, 150.7294],
  [-24.1294, 150.7290],
  [-24.1310, 150.7273],
  [-24.1390, 150.7215],
  [-24.1413, 150.7178],
  [-24.1452, 150.6960],
  [-24.1466, 150.6930],
  [-24.1591, 150.6799],
  [-24.1609, 150.6754],
  [-24.1616, 150.6651],
  [-24.1624, 150.6620],
  [-24.1688, 150.6522],
  [-24.1693, 150.6506],
  [-24.1692, 150.6478],
  [-24.1700, 150.6462],
  [-24.1718, 150.6454],
  [-24.1740, 150.6460],
  [-24.1758, 150.6456],
  [-24.1769, 150.6445],
  [-24.1775, 150.6431],
  [-24.1783, 150.6421],
  [-24.1795, 150.6412],
  [-24.1802, 150.6401],
  [-24.1804, 150.6385],
  [-24.1800, 150.6373],
  [-24.1790, 150.6361],
  [-24.1785, 150.6347],
  [-24.1787, 150.6313],
  [-24.1784, 150.6294],
  [-24.1778, 150.6279],
  [-24.1778, 150.6263],
  [-24.1787, 150.6249],
  [-24.1812, 150.6231],
  [-24.1842, 150.6192],
  [-24.1863, 150.6177],
  [-24.1871, 150.6164],
  [-24.1872, 150.6148],
  [-24.1860, 150.6120],
  [-24.1843, 150.6110],
  [-24.1827, 150.6109],
  [-24.1811, 150.6102],
  [-24.1803, 150.6089],
  [-24.1802, 150.6074],
  [-24.1822, 150.6002],
  [-24.1820, 150.5973],
  [-24.1810, 150.5945],
  [-24.1806, 150.5927],
  [-24.1805, 150.5897],
  [-24.1766, 150.5764],
  [-24.1753, 150.5744],
  [-24.1733, 150.5727],
  [-24.1721, 150.5709],
  [-24.1677, 150.5578],
  [-24.1679, 150.5548],
  [-24.1670, 150.5525],
  [-24.1647, 150.5506],
  [-24.1637, 150.5487],
  [-24.1625, 150.5476],
  [-24.1607, 150.5467],
  [-24.1595, 150.5451],
  [-24.1592, 150.5433],
  [-24.1594, 150.5417],
  [-24.1590, 150.5400],
  [-24.1552, 150.5334],
  [-24.1549, 150.5320],
  [-24.1554, 150.5305],
  [-24.1567, 150.5282],
  [-24.1583, 150.5266],
  [-24.1588, 150.5247],
  [-24.1583, 150.5234],
  [-24.1571, 150.5218],
  [-24.1568, 150.5200],
  [-24.1577, 150.5182],
  [-24.1653, 150.5134],
  [-24.1672, 150.5106],
  [-24.1685, 150.5035],
  [-24.1694, 150.5012],
  [-24.1709, 150.4991],
  [-24.1718, 150.4968],
  [-24.1721, 150.4938],
  [-24.1733, 150.4910],
  [-24.1772, 150.4857],
  [-24.1798, 150.4838],
  [-24.1813, 150.4820],
  [-24.1820, 150.4806],
  [-24.1840, 150.4768],
  [-24.1855, 150.4749],
  [-24.1869, 150.4741],
  [-24.1891, 150.4710],
  [-24.1897, 150.4679],
  [-24.1906, 150.4655],
  [-24.1969, 150.4571],
  [-24.2000, 150.4548],
  [-24.2021, 150.4515],
  [-24.2026, 150.4476],
  [-24.2042, 150.4443],
  [-24.2083, 150.4408],
  [-24.2099, 150.4384],
  [-24.2116, 150.4329],
  [-24.2136, 150.4302],
  [-24.2191, 150.4271],
  [-24.2211, 150.4252],
  [-24.2268, 150.4171],
  [-24.2285, 150.4151],
  [-24.2310, 150.4139],
  [-24.2329, 150.4123],
  [-24.2457, 150.3933],
  [-24.2490, 150.3907],
  [-24.2614, 150.3858],
  [-24.2640, 150.3835],
  [-24.2745, 150.3665],
  [-24.3090, 150.3287],
  [-24.3123, 150.3268],
  [-24.3542, 150.3155],
  [-24.3572, 150.3132],
  [-24.3589, 150.3098],
  [-24.3597, 150.3043],
  [-24.3618, 150.3003],
  [-24.3641, 150.2984],
  [-24.3655, 150.2969],
  [-24.3671, 150.2945],
  [-24.3690, 150.2935],
  [-24.3719, 150.2931],
  [-24.3747, 150.2918],
  [-24.3768, 150.2900],
  [-24.3799, 150.2889],
  [-24.3831, 150.2889],
  [-24.3864, 150.2878],
  [-24.3983, 150.2790],
  [-24.3997, 150.2773],
  [-24.4127, 150.2546],
  [-24.4155, 150.2522],
  [-24.4188, 150.2517],
  [-24.4226, 150.2526],
  [-24.4254, 150.2525],
  [-24.4281, 150.2516],
  [-24.4306, 150.2514],
  [-24.4402, 150.2537],
  [-24.4449, 150.2526],
  [-24.4483, 150.2495],
  [-24.4533, 150.2403],
  [-24.4567, 150.2239],
  [-24.4578, 150.2214],
  [-24.4651, 150.2107],
  [-24.4662, 150.2069],
  [-24.4656, 150.2013],
  [-24.4658, 150.1987],
  [-24.4705, 150.1843],
  [-24.4729, 150.1814],
  [-24.4772, 150.1792],
  [-24.4794, 150.1763],
  [-24.4969, 150.1013],
  [-24.4976, 150.0994],
  [-24.5070, 150.0824],
  [-24.5076, 150.0809],
  [-24.5110, 150.0688]
];

const mouraMineJunctionCockatooJunction = [
  [-24.5110, 150.0688],
  [-24.5113, 150.0678],
  [-24.5126, 150.0644],
  [-24.5145, 150.0571],
  [-24.5153, 150.0551],
  [-24.5164, 150.0510],
  [-24.5172, 150.0494],
  [-24.5266, 150.0380],
  [-24.5272, 150.0370],
  [-24.5336, 150.0199],
  [-24.5347, 150.0184],
  [-24.5384, 150.0132]
];

const cockatooJunctionMoura = [
  [-24.5384, 150.0132],
  [-24.5480, 150.0004],
  [-24.5532, 149.9916],
  [-24.5553, 149.9893],
  [-24.5583, 149.9876],
  [-24.5600, 149.9861],
  [-24.5670, 149.9770],
  [-24.5674, 149.9767],
  [-24.5678, 149.9766],
  [-24.5683, 149.9767]
];

const mouraNorthJunction = [
  [-24.5669, 149.9754],
  [-24.5672, 149.9760],
  [-24.5672, 149.9765],
  [-24.5670, 149.9770]
];

const annandaleBoundaryHillMine = [
  [-24.1820, 150.4806],
  [-24.1831, 150.4797],
  [-24.1843, 150.4794],
  [-24.1870, 150.4800],
  [-24.1878, 150.4801],
  [-24.2013, 150.4787],
  [-24.2068, 150.4781],
  [-24.2084, 150.4770],
  [-24.2090, 150.4751],
  [-24.2083, 150.4732],
  [-24.2066, 150.4722],
  [-24.2049, 150.4726],
  [-24.2037, 150.4742],
  [-24.2034, 150.4765],
  [-24.2034, 150.4765],
  [-24.2013, 150.4787]
];

const mouraMine = [
  [-24.5110, 150.0688],
  [-24.5120, 150.0673],
  [-24.5131, 150.0669],
  [-24.5235, 150.0666],
  [-24.5292, 150.0662],
  [-24.5311, 150.0673],
  [-24.5320, 150.0691],
  [-24.5315, 150.0712],
  [-24.5298, 150.0723],
  [-24.5282, 150.0721],
  [-24.5269, 150.0709],
  [-24.5251, 150.0676],
  [-24.5245, 150.0670],
  [-24.5235, 150.0666]
];

const cockatooLoop = [
  [-24.5384, 150.0132],
  [-24.5396, 150.0124],
  [-24.5405, 150.0122],
  [-24.5510, 150.0123],
  [-24.5519, 150.0119],
  [-24.5526, 150.0114],
  [-24.5532, 150.0105],
  [-24.5564, 150.0053],
  [-24.5565, 150.0039],
  [-24.5562, 150.0027],
  [-24.5528, 149.9988],
  [-24.5518, 149.9983],
  [-24.5496, 149.9987],
  [-24.5486, 150.0003],
  [-24.5485, 150.0016],
  [-24.5492, 150.0031],
  [-24.5529, 150.0077],
  [-24.5533, 150.0090],
  [-24.5532, 150.0105]
];

export const mouraShortLine: Line = {
  name: 'Moura Short Line',
  state: 'QLD',
  segments: [
    {
      segments: [gladstoneBypass, toolooaJunction, calliopeMouraMineJunction],
      history: {
        opened: {
          date: '1968-01-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mouraMineJunctionCockatooJunction],
      history: {
        opened: {
          date: '1968-01-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-22',
          status: 'closed'
        }, {
          date: '2016-10-03',
          status: 'open'
        }]
      }
    },
    {
      segments: [cockatooJunctionMoura, mouraNorthJunction],
      history: {
        opened: {
          date: '1968-01-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mouraMine],
      history: {
        opened: {
          date: '1994-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [annandaleBoundaryHillMine],
      history: {
        opened: {
          date: '1983-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cockatooLoop],
      history: {
        opened: {
          date: '2016-10-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
