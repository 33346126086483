import { Line } from "../../trackTypes";

const macarthurGlenleeJunction = [
  [-34.0720, 150.7968],
  [-34.0730, 150.7915],
  [-34.0745, 150.7878],
  [-34.0763, 150.7854],
  [-34.0778, 150.7838],
  [-34.0800, 150.7808],
  [-34.0809, 150.7788],
  [-34.0847, 150.7625]
];

const glenleeJunctionNorthMenangle = [
  [-34.0847, 150.7625],
  [-34.0859, 150.7590],
  [-34.0872, 150.7566],
  [-34.0893, 150.7538],
  [-34.0914, 150.7518],
  [-34.1007, 150.7458],
  [-34.1027, 150.7451],
  [-34.1148, 150.7428],
  [-34.1156, 150.7428]
];

const northMenanglePicton = [
  [-34.1156, 150.7428],
  [-34.1198, 150.7445],
  [-34.1220, 150.7451],
  [-34.1249, 150.7445],
  [-34.1327, 150.7401],
  [-34.1438, 150.7357],
  [-34.1466, 150.7350],
  [-34.1498, 150.7349],
  [-34.1517, 150.7344],
  [-34.1536, 150.7333],
  [-34.1550, 150.7328],
  [-34.1568, 150.7330],
  [-34.1612, 150.7349],
  [-34.1639, 150.7349],
  [-34.1661, 150.7337],
  [-34.1673, 150.7324],
  [-34.1696, 150.7311],
  [-34.1717, 150.7308],
  [-34.1736, 150.7300],
  [-34.1808, 150.7227],
  [-34.1821, 150.7207],
  [-34.1828, 150.7179],
  [-34.1833, 150.7122],
  [-34.1826, 150.7010],
  [-34.1813, 150.6958],
  [-34.1811, 150.6942],
  [-34.1812, 150.6925],
  [-34.1817, 150.6906],
  [-34.1814, 150.6880],
  [-34.1805, 150.6852],
  [-34.1801, 150.6830],
  [-34.1802, 150.6810],
  [-34.1838, 150.6674],
  [-34.1851, 150.6651],
  [-34.1867, 150.6638],
  [-34.1894, 150.6628],
  [-34.1922, 150.6625],
  [-34.1942, 150.6626],
  [-34.1961, 150.6621],
  [-34.1976, 150.6610],
  [-34.1990, 150.6589],
  [-34.2015, 150.6519],
  [-34.2018, 150.6492],
  [-34.2010, 150.6466],
  [-34.1993, 150.6442],
  [-34.1958, 150.6420],
  [-34.1930, 150.6391],
  [-34.1915, 150.6357],
  [-34.1910, 150.6327],
  [-34.1913, 150.6298],
  [-34.1914, 150.6274],
  [-34.1904, 150.6254],
  [-34.1890, 150.6241],
  [-34.1885, 150.6230],
  [-34.1883, 150.6217],
  [-34.1885, 150.6204],
  [-34.1892, 150.6189],
  [-34.1894, 150.6173],
  [-34.1890, 150.6156],
  [-34.1882, 150.6145],
  [-34.1869, 150.6139],
  [-34.1814, 150.6133],
  [-34.1799, 150.6129],
  [-34.1786, 150.6124]
];

const pictonPictonOldJunction = [
  [-34.1786, 150.6124],
  [-34.1770, 150.6114],
  [-34.1749, 150.6100]
];

const pictonOldJunctionPictonJunction = [
  [-34.1749, 150.6100],
  [-34.1735, 150.6091],
  [-34.1723, 150.6087],
  [-34.1696, 150.6086],
  [-34.1684, 150.6081],
  [-34.1675, 150.6070],
  [-34.1671, 150.6055],
  [-34.1673, 150.6040],
  [-34.1678, 150.6029],
  [-34.1685, 150.6021]
];

const pictonOldStart = [
  [-34.1749, 150.6100],
  [-34.1740, 150.6089],
  [-34.1734, 150.6082],
  [-34.1726, 150.6060]
];

const oldMainTunnel = [
  [-34.1726, 150.6060],
  [-34.1720, 150.6041]
];

const pictonOldEnd = [
  [-34.1720, 150.6041],
  [-34.1707, 150.6001],
  [-34.1705, 150.5993],
  [-34.1705, 150.5986]
];

const pictonJunction = [
  [-34.1685, 150.6021],
  [-34.1696, 150.6010],
  [-34.1703, 150.5997],
  [-34.1705, 150.5986]
];

const pictonLoop = [
  [-34.1705, 150.5986],
  [-34.1706, 150.5980],
  [-34.1710, 150.5970],
  [-34.1716, 150.5955],
  [-34.1717, 150.5940],
  [-34.1721, 150.5927],
  [-34.1732, 150.5916],
  [-34.1740, 150.5912],
  [-34.1746, 150.5904],
  [-34.1753, 150.5894],
  [-34.1766, 150.5885],
  [-34.1835, 150.5864],
  [-34.1848, 150.5853],
  [-34.1874, 150.5810],
  [-34.1887, 150.5798],
  [-34.1899, 150.5792],
  [-34.1907, 150.5784],
  [-34.1923, 150.5767],
  [-34.1961, 150.5746],
  [-34.1998, 150.5740],
  [-34.2023, 150.5731],
  [-34.2050, 150.5713],
  [-34.2140, 150.5630],
  [-34.2331, 150.5495],
  [-34.2354, 150.5483],
  [-34.2382, 150.5474],
  [-34.2416, 150.5453],
  [-34.2439, 150.5429],
  [-34.2470, 150.5394],
  [-34.2564, 150.5328],
  [-34.2681, 150.5245],
  [-34.2715, 150.5231],
  [-34.2753, 150.5232],
  [-34.2786, 150.5246],
  [-34.2820, 150.5254],
  [-34.2846, 150.5249],
  [-34.2866, 150.5240],
  [-34.2890, 150.5235],
  [-34.2912, 150.5237],
  [-34.2945, 150.5248],
  [-34.2985, 150.5253],
  [-34.3029, 150.5241],
  [-34.3053, 150.5226],
  [-34.3090, 150.5202],
  [-34.3115, 150.5191],
  [-34.3170, 150.5175],
  [-34.3198, 150.5162],
  [-34.3235, 150.5130],
  [-34.3293, 150.5052],
  [-34.3302, 150.5033],
  [-34.3310, 150.4993],
  [-34.3314, 150.4982],
  [-34.3322, 150.4967],
  [-34.3361, 150.4924],
  [-34.3379, 150.4913],
  [-34.3397, 150.4911],
  [-34.3522, 150.4941],
  [-34.3553, 150.4948],
  [-34.3567, 150.4946],
  [-34.3579, 150.4938],
  [-34.3592, 150.4920],
  [-34.3617, 150.4898],
  [-34.3628, 150.4885],
  [-34.3638, 150.4862],
  [-34.3647, 150.4852],
  [-34.3662, 150.4847],
  [-34.3701, 150.4844],
  [-34.3723, 150.4839],
  [-34.3741, 150.4825],
  [-34.3754, 150.4819],
  [-34.3767, 150.4816],
  [-34.3781, 150.4809],
  [-34.3810, 150.4784],
  [-34.3827, 150.4777],
  [-34.3848, 150.4781],
  [-34.3952, 150.4856],
  [-34.3968, 150.4864],
  [-34.4002, 150.4876],
  [-34.4023, 150.4887],
  [-34.4035, 150.4901],
  [-34.4042, 150.4916],
  [-34.4046, 150.4948],
  [-34.4056, 150.4973],
  [-34.4074, 150.4998],
  [-34.4091, 150.5007],
  [-34.4109, 150.5006],
  [-34.4119, 150.5001],
  [-34.4137, 150.4982],
  [-34.4144, 150.4956],
  [-34.4141, 150.4921],
  [-34.4143, 150.4903],
  [-34.4150, 150.4886],
  [-34.4165, 150.4871],
  [-34.4247, 150.4823],
  [-34.4257, 150.4816],
  [-34.4328, 150.4751],
  [-34.4377, 150.4724],
  [-34.4388, 150.4713],
  [-34.4438, 150.4643],
  [-34.4445, 150.4629],
  [-34.4455, 150.4614]
];

const mittagongJunctionMittagong = [
  [-34.4455, 150.4614],
  [-34.4486, 150.4576],
  [-34.4506, 150.4542],
  [-34.4521, 150.4502],
  [-34.4526, 150.4478]
];

const pictonPictonTunnel = [
  [-34.1685, 150.6021],
  [-34.1689, 150.6018],
  [-34.1706, 150.6017],
  [-34.1722, 150.6028],
  [-34.1725, 150.6033]
];

const pictonTunnel = [
  [-34.1725, 150.6033],
  [-34.1734, 150.6052]
];

const pictonTunnelRedbankTunnel = [
  [-34.1734, 150.6052],
  [-34.1737, 150.6057],
  [-34.1752, 150.6069],
  [-34.1769, 150.6073],
  [-34.1810, 150.6073],
  [-34.1821, 150.6071],
  [-34.1829, 150.6066],
  [-34.1874, 150.6029],
  [-34.1880, 150.6022],
  [-34.1888, 150.6009],
  [-34.1897, 150.6001],
  [-34.1909, 150.5994],
  [-34.1917, 150.5986],
  [-34.1959, 150.5923],
  [-34.1971, 150.5912],
  [-34.1985, 150.5908],
  [-34.2002, 150.5911],
  [-34.2017, 150.5927],
  [-34.2025, 150.5945]
];

const redbankOldStart = [
  [-34.2025, 150.5945],
  [-34.2033, 150.5954],
  [-34.2043, 150.5961],
  [-34.2067, 150.5966]
];

const redbankTunnel = [
  [-34.2067, 150.5966],
  [-34.2094, 150.5971]
];

const redbankOldEnd = [
  [-34.2094, 150.5971],
  [-34.2099, 150.5972],
  [-34.2107, 150.5971],
  [-34.2121, 150.5964]
];

const redbankDeviation = [
  [-34.2025, 150.5945],
  [-34.2039, 150.5980],
  [-34.2050, 150.5994],
  [-34.2068, 150.6003],
  [-34.2087, 150.6002],
  [-34.2107, 150.5987],
  [-34.2121, 150.5964]
];

const redbankTunneYerrinboolTunnel = [
  [-34.2121, 150.5964],
  [-34.2134, 150.5943],
  [-34.2142, 150.5934],
  [-34.2150, 150.5929],
  [-34.2214, 150.5910],
  [-34.2230, 150.5903],
  [-34.2249, 150.5889],
  [-34.2311, 150.5826],
  [-34.2322, 150.5808],
  [-34.2338, 150.5796],
  [-34.2351, 150.5794],
  [-34.2402, 150.5813],
  [-34.2412, 150.5819],
  [-34.2423, 150.5832],
  [-34.2435, 150.5840],
  [-34.2449, 150.5842],
  [-34.2501, 150.5833],
  [-34.2517, 150.5825],
  [-34.2525, 150.5813],
  [-34.2537, 150.5796],
  [-34.2548, 150.5789],
  [-34.2569, 150.5783],
  [-34.2580, 150.5777],
  [-34.2596, 150.5759],
  [-34.2611, 150.5751],
  [-34.2625, 150.5752],
  [-34.2642, 150.5761],
  [-34.2658, 150.5763],
  [-34.2678, 150.5756],
  [-34.2691, 150.5753],
  [-34.2759, 150.5765],
  [-34.2774, 150.5772],
  [-34.2793, 150.5786],
  [-34.2807, 150.5794],
  [-34.2821, 150.5795],
  [-34.2858, 150.5790],
  [-34.2872, 150.5791],
  [-34.2917, 150.5803],
  [-34.2944, 150.5821],
  [-34.2975, 150.5860],
  [-34.2990, 150.5874],
  [-34.3036, 150.5904],
  [-34.3049, 150.5920],
  [-34.3057, 150.5942],
  [-34.3067, 150.5960],
  [-34.3086, 150.5969],
  [-34.3105, 150.5966],
  [-34.3118, 150.5954],
  [-34.3125, 150.5937],
  [-34.3140, 150.5839],
  [-34.3151, 150.5818],
  [-34.3168, 150.5808],
  [-34.3219, 150.5805],
  [-34.3237, 150.5796],
  [-34.3248, 150.5777],
  [-34.3249, 150.5752],
  [-34.3243, 150.5734],
  [-34.3241, 150.5714],
  [-34.3246, 150.5691],
  [-34.3245, 150.5679],
  [-34.3239, 150.5663],
  [-34.3226, 150.5644],
  [-34.3221, 150.5626],
  [-34.3224, 150.5607],
  [-34.3234, 150.5592],
  [-34.3247, 150.5585],
  [-34.3266, 150.5586],
  [-34.3282, 150.5598],
  [-34.3295, 150.5605],
  [-34.3357, 150.5615],
  [-34.3368, 150.5613],
  [-34.3377, 150.5608],
  [-34.3389, 150.5599],
  [-34.3403, 150.5595],
  [-34.3421, 150.5602],
  [-34.3437, 150.5615],
  [-34.3455, 150.5620],
  [-34.3470, 150.5615],
  [-34.3482, 150.5604],
  [-34.3529, 150.5533],
  [-34.3548, 150.5514],
  [-34.3565, 150.5505],
  [-34.3584, 150.5492],
  [-34.3602, 150.5472],
  [-34.3626, 150.5456],
  [-34.3657, 150.5448],
  [-34.3691, 150.5444],
  [-34.3717, 150.5434],
  [-34.3740, 150.5416],
  [-34.3761, 150.5387],
  [-34.3766, 150.5370],
  [-34.3765, 150.5357],
  [-34.3761, 150.5335],
  [-34.3763, 150.5316],
  [-34.3773, 150.5298],
  [-34.3821, 150.5263],
  [-34.3835, 150.5259],
  [-34.3848, 150.5261],
  [-34.3892, 150.5287],
  [-34.3906, 150.5306],
  [-34.3912, 150.5331],
  [-34.3917, 150.5341],
  [-34.3932, 150.5354],
  [-34.3950, 150.5356],
  [-34.3967, 150.5345],
  [-34.3967, 150.5345],
  [-34.3973, 150.5335]
];

const yerrinboolTunnel = [
  [-34.3973, 150.5335],
  [-34.3976, 150.5323],
  [-34.3975, 150.5309]
];

const yerrinboolTunnelAylmertonTunnel = [
  [-34.3975, 150.5309],
  [-34.3969, 150.5290],
  [-34.3967, 150.5272],
  [-34.3973, 150.5255],
  [-34.3984, 150.5242],
  [-34.4011, 150.5232],
  [-34.4039, 150.5213],
  [-34.4046, 150.5211]
];

const aylmertonTunnel = [
  [-34.4046, 150.5211],
  [-34.4126, 150.5180]
];

const aylmertonTunnelMittagongJunction = [
  [-34.4126, 150.5180],
  [-34.4141, 150.5161],
  [-34.4144, 150.5139],
  [-34.4139, 150.5078],
  [-34.4143, 150.5056],
  [-34.4153, 150.5041],
  [-34.4166, 150.5031],
  [-34.4175, 150.5019],
  [-34.4187, 150.4997],
  [-34.4244, 150.4962],
  [-34.4327, 150.4889],
  [-34.4342, 150.4867],
  [-34.4355, 150.4839],
  [-34.4381, 150.4809],
  [-34.4443, 150.4681],
  [-34.4447, 150.4660],
  [-34.4447, 150.4635],
  [-34.4455, 150.4614]
];

const mittagongMittagongDeviationStart = [
  [-34.4526, 150.4478],
  [-34.4533, 150.4435],
  [-34.4534, 150.4410]
];

const mittagongOldStart = [
  [-34.4534, 150.4410],
  [-34.4532, 150.4395],
  [-34.4533, 150.4381],
  [-34.4534, 150.4375],
  [-34.4555, 150.4270],
  [-34.4561, 150.4255],
  [-34.4579, 150.4229],
  [-34.4594, 150.4214],
  [-34.4611, 150.4205],
  [-34.4633, 150.4203]
];

const theGibTunnelOld = [
  [-34.4633, 150.4203],
  [-34.4680, 150.4201]
];

const mittagongOldEnd = [
  [-34.4680, 150.4201],
  [-34.4710, 150.4200]
];

const mittagongTheGibTunnel = [
  [-34.4534, 150.4410],
  [-34.4535, 150.4397],
  [-34.4569, 150.4248],
  [-34.4578, 150.4227],
  [-34.4597, 150.4208],
  [-34.4623, 150.4200],
  [-34.4633, 150.4200]
];

const theGibTunnel = [
  [-34.4633, 150.4200],
  [-34.4680, 150.4200]
];

const theGibTunnelDeviationEnd = [
  [-34.4680, 150.4200],
  [-34.4710, 150.4200]
];

const mittagongDeviationEndMossVale = [
  [-34.4710, 150.4200],
  [-34.4715, 150.4200],
  [-34.4732, 150.4198],
  [-34.4744, 150.4193],
  [-34.4877, 150.4095],
  [-34.4894, 150.4075],
  [-34.4984, 150.3896],
  [-34.5005, 150.3860],
  [-34.5032, 150.3837],
  [-34.5072, 150.3823],
  [-34.5266, 150.3809],
  [-34.5281, 150.3807],
  [-34.5315, 150.3798],
  [-34.5391, 150.3759],
  [-34.5432, 150.3739],
  [-34.5479, 150.3714]
];

const mossValeMarulan = [
  [-34.5479, 150.3714],
  [-34.5555, 150.3676],
  [-34.5579, 150.3659],
  [-34.5654, 150.3588],
  [-34.5687, 150.3533],
  [-34.5708, 150.3507],
  [-34.5741, 150.3489],
  [-34.5894, 150.3452],
  [-34.5911, 150.3441],
  [-34.5921, 150.3420],
  [-34.5921, 150.3398],
  [-34.5913, 150.3374],
  [-34.5911, 150.3358],
  [-34.5915, 150.3341],
  [-34.5941, 150.3294],
  [-34.5956, 150.3280],
  [-34.5972, 150.3279],
  [-34.5987, 150.3289],
  [-34.6001, 150.3315],
  [-34.6015, 150.3327],
  [-34.6034, 150.3325],
  [-34.6051, 150.3309],
  [-34.6124, 150.3189],
  [-34.6141, 150.3169],
  [-34.6156, 150.3161],
  [-34.6184, 150.3162],
  [-34.6211, 150.3183],
  [-34.6226, 150.3211],
  [-34.6244, 150.3231],
  [-34.6268, 150.3240],
  [-34.6295, 150.3233],
  [-34.6323, 150.3212],
  [-34.6349, 150.3201],
  [-34.6390, 150.3199],
  [-34.6428, 150.3212],
  [-34.6450, 150.3212],
  [-34.6474, 150.3199],
  [-34.6490, 150.3176],
  [-34.6496, 150.3148],
  [-34.6501, 150.3133],
  [-34.6540, 150.3057],
  [-34.6552, 150.3023],
  [-34.6568, 150.2953],
  [-34.6577, 150.2890],
  [-34.6589, 150.2855],
  [-34.6613, 150.2824],
  [-34.6646, 150.2802],
  [-34.6663, 150.2772],
  [-34.6674, 150.2749],
  [-34.6701, 150.2715],
  [-34.6726, 150.2659],
  [-34.6739, 150.2598],
  [-34.6737, 150.2562],
  [-34.6722, 150.2528],
  [-34.6701, 150.2499],
  [-34.6665, 150.2425],
  [-34.6661, 150.2397],
  [-34.6665, 150.2370],
  [-34.6692, 150.2316],
  [-34.6698, 150.2294],
  [-34.6696, 150.2272],
  [-34.6688, 150.2247],
  [-34.6684, 150.2225],
  [-34.6686, 150.2205],
  [-34.6706, 150.2151],
  [-34.6726, 150.2114],
  [-34.6758, 150.2087],
  [-34.6794, 150.2059],
  [-34.6829, 150.2003],
  [-34.6845, 150.1938],
  [-34.6842, 150.1883],
  [-34.6830, 150.1842],
  [-34.6828, 150.1815],
  [-34.6834, 150.1785],
  [-34.6935, 150.1551],
  [-34.6961, 150.1523],
  [-34.6981, 150.1515],
  [-34.7002, 150.1497],
  [-34.7038, 150.1433],
  [-34.7042, 150.1404],
  [-34.7035, 150.1374],
  [-34.7019, 150.1338],
  [-34.7011, 150.1305],
  [-34.7003, 150.1158],
  [-34.7020, 150.1067],
  [-34.7029, 150.1044],
  [-34.7048, 150.1027],
  [-34.7136, 150.0984],
  [-34.7169, 150.0948],
  [-34.7184, 150.0891],
  [-34.7166, 150.0542],
  [-34.7130, 150.0321],
  [-34.7126, 150.0297],
  [-34.7128, 150.0277],
  [-34.7137, 150.0245],
  [-34.7139, 150.0219],
  [-34.7113, 150.0127],
  [-34.7099, 150.0105],
  [-34.7095, 150.0085],
  [-34.7095, 150.0061]
];

const marulanGoldburn = [
  [-34.7095, 150.0061],
  [-34.7121, 149.9818],
  [-34.7121, 149.9774],
  [-34.7099, 149.9692],
  [-34.7057, 149.9629],
  [-34.7017, 149.9596],
  [-34.7003, 149.9579],
  [-34.6987, 149.9546],
  [-34.6941, 149.9314],
  [-34.6939, 149.9293],
  [-34.6933, 149.8811],
  [-34.6922, 149.8769],
  [-34.6895, 149.8729],
  [-34.6868, 149.8705],
  [-34.6851, 149.8673],
  [-34.6847, 149.8636],
  [-34.6866, 149.8567],
  [-34.6880, 149.8545],
  [-34.6902, 149.8530],
  [-34.7036, 149.8493],
  [-34.7058, 149.8475],
  [-34.7071, 149.8443],
  [-34.7072, 149.8409],
  [-34.7079, 149.8384],
  [-34.7101, 149.8362],
  [-34.7193, 149.8320],
  [-34.7219, 149.8295],
  [-34.7235, 149.8259],
  [-34.7235, 149.8225],
  [-34.7214, 149.8164],
  [-34.7212, 149.8144],
  [-34.7214, 149.8125],
  [-34.7227, 149.8085],
  [-34.7241, 149.7978],
  [-34.7229, 149.7625],
  [-34.7232, 149.7602],
  [-34.7247, 149.7567],
  [-34.7281, 149.7526],
  [-34.7308, 149.7507],
  [-34.7393, 149.7476],
  [-34.7427, 149.7466],
  [-34.7453, 149.7445],
  [-34.7466, 149.7424],
  [-34.7503, 149.7313],
  [-34.7522, 149.7254],
  [-34.7537, 149.7232],
  [-34.7583, 149.7193]
];

const goldburntenMileBankDeviationStart = [
  [-34.7583, 149.7193],
  [-34.7624, 149.7158],
  [-34.7637, 149.7148],
  [-34.7661, 149.7132],
  [-34.7730, 149.7102],
  [-34.7748, 149.7090],
  [-34.7796, 149.7048],
  [-34.7806, 149.7034],
  [-34.7809, 149.7014],
  [-34.7806, 149.6971],
  [-34.7809, 149.6951],
  [-34.7820, 149.6933],
  [-34.7887, 149.6851],
  [-34.7894, 149.6839],
  [-34.7898, 149.6823],
  [-34.7897, 149.6780],
  [-34.7898, 149.6683],
  [-34.7895, 149.6669],
  [-34.7890, 149.6659],
  [-34.7881, 149.6646],
  [-34.7871, 149.6626],
  [-34.7866, 149.6595],
  [-34.7867, 149.6572],
  [-34.7864, 149.6546],
  [-34.7855, 149.6518],
  [-34.7851, 149.6483],
  [-34.7861, 149.6380],
  [-34.7867, 149.6134],
  [-34.7861, 149.6110],
  [-34.7851, 149.6090],
  [-34.7845, 149.6068],
  [-34.7843, 149.6043],
  [-34.7852, 149.5980],
  [-34.7848, 149.5948],
  [-34.7830, 149.5892],
  [-34.7826, 149.5850],
  [-34.7907, 149.4986],
  [-34.7905, 149.4948],
  [-34.7889, 149.4899],
  [-34.7835, 149.4804],
  [-34.7817, 149.4758],
  [-34.7807, 149.4704],
  [-34.7809, 149.4652],
  [-34.7840, 149.4434],
  [-34.7847, 149.4414],
  [-34.7856, 149.4396],
  [-34.7878, 149.4371],
  [-34.7888, 149.4347],
  [-34.7888, 149.4312],
  [-34.7876, 149.4278],
  [-34.7872, 149.4260],
  [-34.7871, 149.4241],
  [-34.7876, 149.4217],
  [-34.7884, 149.4187],
  [-34.7882, 149.4159],
  [-34.7869, 149.4118],
  [-34.7859, 149.4110],
  [-34.7834, 149.4072],
  [-34.7759, 149.3986],
  [-34.7748, 149.3969],
  [-34.7733, 149.3957],
  [-34.7723, 149.3944],
  [-34.7718, 149.3922],
  [-34.7722, 149.3901],
  [-34.7735, 149.3884],
  [-34.7753, 149.3877]
];

const tenMileBankDeviationStartLerida = [
  [-34.7753, 149.3877],
  [-34.7778, 149.3871],
  [-34.7841, 149.3840],
  [-34.7853, 149.3823],
  [-34.7856, 149.3802],
  [-34.7852, 149.3786],
  [-34.7835, 149.3767],
  [-34.7786, 149.3751],
  [-34.7769, 149.3737],
  [-34.7763, 149.3721],
  [-34.7758, 149.3694],
  [-34.7749, 149.3678],
  [-34.7732, 149.3662],
  [-34.7721, 149.3646],
  [-34.7696, 149.3590],
  [-34.7653, 149.3501],
  [-34.7644, 149.3480],
  [-34.7635, 149.3440],
  [-34.7627, 149.3423],
  [-34.7617, 149.3413],
  [-34.7600, 149.3400],
  [-34.7584, 149.3381],
  [-34.7576, 149.3357],
  [-34.7576, 149.3291],
  [-34.7574, 149.3266],
  [-34.7568, 149.3237],
  [-34.7569, 149.3211],
  [-34.7574, 149.3187],
  [-34.7584, 149.3160]
];

const tenMileBankDeviation = [
  [-34.7753, 149.3877],
  [-34.7762, 149.3878],
  [-34.7796, 149.3884],
  [-34.7807, 149.3883],
  [-34.7819, 149.3877],
  [-34.7833, 149.3864],
  [-34.7851, 149.3858],
  [-34.7872, 149.3858],
  [-34.7886, 149.3856],
  [-34.7932, 149.3840],
  [-34.7944, 149.3829],
  [-34.7949, 149.3814],
  [-34.7946, 149.3795],
  [-34.7933, 149.3781],
  [-34.7914, 149.3776],
  [-34.7898, 149.3779],
  [-34.7885, 149.3776],
  [-34.7875, 149.3766],
  [-34.7867, 149.3747],
  [-34.7859, 149.3739],
  [-34.7847, 149.3735],
  [-34.7829, 149.3733],
  [-34.7789, 149.3719],
  [-34.7778, 149.3703],
  [-34.7778, 149.3686],
  [-34.7777, 149.3672],
  [-34.7768, 149.3656],
  [-34.7756, 149.3649],
  [-34.7738, 149.3648],
  [-34.7725, 149.3638],
  [-34.7717, 149.3623],
  [-34.7703, 149.3612],
  [-34.7693, 149.3611],
  [-34.7671, 149.3614],
  [-34.7657, 149.3607],
  [-34.7648, 149.3593],
  [-34.7647, 149.3575],
  [-34.7653, 149.3561],
  [-34.7664, 149.3548],
  [-34.7670, 149.3534],
  [-34.7669, 149.3516],
  [-34.7662, 149.3502],
  [-34.7652, 149.3491],
  [-34.7644, 149.3472],
  [-34.7634, 149.3424],
  [-34.7628, 149.3412],
  [-34.7617, 149.3404],
  [-34.7604, 149.3399],
  [-34.7594, 149.3391],
  [-34.7583, 149.3370],
  [-34.7578, 149.3354],
  [-34.7579, 149.3338],
  [-34.7583, 149.3319],
  [-34.7586, 149.3289],
  [-34.7580, 149.3265],
  [-34.7572, 149.3249],
  [-34.7558, 149.3233],
  [-34.7550, 149.3216],
  [-34.7552, 149.3195],
  [-34.7560, 149.3180],
  [-34.7575, 149.3169],
  [-34.7584, 149.3160]
];

const leridaGunning = [
  [-34.7584, 149.3160],
  [-34.7606, 149.3106],
  [-34.7611, 149.3082],
  [-34.7610, 149.3063],
  [-34.7603, 149.3044],
  [-34.7524, 149.2947],
  [-34.7518, 149.2937],
  [-34.7515, 149.2923],
  [-34.7514, 149.2902],
  [-34.7507, 149.2869],
  [-34.7495, 149.2849],
  [-34.7490, 149.2834],
  [-34.7489, 149.2821],
  [-34.7497, 149.2765],
  [-34.7507, 149.2732],
  [-34.7534, 149.2691],
  [-34.7547, 149.2682],
  [-34.7563, 149.2680],
  [-34.7585, 149.2687],
  [-34.7621, 149.2693],
  [-34.7655, 149.2687],
  [-34.7681, 149.2676],
  [-34.7697, 149.2672],
  [-34.7717, 149.2675],
  [-34.7729, 149.2675],
  [-34.7757, 149.2667],
  [-34.7772, 149.2651],
  [-34.7797, 149.2610]
];

const gunningMundoonDeviationStart = [
  [-34.7797, 149.2610],
  [-34.7822, 149.2573],
  [-34.7829, 149.2545],
  [-34.7830, 149.2492],
  [-34.7835, 149.2467],
  [-34.7845, 149.2448],
  [-34.7851, 149.2420],
  [-34.7849, 149.2397],
  [-34.7851, 149.2374],
  [-34.7850, 149.2351],
  [-34.7840, 149.2330],
  [-34.7828, 149.2317],
  [-34.7820, 149.2304],
  [-34.7800, 149.2240],
  [-34.7796, 149.2219],
  [-34.7800, 149.2181],
  [-34.7807, 149.2167],
  [-34.7817, 149.2159],
  [-34.7829, 149.2158],
  [-34.7844, 149.2162],
  [-34.7861, 149.2157],
  [-34.7872, 149.2138],
  [-34.7869, 149.2117],
  [-34.7858, 149.2104],
  [-34.7841, 149.2097],
  [-34.7833, 149.2088],
  [-34.7828, 149.2069],
  [-34.7833, 149.2021],
  [-34.7842, 149.1982],
  [-34.7837, 149.1889],
  [-34.7829, 149.1854],
  [-34.7770, 149.1711],
  [-34.7763, 149.1681],
  [-34.7764, 149.1653],
  [-34.7769, 149.1636],
  [-34.7782, 149.1616],
  [-34.7790, 149.1594],
  [-34.7790, 149.1560],
  [-34.7772, 149.1508],
  [-34.7769, 149.1465],
  [-34.7776, 149.1427],
  [-34.7790, 149.1402],
  [-34.7814, 149.1380],
  [-34.7825, 149.1373],
  [-34.7838, 149.1371],
  [-34.7864, 149.1378],
  [-34.7883, 149.1372],
  [-34.7901, 149.1348],
  [-34.7908, 149.1314],
  [-34.7896, 149.1279],
  [-34.7861, 149.1241],
  [-34.7848, 149.1218],
  [-34.7843, 149.1197],
  [-34.7839, 149.1115],
  [-34.7842, 149.1096],
  [-34.7851, 149.1075],
  [-34.7884, 149.1028],
  [-34.7894, 149.0999],
  [-34.7890, 149.0966],
  [-34.7835, 149.0835],
  [-34.7828, 149.0804],
  [-34.7819, 149.0627],
  [-34.7821, 149.0601],
  [-34.7839, 149.0501],
];

const mundoonDeviationStartCoolalieTemporaryJunction = [
  [-34.7839, 149.0501],
  [-34.7847, 149.0455],
  [-34.7846, 149.0434],
  [-34.7837, 149.0409],
  [-34.7825, 149.0394],
  [-34.7798, 149.0371],
  [-34.7783, 149.0346],
  [-34.7779, 149.0329],
  [-34.7780, 149.0311],
  [-34.7788, 149.0269],
  [-34.7786, 149.0248],
  [-34.7769, 149.0206],
  [-34.7765, 149.0183],
  [-34.7766, 149.0157],
  [-34.7774, 149.0136],
  [-34.7785, 149.0114],
  [-34.7794, 149.0103],
  [-34.7806, 149.0094]
];

const mundoonDeviation = [
  [-34.7839, 149.0501],
  [-34.7846, 149.0482],
  [-34.7866, 149.0448],
  [-34.7871, 149.0430],
  [-34.7867, 149.0406],
  [-34.7853, 149.0382],
  [-34.7846, 149.0364],
  [-34.7843, 149.0343],
  [-34.7848, 149.0278],
  [-34.7849, 149.0251],
  [-34.7842, 149.0233],
  [-34.7829, 149.0223],
  [-34.7794, 149.0218],
  [-34.7749, 149.0220],
  [-34.7730, 149.0211],
  [-34.7717, 149.0188],
  [-34.7718, 149.0168],
  [-34.7728, 149.0151],
  [-34.7750, 149.0136],
  [-34.7774, 149.0129],
  [-34.7786, 149.0121],
  [-34.7796, 149.0107],
  [-34.7806, 149.0094]
];

const coolalieTemporaryJunctionBowning = [
  [-34.7806, 149.0094],
  [-34.7825, 149.0088],
  [-34.7851, 149.0088],
  [-34.7882, 149.0077],
  [-34.7918, 149.0051],
  [-34.7933, 149.0028],
  [-34.7938, 148.9996],
  [-34.7926, 148.9957],
  [-34.7886, 148.9896],
  [-34.7845, 148.9811],
  [-34.7845, 148.9811],
  [-34.7837, 148.9761],
  [-34.7830, 148.9523],
  [-34.7835, 148.9487],
  [-34.7854, 148.9449],
  [-34.7968, 148.9306],
  [-34.7978, 148.9296],
  [-34.7989, 148.9292],
  [-34.8010, 148.9289],
  [-34.8039, 148.9275],
  [-34.8063, 148.9247],
  [-34.8079, 148.9219],
  [-34.8086, 148.9190],
  [-34.8088, 148.9164],
  [-34.8107, 148.8914],
  [-34.8098, 148.8876],
  [-34.8081, 148.8851],
  [-34.7995, 148.8781],
  [-34.7972, 148.8766],
  [-34.7942, 148.8750],
  [-34.7918, 148.8729],
  [-34.7897, 148.8702],
  [-34.7873, 148.8686],
  [-34.7851, 148.8678],
  [-34.7840, 148.8671],
  [-34.7832, 148.8660],
  [-34.7811, 148.8614],
  [-34.7800, 148.8599],
  [-34.7785, 148.8590],
  [-34.7748, 148.8577],
  [-34.7734, 148.8565],
  [-34.7727, 148.8548],
  [-34.7727, 148.8530],
  [-34.7730, 148.8512],
  [-34.7730, 148.8495],
  [-34.7715, 148.8401],
  [-34.7710, 148.8385],
  [-34.7699, 148.8370],
  [-34.7675, 148.8353],
  [-34.7657, 148.8328],
  [-34.7647, 148.8299],
  [-34.7646, 148.8272],
  [-34.7656, 148.8202]
];

const bowningMyloraTemporaryJunction = [
  [-34.7656, 148.8202],
  [-34.7659, 148.8175],
  [-34.7657, 148.8141],
  [-34.7648, 148.8110],
  [-34.7635, 148.8088],
  [-34.7610, 148.8066],
  [-34.7590, 148.8055],
  [-34.7582, 148.8048],
  [-34.7574, 148.8036],
  [-34.7515, 148.7919],
  [-34.7478, 148.7823],
  [-34.7459, 148.7743],
  [-34.7452, 148.7702],
  [-34.7452, 148.7696]
];

const myloraTemporaryJunctionIllalongCreek = [
  [-34.7452, 148.7696],
  [-34.7447, 148.7656],
  [-34.7436, 148.7624],
  [-34.7420, 148.7604],
  [-34.7386, 148.7584],
  [-34.7363, 148.7559],
  [-34.7348, 148.7523],
  [-34.7341, 148.7484],
  [-34.7344, 148.7462],
  [-34.7369, 148.7415],
  [-34.7374, 148.7386],
  [-34.7366, 148.7363],
  [-34.7338, 148.7336],
  [-34.7329, 148.7318],
  [-34.7324, 148.7298],
  [-34.7324, 148.7289],
  [-34.7316, 148.7259],
  [-34.7316, 148.7253],
  [-34.7317, 148.7248],
  [-34.7325, 148.7236],
  [-34.7330, 148.7231],
  [-34.7331, 148.7222],
  [-34.7328, 148.7191],
  [-34.7324, 148.7157],
  [-34.7322, 148.7114],
  [-34.7310, 148.7066],
  [-34.7258, 148.6965],
  [-34.7249, 148.6955],
  [-34.7235, 148.6948],
  [-34.7221, 148.6941],
  [-34.7206, 148.6925],
  [-34.7200, 148.6913],
  [-34.7199, 148.6897],
  [-34.7206, 148.6880],
  [-34.7219, 148.6856],
  [-34.7226, 148.6823],
  [-34.7231, 148.6771],
  [-34.7227, 148.6751],
  [-34.7213, 148.6719],
  [-34.7208, 148.6699],
  [-34.7204, 148.6649],
  [-34.7192, 148.6624]
];

const goondahDeviation = [
  [-34.7452, 148.7696],
  [-34.7452, 148.7676],
  [-34.7458, 148.7646],
  [-34.7456, 148.7621],
  [-34.7445, 148.7600],
  [-34.7431, 148.7588],
  [-34.7407, 148.7578],
  [-34.7378, 148.7553],
  [-34.7361, 148.7526],
  [-34.7354, 148.7502],
  [-34.7357, 148.7482],
  [-34.7367, 148.7465],
  [-34.7411, 148.7434],
  [-34.7431, 148.7423],
  [-34.7442, 148.7405],
  [-34.7443, 148.7381],
  [-34.7434, 148.7362],
  [-34.7419, 148.7353],
  [-34.7401, 148.7351],
  [-34.7376, 148.7347],
  [-34.7348, 148.7333],
  [-34.7250, 148.7249],
  [-34.7224, 148.7233],
  [-34.7208, 148.7221],
  [-34.7195, 148.7202],
  [-34.7186, 148.7175],
  [-34.7182, 148.7150],
  [-34.7178, 148.7139],
  [-34.7171, 148.7126],
  [-34.7166, 148.7112],
  [-34.7165, 148.7095],
  [-34.7177, 148.6983],
  [-34.7176, 148.6967],
  [-34.7173, 148.6956],
  [-34.7172, 148.6934],
  [-34.7176, 148.6921],
  [-34.7186, 148.6903],
  [-34.7192, 148.6879],
  [-34.7184, 148.6855],
  [-34.7169, 148.6840],
  [-34.7159, 148.6825],
  [-34.7156, 148.6806],
  [-34.7160, 148.6784],
  [-34.7176, 148.6752],
  [-34.7181, 148.6735],
  [-34.7192, 148.6637],
  [-34.7192, 148.6624]
];

const illalongCreekBinalongDeviationStart = [
  [-34.7192, 148.6624],
  [-34.7190, 148.6604],
  [-34.7178, 148.6584],
  [-34.7160, 148.6574],
  [-34.7130, 148.6572],
  [-34.7113, 148.6565],
  [-34.7088, 148.6550],
  [-34.7065, 148.6548],
  [-34.7000, 148.6558],
  [-34.6982, 148.6558],
  [-34.6905, 148.6545],
  [-34.6884, 148.6534],
  [-34.6867, 148.6516],
  [-34.6843, 148.6474],
  [-34.6826, 148.6455],
  [-34.6816, 148.6449]
];

const binalongDeviationStartBinalong1st = [
  [-34.6816, 148.6449],
  [-34.6711, 148.6356]
];

const binalong1stBinalongDeviationEnd = [
  [-34.6711, 148.6356],
  [-34.6704, 148.6354],
  [-34.6688, 148.6354],
  [-34.6644, 148.6368]
];

const binalongDeviation = [
  [-34.6816, 148.6449],
  [-34.6778, 148.6430],
  [-34.6761, 148.6418],
  [-34.6707, 148.6368],
  [-34.6694, 148.6360],
  [-34.6677, 148.6361],
  [-34.6644, 148.6368]
];

const binalongDeviationEndGalongTemporaryJunction = [
  [-34.6644, 148.6368],
  [-34.6630, 148.6365],
  [-34.6611, 148.6356],
  [-34.6564, 148.6313],
  [-34.6529, 148.6297],
  [-34.6478, 148.6293],
  [-34.6461, 148.6287],
  [-34.6447, 148.6275],
  [-34.6415, 148.6223],
  [-34.6405, 148.6186],
  [-34.6405, 148.6159],
  [-34.6394, 148.6112],
  [-34.6368, 148.6070],
  [-34.6294, 148.6004],
  [-34.6283, 148.5987],
  [-34.6276, 148.5964],
  [-34.6270, 148.5920],
  [-34.6259, 148.5894],
  [-34.6230, 148.5859],
  [-34.6213, 148.5847],
  [-34.6193, 148.5841],
  [-34.6179, 148.5824],
  [-34.6125, 148.5705],
  [-34.6108, 148.5688],
  [-34.6093, 148.5685]
];

const galongTemporaryJunctionRockyPondsTemporaryJunction = [
  [-34.6093, 148.5685],
  [-34.6071, 148.5674],
  [-34.6060, 148.5657],
  [-34.6050, 148.5628],
  [-34.6042, 148.5614],
  [-34.6031, 148.5604],
  [-34.5993, 148.5585],
  [-34.5971, 148.5572],
  [-34.5959, 148.5555],
  [-34.5957, 148.5536],
  [-34.5959, 148.5525],
  [-34.5976, 148.5451],
  [-34.5977, 148.5431],
  [-34.5973, 148.5407],
  [-34.5965, 148.5388],
  [-34.5951, 148.5364],
  [-34.5945, 148.5344],
  [-34.5924, 148.5193],
  [-34.5917, 148.5169],
  [-34.5897, 148.5132],
  [-34.5892, 148.5113],
  [-34.5887, 148.5079],
  [-34.5879, 148.5055],
  [-34.5847, 148.5007],
  [-34.5841, 148.4992],
  [-34.5840, 148.4977],
  [-34.5841, 148.4949],
  [-34.5837, 148.4929],
  [-34.5822, 148.4911],
  [-34.5808, 148.4907],
  [-34.5795, 148.4907],
  [-34.5769, 148.4913],
  [-34.5750, 148.4910],
  [-34.5731, 148.4898],
  [-34.5720, 148.4882],
  [-34.5707, 148.4852]
];

const galongTemporaryJunctionRockyPondsTemporaryJunctionDeviation = [
  [-34.6093, 148.5685],
  [-34.6077, 148.5674],
  [-34.6067, 148.5659],
  [-34.6042, 148.5587],
  [-34.6039, 148.5543],
  [-34.6039, 148.5543],
  [-34.6052, 148.5469],
  [-34.6048, 148.5450],
  [-34.6035, 148.5434],
  [-34.6021, 148.5429],
  [-34.6006, 148.5431],
  [-34.5987, 148.5444],
  [-34.5976, 148.5446],
  [-34.5959, 148.5441],
  [-34.5948, 148.5427],
  [-34.5940, 148.5406],
  [-34.5931, 148.5395],
  [-34.5919, 148.5386],
  [-34.5913, 148.5379],
  [-34.5906, 148.5365],
  [-34.5905, 148.5345],
  [-34.5908, 148.5321],
  [-34.5906, 148.5302],
  [-34.5897, 148.5283],
  [-34.5883, 148.5269],
  [-34.5877, 148.5259],
  [-34.5873, 148.5242],
  [-34.5872, 148.5220],
  [-34.5869, 148.5209],
  [-34.5862, 148.5195],
  [-34.5847, 148.5179],
  [-34.5837, 148.5159],
  [-34.5826, 148.5064],
  [-34.5818, 148.5041],
  [-34.5805, 148.5020],
  [-34.5797, 148.4988],
  [-34.5789, 148.4973],
  [-34.5774, 148.4962],
  [-34.5748, 148.4957],
  [-34.5730, 148.4945],
  [-34.5717, 148.4918],
  [-34.5707, 148.4852]
];

const rockyPondsTemporaryJunctionHarden = [
  [-34.5707, 148.4852],
  [-34.5689, 148.4800],
  [-34.5686, 148.4786],
  [-34.5686, 148.4770],
  [-34.5715, 148.4620],
  [-34.5714, 148.4598],
  [-34.5694, 148.4512],
  [-34.5686, 148.4493],
  [-34.5664, 148.4461],
  [-34.5654, 148.4453],
  [-34.5629, 148.4443],
  [-34.5616, 148.4433],
  [-34.5568, 148.4374],
  [-34.5562, 148.4357],
  [-34.5562, 148.4339],
  [-34.5583, 148.4259],
  [-34.5585, 148.4230],
  [-34.5584, 148.4143],
  [-34.5579, 148.4123],
  [-34.5562, 148.4078],
  [-34.5559, 148.4054],
  [-34.5571, 148.3949],
  [-34.5577, 148.3930],
  [-34.5592, 148.3900],
  [-34.5597, 148.3874],
  [-34.5592, 148.3830],
  [-34.5584, 148.3806],
  [-34.5534, 148.3715]
];

const hardenMorrisonsHillDeviationStart = [
  [-34.5534, 148.3715],
  [-34.5519, 148.3686],
  [-34.5511, 148.3677],
  [-34.5497, 148.3670],
  [-34.5484, 148.3671],
  [-34.5470, 148.3675],
  [-34.5457, 148.3675],
  [-34.5443, 148.3667],
  [-34.5432, 148.3653],
  [-34.5416, 148.3602],
  [-34.5414, 148.3588],
  [-34.5417, 148.3572],
  [-34.5427, 148.3554],
  [-34.5464, 148.3517],
  [-34.5470, 148.3505],
  [-34.5473, 148.3489],
  [-34.5470, 148.3473],
  [-34.5457, 148.3447],
  [-34.5452, 148.3432],
  [-34.5443, 148.3385],
  [-34.5434, 148.3368],
  [-34.5422, 148.3355],
  [-34.5415, 148.3342],
  [-34.5396, 148.3281],
  [-34.5389, 148.3269],
  [-34.5383, 148.3262],
  [-34.5369, 148.3252],
  [-34.5359, 148.3239],
  [-34.5347, 148.3212],
  [-34.5320, 148.3172],
  [-34.5313, 148.3155],
  [-34.5305, 148.3116],
  [-34.5298, 148.3102],
  [-34.5281, 148.3077],
  [-34.5276, 148.3059],
  [-34.5267, 148.2977],
  [-34.5248, 148.2929],
  [-34.5241, 148.2884],
  [-34.5241, 148.2868],
  [-34.5248, 148.2846],
  [-34.5265, 148.2811],
  [-34.5271, 148.2782],
  [-34.5266, 148.2762],
  [-34.5253, 148.2737],
  [-34.5250, 148.2720],
  [-34.5256, 148.2687],
  [-34.5262, 148.2675],
  [-34.5277, 148.2652],
  [-34.5285, 148.2639],
  [-34.5295, 148.2601],
  [-34.5294, 148.2581],
  [-34.5281, 148.2508],
  [-34.5285, 148.2458],
  [-34.5269, 148.2332],
  [-34.5230, 148.2191],
  [-34.5209, 148.2148],
  [-34.5206, 148.2119],
  [-34.5205, 148.2097],
  [-34.5225, 148.2037],
  [-34.5226, 148.2020],
  [-34.5221, 148.2002],
  [-34.5183, 148.1943],
  [-34.5176, 148.1925],
  [-34.5170, 148.1859],
  [-34.5171, 148.1838],
  [-34.5179, 148.1815],
  [-34.5181, 148.1792],
  [-34.5177, 148.1759],
  [-34.5172, 148.1732],
  [-34.5178, 148.1649],
  [-34.5182, 148.1631],
  [-34.5195, 148.1610],
  [-34.5248, 148.1551],
  [-34.5296, 148.1509],
  [-34.5312, 148.1488],
  [-34.5324, 148.1454],
  [-34.5335, 148.1345],
  [-34.5348, 148.1299],
  [-34.5360, 148.1277]
];

const morrisonsHillDeviation = [
  [-34.5360, 148.1277],
  [-34.5364, 148.1263],
  [-34.5365, 148.1246],
  [-34.5362, 148.1231],
  [-34.5335, 148.1159],
  [-34.5332, 148.1142],
  [-34.5333, 148.1127],
  [-34.5338, 148.1107],
  [-34.5340, 148.1088],
  [-34.5337, 148.1070],
  [-34.5316, 148.1020],
  [-34.5310, 148.0998],
  [-34.5312, 148.0982],
  [-34.5319, 148.0969],
  [-34.5334, 148.0960],
  [-34.5350, 148.0961],
  [-34.5372, 148.0971],
  [-34.5382, 148.0974],
  [-34.5394, 148.0974],
  [-34.5407, 148.0969],
  [-34.5416, 148.0969],
  [-34.5430, 148.0973],
  [-34.5440, 148.0983],
  [-34.5453, 148.1003],
  [-34.5468, 148.1016],
  [-34.5485, 148.1022],
  [-34.5500, 148.1021],
  [-34.5548, 148.1007],
  [-34.5584, 148.0985]
];

const morrisonsHillOld = [
  [-34.5360, 148.1277],
  [-34.5403, 148.1202],
  [-34.5425, 148.1155],
  [-34.5439, 148.1134],
  [-34.5455, 148.1119],
  [-34.5502, 148.1086],
  [-34.5515, 148.1075],
  [-34.5545, 148.1041],
  [-34.5560, 148.1010],
  [-34.5571, 148.0995],
  [-34.5584, 148.0985]
];

const morrisonsHillDeviationEndJindalee = [
  [-34.5584, 148.0985],
  [-34.5622, 148.0981],
  [-34.5632, 148.0978],
  [-34.5644, 148.0969],
  [-34.5655, 148.0952],
  [-34.5669, 148.0899],
  [-34.5677, 148.0883],
  [-34.5692, 148.0870],
  [-34.5707, 148.0866],
  [-34.5722, 148.0869],
  [-34.5745, 148.0884],
  [-34.5757, 148.0888],
  [-34.5783, 148.0892]
];

const jindaleeCootamundra = [
  [-34.5783, 148.0892],
  [-34.5793, 148.0893],
  [-34.5816, 148.0890],
  [-34.5845, 148.0878],
  [-34.5918, 148.0826],
  [-34.5940, 148.0813],
  [-34.6032, 148.0776],
  [-34.6078, 148.0745],
  [-34.6223, 148.0633],
  [-34.6236, 148.0617],
  [-34.6260, 148.0566],
  [-34.6291, 148.0475],
  [-34.6316, 148.0419],
  [-34.6323, 148.0409],
  [-34.6356, 148.0361],
  [-34.6412, 148.0307]
];

const cootamundraFrampton = [
  [-34.6412, 148.0307],
  [-34.6490, 148.0228],
  [-34.6511, 148.0207],
  [-34.6521, 148.0194],
  [-34.6528, 148.0175],
  [-34.6529, 148.0145],
  [-34.6520, 148.0121],
  [-34.6498, 148.0095],
  [-34.6492, 148.0082],
  [-34.6489, 148.0067],
  [-34.6491, 148.0053],
  [-34.6496, 148.0038],
  [-34.6498, 148.0025],
  [-34.6497, 148.0010],
  [-34.6490, 147.9995],
  [-34.6477, 147.9981],
  [-34.6468, 147.9965],
  [-34.6466, 147.9950],
  [-34.6468, 147.9937],
  [-34.6488, 147.9882],
  [-34.6492, 147.9863],
  [-34.6497, 147.9786],
  [-34.6503, 147.9768],
  [-34.6511, 147.9757],
  [-34.6523, 147.9751],
  [-34.6562, 147.9744],
  [-34.6578, 147.9735],
  [-34.6590, 147.9721],
  [-34.6595, 147.9710],
  [-34.6604, 147.9674],
  [-34.6608, 147.9664],
  [-34.6614, 147.9655],
  [-34.6629, 147.9642],
  [-34.6641, 147.9625],
  [-34.6645, 147.9610],
  [-34.6654, 147.9590],
  [-34.6671, 147.9565],
  [-34.6683, 147.9545],
  [-34.6693, 147.9515],
  [-34.6696, 147.9479],
  [-34.6695, 147.9448],
  [-34.6698, 147.9428],
  [-34.6705, 147.9410],
  [-34.6725, 147.9382],
  [-34.6736, 147.9372],
  [-34.6749, 147.9368],
  [-34.6760, 147.9372],
  [-34.6771, 147.9379],
  [-34.6784, 147.9380],
  [-34.6796, 147.9374],
  [-34.6803, 147.9363]
];

const framptonDeviation = [
  [-34.6803, 147.9363],
  [-34.6805, 147.9348],
  [-34.6803, 147.9338],
  [-34.6797, 147.9327],
  [-34.6771, 147.9299],
  [-34.6765, 147.9284],
  [-34.6765, 147.9271],
  [-34.6770, 147.9255],
  [-34.6778, 147.9240],
  [-34.6780, 147.9227],
  [-34.6779, 147.9210],
  [-34.6782, 147.9194],
  [-34.6789, 147.9183],
  [-34.6801, 147.9176],
  [-34.6810, 147.9174],
  [-34.6821, 147.9178],
  [-34.6880, 147.9230],
  [-34.6888, 147.9234],
  [-34.6902, 147.9236]
];

const framptonOld = [
  [-34.6803, 147.9363],
  [-34.6819, 147.9313],
  [-34.6827, 147.9295],
  [-34.6839, 147.9276],
  [-34.6857, 147.9257],
  [-34.6882, 147.9242],
  [-34.6902, 147.9236]
];

const framptonBethungraDeviationStart = [
  [-34.6902, 147.9236],
  [-34.6970, 147.9223],
  [-34.6987, 147.9215],
  [-34.7148, 147.9084],
  [-34.7170, 147.9066],
  [-34.7185, 147.9050],
  [-34.7205, 147.9012],
  [-34.7225, 147.8986],
  [-34.7252, 147.8962],
  [-34.7266, 147.8951],
  [-34.7282, 147.8944],
  [-34.7301, 147.8942],
  [-34.7324, 147.8947],
  [-34.7367, 147.8963],
  [-34.7384, 147.8966],
  [-34.7406, 147.8961]
];

const bethungraOld = [
  [-34.7406, 147.8961],
  [-34.7418, 147.8956],
  [-34.7427, 147.8952],
  [-34.7439, 147.8943],
  [-34.7445, 147.8935],
  [-34.7476, 147.8879],
  [-34.7480, 147.8868],
  [-34.7480, 147.8853],
  [-34.7476, 147.8834],
  [-34.7472, 147.8815],
  [-34.7475, 147.8781],
  [-34.7484, 147.8757],
  [-34.7496, 147.8746],
  [-34.7515, 147.8743],
  [-34.7531, 147.8749],
  [-34.7545, 147.8753],
  [-34.7558, 147.8752],
  [-34.7569, 147.8742]
];

const bethrungraSpiralStart = [
  [-34.7406, 147.8961],
  [-34.7416, 147.8955],
  [-34.7426, 147.8947],
  [-34.7438, 147.8935],
  [-34.7445, 147.8929],
  [-34.7457, 147.8924],
  [-34.7467, 147.8922],
  [-34.7476, 147.8920],
  [-34.7482, 147.8917],
  [-34.7492, 147.8908],
  [-34.7498, 147.8895],
  [-34.7501, 147.8884],
  [-34.7501, 147.8871],
  [-34.7497, 147.8858],
  [-34.7493, 147.8850],
  [-34.7486, 147.8841],
  [-34.7480, 147.8830],
  [-34.7477, 147.8819],
  [-34.7477, 147.8808],
  [-34.7479, 147.8791],
  [-34.7482, 147.8782],
  [-34.7488, 147.8774],
  [-34.7497, 147.8768],
  [-34.7504, 147.8767],
  [-34.7513, 147.8769],
  [-34.7521, 147.8775],
  [-34.7526, 147.8782],
  [-34.7532, 147.8797],
  [-34.7533, 147.8803],
  [-34.7533, 147.8809],
  [-34.7532, 147.8819],
  [-34.7527, 147.8828],
  [-34.7523, 147.8833],
  [-34.7502, 147.8849],
  [-34.7498, 147.8851]
];

const bethrungraSpiralNo1Tunnel = [
  [-34.7498, 147.8851],
  [-34.7491, 147.8851]
];

const bethrungraTunnel1Tunnel2 = [
  [-34.7491, 147.8851],
  [-34.7488, 147.8851],
  [-34.7483, 147.8849]
];

const bethrungraSpiralNo2Tunnel = [
  [-34.7483, 147.8849],
  [-34.7477, 147.8845]
];

const bethrungraSpiralEnd = [
  [-34.7477, 147.8845],
  [-34.7471, 147.8838],
  [-34.7467, 147.8827],
  [-34.7467, 147.8816],
  [-34.7472, 147.8777],
  [-34.7483, 147.8743],
  [-34.7491, 147.8730],
  [-34.7501, 147.8725],
  [-34.7511, 147.8724],
  [-34.7525, 147.8731],
  [-34.7534, 147.8742],
  [-34.7547, 147.8749],
  [-34.7558, 147.8749],
  [-34.7569, 147.8742]
];

const bethungraDeviationEndBethungra = [
  [-34.7569, 147.8742],
  [-34.7627, 147.8681],
  [-34.7639, 147.8664],
  [-34.7645, 147.8639],
  [-34.7644, 147.8615]
];

const bethungraJunee = [
  [-34.7644, 147.8615],
  [-34.7628, 147.8541],
  [-34.7628, 147.8513],
  [-34.7636, 147.8485],
  [-34.7880, 147.8015],
  [-34.7897, 147.7992],
  [-34.7926, 147.7964],
  [-34.7941, 147.7941],
  [-34.7943, 147.7915],
  [-34.7939, 147.7881],
  [-34.7941, 147.7850],
  [-34.7951, 147.7816],
  [-34.8223, 147.7263],
  [-34.8246, 147.7200],
  [-34.8305, 147.7106],
  [-34.8317, 147.7080],
  [-34.8322, 147.7051],
  [-34.8318, 147.6915],
  [-34.8322, 147.6869],
  [-34.8377, 147.6627],
  [-34.8397, 147.6573],
  [-34.8415, 147.6529],
  [-34.8462, 147.6444],
  [-34.8477, 147.6407],
  [-34.8485, 147.6367],
  [-34.8489, 147.6292],
  [-34.8498, 147.6250],
  [-34.8517, 147.6210],
  [-34.8527, 147.6183],
  [-34.8531, 147.6158],
  [-34.8527, 147.6105],
  [-34.8527, 147.6087],
  [-34.8532, 147.6066],
  [-34.8533, 147.6035],
  [-34.8519, 147.5955],
  [-34.8519, 147.5932],
  [-34.8526, 147.5907],
  [-34.8540, 147.5885],
  [-34.8552, 147.5875],
  [-34.8568, 147.5868],
  [-34.8702, 147.5837]
];

const juneeBomen = [
  [-34.8702, 147.5837],
  [-34.8838, 147.5807],
  [-34.8865, 147.5797],
  [-34.8892, 147.5778],
  [-34.8980, 147.5710],
  [-34.9039, 147.5642],
  [-34.9067, 147.5623],
  [-34.9082, 147.5605],
  [-34.9100, 147.5593],
  [-34.9158, 147.5575],
  [-34.9173, 147.5566],
  [-34.9478, 147.5333],
  [-34.9902, 147.4899],
  [-35.0036, 147.4786],
  [-35.0059, 147.4751],
  [-35.0074, 147.4696],
  [-35.0102, 147.4628],
  [-35.0126, 147.4590],
  [-35.0141, 147.4575],
  [-35.0157, 147.4565],
  [-35.0208, 147.4543],
  [-35.0225, 147.4531],
  [-35.0246, 147.4504],
  [-35.0256, 147.4479],
  [-35.0268, 147.4461],
  [-35.0350, 147.4359],
  [-35.0386, 147.4323],
  [-35.0430, 147.4297],
  [-35.0477, 147.4283],
  [-35.0500, 147.4281],
  [-35.0561, 147.4279],
  [-35.0585, 147.4271],
  [-35.0609, 147.4253],
  [-35.0623, 147.4228],
  [-35.0631, 147.4208],
  [-35.0641, 147.4190],
  [-35.0659, 147.4174],
  [-35.0682, 147.4163],
  [-35.0693, 147.4158],
  [-35.0723, 147.4145]
];

const bomenWaggaWagga = [
  [-35.0723, 147.4145],
  [-35.0731, 147.4141],
  [-35.0758, 147.4130],
  [-35.0773, 147.4119],
  [-35.0790, 147.4097],
  [-35.0799, 147.4075],
  [-35.0806, 147.4049],
  [-35.0819, 147.4023],
  [-35.0874, 147.3963],
  [-35.0910, 147.3939],
  [-35.1072, 147.3896],
  [-35.1096, 147.3886],
  [-35.1122, 147.3865],
  [-35.1194, 147.3784],
  [-35.1203, 147.3770],
  [-35.1209, 147.3753],
  [-35.1210, 147.3728],
  [-35.1204, 147.3684]
];

const waggaWaggaGreogery = [
  [-35.1204, 147.3684],
  [-35.1199, 147.3648],
  [-35.1198, 147.3632],
  [-35.1201, 147.3613],
  [-35.1341, 147.3191],
  [-35.1354, 147.3164],
  [-35.1372, 147.3143],
  [-35.1394, 147.3127],
  [-35.1413, 147.3118],
  [-35.1434, 147.3114],
  [-35.1441, 147.3113],
  [-35.1460, 147.3107],
  [-35.1558, 147.3056],
  [-35.1573, 147.3046],
  [-35.1586, 147.3036],
  [-35.1611, 147.3009],
  [-35.1647, 147.2968],
  [-35.1659, 147.2950],
  [-35.1670, 147.2920],
  [-35.1673, 147.2886],
  [-35.1673, 147.2854],
  [-35.1679, 147.2827],
  [-35.1693, 147.2800],
  [-35.1954, 147.2412],
  [-35.2654, 147.1370],
  [-35.2682, 147.1314],
  [-35.2701, 147.1253],
  [-35.2758, 147.1002],
  [-35.2768, 147.0974],
  [-35.2783, 147.0947],
  [-35.2804, 147.0926],
  [-35.2828, 147.0912],
  [-35.2852, 147.0907],
  [-35.3083, 147.0876],
  [-35.3152, 147.0859],
  [-35.3226, 147.0827],
  [-35.3328, 147.0757],
  [-35.3387, 147.0726],
  [-35.3437, 147.0712],
  [-35.3790, 147.0618],
  [-35.4207, 147.0507],
  [-35.4254, 147.0485],
  [-35.4484, 147.0326],
  [-35.4516, 147.0311],
  [-35.4541, 147.0304],
  [-35.4579, 147.0302],
  [-35.5116, 147.0347],
  [-35.5137, 147.0349],
  [-35.6291, 147.0444],
  [-35.6373, 147.0440],
  [-35.6550, 147.0405],
  [-35.6681, 147.0377],
  [-35.6720, 147.0369],
  [-35.6750, 147.0363],
  [-35.7148, 147.0283],
  [-35.7181, 147.0273],
  [-35.7834, 147.0058],
  [-35.7869, 147.0041],
  [-35.7900, 147.0015],
  [-35.7937, 146.9983],
  [-35.7969, 146.9965],
  [-35.8018, 146.9949],
  [-35.8056, 146.9938],
  [-35.8120, 146.9934],
  [-35.8366, 146.9951]
];

const gerogeryAlbury = [
  [-35.8366, 146.9951],
  [-35.9197, 147.0012],
  [-35.9642, 147.0044],
  [-35.9731, 147.0030],
  [-35.9775, 147.0012],
  [-35.9945, 146.9930],
  [-36.0276, 146.9775],
  [-36.0388, 146.9719],
  [-36.0461, 146.9658],
  [-36.0537, 146.9556],
  [-36.0736, 146.9283],
  [-36.0750, 146.9269],
  [-36.0765, 146.9262],
  [-36.0839, 146.9247]
];

const alburyVicBorder = [
  [-36.0839, 146.9247],
  [-36.0911, 146.9232],
  [-36.0933, 146.9222],
  [-36.0950, 146.9206],
  [-36.0961, 146.9185],
  [-36.0999, 146.9088]
];

export const mainSouthLine: Line = {
  name: 'Main South Line',
  state: 'NSW',
  segments: [
    {
      segments: [macarthurGlenleeJunction],
      history: {
        opened: {
          date: '1862-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1892-01-01',
          tracks: 2
        }, {
          date: '2000-01-01',
          electrified: undefined
        }]
      }
    },
    {
      segments: [glenleeJunctionNorthMenangle],
      history: {
        opened: {
          date: '1862-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1892-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [northMenanglePicton],
      history: {
        opened: {
          date: '1863-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1892-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        pictonPictonOldJunction,
        {
          date: '1919-07-13',
          original: [pictonOldStart, {
            name: 'Old Main Tunnel',
            segment: oldMainTunnel,
            type: 'tunnel'
          }, pictonOldEnd],
          deviation: [pictonOldJunctionPictonJunction]
        }
      ],
      history: {
        opened: {
          date: '1867-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1919-07-13',
          tracks: 2
        }]
      }
    },
    {
      segments: [pictonJunction],
      history: {
        opened: {
          date: '1919-07-13',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pictonLoop],
      history: {
        opened: {
          date: '1867-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        pictonPictonTunnel,
         {
          name: 'Picton Tunnel',
          segment: pictonTunnel,
          type: 'tunnel'
         },
         pictonTunnelRedbankTunnel,
         {
          date: '2012-11-30',
          original: [redbankOldStart, {
            name: 'Redbank Tunnel',
            segment: redbankTunnel,
            type: 'tunnel'
           }, redbankOldEnd],
          deviation: [redbankDeviation]
         },

         redbankTunneYerrinboolTunnel,
         {
          name: 'Yerrinbool Tunnel',
          segment: yerrinboolTunnel,
          type: 'tunnel'
         },
         yerrinboolTunnelAylmertonTunnel,
         {
          name: 'Aylmerton Tunnel',
          segment: aylmertonTunnel,
          type: 'tunnel'
         },
         aylmertonTunnelMittagongJunction
      ],
      history: {
        opened: {
          date: '1919-06-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mittagongJunctionMittagong],
      history: {
        opened: {
          date: '1867-03-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1919-07-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        mittagongMittagongDeviationStart,
        {
          date: '1919-03-02',
          original: [mittagongOldStart, {
            name: 'The Gib Tunnel (Old)',
            segment: theGibTunnelOld,
            type: 'tunnel'
          }, mittagongOldEnd],
          deviation: [mittagongTheGibTunnel, {
            name: 'The Gib Tunnel',
            segment: theGibTunnel,
            type: 'tunnel'
          }, theGibTunnelDeviationEnd]
        },
        mittagongDeviationEndMossVale
      ],
      history: {
        opened: {
          date: '1867-12-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1919-03-02',
          tracks: 2
        }]
      }
    },
    {
      segments: [mossValeMarulan],
      history: {
        opened: {
          date: '1868-08-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-10-04',
          tracks: 2
        }]
      }
    },
    {
      segments: [marulanGoldburn],
      history: {
        opened: {
          date: '1869-05-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-10-04',
          tracks: 2
        }]
      }
    },
    {
      segments: [{
        date: '1915-10-24',
        original: [tenMileBankDeviationStartLerida],
        deviation: [tenMileBankDeviation]
      }, goldburntenMileBankDeviationStart],
      history: {
        opened: {
          date: '1875-11-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-10-24',
          tracks: 2
        }]
      }
    },
    {
      segments: [leridaGunning],
      history: {
        opened: {
          date: '1875-11-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-02-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [gunningMundoonDeviationStart],
      history: {
        opened: {
          date: '1876-07-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-02-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [mundoonDeviationStartCoolalieTemporaryJunction],
      history: {
        opened: {
          date: '1876-07-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-02-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mundoonDeviation],
      history: {
        opened: {
          date: '1914-10-12',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [coolalieTemporaryJunctionBowning],
      history: {
        opened: {
          date: '1876-07-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1916-05-07',
          tracks: 2
        }]
      }
    },
    {
      segments: [bowningMyloraTemporaryJunction],
      history: {
        opened: {
          date: '1876-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1916-05-07',
          tracks: 2
        }]
      }
    },
    {
      segments: [myloraTemporaryJunctionIllalongCreek],
      history: {
        opened: {
          date: '1876-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1916-05-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goondahDeviation],
      history: {
        opened: {
          date: '1916-05-07',
          tracks: 2,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [illalongCreekBinalongDeviationStart],
      history: {
        opened: {
          date: '1876-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-12-22',
          tracks: 2
        }]
      }
    },
    {
      segments: [binalongDeviationStartBinalong1st],
      history: {
        opened: {
          date: '1876-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-12-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [{
        date: '1915-12-22',
        original: [binalong1stBinalongDeviationEnd],
        deviation: [binalongDeviation]
      }],
      history: {
        opened: {
          date: '1877-03-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-12-22',
          tracks: 2
        }]
      }
    },
    {
      segments: [{
        date: '1915-06-09',
        original: [galongTemporaryJunctionRockyPondsTemporaryJunction],
        deviation: [galongTemporaryJunctionRockyPondsTemporaryJunctionDeviation]
      }, binalongDeviationEndGalongTemporaryJunction],
      history: {
        opened: {
          date: '1877-03-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1915-06-09',
          tracks: 2
        }]
      }
    },
    {
      segments: [rockyPondsTemporaryJunctionHarden],
      history: {
        opened: {
          date: '1877-03-12',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1914-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        morrisonsHillDeviationEndJindalee,
        {
          date: '1900-08-05',
          original: [morrisonsHillOld],
          deviation: [morrisonsHillDeviation]
        },
        hardenMorrisonsHillDeviationStart
      ],
      history: {
        opened: {
          date: '1877-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1922-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [jindaleeCootamundra],
      history: {
        opened: {
          date: '1877-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1922-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [cootamundraFrampton],
      history: {
        opened: {
          date: '1878-04-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1922-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [framptonDeviation],
      history: {
        opened: {
          date: '1922-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        bethungraOld,
        framptonOld
      ],
      history: {
        opened: {
          date: '1878-04-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        bethungraDeviationEndBethungra,
        framptonBethungraDeviationStart
      ],
      history: {
        opened: {
          date: '1878-04-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1946-07-15',
          tracks: 2
        }]
      }
    },
    {
      segments: [
        bethrungraSpiralStart,
        {
          name: 'Bethrungra Spiral No 1 Tunnel',
          segment: bethrungraSpiralNo1Tunnel,
          type: 'tunnel'
        },
        bethrungraTunnel1Tunnel2,
        {
          name: 'Bethrungra Spiral No 2 Tunnel',
          segment: bethrungraSpiralNo2Tunnel,
          type: 'tunnel'
        },
        bethrungraSpiralEnd
      ],
      history: {
        opened: {
          date: '1946-07-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bethungraJunee],
      history: {
        opened: {
          date: '1878-07-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1942-10-18',
          tracks: 2
        }]
      }
    },
    {
      segments: [juneeBomen],
      history: {
        opened: {
          date: '1878-09-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bomenWaggaWagga],
      history: {
        opened: {
          date: '1879-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waggaWaggaGreogery],
      history: {
        opened: {
          date: '1880-09-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [gerogeryAlbury],
      history: {
        opened: {
          date: '1881-02-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [alburyVicBorder],
      history: {
        opened: {
          date: '1883-06-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy',
          parallelStandard: true
        },
        trackChange: [{
          date: '2011-06-26',
          gauge: 'standard',
          parallelStandard: false
        }]
      }
    }
  ]
};
