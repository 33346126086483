import { Line } from "../../../trackTypes";

const numurkahNathalia = [
  [-36.0877, 145.4404],
  [-36.0869, 145.4404],
  [-36.0862, 145.4400],
  [-36.0853, 145.4392],
  [-36.0847, 145.4379],
  [-36.0742, 145.4041],
  [-36.0595, 145.3679],
  [-36.0587, 145.3656],
  [-36.0535, 145.3389],
  [-36.0534, 145.3373],
  [-36.0535, 145.2252],
  [-36.0527, 145.2202],
  [-36.0515, 145.2171],
  [-36.0506, 145.2150],
  [-36.0502, 145.2132],
  [-36.0504, 145.2080]
];

const nathaliaPicola = [
  [-36.0504, 145.2080],
  [-36.0505, 145.1920],
  [-36.0500, 145.1892],
  [-36.0487, 145.1871],
  [-36.0424, 145.1809],
  [-36.0412, 145.1790],
  [-36.0351, 145.1650],
  [-36.0125, 145.1335],
  [-36.0035, 145.1250],
  [-36.0018, 145.1227],
  [-36.0002, 145.1195],
  [-35.9996, 145.1162],
  [-35.9998, 145.1042]
];

export const picola: Line = {
  name: 'Picola',
  state: 'VIC',
  segments: [
    {
      segments: [numurkahNathalia],
      history: {
        opened: {
          date: '1888-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [nathaliaPicola],
      history: {
        opened: {
          date: '1896-12-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    }
  ]
}
