import { Line } from "../../trackTypes";

const line = [
  [-31.9587, 141.4615],
  [-31.9514, 141.4728],
  [-31.9504, 141.4735],
  [-31.9477, 141.4744],
  [-31.9469, 141.4750],
  [-31.9450, 141.4777],
  [-31.9444, 141.4780],
  [-31.9429, 141.4782],
  [-31.9418, 141.4787],
  [-31.9413, 141.4793],
  [-31.9381, 141.4821],
  [-31.9306, 141.4864],
  [-31.9292, 141.4874],
  [-31.9280, 141.4891],
  [-31.9267, 141.4902],
  [-31.8911, 141.5102],
  [-31.8898, 141.5104],
  [-31.8848, 141.5091],
  [-31.8480, 141.5043],
  [-31.8386, 141.5030],
  [-31.8369, 141.5025],
  [-31.8325, 141.5010],
  [-31.8312, 141.5008],
  [-31.8066, 141.5009],
  [-31.7987, 141.4998],
  [-31.7974, 141.4998],
  [-31.7921, 141.5011],
  [-31.7905, 141.5020],
  [-31.7828, 141.5109],
  [-31.7808, 141.5152],
  [-31.7799, 141.5162],
  [-31.7791, 141.5166],
  [-31.7760, 141.5170],
  [-31.7696, 141.5201],
  [-31.7604, 141.5282],
  [-31.7586, 141.5300],
  [-31.7580, 141.5314],
  [-31.7569, 141.5354],
  [-31.7561, 141.5441],
  [-31.7551, 141.5466],
  [-31.7532, 141.5485],
  [-31.7481, 141.5517],
  [-31.7219, 141.5725],
  [-31.7207, 141.5729],
  [-31.7166, 141.5731],
  [-31.7143, 141.5739],
  [-31.7091, 141.5767],
  [-31.7072, 141.5773],
  [-31.6998, 141.5781],
  [-31.6942, 141.5774],
  [-31.6813, 141.5782],
  [-31.6740, 141.5775],
  [-31.6724, 141.5770],
  [-31.6684, 141.5742],
  [-31.6644, 141.5692],
  [-31.6623, 141.5675],
  [-31.6393, 141.5580],
  [-31.6376, 141.5578],
  [-31.6282, 141.5579],
  [-31.6269, 141.5577],
  [-31.6175, 141.5543],
  [-31.6020, 141.5516],
  [-31.5814, 141.5432],
  [-31.5787, 141.5430],
  [-31.5757, 141.5434],
  [-31.5737, 141.5431],
  [-31.5611, 141.5380],
  [-31.5473, 141.5339],
  [-31.5456, 141.5330],
  [-31.5295, 141.5220],
  [-31.5260, 141.5188],
  [-31.5237, 141.5177],
  [-31.4834, 141.5072],
  [-31.4729, 141.5018],
  [-31.4560, 141.4878],
  [-31.4533, 141.4825]
];

export const tarrawingee: Line = {
  name: 'Tarrawingee',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1891-06-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1898-01-01',
          status: 'closed'
        }, {
          date: '1930-01-01',
          status: 'closed'
        }, {
          date: '1931-04-01',
          status: 'closed'
        }, {
          date: '1899-09-07',
          status: 'open'
        }, {
          date: '1930-05-01',
          status: 'open'
        }]
      }
    }
  ]
};
