import { Line } from "../../../trackTypes";

const line = [
  [-37.7558, 142.02288],
  [-37.7563, 142.0217],
  [-37.7565, 142.0202],
  [-37.7564, 142.0186],
  [-37.7560, 142.0154],
  [-37.7554, 142.0134],
  [-37.7541, 142.0116],
  [-37.7494, 142.0080],
  [-37.7416, 142.0020],
  [-37.7398, 142.0002],
  [-37.7290, 141.9862],
  [-37.7275, 141.9836],
  [-37.6893, 141.8970],
  [-37.6646, 141.8655],
  [-37.6629, 141.8624],
  [-37.6622, 141.8591],
  [-37.6623, 141.8524],
  [-37.6629, 141.8502],
  [-37.6704, 141.8331],
  [-37.6706, 141.8288],
  [-37.6688, 141.8246],
  [-37.6610, 141.8175],
  [-37.6293, 141.7929],
  [-37.6265, 141.7902],
  [-37.6192, 141.7799],
  [-37.6107, 141.7631],
  [-37.6101, 141.7601],
  [-37.6103, 141.7572],
  [-37.6092, 141.7514],
  [-37.6066, 141.7482],
  [-37.6047, 141.7445],
  [-37.6030, 141.7427],
  [-37.5977, 141.7398],
  [-37.5960, 141.7372],
  [-37.5948, 141.7279],
  [-37.5950, 141.7252],
  [-37.5967, 141.7212],
  [-37.5971, 141.7194],
  [-37.5975, 141.7110],
  [-37.6017, 141.6967]
];

export const coleraine: Line = {
  name: 'Coleraine',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1888-11-20',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange:[{
          date: '1977-09-12',
          status: 'closed'
        }]
      }
    }
  ]
};
