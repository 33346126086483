import { Line } from "../../trackTypes";

const midlandJunctionZigZag = [
  [-31.8955, 116.0149],
  [-31.8960, 116.0156],
  [-31.8967, 116.0160],
  [-31.9099, 116.0160],
  [-31.9120, 116.0169],
  [-31.9175, 116.0238],
  [-31.9193, 116.0280],
  [-31.9207, 116.0300],
  [-31.9293, 116.0358],
  [-31.9299, 116.0370],
  [-31.9299, 116.0380],
  [-31.9290, 116.0403],
  [-31.9291, 116.0414],
  [-31.9297, 116.0422],
  [-31.9332, 116.0434],
  [-31.9365, 116.0433],
  [-31.9394, 116.0435],
  [-31.9401, 116.0439],
  [-31.9411, 116.0440],
  [-31.9422, 116.0440],
  [-31.9428, 116.0443],
  [-31.9431, 116.0448],
  [-31.9436, 116.0463]
];

const zigZagTwo = [
  [-31.9411, 116.0440],
  [-31.9398, 116.0444],
  [-31.9392, 116.0450],
  [-31.9385, 116.0463],
  [-31.9379, 116.0471],
  [-31.9373, 116.0471],
  [-31.9364, 116.0472],
  [-31.9348, 116.0479]
];

const zigZagThree = [
  [-31.9379, 116.0471],
  [-31.9387, 116.0465],
  [-31.9390, 116.0458],
  [-31.9397, 116.0452],
  [-31.9413, 116.0448],
  [-31.9423, 116.0450],
  [-31.9427, 116.0454],
  [-31.9429, 116.0460],
  [-31.9428, 116.0471],
  [-31.9433, 116.0489]
];

const zigZagFour = [
  [-31.9428, 116.0471],
  [-31.9427, 116.0461],
  [-31.9424, 116.0455],
  [-31.9419, 116.0453],
  [-31.9411, 116.0454],
  [-31.9402, 116.0459],
  [-31.9398, 116.0465],
  [-31.9395, 116.0473],
  [-31.9392, 116.0477],
  [-31.9380, 116.0488],
  [-31.9377, 116.0493],
  [-31.9378, 116.0500],
  [-31.9381, 116.0505],
  [-31.9393, 116.0512],
  [-31.9400, 116.0517],
  [-31.9410, 116.0531]
];

const zigZagKalamunda = [
  [-31.9393, 116.0512],
  [-31.9386, 116.0502],
  [-31.9386, 116.0494],
  [-31.9389, 116.0488],
  [-31.9410, 116.0480],
  [-31.9417, 116.0482],
  [-31.9420, 116.0487],
  [-31.9422, 116.0494],
  [-31.9427, 116.0498],
  [-31.9430, 116.0503],
  [-31.9434, 116.0510],
  [-31.9437, 116.0512],
  [-31.9444, 116.0511],
  [-31.9456, 116.0498],
  [-31.9462, 116.0496],
  [-31.9467, 116.0497],
  [-31.9470, 116.0501],
  [-31.9474, 116.0515],
  [-31.9476, 116.0517],
  [-31.9480, 116.0517],
  [-31.9492, 116.0508],
  [-31.9496, 116.0505],
  [-31.9502, 116.0505],
  [-31.9515, 116.0515],
  [-31.9521, 116.0514],
  [-31.9529, 116.0506],
  [-31.9535, 116.0506],
  [-31.9583, 116.0525],
  [-31.9598, 116.0527],
  [-31.9624, 116.0523],
  [-31.9633, 116.0525],
  [-31.9653, 116.0548],
  [-31.9660, 116.0568],
  [-31.9665, 116.0574],
  [-31.9716, 116.0602]
];

const kalamundaKarragullen = [
  [-31.9716, 116.0602],
  [-31.9725, 116.0606],
  [-31.9753, 116.0609],
  [-31.9773, 116.0610],
  [-31.9788, 116.0621],
  [-31.9802, 116.0641],
  [-31.9803, 116.0652],
  [-31.9814, 116.0665],
  [-31.9845, 116.0691],
  [-31.9854, 116.0699],
  [-31.9860, 116.0710],
  [-31.9879, 116.0724],
  [-31.9890, 116.0737],
  [-31.9898, 116.0756],
  [-31.9907, 116.0767],
  [-31.9919, 116.0769],
  [-31.9929, 116.0760],
  [-31.9938, 116.0757],
  [-31.9959, 116.0754],
  [-31.9973, 116.0757],
  [-31.9983, 116.0768],
  [-31.9986, 116.0776],
  [-31.9988, 116.0798],
  [-31.9991, 116.0806],
  [-31.9999, 116.0815],
  [-32.0002, 116.0821],
  [-32.0007, 116.0846],
  [-32.0012, 116.0854],
  [-32.0045, 116.0866],
  [-32.0051, 116.0866],
  [-32.0060, 116.0863],
  [-32.0068, 116.0864],
  [-32.0075, 116.0871],
  [-32.0079, 116.0882],
  [-32.0084, 116.0889],
  [-32.0112, 116.0907],
  [-32.0123, 116.0910],
  [-32.0130, 116.0907],
  [-32.0139, 116.0900],
  [-32.0147, 116.0899],
  [-32.0175, 116.0913],
  [-32.0193, 116.0930],
  [-32.0275, 116.0999],
  [-32.0282, 116.1001],
  [-32.0288, 116.0998],
  [-32.0294, 116.0992],
  [-32.0300, 116.0991],
  [-32.0314, 116.0996],
  [-32.0348, 116.1046],
  [-32.0352, 116.1060],
  [-32.0355, 116.1067],
  [-32.0358, 116.1072],
  [-32.0365, 116.1075],
  [-32.0373, 116.1073],
  [-32.0380, 116.1073],
  [-32.0385, 116.1077],
  [-32.0393, 116.1089],
  [-32.0400, 116.1093],
  [-32.0406, 116.1093],
  [-32.0412, 116.1088],
  [-32.0416, 116.1079],
  [-32.0413, 116.1065],
  [-32.0412, 116.1047],
  [-32.0422, 116.1021],
  [-32.0435, 116.1005],
  [-32.0444, 116.1000],
  [-32.0451, 116.1003],
  [-32.0463, 116.1011],
  [-32.0471, 116.1013],
  [-32.0477, 116.1007],
  [-32.0485, 116.0989],
  [-32.0491, 116.0984],
  [-32.0500, 116.0983],
  [-32.0511, 116.0976],
  [-32.0519, 116.0973],
  [-32.0527, 116.0980],
  [-32.0531, 116.0991],
  [-32.0536, 116.0998],
  [-32.0537, 116.1005],
  [-32.0536, 116.1010],
  [-32.0537, 116.1015],
  [-32.0541, 116.1024],
  [-32.0540, 116.1033],
  [-32.0543, 116.1041],
  [-32.0554, 116.1060],
  [-32.0560, 116.1066],
  [-32.0590, 116.1072],
  [-32.0600, 116.1069],
  [-32.0623, 116.1052],
  [-32.0640, 116.1047],
  [-32.0665, 116.1052],
  [-32.0682, 116.1053],
  [-32.0726, 116.1067],
  [-32.0741, 116.1066],
  [-32.0786, 116.1072],
  [-32.0793, 116.1077],
  [-32.0804, 116.1092],
  [-32.0824, 116.1108],
  [-32.0881, 116.1181]
];

export const upperDarlingRange: Line = {
  name: 'Upper Darling Range',
  state: 'WA',
  segments: [
    {
      segments: [midlandJunctionZigZag, zigZagTwo, zigZagThree, zigZagFour,zigZagKalamunda],
      history: {
        opened: {
          date: '1891-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-07-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kalamundaKarragullen],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1949-07-22',
          status: 'closed'
        }]
      }
    }
  ]
};
