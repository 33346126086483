import { Line } from "../../trackTypes";

const leesvilleOld = [
  [-40.8232, 145.1054],
  [-40.8238, 145.1050],
  [-40.8282, 145.0983],
  [-40.8285, 145.0975]
];

const leesvilleDeviation = [
  [-40.8306, 145.1096],
  [-40.8302, 145.1082],
  [-40.8285, 145.0975]
];

const junctionBroadmeadowsJunction = [
  [-40.8285, 145.0975],
  [-40.8282, 145.0921]
];

const broadmeadowsJunctionTrowuttaMillJunction = [
  [-40.8282, 145.0921],
  [-40.8280, 145.0851],
  [-40.8283, 145.0834],
  [-40.8296, 145.0775],
  [-40.8296, 145.0760],
  [-40.8290, 145.0730],
  [-40.8291, 145.0717],
  [-40.8303, 145.0682],
  [-40.8311, 145.0659],
  [-40.8341, 145.0539],
  [-40.8349, 145.0459],
  [-40.8355, 145.0447],
  [-40.8415, 145.0408],
  [-40.8424, 145.0406],
  [-40.8480, 145.0415],
  [-40.8487, 145.0413],
  [-40.8496, 145.0408],
  [-40.8501, 145.0399],
  [-40.8511, 145.0377]
];

const trowuttaMillJunctionBondTierForrest = [
  [-40.8511, 145.0377],
  [-40.8538, 145.0311],
  [-40.8544, 145.0303],
  [-40.8597, 145.0259],
  [-40.8606, 145.0244],
  [-40.8610, 145.0224],
  [-40.8610, 145.0163],
  [-40.8614, 145.0138],
  [-40.8620, 145.0125],
  [-40.8629, 145.0101],
  [-40.8644, 145.0085],
  [-40.8660, 145.0076],
  [-40.8669, 145.0067],
  [-40.8710, 145.0011],
  [-40.8718, 145.0004],
  [-40.8727, 144.9999],
  [-40.8731, 144.9987],
  [-40.8731, 144.9974],
  [-40.8723, 144.9959],
  [-40.8715, 144.9951],
  [-40.8708, 144.9946],
  [-40.8703, 144.9939],
  [-40.8695, 144.9913],
  [-40.8695, 144.9903],
  [-40.8699, 144.9888],
  [-40.8699, 144.9877],
  [-40.8694, 144.9859],
  [-40.8692, 144.9848],
  [-40.8693, 144.9838],
  [-40.8692, 144.9827],
  [-40.8677, 144.9799],
  [-40.8673, 144.9784],
  [-40.8668, 144.9735],
  [-40.8665, 144.9724],
  [-40.8661, 144.9711],
  [-40.8659, 144.9691],
  [-40.8661, 144.9676],
  [-40.8688, 144.9613],
  [-40.8714, 144.9564],
  [-40.8716, 144.9549],
  [-40.8714, 144.9534],
  [-40.8706, 144.9522],
  [-40.8685, 144.9506],
  [-40.8677, 144.9496],
  [-40.8670, 144.9476],
  [-40.8664, 144.9442],
  [-40.8666, 144.9430],
  [-40.8672, 144.9421],
  [-40.8728, 144.9368],
  [-40.8908, 144.9245],
  [-40.8918, 144.9234],
  [-40.9004, 144.9076],
  [-40.9015, 144.9062],
  [-40.9044, 144.9035],
  [-40.9053, 144.9021],
  [-40.9108, 144.8838],
  [-40.9108, 144.8825],
  [-40.9103, 144.8811],
  [-40.9102, 144.8795],
  [-40.9109, 144.8741],
  [-40.9109, 144.8714],
  [-40.9112, 144.8698],
  [-40.9168, 144.8579],
  [-40.9167, 144.8569],
  [-40.9164, 144.8559],
  [-40.9159, 144.8552],
  [-40.9152, 144.8549],
  [-40.9145, 144.8540],
  [-40.9143, 144.8531],
  [-40.9144, 144.8519],
  [-40.9154, 144.8504],
  [-40.9154, 144.8499],
  [-40.9153, 144.8493]
];

const bondTierForrestOld = [
  [-40.9153, 144.8493],
  //DONT HAVE THE ROUTE FOR THIS
  [-40.9183, 144.7851]
];

const bondTierForrestDeviation = [
  [-40.9153, 144.8493],
  [-40.9145, 144.8481],
  [-40.9134, 144.8477],
  [-40.9129, 144.8472],
  [-40.9127, 144.8467],
  [-40.9128, 144.8457],
  [-40.9133, 144.8443],
  [-40.9135, 144.8390],
  [-40.9131, 144.8371],
  [-40.9132, 144.8361],
  [-40.9136, 144.8351],
  [-40.9135, 144.8342],
  [-40.9130, 144.8335],
  [-40.9119, 144.8332],
  [-40.9112, 144.8328],
  [-40.9107, 144.8320],
  [-40.9106, 144.8307],
  [-40.9118, 144.8281],
  [-40.9119, 144.8267],
  [-40.9114, 144.8254],
  [-40.9113, 144.8242],
  [-40.9116, 144.8230],
  [-40.9114, 144.8215],
  [-40.9105, 144.8206],
  [-40.9102, 144.8194],
  [-40.9103, 144.8175],
  [-40.9106, 144.8161],
  [-40.9113, 144.8149],
  [-40.9113, 144.8140],
  [-40.9111, 144.8133],
  [-40.9111, 144.8126],
  [-40.9117, 144.8115],
  [-40.9127, 144.8084],
  [-40.9130, 144.8064],
  [-40.9137, 144.8054],
  [-40.9149, 144.8051],
  [-40.9156, 144.8046],
  [-40.9158, 144.8040],
  [-40.9157, 144.8032],
  [-40.9153, 144.8023],
  [-40.9150, 144.8012],
  [-40.9151, 144.8005],
  [-40.9150, 144.7997],
  [-40.9148, 144.7989],
  [-40.9149, 144.7974],
  [-40.9151, 144.7966],
  [-40.9151, 144.7959],
  [-40.9149, 144.7953],
  [-40.9150, 144.7944],
  [-40.9153, 144.7939],
  [-40.9157, 144.7936],
  [-40.9159, 144.7930],
  [-40.9159, 144.7924],
  [-40.9161, 144.7915],
  [-40.9169, 144.7898],
  [-40.9173, 144.7891],
  [-40.9176, 144.7883],
  [-40.9176, 144.7877],
  [-40.9177, 144.7868],
  [-40.9183, 144.7851]
];

const bondTierForrestRedpa = [
  [-40.9183, 144.7851],
  [-40.9206, 144.7767],
  [-40.9217, 144.7680],
  [-40.9220, 144.7652],
  [-40.9220, 144.7636],
  [-40.9217, 144.7629],
  [-40.9214, 144.7622]
];

const redpaMarrawah = [
  [-40.9214, 144.7622],
  [-40.9198, 144.7450],
  [-40.9198, 144.7441],
  [-40.9206, 144.7387],
  [-40.9205, 144.7358],
  [-40.9203, 144.7353],
  [-40.9198, 144.7348],
  [-40.9195, 144.7338],
  [-40.9196, 144.7328],
  [-40.9196, 144.7323],
  [-40.9193, 144.7316],
  [-40.9190, 144.7312],
  [-40.9185, 144.7311],
  [-40.9181, 144.7308],
  [-40.9177, 144.7302],
  [-40.9176, 144.7293],
  [-40.9178, 144.7273],
  [-40.9189, 144.7241],
  [-40.9194, 144.7233],
  [-40.9210, 144.7220],
  [-40.9220, 144.7211],
  [-40.9223, 144.7204],
  [-40.9221, 144.7167],
  [-40.9218, 144.7162],
  [-40.9210, 144.7160],
  [-40.9207, 144.7155],
  [-40.9192, 144.7012],
  [-40.9195, 144.7005],
  [-40.9206, 144.6992]
];

export const marrawah: Line = {
  name: 'Marrawah',
  state: 'TAS',
  segments: [
    {
      segments: [{
          date: '1914-01-01',
          original: [leesvilleOld],
          deviation: [leesvilleDeviation]
        }],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1924-01-01',
          trackType: 'heavy'
        }, {
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [junctionBroadmeadowsJunction],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1924-01-01',
          trackType: 'heavy'
        }, {
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [broadmeadowsJunctionTrowuttaMillJunction],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1924-01-01',
          trackType: 'heavy'
        }, {
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [trowuttaMillJunctionBondTierForrest],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1924-01-01',
          trackType: 'heavy'
        }, {
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bondTierForrestDeviation],
      history: {
        opened: {
          date: '1913-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1924-01-01',
          trackType: 'heavy'
        }, {
          date: '1974-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bondTierForrestRedpa],
      history: {
        opened: {
          date: '1913-02-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1924-01-01',
          trackType: 'heavy'
        }, {
          date: '1961-03-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [redpaMarrawah],
      history: {
        opened: {
          date: '1913-02-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1924-01-01',
          trackType: 'heavy'
        }, {
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
