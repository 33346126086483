import { Line } from "../../../trackTypes";

const line = [
  [-27.6295, 152.3950],
  [-27.6294, 152.3939],
  [-27.6293, 152.3932],
  [-27.6293, 152.3928],
  [-27.6294, 152.3923],
  [-27.6299, 152.3915],
  [-27.6302, 152.3911],
  [-27.6308, 152.3910],
  [-27.6411, 152.3883],
  [-27.6415, 152.3881],
  [-27.6434, 152.3857],
  [-27.6467, 152.3839],
  [-27.6686, 152.3794],
  [-27.6695, 152.3790],
  [-27.6718, 152.3755],
  [-27.6727, 152.3748],
  [-27.7123, 152.3679],
  [-27.7146, 152.3679],
  [-27.7154, 152.3679],
  [-27.7160, 152.3671],
  [-27.7266, 152.3651]
];

export const laidleyValley: Line = {
  name: 'Laidley Valley',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1911-04-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
