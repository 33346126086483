import { Line } from "../../trackTypes";

const lilydaleColdstream = [
  [-37.7556, 145.3474],
  [-37.7510, 145.3522],
  [-37.7459, 145.3576],
  [-37.7372, 145.3629],
  [-37.7365, 145.3635],
  [-37.7356, 145.3643],
  [-37.7352, 145.3649],
  [-37.7320, 145.3703],
  [-37.7313, 145.3712],
  [-37.7306, 145.3718],
  [-37.7299, 145.3721],
  [-37.7289, 145.3723],
  [-37.7262, 145.3714]
];

const coldstreamYarraGlen = [
  [-37.7262, 145.3714],
  [-37.7246, 145.3707],
  [-37.7170, 145.3675],
  [-37.7151, 145.3672],
  [-37.7138, 145.3674],
  [-37.6818, 145.3777],
  [-37.6806, 145.3777],
  [-37.6796, 145.3773],
  [-37.6674, 145.3688],
  [-37.6654, 145.3683],
  [-37.6641, 145.3687],
  [-37.6635, 145.3692],
  [-37.6621, 145.3714]
];

const yarraGlenTunnelHault = [
  [-37.6621, 145.3714],
  [-37.6593, 145.3759],
  [-37.6587, 145.3776],
  [-37.6587, 145.3794],
  [-37.6610, 145.3928],
  [-37.6611, 145.3940],
  [-37.6599, 145.4087],
  [-37.6596, 145.4106],
  [-37.6563, 145.4217],
  [-37.6562, 145.4229],
  [-37.6564, 145.4244],
  [-37.6641, 145.4469],
  [-37.6642, 145.4485],
  [-37.6641, 145.4501],
  [-37.6620, 145.4577],
  [-37.6618, 145.4591],
  [-37.6614, 145.4749]
];

const tarrawarraTunnel = [
  [-37.6614, 145.4749],
  [-37.6613, 145.4767]
];

const healsvilleTunnelHault = [
  [-37.6549, 145.5091],
  [-37.6548, 145.4969],
  [-37.6551, 145.4951],
  [-37.6557, 145.4937],
  [-37.6625, 145.4853],
  [-37.6631, 145.4837],
  [-37.6629, 145.4819],
  [-37.6616, 145.4792],
  [-37.6613, 145.4780],
  [-37.6613, 145.4767]
];

export const healesville: Line = {
  name: 'Healesville',
  state: 'VIC',
  segments: [
    {
      segments: [lilydaleColdstream],
      history: {
        opened: {
          date: '1888-05-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-12-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [coldstreamYarraGlen],
      history: {
        opened: {
          date: '1888-05-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yarraGlenTunnelHault],
      history: {
        opened: {
          date: '1889-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-15',
          status: 'closed'
        }, {
          date: '2009-02-07',
          status: 'closed'
        }, {
          date: '1990-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [{
          name: 'Tarrawarra Tunnel',
          segment: tarrawarraTunnel,
          type: 'tunnel'
        },
        healsvilleTunnelHault
      ],
      history: {
        opened: {
          date: '1889-03-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-03-15',
          status: 'closed'
        }, {
          date: '2010-07-17',
          status: 'open'
        }]
      }
    }
  ]
};
