import { Line } from "../../../trackTypes";

const waipahiConicalHillTunnel = [
  [-46.1101, 169.2421],
  [-46.1102, 169.2432],
  [-46.1102, 169.2443],
  [-46.1092, 169.2475],
  [-46.1076, 169.2493],
  [-46.1041, 169.2510],
  [-46.1034, 169.2509],
  [-46.1025, 169.2503],
  [-46.1020, 169.2493],
  [-46.1010, 169.2449],
  [-46.0985, 169.2397],
  [-46.0974, 169.2386],
  [-46.0931, 169.2363],
  [-46.0921, 169.2365],
  [-46.0913, 169.2374],
  [-46.0910, 169.2383],
  [-46.0908, 169.2394],
  [-46.0904, 169.2403],
  [-46.0894, 169.2406],
  [-46.0879, 169.2401],
  [-46.0838, 169.2403],
  [-46.0831, 169.2408],
  [-46.0821, 169.2412],
  [-46.0810, 169.2409],
  [-46.0795, 169.2401],
  [-46.0779, 169.2399],
  [-46.0756, 169.2400],
  [-46.0749, 169.2397],
  [-46.0743, 169.2389],
  [-46.0740, 169.2378],
  [-46.0735, 169.2371],
  [-46.0729, 169.2369],
  [-46.0722, 169.2370]
];

const conicalHillTunnel = [
  [-46.0722, 169.2370],
  [-46.0719, 169.2371],
  [-46.0715, 169.2371]
];

const conicalHillTunnelKelso = [
  [-46.0715, 169.2371],
  [-46.0714, 169.2371],
  [-46.0705, 169.2367],
  [-46.0693, 169.2372],
  [-46.0686, 169.2382],
  [-46.0677, 169.2386],
  [-46.0664, 169.2384],
  [-46.0586, 169.2406],
  [-46.0577, 169.2405],
  [-46.0536, 169.2399],
  [-46.0530, 169.2400],
  [-46.0512, 169.2406],
  [-46.0464, 169.2401],
  [-46.0160, 169.2407],
  [-46.0151, 169.2404],
  [-46.0133, 169.2396],
  [-46.0125, 169.2387],
  [-46.0121, 169.2376],
  [-46.0113, 169.2367],
  [-46.0103, 169.2363],
  [-45.9870, 169.2377],
  [-45.9852, 169.2372],
  [-45.9725, 169.2299],
  [-45.9715, 169.2300],
  [-45.9684, 169.2310],
  [-45.9547, 169.2327],
  [-45.9513, 169.2328],
  [-45.9445, 169.2344],
  [-45.9431, 169.2342],
  [-45.9367, 169.2318],
  [-45.9353, 169.2321],
  [-45.9344, 169.2329],
  [-45.9336, 169.2343],
  [-45.9330, 169.2349],
  [-45.9320, 169.2352],
  [-45.9305, 169.2349],
  [-45.9274, 169.2356],
  [-45.9256, 169.2368],
  [-45.9231, 169.2377],
  [-45.9225, 169.2377],
  [-45.9221, 169.2372],
  [-45.9215, 169.2356],
  [-45.9212, 169.2348],
  [-45.9184, 169.2310],
  [-45.9181, 169.2300],
  [-45.9179, 169.2288],
  [-45.9173, 169.2277],
  [-45.9163, 169.2272],
  [-45.9154, 169.2274],
  [-45.9119, 169.2304],
  [-45.9101, 169.2311],
  [-45.9001, 169.2312]
];

const kelsoHeriot = [
  [-45.9001, 169.2312],
  [-45.8848, 169.2353],
  [-45.8826, 169.2364],
  [-45.8560, 169.2533],
  [-45.8541, 169.2554],
  [-45.8494, 169.2645]
];

const heriotEdievale = [
  [-45.8494, 169.2645],
  [-45.8478, 169.2676],
  [-45.8456, 169.2704],
  [-45.8445, 169.2722],
  [-45.8428, 169.2744],
  [-45.8423, 169.2756],
  [-45.8423, 169.2769],
  [-45.8426, 169.2784],
  [-45.8425, 169.2798],
  [-45.8421, 169.2808],
  [-45.8416, 169.2813],
  [-45.8378, 169.2832],
  [-45.8371, 169.2840],
  [-45.8360, 169.2865],
  [-45.8352, 169.2876],
  [-45.8339, 169.2882],
  [-45.8327, 169.2907],
  [-45.8309, 169.2944],
  [-45.8302, 169.2954],
  [-45.8292, 169.2959],
  [-45.8259, 169.2961],
  [-45.8252, 169.2964],
  [-45.8247, 169.2972],
  [-45.8245, 169.2982],
  [-45.8244, 169.3017],
  [-45.8240, 169.3027],
  [-45.8232, 169.3033],
  [-45.8225, 169.3033],
  [-45.8219, 169.3038],
  [-45.8215, 169.3046],
  [-45.8214, 169.3056],
  [-45.8208, 169.3066],
  [-45.8199, 169.3069],
  [-45.8181, 169.3065],
  [-45.8173, 169.3068],
  [-45.8168, 169.3073],
  [-45.8151, 169.3113],
  [-45.8105, 169.3178],
  [-45.8099, 169.3190],
  [-45.8065, 169.3340],
  [-45.8039, 169.3426],
  [-45.8035, 169.3452],
  [-45.8035, 169.3487],
  [-45.8031, 169.3508],
  [-45.7997, 169.3583]
];

export const tapanui: Line = {
  name: 'Tapanui',
  state: 'NZ',
  segments: [
    {
      segments: [
        waipahiConicalHillTunnel,
        {
          name: 'Conical Hill Tunnel',
          segment: conicalHillTunnel,
          type: 'tunnel'
        },
        conicalHillTunnelKelso
      ],
      history: {
        opened: {
          date: '1880-11-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kelsoHeriot],
      history: {
        opened: {
          date: '1884-04-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heriotEdievale],
      history: {
        opened: {
          date: '1905-02-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1968-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
