import { Line } from "../../../trackTypes";

const mackayFarleigh = [
  [-21.1376, 149.1436],
  [-21.1327, 149.1400],
  [-21.1312, 149.1385],
  [-21.1196, 149.1239],
  [-21.1174, 149.1211],
  [-21.1162, 149.1199],
  [-21.1057, 149.1128],
  [-21.1047, 149.1120],
  [-21.1017, 149.1088],
  [-21.1011, 149.1080],
  [-21.0963, 149.1000],
  [-21.0958, 149.0988],
  [-21.0956, 149.0981]
];

const farleighProserpine = [
  [-21.0956, 149.0981],
  [-21.0877, 149.0674],
  [-21.0861, 149.0540],
  [-21.0857, 149.0518],
  [-21.0830, 149.0456],
  [-21.0824, 149.0435],
  [-21.0796, 149.0289],
  [-21.0788, 149.0270],
  [-21.0724, 149.0165],
  [-21.0703, 149.0136],
  [-21.0697, 149.0118],
  [-21.0689, 149.0034],
  [-21.0691, 149.0005],
  [-21.0700, 148.9968],
  [-21.0704, 148.9936],
  [-21.0712, 148.9911],
  [-21.0744, 148.9857],
  [-21.0755, 148.9823],
  [-21.0751, 148.9790],
  [-21.0735, 148.9760],
  [-21.0712, 148.9735],
  [-21.0702, 148.9716],
  [-21.0698, 148.9687],
  [-21.0699, 148.9659],
  [-21.0697, 148.9640],
  [-21.0682, 148.9585],
  [-21.0662, 148.9548],
  [-21.0635, 148.9481],
  [-21.0628, 148.9468],
  [-21.0607, 148.9441],
  [-21.0596, 148.9422],
  [-21.0578, 148.9389],
  [-21.0550, 148.9356],
  [-21.0542, 148.9343],
  [-21.0520, 148.9302],
  [-21.0500, 148.9252],
  [-21.0496, 148.9237],
  [-21.0490, 148.9211],
  [-21.0489, 148.9194],
  [-21.0481, 148.9181],
  [-21.0360, 148.9084],
  [-21.0345, 148.9069],
  [-21.0220, 148.8924],
  [-21.0009, 148.8742],
  [-20.9909, 148.8682],
  [-20.9890, 148.8664],
  [-20.9858, 148.8613],
  [-20.9838, 148.8593],
  [-20.9814, 148.8582],
  [-20.9756, 148.8570],
  [-20.9713, 148.8549],
  [-20.9663, 148.8505],
  [-20.9650, 148.8484],
  [-20.9627, 148.8417],
  [-20.9600, 148.8371],
  [-20.9544, 148.8321],
  [-20.9525, 148.8314],
  [-20.9459, 148.8310],
  [-20.9439, 148.8305],
  [-20.9426, 148.8295],
  [-20.9380, 148.8239],
  [-20.9371, 148.8215],
  [-20.9365, 148.8154],
  [-20.9358, 148.8140],
  [-20.9346, 148.8132],
  [-20.9275, 148.8119],
  [-20.9254, 148.8106],
  [-20.9214, 148.8057],
  [-20.9168, 148.8023],
  [-20.9156, 148.8009],
  [-20.9147, 148.7992],
  [-20.9120, 148.7914],
  [-20.9113, 148.7901],
  [-20.9042, 148.7823],
  [-20.8816, 148.7490],
  [-20.8658, 148.7294],
  [-20.8596, 148.7237],
  [-20.8583, 148.7216],
  [-20.8574, 148.7193],
  [-20.8562, 148.7175],
  [-20.8538, 148.7156],
  [-20.8527, 148.7142],
  [-20.8519, 148.7127],
  [-20.8458, 148.6904],
  [-20.8417, 148.6809],
  [-20.8381, 148.6700],
  [-20.8370, 148.6564],
  [-20.8342, 148.6483],
  [-20.8327, 148.6462],
  [-20.8150, 148.6323],
  [-20.8132, 148.6302],
  [-20.8112, 148.6272],
  [-20.8102, 148.6261],
  [-20.8025, 148.6216],
  [-20.7911, 148.6179],
  [-20.7893, 148.6169],
  [-20.7845, 148.6138],
  [-20.7826, 148.6133],
  [-20.7800, 148.6139],
  [-20.7761, 148.6139],
  [-20.7731, 148.6131],
  [-20.7695, 148.6111],
  [-20.7637, 148.6093],
  [-20.7580, 148.6082],
  [-20.7556, 148.6083],
  [-20.7537, 148.6089],
  [-20.7525, 148.6090],
  [-20.7480, 148.6083],
  [-20.7469, 148.6084],
  [-20.7417, 148.6095],
  [-20.7405, 148.6095],
  [-20.6876, 148.5875],
  [-20.6772, 148.5847],
  [-20.6742, 148.5846],
  [-20.6620, 148.5876],
  [-20.6586, 148.5876],
  [-20.6513, 148.5858],
  [-20.6469, 148.5835],
  [-20.6433, 148.5822],
  [-20.6389, 148.5815],
  [-20.6361, 148.5805],
  [-20.6337, 148.5789],
  [-20.6304, 148.5756],
  [-20.6212, 148.5687],
  [-20.6156, 148.5652],
  [-20.6083, 148.5591],
  [-20.6067, 148.5582],
  [-20.6002, 148.5552],
  [-20.5987, 148.5550],
  [-20.5902, 148.5553],
  [-20.5903, 148.5550],
  [-20.5874, 148.5540],
  [-20.5856, 148.5540],
  [-20.5791, 148.5568],
  [-20.5776, 148.5570],
  [-20.5713, 148.5559],
  [-20.5557, 148.5563],
  [-20.5536, 148.5569],
  [-20.5500, 148.5598],
  [-20.5482, 148.5605],
  [-20.5015, 148.5685],
  [-20.4940, 148.5690],
  [-20.4922, 148.5694],
  [-20.4896, 148.5706],
  [-20.4878, 148.5710],
  [-20.4651, 148.5715],
  [-20.4304, 148.5695],
  [-20.4287, 148.5697],
  [-20.4189, 148.5721],
  [-20.4100, 148.5772],
  [-20.4078, 148.5779],
  [-20.4022, 148.5786]
];

const proserpineBowen = [
  [-20.4022, 148.5786],
  [-20.4000, 148.5789],
  [-20.3993, 148.5789],
  [-20.3978, 148.5785],
  [-20.3973, 148.5785],
  [-20.3941, 148.5786],
  [-20.3923, 148.5783],
  [-20.3911, 148.5775],
  [-20.3848, 148.5721],
  [-20.3711, 148.5642],
  [-20.3703, 148.5634],
  [-20.3652, 148.5572],
  [-20.3645, 148.5566],
  [-20.3615, 148.5549],
  [-20.3609, 148.5544],
  [-20.3345, 148.5233],
  [-20.3024, 148.4855],
  [-20.2840, 148.4624],
  [-20.2813, 148.4586],
  [-20.2763, 148.4531],
  [-20.2373, 148.4076],
  [-20.2306, 148.3999],
  [-20.2295, 148.3984],
  [-20.2218, 148.3898],
  [-20.2203, 148.3884],
  [-20.2034, 148.3697],
  [-20.2010, 148.3678],
  [-20.1957, 148.3652],
  [-20.1931, 148.3634],
  [-20.1774, 148.3467],
  [-20.1639, 148.3275],
  [-20.1539, 148.3130],
  [-20.1529, 148.3108],
  [-20.1514, 148.3043],
  [-20.1501, 148.3016],
  [-20.1476, 148.2987],
  [-20.1460, 148.2963],
  [-20.1424, 148.2894],
  [-20.1399, 148.2806],
  [-20.1391, 148.2705],
  [-20.1384, 148.2673],
  [-20.1255, 148.2421],
  [-20.1047, 148.2220],
  [-20.0976, 148.2177],
  [-20.0808, 148.2042],
  [-20.0585, 148.2013],
  [-20.0561, 148.2012],
  [-20.0217, 148.2057],
  [-20.0183, 148.2061]
];

const bowenJunctionNew = [
  [-20.0183, 148.2061],
  [-20.0163, 148.2062],
  [-20.0151, 148.2058],
  [-20.0142, 148.2051],
  [-20.0136, 148.2041]
];

const bowenBaloonLoop = [
  [-20.0110, 148.2263],
  [-20.0089, 148.2302],
  [-20.0080, 148.2307],
  [-20.0068, 148.2306],
  [-20.0062, 148.2297],
  [-20.0062, 148.2287],
  [-20.0069, 148.2278],
  [-20.0110, 148.2263]
];

const bowenOldShared = [
  [-20.0136, 148.2041],
  [-20.0139, 148.2056],
  [-20.0137, 148.2068],
  [-20.0134, 148.2079],
  [-20.0131, 148.2089],
  [-20.0139, 148.2171],
  [-20.0138, 148.2183],
  [-20.0110, 148.2263]
];

const bowenOld = [
  [-20.0110, 148.2263],
  [-20.0110, 148.2266],
  [-20.0110, 148.2279],
  [-20.0113, 148.2294],
  [-20.0137, 148.2331],
  [-20.0184, 148.2362],
  [-20.0205, 148.2391],
  [-20.0206, 148.2403],
  [-20.0159, 148.2490]
];

const bowenJunction = [
  [-20.0183, 148.2061],
  [-20.0149, 148.2066],
  [-20.0140, 148.2071],
  [-20.0134, 148.2079]
];

const bowenDurroburraJunction = [
  [-20.0136, 148.2041],
  [-20.0126, 148.2023],
  [-20.0121, 148.2009],
  [-20.0108, 148.1894],
  [-20.0135, 148.1675],
  [-20.0137, 148.1662],
  [-20.0141, 148.1652],
  [-20.0152, 148.1631],
  [-20.0154, 148.1622],
  [-20.0154, 148.1612],
  [-20.0133, 148.1495],
  [-20.0132, 148.1490]
];

const durroburraJunctionDuplicationLoop = [
  [-20.0132, 148.1490],
  [-20.0102, 148.1336],
  [-20.0069, 148.1246],
  [-20.0066, 148.1228],
  [-20.0068, 148.1215],
  [-20.0093, 148.1159],
  [-20.0105, 148.1085]
];

const loopOriginal = [
  [-20.0105, 148.1085],
  [-20.0110, 148.1047],
  [-20.0109, 148.1033],
  [-20.0105, 148.1023],
  [-20.0101, 148.1014],
  [-20.0090, 148.1003],
  [-20.0079, 148.0996],
  [-20.0061, 148.0989],
  [-20.0055, 148.0985],
  [-20.0048, 148.0979]
];

const loopNew = [
  [-20.0105, 148.1085],
  [-20.0102, 148.1053],
  [-20.0090, 148.1026],
  [-20.0068, 148.1002],
  [-20.0048, 148.0979]
];

const loopKaili = [
  [-20.0048, 148.0979],
  [-20.0011, 148.0937],
  [-20.0005, 148.0926],
  [-19.9995, 148.0900],
  [-19.9986, 148.0888],
  [-19.9963, 148.0872]
];

const kailiGuthalungra = [
  [-19.9963, 148.0872],
  [-19.9950, 148.0862],
  [-19.9843, 148.0799],
  [-19.9705, 148.0692],
  [-19.9696, 148.0681],
  [-19.9681, 148.0655],
  [-19.9667, 148.0613],
  [-19.9636, 148.0446],
  [-19.9635, 148.0414],
  [-19.9645, 148.0291],
  [-19.9689, 148.0080],
  [-19.9690, 148.0043],
  [-19.9551, 147.9463],
  [-19.9518, 147.9328],
  [-19.9441, 147.9079],
  [-19.9334, 147.8850],
  [-19.9325, 147.8820],
  [-19.9286, 147.8565],
  [-19.9238, 147.8403]
];

const guthalungraBobawaba = [
  [-19.9238, 147.8403],
  [-19.9113, 147.7977],
  [-19.9081, 147.7751],
  [-19.9006, 147.7540],
  [-19.8946, 147.7298],
  [-19.8816, 147.6932],
  [-19.8723, 147.6709],
  [-19.8712, 147.6692],
  [-19.8675, 147.6652],
  [-19.8662, 147.6630],
  [-19.8462, 147.6116],
  [-19.8283, 147.5827],
  [-19.8229, 147.5730]
];

const bobawabaHomeHill = [
  [-19.8229, 147.5730],
  [-19.8221, 147.5711],
  [-19.8148, 147.5508],
  [-19.8132, 147.5481],
  [-19.7977, 147.5332],
  [-19.7773, 147.5189],
  [-19.7570, 147.5013],
  [-19.7548, 147.4987],
  [-19.7534, 147.4962],
  [-19.7506, 147.4887],
  [-19.7500, 147.4876],
  [-19.7424, 147.4752],
  [-19.7404, 147.4729],
  [-19.7021, 147.4403],
  [-19.6936, 147.4308],
  [-19.6912, 147.4288],
  [-19.6655, 147.4139]
];

const homeHillAry = [
  [-19.6655, 147.4139],
  [-19.6540, 147.4073],
  [-19.6338, 147.3882],
  [-19.6284, 147.3857],
  [-19.6263, 147.3855],
  [-19.6104, 147.3872],
  [-19.6082, 147.3879],
  [-19.5981, 147.3922],
  [-19.5968, 147.3932],
  [-19.5938, 147.3964],
  [-19.5922, 147.3971],
  [-19.5785, 147.3987],
  [-19.5770, 147.3985],
  [-19.5755, 147.3975],
  [-19.5743, 147.3960]
];

const ayrNome = [
  [-19.5743, 147.3960],
  [-19.5675, 147.3876],
  [-19.5570, 147.3683],
  [-19.5566, 147.3668],
  [-19.5550, 147.3498],
  [-19.5545, 147.3484],
  [-19.5530, 147.3471],
  [-19.5434, 147.3432],
  [-19.5425, 147.3425],
  [-19.5420, 147.3416],
  [-19.5335, 147.3131],
  [-19.5277, 147.2970],
  [-19.5271, 147.2943],
  [-19.5258, 147.2794],
  [-19.5255, 147.2783],
  [-19.5222, 147.2704],
  [-19.5212, 147.2653],
  [-19.5205, 147.2593],
  [-19.5209, 147.2558],
  [-19.5217, 147.2533],
  [-19.5235, 147.2502],
  [-19.5248, 147.2472],
  [-19.5251, 147.2444],
  [-19.5247, 147.2343],
  [-19.5254, 147.2298],
  [-19.5279, 147.2244],
  [-19.5285, 147.2213],
  [-19.5284, 147.2177],
  [-19.5277, 147.2137],
  [-19.5146, 147.1365],
  [-19.5125, 147.1078],
  [-19.5120, 147.1062],
  [-19.5003, 147.0744],
  [-19.4992, 147.0729],
  [-19.4974, 147.0715],
  [-19.4816, 147.0646],
  [-19.4801, 147.0634],
  [-19.4738, 147.0559],
  [-19.4662, 147.0504],
  [-19.4636, 147.0494],
  [-19.4439, 147.0485],
  [-19.4422, 147.0481],
  [-19.4247, 147.0422],
  [-19.3981, 147.0235],
  [-19.3941, 147.0198],
  [-19.3934, 147.0185],
  [-19.3932, 147.0173],
  [-19.3941, 146.9992],
  [-19.3936, 146.9968],
  [-19.3916, 146.9931],
  [-19.3912, 146.9917],
  [-19.3888, 146.9787],
  [-19.3893, 146.9611],
  [-19.3888, 146.9586],
  [-19.3800, 146.9300],
  [-19.3793, 146.9277],
  [-19.3770, 146.9241],
  [-19.3755, 146.9215]
];

const nomeStuart = [
  [-19.3755, 146.9215],
  [-19.3675, 146.9077],
  [-19.3645, 146.9048],
  [-19.3636, 146.9034],
  [-19.3620, 146.8993],
  [-19.3604, 146.8970],
  [-19.3598, 146.8954],
  [-19.3587, 146.8890],
  [-19.3584, 146.8856],
  [-19.3579, 146.8839],
  [-19.3539, 146.8768],
  [-19.3530, 146.8743],
  [-19.3511, 146.8717],
  [-19.3490, 146.8681],
  [-19.3486, 146.8665],
  [-19.3489, 146.8615],
  [-19.3467, 146.8391],
  [-19.3470, 146.8377],
  [-19.3469, 146.8366],
  [-19.3464, 146.8359],
  [-19.3456, 146.8353]
];

const stuartTownsvilleJunction = [
  [-19.3468, 146.8356],
  [-19.3456, 146.8353],
  [-19.3439, 146.8349],
  [-19.3425, 146.8344],
  [-19.3248, 146.8258],
  [-19.3217, 146.8248],
  [-19.2865, 146.8140],
  [-19.2852, 146.8140],
  [-19.2841, 146.8142],
  [-19.2821, 146.8151],
  [-19.2804, 146.8155],
  [-19.2795, 146.8155],
  [-19.2738, 146.8154],
  [-19.2724, 146.8151],
  [-19.2710, 146.8144],
  [-19.2702, 146.8137]
];

const townsvilleJunctionGreatNorthernRailwayStation = [
  [-19.2710, 146.8144],
  [-19.2705, 146.8142],
  [-19.2699, 146.8143],
  [-19.2695, 146.8146],
  [-19.2685, 146.8157],
  [-19.2672, 146.8167],
  [-19.2663, 146.8175],
  [-19.2656, 146.8176],
  [-19.2646, 146.8177],
  [-19.2642, 146.8176],
  [-19.2637, 146.8173],
  [-19.2633, 146.8167],
  [-19.2632, 146.8161],
  [-19.2634, 146.8152],
  [-19.2641, 146.8138]
];

const greatNorthernRailwayStationJunction = [
  [-19.2641, 146.8138],
  [-19.2645, 146.8133],
  [-19.2662, 146.8116],
  [-19.2670, 146.8114],
  [-19.2675, 146.8116],
  [-19.2697, 146.8133],
  [-19.2702, 146.8137]
];

const greatNorthernRailwayStationKurukanJunction = [
  [-19.2662, 146.8116],
  [-19.2672, 146.8099],
  [-19.2689, 146.8073],
  [-19.2691, 146.8067]
];

const townsvilleJunctionKurkanJunction = [
  [-19.2710, 146.8144],
  [-19.2701, 146.8136],
  [-19.2695, 146.8129],
  [-19.2694, 146.8122],
  [-19.2691, 146.8077],
  [-19.2691, 146.8067]
];

export const northCoastMackayTownsville: Line = {
  name: 'North Coast (Mackay - Townsville)',
  state: 'QLD',
  segments: [
    {
      segments: [mackayFarleigh],
      history: {
        opened: {
          date: '1918-04-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [farleighProserpine],
      history: {
        opened: {
          date: '1923-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [proserpineBowen],
      history: {
        opened: {
          date: '1910-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bowenJunctionNew],
      history: {
        opened: {
          date: '1993-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bowenOldShared],
      history: {
        opened: {
          date: '1890-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bowenOld],
      history: {
        opened: {
          date: '1890-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1910-07-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bowenBaloonLoop, bowenJunction],
      history: {
        opened: {
          date: '1910-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bowenDurroburraJunction],
      history: {
        opened: {
          date: '1890-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [durroburraJunctionDuplicationLoop],
      history: {
        opened: {
          date: '1890-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-06-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [loopOriginal],
      history: {
        opened: {
          date: '1890-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [loopNew],
      history: {
        opened: {
          date: '2012-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [loopKaili],
      history: {
        opened: {
          date: '1890-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2012-06-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [kailiGuthalungra],
      history: {
        opened: {
          date: '1890-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [guthalungraBobawaba],
      history: {
        opened: {
          date: '1891-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bobawabaHomeHill],
      history: {
        opened: {
          date: '1913-07-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [homeHillAry],
      history: {
        opened: {
          date: '1913-06-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ayrNome],
      history: {
        opened: {
          date: '1901-03-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [nomeStuart],
      history: {
        opened: {
          date: '1901-03-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [stuartTownsvilleJunction],
      history: {
        opened: {
          date: '1880-12-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1936-01-01',
          tracks: 2
        }]
      }
    },
    {
      segments: [townsvilleJunctionGreatNorthernRailwayStation, greatNorthernRailwayStationJunction],
      history: {
        opened: {
          date: '1880-12-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2003-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greatNorthernRailwayStationKurukanJunction],
      history: {
        opened: {
          date: '1914-04-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2003-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [townsvilleJunctionKurkanJunction],
      history: {
        opened: {
          date: '2003-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
