import { Line } from "../../../trackTypes";

const flemmingtonGoodsJunctionPippita = [
  [-33.8638, 151.0653],
  [-33.8636, 151.0646],
  [-33.8631, 151.0621],
  [-33.8625, 151.0610],
  [-33.8618, 151.0605],
  [-33.8611, 151.0605],
  [-33.8605, 151.0606],
  [-33.8589, 151.0616],
  [-33.8580, 151.0624],
  [-33.8567, 151.0634]
];

const flemmingtonGoodsJunction = [
  [-33.8644, 151.0507],
  [-33.8641, 151.0520],
  [-33.8634, 151.0532],
  [-33.8628, 151.0544],
  [-33.8617, 151.0587],
  [-33.8614, 151.0596],
  [-33.8610, 151.0602],
  [-33.8605, 151.0606]
];

const pippitaBrickworks = [
  [-33.8502, 151.0725],
  [-33.8495, 151.0741],
  [-33.8486, 151.0750],
  [-33.8478, 151.0751],
  [-33.8469, 151.0750],
  [-33.8462, 151.0749],
  [-33.8438, 151.0758],
  [-33.8426, 151.0760],
  [-33.8412, 151.0760],
  [-33.8401, 151.0756],
  [-33.8393, 151.0747]
];

const saleYardsJunctionThorpes = [
  [-33.8567, 151.0634],
  [-33.8549, 151.0638],
  [-33.8536, 151.0639],
  [-33.8511, 151.0632],
  [-33.8459, 151.0604],
  [-33.8453, 151.0597],
  [-33.8450, 151.0591],
  [-33.8450, 151.0580],
  [-33.8453, 151.0570]
];

const thorpesSaleYardsLoopJunction = [
  [-33.8459, 151.0604],
  [-33.8452, 151.0604],
  [-33.8428, 151.0618],
  [-33.8422, 151.0626],
  [-33.8404, 151.0676],
  [-33.8405, 151.0685],
  [-33.8413, 151.0697],
  [-33.8434, 151.0708],
  [-33.8439, 151.0714],
  [-33.8442, 151.0721],
  [-33.8441, 151.0734],
  [-33.8431, 151.0757],
  [-33.8426, 151.0760]
];

const pippitaBallonJunction = [
  [-33.8567, 151.0634],
  [-33.8542, 151.0655],
  [-33.8537, 151.0662],
  [-33.8525, 151.0682]
];

const balloonShared = [
  [-33.8525, 151.0682],
  [-33.8510, 151.0708],
  [-33.8502, 151.0725]
];


const olympicParkWest = [
  [-33.8525, 151.0682],
  [-33.8518, 151.0687]
];

const olympicParkTunnel = [
  [-33.8518, 151.0687],
  [-33.8514, 151.0689],
  [-33.8504, 151.0688],
  [-33.8492, 151.0679],
  [-33.8483, 151.0675],
  [-33.8479, 151.0674],
  [-33.8473, 151.0677],
  [-33.8470, 151.0682],
  [-33.8461, 151.0706],
  [-33.8459, 151.0712],
  [-33.8460, 151.0721],
  [-33.8464, 151.0729]
];

const olympicParkEast = [
  [-33.8464, 151.0729],
  [-33.8467, 151.0732],
  [-33.8476, 151.0737],
  [-33.8485, 151.0738],
  [-33.8495, 151.0734],
  [-33.8502, 151.0725]
];

export const olympicPark: Line = {
  name: 'Olympic Park',
  state: 'NSW',
  segments: [
    {
      segments: [flemmingtonGoodsJunctionPippita],
      history: {
        opened: {
          date: '1911-07-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-08-26',
          status: 'closed'
        }, {
          date: '1998-03-08',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          tracks: 2
        }]
      }
    },
    {
      segments: [flemmingtonGoodsJunction],
      history: {
        opened: {
          date: '1911-07-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-08-26',
          status: 'closed'
        }, {
          date: '1998-03-08',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          tracks: 2
        }]
      }
    },
    {
      segments: [pippitaBallonJunction],
      history: {
        opened: {
          date: '1911-07-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-11-09',
          status: 'closed'
        }, {
          date: '1998-03-08',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          tracks: 2
        }]
      }
    },
    {
      segments: [balloonShared],
      history: {
        opened: {
          date: '1911-07-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-11-09',
          status: 'closed'
        }, {
          date: '1998-03-08',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [pippitaBrickworks],
      history: {
        opened: {
          date: '1911-07-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1984-11-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [saleYardsJunctionThorpes],
      history: {
        opened: {
          date: '1911-07-31',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [thorpesSaleYardsLoopJunction],
      history: {
        opened: {
          date: '1941-10-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1991-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        olympicParkWest,
        {
          segment: olympicParkTunnel,
          type: 'tunnel'
        },
        olympicParkEast
      ],
      history: {
        opened: {
          date: '1998-05-01',
          tracks: 1,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
