import { Line } from "../../trackTypes";

const qldBorderTweedHeads = [
  [-28.1699, 153.5408],
  [-28.1706, 153.5410],
  [-28.1720, 153.5405]
];

export const southCoastQld: Line = {
  name: 'South Coast (QLD)',
  state: 'NSW',
  segments: [
    {
      segments: [qldBorderTweedHeads],
      history: {
        opened: {
          date: '1903-08-10',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-07-01',
          status: 'closed'
        }]
      }
    }
  ]
};
