import { Line } from "../../../trackTypes";

const stillwaterSouthJunction = [
  [-42.4359, 171.3572],
  [-42.4361, 171.3564],
  [-42.4364, 171.3558],
  [-42.4368, 171.3554],
  [-42.4374, 171.3553]
];

const stillwaterNgahere = [
  [-42.4363, 171.3533],
  [-42.4359, 171.3572],
  [-42.4353, 171.3662],
  [-42.4353, 171.3674],
  [-42.4355, 171.3687],
  [-42.4354, 171.3700],
  [-42.4343, 171.3736],
  [-42.4341, 171.3749],
  [-42.4337, 171.3784],
  [-42.4329, 171.3809],
  [-42.4294, 171.3857],
  [-42.4283, 171.3882],
  [-42.4279, 171.3900],
  [-42.4279, 171.3913],
  [-42.4274, 171.3929],
  [-42.4266, 171.3938],
  [-42.4258, 171.3944],
  [-42.4252, 171.3952],
  [-42.4223, 171.4038],
  [-42.4214, 171.4080],
  [-42.4173, 171.4231],
  [-42.4161, 171.4256],
  [-42.4106, 171.4308],
  [-42.3949, 171.4371],
  [-42.3939, 171.4381],
  [-42.3936, 171.4392],
  [-42.3937, 171.4402],
  [-42.3940, 171.4416],
  [-42.3940, 171.4432],
  [-42.3935, 171.4448],
  [-42.3917, 171.4483]
];

const ngatapaTawhaiTunnel = [
  [-42.3917, 171.4483],
  [-42.3902, 171.4497],
  [-42.3891, 171.4501],
  [-42.3851, 171.4500],
  [-42.3838, 171.4504],
  [-42.3829, 171.4514],
  [-42.3745, 171.4680],
  [-42.3675, 171.4961],
  [-42.3668, 171.4977],
  [-42.3615, 171.5063],
  [-42.3577, 171.5136],
  [-42.3534, 171.5187],
  [-42.3528, 171.5201],
  [-42.3527, 171.5219],
  [-42.3540, 171.5305],
  [-42.3537, 171.5325],
  [-42.3528, 171.5338],
  [-42.3517, 171.5344],
  [-42.3467, 171.5350],
  [-42.3455, 171.5355],
  [-42.3351, 171.5449],
  [-42.3318, 171.5485],
  [-42.3277, 171.5556],
  [-42.3270, 171.5572],
  [-42.3236, 171.5687],
  [-42.3179, 171.5808],
  [-42.3103, 171.5997],
  [-42.2963, 171.6294],
  [-42.2957, 171.6312],
  [-42.2898, 171.6637],
  [-42.2897, 171.6650],
  [-42.2898, 171.6660],
  [-42.2895, 171.6670],
  [-42.2888, 171.6678],
  [-42.2840, 171.6703],
  [-42.2783, 171.6757],
  [-42.2775, 171.6771],
  [-42.2758, 171.6806],
  [-42.2750, 171.6815],
  [-42.2688, 171.6860],
  [-42.2680, 171.6868],
  [-42.2658, 171.6901],
  [-42.2646, 171.6911],
  [-42.2623, 171.6922],
  [-42.2568, 171.6927],
  [-42.2452, 171.6979],
  [-42.2440, 171.6978],
  [-42.2420, 171.6961],
  [-42.2398, 171.6957],
  [-42.2229, 171.7030],
  [-42.2031, 171.7196],
  [-42.2007, 171.7205],
  [-42.1954, 171.7195],
  [-42.1926, 171.7199],
  [-42.1869, 171.7233],
  [-42.1839, 171.7260],
  [-42.1825, 171.7270],
  [-42.1727, 171.7304],
  [-42.1702, 171.7321],
  [-42.1583, 171.7451],
  [-42.1552, 171.7492],
  [-42.1531, 171.7510],
  [-42.1524, 171.7520],
  [-42.1521, 171.7535],
  [-42.1506, 171.7760],
  [-42.1496, 171.7816],
  [-42.1494, 171.7842],
  [-42.1499, 171.7899],
  [-42.1498, 171.7921],
  [-42.1490, 171.7957],
  [-42.1483, 171.7983],
  [-42.1481, 171.8008],
  [-42.1453, 171.8139],
  [-42.1436, 171.8163],
  [-42.1416, 171.8165],
  [-42.1402, 171.8159],
  [-42.1392, 171.8160],
  [-42.1386, 171.8163]
];

const tawhaiTunnel = [
  [-42.1386, 171.8163],
  [-42.1379, 171.8170],
  [-42.1362, 171.8195]
];

const tawhaiTunnelInangahuaRiver = [
  [-42.1362, 171.8195],
  [-42.1355, 171.8206],
  [-42.1341, 171.8232],
  [-42.1321, 171.8255],
  [-42.1246, 171.8398],
  [-42.1234, 171.8416],
  [-42.1186, 171.8462]
];

const inangahuaRiverCronadun = [
  [-42.1186, 171.8462],
  [-42.1111, 171.8535],
  [-42.1097, 171.8543],
  [-42.1082, 171.8542],
  [-42.0864, 171.8472],
  [-42.0759, 171.8442],
  [-42.0744, 171.8442],
  [-42.0683, 171.8454],
  [-42.0672, 171.8452],
  [-42.0654, 171.8447],
  [-42.0558, 171.8399],
  [-42.0544, 171.8397],
  [-42.0529, 171.8404],
  [-42.0298, 171.8634]
];

const cronadunInangahua = [
  [-42.0298, 171.8634],
  [-42.0153, 171.8778],
  [-42.0132, 171.8794],
  [-42.0117, 171.8803],
  [-42.0010, 171.8938],
  [-42.0000, 171.8947],
  [-41.9972, 171.8966],
  [-41.9953, 171.8969],
  [-41.9854, 171.8956],
  [-41.9387, 171.8932],
  [-41.9186, 171.8923],
  [-41.9151, 171.8928],
  [-41.9137, 171.8935],
  [-41.9123, 171.8950],
  [-41.9112, 171.8956],
  [-41.9097, 171.8956],
  [-41.9083, 171.8960],
  [-41.9074, 171.8969],
  [-41.9068, 171.8980],
  [-41.9062, 171.8987],
  [-41.9003, 171.9039],
  [-41.8988, 171.9046],
  [-41.8975, 171.9047],
  [-41.8904, 171.9030],
  [-41.8887, 171.9029],
  [-41.8773, 171.9050],
  [-41.8761, 171.9057],
  [-41.8752, 171.9072],
  [-41.8730, 171.9188],
  [-41.8729, 171.9214],
  [-41.8722, 171.9239],
  [-41.8570, 171.9505],
  [-41.8564, 171.9510],
  [-41.8549, 171.9516]
];

const inangahuaHawksCragTunnel = [
  [-41.8549, 171.9516],
  [-41.8514, 171.9531],
  [-41.8504, 171.9532],
  [-41.8468, 171.9526],
  [-41.8457, 171.9521],
  [-41.8452, 171.9507],
  [-41.8454, 171.9440],
  [-41.8451, 171.9430],
  [-41.8441, 171.9414],
  [-41.8431, 171.9405],
  [-41.8412, 171.9380],
  [-41.8390, 171.9332],
  [-41.8384, 171.9323],
  [-41.8368, 171.9312],
  [-41.8358, 171.9292],
  [-41.8350, 171.9249],
  [-41.8351, 171.9239],
  [-41.8353, 171.9233],
  [-41.8365, 171.9218],
  [-41.8369, 171.9208],
  [-41.8371, 171.9186],
  [-41.8367, 171.9166],
  [-41.8355, 171.9146],
  [-41.8279, 171.8981],
  [-41.8268, 171.8939],
  [-41.8269, 171.8904],
  [-41.8290, 171.8845],
  [-41.8296, 171.8814],
  [-41.8302, 171.8800],
  [-41.8319, 171.8779],
  [-41.8331, 171.8757],
  [-41.8341, 171.8747],
  [-41.8357, 171.8733],
  [-41.8366, 171.8724],
  [-41.8374, 171.8704],
  [-41.8381, 171.8693],
  [-41.8469, 171.8602],
  [-41.8495, 171.8589],
  [-41.8513, 171.8570],
  [-41.8518, 171.8559],
  [-41.8526, 171.8480],
  [-41.8534, 171.8444],
  [-41.8536, 171.8403],
  [-41.8538, 171.8390],
  [-41.8544, 171.8370],
  [-41.8544, 171.8328],
  [-41.8541, 171.8316],
  [-41.8529, 171.8291],
  [-41.8522, 171.8284],
  [-41.8514, 171.8282],
  [-41.8503, 171.8267],
  [-41.8496, 171.8249],
  [-41.8485, 171.8237],
  [-41.8460, 171.8235],
  [-41.8443, 171.8224],
  [-41.8423, 171.8196],
  [-41.8420, 171.8188],
  [-41.8421, 171.8179],
  [-41.8432, 171.8150],
  [-41.8433, 171.8136],
  [-41.8431, 171.8112],
  [-41.8429, 171.8083],
  [-41.8425, 171.8073],
  [-41.8417, 171.8059],
  [-41.8411, 171.8026],
  [-41.8411, 171.8014],
  [-41.8414, 171.8002],
  [-41.8418, 171.7995],
  [-41.8442, 171.7962],
  [-41.8451, 171.7955],
  [-41.8458, 171.7943],
  [-41.8458, 171.7931],
  [-41.8452, 171.7916],
  [-41.8449, 171.7887],
  [-41.8454, 171.7859],
  [-41.8460, 171.7841],
  [-41.8467, 171.7833],
  [-41.8475, 171.7830],
  [-41.8495, 171.7827],
  [-41.8513, 171.7817],
  [-41.8528, 171.7813],
  [-41.8536, 171.7809],
  [-41.8550, 171.7810],
  [-41.8557, 171.7808],
  [-41.8566, 171.7807],
  [-41.8577, 171.7802],
  [-41.8595, 171.7796],
  [-41.8597, 171.7795]
];

const hawksCragTunnel = [
  [-41.8597, 171.7795],
  [-41.8603, 171.7783],
  [-41.8604, 171.7768],
  [-41.8604, 171.7766]
];

const hawksCragTunnelTunnel3 = [
  [-41.8604, 171.7766],
  [-41.8600, 171.7745],
  [-41.8600, 171.7738]
];

const tunnel3 = [
  [-41.8600, 171.7738],
  [-41.8601, 171.7730]
];

const tunnel3Tunnel4 = [
  [-41.8601, 171.7730],
  [-41.8599, 171.7711],
  [-41.8593, 171.7696],
  [-41.8588, 171.7674],
  [-41.8569, 171.7633],
  [-41.8550, 171.7594],
  [-41.8543, 171.7585],
  [-41.8538, 171.7576],
  [-41.8536, 171.7568],
  [-41.8512, 171.7514],
  [-41.8508, 171.7507],
  [-41.8506, 171.7502],
  [-41.8504, 171.7480],
  [-41.8500, 171.7470],
  [-41.8492, 171.7463],
  [-41.8469, 171.7453],
  [-41.8460, 171.7442],
  [-41.8457, 171.7432],
  [-41.8457, 171.7417],
  [-41.8458, 171.7409],
  [-41.8466, 171.7379],
  [-41.8469, 171.7372],
  [-41.8475, 171.7364],
  [-41.8478, 171.7357],
  [-41.8480, 171.7348],
  [-41.8480, 171.7340],
  [-41.8478, 171.7332],
  [-41.8473, 171.7324],
  [-41.8471, 171.7320],
  [-41.8461, 171.7281],
  [-41.8459, 171.7261],
  [-41.8460, 171.7249],
  [-41.8468, 171.7210],
  [-41.8465, 171.7193],
  [-41.8458, 171.7180],
  [-41.8443, 171.7169],
  [-41.8419, 171.7145],
  [-41.8413, 171.7141],
  [-41.8403, 171.7129],
  [-41.8397, 171.7120],
  [-41.8396, 171.7119]
];

const tunnel4 = [
  [-41.8396, 171.7119],
  [-41.8388, 171.7114],
  [-41.8384, 171.7114]
];

const tunnel4Tunnel5 = [
  [-41.8384, 171.7114],
  [-41.8376, 171.7119],
  [-41.8346, 171.7145],
  [-41.8342, 171.7147],
  [-41.8337, 171.7147],
  [-41.8330, 171.7143],
  [-41.8321, 171.7127],
  [-41.8318, 171.7118]
];

const tunnel5 = [
  [-41.8318, 171.7118],
  [-41.8317, 171.7100]
];

const tunnel5Tunnel6 = [
  [-41.8317, 171.7100],
  [-41.8317, 171.7089],
  [-41.8321, 171.7075],
  [-41.8320, 171.7065],
  [-41.8319, 171.7057],
  [-41.8319, 171.7048],
  [-41.8324, 171.7000],
  [-41.8324, 171.6939],
  [-41.8330, 171.6895],
  [-41.8330, 171.6880],
  [-41.8334, 171.6855],
  [-41.8340, 171.6777],
  [-41.8345, 171.6759]
];

const tunnel6 = [
  [-41.8345, 171.6759],
  [-41.8345, 171.6754]
];

const tunnel6Tunnel7 = [
  [-41.8345, 171.6754],
  [-41.8345, 171.6734],
  [-41.8340, 171.6707],
  [-41.8319, 171.6652],
  [-41.8315, 171.6638],
  [-41.8309, 171.6613],
  [-41.8300, 171.6592],
  [-41.8275, 171.6561]
];

const tunnel7 = [
  [-41.8275, 171.6561],
  [-41.8271, 171.6557]
];

const teKuhaWestport = [
  [-41.8271, 171.6557],
  [-41.8243, 171.6522],
  [-41.8192, 171.6443],
  [-41.8176, 171.6428],
  [-41.7978, 171.6329],
  [-41.7969, 171.6320],
  [-41.7864, 171.6131],
  [-41.7850, 171.6119],
  [-41.7663, 171.6076],
  [-41.7656, 171.6071],
  [-41.7653, 171.6064],
  [-41.7651, 171.6051]
];

const westportWestJunction = [
  [-41.7646, 171.6089],
  [-41.7649, 171.6082],
  [-41.7654, 171.6077],
  [-41.7663, 171.6076]
];

export const stillwaterWestport: Line = {
  name: 'Stillwater - Westport',
  state: 'NZ',
  segments: [
    {
      segments: [stillwaterSouthJunction],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [stillwaterNgahere],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        ngatapaTawhaiTunnel,
        {
          name: 'Tawhai Tunnel',
          segment: tawhaiTunnel,
          type: 'tunnel'
        },
        tawhaiTunnelInangahuaRiver
      ],
      history: {
        opened: {
          date: '1892-02-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [inangahuaRiverCronadun],
      history: {
        opened: {
          date: '1908-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cronadunInangahua],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        inangahuaHawksCragTunnel,
        {
          name: 'Hawk\'s Crag Tunnel',
          segment: hawksCragTunnel,
          type: 'tunnel'
        },
        hawksCragTunnelTunnel3,
        {
          name: 'Tunnel No 3',
          segment: tunnel3,
          type: 'tunnel'
        },
        tunnel3Tunnel4,
        {
          name: 'Tunnel No 4',
          segment: tunnel4,
          type: 'tunnel'
        },
        tunnel4Tunnel5,
        {
          name: 'Tunnel No 5',
          segment: tunnel5,
          type: 'tunnel'
        },
        tunnel5Tunnel6,
        {
          name: 'Tunnel No 6',
          segment: tunnel6,
          type: 'tunnel'
        },
        tunnel6Tunnel7,
        {
          date: '2014-01-01',
          original: [{
            name: 'Tunnel No 7',
            segment: tunnel7,
            type: 'tunnel'
          },],
          deviation: [tunnel7]
        }
      ],
      history: {
        opened: {
          date: '1914-07-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [teKuhaWestport],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [westportWestJunction],
      history: {
        opened: {
          date: '1912-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
