import { Line } from "../../../trackTypes";

const jericoBlackall = [
  [-23.6013, 146.1219],
  [-23.6015, 146.1207],
  [-23.6020, 146.1200],
  [-23.7463, 146.0242],
  [-23.7529, 146.0161],
  [-23.7552, 146.0100],
  [-23.7570, 146.0071],
  [-23.7597, 146.0044],
  [-23.7619, 146.0030],
  [-23.7718, 145.9982],
  [-23.7748, 145.9957],
  [-23.7766, 145.9926],
  [-23.7787, 145.9861],
  [-23.7798, 145.9844],
  [-23.7818, 145.9827],
  [-23.7890, 145.9796],
  [-23.7945, 145.9754],
  [-23.8079, 145.9587],
  [-23.8090, 145.9563],
  [-23.8183, 145.9422],
  [-23.8201, 145.9385],
  [-23.8276, 145.9299],
  [-23.8319, 145.9268],
  [-23.8383, 145.9244],
  [-23.8424, 145.9243],
  [-23.8544, 145.9270],
  [-23.8593, 145.9270],
  [-23.8648, 145.9246],
  [-24.0566, 145.7653],
  [-24.1887, 145.6552],
  [-24.3185, 145.5468],
  [-24.3461, 145.5238],
  [-24.4182, 145.4693],
  [-24.4190, 145.4664],
  [-24.4174, 145.4610]
];

const blackallMalvernton = [
  [-24.4174, 145.4610],
  [-24.4165, 145.4577],
  [-24.4164, 145.4530],
  [-24.4171, 145.4490],
  [-24.4171, 145.4461],
  [-24.4155, 145.4265],
  [-24.4159, 145.4226],
  [-24.4187, 145.4127],
  [-24.4773, 145.2150],
  [-24.5046, 145.1216],
  [-24.5060, 145.1165]
];

const malverntonBenlidi = [
  [-24.5060, 145.1165],
  [-24.5112, 145.0984],
  [-24.5527, 144.9129],
  [-24.5571, 144.8645]
];

const benlidiEmmet = [
  [-24.5571, 144.8645],
  [-24.5833, 144.7706],
  [-24.5981, 144.7307],
  [-24.5988, 144.7280],
  [-24.6039, 144.6947],
  [-24.6051, 144.6906],
  [-24.6067, 144.6880],
  [-24.6134, 144.6810],
  [-24.6147, 144.6784],
  [-24.6555, 144.5328],
  [-24.6563, 144.5303],
  [-24.6631, 144.5171],
  [-24.6637, 144.5146],
  [-24.6684, 144.4771]
];

const emmetYaraka = [
  [-24.6684, 144.4771],
  [-24.6764, 144.4135],
  [-24.6833, 144.3577],
  [-24.6837, 144.3562],
  [-24.7188, 144.2843],
  [-24.7193, 144.2823],
  [-24.7228, 144.2519],
  [-24.7231, 144.2507],
  [-24.7266, 144.2442],
  [-24.7289, 144.2427],
  [-24.7340, 144.2422],
  [-24.7388, 144.2397],
  [-24.7399, 144.2383],
  [-24.7440, 144.2294],
  [-24.7444, 144.2278],
  [-24.7441, 144.2236],
  [-24.7413, 144.2160],
  [-24.7413, 144.2134],
  [-24.7468, 144.1978],
  [-24.7482, 144.1961],
  [-24.7589, 144.1893],
  [-24.7605, 144.1871],
  [-24.7726, 144.1488],
  [-24.7738, 144.1471],
  [-24.7968, 144.1279],
  [-24.8226, 144.1159],
  [-24.8334, 144.1065],
  [-24.8387, 144.0978],
  [-24.8403, 144.0964],
  [-24.8613, 144.0866],
  [-24.8624, 144.0865],
  [-24.8693, 144.0879],
  [-24.8712, 144.0878],
  [-24.8747, 144.0864],
  [-24.8765, 144.0851],
  [-24.8839, 144.0750]
];

export const blackall: Line = {
  name: 'Blackall',
  state: 'QLD',
  segments: [
    {
      segments: [jericoBlackall],
      history: {
        opened: {
          date: '1908-03-31',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [blackallMalvernton],
      history: {
        opened: {
          date: '1913-06-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [malverntonBenlidi],
      history: {
        opened: {
          date: '1913-07-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [benlidiEmmet],
      history: {
        opened: {
          date: '1914-12-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [emmetYaraka],
      history: {
        opened: {
          date: '1917-04-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-09-01',
          status: 'closed'
        }]
      }
    }
  ]
}
