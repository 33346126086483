import { Line } from "../../trackTypes";

const line = [
  [-28.03023, 153.42927],
  [-28.03060, 153.42878],
  [-28.03066, 153.42873],
  [-28.03073, 153.42872],
  [-28.03079, 153.42872],
  [-28.03084, 153.42875],
  [-28.03117, 153.42912],
  [-28.03119, 153.42920],
  [-28.03116, 153.42930],
  [-28.03106, 153.42941],
  [-28.03098, 153.42947],
  [-28.03090, 153.42947],
  [-28.03082, 153.42943],
  [-28.03056, 153.42921],
  [-28.03048, 153.42918],
  [-28.03038, 153.42919],
  [-28.03023, 153.42927],
  [-28.02988, 153.42975],
  [-28.02975, 153.42995],
  [-28.02966, 153.43017],
  [-28.02962, 153.43038],
  [-28.02963, 153.43057],
  [-28.02964, 153.43066],
  [-28.02962, 153.43080],
  [-28.02931, 153.43262],
  [-28.02931, 153.43274],
  [-28.02937, 153.43282],
  [-28.02950, 153.43295],
  [-28.02953, 153.43303],
  [-28.02954, 153.43313],
  [-28.02953, 153.43320],
  [-28.02949, 153.43343],
  [-28.02946, 153.43349],
  [-28.02940, 153.43349],
  [-28.02935, 153.43348],
  [-28.02932, 153.43345],
  [-28.02931, 153.43340],
  [-28.02931, 153.43334],
  [-28.02934, 153.43326],
  [-28.02939, 153.43320],
  [-28.02941, 153.43316],
  [-28.02942, 153.43311],
  [-28.02940, 153.43303],
  [-28.02931, 153.43274]
];

export const oasisMonorail: Line = {
  name: 'Oasis Monorail',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1989-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'monorail'
        },
        trackChange: [{
          date: '2017-01-29',
          status: 'closed'
        }]
      }
    }
  ]
}
