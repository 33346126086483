import { Line } from "../../../trackTypes";

const benallaStJames = [
  [-36.5432, 145.9874],
  [-36.5424, 145.9884],
  [-36.5418, 145.9888],
  [-36.5411, 145.9890],
  [-36.5349, 145.9886],
  [-36.5337, 145.9888],
  [-36.5195, 145.9918],
  [-36.5179, 145.9917],
  [-36.5167, 145.9914],
  [-36.5158, 145.9909],
  [-36.4756, 145.9651],
  [-36.4740, 145.9643],
  [-36.4720, 145.9637],
  [-36.4515, 145.9578],
  [-36.4497, 145.9572],
  [-36.4474, 145.9558],
  [-36.4453, 145.9541],
  [-36.4429, 145.9514],
  [-36.4333, 145.9385],
  [-36.4315, 145.9367],
  [-36.3679, 145.8960],
  [-36.3645, 145.8946],
  [-36.3612, 145.8938],
  [-36.2711, 145.8937]
];

const stJamesYarrawonga = [
  [-36.2711, 145.8937],
  [-36.2499, 145.8936],
  [-36.2486, 145.8937],
  [-36.2356, 145.8951],
  [-36.2201, 145.8953],
  [-36.2188, 145.8951],
  [-36.1924, 145.8823],
  [-36.1910, 145.8819],
  [-36.1893, 145.8818],
  [-36.1644, 145.8817],
  [-36.1632, 145.8819],
  [-36.1621, 145.8824],
  [-36.1614, 145.8829],
  [-36.0735, 145.9571],
  [-36.0721, 145.9580],
  [-36.0458, 145.9728],
  [-36.0435, 145.9746],
  [-36.0418, 145.9762],
  [-36.0256, 145.9961],
  [-36.0237, 145.9975],
  [-36.0225, 145.9982],
  [-36.0212, 145.9986],
  [-36.0169, 145.9986]
];

const yarrawongaNswBorder = [
  [-36.0169, 145.9986],
  [-36.0138, 145.9986],
  [-36.0126, 145.9987],
  [-36.0104, 145.9999],
  [-36.0097, 146.0001],
  [-36.0088, 146.0000]
];

export const oaklands: Line = {
  name: 'Oaklands',
  state: 'VIC',
  segments: [
    {
      segments: [benallaStJames],
      history: {
        opened: {
          date: '1883-06-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-03-09',
          gauge: 'standard'
        }, {
          date: '2008-11-09',
          status: 'closed'
        }, {
          date: '2011-06-26',
          status: 'open'
        }]
      }
    },
    {
      segments: [stJamesYarrawonga],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-03-09',
          gauge: 'standard'
        }, {
          date: '2008-11-09',
          status: 'closed'
        }, {
          date: '2011-06-26',
          status: 'open'
        }],
      }
    },
    {
      segments: [yarrawongaNswBorder],
      history: {
        opened: {
          date: '1932-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2010-03-09',
          gauge: 'standard'
        }, {
          date: '2008-11-09',
          status: 'closed'
        }, {
          date: '2011-06-26',
          status: 'open'
        }],
      }
    }
  ]
};
