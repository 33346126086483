import { Line } from "../../trackTypes";

const waggaWaggaLadysmith = [
  [-35.1209, 147.3753],
  [-35.1209, 147.3764],
  [-35.1211, 147.3773],
  [-35.1211, 147.3773],
  [-35.1366, 147.3955],
  [-35.1383, 147.3994],
  [-35.1393, 147.4063],
  [-35.1401, 147.4090],
  [-35.1466, 147.4232],
  [-35.1474, 147.4259],
  [-35.1478, 147.4287],
  [-35.1487, 147.4316],
  [-35.1520, 147.4380],
  [-35.1527, 147.4400],
  [-35.1564, 147.4545],
  [-35.1600, 147.4995],
  [-35.1605, 147.5010],
  [-35.1613, 147.5018],
  [-35.1637, 147.5028],
  [-35.1790, 147.5056],
  [-35.1818, 147.5065],
  [-35.1849, 147.5069],
  [-35.1887, 147.5090],
  [-35.1902, 147.5093],
  [-35.1922, 147.5090],
  [-35.1932, 147.5091],
  [-35.1947, 147.5099],
  [-35.2004, 147.5155],
  [-35.2018, 147.5162],
  [-35.2031, 147.5161],
  [-35.2074, 147.5147]
];

const ladysmithHumula = [
  [-35.2074, 147.5147],
  [-35.2144, 147.5124],
  [-35.2177, 147.5125],
  [-35.2194, 147.5123],
  [-35.2216, 147.5131],
  [-35.2232, 147.5147],
  [-35.2247, 147.5145],
  [-35.2342, 147.5161],
  [-35.2365, 147.5186],
  [-35.2368, 147.5198],
  [-35.2366, 147.5210],
  [-35.2350, 147.5240],
  [-35.2346, 147.5254],
  [-35.2347, 147.5266],
  [-35.2352, 147.5293],
  [-35.2349, 147.5306],
  [-35.2343, 147.5313],
  [-35.2329, 147.5324],
  [-35.2321, 147.5335],
  [-35.2302, 147.5377],
  [-35.2303, 147.5393],
  [-35.2309, 147.5405],
  [-35.2325, 147.5417],
  [-35.2330, 147.5429],
  [-35.2334, 147.5449],
  [-35.2343, 147.5460],
  [-35.2354, 147.5464],
  [-35.2363, 147.5460],
  [-35.2382, 147.5446],
  [-35.2393, 147.5445],
  [-35.2403, 147.5450],
  [-35.2414, 147.5463],
  [-35.2417, 147.5476],
  [-35.2416, 147.5487],
  [-35.2394, 147.5546],
  [-35.2341, 147.5599],
  [-35.2330, 147.5605],
  [-35.2315, 147.5607],
  [-35.2305, 147.5613],
  [-35.2300, 147.5626],
  [-35.2299, 147.5669],
  [-35.2291, 147.5691],
  [-35.2239, 147.5749],
  [-35.2232, 147.5763],
  [-35.2220, 147.5803],
  [-35.2220, 147.5821],
  [-35.2226, 147.5834],
  [-35.2236, 147.5848],
  [-35.2242, 147.5865],
  [-35.2238, 147.5886],
  [-35.2200, 147.5965],
  [-35.2130, 147.6060],
  [-35.2128, 147.6068],
  [-35.2114, 147.6116],
  [-35.2113, 147.6133],
  [-35.2129, 147.6196],
  [-35.2129, 147.6210],
  [-35.2115, 147.6298],
  [-35.2116, 147.6312],
  [-35.2132, 147.6395],
  [-35.2130, 147.6421],
  [-35.2122, 147.6436],
  [-35.1972, 147.6592],
  [-35.1965, 147.6611],
  [-35.1964, 147.6625],
  [-35.1969, 147.6652],
  [-35.1968, 147.6671],
  [-35.1952, 147.6739],
  [-35.1953, 147.6753],
  [-35.1960, 147.6763],
  [-35.1968, 147.6766],
  [-35.2091, 147.6768],
  [-35.2114, 147.6759],
  [-35.2136, 147.6757],
  [-35.2149, 147.6762],
  [-35.2156, 147.6772],
  [-35.2167, 147.6799],
  [-35.2178, 147.6810],
  [-35.2216, 147.6817],
  [-35.2281, 147.6804],
  [-35.2291, 147.6796],
  [-35.2295, 147.6785],
  [-35.2304, 147.6768],
  [-35.2316, 147.6763],
  [-35.2418, 147.6764],
  [-35.2436, 147.6767],
  [-35.2482, 147.6784],
  [-35.2494, 147.6793],
  [-35.2501, 147.6806],
  [-35.2517, 147.6821],
  [-35.2561, 147.6832],
  [-35.2612, 147.6848],
  [-35.2625, 147.6847],
  [-35.2637, 147.6855],
  [-35.2654, 147.6883],
  [-35.2657, 147.6895],
  [-35.2661, 147.6942],
  [-35.2667, 147.6953],
  [-35.2679, 147.6960],
  [-35.2694, 147.6957],
  [-35.2705, 147.6944],
  [-35.2715, 147.6938],
  [-35.2731, 147.6943],
  [-35.2739, 147.6955],
  [-35.2741, 147.6970],
  [-35.2739, 147.6983],
  [-35.2740, 147.6995],
  [-35.2746, 147.7007],
  [-35.2779, 147.7038],
  [-35.2785, 147.7053],
  [-35.2786, 147.7073],
  [-35.2792, 147.7086],
  [-35.2798, 147.7091],
  [-35.2811, 147.7096],
  [-35.2824, 147.7108],
  [-35.2827, 147.7122],
  [-35.2827, 147.7138],
  [-35.2833, 147.7154],
  [-35.2847, 147.7161],
  [-35.2861, 147.7155],
  [-35.2876, 147.7135],
  [-35.2894, 147.7119],
  [-35.2899, 147.7104],
  [-35.2899, 147.7091],
  [-35.2894, 147.7069],
  [-35.2894, 147.7053],
  [-35.2909, 147.7032],
  [-35.2938, 147.7027],
  [-35.2950, 147.7032],
  [-35.2958, 147.7043],
  [-35.2967, 147.7105],
  [-35.2961, 147.7146],
  [-35.2948, 147.7175],
  [-35.2925, 147.7203],
  [-35.2917, 147.7221],
  [-35.2916, 147.7239],
  [-35.2922, 147.7258],
  [-35.2998, 147.7411],
  [-35.3006, 147.7447],
  [-35.3008, 147.7511],
  [-35.3015, 147.7530],
  [-35.3024, 147.7539],
  [-35.3057, 147.7553],
  [-35.3082, 147.7581],
  [-35.3098, 147.7588],
  [-35.3185, 147.7592],
  [-35.3209, 147.7599],
  [-35.3238, 147.7624],
  [-35.3252, 147.7630],
  [-35.3368, 147.7636],
  [-35.3393, 147.7641],
  [-35.3575, 147.7722],
  [-35.3588, 147.7722],
  [-35.3604, 147.7715],
  [-35.3618, 147.7712],
  [-35.3742, 147.7727],
  [-35.3789, 147.7722],
  [-35.3809, 147.7707],
  [-35.3819, 147.7687],
  [-35.3836, 147.7671],
  [-35.3879, 147.7665],
  [-35.3898, 147.7654],
  [-35.4032, 147.7646],
  [-35.4054, 147.7655],
  [-35.4071, 147.7662],
  [-35.4085, 147.7660],
  [-35.4105, 147.7649],
  [-35.4123, 147.7649],
  [-35.4136, 147.7655],
  [-35.4169, 147.7696],
  [-35.4182, 147.7701],
  [-35.4196, 147.7696],
  [-35.4223, 147.7678],
  [-35.4240, 147.7673],
  [-35.4270, 147.7674],
  [-35.4285, 147.7667],
  [-35.4295, 147.7655],
  [-35.4297, 147.7642],
  [-35.4296, 147.7620],
  [-35.4300, 147.7603],
  [-35.4307, 147.7594],
  [-35.4333, 147.7582],
  [-35.4344, 147.7573],
  [-35.4351, 147.7564],
  [-35.4365, 147.7562],
  [-35.4412, 147.7581],
  [-35.4426, 147.7596],
  [-35.4440, 147.7615],
  [-35.4546, 147.7675],
  [-35.4555, 147.7676],
  [-35.4571, 147.7672],
  [-35.4607, 147.7669],
  [-35.4619, 147.7665],
  [-35.4628, 147.7655],
  [-35.4637, 147.7639],
  [-35.4652, 147.7627],
  [-35.4742, 147.7610],
  [-35.4756, 147.7610],
  [-35.4779, 147.7608],
  [-35.4800, 147.7599],
  [-35.4844, 147.7575],
  [-35.4859, 147.7572],
  [-35.4870, 147.7578],
  [-35.4876, 147.7588],
  [-35.4883, 147.7600],
  [-35.4894, 147.7607],
  [-35.4923, 147.7612]
];

const humulaTumbarumba = [
  [-35.4923, 147.7612],
  [-35.4978, 147.7625],
  [-35.5016, 147.7650],
  [-35.5032, 147.7652],
  [-35.5049, 147.7647],
  [-35.5061, 147.7646],
  [-35.5151, 147.7660],
  [-35.5198, 147.7681],
  [-35.5220, 147.7684],
  [-35.5245, 147.7681],
  [-35.5257, 147.7685],
  [-35.5324, 147.7729],
  [-35.5344, 147.7755],
  [-35.5356, 147.7764],
  [-35.5384, 147.7771],
  [-35.5392, 147.7778],
  [-35.5404, 147.7803],
  [-35.5412, 147.7812],
  [-35.5421, 147.7816],
  [-35.5503, 147.7821],
  [-35.5523, 147.7824],
  [-35.5588, 147.7846],
  [-35.5643, 147.7872],
  [-35.5671, 147.7883],
  [-35.5684, 147.7892],
  [-35.5714, 147.7935],
  [-35.5735, 147.7953],
  [-35.5822, 147.8007],
  [-35.5859, 147.8051],
  [-35.5877, 147.8063],
  [-35.5945, 147.8076],
  [-35.5962, 147.8089],
  [-35.6011, 147.8174],
  [-35.6026, 147.8183],
  [-35.6072, 147.8183],
  [-35.6092, 147.8175],
  [-35.6100, 147.8173],
  [-35.6108, 147.8179],
  [-35.6151, 147.8183],
  [-35.6162, 147.8191],
  [-35.6172, 147.8206],
  [-35.6180, 147.8214],
  [-35.6193, 147.8215],
  [-35.6201, 147.8206],
  [-35.6201, 147.8182],
  [-35.6186, 147.8169],
  [-35.6172, 147.8165],
  [-35.6165, 147.8150],
  [-35.6170, 147.8129],
  [-35.6182, 147.8122],
  [-35.6195, 147.8128],
  [-35.6218, 147.8146],
  [-35.6227, 147.8175],
  [-35.6241, 147.8194],
  [-35.6273, 147.8209],
  [-35.6285, 147.8222],
  [-35.6301, 147.8234],
  [-35.6315, 147.8257],
  [-35.6333, 147.8267],
  [-35.6343, 147.8293],
  [-35.6341, 147.8314],
  [-35.6345, 147.8333],
  [-35.6358, 147.8345],
  [-35.6379, 147.8350],
  [-35.6392, 147.8360],
  [-35.6424, 147.8400],
  [-35.6438, 147.8411],
  [-35.6502, 147.8443],
  [-35.6526, 147.8450],
  [-35.6547, 147.8478],
  [-35.6563, 147.8504],
  [-35.6576, 147.8531],
  [-35.6636, 147.8591],
  [-35.6658, 147.8635],
  [-35.6673, 147.8644],
  [-35.6700, 147.8647],
  [-35.6746, 147.8651],
  [-35.6759, 147.8657],
  [-35.6765, 147.8665],
  [-35.6791, 147.8721],
  [-35.6795, 147.8727],
  [-35.6800, 147.8730],
  [-35.6837, 147.8732],
  [-35.6856, 147.8743],
  [-35.6887, 147.8787],
  [-35.6895, 147.8794],
  [-35.6911, 147.8796],
  [-35.6919, 147.8799],
  [-35.6933, 147.8809],
  [-35.6969, 147.8817],
  [-35.6982, 147.8823],
  [-35.7042, 147.8861],
  [-35.7048, 147.8870],
  [-35.7054, 147.8880],
  [-35.7062, 147.8890],
  [-35.7159, 147.8935],
  [-35.7171, 147.8942],
  [-35.7190, 147.8966],
  [-35.7195, 147.8976],
  [-35.7196, 147.8987],
  [-35.7193, 147.9012],
  [-35.7194, 147.9024],
  [-35.7198, 147.9031],
  [-35.7216, 147.9052],
  [-35.7223, 147.9067],
  [-35.7241, 147.9151],
  [-35.7266, 147.9219],
  [-35.7290, 147.9244],
  [-35.7296, 147.9248],
  [-35.7303, 147.9249],
  [-35.7324, 147.9246],
  [-35.7338, 147.9250],
  [-35.7347, 147.9264],
  [-35.7348, 147.9287],
  [-35.7356, 147.9303],
  [-35.7422, 147.9354],
  [-35.7429, 147.9362],
  [-35.7461, 147.9414],
  [-35.7482, 147.9442],
  [-35.7522, 147.9475],
  [-35.7549, 147.9489],
  [-35.7559, 147.9498],
  [-35.7563, 147.9508],
  [-35.7567, 147.9526],
  [-35.7606, 147.9579],
  [-35.7649, 147.9608],
  [-35.7690, 147.9627],
  [-35.7725, 147.9670],
  [-35.7777, 147.9728],
  [-35.7813, 147.9757],
  [-35.7899, 147.9807],
  [-35.7909, 147.9820],
  [-35.7911, 147.9839],
  [-35.7902, 147.9865],
  [-35.7900, 147.9882],
  [-35.7894, 147.9897],
  [-35.7884, 147.9904],
  [-35.7873, 147.9904],
  [-35.7857, 147.9898],
  [-35.7822, 147.9902],
  [-35.7809, 147.9909],
  [-35.7786, 147.9920],
  [-35.7709, 147.9921]
];

export const tumbarumba: Line = {
  name: 'Tumbarumba',
  state: 'NSW',
  segments: [
    {
      segments: [waggaWaggaLadysmith],
      history: {
        opened: {
          date: '1917-05-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-03-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ladysmithHumula],
      history: {
        opened: {
          date: '1917-05-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-10-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [humulaTumbarumba],
      history: {
        opened: {
          date: '1921-05-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1974-10-08',
          status: 'closed'
        }]
      }
    }
  ]
};
