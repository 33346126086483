import { Line } from "../../../trackTypes";

const line = [
  [-37.9423, 176.7702],
  [-37.9476, 176.7754],
  [-37.9487, 176.7767],
  [-37.9822, 176.8374],
  [-37.9939, 176.8712],
  [-38.0049, 176.9131],
  [-38.0055, 176.9192],
  [-38.0051, 176.9219],
  [-38.0039, 176.9250],
  [-38.0037, 176.9275],
  [-38.0052, 176.9404],
  [-38.0060, 176.9422],
  [-38.0074, 176.9435],
  [-38.0107, 176.9448],
  [-38.0181, 176.9499],
  [-38.0219, 176.9535],
  [-38.0233, 176.9542],
  [-38.0284, 176.9550],
  [-38.0300, 176.9559],
  [-38.0327, 176.9581],
  [-38.0337, 176.9594],
  [-38.0379, 176.9662],
  [-38.0385, 176.9679],
  [-38.0387, 176.9698],
  [-38.0391, 176.9711],
  [-38.0399, 176.9721],
  [-38.0417, 176.9731],
  [-38.0434, 176.9748],
  [-38.0615, 177.0112]
];

export const taneatua: Line = {
  name: 'Taneatua',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1928-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2003-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
