import { Line } from "../../trackTypes";

const martonTurakina = [
  [-40.0874, 175.3914],
  [-40.0851, 175.3899],
  [-40.0836, 175.3886],
  [-40.0820, 175.3860],
  [-40.0802, 175.3824],
  [-40.0786, 175.3778],
  [-40.0781, 175.3769],
  [-40.0759, 175.3742],
  [-40.0710, 175.3635],
  [-40.0694, 175.3592],
  [-40.0691, 175.3581],
  [-40.0658, 175.3399],
  [-40.0631, 175.3321],
  [-40.0620, 175.3276],
  [-40.0604, 175.3227],
  [-40.0602, 175.3215],
  [-40.0602, 175.3203],
  [-40.0601, 175.3191],
  [-40.0578, 175.3107],
  [-40.0575, 175.3096],
  [-40.0555, 175.2945],
  [-40.0555, 175.2936],
  [-40.0556, 175.2907],
  [-40.0553, 175.2787],
  [-40.0550, 175.2764],
  [-40.0546, 175.2743],
  [-40.0500, 175.2614],
  [-40.0498, 175.2602],
  [-40.0495, 175.2565],
  [-40.0495, 175.2537],
  [-40.0493, 175.2522],
  [-40.0487, 175.2504],
  [-40.0485, 175.2490],
  [-40.0482, 175.2478],
  [-40.0443, 175.2397],
  [-40.0437, 175.2383],
  [-40.0422, 175.2315],
  [-40.0415, 175.2298],
  [-40.0414, 175.2282],
  [-40.0419, 175.2268],
  [-40.0428, 175.2256],
  [-40.0438, 175.2250],
  [-40.0446, 175.2241],
  [-40.0450, 175.2226],
  [-40.0451, 175.2201],
  [-40.0449, 175.2186],
  [-40.0442, 175.2174],
  [-40.0426, 175.2159],
  [-40.0418, 175.2153],
  [-40.0355, 175.2127]
];

const turakinaTurakinaDeviationStart = [
  [-40.0355, 175.2127],
  [-40.0337, 175.2119]
];

const turakinaOld = [
  [-40.0337, 175.2119],
  [-40.0332, 175.2113],
  [-40.0328, 175.2105],
  [-40.0324, 175.2083],
  [-40.0325, 175.2078],
  [-40.0327, 175.2073],
  [-40.0340, 175.2062],
  [-40.0345, 175.2057],
  [-40.0349, 175.2047],
  [-40.0360, 175.2034],
  [-40.0365, 175.2023],
  [-40.0366, 175.2019],
  [-40.0370, 175.2012],
  [-40.0374, 175.2009],
  [-40.0385, 175.1988],
  [-40.0387, 175.1980],
  [-40.0387, 175.1973],
  [-40.0390, 175.1967],
  [-40.0396, 175.1962],
  [-40.0400, 175.1962],
  [-40.0405, 175.1961],
  [-40.0411, 175.1956],
  [-40.0420, 175.1955],
  [-40.0427, 175.1947],
  [-40.0431, 175.1935],
  [-40.0433, 175.1799],
  [-40.0431, 175.1788],
  [-40.0324, 175.1539],
  [-40.0318, 175.1534],
  [-40.0312, 175.1535],
  [-40.0304, 175.1542],
  [-40.0295, 175.1545],
  [-40.0291, 175.1549],
  [-40.0289, 175.1555],
  [-40.0289, 175.1566],
  [-40.0285, 175.1583],
  [-40.0276, 175.1599],
  [-40.0259, 175.1611],
  [-40.0220, 175.1613],
  [-40.0118, 175.1644],
  [-40.0021, 175.1673],
  [-40.0012, 175.1681],
  [-39.9998, 175.1707],
  [-39.9992, 175.1714],
  [-39.9981, 175.1720],
  [-39.9971, 175.1730],
  [-39.9968, 175.1735],
  [-39.9956, 175.1776],
  [-39.9950, 175.1781],
  [-39.9946, 175.1785],
  [-39.9942, 175.1793],
  [-39.9934, 175.1796],
  [-39.9917, 175.1795],
  [-39.9894, 175.1799],
  [-39.9890, 175.1801],
  [-39.9888, 175.1808],
  [-39.9886, 175.1817],
  [-39.9888, 175.1826],
  [-39.9893, 175.1831],
  [-39.9898, 175.1831],
  [-39.9902, 175.1828],
  [-39.9907, 175.1828],
  [-39.9911, 175.1831],
  [-39.9916, 175.1841],
  [-39.9926, 175.1851],
  [-39.9927, 175.1855],
  [-39.9927, 175.1860],
  [-39.9924, 175.1866],
  [-39.9922, 175.1868],
  [-39.9919, 175.1869],
  [-39.9915, 175.1870],
  [-39.9909, 175.1877],
  [-39.9903, 175.1879],
  [-39.9893, 175.1878],
  [-39.9874, 175.1888],
  [-39.9869, 175.1888],
  [-39.9865, 175.1885],
  [-39.9860, 175.1877],
  [-39.9855, 175.1873],
  [-39.9848, 175.1874],
  [-39.9817, 175.1895],
  [-39.9765, 175.1952],
  [-39.9672, 175.2025],
  [-39.9667, 175.2032],
  [-39.9658, 175.2044],
  [-39.9639, 175.2060],
  [-39.9633, 175.2062],
  [-39.9626, 175.2060],
  [-39.9614, 175.2051],
  [-39.9608, 175.2049],
  [-39.9582, 175.2053],
  [-39.9563, 175.2054],
  [-39.9512, 175.2056],
  [-39.9507, 175.2053],
  [-39.9499, 175.2043],
  [-39.9497, 175.2037],
  [-39.9495, 175.2021],
  [-39.9494, 175.2015],
  [-39.9482, 175.1994],
  [-39.9480, 175.1988],
  [-39.9480, 175.1979],
  [-39.9479, 175.1974],
  [-39.9472, 175.1957],
  [-39.9471, 175.1951],
  [-39.9473, 175.1945],
  [-39.9473, 175.1940],
  [-39.9471, 175.1936],
  [-39.9462, 175.1925],
  [-39.9460, 175.1918],
  [-39.9460, 175.1912],
  [-39.9463, 175.1905],
  [-39.9465, 175.1901],
  [-39.9466, 175.1885],
  [-39.9460, 175.1848],
  [-39.9456, 175.1834],
  [-39.9452, 175.1814],
  [-39.9449, 175.1809],
  [-39.9444, 175.1808],
  [-39.9439, 175.1811],
  [-39.9433, 175.1821],
  [-39.9429, 175.1825],
  [-39.9426, 175.1826],
  [-39.9416, 175.1820]
];

const turakinaDeviationStartTurakinaTunnel = [
  [-40.0337, 175.2119],
  [-40.0323, 175.2114],
  [-40.0304, 175.2104]
];

const turakinaTunnel = [
  [-40.0304, 175.2104],
  [-40.0129, 175.2013]
];

const turakinaTunnelFordellNo2Tunnel = [
  [-40.0129, 175.2013],
  [-40.0128, 175.2011],
  [-40.0119, 175.2009],
  [-40.0108, 175.2011],
  [-40.0086, 175.2022],
  [-40.0074, 175.2026],
  [-39.9916, 175.2040],
  [-39.9901, 175.2046],
  [-39.9862, 175.2090],
  [-39.9854, 175.2108],
  [-39.9852, 175.2127],
  [-39.9855, 175.2149],
  [-39.9854, 175.2162],
  [-39.9849, 175.2176],
  [-39.9843, 175.2183],
  [-39.9815, 175.2201],
  [-39.9808, 175.2202],
  [-39.9794, 175.2203],
  [-39.9757, 175.2223],
  [-39.9742, 175.2235],
  [-39.9728, 175.2238],
  [-39.9710, 175.2231],
  [-39.9705, 175.2231]
];

const fordellNo2Tunnel = [
  [-39.9705, 175.2231],
  [-39.9699, 175.2231]
];

const fordellNo2TunnelFordellNo1Tunnel = [
  [-39.9699, 175.2231],
  [-39.9677, 175.2235],
  [-39.9667, 175.2235]
];

const fordellNo1Tunnel = [
  [-39.9667, 175.2235],
  [-39.9540, 175.2190]
];

const fordellNo1TunnelTurakinaDeviationEnd = [
  [-39.9540, 175.2190],
  [-39.9532, 175.2186],
  [-39.9522, 175.2175],
  [-39.9507, 175.2135],
  [-39.9504, 175.2121],
  [-39.9505, 175.2104],
  [-39.9503, 175.2092],
  [-39.9470, 175.2025],
  [-39.9443, 175.1939],
  [-39.9442, 175.1928],
  [-39.9441, 175.1912],
  [-39.9439, 175.1900],
  [-39.9431, 175.1880],
  [-39.9424, 175.1841],
  [-39.9420, 175.1829],
  [-39.9416, 175.1820]
];

const turakinaDeviationEndAramoho = [
  [-39.9416, 175.1820],
  [-39.9404, 175.1794],
  [-39.9398, 175.1775],
  [-39.9391, 175.1722],
  [-39.9392, 175.1709],
  [-39.9395, 175.1697],
  [-39.9403, 175.1681],
  [-39.9410, 175.1659],
  [-39.9411, 175.1645],
  [-39.9396, 175.1568],
  [-39.9395, 175.1550],
  [-39.9394, 175.1498],
  [-39.9392, 175.1484],
  [-39.9369, 175.1420],
  [-39.9362, 175.1408],
  [-39.9291, 175.1321],
  [-39.9285, 175.1310],
  [-39.9198, 175.1113],
  [-39.9195, 175.1102],
  [-39.9170, 175.0948],
  [-39.9171, 175.0877],
  [-39.9176, 175.0854],
  [-39.9182, 175.0838],
  [-39.9184, 175.0824],
  [-39.9184, 175.0816],
  [-39.9179, 175.0794],
  [-39.9121, 175.0668],
  [-39.9116, 175.0651],
  [-39.9098, 175.0589],
  [-39.9095, 175.0581],
  [-39.9079, 175.0552],
  [-39.9073, 175.0547],
  [-39.9068, 175.0546],
  [-39.9035, 175.0550]
];

const aramohoKaiIwiDeviationStart = [
  [-39.9035, 175.0550],
  [-39.9027, 175.0551],
  [-39.9017, 175.0547],
  [-39.8990, 175.0526],
  [-39.8952, 175.0510],
  [-39.8946, 175.0509],
  [-39.8941, 175.0510],
  [-39.8934, 175.0515],
  [-39.8931, 175.0517],
  [-39.8926, 175.0518],
  [-39.8921, 175.0520],
  [-39.8907, 175.0533],
  [-39.8899, 175.0535],
  [-39.8883, 175.0530],
  [-39.8876, 175.0525],
  [-39.8860, 175.0510],
  [-39.8856, 175.0502],
  [-39.8856, 175.0495],
  [-39.8859, 175.0485],
  [-39.8859, 175.0474],
  [-39.8848, 175.0444],
  [-39.8843, 175.0425],
  [-39.8843, 175.0420],
  [-39.8843, 175.0413],
  [-39.8842, 175.0406],
  [-39.8836, 175.0390],
  [-39.8830, 175.0384],
  [-39.8828, 175.0377],
  [-39.8827, 175.0362],
  [-39.8824, 175.0355],
  [-39.8824, 175.0350],
  [-39.8823, 175.0341],
  [-39.8821, 175.0333],
  [-39.8813, 175.0312],
  [-39.8810, 175.0298],
  [-39.8809, 175.0286],
  [-39.8808, 175.0279],
  [-39.8799, 175.0245],
  [-39.8796, 175.0239],
  [-39.8794, 175.0231],
  [-39.8793, 175.0221],
  [-39.8791, 175.0216],
  [-39.8787, 175.0212],
  [-39.8781, 175.0209],
  [-39.8777, 175.0204],
  [-39.8716, 175.0069],
  [-39.8711, 175.0061],
  [-39.8703, 175.0052],
  [-39.8695, 175.0046],
  [-39.8690, 175.0041],
  [-39.8689, 175.0036],
  [-39.8688, 175.0030],
  [-39.8686, 175.0023],
  [-39.8684, 175.0019],
  [-39.8683, 175.0015],
  [-39.8682, 175.0010],
  [-39.8684, 174.9987],
  [-39.8682, 174.9979],
  [-39.8678, 174.9971],
  [-39.8672, 174.9965],
  [-39.8669, 174.9959],
  [-39.8667, 174.9950],
  [-39.8664, 174.9944],
  [-39.8662, 174.9937],
  [-39.8660, 174.9924],
  [-39.8658, 174.9914],
  [-39.8657, 174.9902],
  [-39.8654, 174.9895],
  [-39.8653, 174.9890],
  [-39.8652, 174.9879],
  [-39.8645, 174.9857],
  [-39.8636, 174.9840],
  [-39.8625, 174.9809],
  [-39.8622, 174.9803]
];

const kaiIwiStartKaiIwiTunnel = [
  [-39.8622, 174.9803],
  [-39.8618, 174.9799],
  [-39.8611, 174.9797],
  [-39.8601, 174.9796]
];

const kaiIwiTunnel = [
  [-39.8601, 174.9796],
  [-39.8584, 174.9801],
  [-39.8581, 174.9800]
];

const kaiIwiTunnelKaiIwiEnd = [
  [-39.8581, 174.9800],
  [-39.8578, 174.9797],
  [-39.8576, 174.9794],
  [-39.8574, 174.9789],
  [-39.8570, 174.9756]
];

const kaiIwiDeviation = [
  [-39.8622, 174.9803],
  [-39.8608, 174.9784],
  [-39.8598, 174.9777],
  [-39.8585, 174.9774],
  [-39.8577, 174.9768],
  [-39.8570, 174.9756]
];

const kaiIwiDeviationEndKaiIwi = [
  [-39.8570, 174.9756],
  [-39.8567, 174.9743],
  [-39.8567, 174.9728],
  [-39.8565, 174.9721],
  [-39.8556, 174.9696],
  [-39.8555, 174.9688],
  [-39.8556, 174.9670],
  [-39.8555, 174.9662],
  [-39.8550, 174.9648],
  [-39.8547, 174.9632],
  [-39.8543, 174.9624],
  [-39.8542, 174.9616],
  [-39.8542, 174.9604],
  [-39.8541, 174.9592],
  [-39.8534, 174.9569],
  [-39.8533, 174.9563],
  [-39.8533, 174.9511],
  [-39.8536, 174.9488],
  [-39.8541, 174.9468],
  [-39.8543, 174.9410],
  [-39.8541, 174.9380],
  [-39.8538, 174.9367],
  [-39.8526, 174.9335],
  [-39.8500, 174.9289],
  [-39.8494, 174.9282],
  [-39.8488, 174.9277],
  [-39.8483, 174.9272]
];

const kaiIwiWaiotara = [
  [-39.8483, 174.9272],
  [-39.8473, 174.9259],
  [-39.8469, 174.9248],
  [-39.8468, 174.9237],
  [-39.8469, 174.9227],
  [-39.8478, 174.9202],
  [-39.8487, 174.9169],
  [-39.8490, 174.9164],
  [-39.8505, 174.9150],
  [-39.8510, 174.9147],
  [-39.8529, 174.9140],
  [-39.8537, 174.9135],
  [-39.8542, 174.9130],
  [-39.8547, 174.9128],
  [-39.8561, 174.9126],
  [-39.8565, 174.9124],
  [-39.8569, 174.9118],
  [-39.8606, 174.9026],
  [-39.8608, 174.9017],
  [-39.8608, 174.9003],
  [-39.8607, 174.8995],
  [-39.8607, 174.8988],
  [-39.8609, 174.8982],
  [-39.8612, 174.8971],
  [-39.8613, 174.8942],
  [-39.8610, 174.8931],
  [-39.8610, 174.8924],
  [-39.8613, 174.8918],
  [-39.8616, 174.8912],
  [-39.8620, 174.8903],
  [-39.8621, 174.8891],
  [-39.8618, 174.8869],
  [-39.8618, 174.8862],
  [-39.8620, 174.8829],
  [-39.8620, 174.8821],
  [-39.8618, 174.8814],
  [-39.8611, 174.8804],
  [-39.8590, 174.8794],
  [-39.8584, 174.8793],
  [-39.8575, 174.8795],
  [-39.8569, 174.8794],
  [-39.8564, 174.8796],
  [-39.8555, 174.8802],
  [-39.8550, 174.8804],
  [-39.8544, 174.8803],
  [-39.8540, 174.8797],
  [-39.8538, 174.8791],
  [-39.8538, 174.8784],
  [-39.8539, 174.8776],
  [-39.8539, 174.8770],
  [-39.8537, 174.8762],
  [-39.8537, 174.8756],
  [-39.8539, 174.8750],
  [-39.8543, 174.8744],
  [-39.8544, 174.8739],
  [-39.8544, 174.8735],
  [-39.8541, 174.8719],
  [-39.8540, 174.8712],
  [-39.8537, 174.8705],
  [-39.8535, 174.8700],
  [-39.8536, 174.8694],
  [-39.8546, 174.8630],
  [-39.8545, 174.8615],
  [-39.8526, 174.8533],
  [-39.8518, 174.8515],
  [-39.8515, 174.8504],
  [-39.8515, 174.8470],
  [-39.8517, 174.8456],
  [-39.8521, 174.8443],
  [-39.8523, 174.8429],
  [-39.8522, 174.8413],
  [-39.8503, 174.8338],
  [-39.8499, 174.8303],
  [-39.8494, 174.8291],
  [-39.8477, 174.8265],
  [-39.8468, 174.8257],
  [-39.8435, 174.8239],
  [-39.8422, 174.8228],
  [-39.8384, 174.8179],
  [-39.8377, 174.8162],
  [-39.8338, 174.7932],
  [-39.8325, 174.7873],
  [-39.8317, 174.7851],
  [-39.8316, 174.7842],
  [-39.8315, 174.7832],
  [-39.8313, 174.7821],
  [-39.8245, 174.7675],
  [-39.8242, 174.7661],
  [-39.8243, 174.7648],
  [-39.8255, 174.7610],
  [-39.8275, 174.7569],
  [-39.8278, 174.7555],
  [-39.8278, 174.7543],
  [-39.8279, 174.7536],
  [-39.8281, 174.7526],
  [-39.8281, 174.7515],
  [-39.8280, 174.7506],
  [-39.8280, 174.7500],
  [-39.8281, 174.7479],
  [-39.8284, 174.7468],
  [-39.8289, 174.7460],
  [-39.8294, 174.7453],
  [-39.8297, 174.7447],
  [-39.8298, 174.7441],
  [-39.8302, 174.7405],
  [-39.8302, 174.7396],
  [-39.8300, 174.7392],
  [-39.8300, 174.7374],
  [-39.8304, 174.7363],
  [-39.8304, 174.7349],
  [-39.8300, 174.7338],
  [-39.8293, 174.7333],
  [-39.8283, 174.7330],
  [-39.8265, 174.7320],
  [-39.8260, 174.7315],
  [-39.8228, 174.7285],
  [-39.8222, 174.7276],
  [-39.8214, 174.7263],
  [-39.8197, 174.7245]
];

const waiotaraWaverley = [
  [-39.8197, 174.7245],
  [-39.8172, 174.7223],
  [-39.8166, 174.7214],
  [-39.8162, 174.7205],
  [-39.8157, 174.7199],
  [-39.8108, 174.7161],
  [-39.8098, 174.7148],
  [-39.8086, 174.7117],
  [-39.8081, 174.7108],
  [-39.8075, 174.7102],
  [-39.8067, 174.7098],
  [-39.8041, 174.7074],
  [-39.8035, 174.7065],
  [-39.8030, 174.7054],
  [-39.8025, 174.7047],
  [-39.8015, 174.7040],
  [-39.8009, 174.7033],
  [-39.8004, 174.7022],
  [-39.7997, 174.7012],
  [-39.7980, 174.6997],
  [-39.7957, 174.6984],
  [-39.7949, 174.6983],
  [-39.7942, 174.6984],
  [-39.7928, 174.6991],
  [-39.7916, 174.6991],
  [-39.7904, 174.6986],
  [-39.7896, 174.6978],
  [-39.7811, 174.6869],
  [-39.7771, 174.6836],
  [-39.7764, 174.6824],
  [-39.7727, 174.6727],
  [-39.7688, 174.6466],
  [-39.7687, 174.6443],
  [-39.7692, 174.6309],
  [-39.7686, 174.6265]
];

const waverleyManutahi = [
  [-39.7686, 174.6265],
  [-39.7682, 174.6241],
  [-39.7682, 174.6226],
  [-39.7686, 174.6190],
  [-39.7702, 174.6090],
  [-39.7722, 174.5936],
  [-39.7740, 174.5868],
  [-39.7742, 174.5852],
  [-39.7737, 174.5576],
  [-39.7742, 174.5337],
  [-39.7738, 174.5311],
  [-39.7725, 174.5282],
  [-39.7725, 174.5268],
  [-39.7728, 174.5258],
  [-39.7735, 174.5249],
  [-39.7739, 174.5240],
  [-39.7739, 174.5232],
  [-39.7730, 174.5197],
  [-39.7721, 174.5180],
  [-39.7707, 174.5166],
  [-39.7699, 174.5164],
  [-39.7689, 174.5166],
  [-39.7680, 174.5163],
  [-39.7673, 174.5152],
  [-39.7672, 174.5138],
  [-39.7675, 174.5128],
  [-39.7681, 174.5119],
  [-39.7685, 174.5111],
  [-39.7725, 174.4977],
  [-39.7725, 174.4965],
  [-39.7722, 174.4934],
  [-39.7718, 174.4924],
  [-39.7711, 174.4918],
  [-39.7696, 174.4913],
  [-39.7687, 174.4909],
  [-39.7682, 174.4904],
  [-39.7667, 174.4890],
  [-39.7661, 174.4887],
  [-39.7655, 174.4886],
  [-39.7649, 174.4885],
  [-39.7638, 174.4877],
  [-39.7619, 174.4858],
  [-39.7612, 174.4855],
  [-39.7605, 174.4854],
  [-39.7587, 174.4859],
  [-39.7581, 174.4859],
  [-39.7567, 174.4854],
  [-39.7531, 174.4829],
  [-39.7524, 174.4822],
  [-39.7501, 174.4793],
  [-39.7454, 174.4762],
  [-39.7446, 174.4759],
  [-39.7437, 174.4760],
  [-39.7398, 174.4769],
  [-39.7389, 174.4769],
  [-39.7380, 174.4763],
  [-39.7353, 174.4738],
  [-39.7342, 174.4733],
  [-39.7297, 174.4738],
  [-39.7288, 174.4733],
  [-39.7281, 174.4721],
  [-39.7280, 174.4710],
  [-39.7276, 174.4700],
  [-39.7244, 174.4658],
  [-39.7235, 174.4652],
  [-39.7222, 174.4647],
  [-39.7213, 174.4646],
  [-39.7182, 174.4649],
  [-39.7167, 174.4646],
  [-39.7154, 174.4638],
  [-39.7124, 174.4617],
  [-39.7116, 174.4609],
  [-39.7102, 174.4588],
  [-39.7095, 174.4580],
  [-39.7071, 174.4561],
  [-39.7063, 174.4551],
  [-39.7029, 174.4479],
  [-39.6987, 174.4429],
  [-39.6958, 174.4406],
  [-39.6801, 174.4238],
  [-39.6783, 174.4207],
  [-39.6724, 174.4153],
  [-39.6708, 174.4146],
  [-39.6686, 174.4146],
  [-39.6671, 174.4142],
  [-39.6607, 174.4111],
  [-39.6592, 174.4094],
  [-39.6588, 174.4082]
];

const manutahiHawera = [
  [-39.6588, 174.4082],
  [-39.6577, 174.4056],
  [-39.6570, 174.4044],
  [-39.6557, 174.4028],
  [-39.6551, 174.4018],
  [-39.6546, 174.4005],
  [-39.6543, 174.3987],
  [-39.6541, 174.3975],
  [-39.6539, 174.3968],
  [-39.6535, 174.3962],
  [-39.6515, 174.3944],
  [-39.6511, 174.3938],
  [-39.6509, 174.3932],
  [-39.6507, 174.3924],
  [-39.6504, 174.3917],
  [-39.6490, 174.3902],
  [-39.6483, 174.3896],
  [-39.6477, 174.3890],
  [-39.6473, 174.3880],
  [-39.6466, 174.3869],
  [-39.6458, 174.3863],
  [-39.6448, 174.3863],
  [-39.6430, 174.3868],
  [-39.6422, 174.3868],
  [-39.6415, 174.3864],
  [-39.6408, 174.3857],
  [-39.6398, 174.3850],
  [-39.6387, 174.3847],
  [-39.6342, 174.3842],
  [-39.6330, 174.3835],
  [-39.6318, 174.3818],
  [-39.6314, 174.3805],
  [-39.6314, 174.3791],
  [-39.6318, 174.3753],
  [-39.6317, 174.3735],
  [-39.6310, 174.3719],
  [-39.6263, 174.3669],
  [-39.6257, 174.3657],
  [-39.6255, 174.3647],
  [-39.6256, 174.3631],
  [-39.6255, 174.3622],
  [-39.6252, 174.3612],
  [-39.6253, 174.3600],
  [-39.6255, 174.3592],
  [-39.6272, 174.3568],
  [-39.6277, 174.3557],
  [-39.6279, 174.3544],
  [-39.6281, 174.3539],
  [-39.6284, 174.3532],
  [-39.6286, 174.3525],
  [-39.6287, 174.3514],
  [-39.6289, 174.3505],
  [-39.6290, 174.3500],
  [-39.6289, 174.3489],
  [-39.6289, 174.3482],
  [-39.6287, 174.3472],
  [-39.6278, 174.3459],
  [-39.6273, 174.3448],
  [-39.6266, 174.3440],
  [-39.6254, 174.3432],
  [-39.6247, 174.3423],
  [-39.6244, 174.3413],
  [-39.6245, 174.3402],
  [-39.6248, 174.3391],
  [-39.6250, 174.3381],
  [-39.6250, 174.3369],
  [-39.6254, 174.3354],
  [-39.6254, 174.3347],
  [-39.6253, 174.3336],
  [-39.6250, 174.3329],
  [-39.6243, 174.3315],
  [-39.6240, 174.3308],
  [-39.6239, 174.3299],
  [-39.6239, 174.3291],
  [-39.6241, 174.3280],
  [-39.6241, 174.3271],
  [-39.6229, 174.3234],
  [-39.6222, 174.3221],
  [-39.6210, 174.3207],
  [-39.6204, 174.3199],
  [-39.6194, 174.3189],
  [-39.6187, 174.3179],
  [-39.6165, 174.3110],
  [-39.6157, 174.3092],
  [-39.6146, 174.3074],
  [-39.6134, 174.3063],
  [-39.6128, 174.3060],
  [-39.6117, 174.3058],
  [-39.6107, 174.3055],
  [-39.6028, 174.2999],
  [-39.6012, 174.2985],
  [-39.5954, 174.2929],
  [-39.5944, 174.2922],
  [-39.5872, 174.2892],
  [-39.5859, 174.2882],
  [-39.5837, 174.2854]
];

const haweraEltham = [
  [-39.5837, 174.2854],
  [-39.5822, 174.2832],
  [-39.5811, 174.2823],
  [-39.5539, 174.2748],
  [-39.5308, 174.2735],
  [-39.5139, 174.2695],
  [-39.4839, 174.2620],
  [-39.4830, 174.2621],
  [-39.4822, 174.2627],
  [-39.4810, 174.2642],
  [-39.4802, 174.2648],
  [-39.4793, 174.2649],
  [-39.4751, 174.2641],
  [-39.4743, 174.2642],
  [-39.4732, 174.2649],
  [-39.4667, 174.2724],
  [-39.4656, 174.2730],
  [-39.4644, 174.2729],
  [-39.4634, 174.2724],
  [-39.4622, 174.2712],
  [-39.4612, 174.2708],
  [-39.4601, 174.2709],
  [-39.4590, 174.2716],
  [-39.4575, 174.2732],
  [-39.4567, 174.2743],
  [-39.4563, 174.2760],
  [-39.4563, 174.2773],
  [-39.4564, 174.2786],
  [-39.4563, 174.2804],
  [-39.4558, 174.2817],
  [-39.4552, 174.2827],
  [-39.4524, 174.2860],
  [-39.4516, 174.2867],
  [-39.4425, 174.2917],
  [-39.4288, 174.2940]
];

const elthamStratford = [
  [-39.4288, 174.2940],
  [-39.4065, 174.2990],
  [-39.4053, 174.2995],
  [-39.4038, 174.3009],
  [-39.4028, 174.3014],
  [-39.3981, 174.3012],
  [-39.3942, 174.2996],
  [-39.3931, 174.2995],
  [-39.3889, 174.2992],
  [-39.3778, 174.2983],
  [-39.3745, 174.2977],
  [-39.3730, 174.2978],
  [-39.3708, 174.2982],
  [-39.3620, 174.2975],
  [-39.3609, 174.2971],
  [-39.3531, 174.2926],
  [-39.3523, 174.2920],
  [-39.3516, 174.2912],
  [-39.3507, 174.2902],
  [-39.3498, 174.2896],
  [-39.3465, 174.2884],

];

const stratfordInglewood = [
  [-39.3465, 174.2884],
  [-39.3430, 174.2869],
  [-39.3333, 174.2829],
  [-39.3321, 174.2821],
  [-39.3314, 174.2817],
  [-39.2932, 174.2665],
  [-39.2858, 174.2636],
  [-39.2847, 174.2633],
  [-39.2839, 174.2632],
  [-39.2816, 174.2628],
  [-39.2803, 174.2623],
  [-39.2738, 174.2580],
  [-39.2730, 174.2576],
  [-39.2669, 174.2562],
  [-39.2644, 174.2551],
  [-39.2613, 174.2534],
  [-39.2594, 174.2518],
  [-39.2581, 174.2511],
  [-39.2561, 174.2509],
  [-39.2552, 174.2506],
  [-39.2535, 174.2498],
  [-39.2528, 174.2496],
  [-39.2498, 174.2497],
  [-39.2489, 174.2496],
  [-39.2475, 174.2489],
  [-39.2443, 174.2484],
  [-39.2434, 174.2480],
  [-39.2402, 174.2465],
  [-39.2359, 174.2438],
  [-39.2188, 174.2367],
  [-39.2097, 174.2331],
  [-39.2038, 174.2307],
  [-39.1811, 174.2197],
  [-39.1730, 174.2142],
  [-39.1717, 174.2136],
  [-39.1661, 174.2118],
  [-39.1650, 174.2113],
  [-39.1615, 174.2089],
  [-39.1608, 174.2086],
  [-39.1576, 174.2080]
];

const inglewoodLeppertonJunction = [
  [-39.1576, 174.2080],
  [-39.1493, 174.2065],
  [-39.1483, 174.2065],
  [-39.1392, 174.2107],
  [-39.1383, 174.2116],
  [-39.1372, 174.2122],
  [-39.1339, 174.2128],
  [-39.1321, 174.2136],
  [-39.1276, 174.2167],
  [-39.1268, 174.2170],
  [-39.1262, 174.2169],
  [-39.1236, 174.2161],
  [-39.1222, 174.2160],
  [-39.1197, 174.2167],
  [-39.1185, 174.2169],
  [-39.1176, 174.2166],
  [-39.1168, 174.2160],
  [-39.1160, 174.2152],
  [-39.1075, 174.2109],
  [-39.1001, 174.2092],
  [-39.0919, 174.2050],
  [-39.0907, 174.2047],
  [-39.0868, 174.2044],
  [-39.0853, 174.2041],
  [-39.0843, 174.2036],
  [-39.0795, 174.1995],
  [-39.0782, 174.1988],
  [-39.0767, 174.1984],
  [-39.0656, 174.1976],
  [-39.0505, 174.1989],
  [-39.0419, 174.1992]
];

const leppertonJunctionNew = [
  [-39.0390, 174.1965],
  [-39.0394, 174.1978],
  [-39.0400, 174.1985],
  [-39.0408, 174.1990],
  [-39.0419, 174.1992]
];

const leppertonJunctionNewPlymouth = [
  [-39.0390, 174.1965],
  [-39.0394, 174.1907],
  [-39.0399, 174.1876],
  [-39.0405, 174.1854],
  [-39.0418, 174.1817],
  [-39.0423, 174.1794],
  [-39.0422, 174.1513],
  [-39.0415, 174.1419],
  [-39.0416, 174.1398],
  [-39.0422, 174.1380],
  [-39.0491, 174.1286],
  [-39.0497, 174.1272],
  [-39.0524, 174.1133],
  [-39.0524, 174.1117],
  [-39.0517, 174.1052],
  [-39.0517, 174.1036],
  [-39.0522, 174.1019],
  [-39.0531, 174.1005],
  [-39.0535, 174.0995],
  [-39.0536, 174.0983],
  [-39.0535, 174.0972],
  [-39.0531, 174.0960],
  [-39.0515, 174.0938],
  [-39.0510, 174.0930],
  [-39.0508, 174.0921],
  [-39.0508, 174.0910],
  [-39.0514, 174.0872],
  [-39.0548, 174.0758],
  [-39.0551, 174.0752],
  [-39.0555, 174.0744],
  [-39.0561, 174.0723],
  [-39.0562, 174.0716],
  [-39.0561, 174.0684],
  [-39.0561, 174.0676],
  [-39.0560, 174.0645],
  [-39.0560, 174.0638],
  [-39.0627, 174.0399],
  [-39.0628, 174.0387],
  [-39.0625, 174.0378],
  [-39.0602, 174.0334],
  [-39.0593, 174.0323],
  [-39.0577, 174.0309]
];

export const martonNewPlymouth: Line = {
  name: 'Marton - New Plymouth',
  state: 'NZ',
  segments: [
    {
      segments: [martonTurakina],
      history: {
        opened: {
          date: '1878-02-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        turakinaTurakinaDeviationStart,
        {
          date: '1947-12-07',
          original: [turakinaOld],
          deviation: [
            turakinaDeviationStartTurakinaTunnel,
            {
              name: 'Turakina Tunnel',
              segment: turakinaTunnel,
              type: 'tunnel'
            },
            turakinaTunnelFordellNo2Tunnel,
            {
              name: 'Fordell No 2 Tunnel',
              segment: fordellNo2Tunnel,
              type: 'tunnel'
            },
            fordellNo2TunnelFordellNo1Tunnel,
            {
              name: 'Fordell No 1 Tunnel',
              segment: fordellNo1Tunnel,
              type: 'tunnel'
            },
            fordellNo1TunnelTurakinaDeviationEnd
          ]
        },
        turakinaDeviationEndAramoho
      ],
      history: {
        opened: {
          date: '1877-05-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        aramohoKaiIwiDeviationStart,
        {
          date: '2008-01-01',
          original: [
            kaiIwiStartKaiIwiTunnel,
            {
              name: 'Kai Iwi Tunnel',
              segment: kaiIwiTunnel,
              type: 'tunnel'
            },
            kaiIwiTunnelKaiIwiEnd
          ],
          deviation: [kaiIwiDeviation]
        },
        kaiIwiDeviationEndKaiIwi
      ],
      history: {
        opened: {
          date: '1879-06-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kaiIwiWaiotara],
      history: {
        opened: {
          date: '1880-09-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waiotaraWaverley],
      history: {
        opened: {
          date: '1881-03-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [waverleyManutahi],
      history: {
        opened: {
          date: '1883-08-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [manutahiHawera],
      history: {
        opened: {
          date: '1885-03-23',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [haweraEltham],
      history: {
        opened: {
          date: '1881-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [elthamStratford],
      history: {
        opened: {
          date: '1881-02-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [stratfordInglewood],
      history: {
        opened: {
          date: '1879-12-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [inglewoodLeppertonJunction],
      history: {
        opened: {
          date: '1877-11-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [leppertonJunctionNew],
      history: {
        opened: {
          date: '1877-11-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [leppertonJunctionNewPlymouth],
      history: {
        opened: {
          date: '1875-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
