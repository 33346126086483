import { Line } from "../../trackTypes";

const northJunction = [
  [-34.8307, 138.5883],
  [-34.8314, 138.5886],
  [-34.8319, 138.5891],
  [-34.8322, 138.5898]
];

const southJunction = [
  [-34.8325, 138.5872],
  [-34.8323, 138.5878],
  [-34.8321, 138.5886],
  [-34.8322, 138.5898]
];

const dryCreekJunctionPooraka = [
  [-34.8322, 138.5898],
  [-34.8355, 138.6090]
];

const poorakaNorthfield = [
  [-34.8355, 138.6090],
  [-34.8382, 138.6223]
];

const northfieldStockade = [
  [-34.8382, 138.6223],
  [-34.8385, 138.6247],
  [-34.8390, 138.6258],
  [-34.8404, 138.6272],
  [-34.8415, 138.6286]
];

export const northfield: Line = {
  name: 'Northfield',
  state: 'SA',
  segments: [
    {
      segments: [northJunction],
      history: {
        opened: {
          date: '1857-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'dual'
        }, {
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southJunction],
      history: {
        opened: {
          date: '1857-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dryCreekJunctionPooraka],
      history: {
        opened: {
          date: '1857-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'dual',
          tracks: 2
        }, {
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [poorakaNorthfield],
      history: {
        opened: {
          date: '1857-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northfieldStockade],
      history: {
        opened: {
          date: '1857-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
