import { Line } from "../../../trackTypes";

const line = [
  [-45.9068, 170.5101],
  [-45.9070, 170.5095],
  [-45.9073, 170.5082],
  [-45.9077, 170.5071],
  [-45.9082, 170.5049],
  [-45.9082, 170.5044],
  [-45.9081, 170.5040],
  [-45.9081, 170.5037],
  [-45.9082, 170.5032],
  [-45.9085, 170.5028],
  [-45.9090, 170.5026],
  [-45.9092, 170.5024]
];

export const oceanBeach: Line = {
  name: 'Ocean Beach',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1963-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
