import { Line } from "../../../trackTypes";

const tunnel1 = [
  [-41.28425, 174.77479],
  [-41.28442, 174.77361]
];

const tunnel1Tunnel2 = [
  [-41.28442, 174.77361],
  [-41.28453, 174.77292]
];

const tunnel2 = [
  [-41.28453, 174.77292],
  [-41.28474, 174.77161]
];

const tunnel2Tunnel3 = [
  [-41.28474, 174.77161],
  [-41.28481, 174.77117]
];

const tunnel3 = [
  [-41.28481, 174.77117],
  [-41.28499, 174.77001]
];

const tunnel3Summit = [
  [-41.28499, 174.77001],
  [-41.28537, 174.76759]
];

export const wellingtonCableCar: Line = {
  name: 'Wellington Cable Car',
  state: 'NZ',
  segments: [
    {
      segments: [
        {
          segment: tunnel1,
          type: 'tunnel'
        },
        tunnel1Tunnel2,
        {
          segment: tunnel2,
          type: 'tunnel'
        },
        tunnel2Tunnel3,
        {
          segment: tunnel3,
          type: 'tunnel'
        },
        tunnel3Summit
      ],
      history: {
        opened: {
          date: '1922-02-22',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'funicular'
        }
      }
    }
  ]
}
