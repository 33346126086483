import { Line } from "../../trackTypes";

const sandyHollowCoxTunnel1 = [
  [-32.3399, 150.5682],
  [-32.3396, 150.5670],
  [-32.3396, 150.5658],
  [-32.3399, 150.5649],
  [-32.3446, 150.5580],
  [-32.3453, 150.5554],
  [-32.3451, 150.5519],
  [-32.3455, 150.5496],
  [-32.3506, 150.5388],
  [-32.3513, 150.5378],
  [-32.3533, 150.5360],
  [-32.3563, 150.5343],
  [-32.3609, 150.5330],
  [-32.3639, 150.5313],
  [-32.3663, 150.5284],
  [-32.3703, 150.5203],
  [-32.3712, 150.5179],
  [-32.3720, 150.5132],
  [-32.3720, 150.5116],
  [-32.3715, 150.5100],
  [-32.3699, 150.5075],
  [-32.3676, 150.5056],
  [-32.3667, 150.5047],
  [-32.3609, 150.4953],
  [-32.3602, 150.4937],
  [-32.3598, 150.4906],
  [-32.3590, 150.4886],
  [-32.3587, 150.4869],
  [-32.3590, 150.4853],
  [-32.3600, 150.4838],
  [-32.3763, 150.4729],
  [-32.3774, 150.4712],
  [-32.3776, 150.4692],
  [-32.3771, 150.4672],
  [-32.3736, 150.4620],
  [-32.3718, 150.4605],
  [-32.3710, 150.4594],
  [-32.3705, 150.4579],
  [-32.3705, 150.4561],
  [-32.3725, 150.4498],
  [-32.3770, 150.4400],
  [-32.3774, 150.4383],
  [-32.3781, 150.4367],
  [-32.3811, 150.4334],
  [-32.3818, 150.4318],
  [-32.3819, 150.4302],
  [-32.3814, 150.4283],
  [-32.3804, 150.4271],
  [-32.3784, 150.4259],
  [-32.3774, 150.4255],
  [-32.3764, 150.4254],
  [-32.3752, 150.4248],
  [-32.3744, 150.4237],
  [-32.3740, 150.4221],
  [-32.3746, 150.4136],
  [-32.3744, 150.4121],
  [-32.3744, 150.4109],
  [-32.3747, 150.4098],
  [-32.3788, 150.4045],
  [-32.3807, 150.4037],
  [-32.3825, 150.4040],
  [-32.3852, 150.4056],
  [-32.3870, 150.4061],
  [-32.3887, 150.4057],
  [-32.3902, 150.4042],
  [-32.3909, 150.4024],
  [-32.3913, 150.3984],
  [-32.3918, 150.3968],
  [-32.3931, 150.3953],
  [-32.3944, 150.3946],
  [-32.3960, 150.3934],
  [-32.3973, 150.3915],
  [-32.3994, 150.3845],
  [-32.4002, 150.3828],
  [-32.4014, 150.3813],
  [-32.4021, 150.3799],
  [-32.4026, 150.3778],
  [-32.4030, 150.3736],
  [-32.4028, 150.3715],
  [-32.4020, 150.3682],
  [-32.4020, 150.3662],
  [-32.4026, 150.3641],
  [-32.4034, 150.3625],
  [-32.4040, 150.3609],
  [-32.4044, 150.3567],
  [-32.4043, 150.3502],
  [-32.4050, 150.3472],
  [-32.4063, 150.3441],
  [-32.4077, 150.3425],
  [-32.4143, 150.3381],
  [-32.4155, 150.3364],
  [-32.4158, 150.3344],
  [-32.4153, 150.3322],
  [-32.4144, 150.3305],
  [-32.4140, 150.3285],
  [-32.4142, 150.3262],
  [-32.4197, 150.3134],
  [-32.4216, 150.3106],
  [-32.4223, 150.3095],
  [-32.4225, 150.3079],
  [-32.4223, 150.3058],
  [-32.4223, 150.3044],
  [-32.4238, 150.2948],
  [-32.4244, 150.2933],
  [-32.4253, 150.2919],
  [-32.4258, 150.2905],
  [-32.4259, 150.2887],
  [-32.4248, 150.2829]
];

const coxGapNo1Tunnel = [
  [-32.4248, 150.2829],
  [-32.4232, 150.2755]
];

const coxTunnel1CoxTunnel2 = [
  [-32.4232, 150.2755],
  [-32.4230, 150.2748],
  [-32.4223, 150.2736],
  [-32.4210, 150.2729],
  [-32.4194, 150.2729],
  [-32.4184, 150.2726],
  [-32.4176, 150.2717],
  [-32.4170, 150.2701],
  [-32.4172, 150.2688],
  [-32.4175, 150.2682]
];

const coxGapNo2Tunnel = [
  [-32.4175, 150.2682],
  [-32.4197, 150.2642]
];

const coxTunnel2BylongTunnel = [
  [-32.4197, 150.2642],
  [-32.4201, 150.2635],
  [-32.4211, 150.2622],
  [-32.4237, 150.2601],
  [-32.4326, 150.2501],
  [-32.4351, 150.2453],
  [-32.4367, 150.2435],
  [-32.4387, 150.2422],
  [-32.4402, 150.2402],
  [-32.4408, 150.2376],
  [-32.4402, 150.2352],
  [-32.4382, 150.2320],
  [-32.4377, 150.2306],
  [-32.4376, 150.2281],
  [-32.4382, 150.2258],
  [-32.4382, 150.2244],
  [-32.4375, 150.2230],
  [-32.4362, 150.2218],
  [-32.4337, 150.2167],
  [-32.4325, 150.2156],
  [-32.4318, 150.2140],
  [-32.4318, 150.2128],
  [-32.4321, 150.2117]
];

const bylongTunnel = [
  [-32.4321, 150.2117],
  [-32.4395, 150.1920]
];

const bylongTunnelWollarGapTunnel = [
  [-32.4395, 150.1920],
  [-32.4407, 150.1890],
  [-32.4431, 150.1854],
  [-32.4439, 150.1835],
  [-32.4451, 150.1768],
  [-32.4461, 150.1746],
  [-32.4467, 150.1734],
  [-32.4470, 150.1715],
  [-32.4479, 150.1699],
  [-32.4490, 150.1685],
  [-32.4495, 150.1673],
  [-32.4503, 150.1648],
  [-32.4526, 150.1611],
  [-32.4530, 150.1596],
  [-32.4529, 150.1576],
  [-32.4520, 150.1557],
  [-32.4503, 150.1547],
  [-32.4432, 150.1533],
  [-32.4414, 150.1524],
  [-32.4387, 150.1501],
  [-32.4372, 150.1483],
  [-32.4342, 150.1426],
  [-32.4267, 150.1336],
  [-32.4242, 150.1318],
  [-32.4224, 150.1301],
  [-32.4207, 150.1266],
  [-32.4194, 150.1254],
  [-32.4178, 150.1246],
  [-32.4079, 150.1241],
  [-32.4058, 150.1248],
  [-32.4001, 150.1288],
  [-32.3934, 150.1315],
  [-32.3876, 150.1325],
  [-32.3853, 150.1326],
  [-32.3788, 150.1319],
  [-32.3752, 150.1320],
  [-32.3730, 150.1312],
  [-32.3659, 150.1235],
  [-32.3637, 150.1196],
  [-32.3631, 150.1179],
  [-32.3621, 150.1138],
  [-32.3622, 150.1121],
  [-32.3626, 150.1104],
  [-32.3627, 150.1086],
  [-32.3619, 150.1063],
  [-32.3544, 150.0979],
  [-32.3482, 150.0885],
  [-32.3474, 150.0854],
  [-32.3448, 150.0592],
  [-32.3444, 150.0573],
  [-32.3427, 150.0518],
  [-32.3425, 150.0501],
  [-32.3425, 150.0473],
  [-32.3420, 150.0456],
  [-32.3408, 150.0439],
  [-32.3353, 150.0398],
  [-32.3345, 150.0387],
  [-32.3340, 150.0373],
  [-32.3340, 150.0356],
  [-32.3345, 150.0340],
  [-32.3357, 150.0325],
  [-32.3363, 150.0314],
  [-32.3366, 150.0299],
  [-32.3369, 150.0260],
  [-32.3375, 150.0245],
  [-32.3388, 150.0233],
  [-32.3405, 150.0224],
  [-32.3417, 150.0215],
  [-32.3430, 150.0198],
  [-32.3457, 150.0133],
  [-32.3463, 150.0122],
  [-32.3476, 150.0110],
  [-32.3484, 150.0099],
  [-32.3489, 150.0086],
  [-32.3498, 150.0070],
  [-32.3536, 150.0031],
  [-32.3547, 150.0025],
  [-32.3566, 150.0025],
  [-32.3582, 150.0032],
  [-32.3596, 150.0035],
  [-32.3640, 150.0022],
  [-32.3661, 150.0008],
  [-32.3703, 149.9959],
  [-32.3713, 149.9953],
  [-32.3723, 149.9953],
  [-32.3738, 149.9958],
  [-32.3751, 149.9960],
  [-32.3763, 149.9952],
  [-32.3770, 149.9934],
  [-32.3765, 149.9915],
  [-32.3760, 149.9907]
];

const wollarGapTunnel = [
  [-32.3760, 149.9907],
  [-32.3737, 149.9877]
];

const wollarGapTunnelUlanJunction = [
  [-32.3737, 149.9877],
  [-32.3700, 149.9831],
  [-32.3666, 149.9796],
  [-32.3630, 149.9773],
  [-32.3602, 149.9745],
  [-32.3462, 149.9520],
  [-32.3452, 149.9510],
  [-32.3437, 149.9503],
  [-32.3416, 149.9501],
  [-32.3390, 149.9493],
  [-32.3370, 149.9470],
  [-32.3329, 149.9367],
  [-32.3307, 149.9336],
  [-32.3204, 149.9244],
  [-32.3192, 149.9221],
  [-32.3189, 149.9186],
  [-32.3245, 149.8926],
  [-32.3271, 149.8852],
  [-32.3276, 149.8820],
  [-32.3273, 149.8764],
  [-32.3265, 149.8736],
  [-32.3246, 149.8707],
  [-32.3180, 149.8645],
  [-32.3166, 149.8628],
  [-32.3114, 149.8533],
  [-32.3093, 149.8507],
  [-32.3008, 149.8432],
  [-32.2993, 149.8414],
  [-32.2957, 149.8354],
  [-32.2951, 149.8335],
  [-32.2948, 149.8318],
  [-32.2943, 149.8304],
  [-32.2933, 149.8292],
  [-32.2917, 149.8282],
  [-32.2903, 149.8267],
  [-32.2895, 149.8244],
  [-32.2892, 149.8176],
  [-32.2881, 149.8145],
  [-32.2858, 149.8121],
  [-32.2695, 149.8022],
  [-32.2678, 149.8007],
  [-32.2666, 149.7984],
  [-32.2661, 149.7953],
  [-32.2672, 149.7858],
  [-32.2700, 149.7761],
  [-32.2716, 149.7718]
];

const ulanJunctionUlanCoalLoader = [
  [-32.2716, 149.7718],
  [-32.2730, 149.7674],
  [-32.2733, 149.7651],
  [-32.2734, 149.7598],
  [-32.2740, 149.7582],
  [-32.2751, 149.7575],
  [-32.2762, 149.7576],
  [-32.2769, 149.7584],
  [-32.2772, 149.7593],
  [-32.2771, 149.7604],
  [-32.2716, 149.7718]
];

const ulanJunctionGulgong = [
  [-32.2771, 149.7604],
  [-32.2882, 149.7375],
  [-32.2919, 149.7308],
  [-32.2927, 149.7267],
  [-32.2922, 149.7227],
  [-32.2891, 149.7148],
  [-32.2883, 149.7116],
  [-32.2865, 149.6820],
  [-32.2868, 149.6793],
  [-32.2876, 149.6771],
  [-32.3376, 149.5904],
  [-32.3383, 149.5879],
  [-32.3385, 149.5860],
  [-32.3382, 149.5813],
  [-32.3383, 149.5799],
  [-32.3388, 149.5785],
  [-32.3587, 149.5492],
  [-32.3591, 149.5476],
  [-32.3589, 149.5461]
];

export const sandyHollowGulgong: Line = {
  name: 'Sandy Hollow - Gulgong',
  state: 'NSW',
  segments: [
    {
      segments: [
        sandyHollowCoxTunnel1,
        {
          name: 'Cox Gap No 1 Tunnel',
          segment: coxGapNo1Tunnel,
          type: 'tunnel'
        },
        coxTunnel1CoxTunnel2,
        {
          name: 'Cox Gap No 2 Tunnel',
          segment: coxGapNo2Tunnel,
          type: 'tunnel'
        },
        coxTunnel2BylongTunnel,
        {
          name: 'Bylong Tunnel',
          segment: bylongTunnel,
          type: 'tunnel'
        },
        bylongTunnelWollarGapTunnel,
        {
          name: 'Wollar Gap Tunnel',
          segment: wollarGapTunnel,
          type: 'tunnel'
        },
        wollarGapTunnelUlanJunction,
        ulanJunctionUlanCoalLoader
      ],
      history: {
        opened: {
          date: '1982-10-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ulanJunctionGulgong],
      history: {
        opened: {
          date: '1985-05-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
