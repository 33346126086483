import { Line } from "../../trackTypes";

const line = [
  [-29.1756, 116.0100],
  [-29.1784, 116.0107],
  [-29.1802, 116.0123],
  [-29.1811, 116.0140],
  [-29.1815, 116.0160],
  [-29.1824, 116.1451],
  [-29.1819, 116.1488],
  [-29.1805, 116.1516],
  [-29.1639, 116.1747],
  [-29.1623, 116.1789],
  [-29.1585, 116.2129],
  [-29.1588, 116.2164],
  [-29.1599, 116.2193],
  [-29.1926, 116.2650],
  [-29.1939, 116.2695],
  [-29.1937, 116.4147],
  [-29.1930, 116.4188],
  [-29.1875, 116.4439],
  [-29.1872, 116.4462],
  [-29.1855, 116.4746],
  [-29.1842, 116.4791],
  [-29.1803, 116.4852],
  [-29.1798, 116.5055],
  [-29.1783, 116.5207],
  [-29.1815, 116.5449],
  [-29.1770, 116.5915],
  [-29.1834, 116.6660],
  [-29.1748, 116.7041],
  [-29.1752, 116.7110],
  [-29.1881, 116.7306],
  [-29.1901, 116.7344],
  [-29.1925, 116.7490],
  [-29.1922, 116.7511],
  [-29.1904, 116.7531],
  [-29.1878, 116.7532],
  [-29.1860, 116.7518],
  [-29.1853, 116.7491],
  [-29.1885, 116.7338],
  [-29.1884, 116.7321],
  [-29.1881, 116.7306]
];

export const karara: Line = {
  name: 'Karara',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2011-03-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
};
