import { Line } from "../../../../trackTypes";

const cathedralSquareRailwayStation = [
  [-43.53993, 172.64253],
  [-43.53992, 172.63988],
  [-43.53993, 172.63683],
  [-43.53988, 172.63671],
  [-43.53980, 172.63666],
  [-43.53190, 172.63660],
];

const heritage = [
  [-43.53190, 172.63660],
  [-43.53165, 172.63660]
];

const loop = [
  [-43.53190, 172.63660],
  [-43.53201, 172.63666],
  [-43.53207, 172.63672],
  [-43.53306, 172.63810],
  [-43.53317, 172.63821], //
  [-43.53414, 172.63955]
];

const manchesterStreet = [
  [-43.53414, 172.63955],
  [-43.53421, 172.63959],
  [-43.53428, 172.63962],
  [-43.53434, 172.63964],
  [-43.53975, 172.63966],
  [-43.53982, 172.63968],
  [-43.53988, 172.63976],
  [-43.53992, 172.63988]
];

const papanuiStation = [
  [-43.53165, 172.63660],
  [-43.52883, 172.63661],
  [-43.52871, 172.63655],
  [-43.52863, 172.63642],
  [-43.52457, 172.63080],
  [-43.52440, 172.63061],
  [-43.52427, 172.63050],
  [-43.51409, 172.62320],
  [-43.51218, 172.62182],
  [-43.51192, 172.62159],
  [-43.50178, 172.61225],
  [-43.49733, 172.60816],
  [-43.49715, 172.60796],
  [-43.49708, 172.60784],
  [-43.49631, 172.60629],
  [-43.49625, 172.60624],
  [-43.49614, 172.60621],
  [-43.49522, 172.60629]
];

const papauniExtension = [
  [-43.49733, 172.60816],
  [-43.49701, 172.60795],
  [-43.49687, 172.60790],
  [-43.49669, 172.60789],
  [-43.49647, 172.60795],
  [-43.49621, 172.60807],
  [-43.49595, 172.60823],
  [-43.48849, 172.61548],
  [-43.48817, 172.61574],
  [-43.48772, 172.61594],
  [-43.48730, 172.61606],
  [-43.48485, 172.61628]
];

const sydenhamPark = [//agricultual show grounds at the time
  [-43.53980, 172.63666],
  [-43.54771, 172.63669],
  [-43.54896, 172.63673]
];

const devonStreet = [
  [-43.54896, 172.63673],
  [-43.55030, 172.63677],
  [-43.55297, 172.63683],
  [-43.55357, 172.63683]
];

const cashmereHills = [
  [-43.55357, 172.63683],
  [-43.56115, 172.63693],
  [-43.56497, 172.63710],
  [-43.56521, 172.63707],
  [-43.56551, 172.63697],
  [-43.56577, 172.63677],
  [-43.56726, 172.63516]
];

const cashmere = [
  [-43.56726, 172.63516],
  [-43.56737, 172.63492],
  [-43.56741, 172.63475],
  [-43.56744, 172.63451],
  [-43.56735, 172.63401],
  [-43.56725, 172.63375],
  [-43.56699, 172.63326],
  [-43.56689, 172.63313],
  [-43.56638, 172.63253],
  [-43.56629, 172.63237],
  [-43.56623, 172.63217],
  [-43.56622, 172.63200],
  [-43.56624, 172.63182],
  [-43.56629, 172.63168],
  [-43.56664, 172.63127],
  [-43.56680, 172.63104],
  [-43.56751, 172.62977],
  [-43.56758, 172.62951],
  [-43.56759, 172.62926],
  [-43.56753, 172.62859],
  [-43.56754, 172.62829],
  [-43.56782, 172.62703],
  [-43.56814, 172.62595],
  [-43.56827, 172.62561],
  [-43.56896, 172.62401],
  [-43.56905, 172.62390],
  [-43.56917, 172.62383],
  [-43.56933, 172.62378],
  [-43.56999, 172.62375],
  [-43.57017, 172.62377],
  [-43.57320, 172.62471],
  [-43.57340, 172.62470],
  [-43.57389, 172.62460],
  [-43.57400, 172.62460],
  [-43.57411, 172.62463],
  [-43.57490, 172.62502],
  [-43.57503, 172.62505],
  [-43.57514, 172.62503],
  [-43.57524, 172.62497],
  [-43.57560, 172.62457],
  [-43.57574, 172.62446],
  [-43.57625, 172.62411],
  [-43.57633, 172.62409],
  [-43.57644, 172.62409],
  [-43.57660, 172.62414],
  [-43.57673, 172.62423],
  [-43.57716, 172.62468],
  [-43.57729, 172.62487],
  [-43.57761, 172.62542],
  [-43.57764, 172.62552],
  [-43.57765, 172.62562],
  [-43.57763, 172.62571],
  [-43.57748, 172.62610],
  [-43.57736, 172.62659],
  [-43.57736, 172.62675],
  [-43.57737, 172.62689],
  [-43.57771, 172.62824],
  [-43.57772, 172.62837],
  [-43.57771, 172.62848],
  [-43.57738, 172.62975],
  [-43.57731, 172.62997],
  [-43.57693, 172.63066],
  [-43.57687, 172.63085],
  [-43.57682, 172.63113],
  [-43.57679, 172.63158],
  [-43.57676, 172.63181],
  [-43.57667, 172.63217],
  [-43.57665, 172.63239],
  [-43.57667, 172.63260],
  [-43.57676, 172.63286],
  [-43.57686, 172.63307],
  [-43.57701, 172.63326],
  [-43.57715, 172.63336],
  [-43.57747, 172.63346],
  [-43.57770, 172.63344],
  [-43.57827, 172.63322],
  [-43.57843, 172.63321],
  [-43.57856, 172.63329],
  [-43.57864, 172.63340],
  [-43.57869, 172.63351],
  [-43.57877, 172.63384],
  [-43.57885, 172.63405],
  [-43.57896, 172.63423],
  [-43.57908, 172.63440],
  [-43.57926, 172.63458],
  [-43.58013, 172.63526],
  [-43.58031, 172.63535],
  [-43.58047, 172.63541],
  [-43.58163, 172.63564]
];

export const line1: Line = {
  name: 'Line 1',
  state: 'NZ',
  segments: [
    {
      segments: [cathedralSquareRailwayStation],
      history: {
        opened: {
          date: '1880-03-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [heritage],
      history: {
        opened: {
          date: '1880-03-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }, {
          date: '2015-02-12',
          status: 'open'
        }]
      }
    },
    {
      segments: [loop],
      history: {
        opened: {
          date: '1880-07-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }, {
          date: '2015-02-12',
          status: 'open'
        }]
      }
    },
    {
      segments: [manchesterStreet],
      history: {
        opened: {
          date: '1880-07-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1893-01-01',
          status: 'closed'
        }, {
          date: '1905-06-06',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [papanuiStation],
      history: {
        opened: {
          date: '1880-06-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-06-06',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [papauniExtension],
      history: {
        opened: {
          date: '1905-06-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [sydenhamPark],
      history: {
        opened: {
          date: '1880-08-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [devonStreet],
      history: {
        opened: {
          date: '1880-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cashmereHills],
      history: {
        opened: {
          date: '1898-12-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1905-08-16',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cashmere],
      history: {
        opened: {
          date: '1912-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-09-10',
          status: 'closed'
        }]
      }
    }
  ]
}
