import { Line } from "../../../trackTypes";

const lumsdenBalfour = [
  [-45.7413, 168.4421],
  [-45.7423, 168.4424],
  [-45.7431, 168.4431],
  [-45.7799, 168.4748],
  [-45.7821, 168.4776],
  [-45.8254, 168.5528],
  [-45.8325, 168.5713],
  [-45.8392, 168.5876]
];

const balfourGore = [
  [-45.8392, 168.5876],
  [-45.8620, 168.6450],
  [-45.8748, 168.6772],
  [-45.8825, 168.7224],
  [-45.8842, 168.7269],
  [-45.8857, 168.7291],
  [-45.8890, 168.7327],
  [-45.9884, 168.8041],
  [-45.9900, 168.8060],
  [-45.9954, 168.8151],
  [-46.0035, 168.8251],
  [-46.0046, 168.8281],
  [-46.0053, 168.8319],
  [-46.0072, 168.8365],
  [-46.0109, 168.8416],
  [-46.0115, 168.8430],
  [-46.0116, 168.8447],
  [-46.0110, 168.8484],
  [-46.0111, 168.8509],
  [-46.0119, 168.8528],
  [-46.0153, 168.8565],
  [-46.0167, 168.8590],
  [-46.0195, 168.8654],
  [-46.0206, 168.8670],
  [-46.0217, 168.8681],
  [-46.0223, 168.8691],
  [-46.0303, 168.8896],
  [-46.0313, 168.8916],
  [-46.0508, 168.9128],
  [-46.0675, 168.9245],
  [-46.0789, 168.9370],
  [-46.0883, 168.9452],
  [-46.0901, 168.9460]
];

const goreInductrialSiding = [
  [-46.0901, 168.9460],
  [-46.0964, 168.9471],
  [-46.0976, 168.9469],
  [-46.0996, 168.9461],
  [-46.1008, 168.9451]
];

export const waimeaPlains: Line = {
  name: 'Waimea Plains',
  state: 'NZ',
  segments: [
    {
      segments: [lumsdenBalfour],
      history: {
        opened: {
          date: '1880-07-021',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balfourGore],
      history: {
        opened: {
          date: '1880-07-021',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1971-04-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goreInductrialSiding],
      history: {
        opened: {
          date: '1880-07-021',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1972-10-01',
          status: 'closed'
        }]
      }
    }
  ]
}
