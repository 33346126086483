import { Line } from "../../trackTypes";

const northamGoomalling = [
  [-31.6496, 116.6928],
  [-31.6486, 116.6955],
  [-31.6477, 116.6969],
  [-31.6444, 116.6999],
  [-31.6432, 116.7003],
  [-31.6418, 116.7002],
  [-31.6313, 116.6958],
  [-31.6299, 116.6946],
  [-31.6247, 116.6878],
  [-31.6226, 116.6867],
  [-31.6202, 116.6867],
  [-31.6184, 116.6880],
  [-31.6174, 116.6895],
  [-31.6159, 116.6903],
  [-31.6069, 116.6922],
  [-31.6057, 116.6921],
  [-31.6049, 116.6916],
  [-31.5984, 116.6822],
  [-31.5972, 116.6814],
  [-31.5956, 116.6809],
  [-31.5940, 116.6796],
  [-31.5923, 116.6769],
  [-31.5916, 116.6740],
  [-31.5911, 116.6727],
  [-31.5900, 116.6710],
  [-31.5895, 116.6694],
  [-31.5875, 116.6625],
  [-31.5851, 116.6596],
  [-31.5756, 116.6541],
  [-31.5713, 116.6541],
  [-31.5606, 116.6576],
  [-31.5582, 116.6591],
  [-31.5560, 116.6615],
  [-31.5494, 116.6675],
  [-31.5470, 116.6685],
  [-31.5431, 116.6712],
  [-31.5422, 116.6724],
  [-31.5415, 116.6743],
  [-31.5407, 116.6755],
  [-31.5298, 116.6855],
  [-31.5275, 116.6868],
  [-31.4875, 116.7277],
  [-31.4847, 116.7284],
  [-31.4682, 116.7268],
  [-31.4497, 116.7199],
  [-31.4481, 116.7200],
  [-31.4452, 116.7211],
  [-31.4426, 116.7211],
  [-31.4407, 116.7220],
  [-31.4157, 116.7420],
  [-31.4137, 116.7429],
  [-31.4121, 116.7442],
  [-31.4102, 116.7472],
  [-31.4021, 116.7653],
  [-31.4004, 116.7675],
  [-31.3615, 116.8005],
  [-31.3600, 116.8011],
  [-31.3581, 116.8013],
  [-31.3563, 116.8025],
  [-31.3551, 116.8045],
  [-31.3533, 116.8062],
  [-31.3508, 116.8068],
  [-31.3479, 116.8064],
  [-31.3450, 116.8071],
  [-31.3425, 116.8087],
  [-31.3364, 116.8104],
  [-31.3354, 116.8109],
  [-31.3335, 116.8125],
  [-31.3277, 116.8152],
  [-31.3266, 116.8154],
  [-31.3249, 116.8151],
  [-31.3236, 116.8152],
  [-31.3197, 116.8168],
  [-31.3186, 116.8170],
  [-31.3174, 116.8165],
  [-31.3159, 116.8150],
  [-31.3147, 116.8146],
  [-31.3132, 116.8150],
  [-31.3087, 116.8192],
  [-31.3069, 116.8196],
  [-31.3040, 116.8186],
  [-31.3027, 116.8188],
  [-31.3015, 116.8197],
  [-31.2978, 116.8270]
];

const goomallingDowerin = [
  [-31.2978, 116.8270],
  [-31.2962, 116.8302],
  [-31.2958, 116.8322],
  [-31.2957, 116.8347],
  [-31.2964, 116.8369],
  [-31.2972, 116.8380],
  [-31.2982, 116.8392],
  [-31.2989, 116.8412],
  [-31.2988, 116.8446],
  [-31.2984, 116.8459],
  [-31.2977, 116.8472],
  [-31.2973, 116.8486],
  [-31.2969, 116.8509],
  [-31.2963, 116.8523],
  [-31.2952, 116.8538],
  [-31.2947, 116.8552],
  [-31.2947, 116.8568],
  [-31.2953, 116.8593],
  [-31.2953, 116.8609],
  [-31.2944, 116.8664],
  [-31.2943, 116.8682],
  [-31.2946, 116.8765],
  [-31.2940, 116.8800],
  [-31.2932, 116.8816],
  [-31.2922, 116.8827],
  [-31.2842, 116.8882],
  [-31.2832, 116.8893],
  [-31.2827, 116.8906],
  [-31.2824, 116.8924],
  [-31.2814, 116.8942],
  [-31.2801, 116.8951],
  [-31.2702, 116.8981],
  [-31.2689, 116.8992],
  [-31.2681, 116.9008],
  [-31.2679, 116.9022],
  [-31.2681, 116.9040],
  [-31.2679, 116.9056],
  [-31.2661, 116.9102],
  [-31.2654, 116.9111],
  [-31.2635, 116.9131],
  [-31.2614, 116.9168],
  [-31.2603, 116.9181],
  [-31.2590, 116.9192],
  [-31.2579, 116.9205],
  [-31.2531, 116.9303],
  [-31.2520, 116.9317],
  [-31.2483, 116.9351],
  [-31.2431, 116.9407],
  [-31.2424, 116.9418],
  [-31.2207, 116.9852],
  [-31.2160, 117.0011],
  [-31.2153, 117.0026],
  [-31.2023, 117.0216],
  [-31.2010, 117.0228],
  [-31.1963, 117.0296],
  [-31.1955, 117.0302],
  [-31.1947, 117.0304],
  [-31.1878, 117.0304]
];

const dowerinDeviationStart = [
  [-31.1878, 117.0304],
  [-31.1815, 117.0304],
  [-31.1799, 117.0310],
  [-31.1769, 117.0330],
  [-31.1758, 117.0341],
  [-31.1689, 117.0454],
  [-31.1667, 117.0525],
  [-31.1660, 117.0574],
  [-31.1640, 117.0622]
];

const dowerinOld = [
  [-31.1640, 117.0622],
  [-31.1629, 117.0641],
  [-31.1560, 117.0736],
  [-31.1542, 117.0780],
  [-31.1536, 117.0819],
  [-31.1523, 117.0847]
];

const dowerinDeviation = [
  [-31.1640, 117.0622],
  [-31.1634, 117.0628],
  [-31.1587, 117.0663],
  [-31.1573, 117.0668],
  [-31.1555, 117.0666],
  [-31.1515, 117.0650],
  [-31.1494, 117.0652],
  [-31.1476, 117.0668],
  [-31.1468, 117.0695],
  [-31.1472, 117.0717],
  [-31.1522, 117.0820],
  [-31.1525, 117.0833],
  [-31.1523, 117.0847]
];

const dowerinDeviationEndWyalkatchem = [
  [-31.1523, 117.0847],
  [-31.1456, 117.0959],
  [-31.1440, 117.0978],
  [-31.1435, 117.0988],
  [-31.1430, 117.1008],
  [-31.1422, 117.1022],
  [-31.1405, 117.1036],
  [-31.1395, 117.1057],
  [-31.1390, 117.1082],
  [-31.1325, 117.1267],
  [-31.1325, 117.1281],
  [-31.1331, 117.1321],
  [-31.1340, 117.1334],
  [-31.1355, 117.1346],
  [-31.1363, 117.1361],
  [-31.1366, 117.1382],
  [-31.1348, 117.1489],
  [-31.1303, 117.1626],
  [-31.1299, 117.1697],
  [-31.1301, 117.1709],
  [-31.1306, 117.1718],
  [-31.1358, 117.1782],
  [-31.1365, 117.1796],
  [-31.1374, 117.1865],
  [-31.1371, 117.1881],
  [-31.1346, 117.1930],
  [-31.1343, 117.1941],
  [-31.1333, 117.1997],
  [-31.1334, 117.2007],
  [-31.1338, 117.2019],
  [-31.1372, 117.2065],
  [-31.1377, 117.2079],
  [-31.1379, 117.2094],
  [-31.1358, 117.2410],
  [-31.1361, 117.2431],
  [-31.1369, 117.2447],
  [-31.1434, 117.2525],
  [-31.1440, 117.2542],
  [-31.1443, 117.2593],
  [-31.1437, 117.2634],
  [-31.1431, 117.2654],
  [-31.1398, 117.2726],
  [-31.1396, 117.2757],
  [-31.1429, 117.2964],
  [-31.1434, 117.2976],
  [-31.1441, 117.2985],
  [-31.1502, 117.3027],
  [-31.1512, 117.3031],
  [-31.1530, 117.3031],
  [-31.1544, 117.3039],
  [-31.1565, 117.3066],
  [-31.1571, 117.3080],
  [-31.1586, 117.3134],
  [-31.1587, 117.3150],
  [-31.1580, 117.3167],
  [-31.1542, 117.3218],
  [-31.1537, 117.3231],
  [-31.1537, 117.3245],
  [-31.1543, 117.3288],
  [-31.1550, 117.3301],
  [-31.1560, 117.3309],
  [-31.1589, 117.3316],
  [-31.1601, 117.3322],
  [-31.1635, 117.3344],
  [-31.1650, 117.3356],
  [-31.1668, 117.3379],
  [-31.1672, 117.3400],
  [-31.1670, 117.3420],
  [-31.1675, 117.3468],
  [-31.1680, 117.3484],
  [-31.1691, 117.3507],
  [-31.1698, 117.3533],
  [-31.1697, 117.3560],
  [-31.1686, 117.3610],
  [-31.1687, 117.3626],
  [-31.1692, 117.3651],
  [-31.1787, 117.3769],
  [-31.1805, 117.3801]
];

const wyalkatchemKorrelocking = [
  [-31.1805, 117.3801],
  [-31.1821, 117.3828],
  [-31.1844, 117.3869],
  [-31.1856, 117.3881],
  [-31.1882, 117.3889],
  [-31.1902, 117.3907],
  [-31.1999, 117.4093],
  [-31.2006, 117.4124],
  [-31.2022, 117.4149],
  [-31.2043, 117.4159],
  [-31.2073, 117.4157],
  [-31.2088, 117.4168],
  [-31.2095, 117.4191],
  [-31.2092, 117.4414],
  [-31.2077, 117.4444],
  [-31.2059, 117.4460],
  [-31.2046, 117.4477],
  [-31.1997, 117.4615],
  [-31.1994, 117.4637],
  [-31.1991, 117.4773]
];

const korrelockingTrayning = [
  [-31.1991, 117.4773],
  [-31.1992, 117.4819],
  [-31.1986, 117.4839],
  [-31.1935, 117.4970],
  [-31.1931, 117.4994],
  [-31.1955, 117.5159],
  [-31.1955, 117.5195],
  [-31.1960, 117.5213],
  [-31.1969, 117.5231],
  [-31.1971, 117.5248],
  [-31.1962, 117.5269],
  [-31.1958, 117.5286],
  [-31.1958, 117.5333],
  [-31.1967, 117.5370],
  [-31.1979, 117.5390],
  [-31.1982, 117.5417],
  [-31.1926, 117.5648],
  [-31.1923, 117.5667],
  [-31.1924, 117.5843],
  [-31.1864, 117.6055],
  [-31.1855, 117.6171],
  [-31.1857, 117.6195],
  [-31.1872, 117.6249],
  [-31.1871, 117.6274],
  [-31.1833, 117.6340],
  [-31.1798, 117.6380],
  [-31.1791, 117.6399],
  [-31.1790, 117.6449],
  [-31.1782, 117.6470],
  [-31.1627, 117.6710],
  [-31.1554, 117.6920],
  [-31.1546, 117.6934],
  [-31.1372, 117.7147],
  [-31.1362, 117.7162],
  [-31.1201, 117.7599],
  [-31.1137, 117.7865],
  [-31.1136, 117.7880],
  [-31.1135, 117.7983]
];

const trayningKununoppin = [
  [-31.1135, 117.7983],
  [-31.1135, 117.9223]
];

const kununoppinMerredin = [
  [-31.1135, 117.9223],
  [-31.1135, 117.9505],
  [-31.1140, 117.9531],
  [-31.1173, 117.9599],
  [-31.1177, 117.9623],
  [-31.1198, 117.9700],
  [-31.1195, 117.9734],
  [-31.1181, 117.9773],
  [-31.1181, 117.9790],
  [-31.1188, 117.9804],
  [-31.1240, 117.9849],
  [-31.1256, 117.9858],
  [-31.1281, 117.9862],
  [-31.1294, 117.9871],
  [-31.1311, 117.9896],
  [-31.1320, 117.9904],
  [-31.1334, 117.9909],
  [-31.1346, 117.9920],
  [-31.1362, 117.9968],
  [-31.1375, 117.9991],
  [-31.1390, 118.0004],
  [-31.1417, 118.0020],
  [-31.1430, 118.0021],
  [-31.1444, 118.0016],
  [-31.1457, 118.0016],
  [-31.1468, 118.0024],
  [-31.1476, 118.0039],
  [-31.1477, 118.0061],
  [-31.1484, 118.0091],
  [-31.1491, 118.0147],
  [-31.1714, 118.0862],
  [-31.1725, 118.0882],
  [-31.1858, 118.1041],
  [-31.1867, 118.1050],
  [-31.2004, 118.1135],
  [-31.2021, 118.1155],
  [-31.2061, 118.1275],
  [-31.2074, 118.1292],
  [-31.2415, 118.1508],
  [-31.2429, 118.1526],
  [-31.2451, 118.1608],
  [-31.2457, 118.1617],
  [-31.2464, 118.1622],
  [-31.2480, 118.1628],
  [-31.2489, 118.1636],
  [-31.2519, 118.1694],
  [-31.2529, 118.1703],
  [-31.2575, 118.1721],
  [-31.2586, 118.1731],
  [-31.2606, 118.1763],
  [-31.2611, 118.1780],
  [-31.2622, 118.1793],
  [-31.2644, 118.1801],
  [-31.2664, 118.1816],
  [-31.2717, 118.1904],
  [-31.2736, 118.1919],
  [-31.2825, 118.1938],
  [-31.3064, 118.2111],
  [-31.3079, 118.2118],
  [-31.3734, 118.2321],
  [-31.3753, 118.2333],
  [-31.3910, 118.2532],
  [-31.3971, 118.2580],
  [-31.4001, 118.2586],
  [-31.4042, 118.2579],
  [-31.4255, 118.2592],
  [-31.4274, 118.2598],
  [-31.4333, 118.2642],
  [-31.4366, 118.2655],
  [-31.4429, 118.2718],
  [-31.4494, 118.2758],
  [-31.4518, 118.2761],
  [-31.4612, 118.2719],
  [-31.4631, 118.2715],
  [-31.4705, 118.2715],
  [-31.4721, 118.2710],
  [-31.4800, 118.2639],
  [-31.4817, 118.2612]
];

const merredinJunction = [
  [-31.4705, 118.2715],
  [-31.4798, 118.2714],
  [-31.4809, 118.2718],
  [-31.4818, 118.2726],
  [-31.4824, 118.2735]
];

export const northamMerredin: Line = {
  name: 'Northam - Merredin',
  state: 'WA',
  segments: [
    {
      segments: [northamGoomalling],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [goomallingDowerin],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        dowerinDeviationStart,
        {
          date: '1993-05-01',
          original: [dowerinOld],
          deviation: [dowerinDeviation]
        },
        dowerinDeviationEndWyalkatchem
      ],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wyalkatchemKorrelocking],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [korrelockingTrayning],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [trayningKununoppin],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-10-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kununoppinMerredin, merredinJunction],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2013-10-01',
          status: 'closed'
        }]
      }
    }
  ]
};
