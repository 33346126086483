import { Line } from "../../../trackTypes";

const argentStreet = [
  [-31.95707, 141.46755],
  [-31.96293, 141.45796]
];

const blendeStreet = [
  [-31.96293, 141.45796],
  [-31.96295, 141.45786],
  [-31.96292, 141.45780],
  [-31.96288, 141.45771],
  [-31.96214, 141.45705],
  [-31.96212, 141.45700],
  [-31.96210, 141.45694],
  [-31.96214, 141.45683],
  [-31.96394, 141.45380],
  [-31.96402, 141.45362],
  [-31.96411, 141.45336],
  [-31.96434, 141.45187],
  [-31.96445, 141.45147],
  [-31.96458, 141.45111]
];

const blendeStreetHillside = [
  [-31.96458, 141.45111],
  [-31.96655, 141.44786],
  [-31.96672, 141.44759],
  [-31.96775, 141.44591],
  [-31.96782, 141.44585],
  [-31.96790, 141.44585],
  [-31.96800, 141.44589],
  [-31.97317, 141.45025],
  [-31.97532, 141.45218]
];

const hillsideOriginal = [
  [-31.97532, 141.45218],
  [-31.97613, 141.45279]
];

const hillsideDeviation = [
  [-31.97532, 141.45218],
  [-31.97551, 141.45239],
  [-31.97558, 141.45256],
  [-31.97566, 141.45266],
  [-31.97574, 141.45271],
  [-31.97592, 141.45275],
  [-31.97613, 141.45279]
];

const hillSidePattonStreet = [
  [-31.97613, 141.45279],
  [-31.97644, 141.45298],
  [-31.97665, 141.45315],
  [-31.97695, 141.45350],
  [-31.97715, 141.45387],
  [-31.97771, 141.45526],
  [-31.97785, 141.45552],
  [-31.97802, 141.45577],
  [-31.98122, 141.45899],
  [-31.98126, 141.45910],
  [-31.98126, 141.45918],
  [-31.98123, 141.45932],
  [-31.97871, 141.46274]
];

const pattonStreetWhittakerStreet = [
  [-31.97871, 141.46274],
  [-31.97599, 141.46641],
  [-31.97594, 141.46643],
  [-31.97589, 141.46643],
  [-31.97580, 141.46641],
  [-31.97322, 141.46379],
  [-31.97313, 141.46379],
  [-31.97308, 141.46380],
  [-31.97302, 141.46383],
  [-31.97111, 141.46643]
];

const southBrokenHillRacecourse = [
  [-31.98122, 141.45899],
  [-31.98498, 141.46282],
  [-31.98506, 141.46284],
  [-31.98514, 141.46282],
  [-31.98521, 141.46278],
  [-31.98980, 141.45664]
];

const argentStreetMurtonStreet = [
  [-31.95707, 141.46755],
  [-31.95693, 141.46764],
  [-31.95683, 141.46762],
  [-31.94977, 141.46171],
  [-31.94967, 141.46172],
  [-31.94962, 141.46173],
  [-31.94954, 141.46175],
  [-31.94179, 141.47444]
];

const chappleStreetKaolinStreet = [
  [-31.94977, 141.46171],
  [-31.94632, 141.45881],
  [-31.94629, 141.45872],
  [-31.94629, 141.45862],
  [-31.94632, 141.45847],
  [-31.95094, 141.45089]
];

const kaolinStreetWesternOval = [
  [-31.95094, 141.45089],
  [-31.95101, 141.45086],
  [-31.95108, 141.45085],
  [-31.95115, 141.45084],
  [-31.95251, 141.45207],
  [-31.95270, 141.45207],
  [-31.95279, 141.45201],
  [-31.95292, 141.45194],
  [-31.95585, 141.44717]
];

const westernOvalWaterSiding = [
  [-31.95585, 141.44717],
  [-31.95752, 141.44444]
];

const waterSidingBismuthStreet = [
  [-31.95752, 141.44444],
  [-31.96040, 141.43973],
  [-31.96050, 141.43968],
  [-31.96055, 141.43968],
  [-31.96063, 141.43971],
  [-31.96472, 141.44314],
  [-31.96475, 141.44325],
  [-31.96473, 141.44337],
  [-31.96371, 141.44505],
  [-31.96369, 141.44519],
  [-31.96372, 141.44531],
  [-31.96651, 141.44764],
  [-31.96654, 141.44772],
  [-31.96655, 141.44786]
];

const bismuthTriange = [
  [-31.96651, 141.44764],
  [-31.96662, 141.44765],
  [-31.96672, 141.44759]
];

const cityCornerTriangeArgentStreet = [
  [-31.95707, 141.46755],
  [-31.95685, 141.46792],
  [-31.95627, 141.46887]
];

const cityCornerJabezStreet = [
  [-31.95627, 141.46887],
  [-31.95584, 141.46957],
  [-31.95258, 141.47499],
  [-31.95248, 141.47509],
  [-31.95238, 141.47516],
  [-31.95109, 141.47506],
  [-31.95099, 141.47512],
  [-31.95085, 141.47524],
  [-31.94792, 141.48005]
];

const eastBrownHillSiding  = [
  [-31.95109, 141.47506],
  [-31.94984, 141.47499]
];

const cityCornerTriangleOxideStreet = [
  [-31.95683, 141.46762],
  [-31.95687, 141.46774],
  [-31.95685, 141.46792]
];

const crystalStreetStation = [
  [-31.95584, 141.46957],
  [-31.95583, 141.46968],
  [-31.95585, 141.46979],
  [-31.95592, 141.46989],
  [-31.95702, 141.47082],
  [-31.95708, 141.47096],
  [-31.95704, 141.47108]
];

export const brokenHillTrams: Line = {
  name: 'Broken Hill (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [argentStreet],
      history: {
        opened: {
          date: '1902-02-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1909-07-10',
          tracks: 2
        }, {
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [blendeStreet],
      history: {
        opened: {
          date: '1902-02-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-07-05',
          tracks: 2
        }, {
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        blendeStreetHillside,
        {
          date: '1903-01-04',
          original: [hillsideOriginal],
          deviation: [hillsideDeviation]
        },
        hillSidePattonStreet
      ],
      history: {
        opened: {
          date: '1902-02-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1911-07-05',
          tracks: 2
        }, {
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [argentStreetMurtonStreet],
      history: {
        opened: {
          date: '1902-12-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pattonStreetWhittakerStreet],
      history: {
        opened: {
          date: '1902-12-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southBrokenHillRacecourse],
      history: {
        opened: {
          date: '1912-12-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [chappleStreetKaolinStreet],
      history: {
        opened: {
          date: '1903-05-06',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kaolinStreetWesternOval],
      history: {
        opened: {
          date: '1907-09-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westernOvalWaterSiding],
      history: {
        opened: {
          date: '1908-07-14',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityCornerTriangeArgentStreet, cityCornerTriangleOxideStreet],
      history: {
        opened: {
          date: '1907-12-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cityCornerJabezStreet, eastBrownHillSiding],
      history: {
        opened: {
          date: '1909-08-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [waterSidingBismuthStreet, bismuthTriange],
      history: {
        opened: {
          date: '1909-08-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [crystalStreetStation],
      history: {
        opened: {
          date: '1919-02-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1926-12-31',
          status: 'closed'
        }]
      }
    }
  ]
};
