import { Line } from '../../trackTypes';

const line = [
  [-37.9277, 144.5883],
  [-37.9259, 144.5913],
  [-37.9244, 144.5933],
  [-37.9226, 144.5951],
  [-37.9211, 144.5964],
  [-37.9191, 144.5976],
  [-37.9161, 144.5988],
  [-37.8982, 144.6022],
  [-37.8817, 144.6073],
  [-37.8768, 144.6076],
  [-37.8720, 144.6084],
  [-37.8679, 144.6090],
  [-37.8634, 144.6085],
  [-37.8589, 144.6075],
  [-37.8569, 144.6073],
  [-37.8550, 144.6074],
  [-37.8529, 144.6078],
  [-37.8511, 144.6083],
  [-37.8485, 144.6096],
  [-37.8459, 144.6116],
  [-37.8430, 144.6149],
  [-37.8419, 144.6166],
  [-37.8287, 144.6413],
  [-37.8277, 144.6437],
  [-37.8270, 144.6460],
  [-37.8264, 144.6486],
  [-37.8261, 144.6523],
  [-37.8262, 144.6561],
  [-37.8309, 144.6859],
  [-37.8321, 144.6943],
  [-37.8329, 144.7005],
  [-37.8330, 144.7035],
  [-37.8330, 144.7057],
  [-37.8326, 144.7089],
  [-37.8318, 144.7120],
  [-37.8313, 144.7134],
  [-37.8301, 144.7162],
  [-37.8282, 144.7192],
  [-37.8264, 144.7213],
  [-37.8249, 144.7227],
  [-37.8229, 144.7242],
  [-37.8216, 144.7249],
  [-37.8078, 144.7323],
  [-37.8057, 144.7331],
  [-37.8045, 144.7334],
  [-37.8032, 144.7337],
  [-37.7830, 144.7366],
  [-37.7816, 144.7369],
  [-37.7805, 144.7373],
  [-37.7792, 144.7380],
  [-37.7780, 144.7388],
  [-37.7768, 144.7399],
  [-37.7756, 144.7413],
  [-37.7745, 144.7431],
  [-37.7736, 144.7450],
  [-37.7728, 144.7478],
  [-37.7723, 144.7499],
  [-37.7722, 144.7516],
  [-37.7724, 144.7532]
];

export const RRL: Line = {
  name: 'Regional Rail Link',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2015-06-21',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    }
  ]
};
