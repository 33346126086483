import { Line } from "../../trackTypes";

const bowellingBokal = [
  [-33.4216, 116.4914],
  [-33.4222, 116.4935],
  [-33.4239, 116.4959],
  [-33.4301, 116.5002],
  [-33.4314, 116.5015],
  [-33.4348, 116.5064],
  [-33.4365, 116.5083],
  [-33.4422, 116.5122],
  [-33.4434, 116.5137],
  [-33.4466, 116.5192],
  [-33.4473, 116.5210],
  [-33.4541, 116.5495],
  [-33.4543, 116.5518],
  [-33.4540, 116.5581],
  [-33.4541, 116.5603],
  [-33.4620, 116.6007],
  [-33.4635, 116.6035],
  [-33.4731, 116.6115],
  [-33.4743, 116.6117],
  [-33.4761, 116.6121],
  [-33.4774, 116.6134],
  [-33.4783, 116.6154],
  [-33.4791, 116.6164],
  [-33.4805, 116.6167],
  [-33.4821, 116.6168],
  [-33.4835, 116.6177],
  [-33.4853, 116.6211],
  [-33.4855, 116.6234],
  [-33.4854, 116.6280],
  [-33.4861, 116.6302],
  [-33.4875, 116.6325],
  [-33.4882, 116.6362],
  [-33.4888, 116.6376],
  [-33.4909, 116.6399],
  [-33.4934, 116.6410],
  [-33.4946, 116.6422],
  [-33.4952, 116.6443],
  [-33.4957, 116.6516],
  [-33.4996, 116.6642],
  [-33.5039, 116.6731],
  [-33.5055, 116.6750],
  [-33.5106, 116.6784],
  [-33.5122, 116.6806],
  [-33.5128, 116.6830],
  [-33.5126, 116.6878],
  [-33.5129, 116.6898],
  [-33.5138, 116.6913],
  [-33.5200, 116.6976],
  [-33.5215, 116.7003],
  [-33.5237, 116.7015],
  [-33.5255, 116.7015],
  [-33.5291, 116.7004],
  [-33.5317, 116.7014],
  [-33.5328, 116.7038],
  [-33.5324, 116.7066],
  [-33.5328, 116.7091],
  [-33.5339, 116.7118],
  [-33.5341, 116.7141],
  [-33.5333, 116.7166],
  [-33.5336, 116.7191],
  [-33.5353, 116.7212],
  [-33.5356, 116.7244],
  [-33.5339, 116.7262],
  [-33.5312, 116.7268],
  [-33.5294, 116.7281],
  [-33.5277, 116.7319],
  [-33.5252, 116.7347],
  [-33.5225, 116.7390],
  [-33.5218, 116.7420],
  [-33.5221, 116.7733],
  [-33.5216, 116.7759],
  [-33.5186, 116.7834],
  [-33.5183, 116.7857],
  [-33.5186, 116.7878],
  [-33.5200, 116.7926],
  [-33.5199, 116.7956],
  [-33.5059, 116.8260],
  [-33.4967, 116.8342],
  [-33.4914, 116.8434],
  [-33.4908, 116.8463],
  [-33.4916, 116.8657],
  [-33.4913, 116.8687],
  [-33.4874, 116.8823],
  [-33.4866, 116.8888],
  [-33.4840, 116.9019]
];

const bokalWagin = [
  [-33.4840, 116.9019],
  [-33.4833, 116.9042],
  [-33.4791, 116.9126],
  [-33.4783, 116.9149],
  [-33.4704, 116.9431],
  [-33.4690, 116.9599],
  [-33.4662, 116.9729],
  [-33.4645, 116.9759],
  [-33.4532, 116.9822],
  [-33.4501, 116.9852],
  [-33.4293, 116.9962],
  [-33.4266, 116.9994],
  [-33.4259, 117.0012],
  [-33.4250, 117.0136],
  [-33.4239, 117.0159],
  [-33.4224, 117.0170],
  [-33.4195, 117.0179],
  [-33.4156, 117.0213],
  [-33.4148, 117.0230],
  [-33.4146, 117.0260],
  [-33.4153, 117.0281],
  [-33.4183, 117.0329],
  [-33.4187, 117.0348],
  [-33.4179, 117.0371],
  [-33.4130, 117.0433],
  [-33.4121, 117.0454],
  [-33.4119, 117.0513],
  [-33.4127, 117.0534],
  [-33.4233, 117.0627],
  [-33.4241, 117.0644],
  [-33.4238, 117.0666],
  [-33.4226, 117.0679],
  [-33.4209, 117.0686],
  [-33.4193, 117.0706],
  [-33.4176, 117.0719],
  [-33.4167, 117.0735],
  [-33.4163, 117.0759],
  [-33.4151, 117.0785],
  [-33.4148, 117.0807],
  [-33.4154, 117.0830],
  [-33.4151, 117.0859],
  [-33.4134, 117.0878],
  [-33.4115, 117.0885],
  [-33.4100, 117.0901],
  [-33.4094, 117.0925],
  [-33.4095, 117.0949],
  [-33.4086, 117.0978],
  [-33.4013, 117.1070],
  [-33.4005, 117.1091],
  [-33.4007, 117.1111],
  [-33.4025, 117.1158],
  [-33.4025, 117.1179],
  [-33.4005, 117.1255],
  [-33.3992, 117.1271],
  [-33.3975, 117.1277],
  [-33.3961, 117.1276],
  [-33.3944, 117.1284],
  [-33.3931, 117.1297],
  [-33.3921, 117.1304],
  [-33.3893, 117.1314],
  [-33.3879, 117.1327],
  [-33.3859, 117.1390],
  [-33.3857, 117.1412],
  [-33.3866, 117.1466],
  [-33.3863, 117.1534],
  [-33.3852, 117.1557],
  [-33.3837, 117.1568],
  [-33.3775, 117.1582],
  [-33.3758, 117.1596],
  [-33.3751, 117.1617],
  [-33.3747, 117.1723],
  [-33.3734, 117.1760],
  [-33.3699, 117.1790],
  [-33.3687, 117.1806],
  [-33.3663, 117.1842],
  [-33.3534, 117.1948],
  [-33.3526, 117.1978],
  [-33.3533, 117.2000],
  [-33.3549, 117.2014],
  [-33.3559, 117.2033],
  [-33.3549, 117.2097],
  [-33.3551, 117.2119],
  [-33.3598, 117.2287],
  [-33.3613, 117.2324],
  [-33.3720, 117.2491],
  [-33.3737, 117.2525],
  [-33.3767, 117.2637],
  [-33.3760, 117.2691],
  [-33.3735, 117.2739],
  [-33.3455, 117.3013],
  [-33.3439, 117.3038],
  [-33.3388, 117.3190],
  [-33.3362, 117.3215],
  [-33.3335, 117.3219],
  [-33.3272, 117.3245],
  [-33.3234, 117.3256],
  [-33.3224, 117.3267],
  [-33.3219, 117.3283],
  [-33.3219, 117.3364],
  [-33.3215, 117.3387],
  [-33.3183, 117.3473],
  [-33.3170, 117.3484],
  [-33.3153, 117.3487]
];

export const bowellingWagin: Line = {
  name: 'Bowelling - Wagin',
  state: 'WA',
  segments: [
    {
      segments: [bowellingBokal],
      history: {
        opened: {
          date: '1918-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bokalWagin],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
