import { Line } from "../../../trackTypes";

const roslyn = [
  [-45.87742, 170.50146],
  [-45.87710, 170.50027],
  [-45.87702, 170.50011],
  [-45.87693, 170.49998],
  [-45.87674, 170.49986],
  [-45.87583, 170.49936],
  [-45.87502, 170.49880],
  [-45.87491, 170.49866],
  [-45.87484, 170.49845],
  [-45.87466, 170.49782],
  [-45.87362, 170.49409],
  [-45.87286, 170.49112],
  [-45.87189, 170.48689],
  [-45.87075, 170.48170],
  [-45.87057, 170.48132],
  [-45.87035, 170.48125],
  [-45.87009, 170.48122],
  [-45.86817, 170.48151]
];

const stuartSt = [
  [-45.87393, 170.50290],
  [-45.87276, 170.49870],
  [-45.87268, 170.49853],
  [-45.87259, 170.49842],
  [-45.87044, 170.49707],
  [-45.87010, 170.49680],
  [-45.86977, 170.49648],
  [-45.86945, 170.49609],
  [-45.86914, 170.49563],
  [-45.86866, 170.49478],
  [-45.86662, 170.49091],
  [-45.86649, 170.49063],
  [-45.86642, 170.49035],
  [-45.86616, 170.48890],
  [-45.86605, 170.48816],
  [-45.86592, 170.48700],
  [-45.86582, 170.48655],
  [-45.86574, 170.48631],
  [-45.86559, 170.48598],
  [-45.86517, 170.48524]
];

const mornington = [
  [-45.87839, 170.49986],
  [-45.88253, 170.48625],
  [-45.88259, 170.48594],
  [-45.88260, 170.48568],
  [-45.88256, 170.48536],
  [-45.88247, 170.48492],
  [-45.88223, 170.48406],
  [-45.88184, 170.48259]
];

const maryhillExtension = [
  [-45.88194, 170.48232],
  [-45.88880, 170.47914]
];

const elginRoad = [
  [-45.88180, 170.48236],
  [-45.88131, 170.48066],
  [-45.88124, 170.48027],
  [-45.88077, 170.47679],
  [-45.88077, 170.47662],
  [-45.88079, 170.47655],
  [-45.88085, 170.47646],
  [-45.88319, 170.47546],
  [-45.88332, 170.47546],
  [-45.88341, 170.47549],
  [-45.88356, 170.47558],
  [-45.88365, 170.47560],
  [-45.88378, 170.47559],
  [-45.88765, 170.47398]
];

export const dunedinCableTrams: Line = {
  name: 'Dunedin Cable Trams',
  state: 'NZ',
  segments: [
    {
      segments: [roslyn],
      history: {
        opened: {
          date: '1881-02-06',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1951-10-25',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stuartSt],
      history: {
        opened: {
          date: '1900-10-06',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1947-07-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mornington],
      history: {
        opened: {
          date: '1883-03-23',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1957-03-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maryhillExtension],
      history: {
        opened: {
          date: '1885-03-18',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1955-10-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [elginRoad],
      history: {
        opened: {
          date: '1906-10-06',
          tracks: 2,
          gauge: 'narrow',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1910-01-22',
          status: 'closed'
        }]
      }
    }
  ]
}
