import { Line } from "../../../trackTypes";

const line = [
  [-21.9377, 148.1058],
  [-21.9386, 148.0997],
  [-21.9692, 148.0360],
  [-21.9712, 148.0280],
  [-21.9736, 148.0228],
  [-21.9793, 148.0155],
  [-21.9856, 148.0111],
  [-21.9906, 148.0093],
  [-22.0481, 147.9961],
  [-22.0850, 147.9813],
  [-22.0922, 147.9766],
  [-22.0985, 147.9692],
  [-22.1163, 147.9358],
  [-22.1227, 147.9277],
  [-22.3002, 147.7968],
  [-22.3557, 147.7483],
  [-22.3861, 147.7293],
  [-22.3938, 147.7261],
  [-22.4032, 147.7246],
  [-22.4081, 147.7227],
  [-22.4198, 147.7148],
  [-22.4273, 147.7117],
  [-22.4557, 147.7069],
  [-22.5161, 147.6832],
  [-22.5243, 147.6782],
  [-22.5389, 147.6652],
  [-22.5478, 147.6606],
  [-22.5744, 147.6534],
  [-22.5786, 147.6511],
  [-22.5823, 147.6474],
  [-22.5945, 147.6284],
  [-22.6158, 147.5848],
  [-22.6208, 147.5781],
  [-22.6610, 147.5384],
  [-22.6689, 147.5304],
  [-22.6709, 147.5272],
  [-22.6786, 147.5147],
  [-22.6790, 147.5123],
  [-22.6782, 147.5101],
  [-22.6762, 147.5089],
  [-22.6742, 147.5090],
  [-22.6726, 147.5102],
  [-22.6718, 147.5121],
  [-22.6711, 147.5262],
  [-22.6709, 147.5272]
];

export const wotongaBlairAthol: Line = {
  name: 'Wotonga Blair Athol',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1983-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-05-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
        }]
      }
    }
  ]
}
