import { Line } from "../../trackTypes";

const line = [
  [-41.2789, 174.7805],
  [-41.2818, 174.7789],
  [-41.2837, 174.7779],
  [-41.2843, 174.7777],
  [-41.2850, 174.7778],
  [-41.2897, 174.7787],
  [-41.2910, 174.7801],
  [-41.2922, 174.7832]
];

export const teAro: Line = {
  name: 'Te Aro',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1893-03-27',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1917-04-23',
          status: 'closed'
        }]
      }
    }
  ]
}
