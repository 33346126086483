import { Line } from "../../../../trackTypes";

const line = [
  [-34.91302, 138.67589],
  [-34.91299, 138.67597],
  [-34.91294, 138.67602],
  [-34.91288, 138.67603],
  [-34.90287, 138.67529],
  [-34.90279, 138.67532],
  [-34.90274, 138.67538],
  [-34.90271, 138.67545],
  [-34.90225, 138.68407],
  [-34.90219, 138.68421],
  [-34.90211, 138.68427],
  [-34.90068, 138.68418],
  [-34.90054, 138.68429],
  [-34.90042, 138.68453],
  [-34.90010, 138.69080],
  [-34.90006, 138.69096],
  [-34.89999, 138.69108],
  [-34.89897, 138.69203],
  [-34.89883, 138.69220],
  [-34.89867, 138.69251],
  [-34.89824, 138.69442]
];

export const east6: Line = {
  name: 'East6',
  state: 'SA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1914-03-04',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-03-18',
          status: 'closed'
        }]
      }
    }
  ]
};
