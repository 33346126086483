import { Line } from "../../trackTypes";

const maryboroughMaryboroughWharf = [
  [-25.5360, 152.6970],
  [-25.5364, 152.6993],
  [-25.5362, 152.7008],
  [-25.5365, 152.7017],
  [-25.5363, 152.7023],
  [-25.5353, 152.7035],
  [-25.5350, 152.7041],
  [-25.5351, 152.7046],
  [-25.5358, 152.7055],
  [-25.5364, 152.7058],
  [-25.5375, 152.7058],
  [-25.5380, 152.7059],
  [-25.5394, 152.7067],
  [-25.5398, 152.7071],
  [-25.5401, 152.7074],
  [-25.5409, 152.7086]
];

const maryboroughWharfWalkersFoundry = [
  [-25.5401, 152.7074],
  [-25.5406, 152.7076],
  [-25.5411, 152.7075],
  [-25.5416, 152.7077],
  [-25.5429, 152.7093],
  [-25.5429, 152.7098],
  [-25.5428, 152.7102],
  [-25.5422, 152.7108]
];

const maryboroughWharfWalkersFoundryTwo = [
  [-25.5416, 152.7077],
  [-25.5402, 152.7058],
  [-25.5396, 152.7049],
  [-25.5402, 152.7058],
  [-25.5405, 152.7060],
  [-25.5434, 152.7064]
];

const walkersFoundrySugarFactory = [
  [-25.5429, 152.7093],
  [-25.5476, 152.7156],
  [-25.5482, 152.7166],
  [-25.5482, 152.7173],
  [-25.5479, 152.7177],
  [-25.5474, 152.7177],
  [-25.5471, 152.7173],
  [-25.5472, 152.7167],
  [-25.5474, 152.7164],
  [-25.5476, 152.7159],
  [-25.5476, 152.7156]
];

export const maryboroughWharf: Line = {
  name: 'Maryborough Wharf',
  state: 'QLD',
  segments: [
    {
      segments: [maryboroughMaryboroughWharf],
      history: {
        opened: {
          date: '1880-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maryboroughWharfWalkersFoundry, maryboroughWharfWalkersFoundryTwo],
      history: {
        opened: {
          date: '1883-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [walkersFoundrySugarFactory],
      history: {
        opened: {
          date: '1894-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
