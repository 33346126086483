import { Line } from "../../../trackTypes";

const toowoombaDraytonDeviationEnd = [
  [-27.4983, 151.8888],
  [-27.4985, 151.8879],
  [-27.4989, 151.8869],
  [-27.5016, 151.8834],
  [-27.5021, 151.8825],
  [-27.5023, 151.8812],
  [-27.5023, 151.8765],
  [-27.5025, 151.8753],
  [-27.5037, 151.8738],
  [-27.5044, 151.8734],
  [-27.5058, 151.8730],
  [-27.5066, 151.8726],
  [-27.5070, 151.8720],
  [-27.5078, 151.8688],
  [-27.5078, 151.8681],
  [-27.5077, 151.8671],
  [-27.5078, 151.8663],
  [-27.5082, 151.8655],
  [-27.5096, 151.8645],
  [-27.5103, 151.8643],
  [-27.5113, 151.8645],
  [-27.5189, 151.8701],
  [-27.5199, 151.8705],
  [-27.5211, 151.8703],
  [-27.5253, 151.8675],
  [-27.5283, 151.8665],
  [-27.5293, 151.8663],
  [-27.5302, 151.8664],
  [-27.5338, 151.8682],
  [-27.5353, 151.8684],
  [-27.5372, 151.8678],
  [-27.5385, 151.8672],
  [-27.5444, 151.8584],
  [-27.5458, 151.8576],
  [-27.5471, 151.8576],
  [-27.5667, 151.8611],
  [-27.5777, 151.8612],
  [-27.5812, 151.8604],
  [-27.5821, 151.8604],
  [-27.5830, 151.8610],
  [-27.5863, 151.8652],
  [-27.5870, 151.8657],
  [-27.5878, 151.8659],
  [-27.5974, 151.8650],
  [-27.5987, 151.8652],
  [-27.6063, 151.8688],
  [-27.6080, 151.8692],
  [-27.6133, 151.8687],
  [-27.6506, 151.8573]
];

const draytonDeviation = [
  [-27.5581, 151.9517],
  [-27.5587, 151.9516],
  [-27.5594, 151.9514],
  [-27.5619, 151.9500],
  [-27.5625, 151.9498],
  [-27.5633, 151.9496],
  [-27.5687, 151.9487],
  [-27.5720, 151.9490],
  [-27.5728, 151.9488],
  [-27.5738, 151.9479],
  [-27.5741, 151.9469],
  [-27.5740, 151.9459],
  [-27.5735, 151.9449],
  [-27.5696, 151.9409],
  [-27.5689, 151.9396],
  [-27.5686, 151.9382],
  [-27.5688, 151.9370],
  [-27.5714, 151.9270],
  [-27.5724, 151.9253],
  [-27.5737, 151.9241],
  [-27.5754, 151.9232],
  [-27.5764, 151.9230],
  [-27.5797, 151.9223],
  [-27.5813, 151.9216],
  [-27.5849, 151.9191],
  [-27.5868, 151.9184],
  [-27.5939, 151.9170],
  [-27.5954, 151.9171],
  [-27.5985, 151.9183],
  [-27.6000, 151.9183],
  [-27.6058, 151.9154],
  [-27.6070, 151.9142],
  [-27.6077, 151.9127],
  [-27.6085, 151.9116],
  [-27.6094, 151.9110],
  [-27.6190, 151.9072],
  [-27.6203, 151.9060],
  [-27.6207, 151.9043],
  [-27.6201, 151.9007],
  [-27.6203, 151.8982],
  [-27.6213, 151.8960],
  [-27.6238, 151.8930],
  [-27.6323, 151.8849],
  [-27.6362, 151.8824],
  [-27.6416, 151.8805],
  [-27.6430, 151.8795],
  [-27.6437, 151.8783],
  [-27.6442, 151.8770],
  [-27.6458, 151.8669],
  [-27.6468, 151.8635],
  [-27.6491, 151.8587],
  [-27.6497, 151.8579],
  [-27.6506, 151.8573]
];

const draytonDeviationEndWarwick = [
  [-27.6506, 151.8573],
  [-27.6516, 151.8570],
  [-27.6529, 151.8566],
  [-27.6545, 151.8568],
  [-27.6568, 151.8575],
  [-27.6628, 151.8598],
  [-27.6641, 151.8600],
  [-27.6652, 151.8600],
  [-27.6662, 151.8602],
  [-27.6671, 151.8609],
  [-27.6677, 151.8618],
  [-27.6684, 151.8624],
  [-27.6695, 151.8626],
  [-27.6703, 151.8622],
  [-27.6717, 151.8604],
  [-27.6726, 151.8597],
  [-27.6738, 151.8595],
  [-27.6757, 151.8599],
  [-27.6766, 151.8603],
  [-27.6779, 151.8618],
  [-27.6788, 151.8626],
  [-27.6800, 151.8629],
  [-27.6814, 151.8624],
  [-27.6837, 151.8603],
  [-27.6853, 151.8595],
  [-27.6873, 151.8598],
  [-27.6901, 151.8618],
  [-27.6931, 151.8629],
  [-27.7202, 151.8635],
  [-27.7227, 151.8625],
  [-27.7248, 151.8595],
  [-27.7258, 151.8553],
  [-27.7275, 151.8523],
  [-27.7303, 151.8509],
  [-27.7348, 151.8509],
  [-27.7370, 151.8516],
  [-27.7394, 151.8536],
  [-27.7508, 151.8713],
  [-27.7561, 151.8782],
  [-27.7583, 151.8829],
  [-27.7593, 151.8884],
  [-27.7601, 151.8907],
  [-27.7625, 151.8932],
  [-27.7668, 151.8959],
  [-27.7693, 151.8993],
  [-27.7704, 151.9031],
  [-27.7722, 151.9059],
  [-27.7751, 151.9077],
  [-27.7783, 151.9079],
  [-27.7807, 151.9069],
  [-27.7829, 151.9050],
  [-27.7847, 151.9040],
  [-27.7867, 151.9035],
  [-27.7903, 151.9038],
  [-27.7922, 151.9044],
  [-27.7956, 151.9070],
  [-27.7971, 151.9073],
  [-27.7987, 151.9065],
  [-27.7999, 151.9043],
  [-27.8005, 151.9035],
  [-27.8016, 151.9030],
  [-27.8026, 151.9032],
  [-27.8076, 151.9061],
  [-27.8120, 151.9077],
  [-27.8223, 151.9091],
  [-27.8261, 151.9081],
  [-27.8290, 151.9060],
  [-27.8317, 151.9050],
  [-27.8351, 151.9054],
  [-27.8402, 151.9083],
  [-27.8421, 151.9089],
  [-27.8455, 151.9087],
  [-27.8484, 151.9075],
  [-27.8529, 151.9038],
  [-27.8559, 151.9027],
  [-27.8589, 151.9025],
  [-27.8614, 151.9031],
  [-27.8654, 151.9051],
  [-27.8684, 151.9058],
  [-27.9181, 151.9094],
  [-27.9204, 151.9090],
  [-27.9258, 151.9068],
  [-27.9275, 151.9064],
  [-27.9369, 151.9064],
  [-27.9502, 151.9095],
  [-27.9516, 151.9096],
  [-27.9656, 151.9049],
  [-27.9670, 151.9047],
  [-27.9690, 151.9049],
  [-27.9717, 151.9061],
  [-27.9748, 151.9086],
  [-27.9767, 151.9097],
  [-27.9794, 151.9102],
  [-27.9813, 151.9101],
  [-27.9908, 151.9073],
  [-27.9924, 151.9072],
  [-27.9937, 151.9077],
  [-27.9988, 151.9121],
  [-28.0087, 151.9171],
  [-28.0099, 151.9175],
  [-28.0113, 151.9172],
  [-28.0129, 151.9160],
  [-28.0136, 151.9144],
  [-28.0141, 151.9135],
  [-28.0152, 151.9127],
  [-28.0190, 151.9119],
  [-28.0211, 151.9119],
  [-28.0301, 151.9150],
  [-28.0353, 151.9177],
  [-28.0373, 151.9182],
  [-28.0384, 151.9187],
  [-28.0416, 151.9216],
  [-28.0455, 151.9232],
  [-28.0526, 151.9249],
  [-28.0548, 151.9259],
  [-28.0597, 151.9307],
  [-28.0708, 151.9459],
  [-28.0722, 151.9474],
  [-28.0802, 151.9532],
  [-28.0820, 151.9538],
  [-28.0842, 151.9536],
  [-28.0920, 151.9498],
  [-28.0936, 151.9496],
  [-28.0958, 151.9508],
  [-28.1027, 151.9604],
  [-28.1041, 151.9614],
  [-28.1057, 151.9616],
  [-28.1101, 151.9606],
  [-28.1114, 151.9606],
  [-28.1133, 151.9612],
  [-28.1141, 151.9613],
  [-28.1149, 151.9610],
  [-28.1156, 151.9603],
  [-28.1168, 151.9562],
  [-28.1172, 151.9543],
  [-28.1183, 151.9530],
  [-28.1199, 151.9526],
  [-28.1478, 151.9556],
  [-28.1492, 151.9560],
  [-28.1544, 151.9584],
  [-28.1594, 151.9623],
  [-28.1605, 151.9636],
  [-28.1638, 151.9696],
  [-28.1654, 151.9713],
  [-28.1691, 151.9738],
  [-28.1707, 151.9741],
  [-28.1750, 151.9736],
  [-28.1771, 151.9738],
  [-28.1789, 151.9749],
  [-28.1815, 151.9778],
  [-28.1821, 151.9794],
  [-28.1852, 152.0017],
  [-28.1863, 152.0042],
  [-28.1965, 152.0176],
  [-28.2002, 152.0210],
  [-28.2015, 152.0216],
  [-28.2055, 152.0220],
  [-28.2066, 152.0230],
  [-28.2069, 152.0243],
  [-28.2062, 152.0268],
  [-28.2051, 152.0288],
  [-28.2021, 152.0364],
  [-28.2021, 152.0374],
  [-28.2026, 152.0394],
  [-28.2035, 152.0403],
  [-28.2082, 152.0422],
  [-28.2095, 152.0423],
  [-28.2179, 152.0409]
];

const warwickWarwickJunction = [
  [-28.2179, 152.0409],
  [-28.2186, 152.0407],
  [-28.2215, 152.0395],

];

const warwickCherryGullyTunnel = [
  [-28.2215, 152.0395],
  [-28.2252, 152.0388],
  [-28.2401, 152.0333],
  [-28.2441, 152.0326],
  [-28.2452, 152.0321],
  [-28.2479, 152.0303],
  [-28.2492, 152.0289],
  [-28.2517, 152.0252],
  [-28.2524, 152.0234],
  [-28.2529, 152.0227],
  [-28.2536, 152.0221],
  [-28.2565, 152.0209],
  [-28.2597, 152.0204],
  [-28.2616, 152.0195],
  [-28.2627, 152.0186],
  [-28.2647, 152.0162],
  [-28.2655, 152.0156],
  [-28.2677, 152.0145],
  [-28.2688, 152.0144],
  [-28.2723, 152.0148],
  [-28.2733, 152.0145],
  [-28.2746, 152.0134],
  [-28.2759, 152.0130],
  [-28.2773, 152.0131],
  [-28.2796, 152.0142],
  [-28.2811, 152.0144],
  [-28.2840, 152.0140],
  [-28.2868, 152.0141],
  [-28.2883, 152.0137],
  [-28.2900, 152.0138],
  [-28.2915, 152.0143],
  [-28.2924, 152.0152],
  [-28.2927, 152.0155],
  [-28.2935, 152.0155],
  [-28.2944, 152.0152],
  [-28.2949, 152.0150],
  [-28.2972, 152.0152],
  [-28.2986, 152.0158],
  [-28.2993, 152.0160],
  [-28.3000, 152.0156],
  [-28.3007, 152.0156],
  [-28.3013, 152.0158],
  [-28.3017, 152.0158],
  [-28.3020, 152.0157],
  [-28.3026, 152.0152],
  [-28.3032, 152.0149],
  [-28.3047, 152.0150],
  [-28.3058, 152.0153],
  [-28.3078, 152.0149],
  [-28.3089, 152.0143],
  [-28.3096, 152.0140],
  [-28.3103, 152.0140],
  [-28.3120, 152.0144],
  [-28.3138, 152.0147],
  [-28.3173, 152.0145],
  [-28.3187, 152.0151],
  [-28.3196, 152.0157],
  [-28.3205, 152.0159],
  [-28.3240, 152.0162],
  [-28.3254, 152.0168],
  [-28.3314, 152.0203],
  [-28.3331, 152.0206],
  [-28.3338, 152.0203],
  [-28.3343, 152.0196],
  [-28.3347, 152.0193],
  [-28.3352, 152.0192],
  [-28.3369, 152.0195],
  [-28.3375, 152.0192],
  [-28.3379, 152.0184],
  [-28.3377, 152.0177],
  [-28.3374, 152.0170],
  [-28.3374, 152.0162],
  [-28.3378, 152.0155],
  [-28.3418, 152.0138],
  [-28.3425, 152.0139],
  [-28.3431, 152.0142],
  [-28.3436, 152.0146],
  [-28.3442, 152.0148],
  [-28.3474, 152.0146],
  [-28.3481, 152.0149],
  [-28.3485, 152.0153],
  [-28.3489, 152.0160],
  [-28.3496, 152.0165],
  [-28.3501, 152.0173],
  [-28.3508, 152.0193],
  [-28.3514, 152.0198],
  [-28.3521, 152.0199],
  [-28.3529, 152.0194],
  [-28.3537, 152.0176],
  [-28.3546, 152.0168],
  [-28.3557, 152.0167],
  [-28.3567, 152.0172],
  [-28.3577, 152.0186],
  [-28.3581, 152.0191],
  [-28.3587, 152.0194],
  [-28.3605, 152.0195],
  [-28.3609, 152.0194],
  [-28.3614, 152.0192],
  [-28.3620, 152.0193],
  [-28.3624, 152.0197],
  [-28.3629, 152.0204],
  [-28.3637, 152.0207],
  [-28.3645, 152.0202],
  [-28.3650, 152.0186],
  [-28.3656, 152.0180],
  [-28.3663, 152.0180],
  [-28.3681, 152.0191],
  [-28.3686, 152.0192],
  [-28.3692, 152.0189],
  [-28.3696, 152.0186],
  [-28.3702, 152.0184],
  [-28.3721, 152.0189],
  [-28.3732, 152.0189],
  [-28.3741, 152.0187],
  [-28.3748, 152.0192],
  [-28.3751, 152.0200],
  [-28.3738, 152.0238],
  [-28.3740, 152.0248],
  [-28.3746, 152.0253],
  [-28.3767, 152.0256],
  [-28.3781, 152.0261],
  [-28.3787, 152.0269],
  [-28.3789, 152.0280],
  [-28.3793, 152.0286],
  [-28.3799, 152.0288],
  [-28.3821, 152.0284],
  [-28.3826, 152.0285],
  [-28.3829, 152.0289],
  [-28.3838, 152.0307],
  [-28.3848, 152.0316],
  [-28.3856, 152.0317],
  [-28.3863, 152.0313],
  [-28.3876, 152.0300],
  [-28.3881, 152.0298],
  [-28.3887, 152.0300],
  [-28.3902, 152.0312],
  [-28.3907, 152.0322],
  [-28.3911, 152.0335],
  [-28.3918, 152.0346],
  [-28.3930, 152.0355],
  [-28.3944, 152.0357],
  [-28.3963, 152.0352],
  [-28.3970, 152.0346],
  [-28.3976, 152.0337],
  [-28.3990, 152.0325],
  [-28.4006, 152.0300],
  [-28.4010, 152.0297],
  [-28.4017, 152.0296],
  [-28.4022, 152.0293],
  [-28.4030, 152.0293],
  [-28.4036, 152.0300],
  [-28.4038, 152.0304],
  [-28.4043, 152.0308],
  [-28.4056, 152.0308],
  [-28.4060, 152.0306],
  [-28.4063, 152.0302],
  [-28.4067, 152.0294],
  [-28.4073, 152.0286],
  [-28.4080, 152.0281],
  [-28.4101, 152.0275]
];

const cherryGullyTunnel = [
  [-28.4101, 152.0275],
  [-28.4125, 152.0267]
];

const cherryGullyTunnelDalveenTunnel = [
  [-28.4125, 152.0267],
  [-28.4137, 152.0263],
  [-28.4145, 152.0262],
  [-28.4159, 152.0263],
  [-28.4166, 152.0268],
  [-28.4167, 152.0274],
  [-28.4164, 152.0285],
  [-28.4164, 152.0289],
  [-28.4165, 152.0293],
  [-28.4173, 152.0303],
  [-28.4188, 152.0313],
  [-28.4233, 152.0323],
  [-28.4240, 152.0320],
  [-28.4243, 152.0316],
  [-28.4245, 152.0307],
  [-28.4250, 152.0299],
  [-28.4262, 152.0291],
  [-28.4271, 152.0291],
  [-28.4276, 152.0296],
  [-28.4286, 152.0319],
  [-28.4291, 152.0324],
  [-28.4299, 152.0326],
  [-28.4306, 152.0324],
  [-28.4311, 152.0318],
  [-28.4314, 152.0312],
  [-28.4317, 152.0308],
  [-28.4322, 152.0305],
  [-28.4374, 152.0304],
  [-28.4385, 152.0309],
  [-28.4401, 152.0332],
  [-28.4405, 152.0335],
  [-28.4410, 152.0337],
  [-28.4414, 152.0336],
  [-28.4420, 152.0339],
  [-28.4424, 152.0345],
  [-28.4430, 152.0350],
  [-28.4447, 152.0354],
  [-28.4459, 152.0353],
  [-28.4465, 152.0354],
  [-28.4471, 152.0358],
  [-28.4478, 152.0358],
  [-28.4484, 152.0355],
  [-28.4491, 152.0345],
  [-28.4495, 152.0342],
  [-28.4508, 152.0339],
  [-28.4516, 152.0334],
  [-28.4519, 152.0326],
  [-28.4522, 152.0305],
  [-28.4524, 152.0299],
  [-28.4529, 152.0295],
  [-28.4541, 152.0294],
  [-28.4547, 152.0297],
  [-28.4555, 152.0304],
  [-28.4570, 152.0307],
  [-28.4576, 152.0306],
  [-28.4584, 152.0303],
  [-28.4591, 152.0303],
  [-28.4596, 152.0303],
  [-28.4599, 152.0300],
  [-28.4609, 152.0285],
  [-28.4614, 152.0280],
  [-28.4621, 152.0276],
  [-28.4625, 152.0272],
  [-28.4627, 152.0266],
  [-28.4630, 152.0246],
  [-28.4633, 152.0236],
  [-28.4653, 152.0202],
  [-28.4659, 152.0195],
  [-28.4666, 152.0191],
  [-28.4715, 152.0180],
  [-28.4724, 152.0174],
  [-28.4730, 152.0164],
  [-28.4736, 152.0147],
  [-28.4743, 152.0138],
  [-28.4750, 152.0133],
  [-28.4760, 152.0129],
  [-28.4765, 152.0124],
  [-28.4768, 152.0116],
  [-28.4768, 152.0105],
  [-28.4769, 152.0100],
  [-28.4772, 152.0095],
  [-28.4785, 152.0085],
  [-28.4791, 152.0084],
  [-28.4812, 152.0089],
  [-28.4819, 152.0094],
  [-28.4828, 152.0102],
  [-28.4836, 152.0104],
  [-28.4843, 152.0100],
  [-28.4845, 152.0091],
  [-28.4847, 152.0067],
  [-28.4850, 152.0053],
  [-28.4857, 152.0041],
  [-28.4877, 152.0017],
  [-28.4879, 152.0012],
  [-28.4883, 151.9999],
  [-28.4887, 151.9993],
  [-28.4892, 151.9988],
  [-28.4896, 151.9982],
  [-28.4900, 151.9969],
  [-28.4904, 151.9964],
  [-28.4909, 151.9959],
  [-28.4913, 151.9950],
  [-28.4912, 151.9898],
  [-28.4908, 151.9883],
  [-28.4908, 151.9874],
  [-28.4915, 151.9844],
  [-28.4914, 151.9821],
  [-28.4908, 151.9811],
  [-28.4895, 151.9806]
];

const dalveenTunnel = [
  [-28.4895, 151.9806],
  [-28.4883, 151.9802]
];

const dalveenTunnelStanthorpe = [
  [-28.4883, 151.9802],
  [-28.4877, 151.9800],
  [-28.4872, 151.9796],
  [-28.4870, 151.9792],
  [-28.4866, 151.9786],
  [-28.4862, 151.9781],
  [-28.4860, 151.9776],
  [-28.4860, 151.9770],
  [-28.4862, 151.9765],
  [-28.4867, 151.9761],
  [-28.4871, 151.9755],
  [-28.4872, 151.9749],
  [-28.4869, 151.9731],
  [-28.4870, 151.9723],
  [-28.4874, 151.9717],
  [-28.5033, 151.9630],
  [-28.5055, 151.9608],
  [-28.5109, 151.9506],
  [-28.5121, 151.9493],
  [-28.5136, 151.9488],
  [-28.5203, 151.9489],
  [-28.5743, 151.9495],
  [-28.5763, 151.9501],
  [-28.5772, 151.9512],
  [-28.5790, 151.9562],
  [-28.5796, 151.9576],
  [-28.5803, 151.9583],
  [-28.5887, 151.9640],
  [-28.5954, 151.9656],
  [-28.5973, 151.9655],
  [-28.6007, 151.9640],
  [-28.6019, 151.9629],
  [-28.6038, 151.9601],
  [-28.6049, 151.9590],
  [-28.6061, 151.9584],
  [-28.6234, 151.9549],
  [-28.6247, 151.9549],
  [-28.6261, 151.9553],
  [-28.6274, 151.9554],
  [-28.6324, 151.9541],
  [-28.6339, 151.9534],
  [-28.6366, 151.9514],
  [-28.6389, 151.9501],
  [-28.6410, 151.9494],
  [-28.6426, 151.9493],
  [-28.6457, 151.9497],
  [-28.6468, 151.9494],
  [-28.6479, 151.9484],
  [-28.6484, 151.9477],
  [-28.6489, 151.9472],
  [-28.6497, 151.9468],
  [-28.6504, 151.9463],
  [-28.6514, 151.9445],
  [-28.6522, 151.9437],
  [-28.6526, 151.9422],
  [-28.6526, 151.9404],
  [-28.6529, 151.9393],
  [-28.6535, 151.9386],
  [-28.6545, 151.9381],
  [-28.6575, 151.9375]
];

const stanthorpeWallangarra = [
  [-28.6575, 151.9375],
  [-28.6583, 151.9376],
  [-28.6592, 151.9380],
  [-28.6610, 151.9394],
  [-28.6613, 151.9398],
  [-28.6616, 151.9404],
  [-28.6622, 151.9410],
  [-28.6629, 151.9413],
  [-28.6643, 151.9413],
  [-28.6648, 151.9411],
  [-28.6654, 151.9407],
  [-28.6667, 151.9394],
  [-28.6671, 151.9384],
  [-28.6672, 151.9370],
  [-28.6664, 151.9328],
  [-28.6664, 151.9313],
  [-28.6668, 151.9299],
  [-28.6690, 151.9262],
  [-28.6698, 151.9253],
  [-28.6713, 151.9242],
  [-28.6776, 151.9211],
  [-28.6796, 151.9207],
  [-28.6821, 151.9211],
  [-28.6831, 151.9208],
  [-28.6840, 151.9203],
  [-28.6855, 151.9184],
  [-28.6856, 151.9174],
  [-28.6853, 151.9159],
  [-28.6854, 151.9146],
  [-28.6859, 151.9131],
  [-28.6868, 151.9124],
  [-28.6883, 151.9117],
  [-28.6892, 151.9109],
  [-28.6903, 151.9095],
  [-28.6919, 151.9082],
  [-28.6943, 151.9074],
  [-28.6965, 151.9075],
  [-28.6986, 151.9080],
  [-28.6996, 151.9081],
  [-28.7005, 151.9077],
  [-28.7026, 151.9063],
  [-28.7034, 151.9061],
  [-28.7045, 151.9060],
  [-28.7052, 151.9058],
  [-28.7143, 151.9016],
  [-28.7154, 151.9007],
  [-28.7170, 151.8984],
  [-28.7181, 151.8973],
  [-28.7199, 151.8961],
  [-28.7206, 151.8960],
  [-28.7216, 151.8961],
  [-28.7222, 151.8960],
  [-28.7229, 151.8955],
  [-28.7233, 151.8949],
  [-28.7238, 151.8931],
  [-28.7240, 151.8916],
  [-28.7244, 151.8903],
  [-28.7252, 151.8892],
  [-28.7306, 151.8848],
  [-28.7315, 151.8843],
  [-28.7329, 151.8839],
  [-28.7336, 151.8835],
  [-28.7341, 151.8830],
  [-28.7359, 151.8801],
  [-28.7369, 151.8790],
  [-28.7408, 151.8764],
  [-28.7421, 151.8748],
  [-28.7428, 151.8735],
  [-28.7437, 151.8711],
  [-28.7444, 151.8701],
  [-28.7455, 151.8693],
  [-28.7520, 151.8669],
  [-28.7532, 151.8657],
  [-28.7571, 151.8597],
  [-28.7586, 151.8552],
  [-28.7591, 151.8545],
  [-28.7599, 151.8542],
  [-28.7606, 151.8541],
  [-28.7622, 151.8545],
  [-28.7635, 151.8544],
  [-28.7647, 151.8535],
  [-28.7654, 151.8520],
  [-28.7654, 151.8509],
  [-28.7651, 151.8491],
  [-28.7653, 151.8482],
  [-28.7657, 151.8476],
  [-28.7664, 151.8474],
  [-28.7695, 151.8474],
  [-28.7703, 151.8479],
  [-28.7707, 151.8486],
  [-28.7708, 151.8495],
  [-28.7710, 151.8501],
  [-28.7725, 151.8528],
  [-28.7735, 151.8540],
  [-28.7742, 151.8544],
  [-28.7752, 151.8543],
  [-28.7759, 151.8538],
  [-28.7770, 151.8525],
  [-28.7776, 151.8521],
  [-28.7782, 151.8521],
  [-28.7791, 151.8523],
  [-28.7797, 151.8521],
  [-28.7803, 151.8517],
  [-28.7809, 151.8506],
  [-28.7844, 151.8473],
  [-28.7854, 151.8467],
  [-28.7862, 151.8465],
  [-28.7873, 151.8462],
  [-28.7880, 151.8463],
  [-28.7897, 151.8447],
  [-28.7899, 151.8437],
  [-28.7898, 151.8425],
  [-28.7899, 151.8416],
  [-28.7903, 151.8407],
  [-28.7924, 151.8385],
  [-28.7933, 151.8382],
  [-28.7939, 151.8382],
  [-28.7949, 151.8388],
  [-28.7966, 151.8411],
  [-28.7980, 151.8423],
  [-28.7996, 151.8430],
  [-28.8026, 151.8432],
  [-28.8052, 151.8440],
  [-28.8080, 151.8455],
  [-28.8119, 151.8470],
  [-28.8131, 151.8471],
  [-28.8140, 151.8468],
  [-28.8171, 151.8452],
  [-28.8190, 151.8449],
  [-28.8207, 151.8452],
  [-28.8228, 151.8463],
  [-28.8243, 151.8480],
  [-28.8247, 151.8498],
  [-28.8245, 151.8520],
  [-28.8249, 151.8534],
  [-28.8257, 151.8544],
  [-28.8266, 151.8548],
  [-28.8416, 151.8564],
  [-28.8429, 151.8571],
  [-28.8436, 151.8581],
  [-28.8445, 151.8608],
  [-28.8453, 151.8620],
  [-28.8466, 151.8626],
  [-28.8473, 151.8626],
  [-28.8479, 151.8624],
  [-28.8487, 151.8619],
  [-28.8495, 151.8619],
  [-28.8515, 151.8629],
  [-28.8525, 151.8640],
  [-28.8530, 151.8650],
  [-28.8534, 151.8662],
  [-28.8545, 151.8675],
  [-28.8557, 151.8680],
  [-28.8596, 151.8674],
  [-28.8616, 151.8674],
  [-28.8624, 151.8678],
  [-28.8628, 151.8686],
  [-28.8631, 151.8706],
  [-28.8643, 151.8726],
  [-28.8651, 151.8733],
  [-28.8657, 151.8741],
  [-28.8658, 151.8747],
  [-28.8659, 151.8767],
  [-28.8664, 151.8782],
  [-28.8673, 151.8791],
  [-28.8699, 151.8804],
  [-28.8705, 151.8810],
  [-28.8706, 151.8816],
  [-28.8706, 151.8821],
  [-28.8704, 151.8829],
  [-28.8704, 151.8836],
  [-28.8708, 151.8843],
  [-28.8718, 151.8851],
  [-28.8724, 151.8859],
  [-28.8732, 151.8871],
  [-28.8740, 151.8879],
  [-28.8801, 151.8909],
  [-28.8818, 151.8924],
  [-28.8833, 151.8948],
  [-28.8949, 151.9056],
  [-28.8954, 151.9065],
  [-28.8954, 151.9076],
  [-28.8951, 151.9083],
  [-28.8945, 151.9092],
  [-28.8942, 151.9104],
  [-28.8944, 151.9117],
  [-28.8951, 151.9130],
  [-28.8963, 151.9141],
  [-28.8977, 151.9146],
  [-28.9003, 151.9142],
  [-28.9017, 151.9142],
  [-28.9062, 151.9155],
  [-28.9098, 151.9177],
  [-28.9104, 151.9184],
  [-28.9109, 151.9192],
  [-28.9115, 151.9213],
  [-28.9171, 151.9325],
  [-28.9179, 151.9334],
  [-28.9192, 151.9338],
  [-28.9202, 151.9336],
  [-28.9214, 151.9330],
  [-28.9240, 151.9313]
];

export const southern: Line = {
  name: 'Southern',
  state: 'QLD',
  segments: [
    {
      segments: [
        {
          date: '1915-01-01',
          original: [toowoombaDraytonDeviationEnd],
          deviation: [draytonDeviation]
        },
        draytonDeviationEndWarwick
      ],
      history: {
        opened: {
          date: '1871-01-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        warwickWarwickJunction
      ],
      history: {
        opened: {
          date: '1881-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        warwickCherryGullyTunnel,
        {
          name: 'Cherry Gully Tunnel',
          segment: cherryGullyTunnel,
          type: 'tunnel'
        },
        cherryGullyTunnelDalveenTunnel,
        {
          name: 'Dalveen Tunnel',
          segment: dalveenTunnel,
          type: 'tunnel'
        },
        dalveenTunnelStanthorpe
      ],
      history: {
        opened: {
          date: '1881-05-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2024-03-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stanthorpeWallangarra],
      history: {
        opened: {
          date: '1887-02-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2024-03-06',
          status: 'closed'
        }]
      }
    }
  ]
}
