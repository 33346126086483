import { Line } from "../../../trackTypes";

const line = [
  [-34.4828, 150.8007],
  [-34.4832, 150.7998],
  [-34.4833, 150.7989],
  [-34.4832, 150.7981],
  [-34.4826, 150.7968],
  [-34.4825, 150.7962],
  [-34.4825, 150.7952],
  [-34.4824, 150.7941],
  [-34.4820, 150.7918],
  [-34.4813, 150.7892],
  [-34.4812, 150.7885],
  [-34.4812, 150.7867],
  [-34.4813, 150.7860],
  [-34.4818, 150.7841],
  [-34.4819, 150.7832],
  [-34.4814, 150.7814],
  [-34.4806, 150.7794],
  [-34.4804, 150.7786],
  [-34.4795, 150.7715],
  [-34.4794, 150.7700],
  [-34.4789, 150.7667],
  [-34.4791, 150.7654],
  [-34.4796, 150.7641],
  [-34.4799, 150.7630],
  [-34.4799, 150.7588],
  [-34.4796, 150.7580],
  [-34.4791, 150.7570],
  [-34.4786, 150.7564],
  [-34.4763, 150.7542],
  [-34.4758, 150.7534],
  [-34.4755, 150.7524]
];

const southKemblaCollierySpur = [
  [-34.4799, 150.7588],
  [-34.4803, 150.7551]
];

export const wongawill: Line = {
  name: 'Wongawill',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1928-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [southKemblaCollierySpur],
      history: {
        opened: {
          date: '1932-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
