import { Line } from "../../../trackTypes";

const capeLambertPannawonica = [
  [-20.5928, 117.1817],
  [-20.5930, 117.1803],
  [-20.5962, 117.1739],
  [-20.5977, 117.1718],
  [-20.5992, 117.1705],
  [-20.6099, 117.1650],
  [-20.6243, 117.1553],
  [-20.6420, 117.1413],
  [-20.6445, 117.1403],
  [-20.6477, 117.1401],
  [-20.6498, 117.1394],
  [-20.6527, 117.1376],
  [-20.6548, 117.1358],
  [-20.6814, 117.1173],
  [-20.6827, 117.1160],
  [-20.6955, 117.0996],
  [-20.6971, 117.0983],
  [-20.6995, 117.0976],
  [-20.7195, 117.0953],
  [-20.7231, 117.0956],
  [-20.7349, 117.0994],
  [-20.7381, 117.1000],
  [-20.7442, 117.0964],
  [-20.8482, 117.0970],
  [-20.8598, 117.1038],
  [-20.8641, 117.1048],
  [-20.8840, 117.1029],
  [-20.8871, 117.1031],
  [-20.9116, 117.1080],
  [-20.9144, 117.1102],
  [-20.9170, 117.1155],
  [-20.9192, 117.1182],
  [-20.9238, 117.1220],
  [-20.9282, 117.1227],
  [-20.9317, 117.1215],
  [-20.9404, 117.1113],
  [-20.9432, 117.1093],
  [-20.9500, 117.1070],
  [-20.9540, 117.1043],
  [-20.9698, 117.0854],
  [-20.9718, 117.0837],
  [-20.9892, 117.0772],
  [-20.9923, 117.0774],
  [-20.9946, 117.0792],
  [-21.0008, 117.0889],
  [-21.0050, 117.0920],
  [-21.0360, 117.0945],
  [-21.0406, 117.0962],
  [-21.0448, 117.0990],
  [-21.0487, 117.1004],
  [-21.0538, 117.0989],
  [-21.0559, 117.0986],
  [-21.0939, 117.1060],
  [-21.0970, 117.1058],
  [-21.1203, 117.0980],
  [-21.1306, 117.0912],
  [-21.1358, 117.0866],
  [-21.1444, 117.0816],
  [-21.1517, 117.0791],
  [-21.1542, 117.0775],
  [-21.1577, 117.0737],
  [-21.1615, 117.0705],
  [-21.1849, 117.0551],
  [-21.1915, 117.0493],
  [-21.1928, 117.0475],
  [-21.1963, 117.0357],
  [-21.2011, 117.0278],
  [-21.2028, 117.0263],
  [-21.2049, 117.0258],
  [-21.2148, 117.0262],
  [-21.2172, 117.0256],
  [-21.2189, 117.0243],
  [-21.2257, 117.0141],
  [-21.2289, 117.0101],
  [-21.2378, 117.0001],
  [-21.2393, 116.9979],
  [-21.2452, 116.9860],
  [-21.2469, 116.9841],
  [-21.2490, 116.9833],
  [-21.2512, 116.9835],
  [-21.2577, 116.9868],
  [-21.2601, 116.9869],
  [-21.2622, 116.9860],
  [-21.2665, 116.9813],
  [-21.2693, 116.9793],
  [-21.2706, 116.9778],
  [-21.2717, 116.9754],
  [-21.2731, 116.9738],
  [-21.2770, 116.9714],
  [-21.2780, 116.9705],
  [-21.2843, 116.9619],
  [-21.2973, 116.9493],
  [-21.3101, 116.9282],
  [-21.3258, 116.8959],
  [-21.3274, 116.8938],
  [-21.3345, 116.8886],
  [-21.3418, 116.8803],
  [-21.3458, 116.8776],
  [-21.3552, 116.8741],
  [-21.3587, 116.8711],
  [-21.3608, 116.8669],
  [-21.3633, 116.8602],
  [-21.3652, 116.8574],
  [-21.3675, 116.8560],
  [-21.3810, 116.8536],
  [-21.3857, 116.8536],
  [-21.3901, 116.8543],
  [-21.3976, 116.8563],
  [-21.3997, 116.8564],
  [-21.4067, 116.8553],
  [-21.4101, 116.8540],
  [-21.4133, 116.8519],
  [-21.4306, 116.8310],
  [-21.4333, 116.8297],
  [-21.4440, 116.8294],
  [-21.4474, 116.8284],
  [-21.4525, 116.8254],
  [-21.4579, 116.8233],
  [-21.4667, 116.8212],
  [-21.4756, 116.8172],
  [-21.4781, 116.8166],
  [-21.4934, 116.8191],
  [-21.5012, 116.8222],
  [-21.5048, 116.8224],
  [-21.5150, 116.8190],
  [-21.5208, 116.8191],
  [-21.5336, 116.8241],
  [-21.5366, 116.8241],
  [-21.5553, 116.8171],
  [-21.5575, 116.8156],
  [-21.5619, 116.8103],
  [-21.5795, 116.7966],
  [-21.5825, 116.7918],
  [-21.5868, 116.7686],
  [-21.5898, 116.7626],
  [-21.6167, 116.7341],
  [-21.6194, 116.7296],
  [-21.6249, 116.7114],
  [-21.6263, 116.7094],
  [-21.6288, 116.7080],
  [-21.6470, 116.7053],
  [-21.6505, 116.7033],
  [-21.6652, 116.6882],
  [-21.6711, 116.6793],
  [-21.6755, 116.6741],
  [-21.6843, 116.6660],
  [-21.6913, 116.6552],
  [-21.6919, 116.6528],
  [-21.6914, 116.6502],
  [-21.6829, 116.6350],
  [-21.6813, 116.6296],
  [-21.6815, 116.6233],
  [-21.6832, 116.6165],
  [-21.6835, 116.6125],
  [-21.6832, 116.6041],
  [-21.6839, 116.6001],
  [-21.6866, 116.5952],
  [-21.6876, 116.5917],
  [-21.6883, 116.5870],
  [-21.6891, 116.5840],
  [-21.6947, 116.5740],
  [-21.6953, 116.5715],
  [-21.6949, 116.5688],
  [-21.6873, 116.5533],
  [-21.6869, 116.5503],
  [-21.6908, 116.5257],
  [-21.6902, 116.5223],
  [-21.6875, 116.5166],
  [-21.6805, 116.4932],
  [-21.6803, 116.4900],
  [-21.6813, 116.4848],
  [-21.6813, 116.4740],
  [-21.6752, 116.4387],
  [-21.6720, 116.4281],
  [-21.6661, 116.4177],
  [-21.6610, 116.4069],
  [-21.6597, 116.4019],
  [-21.6580, 116.3748],
  [-21.6585, 116.3705],
  [-21.6597, 116.3669],
  [-21.6628, 116.3615],
  [-21.6645, 116.3567],
  [-21.6678, 116.3194]
];

const mesaJMine = [
  [-21.6678, 116.3194],
  [-21.6680, 116.3183],
  [-21.6695, 116.3155],
  [-21.6793, 116.3041],
  [-21.6898, 116.2855],
  [-21.6972, 116.2789],
  [-21.6999, 116.2774],
  [-21.7241, 116.2652],
  [-21.7403, 116.2630],
  [-21.7575, 116.2623],
  [-21.7774, 116.2612],
  [-21.7797, 116.2618],
  [-21.7811, 116.2628],
  [-21.7823, 116.2643]
];

const mesaAMine = [
  [-21.6999, 116.2774],
  [-21.7031, 116.2742],
  [-21.7053, 116.2653],
  [-21.7055, 116.2541],
  [-21.7062, 116.2514],
  [-21.7086, 116.2474],
  [-21.7093, 116.2456],
  [-21.7103, 116.2405],
  [-21.7131, 116.2328],
  [-21.7135, 116.2291],
  [-21.7125, 116.2251],
  [-21.7097, 116.2197],
  [-21.7083, 116.2152],
  [-21.7063, 116.1963],
  [-21.7043, 116.1850],
  [-21.7040, 116.1821],
  [-21.7044, 116.1763],
  [-21.7041, 116.1727],
  [-21.7029, 116.1676],
  [-21.6985, 116.1382],
  [-21.6991, 116.1339],
  [-21.7023, 116.1275],
  [-21.7055, 116.1241],
  [-21.7097, 116.1217],
  [-21.7124, 116.1190],
  [-21.7137, 116.1157],
  [-21.7163, 116.0954],
  [-21.7218, 116.0797],
  [-21.7222, 116.0766],
  [-21.7214, 116.0730],
  [-21.7160, 116.0627],
  [-21.7152, 116.0596],
  [-21.7149, 116.0514],
  [-21.7141, 116.0473],
  [-21.7102, 116.0367],
  [-21.6841, 115.9933],
  [-21.6682, 115.9781],
  [-21.6660, 115.9730],
  [-21.6658, 115.9685],
  [-21.6679, 115.9591],
  [-21.6676, 115.9555],
  [-21.6651, 115.9480],
  [-21.6647, 115.9446],
  [-21.6658, 115.9400],
  [-21.6696, 115.9321],
  [-21.6730, 115.9255],
  [-21.6794, 115.9163],
  [-21.6801, 115.9135],
  [-21.6797, 115.9114],
  [-21.6784, 115.9092],
  [-21.6761, 115.9078],
  [-21.6741, 115.9077],
  [-21.6702, 115.9085],
  [-21.6681, 115.9099],
  [-21.6666, 115.9123],
  [-21.6664, 115.9151],
  [-21.6700, 115.9284],
  [-21.6701, 115.9300],
  [-21.6696, 115.9321]
];

const hamersleyConnection = [
  [-21.1809, 117.0576],
  [-21.1835, 117.0556],
  [-21.1904, 117.0478],
  [-21.1923, 117.0462],
  [-21.1950, 117.0447],
  [-21.1970, 117.0443],
  [-21.1990, 117.0448],
  [-21.2031, 117.0474],
  [-21.2046, 117.0482],
  [-21.2159, 117.0500]
];

export const robeRiver: Line = {
  name: 'Robe River',
  state: 'WA',
  segments: [
    {
      segments: [capeLambertPannawonica],
      history: {
        opened: {
          date: '1972-08-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mesaJMine],
      history: {
        opened: {
          date: '1974-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mesaAMine],
      history: {
        opened: {
          date: '2010-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [hamersleyConnection],
      history: {
        opened: {
          date: '2002-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
