import { Line } from "../../../trackTypes";

const line = [
  [-21.1469, 147.8949],
  [-21.1483, 147.8944],
  [-21.1526, 147.8926],
  [-21.1544, 147.8916],
  [-21.1560, 147.8902],
  [-21.1577, 147.8880],
  [-21.1586, 147.8859],
  [-21.1631, 147.8730],
  [-21.1640, 147.8710],
  [-21.1652, 147.8691],
  [-21.1666, 147.8675],
  [-21.1697, 147.8644],
  [-21.1707, 147.8631],
  [-21.1713, 147.8620],
  [-21.1719, 147.8606],
  [-21.1746, 147.8520],
  [-21.1754, 147.8502],
  [-21.1766, 147.8481],
  [-21.1784, 147.8462],
  [-21.1806, 147.8445],
  [-21.1875, 147.8406],
  [-21.2038, 147.8292],
  [-21.2121, 147.8242],
  [-21.2133, 147.8236],
  [-21.2151, 147.8232],
  [-21.2183, 147.8230],
  [-21.2193, 147.8229],
  [-21.2202, 147.8227],
  [-21.2257, 147.8209],
  [-21.2276, 147.8205],
  [-21.2330, 147.8200],
  [-21.2505, 147.8193],
  [-21.2547, 147.8185],
  [-21.2592, 147.8163],
  [-21.2620, 147.8138],
  [-21.3115, 147.7574],
  [-21.3140, 147.7550],
  [-21.3162, 147.7534],
  [-21.3194, 147.7518],
  [-21.3343, 147.7461],
  [-21.3581, 147.7372],
  [-21.3619, 147.7353],
  [-21.3652, 147.7329],
  [-21.3803, 147.7191],
  [-21.3827, 147.7167],
  [-21.3966, 147.7000],
  [-21.3986, 147.6973],
  [-21.4057, 147.6889],
  [-21.4073, 147.6872],
  [-21.4139, 147.6820],
  [-21.4192, 147.6774],
  [-21.4332, 147.6636],
  [-21.4356, 147.6609],
  [-21.4375, 147.6574],
  [-21.4393, 147.6518],
  [-21.4550, 147.6006],
  [-21.4559, 147.5984],
  [-21.4569, 147.5967],
  [-21.5065, 147.5276],
  [-21.5214, 147.5090],
  [-21.5440, 147.4808],
  [-21.5456, 147.4785],
  [-21.5517, 147.4675],
  [-21.5562, 147.4595],
  [-21.5662, 147.4411],
  [-21.5677, 147.4379],
  [-21.5740, 147.4174],
  [-21.5757, 147.4116],
  [-21.5763, 147.4089],
  [-21.5764, 147.4054],
  [-21.5728, 147.3672],
  [-21.5722, 147.3601],
  [-21.5654, 147.2905],
  [-21.5645, 147.2805],
  [-21.5646, 147.2763],
  [-21.5657, 147.2719],
  [-21.5793, 147.2418],
  [-21.5804, 147.2384],
  [-21.5808, 147.2331],
  [-21.5755, 147.1883],
  [-21.5756, 147.1839],
  [-21.5763, 147.1805],
  [-21.5778, 147.1771],
  [-21.5805, 147.1733],
  [-21.6248, 147.1214],
  [-21.6427, 147.0996],
  [-21.6504, 147.0909],
  [-21.6674, 147.0715],
  [-21.6699, 147.0693],
  [-21.6721, 147.0678],
  [-21.7031, 147.0502],
  [-21.7197, 147.0407],
  [-21.7218, 147.0398],
  [-21.7258, 147.0388],
  [-21.7710, 147.0365],
  [-21.7745, 147.0360],
  [-21.8162, 147.0240],
  [-21.8190, 147.0229],
  [-21.8210, 147.0217],
  [-21.8241, 147.0191],
  [-21.8386, 147.0023],
  [-21.8403, 146.9998],
  [-21.8414, 146.9972],
  [-21.8420, 146.9943],
  [-21.8421, 146.9913],
  [-21.8411, 146.9813],
  [-21.8394, 146.9651],
  [-21.8353, 146.9244],
  [-21.8353, 146.9200],
  [-21.8359, 146.9171],
  [-21.8370, 146.9140],
  [-21.8415, 146.9052],
  [-21.8435, 146.9004],
  [-21.8441, 146.8981],
  [-21.8444, 146.8952],
  [-21.8401, 146.8502],
  [-21.8400, 146.8466],
  [-21.8409, 146.8426],
  [-21.8429, 146.8384],
  [-21.8930, 146.7648],
  [-21.8943, 146.7626],
  [-21.8951, 146.7606],
  [-21.9247, 146.6701],
  [-21.9255, 146.6665],
  [-21.9257, 146.6636],
  [-21.9176, 146.5784],
  [-21.9143, 146.5440],
  [-21.9144, 146.5399],
  [-21.9153, 146.5360],
  [-21.9163, 146.5337],
  [-21.9180, 146.5309],
  [-21.9555, 146.4804],
  [-21.9568, 146.4781],
  [-21.9884, 146.4164],
  [-21.9891, 146.4153],
  [-21.9903, 146.4143],
  [-21.9916, 146.4135],
  [-22.0120, 146.4058],
  [-22.0139, 146.4056],
  [-22.0153, 146.4059],
  [-22.0168, 146.4071],
  [-22.0178, 146.4088],
  [-22.0187, 146.4133],
  [-22.0192, 146.4142],
  [-22.0201, 146.4150],
  [-22.0215, 146.4153],
  [-22.0227, 146.4148],
  [-22.0236, 146.4138],
  [-22.0239, 146.4127],
  [-22.0238, 146.4112],
  [-22.0230, 146.4102],
  [-22.0187, 146.4064],
  [-22.0173, 146.4056],
  [-22.0156, 146.4050],
  [-22.0143, 146.4050],
  [-22.0131, 146.4054],
  [-22.0120, 146.4058]
];

export const carmichael: Line = {
  name: 'Carmichael',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2021-11-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
