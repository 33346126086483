import { Line } from "../../trackTypes";

const nuriootPenriceQuary = [
  [-34.4683, 139.0388],
  [-34.4678, 139.0400],
  [-34.4678, 139.0409],
  [-34.4686, 139.0432],
  [-34.4691, 139.0439],
  [-34.4700, 139.0443],
  [-34.4708, 139.0441],
  [-34.4798, 139.0401]
];

export const penriceQuary: Line = {
  name: 'Penrice Quary',
  state: 'SA',
  segments: [
    {
      segments: [nuriootPenriceQuary],
      history: {
        opened: {
          date: '1950-11-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-06-25',
          status: 'closed'
        }]
      }
    }
  ]
};
