import { Line } from "../../../trackTypes";

const rosewoodPerrysKnob = [
  [-27.6419, 152.5959],
  [-27.6414, 152.5965],
  [-27.6383, 152.5974],
  [-27.6377, 152.5974],
  [-27.6323, 152.5951],
  [-27.6279, 152.5918],
  [-27.6274, 152.5908],
  [-27.6275, 152.5897],
  [-27.6279, 152.5889],
  [-27.6291, 152.5880],
  [-27.6295, 152.5871],
  [-27.6295, 152.5864],
  [-27.6284, 152.5836],
  [-27.6280, 152.5831],
  [-27.6272, 152.5828],
  [-27.6265, 152.5830],
  [-27.6242, 152.5844],
  [-27.6236, 152.5844],
  [-27.6231, 152.5840],
  [-27.6226, 152.5832],
  [-27.6222, 152.5829],
  [-27.6217, 152.5829],
  [-27.6199, 152.5835],
  [-27.6195, 152.5833],
  [-27.6186, 152.5825],
  [-27.6177, 152.5824],
  [-27.6171, 152.5826]
];

const perrysKnobCabanda = [
  [-27.6171, 152.5826],
  [-27.6160, 152.5837],
  [-27.6148, 152.5851],
  [-27.6141, 152.5852],
  [-27.6136, 152.5849],
  [-27.6126, 152.5836],
  [-27.6124, 152.5831],
  [-27.6133, 152.5803],
  [-27.6132, 152.5796],
  [-27.6131, 152.5795]
];

const cabandaKunkala = [
  [-27.6131, 152.5795],
  [-27.6127, 152.5791],
  [-27.6123, 152.5790],
  [-27.6112, 152.5790],
  [-27.6107, 152.5788],
  [-27.6103, 152.5784],
  [-27.6100, 152.5779],
  [-27.6100, 152.5774],
  [-27.6101, 152.5769],
  [-27.6127, 152.5727],
  [-27.6128, 152.5721],
  [-27.6126, 152.5713],
  [-27.6122, 152.5708],
  [-27.6120, 152.5702],
  [-27.6119, 152.5683],
  [-27.6118, 152.5676],
  [-27.6114, 152.5659],
  [-27.6114, 152.5652],
  [-27.6115, 152.5635],
  [-27.6113, 152.5629],
  [-27.6110, 152.5625],
  [-27.6102, 152.5622],
  [-27.6088, 152.5617],
  [-27.6080, 152.5618],
  [-27.6067, 152.5623]
];

const kunkalaBirru = [
  [-27.6067, 152.5623],
  [-27.6061, 152.5625],
  [-27.6057, 152.5631],
  [-27.6054, 152.5643],
  [-27.6047, 152.5647],
  [-27.6039, 152.5645],
  [-27.6031, 152.5640],
  [-27.6026, 152.5642],
  [-27.6023, 152.5646],
  [-27.6022, 152.5652],
  [-27.6023, 152.5664],
  [-27.6024, 152.5667],
  [-27.6029, 152.5671],
  [-27.6031, 152.5675],
  [-27.6032, 152.5682],
  [-27.6029, 152.5687],
  [-27.5989, 152.5732],
  [-27.5953, 152.5797],
  [-27.5946, 152.5810]
];

const birruMarburg = [
  [-27.5946, 152.5810],
  [-27.5933, 152.5833],
  [-27.5921, 152.5848],
  [-27.5904, 152.5864],
  [-27.5855, 152.5892],
  [-27.5833, 152.5900],
  [-27.5757, 152.5915],
  [-27.5744, 152.5922],
  [-27.5727, 152.5933],
  [-27.5715, 152.5939],
  [-27.5686, 152.5944],
  [-27.5674, 152.5945],
  [-27.5660, 152.5943]
];

export const marburg: Line = {
  name: 'Marburg',
  state: 'QLD',
  segments: [
    {
      segments: [rosewoodPerrysKnob],
      history: {
        opened: {
          date: '1911-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1979-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [perrysKnobCabanda],
      history: {
        opened: {
          date: '1911-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1973-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cabandaKunkala],
      history: {
        opened: {
          date: '1911-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kunkalaBirru],
      history: {
        opened: {
          date: '1911-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [birruMarburg],
      history: {
        opened: {
          date: '1911-12-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
