import { Line } from "../../trackTypes";

const balhannahMyPleasant = [
  [-34.9968, 138.8305],
  [-34.9972, 138.8316],
  [-34.9973, 138.8327],
  [-34.9972, 138.8331],
  [-34.9967, 138.8340],
  [-34.9961, 138.8347],
  [-34.9954, 138.8349],
  [-34.9936, 138.8350],
  [-34.9927, 138.8353],
  [-34.9914, 138.8361],
  [-34.9903, 138.8373],
  [-34.9890, 138.8400],
  [-34.9879, 138.8412],
  [-34.9860, 138.8431],
  [-34.9837, 138.8460],
  [-34.9823, 138.8487],
  [-34.9789, 138.8533],
  [-34.9756, 138.8601],
  [-34.9748, 138.8610],
  [-34.9697, 138.8638],
  [-34.9688, 138.8647],
  [-34.9656, 138.8690],
  [-34.9644, 138.8700],
  [-34.9554, 138.8741],
  [-34.9365, 138.8828],
  [-34.9343, 138.8844],
  [-34.9279, 138.8915],
  [-34.9270, 138.8921],
  [-34.9246, 138.8933],
  [-34.9221, 138.8958],
  [-34.9147, 138.9059],
  [-34.9051, 138.9142],
  [-34.9022, 138.9159],
  [-34.9013, 138.9167],
  [-34.8991, 138.9196],
  [-34.8981, 138.9205],
  [-34.8962, 138.9212],
  [-34.8950, 138.9220],
  [-34.8946, 138.9227],
  [-34.8937, 138.9239],
  [-34.8927, 138.9247],
  [-34.8899, 138.9260],
  [-34.8891, 138.9268],
  [-34.8831, 138.9354],
  [-34.8821, 138.9380],
  [-34.8811, 138.9429],
  [-34.8792, 138.9471],
  [-34.8787, 138.9476],
  [-34.8724, 138.9526],
  [-34.8711, 138.9532],
  [-34.8662, 138.9541],
  [-34.8654, 138.9544],
  [-34.8643, 138.9551],
  [-34.8633, 138.9552],
  [-34.8626, 138.9550],
  [-34.8614, 138.9545],
  [-34.8605, 138.9544],
  [-34.8595, 138.9547],
  [-34.8581, 138.9560],
  [-34.8573, 138.9577],
  [-34.8564, 138.9586],
  [-34.8554, 138.9589],
  [-34.8479, 138.9589],
  [-34.8295, 138.9601],
  [-34.8287, 138.9603],
  [-34.8244, 138.9622],
  [-34.8236, 138.9630],
  [-34.8192, 138.9698],
  [-34.8178, 138.9708],
  [-34.8156, 138.9724],
  [-34.8144, 138.9735],
  [-34.8114, 138.9777],
  [-34.8097, 138.9794],
  [-34.8078, 138.9806],
  [-34.8068, 138.9816],
  [-34.8063, 138.9830],
  [-34.8062, 138.9836],
  [-34.8064, 138.9861],
  [-34.8062, 138.9873],
  [-34.8030, 138.9952],
  [-34.8020, 138.9991],
  [-34.7905, 139.0243],
  [-34.7893, 139.0262],
  [-34.7804, 139.0350],
  [-34.7796, 139.0361],
  [-34.7784, 139.0387]
];

export const mtPleasant: Line = {
  name: 'Mt Pleasant',
  state: 'SA',
  segments: [
    {
      segments: [balhannahMyPleasant],
      history: {
        opened: {
          date: '1918-09-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-03-04',
          status: 'closed'
        }]
      }
    }
  ]
};
