import { Line } from "../../../trackTypes";

const line = [
  [-43.4889, 172.1104],
  [-43.4884, 172.1040],
  [-43.4836, 172.0577],
  [-43.4810, 172.0332],
  [-43.4775, 172.0151],
  [-43.4775, 172.0119],
  [-43.4784, 172.0084],
  [-43.4807, 172.0045],
  [-43.4813, 172.0023],
  [-43.4804, 171.9562],
  [-43.4801, 171.9432],
  [-43.4798, 171.9280],
  [-43.4800, 171.9263],
  [-43.4799, 171.9254],
  [-43.4788, 171.9216],
  [-43.4787, 171.9200],
  [-43.4785, 171.9193],
  [-43.4782, 171.9186],
  [-43.4780, 171.9178],
  [-43.4779, 171.9165],
  [-43.4776, 171.9158],
  [-43.4633, 171.8954],
  [-43.4626, 171.8948]
];

export const whitecliffs: Line = {
  name: 'Whitecliffs',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1875-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-03-31',
          status: 'closed'
        }]
      }
    }
  ]
}
