import { Line } from "../../trackTypes";

const line = [
  [-37.7865, 144.9288],
  [-37.7853, 144.9281],
  [-37.7849, 144.9276],
  [-37.7845, 144.9271],
  [-37.7842, 144.9263],
  [-37.7839, 144.9256],
  [-37.7837, 144.9246],
  [-37.7834, 144.9132],
  [-37.7835, 144.9121],
  [-37.7838, 144.9111],
  [-37.7842, 144.9104],
  [-37.7848, 144.9096],
  [-37.7863, 144.9086],
  [-37.7866, 144.9083],
  [-37.7880, 144.9065]
];

export const flemingtonRacecource: Line = {
  name: 'Flemington Racecourse',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1861-02-28',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1870-11-05',
          tracks: 2
        }, {
          date: '1919-05-28',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1864-07-01',
          status: 'closed'
        }, {
          date: '1867-10-31',
          status: 'open'
        }]
      }
    }
  ]
}
