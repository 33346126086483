import { Line } from "../../../trackTypes";

const oakeyAcland = [
  [-27.4281, 151.7140],
  [-27.4276, 151.7136],
  [-27.4269, 151.7134],
  [-27.3720, 151.7235],
  [-27.3704, 151.7231],
  [-27.3687, 151.7223],
  [-27.3428, 151.7042],
  [-27.3406, 151.7013],
  [-27.3391, 151.6980],
  [-27.3378, 151.6965],
  [-27.3210, 151.6861],
  [-27.3186, 151.6856],
  [-27.3033, 151.6884]
];

const aclandKulpi = [
  [-27.3033, 151.6884],
  [-27.2932, 151.6903],
  [-27.2689, 151.6882],
  [-27.2428, 151.6927],
  [-27.2230, 151.6928],
  [-27.2205, 151.6935],
  [-27.2172, 151.6953],
  [-27.2058, 151.6991],
  [-27.2036, 151.6992],
  [-27.1995, 151.6982],
  [-27.1913, 151.6990],
  [-27.1904, 151.6993],
  [-27.1880, 151.7005]
];

const kulpiPeranga = [
  [-27.1880, 151.7005],
  [-27.1868, 151.7010],
  [-27.1844, 151.7013],
  [-27.1794, 151.7003],
  [-27.1780, 151.6993],
  [-27.1727, 151.6918],
  [-27.1715, 151.6908],
  [-27.1699, 151.6905],
  [-27.1687, 151.6908],
  [-27.1668, 151.6920],
  [-27.1650, 151.6925],
  [-27.1636, 151.6925],
  [-27.1563, 151.6905],
  [-27.1543, 151.6905],
  [-27.1453, 151.6939]
];

const perangaMuntapaTunnel = [
  [-27.1453, 151.6939],
  [-27.1419, 151.6954],
  [-27.1352, 151.6991],
  [-27.1343, 151.7000],
  [-27.1316, 151.7049],
  [-27.1308, 151.7075],
  [-27.1302, 151.7085],
  [-27.1284, 151.7091],
  [-27.1270, 151.7100],
  [-27.1260, 151.7118],
  [-27.1247, 151.7131],
  [-27.1221, 151.7151],
  [-27.1211, 151.7152],
  [-27.1202, 151.7148],
  [-27.1196, 151.7140],
  [-27.1195, 151.7130],
  [-27.1205, 151.7095],
  [-27.1205, 151.7083],
  [-27.1197, 151.7073],
  [-27.1179, 151.7065],
  [-27.1165, 151.7066],
  [-27.1157, 151.7072],
  [-27.1145, 151.7087],
  [-27.1137, 151.7093],
  [-27.1125, 151.7095],
  [-27.1116, 151.7101],
  [-27.1107, 151.7119],
  [-27.1090, 151.7149],
  [-27.1083, 151.7155],
  [-27.1066, 151.7162],
  [-27.1060, 151.7166],
  [-27.1057, 151.7173],
  [-27.1055, 151.7202],
  [-27.1050, 151.7211],
  [-27.1043, 151.7213],
  [-27.1032, 151.7209],
  [-27.1025, 151.7210],
  [-27.1018, 151.7215],
  [-27.1016, 151.7221],
  [-27.1015, 151.7232],
  [-27.1010, 151.7241],
  [-27.0994, 151.7249],
  [-27.0948, 151.7300],
  [-27.0942, 151.7304],
  [-27.0932, 151.7307],
  [-27.0914, 151.7325],
  [-27.0913, 151.7331],
  [-27.0914, 151.7344],
  [-27.0911, 151.7352],
  [-27.0906, 151.7355],
  [-27.0897, 151.7353],
  [-27.0880, 151.7341],
  [-27.0873, 151.7341],
  [-27.0867, 151.7346],
  [-27.0866, 151.7352],
  [-27.0867, 151.7364],
  [-27.0864, 151.7371],
  [-27.0859, 151.7375],
  [-27.0850, 151.7377],
  [-27.0845, 151.7381],
  [-27.0843, 151.7388],
  [-27.0847, 151.7423],
  [-27.0851, 151.7435],
  [-27.0861, 151.7449],
  [-27.0862, 151.7458],
  [-27.0858, 151.7465],
  [-27.0850, 151.7471],
  [-27.0847, 151.7477],
  [-27.0846, 151.7493],
  [-27.0840, 151.7499],
  [-27.0831, 151.7500],
  [-27.0826, 151.7494],
  [-27.0820, 151.7480],
  [-27.0815, 151.7475],
  [-27.0796, 151.7466],
  [-27.0784, 151.7465],
  [-27.0770, 151.7468],
  [-27.0765, 151.7471]
];

const muntapaTunnel = [
  [-27.0765, 151.7471],
  [-27.0739, 151.7497]
];

const muntapaTunnelCooyar = [
  [-27.0739, 151.7497],
  [-27.0731, 151.7511],
  [-27.0723, 151.7582],
  [-27.0728, 151.7592],
  [-27.0735, 151.7594],
  [-27.0743, 151.7592],
  [-27.0751, 151.7596],
  [-27.0755, 151.7603],
  [-27.0752, 151.7610],
  [-27.0744, 151.7621],
  [-27.0741, 151.7630],
  [-27.0743, 151.7645],
  [-27.0739, 151.7654],
  [-27.0730, 151.7658],
  [-27.0723, 151.7656],
  [-27.0714, 151.7649],
  [-27.0696, 151.7640],
  [-27.0690, 151.7641],
  [-27.0684, 151.7647],
  [-27.0675, 151.7665],
  [-27.0666, 151.7670],
  [-27.0658, 151.7668],
  [-27.0646, 151.7660],
  [-27.0637, 151.7658],
  [-27.0629, 151.7662],
  [-27.0612, 151.7681],
  [-27.0602, 151.7685],
  [-27.0593, 151.7685],
  [-27.0562, 151.7670],
  [-27.0553, 151.7669],
  [-27.0547, 151.7671],
  [-27.0533, 151.7683],
  [-27.0525, 151.7685],
  [-27.0495, 151.7684],
  [-27.0486, 151.7688],
  [-27.0469, 151.7705],
  [-27.0465, 151.7714],
  [-27.0465, 151.7722],
  [-27.0467, 151.7735],
  [-27.0462, 151.7745],
  [-27.0457, 151.7747],
  [-27.0441, 151.7744],
  [-27.0432, 151.7744],
  [-27.0390, 151.7753],
  [-27.0371, 151.7760],
  [-27.0347, 151.7777],
  [-27.0337, 151.7780],
  [-27.0323, 151.7782],
  [-27.0316, 151.7787],
  [-27.0312, 151.7796],
  [-27.0312, 151.7810],
  [-27.0320, 151.7843],
  [-27.0320, 151.7851],
  [-27.0312, 151.7872],
  [-27.0311, 151.7882],
  [-27.0314, 151.7902],
  [-27.0312, 151.7927],
  [-27.0304, 151.7946],
  [-27.0291, 151.7959],
  [-27.0260, 151.8030],
  [-27.0237, 151.8063],
  [-27.0215, 151.8081],
  [-27.0096, 151.8149],
  [-27.0044, 151.8185],
  [-27.0029, 151.8191],
  [-27.0011, 151.8191],
  [-26.9997, 151.8187],
  [-26.9985, 151.8190],
  [-26.9979, 151.8198],
  [-26.9969, 151.8226],
  [-26.9955, 151.8282],
  [-26.9956, 151.8298]
];

export const cooyar: Line = {
  name: 'Cooyar',
  state: 'QLD',
  segments: [
    {
      segments: [oakeyAcland],
      history: {
        opened: {
          date: '1912-04-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1969-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [aclandKulpi],
      history: {
        opened: {
          date: '1912-04-29',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kulpiPeranga],
      history: {
        opened: {
          date: '1912-11-04',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        perangaMuntapaTunnel,
        {
          name: 'Muntapa Tunnel',
          segment: muntapaTunnel,
          type: 'tunnel'
        },
        muntapaTunnelCooyar
      ],
      history: {
        opened: {
          date: '1913-04-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-05-01',
          status: 'closed'
        }]
      }
    }
  ]
}
