import { Line } from "../../../trackTypes";

const line = [
  [-46.2509, 169.7874],
  [-46.2511, 169.7919],
  [-46.2527, 169.7987],
  [-46.2560, 169.8049],
  [-46.2842, 169.8432],
  [-46.2856, 169.8445],
  [-46.2868, 169.8451],
  [-46.2875, 169.8454],
  [-46.2881, 169.8461],
  [-46.2889, 169.8480],
  [-46.2904, 169.8501],
  [-46.2911, 169.8519],
  [-46.2922, 169.8536]
];

export const kaitangata: Line = {
  name: 'Kaitangata',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1876-05-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-12-30',
          status: 'closed'
        }]
      }
    }
  ]
}
