import { Line } from "../../../../trackTypes";

const normonBridge = [
  [-27.48640, 153.03542],
  [-27.48711, 153.03965],
  [-27.48743, 153.04177],
  [-27.48742, 153.04187],
  [-27.48738, 153.04195],
  [-27.48734, 153.04202],
  [-27.48584, 153.04231],
  [-27.48572, 153.04238],
  [-27.48563, 153.04245],
  [-27.48557, 153.04255],
  [-27.48549, 153.04272],
  [-27.48541, 153.04280],
  [-27.48532, 153.04283],
  [-27.48361, 153.04315],
  [-27.48324, 153.04330],
  [-27.47947, 153.04401],
  [-27.47941, 153.04410],
  [-27.47937, 153.04422],
  [-27.47963, 153.04588],
  [-27.47963, 153.04616],
  [-27.47959, 153.04643],
  [-27.47948, 153.04673],
  [-27.47935, 153.04692],
  [-27.47921, 153.04706],
  [-27.47694, 153.04911],
  [-27.47677, 153.04934],
  [-27.47660, 153.04967]
];

const hawthorneRoad = [
  [-27.47660, 153.04967],
  [-27.47604, 153.05127],
  [-27.47548, 153.05277],
  [-27.47508, 153.05411],
  [-27.47498, 153.05437],
  [-27.47481, 153.05470],
  [-27.47457, 153.05506],
  [-27.47449, 153.05522],
  [-27.47349, 153.05709],
  [-27.47340, 153.05735],
  [-27.47320, 153.05797],
  [-27.47310, 153.05825],
  [-27.47269, 153.05912]
];

const balmoralCemetery = [
  [-27.47269, 153.05912],
  [-27.47263, 153.05937],
  [-27.47260, 153.05963],
  [-27.47264, 153.06075],
  [-27.47262, 153.06107],
  [-27.47241, 153.06189],
  [-27.47199, 153.06317],
  [-27.47190, 153.06337],
  [-27.47177, 153.06351],
  [-27.47160, 153.06358],
  [-27.47141, 153.06359],
  [-27.47038, 153.06356]
];

const goulbournStreet = [
  [-27.47038, 153.06356],
  [-27.46988, 153.06360],
  [-27.46961, 153.06367],
  [-27.46910, 153.06387]
];

const oxfordStreet = [
  [-27.46910, 153.06387],
  [-27.46799, 153.06434],
  [-27.46770, 153.06442],
  [-27.46626, 153.06447],
  [-27.46592, 153.06442],
  [-27.46059, 153.06319],
  [-27.46014, 153.06316],
  [-27.45941, 153.06320],
  [-27.45787, 153.06338],
  [-27.45598, 153.06374],
  [-27.45580, 153.06375],
  [-27.45567, 153.06371],
  [-27.45429, 153.06314],
  [-27.45211, 153.06259]
];

const woolloongabbaConnector = [
  [-27.48711, 153.03965],
  [-27.48718, 153.03975],
  [-27.48728, 153.03978],
  [-27.48845, 153.03956],
  [-27.48851, 153.03949],
  [-27.48855, 153.03938],
  [-27.48834, 153.03797],
  [-27.48830, 153.03786],
  [-27.48821, 153.03771]
];

const trafalgarStreet = [
  [-27.48777, 153.03969],
  [-27.48782, 153.03962],
  [-27.48783, 153.03953],
  [-27.48756, 153.03774],
  [-27.48749, 153.03761],
  [-27.48737, 153.03752],
  [-27.48726, 153.03751],
  [-27.48715, 153.03746],
  [-27.48685, 153.03732],
  [-27.48676, 153.03722],
  [-27.48668, 153.03706]
];

export const south4: Line = {
  name: 'South 4',
  state: 'QLD',
  segments: [
    {
      segments: [normonBridge],
      history: {
        opened: {
          date: '1903-07-28',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hawthorneRoad],
      history: {
        opened: {
          date: '1924-10-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [balmoralCemetery],
      history: {
        opened: {
          date: '1925-03-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [goulbournStreet],
      history: {
        opened: {
          date: '1925-04-24',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oxfordStreet],
      history: {
        opened: {
          date: '1935-11-16',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [woolloongabbaConnector],
      history: {
        opened: {
          date: '1927-02-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [trafalgarStreet],
      history: {
        opened: {
          date: '1946-06-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    }
  ]
}
