import { Line } from "../../../trackTypes";

const paramattaWharfStart = [
  [-33.81230, 151.00043],
  [-33.81272, 151.00035],
  [-33.81282, 151.00041],
  [-33.81290, 151.00054],
  [-33.81494, 151.00933],
  [-33.81626, 151.01338],
  [-33.81637, 151.01409],
  [-33.81661, 151.01549],
  [-33.81696, 151.01746],
  [-33.81709, 151.01800],
];

const paramattaWharfShared = [
  [-33.81709, 151.01800],
  [-33.81710, 151.01804],
  [-33.81810, 151.02265],
  [-33.81824, 151.02298],
  [-33.81849, 151.02331],
  [-33.81867, 151.02345]
];

const paramattaWharfEnd = [
  [-33.81867, 151.02345],//
  [-33.82008, 151.02486],
  [-33.82035, 151.02526],
  [-33.82058, 151.02575],
  [-33.82083, 151.02662],
  [-33.82091, 151.02734],
  [-33.82092, 151.02822],
  [-33.82068, 151.04135],
  [-33.82079, 151.04225],
  [-33.82101, 151.04313],
  [-33.82157, 151.04446],
  [-33.82213, 151.04537],
  [-33.82363, 151.04684]
];

const paramattaStationStart = [
  [-33.81819, 151.00577],
  [-33.81714, 151.00374],
  [-33.81688, 151.00352],
  [-33.81677, 151.00345],
  [-33.81648, 151.00338],
  [-33.81523, 151.00292],
  [-33.81489, 151.00295]
];

const paramattaStationShared = [
  [-33.81489, 151.00295],
  [-33.81207, 151.00392],
  [-33.81149, 151.00416],
  [-33.80992, 151.00501],
  [-33.80936, 151.00520],
  [-33.80682, 151.00567],
  [-33.80562, 151.00580],
  [-33.80387, 151.00584],
  [-33.80347, 151.00579],
  [-33.80313, 151.00564],
  [-33.80140, 151.00487],
  [-33.80046, 151.00436]
];

const paramattaStationEnd = [
  [-33.80046, 151.00436],
  [-33.79936, 151.00370],
  [-33.79741, 151.00228],
  [-33.79585, 151.00129],
  [-33.79455, 151.00064],
  [-33.79382, 151.00016],
  [-33.79304, 150.99946],
  [-33.79247, 150.99884],
  [-33.7914, 150.9977]//matches rail allignment point
];

const paramattaLightRailStage1Start = [
  [-33.80775, 150.98748],
  [-33.80703, 150.98797],
  [-33.80655, 150.98827],
  [-33.80626, 150.98850],
  [-33.80435, 150.99042],
  [-33.80386, 150.99094],
  [-33.80273, 150.99209],
  [-33.80239, 150.99235],
  [-33.80232, 150.99248],
  [-33.80232, 150.99264],
  [-33.80237, 150.99276],
  [-33.80298, 150.99361],
  [-33.80305, 150.99384],
  [-33.80300, 150.99410],
  [-33.80281, 150.99455],
  [-33.80264, 150.99496],
  [-33.80197, 150.99695],
  [-33.80168, 150.99760],
  [-33.80155, 150.99800],
  [-33.80151, 150.99841],
  [-33.80145, 150.99932],
  [-33.80149, 150.99978],
  [-33.80167, 151.00041],
  [-33.80170, 151.00067],
  [-33.80161, 151.00100],
  [-33.80039, 151.00395],
  [-33.80037, 151.00419],
  [-33.80046, 151.00436]
];

const paramattaLightRailStage1Middle = [
  [-33.81489, 151.00295],
  [-33.81500, 151.00300],
  [-33.81509, 151.00313],
  [-33.81700, 151.01160],
  [-33.81699, 151.01178],
  [-33.81684, 151.01193],
  [-33.81614, 151.01213],
  [-33.81607, 151.01220],
  [-33.81601, 151.01231],
  [-33.81601, 151.01247],
  [-33.81653, 151.01408],
  [-33.81670, 151.01447],
  [-33.81684, 151.01479],
  [-33.81731, 151.01568],
  [-33.81743, 151.01598],
  [-33.81770, 151.01716],
  [-33.81770, 151.01729],
  [-33.81763, 151.01743],
  [-33.81753, 151.01751],
  [-33.81717, 151.01766],
  [-33.81710, 151.01775],
  [-33.81707, 151.01785],
  [-33.81709, 151.01800]
];

const paramattaLightRailStage1End = [
  [-33.81867, 151.02345],
  [-33.81886, 151.02380],
  [-33.81888, 151.02399],
  [-33.81884, 151.02413],
  [-33.81871, 151.02428]
];

const paramattaLightRailJunction = [
  [-33.81871, 151.02428],
  [-33.81890, 151.02422],
  [-33.81901, 151.02423],
  [-33.81911, 151.02430]
];

const paramattaLightRailDepotJunction = [
  [-33.81996, 151.03161],
  [-33.82005, 151.03253],
  [-33.82011, 151.03222],
  [-33.82018, 151.03210],
  [-33.82037, 151.03199]
];

const paramattaLightRailDepot = [
  [-33.81886, 151.02380],
  [-33.81911, 151.02430],
  [-33.81923, 151.02454],
  [-33.81926, 151.02470],
  [-33.81996, 151.03161],
  [-33.82006, 151.03185],
  [-33.82017, 151.03195],
  [-33.82037, 151.03199],
  [-33.82184, 151.03192],
  [-33.82323, 151.03173]
];

export const paramattaTrams: Line = {
  name: 'Paramatta (Trams)',
  state: 'NSW',
  segments: [
    {
      segments: [paramattaWharfStart, paramattaWharfEnd],
      history: {
        opened: {
          date: '1883-10-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1943-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paramattaWharfShared],//reopen with p1 line
      history: {
        opened: {
          date: '1883-10-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1943-03-31',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paramattaStationStart, paramattaStationEnd],
      history: {
        opened: {
          date: '1902-08-18',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1927-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paramattaStationShared],//reopen when P1 is complete
      history: {
        opened: {
          date: '1902-08-18',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram'
        },
        trackChange: [{
          date: '1927-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        paramattaLightRailStage1Start,
        paramattaLightRailStage1Middle,
        paramattaLightRailStage1End,
        paramattaLightRailJunction,
        paramattaLightRailDepot,
        paramattaLightRailDepotJunction
      ],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        }
      }
    }
  ]
};
