import { Line } from "../../../trackTypes";

const duchessDajarra = [
  [-21.3569, 139.8645],
  [-21.3628, 139.8585],
  [-21.3696, 139.8542],
  [-21.3754, 139.8485],
  [-21.3807, 139.8417],
  [-21.3824, 139.8404],
  [-21.3879, 139.8377],
  [-21.3925, 139.8331],
  [-21.3961, 139.8311],
  [-21.4124, 139.8175],
  [-21.4289, 139.8107],
  [-21.4305, 139.8093],
  [-21.4358, 139.8008],
  [-21.4586, 139.7806],
  [-21.4623, 139.7756],
  [-21.4659, 139.7726],
  [-21.4832, 139.7483],
  [-21.4838, 139.7460],
  [-21.4826, 139.7407],
  [-21.4829, 139.7388],
  [-21.4903, 139.7239],
  [-21.4948, 139.7116],
  [-21.4968, 139.7007],
  [-21.4987, 139.6963],
  [-21.5181, 139.6756],
  [-21.5201, 139.6728],
  [-21.5319, 139.6549],
  [-21.5334, 139.6534],
  [-21.5514, 139.6434],
  [-21.5540, 139.6431],
  [-21.5639, 139.6367],
  [-21.5653, 139.6361],
  [-21.5736, 139.6344],
  [-21.5885, 139.6253],
  [-21.6135, 139.6005],
  [-21.6431, 139.5801],
  [-21.6839, 139.5503],
  [-21.6848, 139.5494],
  [-21.6928, 139.5354],
  [-21.6969, 139.5104]
];

export const dajarra: Line = {
  name: 'Dajarra',
  state: 'QLD',
  segments: [
    {
      segments: [duchessDajarra],
      history: {
        opened: {
          date: '1917-04-16',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
