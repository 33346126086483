import { Line } from "../../../trackTypes";

const rangioraCust = [
  [-43.2994, 172.5974],
  [-43.3001, 172.5972],
  [-43.3006, 172.5970],
  [-43.3010, 172.5966],
  [-43.3013, 172.5961],
  [-43.3014, 172.5952],
  [-43.3020, 172.5901],
  [-43.3021, 172.5880],
  [-43.3028, 172.5833],
  [-43.3033, 172.5819],
  [-43.3042, 172.5809],
  [-43.3052, 172.5794],
  [-43.3056, 172.5785],
  [-43.3111, 172.5356],
  [-43.3128, 172.4648],
  [-43.3069, 172.4244],
  [-43.3040, 172.4111],
  [-43.3039, 172.4097],
  [-43.3040, 172.4071],
  [-43.3042, 172.4057],
  [-43.3065, 172.3994],
  [-43.3071, 172.3968],
  [-43.3076, 172.3955],
  [-43.3079, 172.3946],
  [-43.3094, 172.3861]
];

const custOxford = [
  [-43.3094, 172.3861],
  [-43.3108, 172.3775],
  [-43.3110, 172.3651],
  [-43.3105, 172.3605],
  [-43.3107, 172.3583],
  [-43.3104, 172.3567],
  [-43.3107, 172.3507],
  [-43.3104, 172.3442],
  [-43.3058, 172.2968],
  [-43.3058, 172.2959],
  [-43.3057, 172.2924],
  [-43.3051, 172.2893],
  [-43.3006, 172.2378],
  [-43.2969, 172.2173],
  [-43.2968, 172.2145],
  [-43.2963, 172.2125],
  [-43.2954, 172.2109],
  [-43.2948, 172.2088],
  [-43.2934, 172.2000],
  [-43.2932, 172.1989],
  [-43.2934, 172.1972]
];

const oxfordSheffield = [
  [-43.2934, 172.1972],
  [-43.2988, 172.1790],
  [-43.2996, 172.1761],
  [-43.2995, 172.1751],
  [-43.2976, 172.1687],
  [-43.2976, 172.1672],
  [-43.2981, 172.1653],
  [-43.3079, 172.1509],
  [-43.3084, 172.1494],
  [-43.3085, 172.1476],
  [-43.3086, 172.1451],
  [-43.3097, 172.1274],
  [-43.3084, 172.1095],
  [-43.3087, 172.1076],
  [-43.3094, 172.1061],
  [-43.3101, 172.1053],
  [-43.3190, 172.1005],
  [-43.3195, 172.0999],
  [-43.3317, 172.0827],
  [-43.3553, 172.0582],
  [-43.3569, 172.0555],
  [-43.3578, 172.0522],
  [-43.3591, 172.0504],
  [-43.3630, 172.0496],
  [-43.3668, 172.0475],
  [-43.3686, 172.0449],
  [-43.3700, 172.0440],
  [-43.3877, 172.0375],
  [-43.3897, 172.0356],
  [-43.3913, 172.0319],
  [-43.3917, 172.0281],
  [-43.3915, 172.0259],
  [-43.3909, 172.0235]
];

export const oxford: Line = {
  name: 'Oxford',
  state: 'NZ',
  segments: [
    {
      segments: [rangioraCust],
      history: {
        opened: {
          date: '1874-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-04-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [custOxford],
      history: {
        opened: {
          date: '1875-06-21',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-04-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oxfordSheffield],
      history: {
        opened: {
          date: '1884-07-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1930-07-14',
          status: 'closed'
        }]
      }
    }
  ]
}
