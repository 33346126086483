import { Line } from "../../trackTypes";

const armadalePinjarra = [
  [-32.1539, 116.0128],
  [-32.1574, 116.0121],
  [-32.1720, 116.0088],
  [-32.1737, 116.0086],
  [-32.1891, 116.0087],
  [-32.1912, 116.0084],
  [-32.1948, 116.0073],
  [-32.1965, 116.0071],
  [-32.2217, 116.0076],
  [-32.2244, 116.0072],
  [-32.2764, 115.9910],
  [-32.2784, 115.9908],
  [-32.2834, 115.9908],
  [-32.2860, 115.9903],
  [-32.3089, 115.9808],
  [-32.3110, 115.9804],
  [-32.3394, 115.9806],
  [-32.3420, 115.9799],
  [-32.3520, 115.9746],
  [-32.3536, 115.9741],
  [-32.3557, 115.9741],
  [-32.3606, 115.9758],
  [-32.3636, 115.9761],
  [-32.3721, 115.9746],
  [-32.3741, 115.9746],
  [-32.4119, 115.9828],
  [-32.4147, 115.9830],
  [-32.4286, 115.9815],
  [-32.4311, 115.9807],
  [-32.4347, 115.9785],
  [-32.4369, 115.9775],
  [-32.4661, 115.9723],
  [-32.4933, 115.9712],
  [-32.4970, 115.9717],
  [-32.5050, 115.9742],
  [-32.5079, 115.9741],
  [-32.5140, 115.9720],
  [-32.5161, 115.9703],
  [-32.5305, 115.9554],
  [-32.5408, 115.9411],
  [-32.5540, 115.9260],
  [-32.5781, 115.9051],
  [-32.6121, 115.8827],
  [-32.6145, 115.8816],
  [-32.6231, 115.8796],
  [-32.6250, 115.8794],
  [-32.6283, 115.8799]
];

const pinjarraBunbury = [
  [-32.6283, 115.8799],
  [-32.6304, 115.8801],
  [-32.6429, 115.8817],
  [-32.6444, 115.8817],
  [-32.6733, 115.8778],
  [-32.7061, 115.8698],
  [-32.7072, 115.8698],
  [-32.7204, 115.8699],
  [-32.7215, 115.8701],
  [-32.7643, 115.8821],
  [-32.8319, 115.9130],
  [-32.8344, 115.9146],
  [-32.8383, 115.9185],
  [-32.8404, 115.9196],
  [-32.8551, 115.9210],
  [-32.8583, 115.9220],
  [-32.8595, 115.9221],
  [-32.8612, 115.9219],
  [-32.8832, 115.9134],
  [-32.9040, 115.8978],
  [-32.9069, 115.8971],
  [-32.9154, 115.8973],
  [-32.9179, 115.8979],
  [-32.9279, 115.9027],
  [-32.9297, 115.9033],
  [-32.9311, 115.9034],
  [-32.9433, 115.9026],
  [-32.9447, 115.9023],
  [-32.9516, 115.9000],
  [-32.9537, 115.8996],
  [-32.9601, 115.8997],
  [-32.9611, 115.8995],
  [-32.9720, 115.8962],
  [-32.9737, 115.8960],
  [-32.9846, 115.8960],
  [-32.9863, 115.8958],
  [-32.9951, 115.8932],
  [-33.0115, 115.8913],
  [-33.0139, 115.8914],
  [-33.0639, 115.9023],
  [-33.0662, 115.9024],
  [-33.0686, 115.9018],
  [-33.0793, 115.8974],
  [-33.0892, 115.8909],
  [-33.1072, 115.8829],
  [-33.2384, 115.8437],
  [-33.2410, 115.8436],
  [-33.2457, 115.8443],
  [-33.2473, 115.8441],
  [-33.2485, 115.8437],
  [-33.2552, 115.8394],
  [-33.2655, 115.8355],
  [-33.2814, 115.8310],
  [-33.2828, 115.8305],
  [-33.2874, 115.8284],
  [-33.2917, 115.8272],
  [-33.2928, 115.8266],
  [-33.2940, 115.8255],
  [-33.2978, 115.8203],
  [-33.2992, 115.8192],
  [-33.3057, 115.8165],
  [-33.3067, 115.8159],
  [-33.3079, 115.8144],
  [-33.3085, 115.8130],
  [-33.3094, 115.8099],
  [-33.3105, 115.8073],
  [-33.3251, 115.7865],
  [-33.3261, 115.7840],
  [-33.3292, 115.7638],
  [-33.3298, 115.7612],
  [-33.3368, 115.7423],
  [-33.3399, 115.7355],
  [-33.3408, 115.7326],
  [-33.3426, 115.7219],
  [-33.3429, 115.7206],
  [-33.3508, 115.6963],
  [-33.3517, 115.6926],
  [-33.3520, 115.6878],
  [-33.3517, 115.6827],
  [-33.3517, 115.6828],
  [-33.3519, 115.6820],
  [-33.3523, 115.6812],
  [-33.3541, 115.6783],
  [-33.3545, 115.6772],
  [-33.3546, 115.6759],
  [-33.3543, 115.6745],
  [-33.3539, 115.6735],
  [-33.3526, 115.6719],
  [-33.3517, 115.6704],
  [-33.3504, 115.6674],
  [-33.3441, 115.6564]
];

const bunburyBunburyOld = [
  [-33.3441, 115.6564],
  [-33.3386, 115.6479],
  [-33.3377, 115.6471],
  [-33.3278, 115.6411],
  [-33.3242, 115.6388]
];

export const southWest: Line = {
  name: 'South West',
  state: 'WA',
  segments: [
    {
      segments: [armadalePinjarra],
      history: {
        opened: {
          date: '1893-05-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [pinjarraBunbury],
      history: {
        opened: {
          date: '1894-11-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [bunburyBunburyOld],
      history: {
        opened: {
          date: '1894-11-14',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-05-28',
          status: 'closed'
        }]
      }
    }
  ]
};
