import { Line } from "../../../trackTypes";

const cumminsMoody = [
  [-34.2616, 135.7283],
  [-34.2601, 135.7298],
  [-34.2594, 135.7311],
  [-34.2590, 135.7326],
  [-34.2591, 135.7344],
  [-34.2597, 135.7362],
  [-34.2609, 135.7378],
  [-34.2727, 135.7461],
  [-34.2744, 135.7482],
  [-34.2750, 135.7503],
  [-34.2751, 135.7585],
  [-34.2722, 135.7737],
  [-34.2714, 135.7754],
  [-34.2622, 135.7895],
  [-34.2613, 135.7916],
  [-34.2589, 135.8194],
  [-34.2580, 135.8219],
  [-34.2532, 135.8283],
  [-34.2470, 135.8319],
  [-34.2458, 135.8322],
  [-34.2311, 135.8323],
  [-34.2299, 135.8327],
  [-34.2185, 135.8395],
  [-34.2122, 135.8412],
  [-34.2114, 135.8415],
  [-34.1700, 135.8725],
  [-34.1685, 135.8742],
  [-34.1656, 135.8822],
  [-34.1646, 135.8840],
  [-34.1621, 135.8868],
  [-34.1611, 135.8887],
  [-34.1599, 135.8958],
  [-34.1598, 135.8974],
  [-34.1601, 135.8987],
  [-34.1617, 135.9025],
  [-34.1624, 135.9075],
  [-34.1624, 135.9125],
  [-34.1619, 135.9164],
  [-34.1599, 135.9215],
  [-34.1574, 135.9256],
  [-34.1563, 135.9284],
  [-34.1561, 135.9300],
  [-34.1564, 135.9337],
  [-34.1562, 135.9355],
  [-34.1525, 135.9457],
  [-34.1523, 135.9475],
  [-34.1533, 135.9567],
  [-34.1532, 135.9593],
  [-34.1518, 135.9662],
  [-34.1518, 135.9680],
  [-34.1530, 135.9729],
  [-34.1542, 135.9750],
  [-34.1584, 135.9790]
];

const moodyUngarra = [
  [-34.1584, 135.9790],
  [-34.1613, 135.9819],
  [-34.1634, 135.9855],
  [-34.1638, 135.9876],
  [-34.1641, 135.9919],
  [-34.1645, 135.9938],
  [-34.1673, 136.0026],
  [-34.1699, 136.0078],
  [-34.1704, 136.0096],
  [-34.1707, 136.0142],
  [-34.1717, 136.0172],
  [-34.1745, 136.0213],
  [-34.1826, 136.0267],
  [-34.1839, 136.0283],
  [-34.1845, 136.0304],
  [-34.1850, 136.0379],
  [-34.1844, 136.0403],
  [-34.1817, 136.0449],
  [-34.1808, 136.0459],
  [-34.1778, 136.0478]
];

const ungaraKimba = [
  [-34.1778, 136.0478],
  [-34.1745, 136.0500],
  [-34.1684, 136.0579],
  [-34.1661, 136.0622],
  [-34.1656, 136.0636],
  [-34.1652, 136.0667],
  [-34.1646, 136.0685],
  [-34.1636, 136.0697],
  [-34.1184, 136.1063],
  [-34.1166, 136.1068],
  [-34.1070, 136.1062],
  [-34.1056, 136.1063],
  [-34.0979, 136.1076],
  [-34.0963, 136.1084],
  [-34.0953, 136.1095],
  [-34.0933, 136.1128],
  [-34.0923, 136.1139],
  [-34.0911, 136.1145],
  [-34.0876, 136.1156],
  [-34.0862, 136.1166],
  [-34.0812, 136.1220],
  [-34.0796, 136.1228],
  [-34.0783, 136.1227],
  [-34.0769, 136.1223],
  [-34.0753, 136.1224],
  [-34.0739, 136.1233],
  [-34.0731, 136.1244],
  [-34.0726, 136.1255],
  [-34.0719, 136.1264],
  [-34.0708, 136.1271],
  [-34.0693, 136.1274],
  [-34.0683, 136.1272],
  [-34.0652, 136.1262],
  [-34.0638, 136.1262],
  [-34.0628, 136.1266],
  [-34.0554, 136.1320],
  [-34.0541, 136.1336],
  [-34.0533, 136.1347],
  [-34.0516, 136.1359],
  [-34.0500, 136.1362],
  [-34.0470, 136.1357],
  [-34.0457, 136.1359],
  [-34.0404, 136.1380],
  [-34.0391, 136.1388],
  [-34.0349, 136.1422],
  [-34.0338, 136.1435],
  [-34.0281, 136.1554],
  [-34.0250, 136.1600],
  [-34.0201, 136.1655],
  [-34.0188, 136.1665],
  [-34.0114, 136.1705],
  [-34.0101, 136.1720],
  [-34.0022, 136.1868],
  [-34.0010, 136.1882],
  [-33.9848, 136.2030],
  [-33.9835, 136.2046],
  [-33.9415, 136.2624],
  [-33.9230, 136.2805],
  [-33.8794, 136.3047],
  [-33.8710, 136.3066],
  [-33.8551, 136.3130],
  [-33.8342, 136.3171],
  [-33.8241, 136.3156],
  [-33.8032, 136.3075],
  [-33.7990, 136.3051],
  [-33.7958, 136.3023],
  [-33.7936, 136.3014],
  [-33.7833, 136.3003],
  [-33.7563, 136.3006],
  [-33.7539, 136.3002],
  [-33.7279, 136.2905],
  [-33.7115, 136.2890],
  [-33.7101, 136.2886],
  [-33.7045, 136.2861],
  [-33.7030, 136.2850],
  [-33.6874, 136.2699],
  [-33.6856, 136.2692],
  [-33.6646, 136.2686],
  [-33.6631, 136.2681],
  [-33.6440, 136.2543],
  [-33.6209, 136.2467],
  [-33.6196, 136.2459],
  [-33.6124, 136.2397],
  [-33.6112, 136.2389],
  [-33.6057, 136.2360],
  [-33.5960, 136.2280],
  [-33.5825, 136.2210],
  [-33.5808, 136.2207],
  [-33.5766, 136.2206],
  [-33.5750, 136.2199],
  [-33.5634, 136.2112],
  [-33.5060, 136.1966],
  [-33.4432, 136.1993],
  [-33.4401, 136.1992],
  [-33.4269, 136.1993],
  [-33.4249, 136.1998],
  [-33.4211, 136.2026],
  [-33.4111, 136.2053],
  [-33.4097, 136.2053],
  [-33.4085, 136.2047],
  [-33.4040, 136.2020],
  [-33.4021, 136.2013],
  [-33.3795, 136.1967],
  [-33.3774, 136.1970],
  [-33.3609, 136.2055],
  [-33.3593, 136.2070],
  [-33.3374, 136.2445],
  [-33.3354, 136.2464],
  [-33.3128, 136.2559],
  [-33.2861, 136.2791],
  [-33.2781, 136.2831],
  [-33.2588, 136.2980],
  [-33.2554, 136.2991],
  [-33.2532, 136.2989],
  [-33.2461, 136.2959],
  [-33.2438, 136.2958],
  [-33.2421, 136.2967],
  [-33.2392, 136.3001],
  [-33.2385, 136.3019],
  [-33.2384, 136.3033],
  [-33.2391, 136.3091],
  [-33.2389, 136.3113],
  [-33.2384, 136.3125],
  [-33.2233, 136.3354],
  [-33.2136, 136.3520],
  [-33.2107, 136.3594],
  [-33.2086, 136.3615],
  [-33.2040, 136.3641],
  [-33.2011, 136.3664],
  [-33.1977, 136.3704],
  [-33.1961, 136.3717],
  [-33.1891, 136.3762],
  [-33.1877, 136.3776],
  [-33.1867, 136.3789],
  [-33.1858, 136.3807],
  [-33.1848, 136.3820],
  [-33.1705, 136.3937],
  [-33.1587, 136.3998],
  [-33.1558, 136.4023],
  [-33.1444, 136.4185],
  [-33.1383, 136.4232]
];

const kimbaBuckleboo = [
  [-33.1383, 136.4232],
  [-33.1344, 136.4261],
  [-33.1328, 136.4267],
  [-33.0982, 136.4267],
  [-33.0710, 136.4213],
  [-33.0695, 136.4204],
  [-33.0685, 136.4191],
  [-33.0680, 136.4175],
  [-33.0654, 136.3914],
  [-33.0645, 136.3885],
  [-33.0633, 136.3867],
  [-33.0617, 136.3854],
  [-33.0157, 136.3561],
  [-33.0144, 136.3549],
  [-33.0136, 136.3537],
  [-33.0132, 136.3520],
  [-33.0127, 136.3464],
  [-33.0120, 136.3445],
  [-33.0088, 136.3403],
  [-33.0070, 136.3373],
  [-33.0011, 136.3238],
  [-33.0001, 136.3225],
  [-32.9985, 136.3215],
  [-32.9911, 136.3200],
  [-32.9897, 136.3193],
  [-32.9670, 136.2965],
  [-32.9526, 136.2852],
  [-32.9405, 136.2719],
  [-32.9395, 136.2703],
  [-32.9372, 136.2651],
  [-32.9369, 136.2627],
  [-32.9376, 136.2508],
  [-32.9373, 136.2483],
  [-32.9354, 136.2440],
  [-32.9310, 136.2221],
  [-32.9296, 136.2190],
  [-32.9181, 136.2074],
  [-32.9178, 136.2063],
  [-32.9176, 136.2052]
];

export const buckleboo: Line = {
  name: 'Buckleboo',
  state: 'SA',
  segments: [
    {
      segments: [cumminsMoody],
      history: {
        opened: {
          date: '1912-08-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [moodyUngarra],
      history: {
        opened: {
          date: '1913-03-31',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kimbaBuckleboo],
      history: {
        opened: {
          date: '1926-08-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ungaraKimba],
      history: {
        opened: {
          date: '1913-07-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2005-03-01',
          status: 'closed'
        }]
      }
    }
  ]
};
