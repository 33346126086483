import { Line } from "../../trackTypes";

const alawoonaLoxton = [
  [-34.7350, 140.5133],
  [-34.7340, 140.5159],
  [-34.7327, 140.5178],
  [-34.7304, 140.5196],
  [-34.7157, 140.5248],
  [-34.7123, 140.5250],
  [-34.6519, 140.5101],
  [-34.6504, 140.5101],
  [-34.6489, 140.5106],
  [-34.6416, 140.5139],
  [-34.6392, 140.5143],
  [-34.6278, 140.5127],
  [-34.6264, 140.5127],
  [-34.5864, 140.5185],
  [-34.5844, 140.5192],
  [-34.5717, 140.5278],
  [-34.5699, 140.5283],
  [-34.5682, 140.5283],
  [-34.5297, 140.5232],
  [-34.5274, 140.5236],
  [-34.5222, 140.5265],
  [-34.5208, 140.5271],
  [-34.5066, 140.5300],
  [-34.5051, 140.5307],
  [-34.5040, 140.5318],
  [-34.4821, 140.5655],
  [-34.4802, 140.5674],
  [-34.4516, 140.5807],
  [-34.4502, 140.5808],
  [-34.4492, 140.5800],
  [-34.4485, 140.5788],
  [-34.4470, 140.5734]
];

export const loxton: Line = {
  name: 'Loxton',
  state: 'SA',
  segments: [
    {
      segments: [alawoonaLoxton],
      history: {
        opened: {
          date: '1914-02-13',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-01-01',
          gauge: 'standard'
        }, {
          date: '2015-06-20',
          status: 'closed'
        }]
      }
    }
  ]
};
