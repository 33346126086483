import { Line } from "../../trackTypes";

const saBorderAliceSprings = [
  [-25.9985, 133.4082],
  [-25.9729, 133.4073],
  [-25.9568, 133.4068],
  [-25.9535, 133.4070],
  [-25.8294, 133.4207],
  [-25.7992, 133.4269],
  [-25.7582, 133.4352],
  [-25.6895, 133.4413],
  [-25.6087, 133.4485],
  [-25.5600, 133.4529],
  [-25.5575, 133.4534],
  [-25.4867, 133.4748],
  [-25.4595, 133.4832],
  [-25.3584, 133.5083],
  [-25.3072, 133.5217],
  [-25.3031, 133.5222],
  [-25.2870, 133.5231],
  [-25.2657, 133.5207],
  [-25.2628, 133.5211],
  [-25.2491, 133.5252],
  [-25.2386, 133.5300],
  [-25.2127, 133.5366],
  [-25.1915, 133.5405],
  [-25.1872, 133.5418],
  [-25.1799, 133.5451],
  [-25.1627, 133.5570],
  [-25.1596, 133.5603],
  [-25.1400, 133.5979],
  [-25.1220, 133.6257],
  [-25.1169, 133.6310],
  [-25.1125, 133.6333],
  [-25.1024, 133.6356],
  [-25.0991, 133.6354],
  [-25.0938, 133.6344],
  [-25.0868, 133.6344],
  [-25.0628, 133.6307],
  [-25.0596, 133.6307],
  [-25.0190, 133.6352],
  [-24.9934, 133.6365],
  [-24.9894, 133.6360],
  [-24.9800, 133.6338],
  [-24.9763, 133.6338],
  [-24.9736, 133.6344],
  [-24.9524, 133.6458],
  [-24.9486, 133.6467],
  [-24.9450, 133.6463],
  [-24.9179, 133.6356],
  [-24.9135, 133.6353],
  [-24.9093, 133.6356],
  [-24.9044, 133.6341],
  [-24.8997, 133.6299],
  [-24.8965, 133.6283],
  [-24.8926, 133.6284],
  [-24.8890, 133.6302],
  [-24.8781, 133.6439],
  [-24.8747, 133.6462],
  [-24.8503, 133.6583],
  [-24.8205, 133.6747],
  [-24.8166, 133.6754],
  [-24.7856, 133.6774],
  [-24.7299, 133.6839],
  [-24.7266, 133.6854],
  [-24.7013, 133.7024],
  [-24.6977, 133.7036],
  [-24.5878, 133.7145],
  [-24.5017, 133.7232],
  [-24.4987, 133.7232],
  [-24.4249, 133.7097],
  [-24.4223, 133.7096],
  [-24.4158, 133.7107],
  [-24.4126, 133.7122],
  [-24.4074, 133.7162],
  [-24.3994, 133.7212],
  [-24.3954, 133.7222],
  [-24.3811, 133.7228],
  [-24.3614, 133.7248],
  [-24.3586, 133.7247],
  [-24.3465, 133.7234],
  [-24.3424, 133.7237],
  [-24.3061, 133.7309],
  [-24.3041, 133.7314],
  [-24.3023, 133.7324],
  [-24.2944, 133.7381],
  [-24.2920, 133.7390],
  [-24.2895, 133.7404],
  [-24.2810, 133.7466],
  [-24.2784, 133.7478],
  [-24.2237, 133.7582],
  [-24.1975, 133.7636],
  [-24.1945, 133.7648],
  [-24.1924, 133.7667],
  [-24.1847, 133.7757],
  [-24.1820, 133.7779],
  [-24.1801, 133.7787],
  [-24.1697, 133.7823],
  [-24.1566, 133.7867],
  [-24.1203, 133.7930],
  [-24.0551, 133.7943],
  [-24.0397, 133.7969],
  [-24.0082, 133.8089],
  [-23.9706, 133.8232],
  [-23.9233, 133.8455],
  [-23.9193, 133.8462],
  [-23.8580, 133.8385],
  [-23.8551, 133.8387],
  [-23.8175, 133.8484],
  [-23.8152, 133.8495],
  [-23.8133, 133.8510],
  [-23.8067, 133.8586],
  [-23.8041, 133.8604],
  [-23.8016, 133.8612],
  [-23.7646, 133.8662]
];

const oldGhan = [
  [-23.7646, 133.8662],
  [-23.7615, 133.8666],
  [-23.7502, 133.8707],
  [-23.7486, 133.8709],
  [-23.7465, 133.8703],
  [-23.7347, 133.8630],
  [-23.7327, 133.8625],
  [-23.7309, 133.8627],
  [-23.7298, 133.8632],
  [-23.7264, 133.8651],
  [-23.7256, 133.8655],
  [-23.7246, 133.8657],
  [-23.7230, 133.8660],
  [-23.7209, 133.8667],
  [-23.7059, 133.8741],
  [-23.7003, 133.8764],
  [-23.6997, 133.8765],
  [-23.6991, 133.8763],
  [-23.6984, 133.8757],
  [-23.6971, 133.8734]
];

const aliceApringsKatherine = [
  [-23.6971, 133.8734],
  [-23.6855, 133.8557],
  [-23.6840, 133.8548],
  [-23.6827, 133.8548],
  [-23.6766, 133.8562],
  [-23.6751, 133.8561],
  [-23.6616, 133.8513],
  [-23.6491, 133.8503],
  [-23.6469, 133.8509],
  [-23.6281, 133.8689],
  [-23.6229, 133.8721],
  [-23.6161, 133.8735],
  [-23.6124, 133.8731],
  [-23.6096, 133.8714],
  [-23.6060, 133.8691],
  [-23.5984, 133.8677],
  [-23.5966, 133.8678],
  [-23.5893, 133.8692],
  [-23.5790, 133.8680],
  [-23.5760, 133.8685],
  [-23.5639, 133.8734],
  [-23.5595, 133.8736],
  [-23.4420, 133.8353],
  [-23.2791, 133.7829],
  [-23.2108, 133.7614],
  [-23.2071, 133.7608],
  [-23.0185, 133.7743],
  [-22.8141, 133.8132],
  [-21.9938, 133.9688],
  [-21.9460, 133.9868],
  [-21.9425, 133.9890],
  [-21.9402, 133.9926],
  [-21.9159, 134.0591],
  [-21.8701, 134.1420],
  [-21.8650, 134.1461],
  [-21.8223, 134.1553],
  [-21.8062, 134.1629],
  [-21.7076, 134.2139],
  [-21.7028, 134.2145],
  [-21.6986, 134.2129],
  [-21.6934, 134.2094],
  [-21.6883, 134.2085],
  [-21.6435, 134.2146],
  [-21.6379, 134.2146],
  [-21.6016, 134.2093],
  [-21.5983, 134.2097],
  [-21.5511, 134.2372],
  [-21.5472, 134.2384],
  [-21.3789, 134.2481],
  [-21.3536, 134.2442],
  [-21.3082, 134.2295],
  [-21.1767, 134.2243],
  [-20.9685, 134.2037],
  [-20.7705, 134.2038],
  [-20.7424, 134.2013],
  [-20.6602, 134.2065],
  [-20.6547, 134.2054],
  [-20.5995, 134.1838],
  [-20.5907, 134.1834],
  [-20.5724, 134.1896],
  [-20.5610, 134.1981],
  [-20.5580, 134.1992],
  [-20.5237, 134.2020],
  [-20.5170, 134.2060],
  [-20.5149, 134.2089],
  [-20.5065, 134.2368],
  [-20.5023, 134.2420],
  [-20.4819, 134.2500],
  [-20.4751, 134.2502],
  [-20.3285, 134.2321],
  [-20.3234, 134.2300],
  [-20.2870, 134.2058],
  [-20.2818, 134.2040],
  [-20.1977, 134.1967],
  [-20.1362, 134.1912],
  [-20.0116, 134.1833],
  [-19.9513, 134.1785],
  [-19.9460, 134.1796],
  [-19.9268, 134.1900],
  [-19.9212, 134.1909],
  [-19.6908, 134.1751],
  [-19.6850, 134.1727],
  [-19.6584, 134.1465],
  [-19.6172, 134.1221],
  [-19.5150, 134.0416],
  [-19.4574, 133.9925],
  [-19.4172, 133.9713],
  [-19.4129, 133.9668],
  [-19.3955, 133.9370],
  [-19.3732, 133.9141],
  [-19.3367, 133.8882],
  [-19.3335, 133.8847],
  [-19.3062, 133.8332],
  [-19.3030, 133.8300],
  [-19.2929, 133.8239],
  [-19.2424, 133.7842],
  [-19.1870, 133.7552],
  [-19.1652, 133.7497],
  [-18.9898, 133.7191],
  [-18.9590, 133.7089],
  [-18.6687, 133.5680],
  [-18.6175, 133.5342],
  [-18.4732, 133.4589],
  [-18.0946, 133.3026],
  [-17.9096, 133.2214],
  [-17.5191, 133.1256],
  [-16.8927, 132.9161],
  [-16.8854, 132.9115],
  [-16.7751, 132.7996],
  [-16.7678, 132.7964],
  [-16.6610, 132.7877],
  [-16.6548, 132.7891],
  [-16.6140, 132.8154],
  [-16.6084, 132.8168],
  [-16.5036, 132.8128],
  [-16.4477, 132.8057],
  [-16.4121, 132.8111],
  [-16.3539, 132.8043],
  [-16.3490, 132.8052],
  [-16.3097, 132.8214],
  [-16.2043, 132.8251],
  [-16.1462, 132.8161],
  [-16.0858, 132.8254],
  [-16.0363, 132.8292],
  [-15.9249, 132.7995],
  [-15.8983, 132.7960],
  [-15.8609, 132.7827],
  [-15.7681, 132.7794],
  [-15.7166, 132.7997],
  [-15.6535, 132.8054],
  [-15.5549, 132.7732],
  [-15.5466, 132.7725],
  [-15.5002, 132.7765],
  [-15.4500, 132.7701],
  [-15.4057, 132.7590],
  [-15.3497, 132.7577],
  [-15.3441, 132.7599],
  [-15.3293, 132.7725],
  [-15.3216, 132.7743],
  [-15.1930, 132.7586],
  [-15.0567, 132.7721],
  [-14.9629, 132.7523],
  [-14.8122, 132.7050],
  [-14.8086, 132.7022],
  [-14.8062, 132.6974],
  [-14.7852, 132.6153],
  [-14.7624, 132.5812],
  [-14.7429, 132.5420],
  [-14.7099, 132.5055],
  [-14.6703, 132.4349],
  [-14.6126, 132.3480],
  [-14.5413, 132.2846],
  [-14.4984, 132.2357],
  [-14.4947, 132.2286],
  [-14.4810, 132.2145],
  [-14.4753, 132.2122],
  [-14.4565, 132.2122],
  [-14.4509, 132.2133],
  [-14.4476, 132.2131],
  [-14.4283, 132.2061],
  [-14.4223, 132.2035]
];

const katherineSharedThree = [
  [-14.4223, 132.2035],
  [-14.4063, 132.1990],
  [-14.4042, 132.1978],
  [-14.3977, 132.1916],
  [-14.3829, 132.1804],
  [-14.3675, 132.1667],
  [-14.3635, 132.1606],
  [-14.3544, 132.1508],
  [-14.3487, 132.1462],
  [-14.3447, 132.1406],
  [-14.3390, 132.1345],
  [-14.3317, 132.1309],
  [-14.3298, 132.1292],
  [-14.3266, 132.1244],
  [-14.3100, 132.1108],
  [-14.3089, 132.1093],
  [-14.3074, 132.1070],
  [-14.3053, 132.1056],
  [-14.3028, 132.1052],
  [-14.2964, 132.1063],
  [-14.2941, 132.1060],
  [-14.2922, 132.1051],
  [-14.2589, 132.0782],
  [-14.2515, 132.0713],
  [-14.2447, 132.0669],
  [-14.2433, 132.0653],
  [-14.2420, 132.0622],
  [-14.2391, 132.0595],
  [-14.2377, 132.0574],
  [-14.2356, 132.0559],
  [-14.2275, 132.0538],
  [-14.2025, 132.0448],
  [-14.1929, 132.0403],
  [-14.1852, 132.0382],
  [-14.1820, 132.0359],
  [-14.1625, 132.0169],
  [-14.1601, 132.0155],
  [-14.1476, 132.0104],
  [-14.1366, 132.0045],
  [-14.1310, 132.0034],
  [-14.1187, 131.9992],
  [-14.1166, 131.9988],
  [-14.1153, 131.9982],
  [-14.1065, 131.9916],
  [-14.1047, 131.9906],
  [-14.1003, 131.9894],
  [-14.0983, 131.9893],
  [-14.0904, 131.9904],
  [-14.0862, 131.9901],
  [-14.0830, 131.9904],
  [-14.0816, 131.9901],
  [-14.0805, 131.9893],
  [-14.0744, 131.9820],
  [-14.0722, 131.9786],
  [-14.0690, 131.9762]
];

const newAllignmentOne = [
  [-14.0690, 131.9762],
  [-14.0680, 131.9760],
  [-14.0658, 131.9759],
  [-14.0640, 131.9750],
  [-14.0628, 131.9733],
  [-14.0623, 131.9710],
  [-14.0619, 131.9699],
  [-14.0609, 131.9688],
  [-14.0571, 131.9662],
  [-14.0505, 131.9631]
];

const sharedThreeSharedTwo = [
  [-14.0505, 131.9631],
  [-14.0450, 131.9603],
  [-14.0435, 131.9585],
  [-14.0404, 131.9526],
  [-14.0391, 131.9513],
  [-14.0357, 131.9492],
  [-14.0342, 131.9478],
  [-14.0309, 131.9426],
  [-14.0294, 131.9413],
  [-14.0185, 131.9373],
  [-14.0161, 131.9368],
  [-14.0064, 131.9361],
  [-13.9998, 131.9343],
  [-13.9857, 131.9241],
  [-13.9850, 131.9237]
];

const newAllignmentTwo = [
  [-13.9850, 131.9237],
  [-13.9798, 131.9215],
  [-13.9633, 131.9119],
  [-13.9587, 131.9098],
  [-13.9508, 131.9043]
];

const shareredTwoPineCreek = [
  [-13.9508, 131.9043],
  [-13.9080, 131.8755],
  [-13.8875, 131.8627],
  [-13.8598, 131.8499],
  [-13.8536, 131.8487]
];

const pineCreekNewAllignment = [
  [-13.8536, 131.8487],
  [-13.8262, 131.8420],
  [-13.8197, 131.8415],
  [-13.8173, 131.8407],
  [-13.8082, 131.8354],
  [-13.7761, 131.8200],
  [-13.7618, 131.8107],
  [-13.7598, 131.8088],
  [-13.7521, 131.7964],
  [-13.7509, 131.7952],
  [-13.7343, 131.7817],
  [-13.7259, 131.7765],
  [-13.7111, 131.7734],
  [-13.6997, 131.7678],
  [-13.6965, 131.7672],
  [-13.6813, 131.7665],
  [-13.6660, 131.7682]
];

const shared = [
  [-13.6660, 131.7682],
  [-13.6620, 131.7684],
  [-13.6598, 131.7683],
  [-13.6550, 131.7664],
  [-13.6510, 131.7625],
  [-13.6288, 131.7501],
  [-13.6266, 131.7476],
  [-13.6250, 131.7441],
  [-13.6237, 131.7424],
  [-13.6088, 131.7343],
  [-13.6060, 131.7340],
  [-13.6042, 131.7334],
  [-13.5903, 131.7215],
  [-13.5887, 131.7209],
  [-13.5871, 131.7208],
  [-13.5845, 131.7217],
  [-13.5833, 131.7218],
  [-13.5813, 131.7218],
  [-13.5790, 131.7223],
  [-13.5774, 131.7224],
  [-13.5679, 131.7204],
  [-13.5615, 131.7172],
  [-13.5569, 131.7164],
  [-13.5495, 131.7138],
  [-13.5468, 131.7119],
  [-13.5330, 131.6968],
  [-13.5288, 131.6900],
  [-13.5240, 131.6861],
  [-13.5230, 131.6848],
  [-13.5056, 131.6538],
  [-13.4855, 131.5923],
  [-13.4852, 131.5902],
  [-13.4856, 131.5859],
  [-13.4852, 131.5838],
  [-13.4841, 131.5807],
  [-13.4745, 131.5156],
  [-13.4725, 131.5013],
  [-13.4691, 131.4353],
  [-13.4694, 131.4276],
  [-13.4683, 131.4223],
  [-13.4663, 131.4176],
  [-13.4637, 131.4136],
  [-13.4596, 131.4044],
  [-13.4570, 131.4012],
  [-13.4543, 131.3958],
  [-13.4534, 131.3902],
  [-13.4516, 131.3861],
  [-13.4478, 131.3806],
  [-13.4430, 131.3667]
];

const bridgeCreekNewAllignment = [
  [-13.3980, 131.3026],
  [-13.4026, 131.3136],
  [-13.4091, 131.3244],
  [-13.4190, 131.3347],
  [-13.4210, 131.3378],
  [-13.4229, 131.3440],
  [-13.4246, 131.3468],
  [-13.4408, 131.3632],
  [-13.4430, 131.3667]
];

const sharedTwo = [
  [-13.3980, 131.3026],
  [-13.3821, 131.2852],
  [-13.3758, 131.2752],
  [-13.3743, 131.2703],
  [-13.3716, 131.2668],
  [-13.3646, 131.2623],
  [-13.3499, 131.2466],
  [-13.3486, 131.2434],
  [-13.3469, 131.2212],
  [-13.3445, 131.2167],
  [-13.3401, 131.2125],
  [-13.3386, 131.2096],
  [-13.3376, 131.2005],
  [-13.3361, 131.1936],
  [-13.3351, 131.1918],
  [-13.3259, 131.1814],
  [-13.3204, 131.1773],
  [-13.2832, 131.1557],
  [-13.2555, 131.1358],
  [-13.2478, 131.1246],
  [-13.2420, 131.1093],
  [-13.2378, 131.1032]
];

const adelaideRiverDarwin = [
  [-13.2378, 131.1032],
  [-13.2332, 131.0998],
  [-13.2298, 131.0991],
  [-13.2261, 131.0994],
  [-13.2216, 131.0990],
  [-13.2020, 131.1025],
  [-13.1973, 131.1025],
  [-13.1836, 131.1002],
  [-13.1806, 131.1002],
  [-13.1658, 131.1026],
  [-13.1580, 131.1042],
  [-13.1184, 131.1066],
  [-13.1138, 131.1054],
  [-13.1053, 131.1007],
  [-13.0879, 131.0973],
  [-13.0845, 131.0975],
  [-13.0655, 131.1040],
  [-13.0445, 131.1068],
  [-13.0392, 131.1097],
  [-13.0224, 131.1164],
  [-12.9985, 131.1171],
  [-12.9701, 131.1154],
  [-12.9657, 131.1158],
  [-12.9451, 131.1223],
  [-12.9207, 131.1356],
  [-12.9169, 131.1366],
  [-12.8737, 131.1400],
  [-12.8629, 131.1420],
  [-12.8599, 131.1419],
  [-12.8569, 131.1406],
  [-12.8516, 131.1373],
  [-12.8492, 131.1362],
  [-12.8333, 131.1322],
  [-12.8259, 131.1319],
  [-12.8228, 131.1310],
  [-12.8141, 131.1255],
  [-12.7878, 131.1055],
  [-12.7858, 131.1044],
  [-12.7717, 131.1004],
  [-12.7599, 131.1003],
  [-12.7568, 131.0993],
  [-12.7392, 131.0902],
  [-12.7365, 131.0893],
  [-12.7120, 131.0863],
  [-12.7096, 131.0854],
  [-12.7071, 131.0835],
  [-12.6849, 131.0595],
  [-12.6815, 131.0575],
  [-12.6709, 131.0538],
  [-12.6668, 131.0514],
  [-12.6562, 131.0421],
  [-12.6539, 131.0378],
  [-12.6502, 131.0192],
  [-12.6459, 131.0106],
  [-12.6406, 131.0052],
  [-12.6331, 131.0011],
  [-12.6148, 130.9934],
  [-12.6106, 130.9893],
  [-12.6075, 130.9832],
  [-12.6041, 130.9787],
  [-12.5983, 130.9749],
  [-12.5753, 130.9679],
  [-12.5606, 130.9664],
  [-12.5568, 130.9672],
  [-12.5512, 130.9704],
  [-12.5468, 130.9734],
  [-12.5375, 130.9788],
  [-12.5330, 130.9792],
  [-12.5294, 130.9775],
  [-12.5146, 130.9635],
  [-12.5096, 130.9586],
  [-12.5055, 130.9561],
  [-12.5005, 130.9551],
  [-12.4945, 130.9560],
  [-12.4826, 130.9623],
  [-12.4760, 130.9635],
  [-12.4735, 130.9636],
  [-12.4682, 130.9623],
  [-12.4650, 130.9596],
  [-12.4596, 130.9504],
  [-12.4590, 130.9459],
  [-12.4619, 130.9322],
  [-12.4727, 130.9030],
  [-12.4740, 130.9005],
  [-12.4762, 130.8981],
  [-12.4885, 130.8888],
  [-12.4895, 130.8877],
  [-12.4901, 130.8859],
  [-12.4900, 130.8843],
  [-12.4894, 130.8828],
  [-12.4878, 130.8807]
];

export const adelaideDarwin: Line = {
  name: 'Adelaide - Darwin',
  state: 'NT',
  segments: [
    {
      segments: [saBorderAliceSprings],
      history: {
        opened: {
          date: '1980-10-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [oldGhan],
      history: {
        opened: {
          date: '1929-08-06',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-10-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [aliceApringsKatherine],
      history: {
        opened: {
          date: '2004-01-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [katherineSharedThree],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        },{
          date: '2004-01-17',
          gauge: 'standard',
          status: 'open'
        }]
      }
    },
    {
      segments: [newAllignmentOne],
      history: {
        opened: {
          date: '2004-01-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [sharedThreeSharedTwo],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }, {
          date: '2004-01-17',
          gauge: 'standard',
          status: 'open'
        }]
      }
    },
    {
      segments: [newAllignmentTwo],
      history: {
        opened: {
          date: '2004-01-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [shareredTwoPineCreek],
      history: {
        opened: {
          date: '1917-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }, {
          date: '2004-01-17',
          gauge: 'standard',
          status: 'open'
        }]
      }
    },
    {
      segments: [pineCreekNewAllignment],
      history: {
        opened: {
          date: '2004-01-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [shared],
      history: {
        opened: {
          date: '1889-09-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        }, {
          date: '2004-01-17',
          gauge: 'standard',
          status: 'open'
        }]
      }
    },
    {
      segments: [bridgeCreekNewAllignment],
      history: {
        opened: {
          date: '2004-01-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [sharedTwo],
      history: {
        opened: {
          date: '1889-09-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-06-30',
          status: 'closed'
        },{
          date: '2004-01-17',
          gauge: 'standard',
          status: 'open'
        }]
      }
    },
    {
      segments: [adelaideRiverDarwin],
      history: {
        opened: {
          date: '2004-01-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
