import { Line } from "../../../trackTypes";

const lidcombeNumberOneMortuaryStation = [
  [-33.8643, 151.0476],
  [-33.8649, 151.0491],
  [-33.8659, 151.0503],
  [-33.8670, 151.0509],
  [-33.8681, 151.0510],
  [-33.8693, 151.0509]
];

const numberOneMortuaryStationNumberThreeMortuaryStation = [
  [-33.8693, 151.0509],
  [-33.8699, 151.0511],
  [-33.8706, 151.0511],
  [-33.8736, 151.0521],
  [-33.8751, 151.0523],
  [-33.8770, 151.0521],
  [-33.8780, 151.0524],
  [-33.8787, 151.0534],
  [-33.8789, 151.0552]
];

const numberThreeMortuaryStationNumberFourMortuaryStation = [
  [-33.8789, 151.0552],
  [-33.8789, 151.0565],
  [-33.8783, 151.0581],
  [-33.8768, 151.0594],
  [-33.8715, 151.0603],
  [-33.8702, 151.0601]
];

export const rookwoodCemetery: Line = {
  name: 'Rookwood Cemetery',
  state: 'NSW',
  segments: [
    {
      segments: [lidcombeNumberOneMortuaryStation],
      history: {
        opened: {
          date: '1864-10-22',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [numberOneMortuaryStationNumberThreeMortuaryStation],
      history: {
        opened: {
          date: '1897-05-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [numberThreeMortuaryStationNumberFourMortuaryStation],
      history: {
        opened: {
          date: '1908-06-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1948-12-29',
          status: 'closed'
        }]
      }
    }
  ]
};
