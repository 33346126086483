import { Line } from "../../../trackTypes";

const line = [
  [-38.6948, 146.4660],
  [-38.6942, 146.4664],
  [-38.6843, 146.4772],
  [-38.6836, 146.4778],
  [-38.6543, 146.4839],
  [-38.6534, 146.4845],
  [-38.6520, 146.4866],
  [-38.6514, 146.4868],
  [-38.6499, 146.4865],
  [-38.6494, 146.4858],
  [-38.6472, 146.4827],
  [-38.6426, 146.4786],
  [-38.6419, 146.4783],
  [-38.6407, 146.4783]
];

export const masonMoore: Line = {
  name: 'Mason and Moore Tramway',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1891-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1898-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
