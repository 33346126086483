import { Line } from "../../trackTypes";

const hanburyJunctionWallsendColliery = [
  [-32.8934, 151.7194],
  [-32.8935, 151.7177],
  [-32.8939, 151.7165],
  [-32.8946, 151.7151],
  [-32.8964, 151.7086],
  [-32.8965, 151.7067],
  [-32.8957, 151.7036],
  [-32.8956, 151.7020],
  [-32.8966, 151.6949],
  [-32.8965, 151.6838],
  [-32.8968, 151.6803],
  [-32.8980, 151.6767],
  [-32.8994, 151.6745],
  [-32.9007, 151.6734],
  [-32.9017, 151.6719],
  [-32.9046, 151.6657],
  [-32.9071, 151.6627],
  [-32.9091, 151.6615],
  [-32.9106, 151.6602],
  [-32.9115, 151.6587],
  [-32.9124, 151.6565],
  [-32.9145, 151.6543]
];

export const wallsend: Line = {
  name: 'Wallsend',
  state: 'NSW',
  segments: [
    {
      segments: [hanburyJunctionWallsendColliery],
      history: {
        opened: {
          date: '1861-01-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
