import { Line } from "../../../trackTypes";

const line = [
  [-23.5859, 148.8214],
  [-23.5867, 148.8183],
  [-23.5877, 148.8169],
  [-23.5897, 148.8156],
  [-23.5991, 148.8131],
  [-23.6230, 148.8122],
  [-23.6631, 148.8005],
  [-23.6801, 148.7875],
  [-23.6816, 148.7870],
  [-23.6962, 148.7860],
  [-23.7121, 148.7875],
  [-23.7252, 148.7861],
  [-23.7310, 148.7838],
  [-23.7494, 148.7684],
  [-23.7636, 148.7611],
  [-23.7750, 148.7494],
  [-23.7861, 148.7322],
  [-23.8047, 148.7178],
  [-23.8159, 148.7129],
  [-23.8419, 148.7091],
  [-23.8587, 148.7035],
  [-23.8613, 148.7027],
  [-23.8735, 148.7022],
  [-23.8798, 148.7007],
  [-23.8950, 148.6902],
  [-23.8983, 148.6890],
  [-23.9075, 148.6887],
  [-23.9118, 148.6900],
  [-23.9239, 148.6982],
  [-23.9300, 148.7003],
  [-23.9398, 148.7008],
  [-23.9449, 148.7033],
  [-23.9495, 148.7082],
  [-23.9535, 148.7100],
  [-23.9604, 148.7107],
  [-23.9690, 148.7132],
  [-23.9750, 148.7170],
  [-23.9918, 148.7248],
  [-23.9998, 148.7263],
  [-24.0664, 148.7243],
  [-24.1075, 148.7183],
  [-24.1111, 148.7166],
  [-24.1138, 148.7139],
  [-24.1360, 148.6783],
  [-24.1380, 148.6766],
  [-24.1412, 148.6750],
  [-24.1534, 148.6733],
  [-24.1565, 148.6719],
  [-24.1627, 148.6674],
  [-24.1662, 148.6661],
  [-24.2192, 148.6634],
  [-24.2530, 148.6570],
  [-24.2588, 148.6538],
  [-24.2627, 148.6482],
  [-24.2667, 148.6347],
  [-24.2715, 148.6276],
  [-24.2961, 148.6104],
  [-24.3030, 148.6073],
  [-24.3133, 148.6048],
  [-24.3184, 148.6015],
  [-24.3270, 148.5890],
  [-24.3421, 148.5737],
  [-24.3548, 148.5653],
  [-24.3663, 148.5528],
  [-24.3703, 148.5451],
  [-24.3767, 148.5226],
  [-24.3766, 148.5145],
  [-24.3732, 148.5069],
  [-24.3586, 148.4924],
  [-24.3556, 148.4876],
  [-24.3546, 148.4821],
  [-24.3559, 148.4763],
  [-24.3634, 148.4636],
  [-24.3663, 148.4601],
  [-24.3954, 148.4324],
  [-24.3987, 148.4308],
  [-24.4165, 148.4282],
  [-24.4215, 148.4287],
  [-24.4254, 148.4275],
  [-24.4332, 148.4207],
  [-24.4349, 148.4198],
  [-24.4378, 148.4193],
  [-24.4413, 148.4204],
  [-24.4449, 148.4241],
  [-24.4492, 148.4285],
  [-24.4498, 148.4302],
  [-24.4495, 148.4320],
  [-24.4480, 148.4332],
  [-24.4462, 148.4332],
  [-24.4447, 148.4318],
  [-24.4443, 148.4301],
  [-24.4448, 148.4288],
  [-24.4456, 148.4274],
  [-24.4457, 148.4260],
  [-24.4449, 148.4241]
];

export const bauhinia: Line = {
  name: 'Bauhinia',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2005-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-12-01',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    }
  ]
}
