import { Line } from "../../trackTypes";

const normantonHaydon = [
  [-17.6727, 141.0722],
  [-17.6754, 141.0744],
  [-17.6764, 141.0750],
  [-17.6778, 141.0753],
  [-17.6857, 141.0753],
  [-17.6868, 141.0755],
  [-17.6936, 141.0772],
  [-17.6977, 141.0776],
  [-17.7007, 141.0766],
  [-17.7067, 141.0718],
  [-17.7077, 141.0712],
  [-17.7090, 141.0712],
  [-17.7168, 141.0725],
  [-17.7203, 141.0737],
  [-17.7333, 141.0804],
  [-17.7515, 141.0936],
  [-17.7539, 141.0944],
  [-17.7558, 141.0944],
  [-17.7612, 141.0927],
  [-17.7652, 141.0926],
  [-17.7758, 141.0954],
  [-17.7783, 141.0967],
  [-17.8027, 141.1171],
  [-17.8081, 141.1194],
  [-17.8102, 141.1197],
  [-17.8141, 141.1191],
  [-17.8167, 141.1197],
  [-17.8199, 141.1218],
  [-17.8237, 141.1234],
  [-17.8429, 141.1270],
  [-17.8467, 141.1286],
  [-17.8507, 141.1313],
  [-17.8573, 141.1391],
  [-17.8583, 141.1423],
  [-17.8586, 141.1457],
  [-17.8857, 141.2216],
  [-17.8892, 141.2295],
  [-17.9701, 141.4571],
  [-17.9715, 141.4685],
  [-17.9690, 141.4866]
];

const haydonBlackbull = [
  [-17.9690, 141.4866],
  [-17.9666, 141.5038],
  [-17.9670, 141.5066],
  [-17.9678, 141.5084],
  [-17.9695, 141.5105],
  [-17.9714, 141.5153],
  [-17.9747, 141.5696],
  [-17.9735, 141.5778],
  [-17.9551, 141.6281],
  [-17.9532, 141.6347],
  [-17.9405, 141.7573]
];

const blackbullCroydon = [
  [-17.9405, 141.7573],
  [-17.9393, 141.7697],
  [-17.9395, 141.7759],
  [-17.9517, 141.8363],
  [-17.9556, 141.8455],
  [-18.0085, 141.9263],
  [-18.0436, 141.9993],
  [-18.0450, 142.0041],
  [-18.0467, 142.0075],
  [-18.0505, 142.0121],
  [-18.0528, 142.0186],
  [-18.1038, 142.1250],
  [-18.1521, 142.1949],
  [-18.1562, 142.1986],
  [-18.1707, 142.2054],
  [-18.1730, 142.2072],
  [-18.1876, 142.2276],
  [-18.1980, 142.2380],
  [-18.1988, 142.2385],
  [-18.2012, 142.2394],
  [-18.2018, 142.2394],
  [-18.2026, 142.2393]
];

export const normanton: Line = {
  name: 'Normanton',
  state: 'QLD',
  segments: [
    {
      segments: [normantonHaydon],
      history: {
        opened: {
          date: '1889-05-07',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [haydonBlackbull],
      history: {
        opened: {
          date: '1890-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [blackbullCroydon],
      history: {
        opened: {
          date: '1891-07-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
