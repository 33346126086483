import { Line } from "../../../../trackTypes";

const newFarmWharf = [
  [-27.46392, 153.03143],
  [-27.46372, 153.03144],
  [-27.46307, 153.03124],
  [-27.46282, 153.03122],
  [-27.46250, 153.03127],
  [-27.46176, 153.03120],
  [-27.46148, 153.03125],
  [-27.46091, 153.03156],
  [-27.46047, 153.03173],
  [-27.46013, 153.03182],
  [-27.45954, 153.03184],
  [-27.45937, 153.03189],
  [-27.45925, 153.03197],
  [-27.45908, 153.03210],
  [-27.45755, 153.03359],
  [-27.45752, 153.03371],
  [-27.45755, 153.03384],
  [-27.46440, 153.04255],
  [-27.46447, 153.04258],
  [-27.46453, 153.04258],
  [-27.46460, 153.04256],
  [-27.46622, 153.04099],
  [-27.46633, 153.04094],
  [-27.46642, 153.04094],
  [-27.46829, 153.04148],
  [-27.46843, 153.04154],
  [-27.47027, 153.04290],
  [-27.47029, 153.04297],
  [-27.47030, 153.04305],
  [-27.47027, 153.04312],
  [-27.46727, 153.04604],
  [-27.46705, 153.04626],
  [-27.46306, 153.05017]
];

const wharfExtension= [
  [-27.46306, 153.05017],
  [-27.46297, 153.05023],
  [-27.46286, 153.05026],
  [-27.46278, 153.05026],
  [-27.46244, 153.05024]
];

const brunswickStreet = [
  [-27.46440, 153.04255],
  [-27.46705, 153.04600],
  [-27.46707, 153.04609],
  [-27.46707, 153.04618],
  [-27.46705, 153.04626]
];

const newFarmPark = [
  [-27.46705, 153.04600],
  [-27.46727, 153.04628],
  [-27.47145, 153.05172]
];

const newFarmParkJunctions = [
  [-27.46705, 153.04600],
  [-27.46717, 153.04606],
  [-27.46727, 153.04604],
  [-27.46725, 153.04614],
  [-27.46727, 153.04628]
];

const barryParade = [
  [-27.46177, 153.03120],
  [-27.46118, 153.03099],
  [-27.46079, 153.03091],
  [-27.45926, 153.03081],
  [-27.45904, 153.03083],
  [-27.45890, 153.03089],
  [-27.45844, 153.03122],
  [-27.45822, 153.03130],
  [-27.45796, 153.03134],
  [-27.45745, 153.03133],
  [-27.45720, 153.03135],
  [-27.45625, 153.03159],
  [-27.45585, 153.03179]
];

export const east1: Line = {
  name: 'East 1',
  state: 'QLD',
  segments: [
    {
      segments: [newFarmWharf],
      history: {
        opened: {
          date: '1885-08-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1897-12-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wharfExtension, brunswickStreet],
      history: {
        opened: {
          date: '1914-11-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newFarmPark, newFarmParkJunctions],
      history: {
        opened: {
          date: '1926-02-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1969-04-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [barryParade],
      history: {
        opened: {
          date: '1927-08-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    }
  ]
}
