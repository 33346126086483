import { east1 } from "./adelaide/east1";
import { east2 } from "./adelaide/east2";
import { east3 } from "./adelaide/east3";
import { east4 } from "./adelaide/east4";
import { east5 } from "./adelaide/east5";
import { east6 } from "./adelaide/east6";
import { glenelgTrams } from "./adelaide/glenelgTrams";
import { north1 } from "./adelaide/north1";
import { north2 } from "./adelaide/north2";
import { north3 } from "./adelaide/north3";
import { north4 } from "./adelaide/north4";
import { portAdelaideTrams } from "./adelaide/portAdelaideTrams";
import { south1 } from "./adelaide/south1";
import { south2 } from "./adelaide/south2";
import { south3 } from "./adelaide/south3";
import { south4 } from "./adelaide/south4";
import { south5 } from "./adelaide/south5";
import { west1 } from "./adelaide/west1";
import { west2 } from "./adelaide/west2";
import { west3 } from "./adelaide/west3";
import { west4 } from "./adelaide/west4";

export const adelaideTrams = [
  glenelgTrams,
  portAdelaideTrams,
  east1,
  north1,
  south1,
  west1,
  south2,
  west2,
  north2,
  south3,
  east2,
  east3,
  south4,
  north3,
  north4,
  east4,
  west3,
  east5,
  east6,
  west4,
  south5
];
