import { Line } from "../../../trackTypes";

const line = [
  [-28.0766, 153.3651],
  [-28.0773, 153.3651],
  [-28.0793, 153.3648],
  [-28.0796, 153.3645],
  [-28.0812, 153.3624],
  [-28.0842, 153.3564],
  [-28.0853, 153.3526],
  [-28.0853, 153.3508],
  [-28.0840, 153.3483],
  [-28.0839, 153.3477],
  [-28.0841, 153.3472],
  [-28.0844, 153.3469],
  [-28.0847, 153.3467],
  [-28.0849, 153.3463],
  [-28.0853, 153.3453],
  [-28.0857, 153.3424],
  [-28.0867, 153.3394],
  [-28.0870, 153.3381],
  [-28.0871, 153.3364],
  [-28.0875, 153.3351],
  [-28.0876, 153.3335],
  [-28.0878, 153.3329],
  [-28.0881, 153.3323],
  [-28.0881, 153.3316],
  [-28.0878, 153.3308],
  [-28.0878, 153.3300],
  [-28.0878, 153.3287],
  [-28.0882, 153.3281],
  [-28.0891, 153.3283],
  [-28.0896, 153.3276],
  [-28.0902, 153.3270],
  [-28.0905, 153.3262],
  [-28.0902, 153.3256],
  [-28.0891, 153.3246],
  [-28.0890, 153.3238],
  [-28.0893, 153.3233],
  [-28.0899, 153.3226],
  [-28.0899, 153.3219],
  [-28.0896, 153.3215],
  [-28.0877, 153.3214],
  [-28.0873, 153.3210],
  [-28.0871, 153.3204],
  [-28.0875, 153.3190],
  [-28.0883, 153.3180],
  [-28.0906, 153.3167],
  [-28.0910, 153.3163],
  [-28.0919, 153.3150],
  [-28.0942, 153.3118],
  [-28.0955, 153.3107],
  [-28.0959, 153.3102],
  [-28.0958, 153.3095],
  [-28.0962, 153.3091],
  [-28.0967, 153.3091],
  [-28.0972, 153.3087],
  [-28.0985, 153.3063],
  [-28.0986, 153.3055],
  [-28.0980, 153.3040],
  [-28.0977, 153.3036],
  [-28.0976, 153.3029],
  [-28.0973, 153.3024],
  [-28.0972, 153.3018],
  [-28.0975, 153.3013],
  [-28.0984, 153.3012],
  [-28.0989, 153.3017],
  [-28.0994, 153.3019],
  [-28.0997, 153.3016],
  [-28.0999, 153.3008],
  [-28.1003, 153.3004],
  [-28.1009, 153.3006],
  [-28.1014, 153.3010],
  [-28.1017, 153.3020],
  [-28.1021, 153.3023],
  [-28.1024, 153.3019],
  [-28.1029, 153.3017],
  [-28.1065, 153.3032],
  [-28.1076, 153.3031],
  [-28.1086, 153.3019],
  [-28.1102, 153.2999],
  [-28.1131, 153.2983],
  [-28.1138, 153.2980],
  [-28.1165, 153.2982],
  [-28.1169, 153.2984],
  [-28.1171, 153.2988],
  [-28.1170, 153.3010],
  [-28.1172, 153.3014],
  [-28.1177, 153.3016],
  [-28.1192, 153.3015],
  [-28.1213, 153.3007],
  [-28.1218, 153.3000],
  [-28.1222, 153.2994],
  [-28.1216, 153.2972],
  [-28.1215, 153.2957]
];

export const neranwoodTram: Line = {
  name: 'Neranwood Tramway',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1923-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1928-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
