import { Line } from '../trackTypes';
import { trams } from './australianCapitalTerritory/trams';
import { canberra } from './australianCapitalTerritory/canberra';
import { christmasIsland } from './australianCapitalTerritory/christmasIsland';

export const actLines: Line[] = [
  trams,
  canberra,
  christmasIsland
];
