import { Line } from "../../trackTypes";

const dryCreekPortAdelaide = [
  [-34.8370, 138.5843],
  [-34.8379, 138.5831],
  [-34.8382, 138.5821],
  [-34.8383, 138.5804],
  [-34.8400, 138.5442],
  [-34.8406, 138.5410],
  [-34.8422, 138.5356],
  [-34.8433, 138.5317],
  [-34.8436, 138.5307]
];

const portFlats = [
  [-34.8436, 138.5307],
  [-34.8436, 138.5299],
  [-34.8433, 138.5289],
  [-34.8430, 138.5283],
  [-34.8415, 138.5270],
  [-34.8407, 138.5259],
  [-34.8375, 138.5176],
  [-34.8368, 138.5155],
  [-34.8363, 138.5149],
  [-34.8356, 138.5146],
  [-34.8351, 138.5146],
  [-34.8345, 138.5147],
  [-34.8320, 138.5161],
  [-34.8283, 138.5182],
  [-34.8211, 138.5224]
];

const portFlatsBerth1 = [
  [-34.8283, 138.5182],
  [-34.8276, 138.5182],
  [-34.8270, 138.5180],
  [-34.8266, 138.5177],
  [-34.8263, 138.5172],
  [-34.8257, 138.5154],
  [-34.8253, 138.5149],
  [-34.8247, 138.5145],
  [-34.8242, 138.5145],
  [-34.8238, 138.5146],
  [-34.8191, 138.5172]
];

const portFlatsBerth2 = [
  [-34.8320, 138.5161],
  [-34.8314, 138.5162],
  [-34.8309, 138.5161],
  [-34.8305, 138.5159],
  [-34.8300, 138.5155],
  [-34.8295, 138.5148],
  [-34.8289, 138.5144],
  [-34.8279, 138.5141],
  [-34.8272, 138.5136],
  [-34.8258, 138.5116]
];

const portDockDryCreek = [
  [-34.8489, 138.5118],
  [-34.8489, 138.5131],
  [-34.8486, 138.5142],
  [-34.8465, 138.5208],
  [-34.8436, 138.5307]
];

const portDockJunction = [
  [-34.8501, 138.5133],
  [-34.8493, 138.5134],
  [-34.8489, 138.5137],
  [-34.8486, 138.5142]
];

const maryMacKillopBridge = [
  [-34.8375, 138.5176],
  [-34.8373, 138.5161],
  [-34.8377, 138.5131],
  [-34.8384, 138.5107],
  [-34.8385, 138.5096],
  [-34.8383, 138.5082],
  [-34.8372, 138.5058]
];

const bridgeJunction = [
  [-34.8372, 138.5058],
  [-34.8362, 138.5049],
  [-34.8359, 138.5048]
];

const glanvillePelicanPoint = [
  [-34.8440, 138.4938],
  [-34.8418, 138.4940],
  [-34.8410, 138.4944],
  [-34.8405, 138.4955],
  [-34.8401, 138.4976],
  [-34.8396, 138.4994],
  [-34.8393, 138.5016],
  [-34.8388, 138.5031],
  [-34.8381, 138.5040],
  [-34.8369, 138.5047],
  [-34.8359, 138.5048]
];

const pelicanPoint = [
  [-34.8359, 138.5048],
  [-34.8353, 138.5046],
  [-34.8312, 138.5040],
  [-34.8308, 138.5040],
  [-34.8283, 138.5047],
  [-34.8248, 138.5051],
  [-34.8242, 138.5053],
  [-34.8222, 138.5066],
  [-34.8215, 138.5069],
  [-34.8184, 138.5075],
  [-34.8114, 138.5081],
  [-34.8086, 138.5081],
  [-34.8079, 138.5079],
  [-34.8028, 138.5053],
  [-34.8020, 138.5051],
  [-34.7946, 138.5048],
  [-34.7941, 138.5048],
  [-34.7922, 138.5040],
  [-34.7917, 138.5039],
  [-34.7750, 138.5043]
];

const pelicanPointLoop = [
  [-34.7750, 138.5043],
  [-34.7740, 138.5039],
  [-34.7735, 138.5034],
  [-34.7730, 138.5026],
  [-34.7726, 138.5022],
  [-34.7719, 138.5020],
  [-34.7699, 138.5020],
  [-34.7691, 138.5025],
  [-34.7686, 138.5034],
  [-34.7684, 138.5044],
  [-34.7687, 138.5054],
  [-34.7693, 138.5062],
  [-34.7702, 138.5066],
  [-34.7710, 138.5064],
  [-34.7717, 138.5059],
  [-34.7722, 138.5051],
  [-34.7727, 138.5046],
  [-34.7733, 138.5044],
  [-34.7750, 138.5043]
];

const dryCreekJunction = [
  [-34.8383, 138.5804],
  [-34.8385, 138.5814],
  [-34.8391, 138.5821],
  [-34.8395, 138.5823],
  [-34.8405, 138.5823]
];

const rosewaterLoop = [
  [-34.8436, 138.5307],
  [-34.8447, 138.5287],
  [-34.8465, 138.5275],
  [-34.8507, 138.5266],
  [-34.8515, 138.5262],
  [-34.8527, 138.5251],
  [-34.8535, 138.5231],
  [-34.8537, 138.5162],
  [-34.8536, 138.5156],
  [-34.8531, 138.5145]
];

export const dryCreek: Line = {
  name: 'Dry Creek',
  state: 'SA',
  segments: [
    {
      segments: [portFlats],
      history: {
        opened: {
          date: '1982-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'dual'
        }, {
          date: '2019-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [portFlatsBerth1],
      history: {
        opened: {
          date: '1982-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'dual'
        }, {
          date: '2019-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [portFlatsBerth2],
      history: {
        opened: {
          date: '1982-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'dual'
        }, {
          date: '2019-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [glanvillePelicanPoint],
      //birkenhead loop
      history: {
        opened: {
          date: '1982-01-01',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [portDockDryCreek, portDockJunction],
      history: {
        opened: {
          date: '1868-02-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'dual'
        }, {
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dryCreekPortAdelaide],
      history: {
        opened: {
          date: '1868-02-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'dual'
        }, {
          date: '2019-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [pelicanPoint],
      history: {
        opened: {
          date: '1982-01-01',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          tracks: 2
        }, {
          date: '2019-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [pelicanPointLoop],
      history: {
        opened: {
          date: '2008-08-01',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [maryMacKillopBridge, bridgeJunction],
      history: {
        opened: {
          date: '2008-08-01',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2019-01-01',
          gauge: 'standard'
        }]
      }
    },
    {
      segments: [rosewaterLoop],
      history: {
        opened: {
          date: '1915-11-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1982-01-01',
          gauge: 'dual'
        }, {
          date: '2008-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dryCreekJunction],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
