import { Line } from "../../../trackTypes";

const kalgoorlieBoulder = [
  [-30.74575, 121.47396],
  [-30.74607, 121.47437],
  [-30.74674, 121.47522],
  [-30.74707, 121.47556],
  [-30.74809, 121.47639],
  [-30.75158, 121.47970],
  [-30.75425, 121.48226],
  [-30.75826, 121.48610],
  [-30.75853, 121.48630],
  [-30.75878, 121.48639],
  [-30.76277, 121.48723],
  [-30.76924, 121.48887],
  [-30.76940, 121.48890],
  [-30.76963, 121.48892],
  [-30.77135, 121.48876],
  [-30.77169, 121.48874],
  [-30.78168, 121.48875],
  [-30.78205, 121.48874]
];

const hannanStreet = [
  [-30.74380, 121.47804],
  [-30.74326, 121.47772],
  [-30.74319, 121.47755],
  [-30.74318, 121.47734],
  [-30.74322, 121.47715],
  [-30.74607, 121.47403],
  [-30.74905, 121.47075],
  [-30.75275, 121.46671],
  [-30.75866, 121.46025]
];

const hannanJunction = [
  [-30.74575, 121.47396],
  [-30.74587, 121.47403],
  [-30.74598, 121.47405],
  [-30.74607, 121.47403],
  [-30.74604, 121.47420],
  [-30.74607, 121.47437]
];

const maritanaStreet = [
  [-30.74036, 121.46741],
  [-30.74575, 121.47396]
];

const collinsStreet = [
  [-30.74036, 121.46741],
  [-30.74035, 121.46726],
  [-30.74038, 121.46710],
  [-30.74865, 121.45802]
];

const lamingtonExtension = [
  [-30.74036, 121.46741],
  [-30.73868, 121.46534],
  [-30.73862, 121.46523],
  [-30.73858, 121.46511],
  [-30.73857, 121.46470],
  [-30.73854, 121.46458],
  [-30.73848, 121.46444],
  [-30.73541, 121.46068],
  [-30.73533, 121.46066],
  [-30.73524, 121.46068],
  [-30.73167, 121.46460]
];

const railwayStation = [
  [-30.74663, 121.46742],
  [-30.74903, 121.47035],
  [-30.74910, 121.47054],
  [-30.74905, 121.47075]
];

const racecourseJunction = [
  [-30.75477, 121.46884],
  [-30.75475, 121.46870],
  [-30.75477, 121.46857]
];

const kalgoorlieRacecourse = [
  [-30.75275, 121.46671],
  [-30.75286, 121.46667],
  [-30.75294, 121.46667],
  [-30.75303, 121.46669],
  [-30.75457, 121.46858],
  [-30.75468, 121.46860],
  [-30.75477, 121.46857],
  [-30.75710, 121.46607],
  [-30.75719, 121.46604],
  [-30.75732, 121.46605],
  [-30.75739, 121.46608],
  [-30.75758, 121.46600],
  [-30.75781, 121.46594],
  [-30.75859, 121.46588]
];

const southKalgoorlie = [
  [-30.75457, 121.46858],
  [-30.75477, 121.46884],
  [-30.75790, 121.47267],
  [-30.75882, 121.47375],
  [-30.75894, 121.47384],
  [-30.76532, 121.47527],
  [-30.76543, 121.47533],
  [-30.77504, 121.48130],
  [-30.77533, 121.48144],
  [-30.78170, 121.48150],
  [-30.78180, 121.48156],
  [-30.78185, 121.48166]
];

const burtStreet = [
  [-30.78184, 121.47102],
  [-30.78185, 121.48166],
  [-30.78185, 121.48852],
  [-30.78186, 121.48899],
  [-30.78185, 121.49092],
  [-30.78181, 121.49146],
  [-30.78165, 121.49277],
  [-30.78160, 121.49307],
  [-30.78152, 121.49334],
  [-30.78097, 121.49500],
  [-30.77816, 121.50010]
];

const hopkinsStreet = [
  [-30.78904, 121.48875],
  [-30.78914, 121.48869],
  [-30.78919, 121.48858],
  [-30.78922, 121.47071]
];

const southBoulderJunctions = [
  [-30.78168, 121.48875],
  [-30.78179, 121.48865],
  [-30.78185, 121.48852],
  [-30.78193, 121.48865],
  [-30.78205, 121.48874],
  [-30.78194, 121.48883],
  [-30.78186, 121.48899]
];

const kamballie = [
  [-30.78205, 121.48874],
  [-30.78904, 121.48875],
  [-30.79027, 121.48874],
  [-30.79037, 121.48883],
  [-30.79043, 121.48894],
  [-30.79041, 121.49544],
  [-30.79046, 121.49555],
  [-30.79053, 121.49560],
  [-30.79146, 121.49561],
  [-30.79157, 121.49566],
  [-30.79163, 121.49577],
  [-30.79165, 121.50023]
];

const boulderBlock = [
  [-30.75826, 121.48610],
  [-30.75922, 121.48707],
  [-30.76658, 121.49427],
  [-30.76689, 121.49451],
  [-30.76716, 121.49469],
  [-30.76734, 121.49511],
  [-30.76731, 121.49552],
  [-30.76738, 121.49580],
  [-30.76898, 121.49730],
  [-30.77316, 121.49904],
  [-30.77375, 121.49907],
  [-30.77471, 121.49915],
  [-30.77508, 121.49915],
  [-30.77521, 121.49928],
  [-30.77716, 121.50110]
];

const robertStreet = [
  [-30.75790, 121.47267],
  [-30.75791, 121.47287],
  [-30.75785, 121.47303],
  [-30.75182, 121.47965],
  [-30.75170, 121.47972],
  [-30.75158, 121.47970]
];

export const kalgoorlieTrams: Line = {
  name: 'Kalgoorlie Trams',
  state: 'WA',
  segments: [
    {
      segments: [kalgoorlieBoulder],
      history: {
        opened: {
          date: '1902-05-20',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [southKalgoorlie],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1948-10-24',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hannanStreet, hannanJunction],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [maritanaStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-01-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [collinsStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1947-05-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lamingtonExtension],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1949-01-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [railwayStation],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kalgoorlieRacecourse, racecourseJunction],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hopkinsStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kamballie, southBoulderJunctions],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1939-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [burtStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boulderBlock],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1952-03-10',
          status: 'closed'
        }]
      }
    },
    {
      segments: [robertStreet],
      history: {
        opened: {
          date: '1903-01-01',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'tram'
        },
        trackChange: [{
          date: '1905-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
