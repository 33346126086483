import { Line } from "../../../trackTypes";

const daylesfordWombatForest = [
  [-37.3411, 144.1526],
  [-37.3418, 144.1535],
  [-37.3436, 144.1559],
  [-37.3443, 144.1566],
  [-37.3452, 144.1570],
  [-37.3462, 144.1572],
  [-37.3468, 144.1571],
  [-37.3480, 144.1568],
  [-37.3486, 144.1567],
  [-37.3498, 144.1571],
  [-37.3512, 144.1581],
  [-37.3597, 144.1675],
  [-37.3606, 144.1689],
  [-37.3610, 144.1707],
  [-37.3608, 144.1723],
  [-37.3602, 144.1741],
  [-37.3600, 144.1756]
];

const wombatForestMusk = [
  [-37.3600, 144.1756],
  [-37.3608, 144.1785],
  [-37.3622, 144.1796],
  [-37.3633, 144.1802],
  [-37.3640, 144.1808],
  [-37.3650, 144.1821],
  [-37.3662, 144.1832],
  [-37.3674, 144.1849],
  [-37.3683, 144.1870],
  [-37.3700, 144.1900],
  [-37.3708, 144.1910],
  [-37.3726, 144.1929]
];

const trenthamBullarto = [
  [-37.3856, 144.3235],
  [-37.3852, 144.3198],
  [-37.3852, 144.3186],
  [-37.3852, 144.3176],
  [-37.3854, 144.3165],
  [-37.3856, 144.3157],
  [-37.3860, 144.3140],
  [-37.3895, 144.2954],
  [-37.3923, 144.2816],
  [-37.3924, 144.2804],
  [-37.3923, 144.2794],
  [-37.3920, 144.2779],
  [-37.3915, 144.2757],
  [-37.3913, 144.2747],
  [-37.3907, 144.2656],
  [-37.3911, 144.2558],
  [-37.3913, 144.2547],
  [-37.3916, 144.2540],
  [-37.3925, 144.2521],
  [-37.3931, 144.2512],
  [-37.3938, 144.2506],
  [-37.3942, 144.2504],
  [-37.3952, 144.2502],
  [-37.3964, 144.2496],
  [-37.3970, 144.2491],
  [-37.3973, 144.2487],
  [-37.3977, 144.2477],
  [-37.3987, 144.2443],
  [-37.4011, 144.2376],
  [-37.4013, 144.2363],
  [-37.4012, 144.2351],
  [-37.4009, 144.2339],
  [-37.3999, 144.2316],
  [-37.3953, 144.2240],
  [-37.3951, 144.2237],
  [-37.3949, 144.2233],
  [-37.3947, 144.2227]
];

const bullartoMusk = [
  [-37.3947, 144.2227],
  [-37.3946, 144.2219],
  [-37.3940, 144.2167],
  [-37.3937, 144.2145],
  [-37.3934, 144.2139],
  [-37.3932, 144.2135],
  [-37.3930, 144.2133],
  [-37.3916, 144.2121],
  [-37.3909, 144.2113],
  [-37.3907, 144.2112],
  [-37.3900, 144.2095],
  [-37.3896, 144.2089],
  [-37.3892, 144.2085],
  [-37.3861, 144.2062],
  [-37.3849, 144.2057],
  [-37.3845, 144.2054],
  [-37.3813, 144.2022],
  [-37.3748, 144.1957],
  [-37.3743, 144.1951],
  [-37.3726, 144.1929]
];

const carlsruheTrentham = [
  [-37.3052, 144.48237],
  [-37.3050, 144.4809],
  [-37.3051, 144.4794],
  [-37.3056, 144.4780],
  [-37.3060, 144.4772],
  [-37.3214, 144.4587],
  [-37.3345, 144.4428],
  [-37.3363, 144.4402],
  [-37.3580, 144.4054],
  [-37.3621, 144.3980],
  [-37.3680, 144.3862],
  [-37.3693, 144.3824],
  [-37.3699, 144.3790],
  [-37.3734, 144.3540],
  [-37.3742, 144.3507],
  [-37.3758, 144.3472],
  [-37.3851, 144.3300],
  [-37.3858, 144.3282],
  [-37.3860, 144.3261],
  [-37.3856, 144.3235]
];

const rocklynDaylesford = [
  [-37.4272, 144.0533],
  [-37.4274, 144.0547],
  [-37.4281, 144.0594],
  [-37.4297, 144.0667],
  [-37.4309, 144.0743],
  [-37.4311, 144.0755],
  [-37.4323, 144.0774],
  [-37.4390, 144.0829],
  [-37.4397, 144.0841],
  [-37.4403, 144.0858],
  [-37.4403, 144.0874],
  [-37.4395, 144.0909],
  [-37.4389, 144.0921],
  [-37.4376, 144.0931],
  [-37.4363, 144.0930],
  [-37.4357, 144.0928],
  [-37.4343, 144.0922],
  [-37.4329, 144.0926],
  [-37.4320, 144.0938],
  [-37.4314, 144.0953],
  [-37.4303, 144.0966],
  [-37.4280, 144.0981],
  [-37.4273, 144.0991],
  [-37.4268, 144.1006],
  [-37.4268, 144.1020],
  [-37.4272, 144.1039],
  [-37.4271, 144.1063],
  [-37.4266, 144.1080],
  [-37.4259, 144.1087],
  [-37.4220, 144.1110],
  [-37.4204, 144.1116],
  [-37.4165, 144.1124],
  [-37.4138, 144.1126],
  [-37.4114, 144.1132],
  [-37.4102, 144.1137],
  [-37.4066, 144.1161],
  [-37.4052, 144.1177],
  [-37.4041, 144.1194],
  [-37.4035, 144.1213],
  [-37.4027, 144.1233],
  [-37.4018, 144.1249],
  [-37.4010, 144.1258],
  [-37.3970, 144.1296],
  [-37.3874, 144.1385],
  [-37.3855, 144.1397],
  [-37.3844, 144.1404],
  [-37.3819, 144.1430],
  [-37.3804, 144.1435],
  [-37.3785, 144.1435],
  [-37.3768, 144.1443],
  [-37.3757, 144.1456],
  [-37.3748, 144.1460],
  [-37.3735, 144.1458],
  [-37.3722, 144.1455],
  [-37.3707, 144.1459],
  [-37.3694, 144.1467],
  [-37.3677, 144.1479],
  [-37.3666, 144.1481],
  [-37.3649, 144.1476],
  [-37.3632, 144.1466],
  [-37.3617, 144.1462],
  [-37.3586, 144.1466],
  [-37.3547, 144.1480],
  [-37.3536, 144.1489],
  [-37.3528, 144.1504],
  [-37.3525, 144.1520],
  [-37.3523, 144.1540],
  [-37.3514, 144.1555],
  [-37.3504, 144.1564],
  [-37.3496, 144.1567],
  [-37.3489, 144.1568]
];

const northCreswickAllendale = [
  [-37.4042, 143.8829],
  [-37.4030, 143.8825],
  [-37.4021, 143.8826],
  [-37.4012, 143.8832],
  [-37.3868, 143.8995],
  [-37.3862, 143.9004],
  [-37.3796, 143.9100],
  [-37.3785, 143.9123],
  [-37.3738, 143.9282]
];

const allendaleNewlyn = [
  [-37.3738, 143.9282],
  [-37.3713, 143.9367],
  [-37.3706, 143.9398],
  [-37.3704, 143.9432],
  [-37.3720, 143.9584],
  [-37.3725, 143.9600],
  [-37.3733, 143.9612],
  [-37.3836, 143.9715],
  [-37.3862, 143.9737],
  [-37.3950, 143.9784],
  [-37.3974, 143.9799],
  [-37.4163, 143.9898]
];

const newlynRocklyn = [
  [-37.4163, 143.9898],
  [-37.4195, 143.9916],
  [-37.4211, 143.9929],
  [-37.4221, 143.9940],
  [-37.4264, 143.9992],
  [-37.4274, 144.0011],
  [-37.4280, 144.0038],
  [-37.4279, 144.0062],
  [-37.4264, 144.0222],
  [-37.4260, 144.0242],
  [-37.4236, 144.0324],
  [-37.4233, 144.0345],
  [-37.4238, 144.0413],
  [-37.4244, 144.0429],
  [-37.4265, 144.0473],
  [-37.4269, 144.0491],
  [-37.4272, 144.0533]
];

export const daylesford: Line = {
  name: 'Daylesford',
  state: 'VIC',
  segments: [
    {
      segments: [trenthamBullarto],
      history: {
        opened: {
          date: '1880-03-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-07-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bullartoMusk],
      history: {
        opened: {
          date: '1880-03-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-07-03',
          status: 'closed'
        }, {
          date: '1997-03-17',
          status: 'open'
        }]
      }
    },
    {
      segments: [daylesfordWombatForest],
      history: {
        opened: {
          date: '1880-03-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-07-03',
          status: 'closed'
        }, {
          date: '1988-01-01',
          status: 'open'
        }]
      }
    },
    {
      segments: [wombatForestMusk],
      history: {
        opened: {
          date: '1880-03-17',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-07-03',
          status: 'closed'
        }, {
          date: '1990-09-15',
          status: 'open'
        }]
      }
    },
    {
      segments: [carlsruheTrentham],
      history: {
        opened: {
          date: '1880-02-16',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-07-03',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northCreswickAllendale],
      history: {
        opened: {
          date: '1887-01-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1986-12-08',
          status: 'closed'
        }]
      }
    },
    {
      segments: [allendaleNewlyn],
      history: {
        opened: {
          date: '1887-01-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-11-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newlynRocklyn],
      history: {
        opened: {
          date: '1887-01-19',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-07-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rocklynDaylesford],
      history: {
        opened: {
          date: '1887-06-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-07-28',
          status: 'closed'
        }]
      }
    }
  ]
};
