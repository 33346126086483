import { Line } from "../../trackTypes";

const portAugustaWaBorder = [
  [-32.4936, 137.7672],
  [-32.4909, 137.7665],
  [-32.4900, 137.7667],
  [-32.4798, 137.7726],
  [-32.4782, 137.7727],
  [-32.4765, 137.7723],
  [-32.4656, 137.7659],
  [-32.4627, 137.7653],
  [-32.4419, 137.7686],
  [-32.4386, 137.7683],
  [-32.4349, 137.7664],
  [-32.4315, 137.7631],
  [-32.4298, 137.7619],
  [-32.4252, 137.7601],
  [-32.4239, 137.7599],
  [-32.4205, 137.7601],
  [-32.4186, 137.7593],
  [-32.4167, 137.7574],
  [-32.4133, 137.7511],
  [-32.4116, 137.7493],
  [-32.4094, 137.7478],
  [-32.4057, 137.7461],
  [-32.4043, 137.7451],
  [-32.4031, 137.7437],
  [-32.3980, 137.7337],
  [-32.3973, 137.7312],
  [-32.3966, 137.7233],
  [-32.3969, 137.7204],
  [-32.3980, 137.7178],
  [-32.4008, 137.7138],
  [-32.4018, 137.7113],
  [-32.4021, 137.7083],
  [-32.4016, 137.7057],
  [-32.4000, 137.7017],
  [-32.3996, 137.6997],
  [-32.3993, 137.6946],
  [-32.3980, 137.6869],
  [-32.3970, 137.6845],
  [-32.3914, 137.6774],
  [-32.3873, 137.6742],
  [-32.3857, 137.6723],
  [-32.3847, 137.6693],
  [-32.3805, 137.6498],
  [-32.3796, 137.6471],
  [-32.3651, 137.6203],
  [-32.3528, 137.6072],
  [-32.3504, 137.6054],
  [-32.2613, 137.5681],
  [-32.2593, 137.5667],
  [-32.1403, 137.4525],
  [-32.1131, 137.4364],
  [-32.1107, 137.4336],
  [-32.0989, 137.4089],
  [-32.0969, 137.4066],
  [-32.0942, 137.4054],
  [-32.0831, 137.4043],
  [-32.0711, 137.4070],
  [-32.0682, 137.4069],
  [-32.0654, 137.4054],
  [-32.0633, 137.4032],
  [-32.0605, 137.4016],
  [-32.0569, 137.4012],
  [-32.0526, 137.4022],
  [-32.0510, 137.4021],
  [-32.0492, 137.4015],
  [-32.0256, 137.3891],
  [-32.0037, 137.3822],
  [-31.9237, 137.3725],
  [-31.9207, 137.3715],
  [-31.7797, 137.2918],
  [-31.7777, 137.2901],
  [-31.7406, 137.2471],
  [-31.7181, 137.2252],
  [-31.7109, 137.2227],
  [-31.7082, 137.2203],
  [-31.7066, 137.2171],
  [-31.7051, 137.2105],
  [-31.7037, 137.2076],
  [-31.6918, 137.1907],
  [-31.6911, 137.1888],
  [-31.6900, 137.1843],
  [-31.6891, 137.1820],
  [-31.6867, 137.1787],
  [-31.6823, 137.1687],
  [-31.6800, 137.1658],
  [-31.6689, 137.1592],
  [-31.6633, 137.1536],
  [-31.6426, 137.1417],
  [-31.6309, 137.1312],
  [-31.6034, 137.1165],
  [-31.5695, 137.1037],
  [-31.5664, 137.1039],
  [-31.5638, 137.1053],
  [-31.5581, 137.1117],
  [-31.5554, 137.1127],
  [-31.5529, 137.1127],
  [-31.5464, 137.1101],
  [-31.5444, 137.1080],
  [-31.5425, 137.1048],
  [-31.5398, 137.1024],
  [-31.5305, 137.1000],
  [-31.5194, 137.0944],
  [-31.5084, 137.0904],
  [-31.4982, 137.0842],
  [-31.4925, 137.0754],
  [-31.4885, 137.0672],
  [-31.4813, 137.0576],
  [-31.4720, 137.0385],
  [-31.4682, 137.0267],
  [-31.4659, 137.0240],
  [-31.4633, 137.0228],
  [-31.4600, 137.0223],
  [-31.4573, 137.0208],
  [-31.4541, 137.0174],
  [-31.4506, 137.0156],
  [-31.4489, 137.0139],
  [-31.4478, 137.0119],
  [-31.4428, 136.9990],
  [-31.4413, 136.9971],
  [-31.4393, 136.9961],
  [-31.4190, 136.9928],
  [-31.4169, 136.9916],
  [-31.4154, 136.9897],
  [-31.4146, 136.9875],
  [-31.4140, 136.9829],
  [-31.4158, 136.9662],
  [-31.4152, 136.9625],
  [-31.4134, 136.9594],
  [-31.4111, 136.9577],
  [-31.4086, 136.9572],
  [-31.4067, 136.9575],
  [-31.4035, 136.9584],
  [-31.4011, 136.9584],
  [-31.3988, 136.9579],
  [-31.3966, 136.9580],
  [-31.3936, 136.9592],
  [-31.3908, 136.9596],
  [-31.3880, 136.9587],
  [-31.3857, 136.9564],
  [-31.3845, 136.9539],
  [-31.3841, 136.9512],
  [-31.3843, 136.9464],
  [-31.3839, 136.9436],
  [-31.3825, 136.9408],
  [-31.3809, 136.9393],
  [-31.3774, 136.9369],
  [-31.3729, 136.9353],
  [-31.3713, 136.9344],
  [-31.3592, 136.9261],
  [-31.3337, 136.9162],
  [-31.3317, 136.9144],
  [-31.3303, 136.9118],
  [-31.3282, 136.9020],
  [-31.3269, 136.8996],
  [-31.3239, 136.8971],
  [-31.3196, 136.8960],
  [-31.3173, 136.8962],
  [-31.3108, 136.8974],
  [-31.3079, 136.8972],
  [-31.3054, 136.8959],
  [-31.3037, 136.8939],
  [-31.2962, 136.8782],
  [-31.2863, 136.8493],
  [-31.2763, 136.8317],
  [-31.2735, 136.8286],
  [-31.2621, 136.8206],
  [-31.2595, 136.8178],
  [-31.2370, 136.7779],
  [-31.2343, 136.7587],
  [-31.2388, 136.7076],
  [-31.2392, 136.6804],
  [-31.2374, 136.6746],
  [-31.2174, 136.6520],
  [-31.2159, 136.6491],
  [-31.2151, 136.6462],
  [-31.2149, 136.6392],
  [-31.2154, 136.6372],
  [-31.2163, 136.6355],
  [-31.2186, 136.6333],
  [-31.2197, 136.6315],
  [-31.2200, 136.6282],
  [-31.2194, 136.6256],
  [-31.2143, 136.6200],
  [-31.2098, 136.6138],
  [-31.2059, 136.6024],
  [-31.1974, 136.5864],
  [-31.1967, 136.5848],
  [-31.1933, 136.5704],
  [-31.1916, 136.5548],
  [-31.1918, 136.5521],
  [-31.1926, 136.5496],
  [-31.2001, 136.5386],
  [-31.2011, 136.5362],
  [-31.2025, 136.5252],
  [-31.2023, 136.5170],
  [-31.2075, 136.4935],
  [-31.2085, 136.4912],
  [-31.2202, 136.4781],
  [-31.2220, 136.4745],
  [-31.2223, 136.4705],
  [-31.2215, 136.4672],
  [-31.2207, 136.4477],
  [-31.2182, 136.4356],
  [-31.2181, 136.4326],
  [-31.2225, 136.4181],
  [-31.2239, 136.4157],
  [-31.2254, 136.4132],
  [-31.2270, 136.4117],
  [-31.2288, 136.4053],
  [-31.2311, 136.4007],
  [-31.2326, 136.3958],
  [-31.2330, 136.3908],
  [-31.2328, 136.3857],
  [-31.2305, 136.3692],
  [-31.2281, 136.3621],
  [-31.2215, 136.3505],
  [-31.2204, 136.3482],
  [-31.2168, 136.3353],
  [-31.1972, 136.2966],
  [-31.1803, 136.2355],
  [-31.1801, 136.2332],
  [-31.1838, 136.2065],
  [-31.1837, 136.2045],
  [-31.1780, 136.1735],
  [-31.1662, 136.1275],
  [-31.1372, 136.0145],
  [-31.1192, 135.9449],
  [-31.1190, 135.9383],
  [-31.1062, 135.9024],
  [-31.1027, 135.8978],
  [-31.0844, 135.8877],
  [-31.0828, 135.8861],
  [-31.0749, 135.8736],
  [-31.0738, 135.8711],
  [-31.0691, 135.8471],
  [-31.0662, 135.8406],
  [-31.0583, 135.8316],
  [-31.0570, 135.8294],
  [-31.0480, 135.7969],
  [-31.0039, 135.5952],
  [-30.9635, 135.4797],
  [-30.9169, 135.3483],
  [-30.9049, 135.2871],
  [-30.9047, 135.2756],
  [-30.8982, 135.2489],
  [-30.8960, 135.1997],
  [-30.8957, 135.1978],
  [-30.8728, 135.1104],
  [-30.8572, 135.0204],
  [-30.8562, 135.0167],
  [-30.8506, 135.0059],
  [-30.8499, 135.0030],
  [-30.8500, 135.0001],
  [-30.8541, 134.9869],
  [-30.8543, 134.9845],
  [-30.8540, 134.9804],
  [-30.8614, 134.9405],
  [-30.8615, 134.9380],
  [-30.8594, 134.9265],
  [-30.8527, 134.9154],
  [-30.8368, 134.8749],
  [-30.8152, 134.8328],
  [-30.8047, 134.8182],
  [-30.8036, 134.8154],
  [-30.8013, 134.8076],
  [-30.7705, 134.7619],
  [-30.7672, 134.7555],
  [-30.7564, 134.7429],
  [-30.7236, 134.6840],
  [-30.7059, 134.6360],
  [-30.7053, 134.6329],
  [-30.7129, 134.5438],
  [-30.7138, 134.5411],
  [-30.7209, 134.5294],
  [-30.7217, 134.5276],
  [-30.7221, 134.5250],
  [-30.7203, 134.5004],
  [-30.7161, 134.4408],
  [-30.7037, 134.4081],
  [-30.7033, 134.4039],
  [-30.7087, 134.3631],
  [-30.7086, 134.3602],
  [-30.6786, 134.1946],
  [-30.6594, 134.1186],
  [-30.6592, 134.1157],
  [-30.6646, 134.0937],
  [-30.6648, 134.0915],
  [-30.6602, 134.0526],
  [-30.6593, 134.0365],
  [-30.6575, 134.0322],
  [-30.6504, 134.0264],
  [-30.6487, 134.0241],
  [-30.6479, 134.0214],
  [-30.6451, 133.9768],
  [-30.6304, 133.9006],
  [-30.6297, 133.8987],
  [-30.6239, 133.8897],
  [-30.6135, 133.8568],
  [-30.6051, 133.8125],
  [-30.6021, 133.8036],
  [-30.6011, 133.7993],
  [-30.6014, 133.7669],
  [-30.6000, 133.7501],
  [-30.5898, 133.7194],
  [-30.5862, 133.6966],
  [-30.5852, 133.6941],
  [-30.5805, 133.6870],
  [-30.5796, 133.6834],
  [-30.5830, 133.6439],
  [-30.5593, 133.5539],
  [-30.5522, 133.4901],
  [-30.5516, 133.4733],
  [-30.5531, 133.4626],
  [-30.5525, 133.4511],
  [-30.5530, 133.4368],
  [-30.5526, 133.4342],
  [-30.5488, 133.4232],
  [-30.5484, 133.4182],
  [-30.5462, 133.4126],
  [-30.5459, 133.4102],
  [-30.5464, 133.4079],
  [-30.5477, 133.4054],
  [-30.5482, 133.4038],
  [-30.5521, 133.3716],
  [-30.5482, 133.3424],
  [-30.5490, 133.3056],
  [-30.5486, 133.3031],
  [-30.5422, 133.2854],
  [-30.5418, 133.2831],
  [-30.5421, 133.2812],
  [-30.5451, 133.2729],
  [-30.5453, 133.2705],
  [-30.5448, 133.2684],
  [-30.5408, 133.2608],
  [-30.5404, 133.2595],
  [-30.5399, 133.2560],
  [-30.5420, 133.2344],
  [-30.5417, 133.2313],
  [-30.5425, 133.2285],
  [-30.5438, 133.2257],
  [-30.5443, 133.2236],
  [-30.5462, 133.1974],
  [-30.5467, 133.1951],
  [-30.5477, 133.1926],
  [-30.5482, 133.1901],
  [-30.5484, 133.1873],
  [-30.5495, 133.1843],
  [-30.5512, 133.1819],
  [-30.5521, 133.1800],
  [-30.5535, 133.1641],
  [-30.5530, 133.1524],
  [-30.5548, 133.1413],
  [-30.5545, 133.1354],
  [-30.5557, 133.1284],
  [-30.5558, 133.1225],
  [-30.5571, 133.1128],
  [-30.5571, 133.1003],
  [-30.5575, 133.0958],
  [-30.5570, 133.0919],
  [-30.5570, 133.0899],
  [-30.5646, 133.0537],
  [-30.5715, 133.0393],
  [-30.5712, 133.0353],
  [-30.5699, 133.0220],
  [-30.5698, 133.0162],
  [-30.5704, 133.0137],
  [-30.5719, 133.0114],
  [-30.5726, 133.0087],
  [-30.5721, 133.0026],
  [-30.5710, 132.9986],
  [-30.5714, 132.9784],
  [-30.5711, 132.9764],
  [-30.5693, 132.9705],
  [-30.5690, 132.9686],
  [-30.5694, 132.9652],
  [-30.5692, 132.9623],
  [-30.5677, 132.9573],
  [-30.5672, 132.9526],
  [-30.5665, 132.9504],
  [-30.5664, 132.9477],
  [-30.5669, 132.9452],
  [-30.5682, 132.9425],
  [-30.5708, 132.9333],
  [-30.5710, 132.9298],
  [-30.5704, 132.9259],
  [-30.5711, 132.9175],
  [-30.5717, 132.9150],
  [-30.5746, 132.9100],
  [-30.5752, 132.9077],
  [-30.5743, 132.8882],
  [-30.5748, 132.8840],
  [-30.5746, 132.8758],
  [-30.5756, 132.8644],
  [-30.5754, 132.8623],
  [-30.5746, 132.8591],
  [-30.5745, 132.8569],
  [-30.5761, 132.8492],
  [-30.5762, 132.8462],
  [-30.5727, 132.8360],
  [-30.5707, 132.8329],
  [-30.5669, 132.8258],
  [-30.5663, 132.8239],
  [-30.5656, 132.8149],
  [-30.5635, 132.8028],
  [-30.5572, 132.7818],
  [-30.5535, 132.7732],
  [-30.5530, 132.7710],
  [-30.5530, 132.7687],
  [-30.5534, 132.7666],
  [-30.5533, 132.7643],
  [-30.5510, 132.7580],
  [-30.5481, 132.7532],
  [-30.5441, 132.7449],
  [-30.5436, 132.7428],
  [-30.5438, 132.7374],
  [-30.5444, 132.7350],
  [-30.5459, 132.7320],
  [-30.5463, 132.7296],
  [-30.5460, 132.7276],
  [-30.5444, 132.7239],
  [-30.5433, 132.7188],
  [-30.5410, 132.7140],
  [-30.5403, 132.7118],
  [-30.5395, 132.7068],
  [-30.5386, 132.7047],
  [-30.5345, 132.7006],
  [-30.5335, 132.6988],
  [-30.5313, 132.6937],
  [-30.5284, 132.6905],
  [-30.5272, 132.6884],
  [-30.5218, 132.6640],
  [-30.5177, 132.6489],
  [-30.5176, 132.6457],
  [-30.5180, 132.6421],
  [-30.5171, 132.6377],
  [-30.5168, 132.6340],
  [-30.5150, 132.6302],
  [-30.5125, 132.6210],
  [-30.5124, 132.6194],
  [-30.5126, 132.6149],
  [-30.5118, 132.6108],
  [-30.5116, 132.6023],
  [-30.5112, 132.6001],
  [-30.5120, 132.5974],
  [-30.5098, 132.5957],
  [-30.5095, 132.5865],
  [-30.5090, 132.5845],
  [-30.5064, 132.5778],
  [-30.5029, 132.5706],
  [-30.5021, 132.5681],
  [-30.4988, 132.5508],
  [-30.4988, 132.5482],
  [-30.4995, 132.5445],
  [-30.4994, 132.5426],
  [-30.4988, 132.5403],
  [-30.4974, 132.5373],
  [-30.4969, 132.5342],
  [-30.4971, 132.5311],
  [-30.4983, 132.5268],
  [-30.4984, 132.5221],
  [-30.4957, 132.5131],
  [-30.4943, 132.4987],
  [-30.4949, 132.4938],
  [-30.4945, 132.4908],
  [-30.4895, 132.4792],
  [-30.4889, 132.4768],
  [-30.4884, 132.4604],
  [-30.4854, 132.4390],
  [-30.4854, 132.4369],
  [-30.4862, 132.4315],
  [-30.4855, 132.4217],
  [-30.4860, 132.4169],
  [-30.4857, 132.4136],
  [-30.4862, 132.4105],
  [-30.4888, 132.4037],
  [-30.4892, 132.4005],
  [-30.4881, 132.3889],
  [-30.4900, 132.3671],
  [-30.4887, 132.3549],
  [-30.4920, 132.3317],
  [-30.4910, 132.3233],
  [-30.4912, 132.3208],
  [-30.4920, 132.3186],
  [-30.4945, 132.3148],
  [-30.4955, 132.3114],
  [-30.4956, 132.3078],
  [-30.4952, 132.3059],
  [-30.4956, 132.3011],
  [-30.4971, 132.2971],
  [-30.4984, 132.2879],
  [-30.4981, 132.2846],
  [-30.4996, 132.2755],
  [-30.4995, 132.2723],
  [-30.5006, 132.2666],
  [-30.5021, 132.2628],
  [-30.5050, 132.2597],
  [-30.5063, 132.2560],
  [-30.5063, 132.2529],
  [-30.5074, 132.2469],
  [-30.5072, 132.2428],
  [-30.5081, 132.2396],
  [-30.5088, 132.2332],
  [-30.5081, 132.2263],
  [-30.5070, 132.2229],
  [-30.5066, 132.2154],
  [-30.5058, 132.2111],
  [-30.5058, 132.2083],
  [-30.5054, 132.2061],
  [-30.5041, 132.2031],
  [-30.5038, 132.2008],
  [-30.5043, 132.1940],
  [-30.5042, 132.1909],
  [-30.5047, 132.1883],
  [-30.5062, 132.1849],
  [-30.5073, 132.1776],
  [-30.5070, 132.1751],
  [-30.5037, 132.1671],
  [-30.5008, 132.1535],
  [-30.4950, 132.1433],
  [-30.4926, 132.1405],
  [-30.4885, 132.1337],
  [-30.4878, 132.1314],
  [-30.4874, 132.1285],
  [-30.4848, 132.1239],
  [-30.4836, 132.1206],
  [-30.4836, 132.1174],
  [-30.4849, 132.1131],
  [-30.4850, 132.1103],
  [-30.4842, 132.1071],
  [-30.4826, 132.1048],
  [-30.4807, 132.1027],
  [-30.4756, 132.0922],
  [-30.4754, 132.0891],
  [-30.4767, 132.0832],
  [-30.4804, 132.0776],
  [-30.4814, 132.0749],
  [-30.4815, 132.0724],
  [-30.4798, 132.0616],
  [-30.4713, 132.0269],
  [-30.4713, 132.0237],
  [-30.4719, 132.0213],
  [-30.4733, 132.0185],
  [-30.4739, 132.0149],
  [-30.4726, 132.0102],
  [-30.4725, 132.0077],
  [-30.4728, 132.0043],
  [-30.4727, 132.0022],
  [-30.4704, 131.9957],
  [-30.4701, 131.9934],
  [-30.4704, 131.9914],
  [-30.4720, 131.9872],
  [-30.4735, 131.9847],
  [-30.4742, 131.9830],
  [-30.4748, 131.9807],
  [-30.4760, 131.9786],
  [-30.4781, 131.9755],
  [-30.4790, 131.9713],
  [-30.4788, 131.9665],
  [-30.4783, 131.9644],
  [-30.4772, 131.9618],
  [-30.4767, 131.9596],
  [-30.4769, 131.9571],
  [-30.4775, 131.9548],
  [-30.4776, 131.9526],
  [-30.4770, 131.9502],
  [-30.4760, 131.9482],
  [-30.4756, 131.9468],
  [-30.4749, 131.9406],
  [-30.4748, 131.9373],
  [-30.4706, 131.9252],
  [-30.4700, 131.9215],
  [-30.4686, 131.9158],
  [-30.4683, 131.9129],
  [-30.4679, 131.9112],
  [-30.4670, 131.9090],
  [-30.4666, 131.9065],
  [-30.4669, 131.9028],
  [-30.4667, 131.8995],
  [-30.4669, 131.8977],
  [-30.4680, 131.8942],
  [-30.4683, 131.8924],
  [-30.4682, 131.8906],
  [-30.4673, 131.8870],
  [-30.4664, 131.8781],
  [-30.4658, 131.8760],
  [-30.4620, 131.8676],
  [-30.4582, 131.8505],
  [-30.4571, 131.8472],
  [-30.4569, 131.8443],
  [-30.4662, 131.7990],
  [-30.4664, 131.7969],
  [-30.4693, 131.6288],
  [-30.7636, 129.0013]
];

const portAugustaPortAugustaJunction = [
  [-32.4936, 137.7672],
  [-32.4968, 137.7682],
  [-32.4981, 137.7691],
  [-32.5022, 137.7754],
  [-32.5032, 137.7780],
  [-32.5034, 137.7804],
  [-32.5004, 137.8299]
];

const portAugustaJunctionPortPirie = [
  [-32.5004, 137.8299],
  [-32.5003, 137.8323],
  [-32.5005, 137.8337],
  [-32.5013, 137.8352],
  [-32.5025, 137.8361],
  [-32.5165, 137.8405],
  [-32.5179, 137.8413],
  [-32.5379, 137.8550],
  [-32.6271, 137.9188],
  [-32.6290, 137.9199],
  [-32.6932, 137.9424],
  [-32.6947, 137.9426],
  [-32.7028, 137.9431],
  [-32.7131, 137.9410],
  [-32.7159, 137.9411],
  [-32.7243, 137.9435],
  [-32.7281, 137.9436],
  [-32.7315, 137.9429],
  [-32.7338, 137.9428],
  [-32.7366, 137.9434],
  [-32.7419, 137.9462],
  [-32.7452, 137.9470],
  [-32.7484, 137.9470],
  [-32.7509, 137.9475],
  [-32.7673, 137.9553],
  [-32.7705, 137.9558],
  [-32.7783, 137.9550],
  [-32.7810, 137.9552],
  [-32.8026, 137.9629],
  [-32.8288, 137.9812],
  [-32.8319, 137.9823],
  [-32.9623, 137.9828],
  [-32.9645, 137.9832],
  [-32.9958, 137.9962],
  [-33.0876, 138.0523],
  [-33.0911, 138.0536],
  [-33.1512, 138.0648],
  [-33.1533, 138.0648],
  [-33.1626, 138.0617],
  [-33.1654, 138.0599],
  [-33.1895, 138.0380]
];

const portPirieJunctionWest = [
  [-33.1895, 138.0380],
  [-33.1918, 138.0360],
  [-33.1921, 138.0356],
  [-33.1923, 138.0352],
  [-33.1925, 138.0342],
  [-33.1908, 138.0202],
  [-33.1906, 138.0191]
];

const portPirieJunctionEast = [
  [-33.1895, 138.0380],
  [-33.1906, 138.0376],
  [-33.1917, 138.0377],
  [-33.1925, 138.0382],
  [-33.1930, 138.0388],
  [-33.1936, 138.0401]
];

export const transAustralian: Line = {
  name: 'Trans-Australian',
  state: 'SA',
  segments: [
    {
      segments: [portAugustaWaBorder],
      history: {
        opened: {
          date: '1917-10-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portAugustaPortAugustaJunction],
      history: {
        opened: {
          date: '1879-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1937-01-01',
          gauge: 'dual'
        }, {
          date: '1950-01-01',
          parallelStandard: true,
          gauge: 'narrow'
        }]
      }
    },
    {
      segments: [portAugustaJunctionPortPirie, portPirieJunctionWest],
      history: {
        opened: {
          date: '1937-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [portPirieJunctionEast],
      history: {
        opened: {
          date: '1969-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
