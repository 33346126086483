import { Line } from "../../trackTypes";

const line = [
  [-31.8361, 147.7130],
  [-31.8352, 147.7120],
  [-31.8342, 147.7117],
  [-31.8332, 147.7117],
  [-31.8324, 147.7122],
  [-31.8225, 147.7222],
  [-31.8070, 147.7416],
  [-31.8049, 147.7433],
  [-31.7660, 147.7669],
  [-31.7554, 147.7768],
  [-31.7545, 147.7782],
  [-31.7535, 147.7818],
  [-31.7524, 147.7834],
  [-31.7505, 147.7843],
  [-31.7479, 147.7841],
  [-31.7466, 147.7844],
  [-31.7452, 147.7853],
  [-31.7318, 147.7994],
  [-31.7232, 147.8152],
  [-31.7202, 147.8184],
  [-31.7174, 147.8197],
  [-31.7098, 147.8214],
  [-31.7060, 147.8227],
  [-31.6965, 147.8285],
  [-31.6955, 147.8293],
  [-31.6947, 147.8302]
];

export const warren: Line = {
  name: 'Warren',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1898-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
