import { Line } from "../../trackTypes";

const bullocksFlatTunnelEntrance = [
  [-36.4426, 148.4423],
  [-36.4417, 148.4433],
  [-36.4411, 148.4436],
  [-36.4404, 148.4436],
  [-36.4398, 148.4434],
  [-36.4360, 148.4412],
  [-36.4342, 148.4388],
  [-36.4333, 148.4382],
  [-36.4322, 148.4381],
  [-36.4298, 148.4383],
  [-36.4293, 148.4376],
  [-36.4293, 148.4365],
  [-36.4299, 148.4353],
  [-36.4301, 148.4343],
  [-36.4299, 148.4333],
  [-36.4294, 148.4327]
];

const tunnelPerisher = [
  [-36.4294, 148.4327],
  [-36.4210, 148.4173],
  [-36.4201, 148.4162],
  [-36.4190, 148.4156],
  [-36.4100, 148.4141],
  [-36.4088, 148.4136],
  [-36.4059, 148.4118],
  [-36.4051, 148.4118]
];

const perisherBlueCow = [
  [-36.4051, 148.4118],
  [-36.4038, 148.4117],
  [-36.4033, 148.4115],
  [-36.4016, 148.4101],
  [-36.4008, 148.4090],
  [-36.3927, 148.3956],
  [-36.3914, 148.3942],
  [-36.3906, 148.3938],
  [-36.3860, 148.3929]
];

export const skiTube: Line = {
  name: 'Ski Tube',
  state: 'NSW',
  segments: [
    {
      segments: [
        bullocksFlatTunnelEntrance,
        {
          segment: tunnelPerisher,
          type: 'tunnel'
        }
      ],
      history: {
        opened: {
          date: '1987-07-26',
          tracks: 1,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [{
        segment: perisherBlueCow,
        type: 'tunnel'
      }],
      history: {
        opened: {
          date: '1988-05-31',
          tracks: 1,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
