import { Line } from "../../trackTypes";

const roseworthyTarlee = [
  [-34.5284, 138.7473],
  [-34.5271, 138.7471],
  [-34.5256, 138.7466],
  [-34.5242, 138.7456],
  [-34.5039, 138.7268],
  [-34.5026, 138.7250],
  [-34.5019, 138.7227],
  [-34.5003, 138.7071],
  [-34.4986, 138.7008],
  [-34.4955, 138.6959],
  [-34.4904, 138.6913],
  [-34.4848, 138.6882],
  [-34.4633, 138.6790],
  [-34.4486, 138.6726],
  [-34.4464, 138.6724],
  [-34.4091, 138.6778],
  [-34.4068, 138.6787],
  [-34.4040, 138.6808],
  [-34.4015, 138.6816],
  [-34.3743, 138.6851],
  [-34.3701, 138.6849],
  [-34.3635, 138.6865],
  [-34.3609, 138.6864],
  [-34.3586, 138.6855],
  [-34.3572, 138.6841],
  [-34.3555, 138.6817],
  [-34.3535, 138.6802],
  [-34.3517, 138.6800],
  [-34.3461, 138.6815],
  [-34.3447, 138.6827],
  [-34.3438, 138.6846],
  [-34.3439, 138.6869],
  [-34.3449, 138.6890],
  [-34.3458, 138.6918],
  [-34.3456, 138.6945],
  [-34.3448, 138.6964],
  [-34.3424, 138.7010],
  [-34.3419, 138.7037],
  [-34.3423, 138.7064],
  [-34.3452, 138.7153],
  [-34.3455, 138.7191],
  [-34.3444, 138.7311],
  [-34.3434, 138.7329],
  [-34.3403, 138.7349],
  [-34.3368, 138.7352],
  [-34.3353, 138.7349],
  [-34.3319, 138.7336],
  [-34.3293, 138.7336],
  [-34.3192, 138.7325],
  [-34.3170, 138.7329],
  [-34.3108, 138.7361],
  [-34.3098, 138.7371],
  [-34.3054, 138.7454],
  [-34.3009, 138.7521],
  [-34.2974, 138.7564],
  [-34.2912, 138.7674],
  [-34.2895, 138.7689],
  [-34.2853, 138.7708],
  [-34.2831, 138.7710],
  [-34.2761, 138.7700]
];

const tarleeSaddleworth = [
  [-34.2761, 138.7700],
  [-34.2718, 138.7695],
  [-34.2707, 138.7691],
  [-34.2609, 138.7642],
  [-34.2510, 138.7576],
  [-34.2463, 138.7557],
  [-34.2444, 138.7555],
  [-34.2222, 138.7584],
  [-34.2198, 138.7582],
  [-34.2107, 138.7550],
  [-34.2086, 138.7540],
  [-34.1935, 138.7444],
  [-34.1922, 138.7439],
  [-34.1909, 138.7438],
  [-34.1873, 138.7443],
  [-34.1698, 138.7465],
  [-34.1690, 138.7465],
  [-34.1675, 138.7464],
  [-34.1666, 138.7465],
  [-34.1632, 138.7476],
  [-34.1607, 138.7487],
  [-34.1527, 138.7516],
  [-34.1356, 138.7578],
  [-34.1339, 138.7586],
  [-34.1266, 138.7629],
  [-34.1251, 138.7641],
  [-34.1206, 138.7679],
  [-34.1083, 138.7771],
  [-34.1067, 138.7778],
  [-34.1036, 138.7777],
  [-34.1020, 138.7772],
  [-34.0993, 138.7758],
  [-34.0968, 138.7755],
  [-34.0947, 138.7761],
  [-34.0900, 138.7792],
  [-34.0891, 138.7801],
  [-34.0861, 138.7842]
];

const saddleworthManoora = [
  [-34.0861, 138.7842],
  [-34.0754, 138.7986],
  [-34.0675, 138.8093],
  [-34.0664, 138.8113],
  [-34.0633, 138.8188],
  [-34.0618, 138.8239],
  [-34.0603, 138.8263],
  [-34.0584, 138.8279],
  [-34.0514, 138.8310],
  [-34.0493, 138.8311],
  [-34.0438, 138.8300],
  [-34.0427, 138.8296],
  [-34.0394, 138.8276],
  [-34.0353, 138.8231],
  [-34.0344, 138.8224],
  [-34.0312, 138.8210],
  [-34.0018, 138.8190]
];

const manooraBurra = [
  [-34.0018, 138.8190],
  [-33.9994, 138.8189],
  [-33.9986, 138.8187],
  [-33.9906, 138.8152],
  [-33.9885, 138.8148],
  [-33.9718, 138.8140],
  [-33.9619, 138.8107],
  [-33.9428, 138.8066],
  [-33.9413, 138.8059],
  [-33.9234, 138.7970],
  [-33.9043, 138.7874],
  [-33.8656, 138.7780],
  [-33.8635, 138.7781],
  [-33.8619, 138.7787],
  [-33.8108, 138.8067],
  [-33.7567, 138.8363],
  [-33.7392, 138.8459],
  [-33.7373, 138.8477],
  [-33.7038, 138.8956],
  [-33.7021, 138.8974],
  [-33.6792, 138.9135],
  [-33.6778, 138.9146],
  [-33.6740, 138.9194]
];

const burraHallett = [
  [-33.6740, 138.9194],
  [-33.6712, 138.9226],
  [-33.6707, 138.9233],
  [-33.6702, 138.9236],
  [-33.6675, 138.9241],
  [-33.6667, 138.9241],
  [-33.6658, 138.9235],
  [-33.6649, 138.9224],
  [-33.6628, 138.9171],
  [-33.6614, 138.9149],
  [-33.6595, 138.9134],
  [-33.6522, 138.9100],
  [-33.6465, 138.9083],
  [-33.6328, 138.9047],
  [-33.6263, 138.9046],
  [-33.6250, 138.9041],
  [-33.6122, 138.8945],
  [-33.6105, 138.8937],
  [-33.6042, 138.8920],
  [-33.5888, 138.8914],
  [-33.5871, 138.8911],
  [-33.5766, 138.8883],
  [-33.5743, 138.8884],
  [-33.5716, 138.8893],
  [-33.5528, 138.8922],
  [-33.5283, 138.8962],
  [-33.5260, 138.8960],
  [-33.5026, 138.8988],
  [-33.5000, 138.8987],
  [-33.4983, 138.8983],
  [-33.4935, 138.8964],
  [-33.4863, 138.8950],
  [-33.4821, 138.8929],
  [-33.4706, 138.8832],
  [-33.4684, 138.8828],
  [-33.4605, 138.8828],
  [-33.4224, 138.8921],
  [-33.4222, 138.8921]
];

const hallettTerowie = [
  [-33.4222, 138.8921],
  [-33.4169, 138.8921],
  [-33.4154, 138.8917],
  [-33.4143, 138.8907],
  [-33.4047, 138.8788],
  [-33.4027, 138.8777],
  [-33.3848, 138.8727],
  [-33.3828, 138.8728],
  [-33.3524, 138.8808],
  [-33.3343, 138.8894],
  [-33.3321, 138.8895],
  [-33.3290, 138.8886],
  [-33.3269, 138.8872],
  [-33.3246, 138.8848],
  [-33.3214, 138.8833],
  [-33.3114, 138.8816],
  [-33.3096, 138.8807],
  [-33.3081, 138.8789],
  [-33.3063, 138.8773],
  [-33.2897, 138.8698],
  [-33.2858, 138.8688],
  [-33.2697, 138.8679],
  [-33.2670, 138.8682],
  [-33.2430, 138.8744],
  [-33.2332, 138.8783],
  [-33.2332, 138.8783],
  [-33.2257, 138.8871],
  [-33.2240, 138.8887],
  [-33.2125, 138.8960],
  [-33.1900, 138.9024],
  [-33.1618, 138.9138],
  [-33.1593, 138.9152],
  [-33.1504, 138.9215]
];

const terowiePeterborough = [
  [-33.1504, 138.9215],
  [-33.1439, 138.9261],
  [-33.1419, 138.9266],
  [-33.1401, 138.9263],
  [-33.1337, 138.9245],
  [-33.1209, 138.9180],
  [-33.1192, 138.9165],
  [-33.1093, 138.9029],
  [-33.0979, 138.8927],
  [-33.0953, 138.8915],
  [-33.0905, 138.8906],
  [-33.0890, 138.8907],
  [-33.0800, 138.8932],
  [-33.0787, 138.8932],
  [-33.0777, 138.8927],
  [-33.0770, 138.8919],
  [-33.0756, 138.8887],
  [-33.0747, 138.8878],
  [-33.0733, 138.8876],
  [-33.0715, 138.8887],
  [-33.0705, 138.8888],
  [-33.0695, 138.8883],
  [-33.0653, 138.8835],
  [-33.0630, 138.8822],
  [-33.0617, 138.8809],
  [-33.0598, 138.8754],
  [-33.0588, 138.8739],
  [-33.0482, 138.8640],
  [-33.0425, 138.8608],
  [-32.9762, 138.8453],
  [-32.9746, 138.8441],
  [-32.9738, 138.8426],
  [-32.9736, 138.8412],
  [-32.9740, 138.8363]
];

export const peterborough: Line = {
  name: 'Peterborough',
  state: 'SA',
  segments: [
    {
      segments: [roseworthyTarlee],
      history: {
        opened: {
          date: '1869-07-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tarleeSaddleworth],
      history: {
        opened: {
          date: '1870-08-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2007-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [saddleworthManoora],
      history: {
        opened: {
          date: '1870-08-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [manooraBurra],
      history: {
        opened: {
          date: '1869-08-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2004-03-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [burraHallett],
      history: {
        opened: {
          date: '1878-03-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-11-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hallettTerowie],
      history: {
        opened: {
          date: '1880-12-14',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-07-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [terowiePeterborough],
      history: {
        opened: {
          date: '1881-05-11',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1970-01-01',
          gauge: 'broad'
        }, {
          date: '1988-07-26',
          status: 'closed'
        }]
      }
    }
  ]
};
