import { Line } from "../../../trackTypes";

const erskinevilleJunctionTunnelPortal = [
  [-33.8999, 151.1855],
  [-33.8990, 151.1864],
  [-33.8986, 151.1870],
  [-33.8980, 151.1885]
];

const tunnelPortalCentral = [
  [-33.8980, 151.1885],
  [-33.8975, 151.1902],
  [-33.8974, 151.1953],
  [-33.8972, 151.1963],
  [-33.8967, 151.1972],
  [-33.8961, 151.1979],
  [-33.8951, 151.1984],
  [-33.8935, 151.1987],
  [-33.8916, 151.1998],
  [-33.8905, 151.2012],
  [-33.8872, 151.2054],
  [-33.8838, 151.2079],
  [-33.8825, 151.2084],
  [-33.8813, 151.2080],
  [-33.8805, 151.2075],
  [-33.8796, 151.2065],
  [-33.8786, 151.2058],
  [-33.8776, 151.2057],
  [-33.8753, 151.2065]
];

const townHallTunnelPortal = [
  [-33.8731, 151.2069],
  [-33.8711, 151.2077],
  [-33.8692, 151.2083],
  [-33.8685, 151.2087],
  [-33.8679, 151.2094],
  [-33.8677, 151.2102],
  [-33.8680, 151.2133],
  [-33.8684, 151.2150],
  [-33.8694, 151.2165],
  [-33.8697, 151.2168]
];

const woolloomoolooViaduct = [
  [-33.8697, 151.2168],
  [-33.8702, 151.2173],
  [-33.8720, 151.2186],
  [-33.8729, 151.2194],
  [-33.8739, 151.2210]
];

const kingsCrossTunnel = [
  [-33.8739, 151.2210],
  [-33.8760, 151.2255],
  [-33.8765, 151.2269]
];

const kingsCrossGround = [
  [-33.8765, 151.2269],
  [-33.8771, 151.2311],
  [-33.8773, 151.2319],
  [-33.8776, 151.2327],
  [-33.8786, 151.2342]
];

const edgecliffTunnel = [
  [-33.8786, 151.2342],
  [-33.8791, 151.2353],
  [-33.8795, 151.2375],
  [-33.8801, 151.2391],
  [-33.8808, 151.2401],
  [-33.8831, 151.2423],
  [-33.8840, 151.2428],
  [-33.8846, 151.2429]
];

const woollahraCutting = [
  [-33.8846, 151.2429],
  [-33.8861, 151.2429]
];

const bondiJunctionTunnel = [
  [-33.8861, 151.2429],
  [-33.8871, 151.2428],
  [-33.8882, 151.2429],
  [-33.8894, 151.2437],
  [-33.8902, 151.2447],
  [-33.8904, 151.2452],
  [-33.8914, 151.2491]
];

export const easternSuburbs: Line = {
  name: 'Eastern Suburbs',
  state: 'NSW',
  segments: [
    {
      segments: [
        erskinevilleJunctionTunnelPortal,
        {
          segment: tunnelPortalCentral,
          type: 'tunnel'
        },
        {
          segment: townHallTunnelPortal,
          type: 'tunnel'
        },
        woolloomoolooViaduct,
        {
          segment: kingsCrossTunnel,
          type: 'tunnel'
        },
        kingsCrossGround,
        {
          segment: edgecliffTunnel,
          type: 'tunnel'
        },
        woollahraCutting,
        {
          segment: bondiJunctionTunnel,
          type: 'tunnel'
        }
      ],
      history: {
        opened: {
          date: '1979-06-23',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
