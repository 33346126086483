import { Line } from "../../../../trackTypes";

const waterworksRoad = [
  [-27.46507, 153.01313],
  [-27.46409, 153.01439],
  [-27.46398, 153.01446],
  [-27.46389, 153.01449],
  [-27.46276, 153.01447],
  [-27.46015, 153.01459],
  [-27.45997, 153.01455],
  [-27.45988, 153.01451],
  [-27.45977, 153.01444],
  [-27.45962, 153.01433],
  [-27.45934, 153.01398],
  [-27.45870, 153.01286],
  [-27.45819, 153.01216],
  [-27.45781, 153.01171],
  [-27.45747, 153.01132],
  [-27.45716, 153.01101],
  [-27.45700, 153.01079],
  [-27.45685, 153.01052],
  [-27.45673, 153.01021],
  [-27.45669, 153.00996],
  [-27.45668, 153.00975],
  [-27.45668, 153.00936],
  [-27.45667, 153.00916],
  [-27.45663, 153.00896],
  [-27.45613, 153.00775],
  [-27.45598, 153.00753],
  [-27.45579, 153.00733],
  [-27.45554, 153.00714],
  [-27.45531, 153.00701],
  [-27.45483, 153.00684],
  [-27.45458, 153.00670],
  [-27.45365, 153.00604],
  [-27.45350, 153.00590],
  [-27.45335, 153.00572],
  [-27.45302, 153.00519],
  [-27.45287, 153.00500],
  [-27.45274, 153.00480],
  [-27.45265, 153.00460]
];

const redHill = [
  [-27.45265, 153.00460],
  [-27.45264, 153.00443],
  [-27.45269, 153.00432],
  [-27.45438, 153.00306]
];

const kennedyTerrace = [
  [-27.45438, 153.00306],
  [-27.45544, 153.00240],
  [-27.45554, 153.00231],
  [-27.45614, 153.00166],
  [-27.45617, 153.00155],
  [-27.45618, 153.00147],
  [-27.45570, 152.99872]
];

const gardens = [
  [-27.46846, 153.02683],
  [-27.46843, 153.02693],
  [-27.46843, 153.02704],
  [-27.47203, 153.03056]
];

const gregoryTerrace = [
  [-27.46843, 153.02704],
  [-27.46626, 153.02487],
  [-27.46607, 153.02476],
  [-27.46584, 153.02469],
  [-27.46564, 153.02471],
  [-27.46546, 153.02473],
  [-27.46496, 153.02496],
  [-27.46485, 153.02497],
  [-27.46474, 153.02497],
  [-27.46290, 153.02432],
  [-27.46277, 153.02419],
  [-27.46267, 153.02411],
  [-27.46248, 153.02365],
  [-27.46237, 153.02344],
  [-27.46228, 153.02326],
  [-27.46208, 153.02268],
  [-27.46205, 153.02251],
  [-27.46206, 153.02146],
  [-27.46201, 153.02119],
  [-27.46124, 153.01906],
  [-27.46079, 153.01820],
  [-27.46065, 153.01789],
  [-27.46058, 153.01762],
  [-27.46055, 153.01733],
  [-27.46056, 153.01670],
  [-27.46056, 153.01655],
  [-27.46049, 153.01633],
  [-27.46000, 153.01510],
  [-27.45934, 153.01398]
];

const gregoryTerraceConnecting = [
  [-27.46267, 153.02411],
  [-27.46272, 153.02432],
  [-27.46273, 153.02454],
  [-27.46269, 153.02514],
  [-27.46263, 153.02536],
  [-27.46249, 153.02586],
  [-27.46239, 153.02607],
  [-27.46225, 153.02620],
  [-27.46192, 153.02631],
  [-27.46186, 153.02638],
  [-27.46184, 153.02647],
  [-27.46187, 153.02656]
];

const wharfStreet = [
  [-27.46187, 153.02656],
  [-27.46560, 153.03021],
  [-27.46563, 153.03032],
  [-27.46562, 153.03044]
];

const wharfStreetNorthWestJunction = [
  [-27.46560, 153.03021],
  [-27.46567, 153.03022],
  [-27.46576, 153.03020],
  [-27.46584, 153.03016]
];

const paddingtonConnection = [
  [-27.45873, 152.99935],
  [-27.45852, 152.99979],
  [-27.45847, 153.00000],
  [-27.45840, 153.00041],
  [-27.45837, 153.00062],
  [-27.45811, 153.00107],
  [-27.45804, 153.00115],
  [-27.45795, 153.00118],
  [-27.45757, 153.00126],
  [-27.45646, 153.00151],
  [-27.45627, 153.00155],
  [-27.45614, 153.00166]
];

const paddingtonJunction = [
  [-27.45627, 153.00155],
  [-27.45622, 153.00153],
  [-27.45618, 153.00147]
];

const oleanderDrive = [
  [-27.45265, 153.00460],
  [-27.45245, 153.00432],
  [-27.45233, 153.00421],
  [-27.45190, 153.00386],
  [-27.45173, 153.00366],
  [-27.45154, 153.00330],
  [-27.45148, 153.00306],
  [-27.45146, 153.00285],
  [-27.45146, 153.00248],
  [-27.45143, 153.00221],
  [-27.45122, 153.00147],
  [-27.45115, 153.00131],
  [-27.45107, 153.00119],
  [-27.45097, 153.00108],
  [-27.45064, 153.00081],
  [-27.45047, 153.00071],
  [-27.45028, 153.00064],
  [-27.44993, 153.00059],
  [-27.44977, 153.00053],
  [-27.44944, 153.00037],
  [-27.44924, 153.00025],
  [-27.44888, 152.99997],
  [-27.44866, 152.99986],
  [-27.44792, 152.99960],
  [-27.44777, 152.99954],
  [-27.44761, 152.99942],
  [-27.44714, 152.99900],
  [-27.44699, 152.99882],
  [-27.44644, 152.99777],
  [-27.44628, 152.99754],
  [-27.44571, 152.99684],
  [-27.44562, 152.99669],
  [-27.44556, 152.99650],
  [-27.44554, 152.99636],
  [-27.44559, 152.99487],
  [-27.44558, 152.99460],
  [-27.44539, 152.99337],
  [-27.44541, 152.99311],
  [-27.44546, 152.99282],
  [-27.44585, 152.99143],
  [-27.44589, 152.99111],
  [-27.44596, 152.98955],
  [-27.44594, 152.98930],
  [-27.44573, 152.98850],
  [-27.44571, 152.98831],
  [-27.44572, 152.98813],
  [-27.44608, 152.98684]
];

const ashgroveStateSchool = [
  [-27.44608, 152.98684],
  [-27.44625, 152.98618],
  [-27.44633, 152.98572],
  [-27.44650, 152.98518],
  [-27.44660, 152.98496],
  [-27.44697, 152.98449],
  [-27.44713, 152.98425],
  [-27.44723, 152.98398],
  [-27.44735, 152.98337],
  [-27.44743, 152.98310],
  [-27.44792, 152.98191],
  [-27.44795, 152.98170],
  [-27.44794, 152.98139],
  [-27.44789, 152.98106],
  [-27.44788, 152.98084],
  [-27.44791, 152.98011],
  [-27.44792, 152.98004],
  [-27.44800, 152.97984],
  [-27.44808, 152.97973],
  [-27.44909, 152.97862],
  [-27.44918, 152.97849],
  [-27.44921, 152.97831],
  [-27.44909, 152.97753],
  [-27.44895, 152.97733],
  [-27.44881, 152.97719]
];

export const west1: Line = {
  name: 'West 1',
  state: 'QLD',
  segments: [
    {
      segments: [waterworksRoad],
      history: {
        opened: {
          date: '1897-12-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-08-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [redHill],
      history: {
        opened: {
          date: '1897-12-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-04-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kennedyTerrace],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-04-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gardens],
      history: {
        opened: {
          date: '1902-06-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1947-07-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gregoryTerrace],
      history: {
        opened: {
          date: '1903-02-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1947-07-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gregoryTerraceConnecting],
      history: {
        opened: {
          date: '1902-06-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1947-07-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wharfStreet],
      history: {
        opened: {
          date: '1902-06-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wharfStreetNorthWestJunction],
      history: {
        opened: {
          date: '1948-04-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-12-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [paddingtonConnection, paddingtonJunction],
      history: {
        opened: {
          date: '1915-04-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-04-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [oleanderDrive],
      history: {
        opened: {
          date: '1924-08-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-08-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ashgroveStateSchool],
      history: {
        opened: {
          date: '1935-05-25',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1968-08-05',
          status: 'closed'
        }]
      }
    }
  ]
}
