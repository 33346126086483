import { Line } from "../../../trackTypes";

const rockhamptonGracemere = [
  [-23.4111, 150.5009],
  [-23.4126, 150.4994],
  [-23.4148, 150.4969],
  [-23.4283, 150.4784],
  [-23.4311, 150.4747],
  [-23.4321, 150.4724],
  [-23.4321, 150.4692],
  [-23.4313, 150.4661]
];

const rocklandsGracemere = [
  [-23.4400, 150.5192],
  [-23.4389, 150.5179],
  [-23.4384, 150.5166],
  [-23.4383, 150.5155],
  [-23.4386, 150.5141],
  [-23.4462, 150.4924],
  [-23.4466, 150.4903],
  [-23.4466, 150.4883],
  [-23.4458, 150.4829],
  [-23.4452, 150.4809],
  [-23.4413, 150.4737],
  [-23.4382, 150.4706],
  [-23.4330, 150.4679],
  [-23.4320, 150.4672],
  [-23.4313, 150.4661]
];

const northRocklandsJunction = [
  [-23.4357, 150.5150],
  [-23.4366, 150.5154],
  [-23.4377, 150.5153],
  [-23.4386, 150.5141]
];

const gracemereStanwell = [
  [-23.4313, 150.4661],
  [-23.4306, 150.4644],
  [-23.4306, 150.4618],
  [-23.4316, 150.4593],
  [-23.4350, 150.4555],
  [-23.4367, 150.4532],
  [-23.4460, 150.4374],
  [-23.4471, 150.4349],
  [-23.4490, 150.4292],
  [-23.4508, 150.4259],
  [-23.4561, 150.4188],
  [-23.4581, 150.4155],
  [-23.4644, 150.4003],
  [-23.4667, 150.3966],
  [-23.4693, 150.3942],
  [-23.4730, 150.3920],
  [-23.4753, 150.3901],
  [-23.4769, 150.3881],
  [-23.4804, 150.3821],
  [-23.4817, 150.3782],
  [-23.4850, 150.3430],
  [-23.4849, 150.3398],
  [-23.4837, 150.3339],
  [-23.4836, 150.3312],
  [-23.4841, 150.3287],
  [-23.4857, 150.3252]
];

const stanwellWycarbah = [
  [-23.4857, 150.3252],
  [-23.4884, 150.3190],
  [-23.4890, 150.3159],
  [-23.4898, 150.3139],
  [-23.4909, 150.3120],
  [-23.4914, 150.3102],
  [-23.4935, 150.2915],
  [-23.4944, 150.2881],
  [-23.4977, 150.2822],
  [-23.4996, 150.2799],
  [-23.5252, 150.2584],
  [-23.5266, 150.2567],
  [-23.5276, 150.2547],
  [-23.5328, 150.2278],
  [-23.5349, 150.2235],
  [-23.5420, 150.2173],
  [-23.5458, 150.2154]
];

const wycarbahWestwood = [
  [-23.5458, 150.2154],
  [-23.5488, 150.2137],
  [-23.5510, 150.2121],
  [-23.5550, 150.2085],
  [-23.5560, 150.2072],
  [-23.5581, 150.2037],
  [-23.5596, 150.2000],
  [-23.5610, 150.1979],
  [-23.5663, 150.1923],
  [-23.5678, 150.1899],
  [-23.5697, 150.1884],
  [-23.5717, 150.1875],
  [-23.5743, 150.1857],
  [-23.5784, 150.1814],
  [-23.5794, 150.1800],
  [-23.5814, 150.1767],
  [-23.5889, 150.1689],
  [-23.5915, 150.1672],
  [-23.5947, 150.1665],
  [-23.5979, 150.1667],
  [-23.5998, 150.1665],
  [-23.6024, 150.1657],
  [-23.6035, 150.1651]
];

const westwoodWindah = [
  [-23.6035, 150.1651],
  [-23.6186, 150.1575],
  [-23.6203, 150.1562],
  [-23.6231, 150.1534],
  [-23.6244, 150.1515],
  [-23.6296, 150.1395],
  [-23.6296, 150.1373],
  [-23.6287, 150.1356],
  [-23.6278, 150.1346],
  [-23.6269, 150.1330],
  [-23.6266, 150.1314],
  [-23.6268, 150.1296],
  [-23.6276, 150.1270],
  [-23.6293, 150.1248],
  [-23.6349, 150.1206],
  [-23.6372, 150.1199],
  [-23.6426, 150.1204],
  [-23.6453, 150.1197],
  [-23.6473, 150.1180],
  [-23.6487, 150.1152]
];

const windahGrantleigh = [
  [-23.6487, 150.1152],
  [-23.6489, 150.1138],
  [-23.6491, 150.1112],
  [-23.6518, 150.1029],
  [-23.6519, 150.0999],
  [-23.6514, 150.0972],
  [-23.6514, 150.0946],
  [-23.6532, 150.0885],
  [-23.6553, 150.0837],
  [-23.6620, 150.0588],
  [-23.6635, 150.0449],
  [-23.6640, 150.0432],
  [-23.6704, 150.0285],
  [-23.6727, 150.0252],
  [-23.6986, 150.0006],
  [-23.6998, 149.9986],
  [-23.7012, 149.9952]
];

const grantleighTunnel = [
  [-23.7012, 149.9952],
  [-23.7065, 149.9820],
  [-23.7068, 149.9793],
  [-23.7038, 149.9664],
  [-23.7016, 149.9620],
  [-23.7009, 149.9591],
  [-23.6974, 149.9541],
  [-23.6966, 149.9514],
  [-23.6970, 149.9489],
  [-23.7001, 149.9427],
  [-23.7047, 149.9367],
  [-23.7052, 149.9346],
  [-23.7042, 149.9300],
  [-23.7023, 149.9275],
  [-23.7017, 149.9263],
  [-23.7016, 149.9240],
  [-23.7012, 149.9227]
];

const moocomongaTunnel = [
  [-23.7012, 149.9227],
  [-23.7005, 149.9220],
  [-23.6996, 149.9213]
];

const tunnelAroona = [
  [-23.6996, 149.9213],
  [-23.6991, 149.9209],
  [-23.6982, 149.9196],
  [-23.6979, 149.9175],
  [-23.6983, 149.9152],
  [-23.6983, 149.9126],
  [-23.6994, 149.9102],
  [-23.7010, 149.9092],
  [-23.7025, 149.9090],
  [-23.7045, 149.9076],
  [-23.7053, 149.9049],
  [-23.7046, 149.9027],
  [-23.7001, 149.8975],
  [-23.6971, 149.8947],
  [-23.6961, 149.8919],
  [-23.6968, 149.8898],
  [-23.6984, 149.8883],
  [-23.7001, 149.8879],
  [-23.7018, 149.8866],
  [-23.7054, 149.8801],
  [-23.7071, 149.8788],
  [-23.7099, 149.8780],
  [-23.7115, 149.8770],
  [-23.7124, 149.8751],
  [-23.7144, 149.8543],
  [-23.7151, 149.8520],
  [-23.7161, 149.8505],
  [-23.7282, 149.8419],
  [-23.7333, 149.8374],
  [-23.7344, 149.8360],
  [-23.7375, 149.8302],
  [-23.7409, 149.8195],
  [-23.7423, 149.8112],
  [-23.7419, 149.8088],
  [-23.7384, 149.8003],
  [-23.7376, 149.7977],
  [-23.7368, 149.7847],
  [-23.7358, 149.7816],
  [-23.7288, 149.7719],
  [-23.7277, 149.7701],
  [-23.7221, 149.7511]
];

const aroonaDuaringa = [
  [-23.7221, 149.7511],
  [-23.7216, 149.7492],
  [-23.7142, 149.6936],
  [-23.7136, 149.6917],
  [-23.7108, 149.6845],
  [-23.7107, 149.6818],
  [-23.7135, 149.6706]
];

const duaringaWallaroo = [
  [-23.7135, 149.6706],
  [-23.7143, 149.6673],
  [-23.7144, 149.6655],
  [-23.7143, 149.6633],
  [-23.7144, 149.6616],
  [-23.7161, 149.6519],
  [-23.7160, 149.6498],
  [-23.7154, 149.6476],
  [-23.7153, 149.6450],
  [-23.7157, 149.6429],
  [-23.7158, 149.6411],
  [-23.7153, 149.6346],
  [-23.7156, 149.6316],
  [-23.7153, 149.6288],
  [-23.7143, 149.6243],
  [-23.7141, 149.6224],
  [-23.7149, 149.6119],
  [-23.7148, 149.6101],
  [-23.7107, 149.5914],
  [-23.7093, 149.5891],
  [-23.7062, 149.5862],
  [-23.7050, 149.5841],
  [-23.7039, 149.5793],
  [-23.7037, 149.5771],
  [-23.7053, 149.5707],
  [-23.7055, 149.5696]
];

const wallarooDingo = [
  [-23.7055, 149.5696],
  [-23.7060, 149.5636],
  [-23.7058, 149.5624],
  [-23.6973, 149.5460],
  [-23.6834, 149.5252],
  [-23.6658, 149.4911],
  [-23.6652, 149.4884],
  [-23.6642, 149.4340],
  [-23.6618, 149.4145],
  [-23.6613, 149.4130],
  [-23.6583, 149.4078],
  [-23.6578, 149.4063],
  [-23.6554, 149.3867],
  [-23.6538, 149.3543],
  [-23.6487, 149.3339],
  [-23.6484, 149.3333],
  [-23.6455, 149.3291]
];

const dingoBluff = [
  [-23.6455, 149.3291],
  [-23.6436, 149.3264],
  [-23.6430, 149.3251],
  [-23.6414, 149.3190],
  [-23.6380, 149.3105],
  [-23.6328, 149.3015],
  [-23.6323, 149.3003],
  [-23.6321, 149.2991],
  [-23.6314, 149.2807],
  [-23.6311, 149.2793],
  [-23.6243, 149.2595],
  [-23.6233, 149.2530],
  [-23.6234, 149.2513],
  [-23.6244, 149.2472],
  [-23.6245, 149.2450],
  [-23.6235, 149.2391],
  [-23.6234, 149.2374],
  [-23.6233, 149.2326],
  [-23.6214, 149.2214],
  [-23.6203, 149.2192],
  [-23.6126, 149.2092],
  [-23.6114, 149.2067],
  [-23.6074, 149.1919],
  [-23.6073, 149.1902],
  [-23.6079, 149.1858],
  [-23.6078, 149.1834],
  [-23.6051, 149.1717],
  [-23.6041, 149.1697],
  [-23.6025, 149.1677],
  [-23.6014, 149.1656],
  [-23.6012, 149.1636],
  [-23.6014, 149.1621],
  [-23.6011, 149.1604],
  [-23.5988, 149.1542],
  [-23.5874, 149.1303],
  [-23.5868, 149.1283],
  [-23.5862, 149.1239],
  [-23.5857, 149.1224],
  [-23.5849, 149.1204],
  [-23.5845, 149.1187],
  [-23.5837, 149.1021],
  [-23.5851, 149.0842],
  [-23.5848, 149.0821],
  [-23.5836, 149.0780],
  [-23.5829, 149.0697]
];

const bluffBlackwater = [
  [-23.5829, 149.0697],
  [-23.5824, 149.0648],
  [-23.5826, 149.0637],
  [-23.5858, 149.0551],
  [-23.5861, 149.0538],
  [-23.5873, 149.0359],
  [-23.5861, 149.0254],
  [-23.5860, 149.0194],
  [-23.5854, 149.0169],
  [-23.5843, 149.0147],
  [-23.5837, 149.0119],
  [-23.5840, 148.9896],
  [-23.5848, 148.9845],
  [-23.5853, 148.9441],
  [-23.5855, 148.9420],
  [-23.5860, 148.8834]
];

const blackwaterRangal = [
  [-23.5860, 148.8834],
  [-23.5862, 148.8637],
  [-23.5855, 148.8593],
  [-23.5861, 148.7957]
];

const rangalElectrificationEnd = [
  [-23.5861, 148.7957],
  [-23.5863, 148.7873],
  [-23.5941, 148.7312],
  [-23.6002, 148.7162],
  [-23.6011, 148.7134],
  [-23.6011, 148.7105],
  [-23.6001, 148.7082],
  [-23.5972, 148.7039],
  [-23.5962, 148.7007],
  [-23.5962, 148.6971],
  [-23.5970, 148.6947],
  [-23.6007, 148.6887],
  [-23.6015, 148.6867],
  [-23.6016, 148.6846],
  [-23.6011, 148.6777],
  [-23.6015, 148.6731],
  [-23.5998, 148.6547],
  [-23.5999, 148.6410],
  [-23.5995, 148.6363],
  [-23.5959, 148.6185],
  [-23.5952, 148.6110],
  [-23.5954, 148.6074],
  [-23.5997, 148.5833],
  [-23.6017, 148.5608],
  [-23.6023, 148.5586],
  [-23.6042, 148.5540],
  [-23.6051, 148.5508],
  [-23.6062, 148.5370],
  [-23.6053, 148.5279],
  [-23.6054, 148.5260],
  [-23.6076, 148.5172],
  [-23.6077, 148.5133],
  [-23.6047, 148.4886],
  [-23.6034, 148.4821],
  [-23.6033, 148.4797],
  [-23.6037, 148.4744],
  [-23.6032, 148.4709],
  [-23.6013, 148.4676],
  [-23.6001, 148.4650],
  [-23.5507, 148.2327],
  [-23.5405, 148.1846],
  [-23.5380, 148.1777],
  [-23.5374, 148.1763],
  [-23.5371, 148.1757]
];

const electrificationEndNogoaRiver = [
  [-23.5371, 148.1757],
  [-23.5362, 148.1742],
  [-23.5350, 148.1724],
  [-23.5329, 148.1695]
];

const nogoaRiverBarcaldine = [
  [-23.5329, 148.1695],
  [-23.5286, 148.1633],
  [-23.5259, 148.1579],
  [-23.5256, 148.1558],
  [-23.5376, 148.0230],
  [-23.5413, 148.0100],
  [-23.5418, 148.0076],
  [-23.5417, 148.0013],
  [-23.5425, 147.9983],
  [-23.5426, 147.9969],
  [-23.5417, 147.9933],
  [-23.5412, 147.9161],
  [-23.5479, 147.8138],
  [-23.5473, 147.8119],
  [-23.5458, 147.8100],
  [-23.5452, 147.8079],
  [-23.5461, 147.8055],
  [-23.5497, 147.8024],
  [-23.5508, 147.8006],
  [-23.5509, 147.7987],
  [-23.5501, 147.7965],
  [-23.5498, 147.7951],
  [-23.5542, 147.7201],
  [-23.5536, 147.7184],
  [-23.5510, 147.7147],
  [-23.5501, 147.7122],
  [-23.5504, 147.7093],
  [-23.5538, 147.7017],
  [-23.5547, 147.6944],
  [-23.5551, 147.6931],
  [-23.5581, 147.6865],
  [-23.5587, 147.6848],
  [-23.5624, 147.6678],
  [-23.5668, 147.6536],
  [-23.5676, 147.6426],
  [-23.5684, 147.6394],
  [-23.5697, 147.6237],
  [-23.5696, 147.6219],
  [-23.5683, 147.6137],
  [-23.5666, 147.6100],
  [-23.5663, 147.6089],
  [-23.5661, 147.6042],
  [-23.5663, 147.6025],
  [-23.5673, 147.5996],
  [-23.5695, 147.5863],
  [-23.5694, 147.5853],
  [-23.5690, 147.5839],
  [-23.5694, 147.5825],
  [-23.5718, 147.5801],
  [-23.5761, 147.5723],
  [-23.5774, 147.5665],
  [-23.5782, 147.5653],
  [-23.5883, 147.5567],
  [-23.5897, 147.5558],
  [-23.5917, 147.5552],
  [-23.5927, 147.5544],
  [-23.5951, 147.5486],
  [-23.6017, 147.5407],
  [-23.6106, 147.5350],
  [-23.6117, 147.5338],
  [-23.6147, 147.5278],
  [-23.6227, 147.5197],
  [-23.6245, 147.5184],
  [-23.6287, 147.5163],
  [-23.6318, 147.5138],
  [-23.6328, 147.5123],
  [-23.6374, 147.5013],
  [-23.6387, 147.4996],
  [-23.6419, 147.4971],
  [-23.6443, 147.4941],
  [-23.6471, 147.4917],
  [-23.6507, 147.4878],
  [-23.6520, 147.4870],
  [-23.6538, 147.4865],
  [-23.6552, 147.4849],
  [-23.6563, 147.4823],
  [-23.6573, 147.4808],
  [-23.6575, 147.4801],
  [-23.6575, 147.4793],
  [-23.6562, 147.4745],
  [-23.6560, 147.4720],
  [-23.6570, 147.4590],
  [-23.6576, 147.4578],
  [-23.6644, 147.4502],
  [-23.6650, 147.4488],
  [-23.6659, 147.4442],
  [-23.6659, 147.4425],
  [-23.6656, 147.4403],
  [-23.6657, 147.4389],
  [-23.6668, 147.4363],
  [-23.6682, 147.4346],
  [-23.6697, 147.4339],
  [-23.6732, 147.4333],
  [-23.6740, 147.4334],
  [-23.6751, 147.4338],
  [-23.6760, 147.4339],
  [-23.6770, 147.4334],
  [-23.6775, 147.4321],
  [-23.6772, 147.4308],
  [-23.6762, 147.4301],
  [-23.6750, 147.4298],
  [-23.6738, 147.4290],
  [-23.6731, 147.4278],
  [-23.6731, 147.4264],
  [-23.6740, 147.4228],
  [-23.6740, 147.4220],
  [-23.6734, 147.4208],
  [-23.6733, 147.4198],
  [-23.6736, 147.4188],
  [-23.6744, 147.4182],
  [-23.6763, 147.4176],
  [-23.6772, 147.4167],
  [-23.6776, 147.4157],
  [-23.6776, 147.4128],
  [-23.6779, 147.4119],
  [-23.6788, 147.4106],
  [-23.6795, 147.4100],
  [-23.6805, 147.4099],
  [-23.6814, 147.4104],
  [-23.6822, 147.4114],
  [-23.6829, 147.4120],
  [-23.6865, 147.4130],
  [-23.6874, 147.4130],
  [-23.6894, 147.4120],
  [-23.6899, 147.4116],
  [-23.6914, 147.4094],
  [-23.6918, 147.4085],
  [-23.6924, 147.4067],
  [-23.6929, 147.4062],
  [-23.6937, 147.4058],
  [-23.6941, 147.4050],
  [-23.6944, 147.4033],
  [-23.6941, 147.4020],
  [-23.6942, 147.4013],
  [-23.6945, 147.4006],
  [-23.6959, 147.3991],
  [-23.6974, 147.3964],
  [-23.6973, 147.3950],
  [-23.6964, 147.3934],
  [-23.6960, 147.3914],
  [-23.6962, 147.3901],
  [-23.6973, 147.3877],
  [-23.6986, 147.3867],
  [-23.7000, 147.3862],
  [-23.7015, 147.3855],
  [-23.7020, 147.3848],
  [-23.7022, 147.3841],
  [-23.7002, 147.3698],
  [-23.6998, 147.3690],
  [-23.6987, 147.3678],
  [-23.6984, 147.3670],
  [-23.6986, 147.3662],
  [-23.6992, 147.3653],
  [-23.6996, 147.3647],
  [-23.6997, 147.3629],
  [-23.6994, 147.3620],
  [-23.6986, 147.3611],
  [-23.6981, 147.3602],
  [-23.6966, 147.3541],
  [-23.6961, 147.3532],
  [-23.6950, 147.3519],
  [-23.6946, 147.3510],
  [-23.6940, 147.3477],
  [-23.6907, 147.3427],
  [-23.6891, 147.3411],
  [-23.6855, 147.3385],
  [-23.6847, 147.3369],
  [-23.6847, 147.3355],
  [-23.6843, 147.3347],
  [-23.6834, 147.3341],
  [-23.6830, 147.3333],
  [-23.6821, 147.3312],
  [-23.6798, 147.3275],
  [-23.6788, 147.3266],
  [-23.6761, 147.3256],
  [-23.6753, 147.3248],
  [-23.6739, 147.3209],
  [-23.6705, 147.3166],
  [-23.6659, 147.3128],
  [-23.6652, 147.3118],
  [-23.6629, 147.3059],
  [-23.6622, 147.3049],
  [-23.6514, 147.2956],
  [-23.6497, 147.2932],
  [-23.6475, 147.2873],
  [-23.6453, 147.2844],
  [-23.6445, 147.2828],
  [-23.6433, 147.2782],
  [-23.6419, 147.2761],
  [-23.6410, 147.2740],
  [-23.6410, 147.2714],
  [-23.6420, 147.2687],
  [-23.6429, 147.2675],
  [-23.6457, 147.2650],
  [-23.6472, 147.2623],
  [-23.6470, 147.2593],
  [-23.6455, 147.2569],
  [-23.6433, 147.2552],
  [-23.6393, 147.2498],
  [-23.6370, 147.2473],
  [-23.6359, 147.2449],
  [-23.6361, 147.2418],
  [-23.6373, 147.2398],
  [-23.6393, 147.2381],
  [-23.6412, 147.2357],
  [-23.6446, 147.2329],
  [-23.6458, 147.2315],
  [-23.6467, 147.2295],
  [-23.6472, 147.2287],
  [-23.6495, 147.2267],
  [-23.6516, 147.2255],
  [-23.6523, 147.2255],
  [-23.6530, 147.2259],
  [-23.6537, 147.2258],
  [-23.6539, 147.2253],
  [-23.6540, 147.2241],
  [-23.6543, 147.2235],
  [-23.6550, 147.2231],
  [-23.6554, 147.2228],
  [-23.6559, 147.2218],
  [-23.6564, 147.2213],
  [-23.6566, 147.2208],
  [-23.6565, 147.2204],
  [-23.6567, 147.2199],
  [-23.6572, 147.2195],
  [-23.6576, 147.2194],
  [-23.6581, 147.2195],
  [-23.6585, 147.2194],
  [-23.6588, 147.2191],
  [-23.6590, 147.2187],
  [-23.6592, 147.2184],
  [-23.6596, 147.2182],
  [-23.6599, 147.2179],
  [-23.6601, 147.2174],
  [-23.6598, 147.2169],
  [-23.6593, 147.2167],
  [-23.6583, 147.2169],
  [-23.6578, 147.2166],
  [-23.6574, 147.2159],
  [-23.6577, 147.2126],
  [-23.6580, 147.2120],
  [-23.6584, 147.2116],
  [-23.6586, 147.2112],
  [-23.6586, 147.2102],
  [-23.6588, 147.2097],
  [-23.6592, 147.2091],
  [-23.6594, 147.2088],
  [-23.6594, 147.2083],
  [-23.6590, 147.2075],
  [-23.6587, 147.2072],
  [-23.6581, 147.2070],
  [-23.6578, 147.2068],
  [-23.6567, 147.2055],
  [-23.6564, 147.2054],
  [-23.6559, 147.2053],
  [-23.6555, 147.2049],
  [-23.6553, 147.2045],
  [-23.6549, 147.2041],
  [-23.6543, 147.2040],
  [-23.6533, 147.2044],
  [-23.6529, 147.2044],
  [-23.6526, 147.2039],
  [-23.6524, 147.2029],
  [-23.6520, 147.2024],
  [-23.6505, 147.2019],
  [-23.6489, 147.2006],
  [-23.6484, 147.1995],
  [-23.6484, 147.1960],
  [-23.6485, 147.1953],
  [-23.6488, 147.1943],
  [-23.6489, 147.1934],
  [-23.6488, 147.1925],
  [-23.6491, 147.1916],
  [-23.6497, 147.1911],
  [-23.6502, 147.1906],
  [-23.6506, 147.1898],
  [-23.6504, 147.1886],
  [-23.6501, 147.1881],
  [-23.6495, 147.1876],
  [-23.6491, 147.1868],
  [-23.6491, 147.1858],
  [-23.6495, 147.1847],
  [-23.6494, 147.1838],
  [-23.6485, 147.1817],
  [-23.6485, 147.1806],
  [-23.6490, 147.1793],
  [-23.6517, 147.1751],
  [-23.6528, 147.1715],
  [-23.6543, 147.1681],
  [-23.6546, 147.1665],
  [-23.6551, 147.1654],
  [-23.6566, 147.1643],
  [-23.6570, 147.1635],
  [-23.6574, 147.1597],
  [-23.6573, 147.1583],
  [-23.6568, 147.1557],
  [-23.6562, 147.1543],
  [-23.6547, 147.1520],
  [-23.6540, 147.1498],
  [-23.6536, 147.1456],
  [-23.6540, 147.1439],
  [-23.6553, 147.1413],
  [-23.6557, 147.1392],
  [-23.6555, 147.1353],
  [-23.6564, 147.1291],
  [-23.6560, 147.1265],
  [-23.6563, 147.1253],
  [-23.6563, 147.1244],
  [-23.6554, 147.1224],
  [-23.6546, 147.1221],
  [-23.6536, 147.1221],
  [-23.6529, 147.1217],
  [-23.6523, 147.1204],
  [-23.6528, 147.1185],
  [-23.6554, 147.1149],
  [-23.6579, 147.1090],
  [-23.6582, 147.1058],
  [-23.6573, 147.0992],
  [-23.6573, 147.0983],
  [-23.6577, 147.0960],
  [-23.6574, 147.0908],
  [-23.6553, 147.0861],
  [-23.6514, 147.0822],
  [-23.6511, 147.0804],
  [-23.6525, 147.0740],
  [-23.6525, 147.0723],
  [-23.6491, 147.0474],
  [-23.6497, 147.0311],
  [-23.6478, 147.0197],
  [-23.6481, 147.0173],
  [-23.6509, 147.0104],
  [-23.6513, 147.0081],
  [-23.6506, 147.0003],
  [-23.6476, 146.7381],
  [-23.6488, 146.7334],
  [-23.6491, 146.7306],
  [-23.6487, 146.6960],
  [-23.6490, 146.6940],
  [-23.6514, 146.6857],
  [-23.6533, 146.6617],
  [-23.6528, 146.6580],
  [-23.6486, 146.6464],
  [-23.6482, 146.6441],
  [-23.6480, 146.6304],
  [-23.6474, 146.6274],
  [-23.6463, 146.6245],
  [-23.6456, 146.6217],
  [-23.6406, 146.5540],
  [-23.6399, 146.5514],
  [-23.6269, 146.5265],
  [-23.6263, 146.5223],
  [-23.6282, 146.5139],
  [-23.6293, 146.5120],
  [-23.6342, 146.5067],
  [-23.6358, 146.5029],
  [-23.6361, 146.4923],
  [-23.6235, 146.3256],
  [-23.6219, 146.3193],
  [-23.6206, 146.3171],
  [-23.6198, 146.3152],
  [-23.6193, 146.3119],
  [-23.6193, 146.3099],
  [-23.6204, 146.3054],
  [-23.6205, 146.3016],
  [-23.6183, 146.2871],
  [-23.6192, 146.2834],
  [-23.6219, 146.2776],
  [-23.6222, 146.2756],
  [-23.6190, 146.2553],
  [-23.6169, 146.2512],
  [-23.6071, 146.2416],
  [-23.6063, 146.2399],
  [-23.6054, 146.2368],
  [-23.6041, 146.2350],
  [-23.6019, 146.2340],
  [-23.5990, 146.2338],
  [-23.5972, 146.2327],
  [-23.5962, 146.2305],
  [-23.5964, 146.2286],
  [-23.5978, 146.2252],
  [-23.5980, 146.2226],
  [-23.5960, 146.2127],
  [-23.5966, 146.2089],
  [-23.5995, 146.2024],
  [-23.6060, 146.1634],
  [-23.6061, 146.1606],
  [-23.6013, 146.1219],
  [-23.5650, 145.8353],
  [-23.5656, 145.7962],
  [-23.5682, 145.7358],
  [-23.5671, 145.7328],
  [-23.5603, 145.7248],
  [-23.5590, 145.7210],
  [-23.5592, 145.7179],
  [-23.5611, 145.7146],
  [-23.5674, 145.7088],
  [-23.5687, 145.7070],
  [-23.5695, 145.7039],
  [-23.5705, 145.6789],
  [-23.5710, 145.6661],
  [-23.5694, 145.6593],
  [-23.5693, 145.6557],
  [-23.5703, 145.6501],
  [-23.5720, 145.6429],
  [-23.5732, 145.6132],
  [-23.5520, 145.2896]
];

const barcaldineLongreach = [
  [-23.5520, 145.2896],
  [-23.5403, 145.1137],
  [-23.5235, 144.8627],
  [-23.5130, 144.7044],
  [-23.5085, 144.6378],
  [-23.5037, 144.5650],
  [-23.5034, 144.5635],
  [-23.4925, 144.5216],
  [-23.4735, 144.4475],
  [-23.4412, 144.3222],
  [-23.4407, 144.3191],
  [-23.4401, 144.2520]
];

const longreachWinton = [
  [-23.4401, 144.2520],
  [-23.4401, 144.2499],
  [-23.4398, 144.2487],
  [-23.4391, 144.2476],
  [-23.4382, 144.2468],
  [-23.3702, 144.2189],
  [-23.3675, 144.2173],
  [-23.3231, 144.1766],
  [-23.3101, 144.1640],
  [-23.3073, 144.1605],
  [-23.2974, 144.1470],
  [-23.2878, 144.1385],
  [-23.2722, 144.1278],
  [-23.2583, 144.1213],
  [-23.2524, 144.1198],
  [-23.2483, 144.1177],
  [-23.2134, 144.0887],
  [-23.2115, 144.0861],
  [-23.2070, 144.0745],
  [-23.2057, 144.0726],
  [-23.1362, 144.0089],
  [-23.1021, 143.9776],
  [-22.9805, 143.8664],
  [-22.8173, 143.7172],
  [-22.8144, 143.7137],
  [-22.8032, 143.6977],
  [-22.7762, 143.6663],
  [-22.7251, 143.6125],
  [-22.6788, 143.5609],
  [-22.5604, 143.4287],
  [-22.5453, 143.4190],
  [-22.5428, 143.4168],
  [-22.4779, 143.3311],
  [-22.4759, 143.3278],
  [-22.4593, 143.2892],
  [-22.4581, 143.2872],
  [-22.4509, 143.2782],
  [-22.4498, 143.2761],
  [-22.4240, 143.2000],
  [-22.3972, 143.1454],
  [-22.3960, 143.1418],
  [-22.3806, 143.0853],
  [-22.3801, 143.0820],
  [-22.3791, 143.0544],
  [-22.3787, 143.0399],
  [-22.3785, 143.0390],
  [-22.3782, 143.0387],
  [-22.3779, 143.0384]
];

const wintonBalloonLoop = [
  [-22.3779, 143.0384],
  [-22.3771, 143.0376],
  [-22.3769, 143.0365],
  [-22.3771, 143.0355],
  [-22.3777, 143.0347],
  [-22.3784, 143.0344],
  [-22.3793, 143.0345],
  [-22.3799, 143.0350],
  [-22.3802, 143.0358],
  [-22.3804, 143.0369],
  [-22.3802, 143.0374],
  [-22.3795, 143.0382],
  [-22.3790, 143.0388],
  [-22.3787, 143.0399]
];

export const centralWestern: Line = {
  name: 'Central Western',
  state: 'QLD',
  segments: [
    {
      segments: [rockhamptonGracemere],
      history: {
        opened: {
          date: '1867-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rocklandsGracemere],
      history: {
        opened: {
          date: '1980-011-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-01',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [northRocklandsJunction],
      history: {
        opened: {
          date: '1980-011-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [gracemereStanwell],
      history: {
        opened: {
          date: '1867-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2014-01-01',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [stanwellWycarbah],
      history: {
        opened: {
          date: '1867-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-05-09',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [wycarbahWestwood],
      history: {
        opened: {
          date: '1867-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-08-28',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [westwoodWindah],
      history: {
        opened: {
          date: '1867-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [windahGrantleigh],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-05-06',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [grantleighTunnel],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2009-09-23',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        {
          date: '1983-01-01',
          original: [{
            name: 'Moocomonga Tunnel',
            segment: moocomongaTunnel,
            type: 'tunnel'
          }],
          deviation: [moocomongaTunnel]
        },
        tunnelAroona
      ],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [aroonaDuaringa],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-09-07',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [duaringaWallaroo],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [wallarooDingo],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-08-05',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [dingoBluff],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2015-01-01',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [bluffBlackwater],
      history: {
        opened: {
          date: '1877-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          tracks: 2
        }, {
          date: '1986-09-06',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [blackwaterRangal],
      history: {
        opened: {
          date: '1879-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2011-01-01',
          tracks: 2
        }, {
          date: '1987-10-30',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [rangalElectrificationEnd],
      history: {
        opened: {
          date: '1879-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-10-30',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [electrificationEndNogoaRiver],
      history: {
        opened: {
          date: '1879-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [nogoaRiverBarcaldine],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [barcaldineLongreach],
      history: {
        opened: {
          date: '1892-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [longreachWinton],
      history: {
        opened: {
          date: '1928-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wintonBalloonLoop],
      history: {
        opened: {
          date: '2011-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
}
