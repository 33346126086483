import { Line } from "../../trackTypes";

const northMelbourneMacaulay = [
  [-37.8040, 144.9389],
  [-37.8027, 144.9379],
  [-37.8020, 144.9375],
  [-37.7976, 144.9363],
  [-37.7964, 144.9361],
  [-37.7957, 144.9361],
  [-37.7945, 144.9362]
];

const macaulayRoyalPark = [
  [-37.7945, 144.9362],
  [-37.7918, 144.9367],
  [-37.7907, 144.9370],
  [-37.7897, 144.9375],
  [-37.7890, 144.9380],
  [-37.7881, 144.9393],
  [-37.7877, 144.9400],
  [-37.7866, 144.9425],
  [-37.7860, 144.9433],
  [-37.7851, 144.9446],
  [-37.7825, 144.9475],
  [-37.7818, 144.9486],
  [-37.7813, 144.9500],
  [-37.7811, 144.9512],
  [-37.7811, 144.9515],
  [-37.7812, 144.9532],
  [-37.7809, 144.9551],
  [-37.7803, 144.9564]
];

const royalParkJewel = [
  [-37.7803, 144.9564],
  [-37.7794, 144.9574],
  [-37.7787, 144.9578],
  [-37.7777, 144.9581],
  [-37.7749, 144.9587]
];

const jewelBrunswick = [
  [-37.7749, 144.9587],
  [-37.7745, 144.9588],
  [-37.7730, 144.9590],
  [-37.7676, 144.9596]
];

const brunswickCoburg = [
  [-37.7676, 144.9596],
  [-37.7668, 144.9597],
  [-37.7557, 144.9616],
  [-37.7423, 144.9633]
];

const coburgFawkner = [
  [-37.7423, 144.9633],
  [-37.7408, 144.9634],
  [-37.7404, 144.9635],
  [-37.7399, 144.9634],
  [-37.7336, 144.9628],
  [-37.7273, 144.9620],
  [-37.7189, 144.9610],
  [-37.7146, 144.9605]
];

const fawknerGowrie = [
  [-37.7146, 144.9605],
  [-37.7044, 144.9592],
  [-37.7006, 144.9588]
];

const gowrieUpfield = [
  [-37.7006, 144.9588],
  [-37.6960, 144.9582],
  [-37.6952, 144.9581],
  [-37.6948, 144.9580],
  [-37.6941, 144.9578],
  [-37.6908, 144.9565],
  [-37.6902, 144.9564],
  [-37.6842, 144.9553],
  [-37.6833, 144.9550],
  [-37.6827, 144.9546],
  [-37.6819, 144.9541],
  [-37.6806, 144.9530],
  [-37.6797, 144.9523],
  [-37.6782, 144.9515],
  [-37.6660, 144.9467]
];

const upfieldSomerton = [
  [-37.6660, 144.9467],
  [-37.6558, 144.9427],
  [-37.6545, 144.9422],
  [-37.6538, 144.9418],
  [-37.6530, 144.9414],
  [-37.6433, 144.9351],
  [-37.6428, 144.9349],
  [-37.6424, 144.9348],
  [-37.6416, 144.9348]
];

export const upfield: Line = {
  name: 'Upfield',
  state: 'VIC',
  segments: [
    {
      segments: [northMelbourneMacaulay],
      history: {
        opened: {
          date: '1884-09-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1886-12-19',
          tracks: 2
        }, {
          date: '1920-12-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [macaulayRoyalPark],
      history: {
        opened: {
          date: '1884-09-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1888-09-02',
          tracks: 2
        }, {
          date: '1920-12-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [royalParkJewel],
      history: {
        opened: {
          date: '1884-09-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1889-05-05',
          tracks: 2
        }, {
          date: '1920-12-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [jewelBrunswick],
      history: {
        opened: {
          date: '1884-09-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1892-08-17',
          tracks: 2
        }, {
          date: '1920-12-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [brunswickCoburg],
      history: {
        opened: {
          date: '1884-09-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1891-12-20',
          tracks: 2
        }, {
          date: '1920-12-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [coburgFawkner],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1959-07-19',
          tracks: 2,
        }, {
          date: '1920-12-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1903-07-13',
          status: 'closed'
        }, {
          date: '1906-12-10',
          status: 'open'
        }]
      }
    },
    {
      segments: [fawknerGowrie],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1903-07-13',
          status: 'closed'
        }, {
          date: '1956-05-05',
          status: 'closed'
        }, {
          date: '1928-03-05',
          status: 'open'
        }, {
          date: '1959-08-17',
          status: 'open'
        }, {
          date: '1998-01-01',
          tracks: 2
        }, {
          date: '1959-08-17',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [gowrieUpfield],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1903-07-13',
          status: 'closed'
        }, {
          date: '1956-05-05',
          status: 'closed'
        }, {
          date: '1928-03-05',
          status: 'open'
        }, {
          date: '1959-08-17',
          status: 'open'
        }, {
          date: '1959-08-17',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [upfieldSomerton],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1903-07-13',
          status: 'closed'
        }, {
          date: '1956-05-05',
          status: 'closed'
        }, {
          date: '1928-05-05',
          status: 'open'
        }, {
          date: '1959-07-19',
          status: 'open'
        }]
      }
    }
  ]
};
