import { Line } from "../../trackTypes";

const ulverstoneDewpearlSiding = [
  [-41.1596, 146.1650],
  [-41.1594, 146.1636],
  [-41.1596, 146.1630],
  [-41.1599, 146.1623],
  [-41.1617, 146.1596]
];

const dewpealSidingNietta = [
  [-41.1617, 146.1596],
  [-41.1635, 146.1557],
  [-41.1636, 146.1546],
  [-41.1635, 146.1533],
  [-41.1637, 146.1526],
  [-41.1641, 146.1518],
  [-41.1647, 146.1512],
  [-41.1652, 146.1510],
  [-41.1661, 146.1499],
  [-41.1664, 146.1489],
  [-41.1663, 146.1481],
  [-41.1656, 146.1465],
  [-41.1656, 146.1458],
  [-41.1658, 146.1451],
  [-41.1659, 146.1445],
  [-41.1652, 146.1424],
  [-41.1652, 146.1415],
  [-41.1653, 146.1390],
  [-41.1653, 146.1382],
  [-41.1651, 146.1376],
  [-41.1650, 146.1370],
  [-41.1652, 146.1350],
  [-41.1651, 146.1341],
  [-41.1646, 146.1336],
  [-41.1639, 146.1334],
  [-41.1635, 146.1330],
  [-41.1631, 146.1322],
  [-41.1626, 146.1318],
  [-41.1621, 146.1317],
  [-41.1615, 146.1318],
  [-41.1612, 146.1317],
  [-41.1610, 146.1314],
  [-41.1607, 146.1292],
  [-41.1607, 146.1287],
  [-41.1610, 146.1285],
  [-41.1617, 146.1281],
  [-41.1620, 146.1277],
  [-41.1623, 146.1275],
  [-41.1645, 146.1273],
  [-41.1653, 146.1269],
  [-41.1667, 146.1268],
  [-41.1677, 146.1273],
  [-41.1683, 146.1274],
  [-41.1701, 146.1263],
  [-41.1705, 146.1262],
  [-41.1709, 146.1263],
  [-41.1722, 146.1270],
  [-41.1726, 146.1271],
  [-41.1736, 146.1264],
  [-41.1742, 146.1262],
  [-41.1750, 146.1264],
  [-41.1757, 146.1263],
  [-41.1762, 146.1264],
  [-41.1766, 146.1266],
  [-41.1770, 146.1266],
  [-41.1782, 146.1260],
  [-41.1787, 146.1259],
  [-41.1792, 146.1263],
  [-41.1797, 146.1264],
  [-41.1801, 146.1264],
  [-41.1805, 146.1267],
  [-41.1808, 146.1275],
  [-41.1815, 146.1279],
  [-41.1823, 146.1278],
  [-41.1828, 146.1281],
  [-41.1833, 146.1286],
  [-41.1838, 146.1287],
  [-41.1847, 146.1281],
  [-41.1850, 146.1275],
  [-41.1847, 146.1260],
  [-41.1846, 146.1255],
  [-41.1846, 146.1249],
  [-41.1849, 146.1244],
  [-41.1851, 146.1239],
  [-41.1849, 146.1234],
  [-41.1843, 146.1231],
  [-41.1838, 146.1232],
  [-41.1832, 146.1235],
  [-41.1827, 146.1234],
  [-41.1818, 146.1227],
  [-41.1812, 146.1215],
  [-41.1811, 146.1207],
  [-41.1813, 146.1196],
  [-41.1815, 146.1191],
  [-41.1819, 146.1189],
  [-41.1831, 146.1188],
  [-41.1836, 146.1186],
  [-41.1861, 146.1192],
  [-41.1868, 146.1188],
  [-41.1880, 146.1175],
  [-41.1887, 146.1169],
  [-41.1908, 146.1168],
  [-41.1915, 146.1164],
  [-41.1920, 146.1160],
  [-41.1924, 146.1159],
  [-41.1929, 146.1166],
  [-41.1933, 146.1168],
  [-41.1936, 146.1167],
  [-41.1941, 146.1168],
  [-41.1944, 146.1171],
  [-41.1958, 146.1171],
  [-41.1964, 146.1169],
  [-41.2019, 146.1135],
  [-41.2031, 146.1124],
  [-41.2075, 146.1110],
  [-41.2080, 146.1110],
  [-41.2088, 146.1112],
  [-41.2103, 146.1128],
  [-41.2107, 146.1131],
  [-41.2112, 146.1132],
  [-41.2117, 146.1142],
  [-41.2122, 146.1145],
  [-41.2185, 146.1133],
  [-41.2207, 146.1133],
  [-41.2216, 146.1137],
  [-41.2220, 146.1144],
  [-41.2222, 146.1159],
  [-41.2226, 146.1165],
  [-41.2232, 146.1170],
  [-41.2247, 146.1170],
  [-41.2272, 146.1165],
  [-41.2281, 146.1166],
  [-41.2318, 146.1184],
  [-41.2326, 146.1189],
  [-41.2347, 146.1242],
  [-41.2352, 146.1247],
  [-41.2360, 146.1250],
  [-41.2364, 146.1253],
  [-41.2368, 146.1263],
  [-41.2373, 146.1267],
  [-41.2383, 146.1268],
  [-41.2396, 146.1260],
  [-41.2401, 146.1258],
  [-41.2409, 146.1257],
  [-41.2427, 146.1223],
  [-41.2431, 146.1217],
  [-41.2435, 146.1194],
  [-41.2443, 146.1180],
  [-41.2463, 146.1169],
  [-41.2469, 146.1160],
  [-41.2474, 146.1147],
  [-41.2479, 146.1141],
  [-41.2485, 146.1139],
  [-41.2493, 146.1133],
  [-41.2502, 146.1119],
  [-41.2510, 146.1114],
  [-41.2531, 146.1120],
  [-41.2535, 146.1117],
  [-41.2537, 146.1112],
  [-41.2537, 146.1101],
  [-41.2539, 146.1097],
  [-41.2544, 146.1094],
  [-41.2549, 146.1086],
  [-41.2553, 146.1084],
  [-41.2561, 146.1088],
  [-41.2564, 146.1092],
  [-41.2568, 146.1094],
  [-41.2570, 146.1091],
  [-41.2573, 146.1084],
  [-41.2577, 146.1081],
  [-41.2584, 146.1078],
  [-41.2596, 146.1069],
  [-41.2583, 146.1055],
  [-41.2583, 146.1051],
  [-41.2583, 146.1048],
  [-41.2599, 146.1038],
  [-41.2604, 146.1039],
  [-41.2610, 146.1043],
  [-41.2618, 146.1041],
  [-41.2622, 146.1042],
  [-41.2624, 146.1045],
  [-41.2627, 146.1052],
  [-41.2631, 146.1054],
  [-41.2635, 146.1052],
  [-41.2647, 146.1033],
  [-41.2660, 146.1020],
  [-41.2664, 146.1018],
  [-41.2671, 146.1017],
  [-41.2676, 146.1013],
  [-41.2680, 146.0999],
  [-41.2683, 146.0966],
  [-41.2685, 146.0959],
  [-41.2689, 146.0955],
  [-41.2691, 146.0952],
  [-41.2690, 146.0948],
  [-41.2688, 146.0943],
  [-41.2689, 146.0937],
  [-41.2693, 146.0933],
  [-41.2702, 146.0929],
  [-41.2714, 146.0910],
  [-41.2721, 146.0883],
  [-41.2728, 146.0867],
  [-41.2736, 146.0861],
  [-41.2751, 146.0857],
  [-41.2756, 146.0853],
  [-41.2769, 146.0830],
  [-41.2797, 146.0803],
  [-41.2842, 146.0773],
  [-41.2866, 146.0756],
  [-41.2881, 146.0752],
  [-41.2888, 146.0745],
  [-41.2891, 146.0738],
  [-41.2891, 146.0728],
  [-41.2893, 146.0723],
  [-41.2895, 146.0720],
  [-41.2920, 146.0713],
  [-41.3014, 146.0737],
  [-41.3022, 146.0736],
  [-41.3029, 146.0730],
  [-41.3049, 146.0707],
  [-41.3057, 146.0696],
  [-41.3062, 146.0684],
  [-41.3066, 146.0677],
  [-41.3080, 146.0666],
  [-41.3091, 146.0649],
  [-41.3115, 146.0625],
  [-41.3120, 146.0616],
  [-41.3127, 146.0607],
  [-41.3144, 146.0608],
  [-41.3153, 146.0605],
  [-41.3156, 146.0593],
  [-41.3151, 146.0582],
  [-41.3151, 146.0573],
  [-41.3158, 146.0558],
  [-41.3163, 146.0554],
  [-41.3181, 146.0552],
  [-41.3186, 146.0549],
  [-41.3194, 146.0539],
  [-41.3200, 146.0535],
  [-41.3236, 146.0515],
  [-41.3254, 146.0502],
  [-41.3258, 146.0502],
  [-41.3261, 146.0504],
  [-41.3266, 146.0513],
  [-41.3275, 146.0519],
  [-41.3285, 146.0519],
  [-41.3291, 146.0522],
  [-41.3302, 146.0537],
  [-41.3307, 146.0540],
  [-41.3311, 146.0539],
  [-41.3318, 146.0529],
  [-41.3339, 146.0520],
  [-41.3405, 146.0513],
  [-41.3414, 146.0515],
  [-41.3419, 146.0515],
  [-41.3424, 146.0512],
  [-41.3432, 146.0510],
  [-41.3445, 146.0512],
  [-41.3451, 146.0510],
  [-41.3456, 146.0498],
  [-41.3455, 146.0490],
  [-41.3450, 146.0480],
  [-41.3451, 146.0473],
  [-41.3454, 146.0468],
  [-41.3460, 146.0468],
  [-41.3463, 146.0472],
  [-41.3467, 146.0473],
  [-41.3474, 146.0474],
  [-41.3485, 146.0484],
  [-41.3493, 146.0497],
  [-41.3493, 146.0511],
  [-41.3489, 146.0525],
  [-41.3489, 146.0533],
  [-41.3494, 146.0540],
  [-41.3499, 146.0550],
  [-41.3502, 146.0560],
  [-41.3509, 146.0565],
  [-41.3528, 146.0572],
  [-41.3540, 146.0569],
  [-41.3551, 146.0564],
  [-41.3558, 146.0562],
  [-41.3567, 146.0565],
  [-41.3652, 146.0667]
];

export const nietta: Line = {
  name: 'Nietta',
  state: 'TAS',
  segments: [
    {
      segments: [ulverstoneDewpearlSiding],
      history: {
        opened: {
          date: '1915-12-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-10-28',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dewpealSidingNietta],
      history: {
        opened: {
          date: '1915-12-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1955-12-07',
          status: 'closed'
        }]
      }
    }
  ]
}
