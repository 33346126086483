import { Line } from "../../trackTypes";

const wyalkatchemMountMarshall = [
  [-31.1821, 117.3828],
  [-31.1826, 117.3844],
  [-31.1825, 117.3860],
  [-31.1809, 117.3888],
  [-31.1795, 117.3897],
  [-31.1763, 117.3906],
  [-31.1744, 117.3917],
  [-31.1728, 117.3921],
  [-31.1713, 117.3927],
  [-31.1685, 117.3946],
  [-31.1666, 117.3951],
  [-31.1653, 117.3961],
  [-31.1641, 117.3977],
  [-31.1629, 117.3986],
  [-31.1605, 117.3991],
  [-31.1591, 117.4002],
  [-31.1584, 117.4023],
  [-31.1581, 117.4075],
  [-31.1573, 117.4097],
  [-31.1561, 117.4117],
  [-31.1552, 117.4137],
  [-31.1540, 117.4191],
  [-31.1524, 117.4213],
  [-31.1485, 117.4227],
  [-31.1463, 117.4225],
  [-31.1443, 117.4212],
  [-31.1422, 117.4193],
  [-31.1395, 117.4186],
  [-31.1333, 117.4207],
  [-31.1310, 117.4202],
  [-31.1285, 117.4187],
  [-31.1234, 117.4176],
  [-31.1220, 117.4178],
  [-31.1204, 117.4187],
  [-31.1186, 117.4189],
  [-31.1031, 117.4175],
  [-31.1008, 117.4182],
  [-31.0607, 117.4183],
  [-31.0589, 117.4176],
  [-31.0569, 117.4161],
  [-31.0539, 117.4160],
  [-31.0490, 117.4183],
  [-31.0426, 117.4242],
  [-31.0325, 117.4305],
  [-31.0289, 117.4360],
  [-31.0278, 117.4402],
  [-31.0255, 117.4425],
  [-31.0234, 117.4425],
  [-31.0217, 117.4431],
  [-31.0063, 117.4525],
  [-31.0039, 117.4527],
  [-30.9868, 117.4498],
  [-30.9855, 117.4492],
  [-30.9834, 117.4475],
  [-30.9810, 117.4467],
  [-30.9783, 117.4467],
  [-30.9757, 117.4453],
  [-30.9736, 117.4426],
  [-30.9718, 117.4412],
  [-30.9694, 117.4407],
  [-30.9600, 117.4432],
  [-30.9565, 117.4421],
  [-30.9515, 117.4378],
  [-30.9495, 117.4372],
  [-30.9471, 117.4376],
  [-30.9454, 117.4372],
  [-30.9437, 117.4359],
  [-30.9409, 117.4320],
  [-30.9384, 117.4310],
  [-30.9360, 117.4317],
  [-30.9316, 117.4351],
  [-30.9233, 117.4377],
  [-30.9208, 117.4387],
  [-30.9064, 117.4486],
  [-30.8900, 117.4534],
  [-30.8882, 117.4543],
  [-30.8781, 117.4618],
  [-30.8756, 117.4621],
  [-30.8725, 117.4614],
  [-30.8705, 117.4617],
  [-30.8197, 117.4831],
  [-30.8182, 117.4844],
  [-30.8161, 117.4877],
  [-30.8152, 117.4888],
  [-30.8121, 117.4913],
  [-30.8111, 117.4927],
  [-30.8097, 117.4964],
  [-30.8078, 117.5085],
  [-30.8070, 117.5104],
  [-30.8001, 117.5196],
  [-30.7957, 117.5308],
  [-30.7953, 117.5336],
  [-30.7952, 117.5497],
  [-30.7968, 117.5578],
  [-30.7970, 117.5596],
  [-30.7963, 117.5746],
  [-30.7967, 117.5767],
  [-30.7980, 117.5791],
  [-30.7983, 117.5825],
  [-30.7965, 117.5872],
  [-30.7962, 117.5889],
  [-30.7987, 117.6336],
  [-30.7992, 117.6359],
  [-30.8040, 117.6479],
  [-30.8044, 117.6499],
  [-30.8005, 117.6755],
  [-30.7971, 117.6854],
  [-30.7968, 117.6879],
  [-30.7969, 117.7594],
  [-30.7972, 117.7621],
  [-30.7972, 117.7717],
  [-30.7974, 117.7733],
  [-30.8007, 117.7847],
  [-30.8014, 117.7863],
  [-30.8112, 117.8035],
  [-30.8115, 117.8056],
  [-30.8076, 117.8488],
  [-30.8085, 117.8564],
  [-30.8092, 117.8579],
  [-30.8134, 117.8624]
];

const mountMarshallMudkinbudin = [
  [-30.8134, 117.8624],
  [-30.8150, 117.8645],
  [-30.8154, 117.8665],
  [-30.8154, 117.8735],
  [-30.8145, 117.8771],
  [-30.8143, 117.8781],
  [-30.8141, 117.8867],
  [-30.8150, 117.8965],
  [-30.8154, 117.8981],
  [-30.8262, 117.9288],
  [-30.8264, 117.9315],
  [-30.8255, 117.9357],
  [-30.8252, 117.9948],
  [-30.8255, 117.9964],
  [-30.8332, 118.0201],
  [-30.8334, 118.0215],
  [-30.8332, 118.0275],
  [-30.8335, 118.0289],
  [-30.8348, 118.0311],
  [-30.8356, 118.0333],
  [-30.8366, 118.0345],
  [-30.8388, 118.0355],
  [-30.8396, 118.0361],
  [-30.8447, 118.0426],
  [-30.8461, 118.0437],
  [-30.8491, 118.0446],
  [-30.8535, 118.0479],
  [-30.8561, 118.0521],
  [-30.8567, 118.0543],
  [-30.8587, 118.0568],
  [-30.8606, 118.0571],
  [-30.8640, 118.0564],
  [-30.8656, 118.0568],
  [-30.8666, 118.0580],
  [-30.8835, 118.1172],
  [-30.8836, 118.1188],
  [-30.8831, 118.1213],
  [-30.8831, 118.1234],
  [-30.8836, 118.1251],
  [-30.9136, 118.1754],
  [-30.9142, 118.1776],
  [-30.9141, 118.2234]
];

const mukinbudinLakeBrown = [
  [-30.9141, 118.2234],
  [-30.9141, 118.2395],
  [-30.9143, 118.2414],
  [-30.9422, 118.3379],
  [-30.9431, 118.3397],
  [-30.9466, 118.3441]
];

const lakeBrownBullfinch = [
  [-30.9466, 118.3441],
  [-30.9547, 118.3529],
  [-30.9589, 118.3545],
  [-30.9653, 118.3538],
  [-30.9741, 118.3500],
  [-30.9784, 118.3492],
  [-30.9815, 118.3497],
  [-30.9868, 118.3531],
  [-30.9881, 118.3557],
  [-30.9887, 118.3589],
  [-30.9883, 118.3660],
  [-30.9886, 118.3680],
  [-30.9912, 118.3746],
  [-30.9910, 118.3782],
  [-30.9899, 118.3804],
  [-30.9867, 118.3834],
  [-30.9854, 118.3839],
  [-30.9834, 118.3837],
  [-30.9812, 118.3850],
  [-30.9796, 118.3878],
  [-30.9769, 118.3930],
  [-30.9766, 118.3954],
  [-30.9770, 118.4037],
  [-30.9781, 118.4067],
  [-30.9981, 118.4294],
  [-30.9988, 118.4310],
  [-31.0025, 118.4443],
  [-31.0072, 118.4555],
  [-31.0074, 118.4575],
  [-31.0070, 118.4596],
  [-31.0073, 118.4618],
  [-31.0091, 118.4660],
  [-31.0117, 118.4687],
  [-31.0121, 118.4701],
  [-31.0125, 118.4730],
  [-31.0152, 118.4786],
  [-31.0194, 118.4838],
  [-31.0203, 118.4860],
  [-31.0212, 118.4940],
  [-31.0282, 118.5105],
  [-31.0286, 118.5129],
  [-31.0226, 118.5516],
  [-31.0225, 118.5911],
  [-31.0164, 118.6377],
  [-31.0164, 118.6399],
  [-31.0181, 118.6533],
  [-31.0181, 118.6554],
  [-31.0101, 118.7004],
  [-31.0074, 118.7373],
  [-31.0048, 118.7480],
  [-31.0047, 118.7505],
  [-31.0057, 118.7540],
  [-31.0092, 118.7626],
  [-31.0097, 118.7652],
  [-31.0088, 118.7950],
  [-31.0014, 118.8444],
  [-30.9831, 118.8951],
  [-30.9807, 118.9179],
  [-30.9815, 118.9285],
  [-30.9840, 118.9380],
  [-30.9845, 118.9808],
  [-30.9845, 119.0099],
  [-30.9851, 119.0132],
  [-30.9851, 119.0974],
  [-30.9858, 119.1042],
  [-30.9864, 119.1068],
  [-30.9826, 119.1109]
];

const bullfinchSouthernCross = [
  [-30.9826, 119.1109],
  [-30.9804, 119.1144],
  [-30.9802, 119.1156],
  [-30.9804, 119.1168],
  [-30.9852, 119.1268],
  [-30.9884, 119.1301],
  [-30.9931, 119.1318],
  [-31.0049, 119.1391],
  [-31.0101, 119.1409],
  [-31.0114, 119.1418],
  [-31.0164, 119.1467],
  [-31.0175, 119.1473],
  [-31.0266, 119.1493],
  [-31.0280, 119.1500],
  [-31.0604, 119.1823],
  [-31.0622, 119.1834],
  [-31.0703, 119.1858],
  [-31.0719, 119.1868],
  [-31.0916, 119.2070],
  [-31.0932, 119.2077],
  [-31.1057, 119.2074],
  [-31.1074, 119.2081],
  [-31.1185, 119.2200],
  [-31.1256, 119.2246],
  [-31.1303, 119.2262],
  [-31.1409, 119.2369],
  [-31.1421, 119.2393],
  [-31.1583, 119.2581],
  [-31.1615, 119.2637],
  [-31.1627, 119.2652],
  [-31.1677, 119.2695],
  [-31.1716, 119.2754],
  [-31.1758, 119.2801],
  [-31.1783, 119.2849],
  [-31.1811, 119.2887],
  [-31.1831, 119.2941],
  [-31.2004, 119.3131],
  [-31.2066, 119.3200],
  [-31.2096, 119.3215],
  [-31.2149, 119.3227],
  [-31.2213, 119.3223],
  [-31.2223, 119.3227],
  [-31.2228, 119.3231]
];

export const wyalkatchemSouthernCross: Line = {
  name: 'Wyalkatchem - SouthernCross',
  state: 'WA',
  segments: [
    {
      segments: [wyalkatchemMountMarshall],
      history: {
        opened: {
          date: '1917-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mountMarshallMudkinbudin],
      history: {
        opened: {
          date: '1923-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [mukinbudinLakeBrown],
      history: {
        opened: {
          date: '1923-10-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-11-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lakeBrownBullfinch],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-11-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [bullfinchSouthernCross],
      history: {
        opened: {
          date: '1911-3-08',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-11-29',
          status: 'closed'
        }]
      }
    }
  ]
};
