import { Line } from "../../../trackTypes";

const borderTunnel = [
  [-28.3324, 152.9676],
  [-28.3272, 152.9704]
];

const borderTunnelRunningCreekTunnel1 = [
  [-28.3272, 152.9704],
  [-28.3250, 152.9714],
  [-28.3241, 152.9715],
  [-28.3230, 152.9710],
  [-28.3223, 152.9698],
  [-28.3221, 152.9668],
  [-28.3225, 152.9613],
  [-28.3223, 152.9605],
  [-28.3217, 152.9593],
  [-28.3215, 152.9586],
  [-28.3215, 152.9569],
  [-28.3214, 152.9562],
  [-28.3210, 152.9551],
  [-28.3209, 152.9540],
  [-28.3212, 152.9523],
  [-28.3216, 152.9516],
  [-28.3226, 152.9504],
  [-28.3229, 152.9494],
  [-28.3228, 152.9473],
  [-28.3223, 152.9458],
  [-28.3221, 152.9449],
  [-28.3223, 152.9438],
  [-28.3227, 152.9431]
];

const runningCreekTunnel1 = [
  [-28.3227, 152.9431],
  [-28.3231, 152.9422],
  [-28.3234, 152.9414],
  [-28.3235, 152.9406],
  [-28.3234, 152.9396],
  [-28.3232, 152.9390]
];

const runningCreek = [
  [-28.3232, 152.9390],
  [-28.3227, 152.9366],
  [-28.3221, 152.9350],
  [-28.3220, 152.9338],
  [-28.3225, 152.9326],
  [-28.3232, 152.9320],
  [-28.3238, 152.9312],
  [-28.3241, 152.9300],
  [-28.3240, 152.9289]
];

const runningCreekTunnel2 = [
  [-28.3240, 152.9289],
  [-28.3236, 152.9268]
];

const runningCreekTunnel2Sailsbury = [
  [-28.3236, 152.9268],
  [-28.3236, 152.9265],
  [-28.3238, 152.9248],
  [-28.3235, 152.9236],
  [-28.3228, 152.9227],
  [-28.3220, 152.9224],
  [-28.3213, 152.9219],
  [-28.3199, 152.9200],
  [-28.3181, 152.9186],
  [-28.3171, 152.9171],
  [-28.3158, 152.9157],
  [-28.3153, 152.9149],
  [-28.3124, 152.9061],
  [-28.3108, 152.9045],
  [-28.3100, 152.9041],
  [-28.3083, 152.9041],
  [-28.3073, 152.9046],
  [-28.3056, 152.9070],
  [-28.3035, 152.9083],
  [-28.3024, 152.9084],
  [-28.3007, 152.9078],
  [-28.2999, 152.9076],
  [-28.2981, 152.9077],
  [-28.2973, 152.9075],
  [-28.2948, 152.9062],
  [-28.2936, 152.9059],
  [-28.2849, 152.9056],
  [-28.2826, 152.9052],
  [-28.2817, 152.9047],
  [-28.2806, 152.9035],
  [-28.2791, 152.9027],
  [-28.2753, 152.9023],
  [-28.2741, 152.9025],
  [-28.2689, 152.9052],
  [-28.2678, 152.9054],
  [-28.2668, 152.9052],
  [-28.2636, 152.9033],
  [-28.2617, 152.9028],
  [-28.2599, 152.9031],
  [-28.2569, 152.9044],
  [-28.2549, 152.9040],
  [-28.2538, 152.9024],
  [-28.2538, 152.9008],
  [-28.2542, 152.8991],
  [-28.2543, 152.8981],
  [-28.2539, 152.8966],
  [-28.2484, 152.8871],
  [-28.2461, 152.8849],
  [-28.2449, 152.8842],
  [-28.2435, 152.8840],
  [-28.2297, 152.8864],
  [-28.2269, 152.8879],
  [-28.2253, 152.8878],
  [-28.2225, 152.8862],
  [-28.2212, 152.8860],
  [-28.2194, 152.8862],
  [-28.2186, 152.8861],
  [-28.2179, 152.8857],
  [-28.2162, 152.8838],
  [-28.2120, 152.8819],
  [-28.2105, 152.8819],
  [-28.2076, 152.8829],
  [-28.2058, 152.8832],
  [-28.2037, 152.8832],
  [-28.2016, 152.8838],
  [-28.2001, 152.8848],
  [-28.1989, 152.8850],
  [-28.1979, 152.8848],
  [-28.1959, 152.8837],
  [-28.1943, 152.8837],
  [-28.1929, 152.8843],
  [-28.1920, 152.8857],
  [-28.1908, 152.8906],
  [-28.1898, 152.8921],
  [-28.1880, 152.8925],
  [-28.1842, 152.8919],
  [-28.1799, 152.8922],
  [-28.1785, 152.8926],
  [-28.1752, 152.8944],
  [-28.1727, 152.8951],
  [-28.1711, 152.8961],
  [-28.1674, 152.8997],
  [-28.1636, 152.9015],
  [-28.1553, 152.9084],
  [-28.1534, 152.9092],
  [-28.1442, 152.9103],
  [-28.1425, 152.9110],
  [-28.1406, 152.9125],
  [-28.1388, 152.9132],
  [-28.1296, 152.9135],
  [-28.1276, 152.9142],
  [-28.1251, 152.9161],
  [-28.1215, 152.9168],
  [-28.1108, 152.9155],
  [-28.1092, 152.9151],
  [-28.0994, 152.9108],
  [-28.0983, 152.9106],
  [-28.0924, 152.9107],
  [-28.0911, 152.9110],
  [-28.0861, 152.9128],
  [-28.0843, 152.9130],
  [-28.0816, 152.9126],
  [-28.0797, 152.9126],
  [-28.0691, 152.9152],
  [-28.0595, 152.9153],
  [-28.0519, 152.9171],
  [-28.0452, 152.9170],
  [-28.0432, 152.9174],
  [-28.0371, 152.9206],
  [-28.0316, 152.9216],
  [-28.0286, 152.9220],
  [-28.0221, 152.9249],
  [-28.0138, 152.9269],
  [-27.9989, 152.9276],
  [-27.9957, 152.9284],
  [-27.9940, 152.9283],
  [-27.9892, 152.9271],
  [-27.9776, 152.9286],
  [-27.9761, 152.9284],
  [-27.9729, 152.9266],
  [-27.9710, 152.9262],
  [-27.9659, 152.9261],
  [-27.9639, 152.9254],
  [-27.9628, 152.9253],
  [-27.9509, 152.9260],
  [-27.9486, 152.9250],
  [-27.9452, 152.9220],
  [-27.9429, 152.9213],
  [-27.9410, 152.9215],
  [-27.9389, 152.9225],
  [-27.9371, 152.9225],
  [-27.9312, 152.9209],
  [-27.9282, 152.9210],
  [-27.9152, 152.9241],
  [-27.9128, 152.9240],
  [-27.9089, 152.9229],
  [-27.9067, 152.9228],
  [-27.8893, 152.9287],
  [-27.8873, 152.9288],
  [-27.8630, 152.9236],
  [-27.8579, 152.9234],
  [-27.8534, 152.9247],
  [-27.8405, 152.9245],
  [-27.8384, 152.9253],
  [-27.8263, 152.9365],
  [-27.8247, 152.9396],
  [-27.8235, 152.9411],
  [-27.8185, 152.9443],
  [-27.8103, 152.9475],
  [-27.8041, 152.9486],
  [-27.8030, 152.9489],
  [-27.7816, 152.9616],
  [-27.7798, 152.9622],
  [-27.7694, 152.9639],
  [-27.7559, 152.9689],
  [-27.7499, 152.9695],
  [-27.7431, 152.9694],
  [-27.7410, 152.9705],
  [-27.7395, 152.9727],
  [-27.7386, 152.9761],
  [-27.7369, 152.9781],
  [-27.7177, 152.9886],
  [-27.6975, 153.0033],
  [-27.6950, 153.0039],
  [-27.6914, 153.0035],
  [-27.6885, 153.0044],
  [-27.6851, 153.0073],
  [-27.6835, 153.0093],
  [-27.6808, 153.0146],
  [-27.6785, 153.0168],
  [-27.6698, 153.0206],
  [-27.6678, 153.0208],
  [-27.6665, 153.0202],
  [-27.6647, 153.0190],
  [-27.6626, 153.0186],
  [-27.6544, 153.0197],
  [-27.6521, 153.0192],
  [-27.6449, 153.0154],
  [-27.6426, 153.0149],
  [-27.6398, 153.0155],
  [-27.6332, 153.0192],
  [-27.6280, 153.0222],
  [-27.6247, 153.0227],
  [-27.6228, 153.0225],
  [-27.6216, 153.0225],
  [-27.6151, 153.0242],
  [-27.6101, 153.0267],
  [-27.6088, 153.0279],
  [-27.6069, 153.0311],
  [-27.6045, 153.0325],
  [-27.6023, 153.0325],
  [-27.6005, 153.0314],
  [-27.5987, 153.0290],
  [-27.5974, 153.0281],
  [-27.5960, 153.0277],
  [-27.5886, 153.0291],
  [-27.5855, 153.0290],
  [-27.5807, 153.0295],
  [-27.5652, 153.0286],
  [-27.5614, 153.0280],
  [-27.5591, 153.0268],
  [-27.5576, 153.0254]
];

export const northCoastStandardGauge: Line = {
  name: 'North Coast (Standard Gauge)',
  state: 'QLD',
  segments: [
    {
      segments: [
        {
          name: 'Border Tunnel',
          segment: borderTunnel,
          type: 'tunnel'
        },
        borderTunnelRunningCreekTunnel1,
        {
          name: 'Running Creek No 1 Tunnel',
          segment: runningCreekTunnel1,
          type: 'tunnel'
        },
        runningCreek,
        {
          name: 'Running Creek No 2 Tunnel',
          segment: runningCreekTunnel2,
          type: 'tunnel'
        },
        runningCreekTunnel2Sailsbury
      ],
      history: {
        opened: {
          date: '1930-09-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
}
