import { Line } from "../../../../trackTypes";

const line = [
  [-37.79978, 144.89973],
  [-37.79885, 144.89164],
  [-37.79852, 144.88841],
  [-37.79848, 144.88751],
  [-37.79578, 144.86290]
];

export const footscrayWest: Line = {
  name: 'Footscray West',
  state: 'VIC',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
