import { Line } from "../../../../trackTypes";

const victoriaStreet = [
  [-37.78387, 144.93666],
  [-37.78388, 144.93655],
  [-37.78392, 144.93646],
  [-37.78832, 144.93326],
  [-37.78839, 144.93317],
  [-37.78843, 144.93300]
];

const racecourseRoad = [
  [-37.78953, 144.94234],
  [-37.78942, 144.94210],
  [-37.78937, 144.94184],
  [-37.78843, 144.93300]
];

const maribyrnong = [
  [-37.78843, 144.93300],
  [-37.78836, 144.93233],
  [-37.78836, 144.93214],
  [-37.78839, 144.93195],
  [-37.78840, 144.93179],
  [-37.78741, 144.92242],
  [-37.78739, 144.92216],
  [-37.78742, 144.92147],
  [-37.78740, 144.92133],
  [-37.78732, 144.92119],
  [-37.78062, 144.91478],
  [-37.78046, 144.91474],
  [-37.77189, 144.91615],
  [-37.77180, 144.91612],
  [-37.77170, 144.91595],
  [-37.77021, 144.90251]
];

const explosivesFactory = [
  [-37.77021, 144.90251],
  [-37.76866, 144.88944],
  [-37.76866, 144.88911],
  [-37.76895, 144.88699],
  [-37.76891, 144.88660],
  [-37.76938, 144.88314],
  [-37.76940, 144.88269],
  [-37.76880, 144.87789]
];

const ordnanceFactory = [
  [-37.76938, 144.88314],
  [-37.76948, 144.88297],
  [-37.76962, 144.88288],
  [-37.77498, 144.88188]
];

const ammunitionFactory = [
  [-37.79122, 144.88965],
  [-37.79113, 144.88956],
  [-37.79106, 144.88954],
  [-37.78023, 144.89162]
];

const maidstone = [
  [-37.77498, 144.88188],
  [-37.77508, 144.88193],
  [-37.77513, 144.88203],
  [-37.77555, 144.88547],
  [-37.77563, 144.88562],
  [-37.77580, 144.88568],
  [-37.77886, 144.88510],
  [-37.77908, 144.88518],
  [-37.77924, 144.88544],
  [-37.77999, 144.89139],
  [-37.78008, 144.89157],
  [-37.78023, 144.89162]
];

const mooneePondsConnector = [
  [-37.77170, 144.91595],
  [-37.77254, 144.92373],
  [-37.77252, 144.92383],
  [-37.77248, 144.92391],
  [-37.77239, 144.92396],
  [-37.76711, 144.92485]
];

const maidstoneDepot = [
  [-37.77508, 144.88193],
  [-37.77504, 144.88119],
  [-37.77505, 144.88108],
  [-37.77509, 144.88097],
  [-37.77588, 144.87973],
  [-37.77586, 144.87967],
  [-37.77586, 144.87961],
  [-37.77546, 144.87915]
];

export const west3: Line = {
  name: 'West3',
  state: 'VIC',
  segments: [
    {
      segments: [
        {
          date: '1928-01-01',
          original: [victoriaStreet],
          deviation: [racecourseRoad]
        },
        maribyrnong
      ],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [explosivesFactory],
      history: {
        opened: {
          date: '1940-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [ordnanceFactory],
      history: {
        opened: {
          date: '1941-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [ammunitionFactory],
      history: {
        opened: {
          date: '1942-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [maidstone],
      history: {
        opened: {
          date: '1954-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [mooneePondsConnector],
      history: {
        opened: {
          date: '1942-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [maidstoneDepot],
      underConstruction: true,
      history: {
        opened: {
          date: '',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
