import { Line } from "../../trackTypes";

const junction = [
  [-32.1826, 140.6076],
  [-32.1833, 140.6068],
  [-32.1839, 140.6063],
  [-32.1846, 140.6061],
  [-32.1853, 140.6063]
];

const line = [
  [-32.1843, 140.6010],
  [-32.1844, 140.6024],
  [-32.1846, 140.6050],
  [-32.1847, 140.6055],
  [-32.1853, 140.6063],
  [-32.2472, 140.6415],
  [-32.2517, 140.6426],
  [-32.2690, 140.6400],
  [-32.2862, 140.6361],
  [-32.2890, 140.6359],
  [-32.3073, 140.6376],
  [-32.3094, 140.6381],
  [-32.3124, 140.6397],
  [-32.3155, 140.6398],
  [-32.3193, 140.6380],
  [-32.3223, 140.6374],
  [-32.3452, 140.6365]
];

export const radiumHill: Line = {
  name: 'Radium Hill',
  state: 'SA',
  segments: [
    {
      segments: [line, junction],
      history: {
        opened: {
          date: '1953-10-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1961-12-01',
          status: 'closed'
        }]
      }
    }
  ]
};
