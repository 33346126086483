import { Line } from "../../../../trackTypes";

const cathedralSquare = [
  [-43.53095, 172.63413],
  [-43.53095, 172.63531],
  [-43.53092, 172.63550],
  [-43.53046, 172.63649],
  [-43.53043, 172.63666],
  [-43.53045, 172.63683],
  [-43.53085, 172.63774],
  [-43.53086, 172.63782],
  [-43.53090, 172.63812],
  [-43.53090, 172.63847]
];

const cemetery = [
  [-43.53090, 172.63847],
  [-43.53091, 172.63887],
  [-43.53092, 172.66879],
  [-43.53095, 172.66899],
  [-43.53098, 172.66916],
  [-43.53348, 172.67293],
  [-43.53349, 172.67310],
  [-43.53345, 172.67327],
  [-43.52912, 172.67867],
  [-43.52878, 172.67919],
  [-43.52654, 172.68233],
];

const cemeteryOld = [
  [-43.52654, 172.68233],
  [-43.52640, 172.68261],
  [-43.52638, 172.68287],
  [-43.52642, 172.68424],
  [-43.52643, 172.68452],
  [-43.52644, 172.68463],
  [-43.52648, 172.68472],
  [-43.52744, 172.68602]
];

const cemeteryDeviation = [
  [-43.52654, 172.68233],
  [-43.52461, 172.68513],

];

const cemeteryBranch = [
  [-43.52878, 172.67919],
  [-43.52874, 172.67939],
  [-43.52878, 172.67957],
  [-43.52958, 172.68068],
  [-43.53144, 172.68259]
];

const brightonLink = [
  [-43.52642, 172.68424],
  [-43.52639, 172.68433],
  [-43.52635, 172.68439],
  [-43.52477, 172.68504],
  [-43.52470, 172.68508],
  [-43.52461, 172.68513]
];

const newBrighton = [
  [-43.52461, 172.68513],
  [-43.52409, 172.68581],
  [-43.52391, 172.68616],
  [-43.52378, 172.68648],
  [-43.52370, 172.68687],
  [-43.52367, 172.68722],
  [-43.52365, 172.68819],
  [-43.52359, 172.68853],
  [-43.52350, 172.68886],
  [-43.52304, 172.69009],
  [-43.52150, 172.69471],
  [-43.52136, 172.69503],
  [-43.52046, 172.69659],
  [-43.51738, 172.70149],
  [-43.51722, 172.70183],
  [-43.50935, 172.72089],
  [-43.50924, 172.72119],
  [-43.50731, 172.72919],
  [-43.50700, 172.73060]
];

const brightonBranch = [
  [-43.50731, 172.72919],
  [-43.50731, 172.72933],
  [-43.50736, 172.72939],
  [-43.50880, 172.72997]
];

const worcesterStreet = [
  [-43.53092, 172.66879],
  [-43.53089, 172.66889],
  [-43.53080, 172.66909],
  [-43.53073, 172.66920],
  [-43.53012, 172.66996],
  [-43.53004, 172.66997],
  [-43.52998, 172.66995],
  [-43.52914, 172.66869],
  [-43.52908, 172.66865],
  [-43.52900, 172.66868],
  [-43.52575, 172.67271],
  [-43.52568, 172.67277],
  [-43.52562, 172.67278],
  [-43.52184, 172.67275]
];

const cashelStreetLink = [
  [-43.53309, 172.65683],
  [-43.53302, 172.67196],
  [-43.53304, 172.67216],
  [-43.53307, 172.67231]
];

export const line4: Line = {
  name: 'Line 4',
  state: 'NZ',
  segments: [
    {
      segments: [cathedralSquare],
      history: {
        opened: {
          date: '1886-04-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-03-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-10-18',
          status: 'closed'
        }, {
          date: '1995-02-04',
          status: 'open'
        }]
      }
    },
    {
      segments: [
        cemetery,
        {
          date: '1906-03-26',
          original: [cemeteryOld],
          deviation: [cemeteryDeviation]
        }
      ],
      history: {
        opened: {
          date: '1886-04-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-03-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cemeteryBranch],
      history: {
        opened: {
          date: '1886-04-23',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-03-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brightonLink],
      history: {
        opened: {
          date: '1887-02-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-03-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newBrighton],
      history: {
        opened: {
          date: '1887-02-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-03-26',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brightonBranch],
      history: {
        opened: {
          date: '1887-02-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1906-03-26',
          status: 'closed'
        }]
      }
    },
    {
      segments: [worcesterStreet],
      history: {
        opened: {
          date: '1906-03-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cashelStreetLink],
      history: {
        opened: {
          date: '1910-11-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-10-18',
          status: 'closed'
        }]
      }
    }
  ]
}
