import { Line } from "../../../trackTypes";

const cityCircleTunnel = [
  [-33.8634, 151.2054],
  [-33.8622, 151.2052],
  [-33.8606, 151.2055],
  [-33.8594, 151.2060],
  [-33.8589, 151.2063]
];

const wynyardNorthSydneyTunnel = [
  [-33.8589, 151.2063],
  [-33.8491, 151.2123],
  [-33.8480, 151.2125],
  [-33.8470, 151.2123],
  [-33.8441, 151.2111],
  [-33.8425, 151.2100],
  [-33.8418, 151.2093],
  [-33.8415, 151.2088]
];

const northSydneyTunnel = [
  [-33.8415, 151.2088],
  [-33.8413, 151.2082],
  [-33.8402, 151.2021]
];

const northSydneyTunnelWavertonJunction = [
  [-33.8402, 151.2021],
  [-33.8400, 151.2011],
  [-33.8393, 151.1989]
];

const milsonsPointOneMilsonsPointTwo = [
  [-33.8507, 151.2124],
  [-33.8487, 151.2103],
  [-33.8479, 151.2098]
];

const milsonsPointTwoSydneyCarSidings = [
  [-33.8479, 151.2098],
  [-33.8476, 151.2098],
  [-33.8463, 151.2100]
];

const sydneyCarSidingsLavenderBayTunnel = [
  [-33.8465, 151.2100],
  [-33.8452, 151.2098],
  [-33.8446, 151.2093],
  [-33.8440, 151.2082],
  [-33.8439, 151.2068],
  [-33.8442, 151.2059]
];

const lavenderBayTunnel = [
  [-33.8442, 151.2059],
  [-33.8452, 151.2041],
  [-33.8455, 151.2032]
];

const lavenderBayTunnelWavertonJunction = [
  [-33.8455, 151.2032],
  [-33.8455, 151.2027],
  [-33.8451, 151.2018],
  [-33.8443, 151.2012],
  [-33.8429, 151.2010],
  [-33.8414, 151.2007],
  [-33.8402, 151.2001],
  [-33.8393, 151.1989]
];

const wavertonJunctionWaverton = [
  [-33.8393, 151.1989],
  [-33.8389, 151.1983],
  [-33.8383, 151.1977],
  [-33.8376, 151.1973]
];

const wavertonBayRoadTunnel = [
  [-33.8376, 151.1973],
  [-33.8367, 151.1971]
];

const bayRoadTunnel = [
  [-33.8367, 151.1971],
  [-33.8354, 151.1972]
];

const bayRoadTunnelStLeonards = [
  [-33.8354, 151.1972],
  [-33.8350, 151.1972],
  [-33.8343, 151.1970],
  [-33.8336, 151.1963],
  [-33.8333, 151.1953],
  [-33.8333, 151.1947],
  [-33.8334, 151.1938],
  [-33.8332, 151.1930],
  [-33.8326, 151.1920],
  [-33.8317, 151.1917],
  [-33.8308, 151.1919],
  [-33.8302, 151.1924],
  [-33.8298, 151.1932],
  [-33.8295, 151.1943],
  [-33.8288, 151.1952],
  [-33.8279, 151.1957],
  [-33.8271, 151.1956],
  [-33.8248, 151.1947],
  [-33.8238, 151.1944],
  [-33.8221, 151.1941]
];

const stLeonardLindfield = [
  [-33.8221, 151.1941],
  [-33.8174, 151.1933],
  [-33.8155, 151.1933],
  [-33.8145, 151.1930],
  [-33.8135, 151.1926],
  [-33.8124, 151.1917],
  [-33.8116, 151.1908],
  [-33.8110, 151.1898],
  [-33.8093, 151.1860],
  [-33.8081, 151.1842],
  [-33.8070, 151.1830],
  [-33.8058, 151.1821],
  [-33.8043, 151.1814],
  [-33.8023, 151.1810],
  [-33.7895, 151.1807],
  [-33.7882, 151.1805],
  [-33.7868, 151.1799],
  [-33.7853, 151.1787],
  [-33.7843, 151.1776],
  [-33.7822, 151.1734],
  [-33.7811, 151.1718],
  [-33.7800, 151.1708],
  [-33.7782, 151.1700],
  [-33.7769, 151.1698],
  [-33.7759, 151.1695],
  [-33.7755, 151.1692]
];

const lindfieldHornsby = [
  [-33.7755, 151.1692],
  [-33.7697, 151.1650],
  [-33.7537, 151.1527],
  [-33.7529, 151.1520],
  [-33.7523, 151.1509],
  [-33.7516, 151.1488],
  [-33.7509, 151.1480],
  [-33.7502, 151.1473],
  [-33.7467, 151.1455],
  [-33.7458, 151.1447],
  [-33.7453, 151.1439],
  [-33.7450, 151.1431],
  [-33.7442, 151.1409],
  [-33.7428, 151.1382],
  [-33.7418, 151.1371],
  [-33.7269, 151.1235],
  [-33.7196, 151.1189],
  [-33.7183, 151.1178],
  [-33.7164, 151.1158],
  [-33.7151, 151.1140],
  [-33.7100, 151.1042],
  [-33.7054, 151.0986],
  [-33.7049, 151.0983],
  [-33.7040, 151.0982]
];

export const northShore: Line = {
  name: 'North Shore',
  state: 'NSW',
  segments: [
    {
      segments: [milsonsPointOneMilsonsPointTwo],
      history: {
        opened: {
          date: '1893-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-01-01',
          tracks: 2
        }, {
          date: '1924-05-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [milsonsPointTwoSydneyCarSidings],
      history: {
        opened: {
          date: '1893-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-01-01',
          tracks: 2
        }, {
          date: '1927-08-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1932-03-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        {
          name: 'City Circle',
          segment: cityCircleTunnel,
          type: 'tunnel'
        },
        wynyardNorthSydneyTunnel,
        {
          name: 'North Sydney Tunnel',
          segment: northSydneyTunnel,
          type: 'tunnel'
        },
        northSydneyTunnelWavertonJunction
      ],
      history: {
        opened: {
          date: '1932-02-28',
          tracks: 2,
          gauge: 'standard',
          electrified: {
            current: 'DC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        sydneyCarSidingsLavenderBayTunnel,
        {
          name: 'Lavender Bay Tunnel',
          segment: lavenderBayTunnel,
          type: 'tunnel'
        },
        lavenderBayTunnelWavertonJunction
      ],
      history: {
        opened: {
          date: '1893-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-01-01',
          tracks: 2
        }, {
          date: '1927-08-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1932-03-20',
          tracks: 1
        }]
      }
    },
    {
      segments: [
        sydneyCarSidingsLavenderBayTunnel,
        {
          name: 'Lavender Bay Tunnel',
          segment: lavenderBayTunnel,
          type: 'tunnel'
        },
        wavertonJunctionWaverton
      ],
      history: {
        opened: {
          date: '1893-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-01-01',
          tracks: 2
        }, {
          date: '1927-08-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [
        wavertonBayRoadTunnel,
        {
          name: 'Bay Road Tunnel',
          segment: bayRoadTunnel,
          type: 'tunnel'
        },
        bayRoadTunnelStLeonards
      ],
      history: {
        opened: {
          date: '1893-05-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1912-01-01',
          tracks: 2
        }, {
          date: '1927-08-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [stLeonardLindfield],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1900-10-07',
          tracks: 2
        }, {
          date: '1927-08-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [lindfieldHornsby],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1909-10-09',
          tracks: 2
        }, {
          date: '1929-07-15',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    }
  ]
};
