import { Line } from "../../trackTypes";

const line = [
  [-13.6703, 131.7692],
  [-13.6727, 131.7707],
  [-13.6735, 131.7725],
  [-13.6733, 131.7753],
  [-13.6737, 131.7772],
  [-13.6760, 131.7800],
  [-13.6769, 131.7823],
  [-13.6756, 131.7928],
  [-13.6746, 131.7943],
  [-13.6732, 131.7952],
  [-13.6722, 131.7959],
  [-13.6671, 131.8037],
  [-13.6656, 131.8052],
  [-13.6649, 131.8069],
  [-13.6654, 131.8099],
  [-13.6649, 131.8116],
  [-13.6642, 131.8125],
  [-13.6629, 131.8139],
  [-13.6588, 131.8220],
  [-13.6552, 131.8270],
  [-13.6444, 131.8336],
  [-13.6295, 131.8366],
  [-13.6240, 131.8389],
  [-13.6205, 131.8390],
  [-13.6157, 131.8375],
  [-13.6137, 131.8376],
  [-13.6123, 131.8388],
  [-13.6095, 131.8441],
  [-13.6080, 131.8481],
  [-13.6068, 131.8494],
  [-13.6057, 131.8499],
  [-13.6035, 131.8502],
  [-13.6027, 131.8507]
];

export const francesCreek: Line = {
  name: 'Frances Creek',
  state: 'NT',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1968-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1976-05-01',
          status: 'closed'
        }]
      }
    }
  ]
};
