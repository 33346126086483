import { Line } from "../../trackTypes";

const aberdareJunctionStanfordMerthyr = [
  [-32.7745, 151.5128],
  [-32.7756, 151.5116],
  [-32.7769, 151.5111],
  [-32.7822, 151.5104],
  [-32.7834, 151.5098],
  [-32.7845, 151.5095],
  [-32.7858, 151.5097],
  [-32.7899, 151.5089],
  [-32.7985, 151.5057],
  [-32.8046, 151.5028],
  [-32.8168, 151.4981],
  [-32.8183, 151.4973],
  [-32.8201, 151.4951],
  [-32.8231, 151.4899],
  [-32.8229, 151.4889],
  [-32.8225, 151.4879]
];

const stanfordMerthyrPelawMain = [
  [-32.8231, 151.4899],
  [-32.8256, 151.4829],
  [-32.8259, 151.4796]
];

export const stanfordMerthyr: Line = {
  name: 'Stanford Merthyr',
  state: 'NSW',
  segments: [
    {
      segments: [aberdareJunctionStanfordMerthyr],
      history: {
        opened: {
          date: '1902-01-17',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1934-07-27',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stanfordMerthyrPelawMain],
      history: {
        opened: {
          date: '1902-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1905-06-23',
          status: 'closed'
        }]
      }
    }
  ]
};
