import { Line } from "../../../trackTypes";

const junctionDomestic = [
  [-27.4109, 153.0563],
  [-27.4103, 153.0572],
  [-27.4100, 153.0581],
  [-27.4100, 153.0589],
  [-27.4104, 153.0617],
  [-27.4106, 153.0623],
  [-27.4113, 153.0636],
  [-27.4117, 153.0645],
  [-27.4118, 153.0657],
  [-27.4117, 153.0670],
  [-27.4110, 153.0684],
  [-27.4097, 153.0702],
  [-27.4090, 153.0715],
  [-27.4085, 153.0732],
  [-27.4084, 153.0757],
  [-27.4092, 153.0780],
  [-27.4105, 153.0798],
  [-27.4133, 153.0825],
  [-27.4143, 153.0839],
  [-27.4150, 153.0855],
  [-27.4153, 153.0879],
  [-27.4149, 153.0903],
  [-27.4137, 153.0925],
  [-27.4075, 153.0998],
  [-27.4060, 153.1024],
  [-27.4055, 153.1051],
  [-27.4051, 153.1061],
  [-27.4038, 153.1079],
  [-27.4032, 153.1083],
];

const domesticInternational = [
  [-27.4032, 153.1083],
  [-27.4000, 153.1101],
  [-27.3980, 153.1109],
  [-27.3875, 153.1169],
  [-27.3869, 153.1174],
  [-27.3849, 153.1190]
];

export const airport: Line = {
  name: 'Airtrain',
  state: 'QLD',
  segments: [
    {
      segments: [junctionDomestic],
      history: {
        opened: {
          date: '2001-05-05',
          tracks: 1,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [domesticInternational],
      history: {
        opened: {
          date: '2001-05-05',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
}
