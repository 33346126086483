import { Line } from "../../trackTypes";

const burakinKulja = [
  [-30.5223, 117.1745],
  [-30.5212, 117.1758],
  [-30.5180, 117.1816],
  [-30.5174, 117.1832],
  [-30.5160, 117.1921],
  [-30.5155, 117.1935],
  [-30.5107, 117.2041],
  [-30.5087, 117.2061],
  [-30.5068, 117.2068],
  [-30.5054, 117.2081],
  [-30.5033, 117.2116],
  [-30.5029, 117.2134],
  [-30.5027, 117.2177],
  [-30.5023, 117.2193],
  [-30.5015, 117.2206],
  [-30.4989, 117.2234],
  [-30.4980, 117.2252],
  [-30.4955, 117.2352],
  [-30.4954, 117.2372],
  [-30.4966, 117.2460],
  [-30.4964, 117.2478],
  [-30.4907, 117.2643],
  [-30.4901, 117.2663],
  [-30.4892, 117.2717],
  [-30.4885, 117.2734],
  [-30.4829, 117.2807]
];

const kuljaBeacon = [
  [-30.4829, 117.2807],
  [-30.4823, 117.2826],
  [-30.4781, 117.2953],
  [-30.4777, 117.2989],
  [-30.4773, 117.3005],
  [-30.4727, 117.3093],
  [-30.4722, 117.3114],
  [-30.4731, 117.3197],
  [-30.4737, 117.3219],
  [-30.4776, 117.3313],
  [-30.4783, 117.3362],
  [-30.4810, 117.3452],
  [-30.4810, 117.3479],
  [-30.4798, 117.3501],
  [-30.4762, 117.3532],
  [-30.4753, 117.3549],
  [-30.4742, 117.3610],
  [-30.4730, 117.3636],
  [-30.4712, 117.3656],
  [-30.4702, 117.3680],
  [-30.4702, 117.3699],
  [-30.4723, 117.3797],
  [-30.4724, 117.3813],
  [-30.4713, 117.3871],
  [-30.4706, 117.3887],
  [-30.4670, 117.3935],
  [-30.4664, 117.3955],
  [-30.4665, 117.3972],
  [-30.4678, 117.4003],
  [-30.4680, 117.4030],
  [-30.4658, 117.4102],
  [-30.4657, 117.4118],
  [-30.4677, 117.4506],
  [-30.4673, 117.4533],
  [-30.4648, 117.4627],
  [-30.4612, 117.4708],
  [-30.4595, 117.4740],
  [-30.4593, 117.4763],
  [-30.4601, 117.4783],
  [-30.4631, 117.4811],
  [-30.4641, 117.4835],
  [-30.4638, 117.4944],
  [-30.4634, 117.4965],
  [-30.4588, 117.5100],
  [-30.4535, 117.5218],
  [-30.4533, 117.5237],
  [-30.4535, 117.5251],
  [-30.4567, 117.5312],
  [-30.4573, 117.5334],
  [-30.4572, 117.5368],
  [-30.4534, 117.5468],
  [-30.4523, 117.5522],
  [-30.4524, 117.5592],
  [-30.4517, 117.5640],
  [-30.4517, 117.5657],
  [-30.4528, 117.5704],
  [-30.4526, 117.5730],
  [-30.4471, 117.5870],
  [-30.4398, 117.6143],
  [-30.4350, 117.6426],
  [-30.4349, 117.6942],
  [-30.4352, 117.6961],
  [-30.4372, 117.7024],
  [-30.4374, 117.7043],
  [-30.4359, 117.7186],
  [-30.4358, 117.7587],
  [-30.4350, 117.7672],
  [-30.4351, 117.7695],
  [-30.4485, 117.8674],
  [-30.4484, 117.8719]
];

const beaconBonnieRock = [
  [-30.4484, 117.8719],
  [-30.4482, 117.8822],
  [-30.4501, 117.8920],
  [-30.4651, 117.9434],
  [-30.4678, 117.9600],
  [-30.4677, 117.9839],
  [-30.4662, 117.9894],
  [-30.4663, 117.9920],
  [-30.4681, 117.9952],
  [-30.4703, 118.0015],
  [-30.4714, 118.0070],
  [-30.4714, 118.0094],
  [-30.4719, 118.0111],
  [-30.4734, 118.0133],
  [-30.4743, 118.0155],
  [-30.4752, 118.0166],
  [-30.4768, 118.0178],
  [-30.4780, 118.0195],
  [-30.4783, 118.0211],
  [-30.4779, 118.0255],
  [-30.4782, 118.0285],
  [-30.4826, 118.0420],
  [-30.4848, 118.0454],
  [-30.4854, 118.0472],
  [-30.4851, 118.0491],
  [-30.4830, 118.0530],
  [-30.4814, 118.0543],
  [-30.4793, 118.0550],
  [-30.4776, 118.0565],
  [-30.4771, 118.0587],
  [-30.4773, 118.0622],
  [-30.4758, 118.0741],
  [-30.4746, 118.0784],
  [-30.4744, 118.0808],
  [-30.4778, 118.1101],
  [-30.4776, 118.1118],
  [-30.4769, 118.1154],
  [-30.4767, 118.1238],
  [-30.4765, 118.1258],
  [-30.4760, 118.1275],
  [-30.4761, 118.1292],
  [-30.4919, 118.1737],
  [-30.4947, 118.1841],
  [-30.4947, 118.1866],
  [-30.4934, 118.1917],
  [-30.4935, 118.1945],
  [-30.4972, 118.2021],
  [-30.5000, 118.2061],
  [-30.5005, 118.2089],
  [-30.5000, 118.2119],
  [-30.4999, 118.2183],
  [-30.4991, 118.2207],
  [-30.4925, 118.2283],
  [-30.4917, 118.2306],
  [-30.4918, 118.2324],
  [-30.4952, 118.2398],
  [-30.4958, 118.2418],
  [-30.4965, 118.2494],
  [-30.4968, 118.2599],
  [-30.4976, 118.2624],
  [-30.4996, 118.2644],
  [-30.5006, 118.2661],
  [-30.5016, 118.2684],
  [-30.5026, 118.2701],
  [-30.5094, 118.2762],
  [-30.5108, 118.2780],
  [-30.5160, 118.2882],
  [-30.5217, 118.3024],
  [-30.5222, 118.3093],
  [-30.5235, 118.3135],
  [-30.5237, 118.3151],
  [-30.5237, 118.3174],
  [-30.5238, 118.3186],
  [-30.5283, 118.3310],
  [-30.5301, 118.3331],
  [-30.5309, 118.3355],
  [-30.5305, 118.3401],
  [-30.5317, 118.3478],
  [-30.5327, 118.3499],
  [-30.5344, 118.3516],
  [-30.5353, 118.3537],
  [-30.5352, 118.3599],
  [-30.5358, 118.3682]
];

export const bonnieRock: Line = {
  name: 'Bonnie Rock',
  state: 'WA',
  segments: [
    {
      segments: [burakinKulja],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [kuljaBeacon],
      history: {
        opened: {
          date: '1931-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [beaconBonnieRock],
      history: {
        opened: {
          date: '1931-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1985-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
