import { Line } from "../../trackTypes";

const nynganJunctionCobar = [
  [-31.5538, 147.1803],
  [-31.5532, 147.1785],
  [-31.5530, 147.1761],
  [-31.5533, 147.1742],
  [-31.5539, 147.1724],
  [-31.5638, 147.1538],
  [-31.5654, 147.1499],
  [-31.5666, 147.1456],
  [-31.5807, 147.0709],
  [-31.5811, 147.0597],
  [-31.5637, 146.9486],
  [-31.5514, 146.8702],
  [-31.5500, 146.8583],
  [-31.5466, 146.7575],
  [-31.5466, 146.7501],
  [-31.5483, 146.7301],
  [-31.5518, 146.7037],
  [-31.5544, 146.6951],
  [-31.5547, 146.6926],
  [-31.5528, 146.6483],
  [-31.5591, 146.5374],
  [-31.5590, 146.5358],
  [-31.5584, 146.5347],
  [-31.5574, 146.5333],
  [-31.5569, 146.5316],
  [-31.5565, 146.5237],
  [-31.5568, 146.5214],
  [-31.5581, 146.5182],
  [-31.5584, 146.5166],
  [-31.5582, 146.5150],
  [-31.5571, 146.5127],
  [-31.5566, 146.5109],
  [-31.5558, 146.5016],
  [-31.5552, 146.4998],
  [-31.5538, 146.4968],
  [-31.5534, 146.4956],
  [-31.5534, 146.4945],
  [-31.5542, 146.4881],
  [-31.5541, 146.4856],
  [-31.5488, 146.4591],
  [-31.5473, 146.4538],
  [-31.5470, 146.4514],
  [-31.5475, 146.4465],
  [-31.5471, 146.4338],
  [-31.5468, 146.4319],
  [-31.5430, 146.4158],
  [-31.5334, 146.3559],
  [-31.5323, 146.3521],
  [-31.5272, 146.3430],
  [-31.5262, 146.3398],
  [-31.5259, 146.3367],
  [-31.5262, 146.3338],
  [-31.5288, 146.3238],
  [-31.5292, 146.3191],
  [-31.5285, 146.3015],
  [-31.5288, 146.2980],
  [-31.5310, 146.2775],
  [-31.5310, 146.2763],
  [-31.5305, 146.2744],
  [-31.5292, 146.2714],
  [-31.5286, 146.2680],
  [-31.5287, 146.2662],
  [-31.5282, 146.2637],
  [-31.5254, 146.2567],
  [-31.5251, 146.2551],
  [-31.5253, 146.2528],
  [-31.5262, 146.2508],
  [-31.5278, 146.2486],
  [-31.5285, 146.2465],
  [-31.5286, 146.2440],
  [-31.5280, 146.2422],
  [-31.5263, 146.2390],
  [-31.5256, 146.2370],
  [-31.5255, 146.2355],
  [-31.5257, 146.2324],
  [-31.5251, 146.2164],
  [-31.5244, 146.2127],
  [-31.5226, 146.2084],
  [-31.5221, 146.2062],
  [-31.5221, 146.2042],
  [-31.5227, 146.2017],
  [-31.5228, 146.1999],
  [-31.5223, 146.1960],
  [-31.5224, 146.1943],
  [-31.5229, 146.1926],
  [-31.5248, 146.1899],
  [-31.5253, 146.1886],
  [-31.5254, 146.1870],
  [-31.5238, 146.1775],
  [-31.5238, 146.1690],
  [-31.5241, 146.1669],
  [-31.5259, 146.1616],
  [-31.5261, 146.1594],
  [-31.5256, 146.1573],
  [-31.5225, 146.1519],
  [-31.5219, 146.1497],
  [-31.5216, 146.1443],
  [-31.5222, 146.1407],
  [-31.5232, 146.1378],
  [-31.5236, 146.1351],
  [-31.5232, 146.0741],
  [-31.5230, 146.0716],
  [-31.5198, 146.0538],
  [-31.5195, 146.0481],
  [-31.5202, 146.0427],
  [-31.5202, 146.0397],
  [-31.5130, 145.9784],
  [-31.5122, 145.9751],
  [-31.5104, 145.9705],
  [-31.5076, 145.9656],
  [-31.5070, 145.9637],
  [-31.5073, 145.9617],
  [-31.5079, 145.9606],
  [-31.5097, 145.9586],
  [-31.5102, 145.9572],
  [-31.5104, 145.9557],
  [-31.5056, 145.9153],
  [-31.5050, 145.9137],
  [-31.5034, 145.9119],
  [-31.5029, 145.9110],
  [-31.5006, 145.9041],
  [-31.4986, 145.9004],
  [-31.4978, 145.8979],
  [-31.4976, 145.8951],
  [-31.4986, 145.8853],
  [-31.4984, 145.8839],
  [-31.4975, 145.8822],
  [-31.4959, 145.8805],
  [-31.4953, 145.8792],
  [-31.4952, 145.8780],
  [-31.4953, 145.8765],
  [-31.4953, 145.8753],
  [-31.4941, 145.8696],
  [-31.4931, 145.8658],
  [-31.4926, 145.8615],
  [-31.4928, 145.8575],
  [-31.4939, 145.8508],
  [-31.4938, 145.8471],
  [-31.4920, 145.8393]
];

const cobarCsaMine = [
  [-31.4920, 145.8393],
  [-31.4914, 145.8366],
  [-31.4907, 145.8351],
  [-31.4899, 145.8341],
  [-31.4887, 145.8333],
  [-31.4863, 145.8326],
  [-31.4815, 145.8322],
  [-31.4800, 145.8319],
  [-31.4787, 145.8313],
  [-31.4748, 145.8289],
  [-31.4687, 145.8254],
  [-31.4307, 145.8090],
  [-31.4172, 145.8060],
  [-31.4145, 145.8057],
  [-31.4120, 145.8061],
  [-31.4108, 145.8061],
  [-31.4099, 145.8057],
  [-31.4095, 145.8050],
  [-31.4091, 145.8033],
  [-31.4091, 145.8028],
  [-31.4093, 145.8023]
];

const CsaMineEluraMine = [
  [-31.4120, 145.8061],
  [-31.4076, 145.8077],
  [-31.4060, 145.8079],
  [-31.4042, 145.8076],
  [-31.3612, 145.7913],
  [-31.3591, 145.7900],
  [-31.3535, 145.7841],
  [-31.3507, 145.7825],
  [-31.3411, 145.7801],
  [-31.2958, 145.7502],
  [-31.2933, 145.7495],
  [-31.2609, 145.7497],
  [-31.2583, 145.7492],
  [-31.1910, 145.7047],
  [-31.1710, 145.6916],
  [-31.1695, 145.6900],
  [-31.1684, 145.6879],
  [-31.1638, 145.6735],
  [-31.1635, 145.6713],
  [-31.1635, 145.6570],
  [-31.1636, 145.6561],
  [-31.1644, 145.6530]
];

export const cobar: Line = {
  name: 'Cobar',
  state: 'NSW',
  segments: [
    {
      segments: [nynganJunctionCobar],
      history: {
        opened: {
          date: '1892-07-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [cobarCsaMine],
      history: {
        opened: {
          date: '1918-01-07',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1931-09-10',
          status: 'closed'
        }, {
          date: '1963-12-17',
          status: 'open'
        }]
      }
    },
    {
      segments: [CsaMineEluraMine],
      history: {
        opened: {
          date: '1982-12-15',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    }
  ]
};
