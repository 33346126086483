import { Line } from "../../trackTypes";

const line = [
  [-40.9802, 145.6672],
  [-40.9803, 145.6662],
  [-40.9809, 145.6653],
  [-40.9850, 145.6626],
  [-40.9863, 145.6623],
  [-40.9930, 145.6630]
];

export const flowerdaleBallastPit: Line = {
  name: 'Flowerdale Ballast Pit',
  state: 'TAS',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1980-02-13',
          status: 'closed'
        }]
      }
    }
  ]
}
