import { Line } from "../../../trackTypes";

const tolgaKairi = [
  [-17.2217, 145.4799],
  [-17.2226, 145.4805],
  [-17.2231, 145.4811],
  [-17.2232, 145.4816],
  [-17.2241, 145.4935],
  [-17.2239, 145.4946],
  [-17.2142, 145.5089],
  [-17.2139, 145.5100],
  [-17.2138, 145.5110],
  [-17.2162, 145.5436]
];

const kairiTinarooDeviationStart = [
  [-17.2162, 145.5436],
  [-17.2167, 145.5452],
  [-17.2180, 145.5463],
  [-17.2203, 145.5471]
];

const tinarooOriginal = [
  [-17.2203, 145.5471],
  [-17.2213, 145.5481],
  [-17.2216, 145.5490],
  [-17.2215, 145.5504],
  [-17.2216, 145.5516],
  [-17.2222, 145.5531],
  [-17.2225, 145.5556],
  [-17.2228, 145.5567],
  [-17.2245, 145.5580],
  [-17.2252, 145.5591],
  [-17.2253, 145.5606],
  [-17.2258, 145.5615],
  [-17.2274, 145.5643],
  [-17.2291, 145.5708],
  [-17.2305, 145.5735],
  [-17.2352, 145.5762],
  [-17.2385, 145.5765],
  [-17.2404, 145.5761],
  [-17.2443, 145.5756],
  [-17.2509, 145.5763],
  [-17.2528, 145.5781],
  [-17.2542, 145.5814],
  [-17.2548, 145.5824],
  [-17.2568, 145.5832],
  [-17.2577, 145.5832],
  [-17.2606, 145.5820],
  [-17.2620, 145.5810],
  [-17.2630, 145.5807],
  [-17.2642, 145.5806]
];

const tinarooDeviation = [
  [-17.2203, 145.5471],
  [-17.2253, 145.5481],
  [-17.2288, 145.5483],
  [-17.2399, 145.5475],
  [-17.2485, 145.5477],
  [-17.2499, 145.5483],
  [-17.2511, 145.5500],
  [-17.2514, 145.5515],
  [-17.2510, 145.5538],
  [-17.2514, 145.5559],
  [-17.2630, 145.5753],
  [-17.2633, 145.5770],
  [-17.2632, 145.5792],
  [-17.2635, 145.5799],
  [-17.2642, 145.5806]
];

const tinarooDeviationEndYungabura = [
  [-17.2642, 145.5806],
  [-17.2651, 145.5819],
  [-17.2680, 145.5837],
  [-17.2694, 145.5839],
  [-17.2709, 145.5834]
];

const yungaburraKureen = [
  [-17.2709, 145.5834],
  [-17.2713, 145.5833],
  [-17.2725, 145.5832],
  [-17.2737, 145.5834],
  [-17.2771, 145.5844],
  [-17.2780, 145.5842],
  [-17.2786, 145.5837],
  [-17.2788, 145.5828],
  [-17.2783, 145.5808],
  [-17.2785, 145.5799],
  [-17.2791, 145.5793],
  [-17.2798, 145.5791],
  [-17.2846, 145.5793],
  [-17.2879, 145.5803],
  [-17.2892, 145.5805],
  [-17.2918, 145.5803],
  [-17.2933, 145.5807],
  [-17.2942, 145.5816],
  [-17.2951, 145.5835],
  [-17.2952, 145.5856],
  [-17.2956, 145.5880],
  [-17.2973, 145.5919],
  [-17.2996, 145.5941],
  [-17.3000, 145.5949],
  [-17.3002, 145.5958],
  [-17.3005, 145.5967],
  [-17.3022, 145.5982],
  [-17.3025, 145.5988],
  [-17.3030, 145.6023],
  [-17.3028, 145.6030],
  [-17.3023, 145.6042],
  [-17.3022, 145.6050],
  [-17.3027, 145.6064],
  [-17.3026, 145.6073],
  [-17.3023, 145.6084],
  [-17.3023, 145.6090],
  [-17.3030, 145.6111],
  [-17.3046, 145.6134],
  [-17.3056, 145.6142],
  [-17.3065, 145.6143],
  [-17.3078, 145.6140],
  [-17.3088, 145.6128],
  [-17.3090, 145.6121],
  [-17.3094, 145.6101],
  [-17.3099, 145.6094],
  [-17.3106, 145.6093],
  [-17.3116, 145.6094],
  [-17.3123, 145.6093],
  [-17.3174, 145.6072],
  [-17.3181, 145.6064],
  [-17.3194, 145.6047],
  [-17.3201, 145.6044],
  [-17.3210, 145.6046],
  [-17.3238, 145.6073],
  [-17.3245, 145.6076],
  [-17.3252, 145.6075],
  [-17.3259, 145.6067],
  [-17.3261, 145.6047],
  [-17.3265, 145.6033],
  [-17.3285, 145.6001],
  [-17.3321, 145.5972]
];

const kureenMalanda = [
  [-17.3321, 145.5972],
  [-17.3343, 145.5962],
  [-17.3414, 145.5956],
  [-17.3427, 145.5952],
  [-17.3446, 145.5916],
  [-17.3454, 145.5908],
  [-17.3482, 145.5903],
  [-17.3494, 145.5903],
  [-17.3509, 145.5921],
  [-17.3516, 145.5939]
];

const malandaJaggan = [
  [-17.3516, 145.5939],
  [-17.3549, 145.6021],
  [-17.3561, 145.6029],
  [-17.3574, 145.6028],
  [-17.3612, 145.6007],
  [-17.3617, 145.6007],
  [-17.3632, 145.6009],
  [-17.3643, 145.6016],
  [-17.3647, 145.6022],
  [-17.3650, 145.6025],
  [-17.3666, 145.6031],
  [-17.3694, 145.6053],
  [-17.3702, 145.6056],
  [-17.3717, 145.6052],
  [-17.3729, 145.6049],
  [-17.3740, 145.6052],
  [-17.3774, 145.6090],
  [-17.3783, 145.6096],
  [-17.3807, 145.6100],
  [-17.3820, 145.6095],
  [-17.3835, 145.6073],
  [-17.3835, 145.6060],
  [-17.3820, 145.6034],
  [-17.3820, 145.6021],
  [-17.3828, 145.6011],
  [-17.3859, 145.5996],
  [-17.3866, 145.5996],
  [-17.3874, 145.5999],
  [-17.3885, 145.6012],
  [-17.3894, 145.6015],
  [-17.3905, 145.6013],
  [-17.3917, 145.6007],
  [-17.3921, 145.6001],
  [-17.3924, 145.5991],
  [-17.3930, 145.5983],
  [-17.3941, 145.5983],
  [-17.3951, 145.5988],
  [-17.3963, 145.5989],
  [-17.3990, 145.5987]
];

const jagganTarzali = [
  [-17.3990, 145.5987],
  [-17.3996, 145.5989],
  [-17.4003, 145.5996],
  [-17.4012, 145.6010],
  [-17.4018, 145.6015],
  [-17.4026, 145.6013],
  [-17.4033, 145.6007],
  [-17.4041, 145.6006],
  [-17.4049, 145.6011],
  [-17.4050, 145.6019],
  [-17.4046, 145.6032],
  [-17.4040, 145.6039],
  [-17.4034, 145.6043],
  [-17.4021, 145.6048],
  [-17.4015, 145.6053],
  [-17.4013, 145.6059],
  [-17.4014, 145.6065],
  [-17.4020, 145.6079],
  [-17.4025, 145.6083],
  [-17.4031, 145.6084],
  [-17.4037, 145.6082],
  [-17.4039, 145.6077],
  [-17.4042, 145.6066],
  [-17.4047, 145.6059],
  [-17.4054, 145.6056],
  [-17.4067, 145.6055],
  [-17.4077, 145.6051],
  [-17.4086, 145.6043],
  [-17.4093, 145.6041],
  [-17.4109, 145.6047],
  [-17.4147, 145.6057],
  [-17.4173, 145.6054],
  [-17.4180, 145.6053],
  [-17.4191, 145.6056],
  [-17.4201, 145.6055],
  [-17.4207, 145.6046],
  [-17.4207, 145.6037],
  [-17.4204, 145.6030],
  [-17.4209, 145.6021],
  [-17.4216, 145.6013],
  [-17.4222, 145.6004],
  [-17.4228, 145.5999],
  [-17.4241, 145.5994],
  [-17.4248, 145.5995],
  [-17.4255, 145.6001],
  [-17.4258, 145.6009],
  [-17.4255, 145.6015],
  [-17.4252, 145.6021],
  [-17.4258, 145.6036]
];

const tarzaliMillaaMillaa = [
  [-17.4258, 145.6036],
  [-17.4264, 145.6043],
  [-17.4281, 145.6043],
  [-17.4292, 145.6041],
  [-17.4302, 145.6045],
  [-17.4310, 145.6053],
  [-17.4318, 145.6054],
  [-17.4324, 145.6051],
  [-17.4329, 145.6043],
  [-17.4337, 145.6038],
  [-17.4348, 145.6037],
  [-17.4374, 145.6018],
  [-17.4380, 145.6011],
  [-17.4383, 145.6000],
  [-17.4391, 145.5989],
  [-17.4416, 145.5980],
  [-17.4434, 145.5957],
  [-17.4437, 145.5954],
  [-17.4441, 145.5954],
  [-17.4449, 145.5959],
  [-17.4460, 145.5961],
  [-17.4470, 145.5950],
  [-17.4476, 145.5930],
  [-17.4491, 145.5902],
  [-17.4497, 145.5894],
  [-17.4518, 145.5887],
  [-17.4522, 145.5883],
  [-17.4536, 145.5867],
  [-17.4540, 145.5865],
  [-17.4544, 145.5865],
  [-17.4558, 145.5882],
  [-17.4565, 145.5889],
  [-17.4571, 145.5895],
  [-17.4578, 145.5897],
  [-17.4598, 145.5902],
  [-17.4603, 145.5901],
  [-17.4609, 145.5896],
  [-17.4625, 145.5850],
  [-17.4630, 145.5846],
  [-17.4641, 145.5843],
  [-17.4648, 145.5835],
  [-17.4656, 145.5833],
  [-17.4675, 145.5835],
  [-17.4683, 145.5840],
  [-17.4688, 145.5843],
  [-17.4706, 145.5844],
  [-17.4714, 145.5841],
  [-17.4736, 145.5827],
  [-17.4746, 145.5826],
  [-17.4762, 145.5831],
  [-17.4769, 145.5831],
  [-17.4788, 145.5827],
  [-17.4795, 145.5823],
  [-17.4808, 145.5796],
  [-17.4813, 145.5793],
  [-17.4821, 145.5794],
  [-17.4828, 145.5804],
  [-17.4831, 145.5824],
  [-17.4840, 145.5842],
  [-17.4841, 145.5850],
  [-17.4838, 145.5861],
  [-17.4841, 145.5870],
  [-17.4847, 145.5874],
  [-17.4859, 145.5873],
  [-17.4866, 145.5873],
  [-17.4873, 145.5876],
  [-17.4884, 145.5877],
  [-17.4900, 145.5885],
  [-17.4905, 145.5890],
  [-17.4905, 145.5897],
  [-17.4896, 145.5921],
  [-17.4897, 145.5931],
  [-17.4901, 145.5939],
  [-17.4918, 145.5957],
  [-17.4920, 145.5963],
  [-17.4919, 145.5969],
  [-17.4915, 145.5976],
  [-17.4913, 145.5983],
  [-17.4923, 145.6035],
  [-17.4921, 145.6042],
  [-17.4913, 145.6049],
  [-17.4909, 145.6056],
  [-17.4909, 145.6065],
  [-17.4916, 145.6081],
  [-17.4923, 145.6089],
  [-17.4930, 145.6092],
  [-17.4945, 145.6092],
  [-17.4959, 145.6079],
  [-17.4962, 145.6072],
  [-17.4964, 145.6069],
  [-17.4969, 145.6067],
  [-17.4979, 145.6068],
  [-17.4989, 145.6065],
  [-17.4998, 145.6067],
  [-17.5013, 145.6081],
  [-17.5024, 145.6086],
  [-17.5036, 145.6086],
  [-17.5042, 145.6089],
  [-17.5045, 145.6095],
  [-17.5045, 145.6108],
  [-17.5048, 145.6121],
  [-17.5048, 145.6130],
  [-17.5051, 145.6136],
  [-17.5069, 145.6151],
  [-17.5077, 145.6153],
  [-17.5087, 145.6153],
  [-17.5120, 145.6141]
];

export const millaMilla: Line = {
  name: 'Milla Milla',
  state: 'QLD',
  segments: [
    {
      segments: [tolgaKairi],
      history: {
        opened: {
          date: '1910-03-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kairiTinarooDeviationStart],
      history: {
        opened: {
          date: '1910-03-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tinarooOriginal],
      history: {
        opened: {
          date: '1910-03-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1953-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tinarooDeviation],
      history: {
        opened: {
          date: '1953-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tinarooDeviationEndYungabura],
      history: {
        opened: {
          date: '1910-03-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [yungaburraKureen],
      history: {
        opened: {
          date: '1910-10-18',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kureenMalanda],
      history: {
        opened: {
          date: '1910-12-20',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [malandaJaggan],
      history: {
        opened: {
          date: '1915-10-22',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [jagganTarzali],
      history: {
        opened: {
          date: '1916-12-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [tarzaliMillaaMillaa],
      history: {
        opened: {
          date: '1921-12-19',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
