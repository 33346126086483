import { Line } from "../../../trackTypes";

const hughendenStamford = [
  [-20.8569, 144.1928],
  [-20.8577, 144.1902],
  [-20.8592, 144.1893],
  [-20.8606, 144.1892],
  [-20.8615, 144.1888],
  [-20.8637, 144.1866],
  [-20.9196, 144.1396],
  [-20.9712, 144.0960],
  [-20.9723, 144.0947],
  [-20.9740, 144.0920],
  [-20.9760, 144.0900],
  [-20.9828, 144.0866],
  [-20.9892, 144.0813],
  [-20.9941, 144.0755],
  [-21.0643, 144.0162],
  [-21.1260, 143.9636],
  [-21.1551, 143.9324],
  [-21.1654, 143.9255],
  [-21.2252, 143.8659],
  [-21.2688, 143.8097]
];

const stamfordCorfield = [
  [-21.2688, 143.8097],
  [-21.3050, 143.7607],
  [-21.3705, 143.6956],
  [-21.4364, 143.6300],
  [-21.4920, 143.5858],
  [-21.5329, 143.5464],
  [-21.6005, 143.4808],
  [-21.6165, 143.4616],
  [-21.6330, 143.4479],
  [-21.6807, 143.3998],
  [-21.7147, 143.3725]
];

const corfieldWinton = [
  [-21.7147, 143.3725],
  [-21.7469, 143.3466],
  [-21.8684, 143.2483],
  [-21.8937, 143.2280],
  [-21.9493, 143.2016],
  [-21.9915, 143.1815],
  [-22.0741, 143.1382],
  [-22.1087, 143.1199],
  [-22.1781, 143.0832],
  [-22.2284, 143.0585],
  [-22.2388, 143.0530],
  [-22.2595, 143.0498],
  [-22.2913, 143.0528],
  [-22.3133, 143.0513],
  [-22.3243, 143.0475],
  [-22.3406, 143.0442],
  [-22.3769, 143.0383],
  [-22.3779, 143.0384]
];

const wintonStation = [
  [-22.3779, 143.0384],
  [-22.3849, 143.0369]
];

export const winton: Line = {
  name: 'Winton',
  state: 'QLD',
  segments: [
    {
      segments: [hughendenStamford],
      history: {
        opened: {
          date: '1897-12-13',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [stamfordCorfield],
      history: {
        opened: {
          date: '1898-10-15',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [corfieldWinton, wintonStation],
      history: {
        opened: {
          date: '1899-07-05',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2008-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
