import { Line } from "../../../../trackTypes";

const springStreet = [
  [-37.81116, 144.97298],
  [-37.81350, 144.97404],
  [-37.81510, 144.97479],
  [-37.81521, 144.97491],
  [-37.81526, 144.97509]
];

const collinsStJunction = [
  [-37.81338, 144.97369],
  [-37.81338, 144.97385],
  [-37.81342, 144.97395],
  [-37.81350, 144.97404]
];

const flindersStreetJunction = [
  [-37.81510, 144.97479],
  [-37.81525, 144.97477],
  [-37.81533, 144.97466]
];

const spencerStreetJunction = [
  [-37.81694, 144.95318],
  [-37.81702, 144.95329],
  [-37.81703, 144.95347]
];

const spencerStreetCliftonHill = [
  [-37.81724, 144.95333],
  [-37.81712, 144.95334],
  [-37.81703, 144.95347],
  [-37.81143, 144.97281],
  [-37.81131, 144.97297],
  [-37.81116, 144.97298],
  [-37.81088, 144.97285],
  [-37.81026, 144.97279],
  [-37.80817, 144.97315],
  [-37.80764, 144.97324],
  [-37.80555, 144.97359],
  [-37.80544, 144.97370],
  [-37.80539, 144.97386],
  [-37.80633, 144.98271],
  [-37.80631, 144.98285],
  [-37.80625, 144.98297],
  [-37.80614, 144.98303],
  [-37.79112, 144.98563],
  [-37.79081, 144.98582],
  [-37.79064, 144.98606],
  [-37.78830, 144.99169],
  [-37.78808, 144.99206],
  [-37.78566, 144.99451]
];

const cliftonHillNorthcote = [
  [-37.78566, 144.99451],
  [-37.78440, 144.99587],
  [-37.78393, 144.99615],
  [-37.76842, 144.99921],
  [-37.76683, 144.99919],
  [-37.76035, 145.00031],
  [-37.75251, 145.00162]
];

const connection = [
  [-37.75225, 145.00145],
  [-37.75228, 145.00156],
  [-37.75234, 145.00162],
  [-37.75251, 145.00162],
  [-37.75227, 145.00169],
  [-37.75214, 145.00177]
];

const victoriaParadeCityCircleJunction = [
  [-37.80787, 144.97286],
  [-37.80794, 144.97303],
  [-37.80804, 144.97312],
  [-37.80817, 144.97315]
];

const eastPrestonDepot = [
  [-37.74485, 145.00676],
  [-37.74474, 145.00676],
  [-37.74464, 145.00670],
  [-37.74438, 145.00617],
  [-37.74434, 145.00613],
  [-37.74429, 145.00611],
  [-37.74411, 145.00605],
  [-37.74404, 145.00606],
  [-37.74307, 145.00622]
];

export const north2: Line = {
  name: 'North2',
  state: 'VIC',
  segments: [
    {
      segments: [springStreet, flindersStreetJunction, victoriaParadeCityCircleJunction],
      history: {
        opened: {
          date: '1994-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [collinsStJunction],
      history: {
        opened: {
          date: '2009-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [spencerStreetCliftonHill],
      history: {
        opened: {
          date: '1887-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1940-01-01',
          status: 'closed'
        }, {
          date: '1955-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [spencerStreetJunction],
      history: {
        opened: {
          date: '1955-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [cliftonHillNorthcote],
      history: {
        opened: {
          date: '1890-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'cableTram'
        },
        trackChange: [{
          date: '1893-01-01',
          status: 'closed'
        }, {
          date: '1894-01-01',
          status: 'open'
        }, {
          date: '1897-01-01',
          status: 'closed'
        }, {
          date: '1901-01-01',
          status: 'open'
        }, {
          date: '1940-01-01',
          status: 'closed'
        }, {
          date: '1955-01-01',
          status: 'open',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [connection],
      history: {
        opened: {
          date: '1955-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [eastPrestonDepot],
      history: {
        opened: {
          date: '1955-06-26',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
