import { Line } from "../../../trackTypes";

const makarewaThornbury = [
  [-46.3045, 168.3326],
  [-46.3033, 168.3316],
  [-46.3024, 168.3301],
  [-46.2953, 168.3029],
  [-46.2951, 168.3013],
  [-46.2947, 168.2506],
  [-46.2958, 168.2346],
  [-46.2948, 168.1591],
  [-46.2946, 168.1576],
  [-46.2855, 168.1367],
  [-46.2811, 168.1246],
  [-46.2809, 168.1235],
  [-46.2809, 168.1222],
  [-46.2861, 168.0995]
];

const thornburyJunction = [
  [-46.2836, 168.0961],
  [-46.2868, 168.0953],
  [-46.2875, 168.0949]
];

const thornburyOtautau = [
  [-46.2861, 168.0995],
  [-46.2860, 168.0983],
  [-46.2858, 168.0974],
  [-46.2850, 168.0966],
  [-46.2844, 168.0962],
  [-46.2836, 168.0961],
  [-46.2796, 168.0969],
  [-46.2728, 168.0966],
  [-46.2623, 168.0938],
  [-46.2612, 168.0932],
  [-46.2594, 168.0909],
  [-46.2507, 168.0726],
  [-46.2490, 168.0708],
  [-46.2151, 168.0527],
  [-46.2141, 168.0523],
  [-46.2074, 168.0515],
  [-46.2019, 168.0490],
  [-46.2010, 168.0482],
  [-46.1971, 168.0437],
  [-46.1962, 168.0430],
  [-46.1867, 168.0384],
  [-46.1856, 168.0375],
  [-46.1706, 168.0219],
  [-46.1697, 168.0203],
  [-46.1692, 168.0188],
  [-46.1683, 168.0176],
  [-46.1673, 168.0165],
  [-46.1661, 168.0150],
  [-46.1634, 168.0124],
  [-46.1625, 168.0118],
  [-46.1612, 168.0115],
  [-46.1602, 168.0109],
  [-46.1582, 168.0088],
  [-46.1573, 168.0084],
  [-46.1563, 168.0085],
  [-46.1546, 168.0087],
  [-46.1536, 168.0076],
  [-46.1528, 168.0054],
  [-46.1519, 168.0042],
  [-46.1494, 168.0021],
  [-46.1486, 168.0010],
  [-46.1470, 167.9982],
  [-46.1432, 167.9953]
];

const otautauWairio = [
  [-46.1432, 167.9953],
  [-46.1277, 167.9858],
  [-46.0975, 167.9744],
  [-46.0955, 167.9745],
  [-46.0947, 167.9750],
  [-46.0568, 167.9985],
  [-46.0066, 168.0296],
  [-46.0050, 168.0302],
  [-45.9995, 168.0310]
];

const wairioNightcaps = [
  [-45.9995, 168.0310],
  [-45.9984, 168.0306],
  [-45.9952, 168.0287],
  [-45.9941, 168.0283],
  [-45.9906, 168.0278],
  [-45.9897, 168.0275],
  [-45.9873, 168.0259],
  [-45.9863, 168.0256],
  [-45.9840, 168.0257],
  [-45.9755, 168.0243],
  [-45.9690, 168.0244]
];

const nightcapsTinkers = [
  [-45.9690, 168.0244],
  [-45.9681, 168.0244],
  [-45.9671, 168.0239],
  [-45.9638, 168.0186],
  [-45.9630, 168.0181],
  [-45.9619, 168.0180],
  [-45.9612, 168.0182],
  [-45.9590, 168.0194]
];

const tinkersOhai = [
  [-45.9590, 168.0194],
  [-45.9575, 168.0201],
  [-45.9560, 168.0202],
  [-45.9555, 168.0198],
  [-45.9551, 168.0191],
  [-45.9540, 168.0138],
  [-45.9537, 168.0131],
  [-45.9513, 168.0082],
  [-45.9506, 168.0075],
  [-45.9498, 168.0071],
  [-45.9474, 168.0075],
  [-45.9466, 168.0072],
  [-45.9460, 168.0065],
  [-45.9391, 167.9888],
  [-45.9335, 167.9789],
  [-45.9332, 167.9780],
  [-45.9332, 167.9771],
  [-45.9333, 167.9763],
  [-45.9332, 167.9750],
  [-45.9325, 167.9737],
  [-45.9293, 167.9706],
  [-45.9287, 167.9694],
  [-45.9286, 167.9681],
  [-45.9287, 167.9674],
  [-45.9291, 167.9667]
];

const ohaiBirchwood = [
  [-45.9291, 167.9667],
  [-45.9301, 167.9650],
  [-45.9305, 167.9640],
  [-45.9345, 167.9508],
  [-45.9346, 167.9496],
  [-45.9344, 167.9484],
  [-45.9338, 167.9472],
  [-45.9335, 167.9454],
  [-45.9342, 167.9387],
  [-45.9342, 167.9351]
];

export const wairio: Line = {
  name: 'Wairio',
  state: 'NZ',
  segments: [
    {
      segments: [makarewaThornbury],
      history: {
        opened: {
          date: '1879-06-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [thornburyJunction],
      history: {
        opened: {
          date: '1879-06-09',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1978-01-15',
          status: 'closed'
        }]
      }
    },
    {
      segments: [thornburyOtautau],
      history: {
        opened: {
          date: '1879-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [otautauWairio],
      history: {
        opened: {
          date: '1882-03-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [wairioNightcaps],
      history: {
        opened: {
          date: '1914-06-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [nightcapsTinkers],
      history: {
        opened: {
          date: '1920-09-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [tinkersOhai],
      history: {
        opened: {
          date: '1924-12-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [ohaiBirchwood],
      history: {
        opened: {
          date: '1934-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1990-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
