import { Line } from "../../trackTypes";

const bellariveTunnel = [
  [-42.8729, 147.3664],
  [-42.8723, 147.3687],
  [-42.8714, 147.3693],
  [-42.8704, 147.3693],
  [-42.8693, 147.3689],
  [-42.8683, 147.3684],
  [-42.8676, 147.3685],
  [-42.8645, 147.3702],
  [-42.8638, 147.3710],
  [-42.8631, 147.3719],
  [-42.8623, 147.3736],
  [-42.8616, 147.3746],
  [-42.8597, 147.3766],
  [-42.8585, 147.3781],
  [-42.8578, 147.3794],
  [-42.8558, 147.3843],
  [-42.8543, 147.3893],
  [-42.8538, 147.3913],
  [-42.8535, 147.3952],
  [-42.8534, 147.3964],
  [-42.8537, 147.3979],
  [-42.8542, 147.4017],
  [-42.8552, 147.4075],
  [-42.8563, 147.4118],
  [-42.8567, 147.4129],
  [-42.8572, 147.4137],
  [-42.8583, 147.4143],
  [-42.8591, 147.4151],
  [-42.8596, 147.4162],
  [-42.8598, 147.4176],
  [-42.8598, 147.4182],
  [-42.8594, 147.4188],
  [-42.8577, 147.4200],
  [-42.8572, 147.4203],
  [-42.8566, 147.4203]
];

const tunnel = [
  [-42.8566, 147.4203],
  [-42.8550, 147.4199]
];

const tunnelSorell = [
  [-42.8550, 147.4199],
  [-42.8537, 147.4200],
  [-42.8530, 147.4205],
  [-42.8519, 147.4208],
  [-42.8511, 147.4212],
  [-42.8505, 147.4218],
  [-42.8496, 147.4219],
  [-42.8484, 147.4213],
  [-42.8475, 147.4208],
  [-42.8425, 147.4214],
  [-42.8420, 147.4216],
  [-42.8415, 147.4223],
  [-42.8413, 147.4235],
  [-42.8408, 147.4246],
  [-42.8404, 147.4248],
  [-42.8391, 147.4247],
  [-42.8386, 147.4250],
  [-42.8382, 147.4259],
  [-42.8380, 147.4285],
  [-42.8379, 147.4300],
  [-42.8383, 147.4311],
  [-42.8382, 147.4323],
  [-42.8381, 147.4336],
  [-42.8381, 147.4356],
  [-42.8386, 147.4400],
  [-42.8376, 147.4461],
  [-42.8372, 147.4467],
  [-42.8368, 147.4469],
  [-42.8363, 147.4476],
  [-42.8360, 147.4485],
  [-42.8354, 147.4496],
  [-42.8278, 147.4606],
  [-42.8268, 147.4612],
  [-42.8212, 147.4636],
  [-42.8206, 147.4641],
  [-42.8148, 147.4715],
  [-42.8129, 147.4752],
  [-42.8125, 147.4764],
  [-42.8117, 147.4814],
  [-42.8108, 147.4834],
  [-42.8092, 147.4848],
  [-42.8028, 147.4872],
  [-42.8021, 147.4877],
  [-42.8017, 147.4886],
  [-42.8015, 147.4904],
  [-42.8011, 147.4912],
  [-42.8005, 147.4916],
  [-42.7998, 147.4916],
  [-42.7989, 147.4910],
  [-42.7983, 147.4907],
  [-42.7973, 147.4910],
  [-42.7956, 147.4923],
  [-42.7953, 147.4927],
  [-42.7949, 147.4937],
  [-42.7945, 147.4952],
  [-42.7931, 147.4986],
  [-42.7926, 147.4995],
  [-42.7892, 147.5026],
  [-42.7863, 147.5050],
  [-42.7855, 147.5058],
  [-42.7850, 147.5067],
  [-42.7838, 147.5103],
  [-42.7832, 147.5111],
  [-42.7825, 147.5116],
  [-42.7817, 147.5124],
  [-42.7815, 147.5131],
  [-42.7814, 147.5150],
  [-42.7812, 147.5162],
  [-42.7740, 147.5377],
  [-42.7739, 147.5387],
  [-42.7742, 147.5398],
  [-42.7758, 147.5421],
  [-42.7758, 147.5443],
  [-42.7758, 147.5474],
  [-42.7766, 147.5546],
  [-42.7770, 147.5561],
  [-42.7791, 147.5589],
  [-42.7793, 147.5595],
  [-42.7798, 147.5633]
];

export const sorell: Line = {
  name: 'Sorell',
  state: 'TAS',
  segments: [
    {
      segments: [
        bellariveTunnel,
        {
          name: 'Tunnel Hill Tunnel',
          segment: tunnel,
          type: 'tunnel'
        },
        tunnelSorell
      ],
      history: {
        opened: {
          date: '1892-05-02',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1926-06-30',
          status: 'closed'
        }]
      }
    }
  ]
}
