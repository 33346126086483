import { Line } from "../../../trackTypes";

const line = [
  [-28.2035, 152.0403],
  [-28.2029, 152.0405],
  [-28.2022, 152.0410],
  [-28.2013, 152.0421],
  [-28.2003, 152.0425],
  [-28.1991, 152.0422],
  [-28.1971, 152.0403],
  [-28.1959, 152.0397],
  [-28.1943, 152.0396],
  [-28.1932, 152.0401],
  [-28.1921, 152.0415],
  [-28.1864, 152.0542],
  [-28.1705, 152.0739],
  [-28.1682, 152.0773],
  [-28.1545, 152.0994],
  [-28.1514, 152.1025],
  [-28.1325, 152.1312],
  [-28.1304, 152.1338],
  [-28.1273, 152.1362],
  [-28.1264, 152.1367],
  [-28.1238, 152.1372],
  [-28.1227, 152.1370],
  [-28.1145, 152.1306],
  [-28.1133, 152.1302],
  [-28.1029, 152.1289],
  [-28.1022, 152.1284],
  [-28.1007, 152.1267],
  [-28.0997, 152.1262],
  [-28.0986, 152.1264],
  [-28.0960, 152.1285],
  [-28.0946, 152.1311],
  [-28.0936, 152.1348],
  [-28.0928, 152.1372],
  [-28.0853, 152.1483],
  [-28.0839, 152.1522],
  [-28.0795, 152.1578],
  [-28.0787, 152.1591],
  [-28.0782, 152.1611],
  [-28.0783, 152.1648],
  [-28.0784, 152.1661],
  [-28.0777, 152.1702],
  [-28.0777, 152.1714],
  [-28.0781, 152.1754],
  [-28.0782, 152.1765],
  [-28.0771, 152.1847],
  [-28.0765, 152.1864],
  [-28.0749, 152.1886],
  [-28.0739, 152.1911],
  [-28.0735, 152.1927],
  [-28.0730, 152.1999],
  [-28.0730, 152.2008],
  [-28.0742, 152.2058],
  [-28.0742, 152.2068],
  [-28.0733, 152.2112],
  [-28.0705, 152.2183],
  [-28.0692, 152.2215],
  [-28.0691, 152.2225],
  [-28.0694, 152.2234],
  [-28.0711, 152.2257],
  [-28.0719, 152.2276],
  [-28.0723, 152.2299],
  [-28.0716, 152.2350],
  [-28.0718, 152.2375],
  [-28.0727, 152.2404]
];

export const warwickMaryvale: Line = {
  name: 'Warwick - Maryvale',
  state: 'QLD',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1911-09-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1960-11-01',
          status: 'closed'
        }]
      }
    }
  ]
}
