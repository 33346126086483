import { Line } from "../../trackTypes";

const line = [
  [-34.5971, 148.5572],
  [-34.5963, 148.5570],
  [-34.5957, 148.5571],
  [-34.5942, 148.5580],
  [-34.5928, 148.5585],
  [-34.5884, 148.5595],
  [-34.5868, 148.5596],
  [-34.5847, 148.5593],
  [-34.5834, 148.5588],
  [-34.5799, 148.5573],
  [-34.5785, 148.5570],
  [-34.5750, 148.5571],
  [-34.5735, 148.5574],
  [-34.5718, 148.5582],
  [-34.5673, 148.5611],
  [-34.5652, 148.5621],
  [-34.5640, 148.5631],
  [-34.5622, 148.5653],
  [-34.5610, 148.5664],
  [-34.5595, 148.5669],
  [-34.5572, 148.5672],
  [-34.5562, 148.5675],
  [-34.5532, 148.5691],
  [-34.5523, 148.5692],
  [-34.5515, 148.5690],
  [-34.5492, 148.5677],
  [-34.5474, 148.5672],
  [-34.5456, 148.5675],
  [-34.5447, 148.5680],
  [-34.5420, 148.5698],
  [-34.5376, 148.5740],
  [-34.5369, 148.5751],
  [-34.5367, 148.5765],
  [-34.5366, 148.5795],
  [-34.5360, 148.5829],
  [-34.5363, 148.5866],
  [-34.5359, 148.5886],
  [-34.5352, 148.5900],
  [-34.5337, 148.5915],
  [-34.5317, 148.5927],
  [-34.5301, 148.5931],
  [-34.5294, 148.5934],
  [-34.5288, 148.5943],
  [-34.5282, 148.5959],
  [-34.5273, 148.5967],
  [-34.5262, 148.5969],
  [-34.5191, 148.5959],
  [-34.5169, 148.5960],
  [-34.5111, 148.5962],
  [-34.5099, 148.5965],
  [-34.5074, 148.5973],
  [-34.5065, 148.5972],
  [-34.5058, 148.5969],
  [-34.5046, 148.5960],
  [-34.5037, 148.5957],
  [-34.5027, 148.5960],
  [-34.5018, 148.5969],
  [-34.5014, 148.5980],
  [-34.5015, 148.6000],
  [-34.5010, 148.6012],
  [-34.5002, 148.6020],
  [-34.4993, 148.6025],
  [-34.4986, 148.6033],
  [-34.4982, 148.6045],
  [-34.4980, 148.6061],
  [-34.4972, 148.6079],
  [-34.4961, 148.6091],
  [-34.4946, 148.6099],
  [-34.4933, 148.6107],
  [-34.4913, 148.6128],
  [-34.4909, 148.6139],
  [-34.4908, 148.6152],
  [-34.4911, 148.6162],
  [-34.4921, 148.6176],
  [-34.4926, 148.6196],
  [-34.4913, 148.6270],
  [-34.4902, 148.6299],
  [-34.4899, 148.6315],
  [-34.4900, 148.6339],
  [-34.4891, 148.6365],
  [-34.4874, 148.6378],
  [-34.4854, 148.6382],
  [-34.4844, 148.6389],
  [-34.4837, 148.6400],
  [-34.4808, 148.6461],
  [-34.4766, 148.6629],
  [-34.4757, 148.6643],
  [-34.4671, 148.6736],
  [-34.4666, 148.6744],
  [-34.4657, 148.6767],
  [-34.4620, 148.6829],
  [-34.4497, 148.7087],
  [-34.4456, 148.7178],
  [-34.4445, 148.7193],
  [-34.4433, 148.7199],
  [-34.4418, 148.7203],
  [-34.4372, 148.7211]
];

export const boorowa: Line = {
  name: 'Boorowa',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1915-02-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1987-12-01',
          status: 'closed'
        }]
      }
    }
  ]
};
