import { Line } from "../../trackTypes";

const line = [
  [-35.2379, 136.9844],
  [-35.2366, 136.9819],
  [-35.2363, 136.9810],
  [-35.2364, 136.9803],
  [-35.2414, 136.9783],
  [-35.2432, 136.9766],
  [-35.2443, 136.9751],
  [-35.2456, 136.9716],
  [-35.2462, 136.9707],
  [-35.2553, 136.9614],
  [-35.2560, 136.9600],
  [-35.2562, 136.9587],
  [-35.2560, 136.9574],
  [-35.2562, 136.9557],
  [-35.2565, 136.9539],
  [-35.2566, 136.9462],
  [-35.2568, 136.9387],
  [-35.2567, 136.9383],
  [-35.2564, 136.9379],
  [-35.2541, 136.9371],
  [-35.2534, 136.9365],
  [-35.2528, 136.9355]
];

const branch = [
  [-35.2366, 136.9819],
  [-35.2364, 136.9818],
  [-35.2360, 136.9820],
  [-35.2353, 136.9825],
  [-35.2348, 136.9826],
  [-35.2344, 136.9825],
  [-35.2314, 136.9803],
  [-35.2270, 136.9787],
  [-35.2220, 136.9789]
];

export const marionBay: Line = {
  name: 'Marion Bay',
  state: 'SA',
  segments: [
    {
      segments: [line, branch],
      history: {
        opened: {
          date: '1889-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1930-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
