import { Line } from "../../../trackTypes";

const line = [
  [-43.5410, 172.5534],
  [-43.5408, 172.5521],
  [-43.5404, 172.5512],
  [-43.5398, 172.5504],
  [-43.5379, 172.5485],
  [-43.5370, 172.5483],
  [-43.5345, 172.5480],
  [-43.5338, 172.5477],
  [-43.5333, 172.5470],
  [-43.5320, 172.5433]
];

export const riccartonRacecourse: Line = {
  name: 'Riccarton Racecourse',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1877-11-03',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-11-10',
          status: 'closed'
        }]
      }
    }
  ]
}
