import { Line } from "../../trackTypes";

const line = [
  [-31.1485, 117.0911],
  [-31.1473, 117.0922],
  [-31.1459, 117.0926],
  [-31.1403, 117.0921],
  [-31.1393, 117.0921],
  [-31.1355, 117.0929],
  [-31.1340, 117.0928],
  [-31.1325, 117.0925],
  [-31.1314, 117.0925],
  [-31.1220, 117.0941],
  [-31.1193, 117.0939],
  [-31.1073, 117.0899],
  [-31.0896, 117.0898],
  [-31.0877, 117.0903],
  [-31.0473, 117.1135],
  [-31.0322, 117.1261],
  [-31.0308, 117.1267],
  [-31.0293, 117.1265],
  [-31.0278, 117.1254],
  [-31.0232, 117.1190],
  [-31.0220, 117.1182],
  [-31.0205, 117.1179],
  [-31.0190, 117.1180],
  [-31.0178, 117.1176],
  [-31.0167, 117.1167],
  [-31.0116, 117.1089],
  [-31.0030, 117.0992],
  [-31.0013, 117.0982],
  [-30.9511, 117.0848],
  [-30.9495, 117.0839],
  [-30.9476, 117.0819],
  [-30.9418, 117.0788],
  [-30.9405, 117.0786],
  [-30.9392, 117.0790],
  [-30.9342, 117.0819],
  [-30.9298, 117.0871],
  [-30.9285, 117.0881],
  [-30.9197, 117.0924],
  [-30.9179, 117.0941],
  [-30.9168, 117.0963],
  [-30.9157, 117.0976],
  [-30.9133, 117.0995],
  [-30.9122, 117.1006],
  [-30.9086, 117.1064],
  [-30.9069, 117.1078],
  [-30.9051, 117.1079],
  [-30.9031, 117.1071],
  [-30.8961, 117.1069],
  [-30.8943, 117.1075],
  [-30.8929, 117.1088],
  [-30.8913, 117.1097],
  [-30.8891, 117.1097],
  [-30.8877, 117.1102],
  [-30.8864, 117.1113],
  [-30.8855, 117.1119],
  [-30.8838, 117.1124],
  [-30.8822, 117.1123],
  [-30.8805, 117.1115],
  [-30.8787, 117.1113],
  [-30.8770, 117.1120],
  [-30.8759, 117.1122],
  [-30.8743, 117.1122],
  [-30.8725, 117.1129],
  [-30.8707, 117.1128],
  [-30.8637, 117.1091],
  [-30.8616, 117.1088],
  [-30.8600, 117.1079],
  [-30.8572, 117.1039],
  [-30.8559, 117.1027],
  [-30.8439, 117.0979],
  [-30.8423, 117.0980],
  [-30.8317, 117.1044],
  [-30.8279, 117.1052],
  [-30.8258, 117.1047],
  [-30.8232, 117.1026],
  [-30.8217, 117.1020],
  [-30.8155, 117.1010],
  [-30.8143, 117.1011],
  [-30.7947, 117.1055],
  [-30.7877, 117.1084],
  [-30.7863, 117.1096],
  [-30.7816, 117.1168],
  [-30.7794, 117.1186],
  [-30.7782, 117.1206],
  [-30.7780, 117.1226],
  [-30.7783, 117.1245],
  [-30.7780, 117.1264],
  [-30.7771, 117.1279],
  [-30.7633, 117.1395],
  [-30.7585, 117.1425],
  [-30.7515, 117.1436],
  [-30.7499, 117.1432],
  [-30.7488, 117.1426],
  [-30.7472, 117.1410],
  [-30.7455, 117.1402],
  [-30.7336, 117.1401],
  [-30.7322, 117.1407],
  [-30.7261, 117.1459],
  [-30.7251, 117.1473],
  [-30.7245, 117.1494],
  [-30.7234, 117.1510],
  [-30.7158, 117.1564],
  [-30.7149, 117.1576],
  [-30.7141, 117.1593],
  [-30.7128, 117.1606],
  [-30.7028, 117.1641],
  [-30.7014, 117.1653],
  [-30.6974, 117.1713],
  [-30.6960, 117.1724],
  [-30.6890, 117.1736],
  [-30.6876, 117.1745],
  [-30.6860, 117.1761],
  [-30.6802, 117.1780],
  [-30.6679, 117.1789],
  [-30.6666, 117.1787],
  [-30.6643, 117.1777],
  [-30.6452, 117.1749],
  [-30.6437, 117.1750],
  [-30.6388, 117.1769],
  [-30.6372, 117.1770],
  [-30.6290, 117.1756],
  [-30.6243, 117.1743],
  [-30.6230, 117.1734],
  [-30.6214, 117.1713],
  [-30.6199, 117.1703],
  [-30.6181, 117.1703],
  [-30.6105, 117.1736],
  [-30.6081, 117.1738],
  [-30.6014, 117.1718],
  [-30.5915, 117.1717],
  [-30.5903, 117.1720],
  [-30.5838, 117.1757],
  [-30.5820, 117.1763],
  [-30.5572, 117.1796],
  [-30.5502, 117.1818],
  [-30.5489, 117.1818],
  [-30.5476, 117.1811],
  [-30.5379, 117.1714],
  [-30.5369, 117.1708],
  [-30.5316, 117.1692],
  [-30.5306, 117.1691],
  [-30.5296, 117.1695],
  [-30.5223, 117.1745],
  [-30.5201, 117.1759],
  [-30.5188, 117.1760],
  [-30.5174, 117.1756],
  [-30.5128, 117.1718],
  [-30.5029, 117.1680],
  [-30.5015, 117.1671],
  [-30.4968, 117.1616],
  [-30.4956, 117.1608],
  [-30.4891, 117.1596],
  [-30.4877, 117.1597],
  [-30.4813, 117.1627],
  [-30.4803, 117.1629],
  [-30.4644, 117.1629],
  [-30.4319, 117.1503],
  [-30.4311, 117.1502],
  [-30.4293, 117.1503],
  [-30.4281, 117.1501],
  [-30.4168, 117.1450],
  [-30.4064, 117.1416],
  [-30.4053, 117.1410],
  [-30.3963, 117.1346],
  [-30.3906, 117.1312],
  [-30.3891, 117.1297],
  [-30.3878, 117.1274],
  [-30.3868, 117.1264],
  [-30.3829, 117.1238],
  [-30.3749, 117.1216],
  [-30.3735, 117.1217],
  [-30.3711, 117.1224],
  [-30.3697, 117.1224],
  [-30.3666, 117.1215],
  [-30.3657, 117.1211],
  [-30.3591, 117.1149]
];

export const kalannie: Line = {
  name: 'Kalannie',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        }
      }
    }
  ]
};
