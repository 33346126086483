import { Line } from "../../../../trackTypes";

const swanstonStreetNorth = [
  [-37.81170, 144.96480],
  [-37.80943, 144.96375],
  [-37.80736, 144.96281],
  [-37.80723, 144.96279],
  [-37.80717, 144.96280],
  [-37.80683, 144.96286],
  [-37.80457, 144.96326]
];

const laTrobeStreetJunction = [
  [-37.80943, 144.96375],
  [-37.80952, 144.96376],
  [-37.80959, 144.96372],
  [-37.80965, 144.96363]
];

const eastCoburg = [
  [-37.80457, 144.96326],
  [-37.79702, 144.96456],
  [-37.79689, 144.96467],
  [-37.79685, 144.96488],
  [-37.79713, 144.96728],
  [-37.79709, 144.96747],
  [-37.79696, 144.96759],
  [-37.78146, 144.97023],
  [-37.77566, 144.97104],
  [-37.76237, 144.97334],
  [-37.76218, 144.97350],
  [-37.76214, 144.97378],
  [-37.76200, 144.97395],
  [-37.75682, 144.97484],
  [-37.75653, 144.97491],
  [-37.75649, 144.97492],
  [-37.75261, 144.97637],
  [-37.74532, 144.97756],
  [-37.74489, 144.97774],
  [-37.74278, 144.97810]
];

const victoriaStreetOld = [
  [-37.80736, 144.96281],
  [-37.80672, 144.96250],
  [-37.80663, 144.96242],
  [-37.80657, 144.96228]
];

const victoriaStreetDeviation = [
  [-37.80683, 144.96286],
  [-37.80669, 144.96278],
  [-37.80661, 144.96262],
  [-37.80657, 144.96228]
];

const victoriaStreetConnector = [
  [-37.80657, 144.96228],
  [-37.80630, 144.95977],
  [-37.80627, 144.95965],
  [-37.80620, 144.95953],
  [-37.80610, 144.95942]
];

export const north5: Line = {
  name: 'North5',
  state: 'VIC',
  segments: [
    {
      segments: [swanstonStreetNorth],
      history: {
        opened: {
          date: '1888-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1889-01-01',
          trackType: 'cableTram'
        }, {
          date: '1924-01-01',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [laTrobeStreetJunction],
      history: {
        opened: {
          date: '1951-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [eastCoburg],
      history: {
        opened: {
          date: '1916-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    },
    {
      segments: [
        {
          date: '2024-09-25',
          original: [victoriaStreetOld],
          deviation: [victoriaStreetDeviation]
        },
        victoriaStreetConnector
      ],
      history: {
        opened: {
          date: '1926-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }
      }
    }
  ]
};
